import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialog } from '../../../../../components/dialogs/basedialog/base.dialog';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { TranslateHelper } from '../../../../../helpers/injector.helper';
import { RestService } from '../../../../../services/rest.service';
import { BaseListDetail } from '../../../../../settings/base.list.settings';
import { RequestType } from '../../../models/auth.type';
import { Content, EndpointParameter, EndpointResponseBody, ParameterType } from '../../../models/end.point.description';
import { RestObject } from '../../../models/rest.object';
import { EndpointContentTypeDialog } from './end.point.content.type.dialog/end.point.content.type.dialog';
import { EndpointParameterDialog } from './end.point.parameter.dialog/end.point.parameter.dialog';

@Component({
    selector: 'end-point-rest-detail',
    templateUrl: 'end.pont.rest.detail.html',
    styleUrls: ['end.pont.rest.detail.css']
})
export class EndPointRestDetailComponent extends BaseListDetail {

    public requestTypes: any[];
    public objects: any[];
    private _baseDialogRef: MatDialogRef<BaseDialog, any>;

    constructor(private _service: RestService) {
        super();
    }

    ngOnInit() {
        this.requestTypes = EnumHelper.GetDropdownValues(RequestType);
        super.ngOnInit();
    }

    setSelectedItem(item: any): void {
        if (!this.objects && item) {
            this._service.loadRestObjects((item as RestObject).RestID).subscribe(result => {
                const os = [];
                if (result) {
                    result.forEach(r => {
                        os.push({
                            Caption: r.Caption,
                            Value: r.Value
                        });
                    });
                }
                this.objects = os;
            });
        }
        super.setSelectedItem(item);
    }

    addNewParameter(): void {
        this._baseDialogRef = BaseDialog.ShowDialog({
            ContentType: EndpointParameterDialog,
            Title: TranslateHelper.TranslatorInstance.instant('@@Create parameter'),
            Handler: this.newEndpointParameterReceived.bind(this),
            InitArgs: new EndpointParameter()
        });
        this.OnItemChanged();
    }

    private newEndpointParameterReceived(result: any): void {
        if (result) {
            this.SelectedItem.Parameters.push(result);
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    private editEndpointParameterReceived(result: any): void {
        if (result) {
            const parm = this.SelectedItem.Parameters.find(x => x.Name === result.Name);
            if (parm) {
                var index = this.SelectedItem.Parameters.indexOf(result);
                this.SelectedItem.Parameters.splice(index, 1);

                this.SelectedItem.Parameters.push(parm);
            }
            else {
                this.SelectedItem.Parameters.push(result);
            }
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    editParamater(parameter: any): void {
        this._baseDialogRef = BaseDialog.ShowDialog({
            ContentType: EndpointParameterDialog,
            Title: TranslateHelper.TranslatorInstance.instant('@@Edit parameter'),
            Handler: this.editEndpointParameterReceived.bind(this),
            InitArgs: parameter
        });
        this.OnItemChanged();
    }

    removeParameter(parameter: any): void {
        if (parameter) {
            var index = this.SelectedItem.Parameters.indexOf(parameter);
            this.SelectedItem.Parameters.splice(index, 1);
            this.OnItemChanged();
        }
    }

    private addNewRequestContentTypeReceived(result: any): void {
        if (result) {
            if (!this.SelectedItem.RequestBody.Contents)
                this.SelectedItem.RequestBody.Contents = [];
            this.SelectedItem.RequestBody.Contents.push(result);
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    private addNewResponseContentTypeReceived(statusCode: number, result: any): void {
        if (result) {
            const resp = this.SelectedItem.Responses.find(x => x.StatusCode === statusCode);
            if (resp) {
                resp.Contents.push(result);
            }
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    private editRequestContentTypeReceived(result: any): void {
        if (result) {
            const con = this.SelectedItem.RequestBody.Contents.find(x => x.ContentType === result.ContentType);
            if (con) {
                var index = this.SelectedItem.RequestBody.Contents.indexOf(result);
                this.SelectedItem.RequestBody.Contents.splice(index, 1);

                this.SelectedItem.RequestBody.Contents.push(result);
            }
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    private editResponseContentTypeReceived(statusCode: number, result: any): void {
        if (result) {
            const resp = this.SelectedItem.Responses.find(x => x.StatusCode === statusCode);
            if (resp) {
                const con = resp.Contents.find(x => x.ContentType === result.ContentType);
                if (con) {
                    var index = resp.Contents.indexOf(con);
                    resp.Contents.splice(index, 1);
                    resp.Contents.push(result);
                    //todo: replace content in given response.
                    var respIndex = this.SelectedItem.Responses.indexOf(resp);
                    this.SelectedItem.Responses.splice(respIndex, 1);
                    this.SelectedItem.Responses.push(resp);
                }
            }
            
            
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    editContentType(content: any, isRequestBody: boolean, statusCode: number = 0): void {
        this._baseDialogRef = BaseDialog.ShowDialog({
            ContentType: EndpointContentTypeDialog,
            Title: TranslateHelper.TranslatorInstance.instant('@@Edit Content-Type'),
            Handler: isRequestBody ? this.editRequestContentTypeReceived.bind(this) : this.editResponseContentTypeReceived.bind(this, statusCode),
            InitArgs: content
        });
        this.OnItemChanged();
    }

    deleteContentType(content: any, isRequestBody: boolean, statusCode: number = 0): void {
        if (content) {
            if (isRequestBody) {
                var index = this.SelectedItem.RequestBody.Contents.indexOf(content);
                this.SelectedItem.RequestBody.Contents.splice(index, 1);
            } else {
                if (statusCode) {
                    const resp = this.SelectedItem.Responses.find(x => x.StatusCode === statusCode);
                    if (resp) {
                        var index = resp.Contents.indexOf(content);
                        resp.Contents.splice(index, 1);
                    }
                }
            }           
            this.OnItemChanged();
        }
    }

    addNewTab(isRequestBody: boolean, statusCode: number = 0): void {
        this._baseDialogRef = BaseDialog.ShowDialog({
            ContentType: EndpointContentTypeDialog,
            Title: TranslateHelper.TranslatorInstance.instant('@@New Content-Type'),
            Handler: isRequestBody ? this.addNewRequestContentTypeReceived.bind(this) : this.addNewResponseContentTypeReceived.bind(this, statusCode),
            InitArgs: new Content()
        });
        this.OnItemChanged();
    }

    addNewResponse(): void {
        const resp = new EndpointResponseBody();
        resp.StatusCode = 0;
        resp.Contents = [];
        this.SelectedItem.Responses.push(resp);
        this.OnItemChanged();
    }

    removeResponse(f: any): void {
        if (f) {
            var index = this.SelectedItem.Responses.indexOf(f);
            this.SelectedItem.Responses.splice(index, 1);
            this.OnItemChanged();
        }
    }

    public getEnumValue(value: ParameterType): string {
        return ParameterType[value];
    }

    GetIcon(value) {
        return value ? 'done' : '';
    }
}