import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { Row } from '../../models/basic/row.model';
import { Comparer } from '../../models/enums/comparer.enum';
import { Concat } from '../../models/enums/contact.enum';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { Order } from '../../models/enums/order.enum';
import { RequestFilter } from '../../models/rest/requestfilter';
import { RequestOptions } from '../../models/rest/requestoptions';
import { RequestSort } from '../../models/rest/requestsort';
import { StandardRequestBase } from '../../services/request-base';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'systempassthrough-settings',
    templateUrl: '../base.list.settings.html'
})
export class SystemPassThroughSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@SystemPassThrough',
            ID: 'systempassthrough',
            Icon: 'sync_alt',
            Index: 1,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 4
            },
            Content: SystemPassThroughSettings
        };
    }

    constructor(protected fR: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: StandardRequestBase) {
        super(fR, cdRef);
        this.ShowSave = false;
        this.ShowDelete = false;
        this.ShowAdd = false;
    }

    getContentType() {
        return SystemPassThroughDetail;
    }

    loadList(handler) {
        this.service.executeGet('passthrough', 'GetCollections').subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry,
                        ID: entry
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.Component.SelectedItem = data;
        this.Component.reload();
    }

    getNewItem() {
        throw new Error('Method not implemented.');
    }
    getSaveSuccessText(sel: any): SaveTexts {
        throw new Error('Method not implemented.');
    }
    getDeleteText(sel: any): DeleteTexts {
        throw new Error('Method not implemented.');
    }
    delete(data, handler) {
        throw new Error('Method not implemented.');
    }
    saveInternal(item, handler) {
        throw new Error('Method not implemented.');
    }
}

@Component({
    selector: 'systempassthrough-detail',
    templateUrl: './systempassthrough.settings.html',
    styleUrls: ['./systempassthrough.settings.css']
})
export class SystemPassThroughDetail extends BaseListDetail {
    Columns = [];
    Source = [];
    FilteredCount = 0;
    Loading = true;
    PageSize = 100;
    Selection;
    Filter: RequestOptions = RequestOptions.CleanRequestOptions();
    private fetchedPages = new Set<number>();

    constructor(private service: StandardRequestBase, public dialog: MatDialog) {
        super();
    }

    reload() {
        this.Selection = null;
        this.Columns = [];
        this.reloadInternal(RequestOptions.CleanRequestOptions());
    }

    private reloadInternal(filter) {
        this.Filter = filter;
        this.service.executePost(this.Filter, 'passthrough', 'GetCount', 'ID=' + this.SelectedItem).subscribe((result) => {
            this.FilteredCount = result;
            this.fetchedPages = new Set<number>();
            this.Source = Array.from(Array(this.FilteredCount), (v, index) => new Row());
            this.fetchPage(0);
        });
    }
    private fetchPage(page: number) {
        if (this.fetchedPages.has(page)) {
            return;
        }
        this.fetchedPages.add(page);
        this.Loading = true;
        const queryFilter = JSON.parse(JSON.stringify(this.Filter));
        queryFilter.StartRow = page * this.PageSize;
        queryFilter.EndRow = queryFilter.StartRow + this.PageSize;
        this.service.executePost(queryFilter, 'passthrough', 'GetData', 'ID=' + this.SelectedItem).subscribe((result) => {
            let index = page * this.PageSize;
            const rows = [];
            if (result) {
                result.forEach((item) => {
                    Object.keys(item.Value).forEach((col) => {
                        const actCol = this.Columns.find(x => x.Caption == col);
                        if (!actCol) {
                            this.Columns.push({
                                Caption: col,
                                CalculatedWidth: '200px'
                            });
                        }
                    });
                    const row = new Row();
                    item.Value['_id'] = item.Key;
                    row.data = item.Value;
                    row.index = index;
                    index++;
                    rows.push(row);
                });
            }
            this.Source.splice(page * this.PageSize, this.PageSize, ...rows);
            this.Source = [...this.Source];
            this.Loading = false;
            this.cdRef.detectChanges();
        });
        this.cdRef.detectChanges();
    }
    delete() {
        const sel = this.Selection;
        if (sel && sel.data) {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Sind Sie sicher, dass Sie den ausgewaehlten Eintrag loeschen wollen?'),
                new TranslateFormatText('@@Loeschen'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        const path = 'Delete?coll=' + this.SelectedItem + '&ID=' + sel.data['_id'];
                        this.service.executeDelete('passthrough', path).subscribe(result => {
                            this.reload();
                        });
                    }
                });
        }
    }

    LazyLoad(event: LazyLoadEvent) {
        const options = RequestOptions.CleanRequestOptions();
        if (event.multiSortMeta && event.multiSortMeta.length > 0) {
            const list = [];
            for (let i = 0; i < event.multiSortMeta.length; i++) {
                let sort = event.multiSortMeta[i];
                let s = new RequestSort();
                s.Name = sort.field;
                s.Order = sort.order == -1 ? Order.DESC : Order.ASC;
                s.Position = i;
                list.push(s);
            }
            if (list.length > 0) {
                options.Sort = list;
            }
        }
        if (event.filters) {
            const requestFilters = new RequestFilter();
            requestFilters.ConcatOperator = Concat.And;
            const columns = Object.keys(event.filters);
            for (let i = 0; i < columns.length; i++) {
                let column: any = event.filters[columns[i]];
                const filters = new RequestFilter();
                if (column[0] && column[0].operator == 'and') {
                    filters.ConcatOperator = Concat.And;
                } else {
                    filters.ConcatOperator = Concat.Or;
                }
                if (Array.isArray(column)) {
                    column.forEach((filter) => {
                        this.ConvertFilter(filter, filters, columns[i]);
                    });
                } else {
                    this.ConvertFilter(column, filters, columns[i]);
                }
                if (filters.Filters) {
                    if (!requestFilters.Filters) {
                        requestFilters.Filters = [];
                    }
                    requestFilters.Filters.push(filters);
                }
            }

            if (requestFilters.Filters) {
                if (options.Filters) {
                    options.Filters.push(requestFilters);
                } else {
                    options.Filters = [requestFilters];
                }
            }
        }
        if (JSON.stringify(options) != JSON.stringify(this.Filter)) {
            this.reloadInternal(options);            
        } else {
            const startPage = Math.floor(event.first / this.PageSize);
            const endPage = Math.floor((event.first + event.rows -1) / this.PageSize);
            for (let i = startPage; i <= endPage; i++) {
                this.fetchPage(i);
            }
        }
    }

    ColResized(event) {
        if (event && event.element) {
            const col = this.Columns.find((value) => value.Caption == event.element.innerText);
            if (col) {
                col.CalculatedWidth = event.element.clientWidth + 'px';
            }
        }
    }

    ConvertFilter(filter, filters, name) {
        if (filter.value != null) {
            if (!filters.Filters) {
                filters.Filters = [];
            }
            let f = new RequestFilter();
            f.Name = name;
            f.Value = filter.value;
            f.Operator = Comparer.Equal;
            switch (filter.matchMode) {
                case FilterMatchMode.EQUALS:
                    f.Operator = Comparer.Equal;
                    break;
                case FilterMatchMode.NOT_EQUALS:
                    f.Operator = Comparer.NotEqual;
                    break;
                case FilterMatchMode.CONTAINS:
                    f.Operator = Comparer.Like;
                    break;
                case FilterMatchMode.LESS_THAN:
                    f.Operator = Comparer.Smaller;
                    break;
                case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
                    f.Operator = Comparer.SmallerEqual;
                    break;
                case FilterMatchMode.GREATER_THAN:
                    f.Operator = Comparer.Greater;
                    break;
                case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
                    f.Operator = Comparer.GreaterEqual;
                    break;
            }
            filters.Filters.push(f);
        }
        return
    }
}
