import { Component } from '@angular/core';
import domtoimage from "dom-to-image-more";
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-converttoimage-settings',
    templateUrl: './convertToImage.settings.html'
})
export class ConvertToImageSettings extends FormulaWorkflowDialogContent {
    Data: ConvertToImageSettingsData;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'convertToImageWFModule';
        reg.Caption = '@@Convert to Image';
        reg.GroupID = 'wfActions';
        reg.Index = 71;
        reg.SettingsControl = ConvertToImageSettings;
        reg.SettingsTypeHelper = new ConvertToImageSettingsDataHelper();
        reg.Executer = ConvertToImageModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.HasExpressions = false;
    }

    initialize(data: any) {
        if (data) {
            this.Data = data;
        }
    }

    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }

    showFormulaDialog() {
        const variables = [...this.Variables];
        
        const args = {
            InputMode: FormulaInputMode.VariableName,
            Formula: this.Data.FileName,
            Variables: variables
        };
        FormulaEditorDialog.ShowDialog(args, (result) => {
            if (result && result.Formula) {
                this.Data.FileName = result.Formula;
            }
            return true;
        });
    }
}

export class ConvertToImageSettingsData {
    ElemName: string;
    AutoSave: boolean;
    FileName: string;
}

export class ConvertToImageSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new ConvertToImageSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class ConvertToImageModuleExecuter extends WorkflowModuleExecuter {

    private static beforeScreenShot(element) {
        if (element) {
            if (element.Element && typeof element.Element.BeforeScreenShot == 'function') {
                element.Element.BeforeScreenShot();
            }
            if (element.Elements) {
                element.Elements.forEach(x => {
                    ConvertToImageModuleExecuter.beforeScreenShot(x);
                });
            }
        }
    }

    private static afterScreenShot(element) {
        if (element) {
            if (element.Element && typeof element.Element.AfterScreenShot == 'function') {
                element.Element.AfterScreenShot();
            }
            if (element.Elements) {
                element.Elements.forEach(x => {
                    ConvertToImageModuleExecuter.afterScreenShot(x);
                });
            }
        }
    }

    async execute(status: WorkflowStatus): Promise<number> {
        return new Promise((resolve) => {
            if (status.ActualSettings) {
                const calculator = new WorkflowFormulaCalculator(status);
                if (typeof status.ActualSettings.ElemName === 'string') {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                    if (elem) {
                        let htmlElement = document.getElementById('LE_' + elem.ID);
                        if (htmlElement) {
                            ConvertToImageModuleExecuter.beforeScreenShot(elem);
                            domtoimage
                                .toPng(htmlElement)
                                .then(function (image) {
                                    //let image = canvas.toDataURL("image/png");
                                    if (status.ActualSettings.AutoSave && status.ActualSettings.FileName) {
                                        const link = document.createElement('a');
                                        link.href = image;
                                        link.download = calculator.CalcFormula(status.ActualSettings.FileName);
                                        // Version link.click() to work at firefox
                                        link.dispatchEvent(new MouseEvent('click', {
                                            bubbles: true,
                                            cancelable: true,
                                            view: window
                                        }));

                                        setTimeout(() => { // firefox
                                            window.URL.revokeObjectURL(image);
                                            link.remove();
                                        }, 100);
                                    }
                                    status.ActualParameter = image;
                                    ConvertToImageModuleExecuter.afterScreenShot(elem);
                                    resolve(0);
                                })
                                .catch(function (error) {
                                    status.Logger.logError('ConvertToImage modul: Image conversion failed.');
                                    ConvertToImageModuleExecuter.afterScreenShot(elem);
                                    resolve(-1);
                                });
                        } else {
                            status.Logger.logError('ConvertToImage modul: Control not found.');
                            resolve(-1)
                        }
                    } else {
                        status.Logger.logError('ConvertToImage modul: Element not found.');
                        resolve(-1)
                    }
                } else {
                    status.Logger.logError('ConvertToImage modul: No element name found.');
                    resolve(-1)
                }
            } else {
                status.Logger.logError('ConvertToImage modul: No settings found.');
                resolve(-1)
            }
        });
    }
}
