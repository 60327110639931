import { Directive, EventEmitter, InjectionToken, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { plainToClass, Type } from 'class-transformer';
import { ClientHelper } from '../../helpers/client.helper';
import { InjectorHelper } from '../../helpers/injector.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';
import { WF_REGISTRY, WF_SERVICE_SETTINGS } from '../../services/workflow.service';
import { LogStatus } from '../enums/logstatus.enum';
import { WorkflowErrorType } from '../enums/workflowerrortype.enum';
import { WorkflowParameterType } from '../enums/workflowparametertype.enum';
import { WorkflowViewType } from '../enums/workflowviewtype.enum';
import { Layout } from '../layout.model';
import { LayoutElement } from '../layoutelement.model';
import { TranslatedString } from '../translatedstring.model';
import { DebugLogMessage, DebugSessionMessage, DebugSessionState, DebugSessionStateData, NextStep } from './debug.log.message';
import { BreakPointInfo, WorkflowDebugSettings } from './workflow.debug.settings';

// @dynamic
export class WorkflowData {
    @Type(() => WorkflowModuleData)
    Modules: WorkflowModuleData[] = [];
    ErrorType: WorkflowErrorType = WorkflowErrorType.None;
    @Type(() => WorkflowConnectorData)
    Connectors: WorkflowConnectorData[] = [];
    GenerateLog = false;
}
// @dynamic
export class WorkflowSaveObject extends WorkflowData {
    @Type(() => TranslatedString)
    Caption: TranslatedString;
    @Type(() => TranslatedString)
    Description: TranslatedString;
    @Type(() => WorkflowParameter)
    Parameters: WorkflowParameter[] = [];

    public static DeserializeModuleSettings(mod) {
        if (mod) {
            const retVal = Object.assign({}, mod);
            if (typeof mod.Settings === 'string') {
                const settings = JSON.parse(mod.Settings);
                retVal.Settings = settings;
                if (mod.SettingsType) {
                    const dataType = WF_SERVICE_SETTINGS.get(mod.SettingsType);
                    if (dataType) {
                        const dataTypeInstance: any = plainToClass(dataType, settings);
                        if (typeof dataTypeInstance.ExtendedDeserialize === 'function') {
                            dataTypeInstance.ExtendedDeserialize();
                        }
                        retVal.Settings = dataTypeInstance;
                    }
                }
            }
            return retVal;
        }
        return null;
    }

    public static SerializeModuleSettings(mod) {
        if (mod && mod.Settings) {
            if (typeof mod.Settings === 'object') {
                if (typeof mod.Settings.ExtendedSerialize === 'function') {
                    mod.Settings.ExtendedSerialize(mod);
                } else {
                    mod.Settings = JSON.stringify(mod.Settings);
                }
            }
        }
    }
}
export class WorkflowParameter {
    Name: string;
    Type: WorkflowParameterType = WorkflowParameterType.String;
    Required = false;
    Default;

    static GetPropertyTypeFromParameterType(wpt: WorkflowParameterType) {
        switch (wpt) {
            case WorkflowParameterType.Boolean:
                return 'System.Boolean';
            case WorkflowParameterType.DateTime:
                return 'System.DateTime';
            case WorkflowParameterType.Double:
                return 'System.Double';
            case WorkflowParameterType.Long:
                return 'System.Int64';
            case WorkflowParameterType.String:
                return 'System.String';
        }
        return '';
    }
}
// @dynamic
export class WorkflowModuleData {
    ID: number;
    SID: string = UUID.UUID();
    Module: string;
    Settings: any;
    SettingsType: string;
    XPos: number;
    YPos: number;
    @Type(() => WorkflowExpression)
    Expressions: WorkflowExpression[] = [];
    @Type(() => TranslatedString)
    Description: TranslatedString;
    BreakpointSet: boolean;
    ErrorType: WorkflowErrorType;
    Instance: any;
    Width: number;
    Height: number;
    Group: string;
    Icon: string;
    GroupIcon: string;
    ViewType: WorkflowViewType;
    Selected: boolean;
    toJSON() {
        const result = Object.assign({}, this);
        delete result.Instance;
        delete result.Width;
        delete result.Height;
        delete result.Group;
        delete result.ViewType;
        delete result.Icon;
        delete result.GroupIcon;
        delete result.Selected;
        return result;
    }
}
export class WorkflowExpression {
    Property: string;
    Formula: string;
}
export abstract class AServiceWorkflowData {
    ExtendedSerialize(module) {
        module.SettingsType = this.getTypeName();
        module.Settings = JSON.stringify(this);
    }

    abstract getTypeName(): string;
}
export class WorkflowConnectorData {
    ID: number;
    SID: string = UUID.UUID();
    ExitModule: number;
    ExitOption: number;
    EntryModule: number;
    EntryOption = 0;
}
// @dynamic
export class WorkflowDescription {
    ID;
    Caption: string;
    @Type(() => WorkflowData)
    Data: WorkflowData;
}
export class WorkflowStatus {
    ActualParameter: any;
    ActualSettings: any;
    ActualEntryOption = 0;
    Logger: WorkflowLogger;
    DebugStateHandler: WorkflowDebugStateHandler;
    WorkflowLayoutService = new WorkflowLayoutService();
    Context: Map<string, any> = new Map<string, any>();
    GenerateLog = false;
    private ForEachStack: LoopWorkflowModuleExecuter[] = [];

    static CopyContext(source: Map<string, any>, target: Map<string, any>) {
        if (source && target) {
            source.forEach((v, k) => {
                if (typeof v === 'undefined' || v === null) {
                    target.set(k, v);
                } else {
                    target.set(k, JSON.parse(JSON.stringify(v)));
                }
            });
        }
    }

    constructor(public WorkflowID: string, public WorkflowName: string, public ParentState?: WorkflowStatus) {
        this.Logger = new WorkflowLogger(this);
        if (ParentState) {
            if (ParentState.DebugStateHandler) {
                this.DebugStateHandler = new WorkflowDebugStateHandler(this);
            }
        } else {
            const client = WorkflowDebugHelper.DebugClient.getValue();
            if (client) {
                this.DebugStateHandler = new WorkflowDebugStateHandler(this);
            }
        }
    }

    AddLoop(ex: LoopWorkflowModuleExecuter) {
        this.ForEachStack.push(ex);
    }

    RemoveLoop(ex: LoopWorkflowModuleExecuter): boolean {
        const length = this.ForEachStack.length - 1;
        if (length > -1 && this.ForEachStack[length] === ex) {
            ex.reset();
            this.ForEachStack.splice(length, 1);
            return true;
        }
        return false;
    }

    GetActualLoop(): LoopWorkflowModuleExecuter {
        const length = this.ForEachStack.length - 1;
        if (length > -1) {
            return this.ForEachStack[length];
        }
        return null;
    }

    CopyInfos() {
        const status: any = {};
        status.ActualEntryOption = this.ActualEntryOption;
        status.ActualParameter = this.ActualParameter;
        status.Context = {};
        this.Context.forEach((v, k) => {
            if (typeof v === 'undefined' || v === null) {
                status.Context[k] = v;
            } else {
                status.Context[k] = JSON.parse(JSON.stringify(v));
            }
        });
        status.WorkflowID = this.WorkflowID;
        status.WorkflowName = this.WorkflowName;
        return status;
    }
}

export class WorkflowDebugStateHandler {
    private _DebugState: WorkflowDebugState;
    private _RunID = UUID.UUID();
    private _Subscriptions = [];
    private _SteppedIntoHandler: StepIntoHandler[] = [];
    private _Initialized = false;
    private _TopParentState: WorkflowDebugStateHandler;
    private _LastNextStep: NextStep;
    private _AdditionalData = {};
    private _WaitTimeout = 5 * 60 * 1000;
    private _TimeOutToken;

    get RunID() {
        const actHandler = this._TopParentState ?? this;
        return actHandler._RunID;
    }
    get LastNextStep() {
        const actHandler = this._TopParentState ?? this;
        return actHandler._LastNextStep;
    }

    constructor(private status: WorkflowStatus) {
        let parent = status.ParentState;
        if (parent) {
            while (parent.ParentState) {
                parent = parent.ParentState;
            }
            this._TopParentState = parent.DebugStateHandler;
        } else {
            this._DebugState = new WorkflowDebugState();
            this._SteppedIntoHandler.push(new StepIntoHandler('', -1));
        }
    }

    Init() {
        if (!this._Initialized) {
            const settings = WorkflowDebugHelper.DebugSettings.getValue();
            if (settings && settings.Workflows && settings.Workflows.ClientWorkflows) {
                const actSetting = settings.Workflows.ClientWorkflows.find(x =>
                    x.ID === this.status.WorkflowID &&
                    x.URL === this.status.WorkflowLayoutService.Layout['_key'] &&
                    x.Breakpoints
                );
                if (actSetting) {
                    const actHandler = this._TopParentState ?? this;
                    actHandler._DebugState.Breakpoints[this._RunID] = actSetting.Breakpoints.filter(x => x.BreakOnStart || x.BreakOnEnd);
                }
            }
            if (this._TopParentState) {
                this._Initialized = true;
                return;
            }
            if (settings && typeof settings.WaitTimeout === 'number' && settings.WaitTimeout >= 1) {
                this._WaitTimeout = settings.WaitTimeout * 60 * 1000;
            }
            this._Subscriptions.push(WorkflowDebugHelper.NextStep.subscribe(x => {
                if (x.RunID === this._RunID) {
                    this._SteppedIntoHandler.forEach(x => x.SteppedOver = false);
                    this._LastNextStep = x.NextStep;
                    this._DebugState.Breakpoints[x.StepIntoRunID] = x.Breakpoints;
                    switch (x.NextStep) {
                        case NextStep.Play:
                            this._DebugState.State = DebugState.WaitingForBreakPoint;
                            break;
                        case NextStep.Cancel:
                            this._DebugState.State = DebugState.Cancel;
                            break;
                        case NextStep.Next:
                            if (this._DebugState.LastMessage && this._DebugState.LastMessage.CanStepInto) {
                                this._SteppedIntoHandler[this._SteppedIntoHandler.length - 1].SteppedOver = true;
                                this._DebugState.State = DebugState.WaitingForBreakPoint;
                            } else {
                                this._DebugState.State = DebugState.NextStep;
                            }
                            break;
                        case NextStep.Into:
                            this._DebugState.State = DebugState.NextStep;
                            break;
                        case NextStep.Out:
                            this._DebugState.State = DebugState.WaitingForBreakPoint;
                            if (this._DebugState.LastMessage && this._DebugState.LastMessage.CanStepInto) {
                                // Wenn bei nem m�glichen StepIn StepOut gedr�ckt wurde, ist der vorletzte Handler gemeint
                                this._SteppedIntoHandler[this._SteppedIntoHandler.length - 2].SteppedOut = true;
                            } else {
                                this._SteppedIntoHandler[this._SteppedIntoHandler.length - 1].SteppedOut = true;
                            }
                            break;
                    }
                    clearTimeout(this._TimeOutToken);
                    this._TimeOutToken = null;
                    if (this._DebugState.ActualResolve) {
                        this._DebugState.ActualResolve();
                    }
                }
            }));
            this._Subscriptions.push(WorkflowDebugHelper.DebugClient.subscribe(x => {
                if (this._Initialized) {
                    clearTimeout(this._TimeOutToken);
                    this._TimeOutToken = null;
                    if (this._DebugState.ActualResolve) {
                        this._DebugState.ActualResolve();
                    }
                    this._DebugState = null;
                }
            }));
            this._Initialized = true;
        }
    }

    WaitForDebug(handler: IWaitForDebugHandler) {
        return new Promise<void>((resolve, reject) => {
            const actHandler = this._TopParentState ?? this;
            let data: DebugSessionStateData;
            if (actHandler._SteppedIntoHandler.length > 0) {
                const lastIndex = actHandler._SteppedIntoHandler.length - 1;
                if (lastIndex > 0) {
                    const lastHandler = actHandler._SteppedIntoHandler[lastIndex];
                    const removeHandler = handler.RemoveHandler(lastHandler);
                    if (removeHandler) {
                        if (lastHandler.SteppedOut || lastHandler.SteppedOver) {
                            actHandler._DebugState.State = DebugState.NextStep;
                        }
                        actHandler._SteppedIntoHandler.splice(lastIndex, 1);
                    }
                }
            }
            let loadData = false;
            if (actHandler._DebugState.State === DebugState.NextStep) {
                loadData = true;
            } else if (actHandler._DebugState.State === DebugState.WaitingForBreakPoint) {
                if (actHandler._DebugState.Breakpoints) {
                    const bpList = actHandler._DebugState.Breakpoints[this._RunID];
                    if (Array.isArray(bpList)) {
                        loadData = handler.CheckBreakPoints(bpList);
                    }
                }
            }
            if (loadData) {
                data = handler.GetStateData(this._RunID, actHandler._DebugState);
            }
            let canStepInto = false;
            const sih = handler.GetNewHandler();
            if (sih) {
                canStepInto = true;
                actHandler._SteppedIntoHandler.push(sih);
            }
            if (data) {
                const debugMessage = new DebugSessionState();
                debugMessage.ClientID = ClientHelper.ClientID;
                debugMessage.RunID = actHandler._RunID;
                debugMessage.StepIntoRunID = this._RunID;
                debugMessage.Data = data;
                debugMessage.Status = this.status.CopyInfos();
                //handler.SetAdditionalStatusInfos(debugMessage.Status);
                debugMessage.CanStepInto = canStepInto;
                debugMessage.CanStepOut = this._TopParentState != null || actHandler._SteppedIntoHandler.length > (canStepInto ? 2 : 1);;
                actHandler._DebugState.LastMessage = debugMessage;
                actHandler._DebugState.ActualResolve = resolve;
                const message = new DebugSessionMessage();
                message.Target = WorkflowDebugHelper.DebugClient.getValue();
                message.Type = 'WorkflowState';
                message.Content = debugMessage;
                WorkflowDebugHelper.SendDebugMessage(message);
                clearTimeout(actHandler._TimeOutToken);
                actHandler._TimeOutToken = setTimeout(() => {
                    if (actHandler._DebugState.ActualResolve) {
                        actHandler._DebugState.ActualResolve();
                    }
                    actHandler._LastNextStep = NextStep.Cancel;
                    actHandler._DebugState.State = DebugState.Cancel;
                    const message = new DebugSessionMessage();
                    message.Target = WorkflowDebugHelper.DebugClient.getValue();
                    message.Type = 'WorkflowFinished';
                    message.Content = {
                        RunID: actHandler._RunID
                    };
                    WorkflowDebugHelper.SendDebugMessage(message);
                }, actHandler._WaitTimeout);
            } else {
                resolve();
            }
        });
    }

    OnWorkflowFinished() {
        if (this._TopParentState) {
            return;
        }
        clearTimeout(this._TimeOutToken);
        this._TimeOutToken = null;
        if (this._DebugState.State !== DebugState.Cancel) {
            const message = new DebugSessionMessage();
            message.Target = WorkflowDebugHelper.DebugClient.getValue();
            message.Type = 'WorkflowFinished';
            message.Content = {
                RunID: this._RunID
            };
            WorkflowDebugHelper.SendDebugMessage(message);
        }
        this._Subscriptions.forEach(x => x.unsubscribe());
        this._Subscriptions = [];
    }

    UpdateState(step: NextStep) {
        const actHandler = this._TopParentState ?? this;
        if (actHandler._DebugState) {
            switch (step) {
                case NextStep.Play:
                    actHandler._DebugState.State = DebugState.WaitingForBreakPoint;
                    break;
                case NextStep.Cancel:
                    actHandler._DebugState.State = DebugState.Cancel;
                    break;
                case NextStep.Next:
                case NextStep.Out:
                    actHandler._DebugState.State = DebugState.NextStep;
                    break;
            }
        }
    }

    GetAndRemoveAdditionalData(key: string): string {
        const actHandler = this._TopParentState ?? this;
        const retVal = actHandler._AdditionalData[key];
        delete actHandler._AdditionalData[key];
        return retVal;
    }

    SetAdditionalData(key: string, data: string) {
        const actHandler = this._TopParentState ?? this;
        actHandler._AdditionalData[key] = data;
    }
}

export class WorkflowDebugState {
    State: DebugState = DebugState.WaitingForBreakPoint;
    LastMessage: DebugSessionState;
    ActualResolve;
    Breakpoints = {}
}

export enum DebugState {
    WaitingForBreakPoint,
    Cancel,
    NextStep
}

export class StepIntoHandler {
    get WorkflowID() {
        return this._WorkflowID;
    }
    get ModuleID() {
        return this._ModuleID;
    }
    SteppedOut = false;
    SteppedOver = false;

    constructor(private _WorkflowID: string, private _ModuleID: number) {
    }
}

export interface IWaitForDebugHandler {
    RemoveHandler(handler: StepIntoHandler): boolean;
    GetNewHandler(): StepIntoHandler;
    CheckBreakPoints(breakpoints: BreakPointInfo[]): boolean;
    GetStateData(runID: string, state: WorkflowDebugState): DebugSessionStateData;
    SetAdditionalStatusInfos(status);
}

export interface IAdditionalBreakPointHandler {
    get Token(): InjectionToken<{}>;
    get ComponentType();
    get EmptyData(): any;
    updateControlData(controlData, data, debugData);
    checkBreakPointDataForRemove(bpData): boolean;
}

@Directive()
export class WorkflowLogger {
    private static InstanceID = 0;
    private ErrorType: WorkflowErrorType = WorkflowErrorType.None;
    DebugSettings: WorkflowDebugSettings;
    ID = WorkflowLogger.InstanceID++;

    @Output() OnError = new EventEmitter<string>();
    @Output() OnWarning = new EventEmitter<string>();

    private Prefix = '';
    private StatusPrefix: string;
    private ActualModule: WorkflowModuleData;
    private Translate: TranslateService;

    constructor(private status: WorkflowStatus) {
        WorkflowDebugHelper.DebugSettings.subscribe(x => this.DebugSettings = x);
        let retVal = '(' + this.ID + ') ' + this.status.WorkflowName + ' -> ';
        if (this.status.ParentState) {
            retVal = this.status.ParentState.Logger.StatusPrefix + retVal;
        }
        this.StatusPrefix = retVal;
        this.Translate = InjectorHelper.InjectorInstance.get(TranslateService);
    }

    SetActualModule(module: WorkflowModuleData) {
        this.ActualModule = module;
        if (module) {
            const desc = WF_REGISTRY.get(module.Module);
            const description = this.Translate.instant(desc.Caption) + (module.Description ? ' (' + module.Description.toString() + ') ' : ' ') + '-> ';
            this.Prefix = description;
        } else {
            this.Prefix = '';
        }
    }

    SetErrorType(type: WorkflowErrorType) {
        this.ErrorType = type;
    }

    private getText(text) {
        return this.StatusPrefix + this.Prefix + text;
    }

    logInfo(text: string) {
        this.log(this.getText(text), LogStatus.Info);
    }

    logError(text: string) {
        let logText = this.getText(text);
        this.log(logText, LogStatus.Error);
        if (this.ErrorType == WorkflowErrorType.MessageCenter || (this.ActualModule && this.ActualModule.ErrorType == WorkflowErrorType.MessageCenter)) {
            NotificationHelper.Error('Exception while executing: ' + logText, '@@Workflow execution error');
        }
        this.OnError.emit(text);
    }

    logWarning(text: string) {
        let logText = this.getText(text);
        this.log(logText, LogStatus.Warning);
        if (this.ErrorType == WorkflowErrorType.MessageCenter || (this.ActualModule && this.ActualModule.ErrorType == WorkflowErrorType.MessageCenter)) {
            NotificationHelper.Warn('Exception while executing: ' + logText, '@@Workflow execution error');
        }
        this.OnWarning.emit(text);
    }

    private log(text: string, logStatus: LogStatus) {
        return new Promise<void>((resolve, reject) => {
            if (this.DebugSettings && this.DebugSettings.ShowWorkflowLog) {
                const date = new Date();
                let logText = '[' + date.toJSON() + '] ' + text;
                switch (logStatus) {
                    case LogStatus.Error:
                        logText += ' (Error)';
                        break;
                    case LogStatus.Warning:
                        logText += ' (Warning)';
                        break;
                }
                const message = new DebugLogMessage();
                message.Message = logText;
                message.Type = logStatus;
                message.Timestamp = date;
                message.WorkflowID = this.status.WorkflowID;
                if (this.ActualModule) {
                    message.ModuleID = this.ActualModule.ID + '';
                }
                message.Status = this.status.CopyInfos();
                WorkflowDebugHelper.NextLog.next(message);
            }
            resolve();
        });
    }
}
export class WorkflowLayoutService {

    private _Layout: Layout;
    get Layout() { return this._Layout; }
    private _ActualElementName: string;
    private _ActualElement: LayoutElement;
    get ActualElement() { return this._ActualElement; }
    private _VariablesByName = {};
    private _ElementsByName;

    private static FillElements(elements: LayoutElement[], state, templateName: string, parentName: string): boolean {
        
        let retVal = false;
        if (elements) {
            elements.forEach(x => {
                let checkName;
                if (x.Name) {
                    checkName = templateName ? templateName + '.' + x.Name : x.Name;
                    if (state.ElementsObject[checkName]) {
                        state.DoubleNames[checkName] = true;
                    } else {
                        state.ElementsObject[checkName] = {
                            Element: x,
                            ParentName: parentName
                        };
                    }
                }
                if (x === state.ActualElement) {
                    retVal = true;
                    state.ActualElementName = checkName;
                }
                if (x.ElementType === 'repeat' && x.Element) {
                    const repeatElements = x.Element.RepeatElements;
                    if (repeatElements.length > 0) {
                        let elemObj;
                        let elemObjFound = false;
                        repeatElements.forEach((re, i) => {
                            const actObj = {};
                            const newState = {
                                ElementsObject: actObj,
                                ActualElement: state.ActualElement,
                                DoubleNames: state.DoubleNames,
                                ActualElementName: null
                            };
                            const actFound = WorkflowLayoutService.FillElements(re.Elements, newState, templateName, checkName);
                            if (actFound) {
                                elemObj = actObj;
                                elemObjFound = true;
                                retVal = true;
                                state.ActualElementName = newState.ActualElementName;
                            } else if (!elemObjFound) {
                                elemObj = actObj;
                            }
                        });
                        Object.keys(elemObj).forEach(name => {
                            if (state.ElementsObject[name]) {
                                state.DoubleNames[name] = true;
                            } else {
                                state.ElementsObject[name] = elemObj[name];
                            }
                        });
                    }
                } else {
                    let tmpName = templateName;
                    if (x.ElementType === 'template') {
                        if (tmpName) {
                            tmpName += '.' + (x.Name || '');
                        } else {
                            tmpName = (x.Name || '');
                        }
                    }
                    const nextLevel = WorkflowLayoutService.FillElements(x.Elements, state, tmpName, checkName);
                    if (nextLevel) {
                        retVal = true;
                    }
                }
            });
        }
        return retVal;
    }

    init(layout: Layout, actualElement: LayoutElement) {
        this._Layout = layout;
        this._ActualElement = actualElement;
    }

    getElementVariables() {
        if (!this._ElementsByName) {
            this.initElements();
        }
        return this.getVariablesByName(this._ActualElementName).Variables;
    }

    getVariablesByName(name: string): VariableListResult {
        if (typeof name === 'string') {
            let retVal: VariableListResult = this._VariablesByName[name];
            if (!retVal) {
                retVal = new VariableListResult();
                const elem = this.getElementByNameInternal(name);
                if (elem) {
                    retVal.ElemFound = true;
                    this.getVariablesInternal(elem, retVal.Variables);
                }
                this._VariablesByName[name] = retVal;
            }
            return retVal;
        }
        return new VariableListResult();
    }

    private getVariablesInternal(elementEntry, variableList) {
        if (elementEntry) {
            if (elementEntry.Element.Variables) {
                variableList.push(...elementEntry.Element.Variables);
            }
            if (elementEntry.ParentName) {
                const parent = this.getElementByNameInternal(elementEntry.ParentName);
                this.getVariablesInternal(parent, variableList);
            }
        }
    }

    private getElementByNameInternal(name: string) {
        if (!this._ElementsByName) {
            this.initElements();
        }
        let property = '' + name;
        if (this._Layout && this._Layout['ElementType'] == 'template' && !property.startsWith(this._Layout.Name)) {
            property = this._Layout.Name + '.' + name;
        }
        return this._ElementsByName[property];
    }

    getElementByName(name: string) {
        const elem = this.getElementByNameInternal(name);
        if (elem) {
            return elem.Element;
        }
        return null;
    }

    private initElements() {
        const ebn = {};
        if (this._Layout) {
            const state = {
                ElementsObject: ebn,
                ActualElement: this._ActualElement,
                DoubleNames: {},
                ActualElementName: null
            };
            const layout: any = this._Layout;
            WorkflowLayoutService.FillElements([layout], state, null, null);
            this._ActualElementName = state.ActualElementName;
        }
        this._ElementsByName = ebn;
    }
}
export class VariableListResult {
    ElemFound = false;
    Variables = [];
}
export class WorkflowModuleExecuter {
    ModulID: number;

    async execute(status: WorkflowStatus): Promise<number> {
        status.ActualParameter = null;
        return -1;
    }
}
export abstract class LoopWorkflowModuleExecuter extends WorkflowModuleExecuter {
    abstract reset();
}
