import { Component, Directive } from '@angular/core';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { LayoutService } from '../../../services/layout.service';
import { NavigationService } from '../../../services/navigation.service';
import {
    WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry
} from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-preventnavigation-settings',
    templateUrl: './preventnavigation.settings.html'
})
export class PreventNavigationSettings extends WorkflowDialogContent {
    static ModuleID = 'preventNavigationWFModule';

    Message;

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            this.Message = data.Message;
        }
    }

    getResult(): any {
        let retVal = new PreventNavigationSettingsData();
        retVal.Message = this.Message;
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = PreventNavigationSettings.ModuleID;
        reg.Caption = '@@Navigation verhindern';
        reg.GroupID = 'navigation';
        reg.Index = 40;
        reg.SettingsControl = PreventNavigationSettings;
        reg.SettingsTypeHelper = new PreventNavigationSettingsDataHelper();
        reg.Executer = PreventNavigationModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }
}

// @dynamic
export class PreventNavigationSettingsData extends AServiceWorkflowData {
    Message: string = "";
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Navigation.PreventNavigationSettingsData';
    }
}
export class PreventNavigationSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new PreventNavigationSettingsData();
    }
}

export class PreventNavigationModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            LayoutService.PreventBrowserClose.next(true);
            NavigationService.DisableBack.next(status.ActualSettings.Message);    
            return 0;
        } else {
            status.Logger.logError('preventNavigationWFModule modul: No settings found.');
        }

        return super.execute(status);
    }
}


@Directive({
    selector: 'wf-allownavigation-settings'
})
export class AllowNavigationSettings extends WorkflowDialogContent {
    static ModuleID = 'AllowNavigationWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = AllowNavigationSettings.ModuleID;
        reg.Caption = '@@Navigation erlauben';
        reg.GroupID = 'navigation';
        reg.Index = 40;
        reg.SettingsTypeHelper = new AllowNavigationSettingsDataHelper();
        reg.Executer = AllowNavigationModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }
}

export class AllowNavigationSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return null;
    }
}

export class AllowNavigationModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        LayoutService.PreventBrowserClose.next(false);
        NavigationService.DisableBack.next(null);
        return 0;
    }
}