import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { MeasureTaskExecuter } from '../../../../models/tasks/formattasks/measure.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { AMeasurePanel, AMeasureTaskSettingsHelper } from '../../a.task.panel';

@Component({
    selector: 'measure-formatting-task-panel',
    templateUrl: './measure.formatting.task.panel.html'
})
export class MeasureFormattingTaskPanel extends AMeasurePanel {
    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = MeasureTaskExecuter.TaskID;
        reg.Caption = '@@Wert';
        reg.SettingsControl = MeasureFormattingTaskPanel;
        reg.SettingsHelper = new MeasureFormattingTaskSettingsHelper();
        reg.Executer = MeasureTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }

}

export class MeasureFormattingTaskSettingsHelper extends AMeasureTaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsTableObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Wertformatierung');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Wertformatierung');
    }
}
