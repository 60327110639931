import { PrimitiveFormula, FormulaGroups } from './iformula';
import { ValueAndType, FormulaStatics } from '../basic/formulaEditor.model';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { divide, equal } from 'mathjs';
import { ValueType } from '../enums/valuetype.enum';
import { OperationType } from '../enums/operationtype.enum';

export class Division extends PrimitiveFormula {
    static StaticID = 'cdbf5044-69f7-45a8-bf60-01855f352f4d';

    constructor() {
        super();
        this._ID = Division.StaticID;
        this._Name = 'Divison';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
    }

    CanCalcNulls(): boolean {
        return true;
    }
    Group(): string {
        return FormulaGroups.BasicOperations;
    }
    OrderID(): number {
        return 4;
    }
    OperationType(): OperationType {
        return OperationType.Division;
    }
    OperationValue(): string {
        return '/';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args) {
            let first = true;
            let actVal = null;
            args.some((item) => {
                if (first) {
                    if (typeof item.Value === 'undefined' || item.Value == null) {
                        return true;
                    }
                    if (maxType === ValueType.Long || maxType === ValueType.Double || maxType === ValueType.String) {
                        actVal = item.Value;
                    } else {
                        return true;
                    }
                } else {
                    if (typeof item.Value !== 'undefined' && item.Value != null &&
                        (maxType === ValueType.Long || maxType === ValueType.Double)) {
                        if (equal(item.Value, 0)) {
                            actVal = null;
                            return true;
                        } else {
                            actVal = divide(actVal, item.Value);
                        }
                    } else {
                        actVal = null;
                        return true;
                    }
                }
                first = false;
                return false;
            });
            if (actVal !== null) {
                retVal.Value = actVal;
                retVal.Type = ValueType.Double;
            }
        }
        return retVal;
    }
}
