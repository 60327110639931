import { add } from 'mathjs';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { Axis, AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { AxisType } from '../../enums/query.enum';
import { SortType, TaskAxisLevel } from '../../enums/sortType.enum';
import { ATask, ITaskExecuter } from '../atask.model';
import { TopDownTaskExecuter } from './topdown.model';

export class DataSortTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    MeasureUniqueID: number;
    LevelUniqueID;

    Attribute: string;
    Ascending: boolean ;
    SortType: SortType.Caption;
    RecursionFilteringAxis: AxisType;

    Execute() {
    }
}

export const SortNumberArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export class DataSortTaskExecuter implements ITaskExecuter {

    MeasureUniqueID = -1;
    LevelUniqueID = -1;

    Ascending = false;
    RecursionFilteringAxis: AxisType;
    SortType: SortType;
    Attribute: string;

    Result: MultiResult;
    DataDescription: DataDescription;

    private static SortAlphaNumeric(a, b) {
        if (typeof a.Value === 'string' && typeof b.Value === 'string') {
            const aLength = a.Value.length;
            const bLength = b.Value.length;
            let aStep = 0;
            let bStep = 0;
            while (aStep < aLength && bStep < bLength) {
                let aChar = a.Value[aStep];
                const aIsNumber = SortNumberArray.some(x => x === aChar);
                let aPart = '';
                do {
                    aPart += aChar;
                    aStep++;
                    if (aStep < aLength) {
                        aChar = a.Value[aStep];
                    } else {
                        break;
                    }
                } while (SortNumberArray.some(x => x === aChar) === aIsNumber);

                let bChar = b.Value[bStep];
                const bIsNumber = SortNumberArray.some(x => x === bChar);
                let bPart = '';
                do {
                    bPart += bChar;
                    bStep++;
                    if (bStep < bLength) {
                        bChar = b.Value[bStep];
                    } else {
                        break;
                    }
                } while (SortNumberArray.some(x => x === bChar) === bIsNumber);

                if (aIsNumber && bIsNumber) {
                    const diff = parseInt(aPart, 10) - parseInt(bPart, 10);
                    if (diff !== 0) {
                        return diff;
                    }
                } else {
                    const aToLower = aPart.toLowerCase();
                    const bToLower = bPart.toLowerCase();
                    if (aToLower < bToLower) {
                        return -1;
                    } else if (bToLower < aToLower) {
                        return 1;
                    }
                }
            }
            return aLength - bLength;
        }
        return 0;
    }

    private static SortList(list: AxisNode[], oppositeAxis: Axis, measure: AxisNode, result: MultiResult,
        cellAction, ascending: boolean, sortType: SortType, recursive: boolean): AxisNode[] {
        const childList: AxisNode[] = [];
        if (list && list.length > 0) {
            const sortList = [];
            if (list.length === 1) {
                sortList.push({
                    Node: list[0]
                });
            } else if (sortType === SortType.Measure) {
                list.forEach(lNode => {
                    let actVal = null;
                    oppositeAxis.Nodes.forEach(oppNode => {
                        const cellVal = cellAction(result, lNode, oppNode, measure, false);
                       
                            if (cellVal && cellVal.InternalValue != null) {
                                if (actVal == null) {
                                    actVal = cellVal.InternalValue;
                                } else {
                                    actVal = add(actVal, cellVal.InternalValue);
                                }
                            }
                        
                    });
                    sortList.push({
                        Node: lNode,
                        Value: actVal
                    });
                });
                sortList.sort(TopDownTaskExecuter.Sort);
            } else {
                switch (sortType) {
                    case SortType.Attribute:
                        // TODO
                        break;
                    case SortType.Caption:
                        list.forEach(lNode => {
                            sortList.push({
                                Node: lNode,
                                Value: lNode.Caption
                            });
                        });
                        break;
                    case SortType.Code:
                        list.forEach(lNode => {
                            sortList.push({
                                Node: lNode,
                                Value: lNode.Key
                            });
                        });
                        break;
                }
                sortList.sort(DataSortTaskExecuter.SortAlphaNumeric);
            }
            if (ascending) {
                sortList.reverse();
            }
            if (recursive) {
                sortList.forEach(entry => {
                    entry.Node.IsLastChild = false;
                    childList.push(entry.Node);
                    entry.Node.Children = DataSortTaskExecuter.SortList(entry.Node.Children,
                        oppositeAxis, measure, result, cellAction, ascending, sortType, recursive);
                });
            } else {
                sortList.forEach(entry => {
                    entry.Node.IsLastChild = false;
                    childList.push(entry.Node);
                });
            }
            childList[childList.length - 1].IsLastChild = true;
        }
        return childList;
    }

    async Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.Ascending === 'boolean') {
                this.Ascending = settings.Ascending;
            }
            if (typeof settings.SortType === 'number') {
                this.SortType = settings.SortType;
            }
            if (typeof settings.RecursionFilteringAxis === 'number') {
                this.RecursionFilteringAxis = settings.RecursionFilteringAxis;
            }
            if (typeof settings.Attribute === 'string') {
                this.Attribute = settings.Attribute;
            }
        }
    }


    Execute() {
        const isAllLevelsOfAxisX = this.LevelUniqueID === TaskAxisLevel.XAxis;
        const isAllLevelsOfAxisY = this.LevelUniqueID === TaskAxisLevel.YAxis;
        const isAllLevelsOfAxis = isAllLevelsOfAxisX || isAllLevelsOfAxisY;

        if (this.Result && this.DataDescription && (isAllLevelsOfAxis || this.LevelUniqueID >= -1)
            && this.MeasureUniqueID >= -1) {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                if (isAllLevelsOfAxisX) {
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.X_Axis) { // Area auf X suchen
                        const noLevelIndex = {
                            Index: 0
                        };
                        const axisNodes = [];
                        this.DataDescription.XLevelNodes.Areas.forEach(area => {
                            const areaNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex);
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                const sorted = DataSortTaskExecuter.SortList(areaNodes.Nodes, this.Result.YAxis, measure, this.Result,
                                    MultiResultHelper.GetValueX, this.Ascending, this.SortType, true);
                                if (sorted.length > 0) {
                                    sorted[sorted.length - 1].IsLastChild = false;
                                    axisNodes.push(...sorted);
                                }
                            } else {
                                areaNodes.Nodes.forEach(x => {
                                    x.IsLastChild = false;
                                    axisNodes.push(x);
                                });
                            }
                        });
                        if (axisNodes.length > 0) {
                            axisNodes[axisNodes.length - 1].IsLastChild = true;
                        }
                        this.Result.XAxis.Nodes = axisNodes;
                    } else {
                        this.Result.XAxis.Nodes = DataSortTaskExecuter.SortList(this.Result.XAxis.Nodes, this.Result.YAxis, measure,
                            this.Result, MultiResultHelper.GetValueX, this.Ascending, this.SortType, true);
                    }
                } else if (isAllLevelsOfAxisY) {
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.Y_Axis) { // Area auf Y suchen
                        const noLevelIndex = {
                            Index: 0
                        };
                        const axisNodes = [];
                        this.DataDescription.YLevelNodes.Areas.forEach(area => {
                            const areaNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex);
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                const sorted = DataSortTaskExecuter.SortList(areaNodes.Nodes, this.Result.XAxis, measure, this.Result,
                                    MultiResultHelper.GetValueY, this.Ascending, this.SortType, true);
                                if (sorted.length > 0) {
                                    sorted[sorted.length - 1].IsLastChild = false;
                                    axisNodes.push(...sorted);
                                }
                            } else {
                                areaNodes.Nodes.forEach(x => {
                                    x.IsLastChild = false;
                                    axisNodes.push(x);
                                });
                            }
                        });
                        if (axisNodes.length > 0) {
                            axisNodes[axisNodes.length - 1].IsLastChild = true;
                        }
                        this.Result.YAxis.Nodes = axisNodes;
                    } else {
                        this.Result.YAxis.Nodes = DataSortTaskExecuter.SortList(this.Result.YAxis.Nodes, this.Result.XAxis, measure,
                            this.Result, MultiResultHelper.GetValueY, this.Ascending, this.SortType, true);
                    }
                } else {
                    let levelAxis, oppositeAxis, cellAction, levelArea, measureArea;
                    this.DataDescription.XLevelNodes.Areas.some(a => {
                        if (a.Tuples) {
                            a.Tuples.some(t => {
                                if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                    levelArea = a;
                                    levelAxis = this.Result.XAxis;
                                    oppositeAxis = this.Result.YAxis;
                                    cellAction = MultiResultHelper.GetValueX;
                                    return true;
                                }
                                return false;
                            });
                        }
                        if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                            measureArea = a;
                        }
                    });

                    if (levelArea) { // Level auf X
                        if (measureArea) {
                            if (levelArea !== measureArea) {
                                // TODO: Fehler ausgeben
                                return;
                            }
                        } else {
                            if (!this.DataDescription.YLevelNodes.Areas.some(a => {
                                if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                    measureArea = a;
                                    return true;
                                }
                                return false;
                            })) {
                                // TODO: Fehler ausgeben (Wert nicht gefunden)
                                return;
                            }
                        }
                    } else {
                        if (measureArea) {
                            this.DataDescription.YLevelNodes.Areas.some(a => {
                                if (a.Tuples) {
                                    return a.Tuples.some(t => {
                                        if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                            levelAxis = this.Result.YAxis;
                                            oppositeAxis = this.Result.XAxis;
                                            cellAction = MultiResultHelper.GetValueY;
                                            return true;
                                        }
                                        return false;
                                    });
                                }
                                return false;
                            });
                        } else {
                            this.DataDescription.YLevelNodes.Areas.forEach(a => {
                                if (a.Tuples) {
                                    a.Tuples.some(t => {
                                        if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                            if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                                levelAxis = this.Result.YAxis;
                                                oppositeAxis = this.Result.XAxis;
                                                cellAction = MultiResultHelper.GetValueY;
                                            } else {
                                                // TODO: Fehler ausgeben (Wert nicht in Area des Levels)
                                            }
                                            return true;
                                        }
                                        return false;
                                    });
                                }
                            });
                        }
                    }
                    if (levelAxis && oppositeAxis && cellAction) {
                        const sumNodes = MultiResultHelper.GetNodeSections(levelAxis, this.LevelUniqueID);
                        for (let i = 0; i < sumNodes.length; i++) {
                            const sn = sumNodes[i];
                            if (sn.length > 0) {
                                const childList = DataSortTaskExecuter.SortList(sn, oppositeAxis, measure,
                                    this.Result, cellAction, this.Ascending, this.SortType, false);
                                if (childList[0].Parent) {
                                    childList[0].Parent.Children = childList;
                                } else {
                                    const axisNodes = [];
                                    let found = false;
                                    levelAxis.Nodes.forEach(x => {
                                        if (x.UniqueID === this.LevelUniqueID) {
                                            if (!found) {
                                                childList[childList.length - 1].IsLastChild = false;
                                                axisNodes.push(...childList);
                                            }
                                            found = true;
                                        } else {
                                            x.IsLastChild = false;
                                            axisNodes.push(x);
                                        }
                                    });
                                    for (let j = axisNodes.length - 1; j >= 0; j--) {
                                        const an = axisNodes[j];
                                        if (an.MemberId !== 'MaCTopDownRemainder') {
                                            an.IsLastChild = true;
                                            break;
                                        }
                                    }
                                    levelAxis.Nodes = axisNodes;
                                }
                            }
                        }
                    } else {
                        // TODO: Fehler ausgeben, Level nicht gefunden
                    }
                }
            }
        }
    }
}

