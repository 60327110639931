import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { Gradient } from '../../../../models/style/gradient.model';
import { BaseAction } from '../../base.action';

@Component({
    selector: 'backgroundcolor-action',
    templateUrl: './backgroundcolor.action.html',
    styleUrls: ['./backgroundcolor.action.css'],
})
export class BackgroundColorAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'backgroundcolor';

    FillStyle = {}

    //#region SelectedBackgroundColor
    @Input()
    get SelectedBackgroundColor() {
        if (this.SelectedItemValue) {
            return this.SelectedItemValue.BackgroundColor;
        }
        return null;
    }
    set SelectedBackgroundColor(val) {
        if (this.SelectedItemValue) {
            this.SelectedItemValue.BackgroundColor = val;
        }
        this.updateFillStyle();
        this.SelectedBackgroundColorChange.emit(val);
    }

    @Output() SelectedBackgroundColorChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {
    }

    ExecuteAction() {
    }

    Visibility() {
        return true;
    }

    updateFillStyle() {
        const fillStyle = {};
        if (this.SelectedItemValue) {
            const gradient = Gradient.getStyleObject(this.SelectedItemValue.BackgroundColor);
            if (gradient) {
                const bgc = gradient['background-color'];
                if (bgc) {
                    fillStyle['fill'] = bgc;
                } else {
                    const bg = gradient['background'];
                    if (bg) {
                        fillStyle['fill'] = bg;
                    }
                }
            }
        }
        this.FillStyle = fillStyle;
    }

    OnItemSet() {
        this.updateFillStyle();
    }
}
