import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService } from '../../../services/datamodel.service';
import { ContainerRelationEditDialog } from '../dialogs/containerrelation.edit.dialog';

@Component({
    selector: 'containerrelation-overview',
    templateUrl: './containerrelation.overview.html',
    styleUrls: ['./containerrelation.overview.css']
})
export class ContainerRelationOverview {
    Relations = [];

    //#region Container
    ContainerValue;

    @Input()
    get Container() {
        return this.ContainerValue;
    }
    set Container(val) {
        this.ContainerValue = val;
        this.update();
        this.ContainerChange.emit(this.ContainerValue);
    }

    @Output() ContainerChange = new EventEmitter<any>();
    //#endregion

    constructor(private dataService: DataModelService, private settingsService: DataSettingsService, private cdRef: ChangeDetectorRef) {
    }

    update() {
        this.Relations = [];
        if (this.Container && this.Container.SID) {
            this.settingsService.GetRelationInfosForContainer(this.Container.SID).subscribe((result) => {
                if (result) {
                    this.Relations = result;
                    this.cdRef.detectChanges();
                }
            });
        }
    }

    newRel() {
        this.showEditDialog(null);
    }

    editRel(sid) {
        this.showEditDialog(sid);
    }

    showEditDialog(sid) {
        if (this.Container && this.Container.DataModelID) {
            const args = {
                DataModelID: this.Container.DataModelID,
                IsNew: false,
                SID: null,
                PrimaryContainer: null
            };
            if (sid) {
                args.SID = sid;
            } else {
                args.IsNew = true;
                if (this.Container.SID) {
                    args.PrimaryContainer = this.Container.SID;
                }
            }
            BaseDialog.ShowDialog({
                ContentType: ContainerRelationEditDialog,
                InitArgs: args,
                Handler: (rel) => {
                    this.saveRelation(rel, args.IsNew);
                    return true;
                },
                Title: args.IsNew ? '@@Neue Relation' : '@@Relation bearbeiten'
            });
        }
    }

    saveRelation(rel, isNew) {
        if (rel) {
            const crsr = {
                Relation: rel,
                ActContainer: null
            };
            if (this.Container) {
                crsr.ActContainer = this.Container.SID;
            }
            this.settingsService.SaveRelation(crsr).subscribe((result) => {
                if (result) {
                    NotificationHelper.Success('@@Relation erfolgreich gespeichert', '@@Relation speichern');
                    let index;
                    if (this.Relations.some((r, i) => {
                        if (r.SID === result.SID) {
                            index = i;
                            return true;
                        }
                        return false;
                    })) {
                        this.Relations.splice(index, 1, result);
                    } else {
                        this.Relations.push(result);
                    }
                } else if (!isNew && rel.SID) {
                    // Wenn kein Ergebnis zur�ckkommt, bezieht sich Relation auf andere Tabelle -> aus Liste l�schen
                    let index;
                    if (this.Relations.some((r, i) => {
                        if (r.SID === rel.SID) {
                            index = i;
                            return true;
                        }
                        return false;
                    })) {
                        this.Relations.splice(index, 1);
                    }
                }
            });
        }
    }

    deleteRel(i, sid) {
        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Sind Sie sicher, dass Sie die Relation loeschen wollen?'),
            new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                if (x === MessageBoxResult.Yes) {
                    this.dataService.DeleteContainer(sid).subscribe();
                    NotificationHelper.Success('@@Relation erfolgreich geloescht', '@@Relation loeschen');
                    this.Relations.splice(i, 1);
                }
            });
    }
}
