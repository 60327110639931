import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs'; // Import Subscription
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { GradientType } from '../../../models/enums/gradienttype.enum';
import { Border } from '../../../models/style/border.model';
import { BorderSide } from '../../../models/style/borderside.model';
import { Color } from '../../../models/style/color.model';
import { Gradient } from '../../../models/style/gradient.model';
import { GradientStopColor } from '../../../models/style/gradientstopcolor.model';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'colorpicker-theme-control',
    templateUrl: './colorpicker.theme.control.html',
    styleUrls: ['./colorpicker.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorpickerThemeControl extends BaseThemeControl implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = []; // Array to hold subscriptions

    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption(): string {
        return this.CaptionValue;
    }
    set Caption(val: string) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<string>();
    //#endregion
    //#region Icon
    IconValue: string;
    @Input()
    get Icon(): string {
        return this.IconValue;
    }
    set Icon(val: string) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
    }

    @Output() IconChange = new EventEmitter<string>();
    //#endregion
    //#region ColorType
    ColorTypeValue: string = 'Color';
    @Input()
    get ColorType(): string {
        return this.ColorTypeValue;
    }
    set ColorType(val: string) {
        this.ColorTypeValue = val;
        this.ColorTypeChange.emit(this.ColorTypeValue);
    }

    @Output() ColorTypeChange = new EventEmitter<string>();
    //#endregion

    //#region ColorSet
    ColorSetValue: boolean = false;
    get ColorSet(): boolean {
        return this.ColorSetValue;
    }
    set ColorSet(val: boolean) {
        this.ColorSetValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    //#region Active
    ActiveValue: boolean = false;
    get Active(): boolean {
        return this.ActiveValue;
    }
    set Active(val: boolean) {
        this.ActiveValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    get Color() {
        if (this.DataSourceInternal) {
            if (this.DataSourceInternal instanceof Border || this.ColorType == 'Border') {
                if (this.DataSourceInternal.BottomBorder && this.DataSourceInternal.BottomBorder.Color && this.DataSourceInternal.BottomBorder.Color.Colors && this.DataSourceInternal.BottomBorder.Color.Colors.length == 1) {
                    return this.DataSourceInternal.BottomBorder.Color.Colors[0].Color;
                }
            } else if (this.DataSourceInternal instanceof Gradient || this.ColorType == 'Gradient') {
                if (this.DataSourceInternal.Colors && this.DataSourceInternal.Colors.length == 1) {
                    return this.DataSourceInternal.Colors[0].Color;
                }
                return null;
            } else {
                return this.DataSourceInternal;
            }
        } else {
            return null;
        }
    }

    InitComplete = false;

    ngOnInit(): void {
        // Store subscriptions in the array
        this.subscriptions.push(this.DataSourceInternalChange.subscribe((value) => {
            this.RefreshInternalValues(true);
        }));
        this.subscriptions.push(this.SelectedItemChange.subscribe((value) => {
            this.RefreshInternalValues(true);
        }));
        this.RefreshInternalValues(true);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    RefreshInternalValues(setActive) {
        if (this.UserChange) {
            this.UserChange = false;
        } else {
            if (setActive) {
                this.Active = false;
            }
            this.updateFillStyle();
            if (Color.GetStyleText(this.Color) != '' && setActive) {
                this.Active = true;
            }
            if (this.Color && (this.Color.R || this.Color.G || this.Color.B)) {
                this.ColorSet = true;
            }
            this.cdRef.detectChanges();
        }
    }

    menuKeyDown(ev) {
        MenuKeyDownHelper.onKeyDown(ev);
    }
    FillStyle = {};
    UserChange = false;

    ActiveChanged(val) {
        this.UserChange = true;
        if (!val) {
            if (this.DataSourceInternal instanceof Border || this.ColorType == 'Border') {
                this.setBorderSide(this.SelectedItemValue.TopBorder, null);
                this.setBorderSide(this.SelectedItemValue.RightBorder, null);
                this.setBorderSide(this.SelectedItemValue.BottomBorder, null);
                this.setBorderSide(this.SelectedItemValue.LeftBorder, null);
                this.StyleChanged.emit();
            } else if (this.DataSourceInternal instanceof Gradient || this.ColorType == 'Gradient') {
                this.DataSourceInternal.Colors = [];
                this.StyleChanged.emit();
            } else {
                this.DataSourceInternal = new Color();
            }
            this.ColorSet = false;
            this.updateFillStyle();
        }
    }

    updateFillStyle() {
        const style = {};

        if (this.Color) {
            style['fill'] = Color.GetStyleText(this.Color);
            this.ColorSet = Color.GetStyleText(this.Color) != '';
        }
        this.FillStyle = style;
        this.cdRef.detectChanges();
    }

    setBorderSide(side, color) {
        if (side) {
            if (color) {
                const cs = new GradientStopColor();
                cs.Color = color;
                if (!side.Colors) {
                    side.Colors = [];
                }
                side.Colors.push(cs);
            } else {
                side.Colors = [];
            }
        }
    }

    SetDataSource(color) {
        if (color) {
            if (!this.DataSourceInternal) {
                switch (this.ColorType) {
                    case 'Border': this.DataSourceInternal = new Border(); break;
                    case 'Gradient': this.DataSourceInternal = new Gradient(); break;
                    case 'Color': this.DataSourceInternal = new Color(); break;
                }
            }
            if (this.DataSourceInternal instanceof Border || this.ColorType == 'Border') {
                this.setBorderSide(this.SelectedItemValue.TopBorder, color);
                this.setBorderSide(this.SelectedItemValue.RightBorder, color);
                this.setBorderSide(this.SelectedItemValue.BottomBorder, color);
                this.setBorderSide(this.SelectedItemValue.LeftBorder, color);
                this.StyleChanged.emit();
            } else if (this.DataSourceInternal instanceof Gradient || this.ColorType == 'Gradient') {
                this.DataSourceInternal.Type = GradientType.Solid;
                this.DataSourceInternal.Colors = [];
                const cs = new GradientStopColor();
                cs.Color = color;
                this.DataSourceInternal.Colors.push(cs);
                this.StyleChanged.emit();
            } else if (this.DataSourceInternal instanceof Color || this.ColorType == 'Color') {
                this.DataSourceInternal = color;
            }

            this.ColorSet = true;
        } else {
            if (this.DataSourceInternal instanceof Border || this.ColorType == 'Border') {
                this.setBorderSide(this.SelectedItemValue.TopBorder, null);
                this.setBorderSide(this.SelectedItemValue.RightBorder, null);
                this.setBorderSide(this.SelectedItemValue.BottomBorder, null);
                this.setBorderSide(this.SelectedItemValue.LeftBorder, null);
                this.StyleChanged.emit();
            } else if (this.DataSourceInternal instanceof Gradient || this.ColorType == 'Gradient') {
                this.DataSourceInternal.Colors = [];
                this.StyleChanged.emit();
            } else {
                this.DataSourceInternal = null;
            }

            this.ColorSet = false;
        }
        this.updateFillStyle();
    }

    clearValue(event): void {
        event.stopPropagation();
        if (this.DataSourceInternal instanceof Border || this.ColorType == 'Border') {
            this.setBorderSide(this.SelectedItemValue.TopBorder, null);
            this.setBorderSide(this.SelectedItemValue.RightBorder, null);
            this.setBorderSide(this.SelectedItemValue.BottomBorder, null);
            this.setBorderSide(this.SelectedItemValue.LeftBorder, null);
            this.StyleChanged.emit();
        } else if (this.DataSourceInternal instanceof Gradient || this.ColorType == 'Gradient') {
            this.DataSourceInternal.Colors = [];
            this.StyleChanged.emit();
        } else {
            this.DataSourceInternal = null;
        }
        this.ColorSet = false;
        this.updateFillStyle();
    }
}
