import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';

@Component({
    selector: 'object-property-chooser',
    templateUrl: './object.property.chooser.html',
    styleUrls: ['./object.property.chooser.css']
})
export class ObjectPropertyChooser implements OnInit {
    Nodes = [];

    //#region PropertyName
    PropertyNameValue: string;

    @Input()
    get PropertyName() {
        return this.PropertyNameValue;
    }
    set PropertyName(val) {
        if (val !== this.PropertyNameValue) {
            this.PropertyNameValue = val;
            this.PropertyNameChange.emit(this.PropertyNameValue);
        }
    }

    @Output() PropertyNameChange = new EventEmitter<any>();
    //#endregion

    //#region PropertyList
    PropertyListValue = [];

    @Input()
    get PropertyList() {
        return this.PropertyListValue;
    }
    set PropertyList(val) {
        if (val !== this.PropertyListValue) {
            this.PropertyListValue = val;
            this.PropertyListChange.emit(this.PropertyListValue);
        }
    }

    @Output() PropertyListChange = new EventEmitter<any>();
    //#endregion

    //#region Editable
    EditableValue = true;

    @Input()
    get Editable() {
        return this.EditableValue;
    }
    set Editable(val) {
        if (val !== this.EditableValue) {
            this.EditableValue = val;
            this.EditableChange.emit(this.EditableValue);
        }
    }

    @Output() EditableChange = new EventEmitter<any>();
    //#endregion

    //#region PlaceHolder
    PlaceHolderValue = '@@Eigenschaft';

    @Input()
    get PlaceHolder() {
        return this.PlaceHolderValue;
    }
    set PlaceHolder(val) {
        if (val) {
            this.PlaceHolderValue = val;
            this.PlaceHolderChange.emit(this.PlaceHolderValue);
        }
    }

    @Output() PlaceHolderChange = new EventEmitter<any>();
    //#endregion

    //#region IgnoreListProperties
    IgnoreListPropertiesValue = false;

    @Input()
    get IgnoreListProperties() {
        return this.IgnoreListPropertiesValue;
    }
    set IgnoreListProperties(val) {
        this.IgnoreListPropertiesValue = val;
        this.IgnoreListPropertiesChange.emit(this.IgnoreListPropertiesValue);
    }

    @Output() IgnoreListPropertiesChange = new EventEmitter<any>();
    //#endregion

    private static GetNodes(propList, idKeeper: IDKeeper, ignoreList: boolean) {
        const retVal = [];
        if (propList && propList.length > 0) {
            propList.forEach(x => {
                if (ignoreList && x.IsList) {
                    return;
                }
                const propNode = new PropertyNode(x.FullPath, idKeeper.NextID);
                propNode.Caption = x.Name;
                propNode.Children = ObjectPropertyChooser.GetNodes(x.ObjectProperties, idKeeper, ignoreList);
                propNode.HasChildren = propNode.Children.length > 0;
                propNode.Selectable = !propNode.HasChildren;
                retVal.push(propNode);
            });
        }
        return retVal;
    }

    ngOnInit(): void {
        this.Nodes = ObjectPropertyChooser.GetNodes(this.PropertyListValue, new IDKeeper(), this.IgnoreListPropertiesValue);
    }

    nodeSelected(ev) {
        if (ev) {
            this.PropertyName = ev.Path;
        }
    }
}

export class PropertyNode extends ABaseTreeNode {
    Path: string;

    constructor(path: string, id: number) {
        super(id);
        this.Path = path;
    }
}
