import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { LoginError } from '../../../models/enums/loginerror.enum';
import { ProviderChoice } from '../../../models/enums/providerchoice.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { SecurityService } from '../../../services/security.service';
import { UsersService } from '../../../services/users.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'linkexternal-wf-settings',
    templateUrl: './linkexternal.settings.html'
})
export class LinkExternalSettings extends FormulaWorkflowDialogContent {
    Data = new LinkExternalSettingsData();
    SecurityProviderTypes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'linkexternalWFModule';
        reg.Caption = '@@Link External'
        reg.GroupID = 'wfActions';
        reg.Index = 105;
        reg.SettingsControl = LinkExternalSettings;
        reg.SettingsTypeHelper = new LinkExternalSettingsDataHelper();
        reg.Executer = LinkExternalModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private service: SecurityService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetSecurityProviders().subscribe((types) => {
            if (types) {
                this.SecurityProviderTypes = types;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(LinkExternalSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }
}



export class LinkExternalSettingsData {
    ProviderChoice: ProviderChoice = ProviderChoice.Control;
    Provider: string;
}

export class LinkExternalSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Success';

        const error = new WorkflowExitInfo();
        error.ID = LoginError.Password;
        error.Label = '@@Error';

        return [success, error];
    }

    getEmptySettingsInstance() {
        return new LinkExternalSettingsData();
    }
}

export class LinkExternalModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const service = InjectorHelper.InjectorInstance.get<SecurityService>(SecurityService);
            const providers = await service.GetSecurityProviders().toPromise();
            let loginId = UUID.UUID();

            if (providers) {
                const calculator = new WorkflowFormulaCalculator(status);
                let providerID;
                switch (status.ActualSettings.ProviderChoice) {
                    case ProviderChoice.Control:
                        const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.Provider);
                        if (elem && elem.Element && elem.Element.DataSource) {
                            providerID = '' + elem.Element.DataSource;
                        }
                        break;
                    case ProviderChoice.Fixed:
                        providerID = status.ActualSettings.Provider;
                        break;
                    case ProviderChoice.Formula:
                        providerID = calculator.CalcFormula(status.ActualSettings.Provider);
                        break;
                }
                let openedwindow;
                let selectedProvider;
                if (providerID) {
                    selectedProvider = providers.find(x => x.Id === providerID);
                }
                if (!selectedProvider) {
                    selectedProvider = providers.find(x => x.IsDefault);
                }
                if (selectedProvider) {
                    if (selectedProvider.Url) {
                        if (selectedProvider.Id === '727bd278-906c-4cf8-a803-379ef57f2c89') {
                            document.cookie = "hajer" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            var date = new Date();
                            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
                            var expires = "; expires=" + date.toUTCString();
                            document.cookie = 'hajer' + "=" + (loginId || "") + expires + "; path=/";
                            openedwindow = window.open((selectedProvider.Url), '_blank');
                        } else {
                            openedwindow = window.open((selectedProvider.Url + '&state=' + selectedProvider.Id + '|' + loginId), '_blank');
                        }
                    }
                    providerID = selectedProvider.Id;
                }
                const credentials = {
                    UserName: name,
                    Password: '',
                    ProviderId: providerID,
                    LoginId: loginId
                };
                const userService = InjectorHelper.InjectorInstance.get<UsersService>(UsersService);
                const retVal = await userService.executeLinkExternal(credentials);
                if (openedwindow) {
                    openedwindow.close();
                }
                if (retVal) {
                    return 0;
                }
                return 1;
            } else {
                status.Logger.logError('Login modul: No providers found.');
            }
        } else {
            status.Logger.logError('Login modul: No settings found.');
        }
        return -1;
    }
}
