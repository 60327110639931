export class WorkflowDebugSettings {
    ShowWorkflowLog = false;
    ShowServiceLogsOnTest = false;
    WaitTimeout = 5;
    Workflows: SelectedWorkflows = new SelectedWorkflows();
}

export class SelectedWorkflows {
    ServiceWorkflows: ServiceWorkflowInfo[] = [];
    ClientWorkflows: ClientWorkflowInfo[] = [];
    ConnectorWorkflows: ConnectorWorkflowInfo[] = [];
}

export class ServiceWorkflowInfo {
    ID: string;
    Breakpoints: BreakPointInfo[] = [];
}

export class ClientWorkflowInfo extends ServiceWorkflowInfo {
    URL: string;
    PageSID: string;
}

export class ConnectorWorkflowInfo extends ServiceWorkflowInfo {
    ConnectorID: string;
    ContainerID: string;
}

export class BreakPointInfo {
    ModuleID: number;
    BreakOnStart = false;
    BreakOnEnd = false;
    AdditionalBreakPoints: any;
}

export class BreakPointSelection {
    ModuleID: number;
    Start: boolean;
}
