import { Pipe, PipeTransform } from "@angular/core";
import { plainToClass } from "class-transformer";
import { LayoutElement } from "../models/layoutelement.model";

@Pipe({ name: 'GenerateLayoutCopy', pure: true })
export class GenerateLayoutCopyPipe implements PipeTransform {
    transform(element) {
        let retVal = JSON.parse(JSON.stringify(element));
        retVal = plainToClass(LayoutElement, retVal);
        return retVal;
    }
}


@Pipe({ name: 'DOMRectToStyle', pure: true })
export class DOMRectToStylePipe implements PipeTransform {
    transform(value) {
        let retVal = {};
        if (value && value.rect) {
            let item = value.rect;
            retVal = {
                width: item.width + 'px',
                height: item.height + 'px',
                top: item.top + 'px',
                left: item.left + 'px',
                right: item.right + 'px',
                bottom: item.bottom + 'px',
                x: item.x + 'px',
                y: item.y + 'px',
                position: 'absolute'
            };
        }
        return retVal;
    }
}