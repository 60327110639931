import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {RequestBase} from './request-base';

@Injectable()
export class RolesService extends RequestBase {
    BasePath = 'config/api/roles';

    static IsSystemRole(id) {
        return id === '0a5d664e-be8d-4a8c-b748-0d4793280d0d' || id === 'df03329c-6538-4918-ba8c-3f743c2928a0'; // InitialAdmin && User Role
    }

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ReadRoles(): Observable<any[]> {
        return this.http.post<any[]>(this.API_BASE_URL + this.BasePath + '/ReadRoles', null, this.options);
    }

    ReadRoleInfos(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/ReadRoleInfos', this.options);
    }

    FindRole(id: string) {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/FindRole?id=' + id, this.options);
    }

    AddOrUpdateRole(role): Observable<any> {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/AddOrUpdateRole', role, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }

    DeleteRole(id: string) {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/DeleteRole?id=' + id, this.options).pipe(
            catchError(this.handleError('delete', []))
        );
    }

    GetSecurityEnumTreeNodes(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetSecurityEnumTreeNodes?roleID=' + id, this.options).pipe(
            catchError(this.handleError('GetSecurityEnumTreeNodes', null))
        );
    }

    SaveSecurityEnums(id: string, data) {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/SaveSecurityEnums?roleID=' + id, data, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }

    GetUserRoles(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetUserRoles', this.options);
    }

    FindUserRole(id: string) {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/FindUserRole?id=' + id, this.options);
    }

    AddUserRole(role): Observable<any> {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/AddUserRole', role, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }

    UpdateUserRole(role): Observable<any> {
        return this.http.put(this.API_BASE_URL + this.BasePath + '/UpdateUserRole', role, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }

    DeleteCompleteUserRole(id: string) {
        return this.http.delete(this.API_BASE_URL + 'api/selfservice/security/DeleteCompleteUserRole?roleId=' + id, this.options).pipe(
            catchError(this.handleError('delete', []))
        );
    }

    GetSystempermissions(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/systempermissions', this.options);
    }

    AddSystempermissions(role): Observable<any> {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/systempermissions', role, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }
}
