import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class TranslationsEndpoints extends OfflineEndpointCollection {
    BasePath = 'base/translations/';
    EndPoints = [
        //#region GetTranslations
        {
            Name: 'GetTranslations',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.ServiceBase.Config.Translation'].filter((value) => {
                        return value.LCID == params['culture']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0].Data);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetTranslationsForKey
        {
            Name: 'GetTranslationsForKey',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.ServiceBase.Config.Translation'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = {
                                Key: params['key'],
                                Translations: []
                            }
                            values.forEach((value) => {
                                if (value && value.Data && value.Data[params['key']]) {
                                    retVal.Translations.push({
                                        LCID: value.LCID,
                                        Value: value.Data[params['key']]
                                    })
                                }
                            })
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetAllTranslationKeys
        {
            Name: 'GetAllTranslationKeys',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.ServiceBase.Config.Translation'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let list = [];
                            values.forEach((language) => {
                                if (language && language.Data) {
                                    let keys = Object.keys(language.Data);
                                    list.push(...keys);
                                }
                            })
                            observer.next(list.filter((v, i, a) => a.indexOf(v) === i));
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
    ]
}