import { Component } from '@angular/core';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'macplanning-property-menu-tab',
    templateUrl: './planning.menu.tab.html'
})
export class MacPlanningPropertyMenuTab extends APropertyTab {
    IsMultidimensional = false;
    Layout;

    static CheckVisible(): boolean {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'planningreportObject') {
            return true;
        }
        return false;
    }

    ngOnInit() {
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
        }));
        super.ngOnInit();
    }

    onItemSet() {
        let multi = false;
        if (this.SelectedItem) {
            if (!this.SelectedItem.FormatTasks) {
                this.SelectedItem.FormatTasks = [];
            }
            if (!this.SelectedItem.DataTasks) {
                this.SelectedItem.DataTasks = [];
            }
            if (this.SelectedItem.Query && this.Layout && this.Layout.Datadescriptions) {
                const dataDesc = this.Layout.Datadescriptions.find(x => x.ID === this.SelectedItem.Query);
                if (dataDesc) {
                    multi = !dataDesc.IsRelational;
                }
            }
        }
        this.IsMultidimensional = multi;
    }

    TasksChanged() {
        this.SelectedItem.Element.RefreshStyles();
    }
}
