import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-tourguide.dialog',
  templateUrl: './tourguide.dialog.component.html',
  styleUrls: ['./tourguide.dialog.component.css']
})
export class TourguideDialog {

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
console.log(config);

  }

  close(): void {
    this.ref.close();
  }
  
}

