import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-url-navigation-settings',
    templateUrl: './url.navigation.settings.html',
    styleUrls: ['./url.navigation.settings.css']
})
export class URLNavigationSettings extends FormulaWorkflowDialogContent {
    Data = new URLNavigationSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'urlNavigationWFModule';
        reg.Caption = '@@URL Navigation';
        reg.GroupID = 'navigation';
        reg.Index = 22;
        reg.SettingsControl = URLNavigationSettings;
        reg.SettingsTypeHelper = new URLNavigationSettingsDataHelper();
        reg.Executer = URLNavigationModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(URLNavigationSettingsData, seri);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class URLNavigationSettingsData {
    Back = true;
    UseFormula = false;
    BackFormula: string;
}

export class URLNavigationSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [];
    }
    getEmptySettingsInstance() {
        return new URLNavigationSettingsData();
    }
}

export class URLNavigationModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let backValue;
            if (status.ActualSettings.UseFormula) {
                const calculator = new WorkflowFormulaCalculator(status);
                backValue = calculator.CalcFormula(status.ActualSettings.BackFormula);
            } else {
                backValue = status.ActualSettings.Back;
            }
            if (typeof backValue === 'boolean') {
                const location = InjectorHelper.InjectorInstance.get<Location>(Location);
                if (backValue) {
                    location.back();
                } else {
                    location.forward();
                }
            } else {
                status.Logger.logError('URL navigation modul: No direction defined.');
            }
        } else {
            status.Logger.logError('URL navigation modul: No settings found.');
        }
        return super.execute(status);
    }
}
