import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';

export class ExecuteOnActualDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const data = new WorkflowExitInfo();
        data.ID = 0;
        data.Label = '@@Data';
        data.Type = 'relDataEntry';
        const exit = new WorkflowExitInfo();
        exit.ID = 1;
        exit.Label = '@@Ende';
        exit.Type = 'relData';
        return [data, exit];
    }

    getEmptySettingsInstance() {
        return new ExecuteOnActualDataSettingsData();
    }
}

export class ExecuteOnActualDataSettingsData extends AServiceWorkflowData {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'execOnDataWFModule';
        reg.Caption = '@@Auf Datenzeile ausfuehren';
        reg.GroupID = 'reldataoperations';
        reg.Index = 60;
        reg.SettingsTypeHelper = new ExecuteOnActualDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ExecuteOnActual.ExecuteOnActualDataSettingsData';
    }
}

export class ExecuteOnActualObjectHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const data = new WorkflowExitInfo();
        data.ID = 0;
        data.Label = '@@Object';
        data.Type = 'objDataEntry';
        const exit = new WorkflowExitInfo();
        exit.ID = 1;
        exit.Label = '@@Ende';
        exit.Type = 'objData';
        return [data, exit];
    }

    getEmptySettingsInstance() {
        return new ExecuteOnActualObjectSettingsData();
    }
}

export class ExecuteOnActualObjectSettingsData extends AServiceWorkflowData {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'execOnObjectWFModule';
        reg.Caption = '@@Auf Objekt ausfuehren';
        reg.GroupID = 'objectoperations';
        reg.Index = 25;
        reg.SettingsTypeHelper = new ExecuteOnActualObjectHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ExecuteOnActual.ExecuteOnActualObjectSettingsData';
    }
}
