import { Component, Input, ViewChild } from '@angular/core';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { ObjectCreatorSourceMultiDimensional } from '../../multipanels/objectcreatorsource/objectcreatorsource.multidim';
import { DataDescriptionHelper } from '../../query/ddhelper.query';

@Component({
    selector: 'replace-element-dialog',
    templateUrl: './replace.element.dialog.html',
    styles: ['.imageDiv { margin: 10px 0px 10px 10px; }', '.textDiv { margin: 11px; }']
})
export class ReplaceElementDialog {

    InitArgs;
    Text;
    CustomButtons = [
        { Caption: '@@Austauschen', ID: 1 },
        { Caption: '@@Abbrechen', ID: 2 }
    ];
    CloseOnCustomButton = true;
    Result;


    @ViewChild('sourceControl') sourceControl: ObjectCreatorSourceMultiDimensional;



    get DataModelID() {
        return this.DataDescriptionData.DataModelID;
    }


    get SelectedLevel() {
        return this.sourceControl?.SelectedLevelItem?.Caption ? this.sourceControl.SelectedLevelItem.Caption : "";
    }

    get SelectedMeasure() {
        return this.sourceControl?.SelectedMeasureItem?.Caption ? this.sourceControl.SelectedMeasureItem.Caption : "";
    }

    DataDescriptionDataValue: DatadescriptionData;
    @Input()
    get DataDescriptionData() {
        return this.DataDescriptionDataValue;
    }
    set DataDescriptionData(val) {
        this.DataDescriptionDataValue = val;
    }

    Initialize(args) {
        if (args) {
            this.InitArgs = args;
            let ddd= new DatadescriptionData();
            ddd.Datadescription = this.InitArgs.DataDescription;
            ddd.DataModelID = ddd.Datadescription.DataModelID;
            this.DataDescriptionData = ddd;

        }
    }

    GetDialogResult() {
            return this.InitArgs.DataDescription;
    }

     async OnCustomButtonClicked(but) {
        if (this.InitArgs && this.InitArgs.DataDescription) {
            switch (but.ID) {


                case 1: // Austauschen
                    if (this.InitArgs.IsMeasure) {

                        await DataDescriptionHelper.ReplaceMeasureByID(this.InitArgs.DataDescription, this.InitArgs.ID, this.sourceControl.SelectedMeasureItem.SID);
                    }
                    else {
                        await DataDescriptionHelper.ReplaceLevelNodeByID(this.InitArgs.DataDescription, this.InitArgs.ID, this.sourceControl.SelectedLevelItem.SID);

                    }
                    this.Result = this.InitArgs.DataDescription;
                        break;
                case 2: // abbrechen

                    this.Result = this.InitArgs.DataDescription;
                    break;
               
            }
        }
    }
}
