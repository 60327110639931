import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'tabular-panel',
    templateUrl: './tabular.panel.html',
    styleUrls:['./tabular.panel.css']
})
export class TabularPanel extends BaseDataSourcePanel {

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: TabularPanel,
            DBInfoType: TabularDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = TabularDBInfo;
    }   
}

export class TabularDBInfo extends ADBInfo {
    Server: string = null;
    Database: string = null;
    getTypeName(): string {
        return 'evidanza.Database.Tabular.TabularDBInfo';
    }
}
