import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-readrowdata-settings',
    templateUrl: './readrowdata.settings.html',
    // styleUrls: ['./readrowdata.settings.css']
})
export class ReadRowDataSettings extends WorkflowDialogContent {
    Columns = [];
    Data = new ReadRowDataSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readrowdataWFModule';
        reg.Caption = '@@Spaltenwerte auslesen';
        reg.GroupID = 'reldataoperations';
        reg.Index = 70;
        reg.SettingsControl = ReadRowDataSettings;
        reg.SettingsTypeHelper = new ReadRowDataSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const ds = this.ActualState.get('DataSource');
        if (ds) {
            this.Columns = ds.Columns;
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadRowDataSettingsData, json);
        }
    }

    getResult() {
        const cols = [];
        this.Data.Columns.forEach(x => {
            if (x.ColumnName && (x.StatusKey || x.UseForState)) {
                cols.push(x);
            }
        });
        this.Data.Columns = cols;
        return this.Data;
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Columns) {
            this.Data.Columns.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }

    addColumn() {
        if (this.Data) {
            if (this.Data.Columns) {
                this.Data.Columns.push(new ReadRowDataEntry());
            } else {
                this.Data.Columns = [new ReadRowDataEntry()];
            }
        }
    }

    removeColumn(i) {
        if (this.Data && this.Data.Columns) {
            this.Data.Columns.splice(i, 1);
        }
    }
}

// @dynamic
export class ReadRowDataSettingsData extends AServiceWorkflowData {
    @Type(() => ReadRowDataEntry)
    Columns: ReadRowDataEntry[] = [];
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ReadRowData.ReadRowDataSettingsData';
    }
}

export class ReadRowDataEntry {
    ColumnName: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadRowDataSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        if (!settings || !settings.Columns || !settings.Columns.some(x => x.UseForState)) {
            retVal.Type = 'relData';
        }
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new ReadRowDataSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Columns) {
                const list = [];
                settings.Columns.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}
