import { UUID } from 'angular2-uuid';
import { MultiCacheService } from '../../cache/multi/cache.service';
import { Cube, Level } from '../../cache/multi/models/database.model';
import { NotificationHelper } from '../../helpers/notification.helper';
import { Axis, DataDescription, HeterogenArea } from '../../models/datadescription/multi/datadescription.model';
import { MeasureInfo } from '../../models/datadescription/multi/measureinfo.model';
import {
    AxisDef, DynamicElementDef, LevelDef, MeasureDef, OffsetDef, QueryDef, WhereDef, WhereOptions
} from '../../models/datadescription/multi/querydef.model';
import { AxisType, MeasureAccumulation } from '../../models/enums/query.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { DataDescriptionHelper } from './ddhelper.query';

export class QueryHelper {

    static async CheckFlatLevelCount(datadescription: DataDescription): Promise<boolean> {
        // #warning check ob Designmodus fehlt
        const dmID = datadescription.DataModelID;
        let retVal = false;
        let measureaxis: Axis = null;
        let otheraxis: Axis = null;
        if (datadescription.ShowMeasureOnAxis === AxisType.X_Axis) {
            measureaxis = datadescription.XLevelNodes;
            otheraxis = datadescription.YLevelNodes;
        } else {
            measureaxis = datadescription.YLevelNodes;
            otheraxis = datadescription.XLevelNodes;
        }
        if (measureaxis) {
            const cubes: UUID[] = [];
            measureaxis.Areas.forEach(a => {
                a.Measures.forEach(mi => {
                    if (mi.Cube && !cubes.some(x => x === mi.Cube)) {
                        cubes.push(mi.Cube);
                    }
                });
            });
            if (cubes.length > 1) {
                for (let i = 0; i < measureaxis.Areas.length; i++) {
                    const a = measureaxis.Areas[i];
                    const areacubes: UUID[] = [];
                    a.Measures.forEach(mi => {
                        if (mi.Cube && !areacubes.some(x => x === mi.Cube)) {
                            areacubes.push(mi.Cube);
                        }
                    });
                    if (areacubes.length > 1) {
                        for (let j = 0; j < a.Tuples.length; j++) {
                            const t = a.Tuples[j];
                            if (t.Levels.length > 1) {
                                for (let k = 0; k < t.Levels.length; k++) {
                                    const l = t.Levels[k];
                                    const level: Level = await MultiCacheService.GetLevel(l.Level, dmID);
                                    let cube: Cube = await MultiCacheService.GetCube(areacubes[0], dmID);
                                    const firstcubefound = Cube.HasLevel(cube, level);
                                    for (let m = 1; m < areacubes.length; m++) {
                                        cube = await MultiCacheService.GetCube(areacubes[m], dmID);
                                        const nextcubefound = Cube.HasLevel(cube, level);
                                        if (nextcubefound !== firstcubefound) {
                                            retVal = true;
                                            break;
                                        }
                                    }
                                    if (retVal) {
                                        break;
                                    }
                                }
                                if (retVal) {
                                    break;
                                }
                            }
                        }
                        if (retVal) {
                            break;
                        }
                    }
                }
                if (!retVal) {
                    for (let i = 0; i < otheraxis.Areas.length; i++) {
                        const a = otheraxis.Areas[i];
                        for (let j = 0; j < a.Tuples.length; j++) {
                            const t = a.Tuples[j];
                            if (t.Levels.length > 1) {
                                for (let k = 0; k < t.Levels.length; k++) {
                                    const l = t.Levels[k];
                                    const level: Level = await MultiCacheService.GetLevel(l.Level, dmID);
                                    let cube: Cube = await MultiCacheService.GetCube(cubes[0], dmID);
                                    const firstcubefound = Cube.HasLevel(cube, level);
                                    for (let m = 1; m < cubes.length; m++) {
                                        cube = await MultiCacheService.GetCube(cubes[m], dmID);
                                        const nextcubefound = Cube.HasLevel(cube, level);
                                        if (nextcubefound !== firstcubefound) {
                                            retVal = true;
                                            break;
                                        }
                                    }
                                    if (retVal) {
                                        break;
                                    }
                                }
                                if (retVal) {
                                    break;
                                }
                            }
                        }
                        if (retVal) {
                            break;
                        }
                    }
                }
            }
        }

        return retVal;
    }

    static async GetQueryDef(ddclone: DataDescription, xarea: HeterogenArea, yarea: HeterogenArea): Promise<QueryDef[]> {

        await MultiCacheService.AwaitIsInitialized(ddclone.DataModelID);

        const definitions: QueryDef[] = [];

        const cubemeasures = QueryHelper.GetCubeMeasures(ddclone.ShowMeasureOnAxis === AxisType.X_Axis ? xarea.Measures : yarea.Measures);

        for (let i = 0; i < cubemeasures.length; i++) {
            const cms = cubemeasures[i];
            const definition = new QueryDef();
            definition.EffectiveUserName = ddclone.EffectiveUserName;
            definition.Cube = await MultiCacheService.GetCube(cms.Cube, ddclone.DataModelID);
            definition.ID = UUID.UUID();
            definition.DataModell = ddclone.DataModelID;
            definition.AutomatedSolveOrder = ddclone.AdvancedSettings.AutomatedSolveOrder;
            definition.MeasureOnAxis = ddclone.ShowMeasureOnAxis;

            const dres = DataDescriptionHelper.GetValidDynamicRowElements(ddclone);
            for (let j = 0; j < dres.length; j++) {
                const dre = dres[j];
                const de = new DynamicElementDef();
                de.Code = dre.Code;
                de.Description = dre.Description;
                de.Formula = dre.Formula;
                de.Parent = dre.Parent;
                de.Level = await MultiCacheService.GetLevel(dre.Level, ddclone.DataModelID);
                if (de.Level) {
                    de.Hierarchy = de.Level.Parent;
                }
                definition.DynamicElements.push(de);
            }

            await QueryHelper.FillDefinition(ddclone, xarea, cms, definition, AxisType.X_Axis);
            await QueryHelper.FillDefinition(ddclone, yarea, cms, definition, AxisType.Y_Axis);

            if (ddclone.Filter) {
                ddclone.Filter.forEach(f => {
                    const options = new WhereOptions();
                    options.IsOptional = true;
                    const wd = new WhereDef();
                    wd.DimensionId = f.Dimension;
                    wd.HierarchyId = f.Hierarchy;
                    wd.Formula = f.Formula;
                    wd.Options = options;
                    wd.Defaults = f.Defaults;
                    definition.Where.push(wd);
                });
            }

            definitions.push(definition);
        }

        return definitions;
    }
    static async FillDefinition(ddclone: DataDescription, area: HeterogenArea, cms: any, definition: QueryDef, axis: AxisType) {
        if (area) {
            if (area.Measures.length > 0) {
                await QueryHelper.SetMeasures(cms.MeasureInfos, definition, ddclone.DataModelID);
                const axisdef = new AxisDef();
                axisdef.AreaID = area.UniqueID;
                axisdef.Type = AxisType.Measure;
                axisdef.TopCount = ddclone.TopCount;
                axisdef.HasMeasures = true;
                axisdef.ShowAll = true;
                definition.Axis.push(axisdef);
                definition.HeterogenPosition.set(AxisType.Measure, area.UniqueID);
            }
            if (area.LevelCount > 0) {
                await QueryHelper.BuildAxisDef(area, definition, ddclone, axis);
            }
            definition.HeterogenPosition.set(axis, area.UniqueID);
        }
    }

    static async BuildAxisDef(area: HeterogenArea, definition: QueryDef, ddclone: DataDescription, axis: AxisType): Promise<boolean> {
        let found = false;
        const axisdef = new AxisDef();
        axisdef.AreaID = area.UniqueID;
        axisdef.Type = axis;
        axisdef.TopCount = ddclone.TopCount;
        axisdef.HasMeasures = false; // area.Measures.length > 0;
        if (axis === AxisType.X_Axis) {
            axisdef.ShowAll = ddclone.XLevelNodes.ShowAll;
        } else if (axis === AxisType.Y_Axis) {
            axisdef.ShowAll = ddclone.YLevelNodes.ShowAll;
        }
        let count = 0;
        for (let i = 0; i < area.Tuples.length; i++) {
            for (let j = 0; j < area.Tuples[i].Levels.length; j++) {
                const levelnode = area.Tuples[i].Levels[j];
                const level: Level = await MultiCacheService.GetLevel(levelnode.Level, ddclone.DataModelID);
                if (level) {
                    if (Cube.HasLevel(definition.Cube, level)) {
                        const leveldef = new LevelDef();
                        leveldef.Attributes = [...levelnode.Attributes];
                        leveldef.Depth = count++;
                        leveldef.Level = level;
                        leveldef.Hierarchy = level.Parent;
                        leveldef.Dimension = level.Parent.Parent;
                        leveldef.UniqueID = levelnode.UniqueID;
                        if (ddclone.MeasureInfos.find(m => m.Details.FunctionOffset &&
                            m.Details.FunctionOffset.OffsetLevel && m.Details.FunctionOffset.OffsetValue !== 0)) {
                            leveldef.TupleIndex = leveldef.Depth;
                        } else {
                            leveldef.TupleIndex = i;
                        }
                        axisdef.Levels.push(leveldef);
                        found = true;
                    }
                }
                else {
                    NotificationHelper.Error(levelnode.Caption + ",ID: " + levelnode.Level + ", DimensionID: " + levelnode.DimensionID + ", HierarchyID: " + levelnode.HierarchieID + " nicht gefunden", "@@Level nicht gefunden");
                }
            }
        }

        if (found) {
            definition.Axis.push(axisdef);
        }

        return found;
    }

    static async SetMeasures(measureInfos: MeasureInfo[], definition: QueryDef, dmID: any) {
        for (let i = 0; i < measureInfos.length; i++) {
            const mis = measureInfos[i];
            const mi = new MeasureDef();
            mi.Attribute = mis.Attribute;
            mi.AttributeHierarchy = await MultiCacheService.GetHierarchy(mis.AttributeHierarchy, dmID);
            mi.Caption = TranslatedString.GetTranslation(mis.Caption);

            if (mis.Details.FunctionAccumulation) {
                if (mis.Details.FunctionAccumulation.UseMTD) {
                    mi.FunctionAccumulation = MeasureAccumulation.MTD;
                } else if (mis.Details.FunctionAccumulation.UseYTD) {
                    mi.FunctionAccumulation = MeasureAccumulation.YTD;
                } else if (mis.Details.FunctionAccumulation.UseWTD) {
                    mi.FunctionAccumulation = MeasureAccumulation.WTD;
                }
            }

            if (mis.Details.FunctionOffset && mis.Details.FunctionOffset.OffsetLevel && mis.Details.FunctionOffset.OffsetValue !== 0) {
                mi.FunctionOffset = new OffsetDef();
                mi.FunctionOffset.Level = await MultiCacheService.GetLevel(mis.Details.FunctionOffset.OffsetLevel, dmID);
                if (mi.FunctionOffset.Level) {
                    mi.FunctionOffset.Hierarchy = mi.FunctionOffset.Level.Parent;
                }
                mi.FunctionOffset.Counter = mis.Details.FunctionOffset.OffsetValue;
            }

            if (mis.Details.FunctionParentReferencing && mis.Details.FunctionParentReferencing.PRHierarchy) {
                mi.FunctionParentReferencing = new OffsetDef();
                mi.FunctionParentReferencing.Hierarchy =
                    await MultiCacheService.GetHierarchy(mis.Details.FunctionParentReferencing.PRHierarchy, dmID);
                mi.FunctionParentReferencing.Counter = mis.Details.FunctionParentReferencing.PRValue;
            }

            if (mis.Details.ReferencingSelects) {
                mis.Details.ReferencingSelects.forEach(f => {
                    if (f.Formula || (f.Defaults && f.Defaults.length > 0)) {
                        const options = new WhereOptions();
                        options.IsOptional = true;
                        const wd = new WhereDef();
                        wd.DimensionId = f.Dimension;
                        wd.HierarchyId = f.Hierarchy;
                        wd.Formula = f.Formula;
                        wd.Defaults = f.Defaults;
                        wd.Options = options;
                        mi.ReferenceWhere.push(wd);
                    }
                });
            }

            if (mis.Details.TimeRangeReference && mis.Details.TimeRangeReference.Level) {
                mi.TimeRangeReference = new OffsetDef();
                mi.TimeRangeReference.Counter = mis.Details.TimeRangeReference.Counter;
                mi.TimeRangeReference.Level = await MultiCacheService.GetLevel(mis.Details.TimeRangeReference.Level, dmID);
                if (mi.TimeRangeReference.Level) {
                    mi.TimeRangeReference.Hierarchy = mi.TimeRangeReference.Level.Parent;
                }
            }

            if (typeof mis.Aggregation === 'number') {
                mi.ASFunctionType = mis.Aggregation;
            }

            mi.Measure = await MultiCacheService.GetMeasure(mis.Measure, dmID);
            mi.QueryAttribute = mis.QueryAttribute;
            mi.UniqueID = mis.UniqueID;

            definition.Measures.push(mi);
        }
    }

    static GetCubeMeasures(measures: MeasureInfo[]): any[] {
        const cubemeasures = [];

        measures.forEach(mi => {
            if (!mi.SpecialElementType && mi.Measure && mi.Cube) {
                const cmd = cubemeasures.find(x => x.Cube === mi.Cube);
                if (!cmd) {
                    const newcmd = {
                        Cube: mi.Cube,
                        MeasureInfos: []
                    };
                    newcmd.MeasureInfos.push(mi);
                    cubemeasures.push(newcmd);
                } else {
                    cmd.MeasureInfos.push(mi);
                }
            }
        });

        return cubemeasures;
    }

    static IsEmptyMultiResult(multiresult): boolean {
        return multiresult.XAxis.ElementCount === 0 && multiresult.YAxis.ElementCount === 0 && multiresult.Measures.ElementCount === 0;
    }

    static GetNodeFromMultiResult(result, x, y, m) {
        const xcells = result.Cells[x];
        if (xcells) {
            const ycells = xcells[y];
            if (ycells) {
                return ycells[m];
            }
        }
    }

    static SetNodeInMultiResult(result, x, y, m, node) {
        let xcells = result.Cells[x];
        if (!xcells) {
            xcells = [];
            result.Cells[x] = xcells;
        }
        let ycells = xcells[y];
        if (!ycells) {
            ycells = [];
            xcells[y] = ycells;
        }
        const cloned = JSON.parse(JSON.stringify(node));
        ycells[m] = cloned;
        cloned.X = x;
        cloned.Y = y;
        cloned.M = m;
    }
}
