import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren
} from '@angular/core';

@Component({
    selector: 'evi-header-column-container',
    templateUrl: './header.column.container.html',
    styleUrls: ['./header.column.container.css'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        trigger('animateOrder', [
            state('ASC', style({ transform: 'rotate(0deg)', opacity: 1 })),
            state('DESC', style({ transform: 'rotate(180deg)', opacity: 1 })),
            state('NONE', style({ opacity: 0 })),
            transition('ASC => DESC', [
                animate('200ms')
            ]),
            transition('DESC => NONE', [
                animate('200ms')
            ]),
            transition('NONE => ASC', [
                animate('200ms')
            ])
        ])
    ],
})
export class HeaderColumnContainer implements AfterViewInit {
    @ViewChildren('col') elements: QueryList<ElementRef>;
    ngAfterViewInit(): void {
        if (this.elements && this.ColumnsValue && this.DataTableValue) {
            this.elements.toArray().forEach((element) => {
                this.DataTableValue.IntersectHelper.Observer.observe(element.nativeElement);
            });
        }
    }
    //#region DataTable
    DataTableValue: any;

    @Input()
    get DataTable() {
        return this.DataTableValue;
    }
    set DataTable(val: any) {
        this.DataTableValue = val;
    }
    //#endregion
    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
    }
    //#endregion
    //#region ToolTip
    ShowToolTip;
    enter(event: MouseEvent) {
        if (event.target['offsetWidth'] < event.target['scrollWidth']) {
            this.ShowToolTip = true;
        } else {
            this.ShowToolTip = false;
        }
    }
    //#endregion

    SetExpanded(column, expanded) {
        column.expanded = expanded;
        if (this.DataTableValue) {
            this.DataTableValue.UpdateColumnWidths();
        }
    }
    onResizeStart(event, column) {
        column.Resizing = true;
        column.WasResized = false;
        this.checkSizeChange(event, column);
    }
    onResizing(event, column) {
        column.Resizing = true;
        this.checkSizeChange(event, column);
    }
    onResizeStop(event, column) {
        column.Resizing = false;
        this.checkSizeChange(event, column);        
        if (column.WasResized) {
            column.WasResized = false;
            if (this.DataTableValue) {
                this.DataTableValue.ColumnResized.emit(column);
            }
        }
    }
    private checkSizeChange(event, column) {
        if (column.CalculatedWidth !== event.size.width) {
            column.WasResized = true;
            column.CalculatedWidth = event.size.width;
            this.CheckChildrenWidths(column);
            this.CheckParentWidths(column);
        }
    }
    CheckChildrenWidths(column) {
        if (column) {
            const childList = [];
            if (column.expanded) {
                if (column.Children) {
                    childList.push(...column.Children);
                }
            } else if (column.FixedChildren) {
                childList.push(...column.FixedChildren);
            }
            if (childList.length > 0) {
                let colWidth = column.CalculatedWidth;
                const nPC = {
                    ChildList: [],
                    WidthList: [],
                    Sum: 0
                };
                childList.forEach(child => {
                    nPC.ChildList.push(child);
                    let childWidth = 0;
                    if (child.CalculatedWidth) {
                        childWidth = child.CalculatedWidth;
                    }
                    nPC.WidthList.push(childWidth);
                    nPC.Sum += childWidth;
                });
                if (nPC.Sum > 0) {
                    nPC.ChildList.forEach((x, i) => {
                        x.CalculatedWidth = Math.round(100 * colWidth * nPC.WidthList[i] / nPC.Sum) / 100;
                    });
                    childList.forEach(child => {
                        this.CheckChildrenWidths(child);
                    });
                }
            }
        }
    }

    CheckParentWidths(column) {
        if (column && this.DataTableValue) {
            let parent = column;
            while (parent) {
                const parentParent = this.DataTableValue.ParentInfo[parent.Name];
                if (parentParent) {
                    parent = parentParent;
                } else {
                    this.DataTableValue.GetColumnWidth(parent);
                    break;
                }
            }
        }
    }

    //#region Actions
    @Output() ColumnSortChanged = new EventEmitter<any>();
    ChangeSort(column, event) {
        this.ColumnSortChanged.emit({ column: column, event: event });
    }
    ChangeSortExternal(event) {
        this.ColumnSortChanged.emit(event);
    }

    @Output() ColumnFilterChange = new EventEmitter<any>();
    ChangeFilter(column) {
        this.ColumnFilterChange.emit(column);
    }

    @Output() ColumnFilterCancel = new EventEmitter<any>();
    CancelFilter(column) {
        this.ColumnFilterCancel.emit(column);
    }
    //#endregion
    GetStyle(column) {
        let retVal = { ...this.DataTable.ResultStyle[column.Name], ...this.DataTable.HeaderCellStyle };
        //this.DataTable.ResultStyle[column.Name];
        //if (this.DataTable && this.DataTable.TableStyle == TableStyle.SpreadSheet && this.DataTable.CustomBaseStyle) {
        //    if (this.DataTable.CustomBaseStyle.HeaderBackground) {
        //        retVal['background'] = this.DataTable.CustomBaseStyle.HeaderBackground;
        //    }
        //    if (this.DataTable.CustomBaseStyle.Line) {
        //        retVal['border'] = "1px solid " + this.DataTable.CustomBaseStyle.Line;
        //        retVal['border-bottom'] = "1px solid " + this.DataTable.CustomBaseStyle.Line;
        //    }
        //    if (this.DataTable.CustomBaseStyle.HeaderForeground) {
        //        retVal['color'] = this.DataTable.CustomBaseStyle.HeaderForeground;
        //    }
        //}
        return retVal;
    }
}
