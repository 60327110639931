import { CacheService } from '../cache/cache.service';
import { ValueFormulaNodeCalculator } from '../models/calculation/formula.node.calculator';
import { Comparer } from '../models/enums/comparer.enum';
import { Concat } from '../models/enums/contact.enum';
import { FormulaInputMode } from '../models/enums/formulainputmode.enum';
import { Order } from '../models/enums/order.enum';
import { RequestFilter } from '../models/rest/requestfilter';
import { RequestOptions } from '../models/rest/requestoptions';
import { RequestSort } from '../models/rest/requestsort';
import { Variable } from '../models/variable.model';
import { LayoutService } from '../services/layout.service';
import { AdvancedFormulaParser } from './formula.parser';
import { LayoutHelper } from './layout.helper';
import { MetaHelper } from './meta.helper';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'RestParameterFilter',
	pure: false,
})
export class RestParameterFilter implements PipeTransform {
	transform(items: any[], filter: Object): any {
		if (!items || !filter) {
			return items;
		}
		return items.filter(
			(item) => item.ParameterType === filter['ParameterType']
		);
	}
}

@Pipe({
	name: 'CaptionFilter',
	pure: false,
})
export class CaptionFilterPipe implements PipeTransform {
	transform(items: any[], filter: string): any {
		if (!filter) {
			return items;
		}
		return items.filter((item) =>
			item.Caption.DefaultValue.toLowerCase().startsWith(filter.toLowerCase())
		);
	}
}
@Pipe({
	name: 'ERDFilter',
	pure: true,
})
export class ERDFilterPipe implements PipeTransform {
	transform(items: any[], filter: boolean, refresh: number): any {
		if (!filter) {
			return items.filter(
				(value) =>
					value.Data.ContainerType !=
					'evidanza.App.Shared.Dynamic.ContainerClassObject'
			);
		}
		return items;
	}
}
@Pipe({
	name: 'DataType',
	pure: true,
})
export class DataTypePipe implements PipeTransform {
	transform(field: any): any {
		if (field && field.DataTyp) {
			return field.DataTyp.replace('System.', '');
		}
		return null;
	}
}

export class FilterHelper {
	public static AddDeletedFilter(requestoptions) {
		if (requestoptions.Filters && requestoptions.Filters.length > 0) {
			let found = false;
			requestoptions.Filters.forEach((filter) => {
				if (filter.Name === '_Status') {
					found = true;
				}
			});
			if (!found) {
				const filter = new RequestFilter();
				filter.Name = '_Status';
				filter.Operator = Comparer.NotEqual;
				filter.Value = 2;
				requestoptions.Filters.push(filter);
			}
		} else {
			requestoptions.Filters = [];
			const filter = new RequestFilter();
			filter.Name = '_Status';
			filter.Operator = Comparer.NotEqual;
			filter.Value = 2;
			requestoptions.Filters.push(filter);
		}
	}
	public static AddSearchFilter(requestoptions, searchfilter) {
		if (requestoptions.Filters && requestoptions.Filters.length > 0) {
			let found = false;
			requestoptions.Filters.forEach((filter) => {
				if (filter.Filters && filter.Filters.length > 0) {
					FilterHelper.AddSearchFilter(requestoptions.Filters, searchfilter);
				} else {
					if (filter.Name === searchfilter.Name) {
						found = true;
						filter.Operator = searchfilter.Operator;
						filter.Value = searchfilter.Value;
						if (
							searchfilter.Operator === Comparer.Between ||
							searchfilter.Operator === Comparer.NotBetween
						) {
							filter.Value2 = searchfilter.Value2;
						}
					}
				}
			});
			if (!found) {
				requestoptions.Filters.push(searchfilter);
			}
		} else {
			requestoptions.Filters = [];
			requestoptions.Filters.push(searchfilter);
		}
	}
	public static CheckFilterValueChanges(requestoptions, searchfilter) {
		if (requestoptions.Filters && requestoptions.Filters.length > 0) {
			let found = false;
			let changes = false;
			requestoptions.Filters.forEach((filter) => {
				if (filter.Name === searchfilter.Name) {
					found = true;
					if (filter.Value !== searchfilter.Value) {
						changes = true;
					}
					if (filter.Value2 !== searchfilter.Value2) {
						changes = true;
					}
				}
			});
			if (!found) {
				changes = true;
			}
			return changes;
		} else {
			return true;
		}
	}
	public static FindFilterValue(filter, value) {
		if (filter.Value === value || filter.Value2 === value) {
			return true;
		}
		if (
			filter.Filters &&
			filter.Filters.length > 0 &&
			filter.Filters.some((x) => FilterHelper.FindFilterValue(x, value))
		) {
			return true;
		}
		return false;
	}
	public static ReplaceFilterValue(filter, value, replace) {
		if (filter.Value === value) {
			filter.Value = replace;
		}
		if (filter.Value2 === value) {
			filter.Value2 = replace;
		}
		if (filter.Filters && filter.Filters.length > 0) {
			filter.Filters.forEach((x) => {
				FilterHelper.ReplaceFilterValue(x, value, replace);
			});
		}
		return true;
	}
	public static PrepareFilter(DataSourceElement) {
		console.log('checking: ', DataSourceElement);

		const Filter = new RequestOptions();
		Filter.Filters = [];
		Filter.Columns = [];

		if (DataSourceElement) {
			let dataFilter;
			if (DataSourceElement.SRDataFilter) {
				dataFilter = DataSourceElement.SRDataFilter;
			} else if (DataSourceElement.DataFilter) {
				dataFilter = DataSourceElement.DataFilter;
			} else if (DataSourceElement.Filter) {
				dataFilter = DataSourceElement.Filter;
			}

			if (dataFilter) {
				if (dataFilter.Filters && dataFilter.Filters.length > 0) {
					const parser = new AdvancedFormulaParser();
					parser.SetVariables([], FormulaInputMode.AliasKey);
					const calculator = new ValueFormulaNodeCalculator(
						false,
						FilterHelper.GetVariables(DataSourceElement),
						new Map()
					);
					dataFilter.Filters.forEach((f) => {
						const res = FilterHelper.ReplaceVariables(f, (value) => {
							const fni = parser.Parse(value);
							const fniVal = calculator.Calc(fni);
							if (fniVal) {
								return fniVal.Value;
							}
							return value;
						});
						if (res) {
							Filter.Filters.push(res);
						}
					});
				}
				if (dataFilter.Columns) {
					Filter.Columns.push(...dataFilter.Columns);
				}
			}
		}
		return Filter;
	}
	public static GetVariableList(elemName, layout) {
		const retVal = {
			ElemFound: false,
			Variables: [],
		};
		const path = LayoutHelper.GetElementPath(layout, elemName);
		if (path.length > 0) {
			retVal.ElemFound = true;
			path.forEach((element) => {
				if (element && element.Variables) {
					retVal.Variables.push(...element.Variables);
				}
			});
			retVal.Variables.reverse();
		}
		return retVal;
	}
	public static GetVariables(element) {
		const variables = [];
		if (element && element.Variables) {
			variables.push(...element.Variables);
		}
		variables.push(...FilterHelper.GetParentVariables(element));
		return variables;
	}
	private static GetParentVariables(element) {
		const variables = [];
		const layout = LayoutService.SelectedLayout.getValue();
		if (layout) {
			const parent = MetaHelper.FindParent(layout, element);
			if (parent) {
				if (parent.Variables) {
					variables.push(...parent.Variables);
				}
				variables.push(...FilterHelper.GetParentVariables(parent));
			}
		}
		return variables;
	}
	public static GetGlobalVariables() {
		const prom = new Promise((resolve, reject) => {
			CacheService.ReadGlobalVariables().then((variables) => {
				const result = [];
				if (variables && variables.length > 0) {
					variables.forEach((v) => {
						const item = new Variable();
						item.Formula = v.Value;
						item.Name = v.Name;
						item.Type = v.Type;
						result.push(item);
					});
				}
				resolve(result);
			});
		});
		return prom;
	}
	public static ReplaceVariables(filter, manipulateValue) {
		let retVal = Object.assign({}, filter);
		if (filter.Filters) {
			const newList = [];
			filter.Filters.forEach((f) => {
				const res = FilterHelper.ReplaceVariables(f, manipulateValue);
				if (res) {
					newList.push(res);
				}
			});
			if (newList.length > 0) {
				retVal.Filters = newList;
			} else {
				retVal = null;
			}
		} else if (filter.Value) {
			retVal.Value = manipulateValue(filter.Value);
			if (filter.IsOptional) {
				if (
					retVal.Value == null ||
					retVal.Value === '' ||
					(Array.isArray(retVal.Value) && retVal.Value.length === 0)
				) {
					retVal = null;
				}
			}
			if (
				filter.Operator === Comparer.Between ||
				filter.Operator === Comparer.NotBetween
			) {
				if (filter.Value2) {
					retVal.Value2 = manipulateValue(filter.Value2);
					if (filter.IsOptional) {
						if (retVal.Value2 == null || retVal.Value2 === '') {
							retVal = null;
						}
					}
				}
			}
		}
		return retVal;
	}

	public static ApplyFilter(
		options: RequestOptions,
		data: any[],
		sort: any[] = null
	): any[] {
		let retVal = [];
		if (data && data.length > 0) {
			if (options && options.Filters && options.Filters.length > 0) {
				retVal = data.filter((x) => FilterHelper.AddValue(options.Filters, x));
			} else {
				retVal.push(...data);
			}
		}
		if (sort && retVal.length > 1) {
			sort.forEach((col: RequestSort) => {
				retVal = retVal.sort((a, b) => {
					if (a[col.Name] > b[col.Name]) {
						return col.Order == Order.ASC ? 1 : -1;
					}
					if (a[col.Name] < b[col.Name]) {
						return col.Order == Order.ASC ? -1 : 1;
					}
					if (a[col.Name] == b[col.Name]) {
						return 0;
					}
				});
			});
		}
		return retVal;
	}
	public static ApplyTableFilter(
		filters: RequestFilter[],
		data: any[],
		sort: RequestSort[] = null
	): any[] {
		let retVal = [];
		if (data && data.length > 0) {
			if (filters && filters.length > 0) {
				retVal = data.filter((x) => FilterHelper.AddValue(filters, x.data));
			} else {
				retVal.push(...data);
			}
		}
		if (sort && retVal.length > 1) {
			sort.forEach((col) => {
				retVal = retVal.sort((a, b) => {
					if (a.data[col.Name] > b.data[col.Name]) {
						return col.Order == Order.ASC ? 1 : -1;
					}
					if (a.data[col.Name] < b.data[col.Name]) {
						return col.Order == Order.ASC ? -1 : 1;
					}
					if (a.data[col.Name] == b.data[col.Name]) {
						return 0;
					}
				});
			});
		}
		return retVal;
	}

	private static AddValue(filters: RequestFilter[], data: any): boolean {
		let add = false;
		if (data) {
			add = true;
			if (filters && filters.length > 0) {
				if (filters[0].ConcatOperator === Concat.Or) {
					add = filters.some((x) => {
						if (x.Filters && x.Filters.length > 0) {
							return FilterHelper.AddValue(x.Filters, data);
						} else {
							return FilterHelper.CheckFilter(
								x.Name,
								x.Operator,
								x.Value,
								x.Value2,
								data
							);
						}
					});
				} else {
					filters.some((x) => {
						let actualAdd;
						if (x.Filters && x.Filters.length > 0) {
							actualAdd = FilterHelper.AddValue(x.Filters, data);
						} else {
							actualAdd = FilterHelper.CheckFilter(
								x.Name,
								x.Operator,
								x.Value,
								x.Value2,
								data
							);
						}
						if (!actualAdd) {
							add = false;
							return true;
						}
						return false;
					});
				}
			}
		}
		return add;
	}

	private static CheckFilter(
		name: string,
		operator: Comparer,
		value: any,
		value2: any,
		data: any
	): boolean {
		const val = data[name];
		switch (operator) {
			case Comparer.Equal:
				return val === value;
			case Comparer.Greater:
				return val > value;
			case Comparer.GreaterEqual:
				return val >= value;
			case Comparer.In:
				return Array.isArray(value) && value.indexOf(val) > -1;
			case Comparer.Like:
				return (
					typeof val === 'string' &&
					typeof value === 'string' &&
					val.toLowerCase().indexOf(value.toLowerCase()) > -1
				);
			case Comparer.NotLike:
				return (
					typeof val === 'string' &&
					typeof value === 'string' &&
					val.toLowerCase().indexOf(value.toLowerCase()) == -1
				);
			case Comparer.NotEqual:
				return val !== value;
			case Comparer.NotIn:
				return Array.isArray(value) && value.indexOf(val) === -1;
			case Comparer.Smaller:
				return val < value;
			case Comparer.SmallerEqual:
				return val <= value;
			case Comparer.Between:
				return value <= val && val <= value2;
			case Comparer.NotBetween:
				return val < value || value2 < val;
			default:
				return false;
		}
	}
}
