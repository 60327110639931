import { ExportService } from '../services/export.service';
import { DataPreviewService } from '../services/data.preview.service';
import { DataSettingsService } from '../services/data.settings.service';
import { QueryEngineService } from '../services/query.engine.service';
import { KPIService } from '../services/kpi.service';
import { RestService } from '../services/rest.service';
import { WorkflowWizardService } from '../services/workflow.wizard.service';
import { MessageSettingsService } from '../settings/messagesettings/MessageSettingsService';

export const MW_SERVICES = [
    ExportService,
    DataPreviewService,
    DataSettingsService,
    QueryEngineService,
    MessageSettingsService,
    KPIService,
    WorkflowWizardService,
    RestService
];
