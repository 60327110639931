import { TreeNode } from "primeng/api";
import { LayoutElement } from "../layoutelement.model";
import { WorkflowData } from "../workflow/workflow.model";

export class GitComparison {
    DocumentA: GitComparisonDocument;
    DocumentB: GitComparisonDocument;
    HasChanges: boolean = false;
    ChangesTree: any[] = [];
    Changes: {};
}

export class GitComparisonDocument {
    Layout: LayoutElement;
    Workflows: object;
    Compare: object;
}

export class GitComparisonElement {
    Parent: LayoutElement;
    ParentID: string;
    Layout: LayoutElement;
    JSON: string;
    Position: number;
    ElementType: string;
}

export class GitComparisonWorkflow {
    Data: WorkflowData;
    JSON: string;
}

export class GitComparisonOptions {
    CreateChangesTree: boolean = true;
    DeepComparison: boolean = false;
}

export class GitComparisonDiff {
    Type: GitDiffType = GitDiffType.NONE;

    Key: string;

    Source: object;
    SourceJSON: string;

    Modified: object;
    ModifiedJSON: string;

    //InternalChanges: GitComparisonDiff[];

    ChangeType: GitDiffChangeType = GitDiffChangeType.NONE;
}

export enum GitDiffChangeType {
    NONE,
    ADD,
    REMOVE,
    MODIFIED
}
export enum GitDiffType {
    NONE,
    LAYOUTELEMENT,
    WORKFLOW,
    OTHER
}