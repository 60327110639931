import { TranslatedString } from '../translatedstring.model';
import { InjectorHelper } from '../../helpers/injector.helper';
import { FormulaService } from '../../services/formula.service';
import { Exclude, deserialize, plainToClass, Type } from 'class-transformer';
import { FormulaGroupEnum } from '../enums/formulagroupenum.enum';
import { OperationType } from '../enums/operationtype.enum';
import { BinaryOperation } from '../enums/binaryoperation.enum';
import { CompareType } from '../enums/comparetype.enum';
import { FormulaNodeType } from '../enums/formulanodetype.enum';
import { VariableDisplay } from '../enums/variabledisplay.enum';
import { ValueType } from '../enums/valuetype.enum';

export class FormulaStatics {
    static _KeyWords = new Map<string, string>();
    static _FuncNameByKey = new Map<string, string>();
    static IntMaxValue = 2147483647;

    static Init() {
        const service = InjectorHelper.InjectorInstance.get<FormulaService>(FormulaService);
        service.GetFormulaKeys().subscribe((result) => {
            if (result) {
                const key = new Map<string, string>();
                const funcName = new Map<string, string>();
                result.forEach(k => {
                    key.set(k.Name.toLowerCase(), k.Key);
                    funcName.set(k.Key, k.Name);
                });
                FormulaStatics._KeyWords = key;
                FormulaStatics._FuncNameByKey = funcName;
            }
        });
    }
}

export class VariablesNodeInformation {
    AliasKey = '';
    VariableID = '';
    IsUsedInFormula = false;
    VariableObject: any = null;
    Name = '';
    ToolTip: string;
    Children: VariablesNodeInformation[] = [];

    static FillKeyDict(list: VariablesNodeInformation[], dict: Map<string, string>) {
        if (list && dict) {
            list.forEach(vni => {
                dict.set(vni.VariableID, vni.AliasKey);
                VariablesNodeInformation.FillKeyDict(vni.Children, dict);
            });
        }
    }
}

export class NonClickableVariablesNodeInformation extends VariablesNodeInformation {
    Expand = false;
}

export class FormulaInfo {
    Name: string;
    Description: string;
    Operation: string;
    Group: FormulaGroupEnum;
}

export class Operation {
    Name = '';
    OperationValue: string;
    OperationType: OperationType = OperationType.None;
    CompareType: CompareType = CompareType.None;
    BinaryOperation: BinaryOperation = BinaryOperation.None;
    FunctionType: string;
    MinOperandCount = 0;
    MaxOperandCount: number = FormulaStatics.IntMaxValue;
    Description: string;
}
// @dynamic
export class FormulaNodeInformation {
    Value = 0;
    TextValue: string;
    DateValue: Date;
    BoolValue: boolean;
    SaveTag;
    Name: string;
    @Type(() => TranslatedString)
    TranslatedName: TranslatedString;
    Type: FormulaNodeType = FormulaNodeType.None;
    VariableID: string;
    SignIsNegative: boolean;
    @Type(() => Operation)
    Operation: Operation = new Operation();
    @Exclude()
    ParentNode: FormulaNodeInformation;
    @Type(() => FormulaNodeInformation)
    Operands: FormulaNodeInformation[] = [];
    AliasKey = '';
    DirectVariablePosition: number;

    static ApplyAliasKeys(keys: Map<string, string>, formula: FormulaNodeInformation) {
        if (formula && keys) {
            if (formula.Type === FormulaNodeType.Variable) {
                const alias = keys.get(formula.VariableID);
                if (alias) {
                    formula.AliasKey = alias;
                }
            }
            if (formula.Operands) {
                formula.Operands.forEach(node => {
                    FormulaNodeInformation.ApplyAliasKeys(keys, node);
                });
            }
        }
    }

    static ToFormulaNodeInformation(val): FormulaNodeInformation {
        if (val) {
            if (val instanceof FormulaNodeInformation) {
                return val;
            }
            let retVal: FormulaNodeInformation;
            if (typeof val === 'object') {
                const tmp: any = plainToClass(FormulaNodeInformation, val);
                retVal = tmp;
            }
            if (typeof val === 'string') {
                retVal = deserialize(FormulaNodeInformation, val);
            }
            if (retVal) {
                retVal.SetParents();
                return retVal;
            }
        }
        return null;
    }

    toJSON() {
        const result = Object.assign({}, this);
        delete result.ParentNode;
        return result;
    }

    SetParents() {
        if (this.Operands.length > 0 && !this.Operands.some(x => x.Type !== FormulaNodeType.None)) {
            this.Operands = [];
        }
        this.Operands.forEach(op => {
            op.ParentNode = this;
            op.SetParents();
        });
    }

    ToString(uniqueNames: Map<string, string>, variableDisplay: VariableDisplay, variablePrefix?: string, variableSuffix?: string): string {
        let retVal = '';
        if (this.Type === FormulaNodeType.Variable || this.Type === FormulaNodeType.SystemVariable) {
            let variable = '';
            switch (variableDisplay) {
                case VariableDisplay.NodeName:
                    if (this.TranslatedName) {
                        variable = TranslatedString.GetTranslation(this.TranslatedName);
                    } else {
                        variable = this.Name;
                    }
                    break;
                case VariableDisplay.VariableID:
                    if (uniqueNames) {
                        const name = uniqueNames.get(this.VariableID);
                        if (name) {
                            variable = name;
                        } else {
                            variable = this.VariableID;
                        }
                    } else {
                        variable = this.VariableID;
                    }
                    break;
                case VariableDisplay.AliasKey:
                    variable = this.AliasKey;
                    break;
                case VariableDisplay.Tag:
                    if (this.SaveTag) {
                        variable = this.SaveTag.toString();
                    } else {
                        variable = '<undefined tag>';
                    }
                    break;
            }
            if ((!variable || variable === '') && this.SaveTag) {
                variable = this.SaveTag.toString();
            }
            retVal = (variablePrefix || '') + (variable || '') + (variableSuffix || '');
        } else if (this.Type === FormulaNodeType.Value) {
            if (this.Value) {
                retVal = this.Value.toString();
            }
        } else if (this.Type === FormulaNodeType.DateValue) {
            if (this.DateValue) {
                retVal = '\'' + this.DateValue.toLocaleDateString() + '\'';
            }
        } else if (this.Type === FormulaNodeType.TextValue) {
            if (this.TextValue) {
                retVal = '"' + this.TextValue + '"';
            }
        } else if (this.Type === FormulaNodeType.BoolValue) {
            if (typeof this.BoolValue === 'boolean') {
                if (this.BoolValue) {
                    retVal = 'true';
                } else {
                    retVal = 'false';
                }
            }
        } else if (this.Type === FormulaNodeType.Operation) {
            retVal = this.GetOperationChain(uniqueNames, variableDisplay, variablePrefix, variableSuffix);
        }
        retVal = this.GetBrackets(retVal);
        retVal = this.GetSign(retVal);
        return retVal;
    }

    private GetOperationChain(dd: Map<string, string>, variableDisplay: VariableDisplay, prefix: string, suffix: string): string {
        let retVal = '';
        let conj = '';
        let conjNeg = '';
        switch (this.Operation.OperationType) {
            case OperationType.Addition:
                conj = ' + ';
                conjNeg = ' - ';
                break;
            case OperationType.Multiplication:
                conj = ' * ';
                conjNeg = conj;
                break;
            case OperationType.Division:
                conj = ' / ';
                conjNeg = conj;
                break;
            case OperationType.Compare:
                switch (this.Operation.CompareType) {
                    case CompareType.Equal:
                        conj = ' = ';
                        conjNeg = conj;
                        break;
                    case CompareType.Greater:
                        conj = ' > ';
                        conjNeg = conj;
                        break;
                    case CompareType.GreaterEqual:
                        conj = ' >= ';
                        conjNeg = conj;
                        break;
                    case CompareType.Smaller:
                        conj = ' < ';
                        conjNeg = conj;
                        break;
                    case CompareType.SmallerEqual:
                        conj = ' <= ';
                        conjNeg = conj;
                        break;
                    case CompareType.NotEqual:
                        conj = ' <> ';
                        conjNeg = conj;
                        break;
                }
                break;
            case OperationType.Binary:
            case OperationType.Function:
                conj = ' ; ';
                conjNeg = conj;
                break;
        }

        for (let i = 0; i < this.Operands.length; i++) {
            const subNode = this.Operands[i];
            let nodeString = subNode.ToString(dd, variableDisplay, prefix, suffix);
            if (i === 0) {
                if (this.Type === FormulaNodeType.Operation && this.Operation.OperationType === OperationType.Addition &&
                    subNode.SignIsNegative) {
                    nodeString = '-' + nodeString;
                }
                retVal = nodeString;
            } else {
                retVal = retVal + (subNode.SignIsNegative ? conjNeg : conj) + nodeString;
            }
        }

        if (this.Type === FormulaNodeType.Operation) {
            if (this.Operation.OperationType === OperationType.Function) {
                const name = FormulaStatics._FuncNameByKey.get(this.Operation.FunctionType);
                if (name) {
                    retVal = name + '(' + retVal + ')';
                }
            } else if (this.Operation.OperationType === OperationType.Binary) {
                switch (this.Operation.BinaryOperation) {
                    case BinaryOperation.And:
                        retVal = 'AND(' + retVal + ')';
                        break;
                    case BinaryOperation.Or:
                        retVal = 'OR(' + retVal + ')';
                        break;
                }
            }
        }
        return retVal;
    }

    private GetBrackets(nodeString: string): string {
        if (this.Type === FormulaNodeType.Operation) {
            if (this.Operation.OperationType === OperationType.Addition || this.Operation.OperationType === OperationType.Subtraktion) {
                if (this.ParentNode) {
                    if (this.ParentNode.Type === FormulaNodeType.Operation &&
                        this.ParentNode.Operation.OperationType === OperationType.Function) {
                        return nodeString;
                    } else {
                        return '(' + nodeString + ')';
                    }
                } else {
                    return nodeString;
                }
            }
            if (this.Operation.OperationType !== OperationType.Function &&
                this.ParentNode && this.ParentNode.Type === FormulaNodeType.Operation
                && this.ParentNode.Operation.OperationType === OperationType.Division && this.Operands.length > 1) {
                return '(' + nodeString + ')';
            }
            if (this.Operation.OperationType === OperationType.Compare || this.Operation.OperationType === OperationType.Binary) {
                return nodeString;
            }
            if (this.SignIsNegative) {
                return '(' + nodeString + ')';
            }
        } else {
            if (this.ParentNode && this.ParentNode.Type === FormulaNodeType.Operation &&
                this.ParentNode.Operation.OperationType === OperationType.Division && this.Operands.length > 1) {
                return '(' + nodeString + ')';
            }
        }
        return nodeString;
    }

    private GetSign(nodeString: string): string {
        if (this.SignIsNegative) {
            if (!(this.ParentNode && this.ParentNode.Type === FormulaNodeType.Operation &&
                this.ParentNode.Operation.OperationType === OperationType.Addition)) {
                return '-' + nodeString;
            }
        }
        return nodeString;
    }

    AddOperand(child: FormulaNodeInformation): FormulaNodeInformation {
        if (child) {
            if (child.ParentNode) {
                child.ParentNode.DeleteOperand(child);
            }
            this.Operands.push(child);
            child.ParentNode = this;
        }
        return child;
    }

    DeleteOperand(child: FormulaNodeInformation): FormulaNodeInformation {
        const index = this.Operands.indexOf(child);
        if (index >= 0 && index < this.Operands.length) {
            this.Operands.splice(index, 1);
        }
        child.ParentNode = null;
        return child;
    }
}

export class ValueAndType {
    Type: ValueType = ValueType.Null;
    Value = null;

    static GetValueTypeFromType(type): ValueType {
        if (type) {
            switch (type) {
                case 'System.DateTime':
                    return ValueType.Datetime;
                case 'System.String':
                    return ValueType.String;
                case 'System.Int16':
                case 'System.Int32':
                case 'System.Int64':
                    return ValueType.Long;
                case 'System.Single':
                case 'System.Double':
                case 'System.Decimal':
                    return ValueType.Double;
                case 'System.Boolean':
                    return ValueType.Bool;
                default:
                    return ValueType.Object;
            }
        }
        return ValueType.Null;
    }

    static GetValueAndTypeFromJSObject(obj): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Value = obj;
        switch (typeof obj) {
            case 'string':
                retVal.Type = ValueType.String;
                break;
            case 'number':
                retVal.Type = ValueType.Double;
                break;
            case 'boolean':
                retVal.Type = ValueType.Bool;
                break;
            case 'object':
                if (obj instanceof Date) {
                    retVal.Type = ValueType.Datetime;
                } else {
                    retVal.Type = ValueType.Object;
                }
                break;
        }
        return retVal;
    }
}
