import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class JiraService extends RequestBase {
    BasePath = 'api/jira';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    Get(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Get', filter, this.options);
    }

    GetAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAll', this.options);
    }

    GetByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetByID?id=' + id, this.options);
    }

    Save(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save', obj, this.options);
    }

    Delete(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }

    GetAllProjects(config): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetAllProjects', config, this.options);
    }

    GetIssueTypes(config): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetIssueTypes', config, this.options);
    }

    SearchIssues(search): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SearchIssues', search, this.options);
    }

    GetIssueByKey(search): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetIssueByKey', search, this.options);
    }

    UpdateIssue(issueSave): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/UpdateIssue', issueSave, this.options);
    }

    SaveIssue(issueSave): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveIssue', issueSave, this.options);
    }

    GetUsers(config): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetUsers', config, this.options);
    }

    GetCreateUrl(createIssue): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetCreateUrl', createIssue, this.options);
    }

    GetPriorities(config): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetPriorities', config, this.options);
    }

    GetStatuses(config, distinct): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetStatuses?distinct=' + distinct, config, this.options);
    }

    GetTransitions(config, issue): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetTransitions?issue=' + issue, config, this.options);
    }

    TransitionIssue(config, issue, transition): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/TransitionIssue?issue=' + issue + "&transition=" + transition, config, this.options);
    }

    AssigneeIssue(config, issue, accountId): Observable<any> {
        if (accountId == null) {
            return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AssigneeIssue?issue=' + issue, config, this.options);
        } else {
            return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AssigneeIssue?issue=' + issue + "&accountId=" + accountId, config, this.options);
        }
    }
}