import { Component } from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';


@Component({
    selector: 'wf-trigger-event-settings',
    templateUrl: './trigger.event.settings.html',
    styleUrls: ['./trigger.event.settings.css']
})
export class TriggerEventSettings extends FormulaWorkflowDialogContent {
    Data = new TriggerEventSettingsData();
    ElemName: string;
    EventName: string;
    Formula: string;
    CustomEvents:any = [];
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'TriggerEventWFModule';
        reg.Caption = '@@TriggerEvent';
        reg.GroupID = 'events';
        reg.Index = 0;
        reg.SettingsControl = TriggerEventSettings;
        reg.SettingsTypeHelper = new TriggerEventWFSettingsDataHelper();
        reg.Executer = TriggerEventWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            if (data.ElemName) {
                this.ElemName = data.ElemName;
            }
            if (data.EventName) {
                this.EventName = data.EventName;
            }
            if (data.Formula) {
                this.Formula = data.Formula;
            }
        }
    }

    getResult(): any {
        const retVal = new TriggerEventSettingsData();
        retVal.EventName = this.EventName;
        retVal.ElemName = this.ElemName;
        retVal.Formula = this.Formula;
        return retVal;
    }

    SelectedElemChanged() {
        const element = LayoutHelper.getElementByName([this.WFEditOptions.Layout], this.ElemName, false);
        if (element && element['CustomEvents']) {
            this.CustomEvents = element['CustomEvents'];
        }
    }
}
// @dynamic
export class TriggerEventSettingsData extends AServiceWorkflowData {
    EventName: string = "";
    ElemName: string = "";
    Formula: string = "";
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.TriggerEvent.TriggerEventSettingsData';
    }
}
export class TriggerEventWFSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new TriggerEventSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class TriggerEventWFModuleExecuter extends WorkflowModuleExecuter {

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                
                const elem = MetaHelper.FindParentCustomEvent(status.WorkflowLayoutService.Layout, status.WorkflowLayoutService.ActualElement, status.ActualSettings.EventName);
                if (elem) {
                    if (status.ActualSettings.Formula) {
                        const calculator = new WorkflowFormulaCalculator(status);
                        const content = calculator.CalcFormula(status.ActualSettings.Formula);

                        elem.Element.triggerEvent(status.ActualSettings.EventName, content);
                    } else {
                        status.Logger.logError('TriggerEventWF modul: No Formula set.');
                    }
                } else {
                    status.Logger.logError('TriggerEventWF modul: Element not found.');
                }
            } else {
                status.Logger.logError('TriggerEventWF modul: No element name found.');
            }
        } else {
            status.Logger.logError('TriggerEventWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
