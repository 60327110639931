import { BehaviorSubject } from 'rxjs';
import { MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { LayoutChangeType } from '../models/enums/layoutchangetype.enum';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../models/enums/messageboxresult.enum';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { ViewType } from '../models/enums/viewtype.enum';
import { LayoutChange } from '../models/layout/layout.change.model';
import { CollaborationService } from '../services/collaboration.service';
import { LayoutService } from '../services/layout.service';
import { UsersService } from '../services/users.service';
import { WebsocketService } from '../services/websocket.service';
import { TranslateFormatText } from './array.helpers';
import { ClientHelper } from './client.helper';
import { CollaborationHelper } from './collaboration.helper';
import { InjectorHelper } from './injector.helper';
import { LayoutHelper } from './layout.helper';
import { NotificationHelper } from './notification.helper';

export interface ILayoutChangeClient {
    get LayoutElement();

    checkForChangedLayout(): Promise<void>;
    detectChanges();
    refreshAndQuit();
}

export class LayoutChangeStatusHandler {

    private static Instance: LayoutChangeStatusHandler;
    public static LayoutChangeHandlerConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public static LayoutChangeHandlerViewMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public static LayoutChangeHandlerMessages: BehaviorSubject<any[]> = new BehaviorSubject(null);

    private Connected = false;
    private WebSocketCheckActive = false;
    private Loading = true;
    private CachedChanges = [];
    private Subscriptions = [];
    private ColService: CollaborationService;
    private AliveInterval;

    public static CheckForInit(channel: string, client: ILayoutChangeClient) {
        if (!LayoutChangeStatusHandler.Instance) {
            LayoutChangeStatusHandler.Instance = new LayoutChangeStatusHandler(channel, client);
            const userNames = CollaborationHelper.GetActiveUserNames(channel);
            if (userNames.length > 0) {
                const tft = new TranslateFormatText('@@PageAlreadyEditedBy{0}');
                tft.FormatParams.push(userNames.join(', '));
                MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Edit page'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                //MessageBoxHelper.ShowDialog(new TranslateFormatText('@@ChangeToEditMode'), new TranslateFormatText('@@Edit page'),
                //    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                //        if (x === MessageBoxResult.Yes) {
                //            LayoutChangeStatusHandler.CheckForCheckOut();
                //        }
                //    });
            } else {
                LayoutChangeStatusHandler.CheckForCheckOut();
            }
        }
    }

    public static Unsubscribe() {
        if (LayoutChangeStatusHandler.Instance) {
            LayoutChangeStatusHandler.Instance.unsubscribe();
            LayoutChangeStatusHandler.Instance = null;
        }
    }

    private static CheckForCheckOut() {
        const checkOut = CollaborationHelper.ActualCheckOutUser.getValue();
        if (checkOut) {
            const user = UsersService.ActiveUser.getValue();
            if (checkOut.ID === user.SID) {
                LayoutChangeStatusHandler.Instance.ConnectInternal();
            } else if (user.IsAdministrator) {
                const text = '@@Diese Seite ist bereits von Benutzer {0} ausgecheckt. Wollen Sie den Checkout uebernehmen?';
                const tf = new TranslateFormatText(text);
                tf.FormatParams.push(checkOut.Name);
                MessageBoxHelper.ShowDialog(tf, new TranslateFormatText('@@Seite bereits ausgecheckt'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(y => {
                        if (y === MessageBoxResult.Yes) {
                            CollaborationHelper.CheckOut(true, true);
                            LayoutChangeStatusHandler.Instance.ConnectInternal();
                        }
                    });
            } else {
                const text = '@@Diese Seite ist bereits von Benutzer {0} ausgecheckt.';
                const tf = new TranslateFormatText(text);
                tf.FormatParams.push(checkOut.Name);
                MessageBoxHelper.ShowDialog(tf, new TranslateFormatText('@@Seite bereits ausgecheckt'),
                    MessageBoxButtons.Ok, MessageBoxIcon.Information);
            }
        } else {
            LayoutChangeStatusHandler.Instance.ConnectInternal();
        }
    }

    private static GetPropChangeText(layout, obj): string {
        if (layout) {
            const changeInfo = LayoutChangeStatusHandler.GetPropChangeTextInternal(layout, obj);
            if (changeInfo) {
                let retVal = 'Changed ';
                Object.keys(changeInfo).forEach((k, i) => {
                    if (i > 0) {
                        retVal += ' and ';
                    }
                    retVal += 'property ' + k + ' on element';
                    const list = changeInfo[k];
                    if (list.length > 1) {
                        retVal += 's';
                    }
                    retVal += ' ' + list.join(', ');
                });
                return retVal;
            }
        }
        return null;
    }

    private static GetPropChangeTextInternal(layoutElement, obj) {
        let retVal = null;
        if (layoutElement) {
            const propList = obj[layoutElement.ID];
            if (Array.isArray(propList) && propList.length > 0) {
                propList.forEach(prop => {
                    if (retVal == null) {
                        retVal = {};
                    }
                    const list = retVal[prop.PropertyName];
                    if (list) {
                        list.push(LayoutHelper.GetElementName(layoutElement));
                    } else {
                        retVal[prop.PropertyName] = [LayoutHelper.GetElementName(layoutElement)];
                    }
                });
            }
            if (layoutElement.Elements) {
                layoutElement.Elements.forEach(x => {
                    const nextLevel = LayoutChangeStatusHandler.GetPropChangeTextInternal(x, obj);
                    if (nextLevel) {
                        if (retVal == null) {
                            retVal = {};
                        }
                        Object.keys(nextLevel).forEach(k => {
                            const list = retVal[k];
                            if (list) {
                                list.push(...nextLevel[k]);
                            } else {
                                retVal[k] = [...nextLevel[k]];
                            }
                        });
                    }
                });
            }
        }
        return retVal;
    }

    private static GetChangeText(layout, change: LayoutChange): string {
        let retVal = null;
        if (layout && change && change.ChangeValue) {
            let searchLayout = layout;
            let resName;
            if (change.ResolutionID) {
                if (layout && layout.Resolutions) {
                    searchLayout = layout.Resolutions.find(x => x.ID === change.ResolutionID);
                    resName = LayoutHelper.GetElementName(searchLayout);
                } else {
                    searchLayout = null;
                }
            }
            if (searchLayout) {
                switch (change.LayoutChangeType) {
                    case LayoutChangeType.ElementAdded:
                        const addTargetElem = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.ParentID);
                        if (addTargetElem) {
                            retVal = 'Added element ' + LayoutHelper.GetElementName(JSON.parse(change.ChangeValue.ElementContent)) +
                                ' to element ' + LayoutHelper.GetElementName(addTargetElem);
                        }
                        break;
                    case LayoutChangeType.ElementRemoved:
                        const removeTargetElem = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.ParentID);
                        if (removeTargetElem) {
                            retVal = 'Removed element ' + change.ChangeValue.ElementName +
                                ' from element ' + LayoutHelper.GetElementName(removeTargetElem);
                        }
                        break;
                    case LayoutChangeType.PositionChange:
                        const posChangeSourceElem = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.OldParentID);
                        if (posChangeSourceElem) {
                            if (change.ChangeValue.OldParentID === change.ChangeValue.NewParentID) {
                                if (posChangeSourceElem.Elements) {
                                    posChangeSourceElem.Elements.some((x, i) => {
                                        if (x.ID === change.ChangeValue.ElementID) {
                                            retVal = 'Moved element ' + LayoutHelper.GetElementName(x) +
                                                ' inside element ' + LayoutHelper.GetElementName(posChangeSourceElem);
                                            return true;
                                        }
                                        return false;
                                    });
                                }                                
                            } else {
                                const posChangeTargetElem = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.NewParentID);
                                if (posChangeTargetElem) {
                                    posChangeTargetElem.Elements.some((x) => {
                                        if (x.ID === change.ChangeValue.ElementID) {
                                            retVal = 'Moved element ' + LayoutHelper.GetElementName(x) + ' from element ' +
                                                LayoutHelper.GetElementName(posChangeSourceElem) + ' to element ' +
                                                LayoutHelper.GetElementName(posChangeTargetElem);
                                            return true;
                                        }
                                        return false;
                                    });
                                }
                            }
                        }
                        break;
                    case LayoutChangeType.MultiPositionChange:
                        if (change.ChangeValue.Elements) {
                            const targetElem = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.NewParentID);
                            if (targetElem && targetElem.Elements) {
                                const sourceElems = {};
                                const removedElems = {};
                                change.ChangeValue.Elements.forEach(lpcElem => {
                                    if (lpcElem.Elements && lpcElem.Elements.length > 0) {
                                        let sourceElem;
                                        if (lpcElem.ParentID === change.ChangeValue.NewParentID) {
                                            sourceElem = targetElem;
                                        } else {
                                            sourceElem = sourceElems[lpcElem.ParentID];
                                            if (!sourceElem) {
                                                sourceElem = LayoutHelper.GetElementByID(searchLayout, lpcElem.ParentID);
                                                if (sourceElem) {
                                                    sourceElems[lpcElem.ParentID] = sourceElem;
                                                }
                                            }
                                        }
                                        if (sourceElem) {
                                            const removedList = [];
                                            lpcElem.Elements.forEach(lpcElemElem => {
                                                targetElem.Elements.some((x) => {
                                                    if (x.ID === lpcElemElem.ElementID) {
                                                        removedList.push(LayoutHelper.GetElementName(x));
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                            });
                                            if (removedList.length > 0) {
                                                const rE = removedElems[lpcElem.ParentID];
                                                if (rE) {
                                                    rE.RemovedElements.push(...removedList);
                                                } else {
                                                    removedElems[lpcElem.ParentID] = {
                                                        Name: LayoutHelper.GetElementName(sourceElem),
                                                        RemovedElements: removedList
                                                    };
                                                }
                                            }
                                        }
                                    }
                                });
                                const remKeys = Object.keys(removedElems);
                                if (remKeys.length > 0) {
                                    retVal = 'Moved ';
                                    remKeys.forEach((x, i) => {
                                        if (i > 0) {
                                            retVal += ' and '
                                        }
                                        const elem = removedElems[x];
                                        retVal += 'element';
                                        if (elem.RemovedElements.length > 0) {
                                            retVal += 's';
                                        }
                                        retVal += ' ' + elem.RemovedElements.join(', ') + ' from element ' + elem.Name;
                                    });
                                    retVal += ' to element ' + LayoutHelper.GetElementName(targetElem);
                                }
                            }
                        }
                        break;
                    case LayoutChangeType.PropertyChange:
                        const changePropObj = {};
                        changePropObj[change.ChangeValue.ElementID] = [change.ChangeValue];
                        retVal = LayoutChangeStatusHandler.GetPropChangeText(searchLayout, changePropObj);
                        break;
                    case LayoutChangeType.MultiPropertyChange:
                        const multiChangePropObj = {};
                        change.ChangeValue.Elements.forEach(x => {
                            x.ElementIDs.forEach(id => {
                                const list = multiChangePropObj[id];
                                if (list) {
                                    list.push(...x.Properties);
                                } else {
                                    multiChangePropObj[id] = [...x.Properties];
                                }
                            });
                        });
                        retVal = LayoutChangeStatusHandler.GetPropChangeText(searchLayout, multiChangePropObj);
                        break;
                    case LayoutChangeType.WorkflowAdded:
                        const wfAddParent = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.LayoutID);
                        if (wfAddParent) {
                            const wf = JSON.parse(change.ChangeValue.Workflow);
                            retVal = 'Added workflow ' + wf.Caption + ' on element ' + LayoutHelper.GetElementName(wfAddParent);
                        }
                        break;
                    case LayoutChangeType.WorkflowChange:
                        const wfChangeParent = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.LayoutID);
                        if (wfChangeParent && wfChangeParent.Workflows) {
                            const changedList = [];
                            const wfList = {};
                            change.ChangeValue.Workflows.forEach(x => {
                                const wfD = JSON.parse(x);
                                wfList[wfD.ID] = wfD.Caption;
                            });
                            for (let i = 0; i < wfChangeParent.Workflows.length; i++) {
                                const wfD = wfList[wfChangeParent.Workflows[i].ID];
                                if (wfD) {
                                    changedList.push(wfD);
                                }
                            }
                            if (changedList.length > 0) {
                                retVal = 'Changed workflow';
                                if (changedList.length > 1) {
                                    retVal += 's';
                                }
                                retVal += ' ' + changedList.join(', ') + ' on element ' + LayoutHelper.GetElementName(wfChangeParent);
                            }
                        }
                        break;
                    case LayoutChangeType.WorkflowRemoved:
                        const wfRemoveParent = LayoutHelper.GetElementByID(searchLayout, change.ChangeValue.LayoutID);
                        if (wfRemoveParent) {
                            retVal = 'Removed workflow ' + change.ChangeValue.WorkflowCaption + ' from element ' +
                                LayoutHelper.GetElementName(wfRemoveParent);
                        }
                        break;
                    case LayoutChangeType.ResolutionAdded:
                        const addedRes = JSON.parse(change.ChangeValue.Resolution);
                        retVal = 'Added resolution ' + LayoutHelper.GetElementName(addedRes) +
                            ' on element ' + LayoutHelper.GetElementName(layout);
                        break;
                    case LayoutChangeType.ResolutionRemoved:
                        retVal = 'Removed resolution ' + change.ChangeValue.ResolutionName +
                            ' from element ' + LayoutHelper.GetElementName(layout);
                        break;
                }
                if (retVal && resName) {
                    retVal += ' (Resolution: ' + resName + ')';
                }
            }
        }
        return retVal;
    }

    private static GetChangeInfoMessage(text, content) {
        if (text) {
            let message = 'Layout change';
            if (content.User) {
                message += ' by user "' + content.User.Name + '"';
            }
            message += ': ' + text;
            const date = new Date(content.ChangeDate);
            return {
                MessageTimeText: date.toLocaleDateString(undefined, {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }),
                Message: message,
                Sender: 'System',
                OwnMessage: false
            };
        }
        return null;
    }

    constructor(private Channel: string, private client: ILayoutChangeClient) {
        CollaborationHelper.Subscribe(this.Channel, true);
        LayoutChangeStatusHandler.LayoutChangeHandlerViewMode.next(true);
        this.ColService = InjectorHelper.InjectorInstance.get<CollaborationService>(CollaborationService);
        this.AliveInterval = setInterval(() => {
            this.ColService.SubscriptionAlive(this.Channel).subscribe();
        }, 4 * 60 * 1000); // 4 Min.
    }

    private ConnectInternal() {
        this.client.checkForChangedLayout().then(() => {
            if (!this.Connected) {
                if (WebsocketService.Active.getValue()) {
                    this.subscribe();
                } else {
                    const tf = new TranslateFormatText('@@LayoutChangeHandler No WebSocketConnection');
                    MessageBoxHelper.ShowDialog(tf, new TranslateFormatText('@@No Connection'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Information);
                }
            }
        });
    }

    private subscribe() {
        if (!this.Connected) {
            this.Connected = true;
            let checkDate = new Date();
            this.Subscriptions.push(WebsocketService.Active.subscribe(x => {
                if (!x && !this.WebSocketCheckActive) {
                    this.WebSocketCheckActive = true;
                    const tf = new TranslateFormatText('@@LayoutChangeHandler WebSocketConnection Lost');
                    MessageBoxHelper.ShowDialog(tf, new TranslateFormatText('@@Connection Lost'),
                        MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                            this.WebSocketCheckActive = false;
                            if (x === MessageBoxResult.Yes) {
                                this.client.refreshAndQuit();
                            }
                        });
                }
            }));
            this.Subscriptions.push(WebsocketService.MessageReceived.subscribe(response => {
                const parsed = JSON.parse(response);
                const ccChannel = 'ClientChange_' + this.Channel;
                if (parsed.Level === NotificationLevel.System && parsed.Channels &&
                    parsed.Channels.some(x => x === ccChannel)) {
                    if (parsed.Type === 'evidanza.App.Shared.Collaboration.ClientChangeInfo') {
                        const content = JSON.parse(parsed.Content);
                        if (content) {
                            let text;
                            if (content.ClientID !== ClientHelper.ClientID) {
                                if (this.Loading) {
                                    this.CachedChanges.push(content);
                                } else {
                                    text = LayoutHelper.ApplyLayoutChange(this.client.LayoutElement, JSON.parse(content.ChangeInfo));
                                }
                            } else {
                                text = LayoutChangeStatusHandler.GetChangeText(this.client.LayoutElement, JSON.parse(content.ChangeInfo));
                            }
                            if (text) {
                                const message = LayoutChangeStatusHandler.GetChangeInfoMessage(text, content);
                                if (message) {
                                    const newMessages = [];
                                    const oldMessages = LayoutChangeStatusHandler.LayoutChangeHandlerMessages.getValue();
                                    if (oldMessages) {
                                        newMessages.push(...oldMessages);
                                    }
                                    newMessages.push(message);
                                    LayoutChangeStatusHandler.LayoutChangeHandlerMessages.next(newMessages);
                                }
                                this.client.detectChanges();
                            }
                        }
                    } else if (parsed.Type === 'evidanza.App.Shared.Collaboration.ClientSaveInfo') {
                        const content = JSON.parse(parsed.Content);
                        if (content && content.ClientID !== ClientHelper.ClientID) {
                            const settings = CollaborationHelper.Settings.getValue();
                            if (!settings || settings.ShowSaveMessage) {
                                const tf = new TranslateFormatText('@@ClientSave by user {0}');
                                tf.FormatParams.push(content.User.Name);
                                MessageBoxHelper.ShowDialog(tf, new TranslateFormatText('@@ClientSave'),
                                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                                        if (x === MessageBoxResult.Yes) {
                                            this.client.refreshAndQuit();
                                        }
                                    });
                            } else {
                                const tf = new TranslateFormatText('@@ClientSaveInfo by user {0}');
                                tf.FormatParams.push(content.User.Name);
                                NotificationHelper.Info(tf, '@@Information');
                            }
                        }
                    }
                }
            }));
            WebsocketService.Subscribe([{ Channel: 'ClientChange_' + this.Channel, Level: [0] }]);

            this.Subscriptions.push(LayoutService.LayoutChanged.subscribe(x => {
                if (x && LayoutService.ViewType.getValue() === ViewType.Edit) {
                    this.ColService.SendClientChangeInfo({
                        Channel: this.Channel,
                        ChangeInfo: JSON.stringify(x)
                    }).subscribe();
                }
            }));
            this.Subscriptions.push(CollaborationHelper.ForcedCheckout.subscribe((x) => {
                const tf = new TranslateFormatText('@@Der Checkout der Seite wurde von Benutzer {0} ueberschrieben');
                tf.FormatParams.push(x.Name);
                NotificationHelper.Info(tf, new TranslateFormatText('@@Checkout ueberschrieben'));
                this.client.refreshAndQuit();
            }));
            this.ColService.GetAllClientChanges(this.Channel).subscribe(x => {
                const messages = [];
                const le = this.client.LayoutElement;
                if (x) {
                    x.forEach(change => {
                        checkDate = new Date(change.ChangeDate);
                        const text = LayoutHelper.ApplyLayoutChange(le, JSON.parse(change.ChangeInfo));
                        const message = LayoutChangeStatusHandler.GetChangeInfoMessage(text, change);
                        if (message) {
                            messages.push(message);
                        }
                    });
                }
                let copy = this.CachedChanges.splice(0);
                copy.forEach(change => {
                    const date = new Date(change.ChangeDate);
                    if (date > checkDate) {
                        const text = LayoutHelper.ApplyLayoutChange(le, JSON.parse(change.ChangeInfo));
                        const message = LayoutChangeStatusHandler.GetChangeInfoMessage(text, change);
                        if (message) {
                            messages.push(message);
                        }
                    }
                });
                this.Loading = this.CachedChanges.length > 0;
                while (this.Loading) {
                    copy = this.CachedChanges.splice(0);
                    copy.forEach(change => {
                        const text = LayoutHelper.ApplyLayoutChange(le, JSON.parse(change.ChangeInfo));
                        const message = LayoutChangeStatusHandler.GetChangeInfoMessage(text, change);
                        if (message) {
                            messages.push(message);
                        }
                    });
                    this.Loading = this.CachedChanges.length > 0;
                }
                LayoutChangeStatusHandler.LayoutChangeHandlerMessages.next(messages);
                this.client.detectChanges();
            });
            LayoutChangeStatusHandler.LayoutChangeHandlerConnected.next(true);
            LayoutChangeStatusHandler.LayoutChangeHandlerViewMode.next(false);
        }
    }

    private unsubscribe() {
        this.Subscriptions.forEach(x => x.unsubscribe());
        WebsocketService.Unsubscribe([{ Channel: 'ClientChange_' + this.Channel, Level: [0] }]);
        clearInterval(this.AliveInterval);
        CollaborationHelper.Subscribe(this.Channel, false);
        LayoutChangeStatusHandler.LayoutChangeHandlerConnected.next(false);
        LayoutChangeStatusHandler.LayoutChangeHandlerViewMode.next(false);
        LayoutChangeStatusHandler.LayoutChangeHandlerMessages.next(null);
    }
}
