import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	Injector,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { InjectorHelper } from '../../helpers/injector.helper';
import { TranslatedString } from '../../models/translatedstring.model';
import { SelfServiceSecurityService } from '../../services/datamodel.service';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { RolesService } from '../../services/roles.service';
import { UsersService } from '../../services/users.service';
import {
	BaseListDetail,
	BaseListSettings,
	DeleteTexts,
	SaveTexts,
} from '../base.list.settings';
import { EnumSecuritySettings } from './enumSec/enumsec.settings';
import { PermissionsPage } from './permissions/permissions.page';
import config from '../../../assets/config.json';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
	selector: 'roles-settings',
	templateUrl: '../base.list.settings.html',
	styleUrls: ['./roles.settings.css'],
})
export class RoleSettings extends BaseListSettings {
	static RoleNode: BehaviorSubject<any> = new BehaviorSubject(null);
	ExpandedItem: string[] = [];
	constructor(
		private rolesService: RolesService,
		private userService: UsersService,
		protected factoryResolver: ComponentFactoryResolver,
		private translate: TranslateService,
		protected cdRef: ChangeDetectorRef,
		private securityService: SelfServiceSecurityService,
		private router: Router
	) {
		super(factoryResolver, cdRef);

		this.ShowSave = false;
		this.ShowDelete = false;
	}

	static GetSettingsEntry() {
		return {
			Caption: '@@Rollen',
			ID: 'roles',
			Icon: 'security',
			Index: 2,
			Parent: 'userCont',
			Security: {
				Name: 'evidanza.App.Shared.Security.UsermanagementRight',
				Value: 8,
			},
			Children: [],
			UpdateFunction: RoleSettings.UpdateRoles,
		};
	}
    init() {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            this.isFreeUser = true;
            this.router.navigate(['/default/settings/access-denied'])
            return;
        }
		this.InitArgs = RoleSettings.RoleNode.getValue();
	}
	ToggleExpandItem(item: string) {
		if (this.ExpandedItem.includes(item)) {
			this.ExpandedItem.splice(this.ExpandedItem.indexOf(item), 1);
			return;
		}
		this.ExpandedItem.push(item);
	}
    static UpdateRoles(nav) {
		if (nav) {
			nav.Children = [];
			const ms = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
			ms.GetNavigationStructures().subscribe((ns) => {
				nav.NavigationStructures = ns;
				const rs =
					InjectorHelper.InjectorInstance.get<RolesService>(RolesService);
				rs.ReadRoleInfos().subscribe((result) => {
					const list = [];
					let index = 0;
					list.push({
						Caption: '@@Uebersicht',
						ID: 'role-overview',
						Icon: 'menu',
						Index: index++,
						Content: RoleSettings,
                    });
                    const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
                    if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
                        nav.Children = list;
                        RoleSettings.RoleNode.next(nav);
                        NavigationService.Refresh.next(null);
                        return;
                    }
					if (result) {
						const roleList = [];
						result.forEach((role) => {
							const node = RoleSettings.CreateRoleNode(role, ns);
							node['Index'] = index++;
							roleList.push(node);
						});
						ArrayHelpers.sortAlphabetical(roleList, 'Caption');
						list.push(...roleList);
					}
					nav.Children = list;
					RoleSettings.RoleNode.next(nav);
					NavigationService.Refresh.next(null);
				});
			});
		}
	}

	private static CreateRoleNode(role, ns) {
		const retVal = {
			Caption: role.Name,
			ID: role.Id,
			Icon: 'radio_button_unchecked',
			Children: [],
		};
		retVal.Children.push({
			Caption: '@@Berechtigungen',
			ID: 'enums',
			Icon: 'security',
			Index: 0,
			Content: EnumSecuritySettings,
			InitArgs: role,
		});
		if (!RolesService.IsSystemRole(role.Id)) {
			//retVal.Children.push({
			//    Caption: '@@Integrierte Rollen',
			//    ID: role.Id + '_childRoles',
			//    Icon: 'list',
			//    Index: 1,
			//    Content: ChildRoleSettings,
			//    InitArgs: role
			//});
			if (ns && ns.length > 0) {
				const layoutNode = {
					Caption: '@@Layout',
					ID: 'layout',
					Icon: 'aspect_ratio',
					Index: 2,
					Children: [],
				};
				ns.forEach((x, i) => {
					layoutNode.Children.push({
						Caption: x.Caption,
						ID: x.SID,
						Icon: 'radio_button_unchecked',
						Index: i,
						Content: PermissionsPage,
						InitArgs: {
							RoleID: role.Id,
							StructureID: x.SID,
							StructureKey: x.Caption,
						},
					});
				});
				retVal.Children.push(layoutNode);
			}
			// retVal.Children.push({
			//     Caption: '@@Container',
			//     ID: 'container',
			//     Icon: 'description',
			//     Index: 3,
			//     Content: ContainerSecuritySettings,
			//     InitArgs: role
			// });
		}
		return retVal;
	}

	getContentType() {
		return RoleDetail;
	}

	loadList(handler) {
		this.rolesService.ReadRoleInfos().subscribe((result) => {
			if (result) {
				const rolesList = [];
				result.forEach((role) => {
					if (role.Id === '0a5d664e-be8d-4a8c-b748-0d4793280d0d') {
						rolesList.push({
							Caption: role.Name,
							ID: role.Id,
							CanNotDelete: true,
							CanNotEdit: true,
						});
						return;
					}
					rolesList.push({ Caption: role.Name, ID: role.Id });
				});
				this.userService.ReadUserInfos().subscribe((result) => {
					if (result) {
						rolesList.forEach((role) => {
							role['Users'] = [];
							result.forEach((entry) => {
								if (entry['Roles'] && entry['Roles'].indexOf(role.ID) >= 0) {
									let name = entry.Name;
									if(name.indexOf("@")){
										name = name.split("@")[0]
									}
									role['Users'].push(name);
								}
							});
						});
					}
					handler(rolesList);
				});
			}
		});
	}

	loadData(data) {
		this.rolesService.FindRole(data).subscribe((result) => {
			if (result) {
				this.setSelectedItem(result);
			}
		});
	}

	setSelectedItem(item) {
		super.setSelectedItem(item);
		if (!item || RolesService.IsSystemRole(item.SID)) {
			this.ShowSave = false;
			this.ShowDelete = false;
		} else {
			this.ShowSave = true;
			this.ShowDelete = true;
		}
	}

	getNewItem() {
		return {
			Name: new TranslatedString(this.translate.instant('@@Neue Rolle')),
			IsAdministrator: false,
		};
	}

	getDeleteText(sel): DeleteTexts {
		const retVal = new DeleteTexts();
		retVal.Question = new TranslateFormatText(
			"@@Sind Sie sicher, dass Sie die Rolle '{0}' loeschen moechten?"
		);
		retVal.Question.FormatParams.push(sel.Caption);
		retVal.Success = new TranslateFormatText(
			"@@Rolle '{0}' erfolgreich geloescht."
		);
		retVal.Success.FormatParams.push(sel.Caption);
		retVal.Title = new TranslateFormatText('@@Rolle loeschen');
		return retVal;
	}

	getSaveSuccessText(sel): SaveTexts {
		let caption = TranslatedString.GetTranslation(sel.Name);
		if (!caption) {
			caption = sel.SID;
		}
		const retVal = new SaveTexts();
		retVal.Text = new TranslateFormatText(
			"@@Rolle '{0}' erfolgreich gespeichert."
		);
		retVal.Text.FormatParams.push(caption);
		retVal.Title = new TranslateFormatText('@@Rolle speichern');
		return retVal;
	}

	delete(data, handler) {
		this.securityService.DeleteCompleteRole(data).subscribe((res) => {
			handler(res);
		});
		const node = RoleSettings.RoleNode.getValue();
		if (node && node.Children) {
			for (let i = 0; i < node.Children.length; i++) {
				if (node.Children[i].ID === data) {
					node.Children.splice(i, 1);
					break;
				}
			}
		}
	}

	saveInternal(item, handler) {
		if (item?.Name?.DefaultValue?.trim() == '') {
			NotificationHelper.Error('Name field is required', '@@Error');
			LayoutService.Loading.next(false);
			return;
		}

		this.rolesService.AddOrUpdateRole({ Role: item }).subscribe((result) => {
			if (result) {
				handler(result, result.SID, result.Caption);
			}
		});
	}

	handleNew(item, result) {
		item.SID = result.SID;
		item.Version = result.Version;
		const node = RoleSettings.RoleNode.getValue();
		if (node && node.Children) {
			const child = RoleSettings.CreateRoleNode(
				{
					Id: result.SID,
					Name: result.Caption,
				},
				node.NavigationStructures
			);
			node.Children.push(child);
		}
	}
}

@Component({
	selector: 'roles-detail',
	templateUrl: './roles.settings.html',
	styleUrls: ['./roles.settings.css'],
})
export class RoleDetail extends BaseListDetail {
	CanSave = false;

	setSelectedItem(item) {
		super.setSelectedItem(item);
		this.CanSave = item && !RolesService.IsSystemRole(item.SID);
	}
}
