import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CacheService } from '../../cache/cache.service';
import { TranslateHelper } from '../../helpers/injector.helper';
import { VariableHelper } from '../../helpers/variable.helper';

export class VariableInfo {
    IsGlobal = false;
    ElemName: string;
    VariableName: string;
}

@Component({
    selector: 'variable-chooser-component',
    templateUrl: './variable.chooser.html'
})
export class VariableChooser implements OnInit {
    GlobalList = [];
    LocalList = [];

    //#region Layout
    LayoutValue;
    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.LayoutChange.emit(this.LayoutValue);
    }
    @Output() LayoutChange = new EventEmitter<any>();
    //#endregion

    //#region SelectedVariable
    SelectedVariablesValue: VariableInfo[] = [];
    @Input()
    get SelectedVariables(): VariableInfo[] {
        return this.SelectedVariablesValue;
    }
    set SelectedVariables(val: VariableInfo[]) {
        this.SelectedVariablesValue = val;
        this.SelectedVariablesChange.emit(this.SelectedVariablesValue);
    }
    @Output() SelectedVariablesChange = new EventEmitter<any>();
    //#endregion

    //#region Placeholder
    PlaceholderValue = '@@Variablen';
    @Input()
    get Placeholder() {
        return this.PlaceholderValue;
    }
    set Placeholder(val) {
        this.PlaceholderValue = val;
        this.PlaceholderChange.emit(this.PlaceholderValue);
    }
    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    //#region VariableType
    VariableTypeValue;
    @Input()
    get VariableType() {
        return this.VariableTypeValue;
    }
    set VariableType(val) {
        this.VariableTypeValue = val;
        this.VariableTypeChange.emit(this.VariableTypeValue);
    }
    @Output() VariableTypeChange = new EventEmitter<any>();
    //#endregion

    @Output() VariablesChanged = new EventEmitter<any>();

    private static GetVariables(element, varType, selected) {
        const retVal = [];
        if (element.Name && element.Variables) {
            let variables = element.Variables;
            if (varType) {
                variables = variables.filter(x => x.Type === varType);
            }
            variables.forEach(v => {
                const info = {
                    Caption: v.Name,
                    Data: new VariableInfo(),
                    IsSelected: false
                };
                info.Data.ElemName = element.Name;
                info.Data.VariableName = v.Name;
                const dt = VariableHelper.GetTypeCaption(v.Type);
                if (dt) {
                    info.Caption = v.Name + ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ', ' + element.Name + ')';
                } else {
                    info.Caption = v.Name + ' (' + element.Name + ')';
                }
                selected.some((x, i) => {
                    if (!x.Value.IsGlobal && x.Value.ElemName === element.Name && x.Value.VariableName === v.Name) {
                        info.IsSelected = true;
                        selected.splice(i, 1);
                        return true;
                    }
                    return false;
                });
                retVal.push(info);
            });
        }
        if (element.Elements) {
            element.Elements.forEach(x => {
                retVal.push(...VariableChooser.GetVariables(x, varType, selected));
            });
        }
        return retVal;
    }

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        CacheService.ReadGlobalVariables().then((vars) => {
            const selected = [];
            if (this.SelectedVariablesValue) {
                this.SelectedVariablesValue.forEach((x, i) => {
                    selected.push({
                        Value: x,
                        Index: i
                    });
                });
            }
            if (vars) {
                const globalList = [];
                if (this.VariableTypeValue) {
                    vars = vars.filter(x => x.Type === this.VariableTypeValue);
                }
                vars.forEach(v => {
                    const info = {
                        Caption: v.Name,
                        Data: new VariableInfo(),
                        IsSelected: false
                    };
                    info.Data.IsGlobal = true;
                    info.Data.VariableName = v.Name;
                    const dt = VariableHelper.GetTypeCaption(v.Type);
                    if (dt) {
                        info.Caption += ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ')';
                    }
                    selected.some((x, i) => {
                        if (x.Value.IsGlobal && x.Value.VariableName === v.Name) {
                            info.IsSelected = true;
                            selected.splice(i, 1);
                            return true;
                        }
                        return false;
                    });
                    globalList.push(info);
                });
                this.GlobalList = globalList;
            }
            if (this.LayoutValue) {
                this.LocalList = VariableChooser.GetVariables(this.LayoutValue, this.VariableTypeValue, selected);
            }
            if (selected.length > 0) {
                for (let i = selected.length - 1; i >= 0; i--) {
                    this.SelectedVariablesValue.splice(selected[i].Index, 1);
                }
                this.VariablesChanged.emit();
            }
            this.cdRef.detectChanges();
        });
    }

    checkItem(item) {
        if (item) {
            if (item.IsSelected) {
                if (this.SelectedVariablesValue) {
                    this.SelectedVariablesValue.push(item.Data);
                } else {
                    this.SelectedVariables = [item.Data];
                }
                this.VariablesChanged.emit();
            } else if (this.SelectedVariablesValue) {
                if (item.Data.IsGlobal) {
                    if (this.SelectedVariablesValue.some((x, i) => {
                        if (x.IsGlobal && x.VariableName === item.Data.VariableName) {
                            this.SelectedVariablesValue.splice(i, 1);
                            return true;
                        }
                        return false;
                    })) {
                        this.VariablesChanged.emit();
                    }
                } else {
                    if (this.SelectedVariablesValue.some((x, i) => {
                        if (!x.IsGlobal && x.ElemName == item.Data.ElemName && x.VariableName === item.Data.VariableName) {
                            this.SelectedVariablesValue.splice(i, 1);
                            return true;
                        }
                        return false;
                    })) {
                        this.VariablesChanged.emit();
                    }
                }
            }
        }
    }
}
