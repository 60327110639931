import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Alignment } from '../../../../models/enums/alignment.enum';


@Component({
  selector: 'verticalalignment-action',
  templateUrl: './verticalalignment.action.html',
  styleUrls: ['./verticalalignment.action.css'],
})
export class VerticalAlignmentAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'verticalalignment';
    
    //#region VerticalAlignment
    @Input()
    get VerticalAlignment() {
        if (this.SelectedItemValue) {
            return this.SelectedItemValue.VerticalContentAlignment;
        }
        return Alignment.Start;
    }
    set VerticalAlignment(val) {
        if (this.SelectedItemValue) {
            this.SelectedItemValue.VerticalContentAlignment = val;
        }
        this.VerticalAlignmentChange.emit(val);
    }

    @Output() VerticalAlignmentChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {

    }
    ExecuteAction() {

    }
    setTop() {
        this.VerticalAlignment = Alignment.Start;
    }

    setCenterVert() {
        this.VerticalAlignment = Alignment.Center;
    }

    setBottom() {
        this.VerticalAlignment = Alignment.End;
    }
    Visibility() {
        return true;
    }
}
