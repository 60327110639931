import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {MetaService} from 'src/app/services/meta.service';
import {LayoutService} from 'src/app/services/layout.service';
import {NotificationHelper} from 'src/app/helpers/notification.helper';
import {MessageBoxHelper} from 'src/app/components/dialogs/messagebox/messagebox.dialog';
import {MessageBoxButtons} from 'src/app/models/enums/messageboxbuttons.enum';
import {MessageBoxResult} from 'src/app/models/enums/messageboxresult.enum';
import {TranslateFormatText} from 'src/app/helpers/array.helpers';
import {MessageBoxIcon} from 'src/app/models/enums/messageboxicon.enum';
import {CreateDialogFlagService} from '../../create.flag.service';

@Component({
    selector: 'lib-my-apps',
    templateUrl: './my-apps.component.html',
    styleUrls: ['./my-apps.component.css'],
})
export class MyAppsComponent implements OnInit {
    isShowCreateDialog = false;
    SearchValue: string;
    FilteredList: NavigationInterface[];
    List: NavigationInterface[];
    NavigationSubscription: any;
    EditItem: any = {};
    RenderList: NavigationInterface[];
    isEditing: boolean = false;
    isApplicationProcess: boolean = false;
    importApp: boolean = false;
    isLoading = false;
    createDialogAction: 'application' | 'workflow' | 'both';
    cards: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    title = {
        DisplayName: '',
    };
    ModifiedKey: string = '';

    selectedFile: File | null = null;
    Icons = ['coffee', 'anchor', 'move_down', 'keyboard_command', 'event_list', 'terminal', 'flight', 'cycle'];

    Colors = [
        '#F4FFDE',
        '#FBF4ED',
        '#F3F1C7',
        '#F5D1D1',
        '#C7F3E3',
        '#FBF4ED',
        '#C7F3F0',
        '#E4D8CC',
        '#FFEBFB',
        '#F3F1C7',
        '#EBEDFB',
        '#FFEFDB',
        '#D5D0F1',
        '#CCCCCC',
        '#FFFAED',
        '#F2FFD6',
        '#FDFCEC',
        '#E7F4F7',
        '#FFF8E6',
        '#F9E2D2',
        '#FAF3E0',
        '#E5F2E8',
        '#FFE8F7',
        '#EAE7FF',
        '#FAF8ED',
        '#FCF2F2',
        '#E6F9F5',
        '#FCF8ED',
        '#E6FAF8',
        '#F7EEE3',
        '#FFFBF5',
        '#E9E8FF',
        '#F7F7F7',
        '#FFF5E6',
        '#F0E6FF',
        '#E6F7FF',
        '#F8F8F8',
        '#FFFFE6',
        '#F2F2F2',
        '#FFF2E6',
        '#FAF6FF',
        '#F9FAF6',
    ];
    redirectionFromTourGuide;
    zIndex: number;
    private zIndexSubscription: Subscription;

    constructor(
        private router: Router,
        private metaService: MetaService,
        private createDialogFlagService: CreateDialogFlagService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.title = JSON.parse(localStorage.getItem('user'));
        this.getApps();
        this.createDialogFlagService.showCreateDialog$.subscribe((flag) => {
            if (flag.isShow) {
                this.showCreateDialog(flag.action);
            }
        });
    }

    getApps() {
        this.isLoading = true;
        this.NavigationSubscription = this.metaService
            .GetNavigationStructures()
            .pipe(map((data) => data.filter((item) => item.Caption != 'default'))) // hide default navigation
            .subscribe((navs) => {
                this.List = navs.map((navItem, index) => ({
                    ...navItem,
                    Caption2: navItem?.Caption?.includes('workspace-') ? navItem?.Caption?.split('workspace-')[1] : navItem?.Caption,
                    icon: this.Icons[index % this.Icons.length],
                    color: this.Colors[index % this.Colors.length],
                }));
                this.FilteredList = this.List;
                this.isLoading = false;
                this.cdr.detectChanges();
            });
    }

    deleteNavigation(app) {
        MessageBoxHelper.ShowDialog(
            new TranslateFormatText(
                '@@Are you sure you want to delete the navigation? Deleting the navigation will delete it’s associated data.'
            ),
            new TranslateFormatText('@@Delete Application'),
            MessageBoxButtons.YesNo,
            MessageBoxIcon.Question
        ).then((x) => {
            if (x === MessageBoxResult.Yes) {
                if (app !== null) {
                    let {Caption} = app;
                    this.metaService.TryDeleteMyApp(Caption).subscribe((response) => {
                        if (response) {
                            // NotificationHelper.Success('@@success', 'Navigataion deleted');
                            localStorage.removeItem('selectedApp');
                            this.getApps();
                        }
                    });
                    this.FilteredList = this.FilteredList.filter((list) => list.Caption !== Caption);
                    this.cdr.detectChanges();
                    NotificationHelper.Success('@@Your app is being deleted in the background and may take a while.', 'App deleted');
                }
            }
        });
    }

    editNavigation(app) {
        LayoutService.Loading.next(true);
        this.metaService.LoadNavigationStructure(app.SID).subscribe((response) => {
            if (response) {
                this.EditItem = response;
                this.EditItem.NonModified = this.EditItem._key;
                this.EditItem._key = this.EditItem._key ? this.EditItem._key.split('-')[2] : '';
                this.EditItem.LoginRequired = this.EditItem.LoginRequired!==undefined?this.EditItem.LoginRequired:true;
                this.isEditing = true;
                this.isApplicationProcess = true;
                LayoutService.Loading.next(false);
                this.cdr.detectChanges();
                this.showCreateDialog();
                this.cdr.detectChanges();
            }
        });
    }

    createNew() {
        this.EditItem = {
            LoginRequired:true
        };
        this.isEditing = false;
        this.showCreateDialog('both');
    }

    showCreateDialog(action?: 'application' | 'workflow' | 'both') {
        this.isShowCreateDialog = true;
        this.createDialogAction = action;
        this.cdr.detectChanges();
    }

    closeCreateDialog(shouldReload?: boolean) {
        this.isShowCreateDialog = false;
        if (shouldReload) {
            this.getApps();
        }
        this.isApplicationProcess = false;
        this.isEditing = false;
        this.createDialogFlagService.setShowCreateDialog({isShow: false, action: 'both'});
    }

    closeImportAppDialog() {
        this.importApp = false;
        this.selectedFile = null;
        this.cdr.detectChanges();
    }

    handleEdit(app): void {
        localStorage.setItem('selectedApp', JSON.stringify(app));
        this.router.navigate(['/default/settings/layout/navigation']);
    }

    updateFiltered() {
        const lowercaseInput = this.SearchValue.toLowerCase();
        this.FilteredList = this.List.filter((navItem) => navItem.Caption.toLowerCase().includes(lowercaseInput));
    }

    onChooseFile(event: Event, fileUpload) {
        const input = event as any;
        if (input.files?.length) {
            this.selectedFile = input.files[0];
        }
        fileUpload.clear();
    }

    onImport() {
        if(!this.selectedFile) return;
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        LayoutService.Loading.next(true);
        this.metaService.Import(formData).subscribe({
            next: (res) => {
                NotificationHelper.Success('The Application has imported successfully.', '@@Success');
                this.getApps();
                this.closeImportAppDialog();
                LayoutService.Loading.next(false);
            },
            error: (err) => {
                LayoutService.Loading.next(false);
                // NotificationHelper.Error('Error occur while importing data', '@@Error');
            }
        })
    }

    ngOnDestroy() {
        if (this.NavigationSubscription) {
            this.NavigationSubscription.unsubscribe();
        }
        if (this.zIndexSubscription) {
            this.zIndexSubscription.unsubscribe();
        }
        this.isApplicationProcess = false;
    }
}

interface NavigationInterface {
    Caption: string;
    IsCapsule: boolean;
    IsOverridden: boolean;
    SID: string;
    Version: string;
    _isExternal: boolean;
    CanEditInfo: any;
    color: string;
    icon: any;
}
