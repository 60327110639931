import { Component, OnInit } from "@angular/core";
import { FormulaInputMode } from "../models/enums/formulainputmode.enum";
import { FormulaEditorDialog } from "../components/common/formulaEditor/formulaEditor.control";

@Component({
    selector: 'workflow-expression-dialog',
    templateUrl: 'workflow.expression.dialog.html',
    styleUrls: ['./workflow.expression.dialog.css']
})
export class WorkflowExpressionDialog implements OnInit {

    AllProperties: any[];
    Expressions: any[];
    Variables: any[];

    ngOnInit(): void {
    }

    Initialize(args) {
        if (args) {
            if (args.Properties) {
                this.AllProperties = args.Properties;
            }
            if (args.Expressions) {
                var expressions = [];
                args.Expressions.forEach(function (exp) {
                    expressions.push({
                        Property: exp.Property,
                        Formula: exp.Formula
                    });
                });
                this.Expressions = expressions;
            }
            if (args.Variables) {
                this.Variables = args.Variables;
            }
        }
    }

    GetDialogResult() {
        return { Expressions: this.Expressions };
    }

    getProps(actExp) {
        var props = [];
        var expressions = this.Expressions;
        this.AllProperties.forEach(function (prop) {
            if (!expressions.some(function (exp) {
                if (exp.Property == prop.Value) {
                    return actExp != exp;
                }
                return false;
            })) {
                props.push(prop);
            }
        });
        return props;
    }

    addExpression() {
        this.Expressions.push({
            Property: null,
            Formula: null
        });
    }

    showFormulaDialog(exp) {
        let args = {
            Variables: this.Variables,
            InputMode: FormulaInputMode.VariableName,
            Formula: exp.Formula
        };
        FormulaEditorDialog.ShowDialog(args, function (result) {
            if (result && result.Formula) {
                exp.Formula = result.Formula;
            }
            return true;
        });
    }

    deleteExpression(index) {
        this.Expressions.splice(index, 1);
    }
}

//@Pipe({ name: 'propFilter' })
//export class PropertyFilterPipe implements PipeTransform {
//    transform(items: any[], actExp: any, expressions: any[]): any {
//        if (!items || !expressions || !actExp) {
//            return items;
//        }
//        // filter items array, items which match and return true will be
//        // kept, false will be filtered out
//        return items.filter(item => {
//            return !expressions.some(function (exp) {
//                if (exp.Property == item.Value) {
//                    return actExp != exp;
//                }
//                return false;
//            });
//        });
//    }
//}