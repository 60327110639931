import { LineType } from '../../models/enums/linetype.enum';
import { Operator } from '../../models/enums/operator.enum';
import { Class, Function, Module, Parameter, Property } from '../script.model';
import { CaseScriptLine, ForScriptLine, IfScriptLine, LetScriptLine, ReturnScriptLine, ScriptLine, ScriptOperation, SetScriptLine, SwitchScriptLine, TryScriptLine, WhileScriptLine } from '../scriptoperation.model';
import { Registry } from './registry.script';

const OperatorChars = new Set(['+', '-', '/', '*', '=', '<', '>', '!', '&', '|', '?']);
const LineEnd = new Set([';']);
const LineFeed = new Set(['\n', '\r']);
const Bracketstarts = new Set(['(', '[', '{']);
const Bracketends = new Set([')', ']', '}']);
const CharSet = new Set(['+', '-', '*', '/']);
const CharSet2 = new Set(['=', '>']);
const OperatorWeigth = [
    Operator.Increase,
    Operator.Decrease,
    Operator.Not,
    Operator.Sub,
    Operator.Add,
    Operator.Div,
    Operator.Multi,
    Operator.Equal,
    Operator.Less,
    Operator.Great,
    Operator.LessEqual,
    Operator.GreatEqual,
    Operator.NotEqual,
    Operator.And,
    Operator.Or,
    Operator.If
];

export class ScriptParser {
    LinePosition: number = 1;
    StatusVariableNames = [];
    StatusValueNames = [];

    public GetModule(script: string): Module {
        var ret = new Module();
        //ret.Imports["JSON"] = new Class() { NetType = typeof (JSON) };
        //ret.Imports["Object"] = new Class() { NetType = typeof (ObjectWrapper) };
        //ret.Imports["Global"] = new Class() { NetType = typeof(Global) };

        let tmp = ScriptParser.RemoveStrings(script);
        script = tmp.Script;
        let strings = tmp.Strings;

        let substring = "";
        for (let i = 0; i < script.length; i++) {
            if (LineEnd.has(script[i])) {
                substring = "";
                continue;
            }
            if (script[i] == '/' && script[i + 1] == '/') {
                i = ScriptParser.GetEndOfLine(script, i);
            }
            else if (script[i] == ' ' || script[i] == '{') {
                let endLine = ScriptParser.GetEndOfLine(script, i);
                let line = script.substring(i, endLine);

                if (substring == "import") {
                    let splits = line.split(" from ");
                    let from = splits[splits.length - 1];
                    let fromname = strings.get(ScriptParser.TrimEnd(from.replace("\"", "")).trim());
                    let liststring = ScriptParser.GetSubLine(line, '{', '}').trim();
                    let list = liststring.split(',');

                    let frommodule = Registry.Modules.get(fromname);
                    if (frommodule != null) {
                        list.forEach((l) => {
                            let imp = l.trim().split(" as ");
                            let exp = frommodule.Exports.get(imp[0]);
                            ret.Imports.set(imp[imp.length - 1], exp);
                        });
                    }
                    i = endLine;
                }
                else if (substring == "export") {
                    if (script.substring(i + 1, i + 4) == "let") {
                        line = line.trim().substring(4).trim();
                        let splits = line.split(":");
                        let splits2 = ScriptParser.TrimEnd(splits[1]).split("=");
                        let prop = new Property();
                        prop.Name = splits[0].trim();
                        prop.Class = splits2[1].trim();
                        if (splits2.length > 1) {
                            prop.Default = this.GetOperation(splits2[1].trim(), strings);
                        }
                        ret.Properties.set(prop.Name, prop);
                        ret.Exports.set(prop.Name, prop);
                        i = endLine;
                    }
                    else {
                        let splits = line.trim().split(' ');
                        let bracketspace = ScriptParser.GetBracketArea(script, i);
                        let classScript = bracketspace.Script;

                        let sclass = this.GetClass(classScript, ret, splits[1], strings);
                        ret.Classes.set(sclass.Name, sclass);
                        ret.Exports.set(sclass.Name, sclass);
                        i = bracketspace.EndPosition;
                    }
                }
                else if (substring == "class") {
                    let splits = line.trim().split(' ');
                    let bracketspace = ScriptParser.GetBracketArea(script, i);
                    let classScript = bracketspace.Script;

                    let sclass = this.GetClass(classScript, ret, splits[0], strings);
                    ret.Classes.set(sclass.Name, sclass);
                    i = bracketspace.EndPosition;
                }
                substring = "";
            }
            else if (!LineFeed.has(script[i])) {
                substring += script[i];
            }
            else if (script[i] == '\r' && script.length > i + 1 && script[i + 1] != '\n') {
                this.LinePosition++;
            }
            else if (script[i] == '\n') {
                this.LinePosition++;
            }
        }
        return ret;
    }

    public GetClass(script: string, module: Module, name: string, strings: Map<string, string>): Class {
        let ret = new Class();
        ret.Name = name;
        ret.Module = module;

        let substring = "";
        for (let i = 0; i < script.length; i++) {
            if (LineEnd.has(script[i])) {
                substring = "";
                continue;
            }

            if (script[i] == '/' && script[i + 1] == '/') {
                i = ScriptParser.GetEndOfLine(script, i);
            }
            else if (script[i] == ':' || script[i] == '=') {
                let endLine = ScriptParser.GetEndOfLine(script, i);
                let line = substring + script.substring(i, endLine);

                let prop = new Property();
                if (line.includes("public ")) {
                    prop.IsPublic = true;
                    line = line.replace("public", "");
                }
                if (line.includes("private ")) {
                    prop.IsPublic = false;
                    line = line.replace("private", "");
                }
                if (line.includes("static ")) {
                    prop.IsStatic = true;
                    line = line.replace("static", "");
                }
                if (line.includes("readonly ")) {
                    prop.IsReadOnly = true;
                    line = line.replace("readonly", "");
                }
                let splits = line.trim().split(":");
                let splits2: string[] = null;
                if (splits.length > 1) {
                    splits2 = ScriptParser.TrimEnd(splits[1]).split("=");
                    prop.Name = splits[0].trim();
                    prop.Class = splits2[0].trim();
                }
                else {
                    splits2 = ScriptParser.TrimEnd(line).split("=");
                    prop.Name = splits2[0].trim();
                }
                if (splits2.length > 1) {
                    prop.Default = this.GetOperation(splits2[1].trim(), strings);
                }
                ret.Properties.set(prop.Name, prop);
                substring = "";
                i = endLine;
            }
            else if (script[i] == '(') {
                let func = new Function();
                func.Class = ret;

                if (substring.includes("public")) {
                    func.IsPublic = true;
                    substring = substring.replace("public", "");
                }
                if (substring.includes("private")) {
                    func.IsPublic = false;
                    substring = substring.replace("private", "");
                }
                if (substring.includes("static")) {
                    func.IsStatic = true;
                    substring = substring.replace("static", "");
                }

                func.Name = substring.trim();
                let bracketarea = ScriptParser.GetBracketArea(script, i, '(', ')');
                let parameter = bracketarea.Script;
                let retType = script.substring(bracketarea.EndPosition + 1, script.indexOf('{', bracketarea.EndPosition)).trim();
                if (retType.startsWith(":")) {
                    func.ReturnClass = retType.replace(":", "").trim();
                }
                func.Parameters = this.GetParameters(strings, parameter);
                ret.Functions.set(func.Name, func);

                bracketarea = ScriptParser.GetBracketArea(script, bracketarea.EndPosition);
                func.Script = bracketarea.Script;
                func.ScriptLines = this.GetScriptLines(func.Script, strings, 0);
                func.LastLine = this.LinePosition;
                i = bracketarea.EndPosition;
                substring = "";
            }
            else if (!LineFeed.has(script[i])) {
                substring += script[i];
            }
            else if (script[i] == '\r' && script.length > i + 1 && script[i + 1] != '\n') {
                this.LinePosition++;
            }
            else if (script[i] == '\n') {
                this.LinePosition++;
            }
        }

        return ret;
    }

    private GetParameters(strings: Map<string, string>, parameter: string): Parameter[] {
        let ret: Parameter[] = [];
        if (parameter) {
            let paraSplit = parameter.split(',');
            paraSplit.forEach((item: string) => {
                let param = new Parameter();
                param.IsOptional = item.includes("?");
                let splits = item.split(":");
                param.Name = splits[0].replace("?", "").trim();
                if (splits.length > 1) {
                    let splits2 = ScriptParser.TrimEnd(splits[1]).split("=");
                    param.Class = splits2[0].trim();
                    if (splits2.length > 1) {
                        param.Default = this.GetOperation(splits2[1].trim(), strings);
                    }
                }
                ret.push(param);
            });
        }
        return ret;
    }

    private GetParameters2(line: string, strings: Map<string, string>, depth: number): ScriptOperation[] {
        let ret: ScriptOperation[] = [];
        if (line) {
            let splits = ScriptParser.GetStringSplits(line);

            splits.forEach((x: string) => {
                x = x.trim();
                if (x.startsWith("(") && x.includes("=>")) {
                    ret.push(this.GetValueOperation(x, strings, depth + 1));
                }
                else if (!x.startsWith("{") && x.includes(":")) {
                    var splits2 = ScriptParser.Split(x, ':', 2);
                    var so = new ScriptOperation();
                    so.Name = splits2[0].trim();
                    so.Operator = Operator.Set;
                    so.Operators.push(this.GetOperation(splits2[1], strings));
                    ret.push(so);
                }
                else {
                    ret.push(this.GetOperation(x, strings, depth + 1));
                }
            }, this);
        }
        return ret;
    }

    private static GetStringSplits(line: string, splitter: string = ','): string[] {
        var splits: string[] = [];

        let counter = 0;
        let part = "";
        for (let i = 0; i < line.length; i++) {
            if (counter == 0 && line[i] == splitter) {
                splits.push(part.trim());
                part = "";
            }
            else if (Bracketstarts.has(line[i])) {
                part += line[i];
                counter++;
            }
            else if (Bracketends.has(line[i])) {
                part += line[i];
                counter--;
            }
            else {
                part += line[i];
            }
        }

        if (part.trim()) {
            splits.push(part.trim());
        }

        return splits;
    }

    public GetScriptLines(script: string, strings: Map<string, string>, depth: number = 0): ScriptLine[] {
        let ret: ScriptLine[] = [];

        let lastIf = null;
        let lastTry = null;

        let substring = "";
        for (let i = 0; i < script.length; i++) {
            if (LineEnd.has(script[i])) {
                if (substring.trim()) {
                    ret.push(this.GetScriptLine(substring.trim(), strings));
                }
                substring = "";
                continue;
            }

            if (script[i] == '/' && script[i + 1] == '/') {
                i = ScriptParser.GetEndOfLine(script, i);
            }
            else if ((script[i] == ' ' || script[i] == '(' || script[i] == '{') && substring) {
                let endLine = 0;
                let endPosition = 0;
                let line = "";
                let sub = "";
                let check = "";
                let bracketArea: BracketReturn = null;
                const trim = substring.trim();
                switch (trim) {
                    case "var": // Technisch nicht das gleiche, wird aber so behandelt!
                    case "let":
                        let letLine = new LetScriptLine();
                        letLine.ScriptText = line;
                        letLine.IsVar = trim == "var";
                        letLine.LineNumber = this.LinePosition;

                        endLine = ScriptParser.GetEndOfScriptLine(script, i);
                        line = script.substring(i, endLine == -1 ? script.length : endLine);
                        let splits = line.trim().split(":");
                        let splits102: string[] = null;
                        if (splits.length > 1) {
                            splits102 = ScriptParser.Split(ScriptParser.TrimEnd(splits[1]), "=", 2);
                            letLine.Name = splits[0].trim();
                        }
                        else {
                            splits102 = ScriptParser.Split(ScriptParser.TrimEnd(line), "=", 2);
                            letLine.Name = splits102[0].trim();
                        }
                        if (splits102.length > 1) {
                            letLine.Operation = this.GetOperation(splits102[1].trim(), strings);
                            if (letLine.Operation.Name == 'Global.getStatus') {
                                this.StatusVariableNames.push(letLine.Name);
                            }
                        }
                        ret.push(letLine);
                        i = endLine;
                        break;
                    case "if":
                        lastIf = new IfScriptLine();
                        lastIf.ScriptText = line;
                        lastIf.LineType = LineType.If;
                        lastIf.LineNumber = this.LinePosition;

                        ret.push(lastIf);

                        bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                        check = bracketArea.Script;
                        lastIf.Operation = this.GetOperation(check, strings);
                        bracketArea = ScriptParser.GetBracketArea(script, bracketArea.EndPosition);
                        sub = bracketArea.Script;
                        lastIf.SubScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        lastIf.IfEndLine = this.LinePosition;
                        i = bracketArea.EndPosition;
                        break;
                    case "else":
                        if (script.substring(i).trim().startsWith("if")) {
                            var ifline = new IfScriptLine();
                            ifline.ScriptText = line;
                            ifline.LineType = LineType.ElseIf;
                            ifline.LineNumber = this.LinePosition;

                            bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                            check = bracketArea.Script;
                            ifline.Operation = this.GetOperation(check, strings);
                            bracketArea = ScriptParser.GetBracketArea(script, bracketArea.EndPosition);
                            sub = bracketArea.Script;
                            ifline.SubScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                            ifline.IfEndLine = this.LinePosition;
                            lastIf.ElseIfScriptLine.push(ifline);
                            i = bracketArea.EndPosition;
                        }
                        else {
                            let bracketArea = ScriptParser.GetBracketArea(script, i);
                            sub = bracketArea.Script;
                            lastIf.ElseScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                            lastIf.ElseEndLine = this.LinePosition;
                            i = bracketArea.EndPosition;
                        }
                        break;
                    case "while":
                        var whileline = new WhileScriptLine();
                        whileline.ScriptText = line;
                        whileline.LineNumber = this.LinePosition;

                        ret.push(whileline);
                        bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                        check = bracketArea.Script;
                        whileline.Operation = this.GetOperation(check, strings);
                        bracketArea = ScriptParser.GetBracketArea(script, bracketArea.EndPosition);
                        sub = bracketArea.Script;
                        whileline.SubScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        whileline.EndLine = this.LinePosition;
                        i = bracketArea.EndPosition;
                        break;
                    case "switch":
                        var switchLine = new SwitchScriptLine();
                        switchLine.ScriptText = line;
                        switchLine.LineNumber = this.LinePosition;

                        ret.push(switchLine);
                        bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                        check = bracketArea.Script;
                        switchLine.Operation = this.GetOperation(check, strings);
                        bracketArea = ScriptParser.GetBracketArea(script, bracketArea.EndPosition);
                        sub = bracketArea.Script;
                        switchLine.CaseLines = this.GetScriptLines(sub, strings, depth + 1);
                        let defaultCase = switchLine.CaseLines.find(x => x.IsDefault);
                        if (defaultCase != null) {
                            switchLine.DefaultScriptLines = defaultCase.SubScriptLines;
                            switchLine.CaseLines.splice(switchLine.CaseLines.indexOf(defaultCase), 1);
                        }
                        i = bracketArea.EndPosition;
                        break;
                    case "case":
                    case "default:":
                        let caseLine = new CaseScriptLine();
                        caseLine.ScriptText = line;
                        caseLine.LineNumber = this.LinePosition;

                        ret.push(caseLine);
                        caseLine.IsDefault = trim == "default:";
                        if (!caseLine.IsDefault) {
                            bracketArea = ScriptParser.GetBracketAreaEndString(script, i, ':');
                            check = bracketArea.Script;
                            endPosition = bracketArea.EndPosition;
                            caseLine.Operation = this.GetOperation(check, strings);
                        } else {
                            endPosition = i;
                        }
                        bracketArea = ScriptParser.GetBracketAreaEndString(script, endPosition + 1, "break");
                        sub = bracketArea.Script;
                        caseLine.SubScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        i = bracketArea.EndPosition + 5;
                        break;
                    case "for":
                        let forscriptLine: ForScriptLine = new ForScriptLine();
                        forscriptLine.ScriptText = line;
                        forscriptLine.LineNumber = this.LinePosition;

                        ret.push(forscriptLine);
                        bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                        check = bracketArea.Script;
                        bracketArea = ScriptParser.GetBracketArea(script, bracketArea.EndPosition);
                        sub = bracketArea.Script;
                        forscriptLine.SubScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        forscriptLine.EndLine = this.LinePosition;

                        if (check.includes(" in ") || check.includes(" of ")) {
                            forscriptLine.IsForEach = true;

                            let splits2 = check.split(check.includes(" in ") ? " in " : " of ");
                            forscriptLine.LetSetOperation = new LetScriptLine();
                            forscriptLine.LetSetOperation.ScriptText = check;
                            forscriptLine.LetSetOperation.LineNumber = this.LinePosition;

                            let lsname = splits2[0].trim();
                            if (lsname.startsWith("let")) {
                                forscriptLine.LetSetOperation.Name = splits2[0].trim().substring(4).trim();
                            } else {
                                forscriptLine.LetSetOperation.Name = splits2[0].trim();
                            }
                            forscriptLine.LetSetOperation.Operation = new ScriptOperation();
                            forscriptLine.LetSetOperation.Operation.Operator = Operator.Enumerator;
                            forscriptLine.LetSetOperation.Operation.Operators.push(this.GetOperation(splits2[1], strings));
                        } else {
                            var parts = check.split(';');
                            forscriptLine.LetSetOperation = this.GetScriptLines(parts[0] + ";", strings, depth + 1)[0] as LetScriptLine;
                            forscriptLine.Operation = this.GetOperation(parts[1], strings);
                            forscriptLine.SetLine = this.GetScriptLine(parts[2], strings);
                        }
                        i = bracketArea.EndPosition;
                        break;
                    case "try":
                        lastTry = new TryScriptLine();
                        lastTry.ScriptText = line;
                        lastTry.LineNumber = this.LinePosition;

                        ret.push(lastTry);
                        bracketArea = ScriptParser.GetBracketArea(script, i);
                        sub = bracketArea.Script;
                        lastTry.TryScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        i = bracketArea.EndPosition;
                        break;
                    case "catch":
                        endLine = script.indexOf('{', i);
                        line = ScriptParser.TrimEnd(script.substring(i, endLine == -1 ? script.length : endLine));
                        if (line.trim().startsWith("(")) {
                            lastTry.CatchExceptionName = ScriptParser.GetSubLine(line, '(', ')').trim();
                        }
                        bracketArea = ScriptParser.GetBracketArea(script, i);
                        sub = bracketArea.Script;
                        lastTry.CatchScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        i = bracketArea.EndPosition;
                        break;
                    case "finally":
                        bracketArea = ScriptParser.GetBracketArea(script, i);
                        sub = bracketArea.Script;
                        lastTry.FinallyScriptLines = this.GetScriptLines(sub, strings, depth + 1);
                        i = bracketArea.EndPosition;
                        break;
                    case "return":
                        endLine = ScriptParser.GetEndOfScriptLine(script, i);
                        line = ScriptParser.TrimEnd(script.substring(i, endLine == -1 ? script.length : endLine)).trim();
                        let retScript = new ReturnScriptLine();
                        retScript.ScriptText = line;
                        retScript.Operation = this.GetOperation(line, strings);
                        retScript.LineNumber = this.LinePosition;
                        ret.push(retScript);
                        i = endLine;
                        break;
                    case "throw":
                        endLine = ScriptParser.GetEndOfScriptLine(script, i);
                        line = ScriptParser.TrimEnd(script.substring(i, endLine == -1 ? script.length : endLine)).trim();
                        let sLine = new ScriptLine();
                        sLine.Operation = this.GetOperation(line, strings);
                        sLine.LineType = LineType.Throw;
                        sLine.ScriptText = line;
                        sLine.LineNumber = this.LinePosition;
                        ret.push(sLine);
                        i = endLine;
                        break;
                    default:
                        substring += script[i];
                        if (script[i] == '(') {
                            bracketArea = ScriptParser.GetBracketArea(script, i, '(', ')');
                            sub = bracketArea.Script;
                            substring += sub + ")";
                            i = bracketArea.EndPosition;
                        }
                        continue;
                }

                substring = "";
            }
            else if (!LineFeed.has(script[i])) {
                substring += script[i];
            }
            else if (script[i] == '\r' && script.length > i + 1 && script[i + 1] != '\n') {
                this.LinePosition++;
            }
            else if (script[i] == '\n') {
                this.LinePosition++;
            }
        }

        return ret;
    }


    private GetScriptLine(line: string, strings: Map<string, string>): ScriptLine {
        let value = line.trim();
        if (line.includes("=")) {
            let index = line.indexOf('=');
            if (CharSet.has(line[index - 1]) || CharSet2.has(line[index + 1])) {
                let sLine = new ScriptLine();
                sLine.LineType = LineType.Other;
                sLine.Operation = this.GetOperation(value, strings);
                sLine.ScriptText = line;
                sLine.LineNumber = this.LinePosition;
                return sLine;
            }
            else if (CharSet.has(line[index - 1])) {
                let splits = ScriptParser.Split(value, '=', 2);
                value = splits[0].substring(0, splits[0].length - 1);
                let setLine = new SetScriptLine();
                setLine.ScriptText = line;
                setLine.LineNumber = this.LinePosition;
                setLine.SetOperation = this.GetAccessOperation(value.trim(), strings, 0);
                setLine.Operation = this.GetOperation(value + " " + line[index - 1] + " " + splits[1], strings);
                if (setLine.Operation.Name == 'Global.getStatus') {
                    this.StatusVariableNames.push(setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Name);
                } else if (this.StatusVariableNames.some(x => x == setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Name)) {
                    this.StatusValueNames.push(setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Operators[0].Value);
                }
                return setLine;
            }
            else {
                let splits = ScriptParser.Split(value, '=', 2);
                let setLine = new SetScriptLine();
                setLine.ScriptText = line;
                setLine.LineNumber = this.LinePosition;
                setLine.SetOperation = this.GetAccessOperation(splits[0].trim(), strings, 0);
                setLine.Operation = this.GetOperation(splits[1], strings);
                if (setLine.Operation.Name == 'Global.getStatus') {
                    this.StatusVariableNames.push(setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Name);
                } else if (this.StatusVariableNames.some(x => x == setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Name)) {
                    this.StatusValueNames.push(setLine.SetOperation.Operators[setLine.SetOperation.Operators.length - 1].Operators[0].Value);
                }
                return setLine;
            }
        }
        else if (line == "break") {
            let sline = new ScriptLine();
            sline.LineType = LineType.Break;
            sline.ScriptText = line;
            sline.LineNumber = this.LinePosition;
            return sline;
        }
        else if (line == "continue") {
            let sline = new ScriptLine();
            sline.LineType = LineType.Continute;
            sline.ScriptText = line;
            sline.LineNumber = this.LinePosition;
            return sline;
        }
        else if (line == "throw") {
            let sline = new ScriptLine();
            sline.LineType = LineType.Throw;
            sline.ScriptText = line;
            sline.LineNumber = this.LinePosition;
            return sline;
        }
        else {
            let sLine = new ScriptLine();
            sLine.LineType = LineType.Other;
            sLine.Operation = this.GetOperation(value, strings);
            sLine.ScriptText = line;
            sLine.LineNumber = this.LinePosition;
            return sLine;
        }
    }

    private GetOperation(value: string, strings: Map<string, string>, depth: number = 0): ScriptOperation {
        if (depth > 100) {
            throw new Error("Error in ScriptExecution.");
        }
        value = value.trim();

        let ret = new ScriptOperation();
        let parts = [];
        let substring = "";
        for (let i = 0; i < value.length; i++) {
            while (substring[0] == '.') {
                substring = substring.substring(1);
            }
            if (value[i] == '(') {
                let bracketArea = ScriptParser.GetBracketArea(value, i, '(', ')');
                let subvalue = bracketArea.Script;
                if (!substring.trim()) {
                    parts.push(this.GetOperation(subvalue, strings));
                }
                else if (substring.trim().startsWith("new ")) {
                    parts.push(this.GetValueOperation(substring + "(" + subvalue + ")", strings, depth + 1));
                }
                else// funktion
                {
                    let lastOp = new ScriptOperation();
                    lastOp.Operator = Operator.Function;
                    lastOp.Name = substring;
                    lastOp.Operators = this.GetParameters2(subvalue.trim(), strings, depth + 1);
                    lastOp.Operators.push(this.GetAccessOperation(substring.trim(), strings, depth));
                    if (this.StatusVariableNames.some(x => x + '.SetValue' == lastOp.Name || 'this.' + x + '.SetValue' == lastOp.Name)) {
                        this.StatusValueNames.push(lastOp.Operators[0].Value);
                    }
                    parts.push(lastOp);
                }
                i = bracketArea.EndPosition;
                substring = "";
            }
            else if (OperatorChars.has(value[i])) {
                if (substring.trim()) {
                    parts.push(this.GetValueOperation(substring, strings, depth + 1));
                    substring = "";
                }

                let opera = ScriptParser.GetOperator(value, i);
                i = opera.i;
                parts.push(opera.Operator);
            }
            else if (!LineFeed.has(value[i])) {
                substring += value[i];
            }
            else if (value[i] == '\r' && value.length > i + 1 && value[i + 1] != '\n') {
                this.LinePosition++;
            }
            else if (value[i] == '\n') {
                this.LinePosition++;
            }
        }

        if (substring) {
            parts.push(this.GetValueOperation(substring, strings, depth + 1));
        }

        if (parts.length > 1) {
            // Hier wird noch eine "chain" Operation benötigt, welche die Operationen einfach aneinanderreiht.
            // Dafür alle Operationen zusammenfassen, die keinen Operator dazwischenhaben.
            let chainOperations = [];
            let chainStart = -1;
            parts.forEach((item) => {
                if (item instanceof ScriptOperation) {
                    chainOperations.push(item);
                    chainStart = chainStart == -1 ? parts.indexOf(item) : chainStart;
                }
                else {
                    if (chainOperations.length > 1) {
                        let c = new ScriptOperation();
                        c.Operator = Operator.Chain;
                        c.Operators = chainOperations;

                        let indexStart = parts.indexOf(chainOperations[0]);
                        parts.splice(indexStart, chainOperations.length, c);
                    }
                    chainOperations = [];
                    chainStart = -1;
                }
            }, this);

            if (chainOperations.length > 1) {
                let c = new ScriptOperation();
                c.Operator = Operator.Chain;
                c.Operators = chainOperations;

                let indexStart = parts.indexOf(chainOperations[0]);
                parts.splice(indexStart, chainOperations.length, c);
            }

            OperatorWeigth.forEach((item) => {
                let found = true;
                while (found && parts.length > 1) {
                    found = false;
                    for (let i = 0; i < parts.length; i++) {
                        let op = parts[i];
                        if (op === item) {
                            found = true;
                            if (op === Operator.Sub && (i == 0 || !(parts[i - 1] instanceof ScriptOperation)) && parts[i + 1] instanceof ScriptOperation) {
                                parts[i + 1].IsNegative = true;
                                parts.splice(i, 1);
                            }
                            else {
                                var newOp = new ScriptOperation();
                                newOp.Operator = op;
                                parts[i] = newOp;
                                if (op == Operator.Increase || op == Operator.Decrease) {
                                    if (i == 0 || parts[i + 1] instanceof ScriptOperation) {
                                        newOp.Operator = op == Operator.Increase ? Operator.IncreaseBefore : Operator.DecreaseBefore;
                                        newOp.Operators.push(parts[i + 1]);
                                        parts.splice(i + 1, 1);
                                    }
                                    else {
                                        newOp.Operator = op == Operator.Increase ? Operator.IncreaseAfter : Operator.DecreaseAfter;
                                        newOp.Operators.push(parts[i - 1]);
                                        parts.splice(i - 1, 1);
                                    }
                                }
                                else if (op == Operator.Not) {
                                    newOp.Operators.push(parts[i + 1]);
                                    parts.splice(i + 1, 1);
                                }
                                else if (op == Operator.If) {
                                    newOp.Operators.push(parts[i - 1]);
                                    newOp.Operators.push(parts[i + 1]);
                                    newOp.Operators.push(parts[i + 3]);
                                    parts.splice(i + 1, 3);
                                    parts.splice(i - 1, 1);
                                }
                                else {
                                    newOp.Operators.push(parts[i - 1]);
                                    newOp.Operators.push(parts[i + 1]);
                                    parts.splice(i + 1, 1);
                                    parts.splice(i - 1, 1);
                                }
                            }
                            break;
                        }
                    }
                }
            }, this);
        }

        ret = parts[0] as ScriptOperation;

        return ret;
    }

    private GetValueOperation(value: string, strings: Map<string, string>, depth: number): ScriptOperation {
        var ret = new ScriptOperation();

        value = value.trim();

        if (value.includes("=>")) {
            var splits = ScriptParser.Split(value, "=>", 2);
            var func = new Function();
            func.IsInlineFunction = true;
            var parameter = splits[0].trim();
            if (parameter.startsWith("(")) {
                parameter = parameter.substring(1, parameter.length - 1);
            }
            func.Parameters = this.GetParameters(strings, parameter);
            var body = splits[1].trim();
            if (splits[1].trim().startsWith("{")) {
                let bracketArea = ScriptParser.GetBracketArea(body, 0);
                var sub = bracketArea.Script;
                func.ScriptLines = this.GetScriptLines(sub, strings, depth + 1);
            }
            else {
                if (!body.endsWith(";")) {
                    body = "return " + body + ";";
                }
                func.ScriptLines = this.GetScriptLines(body, strings, depth + 1);
            }
            func.LastLine = this.LinePosition;
            ret.Operator = Operator.InlineFunction;
            ret.Value = func;
        }
        else if (value.startsWith("new ")) {
            value = value.substring(4);

            if (value.startsWith("{")) {
                ret.Operator = Operator.NewDynamic;
                ret.Operators = this.GetParameters2(ScriptParser.GetSubLine(value, '{', '}').trim(), strings, depth);
            }
            else if (value.endsWith("]")) {
                var subvalue = ScriptParser.GetSubLine(value, '[', ']');
                ret.Operator = Operator.NewArray;
                ret.Operators = this.GetParameters2(subvalue, strings, depth + 1);
            }
            else {
                ret.Operator = Operator.New;
                ret.Name = value.substring(0, value.indexOf('('));
                ret.Operators = this.GetParameters2(ScriptParser.GetSubLine(value, '(', ')').trim(), strings, depth);
            }
        }
        else if (value.startsWith("typeof ")) {
            value = value.substring(7);
            ret.Operator = Operator.TypeOf;
            ret.Operators.push(this.GetOperation(value.trim(), strings, depth + 1));
        }
        else if (value.startsWith("{")) // Noch mit anderen darüber sprechen.
        {
            ret.Operator = Operator.NewDynamic;
            ret.Operators = this.GetParameters2(ScriptParser.GetSubLine(value, '{', '}').trim(), strings, depth);
        }
        else if (value.startsWith("[")) {
            var subvalue = ScriptParser.GetSubLine(value, '[', ']');
            ret.Operator = Operator.NewArray;
            ret.Operators = this.GetParameters2(subvalue, strings, depth + 1);
        }
        else if (strings.has(value)) {
            ret.Operator = Operator.Value;
            ret.Value = strings.get(value);
        }
        else if (value == "true") {
            ret.Operator = Operator.Value;
            ret.Value = true;
        }
        else if (value == "false") {
            ret.Operator = Operator.Value;
            ret.Value = false;
        }
        else if (value == "void") {
            ret.Operator = Operator.Void;
        }
        else if (value == "null") {
            ret.Operator = Operator.Value;
            ret.Value = null;
        }
        else {
            let d = parseFloat(value);
            if (isNaN(d)) {
                ret = this.GetAccessOperation(value, strings, depth + 1);
            }
            else {
                ret.Operator = Operator.Value;
                ret.Value = d;
            }
        }

        return ret;
    }

    private GetAccessOperation(line: string, strings: Map<string, string>, depth: number): ScriptOperation {
        let ret = new ScriptOperation();
        ret.Operator = Operator.Get;

        let funcSplits = ScriptParser.GetStringSplits(line, '.');
        for (let i = 0; i < funcSplits.length; i++) {
            if (funcSplits[i].includes("[") || funcSplits[i].includes("]")) {
                let end = funcSplits[i].indexOf('[');
                let name = funcSplits[i].substring(0, end);
                let aa = new ScriptOperation();
                aa.Operator = Operator.ArrayAccess;
                aa.Name = name;
                ret.Operators.push(aa);

                while (end < funcSplits[i].length) {
                    let scriptArea = ScriptParser.GetBracketArea(funcSplits[i], end, '[', ']');
                    let subvalue = scriptArea.Script;
                    ret.Operators[ret.Operators.length - 1].Operators.push(this.GetOperation(subvalue, strings, depth + 1));
                    end = scriptArea.EndPosition + 1;
                }
            }
            else {
                let v = new ScriptOperation();
                v.Operator = Operator.VariableProperty;
                v.Name = funcSplits[i];
                ret.Operators.push(v);
            }
        }

        return ret;
    }

    private ciEquals(a, b) {
        return typeof a === 'string' && typeof b === 'string'
            ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
            : a === b;
    }

    private static GetSubLine(line: string, start: string, end: string): string {
        return line.substring(line.indexOf(start) + 1, line.lastIndexOf(end));
    }

    private static RemoveStrings(value: string): RemoveStringReturn {
        let strings = new Map<string, string>();
        var strs = [];
        let startIndex = -1;
        let simple = false;

        for (let i = 0; i < value.length; i++) {
            if (startIndex == -1) {
                if (value[i] == '/' && value[i + 1] == '/') {
                    i = ScriptParser.GetEndOfLine(value, i);
                }
                else {
                    if (value[i] == '"') {
                        startIndex = i;
                    }
                    else if (value[i] == '\'') {
                        startIndex = i;
                        simple = true;
                    }
                }
            }
            else {
                if (value[i] == '\\') {
                    i++;
                }
                else if (value[i] == '"') {
                    if (!simple) {
                        strs.push({ Str: value.substring(startIndex + 1, i), StartIndex: startIndex, EndIndex: i + 1 });
                        startIndex = -1;
                    }
                }
                else if (value[i] == '\'') {
                    if (simple) {
                        strs.push({ Str: value.substring(startIndex + 1, i), StartIndex: startIndex, EndIndex: i + 1 });
                        startIndex = -1;
                        simple = false;
                    }
                }
            }
        }

        for (let i = strs.length - 1; i >= 0; i--) {
            strings.set("#" + i + "#", strs[i].Str);
            value = value.substr(0, strs[i].StartIndex) + "#" + i + "#" + value.substr(strs[i].EndIndex)
        }

        let ret = new RemoveStringReturn();
        ret.Script = value;
        ret.Strings = strings;

        return ret;
    }

    private static GetEndOfLine(script: string, indexstart: number): number {
        let ret = -1;
        for (var lf of LineFeed) {
            let index = script.indexOf(lf, indexstart);
            if (ret == -1 || ret > index) {
                ret = index;
            }
        }
        return ret;
    }

    private static GetBracketArea(script: string, start: number, bracketStart: string = '{', bracketEnd: string = '}'): BracketReturn {
        let bracketcount = 1;
        start = script.indexOf(bracketStart, start) + 1;
        for (let i = start; i < script.length; i++) {
            if (script[i] == bracketStart) {
                bracketcount++;
            }
            if (script[i] == bracketEnd) {
                bracketcount--;
            }

            if (bracketcount == 0) {
                return { Script: script.substring(start, i), EndPosition: i } as BracketReturn;
            }
        }

        return null;
    }

    private static GetBracketAreaEndString(script: string, start: number, endstring: string): BracketReturn {
        let bracketcount = 0;
        for (let i = start; i < script.length; i++) {
            if (Bracketstarts.has(script[i])) {
                bracketcount++;
            }
            if (Bracketends.has(script[i])) {
                bracketcount--;
            }

            if (bracketcount == 0 && script.substring(i).startsWith(endstring)) {
                return { Script: script.substring(start, i), EndPosition: i } as BracketReturn;
            }
        }

        return null;
    }

    private static GetEndOfScriptLine(script: string, indexstart: number): number {
        // Abgekürzt, damit gehen erstmal keine Verschachtelungen, später überarbeiten!! Wenn [,{ oder ( kommt, müssen kommas ignoriert werden.
        let counter = 0;
        for (let i = indexstart; i < script.length; i++) {
            if (counter == 0 && script[i] == ';') {
                return i;
            }
            else if (Bracketstarts.has(script[i])) {
                counter++;
            }
            else if (Bracketends.has(script[i])) {
                counter--;
            }
        }

        return script.length;
    }

    private static TrimEnd(value: string): string {
        var ret = "" + value;
        while (ret && ret.length > 0 && (ret[ret.length - 1] == ';' || ret[ret.length - 1] == ' ')) {
            ret = ret.slice(0, -1);
        }
        return ret;
    }

    private static GetOperator(value: string, i: number): OperatorReturn {
        var op = "";
        if (value.length - 1 > i && OperatorChars.has(value[i + 1])) {
            op = "" + value[i] + value[i + 1];
            i++;
        }
        else {
            op = "" + value[i];
        }

        let fundOp: Operator = Operator.None;

        switch (op) {
            case "==":
                fundOp = Operator.Equal;
                break;
            case "<=":
                fundOp = Operator.LessEqual;
                break;
            case ">=":
                fundOp = Operator.GreatEqual;
                break;
            case "<>":
            case "!=":
                fundOp = Operator.NotEqual;
                break;
            case "<":
                fundOp = Operator.Less;
                break;
            case ">":
                fundOp = Operator.Great;
                break;
            case "!":
                fundOp = Operator.Not;
                break;
            case "+":
                fundOp = Operator.Add;
                break;
            case "-":
                fundOp = Operator.Sub;
                break;
            case "/":
                fundOp = Operator.Div;
                break;
            case "*":
                fundOp = Operator.Multi;
                break;
            case "||":
                fundOp = Operator.Or;
                break;
            case "&&":
                fundOp = Operator.And;
                break;
            case "?":
                fundOp = Operator.If;
                break;
            case "++":
                fundOp = Operator.Increase;
                break;
            case "--":
                fundOp = Operator.Decrease;
                break;

        }
        return { Operator: fundOp, i: i } as OperatorReturn;
    }

    private static Split(value: string, separator: string, count: number): string[] {
        if (count < 0) {
            throw "Negative Split Count";
        }
        let retVal = [];
        if (count == 1) {
            retVal.push(value);
        } else if (count > 1) {
            let actualCount = 0;
            let substring = value;
            while (actualCount < count - 1) {
                const index = substring.indexOf(separator);
                if (index < 0) {
                    break;
                } else {
                    retVal.push(substring.substring(0, index));
                    if (index + separator.length == substring.length) {
                        substring = '';
                        break;
                    }
                    substring = substring.substring(index + separator.length);
                }
                actualCount++;
            }
            if (substring.length > 0) {
                retVal.push(substring);
            }
        }
        return retVal;
    }
}

class BracketReturn {
    Script: string;
    EndPosition: number;
}

class RemoveStringReturn {
    Script: string;
    Strings: Map<string, string>;
}

class OperatorReturn {
    Operator: Operator;
    i: number;
}