import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	SimpleChanges,
} from '@angular/core';
import { MetaService } from '../../../services/meta.service';
import { SelfServiceObjectService } from '../../../services/datamodel.service';
import { Color } from '../../../models/style/color.model';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { FilterHelper } from '../../../helpers/filter.helper';

const surfaceBorder = '#090909';
@Component({
	selector: 'chart-wrapper',
	templateUrl: './chartwrapper.component.html',
	styleUrls: ['./chartwrapper.component.css'],
})
export class ChartWrapperComponent implements OnInit, OnDestroy {
	_options: any;
	@Input()
	get Options() {
		return this._options;
	}
	set Options(val) {
		this._options = val;
	}

	_chartdata: any;
	@Input()
	get Chartdata() {
		return this._chartdata;
	}
	set Chartdata(val) {
		this._chartdata = val;
	}

	_defaultPalette;
	@Input()
	get DefaultPalette() {
		return this._defaultPalette;
	}
	set DefaultPalette(val) {
		this._defaultPalette = val;
	}
	_loading: boolean;
	@Input()
	get Loading() {
		return this._loading;
	}
	set Loading(val) {
		this._loading = val;
	}
	@Input() ChartType: string;
	@Input() ChartPalette: string;
	@Input() LegendPosition: string;
	@Input() DataFilter: any;
	@Input() XLabel: string;
	@Input() YLabel: string;
	@Input() BoldXLabel: boolean;
	@Input() ItalicXLabel: boolean;
	@Input() ColorXLabel: string;
	@Input() BoldYLabel: boolean;
	@Input() ItalicYLabel: boolean;
	@Input() ColorYLabel: string;
	@Input() FontSizeXLabel: number;
	@Input() FontSizeYLabel: number;
	@Input() GridColor: string;
	@Input() AggregationColumns: string;
	@Input() Aggregation: string;
	@Input() GroupByDateColumns: string;
	@Input() GroupByDate: string;
	@Input() NoOfRecords: number;
	@Input() GroupByTableColumns: string;
	@Input() DataSource: string;
	@Input() HideXAxisGrid: boolean;
	@Input() HideYAxisGrid: boolean;
	@Input() LayoutElement: any;
	chartVisible: boolean = false;

	constructor(
		private cdRef: ChangeDetectorRef,
		private metaService: MetaService,
		private dataService: SelfServiceObjectService
	) {}
	ngOnInit() {
		this.chartVisible = true;
	}

	ngOnDestroy() {
		this.chartVisible = false;
	}
	SetDefaultPalette() {
		this.DefaultPalette = [
			'#FF5733',
			'#33FF57',
			'#5733FF',
			'#FFD700',
			'#8A2BE2',
			'#00FFFF',
			'#FF4500',
			'#008000',
			'#4B0082',
		];
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes['ChartPalette']) {
			if (this.ChartPalette && this.ChartPalette !== null) {
				this.GetPalette();
				this.destroyAndRecreateChart();
				return;
			}
			this.SetDefaultPalette();
			this.GetPalette();
			this.destroyAndRecreateChart();
			return;
		}
		if (
			changes['DataFilter'] ||
			changes['DataSource'] ||
			changes['GroupByDateColumns'] ||
			changes['GroupByDate'] ||
			changes['NoOfRecords'] ||
			changes['AggregationColumns'] ||
			changes['Aggregation'] ||
			changes['GroupByTableColumns']
		) {
			this.LoadChartData();
			this.destroyAndRecreateChart();
			return;
		}
		if (
			changes['LegendPosition'] ||
			changes['XLabel'] ||
			changes['YLabel'] ||
			changes['BoldXLabel'] ||
			changes['ItalicXLabel'] ||
			changes['ColorXLabel'] ||
			changes['BoldYLabel'] ||
			changes['ItalicYLabel'] ||
			changes['ColorYLabel'] ||
			changes['FontSizeXLabel'] ||
			changes['FontSizeYLabel'] ||
			changes['GridColor'] ||
			changes['HideXAxisGrid'] ||
			changes['HideYAxisGrid'] ||
			changes['ChartType']
		) {
			this.destroyAndRecreateChart();
			this.ChangeChartSettings();
		}
	}

	private destroyAndRecreateChart() {
		this.chartVisible = false;
		this.cdRef.detectChanges();

		// Delayed set to true to ensure proper destruction and recreation
		setTimeout(() => {
			this.chartVisible = true;
			this.cdRef.detectChanges();
		});
	}
	LoadChartData() {
		if (
			!this.DataSource ||
			!this.GroupByTableColumns ||
			!this.AggregationColumns ||
			!this.Aggregation
		) {
			this.Chartdata = { labels: [], datasets: [] };
			return;
		}
		this.Loading = true;
		const filters = FilterHelper.PrepareFilter(this.LayoutElement);

		if (this.GroupByTableColumns && this.GroupByTableColumns.length > 0) {
			filters.GroupBy = [this.GroupByTableColumns];
		}
		if (this.AggregationColumns && this.AggregationColumns.length > 0) {
			const aggregationObj = {
				Field: this.AggregationColumns,
				Operation: this.Aggregation,
				Alias: this.AggregationColumns,
			};
			filters.Aggregations = [aggregationObj];
		}
		if (this.GroupByDateColumns && this.GroupByDate) {
			filters.GroupByDate = {
				Field: this.GroupByDateColumns,
				GroupingLevel: this.GroupByDate,
			};
		}
		if (!this.GroupByDate) {
			delete filters.GroupByDate;
		}
		filters.EndRow = null;
		if (
			this.GroupByTableColumns &&
			this.DataSource &&
			this.AggregationColumns &&
			this.Aggregation
		) {
			this.Chartdata = { labels: [], datasets: [] };
			this.dataService
				.ExecuteObjectQueryExtended(this.DataSource, filters)
				.subscribe({
					next: (data) => {
						if (data && data.length > 0) {
							let result;
							this.NoOfRecords && this.NoOfRecords > 0
								? (result = this.GetMaxNoOfResults(
										data[0].Data,
										this.NoOfRecords,
										this.AggregationColumns
								  ))
								: (result = data[0].Data);

							if (this.GroupByDate) {
								this.PrepareChartData(result);
							} else {
								this.PrepareChartdataWithoutGroupBy(result);
							}
						}
						this.GetPalette();
						this.Loading = false;
						this.cdRef.detectChanges();
					},
					error: (error) => {
						NotificationHelper.Error(error.error['error'], '@@Error');
					},
				});
			return;
		}
	}
	GetMaxNoOfResults(array, maxResults, sortByField) {
		// Sort the array in descending order based on the specified field
		array.sort((a, b) => b[sortByField] - a[sortByField]);

		// Slice the array to get the top-rated movies based on the maxResults
		const topResults = array.slice(0, maxResults);

		return topResults;
	}
	PrepareChartData(data) {
		const groupedData = this.groupByFieldName(data, this.GroupByTableColumns);
		// labels => uniquely _Created kr k set krwa dena hy
		let chartData: any = { labels: [], datasets: [] };

		// set unique labels for the x-axis
		data.forEach((item) => {
			// if (this.GroupByDate === 'Month') {
			// 	const month = this.getMonthNameByNumber(
			// 		item[this.GroupByDateColumns].split('-')[1].replace(/^0+/, '')
			// 	);
			// 	chartData.labels.push(month);
			// }
			// if (this.GroupByDate === 'Year') {
			// 	const year = item[this.GroupByDateColumns];
			// 	chartData.labels.push(year);
			// }
			// if (this.GroupByDate === 'Day') {
			// 	const day = item[this.GroupByDateColumns];
			// 	chartData.labels.push(day);
			// }
			chartData.labels.push(item[this.GroupByDateColumns]);
		});
		chartData.labels = [...new Set(chartData.labels)];

		//  set the datasets according to the given aggregation
		const datasets = Object.keys(groupedData).map((key) => {
			const datasetData = [];
			groupedData[key].forEach((item) => {
				const index = chartData.labels.indexOf(item[this.GroupByDateColumns]);
				datasetData[index] = item[this.AggregationColumns];
			});
			const datasetObj = {
				label: key,
				data: datasetData,
			};
			return datasetObj;
		});
		chartData.datasets = datasets;
		this.Chartdata = chartData;
	}
	PrepareChartdataWithoutGroupBy(data) {
		const chartdata = { labels: [], datasets: [{ data: [], label: '' }] };

		data.forEach((item) => {
			chartdata.labels.push(item[this.GroupByTableColumns]);
			chartdata.datasets[0].data.push(item[this.AggregationColumns]);
		});
		chartdata.datasets[0].label = this.AggregationColumns;
		this.Chartdata = chartdata;
	}
	groupByFieldName(data, fieldName) {
		const groupedData = {};

		data.forEach((item) => {
			const fieldValue = item[fieldName];

			if (!groupedData[fieldValue]) {
				groupedData[fieldValue] = [item];
			} else {
				groupedData[fieldValue].push(item);
			}
		});

		return groupedData;
	}
	GetPalette() {
		if (this.Chartdata) {
			if (this.ChartPalette) {
				this.metaService
					.ReadPalette(this.ChartPalette)
					.subscribe((paletteInfo) => {
						let colorsArray = [];
						if (paletteInfo.Palette && paletteInfo.Palette.length > 0) {
							paletteInfo.Palette.forEach((color) => {
								colorsArray.push(Color.HexFromColor(color));
							});
						}
						if (colorsArray && colorsArray.length > 0) {
							this.DefaultPalette = colorsArray;
						}
						this.Chartdata = {
							...this.Chartdata,
							datasets: this.SetPrimeNGChartPalette(this.Chartdata.datasets),
						};
						this.cdRef.detectChanges();
					});
				return;
			}
			this.Chartdata = {
				...this.Chartdata,
				datasets: this.SetPrimeNGChartPalette(this.Chartdata.datasets),
			};
			this.cdRef.detectChanges();
		}
	}
	SetPrimeNGChartPalette(seriesData: any[]): any {
		if (this.GroupByDate) {
			const coloredSeries = seriesData.map((series, index) => {
				const backgroundColorArray = series.data.map(
					(data) => this.DefaultPalette[index % this.DefaultPalette.length]
				);
				const colorObject = {
					...series,
					backgroundColor: backgroundColorArray,
				};
				if (this.ChartType === 'line') {
					colorObject.borderColor =
						this.DefaultPalette[index % this.DefaultPalette.length];
					colorObject.fill = false;
					colorObject.tension = 0.4;
				}
				return colorObject;
			});
			return coloredSeries;
		}
		const backgroundColor = [];
		seriesData[0]?.data.forEach((series, index) => {
			backgroundColor.push(
				this.DefaultPalette[index % this.DefaultPalette.length]
			);
		});
		return [
			{
				...seriesData[0],
				backgroundColor: backgroundColor,
			},
		];
	}

	ChangeChartSettings() {
		if (!this.ChartType) {
			NotificationHelper.Error('@@Please select chart type', '@@Error');
			return;
		}
		if (this.HideXAxisGrid) {
			this.Options.scales.x.grid.display = false;
		}
		if (!this.HideXAxisGrid) {
			this.Options.scales.x.grid.display = true;
		}
		if (this.HideYAxisGrid) {
			this.Options.scales.y.grid.display = false;
		}
		if (!this.HideYAxisGrid) {
			this.Options.scales.y.grid.display = true;
		}
		if (this.XLabel) {
			this.Options.scales.x.title.text = this.XLabel;
		}
		if (this.YLabel) {
			this.Options.scales.y.title.text = this.YLabel;
		}
		if (this.BoldXLabel) {
			this.Options.scales.x.title.font.weight = 900;
		}
		if (this.ItalicXLabel) {
			this.Options.scales.x.title.font.style = 'italic';
		}
		if (this.ColorXLabel) {
			const color = Color.HexFromColor(this.ColorXLabel);
			this.Options.scales.x.title.color = color;
		}
		if (this.BoldYLabel) {
			this.Options.scales.y.title.font.weight = 900;
		}
		if (this.ItalicYLabel) {
			this.Options.scales.y.title.font.style = 'italic';
		}
		if (this.ColorYLabel) {
			const color = Color.HexFromColor(this.ColorYLabel);
			this.Options.scales.y.title.color = color;
		}
		if (this.FontSizeXLabel) {
			this.Options.scales.x.title.font.size = this.FontSizeXLabel;
		}
		if (this.FontSizeYLabel) {
			this.Options.scales.y.title.font.size = this.FontSizeYLabel;
		}
		if (this.GridColor) {
			const color = Color.HexFromColor(this.GridColor);
			this.Options.scales.x.grid.color = color;
			this.Options.scales.y.grid.color = color;
		}
		if (!this.GridColor) {
			this.Options.scales.x.grid.color = surfaceBorder;
			this.Options.scales.y.grid.color = surfaceBorder;
		}
		if (this.ChartType) {
			const gridTypes = ['line', 'bar', 'radar'];
			if (gridTypes.includes(this.ChartType)) {
				this.Options.scales.x.display = true;
				this.Options.scales.y.display = true;
			}
			if (!gridTypes.includes(this.ChartType)) {
				this.Options.scales.x.display = false;
				this.Options.scales.y.display = false;
			}
		}
		this.cdRef.detectChanges();
	}
	getMonthNameByNumber(monthNumber) {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		// Validate that the monthNumber is within the valid range
		if (monthNumber >= 1 && monthNumber <= 12) {
			// Return the month name from the array
			return monthNames[monthNumber - 1]; // Adjusting to 0-based index
		} else {
			return 'Invalid month number';
		}
	}
}
