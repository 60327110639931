import {
    AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import { UUID } from 'angular2-uuid';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistry, TaskType } from '../../../helpers/task.registry';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';
import { NewTaskDialog } from '../../taskpanels/new.task.dialog';

@Component({
    selector: 'datatask-panel',
    templateUrl: './datatask.panel.html',
    styles: [
        '.taskCaption { font-size: 18px; margin-bottom: 10px; }',
        '.listPanel { margin-left: 5px; margin-right: 5px; }',
        '.listPanel .settingsList { width: 250px; margin-right: 0px; }'
    ]
})
export class DataTaskPanel implements AfterViewInit {
    AllListItems = [];
    ListItems = [];
    selectedNode;
    ActiveStyle = {};
    SelectedTaskCaption = '';
    Initialized = false;

    TaskListValue = [];
    @Input()
    get TaskList() {
        return this.TaskListValue;
    }
    set TaskList(val) {
        this.TaskListValue = val;
        this.TaskListChange.emit(val);
        this.updateTaskList();
    }
    @Output() TaskListChange = new EventEmitter<any>();

    DataDescriptionValue;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(val) {
        this.DataDescriptionValue = val;
        this.DataDescriptionChange.emit(val);
    }
    @Output() DataDescriptionChange = new EventEmitter<any>();

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterList();
    }

    constructor(private factoryResolver: ComponentFactoryResolver, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.Initialized = true;
        this.updateTaskList();
    }

    updateTaskList() {
        if (this.Initialized) {
            const list = [];
            if (this.TaskListValue) {
                this.TaskListValue.forEach((task, i) => {
                    const item = {
                        Caption: 'Missing TaskType',
                        ID: i,
                        Tooltip: ''
                    };
                    if (typeof task.ID === 'undefined') {
                        task.ID = UUID.UUID();
                    }
                    item.Tooltip = item.Caption;
                    if (task.TaskType) {
                        item.Caption = task.TaskType;
                        const desc = TaskRegistry.get(task.TaskType);
                        if (desc && desc.SettingsHelper) {
                            item.Caption = desc.SettingsHelper.getCaption(task, this.DataDescriptionValue);
                            if (task.MeasureUniqueID) {
                                // item.Tooltip = new Promise<string>((resolve, reject) => {
                                // const tooltip =
                                desc.SettingsHelper.Gettooltip(task, this.DataDescriptionValue).then(tooltip => {
                                    item.Tooltip = (!tooltip || tooltip == '') ? item.Caption : tooltip;
                                    if (task.TaskType) {
                                        item.Tooltip += ' #' + task.TaskType;
                                        if (task.Formula) {
                                            item.Tooltip += ' #' + task.Formula;
                                        }
                                        if (task.IsActive) {
                                            item.Tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@Aktiv: ');
                                        }
                                        else {
                                            item.Tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@Inaktiv: ');
                                        }
                                    }
                                });
                            }
                        }
                    }
                    list.push(item);
                });
            }
            this.AllListItems = list;
            this.FilterList();
            this.setSelectedItem(null);
        }
    }

    FilterList() {
        this.ListItems = this.AllListItems.filter(item =>
            (!this._SearchValue || item.Caption.toString().search(this._SearchValue) !== -1));
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
            let task = null;
            if (selection && this.TaskListValue) {
                task = this.TaskListValue[selection.ID];
            }
            this.setSelectedItem(task);
        }
    }

    addItem() {
        NewTaskDialog.ShowDialog(TaskType.DataTask, null, (r) => {
            const task = {
                TaskType: r,
                IsActive: true,
                ID: UUID.UUID()
            };
            const item = {
                Caption: r,
                ID: this.TaskListValue.length
            };
            this.TaskListValue.push(task);
            const desc = TaskRegistry.get(r);
            if (desc && desc.SettingsHelper) {
                item.Caption = desc.SettingsHelper.getCaption(task, this.DataDescriptionValue);
            }
            this.AllListItems.push(item);
            this.SearchValue = null;
            this.FilterList();
            this.selectedNode = item;
            this.setSelectedItem(task);
            this.cdRef.detectChanges();
        });
    }

    copyItem() {
        if (this.selectedNode) {
            const oldTask = this.TaskListValue[this.selectedNode.ID];
            const task = JSON.parse(JSON.stringify(oldTask));
            const item = {
                Caption: this.selectedNode.Caption,
                ID: this.TaskListValue.length
            };
            this.TaskListValue.push(task);
            this.AllListItems.push(item);
            this.FilterList();
            this.selectedNode = item;
            this.setSelectedItem(task);
            this.cdRef.detectChanges();
        }
    }

    setSelectedItem(item) {
        let caption = '';
        const activeStyle = {};
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (item && item.TaskType) {
                const desc = TaskRegistry.get(item.TaskType);
                if (desc) {
                    caption = desc.Caption;
                    const factory = this.factoryResolver.resolveComponentFactory(desc.SettingsControl);
                    const component: any = this.viewContainerRef.createComponent(factory).instance;
                    component.DataDescription = this.DataDescriptionValue;
                    component.Settings = item;
                    if (item.IsActive === false) {
                        activeStyle['opacity'] = '0.5';
                    }
                }
            }
        } else if (item && item.IsActive === false) {
            activeStyle['opacity'] = '0.5';
        }
        this.ActiveStyle = activeStyle;
        this.SelectedTaskCaption = caption;
    }

    deleteAllItems() {
        if (this.TaskListValue) {
            if (this.TaskListValue.length > 0) {
                const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie alle Tasks loeschen moechten?');
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                        if (retVal === MessageBoxResult.Yes) {
                            this.TaskListValue = [];
                            this.ListItems = [];
                            this.AllListItems = [];
                            this.cdRef.detectChanges();
                        }
                    });
            }
        }
    }

    deleteItem() {
        const sel = this.selectedNode;
        if (sel) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Task \'{0}\' loeschen moechten?');
            text.FormatParams.push(sel.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                    if (retVal === MessageBoxResult.Yes) {
                        this.selectedNode = null;
                        this.setSelectedItem(null);
                        this.TaskListValue.splice(sel.ID, 1);
                        this.AllListItems.splice(sel.ID, 1);
                        for (let i = sel.ID; i < this.AllListItems.length; i++) {
                            this.AllListItems[i].ID--;
                        }
                        this.FilterList();
                        this.cdRef.detectChanges();
                    }
                });
        }
    }

    refreshList(selectedNode = null, task = null) {
        this.FilterList();
        this.selectedNode = selectedNode;
        this.setSelectedItem(task);
    }

    moveUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListValue && this.TaskListValue.length > 0) {
            const task = this.TaskListValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListValue.splice(0, 0, task);
            this.AllListItems.splice(this.selectedNode.ID, 1);
            this.AllListItems.splice(0, 0, this.selectedNode);
            this.AllListItems.forEach((x, i) => { x.ID = i; });
            this.refreshList(this.selectedNode, task);
        }
    }

    moveOneUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListValue && this.TaskListValue.length > 0) {
            const task = this.TaskListValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListValue.splice(this.selectedNode.ID - 1, 0, task);
            this.AllListItems.splice(this.selectedNode.ID, 1);
            this.AllListItems.splice(this.selectedNode.ID - 1, 0, this.selectedNode);
            this.AllListItems.forEach((x, i) => { x.ID = i; });
            this.refreshList(this.selectedNode, task);
        }
    }

    moveOneDown() {
        if (this.selectedNode && this.TaskListValue && this.TaskListValue.length > 0 &&
            this.selectedNode.ID < this.TaskListValue.length - 1) {
            const task = this.TaskListValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListValue.splice(this.selectedNode.ID + 1, 0, task);
            this.AllListItems.splice(this.selectedNode.ID, 1);
            this.AllListItems.splice(this.selectedNode.ID + 1, 0, this.selectedNode);
            this.AllListItems.forEach((x, i) => { x.ID = i; });
            this.refreshList(this.selectedNode, task);
        }
    }

    moveDown() {
        if (this.selectedNode && this.TaskListValue && this.TaskListValue.length > 0 &&
            this.selectedNode.ID < this.TaskListValue.length - 1) {
            const task = this.TaskListValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListValue.push(task);
            this.AllListItems.splice(this.selectedNode.ID, 1);
            this.AllListItems.push(this.selectedNode);
            this.AllListItems.forEach((x, i) => { x.ID = i; });
            this.refreshList(this.selectedNode, task);
        }
    }

    setActive() {
        const activeStyle = {};
        if (this.selectedNode && this.TaskListValue) {
            const task = this.TaskListValue[this.selectedNode.ID];
            if (task.IsActive === false) {
                task.IsActive = true;
            } else {
                task.IsActive = false;
                activeStyle['opacity'] = '0.5';
            }
        }
        this.ActiveStyle = activeStyle;
    }
}
