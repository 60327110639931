import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { AddColumnsSettings } from '../addcolumns/addcolumns.settings';

@Component({
    selector: 'wf-simplecomparison-settings',
    templateUrl: './simplecomparison.settings.html'
})
export class SimpleComparisonSettings extends WorkflowDialogContent {
    Data = new SimpleComparisonSettingsData();
    SourceColumns = [];
    TargetColumns = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'simpleComparisonWFModule';
        reg.Caption = '@@Einfacher Datenabgleich';
        reg.GroupID = 'reldataoperations';
        reg.Index = 50;
        reg.SettingsControl = SimpleComparisonSettings;
        reg.SettingsTypeHelper = new SimpleComparisonSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource && dataSource.Columns) {
            this.SourceColumns.push(...dataSource.Columns);
        }
        AddColumnsSettings.ReadOtherDataSource(this.WFData, this.ModuleID, 2).then(list => {
            if (list) {
                this.TargetColumns = list;
            }
        });
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SimpleComparisonSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }

    addPrimaryKey() {
        if (this.Data) {
            if (this.Data.PrimaryMapping) {
                this.Data.PrimaryMapping.push(new SimpleComparisonMapping());
            } else {
                this.Data.PrimaryMapping = [new SimpleComparisonMapping()];
            }
        }
    }

    removePrimaryKey(i) {
        if (this.Data && this.Data.PrimaryMapping && this.Data.PrimaryMapping.length > 1) {
            this.Data.PrimaryMapping.splice(i, 1);
        }
    }

    addHashColumn() {
        if (this.Data) {
            if (this.Data.HashMapping) {
                this.Data.HashMapping.push(new SimpleComparisonMapping());
            } else {
                this.Data.HashMapping = [new SimpleComparisonMapping()];
            }
        }
    }

    removeHashColumn(i) {
        if (this.Data && this.Data.HashMapping && this.Data.HashMapping.length > 1) {
            this.Data.HashMapping.splice(i, 1);
        }
    }
}

// @dynamic
export class SimpleComparisonSettingsData extends AServiceWorkflowData {
    Delete = false;
    @Type(() => SimpleComparisonMapping)
    PrimaryMapping: SimpleComparisonMapping[] = [];
    @Type(() => SimpleComparisonMapping)
    HashMapping: SimpleComparisonMapping[] = [];
    TargetHash: string;
    BlockLines = 0;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.SimpleComparison.SimpleComparisonSettingsData';
    }
}

export class SimpleComparisonMapping {
    Source: string;
    Target: string;
}

export class SimpleComparisonSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getEntryPoints(): WorkflowExitInfo[] {
        const entry = new WorkflowExitInfo();
        entry.ID = 0;
        entry.Type = 'relData';

        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';

        const target = new WorkflowExitInfo();
        target.ID = 2;
        target.Label = '@@Ziel';
        target.Type = 'relData';

        return [entry, fe, target];
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = [];

        const newRow = new WorkflowExitInfo();
        newRow.ID = 2;
        newRow.Label = '@@Neue Datenzeile';
        newRow.Type = 'relData';
        retVal.push(newRow);

        const changedRow = new WorkflowExitInfo();
        changedRow.ID = 3;
        changedRow.Label = '@@Geaenderte Datenzeile';
        changedRow.Type = 'relData';
        retVal.push(changedRow);

        const unChangedRow = new WorkflowExitInfo();
        unChangedRow.ID = 4;
        unChangedRow.Label = '@@Unveraenderte Datenzeile';
        unChangedRow.Type = 'relData';
        retVal.push(unChangedRow);

        if (settings && settings.Delete) {
            const deletedRows = new WorkflowExitInfo();
            deletedRows.ID = 5;
            deletedRows.Label = '@@Geloeschte Datenzeilen';
            deletedRows.Type = 'relData';
            retVal.push(deletedRows);
        } else {
            const exit = new WorkflowExitInfo();
            exit.ID = 1;
            exit.Label = '@@Ende';
            exit.Type = 'forEach';
            retVal.push(exit);
        }

        return retVal;
    }

    getEmptySettingsInstance() {
        return new SimpleComparisonSettingsData();
    }

    async fillActualState(module, state, wfData) {

    }
}
