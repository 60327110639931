import { OnInit, Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslatedString, TranslatedStringElement } from "../../../models/translatedstring.model";
import { StandardRequestBase } from "../../../services/request-base";

@Component({
    selector: 'translation-textbox-dialog',
    templateUrl: './translation.textbox.dialog.html',
    styleUrls: ['./translation.textbox.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTextBoxDialog implements OnInit {

    TranslatedString: TranslatedString = new TranslatedString();
    AllLanguages = [];
    RemainingLanguages = [];

    constructor(private dialogRef: MatDialogRef<TranslationTextBoxDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private requestService: StandardRequestBase) {
        dialogRef.disableClose = true;
        if (data) {
            this.TranslatedString = data.Data;
        }
        requestService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            this.AllLanguages = languages;
            this.updateRemaining();
        });
    }

    ngOnInit(): void {
    }

    getLanguageName(lcid: number):string {
        var retVal = '';
        this.AllLanguages.some(function (lang) {
            if (lang.LCID == lcid) {
                retVal = lang.Caption;
                return true;
            }
            return false;
        });
        return retVal;
    }

    deleteClicked(ev, lang) {
        this.TranslatedString.SerializedTranslations.splice(this.TranslatedString.SerializedTranslations.indexOf(lang), 1);
        this.updateRemaining();
    }

    addLanguage(ev, lcid, text) {
        var seri = new TranslatedStringElement();
        seri.LCID = lcid;
        seri.Value = text;
        this.TranslatedString.SerializedTranslations.push(seri);
        this.updateRemaining();       
    }

    close() {
        this.dialogRef.close({ ok: true, data: this.TranslatedString });
    }
    cancel() {
        this.dialogRef.close({ ok: false });
    }

    updateRemaining() {
        var remaining = [];
        var seri = this.TranslatedString.SerializedTranslations;
        this.AllLanguages.forEach(function (lang) {
            var existing = false;
            seri.some(function (st) {
                if (st.LCID == lang.LCID) {
                    existing = true;
                    return true;
                }
                return false;
            });
            if (!existing) {
                remaining.push(lang);
            }
        });
        this.RemainingLanguages = remaining;            
        
    }

}