import { UUID } from "angular2-uuid";
import { Subject } from "rxjs";
import { DefaultTaskPosition } from "../enums/query.enum";
import { DataDescriptionHelper } from "../../components/query/ddhelper.query";
import { MeasureInfo } from "../datadescription/multi/measureinfo.model";
import { LevelNode } from "../datadescription/multi/levelnode.model";
import { MultiResult } from '../datadescription/multi/multiresult.model';
import { DataDescription } from '../datadescription/multi/datadescription.model';
import { Base, JsonIgnore } from "../base.model";
import { Row } from "../basic/row.model";
import { Column } from "../basic/column.model";
// @dynamic
export abstract class ATask extends Base {
    ID: UUID = UUID.UUID();
    IsActive: boolean = true;

    //json ignore
    @JsonIgnore
    Matrix: any;
    @JsonIgnore
    DataDescription: any;
    @JsonIgnore
    AlwaysExecuteFormatTaskOnExport: boolean = false;
    @JsonIgnore
    ActiveByProcessButton?: boolean = null;
    @JsonIgnore
    CaptionChanged: Subject<any> = new Subject<any>();

    abstract ClientImageID: string;
    abstract TaskType: string;
    abstract Caption: string;
    abstract ToolTipCaption: string;
    abstract ToolTipDescription: string;
    abstract IsValid: boolean;

    abstract Execute();

    get CaptionExplanation(): string { return this.Caption; }
    get CanCombineTasks(): boolean { return false; }
    get IsActuallyActive(): boolean {
        if (this.ActiveByProcessButton != null) {
            return this.ActiveByProcessButton;
        } else {
            return this.IsActive;
        }
    }
    //defaulttask
    get Position(): DefaultTaskPosition { return DefaultTaskPosition.BeforeDataTasks; }
    get OrderID(): number { return 0; }

    AreTasksCominable(task: ATask): boolean { return false };
    GetToolTip(): string { return this.Caption; }
    toString(): string { return this.Caption; }

    GetLevelNode(leveluniqueID?: number): LevelNode {
        if (leveluniqueID) {
            if (this.DataDescription) {
                return DataDescriptionHelper.FindLevelNodeByID(this.DataDescription, leveluniqueID, false)
            }
        }
        return null;
    }

    GetMeasureInfo(measureUniqueID?: number): MeasureInfo {
        if (measureUniqueID) {
            if (this.DataDescription) {
                return DataDescriptionHelper.FindMeasureByID(this.DataDescription, measureUniqueID, false)
            }
        }
        return null;
    }

    //GetFlatLevel()
    //GetFlatMeasure()

    OnCaptionChanged() {
        this.CaptionChanged.next(null);
    }
}

export interface ITaskExecuter {
    Init(settings, result: MultiResult, dataDescription: DataDescription, context);
    Execute();
}

export interface IPlanningTaskExecuter {
    Init(settings, Rows: Row[], Columns: Column[], context);
    Execute();
}