import { Component } from '@angular/core';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { TranslationDialog } from '../../dialogs/translationdialog/translation.dialog';

@Component({
    selector: 'filter-dropdown-menutab',
    templateUrl: './filter.dropdown.menu.tab.html'
})
export class FilterDropdownMenuTab extends APropertyTab {
    translateCaption(key) {
        TranslationDialog.ShowDialog(key);
    }
}
