import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { InteractivePropertyInfo } from '../../../components/common/datasourceselectioncontol/datasource.selection.control';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { RestService } from '../../../services/rest.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';
import { RestObjectInfoDialog } from './rest.object.info.dialog';

@Component({
    selector: 'wf-execute-rest-endpoint-settings',
    templateUrl: 'execute.rest.endpoint.settings.html'
})
export class ExecuteRestEndPointSettings extends FormulaWorkflowDialogContent {
    Data = new ExecuteRestEndPointSettingsData();
    DataModels = [];
    DataSourceQuery = new DataSourceQuery(null);
    EndPoints = [];
    Parameters = [];
    BodyTypes = [];
    RequestType;
    BodyRequired = false;
    ResponseStatusCodes = [];
    AvailableStatusCodes = [];
    ResponseContentTypes = {};

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'executeRestEndpointModule';
        reg.Caption = '@@Rest Endpunkt';
        reg.GroupID = 'wfActions';
        reg.Index = 35;
        reg.SettingsControl = ExecuteRestEndPointSettings;
        reg.SettingsTypeHelper = new ExecuteRestEndPointSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: RestService, private dataService: DataModelService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        const data = this.Data;
        this.dataService.GetModels().subscribe(models => {
            if (models) {
                this.DataModels = models;
                if (models.some(x => x.SID === data.DataModel)) {
                    this.updateQuery();
                    this.dataService.GetDataSourcesByQuery(this.DataSourceQuery).subscribe(dataSources => {
                        if (dataSources) {
                            if (dataSources.some(x => x.SID === data.DataSource)) {
                                this.service.LoadAllRestEndPointInfosByDataSource(data.DataSource).subscribe(endPoints => {
                                    if (endPoints) {
                                        this.EndPoints = endPoints;
                                        if (this.EndPoints.some(x => x.SID === data.EndPointID)) {
                                            this.service.loadEndPoint(data.EndPointID).subscribe(endPoint => {
                                                this.readEndPoint(endPoint);
                                                if (this.Parameters.length > 0) {
                                                    data.Parameters.forEach(p => {
                                                        const actParam = this.Parameters.find(x => x.Name === p.Name);
                                                        if (actParam) {
                                                            actParam.Value = p.Value;
                                                        }
                                                    });
                                                }
                                                const resultList = [];
                                                data.ResultSettings.forEach(x => {
                                                    const contentTypes = this.ResponseContentTypes['' + x.StatusCode];
                                                    if (contentTypes && contentTypes.some(ct => ct.ContentType === x.ContentType)) {
                                                        resultList.push(x);
                                                    }
                                                });
                                                data.ResultSettings = resultList;
                                                this.updateAvailable();
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ExecuteRestEndPointSettingsData, json);
        }
    }

    getResult() {
        const parameters = [];
        this.Parameters.forEach(p => {
            if (p.Value) {
                const saveParam = new EndPointParameter();
                saveParam.Name = p.Name;
                saveParam.Value = p.Value;
                parameters.push(saveParam);
            }
        });
        this.Data.Parameters = parameters;
        const result = [];
        this.Data.ResultSettings.forEach(x => {
            if (typeof x.StatusCode === 'number' && x.ContentType) {
                result.push(x);
            }
        });
        this.Data.ResultSettings = result;
        if (this.Data.InteractiveProperties) {
            this.Data.InteractiveProperties = this.Data.InteractiveProperties.filter(x => x.Value);
        }
        return this.Data;
    }

    updateQuery() {
        const query = new DataSourceQuery(this.Data.DataModel);
        query.DBInfoType = '0ccbe866-f06d-44a5-aad2-bdb11c9cf327';
        query.FillInteractiveProperties = true;
        this.DataSourceQuery = query;
    }

    modelChanged() {
        this.EndPoints = [];
        this.Parameters = [];
        this.BodyTypes = [];
        this.RequestType = null;
        this.BodyRequired = false;
        this.Data.DataSource = null;
        this.Data.InteractiveProperties = [];
        this.Data.EndPointID = null;
        this.Data.Parameters = [];
        this.Data.BodySettings = new EndPointBodyObject();
        this.Data.ResultSettings = [];
        this.updateQuery();
    }

    dataSourceChanged() {
        this.EndPoints = [];
        this.Parameters = [];
        this.BodyTypes = [];
        this.RequestType = null;
        this.BodyRequired = false;
        this.Data.EndPointID = null;
        this.Data.Parameters = [];
        this.Data.BodySettings = new EndPointBodyObject();
        this.Data.ResultSettings = [];
        this.service.LoadAllRestEndPointInfosByDataSource(this.Data.DataSource).subscribe(endPoints => {
            if (endPoints) {
                this.EndPoints = endPoints;
            }
        });
    }

    onEndPointChanged() {
        const oldParams = this.Parameters.slice();
        this.Parameters = [];
        this.BodyTypes = [];
        this.RequestType = null;
        this.BodyRequired = false;
        this.ResponseStatusCodes = [];
        this.ResponseContentTypes = {};
        this.Data.Parameters = [];
        this.Data.BodySettings = new EndPointBodyObject();
        this.Data.ResultSettings = [];
        this.service.loadEndPoint(this.Data.EndPointID).subscribe(endPoint => {
            this.readEndPoint(endPoint);
            if (this.Parameters.length > 0) {
                oldParams.forEach(x => {
                    if (x.Value) {
                        const newParam = this.Parameters.find(y => y.Name === x.Name);
                        if (newParam) {
                            newParam.Value = x.Value;
                        }
                    }
                });
            }
            if (this.ResponseStatusCodes.length === 1) {
                const rs = new EndPointResultObject();
                rs.StatusCode = this.ResponseStatusCodes[0];
                const obj = this.ResponseContentTypes['' + rs.StatusCode];
                if (obj.length > 0) {
                    rs.ContentType = obj[0].ContentType;
                }
                this.Data.ResultSettings.push(rs);
                this.AvailableStatusCodes.splice(0, 1);
            }
        });
    }

    readEndPoint(endPoint) {
        if (endPoint) {
            this.RequestType = endPoint.RequestType;
            if (endPoint.Parameters) {
                const paramList = [];
                endPoint.Parameters.forEach(p => {
                    paramList.push({
                        Name: p.Name,
                        Caption: p.Name + ' (' + p.Type + ')' + (p.Required ? '*' : ''),
                        Description: p.Description,
                        Value: null
                    });
                });
                this.Parameters = paramList;
            }
            if (endPoint.RequestBody) {
                this.BodyRequired = endPoint.RequestBody.Required;
                if (endPoint.RequestBody.Contents) {
                    this.BodyTypes = endPoint.RequestBody.Contents;
                }
            }
            if (endPoint.Responses) {
                const responseStatus = [];
                const contentTypes = {};
                endPoint.Responses.forEach(response => {
                    responseStatus.push(response.StatusCode);
                    contentTypes['' + response.StatusCode] = response.Contents;
                });
                this.ResponseStatusCodes = responseStatus;
                this.AvailableStatusCodes = responseStatus.slice();
                this.ResponseContentTypes = contentTypes;
            }
        }
    }

    showBodyInfo() {
        if (this.Data && this.Data.BodySettings && this.Data.BodySettings.ContentType) {
            const entry = this.BodyTypes.find(x => x.ContentType === this.Data.BodySettings.ContentType);
            if (entry) {
                this.showRestObjectInfo(entry.ObjectID);
            }
        }
    }

    addResponse() {
        if (this.Data) {
            if (this.Data.ResultSettings) {
                this.Data.ResultSettings.push(new EndPointResultObject());
            } else {
                this.Data.ResultSettings = [new EndPointResultObject()];
            }
        }
    }

    removeResponse(i) {
        if (this.Data && this.Data.ResultSettings) {
            this.Data.ResultSettings.splice(i, 1);
        }
    }

    onStatusChange(r) {
        const obj = this.ResponseContentTypes['' + r.StatusCode];
        if (r.ContentType && obj.some(x => x.ContentType === r.ContentType)) {
            // nichts tun
        } else if (obj.length > 0) {
            r.ContentType = obj[0].ContentType;
        }
        this.updateAvailable();
    }

    updateAvailable() {
        this.AvailableStatusCodes = this.ResponseStatusCodes.filter(x => !this.Data.ResultSettings.some(y => y.StatusCode === x));
    }

    showResponseInfo(r) {
        const responseContentType = this.ResponseContentTypes['' + r.StatusCode];
        if (responseContentType) {
            const entry = responseContentType.find(x => x.ContentType === r.ContentType);
            if (entry) {
                this.showRestObjectInfo(entry.ObjectID);
            }
        }
    }

    showRestObjectInfo(objectID) {
        RestObjectInfoDialog.ShowDialog(objectID);
    }
}

export class EndPointParameter {
    Name: string;
    Value: string;
}

export class EndPointBodyObject {
    ContentType: string;
    Formula: string;
}

export class EndPointResultObject {
    StatusCode: number;
    ContentType: string;
}

// @dynamic
export class ExecuteRestEndPointSettingsData extends AServiceWorkflowData {
    DataModel: string;
    DataSource: string;
    @Type(() => InteractivePropertyInfo)
    InteractiveProperties: InteractivePropertyInfo[] = [];
    EndPointID: string;
    @Type(() => EndPointParameter)
    Parameters: EndPointParameter[] = [];
    @Type(() => EndPointBodyObject)
    BodySettings: EndPointBodyObject = new EndPointBodyObject();
    @Type(() => EndPointResultObject)
    ResultSettings: EndPointResultObject[] = [];
    StatusKey: string;
    UseForState = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.Rest.ExecuteRestEndPointSettingsData';
    }
}

export class ExecuteRestEndPointSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = [];
        if (settings && settings.ResultSettings) {
            settings.ResultSettings.forEach(x => {
                const wei = new WorkflowExitInfo();
                wei.ID = x.StatusCode;
                wei.Label = '' + x.StatusCode;
                retVal.push(wei);
            });
        }
        const def = new WorkflowExitInfo();
        def.Label = '@@Default';
        retVal.push(def);
        return retVal;
    }

    getEmptySettingsInstance() {
        return new ExecuteRestEndPointSettingsData();
    }
}
