import { WorkflowDialogContent, DataCheck } from '../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';
import { serialize, deserialize } from 'class-transformer';

@Component({
    selector: 'wf-sleep-settings',
    templateUrl: './sleep.settings.html'
})
export class SleepSettings extends WorkflowDialogContent {
    Data = new SleepSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'sleepWFModule';
        reg.Caption = '@@Verzoegerung';
        reg.GroupID = 'wfActions';
        reg.Index = 100;
        reg.SettingsControl = SleepSettings;
        reg.SettingsTypeHelper = new SleepSettingsDataHelper();
        reg.Executer = SleepModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SleepSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.SleepTime <= 0) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Die Verzoegerung muss groesser als 0 Millisekunden sein.';
        }
        return retVal;
    }
}

export class SleepSettingsData extends AServiceWorkflowData {
    SleepTime: number;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Sleep.SleepSettingsData';
    }
}

export class SleepSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new SleepSettingsData();
    }
}

export class SleepModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.SleepTime > 0) {
                await new Promise(resolve => setTimeout(resolve, status.ActualSettings.SleepTime));
            } else {
                status.Logger.logWarning('Sleep modul: Sleep time must not be negative or zero.');
            }
            return 0;
        } else {
            status.Logger.logError('Sleep modul: No settings found.');
        }
        return super.execute(status);
    }
}
