import { Component } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { TopDownTaskExecuter } from '../../../models/tasks/datatasks/topdown.model';
import { ALevelMeasurePanel, ALevelMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'topdown-task-panel',
    templateUrl: './topdown.task.panel.html'
})
export class TopDownTaskPanel extends ALevelMeasurePanel {
    CompareTypes: any[];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = 'topdown';
        reg.Caption = '@@Top-Down';
        reg.SettingsControl = TopDownTaskPanel;
        reg.SettingsHelper = new TopDownSettingsHelper();
        reg.Executer = TopDownTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }
}

export class TopDownSettingsHelper extends ALevelMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@TopDown');
        }
        return TranslateHelper.TranslatorInstance.instant('@@TopDown');
    }
}
