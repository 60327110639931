import { Type, plainToClass } from 'class-transformer';
import { DynamicTimeType, RangeOrientation } from '../enums/query.enum';
import { UUID } from 'angular2-uuid';
import { TranslatedString } from '../translatedstring.model';
import { LevelType } from '../enums';

export class TimeReference {
  ReferenceDate: Date;
  TargetDate: Date;
}

// @dynamic
export class DynamicTimePoint {
    ID: UUID = UUID.UUID();
    @Type(() => TranslatedString)
    Name: TranslatedString = new TranslatedString();
    @Type(() => TranslatedString)
    Description: TranslatedString = new TranslatedString();
    TimeType: DynamicTimeType;
    FixTime: Date;
    TimeMovement: Map<LevelType, number> = new Map<LevelType, number>();
    FixationLevel: LevelType;
    TimeFixation: Map<LevelType, number> = new Map<LevelType, number>();
    SundayShift = 0;
    @Type(() => TimeReference)
    References: TimeReference[] = [];
    Orientation: RangeOrientation = RangeOrientation.End;

    clone(): DynamicTimePoint {
        return plainToClass(DynamicTimePoint, JSON.parse(JSON.stringify(this)));
    }

    public constructor(init?: Partial<DynamicTimePoint>) {
        Object.assign(this, init);
    }

    toJSON() {
        let result;
        result = Object.assign({}, this);
        result.TimeMovement = {};
        this.TimeMovement.forEach((v, k) => {
            result.TimeMovement[LevelType[k]] = v;
        });
        result.TimeFixation = {};
        this.TimeFixation.forEach((v, k) => {
            result.TimeFixation[LevelType[k]] = v;
        });
        return result;
    }

    static plainToClass(dtp) {
        dtp = plainToClass(DynamicTimePoint, dtp);
        if (!(dtp.TimeMovement instanceof Map)) {
            const map = new Map<LevelType, number>();
            if (dtp.TimeMovement) {
                Object.keys(dtp.TimeMovement).forEach(k => {
                    map.set(LevelType[k], dtp.TimeMovement[k]);
                });
            }
            dtp.TimeMovement = map;
        }
        if (!(dtp.TimeFixation instanceof Map)) {
            const map = new Map<LevelType, number>();
            if (dtp.TimeFixation) {
                Object.keys(dtp.TimeFixation).forEach(k => {
                    map.set(LevelType[k], dtp.TimeFixation[k]);
                });
            }
            dtp.TimeFixation = map;
        }
        return dtp;
    }
}
