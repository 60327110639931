import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import moment from 'moment';
import { ChartSelectEvent, GoogleChartInterface } from 'ng2-google-charts';
import { BaseDialog } from "../../components/dialogs/basedialog/base.dialog";
import { Comparer } from "../../models/enums/comparer.enum";
import { DialogButton } from "../../models/enums/dialogbutton.enum";
import { monitoringChartFunctions } from "../../models/enums/monitoringchartfunctions.enum";
import { UnitType } from "../../models/enums/unittype.enum";
import { RequestColumn } from "../../models/rest/requestcolumn";
import { RequestFilter } from "../../models/rest/requestfilter";
import { RequestOptions } from "../../models/rest/requestoptions";
import { LogsService } from "../../services/logs.service";
import { MonitoringService } from "../../services/monitoring.service";
import { SchedulerService } from "../../services/scheduler.service";
import { LogsdialogComponent } from "./logsdialog.component";
import { LogsdialogsystemComponent } from "./logsdialogsystem.component";

@Component({
    selector: 'logs-settings',
    templateUrl: './logs.settings.html',
    styleUrls: ['./logs.settings.css']
})
export class LogsSettings implements OnInit, AfterViewInit {

    Columns = [
        {
            Caption: '@@MessageTime',
            Data: 'MessageTime',
            Name: 'MessageTime',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.DateTime',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@UserName',
            Data: 'UserName',
            Name: 'UserName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@TypeName',
            Data: 'TypeName',
            Name: 'TypeName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@MethodName',
            Data: 'MethodName',
            Name: 'MethodName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Line',
            Data: 'Line',
            Name: 'Line',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.Int32',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Parameters',
            Data: 'Parameters',
            Name: 'Parameters',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@MessageLevel',
            Data: 'MessageLevel',
            Name: 'MessageLevel',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.Int32',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Message',
            Data: 'Message',
            Name: 'Message',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@StackTrace',
            Data: 'StackTrace',
            Name: 'StackTrace',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@HostName',
            Data: 'HostName',
            Name: 'HostName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        }
    ];

    ColumnsWorkflowTable = [
        {
            Caption: '@@ExecutionTime',
            Data: 'ExecutionTime',
            Name: 'ExecutionTime',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.DateTime',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@UserName',
            Data: 'UserName',
            Name: 'UserName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Name',
            Data: 'Name',
            Name: 'Name',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Successfull',
            Data: 'Successfull',
            Name: 'Successfull',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.Boolean',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Log',
            Data: 'Log',
            Name: 'Log',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        }
    ];

    // #region ChartsSystemLogs
    PieChart: GoogleChartInterface = {
        chartType: 'PieChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Error count by Type',
            'height': 400        },
    };

    PieChartDayCount: GoogleChartInterface = {
        chartType: 'ColumnChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Error count by day', 'isStacked': true,
            'height': 400
        },
    };

    PieChartHourCount: GoogleChartInterface = {
        chartType: 'ColumnChart',
        dataTable: this.returnEmptyHourList(),
        //firstRowIsData: true,
        options: {
            'title': 'Error count by hour',
            'isStacked': true,
            'hAxis': {
                'title': 'time in hours',
                'height': 400             }
        },
    };

    PieChartErrorLevel: GoogleChartInterface = {
        chartType: 'PieChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Error count by level',
            'height': 400
        },
    };
    //#endregion

    // #region ChartsWorkflowLogs
    PieChartWorkflowType: GoogleChartInterface = {
        chartType: 'PieChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Workflow Count by Type',
            'height': 400
        },
    };

    PieChartWorkflowUser: GoogleChartInterface = {
        chartType: 'PieChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Workflow ran by user',
            'height': 400
        },
    };

    PieChartWorkflowSuccessful: GoogleChartInterface = {
        chartType: 'PieChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Workflow ran successful',
            'height': 400
        },
    };

    PieChartDayCountWorkflow: GoogleChartInterface = {
        chartType: 'ColumnChart',
        dataTable: [],
        //firstRowIsData: true,
        options: {
            'title': 'Error count by day', 'isStacked': true,
            'height': 400
        },
    };
    //#endregion
    Count;
    FilteredCount;
    LoadingSub;
    Rows;
    RowsWorkflowTable;
    startDate = moment().startOf('day').subtract(6, 'days').format();
    endDate = moment().endOf('day').format();
    SelectedDate = new Date();
    SelectedSingleDate = new Date();
    SelectedDateInvalid = false;
    SelectedDateWorkflowInvalid = false;
    

    Tabs = ["System", "Workflow"];

    SelectedDateWorkflows = new Date();

    LastValidSelectedDate = new  Date();
    LastValidSelectedWorkflowDate = new Date();
   // DataSource: LogsDataSource;

    ngOnInit(): void {
       
    }

   

    constructor(private Service: MonitoringService, private cdRef: ChangeDetectorRef, private service: SchedulerService, private logService: LogsService) {

    }
    ngAfterViewInit(): void {
        // this.DataSource = new LogsDataSource();
        //#region Set Filter Columns
        


        this.SelectedDate[0] = moment().startOf('day').subtract(6, 'days').toDate();
        this.SelectedDate[1] = moment().startOf('day').toDate();

        this.LastValidSelectedDate[0] = this.SelectedDate[0];
        this.LastValidSelectedDate[1] = this.SelectedDate[1];


        this.SelectedDateWorkflows[0] = moment().startOf('day').subtract(6, 'days').toDate();
        this.SelectedDateWorkflows[1] = moment().startOf('day').toDate();

        this.LastValidSelectedWorkflowDate[0] = this.SelectedDate[0];
        this.LastValidSelectedWorkflowDate[1] = this.SelectedDate[1];

        let requestOption = this.getTimeFilter(this.startDate, this.endDate, this.Tabs[0]);

        let dateStart = new Date(this.SelectedSingleDate.setHours(0, 0, 0, 0));
        let dateEnd = new Date(this.SelectedSingleDate.setHours(23, 59, 59, 999));
        let filter = this.getTimeFilter(dateStart, dateEnd, this.Tabs[0]);
        this.refreshSingleDayChart(filter);

        this.refreshTable(requestOption);
        this.refreshRangeTables(requestOption);      

       
        

        let filterWorkflow = this.getTimeFilter(this.SelectedDateWorkflows[0], this.SelectedDateWorkflows[1], "Workflow");

        this.refreshWorkflowTable(filterWorkflow);
        this.refreshWorkflowLogCharts(filterWorkflow);


        if (this.LoadingSub) {
            this.LoadingSub.unsubscribe();
            this.LoadingSub = null;
        }
        //this.LoadingSub = this.DataSource.Loading.subscribe((Loading) => {
        //    if (this.cdRef) {
        //        this.cdRef.detectChanges();
        //    }
        //})
        //this.DataSource.Initialize();

        //#endregion
    }
    

    FilterChanged(filter) {
        //this.DataSource.SetFilter(filter);
    }
    SortChanged(sort) {
        //this.DataSource.SetOrder(sort);
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Logs',
            ID: 'logs',
            Icon: 'bug_report',
            Index: 4,
            Parent: 'about',
            Security: {
                Name: 'evidanza.App.Shared.Security.AboutRight',
                Value: 2
            },
            Content: LogsSettings
        }
    }


    //#region Datetime Pickers 
    dateTimePickerOneDaySelectionChanged() {
        let filter = this.getTimeFilter(this.SelectedSingleDate.setHours(0, 0, 0, 0), this.SelectedSingleDate.setHours(23, 59, 59, 999), this.Tabs[0]);
        this.refreshSingleDayChart(filter);
    }

    dateTimePickerSelectionChanged() {

        let startDate = this.returnDateStartRangePicker(this.SelectedDate);
        let endDate = this.returnDateEndRangePicker(this.SelectedDate);
        let filter = this.getTimeFilter(startDate, endDate, this.Tabs[0]);
        let numberOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;

        if (numberOfDays <= 30) {
            this.LastValidSelectedDate[0] = startDate;
            this.LastValidSelectedDate[1] = endDate;
            this.refreshTable(filter);
            this.refreshRangeTables(filter);
            this.SelectedDateInvalid = false;
        } else {
            this.SelectedDateInvalid = true;
        }

        
    }

    dateTimePickerWorkflowsSelectionChanged() {

        let startDate = this.returnDateStartRangePicker(this.SelectedDateWorkflows);
        let endDate = this.returnDateEndRangePicker(this.SelectedDateWorkflows);
        let filter = this.getTimeFilter(startDate, endDate, this.Tabs[1]);
        let numberOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;

        if (numberOfDays <= 30) {
            this.LastValidSelectedWorkflowDate[0] = startDate;
            this.LastValidSelectedWorkflowDate[1] = endDate;
            this.refreshWorkflowTable(filter);
            this.refreshWorkflowLogCharts(filter);
            this.SelectedDateWorkflowInvalid = false;
        } else {
            this.SelectedDateWorkflowInvalid = true;        
        }
    }


    returnDateStartRangePicker(datetimepicker) {
        let dateStart = new Date(datetimepicker[0].setHours(0, 0, 0, 0));
        return dateStart;
    }

    returnDateEndRangePicker(datetimepicker) {
        let dateEnd = new Date(datetimepicker[1].setHours(23, 59, 59, 999));
        return dateEnd;
    }
    //#endregion

    //#region onClickChartEvents 


    onSelect(event: ChartSelectEvent, chart: GoogleChartInterface, tab) {
        if (event.column == null) {
            event.column = 0;
        }
        if (event.row == null) {
            event.row = 0;
        }

        let startDate;
        let endDate;

        if (tab == "System") {
            if (this.SelectedDateInvalid == false) {
                startDate = this.returnDateStartRangePicker(this.SelectedDate);
                endDate = this.returnDateEndRangePicker(this.SelectedDate);
            } else {
                startDate = this.returnDateStartRangePicker(this.LastValidSelectedDate);
                endDate = this.returnDateEndRangePicker(this.LastValidSelectedDate);
            }
            
            
        } else if (tab == "Workflow"){
            if (this.SelectedDateInvalid = false) {
                startDate = this.returnDateStartRangePicker(this.SelectedDateWorkflows);
                endDate = this.returnDateEndRangePicker(this.SelectedDateWorkflows);
            } else {
                startDate = this.returnDateStartRangePicker(this.LastValidSelectedWorkflowDate);
                endDate = this.returnDateEndRangePicker(this.LastValidSelectedWorkflowDate);
            }
        }
        
        let value;
        let column3 = new RequestFilter();
        if (chart.chartType == "PieChart") {
            column3.Name = chart.dataTable[0][event.column];
            column3.Operator = Comparer.Equal;
            value = chart.dataTable[event.row + 1][0];
        } else if (chart.chartType == "ColumnChart") {
            //let startDateString = chart.dataTable[event.row + 1][0];
            //let endDateBefore = moment(startDateString).toDate();
            //startDate = new Date(moment(startDateString).toDate());
            //endDate = new Date(endDateBefore.setHours(23, 59, 59, 9999));
            const tableDate: Date = chart.dataTable[event.row + 1][0];
            startDate = new Date(tableDate.getFullYear(), tableDate.getMonth(), tableDate.getDate());
            endDate = new Date(tableDate.getFullYear(), tableDate.getMonth(), tableDate.getDate(), 23, 59, 59, 999);

            if (tab == "System") {
                column3.Name = "MessageLevel";
            } else if (tab == "Workflow") {
                column3.Name = "Successfull";
            }
            column3.Operator = Comparer.Equal;
            value = chart.dataTable[0][event.column];
        }
        if (value == "None" || value == null) {
            value = 0;
        } else if (value == "Critical") {
            value = 1;
        } else if (value == "Error") {
            value = 2;
        } else if (value == "Warning") {
            value = 3;
        } else if (value == "Information") {
            value = 4;
        } else if (value == "Debug") {
            value = 5;
        } else if (value == "Trace") {
            value = 6;
        }
        column3.Value = value;        

        let filter = this.getTimeFilter(startDate, endDate, tab);

        filter.Filters.push(column3);

        let numberOfDays = moment(endDate).diff(moment(startDate), 'days') + 1;

        if (numberOfDays <= 30) {
            if (tab == "System") {

                this.refreshTable(filter);
            } else if (tab == "Workflow") {
                this.refreshWorkflowTable(filter);
            }
        }
        
    }

    onSelectHour(event: ChartSelectEvent, chart: GoogleChartInterface) {
        if (event.column == null) {
            event.column = 0;
        }
        if (event.row == null) {
            event.row = 0;
        }

        let value;
        let column3 = new RequestFilter();
        if (chart.chartType == "ColumnChart") {

            column3.Name = "MessageLevel";
            column3.Operator = Comparer.Equal;
            value = chart.dataTable[0][event.column];
        }
        if (value == "None") {
            value = 0;
        } else if (value == "Critical") {
            value = 1;
        } else if (value == "Error") {
            value = 2;
        } else if (value == "Warning") {
            value = 3;
        } else if (value == "Information") {
            value = 4;
        } else if (value == "Debug") {
            value = 5;
        } else if (value == "Trace") {
            value = 6;
        }
        column3.Value = value;
        let filter = this.getTimeFilter(this.SelectedSingleDate.setHours(chart.dataTable[event.row + 1][0], 0, 0, 0), this.SelectedSingleDate.setHours(chart.dataTable[event.row + 1][0], 59, 59, 999), this.Tabs[0]);
        filter.Filters.push(column3);
        this.refreshTable(filter);
    }
    //#endregion

    //#region refresh Tables and charts

    refreshTable(filter) {

        this.logService.Get(filter).subscribe((ResultGet) => {
            this.Rows = ResultGet;
            if (this.cdRef) {
                this.cdRef.detectChanges();
            }
        })
    }

    refreshWorkflowTable(filter) {
        this.service.GetTriggerLogs(filter).subscribe((result) => {
            this.RowsWorkflowTable = result;
            if (this.cdRef) {
                this.cdRef.detectChanges();
            }
        })

    }


    refreshWorkflowLogCharts(filter) {

        this.refreshPieChart(filter, ['Name', 'Count'], this.PieChartWorkflowType, monitoringChartFunctions.CountWorkflowByType);
        this.refreshPieChart(filter, ['UserName', 'Count'], this.PieChartWorkflowUser, monitoringChartFunctions.CountWorkflowByUser);
        this.refreshPieChart(filter, ['Successfull', 'Count'], this.PieChartWorkflowSuccessful, monitoringChartFunctions.CountWorkflowSuccessful);

        this.Service.CountByDayWorkflow(filter).subscribe((result) => {
            const data = this.returnLastThirtyDaysList(this.SelectedDateWorkflows[0], this.SelectedDateWorkflows[1], this.Tabs[1], result);
            this.PieChartDayCountWorkflow.dataTable = data.Data;
            let options = {
                'title': 'Error count by day',
                'isStacked': true,
                'height': 400,
                'hAxis': {
                    'ticks': data.Ticks
                }
            };
            this.PieChartDayCountWorkflow.options = options,
            this.cdRef.detectChanges();
            if (this.PieChartDayCountWorkflow.component && this.PieChartDayCountWorkflow.component.getDataTable()) {
                this.PieChartDayCountWorkflow.component.draw();
            }
        });
    }


    refreshRangeTables(filter) {

        this.refreshPieChart(filter, ['MessageLevel', 'Count'], this.PieChartErrorLevel, monitoringChartFunctions.CountByLevel);
        this.refreshPieChart(filter, ['TypeName', 'Count'], this.PieChart, monitoringChartFunctions.CountByType);

        this.Service.CountByDay(filter).subscribe((result) => {
            const data = this.returnLastThirtyDaysList(this.SelectedDate[0], this.SelectedDate[1], this.Tabs[0], result);
            this.PieChartDayCount.dataTable = data.Data;
            let options = {
                'title': 'Error count by day',
                'isStacked': true,
                'height': 400,
                'hAxis': {
                    'ticks': data.Ticks
                }            
            };
            this.PieChartDayCount.options = options;
            this.cdRef.detectChanges();
            if (this.PieChartDayCount.component.getDataTable()) {
                this.PieChartDayCount.component.draw();
            }
        });
    }



    refreshSingleDayChart(filter) {
        this.Service.CountByHour(filter).subscribe((result) => {
            let list = [['MessageLevel', 'Error', 'Information', 'Critical', 'Debug', 'None', 'Trace', 'Warning']];
            if (result != null) {
                result.forEach((item) => {
                    list.push([item.Key, item.Value[0], item.Value[1], item.Value[2], item.Value[3], item.Value[4], item.Value[5], item.Value[6], item.Value[7]]);
                })
            }

            this.PieChartHourCount.dataTable = this.returnEmptyHourList();
            for (let i = 1; i < this.PieChartHourCount.dataTable.length; i++) {
                for (let n = 0; n < list.length; n++) {
                    if (this.PieChartHourCount.dataTable[i][0] == list[n][0]) {
                        this.PieChartHourCount.dataTable[i][1] = list[n][1];
                        this.PieChartHourCount.dataTable[i][2] = list[n][2];
                        this.PieChartHourCount.dataTable[i][3] = list[n][3];
                        this.PieChartHourCount.dataTable[i][4] = list[n][4];
                        this.PieChartHourCount.dataTable[i][5] = list[n][5];
                        this.PieChartHourCount.dataTable[i][6] = list[n][6];
                        this.PieChartHourCount.dataTable[i][7] = list[n][7];
                        break;
                    }
                }
            }
            this.cdRef.detectChanges();
            if (this.PieChartHourCount.component.getDataTable()) {
                this.PieChartHourCount.component.draw();
            }
        });
    }


    refreshPieChart(filter, header: any[], chart, chartFunction: monitoringChartFunctions) {
        this.Service.ExecuteChartRefresh(filter, chartFunction).subscribe((result) => {
            let list = [header];
            if (result != null) {
                result.forEach((item) => {
                    list.push([item.Key, item.Value]);
                })
            }
            chart.dataTable = list;
            this.cdRef.detectChanges();
            if (chart.component && chart.component.getDataTable()) {
                chart.component.draw();
            }
        });
    }

    //#endregion    

    returnEmptyHourList() {
        let cleanedHourList: any[] = [['MessageLevel', 'Error', 'Information', 'Critical', 'Debug', 'None', 'Trace', 'Warning']];
        cleanedHourList.push(...Array.from(Array(24), (v, index) => [index, 0, 0, 0, 0, 0, 0, 0]));
        return cleanedHourList;
    }

    returnLastThirtyDaysList(date1: Date, date2: Date, tab: String, result) {

        let newEndDate = new Date(date2.setHours(0, 0, 0, 0));

        let diffInDays = Math.abs(newEndDate.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);
        let cleanedDayList: any[];
        if (tab == "System") {
            cleanedDayList = [[{ label: 'MessageLevel', type: 'date' }, 'Error', 'Information', 'Critical', 'Debug', 'None', 'Trace', 'Warning']];
        } else if (tab == "Workflow") {
            cleanedDayList = [['ExecutionTime', 'true', 'false']];
        }
        const ticks = [];
        for (let i = diffInDays; i >= 0; i--) {
            const mom = moment(date2).startOf('day').subtract(i, 'days');
            const key = mom.format('MM/DD/YYYY');
            const existing = result.find(x => x.Key === key);
            ticks.push({ v: mom.toDate(), f: mom.format('LL') });
            if (existing) {
                cleanedDayList.push([mom.toDate(), ...existing.Value]);
            } else {
                let newDay;
                if (tab == "System") {
                    newDay = [mom.toDate(), 0, 0, 0, 0, 0, 0, 0];
                } else if (tab == "Workflow") {
                    newDay = [mom.toDate(), 0, 0];
                }
                cleanedDayList.push(newDay);
            }
        }        
        return {
            Ticks: ticks,
            Data: cleanedDayList
        };
    }    
    
    getTimeFilter(dateStart, dateEnd, tab: String) {


        let requestColumns = [];
        this.Columns.forEach((col) => {
            let column = new RequestColumn();
            column.Name = col.Name;
            column.IsCommaSeparated = false;
            requestColumns.push(column);
        });

        let requestOption = new RequestOptions();

        let column = new RequestFilter();
        if (tab == "System") {
            column.Name = "MessageTime";
        } else if (tab == "Workflow") {
            column.Name = "ExecutionTime";
        }        
        column.Operator = Comparer.GreaterEqual;
        column.Value = moment(dateStart).utc(true).toJSON();

        let column2 = new RequestFilter();
        if (tab == "System") {
            column2.Name = "MessageTime";
        } else if (tab == "Workflow") {
            column2.Name = "ExecutionTime";
        }
        column2.Operator = Comparer.SmallerEqual;
        column2.Value = moment(dateEnd).utc(true).toJSON();

        requestOption.Columns = requestColumns;
        requestOption.Filters.push(column);
        requestOption.Filters.push(column2);
        requestOption.StartRow = null;
        requestOption.EndRow = null;

        return requestOption;
    }

    ShowDetailInfo(event) {
        BaseDialog.ShowDialog({
            ContentType: LogsdialogComponent,
            Title: '@@Details',
            Buttons: DialogButton.Cancel,
            InitArgs: event.data = event,
            Width: 600
        });
    }

    ShowDetailInfoSystem(event) {
        BaseDialog.ShowDialog({
            ContentType: LogsdialogsystemComponent,
            Title: '@@Details',
            Buttons: DialogButton.Cancel,
            InitArgs: event,
            Width: 600
        });
    }

}

//export class LogsDataSource extends DataSource<any | undefined>{
//    private pageSize = 300;
//    public FilteredCount=0;
//    private Count;


//    private Filter: RequestOptions = new RequestOptions();

//    private Columns;
//    private DataService: LogsService;

//    public cachedData = Array.from<any>({ length: this.FilteredCount });
//    private fetchedPages = new Set<number>();
//    private dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedData);
//    private subscription = new Subscription();
//    public Initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//    public Loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

//    connect(collectionViewer: CollectionViewer): Observable<(any | undefined)[]> {
//        this.subscription = new Subscription();
//        this.subscription.add(collectionViewer.viewChange.subscribe(range => {
//            const startPage = this.getPageForIndex(range.start);
//            const endPage = this.getPageForIndex(range.end - 1);
//            for (let i = startPage; i <= endPage; i++) {
//                this.fetchPage(i);
//            }
//        }));
//        this.dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedData);
//        return this.dataStream;
//    }

//    disconnect(): void {
//        this.subscription.unsubscribe();
//    }

//    constructor() {
//        super();

//        const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
//        this.DataService = new LogsService(http);
//    }

//    public SetRequestColumns(Columns: RequestColumn[]) {
//        this.Filter.Columns = Columns;
//        this.Initialize();
//    }
//    public SetFilter(Filter: RequestFilter[]) {
//        this.Filter.Filters = Filter;
//        this.Initialize();
//    }
//    public SetOrder(Sort: RequestSort[]) {
//        this.Filter.Sort = Sort;
//        this.Initialize();
//    }
//    public Initialize() {
//        this.Refresh();
//    }

//    public Refresh() {
//        //#region Set Filter
//        this.fetchedPages = new Set<number>();
//        this.Initialized.next(false);
//        this.DataService.Count(this.Filter).subscribe((result) => {
//            this.FilteredCount = result;
//            this.Count = result;
//            this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
//            this.Initialized.next(true);
//            this.fetchPage(0);
//        });       
//    }

//    private getPageForIndex(index: number): number {
//        return Math.floor(index / this.pageSize);
//    }
//    private fetchPage(page: number) {
//        if (this.fetchedPages.has(page)) {
//            return;
//        }
//        this.Loading.next(true);
//        this.fetchedPages.add(page);
//        this.Filter.StartRow = page * this.pageSize;
//        this.Filter.EndRow = this.Filter.StartRow + this.pageSize;
//        this.DataService.Get(this.Filter).subscribe((result) => {
//            //let rows = this.FillResult(result);
//            //this.cachedData = rows;
//            let rows = [];
//            result.forEach((item) => {
//                let row = {
//                    edit: false,
//                    selected: false,
//                    data: item
//                }
//                rows.push(row);
//            })
//            this.cachedData.splice(page * this.pageSize, this.pageSize, ...rows);
//            this.dataStream.next(this.cachedData);
//            this.Loading.next(false);
//        });
//    }

//    public fetch(start: number, end: number) {
//        this.Loading.next(true);
//        this.Filter.StartRow = start;
//        this.Filter.EndRow = end;
//        this.DataService.Get(this.Filter).subscribe((result) => {
//            let rows = [];
//            let index = start;
//            result.forEach((item) => {
//                let row = new Row();
//                row.data = item;                
//                row.index = index;
//                rows.push(row);
//                index++;
//            })
//            this.cachedData.splice(start, end - start, ...rows);
//            this.cachedData = [...this.cachedData];
//            this.dataStream.next(this.cachedData);
//            this.Loading.next(false);            
//        });
//    }   
    

//    FillResult(result) {
//        let rows = [];
//        result.forEach((row) => {
//            let insert = {};
//            this.Columns.forEach((column) => {
//                if (!row[column.name]) {
//                    insert[column.data] = "";
//                } else {
//                    insert[column.data] = row[column.name];
//                }
//            });
//            rows.push(insert);
//        });
//        return rows;
//    }

//}