import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component,
    EventEmitter, Inject, Input, OnInit, Output,
} from '@angular/core';
import { LayoutService } from '../../../../services/layout.service';
import { IBaseComponent } from '../../base.component';

@Component({
    selector: 'basic-datatable',
    templateUrl: './basic-datatable.control.html',
    styleUrls: ['./basic-datatable.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicDataTableControl  extends IBaseComponent {
    ControlInitialized() {
        throw new Error('Method not implemented.');
    }
    ViewInitialized = false;
    @Input() Columns;
    @Input() DataRows;
    @Output() OnRowDoubleClicked = new EventEmitter<any>();
    constructor(private cdref: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
    }
    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        super.ngAfterViewInit();
        this.cdRef.detectChanges();
    }

    RowDoubleClicked(event) {
        this.triggerEvent('DoubleClick', { RowIndex: event.index, RowData: event.data });
        this.OnRowDoubleClicked.emit(event);
    }
}