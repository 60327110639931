import { Component } from '@angular/core';

@Component({
    selector: 'container-field-edit-dialog',
    templateUrl: './container.field.edit.dialog.html'
})
export class ContainerFieldEditDialog {
    public static AllTypes = [{ Caption: 'Bool', Value: 'System.Boolean' }, { Caption: 'ByteArray', Value: 'System.Byte[]' },
        { Caption: 'Char', Value: 'System.Char' }, { Caption: 'Date', Value: 'System.DateTime' },
        { Caption: 'Double', Value: 'System.Double' }, { Caption: 'Guid', Value: 'System.Guid' },
        { Caption: 'Int', Value: 'System.Int32' }, { Caption: 'Long', Value: 'System.Int64' },
        { Caption: 'Short', Value: 'System.Int16' }, { Caption: 'Single', Value: 'System.Single' },
        { Caption: 'String', Value: 'System.String' }, { Caption: 'Timespan', Value: 'System.TimeSpan' },
        { Caption: 'Unsigned Short', Value: 'System.UInt16' }, { Caption: 'Unsigned Int', Value: 'System.UInt32' }];

    Field;
    Settings = new ContainerFieldPropertySettings();
    AllTypeList = [];
    TypeName = '';

    constructor() {
        this.AllTypeList.push(...ContainerFieldEditDialog.AllTypes);
    }

    Initialize(args) {
        if (args && args.Field) {
            if (args.Settings) {
                this.Settings = args.Settings;
            }
            if (args.Field) {
                const json = JSON.stringify(args.Field);
                this.Field = JSON.parse(json);
                if (this.Settings.DataTyp.Editable === false) {
                    this.TypeName = this.Field.DataTyp;
                    ContainerFieldEditDialog.AllTypes.some(x => {
                        if (this.Field.DataTyp === x.Value) {
                            this.TypeName = x.Caption;
                            return true;
                        }
                        return false;
                    });
                }
            }
        }
    }

    GetDialogResult() {
        return this.Field;
    }
}

export class ContainerFieldInitArgs {
    Field;
    Settings = new ContainerFieldPropertySettings();
}

export class ContainerFieldPropertySettings {
    Name = new ContainerFieldProperty();
    Caption = new ContainerFieldProperty();
    DataTyp = new ContainerFieldProperty();
    IsPrimary = new ContainerFieldProperty();
    Nullable = new ContainerFieldProperty();
    FieldLength = new ContainerFieldProperty();
    Expression = new ContainerFieldProperty();
    NumericPrecision = new ContainerFieldProperty();
}

export class ContainerFieldProperty {
    Editable = false;
    Visible = true;
}
