import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Comparer } from '../../models/enums/comparer.enum';

@Component({
  selector: 'evi-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.css']
})
export class OperatorComponent {
  //#region Column
  ColumnValue;

  @Input()
  get Column() {
    return this.ColumnValue;
  }
  set Column(val) {
    this.ColumnValue = val;
    this.SetValues();
    this.ColumnChange.emit(this.ColumnValue);
  }
  @Output() ColumnChange = new EventEmitter<any>();
  //#endregion

  //#region Operator
  OperatorValue;

  @Input()
  get Operator() {
    return this.OperatorValue;
  }
  set Operator(val) {
    this.OperatorValue = val;
    this.SetValues();
    this.OperatorChange.emit(this.OperatorValue);
  }
  @Output() OperatorChange = new EventEmitter<any>();
  //#endregion

  SetValues() {
    if (this.ColumnValue && this.ColumnValue.Type) {
      switch (this.ColumnValue.Type) {
        case 'System.String':
          this.OperatorValues = [
            { Name: '=', Type: Comparer.Equal },
            { Name: '!=', Type: Comparer.NotEqual },
            { Name: Comparer[Comparer.Like], Type: Comparer.Like }
          ]
          break;
        case 'System.Int32':
        case 'System.Int64':
        case 'System.Double':
          this.OperatorValues = [
            { Name: '=', Type: Comparer.Equal },
            { Name: '!=', Type: Comparer.NotEqual },
            { Name: '>', Type: Comparer.Greater },
            { Name: '>=', Type: Comparer.GreaterEqual },
            { Name: '<', Type: Comparer.Smaller },
            { Name: '<=', Type: Comparer.SmallerEqual }
          ]
          break;
        case 'System.Boolean':
          this.OperatorValues = [
            { Name: '=', Type: Comparer.Equal },
            { Name: '!=', Type: Comparer.NotEqual }
          ]
          break;
        case 'System.DateTime':
          this.OperatorValues = [
            { Name: '=', Type: Comparer.Equal },
            { Name: '!=', Type: Comparer.NotEqual },
            { Name: '>', Type: Comparer.Greater },
            { Name: '>=', Type: Comparer.GreaterEqual },
            { Name: '<', Type: Comparer.Smaller },
            { Name: '<=', Type: Comparer.SmallerEqual }
          ]
          break;
        default:
          this.OperatorValues = [
            { Name: '=', Type: Comparer.Equal },
            { Name: '!=', Type: Comparer.NotEqual }
          ]
          break;
      }
      if (this.Operator == null) {
        this.OperatorValue = Comparer.Equal;
      }
    }
  }

  OperatorValues=[];
}
