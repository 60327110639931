import { IFormula } from './iformula';
import { Addition } from './addition';
import { Subtraction } from './subtraction';
import { Multiplication } from './multiplication';
import { Division } from './division';
import { Greater } from './greater';
import { Smaller } from './smaller';
import { Equal } from './equal';
import { GreaterEqual } from './greaterequal';
import { SmallerEqual } from './smallerequal';
import { NotEqual } from './notequal';
import { And } from './and';
import { Or } from './or';
import { AbsFormula } from './absformula';
import { ActualDate, TodayFormula } from './actualdate';
import { Avg, AvgNonEmpty, AvgNonZero } from './avg';
import { DateAdd } from './dateadd';
import { DayFormula } from './dayformula';
import { DaysDiffFormula } from './daysdiffformula';
import { IfThenElse } from './ifthenelse';
import { IndexOf } from './indexof';
import { LTrim } from './ltrim';
import { Max } from './max';
import { Min } from './min';
import { MonthFormula } from './monthformula';
import { RoundFormula } from './roundformula';
import { RTrim } from './rtrim';
import { StrLength } from './strlength';
import { SubString } from './substring';
import { Sum } from './sum';
import { ToDoubleFormula, ToLongFormula, ToStringFormula } from './todoubleformula';
import { ToLower } from './tolower';
import { ToUpper } from './toupper';
import { WeekFormula } from './weekformula';
import { YearFormula } from './yearformula';
import { IsNull } from './isnull';
import { Pow } from './pow';
import { SquareRoot } from './squareroot';
import { ToJSON } from './tojson';
import { ToObject } from './toobject';
import { GetProperty } from './getproperty';
import { NewGuid } from './newguid';
import { TranslateFormula } from './translateformula';
import { GenerateNull } from './generatenull';

export const FormulaRegistry = new Map<string, IFormula>([
    [Addition.StaticID, new Addition()],
    [Subtraction.StaticID, new Subtraction()],
    [Multiplication.StaticID, new Multiplication()],
    [Division.StaticID, new Division()],
    [Greater.StaticID, new Greater()],
    [Smaller.StaticID, new Smaller()],
    [Equal.StaticID, new Equal()],
    [GreaterEqual.StaticID, new GreaterEqual()],
    [SmallerEqual.StaticID, new SmallerEqual()],
    [NotEqual.StaticID, new NotEqual()],
    [And.StaticID, new And()],
    [Or.StaticID, new Or()],
    [AbsFormula.StaticID, new AbsFormula()],
    [ActualDate.StaticID, new ActualDate()],
    [Avg.StaticID, new Avg()],
    [AvgNonEmpty.StaticID, new AvgNonEmpty()],
    [AvgNonZero.StaticID, new AvgNonZero()],
    [DateAdd.StaticID, new DateAdd()],
    [DayFormula.StaticID, new DayFormula()],
    [DaysDiffFormula.StaticID, new DaysDiffFormula()],
    [IfThenElse.StaticID, new IfThenElse()],
    [IndexOf.StaticID, new IndexOf()],
    [LTrim.StaticID, new LTrim()],
    [Max.StaticID, new Max()],
    [Min.StaticID, new Min()],
    [MonthFormula.StaticID, new MonthFormula()],
    [RoundFormula.StaticID, new RoundFormula()],
    [RTrim.StaticID, new RTrim()],
    [StrLength.StaticID, new StrLength()],
    [SubString.StaticID, new SubString()],
    [Sum.StaticID, new Sum()],
    [TodayFormula.StaticID, new TodayFormula()],
    [ToDoubleFormula.StaticID, new ToDoubleFormula()],
    [ToLongFormula.StaticID, new ToLongFormula()],
    [ToStringFormula.StaticID, new ToStringFormula()],
    [ToLower.StaticID, new ToLower()],
    [ToUpper.StaticID, new ToUpper()],
    [WeekFormula.StaticID, new WeekFormula()],
    [YearFormula.StaticID, new YearFormula()],
    [IsNull.StaticID, new IsNull()],
    [Pow.StaticID, new Pow()],
    [SquareRoot.StaticID, new SquareRoot()],
    [ToJSON.StaticID, new ToJSON()],
    [ToObject.StaticID, new ToObject()],
    [GetProperty.StaticID, new GetProperty()],
    [NewGuid.StaticID, new NewGuid()],
    [TranslateFormula.StaticID, new TranslateFormula()],
    [GenerateNull.StaticID, new GenerateNull()]
]);
