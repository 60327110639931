import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { KPIService } from '../../../../services/kpi.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-fill-kpi-settings-item',
    templateUrl: 'fill.kpi.settings.html'
})
export class FillKPISettings extends FormulaWorkflowDialogContent {
    Data = new FillKPISettingsData();
    KPINodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'fillKPIModule';
        reg.Caption = '@@KPIs befuellen';
        reg.GroupID = '-1';
        reg.Index = 10;
        reg.SettingsControl = FillKPISettings;
        reg.SettingsTypeHelper = new FillKPISettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: KPIService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetKPIStructure().subscribe(result => {
            const kpiDict = new Map();
            if (result && result.Categories) {
                let index = 0;
                const kpiNodes = [];
                result.Categories.forEach(cat => {
                    const btn = new ABaseTreeNode(index++);
                    btn.Caption = cat.Caption;
                    btn.Selectable = false;
                    btn.HasChildren = true;
                    const children = [];
                    cat.KPIs.forEach(kpi => {
                        const kNode = new KPINode(index++);
                        kNode.Caption = kpi.Caption;
                        kNode.SID = kpi.SID;
                        kpiDict.set(kpi.SID, kpi.Caption);
                        children.push(kNode);
                    });
                    btn.Children = children;
                    kpiNodes.push(btn);
                });
                this.KPINodes = kpiNodes;
            }
            this.Data.KPIs.forEach(x => {
                const caption = kpiDict.get(x.ID);
                if (caption) {
                    x['Caption'] = caption;
                } else {
                    x.ID = null;
                    x['Caption'] = '';
                }
            });
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(FillKPISettingsData, json);
        }
    }

    getResult(): any {
        const kpis = [];
        this.Data.KPIs.forEach(kpi => {
            if (kpi.ID && (kpi.UseActualParam || kpi.Formula)) {
                delete kpi['Caption'];
                kpis.push(kpi);
            }
        });
        this.Data.KPIs = kpis;
        return this.Data;
    }

    onKPISelected(kpi, ev) {
        if (kpi && ev) {
            kpi.ID = ev.SID;
            kpi.Caption = ev.Caption;
        }
    }

    addKPI() {
        if (this.Data) {
            const newItem = new KPIItem();
            newItem['Caption'] = '';
            if (this.Data.KPIs) {
                this.Data.KPIs.push(newItem);
            } else {
                this.Data.KPIs = [newItem];
            }
        }
    }

    removeKPI(i) {
        if (this.Data && this.Data.KPIs) {
            this.Data.KPIs.splice(i, 1);
        }
    }
}

export class KPINode extends ABaseTreeNode {
    SID;
}

export class KPIItem {
    ID: string;
    Formula: string;
    UseActualParam = false;
}

// @dynamic
export class FillKPISettingsData extends AServiceWorkflowData {
    @Type(() => KPIItem)
    KPIs: KPIItem[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.KPIOperations.FillKPI.FillKPISettingsData';
    }
}

export class FillKPISettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new FillKPISettingsData();
    }
}
