import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { UserSettingsService } from '../../../services/usersettings.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-profile-management',
    templateUrl: './profilemanagement.control.html',
    styleUrls: ['./profilemanagement.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileManagementControl extends IBaseComponent {

    static Type: any = 'profilemanagement';
    static Default = {
        Type: 'profilemanagement',
        Layout: {
            _Editable: true,
            Width: { Type: 0, Value:400},
            Height: {Type:0,Value:200}
        }
    };    

    constructor(private usersettingsService: UserSettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('valuechanged');
    }

    ProfileVariables;
    LayoutVariables;

    SelectedProfile;
    NewProfile;
    Profiles = [];
    ControlInitialized() {
        this.LoadVariables();
        this.LoadProfiles();
    }
    LoadVariables() {
        if (this.Layout && this.Layout.ProfileVariables) {
            this.ProfileVariables = this.Layout.ProfileVariables;
        }
        if (this.Layout && this.Layout.Variables) {
            this.LayoutVariables = this.Layout.Variables;
        }
        this.cdRef.detectChanges();
    }

    LoadProfiles() {
        if (this.LayoutElement.ControlStyling) {
            this.Profiles = this.LayoutElement.Profiles;
            this.cdRef.detectChanges();
            return;
        }
        this.usersettingsService.ReadProfileByLayout(this.Layout._Id).subscribe((profiles) => {
            this.Profiles = profiles;
            this.cdRef.detectChanges();
        });
    }

    Add() {
        this.NewProfile = {
            Name: 'NewProfile',
            LayoutID: this.Layout._Id
        }
    }
    Save() {
        if (this.NewProfile) {
            this.usersettingsService.CreateProfile(this.NewProfile).subscribe(() => {
                this.SelectedProfile = this.NewProfile;
                this.NewProfile = null;

                this.SaveVariables();
                this.LoadProfiles();
            })
        } else {
            this.SaveVariables();
        }
    }
    Delete() {
        if (this.SelectedProfile) {
            this.usersettingsService.RemoveByLayoutProfile(this.Layout._Id, this.SelectedProfile.Name).subscribe(() => {
                this.usersettingsService.DeleteProfile(this.SelectedProfile.ID).subscribe(() => {
                    this.SelectedProfile = null;
                    this.LoadProfiles();
                });
            })
        }
    }

    SaveVariables() {
        this.LoadVariables();
        this.usersettingsService.SaveValues(this.ProfileVariables, this.Layout._Id, this.SelectedProfile.Name);
    }
    ProfileChanged() {
        if (!this.LayoutElement.ControlStyling) {    
            this.usersettingsService.ChangeProfile(this.Layout._Id, this.SelectedProfile.Name).then(() => {
                this.cdRef.detectChanges();
                this.triggerEvent('valuechanged');
            });
        }
    }
}
