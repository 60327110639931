import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'crate-panel',
    templateUrl: './crate.panel.html',
    styleUrls: ['./crate.panel.css']
})
export class CratePanel extends BaseDataSourcePanel {
    static GetInfo(): DBPanelInfo {
        return {
            ControlType: CratePanel,
            DBInfoType: CrateDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = CrateDBInfo;
    }
}

export class CrateDBInfo extends ADBInfo {
    Server = null;
    Database = null;
    Username = null;
    Port = 4200;
    Password = null;
    SSPI = false;
    Timeout = 30;

    getTypeName(): string {
        return 'evidanza.Database.Crate.CrateDBInfo';
    }
}
