import { WorkflowModuleSettingsHelper, WorkflowExitInfo, WorkflowRegistry, ObsoleteInfo } from '../../../services/workflow.service';
import { WorkflowModuleExecuter, WorkflowStatus, AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { DefineDateSettings } from '../definedate/define.date.settings';

export class GetCurrentDateSettingsData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GetCurrentDate.GetCurrentDateSettingsData';
    }
}

export class GetCurrentDateModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        status.ActualParameter = new Date();
        return 0;
    }
}

export class GetCurrentDateSettingsHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getCurrentDateModule';
        reg.Caption = '@@Aktuelles Datum auslesen';
        reg.SettingsTypeHelper = new GetCurrentDateSettingsHelper();
        reg.Executer = GetCurrentDateModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = DefineDateSettings.ModuleID;
        return reg;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetCurrentDateSettingsData();
    }
}
