import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { TaskHelper } from '../task.helper';
import { AConditionalFormatingTask } from './aconditional.formatting.task';
import { LevelFormattingTaskExecuter } from './level.formatting.task';

export class ConditionalLevelTaskExecuter extends AConditionalFormatingTask {
    static TaskID = 'conditionallevelformattingtask';

    LevelUniqueID = -1;

    InitConditionalTaskSettings(settings: any) {
        if (settings && typeof settings.LevelUniqueID === 'number') {
            this.LevelUniqueID = settings.LevelUniqueID;
        }
    }

    Execute() {
        if (this.ConditionFormatingStyles && this.ConditionFormatingStyles.length > 0 && this.Merger) {
            LevelFormattingTaskExecuter.IterateLevelNodes(this.LevelUniqueID, this.DataDescription, this.Result, (x, y, m) => {
                this.ConditionFormatingStyles.forEach(cfs => {
                    const isFullFilled = TaskHelper.CheckConditionObject(cfs.Condition, this.Result, x, y, m);
                    if (isFullFilled) {
                        if (cfs.FormatHeaders) {
                            if (x.UniqueID === this.LevelUniqueID) {
                                x.StyleID = this.Merger.MergeStyle(x.StyleID, cfs.Style);
                            } else {
                                y.StyleID = this.Merger.MergeStyle(y.StyleID, cfs.Style);
                            }
                        }
                        const requiresCell = TaskHelper.RequiresCell(cfs.Style);
                        if (requiresCell) {
                            const cell = MultiResultHelper.GetCellOrNew(this.Result, x, y, m);
                            cell.StyleID = this.Merger.MergeStyle(cell.StyleID, cfs.Style);
                        } else {
                            const cell = MultiResultHelper.GetCellOrNull(this.Result, x, y, m);
                            if (cell) {
                                cell.StyleID = this.Merger.MergeStyle(cell.StyleID, cfs.Style);
                            }
                        }
                    }
                });
            }, null);
        }
    }
}
