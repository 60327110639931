import { ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { IBaseComponent } from '../../components/controls/base.component';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { WF_REGISTRY, WorkflowModuleSettingsHelper } from '../../services/workflow.service';
import { WorkflowEditOptions } from '../../workflow/workflow.control';
import { WorkflowDialog, WorkflowDialogContent } from '../../workflow/workflow.dialog';

@Component({
    selector: 'workflow-wizard-edit-item',
    templateUrl: './workflow.wizard.edit.item.html'
})
export class WorkflowWizardEditItem extends IBaseComponent {
    static Type: any = 'workflow-edit-control';
    static Default = {
        Type: 'workflow-edit-control'
    };
    static EditItemInitialized: Subject<any> = new Subject();

    EditStyle;
    DialogContent: WorkflowDialogContent;
    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    constructor(private cfr: ComponentFactoryResolver, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ControlInitialized() {
        const page = NavigationService.SelectedPage.getValue();
        if (page === 'template') {
            this.EditStyle = {
                opacity: 0.5,
                'pointer-events': 'None'
            };
            WorkflowWizardEditItem.EditItemInitialized.next(this);
        }
    }

    FillWithData(wfData) {
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (wfData && this.LayoutElementValue && this.LayoutElementValue.ModuleInfo) {
                const desc = WF_REGISTRY.get(this.LayoutElementValue.ModuleInfo.Module);
                if (desc && desc.SettingsControl) {
                    const factory = this.cfr.resolveComponentFactory(desc.SettingsControl);
                    const component = factory.create(this.viewContainerRef.parentInjector);
                    const dc = <WorkflowDialogContent>component.instance;
                    dc.WFData = wfData;
                    dc.WFEditOptions = new WorkflowEditOptions();
                    dc.WFEditOptions.IsService = true;
                    dc.ModuleID = this.LayoutElementValue.ModuleInfo.ID;
                    const modList = [];
                    if (dc.UseActualState) {
                        const params = wfData['Parameters'];
                        if (params && params.length > 0) {
                            const list = [];
                            params.forEach(x => {
                                list.push(x.Name);
                            });
                            WorkflowModuleSettingsHelper.AddStatusKeysToState(dc.ActualState, list);
                        }
                        const idList = [];
                        let prevModule = WorkflowDialog.getPreviousModule(dc.ModuleID, wfData.Connectors, wfData.Modules, idList);
                        while (prevModule != null) {
                            modList.splice(0, 0, prevModule.Module);
                            prevModule = WorkflowDialog.getPreviousModule(prevModule.Module.ID, wfData.Connectors, wfData.Modules, idList);
                        }
                    }
                    this.FillState(dc, modList, wfData, component);
                }
            }
        }
    }

    private FillState(dc: WorkflowDialogContent, modList, wfData, component) {
        if (modList && modList.length > 0) {
            const actMod = modList.splice(0, 1)[0];
            const actDesc = WF_REGISTRY.get(actMod.Module);
            if (actDesc && actDesc.SettingsTypeHelper) {
                actDesc.SettingsTypeHelper.fillActualState(actMod, dc.ActualState, wfData).then(() => {
                    this.FillState(dc, modList, wfData, component);
                });
            } else {
                this.FillState(dc, modList, wfData, component);
            }
        } else {
            const data = wfData.Modules.find(x => x.ID === dc.ModuleID);
            let settings;
            if (typeof data.Settings === 'object') {
                settings = JSON.parse(JSON.stringify(data.Settings));
            } else {
                settings = WorkflowSaveObject.DeserializeModuleSettings(data).Settings;
            }
            dc.initialize(settings);
            this.viewContainerRef.insert(component.hostView);
            this.DialogContent = dc;
            this.cdRef.detectChanges();
        }
    }

    GetResult() {
        const retVal = {
            Settings: null,
            IsCorrect: true
        };
        if (this.DialogContent) {
            const check = this.DialogContent.checkData();
            if (check) {
                retVal.IsCorrect = check.IsCorrect;
                if (check.IsCorrect) {
                    retVal.Settings = this.DialogContent.getResult();
                    WorkflowSaveObject.SerializeModuleSettings(retVal);
                }
            }
        }
        return retVal;
    }
}
