import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ResourceLoginType, UserConfig } from '../../../models/userconfig.model';
import { StandardRequestBase } from '../../../services/request-base';

@Component({
    selector: 'logininfo-control',
    templateUrl: './logininfo.control.html',
    styleUrls: ['./logininfo.control.css']
})
export class LoginInfoControl implements OnInit {

    LoginTypes = [];
    SecurityProviders = [];

    //#region DBInfo
    DBInfoValue;

    @Input()
    get DBInfo() {
        return this.DBInfoValue;
    }
    set DBInfo(val) {
        this.DBInfoValue = val;
        this.DBInfoChange.emit(this.DBInfoValue);
    }

    @Output() DBInfoChange = new EventEmitter<any>();
    //#endregion

    constructor(private service: StandardRequestBase) {
        this.LoginTypes = EnumHelper.GetDropdownValues(ResourceLoginType);
    }

    ngOnInit(): void {
        this.service.executeGet('config/api/security', 'GetSecurityProviders').subscribe((result) => {
            this.SecurityProviders = result;
        });
    }

    @Output() OnDataChange = new EventEmitter<any>();
    onChange(ev) {
        this.OnDataChange.emit();
    }

    loginTypeChanged(ev) {
        if (this.DBInfo) {
            if (this.DBInfo.LoginType == ResourceLoginType.FixedAccount && !this.DBInfo.User) {
                this.DBInfo.User = new UserConfig();
            }
        }
        this.onChange(ev);
    }
}