import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Type } from 'class-transformer';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';
import { RegionSetting } from '../region.control';

@Component({
    selector: 'wf-clustering-settings',
    templateUrl: './clustering.settings.html',
    styleUrls: ['./clustering.settings.css']
})
export class ClusteringSettings extends WorkflowDialogContent {
    Method = 0;
    ClusterNumber = 2;
    Columns = [];
    SubGroupingDisabled = true;
    RegionSetting = new RegionSetting();

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'clusteringWFModule';
        reg.Caption = '@@Clustering';
        reg.GroupID = 'aioperations';
        reg.Index = 180;
        reg.SettingsControl = ClusteringSettings;
        reg.SettingsTypeHelper = new ClusteringSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private translate: TranslateService) {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        const list = [];
        if (dataSource) {
            dataSource.Columns.forEach(col => {
                list.push({
                    Name: col.Name,
                    Scale: false,
                    Category: false,
                    Grouping: false,
                    SubGrouping: false,
                    DateCol: false
                });
            });
        }
        this.Columns = list;
        if (data) {
            if (data.Method) {
                this.Method = data.Method;
            }
            if (data.ScaleColumns) {
                data.ScaleColumns.forEach(sc => {
                    list.some(entry => {
                        if (entry.Name === sc) {
                            entry.Scale = true;
                            return true;
                        }
                        return false;
                    });
                });
            }
            if (data.CatColumns) {
                let subCol;
                data.CatColumns.forEach(sc => {
                    list.some(entry => {
                        if (entry.Name === sc) {
                            entry.Category = true;
                            if (data.Grouping === entry.Name) {
                                entry.Grouping = true;
                                this.SubGroupingDisabled = false;
                            } else if (data.SubGrouping === entry.Name) {
                                subCol = entry;
                                entry.SubGrouping = true;
                            }
                            return true;
                        }
                        return false;
                    });
                });
                if (subCol && this.SubGroupingDisabled) {
                    subCol.SubGrouping = false;
                }
            }
            if (data.ClusterNumber) {
                this.ClusterNumber = data.ClusterNumber;
            }
            if (data.DateColumn) {
                const dateCol = list.find(x => x.Name === data.DateColumn);
                if (dateCol && !dateCol.Category) {
                    dateCol.DateCol = true;
                }
            }
            if (data.RegionSetting) {
                this.RegionSetting = data.RegionSetting;
            }
        }
    }

    getResult() {
        const retVal = new ClusteringSettingsData();
        retVal.Method = this.Method;
        this.Columns.forEach(col => {
            if (col.Scale) {
                retVal.ScaleColumns.push(col.Name);
            }
            if (col.Category) {
                retVal.CatColumns.push(col.Name);
                if (col.Grouping) {
                    retVal.Grouping = col.Name;
                } else if (col.SubGrouping) {
                    retVal.SubGrouping = col.Name;
                }
            } else if (col.DateCol) {
                retVal.DateColumn = col.Name;
            }
        });
        retVal.ClusterNumber = this.ClusterNumber;
        retVal.RegionSetting = this.RegionSetting;
        return retVal;
    }

    clusterChanged(ev) {
        if (ev && ev.value) {
            this.ClusterNumber = ev.value;
        }
    }

    dateColChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.DateCol = false);
            col.DateCol = true;
        }
    }

    catColChanged(ev, col) {
        if (!ev || ev.checked === false) {
            if (col.Grouping) {
                col.Grouping = false;
                this.Columns.forEach(c => c.SubGrouping = false);
                this.SubGroupingDisabled = true;
            }
            if (col.SubGrouping) {
                col.SubGrouping = false;
            }
        }
    }

    groupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.Grouping = false);
            col.Grouping = true;
            col.SubGrouping = false;
            this.SubGroupingDisabled = false;
        } else {
            this.Columns.forEach(c => c.SubGrouping = false);
            this.SubGroupingDisabled = true;
        }
    }

    subGroupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.SubGrouping = false);
            col.SubGrouping = true;
        }
    }

    GetExpressionProperties() {
        return [
            {
                Caption: this.translate.instant('@@Cluster'),
                Value: 'ClusterNumber'
            },
            {
                Caption: this.translate.instant('@@Normierung'),
                Value: 'ScaleColumns'
            },
            {
                Caption: this.translate.instant('@@Kategorie'),
                Value: 'CatColumns'
            },
            {
                Caption: this.translate.instant('@@Methode'),
                Value: 'Method'
            },
            {
                Caption: this.translate.instant('@@Gruppierung'),
                Value: 'Grouping'
            },
            {
                Caption: this.translate.instant('@@Untergruppierung'),
                Value: 'SubGrouping'
            },
            {
                Caption: this.translate.instant('@@Datum'),
                Value: 'DateColumn'
            },
            {
                Caption: this.translate.instant('@@Land'),
                Value: 'RegionSetting.Country'
            },
            {
                Caption: this.translate.instant('@@Region'),
                Value: 'RegionSetting.Region'
            }
        ];
    }
}

// @dynamic
export class ClusteringSettingsData extends AServiceWorkflowData {
    Method = 0;
    ScaleColumns = [];
    CatColumns = [];
    ClusterNumber = 2;
    Grouping: string;
    SubGrouping: string;
    DateColumn: string;
    @Type(() => RegionSetting)
    RegionSetting = new RegionSetting();

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.Clustering.ClusteringSettingsData';
    }
}

export class ClusteringSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new ClusteringSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const ds = state.get('DataSource');
            if (ds) {
                ds.Columns = [
                    {
                        Name: 'Category',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Subcategory',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Rownumber',
                        DataTyp: 'System.Int32'
                    },
                    {
                        Name: 'Label',
                        DataTyp: 'System.Int32'
                    }
                ];
            }
        }
    }
}
