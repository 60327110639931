import { WorkflowRegistryHelper } from "./workflow.registry";
import { WF_GROUP_REGISTRY, WF_SERVICE_SETTINGS, WF_TYPE_REGISTRY, WorkflowExitTypeInfo } from "../../services/workflow.service";
import { AddColumnsSettings, AddColumnsSettingsData } from "../../workflow/modules/dataoperations/addcolumns/addcolumns.settings";
import { AggregateSettings, AggregateSettingsData } from "../../workflow/modules/dataoperations/aggregate/aggregate.settings";
import { AnomalyDetectionSettings, AnomalyDetectionSettingsData } from "../../workflow/modules/dataoperations/ai/anomalydetection/anomaly.detection.settings";
import { AugmentControl } from "../../workflow/modules/dataoperations/ai/augment.control";
import { ClusteringSettings, ClusteringSettingsData } from "../../workflow/modules/dataoperations/ai/clustering/clustering.settings";
import { CorrelationSettings, CorrelationSettingsData } from "../../workflow/modules/dataoperations/ai/correlation/correlation.settings";
import { LuisSettings, LuisValueSettings } from "../../workflow/modules/dataoperations/ai/luis/luis.settings";
import { MultiAnomalyDetectionSettings, MultiAnomalyDetectionSettingsData } from "../../workflow/modules/dataoperations/ai/multianomalydetection/multi.anomaly.detection.settings";
import { MultiFeatureModelSettings, MultiFeatureModelSettingsData } from "../../workflow/modules/dataoperations/ai/multifeaturemodel/multi.feature.model.settings";
import { MultiFeaturePredictionSettings, MultiFeaturePredictionSettingsData } from "../../workflow/modules/dataoperations/ai/multifeatureprediction/multi.feature.prediction.settings";
import { RegionControl } from "../../workflow/modules/dataoperations/ai/region.control";
import { SingleFeatureModelSettings, SingleFeatureModelSettingsData } from "../../workflow/modules/dataoperations/ai/singlefeaturemodel/single.feature.model.settings";
import { SingleFeaturePredictionSettings, SingleFeaturePredictionSettingsData } from "../../workflow/modules/dataoperations/ai/singlefeatureprediction/single.feature.prediction.settings";
import { TextClassificationModelSettings, TextClassificationModelSettingsData } from "../../workflow/modules/dataoperations/ai/textclassificationmodel/text.classification.model.settings";
import { TextClassificationPredictSettings, TextClassificationPredictSettingsData } from "../../workflow/modules/dataoperations/ai/textclassificationpredict/text.classification.predict.settings";
import { ChangeDataTypeSettings, ChangeDataTypeSettingsData } from "../../workflow/modules/dataoperations/changedatatype/changedatatype.settings";
import { ClearDeltaInfoSettings, ClearDeltaInfoSettingsData } from "../../workflow/modules/dataoperations/cleardeltainfo/cleardeltainfo.settings";
import { DatasourceObjectSettings, DatasourceObjectSettingsData, DataSourcePreviewPanel, DatasourceSettings, DatasourceSettingsData } from "../../workflow/modules/dataoperations/datasource/datasource.settings";
import { EditColumnsSettings, EditColumnsSettingsData } from "../../workflow/modules/dataoperations/editcolumns/editcolumns.settings";
import { ExecuteOnActualDataSettingsData, ExecuteOnActualObjectSettingsData } from "../../workflow/modules/dataoperations/executeonactual/executeonactual.settings";
import { ColumnDialog, ExportDataSettings, ExportDataSettingsData, ExportObjectSettings, ExportObjectSettingsData } from "../../workflow/modules/dataoperations/exportdata/exportdata.settings";
import { GenerateReaderSettings, GenerateReaderSettingsData } from "../../workflow/modules/dataoperations/generatereader/generate.reader.settings";
import { PivotSettings, PivotSettingsData } from "../../workflow/modules/dataoperations/pivot/pivot.settings";
import { PythonSettings, PythonSettingsData } from "../../workflow/modules/dataoperations/python/python.settings";
import { ReadRowDataSettings, ReadRowDataSettingsData } from "../../workflow/modules/dataoperations/readrowdata/readrowdata.settings";
import { RelToObjectSettingsData } from "../../workflow/modules/dataoperations/reltoobject/reltoobject.data";
import { RelToObjectObjectSettings } from "../../workflow/modules/dataoperations/reltoobject/reltoobject.object.settings";
import { RelToObjectSettings } from "../../workflow/modules/dataoperations/reltoobject/reltoobject.settings";
import { SimpleComparisonSettings, SimpleComparisonSettingsData } from "../../workflow/modules/dataoperations/simplecomparison/simplecomparison.settings";
import { UnionDatasourceSettingsData, UnionDatasourceSettingsDataHelper } from "../../workflow/modules/dataoperations/uniondatasource/unionds.settings";
import { UnpivotSettings, UnpivotSettingsData } from "../../workflow/modules/dataoperations/unpivot/unpivot.settings";
import { GenerateDocumentStructureSettings, GenerateDocumentStructureSettingsData } from "../../workflow/modules/documentoperations/generatedocumentstructure/generate.document.structure.settings";
import { GetObjectAndTemplateFromPythontStructureSettings, GetObjectAndTemplateFromPythontStructureSettingsData } from "../../workflow/modules/documentoperations/getobjectandtemplatefrompython/get.object.and.template.from.python";
import { GetObjectFromDocumentStructureSettings, GetObjectFromDocumentStructureSettingsData } from "../../workflow/modules/documentoperations/getobjectfromdocumentstructure/get.object.from.document.structure.settings";
import { DeleteResourceSettings, DeleteResourceSettingsData } from "../../workflow/modules/fileoperations/deleteresource/delete.resource.settings";
import { LoadResourceSettings, LoadResourceSettingsData } from "../../workflow/modules/fileoperations/loadresource/load.resource.settings";
import { SaveResourceSettings, SaveResourceSettingsData } from "../../workflow/modules/fileoperations/saveresource/save.resource.settings";
import { WriteToTCPSettings, WriteToTCPSettingsData } from "../../workflow/modules/fileoperations/writetotcp/write.to.tcp.settings";
import { FillKPISettings, FillKPISettingsData } from "../../workflow/modules/kpi/fillkpi/fill.kpi.settings";
import { ReadKPISettings, ReadKPISettingsData } from "../../workflow/modules/kpi/readkpi/read.kpi.settings";
import { AddPropertiesSettings, AddPropertiesSettingsData } from "../../workflow/modules/objectoperations/addproperties/add.properties.settings";
import { PythonObjectSettings, PythonObjectSettingsData } from "../../workflow/modules/objectoperations/ai/python.object.settings";
import { EditPropertiesSettings, EditPropertiesSettingsData } from "../../workflow/modules/objectoperations/editproperties/editproperties.settings";
import { GenerateObjectReaderSettings, GenerateObjectReaderSettingsData } from "../../workflow/modules/objectoperations/generatereader/generate.object.reader.settings";
import { ObjectPropertyChooser } from "../../workflow/modules/objectoperations/object.property.chooser";
import { ObjectToRelSettings, ObjectToRelSettingsData } from "../../workflow/modules/objectoperations/objecttorel/objecttorel.settings";
import { ReadObjectPropertiesSettings, ReadObjectPropertiesSettingsData } from "../../workflow/modules/objectoperations/readobjectprops/readobjectprops.settings";
import { ReplaceEnumSettings, ReplaceEnumSettingsData } from "../../workflow/modules/objectoperations/replaceenum/replaceenum.settings";
import { ExecuteRestEndPointSettings, ExecuteRestEndPointSettingsData } from "../../workflow/modules/rest/execute.rest.endpoint.settings";
import { RestObjectInfoDialog } from "../../workflow/modules/rest/rest.object.info.dialog";
import { SendMessageSettings, SendMessageSettingsData } from "../../workflow/modules/sendmessage/sendmessage.settings";
import { TriggerMessageSettings, TriggerMessageSettingsData } from "../../workflow/modules/sendmessage/trigger.message.settings";
import { ShowWizardDialog } from "../../workflow/modules/showwizard/show.wizard.dialog";
import { ShowWizardSettings } from "../../workflow/modules/showwizard/show.wizard.settings";


export const MW_WORKFLOW = [
    AddColumnsSettings,
    AggregateSettings,
    ChangeDataTypeSettings,
    DatasourceSettings,
    DatasourceObjectSettings,
    DataSourcePreviewPanel,
    EditColumnsSettings,
    ExportDataSettings,
    ExportObjectSettings,
    ColumnDialog,
    PivotSettings,
    PythonSettings,
    PythonObjectSettings,
    UnpivotSettings,
    ClusteringSettings,
    CorrelationSettings,
    LuisValueSettings,
    AnomalyDetectionSettings,
    SingleFeatureModelSettings,
    MultiAnomalyDetectionSettings,
    SingleFeaturePredictionSettings,
    MultiFeatureModelSettings,
    MultiFeaturePredictionSettings,
    AugmentControl,
    RelToObjectSettings,
    RelToObjectObjectSettings,
    ObjectToRelSettings,
    ClearDeltaInfoSettings,
    SimpleComparisonSettings,
    ReadRowDataSettings,
    EditPropertiesSettings,
    ObjectPropertyChooser,
    ReplaceEnumSettings,
    ReadObjectPropertiesSettings,
    SendMessageSettings,
    TriggerMessageSettings,
    LoadResourceSettings,
    SaveResourceSettings,
    DeleteResourceSettings,
    RegionControl,
    WriteToTCPSettings,
    GenerateReaderSettings,
    FillKPISettings,
    ReadKPISettings,
    ShowWizardSettings,
    ShowWizardDialog,
    TextClassificationModelSettings,
    TextClassificationPredictSettings,
    GenerateDocumentStructureSettings,
    GetObjectFromDocumentStructureSettings,
    GetObjectAndTemplateFromPythontStructureSettings,
    GenerateObjectReaderSettings,
    AddPropertiesSettings,
    ExecuteRestEndPointSettings,
    RestObjectInfoDialog
];

export class MWWorkflowRegistryHelper {
    public static Build() {
        // Module
        MW_WORKFLOW.forEach(wf => {
            if (typeof wf['GetRegistry'] === 'function') {
                WorkflowRegistryHelper.ExternalWorkflowRegistry.push(wf);
            }
        });
        WorkflowRegistryHelper.ExternalWorkflowRegistry.push(...[UnionDatasourceSettingsDataHelper,
            ExecuteOnActualDataSettingsData, ExecuteOnActualObjectSettingsData]);

        // Gruppen
        WF_GROUP_REGISTRY.push({
            Caption: '@@Relationale Operationen',
            GroupID: 'reldataoperations',
            Index: 30,
            ParentID: null,
            Icon:'wf_group_reldataoperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Objekt Operationen',
            GroupID: 'objectoperations',
            Index: 35,
            ParentID: null,
            Icon: 'wf_group_objectoperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@AI Operationen',
            GroupID: 'aioperations',
            Index: 37,
            ParentID: null,
            Icon: 'wf_group_aioperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Datei Operationen',
            GroupID: 'fileoperations',
            Index: 38,
            ParentID: null,
            Icon: 'wf_group_fileoperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@KPI Operationen',
            GroupID: 'kpioperations',
            Index: 39,
            ParentID: null,
            Icon: 'wf_group_kpioperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Dokument Operationen',
            GroupID: 'documentoperations',
            Index: 40,
            ParentID: null,
            Icon: 'wf_group_documentoperations'
        });
        

        const path = 'evidanza.MiddleWare.Shared.Workflow.DataOperations.';
        const objPath = 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.';
        const filePath = 'evidanza.MiddleWare.Shared.Workflow.FileOperations.';
        const kpiPath = 'evidanza.MiddleWare.Shared.Workflow.KPIOperations.';
        const docPath = 'evidanza.MiddleWare.Shared.Workflow.DocumentOperations.';
        // Service-Settings
        WF_SERVICE_SETTINGS.set(path + 'AddColumns.AddColumnsSettingsData', AddColumnsSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'Aggregate.AggregateSettingsData', AggregateSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ChangeDataType.ChangeDataTypeSettingsData', ChangeDataTypeSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'DataSource.DatasourceSettingsData', DatasourceSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'DataSource.DatasourceObjectSettingsData', DatasourceObjectSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'EditColumns.EditColumnsSettingsData', EditColumnsSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ExportData.ExportDataSettingsData', ExportDataSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ExportData.ExportObjectSettingsData', ExportObjectSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'Pivot.PivotSettingsData', PivotSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'Python.PythonSettingsData', PythonSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'AI.PythonObjectSettingsData', PythonObjectSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'UnionDatasource.UnionDatasourceSettingsData', UnionDatasourceSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'Unpivot.UnpivotSettingsData', UnpivotSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ExecuteOnActual.ExecuteOnActualDataSettingsData', ExecuteOnActualDataSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ExecuteOnActual.ExecuteOnActualObjectSettingsData', ExecuteOnActualObjectSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.Clustering.ClusteringSettingsData', ClusteringSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.Correlation.CorrelationSettingsData', CorrelationSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.Luis.LuisSettingsData', LuisSettings);
        WF_SERVICE_SETTINGS.set(path + 'AI.AnomalyDetection.AnomalyDetectionSettingsData', AnomalyDetectionSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.SingleFeatureModel.SingleFeatureModelSettingsData', SingleFeatureModelSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.SingleFeaturePrediction.SingleFeaturePredictionSettingsData',
            SingleFeaturePredictionSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.MultiFeatureModel.MultiFeatureModelSettingsData', MultiFeatureModelSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.MultiFeaturePrediction.MultiFeaturePredictionSettingsData', MultiFeaturePredictionSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.MultiAnomalyDetection.MultiAnomalyDetectionSettingsData', MultiAnomalyDetectionSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.TextClassificationModel.TextClassificationModelSettingsData',
            TextClassificationModelSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'AI.TextClassificationPredict.TextClassificationPredictSettingsData',
            TextClassificationPredictSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'RelToObject.RelToObjectSettingsData', RelToObjectSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'ObjectToRel.ObjectToRelSettingsData', ObjectToRelSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ClearDeltaInfo.ClearDeltaInfoSettingsData', ClearDeltaInfoSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'SimpleComparison.SimpleComparisonSettingsData', SimpleComparisonSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'ReadRowData.ReadRowDataSettingsData', ReadRowDataSettingsData);
        WF_SERVICE_SETTINGS.set(path + 'GenerateReader.GenerateReaderSettingsData', GenerateReaderSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'EditProperties.EditPropertiesSettingsData', EditPropertiesSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'ReplaceEnum.ReplaceEnumSettingsData', ReplaceEnumSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'ReadObjectProps.ReadObjectPropertiesSettingsData', ReadObjectPropertiesSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'GenerateReader.GenerateObjectReaderSettingsData', GenerateObjectReaderSettingsData);
        WF_SERVICE_SETTINGS.set(objPath + 'AddProperties.AddPropertiesSettingsData', AddPropertiesSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.MiddleWare.Shared.Workflow.SendMessage.SendMessageSettingsData', SendMessageSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.MiddleWare.Shared.Workflow.SendMessage.TriggerMessageSettingsData', TriggerMessageSettingsData);
        WF_SERVICE_SETTINGS.set(filePath + 'LoadResource.LoadResourceSettingsData', LoadResourceSettingsData);
        WF_SERVICE_SETTINGS.set(filePath + 'SaveResource.SaveResourceSettingsData', SaveResourceSettingsData);
        WF_SERVICE_SETTINGS.set(filePath + 'DeleteResource.DeleteResourceSettingsData', DeleteResourceSettingsData);
        WF_SERVICE_SETTINGS.set(filePath + 'WriteToTCP.WriteToTCPSettingsData', WriteToTCPSettingsData);
        WF_SERVICE_SETTINGS.set(kpiPath + 'FillKPI.FillKPISettingsData', FillKPISettingsData);
        WF_SERVICE_SETTINGS.set(kpiPath + 'ReadKPI.ReadKPISettingsData', ReadKPISettingsData);
        WF_SERVICE_SETTINGS.set(docPath + 'GenerateDocumentStructure.GenerateDocumentStructureSettingsData',
            GenerateDocumentStructureSettingsData);
        WF_SERVICE_SETTINGS.set(docPath + 'GetObjectFromDocumentStructure.GetObjectFromDocumentStructureSettingsData',
            GetObjectFromDocumentStructureSettingsData);
        WF_SERVICE_SETTINGS.set(docPath + 'GetObjectAndTemplateFromPython.GetObjectAndTemplateFromPythonSettingsData',
            GetObjectAndTemplateFromPythontStructureSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.MiddleWare.Shared.Workflow.Rest.ExecuteRestEndPointSettingsData',
            ExecuteRestEndPointSettingsData);

        // TypeInfos
        const relDataInfo = new WorkflowExitTypeInfo();
        relDataInfo.ID = 'relData';
        relDataInfo.Color = '#04B431';
        WF_TYPE_REGISTRY.set(relDataInfo.ID, relDataInfo);
        const objDataInfo = new WorkflowExitTypeInfo();
        objDataInfo.ID = 'objData';
        objDataInfo.Color = '#FF8000';
        WF_TYPE_REGISTRY.set(objDataInfo.ID, objDataInfo);
        const relDataEntryInfo = new WorkflowExitTypeInfo();
        relDataEntryInfo.ID = 'relDataEntry';
        relDataEntryInfo.Color = '#1c8547';
        WF_TYPE_REGISTRY.set(relDataEntryInfo.ID, relDataEntryInfo);
        const objDataEntryInfo = new WorkflowExitTypeInfo();
        objDataEntryInfo.ID = 'objDataEntry';
        objDataEntryInfo.Color = '#916711';
        WF_TYPE_REGISTRY.set(objDataEntryInfo.ID, objDataEntryInfo);
    }
}
