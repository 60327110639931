import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { CacheService } from '../../cache/cache.service';
import { GlobalEventHelper } from '../../helpers/globalevent.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { PageEditClient } from '../../helpers/page.edit.client';
import { PermissionHelper } from '../../helpers/permissions.helper';
import { VariableHelper } from '../../helpers/variable.helper';
import { ContentType } from '../../models/enums/contenttype.enum';
import { ViewType } from '../../models/enums/viewtype.enum';
import { Layout } from '../../models/layout.model';
import { LayoutEditService } from '../../models/layout/layout.edit.client';
import { Registry } from '../../script/modules/registry.script';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { SideNavService } from '../../services/sidenav.service';

@Component({
    selector: 'contentpage',
    templateUrl: './content.page.html',
    styleUrls: ['./content.page.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPage implements OnInit, OnDestroy {
    ErrorPage = false;
    PermissionPage = false;

    ShowContentValue = false;
    get ShowContent() {
        return this.ShowContentValue;
    }
    set ShowContent(value) {
        this.ShowContentValue = value;
    }
    Component;
    Subscriptions = {};
    LayoutElement;
    NavigationNode;
    Initialized = false;
    Destroyed = false;
    CheckoutUser;
    Url;
    ActualPageEditClient: PageEditClient;

    constructor(private translate: TranslateService, private metaService: MetaService,
        private router: Router, private cdRef: ChangeDetectorRef, private meta: Meta, script: ScriptLibraryService) {
        Registry.initializeRegistry(script);
    }
    ngOnInit(): void {
        LayoutService.DragType.next('content');
        this.Subscriptions['KeyEvent'] = LayoutService.KeyPress.subscribe(x => {
            if (x.key === 'F2' && !LayoutService.LayoutEditMode.getValue()) {
                if (PermissionHelper.GetAppBuilderPermission() && PermissionHelper.EditPermission.getValue()) {
                    const viewType = LayoutService.ViewType.getValue();
                    if (viewType === ViewType.Edit) {
                        if (this.ActualPageEditClient) {
                            this.ActualPageEditClient.checkForSave().then(() => {
                                LayoutService.ViewType.next(ViewType.View);
                            });
                        } else {
                            LayoutService.ViewType.next(ViewType.View);
                        }
                    } else {
                        LayoutService.ViewType.next(ViewType.Edit);
                        LayoutService.SelectedItem.next(this.LayoutElement);
                        if (this.NavigationNode && !this.ActualPageEditClient) {
                            const le = this.LayoutElement;
                            this.metaService.CanEditPage(this.NavigationNode.Url).subscribe(x => {
                                this.ActualPageEditClient = new PageEditClient(le, {
                                    CanEditInfo: x
                                }, this.metaService);
                                LayoutEditService.SetNext(this.ActualPageEditClient, false);
                            });
                        }
                    }
                }
            }
        });
        this.Subscriptions['PopState'] = NavigationService.OnPopState.subscribe(() => {
            if (this.Component && this.Component.DisableNavigation) {
                if (this.Component.DisableNavigationText) {
                    NavigationService.DisableBack.next(this.Component.DisableNavigationText);
                } else {
                    NavigationService.DisableBack.next(this.translate.instant('@@Website verlassen?'));
                }
            }
        });
        NavigationService.SelectedPage.next('content');
        this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe(viewType => {
            if (this.Component) {
                this.Component.Editable = viewType !== ViewType.View;
                this.Component.SetViewType(viewType);                
            }
        });
        this.Subscriptions['RootSize'] = LayoutService.RootSize.subscribe(async (size) => {
            if (this.LayoutElement && this.LayoutElement.Resolutions) {
                let temp = LayoutHelper.GetActiveResolution(this.LayoutElement);
                if (temp != this.Component) {
                    this.Component = temp
                    await VariableHelper.LoadVariables(this.Component);
                    GlobalEventHelper.InitGlobalEvents(this.Component);
                    if (!this.Component.ElementType) {
                        if (this.Component.ContentType) {
                            switch (this.Component.ContentType) {
                                case ContentType.Grid: this.Component.ElementType = 'grid'; break;
                                case ContentType.Flex: this.Component.ElementType = 'flex'; break;
                                case ContentType.Raster: this.Component.ElementType = 'raster'; break;
                                case ContentType.Canvas: this.Component.ElementType = 'canvas'; break;
                            }
                        } else {
                            this.Component.ElementType = 'grid';
                        }
                    }
                    PermissionHelper.GetContentPermissions(this.LayoutElement._key).then(() => {
                        if (this.Component && this.Component.DisableNavigation) {
                            LayoutService.PreventBrowserClose.next(true);
                        }
                        this.LayoutElement.Editable = false;
                        this.LayoutElement.SetViewType(LayoutService.ViewType.getValue());
                        LayoutService.SelectedItem.next(this.Component);
                        LayoutService.SelectedLayout.next(this.LayoutElement);
                        this.ShowContent = true;
                        PermissionHelper.EditPermission.next(true);
                        this.cdRef.detectChanges();
                    });
                }
            }
        });
        this.Subscriptions['LayoutPermissions'] = PermissionHelper.ActiveNavigationPermissions.subscribe(() => {
            if (this.Initialized) {
                const contentShown = this.ShowContent;
                this.checkPermission();
                if (contentShown && !this.ShowContent && LayoutService.ViewType.getValue() === ViewType.Edit) {
                    LayoutService.ViewType.next(ViewType.View);
                    this.cdRef.detectChanges();
                }
            }
        });
        this.Subscriptions['ContentPermissions'] = PermissionHelper.ContentPermissionChanged.subscribe((key) => {
            if (this.LayoutElement && this.LayoutElement._key === key) {
                PermissionHelper.GetContentPermissions(this.LayoutElement._key).then(() => {
                    this.cdRef.detectChanges();
                });
            }
        });
        this.Subscriptions['Impersonate'] = PermissionHelper.Impersonate.subscribe((roles) => {
            if (this.LayoutElement) {
                PermissionHelper.GetContentPermissions(this.LayoutElement._key).then(() => {
                    this.cdRef.detectChanges();
                });
            }
        });
        this.Subscriptions['Url'] = SideNavService.InternalURL.subscribe((url) => {
            if (url != this.Url) {
                this.Url = url;
                this.refreshAndQuit();
            }
        });
        this.Initialized = true;
    }

    ngOnDestroy(): void {
        LayoutService.PreventBrowserClose.next(false);
        this.Destroyed = true;
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            if (this.Subscriptions[key].unsubscribe) {
                this.Subscriptions[key].unsubscribe();
            }
        });
        if (this.Component && this.Component.MetaTags) {
            this.ActiveMetaTags.forEach((tag) => {
                this.meta.removeTag(tag);
            });
        }
    }

    checkPermission() {
        if (this.Destroyed) {
            this.ErrorPage = false;
            this.PermissionPage = false;
            this.ShowContent = false;
            PermissionHelper.EditPermission.next(false);
        } else if (this.NavigationNode) {
            const naviperm = PermissionHelper.ActiveNavigationPermissions.getValue();
            if (naviperm && naviperm[this.NavigationNode._Id] === true) {
                this.ErrorPage = false;
                this.PermissionPage = true;
                this.ShowContent = false;
                PermissionHelper.EditPermission.next(false);
            } else {
                this.ErrorPage = false;
                this.PermissionPage = false;
                this.ShowContent = true;
                PermissionHelper.EditPermission.next(true);
            }
        } else {
            this.ErrorPage = true;
            this.PermissionPage = false;
            this.ShowContent = false;
            PermissionHelper.EditPermission.next(false);
        }
        this.cdRef.detectChanges();
    }
    ActiveMetaTags = null;
    getLayout() {
        this.Component = null;
        this.cdRef.detectChanges();
        this.checkPermission();
        if (this.ShowContent) {
            this.metaService.LoadPage(this.NavigationNode.Url).subscribe((result) => {
                this.updateLayout(result);
            });
        }
    }

    //#region ILayoutChangeClient
    //checkForChangedLayout() {
    //    return new Promise<void>(resolve => {
    //        if (this.NavigationNode) {
    //            CacheService.ContainsLayout(this.NavigationNode.Url).then(contains => {
    //                if (contains) {
    //                    resolve();
    //                } else {
    //                    NotificationHelper.Info('@@RefreshPage', '@@RefreshPageHeader');
    //                    CacheService.ReadLayout(this.NavigationNode.Url).then(layout => {
    //                        this.updateLayout(layout);
    //                        resolve();
    //                    });
    //                }
    //            });
    //        } else {
    //            resolve();
    //        }
    //    });
    //}

    updateLayout(layoutElement) {
        this.setLayoutElement(layoutElement).then(() => {
            PermissionHelper.GetContentPermissions(this.LayoutElement._key).then(() => {
                this.Component = LayoutHelper.GetActiveResolution(this.LayoutElement);
                if (this.Component && this.Component.DisableNavigation) {
                    LayoutService.PreventBrowserClose.next(true);
                }
                if (this.Component.MetaTags) {
                    let list = [];
                    this.Component.MetaTags.forEach((tag) => {
                        let item = { name: tag.Key, content: tag.Value };
                        this.meta.updateTag(item);
                        list.push(item);
                    });
                    this.ActiveMetaTags = list;
                }
                this.LayoutElement.Editable = false;
                this.LayoutElement.SetViewType(ViewType.View);
                LayoutService.SelectedItem.next(this.LayoutElement);
                LayoutService.SelectedLayout.next(this.LayoutElement);
                this.ShowContent = true;
                PermissionHelper.EditPermission.next(true);
                this.cdRef.detectChanges();
            });
        });
    }

    private setLayoutElement(result) {
        return new Promise(resolve => {
            if (result) {
                this.LayoutElement = plainToClass(Layout, result);
                let layout = LayoutHelper.GetActiveResolution(this.LayoutElement);
                switch (this.LayoutElement.ContentType) {
                    case ContentType.Grid: layout.ElementType = 'grid'; break;
                    case ContentType.Flex: layout.ElementType = 'flex'; break;
                    case ContentType.Raster: layout.ElementType = 'raster'; break;
                    case ContentType.Canvas: layout.ElementType = 'canvas'; break;
                }
                VariableHelper.LoadVariables(layout).then(() => {
                    GlobalEventHelper.InitGlobalEvents(layout);
                    resolve(null);
                });
            } else {
                this.LayoutElement = new Layout();
                this.LayoutElement.ElementType = 'grid';
                resolve(null);
            }
        });
    }

    detectChanges() {
        this.cdRef.detectChanges();
    }

    refreshAndQuit() {
        if (LayoutService.ViewType.getValue() === ViewType.Edit) {
            LayoutService.ViewType.next(ViewType.View);
        }
        if (this.Component && this.Component.MetaTags) {
            this.ActiveMetaTags.forEach((tag) => {
                this.meta.removeTag("name='" + tag.name + "'");
            });
        }
        this.Component = null;
        LayoutService.PreventBrowserClose.next(false);
        this.LayoutElement = null;
        this.NavigationNode = null;
        this.ActualPageEditClient = null;
        LayoutEditService.CloseAll().then(() => {
            const navStruct = SideNavService.SelectedNavigationStructure.getValue();
            if (navStruct) {
                const url = this.Url;
                if (url) {
                    if (SideNavService.IsSystemURL(url)) {
                        this.getLayout();
                    } else {
                        CacheService.ReadNavigation(navStruct).then(navList => {
                            if (navList) {
                                const toLower = url.toLowerCase();
                                this.NavigationNode = navList.find(ni => ni.Url && ni.Url.toLowerCase() === toLower);
                                if (this.NavigationNode) {
                                    this.getLayout();
                                } else {
                                    this.metaService.LoadNavigationStructurePropertyByKey(navStruct, 'LandingPage').subscribe(lp => {
                                        if (typeof lp === 'string' && lp.toLowerCase() === toLower) {
                                            this.NavigationNode = {
                                                _Id: 'landingpage',
                                                Url: lp
                                            };
                                        }
                                        this.getLayout();
                                    });
                                }
                            } else {
                                this.getLayout();
                            }
                        });
                    }
                } else {
                    this.metaService.LoadNavigationStructurePropertyByKey(navStruct, 'LandingPage').subscribe(lp => {
                        if (typeof lp === 'string') {
                            this.router.navigateByUrl(navStruct + lp);
                        } else {
                            this.getLayout();
                        }
                    });
                }
            } else {
                this.getLayout();
            }
        });
    }
    //#endregion
}
