import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Font } from '../../../../models/style/font.model';


@Component({
  selector: 'bold-action',
  templateUrl: './bold.action.html',
  styleUrls: ['./bold.action.css'],
})
export class BoldAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'bold';
    
    //#region Bold
    @Input()
    get Bold() {
        if (this.SelectedItemValue && this.SelectedItemValue.Font) {
            return this.SelectedItemValue.Font.Bold;
        }
        return false;
    }
    set Bold(val) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Font) {
                this.SelectedItemValue.Font = new Font();
            }
            this.SelectedItemValue.Font.Bold = val;
        }
        this.BoldChange.emit(val);
    }

    @Output() BoldChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {

    }
    ExecuteAction() {
        this.Bold = !this.Bold;
    }

    Visibility() {
        return true;
    }

    GetStyle() {
        let result = {};
        if (this.Bold) {
            result['color'] = 'var(--primary-color)';
        }
        return result;
    }
}
