import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class ScriptLibraryService extends RequestBase {

    BasePath = 'api/scriptlibrary';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }
  
    Get(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Get', filter, this.options);
    }
    GetAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAll', this.options);
    }
    GetByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetByID?id=' + id, this.options);
    }
    LoadClientScripts(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadClientScripts', this.options);
    }

    Save(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save', obj, this.options);
    }

    Delete(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }

    GetScriptImports(connector, isclient) {
        if (connector && connector != null) {
            return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetScriptImports?connector=' + connector, this.options)
        } else {
            return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetScriptImports?isClient=' + isclient, this.options)
        }
    }

    RequestScriptLock(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/RequestScriptLock', obj, this.options);
    }

    SaveScriptToChangeLog(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveScriptToChangeLog', obj, this.options);
    }

    LoadByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadByID?id=' + id, this.options);
    }

    TryDeleteScript(id, ignoreVersions): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/TryDeleteScript?id=' + id + '&ignoreVersions=' + ignoreVersions;
        return this.http.delete<any>(path, this.options);
    }
}
