import { Component } from '@angular/core';
import { AObjectPreviewSettings } from '../common/adatapreview.settings';

@Component({
    selector: 'container-xml-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerXMLSettings extends AObjectPreviewSettings {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Container.ContainerXML';
    }

    getDBTypes(): number[][] {
        return [[1048576]]; // ObjectRead
    }

    getResourceBase(): number {
        return 1;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerXML',
            ID: 'containerxml',
            Icon: 'table_chart',
            Index: 20,
            Parent: 'objectcontainer',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
                Value: 4
            },
            Content: ContainerXMLSettings
        }
    }
}