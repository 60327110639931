import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-aggregate-settings',
    templateUrl: './aggregate.settings.html',
    styleUrls: ['./aggregate.settings.css']
})
export class AggregateSettings extends WorkflowDialogContent {
    Aggregations;
    AggregateColumns = [];
    GroupColumns = [];
    AllColumns = [];

    public static GetAggregations() {
        return [
            { Caption: '@@Avg', Value: 2 },
            { Caption: '@@Count', Value: 4 },
            { Caption: '@@Max', Value: 6 },
            { Caption: '@@Min', Value: 10 },
            { Caption: '@@Sum', Value: 1 },
        ];
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'aggregateWFModule';
        reg.Caption = '@@Aggregieren';
        reg.GroupID = 'reldataoperations';
        reg.Index = 120;
        reg.SettingsControl = AggregateSettings;
        reg.SettingsTypeHelper = new AggregateSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
        this.Aggregations = AggregateSettings.GetAggregations();
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource) {
            this.AllColumns = dataSource.Columns;
        }
        if (data) {
            const json = serialize(data);
            const obj = deserialize(AggregateSettingsData, json);
            this.AggregateColumns = obj.AggregateColumns;
            const groupCols = [];
            obj.GroupColumns.forEach(gc => {
                const entry = new AggregateColumn();
                entry.Column = gc;
                groupCols.push(entry);
            });
            this.GroupColumns = groupCols;
        }
    }

    getResult() {
        const retVal = new AggregateSettingsData();
        retVal.AggregateColumns = this.AggregateColumns;
        this.GroupColumns.forEach(gc => {
            retVal.GroupColumns.push(gc.Column);
        });
        return retVal;
    }

    checkData() {
        const retVal = new DataCheck();
        if (this.AggregateColumns.some(ac => !ac.Column || !ac.Aggregation) || this.GroupColumns.some(gc => !gc.Column)) {
            retVal.IsCorrect = false;
            retVal.Error =
                '@@Bitte vergeben Sie fuer jede Aggregation eine Spalte und einen Aggregationstyp und fuer jede Gruppierung eine Spalte.';
        }
        return retVal;
    }

    addAggregateColumn() {
        this.AggregateColumns.push(new AggregateColumn());
    }

    removeAggregateColumn(i) {
        this.AggregateColumns.splice(i, 1);
    }

    addGroupColumn() {
        this.GroupColumns.push({ Column: '' });
    }

    removeGroupColumn(i) {
        this.GroupColumns.splice(i, 1);
    }

    getColumns(col) {
        const selectedColumns = [];
        this.AggregateColumns.forEach(agg => {
            if (agg.Column !== col) {
                selectedColumns.push(agg.Column);
            }
        });
        this.GroupColumns.forEach(gc => {
            if (gc.Column !== col) {
                selectedColumns.push(gc.Column);
            }
        });
        const columns = [];
        this.AllColumns.forEach(allCol => {
            if (!selectedColumns.some(sc => sc === allCol.Name)) {
                columns.push(allCol);
            }
        });
        return columns;
    }
}
// @dynamic
export class AggregateSettingsData extends AServiceWorkflowData {
    @Type(() => AggregateColumn)
    AggregateColumns: AggregateColumn[] = [];
    GroupColumns: string[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.Aggregate.AggregateSettingsData';
    }
}

export class AggregateColumn {
    Column: string;
    Aggregation = 1;
}

export class AggregateSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new AggregateSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            var ds = state.get('DataSource');
            if (ds && ds.Columns) {
                const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
                if (settings) {
                    const newColumns = [];
                    if (settings.GroupColumns) {
                        settings.GroupColumns.forEach(gc => {
                            ds.Columns.some(col => {
                                if (col.Name === gc) {
                                    newColumns.push(col);
                                    return true;
                                }
                                return false;
                            });
                        });
                    }
                    if (settings.AggregateColumns) {
                        settings.AggregateColumns.forEach(agg => {
                            ds.Columns.some(col => {
                                if (col.Name === agg.Column) {
                                    newColumns.push(col);
                                    return true;
                                }
                                return false;
                            });
                        });
                    }
                    ds.Columns = newColumns;
                }
            }
        }
    }
}
