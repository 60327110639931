import { Component, OnInit } from '@angular/core';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { ClientAuthentication, CodeChallengeMethod, GrantType } from '../../models/auth.type';
import { OAuth2Settings } from '../../models/oauth2.settings';

@Component({
    selector: 'access-token-dialog',
    templateUrl: 'access.token.dialog.html',
    styleUrls: ['access.token.dialog.css']
})
export class AccessTokenDialog implements OnInit {

    public grantTypes: any[];
    public clientAuthentications: any[];
    public accessToken = new OAuth2Settings();
    public codeChallengeMethods: any[];

    public get IsPASSWORDCREDENTIALS(): boolean {
        return this.accessToken.GrantType === GrantType.PASSWORD_CREDENTIALS;
    }
    public get IsNotIMPLICIT(): boolean {
        return this.accessToken.GrantType !== GrantType.IMPLICIT;
    }
    public get IsImplicitOrAuthorizationCode(): boolean {
        return this.accessToken.GrantType === GrantType.AUTORIZATION_CODE || this.accessToken.GrantType === GrantType.IMPLICIT;
    }

    ngOnInit(): void {
        this.clientAuthentications = EnumHelper.GetDropdownValues(ClientAuthentication);
        this.codeChallengeMethods = EnumHelper.GetDropdownValues(CodeChallengeMethod);
        this.grantTypes = GrantType.getDropdownValues();
    }

    public Initialize(arg: OAuth2Settings): void {
        this.accessToken = new OAuth2Settings(arg);
    }

    public GetDialogResult() {
        return this.accessToken;
    }

    public updateTokenInfo(event: any): void {
        this.accessToken.AccessToken = event.AccessToken;
        this.accessToken.RefreshToken = event.RefreshToken;
        this.accessToken.TokenType = event.TokenType;
    }
}