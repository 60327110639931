import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { Component } from '@angular/core';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { Type, deserialize, serialize } from 'class-transformer';

@Component({
    selector: 'wf-define-texts-settings-item',
    templateUrl: 'define.texts.settings.html',
    styles: ['.textDIV { border: 1px solid lightgray; border-radius: 3px; margin-bottom: 4px; }']
})
export class DefineTextsSettings extends FormulaWorkflowDialogContent {
    Data = new DefineTextsSettingsData();
    Mentions;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'defineTextWFModule';
        reg.Caption = '@@Texte definieren';
        reg.GroupID = 'generateData';
        reg.Index = 16;
        reg.SettingsControl = DefineTextsSettings;
        reg.SettingsTypeHelper = new DefineTextsSettingsDataHelper();
        reg.Executer = DefineTextsModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (this.Variables && this.Variables.length > 0) {
            const mentions = [];
            this.Variables.forEach(x => {
                mentions.push(x.AliasKey);
            });
            this.Mentions = {
                mentions: [{
                    items: mentions,
                    triggerChar: DefineTextsModuleExecuter.replacer,
                    mentionSelect: (x) => {
                        if (x && x.label) {
                            return DefineTextsModuleExecuter.replacer + x.label + DefineTextsModuleExecuter.replacer;
                        }
                        return '';
                    }
                }],
                disableStyle: false
            };
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DefineTextsSettingsData, json);
        }
    }

    getResult() {
        const texts = [];
        this.Data.Texts.forEach(x => {
            if (x.StatusKey || x.UseForState) {
                texts.push(x);
            }
        });
        this.Data.Texts = texts;
        return this.Data;
    }

    addText() {
        if (this.Data) {
            if (this.Data.Texts) {
                this.Data.Texts.push(new TextSetting());
            } else {
                this.Data.Texts = [new TextSetting()];
            }
        }
    }

    removeText(i) {
        if (this.Data && this.Data.Texts) {
            this.Data.Texts.splice(i, 1);
        }
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Texts) {
            this.Data.Texts.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }
}

// @dynamic
export class DefineTextsSettingsData extends AServiceWorkflowData {
    @Type(() => TextSetting)
    Texts: TextSetting[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineText.DefineTextsSettingsData';
    }
}

export class TextSetting {
    Text: string;
    StatusKey: string;
    UseForState = false;
}

export class DefineTextsSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new DefineTextsSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Texts) {
                const list = [];
                settings.Texts.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class DefineTextsModuleExecuter extends WorkflowModuleExecuter {
    static replacer = '$';
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Texts) {
                const variables = [];
                status.Context.forEach((v, k) => {
                    if (typeof v !== 'undefined' && v != null) {
                        variables.push({
                            Key: DefineTextsModuleExecuter.replacer + k + DefineTextsModuleExecuter.replacer,
                            Value: '' + v
                        });
                    }
                });
                status.ActualSettings.Texts.forEach(x => {
                    let text = x.Text;
                    if (text.indexOf(DefineTextsModuleExecuter.replacer) >= 0) {
                        variables.some(y => {
                            text = text.replace(y.Key, y.Value);
                            return text.indexOf(DefineTextsModuleExecuter.replacer) < 0;
                        });
                    }
                    if (x.StatusKey) {
                        const key = DefineTextsModuleExecuter.replacer + x.StatusKey + DefineTextsModuleExecuter.replacer;
                        if (status.Context.has(x.StatusKey)) {
                            const variable = variables.find(y => y.Key === key);
                            variable.Value = text;
                        } else {
                            variables.push({
                                Key: key,
                                Value: text
                            });
                        }
                        status.Context.set(x.StatusKey, text);
                    }
                    if (x.UseForState === true) {
                        status.ActualParameter = text;
                    }
                });
            }
            return 0;
        } else {
            status.Logger.logError('DefineTexts modul: No settings found.');
        }
        return super.execute(status);
    }
}
