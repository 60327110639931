import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { jsPlumb } from 'jsplumb';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { RelSourceHelper } from '../../../helpers/relsource.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { QueryEngineService } from '../../../services/query.engine.service';

@Component({
    selector: 'query-relation-overview',
    templateUrl: './query.relation.overview.html',
    styleUrls: ['./query.relation.overview.css']
})
export class QueryRelationOverview implements AfterViewInit {

    AllContainer = [];
    LostContainer = [];
    GridStyle = {
        'zoom': 1
    };
    DialogID = Math.round(Math.random() * 999999999);
    jsPlumbInstance;
    StartContainer;
    SelectedContainer;
    LoadedRelations = new Map();

    @ViewChild('grid') grid;

    InitArgsValue;
    @Input()
    get InitArgs() {
        return this.InitArgsValue;
    }
    set InitArgs(val) {
        this.Reset(val);
    }

    @Output() SelectedContainerChanged = new EventEmitter<any>();

    constructor(private service: QueryEngineService, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.jsPlumbInstance = jsPlumb.getInstance({
            Connector: 'Straight',
            Container: this.DialogID.toString()
        });
        this.Refresh();
    }

    Refresh() {
        if (this.jsPlumbInstance) {
            this.jsPlumbInstance.reset();
            if (this.StartContainer) {
                this.bindContainers(this.StartContainer);
            }
        }
    }

    Reset(args) {
        this.AllContainer = [];
        this.LostContainer = [];
        this.GridStyle = {
            'zoom': 1
        };
        this.StartContainer = null;
        this.SelectedContainer = null;
        this.SelectedContainerChanged.emit(null);
        this.InitArgsValue = null;
        this.LoadedRelations = new Map();
        this.Initialize(args);
        this.cdRef.detectChanges();
        if (this.grid && this.grid.nativeElement) {
            this.grid.nativeElement.scrollTop = 0;
        }
        this.Refresh();
    }

    Initialize(args) {
        if (args && args.Container && args.Relations) {
            this.InitArgsValue = {
                Container: args.Container,
                Relations: args.Relations,
                DataModelID: args.DataModelID
            };
            const containerRelMapping = new Map();
            const maxListID = {
                count: 0,
                id: null
            };
            args.Relations.forEach(r => {
                let list = containerRelMapping.get(r.FromContainerUniqueID);
                if (!list) {
                    list = [];
                    containerRelMapping.set(r.FromContainerUniqueID, list);
                }
                list.push(r);
                if (list.length > maxListID.count) {
                    maxListID.count = list.length;
                    maxListID.id = r.FromContainerUniqueID;
                }
                list = containerRelMapping.get(r.ToContainerUniqueID);
                if (!list) {
                    list = [];
                    containerRelMapping.set(r.ToContainerUniqueID, list);
                }
                list.push(r);
                if (list.length > maxListID.count) {
                    maxListID.count = list.length;
                    maxListID.id = r.ToContainerUniqueID;
                }
            });
            let firstCont;
            args.Container.forEach(c => {
                if (c.UniqueID === maxListID.id) {
                    firstCont = c;
                } else {
                    this.LostContainer.push(c);
                }
            });
            if (!firstCont && args.Container.length === 1) {
                firstCont = args.Container[0];
                this.LostContainer = [];
            }
            if (firstCont) {
                this.StartContainer = this.findRelationsForContainer(['l', 'r', 't', 'b'], firstCont, containerRelMapping, null);
                this.placeContainer(this.StartContainer, 1, 1);
                this.GridStyle['grid-template-columns'] = 'repeat(' + this.StartContainer.Width + ', 90px)';
                this.GridStyle['grid-template-rows'] = 'repeat(' + this.StartContainer.Height + ', 90px)';
            }
        }
    }

    findRelationsForContainer(directionList, container, relationMapping, actRel) {
        const contObj = {
            Container: container,
            Children: {
                Left: [],
                Right: [],
                Top: [],
                Bottom: []
            },
            Width: 1,
            Height: 1,
            InnerWidth: 1,
            InnerHeight: 1,
            LeftCont: {
                Height: 0,
                Width: 0
            },
            TopCont: {
                Height: 0,
                Width: 0
            },
            RightCont: {
                Height: 0,
                Width: 0
            },
            BottomCont: {
                Height: 0,
                Width: 0
            },
            Position: {
                Left: 0,
                Top: 0,
                Width: 1,
                Height: 1
            },
            IsSource: false,
            ID: this.DialogID + '_' + container.UniqueID,
            Relations: []
        };
        const relList = relationMapping.get(container.UniqueID);
        let leftMax = 0, rightMax = 0, topMax = 0, bottomMax = 0,
            leftHeight = 0, rightHeight = 0, topWidth = 0, bottomWidth = 0;
        if (relList) {
            let index = 0;
            relList.forEach(rle => {
                if (rle.Relation !== actRel) {
                    const isSource = rle.FromContainerUniqueID === container.UniqueID;
                    const contID = isSource ? rle.ToContainerUniqueID : rle.FromContainerUniqueID;
                    this.LostContainer.some((c, i) => {
                        if (c.UniqueID === contID) {
                            this.LostContainer.splice(i, 1);
                            contObj.Relations.push(rle.Relation);
                            const entrySide = directionList[index++ % directionList.length];
                            let childCont;
                            switch (entrySide) {
                                case 'l':
                                    childCont = this.findRelationsForContainer(['l', 't', 'b'], c, relationMapping, rle.Relation);
                                    childCont.IsSource = !isSource;
                                    if (childCont.Width > rightMax) {
                                        rightMax = childCont.Width;
                                    }
                                    rightHeight += childCont.Height;
                                    contObj.Children.Right.push(childCont);
                                    break;
                                case 'r':
                                    childCont = this.findRelationsForContainer(['r', 't', 'b'], c, relationMapping, rle.Relation);
                                    childCont.IsSource = !isSource;
                                    if (childCont.Width > leftMax) {
                                        leftMax = childCont.Width;
                                    }
                                    leftHeight += childCont.Height;
                                    contObj.Children.Left.push(childCont);
                                    break;
                                case 't':
                                    childCont = this.findRelationsForContainer(['t', 'l', 'r'], c, relationMapping, rle.Relation);
                                    childCont.IsSource = !isSource;
                                    if (childCont.Height > bottomMax) {
                                        bottomMax = childCont.Height;
                                    }
                                    bottomWidth += childCont.Width;
                                    contObj.Children.Bottom.push(childCont);
                                    break;
                                case 'b':
                                    childCont = this.findRelationsForContainer(['b', 'l', 'r'], c, relationMapping, rle.Relation);
                                    childCont.IsSource = !isSource;
                                    if (childCont.Height > topMax) {
                                        topMax = childCont.Height;
                                    }
                                    topWidth += childCont.Width;
                                    contObj.Children.Top.push(childCont);
                                    break;
                            }
                            return true;
                        }
                    });
                }
            });
        }
        if (actRel && contObj.Relations.length === 0) {
            contObj.Relations.push(actRel);
        }
        if (contObj.Children.Left.length > 1) {
            leftHeight += contObj.Children.Left.length - 1;
        }
        if (contObj.Children.Right.length > 1) {
            rightHeight += contObj.Children.Right.length - 1;
        }
        if (contObj.Children.Top.length > 1) {
            topWidth += contObj.Children.Top.length - 1;
        }
        if (contObj.Children.Bottom.length > 1) {
            bottomWidth += contObj.Children.Bottom.length - 1;
        }
        contObj.LeftCont.Height = leftHeight;
        contObj.LeftCont.Width = leftMax;
        contObj.TopCont.Height = topMax;
        contObj.TopCont.Width = topWidth;
        contObj.RightCont.Height = rightHeight;
        contObj.RightCont.Width = rightMax;
        contObj.BottomCont.Height = bottomMax;
        contObj.BottomCont.Width = bottomWidth;
        let innerWidth = Math.max(topWidth, bottomWidth);
        if (innerWidth <= 1) {
            innerWidth = 1;
            if (contObj.Children.Left.length > 0) {
                contObj.Position.Left++;
                innerWidth++;
            }
            if (contObj.Children.Right.length > 0) {
                innerWidth++;
            }
        } else {
            contObj.Position.Width = innerWidth;
        }
        contObj.InnerWidth = innerWidth;
        let innerHeight = Math.max(leftHeight, rightHeight);
        if (innerHeight <= 1) {
            innerHeight = 1;
            if (contObj.Children.Top.length > 0) {
                contObj.Position.Top++;
                innerHeight++;
            }
            if (contObj.Children.Bottom.length > 0) {
                innerHeight++;
            }
        } else {
            contObj.Position.Height = innerHeight;
        }
        contObj.InnerHeight = innerHeight;
        contObj.Width = Math.max(contObj.Width, leftMax + innerWidth + rightMax);
        contObj.Height = Math.max(contObj.Height, topMax + innerHeight + bottomMax);
        this.AllContainer.push(contObj);
        return contObj;
    }

    placeContainer(container, startCol, startRow) {
        container.Style = {
            'grid-column': (startCol + container.LeftCont.Width + container.Position.Left) + '/ span ' + container.Position.Width,
            'grid-row': (startRow + container.TopCont.Height + container.Position.Top) + '/ span ' + container.Position.Height
        };
        if (container.LeftCont.Height < container.InnerHeight) {
            let top = 0;
            const percent = container.InnerHeight / (container.LeftCont.Height - container.Children.Left.length + 1);
            container.Children.Left.forEach(child => {
                const newChildHeight = child.Height * percent;
                const diff = Math.floor((newChildHeight - child.Height) / 2);
                this.placeContainer(child, startCol + container.LeftCont.Width - child.Width,
                    startRow + container.TopCont.Height + top + diff);
                top += Math.floor(newChildHeight) + 1;
            });
        } else {
            let top = 0;
            container.Children.Left.forEach(child => {
                this.placeContainer(child, startCol + container.LeftCont.Width - child.Width, startRow + container.TopCont.Height + top);
                top += child.Height + 1;
            });
        }
        if (container.TopCont.Width < container.InnerWidth) {
            let left = 0;
            const percent = container.InnerWidth / (container.TopCont.Width - container.Children.Top.length + 1);
            container.Children.Top.forEach(child => {
                const newChildWidth = child.Width * percent;
                const diff = Math.floor((newChildWidth - child.Width) / 2);
                this.placeContainer(child, startCol + container.LeftCont.Width + left + diff,
                    startRow + container.TopCont.Height - child.Height);
                left += Math.floor(newChildWidth) + 1;
            });
        } else {
            let left = 0;
            container.Children.Top.forEach(child => {
                this.placeContainer(child, startCol + container.LeftCont.Width + left, startRow + container.TopCont.Height - child.Height);
                left += child.Width + 1;
            });
        }
        if (container.InnerHeight > container.RightCont.Height) {
            let top = 0;
            const percent = container.InnerHeight / (container.RightCont.Height - container.Children.Right.length + 1);
            container.Children.Right.forEach(child => {
                const newChildHeight = child.Height * percent;
                const diff = Math.floor((newChildHeight - child.Height) / 2);
                this.placeContainer(child, startCol + container.LeftCont.Width + container.InnerWidth,
                    startRow + container.TopCont.Height + top + diff);
                top += Math.floor(newChildHeight) + 1;
            });
        } else {
            let top = 0;
            container.Children.Right.forEach(child => {
                this.placeContainer(child, startCol + container.LeftCont.Width + container.InnerWidth,
                    startRow + container.TopCont.Height + top);
                top += child.Height + 1;
            });
        }
        if (container.InnerWidth > container.BottomCont.Width) {
            let left = 0;
            const percent = container.InnerWidth / (container.BottomCont.Width - container.Children.Bottom.length + 1);
            container.Children.Bottom.forEach(child => {
                const newChildWidth = child.Width * percent;
                const diff = Math.floor((newChildWidth - child.Width) / 2);
                this.placeContainer(child, startCol + container.LeftCont.Width + left + diff,
                    startRow + container.TopCont.Height + container.InnerHeight);
                left += Math.floor(newChildWidth) + 1;
            });
        } else {
            let left = 0;
            container.Children.Bottom.forEach(child => {
                this.placeContainer(child, startCol + container.LeftCont.Width + left,
                    startRow + container.TopCont.Height + container.InnerHeight);
                left += child.Width + 1;
            });
        }
    }

    bindContainers(container) {
        let percent = 1.0 / (container.Children.Left.length + 1);
        let index = 1;
        container.Children.Left.forEach(child => {
            const parentExitPoint = {
                anchor: [0, percent * index, -1, 0],
                enabled: false,
                endpoint: 'Blank',
                uuid: container.ID + '_' + child.ID,
            };
            this.jsPlumbInstance.addEndpoint(container.ID, parentExitPoint);
            const childEntryPoint = {
                anchor: [1, 0.5, 1, 0],
                enabled: false,
                endpoint: 'Blank',
                uuid: child.ID + '_' + container.ID,
            };
            this.jsPlumbInstance.addEndpoint(child.ID, childEntryPoint);
            index++;
            const options = {
                overlays: [['Arrow', { width: 10, length: 10 }]],
                uuids: [parentExitPoint.uuid]
            };
            if (child.IsSource) {
                options.uuids.splice(0, 0, childEntryPoint.uuid);
            } else {
                options.uuids.push(childEntryPoint.uuid);
            }
            this.jsPlumbInstance.connect(options);
            this.bindContainers(child);
        });
        percent = 1.0 / (container.Children.Top.length + 1);
        index = 1;
        container.Children.Top.forEach(child => {
            const parentExitPoint = {
                anchor: [percent * index, 0, 0, -1],
                enabled: false,
                endpoint: 'Blank',
                uuid: container.ID + '_' + child.ID,
            };
            this.jsPlumbInstance.addEndpoint(container.ID, parentExitPoint);
            const childEntryPoint = {
                anchor: [0.5, 1, 0, 1],
                enabled: false,
                endpoint: 'Blank',
                uuid: child.ID + '_' + container.ID,
            };
            this.jsPlumbInstance.addEndpoint(child.ID, childEntryPoint);
            index++;
            const options = {
                overlays: [['Arrow', { width: 10, length: 10 }]],
                uuids: [parentExitPoint.uuid]
            };
            if (child.IsSource) {
                options.uuids.splice(0, 0, childEntryPoint.uuid);
            } else {
                options.uuids.push(childEntryPoint.uuid);
            }
            this.jsPlumbInstance.connect(options);
            this.bindContainers(child);
        });
        percent = 1.0 / (container.Children.Right.length + 1);
        index = 1;
        container.Children.Right.forEach(child => {
            const parentExitPoint = {
                anchor: [1, percent * index, 1, 0],
                enabled: false,
                endpoint: 'Blank',
                uuid: container.ID + '_' + child.ID,
            };
            this.jsPlumbInstance.addEndpoint(container.ID, parentExitPoint);
            const childEntryPoint = {
                anchor: [0, 0.5, -1, 0],
                enabled: false,
                endpoint: 'Blank',
                uuid: child.ID + '_' + container.ID,
            };
            this.jsPlumbInstance.addEndpoint(child.ID, childEntryPoint);
            index++;
            const options = {
                overlays: [['Arrow', { width: 10, length: 10 }]],
                uuids: [parentExitPoint.uuid]
            };
            if (child.IsSource) {
                options.uuids.splice(0, 0, childEntryPoint.uuid);
            } else {
                options.uuids.push(childEntryPoint.uuid);
            }
            this.jsPlumbInstance.connect(options);
            this.bindContainers(child);
        });
        percent = 1.0 / (container.Children.Bottom.length + 1);
        index = 1;
        container.Children.Bottom.forEach(child => {
            const parentExitPoint = {
                anchor: [percent * index, 1, 0, 1],
                enabled: false,
                endpoint: 'Blank',
                uuid: container.ID + '_' + child.ID,
            };
            this.jsPlumbInstance.addEndpoint(container.ID, parentExitPoint);
            const childEntryPoint = {
                anchor: [0.5, 0, 0, -1],
                enabled: false,
                endpoint: 'Blank',
                uuid: child.ID + '_' + container.ID,
            };
            this.jsPlumbInstance.addEndpoint(child.ID, childEntryPoint);
            index++;
            const options = {
                overlays: [['Arrow', { width: 10, length: 10 }]],
                uuids: [parentExitPoint.uuid]
            };
            if (child.IsSource) {
                options.uuids.splice(0, 0, childEntryPoint.uuid);
            } else {
                options.uuids.push(childEntryPoint.uuid);
            }
            this.jsPlumbInstance.connect(options);
            this.bindContainers(child);
        });
    }

    async containerClicked(contObj) {
        if (contObj.NewTargetRelation) {
            if (this.SelectedContainer && this.InitArgsValue) {
                const actRel = RelSourceHelper.FindConnection(this.SelectedContainer.Container.UniqueID,
                    contObj.Container.UniqueID, this.InitArgsValue.Relations);
                const actID = actRel.FromContainerUniqueID === this.SelectedContainer.Container.UniqueID ?
                    actRel.ToContainerUniqueID : actRel.FromContainerUniqueID;
                let actCont;
                this.AllContainer.some(ac => {
                    if (ac.Container.UniqueID === actID) {
                        actCont = ac;
                        return true;
                    }
                    return false;
                });
                const text =
                    new TranslateFormatText('@@Wollen Sie die Verbindung von Container {0} zu Container {1} auf Container {2} aendern?');
                text.FormatParams.push(this.SelectedContainer.Container.Caption + ' (' + this.SelectedContainer.Container.UniqueID + ')');
                text.FormatParams.push(actCont.Container.Caption + ' (' + actCont.Container.UniqueID + ')');
                text.FormatParams.push(contObj.Container.Caption + ' (' + contObj.Container.UniqueID + ')');
                const result = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Verbindung aendern'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                if (result === MessageBoxResult.Yes) {
                    this.InitArgsValue.Relations.some((r, i) => {
                        if (r.Relation === actRel.Relation) {
                            this.InitArgsValue.Relations.splice(i, 1);
                            return true;
                        }
                        return false;
                    });
                    if (contObj.NewTargetRelation.PrimaryContainer === this.SelectedContainer.Container.SID) {
                        this.InitArgsValue.Relations.push({
                            FromContainerUniqueID: this.SelectedContainer.Container.UniqueID,
                            FromContainer: this.SelectedContainer.Container.SID,
                            FromDataSource: this.SelectedContainer.Container.DataSource,
                            ToContainerUniqueID: contObj.Container.UniqueID,
                            ToContainer: contObj.Container.SID,
                            ToDataSource: contObj.Container.DataSource,
                            Relation: contObj.NewTargetRelation.SID
                        });
                    } else {
                        this.InitArgsValue.Relations.push({
                            FromContainerUniqueID: contObj.Container.UniqueID,
                            FromContainer: contObj.Container.SID,
                            FromDataSource: contObj.Container.DataSource,
                            ToContainerUniqueID: this.SelectedContainer.Container.UniqueID,
                            ToContainer: this.SelectedContainer.Container.SID,
                            ToDataSource: this.SelectedContainer.Container.DataSource,
                            Relation: contObj.NewTargetRelation.SID
                        });
                    }
                    this.Reset(this.InitArgsValue);
                }
            }
        } else {
            this.AllContainer.forEach(c => {
                delete c.Style['background'];
                delete c.NewTargetRelation;
            });
            if (contObj === this.SelectedContainer) {
                this.SelectedContainer = null;
                this.SelectedContainerChanged.emit(null);
            } else {
                this.SelectedContainer = contObj;
                this.SelectedContainerChanged.emit(contObj.Container);
                this.SelectedContainer.Style['background'] = 'gray';
                if (this.InitArgsValue) {
                    this.checkLoadedRelations(contObj.Container.SID, (relations) => {
                        if (this.SelectedContainer) {
                            const sid = this.SelectedContainer.Container.SID;
                            relations.forEach(r => {
                                if (!this.InitArgsValue.Relations.some(rel => rel.Relation === r.SID)) {
                                    const other = r.PrimaryContainer === sid ? r.SecondaryContainer : r.PrimaryContainer;
                                    this.AllContainer.some(c => {
                                        if (c.Container.SID === other) {
                                            c.Style['background'] = 'rgb(66, 199, 86)';
                                            c.NewTargetRelation = r;
                                            return true;
                                        }
                                        return false;
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }
    }

    checkLoadedRelations(sid, func) {
        const rels = this.LoadedRelations.get(sid);
        if (rels) {
            func(rels);
        } else {
            this.service.GetRelationsForContainer(this.InitArgsValue.DataModelID, sid).subscribe(r => {
                    if (r) {
                        this.LoadedRelations.set(sid, r);
                        func(r);
                    }
                });
        }
    }

    onScroll(ev) {
        if (ev && ev.getModifierState('Control')) {
            const delta = ev.deltaY;
            if (typeof delta === 'number') {
                if (delta < 0) {
                    this.increaseZoom();
                } else {
                    this.decreaseZoom();
                }
            }
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    increaseZoom() {
        const zoomValue = this.GridStyle['zoom'] + 0.05;
        this.GridStyle['zoom'] = zoomValue;
        this.jsPlumbInstance.setZoom(zoomValue);
    }

    decreaseZoom() {
        let zoomValue = this.GridStyle['zoom'];
        if (zoomValue > 0.05) {
            zoomValue -= 0.05;
            this.GridStyle['zoom'] = zoomValue;
            this.jsPlumbInstance.setZoom(zoomValue);
        }
    }

    resetZoom() {
        this.GridStyle['zoom'] = 1;
        this.jsPlumbInstance.setZoom(1);
    }

    getZoomText() {
        return Math.round(this.GridStyle['zoom'] * 100);
    }

    deleteContainer() {
        if (this.SelectedContainer && this.InitArgsValue) {
            const id = this.SelectedContainer.Container.UniqueID;
            const targets = [];
            const newList = [];
            this.InitArgsValue.Relations.forEach(r => {
                if (r.FromContainerUniqueID === id) {
                    targets.push({
                        TargetSID: r.ToContainer,
                        TargetUnique: r.ToContainerUniqueID,
                        TargetDS: r.ToDataSource,
                        Container: [{
                            SID: r.ToContainer,
                            UniqueID: r.ToContainerUniqueID,
                            DS: r.ToDataSource
                        }]
                    });
                } else if (r.ToContainerUniqueID === id) {
                    targets.push({
                        TargetSID: r.FromContainer,
                        TargetUnique: r.FromContainerUniqueID,
                        TargetDS: r.FromDataSource,
                        Container: [{
                            SID: r.FromContainer,
                            UniqueID: r.FromContainerUniqueID,
                            DS: r.FromDataSource
                        }]
                    });
                } else {
                    newList.push(r);
                }
            });
            if (targets.length > 1) {
                const infoList = [];
                targets.forEach(t => {
                    t.Container.push(...this.fillLinkedUniqueIDs(t.TargetUnique, newList, null));
                    if (!infoList.some((e, i) => {
                        if (e.Container.length <= t.Container.length) {
                            infoList.splice(i, 0, t);
                            return true;
                        }
                        return false;
                    })) {
                        infoList.push(t);
                    }
                });
                const firstCont = infoList.splice(0, 1)[0];
                let added = true;
                while (added) {
                    added = false;
                    for (let i = 0; i < infoList.length; i++) {
                        const entry = infoList[i];
                        this.checkLoadedRelations(entry.TargetSID, (relations) => {
                            relations.some(r => {
                                let newEntry;
                                if (!newList.some(rel => rel.Relation === r.SID)) {
                                    if (r.PrimaryContainer === entry.TargetSID) {
                                        firstCont.Container.some(c => {
                                            if (c.SID === r.SecondaryContainer) {
                                                newEntry = {
                                                    FromContainerUniqueID: entry.TargetUnique,
                                                    FromContainer: entry.TargetSID,
                                                    FromDataSource: entry.TargetDS,
                                                    ToContainerUniqueID: c.UniqueID,
                                                    ToContainer: c.SID,
                                                    ToDataSource: c.DS,
                                                    Relation: r.SID
                                                };
                                                return true;
                                            }
                                            return false;
                                        });
                                    } else {
                                        firstCont.Container.some(c => {
                                            if (c.SID === r.PrimaryContainer) {
                                                newEntry = {
                                                    FromContainerUniqueID: c.UniqueID,
                                                    FromContainer: c.SID,
                                                    FromDataSource: c.DS,
                                                    ToContainerUniqueID: entry.TargetUnique,
                                                    ToContainer: entry.TargetSID,
                                                    ToDataSource: entry.TargetDS,
                                                    Relation: r.SID
                                                };
                                                return true;
                                            }
                                            return false;
                                        });
                                    }
                                }
                                if (newEntry) {
                                    added = true;
                                    newList.push(newEntry);
                                    firstCont.Container.push(...entry.Container);
                                    infoList.splice(i, 1);
                                    i--;
                                    return true;
                                }
                                return false;
                            });
                        });
                    }
                }
                if (infoList.length > 0) {
                    let text = '@@Loeschen des Containers nicht moeglich, ' +
                        'da die Verbindungen der folgenden Container nicht auf andere bestehende Container geaendert werden koennen' + ':';
                    infoList.forEach(entry => {
                        let cont;
                        this.AllContainer.some(ac => {
                            if (ac.Container.UniqueID === entry.TargetUnique) {
                                cont = ac.Container;
                                return true;
                            }
                            return false;
                        });
                        text += '\n' + cont.Caption + ' (' + cont.UniqueID + ')';
                    });
                    MessageBoxHelper.ShowDialog(new TranslateFormatText(text),
                        new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    return;
                }
            }
            this.InitArgsValue.Container.some((c, i) => {
                if (c.UniqueID === id) {
                    this.InitArgsValue.Container.splice(i, 1);
                    return true;
                }
                return false;
            });
            this.InitArgsValue.Relations.splice(0);
            this.InitArgsValue.Relations.push(...newList);
            this.Reset(this.InitArgsValue);
        }
    }

    fillLinkedUniqueIDs(uniqueID, relations, actRel) {
        const retVal = [];
        relations.forEach(r => {
            if (r.Relation !== actRel) {
                if (r.FromContainerUniqueID === uniqueID) {
                    retVal.push({
                        SID: r.ToContainer,
                        UniqueID: r.ToContainerUniqueID,
                        DS: r.ToDataSource
                    });
                    const childIDs = this.fillLinkedUniqueIDs(r.ToContainerUniqueID, relations, r.Relation);
                    retVal.push(...childIDs);
                } else if (r.ToContainerUniqueID === uniqueID) {
                    retVal.push({
                        SID: r.FromContainer,
                        UniqueID: r.FromContainerUniqueID,
                        DS: r.FromDataSource
                    });
                    const childIDs = this.fillLinkedUniqueIDs(r.FromContainerUniqueID, relations, r.Relation);
                    retVal.push(...childIDs);
                }
            }
        });
        return retVal;
    }
}
