import { Component } from '@angular/core';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-unpivot-settings',
    templateUrl: './unpivot.settings.html',
    styleUrls: ['./unpivot.settings.css']
})
export class UnpivotSettings extends WorkflowDialogContent {
    UnpivotCaption: string;
    ShowColumnCaptions: boolean;
    ColumnCaption: string;
    Columns = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'unpivotWFModule';
        reg.Caption = '@@Unpivot';
        reg.GroupID = 'reldataoperations';
        reg.Index = 140;
        reg.SettingsControl = UnpivotSettings;
        reg.SettingsTypeHelper = new UnpivotSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const ds = this.ActualState.get('DataSource');
        const columns = [];
        const colDict = new Map();
        if (ds && ds.Columns) {
            ds.Columns.forEach(col => {
                const pivCol = {
                    Caption: col.Name + ' (' + col.DataTyp + ')',
                    ColumnName: col.Name,
                    Type: col.DataTyp,
                    Status: 0
                };
                columns.push(pivCol);
                colDict.set(pivCol.ColumnName, pivCol);
            });
        }
        this.Columns = columns;
        if (data) {
            this.UnpivotCaption = data.UnpivotCaption;
            this.ShowColumnCaptions = data.ShowColumnCaptions;
            this.ColumnCaption = data.ColumnCaption;
            data.OriginalColumns.forEach(uc => {
                const col = colDict.get(uc);
                if (col) {
                    col.Status = 2;
                }
            });
            data.UnpivotColumns.forEach(uc => {
                const col = colDict.get(uc);
                if (col) {
                    col.Status = 1;
                }
            });
        }
    }

    getResult() {
        const retVal = new UnpivotSettingsData();
        retVal.UnpivotCaption = this.UnpivotCaption;
        retVal.ShowColumnCaptions = this.ShowColumnCaptions;
        retVal.ColumnCaption = this.ColumnCaption;
        this.Columns.forEach(col => {
            if (col.Status === 1) {
                retVal.UnpivotColumns.push(col.ColumnName);
            } else if (col.Status === 2) {
                retVal.OriginalColumns.push(col.ColumnName);
            }
        });
        return retVal;
    }

    checkData() {
        const retVal = new DataCheck();
        let type;
        let count = 0;
        this.Columns.some(col => {
            count++;
            if (col.Status === 1) {
                if (!type) {
                    type = col.Type;
                } else {
                    if (col.Type !== type) {
                        retVal.IsCorrect = false;
                        retVal.Error = '@@Unpivot-Spalten muessen denselben Datentyp besitzen.';
                        return true;
                    }
                }
            }
            return false;
        });
        if (retVal.IsCorrect && count < 2) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Unpivot-Spalten muessen denselben Datentyp besitzen.';
        }
        return retVal;
    }

    setStatus(ev, col, status) {
        if (ev.checked) {
            col.Status = status;
        } else {
            col.Status = 0;
        }
    }

    selectAll(ev, status) {
        if (ev) {
            if (ev.checked) {
                this.Columns.forEach(col => {
                    col.Status = status;
                });
            } else {
                this.Columns.forEach(col => {
                    if (col.Status === status) {
                        col.Status = 0;
                    }
                });
            }
        }
    }
}

export class UnpivotSettingsData extends AServiceWorkflowData {
    OriginalColumns: string[] = [];
    UnpivotColumns: string[] = [];
    UnpivotCaption: string;
    ShowColumnCaptions: boolean;
    ColumnCaption: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.Unpivot.UnpivotSettingsData';
    }
}

export class UnpivotSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new UnpivotSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && ds.Columns && settings) {
                const colDict = new Map();
                ds.Columns.forEach(col => {
                    colDict.set(col.Name, col);
                });
                const newCols = [];
                if (settings.OriginalColumns) {
                    settings.OriginalColumns.forEach(oc => {
                        const col = colDict.get(oc);
                        if (col) {
                            newCols.push(col);
                        }
                    });
                }
                if (settings.ShowColumnCaptions === true) {
                    let caption = '';
                    if (settings.ColumnCaption) {
                        caption = settings.ColumnCaption;
                    }
                    newCols.push({ Name: caption, DataTyp: 'System.String' });
                }
                let pivCol;
                if (settings.UnpivotColumns) {
                    settings.UnpivotColumns.some(oc => {
                        pivCol = colDict.get(oc);
                        if (pivCol) {
                            return true;
                        }
                        return false;
                    });
                }
                let colCaption = '';
                if (settings.UnpivotCaption) {
                    colCaption = settings.UnpivotCaption;
                }
                const newCol = {
                    Name: colCaption,
                    DataTyp: null
                };
                if (pivCol) {
                    newCol.DataTyp = pivCol.DataTyp;
                }
                ds.Columns = newCols;
            }
        }
    }
}
