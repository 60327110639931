import { SideNavMode } from '../enums/sidenavmode.enum';
import { SideNavPosition } from '../enums/sidenavposition.enum';
import { LayoutBase } from '../layoutbase.model';
import { LayoutUnit } from '../basic/layoutunit.model';
import { UnitType } from '../enums/unittype.enum';

export class SideNav extends LayoutBase {
    Mode: SideNavMode = SideNavMode.over;
    Position: SideNavPosition = SideNavPosition.start;

    static GetDefault(): SideNav {
        const sideNav = new SideNav();
        sideNav.Mode = SideNavMode.push;
        sideNav.Width = new LayoutUnit();
        sideNav.Width.Value = 250;
        sideNav.Width.Type = UnitType.Pixel;
        sideNav.Height = new LayoutUnit();
        return sideNav;
    }
}
