import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class CapsuleService extends RequestBase {
    BasePath = 'api/capsule';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetCapsuleTags(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetCapsuleTags', this.options);
    }

    GetOverriddenInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetOverriddenInfos', this.options);
    }

    DeleteOverridden(request): Observable<any> {
        const options = {
            headers: this.headers,
            body: request
        };
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteOverridden', options);
    }
}
