import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class TranslateFormula extends AFormula {
    static StaticID = 'aba5ad2b-3a81-4fd8-bc90-6ea891207497';

    ID(): string {
        return TranslateFormula.StaticID;
    }

    Name(): string {
        return 'Translate';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Text uebersetzen');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1006;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Uebersetzen') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        retVal.Value = '';
        if (args && args.length > 0) {
            const val = args[0].Value;
            if (typeof val !== 'undefined' && val !== null) {
                retVal.Value = TranslateHelper.TranslatorInstance.instant('' + val);
            }
        }
        return retVal;
    }
}
