import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'timespan-control',
    templateUrl: './timespan.control.html',
    styleUrls: ['./timespan.control.css']
})
export class TimeSpanControl implements OnInit {

    MilliSeconds = 0;
    Seconds = 0;
    Minutes = 0;
    Hours = 0;
    Days = 0;
    Internal = false;

    private static MilliDivisor = 1000;
    private static SecDivisor = TimeSpanControl.MilliDivisor * 60;
    private static MinDivisor = TimeSpanControl.SecDivisor * 60;
    private static HourDivisor = TimeSpanControl.MinDivisor * 24;

    //#region TimeSpan
    TimeSpanValue;

    @Input()
    get TimeSpan() {
        return this.TimeSpanValue;
    }
    set TimeSpan(val) {
        if (val != this.TimeSpanValue) {
            this.TimeSpanValue = val;
            this.updateValues();
            this.TimeSpanChange.emit(this.TimeSpanValue);
        }
    }

    @Output() TimeSpanChange = new EventEmitter<any>();
    //#endregion

    //#region ShowMilliseconds
    ShowMillisecondsValue = false;

    @Input()
    get ShowMilliseconds() {
        return this.ShowMillisecondsValue;
    }
    set ShowMilliseconds(val) {
        this.ShowMillisecondsValue = val;
    }
    //#endregion

    //#region Caption
    CaptionValue;

    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
    }
    //#endregion

    //#region Horizontal
    HorizontalValue = true;

    @Input()
    get Horizontal() {
        return this.HorizontalValue;
    }
    set Horizontal(val) {
        this.HorizontalValue = val;
    }
    //#endregion

    //#region Editable
    EditableValue;
    @Input()
    get _Editable() {
        return this.EditableValue;
    }
    set _Editable(val) {
        this.EditableValue = val;
    }
    //#endregion

    ngOnInit(): void {
        this.updateValues();
    }

    updateValues() {
        if (!this.Internal && typeof this.TimeSpanValue === 'number') {
            this.Days = Math.floor(this.TimeSpanValue / TimeSpanControl.HourDivisor);
            let remaining = this.TimeSpanValue % TimeSpanControl.HourDivisor;
            this.Hours = Math.floor(remaining / TimeSpanControl.MinDivisor);
            remaining = remaining % TimeSpanControl.MinDivisor;
            this.Minutes = Math.floor(remaining / TimeSpanControl.SecDivisor);
            remaining = remaining % TimeSpanControl.SecDivisor;
            this.Seconds = Math.floor(remaining / TimeSpanControl.MilliDivisor);
            this.MilliSeconds = remaining % TimeSpanControl.MilliDivisor;
        }
        if (!this.TimeSpanValue || typeof this.TimeSpanValue !== 'number') {
            this.Days = 0;
            this.Hours = 0;
            this.Minutes = 0;
            this.Seconds = 0;
            this.MilliSeconds = 0;
        }
    }

    @Output() TimeSpanHasChanged = new EventEmitter<any>();
    onChange() {
        this.Internal = true;
        this.TimeSpan = this.Days * TimeSpanControl.HourDivisor + this.Hours * TimeSpanControl.MinDivisor +
            this.Minutes * TimeSpanControl.SecDivisor + this.Seconds * TimeSpanControl.MilliDivisor + this.MilliSeconds;
        this.TimeSpanHasChanged.emit();
        this.Internal = false;
    }
}