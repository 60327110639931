export enum KeyFilter {
    pint,
    int,
    pnum,
    money,
    num,
    hex,
    email,
    alpha,
    alhpanum,
    regex
}