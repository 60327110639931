import { HttpClient } from '@angular/common/http';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { DynamicDataService } from '../../../services/dynamicdata.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { ElementValueSettings, ElementValueSettingsData } from '../layout/elemValue/elemValue.settings';

export class DeleteDataWFSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new ElementValueSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const DeleteDataWF = new WorkflowExitInfo();
        DeleteDataWF.ID = 0;
        DeleteDataWF.Label = '@@Success';
        const end = new WorkflowExitInfo();
        end.ID = 1;
        end.Label = '@@Error';
        return [DeleteDataWF, end];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class DeleteDataWFModuleExecuter extends WorkflowModuleExecuter {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'DeleteDataWFWFModule';
        reg.Caption = '@@DeleteItem';
        reg.GroupID = 'dataoperations';
        reg.Index = 20;
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new DeleteDataWFSettingsDataHelper();
        reg.Executer = DeleteDataWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        if (elem.Element.DataSource) {
                            const DataSourceId = MetaHelper.FindDataSourceID(status.WorkflowLayoutService.Layout, elem);
                            if (DataSourceId) {
                                const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
                                const service = new DynamicDataService(http);
                                const result = await service.DeleteEntry(DataSourceId, elem.Element.DataSource).toPromise();
                                if (result == null) {
                                    status.ActualParameter = elem.Element.DataSource;
                                    return 0;
                                } else {
                                    status.Logger.logError('DeleteDataWF modul: Data could not be deleted. (' + result + ')');
                                    return 1;
                                }
                            } else {
                                status.Logger.logError('DeleteDataWF modul: No DataSource set.');
                            }
                        } else {
                            status.Logger.logError('DeleteDataWF modul: No Data found.');
                        }
                    } else {
                        status.Logger.logError('DeleteDataWF modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('DeleteDataWF modul: Element not found.');
                }
            } else {
                status.Logger.logError('DeleteDataWF modul: No element name found.');
            }
        } else {
            status.Logger.logError('DeleteDataWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
