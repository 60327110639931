import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-anomaly-detection-settings',
    templateUrl: './anomaly.detection.settings.html',
    styleUrls: ['./anomaly.detection.settings.css']
})
export class AnomalyDetectionSettings extends WorkflowDialogContent {
    Data = new AnomalyDetectionSettingsData();
    Columns = [];

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'anomalyDetectionWFModule';
        reg.Caption = '@@Anomaly Detection';
        reg.GroupID = 'aioperations';
        reg.Index = 160;
        reg.SettingsControl = AnomalyDetectionSettings;
        reg.SettingsTypeHelper = new AnomalyDetectionSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private translate: TranslateService) {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        const list = [];
        if (dataSource) {
            dataSource.Columns.forEach(col => {
                list.push({
                    Name: col.Name
                });
            });
        }
        this.Columns = list;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(AnomalyDetectionSettingsData, json);
        }
    }

    getResult() {
        if (!this.Data.DateColumn) {
            this.Data.Augments = 0;
            this.Data.Rounding = null;
        }
        return this.Data;
    }

    sensitivityChanged(ev) {
        if (ev && ev.value) {
            this.Data.Sensitivity = ev.value;
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Columns.length > 0 && !this.Data.ValueColumn) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie eine Wertspalte';
        }
        return retVal;
    }

    GetExpressionProperties() {
        return [
            {
                Caption: this.translate.instant('@@Datumsspalte'),
                Value: 'DateColumn'
            },
            {
                Caption: this.translate.instant('@@Wertspalte'),
                Value: 'ValueColumn'
            },
            {
                Caption: this.translate.instant('@@Datums-Ebene'),
                Value: 'Rounding'
            },
            {
                Caption: this.translate.instant('@@Zusaetzliche Spalten'),
                Value: 'Augments'
            },
            {
                Caption: this.translate.instant('@@Datenzeitraum in Tagen'),
                Value: 'Days'
            },
            {
                Caption: this.translate.instant('@@Vorhersagezeitraum in Tagen'),
                Value: 'DaysOut'
            },
            {
                Caption: this.translate.instant('@@Sensitivitaet'),
                Value: 'Sensitivity'
            }
        ];
    }
}

export class AnomalyDetectionSettingsData extends AServiceWorkflowData {
    DateColumn: string;
    ValueColumn: string;
    Rounding: string;
    Augments: number;
    Days = 2;
    DaysOut = 1;
    Sensitivity = 3;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.AnomalyDetection.AnomalyDetectionSettingsData';
    }
}

export class AnomalyDetectionSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new AnomalyDetectionSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state && module && module.Settings) {
            const ds = state.get('DataSource');
            if (ds) {
                ds.Columns = [
                    {
                        Name: 'AnomalyDates',
                        DataTyp: 'System.DateTime'
                    }
                ];
            }
        }
    }
}
