import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VariableHelper } from '../helpers/variable.helper';
import { RequestBase } from './request-base';

@Injectable()
export class UserSettingsService extends RequestBase {
    BasePath = "api/usersettings";

    constructor(http: HttpClient) {
        super(http);
    }

    ReadByID(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByID?id=' + id, this.options);
    }
    ReadByLayout(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByLayout?id=' + id, this.options);
    }
    ReadByLayoutProfile(layoutid: string, profilename: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByLayoutProfile?layoutid=' + layoutid + '&name=' + profilename, this.options);
    }
    
    AddOrUpdate(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdate', data, this.options);
    }

    Remove(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Remove?id=' + id, this.options);
    }
    RemoveByLayout(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveByLayout?id=' + id, this.options);
    }
    RemoveByLayoutProfile(layoutid: string, profilename: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveByLayoutProfile?layoutid=' + layoutid + '&name=' + profilename, this.options);
    }

    RemoveByLayoutAndItemAuto(layoutid: string, itemid:string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveByLayoutAndItemAuto?layoutid=' + layoutid + '&itemid=' + itemid, this.options);
    }
    RemoveByLayoutAndItemProfile(layoutid: string, itemid:string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveByLayoutAndItemProfile?layoutid=' + layoutid + '&itemid=' + itemid, this.options);
    }

    ReadProfileByLayout(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadProfileByLayout?id=' + id, this.options);
    }
    CreateProfile(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CreateProfile', data, this.options);
    }
    DeleteProfile(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteProfile?id=' + id, this.options);
    }
    DeleteProfileAndValues(layoutid: string, name: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteProfileAndValues?layoutid=' + layoutid + '&name=' + name, this.options);
    }

    SaveValues(profileVariables: any[], layoutID: string, profileName: string) {
        if (profileVariables) {
            profileVariables.forEach((item) => {
                const availableVariables = VariableHelper.AvailableVariables[layoutID];
                if (availableVariables) {
                    const toSave = availableVariables.find((value) => value.ID == item.ID);
                    if (toSave) {
                        this.AddOrUpdate({
                            LayoutID: layoutID,
                            Value: JSON.stringify(toSave.Value),
                            ItemID: toSave.ID,
                            Profile: profileName
                        }).subscribe();
                    }
                }
                const availableControls = VariableHelper.AvailableControls[layoutID];
                if (availableControls) {
                    const toSave = availableControls.find((value) => value.ID == item.ID);
                    if (toSave && toSave.Element) {
                        this.AddOrUpdate({
                            LayoutID: layoutID,
                            Value: JSON.stringify(toSave.Element.GetUserSpecificValue()),
                            ItemID: toSave.ID,
                            Profile: profileName
                        }).subscribe();
                    }
                }
            });
        }
    }

    ChangeProfile(layoutID: string, profileName: string) {
        return new Promise<void>((resolve, reject) => {
            VariableHelper.ActiveVariables = null;
            VariableHelper.ActiveVariablesMap = {};
            this.ReadByLayoutProfile(layoutID, profileName).subscribe((variables) => {
                if (variables) {
                    VariableHelper.ActiveVariables = variables;
                    variables.forEach((item) => {
                        if (item.Value) {
                            item.Value = JSON.parse(item.Value);
                        }
                        VariableHelper.ActiveVariablesMap[item.LayoutID + '||' + item.ItemID] = item;
                        const availableVariables = VariableHelper.AvailableVariables[layoutID];
                        if (availableVariables) {
                            const toLoad = availableVariables.find((value) => value.ID == item.ItemID);
                            if (toLoad) {
                                const varSub = VariableHelper.VariableSubscriptions[item.LayoutID + '||' + item.ItemID];
                                if (varSub) {
                                    varSub.next(item.Value)
                                }
                                toLoad.Value = item.Value;
                            }
                        }
                        const availableControls = VariableHelper.AvailableControls[layoutID];
                        if (availableControls) {
                            const toLoad = availableControls.find((value) => value.ID == item.ItemID);
                            if (toLoad && toLoad.Element) {
                                toLoad.Element.SetUserSpecificValue(item.Value);
                            }
                        }
                    })
                }
                resolve();
            });
        });
    }
}
