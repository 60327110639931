import { Column } from '../../basic/column.model';
import { Row } from '../../basic/row.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { IPlanningTaskExecuter } from '../atask.model';

export class PlanningHeaderFormattingTaskExecuter implements IPlanningTaskExecuter {
    static TaskID = 'planningheaderstyletask';

    LevelUniqueID = -1;
    Style: CellStyle;
    Merger: StyleMerger;

    Init(settings: any, Rows: Row[], Columns: Column[], context: any) {
        this.Merger = StyleMerger.GetStyleMerger(context);
        if (settings) {
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
    }

    Execute() {
        if (this.Style && this.Merger) {
            this.Merger["DefaultHeaderStyle"] = this.Merger.MergeStyle(parseInt(this.Merger["DefaultHeaderStyle"]), this.Style);
        }
    }
}
