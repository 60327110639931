import { Component, EventEmitter, Input, Output } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { EnumHelper } from '../../helpers/enum.helper';
import { DivisorTypes, NumberFormat } from '../../models/styling/number.format.model';

@Component({
    selector: 'number-format-panel',
    templateUrl: './number.format.panel.html',
    styles: [
        '.buttonSpan { line-height: normal; font-size: 10px; }',
        '.buttonStack > * { width: 64px; text-align: center!important; }'
    ]
})
export class NumberFormatPanel {
    SuffixValues;
    PraefixList;
    SuffixList;
    InternalNumberFormat = new NumberFormat();

    //#region NumberFormat
    NumberFormatValue: NumberFormat;
    @Input()
    get NumberFormat() {
        return this.NumberFormatValue;
    }
    set NumberFormat(val) {
        if (val !== this.NumberFormatValue) {
            this.NumberFormatValue = val;
            if (val) {
                this.InternalNumberFormat = deserialize(NumberFormat, serialize(val));
            } else {
                this.InternalNumberFormat = new NumberFormat();
            }
            this.NumberFormatChange.emit(this.NumberFormatValue);
        }
    }
    @Output() NumberFormatChange = new EventEmitter<any>();
    //#endregion

    @Output() NumberFormatChanged = new EventEmitter<NumberFormat>();

    constructor() {
        this.SuffixValues = EnumHelper.GetDropdownValues(DivisorTypes);
        this.PraefixList = ['(', '↑', '↓', '→', '←'];
        this.SuffixList = ['€','$',')', '↑', '↓', '→', '←'];
    }

    percentChanged(ev) {
        if (ev && ev.source) {
            this.InternalNumberFormat.Suffix = ev.source.checked ? DivisorTypes.Percent : DivisorTypes.None;
            this.onSuffixEnumChange();
        }
    }

    separatorChanged(ev) {
        if (ev && ev.source) {
            this.checkNumberFormat(() => {
                this.InternalNumberFormat.ShowNumberGroupSeperator = ev.source.checked;
                this.NumberFormatValue.ShowNumberGroupSeperator = ev.source.checked;
            });
        }
    }

    addDigit() {
        this.checkNumberFormat(() => {
            this.InternalNumberFormat.DecimalDigits++;
            this.NumberFormatValue.DecimalDigits++;
        });
    }

    removeDigit() {
        if (this.InternalNumberFormat.DecimalDigits > 0) {
            this.checkNumberFormat(() => {
                this.InternalNumberFormat.DecimalDigits--;
                this.NumberFormatValue.DecimalDigits--;
            });
        }
    }

    onSuffixEnumChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.Suffix = this.InternalNumberFormat.Suffix;
            switch (this.NumberFormatValue.Suffix) {
                case DivisorTypes.Percent:
                    this.InternalNumberFormat.Divisor = 0.01;
                    this.NumberFormatValue.Divisor = 0.01;
                    break;
                case DivisorTypes.Thousand:
                    this.InternalNumberFormat.Divisor = 1000;
                    this.NumberFormatValue.Divisor = 1000;
                    break;
                case DivisorTypes.HundredThousand:
                    this.InternalNumberFormat.Divisor = 100000;
                    this.NumberFormatValue.Divisor = 100000;
                    break;
                case DivisorTypes.Million:
                    this.InternalNumberFormat.Divisor = 1000000;
                    this.NumberFormatValue.Divisor = 1000000;
                    break;
            }
        });
    }

    onDivisorChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.Divisor = this.InternalNumberFormat.Divisor;
        });
    }

    onSignChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.ChangeSign = this.InternalNumberFormat.ChangeSign;
        });
    }

    onPraefixChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.CustomPraefix = this.InternalNumberFormat.CustomPraefix;
        });
    }

    setPraefix(p) {
        this.InternalNumberFormat.CustomPraefix = p;
        this.onPraefixChange();
    }

    onSuffixChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.CustomSuffix = this.InternalNumberFormat.CustomSuffix;
        });
    }

    setSuffix(p) {
        this.InternalNumberFormat.CustomSuffix = p;
        this.onSuffixChange();
    }

    onSpacesChange() {
        this.checkNumberFormat(() => {
            this.NumberFormatValue.ChangeSign = this.InternalNumberFormat.ChangeSign;
        });
    }

    checkNumberFormat(action: () => void) {
        if (!this.NumberFormatValue) {
            this.NumberFormatValue = new NumberFormat();
        }
        action();
        this.NumberFormatChanged.emit(this.NumberFormatValue);
    }
}
