
import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { DatadescriptionSaveObject } from '../../models/datadescription/datadescriptiondata.model';
import { TranslatedString } from '../../models/translatedstring.model';
import { DatadescriptionService } from '../../services/datadescription.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'datadescription-settings',
    templateUrl: '../base.list.settings.html'
})
export class DatadescriptionSettings extends BaseListSettings {
 
    constructor(protected fs: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: DatadescriptionService) {
        super(fs, cdRef);
        this.initWF();
    }

    getContentType() {
        return DatadescriptionDetail;
    }

    initWF() {
       
    }

  loadList(handler) {
    const getFunc = this.service.GetAllDatadescriptions();
        getFunc.subscribe((result) => {
            if (result) {
                const list = [];
                result.forEach(function (item) {
                    list.push({
                        Caption: item.Name,
                        ID: item.Id
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.service.GetDatadescription(data).subscribe(result => {
            if (result) {
                const wf = plainToClass(DatadescriptionSaveObject, result);
                this.setSelectedItem(wf);
            }
        });
    }

    getNewItem() {
        const Datadescription = new DatadescriptionSaveObject();
        Datadescription.Caption = new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer Datadescription'));
        return Datadescription;
    }


    getDeleteText(sel): DeleteTexts {
        const caption = TranslatedString.GetTranslation(sel.Caption);
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Datadescription \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(caption);
        retVal.Success = new TranslateFormatText('@@Datadescription \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Datadescription loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const caption = TranslatedString.GetTranslation(sel.Caption);
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Datadescription \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Datadescription speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteDatadescription(data).subscribe((res) => {
            handler(res);
        });
    }

  saveInternal(item, handler) {
    const saveFunc = this.service.SaveDatadescription(item);
        saveFunc.subscribe(result => {
            if (result) {
                handler(result, result.Id, result.Name);
            }
        });
    }

    handleNew(item, result) {
        if (!item.SID) {
            item.SID = result.Id;
        }
        if (!item.Version) {
            item.Version = result.Version;
        }
  }

  //static GetSettingsEntry() {
  //  return {
  //    Caption: '@@Datadescription',
  //    ID: 'datadescription',
  //    Icon: 'datadescription',
  //    Index: 3,
  //    Parent: 'data',
  //    Content: DatadescriptionSettings
  //  }
  //}
}

//@BaseListComponent('Datadescription-template-settings')
//export class DatadescriptionTemplateSettings extends DatadescriptionSettings {
//  static GetSettingsEntry() {
//    return {
//      Caption: '@@Datadescription',
//      ID: 'datadescription',
//      Icon: 'datadescription',
//      Index: 3,
//      Parent: 'data',
//      Content: GlobalVariableSettings
//    }
//  }
//    initWF() {
      
//    }
//}

@Component({
    selector: 'datadescription-detail',
    templateUrl: './datadescription.settings.html',
    styleUrls: ['./datadescription.settings.css']
})
export class DatadescriptionDetail extends BaseListDetail {

    constructor(cdRef: ChangeDetectorRef) {
        super();
    }

    setSelectedItem(item) {
        if (this.SelectedItem && item) {
            this.SelectedItem = null;
            //this.cdRef.detectChanges();
        }
        super.setSelectedItem(item);
    }

    CaptionChanged(caption) {
        if (this.SelectedItem) {
            this.SelectedItem.Caption = caption;
            this.OnItemChanged();
        }
    }

    DescriptionChanged(description) {
        if (this.SelectedItem) {
            this.SelectedItem.Description = description;
            this.OnItemChanged();
        }
    }
}
