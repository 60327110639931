import { PipeTransform, Pipe } from '@angular/core';

export class ArrayHelpers {
    static removeProps(obj, keys) {
        if (obj instanceof Array) {
            obj.forEach(function (item) {
                ArrayHelpers.removeProps(item, keys)
            });
        }
        else if (typeof obj === 'object') {
            Object.getOwnPropertyNames(obj).forEach(function (key) {
                if (keys.indexOf(key) !== -1) delete obj[key];
                else ArrayHelpers.removeProps(obj[key], keys);
            });
        }
    }
    static removeChild(item, array, property) {
        if (array.indexOf(item) > -1) {
            array.splice(array.indexOf(item), 1);
        } else {
            array.forEach((sub) => {
                if (sub[property] && sub[property].length > -1) {
                    ArrayHelpers.removeChild(item, sub[property], property);
                }
            });
        }
    }
    static sortAlphabetical(list: any[], propName?: string) {
        if (propName) {
            list.sort((a, b) => {
                const aVal = ('' + a[propName]).toLowerCase();
                const bVal = ('' + b[propName]).toLowerCase();
                if (aVal < bVal) {
                    return -1;
                }
                if (aVal > bVal) {
                    return 1;
                }
                return 0;
            });
        } else {
            list.sort((a, b) => {
                const aVal = ('' + a).toLowerCase();
                const bVal = ('' + b).toLowerCase();
                if (aVal < bVal) {
                    return -1;
                }
                if (aVal > bVal) {
                    return 1;
                }
                return 0;
            });
        }
    }
}

export class StringHelper {
    static format(text: string, args: string[]): string {
        var retVal = text;
        for (var i = args.length - 1; i >= 0; i--) {
            retVal = retVal.replace(new RegExp('\\{' + i + '\\}', 'gm'), args[i]);
        }
        return retVal;
    }
}

@Pipe({ name: 'format' })
export class FormatPipe implements PipeTransform {
    transform(value: string, args: string[]): string {
        if (value && args) {
            return StringHelper.format(value, args);
        }
        return '';
    }
}

export class TranslateFormatText {
    TranslateText: string;
    FormatParams: string[] = [];

    constructor(text: string) {
        this.TranslateText = text;
    }
}
