import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { DialogType } from '../../../models/enums/dialogtype.enum';
import { Layout } from '../../../models/layout.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
    selector: 'content-dialog',
    templateUrl: 'content.dialog.html',
    styleUrls: ['./content.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentDialog implements OnInit, OnDestroy {
    
    DataSource;
    DialogData;
    LayoutElement;
    TempLayout;
    TempParameters;
    InternalChange = false;
    ParamSub;
    LayoutSub;
    constructor(dialogRef: MatDialogRef<ContentDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private service: MetaService) {
        this.DialogData = data;
        dialogRef.disableClose = false;
    }
    //#region Layout
    LayoutValue;

    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.LayoutChange.emit(this.LayoutValue);
    }

    @Output() LayoutChange = new EventEmitter<any>();
  //#endregion
    ngOnInit(): void {
        if (this.DialogData) {
            if (!this.DialogData.ActualSettings && this.DialogData.Type == DialogType.Content) {
                this.LayoutSub = LayoutService.SelectedLayout.subscribe(x => {
                    if (!this.InternalChange) {
                        this.TempLayout = x;
                    }
                });
                this.ParamSub = NavigationService.Params.subscribe(x => {
                    if (!this.InternalChange) {
                        this.TempParameters = x;
                    }
                });
                const params = {};
                if (this.DialogData.Parameters && Array.isArray(this.DialogData.Parameters)) {
                    if (this.TempLayout instanceof Layout) {
                        this.DialogData.Parameters.forEach((param) => {
                            if (!param['Manual']) {
                                var elem = LayoutHelper.getElementByName(this.TempLayout.Elements, param['Value']);
                                if (elem && elem.Element) {
                                    params[param['Name']] = elem.Element.DataSource;
                                }
                            } else {
                                params[param['Name']] = param['Value'];
                            }
                        });
                    }
                }
                this.service.LoadPage(this.DialogData.URL).subscribe(result => {
                    if (result) {
                        this.LayoutElement = plainToClass(Layout, result);
                        this.InternalChange = true;
                        LayoutService.SelectedLayout.next(this.LayoutElement);
                        NavigationService.Params.next(params);
                        this.InternalChange = false;
                    }
                });
            }
            if (this.DialogData.ActualSettings && this.DialogData.ActualSettings.Type == DialogType.Template) {
                if (this.DialogData.ActualSettings.Template) {
                    this.service.LoadTemplate(this.DialogData.ActualSettings.Template).subscribe((result) => {
                        if (result) {
                            this.DataSource = null;
                            this.LayoutElement = plainToClass(Layout, result);
                            this.Layout = this.LayoutElement;
                            this.LayoutElement.Elements[0].ElementSet.subscribe(() => {
                                setTimeout(() => {
                                    this.LayoutElement.Elements[0].Element.DataSource = this.DialogData.ActualParameter;
                                }, 100);
                            });
                        }
                    });
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.LayoutSub) {
            this.LayoutSub.unsubscribe();
            this.LayoutSub = null;
            LayoutService.SelectedLayout.next(this.TempLayout);
        }
        if (this.ParamSub) {
            this.ParamSub.unsubscribe();
            this.ParamSub = null;
            NavigationService.Params.next(this.TempParameters);
        }
    }
}
