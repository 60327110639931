import { TranslateHelper } from '../../../helpers/injector.helper';

export enum AuthType{
    None, 
    Basic,
    OAuth2_0,
    BearerToken
}

export enum ClientAuthentication {
    BasicAuthHeader,
    ClientCredentialsInBody
}

export enum CodeChallengeMethod {
    SHA_256,
    Plain
}


export enum RequestType {
    GET,
    POST,
    PUT,
    PATCH,
    DELETE
}

export class GrantType {
    public static readonly  AUTORIZATION_CODE = "authorization_code";
    public static readonly  IMPLICIT = "implicit";
    public static readonly  PASSWORD_CREDENTIALS = "password";
    public static readonly  CLIENT_CREDENTIALS = "client_credentials";

    public static getDropdownValues(): {key: string, value: string}[] {
        const retVal = [];
        Object.keys(GrantType).forEach(k => {
            retVal.push({
                key: TranslateHelper.TranslatorInstance.instant("@@"+ k),
                value: GrantType[k]
            });
        });
        return retVal;
    }
}