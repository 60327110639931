import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry } from '../../../services/workflow.service';
import { ElementValueSettings, ElementValueSettingsDataHelper } from '../layout/elemValue/elemValue.settings';

export class RefreshWFModuleExecuter extends WorkflowModuleExecuter {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'RefreshWFModule';
        reg.Caption = '@@Refresh';
        reg.GroupID = 'dataoperations';
        reg.Index = 50;
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = RefreshWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    private static ExecuteRefresh(elem) {
        if (elem.Element) {
            if (typeof elem.Element.ExecuteRefresh === 'function') {
                elem.Element.ExecuteRefresh();
            }
        }
        if (elem.Elements) {
            elem.Elements.forEach(child => {
                RefreshWFModuleExecuter.ExecuteRefresh(child);
            });
        }
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    RefreshWFModuleExecuter.ExecuteRefresh(elem);
                    return 0;
                } else {
                    status.Logger.logError('RefreshWF modul: Element not found.');
                }
            } else {
                status.Logger.logError('RefreshWF modul: No element name found.');
            }
        } else {
            status.Logger.logError('RefreshWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
