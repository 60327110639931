import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-readmultiproperty-settings',
    templateUrl: './read.multi.property.settings.html'
})
export class ReadMultiPropertySettings extends WorkflowDialogContent {
    static ModuleID = 'readMultiPropertyModule';

    Data = new ReadMultiPropertySettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ReadMultiPropertySettings.ModuleID;
        reg.Caption = '@@Read properties';
        reg.SettingsControl = ReadMultiPropertySettings;
        reg.SettingsTypeHelper = new ReadMultiPropertySettingsDataHelper();
        reg.Executer = ReadMultiPropertyModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadMultiPropertySettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Properties.forEach(x => {
            if (x.PropName && (x.StatusKey || x.UseForState)) {
                values.push(x);
            }
        });
        this.Data.Properties = values;
        return this.Data;
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Properties) {
            this.Data.Properties.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }

    addValue() {
        if (this.Data) {
            if (this.Data.Properties) {
                this.Data.Properties.push(new PropertySetting());
            } else {
                this.Data.Properties = [new PropertySetting()];
            }
        }
    }

    removeValue(i) {
        if (this.Data && this.Data.Properties) {
            this.Data.Properties.splice(i, 1);
        }
    }
}

// @dynamic
export class ReadMultiPropertySettingsData extends AServiceWorkflowData {
    @Type(() => PropertySetting)
    Properties: PropertySetting[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ReadProperty.ReadMultiPropertySettingsData';
    }
}

export class PropertySetting {
    PropName: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadMultiPropertySettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadMultiPropertySettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Properties) {
                const list = [];
                settings.Properties.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class ReadMultiPropertyModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const val = status.ActualParameter;
        if (typeof val === 'object') {
            if (status.ActualSettings) {
                status.ActualSettings.Properties.forEach(prop => {
                    if (prop.PropName) {
                        const propList = prop.PropName.split('.');
                        let actVal = val;
                        for (let i = 0; i < propList.length - 1; i++) {
                            actVal = actVal[propList[i]];
                            if (typeof actVal !== 'object') {
                                status.Logger.logWarning('ReadProperty modul: Property ' + prop.PropName + ' not found.');
                                return;
                            }
                        }
                        actVal = actVal[propList[propList.length - 1]];
                        if (prop.StatusKey) {
                            status.Context.set(prop.StatusKey, actVal);
                        }
                        if (prop.UseForState) {
                            status.ActualParameter = actVal;
                        }
                    }
                });
                return 0;
            } else {
                status.Logger.logError('ReadMultiProperty modul: No settings found.');
            }
        } else {
            status.Logger.logError('ReadMultiProperty modul: No object found to read properties from.');
        }
        return super.execute(status);
    }
}
