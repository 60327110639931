import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { CacheService } from '../cache/cache.service';
import { LayoutUnit } from '../models/basic/layoutunit.model';
import { MediaSource } from '../models/datamodel/mediasource.model';
import { Alignment, FlexContentAlignment, FlexItemAlignment } from '../models/enums/alignment.enum';
import { BackgroundPosition, BackgroundRepeat, BackgroundSize } from '../models/enums/background.enum';
import { ControlStyle } from '../models/enums/controlstyle.enum';
import { LayoutPosition } from '../models/enums/layoutposition.enum';
import { ObjectFit } from '../models/enums/objectfit.enum';
import { Orientation } from '../models/enums/orientation.enum';
import { Overflow } from '../models/enums/overflow.enum';
import { PrimeFlexSizes } from '../models/enums/primeflexsizes.enum';
import { PropertyGroupDisplay } from '../models/enums/propertygroupdisplay.enum';
import { Resizeable } from '../models/enums/resizeable.enum';
import { TextOverflow } from '../models/enums/textoverflow.enum';
import { TransitionProperty } from '../models/enums/transitionproperty.enum';
import { TransitionTimingFunction } from '../models/enums/transitiontimingfunction.enum';
import { UnitType } from '../models/enums/unittype.enum';
import { WhiteSpace } from '../models/enums/whitespace.enum';
import { LayoutBase } from '../models/layoutbase.model';
import { LayoutElement } from '../models/layoutelement.model';
import { MenuTabLabelPosition } from '../models/menutab/menutab.property.model';
import { BorderSide } from '../models/style/borderside.model';
import { Color } from '../models/style/color.model';
import { Gradient } from '../models/style/gradient.model';
import { TranslatedString } from '../models/translatedstring.model';
import { TranslateHelper } from './injector.helper';
import { RTLHelper } from './rtl.helper';
import { ThemeHelper } from './theme.helpers';

@Pipe({ name: 'BaseComponentStyle', pure: true })
export class BaseComponentStylePipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' Create BaseComponentStyle(' + RefreshCount + ')');
        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSourceValue;

            if (this.StyleRef && Object.keys(this.StyleRef).length > 0) {
                this.BuildStyle(this.StyleRef, result);
            }
            this.BuildStyle(LayoutElement, result);
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result);
                });
            }
        }
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' StyleCreated BaseComponentStyle(' + RefreshCount + ')');
        return result;
    }
    BuildStyle(element, result) {
        if (element.Transitions && element.Transitions.length > 0) {
            result['transition'] = '';
            for (let i = 0; i < element.Transitions.length; i++) {
                const transition = element.Transitions[i];
                if (transition.Property != null) {
                    result['transition'] += TransitionProperty[transition.Property];
                    if (transition.Duration != null) {
                        result['transition'] += ' ' + transition.Duration + 's';
                        if (transition.TimingFunction != null) {
                            result['transition'] += ' ' + TransitionTimingFunction[transition.TimingFunction];
                            if (transition.Delay != null) {
                                result['transition'] += ' ' + transition.Delay + 's';
                            }
                        }
                    }
                    if (i + 1 < element.Transitions.length) {
                        result['transition'] += ',';
                    }
                }
            }
        }
        //#region Font
        if (element.Font) {
            if (element.Font.Bold != null) {
                if (element.Font.Bold) {
                    result['font-weight'] = 'bold';
                } else {
                    // delete result['font-weight'];
                    result['font-weight'] = 'normal';
                }
            }
            if (element.Font.Italic != null) {
                if (element.Font.Italic) {
                    result['font-style'] = 'italic';
                } else {
                    delete result['font-style'];
                }
            }
            if (element.Font.Underline != null) {
                if (element.Font.Underline) {
                    result['text-decoration'] = 'underline';
                } else {
                    delete result['text-decoration'];
                }
            }
            if (element.Font.FontFamilyName != null) {
                if (element.Font.FontFamilyName) {
                    if (ThemeHelper.CustomFontsMap && ThemeHelper.CustomFontsMap[element.Font.FontFamilyName]) {
                        result['font-family'] = 'var(' + ThemeHelper.CustomFontsMap[element.Font.FontFamilyName].VariableName + ')';
                    } else {
                        result['font-family'] = element.Font.FontFamilyName;
                    }
                } else {
                    delete result['font-family'];
                }
            }
            if (element.Font.FontSize != null) {
                if (element.Font.FontSize) {
                    result['font-size'] = element.Font.FontSize + 'pt';
                } else {
                    delete result['font-size'];
                }
            }
            if (element.Font.FontColor) {
                const colorText = Color.GetStyleText(element.Font.FontColor);
                if (colorText) {
                    result['color'] = colorText;
                } else {
                    delete result['color'];
                }
            }
        }
        //#endregion
        if (element.WhiteSpace != null) {
            result['white-space'] = WhiteSpace[element.WhiteSpace];
        }
        if (element.TextOverflow != null) {
            result['text-overflow'] = TextOverflow[element.TextOverflow];
        }
        if (element && element.Element && element.Element.ContainerSize) {
            result['width'] = element.Element.ContainerSize.Width;
            result['height'] = element.Element.ContainerSize.Height;
        }
        //#region Alignment
        if (element.ElementType !== 'grid') {
            switch (element.HorizontalContentAlignment) {
                case Alignment.Center:
                    result['justify-content'] = 'center';
                    break;
                case Alignment.End:
                    result['justify-content'] = 'flex-end';
                    break;
                case Alignment.Start:
                    result['justify-content'] = 'flex-start';
                    break;
            }

            switch (element.VerticalContentAlignment) {
                case Alignment.Start:
                    result['align-items'] = 'flex-start';
                    break;
                case Alignment.End:
                    result['align-items'] = 'flex-end';
                    break;
                case Alignment.Center:
                    result['align-items'] = 'center';
                    break;
            }
        }
        //#endregion
        //#region TextAlignment
        switch (element.HorizontalTextAlignment) {
            case Alignment.Center:
                result['text-align'] = 'center';
                break;
            case Alignment.End:
                result['text-align'] = 'end';
                break;
            case Alignment.Start:
                result['text-align'] = 'start';
                break;
            case Alignment.Stretch:
                result['text-align'] = 'justify';
                break;
        }
        if (element.ElementType === 'checkbox' || element.ElementType === 'icon' || element.ElementType === 'textblock') {
            switch (element.VerticalTextAlignment) {
                case Alignment.Start:
                    result['align-items'] = 'start';
                    break;
                case Alignment.End:
                    result['align-items'] = 'end';
                    break;
                case Alignment.Center:
                    result['align-items'] = 'center';
                    break;
            }
        } else {
            switch (element.VerticalTextAlignment) {
                case Alignment.Start:
                    result['vertical-align'] = 'top';
                    break;
                case Alignment.End:
                    result['vertical-align'] = 'bottom';
                    break;
                case Alignment.Center:
                    result['vertical-align'] = 'middle';
                    break;
            }
        }
        //#endregion
        if (!element.UseDecorationsOnLayout) {
            //#region Shadow
            if (element.BoxShadow && element.BoxShadow.length > 0) {
                let shadowtext = '';
                for (let i = 0; i < element.BoxShadow.length; i++) {
                    const item = element.BoxShadow[i];
                    let line = item.ShiftRight + 'px ' + item.ShiftDown + 'px ' + item.Blur + 'px ' + item.Spread + 'px ';
                    if (item.Opacity != null) {
                        let opa = item.Opacity;
                        if (typeof item.Opacity != 'number') {
                            opa = parseFloat(item.Opacity)
                        }
                        if (opa == 1) {
                            line += item.Color;
                        } else {
                            let color = Color.FromHex(item.Color);
                            color.A = Math.round(opa * 255);
                            line += Color.GetStyleText(color);
                        }
                    } else {
                        line += item.Color;
                    }
                    if ((i + 1) < element.BoxShadow.length) {
                        line += ', ';
                    }
                    shadowtext += line;
                }
                if (shadowtext) {
                    result['box-shadow'] = shadowtext;
                }
            }
            //#endregion
            //#region Border
            if (element.Border) {
                let style = BorderSide.BorderToStyle(element.Border.LeftBorder);
                if (element.Border.LeftBorder && style !== '') {
                    result['border-left'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.TopBorder);
                if (element.Border.TopBorder && style !== '') {
                    result['border-top'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.RightBorder);
                if (element.Border.RightBorder && style !== '') {
                    result['border-right'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.BottomBorder);
                if (element.Border.BottomBorder && style) {
                    result['border-bottom'] = style;
                }
                if (element.Border.BorderRadius) {
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.TopLeft);
                    if (element.Border.BorderRadius.TopLeft && style !== '') {
                        result['border-top-left-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.TopRight);
                    if (element.Border.BorderRadius.TopRight && style !== '') {
                        result['border-top-right-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.BottomLeft);
                    if (element.Border.BorderRadius.BottomLeft && style !== '') {
                        result['border-bottom-left-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.BottomRight);
                    if (element.Border.BorderRadius.BottomRight && style !== '') {
                        result['border-bottom-right-radius'] = style;
                    }
                }
            }
            //#endregion
            //#region Background
            const bg = element.BackgroundColor;
            if (bg) {
                const styles = Gradient.getStyleObject(bg);
                Object.keys(styles).forEach((key) => {
                    result[key] = styles[key];
                });
            }
            if (element.BackgroundImage) {
                if (this.LayoutElement && this.LayoutElement.MediaSource) {
                    if (this.MediaSource) {
                        const url = MediaSource.GetURL(this.MediaSource, element.BackgroundImage);
                        result['background-image'] = 'url(\'' + url + '\')';
                    } else {
                        CacheService.ReadMediaSourceSub(this.LayoutElement.MediaSource).subscribe((source) => {
                            this.MediaSource = source;
                            const url = MediaSource.GetURL(this.MediaSource, element.BackgroundImage);
                            result['background-image'] = 'url(\'' + url + '\')';
                        });
                    }
                }
            }
            if (typeof element.BackgroundRepeat === 'number' && element.BackgroundRepeat >= 0) {
                result['background-repeat'] = BackgroundRepeat[element.BackgroundRepeat];
            }
            if (typeof element.BackgroundSize === 'number' && element.BackgroundSize >= 0) {
                result['background-size'] = BackgroundSize[element.BackgroundSize];
            }
            if (typeof element.BackgroundPosition === 'number' && element.BackgroundPosition >= 0) {
                result['background-position'] = BackgroundPosition[element.BackgroundPosition];
            }
            //#endregion
        }

        if (element.Visible === false) {
            result['display'] = 'none';
        } else if (element.Visible === true) {
            delete result['display'];
        }
        if (element.LayoutPosition != null) {
            result['position'] = LayoutPosition[element.LayoutPosition];
        }
        if (element.ImageFit != null) {
            result['object-fit'] = ObjectFit[element.ImageFit];
        }
        if (element.Cursor) {
            if (this.LayoutElement) {
                switch (this.LayoutElement.ElementType) {
                    case 'button':
                    case 'download':
                    case 'tablecolumnvisibility':
                    case 'upload':
                        result['cursor'] = element.Cursor;
                        break;
                }
            }
        }
    }
}
//#region LayoutItem
@Pipe({ name: 'LayoutItemStyle', pure: true })
export class LayoutItemStylePipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' Create LayoutItemStyle(' + RefreshCount + ')');

        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSourceValue;

            if (this.StyleRef && Object.keys(this.StyleRef).length > 0) {
                this.BuildStyle(this.StyleRef, result, LayoutElement.Element);
            }
            if (LayoutElement) {
                this.BuildStyle(LayoutElement, result, LayoutElement.Element);
            }
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result, LayoutElement.Element);
                });
            }
            if (LayoutElement.Parent && LayoutElement.Parent.ElementType === 'flex') {
                const theme = ThemeHelper.AcitveTheme;
                if (theme && theme.ControlStyle === ControlStyle.Bootstrap) {
                    const ignoreList = ['textblock', 'grid', 'bootstrapgrid', 'flex',
                        'canvas', 'raster', 'repeat', 'bootstraprepeat', 'template'];
                    if (!ignoreList.some(x => x == LayoutElement.ElementType)) {
                        switch (LayoutElement.Parent.Orientation) {
                            case Orientation.Horizontal:
                            case Orientation.HorizontalReverse:
                            case Orientation.HorizontalWrapping:
                            case Orientation.HorizontalReverseWrapping:
                                if (!result['width'] && !result['min-width']) {
                                    result['min-width'] = "100px";
                                }
                                break;
                            case Orientation.Vertical:
                            case Orientation.VerticalReverse:
                            case Orientation.VerticalWrapping:
                            case Orientation.VerticalReverseWrapping:
                                if (!result['height'] && !result['min-height']) {
                                    result['min-height'] = "50px";
                                }
                                break;
                        }
                    }
                }
            }
        }
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' StyleCreated LayoutItemStyle(' + RefreshCount + ')');
        return result;
    }
    BuildStyle(element, result, control) {
        if (element.Transitions && element.Transitions.length > 0) {
            result['transition'] = '';
            for (let i = 0; i < element.Transitions.length; i++) {
                const transition = element.Transitions[i];
                if (transition.Property != null) {
                    result['transition'] += TransitionProperty[transition.Property];
                    if (transition.Duration != null) {
                        result['transition'] += ' ' + transition.Duration + 's';
                        if (transition.TimingFunction != null) {
                            result['transition'] += ' ' + TransitionTimingFunction[transition.TimingFunction];
                            if (transition.Delay != null) {
                                result['transition'] += ' ' + transition.Delay + 's';
                            }
                        }
                    }
                    if (i + 1 < element.Transitions.length) {
                        result['transition'] += ',';
                    }
                }
            }
        }
        if (element.Font) {
            if (element.Font.Bold != null) {
                if (element.Font.Bold) {
                    result['font-weight'] = 'bold';
                } else {
                    delete result['font-weight'];
                    // result['font-weight'] = 'normal';
                }
            }
            if (element.Font.Italic != null) {
                if (element.Font.Italic) {
                    result['font-style'] = 'italic';
                } else {
                    delete result['font-style'];
                }
            }
            if (element.Font.Underline != null) {
                if (element.Font.Underline) {
                    result['text-decoration'] = 'underline';
                } else {
                    delete result['text-decoration'];
                }
            }
            if (element.Font.FontFamilyName != null) {
                if (element.Font.FontFamilyName) {
                    if (ThemeHelper.CustomFontsMap && ThemeHelper.CustomFontsMap[element.Font.FontFamilyName]) {
                        result['font-family'] = 'var(' + ThemeHelper.CustomFontsMap[element.Font.FontFamilyName].VariableName + ')';
                    } else {
                        result['font-family'] = element.Font.FontFamilyName;
                    }
                } else {
                    delete result['font-family'];
                }
            }
            if (element.Font.FontSize != null) {
                if (element.Font.FontSize) {
                    result['font-size'] = element.Font.FontSize + 'pt';
                } else {
                    delete result['font-size'];
                }
            }
            if (element.Font.FontColor) {
                const colorText = Color.GetStyleText(element.Font.FontColor);
                if (colorText) {
                    result['color'] = colorText;
                } else {
                    delete result['color'];
                }
            }
        }
        if (element.WhiteSpace != null) {
            result['white-space'] = WhiteSpace[element.WhiteSpace];
        }
        if (element.TextOverflow != null) {
            result['text-overflow'] = TextOverflow[element.TextOverflow];
        }
        if (element.Width) {
            const style = LayoutUnit.ToStyle(element.Width);
            if (style !== '') {
                result['width'] = style;
            }
        }
        if (element.Height) {
            const style = LayoutUnit.ToStyle(element.Height);
            if (style !== '') {
                result['height'] = style;
            }
        }
        if (element.MinWidth) {
            const style = LayoutUnit.ToStyle(element.MinWidth);
            if (style !== '') {
                result['min-width'] = style;
            }
        }
        if (element.MinHeight) {
            const style = LayoutUnit.ToStyle(element.MinHeight);
            if (style !== '') {
                result['min-height'] = style;
            }
        }
        if (element.MaxWidth) {
            const style = LayoutUnit.ToStyle(element.MaxWidth);
            if (style !== '') {
                result['max-width'] = style;
            }
        }
        if (element.MaxHeight) {
            const style = LayoutUnit.ToStyle(element.MaxHeight);
            if (style !== '') {
                result['max-height'] = style;
            }
        }
        if (element.Margin) {
            const styleLeft = LayoutUnit.ToStyle(element.Margin.Left);
            if (element.Margin.Left && styleLeft) {
                result['margin-left'] = styleLeft;
            }
            const styleTop = LayoutUnit.ToStyle(element.Margin.Top);
            if (element.Margin.Top && styleTop) {
                result['margin-top'] = styleTop;
            }
            const styleRight = LayoutUnit.ToStyle(element.Margin.Right);
            if (element.Margin.Right && styleRight !== '') {
                result['margin-right'] = styleRight;
            }
            const styleBottom = LayoutUnit.ToStyle(element.Margin.Bottom);
            if (element.Margin.Bottom && styleBottom !== '') {
                result['margin-bottom'] = styleBottom;
            }
            //let minusWidth = '';
            //if (styleLeft !=='' && styleRight !== '') {
            //    minusWidth = ' + ' + result['margin-left'] + ' - ' + result['margin-right'] + ')';
            //} else if (styleLeft !== '') {
            //    minusWidth = ' + ' + result['margin-left'] + ')';
            //} else if (styleRight !== '') {
            //    minusWidth = ' + ' + result['margin-right'] + ')';
            //}
            //if (minusWidth != '') {
            //    if (result['width']) {
            //        result['width'] = 'calc(' + result['width'] + minusWidth;
            //    } else {
            //        result['width'] = 'calc(100%' + minusWidth;
            //    }
            //}
            //let minusHeight = '';
            //if (styleTop !== '' && styleBottom !== '') {
            //    minusHeight = ' + ' + result['margin-top'] + ' - ' + result['margin-bottom'] + ')';
            //} else if (styleTop !== '') {
            //    minusHeight = ' + ' + result['margin-top'] + ')';
            //} else if (styleBottom !== '') {
            //    minusHeight = ' + ' + result['margin-bottom'] + ')';
            //}
            //if (minusHeight != '') {
            //    if (result['height']) {
            //        result['height'] = 'calc(' + result['height'] + minusHeight;
            //    } else {
            //        result['height'] = 'calc(100%' + minusHeight;
            //    }
            //}
        }
        if (element.ZIndex != null) {
            result['z-index'] = element.ZIndex;
            if (control.Zindex != element.ZIndex) {
                control.ZIndex = element.ZIndex;
                if (control.Parent && control.Parent.Element && control.Parent.Element.cdRef) {
                    control.Parent.Element.RefreshCount += 1;
                }
            }
        } else {
            if (control.ZIndex != null) {
                delete control.ZIndex;
                if (control.Parent && control.Parent.Element && control.Parent.Element.cdRef) {
                    control.Parent.Element.RefreshCount += 1;
                }
            }
        }
        if (element.Scrollable) {
            result['overflow'] = 'auto';
        }
        if (element.Overflow != null) {
            result['overflow'] = Overflow[element.Overflow];
        } else {
            result['overflow'] = 'hidden';
        }
        switch (element.HorizontalContentAlignment) {
            case Alignment.Center:
                result['justify-self'] = 'center';
                break;
            case Alignment.End:
                result['justify-self'] = 'flex-end';
                break;
            case Alignment.Start:
                result['justify-self'] = 'flex-start';
                break;
        }
        switch (element.VerticalContentAlignment) {
            case Alignment.Start:
                result['align-self'] = 'flex-start';
                break;
            case Alignment.End:
                result['align-self'] = 'flex-end';
                break;
            case Alignment.Center:
                result['align-self'] = 'center';
                break;
        }

        //#region TextAlignment
        switch (element.HorizontalTextAlignment) {
            case Alignment.Center:
                result['text-align'] = 'center';
                break;
            case Alignment.End:
                result['text-align'] = 'end';
                break;
            case Alignment.Start:
                result['text-align'] = 'start';
                break;
            case Alignment.Stretch:
                result['text-align'] = 'justify';
                break;
        }

        switch (element.VerticalTextAlignment) {
            case Alignment.Start:
                result['vertical-align'] = 'top';
                break;
            case Alignment.End:
                result['vertical-align'] = 'bottom';
                break;
            case Alignment.Center:
                result['vertical-align'] = 'middle';
                break;
        }
        //#endregion

        if (element.Visible === false) {
            result['display'] = 'none';
        } else if (element.Visible === true) {
            delete result['display'];
        }

        if (element.Padding) {
            let style = LayoutUnit.ToStyle(element.Padding.Left);
            if (element.Padding.Left && style) {
                result['padding-left'] = style;
            }
            style = LayoutUnit.ToStyle(element.Padding.Top);
            if (element.Padding.Top && style) {
                result['padding-top'] = style;
            }
            style = LayoutUnit.ToStyle(element.Padding.Right);
            if (element.Padding.Right && style) {
                result['padding-right'] = style;
            }
            style = LayoutUnit.ToStyle(element.Padding.Bottom);
            if (element.Padding.Bottom && style) {
                result['padding-bottom'] = style;
            }
        }
        if (element.LayoutPosition != null) {
            result['position'] = LayoutPosition[element.LayoutPosition];
        }
        if (element.ImageFit != null) {
            result['object-fit'] = ObjectFit[element.ImageFit];
        }
        if (element.Location) {
            if (element.Location.Left && element.Location.Left.toStyle() !== '') {
                result['left'] = element.Location.Left.toStyle();
            }
            if (element.Location.Top && element.Location.Top.toStyle() !== '') {
                result['top'] = element.Location.Top.toStyle();
            }
            if (element.Location.Right && element.Location.Right.toStyle() !== '') {
                result['right'] = element.Location.Right.toStyle();
            }
            if (element.Location.Bottom && element.Location.Bottom.toStyle() !== '') {
                result['bottom'] = element.Location.Bottom.toStyle();
            }
        }
        if (element.UseDecorationsOnLayout) {
            //#region Shadow
            if (element.BoxShadow && element.BoxShadow.length > 0) {
                let shadowtext = '';
                for (let i = 0; i < element.BoxShadow.length; i++) {
                    const item = element.BoxShadow[i];
                    let line = item.ShiftRight + 'px ' + item.ShiftDown + 'px ' + item.Blur + 'px ' + item.Spread + 'px ';
                    if (item.Opacity != null) {
                        let opa = item.Opacity;
                        if (typeof item.Opacity != 'number') {
                            opa = parseFloat(item.Opacity)
                        }
                        if (opa == 1) {
                            line += item.Color;
                        } else {
                            let color = Color.FromHex(item.Color);
                            color.A = Math.round(opa * 255);
                            line += Color.GetStyleText(color);
                        }
                    } else {
                        line += item.Color;
                    }
                    if ((i + 1) < element.BoxShadow.length) {
                        line += ', ';
                    }
                    shadowtext += line;
                }
                if (shadowtext) {
                    result['box-shadow'] = shadowtext;
                }
            }
            //#endregion
            //#region Border
            if (element.Border) {
                let style = BorderSide.BorderToStyle(element.Border.LeftBorder);
                if (element.Border.LeftBorder && style !== '') {
                    result['border-left'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.TopBorder);
                if (element.Border.TopBorder && style !== '') {
                    result['border-top'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.RightBorder);
                if (element.Border.RightBorder && style !== '') {
                    result['border-right'] = style;
                }
                style = BorderSide.BorderToStyle(element.Border.BottomBorder);
                if (element.Border.BottomBorder && style) {
                    result['border-bottom'] = style;
                }
                if (element.Border.BorderRadius) {
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.TopLeft);
                    if (element.Border.BorderRadius.TopLeft && style !== '') {
                        result['border-top-left-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.TopRight);
                    if (element.Border.BorderRadius.TopRight && style !== '') {
                        result['border-top-right-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.BottomLeft);
                    if (element.Border.BorderRadius.BottomLeft && style !== '') {
                        result['border-bottom-left-radius'] = style;
                    }
                    style = LayoutUnit.ToStyle(element.Border.BorderRadius.BottomRight);
                    if (element.Border.BorderRadius.BottomRight && style !== '') {
                        result['border-bottom-right-radius'] = style;
                    }
                }
            }
            //#endregion
            //#region Background
            const bg = element.BackgroundColor;
            if (bg) {
                const styles = Gradient.getStyleObject(bg);
                Object.keys(styles).forEach((key) => {
                    result[key] = styles[key];
                });
            }
            if (element.BackgroundImage) {
                if (this.LayoutElement && this.LayoutElement.MediaSource) {
                    if (this.MediaSource) {
                        const url = MediaSource.GetURL(this.MediaSource, element.BackgroundImage);
                        result['background-image'] = 'url(\'' + url + '\')';
                    } else {
                        CacheService.ReadMediaSourceSub(this.LayoutElement.MediaSource).subscribe((source) => {
                            this.MediaSource = source;
                            const url = MediaSource.GetURL(this.MediaSource, element.BackgroundImage);
                            result['background-image'] = 'url(\'' + url + '\')';
                        });
                    }
                }
            }
            if (typeof element.BackgroundRepeat === 'number' && element.BackgroundRepeat >= 0) {
                result['background-repeat'] = BackgroundRepeat[element.BackgroundRepeat];
            }
            if (typeof element.BackgroundSize === 'number' && element.BackgroundSize >= 0) {
                result['background-size'] = BackgroundSize[element.BackgroundSize];
            }
            if (typeof element.BackgroundPosition === 'number' && element.BackgroundPosition >= 0) {
                result['background-position'] = BackgroundPosition[element.BackgroundPosition];
            }
            //#endregion
        }
        let transformText = '';
        if (element.RotateX != null) {
            transformText += 'rotateX(' + element.RotateX + 'deg) ';
        }
        if (element.RotateY != null) {
            transformText += 'rotateY(' + element.RotateY + 'deg) ';
        }
        if (element.RotateZ != null) {
            transformText += 'rotateZ(' + element.RotateZ + 'deg) ';
        }
        if (element.TranslateX && element.TranslateX.Value != null) {
            transformText += 'translateX(' + element.TranslateX.toStyle() + ') ';
        }
        if (element.TranslateY && element.TranslateY.Value != null) {
            transformText += 'translateY(' + element.TranslateY.toStyle() + ') ';
        }
        if (element.TranslateZ && element.TranslateZ.Value != null) {
            transformText += 'translateZ(' + element.TranslateZ.toStyle() + ') ';
        }
        if (element.ScaleX) {
            transformText += 'scaleX(' + element.ScaleX + ') ';
        }
        if (element.ScaleY) {
            transformText += 'scaleY(' + element.ScaleY + ') ';
        }
        if (element.ScaleZ) {
            transformText += 'scaleZ(' + element.ScaleZ + ') ';
        }
        if (transformText) {
            result['transform'] = transformText;
        }
        if (element.Cursor) {
            result['cursor'] = element.Cursor;
        }
    }
}
@Pipe({ name: 'LayoutItemDisplay', pure: true })
export class LayoutItemDisplayPipe implements PipeTransform {
    transform(LayoutElement, RefreshCount) {
        const result = {};

        if (LayoutElement && LayoutElement.Element) {
            if (LayoutElement.ElementType === 'carousel') {
                return {
                    display: 'grid'
                };
            }
            if (LayoutElement.Element.StyleRef && Object.keys(LayoutElement.Element.StyleRef).length > 0) {
                this.BuildStyle(LayoutElement.Element.StyleRef, result);
            }
            this.BuildStyle(LayoutElement, result);
            if (LayoutElement.Element.WorkflowStyles && LayoutElement.Element.WorkflowStyles.length > 0) {
                LayoutElement.Element.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result);
                });
            }
        }
        let retVal = null;
        if (result['justify-self'] || result['align-self']) {
            retVal = 'grid';
            delete result['justify-self'];
            delete result['align-self'];
        }

        return {
            display: retVal
        };
    }
    BuildStyle(element, result) {
        switch (element.HorizontalContentAlignment) {
            case Alignment.Center:
                result['justify-self'] = 'center';
                break;
            case Alignment.End:
                result['justify-self'] = 'flex-end';
                break;
            case Alignment.Start:
                result['justify-self'] = 'flex-start';
                break;
        }
        switch (element.VerticalContentAlignment) {
            case Alignment.Start:
                result['align-self'] = 'flex-start';
                break;
            case Alignment.End:
                result['align-self'] = 'flex-end';
                break;
            case Alignment.Center:
                result['align-self'] = 'center';
                break;
        }
    }
}
@Pipe({ name: 'LoadingStyleDefinition', pure: true })
export class LoadingStyleDefinitionPipe implements PipeTransform {
    transform(BaseStyle, LayoutElement) {
        const result = BaseStyle;
        if (LayoutElement && LayoutElement.Element) {
            if (LayoutElement.Element.IsComponent) {
                result['position'] = 'fixed';
            }
        }
        return result;
    }
}
//#endregion
//#region Layouts
//#region Grid
@Pipe({ name: 'GridStyleDefinition', pure: true })
export class GridStyleDefinitionPipe implements PipeTransform {
    transform(BaseStyle, LayoutElement) {
        const result = BaseStyle;
        if (LayoutElement.ColumnDefinitions && LayoutElement.ColumnDefinitions.length > 0) {
            result['grid-template-columns'] = this.calculateSize(LayoutElement.ColumnDefinitions);
        }
        if (LayoutElement.RowDefinitions && LayoutElement.RowDefinitions.length > 0) {
            result['grid-template-rows'] = this.calculateSize(LayoutElement.RowDefinitions);
        }
        return result;
    }
    calculateSize(items: LayoutBase[]): string {
        let result = '';
        const tempItems = {};
        for (let i = 0; i < items.length; i++) {
            if (items[i].Size) {
                if (items[i].Size.Type == UnitType.Auto) {
                    tempItems[i] = 'max-content';
                } else {
                    tempItems[i] = LayoutUnit.ConvertFrom(items[i].Size);
                }
            }
        }
        Object.keys(tempItems).forEach((item) => {
            if (result.length > 0) {
                result += ' ';
            }
            result += tempItems[item];
        });
        return result;
    }
}
@Pipe({ name: 'GridCellStyle', pure: true })
export class GridCellStylePipe implements PipeTransform {
    transform(item, RefreshCount) {
        const result = {};

        if (item.Row) {
            result['grid-row'] = item.Row;
            if (item.RowSpan) {
                result['grid-row-start'] = item.Row;
                result['grid-row-end'] = item.Row + item.RowSpan;
            }
        }
        if (item.Column) {
            result['grid-column'] = item.Column;
            if (item.ColSpan) {
                result['grid-column-start'] = item.Column;
                result['grid-column-end'] = item.Column + item.ColSpan;
            }
        }
        if (item.Scrollable) {
            result['overflow'] = 'auto';
        } else if (item.CellOverflow != null) {
            result['overflow'] = Overflow[item.CellOverflow];
        }
        return result;
    }
}
@Pipe({ name: 'GridEditCellStyle', pure: true })
export class GridEditCellStylePipe implements PipeTransform {
    transform(item, drag) {
        const result = {};
        if (item.Row) {
            result['grid-row'] = item.Row;
        }
        if (item.Column) {
            result['grid-column'] = item.Column;
        }
        if (drag && !(item.Row == drag.Row && item.Column == drag.Column)) {
            result['z-index'] = 9999999999999999;
        }
        return result;
    }
}
@Pipe({ name: 'GridAlignmentStyle', pure: true })
export class GridAlignmentStylePipe implements PipeTransform {
    transform(item) {
        const result = {};
        switch (item.VerticalContentAlignment) {
            case Alignment.Start:
                result['justify-content'] = 'flex-start';
                break;
            case Alignment.End:
                result['justify-content'] = 'flex-end';
                break;
            case Alignment.Center:
                result['justify-content'] = 'center';
                break;
        }
        return result;
    }
}
@Pipe({ name: 'GridItemStyle', pure: true })
export class GridItemStylePipe implements PipeTransform {
    transform(item) {
        const alignObj = {
            HasHorizontalAlignment: false,
            HasVerticalAlignment: false
        };
        let retVal = {};
        if (item && item.Element && item.Element.createStyleInternal) {
            retVal = item.Element.createStyleInternal((elem, result) => {
                const ret = this.createItemStyleInternal(elem, result);
                if (ret.HasHorizontalAlignment) {
                    alignObj.HasHorizontalAlignment = true;
                }
                if (ret.HasVerticalAlignment) {
                    alignObj.HasVerticalAlignment = true;
                }
            });
        } else {
            const ret = this.createItemStyleInternal(item, retVal);
            if (ret.HasHorizontalAlignment) {
                alignObj.HasHorizontalAlignment = true;
            }
            if (ret.HasVerticalAlignment) {
                alignObj.HasVerticalAlignment = true;
            }
        }
        if (!alignObj.HasVerticalAlignment) {
            retVal['height'] = '100%';
        }
        if (!alignObj.HasHorizontalAlignment) {
            retVal['width'] = '100%';
        }
        return retVal;
    }

    createItemStyleInternal(element, result) {
        const alignObj = {
            HasHorizontalAlignment: false,
            HasVerticalAlignment: false
        };
        switch (element.HorizontalContentAlignment) {
            case Alignment.Center:
                result['align-self'] = 'center';
                alignObj.HasHorizontalAlignment = true;
                break;
            case Alignment.End:
                result['align-self'] = 'flex-end';
                alignObj.HasHorizontalAlignment = true;
                break;
            case Alignment.Start:
                result['align-self'] = 'flex-start';
                alignObj.HasHorizontalAlignment = true;
                break;
        }
        switch (element.VerticalContentAlignment) {
            case Alignment.Start:
                alignObj.HasVerticalAlignment = true;
                break;
            case Alignment.End:
                alignObj.HasVerticalAlignment = true;
                break;
            case Alignment.Center:
                alignObj.HasVerticalAlignment = true;
                break;
        }
        return alignObj;
    }
}
//#endregion
//#region Flex
@Pipe({ name: 'FlexAlign', pure: true })
export class FlexAlignPipe implements PipeTransform {
    transform(element, RefreshCount) {
        const result = {};
        if (element) {
            if (element.FlexAlignItems != null) {
                result['align-items'] = FlexItemAlignment[element.FlexAlignItems];
            }
            if (element.FlexAlignContent != null) {
                result['align-content'] = FlexContentAlignment[element.FlexAlignContent];
            }
        }
        return result;
    }
}
@Pipe({ name: 'FlexClass', pure: true })
export class FlexClassPipe implements PipeTransform {
    transform(element, RefreshCount) {
        let result = '';
        switch (element.Orientation) {
            case Orientation.Horizontal: result = 'flex-row'; break;
            case Orientation.HorizontalReverse: result = 'flex-row-reverse'; break;
            case Orientation.HorizontalWrapping: result = 'flex-row-wrapping'; break;
            case Orientation.HorizontalReverseWrapping: result = 'flex-row-reverse-wrapping'; break;
            case Orientation.Vertical: result = 'flex-column'; break;
            case Orientation.VerticalReverse: result = 'flex-column-reverse'; break;
            case Orientation.VerticalWrapping: result = 'flex-column-wrapping'; break;
            case Orientation.VerticalReverseWrapping: result = 'flex-column-reverse-wrapping'; break;
        }
        return result;
    }
}
@Pipe({ name: 'FlexItem', pure: true })
export class FlexItemPipe implements PipeTransform {

    Layout;

    transform(element, layout) {
        let result = {};
        this.Layout = layout;
        if (element && element.Element && element.Element.createStyleInternal) {
            result = element.Element.createStyleInternal((elem, r) => {
                this.createItemStyleInternal(elem, r);
            });
        } else {
            this.createItemStyleInternal(element, result);
        }
        return result;
    }
    createItemStyleInternal(elem, result) {
        switch (this.Layout.Orientation) {
            case Orientation.Horizontal:
            case Orientation.HorizontalReverse:
            case Orientation.HorizontalWrapping:
            case Orientation.HorizontalReverseWrapping:
                switch (elem.VerticalContentAlignment) {
                    case Alignment.Start:
                        result['align-self'] = 'flex-start';
                        break;
                    case Alignment.End:
                        result['align-self'] = 'flex-end';
                        break;
                    case Alignment.Center:
                        result['align-self'] = 'center';
                        break;
                }
                break;
            case Orientation.Vertical:
            case Orientation.VerticalReverse:
            case Orientation.VerticalWrapping:
            case Orientation.VerticalReverseWrapping:
                switch (elem.HorizontalContentAlignment) {
                    case Alignment.Center:
                        result['align-self'] = 'center';
                        break;
                    case Alignment.End:
                        result['align-self'] = 'flex-end';
                        break;
                    case Alignment.Start:
                        result['align-self'] = 'flex-start';
                        break;
                }
        }
    }
}
//#endregion
@Pipe({ name: 'RepeatClass', pure: true })
export class RepeatClassPipe implements PipeTransform {

    Layout;

    transform(item, source) {
        if (item && source) {
            source.FlexFill = item.FlexFill;
        }
        if (item && item.FlexFill) {
            return 'fill';
        }
        if (source && source.FlexFill) {
            return 'fill';
        }
    }
}
@Pipe({ name: 'CanvasItemPosition', pure: true })
export class CanvasItemPositionPipe implements PipeTransform {
    transform(element, RefreshCount) {
        const result = {};
        if (element.Location) {
            if (RTLHelper.Direction === 'ltr') {
                if (element.Location.Left && LayoutUnit.ToStyle(element.Location.Left) !== '') {
                    result['left'] = LayoutUnit.ToStyle(element.Location.Left);
                }
                if (element.Location.Right && LayoutUnit.ToStyle(element.Location.Right) !== '') {
                    result['right'] = LayoutUnit.ToStyle(element.Location.Right);
                }
            } else {
                if (element.Location.Left && LayoutUnit.ToStyle(element.Location.Left) !== '') {
                    result['right'] = LayoutUnit.ToStyle(element.Location.Left);
                }
                if (element.Location.Right && LayoutUnit.ToStyle(element.Location.Right) !== '') {
                    result['left'] = LayoutUnit.ToStyle(element.Location.Right);
                }
            }
            if (element.Location.Top && LayoutUnit.ToStyle(element.Location.Top) !== '') {
                result['top'] = LayoutUnit.ToStyle(element.Location.Top);
            }
            if (element.Location.Bottom && LayoutUnit.ToStyle(element.Location.Bottom) !== '') {
                result['bottom'] = LayoutUnit.ToStyle(element.Location.Bottom);
            }
        }
        if (element.Width && LayoutUnit.ToStyle(element.Width) !== '') {
            result['width'] = LayoutUnit.ToStyle(element.Width);
        }
        if (element.Height && LayoutUnit.ToStyle(element.Height) !== '') {
            result['height'] = LayoutUnit.ToStyle(element.Height);
        }
        if (element.ZIndex) {
            result['z-index'] = element.ZIndex;
        }
        result['position'] = 'absolute';
        return result;
    }
}
//#endregion
//#region Controls
@Pipe({ name: 'FloatingLabel', pure: true })
export class FloatingLabelPipe implements PipeTransform {
    transform(element, Refresh) {
        const result = {};
        if (element.Font) {
            if (element.Font.Bold != null) {
                if (element.Font.Bold) {
                    result['font-weight'] = 'bold';
                } else {
                    // delete result['font-weight'];
                    result['font-weight'] = 'normal';
                }
            }
            if (element.Font.Italic != null) {
                if (element.Font.Italic) {
                    result['font-style'] = 'italic';
                } else {
                    delete result['font-style'];
                }
            }
            if (element.Font.Underline != null) {
                if (element.Font.Underline) {
                    result['text-decoration'] = 'underline';
                } else {
                    delete result['text-decoration'];
                }
            }
            if (element.Font.FontFamilyName != null) {
                if (element.Font.FontFamilyName) {
                    if (ThemeHelper.CustomFontsMap && ThemeHelper.CustomFontsMap[element.Font.FontFamilyName]) {
                        result['font-family'] = 'var(' + ThemeHelper.CustomFontsMap[element.Font.FontFamilyName].VariableName + ')';
                    } else {
                        result['font-family'] = element.Font.FontFamilyName;
                    }
                } else {
                    delete result['font-family'];
                }
            }
            if (element.Font.FontSize != null) {
                if (element.Font.FontSize) {
                    result['font-size'] = element.Font.FontSize + 'pt';
                } else {
                    delete result['font-size'];
                }
            }
            if (element.Font.FontColor) {
                const colorText = Color.GetStyleText(element.Font.FontColor);
                if (colorText) {
                    result['color'] = colorText;
                } else {
                    delete result['color'];
                }
            }
        }
        return result;
    }
}
@Pipe({ name: 'TextblockStyle', pure: true })
export class TextblockStylePipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSource;

            if (this.StyleRef && Object.keys(this.StyleRef).length > 0) {
                this.BuildStyle(this.StyleRef, result, LayoutElement.Element);
            }
            this.BuildStyle(LayoutElement, result, LayoutElement.Element);
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result, LayoutElement.Element);
                });
            }
        }
        return result;
    }
    BuildStyle(element, result, control) {

        if (element.WhiteSpace != null) {
            result['white-space'] = WhiteSpace[element.WhiteSpace];
        }
        if (element.TextOverflow != null) {
            result['text-overflow'] = TextOverflow[element.TextOverflow];
        }
        if (element.Scrollable) {
            result['overflow'] = 'auto';
        } else if (element.Overflow != null) {
            result['overflow'] = Overflow[element.Overflow];
        } else {
            result['overflow'] = 'hidden';
        }
    }
}
@Pipe({ name: 'CarouselSize', pure: true })
export class CarouselSizePipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSource;

            if (this.StyleRef && Object.keys(this.StyleRef).length > 0) {
                this.BuildStyle(this.StyleRef, result, LayoutElement.Element);
            }
            this.BuildStyle(LayoutElement, result, LayoutElement.Element);
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result, LayoutElement.Element);
                });
            }
        }
        return result;
    }
    BuildStyle(element, result, control) {
        if (element.InnerMargin) {
            const styleLeft = LayoutUnit.ToStyle(element.InnerMargin.Left);
            if (element.InnerMargin.Left && styleLeft) {
                result['margin-left'] = styleLeft;
            }
            const styleTop = LayoutUnit.ToStyle(element.InnerMargin.Top);
            if (element.InnerMargin.Top && styleTop) {
                result['margin-top'] = styleTop;
            }
            const styleRight = LayoutUnit.ToStyle(element.InnerMargin.Right);
            if (element.InnerMargin.Right && styleRight !== '') {
                result['margin-right'] = styleRight;
            }
            const styleBottom = LayoutUnit.ToStyle(element.InnerMargin.Bottom);
            if (element.InnerMargin.Bottom && styleBottom !== '') {
                result['margin-bottom'] = styleBottom;
            }
            let minusWidth = '';
            if (styleLeft !=='' && styleRight !== '') {
                minusWidth = ' - ' + result['margin-left'] + ' - ' + result['margin-right'] + ')';
            } else if (styleLeft !== '') {
                minusWidth = ' - ' + result['margin-left'] + ')';
            } else if (styleRight !== '') {
                minusWidth = ' - ' + result['margin-right'] + ')';
            }
            if (minusWidth != '') {
                if (result['width']) {
                    result['width'] = 'calc(' + result['width'] + minusWidth;
                } else {
                    result['width'] = 'calc(100%' + minusWidth;
                }
            }
            let minusHeight = '';
            if (styleTop !== '' && styleBottom !== '') {
                minusHeight = ' - ' + result['margin-top'] + ' - ' + result['margin-bottom'] + ')';
            } else if (styleTop !== '') {
                minusHeight = ' - ' + result['margin-top'] + ')';
            } else if (styleBottom !== '') {
                minusHeight = ' - ' + result['margin-bottom'] + ')';
            }
            if (minusHeight != '') {
                if (result['height']) {
                    result['height'] = 'calc(' + result['height'] + minusHeight;
                } else {
                    result['height'] = 'calc(100%' + minusHeight;
                }
            }
        }
    }
}
@Pipe({ name: 'CarouselArrowSize', pure: true })
export class CarouselArrowSizePipe implements PipeTransform {
    transform(LayoutElement, RefreshCount, Type) {
        const result = {};

        switch (Type) {
            case '':
                if (LayoutElement.IconWidth) {
                    result['width'] = LayoutUnit.ToStyle(LayoutElement.IconWidth);
                }
                if (LayoutElement.IconHeight) {
                    result['height'] = LayoutUnit.ToStyle(LayoutElement.IconHeight);
                }
                break;
            case 'Nav':
                if (LayoutElement.IconWidthNav) {
                    result['width'] = LayoutUnit.ToStyle(LayoutElement.IconWidthNav);
                }
                if (LayoutElement.IconHeightNav) {
                    result['height'] = LayoutUnit.ToStyle(LayoutElement.IconHeightNav);
                }
                break;
        }

       
        return result;
    }
}

@Pipe({ name: 'ComboBoxStyle', pure: true })
export class ComboBoxStylePipe implements PipeTransform {
    transform(input, Height, MaxHeight, hsbv) {
        return {
            width: input.scrollWidth + 'px',
            height: Height + 'px',
            'max-height': MaxHeight + 'px'
        };
    }
}
@Pipe({ name: 'ComboBoxHeight', pure: true })
export class ComboBoxHeightPipe implements PipeTransform {
    transform(scroller, component, scrollWidth) {
        if (scroller && scroller.viewport && scroller.viewport.elementRef && scroller.viewport.elementRef.nativeElement) {
            let element = scroller.viewport.elementRef.nativeElement;
            if (element.scrollWidth > element.offsetWidth) {
                component.HorizontalScrollBarVisible = true;
            } else {
                component.HorizontalScrollBarVisible = false;
            }
        } else {
            component.HorizontalScrollBarVisible = false;
        }

        return
    }
}
@Pipe({ name: 'ComboBoxFont', pure: true })
export class ComboBoxFontPipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' Create LayoutItemStyle(' + RefreshCount + ')');

        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSourceValue;

            if (this.StyleRef && Object.keys(this.StyleRef).length > 0) {
                this.BuildStyle(this.StyleRef, result);
            }
            if (LayoutElement) {
                this.BuildStyle(LayoutElement, result);
            }
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result);
                });
            }
        }
        // console.log(new Date().toJSON() + '  ' + LayoutElement.ID + ' StyleCreated LayoutItemStyle(' + RefreshCount + ')');
        return result;
    }
    BuildStyle(element, result) {
        if (element.Font) {
            if (element.Font.Bold != null) {
                if (element.Font.Bold) {
                    result['font-weight'] = 'bold';
                } else {
                    delete result['font-weight'];
                    // result['font-weight'] = 'normal';
                }
            }
            if (element.Font.Italic != null) {
                if (element.Font.Italic) {
                    result['font-style'] = 'italic';
                } else {
                    delete result['font-style'];
                }
            }
            if (element.Font.Underline != null) {
                if (element.Font.Underline) {
                    result['text-decoration'] = 'underline';
                } else {
                    delete result['text-decoration'];
                }
            }
            if (element.Font.FontFamilyName != null) {
                if (element.Font.FontFamilyName) {
                    if (ThemeHelper.CustomFontsMap && ThemeHelper.CustomFontsMap[element.Font.FontFamilyName]) {
                        result['font-family'] = 'var(' + ThemeHelper.CustomFontsMap[element.Font.FontFamilyName].VariableName + ')';
                    } else {
                        result['font-family'] = element.Font.FontFamilyName;
                    }
                } else {
                    delete result['font-family'];
                }
            }
            if (element.Font.FontSize != null) {
                if (element.Font.FontSize) {
                    result['font-size'] = element.Font.FontSize + 'pt';
                } else {
                    delete result['font-size'];
                }
            }
            if (element.Font.FontColor) {
                const colorText = Color.GetStyleText(element.Font.FontColor);
                if (colorText) {
                    result['color'] = colorText;
                } else {
                    delete result['color'];
                }
            }
        }
        if (element.WhiteSpace != null) {
            result['white-space'] = WhiteSpace[element.WhiteSpace];
        }
        if (element.TextOverflow != null) {
            result['text-overflow'] = TextOverflow[element.TextOverflow];
        }

        return result;
    }
}
//#region Button
@Pipe({ name: 'HorizontalButtonStyle', pure: true })
export class HorizontalButtonStylePipe implements PipeTransform {
    transform(element, RefreshCount) {
        const horStyle = {
            'justify-content': 'center'
        };

        if (element) {
            const hor = element['text-align'];
            if (hor) {
                switch (hor) {
                    case 'start':
                        horStyle['justify-content'] = 'flex-start';
                        break;
                    case 'end':
                        horStyle['justify-content'] = 'flex-end';
                        break;
                }
            }

        }
        return horStyle;
    }
}

@Pipe({ name: 'VerticalButtonStyle', pure: true })
export class VerticalButtonStylePipe implements PipeTransform {
    transform(element, RefreshCount) {

        const vertStyle = {
            'justify-content': 'center'
        };
        if (element) {
            const ver = element['vertical-align'];
            if (ver) {
                switch (ver) {
                    case 'top':
                        vertStyle['justify-content'] = 'flex-start';
                        break;
                    case 'bottom':
                        vertStyle['justify-content'] = 'flex-end';
                        break;
                }
            }
        }
        return vertStyle;
    }
}

@Pipe({ name: 'ButtonTextAlignment', pure: true })
export class ButtonTextAlignmentPipe implements PipeTransform {
    transform(element, RefreshCount) {
        const result = {};

        //#region TextAlignment
        switch (element.CaptionPosition) {
            case 'top':
                break;
            case 'right':
                break;
            case 'bottom':
                break;
            case 'left':
                break;
        }

        switch (element.HorizontalTextAlignment) {
            case Alignment.Center:
                result['text-align'] = 'center';
                break;
            case Alignment.End:
                result['text-align'] = 'end';
                break;
            case Alignment.Start:
                result['text-align'] = 'start';
                break;
            case Alignment.Stretch:
                result['text-align'] = 'justify';
                break;
        }

        switch (element.VerticalTextAlignment) {
            case Alignment.Start:
                result['align-self'] = 'flex-start';
                break;
            case Alignment.End:
                result['align-self'] = 'flex-end';
                break;
            case Alignment.Center:
                result['align-self'] = 'center';
                break;
        }
        //#endregion
        return result;
    }
}
//#endregion
//#region InputGroup
@Pipe({ name: 'InputGroup', pure: true })
export class InputGroupPipe implements PipeTransform {
    transform(LayoutElement, RefreshCount) {
        const result = {};
        result['width'] = '24px';
        const width = LayoutElement.Width;
        if (width) {
            const widthStyle = LayoutUnit.ToStyle(width);
            if (widthStyle) {
                result['width'] = widthStyle;
            }
        }
        return result;
    }
}

@Pipe({ name: 'InputGroupItemPosition', pure: true })
export class InputGroupItemPositionPipe implements PipeTransform {
    transform(items, before) {
        return items.filter((value) => value.IsBefore == before);
    }
}
//#endregion
//#region DataTable Styles
@Pipe({ name: 'DataTableHeaderStyle', pure: true })
export class DataTableHeaderStylePipe implements PipeTransform {
    transform(column, basestyle) {
        return {
            width: column.CalculatedWidth,
            //'min-width': column.CalculatedWidth,
            //'max-width': column.CalculatedWidth,
            background: basestyle.HeaderBackground,
            color: basestyle.HeaderForeground,
            'border-color': basestyle.Line
        };
    }
}
@Pipe({ name: 'DataTableCellStyle', pure: true })
export class DataTableCellStylePipe implements PipeTransform {
    transform(column, basestyle) {
        return {
            width: column.CalculatedWidth,
            //'min-width': column.CalculatedWidth,
            //'max-width': column.CalculatedWidth,
            background: column.IsSticky ? basestyle.StickyBackground : null,
            'border-color': basestyle.Line
        };
    }
}
@Pipe({ name: 'DataTableCellContent', pure: false })
export class DataTableCellContentPipe implements PipeTransform {

    static getText(data, column, enumsources) {
        let result = '';
        if (column) {
            if (column.Prefix) {
                result += column.Prefix;
            }
            if (data !== null && typeof (data) !== 'undefined') {
                if (column.Type === 'System.DateTime' && column.DateFormat) {
                    const locMom = moment(data);
                    if (column.DateFormatCulture) {
                        locMom.locale(column.DateFormatCulture);
                    }
                    result += locMom.format(column.DateFormat);
                } else if (column.Type === 'System.Double' && column.NumericPrecision != null) {
                    result += parseFloat(data).toFixed(column.NumericPrecision);
                } else if (column.IsEnum && enumsources) {
                    const enumSource = enumsources[column.Type3];
                    if (enumSource) {
                        const kv = enumSource.find(x => x.Value === data);
                        if (kv) {
                            if (TranslateHelper.TranslatorInstance) {
                                result += TranslateHelper.TranslatorInstance.instant(kv.Caption);
                            } else {
                                result += kv.Caption;
                            }
                        } else {
                            result += data;
                        }
                    } else {
                        result += data;
                    }
                } else if (column.Type2 === "a0c232a9-4ab7-444c-a7e2-d23899a5673b" && typeof data === 'object') {
                    result += TranslatedString.GetTranslation(data);
                } else {
                    result += data;
                }
            }
            if (column.Suffix) {
                result += column.Suffix;
            }
        }
        return result;
    }

    transform(row, column, enumsources) {
        return DataTableCellContentPipe.getText(row.data[column.Name], column, enumsources);
    }
}

@Pipe({ name: 'DataTableIconCellContent', pure: false })
export class DataTableIconCellContentPipe implements PipeTransform {
    transform(row, column, enumsources) {
        const data = row.data[column.Name];
        let retVal = '';
        if (column) {
            if (column.IconMapping && data !== null && typeof (data) !== 'undefined') {
                if (column.IsEnum) {
                    retVal = column.IconMapping[data];
                } else if (['a2eb35c4-56d5-4acc-a58e-ef855cb5086b', 'c02230ee-05fb-46d2-ab58-5b9dde1f32d5', 'a4299f61-eb3f-4b29-8c4e-144458d17048'].indexOf(column.Type2) > -1) {
                    const numVal = Number(data);
                    if (!isNaN(numVal)) {
                        Object.keys(column.IconMapping).some(k => {
                            const splits = k.split('|');
                            let from = Number.NaN;
                            let to = Number.NaN;
                            if (splits[0]) {
                                from = Number(splits[0]);
                            }
                            if (splits.length > 1 && splits[1]) {
                                to = Number(splits[1]);
                            }
                            if (isNaN(from)) {
                                if (!isNaN(to) && numVal < to) {
                                    retVal = column.IconMapping[k];
                                    return true;
                                }
                            } else {
                                if (isNaN(to)) {
                                    if (from <= numVal) {
                                        retVal = column.IconMapping[k];
                                        return true;
                                    }
                                } else {
                                    if (from <= numVal && numVal < to) {
                                        retVal = column.IconMapping[k];
                                        return true;
                                    }
                                }
                            }
                            return false;
                        });
                    }
                } else if (column.Type2 == 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69') {
                    const locMom = moment(data);
                    if (locMom.isValid()) {
                        Object.keys(column.IconMapping).some(k => {
                            const splits = k.split('|');
                            let from;
                            let to;
                            if (splits[0]) {
                                from = moment(splits[0]);
                            }
                            if (splits.length > 1 && splits[1]) {
                                to = moment(splits[1]);
                            }
                            if (from && from.isValid()) {
                                if (to && to.isValid()) {
                                    if (locMom.isSameOrAfter(from) && locMom.isBefore(to)) {
                                        retVal = column.IconMapping[k];
                                        return true;
                                    }
                                } else {
                                    if (locMom.isSameOrAfter(from)) {
                                        retVal = column.IconMapping[k];
                                        return true;
                                    }
                                }
                            } else {
                                if (to && to.isValid()) {
                                    if (locMom.isBefore(to)) {
                                        retVal = column.IconMapping[k];
                                        return true;
                                    }
                                }
                            }
                            return false;
                        });
                    }
                } else {
                    const text = DataTableCellContentPipe.getText(data, column, enumsources);
                    if (text) {
                        retVal = column.IconMapping[text];
                    }
                }
            }
            if (!retVal) {
                retVal = column.DefaultIcon;
            }
        }
        return retVal;
    }
}

//#endregion
//#region TextArea
@Pipe({ name: 'TextareaStyle', pure: true })
export class TextareaStylePipe implements PipeTransform {
    transform(BaseStyle, element) {


        switch (element.Resizeable) {
            case Resizeable.none:
                BaseStyle['resize'] = 'none';
                break;
            case Resizeable.horizontal:
                BaseStyle['resize'] = 'horizontal';
                break;
            case Resizeable.vertical:
                BaseStyle['resize'] = 'vertical';
                break;
            default:
                BaseStyle['resize'] = 'both';
                break;
        }
        return BaseStyle;
    }
}
//#endregion
//#endregion
@Pipe({ name: 'DisplayName', pure: true })
export class DisplayNamePipe implements PipeTransform {
    transform(element, CaptionGetter) {
        if (Array.isArray(element)) {
            let retVal = '';
            if (CaptionGetter) {
                for (let i = 0; i < element.length; i++) {
                    const item = element[i];
                    if (i > 0) {
                        retVal += ', ';
                    }
                    retVal += CaptionGetter.GetCaption(item);
                }
            }
            return retVal;
        } else {
            if (CaptionGetter) {
                return CaptionGetter.GetCaption(element);
            }
        }
        return '';
    }
}

@Pipe({ name: 'DisplayMember', pure: true })
export class DisplayMemberPipe implements PipeTransform {
    transform(element, displayMemberPath) {
        if (Array.isArray(element)) {
            const list = [];
            if (displayMemberPath) {
                element.forEach(x => {
                    if (typeof x === 'object') {
                        list.push(x[displayMemberPath]);
                    } else {
                        list.push(x);
                    }
                });
            } else {
                list.push(...element);
            }
            return list.join(', ');
        } else {
            if (displayMemberPath && typeof element === 'object') {
                return element[displayMemberPath];
            } else {
                return '' + element;
            }
        }
    }
}

@Pipe({ name: 'ComboDisplayName', pure: true })
export class ComboDisplayNamePipe implements PipeTransform {
    transform(element, CaptionGetter) {
        if (CaptionGetter && element) {
            if (Array.isArray(element)) {
                let retVal = '';
                for (let i = 0; i < element.length; i++) {
                    const item = element[i];
                    if (i > 0) {
                        retVal += ', ';
                    }
                    retVal += CaptionGetter.GetCaption(item.data);
                }
                return retVal;
            } else {
                return CaptionGetter.GetCaption(element.data);
            }
        }
        return '';
    }
}

@Pipe({ name: 'IconStyle', pure: true })
export class IconStylePipe implements PipeTransform {
    transform(LayoutElement, RefreshCount) {
        const result = {};
        result['width'] = '24px';
        result['height'] = '24px';
        const iconWidth = LayoutElement.IconWidth;
        if (iconWidth) {
            const widthStyle = LayoutUnit.ToStyle(iconWidth);
            if (widthStyle) {
                result['width'] = widthStyle;
            }
        }
        const iconHeight = LayoutElement.IconHeight;
        if (iconHeight) {
            const heightStyle = LayoutUnit.ToStyle(iconHeight);
            if (heightStyle) {
                result['height'] = heightStyle;
            }
        }

        //#region IconPadding
        if (LayoutElement.IconPadding) {
            let style = LayoutUnit.ToStyle(LayoutElement.IconPadding.Left);
            if (LayoutElement.IconPadding.Left && style) {
                result['padding-left'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconPadding.Top);
            if (LayoutElement.IconPadding.Top && style) {
                result['padding-top'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconPadding.Right);
            if (LayoutElement.IconPadding.Right && style) {
                result['padding-right'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconPadding.Bottom);
            if (LayoutElement.IconPadding.Bottom && style) {
                result['padding-left'] = style;
            }
        }
        //#endregion


        //#region IconPadding
        if (LayoutElement.IconMargin) {
            let style = LayoutUnit.ToStyle(LayoutElement.IconMargin.Left);
            if (LayoutElement.IconMargin.Left && style) {
                result['margin-left'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconMargin.Top);
            if (LayoutElement.IconMargin.Top && style) {
                result['margin-top'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconMargin.Right);
            if (LayoutElement.IconMargin.Right && style) {
                result['margin-right'] = style;
            }
            style = LayoutUnit.ToStyle(LayoutElement.IconMargin.Bottom);
            if (LayoutElement.IconMargin.Bottom && style) {
                result['margin-bottom'] = style;
            }
        }
        //#endregion

        //#region IconAlignment
        switch (LayoutElement.IconAlignment) {
            case Alignment.Start:
                result['align-self'] = 'flex-start';
                break;
            case Alignment.End:
                result['align-self'] = 'flex-end';
                break;
            case Alignment.Center:
                result['align-self'] = 'center';
                break;
            case Alignment.Stretch:
                result['align-self'] = 'stretch';
                break;
        }
        //#endregion

        return result;
    }
}

@Pipe({ name: 'ConvertLayoutElement', pure: true })
export class ConvertLayoutElementPipe implements PipeTransform {
    transform(element) {
        const retVal = new LayoutElement();
        retVal.init(element);
        return retVal;
    }
}

@Pipe({ name: 'ContentPosition', pure: true })
export class ContentPositionPipe implements PipeTransform {
    LayoutElement;
    StyleRef;
    WorkflowStyles;
    MediaSource: any;
    transform(LayoutElement, RefreshCount) {
        const result = {};
        this.LayoutElement = LayoutElement;
        if (LayoutElement && LayoutElement.Element) {
            this.StyleRef = LayoutElement.Element.StyleRef;
            this.WorkflowStyles = LayoutElement.Element.WorkflowStyles;
            this.MediaSource = LayoutElement.Element.MediaSource;

            if (this.StyleRef) {
                this.BuildStyle(this.StyleRef, result);
            }
            if (LayoutElement) {
                this.BuildStyle(LayoutElement, result);
            }
            if (this.WorkflowStyles && this.WorkflowStyles.length > 0) {
                this.WorkflowStyles.forEach(x => {
                    this.BuildStyle(x, result);
                });
            }
        }
        return result;
    }
    BuildStyle(element, result) {
        //#region TextAlignment
        switch (element.HorizontalTextAlignment) {
            case Alignment.Center:
                result['text-align'] = 'center';
                break;
            case Alignment.End:
                result['text-align'] = 'end';
                break;
            case Alignment.Start:
                result['text-align'] = 'start';
                break;
            case Alignment.Stretch:
                result['text-align'] = 'justify';
                break;
        }
        if (element.ElementType === 'checkbox' || element.ElementType === 'icon' || element.ElementType === 'textblock') {
            switch (element.VerticalTextAlignment) {
                case Alignment.Start:
                    result['align-items'] = 'start';
                    break;
                case Alignment.End:
                    result['align-items'] = 'end';
                    break;
                case Alignment.Center:
                    result['align-items'] = 'center';
                    break;
            }
        } else {
            switch (element.VerticalTextAlignment) {
                case Alignment.Start:
                    result['vertical-align'] = 'top';
                    break;
                case Alignment.End:
                    result['vertical-align'] = 'bottom';
                    break;
                case Alignment.Center:
                    result['vertical-align'] = 'middle';
                    break;
            }
        }
        //#endregion
    }
}

@Pipe({ name: 'LayoutItemHover', pure: true })
export class LayoutItemHoverPipe implements PipeTransform {
    transform(Selected, Hover) {
        if (Selected) {
            return 'layoutitem-edit-overlay-selected';
        }
        if (Hover) {
            return 'layoutitem-edit-overlay-hover';
        }
        return null;
    }
}

@Pipe({ name: 'GridItemHover', pure: true })
export class GridItemHoverPipe implements PipeTransform {
    transform(Selected, Hover) {
        if (Selected) {
            return 'editItem';
        }
        if (Hover) {
            return '';
        }
        return null;
    }
}

@Pipe({ name: 'TextBoxStyle', pure: true })
export class TextBoxStylePipe implements PipeTransform {
    transform(LayoutElement, RefreshCount) {
        const result = {};
        if (LayoutElement && LayoutElement.Element) {
            const styleRef = LayoutElement.Element.StyleRef;
            if (styleRef && Object.keys(styleRef).length > 0) {
                this.BuildStyle(styleRef, result);
            }
            this.BuildStyle(LayoutElement, result);
            const wfStyles = LayoutElement.Element.WorkflowStyles;
            if (wfStyles && wfStyles.length > 0) {
                wfStyles.forEach(x => {
                    this.BuildStyle(x, result);
                });
            }
        }
        return result;
    }
    BuildStyle(element, result) {
        if (element.Font && element.Font.Underline != null) {
            if (element.Font.Underline) {
                result['text-decoration'] = 'underline';
            } else {
                delete result['text-decoration'];
            }
        }
        if (element.Cursor) {
            result['cursor'] = element.Cursor;
        }
    }
}

@Pipe({ name: 'PropertyGroupStyle', pure: true })
export class PropertyGroupStylePipe implements PipeTransform {
    transform(value) {
        let retVal:any  = {}
        
        if (value && value.PropertyGroup) {
            let item = value.PropertyGroup;
            if (item.Display == PropertyGroupDisplay.Grid) {
                retVal.display = 'grid';
                if (item.Columns) {
                    retVal['grid-template-columns'] = "";
                    item.Columns.forEach((col) => {
                        retVal['grid-template-columns'] += col+" ";
                    });
                }
                if (item.Rows) {
                    retVal['grid-template-rows'] = "";
                    item.Rows.forEach((row) => {
                        retVal['grid-template-rows'] += row+" ";
                    });
                }
            }
            if (item.Display == PropertyGroupDisplay.Flex) {
                retVal.display = 'flex';
            }
        }
        return retVal;
    }
}
@Pipe({ name: 'ShadowPreview', pure: false })
export class ShadowPreviewPipe implements PipeTransform {
    transform(BoxShadow: any) {
        let result = {};
        if (BoxShadow) {
            if (Array.isArray(BoxShadow) && BoxShadow.length > 0) {
                let shadowtext = '';
                for (let i = 0; i < BoxShadow.length; i++) {
                    const item = BoxShadow[i];
                    let line = item.ShiftRight + 'px ' + item.ShiftDown + 'px ' + item.Blur + 'px ' + item.Spread + 'px ';
                    if (item.Opacity != null) {
                        let opa = item.Opacity;
                        if (typeof item.Opacity != 'number') {
                            opa = parseFloat(item.Opacity)
                        }
                        if (opa == 1) {
                            line += item.Color;
                        } else {
                            let color = Color.FromHex(item.Color);
                            color.A = Math.round(opa * 255);
                            line += Color.GetStyleText(color);
                        }
                    } else {
                        line += item.Color;
                    }
                    if ((i + 1) < BoxShadow.length) {
                        line += ', ';
                    }
                    shadowtext += line;
                }
                if (shadowtext) {
                    result['box-shadow'] = shadowtext;
                }
            }else if (typeof BoxShadow == 'object') {
                const item = BoxShadow;
                let line = item.ShiftRight + 'px ' + item.ShiftDown + 'px ' + item.Blur + 'px ' + item.Spread + 'px ';
                if (item.Opacity != null) {
                    let opa = item.Opacity;
                    if (typeof item.Opacity != 'number') {
                        opa = parseFloat(item.Opacity)
                    }
                    if (opa == 1) {
                        line += item.Color;
                    } else {
                        let color = Color.FromHex(item.Color);
                        color.A = Math.round(opa * 255);
                        line += Color.GetStyleText(color);
                    }
                } else {
                    line += item.Color;
                }
                
                result['box-shadow'] = line;
            }
        }
        
        return result;
    }

}
@Pipe({ name: 'SectionStyle', pure: true })
export class SectionStylePipe implements PipeTransform {
    transform(value) {
        let retVal: any = {}

        if (value) {
            if (value.Display == PropertyGroupDisplay.Grid) {
                retVal.display = 'grid';
                if (value.Columns) {
                    retVal['grid-template-columns'] = "";
                    value.Columns.forEach((col) => {
                        retVal['grid-template-columns'] += col + " ";
                    });
                }
                if (value.Rows) {
                    retVal['grid-template-rows'] = "";
                    value.Rows.forEach((row) => {
                        retVal['grid-template-rows'] += row + " ";
                    });
                }
            }
            if (value.Display == PropertyGroupDisplay.Flex) {
                retVal.display = 'flex';
            }
        }
        return retVal;
    }
}
@Pipe({ name: 'GenericItemLabelStyle', pure: true })
export class GenericItemLabelStylePipe implements PipeTransform {
    transform(item) {
        let retVal: any = {};//{ 'margin-right': (i + 1) % Columns > 0 ? '5px' : '0px', 'margin-left': (i + 1) % Columns == 0 ? '5px' : '0px' }

        if (item) {
            if (item.Label) {
                switch (item.LabelPosition) {
                    case MenuTabLabelPosition.Top:
                        retVal['padding-bottom'] = '5px';
                        break;
                    case MenuTabLabelPosition.Left:
                        retVal['padding-right'] = '5px';
                        break;
                    case MenuTabLabelPosition.Right:
                        retVal['padding-left'] = '5px';
                        break;
                    case MenuTabLabelPosition.Bottom:
                        retVal['padding-top'] = '5px';
                        break;
                    default:
                        retVal['padding-bottom'] = '5px';
                        break;
                }
                if (item.LabelMinSize) {
                    retVal['min-width'] = item.LabelMinSize;
                }
            }
        }
        return retVal;
    }
}
@Pipe({ name: 'GenericItemStyle', pure: true })
export class GenericItemStylePipe implements PipeTransform {
    transform(item) {
        let retVal: any = {};//{ 'margin-right': (i + 1) % Columns > 0 ? '5px' : '0px', 'margin-left': (i + 1) % Columns == 0 ? '5px' : '0px' }

        if (item) {
            retVal['overflow'] = 'hidden';
            retVal['padding'] = '5px';
            if (item.Row) {
                if (item.RowSpan) {
                    retVal['grid-row-start'] = item.Row;
                    retVal['grid-row-end'] = item.Row + item.RowSpan;
                } else {
                    retVal['grid-row'] = item.Row;
                }
            }
            if (item.Column) {
                if (item.ColSpan) {
                    retVal['grid-column-start'] = item.Column;
                    retVal['grid-column-end'] = item.Column + item.ColSpan;
                } else {
                    retVal['grid-column'] = item.Column;
                }
            }
            if (item.Label && item.LabelPosition) {
                retVal['display'] = 'flex';
                switch (item.LabelPosition) {
                    case MenuTabLabelPosition.Top:
                        retVal['flex-direction'] = 'column';
                        retVal['align-items'] = 'stretch';
                        break;
                    case MenuTabLabelPosition.Left:
                        retVal['flex-direction'] = 'row';
                        retVal['align-items'] = 'center';
                        break;
                    case MenuTabLabelPosition.Right:
                        retVal['flex-direction'] = 'row-reverse';
                        retVal['align-items'] = 'center';
                        break;
                    case MenuTabLabelPosition.Bottom:
                        retVal['flex-direction'] = 'column-reverse';
                        retVal['align-items'] = 'stretch';
                        break;
                }
            }
        }
        return retVal;
    }
}

@Pipe({ name: 'BootstrapGridItemClass', pure: true })
export class BootstrapGridItemClassPipe implements PipeTransform {
    transform(item, RefreshCount) {
        let retVal = "";
        if (item.ClassDefinitions) {
            let count = item.ClassDefinitions.length
            for (let i = 0; i < count; i++) {
                let cls = item.ClassDefinitions[i];
                if (cls.Column) {
                    retVal += 'col-' + cls.Column;
                }
                if (cls.ColumnOffset) {
                    retVal += 'col-offset-' + cls.ColumnOffset;
                }
                if (cls.ResponsiveSize != null) {
                    retVal += ' ' + PrimeFlexSizes[cls.ResponsiveSize];
                    if (i + 1 < count) {
                        retVal += ':'
                    }
                }
            }
        }
        return retVal;
    }
}
@Pipe({ name: 'CSSClass', pure: true })
export class CSSClassPipe implements PipeTransform {
    transform(item, RefreshCount) {
        let retVal = "";
        if (item.CSSClasses && item.CSSClasses.length > 0) {
            item.CSSClasses.forEach((css) => {
                retVal += 'customerclass_' + css + ' ';
            })
        }
        if (item.UseBoxed) {
            retVal += 'p-container';
            if (item.BoxedSize != null) {
                retVal += '-' + PrimeFlexSizes[item.BoxedSize] + ' ';
            }
        }
        return retVal;
    }
}