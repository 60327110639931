import { Observable, Subject } from 'rxjs';
import { LayoutEditDialog } from '../components/dialogs/layouteditdialog/layout.edit.dialog';
import { MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../models/enums/messageboxresult.enum';
import { ILayoutEditClient, LayoutEditService } from '../models/layout/layout.edit.client';
import { MetaService } from '../services/meta.service';
import { UsersService } from '../services/users.service';
import { TranslateFormatText } from './array.helpers';
import { LayoutHelper } from './layout.helper';
import { NotificationHelper } from './notification.helper';

export class TemplateEditClient implements ILayoutEditClient {
    private ViewModeValue = true;
    get ViewMode(): boolean {
        return this.ViewModeValue;
    }
    private CanCheckOutValue = false;
    get CanCheckOut(): boolean {
        return this.CanCheckOutValue;
    }
    private CanSaveValue = false;
    get CanSave(): boolean {
        return this.CanSaveValue;
    }
    get Layout() {
        return this.layout;
    }

    private TemplateInfoChangedSubject: Subject<any> = new Subject();
    TemplateInfoChanged: Observable<any> = this.TemplateInfoChangedSubject;

    private TempLayout;

    constructor(private layout, private templateInfo, private service: MetaService) {
        if (layout) {
            LayoutHelper.CheckTemplate(layout);
            this.TempLayout = LayoutHelper.ParseLayout(layout);
        }
        if (templateInfo && templateInfo.CanEditInfo && templateInfo.CanEditInfo.CanEdit) {
            const user = UsersService.ActiveUser.getValue();
            if (user && templateInfo.CanEditInfo.EditUserID == user.SID) {
                this.CanSaveValue = true;
                this.ViewModeValue = false;
            } else {
                LayoutEditDialog.ShowDialog((x) => {
                    if (typeof x === 'boolean' && x) {
                        this.onCheckOut();
                    } else {
                        this.CanCheckOutValue = true;
                        LayoutEditService.LayoutEditClientChange.next();
                    }
                });
            }
        }
    }

    onCheckOut() {
        if (this.templateInfo && this.service && this.layout) {
            this.service.RequestTemplateLock(this.layout).subscribe(x => {
                if (x && x.CanEditInfo) {
                    this.templateInfo = x;
                    this.TemplateInfoChangedSubject.next(x);
                    this.TempLayout = LayoutHelper.ParseLayout(this.layout);
                    if (x.CanEditInfo.CanEdit) {
                        const text = new TranslateFormatText('@@Template \'{0}\' erfolgreich gespeichert.');
                        text.FormatParams.push(x.Caption);
                        NotificationHelper.Success(text, new TranslateFormatText('@@Template speichern'));
                        this.ViewModeValue = false;
                        this.CanCheckOutValue = false;
                        this.CanSaveValue = true;
                        LayoutEditService.LayoutEditClientChange.next();
                    } else {
                        const text = new TranslateFormatText(x.CanEditInfo.NewVersion ? '@@AlreadySavedBy{0}' : '@@LockedBy{0}');
                        text.FormatParams.push(x.CanEditInfo.EditUserName);
                        MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                            MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    }
                }
            });
        }
    }

    onSave() {        
        return new Promise<void>(resolve => {
            if (this.templateInfo && this.service && this.layout) {
                this.service.SaveTemplateToChangeLog(this.layout).subscribe(x => {
                    if (x) {
                        this.templateInfo = x;
                        this.TemplateInfoChangedSubject.next(x);
                        this.TempLayout = LayoutHelper.ParseLayout(this.layout);
                        const text = new TranslateFormatText('@@Template \'{0}\' erfolgreich gespeichert.');
                        text.FormatParams.push(x.Caption);
                        NotificationHelper.Success(text, new TranslateFormatText('@@Template speichern'));
                    } else {
                        NotificationHelper.Error(new TranslateFormatText('@@AlreadySavedByAdmin'),
                            new TranslateFormatText('@@Template speichern'));
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    clear() {
        return new Promise<void>(resolve => {
            if (this.CanSaveValue && this.templateInfo) {
                let askForSave = this.templateInfo.IsNew == true;
                if (!askForSave && this.layout) {
                    const changes = LayoutHelper.CompareParsed(this.TempLayout, LayoutHelper.ParseLayout(this.layout), {
                        CreateChangesTree: false,
                        DeepComparison: false
                    });
                    askForSave = changes.HasChanges;
                }
                if (askForSave) {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie vorher speichern?'),
                        new TranslateFormatText('@@Speichern'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                            if (x === MessageBoxResult.Yes) {
                                this.onSave().then(() => {
                                    this.TemplateInfoChangedSubject.complete();
                                    resolve();
                                });
                            } else {
                                this.TemplateInfoChangedSubject.complete();
                                resolve();
                            }
                        });
                } else {
                    this.TemplateInfoChangedSubject.complete();
                    resolve();
                }
            } else {
                this.TemplateInfoChangedSubject.complete();
                resolve();
            }
        });
    }
}
