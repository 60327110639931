import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BaseDialog } from '../basedialog/base.dialog';
import { WorkflowService } from '../../../services/workflow.service';
import { WebsocketService } from '../../../services/websocket.service';
import { NotificationLevel } from '../../../models/enums/notificationlevel.enum';
import { interval } from 'rxjs';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';

// @dynamic
@Component({
    selector: 'workflowdebug-dialog',
    templateUrl: './workflowdebug.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowDebugDialog implements OnInit {

    @ViewChild('editor') editor;

    HasChanges = false;
    CustomButtons = [{
        Caption: '@@Weiter',
        ID: 0
    }, {
        Caption: '@@Weiter bis Breakpoint',
        ID: 1
    }, {
        Caption: '@@Debug abbrechen',
        ID: 2
    }, {
        Caption: '@@Abbrechen',
        ID: 3
    }];
    CloseOnCustomButton = false;
    WorkflowStatus;
    Loading = true;
    editorOptions = {
        language: 'json'
    };
    Channel: string;
    DebugAliveSub;

    public static ShowDialog(channel: string) {
        return new Promise((resolve, reject) => {
            if (channel) {
                BaseDialog.ShowDialog({
                    ContentType: WorkflowDebugDialog,
                    InitArgs: { channel: channel, },
                    Title: '@@Workflow debuggen',
                    Buttons: DialogButton.None,
                    HeightText: '100vh',
                    WidthText: '100vw',
                    Handler: (val) => {
                        resolve(val);
                    }
                });
            }
        });
    }

    constructor(private wfService: WorkflowService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.Loading = false;
    }

    Initialize(initargs) {
        if (initargs.channel) {
            this.Channel = initargs.channel;
            WebsocketService.WaitForWebSocketService().then(() => {
                this.DebugAliveSub = interval(5000).subscribe((val) => {
                    this.wfService.DebugIsAlive(initargs.channel).subscribe();
                });
                const subScription = WebsocketService.MessageReceived.subscribe((response) => {
                    const parsed = JSON.parse(response);
                    if (parsed.Level === NotificationLevel.System &&
                        parsed.Type === 'evidanza.App.Shared.Workflow.Data.WorkflowStatusInfoObject') {
                        this.WorkflowStatus = parsed.Content;
                        this.cdRef.detectChanges();
                        if (this.editor?._editor) {
                            setTimeout(() => { this.editor._editor.getAction('editor.action.formatDocument').run(); }, 300);
                        }
                        if (this.WorkflowStatus.Unsubscribe === true) {
                            if (this.DebugAliveSub) {
                                this.DebugAliveSub.unsubscribe();
                            }
                            subScription.unsubscribe();
                        }
                    }
                });
            });
        }
    }

    OnChanged(editor) {
        setTimeout(() => { editor.getAction('editor.action.formatDocument').run(); }, 300);
    }

    OnCustomButtonClicked(button) {
        if (button) {
            switch (button.ID) {
                case 0:
                    this.wfService.NextWorkflowDebugStep(this.Channel, false).subscribe();
                    break;
                case 1:
                    this.wfService.NextWorkflowDebugStep(this.Channel, true).subscribe();
                    break;
                case 2:
                    this.wfService.CancelWorkflowDebug(this.Channel).subscribe();
                    if (this.DebugAliveSub) {
                        this.DebugAliveSub.unsubscribe();
                    }
                    break;
                case 3:
                    this.wfService.CancelWorkflowDebug(this.Channel).subscribe();
                    if (this.DebugAliveSub) {
                        this.DebugAliveSub.unsubscribe();
                    }
                    if (this['BaseDialog']) {
                        this['BaseDialog'].dialogRef.close(null);
                    }
                    break;
            }
        }
    }
}
