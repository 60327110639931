import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { SettingsService } from "../../../../../services/settings.service";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'onedrive-panel',
    templateUrl: './onedrive.panel.html',
    styleUrls: ['./onedrive.panel.css']
})
export class OneDrivePanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = OneDrivePanel;
    }

    get RedirectUrl(): string {
        var ret = SettingsService.API_BASE_URL.getValue() + 'config/api/media/oauth/authorizemicrosoft';
        this.MediaSource.RedirectUrl = ret;
        this.MediaSource.Private = true; 
        return ret;
    }

    set RedirectUrl(val) {
        if (val != this.MediaSource.RedirectUrl) {
            this.MediaSource.RedirectUrl = val;
        }
    }

    authenticate(): void {
        let authstring = 'https://login.microsoftonline.com/' + this.MediaSource.TenantId + '/oauth2/v2.0/authorize?client_id=' + this.MediaSource.ClientId + '&response_type=code&redirect_uri=' + this.MediaSource.RedirectUrl + '&state=' + this.MediaSource.SID + '&response_mode=query&scope=offline_access https://graph.microsoft.com/Files.ReadWrite.All https://graph.microsoft.com/User.Read';
        window.open(authstring, '_blank');
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: OneDrivePanel,
            MediaType: '8F51EEC7-1DF6-4481-B76B-EB83C0BAC302'.toLowerCase()
        };
    }
}