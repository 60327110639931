import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiCacheService } from '../../../../../cache/multi/cache.service';
import { Level } from '../../../../../cache/multi/models/database.model';
import { FilterHelper } from '../../../../../helpers/filter.helper';
import { DataDescription } from '../../../../../models/datadescription/multi/datadescription.model';
import { MeasureInfo } from '../../../../../models/datadescription/multi/measureinfo.model';
import { DimensionType } from '../../../../../models/enums';
import { OcDragItemType } from '../../../../../models/enums/oc.DragItemType';
import { DynamicTimeRange } from '../../../../../models/time/dynamictimerange.model';
import { DynamicTimeSettingsService } from '../../../../../services/dynamictimesettings.service';
import { LayoutService } from '../../../../../services/layout.service';
import { DatadescriptionControl } from '../../../../dialogs/datadescription/datadescription.control';

@Component({
    selector: 'dynamictimereferenceControl',
    templateUrl: './dynamictimereference.control.html',
    styleUrls: ['./dynamictimereference.control.css']
})
export class DynamicTimeReferenceControl implements OnInit, OnDestroy {

    DynamicTimePoints = [];

    DataDescriptionValue: DataDescription;
    MeasureDynamicTimeReferencing: DynamicTimeRange;
    MeasureDynamicTimeReferencingLevelValue: Level;

    SelectedMeasureValue: MeasureInfo;
    Variables: any[];
    @Input()
    get SelectedMeasure() {
        return this.SelectedMeasureValue;
    }
    set SelectedMeasure(value) {
        let mdtr;
        this.SelectedMeasureValue = value;
        this.MeasureDynamicTimeReferencingLevelValue = null;
        if (this.SelectedMeasureValue && this.SelectedMeasureValue.Details) {
            if (!this.SelectedMeasureValue.Details.MeasureDynamicTimeReferencing) {
                this.SelectedMeasure.Details.MeasureDynamicTimeReferencing = new DynamicTimeRange();
            }
            mdtr = this.SelectedMeasureValue.Details.MeasureDynamicTimeReferencing;
            MultiCacheService.GetLevel(mdtr.LevelID, this.DataDescription.DataModelID).then(level => {
                if (level) {
                    mdtr.LevelID = level.ID;
                    mdtr.HierarchyID = level.Parent.ID;
                    this.MeasureDynamicTimeReferencingLevelValue = level;
                }
            });
        }
        this.MeasureDynamicTimeReferencing = mdtr;
    }

    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
    }

    Subscription;
    DraggedLevel;

    constructor(private service: DynamicTimeSettingsService) {
    }

    ngOnInit(): void {
        this.service.GetDynamicTimePoints().subscribe(r => {
            if (r) {
                this.DynamicTimePoints = r;
            }
        });
        this.Variables = FilterHelper.GetVariables(LayoutService.SelectedLayout.getValue());
        this.Subscription = DatadescriptionControl.ObjectCreatorMPDragStart.subscribe((state) => {
            this.DraggedLevel = null;
            if (state && state.DragType === OcDragItemType.Level && state.DragContent) {
                MultiCacheService.GetLevel(state.DragContent.DraggedID, this.DataDescription.DataModelID).then(level => {
                    if (level && level.Parent.Parent.DimensionType === DimensionType.Time) {
                        this.DraggedLevel = level;
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }

    itemDrop(event) {
        if (this.DraggedLevel) {
            this.MeasureDynamicTimeReferencing.LevelID = this.DraggedLevel.ID;
            this.MeasureDynamicTimeReferencing.HierarchyID = this.DraggedLevel.Parent.ID;
            this.MeasureDynamicTimeReferencingLevelValue = this.DraggedLevel;
        }
    }

    deleteLevelID() {
        if (this.MeasureDynamicTimeReferencing) {
            this.MeasureDynamicTimeReferencing.LevelID = undefined;
            this.MeasureDynamicTimeReferencing.HierarchyID = undefined;
        }
        this.MeasureDynamicTimeReferencingLevelValue = null;
    }
}
