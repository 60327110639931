import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../helpers/injector.helper';

export class TranslatedString {
    DefaultValue = '';
    SerializedTranslations: TranslatedStringElement[] = [];

    static GetTranslation(translatedString): string {
        if (translatedString) {
            if (Array.isArray(translatedString.SerializedTranslations) && translatedString.SerializedTranslations.length > 0) {
                const lcid = parseInt(TranslateHelper.TranslatorInstance.currentLang, 10);
                if (!isNaN(lcid)) {
                    const selected = translatedString.SerializedTranslations.find(x => x.LCID === lcid);
                    if (selected) {
                        return selected.Value;
                    }
                }
            }
            if (Array.isArray(translatedString.Translations) && translatedString.Translations.length > 0) {
                const lcid = parseInt(TranslateHelper.TranslatorInstance.currentLang, 10);
                if (!isNaN(lcid)) {
                    const selected = translatedString.Translations.find(x => x.__key === lcid);
                    if (selected) {
                        return selected.__value;
                    }
                }
            }
            if (typeof translatedString.DefaultValue === 'string') {
                return translatedString.DefaultValue;
            }
        }
        return '';
    }

    constructor(defaultValue?: string) {
        if (defaultValue) {
            this.DefaultValue = defaultValue;
        }
    }

    toString() {
        return TranslatedString.GetTranslation(this);
    }

    clone(): TranslatedString {
        let retVal;
        retVal = plainToClass(TranslatedString, JSON.parse(JSON.stringify(this)));
        return retVal;
    }

    toJSON() {
        let result;
        result = Object.assign({}, this);
        delete result.Value; // Value nicht mit nach unten geben, da dieser sonst ge�nderte SerializedTranslations �berschreibt
        return result;
    }
}

export class TranslatedStringElement {
    LCID = 0;
    Value = '';
}
