import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { PlanningContentFormattingTaskExecuter } from '../../../../models/tasks/planning/content.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ATaskPanel } from '../../a.task.panel';

@Component({
    selector: 'content-planning-task-panel',
    templateUrl: './content.planning.task.panel.html'
})
export class ContentPlanningTaskPanel extends ATaskPanel {

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = PlanningContentFormattingTaskExecuter.TaskID;
        reg.Caption = '@@Content';
        reg.SettingsControl = ContentPlanningTaskPanel;
        reg.SettingsHelper = new ContentPlanningTaskSettingsHelper();
        reg.Executer = PlanningContentFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }    
}

export class ContentPlanningTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsPlanningObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Contentformatierung');
    }
}
