import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { ArrayHelpers, TranslateFormatText } from '../../../helpers/array.helpers';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { DefineValueSettings, WorkflowFormulaCalculator } from './define.value.settings';

@Component({
    selector: 'define-multi-values-wf-settings',
    templateUrl: './define.multi.values.settings.html'
})
export class DefineMultiValuesSettings extends FormulaWorkflowDialogContent {

    Data = new DefineMultiValuesSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = DefineValueSettings.MultiModuleID;
        reg.Caption = '@@Werte definieren';
        reg.GroupID = 'generateData';
        reg.Index = 10;
        reg.SettingsControl = DefineMultiValuesSettings;
        reg.SettingsTypeHelper = new DefineMultiValuesSettingsDataHelper();
        reg.Executer = DefineMultiValuesModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DefineMultiValuesSettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Values.forEach(x => {
            if (x.Formula && (x.StatusKey || x.UseForState)) {
                values.push(x);
            }
        });
        this.Data.Values = values;
        return this.Data;
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Values) {
            this.Data.Values.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }

    addValue() {
        if (this.Data) {
            if (this.Data.Values) {
                this.Data.Values.push(new ValueSetting());
            } else {
                this.Data.Values = [new ValueSetting()];
            }
        }
    }

    removeValue(i) {
        if (this.Data && this.Data.Values) {
            this.Data.Values.splice(i, 1);
        }
    }

    showFormulaDialog(val, i) {
        const variables = [...this.Variables];
        if (this.Data && this.Data.Values) {
            let added = false;
            for (let j = 0; j < i; j++) {
                const prevVal = this.Data.Values[j];
                if (prevVal.Formula && prevVal.StatusKey && !variables.some(x => x.Name === prevVal.StatusKey)) {
                    const vni = new VariablesNodeInformation();
                    vni.AliasKey = prevVal.StatusKey;
                    vni.Name = prevVal.StatusKey;
                    vni.VariableID = prevVal.StatusKey;
                    variables.push(vni);
                    added = true;
                }
            }
            if (added) {
                ArrayHelpers.sortAlphabetical(variables, 'Name');
            }
        }
        const args = {
            InputMode: FormulaInputMode.VariableName,
            Formula: val.Formula,
            Variables: variables
        };
        FormulaEditorDialog.ShowDialog(args, (result) => {
            if (result && result.Formula) {
                val.Formula = result.Formula;
            }
            return true;
        });
    }

    onDrop(ev) {
        if (ev && ev.currentIndex !== ev.previousIndex && this.Data && this.Data.Values) {
            const droppedVal = this.Data.Values[ev.previousIndex];
            if (droppedVal.StatusKey) {
                if (ev.currentIndex < ev.previousIndex) {
                    if (droppedVal.Formula) {
                        for (let i = ev.currentIndex; i < ev.previousIndex; i++) {
                            const indexVal = this.Data.Values[i];
                            if (indexVal.StatusKey && droppedVal.Formula.indexOf(indexVal.StatusKey) > -1) {
                                const t = '@@Wert kann nicht verschoben werden, da sich seine Formel auf mindestens einen vorherigen Wert bezieht.';
                                MessageBoxHelper.ShowDialog(new TranslateFormatText(t), new TranslateFormatText('@@Fehler'),
                                    MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                                return;
                            }
                        }
                    }
                } else {
                    if (droppedVal.StatusKey) {
                        for (let i = ev.previousIndex + 1; i <= ev.currentIndex; i++) {
                            const indexVal = this.Data.Values[i];
                            if (indexVal.Formula && indexVal.Formula.indexOf(droppedVal.StatusKey) > -1) {
                                const t = '@@Wert kann nicht verschoben werden, da sich die Formel mindestens eines nachfolgenden Wertes auf ihn selbst bezieht.';
                                MessageBoxHelper.ShowDialog(new TranslateFormatText(t), new TranslateFormatText('@@Fehler'),
                                    MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                                return;
                            }
                        }
                    }
                }
            }
            moveItemInArray(this.Data.Values, ev.previousIndex, ev.currentIndex);
        }
    }
}

// @dynamic
export class DefineMultiValuesSettingsData extends AServiceWorkflowData {
    @Type(() => ValueSetting)
    Values: ValueSetting[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineValue.DefineMultiValuesSettingsData';
    }
}

export class ValueSetting {
    Formula: string;
    StatusKey: string;
    UseForState = false;
}

export class DefineMultiValuesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new DefineMultiValuesSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Values) {
                const list = [];
                settings.Values.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class DefineMultiValuesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const calculator = new WorkflowFormulaCalculator(status);
            status.ActualSettings.Values.forEach(x => {
                if (typeof x.Formula === 'string') {
                    try {
                        if (x.StatusKey) {
                            const val = calculator.CalcAndAddToStatus(x.Formula, x.StatusKey);
                            status.Context.set(x.StatusKey, val);
                            if (x.UseForState) {
                                status.ActualParameter = val;
                            }
                        } else if (x.UseForState) {
                            status.ActualParameter = calculator.CalcFormula(x.Formula);
                        }
                    } catch (ex) {
                        status.Logger.logWarning('DefineMultiValues modul: Error while calculating formula ' +
                            x.Formula + ' (' + ex + ').');
                    }
                }
            });
            return 0;
        } else {
            status.Logger.logError('DefineMultiValues modul: No settings found.');
        }
        return super.execute(status);
    }
}
