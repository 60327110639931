import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { InjectorHelper } from '../helpers/injector.helper';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { StandardRequestBase } from './request-base';
import { UsersService } from './users.service';

export interface IWebsocketService {
    Initialize(url: string);
    Start();
    Stop();
    Subscribe(channel: string): Promise<void>;
    Unsubscribe(channel: string);
}

export class WebsocketService {
    public static MessageReceived: Subject<any> = new Subject();
    public static Active: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private static Service: IWebsocketService;

    public static Initialize(url, useWorker) {
        if (useWorker == false) {
            WebsocketService.Service = new DirectWebSocket();
        } else {
            WebsocketService.Service = new WebWorkerWebSocket();        
        }
        WebsocketService.Service.Initialize(url);
    }

    public static Start() {
        WebsocketService.Service.Start();
    }

    public static Stop() {
        WebsocketService.Service.Stop();
    }

    public static Subscribe(channel) {
        return WebsocketService.Service.Subscribe(channel);
    }
    public static Unsubscribe(channel) {
        WebsocketService.Service.Unsubscribe(channel);
    }

    public static WaitForWebSocketService() {
        return new Promise<void>(resolve => {
            const active = WebsocketService.Active.getValue();
            if (active) {
                resolve();
            } else {
                const sub = WebsocketService.Active.subscribe(x => {
                    if (x) {
                        sub.unsubscribe();
                        resolve();
                    }
                });
            }
        });
    }
}

export class WebsocketSubscription {
    private Subscription: Subscription;
    private Active = true;    

    static SendNotification(channel: string, type: string, content: any) {
        if (content) {
            const user = UsersService.ActiveUser.getValue();
            if (user) {
                const service = InjectorHelper.InjectorInstance.get<StandardRequestBase>(StandardRequestBase);
                service.executePost({
                    Channels: [channel],
                    Type: type,
                    Content: JSON.stringify(content)
                }, 'config/api/notification', 'SendNotification').subscribe();
            }
        }
    }

    constructor(private Channel: string, type: string, handler) {
        WebsocketService.WaitForWebSocketService().then(() => {
            if (this.Active) {
                const sub = WebsocketService.MessageReceived.subscribe((response) => {
                    const parsed = JSON.parse(response);
                    if (parsed.Level === NotificationLevel.System &&
                        parsed.Channels && parsed.Channels.some(x => x === this.Channel) &&
                        parsed.Type === type) {
                        handler(parsed.Content ?? '');
                    }
                });
                WebsocketService.Subscribe([{ Channel: this.Channel, Level: [0] }]).then(() => {
                    this.Subscription = sub;
                    if (!this.Active) {
                        this.Unsubscribe();
                    }
                });
            }
        });
    }

    Unsubscribe() {
        this.Active = false;
        if (this.Subscription) {
            this.Subscription.unsubscribe();
            WebsocketService.Unsubscribe([{ Channel: this.Channel, Level: [0] }]);
        }
    }
}

export class WebWorkerWebSocket implements IWebsocketService {
    private Subscriptions = {};
    private Worker: Worker;

    Initialize(url) {
        const WORKER_ENABLED = !!(Worker);

        if (WORKER_ENABLED) {

            this.Worker = new Worker('assets/signalr/websocket.js');

            this.Worker.onmessage = (data) => {
                const dataObj = data.data;
                switch (dataObj.Type) {
                    case 'Active':
                        WebsocketService.Active.next(dataObj.Value);
                        break;
                    case 'Message':
                        WebsocketService.MessageReceived.next(dataObj.Value);
                        break;
                    case 'Subscription':
                        const resolve = this.Subscriptions[dataObj.Value];
                        if (resolve) {
                            resolve();
                        }
                        break;
                }
            };

            this.Worker.onerror = (data) => {
                console.log(data);
            };

            this.SendMessage('Initialize', {
                URL: url,
                Token: localStorage.getItem('token')
            });
            UsersService.TokenSet.subscribe(() => {
                this.SendMessage('Token', localStorage.getItem('token'));
            });
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {
                    this.SendMessage('Check', '');
                }
            });
        } else {
            throw new Error('WebWorker is not enabled');
        }
    }

    Start() {
        this.SendMessage('Start', '');
    }

    Stop() {
        this.SendMessage('Stop', '');
    }

    Subscribe(channel) {
        return new Promise<void>(resolve => {
            const data = {
                Channel: channel,
                ID: UUID.UUID()
            };
            this.Subscriptions[data.ID] = resolve;
            this.SendMessage('Subscribe', data);
        });
    }

    Unsubscribe(channel) {
        this.SendMessage('UnSubscribe', channel);
    }

    private SendMessage(type: string, data) {
        if (this.Worker) {
            this.Worker.postMessage({
                Type: type,
                Value: JSON.parse(JSON.stringify(data))
            });
        }
    }
}

export class DirectWebSocket implements IWebsocketService {

    Connected: boolean = false;
    private URL: string = '';
    private hubConnection: signalR.HubConnection
    private ManualClose = false;
    
    public Initialize(url) {
        this.URL = url;
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible' && (!this.hubConnection || this.hubConnection.state !== HubConnectionState.Connected)) {
                this.Start();
            }
        })
    }
    private Try = 0;
    private Timeouts = [0, 1000, 2000, 30000, 10000, null];
    public Start() {
            if (localStorage.getItem('token')) {
                this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.URL, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => {
                        return window.localStorage.getItem('token');
                    }
                }).configureLogging(signalR.LogLevel.None).build();

                this.hubConnection.onclose(() => {
                    if (!this.ManualClose) {
                        this.Reconnect();
                    }
                });

                this.hubConnection
                    .start()
                    .then(async () => {
                        await this.until(() => {
                            return this.hubConnection.state == signalR.HubConnectionState.Connected;
                        });
                        this.Try = 0;
                        this.Connected = true;
                        this.AddMessageListener();
                        this.AddInternalMessageListener();
                        WebsocketService.Active.next(true);
                    })
                    .catch(err => {
                        this.Reconnect();
                    })
            } else {
                WebsocketService.Active.next(false);
            }
    }
    public Stop() {
        if (this.hubConnection) {
            WebsocketService.Active.next(false);
            this.ManualClose = true;
            this.hubConnection.stop();
        }
    }
    private Reconnect() {
        this.Connected = false;
        WebsocketService.Active.next(false);
        if (localStorage.getItem('token')) {
            let Timeout = this.Timeouts[this.Try];
            if (Timeout == null) {
                this.ManualClose = true;
            } else {
                setTimeout(() => {
                    this.Try += 1;
                    this.Start();
                }, Timeout);
            }
        }
    }
    private AddMessageListener() {
        this.hubConnection.on('message', (message) => {
            WebsocketService.MessageReceived.next(message);
        });
    }
    private AddInternalMessageListener() {
        this.hubConnection.on('internal', (message) => {
            WebsocketService.MessageReceived.next(message);
        });
    }
    public Subscribe(channel) {
        return this.hubConnection.invoke('Subscribe', channel);
    }
    public Unsubscribe(channel) {
        this.hubConnection.invoke('UnSubscribe', channel);
    }

    private sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private async until(fn) {
        while (!fn()) {
            await this.sleep(0)
        }
    }
}