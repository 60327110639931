import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { FilterElementSettings } from '../../../models/datadescription/multi/filter/filterElementSettings.model';
import { FilterMultidimensional } from '../../../models/datadescription/multi/filter/filterMultidimensional.model';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { LayoutService } from '../../../services/layout.service';
import { FilterTreeDialog } from '../../controls/filter/filter.tree.dialog';
import { DatadescriptionControl } from '../../dialogs/datadescription/datadescription.control';

@Component({
    selector: 'filterpanelcontrol',
    templateUrl: './filterpanel.control.html',
    styleUrls: ['./filterpanel.control.css']
})
export class FilterPanelControl implements OnInit, OnDestroy {

    SystemVariables = [];
    DraggedHierarchy = null;

    DataDescriptionValue: DataDescription;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
    }

    filterItemsValue: FilterMultidimensional[] = [];
    @Input()
    get FilterItems() {
        return this.filterItemsValue;
    }
    set FilterItems(value) {
        if (Array.isArray(value)) {
            value.forEach(x => {
                FilterPanelControl.SetDefaultSelectsText(x);
            });
            this.filterItemsValue = value;
        } else {
            this.filterItemsValue = [];
        }
    }
    @Output() FilterItemsChange = new EventEmitter<any>();

    Subscription;

    private static SetDefaultSelectsText(item: FilterMultidimensional) {
        if (item) {
            item.DefaultSelectsText = '[';
            if (item.Defaults) {
                let count = 0;
                item.Defaults.forEach(ds => {
                    count += ds.MemberIds ? ds.MemberIds.length : 0;
                });
                item.DefaultSelectsText += count.toString();
            } else {
                item.DefaultSelectsText += '0';
            }
            item.DefaultSelectsText += ']';
        }
    }

    ngOnInit(): void {
        this.SystemVariables = FilterHelper.GetVariables(LayoutService.SelectedLayout.getValue());
        this.Subscription = DatadescriptionControl.ObjectCreatorMPDragStart.subscribe(x => {
            this.DraggedHierarchy = null;
            if (x && x.DragType === OcDragItemType.Level && x.DragContent) {
                MultiCacheService.GetHierarchy(x.DragContent.DraggedID, this.DataDescription.DataModelID).then(y => {
                    this.DraggedHierarchy = y;
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }

    delete(index) {
        this.FilterItems.splice(index, 1);
    }

    showFilterTreeDialog(item) {
        const FilterElementSettingsValue = new FilterElementSettings();
        FilterElementSettingsValue.CodeDesc = 3;
        FilterElementSettingsValue.DataSource = item.DatasourceID;
        FilterElementSettingsValue.DataModel = this.DataDescription.DataModelID;
        FilterElementSettingsValue.Hierarchy = item.Hierarchy;
        FilterElementSettingsValue.Levels = item.Levels;
        FilterElementSettingsValue.MultiSelect = true;
        FilterElementSettingsValue.Relational = false;
        FilterElementSettingsValue.FilterItems = this.FilterItems;
        FilterElementSettingsValue.SavedSelection = item.Defaults;
        const args = {
            FilterElementSettingsValue
        };
        FilterTreeDialog.ShowDialog(args, result => {
            if (result && result.FilterSelection) {
                item.Defaults = result.FilterSelection;
                FilterPanelControl.SetDefaultSelectsText(item);
            }
            return true;
        });
    }

    clearSelects(item) {
        if (item) {
            item.Defaults = [];
            item.DefaultSelectsText = '[0]';
        }
    }

    itemDrop(event) {
        if (this.DraggedHierarchy && !this.FilterItems.some(x => x.Hierarchy === this.DraggedHierarchy.ID)) {
            const filterItem = new FilterMultidimensional();
            filterItem.Hierarchy = this.DraggedHierarchy.ID;
            filterItem.Levels = [];
            this.DraggedHierarchy.Levels.forEach(l => {
                filterItem.Levels.push(l.ID);
            });
            filterItem.Dimension = this.DraggedHierarchy.Parent.ID;
            filterItem.Caption = this.DraggedHierarchy.Caption;
            filterItem.DatasourceID = this.DraggedHierarchy.Parent.DatasourceID;
            this.FilterItems.push(filterItem);
            this.DraggedHierarchy = null;
        }
    }
}
