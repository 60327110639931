import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class WebPushService extends RequestBase {


    BasePath = 'api/webpush';

    public static ActiveSubscription: BehaviorSubject<any> = new BehaviorSubject(null);
    public static UseWebPush: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(http: HttpClient) {
        super(http);
    }

    SaveSubscription(sub): Observable<any> {
        WebPushService.ActiveSubscription.next(sub);
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveSubscription', sub, this.options);
    }

    deleteSubscription(sub): Observable<any>  {
        WebPushService.ActiveSubscription.next(null);
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/DeleteSubscription', sub, this.options);
    }
}