import { Component } from '@angular/core';
import { ADataPreviewSettings } from '../common/adatapreview.settings';

@Component({
    selector: 'container-xls-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerExcelSettings extends ADataPreviewSettings {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Container.ContainerExcel';
    }

    getDBTypes(): number[][] {
        return [[256]]; // DataSourceRead
    }

    getResourceBase(): number {
        return 1;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerExcel',
            ID: 'containerexcel',
            Icon: 'table_chart',
            Index: 20,
            Parent: 'relational',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 8
            },
            Content: ContainerExcelSettings
        }
    }
}