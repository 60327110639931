import { AfterViewInit, ChangeDetectorRef, Component, Inject, InjectionToken, Input } from '@angular/core';
import { ScriptParser } from '../../script/modules/parser.script';
import { ConnectorService } from '../../services/connector.service';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
declare var monaco: any;

@Component({
    selector: 'debug-script-breakpoint-control',
    templateUrl: './debug.script.breakpoint.control.html',
    styleUrls: ['./debug.script.breakpoint.control.css']
})
export class DebugScriptBreakpointControl implements AfterViewInit {    
    editorOptions = {
        automaticLayout: true,
        language: 'typescript',
        readOnly: true,
        glyphMargin: true,
        scrollBeyondLastLine: false
    };
    editor;
    PossibleBreakpoints = {};

    get ActualScript() {
        if (this.ExternalScriptValue) {
            const loaded = this.LoadedScripts[this.ExternalScriptValue];
            if (loaded) {
                return loaded.Script;
            }
            return '';
        } else {
            return this.ScriptValue;
        }
    }

    //#region Script
    ScriptValue;
    @Input()
    get Script() {
        return this.ScriptValue;
    }
    set Script(val) {
        if (val != this.ScriptValue) {
            this.ScriptValue = val;
            this.PossibleBreakpoints = this.getPossibleBreakpoints(val);
        }
    }
    //#endregion

    //#region ExternalScript
    LoadedScripts = {};
    ExternalScriptValue;
    @Input()
    get ExternalScript() {
        return this.ExternalScriptValue;
    }
    set ExternalScript(val) {
        let isConnector = false;
        if (typeof val === 'object' && val.ScriptLibraryID) {
            if (typeof val.IsConnectorScript === 'boolean') {
                isConnector = val.IsConnectorScript
            }
            val = val.ScriptLibraryID;
        }
        if (val != this.ExternalScriptValue) {
            if (val) {
                let loadedEntry = this.LoadedScripts[val];
                if (loadedEntry) {
                    this.ExternalScriptValue = val;
                } else {
                    if (isConnector) {
                        this.conService.GetScript(val).subscribe(x => {
                            loadedEntry = {
                                Script: '',
                                PossibleBreakpoints: {}
                            };
                            if (x && x.Script) {
                                loadedEntry.Script = x.Script.Text;
                                loadedEntry.PossibleBreakpoints = this.getPossibleBreakpoints(x.Script.Text);
                            }
                            this.LoadedScripts[val] = loadedEntry;
                            this.ExternalScriptValue = val;
                            this.cdRef.detectChanges();
                        });
                    } else {
                        this.service.LoadByID(val).subscribe(x => {
                            loadedEntry = {
                                Script: '',
                                PossibleBreakpoints: {}
                            };
                            if (x) {
                                loadedEntry.Script = x.Text;
                                loadedEntry.PossibleBreakpoints = this.getPossibleBreakpoints(x.Text);
                            }
                            this.LoadedScripts[val] = loadedEntry;
                            this.ExternalScriptValue = val;
                            this.cdRef.detectChanges();
                        });
                    }
                }
            } else {
                this.ExternalScriptValue = val;
            }
        }
    }
    //#endregion

    //#region Breakpoints
    BreakpointsValue = [];
    @Input()
    get Breakpoints() {
        return this.BreakpointsValue;
    }
    set Breakpoints(val) {
        if (Array.isArray(val)) {
            this.BreakpointsValue = val;
        } else {
            this.BreakpointsValue = [];
        }
        this.updateBreakpoints(false);
    }
    //#endregion

    //#region ActualLine
    ActualLineValue = -1;
    @Input()
    get ActualLine() {
        return this.ActualLineValue;
    }
    set ActualLine(val) {
        if (typeof val === 'number') {
            this.ActualLineValue = val;
        } else {
            this.ActualLineValue = -1;
        }
        this.updateBreakpoints(true);
    }
    //#endregion

    OldDecorations = [];

    constructor(private service: ScriptLibraryService, private conService: ConnectorService, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.SizeChanged();
        this.updateBreakpoints(true);
    }

    init(editor) {
        this.editor = editor;
        this.editor.onMouseDown((e) => {
            if (e.target && (e.target.type === 2 || e.target.type === 3) && e.target.range) {
                const lineNumber = e.target.range.startLineNumber;
                if (typeof lineNumber === 'number') {
                    if (this.ExternalScriptValue) {
                        let index;
                        if (this.BreakpointsValue.some((x, i) => {
                            if (this.ExternalScriptValue === x.LibraryID && x.LineNumber === lineNumber) {
                                index = i;
                                return true;
                            }
                            return false;
                        })) {
                            this.BreakpointsValue.splice(index, 1);
                            this.updateBreakpoints(false);
                        } else {
                            const loaded = this.LoadedScripts[this.ExternalScriptValue];
                            if (loaded && loaded.PossibleBreakpoints[lineNumber] === true) {
                                this.BreakpointsValue.push({
                                    LineNumber: lineNumber,
                                    LibraryID: this.ExternalScriptValue
                                });
                                this.updateBreakpoints(false);
                            }
                        }
                    } else {
                        let index;
                        if (this.BreakpointsValue.some((x, i) => {
                            if (!x.LibraryID && x.LineNumber === lineNumber) {
                                index = i;
                                return true;
                            }
                            return false;
                        })) {
                            this.BreakpointsValue.splice(index, 1);
                            this.updateBreakpoints(false);
                        } else {
                            if (this.PossibleBreakpoints[lineNumber] === true) {
                                this.BreakpointsValue.push({
                                    LineNumber: lineNumber
                                });
                                this.updateBreakpoints(false);
                            }
                        }
                    }
                }
            }
        });
        setTimeout(() => {
            this.updateBreakpoints(true);
        }, 100);
    }

    SizeChanged() {
        setTimeout(() => {
            if (this.editor) {
                this.editor.layout();
            }
        }, 100);
    }

    updateBreakpoints(setPos: boolean) {
        if (this.editor) {
            const newDecs = [];
            let found = false;
            if (this.BreakpointsValue) {
                this.BreakpointsValue.forEach(x => {
                    if (this.ExternalScriptValue) {
                        if (x.LibraryID !== this.ExternalScriptValue) {
                            return;
                        }
                    } else if (x.LibraryID) {
                        return;
                    }
                    let styleText = 'scriptDebugBreakpoint';
                    if (x.LineNumber === this.ActualLineValue) {
                        found = true;
                        styleText += ' scriptDebugArrow';
                    }
                    newDecs.push({
                        range: new monaco.Range(x.LineNumber, 1, x.LineNumber, 1),
                        options: {
                            glyphMarginClassName: styleText
                        }
                    });
                });
            }
            if (!found && this.ActualLineValue > -1) {
                found = true;
                newDecs.push({
                    range: new monaco.Range(this.ActualLineValue, 1, this.ActualLineValue, 1),
                    options: {
                        glyphMarginClassName: 'scriptDebugArrow'
                    }
                });
            }
            this.OldDecorations = this.editor.deltaDecorations(this.OldDecorations, newDecs);
            if (setPos && found) {
                this.editor.revealLine(this.ActualLineValue);
            }
        }
    }

    getPossibleBreakpoints(script) {
        const possible = {};
        if (script) {
            const module = new ScriptParser().GetModule(script);
            module.Classes.forEach(c => {
                c.Functions.forEach(f => {
                    let maxNumber = -1;
                    f.ScriptLines.forEach(sl => {
                        if (typeof sl.getAllLineNumbers === 'function') {
                            const lineNumbers = sl.getAllLineNumbers();
                            lineNumbers.forEach(ln => {
                                possible[ln] = true;
                                if (ln > maxNumber) {
                                    maxNumber = ln;
                                }
                            });
                        }
                    });
                    if (maxNumber > -1) {
                        possible[maxNumber + 1] = true;
                    }
                });
            });
        }
        return possible;
    }

    onScriptLoaded() {
        this.updateBreakpoints(true);
    }
}

@Component({
    selector: 'debug-script-breakpoint-settings-control',
    template: '<debug-script-breakpoint-control *ngIf="Data" class="full" [Script]="Data.Script" [Breakpoints]="Data.Breakpoints" [ActualLine]="Data.ActualLine" [ExternalScript]="Data.ExternalScript"></debug-script-breakpoint-control>'
})
export class DebugScriptBreakpointSettingsControl {
    static SCRIPT_DEBUG_DATA = new InjectionToken<{}>('SCRIPT_DEBUG_DATA');
    constructor(@Inject(DebugScriptBreakpointSettingsControl.SCRIPT_DEBUG_DATA) public Data) {
    }
}

