import { Component, ViewChild } from '@angular/core';
import { ABaseTreeNode, BaseTreeControl } from '../../../components/common/basetreecontrol/base.tree.control';

@Component({
    selector: 'attribute-chooser-dialog',
    templateUrl: './attribute.chooser.dialog.html'
})
export class AttributeChooserDialog {
    RootNodes = [];
    @ViewChild('tree') tree: BaseTreeControl;

    Initialize(args) {
        if (args && args.Columns && args.TableInfos) {
            const nodes: ContainerNode[] = [];
            let index = 0;
            args.Columns.forEach(c => {
                const ti = args.TableInfos.find(t => t.SID === c.ContainerID);
                if (ti) {
                    const fi = ti.Fields.find(x => x.SID === c.FieldID);
                    if (fi) {
                        let parentNode = nodes.find(x => x.UniqueID === c.ContainerUniqueID);
                        if (!parentNode) {
                            parentNode = new ContainerNode(index++);
                            parentNode.Caption = ti.Caption + ' (' + c.ContainerUniqueID + ')';
                            parentNode.UniqueID = c.ContainerUniqueID;
                            parentNode.HasChildren = true;
                            parentNode.Children = [];
                            nodes.push(parentNode);
                        }
                        const childNode = new ContainerNode(index++);
                        childNode.Caption = fi.Caption + ' (' + c.UniqueID + ')';
                        childNode.UniqueID = c.UniqueID;
                        childNode.Name = fi.Caption;
                        childNode.DataType = fi.DataType;
                        parentNode.Children.push(childNode);
                    }
                }
            });
            this.RootNodes = nodes;
        }
    }

    GetDialogResult() {
        const retVal = [];
        if (this.tree) {
            this.tree.checklistSelection.selected.forEach(x => {
                if (!x.Expandable) {
                    retVal.push({
                        Name: x.OriginalNode['Name'],
                        ID: x.OriginalNode['UniqueID'],
                        DataType: x.OriginalNode['DataType']
                    });
                }
            });
        }
        return retVal;
    }
}

export class ContainerNode extends ABaseTreeNode {
    UniqueID;
    Name;
    DataType;

    constructor(id) {
        super(id);
        this.Checkable = true;
    }
}
