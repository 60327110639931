import { BaseDataSourcePanel } from "../basedatasource.panel";
import { SQLDBInfo, DBPanelInfo } from "../../../../../models/datamodel/adbinfo.model";
import { Component } from "@angular/core";

@Component({
    selector: 'sqldb-panel',
    templateUrl: './sqldb.panel.html',
    styleUrls: ['./sqldb.panel.css']
})
export class SQLDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = SQLDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: SQLDBPanel,
            DBInfoType: SQLDBInfo
        };
    }
}