import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { Color } from '../../../models/style/color.model';

@Component({
    selector: 'lib-color-picker-menu',
    templateUrl: './colorpickermenu.control.html',
    styleUrls: ['./colorpickermenu.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerMenuComponent  {

    SelectedColorValue = new Color();
    GradientValue :string = ''
    @Input()
    get SelectedColor() {
        return this.SelectedColorValue;
    }
    set SelectedColor(val) {
        if (val != this.SelectedColorValue) {
            this.SelectedColorValue = val;
            this.FillStyle = {
                'fill': Color.GetStyleText(this.SelectedColorValue)
            };
            this.SelectedColorChange.emit(this.SelectedColorValue);
        }
    }

    @Output() SelectedColorChange = new EventEmitter<Color>();

    @Output() ColorChanged = new EventEmitter<Color>();
    @Output() GradientColorValue = new EventEmitter<any>();
    ToolTipValue;
    @Input()
    get ToolTip() {
        return this.ToolTipValue;
    }
    set ToolTip(val) {
        this.ToolTipValue = val;
        this.ToolTipChange.emit(this.ToolTipValue);
    }

    @Output() ToolTipChange = new EventEmitter<any>();
    UseVariablesValue = true;
    @Input()
    get UseVariables() {
        return this.UseVariablesValue;
    }
    set UseVariables(val) {
        this.UseVariablesValue = val;
    }

    UseSystemVariablesValue = true;
    @Input()
    get UseSystemVariables() {
        return this.UseSystemVariablesValue;
    }
    set UseSystemVariables(val) {
        this.UseSystemVariablesValue = val;
    }
    @Input() InputGradient;
    @Input() SelectedGradient;
    
    FillStyle = {};
    GradientColorChange(value) {
        this.GradientColorValue.emit(value)
    }
    ColorChange(value) {
        this.SelectedColor = value;
        this.ColorChanged.emit(value);
    }

    menuKeyDown(ev) {
        MenuKeyDownHelper.onKeyDown(ev);
    }
}
