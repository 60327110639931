import { APP_ACTIONS } from "../../imports/app.actions";
import { ChangeDataSourceDeploymentAction } from "../../models/actions/changedatasource.model";
import { DeploymentAction } from "../../models/actions/deploymentaction.model";

export const ACTIONS_REGISTRY = new Map<string, any>();

export class ActionRegistryHelper {

    static ExternalActionRegistry: any[] = [];

    public static Build() {
        APP_ACTIONS.forEach((item) => {
            ACTIONS_REGISTRY.set(item.Name, item);
        });
        this.ExternalActionRegistry.forEach((item) => {
            ACTIONS_REGISTRY.set(item.name, item);
        });
    }
}

export const ACTIONS_GROUP_REGISTRY = new Map<string, string>();
export const APP_DEPLOYMENTACTIONS_CONTROLS = [

]
export const DEPLOYMENT_ACTIONS: DeploymentAction[] = [
    new ChangeDataSourceDeploymentAction()
];
export const DEPLOYMENTACTIONS_REGISTRY = new Map<string, DeploymentAction>();
export class DeploymentActionRegistryHelper {
    static ExternalActionRegistry: DeploymentAction[] = [];
    public static Build() {
        DEPLOYMENT_ACTIONS.forEach((item) => {
            DEPLOYMENTACTIONS_REGISTRY.set(item.ID, item);
        });
        this.ExternalActionRegistry.forEach((item) => {
            DEPLOYMENTACTIONS_REGISTRY.set(item.ID, item);
        });
    }
}