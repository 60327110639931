import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsersService } from "../../services/users.service";
import { Router } from "@angular/router";
import { NotificationHelper } from "../../helpers/notification.helper";
import { LayoutService } from "../../services/layout.service";

@Component({
    selector: 'lib-personal-settings',
    templateUrl: './personal-settings.component.html',
    styleUrls: ['./personal-settings.component.scss']
})
export class PersonalSettingsComponent implements OnInit {

    @ViewChild('settingForm') settingForm;
    form: FormGroup;
    newPassHide = true;
    oldPassHide = true;
    confirmPassHide = true;
    toggleWindow = true;
    selectedItem;

    errorMessage: string = '';
    showTooltip = false;
    hasCharacterLength = false;
    hasCapitalLetter = false;
    hasNumber = false;
    hasSpecialChar = false;
    hasAlphabit = false;

    userInfo;
    displayIcon = '';

    nav_list = [
        { id: 'change_password', label: 'Change Password', icon: 'lock_reset' },
        { id: 'notification_preferences', label: 'Notification Preferences', icon: 'notifications_active' },
        { id: 'display_themes', label: 'Display Themes', icon: 'contrast' },
        { id: 'personalized_settings', label: 'Personalized Settings', icon: 'settings_account_box' },
    ]

    constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router, private cdRef: ChangeDetectorRef) {
    }
    hidePasswordChange:boolean=false;
    ngOnInit(): void {
        this.buildForm();
        UsersService.ActiveUser.subscribe((user) => {
            this.userInfo = user;
            if (this.userInfo.FirstName) {
                const firstName = this.userInfo?.FirstName ? this.userInfo?.FirstName[0] : '';
                const lastName = this.userInfo?.LastName ? this.userInfo?.LastName[0] : '';
                this.displayIcon = firstName + lastName;
                if (this.userInfo?.SignupMethod && this.userInfo?.SignupMethod !== "email") {
                    this.nav_list = this.nav_list.filter((nav) => nav.id != "change_password")
                    this.hidePasswordChange = true;
                    this.toggleWindow=false;
                }
            } else {
                this.displayIcon = this.userInfo.DisplayName[0];
            }
        });
        this.form.valueChanges.subscribe(() => {
            const _form = this.form.getRawValue();
            this.validatePassword(_form.newPassword);
        });
        this.selectedItem = 'change_password';
    }

    buildForm() {
        this.form = this.fb.group({
            email: ['', Validators.required, Validators.email],
            newPassword: ['', [Validators.required, Validators.minLength(8),
            Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s\S])[a-zA-Z\d\s\S]{8,}$/)]
            ],
            oldPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
    }

    onChangePassword() {
        const _form = this.form.getRawValue();

        // if (_form && !_form.email) {
        //     NotificationHelper.Error(`Please enter your email`, '@@Error');
        //     return;
        // }

        if (_form && !_form.newPassword) {
            NotificationHelper.Error(`Please enter your new password`, '@@Error');
            return;
        }

        if (_form && !_form.oldPassword) {
            NotificationHelper.Error(`Please enter your old password`, '@@Error');
            return;
        }

        if (_form && _form.newPassword != _form.confirmPassword) {
            NotificationHelper.Error(`@@Passwords do not match`, '@@Error');
            return;
        }

        if (this.errorMessage) {
            NotificationHelper.Error(`@@${this.errorMessage}`, '@@Error');
            return;
        }

        const payload = { Email: this.userInfo.Email, NewPassword: _form.newPassword, OldPassword: _form.oldPassword };
        this.loading(true);
        this.usersService.changePassword(payload).subscribe({
            next: (res) => {
                this.loading(false);
                if (res['StatusCode'] == 200) {
                    const message = res['Message'] || '';
                    NotificationHelper.Success(`@@${message}`, '@@Success');
                    this.resetForm();
                }
            },
            error: (err) => {
                this.loading(false);
                // const message = err.error.Message || '';
                // NotificationHelper.Error(`@@${message}`, '@@Error');
            }
        })
    }

    validatePassword(password) {
        this.hasCharacterLength = /^.{8,}$/.test(password);
        this.hasCapitalLetter = /[A-Z]/.test(password);
        this.hasNumber = /[0-9]/.test(password);
        this.hasSpecialChar = /(?=.*[^a-zA-Z\d\s])/.test(password);
        // !@#$%^&*(),.?
        this.hasAlphabit = /[a-z]/.test(password);

        if (password && password.length < 8) {
            this.errorMessage = 'Password must be at least 8 characters long.';
        } else if (!this.hasCapitalLetter) {
            this.errorMessage = 'Password must contain at least one capital letter.';
        } else if (!this.hasNumber) {
            this.errorMessage = 'Password must contain at least one number.';
        } else if (!this.hasSpecialChar) {
            this.errorMessage = 'Password must contain at least one special character.';
        } else if (!this.hasAlphabit) {
            this.errorMessage = 'Password must contain at least one alphabet character.';
        } else {
            this.errorMessage = '';
        }
        // return this.hasCapitalLetter && this.hasCapitalLetter && this.hasNumber && this.hasSpecialChar && this.hasAlphabit;
    }

    onToggleWindow(id) {
        this.selectedItem = id;
        this.toggleWindow = id == 'change_password';
        this.resetForm();
    }

    goHomePage() {
        this.router.navigate(['default/settings/dashboard']);
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

    resetForm() {
        this.form.reset();
        if (this.settingForm) {
            this.settingForm.resetForm();
        }
    }

}
