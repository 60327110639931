import { equal, round } from 'mathjs';
import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class RoundFormula extends AFormula {
    static StaticID = '31b493c4-ef9f-435b-860f-76fdf4beb726';

    ID(): string {
        return RoundFormula.StaticID;
    }

    Name(): string {
        return 'Round';
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 999;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Measure') + '; ' +
            TranslateHelper.TranslatorInstance.instant('@@Stellen') + ')';
    }

    MinVariableCount(): number {
        return 2;
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && args.length > 1) {
            const num = args[0].Value;
            const digits = args[1].Value;
            if (typeof num === 'number' && typeof digits === 'number') {
                retVal.Value = round(num, digits);
                retVal.Type = ValueType.Double;
                if (equal(digits, 0)) {
                    retVal.Type = ValueType.Long;
                }
            }
        }
        return retVal;
    }
}
