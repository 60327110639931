import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { PlanningHeaderFormattingTaskExecuter } from '../../../../models/tasks/planning/header.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ATaskPanel } from '../../a.task.panel';

@Component({
    selector: 'header-planning-task-panel',
    templateUrl: './header.planning.task.panel.html'
})
export class HeaderPlanningTaskPanel extends ATaskPanel {

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = PlanningHeaderFormattingTaskExecuter.TaskID;
        reg.Caption = '@@Header';
        reg.SettingsControl = HeaderPlanningTaskPanel;
        reg.SettingsHelper = new HeaderPlanningTaskSettingsHelper();
        reg.Executer = PlanningHeaderFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }    
}

export class HeaderPlanningTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsPlanningObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Headerformatierung');
    }
}
