import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { VariableHelper } from '../../../helpers/variable.helper';
import { Layout } from '../../../models/layout.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { AContentDialog } from './acontent.dialog';

@Component({
    selector: 'wf-template-dialog-content',
    templateUrl: './template.dialog.content.html'
})
export class TemplateDialogContent extends AContentDialog implements OnDestroy {
    TempLayout;

    constructor(private service: MetaService, public cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    LoadLayout(): void {
        if (this.InitArgs && this.InitArgs.Template) {
            this.TempLayout = LayoutService.SelectedLayout.getValue();
            this.service.LoadTemplate(this.InitArgs.Template).subscribe((result) => {
                if (result) {
                    const layout = plainToClass(Layout, result);
                    VariableHelper.LoadVariables(layout).then(() => {
                        this.Layout = layout;
                        LayoutService.SelectedLayout.next(this.Layout);
                    });
                }
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.TempLayout) {
            LayoutService.SelectedLayout.next(this.TempLayout);
        }
    }
}
