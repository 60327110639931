import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { ClientHelper } from '../../helpers/client.helper';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';

@Component({
    selector: 'debug-workflow-attach',
    templateUrl: './debug.workflow.attach.dialog.html'
})
export class DebugWorkflowAttachDialog implements OnInit {
    Clients = [];
    ConnectedClients = [];

    static ShowDialog(connectedClients, handler) {
        BaseDialog.ShowDialog({
            ContentType: DebugWorkflowAttachDialog,
            InitArgs: { ConnectedClients: connectedClients },
            Handler: (x) => {
                handler(x);
                return true;
            },
            Title: '@@Attach to process'
        });
    }

    constructor(private cdRef: ChangeDetectorRef, private translate: TranslateService) {
    }

    ngOnInit(): void {
        const selfSelected = this.ConnectedClients.find(x => x.ClientID === ClientHelper.ClientID);
        if (selfSelected) {
            this.addClient(selfSelected.ClientID, selfSelected.Caption, true);
        } else {
            this.addClient(ClientHelper.ClientID, this.translate.instant('@@OwnWindow'), false);
        }
        this.cdRef.detectChanges();
        WorkflowDebugHelper.ClientIDAnswer.subscribe(x => {
            const selected = this.ConnectedClients.find(cc => cc.ClientID === x.ClientID);
            if (selected) {
                this.addClient(selected.ClientID, selected.Caption, true);
            } else {
                this.addClient(x.ClientID, x.ClientID + ' (' + x.URL + ')', false);
            }
            this.cdRef.detectChanges();
        });
        WorkflowDebugHelper.RequestClientIDs();
    }

    private addClient(id: string, caption: string, selected: boolean) {
        this.Clients.push({
            ClientID: id,
            Caption: caption,
            Selected: selected,
            Disabled: selected
        });
    }

    Initialize(args) {
        if (args && args.ConnectedClients) {
            this.ConnectedClients = args.ConnectedClients;
        }
    }

    GetDialogResult() {
        const clients = [];
        this.Clients.forEach(x => {
            if (x.Selected && !x.Disabled) {
                clients.push({
                    ClientID: x.ClientID,
                    Caption: x.Caption
                });
            }
        });
        return clients;
    }
}
