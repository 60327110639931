import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { NonClickableVariablesNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { EditTaskExecuter } from '../../../models/tasks/datatasks/edit.model';
import { StandardRequestBase } from '../../../services/request-base';
import { ALevelMeasurePanel, ALevelMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'edit-task-panel',
    templateUrl: './edit.task.panel.html'
})
export class EditTaskPanel extends ALevelMeasurePanel implements OnInit {

    Variables;
    Operations = [];
    InputMode: FormulaInputMode = FormulaInputMode.AliasKey;

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = EditTaskExecuter.TaskID;
        reg.Caption = '@@Editieren';
        reg.SettingsControl = EditTaskPanel;
        reg.SettingsHelper = new EditSettingsHelper();
        reg.Executer = EditTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

   

    constructor(private service: StandardRequestBase) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        const query = {
            Group: FormulaGroupEnum.All
        };
        this.service.executePost(query, 'api/formula', 'GetFormulaInfos').subscribe(result => this.Operations = result);
        this.updateVariables();
    }

    updateVariables() {
        if (this.SettingsValue) {
            const uniqueID = typeof this.SettingsValue.MeasureUniqueID === 'number' ?
                this.SettingsValue.MeasureUniqueID : this.SettingsValue.LevelUniqueID;
            EditTaskExecuter.GetMeasuresAndLevelsForCalcTask(this.DataDescriptionValue, uniqueID).then(result => {
                if (result) {
                    const variables = [];

                    const measureNode = new NonClickableVariablesNodeInformation();
                    measureNode.Name = '@@Werte';
                    measureNode.Expand = true;
                    variables.push(measureNode);

                    result.Measures.forEach(m => {
                        const vni = new VariablesNodeInformation();
                        if (m.AreaCaption) {
                            vni.Name = m.AreaCaption + ' / ' + m.Measure.toString();
                        } else {
                            vni.Name = m.Measure.toString();
                        }
                        vni.VariableID = vni.AliasKey = 'M' + m.Measure.UniqueID;
                        measureNode.Children.push(vni);
                    });

                    const levelNode = new NonClickableVariablesNodeInformation();
                    levelNode.Name = '@@Level';
                    variables.push(levelNode);

                    result.Levels.forEach(l => {
                        const vni = new VariablesNodeInformation();
                        vni.Name = l.toString();
                        vni.VariableID = vni.AliasKey = 'L' + l.UniqueID;
                        levelNode.Children.push(vni);
                    });

                    this.Variables = variables;
                }
            });
        }
    }

    onMeasureChanged() {
        if (this.Settings.MeasureUniqueID && this.Settings.LevelUniqueID) {
            this.Settings.LevelUniqueID = null;
        }
        this.updateVariables();
    }
    onLevelChanged() {
        if (this.Settings.LevelUniqueID && this.Settings.MeasureUniqueID) {
            this.Settings.MeasureUniqueID = null;
        }
        this.updateVariables();
    }
}

export class EditSettingsHelper extends ALevelMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption ;
        }
        return TranslateHelper.TranslatorInstance.instant('@@Editieren');
    }
}
