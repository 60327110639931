import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { MessageBoxHelper } from '../../../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../../../helpers/array.helpers';
import { AttributeProperty } from '../../../../../../models/ContainerClassObject';
import { MessageBoxButtons } from '../../../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../../../models/enums/messageboxicon.enum';

@Component({
    selector: 'restobject-property-detail',
    templateUrl: './rest.object.property.dialog.html'
})
export class RestObjectPropertyDialog {
    SelectedField: AttributeProperty;
    AllTypes = [];
    AllObjects = [];

    constructor() {
        this.SelectedField = new AttributeProperty();
        this.SelectedField.Type = '9b056ce1-ffb9-4168-9e00-977449f2f66d';
    }

    Initialize(args) {
        if (args) {
            if (args.Field) {
                const json = serialize(args.Field);
                this.SelectedField = deserialize(AttributeProperty, json);
            }
            this.AllTypes = args.AllTypes;
            this.AllObjects = args.AllObjects;
        }
    }

    GetDialogResult() {
        if (this.SelectedField.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
            if (!this.SelectedField.AdvancedType || this.SelectedField.AdvancedType === '00000000-0000-0000-0000-000000000000') {
                const warning =
                    new TranslateFormatText('@@Sie verwenden den Feldtyp Klasse ohne eine entsprechende Tabelle selektiert zu haben.');
                MessageBoxHelper.ShowDialog(warning, new TranslateFormatText('@@Hinweis'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
            }
        } 
        return this.SelectedField;
    }

    onTypeChange() {
        this.SelectedField.Default = null;
    }
}
