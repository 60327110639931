import { Component } from '@angular/core';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { LayoutHelper } from '../../../../helpers/layout.helper';
import { FormulaNodeCalculator } from '../../../../models/calculation/formula.node.calculator';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-readVariable-settings',
    templateUrl: './readVariable.settings.html',
    styleUrls: ['./readVariable.settings.css']
})
export class ReadVariableSettings extends WorkflowDialogContent {
    static MultiModuleID = 'readMultiLayoutVariableModule';

    ElemName: string;
    SystemVariables = [];
    Variable;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readLayoutVariableModule';
        reg.Caption = '@@Variable auslesen';
        reg.SettingsControl = ReadVariableSettings;
        reg.SettingsTypeHelper = new ReadVariableSettingsDataHelper();
        reg.Executer = ReadVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ReadVariableSettings.MultiModuleID;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.ElemName) {
                this.ElemName = data.ElemName;
            }
            if (data.Variable) {
                this.Variable = data.Variable;
            }
        }
    }

    getResult(): any {
        const retVal = new ReadVariableSettingsData();
        retVal.Variable = this.Variable;
        retVal.ElemName = this.ElemName;
        return retVal;
    }

    SelectedElemChanged() {
        const element = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, this.ElemName);
        if (element) {
            const vars = FilterHelper.GetVariables(element);
            this.SystemVariables = vars;
        }
    }
    compareFn(item1, item2) {
        return item1 && item2 && item1.Name === item2.Name;
    }
}

export class ReadVariableSettingsData {
    Variable: any;
    ElemName: string;
}

export class ReadVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadVariableSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class ReadVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variable) {
                if (typeof status.ActualSettings.ElemName === 'string') {
                    const variableResult = status.WorkflowLayoutService.getVariablesByName(status.ActualSettings.ElemName);
                    if (variableResult.ElemFound) {
                        if (variableResult.Variables.length > 0) {
                            const variable = variableResult.Variables.find(x => x.Name === status.ActualSettings.Variable.Name);
                            if (variable) {
                                const vat = FormulaNodeCalculator.GetVariableValue(variable, false);
                                if (vat) {
                                    status.ActualParameter = vat.Value;
                                } else {
                                    status.ActualParameter = null;
                                }
                                return 0;
                            } else {
                                status.Logger.logError('ReadVariable module: Variable ' +
                                    status.ActualSettings.Variable.Name + ' could not be found.');
                            }
                        } else {
                            status.Logger.logError('ReadVariable module: Element has no variables defined.');
                        }
                    } else {
                        status.Logger.logError('ReadVariable module: Element not found.');
                    }
                } else {
                    status.Logger.logError('ReadVariable module: No element name found.');
                }
            } else {
                status.Logger.logError('ReadVariable module: Variable not set.');
            }
        } else {
            status.Logger.logError('ReadVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
