export enum ValueType {
    /// <summary>
    /// Wert ist NULL.
    /// </summary>
    Null = 0x0001,
    /// <summary>
    /// Wert ist ein Boolean.
    /// </summary>
    Bool = 0x0002,
    /// <summary>
    /// Wert ist Long.
    /// </summary>
    Long = 0x0004,
    /// <summary>
    /// Wert ist Datetime
    /// </summary>
    Datetime = 0x0008,
    /// <summary>
    /// Wert ist Double
    /// </summary>
    Double = 0x0010,
    /// <summary>
    /// Wert ist String.
    /// </summary>
    String = 0x0020,
    /// <summary>
    /// Wert ist ein Bild.
    /// </summary>
    Picture = 0x0040,
    Spark = 0x0080,
    Range = 0x0100,
    Object = 0x0200,
    List = 0x0400,
}