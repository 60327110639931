import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Border } from '../../../../models/style/border.model';
import { BorderStyle } from '../../../../models/enums/borderstyle.enum';
import { GradientType } from '../../../../models/enums/gradienttype.enum';
import { Gradient } from '../../../../models/style/gradient.model';
import { UnitType } from '../../../../models/enums/unittype.enum';


@Component({
  selector: 'border-action',
  templateUrl: './border.action.html',
  styleUrls: ['./border.action.css'],
})
export class BorderAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'border';
    
    borderStyles = BorderStyle;
    
    clearBorder() {
        if (this.SelectedItemValue) {
            this.SelectedItemValue.Border = new Border();
        }
    }

    setFullBorder(width, color, style) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Border) {
                this.SelectedItemValue.Border = new Border();
            }
            this.setBorder(this.SelectedItemValue.Border.TopBorder, width, color, style, false);
            this.setBorder(this.SelectedItemValue.Border.RightBorder, width, color, style, false);
            this.setBorder(this.SelectedItemValue.Border.BottomBorder, width, color, style, false);
            this.setBorder(this.SelectedItemValue.Border.LeftBorder, width, color, style, false);
        }
    }

    setTopBorder(width, color, style) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Border) {
                this.SelectedItemValue.Border = new Border();
            }
            this.setBorder(this.SelectedItemValue.Border.TopBorder, width, color, style, true);
        }
    }

    hasTopBorder() {
        if (this.SelectedItemValue && this.SelectedItemValue.Border) {
            return this.hasBorderSet(this.SelectedItemValue.Border.TopBorder);
        }
        return false;
    }

    setBottomBorder(width, color, style) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Border) {
                this.SelectedItemValue.Border = new Border();
            }
            this.setBorder(this.SelectedItemValue.Border.BottomBorder, width, color, style, true);
        }
    }

    hasBottomBorder() {
        if (this.SelectedItemValue && this.SelectedItemValue.Border) {
            return this.hasBorderSet(this.SelectedItemValue.Border.BottomBorder);
        }
        return false;
    }

    setLeftBorder(width, color, style) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Border) {
                this.SelectedItemValue.Border = new Border();
            }
            this.setBorder(this.SelectedItemValue.Border.LeftBorder, width, color, style, true);
        }
    }

    hasLeftBorder() {
        if (this.SelectedItemValue && this.SelectedItemValue.Border) {
            return this.hasBorderSet(this.SelectedItemValue.Border.LeftBorder);
        }
        return false;
    }

    setRightBorder(width, color, style) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Border) {
                this.SelectedItemValue.Border = new Border();
            }
            this.setBorder(this.SelectedItemValue.Border.RightBorder, width, color, style, true);
        }
    }

    hasRightBorder() {
        if (this.SelectedItemValue && this.SelectedItemValue.Border) {
            return this.hasBorderSet(this.SelectedItemValue.Border.RightBorder);
        }
        return false;
    }

    hasBorderSet(side) {
        if (side && side.Thickness && side.Thickness.Value > 0 && side.Color && side.Color.Colors.length > 0) {
            return side.Type == GradientType.Solid || side.Type == GradientType.Gradient;
        }
        return false;
    }

    setBorder(side, width, color, style, check) {
        if (side) {
            width = this.getRealNumber(width);
            style = this.getRealNumber(style);
            if (check) {
                if (side.Thickness.Value == width && side.Style == style && Gradient.IsSameGradient(side.Color, color)) {
                    width = 0;
                    style = 0;
                    color = null;
                }
            }
            side.Thickness.Value = width;
            side.Thickness.Type = UnitType.Pixel;
            side.Color = color;
            side.Style = style;
        }
    }
    getRealNumber(value) {
        var retVal = parseInt(value);
        if (isNaN(retVal)) {
            retVal = 0;
        }
        return retVal;
    }

    getBorderPreview() {
        let result = {};
        if (this.SelectedItemValue && this.SelectedItemValue.Border) {
            if (this.SelectedItemValue.Border.LeftBorder) {
                result['border-left'] = this.SelectedItemValue.Border.LeftBorder.toStyle();
            }
            if (this.SelectedItemValue.Border.TopBorder) {
                result['border-top'] = this.SelectedItemValue.Border.TopBorder.toStyle();
            }
            if (this.SelectedItemValue.Border.RightBorder) {
                result['border-right'] = this.SelectedItemValue.Border.RightBorder.toStyle();
            }
            if (this.SelectedItemValue.Border.BottomBorder) {
                result['border-bottom'] = this.SelectedItemValue.Border.BottomBorder.toStyle();
            }
        }
        return result;
    }

    ControlInitialized() {

    }
    ExecuteAction() {
        
    }

    Visibility() {
        return true;
    }
}
