import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LogManager } from '../helpers/log.helpers';
import { NotificationHelper } from '../helpers/notification.helper';
import { ServiceRegistryHelper } from '../helpers/serviceregistry.helper';
import { MethodType } from '../models/enums/methodtype.enum';
import { SettingsService } from '../services/settings.service';

@Injectable()
export class OfflineClient extends HttpClient {

    EndPoints = ServiceRegistryHelper.SERVICEREGISTRY;
    BaseUrl = SettingsService.API_BASE_URL.getValue();

    public get(url: string, options?: any): Observable<any> {

        var params = this.getAllUrlParams(url);
        url = this.getUrl(url);
        if (this.EndPoints && this.EndPoints[url + ':' + MethodType.Get]) {
            LogManager.Log('OfflineClient Get', url);
            return this.EndPoints[url + ':' + MethodType.Get].execute(params);
        } else {
            NotificationHelper.Error("Endpoint ("+url+":Get) not found!","@@Request")
            return of(null);
        }
    }
    public post(url: string, body: string, options?: any): Observable<any> {
        var params = this.getAllUrlParams(url);
        url = this.getUrl(url);
        if (this.EndPoints && this.EndPoints[url + ':' + MethodType.Post]) {
            LogManager.Log('OfflineClient Post', url);
            return this.EndPoints[url + ':' + MethodType.Post].execute(params, body);
        } else {
            NotificationHelper.Error("Endpoint (" + url + ":Post) not found!", "@@Request")
            return of(null);
        }
    }
    public put(url: string, body: string, options?: any): Observable<any> {
        var params = this.getAllUrlParams(url);
        url = this.getUrl(url);
        if (this.EndPoints && this.EndPoints[url + ':' + MethodType.Put]) {
            LogManager.Log('OfflineClient Put', url);
            return this.EndPoints[url + ':' + MethodType.Put].execute(params, body);
        } else {
            NotificationHelper.Error("Endpoint (" + url + ":Put) not found!", "@@Request")
            return of(null);
        }
    }
    public delete(url: string, options?: any): Observable<any> {
        var params = this.getAllUrlParams(url);
        url = this.getUrl(url);
        if (this.EndPoints && this.EndPoints[url + ':' + MethodType.Delete]) {
            LogManager.Log('OfflineClient Delete', url);
            return this.EndPoints[url + ':' + MethodType.Delete].execute(params);
        } else {
            NotificationHelper.Error("Endpoint (" + url + ":Delete) not found!", "@@Request")
            return of(null);
        }
    }

    getUrl(url) {
        this.BaseUrl = SettingsService.API_BASE_URL.getValue();
        return url.replace(this.BaseUrl, '').split('?')[0];
    }
    getAllUrlParams(url) {
        var queryString = url ? url.split('?')[1] : null;
        var obj = {};
        if (queryString) {
            queryString = queryString.split('#')[0];
            var arr = queryString.split('&');

            for (var i = 0; i < arr.length; i++) {
                var a = arr[i].split('=');
                var paramName = a[0];
                var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
                paramName = paramName.toLowerCase();
                //if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
                if (paramName.match(/\[(\d+)?\]$/)) {
                    var key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];
                    if (paramName.match(/\[\d+\]$/)) {
                        var index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                } else {
                    if (!obj[paramName]) {
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }
        return obj;
    }
}