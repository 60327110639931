import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UUID } from 'angular2-uuid';
import { OcDragItemType } from '../../models/enums/oc.DragItemType';
import { AxisType } from '../../models/enums/query.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { DatadescriptionControl, OCMPDragInfo } from '../dialogs/datadescription/datadescription.control';
import { ObjectCreatorSourceMultiDimensional } from '../multipanels/objectcreatorsource/objectcreatorsource.multidim';
import { ObjectCreatorSourceRelational } from '../multipanels/objectcreatorsource/objectcreatorsource.relational';
import { HeterogenAreaItemBasicInfo, HeterogenAreaItemSelectedInfo, ObjectcreatorDataManager } from './helper/objectcreator.datamanager';

@Component({
    selector: 'objectcreatorDroparea',
    templateUrl: './objectcreator.droparea.html',
    styleUrls: ['./objectcreator.droparea.css']
})
export class ObjectCreatorDropAreaCtrl implements OnInit, OnDestroy {

    ObjectcreatorDataManagerValue: ObjectcreatorDataManager;
    @Input()
    get ObjectcreatorDataManager() {
        return this.ObjectcreatorDataManagerValue;
    }
    set ObjectcreatorDataManager(value) {
        this.ObjectcreatorDataManagerValue = value;
    }

    Header;

    contextMenuValue: MatMenuTrigger;
    @Input()
    get contextMenu() {
        return this.contextMenuValue;
    }
    set contextMenu(value) {
        this.contextMenuValue = value;
    }

    @Input()
    OcDragItemType: OcDragItemType;

    @Input()
    AxisType: AxisType = AxisType.None;

    @Input()
    AreaUniqueID: number;

    objectCreatorItemsValue = [];

    @Input()
    get objectCreatorItems() {
        return this.objectCreatorItemsValue;
    }
    set objectCreatorItems(value) {
        this.objectCreatorItemsValue = [];
        if (Array.isArray(value)) {
            this.objectCreatorItemsValue = value;
        }
    }

    dropareaInfoValue: DropAreaDraggingInfo;
    get dropareaInfo() {
        if (!this.dropareaInfoValue) {
            this.dropareaInfoValue = new DropAreaDraggingInfo();
            this.dropareaInfoValue.AreaUniqueID = this.AreaUniqueID;
            this.dropareaInfoValue.AxisType = this.AxisType;
            this.dropareaInfoValue.objectCreatorItemsValue = this.objectCreatorItems;
            this.dropareaInfoValue.OcDragItemType = this.OcDragItemType;
        }
        return this.dropareaInfoValue;
    }

    Subscriptions = [];

    @Input()
    IsRelational: boolean;

    @Output() DropAreaDroping = new EventEmitter<any>();

    //#region Caption
    @Input()
    get Caption() {
        return "";
    }
    set Caption(val) {
    }
    //#region DataDescription
    @Input()
    DataDescription: any;

    DragInfo: OCMPDragInfo;
    DropClass = '';

    itemGhost = { Caption: "hier einfuegen", ID: -666, index: -1 };

    constructor() {
        this.objectCreatorItems = [this.itemGhost];
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

     itemGhostindexSetFinished = false;

    ngOnInit(): void {
        this.Subscriptions.push(DatadescriptionControl.ObjectCreatorMPDragStart.subscribe((state) => {
            this.DragInfo = state;
            if (state && (state.DragType === this.OcDragItemType || state.DragType === OcDragItemType.LevelAndMeasure)) {
                this.DropClass = 'FieldsDrop';
            } else {
                this.DropClass = '';
            }
            if (!this.itemGhostindexSetFinished &&this.itemGhost.index < 0) {
                this.itemGhost.index = this.objectCreatorItems.length;
            }
            this.itemGhostindexSetFinished = false;
        }));
        this.Subscriptions.push(ObjectCreatorSourceRelational.DragEnd.subscribe(() => {
            this.dragend(null);
        }));
        this.Subscriptions.push(ObjectCreatorSourceMultiDimensional.DragEnd.subscribe(() => {
            this.dragend(null);
        }));
        this.fillDragItems();
    }

    fillDragItems() {
        if (this.IsRelational && this.DataDescription && this.DataDescription.Fields) {
            const newList = [];
            this.DataDescription.Fields.forEach(x => {
                let caption = TranslatedString.GetTranslation(x.TranslatedCaption);
                if (!caption) {
                    caption = x.Caption;
                }
                newList.push({
                    ID: x.ID,
                    Caption: caption
                });
            });
            this.objectCreatorItems = newList;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        const eventProperty = {
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex,
            areaID : this.AreaUniqueID,
            dropAreaType: this.OcDragItemType,
            axisType: this.AxisType,
            item :null
        };

        if (event.container !== event.previousContainer) {
            eventProperty.item = event.previousContainer.data;
        }
        this.DropAreaDroping.next(eventProperty);
        if (this.IsRelational && this.DataDescription && this.DataDescription.Fields) {
            if (event.previousIndex >= 0 && event.previousIndex < this.DataDescription.Fields.length &&
                event.currentIndex >= 0 && event.currentIndex < this.DataDescription.Fields.length) {
                const item = this.DataDescription.Fields.splice(event.previousIndex, 1)[0];
                this.DataDescription.Fields.splice(event.currentIndex, 0, item);
                this.fillDragItems();
            }
        }
    }

    delete(item) {
        if (this.IsRelational) {
            let index;
            if (item && this.DataDescription && this.DataDescription.Fields && this.DataDescription.Fields.some((x, i) => {
                if (x.ID == item.ID) {
                    index = i;
                }
            })) {
                this.DataDescription.Fields.splice(index, 1);
                this.fillDragItems();
            }
        } else {
            const selInfo = new HeterogenAreaItemSelectedInfo();
            const areaID = this.AreaUniqueID;
            selInfo.HeterogenAreaID = areaID;
            selInfo.DropAreaType = this.OcDragItemType;
            const haBasicInfo = new HeterogenAreaItemBasicInfo();
            haBasicInfo.AreaID = areaID;
            haBasicInfo.UniqueID = item.UniqueID;
            selInfo.SelectedItems.push(haBasicInfo);
            this.ObjectcreatorDataManager.DeleteDBHeterogenAreaElements(selInfo);
        }
    }

    deleteAll() {
        if (this.IsRelational && this.DataDescription) {
            this.DataDescription.Fields = [];
            this.fillDragItems();
        }
    }

    itemDrop(event) {
        const itemghostindex = this.itemGhost.index;
        this.itemGhostindexSetFinished = true;
       // console.log(itemghostindex);
        const index = itemghostindex >= 0 ? itemghostindex : this.objectCreatorItems.length;
        //console.log(index);
        const dragInfo = this.DragInfo;
        this.dragend(null);
        if (dragInfo && dragInfo.DragContent) {
            this.itemGhost.index = -1;
            if (typeof dragInfo.DragContent === 'object' && typeof dragInfo.DragContent.SpecialElementType === 'number') { // SpecialElement
                this.DropAreaDroping.next({
                    SpecialElement: dragInfo.DragContent.SpecialElementType,
                    Index: index
                });
            } else {
                if (this.OcDragItemType === OcDragItemType.Fields) {
                    if (dragInfo.DragType === OcDragItemType.Fields && this.DataDescription) {
                        const newItems = [];
                        dragInfo.DragContent.forEach((item, i) => {
                            newItems.push({
                                ID: item.ID,
                                Caption: item.Caption,
                                TranslatedCaption: item.TranslatedCaption,
                                FieldID: item.FieldID,
                                ContainerID: item.ContainerID,
                                ContainerUniqueID: item.ContainerUniqueID,
                                DataSourceID: item.DataSourceID,
                                Type: item.Type
                            });
                        });
                        if (this.DataDescription.Fields) {
                            this.DataDescription.Fields.splice(index, 0, ...newItems);
                        } else {
                            this.DataDescription.Fields = newItems;
                        }
                        this.fillDragItems();
                        this.itemGhost.index = this.objectCreatorItems.length;
                    }
                } else {
                    this.DropAreaDroping.next({
                        Items: dragInfo.DragContent.DropIDs,
                        Index: index
                    });
                }
            }
        }
    }

    public dragStart(index) {
        if (this.objectCreatorItems.some(x => x.ID === -666)) {
            const oldIndex = this.itemGhost.index;
            if (oldIndex != index) {
                this.itemGhost.index = index;
                moveItemInArray(this.objectCreatorItems, oldIndex, this.itemGhost.index);
            }
        } else {
            this.itemGhost.index = index;
            this.objectCreatorItems.splice(index, 0, this.itemGhost);
        }
    }

    public dragend(event: DragEvent) {
        this.DragInfo = null;
        this.objectCreatorItems.some((x, i) => {
            if (x.ID === -666) {
                this.objectCreatorItems.splice(i, 1);
                return true;
            }
            return false;
        });
        this.itemGhost.index = -1;
    }

    onContextMenu(event: MouseEvent, dditem: any) {
        event.preventDefault();
        event['OcDragItem'] = dditem;
        event['DropArea'] = this;
    }
}

export class LevelOCItem {
    Level: UUID;
    UniqueID: number;
    Caption: string;
    IsStartTupelLevel: boolean;
    IsEndTupelLevel: boolean;
    IsMultiTupelLevel: boolean;

    Tooltip: string;
    IsHidden: boolean;
}

export class DropAreaDraggingInfo {

    OcDragItemType: OcDragItemType;

    AxisType: AxisType = AxisType.None;

    AreaUniqueID: number;

    objectCreatorItemsValue = [];
}
