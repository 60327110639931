import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-readobjectprops-settings',
    templateUrl: './readobjectprops.settings.html'
})
export class ReadObjectPropertiesSettings extends WorkflowDialogContent {

    Data = new ReadObjectPropertiesSettingsData();
    Properties = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readObjectPropsWFModule';
        reg.Caption = '@@Eigenschaften auslesen';
        reg.GroupID = 'objectoperations';
        reg.Index = 40;
        reg.SettingsControl = ReadObjectPropertiesSettings;
        reg.SettingsTypeHelper = new ReadObjectPropertiesSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            const json = JSON.stringify(dsInfo.Properties);
            this.Properties = JSON.parse(json);
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadObjectPropertiesSettingsData, json);
        }
    }

    getResult(): any {
        const props = [];
        this.Data.Properties.forEach(x => {
            if (x.PropertyName && (x.StatusKey || x.UseForState)) {
                props.push(x);
            }
        });
        this.Data.Properties = props;
        return this.Data;
    }

    addProp() {
        if (this.Data) {
            if (this.Data.Properties) {
                this.Data.Properties.push(new ReadPropertyEntry());
            } else {
                this.Data.Properties = [new ReadPropertyEntry()];
            }
        }
    }

    deleteProp(i) {
        if (this.Data && this.Data.Properties) {
            this.Data.Properties.splice(i, 1);
        }
    }

    useForStateChecked(ev, prop) {
        if (ev && ev.checked && this.Data && this.Data.Properties) {
            this.Data.Properties.forEach(x => {
                x.UseForState = false;
            });
            prop.UseForState = true;
        }
    }
}

// @dynamic
export class ReadObjectPropertiesSettingsData extends AServiceWorkflowData {
    @Type(() => ReadPropertyEntry)
    Properties: ReadPropertyEntry[] = [];
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.ReadObjectProps.ReadObjectPropertiesSettingsData';
    }
}

export class ReadPropertyEntry {
    PropertyName: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadObjectPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const info = new WorkflowExitInfo();
        if (!settings || !settings.Properties || !settings.Properties.some(x => x.UseForState)) {
            info.Type = 'objData';
        }
        return [info];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new ReadObjectPropertiesSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Properties) {
                const list = [];
                settings.Properties.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}
