import {
    AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver,
    EventEmitter, Input, Output, Renderer2, ViewChild, ViewContainerRef
} from '@angular/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { TaskRegistry, TaskType } from '../../helpers/task.registry';
import { DataDescription } from '../../models/datadescription/multi/datadescription.model';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { BaseDialog } from '../dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../dialogs/messagebox/messagebox.dialog';
import { NewTaskDialog } from './new.task.dialog';

@Component({
    selector: 'task-overview',
    templateUrl: './task.overview.html',
    styles: ['.settingsList { height: 390px }']
})
export class TaskOverview implements AfterViewInit {

    AllListItems = [];
    ListItems = [];
    selectedNode;
    ActiveStyle = {};
    DataDescription;
    Initialized = false;

    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterList();
    }

    LayoutValue;
    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        if (val != this.LayoutValue) {
            this.LayoutValue = val;
            this.InitData();
            this.LayoutChange.emit(val);
        }
    }
    @Output() LayoutChange = new EventEmitter<any>();

    LayoutElementValue;
    @Input()
    get LayoutElement() {
        return this.LayoutElementValue;
    }
    set LayoutElement(val) {
        if (val != this.LayoutElementValue) {
            this.LayoutElementValue = val;
            this.InitData();
            this.LayoutElementChange.emit(val);
        }
    }
    @Output() LayoutElementChange = new EventEmitter<any>();

    TaskTypeValue = TaskType.DataTask;
    @Input()
    get TaskType() {
        return this.TaskTypeValue;
    }
    set TaskType(val) {
        if (val != this.TaskTypeValue) {
            this.TaskTypeValue = val;
            this.InitData();
            this.TaskTypeChange.emit(val);
        }
    }
    @Output() TaskTypeChange = new EventEmitter<any>();

    @Output() TasksChanged = new EventEmitter<any>();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.Initialized = true;
        this.InitData();
    }

    InitData() {
        this.selectedNode = null;
        if (this.Initialized) {
            if (this.LayoutValue && this.LayoutValue.Datadescriptions && this.LayoutElementValue && this.LayoutElementValue.Query) {
                const data = this.LayoutValue.Datadescriptions.find((dd) => dd.ID === this.LayoutElementValue.Query);
                if (data) {
                    this.DataDescription = plainToClass(DataDescription, data.Datadescription);
                }
            }
            const taskList = this.getTaskList();
            if (taskList) {
                const list = this.getTaskListItems(taskList);
                this.AllListItems = list;
                this.FilterList();
            }
        }
    }

    FilterList() {
        this.ListItems = this.AllListItems.filter(item =>
            (!this._SearchValue || this._SearchValue == '' || item.Caption.toString().search(this._SearchValue) !== -1));
    }

    getTaskListItems(taskList):any [] {
        const list = [];
        taskList.forEach(task => {
            if (task.TaskType) {
                if (typeof task.ID === 'undefined') {
                    task.ID = UUID.UUID();
                }
                const desc = TaskRegistry.get(task.TaskType);
                if (desc) {
                    if (this.LayoutElementValue && task.QueryID !== this.LayoutElementValue.Query) {
                        return;
                    }
                    const item = {
                        Caption: task.TaskType,
                        Task: task,
                        Tooltip: task.TaskType
                    };
                    if (desc.SettingsHelper) {
                        if (!desc.SettingsHelper.canExecute({ LayoutElement: this.LayoutElementValue })) {
                            return;
                        }
                        item.Caption = desc.SettingsHelper.getCaption(task, this.DataDescription);
                        desc.SettingsHelper.Gettooltip(task, this.DataDescription).then((result) => {
                            const tooltip = result;
                            item.Tooltip = (!tooltip || tooltip === '') ? item.Caption : tooltip;
                        });
                    }
                    list.push(item);
                }
            }
        });
        return list;
    }

    getTaskList() {
        if (this.LayoutElementValue) {
            if (this.TaskTypeValue === TaskType.DataTask) {
                if (!this.LayoutElementValue.DataTasks) {
                    this.LayoutElementValue.DataTasks = [];
                }
                return this.LayoutElementValue.DataTasks;
            } else {
                if (!this.LayoutElementValue.FormatTasks) {
                    this.LayoutElementValue.FormatTasks = [];
                }
                return this.LayoutElementValue.FormatTasks;
            }
        }
        return null;
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
            let task = null;
            if (selection) {
                task = selection.Task;
            }
            this.setSelectedItem(task);
        }
    }

    addItem() {
        const taskList = this.getTaskList();
        if (taskList) {
            NewTaskDialog.ShowDialog(this.TaskTypeValue, { LayoutElement: this.LayoutElementValue }, (r) => {
                const task = {
                    TaskType: r,
                    IsActive: true,
                    QueryID: this.LayoutElementValue.Query,
                    ID: UUID.UUID()
                };
                const item = {
                    Caption: r,
                    Task: task
                };
                const desc = TaskRegistry.get(r);
                if (desc && desc.SettingsHelper) {
                    item.Caption = desc.SettingsHelper.getCaption(task, this.DataDescription);
                }
                this.editItemInternal(item, taskList).then(x => {
                    if (x) {
                        this.AllListItems.push(item);
                        this.SearchValue = null;
                        this.FilterList();
                        this.selectedNode = item;
                        this.setSelectedItem(task);
                        this.cdRef.detectChanges();
                    }
                });
            });
        }
    }

    copyItem() {
        if (this.selectedNode) {
            const taskList = this.getTaskList();
            if (taskList) {
                const task = JSON.parse(JSON.stringify(this.selectedNode.Task));
                const item = {
                    Caption: this.selectedNode.Caption,
                    Task: task
                };
                taskList.push(task);
                this.AllListItems.push(item);
                this.FilterList();
                this.selectedNode = item;
                this.setSelectedItem(task);
                this.editItemInternal(item, taskList).then(x => {
                    if (!x) {
                        this.TasksChanged.emit(true);
                    }
                });
            }
        }
    }

    setSelectedItem(item) {
        const activeStyle = {};
        if (item && item.IsActive === false) {
            activeStyle['opacity'] = '0.5';
        }
        this.ActiveStyle = activeStyle;
    }

    editItem() {
        const sel = this.selectedNode;
        const taskList = this.getTaskList();
        this.editItemInternal(sel, taskList);
    }

    editItemInternal(sel, taskList) {
        return new Promise<boolean>(resolve => {
            if (sel && taskList && (this.DataDescription || this.LayoutElementValue.ElementType == 'planningreportObject')) {
                TaskEditDialog.ShowDialog(sel.Task, this.DataDescription, this.LayoutElementValue, (result) => {
                    if (result) {
                        const index = taskList.indexOf(sel.Task);
                        if (index > -1) {
                            taskList.splice(index, 1, result);
                        } else {
                            taskList.push(result);
                        }
                        sel.Task = result;
                        const desc = TaskRegistry.get(result.TaskType);
                        if (desc && desc.SettingsHelper) {
                            sel.Caption = desc.SettingsHelper.getCaption(result, this.DataDescription);
                        }
                        this.TasksChanged.emit(true);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });        
    }

    deleteItem() {
        if (this.selectedNode) {
            const selIndex = this.ListItems.indexOf(this.selectedNode);
            const selIndex2 = this.AllListItems.indexOf(this.selectedNode);
            if (selIndex >= 0) {
                const taskList = this.getTaskList();
                if (taskList) {
                    const index = taskList.indexOf(this.selectedNode.Task);
                    if (index > -1) {
                        const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Task \'{0}\' loeschen moechten?');
                        text.FormatParams.push(this.selectedNode.Caption);
                        MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                            MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                                if (retVal === MessageBoxResult.Yes) {
                                    this.selectedNode = null;
                                    taskList.splice(index, 1);
                                    this.ListItems.splice(selIndex, 1);
                                    this.AllListItems.splice(selIndex2, 1);
                                    this.TasksChanged.emit(true);
                                }
                            });
                    }
                }
            }
        }
    }


    deleteAllItems() {
        let taskList = this.getTaskList();
        if (taskList) {
            if (taskList.length  > 0) {
                const text = TranslateHelper.TranslatorInstance.instant('@@Sind Sie sicher, dass Sie alle Tasks loeschen moechten?');
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                        if (retVal === MessageBoxResult.Yes) {
                            taskList = [];
                            this.ListItems = [];
                            this.AllListItems = [];
                            this.TasksChanged.emit(true);
                        }
                    });
            }
        }
    }
    moveUp() {
        if (this.selectedNode) {
            const selIndex = this.ListItems.indexOf(this.selectedNode);
            const selIndex2 = this.AllListItems.indexOf(this.selectedNode);
            if (selIndex2 > 0) {
                const taskList = this.getTaskList();
                if (taskList) {
                    const index = taskList.indexOf(this.selectedNode.Task);
                    if (index > -1) {
                        taskList.splice(index, 1);
                        taskList.splice(0, 0, this.selectedNode.Task);
                        this.ListItems.splice(selIndex, 1);
                        this.ListItems.splice(0, 0, this.selectedNode);
                        this.AllListItems.splice(selIndex2, 1);
                        this.AllListItems.splice(0, 0, this.selectedNode);

                        this.SearchValue = null;
                        this.FilterList();
                        this.setSelectedItem(this.selectedNode.Task);
                        this.TasksChanged.emit(true);
                    }
                }
            }
            
        }
    }

    moveOneUp() {
        if (this.selectedNode) {
            const selIndex = this.ListItems.indexOf(this.selectedNode);
            const selIndex2 = this.AllListItems.indexOf(this.selectedNode);
            if (selIndex > 0) {
                const taskList = this.getTaskList();
                if (taskList) {
                    const index = taskList.indexOf(this.selectedNode.Task);
                    const indexBefore = taskList.indexOf(this.ListItems[selIndex - 1].Task);
                    if (index > -1 && indexBefore > -1) {
                        taskList.splice(index, 1);
                        taskList.splice(indexBefore, 0, this.selectedNode.Task);
                        this.ListItems.splice(selIndex, 1);
                        this.ListItems.splice(selIndex - 1, 0, this.selectedNode);
                        this.AllListItems.splice(selIndex2, 1);
                        this.AllListItems.splice(selIndex2 - 1, 0, this.selectedNode);
                        this.TasksChanged.emit(true);
                    }
                }
            }
        }
    }

    moveOneDown() {
        if (this.selectedNode) {
            const selIndex = this.ListItems.indexOf(this.selectedNode);
            const selIndex2 = this.AllListItems.indexOf(this.selectedNode);
            if (selIndex > -1 && selIndex < this.ListItems.length - 1) {
                const taskList = this.getTaskList();
                if (taskList) {
                    const index = taskList.indexOf(this.selectedNode.Task);
                    const indexAfter = taskList.indexOf(this.ListItems[selIndex + 1].Task);
                    if (index > -1 && indexAfter > index) {
                        taskList.splice(index, 1);
                        taskList.splice(indexAfter, 0, this.selectedNode.Task);
                        this.ListItems.splice(selIndex, 1);
                        this.ListItems.splice(selIndex + 1, 0, this.selectedNode);
                        this.AllListItems.splice(selIndex2, 1);
                        this.AllListItems.splice(selIndex2 + 1, 0, this.selectedNode);
                        this.TasksChanged.emit(true);
                    }
                }
            }
        }
    }

    moveDown() {
        if (this.selectedNode) {
            const selIndex = this.ListItems.indexOf(this.selectedNode);
            const selIndex2 = this.AllListItems.indexOf(this.selectedNode);
            if (selIndex > -1 && selIndex < this.ListItems.length - 1) {
                const taskList = this.getTaskList();
                if (taskList) {
                    const index = taskList.indexOf(this.selectedNode.Task);
                    if (index > -1) {
                        taskList.splice(index, 1);
                        taskList.push(this.selectedNode.Task);
                        this.ListItems.splice(selIndex, 1);
                        this.ListItems.push(this.selectedNode);
                        this.AllListItems.splice(selIndex2, 1);
                        this.AllListItems.push(this.selectedNode);
                        this.SearchValue = null;
                        this.FilterList();
                        this.setSelectedItem(this.selectedNode.Task);
                        this.TasksChanged.emit(true);
                    }
                }
            }
        }
    }

    setActive() {
        const activeStyle = {};
        if (this.selectedNode) {
            if (this.selectedNode.Task.IsActive === false) {
                this.selectedNode.Task.IsActive = true;
            } else {
                this.selectedNode.Task.IsActive = false;
                activeStyle['opacity'] = '0.5';
            }
        }
        this.ActiveStyle = activeStyle;
    }
}

@Component({
    selector: 'task-edit-dialog',
    template: '<div class="flex-column scroll full"><ng-template #dynamic></ng-template></div>'
})
export class TaskEditDialog implements AfterViewInit {
    Task;
    DataDescription;
    LayoutElement;
    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    static ShowDialog(task, dd, le, handler) {
        BaseDialog.ShowDialog({
            ContentType: TaskEditDialog,
            InitArgs: {
                Task: task,
                DataDescription: dd,
                LayoutElement: le
            },
            Handler: (r) => {
                if (handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Task bearbeiten'
        });
    }

    constructor(private factoryResolver: ComponentFactoryResolver, private cdRef: ChangeDetectorRef, private renderer: Renderer2) {
    }

    Initialize(args) {
        if (args) {
            this.DataDescription = args.DataDescription;
            this.LayoutElement = args.LayoutElement;
            if (args.Task) {
                const json = JSON.stringify(args.Task);
                this.Task = JSON.parse(json);
            }
        }
    }

    GetDialogResult() {
        return this.Task;
    }

    ngAfterViewInit(): void {
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (this.Task && this.Task.TaskType) {
                const desc = TaskRegistry.get(this.Task.TaskType);
                if (desc) {
                    const factory = this.factoryResolver.resolveComponentFactory(desc.SettingsControl);
                    const compRef = this.viewContainerRef.createComponent(factory);
                    const component: any = compRef.instance;
                    component.DataDescription = this.DataDescription;
                    component.LayoutElement = this.LayoutElement;
                    component.Settings = this.Task;
                    this.renderer.addClass(compRef.location.nativeElement, 'full');
                }
            }
        }
        this.cdRef.detectChanges();
    }
}
