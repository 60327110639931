import { WorkflowDialogContent, DataCheck } from '../../workflow.dialog';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { plainToClass, Type } from 'class-transformer';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'define-date-wf-settings',
    templateUrl: './define.date.settings.html',
    styleUrls: ['./define.date.settings.css']
})
export class DefineDateSettings extends WorkflowDialogContent {
    static ModuleID = 'defineDateWFModule';

    Data = new DefineDateSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = DefineDateSettings.ModuleID;
        reg.Caption = '@@Datum definieren';
        reg.GroupID = 'generateData';
        reg.Index = 20;
        reg.SettingsControl = DefineDateSettings;
        reg.SettingsTypeHelper = new DefineDateSettingsDataHelper();
        reg.Executer = DefineDateModulExecuter;
        return reg;
    }

    initialize(data) {
        if (data) {
            const json = JSON.stringify(data);
            this.Data = plainToClass(DefineDateSettingsData, JSON.parse(json));
        }
    }

    getResult(): any {
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (!this.Data.UseActualDate) {
            if (this.Data.FixDate instanceof Date) {
                if (isNaN(this.Data.FixDate.getTime())) {
                    retVal.IsCorrect = false;
                    retVal.Error = '@@Bitte definiern Sie ein gueltiges Datum';
                }
            } else {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte definiern Sie ein gueltiges Datum';
            }
        }
        return retVal;
    }

    useActDateChanged(ev) {
        if (ev && ev.checked) {
            this.updateDate();
        }
    }

    updateDate() {
        this.Data.FixDate = DefineDateModulExecuter.GetManipulatedDate(this.Data);
    }
}

export class DefineDateSettingsData extends AServiceWorkflowData {
    UseActualDate = false;
    FixDate;
    YearManipulation = new DefineDateManipulation();
    @Type(() => DefineDateManipulation)
    MonthManipulation = new DefineDateManipulation();
    @Type(() => DefineDateManipulation)
    DayManipulation = new DefineDateManipulation();
    HourManipulation = new DefineDateManipulation();
    MinuteManipulation = new DefineDateManipulation();
    // SecondManipulation = new DefineDateManipulation();
    StatusKey: string;
    UseForState = true;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineDate.DefineDateSettingsData';
    }
}

export class DefineDateManipulation {
    IsFixedValue = false;
    Value = 0;

    get FixedValue() {
        if (this.IsFixedValue && this.Value == 0) {
            return 1;
        }
        return this.Value;
    }
    set FixedValue(val) {
        this.Value = val;
    }
}

export class DefineDateSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new DefineDateSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class DefineDateModulExecuter extends WorkflowModuleExecuter {
    static GetManipulatedDate(data) {
        const date = new Date();
        // if (data.SecondManipulation) {
        //    if (data.SecondManipulation.IsFixedValue) {
        //        date.setSeconds(data.SecondManipulation.Value);
        //    } else if (data.SecondManipulation.Value !== 0) {
        //        date.setSeconds(date.getSeconds() + data.SecondManipulation.Value);
        //    }
        // }
        if (data.MinuteManipulation) {
            if (data.MinuteManipulation.IsFixedValue) {
                date.setMinutes(data.MinuteManipulation.Value);
            } else if (data.MinuteManipulation.Value !== 0) {
                date.setMinutes(date.getMinutes() + data.MinuteManipulation.Value);
            }
        }
        if (data.HourManipulation) {
            if (data.HourManipulation.IsFixedValue) {
                date.setHours(data.HourManipulation.Value);
            } else if (data.HourManipulation.Value !== 0) {
                date.setHours(date.getHours() + data.HourManipulation.Value);
            }
        }
        if (data.DayManipulation) {
            if (data.DayManipulation.IsFixedValue) {
                date.setDate(data.DayManipulation.Value);
            } else if (data.DayManipulation.Value !== 0) {
                date.setDate(date.getDate() + data.DayManipulation.Value);
            }
        }
        if (data.MonthManipulation) {
            if (data.MonthManipulation.IsFixedValue) {
                date.setMonth(data.MonthManipulation.Value - 1);
            } else if (data.MonthManipulation.Value !== 0) {
                date.setMonth(date.getMonth() + data.MonthManipulation.Value);
            }
        }
        if (data.YearManipulation) {
            if (data.YearManipulation.IsFixedValue) {
                date.setFullYear(data.YearManipulation.Value);
            } else if (data.YearManipulation.Value !== 0) {
                date.setFullYear(date.getFullYear() + data.YearManipulation.Value);
            }
        }
        return date;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let date;
            if (status.ActualSettings.UseActualDate) {
                date = DefineDateModulExecuter.GetManipulatedDate(status.ActualSettings);
            } else {
                date = status.ActualSettings.FixDate;
            }
            if (status.ActualSettings.StatusKey) {
                status.Context.set(status.ActualSettings.StatusKey, date);
            }
            if (status.ActualSettings.UseForState === true) {
                status.ActualParameter = date;
            }
            return 0;
        } else {
            status.Logger.logError('DefineDate modul: No settings found.');
        }
        return super.execute(status);
    }
}
