import { Component } from '@angular/core';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { MediaService } from '../../../services/media.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-download-settings',
    templateUrl: './download.settings.html'
})
export class DownloadSettings extends FormulaWorkflowDialogContent {
    Data: DownloadSettingsData;
    MediaSources: any[];
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'downloadWFModule';
        reg.Caption = '@@Download';
        reg.GroupID = 'wfActions';
        reg.Index = 72;
        reg.SettingsControl = DownloadSettings;
        reg.SettingsTypeHelper = new DownloadSettingsDataHelper();
        reg.Executer = DownloadModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.HasExpressions = false;
        this.MediaSources = MediaService.MediaSources.getValue();
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            this.Data = data;
        }
    }

    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }

    showFormulaDialog(value) {
        const variables = [...this.Variables];
        const args = {
            InputMode: FormulaInputMode.VariableName,
            Formula: this.Data[value],
            Variables: variables
        };
        FormulaEditorDialog.ShowDialog(args, (result) => {
            if (result && result.Formula) {
                this.Data[value] = result.Formula;
            }
            return true;
        });
    }
}

export class DownloadSettingsData {
    UseMediaSource: boolean;
    MediaSourceID: string;
    Resource: string;
    AutoSave: boolean;
    FileName: string;
}

export class DownloadSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new DownloadSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class DownloadModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        return new Promise((resolve) => {
            if (status.ActualSettings) {
                const calculator = new WorkflowFormulaCalculator(status);
                if (status.ActualSettings.UseMediaSource && status.ActualSettings.MediaSourceID) {
                    let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
                    mediaService.LoadImage(status.ActualSettings.MediaSourceID, calculator.CalcFormula(status.ActualSettings.Resource)).subscribe((image) => {
                        if (status.ActualSettings.AutoSave && status.ActualSettings.FileName) {
                            const link = document.createElement('a');
                            link.href = image;
                            link.download = calculator.CalcFormula(status.ActualSettings.FileName);
                            // Version link.click() to work at firefox
                            link.dispatchEvent(new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));

                            setTimeout(() => { // firefox
                                window.URL.revokeObjectURL(image);
                                link.remove();
                            }, 100);
                        }
                        status.ActualParameter = image;
                        resolve(0);
                    });
                } else {
                    if (status.ActualSettings.Resource) {
                        let image = calculator.CalcFormula(status.ActualSettings.Resource);
                        if (status.ActualSettings.AutoSave && status.ActualSettings.FileName) {
                            const link = document.createElement('a');
                            link.href = image;
                            link.download = calculator.CalcFormula(status.ActualSettings.FileName);
                            // Version link.click() to work at firefox
                            link.dispatchEvent(new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));

                            setTimeout(() => { // firefox
                                window.URL.revokeObjectURL(image);
                                link.remove();
                            }, 100);
                            status.ActualParameter = image;
                            resolve(0);
                        } else {
                            status.Logger.logError('Download module: No Filename found.');
                            resolve(-1)
                        }
                        
                    } else {
                        status.Logger.logError('Download module: No resource found.');
                        resolve(-1)
                    }
                }
            } else {
                status.Logger.logError('Download module: No settings found.');
                resolve(-1)
            }
        });
    }
}
