import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { TranslatedString } from '../../../../../models/translatedstring.model';
import { RestService } from '../../../../../services/rest.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../../../base.list.settings';
import { EndpointRequestBody } from '../../../models/end.point.description';
import { EndPointRestItem } from '../../../models/end.point.rest.item';
import { EndPointRestDetailComponent } from './end.pont.rest.detail';

@Component({
    selector: 'end-poin-rest',
    templateUrl: '../../../../base.list.settings.html'
})
export class EndPointRestComponent extends BaseListSettings {

    constructor(private _service: RestService, private _translate: TranslateService, fr: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(fr, cdRef);
    }

    loadList(handler: any) {
        this._service.loadEndPoints(this.InitArgs.SID).subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r => list.push({
                    Caption: r.Caption.DefaultValue,
                    ID: r.SID
                }));
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.loadEndPoint(data).subscribe(result => this.setSelectedItem(result));
    }

    getNewItem() {
        const retVal = new EndPointRestItem();
        retVal.RestID = this.InitArgs.SID;
        retVal.Caption = new TranslatedString(this._translate.instant('@@Neue Endpunkt'));
        retVal.Description = '';
        retVal.Parameters = [];
        retVal.RequestBody = new EndpointRequestBody();
        retVal.Responses = [];
        return retVal;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Endpunkt \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Endpunkt \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Endpunkt loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._service.remove(data).subscribe(() => handler());
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Endpunkt \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Endpunkt speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        this._service.saveEndPoint(item as EndPointRestItem).subscribe(result => {
            if (result)
                handler(new EndPointRestItem(result), result.SID, result.Caption);
        });
    }

    getContentType() {
        return EndPointRestDetailComponent;
    }
}