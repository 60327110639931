import { Component } from '@angular/core';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';

@Component({
    selector: 'queryselect-menu-tab',
    templateUrl: './query.menu.tab.html',
    styleUrls: ['./query.menu.tab.css']
})
export class QuerySelectMenuTab extends ALayoutMenuTab {
    Queries = [];

    onLayoutSet() {
        let queries = [];
        if (this.Layout && this.Layout.Datadescriptions) {
            queries = this.Layout.Datadescriptions;
        }
        this.Queries = queries;
    }

    queryChanged() {
        if (this.SelectedItemValue) {
            if (this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.OnDataBindingChanged === 'function') {
                this.SelectedItemValue.Element.OnDataBindingChanged();
            }
            this.onStyleChanged('Query');
        }
    }
}
