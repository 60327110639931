import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataModelService, DataSourceInfo, DataSourceQuery } from '../../../services/datamodel.service';

@Component({
    selector: 'datasource-selection-control',
    templateUrl: './datasource.selection.control.html',
    styleUrls: ['./datasource.selection.control.css']
})
export class DataSourceSelectionControl implements OnInit {
    //#region Query
    QueryValue: DataSourceQuery;

    @Input()
    get Query(): DataSourceQuery {
        return this.QueryValue;
    }
    set Query(val: DataSourceQuery) {
        this.QueryValue = val;
        this.loadDataSources();
        this.QueryChange.emit(this.QueryValue);
    }

    @Output() QueryChange = new EventEmitter<DataSourceQuery>();
    //#endregion

    //#region DataSource
    DataSourceValue: DataSourceSelectionInfo = new DataSourceSelectionInfo();

    @Input()
    get DataSource(): DataSourceSelectionInfo {
        return this.DataSourceValue;
    }
    set DataSource(val: DataSourceSelectionInfo) {
        if (!val) {
            val = new DataSourceSelectionInfo();
        }
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<DataSourceSelectionInfo>();
    //#endregion

    //#region Variables
    VariablesValue = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        if (val) {
            this.VariablesValue = val;
            this.VariablesChange.emit(this.VariablesValue);
        }
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion

    private DataSources: DataSourceInfo[] = [];

    @Output() SourceChanged = new EventEmitter();
    @Output() InteractivePropertyChanged = new EventEmitter<string>();
    Initialized = false;

    constructor(private service: DataModelService) {
    }

    ngOnInit(): void {
        this.Initialized = true;
        this.loadDataSources();
    }

    loadDataSources() {
        if (this.Initialized) {
            this.DataSources = [];
            if (this.QueryValue && this.QueryValue.DataModelID) {
                this.service.GetDataSourcesByQuery(this.QueryValue).subscribe(x => {
                    if (x) {
                        this.DataSources = x;
                    }
                    this.checkProps();
                });
            }
        }
    }

    dataSourceChanged() {
        this.checkProps();
        this.SourceChanged.emit();
    }

    checkProps() {
        if (this.DataSourceValue) {
            const props = [];
            if (this.DataSources) {
                const source = this.DataSources.find(x => x.SID === this.DataSourceValue.DataSource);
                if (source && source.InteractiveProperties) {
                    source.InteractiveProperties.forEach(prop => {
                        const ipi = new InteractivePropertyInfo();
                        ipi.Name = prop;
                        if (this.DataSourceValue.InteractiveProperties) {
                            const existing = this.DataSourceValue.InteractiveProperties.find(x => x.Name === prop);
                            if (existing) {
                                ipi.Value = existing.Value;
                            }
                        }
                        props.push(ipi);
                    });
                }
            }
            this.DataSourceValue.InteractiveProperties = props;
        }
    }

    onPropChanged(name) {
        this.InteractivePropertyChanged.emit(name);
    }
}

export class InteractivePropertyInfo {
    Name: string;
    Value: string;
}

export class DataSourceSelectionInfo {
    DataSource: string;
    InteractiveProperties: InteractivePropertyInfo[] = [];
}
