import { Component, OnInit } from '@angular/core';
import { CreateDialogFlagService } from '../../create.flag.service';
@Component({
  selector: 'lib-without-dashboard',
  templateUrl: './without-dashboard.component.html',
  styleUrls: ['./without-dashboard.component.css'],
})
export class WithoutDashboardComponent implements OnInit {
  title = {
    DisplayName: '',
  };
  isShowCreateDialog: boolean;
  isApplicationProcess: boolean;
  constructor(private createDialogFlagService: CreateDialogFlagService) {}

  ngOnInit(): void {
    this.title = JSON.parse(localStorage.getItem('user'));
  }

  openCreateDialog() {
    this.createDialogFlagService.setShowCreateDialog({ isShow: true, action: 'both' });
    // this.isApplicationProcess = true;
    // this.isShowCreateDialog = true;
  }

  // closeCreateDialog(flag: boolean) {
  //   this.isShowCreateDialog = flag;
  //   this.isApplicationProcess = flag;
  //   this.createDialogFlagService.setShowCreateDialog({ isShow: false, action: 'both' });
  // }
}
