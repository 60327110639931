import { Component, OnInit } from '@angular/core';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { RestService } from '../../../services/rest.service';

@Component({
    selector: 'rest-object-info-dialog',
    templateUrl: 'rest.object.info.dialog.html'
})
export class RestObjectInfoDialog implements OnInit {
    ObjectID;
    RootNodes = [];

    private static BuildNodes(props, idKeeper: IDKeeper) {
        const retVal = [];
        if (props) {
            props.forEach(x => {
                const node = new ABaseTreeNode(idKeeper.NextID);
                node.Caption = x.Name;
                if (x.IsObject) {
                    if (x.IsList) {
                        node.Caption += ' (' + (x.IsList ? 'list of ' : '') + x.ObjectName + ')';
                    }
                    node.HasChildren = true;
                    node.Children = RestObjectInfoDialog.BuildNodes(x.ObjectProperties, idKeeper);
                    node.IsExpanded = true;
                } else if (x.SimpleType) {
                    node.Caption += ' (' + (x.IsList ? 'list of ' : '') + x.SimpleType + ')';
                }
                retVal.push(node);
            });
        }
        return retVal;
    }

    static ShowDialog(objectID) {
        BaseDialog.ShowDialog({
            ContentType: RestObjectInfoDialog,
            InitArgs: { ObjectID: objectID },
            Buttons: DialogButton.Ok,
            Title: '@@Objekt-Info'            
        });
    }

    constructor(private service: RestService) {
    }

    ngOnInit(): void {
        if (this.ObjectID) {
            this.service.GetRestObjectProperties(this.ObjectID).subscribe(x => {
                if (x) {
                    const idKeeper = new IDKeeper();
                    const node = new ABaseTreeNode(idKeeper.NextID);
                    node.Caption = x.ObjectName;
                    node.HasChildren = true;
                    node.Children = RestObjectInfoDialog.BuildNodes(x.ObjectProperties, idKeeper);
                    node.IsExpanded = true;
                    this.RootNodes = [node];
                }
            });
        }
    }

    Initialize(args) {
        if (args && args.ObjectID) {
            this.ObjectID = args.ObjectID;
        }
    }
}
