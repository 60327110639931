import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { FormulaNodeCalculator } from '../../../../models/calculation/formula.node.calculator';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { MultiVariableEntry, ReadMultiVariableSettingsData } from '../readVariable/read.multi.variable.settings';
import { ReadGlobalVariableSettings } from './readGlobalVariable.settings';

@Component({
    selector: 'wf-readMultiGlobalVariable-settings',
    templateUrl: './read.multi.global.variable.settings.html'
})
export class ReadMultiGlobalVariableSettings extends WorkflowDialogContent {
    Data = new ReadMultiVariableSettingsData();
    GlobalVariables = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ReadGlobalVariableSettings.MultiModuleID;
        reg.Caption = '@@Globale Variablen auslesen';
        reg.SettingsControl = ReadMultiGlobalVariableSettings;
        reg.SettingsTypeHelper = new ReadMultiGlobalVariableSettingsDataHelper();
        reg.Executer = ReadMultiGlobalVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadMultiVariableSettingsData, json);
            CacheService.ReadGlobalVariables().then((vars) => {
                if (vars) {
                    const variableList = [];
                    vars.forEach(x => {
                        const varObj = {
                            Caption: x.Name,
                            Name: x.Name
                        };
                        const dt = VariableHelper.GetTypeCaption(x.Type);
                        if (dt) {
                            varObj.Caption = x.Name + ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ')';
                        }
                        variableList.push(varObj);
                    });
                    this.GlobalVariables = variableList;
                }
            });
        }
    }

    getResult(): any {
        const variables = [];
        this.Data.Variables.forEach(x => {
            if (x.Variable && (x.UseForState || x.StatusKey)) {
                variables.push(x);
            }
        });
        this.Data.Variables = variables;
        return this.Data;
    }

    add() {
        if (this.Data) {
            if (this.Data.Variables) {
                this.Data.Variables.push(new MultiVariableEntry());
            } else {
                this.Data.Variables = [new MultiVariableEntry()];
            }
        }
    }

    delete(i) {
        if (this.Data && this.Data.Variables) {
            this.Data.Variables.splice(i, 1);
        }
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Variables) {
            this.Data.Variables.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }
}

export class ReadMultiGlobalVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadMultiVariableSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Variables) {
                const list = [];
                settings.Variables.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class ReadMultiGlobalVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variables) {
                const global: any = await CacheService.RefreshGlobalVariableValues();
                if (global && global.length > 0) {
                    status.ActualSettings.Variables.forEach(v => {
                        const variable = global.find(x => x.Name === v.Variable);
                        if (variable) {
                            const vat = FormulaNodeCalculator.GetVariableValue(variable, false).Value;
                            if (v.StatusKey) {
                                status.Context.set(v.StatusKey, vat);
                            }
                            if (v.UseForState) {
                                status.ActualParameter = vat;
                            }
                        } else {
                            status.Logger.logWarning('ReadGlobalVariable module: Global variable ' + v.Variable + ' could not be found.');
                        }
                    });
                } else {
                    status.Logger.logError('ReadGlobalVariable module: No global variables found.');
                    return -1;
                }
            }
            return 0;
        } else {
            status.Logger.logError('ReadGlobalVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
