import { Type } from 'class-transformer';
import { Color } from './color.model';

// @dynamic
export class GradientStopColor {
    @Type(() => Color)
    Color: Color = Color.FromHex('#000000');
    Offset = 0;

    static IsSameGradientStop(gradient1: GradientStopColor, gradient2: GradientStopColor): boolean {
        if (gradient1) {
            if (gradient2) {
                if (gradient1.Offset === gradient2.Offset) {
                    const col1 = gradient1.Color;
                    const col2 = gradient2.Color;
                    return col1.A === col2.A && col1.R === col2.R && col1.G === col2.G && col1.B === col2.B;
                }
            }
            return false;
        } else {
            if (gradient2) {
                return false;
            }
            return true;
        }
    }

    static FromObject(gsc): GradientStopColor {
        if (gsc) {
            const retVal = new GradientStopColor();
            retVal.Offset = gsc.Offset;
            retVal.Color = Color.FromObject(gsc.Color);
            return retVal;
        }
        return null;
    }
}
