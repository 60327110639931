import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { FlexContentAlignment, FlexItemAlignment } from '../../../models/enums/alignment.enum';
import { Orientation } from '../../../models/enums/orientation.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-flex',
    templateUrl: './flex.layout.html',
    styleUrls: ['./flex.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexLayout extends IBaseComponent {

    static Type: any = 'flex';
    static Default = {
        Editable: true,
        Type: 'flex'
    };
    //#region Items
    _Items;
    _Items$;
    @Input()
    public get Items() {
        return this._Items;
    }
    public set Items(val) {
        this._Items = val;
        this._Items$ = of(val);
        if (this.ItemsChange) {
            this.ItemsChange.emit(this._Items);
        }
    }
    @Output()
    public ItemsChange = new EventEmitter();
    //#endregion
    //#region Orientation
    OrientationValue;
    @Input()
    public get Orientation() {
        if (this.LayoutElement) {
            return this.LayoutElement.Orientation;
        } else {
            return this.Orientation;
        }
    }
    public set Orientation(val) {
        if (this.LayoutElement) {
            this.LayoutElement.Orientation = val;
            if (this.OrientationChange) {
                this.OrientationChange.emit(this.LayoutElement.Orientation);
            }
        } else {
            this.OrientationValue = val;
            if (this.OrientationChange) {
                this.OrientationChange.emit(this.OrientationValue);
            }
        }
    }
    @Output()
    public OrientationChange = new EventEmitter();
    //#endregion
    dragItem;
    dragType;
    ResizeTimer;
    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ControlInitialized() {
        this.cdRef.detectChanges();
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.cdRef.detectChanges();
        });
    }

    onLayoutElementSet() {
        this.Items = this.LayoutElementValue.Elements;
    }
    onLayoutElementChanged() {
        this.Items = this.LayoutElementValue.Elements;
    }

    itemDrop(event) {
        let insert;
        insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
        insert.Parent = this.LayoutElement;
        this.LayoutElement.Elements.push(insert);
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(insert),
            Index: this.LayoutElementValue.Elements.length - 1,
            ParentID: this.LayoutElementValue.ID
        });
        event.currentTarget.style.backgroundColor = 'transparent';
    }

    removeItem(item) {
        const index = this.Items.indexOf(item);
        if (index > -1) {
            this.Items.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
        }
    }

    itemSelected(item, event) {
        if (!item.Selected && !this.FromBaseLayout) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
        }
    }

    GetDropOrientation() {
        switch (this.LayoutElement.Orientation) {
            case Orientation.Horizontal:
            case Orientation.HorizontalReverse:
            case Orientation.HorizontalWrapping:
            case Orientation.HorizontalReverseWrapping:
                return 'horizontal';
            case Orientation.Vertical:
            case Orientation.VerticalReverse:
            case Orientation.VerticalWrapping:
            case Orientation.VerticalReverseWrapping:
                return 'vertical';
        }
    }
    cdkDrop(event: CdkDragDrop<any[]>) {
        const item = this._Items[event.previousIndex];
        moveItemInArray(this._Items, event.previousIndex, event.currentIndex);
        LayoutService.OnLayoutElementMoved({
            ElementID: item.ID,
            Index: event.currentIndex,
            OldParentID: this.LayoutElementValue.ID,
            NewParentID: this.LayoutElementValue.ID
        });
    }

    trackByID(index, item) {
        return item.ID;
    }
}

export class FlexPanel extends BasePanel{
    static override SIDS = ['7e4c7494-c70a-4344-b801-cc2731d43e31']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'7e4c7494-c70a-4344-b801-cc2731d43e31',
            ID: 'flex',
            Caption: '@@Flex',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'flex';
            }
        });
       
        PROPERTIES.push({
            ID: "Orientation",
            Parent: "flex",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Orientation",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: Orientation
            }
        });
        PROPERTIES.push({
            ID: "FlexAlignItems",
            Parent: "flex",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@AlignItems",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: FlexItemAlignment
            }
        });
        PROPERTIES.push({
            ID: "FlexAlignContent",
            Parent: "flex",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@AlignContent",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: FlexContentAlignment
            }
        });
    }
}