import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { NavigationHelper } from '../../../helpers/navigation.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { REGISTRY } from '../../../services/dynamic.component.service';
import { MetaService } from '../../../services/meta.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SideNavService } from '../../../services/sidenav.service';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'permissions',
    templateUrl: './permissions.page.html',
    styleUrls: ['./permissions.page.css']
})
export class PermissionsPage implements OnInit {

    InitArgs;
    RootNodes = [];

    SelectedNode: PermissionNode;
    SelectedResolution;
    NavPermHasChanges = false;
    NavigationStructure;
    NaviStructureHidden = false;

    NavigationPermissions;
    ContentMap = new Map();

    toggleWindow = true;

    Columns = [
        {
            Name: 'Name',
            Label: '@@Label',
            Type: 'String',
            Editable: false
        },
        {
            Name: 'InVisible',
            Label: '@@InVisible',
            Type: 'Boolean',
            Editable: true
        },
        {
            Name: 'Disabled',
            Label: '@@Disabled',
            Type: 'Boolean',
            Editable: true
        }
    ];
    TreeSource;
    Resolutions;
    private static getNodes(structList, idKeeper): PermissionNode[] {
        const retVal = [];
        if (structList) {
            structList.forEach(x => {
                const node = new PermissionNode(idKeeper.NextID);
                node.NodeID = x.Data._Id;
                node.Url = x.Data.Url;
                node.Caption = TranslatedString.GetTranslation(x.Data.Caption);
                const children = PermissionsPage.getNodes(x.Children, idKeeper);
                if (children && children.length > 0) {
                    node.HasChildren = true;
                    node.Children = children;
                }
                retVal.push(node);
            });
        }
        return retVal;
    }

    private static GetLayoutNodes(elements, depth, permission) {
        const retVal = [];
        if (elements) {
            elements.forEach(elem => {
                const node = {
                    Name: elem.Name !== null ? elem.Name : 'undefined',
                    InVisible: false,
                    Disabled: false,
                    Depth: depth,
                    ID: elem.ID,
                    Icon: null,
                    Expanded: false,
                    Children: null
                };
                const reg = REGISTRY[elem.ElementType];
                if (reg) {
                    node.Icon = reg.Icon;
                }
                if (permission) {
                    const entry = permission[elem.ID];
                    if (entry) {
                        node.InVisible = entry.InVisible;
                        node.Disabled = entry.Disabled;
                    }
                }
                const children = PermissionsPage.GetLayoutNodes(elem.Elements, depth + 1, permission);
                if (children.length > 0) {
                    node.Expanded = true;
                    node.Children = children;
                }
                retVal.push(node);
            });
        }
        return retVal;
    }

    private static PrepareSave(nodeList, permission) {
        if (nodeList) {
            nodeList.forEach(node => {
                if (node.InVisible === true || node.Disabled === true) {
                    permission[node.ID] = {
                        InVisible: node.InVisible,
                        Disabled: node.Disabled
                    };
                }
                PermissionsPage.PrepareSave(node.Children, permission);
            });
        }
    }

    constructor(private permissionService: PermissionsService, private metaService: MetaService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.InitArgs) {
            this.metaService.GetNavigationStructure(this.InitArgs.StructureID).subscribe((ns) => {
                if (ns) {
                    this.NavigationStructure = ns;
                    this.permissionService.ReadNavigationByKey(this.InitArgs.RoleID).subscribe((permission) => {
                        if (permission) {
                            this.NavigationPermissions = permission;
                        } else {
                            this.NavigationPermissions = {
                                RoleID: this.InitArgs.RoleID,
                                Elements: {}
                            };
                        }
                        const structPerm = this.NavigationPermissions.Elements[this.InitArgs.StructureID];
                        this.NaviStructureHidden = typeof structPerm === 'object' && structPerm.InVisible === true;                        
                        this.metaService.GetNavigationNodesForStructure(this.InitArgs.StructureKey).subscribe(nodes => {
                            const structure = NavigationHelper.BuildStructure(nodes);
                            const idKeeper = new IDKeeper();
                            const rootNodes = [];
                            if (ns.BaseLayout) {
                                const baseLayoutNode = new PermissionNode(idKeeper.NextID);
                                baseLayoutNode.Url = this.InitArgs.StructureID + '_baselayout';
                                baseLayoutNode.Caption = TranslateHelper.TranslatorInstance.instant('@@BaseLayout');
                                rootNodes.push(baseLayoutNode);
                            }
                            if (ns.LandingPage) {
                                const landingPageNode = new PermissionNode(idKeeper.NextID);
                                landingPageNode.Url = ns.LandingPage;
                                landingPageNode.Caption = TranslateHelper.TranslatorInstance.instant('@@LandingPage');
                                rootNodes.push(landingPageNode);
                            }
                            rootNodes.push(...PermissionsPage.getNodes(structure, idKeeper));
                            this.RootNodes = rootNodes;
                            this.cdRef.detectChanges();
                        });
                    });
                }
            });
        }
    }

    async checkHasChanges() {
        let hasChanges = this.NavPermHasChanges;
        if (!hasChanges) {
            this.ContentMap.forEach(v => {
                if (v.HasChanges) {
                    hasChanges = true;
                }
            });
        }
        if (hasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.Save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                this.NavPermHasChanges = false;
                this.ContentMap.forEach(v => {
                    v.HasChanges = false;
                });
            }
        }
        return true;
    }

    structPermChanged() {
        if (this.NavigationPermissions && this.NavigationPermissions.Elements) {
            this.NavigationPermissions.Elements[this.InitArgs.StructureID] = {
                InVisible: this.NaviStructureHidden
            };
            this.NavPermHasChanges = true;
        }
    }

    treeDataChanged() {
        if (this.SelectedNode) {
            const actContent = this.ContentMap.get(this.SelectedNode.Url);
            if (actContent) {
                actContent.HasChanges = true;
            }
        }
    }

    setHasChanges() {
        this.NavPermHasChanges = true;
    }

    nodeSelected(node) {
        this.ToggleWindow();
        this.SelectedNode = node;
        this.TreeSource = null;
        if (node.NodeID) {
            if (!this.NavigationPermissions.Elements[node.NodeID]) {
                this.NavigationPermissions.Elements[node.NodeID] = {};
            }
        }
        const actContent = this.ContentMap.get(node.Url);
        if (actContent) {
            this.TreeSource = actContent.TreeSource;
            this.Resolutions = actContent.Resolutions;
            if (this.Resolutions && this.Resolutions.length > 0) {
                this.SelectedResolution = this.Resolutions[0];
            }
        } else {
            if (node.Url === this.InitArgs.StructureID + '_baselayout') {
                const key = this.InitArgs.RoleID + '_' + this.InitArgs.StructureID + '_baselayout';
                this.permissionService.ReadByKey(key).subscribe(layoutPerm => {
                    if (!layoutPerm) {
                        layoutPerm = {
                            RoleID: this.InitArgs.RoleID,
                            StructureID: this.InitArgs.StructureID,
                            LayoutKey: 'baselayout',
                            Elements: {}
                        };
                    }
                    this.TreeSource = PermissionsPage.GetLayoutNodes([this.NavigationStructure.BaseLayout], 0, layoutPerm.Elements);
                    this.Resolutions = this.FillResolutions(this.NavigationStructure.BaseLayout, layoutPerm);
                    this.ContentMap.set(node.Url, {
                        Permission: layoutPerm,
                        TreeSource: [...this.TreeSource],
                        HasChanges: false,
                        Resolutions: this.Resolutions ? [...this.Resolutions] : null
                    });
                    this.cdRef.detectChanges();
                });
            } else {
                if (node.Url && !SideNavService.IsSystemURL(node.Url)) {
                    this.metaService.ReadLayoutByKey(node.Url).subscribe(layout => {
                        if (layout) {
                            const key = this.InitArgs.RoleID + '_' + this.InitArgs.StructureID + '_' + layout._key;
                            this.permissionService.ReadByKey(key).subscribe(layoutPerm => {
                                if (!layoutPerm) {
                                    layoutPerm = {
                                        RoleID: this.InitArgs.RoleID,
                                        StructureID: this.InitArgs.StructureID,
                                        LayoutKey: layout._key,
                                        Elements: {}
                                    };
                                }
                                this.TreeSource = PermissionsPage.GetLayoutNodes([layout], 0, layoutPerm.Elements);
                                this.Resolutions = this.FillResolutions(layout, layoutPerm);
                                this.ContentMap.set(node.Url, {
                                    Permission: layoutPerm,
                                    TreeSource: [...this.TreeSource],
                                    HasChanges: false,
                                    Resolutions: this.Resolutions?[...this.Resolutions]:null
                                });
                                this.cdRef.detectChanges();
                            });
                        }
                    });
                }
            }
        }
    }
    FillResolutions(Layout, layoutPerm) {
        if (Layout && Layout.Resolutions && Layout.Resolutions.length > 0) {
            let list = [];
            list.push({
                Name: "Default",
                Layout: Layout,
                Tree: [...this.TreeSource]
            })
            this.SelectedResolution = list[0];
            Layout.Resolutions.forEach((rel) => {
                list.push({
                    Name: rel.Name,
                    Layout: rel,
                    Tree: PermissionsPage.GetLayoutNodes([rel], 0, layoutPerm.Elements)
                })
            });
            return list;
        }
        this.SelectedResolution = null;
        return null;
    }
    Save() {
        this.ContentMap.forEach(v => {
            if (v.HasChanges) {
                const elements = {};
                if (v.Resolutions && v.Resolutions.length > 0) {
                    for (var i = 0; i < v.Resolutions.length; i++) {
                        let tree = v.Resolutions[i].Tree;
                        PermissionsPage.PrepareSave(tree, elements);
                    }
                } else {
                    PermissionsPage.PrepareSave(v.TreeSource, elements);
                }
                
                v.Permission.Elements = elements;
                const key = this.InitArgs.RoleID + '_' + this.InitArgs.StructureID + '_' + v.Permission.LayoutKey;
                this.permissionService.AddOrUpdateWithKey(v.Permission, key).subscribe(res => {
                    if (res) {
                        v.Permission._Id = res[0];
                        v.Permission._version = res[1];
                        NotificationHelper.Success('@@Content permission for url {0} has been successfully saved',
                            '@@Save content permission', { 0: v.Permission.LayoutKey });
                    }
                });
                v.HasChanges = false;
            }
        });
        if (this.NavPermHasChanges) {
            const elements = {};
            Object.keys(this.NavigationPermissions.Elements).forEach(x => {
                const entry = this.NavigationPermissions.Elements[x];
                if (typeof entry === 'object' && entry.InVisible === true) {
                    elements[x] = entry;
                }
            });
            this.NavigationPermissions.Elements = elements;
            this.permissionService.AddOrUpdateNavigationWithKey(this.NavigationPermissions,
                this.NavigationPermissions.RoleID).subscribe((res) => {
                    this.NavigationPermissions['_Id'] = res[0];
                    this.NavigationPermissions['_version'] = res[1];
                    NotificationHelper.Success('@@Navigation permissions have been successfully saved', '@@Save navigation permissions');
                });
            this.NavPermHasChanges = false;
        }
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}

export class PermissionNode extends ABaseTreeNode {
    NodeID;
    Url;
}
