import { TranslateService } from '@ngx-translate/core';
import { SnotifyService, SnotifyToastConfig, SnotifyPosition } from 'ng-snotify';
import { MessageType } from '../models/enums/messagetype.enum';
import { TranslateFormatText } from './array.helpers';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { ReceivedMessage } from '../models/notification/receivedmessage.model';
import { BehaviorSubject } from 'rxjs';

export class NotificationHelper {
    public static ReceivedMessages: BehaviorSubject<ReceivedMessage[]> = new BehaviorSubject<ReceivedMessage[]>([]);
    public static IgnoreErrorMessages = false;
    private static Service: SnotifyService = null;
    private static Translate: TranslateService = null;
    private static Config: SnotifyToastConfig = {
        timeout: 2000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        titleMaxLength: 200,
        position: SnotifyPosition.leftBottom,
    };
    public static Initialize(service: SnotifyService, translate: TranslateService ) {
        NotificationHelper.Service = service;
        NotificationHelper.Translate = translate;
    }
    public static Success(content, title, contentParams?, titleParams?) {
        NotificationHelper.TranslateTexts(content, title, contentParams, titleParams, MessageType.Success);
    }
    public static Info(content, title, contentParams?, titleParams?) {
        NotificationHelper.TranslateTexts(content, title, contentParams, titleParams, MessageType.Information);
    }
    public static Error(content, title, contentParams?, titleParams?) {
        NotificationHelper.TranslateTexts(content, title, contentParams, titleParams, MessageType.Error);
    }
    public static Warn(content, title, contentParams?, titleParams?) {
        NotificationHelper.TranslateTexts(content, title, contentParams, titleParams, MessageType.Warning);
    }

    public static HandleClientNotification(clientNotification) {
        let level = NotificationLevel.Information; // Information
        if (clientNotification.Level) {
            level = clientNotification.Level;
        }
        const cnContent = JSON.parse(clientNotification.Content);
        let title = '';
        if (cnContent.Title) {
            title = cnContent.Title;
        }
        let content = '';
        if (cnContent.Content) {
            content = cnContent.Content;
        }
        let config = NotificationHelper.Config;
        if (cnContent.HTMLContent) {
            config = JSON.parse(JSON.stringify(config));
            config.html = cnContent.HTMLContent;
        }
        switch (level) {
            case NotificationLevel.Error:
            case NotificationLevel.Critical:
                if (!NotificationHelper.IgnoreErrorMessages) {
                    NotificationHelper.Service.error(content, title, config);
                }
                NotificationHelper.AddToMessages(MessageType.Error, title, content, clientNotification.CreateDate);
                break;
            case NotificationLevel.Warning:
                NotificationHelper.Service.warning(content, title, config);
                NotificationHelper.AddToMessages(MessageType.Warning, title, content, clientNotification.CreateDate);
                break;
            default:
                NotificationHelper.Service.info(content, title, config);
                NotificationHelper.AddToMessages(MessageType.Information, title, content, clientNotification.CreateDate);
                break;
        }
    }
    public static HandleErrorNotification(clientNotification) {
        let level = NotificationLevel.Information; // Information
        const cnContent = JSON.parse(clientNotification.Content);
        let title = '';
        
        if (cnContent.Title) {
            title = cnContent.Title;
        }
        let content = '';
        if (cnContent.Content) {
            content = cnContent.Content;
        }
        let config = NotificationHelper.Config;
        if (cnContent.HTMLContent) {
            config = JSON.parse(JSON.stringify(config));
            config.html = cnContent.HTMLContent;
        }
        if (cnContent.Level) {
            level = cnContent.Level;
        }
        switch (level) {
            case NotificationLevel.Error:
            case NotificationLevel.Critical:
                if (!NotificationHelper.IgnoreErrorMessages) {
                    NotificationHelper.Service.error(content, title, config);
                }
                NotificationHelper.AddToMessages(MessageType.Error, title, content, clientNotification.CreateDate);
                break;
            case NotificationLevel.Warning:
                NotificationHelper.Service.warning(content, title, config);
                NotificationHelper.AddToMessages(MessageType.Warning, title, content, clientNotification.CreateDate);
                break;
            default:
                NotificationHelper.Service.info(content, title, config);
                NotificationHelper.AddToMessages(MessageType.Information, title, content, clientNotification.CreateDate);
                break;
        }
    }

    private static TranslateTexts(content, title, contentParams, titleParams, messageType) {
        if (content instanceof TranslateFormatText) {
            contentParams = {};
            content.FormatParams.forEach((v, i) => {
                contentParams[i.toString()] = v;
            });
            content = content.TranslateText;
        }
        if (title instanceof TranslateFormatText) {
            titleParams = {};
            title.FormatParams.forEach((v, i) => {
                titleParams[i.toString()] = v;
            });
            title = title.TranslateText;
        }

        if (content) {
            NotificationHelper.Translate.get(content, contentParams).subscribe((body) => {
                if (title) {
                    NotificationHelper.Translate.get(title, titleParams).subscribe((head) => {
                        NotificationHelper.Send(messageType, head, body, true);
                    });
                } else {
                    NotificationHelper.Send(messageType, title, body, true);
                }
            });
        } else if (title) {
            NotificationHelper.Translate.get(title, titleParams).subscribe((head) => {
                NotificationHelper.Send(messageType, head, content, true);
            });
        } else {
            NotificationHelper.Send(messageType, title, content, true);
        }
    }

    public static Send(messageType: MessageType, head: string, body: string, addToMessages: boolean) {
        switch (messageType) {
            case MessageType.Error:
                if (!NotificationHelper.IgnoreErrorMessages) {
                    NotificationHelper.Service.error(body, head, NotificationHelper.Config);
                }
                break;
            case MessageType.Information:
                NotificationHelper.Service.info(body, head, NotificationHelper.Config);
                break;
            case MessageType.Success:
                NotificationHelper.Service.success(body, head, NotificationHelper.Config);
                break;
            case MessageType.Warning:
                NotificationHelper.Service.warning(body, head, NotificationHelper.Config);
                break;
        }
        if (addToMessages) {
            NotificationHelper.AddToMessages(messageType, head, body, new Date());
        }
    }

    private static AddToMessages(messageType, head, body, createDate) {
        const rm = new ReceivedMessage();
        rm.CreateDate = createDate;
        rm.MessageType = messageType;
        rm.Title = head;
        rm.Content = body;
        rm.Read = false;
        const newMessages = [rm];
        const oldMessages = NotificationHelper.ReceivedMessages.getValue();
        if (oldMessages) {
            newMessages.push(...oldMessages);
        }
        NotificationHelper.ReceivedMessages.next(newMessages);
    }

    public static ClearMessages() {
        NotificationHelper.ReceivedMessages.next([]);
    }
}
