import {HttpEventType} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateFormatText} from '../../../helpers/array.helpers';
import {DialogHelper, TranslateHelper} from '../../../helpers/injector.helper';
import {MessageBoxButtons} from '../../../models/enums/messageboxbuttons.enum';
import {MessageBoxIcon} from '../../../models/enums/messageboxicon.enum';
import {MessageBoxResult} from '../../../models/enums/messageboxresult.enum';
import {MediaService} from '../../../services/media.service';
import {MessageBox, MessageBoxHelper} from '../../../components/dialogs/messagebox/messagebox.dialog';
import {LayoutService} from "../../../services/layout.service";
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'system-icon-settings',
    templateUrl: './system-icon.settings.html',
    styleUrls: ['./system-icon.settings.css']
})
export class SystemIconSettings implements OnInit {
    Icons = [];
    SelectedIcon;
    HasChanges = false;
    @ViewChild('fileInput') fileInput;
    Uploading = false;
    UploadProgress = 0;
    Uploadindeterminate = false;

    openDialog = false;

    FilteredIcons = [];
    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }

    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterIcons();
    }

    // static GetSettingsEntry() {
    //     return {
    //         Caption: '@@System Icons',
    //         ID: 'system-icons',
    //         Icon: 'settings_system_daydream',
    //         Index: 2,
    //         Parent: 'ci',
    //         Security: {
    //             Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
    //             Value: 4
    //         },
    //         Content: SystemIconSettings
    //     };
    // }

    constructor(private service: MediaService, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    async refresh() {
        const check = await this.checkHasChanges();
        if (check) {
            this.SelectedIcon = null;
            this.FilteredIcons = [];
            this.Icons = [];
            this.service.GetAddonIcons().subscribe(icons => {
                if (icons) {
                    const list = [];
                    icons.forEach((icon, index) => {
                        const safeSvgContent = this.sanitizer.bypassSecurityTrustResourceUrl(icon.IconURL);
                        this.iconRegistry.addSvgIcon(`system-icon-${icon.IconName}`, safeSvgContent, {withCredentials: false});
                        list.push({
                            Name: icon.IconName,
                            Icon: icon.IconURL,
                            IsDefault: icon.IsDefault,
                            Id: icon.Id,
                        });
                    });
                    this.Icons = list;
                        this.FilterIcons();

                }
                this.cdRef.detectChanges();
            });
        }
    }

    setHasChanges() {
        this.HasChanges = true;
    }

    Upload(ev) {
        const icon = this.SelectedIcon;
        if (icon && ev && ev.target) {
            const selectedFile = ev.target.files[0];
            this.isValidSVGFile(selectedFile).then((isValid) => {
                if(isValid) {
                    const isValidExt = this.isValidExtension(selectedFile, ['.svg']);
                    if (selectedFile && isValidExt) {
                        icon.ChangedFile = selectedFile;
                        if (!icon.Name) {
                            icon.Name = selectedFile.name.replace('.svg', '');
                        }
                        const fr = new FileReader();
                        fr.addEventListener('load', function () {
                            icon.Preview = 'url(' + fr.result + ')';
                        }, false);
                        fr.readAsDataURL(selectedFile);
                        this.HasChanges = true;
                    }
                }
                else {
                    NotificationHelper.Error(`Dark mode is not available for this icon in GA Universe.`, '@@Error');
                }
            })
        }
    }

    // Function to check if SVG icon is valid/font icon
    isValidSVGFile(file: File): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                const svgContent = fr.result as string;
                const isValid = !this.hasFillAttribute(svgContent);
                resolve(isValid);
            };
            fr.onerror = () => {
                reject(new Error('Error reading file'));
            };
            fr.readAsText(file);
        });
    }

    hasFillAttribute(svgContent: string): boolean {
        const fillAttributeRegex = /fill="[^"]*"/gi;
        return fillAttributeRegex.test(svgContent);
    }

    isValidExtension(file, allowed: string[]) {
        if (file) {
            const fileName = file.name.toLowerCase();
            const extension = fileName.split('.').pop();
            const isAllowed = allowed.includes('.' + extension);
            if(!isAllowed) {
                NotificationHelper.Error(`Unsupported file type. Please upload a ${allowed} format`, '@@Error');
            }
            return isAllowed;
        }
        return true;
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Do you want to save the changes?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                return this.save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                if (this.SelectedIcon && !this.SelectedIcon.IsNew) {
                    delete this.SelectedIcon.ChangedFile;
                    delete this.SelectedIcon.Preview;
                }
                this.HasChanges = false;
            }
        }
        return true;
    }

    update() {
        const icon = this.SelectedIcon;
        const uploadData = new FormData();
        uploadData.append('IconName', icon?.Name);
        if (icon?.ChangedFile && icon?.ChangedFile?.name) {
            uploadData.append('file', icon.ChangedFile, icon.ChangedFile.name);
        }
        if (icon?.IsDefault) {
            uploadData.append('IsDefault', icon.IsDefault);
        }
        this.loading(true);
        this.service.UpdateAddonIcons(uploadData, icon.Id).subscribe(event => {
            this.onDialogCancel();
            this.loading(false);
            this.refresh();
        });
        this.HasChanges = false;
    }

    save(): boolean {
        const icon = this.SelectedIcon;
        if (icon) {
            const errorList = [];
            if (typeof icon.Name !== 'string' || icon.Name === '') {
                // errorList.push(TranslateHelper.TranslatorInstance.instant('@@Vergeben Sie einen Namen'));
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Enter a name'));
            } else if (this.Icons.some(i => i === icon.Name)) {
                // errorList.push(TranslateHelper.TranslatorInstance.instant('@@Name bereits vorhanden'));
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Name already exists'));
            }
            if (!icon.ChangedFile) {
                // errorList.push(TranslateHelper.TranslatorInstance.instant('@@Waehlen Sie eine Datei'));
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Upload an icon'));
            }
            if (errorList.length > 0) {
                // const textVal = '@@Aenderungen konnten nicht gespeichert werden. Bitte ueberpruefen Sie folgende Einstellungen:\n{0}';
                const textVal = '@@Changes could not be saved. Please check the following fields:\n{0}';
                const text = new TranslateFormatText(textVal);
                text.FormatParams.push(errorList.join('\n'));
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                return false;
            }
            this.loading(true);
            const uploadData = new FormData();
            uploadData.append('IconName', icon.Name);
            uploadData.append('file', icon.ChangedFile, icon.ChangedFile.name);
            uploadData.append('IsDefault', icon.IsDefault);
            this.service.UploadAddonIcons(uploadData).subscribe({
               next: (event) => {
                this.onDialogCancel();
                this.loading(false);
                uploadData.forEach((value) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            this.Uploading = true;
                            break;
                        case HttpEventType.UploadProgress:
                            this.UploadProgress = Math.round(100 * event.loaded / event.total);
                            if (this.UploadProgress === 100) {
                                this.Uploadindeterminate = true;
                                this.Uploading = false;
                                this.UploadProgress = 0;
                            }
                            break;
                        case HttpEventType.Response:
                            this.Uploadindeterminate = false;
                            delete icon.ChangedFile;
                            delete icon.Preview;
                            if (icon.IsNew) {
                                delete icon.IsNew;
                                this.Icons.push(icon);
                            }
                            break;
                    }
                });
                this.refresh();
               },

               error: (err) => {
                if(err && err.error && err.error.Message){
                    NotificationHelper.Error(`@@${err.error.Message}`, '@@Error');
                }
               }
            });
            this.HasChanges = false;
        }
        return true;
    }

    addItem() {
        if (this.HasChanges) {
            const config = {
                data: {
                    Data: {
                        Message: new TranslateFormatText('@@Do you want to save the changes?'),
                        Title: new TranslateFormatText('@@Frage'),
                        Buttons: MessageBoxButtons.YesNoAbort,
                        Icon: MessageBoxIcon.Question
                    }
                }
            };
            const dialogRef = DialogHelper.DialogInstance.open(MessageBox, config);
            dialogRef.afterClosed().subscribe(retVal => {
                if (retVal === MessageBoxResult.Yes) {
                    this.save();
                    this.OnAddNew();
                } else if (retVal === MessageBoxResult.Abort) {
                    return false;
                } else { // No
                    if (this.SelectedIcon && !this.SelectedIcon.IsNew) {
                        delete this.SelectedIcon.ChangedFile;
                        delete this.SelectedIcon.Preview;
                    }
                    this.HasChanges = false;
                    this.OnAddNew();
                }
            });
        } else {
            this.OnAddNew();
        }
        this.openDialog = true;
    }

    OnAddNew() {
        if (this.SelectedIcon) {
            this.SelectedIcon.Selected = false;
        }
        this.SelectedIcon = {
            id: null,
            Name: null,
            IsDefault: true,
            IsNew: true,
            Selected: true
        };
        this.HasChanges = true;
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    async deleteItem() {
        const sel = this.SelectedIcon;
        if (sel) {
            // const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Icon \'{0}\' loeschen moechten?');
            const text = new TranslateFormatText('@@Are you sure you want to delete the \'{0}\' icon?');
            text.FormatParams.push(sel.Name);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Delete'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.loading(true);
                this.service.DeleteAddonIcons(sel.Id).subscribe({
                    next: (result) => {
                        this.onDialogCancel();
                        this.loading(false);
                        this.refresh();
                        if (result) {
                            this.SelectedIcon = null;
                            this.Icons.splice(this.Icons.indexOf(sel), 1);
                            this.FilterIcons();
                            this.HasChanges = false;
                        }
                    },

                    error:(err) => {
                        if(err && err.error && err.error.Message){
                            NotificationHelper.Error(`@@${err.error.Message}`, '@@Error');
                        }
                    }
                    
                });
            }
        }
    }

    getImage() {
        const retVal = {};
        if (this.SelectedIcon && this.SelectedIcon.Preview) {
            retVal['background-image'] = this.SelectedIcon.Preview;
        }
        return retVal;
    }

    async setSelected(icon) {
        const check = await this.checkHasChanges();
        if (check) {
            this.SelectedIcon = null;
            this.Icons.forEach(i => {
                i.Selected = false;
            });
            icon.Selected = true;
            this.SelectedIcon = icon;
            this.openDialog = true;
        }
    }

    FilterIcons() {
        if (this.SelectedIcon) {
            this.SelectedIcon.Selected = false;
            this.SelectedIcon = null;
        }
        let val = this._SearchValue;
        if (val) {
            val = val.toLowerCase();
            this.FilteredIcons = this.Icons.filter(icon => {
                return icon.Name.toLowerCase().indexOf(val) >= 0;
            });
        } else {
            this.FilteredIcons = this.Icons;
        }
    }

    onDialogCancel() {
        this.openDialog = false;
        this.SelectedIcon = null;
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

}
