import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../models/enums/dialogbutton.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { WorkflowService } from '../../services/workflow.service';

@Component({
    selector: 'edit-wf-group-dialog',
    templateUrl: './edit.workflow.group.dialog.html'
})
export class EditWorkflowGroupDialog implements OnInit {
    InitArgs;
    Grouping;
    RetVal;

    static NewWorkflowGroup(isService: boolean, handler) {
        BaseDialog.ShowDialog({
            ContentType: EditWorkflowGroupDialog,
            Title: '@@Neue Gruppierung',
            InitArgs: {
                IsNew: true,
                IsService: isService
            },
            Handler: r => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            }
        });
    }

    static EditWorkflowGroup(id: string, handler) {
        BaseDialog.ShowDialog({
            ContentType: EditWorkflowGroupDialog,
            Title: '@@Gruppierung bearbeiten',
            InitArgs: {
                IsNew: false,
                ID: id
            },
            Handler: r => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            }
        });
    }

    constructor(private cdRef: ChangeDetectorRef, private service: WorkflowService) {
    }

    ngOnInit(): void {
        if (this.InitArgs) {
            if (this.InitArgs.IsNew) {
                this.Grouping = {
                    Caption: new TranslatedString(),
                    IsService: this.InitArgs.IsService
                };
            } else {
                this.service.GetWorkflowGroup(this.InitArgs.ID).subscribe(x => {
                    this.Grouping = x;
                    this.cdRef.detectChanges();
                });
            }
        }
    }

    Initialize(args) {
        this.InitArgs = args;
    }

    GetDialogResult() {
        return this.RetVal;
    }

    OnDialogButtonClickAction(button: DialogButton) {
        return new Promise<boolean>(resolve => {
            if (button == DialogButton.Ok && this.Grouping) {
                this.service.SaveWorkflowGroup(this.Grouping).subscribe(x => {
                    this.RetVal = x;
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }
}
