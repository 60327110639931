import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { DataDescriptionRelational } from '../../../models/datadescription/relational/datadescriptionrelational.model';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { DataModelService } from '../../../services/datamodel.service';
import { MessageBoxHelper } from '../messagebox/messagebox.dialog';

export class OCMPDragInfo {
    constructor(public DragType: OcDragItemType, public DragContent) {
    }
}

@Component({
    selector: 'datadescription-control',
    templateUrl: './datadescription.control.html',
    styleUrls: ['./datadescription.control.css']
})
export class DatadescriptionControl implements OnInit {

    public static ObjectCreatorMPDragStart: Subject<OCMPDragInfo> = new Subject<OCMPDragInfo>();

    showContainerSelect = false;
    InitArgsQuery;
    @Output() ContentChanged = new EventEmitter<DatadescriptionData>();
    AllDataModels: any;
    DataModelID: any;
    showPreview = false;

    @Input()
    get SelectedDataModel() {
        return this.DataValue.DataModelID;
    }
    set SelectedDataModel(val) {
        //ToDo OC Abfrage if (this.checkchangeIsRelational())
        {
            this.DataValue.DataModelID = val;
            this.DataModelID = this.DataValue.DataModelID;
            this.DataDescriptionValue.DataModelID = val;
        }
    }

    @Input()
    get IsRelational() {
        return this.DataValue.IsRelational;
    }
    set IsRelational(val) {
        //ToDo OC Abfrage if (this.checkchangeIsRelational())
        {
            this.DataValue.IsRelational = val;
            this.DataDescription = this.DataValue.Datadescription;
            // this.DatadescriptionChange.emit(val);
        }
    }

    @Input()
    get IsAllXValues() {
        if (this.DataValue.Datadescription.XLevelNodes) {
            return this.DataValue.Datadescription.XLevelNodes.ShowAll;
        }
        return false;
    }
    set IsAllXValues(val) {
        if (this.DataValue.Datadescription.XLevelNodes) {
            this.DataValue.Datadescription.XLevelNodes.ShowAll = val;
        }
    }

    @Input()
    get IsAllYValues() {
        if (this.DataValue.Datadescription.YLevelNodes) {
            return this.DataValue.Datadescription.YLevelNodes.ShowAll;
        }
        return false;
    }
    set IsAllYValues(val) {
        if (this.DataValue.Datadescription.YLevelNodes) {
            this.DataValue.Datadescription.YLevelNodes.ShowAll = val;
        }
    }

    DataDescriptionValue: any = new DataDescriptionRelational();
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(val) {

        val.DataModelID = this.Data.DataModelID;
        this.DataDescriptionValue = val;
        this.Data.Datadescription = val;
    }

    //#region Data
    DataValue: DatadescriptionData = new DatadescriptionData();
    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val) {
            this.DataValue = val;
            val.Datadescription.DataModelID = val.DataModelID;
            this.DataDescription = val.Datadescription;
            this.DataModelID = val.DataModelID;
            this.IsRelational = val.IsRelational;
            this.DataChange.emit(this.DataValue);
        }
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    constructor(private dataModelService: DataModelService) {
    }

    ngOnInit(): void {
        this.dataModelService.GetModels().subscribe(models => {
            this.AllDataModels = models;
        });
    }

    async checkchangeIsRelational() {
        const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bisherige Einstellungen gehen verloren. Wollen Sie trotzdem ändern?'),
            new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
        if (retVal === MessageBoxResult.Yes) {
            return true;
        }
        else return false;
    }

    OnShowContainerSelect(ev) {
        this.InitArgsQuery = ev;
        this.showContainerSelect = true;
    }

    CloseSelectDialogClicked() {
        this.InitArgsQuery = null;
        this.showContainerSelect = false;
    }

    Test() {
        this.showPreview = !this.showPreview;
    }

    onContentChanged() {
        this.ContentChanged.emit(this.DataValue);
    }
}
