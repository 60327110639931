import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-get-object-and-template-from-python-item',
    templateUrl: 'get.object.and.template.from.python.html'
})
export class GetObjectAndTemplateFromPythontStructureSettings extends FormulaWorkflowDialogContent {
    Data = new GetObjectAndTemplateFromPythontStructureSettingsData();
    VariableNodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getObjectAndTemplateFromPythonWFModule';
        reg.Caption = '@@Objekt/Template aus Dokumentstruktur';
        reg.GroupID = 'documentoperations';
        reg.Index = 50;
        reg.SettingsControl = GetObjectAndTemplateFromPythontStructureSettings;
        reg.SettingsTypeHelper = new GetObjectAndTemplateFromPythontStructureSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetObjectAndTemplateFromPythontStructureSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        const errors = [];
        if (!this.Data.UseForState && !this.Data.StatusKey) {
            const text = '@@Bitte vergeben Sie einen Status Key fuer das Ergebnis oder setzen Sie das Ergebnis als aktuellen Parameter';
            errors.push(TranslateHelper.TranslatorInstance.instant(text));
        }
        if (errors.length > 0) {
            retVal.IsCorrect = false;
            retVal.Error = errors.join('\n');
        }
        return retVal;
    }

    getResult() {
        return this.Data;
    }
}

export class GetObjectAndTemplateFromPythontStructureSettingsData extends AServiceWorkflowData {
    StatusKey: string;
    DocumnetStructure: string;
    DocumnetMatching: string;
    UseForState = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DocumentOperations.GetObjectAndTemplateFromPython.GetObjectAndTemplateFromPythonSettingsData';
    }
}

export class GetObjectAndTemplateFromPythontStructureSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new GetObjectAndTemplateFromPythontStructureSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}
