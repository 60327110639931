import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CollaborationHelper } from '../../helpers/collaboration.helper';
import { ILayoutChangeClient, LayoutChangeStatusHandler } from '../../helpers/layout.change.status.handler';
import { Layout } from '../../models/layout.model';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { BaseListDetail } from '../base.list.settings';

@Component({
    selector: 'pages-detail',
    templateUrl: './pages.detail.html'
})
export class PagesDetail extends BaseListDetail implements OnDestroy, ILayoutChangeClient {
    //#region Page
    @Input()
    get Page() {
        return this.SelectedItem;
    }
    set Page(val) {
        this.SelectedItem = val;
        this.PageChange.emit(val);
    }
    @Output() PageChange = new EventEmitter<boolean>();
    //#endregion

    //#region ShowEdit
    ShowEditValue = true;
    @Input()
    get ShowEdit() {
        return this.ShowEditValue;
    }
    set ShowEdit(val) {
        this.ShowEditValue = val;
        this.ShowEditChange.emit(val);
    }
    @Output() ShowEditChange = new EventEmitter<boolean>();
    //#endregion

    get LayoutElement(): any {
        if (this.SelectedItem) {
            return this.SelectedItem.Layout;
        }
        return null;
    }

    Subscriptions = [];
    LayoutEditMode = false;
    LayoutChangeHandlerConnected = false;
    constructor(private service: MetaService) {
        super();
    }

    ngOnInit(): void {
        if (this.ShowEditValue) {
            this.Subscriptions.push(LayoutService.KeyPress.subscribe((event) => {
                if (event && event.key == "F2" && !this.LayoutEditMode) {
                    this.editLayout();
                }
            }));
            this.Subscriptions.push(LayoutService.LayoutEditMode.subscribe((val) => {
                this.LayoutEditMode = val;
            }));
            this.Subscriptions.push(LayoutChangeStatusHandler.LayoutChangeHandlerConnected.subscribe(val => {
                this.LayoutChangeHandlerConnected = val;
                if (val) {
                    this.OnItemChanged();
                }
            }));
        }
    }

    ngOnDestroy() {
        this.Subscriptions.forEach(x => x.unsubscribe());
        this.unsubscribeChangeStatus();
    }

    unsubscribeChangeStatus() {
        if (this.ShowEditValue) {
            LayoutChangeStatusHandler.Unsubscribe();
            CollaborationHelper.SetActiveChannel(null);
        }
    }

    setSelectedItem(item) {
        if (this.SelectedItem) {
            delete this.SelectedItem.Layout;
        }
        this.unsubscribeChangeStatus();
        super.setSelectedItem(item);
    }

    editLayout() {
        const sel = this.SelectedItem;
        if (sel) {
            if (sel.Layout) {
                this.onEditLayout(sel);
            } else {
                this.service.ReadLayoutByKey(sel.OldURL).subscribe(l => {
                    if (l) {
                        sel.Layout = plainToClass(Layout, l);
                        this.onEditLayout(sel);
                    }
                });
            }
        }
    }

    private onEditLayout(sel) {
        LayoutService.SelectedLayout.next(sel.Layout);
        LayoutService.LayoutEditMode.next(true);
        CollaborationHelper.SetActiveChannel(sel.OldURL);
        LayoutChangeStatusHandler.CheckForInit(sel.OldURL, this);
    }

    onChange() {
        if (this.SelectedItem) {
            this.SelectedItem.URL = this.SelectedItem.URL.replace(/[^a-zA-Z0-9/]/g, '');
            this.OnItemChanged();
        }
    }

    //#region ILayoutChangeClient
    checkForChangedLayout() {
        return new Promise<void>(resolve => {
            resolve();
        });
    }

    detectChanges() {
        if (this.cdRef) {
            this.cdRef.detectChanges();
        }
    }

    refreshAndQuit() {
        this.setSelectedItem(this.SelectedItem);
    }
    //#endregion
}