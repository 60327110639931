import { BaseWorkerControl } from '../base.worker.control';
import { Component } from '@angular/core';
import { WorkflowService } from '../../../../../services/workflow.service';
import { plainToClass } from 'class-transformer';

@Component({
    selector: 'workflow-worker-control',
    templateUrl: './workflow.worker.control.html',
    styleUrls: ['./workflow.worker.control.css']
})
export class WorkflowWorkerControl extends BaseWorkerControl {
    Workflows;
    Settings: WorkflowWorkerSettings;

    constructor(private service: WorkflowService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetAllServiceWorkflows().subscribe((result) => {
            if (result) {
                this.Workflows = result;
                this.checkSettings();
            }
        });
    }

    OnTriggerSet() {
        let settings;
        if (this.TriggerValue && this.TriggerValue.ExecutionContext) {
            try {
                const json = JSON.parse(this.TriggerValue.ExecutionContext);
                if (json && json.WorkflowData) {
                    settings = plainToClass(WorkflowWorkerSettings, json.WorkflowData);
                }
            } catch { }
        }
        if (!settings) {
            settings = new WorkflowWorkerSettings();
        }
        this.Settings = settings;
        this.checkSettings();
        if (this.TriggerValue) {
            this.TriggerValue.ExecutionContextAsObject = settings;
        }
    }

    checkSettings() {
        if (this.Workflows && this.Settings) {
            const newList = [];
            if (this.Settings.WorkflowID) {
                const wf = this.Workflows.find(x => x.Id === this.Settings.WorkflowID);
                if (wf) {
                    if (this.Settings.ParamMapping && this.Settings.ParamMapping.length > 0) {
                        wf.Parameters.forEach(x => {
                            let oldEntry = this.Settings.ParamMapping.find(y => y.Name === x.Name);
                            if (!oldEntry) {
                                oldEntry = {
                                    Name: x.Name,
                                };
                            }
                            oldEntry.Type = x.Type;
                            oldEntry.Required = x.Required;
                            newList.push(oldEntry);
                        });
                    } else {
                        wf.Parameters.forEach(x => {
                            newList.push({
                                Name: x.Name,
                                Type: x.Type,
                                Required: x.Required
                            });
                        });
                    }
                }
            }
            this.Settings.ParamMapping = newList;
        }
    }

    workflowChanged() {
        this.checkSettings();
        this.OnTriggerHasChanged();
    }
}

export class WorkflowWorkerSettings {
    WorkflowID: string;
    ParamMapping = [];

    toJSON() {
        const retVal = {
            WorkflowData: {
                IsServiceWorkflow: true,
                WorkflowID: this.WorkflowID,
                ParamMapping: []
            }
        };
        if (this.ParamMapping) {
            this.ParamMapping.forEach(x => {
                if (typeof x.Value !== 'undefined' && x.Value !== null) {
                    retVal.WorkflowData.ParamMapping.push({
                        Name: x.Name,
                        SetValue: true,
                        Value: x.Value
                    });
                }
            });
        }
        return retVal;
    }
}
