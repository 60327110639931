import { Injectable } from "@angular/core";
import { RequestBase } from "./request-base";
import { Observable } from "rxjs";

@Injectable()
export class ExportService extends RequestBase {
    BasePath = "api/selfservice/export";

    GetSupportsStatus(containerId): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetSupportsStatus?containerId=' + containerId, this.options);
    }

    GetSupportStatusByPreview(dataSourceId, structInfo): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetSupportStatusByPreview?dataSourceId=' + dataSourceId, structInfo, this.options);
    }
}