import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class LanguageEndpoints extends OfflineEndpointCollection {
    BasePath = 'config/api/config/';
    EndPoints = [
        //#region GetSelectedLanguages
        {
            Name: 'GetSelectedLanguages',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.ServiceBase.Config.LanguageSettings'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
    ]
}