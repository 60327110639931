import { LayoutUnit } from './layoutunit.model';

export class Column {
  Caption: string;
  Data: string;
  Name: string;
  IsSortable: boolean = false;
  IsFilterable: boolean = false;
  IsCommaSeparated: boolean = false;
  OrderState:string = 'NONE';
  Type: string;
  Type2: string;
  Type3: string;
  Width: LayoutUnit;
  SearchValue: string = null;
  IsVisible: boolean = true;
  IsSticky: boolean = false;
  Prefix: string = null;
  Suffix: string = null;
  NumericPrecision: number = 0;
  DateFormat: string;
  Expandable: boolean = false;
  Children: Column[] = [];
  FixedChildren: Column[] = [];
}
