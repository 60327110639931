import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { StyleMerger } from '../../styling/styleMerger';
import { ITaskExecuter } from '../atask.model';
import { ConditionFormattingStyle } from '../conditional.model';

export abstract class AConditionalFormatingTask implements ITaskExecuter {
    ConditionFormatingStyles: ConditionFormattingStyle[] = [];
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;

    abstract Execute();

    abstract InitConditionalTaskSettings(settings);

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings && settings.ConditionFormatingStyles) {
            this.ConditionFormatingStyles = settings.ConditionFormatingStyles;
            this.ConditionFormatingStyles.forEach(cfs => {
                if (cfs.Style) {
                    delete cfs.Style['MergeID'];
                }
            });
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
        this.InitConditionalTaskSettings(settings);
    }
}
