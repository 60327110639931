import { RequestBase } from './request-base';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SchedulerService extends RequestBase {
    BasePath = 'api/scheduler';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetTriggerTypes(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTriggerTypes', this.options);
    }

    GetWorkerTypes(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkerTypes', this.options);
    }

    GetActiveTriggerStates(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetActiveTriggerStates', this.options);
    }

    GetTriggerLogs(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetTriggerLogs', filter, this.options);
    }

    LoadTrigger(sid: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadTrigger?sid=' + sid, this.options);
    }

    SaveTrigger(serStruct): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveTrigger', serStruct, this.options);
    }

    DeleteTrigger(triggerId): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteTrigger?triggerId=' + triggerId, this.options);
    }

    CancelTrigger(runID): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/CancelTrigger?runID=' + runID, this.options);
    }

    GetTriggerDetails(runID, channel): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/GetTriggerDetails?runID=' + runID + '&channel=' + channel;
        return this.http.get<any>(path, this.options);
    }

    GetTriggerStateInfos(allUsers: boolean) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTriggerStateInfos?allUsers=' + allUsers, this.options);
    }
}

export const TRIGGER_DICT = new Map<string, any>([
]);

export const WORKER_DICT = new Map<string, any>([
]);
