import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { ITaskExecuter } from '../atask.model';

export class MeasureHeaderTaskExecuter implements ITaskExecuter {
    static TaskID = 'measureheaderformattingtask';

    MeasureUniqueID = -1;
    Style: CellStyle;
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;


    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
    }

    Execute() {
        const measureID = this.MeasureUniqueID;
        const dataDescription = this.DataDescription;
        const result = this.Result;
        if (measureID > -1 && dataDescription && result) {
            const mNode = result.Measures.Nodes.find(x => x.UniqueID === measureID);
            if (mNode) {
                mNode.StyleID = this.Merger.MergeStyle(mNode.StyleID, this.Style);
            }
        }
    }
}
