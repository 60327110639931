import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DiffEditorModel } from 'ngx-monaco-editor';
import { ThemeHelper } from '../../../helpers/theme.helpers';
import { DialogButton } from "../../../models/enums/dialogbutton.enum";
import { BaseDialog } from "../basedialog/base.dialog";

@Component({
    selector: 'monaco-preview-dialog',
    templateUrl: './monaco.preview.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonacoPreviewDialog {
    Document: DiffEditorModel  = null;
    Original: DiffEditorModel  = null;
    NoResult = false;

    editorOptions = {
        theme: 'vs-dark',
        readOnly: true
    };

    constructor() {
    }
    Initialize(args) {
        if (args) {
            if (args.Document && args.Original) {
                this.Document = {
                    code: args.Document,
                    language: 'json'
                };
                this.Original = {
                    code: args.Original,
                    language: 'json'
                };
                this.NoResult = false;
            } else {
                if (args.Document) {
                    this.Document = args.Document;
                } else {
                    this.NoResult = true;
                }
            }
        }

        if (ThemeHelper.AcitveTheme) {
            if (ThemeHelper.AcitveTheme.IsDarkTheme) {
                this.editorOptions.theme = 'vs-dark';
            } else {
                delete this.editorOptions.theme;
            }
        }
    }

    static ShowDialog(initArgs, title) {
        BaseDialog.ShowDialog({
            ContentType: MonacoPreviewDialog,
            InitArgs: initArgs,
            Title: title,
            Buttons: DialogButton.Ok,
            HeightText: '100vh',
            WidthText: '100vw'
        });
    }
}