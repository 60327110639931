import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WIDGETREGISTRY } from '../../../services/dynamic.component.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'widget-menu-tab',
    templateUrl: './widget.menu.tab.html',
    styleUrls: ['./widget.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetMenuTab extends APropertyTab {
    WidgetMenu;

    onItemSet() {
        if (this.SelectedItem.NativeComponent && WIDGETREGISTRY[this.SelectedItem.NativeComponent] && WIDGETREGISTRY[this.SelectedItem.NativeComponent].MenuTab) {
            this.WidgetMenu = new ComponentPortal(WIDGETREGISTRY[this.SelectedItem.NativeComponent].MenuTab);
        }
    }
}
