import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../models/enums/order.enum';
import { RequestSort } from '../models/rest/requestsort';

@Pipe({
    name: 'datatablesort',
    pure: false
})
export class DataTableSortPipe implements PipeTransform {
  transform(items: any[], sort: RequestSort[]): any {
    if (!items || !sort) {
      return items;
    } else if (items && sort && sort.length == 0) {
      return items.sort((a, b) => {
          return a.index > b.index ? 1 : -1;
      })
    } else {
      let column = sort[0];
      return items.sort((a, b) => {
        if (a.data[column.Name] == b.data[column.Name]) {
          return 0;
        }
        if (column.Order == Order.ASC) {
          return a.data[column.Name] > b.data[column.Name] ? 1 : -1;
        }
        if (column.Order == Order.DESC) {
          return a.data[column.Name] < b.data[column.Name] ? 1 : -1;
        }
      });
    }
  }
}
