import { UUID } from 'angular2-uuid';
import { Type } from 'class-transformer';
import { Cube, Dimension, Hierarchy, Level, Measure } from '../../../cache/multi/models/database.model';
import { FormulaNodeInformation } from '../../basic/formulaEditor.model';
import { Aggregation } from '../../enums';
import { AxisType, MeasureAccumulation } from '../../enums/query.enum';
import { MultiResult } from './multiresult.model';

export class OffsetDef {
    Member: string;
    Level: Level;
    Hierarchy: Hierarchy;
    Counter = 0;

    toJSON() {
        let result;
        result = Object.assign({}, this);
        if (this.Hierarchy) {
            result.Hierarchy = this.Hierarchy.ID;
        }
        if (this.Level) {
            result.Level = this.Level.ID;
        }
        return result;
    }
}

export class LevelDef {
    Dimension: Dimension;
    Hierarchy: Hierarchy;
    Level: Level;
    UniqueID = -1;
    Attributes: string[];
    Depth = 0;
    TupleIndex = 0;

    toJSON() {
        let result;
        result = Object.assign({}, this);
        result.Dimension = this.Dimension.ID;
        result.Hierarchy = this.Hierarchy.ID;
        result.Level = this.Level.ID;
        return result;
    }
}
// @dynamic
export class AxisDef {
    AreaID: number;
    Type: AxisType;
    ShowAll = false;
    TopCount = -1;
    @Type(() => LevelDef)
    Levels: LevelDef[] = [];
    HasMeasures = false;
}

export class WhereSelects {
    LevelId: UUID;
    MemberIds: string[];
    Start;
    End;
}

export class HeterogenSettings {
}

// @dynamic
export class WhereOptions {
    IsOptional = false;
    @Type(() => HeterogenSettings)
    Heterogen: HeterogenSettings;
}

// @dynamic
export class WhereDef {
    DimensionId: UUID;
    HierarchyId: UUID;
    Formula: string;
    @Type(() => WhereOptions)
    Options: WhereOptions;
    @Type(() => WhereSelects)
    FixedMemberIds: WhereSelects[];
    Defaults: WhereSelects[];
}

// @dynamic
export class MeasureDef {
    QueryAttribute = false;
    Attribute: string;
    Key: string;
    Caption: string;
    Measure: Measure;
    AttributeHierarchy: Hierarchy;
    UniqueID = -1;
    ASFunctionType: Aggregation;
    @Type(() => OffsetDef)
    TimeRangeReference: OffsetDef;
    FunctionAccumulation: MeasureAccumulation;
    @Type(() => OffsetDef)
    FunctionParentReferencing: OffsetDef;
    @Type(() => OffsetDef)
    FunctionOffset: OffsetDef;
    @Type(() => WhereDef)
    ReferenceWhere: WhereDef[] = [];

    toJSON() {
        let result;
        result = Object.assign({}, this);
        result.Measure = this.Measure.ID;
        if (result.AttributeHierarchy) {
            result.AttributeHierarchy = this.AttributeHierarchy.ID;
        }
        return result;
    }
}

// @dynamic
export class DynamicElementDef {
    Parent: string;
    Level: Level;
    Hierarchy: Hierarchy;
    Description: string;
    Code: string;
    @Type(() => FormulaNodeInformation)
    Formula: FormulaNodeInformation;
    SolveOrder = -1;

    toJSON() {
        let result;
        result = Object.assign({}, this);
        if (this.Hierarchy) {
            result.Hierarchy = this.Hierarchy.ID;
        }
        if (this.Level) {
            result.Level = this.Level.ID;
        }
        return result;
    }
}
// @dynamic
export class QueryDef {
    ID: UUID;
    Cube: Cube;
    DatasourceID: UUID;
    AutomatedSolveOrder = false;
    EffectiveUserName: string;
    @Type(() => MeasureDef)
    Measures: MeasureDef[] = [];
    @Type(() => AxisDef)
    Axis: AxisDef[] = [];
    @Type(() => DynamicElementDef)
    DynamicElements: DynamicElementDef[] = [];
    DataModell: UUID;
    Variables = {};
    Where: WhereDef[] = [];
    HeterogenPosition: Map<AxisType, number> = new Map<AxisType, number>();
    MeasureOnAxis: AxisType;
    MultiResult: MultiResult;
    Index: number;

    toJSON() {
        let result;
        result = Object.assign({}, this);
        result.DatasourceID = this.Cube.Dimensions[0].DatasourceID;
        result.Cube = this.Cube.ID;
        if (this.Variables) {
            result.Variables = JSON.stringify(this.Variables);
        } else {
            result.Variables = '';
        }
        delete result.HeterogenPosition;
        delete result.MeasureOnAxis;
        delete result.MultiResult;
        delete result.Index;
        return result;
    }
}
