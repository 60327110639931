import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { SettingsService } from "../../../../../services/settings.service";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'googledrive-panel',
    templateUrl: './googledrive.panel.html',
    styleUrls: ['./googledrive.panel.css']
})
export class GoogleDrivePanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = GoogleDrivePanel;
    }

    get RedirectUrl(): string {
        var ret = SettingsService.API_BASE_URL.getValue() + 'config/api/media/oauth/authorizegoogledrive';
        this.MediaSource.RedirectUrl = ret;
        this.MediaSource.Private = true; 
        return ret;
    }

    set RedirectUrl(val) {
        if (val != this.MediaSource.RedirectUrl) {
            this.MediaSource.RedirectUrl = val;
        }
    }

    authenticate(): void {
        var authstring = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/drive&response_type=code&state=' + this.MediaSource.SID + '&redirect_uri=' + this.MediaSource.RedirectUrl + '&client_id=' + this.MediaSource.ClientId + '&access_type=offline&prompt=consent';
        window.open(authstring, '_blank');
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: GoogleDrivePanel,
            MediaType: '6E6D02BD-D96C-4F42-99D4-822F74D7F5E8'.toLowerCase()
        };
    }
}