import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { deserialize, plainToClass, serialize } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { EnumHelper } from '../../helpers/enum.helper';
import { TranslateHelper } from '../../helpers/injector.helper';
import { WorkflowErrorType } from '../../models/enums/workflowerrortype.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { Registry } from '../../script/modules/registry.script';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { WorkflowService } from '../../services/workflow.service';
import { ServiceWorkflowUsageDialog } from '../../workflow/modules/service.workflow.usage.dialog';
import { WorkflowEditOptions } from '../../workflow/workflow.control';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'workflow-settings',
    templateUrl: '../base.list.settings.html'
})
export class WorkflowSettings extends BaseListSettings {
    EditOptions: WorkflowEditOptions;

    constructor(protected fs: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: WorkflowService, lib: ScriptLibraryService) {
        super(fs, cdRef);
        this.ShowFilter = true;
        this.ShowCopy = true;
        this.initWF();
        Registry.initializeRegistry(lib);
    }

    getContentType() {
        return WorkflowDetail;
    }

    initWF() {
        this.EditOptions = new WorkflowEditOptions();
        this.EditOptions.IsService = true;
    }

    protected ComponentSet() {
        super.ComponentSet();
        this.Component.EditOptions = this.EditOptions;
        if (this.EditOptions.IsService) {
            this.CopyType = "ServiceWorkflowCopy";
        } else {
            this.CopyType = "ClientLibraryWorkflowCopy";
        }
    }

    loadList(handler) {
        const getFunc = this.EditOptions.IsService ? this.service.GetAllServiceWorkflows() : this.service.GetAllWorkflowTemplates();
        getFunc.subscribe((result) => {
            if (result) {
                const list = [];
                result.forEach(item => {
                    list.push({
                        Caption: item.Name,
                        ID: item.Id,
                        IsCapsule: item.IsCapsule,
                        IsOverridden: item.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.service.GetWorkflow(data).subscribe(result => {
            if (result) {
                const wf = plainToClass(WorkflowSaveObject, result);
                this.setSelectedItem(wf);
            }
        });
    }

    getNewItem() {
        this.setSelectedItem(null);
        const workflow = new WorkflowSaveObject();
        workflow.Caption = new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer Workflow'));
        return workflow;
    }

    getCopyItem(selected): Promise<any> {
        return new Promise(resolve => {
            if (selected) {
                const json = JSON.stringify(selected);
                const copy = deserialize(WorkflowSaveObject, json);
                resolve(copy);
            } else {
                resolve(null);
            }
        });
    }

    getDuplicateItem(selected): Promise<any> {
        return new Promise(resolve => {
            if (selected) {
                const json = serialize(selected);
                const copy = deserialize(WorkflowSaveObject, json);
                delete copy['SID'];
                delete copy['Version'];
                delete copy['ObjectStatus'];
                if (copy.Caption) {
                    copy.Caption.DefaultValue += ' - (Copy)';
                    if (copy.Caption.SerializedTranslations) {
                        copy.Caption.SerializedTranslations.forEach(x => {
                            x.Value += ' - (Copy)';
                        });
                    }
                }
                setTimeout(() => {
                    resolve(copy);
                }, 100);
            } else {
                resolve(null);
            }
        });
    }

    getPasteItem(): Promise<any> {
        return new Promise(resolve => {
            const json = serialize(ClipboardHelper.ClipboardContent.content);
            const copy = deserialize(WorkflowSaveObject, json);
            if (copy['SID']) {
                this.service.GetWorkflow(copy['SID']).subscribe(result => {
                    if (!result) {
                        resolve(copy);
                    } else {
                        delete copy['SID'];
                        delete copy['Version'];
                        delete copy['ObjectStatus'];
                        if (copy.Caption) {
                            copy.Caption.DefaultValue += ' - (Copy)';
                            if (copy.Caption.SerializedTranslations) {
                                copy.Caption.SerializedTranslations.forEach(x => {
                                    x.Value += ' - (Copy)';
                                });
                            }
                        }
                        setTimeout(() => {
                            resolve(copy);
                        }, 100);
                    }
                });
            } else {
                resolve(copy);
            }
        });
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Workflow \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Workflow \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Workflow loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const caption = TranslatedString.GetTranslation(sel.Caption);
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Workflow \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Workflow speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteWorkflow(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        const saveFunc = this.EditOptions.IsService ? this.service.SaveWorkflow(item) : this.service.SaveWorkflowTemplate(item);
        saveFunc.subscribe(result => {
            if (result) {
                handler(result, result.Id, result.Name);
            }
        });
    }

    handleNew(item, result) {
        if (!item.SID) {
            item.SID = result.Id;
        }
        if (!item.Version) {
            item.Version = result.Version;
        }
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'workflow-template-settings',
    templateUrl: '../base.list.settings.html'
})
export class WorkflowTemplateSettings extends WorkflowSettings {

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Workflow',
    //        ID: 'workflow',
    //        Icon: 'device_hub',
    //        Index: 1,
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.DataRight',
    //            Value: 16
    //        },
    //        Parent: 'data',
    //        Children: [
    //            {
    //                Caption: '@@Client',
    //                ID: 'wf-templates',
    //                Icon: 'paste',
    //                Index: 0,
    //                Parent: 'workflow',
    //                Security: {
    //                    Name: 'evidanza.App.Shared.Security.DataRight',
    //                    Value: 32
    //                },
    //                Content: WorkflowTemplateSettings
    //            },
    //            {
    //                Caption: '@@Service',
    //                ID: 'wf-service',
    //                Icon: 'settings_system',
    //                Index: 1,
    //                Parent: 'workflow',
    //                Security: {
    //                    Name: 'evidanza.App.Shared.Security.DataRight',
    //                    Value: 64
    //                },
    //                Content: WorkflowSettings
    //            }
    //        ]
    //    };
    //}

    initWF() {
        this.EditOptions = new WorkflowEditOptions();
        this.EditOptions.IsTemplate = true;
    }
}

@Component({
    selector: 'workflow-detail',
    templateUrl: './workflow.settings.html',
    styleUrls: ['./workflow.settings.css']
})
export class WorkflowDetail extends BaseListDetail {
    EditOptions;
    Caption;
    ErrorTypes = [];

    constructor() {
        super();
        this.ErrorTypes = EnumHelper.GetDropdownValues(WorkflowErrorType);
    }
    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.updateCaption();
    }

    updateCaption() {
        let caption = TranslateHelper.TranslatorInstance.instant('@@Details');
        if (this.SelectedItem) {
            caption += ' - ' + TranslatedString.GetTranslation(this.SelectedItem.Caption);
        }
        this.Caption = caption;
    }

    CaptionChanged(caption) {
        if (this.SelectedItem) {
            this.SelectedItem.Caption = caption;
            this.updateCaption();
            this.OnItemChanged();
        }
    }

    DescriptionChanged(description) {
        if (this.SelectedItem) {
            this.SelectedItem.Description = description;
            this.OnItemChanged();
        }
    }

    ShowUsage() {
        if (this.SelectedItem) {
            ServiceWorkflowUsageDialog.ShowDialog(this.SelectedItem.SID);
        }
    }

    OnContentChanged() {
        if (this.SelectedItem) {
            this.OnItemChanged();
        }
    }
}
