import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { GitHelper } from '../../../../helpers/git.helper';
import { DialogButton } from '../../../../models/enums/dialogbutton.enum';
import { TranslatedString } from '../../../../models/translatedstring.model';
import { GitService } from '../../../../services/git.service';
import { LayoutService } from '../../../../services/layout.service';
import { IBaseComponent } from '../../../controls/base.component';
import { BaseDialog } from '../../../dialogs/basedialog/base.dialog';
import { RequestNameDialog } from '../../../dialogs/reqeustname/reqeustname.dialog';

@Component({
    selector: 'evi-repository-select',
    templateUrl: 'repositoryselect.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepositorySelectControl extends IBaseComponent {
    

    static Type: any = 'repositoryselect';
    static Default = {
        Editable: true,
        Type: 'repositoryselect'
    };

    GitActive = false;
    Repositories = [];
    SelectedRepository;

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private service: GitService) {
        super(cdRef, data);
    }

    ngOnInit() {
        super.onInit();
        this.GitActive = GitHelper.IsActive;
        this.Refresh();
    }
    
    ControlInitialized() {
        
    }
    Refresh() {
        if (this.GitActive) {
            this.SelectedRepository = GitHelper.SelectedRepository.getValue();
            this.Repositories = [{ Name: "Create new repository" }, ...GitHelper.Repositories];
            this.cdRef.detectChanges();
            this.Subscriptions['BranchesChanged'] = GitHelper.BranchesChanged.subscribe((branches) => {
                this.Repositories = GitHelper.LocalBranches;
                this.cdRef.detectChanges();
            });

        }
    }
    SelectedItemChanged() {
        if (this.SelectedRepository == "Create new repository") {
            BaseDialog.ShowDialog({
                ContentType: RequestNameDialog,
                InitArgs: { Text: '', Placeholder: 'Name' },
                Title: '@@Name waehlen',
                Buttons: DialogButton.None,
                Handler: (result) => {
                    if (result) {
                        let item = {
                            Caption: new TranslatedString(result),
                            Name: result
                        }
                        this.service.CreateRepository(item).subscribe((result) => {
                            this.Refresh();
                            return true;
                        });
                    } else {
                        return true;
                    }
                }
            });
        } else {
            GitHelper.SelectedRepository.next(this.SelectedRepository);
            window.location.reload();
        }
    }
    DeleteRepository(name) {
        this.service.RemoveRepository(name).subscribe(() => {
            this.Refresh();
        });
    }
}
