import { serialize, deserialize } from 'class-transformer';
import { Component } from '@angular/core';

@Component({
    selector: 'mapping-values-detail',
    templateUrl: './mapping.rest.values.dialog.html'
})
export class MappingRestValuesDialog {
    AttributeValues = [];
    Columns = [];
    SelectedField = {};

    constructor() {
    }

    Initialize(args) {
        if (args) {
            if (args.Columns) {
                this.Columns = args.Columns;
            }
            if (args.Field) {
                this.SelectedField = args.Field;
            }
            this.AttributeValues = args.AttributeValues;
        }
    }

    GetDialogResult() {       
        return this.SelectedField;
    }

    onValueChanged() {
    }
}
