import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogHelper } from '../../../helpers/injector.helper';
import { WorkflowWizardService } from '../../../services/workflow.wizard.service';

@Component({
    selector: 'show-wizard-wf-dialog',
    templateUrl: './show.wizard.dialog.html',
    styles: ['.dialogContent { display: flex; flex-direction: column; flex: 1 1 auto; overflow: hidden; }']
})
export class ShowWizardDialog implements OnInit {
    WizardID;
    WizardObject;
    Title = '';

    public static async ShowAsync(title, wizardID) {
        const config = new MatDialogConfig();
        config.data = {
            Title: title,
            WizardID: wizardID
        };
        config.autoFocus = false;
        const diaRef = DialogHelper.DialogInstance.open(ShowWizardDialog, config);
        await diaRef.afterClosed().toPromise();
    }

    constructor(private dialogRef: MatDialogRef<ShowWizardDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
        private service: WorkflowWizardService) {
        dialogRef.disableClose = true;
        if (data) {
            this.Title = data.Title;
            this.WizardID = data.WizardID;
        }
    }

    ngOnInit(): void {
        if (this.WizardID) {
            this.service.GetWizardObject(this.WizardID).subscribe(result => {
                if (result) {
                    this.WizardObject = result;
                }
            });
        }
    }

    okClicked() {
        this.dialogRef.close();
    }

    cancelClicked() {
        this.dialogRef.close();
    }
}
