import { UUID } from 'angular2-uuid';
import { plainToClass, Type } from 'class-transformer';
import { Duration } from 'moment';
import { Subject } from 'rxjs';
import { Base, JsonIgnore } from '../../base.model';
import { FormulaNodeInformation } from '../../basic/formulaEditor.model';
import { AxisType } from '../../enums/query.enum';
import { TranslatedString } from '../../translatedstring.model';
import { FilterMultidimensional } from './filter/filterMultidimensional.model';
import { LevelNode } from './levelnode.model';
import { MeasureInfo } from './measureinfo.model';
import { QuerySettings } from './querysettings.model';

// @dynamic
export class Tuple {
    @Type(() => LevelNode)
    Levels: LevelNode[] = [];
    HideRepeatedNames = false;
}

// @dynamic
export class DynamicElement {
    Active = true;
    /// <summary>
    /// Element, unter welches die berechnete Zeile gehängt werden soll (IMember)
    /// </summary>
    Parent: string;
    Level: UUID;
    Description: string;
    Code: string;

    @Type(() => FormulaNodeInformation)
    Formula: FormulaNodeInformation;
}

// @dynamic
export class HeterogenArea extends Base {

    @Type(() => TranslatedString)
    Caption: TranslatedString;
    @Type(() => Tuple)
    Tuples: Tuple[] = [];
    @Type(() => MeasureInfo)
    Measures: MeasureInfo[] = [];
    UniqueID = -1;
    EmptyElementCount = 0;

    get LevelCount(): number {
        let count = 0;
        this.Tuples.forEach(t => {
            count += t.Levels.length;
        });
        return count;
    }
}

// @dynamic
export class Axis {
    @Type(() => HeterogenArea)
    Areas: HeterogenArea[] = [];
    ShowAll = false;
    AxisType: AxisType;

    public constructor(init?: Partial<Axis>) {
        Object.assign(this, init);
    }

    get HasAreas(): boolean {
        return this.Areas && this.Areas.some(area => {
            if (area.Measures && area.Measures.length > 0) {
                return true;
            }
            if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                return true;
            }
            return false;
        });
    }
}

// @dynamic
export class DataDescription extends Base {

    /// <summary>
    /// Die Liste der Data Tasks
    /// </summary>
    Tasks: any[] = [];

    SelectedCubes: any[];

    Filter: FilterMultidimensional[] = [];

    /// <summary>
    /// Die Datenbank aus der die Daten geliefert werden sollen
    /// </summary>
    DataModelID: UUID;

    @Type(() => Axis)
    XLevelNodes: Axis = new Axis({ AxisType: AxisType.X_Axis });

    @Type(() => Axis)
    YLevelNodes: Axis = new Axis({ AxisType: AxisType.Y_Axis });


    @Type(() => QuerySettings)
    AdvancedSettings: QuerySettings = new QuerySettings();

    /// <summary>
    /// Die Dynamischen Zeilen Elemente
    /// </summary>
    @Type(() => DynamicElement)
    DynamicRowElements: DynamicElement[] = [];

    // ToDo später
    Timeout: Duration;

    /// <summary>
    /// bei SlideObjectType Analyse_Table wird hier aufgezeigt ob die Analysetabelle als PowerGrid genutzt werden soll
    /// </summary>
    PowerGrid = false;

    @JsonIgnore
    Changed: Subject<any> = new Subject<any>();
    @JsonIgnore
    PowerGridDepth = 0;
    @JsonIgnore
    EffectiveUserName: string; // Analysisservername
    @JsonIgnore
    TopCount = -1;

    get LevelNodes(): Axis[] {
        return [this.XLevelNodes, this.YLevelNodes];
    }

    get ShowMeasureOnAxis(): AxisType {
        let axisType = AxisType.None;
        if (this.XLevelNodes.Areas.some(area => area.Measures && area.Measures.length > 0)) {
            axisType = this.XLevelNodes.AxisType;
        }
        if (axisType === AxisType.None && this.YLevelNodes.Areas.some(area => area.Measures && area.Measures.length > 0)) {
            axisType = this.YLevelNodes.AxisType;
        }
        return axisType === AxisType.None ? AxisType.X_Axis : axisType;
    }

    FireChanged() {
        this.Changed.next(null);
    }

    get MeasureInfos(): MeasureInfo[] {
        const measureinfos = [];
        this.XLevelNodes.Areas.forEach(a => {
            measureinfos.push(...a.Measures);
        });
        if (measureinfos.length === 0) {
            this.YLevelNodes.Areas.forEach(a => {
                measureinfos.push(...a.Measures);
            });
        }
        return measureinfos;
    }

    get IsEmpty(): boolean {
        return !this.XLevelNodes.HasAreas && !this.YLevelNodes.HasAreas;
    }

    clone(): DataDescription {
        let retVal;
        retVal = plainToClass(DataDescription, JSON.parse(JSON.stringify(this)));
        return retVal;
    }
}
