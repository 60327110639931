import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutService } from '../../../services/layout.service';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'marker',
    templateUrl: './marker.control.html',
    styleUrls: ['./marker.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerControl extends IBaseComponent {

    static Type: any = 'marker';
    static Default = { Type: 'marker',Layout: {
        _Editable: true,
        Width: { Type: 0, Value:50},
        Height: {Type:0,Value:300}
    } };

    markers: MarkerItem[] = [];

    @Input()
    get MarkerPositions(): MarkerPosition[] {
        const list = [];
        this.markers.forEach(x => {
            list.push(x.Position);
        });
        return list;
    }
    set MarkerPositions(val: MarkerPosition[]) {
        const list = [];
        if (val) {
            val.forEach(x => {
                list.push(new MarkerItem(x));
            });
        }
        this.markers = list;
    }

    get SelectedMarkerID(): any {
        const selected = this.markers.find(x => x.Selected);
        if (selected) {
            return selected.Position.ID;
        }
        return null;
    }
    set SelectedMarkerID(val: any) {
        this.markers.forEach(x => x.Selected = false);
        const selected = this.markers.find(x => x.Position.ID == val);
        if (selected) {
            selected.Selected = true;
        }
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('OnMarkCompleted');
        this.EventList.push('OnMarkAdded');
        this.EventList.push('OnMarkSelected');
        this.EventList.push('OnMarkDeleted');
        this.EventList.push('OnMarkModified');
        this.PropertyList.push(new ElementProperty('MarkerPositions', 'object', '@@Markierungen'));
        this.PropertyList.push(new ElementProperty('SelectedMarkerID', 'object', '@@Auswahl'));
    }

    ControlInitialized() {
    }

    onDragOffsetEnd(ev, m) {
        if (ev && m && m.Position) {
            m.Position.X += ev.x;
            m.Position.Y += ev.y;
            const index = this.markers.indexOf(m);
            if (index > -1) {
                this.markers.splice(index, 1);
                window.setTimeout(() => {
                    this.markers.push(m);
                    this.cdRef.detectChanges();
                }, 50);
            }
            this.triggerEvent('OnMarkModified', m.Position);
        }
    }

    onResize(ev, m) {
        if (ev && m && m.Position) {
            if (ev.direction.n) {
                m.Position.Y += m.Position.Height - ev.size.height;
            }
            if (ev.direction.w) {
                m.Position.X += m.Position.Width - ev.size.width;
            }
            m.Position.Height = ev.size.height;
            m.Position.Width = ev.size.width;
            this.triggerEvent('OnMarkModified', m.Position);
        }
    }

    onMarkerClick(ev, m) {
        if (ev && m && this.Editable && !this.Disabled) {
            if (!m.Selected) {
                this.markers.forEach(x => x.Selected = false);
                m.Selected = true;
                this.triggerEvent('OnMarkSelected', m.Position);
            }
            ev.stopPropagation();
        }
    }

    addmarker() {
        const newMarker = new MarkerPosition();
        newMarker.X = 100;
        newMarker.Y = 100;
        newMarker.Width = 100;
        newMarker.Height = 100;
        newMarker.ID = UUID.UUID();
        this.markers.push(new MarkerItem(newMarker));
        this.triggerEvent('OnMarkAdded', newMarker);
    }

    deletemarker() {
        const sel = this.markers.find(x => x.Selected);
        if (sel) {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Markierung wirklich loeschen?'),
                new TranslateFormatText('@@Markierung loeschen'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then((res) => {
                    if (res === MessageBoxResult.Yes) {
                        const deleteMarkIndex = this.markers.indexOf(sel);
                        if (deleteMarkIndex > -1) {
                            this.markers.splice(deleteMarkIndex, 1);
                            this.triggerEvent('OnMarkDeleted', sel.Position.ID);
                            this.cdRef.detectChanges();
                        }
                    }
                });
        }
    }

    confirmmarker() {
        this.triggerEvent('OnMarkCompleted', this.MarkerPositions);
    }

    onControlClick() {
        this.markers.forEach(x => x.Selected = false);
        this.triggerEvent('OnMarkSelected', null);
    }
}

export class MarkerPosition {
    X = 0.0;
    Y = 0.0;
    Height = 0.0;
    Width = 0.0;
    ID: any;
}

export class MarkerItem {
    Position: MarkerPosition;
    Selected = false;

    constructor(pos: MarkerPosition) {
        this.Position = pos;
    }
}
