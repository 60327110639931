import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { ChangeKeyDialog } from '../../../components/dialogs/changekeydialog/change.key.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { ArrayHelpers, TranslateFormatText } from '../../../helpers/array.helpers';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { NavigationHelper } from '../../../helpers/navigation.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { Layout } from '../../../models/layout.model';
import { GeneralSettingsService } from '../../../services/generalsettings.service';
import { JiraService } from '../../../services/jira.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { StandardRequestBase } from '../../../services/request-base';
import { SideNavService } from '../../../services/sidenav.service';
import { UsersService } from '../../../services/users.service';
import { WorkflowService } from '../../../services/workflow.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { VersioningOverview } from '../../versioningoverview/versioning.overview';
import { NavigationOverwiewSettings } from './navigation.overview.settings';

@Component({
    selector: 'navigation-overview-version-settings',
    templateUrl: './navigation.overview.version.settings.html',
    styleUrls:['./navigation.overview.version.settings.css']
})
export class NavigationOverviewVersionSettings implements OnInit, OnDestroy {
    ListItems = [];
    FilteredItems = [];
    FilteredPages = [];
    selectedNode;
    HasChanges = false;
    SearchValue: string;
    SearchPages:string;
    SelectedItem;
    EditMode = false;
    ActiveUser;
    UserSub;

    Pages = [];
    Subscription;
    TempLayout;
    Themes;
    Workflows = [];
    Jiras;
    Projects;
    CSSFiles;
    FAVIcon;
    PWAIcon;

    toggleWindow = true;

    // static GetSettingsEntry() {
    //     return {
    //         Caption: '@@Navigation',
    //         ID: 'navigation',
    //         Icon: 'menu',
    //         Index: 20,
    //         Parent: 'layout',
    //         Security: {
    //            key: 'LN-002'
    //         },
    //         Children: [],
    //         UpdateFunction: NavigationOverviewVersionSettings.UpdateNodes
    //     };
    // }

    private static UpdateNodes(nav) {
        NavigationOverwiewSettings.NaviNode.next(nav);
        if (nav) {
            nav.Children = [];
            const ds = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
            ds.GetNavigationStructures().subscribe(nsList => {
                let index = 0;
                const list = [];
                // list.push({
                //     Caption: '@@Uebersicht',
                //     ID: 'navi-overview',
                //     Icon: 'menu',
                //     Security: {
                //         key: 'LN-002'
                //     },
                //     Index: index++,
                //     Content: NavigationOverviewVersionSettings
                // });
                if (nsList) {
                    const gss = InjectorHelper.InjectorInstance.get<GeneralSettingsService>(GeneralSettingsService);
                    gss.GetDefaultNavigationStructure().then(defaultNS => {
                        nsList.forEach(ns => {
                            const node = NavigationOverwiewSettings.GetNode(ns, index++);
                            if (defaultNS) {
                                if (ns.SID == defaultNS) {
                                    node.Caption += ' *';
                                }
                            } else if (ns.Caption == 'default') {
                                node.Caption += ' *';
                            }
                            if (localStorage.getItem('selectedApp') === ns.SID) {
                                
                                list.push(node);
                            }
                        });
                        nav.Children = list;
                        NavigationService.Refresh.next(null);
                    });
                } else {
                    nav.Children = list;
                    NavigationService.Refresh.next(null);
                }
            });
        }
    }

    constructor(private cdRef: ChangeDetectorRef, private service: MetaService, private translate: TranslateService,
        private standardService: StandardRequestBase, private sanitizer: DomSanitizer, private router: Router,
        private wfService: WorkflowService, private jira: JiraService) {
    }

    ngOnInit(): void {
        NavigationService.SelectedPage.next('layout');
        this.refresh();
        this.UserSub = UsersService.ActiveUser.subscribe(x => {
            this.ActiveUser = x;
        });
        this.standardService.executeGet('config/api/config', 'GetManagementStatus').subscribe((result) => {
            if (result) {
                this.wfService.GetAllServiceWorkflows().subscribe(data => {
                    this.Workflows = data;
                });
            }
        });
        this.service.GetAllPages().subscribe(r => {
            if (r) {
                this.Pages.push(...r);
                this.UpdatePages()
            }
        });
        this.service.GetCustomCSS(null).subscribe((files) => {
            this.CSSFiles = files;
        });
        this.service.GetThemes(null).subscribe(themes => {
            this.Themes = themes;
        });
        this.jira.GetAll().subscribe(jiras => {
            this.Jiras = jiras;
        });
        this.Subscription = LayoutService.LayoutEditMode.subscribe(x => {
            if (!x && this.TempLayout) {
                LayoutService.BaseLayoutEditMode.next(false);
                const sel = this.SelectedItem;
                if (sel) {
                    const layout = sel[this.TempLayout.Key];
                    if (layout) {
                        const actLayout = JSON.stringify(layout);
                        if (actLayout !== this.TempLayout.Value) {
                            this.setHasChanges(true);
                        }
                    }
                }
                this.TempLayout = null;
            }
        });
    }

    ngOnDestroy(): void {
        NavigationService.SelectedPage.next('settings');
        if (this.UserSub) {
            this.UserSub.unsubscribe();
            this.UserSub = null;
        }
        if (this.Subscription) {
            this.Subscription.unsubscribe();
            this.Subscription = null;
        }
    }

    UpdateFiltered() {
        let list = [];
        if (this.ListItems) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.Caption.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.ListItems);
            }
        }
        this.FilteredItems = list;
    }
    UpdatePages() {
        let list = [];
        if (this.Pages) {
            if (this.SearchPages) {
                const toLower = this.SearchPages.toLowerCase();
                list = this.Pages.filter(x => x.Caption.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.Pages);
            }
        }
        this.FilteredPages = list;
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.checkHasChanges().then(check => {
                if (check) {
                    this.selectedNode = selection;
                    this.setSelectedItem(null);
                    if (selection && selection.SID) {
                        this.service.LoadNavigationStructure(selection.SID).subscribe((result) => {
                            if (result) {
                                this.setSelectedItem(result);
                                this.EditMode = this.ActiveUser && selection.CanEditInfo && selection.CanEditInfo.EditUserID == this.ActiveUser.SID;
                            }
                        });
                    }
                }
            });
        }
    }

    setSelectedItem(item) {
        this.EditMode = false;
        this.SelectedItem = item;
        this.PWAIcon = null;
        this.FAVIcon = null;
        if (item) {
            if (item.PWAIcon) {
                this.PWAIcon = this.sanitizer.bypassSecurityTrustResourceUrl(item.PWAIcon);
            }
            if (item.FAVIcon) {
                this.FAVIcon = this.sanitizer.bypassSecurityTrustResourceUrl(item.FAVIcon);
            }
            if (item.Jira) {
                var config = this.Jiras.find(x => x.SID == item.Jira);
                this.jira.GetAllProjects(config).subscribe(projects => {
                    this.Projects = projects;
                    this.cdRef.detectChanges();
                });
            }
        }
        this.cdRef.detectChanges();
    }

    setHasChanges(hasChanges) {
        if (typeof hasChanges === 'boolean') {
            this.HasChanges = hasChanges;
            LayoutService.PreventBrowserClose.next(this.HasChanges);
        }
    }

    checkHasChanges() {
        return new Promise<boolean>(resolve => {
            if (this.HasChanges) {
                if (this.selectedNode && this.selectedNode.CanEditInfo && !this.selectedNode.CanEditInfo.CanEdit) {
                    resolve(true);
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                        new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question).then(retVal => {
                            if (retVal === MessageBoxResult.Yes) {
                                if (this.EditMode) {
                                    this.save(false).then(x => {
                                        resolve(x);
                                    });
                                } else {
                                    this.setEditMode(false).then(x => {
                                        resolve(x);
                                    });
                                }
                            } else if (retVal === MessageBoxResult.Abort) {
                                resolve(false);
                            } else { // No
                                this.setHasChanges(false);
                                resolve(true);
                            }
                        });
                }
            } else {
                resolve(true);
            }
        });
    }

    setEditMode(fromButton) {
        return new Promise<boolean>(resolve => {
            if (this.SelectedItem) {
                this.service.RequestNavigationStructureLock(this.SelectedItem).subscribe(x => {
                    if (x && x.CanEditInfo) {
                        let index = -1;
                        if (this.ListItems.some((y, i) => {
                            if (x.SID == y.SID) {
                                index = i;
                                return true;
                            }
                            return false;
                        })) {
                            this.ListItems.splice(index, 1, x);
                            this.checkItemStyle(x);
                            if (fromButton) {
                                this.selectedNode = x;
                            }
                        }
                        if (x.CanEditInfo.CanEdit) {
                            this.EditMode = true;
                            this.setHasChanges(false);
                            const text = new TranslateFormatText('@@Navigation \'{0}\' erfolgreich gespeichert.');
                            text.FormatParams.push(x.Caption);
                            NotificationHelper.Success(text, new TranslateFormatText('@@Navigation speichern'));
                            this.cdRef.detectChanges();
                            resolve(true);
                        } else {
                            const text = new TranslateFormatText(x.CanEditInfo.NewVersion ? '@@AlreadySavedBy{0}' : '@@LockedBy{0}');
                            text.FormatParams.push(x.CanEditInfo.EditUserName);
                            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                                MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    checkItemStyle(data) {
        if (data) {
            const style = {};
            if (data.IsCapsule) {
                style['font-style'] = 'italic';
            }
            if (data.IsOverridden) {
                style['font-weight'] = 'bold';
            }
            if (data.CanEditInfo) {
                if (data.CanEditInfo.CanEdit) {
                    if (this.ActiveUser && data.CanEditInfo.EditUserID == this.ActiveUser.SID) {
                        style['text-decoration'] = 'underline';
                    }
                } else {
                    style['text-decoration'] = 'line-through';
                }
            }
            data.Style = style;
        }
    }

    save(fromSaveButton: boolean): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            LayoutService.Loading.next(true);
            this.checkCanSave().then(dc => {
                if (dc.IsCorrect) {
                    const sel = this.SelectedItem;
                    if (sel) {
                        this.service.SaveNavigationStructureToChangeLog(sel).subscribe((result) => {
                            if (result) {
                                this.setHasChanges(false);
                                const text = new TranslateFormatText('@@Navigation \'{0}\' saved successfully.');
                                text.FormatParams.push(result.Caption);
                                NotificationHelper.Success(text, new TranslateFormatText('@@Save Navigation'));
                                let index = -1;
                                if (this.ListItems.some((x, i) => {
                                    if (x.SID == result.SID) {
                                        index = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    this.ListItems.splice(index, 1, result);
                                } else { // neu
                                    sel._Id = result.SID;
                                    sel._version = result.Version;
                                    const node = NavigationOverwiewSettings.NaviNode.getValue();
                                    if (node && node.Children) {
                                        const child = NavigationOverwiewSettings.GetNode(result, node.Children.length);
                                        node.Children.push(child);
                                    }
                                    this.ListItems.push(result);
                                    ArrayHelpers.sortAlphabetical(this.ListItems, 'Caption');
                                    this.UpdateFiltered();
                                    // neue Struktur wird in Meta gespeichert -> neuer CheckOut n�tig
                                    this.EditMode = this.ActiveUser && result.CanEditInfo && result.CanEditInfo.EditUserID == this.ActiveUser.SID;
                                }
                                this.checkItemStyle(result);
                                if (fromSaveButton) {
                                    this.selectedNode = result;
                                }
                            } else {
                                NotificationHelper.Error(new TranslateFormatText('@@AlreadySavedByAdmin'),
                                    new TranslateFormatText('@@Save Navigation'));
                            }
                            LayoutService.Loading.next(false);
                        });
                    } else {
                        LayoutService.Loading.next(false);
                    }
                } else {
                    const t = '@@Changes could not be saved. Please check the following settings:\n{0}';
                    const text = new TranslateFormatText(t);
                    text.FormatParams.push(dc.Error);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                    LayoutService.Loading.next(false);
                }
                resolve(dc.IsCorrect);
            });
        });
    }

    checkCanSave(): Promise<DataCheck> {
        return new Promise<DataCheck>(resolve => {
            const sel = this.SelectedItem;
            if (sel) {
                if (sel._key) {
                    this.service.GetNavigationStructures().subscribe(nsList => {
                        const toLower = sel._key.toLowerCase();
                        const retVal = new DataCheck();
                        if (nsList.some(x => x.Caption.toLowerCase() === toLower && x.SID !== sel._Id)) {
                            const text = '@@A navigation with the specified key already exists. Please change the key.';
                            retVal.Error = this.translate.instant(text);
                            retVal.IsCorrect = false;
                        }
                        resolve(retVal);
                    });
                } else {
                    const retVal = new DataCheck();
                    retVal.Error = this.translate.instant('@@Please assign a key');
                    retVal.IsCorrect = false;
                    resolve(retVal);
                }
            } else {
                resolve(new DataCheck());
            }
        });
    }

    refresh() {
        this.checkHasChanges().then(check => {
            if (check) {
                LayoutService.Loading.next(true);
                this.SearchValue = null;
                this.EditMode = false;
                this.ListItems = [];
                this.FilteredItems = [];
                this.selectedNode = null;
                this.setSelectedItem(null);
                this.HasChanges = false;
                this.service.LoadNavigationStructures().subscribe((result) => {
                    if (result) {
                        result.forEach(x => this.checkItemStyle(x));
                        ArrayHelpers.sortAlphabetical(result, 'Caption');
                        this.ListItems = result;
                        this.UpdateFiltered();
                    }
                    LayoutService.Loading.next(false);
                    this.cdRef.detectChanges();
                });
            }
        });
    }

    addItem() {
        this.checkHasChanges().then(check => {
            if (check) {
                this.selectedNode = null;
                this.setSelectedItem(null);
                this.setSelectedItem({});
                this.EditMode = true;
            }
        });
    }

    async deleteItem(item?: any) {
        if (item) {
            await this.onItemClick(item)
        }
        const sel = this.selectedNode;
        if (sel) {
            if (sel.CanEditInfo && !sel.CanEditInfo.CanEdit) {
                return;
            }
            const tft = new TranslateFormatText('@@Are you sure you want to delete navigation \'{0}\'?');
            tft.FormatParams.push(sel.Caption);
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Delete Navigation'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.deleteItemInternal(sel, false);
                    }
                });
        }
    }

    private deleteItemInternal(sel, ignoreVersions: boolean) {
        this.service.TryDeleteNavigationStructure(sel.SID, ignoreVersions).subscribe(result => {
            if (result) {
                if (result.Deleted) {
                    this.setHasChanges(false);
                    this.refresh();
                    const success = new TranslateFormatText('@@Navigation \'{0}\' successfully deleted.');
                    success.FormatParams.push(sel.Caption);
                    NotificationHelper.Success(success, '@@Navigation loeschen');
                } else if (result.IsLocked) {
                    const text = new TranslateFormatText('@@LockedBy{0}');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else if (result.MultipleVersions) {
                    const text = new TranslateFormatText('@@MultipleVersionsInfoText');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@MultipleVersionsInfoHeader'),
                        MessageBoxButtons.None, MessageBoxIcon.Information, 500, null, [
                        { Caption: '@@DeleteAllVersions', ID: 0 },
                        { Caption: '@@GoToVersionsOverview', ID: 1 },
                        { Caption: '@@Cancel', ID: 2 }
                    ]).then(result => {
                        if (result == 0) {
                            this.deleteItemInternal(sel, true);
                        } else if (result == 1) {
                            const navStruct = SideNavService.SelectedNavigationStructure.getValue();
                            if (navStruct) {
                                this.router.navigateByUrl(navStruct + VersioningOverview.Path);
                            }
                        }
                    });
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@CannotDeleteDefaultStructure'),
                        new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                }
            }
        });
    }

    OnJiraChanged(item) {
        this.setHasChanges(true);
        const config = this.Jiras.find(x => x.SID == item.value);
        if (config) {
            this.jira.GetAllProjects(config).subscribe(projects => {
                this.Projects = projects;
                this.cdRef.detectChanges();
            });
        }
    }

    editLayout() {
        const sel = this.SelectedItem;
        if (sel) {
            if (sel.BaseLayout) {
                if (!(sel.BaseLayout instanceof Layout)) {
                    sel.BaseLayout = plainToClass(Layout, sel.BaseLayout);
                }
            } else {
                sel.BaseLayout = NavigationHelper.GetDefaultBaseLayout();
            }
            if (sel._key === 'default') {
                // removing header from the layout
                let Elements = []
                Elements = sel.BaseLayout.Elements.filter(x => (x._Name !== 'Header' && x._Name !== 'Body'));
                let bodyElement = sel.BaseLayout.Elements.find(x => x._Name === 'Body');
                bodyElement._Row = 1;
                sel.BaseLayout.Elements = [bodyElement, ...Elements];
                // remove first item from the RowDefinitions array
                sel.BaseLayout.RowDefinitions = sel.BaseLayout.RowDefinitions.slice(1);
                sel.BaseLayout._rowDefinitions = sel.BaseLayout._rowDefinitions.slice(1);
                sel.BaseLayout.SID = sel.BaseLayout.SID.slice(1);
            }
            LayoutService.SelectedLayout.next(sel.BaseLayout);
            LayoutService.BaseLayoutEditMode.next(true);
            LayoutService.LayoutEditMode.next(true);
            this.TempLayout = {
                Key: 'BaseLayout',
                Value: JSON.stringify(sel.BaseLayout)
            };
        }
    }

    resetBaseLayout() {
        if (this.SelectedItem) {
            this.SelectedItem.BaseLayout = null;
            this.setHasChanges(true);
        }
    }

    editLogin() {
        const sel = this.SelectedItem;
        if (sel) {
            if (sel.LoginLayout) {
                if (!(sel.LoginLayout instanceof Layout)) {
                    sel.LoginLayout = plainToClass(Layout, sel.LoginLayout);
                }
            } else {
                sel.LoginLayout = NavigationHelper.GetDefaultLoginLayout();
            }
            LayoutService.SelectedLayout.next(sel.LoginLayout);
            LayoutService.LayoutEditMode.next(true);
            this.TempLayout = {
                Key: 'LoginLayout',
                Value: JSON.stringify(sel.LoginLayout)
            };
        }
    }

    resetLoginLayout() {
        if (this.SelectedItem) {
            this.SelectedItem.LoginLayout = null;
            this.setHasChanges(true);
        }
    }

    UploadFAV(ev) {
        if (ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                const fr = new FileReader();
                const item = this.SelectedItem;
                const that = this;
                fr.addEventListener('load', function () {
                    item.FAVIcon = fr.result;
                    that.setHasChanges(true);
                }, false);
                fr.readAsDataURL(selectedFile);
            }
        }
    }

    UploadPWA(ev) {
        if (ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                const fr = new FileReader();
                const item = this.SelectedItem;
                const that = this;
                fr.addEventListener('load', function () {
                    item.PWAIcon = fr.result;
                    that.setHasChanges(true);
                }, false);
                fr.readAsDataURL(selectedFile);
            }
        }
    }

    editKey() {
        const sel = this.SelectedItem;
        if (sel) {
            const forbiddenKeys = [];
            this.ListItems.forEach(x => {
                forbiddenKeys.push(x.Caption);
            });
            ChangeKeyDialog.ShowDialog(forbiddenKeys, true, (key) => {
                if (key) {
                    this.service.ChangeNavigationStructureKey(sel._Id, key).subscribe(x => {
                        if (typeof x === 'number') {
                            if (x == 0) {
                                sel._key = key;
                                NotificationHelper.Success('@@SuccessfullyReplacedKeyText', '@@SuccessfullyReplacedKeyHeader');
                            } else {
                                ChangeKeyDialog.ShowMessage(x);
                            }
                        }
                    });
                }
            });
        }
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
