import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { VariableHelper } from '../../../helpers/variable.helper';
import { EventActionType } from '../../../models/enums/eventactiontype.enum';
import { EventAction } from '../../../models/layoutbase.model';
import { GLOBALEVENTREGISTRY } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { UserSettingsService } from '../../../services/usersettings.service';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { CapsuleHelper } from '../../../helpers/capsule.helper';

@Component({
    selector: 'pagesettings-menu-tab',
    templateUrl: './pagesettings.menu.tab.html',
    styleUrls: ['./pagesettings.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSettingsMenuTab extends ALayoutMenuTab {
    //#region AutoSave
    SelectedAutoSaveVariable;
    //#endregion
    //#region ProfileVariable
    SelectedProfileVariable;
    //#endregion
    ShowVariables;
    Variables;
    Controls;
    Workflows = [];
    //#region GlobalEvents
    Events = [];
    SelectedEvent;
    SelectedEventHandler;
    EventHandlerTypes = [];
    //#endregion

    Types = [{ Caption: '@@Variable', Name: 'Variable' }, { Caption: '@@Control', Name: 'Control' }];

    SelectedLayoutEvent;
    LayoutEvents = [];
    ShowPageName = false;
    ShowCapsuleTags = false;

    constructor(private userSettingsService: UserSettingsService, cdRef: ChangeDetectorRef, private metaService: MetaService) {
        super(cdRef);
        this.EventHandlerTypes = EnumHelper.GetDropdownValues(EventActionType);
    }

    ngOnInit() {
        const page = NavigationService.SelectedPage.getValue();
        this.ShowPageName = page === 'content';
        if (page === 'content' || page === 'template') {
            CapsuleHelper.GetCapsuleTags().then(r => {
                if (r) {
                    this.ShowCapsuleTags = true;
                }
            });
        }
        if (page === 'content' || page === 'widget' || page === 'template' || page === 'output' || page === 'layout') {
            this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
                this.Layout = LayoutHelper.GetActiveResolution(layout);

                if (!this.Layout.LayoutEvents) {
                    this.Layout.LayoutEvents = [];
                }
                this.SelectedLayoutEvent = null;

                if (!this.Layout.GlobalEvents) {
                    this.Layout.GlobalEvents = [];
                }
                this.Events = this.Layout.GlobalEvents;

                GLOBALEVENTREGISTRY.forEach((event) => {
                    const ev = this.Events.find((value) => event.ID == value.ID);
                    if (!ev) {
                        this.Events.push({
                            ID: event.ID,
                            Caption: event.Caption
                        });
                    }
                });

                this.FetchWorkflows(this.Layout);
                this.Variables = VariableHelper.AvailableVariables[this.Layout._Id];
                this.Controls = VariableHelper.AvailableControls[this.Layout._Id];
            }));
        }
        this.ShowVariables = (page !== 'widget' && page !== 'template');
        this.metaService.ReadEventInfos().subscribe(x => {
            if (x) {
                this.LayoutEvents = x;
            }
        });
    }

    AddAutoSaveItem() {
        if (this.Layout) {
            const item = {
                ID: null,
                Name: null,
                Type: 'Variable'
            };
            this.SelectedAutoSaveVariable = item;
            if (this.Layout.AutoSaveVariables) {
                this.Layout.AutoSaveVariables.push(item);
            } else {
                this.Layout.AutoSaveVariables = [item];
            }
            this.onAutoSaveVariablesChanged();
        }
    }
    DeleteAutoSaveItem() {
        if (this.Layout && this.Layout.AutoSaveVariables && this.SelectedAutoSaveVariable) {
            const index = this.Layout.AutoSaveVariables.indexOf(this.SelectedAutoSaveVariable);
            if (index > -1) {
                this.Layout.AutoSaveVariables.splice(index, 1);
                this.userSettingsService.RemoveByLayoutAndItemAuto(this.Layout._Id, this.SelectedAutoSaveVariable.ID).subscribe();
                this.onAutoSaveVariablesChanged();
                const length = this.Layout.AutoSaveVariables.length;
                if (length > 0) {
                    if (index < length) {
                        this.SelectedAutoSaveVariable = this.Layout.AutoSaveVariables[index];
                    } else {
                        this.SelectedAutoSaveVariable = this.Layout.AutoSaveVariables[length - 1];
                    }
                } else {
                    this.SelectedAutoSaveVariable = null;
                }
            }
        }
    }

    onAutoSaveVariablesChanged() {
        LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'AutoSaveVariables', this.Layout.AutoSaveVariables);
    }

    AddProfileItem() {
        if (this.Layout) {
            const item = {
                ID: null,
                Name: null,
                Type: 'Variable'
            };
            this.SelectedProfileVariable = item;
            if (this.Layout.ProfileVariables) {
                this.Layout.ProfileVariables.push(item);
            } else {
                this.Layout.ProfileVariables = [item];
            }
            this.onProfileVariablesChanged();
        }
    }
    DeleteProfileItem() {
        if (this.Layout && this.Layout.ProfileVariables && this.SelectedProfileVariable) {
            const index = this.Layout.ProfileVariables.indexOf(this.SelectedProfileVariable);
            if (index > -1) {
                this.Layout.ProfileVariables.splice(index, 1);
                this.userSettingsService.RemoveByLayoutAndItemProfile(this.Layout._Id, this.SelectedProfileVariable.ID).subscribe();
                this.onProfileVariablesChanged();
                const length = this.Layout.ProfileVariables.length;
                if (length > 0) {
                    if (index < length) {
                        this.SelectedProfileVariable = this.Layout.ProfileVariables[index];
                    } else {
                        this.SelectedProfileVariable = this.Layout.ProfileVariables[length - 1];
                    }
                } else {
                    this.SelectedProfileVariable = null;
                }
            }
        }
    }

    onProfileVariablesChanged() {
        LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'ProfileVariables', this.Layout.ProfileVariables);
    }

    onGlobalEventsChanged() {
        LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'GlobalEvents', this.Layout.GlobalEvents);
    }

    addEventHandler() {
        if (this.SelectedEvent) {
            const ev = new EventAction();
            this.SelectedEventHandler = ev;
            if (this.SelectedEvent.Handlers) {
                this.SelectedEvent.Handlers.push(ev);
            } else {
                this.SelectedEvent.Handlers = [ev];
            }
            this.onGlobalEventsChanged();
        }
    }
    removeEventHandler() {
        if (this.SelectedEvent && this.SelectedEvent.Handlers && this.SelectedEventHandler) {
            const index = this.SelectedEvent.Handlers.indexOf(this.SelectedEventHandler);
            if (index > -1) {
                this.SelectedEvent.Handlers.splice(index, 1);
                this.onGlobalEventsChanged();
                const length = this.SelectedEvent.Handlers.length;
                if (length > 0) {
                    if (index < length) {
                        this.SelectedEventHandler = this.SelectedEvent.Handlers[index];
                    } else {
                        this.SelectedEventHandler = this.SelectedEvent.Handlers[length - 1];
                    }
                } else {
                    this.SelectedEventHandler = null;
                }
            }
        }
    }
    SetEventLabel() {
        if (this.SelectedEventHandler) {
            this.SelectedEventHandler.Label = EventActionType[this.SelectedEventHandler.ActionType];
            if (this.SelectedEventHandler.Action) {
                if (this.SelectedEventHandler.ActionType == 0) {
                    const item = this.Workflows.find((value) => value.ID == this.SelectedEventHandler.Action);
                    if (item) {
                        this.SelectedEventHandler.Label += ' - ' + item.Caption;
                    }
                }
                if (this.SelectedEventHandler.ActionType == 1) {
                    const item = this.Variables.find((value) => value.ID == this.SelectedEventHandler.Action);
                    if (item) {
                        this.SelectedEventHandler.Label += ' - ' + item.Name;
                    }
                }
            }
            this.onGlobalEventsChanged();
        }
    }

    FetchWorkflows(Layout) {
        this.Workflows = [];
        if (Layout.Workflows) {
            this.Workflows = Layout.Workflows;
        }
    }

    ControlChanged(Selection) {
        const item = this.Controls.find((value) => value.ID == Selection.ID);
        if (item) {
            Selection.Name = item.Name;
        }
    }
    VariableChanged(Selection) {
        const item = this.Variables.find((value) => value.ID == Selection.ID);
        if (item) {
            Selection.Name = item.Name;
        }
    }

    addLayoutEvent() {
        if (this.Layout) {
            const ev = {
                Name: TranslateHelper.TranslatorInstance.instant('@@Neuer Eventhandler'),
                ActionType: EventActionType.Workflow
            };
            if (this.Layout.LayoutEvents) {
                this.Layout.LayoutEvents.push(ev);
            } else {
                this.Layout.LayoutEvents = [ev];
            }
            this.SelectedLayoutEvent = ev;
            this.onLayoutEventsChanged();
        }
    }

    removeLayoutEvent() {
        if (this.Layout && this.Layout.LayoutEvents && this.SelectedLayoutEvent) {
            const index = this.Layout.LayoutEvents.indexOf(this.SelectedLayoutEvent);
            if (index > -1) {
                this.Layout.LayoutEvents.splice(index, 1);
                this.onLayoutEventsChanged();
                if (index < this.Layout.LayoutEvents.length) {
                    this.SelectedLayoutEvent = this.Layout.LayoutEvents[index];
                } else if (this.Layout.LayoutEvents.length > 0) {
                    this.SelectedLayoutEvent = this.Layout.LayoutEvents[this.Layout.LayoutEvents.length - 1];
                } else {
                    this.SelectedLayoutEvent = null;
                }
            }
        }
    }

    onLayoutEventsChanged() {
        LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'LayoutEvents', this.Layout.LayoutEvents);
    }

    SelectedTag;

    AddTag() {
        if (this.Layout) {
            const item = {
                Key: null,
                Value: null
            };
            this.SelectedTag = item;
            if (this.Layout.MetaTags) {
                this.Layout.MetaTags.push(item);
            } else {
                this.Layout.MetaTags = [item];
            }
            //this.onAutoSaveVariablesChanged();
        }
    }
    DeleteTag() {
        if (this.Layout && this.Layout.MetaTags && this.SelectedTag) {
            const index = this.Layout.MetaTags.indexOf(this.SelectedTag);
            if (index > -1) {
                this.Layout.MetaTags.splice(index, 1);
            }
        }
    }

    //onAutoSaveVariablesChanged() {
    //    LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'AutoSaveVariables', this.Layout.AutoSaveVariables);
    //}
}
