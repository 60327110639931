import { Component } from '@angular/core';
import { deserialize, Type } from 'class-transformer';
import { BaseWorkerControl } from '../../../settings/scheduler/controls/workercontrols/base.worker.control';
import { MessageSettingsService } from '../MessageSettingsService';

@Component({
    selector: 'tapp-message-worker-control',
    templateUrl: './tapp.message.worker.control.html',
    styleUrls: ['./tapp.message.worker.control.css']
})
export class TAppMessageWorkerControl extends BaseWorkerControl {

    Settings = new TAppMessageWorkerContent();
    AllMessages = [];

    public static GetInfo() {
        return {
            Key: 'd6b519e1-544f-4f9e-97bb-e24436eff0cf',
            Type: TAppMessageWorkerControl
        };
    }

    constructor(private service: MessageSettingsService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.service.LoadMessageSettingsInfos().subscribe(result => {
            if (result) {
                this.AllMessages = result;
            }
        });
    }

    OnTriggerSet() {
        if (this.TriggerValue && this.TriggerValue.ExecutionContext) {
            this.Settings = deserialize(TAppMessageWorkerContent, this.TriggerValue.ExecutionContext);
        } else {
            this.Settings = new TAppMessageWorkerContent();
        }
        this.loadVariables();
        if (this.TriggerValue) {
            this.TriggerValue.ExecutionContextAsObject = this.Settings;
        }
    }

    loadVariables() {
        const settings = this.Settings;
        if (settings) {
            if (settings.MessageID) {
                this.service.GetMessageSettingVariables(settings.MessageID).subscribe(result => {
                    const variables = [];
                    if (result) {
                        result.forEach(x => {
                            let existing = settings.Parameters.find(y => y.Key === x);
                            if (!existing) {
                                existing = new MessageParam();
                                existing.Key = x;
                            }
                            variables.push(existing);
                        });
                    }
                    settings.Parameters = variables;
                });
            } else {
                settings.Parameters = [];
            }
        }
    }
}


// @dynamic
export class TAppMessageWorkerContent {
    MessageID: string;
    @Type(() => MessageParam)
    Parameters: MessageParam[] = [];

    toJSON() {
        const retVal = new TAppMessageWorkerContent();
        retVal.MessageID = this.MessageID;
        this.Parameters.forEach(x => {
            if (x.Formula) {
                retVal.Parameters.push(x);
            }
        });
        return retVal;
    }
}

export class MessageParam {
    Key: string;
    Formula: string;
}
