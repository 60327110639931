import { LevelType } from '../models/enums';
import { WhereSelects } from '../models/datadescription/multi/querydef.model';
import { DynamicTimeType, RangeOrientation } from '../models/enums/query.enum';
import { DynamicTimePoint, TimeReference } from '../models/time/dynamictimepoint.model';

export class TimeHelper {

    static GetDateTimeFromDynamicTimePointTimeType(dt: DynamicTimePoint, whereSelects: WhereSelects) {
        let retVal: Date = null;
        if (dt) {
            switch (dt.TimeType) {
                case DynamicTimeType.Now:
                    retVal = new Date();
                    if (dt.References && dt.References.length > 0) {
                        const now = new Date(); // UTC???
                        const references = [...dt.References];
                        references.sort((a: TimeReference, b: TimeReference) => {
                            return a.ReferenceDate.getTime() - b.ReferenceDate.getTime();
                        });
                        references.some(reference => {
                            if (now >= reference.ReferenceDate) {
                                retVal = new Date(reference.TargetDate);
                                return false;
                            }
                            return true;
                        });
                    }
                    break;
                case DynamicTimeType.Filter:
                    switch (dt.Orientation) {
                        case RangeOrientation.Start:
                            retVal = new Date(whereSelects.Start);
                            break;
                        case RangeOrientation.End:
                            retVal = new Date(whereSelects.End);
                            break;
                    }
                    break;
                case DynamicTimeType.Fix:
                    if (dt.FixTime) {
                        retVal = new Date(dt.FixTime);
                    }
                    break;
                default:
                    break;
            }
        }
        return retVal;
    }

    static GetDateTimeFromDynamicTimePointMovement(point: Date, timeMovement: Map<LevelType, number>) {
        let retVal: Date = null;
        if (point) {
            retVal = new Date(point);
            if (timeMovement) {
                timeMovement.forEach((value, key) => {
                    if (value !== 0) {
                        switch (key) {
                            case LevelType.TimeYears:
                                retVal.setFullYear(retVal.getFullYear() + value);
                                break;
                            case LevelType.TimeHalfYears:
                                retVal.setMonth(retVal.getMonth() + value * 6);
                                break;
                            case LevelType.TimeQuarters:
                                retVal.setMonth(retVal.getMonth() + value * 3);
                                break;
                            case LevelType.TimeMonths:
                                retVal.setMonth(retVal.getMonth() + value);
                                break;
                            case LevelType.TimeWeeks:
                                retVal.setDate(retVal.getDate() + value * 7);
                                break;
                            case LevelType.TimeDays:
                                retVal.setDate(retVal.getDate() + value);
                                break;
                        }
                    }
                });
            }
        }
        return retVal;
    }

    static GetDateTimeFromDynamicTimePointFixation(dtp: DynamicTimePoint, point: Date) {
        let retVal: Date = null;
        if (point) {
            retVal = new Date(point);
            if (dtp) {
                if (dtp.TimeFixation) {
                    switch (dtp.FixationLevel) {
                        case LevelType.TimeHalfYears:
                            let hyMonth = 0;
                            const halfYear = dtp.TimeFixation.get(LevelType.TimeHalfYears);
                            if (halfYear >= 2) {
                                hyMonth = 6;
                            }
                            let halfYearMonth = dtp.TimeFixation.get(LevelType.TimeMonths);
                            if (typeof halfYearMonth === 'number' && halfYearMonth > 1) {
                                if (halfYearMonth > 6) {
                                    halfYearMonth = 5;
                                } else {
                                    halfYearMonth--;
                                }
                                hyMonth += halfYearMonth;
                            }
                            retVal.setMonth(hyMonth);
                            TimeHelper.SetDay(dtp.TimeFixation.get(LevelType.TimeDays), retVal);
                            break;
                        case LevelType.TimeQuarters:
                            let qMonth = 0;
                            let quarter = dtp.TimeFixation.get(LevelType.TimeQuarters);
                            if (typeof quarter === 'number' && quarter > 1) {
                                if (quarter === 2) {
                                    qMonth = 3;
                                } else if (quarter === 3) {
                                    qMonth = 6;
                                } else {
                                    qMonth = 9;
                                }
                            }
                            let quarterMonth = dtp.TimeFixation.get(LevelType.TimeMonths);
                            if (typeof quarterMonth === 'number' && quarterMonth > 1) {
                                if (quarterMonth > 3) {
                                    quarterMonth = 2;
                                } else {
                                    quarterMonth--;
                                }
                                qMonth += quarterMonth;
                            }
                            retVal.setMonth(qMonth);
                            TimeHelper.SetDay(dtp.TimeFixation.get(LevelType.TimeDays), retVal);
                            break;
                        case LevelType.TimeMonths:
                            TimeHelper.SetDay(dtp.TimeFixation.get(LevelType.TimeDays), retVal);
                            break;
                        case LevelType.TimeWeeks:
                            let dayDiff = dtp.TimeFixation.get(LevelType.TimeDays);
                            if (typeof dayDiff === 'number') {
                                if (dayDiff < 1) {
                                    dayDiff = 0;
                                } else if (dayDiff > 7) {
                                    dayDiff = 6;
                                } else {
                                    dayDiff--;
                                }
                            } else {
                                dayDiff = 0;
                            }
                            retVal.setMonth(0);
                            retVal.setDate(1);
                            const dayOfWeek = retVal.getDay();
                            if (dayOfWeek < 5) { // So (0) - Do (4)
                                retVal.setDate(2 - dayOfWeek + dayDiff);
                            } else { // Fr (5) oder Sa (6)
                                retVal.setDate(2 + 7 - dayOfWeek + dayDiff);
                            }
                            let weekDiff = dtp.TimeFixation.get(LevelType.TimeWeeks);
                            if (typeof weekDiff === 'number' && weekDiff > 1) {
                                if (weekDiff > 53) {
                                    retVal.setDate(retVal.getDate() + 52 * 7);
                                } else {
                                    retVal.setDate(retVal.getDate() + (weekDiff - 1) * 7);
                                }
                            }
                            break;
                    }
                }
                if (typeof dtp.SundayShift === 'number' && dtp.SundayShift !== 0 && retVal.getDay() === 0) {
                    retVal.setDate(retVal.getDate() + dtp.SundayShift);
                }
            }
        }
        return retVal;
    }

    private static IsLeapYear(year: number) {
        return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
    }

    private static SetDay(dayVal: number, date: Date) {
        if (date) {
            if (typeof dayVal === 'number') {
                if (dayVal < 1) {
                    dayVal = 1;
                } else if (dayVal > 28) {
                    const month = date.getMonth();
                    switch (month) {
                        case 1: // Februar
                            const year = date.getFullYear();
                            if (TimeHelper.IsLeapYear(year)) {
                                if (dayVal > 29) {
                                    dayVal = 29;
                                }
                            } else if (dayVal > 28) {
                                dayVal = 28;
                            }
                            break;
                        case 3: // April
                        case 5: // Juni
                        case 8: // September
                        case 10: // November
                            if (dayVal > 30) {
                                dayVal = 30;
                            }
                            break;
                        default:
                            if (dayVal > 31) {
                                dayVal = 31;
                            }
                            break;
                    }
                }
            } else {
                dayVal = 1;
            }
            date.setDate(dayVal);
        }
    }
}
