import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnDestroy } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { deserialize, plainToClass, serialize, Type } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { StyleBase } from '../../../../models/layoutbase.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { PROPERTYGROUPS } from '../../../../services/dynamic.component.service';
import { LayoutService } from '../../../../services/layout.service';
import { MetaService } from '../../../../services/meta.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'set-multi-style-settings',
    templateUrl: './set.multi.style.settings.html'
})
export class SetMultiStyleSettings extends WorkflowDialogContent implements OnDestroy {
    static ModuleID = 'setMultiStyleWFModule';

    Data = new SetMultiStyleSettingsData();
    StyleList = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetMultiStyleSettings.ModuleID;
        reg.Caption = '@@Styles anwenden';
        reg.GroupID = 'wfActions';
        reg.Index = 70;
        reg.SettingsControl = SetMultiStyleSettings;
        reg.SettingsTypeHelper = new SetMultiStyleSettingsDataHelper();
        reg.Executer = SetMultiStyleModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private metaService: MetaService) {
        super();
    }
    
    Content;
    Advanced;
    Decorations;
    ngOnInit(): void {
        this.metaService.GetControlStyles().subscribe(result => {
            if (result) {
                this.StyleList = result;
            }
        });
        //let sids = [
        //    'dbefb974-2c57-44e2-a3b6-dcf4fe96c4a3', // ContentPanel
        //    '1FE28779-54CC-4478-9A19-10BD93C59262', // AdvancedPanel
        //    '005cecb9-e72c-48ee-af12-6a2602a79dcf', // DecorationsPanel
        //]
        this.Content = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == 'dbefb974-2c57-44e2-a3b6-dcf4fe96c4a3';
        }))
        this.Advanced = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == '1FE28779-54CC-4478-9A19-10BD93C59262';
        }))
        this.Decorations = this.ConvertToPG(PROPERTYGROUPS.find((value) => {
            return value.SID == '005cecb9-e72c-48ee-af12-6a2602a79dcf';
        }))
    }
    ngOnDestroy(): void {
        LayoutService.SelectedObject.next(null);
    }
    ConvertToPG(x) {
        if (x) {
            const item: any = {
                SID: x.SID ? x.SID : UUID.UUID(),
                ID: x.ID,
                Caption: x.Caption,
                Visible: false,
                PropertyGroup: x,
                Index: x.Index,
                Badge: x.Badge,
                BadgeSub: x.BadgeSub
            };
            if (x.Content) {
                item.Content = new ComponentPortal(x.Content);
                item.Visible = true;
            }
            if (typeof x.CheckOnChange === 'function') {
                item.CheckOnChange = x.CheckOnChange;
            }
            return item
        }
        return null;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetMultiStyleSettingsData, json);
        }
    }
    SetStyle(style) {
        LayoutService.SelectedObject.next(style.Layout);
    }
    getResult() {
        const elements = [];
        this.Data.Styles.forEach(x => {
            if (x.Elements) {
                const elemList = [];
                x.Elements.forEach(y => {
                    if (y.ElemName) {
                        elemList.push(y);
                    }
                });
                if (elemList.length > 0) {
                    x.Elements = elemList;
                    elements.push(x);
                }
            }
        });
        this.Data.Styles = elements;
        return this.Data;
    }

    addStyle() {
        if (this.Data) {
            if (this.Data.Styles) {
                this.Data.Styles.push(new MultiStyleData());
            } else {
                this.Data.Styles = [new MultiStyleData()];
            }
        }
    }

    removeStyle(i) {
        if (this.Data && this.Data.Styles) {
            this.Data.Styles.splice(i, 1);
        }
    }

    addElement(style) {
        style.Elements.push(new MultiStyleElem());
    }

    removeElement(style, j) {
        style.Elements.splice(j, 1);
    }
}

export class MultiStyleElem {
    ElemName: string;
}

// @dynamic
export class MultiStyleData {
    @Type(() => MultiStyleElem)
    Elements: MultiStyleElem[] = [];
    StyleRef: string;
    @Type(() => StyleBase)
    Layout: StyleBase = new StyleBase();
}

// @dynamic
export class SetMultiStyleSettingsData {
    @Type(() => MultiStyleData)
    Styles: MultiStyleData[] = [];
}

export class SetMultiStyleSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetMultiStyleSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Styles) {
            settings.Styles.forEach(style => {
                if (style.Elements) {
                    style.Elements.forEach(elem => {
                        if (elem.ElemName === oldName) {
                            elem.ElemName = newName;
                            retVal = true;
                        }
                    });
                }
            });
        }
        return retVal;
    }
}

export class SetMultiStyleModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            for (let i = 0; i < status.ActualSettings.Styles.length; i++) {
                const styleData = status.ActualSettings.Styles[i];
                let layoutStyle;
                if (styleData.StyleRef) {
                    const style = await CacheService.ReadStyle(styleData.StyleRef);
                    if (style) {
                        const json = style['StyleJSON'];
                        if (json) {
                            layoutStyle = plainToClass(StyleBase, JSON.parse(json));
                        }
                    }
                } else {
                    layoutStyle = plainToClass(StyleBase, styleData.Layout);
                }
                if (layoutStyle) {
                    styleData.Elements.forEach(elemInfo => {
                        const elem = status.WorkflowLayoutService.getElementByName(elemInfo.ElemName);
                        if (elem) {
                            if (elem.Element) {
                                if (Array.isArray(elem.Element.WorkflowStyles)) {
                                    elem.Element.WorkflowStyles.push(layoutStyle);
                                } else {
                                    elem.Element.WorkflowStyles = [layoutStyle];
                                }
                                elem.Element.onWorkflowStylesChanged();
                            } else {
                                status.Logger.logWarning('SetMultiStyle modul: Control ' + elemInfo.ElemName + ' not found.');
                            }
                        } else {
                            status.Logger.logWarning('SetMultiStyle modul: Element ' + elemInfo.ElemName + ' not found.');
                        }
                    });
                } else {
                    status.Logger.logWarning('SetMultiStyle modul: Style not found.');
                }
            }
            return 0;
        } else {
            status.Logger.logError('SetMultiStyle modul: No settings found.');
        }
        return super.execute(status);
    }
}
