import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { LAYOUTEVENTREGISTRY } from '../../../services/dynamic.component.service';
import { MetaService } from '../../../services/meta.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-trigger-layout-event-settings',
    templateUrl: './trigger.layout.event.settings.html'
})
export class TriggerLayoutEventSettings extends FormulaWorkflowDialogContent {
    Data = new TriggerLayoutEventSettingsData();
    Events = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'triggerLayoutEventModule';
        reg.Caption = '@@Trigger LayoutEvent';
        reg.GroupID = 'events';
        reg.Index = 10;
        reg.SettingsControl = TriggerLayoutEventSettings;
        reg.SettingsTypeHelper = new TriggerLayoutEventSettingsDataHelper();
        reg.Executer = TriggerLayoutEventModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private metaService: MetaService) {
        super();
    }

    ngOnInit(): void {
        this.metaService.ReadEventInfos().subscribe(x => {
            if (x) {
                this.Events = x;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(TriggerLayoutEventSettingsData, json);
        }
    }

    getResult() {
        this.Data.Events = this.Data.Events.filter(x => x.EventID);
        return this.Data;
    }

    addEvent() {
        if (this.Data) {
            if (this.Data.Events) {
                this.Data.Events.push(new TriggerLayoutEventSettingsDataEntry());
            } else {
                this.Data.Events = [new TriggerLayoutEventSettingsDataEntry()];
            }
        }
    }

    removeEvent(i) {
        if (this.Data && this.Data.Events) {
            this.Data.Events.splice(i, 1);
        }
    }

    onDrop(ev) {
        if (ev && ev.currentIndex !== ev.previousIndex && this.Data && this.Data.Events) {
            moveItemInArray(this.Data.Events, ev.previousIndex, ev.currentIndex);
        }
    }
}

export class TriggerLayoutEventSettingsDataEntry {
    EventID: string;
    EventData: string;
}

// @dynamic
export class TriggerLayoutEventSettingsData {
    @Type(() => TriggerLayoutEventSettingsDataEntry)
    Events: TriggerLayoutEventSettingsDataEntry[] = [];
}

export class TriggerLayoutEventSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new TriggerLayoutEventSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class TriggerLayoutEventModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Events && status.ActualSettings.Events.length > 0) {
                const eventList = JSON.parse(JSON.stringify(status.ActualSettings.Events));
                const calculator = new WorkflowFormulaCalculator(status);
                eventList.forEach(x => {
                    let eventData;
                    if (x.EventData) {
                        try {
                            eventData = calculator.CalcFormula(x.EventData);
                        } catch (ex) {
                            status.Logger.logWarning('TriggerLayoutEventWF modul: Error while calculating event data ' +
                                x.EventData + ' (' + ex + ').');
                        }
                    }
                    const layoutEvent = LAYOUTEVENTREGISTRY.find(y => y.ID === x.EventID);
                    if (layoutEvent && layoutEvent.Trigger) {
                        layoutEvent.Trigger.next(eventData);
                    } else {
                        status.Logger.logWarning('TriggerLayoutEventWF modul: Event with ID ' + x.EventID + ' not found.');
                    }
                });
            }
            return 0;
        } else {
            status.Logger.logError('TriggerLayoutEventWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
