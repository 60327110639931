// google-signin.component.ts
import { Component, OnInit } from '@angular/core';
import { LoginComponent } from "../login/login.component";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterceptorSkipHeader } from '../../services/interceptor.skip.header';
@Component({
  selector: 'app-google-signin',
  template: '<div id="googleSignInButton"></div>',
  styleUrls: ["../login/login.component.scss"],
})
export class GoogleSigninComponent implements OnInit {
  constructor(private loginComponent: LoginComponent, private http: HttpClient) { }

  ngOnInit(): void {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    this.http.get('assets/config.json', { headers }).subscribe(result => {
      if (result) {
        window.google["accounts"].id.initialize({
          client_id: result["Google_Client_Id"],
          callback: this.handleCredentialResponse.bind(this),
        });

        window.google["accounts"].id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large' }
        );

        window.google["accounts"].id.prompt(); // Optional  
      }
    })
  }

  handleCredentialResponse(response: any) {
    this.loginComponent.handleGoogleSignin(response.credential);
  }
}
