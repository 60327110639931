import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'evi-treetable',
    templateUrl: './treetable.control.html',
    styleUrls: ['./treetable.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeTableControl {

    Instance;

    //#region Rows
    RowsValue;

    @Input()
    get Rows() {
        return this.RowsValue;
    }
    set Rows(val) {
        this.RowsValue = val;
        this.checkRows();
        this.RowsChange.emit(this.RowsValue);
    }

    @Output() RowsChange = new EventEmitter<any>();
    //#endregion
    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        this.ColumnsChange.emit(this.ColumnsValue);
    }

    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region AlternatingLineColors
    AlternatingLineColorsValue = false;

    @Input()
    get AlternatingLineColors() {
        return this.AlternatingLineColorsValue;
    }
    set AlternatingLineColors(val) {
        this.AlternatingLineColorsValue = val;
        this.checkRows();
        this.AlternatingLineColorsChange.emit(this.AlternatingLineColorsValue);
    }

    @Output() AlternatingLineColorsChange = new EventEmitter<any>();
    //#endregion

    static setRowStyle(row, indexObj) {
        if (!row.Style) {
            row.Style = {};
        }
        row.Style['background-color'] = (indexObj.Index % 2 === 0) ? 'lightgray' : null;
        indexObj.Index++;
        if (row.Children && row.Children.length > 0 && row.Expanded) {
            row.Children.forEach(x => {
                TreeTableControl.setRowStyle(x, indexObj);
            });
        }
    }

    constructor() {
        this.Instance = this;
    }

    @Output() DataChanged = new EventEmitter<any>();
    OnChange() {
        this.DataChanged.emit();
    }
    
    OnExpand() {
        this.checkRows();
    }

    checkRows() {
        if (this.AlternatingLineColorsValue === true && Array.isArray(this.RowsValue)) {
            const indexObj = { Index: 0 };
            this.RowsValue.forEach(x => {
                TreeTableControl.setRowStyle(x, indexObj);
            });
        }
    }
}
