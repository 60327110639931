import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { AudioHelper } from '../../../helpers/audio.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { SecurityService } from '../../../services/security.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'audio-wf-settings',
    templateUrl: './audio.settings.html'
})
export class AudioSettings extends FormulaWorkflowDialogContent {
    Data = new AudioSettingsData();
    SecurityProviderTypes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'audioWFModule';
        reg.Caption = '@@PlayAudio'
        reg.GroupID = 'wfActions';
        reg.Index = 1;
        reg.SettingsControl = AudioSettings;
        reg.SettingsTypeHelper = new AudioSettingsDataHelper();
        reg.Executer = AudioModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private service: SecurityService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetSecurityProviders().subscribe((types) => {
            if (types) {
                this.SecurityProviderTypes = types;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(AudioSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }
}



export class AudioSettingsData {
    Url: string;
}

export class AudioSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Success';
        return [success];
    }

    getEmptySettingsInstance() {
        return new AudioSettingsData();
    }
}

export class AudioModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const calculator = new WorkflowFormulaCalculator(status);
            if (status.ActualSettings.Url) {
                let url = calculator.CalcFormula(status.ActualSettings.Url);
                AudioHelper.Play(url);
                return 0;
            } else {
                status.Logger.logError('Audio modul: No Url found.');
            }
        } else {
            status.Logger.logError('Audio modul: No settings found.');
        }
        return -1;
    };
}