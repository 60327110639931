import {OnInit, Component, ChangeDetectionStrategy} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {PermissionHelper} from '../../helpers/permissions.helper';
import {GitHelper} from '../../helpers/git.helper';
import {LayoutService} from '../../services/layout.service';
import {ViewType} from '../../models/enums/viewtype.enum';

@Component({
    selector: 'settings-page',
    templateUrl: './settings.page.html',
    styleUrls: ['./settings.page.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsPage implements OnInit {

    Instance;
    NavigationItems;
    SelectedComponent;
    ModelNav;
    RoleNav;
    Style = {
        padding: '10px'
    };

    constructor() {
        this.Instance = this;
        this.NavigationItems = SETTINGS_LIST;
    }

    ngOnInit(): void {
        NavigationService.SelectedPage.next('settings');
        LayoutService.ViewType.next(ViewType.Edit);
        LayoutService.ViewType.next(ViewType.View);
        PermissionHelper.EditPermission.next(false);
    }

    SetComponent(nav, comp) {
        this.SelectedComponent = comp;
        if (nav && typeof nav.UpdateFunction === 'function') {
            nav.UpdateFunction(nav);

        }
    }

    async CheckComponent() {
        if (this.SelectedComponent) {
            if (typeof this.SelectedComponent.checkHasChanges === 'function') {
                return await this.SelectedComponent.checkHasChanges();
            }
        }
        return true;
    }
}


export const SETTINGS_LIST: SettingsEntry[] = [
    {
        Caption: '@@Layout',
        ID: 'layout',
        Icon: '_SYSTEM_LAYOUT',
        Index: 1,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.LayoutRight',
            Value: 1
        },
        Children: []
    },
    {
        Caption: '@@Data',
        ID: 'data',
        Icon: '_SYSTEM_DATA',
        Index: 2,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.DataRight',
            Value: 1
        },
        Children: []
    },
    {
        Caption: '@@Benutzerverwaltung',
        ID: 'userCont',
        Icon: '_SYSTEM_USER_MANAGEMENT',
        Index: 3,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.UsermanagementRight',
            Value: 1
        },
        Children: []
    },
    {
        Caption: '@@CorporateIdentity',
        ID: 'ci',
        Icon: '_SYSTEM_CORP_IDENTITY',
        Index: 4,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
            Value: 1
        },
        Children: []
    },
    {
        Caption: '@@Einstellungen',
        ID: 'settings',
        Icon: '_SYSTEM_SETTINGS',
        Index: 5,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.PageSettingsRight',
            Value: 1
        },
        Children: []
    },
    {
        Caption: '@@About',
        ID: 'about',
        Icon: '_SYSTEM_ABOUT',
        Index: 6,
        Parent: '',
        Security: {
            Name: 'evidanza.App.Shared.Security.AboutRight',
            Value: 1
        },
        Children: []
    }
];

export interface SettingsEntry {
    Caption: string;
    ID: string;
    Icon: string;
    Index: number;
    Parent: string;
    Security?: SettingsEntrySecurity;
    Content?;
    Children?;
    UpdateFunction?;
}

export class SettingsEntrySecurity {
    Name: string;
    Value: number;
}