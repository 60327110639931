import { UUID } from 'angular2-uuid';
import { Aggregation, DimensionType, LevelType } from '../../../models/enums';
import { AttributeUsage, MeasureDataType } from '../../../models/enums/query.enum';

export class Element {
  Caption: string;
  Name: string;
  ID: UUID;
}

export class Member extends Element {
  Key: string;
  Parent: UUID;
  IsAll: boolean;
}

export class Attribute extends Element {
  RelatedAttributeIDs: UUID[] = [];
  Type: LevelType;
  Usage: AttributeUsage;
}

export class Measure extends Element {
  Aggregator: Aggregation;
  DataType: MeasureDataType;
  Expression: string;
  Folder: string;
  Cube: Cube;
}

export class Level extends Element {
  LevelNumber: number;
  LevelType: LevelType;
  MemberCount: number;
  SourceAttributeID: UUID;
  Parent: Hierarchy;
}

export class Hierarchy extends Element {
  HasRollUp: boolean = false;
  IsAttributeHierarchy: boolean = false;
  Levels: Level[] = [];
  Parent: Dimension;
}

export class Dimension extends Element {
  DimensionType: DimensionType = DimensionType.Unknown;
  HasUnknownMember: boolean = false;
  LastProcess: Date;
  LastSchemaUpdate: Date;
  Hierarchies: Hierarchy[] = [];
  Attributes: Attribute[] = [];
  DatasourceID: UUID;
}

export class Cube extends Element {
  CanDrillThrough: boolean;
  Measures: Measure[] = [];
  Dimensions: Dimension[] = [];
  LastProcess: Date;
  LastSchemaUpdate: Date;

  static HasLevel(cube: Cube, level: Level) : boolean {
    let hasLevel = false;
    for (var i = 0; i < cube.Dimensions.length; i++) {
      if (cube.Dimensions[i].ID == level.Parent.Parent.ID) {
        hasLevel = true;
        break;
      }
    }
    return hasLevel;
  }
}

export class Database extends Element {
  Cubes: Cube[] = [];
  Dimensions: Dimension[] = [];
  LastProcess: Date;
  LastSchemaUpdate: Date;
  AdministratorSids: string[];
}
