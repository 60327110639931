import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { SettingsService } from "../../../../../services/settings.service";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'box-panel',
    templateUrl: './box.panel.html',
    styleUrls: ['./box.panel.css']
})
export class BoxPanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = BoxPanel;
    }

    get RedirectUrl(): string {
        var ret = SettingsService.API_BASE_URL.getValue() + 'config/api/media/oauth/authorizebox';
        this.MediaSource.RedirectUrl = ret;
        this.MediaSource.Private = true; 
        return ret;
    }

    set RedirectUrl(val) {
        if (val != this.MediaSource.RedirectUrl) {
            this.MediaSource.RedirectUrl = val;
        }
    }

    authenticate(): void {
        var authstring = 'https://account.box.com/api/oauth2/authorize?response_type=code&state=' + this.MediaSource.SID + '&redirect_uri=' + this.MediaSource.RedirectUrl + '&client_id=' + this.MediaSource.ClientId;
        window.open(authstring, '_blank');
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: BoxPanel,
            MediaType: '42B0D605-AEE4-483C-AA56-9D650986A797'.toLowerCase()
        };
    }
}