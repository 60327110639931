import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';
import { TranslatedString } from '../../models/translatedstring.model';
import {
    ClientWorkflowInfo, ConnectorWorkflowInfo, SelectedWorkflows, ServiceWorkflowInfo, WorkflowDebugSettings
} from '../../models/workflow/workflow.debug.settings';
import { ConnectorService } from '../../services/connector.service';
import { DebugService } from '../../services/debug.service';
import { UsersService } from '../../services/users.service';

@Component({
    selector: 'debug-workflow-settings',
    templateUrl: './debug.workflow.settings.page.html',
    styleUrls: ['./debug.workflow.settings.page.css']
})
export class DebugWorkflowSettingsPage implements OnInit {
    Settings: WorkflowDebugSettings;
    NodesLoading = false;
    WorkflowInfo;
    SelectedWorkflow;
    OnlyWithBreakpoints = false;
    SearchValueToLower = '';
    OriginalSearchValue = '';
    get SearchValue():string {
        return this.OriginalSearchValue;
    }
    set SearchValue(val: string) {
        this.OriginalSearchValue = val;
        this.SearchValueToLower = val.toLowerCase();
        this.UpdateFilterDelayed();
    }
    Delay;

    private static CheckBreakPoints(list) {
        const newList = [];
        if (list) {
            list.forEach(x => {
                const newBreakPoints = [];
                if (x.Breakpoints) {
                    x.Breakpoints.forEach(bp => {
                        if (bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints) {
                            newBreakPoints.push(bp);
                        }
                    });
                }
                if (newBreakPoints.length > 0) {
                    x.Breakpoints = newBreakPoints;
                    newList.push(x);
                }
            });
        }
        return newList;
    }

    constructor(private cdRef: ChangeDetectorRef, private debugService: DebugService, private conService: ConnectorService) {
    }

    ngOnInit(): void {
        WorkflowDebugHelper.DebugSettings.subscribe(x => {
            if (x) {
                this.Settings = JSON.parse(JSON.stringify(x));
            } else {
                this.Settings = null;
            }
            this.SelectedWorkflow = null;
            this.onSettingsChanged();            
        });
        UsersService.ActiveUser.subscribe(x => {
            if (x) {
                this.refreshTree();
            }
        });
    }

    refresh() {
        this.OriginalSearchValue = '';
        this.SearchValueToLower = '';
        this.OnlyWithBreakpoints = false;
        const settings = WorkflowDebugHelper.DebugSettings.getValue();
        if (settings) {
            this.Settings = JSON.parse(JSON.stringify(settings));
        } else {
            this.Settings = null;
        }
        this.refreshTree();
    }

    apply() {
        if (this.Settings && this.Settings.Workflows) {
            if (this.Settings.Workflows.ServiceWorkflows) {
                this.Settings.Workflows.ServiceWorkflows =
                    DebugWorkflowSettingsPage.CheckBreakPoints(this.Settings.Workflows.ServiceWorkflows);
            }
            if (this.Settings.Workflows.ClientWorkflows) {
                this.Settings.Workflows.ClientWorkflows =
                    DebugWorkflowSettingsPage.CheckBreakPoints(this.Settings.Workflows.ClientWorkflows);
            }
            if (this.Settings.Workflows.ConnectorWorkflows) {
                this.Settings.Workflows.ConnectorWorkflows =
                    DebugWorkflowSettingsPage.CheckBreakPoints(this.Settings.Workflows.ConnectorWorkflows);
            }
        }
        WorkflowDebugHelper.UpdateSettings(this.Settings);
    }

    refreshTree() {
        this.NodesLoading = true;
        this.SelectedWorkflow = null;
        this.debugService.GetAllWorkflows().subscribe(x => {
            this.WorkflowInfo = x;
            this.NodesLoading = false;
            this.onSettingsChanged();
        });
    }

    clearAllBreakpoints() {
        if (this.Settings) {
            this.Settings.Workflows = new SelectedWorkflows();
            this.onSettingsChanged();
        }
    }

    onServiceWFSelection(wf) {
        this.SelectedWorkflow = null;
        this.debugService.GetWorkflow(wf.Id, 'service').subscribe(x => {
            if (x) {
                let settings: ServiceWorkflowInfo;
                if (this.Settings) {
                    if (!this.Settings.Workflows) {
                        this.Settings.Workflows = new SelectedWorkflows();
                    }
                    if (this.Settings.Workflows.ServiceWorkflows) {
                        settings = this.Settings.Workflows.ServiceWorkflows.find(x => x.ID === wf.Id);
                    } else {                        
                        this.Settings.Workflows.ServiceWorkflows = [];
                    }
                    if (!settings) {
                        settings = new ServiceWorkflowInfo()
                        settings.ID = wf.Id;
                        this.Settings.Workflows.ServiceWorkflows.push(settings);
                    }
                }
                this.SelectedWorkflow = {
                    IsService: true,
                    Workflow: x,
                    Caption: TranslatedString.GetTranslation(x.Caption),
                    Settings: settings
                };
            }
            this.cdRef.detectChanges();
        });
    }

    onClientWFSelection(pi, wf) {
        this.SelectedWorkflow = null;
        this.debugService.GetWorkflow(wf.SID, pi.SID).subscribe(x => {
            if (x) {
                let settings: ClientWorkflowInfo;
                if (this.Settings) {
                    if (!this.Settings.Workflows) {
                        this.Settings.Workflows = new SelectedWorkflows();
                    }
                    if (this.Settings.Workflows.ClientWorkflows) {
                        settings = this.Settings.Workflows.ClientWorkflows.find(x => x.ID === wf.SID && x.URL === pi.URL);
                    } else {
                        this.Settings.Workflows.ClientWorkflows = [];
                    }
                    if (!settings) {
                        settings = new ClientWorkflowInfo()
                        settings.ID = wf.SID;
                        settings.URL = pi.URL;
                        settings.PageSID = pi.SID;
                        this.Settings.Workflows.ClientWorkflows.push(settings);
                    }
                }
                this.SelectedWorkflow = {
                    IsService: false,
                    Workflow: x.Data,
                    Caption: x.Caption,
                    Settings: settings
                };
            }
            this.cdRef.detectChanges();
        });
    }

    onConnectorWFSelection(cmi, before) {
        this.SelectedWorkflow = null;
        this.conService.GetConnectorWorkflow(cmi.ConnectorID, before).subscribe(x => {
            if (x) {
                let settings: ConnectorWorkflowInfo;
                if (this.Settings) {
                    if (!this.Settings.Workflows) {
                        this.Settings.Workflows = new SelectedWorkflows();
                    }
                    const wfId = before ? cmi.BeforeExecute.WorkflowID : cmi.AfterExecute.WorkflowID;
                    if (this.Settings.Workflows.ConnectorWorkflows) {
                        settings = this.Settings.Workflows.ConnectorWorkflows.find(x => x.ConnectorID == cmi.ConnectorID && x.ID == wfId);
                    } else {
                        this.Settings.Workflows.ConnectorWorkflows = [];
                    }
                    if (!settings) {
                        settings = new ConnectorWorkflowInfo()
                        settings.ID = wfId;
                        settings.ConnectorID = cmi.ConnectorID;
                        this.Settings.Workflows.ConnectorWorkflows.push(settings);
                    }
                }
                this.SelectedWorkflow = {
                    IsService: true,
                    Workflow: x,
                    Caption: TranslatedString.GetTranslation(x.Caption),
                    Settings: settings
                };
            }
            this.cdRef.detectChanges();
        });
    }

    onConnectorMappingSelection(cmi, cci, oi) {
        this.SelectedWorkflow = null;
        this.conService.GetWorkflowMapping(oi.MappingID).subscribe(x => {
            if (x && x.Workflow) {
                let settings: ConnectorWorkflowInfo;
                if (this.Settings) {
                    if (!this.Settings.Workflows) {
                        this.Settings.Workflows = new SelectedWorkflows();
                    }
                    if (this.Settings.Workflows.ConnectorWorkflows) {
                        settings = this.Settings.Workflows.ConnectorWorkflows.find(x => x.ConnectorID == cmi.ConnectorID &&
                            x.ContainerID == cci.ContainerID && x.ID == oi.WorkflowID);
                    } else {
                        this.Settings.Workflows.ConnectorWorkflows = [];
                    }
                    if (!settings) {
                        settings = new ConnectorWorkflowInfo()
                        settings.ID = oi.WorkflowID;
                        settings.ConnectorID = cmi.ConnectorID;
                        settings.ContainerID = cci.ContainerID;
                        this.Settings.Workflows.ConnectorWorkflows.push(settings);
                    }
                }
                this.SelectedWorkflow = {
                    IsService: true,
                    Workflow: x.Workflow,
                    Caption: TranslatedString.GetTranslation(x.Workflow.Caption),
                    Settings: settings
                };
            }
            this.cdRef.detectChanges();
        });
    }

    onSettingsChanged() {
        if (this.WorkflowInfo) {
            if (this.Settings && this.Settings.Workflows) {
                if (this.WorkflowInfo.ServiceWorkflows) {
                    this.WorkflowInfo.ServiceHasSettings = false;
                    if (this.Settings.Workflows.ServiceWorkflows && this.Settings.Workflows.ServiceWorkflows.length > 0) {
                        this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                            const s = this.Settings.Workflows.ServiceWorkflows.find(y => y.ID === x.Id);
                            if (s && s.Breakpoints && s.Breakpoints.some(bp => bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints)) {
                                x.HasSettings = true;
                                this.WorkflowInfo.ServiceHasSettings = true;
                            } else {
                                x.HasSettings = false;
                            }
                        });
                    } else {
                        this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                            x.HasSettings = false;
                        });
                    }
                }
                if (this.WorkflowInfo.ClientWorkflows) {
                    this.WorkflowInfo.ClientHasSettings = false;
                    if (this.Settings.Workflows.ClientWorkflows && this.Settings.Workflows.ClientWorkflows.length > 0) {
                        this.WorkflowInfo.ClientWorkflows.forEach(x => {
                            x.HasSettings = false;
                            x.Workflows.forEach(y => {
                                const s = this.Settings.Workflows.ClientWorkflows.find(z => z.ID === y.SID);
                                if (s && s.Breakpoints && s.Breakpoints.some(bp => bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints)) {
                                    y.HasSettings = true;
                                    x.HasSettings = true;
                                    this.WorkflowInfo.ClientHasSettings = true;
                                } else {
                                    y.HasSettings = false;
                                }
                            });
                        });
                    } else {
                        this.WorkflowInfo.ClientWorkflows.forEach(x => {
                            x.HasSettings = false;
                            x.Workflows.forEach(y => {
                                y.HasSettings = false;
                            });
                        });
                    }
                }
                if (this.WorkflowInfo.ConnectorWorkflows) {
                    this.WorkflowInfo.ConnectorHasSettings = false;
                    const cw = this.Settings.Workflows.ConnectorWorkflows;
                    if (cw && cw.length > 0) {
                        this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                            x.HasSettings = false;
                            if (x.BeforeExecute) {
                                const s = cw.find(y => y.ConnectorID === x.ConnectorID && y.ID === x.BeforeExecute.WorkflowID);
                                if (s && s.Breakpoints && s.Breakpoints.some(bp => bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints)) {
                                    x.BeforeExecute.HasSettings = true;
                                    x.HasSettings = true;
                                    this.WorkflowInfo.ConnectorHasSettings = true;
                                } else {
                                    x.BeforeExecute.HasSettings = false;
                                }
                            }
                            if (x.AfterExecute) {
                                const s = cw.find(y => y.ConnectorID === x.ConnectorID && y.ID === x.AfterExecute.WorkflowID);
                                if (s && s.Breakpoints && s.Breakpoints.some(bp => bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints)) {
                                    x.AfterExecute.HasSettings = true;
                                    x.HasSettings = true;
                                    this.WorkflowInfo.ConnectorHasSettings = true;
                                } else {
                                    x.AfterExecute.HasSettings = false;
                                }
                            }
                            if (x.Containers) {
                                x.Containers.forEach(con => {
                                    con.HasSettings = false;
                                    if (con.Operations) {
                                        con.Operations.forEach(op => {
                                            const s = cw.find(y => y.ConnectorID === x.ConnectorID &&
                                                y.ContainerID == con.ContainerID && y.ID === op.WorkflowID);
                                            if (s && s.Breakpoints && s.Breakpoints.some(bp => bp.BreakOnStart || bp.BreakOnEnd || bp.AdditionalBreakPoints)) {
                                                op.HasSettings = true;
                                                con.HasSettings = true;
                                                x.HasSettings = true;
                                                this.WorkflowInfo.ConnectorHasSettings = true;
                                            } else {
                                                op.HasSettings = false;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            } else {
                if (this.WorkflowInfo.ServiceWorkflows) {
                    this.WorkflowInfo.ServiceHasSettings = false;
                    this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                        x.HasSettings = false;
                    });
                }
                if (this.WorkflowInfo.ClientWorkflows) {
                    this.WorkflowInfo.ClientHasSettings = false;
                    this.WorkflowInfo.ClientWorkflows.forEach(x => {
                        x.HasSettings = false;
                        x.Workflows.forEach(y => {
                            y.HasSettings = false;
                        });
                    });
                }
                if (this.WorkflowInfo.ConnectorWorkflows) {
                    this.WorkflowInfo.ConnectorHasSettings = false;
                    this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                        x.HasSettings = false;
                        if (x.BeforeExecute) {
                            x.BeforeExecute.HasSettings = false;
                        }
                        if (x.AfterExecute) {
                            x.AfterExecute.HasSettings = false;
                        }
                        if (x.Containers) {
                            x.Containers.forEach(con => {
                                con.HasSettings = false;
                                if (con.Operations) {
                                    con.Operations.forEach(op => {
                                        op.HasSettings = false;
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }
        this.UpdateFilter();
    }

    UpdateFilterDelayed() {
        clearTimeout(this.Delay);
        this.Delay = setTimeout(() => { this.UpdateFilter(); }, 500);
    }

    UpdateFilter() {
        if (this.WorkflowInfo) {
            if (this.OnlyWithBreakpoints) {
                if (this.SearchValueToLower) {
                    if (this.WorkflowInfo.ServiceWorkflows) {
                        this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                            x.Visible = x.HasSettings && x.Name.toLowerCase().indexOf(this.SearchValueToLower) > -1;
                        });
                    }
                    if (this.WorkflowInfo.ClientWorkflows) {
                        this.WorkflowInfo.ClientWorkflows.forEach(x => {
                            x.Visible = false;
                            if (x.Caption.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                x.Workflows.forEach(y => {
                                    if (y.HasSettings) {
                                        y.Visible = true;
                                        x.Visible = true;
                                    } else {
                                        y.Visible = false;
                                    }
                                });
                            } else {
                                x.Workflows.forEach(y => {
                                    if (y.HasSettings && x.Caption.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                        y.Visible = true;
                                        x.Visible = true;
                                    } else {
                                        y.Visible = false;
                                    }
                                });
                            }
                        });
                    }
                    if (this.WorkflowInfo.ConnectorWorkflows) {
                        this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                            x.Visible = false;
                            if (x.ConnectorName.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                if (x.BeforeExecute) {
                                    if (x.BeforeExecute.HasSettings) {
                                        x.BeforeExecute.Visible = true;
                                        x.Visible = true;
                                    } else {
                                        x.BeforeExecute.Visible = false;
                                    }
                                }
                                if (x.AfterExecute) {
                                    if (x.AfterExecute.HasSettings) {
                                        x.AfterExecute.Visible = true;
                                        x.Visible = true;
                                    } else {
                                        x.AfterExecute.Visible = false;
                                    }
                                }
                                if (x.Containers) {
                                    x.Containers.forEach(con => {
                                        con.Visible = false;
                                        if (con.Operations) {
                                            con.Operations.forEach(op => {
                                                if (op.HasSettings) {
                                                    op.Visible = true;
                                                    con.Visible = true;
                                                    x.Visible = true;
                                                } else {
                                                    op.Visible = false;
                                                }
                                            });
                                        }
                                    });
                                }
                            } else {
                                if (x.Containers) {
                                    x.Containers.forEach(con => {
                                        con.Visible = false;
                                        if (con.Operations && con.ContainerName.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                            con.Operations.forEach(op => {
                                                if (op.HasSettings) {
                                                    op.Visible = true;
                                                    con.Visible = true;
                                                    x.Visible = true;
                                                } else {
                                                    op.Visible = false;
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                } else {
                    if (this.WorkflowInfo.ServiceWorkflows) {
                        this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                            x.Visible = x.HasSettings;
                        });
                    }
                    if (this.WorkflowInfo.ClientWorkflows) {
                        this.WorkflowInfo.ClientWorkflows.forEach(x => {
                            x.Visible = false;
                            x.Workflows.forEach(y => {
                                if (y.HasSettings) {
                                    y.Visible = true;
                                    x.Visible = true;
                                } else {
                                    y.Visible = false;
                                }
                            });
                        });
                    }
                    if (this.WorkflowInfo.ConnectorWorkflows) {
                        this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                            x.Visible = false;
                            if (x.BeforeExecute) {
                                if (x.BeforeExecute.HasSettings) {
                                    x.BeforeExecute.Visible = true;
                                    x.Visible = true;
                                } else {
                                    x.BeforeExecute.Visible = false;
                                }
                            }
                            if (x.AfterExecute) {
                                if (x.AfterExecute.HasSettings) {
                                    x.AfterExecute.Visible = true;
                                    x.Visible = true;
                                } else {
                                    x.AfterExecute.Visible = false;
                                }
                            }
                            if (x.Containers) {
                                x.Containers.forEach(con => {
                                    con.Visible = false;
                                    if (con.Operations) {
                                        con.Operations.forEach(op => {
                                            if (op.HasSettings) {
                                                op.Visible = true;
                                                con.Visible = true;
                                                x.Visible = true;
                                            } else {
                                                op.Visible = false;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            } else if (this.SearchValueToLower) {
                if (this.WorkflowInfo.ServiceWorkflows) {
                    this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                        x.Visible = x.Name.toLowerCase().indexOf(this.SearchValueToLower) > -1;
                    });
                }
                if (this.WorkflowInfo.ClientWorkflows) {
                    this.WorkflowInfo.ClientWorkflows.forEach(x => {
                        if (x.Caption.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                            x.Visible = true;
                            x.Workflows.forEach(y => {
                                y.Visible = true;
                            });
                        } else {
                            x.Visible = false;
                            x.Workflows.forEach(y => {
                                if (y.Caption.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                    y.Visible = true;
                                    x.Visible = true;
                                } else {
                                    y.Visible = false;
                                }
                            });
                        }
                    });
                }
                if (this.WorkflowInfo.ConnectorWorkflows) {
                    this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                        if (x.ConnectorName.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                            x.Visible = true;
                            if (x.BeforeExecute) {
                                x.BeforeExecute.Visible = true;
                            }
                            if (x.AfterExecute) {
                                x.AfterExecute.Visible = true;
                            }
                            if (x.Containers) {
                                x.Containers.forEach(con => {
                                    if (con.Operations) {
                                        con.Visible = true;
                                        con.Operations.forEach(op => {
                                            op.Visible = true;
                                        });
                                    } else {
                                        con.Visible = false;
                                    }
                                });
                            }
                        } else {
                            x.Visible = false;
                            if (x.Containers) {
                                x.Containers.forEach(con => {
                                    if (con.Operations && con.ContainerName.toLowerCase().indexOf(this.SearchValueToLower) > -1) {
                                        x.Visible = true;
                                        con.Visible = true;
                                        con.Operations.forEach(op => {
                                            op.Visible = true;
                                        });
                                    } else {
                                        con.Visible = false;
                                    }
                                });
                            }
                        }
                    });
                }
            } else {
                if (this.WorkflowInfo.ServiceWorkflows) {
                    this.WorkflowInfo.ServiceWorkflows.forEach(x => {
                        x.Visible = true;
                    });
                }
                if (this.WorkflowInfo.ClientWorkflows) {
                    this.WorkflowInfo.ClientWorkflows.forEach(x => {
                        x.Visible = true;
                        x.Workflows.forEach(y => {
                            y.Visible = true;
                        });
                    });
                }
                if (this.WorkflowInfo.ConnectorWorkflows) {
                    this.WorkflowInfo.ConnectorWorkflows.forEach(x => {
                        x.Visible = true;
                        if (x.BeforeExecute) {
                            x.BeforeExecute.Visible = true;
                        }
                        if (x.AfterExecute) {
                            x.AfterExecute.Visible = true;
                        }
                        if (x.Containers) {
                            x.Containers.forEach(con => {
                                if (con.Operations) {
                                    con.Visible = true;
                                    con.Operations.forEach(op => {
                                        op.Visible = true;
                                    });
                                } else {
                                    con.Visible = false;
                                }
                            });
                        }
                    });
                }
            }
        }
        this.cdRef.detectChanges();
    }
}
