import {
    AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { UUID } from 'angular2-uuid';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { Level, Measure } from '../../../cache/multi/models/database.model';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { FilterHelper } from '../../../helpers/filter.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { FormulaNodeCalculator } from '../../../models/calculation/formula.node.calculator';
import { AxisNode } from '../../../models/datadescription/multi/multiresult.model';
import {
    AxisDef, LevelDef, MeasureDef, QueryDef, WhereDef, WhereOptions, WhereSelects
} from '../../../models/datadescription/multi/querydef.model';
import { Aggregation, DimensionType } from '../../../models/enums';
import { AxisType } from '../../../models/enums/query.enum';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { MultiReportingService, ReportingService } from '../../../services/reporting.service';
import { ABaseTreeNode, BaseTreeControl, IBaseTreeNode, IDKeeper, ITreeLogic } from '../../common/basetreecontrol/base.tree.control';
import { IBaseComponent } from '../base.component';
import { FromToFilter } from './from.to.filter';

@Component({
    selector: 'filter-tree-component',
    templateUrl: './filter.tree.control.html'
})
export class FilterTreeComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('treeControl') treeControl: BaseTreeControl;
    Logic;
    RootNodes = [];
    FilterSelection = [];
    Initialized = false;

    //#region Settings
    SettingsValue;
    @Input()
    get Settings() {
        return this.SettingsValue;
    }
    set Settings(val) {
        if (val !== this.SettingsValue) {
            this.SettingsValue = val;
            this.InitLogic();
            this.SettingsChange.emit(this.SettingsValue);
        }
    }
    @Output() SettingsChange = new EventEmitter<any>();
    //#endregion

    @Output() SelectionChange = new EventEmitter<any>();
    @Output() RootNodesFilled = new EventEmitter<boolean>();

    LanguageSub;

    private static updateCaption(nodes, codeDesc) {
        if (nodes) {
            nodes.forEach(node => {
                node.updateCaption(codeDesc);
                FilterTreeComponent.updateCaption(node.Children, codeDesc);
            });
        }
    }

    private static deselect(nodes) {
        if (nodes) {
            nodes.forEach(node => {
                node.Selected = false;
                FilterTreeComponent.deselect(node.Children);
            });
        }
    }

    constructor(private relService: ReportingService, private service: MultiReportingService) {
    }

    ngOnInit(): void {
        this.LanguageSub = LanguageService.LanguageChanged.subscribe(() => {
            this.RefreshLogic();
        });
    }

    RefreshLogic() {
        if (this.Logic instanceof MultidimensionalTreeLogic) {
            this.Logic.refresh().then(x => {
                this.RootNodes = x;
                this.RootNodesFilled.emit(true);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.LanguageSub) {
            this.LanguageSub.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.Initialized = true;
        this.InitLogic();
    }

    InitLogic() {
        this.Logic = null;
        this.RootNodes = [];
        this.FilterSelection = [];
        this.RootNodesFilled.emit(false);
        if (this.Initialized) {
            const le = this.SettingsValue;
            if (le) {
                if (Array.isArray(le.SavedSelection)) {
                    this.FilterSelection = [...le.SavedSelection];
                }
                if (le.Relational) {
                    if (le.Container && le.Field && le.DataSource) {
                        const query = {
                            Filters: [],
                            Sort: [],
                            Columns: [{
                                FieldID: le.Field,
                                ContainerID: le.Container,
                                DatasourceID: le.DataSource
                            }],
                            AdditionalColumns: [],
                            Relations: [],
                            Distinct: true
                        };
                        if (typeof le.Sort === 'number') {
                            query.Sort.push({
                                FieldID: le.Field,
                                ContainerID: le.Container,
                                DatasourceID: le.DataSource,
                                Order: le.Sort
                            });
                        }
                        this.relService.ExecuteQuery(query).subscribe(x => {
                            const retVal = [];
                            if (x && x.length > 0) {
                                const keys = Object.keys(x[0]);
                                if (keys.length > 0) {
                                    const col = keys[0];
                                    let index = 0;
                                    x.forEach(row => {
                                        const node = new FilterTreeNode(index++);
                                        node.Caption = row[col];
                                        retVal.push(node);
                                    });
                                }
                            }
                            this.RootNodes = retVal;
                            this.RootNodesFilled.emit(true);
                        });
                    }
                } else {
                    this.Logic = new MultidimensionalTreeLogic(this.service);
                    this.Logic.initialize(le).then(x => {
                        if (x) {
                            this.RootNodes = x;
                            this.RootNodesFilled.emit(true);
                        }
                    });
                }
            }
        }
    }

    onSingleSelect(ev) {
        if (this.SettingsValue && !this.SettingsValue.MultiSelect) {
            this.FilterSelection.splice(0);
            if (ev && ev.length > 0) {
                if (this.SettingsValue.Relational) {
                    this.FilterSelection.push(ev[0].Caption);
                } else {
                    const first = ev[0];
                    this.FilterSelection.push({
                        LevelId: first.LevelID,
                        MemberIds: [first.MemberID],
                        MemberCaptions: [first.Caption]
                    });
                }
            }
            this.fireSelect();
        }
    }

    onMultiSelect(ev, node) {
        if (ev && this.SettingsValue) {
            if (this.SettingsValue.Relational) {
                if (ev.checked) {
                    this.FilterSelection.push(node.Caption);
                    this.fireSelect();
                } else {
                    const index = this.FilterSelection.indexOf(node.Caption);
                    if (index > -1) {
                        this.FilterSelection.splice(index, 1);
                        this.fireSelect();
                    }
                }
            } else {
                if (ev.checked) {
                    let levItem = this.FilterSelection.find(x => x.LevelId === node.LevelID);
                    if (!levItem) {
                        levItem = {
                            LevelId: node.LevelID,
                            MemberIds: [],
                            MemberCaptions: []
                        };
                        this.FilterSelection.push(levItem);
                    }
                    levItem.MemberIds.push(node.MemberID);
                    levItem.MemberCaptions.push(node.Caption);
                    this.fireSelect();
                } else {
                    this.FilterSelection.some((levItem, i) => {
                        if (levItem.LevelId === node.LevelID) {
                            if (levItem.MemberIds) {
                                const index = levItem.MemberIds.indexOf(node.MemberID);
                                if (index > -1) {
                                    levItem.MemberIds.splice(index, 1);
                                    levItem.MemberCaptions.splice(index, 1);
                                    if (levItem.MemberIds.length === 0) {
                                        this.FilterSelection.splice(i, 1);
                                    }
                                    this.fireSelect();
                                }
                            } else {
                                this.FilterSelection.splice(i, 1);
                            }
                            return true;
                        }
                        return false;
                    });
                }
            }
        }
    }

    fireSelect() {
        if (this.Logic) {
            this.Logic.WhereSelects = [...this.FilterSelection];
        }
        this.SelectionChange.emit([...this.FilterSelection]);
    }

    codeDescChanged() {
        if (this.SettingsValue && !this.SettingsValue.Relational) {
            FilterTreeComponent.updateCaption(this.RootNodes, this.SettingsValue.CodeDesc);
        }
    }

    clearSelection() {
        if (this.SettingsValue && this.SettingsValue.MultiSelect) {
            FilterTreeComponent.deselect(this.RootNodes);
        } else if (this.treeControl) {
            this.treeControl.SelectedNode = null;
        }
        this.FilterSelection.splice(0);
        this.fireSelect();
    }
}

@Component({
    selector: 'filter-tree-control',
    template: '<filter-tree-component #treeControl [Settings]="LayoutElement" (SelectionChange)="onSelectionChange($event)"></filter-tree-component>'
})
export class FilterTreeControl extends IBaseComponent {
    static Type = 'filtertree';
    static Default = {
        Caption: 'FilterTree',
        Type: 'filtertree',
        Layout: {
            Height: {Type: 0, Value: 250},
            Width: {Type: 0, Value: 250},
        }
    };

    @ViewChild('treeControl') treeControl: FilterTreeComponent;

    static updateVariables(layoutElement, layout, selection: any[]) {
        if (layoutElement.FilterVariables && layoutElement.FilterVariables.length > 0) {
            let value;
            if (selection) {
                if (layoutElement.Relational && !layoutElement.MultiSelect) {
                    if (selection.length > 0) {
                        value = selection[0];
                    }
                } else {
                    value = [...selection];
                }
            }
            FromToFilter.FillVariables(layoutElement.FilterVariables, value, layout);
        }
    }

    static updateSavedSelection(layoutElement, selection: any[]) {
        if (layoutElement.SaveSelection) {
            layoutElement.SavedSelection = [...selection];
        } else {
            layoutElement.SavedSelection = [];
        }
    }

    static GetDynamicTimeInit(le, layout): Promise<WhereSelects> {
        return new Promise<WhereSelects>(resolve => {
            if (le && le.DynamicTime && !le.Relational && !le.SaveSelection
                && le.DataSource && le.Hierarchy && le.Levels && le.Levels.length > 0) {
                MultiCacheService.GetHierarchy(le.Hierarchy, le.DataModel).then(hier => {
                    if (hier && hier.Parent && hier.Parent.DimensionType == DimensionType.Time) {
                        let level;
                        le.Levels.forEach(x => {
                            const actLevel = hier.Levels.find(hl => hl.ID === x);
                            if (actLevel && (!level || level.LevelNumber < actLevel.LevelNumber)) {
                                level = actLevel;
                            }
                        });
                        if (level) {
                            const service = InjectorHelper.InjectorInstance.get(MultiReportingService);
                            service.GetMemberFromDynamicTimePoint(le.DynamicTime, le.DataSource, level.ID).subscribe(member => {
                                if (member) {
                                    const ws = new WhereSelects();
                                    ws.LevelId = level.ID;
                                    ws.MemberIds = [member.ID];
                                    ws["MemberCaptions"] = [member.Caption];
                                    resolve(ws);
                                } else {
                                    resolve(null);
                                }
                            }, () => resolve(null));
                            return;
                        }
                    }
                    resolve(null);
                }, () => resolve(null));
            } else {
                resolve(null);
            }
        });
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('selectionChanged');
    }

    ControlInitialized() {
        if (this.LayoutElementValue.SaveSelection && Array.isArray(this.LayoutElementValue.SavedSelection)) {
            FilterTreeControl.updateVariables(this.LayoutElementValue, this.LayoutValue, this.LayoutElementValue.SavedSelection);
        }
    }

    setInitialized() {
        FilterTreeControl.GetDynamicTimeInit(this.LayoutElementValue, this.LayoutValue).then(x => {
            if (x) {
                this.LayoutElementValue.SavedSelection = [x];
                FilterTreeControl.updateVariables(this.LayoutElementValue, this.LayoutValue, this.LayoutElementValue.SavedSelection);
                this.onLayoutElementChanged();
            }
            super.setInitialized();
        });
    }

    onSelectionChange(ev) {
        FilterTreeControl.updateVariables(this.LayoutElementValue, this.LayoutValue, ev);
        FilterTreeControl.updateSavedSelection(this.LayoutElementValue, ev);
        this.triggerEvent('selectionChanged', ev);
    }

    codeDescChanged() {
        if (this.treeControl) {
            this.treeControl.codeDescChanged();
        }
    }

    onLayoutElementChanged() {
        if (this.treeControl) {
            this.treeControl.InitLogic();
        }
    }
}

export class MultidimensionalTreeLogic implements ITreeLogic {

    WhereSelects: WhereSelects[];
    DataModel;
    Datasource;
    LevelList;
    CodeDesc = 0;
    ConditionalFilter;
    LayoutElement;
    private _IdKeeper = new IDKeeper();

    static ExecuteDependendFilter(level: Level, conditionalFilter, dataModel, additionalFilter: WhereDef[],
        layoutElement, service: MultiReportingService) {
        return new Promise<AxisNode[]>((resolve, reject) => {
            MultiCacheService.GetCube(conditionalFilter.Cube, dataModel).then(c => {
                const definition = new QueryDef();
                definition.Cube = c;
                definition.ID = UUID.UUID();
                definition.DataModell = dataModel;
                definition.MeasureOnAxis = AxisType.X_Axis;

                let uid = 0;

                const mAxisDef = new AxisDef();
                mAxisDef.AreaID = uid++;
                mAxisDef.Type = AxisType.Measure;
                mAxisDef.HasMeasures = true;
                mAxisDef.ShowAll = true;
                definition.Axis.push(mAxisDef);

                let measureList: Measure[] = [];
                if (conditionalFilter.Measures && conditionalFilter.Measures.length > 0) {
                    measureList = c.Measures.filter(m => conditionalFilter.Measures.some(cfm => cfm === m.ID));
                }
                if (measureList.length === 0) {
                    measureList = c.Measures;
                }
                measureList.forEach(m => {
                    const mi = new MeasureDef();
                    mi.Caption = m.Caption;
                    mi.ASFunctionType = Aggregation.Sum;
                    mi.Measure = m;
                    mi.UniqueID = uid++;
                    definition.Measures.push(mi);
                });

                const axisdef = new AxisDef();
                axisdef.AreaID = uid++;
                axisdef.Type = AxisType.Y_Axis;
                //axisdef.ShowAll = true;

                const leveldef = new LevelDef();
                leveldef.Level = level;
                leveldef.Hierarchy = level.Parent;
                leveldef.Dimension = level.Parent.Parent;
                leveldef.UniqueID = uid++;

                axisdef.Levels.push(leveldef);
                definition.Axis.push(axisdef);
                definition.HeterogenPosition.set(AxisType.Y_Axis, axisdef.AreaID);
                
                if (additionalFilter) {
                    definition.Where.push(...additionalFilter);
                }

                if (conditionalFilter.Filter) {
                    conditionalFilter.Filter.forEach(f => {
                        const options = new WhereOptions();
                        options.IsOptional = true;
                        const wd = new WhereDef();
                        wd.DimensionId = f.Dimension;
                        wd.HierarchyId = f.Hierarchy;
                        wd.Formula = f.Formula;
                        wd.Options = options;
                        wd.Defaults = f.Defaults;
                        definition.Where.push(wd);
                    });
                }

                if (layoutElement) {
                    const variableList = FilterHelper.GetVariables(layoutElement);
                    variableList.forEach(x => {
                        const vat = FormulaNodeCalculator.GetVariableValue(x, false).Value;
                        if (typeof vat !== 'undefined' && vat !== null) {
                            definition.Variables[x.Name] = vat;
                        }
                    });
                }

                service.ExecuteQuery(dataModel, definition).toPromise().then(x => {
                    if (x && x.YAxis && x.YAxis.Nodes) {
                        resolve(x.YAxis.Nodes);
                    } else {
                        resolve([]);
                    }
                });
            });
        });
    }

    constructor(private service: MultiReportingService) {
    }

    initialize(filterElementSetting): Promise<IBaseTreeNode[]> {
        const promise = new Promise<IBaseTreeNode[]>((resolve, reject) => {
            this.LayoutElement = filterElementSetting;
            if (filterElementSetting && filterElementSetting.DataModel && filterElementSetting.DataSource
                && filterElementSetting.Hierarchy && filterElementSetting.Levels) {
                if (Array.isArray(filterElementSetting.SavedSelection)) {
                    this.WhereSelects = [...filterElementSetting.SavedSelection];
                }
                if (filterElementSetting.ConditionalFilter) {
                    this.ConditionalFilter = filterElementSetting.ConditionalFilter;
                }
                this.Datasource = filterElementSetting.DataSource;
                this.DataModel = filterElementSetting.DataModel;
                if (typeof filterElementSetting.CodeDesc === 'number') {
                    this.CodeDesc = filterElementSetting.CodeDesc;
                }
                MultiCacheService.GetHierarchy(filterElementSetting.Hierarchy, filterElementSetting.DataModel).then(hier => {
                    if (hier) {
                        const levels = [];
                        hier.Levels.forEach(l => {
                            if (filterElementSetting.Levels.some(ll => ll === l.ID)) {
                                levels.push(l);
                            }
                        });
                        this.LevelList = levels;
                        if (levels.length > 0) {
                            this.loadChildren(new MultiTreeNode(-1)).then(children => {
                                resolve(children);
                            });
                        } else {
                            resolve([]);
                        }
                    } else {
                        resolve([]);
                    }
                }, () => { resolve([]); });
            } else {
                resolve([]);
            }
        });
        return promise;
    }

    refresh(): Promise<IBaseTreeNode[]> {
        return this.loadChildren(new MultiTreeNode(-1));
    }

    loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]> {
        const promise = new Promise<IBaseTreeNode[]>((resolve, reject) => {
            if (node instanceof MultiTreeNode) {
                if (node.Depth < this.LevelList.length) {
                    const nodeLevel = this.LevelList[node.Depth];
                    if (this.ConditionalFilter) {
                        // Query zusammenbauen
                        const additionalFilter = [];
                        let parent = node;
                        while (parent) {
                            if (parent.Depth === 0) {
                                break;
                            }
                            const wd = new WhereDef();
                            wd.DimensionId = nodeLevel.Parent.Parent.ID;
                            wd.HierarchyId = nodeLevel.Parent.ID;
                            wd.Options = new WhereOptions();
                            const sel = new WhereSelects();
                            sel.LevelId = parent.LevelID;
                            sel.MemberIds = [parent.MemberID];
                            wd.Defaults = [sel];
                            additionalFilter.splice(0, 0, wd);
                            parent = parent.Parent;
                        }
                        MultidimensionalTreeLogic.ExecuteDependendFilter(nodeLevel, this.ConditionalFilter, this.DataModel,
                            additionalFilter, this.LayoutElement, this.service).then(x => {
                            const retVal = [];
                            const nextIndex = node.Depth + 1;
                            const hasChildren = nextIndex < this.LevelList.length;
                            x.forEach(axisNode => {
                                if (axisNode.Position !== -1 && axisNode.Visible !== false) {
                                    const memberNode = new MultiTreeNode(this._IdKeeper.NextID);
                                    memberNode.Parent = node;
                                    memberNode.MemberKey = axisNode.Key;
                                    memberNode.LevelID = nodeLevel.ID;
                                    memberNode.MemberCaption = axisNode.Caption;
                                    memberNode.updateCaption(this.CodeDesc);
                                    memberNode.MemberID = axisNode.MemberId;
                                    memberNode.Depth = nextIndex;
                                    memberNode.HasChildren = hasChildren;
                                    if (this.WhereSelects) {
                                        this.WhereSelects.some(ws => {
                                            if (ws.LevelId === memberNode.LevelID) {
                                                if (ws.MemberIds.includes(axisNode.MemberId)) {
                                                    memberNode.Selected = true;
                                                    return true;
                                                }
                                            }
                                            return false;
                                        });
                                    }
                                    retVal.push(memberNode);
                                }
                            });
                            if (this.LayoutElement && typeof this.LayoutElement.Sort === 'number') {
                                ArrayHelpers.sortAlphabetical(retVal, 'Caption');
                                if (this.LayoutElement.Sort === 1) {
                                    retVal.reverse();
                                }
                            }
                            resolve(retVal);
                        });
                    } else {
                        this.service.GetMembers(this.Datasource, nodeLevel.ID, node.MemberID, node.LevelID).subscribe(members => {
                            const retVal = [];
                            if (members) {
                                const nextIndex = node.Depth + 1;
                                const hasChildren = nextIndex < this.LevelList.length;
                                members.forEach(m => {
                                    const memberNode = new MultiTreeNode(this._IdKeeper.NextID);
                                    memberNode.MemberKey = m.Key;
                                    memberNode.LevelID = nodeLevel.ID;
                                    memberNode.MemberCaption = m.Caption;
                                    memberNode.updateCaption(this.CodeDesc);
                                    memberNode.MemberID = m.ID;
                                    memberNode.Depth = nextIndex;
                                    memberNode.HasChildren = hasChildren;
                                    if (this.WhereSelects) {
                                        this.WhereSelects.some(ws => {
                                            if (ws.LevelId === memberNode.LevelID) {
                                                if (ws.MemberIds.includes(m.ID)) {
                                                    memberNode.Selected = true;
                                                    return true;
                                                }
                                            }
                                            return false;
                                        });
                                    }
                                    retVal.push(memberNode);
                                });
                                if (this.LayoutElement && typeof this.LayoutElement.Sort === 'number') {
                                    ArrayHelpers.sortAlphabetical(retVal, 'Caption');
                                    if (this.LayoutElement.Sort === 1) {
                                        retVal.reverse();
                                    }
                                }
                            }
                            resolve(retVal);
                        });
                    }
                } else {
                    resolve([]);
                }
            } else {
                resolve([]);
            }
        });
        return promise;
    }
}

export class FilterTreeNode extends ABaseTreeNode {
    Selected = false;
}

export class MultiTreeNode extends FilterTreeNode {
    Parent: MultiTreeNode;
    Depth = 0;
    MemberKey = '';
    MemberCaption = '';
    LevelID = '00000000-0000-0000-0000-000000000000';
    MemberID = '';

    updateCaption(codeDesc) {
        if (codeDesc === 1) { // Code
            this.Caption = this.MemberKey;
        } else if (codeDesc === 3) { // Code & Description
            this.Caption = this.MemberKey + ' ' + this.MemberCaption;
        } else {
            this.Caption = this.MemberCaption;
        }
    }
}
