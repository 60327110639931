import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
import { HeterogenArea } from '../../../models/datadescription/multi/datadescription.model';
import { AxisType } from '../../../models/enums/query.enum';
import { ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';

@Component({
  selector: 'heterogenArea',
  templateUrl: './heterogenArea.control.html',
  styleUrls: ['./heterogenArea.control.css']
})
export class HeterogenAreaControl implements OnInit, AfterViewInit, OnDestroy {

  Subscriptions = [];


  @Input()
  ObjectcreatorDataManager: ObjectcreatorDataManager;    

  @Input()
  IsMeasuresOnAxis: boolean = false;

  @Input()
  AxisType: AxisType = AxisType.None;
  
  HeterogenAreaValue: HeterogenArea;
  @Input()
  get HeterogenArea() {
    return this.HeterogenAreaValue;
  }
  set HeterogenArea(value) {
    this.HeterogenAreaValue = value;
  }


    ngAfterViewInit(): void {
    }

  ngOnInit(): void {
    //this.Subscriptions.push(ObjectcreatorDataManager.AreaChanged.subscribe(haInfo => {
    //  let areaId = haInfo.HeterogenAreaID;
    //  if (this.HeterogenArea.UniqueID == areaId) {
    //    var area = DataDescriptionHelper.FindAreaByID(this.ObjectcreatorDataManager.DataDescription, areaId);
    //    this.HeterogenArea = area;
    //  }
    //}));
    }


  ngOnDestroy(): void {
    this.Subscriptions.forEach(x => x.unsubscribe());
  }
   
  constructor(private factoryResolver: ComponentFactoryResolver) {
    
    }

  onDropAreaDropingLevel(event) {
    //var dropAreaItems = event.OcItems;
    //var index = event.Index;
    //var toDropItems = event.Items;

    //for (var i = 0; i < dropAreaItems.length; i++) {
    //  let item = dropAreaItems[i];
    //  dropAreaItems.splice(index, 0, item);
    //}
   
  }

  onDropAreaDropingMeasure(event) {
    var dropAreaItems = event.OcItems;
    var index = event.Index;
    var toDropItems = event.Items;

    
    //dropAreaItems.splice(index, 1);
  }

  onContextMenu(event: MouseEvent, areaItemType: any) {
    event.preventDefault();
    event['AreaItemType'] = areaItemType;
  }




}

