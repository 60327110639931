import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileExplorerDialog } from '../../../components/fileexplorer/fileexplorer.dialog';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'fileselection-theme-control',
    templateUrl: './fileselection.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSelectionThemeControl extends BaseThemeControl {
    constructor(public cdRef: ChangeDetectorRef, private dialog: MatDialog) {
        super(cdRef);
    }

    OpenExplorer() {
        const dialogRef = this.dialog.open(FileExplorerDialog, {
            panelClass: 'fileexplorer-dialog-container',
            data: { MediaSourceID: this.SelectedItem.MediaSource }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DataSourceInternal = result;
            }
        });
    }

    clearFile() {
        this.DataSourceInternal = null;
    }
}
