import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { NavigationService } from '../../../services/navigation.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-navigationparameters-settings',
    templateUrl: './navigationparameters.settings.html',
    styleUrls: ['./navigationparameters.settings.css']
})
export class ReadNavigationParametersSettings extends WorkflowDialogContent {
    static ModuleID = 'readMultiNavigationParameterWFModule';

    Data = new ReadNavigationParametersSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ReadNavigationParametersSettings.ModuleID;
        reg.Caption = '@@URL Parameter auslesen';
        reg.GroupID = 'navigation';
        reg.Index = 40;
        reg.SettingsControl = ReadNavigationParametersSettings;
        reg.SettingsTypeHelper = new ReadNavigationParametersSettingsDataHelper();
        reg.Executer = ReadMultiNavigationParametersModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(ReadNavigationParametersSettingsData, seri);
        }
    }

    getResult(): any {
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.Parameters.some(p => !p.Name)) {
            retVal.Error = '@@Bitte vergeben Sie fuer jeden Parameter einen Namen';
            retVal.IsCorrect = false;
        }
        return retVal;
    }

    addParam() {
        if (this.Data) {
            if (this.Data.Parameters) {
                this.Data.Parameters.push(new URLParameter());
            } else {
                this.Data.Parameters = [new URLParameter()];
            }
        }
    }

    removeParam(i) {
        if (this.Data && this.Data.Parameters) {
            this.Data.Parameters.splice(i, 1);
        }
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Parameters) {
            this.Data.Parameters.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }
}
// @dynamic
export class ReadNavigationParametersSettingsData {
    @Type(() => URLParameter)
    Parameters: URLParameter[] = [];
}

export class URLParameter {
    Name: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadNavigationParametersSettingsDataHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readNavigationParameterWFModule';
        reg.Caption = '@@ReadNavigationParameter';
        reg.SettingsTypeHelper = new ReadNavigationParametersSettingsDataHelper();
        reg.Executer = ReadNavigationParametersModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ReadNavigationParametersSettings.ModuleID;
        return reg;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadNavigationParametersSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Parameters) {
                const list = [];
                settings.Parameters.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class ReadNavigationParametersModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const params = NavigationService.Params.getValue();
            const keys = Object.keys(params);
            if (keys && keys.length > 0) {
                status.ActualParameter = params;

                return 0;
            } else {
                status.Logger.logError('ReadNavigationParameter modul: No Parameters found.');
            }
        } else {
            status.Logger.logError('ReadNavigationParameter modul: No settings found.');
        }
        return super.execute(status);
    }
}

export class ReadMultiNavigationParametersModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let keys;
            const params = NavigationService.Params.getValue();
            if (params) {
                keys = Object.keys(params);
            }
            if (keys && keys.length > 0) {
                if (status.ActualSettings.Parameters) {
                    status.ActualSettings.Parameters.forEach(x => {
                        const val = params[x.Name];
                        if (val) {
                            if (x.StatusKey) {
                                status.Context.set(x.StatusKey, val);
                            }
                            if (x.UseForState === true) {
                                status.ActualParameter = val;
                            }
                        } else {
                            status.Logger.logWarning('ReadNavigationParameter modul: Parameter ' + x.Name + ' not found.');
                        }
                    });
                }
            } else {
                status.Logger.logWarning('ReadNavigationParameter modul: No Parameters found.');
            }
            return 0;
        } else {
            status.Logger.logError('ReadNavigationParameter modul: No settings found.');
        }
        return super.execute(status);
    }
}
