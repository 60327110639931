import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { CacheService } from '../../../cache/cache.service';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { TranslationDialog } from '../../../components/dialogs/translationdialog/translation.dialog';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { DialogResult } from '../../../models/enums/dialogresult.enum';
import { Order } from '../../../models/enums/order.enum';
import { PagingType } from '../../../models/enums/pagingtype.enum';
import { ResponsiveType } from '../../../models/enums/responsivetype.enum';
import { SelectMode } from '../../../models/enums/selectmode.enum';
import { TableStyle } from '../../../models/enums/tablestyle.enum';
import { Color } from '../../../models/style/color.model';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { WorkerService } from '../../../services/worker.service';
import { DataTableCommunicationService } from "../../../services/datatable-communication.service";
@Component({
    selector: 'datatable-settings-menu-tab',
    templateUrl: './datatable.menu.tab.html',
    styleUrls: ['./datatable.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableSettingsMenuTab extends ALayoutMenuTab {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.SelectedItemValue.TableStyle == null) {
            this.SelectedItemValue.TableStyle = TableStyle.DataTable;
        }
        if (this.SelectedItemValue && this.SelectedItemValue.ResponsiveType == null) {
            this.SelectedItemValue.ResponsiveType = ResponsiveType.Scroll;
        }
        if (this.SelectedItemValue && this.SelectedItemValue.PagingType == null) {
            this.SelectedItemValue.PagingType = PagingType.Scroll;
        }
        if (this.SelectedItemValue && this.SelectedItemValue.ResizeMode == null) {
            this.SelectedItemValue.ResizeMode = 'fit';
        }
        if (this.SelectedItemValue && this.SelectedItemValue.EditMode == null) {
            this.SelectedItemValue.EditMode = 'row';
        }

        if (typeof this.SelectedItemValue.PageReport !== 'string') {
            this.SelectedItemValue.PageReport = '';
        }
        if (this.ValuesChangedSubscription != null) {
            this.ValuesChangedSubscription.unsubscribe();
            this.ValuesChangedSubscription = null;
        }
        this.ValuesChangedSubscription = this.SelectedItemValue.ValuesChanged.subscribe(prop => {
            if (typeof prop === 'string') {
                if (prop === 'Editable') {
                    this.SelectedItemValue.EditMode = 'row';
                }
            } else if (Array.isArray(prop)) {
                if (prop.some(x => x === 'Editable')) {
                    this.SelectedItemValue.EditMode = 'row';
                }
            }
        });
    }

    @Output() SelectedItemChange = new EventEmitter<any>();

    public static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'datatable') {
            menuItem.Visible = true;
            menuItem.Expanded = true;
        } else {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        }
    }

    ValuesChangedSubscription;
    PagingTypes = [];
    SelectModes = [];
    ResponsiveTypes = [];
    TableStyles = [];
    PagerPageSizes = [5, 10, 25, 100];
    constructor(cdRef: ChangeDetectorRef, private worker: WorkerService) {
        super(cdRef);
        this.ResponsiveTypes = EnumHelper.GetDropdownValues(ResponsiveType);
        this.PagingTypes = EnumHelper.GetDropdownValues(PagingType);
        this.SelectModes = EnumHelper.GetDropdownValues(SelectMode).filter(x => x.type != SelectMode['fire&forget']);
        this.SelectModes.unshift({ type: null, value: '@@None' });
        this.TableStyles = EnumHelper.GetDropdownValues(TableStyle);

    }

    pagingTypeChanged() {
        this.onStyleChanged('PagingType');
        if (this.SelectedItemValue && this.SelectedItemValue.PagingType == PagingType.Pager &&
            typeof this.SelectedItemValue.PageSize === 'number') {
            let firstPS = this.PagerPageSizes[0];
            if (this.SelectedItemValue.PageSize < firstPS) {
                this.SelectedItemValue.PageSize = firstPS;
                this.onStyleChanged('PageSize');
            } else if (this.SelectedItemValue.PageSize > firstPS) {
                for (let i = 1; i < this.PagerPageSizes.length; i++) {
                    const actSize = this.PagerPageSizes[i];
                    const middle = (actSize + firstPS) / 2;
                    if (this.SelectedItemValue.PageSize < middle) {
                        this.SelectedItemValue.PageSize = firstPS;
                        this.onStyleChanged('PageSize');
                        return;
                    } else if (this.SelectedItemValue.PageSize < actSize) {
                        this.SelectedItemValue.PageSize = actSize;
                        this.onStyleChanged('PageSize');
                        return;
                    } else if (this.SelectedItemValue.PageSize == actSize) {
                        return;
                    }
                    firstPS = actSize;
                }
                if (this.SelectedItemValue.PageSize > firstPS) {
                    this.SelectedItemValue.PageSize = firstPS;
                }
            }
        }
    }
}


@Component({
    selector: 'datatable-columns-menu-tab',
    templateUrl: './datatable.columns.menu.tab.html',
    styleUrls: ['./datatable.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableColumnsMenuTab extends ALayoutMenuTab implements OnDestroy {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue.Columns && this.SelectedItemValue.Columns.length > 0) {
            this.SelectedItemValue.Columns = this.SelectedItemValue.Columns.filter((col) => col.FetchData);
        }
        if (this.DataBindingChangedSubscription != null) {
            this.DataBindingChangedSubscription.unsubscribe();
            this.DataBindingChangedSubscription = null;
        }
        if (this.SelectedItemValue.DataBindingChanged) {
            this.DataBindingChangedSubscription = this.SelectedItemValue.DataBindingChanged.subscribe((Property) => {
                this.ReadTableProperties();
            });
        }
        this.ReadTableProperties();
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    DataSourceID;
    //#region FlatTableProperties
    TablePropertiesValue;
    @Input()
    get TableProperties() {
        return this.TablePropertiesValue;
    }
    set TableProperties(val) {
        this.TablePropertiesValue = val;
        this.worker.ConvertToTree({ TableProperties: this.TableProperties, SelectedItem: this.SelectedItemValue }).then((data: any) => {
            this.TreeData = data.TreeData;
            if (data.TreeData) {
                this.SortColumns = data.TreeData.map((tree) => {
                    return {
                        Caption: tree?.data?.Name,
                        Value: tree?.data?.Name
                    }
                })
            }
            this.SelectedTreeNodes = data.SelectedTreeNodes;
            this.SelectedColumns = this.SelectedItemValue.Columns;
            this.cdRef.detectChanges();
        });
        this.TablePropertiesChange.emit(this.TablePropertiesValue);
    }

    @Output() TablePropertiesChange = new EventEmitter<any>();
    //#endregion

    public static CheckOnChange(menuItem) {

        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'datatable') {
            menuItem.Visible = true;
            menuItem.Expanded = true;
        } else {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        }
    }

    DataBindingChangedSubscription;
    SelectedColumn;
    TreeData;
    SelectedTreeNodes;
    SelectedColumns;
    OrderTypes = [];
    Cultures = [];
    SortColumn:string;
    SortOrder:string;
    SortColumns;
    constructor(cdRef: ChangeDetectorRef, private worker: WorkerService,
        private languageService: LanguageService, translate: TranslateService, private datatableComService: DataTableCommunicationService) {
        super(cdRef);
        this.OrderTypes = EnumHelper.GetDropdownValues(Order);
        this.OrderTypes.unshift({ value: '@@None', type: null })
        this.Cultures.push({
            Caption: translate.instant('@@Keine Auswahl'),
            Name: null
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.languageService.getLanguages().subscribe(x => {
            if (x) {
                this.Cultures.push(...x);
            }
        });
    }
    onLayoutSet() {
        this.ReadTableProperties();
    }
    ColumnChanged() {
        if (this.SelectedColumn && !this.SelectedColumn.Width) {
            this.SelectedColumn.Width = new LayoutUnit();
        }
    }
    ReadTableProperties() {
        const sel = this.SelectedItemValue;
        this.TreeData = [];
        if (this.Layout && sel) {
            MetaHelper.FindDataBindingProperties(this.Layout, sel).then(result => {
                if (result) {
                    CacheService.ReadFlatDataTable(result.Table.SID).then((props) => {
                        if (props != this.TableProperties) {
                            this.TableProperties = props;
                        }
                    });
                } else {
                    this.cdRef.detectChanges();
                }
            });
        }
    }
    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }
    translateCaption(key) {
        TranslationDialog.ShowDialog(key);
    }
    SettingsChanged() {
        this.SelectedItemValue.ValuesChanged.next('Columns');
    }

    

    ResetWidth(SelectedColumn) {
        SelectedColumn.Width = new LayoutUnit();
        this.SettingsChanged();
    }
    nodeSelect(event) {
        let selectedNode = event.node;
        let selectedColumn = event.node.data;
        if (this.SelectedTreeNodes.indexOf(event.node) > -1) {
            let col = selectedColumn;
            col['Caption'] = col['Name'];
            col['IsVisible'] = true;
            col['FetchData'] = true;
            col['ParentIsList'] = this.CheckParentIsList(selectedNode);
            if (!this.SelectedItemValue.Columns) {
                this.SelectedItemValue.Columns = [];
            }
            this.SelectedItemValue.Columns.push(col)
        } else {
            const node = this.SelectedItemValue.Columns.find((col) => selectedColumn.Name == col.Name);
            this.SelectedItemValue.Columns.splice(this.SelectedItemValue.Columns.indexOf(node), 1);
        }
        this.SelectedColumns = this.SelectedItemValue.Columns.filter((value) => value.FetchData);
        // console.log("sort columnnn ---> ", this.SortColumn)
        this.datatableComService.setSortColumn(this.SortColumn);
        this.datatableComService.setSortOrder(this.SortOrder);
        this.SettingsChanged();
    }
    sortColumnChanged(event){
        const {value} = event;
        this.SortColumn = value;
        this.datatableComService.setSortColumn(value);
    }
    sortOrderChanged(event){
        const {value} = event;
        this.SortOrder = value;
        this.datatableComService.setSortOrder(value);
    }
    CheckParentIsList(node) {
        let retVal = false;
        if (node && node.parent) {
            retVal = node.parent.data.IsList;
            if (retVal) {
                return retVal;
            }
            if (node.parent.parent) {
                retVal = this.CheckParentIsList(node.parent.parent);
            }
        }
        return retVal;
    }
    showIconDialog() {
        if (this.SelectedColumn) {
            BaseDialog.ShowDialogAsync({
                ContentType: DataTableIconsMappingDialog,
                InitArgs: { Column: this.SelectedColumn },
                Title: '@@IconMapping',
                AutoFocus: false
            }).then((x) => {
                if (x == DialogResult.Ok) {
                    this.SettingsChanged();
                }
            });
        }
    }
}

@Component({
    selector: 'datatable-icons-mapping-dialog',
    templateUrl: './datatable.icons.mapping.dialog.html',
    styleUrls: ['./datatable.icons.mapping.dialog.css'],
})
export class DataTableIconsMappingDialog implements OnInit, OnDestroy {
    Column;
    ColumnType;
    Values = [];
    Icons = [];
    DefaultIcon;
    IsNumber = false;
    IsDate = false;
    IsFixed = false;
    IconSub;

    ngOnInit(): void {
        this.IconSub = MediaService.IconList.subscribe((result) => {
            const icons = [];
            if (result) {
                result.forEach((icon) => {
                    icons.push({ Caption: icon, Icon: icon, Value: icon });
                });
            }
            this.Icons = icons;
        });
        if (this.Column) {
            this.DefaultIcon = this.Column.DefaultIcon;
            const values = [];
            const settings = this.Column.IconMapping ?? {};
            this.ColumnType = this.Column.Type2;
            switch (this.ColumnType) {
                case '876cf188-243b-49ed-91ab-b2cf27216a30':
                    this.IsFixed = true;
                    if (this.Column.Type3 !== '00000000-0000-0000-0000-000000000000') {
                        EnumHelper.GetEnumValues(this.Column.Type3).then((data) => {
                            data.forEach(x => {
                                values.push({
                                    Key: x.Value,
                                    Caption: x.Caption,
                                    Value: settings[x.Value]
                                });
                            });
                        });
                    }
                    break;
                case '02414483-3194-474d-a3d1-0a1a8a36a9e0':
                    this.IsFixed = true;
                    values.push({
                        Key: 'true',
                        Caption: 'true',
                        Value: settings['true']
                    });
                    values.push({
                        Key: 'false',
                        Caption: 'false',
                        Value: settings['false']
                    });
                    break;
                case 'a2eb35c4-56d5-4acc-a58e-ef855cb5086b':
                case 'c02230ee-05fb-46d2-ab58-5b9dde1f32d5':
                case 'a4299f61-eb3f-4b29-8c4e-144458d17048':
                    this.IsNumber = true;
                    Object.keys(settings).forEach(k => {
                        const splits = k.split('|');
                        values.push({
                            From: splits[0],
                            To: splits[1],
                            Value: settings[k]
                        });
                    });
                    break;
                case 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69':
                    this.IsDate = true;
                    Object.keys(settings).forEach(k => {
                        const val = {
                            From: null,
                            To: null,
                            Value: settings[k]
                        };
                        const splits = k.split('|');
                        if (splits[0]) {
                            val.From = new Date(splits[0]);
                        }
                        if (splits[1]) {
                            val.To = new Date(splits[1]);
                        }
                        values.push(val);
                    });
                    break;
                default:
                    Object.keys(settings).forEach(k => {
                        values.push({
                            Key: k,
                            Value: settings[k]
                        });
                    });
                    break;
            }
            this.Values = values;
        }
    }
    ngOnDestroy(): void {
        this.IconSub.unsubscribe();
        this.IconSub = null;
    }
    Initialize(args) {
        if (args) {
            this.Column = args.Column;
        }
    }
    OnDialogButtonClickAction(button: DialogButton) {
        if (button == DialogButton.Ok && this.Column) {
            this.Column.DefaultIcon = this.DefaultIcon;
            const settings = {};
            if (this.IsNumber) {
                this.Values.forEach(x => {
                    if (x.Value) {
                        settings[(x.From ?? '') + '|' + (x.To ?? '')] = x.Value;
                    }
                });
            } else if (this.IsDate) {
                this.Values.forEach(x => {
                    if (x.Value) {
                        let from = '';
                        if (x.From instanceof Date) {
                            from = x.From.toISOString();
                        }
                        let to = '';
                        if (x.To instanceof Date) {
                            to = x.To.toISOString();
                        }
                        settings[from + '|' + to] = x.Value;
                    }
                });
            } else {
                this.Values.forEach(x => {
                    if (x.Value) {
                        settings[x.Key] = x.Value;
                    }
                });
            }
            this.Column.IconMapping = settings;
        }
        return true;
    }
    addValue() {
        this.Values.push({});
    }
    removeValue(i) {
        this.Values.splice(i, 1);
    }
}

@Component({
    selector: 'datatable-fixedcolumns-menu-tab',
    templateUrl: './datatable.fixedcolumns.menu.tab.html',
    styleUrls: ['./datatable.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableFixedColumnsMenuTab extends ALayoutMenuTab implements OnDestroy {

    public static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'datatable') {
            menuItem.Visible = true;
            menuItem.Expanded = true;
        } else {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        }
    }
}

@Component({
    selector: 'datatable-style-menu-tab',
    templateUrl: './datatable.style.menu.tab.html',
    styleUrls: ['./datatable.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableStyleMenuTab extends ALayoutMenuTab implements OnDestroy {
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.SelectedItemValue.CustomBaseStyle == null) {
            this.SelectedItemValue.CustomBaseStyle = {};
        } else {
            const cbs = this.SelectedItemValue.CustomBaseStyle;
            if (cbs.HeaderBackground) {
                cbs.HeaderBackground = Color.FromObject(cbs.HeaderBackground);
            }
            if (cbs.HeaderForeground) {
                cbs.HeaderForeground = Color.FromObject(cbs.HeaderForeground);
            }
            if (cbs.Line) {
                cbs.Line = Color.FromObject(cbs.Line);
            }
            if (cbs.StickyBackground) {
                cbs.StickyBackground = Color.FromObject(cbs.StickyBackground);
            }
        }
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    public static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'datatable') {
            menuItem.Visible = true;
            menuItem.Expanded = true;
        } else {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        }
    }
    constructor(cdRef: ChangeDetectorRef, private worker: WorkerService) {
        super(cdRef);
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }
}