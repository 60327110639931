import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { LayoutService } from '../../../services/layout.service';
import { DatadescriptionControl } from '../../dialogs/datadescription/datadescription.control';

@Component({
    selector: 'objectcreatorsource-multidimensional',
    templateUrl: './objectcreatorsource.multidim.html'
})
export class ObjectCreatorSourceMultiDimensional implements OnInit {

    static DragEnd = new Subject();

    Subscriptions = [];
    SelectedItem;

    DataModelIDValue;
    @Input()
    get DataModelID() {
        return this.DataModelIDValue;
    }
    set DataModelID(val) {
        this.DataModelIDValue = val;
    }


    IsLevelTreeVisibleValue = true;

    @Input()
    get IsLevelTreeVisible() {
        return this.IsLevelTreeVisibleValue;
    }
    set IsLevelTreeVisible(val) {
        this.IsLevelTreeVisibleValue = val;
    }



    IsMeasureTreeVisibleValue = true;

    @Input()
    get IsMeasureTreeVisible() {
        return this.IsMeasureTreeVisibleValue;
    }
    set IsMeasureTreeVisible(val) {
        this.IsMeasureTreeVisibleValue = val;
    }

    IsSpecialTreeVisibleValue = true;

    @Input()
    get IsSpecialTreeVisible() {
        return this.IsSpecialTreeVisibleValue;
    }
    set IsSpecialTreeVisible(val) {
        this.IsSpecialTreeVisibleValue = val;
    }


    get SelectedCubesIds() {
        if (!this.DataDescriptionData.Datadescription.SelectedCubes) {
            this.DataDescriptionData.Datadescription.SelectedCubes = [];
        }
        return this.DataDescriptionData.Datadescription.SelectedCubes;
    }
    set SelectedCubeIds(val) {
        this.DataDescriptionData.Datadescription.SelectedCubes
    }

    DataDescriptionDataValue: DatadescriptionData;

    @Input()
    get DataDescriptionData() {
        return this.DataDescriptionDataValue;
    }
    set DataDescriptionData(val) {
        this.DataDescriptionDataValue = val;
    }

    @ViewChild('levelTree') levelTree; // Zugriff auf das TypeScript-Element
    // Im gegensatz dazu :!!!!!!!!!!!!!!!!!!!
    // @ViewChild('levelTree', { static: false, read: ElementRef }) levelTree: ElementRef  // Zugriff auf das HTML elemt '.nativeElement'

    @ViewChild('measureTree') measureTree;
    @ViewChild('specialTree') specialTree;
    @ViewChild('cubePanel') cubePanel;
    @ViewChild('levelPanel') levelPanel;
    @ViewChild('measurePanel') measurePanel;
    @ViewChild('specialPanel') specialPanel;

    CubesValues = [];
    SelectedCubes = [];
    Dimensions: any[];
    Measures: any[];

    private async GetAllCubes() {
        if (!this.CubesValues || this.CubesValues.length < 1) {
            const r = await MultiCacheService.GetCubes(this.DataModelID);
            if (r) {
                const cubelist = [];
                r.forEach(c => {
                    const cubeinfo = {
                        Caption: c.Caption,
                        ID: c.ID,
                        Measures: c.Measures,
                        Dimensions: c.Dimensions,
                        Selected: this.SelectedCubesIds.includes(c.ID)
                    };
                    cubelist.push(cubeinfo);
                    if (cubeinfo.Selected) {
                        this.SelectedCubes.push(cubeinfo);
                    }
                });
                cubelist.sort((a, b) => {
                    const textA = (a && typeof a.Caption === 'string') ? a.Caption.toUpperCase() : '';
                    const textB = (b && typeof b.Caption === 'string') ? b.Caption.toUpperCase() : '';
                    return textA.localeCompare(textB);
                });
                this.CubesValues = cubelist;
            }

            if (this.SelectedCubes && this.SelectedCubes.length > 0) {
                if (this.IsLevelTreeVisible) {
                    this.loadDimensions(this.SelectedCubes);
                   // this.levelPanel.open();
                }
                if (this.IsMeasureTreeVisible) {
                    this.measureTree.DbItems = this.SelectedCubes;
                    this.measureTree.UpdateTreeSource(null);
                    //if (!this.IsLevelTreeVisible) {
                    //    this.measurePanel.open();
                    //}
                }
            }
            //else {
            //    this.cubePanel.open();
            //}
        }
        if (this.IsSpecialTreeVisible) {
            this.specialTree.UpdateTreeSource(null);
        }
        return this.CubesValues;
    }

    ngOnInit(): void {
         this.GetAllCubes();
       
        this.Subscriptions.push(LayoutService.SelectedItem.subscribe((item) => {
            this.SelectedItem = item;
        }));
    }

    cubeSelectionChanged(event, cube) {
        if (cube.Selected) {
            this.SelectedCubes.push(cube);
            if (!this.SelectedCubesIds.includes(cube.ID)) {
                this.SelectedCubesIds.push(cube.ID);
            }
            this.cubePanel.close();
            if (this.IsLevelTreeVisible) {
                this.levelPanel.open();
            }
            else {
                {
                    this.measurePanel.open();
                }
            }
        } else {
            let index = -1;
            this.SelectedCubes.some((ds) => {
                index++;
                if (ds.SID == cube.SID) {
                    return true;
                }
                return false;
            });
            if (this.SelectedCubesIds.includes(cube.ID)) {
                this.SelectedCubesIds.splice(this.SelectedCubesIds.indexOf(cube.ID), 1);
            }
            this.SelectedCubes.splice(index, 1);
        }

        this.Dimensions = [];

        if (this.IsLevelTreeVisible) {
            this.loadDimensions(this.SelectedCubes);
        }
        if (this.IsMeasureTreeVisible) {
            this.measureTree.DbItems = this.SelectedCubes;
            this.measureTree.UpdateTreeSource(null);
        }
    }

    SelectedMeasureItem = null;
    selectionMeasureChange(item) {
        this.SelectedMeasureItem = item;
    }

    get SelectedMeasureCaption() {
        if (this.SelectedMeasureItem?.Caption) {
            return this.SelectedMeasureItem.Caption;
        }
        return "";
    }

    SelectedLevelItem = null;
    selectionLevelChange(item) {
        this.SelectedLevelItem = item;
    }

    get SelectedLevelCaption() {
        if (this.SelectedLevelItem?.Caption) {
            return this.SelectedLevelItem.Caption;
        }
        return "";
    }


    private loadDimensions(selectedCubes) {
        this.levelTree.DbItems = selectedCubes;
        this.levelTree.UpdateTreeSource(null);
    }

    dragEnd(ev) {
        ObjectCreatorSourceMultiDimensional.DragEnd.next(null);
        DatadescriptionControl.ObjectCreatorMPDragStart.next(null);
    }
}
