import { Component } from '@angular/core';
import { DataModelService } from '../../../../../services/datamodel.service';
import { RestService } from '../../../../../services/rest.service';
import { WebHookService } from '../../../../../services/webhook.service';
import { BaseListDetail } from '../../../../../settings/base.list.settings';
import { RestObject } from '../../../models/rest.object';

@Component({
    selector: 'webhook-rest-detail',
    templateUrl: 'webhook.rest.detail.html',
    styleUrls: ['webhook.rest.detail.css']
})
export class WebhookRestDetailComponent extends BaseListDetail {


    public objects: any[];
    public datamodels: any[];
    public datasources: any[];

    datamodel: any;

    public selectedObject: any;

    Yaml: string;

    editorOptions = {
        language: 'yaml'
    };

   

    constructor(private _service: WebHookService, private _restservice: RestService, protected dataService: DataModelService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.dataService.GetModels().subscribe((result) => {
            this.datamodels = result;
        });
      
    }
  

    setSelectedItem(item: any) {
        if (!this.objects && item) {
            this._restservice.loadRestObjects((item as RestObject).RestID).subscribe(result => {
                const os = [];
                if (result) {
                    result.forEach(r => {
                        os.push({
                            Caption: r.Caption,
                            Value: r.Value
                        });
                    });
                }
                this.objects = os;
            });
        }
        super.setSelectedItem(item);

        if (this.SelectedItem) {
            if (this.SelectedItem.DataModelID) {
                this.LoadDataSources();
            }
            if ( this.SelectedItem['ObjectID']) {
                const name = this.SelectedItem['Name'] ? this.SelectedItem['Name'] : '';
                const description = this.SelectedItem.Description?.DefaultValue ? this.SelectedItem.Description : '';
                this._restservice.getYamlFromRestObject(this.SelectedItem['ObjectID'], name, description).subscribe(result => {
                    this.Yaml = result;
                });
            }
        }
    }

    
    public dataModelSelectionChange(event: string): void {

        let datamodel = this.datamodels.find(x => x.SID === this.SelectedItem.DataModelID);
        if (datamodel) {
            this.SelectedItem.DataModelName = datamodel.AdditionalInfos?.[0] ? datamodel.AdditionalInfos[0] : datamodel.Caption;
            this.LoadDataSources();
        }
    }

    LoadDataSources() {
        this.datasources = [];
        
        this.dataService.GetDataSources(this.SelectedItem.DataModelID).subscribe(sources => {
                const list = [];
              
                this.datasources = sources;
            });
        
    }

    public objectSelectionChange(event: string): void {
        if (this.SelectedItem && this.SelectedItem['ObjectID']) {
            const name = this.SelectedItem['Name'] ? this.SelectedItem['Name'] : '';
            const description = this.SelectedItem.Description?.DefaultValue ? this.SelectedItem.Description.DefaultValue : '';
            this._restservice.getYamlFromRestObject(this.SelectedItem['ObjectID'], name, description).subscribe(result => {
                this.Yaml = result;
            });
        }
    }
}