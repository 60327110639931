import { Observable, of } from 'rxjs';
import { CacheService } from '../cache/cache.service';
import { LayoutHelper } from './layout.helper';

// @dynamic
export class MetaHelper {
    private static ControlTypes = {
        //#region Class
        'c4ef25dd-c176-418c-836e-f26c52d7f59c': {
            //'scheduler': function (element) {
            //    return !element.IsList && !element.IsShared && !element.IsReverse;
            //},
            'combobox': function (element) {
                return element.IsShared;
            },
            'listbox': function (element) {
                return element.IsList;
            },
            'repeat': function (element) {
                return element.IsList;
            },
            'autocomplete': function (element) {
                return element.IsShared;
            },
            'datatable': function (element) {
                return element.IsList;
            }
        },
        //#endregion
        //#region Boolean
        '02414483-3194-474d-a3d1-0a1a8a36a9e0': {
            'checkbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'togglebutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region ChangeDate
        '397767a9-9711-49cd-adb7-76678fff16bf': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region ChangeUser
        '07b8fdec-641d-48b7-b491-ca13af4b59c5': {},
        //#endregion
        //#region CreateDate
        'f696d7f4-fa0b-4664-84d7-3f9bab8c99b7': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region CreateUser
        '919a5bff-675a-435c-b0c6-6d1076ae9156': {},
        //#endregion
        //#region Datum
        'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69': {
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Double
        'a2eb35c4-56d5-4acc-a58e-ef855cb5086b': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region Enum
        '876cf188-243b-49ed-91ab-b2cf27216a30': {
            'combobox': function (element) {
                return !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            // 'slider': function (element) {
            //    return !element.IsList && !element.IsShared && !element.IsReverse;
            // },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'autocomplete': function (element) {
                return !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region Guid
        '983dad4c-fd88-4124-aed0-1dd1d177521a': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Integer
        'c02230ee-05fb-46d2-ab58-5b9dde1f32d5': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Long
        'a4299f61-eb3f-4b29-8c4e-144458d17048': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Text
        '9b056ce1-ffb9-4168-9e00-977449f2f66d': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textarea': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'image': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'objectviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'video': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'iframe': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'embed': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'upload': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'download': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'qr': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'pdfviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region TimeSpan
        '3681a7ff-b566-4870-9ad2-9c6bd8dfcae0': {
            'timespan': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Mehrsprachiger Text
        'a0c232a9-4ab7-444c-a7e2-d23899a5673b': {
            'translationtextbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'repeat': function (element) {
                return element.IsList;
            }
        },
        //#endregion
        //#region Benutzer
        'a236172b-9aaa-4211-9fe9-b57daf3f3a29': {
            'datatable': function (element) {
                return element.IsList;
            },
            'combobox': function (element) {
                return true;
            },
            'listbox': function (element) {
                return element.IsList;
            },
            'autocomplete': function (element) {
                return true;
            }
        },
        //#endregion
        //#region Else
        '944c8a5c-a09f-419d-94fd-28344e52bf0f': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textarea': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'image': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'objectviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'video': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'iframe': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'embed': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'upload': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'download': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
    };

    private static DataTypes = {
        //#region Class
        'c4ef25dd-c176-418c-836e-f26c52d7f59c': {
            'raster': function (element) {
                return true; // !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'grid': function (element) {
                return true; // !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'bootstrapgrid': function (element) {
                return true; // !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'bootstraprepeat': function (element) {
                return true; // !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'flex': function (element) {
                return true; // !element.IsList && !element.IsShared && !element.IsReverse;
            },
            //'scheduler': function (element) {
            //    return !element.IsList && !element.IsShared && !element.IsReverse;
            //},
            'combobox': function (element) {
                return element.IsShared;
            },
            'listbox': function (element) {
                return element.IsList;
            },
            'repeat': function (element) {
                return element.IsList;
            },
            'autocomplete': function (element) {
                return element.IsShared;
            },
            'datatable': function (element) {
                return element.IsList;
            },
            'spreadsheet': function (element) {
                return element.IsList && element.IsShared;
            },
            'pivottable': function (element) {
                return element.IsList && element.IsShared;
            }
        },
        //#endregion
        //#region Boolean
        '02414483-3194-474d-a3d1-0a1a8a36a9e0': {
            'checkbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'togglebutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },

        },
        //#endregion
        //#region ChangeDate
        '397767a9-9711-49cd-adb7-76678fff16bf': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region ChangeUser
        '07b8fdec-641d-48b7-b491-ca13af4b59c5': {},
        //#endregion
        //#region CreateDate
        'f696d7f4-fa0b-4664-84d7-3f9bab8c99b7': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region CreateUser
        '919a5bff-675a-435c-b0c6-6d1076ae9156': {},
        //#endregion
        //#region Datum
        'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69': {
            'datetimepicker': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Double
        'a2eb35c4-56d5-4acc-a58e-ef855cb5086b': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Enum
        '876cf188-243b-49ed-91ab-b2cf27216a30': {
            'combobox': function (element) {
                return !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            // 'slider': function (element) {
            //    return !element.IsList && !element.IsShared && !element.IsReverse;
            // },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'autocomplete': function (element) {
                return !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region Guid
        '983dad4c-fd88-4124-aed0-1dd1d177521a': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Integer
        'c02230ee-05fb-46d2-ab58-5b9dde1f32d5': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region Long
        'a4299f61-eb3f-4b29-8c4e-144458d17048': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'slider': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'sumcomponent': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region Text
        '9b056ce1-ffb9-4168-9e00-977449f2f66d': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textarea': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'image': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'objectviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'video': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'iframe': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'embed': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'upload': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'download': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'qr': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'pdfviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        },
        //#endregion
        //#region TimeSpan
        '3681a7ff-b566-4870-9ad2-9c6bd8dfcae0': {
            'timespan': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            }
        },
        //#endregion
        //#region Mehrsprachiger Text
        'a0c232a9-4ab7-444c-a7e2-d23899a5673b': {
            'translationtextbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'repeat': function (element) {
                return element.IsList;
            }
        },
        //#endregion
        //#region Benutzer
        'a236172b-9aaa-4211-9fe9-b57daf3f3a29': {
            'datatable': function (element) {
                return element.IsList;
            },
            'combobox': function (element) {
                return true;
            },
            'listbox': function (element) {
                return element.IsList;
            },
            'autocomplete': function (element) {
                return true;
            }
        },
        //#endregion
        //#region Else
        '944c8a5c-a09f-419d-94fd-28344e52bf0f': {
            'textbox': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textarea': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'editor': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'textblock': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'image': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'objectviewer': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'video': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'iframe': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'embed': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'chiplist': function (element) {
                return element.IsList && !element.IsShared && !element.IsReverse;
            },
            'radiobutton': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'upload': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
            'download': function (element) {
                return !element.IsList && !element.IsShared && !element.IsReverse;
            },
        }
        //#endregion
    };

    static FindParent(layout, SelectedItem) {
        if (SelectedItem && SelectedItem.Parent) {
            return SelectedItem.Parent;
        }  else {
            return null;
        }

        //if (layout && layout.Elements && SelectedItem) {
        //    for (let i = 0; i < layout.Elements.length; i++) {
        //        const item = layout.Elements[i];
        //        if (item.ID === SelectedItem.ID) {
        //            return layout;
        //        }
        //        if (item.Elements && item.Elements.length > 0) {
        //            const result = MetaHelper.FindParent(item, SelectedItem);
        //            if (result) {
        //                return result;
        //            }
        //        }
        //    }
        //} else {
        //    return null;
        //}
    }

    static ParentIsOfType(item, type: string): boolean {
        if (item) {
            let layout = LayoutHelper.GetLayout();
            if (layout) {
                let resolution = LayoutHelper.GetActiveResolution(layout);
                if (resolution) {
                    layout = resolution;
                }
            }
            const parent = MetaHelper.FindParent(layout, item);
            return parent && parent.ElementType === type;
        }
        return false;
    }

    static FindParentPath(layout, selectedItem) {
        if (layout && layout.Elements && selectedItem) {
            for (let i = 0; i < layout.Elements.length; i++) {
                const item = layout.Elements[i];
                if (item.ID === selectedItem.ID) {
                    return [layout];
                }
                if (item.Elements && item.Elements.length > 0) {
                    const result = MetaHelper.FindParentPath(item, selectedItem);
                    if (result) {
                        return [layout].concat(result);
                    }
                }
            }
        }
        return null;
    }

    static FindValidParent(layout, item) {
        const parent = MetaHelper.FindParent(layout, item);
        if (parent) {
            if ((parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') ||
                (parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                return parent;
            } else {
                return MetaHelper.FindValidParent(layout, parent);
            }
        } else {
            return null;
        }
    }
    static FindDisabledParent(layout, item) {
        const parent = MetaHelper.FindParent(layout, item);
        if (parent) {
            if (parent.IsShared) {
                return true;
            } else {
                return MetaHelper.FindDisabledParent(layout, parent);
            }
        } else {
            return false;
        }
    }
    static FindValidWorkflowParent(layout, item) {
        const parent = MetaHelper.FindParent(layout, item);
        if (parent) {
            if (parent.Workflows) {
                return parent;
            } else {
                return MetaHelper.FindValidWorkflowParent(layout, parent);
            }
        } else {
            return null;
        }
    }
    static FindValidWorkflow(layout, item, workflowname) {
        const parent = MetaHelper.FindParent(layout, item);
        if (parent) {
            if (parent.Workflows) {
                const wfs = parent.Workflows.filter((wf, index) => {
                    return wf.Caption === workflowname && wf.Data;
                });
                if (wfs && wfs.length === 1) {
                    return wfs[0];
                } else {
                    return MetaHelper.FindValidWorkflow(layout, parent, workflowname);
                }
            } else {
                return MetaHelper.FindValidWorkflow(layout, parent, workflowname);
            }
        } else {
            return null;
        }
    }
    static FindTableProperties(layout, item): any {
        const promise = new Promise((resolve, reject) => {
            if (item.DataModelID && item.DataModelID !== '00000000-0000-0000-0000-000000000000') {
                CacheService.ReadTableSub(item.DataSource).subscribe((result) => {
                    resolve(result);
                    return;
                });
            } else {
                const parent = MetaHelper.FindValidParent(layout, item);
                if (parent) {
                    if (parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                        CacheService.ReadTableSub(parent.DataSourceID).subscribe((result) => {
                            resolve(result);
                        });
                    } else if ((parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                        ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                        CacheService.ReadTableSub(parent.DataSource).subscribe((result) => {
                            resolve(result);
                        });
                    } else if (parent.MetaTableProperties) {
                        resolve(parent.MetaTableProperties)
                        return;
                    } else {
                        resolve(null);
                        return
                    }
                } else {
                    resolve(null)
                    return
                }
                //if (parent && parent.MetaTableProperties) {
                //    resolve(parent.MetaTableProperties)
                //} else {
                //    resolve(null);
                //}
            }
        });
        return promise;
    }
    static FindTablePropertiesSub(layout, item): Observable<any> {
        if (item.DataModelID && item.DataModelID !== '00000000-0000-0000-0000-000000000000') {
            return CacheService.ReadTableSub(item.DataSource)
        } else {
            const parent = MetaHelper.FindValidParent(layout, item);
            if (parent) {
                if (parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                    return CacheService.ReadTableSub(parent.DataSourceID)
                } else if ((parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                    ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                    return CacheService.ReadTableSub(parent.DataSource)
                } else if (parent.MetaTableProperties) {
                    return of(parent.MetaTableProperties)
                } else {
                    return of(null);
                }
            } else {
                return of(null);
            }
        }
    }
    static FindDataBindingProperties(layout, item): any {
        const promise = new Promise((resolve, reject) => {
            if ((!item.DataModelID || item.DataModelID == '00000000-0000-0000-0000-000000000000') && item.DataSourceID && item.DataSourceID !== '00000000-0000-0000-0000-000000000000' && item.UseAsSelection) {
                CacheService.ReadTableSub(item.DataSourceID).subscribe((result) => {
                    MetaHelper.CheckForDataBinding(result, item.DataSource).then(x => {
                        resolve(x);
                    });
                });
            } else
            if (item.DataModelID && item.DataModelID !== '00000000-0000-0000-0000-000000000000') {
                CacheService.ReadTableSub(item.DataSource).subscribe((result) => {
                    resolve({
                        IsDataBinding: false,
                        IsShared: false,
                        Table: result,
                        KeyColumns: null
                    });
                });
            } else {
                const parent = MetaHelper.FindValidParent(layout, item);
                if (parent) {
                    if (parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                        CacheService.ReadTableSub(parent.DataSourceID).subscribe((result) => {
                            MetaHelper.CheckForDataBinding(result, item.DataSource).then(x => {
                                resolve(x);
                            });
                        });
                    } else if ((parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                        ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                        CacheService.ReadTableSub(parent.DataSource).subscribe((result) => {
                            MetaHelper.CheckForDataBinding(result, item.DataSource).then(x => {
                                resolve(x);
                            });
                        });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            }
        });
        return promise;
    }

    private static CheckForDataBinding(result, dataSource) {
        const promise = new Promise((resolve, reject) => {
            if (result) {
                const retVal = {
                    IsDataBinding: false,
                    IsShared: false,
                    Table: result,
                    KeyColumns: null
                };
                const fields = result.Fields;
                if (fields) {
                    const field = fields.find(x => x.ID === dataSource);
                    if (field) {
                        retVal.IsDataBinding = true;
                        if (field.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                            if (field.AdvancedType && field.AdvancedType !== '00000000-0000-0000-0000-000000000000') {
                                CacheService.ReadTableSub(field.AdvancedType).subscribe(adv => {
                                    if (adv) {
                                        retVal.Table = adv;
                                        if (field.IsShared && !field.IsReverse) {
                                            retVal.IsShared = true;
                                            if (field.KeyColumns && field.KeyColumns.length > 0) {
                                                retVal.KeyColumns = [...field.KeyColumns];
                                            }
                                        }
                                        resolve(retVal);
                                    } else {
                                        resolve(null);
                                    }
                                });
                            } else {
                                resolve(retVal);
                            }
                        } else if (field.Type === 'a236172b-9aaa-4211-9fe9-b57daf3f3a29') {
                            CacheService.ReadTableSub(field.Type).subscribe(adv => {
                                if (adv) {
                                    retVal.IsShared = true;
                                    retVal.Table = adv;
                                    resolve(retVal);
                                } else {
                                    resolve(null);
                                }
                            });
                        } else {
                            resolve(retVal);
                        }
                    } else {
                        resolve(retVal);
                    }
                } else {
                    resolve(retVal);
                }
            } else {
                resolve(result);
            }
        });
        return promise;
    }

    static FindFlatTableProperties(layout, item): any {
        const promise = new Promise((resolve, reject) => {
            // if (item.DataSourceID && item.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
            //    CacheService.ReadFlatTable(item.DataSourceID).then((result) => {
            //        resolve(result);
            //    });
            // } else
            if (item.DataModelID && item.DataModelID !== '00000000-0000-0000-0000-000000000000') {
                CacheService.ReadFlatTable(item.DataSource, true).then((result) => {
                    resolve(result);
                });
            } else {
                const parent = MetaHelper.FindValidParent(layout, item);
                if (parent) {
                    if (parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                        CacheService.ReadFlatTable(parent.DataSourceID, true).then((result) => {
                            resolve(result);
                        });
                    } else if ((parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                        ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                        CacheService.ReadFlatTable(parent.DataSource, true).then((result) => {
                            resolve(result);
                        });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            }
        });
        return promise;
    }
    static FindDataSourceID(layout, item) {
        if (item.DataSourceID && item.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
            return item.DataSourceID;
        } else if ((item.DataModelID && item.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
            ((item.DataSource && item.DataSource !== '00000000-0000-0000-0000-000000000000') && !item.DataModelID)) {
            return item.DataSource;
        } else {
            const parent = MetaHelper.FindValidParent(layout, item);
            if (parent) {
                if (parent.DataSourceID && parent.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
                    return parent.DataSourceID;
                } else if ((parent.DataModelID && parent.DataModelID !== '00000000-0000-0000-0000-000000000000') ||
                    ((parent.DataSource && parent.DataSource !== '00000000-0000-0000-0000-000000000000') && !parent.DataModelID)) {
                    return parent.DataSource;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }
    static FindElementByID(layout, id) {
        if (layout.ID == id) {
            return layout;
        }
        if (layout.Elements && layout.Elements.length > 0) {
            for (let i = 0; i < layout.Elements.length; i++) {
                let item = layout.Elements[i];
                if (item.Elements && item.Elements.length > 0) {
                    let ret = this.FindElementByID(item, id);
                    if (ret) {
                        return ret;
                    }
                }
                if (item.ID == id) {
                    return item;
                }
            }
        }
        return null;
    }
    static FindParentElementType(layout, SelectedItem, ElementType) {
        const parent = MetaHelper.FindParent(layout, SelectedItem);
        if (parent) {
            if (parent.ElementType === ElementType) {
                return parent;
            } else {
                return MetaHelper.FindParentElementType(layout, parent, ElementType);
            }
        } else {
            return null;
        }
    }
    static FindParentCustomEvent(layout, SelectedItem, EventName) {
        const parent = MetaHelper.FindParent(layout, SelectedItem);
        if (parent) {
            if (parent.CustomEvents) {
                var element = parent.CustomEvents.find((value) => value.Name == EventName);

                if (element) {
                    return parent;
                }
                else {
                    return MetaHelper.FindParentCustomEvent(layout, parent, EventName);
                }
            } else {
                return MetaHelper.FindParentCustomEvent(layout, parent, EventName);
            }
        } else {
            return null;
        }
    }

    static FilterTableProperties(layout, item): any {
        const promise = new Promise((resolve, reject) => {
            let retVal;
            MetaHelper.FindTableProperties(layout, item).then((result) => {
                retVal = result;

                if (result && result.Fields) {
                    retVal = JSON.parse(JSON.stringify(retVal));
                    retVal.Fields = result.Fields.filter((element, index, array) => {
                        return MetaHelper.FieldFitsForElementType(element, item.ElementType);
                    });
                    resolve(retVal);
                } else {
                    resolve(null);
                }
            });
        });
        return promise;
    }
    static FieldFitsForElementType(field, elementType): boolean {
        if (field && elementType) {
            const dtObj = MetaHelper.DataTypes[field.Type];
            if (dtObj) {
                const dtFunc = dtObj[elementType];
                if (dtFunc) {
                    return dtFunc(field);
                }
            }
        }
        return false;
    }
    static GetValidControls(item): any {
        const retVal = [];
        if (item && item.Type) {
            const ct = MetaHelper.ControlTypes[item.Type];
            if (ct) {
                Object.keys(ct).forEach((key) => {
                    if (ct[key](item)) {
                        retVal.push(key);
                    }
                });
            }
        }
        return retVal;
    }
}