import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MultiCacheService } from '../../../../cache/multi/cache.service';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { SeriesSettings } from '../../../../models/controls/chart.model';
import { ChartType } from '../../../../models/enums/charttype.enum';
import { AxisType } from '../../../../models/enums/query.enum';
import { BubbleSeriesFormattingTaskExecuter } from '../../../../models/tasks/chartformattasks/bubble.series.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { MultiReportingService } from '../../../../services/reporting.service';
import { ConditionalSeriesFormattingPanel } from '../conditional.series.formatting.panel';

@Component({
    selector: 'bubble-series-formatting-task-panel',
    templateUrl: './bubble.series.formatting.panel.html'
})
export class BubbleSeriesFormattingPanel extends ConditionalSeriesFormattingPanel implements OnInit {
    static TaskID = 'bubbleseriesformatting';
    Members;
    Measures = [];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = BubbleSeriesFormattingPanel.TaskID;
        reg.Caption = '@@Bubble';
        reg.SettingsControl = BubbleSeriesFormattingPanel;
        reg.SettingsHelper = new BubbleSeriesFormattingTaskSettingsHelper();
        reg.Executer = BubbleSeriesFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    constructor(private service: MultiReportingService, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this.DataDescriptionValue) {
            let rMeasureID;
            if (this.LayoutElement && this.LayoutElement.ChartSettings && this.LayoutElement.ChartSettings.Series &&
                this.LayoutElement.ChartSettings.Series.length > 0) {
                const first = this.LayoutElement.ChartSettings.Series[0];
                if (first) {
                    rMeasureID = first['Radius'];
                }
            }
            let area;
            const measureList = [];
            if (this.DataDescriptionValue.ShowMeasureOnAxis == AxisType.X_Axis) {
                if (this.DataDescriptionValue.XLevelNodes && this.DataDescriptionValue.XLevelNodes.Areas) {
                    this.DataDescriptionValue.XLevelNodes.Areas.forEach(x => {
                        if (x.Measures) {
                            x.Measures.forEach(m => {
                                if ('' + m.UniqueID == rMeasureID) {
                                    area = x;
                                }
                                measureList.push({
                                    Caption: m.toString(),
                                    Value: m.UniqueID
                                });
                            });
                        }
                    });
                }
            } else {
                if (this.DataDescriptionValue.YLevelNodes && this.DataDescriptionValue.YLevelNodes.Areas) {
                    this.DataDescriptionValue.YLevelNodes.Areas.forEach(x => {
                        if (x.Measures) {
                            x.Measures.forEach(m => {
                                if ('' + m.UniqueID == rMeasureID) {
                                    area = x;
                                }
                                measureList.push({
                                    Caption: m.toString(),
                                    Value: m.UniqueID
                                });
                            });
                        }
                    });
                }
            }
            this.Measures = measureList;
            if (area && area.Tuples && area.Tuples.length > 0 && this.DataDescriptionValue.DataModelID) {
                const firstTuple = area.Tuples[0];
                if (firstTuple && firstTuple.Levels && firstTuple.Levels.length > 0) {
                    const level = firstTuple.Levels[firstTuple.Levels.length - 1].Level;
                    MultiCacheService.GetLevel(level, this.DataDescriptionValue.DataModelID).then(l => {
                        this.service.GetMembers(l.Parent.Parent.DatasourceID, level, null, null).subscribe(result => {
                            const list = [];
                            if (result) {
                                result.forEach(m => {
                                    list.push({
                                        Caption: m.Caption,
                                        Value: m.ID
                                    });
                                });
                            }
                            this.Members = list;
                            this.cdRef.detectChanges();
                        });
                    });
                }
            }
        }        
    }

    OnSettingsSet() {
        if (this.SettingsValue && !this.SettingsValue.Style) {
            this.SettingsValue.Style = new SeriesSettings();
        }
    }
}

export class BubbleSeriesFormattingTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        if (TaskHelper.IsChartObject(reportObject) && reportObject.LayoutElement.ChartSettings) {
            return reportObject.LayoutElement.ChartSettings.ChartType == ChartType.BubbleChart;
        }
        return false;
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Bubble');
    }
}
