import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { IndexType } from '../../../models/enums/indextype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-getlistitemcalc-settings',
    templateUrl: './getlistitemcalc.settings.html'
})
export class GetListItemCalcSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'getlistitemcalcWFModule';
    Data = new GetListItemCalcSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = GetListItemCalcSettings.ModuleID;
        reg.Caption = '@@Listeneintrag auslesen';
        reg.GroupID = 'listoperations';
        reg.SettingsControl = GetListItemCalcSettings;
        reg.SettingsTypeHelper = new GetListItemCalcSettingsDataHelper();
        reg.Executer = GetListItemCalcModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetListItemCalcSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }
}

export class GetListItemCalcSettingsData extends AServiceWorkflowData {
    IndexType: IndexType = 0;
    Formula: string;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ListOperations.GetListItemCalcSettingsData';
    }
}



export class GetListItemCalcSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new GetListItemCalcSettingsData();
    }
}

export class GetListItemCalcModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (Array.isArray(status.ActualParameter)) {
                const length = status.ActualParameter.length;
                if (length > 0) {
                    switch (status.ActualSettings.IndexType) {
                        case IndexType.First:
                            status.ActualParameter = status.ActualParameter[0];
                            return 0;
                        case IndexType.Last:
                            status.ActualParameter = status.ActualParameter[length - 1];
                            return 0;
                        case IndexType.Custom:
                            try {
                                const calculator = new WorkflowFormulaCalculator(status);
                                const index = calculator.CalcFormula(status.ActualSettings.Formula);
                                if (typeof index === 'number') {
                                    if (index <= 0 || index > length) {
                                        const error = 'GetListItem module: Invalid index (' + index + ') for list with length ' + length;
                                        status.Logger.logError(error);
                                    } else {
                                        status.ActualParameter = status.ActualParameter[index - 1];
                                        return 0;
                                    }
                                } else {
                                    status.Logger.logError('GetListItem module: Formula \'' + status.ActualSettings.Formula +
                                        '\' does not calculate a number');
                                }
                            } catch (ex) {
                                status.Logger.logError('GetListItem module: Error while calculating formula \'' +
                                    status.ActualSettings.Formula + '\' (' + ex + ')');
                            }
                            break;
                    }
                } else {
                    status.Logger.logError('GetListItem module: Empty enumerable.');
                }
            } else {
                status.Logger.logError('GetListItem module: No enumerable parameter set.');
            }
        } else {
            status.Logger.logError('GetListItem module: No settings found.');
        }
        return super.execute(status);
    }
}
