import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslatedString } from '../../models/translatedstring.model';
import { KPIService } from '../../services/kpi.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'kpi-settings',
    templateUrl: '../base.list.settings.html'
})
export class KPICategorySettings extends BaseListSettings {

    //public static GetSettingsEntry() {
    //    return {
    //        Caption: '@@KPI Kategorien',
    //        ID: 'kpiCategorySettings',
    //        Icon: 'table_chart',
    //        Index: 35,
    //        Parent: 'data',
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.KPIKategorieRight',
    //            Value: 1
    //        },
    //        Content: KPICategorySettings
    //    };
    //}

    constructor(private service: KPIService, private translate: TranslateService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.service.GetKPICategoryInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((item) => {
                    list.push({
                        Caption: item.Caption,
                        ID: item.SID
                    });
                });
                handler(list);
            }
        });
    }
    loadData(data: any) {
        if (data) {
            this.service.LoadKPICategory(data).subscribe(result => {
                if (result) {
                    this.setSelectedItem(result);
                }
            });
        }
    }
    getNewItem() {
        return {
            Caption: new TranslatedString(this.translate.instant('@@Neue Kategorie'))
        };
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Kategorie \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Kategorie \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@KPI Kategorie loeschen');
        return retVal;
    }
    delete(data: any, handler: any) {
        this.service.DeleteKPICategory(data).subscribe(() => {
            handler();
        });
    }
    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Kategorie \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@KPI Kategorie speichern');
        return retVal;
    }
    saveInternal(item: any, handler: any) {
        this.service.SaveKPICategory(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }
    getContentType() {
        return KPICategoryDetail;
    }
    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }
}

@Component({
    selector: 'kpi-settings-detail',
    templateUrl: './kpi.category.settings.html',
})
export class KPICategoryDetail extends BaseListDetail {
}
