import { Type } from 'class-transformer';
import { ViewType } from './enums/viewtype.enum';
import { LayoutBase } from './layoutbase.model';
import { LayoutElement } from './layoutelement.model';
import { Variable } from './variable.model';
import { WorkflowDescription } from './workflow/workflow.model';

// @dynamic
export class Layout extends LayoutBase {
    _Id;
    @Type(() => LayoutElement)
    Elements: LayoutElement[] = [];
    @Type(() => LayoutElement)
    Resolutions: LayoutElement[] = [];
    @Type(() => WorkflowDescription)
    Workflows: WorkflowDescription[] = [];
    @Type(() => Variable)
    Variables: Variable[];
    SetViewType(vt: ViewType) {
        this.ViewType = vt;
        if (this.Elements) {
            this.Elements.forEach((item) => {
                item.SetViewType(vt);
            });
        }
        this.ValuesChanged.next(null);
    }
    toJSON() {
        const result = super.toJSON();
        if (result.Variables && result.Variables.length > 0) {
            const copyList = [];
            result.Variables.forEach((v) => {
                const varCopy = Object.assign({}, v);
                delete varCopy.Value;
                copyList.push(varCopy);
            });
            result.Variables = copyList;
        }
        return result;
    }
}
