import { UUID } from 'angular2-uuid';
import { plainToClass, Type } from 'class-transformer';
import { VisibilityType } from '../../enums/oc.enum';
import { SpecialElementType } from '../../enums/specialElementType';
import { DynamicTimeRange } from '../../time/dynamictimerange.model';
import { TimeRangeReference } from '../../time/timerangereference.model';
import { FilterMultidimensional } from './filter/filterMultidimensional.model';
import { MeasureReportParameterReferencing } from './measurereportparameterref.model';
import { Aggregation } from '../../enums';
import { TranslatedString } from '../../translatedstring.model';
import { Base } from '../../base.model';

export class MeasureFunctionOffset {
    OffsetLevel: UUID;
    OffsetValue = 0;
}

export class MeasureFunctionParentReferencing {
    PRHierarchy: UUID;
    PRValue = 0;
}

export class MeasureFunctionAccumulation {
    UseColumns = false;
    UseYTD = false;
    UseMTD = false;
    UseWTD = false;
    UseRows = false;

    toString(): string {
        let result = '';
        if (this.UseColumns) {
            result = 'UseColumns';
            if (this.UseRows) {
                result += ' + UseRows';
            }
        } else if (this.UseRows) {
            result = 'UseRows';
        } else if (this.UseYTD) {
            result = 'UseYTD';
        }
        return result;
    }
}

// @dynamic
export class MeasureInfoDetails {
    @Type(() => DynamicTimeRange)
    MeasureDynamicTimeReferencing: DynamicTimeRange;
    @Type(() => TimeRangeReference)
    TimeRangeReference: TimeRangeReference = new TimeRangeReference();
    @Type(() => MeasureFunctionAccumulation)
    FunctionAccumulation: MeasureFunctionAccumulation;
    @Type(() => MeasureFunctionParentReferencing)
    FunctionParentReferencing: MeasureFunctionParentReferencing;
    @Type(() => MeasureFunctionOffset)
    FunctionOffset: MeasureFunctionOffset = new MeasureFunctionOffset();
    @Type(() => FilterMultidimensional)
    ReferencingSelects: FilterMultidimensional[] = [];
    @Type(() => MeasureReportParameterReferencing)
    ReportParameterReferencing: MeasureReportParameterReferencing;

    get ContainsAnyReferencing(): boolean {
        if (this.ReferencingSelects && this.ReferencingSelects.length > 0) {
            return true;
        }
        if (this.FunctionParentReferencing || this.TimeRangeReference || this.MeasureDynamicTimeReferencing) {
            return true;
        }
        return this.ReportParameterReferencing && this.ReportParameterReferencing.ParameterObjects &&
            this.ReportParameterReferencing.ParameterObjects.some(x => x.Dimension &&
                x.SelectedReportParameter && x.SelectedReportParameter.length > 0);
    }
}
// @dynamic
export class MeasureInfo extends Base {

    static Keys: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    QueryAttribute = false;
    Attribute: string;
    AttributeHierarchy: UUID;
    @Type(() => MeasureInfoDetails)
    Details: MeasureInfoDetails = new MeasureInfoDetails();
    Variable = '';
    SpecialElementType: SpecialElementType = SpecialElementType.None;
    Measure: UUID;
    Cube: UUID;
    // IdentityString: string; //??
    Position = -1;
    UniqueID = -1;
    Aggregation: Aggregation = Aggregation.Sum;
    @Type(() => TranslatedString)
    Caption: TranslatedString;

    VisibilityType: VisibilityType = VisibilityType.VisibleDefault;

    

    get IsVisible() {
        return this.VisibilityType !== VisibilityType.NonVisibleDefault && this.VisibilityType !== VisibilityType.NonVisibleForce;
    }


  

    public constructor(init?: Partial<MeasureInfo>) {
        super();
        Object.assign(this, init);
    }

    duplicate(uniqueid: number): MeasureInfo {
        const result = this.clone();
        result.UniqueID = uniqueid;
        return result;
    }

    clone(): MeasureInfo {
        let retVal;
        retVal = plainToClass(MeasureInfo, JSON.parse(JSON.stringify(this)));
        return retVal;
    }

    ToStringWithNewLine(): string {
        return TranslatedString.GetTranslation(this.Caption);
    }

    toString(): string {
        let result = TranslatedString.GetTranslation(this.Caption);
        if (result) {
            result = result.replace(/(\r\n|\n|\r)/gm, '');
        }
        return result;
    }

    get Key(): string {
        let mod: number;
        let col: number = this.Position + 1;
        const result: string[] = [];
        result[0] = MeasureInfo.Keys[0];
        result[1] = MeasureInfo.Keys[0];
        for (let i = 1; i >= 0; i--) {
            mod = (col - i) % 26;
            col = Math.floor((col - mod) / 26);
            if (mod > -1) {
                result[i] = MeasureInfo.Keys[mod];
            }
        }
        return result[0] + result[1];
    }
}
