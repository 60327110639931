import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class PermissionsService extends RequestBase {
    BasePath = 'api/permissions';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ReadById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadById?id=' + id, this.options);
    }
    ReadByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByKey?key=' + key, this.options);
    }
    Read(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Read', this.options);
    }
    AddOrUpdate(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdate', data, this.options);
    }
    AddOrUpdateWithKey(data, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateWithKey?key=' + key, data, this.options);
    }
    Remove(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Remove?id=' + id, this.options);
    }

    ReadNavigationById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadNavigationById?id=' + id, this.options);
    }
    ReadNavigationByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadNavigationByKey?key=' + key, this.options);
    }
    ReadNavigation(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadNavigation', this.options);
    }
    AddOrUpdateNavigation(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateNavigation', data, this.options);
    }
    AddOrUpdateNavigationWithKey(data, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateNavigationWithKey?key=' + key, data, this.options);
    }
    RemoveNavigation(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveNavigation?id=' + id, this.options);
    }

    UpdateUserRoles(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/UpdateUserRoles', obj, this.options);
    }
}
