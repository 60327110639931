import {ComponentPortal} from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DomHandler} from 'primeng/dom';
import {CheckBoxThemeControl} from '../../../appbuilder/controls/checkbox/checkbox.control';
import {ComboboxThemeControl} from '../../../appbuilder/controls/combobox/combobox.theme.control';
import {TextboxThemeControl} from '../../../appbuilder/controls/textbox/textbox.theme.control';
import {
    TranslationTextBoxThemeControl
} from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import {GenericMenuTab} from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import {BasePanel} from '../../../appbuilder/panels/base.panel';
import {NotificationHelper} from '../../../helpers/notification.helper';
import {RTLHelper} from '../../../helpers/rtl.helper';
import {KeyFilter} from '../../../models/enums/keyfilter.enum';
import {PropertyGroupDisplay} from '../../../models/enums/propertygroupdisplay.enum';
import {ElementProperty} from '../../../models/layoutbase.model';
import {
    PROPERTIES,
    PROPERTYGROUPS,
} from '../../../services/dynamic.component.service';
import {LayoutService} from '../../../services/layout.service';
import {IBaseComponent} from '../base.component';
import {MediaService} from "../../../services/media.service";

@Component({
    selector: 'evi-googlemap',
    templateUrl: './gmap.control.html',
    styleUrls: ['./gmap.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GMapControl extends IBaseComponent {
    static Type: any = 'googlemap';
    apiLoaded: Observable<boolean>;
    static Default = {
        Type: 'googlemap',
        Layout: {
            Longitude: 69.3451, Latitude: 30.3753,
            Width: { Type: 0, Value: 400 },
            Height: { Type: 0, Value: 400 }
        },
    };
    GeneralSettings;

    ControlInitialized() {
    }

    constructor(
        cdRef: ChangeDetectorRef,
        public httpClient: HttpClient,
        @Inject(LayoutService.CONTAINER_DATA) public data,
    ) {
        super(cdRef, data);
        let settings = localStorage.getItem('GeneralSettings');
        if (settings) {
            this.GeneralSettings = JSON.parse(settings);
            const key = this.GeneralSettings?.GoogleMapsAPIKey || '';

            MediaService.IconURL.subscribe(url => {
                if (url) {
                    this.MarkerIconURL = url;
                }
            })
            this.LoadGoogleMap(key);
        }

    }
    LoadGoogleMap(key) {
        const url = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=visualization`
        this.apiLoaded = this.httpClient.jsonp(url, 'callback')
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    @Input()
    get Longitude() {
        return this.LayoutElement.Longitude;
    }

    set Longitude(val) {
        this.LayoutElement.Longitude = val;
        this.LongitudeChange.emit(this.LayoutElement.Longitude);
    }

    @Output() LongitudeChange = new EventEmitter<any>();

    @Input()
    get Latitude() {
        return this.LayoutElement.Latitude;
    }

    set Latitude(val) {
        this.LayoutElement.Latitude = val;
        this.LatitudeChange.emit(this.LayoutElement.Latitude);
    }

    @Output() LatitudeChange = new EventEmitter<any>();

    MarkerIconURL = '';
    IconValue;
    @Input()
    get MarkerIcon() {
        if (this.IconValue != null) {
            return this.IconValue;
        }
        return this.LayoutElement.MarkerIcon;
    }
    set Icon(val) {
        this.IconValue = val;
        this.cdRef.detectChanges();
    }

    display: any;
    center: google.maps.LatLngLiteral;
    zoom: 4;
    markerOptions: google.maps.MarkerOptions = {draggable: true};
    markerPositions: google.maps.LatLngLiteral = {
        lat: 30.3756933837723,
        lng: 69.34467084655763,
    }

    markerIconSize: google.maps.Size = {height: 30, width: 30, equals(other: google.maps.Size | null): boolean {
        return true}};
    get markerIcon(): string | google.maps.Icon {
        console.log('this.MarkerIcon', this.MarkerIcon);
        if(this.MarkerIcon) {
            return {
                url: this.MarkerIconURL + this.MarkerIcon + '.svg',
                scaledSize: this.markerIconSize
            };
        }
        return;
    }

    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.Latitude = event.latLng.toJSON();
    }

    move(event: google.maps.MapMouseEvent) {
        // if (event.latLng != null) this.Longitude = event.latLng.toJSON();
    }
}

export class GoogleMapPanel extends BasePanel {
    static override SIDS = ['7ca4f1c3-f490-4565-ae0a-e312868f9d8c'];

    static InitPanel() {
        PROPERTYGROUPS.push({
            SID: '7ca4f1c3-f490-4565-ae0a-e312868f9d8c',
            ID: 'GoogleMap',
            Caption: '@@GoogleMap',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'googlemap';
            },
        });

        PROPERTIES.push({
            ID: 'Longitude',
            Parent: 'GoogleMap',
            Content: new ComponentPortal(TextboxThemeControl),
            Label: '@@Longitude',
            Column: 1,
            Row: 1,
            InitArgs: {
                InputType: 'number',
            },
        });
        PROPERTIES.push({
            ID: 'Latitude',
            Parent: 'GoogleMap',
            Content: new ComponentPortal(TextboxThemeControl),
            Label: '@@Latitude',
            Column: 1,
            Row: 2,
            InitArgs: {
                InputType: 'number',
            },
        });

        MediaService.IconList.subscribe((result) => {
            if (result) {
                let icons = [];
                result.forEach((icon) => {
                    icons.push({ Caption: icon, Icon: icon, Value: icon })
                });
                PROPERTIES.push({
                    ID: "MarkerIcon",
                    Parent: "GoogleMap",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@Icon",
                    Column: 1,
                    Row: 3,
                    InitArgs: {
                        Placeholder: "@@Icon",
                        Multiple: false,
                        ItemsSource: icons,
                        ValueMemberPath: "Value",
                        DisplayMemberPath: "Caption",
                        IconMemberPath: "Icon"
                    }
                });
            }
        });
    }
}
