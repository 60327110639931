import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-generate-document-structure-settings-item',
    templateUrl: 'generate.document.structure.settings.html'
})
export class GenerateDocumentStructureSettings extends FormulaWorkflowDialogContent {
    Data = new GenerateDocumentStructureSettingsData();
    VariableNodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'generateDocumentStructureWFModule';
        reg.Caption = '@@Dokumentstruktur auslesen';
        reg.GroupID = 'documentoperations';
        reg.Index = 10;
        reg.SettingsControl = GenerateDocumentStructureSettings;
        reg.SettingsTypeHelper = new GenerateDocumentStructureSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GenerateDocumentStructureSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        const errors = [];
        if (!this.Data.DocumentKey) {
            errors.push(TranslateHelper.TranslatorInstance.instant('@@Bitte vergeben Sie einen Status Key fuer das Dokument'));
        }
        if (!this.Data.UseForState && !this.Data.StatusKey) {
            const text = '@@Bitte vergeben Sie einen Status Key fuer das Ergebnis oder setzen Sie das Ergebnis als aktuellen Parameter';
            errors.push(TranslateHelper.TranslatorInstance.instant(text));
        }
        if (errors.length > 0) {
            retVal.IsCorrect = false;
            retVal.Error = errors.join('\n');
        }
        return retVal;
    }

    getResult() {
        return this.Data;
    }

    onVariableSelect(event) {
        if (event) {
            this.Data.DocumentKey = event.Caption;
        }
    }
}

export class GenerateDocumentStructureSettingsData extends AServiceWorkflowData {
    DocumentKey: string;
    StatusKey: string;
    UseForState = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DocumentOperations.GenerateDocumentStructure.GenerateDocumentStructureSettingsData';
    }
}

export class GenerateDocumentStructureSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new GenerateDocumentStructureSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}
