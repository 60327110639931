import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientHelper } from '../helpers/client.helper';
import { BasicAuthInterceptor } from './basic_auth.interceptor';
import { RequestBase } from './request-base';

@Injectable()
export class CollaborationService extends RequestBase {
    BasePath = 'api/collaboration';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    TrySendUnsubscribe(channel) {
        BasicAuthInterceptor.TryFetch(this.API_BASE_URL + this.BasePath + '/Unsubscribe', 'POST', {
            Channel: channel,
            ClientID: ClientHelper.ClientID
        }).then();
    }

    SendSubscription(subscription): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SendSubscription', subscription, this.options);
    }

    SubscriptionAlive(channel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/SubscriptionAlive?channel=' + channel, this.options);
    }

    GetChannelInfo(channel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetChannelInfo?channel=' + channel, this.options);
    }

    SendCheckOut(checkOut): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SendCheckOut', checkOut, this.options);
    }

    SendClientChangeInfo(changeInfo): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SendClientChangeInfo', changeInfo, this.options);
    }

    GetAllClientChanges(channel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllClientChanges?channel=' + channel, this.options);
    }
}
