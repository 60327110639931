import { UUID } from 'angular2-uuid';
import { DataSwitchSettings, DataSwitchSettingsData } from '../workflow/modules/dataswitch/dataswitch.settings';
import { ExecuteWorkflowData, ExecuteWorkflowSettings } from '../workflow/modules/executeWorkflow/executeWorkflow.settings';
import { ForEachLoopSettingsData } from '../workflow/modules/forEach/forEachLoop.settings';
import { SetControlPropertiesSettings, SetControlPropertiesSettingsData } from '../workflow/modules/layout/elemProperty/set.control.properties.settings';
import {
    RemoveMultiStyleSettings, RemoveMultiStyleSettingsData
} from '../workflow/modules/layout/setStyle/remove.multi.style.settings';
import { SetMultiStyleSettings, SetMultiStyleSettingsData } from '../workflow/modules/layout/setStyle/set.multi.style.settings';
import { LoginSettings, LoginSettingsData } from '../workflow/modules/login/login.settings';
import {
    ReadMultiPropertySettings, ReadMultiPropertySettingsData
} from '../workflow/modules/readProperty/read.multi.property.settings';
import { RestCallSettings, RestCallSettingsData } from '../workflow/modules/restCall/restCall.settings';
import { ReadMultiVariableSettingsData } from '../workflow/modules/variables/readVariable/read.multi.variable.settings';
import { ReadVariableSettings } from '../workflow/modules/variables/readVariable/readVariable.settings';
import { SetMultiVariableSettingsData } from '../workflow/modules/variables/setVariable/set.multi.variable.settings';
import { SetVariableSettings } from '../workflow/modules/variables/setVariable/setVariable.settings';
import { SetWorkflowStatusData, SetWorkflowStatusSettings } from '../workflow/modules/wfStatus/setWFStatus.settings';
import { LayoutUnit } from '../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../models/basic/layoutunitthickness.model';
import { SideNav } from '../models/controls/sidenav.model';
import { Alignment } from '../models/enums/alignment.enum';
import { EventActionType } from '../models/enums/eventactiontype.enum';
import { GradientType } from '../models/enums/gradienttype.enum';
import { MethodType } from '../models/enums/methodtype.enum';
import { Overflow } from '../models/enums/overflow.enum';
import { ProviderChoice } from '../models/enums/providerchoice.enum';
import { UnitType } from '../models/enums/unittype.enum';
import { Layout } from '../models/layout.model';
import { LayoutBase, StyleBase } from '../models/layoutbase.model';
import { LayoutElement } from '../models/layoutelement.model';
import { Border } from '../models/style/border.model';
import { Color } from '../models/style/color.model';
import { Font } from '../models/style/font.model';
import { Gradient } from '../models/style/gradient.model';
import { GradientStopColor } from '../models/style/gradientstopcolor.model';
import { TranslatedString } from '../models/translatedstring.model';
import { Variable } from '../models/variable.model';
import { WorkflowData, WorkflowDescription, WorkflowModuleData } from '../models/workflow/workflow.model';
import { LayoutService } from '../services/layout.service';
import { SettingsService } from '../services/settings.service';
import { PermissionHelper } from './permissions.helper';
import { StartWfModuleData, StartWfModuleSettings } from '../workflow/modules/startend/start.wfmodule';
import { DefineMultiValuesSettingsData } from '../workflow/modules/definevalue/define.multi.values.settings';
import { DefineValueSettings } from '../workflow/modules/definevalue/define.value.settings';
import { ExecuteScriptSettings, ExecuteScriptSettingsData } from '../workflow/modules/script/execute.script.settings';
import { SetMultiElemValueSettings, SetMultiElemValueSettingsData } from '../workflow/modules/layout/elemValue/set.multi.elem.value.settings';
import { log } from 'mathjs';

export class NavigationHelper {
    
    public static CopyID;
    public static CutID;

    public static IsNavigationTree(selectedItem) {
        return selectedItem != null && selectedItem.Element != null && selectedItem.Element.DataSourceID == '7b0af7f2-9030-4e58-a54e-3009258c67b2';
    }

    public static GetSelectedTreeViewNodeID(selectedItem) {
        var id = '';
        if (selectedItem.Element.SelectedNodes && selectedItem.Element.SelectedNodes.length > 0) {
            var node = selectedItem.Element.SelectedNodes[0];
            if (node.Data && node.Data._Id) {
                id = node.Data._Id;
            }
        }
        return id;
    }

    public static GetSelectedTreeViewNode(selectedItem) {
        var tvn = null;
        if (selectedItem.Element.SelectedNodes && selectedItem.Element.SelectedNodes.length > 0) {
            var node = selectedItem.Element.SelectedNodes[0];
            if (node.Data && node.Data._Id) {
                tvn = node.Data;
            }
        }
        return tvn;
    }

    static BuildStructure(naviNodes) {
        const root = new NavigationStructureItem(null);
        const otherNodes: NavigationStructureItem[] = [];
        const nodeMap = new Map<string, NavigationStructureItem>();
        naviNodes.forEach(nn => {
            const node = new NavigationStructureItem(nn);
            nodeMap.set(nn._Id, node);
            if (nn.ParentID) {
                const parent = nodeMap.get(nn.ParentID);
                if (parent) {
                    parent.AddChild(node);
                } else {
                    otherNodes.push(node);
                }
            } else {
                root.AddChild(node);
            }
        });
        otherNodes.forEach(node => {
            const parent = nodeMap.get(node.ParentID);
            if (parent) {
                parent.AddChild(node);
            }
        });
        return root.GetStructure().Children;
    }

    static BuildNavigation(naviNodes) {
        const structure = NavigationHelper.BuildStructure(naviNodes);
        const perm = PermissionHelper.ActiveNavigationPermissions.getValue();
        return {
            NavigationItems: NavigationHelper.GetNavigationItems(structure, perm)
        };
    }

    private static GetNavigationItems(structList, perm) {
        const retVal = [];
        if (structList) {
            structList.forEach(x => {
                const node = {
                    Id: x.Data._Id,
                    Label: TranslatedString.GetTranslation(x.Data.Caption),
                    Url: null,
                    Icon: null,
                    children: null,
                    IsVisible: true,
                    InVisible: false
                };
                if (x.Data.Url) {
                    node.Url = '/' + x.Data.StructureKey + x.Data.Url;
                }
                if (x.Data.Icon) {
                    node.Icon = x.Data.Icon;
                }
                if (perm) {
                    const actPerm = perm[node.Id];
                    if (typeof actPerm === 'boolean') {
                        node.InVisible = actPerm;
                    }
                }
                if (x.Data.Hidden) {
                    node.InVisible = true;
                }
                const children = NavigationHelper.GetNavigationItems(x.Children, perm);
                if (children.length > 0) {
                    node.children = children;
                }
                retVal.push(node);
            });
        }
        return retVal;
    }

    private static GetWFModule(id: number): WorkflowModuleData {
        const retVal = new WorkflowModuleData();
        retVal.ID = id;
        const idMod = id % 4;
        retVal.XPos = 50 + Math.floor(id / 4) * 285 + idMod * 25;
        retVal.YPos = 50 + idMod * 150;
        return retVal;
    }

    static GetDefaultBaseLayout(): Layout {
        const l = new Layout();

        l.Visible = true;
        l.Name = 'BaseLayout';
        l['ElementType'] = 'grid';
        l['SideNav'] = SideNav.GetDefault();

        const outerCol = new LayoutBase();
        outerCol.Size = new LayoutUnit();
        outerCol.Size.Type = UnitType.Rest;
        l['ColumnDefinitions'] = [outerCol];

        const outerFirstRow = new LayoutBase();
        outerFirstRow.Size = new LayoutUnit();
        outerFirstRow.Size.Type = UnitType.Pixel;
        outerFirstRow.Size.Value = 50;
        const outerSecondRow = new LayoutBase();
        outerSecondRow.Size = new LayoutUnit();
        outerSecondRow.Size.Type = UnitType.Rest;
        l['RowDefinitions'] = [outerFirstRow, outerSecondRow];

        const innerGrid = new LayoutElement();

        innerGrid.Visible = true;
        innerGrid.Name = 'Header';
        innerGrid.ElementType = 'grid';

        innerGrid.BackgroundColor = new Gradient();
        innerGrid.BackgroundColor.Type = GradientType.Solid;
        const color = new GradientStopColor();
        color.Color = Color.FromHex('#5E5E5E');
        innerGrid.BackgroundColor.Colors.push(color);

        innerGrid.Font = new Font();
        innerGrid.Font.FontColor = Color.FromHex('#F5F5F5');

        const firstCol = new LayoutBase();
        firstCol.Size = new LayoutUnit();
        firstCol.Size.Type = UnitType.Pixel;
        firstCol.Size.Value = 50;
        const secCol = new LayoutBase();
        secCol.Size = new LayoutUnit();
        secCol.Size.Type = UnitType.Rest;
        const thirdCol = new LayoutBase();
        thirdCol.Size = new LayoutUnit();
        thirdCol.Size.Type = UnitType.Pixel;
        thirdCol.Size.Value = 50;
        const fourthCol = new LayoutBase();
        fourthCol.Size = new LayoutUnit();
        fourthCol.Size.Type = UnitType.Pixel;
        fourthCol.Size.Value = 50;

        innerGrid['ColumnDefinitions'] = [firstCol, secCol, thirdCol, fourthCol];

        const innerRow = new LayoutBase();
        innerRow.Size = new LayoutUnit();
        innerRow.Size.Type = UnitType.Rest;

        innerGrid['RowDefinitions'] = [innerRow];

        const sideNavToggle = new LayoutElement();
        sideNavToggle.Name = 'sidenav-toggle_1';
        sideNavToggle.Visible = true;
        sideNavToggle.Editable = true;
        sideNavToggle.ElementType = 'sidenav-toggle';
        innerGrid.Elements.push(sideNavToggle);

        const messageSetting = new LayoutElement();
        messageSetting.Name = 'messagecenter_1';
        messageSetting.Visible = true;
        messageSetting.Editable = true;
        messageSetting.ElementType = 'messagecenter';
        messageSetting.Column = 3;
        innerGrid.Elements.push(messageSetting);

        const userMenu = new LayoutElement();
        userMenu.Name = 'usermenu_1';
        userMenu.Visible = true;
        userMenu.Editable = true;
        userMenu.ElementType = 'usermenu';
        userMenu.Column = 4;
        innerGrid.Elements.push(userMenu);

        l.Elements.push(innerGrid);

        const content = new LayoutElement();
        content.Name = 'Content';
        content.Visible = true;
        content.Row = 2;
        content.ElementType = 'content';
        content['CellOverflow'] = Overflow.hidden;
        l.Elements.push(content);

        return l;
    }

    static GetDefaultLoginLayout(): Layout {
        //#region Layout
        const l = new Layout();
        l.Visible = true;
        l.Name = 'Login';
        l.BackgroundColor = new Gradient();
        l.BackgroundColor.Type = GradientType.Solid;
        const outerColor = new GradientStopColor();
        outerColor.Color = Color.FromHex('#808080');
        l.BackgroundColor.Colors.push(outerColor);

        l['ElementType'] = 'grid';

        const outerCol = new LayoutBase();
        outerCol.Size = new LayoutUnit();
        outerCol.Size.Type = UnitType.Rest;
        l['ColumnDefinitions'] = [outerCol];

        const outerRow = new LayoutBase();
        outerRow.Size = new LayoutUnit();
        outerRow.Size.Type = UnitType.Rest;
        l['RowDefinitions'] = [outerRow];

        const idVar = new Variable();
        idVar.Name = 'ID';
        idVar.Type = 'System.String';

        const mfaVar = new Variable();
        mfaVar.Name = 'MFA';
        mfaVar.Type = 'System.Boolean';
        mfaVar.Formula = 'false';
        l.Variables = [idVar, mfaVar];

        const innerGrid = new LayoutElement();

        innerGrid.Visible = true;
        innerGrid.Name = 'InnerFlex';
        innerGrid.ElementType = 'flex';
        innerGrid['Orientation'] = 1;
        innerGrid.HorizontalContentAlignment = Alignment.Center;
        innerGrid.VerticalContentAlignment = Alignment.Center;
        innerGrid.Width = new LayoutUnit();
        innerGrid.Width.Type = UnitType.Pixel;
        innerGrid.Width.Value = 371;
        innerGrid.Height = new LayoutUnit();
        innerGrid.Height.Type = UnitType.Auto;
        innerGrid.BackgroundColor = new Gradient();
        innerGrid.BackgroundColor.Type = GradientType.Solid;
        innerGrid.Border = new Border();
        innerGrid.Border.BorderRadius.TopLeft.Type = UnitType.Pixel;
        innerGrid.Border.BorderRadius.TopLeft.Value = 12;
        innerGrid.Border.BorderRadius.TopRight.Type = UnitType.Pixel;
        innerGrid.Border.BorderRadius.TopRight.Value = 12;
        innerGrid.Border.BorderRadius.BottomLeft.Type = UnitType.Pixel;
        innerGrid.Border.BorderRadius.BottomLeft.Value = 12;
        innerGrid.Border.BorderRadius.BottomRight.Type = UnitType.Pixel;
        innerGrid.Border.BorderRadius.BottomRight.Value = 12;
        const color = new GradientStopColor();
        color.Color = Color.FromHex('#D3D3D3');
        innerGrid.BackgroundColor.Colors.push(color);

        const errorText = new LayoutElement();
        errorText.Visible = false;
        errorText.Name = 'ErrorText';
        errorText.ElementType = 'textblock';
        errorText.Caption = 'Benutzername oder Passwort falsch! Bitte kontrollieren Sie die Eingabe.';
        errorText.Font = new Font();
        errorText.Font.FontColor = Color.FromHex('FF0000');
        errorText.Width = new LayoutUnit();
        errorText.Width.Type = UnitType.Pixel;
        errorText.Width.Value = 265;
        errorText.HorizontalContentAlignment = Alignment.Center;
        errorText.HorizontalTextAlignment = Alignment.Center;
        errorText.Padding = new LayoutUnitThickness();
        errorText.Padding.Top.Type = UnitType.Pixel;
        errorText.Padding.Top.Value = 10;
        innerGrid.Elements.push(errorText);

        const iconButton = new LayoutElement();
        iconButton.Visible = true;
        iconButton.Name = 'LoginIcon';
        iconButton.ElementType = 'icon';
        iconButton['Icon'] = 'account_circle';
        iconButton.Font = new Font();
        iconButton.Font.FontColor = new Color();
        iconButton.Font.FontColor.ColorReference = LayoutService.PrimarySID;
        const iconWidth = new LayoutUnit();
        iconWidth.Type = UnitType.Pixel;
        iconWidth.Value = 300;
        iconButton['IconWidth'] = iconWidth;
        const iconHeight = new LayoutUnit();
        iconHeight.Type = UnitType.Pixel;
        iconHeight.Value = 300;
        iconButton['IconHeight'] = iconHeight;
        iconButton.HorizontalContentAlignment = Alignment.Center;
        innerGrid.Elements.push(iconButton);

        const loginText = new LayoutElement();
        loginText.Visible = true;
        loginText.Name = 'LoginText';
        loginText.ElementType = 'textblock';
        loginText.Caption = 'Login';
        loginText.Font = new Font();
        loginText.Font.FontSize = 18;
        loginText.Font.FontColor = Color.FromHex('#585858');
        loginText.Width = new LayoutUnit();
        loginText.Width.Type = UnitType.Percent;
        loginText.Width.Value = 100;
        loginText.HorizontalTextAlignment = Alignment.Center;
        innerGrid.Elements.push(loginText);

        const nameFlex = new LayoutElement();
        nameFlex.Visible = true;
        nameFlex.Name = 'NameFlex';
        nameFlex.ElementType = 'flex';
        nameFlex['Orientation'] = 0;
        nameFlex.Padding = new LayoutUnitThickness();
        nameFlex.Padding.Left.Type = UnitType.Pixel;
        nameFlex.Padding.Left.Value = 16;
        nameFlex.Padding.Right.Type = UnitType.Pixel;
        nameFlex.Padding.Right.Value = 16;

        const userIcon = new LayoutElement();
        userIcon.Visible = true;
        userIcon.Name = 'UserIcon';
        userIcon.ElementType = 'icon';
        userIcon.HorizontalContentAlignment = Alignment.Center;
        userIcon.VerticalContentAlignment = Alignment.Center;
        userIcon.Height = new LayoutUnit();
        userIcon.Height.Type = UnitType.Percent;
        userIcon.Height.Value = 100;
        userIcon['Icon'] = 'person';
        userIcon.Font = new Font();
        userIcon.Font.FontColor = new Color();
        userIcon.Font.FontColor.ColorReference = LayoutService.PrimarySID;
        userIcon.Margin = new LayoutUnitThickness();
        userIcon.Margin.Right.Type = UnitType.Pixel;
        userIcon.Margin.Right.Value = 5;
        nameFlex.Elements.push(userIcon);

        const nameBox = new LayoutElement();
        nameBox.Visible = true;
        nameBox.Editable = true;
        nameBox.Name = 'LoginName';
        nameBox.ElementType = 'textbox';
        nameBox['Placeholder'] = 'USERNAME';
        nameBox['FlexFill'] = true;
        nameFlex.Elements.push(nameBox);

        innerGrid.Elements.push(nameFlex);

        const pwFlex = new LayoutElement();
        pwFlex.Visible = true;
        pwFlex.Name = 'PasswordFlex';
        pwFlex.ElementType = 'flex';
        pwFlex['Orientation'] = 0;
        pwFlex.Padding = new LayoutUnitThickness();
        pwFlex.Padding.Left.Type = UnitType.Pixel;
        pwFlex.Padding.Left.Value = 16;
        pwFlex.Padding.Right.Type = UnitType.Pixel;
        pwFlex.Padding.Right.Value = 16;

        const pwIcon = new LayoutElement();
        pwIcon.Visible = true;
        pwIcon.Name = 'PasswordIcon';
        pwIcon.ElementType = 'icon';
        pwIcon.HorizontalContentAlignment = Alignment.Center;
        pwIcon.VerticalContentAlignment = Alignment.Center;
        pwIcon.Height = new LayoutUnit();
        pwIcon.Height.Type = UnitType.Percent;
        pwIcon.Height.Value = 100;
        pwIcon['Icon'] = 'lock';
        pwIcon.Font = new Font();
        pwIcon.Font.FontColor = new Color();
        pwIcon.Font.FontColor.ColorReference = LayoutService.PrimarySID;
        pwIcon.Margin = new LayoutUnitThickness();
        pwIcon.Margin.Right.Type = UnitType.Pixel;
        pwIcon.Margin.Right.Value = 5;
        pwFlex.Elements.push(pwIcon);

        const pwBox = new LayoutElement();
        pwBox.Visible = true;
        pwBox.Editable = true;
        pwBox.Name = 'LoginPassword';
        pwBox.ElementType = 'textbox';
        pwBox['Placeholder'] = 'PASSWORD';
        pwBox['InputType'] = 'password';
        pwBox['FlexFill'] = true;
        pwFlex.Elements.push(pwBox);

        innerGrid.Elements.push(pwFlex);

        const mfaFlex = new LayoutElement();
        mfaFlex.Visible = false;
        mfaFlex.Name = 'MFAFlex';
        mfaFlex.ElementType = 'flex';
        mfaFlex['Orientation'] = 0;
        mfaFlex.Padding = new LayoutUnitThickness();
        mfaFlex.Padding.Left.Type = UnitType.Pixel;
        mfaFlex.Padding.Left.Value = 16;
        mfaFlex.Padding.Right.Type = UnitType.Pixel;
        mfaFlex.Padding.Right.Value = 16;

        const mfaIcon = new LayoutElement();
        mfaIcon.Visible = true;
        mfaIcon.Name = 'MFAIcon';
        mfaIcon.ElementType = 'icon';
        mfaIcon.HorizontalContentAlignment = Alignment.Center;
        mfaIcon.VerticalContentAlignment = Alignment.Center;
        mfaIcon.Height = new LayoutUnit();
        mfaIcon.Height.Type = UnitType.Percent;
        mfaIcon.Height.Value = 100;
        mfaIcon['Icon'] = 'key';
        mfaIcon.Font = new Font();
        mfaIcon.Font.FontColor = new Color();
        mfaIcon.Font.FontColor.ColorReference = LayoutService.PrimarySID;
        mfaIcon.Margin = new LayoutUnitThickness();
        mfaIcon.Margin.Right.Type = UnitType.Pixel;
        mfaIcon.Margin.Right.Value = 5;
        mfaFlex.Elements.push(mfaIcon);

        const mfaBox = new LayoutElement();
        mfaBox.Visible = true;
        mfaBox.Editable = true;
        mfaBox.Name = 'LoginMFA';
        mfaBox.ElementType = 'textbox';
        mfaBox['Placeholder'] = 'CODE';
        mfaBox['FlexFill'] = true;
        mfaFlex.Elements.push(mfaBox);

        innerGrid.Elements.push(mfaFlex);

        const providerBox = new LayoutElement();
        providerBox.Visible = true;
        providerBox.Editable = true;
        providerBox.Name = 'Providers';
        providerBox.ElementType = 'combobox';
        providerBox['Placeholder'] = 'SELECTSECURITYPROVIDERTYPE';
        providerBox['DisplayMemberPath'] = 'Name';
        providerBox.Padding = new LayoutUnitThickness();
        providerBox.Padding.Left.Type = UnitType.Pixel;
        providerBox.Padding.Left.Value = 40;
        providerBox.Padding.Right.Type = UnitType.Pixel;
        providerBox.Padding.Right.Value = 16;
        providerBox.Height = new LayoutUnit();
        providerBox.Height.Type = UnitType.Pixel;
        providerBox.Height.Value = 42;
        innerGrid.Elements.push(providerBox);

        const loginButton = new LayoutElement();
        loginButton.Visible = true;
        loginButton.Editable = true;
        loginButton.Name = 'LoginButton';
        loginButton.ElementType = 'button';
        loginButton.Caption = 'Login';
        loginButton['ButtonType'] = 'mat-button';
        loginButton.Width = new LayoutUnit();
        loginButton.Width.Value = 100;
        loginButton.Width.Type = UnitType.Percent;
        loginButton.BackgroundColor = new Gradient();
        loginButton.BackgroundColor.Type = GradientType.Solid;
        const loginColor = new GradientStopColor();
        loginColor.Color = new Color();
        loginColor.Color.ColorReference = LayoutService.PrimarySID;
        loginButton.BackgroundColor.Colors.push(loginColor);
        loginButton.Font = new Font();
        loginButton.Font.FontColor = Color.FromHex('#FFFFFF');
        loginButton.Border = new Border();
        loginButton.Border.BorderRadius.TopLeft.Type = UnitType.Pixel;
        loginButton.Border.BorderRadius.TopLeft.Value = 4;
        loginButton.Border.BorderRadius.TopRight.Type = UnitType.Pixel;
        loginButton.Border.BorderRadius.TopRight.Value = 4;
        loginButton.Border.BorderRadius.BottomLeft.Type = UnitType.Pixel;
        loginButton.Border.BorderRadius.BottomLeft.Value = 4;
        loginButton.Border.BorderRadius.BottomRight.Type = UnitType.Pixel;
        loginButton.Border.BorderRadius.BottomRight.Value = 4;
        loginButton.Padding = new LayoutUnitThickness();
        loginButton.Padding.Left.Type = UnitType.Pixel;
        loginButton.Padding.Left.Value = 16;
        loginButton.Padding.Top.Type = UnitType.Pixel;
        loginButton.Padding.Top.Value = 16;
        loginButton.Padding.Bottom.Type = UnitType.Pixel;
        loginButton.Padding.Bottom.Value = 16;
        loginButton.Padding.Right.Type = UnitType.Pixel;
        loginButton.Padding.Right.Value = 16;
        innerGrid.Elements.push(loginButton);

        l.Elements.push(innerGrid);
        //#endregion

        //#region Workflows
        let wfID = 0;
        //#region InitProviders
        const initWorkflow = new WorkflowDescription();
        initWorkflow.Caption = 'InitProviders';
        initWorkflow.ID = '23cfafd8-c3d9-41ed-8ae7-7f2bb26aa73f';
        initWorkflow.Data = new WorkflowData();

        const initStart = NavigationHelper.GetWFModule(wfID++);
        initStart.Module = StartWfModuleSettings.ModuleID;
        initStart.Settings = new StartWfModuleSettings();
        initWorkflow.Data.Modules.push(initStart);

        const initRest = new RestCallSettingsData();
        initRest.UseServiceURL = true;
        initRest.Type = MethodType.Get;
        initRest.Path = 'config/api/security/GetSecurityProviders?apibase=' + SettingsService.API_BASE_URL.getValue();
        const initRestModule = NavigationHelper.GetWFModule(wfID++);
        initRestModule.Module = RestCallSettings.ModuleID;
        initRestModule.Settings = initRest;
        initWorkflow.Data.Modules.push(initRestModule);

        const initSetStatus = new SetWorkflowStatusData();
        initSetStatus.StatusKey = 'providers';
        const initSetStatusModule = NavigationHelper.GetWFModule(wfID++);
        initSetStatusModule.Module = SetWorkflowStatusSettings.ModuleID;
        initSetStatusModule.Settings = initSetStatus;
        initWorkflow.Data.Modules.push(initSetStatusModule);

        const initSetProp = new SetControlPropertiesSettingsData();
        initSetProp.Controls.push({
            ControlName: providerBox.Name,
            Properties: [
                {
                    PropertyName: 'ItemsSource',
                    Formula: initSetStatus.StatusKey
                }
            ]
        });
        const initSetPropModule = NavigationHelper.GetWFModule(wfID++);
        initSetPropModule.Module = SetControlPropertiesSettings.ModuleID;
        initSetPropModule.Settings = initSetProp;
        initWorkflow.Data.Modules.push(initSetPropModule);

        const initScriptProp = new ExecuteScriptSettingsData();
        initScriptProp.ID = '9c3cf944-8183-912b-fc4e-e2518397dc26';
        initScriptProp.UseJObject = false;
        initScriptProp.Script = 'import { Global } from "System";\r\nexport class Main {\r\n    constructor() {\r\n    }\r\n    run(): any {\r\n        let status = Global.getStatus();\r\n        let providers = status["providers"];\r\n        if (providers != null && providers.length > 0) {\r\n            status["multiProviders"] = providers.length > 1;\r\n            for (let p of providers) {\r\n                if (p.IsDefault) {\r\n                    return p;\r\n                }\r\n            }\r\n            return providers[0];\r\n        }\r\n        status["multiProviders"] = false;\r\n        return null;\r\n    }\r\n    stop(): void {\r\n    }\r\n}';
        const initScriptModule = NavigationHelper.GetWFModule(wfID++);
        initScriptModule.Module = ExecuteScriptSettings.ModuleID;
        initScriptModule.Settings = initScriptProp;
        initWorkflow.Data.Modules.push(initScriptModule);

        const initSetValueProp = new SetMultiElemValueSettingsData();
        initSetValueProp.Elements.push({
            ElemName: providerBox.Name,
            GetFromState: true,
            StatusKey: null
        });
        const initSetValueModule = NavigationHelper.GetWFModule(wfID++);
        initSetValueModule.Module = SetMultiElemValueSettings.ModuleID;
        initSetValueModule.Settings = initSetValueProp;
        initWorkflow.Data.Modules.push(initSetValueModule);

        const selChangeWFID = 'c760d1eb-f7e8-4e74-b1dd-7724aebc585a';

        const initExecWF = new ExecuteWorkflowData();
        initExecWF.WorkflowID = selChangeWFID;
        const initExecWFModule = NavigationHelper.GetWFModule(wfID++);
        initExecWFModule.Module = ExecuteWorkflowSettings.ModuleID;
        initExecWFModule.Settings = initExecWF;
        initWorkflow.Data.Modules.push(initExecWFModule);

        const initDataSwitchProps = new DataSwitchSettingsData();
        initDataSwitchProps.Conditions.push({
            Formula: 'multiProviders',
            Name: 'multiProviders'
        });
        const initDataSwitchModule = NavigationHelper.GetWFModule(wfID++);
        initDataSwitchModule.Module = DataSwitchSettings.ModuleID;
        initDataSwitchModule.Settings = initDataSwitchProps;
        initWorkflow.Data.Modules.push(initDataSwitchModule);

        const provStyle = new StyleBase();
        provStyle.Visible = false;
        const initSetProvStyle = new SetMultiStyleSettingsData();
        initSetProvStyle.Styles.push({
            Elements: [{ ElemName: providerBox.Name }],
            Layout: provStyle,
            StyleRef: null
        });
        const initSetProvStyleModule = NavigationHelper.GetWFModule(wfID++);
        initSetProvStyleModule.Module = SetMultiStyleSettings.ModuleID;
        initSetProvStyleModule.Settings = initSetProvStyle;
        initWorkflow.Data.Modules.push(initSetProvStyleModule);

        initWorkflow.Data.Connectors.push({
            ExitModule: initStart.ID,
            EntryModule: initRestModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initRestModule.ID,
            EntryModule: initSetStatusModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initSetStatusModule.ID,
            EntryModule: initSetPropModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initSetPropModule.ID,
            EntryModule: initScriptModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initScriptModule.ID,
            EntryModule: initSetValueModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initSetValueModule.ID,
            EntryModule: initExecWFModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initExecWFModule.ID,
            EntryModule: initDataSwitchModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        initWorkflow.Data.Connectors.push({
            ExitModule: initDataSwitchModule.ID,
            EntryModule: initSetProvStyleModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });

        l.Workflows.push(initWorkflow);

        l.Events.push({
            EventID: 'OnInit',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: initWorkflow.ID
                }
            ]
        });
        //#endregion

        wfID = 0;
        //#region Login
        const loginWF = new WorkflowDescription();
        loginWF.Caption = 'Login';
        loginWF.ID = '990f0512-d66f-4573-ba48-8993dce3441d';
        loginWF.Data = new WorkflowData();

        const loginStartModule = new WorkflowModuleData();
        loginStartModule.ID = wfID++;
        loginStartModule.XPos = 315;
        loginStartModule.YPos = 25;
        loginStartModule.Module = StartWfModuleSettings.ModuleID;
        loginStartModule.Settings = new StartWfModuleData();
        loginWF.Data.Modules.push(loginStartModule);

        const loginReadVar = new ReadMultiVariableSettingsData();
        loginReadVar.Variables.push({
            ElemName: l.Name,
            Variable: idVar.Name,
            StatusKey: 'providerID',
            UseForState: false
        });
        loginReadVar.Variables.push({
            ElemName: l.Name,
            Variable: mfaVar.Name,
            StatusKey: 'mfa',
            UseForState: false
        });
        const loginReadVarModule = new WorkflowModuleData();
        loginReadVarModule.ID = wfID++;
        loginReadVarModule.XPos = 260;
        loginReadVarModule.YPos = loginStartModule.YPos + 120;
        loginReadVarModule.Module = ReadVariableSettings.MultiModuleID;
        loginReadVarModule.Settings = loginReadVar;
        loginWF.Data.Modules.push(loginReadVarModule);

        const login = new LoginSettingsData();
        login.UseControlForName = true;
        login.Name = nameBox.Name;
        login.UseControlForPassword = true;
        login.Password = pwBox.Name;
        login.UseControlForMFACode = true;
        login.MFACode = mfaBox.Name;
        login.ProviderChoice = ProviderChoice.Formula;
        login.Provider = 'providerID';
        const loginModule = new WorkflowModuleData();
        loginModule.ID = wfID++;
        loginModule.XPos = loginReadVarModule.XPos;
        loginModule.YPos = loginReadVarModule.YPos + 120;
        loginModule.Module = LoginSettings.ModuleID;
        loginModule.Settings = login;
        loginWF.Data.Modules.push(loginModule);

        const wrongPW = new SetControlPropertiesSettingsData();
        wrongPW.Controls.push({
            ControlName: errorText.Name,
            Properties: [{
                Formula: 'translate("Benutzername oder Passwort falsch! Bitte kontrollieren Sie die Eingabe.")',
                PropertyName: 'Caption'
            }]
        });
        const wrongPWModule = new WorkflowModuleData();
        wrongPWModule.ID = wfID++;
        wrongPWModule.XPos = 50;
        wrongPWModule.YPos = loginModule.YPos + 120;
        wrongPWModule.Module = SetControlPropertiesSettings.ModuleID;
        wrongPWModule.Settings = wrongPW;
        loginWF.Data.Modules.push(wrongPWModule);

        const unauthorized = new SetControlPropertiesSettingsData();
        unauthorized.Controls.push({
            ControlName: errorText.Name,
            Properties: [{
                Formula: 'translate("Kein Loginrecht vorhanden!")',
                PropertyName: 'Caption'
            }]
        });
        const unauthorizedModule = new WorkflowModuleData();
        unauthorizedModule.ID = wfID++;
        unauthorizedModule.XPos = loginReadVarModule.XPos;
        unauthorizedModule.YPos = wrongPWModule.YPos + 30;
        unauthorizedModule.Module = SetControlPropertiesSettings.ModuleID;
        unauthorizedModule.Settings = unauthorized;
        loginWF.Data.Modules.push(unauthorizedModule);

        const disabled = new SetControlPropertiesSettingsData();
        disabled.Controls.push({
            ControlName: errorText.Name,
            Properties: [{
                Formula: 'translate("Benutzer inaktiv!")',
                PropertyName: 'Caption'
            }]
        });
        const disabledModule = new WorkflowModuleData();
        disabledModule.ID = wfID++;
        disabledModule.XPos = unauthorizedModule.XPos + 210;
        disabledModule.YPos = wrongPWModule.YPos;
        disabledModule.Module = SetControlPropertiesSettings.ModuleID;
        disabledModule.Settings = disabled;
        loginWF.Data.Modules.push(disabledModule);

        const error = new SetMultiStyleSettingsData();
        const style = new StyleBase();
        style.Visible = true;
        error.Styles.push({
            Elements: [
                {
                    ElemName: errorText.Name
                }
            ],
            Layout: style,
            StyleRef: null
        });
        const errorModule = new WorkflowModuleData();
        errorModule.ID = wfID++;
        errorModule.XPos = unauthorizedModule.XPos;
        errorModule.YPos = unauthorizedModule.YPos + 120;
        errorModule.Module = SetMultiStyleSettings.ModuleID;
        errorModule.Settings = error;
        loginWF.Data.Modules.push(errorModule);

        const loginDataSwitch = new DataSwitchSettingsData();
        loginDataSwitch.Conditions.push({
            Name: 'MFA',
            Formula: 'mfa'
        });
        const loginDataSwitchModule = new WorkflowModuleData();
        loginDataSwitchModule.ID = wfID++;
        loginDataSwitchModule.XPos = disabledModule.XPos + 355;
        loginDataSwitchModule.YPos = loginReadVarModule.YPos + 50;
        loginDataSwitchModule.Module = DataSwitchSettings.ModuleID;
        loginDataSwitchModule.Settings = loginDataSwitch;
        loginWF.Data.Modules.push(loginDataSwitchModule);

        const mfaText = new SetControlPropertiesSettingsData();
        mfaText.Controls.push({
            ControlName: errorText.Name,
            Properties: [{
                Formula: 'translate("Falscher Code!")',
                PropertyName: 'Caption'
            }]
        });
        const mfaModule = new WorkflowModuleData();
        mfaModule.ID = wfID++;
        mfaModule.XPos = disabledModule.XPos + 210;
        mfaModule.YPos = wrongPWModule.YPos;
        mfaModule.Module = SetControlPropertiesSettings.ModuleID;
        mfaModule.Settings = mfaText;
        loginWF.Data.Modules.push(mfaModule);

        const mfaCaption = new SetControlPropertiesSettingsData();
        mfaCaption.Controls.push({
            ControlName: loginText.Name,
            Properties: [{
                Formula: 'translate("Authentication")',
                PropertyName: 'Caption'
            }]
        });
        const mfaCaptionModule = new WorkflowModuleData();
        mfaCaptionModule.ID = wfID++;
        mfaCaptionModule.XPos = mfaModule.XPos + 210;
        mfaCaptionModule.YPos = mfaModule.YPos;
        mfaCaptionModule.Module = SetControlPropertiesSettings.ModuleID;
        mfaCaptionModule.Settings = mfaCaption;
        loginWF.Data.Modules.push(mfaCaptionModule);

        const mfaActive = new SetMultiStyleSettingsData();
        const mfaActiveStyle = new StyleBase();
        mfaActiveStyle.Visible = true;
        const mfaInActiveStyle = new StyleBase();
        mfaInActiveStyle.Visible = false;
        mfaActive.Styles.push({
            Elements: [
                {
                    ElemName: mfaFlex.Name
                }
            ],
            Layout: mfaActiveStyle,
            StyleRef: null
        });
        mfaActive.Styles.push({
            Elements: [
                {
                    ElemName: nameFlex.Name
                },
                {
                    ElemName: pwFlex.Name
                },
                {
                    ElemName: providerBox.Name
                }
            ],
            Layout: mfaInActiveStyle,
            StyleRef: null
        });
        const mfaActiveModule = new WorkflowModuleData();
        mfaActiveModule.ID = wfID++;
        mfaActiveModule.XPos = mfaCaptionModule.XPos;
        mfaActiveModule.YPos = mfaCaptionModule.YPos + 120;
        mfaActiveModule.Module = SetMultiStyleSettings.ModuleID;
        mfaActiveModule.Settings = mfaActive;
        loginWF.Data.Modules.push(mfaActiveModule);

        const loginSetVar = new SetMultiVariableSettingsData();
        loginSetVar.Elements.push({
            ElemName: l.Name,
            Variables: [
                {
                    Name: mfaVar.Name,
                    UseActualParam: false,
                    UseVariable: false,
                    Value: 'true'
                }
            ]
        });
        const loginSetVarModule = new WorkflowModuleData();
        loginSetVarModule.ID = wfID++;
        loginSetVarModule.XPos = mfaActiveModule.XPos;
        loginSetVarModule.YPos = mfaActiveModule.YPos + 120;
        loginSetVarModule.Module = SetVariableSettings.MultiModuleID;
        loginSetVarModule.Settings = loginSetVar;
        loginWF.Data.Modules.push(loginSetVarModule);

        const loginSuccessSetVar = new SetMultiVariableSettingsData();
        loginSuccessSetVar.Elements.push({
            ElemName: l.Name,
            Variables: [
                {
                    Name: mfaVar.Name,
                    UseActualParam: false,
                    UseVariable: false,
                    Value: 'false'
                }
            ]
        });
        const loginSuccessSetVarModule = new WorkflowModuleData();
        loginSuccessSetVarModule.ID = wfID++;
        loginSuccessSetVarModule.XPos = 50;
        loginSuccessSetVarModule.YPos = loginModule.YPos;
        loginSuccessSetVarModule.Module = SetVariableSettings.MultiModuleID;
        loginSuccessSetVarModule.Settings = loginSuccessSetVar;
        loginWF.Data.Modules.push(loginSuccessSetVarModule);

        loginWF.Data.Connectors.push({
            ExitModule: loginStartModule.ID,
            EntryModule: loginReadVarModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginReadVarModule.ID,
            EntryModule: loginModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginModule.ID,
            EntryModule: loginSuccessSetVarModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginModule.ID,
            EntryModule: wrongPWModule.ID,
            EntryOption: 0,
            ExitOption: 1,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginModule.ID,
            EntryModule: unauthorizedModule.ID,
            EntryOption: 0,
            ExitOption: 2,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginModule.ID,
            EntryModule: disabledModule.ID,
            EntryOption: 0,
            ExitOption: 3,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: wrongPWModule.ID,
            EntryModule: errorModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: unauthorizedModule.ID,
            EntryModule: errorModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: disabledModule.ID,
            EntryModule: errorModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginModule.ID,
            EntryModule: loginDataSwitchModule.ID,
            EntryOption: 0,
            ExitOption: 4,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginDataSwitchModule.ID,
            EntryModule: mfaCaptionModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: mfaCaptionModule.ID,
            EntryModule: mfaActiveModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: mfaActiveModule.ID,
            EntryModule: loginSetVarModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: loginDataSwitchModule.ID,
            EntryModule: mfaModule.ID,
            EntryOption: 0,
            ExitOption: 1,
            ID: wfID++,
            SID: UUID.UUID()
        });
        loginWF.Data.Connectors.push({
            ExitModule: mfaModule.ID,
            EntryModule: errorModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });

        l.Workflows.push(loginWF);

        loginButton.Events.push({
            EventID: 'click',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: loginWF.ID
                }
            ]
        });
        //#endregion
        wfID = 0;
        //#region OnEnter
        const keyDownWF = new WorkflowDescription();
        keyDownWF.Caption = 'OnEnter';
        keyDownWF.ID = '937cdff9-71c9-4873-910d-b6ac1e2fd55d';
        keyDownWF.Data = new WorkflowData();

        const keyDownStartModule = NavigationHelper.GetWFModule(wfID++);
        keyDownStartModule.Module = StartWfModuleSettings.ModuleID;
        keyDownStartModule.Settings = new StartWfModuleData();
        keyDownWF.Data.Modules.push(keyDownStartModule);

        const readProps = new ReadMultiPropertySettingsData();
        readProps.Properties.push({
            PropName: 'key',
            StatusKey: 'key',
            UseForState: false
        });
        const readPropsModule = NavigationHelper.GetWFModule(wfID++);
        readPropsModule.Module = ReadMultiPropertySettings.ModuleID;
        readPropsModule.Settings = readProps;
        keyDownWF.Data.Modules.push(readPropsModule);

        const dataSwitch = new DataSwitchSettingsData();
        dataSwitch.Conditions.push({
            Name: 'Enter',
            Formula: 'key="Enter"'
        });
        const dataSwitchModule = NavigationHelper.GetWFModule(wfID++);
        dataSwitchModule.Module = 'dataswitchWFModule';
        dataSwitchModule.Settings = dataSwitch;
        keyDownWF.Data.Modules.push(dataSwitchModule);

        const execWF = new ExecuteWorkflowData();
        execWF.WorkflowID = loginWF.ID;
        const execWFModule = NavigationHelper.GetWFModule(wfID++);
        execWFModule.Module = 'execWFModule';
        execWFModule.Settings = execWF;
        keyDownWF.Data.Modules.push(execWFModule);

        const keyDownRemoveStyle = new RemoveMultiStyleSettingsData();
        keyDownRemoveStyle.Elements.push({ ElemName: errorText.Name });
        const keyDownRemoveStyleModule = NavigationHelper.GetWFModule(wfID++);
        keyDownRemoveStyleModule.Module = RemoveMultiStyleSettings.ModuleID;
        keyDownRemoveStyleModule.Settings = keyDownRemoveStyle;
        keyDownWF.Data.Modules.push(keyDownRemoveStyleModule);

        keyDownWF.Data.Connectors.push({
            ExitModule: keyDownStartModule.ID,
            EntryModule: readPropsModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        keyDownWF.Data.Connectors.push({
            ExitModule: readPropsModule.ID,
            EntryModule: dataSwitchModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        keyDownWF.Data.Connectors.push({
            ExitModule: dataSwitchModule.ID,
            EntryModule: execWFModule.ID,
            EntryOption: 0,
            ExitOption: 1,
            ID: wfID++,
            SID: UUID.UUID()
        });
        keyDownWF.Data.Connectors.push({
            ExitModule: dataSwitchModule.ID,
            EntryModule: keyDownRemoveStyleModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });

        nameBox.Events.push({
            EventID: 'keydown',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: keyDownWF.ID
                }
            ]
        });

        pwBox.Events.push({
            EventID: 'keydown',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: keyDownWF.ID
                }
            ]
        });

        mfaBox.Events.push({
            EventID: 'keydown',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: keyDownWF.ID
                }
            ]
        });

        l.Workflows.push(keyDownWF);
        //#endregion
        wfID = 0;
        //#region ProviderChanged
        const selChangeWF = new WorkflowDescription();
        selChangeWF.Caption = 'ProviderChanged';
        selChangeWF.ID = selChangeWFID;
        selChangeWF.Data = new WorkflowData();

        const selChangeStartModule = NavigationHelper.GetWFModule(wfID++);
        selChangeStartModule.Module = StartWfModuleSettings.ModuleID;
        selChangeStartModule.Settings = new StartWfModuleData();
        selChangeWF.Data.Modules.push(selChangeStartModule);

        const selChangeSetStatusProps = new SetWorkflowStatusData();
        selChangeSetStatusProps.StatusKey = 'selectedProvider';
        selChangeSetStatusProps.KeepActualParam = true;
        const selChangeSetStatusModule = NavigationHelper.GetWFModule(wfID++);
        selChangeSetStatusModule.Module = SetWorkflowStatusSettings.ModuleID;
        selChangeSetStatusModule.Settings = selChangeSetStatusProps;
        selChangeWF.Data.Modules.push(selChangeSetStatusModule);

        const selDefValuesProps = new DefineMultiValuesSettingsData();
        selDefValuesProps.Values.push({
            Formula: 'GenerateNull()',
            StatusKey: 'propID',
            UseForState: false
        });
        selDefValuesProps.Values.push({
            Formula: 'GenerateNull()',
            StatusKey: 'propURL',
            UseForState: false
        });
        selDefValuesProps.Values.push({
            Formula: '""',
            StatusKey: 'propType',
            UseForState: false
        });
        const selDefValuesModule = NavigationHelper.GetWFModule(wfID++);
        selDefValuesModule.Module = DefineValueSettings.MultiModuleID;
        selDefValuesModule.Settings = selDefValuesProps;
        selChangeWF.Data.Modules.push(selDefValuesModule);

        const selRemoveStyle = new RemoveMultiStyleSettingsData();
        selRemoveStyle.Elements.push({ ElemName: nameFlex.Name });
        selRemoveStyle.Elements.push({ ElemName: pwFlex.Name });
        const selRemoveStyleModule = NavigationHelper.GetWFModule(wfID++);
        selRemoveStyleModule.Module = RemoveMultiStyleSettings.ModuleID;
        selRemoveStyleModule.Settings = selRemoveStyle;
        selChangeWF.Data.Modules.push(selRemoveStyleModule);

        const selFirstDataSwitchProps = new DataSwitchSettingsData();
        selFirstDataSwitchProps.Conditions.push({
            Formula: 'IsNull(selectedProvider)',
            Name: 'isNull'
        });
        const selFirstDataSwitchModule = NavigationHelper.GetWFModule(wfID++);
        selFirstDataSwitchModule.Module = DataSwitchSettings.ModuleID;
        selFirstDataSwitchModule.Settings = selFirstDataSwitchProps;
        selChangeWF.Data.Modules.push(selFirstDataSwitchModule);

        const selReadProps = new ReadMultiPropertySettingsData();
        selReadProps.Properties.push({
            PropName: 'Id',
            StatusKey: 'propID',
            UseForState: false
        });
        selReadProps.Properties.push({
            PropName: 'Url',
            StatusKey: 'propURL',
            UseForState: false
        });
        selReadProps.Properties.push({
            PropName: 'Type',
            StatusKey: 'propType',
            UseForState: false
        });
        const selReadPropsModule = NavigationHelper.GetWFModule(wfID++);
        selReadPropsModule.Module = ReadMultiPropertySettings.ModuleID;
        selReadPropsModule.Settings = selReadProps;
        selChangeWF.Data.Modules.push(selReadPropsModule);

        const selSetVar = new SetMultiVariableSettingsData();
        selSetVar.Elements.push({
            ElemName: l.Name,
            Variables: [
                {
                    Name: idVar.Name,
                    UseActualParam: false,
                    UseVariable: false,
                    Value: 'propID'
                }
            ]
        });
        const selSetVarModule = NavigationHelper.GetWFModule(wfID++);
        selSetVarModule.Module = SetVariableSettings.MultiModuleID;
        selSetVarModule.Settings = selSetVar;
        selChangeWF.Data.Modules.push(selSetVarModule);

        const selDataSwitch = new DataSwitchSettingsData();
        selDataSwitch.Conditions.push({
            Name: 'HasUrl',
            Formula: 'Or(IsNull(propURL);propURL="")=false'
        });
        selDataSwitch.Conditions.push({
            Name: 'IsScan',
            Formula: 'propType="31e75470-8458-48e5-af70-d8a6e8840b37"'
        });
        const selDataSwitchModule = NavigationHelper.GetWFModule(wfID++);
        selDataSwitchModule.Module = DataSwitchSettings.ModuleID;
        selDataSwitchModule.Settings = selDataSwitch;
        selChangeWF.Data.Modules.push(selDataSwitchModule);

        const nameStyle = new StyleBase();
        nameStyle.Visible = false;
        const selSetNameStyle = new SetMultiStyleSettingsData();
        selSetNameStyle.Styles.push({
            Elements: [{ ElemName: nameFlex.Name }],
            Layout: nameStyle,
            StyleRef: null
        });
        const selSetNameStyleModule = NavigationHelper.GetWFModule(wfID++);
        selSetNameStyleModule.Module = SetMultiStyleSettings.ModuleID;
        selSetNameStyleModule.Settings = selSetNameStyle;
        selChangeWF.Data.Modules.push(selSetNameStyleModule);

        const pwStyle = new StyleBase();
        pwStyle.Visible = false;
        const selSetPWStyle = new SetMultiStyleSettingsData();
        selSetPWStyle.Styles.push({
            Elements: [{ ElemName: pwFlex.Name }],
            Layout: pwStyle,
            StyleRef: null
        });
        const selSetPWStyleModule = NavigationHelper.GetWFModule(wfID++);
        selSetPWStyleModule.Module = SetMultiStyleSettings.ModuleID;
        selSetPWStyleModule.Settings = selSetPWStyle;
        selChangeWF.Data.Modules.push(selSetPWStyleModule);

        selChangeWF.Data.Connectors.push({
            ExitModule: selChangeStartModule.ID,
            EntryModule: selChangeSetStatusModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selChangeSetStatusModule.ID,
            EntryModule: selDefValuesModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selDefValuesModule.ID,
            EntryModule: selRemoveStyleModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selRemoveStyleModule.ID,
            EntryModule: selFirstDataSwitchModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selFirstDataSwitchModule.ID,
            EntryModule: selReadPropsModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selFirstDataSwitchModule.ID,
            EntryModule: selSetVarModule.ID,
            EntryOption: 0,
            ExitOption: 1,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selReadPropsModule.ID,
            EntryModule: selSetVarModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selSetVarModule.ID,
            EntryModule: selDataSwitchModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selDataSwitchModule.ID,
            EntryModule: selSetNameStyleModule.ID,
            EntryOption: 0,
            ExitOption: 1,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selDataSwitchModule.ID,
            EntryModule: selSetPWStyleModule.ID,
            EntryOption: 0,
            ExitOption: 2,
            ID: wfID++,
            SID: UUID.UUID()
        });
        selChangeWF.Data.Connectors.push({
            ExitModule: selSetNameStyleModule.ID,
            EntryModule: selSetPWStyleModule.ID,
            EntryOption: 0,
            ExitOption: 0,
            ID: wfID++,
            SID: UUID.UUID()
        });

        l.Workflows.push(selChangeWF);

        providerBox.Events.push({
            EventID: 'selectionchanged',
            Handlers: [
                {
                    ActionType: EventActionType.Workflow,
                    Action: selChangeWF.ID
                }
            ]
        });
        //#endregion
        //#endregion

        return l;
    }
}

export class NavigationStructureItem {
    private NavigationNode;
    private UnsortedChildren: NavigationStructureItem[][] = [];

    get ID() {
        return this.NavigationNode._Id;
    }
    get PreviousSibbling() {
        return this.NavigationNode.PreviousSibbling;
    }
    get ParentID() {
        return this.NavigationNode.ParentID;
    }

    constructor(nn) {
        this.NavigationNode = nn;
    }

    AddChild(child: NavigationStructureItem) {
        for (let i = 0; i < this.UnsortedChildren.length; i++) {
            const childList = this.UnsortedChildren[i];
            const first = childList[0].PreviousSibbling;
            if (first) {
                if (child.ID === first) {
                    childList.splice(0, 0, child);
                    for (let j = 0; j < this.UnsortedChildren.length; j++) {
                        if (j !== i) {
                            const innerList = this.UnsortedChildren[j];
                            const lastID = innerList[innerList.length - 1].ID;
                            if (child.PreviousSibbling === lastID) {
                                innerList.push(...childList);
                                this.UnsortedChildren.splice(i, 1);
                                return;
                            }
                        }
                    }
                    return;
                }
            }
            const last = childList[childList.length - 1].ID;
            if (child.PreviousSibbling === last) {
                childList.push(child);
                for (let j = 0; j < this.UnsortedChildren.length; j++) {
                    if (j !== i) {
                        const innerList = this.UnsortedChildren[j];
                        const innerFirst = innerList[0];
                        if (innerFirst.PreviousSibbling === child.ID) {
                            childList.push(...innerList);
                            this.UnsortedChildren.splice(j, 1);
                            return;
                        }
                    }
                }
                return;
            }
        }
        this.UnsortedChildren.push([child]);
    }

    GetStructure() {
        const retVal = {
            Data: this.NavigationNode,
            Children: []
        };
        let lastID;
        if (this.UnsortedChildren.some(x => {
            if (!x[0].PreviousSibbling) {
                x.forEach(y => {
                    retVal.Children.push(y.GetStructure());
                });
                lastID = x[x.length - 1].ID;
                return true;
            }
            return false;
        })) {
            while (this.UnsortedChildren.some(x => {
                if (x[0].PreviousSibbling === lastID) {
                    x.forEach(y => {
                        retVal.Children.push(y.GetStructure());
                    });
                    lastID = x[x.length - 1].ID;
                    return true;
                }
                return false;
            })) { }
        }
        return retVal;
    }
}
