import { BaseDataSourcePanel } from '../basedatasource.panel';
import { Component } from '@angular/core';
import { DBPanelInfo, NoDatasourceDBInfo } from '../../../../../models/datamodel/adbinfo.model';

@Component({
    selector: 'nodb-panel',
    templateUrl: './nodatabase.panel.html',
    styleUrls: ['./nodatabase.panel.css']
})
export class NoDBPanel extends BaseDataSourcePanel {

    MediaSources = [];

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: NoDBPanel,
            DBInfoType: NoDatasourceDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = NoDatasourceDBInfo;
    }

    ngOnInit(): void {
    }
}
