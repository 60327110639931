import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { StringHelper, TranslateFormatText } from '../../../helpers/array.helpers';
import { ContainerPathHelper } from '../../../helpers/containerpath.helper';
import { DataTypeHelper } from '../../../helpers/datatype.helper';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { FormulaNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { Aggregation, LevelType } from '../../../models/enums';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { QueryEngineService } from '../../../services/query.engine.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { ContainerTabularColumnEditDialog } from '../dialogs/container.tabular.column.edit.dialog';
import { RelQueryDefDialog } from '../dialogs/rel.query.def.dialog';
import { TabularColumnDialog, TabularColumnNode } from '../dialogs/tabular.column.dialog';

@Component({
    selector: 'containertabular-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerTabularSettings extends BaseListSettings {

    constructor(private translate: TranslateService, private dataModelService: DataModelService, private queryService: QueryEngineService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerTabularDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.Tables = [];
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.SpeedBase.ContainerTabular').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach(item => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                this.queryService.GetRelContainer(this.InitArgs.SID).subscribe((tables) => {
                    if (tables) {
                        comp.Tables = tables;
                    }
                });
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[268435456]]; // QueryEngine
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(ds => {
                    if (ds) {
                        comp.DataSources = ds;
                    }
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((tabular) => {
                if (tabular) {
                    if (tabular.Columns && this.Component) {
                        let index = 1;
                        const variables = [];
                        const keys = new Map<string, string>();
                        tabular.Columns.forEach(col => {
                            col.AliasKey = ContainerPathHelper.GetExcelColumnName(index++);
                            const vni = new VariablesNodeInformation();
                            vni.VariableID = col.Id;
                            vni.AliasKey = col.AliasKey;
                            vni.Name = TranslatedString.GetTranslation(col.Caption);
                            variables.push(vni);
                            keys.set(vni.VariableID, vni.AliasKey);
                        });
                        const tables = this.Component.Tables;
                        tabular.Columns.forEach(col => {
                            col.IsTableField = false;
                            if (col.Formula) {
                                col.Formula = plainToClass(FormulaNodeInformation, col.Formula);
                                FormulaNodeInformation.ApplyAliasKeys(keys, col.Formula);
                                col.FormulaText = col.Formula.ToString(null, VariableDisplay.AliasKey);
                            } else {
                                if (tabular.SourceQuery && tabular.SourceQuery.Columns) {
                                    const defCol = tabular.SourceQuery.Columns.find(x => x.UniqueID === col.UniqueId);
                                    if (defCol) {
                                        tables.some(t => {
                                            if (t.SID === defCol.ContainerID) {
                                                return t.Fields.some(f => {
                                                    if (defCol.FieldID === f.SID) {
                                                        col.IsTableField = true;
                                                        col.FieldCaption = t.Caption + ' (' + defCol.ContainerUniqueID + ')' + ' -> ' +
                                                            f.Caption + ' (' + defCol.UniqueID + ')';
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                            }
                                            return false;
                                        });
                                    }
                                }
                            }
                        });
                        this.setSelectedItem(tabular);
                        this.Component.Variables = variables;
                        this.Component.VariableIndex = index;
                    } else {
                        this.setSelectedItem(tabular);
                    }
                }
            });
        }
    }

    getNewItem() {
        const name = this.translate.instant('@@Neues Tabular');
        return {
            DataModelID: this.InitArgs.SID,
            Name: name,
            DataSourceId: null,
            SourceQuery: {
                Columns: [],
                Relations: []
            },
            Columns: [],
            IsCaseSensitive: false
            // EnableIndexAggregation???
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Tabular \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Tabular \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Tabular loeschen');
        return retVal;
    }

    delete(data) {
        this.dataModelService.DeleteContainer(data).subscribe();
    }


    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Tabular \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Tabular speichern');
        return retVal;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        if (this.Component) {
            const tabular = this.Component.SelectedItem;
            if (tabular) {
                const errors = [];
                if (!tabular.DataSourceId) {
                    errors.push(this.translate.instant('@@Fehlende Datenquelle'));
                }
                if (tabular.Columns) {
                    const parser = new AdvancedFormulaParser();
                    parser.SetVariables(this.Component.Variables, FormulaInputMode.AliasKey);
                    tabular.Columns.forEach(col => {
                        if (!col.IsTableField) {
                            if (col.FormulaText) {
                                try {
                                    col.Formula = parser.Parse(col.FormulaText);
                                } catch {
                                    errors.push(StringHelper.format(this.translate.instant('@@Fehler in Formel von Spalte {0}'),
                                        [TranslatedString.GetTranslation(col.Caption)]));
                                }
                            } else {
                                col.Formula = null;
                            }
                        }
                    });
                }
                if (errors.length > 0) {
                    retVal.IsCorrect = false;
                    retVal.Error = errors.join('\n');
                }
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            this.dataModelService.SaveContainer('evidanza.MiddleWare.Shared.SpeedBase.ContainerTabular',
                item).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containertabular-detail',
    templateUrl: './containertabular.settings.html',
    styleUrls: ['./containertabular.settings.css']
})
export class ContainerTabularDetail extends ADataModelDetail {
    Tables = [];
    DataSources = [];
    Variables = [];
    VariableIndex = 1;
    DataTypes;
    NumberTypes = ['System.Int16', 'System.Int32', 'System.Int64', 'System.Decimal', 'System.Double', 'System.Byte'];
    Rows = [];

    constructor(private translate: TranslateService, private settingsService: DataSettingsService) {
        super();
        this.DataTypes = DataTypeHelper.GetDataTypes();
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.Variables = [];
        this.VariableIndex = 1;
        const rows = [];
        if (item && item.Columns) {
            item.Columns.forEach(f => {
                const row = this.fillRow({}, f);
                rows.push(row);
            });
        }
        this.Rows = rows;
    }

    processTabular() {
        const selected = this.SelectedItem;
        if (selected && selected.SID) {
            NotificationHelper.Info('Process Tabular gestartet.', 'Information');
            this.settingsService.Process(selected.DataSourceId, selected.SID).subscribe(() => {
                NotificationHelper.Info('Process Tabular beendet.', 'Information');
            });
        }
    }

    fillRow(row, col) {
        row.Id = col.Id;
        row.Caption = TranslatedString.GetTranslation(col.Caption);
        row.OriginalColumn = col.FieldCaption;
        row.FormulaKey = col.AliasKey;
        row.Type = col.DataType;
        this.DataTypes.some(x => {
            if (x.Value === col.DataType) {
                row.Type = x.Caption;
                return true;
            }
            return false;
        });
        row.IsMeasure = col.IsMeasure ? 'done' : '';
        row.Visible = col.IsVisible ? 'done' : '';
        row.Formula = col.FormulaText;
        if (col.CalcOnProcess === true && col.IsTableField === false) {
            row.CalcOnProcess = 'done';
        } else {
            row.CalcOnProcess = '';
        }
        row.Index = col.Index;
        row.Aggregation = Aggregation[col.Aggregation];
        row.LevelType = LevelType[col.LevelType];
        return row;
    }

    editField(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Columns) {
            let fieldIndex, field;
            if (selected.Columns.some((x, i) => {
                if (x.Id === row.Id) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                BaseDialog.ShowDialog({
                    ContentType: ContainerTabularColumnEditDialog,
                    InitArgs: {
                        Column: field,
                        Variables: this.Variables
                    },
                    Title: '@@Spalte bearbeiten',
                    Handler: (f) => {
                        if (f) {
                            this.fillRow(row, f);
                            selected.Columns.splice(fieldIndex, 1, f);
                            this.OnItemChanged();
                            this.Variables.some(v => {
                                if (v.VariableID === f.Id) {
                                    v.Name = TranslatedString.GetTranslation(f.Caption);
                                    return true;
                                }
                                return false;
                            });
                        }
                        return true;
                    }
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Columns) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Spalte {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Spalte loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Columns.some((f, i) => {
                            if (f.Id === row.Id) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Columns.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            for (let i = 0; i < this.Variables.length; i++) {
                                if (this.Variables[i].VariableID === row.Id) {
                                    this.Variables.splice(i, 1);
                                    break;
                                }
                            }
                            this.OnItemChanged();
                            const parser = new AdvancedFormulaParser();
                            parser.SetVariables(this.Variables, FormulaInputMode.AliasKey);
                            selected.Columns.forEach((col) => {
                                if (!col.IsTableField && typeof col.FormulaText === 'string' && col.FormulaText !== '') {
                                    try {
                                        parser.Parse(col.FormulaText);
                                    } catch {
                                        col.FormulaText = '';
                                    }
                                }
                            });
                        }
                    }
                });
        }
    }

    addField() {
        const tabular = this.SelectedItem;
        if (tabular && tabular.SourceQuery) {
            const args = {
                RootNodes: []
            };
            let index = 0;
            tabular.SourceQuery.Columns.forEach(c => {
                if (tabular.Columns && tabular.Columns.some(x => x.UniqueId === c.UniqueID)) {
                    return;
                }
                const table = this.Tables.find(x => x.SID === c.ContainerID);
                if (table) {
                    const field = table.Fields.find(x => x.SID === c.FieldID);
                    if (field) {
                        let rootNode = args.RootNodes.find(x => x.UniqueID === c.ContainerUniqueID);
                        if (!rootNode) {
                            rootNode = new TabularColumnNode(index++);
                            rootNode.Caption = table.Caption + ' (' + c.ContainerUniqueID + ')';
                            rootNode.UniqueID = c.ContainerUniqueID;
                            rootNode.HasChildren = true;
                            rootNode.Children = [];
                            rootNode.IsExpanded = true;
                            args.RootNodes.push(rootNode);
                        }
                        const child = new TabularColumnNode(index++);
                        child.Caption = field.Caption + ' (' + c.UniqueID + ')';
                        child.FieldCaption = rootNode.Caption + ' -> ' + child.Caption;
                        child.UniqueID = c.UniqueID;
                        child.DataTyp = field.DataTyp;
                        child.Selected = true;
                        rootNode.Children.push(child);
                    }
                }
            });
            BaseDialog.ShowDialog({
                ContentType: TabularColumnDialog,
                InitArgs: args,
                Title: '@@Spalten hinzufuegen',
                Handler: fields => {
                    if (fields && fields.length > 0) {
                        fields.forEach(f => {
                            const tc = {
                                Id: UUID.UUID(),
                                UniqueId: f.UniqueID,
                                Caption: new TranslatedString(f.Caption),
                                DataType: f.DataTyp,
                                IsMeasure: this.NumberTypes.some(x => x === f.DataTyp),
                                Formula: null,
                                CalcOnProcess: true,
                                Index: 0,
                                Aggregation: Aggregation.Sum,
                                LevelType: LevelType.Regular,
                                IsVisible: true,
                                FieldCaption: f.FieldCaption,
                                IsTableField: true,
                                FormulaText: null,
                                AliasKey: ContainerPathHelper.GetExcelColumnName(this.VariableIndex++)
                            };
                            tabular.Columns.push(tc);
                            const vni = new VariablesNodeInformation();
                            vni.VariableID = tc.Id;
                            vni.AliasKey = tc.AliasKey;
                            vni.Name = tc.FieldCaption;
                            this.Variables.push(vni);
                            const row = this.fillRow({}, tc);
                            this.Rows.push(row);
                        });
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    addCalcField() {
        const tabular = this.SelectedItem;
        if (tabular) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables
            }, (r) => {
                if (r) {
                    const name = this.translate.instant('@@Neue Formelspalte');
                    const tc: any = {
                        Id: UUID.UUID(),
                        UniqueId: -1,
                        Caption: new TranslatedString(name),
                        DataType: 'System.String',
                        IsMeasure: true,
                        Formula: null,
                        CalcOnProcess: true,
                        Index: 0,
                        Aggregation: Aggregation.Sum,
                        LevelType: LevelType.Regular,
                        IsVisible: true,
                        FieldCaption: null,
                        IsTableField: false,
                        FormulaText: r.Formula,
                        AliasKey: ContainerPathHelper.GetExcelColumnName(this.VariableIndex++)
                    };
                    tabular.Columns.push(tc);
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = tc.Id;
                    vni.AliasKey = tc.AliasKey;
                    vni.Name = name;
                    this.Variables.push(vni);
                    const row = this.fillRow({}, tc);
                    this.Rows.push(row);
                    this.OnItemChanged();
                }
                return true;
            });
        }
    }

    editQueryDef() {
        const selected = this.SelectedItem;
        if (selected && selected.SourceQuery) {
            const usedIDs = [];
            selected.Columns.forEach(col => {
                if (typeof col.UniqueId === 'number' && col.UniqueId > 0) {
                    usedIDs.push(col.UniqueId);
                }
            });
            RelQueryDefDialog.ShowDialog(selected.SourceQuery, selected.DataModelID, usedIDs, r => {
                if (r) {
                    selected.SourceQuery = r;
                    this.OnItemChanged();
                }
            });
        }
    }
}
