import { IBaseComponent } from '../../controls/base.component';
import { Component } from '@angular/core';

@Component({
    selector: 'datasource-settings-component',
    templateUrl: './datasource.static.control.html'
})
export class DataSourceStaticControl extends IBaseComponent {
    static Type: any = 'datasource';
    static Default = { Type: 'datasource', DataSource: '6c95bd3a-acf4-4054-8773-25f0ac15d37d' };
    ControlInitialized() {
    }
}
