import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';
import { SetVariableSettings, SetVariableSettingsData, VariableEntry } from './setVariable.settings';

@Component({
    selector: 'wf-setMultiVariable-settings',
    templateUrl: './set.multi.variable.settings.html'
})
export class SetMultiVariableSettings extends FormulaWorkflowDialogContent {
    Data = new SetMultiVariableSettingsData();
    NameMap = new Map();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetVariableSettings.MultiModuleID;
        reg.Caption = '@@Variablen setzen';
        reg.SettingsControl = SetMultiVariableSettings;
        reg.SettingsTypeHelper = new SetMultiVariableSettingsDataHelper();
        reg.Executer = SetMultiVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetMultiVariableSettingsData, json);
            this.Data.Elements.forEach(x => {
                this.updateVariables(x);
            });
        }
    }

    getResult(): any {
        const elements = [];
        this.Data.Elements.forEach(x => {
            if (x.ElemName && x.Variables) {
                const variables = [];
                x.Variables.forEach(v => {
                    if (v.Name && (v.UseActualParam || v.Value)) {
                        variables.push(v);
                    }
                });
                if (variables.length > 0) {
                    x.Variables = variables;
                    delete x['ClientVariables'];
                    elements.push(x);
                }
            }
        });
        this.Data.Elements = elements;
        return this.Data;
    }

    addElement() {
        if (this.Data) {
            if (this.Data.Elements) {
                this.Data.Elements.push(new SetVariableSettingsData());
            } else {
                this.Data.Elements = [new SetVariableSettingsData()];
            }
        }
    }

    removeElement(elemIndex) {
        if (this.Data && this.Data.Elements) {
            this.Data.Elements.splice(elemIndex, 1);
        }
    }

    elemNameChanged(elem) {
        if (elem) {
            elem.Variables = [];
            this.updateVariables(elem);
        }
    }

    addVariable(elem) {
        if (elem) {
            if (elem.Variables) {
                elem.Variables.push(new VariableEntry());
            } else {
                elem.Variables = [new VariableEntry()];
            }
        }
    }

    deleteVariable(elem, i) {
        if (elem && elem.Variables) {
            elem.Variables.splice(i, 1);
        }
    }

    updateVariables(elem) {
        if (elem) {
            const variableList = [];
            if (elem.ElemName) {
                let mapEntry = this.NameMap.get(elem.ElemName);
                if (!mapEntry) {
                    mapEntry = [];
                    const layoutVars = FilterHelper.GetVariableList(elem.ElemName, this.WFEditOptions.Layout);
                    layoutVars.Variables.forEach(x => {
                        const varObj = {
                            Caption: x.Name,
                            Name: x.Name
                        };
                        const dt = VariableHelper.GetTypeCaption(x.Type);
                        if (dt) {
                            varObj.Caption = x.Name + ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ')';
                        }
                        mapEntry.push(varObj);
                    });
                    this.NameMap.set(elem.ElemName, mapEntry);
                }
                variableList.push(...mapEntry);
            }
            elem.ClientVariables = variableList;
        }
    }
}

// @dynamic
export class SetMultiVariableSettingsData {
    @Type(() => SetVariableSettingsData)
    Elements: SetVariableSettingsData[] = [];
}

export class SetMultiVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetMultiVariableSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Elements) {
            settings.Elements.forEach(variable => {
                if (variable.ElemName === oldName) {
                    variable.ElemName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class SetMultiVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Elements && status.ActualSettings.Elements.length > 0) {
                const layout = status.WorkflowLayoutService.Layout;
                if (layout) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    if (!status.ActualSettings.Elements.some(elemEntry => {
                        if (elemEntry.Variables && elemEntry.Variables.length > 0) {
                            if (typeof elemEntry.ElemName === 'string') {
                                const mapEntry = status.WorkflowLayoutService.getVariablesByName(elemEntry.ElemName);
                                if (mapEntry.ElemFound) {
                                    if (mapEntry.Variables.length > 0) {
                                        let success = true;
                                        elemEntry.Variables.some(v => {
                                            let val;
                                            if (v.UseActualParam) {
                                                val = status.ActualParameter;
                                            } else {
                                                try {
                                                    val = calculator.CalcFormula(v.Value);
                                                } catch (ex) {
                                                    success = false;
                                                    status.Logger.logError('SetVariable modul: Error while calculating formula ' +
                                                        v.Value + ' for variable ' + v.Name + ' (' + ex + ').');
                                                    return true;
                                                }
                                            }
                                            let nameFound = false;
                                            let correctType = false;
                                            mapEntry.Variables.some(ev => {
                                                if (ev.Name === v.Name) {
                                                    nameFound = true;
                                                    if (VariableHelper.CheckValueForType(ev.Type, val)) {
                                                        correctType = true;
                                                        ev.Value = val;
                                                        VariableHelper.ValueChanged(layout, ev);
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            });
                                            if (nameFound) {
                                                if (!correctType) {
                                                    success = false;
                                                    status.Logger.logError('SetVariable module: Types don\'t match for variable \'' +
                                                        v.Name + '\'.');
                                                    return true;
                                                }
                                            } else {
                                                success = false;
                                                status.Logger.logError('SetVariable module: Variable \'' + v.Name + '\' not found');
                                                return true;
                                            }
                                            return false;
                                        });
                                        if (success) {
                                            return false;
                                        }
                                    } else {
                                        status.Logger.logError('SetVariable modul: No variables found.');
                                    }
                                } else {
                                    status.Logger.logError('SetVariable modul: Element not found.');
                                }
                            } else {
                                status.Logger.logError('SetVariable modul: No element name found.');
                            }
                            return true;
                        }
                        return false;
                    })) {
                        return 0;
                    }
                } else {
                    status.Logger.logError('SetVariable modul: No layout found.');
                }
            }
        } else {
            status.Logger.logError('SetVariable modul: No settings found.');
        }
        return super.execute(status);
    }
}
