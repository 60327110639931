import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { LanguageService } from '../../../services/language.service';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';

@Component({
    selector: 'container-datetime-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerDatetimeSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerDatetime',
            ID: 'containerdatetime',
            Icon: 'table_chart',
            Index: 40,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 32
            },
            Parent: 'relational',
            Content: ContainerDatetimeSettings
        };
    }

    constructor(private translate: TranslateService, private dMService: DataModelService, private dSService: DataSettingsService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerDatetimeDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dMService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Container.ContainerDateTime').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach((item) => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[4096], [8192], [32768]]; // RelationalRead und RelationalWrite und RelationalCreateStructure
                query.ResourceBase = 0; // Database
                this.dMService.GetDataSourcesByQuery(query).subscribe(sources => {
                    if (sources) {
                        comp.DataSources = sources;
                    }
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dMService.GetContainer(data).subscribe(cont => {
                this.setSelectedItem(cont);
            });
        }
    }

    getNewItem() {
        const name = this.translate.instant('@@Neuer Container');
        const date = new Date();
        return {
            DataModelID: this.InitArgs.SID,
            Name: name,
            StartDate: new Date(Date.UTC(date.getFullYear() - 10, 0)),
            FutureYears: 3
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Container \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Container \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Container loeschen');
        return retVal;
    }

    delete(data) {
        this.dMService.DeleteContainer(data).subscribe();
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Container \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Container speichern');
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            this.dMService.SaveContainer('evidanza.MiddleWare.Shared.Container.ContainerDateTime', item).subscribe(result => {
                handler(result, result.SID, result.Caption);
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerdatetime-detail',
    templateUrl: './containerdatetime.settings.html',
    styleUrls:['./containerdatetime.settings.css']
})
export class ContainerDatetimeDetail extends ADataModelDetail {
    Languages;

    constructor(private lService: LanguageService) {
        super();
    }

    ngOnInit(): void {
        this.lService.getLanguages().subscribe(x => {
            if (x && x.length > 0) {
                this.Languages = x;
                this.checkLanguages();
            }
        });
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.checkLanguages();
    }

    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(this.SelectedItem.SID, '@@Vorschau');
        }
    }

    checkLanguages() {
        if (this.Languages) {
            if (this.SelectedItem && this.SelectedItem.Languages && this.SelectedItem.Languages.length > 0) {
                this.Languages.forEach(x => x.Selected = this.SelectedItem.Languages.indexOf(x.Name) > -1);
            } else {
                this.Languages.forEach(x => x.Selected = false);
            }
        }
    }

    onLanguagesChanged() {
        if (this.Languages && this.SelectedItem) {
            const languages = [];
            this.Languages.forEach(x => {
                if (x.Selected) {
                    languages.push(x.Name);
                }
            });
            this.SelectedItem.Languages = languages;
            this.OnItemChanged();
        }
    }
}
