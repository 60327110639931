import { RequestBase } from "./request-base";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class DataPreviewService extends RequestBase {
    BasePath = 'api/selfservice/datapreview';

    GetStructurePreview(dataSource, structInfo): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetStructurePreview?dataSourceId=' + dataSource, structInfo, this.options);
    }

    GetStructureWithOptions(dataSource, structInfo): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetStructureWithOptions?dataSourceId=' + dataSource, structInfo, this.options);
    }

    GetPreview(dataSource, previewInfo, rowCount?: number): Observable<any> {
        var path = this.API_BASE_URL + this.BasePath + '/GetPreview?dataSourceId=' + dataSource;
        if (rowCount) {
            path += '&rowcount=' + rowCount;
        }
        return this.http.post<any>(path, previewInfo, this.options);
    }

    ExecuteQuery(containerID, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteQuery?containerId=' + containerID, filter, this.options);
    }
}