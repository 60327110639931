import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { CapsuleService } from '../../services/capsule.service';
import { LayoutService } from '../../services/layout.service';
import config from '../../../assets/config.json'
@Component({
    selector: 'capsule-overview-page',
    templateUrl: './capsule.overview.page.html'
})
export class CapsuleOverviewPage implements OnInit {

    static GetSettingsEntry() {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            return null;
        }
        return {
            Caption: '@@Overview',
            ID: 'overview',
            Icon: 'description',
            Index: 40,
            Parent: 'about',
            Children: [{
                Caption: '@@Capsule Overview',
                ID: 'capsule-overview',
                Icon: 'description',
                Index: 10,
                Parent: 'overview',
                Security: {
                    Name: 'evidanza.App.Shared.Security.AboutRight',
                    Value: 4
                },
                Content: CapsuleOverviewPage
            }]
        };
    }

    SelectedType;
    SelectedObject;
    TypeList = [];
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    toggleWindow = true;

    get Objects() {
        if (this.SelectedType) {
            return this.SelectedType.Entries;
        }
        return [];
    }

    constructor(private cdRef: ChangeDetectorRef, private service: CapsuleService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        LayoutService.Loading.next(true);
        this.service.GetOverriddenInfos().subscribe(x => {
            this.SelectedType = null;
            this.SelectedObject = null;
            LayoutService.Loading.next(false);
            if (x && x.length > 0) {
                this.TypeList = x;
                this.SelectedType = x[0];
                if (this.SelectedType && this.SelectedType.Entries && this.SelectedType.Entries.length > 0) {
                    this.SelectedObject = this.SelectedType.Entries[0];
                }
            } else {
                this.TypeList = [];
            }
            this.cdRef.detectChanges();
        });
    }

    onTypeChange() {
        if (this.SelectedType && this.SelectedType.Entries && this.SelectedType.Entries.length > 0) {
            this.SelectedObject = this.SelectedType.Entries[0];
        } else {
            this.SelectedObject = null;
        }
    }

    onItemClick(data) {
        this.SelectedObject = data;
    }

    onDeleteItem(data) {
        this.SelectedObject = data;
        this.deleteItem();
    }

    deleteItem() {
        const sel = this.SelectedObject;
        if (sel) {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@DeleteSingleOverridden'), new TranslateFormatText('@@Delete'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        LayoutService.Loading.next(true);
                        this.service.DeleteOverridden({
                            IDList: [sel.ID]
                        }).subscribe(() => {
                            LayoutService.Loading.next(false);
                            NotificationHelper.Success('@@DeleteSingleOverriddenSuccess', '@@Delete');
                            let selObj = null;
                            if (this.SelectedType && this.SelectedType.Entries) {
                                const index = this.SelectedType.Entries.indexOf(sel);
                                if (index > -1) {
                                    this.SelectedType.Entries.splice(index, 1);
                                    if (this.SelectedType.Entries.length == 0 && this.TypeList) {
                                        const typeIndex = this.TypeList.indexOf(this.SelectedType);
                                        let selType = null;
                                        if (typeIndex > -1) {
                                            this.TypeList.splice(typeIndex, 1);
                                        }
                                        this.SelectedType = selType;
                                    }
                                }
                            }
                            this.SelectedObject = selObj;
                            this.cdRef.detectChanges();
                        });
                    }
                });
        }
    }

    deleteAll() {
        const selType = this.SelectedType;
        if (selType && selType.Entries) {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@DeleteMultiOverridden'), new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        LayoutService.Loading.next(true);
                        const idList = [];
                        selType.Entries.forEach(y => idList.push(y.ID));
                        this.service.DeleteOverridden({
                            IDList: idList
                        }).subscribe(() => {
                            LayoutService.Loading.next(false);
                            NotificationHelper.Success('@@DeleteMultiOverriddenSuccess', '@@Loeschen');
                            if (this.TypeList) {
                                const index = this.TypeList.indexOf(selType);
                                if (index > -1) {
                                    this.TypeList.splice(index, 1);
                                }
                            }
                            this.SelectedType = null;
                            this.SelectedObject = null;
                            this.cdRef.detectChanges();
                        });
                    }
                });
        }
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
