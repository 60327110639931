import { ChangeDetectorRef, Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { VariableHelper } from '../../../helpers/variable.helper';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { Layout } from '../../../models/layout.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { AContentDialog } from './acontent.dialog';

@Component({
    selector: 'wf-layout-dialog-content',
    templateUrl: './layout.dialog.content.html'
})
export class LayoutDialogContent extends AContentDialog {
    TempLayout;
    TempParameters;
    InternalChange = false;
    ParamSub;
    LayoutSub;

    constructor(private service: MetaService, public cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    LoadLayout(): void {
        if (this.InitArgs && this.InitArgs.URL) {
            this.LayoutSub = LayoutService.SelectedLayout.subscribe(x => {
                if (!this.InternalChange) {
                    this.TempLayout = x;
                }
            });
            this.ParamSub = NavigationService.Params.subscribe(x => {
                if (!this.InternalChange) {
                    this.TempParameters = x;
                }
            });
            const params = {};
            if (Array.isArray(this.InitArgs.Parameters)) {
                const elements = [];
                if (this.TempLayout instanceof Layout) {
                    elements.push(...this.TempLayout.Elements);
                }
                this.InitArgs.Parameters.forEach((param) => {
                    if (!param['Manual']) {
                        const elem = LayoutHelper.getElementByName(elements, param['Value']);
                        if (elem && elem.Element) {
                            params[param['Name']] = elem.Element.DataSource;
                        }
                    } else {
                        params[param['Name']] = param['Value'];
                    }
                });
            }
            this.service.LoadPage(this.InitArgs.URL).subscribe(result => {
                if (result) {
                    const layout = plainToClass(Layout, result);
                    layout.SetViewType(ViewType.View);
                    VariableHelper.LoadVariables(layout).then(() => {
                        this.Layout = layout;
                        this.InternalChange = true;
                        LayoutService.SelectedLayout.next(this.Layout);
                        NavigationService.Params.next(params);
                        this.InternalChange = false;
                        this.cdRef.detectChanges();
                    });
                }
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.LayoutSub) {
            this.LayoutSub.unsubscribe();
            this.LayoutSub = null;
            LayoutService.SelectedLayout.next(this.TempLayout);
        }
        if (this.ParamSub) {
            this.ParamSub.unsubscribe();
            this.ParamSub = null;
            NavigationService.Params.next(this.TempParameters);
        }
    }
}
