import { AboutSettings } from '../settings/about/about.settings';
import { DeploymentActionsDetail, DeploymentActionsSettings } from '../settings/actions/deployactions.settings';
import { AgentDetail, AgentSettings } from '../settings/agent/agent.settings';
import { CapsuleOverviewPage } from '../settings/capsuleoverview/capsule.overview.page';
import { ChangeLogOverview } from '../settings/changelogoverview/changelog.overview';
import { SaveChangeLogDialog } from '../settings/changelogoverview/save.changelog.dialog';
import { AppBuilderSettings } from '../settings/ci/appbuilder/appbuilder.settings';
import { ChartPaletteDetail, ChartPaletteSettings } from '../settings/ci/chartpalette/chart.palette.settings';
import { ControlStylesDetail, ControlStylesSettings } from '../settings/ci/controlstyles/control.styles.settings';
import { CustomCSSDetail, CustomCSSSettings } from '../settings/ci/customcss/customcss.settings';
import { CustomCSSVarsDetail, CustomCSSVarsSettings } from '../settings/ci/customcssvars/customcssvars.settings';
import { FontSettings } from '../settings/ci/fonts/font.settings';
import { IconSettings } from '../settings/ci/icons/icon.settings';
import { MetaTagsDetail, MetaTagsSettings } from '../settings/ci/tags/metatags.settings';
import { ThemesDetail, ThemeSettings } from '../settings/ci/theme/theme.settings';
import { ABaseElementNameControl } from '../settings/common/abaseelement.name.control';
import { ConnectorOverviewDetail, ConnectorOverviewSettings } from '../settings/connector/connector.overview.settings';
import { ConnectorScriptsDetail, ConnectorScriptsSettings } from '../settings/connector/scripts/connector.scripts.settings';
import { ConnectorWorkflowMappingSettings } from '../settings/connector/wfmapping/connector.wfmapping.settings';
import { DataModelSettings } from '../settings/datamodel/datamodel.settings';
import { DataSourceSettings } from '../settings/datamodel/datasource/datasource.settings';
import { DBInfoInteractivePropertyControl } from '../settings/datamodel/datasource/datasourcepanels/dbinfo.interactive.property.control';
import { MediaSourceSettings } from '../settings/datamodel/mediasource/mediasource.settings';
import { GitSettings } from '../settings/development/git/git.settings';
import { CustomRestDetail, CustomRestSettings } from '../settings/external/customrest/customrest.settings';
import { GeneralSettings } from '../settings/general/general.settings';
import { GlobalVariableDetail, GlobalVariableSettings } from '../settings/globalvariable/globalvariable.settings';
import { GroupDetail, GroupsSettings } from '../settings/groups/groups.settings';
import { JiraDetail, JiraSettings } from '../settings/jira/jira.settings';
import { JobsSettings } from '../settings/jobs/jobs.settings';
import { LanguageSettings } from '../settings/languages/languages.settings';
import { LayoutEventsDetail, LayoutEventsSettings } from '../settings/layoutevents/layout.events.settings';
import { LogsSettings } from '../settings/logsettings/logs.settings';
import { LogsMailDetail, LogsMailSettings } from '../settings/logsettings/logsmails/logsmails.settings';
import { MailDetail, MailSettings } from '../settings/mail/mail.settings';
import { NavigationOverviewDetail, NavigationOverwiewSettings } from '../settings/navigation/overview/navigation.overview.settings';
import { NavigationOverviewVersionSettings } from '../settings/navigation/overview/navigation.overview.version.settings';
import { NavigationStructureSettings } from '../settings/navigation/structure/navigation.structure.settings';
import { NumberCircleDetail, NumberCircleSettings } from '../settings/numbercircles/numbercircle.settings';
import { OutputDetail, OutputSettings } from '../settings/output/output.settings';
import { RepeatingChooser } from '../settings/output/repeating.chooser';
import { PagesDetail } from '../settings/pages/pages.detail';
import { PagesSettings } from '../settings/pages/pages.settings';
import { PagesVersionSettings } from '../settings/pages/pages.version.settings';
import { AmazonForm } from '../settings/provider/amazon/amazon.form';
import { AzureADForm } from '../settings/provider/azuread/azuread.form';
import { FacebookForm } from '../settings/provider/facebook/facebook.form';
import { GoogleForm } from '../settings/provider/google/google.form';
import { IdentityForm } from '../settings/provider/identity/identity.form';
import { IdentityMFAMailDialog } from '../settings/provider/identity/identity.mfa.mail.dialog';
import { InstagramForm } from '../settings/provider/instagram/instagram.form';
import { LdapForm } from '../settings/provider/ldap/ldap.form';
import { LinkdidForm } from '../settings/provider/linkdid/linkdid.form';
import { ProviderDetail, ProviderSettings } from '../settings/provider/provider.settings';
import { SimpleForm } from '../settings/provider/simple/simple.form';
import { TwitterForm } from '../settings/provider/twitter/twitter.form';
import { ChildRoleSettings } from '../settings/roles/childRoles/childroles.settings';
import { ContainerFieldSecDialog, ContainerSecuritySettings } from '../settings/roles/container/container.settings';
import { EnumSecuritySettings } from '../settings/roles/enumSec/enumsec.settings';
import { PermissionsPage } from '../settings/roles/permissions/permissions.page';
import { RoleDetail, RoleSettings } from '../settings/roles/roles.settings';
import { CCOTriggerControl } from '../settings/scheduler/controls/triggercontrols/cco.trigger.control';
import { FileSystemTriggerControl } from '../settings/scheduler/controls/triggercontrols/filesystem.trigger.control';
import { FTPTriggerControl } from '../settings/scheduler/controls/triggercontrols/ftp.trigger.control';
import { MailTriggerControl } from '../settings/scheduler/controls/triggercontrols/mail.trigger.control';
import { TimeTriggerControl } from '../settings/scheduler/controls/triggercontrols/time.trigger.control';
import { WebHookTriggerControl } from '../settings/scheduler/controls/triggercontrols/webhook.trigger.control';
import {
    CapsuleExportWorkerControl
} from '../settings/scheduler/controls/workercontrols/capsuleexport/capsule.export.worker.control';
import {
    CapsuleImportWorkerControl
} from '../settings/scheduler/controls/workercontrols/capsuleimport/capsule.import.worker.control';
import { DBBackupControl } from '../settings/scheduler/controls/workercontrols/dbbackup/dbbackup.worker.control';
import { WorkflowWorkerControl } from '../settings/scheduler/controls/workercontrols/workflow/workflow.worker.control';
import { SchedulerDetail, SchedulerSettings } from '../settings/scheduler/scheduler.settings';
import { ScriptLibraryDetail, ScriptLibrarySettings } from '../settings/scriptlibrary/scriptlibrary.settings';
import { ScriptLibraryVersionSettings } from '../settings/scriptlibrary/scriptlibrary.version.settings';
import { SystemPassThroughDetail, SystemPassThroughSettings } from '../settings/systemdatabase/systempassthrough.settings';
import { TemplateDetail, TemplateInfo, TemplateSettings } from '../settings/templates/templates.settings';
import { TemplatesVersionSettings } from '../settings/templates/templates.version.settings';
import { TranslationsSettings } from '../settings/translations/translations.settings';
import { UserProviderDetail, UserProviderSettings } from '../settings/user/user.provider.settings';
import { UserDetail, UserSettings } from '../settings/user/user.settings';
import { VersioningOverview } from '../settings/versioningoverview/versioning.overview';
import { EditWorkflowGroupDialog } from '../settings/workflow/edit.workflow.group.dialog';
import { WorkflowDetail, WorkflowSettings, WorkflowTemplateSettings } from '../settings/workflow/workflow.settings';
import { WorkflowVersionSettings } from '../settings/workflow/workflow.version.settings';
import {GenerateMobileAppComponent} from "../components/generate-mobile-app/generate-mobile-app.component";
import {SystemIconSettings} from "../settings/ci/system-icons/system-icon.settings";
import { HelpCenterComponent } from '../component_v2/help-center/help-center.component';
import {PersonalSettingsComponent} from "../component_v2/personal-settings/personal-settings.component";
import { HeaderComponent } from '../component_v2/header/header.component';
import { MessageCenterDialog } from '../component_v2/header/messagecenter.dialogue';
import { UserMenuControl } from '../component_v2/header/usermenu.component';
import { HelpMenuControl } from '../component_v2/header/help.menu/help.menu';
import { LiveAgentChatButtonComponent } from '../component_v2/help-center/live-agent-chat-button/live-agent-chat-button.component';
import {UserRoleSettings} from "../settings/user-roles/user-roles.settings";
import {UserPermissionsSettings} from "../settings/user-permissions/user-permissions.settings";

export const APP_SETTINGS = [
    WorkflowSettings,
    WorkflowTemplateSettings,
    WorkflowDetail,
    WorkflowVersionSettings,
    EditWorkflowGroupDialog,
    TemplateSettings,
    TemplateDetail,
    TemplatesVersionSettings,
    TemplateInfo,
    ProviderSettings,
    ProviderDetail,
    LdapForm,
    AzureADForm,
    AmazonForm,
    SimpleForm,
    GoogleForm,
    FacebookForm,
    IdentityForm,
    IdentityMFAMailDialog,
    InstagramForm,
    LinkdidForm,
    TwitterForm,
    MailSettings,
    MailDetail,
    LanguageSettings,
    TranslationsSettings,
    UserSettings,
    UserDetail,
    UserProviderSettings,
    UserProviderDetail,
    LogsSettings,
    JobsSettings,
    PermissionsPage,
    RoleSettings,
    RoleDetail,
    UserRoleSettings,
    UserPermissionsSettings,
    EnumSecuritySettings,
    ChildRoleSettings,
    GroupsSettings,
    GroupDetail,
    SchedulerSettings,
    SchedulerDetail,
    WorkflowWorkerControl,
    DBBackupControl,
    TimeTriggerControl,
    DataModelSettings,
    DataSourceSettings,
    ContainerSecuritySettings,
    ContainerFieldSecDialog,
    MediaSourceSettings,
    CapsuleExportWorkerControl,
    CapsuleImportWorkerControl,
    IconSettings,
    SystemIconSettings,
    FontSettings,
    GlobalVariableSettings,
    GlobalVariableDetail,
    MailTriggerControl,
    FTPTriggerControl,
    FileSystemTriggerControl,
    WebHookTriggerControl,
    CCOTriggerControl,
    //ContextMenuSettings,
    //ContextMenuDetail,
    ControlStylesSettings,
    ControlStylesDetail,
    ABaseElementNameControl,
    OutputSettings,
    OutputDetail,
    RepeatingChooser,
    AboutSettings,
    MetaTagsSettings,
    MetaTagsDetail,
    ThemeSettings,
    ThemesDetail,
    SystemPassThroughSettings,
    SystemPassThroughDetail,
    PagesSettings,
    PagesDetail,
    PagesVersionSettings,
    NavigationOverwiewSettings,
    NavigationOverviewDetail,
    NavigationOverviewVersionSettings,
    NavigationStructureSettings,
    //WidgetSettings,
    //WidgetDetail,
    DeploymentActionsSettings,
    DeploymentActionsDetail,
    CustomCSSVarsSettings,
    CustomCSSVarsDetail,
    ScriptLibrarySettings,
    ScriptLibraryDetail,
    ScriptLibraryVersionSettings,
    JiraSettings,
    JiraDetail,
    GeneralSettings,
    DBInfoInteractivePropertyControl,
    CustomRestSettings,
    CustomRestDetail,
    NumberCircleSettings,
    NumberCircleDetail,
    ConnectorOverviewSettings,
    ConnectorOverviewDetail,
    ConnectorScriptsSettings,
    ConnectorScriptsDetail,
    ConnectorWorkflowMappingSettings,
    LayoutEventsSettings,
    LayoutEventsDetail,
    LogsMailSettings,
    LogsMailDetail,
    GitSettings,
    AppBuilderSettings,
    CustomCSSSettings,
    CustomCSSDetail,
    ChartPaletteSettings,
    ChartPaletteDetail,
    CapsuleOverviewPage,
    ChangeLogOverview,
    SaveChangeLogDialog,
    VersioningOverview,
    AgentSettings,
    AgentDetail,
    HelpCenterComponent,
    GenerateMobileAppComponent,
    PersonalSettingsComponent,
    HeaderComponent,
    MessageCenterDialog,
    UserMenuControl,
    HelpMenuControl,
    LiveAgentChatButtonComponent,
];
