import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { MultiCacheService } from '../../cache/multi/cache.service';
import { TranslateHelper } from '../../helpers/injector.helper';
import { ATaskSettingsHelper } from '../../helpers/task.registry';
import { TranslatedString } from '../../models/translatedstring.model';
import { DataDescriptionHelper } from '../query/ddhelper.query';

@Directive()
export abstract class ATaskPanel {

    levelUniqueToUUID: Map<number, UUID> = new Map<number, UUID>();
    measureUniqueToUUID: Map<number, UUID> = new Map<number, UUID>();

    DataDescriptionValue;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(val) {
        this.DataDescriptionValue = val;
        this.DataDescriptionChange.emit(val);
    }

    @Output() DataDescriptionChange = new EventEmitter<any>();

    SettingsValue;
    @Input()
    get Settings() {
        return this.SettingsValue;
    }
    set Settings(val) {
        this.SettingsValue = val;
        this.OnSettingsSet();
        this.SettingsChange.emit(val);
    }

    @Output() SettingsChange = new EventEmitter<any>();

    LayoutElementValue;
    @Input()
    get LayoutElement() {
        return this.LayoutElementValue;
    }
    set LayoutElement(val) {
        this.LayoutElementValue = val;
        this.LayoutElementChange.emit(val);
    }

    @Output() LayoutElementChange = new EventEmitter<any>();

    OnSettingsSet() {
    }

}

@Directive()
export abstract class ALevelPanel extends ATaskPanel implements OnInit {

    Levels = [];

    ngOnInit(): void {
        const levels = [{
            Caption: TranslateHelper.TranslatorInstance.instant('@@Keine Auswahl'),
            Value: null
        }];
        if (this.DataDescription) {
            if (this.DataDescription.XLevelNodes && this.DataDescription.XLevelNodes.Areas) {
                this.DataDescription.XLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    levels.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                    this.levelUniqueToUUID.set(lev.UniqueID, lev.Level);
                                });
                            }
                        });
                    }
                });
            }
            if (this.DataDescription.YLevelNodes && this.DataDescription.YLevelNodes.Areas) {
                this.DataDescription.YLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    levels.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                    this.levelUniqueToUUID.set(lev.UniqueID, lev.Level);
                                });
                            }
                        });
                    }
                });
            }
        }
        this.Levels = levels;
    }
}

@Directive()
export abstract class AMeasurePanel extends ATaskPanel implements OnInit {

    Measures = [];

    ngOnInit(): void {
        const measures = [{
            Caption: TranslateHelper.TranslatorInstance.instant('@@Keine Auswahl'),
            Value: null
        }];
        if (this.DataDescription) {
            if (this.DataDescription.XLevelNodes && this.DataDescription.XLevelNodes.Areas) {
                this.DataDescription.XLevelNodes.Areas.forEach(area => {
                    if (area.Measures) {
                        area.Measures.forEach(m => {
                            measures.push({
                                Caption: m.toString(),
                                Value: m.UniqueID
                            });
                            this.measureUniqueToUUID.set(m.UniqueID, m.Measure);
                        });
                    }
                });
            }
            if (this.DataDescription.YLevelNodes && this.DataDescription.YLevelNodes.Areas) {
                this.DataDescription.YLevelNodes.Areas.forEach(area => {
                    if (area.Measures) {
                        area.Measures.forEach(m => {
                            measures.push({
                                Caption: m.toString(),
                                Value: m.UniqueID
                            });
                        });
                    }
                });
            }
        }
        this.Measures = measures;
    }
}

@Directive()
export abstract class ALevelMeasurePanel extends ATaskPanel implements OnInit {

    Measures = [];
    Levels = [];


    ngOnInit(): void {
        //cumulateTask setzt hier vorher schon "Keine Auswahl" als Element
        const levels = this.Levels;
        const measures = [];

        if (this.DataDescription) {
            if (this.DataDescription.XLevelNodes && this.DataDescription.XLevelNodes.Areas) {
                this.DataDescription.XLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    levels.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                    this.levelUniqueToUUID.set(lev.UniqueID, lev.Level);
                                });
                            }
                        });
                    }
                    if (area.Measures) {
                        area.Measures.forEach(m => {
                            measures.push({
                                Caption: m.toString(),
                                Value: m.UniqueID
                            });
                            this.measureUniqueToUUID.set(m.UniqueID, m.Measure);
                        });
                    }
                });
            }
            if (this.DataDescription.YLevelNodes && this.DataDescription.YLevelNodes.Areas) {
                this.DataDescription.YLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    levels.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                    this.levelUniqueToUUID.set(lev.UniqueID, lev.Level);
                                });
                            }
                        });
                    }
                    if (area.Measures) {
                        area.Measures.forEach(m => {
                            measures.push({
                                Caption: m.toString(),
                                Value: m.UniqueID
                            });
                            this.measureUniqueToUUID.set(m.UniqueID, m.Measure);
                        });
                    }
                });
            }
        }
        this.Levels = levels;
        if (this.Levels.length > 0 && !this.Settings.LevelUniqueID) {
            this.Settings.LevelUniqueID = this.Levels[0].Value;
        }
        this.Measures = measures;
        if (this.Measures.length > 0 && !this.Settings.MeasureUniqueID) {
            this.Settings.MeasureUniqueID = this.Measures[0].Value;
        }
    }
}

export abstract class ALevelTaskSettingsHelper extends ATaskSettingsHelper {
    getCaption(task: any, dd: any) {
        if (task && task.LevelUniqueID && dd) {
            const levelNode = DataDescriptionHelper.FindLevelNodeByID(dd, task.LevelUniqueID);
            if (levelNode) {
                return TranslatedString.GetTranslation(levelNode.Caption);
            }
        }
        return '';
    }


    async Gettooltip(task: any, dd: any) {
        let tooltip = '';
        if (task && dd) {
            const levelNode = DataDescriptionHelper.FindLevelNodeByID(dd, task.LevelUniqueID);
         
            if (levelNode?.Level) {
                const levelDB = await MultiCacheService.GetLevel(levelNode.Level, dd.DataModelID);

                if (levelDB) {

                    const levelName = levelDB ? ' > ' + levelDB.Name : '';
                    const hierDBName = levelDB.Parent?.Name ? ' > ' + levelDB.Parent.Name : '';
                    const dimDBName = levelDB.Parent?.Parent?.Name ? levelDB.Parent.Parent.Name : '';



                    tooltip += TranslateHelper.TranslatorInstance.instant("@@Level: ") + dimDBName + hierDBName + levelName + ' #' + levelDB.Caption;

                }

            }
            return tooltip;

        }
    }
}

export abstract class AMeasureTaskSettingsHelper extends ATaskSettingsHelper {
    getCaption(task: any, dd: any) {
        if (task && task.MeasureUniqueID && dd) {
            const measureInfo = DataDescriptionHelper.FindMeasureByID(dd, task.MeasureUniqueID);
            if (measureInfo) {
                return TranslatedString.GetTranslation(measureInfo.Caption);
            }
        }
        return '';
    }

     async Gettooltip(task: any, dd: any) {
        let tooltip = '';
        if (task && task.MeasureUniqueID && dd) {
            const measure = DataDescriptionHelper.FindMeasureByID(dd, task.MeasureUniqueID);

            if (measure?.Measure) {
                const mDB = await MultiCacheService.GetMeasure(measure.Measure, dd.DataModelID);
                const measureCube = mDB.Cube?.Name ? mDB.Cube.Name : '';
                const measureFolder = mDB.Folder ? ' > ' + mDB.Folder : '';
                const measureName = mDB.Name ? ' > ' + mDB.Name : '';


                tooltip += TranslateHelper.TranslatorInstance.instant("@@Measure: ") + measureCube + measureFolder + measureName + ' #' + mDB.Caption;

            }
        }
        return tooltip;
    }
}

export abstract class ALevelMeasureTaskSettingsHelper extends ATaskSettingsHelper {
    getCaption(task: any, dd: any) {
        if (task && dd) {
            let measureInfo;
            let levelNode;
            if (task.MeasureUniqueID) {
                measureInfo = DataDescriptionHelper.FindMeasureByID(dd, task.MeasureUniqueID);
            }
            if (task.LevelUniqueID) {
                levelNode = DataDescriptionHelper.FindLevelNodeByID(dd, task.LevelUniqueID);
            }
            if (measureInfo) {
                if (levelNode) {
                    return TranslatedString.GetTranslation(measureInfo.Caption) + ' - ' +
                        TranslatedString.GetTranslation(levelNode.Caption);
                } else {
                    return TranslatedString.GetTranslation(measureInfo.Caption);
                }
            } else if (levelNode) {
                return TranslatedString.GetTranslation(levelNode.Caption);
            }
        }
        return '';
    }

    async Gettooltip(task: any, dd: any) {
        let tooltip = '';
        if (task &&  dd) {
            const levelNode  = DataDescriptionHelper.FindLevelNodeByID(dd, task.LevelUniqueID);
         
            if (levelNode?.Level ) {
                const levelDB = await MultiCacheService.GetLevel(levelNode.Level, dd.DataModelID);

                if (levelDB) {

                    const levelName = levelDB ? ' > ' + levelDB.Name : '';
                    const hierDBName = levelDB.Parent?.Name ? ' > ' + levelDB.Parent.Name : '';
                    const dimDBName = levelDB.Parent?.Parent?.Name ? levelDB.Parent.Parent.Name : '';



                    tooltip += TranslateHelper.TranslatorInstance.instant("@@Level: ") + dimDBName + hierDBName + levelName + ' #' + levelDB.Caption +"# ";
                 
                }

            }

            const measure = DataDescriptionHelper.FindMeasureByID(dd, task.MeasureUniqueID);

            if (measure?.Measure) {
                const mDB = await MultiCacheService.GetMeasure(measure.Measure, dd.DataModelID);
                const measureCube = mDB.Cube?.Name ? mDB.Cube.Name : '';
                const measureFolder = mDB.Folder ? ' > ' + mDB.Folder : '';
                const measureName = mDB.Name ? ' > ' + mDB.Name : '';


                tooltip += TranslateHelper.TranslatorInstance.instant("@@Measure: " ) + measureCube + measureFolder + measureName + ' #' + mDB.Caption;

            }
            return tooltip;

        }
    }
}
