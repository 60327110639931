import { Component, Input } from '@angular/core';

@Component({
    selector: 'augment-control',
    templateUrl: './augment.control.html',
    styleUrls: ['./augment.control.css']
})
export class AugmentControl {

    HOD = false;
    HODDisabled = false;
    DOW = false;
    DOWDisabled = false;
    DOM = false;
    DOMDisabled = false;
    WOY = false;
    WOYDisabled = false;
    MOY = false;
    MOYDisabled = false;
    Rounding = '';

    ShowRoundingValue = true;

    @Input()
    get ShowRounding() {
        return this.ShowRoundingValue;
    }
    set ShowRounding(val) {
        this.ShowRoundingValue = val;
    }

    DataValue;    

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val != this.DataValue) {
            this.DataValue = val;
            if (val) {
                if (val.Augments) {
                    this.HOD = (val.Augments & 1) === 1;
                    this.DOW = (val.Augments & 2) === 2;
                    this.MOY = (val.Augments & 4) === 4;
                    this.WOY = (val.Augments & 8) === 8;
                    this.DOM = (val.Augments & 16) === 16;
                } else {
                    this.HOD = false;
                    this.DOW = false;
                    this.MOY = false;
                    this.WOY = false;
                    this.DOM = false;
                }
                if (val.Rounding) {
                    this.Rounding = val.Rounding;
                } else {
                    this.Rounding = '';
                }
            } else {
                this.HOD = false;
                this.DOW = false;
                this.MOY = false;
                this.WOY = false;
                this.DOM = false;
                this.Rounding = '';
            }
            this.checkEnabled();
        }
    }

    checkEnabled() {
        this.HODDisabled = false;
        this.DOWDisabled = false;
        this.MOYDisabled = false;
        this.DOMDisabled = false;
        this.WOYDisabled = false;
        switch (this.Rounding) {
            case 'D':
                this.HODDisabled = true;
                break;
            case 'W':
                this.HODDisabled = true;
                this.DOMDisabled = true;
                break;
            case 'M':
                this.HODDisabled = true;
                this.DOWDisabled = true;
                this.DOMDisabled = true;
                this.WOYDisabled = true;
                break;
            case 'Q':
                this.HODDisabled = true;
                this.DOWDisabled = true;
                this.MOYDisabled = true;
                this.DOMDisabled = true;
                this.WOYDisabled = true;
                break;
            case 'Y':
                this.HODDisabled = true;
                this.DOWDisabled = true;
                this.MOYDisabled = true;
                this.DOMDisabled = true;
                this.WOYDisabled = true;
                break;
        }
    }

    onRoundingChanged() {
        if (this.DataValue) {
            this.DataValue.Rounding = this.Rounding;
        }
        this.checkEnabled();
    }

    onAugmentChanged() {
        if (this.DataValue) {
            let augments = 0;
            if (this.HOD) {
                augments |= 1;
            }
            if (this.DOW) {
                augments |= 2;
            }
            if (this.MOY) {
                augments |= 4;
            }
            if (this.WOY) {
                augments |= 8;
            }
            if (this.DOM) {
                augments |= 16;
            }
            this.DataValue.Augments = augments;
        }
    }
}
