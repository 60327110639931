import { deserialize, plainToClass } from 'class-transformer';
import { TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { WorkflowNameChangeDialog } from '../components/dialogs/workflowdebug/workflow.name.change.dialog';
import { LayoutChangeType } from '../models/enums/layoutchangetype.enum';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../models/enums/messageboxresult.enum';
import { ViewType } from '../models/enums/viewtype.enum';
import {
    GitComparison, GitComparisonDiff, GitComparisonDocument, GitComparisonElement,
    GitComparisonOptions, GitComparisonWorkflow, GitDiffChangeType, GitDiffType
} from '../models/git/gitcomparison.model';
import { Layout } from '../models/layout.model';
import {
    LayoutChange, LayoutElementAddedValue, LayoutElementRemovedValue, LayoutPositionChangeValue,
    LayoutPropertyChangeValue, MultiLayoutPositionChangeValue, MultiLayoutPropertyChangeValue, MultiLayoutWorkflowChangeValue,
    PropertyChangeValue, ResolutionAddedValue, ResolutionRemovedValue, WorkflowAddedValue, WorkflowRemovedValue
} from '../models/layout/layout.change.model';
import { EventData } from '../models/layoutbase.model';
import { LayoutElement } from '../models/layoutelement.model';
import { WorkflowDescription } from '../models/workflow/workflow.model';
import { LayoutService } from '../services/layout.service';
import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { TranslateFormatText } from './array.helpers';
import { InjectorHelper, TranslateHelper } from './injector.helper';
import { VariableHelper } from './variable.helper';

// @dynamic
export class LayoutHelper {
    //#region GetElementByName
    public static getElementByName(elementList: LayoutElement[], name: string, ignoreTemplate?: boolean): LayoutElement {
        if (ignoreTemplate === true) {
            return LayoutHelper.getElementByElementName(elementList, name);
        } else {
            return LayoutHelper.getElementByNameAndTemplate(elementList, name, null);
        }
    }
    private static getElementByNameAndTemplate(elementList: LayoutElement[], name: string, templateName: string): LayoutElement {
        let retVal: LayoutElement = null;
        if (elementList && name) {
            elementList.some(elem => {
                const checkName = templateName ? templateName + '.' + elem.Name : elem.Name;
                if (checkName === name) {
                    retVal = elem;
                    return true;
                }
                let tmpName = templateName;
                if (elem.ElementType === 'template') {
                    if (tmpName) {
                        tmpName += '.' + (elem.Name || '');
                    } else {
                        tmpName = (elem.Name || '');
                    }
                }
                retVal = LayoutHelper.getElementByNameAndTemplate(elem.Elements, name, tmpName);
                if (retVal) {
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }

    private static getElementByElementName(elementList: LayoutElement[], name: string): LayoutElement {
        let retVal: LayoutElement = null;
        if (elementList && name) {
            elementList.some(elem => {
                if (elem.Name === name) {
                    retVal = elem;
                    return true;
                }
                retVal = LayoutHelper.getElementByElementName(elem.Elements, name);
                if (retVal) {
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }
    //#endregion
    //#region GetElementPath
    public static GetElementPath(layout, elemName) {
        const retVal = [];
        if (layout) {
            const path = LayoutHelper.FindElemRecursive([layout], elemName, null);
            retVal.push(...path);
        }
        return retVal;
    }
    private static FindElemRecursive(elements, elemName, templateName) {
        const retVal = [];
        if (elements) {
            elements.some(elem => {
                const checkName = templateName ? templateName + '.' + elem.Name : elem.Name;
                if (checkName === elemName) {
                    retVal.push(elem);
                    return true;
                }
                let tmpName = templateName;
                if (elem.ElementType === 'template') {
                    if (tmpName) {
                        tmpName += '.' + (elem.Name || '');
                    } else {
                        tmpName = (elem.Name || '');
                    }
                }
                const childPath = LayoutHelper.FindElemRecursive(elem.Elements, elemName, tmpName);
                if (childPath.length > 0) {
                    retVal.push(elem);
                    retVal.push(...childPath);
                }
            });
        }
        return retVal;
    }
    //#endregion
    //#region GetElement
    public static GetElementByID(elem, id) {
        if (elem) {
            if (elem.ID === id) {
                return elem;
            }
            if (elem.Elements) {
                let retVal;
                if (elem.Elements.some(x => {
                    retVal = LayoutHelper.GetElementByID(x, id);
                    if (retVal) {
                        return true;
                    }
                    return false;
                })) {
                    return retVal;
                }
            }
        }
        return null;
    }
    public static GetNextElementName(item, type, caption) {
        const names = {};
        let count = LayoutHelper.GetNamesFromItemsWithType(item, type, names) + 1;
        let name = caption + '_' + count;
        while (names[name]) {
            count++;
            name = caption + '_' + count;
        }
        return name;
    }
    private static GetNamesFromItemsWithType(item, type, nameObj) {
        let retVal = 0;
        if (item) {
            if (item.ElementType === type) {
                if (typeof item.Name === 'string') {
                    nameObj[item.Name] = true;
                    retVal++;
                }
            }
            if (item.Elements) {
                item.Elements.forEach(elem => {
                    retVal += LayoutHelper.GetNamesFromItemsWithType(elem, type, nameObj);
                });
            }
        }
        return retVal;
    }
    public static GetNextElementNameByPrefix(item, prefix) {
        const names = {};
        let count = LayoutHelper.GetNamesFromItemsWithPrefix(item, prefix, names) + 1;
        let name = prefix + '_' + count;
        while (names[name]) {
            count++;
            name = prefix + '_' + count;
        }
        return name;
    }
    private static GetNamesFromItemsWithPrefix(item, prefix, nameObj) {
        let retVal = 0;
        if (item) {
            if (typeof item.Name === 'string' && item.Name.startsWith(prefix)) {
                nameObj[item.Name] = true;
                retVal++;
            }
            if (item.Elements) {
                item.Elements.forEach(elem => {
                    retVal += LayoutHelper.GetNamesFromItemsWithPrefix(elem, prefix, nameObj);
                });
            }
        }
        return retVal;
    }
    //#endregion

    public static RefreshMenuTabs: Subject<any> = new Subject();


    public static GetDroppedLayoutElement(dragItem): LayoutElement {
        const insert = new LayoutElement();
        insert.ElementType = dragItem.Type;
        insert.Editable = dragItem.Editable;
        insert.Name = dragItem.Name;
        if (dragItem.Layout) {
            Object.assign(insert, dragItem.Layout);
        }
        if (dragItem.Type === 'template') {
            Object.assign(insert, dragItem);
        }
        if (dragItem.Type === 'widget') {
            Object.assign(insert, dragItem);
        }
        insert.SetViewType(ViewType.Edit);
        return insert;
    }
    public static GetLayout() {
        const page = NavigationService.SelectedPage.getValue();
        if (page === 'content' || page === 'widget' || page === 'template' || page === 'output' || page === 'layout') {
            return LayoutService.SelectedLayout.getValue();
        }
        return null;
    }
    public static GetActiveResolution(layout) {
        const size = LayoutService.RootSize.getValue();
        if (layout && size) {
            let resolution = null;
            if (layout.Resolutions && layout.Resolutions.length > 0) {
                resolution = layout.Resolutions.find((value) => size.Width > value.FromWidth && size.Width <= value.ToWidth);
            }
            if (resolution) {
                LayoutService.SelectedResolution.next(resolution);
                return resolution;
            } else {
                LayoutService.SelectedResolution.next(null);
            }
        } else {
            LayoutService.SelectedResolution.next(null);
        }
        
        return layout;
    }

    //#region AppfameworkLibrary
    public static InitSelectionListeners() {
        LayoutService.SelectedItem.subscribe((item) => {
            const baseLayout = LayoutService.SelectedLayout.getValue();
            if (baseLayout) {
                if (item) {
                    LayoutService.DragType.next(item.ID);
                    let resolutionfound = false;
                    if (baseLayout.Resolutions && baseLayout.Resolutions.length > 0) {
                        const size = LayoutService.RootSize.getValue();
                        if (size) {
                            baseLayout.Resolutions.forEach((res) => {
                                if (size.Width > res.FromWidth && size.Width <= res.ToWidth) {
                                    resolutionfound = true;
                                    if (res.ID == item.ID) {
                                        item.Selected = true;
                                    }
                                    res.Elements.forEach((i) => {
                                        this.checkItem(i, item);
                                    });
                                }
                            });
                        }
                    }
                    if (!resolutionfound) {
                        this.checkItem(baseLayout, item);
                        LayoutService.SelectionChanged.next(true);
                    }
                }
            }
        });
        LayoutService.SelectedItems.subscribe((items) => {
            const baseLayout = LayoutService.SelectedLayout.getValue();
            if (baseLayout) {
                if (items) {
                    LayoutService.DragType.next(null);
                }
                let resolutionfound = false;
                if (baseLayout.Resolutions && baseLayout.Resolutions.length > 0) {
                    const size = LayoutService.RootSize.getValue();
                    if (size) {
                        baseLayout.Resolutions.forEach((res) => {
                            if (size.Width > res.FromWidth && size.Width <= res.ToWidth) {
                                resolutionfound = true;
                                res.Elements.forEach((i) => {
                                    this.checkItems(i, items);
                                });
                            }
                        });
                    }
                }
                if (!resolutionfound) {
                    this.checkItems(baseLayout, items);
                    LayoutService.SelectionChanged.next(true);
                }
            }
        });
    }
    private static checkItem(item: any, selecteditem: any) {
        item.Selected = item === selecteditem;
        if (item.Elements) {
            item.Elements.forEach((i) => {
                this.checkItem(i, selecteditem);
            });
        }
    }
    private static checkItems(item: any, selecteditems: any) {
        if (selecteditems) {
            const found = selecteditems.find((value) => item == value);
            item.Selected = found != null ? true : false;
            if (item.Elements) {
                item.Elements.forEach((i) => {
                    this.checkItems(i, selecteditems);
                });
            }
        }
    }
    //#endregion
    //#region LayoutChanges
    public static ApplyLayoutChange(layout, change: LayoutChange): string {
        let retVal = null;
        if (layout && change) {
            let searchLayout = layout;
            let resName;
            if (change.ResolutionID) {
                if (layout && layout.Resolutions) {
                    searchLayout = layout.Resolutions.find(x => x.ID === change.ResolutionID);
                    resName = LayoutHelper.GetElementName(searchLayout);
                } else {
                    searchLayout = null;
                }
            }
            switch (change.LayoutChangeType) {
                case LayoutChangeType.ElementAdded:
                    retVal = LayoutHelper.AddLayoutElement(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.ElementRemoved:
                    retVal = LayoutHelper.RemoveLayoutElement(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.PositionChange:
                    retVal = LayoutHelper.ChangePosition(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.MultiPositionChange:
                    retVal = LayoutHelper.ChangeMultiPosition(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.PropertyChange:
                    retVal = LayoutHelper.ApplyLayoutPropertyChange(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.MultiPropertyChange:
                    retVal = LayoutHelper.ApplyMultiLayoutPropertyChange(searchLayout, change.ChangeValue);
                    break;
                case LayoutChangeType.WorkflowAdded:
                    retVal = LayoutHelper.AddWorkflow(searchLayout, change.ChangeValue);
                    if (retVal) {
                        LayoutService.LayoutWorkflowsChanged.next(change);
                    }
                    break;
                case LayoutChangeType.WorkflowChange:
                    retVal = LayoutHelper.ChangeWorkflows(searchLayout, change.ChangeValue);
                    if (retVal) {
                        LayoutService.LayoutWorkflowsChanged.next(change);
                    }
                    break;
                case LayoutChangeType.WorkflowRemoved:
                    retVal = LayoutHelper.RemoveWorkflow(searchLayout, change.ChangeValue)
                    if (retVal) {
                        LayoutService.LayoutWorkflowsChanged.next(change);
                    }
                    break;
                case LayoutChangeType.ResolutionAdded:
                    retVal = LayoutHelper.AddResolution(layout, change.ChangeValue);
                    break;
                case LayoutChangeType.ResolutionRemoved:
                    retVal = LayoutHelper.RemoveResolution(layout, change.ChangeValue);
                    break;
            }
            if (retVal && resName) {
                retVal += ' (Resolution: ' + resName + ')';
            }
        }
        return retVal;
    }
    //#region LayoutElement
    public static GetElementName(elem): string {
        if (elem) {
            if (elem.Name) {
                return elem.Name;
            }
            return elem.ID;
        }
        return '';
    }

    private static AddLayoutElement(layout, lea: LayoutElementAddedValue): string {
        const elem = LayoutHelper.GetElementByID(layout, lea.ParentID);
        if (elem) {
            if (!elem.Elements) {
                elem.Elements = [];
            }
            const le = plainToClass(LayoutElement, JSON.parse(lea.ElementContent));
            le.SetViewType(elem.ViewType);
            elem.Elements.splice(lea.Index, 0, le);
            if (elem.ValuesChanged) {
                elem.ValuesChanged.next();
            }
            LayoutService.LayoutElementsChangedEvent.next(null);
            return 'Added element ' + LayoutHelper.GetElementName(le) + ' to element ' + LayoutHelper.GetElementName(elem);
        }
        return null;
    }

    private static RemoveLayoutElement(layout, ler: LayoutElementRemovedValue): string {
        const elem = LayoutHelper.GetElementByID(layout, ler.ParentID);
        if (elem && elem.Elements) {
            let index;
            let removedName;
            if (elem.Elements.some((x, i) => {
                if (x.ID === ler.ElementID) {
                    index = i;
                    removedName = LayoutHelper.GetElementName(x);
                    return true;
                }
                return false;
            })) {
                elem.Elements.splice(index, 1);
                if (elem.ValuesChanged) {
                    elem.ValuesChanged.next();
                }
                LayoutService.LayoutElementsChangedEvent.next(null);
                return 'Removed element ' + removedName + ' from element ' + LayoutHelper.GetElementName(elem);
            }
        }
        return null;
    }

    private static ChangePosition(layout, lpc: LayoutPositionChangeValue): string {
        let elem;
        let elemIndex;
        const sourceElem = LayoutHelper.GetElementByID(layout, lpc.OldParentID);
        if (sourceElem && sourceElem.Elements && sourceElem.Elements.some((x, i) => {
            if (x.ID === lpc.ElementID) {
                elem = x;
                elemIndex = i;
                return true;
            }
            return false;
        })) {
            const targetElem = lpc.OldParentID === lpc.NewParentID ? sourceElem : LayoutHelper.GetElementByID(layout, lpc.NewParentID);
            if (targetElem) {
                sourceElem.Elements.splice(elemIndex, 1);
                if (sourceElem.ValuesChanged) {
                    sourceElem.ValuesChanged.next();
                }
                if (!targetElem.Elements) {
                    targetElem.Elements = [];
                }
                targetElem.Elements[lpc.Index] = elem;
                if (targetElem.ValuesChanged) {
                    targetElem.ValuesChanged.next();
                }
                LayoutService.LayoutElementsChangedEvent.next(null);
                if (lpc.OldParentID === lpc.NewParentID) {
                    return 'Moved element ' + LayoutHelper.GetElementName(elem) +
                        ' inside element ' + LayoutHelper.GetElementName(sourceElem);
                } else {
                    return 'Moved element ' + LayoutHelper.GetElementName(elem) + ' from element ' +
                        LayoutHelper.GetElementName(sourceElem) + ' to element ' + LayoutHelper.GetElementName(targetElem);
                }
            }
        }
        return null;
    }

    private static ChangeMultiPosition(layout, lpc: MultiLayoutPositionChangeValue): string {
        if (lpc && lpc.Elements) {
            const targetElem = LayoutHelper.GetElementByID(layout, lpc.NewParentID);
            if (targetElem) {
                const elemList = [];
                const sourceElems = {};
                const removedElems = {};
                lpc.Elements.forEach(lpcElem => {
                    if (lpcElem.Elements && lpcElem.Elements.length > 0) {
                        let sourceElem;
                        if (lpcElem.ParentID === lpc.NewParentID) {
                            sourceElem = targetElem;
                        } else {
                            sourceElem = sourceElems[lpcElem.ParentID];
                            if (!sourceElem) {
                                sourceElem = LayoutHelper.GetElementByID(layout, lpcElem.ParentID);
                                if (sourceElem) {
                                    sourceElems[lpcElem.ParentID] = sourceElem;
                                }
                            }
                        }
                        if (sourceElem && sourceElem.Elements) {
                            const removedList = [];
                            lpcElem.Elements.forEach(lpcElemElem => {
                                let elem;
                                let elemIndex;
                                if (sourceElem.Elements.some((x, i) => {
                                    if (x.ID === lpcElemElem.ElementID) {
                                        elem = x;
                                        elemIndex = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    elemList.push({
                                        Element: elem,
                                        DropIndex: lpcElemElem.DropIndex
                                    });
                                    sourceElem.Elements.splice(elemIndex, 1);
                                    removedList.push(LayoutHelper.GetElementName(elem));
                                }
                            });
                            if (removedList.length > 0) {
                                const rE = removedElems[lpcElem.ParentID];
                                if (rE) {
                                    rE.RemovedElements.push(...removedList);
                                } else {
                                    removedElems[lpcElem.ParentID] = {
                                        Name: LayoutHelper.GetElementName(sourceElem),
                                        RemovedElements: removedList
                                    };
                                }
                            }
                        }
                    }
                });
                if (elemList.length > 0) {
                    Object.keys(sourceElems).forEach(x => {
                        const elem = sourceElems[x];
                        if (elem.ValuesChanged) {
                            elem.ValuesChanged.next();
                        }
                    });
                    elemList.sort((a, b) => b.DropIndex - a.DropIndex);
                    const layoutElemList = [];
                    elemList.forEach(x => layoutElemList.push(x.Element));
                    if (!targetElem.Elements) {
                        targetElem.Elements = [];
                    }
                    targetElem.Elements.splice(lpc.Index, 0, ...layoutElemList);
                    if (targetElem.ValuesChanged) {
                        targetElem.ValuesChanged.next();
                    }
                    LayoutService.LayoutElementsChangedEvent.next(null);
                    let retVal = 'Moved ';
                    Object.keys(removedElems).forEach((x, i) => {
                        if (i > 0) {
                            retVal += ' and '
                        }
                        const elem = removedElems[x];
                        retVal += 'element';
                        if (elem.RemovedElements.length > 0) {
                            retVal += 's';
                        }
                        retVal += ' ' + elem.RemovedElements.join(', ') + ' from element ' + elem.Name;
                    });
                    retVal += ' to element ' + LayoutHelper.GetElementName(targetElem);
                    return retVal;
                }
            }
        }
        return null;
    }

    private static ApplyLayoutPropertyChange(layout, lpc: LayoutPropertyChangeValue): string {
        LayoutHelper.CheckProperty(lpc);
        const obj = {};
        obj[lpc.ElementID] = [lpc];
        const resFound = LayoutHelper.ExecuteLayoutChange(layout, obj);
        if (resFound) {
            LayoutService.LayoutPropertyChangedEvent.next(obj);
        }
        return resFound;
    }

    private static ApplyMultiLayoutPropertyChange(layout, mlpc: MultiLayoutPropertyChangeValue): string {
        const obj = {};
        mlpc.Elements.forEach(x => {
            x.Properties.forEach(p => LayoutHelper.CheckProperty(p));
            x.ElementIDs.forEach(id => {
                const list = obj[id];
                if (list) {
                    list.push(...x.Properties);
                } else {
                    obj[id] = [...x.Properties];
                }
            });
        });
        const resFound = LayoutHelper.ExecuteLayoutChange(layout, obj);
        if (resFound) {
            LayoutService.LayoutPropertyChangedEvent.next(obj);
        }
        return resFound;
    }

    private static CheckProperty(pcv: PropertyChangeValue) {
        if (pcv) {
            switch (pcv.PropertyName) {
                case 'Elements':
                    if (Array.isArray(pcv.Value)) {
                        pcv.Value = plainToClass(LayoutElement, pcv.Value);
                    }
                    break;
                case 'Events':
                    if (Array.isArray(pcv.Value)) {
                        pcv.Value = plainToClass(EventData, pcv.Value);
                    }
                    break;
                // Weitere Props? LayoutUnit? LayoutUnitThickness, etc?
            }
        }
    }
    //#endregion
    //#region Workflow
    private static AddWorkflow(layout, wav: WorkflowAddedValue): string {
        const parent = LayoutHelper.GetElementByID(layout, wav.LayoutID);
        if (parent) {
            const wf = deserialize(WorkflowDescription, wav.Workflow);
            if (parent.Workflows) {
                parent.Workflows.push(wf);
            } else {
                parent.Workflows = [wf];
            }
            return 'Added workflow ' + wf.Caption + ' on element ' + LayoutHelper.GetElementName(parent);
        }
        return null;
    }

    private static ChangeWorkflows(layout, mlwcv: MultiLayoutWorkflowChangeValue): string {
        const parent = LayoutHelper.GetElementByID(layout, mlwcv.LayoutID);
        if (parent && parent.Workflows) {
            const changedList = [];
            const wfList = {};
            mlwcv.Workflows.forEach(x => {
                const wfD = deserialize(WorkflowDescription, x);
                wfList[wfD.ID] = wfD;
            });
            for (let i = 0; i < parent.Workflows.length; i++) {
                const wfD = wfList[parent.Workflows[i].ID];
                if (wfD) {
                    parent.Workflows.splice(i, 1, wfD);
                    changedList.push(wfD.Caption);
                }
            }
            if (changedList.length > 0) {
                let retVal = 'Changed workflow';
                if (changedList.length > 1) {
                    retVal += 's';
                }
                retVal += ' ' + changedList.join(', ') + ' on element ' + LayoutHelper.GetElementName(parent);
                return retVal;
            }
        }
        return null;
    }

    private static RemoveWorkflow(layout, wrv: WorkflowRemovedValue):string {
        const parent = LayoutHelper.GetElementByID(layout, wrv.LayoutID);
        if (parent && parent.Workflows) {
            let index;
            let caption;
            if (parent.Workflows.some((x, i) => {
                if (x.ID === wrv.WorkflowID) {
                    index = i;
                    caption = x.Caption;
                    return true;
                }
                return false;
            })) {
                parent.Workflows.splice(index, 1);
                return 'Removed workflow ' + caption + ' from element ' + LayoutHelper.GetElementName(parent);
            }
        }
        return null;
    }
    //#endregion
    //#region Resolution
    private static AddResolution(layout, rav: ResolutionAddedValue): string {
        if (layout) {
            const res = plainToClass(Layout, JSON.parse(rav.Resolution));
            if (layout.Resolutions) {
                layout.Resolutions.push(res);
            } else {
                layout.Resolutions = [res];
            }
            return 'Added resolution ' + LayoutHelper.GetElementName(res) + ' on element ' + LayoutHelper.GetElementName(layout);
        }
        return null;
    }

    private static RemoveResolution(layout, rrv: ResolutionRemovedValue): string {
        if (layout && layout.Resolutions) {
            let index;
            let caption;
            if (layout.Resolutions.some((x, i) => {
                if (x.ID === rrv.ResolutionID) {
                    index = i;
                    caption = LayoutHelper.GetElementName(x);
                    return true;
                }
                return false;
            })) {
                layout.Resolutions.splice(index, 1);
                return 'Removed resolution ' + caption + ' from element ' + LayoutHelper.GetElementName(layout);
            }
        }
        return null;
    }
    //#endregion

    private static ExecuteLayoutChange(layout, obj): string {
        if (layout) {
            const changeInfo = LayoutHelper.ApplyLayoutChanges(layout, obj);
            if (changeInfo) {
                let retVal = 'Changed ';
                Object.keys(changeInfo).forEach((k, i) => {
                    if (i > 0) {
                        retVal += ' and ';
                    }
                    retVal += 'property ' + k + ' on element';
                    const list = changeInfo[k];
                    if (list.length > 1) {
                        retVal += 's';
                    }
                    retVal += ' ' + list.join(', ');
                });
                return retVal;
            }
        }
        return null;
    }

    private static ApplyLayoutChanges(layoutElement, obj) {
        let retVal = null;
        if (layoutElement) {
            const propList = obj[layoutElement.ID];
            if (Array.isArray(propList) && propList.length > 0) {                
                propList.forEach(prop => {
                    const propSplits = prop.PropertyName.split('.');
                    let val = layoutElement;
                    for (let i = 0; i < propSplits.length - 1; i++) {
                        val = val[propSplits[i]];
                        if (typeof val !== 'object') {
                            // TODO: Fehlermeldung
                        }
                    }
                    val[propSplits[propSplits.length - 1]] = prop.Value;
                    if (retVal == null) {
                        retVal = {};
                    }
                    const list = retVal[prop.PropertyName];
                    if (list) {
                        list.push(LayoutHelper.GetElementName(layoutElement));
                    } else {
                        retVal[prop.PropertyName] = [LayoutHelper.GetElementName(layoutElement)];
                    }                    
                });
                if (layoutElement.ValuesChanged) {
                    layoutElement.ValuesChanged.next();
                }
            }
            if (layoutElement.Elements) {
                layoutElement.Elements.forEach(x => {
                    const nextLevel = LayoutHelper.ApplyLayoutChanges(x, obj);
                    if (nextLevel) {
                        if (retVal == null) {
                            retVal = {};
                        }
                        Object.keys(nextLevel).forEach(k => {
                            const list = retVal[k];
                            if (list) {
                                list.push(...nextLevel[k]);
                            } else {
                                retVal[k] = [...nextLevel[k]];
                            }
                        });
                    }
                });
            }
        }
        return retVal;
    }
    //#endregion
   
    static checkURL(item) {
        if (item && item.URL) {
            item.URL = item.URL.replace(/ /g, '').replace(/\/\/*/g, '/');
            if (!item.URL.startsWith('/')) {
                item.URL = '/' + item.URL;
            }
        }
    }

    private static GetAllElementNames(layoutElement, list, templatePath) {
        list.push(templatePath + layoutElement.Name);
        if (layoutElement.Elements) {
            if (layoutElement.ElementType === 'template') {
                templatePath += layoutElement.Name + '.';
            }
            layoutElement.Elements.forEach(child => {
                LayoutHelper.GetAllElementNames(child, list, templatePath);
            });
        }
    }

    private static DoDelete(layoutElement, onDelete, layout) {
        const settingsService = InjectorHelper.InjectorInstance.get<SettingsService>(SettingsService);
        settingsService.Delete.next(layoutElement);
        LayoutService.SelectedItem.next(layout);
        LayoutService.RefreshTree.next(true);
        if (typeof onDelete === 'function') {
            onDelete();
        }
    }

    public static OnDeleteElement(layoutElement, templatePath, layout, onDelete) {
        const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Element {0} loeschen moechten?');
        text.FormatParams.push(layoutElement.Name);
        MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
            MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                if (x === MessageBoxResult.Yes) {
                    if (layout && layout.Workflows && layout.Workflows.length > 0) {
                        const elemNames = [];
                        LayoutHelper.GetAllElementNames(layoutElement, elemNames, templatePath ?? '');
                        WorkflowNameChangeDialog.CheckObjectDelete(layout, elemNames).then((y) => {
                            if (y === MessageBoxResult.Yes) {
                                LayoutHelper.DoDelete(layoutElement, onDelete, layout);
                            }
                        });
                    } else {
                        LayoutHelper.DoDelete(layoutElement, onDelete, layout);
                    }
                }
            });
    }

    public static setTimeout(min, max) {
        return new Promise((resolve, reject) => {
            min = Math.ceil(min);
            max = Math.floor(max);
            const ms = Math.floor(Math.random() * (max - min)) + min;
            setTimeout(() => {
                resolve(null);
            }, ms);
        });
    }

    public static CheckTemplate(template) {
        if (template) {
            if (template.Elements && template.Elements.length > 0) {
                const first = template.Elements[0];
                if (first.Workflows) {
                    template.Workflows = JSON.parse(JSON.stringify(first.Workflows));
                    delete first.Workflows;
                }
                if (first.CustomEvents) {
                    template.CustomEvents = JSON.parse(JSON.stringify(first.CustomEvents));
                    delete first.CustomEvents;
                }
            } else {
                const le = new LayoutElement();
                le.ElementType = 'grid';
                le.Name = 'Base';
                template.Elements = [le];
            }
        }
    }

    //#region LayoutCompare
    //private static TempLayout;
    //private static Layout;
    //public static SetActiveLayout(layout) {
    //    LayoutHelper.Layout = layout;
    //    if (layout) {
    //        LayoutHelper.TempLayout = LayoutHelper.ParseLayout(layout);
    //    } else {
    //        LayoutHelper.TempLayout = null;
    //    }
    //}
    //public static GetActiveChanges() {
    //    if (LayoutHelper.TempLayout && LayoutHelper.Layout) {
    //        return LayoutHelper.CompareParsed(LayoutHelper.TempLayout, LayoutHelper.ParseLayout(LayoutHelper.Layout), {
    //            CreateChangesTree: true,
    //            DeepComparison: true
    //        })
    //    }
    //}
    public static CompareParsed(a: GitComparisonDocument, b: GitComparisonDocument, options: GitComparisonOptions) {
        let retVal: GitComparison = new GitComparison();
        retVal.DocumentA = a;
        retVal.DocumentB = b;

        if (options.CreateChangesTree) {
            retVal.ChangesTree = [];
        } else {
            delete retVal.ChangesTree;
        }


        let layoutelements: TreeNode = {};
        layoutelements.label = TranslateHelper.TranslatorInstance.instant("@@LayoutElements");
        layoutelements.children = [];
        layoutelements.expanded = true;
        let workflows: TreeNode = {};
        workflows.label = TranslateHelper.TranslatorInstance.instant("@@Workflows");
        workflows.children = [];
        workflows.expanded = true;

        let layoutresult = LayoutHelper.CompareLayoutElements(retVal.DocumentA, retVal.DocumentB, options);
        let workflowresult = LayoutHelper.CompareWorkflows(retVal.DocumentA, retVal.DocumentB, options);
        if (options.CreateChangesTree) {
            layoutelements.children = layoutresult.Tree;
            workflows.children = workflowresult.Tree;
            retVal.ChangesTree = [layoutelements, workflows];
        }
        retVal.Changes = { ...layoutresult.Changes, ...workflowresult.Changes };
        let changes = Object.keys(retVal.Changes);
        if (changes && changes.length > 0) {
            retVal.HasChanges = true;
        }

        return retVal;
    } 
    public static Compare(a: LayoutElement, b: LayoutElement, options: GitComparisonOptions) {
        return LayoutHelper.CompareParsed(LayoutHelper.ParseLayout(a), LayoutHelper.ParseLayout(b), options);
    }
    public static CompareText(a, b, options: GitComparisonOptions) {
        return LayoutHelper.Compare(plainToClass(LayoutElement, JSON.parse(a)), plainToClass(LayoutElement, JSON.parse(b)), options);
    }

    public static CompareLayoutElements(a: GitComparisonDocument, b: GitComparisonDocument, options: GitComparisonOptions) {
        let retVal = {
            Tree: [],
            Changes: {}
        }
        //#region LayoutElements
        if (a.Compare && b.Compare) {

            let source = a.Compare;
            let sourceKeys = Object.keys(a.Compare);

            let target = b.Compare;
            let targetKeys = Object.keys(b.Compare);

            sourceKeys.forEach((key) => {
                if (!target[key]) {
                    let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.REMOVE, GitDiffType.LAYOUTELEMENT, a.Layout, b.Layout);
                    retVal.Changes[key]=diff;
                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = source[key].Layout._Name;
                        item.data = diff;
                        item.key = key;
                        retVal.Tree.push(item);
                    }
                } else {
                    if (target[key].ParentID == source[key].ParentID &&
                        target[key].Position == source[key].Position &&
                        target[key].JSON == source[key].JSON) {

                    } else {
                        let treenode:TreeNode = null;
                        if (options.CreateChangesTree) {
                            treenode = {};
                            treenode.label = source[key].Layout._Name;
                            treenode.key = key;
                            if (!options.DeepComparison) {
                                retVal.Tree.push(treenode);
                            }
                        }
                        let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.MODIFIED, GitDiffType.LAYOUTELEMENT, JSON.parse(source[key].JSON), JSON.parse(target[key].JSON));

                        if (options.DeepComparison) {
                            let children = LayoutHelper.FillChildren(diff.Source, diff.Modified, options, key);
                            if (options.CreateChangesTree) {
                                if (treenode) {
                                    treenode.children = children.Tree;
                                    retVal.Tree.push(treenode);
                                } else {
                                    
                                }
                            }
                            retVal.Changes = { ...retVal.Changes, ...children.Changes };
                        }
                        retVal.Changes[key]=diff;
                    }
                }
            })
            targetKeys.forEach((key) => {
                if (!source[key]) {
                    let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.ADD, GitDiffType.LAYOUTELEMENT, a.Layout, b.Layout);
                    retVal.Changes[key]=diff;
                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = target[key].Layout._Name;
                        item.data = diff;
                        item.key = key;
                        retVal.Tree.push(item);
                    }
                }
            })
        }
        //#endregion
        return retVal;
    }
    private static FillChildren(Source, Modified, options, prefix) {
        let retVal = {
            Tree: [],
            Changes: {}
        }
        
        if (Source != null && Modified != null) {
            let sourceKeys = Object.keys(Source);
            let modifiedKeys = Object.keys(Modified);
            sourceKeys.forEach((key) => {
                let fullkey = prefix + '||' + key;
                let sourceValue = Source[key];
                let modifiedValue = Modified[key];
                let isArray = Array.isArray(sourceValue) || Array.isArray(modifiedValue);
                let isObject = (typeof (sourceValue) == 'object' || typeof (modifiedValue) == 'object') && !isArray;
                if (isObject || isArray) {
                    sourceValue = JSON.stringify(sourceValue);
                    modifiedValue = JSON.stringify(modifiedValue);
                }

                if (Modified[key] == undefined) {
                    let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.REMOVE, GitDiffType.OTHER, Source[key], Modified[key]);
                    retVal.Changes[fullkey] = diff;
                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = key;
                        item.data = diff;
                        item.key = fullkey;
                        retVal.Tree.push(item);
                    }
                } else {
                    if (modifiedValue == sourceValue) {
                    } else {
                        let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.MODIFIED, GitDiffType.OTHER, Source[key], Modified[key]);
                        let treenode: TreeNode = {};
                        if (options.CreateChangesTree) {
                            treenode.label = key;
                            treenode.data = diff;
                            treenode.key = fullkey;
                            treenode.children = [];
                        }

                        if (options.DeepComparison) {
                            if (isArray) {

                                let SourceLength = Source[key].length;
                                let ModifiedLength = Modified[key].length;
                                let length = SourceLength >= ModifiedLength ? SourceLength : ModifiedLength;
                                for (var i = 0; i < length; i++) {

                                    let sourceObject = null;
                                    if (SourceLength > i) {
                                        sourceObject = Source[key][i];
                                    }
                                    let modifiedObject = null;
                                    if (ModifiedLength > i) {
                                        modifiedObject = Modified[key][i];
                                    }
                                    if (sourceObject != null && modifiedObject != null) {
                                        let objects = LayoutHelper.FillChildren(sourceObject, modifiedObject, options, fullkey + '||' + i);
                                        let subdiff = LayoutHelper.GenerateDiff(fullkey + '||' + i, GitDiffChangeType.MODIFIED, GitDiffType.OTHER, sourceObject, modifiedObject);
                                        retVal.Changes[fullkey + '||' + i] = subdiff;
                                        if (options.CreateChangesTree) {
                                            if (objects.Tree && objects.Tree.length > 0) {
                                                let arraytreenode: TreeNode = {};
                                                arraytreenode.label = i + '';
                                                arraytreenode.data = subdiff;
                                                arraytreenode.key = fullkey + '||' + i;
                                                arraytreenode.children = objects.Tree;
                                                if (treenode) {
                                                    treenode.children = [arraytreenode];
                                                }
                                            }
                                        }
                                        retVal.Changes = { ...retVal.Changes, ...objects.Changes };
                                    }
                                    if (sourceObject == null && modifiedObject != null) {
                                        let diff = LayoutHelper.GenerateDiff(i+'', GitDiffChangeType.ADD, GitDiffType.OTHER, null, modifiedObject);
                                        retVal.Changes[fullkey + '||' + i] = diff;
                                        if (options.CreateChangesTree) {
                                            if (treenode) {
                                                let item: TreeNode = {};
                                                item.label = i + '';;
                                                item.data = diff;
                                                item.key = fullkey + '||' + i;
                                                treenode.children = [...treenode.children, item];
                                            }
                                        }
                                    }
                                    if (sourceObject != null && modifiedObject == null) {
                                        let diff = LayoutHelper.GenerateDiff(i + '', GitDiffChangeType.REMOVE, GitDiffType.OTHER, sourceObject, null);
                                        retVal.Changes[fullkey + '||' + i] = diff;
                                        if (options.CreateChangesTree) {
                                            if (treenode) {
                                                let item: TreeNode = {};
                                                item.label = i + '';;
                                                item.data = diff;
                                                item.key = fullkey + '||' + i;
                                                treenode.children = [...treenode.children, item];
                                            }
                                        }
                                    }   
                                }
                                if (treenode && treenode.children && treenode.children.length > 0) {
                                    retVal.Tree.push(treenode);
                                }
                            } else if (isObject) {
                                let objects = LayoutHelper.FillChildren(diff.Source, diff.Modified, options, fullkey);
                                if (options.CreateChangesTree) {
                                    if (treenode) {
                                        treenode.children = objects.Tree;
                                        retVal.Tree.push(treenode);
                                    }
                                }
                                retVal.Changes = { ...retVal.Changes, ...objects.Changes };
                            } else {
                                if (options.CreateChangesTree) {
                                    retVal.Tree.push(treenode);
                                }
                            }
                        } else {
                            if (options.CreateChangesTree) {
                                retVal.Tree.push(treenode);
                            }
                        }
                        retVal.Changes[fullkey] = diff;
                    }
                }
            });
            modifiedKeys.forEach((key) => {
                let fullkey = prefix + '||' + key;
                let sourceValue = Source[key];
                let modifiedValue = Modified[key];
                let isArray = Array.isArray(sourceValue) || Array.isArray(modifiedValue);
                let isObject = (typeof (sourceValue) == 'object' || typeof (modifiedValue) == 'object') && !isArray;
                if (isObject || isArray) {
                    sourceValue = JSON.stringify(sourceValue);
                    modifiedValue = JSON.stringify(modifiedValue);
                }
                if (Source[key] == undefined) {
                    let diff = new GitComparisonDiff();
                    diff.ChangeType = GitDiffChangeType.ADD;
                    diff.Type = GitDiffType.OTHER;
                    diff.Key = key;
                    diff.Source = Source[key];
                    diff.SourceJSON = sourceValue;
                    diff.Modified = Modified[key];
                    diff.ModifiedJSON = modifiedValue;
                    retVal.Changes[fullkey] = diff;
                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = key;
                        item.data = diff;
                        item.key = fullkey;
                        retVal.Tree.push(item);
                    }
                }
            });
        }
        if (Source == null && Modified != null) {
            let diff = LayoutHelper.GenerateDiff(prefix, GitDiffChangeType.ADD, GitDiffType.OTHER, null, Modified);
            retVal.Changes[prefix] = diff;
            if (options.CreateChangesTree) {
                let item: TreeNode = {};
                item.label = prefix;
                item.data = diff;
                item.key = prefix;
                retVal.Tree.push(item);
            }
        }
        if (Source != null && Modified == null) {
            let diff = LayoutHelper.GenerateDiff(prefix, GitDiffChangeType.REMOVE, GitDiffType.OTHER, Source, null);
            retVal.Changes[prefix] = diff;
            if (options.CreateChangesTree) {
                let item: TreeNode = {};
                item.label = prefix;
                item.data = diff;
                item.key = prefix;
                retVal.Tree.push(item);
            }
        }
        
        return retVal;
    }
    public static CompareWorkflows(a: GitComparisonDocument, b: GitComparisonDocument, options: GitComparisonOptions) {
        let retVal = {
            Tree: [],
            Changes: {}
        }
        //#region Workflows
        if (a.Workflows && b.Workflows) {
            let source = a.Workflows;
            let sourceKeys = Object.keys(a.Workflows);
            let target = b.Workflows;
            let targetKeys = Object.keys(b.Workflows);
            sourceKeys.forEach((key) => {
                if (!target[key]) {
                    let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.REMOVE, GitDiffType.WORKFLOW, source[key].Data, null);
                    retVal.Changes[key] = diff;
                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = source[key].Data.Caption;
                        item.data = diff;
                        item.key = key;
                        retVal.Tree.push(item);
                    }

                    if (options.CreateChangesTree) {
                        let item: TreeNode = {};
                        item.label = source[key].Data.Caption;
                        item.data = diff;
                        item.key = key;
                        retVal.Tree.push(item);
                    }
                } else {
                    if (target[key].JSON == source[key].JSON) {

                    } else {
                        let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.MODIFIED, GitDiffType.WORKFLOW, source[key].Data, target[key].Data);
                        let modulesTree: TreeNode = {};
                        modulesTree.label = "Modules";
                        modulesTree.key = "Modules";
                        modulesTree.children = [];
                        let connectorsTree: TreeNode = {};
                        connectorsTree.label = "Connectors";
                        connectorsTree.key = "Connectors";
                        connectorsTree.children = [];
                        if (source[key].Data && source[key].Data.Data && source[key].Data.Data.Modules && source[key].Data.Data.Connectors &&
                            target[key].Data && target[key].Data.Data && target[key].Data.Data.Modules && target[key].Data.Data.Connectors) {
                            //#region Modules
                            let sourceModules = source[key].Data.Data.Modules;
                            let targetModules = target[key].Data.Data.Modules;
                            for (var i = 0; i < sourceModules.length; i++) {
                                let sm = sourceModules[i];
                                let item = targetModules.find((val) => val.SID == sm.SID);
                                if (item != null) {
                                    if (JSON.stringify(item) == JSON.stringify(sm)) {

                                    } else {
                                        if (options.DeepComparison) {
                                            let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.MODIFIED, GitDiffType.OTHER, sm, item);
                                            retVal.Changes[key + '||' + i] = subdiff;
                                            let objects = LayoutHelper.FillChildren(sm, item, options, key);
                                            if (objects.Changes) {
                                                retVal.Changes = { ...retVal.Changes, ...objects.Changes };
                                            }
                                            if (options.CreateChangesTree) {
                                                if (objects.Tree && objects.Tree.length > 0) {
                                                    let arraytreenode: TreeNode = {};
                                                    arraytreenode.label = i + '';
                                                    arraytreenode.data = subdiff;
                                                    arraytreenode.key = key + '||' + i;
                                                    arraytreenode.children = objects.Tree;
                                                    modulesTree.children.push(arraytreenode);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.REMOVE, GitDiffType.OTHER, sm, null);
                                    retVal.Changes[key + '||' + i] = subdiff;
                                    if (options.CreateChangesTree) {
                                        let arraytreenode: TreeNode = {};
                                        arraytreenode.label = i + '';
                                        arraytreenode.data = subdiff;
                                        arraytreenode.key = key + '||' + i;
                                        modulesTree.children.push(arraytreenode);
                                    }
                                }
                            }
                            for (var i = 0; i < targetModules.length; i++) {
                                let sm = targetModules[i];
                                let item = sourceModules.find((val) => val.SID == sm.SID);
                                if (item == null) {
                                    let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.ADD, GitDiffType.OTHER, null, sm);
                                    retVal.Changes[key + '||' + i] = subdiff;
                                    if (options.CreateChangesTree) {
                                        let arraytreenode: TreeNode = {};
                                        arraytreenode.label = i + '';
                                        arraytreenode.data = subdiff;
                                        arraytreenode.key = key + '||' + i;
                                        modulesTree.children.push(arraytreenode);
                                    }
                                }
                            }
                            //#endregion
                            //#region Connectors
                            let sourceConnectors = source[key].Data.Data.Connectors;
                            let targetConnectors = target[key].Data.Data.Connectors;
                            for (var i = 0; i < sourceConnectors.length; i++) {
                                let sm = sourceConnectors[i];
                                let item = targetConnectors.find((val) => val.SID == sm.SID);
                                if (item != null) {
                                    if (JSON.stringify(item) == JSON.stringify(sm)) {

                                    } else {
                                        if (options.DeepComparison) {
                                            let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.MODIFIED, GitDiffType.OTHER, sm, item);
                                            retVal.Changes[key + '||' + i] = subdiff;
                                            let objects = LayoutHelper.FillChildren(sm, item, options, key);
                                            if (objects.Changes) {
                                                retVal.Changes = { ...retVal.Changes, ...objects.Changes };
                                            }
                                            if (options.CreateChangesTree) {
                                                if (objects.Tree && objects.Tree.length > 0) {
                                                    let arraytreenode: TreeNode = {};
                                                    arraytreenode.label = i + '';
                                                    arraytreenode.data = subdiff;
                                                    arraytreenode.key = key + '||' + i;
                                                    arraytreenode.children = objects.Tree;
                                                    connectorsTree.children.push(arraytreenode);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.REMOVE, GitDiffType.OTHER, sm, null);
                                    retVal.Changes[key + '||' + i] = subdiff;
                                    if (options.CreateChangesTree) {
                                        let arraytreenode: TreeNode = {};
                                        arraytreenode.label = i + '';
                                        arraytreenode.data = subdiff;
                                        arraytreenode.key = key + '||' + i;
                                        connectorsTree.children.push(arraytreenode);
                                    }
                                }
                            }
                            for (var i = 0; i < targetConnectors.length; i++) {
                                let sm = targetConnectors[i];
                                let item = sourceConnectors.find((val) => val.SID == sm.SID);
                                if (item == null) {
                                    let subdiff = LayoutHelper.GenerateDiff(key + '||' + i, GitDiffChangeType.ADD, GitDiffType.OTHER, null, sm);
                                    retVal.Changes[key + '||' + i] = subdiff;
                                    if (options.CreateChangesTree) {
                                        let arraytreenode: TreeNode = {};
                                        arraytreenode.label = i + '';
                                        arraytreenode.data = subdiff;
                                        arraytreenode.key = key + '||' + i;
                                        connectorsTree.children.push(arraytreenode);
                                    }
                                }
                            }
                            //#endregion
                        }

                        retVal.Changes[key] = diff;
                        if (options.CreateChangesTree) {
                            
                            let item: TreeNode = {};
                            item.label = target[key].Data.Caption;
                            item.data = diff;
                            item.key = key;
                            item.children = [];
                            if (modulesTree.children.length > 0) {
                                item.children = [...item.children, modulesTree];
                            }
                            if (connectorsTree.children.length > 0) {
                                item.children = [...item.children, connectorsTree];
                            }
                            retVal.Tree.push(item);
                        }
                    }
                }
            })
            targetKeys.forEach((key) => {
                if (!source[key]) {
                    if (options.CreateChangesTree) {
                        let diff = LayoutHelper.GenerateDiff(key, GitDiffChangeType.ADD, GitDiffType.WORKFLOW, null, target[key].Data);
                        retVal.Changes[key] = diff;
                        if (options.CreateChangesTree) {
                            let item: TreeNode = {};
                            item.label = target[key].Data.Caption;
                            item.data = diff;
                            item.key = key;
                            retVal.Tree.push(item);
                        }
                    }
                }
            })
        }
        //#endregion
        return retVal;
    }


    private static GenerateDiff(Key: string, ChangeType: GitDiffChangeType, Type: GitDiffType, Source, Modified) {
        let diff = new GitComparisonDiff();
        diff.ChangeType = ChangeType;
        diff.Type = Type;
        diff.Key = Key;
        diff.Source = Source;
        diff.SourceJSON = Source?JSON.stringify(Source):null;
        diff.Modified = Modified;
        diff.ModifiedJSON = Modified ? JSON.stringify(Modified) : null;
        return diff;
    }
    private static CreateFlatList(Layout, Result, Parent, Position) {
        let copy = JSON.parse(JSON.stringify(Layout));
        delete copy.Elements;
        delete copy.Workflows;
        let item = new GitComparisonElement();
        item.Parent = Parent;
        item.ParentID = Parent ? Parent.ID : null;
        item.Layout = Layout;
        item.JSON = JSON.stringify(copy);
        item.Position = Position;
        item.ElementType = Layout.ElementType;
        Result[Layout.ID] = item;
        if (Layout.Elements && Layout.Elements.length > 0) {
            for (let i = 0; i < Layout.Elements.length; i++) {
                let element = Layout.Elements[i];
                this.CreateFlatList(element, Result, Layout, i);
            }
        }
    }
    public static ParseLayout(element): GitComparisonDocument {
        let retVal: GitComparisonDocument = {
            Layout: element,
            Workflows: {},
            Compare: {}
        };
        this.CreateFlatList(retVal.Layout, retVal.Compare, null, 0);
        if (retVal.Layout && retVal.Layout['Workflows']) {
            retVal.Layout['Workflows'].forEach((workflow) => {
                let item = new GitComparisonWorkflow();
                item.Data = workflow;
                item.JSON = JSON.stringify(workflow);
                retVal.Workflows[workflow.ID] = item;
            });
        }
        return retVal;
    }
    //#endregion
}
