import { UUID } from 'angular2-uuid';
import { Base, JsonIgnore } from '../../../../models/base.model';
import { WhereSelects } from '../querydef.model';
import { FilterSettingsMultidimensional } from './FilterSettingsMultidimensional.model';

export class FilterMultidimensional extends Base {

    Caption: any;

    Hierarchy: any;

    Dimension: any;

    Formula: string;

    Defaults: WhereSelects[] = [];

    FilterSettings: FilterSettingsMultidimensional;
    DatasourceID: UUID;
    Levels: UUID[];

    @JsonIgnore
    DefaultSelectsText = '[0]';
}
