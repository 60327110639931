import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export class RTrim extends AFormula {
    static StaticID: string = '660d456f-3df4-40d2-a786-c9f7ea34f9ac';

    ID(): string {
        return RTrim.StaticID;
    }

    Name(): string {
        return 'RTrim';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Kuerzen einer Zeichenkette an der rechten Seite');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 15;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '('
            + TranslateHelper.TranslatorInstance.instant('@@Text') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Zeichen') + ')';
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        retVal.Value = '';
        if (args && args.length > 0) {
            var text = '';
            var trimChar = ' ';
            if (typeof args[0].Value !== 'undefined' && args[0].Value != null) {
                text = String(args[0].Value);
            }
            if (args.length > 1 && typeof args[1].Value !== 'undefined' && args[1].Value != null) {
                var tc = String(args[1].Value);
                if (tc && tc.length > 0) {
                    trimChar = tc[0];
                }
            }
            var length = text.length;
            while (length > 0 && text[length - 1] == trimChar) {
                length--;
            }
            retVal.Value = text.substr(0, length);
        }
        return retVal;
    }
}