import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { EnumHelper } from '../../helpers/enum.helper';
import { InjectorHelper } from '../../helpers/injector.helper';
import { WorkflowParameterType } from '../../models/enums/workflowparametertype.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { ConnectorService } from '../../services/connector.service';
import { NavigationService } from '../../services/navigation.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { DataModelRegistry } from '../datamodel/datamodel.settings';
import { DataObjectSettings } from '../datamodel/dataobject/dataobject.settings';
import { DataSourceSettings } from '../datamodel/datasource/datasource.settings';
import { ContainerEnumSettings } from '../datamodel/enum/containerenum.settings';
import { ConnectorWorkflowEditDialog } from './connector.workflow.edit.dialog';
import { ConnectorScriptsSettings } from './scripts/connector.scripts.settings';
import { ConnectorWorkflowMappingSettings } from './wfmapping/connector.wfmapping.settings';
import { NotificationHelper } from "../../helpers/notification.helper";
import { LayoutService } from "../../services/layout.service";
import { StandardRequestBase } from "../../services/request-base";

@Component({
    selector: 'connector-overview',
    templateUrl: '../base.list.settings.html'
})
export class ConnectorOverviewSettings extends BaseListSettings {

    static ConnectorNode: BehaviorSubject<any> = new BehaviorSubject(null);

    static GetSettingsEntry() {
        return {
            Caption: '@@Connector',
            ID: 'connector',
            Icon: 'dataset',
            Index: 20,
            Parent: 'data',
            // Security: {
            //    Name: 'evidanza.App.Shared.Security.LayoutRight',
            //    Value: 4
            // },
            Children: [],
            UpdateFunction: ConnectorOverviewSettings.UpdateNodes
        };
    }

    private static UpdateNodes(nav) {
        ConnectorOverviewSettings.ConnectorNode.next(nav);
        if (nav) {
            nav.Children = [];
            const cs = InjectorHelper.InjectorInstance.get<ConnectorService>(ConnectorService);
            cs.GetConnectorInfos().subscribe(conList => {
                let index = 0;
                const list = [];
                list.push({
                    Caption: '@@Uebersicht',
                    ID: 'connector-overview',
                    Icon: 'menu',
                    // Security: {
                    //    Name: 'evidanza.App.Shared.Security.LayoutRight',
                    //    Value: 4
                    // },
                    Index: index++,
                    Content: ConnectorOverviewSettings
                });
                if (conList) {
                    conList.forEach(ns => {
                        ns.IsConnector = true;
                        const node = ConnectorOverviewSettings.GetNode(ns, index++);
                        list.push(node);
                    });
                }
                nav.Children = list;
                NavigationService.Refresh.next(null);
            });
        }
        
    }

    private static GetNode(ns, index) {
        const containerNode = {
            Caption: '@@DataModel',
            ID: 'dataModel-container',
            Icon: 'description',
            // Security: {
            //    Name: 'evidanza.App.Shared.Security.LayoutRight',
            //    Value: 4
            // },
            Index: 0,
            Children: [
                {
                    Caption: '@@Datenquellen',
                    ID: 'connector-datasources',
                    Icon: 'storage',
                    // Security: {
                    //    Name: 'evidanza.App.Shared.Security.LayoutRight',
                    //    Value: 4
                    // },
                    Index: 0,
                    Content: DataSourceSettings,
                    InitArgs: ns
                },
                {
                    Caption: '@@Datenstruktur',
                    ID: 'connector-datastructure',
                    // Security: {
                    //    Name: 'evidanza.App.Shared.Security.DataRight',
                    //    Value: 8
                    // },
                    Icon: 'description',
                    Index: 1,
                    Children: [
                        {
                            Caption: '@@Datenobjekte',
                            ID: 'connector-dataobjects',
                            Icon: 'assignment',
                            Index: 0,
                            // Security: {
                            //    Name: 'evidanza.App.Shared.Security.DataRight',
                            //    Value: 4096
                            // },
                            Content: DataObjectSettings,
                            InitArgs: ns
                        },
                        {
                            Caption: '@@Enums',
                            ID: 'connector-enums',
                            Icon: 'list',
                            Index: 1,
                            // Security: {
                            //    Name: 'evidanza.App.Shared.Security.DataRight',
                            //    Value: 8192
                            // },
                            Content: ContainerEnumSettings,
                            InitArgs: ns
                        }
                    ]
                }
            ]
        };
        const retVal = {
            Caption: ns.Caption,
            ID: ns.SID,
            Icon: 'radio_button_unchecked',
            Index: index,
            // Security: {
            //    Name: 'evidanza.App.Shared.Security.LayoutRight',
            //    Value: 4
            // },
            Children: [
                containerNode,
                {
                    Caption: '@@Scripts',
                    ID: 'connector-scripts',
                    Icon: 'code',
                    // Security: {
                    //    Name: 'evidanza.App.Shared.Security.LayoutRight',
                    //    Value: 4
                    // },
                    Index: 1,
                    Content: ConnectorScriptsSettings,
                    InitArgs: ns
                },
                {
                    Caption: '@@Workflow-Mapping',
                    ID: 'connector-workflow-mapping',
                    Icon: 'device_hub',
                    // Security: {
                    //    Name: 'evidanza.App.Shared.Security.LayoutRight',
                    //    Value: 4
                    // },
                    Index: 2,
                    Content: ConnectorWorkflowMappingSettings,
                    InitArgs: ns
                }
            ]
        };
        DataModelRegistry.Registry.forEach((child, i) => {
            const regEntry = ConnectorOverviewSettings.GetRegEntry(ns, child);
            regEntry.Index = i + 2;
            containerNode.Children.push(regEntry);
        });
        return retVal;
    }

    private static GetRegEntry(model, entry) {
        const retVal = Object.assign({}, entry);
        retVal.ID = entry.ID;
        if (entry.Children) {
            retVal.Children = [];
            entry.Children.forEach((child, i) => {
                const regEntry = ConnectorOverviewSettings.GetRegEntry(model, child);
                regEntry.Index = i;
                retVal.Children.push(regEntry);
            });
        } else {
            retVal.InitArgs = model;
        }
        return retVal;
    }
    constructor(protected cfr: ComponentFactoryResolver, cdRef: ChangeDetectorRef, private service: ConnectorService, private standardService: StandardRequestBase) {
        super(cfr, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ConnectorOverviewDetail;
    }

    loadList(handler) {
        this.service.GetConnectorInfos().subscribe(result => {
            const list = [];
            if (result) {
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
            }
            handler(list);
        });
    }

    loadData(data) {
        this.service.GetConnector(data).subscribe(con => {
            this.setSelectedItem(con);
        });
    }

    getNewItem() {
        return {
            Variables: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        const text = '@@Are you sure you want to delete connector \'{0}\' ? ';
        retVal.Question = new TranslateFormatText(text);
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Connector \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Connector delete');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Connector \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Connector save');
        return retVal;
    }

    delete(data, handler) {
        // momentan nichts tun
        this.standardService.executeDelete('api/selfservice/connector', 'DeleteConnector', 'id=' + data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        if (item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.SaveConnector(item).subscribe(x => {
            if (x) {
                handler(x, x.SID, x.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        const node = ConnectorOverviewSettings.ConnectorNode.getValue();
        if (node && node.Children) {
            const child = ConnectorOverviewSettings.GetNode(item, node.Children.length);
            node.Children.push(child);
        }
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'connector-overview-detail',
    templateUrl: './connector.overview.settings.html',
    styleUrls: ['./connector.overview.settings.css']
})
export class ConnectorOverviewDetail extends BaseListDetail {
    UsageList = [
        { Caption: '@@DocumentStoreRead', Value: 16 },
        { Caption: '@@DocumentStoreWrite', Value: 32 },
        { Caption: '@@DocumentStoreBulk', Value: 64 },
        { Caption: '@@DataSourceRead', Value: 256 },
        { Caption: '@@DataSourceWrite', Value: 512 },
        { Caption: '@@DataSourceDbConnection', Value: 1024 },
        { Caption: '@@DataSourceReadWithMethodType', Value: 2048 },
        { Caption: '@@RelationalRead', Value: 4096 },
        { Caption: '@@RelationalWrite', Value: 8192 },
        { Caption: '@@RelationalBulk', Value: 16384 },
        { Caption: '@@RelationalCreateStructure', Value: 32768 },
        { Caption: '@@RelationalPackages', Value: 65536 },
        { Caption: '@@RelationalStoredProcedures', Value: 131072 },
        { Caption: '@@RelationalFunctions', Value: 262144 },
        { Caption: '@@ObjectRead', Value: 1048576 },
        { Caption: '@@ObjectWrite', Value: 2097152 },
    ];
    UsageValue = [];
    DataTypes = [];

    constructor() {
        super();
        this.DataTypes = EnumHelper.GetDropdownValues(WorkflowParameterType);
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        const usageValue = [];
        if (item && typeof item.Usage === 'number') {
            this.UsageList.forEach(x => {
                if ((x.Value & item.Usage) == x.Value) {
                    usageValue.push(x.Value);
                }
            });
        }
        this.UsageValue = usageValue;
    }

    onUsageChanged() {
        if (this.SelectedItem) {
            let usage = 0;
            this.UsageValue.forEach(x => {
                usage = usage | x;
            });
            this.SelectedItem.Usage = usage;
            this.OnItemChanged();
        }
    }

    addVariable() {
        if (this.SelectedItem) {
            if (this.SelectedItem.Variables) {
                this.SelectedItem.Variables.push({
                    Type: WorkflowParameterType.String,
                    IsExtern: true
                });
            } else {
                this.SelectedItem.Variables = [{
                    Type: WorkflowParameterType.String,
                    IsExtern: true
                }];
            }
            this.OnItemChanged();
        }
    }

    deleteVariable(i) {
        if (this.SelectedItem && this.SelectedItem.Variables) {
            this.SelectedItem.Variables.splice(i, 1);
            this.OnItemChanged();
        }
    }

    editBeforeExecute() {
        const sel = this.SelectedItem;
        if (sel) {
            const wf = sel.BeforeExecute ?? new WorkflowSaveObject();
            ConnectorWorkflowEditDialog.ShowDialog(sel, wf, (x) => {
                sel.BeforeExecute = x;
                this.OnItemChanged();
            });
        }
    }

    resetBeforeExecute() {
        if (this.SelectedItem) {
            this.SelectedItem.BeforeExecute = null;
            this.OnItemChanged();
        }
    }

    editAfterExecute() {
        const sel = this.SelectedItem;
        if (sel) {
            const wf = sel.AfterExecute ?? new WorkflowSaveObject();
            ConnectorWorkflowEditDialog.ShowDialog(sel, wf, (x) => {
                sel.AfterExecute = x;
                this.OnItemChanged();
            });
        }
    }

    resetAfterExecute() {
        if (this.SelectedItem) {
            this.SelectedItem.AfterExecute = null;
            this.OnItemChanged();
        }
    }
}
