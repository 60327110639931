import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { SettingsService } from "../../../../../services/settings.service";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'amazondrive-panel',
    templateUrl: './amazondrive.panel.html',
    styleUrls: ['./amazondrive.panel.css']
})
export class AmazonDrivePanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = AmazonDrivePanel;
    }

    get RedirectUrl(): string {
        var ret = SettingsService.API_BASE_URL.getValue() + 'config/api/media/oauth/authorizeamazon';
        this.MediaSource.RedirectUrl = ret;
        this.MediaSource.Private = true; 
        return ret;
    }

    set RedirectUrl(val) {
        if (val != this.MediaSource.RedirectUrl) {
            this.MediaSource.RedirectUrl = val;
        }
    }

    authenticate(): void {
        //let authstring = 'https://www.amazon.com/ap/oa?client_id=' + this.MediaSource.ClientId + '&scope=clouddrive:read_all clouddrive:write&response_type=code&redirect_uri=' + this.MediaSource.RedirectUrl + '&state=' + this.MediaSource.SID;
        let authstring = 'https://www.amazon.com/ap/oa?client_id=' + this.MediaSource.ClientId + '&scope=profile&response_type=code&redirect_uri=' + this.MediaSource.RedirectUrl + '&state=' + this.MediaSource.SID;
        window.open(authstring, '_blank'); 
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: AmazonDrivePanel,
            MediaType: 'E44BA8E2-1955-4E0C-BFCF-C0AE5B20618D'.toLowerCase()
        };
    }
}