import { ComponentPortal } from '@angular/cdk/portal';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';
import { IBaseComponent } from '../base.component';
import { ButtonControl } from '../button/button.control';

@Component({
    selector: 'evi-upload',
    templateUrl: './upload.control.html',
    styleUrls: ['./upload.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadControl extends IBaseComponent {
    static Type: any = 'upload';
    static Default = { Placeholder: 'Please type ...', Type: 'upload',
        Layout: {
            Caption: 'Choose File',
            ButtonType: 'mat-button',
            _Editable: true,
            Width: { Type: 0, Value:150},
            Height: {Type:0,Value:40}
            
        }};
    Types = {
        '.csv': {
            Caption: 'CSV',
            Value: '.csv',
            Type: 'text/csv'
        },
        'application/vnd.ms-excel': {
            Caption: 'Excel 97-2003',
            Value: 'application/vnd.ms-excel',
            Type: 'application/vnd.ms-excel'
        },
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
            Caption: 'Excel 2007+',
            Value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        'text/plain': {
            Caption: 'Text',
            Value: 'text/plain',
            Type: 'text/plain'
        },
        'image/*': {
            Caption: 'Image',
            Value: 'image/*',
            Type: 'image/*'
        },
        'text/html': {
            Caption: 'Html',
            Value: 'text/html',
            Type: 'text/html'
        },
        'video/*': {
            Caption: 'Video',
            Value: 'video/*',
            Type: 'video/*'
        },
        'audio/*': {
            Caption: 'Audio',
            Value: 'audio/*',
            Type: 'audio/*'
        },
        '.pdf': {
            Caption: 'Pdf',
            Value: '.pdf',
            Type: 'application/pdf'
        }
    };
    PathValue;
    get Path() {
        if (this.PathValue != null) {
            return this.PathValue;
        }
        if (this.LayoutElementValue.Path) {
            return this.LayoutElementValue.Path;
        }
        return '';
    }
    set Path(val) {
        this.PathValue = val;
    }

    CaptionValue;
    get Caption() {
        if (this.CaptionValue != null) {
            return this.CaptionValue;
        }
        return this.LayoutElementValue.Caption;
    }
    set Caption(val) {
        this.CaptionValue = val;
        if (this.button) {
            this.button.Caption = val;
        }
        this.cdRef.detectChanges();
    }

    IconValue;
    get Icon() {
        if (this.IconValue != null) {
            return this.IconValue;
        }
        return this.LayoutElementValue.Icon;
    }
    set Icon(val) {
        this.IconValue = val;
        if (this.button) {
            this.button.Icon = val;
        }
        this.cdRef.detectChanges();
    }

    get FileTypes() {
        if (this.LayoutElementValue && this.LayoutElementValue.FileTypes && this.LayoutElementValue.FileTypes.length > 0) {
            return this.LayoutElementValue.FileTypes.join(', ');
        }
        return null;
    }

    ButtonLayout;
    @ViewChild('button') button: ButtonControl;

    constructor(private mediaService: MediaService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Text'));
        this.PropertyList.push(new ElementProperty('Icon', 'string', '@@Icon'));
        this.PropertyList.push(new ElementProperty('Path', 'string', '@@Path'));
        this.EventList.push('uploadcomplete');
        this.EventList.push('uploadstarting');
        this.EventList.push('uploadstarted');
        this.EventList.push('uploadfailed');
    }
    UseFilePicker = false;
    ControlInitialized() {
        this.SetButtonLayout();
        if (window['showOpenFilePicker']) {
            this.UseFilePicker = true;
        }
    }

    private SetButtonLayout() {
        if (this.LayoutElementValue) {
            this.ButtonLayout = plainToClass(LayoutElement, JSON.parse(JSON.stringify(this.LayoutElementValue)));
        }
    }
    FileDrop(event) {
        let ev = {
            target: {
                files: event
            }
        }
        this.UploadPicture(ev)
    }
    onLayoutElementSet() {
        this.SetButtonLayout();
    }
    onLayoutElementChanged() {
        this.SetButtonLayout();
    }
    async OpenFilePicker(event) {
        if(!this.Editable || this.Disabled) {
            return this.PreventDefault(event);
        }
        if (this.UseFilePicker) {
            const pickerOpts = {
                types: [],
                excludeAcceptAllOption: this.LayoutElementValue.HideAllFiles ? true : false,
                multiple: this.LayoutElementValue.Multiple ? true : false
            };
            if (this.LayoutElementValue.FileTypes) {
                pickerOpts.types = [];

                this.LayoutElementValue.FileTypes.forEach((ft) => {
                    let lft = this.Types[ft];
                    let item = {
                        description: ft,
                        accept: {}
                    };
                    if (lft) {
                        item.description = lft.Caption;
                        item.accept[lft.Type] = [];
                    }
                    pickerOpts.types.push(item);

                })
            }

            let fileHandles = await window['showOpenFilePicker'](pickerOpts);
            if (fileHandles && fileHandles.length > 0) {
                let event = {
                    target: {
                        files: []
                    }
                }
                if (this.LayoutElementValue.Multiple) {
                    let count = fileHandles.length;
                    let i = 0;
                    fileHandles.forEach((fileHandle) => {
                        fileHandle.getFile().then((file) => {
                            event.target.files.push(file);
                            i += 1;
                            if (i == count) {
                                this.UploadPicture(event);
                            }
                        })
                    })
                } else {
                    fileHandles[0].getFile().then((file) => {
                        event.target.files.push(file);
                        this.UploadPicture(event)
                    })
                }
            }
            //if (fileHandle.kind === 'file') {
            //    // run file code

            //} else if (fileHandle.kind === 'directory') {
            //    // run directory code
            //}
        }
    }
    UploadPicture(event) {
        if(!this.Editable || this.Disabled) {
            return this.PreventDefault(event);
        }

        if (this.LayoutElementValue && (this.LayoutElementValue.MediaSource || this.LayoutElementValue.UseForBlob)) {
            let selectedFile = null;
            if (this.LayoutElementValue.Multiple) {
                selectedFile = event.target.files;
            } else {
                selectedFile = event.target.files[0];
            }
            this.triggerEvent('uploadstarting', selectedFile);
            if (this.LayoutElementValue.UseForBlob) {
                this.triggerEvent('uploadstarted', selectedFile);
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.DataSource = reader.result;
                    selectedFile.data = reader.result;
                    this.triggerEvent('uploadcomplete', selectedFile);
                }
                if (this.LayoutElementValue.DataType == 'bin') {
                    reader.readAsBinaryString(selectedFile);
                } else {
                    reader.readAsDataURL(selectedFile);
                }
            } else {
                if (this.LayoutElementValue.CheckForExisting) {
                    this.mediaService.ListObjects(this.LayoutElementValue.MediaSource, this.Path).subscribe(r => {
                        if (r && r.length > 0) {
                            const found = [];
                            if (this.LayoutElementValue.Multiple) {
                                Array.from(selectedFile).forEach((file: File) => {
                                    const value = r.find((v) => v.Name == file.name);
                                    if (value != null) {
                                        found.push(file.name);
                                    }
                                });
                            } else {
                                const value = r.find((v) => v.Name == selectedFile.name);
                                if (value != null) {
                                    found.push(selectedFile.name);
                                }
                            }

                            if (found && found.length > 0) {
                                let text = '';
                                if (found.length > 1) {
                                    text = '@@Die Dateien mit den Namen \'{0}\' existieren bereits. Wollen Sie die Dateien ueberschreiben?';
                                } else {
                                    text = '@@Datei mit Name \'{0}\' existiert bereits. Wollen Sie die Datei ueberschreiben?';
                                }

                                const tft = new TranslateFormatText(text);
                                const vari = found.join(', ');
                                tft.FormatParams.push(vari);
                                MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Datei ueberschreiben'), MessageBoxButtons.YesNo,
                                    MessageBoxIcon.Question).then((res) => {
                                        if (res === MessageBoxResult.Yes) {
                                            this.uploadSpecific(selectedFile);
                                        }
                                    });
                            } else {
                                this.uploadSpecific(selectedFile);
                            }
                        } else {
                            this.uploadSpecific(selectedFile);
                        }
                    });
                } else {
                    this.uploadFile(selectedFile);
                }
            }

        }
    }

    CheckFileSize(selectedFile): boolean {
        let errors = [];
        if (this.LayoutElementValue.MaxFileSize) {
            if (this.LayoutElementValue.Multiple) {
                Array.from(selectedFile).forEach((file: File) => {
                    if (file.size > this.LayoutElementValue.MaxFileSize) {
                        errors.push(this.CopyFile(file));
                    }
                });
            } else {
                if (selectedFile.size > this.LayoutElementValue.MaxFileSize) {
                    errors.push(this.CopyFile(selectedFile));
                }
            }
        }
        if (errors.length > 0) {
            this.triggerEvent('uploadfailed', errors);
            console.log(errors);
        }
        return errors.length == 0;
    }
    CopyFile(file:File):any {
        let item: any = {};
        item.name = file.name;
        item.size = file.size;
        item.type = file.type;
        return item;
    }
    uploadFile(selectedFile) {
        if (selectedFile && this.LayoutElementValue && this.LayoutElementValue.MediaSource) {
            const uploadData = new FormData();

            if (this.CheckFileSize(selectedFile)) {
                if (this.LayoutElementValue.Multiple) {
                    Array.from(selectedFile).forEach((file: File) => {
                        uploadData.append(this.Path + file.name, file, this.Path + file.name);
                    });
                } else {
                    uploadData.append(this.Path + selectedFile.name, selectedFile, this.Path + selectedFile.name);
                }
                this.triggerEvent('uploadstarted', selectedFile);
                this.mediaService.UploadFile(this.LayoutElementValue.MediaSource, uploadData).subscribe((result) => {
                    if (result.type === HttpEventType.Response) {
                        this.triggerEvent('uploadcomplete', result.body);
                    }
                });
            }
        }
    }
    uploadSpecific(selectedFile) {
        if (selectedFile && this.LayoutElementValue && this.LayoutElementValue.MediaSource) {
            const uploadData = new FormData();
            if (this.CheckFileSize(selectedFile)) {
                if (this.LayoutElementValue.Multiple) {
                    Array.from(selectedFile).forEach((file: File) => {
                        uploadData.append(this.Path + file.name, file, this.Path + file.name);
                    });
                } else {
                    uploadData.append(this.Path + selectedFile.name, selectedFile, this.Path + selectedFile.name);
                }
                this.triggerEvent('uploadstarted', selectedFile);
                this.mediaService.UploadSpecific(this.LayoutElementValue.MediaSource, uploadData).subscribe(e => {
                    if (e.type === HttpEventType.Response) {
                        this.triggerEvent('uploadcomplete', e.body);
                    }
                });
            }
        }
    }
    GetZIndex() {
        const retVal = {};
        if (this.LayoutElementValue && typeof this.LayoutElementValue.ZIndex === 'number') {
            retVal['z-index'] = this.LayoutElementValue.ZIndex + 1;
        }
        return retVal;
    }

    SetProperties() {
        return super.SetProperties();
    }

    // prevents the browsers default behaviour and
    // stops an event from bubbling or propagating up the DOM tree
    PreventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }
}
export class UploadPanel extends BasePanel {
    static override SIDS = ['e451c7d3-3b46-4996-b3e6-0331d524d39f']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'e451c7d3-3b46-4996-b3e6-0331d524d39f',
            ID: 'upload',
            Caption: '@@Upload',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'upload';
            }
        })
        // PROPERTIES.push({
        //     ID: "UseForBlob",
        //     Parent: "upload",
        //     Content: new ComponentPortal(CheckBoxThemeControl),
        //     InitArgs: {
        //         Caption: "@@UseForBlob"
        //     },
        //     Row: 1
        // })
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "upload",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Row: 3,
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
            let FileTypes = [{
                Caption: 'CSV',
                Value: '.csv'
            }, {
                Caption: 'Excel 97-2003',
                Value: 'application/vnd.ms-excel'
            }, {
                Caption: 'Excel 2007+',
                Value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }, {
                Caption: 'Text',
                Value: 'text/plain'
            }, {
                Caption: 'Image',
                Value: 'image/*'
            }, {
                Caption: 'Html',
                Value: 'text/html'
            }, {
                Caption: 'Video',
                Value: 'video/*'
            }, {
                Caption: 'Audio',
                Value: 'audio/*'
            }, {
                Caption: 'Pdf',
                Value: '.pdf'
                }]
            PROPERTIES.push({
                ID: "FileTypes",
                Parent: "upload",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@FileTypes",
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: true,
                    ItemsSource: FileTypes,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'Value'
                }
            })
            PROPERTIES.push({
                ID: "HideAllFiles",
                Parent: "upload",
                Content: new ComponentPortal(CheckBoxThemeControl),
                InitArgs: {
                    Caption: "@@HideAllFiles"
                }
            })
            PROPERTIES.push({
                ID: "MaxFileSize",
                Parent: "upload",
                Content: new ComponentPortal(TextboxThemeControl),
                Label: "@@MaxFileSize",
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    InputType: "number"
                }
            });
            PROPERTIES.push({
                ID: "Path",
                Parent: "upload",
                Content: new ComponentPortal(TextboxThemeControl),
                Label: "@@Path",
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                }
            });
            PROPERTIES.push({
                ID: "CheckForExisting",
                Parent: "upload",
                Content: new ComponentPortal(CheckBoxThemeControl),
                InitArgs: {
                    Caption: "@@Auf bestehende Datei pruefen"
                },
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                }
            })
            PROPERTIES.push({
                ID: "Multiple",
                Parent: "upload",
                Content: new ComponentPortal(CheckBoxThemeControl),
                InitArgs: {
                    Caption: "@@Multiple"
                }
            })
            let DataTypes = [
                {
                    Caption: "@@Binary",
                    Type: "bin"
                },
                {
                    Caption: "@@Url",
                    Type: "url"
                }
            ];
            PROPERTIES.push({
                ID: "DataType",
                Parent: "upload",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@DataType",
                CheckVisibility: (item) => {
                    return item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: true,
                    ItemsSource: DataTypes,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'Type'
                }
            })
        });

       
    }
}