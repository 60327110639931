import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class QueryEngineService extends RequestBase {
    BasePath = 'api/queryengine/relational';

    ExecuteQuery(query): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteQuery', query, this.options);
    }

    GetRelContainer(dataModel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetRelContainer?dataModel=' + dataModel, this.options);
    }

    GetRelationsForContainer(dataModelID, container): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetRelationsForContainer?dataModelID=' + dataModelID +
            '&container=' + container, this.options);
    }
}
