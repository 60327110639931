import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowDialogContent, DataCheck } from '../../workflow.dialog';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';
import { serialize, deserialize } from 'class-transformer';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { Component } from '@angular/core';

@Component({
    selector: 'get-language-wf-settings',
    templateUrl: './getCurrentLanguage.settings.html'
})
export class GetCurrentLanguageSettings extends WorkflowDialogContent {
    Data = new GetCurrentLanguageSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getLanguageWFModule';
        reg.Caption = '@@Aktuelle Sprache auslesen';
        reg.GroupID = 'generateData';
        reg.Index = 80;
        reg.SettingsControl = GetCurrentLanguageSettings;
        reg.SettingsTypeHelper = new GetCurrentLanguageSettingsDataHelper();
        reg.Executer = GetLanguageModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetCurrentLanguageSettingsData, json);
        }
    }


    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (!this.Data.StatusKey && !this.Data.UseForState) {
            retVal.Error = '@@Bitte vergeben Sie einen Status-Key oder setzen Sie die Sprache als aktuellen Parameter';
            retVal.IsCorrect = false;
        }
        return retVal;
    }

    getResult() {
        return this.Data;
    }
}

export class GetCurrentLanguageSettingsData extends AServiceWorkflowData {
    StatusKey: string;
    UseForState = false;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GetCurrentLanguage.GetCurrentLanguageSettingsData';
    }
}

export class GetCurrentLanguageSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetCurrentLanguageSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class GetLanguageModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const actLCID = parseInt(TranslateHelper.TranslatorInstance.currentLang, 10);
            if (status.ActualSettings.StatusKey) {
                status.Context.set(status.ActualSettings.StatusKey, actLCID);
            }
            if (status.ActualSettings.UseForState === true) {
                status.ActualParameter = actLCID;
            }
            return 0;
        } else {
            status.Logger.logError('GetLanguage module: No settings found.');
        }
        return super.execute(status);
    }
}
