import { Type } from "class-transformer";
import { UUID } from "angular2-uuid";

// @dynamic
export class MeasureReportParameterReferencing {
  @Type(() => ReportParameterReferencingObject)
  ParameterObjects: ReportParameterReferencingObject[] = [];
}

export class ReportParameterReferencingObject {
  Dimension: UUID;
  SelectedReportParameter: UUID[] = [];
}
