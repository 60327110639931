import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class AgentService extends RequestBase {
    BasePath = 'api/agents';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    Get(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Get', filter, this.options);
    }

    GetAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAll', this.options);
    }

    GetByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetByID?id=' + id, this.options);
    }

    Save(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save', obj, this.options);
    }

    Delete(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }

    GetConfigFile(id): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetConfigFile?id=' + id, this.options);
    }

    GetLogs(id): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetLogs?id=' + id, this.options);
    }
}