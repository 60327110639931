import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { WorkflowModuleExecuter, WorkflowSaveObject, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowService } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowEngine } from '../../workflow.engine';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { TemplateParameterValue, TemplateSettingsData, TemplateSettingsDataHelper } from './template.settings';

@Component({
    selector: 'wf-template-advanced-settings',
    templateUrl: './template.advanced.settings.html'
})
export class TemplateAdvancedSettings extends FormulaWorkflowDialogContent {
    Data = new TemplateSettingsData();
    ParameterValues = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = TemplateSettingsData.ModuleID;
        reg.Caption = '@@Template';
        reg.GroupID = '-1';
        reg.SettingsControl = TemplateAdvancedSettings;
        reg.SettingsTypeHelper = new TemplateSettingsDataHelper();
        reg.Executer = TemplateAdvancedModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private wfService: WorkflowService) {
        super();
    }

    ngOnInit(): void {
        if (this.Data) {
            this.wfService.LoadWorkflow(this.Data.TemplateID).subscribe((wf) => {
                const paramList = [];
                const paraValues = this.Data.ParameterValues || [];
                if (wf && wf.Parameters && wf.Parameters.length > 0) {
                    wf.Parameters.forEach(p => {
                        const par: any = {
                            Name: p.Name,
                            Required: p.Required,
                            Value: null
                        };
                        paraValues.some(pv => {
                            if (pv.ParameterName === p.Name) {
                                par.Value = pv.Value;
                                return true;
                            }
                            return false;
                        });
                        paramList.push(par);
                    });
                }
                this.ParameterValues = paramList;
            });
        }
    }

    initialize(data) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(TemplateSettingsData, json);
        }
    }

    getResult(): any {
        if (this.Data) {
            const paramList = [];
            this.ParameterValues.forEach(pv => {
                if (typeof pv.Value === 'string') {
                    const tpv = new TemplateParameterValue();
                    tpv.ParameterName = pv.Name;
                    tpv.Value = pv.Value;
                    paramList.push(tpv);
                }
            });
            this.Data.ParameterValues = paramList;
        }
        return this.Data;
    }
}

export class TemplateAdvancedModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const wfService = InjectorHelper.InjectorInstance.get<WorkflowService>(WorkflowService);
            const wf: WorkflowSaveObject = await wfService.LoadWorkflow(status.ActualSettings.TemplateID).toPromise();
            if (wf) {
                if (wf.Modules) {
                    const modules = [];
                    wf.Modules.forEach(x => {
                        modules.push(WorkflowSaveObject.DeserializeModuleSettings(x));
                    });
                    wf.Modules = modules;
                }
                if (wf.Parameters && wf.Parameters.length > 0) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    const paramValues = status.ActualSettings.ParameterValues || [];
                    if (wf.Parameters.some(wfp => {
                        let value = wfp.Default;
                        paramValues.some(pv => {
                            if (pv.ParameterName === wfp.Name && typeof pv.Value === 'string') {
                                value = calculator.CalcFormula(pv.Value);
                                return true;
                            }
                            return false;
                        });
                        if (typeof value !== 'undefined' && value != null) {
                            status.Context.set(wfp.Name, value);
                        } else if (wfp.Required) {
                            let caption = TranslatedString.GetTranslation(wf.Caption);
                            if (caption) {
                                caption = ' for workflow ' + caption;
                            }
                            status.Logger.logError('Template modul: Required parameter ' + wfp.Name + caption + ' not set.');
                            return true;
                        }
                        return false;
                    })) {
                        return super.execute(status);
                    }
                }
                const newStatus = new WorkflowStatus(status.ActualSettings.TemplateID, TranslatedString.GetTranslation(wf.Caption), status);
                newStatus.ActualParameter = status.ActualParameter;
                newStatus.Logger = status.Logger;
                WorkflowStatus.CopyContext(status.Context, newStatus.Context);
                newStatus.WorkflowLayoutService = status.WorkflowLayoutService;
                newStatus.WorkflowID = status.ActualSettings.TemplateID;
                const engine = new WorkflowEngine(wf, newStatus);
                engine.CheckExpressions = true;
                engine.PageSID = 'template';
                await engine.startExecution();
                status.ActualParameter = newStatus.ActualParameter;
                return 0;
            } else {
                status.Logger.logError('Template modul: Template with id ' + status.ActualSettings.TemplateID + ' not found.');
            }
        } else {
            status.Logger.logError('Template modul: No settings found.');
        }
        return super.execute(status);
    }
}
