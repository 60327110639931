import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { NavigationHelper } from '../../../helpers/navigation.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { Layout } from '../../../models/layout.model';
import { GeneralSettingsService } from '../../../services/generalsettings.service';
import { JiraService } from '../../../services/jira.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { StandardRequestBase } from '../../../services/request-base';
import { WorkflowService } from '../../../services/workflow.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { NavigationStructureSettings } from '../structure/navigation.structure.settings';

@Component({
    selector: 'navigation-overview',
    templateUrl: '../../base.list.settings.html'
})
export class NavigationOverwiewSettings extends BaseListSettings {

    static NaviNode: BehaviorSubject<any> = new BehaviorSubject(null);

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Navigation',
    //        ID: 'navigation',
    //        Icon: 'menu',
    //        Index: 20,
    //        Parent: 'layout',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.LayoutRight',
    //            Value: 4
    //        },
    //        Children: [],
    //        UpdateFunction: NavigationOverwiewSettings.UpdateNodes
    //    };
    //}

    private static UpdateNodes(nav) {
        NavigationOverwiewSettings.NaviNode.next(nav);
        if (nav) {
            nav.Children = [];
            const ds = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
            ds.GetNavigationStructures().subscribe(nsList => {
                let index = 0;
                const list = [];
                list.push({
                    Caption: '@@Uebersicht',
                    ID: 'navi-overview',
                    Icon: 'menu',
                    Security: {
                        Name: 'evidanza.App.Shared.Security.LayoutRight',
                        Value: 4
                    },
                    Index: index++,
                    Content: NavigationOverwiewSettings
                });
                if (nsList) {
                    const gss = InjectorHelper.InjectorInstance.get<GeneralSettingsService>(GeneralSettingsService);
                    gss.GetDefaultNavigationStructure().then(defaultNS => {
                        nsList.forEach(ns => {
                            const node = NavigationOverwiewSettings.GetNode(ns, index++);
                            if (defaultNS) {
                                if (ns.SID == defaultNS) {
                                    node.Caption += ' *';
                                }
                            } else if (ns.Caption == 'default') {
                                node.Caption += ' *';
                            }
                            list.push(node);
                        });
                        nav.Children = list;
                        NavigationService.Refresh.next(null);
                    });
                } else {
                    nav.Children = list;
                    NavigationService.Refresh.next(null);
                }
            });
        }
    }

    static GetNode(ns, index) {        
        return {
            Caption: ns.Caption,
            ID: ns.SID,
            Icon: 'menu',
            Index: index,
            Security: {
                Name: 'evidanza.App.Shared.Security.LayoutRight',
                Value: 4
            },
            Content: NavigationStructureSettings,
            InitArgs: {...ns, parent:'Navigation'}
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private service: MetaService, private translate: TranslateService, private gss: GeneralSettingsService) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.service.GetNavigationStructures().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this.ShowDelete = false;
        this.service.GetNavigationStructure(data).subscribe(r => {
            if (r) {
                if (r._iscapsule) {
                    this.ShowDelete = r._isoverriden;
                } else {
                    this.gss.GetDefaultNavigationStructure().then(defaultNS => {
                        if (this.Component.SelectedItem == r) {
                            if (defaultNS) {
                                this.ShowDelete = r._Id != defaultNS;
                            } else {
                                this.ShowDelete = r._key != 'default';
                            }
                            this.cdRef.detectChanges();
                        }
                    });
                }
                this.setSelectedItem(r);
                this.cdRef.detectChanges();
            } else {
                this.setSelectedItem(null);
            }
        });
    }

    getNewItem() {
        this.HasChanges = true;
        return {};
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        const text = '@@Sind Sie sicher, dass Sie die Navigation \'{0}\' loeschen moechten? ';
        retVal.Question = new TranslateFormatText(text);
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Navigation \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Navigation loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Navigation \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel._key);
        retVal.Title = new TranslateFormatText('@@Navigation speichern');
        return retVal;
    }

    async deleteItem() {
        const sel = this.Component.SelectedItem;
        if (sel) {
            if (sel._isoverriden) {
                await super.deleteItem();
            } else {
                const defaultNS = await this.gss.GetDefaultNavigationStructure();
                if (defaultNS) {
                    if (defaultNS == sel._Id) {
                        MessageBoxHelper.ShowDialog(new TranslateFormatText('DefaultNSNoDeleteText'),
                            new TranslateFormatText('DefaultNSNoDeleteHeader'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    } else {
                        await super.deleteItem();
                    }
                } else if (sel._key == 'default') {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('DefaultNSNoDeleteText'),
                        new TranslateFormatText('DefaultNSNoDeleteHeader'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else {
                    await super.deleteItem();
                }
            }
        }
    }

    delete(data: any, handler) {
        this.service.DeleteNavigationStructure(data).subscribe(() => {
            handler(null);
            const node = NavigationOverwiewSettings.NaviNode.getValue();
            if (node && node.Children) {
                for (let i = 0; i < node.Children.length; i++) {
                    if (node.Children[i].ID === data) {
                        node.Children.splice(i, 1);
                        break;
                    }
                }
            }
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const sel = this.Component.SelectedItem;
        if (sel) {
            if (sel._key) {
                const nsList = await this.service.GetNavigationStructures().toPromise();
                const toLower = sel._key.toLowerCase();
                if (nsList.some(x => x.Caption.toLowerCase() === toLower && x.SID !== sel._Id)) {
                    const text = '@@Es existiert bereits eine Navigation mit dem angegebenen Key. Bitte aendern Sie den Key.';
                    retVal.Error = this.translate.instant(text);
                    retVal.IsCorrect = false;
                }
            } else {
                retVal.Error = this.translate.instant('@@Bitte vergeben Sie einen Key');
                retVal.IsCorrect = false;
            }
        }
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        this.service.AddOrUpdateNavigationStructureWithKey(item, item._key).subscribe(r => {
            if (r) {
                handler(r, r.SID, r.Caption);
            }
        });
    }

    getContentType() { return NavigationOverviewDetail; }

    handleNew(item, result) {
        // console.log('new item: ',item,result)
        item._Id = result.SID;
        item._version = result.Version;
        const node = NavigationOverwiewSettings.NaviNode.getValue();
        if (node && node.Children) {
            const child = NavigationOverwiewSettings.GetNode(result, node.Children.length);
            node.Children.push(child);
        }
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    init() {
        NavigationService.SelectedPage.next('layout');
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        NavigationService.SelectedPage.next('settings');
    }
}

@Component({
    selector: 'navigation-overview-detail',
    templateUrl: './navigation.overview.settings.html',
    styleUrls: ['./navigation.overview.settings.css']
})
export class NavigationOverviewDetail extends BaseListDetail implements OnDestroy {
    Pages = [];
    Subscription;
    TempLayout;
    Themes;
    Workflows = [];
    LocalUserManagement;
    Jiras;
    Projects;
    CSSFiles;

    constructor(private service: MetaService, private standardService: StandardRequestBase, private sanitizer: DomSanitizer, private wfService: WorkflowService, private jira: JiraService) {
        super();
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.wfService.GetAllServiceWorkflows().subscribe((data => {
            this.Workflows = data;
        }))
        this.standardService.executeGet('config/api/config', 'GetManagementStatus').subscribe((result) => {
            this.LocalUserManagement = result;
        });
        this.service.GetAllPages().subscribe(r => {
            if (r) {
                this.Pages.push(...r);
            }
        });
        this.service.GetCustomCSS(null).subscribe((files) => {
            this.CSSFiles = files;
        })
        
        this.service.GetThemes(null).subscribe(themes => {
            this.Themes = themes;
        });
        this.jira.GetAll().subscribe(jiras => {
            this.Jiras = jiras;
        });
        this.Subscription = LayoutService.LayoutEditMode.subscribe(x => {
            if (!x && this.TempLayout) {
                LayoutService.BaseLayoutEditMode.next(false);
                const sel = this.SelectedItem;
                if (sel) {
                    const layout = sel[this.TempLayout.Key];
                    if (layout) {
                        const actLayout = JSON.stringify(layout);
                        if (actLayout !== this.TempLayout.Value) {
                            this.OnItemChanged();
                        }
                    }
                }
                this.TempLayout = null;
            }
        });
    }

    OnJiraChanged(item) {
        this.OnItemChanged();
        var config = this.Jiras.find(x => x.SID == item.value);
        if (config) {
            this.jira.GetAllProjects(config).subscribe(projects => {
                this.Projects = projects;
                this.cdRef.detectChanges();
            });
        }
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.PWAIcon = null;
        this.FAVIcon = null;
        if (item) {
            if (item.PWAIcon) {
                this.PWAIcon = this.sanitizer.bypassSecurityTrustResourceUrl(item.PWAIcon);
            }
            if (item.FAVIcon) {
                this.FAVIcon = this.sanitizer.bypassSecurityTrustResourceUrl(item.FAVIcon);
            }
        }
        if (item && item.Jira) {
            var config = this.Jiras.find(x => x.SID == item.Jira);
            this.jira.GetAllProjects(config).subscribe(projects => {
                this.Projects = projects;
                this.cdRef.detectChanges();
            });
        }
    }

    editLayout() {
        const sel = this.SelectedItem;
        if (sel) {
            if (sel.BaseLayout) {
                if (!(sel.BaseLayout instanceof Layout)) {
                    sel.BaseLayout = plainToClass(Layout, sel.BaseLayout);
                }
            } else {
                sel.BaseLayout = NavigationHelper.GetDefaultBaseLayout();
            }
            LayoutService.SelectedLayout.next(sel.BaseLayout);
            LayoutService.BaseLayoutEditMode.next(true);
            LayoutService.LayoutEditMode.next(true);
            this.TempLayout = {
                Key: 'BaseLayout',
                Value: JSON.stringify(sel.BaseLayout)
            };
        }
    }

    resetBaseLayout() {
        if (this.SelectedItem) {
            this.SelectedItem.BaseLayout = null;
            this.OnItemChanged();
        }
    }

    editLogin() {
        const sel = this.SelectedItem;
        if (sel) {
            if (sel.LoginLayout) {
                if (!(sel.LoginLayout instanceof Layout)) {
                    sel.LoginLayout = plainToClass(Layout, sel.LoginLayout);
                }
            } else {
                sel.LoginLayout = NavigationHelper.GetDefaultLoginLayout();
            }
            LayoutService.SelectedLayout.next(sel.LoginLayout);
            LayoutService.LayoutEditMode.next(true);
            this.TempLayout = {
                Key: 'LoginLayout',
                Value: JSON.stringify(sel.LoginLayout)
            };
        }
    }

    resetLoginLayout() {
        if (this.SelectedItem) {
            this.SelectedItem.LoginLayout = null;
            this.OnItemChanged();
        }
    }
    FAVIcon;
    PWAIcon;
    UploadFAV(ev) {
        if (ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                const fr = new FileReader();
                let item = this.SelectedItem;
                let that = this;
                fr.addEventListener('load', function () {
                    item.FAVIcon = fr.result;
                    that.OnItemChanged();
                }, false);
                fr.readAsDataURL(selectedFile);
            }
        }
    }
    UploadPWA(ev) {
        if (ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                const fr = new FileReader();
                let item = this.SelectedItem;
                let that = this;
                fr.addEventListener('load', function () {
                    item.PWAIcon = fr.result;
                    that.OnItemChanged();
                }, false);
                fr.readAsDataURL(selectedFile);
                
            }
        }
    }
}
