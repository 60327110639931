export enum CustomRestEndpointType {
    Save,
    ForceSave,
    Delete,
    ForceDelete,
    DeleteByFilter,
    SearchObjects,
    CountObjects,
    OpenCachedQuery,
    CloseCachedQuery,
    ExecuteCachedCountQuery,
    ExecuteCachedQuery,
    ExecuteCount,
    ExecuteQuery,
    ExecuteObjectQuery
}