import { add } from 'mathjs';
import { FormulaStatics, ValueAndType } from '../basic/formulaEditor.model';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { RangeEnabledFormula } from './iformula';

export class Sum extends RangeEnabledFormula {
    static StaticID: string = 'd3e95252-f734-4ca3-b1fd-db404d5e1f0a';

    constructor() {
        super();
        this._ID = Sum.StaticID;
        this._Name = 'Sum';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
    }

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && (maxType === ValueType.Long || maxType === ValueType.Double)) {
            let value = null;
            args.forEach((item) => {
                if (typeof item.Value !== 'undefined' && item.Value != null) {
                    if (item.Type === ValueType.Range) {
                        this.HandleNumericRange(item, (val) => {
                            if (val !== null && !isNaN(val)) {
                                if (value === null) {
                                    value = val;
                                } else {
                                    value = add(value, val);
                                }
                            }
                        });
                    } else {
                        if (value === null) {
                            value = item.Value;
                        } else {
                            value = add(value, item.Value);
                        }
                    }
                }
            });
            if (value !== null) {
                retVal.Value = value;
                retVal.Type = maxType;
            }
        }
        return retVal;
    }
}