import { Component } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { SpecialElementType } from '../../../models/enums/specialElementType';
import { DatadescriptionControl, OCMPDragInfo } from '../../dialogs/datadescription/datadescription.control';
import { ObjectCreatorSourceTreeBase, TreeNodeSource } from './objectcreatorsourcetree.base';

@Component({
    selector: 'objectcreatorsourcetreebase-special',
    templateUrl: './objectcreatorsourcetree.base.html',
    styleUrls: ['./objectcreatorsourcetree.css']
})
export class ObjectCreatorSourceTreeSpecial extends ObjectCreatorSourceTreeBase {

    UpdateTreeSource(node) {

        this.TreeSource = [];

        const NodeCalculated = new TreeNodeSource(this.MaxUniqueID++);
        NodeCalculated.Caption = TranslateHelper.TranslatorInstance.instant("@@Berechneter Wert");
        NodeCalculated.SID = UUID.UUID();
        NodeCalculated.Icon = "calculate";
        NodeCalculated.SpecialElementType = SpecialElementType.CalculatedElement;
        NodeCalculated.IsExpanded = false;
        this.TreeSource.push(NodeCalculated);

        const NodeEmpty = new TreeNodeSource(this.MaxUniqueID++);
        NodeEmpty.Caption = TranslateHelper.TranslatorInstance.instant("@@leeres Element");
        NodeEmpty.Icon = "border_clear";
        NodeEmpty.SID = UUID.UUID();
        NodeEmpty.SpecialElementType = SpecialElementType.EmptyElement;
        NodeEmpty.IsExpanded = false;
        this.TreeSource.push(NodeEmpty);

        const NodeVariable = new TreeNodeSource(this.MaxUniqueID++);
        NodeVariable.Caption = TranslateHelper.TranslatorInstance.instant("@@Variablenelement");
        NodeVariable.SID = UUID.UUID();
        NodeVariable.SpecialElementType = SpecialElementType.Variable;
        NodeVariable.Icon = "globalvariable";
        NodeVariable.IsExpanded = false;
        this.TreeSource.push(NodeVariable);
    }

    dragStart(event) {
        if (event && event.DragData && event.DragData.length > 0) {
            const dbItem = event.DragData[0];
            if (dbItem) {
                if (dbItem.SpecialElementType === SpecialElementType.Variable) {
                    DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.LevelAndMeasure, { SpecialElementType: dbItem.SpecialElementType }));
                } else {
                    DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.Measure, { SpecialElementType: dbItem.SpecialElementType }));
                }
            }
        }
    }

    dragEnd(ev) {
        DatadescriptionControl.ObjectCreatorMPDragStart.next(null);
    }
}
