import { Component } from '@angular/core';
import { OfflineHelper } from '../../../helpers/offline.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'delete-offline-value-settings',
    templateUrl: './delete.offline.value.settings.html',
    styleUrls: ['./delete.offline.value.settings.css']
})
export class DeleteOfflineValueSettings extends WorkflowDialogContent {
    ActualStatusKey = '';
    KeepParam = true;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'deleteOfflineValueWFModule';
        reg.Caption = '@@Offlinewert loeschen';
        reg.GroupID = 'wfOffline';
        reg.Index = 30;
        reg.SettingsControl = DeleteOfflineValueSettings;
        reg.SettingsTypeHelper = new DeleteOfflineValueDataHelper();
        reg.Executer = DeleteOfflineValueExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.StatusKey) {
                this.ActualStatusKey = data.StatusKey;
            }
            if (typeof data.KeepActualParam === 'boolean') {
                this.KeepParam = data.KeepActualParam;
            }
        }
    }

    getResult(): any {
        const retVal = new DeleteOfflineValueData();
        retVal.StatusKey = this.ActualStatusKey;
        retVal.KeepActualParam = this.KeepParam;
        return retVal;
    }
}

export class DeleteOfflineValueData extends AServiceWorkflowData {
    StatusKey = '';
    KeepActualParam = true;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.WFOffline.DeleteOfflineValue';
    }
}

export class DeleteOfflineValueDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new DeleteOfflineValueData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.StatusKey) {
            return settings.StatusKey;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class DeleteOfflineValueExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.StatusKey) {
                OfflineHelper.DeleteWorkflowData(status.ActualSettings.StatusKey);
                if (!status.ActualSettings.KeepActualParam) {
                    status.ActualParameter = null;
                }
                return 0;
            } else {
                status.Logger.logError('DeleteOfflineValue module: No status key found.');
            }
        } else {
            status.Logger.logError('DeleteOfflineValue module: No settings found.');
        }
        return super.execute(status);
    }
}
