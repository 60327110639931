import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class RolesEndpoints extends OfflineEndpointCollection {
    BasePath = 'config/api/roles/';
    EndPoints = [
        //#region FindRole
        {
            Name: 'FindRole',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.Base.Security.Role'].filter((value) => {
                        return value.SID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region ReadRoles
        //{
        //    Name: 'ReadRoles',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.Core.Base.Security.Role'].filter((value) => {
        //                return value._Id == params['id']
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //#endregion
        //#region ReadRoleInfos
        {
            Name: 'ReadRoleInfos',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.Base.Security.Role'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Name: value.Name ? value.Name : value.SID,
                                    Id:value.SID
                                })
                            })
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
    ]
}