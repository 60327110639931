import { OnInit, Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { DialogHelper } from "../../../helpers/injector.helper";
import { TranslateFormatText } from "../../../helpers/array.helpers";

@Component({
  selector: 'template-dialog',
  templateUrl: 'template.dialog.html',
    styleUrls: ['./template.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateDialog implements OnInit {

  DialogData;

  constructor(private dialogRef: MatDialogRef<TemplateDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.DialogData = data;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
}
