import { Component } from "@angular/core";

@Component({
    selector: 'template-column-dialog',
    templateUrl: 'template.column.dialog.html',
    styleUrls: ['template.column.dialog.css']
})
export class TemplateColumnDialog {

    public Args: any[];

    public Initialize(args: any[]): void {
        this.Args = args;
    }

    public checkAll(event: boolean): void {
        this.Args.forEach(a => a.ColSelected = event);
    }

    public GetDialogResult() {
        return this.Args.filter(x => x.ColSelected);
    }
}