import { Component, OnInit } from "@angular/core";
import { Content } from "../../../../models/end.point.description";

@Component({
    selector: 'end-point-content-type-dialog',
    templateUrl: 'end.point.content.type.dialog.html',
    styleUrls: ['end.point.content.type.dialog.css']
})
export class EndpointContentTypeDialog implements OnInit {
    public content = new Content();

    ngOnInit(): void {
        this.content.ObjectID = "00000000-0000-0000-0000-000000000000";
    }

    public Initialize(arg: Content): void {
        this.content = arg;
    }

    public GetDialogResult() {
        return this.content;
    }
}