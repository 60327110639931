import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'metatags-settings',
    templateUrl: '../../base.list.settings.html'
})
export class MetaTagsSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return MetaTagsDetail;
    }

    loadList(handler) {
        const list = [];
        this.service.GetTags().subscribe((result) => {
            var list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Key,
                    ID: entry.SID
                });
            });
            handler(list);
        });
        handler(list);
    }

    loadData(data) {
        this.service.GetTag(data).subscribe((result) => {
            if (result) {
                const item = result;
                this.setSelectedItem(item);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Key: TranslateHelper.TranslatorInstance.instant('@@Neuer Tag')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Tag \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Tag \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Tag loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        let caption = sel?.Caption;
        if(!caption) {
           caption = sel.Key;
        }
        retVal.Text = new TranslateFormatText('@@Tag \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Tag speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteTag(data).subscribe((result) => {
            handler(result);
        })
    }

    saveInternal(item, handler) {
        if(item && item.Key && item.Value) {
            this.service.SaveTag(item).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, item.Key);
                    LayoutService.Loading.next(false);
                }
            });
        }
        else {
            LayoutService.Loading.next(false);
            this.toggleWindow = true;
            this.cdRef.detectChanges();
            NotificationHelper.Error('Please fill mandatory fields', '@@Error')
        }
    }

    handleNew(item, result) {
        item.ID = result;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@MetaTags',
            ID: 'tags',
            Icon: 'sell',
            Index: 30,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 64
            },
            Content: MetaTagsSettings
        }
    }
}

@Component({
    selector: 'meta-tags-detail',
    templateUrl: './metatags.settings.html',
    styleUrls: ['./metatags.settings.css']
})
export class MetaTagsDetail extends BaseListDetail {
}