import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContextEvent } from '../models/contextevent.model';
import { CSSVarType } from '../models/enums/cssvartype.enum';
import { LayoutChangeType } from '../models/enums/layoutchangetype.enum';
import { ViewType } from '../models/enums/viewtype.enum';
import { Layout } from '../models/layout.model';
import {
    LayoutChange, LayoutElementAddedValue, LayoutElementRemovedValue, LayoutPositionChangeValue,
    MultiLayoutPositionChangeValue, MultiLayoutWorkflowChangeValue, MultiPropertyChangeValue,
    ResolutionAddedValue, ResolutionRemovedValue, WorkflowAddedValue, WorkflowRemovedValue
} from '../models/layout/layout.change.model';
import { LayoutElement } from '../models/layoutelement.model';
import { CustomCSSVariable } from '../models/style/customcssvar.model';
import { TranslatedString } from '../models/translatedstring.model';

@Injectable()
export class LayoutService {
    static DataSourceChanges = 0;
    static BaseLayoutEditMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static SelectedLayout: BehaviorSubject<any> = new BehaviorSubject(null);
    static SelectedResolution: BehaviorSubject<any> = new BehaviorSubject(null);
    static SelectedRibbon: BehaviorSubject<any> = new BehaviorSubject(null);
    static LayoutEditDialogOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    //static Selection: BehaviorSubject<any> = new BehaviorSubject(null);
    static SelectedObject: BehaviorSubject<any> = new BehaviorSubject(null);
    static SelectedItem: BehaviorSubject<any> = new BehaviorSubject(null);
    static SelectedItems: BehaviorSubject<LayoutElement[]> = new BehaviorSubject(null);
    static SelectionChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    static BaseLayout: BehaviorSubject<Layout> = new BehaviorSubject(null);
    static DragType: BehaviorSubject<string> = new BehaviorSubject(null);
    static ViewType: BehaviorSubject<ViewType> = new BehaviorSubject(ViewType.View);
    static RefreshTree: Subject<boolean> = new Subject();
    static OverlayStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static StyleChanged: Subject<any> = new Subject();
    static RootSize: BehaviorSubject<any> = new BehaviorSubject(null);
    static ResolutionSelected: Subject<any> = new Subject();
    static KeyPress: Subject<any> = new Subject();
    static Ribbon: BehaviorSubject<any> = new BehaviorSubject(null);
    static HostClick: Subject<ContextEvent> = new Subject();
    static CloseContextMenu: Subject<any> = new Subject();
    static Theme: BehaviorSubject<any> = new BehaviorSubject(null);
    static Loading: BehaviorSubject<any> = new BehaviorSubject(false);
    static TriggerEventsInEditMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static LayoutEditMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static CustomCSSVariables: CustomCSSVariable[] = null;
    static CSSVariablesMap: any = null;
    static PreventBrowserClose: BehaviorSubject<boolean> = new BehaviorSubject(false);
    static PropertyChanged: Subject<any> = new Subject();
    static LayoutChanged: Subject<LayoutChange> = new Subject();
    static PropertyPanelsChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    //static SelectedItemPositon: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    //static SelectedItemsPositon: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    static LayoutPropertyChangedEvent: Subject<any> = new Subject<any>();
    static LayoutElementsChangedEvent: Subject<any> = new Subject<any>();
    static LayoutWorkflowsChanged: Subject<any> = new Subject<any>();

    static PrimarySID = 'f4c48ffa-d4ab-41c4-a49f-5b888cce1f67';
    static SystemCSSVariables: CustomCSSVariable[] = [
        {
            SID: LayoutService.PrimarySID,
            Caption: new TranslatedString('Primary'),
            Name: 'Primary',
            Value: '',
            Type: CSSVarType.Color,
            VariableName: '--primary-color'
        },
        {
            SID: 'aecd0633-29fe-46d5-945e-5fadbb560f41',
            Caption: new TranslatedString('Accent'),
            Name: 'Accent',
            Value: '',
            Type: CSSVarType.Color,
            VariableName: '--accent-color'
        },
        {
            SID: 'a07b83f6-00e8-496f-a09b-dd9bcbac3dbf',
            Caption: new TranslatedString('Warn'),
            Name: 'Warn',
            Value: '',
            Type: CSSVarType.Color,
            VariableName: '--warn-color'
        }
    ];

    static CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

    private static OnLayoutChanged(changeValue, changeType: LayoutChangeType) {
        let resolution;
        const resVal = LayoutService.SelectedResolution.getValue();
        if (resVal) {
            resolution = resVal.ID;
        }
        LayoutService.LayoutChanged.next({
            ChangeValue: changeValue,
            LayoutChangeType: changeType,
            ResolutionID: resolution
        });
    }

    static OnLayoutPropertyChanged(elementID: string, propName: string, value: any) {        
        LayoutService.OnLayoutChanged({
            ElementID: elementID,
            PropertyName: propName,
            Value: value
        }, LayoutChangeType.PropertyChange);
    }

    static OnMultiLayoutPropertyChanged(elements: MultiPropertyChangeValue[]) {
        LayoutService.OnLayoutChanged({
            Elements: elements
        }, LayoutChangeType.MultiPropertyChange);
    }

    static OnLayoutElementMoved(changeValue: LayoutPositionChangeValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.PositionChange);
    }

    static OnMultiLayoutElementMoved(changeValue: MultiLayoutPositionChangeValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.MultiPositionChange);
    }

    static OnLayoutElementAdded(changeValue: LayoutElementAddedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.ElementAdded);
    }

    static OnLayoutElementRemoved(changeValue: LayoutElementRemovedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.ElementRemoved);
    }

    static OnLayoutWorkflowsChanged(changeValue: MultiLayoutWorkflowChangeValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.WorkflowChange);
    }

    static OnLayoutWorkflowAdded(changeValue: WorkflowAddedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.WorkflowAdded);
    }

    static OnLayoutWorkflowRemoved(changeValue: WorkflowRemovedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.WorkflowRemoved);
    }

    static OnResolutionAdded(changeValue: ResolutionAddedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.ResolutionAdded);
    }

    static OnResolutionRemoved(changeValue: ResolutionRemovedValue) {
        LayoutService.OnLayoutChanged(changeValue, LayoutChangeType.ResolutionRemoved);
    }
}
