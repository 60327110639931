export class LogManager {

    public static Log(title, message) {
        console.log('[' + new Date().toJSON() + '] '+title+':' + message);
    }

    public static Error(title, message) {
        console.error('[' + new Date().toJSON() + '] ' + title + ':' + message);
    }

    public static Warn(title, message) {
        console.warn('[' + new Date().toJSON() + '] ' + title + ':' + message);
    }
}