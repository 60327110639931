import { Component, EventEmitter, Input, Output } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { AxisTick, ChartTextStyle, ContinuousAxisSettings, GridLineSettings } from '../../../models/controls/chart.model';
import { AxisTextPosition } from '../../../models/enums/axistextposition.enum';
import { Color } from '../../../models/style/color.model';
import { MediaService } from '../../../services/media.service';

@Component({
    selector: 'chartsreportobject-axis-menu-tab',
    templateUrl: './axis.menu.tab.html'
})
export class AxisMenuTab {
    ActualSettings = new AxisDefinition();

    //#region AxisSettings
    AxisSettingsValue: ContinuousAxisSettings;

    @Input()
    get AxisSettings() {
        return this.AxisSettingsValue;
    }
    set AxisSettings(val) {
        this.AxisSettingsValue = val;
        this.ActualSettings = AxisDefinition.GetAxisDefinition(this.AxisSettingsValue);
        this.AxisSettingsChange.emit(this.AxisSettingsValue);
    }

    @Output() AxisSettingsChange = new EventEmitter<any>();
    //#endregion

    //#region IsValueAxis
    IsValueAxisValue = false;

    @Input()
    get IsValueAxis() {
        return this.IsValueAxisValue;
    }
    set IsValueAxis(val) {
        this.IsValueAxisValue = val;
        this.IsValueAxisChange.emit(this.IsValueAxisValue);
    }

    @Output() IsValueAxisChange = new EventEmitter<any>();
    //#endregion

    fontFamilies = [];
    fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
    formats = ['decimal', 'scientific', 'currency', 'percent', 'short', 'long'];
    @Output() OptionChanged = new EventEmitter<string>();

    constructor() {
        MediaService.GetFontList().forEach(x => {
            this.fontFamilies.push({
                Name: x,
                Style: {
                    'font-family': x
                }
            });
        });
    }

    onOptionChanged(prop: string) {
        this.OptionChanged.emit(prop);
    }

    showTextChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            this.AxisSettingsValue.TextPosition = this.ActualSettings.ShowText ? AxisTextPosition.out : AxisTextPosition.none;
            this.onOptionChanged('TextPosition');
        }
    }

    showGridLinesChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (this.ActualSettings.ShowGridLines) {
                if (this.AxisSettingsValue.GridLines) {
                    this.AxisSettingsValue.GridLines.Count = -1;
                    this.onOptionChanged('ShowGridLines');
                }
            } else {
                if (!this.AxisSettingsValue.GridLines) {
                    this.AxisSettingsValue.GridLines = new GridLineSettings();
                }
                this.AxisSettingsValue.GridLines.Count = 0;
                this.onOptionChanged('ShowGridLines');
            }
        }
    }

    showMinorGridLinesChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (this.ActualSettings.ShowMinorGridLines) {
                if (this.AxisSettingsValue.MinorGridLines) {
                    this.AxisSettingsValue.MinorGridLines.Count = -1;
                    this.onOptionChanged('ShowMinorGridLines');
                }
            } else {
                if (!this.AxisSettingsValue.MinorGridLines) {
                    this.AxisSettingsValue.MinorGridLines = new GridLineSettings();
                }
                this.AxisSettingsValue.MinorGridLines.Count = 0;
                this.onOptionChanged('ShowMinorGridLines');
            }
        }
    }

    fontNameChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (!this.AxisSettingsValue.TextStyle) {
                this.AxisSettingsValue.TextStyle = new ChartTextStyle();
            }
            this.AxisSettingsValue.TextStyle.FontName = this.ActualSettings.TextStyle.FontName;
            this.onOptionChanged('FontName');
        }
    }

    fontSizeChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (!this.AxisSettingsValue.TextStyle) {
                this.AxisSettingsValue.TextStyle = new ChartTextStyle();
            }
            this.AxisSettingsValue.TextStyle.FontSize = this.ActualSettings.TextStyle.FontSize;
            this.onOptionChanged('FontSize');
        }
    }

    onBoldChanged() {
        if (this.ActualSettings) {
            this.ActualSettings.TextStyle.Bold = !this.ActualSettings.TextStyle.Bold;
            if (this.AxisSettingsValue) {
                if (!this.AxisSettingsValue.TextStyle) {
                    this.AxisSettingsValue.TextStyle = new ChartTextStyle();
                }
                this.AxisSettingsValue.TextStyle.Bold = this.ActualSettings.TextStyle.Bold;
                this.onOptionChanged('Bold');
            }
        }
    }

    onItalicChanged() {
        if (this.ActualSettings) {
            this.ActualSettings.TextStyle.Italic = !this.ActualSettings.TextStyle.Italic;
            if (this.AxisSettingsValue) {
                if (!this.AxisSettingsValue.TextStyle) {
                    this.AxisSettingsValue.TextStyle = new ChartTextStyle();
                }
                this.AxisSettingsValue.TextStyle.Italic = this.ActualSettings.TextStyle.Italic;
                this.onOptionChanged('Italic');
            }
        }
    }

    onColorChanged(color: Color) {
        if (this.ActualSettings) {
            this.ActualSettings.FontColor = color;
            if (color) {
                const hex = Color.HexFromColor(color);
                this.ActualSettings.ColorStyle = {
                    fill: hex
                };
                if (this.AxisSettingsValue) {
                    if (!this.AxisSettingsValue.TextStyle) {
                        this.AxisSettingsValue.TextStyle = new ChartTextStyle();
                    }
                    this.AxisSettingsValue.TextStyle.Color = hex;
                    this.onOptionChanged('FontColor');
                }
            } else if (this.AxisSettingsValue && this.AxisSettingsValue.TextStyle) {
                this.AxisSettingsValue.TextStyle.Color = null;
                this.onOptionChanged('FontColor');
            }
        }
    }

    clearFont() {
        if (this.ActualSettings) {
            this.ActualSettings.TextStyle = new ChartTextStyle();
            this.ActualSettings.FontColor = null;
            this.ActualSettings.ColorStyle = null;
            if (this.AxisSettingsValue) {
                this.AxisSettingsValue.TextStyle = null;
                this.onOptionChanged('TextStyle');
            }
        }
    }

    onMinChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (!this.AxisSettingsValue.MinValue) {
                this.AxisSettingsValue.MinValue = new AxisTick();
            }
            this.AxisSettingsValue.MinValue.Value = this.ActualSettings.MinValue;
            this.onOptionChanged('MinValue');
        }
    }

    autoMinChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (this.ActualSettings.AutoMin) {
                this.AxisSettingsValue.MinValue = null;
            } else {
                this.AxisSettingsValue.MinValue = new AxisTick();
                this.AxisSettingsValue.MinValue.Value = this.ActualSettings.MinValue;
            }
            this.onOptionChanged('MinValue');
        }
    }

    onMaxChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (!this.AxisSettingsValue.MaxValue) {
                this.AxisSettingsValue.MaxValue = new AxisTick();
            }
            this.AxisSettingsValue.MaxValue.Value = this.ActualSettings.MaxValue;
            this.onOptionChanged('MaxValue');
        }
    }

    autoMaxChanged() {
        if (this.ActualSettings && this.AxisSettingsValue) {
            if (this.ActualSettings.AutoMax) {
                this.AxisSettingsValue.MaxValue = null;
            } else {
                this.AxisSettingsValue.MaxValue = new AxisTick();
                this.AxisSettingsValue.MaxValue.Value = this.ActualSettings.MaxValue;
            }
            this.onOptionChanged('MaxValue');
        }
    }
}

export class AxisDefinition {
    ShowText = false;
    ShowGridLines = false;
    ShowMinorGridLines = false;
    TextStyle = new ChartTextStyle();
    FontColor: Color;
    ColorStyle;
    MinValue = 0;
    AutoMin = true;
    MaxValue = 0;
    AutoMax = true;

    static GetAxisDefinition(axisSettings: ContinuousAxisSettings): AxisDefinition {
        const retVal = new AxisDefinition();
        if (axisSettings) {
            retVal.ShowText = axisSettings.TextPosition !== AxisTextPosition.none;
            retVal.ShowGridLines = !axisSettings.GridLines || axisSettings.GridLines.Count !== 0;
            retVal.ShowMinorGridLines = !axisSettings.MinorGridLines || axisSettings.MinorGridLines.Count !== 0;
            if (axisSettings.TextStyle) {
                retVal.TextStyle = deserialize(ChartTextStyle, serialize(axisSettings.TextStyle));
                if (axisSettings.TextStyle.Color) {
                    retVal.FontColor = Color.FromHex(axisSettings.TextStyle.Color);
                    retVal.ColorStyle = {
                        fill: axisSettings.TextStyle.Color
                    };
                }
            }
            if (axisSettings.MinValue) {
                retVal.AutoMin = false;
                retVal.MinValue = axisSettings.MinValue.Value;
            }
            if (axisSettings.MaxValue) {
                retVal.AutoMax = false;
                retVal.MaxValue = axisSettings.MaxValue.Value;
            }
        }
        return retVal;
    }
}
