import { StyleBase } from '../layoutbase.model';
import { NumberFormat } from './number.format.model';

export class CellStyle extends StyleBase {
    NumberFormat: NumberFormat;
    Gadget: string;

    toJSON() {
        const result = super.toJSON();
        delete result['MergeID'];
        return result;
    }
}
