import { Injectable } from '@angular/core';
import { RequestBase } from './request-base';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatadescriptionSaveObject } from '../models/datadescription/datadescriptiondata.model';
import { ABaseTreeNode } from '../components/common/basetreecontrol/base.tree.control';

@Injectable()
export class DatadescriptionService extends RequestBase {
    static DialogStatusCopy: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    BasePath = 'api/datadescription';

    GetAllDatadescriptions(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllDatadescriptions', this.options);
    }

    GetDatadescription(id): Observable<DatadescriptionSaveObject> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDatadescription?id=' + id, this.options);
    }

    SaveDatadescription(wf): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveDatadescription', wf, this.options);
    }


    DeleteDatadescription(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteDatadescription?id=' + id, this.options);
    }
}

export const DD_REGISTRY = new Map<string, DatadescriptionRegistry>();
export const DD_GROUP_REGISTRY: DatadescriptionGroupDescription[] = [];
export const DD_SERVICE_SETTINGS = new Map<string, any>();
export const DD_TYPE_REGISTRY = new Map<string, DatadescriptionExitTypeInfo>();

export class DatadescriptionNodeHelper {
    private static ServiceList: any[] = null;
    private static ClientList: any[] = null;

    public static GetList(service: boolean) {
        const retVal = [];
        if (service) {
            if (!DatadescriptionNodeHelper.ServiceList) {
                DatadescriptionNodeHelper.ServiceList = DatadescriptionNodeHelper.FillList(true);
            }
            retVal.push(...DatadescriptionNodeHelper.ServiceList);
        } else {
            if (!DatadescriptionNodeHelper.ClientList) {
                DatadescriptionNodeHelper.ClientList = DatadescriptionNodeHelper.FillList(false);
            }
            retVal.push(...DatadescriptionNodeHelper.ClientList);
        }
        return retVal;
    }

    private static FillList(isRelational: boolean) {
        let index = 0;
        const groupDict = new Map<string, DatadescriptionTreeNode>();
        const parentDict = new Map<string, DatadescriptionTreeNode[]>();
        DD_GROUP_REGISTRY.forEach(v => {
            const group = new DatadescriptionTreeNode(index++);
            group.TranslateCaption = v.Caption;
            group.Index = v.Index;
            group.GroupID = v.GroupID;
            group.IsExpanded = true;
            group.HasChildren = true;
            group.Children = [];
            groupDict.set(v.GroupID, group);
            if (v.ParentID) {
                let list = parentDict.get(v.ParentID);
                if (!list) {
                    list = [];
                    parentDict.set(v.ParentID, list);
                }
                list.push(group);
            }
        });
        DD_REGISTRY.forEach((module, key) => {
            if (!module.ObsoleteInfo) {
                let found;
                if (module.DatadescriptionType === DatadescriptionType.Both) {
                    found = true;
                } else {
                  if (isRelational) {
                    found = module.DatadescriptionType === DatadescriptionType.Relational;
                  } else {
                    found = module.DatadescriptionType === DatadescriptionType.Multidimensional;
                    }
                }
                if (found) {
                    let group = groupDict.get(module.GroupID);
                    if (!group) {
                        group = groupDict.get('');
                    }
                    if (group) {
                        const childNode = new DatadescriptionTreeNode(index++);
                        childNode.TranslateCaption = module.Caption;
                        childNode.Index = module.Index;
                        childNode.Module = key;
                        childNode.Draggable = true;
                        group.Children.push(childNode);
                    }
                }
            }
        });
        // ModuleNodes ordnen
        groupDict.forEach(gr => {
            gr.Children.sort((a, b) => a['Index'] - b['Index']);
        });
        // Gruppierung bauen
        const subGroups = [];
        parentDict.forEach((v, k) => {
            const parent = groupDict.get(k);
            if (parent) {
                v.sort((a, b) => a['Index'] - b['Index']);
                v.forEach(x => {
                    subGroups.push(x.GroupID);
                });
                parent.Children.splice(0, 0, ...v);
            }
        });
        // Untergruppen aus HauptDict entfernen
        subGroups.forEach(x => {
            groupDict.delete(x);
        });
        const glTmp = [];
        groupDict.delete('-1');
        groupDict.forEach(gr => {
            if (gr.Children.length > 0) {
                glTmp.push(gr);
            }
        });
        glTmp.sort((a, b) => a.Index - b.Index);
        return glTmp;
    }
}

export class DatadescriptionTreeNode extends ABaseTreeNode {
    Index: number;
    Module: string;
    GroupID: string;
}

export class DatadescriptionRegistry {
    ID: string;
    Caption: string;
    GroupID = '';
    Index = 0;
    SettingsControl;
    SettingsTypeHelper: DatadescriptionModuleSettingsHelper;
    Executer;
    DatadescriptionType: DatadescriptionType = DatadescriptionType.Both;
    ObsoleteInfo: ObsoleteInfo;
}

export class ObsoleteInfo {
    ReplacedBy: string;
}

export class DatadescriptionGroupDescription {
    GroupID = '';
    Caption: string;
    Index = 0;
    ParentID;
}

export enum DatadescriptionType {
    Relational,
    Multidimensional,
    Both
}

export abstract class DatadescriptionModuleSettingsHelper {
    MustUpdateExitPoints = false;

    public static AddStatusKeysToState(state, keys) {
        if (state && Array.isArray(keys) && keys.length > 0) {
            let list = state.get('statusKeys');
            if (!list) {
                list = [];
                state.set('statusKeys', list);
            }
            keys.forEach(x => {
                if (!list.some(y => y === x)) {
                    list.push(x);
                }
            });
        }
    }

    protected static GetSettingsFromModule(module) {
        if (module) {
            if (typeof module.Settings === 'string') {
                return JSON.parse(module.Settings);
            }
            return module.Settings;
        }
        return null;
    }

    getAdditionalCaption(settings) {
        return null;
    }

    async fillActualState(module, state, wfData) {
    }

    getEntryPoints(): DatadescriptionExitInfo[] {
        return [new DatadescriptionExitInfo()];
    }

    abstract getExitPoints(settings): DatadescriptionExitInfo[];

    abstract getEmptySettingsInstance(): any;

    getExitPointLabel(settings: any, id: number): string {
        const exitPoints = this.getExitPoints(settings);
        let retVal = '';
        exitPoints.some(ep => {
            if (ep.ID === id) {
                retVal = ep.Label;
                return true;
            }
            return false;
        });
        return retVal;
    }
}

export class DatadescriptionExitInfo {
    ID = 0;
    Label = '';
    Type;

    equals(other: DatadescriptionExitInfo): boolean {
        return other && other.ID === this.ID && other.Label === this.Label && other.Type === this.Type;
    }
}

export class DatadescriptionExitTypeInfo {
    ID: string;
    Color: string;
}
