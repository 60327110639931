import { AFormula, FormulaGroups } from './iformula';
import { ValueAndType } from '../basic/formulaEditor.model';
import { TranslateHelper } from '../../helpers/injector.helper';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { subtract, divide } from 'mathjs';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';

export class DaysDiffFormula extends AFormula {
    static StaticID = 'd62ad3d3-1503-478c-b253-1b08491d9fd4';

    ID(): string {
        return DaysDiffFormula.StaticID;
    }

    Name(): string {
        return 'DaysDiff';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Tagesberechnung von einem Start- bis zu einem Enddatum');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 10;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        const date = TranslateHelper.TranslatorInstance.instant('@@Datum');
        return this.Name() + '(' + date + '; ' + date + ')';
    }

    MinVariableCount(): number {
        return 2;
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (maxType === ValueType.Datetime && args && args.length >= 2) {
            const date1 = args[0].Value;
            if (date1 instanceof Date) {
                const date1Time = date1.getTime();
                if (!isNaN(date1Time)) {
                    const date2 = args[1].Value;
                    if (date2 instanceof Date) {
                        const date2Time = date2.getTime();
                        if (!isNaN(date2Time)) {
                            retVal.Type = ValueType.Double;
                            retVal.Value = divide(subtract(date1Time, date2Time), 86400000); // 24 * 60 * 60 * 1000
                        }
                    }
                }
            }
        }
        return retVal;
    }
}
