import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { AxisType } from '../../enums/query.enum';
import { ITaskExecuter } from '../atask.model';
import { ChartSeriesResult, SeriesLabelInfo } from './chart.format.model';

export class ChartSeriesLabelTaskExecuter implements ITaskExecuter {
    LevelUniqueID: number;
    MeasureUniqueID: number;
    ShowCaption = false;
    ShowValue = false;
    MeasuresOnSeries = false;
    Result: ChartSeriesResult;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        if (dataDescription) {
            this.MeasuresOnSeries = dataDescription.ShowMeasureOnAxis === AxisType.Y_Axis;
        }
        if (settings) {
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.ShowCaption === 'boolean') {
                this.ShowCaption = settings.ShowCaption;
            }
            if (typeof settings.ShowValue === 'boolean') {
                this.ShowValue = settings.ShowValue;
            }
        }
        if (context) {
            this.Result = context.ChartResult;
        }
    }

    Execute() {
        if (this.Result && this.Result.Series && (this.ShowCaption || this.ShowValue)) {
            if (this.LevelUniqueID === -1 && this.MeasureUniqueID === -1) {
                this.Result.Series.forEach(series => {
                    this.FormatSeries(series);
                });
            } else {
                if (this.MeasuresOnSeries && typeof this.MeasureUniqueID === 'number' && this.MeasureUniqueID > -1) {
                    if (typeof this.LevelUniqueID === 'number' && this.LevelUniqueID > -1) {
                        this.Result.Series.forEach(series => {
                            if (series.MeasureNode && series.MeasureNode.UniqueID === this.MeasureUniqueID
                                && series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID) {
                                this.FormatSeries(series);
                            }
                        });
                    } else {
                        this.Result.Series.forEach(series => {
                            if (series.MeasureNode && series.MeasureNode.UniqueID === this.MeasureUniqueID) {
                                this.FormatSeries(series);
                            }
                        });
                    }
                } else if (typeof this.LevelUniqueID === 'number' && this.LevelUniqueID > -1) {
                    this.Result.Series.forEach(series => {
                        if (series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID) {
                            this.FormatSeries(series);
                        }
                    });
                }
            }
        }
    }

    private FormatSeries(series) {
        series.LabelInfo = new SeriesLabelInfo();
        series.LabelInfo.ShowCaption = this.ShowCaption;
        series.LabelInfo.ShowValue = this.ShowValue;
    }
}
