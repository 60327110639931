import { DataType } from "../models/enums/datatype.enum";
import { LineType } from "../models/enums/linetype.enum";
import { Operator } from "../models/enums/operator.enum";

export class ScriptLine {
    public ScriptText: string;
    public LineType: LineType;
    public Operation: ScriptOperation;
    public LineNumber: number;

    getAllLineNumbers(): number[] {
        return [this.LineNumber];
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        return null;
    }
}

export class LetScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Let;
    }

    public Name: string;
    public Type: DataType;
    public IsVar: boolean;
}

export class SetScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Set;
    }

    public Name: string;
    public SetOperation: ScriptOperation;
}

export class IfScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.If;
    }

    public ElseIfScriptLine = [];
    public ElseScriptLines = [];
    public SubScriptLines = [];

    public IfEndLine: number;
    public ElseEndLine: number;

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        if (typeof this.IfEndLine === 'number') {
            retVal.push(this.IfEndLine);
        }
        if (typeof this.ElseEndLine === 'number') {
            retVal.push(this.ElseEndLine);
        }
        this.SubScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        this.ElseIfScriptLine.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        this.ElseScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.SubScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        if (this.ElseIfScriptLine.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        if (this.ElseScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class WhileScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.While;
    }

    public SubScriptLines = [];
    public EndLine: number;

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        if (typeof this.EndLine === 'number') {
            retVal.push(this.EndLine);
        }
        this.SubScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.SubScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class CaseScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Case;
    }

    public SubScriptLines = [];
    public IsDefault: boolean;

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        this.SubScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.SubScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class SwitchScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Switch;
    }

    public CaseLines = [];
    public DefaultScriptLines = [];

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        this.CaseLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        this.DefaultScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.CaseLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        if (this.DefaultScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class ForScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.For;
    }

    public IsForEach: boolean;
    public LetSetOperation: LetScriptLine;
    public SetLine: ScriptLine;
    public SubScriptLines = [];
    public EndLine: number;

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        if (typeof this.EndLine === 'number') {
            retVal.push(this.EndLine);
        }
        this.SubScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.LetSetOperation) {
            retVal = this.LetSetOperation.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return retVal;
            }
        }
        if (this.SetLine) {
            retVal = this.SetLine.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return retVal;
            }
        }
        if (this.SubScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class TryScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Try;
    }

    public TryScriptLines = [];
    public CatchExceptionName: string;
    public CatchScriptLines = [];
    public FinallyScriptLines = [];

    getAllLineNumbers(): number[] {
        const retVal = super.getAllLineNumbers();
        this.TryScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        this.CatchScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        this.FinallyScriptLines.forEach(x => {
            const subNumbers = x.getAllLineNumbers();
            retVal.push(...subNumbers);
        });
        return retVal;
    }

    getLineOnLineNumber(lineNumber: number): ScriptLine {
        if (lineNumber === this.LineNumber) {
            return this;
        }
        let retVal;
        if (this.TryScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        if (this.CatchScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        if (this.FinallyScriptLines.some(x => {
            retVal = x.getLineOnLineNumber(lineNumber);
            if (retVal) {
                return true;
            }
            return false;
        })) {
            return retVal;
        }
        return retVal;
    }
}

export class ReturnScriptLine extends ScriptLine {
    constructor() {
        super();
        this.LineType = LineType.Return;
    }

    public Name: string;
    public Type: DataType;
}

export class ScriptOperation {
    public Operator: Operator;
    public Operators = [];
    public Value: any;
    public Name: string;
    public Type: string;
    public IsNegative: boolean;
}

export class StringPositions {
    public Str: string;
    public StartIndex: number;
    public EndIndex: number;
}
