import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { TranslatedString } from '../../models/translatedstring.model';
import { NumberCircleService } from '../../services/numbercircle.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'numbercircle-settings',
    templateUrl: '../base.list.settings.html'
})
export class NumberCircleSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@Number ranges',
            ID: 'numbercircles',
            Icon: 'numbers',
            Index: 50,
            Parent: 'data',
            Content: NumberCircleSettings
        };
    }

    constructor(protected fs: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: NumberCircleService) {
        super(fs, cdRef);
    }

    loadList(handler: any) {
        this.service.GetNumberInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(item => {
                    list.push({
                        Caption: item.Caption,
                        ID: item.SID,
                        IsCapsule: item.IsCapsule,
                        IsOverridden: item.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }
    loadData(data: any) {
        this.service.GetNumberDetailInfo(data).subscribe(x => {
            if (x) {
                this.setSelectedItem(x.NumberCircle);
                if (this.Component) {
                    this.Component.ActualNumber = x.ActualNumber;
                }
            }
        });
    }
    get SaveDisabled(): boolean {
        return false;
    }
    getNewItem() {
        return {
            StartNumber: 0,
            FormatString: '{0}'
        };
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete the number range \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Number range \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Delete');
        return retVal;
    }
    delete(data: any, handler: any) {
        this.service.DeleteNumbers(data).subscribe(() => {
            handler();
        });
    }
    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Number range \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Save');
        return retVal;
    }
    saveInternal(item: any, handler: any) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.SaveNumbers(item).subscribe(x => {
            if (x) {
                handler(x, x.SID, x.Caption);
            }
        });
    }
    getContentType() {
        return NumberCircleDetail;
    }
    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        if(this.Component) {
            this.Component.ActualNumber = result.ActualNumber;
        }
    }
    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        if (this.Component) {
            const selItem = this.Component.SelectedItem;
            if (selItem) {
                if (!selItem.Name || typeof selItem.StartNumber !== 'number') {
                    retVal.IsCorrect = false;
                    retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Please enter a name and a start number');
                }
            }
        }
        return retVal;
    }
}

@Component({
    selector: 'numbercircle-detail',
    templateUrl: './numbercircle.settings.html',
    styleUrls:['./numbercircle.settings.css']
})
export class NumberCircleDetail extends BaseListDetail {
    ActualNumber = '';

    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.ActualNumber = '';
    }
}
