import { Component } from '@angular/core';
import { Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { DatasourceSettingsDataHelper } from '../datasource/datasource.settings';

@Component({
    selector: 'wf-addcolumns-settings',
    templateUrl: './addcolumns.settings.html',
    styleUrls: ['./addcolumns.settings.css']
})
export class AddColumnsSettings extends WorkflowDialogContent {
    DataSourceStructure;
    LookUpStructure;
    OrigData;
    Mappings = [];
    AllColumns = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'addcolumnsWFModule';
        reg.Caption = '@@Spalten ergaenzen';
        reg.GroupID = 'reldataoperations';
        reg.Index = 100;
        reg.SettingsControl = AddColumnsSettings;
        reg.SettingsTypeHelper = new AddColumnsSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    public static ReadOtherDataSource(workflowData, moduleID, entryOption) {
        const promise = new Promise<any[]>((resolve, reject) => {
            if (workflowData && workflowData.Connectors) {
                let lookUpMod;
                workflowData.Connectors.some(con => {
                    if (con.EntryModule === moduleID && con.EntryOption === entryOption) {
                        return workflowData.Modules.some(mod => {
                            if (mod.ID === con.ExitModule && mod.Module === 'datasourceWFModule') {
                                lookUpMod = mod;
                                return true;
                            }
                            return false;
                        });
                    }
                    return false;
                });
                DatasourceSettingsDataHelper.getColumns(lookUpMod).then((list) => {
                    resolve(list);
                }, (r) => { reject(r); });
            } else {
                resolve(null);
            }
        });
		return promise;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            this.DataSourceStructure = dsInfo.Columns;
        }
        if (data) {
            this.OrigData = data;
            AddColumnsSettings.ReadOtherDataSource(this.WFData, this.ModuleID, 1).then((list) => {
                this.LookUpStructure = list;
                this.initInternal();
            });
        }
    }

    initInternal() {
        if (this.LookUpStructure && this.DataSourceStructure && this.OrigData) {
            const lus = this.LookUpStructure;
            const dss = this.DataSourceStructure;
            if (this.OrigData.LookUpMapping) {
                const mappings = [];
                this.OrigData.LookUpMapping.forEach(map => {
                    const mapObj = {
                        DS: null,
                        LU: null,
                        DSSettings: null,
                        LUSettings: null
                    };
                    if (map.DataSourceColumn) {
                        if (map.DataSourceColumn.Settings) {
                            mapObj.DSSettings = JSON.parse(map.DataSourceColumn.Settings);
                        }
                        if (map.DataSourceColumn.ColumnName) {
                            mapObj.DS = dss.find(x => x.Name === map.DataSourceColumn.ColumnName);
                        }
                    }
                    if (map.LookUpColumn) {
                        if (map.LookUpColumn.Settings) {
                            mapObj.LUSettings = JSON.parse(map.LookUpColumn.Settings);
                        }
                        if (map.LookUpColumn.ColumnName) {
                            mapObj.LU = lus.find(x => x.Name === map.LookUpColumn.ColumnName);
                        }
                    }
                    mappings.push(mapObj);
                });
                this.Mappings = mappings;
            }
            const allColumns = [];
            let addCols = [];
            if (this.OrigData.AdditionalColumns) {
                addCols = this.OrigData.AdditionalColumns;
            }
            lus.forEach(luc => {
                const allCol = {
                    Name: luc.Name + ' (' + luc.DataTyp + ')',
                    OriginalColumnName: luc.Name,
                    NewColumnName: luc.Name,
                    IsSelected: false
                };
                addCols.some(ac => {
                    if (ac.OriginalColumnName === luc.Name) {
                        allCol.NewColumnName = ac.NewColumnName;
                        allCol.IsSelected = true;
                        return true;
                    }
                    return false;
                });
                allColumns.push(allCol);
            });
            this.AllColumns = allColumns;
        }
    }

    addColumn() {
        this.Mappings.push({
            DS: null,
            LU: null,
            DSSettings: null,
            LUSettings: null
        });
    }

    removeColumn(i) {
        this.Mappings.splice(i, 1);
    }

    getResult(): any {
        const retVal = new AddColumnsSettingsData();
        this.Mappings.forEach(map => {
            if (map.LU && map.DS) {
                const cm = new ColumnsMapping();
                cm.LookUpColumn = new ColumnsMappingSetting();
                cm.LookUpColumn.ColumnName = map.LU.Name;
                cm.DataSourceColumn = new ColumnsMappingSetting();
                cm.DataSourceColumn.ColumnName = map.DS.Name;
                // TODO: Settings
                retVal.LookUpMapping.push(cm);
            }
        });
        this.AllColumns.forEach(allCol => {
            if (allCol.IsSelected) {
                const ac = new AdditionalColumn();
                ac.OriginalColumnName = allCol.OriginalColumnName;
                ac.NewColumnName = allCol.NewColumnName;
                retVal.AdditionalColumns.push(ac);
            }
        });
        return retVal;
    }

    selectAll(ev) {
        if (ev) {
            this.AllColumns.forEach(s => {
                s.IsSelected = ev.checked;
            });
        }
    }
}

// @dynamic
export class AddColumnsSettingsData extends AServiceWorkflowData {
    @Type(() => ColumnsMapping)
    LookUpMapping: ColumnsMapping[] = [];
    @Type(() => AdditionalColumn)
    AdditionalColumns: AdditionalColumn[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AddColumns.AddColumnsSettingsData';
    }
}

export class ColumnsMappingSetting {
    ColumnName: string;
    Settings;
}

// @dynamic
export class ColumnsMapping {
    @Type(() => ColumnsMappingSetting)
    DataSourceColumn: ColumnsMappingSetting;
    @Type(() => ColumnsMappingSetting)
    LookUpColumn: ColumnsMappingSetting;
}

export class AdditionalColumn {
    OriginalColumnName: string;
    NewColumnName: string;
}

export class AddColumnsSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Erfolgreich';
        success.Type = 'relData';
        const noSuccess = new WorkflowExitInfo();
        noSuccess.ID = 1;
        noSuccess.Label = '@@Nicht erfolgreich';
        return [success, noSuccess];
    }

    getEmptySettingsInstance() {
        return new AddColumnsSettingsData();
    }

    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const lookUp = new WorkflowExitInfo();
        lookUp.ID = 1;
        lookUp.Label = '@@LookUp';
        lookUp.Type = 'relData';
        return [def, lookUp];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && settings && settings.AdditionalColumns && settings.AdditionalColumns.length > 0) {
                const colList = await AddColumnsSettings.ReadOtherDataSource(wfData, module.ID, 1);
                if (colList) {
                    settings.AdditionalColumns.forEach(addCol => {
                        colList.some(col => {
                            if (col.Name === addCol.OriginalColumnName) {
                                const newCol = JSON.parse(JSON.stringify(col));
                                newCol.Name = addCol.NewColumnName;
                                ds.Columns.push(newCol);
                                return true;
                            }
                            return false;
                        });
                    });
                }
            }
        }
    }
}
