import { OnInit, Input, Output, EventEmitter, Component, ChangeDetectorRef } from '@angular/core';
import { CapsuleHelper } from '../../../helpers/capsule.helper';

@Component({
    selector: 'capsule-tag-control',
    templateUrl: './capsule.tag.control.html',
    styleUrls:['./capsule.tag.control.css']
})
export class CapsuleTagControl implements OnInit {
    CapsuleTags;
    SelectDisabled = false;

    //#region CapsuleTag
    CapsuleTagValue: string;
    @Input()
    get CapsuleTag(): string {
        return this.CapsuleTagValue;
    }
    set CapsuleTag(val) {
        if (val !== this.CapsuleTagValue) {
            this.CapsuleTagValue = val;
            this.checkDisabled();
            this.CapsuleTagChange.emit(val);
        }
    }
    @Output() CapsuleTagChange = new EventEmitter<any>();
    //#endregion

    //#region PlaceHolder
    PlaceHolderValue = '@@Kapsel-Tag';

    @Input()
    get PlaceHolder() {
        return this.PlaceHolderValue;
    }
    set PlaceHolder(val) {
        this.PlaceHolderValue = val;
        this.PlaceHolderChange.emit(this.PlaceHolderValue);
    }

    @Output() PlaceHolderChange = new EventEmitter<any>();
    //#endregion

    //#region Multiple
    MultipleValue = false;

    @Input()
    get Multiple() {
        return this.MultipleValue;
    }
    set Multiple(val) {
        this.MultipleValue = val;
        this.MultipleChange.emit(this.MultipleValue);
    }

    @Output() MultipleChange = new EventEmitter<any>();
    //#endregion

    //#region Disabled
    DisabledValue = false;

    @Input()
    get Disabled() {
        return this.DisabledValue;
    }
    set Disabled(val) {
        this.DisabledValue = val;
        this.DisabledChange.emit(this.DisabledValue);
    }

    @Output() DisabledChange = new EventEmitter<any>();
    //#endregion

    @Output() SelectionChanged = new EventEmitter<any>();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        CapsuleHelper.GetCapsuleTags().then(r => {
            this.CapsuleTags = r;
            this.checkDisabled();
        });
    }

    onSelectionChanged() {
        this.SelectionChanged.emit();
    }

    private checkDisabled() {
        if (this.MultipleValue) {
            this.SelectDisabled = this.CapsuleTags && Array.isArray(this.CapsuleTagValue) &&
                this.CapsuleTagValue.some(x => !this.CapsuleTags.some(y => x === y));
        } else {
            this.SelectDisabled = this.CapsuleTagValue && this.CapsuleTags && !this.CapsuleTags.some(x => x === this.CapsuleTagValue);
        }
        this.cdRef.detectChanges();
    }
}
