import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { LayoutWizardDialog, LayoutWizardInitArgs } from '../../components/wizards/layout/layout.wizard.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { Layout } from '../../models/layout.model';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { SideNavService } from '../../services/sidenav.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { PagesDetail } from './pages.detail';

@Component({
    selector: 'pages-settings',
    templateUrl: '../base.list.settings.html'
})
export class PagesSettings extends BaseListSettings {
    Pages = [];

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Seiten',
    //        ID: 'pages',
    //        Icon: 'tab',
    //        Index: 10,
    //        Parent: 'layout',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.LayoutRight',
    //            Value: 2
    //        },
    //        Content: PagesSettings
    //    };
    //}

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private service: MetaService, private translate: TranslateService) {
        super(factoryResolver, cdRef);
        this.CopyType = "LayoutCopy";
        this.ShowCopy = true;
    }

    loadList(handler: any) {
        this.Pages = [];
        this.service.GetAllPages().subscribe(result => {
            if (result) {
                this.Pages = result;
                const list = [];
                result.forEach((entry) => {
                    entry.OldURL = entry.URL;
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this.setSelectedItem(this.Pages.find(x => x.SID === data));
    }

    getNewItem() {
        return {
            Name: this.translate.instant('@@Neue Seite'),
            URL: '/',
            IsNew: true
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        const text = '@@Sind Sie sicher, dass Sie die Seite \'{0}\' loeschen moechten? ' +
            'Die Seite kann somit in bestehenden Navigationsstrukturen nicht mehr gefunden werden.';
        retVal.Question = new TranslateFormatText(text);
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Seite \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Seite loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = sel.Name;
        if (!caption) {
            caption = sel.URL;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Seite \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Seite speichern');
        return retVal;
    }

    delete(data: any, handler) {
        if (data) {
            this.service.RemoveLayout(data).subscribe(() => {
                handler(null);
            });
        }
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const sel = this.Component.SelectedItem;
        if (sel) {
            if (sel.URL) {
                LayoutHelper.checkURL(sel);
                if (sel.IsNew || sel.URL !== sel.OldURL) {
                    if (SideNavService.IsSystemURL(sel.URL)) {
                        const text = '@@Die angegebene URL gleicht einer System-URL. Bitte aendern Sie die URL.';
                        retVal.Error = this.translate.instant(text);
                        retVal.IsCorrect = false;
                    } else {
                        const pages = await this.service.GetAllPages().toPromise();
                        const toLower = sel.URL.toLowerCase();
                        if (pages.some(p => p.URL.toLowerCase() === toLower)) {
                            const text = '@@Es existiert bereits eine Seite zu der angegebenen URL. Bitte aendern Sie die URL.';
                            retVal.Error = this.translate.instant(text);
                            retVal.IsCorrect = false;
                        }
                    }
                }
            } else {
                retVal.Error = this.translate.instant('@@Bitte vergeben Sie eine URL.');
                retVal.IsCorrect = false;
            }
        }
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        LayoutHelper.checkURL(item);
        if (item.IsNew || item.IsPaste) {
            if (item.Layout) {
                item.Layout.PageName = item.Name;
                item.Layout.CapsuleTag = item.CapsuleTag;
                this.service.AddPage(item.Layout, item.URL).subscribe((result) => {
                    if (result) {
                        let caption = item.Name;
                        if (!caption) {
                            caption = item.URL;
                        }
                        handler(result, result.SID, result.Caption);
                    }
                });
            }
        } else {
            const content = {
                OldURL: item.OldURL,
                NewURL: item.URL,
                Name: item.Name,
                CapsuleTag: item.CapsuleTag,
                Layout: null
            };
            if (item.Layout) {
                content.Layout = JSON.stringify(item.Layout);
            }
            this.service.UpdatePage(content).subscribe(x => {
                if (x) {
                    if (x.Success) {
                        item.OldURL = x.PageInfo.URL;
                        handler(x.PageInfo, x.PageInfo.SID, x.PageInfo.Caption);
                    } else {
                        const text = new TranslateFormatText('@@Fehler beim Speichern der Seite: {0}');
                        text.FormatParams.push(x.Error);
                        MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        LayoutService.Loading.next(false);
                    }
                }
            });
        }
    }

    getContentType() { return PagesDetail; }

    handleNew(item, result) {
        delete item.IsNew;
        delete item.Layout;
        item.OldURL = result.URL;
        item.SID = result.SID;
        item.Version = result.Version;
        this.Pages.push(item);
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    init() {
        NavigationService.SelectedPage.next('content');
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        NavigationService.SelectedPage.next('settings');
    }


    getCopyItem(sel) {
        return new Promise(resolve => {
            if (sel) {
                if (!sel.Layout) {
                    this.service.ReadLayoutByKey(sel.OldURL).subscribe(l => {
                        if (l) {
                            sel.Layout = plainToClass(Layout, l);
                            resolve(this.CopyLayout(sel));
                        }
                    });
                } else {
                    resolve(this.CopyLayout(sel));
                }
            } else {
                resolve(null);
            }
        });
    }
    getDuplicateItem(sel) {
        return new Promise(resolve => {
            if (sel) {
                if (!sel.Layout) {
                    this.service.ReadLayoutByKey(sel.OldURL).subscribe(l => {
                        if (l) {
                            sel.Layout = plainToClass(Layout, l);
                            resolve(this.DuplicateLayout(sel));
                        }
                    });
                } else {
                    resolve(this.DuplicateLayout(sel));
                }
            } else {
                resolve(null);
            }
        });
    }

    CopyLayout(sel) {
        const copy = JSON.parse(JSON.stringify(sel));
        return copy;
    }
    DuplicateLayout(sel) {
        const copy = JSON.parse(JSON.stringify(sel));
        delete copy.SID;
        copy.Caption += ' (copy)';
        copy.Name += ' (copy)';
        copy.URL += '_copy';
        return copy;
    }

    getPasteItem(): Promise<any> {
        return new Promise(resolve => {
            const copy = JSON.parse(JSON.stringify(ClipboardHelper.ClipboardContent.content));
            if (copy.Layout) {
                copy.Layout = plainToClass(Layout, copy.Layout);
            }
            if (copy['SID']) {
                let result = this.Pages.find(x => x.SID === copy.SID);
                if (!result) {
                    resolve(copy);
                } else {
                    delete copy.SID;
                    delete copy.OldURL;
                    copy.IsPaste = true;
                    copy.Caption += ' (copy)';
                    copy.Name += ' (copy)';
                    copy.URL += '_copy';
                    if (copy.Layout) {
                        delete copy.Layout._Id;
                        delete copy.Layout._version;
                    }
                    resolve(copy);
                }
            } else {
                if (copy.Layout) {
                    copy.Layout = plainToClass(Layout, copy.Layout);
                }
                resolve(copy);
            }
        });
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (item && item.IsNew) {
            const initArgs = new LayoutWizardInitArgs();
            initArgs.Page = item;
            initArgs.ShowCancel = true;
            LayoutWizardDialog.ShowDialog(initArgs, (x) => {
                if (x) {
                    this.setHasChanges(true);
                } else {
                    this.setSelectedItem(null);
                }
            });
        }
    }
}
