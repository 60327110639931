import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { FormulaEditorDialog } from '../../../../components/common/formulaEditor/formulaEditor.control';
import { VariablesNodeInformation } from '../../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../../models/enums/formulainputmode.enum';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-editproperties-settings',
    templateUrl: './editproperties.settings.html',
    // styleUrls: ['./editproperties.settings.css']
})
export class EditPropertiesSettings extends FormulaWorkflowDialogContent {
    Data = new EditPropertiesSettingsData();
    Properties = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'editPropertiesWFModule';
        reg.Caption = '@@Eigenschaften bearbeiten';
        reg.GroupID = 'objectoperations';
        reg.Index = 40;
        reg.SettingsControl = EditPropertiesSettings;
        reg.SettingsTypeHelper = new EditPropertiesSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            const json = JSON.stringify(dsInfo.Properties);
            this.Properties = JSON.parse(json);
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(EditPropertiesSettingsData, json);
        }
    }

    getResult(): any {
        const props = [];
        this.Data.Properties.forEach(x => {
            if (x.PropertyName && x.Formula) {
                props.push(x);
            }
        });
        this.Data.Properties = props;
        return this.Data;
    }

    onNewChanged(prop) {
        prop.PropertyName = '';
    }

    addProp() {
        if (this.Data) {
            if (this.Data.Properties) {
                this.Data.Properties.push(new EditPropertyEntry());
            } else {
                this.Data.Properties = [new EditPropertyEntry()];
            }
        }
    }

    removeProp(i) {
        if (this.Data && this.Data.Properties) {
            this.Data.Properties.splice(i, 1);
        }
    }

    showFormulaDialog(prop, index) {
        const variables = [...this.Variables];
        if (this.Data && this.Data.Properties) {
            for (let i = 0; i < index; i++) {
                const dataProp = this.Data.Properties[i];
                if (dataProp.NewProperty && dataProp.PropertyName) {
                    const vni = new VariablesNodeInformation();
                    vni.Name = dataProp.PropertyName;
                    variables.push(vni);
                }
            }
        }
        this.Properties.forEach(stateProp => {
            const vni = new VariablesNodeInformation();
            vni.Name = stateProp.FullPath;
            variables.push(vni);
        });
        variables.sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }
            return 0;
        });
        const args = {
            Variables: variables,
            InputMode: FormulaInputMode.VariableName,
            Formula: prop.Formula
        };
        FormulaEditorDialog.ShowDialog(args, result => {
            if (result && result.Formula) {
                prop.Formula = result.Formula;
            }
            return true;
        });
    }
}

// @dynamic
export class EditPropertiesSettingsData extends AServiceWorkflowData {
    @Type(() => EditPropertyEntry)
    Properties: EditPropertyEntry[] = [];
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.EditProperties.EditPropertiesSettingsData';
    }
}

export class EditPropertyEntry {
    PropertyName: string;
    NewProperty = false;
    Formula: string;
}

export class EditPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const info = new WorkflowExitInfo();
        info.Type = 'objData';
        return [info];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new EditPropertiesSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && ds.Properties && settings && settings.Properties) {
                settings.Properties.forEach(x => {
                    if (x.NewProperty) {
                        let actProps = ds.Properties;
                        const path = x.PropertyName.split('.');
                        const newPath = [];
                        for (let i = 0; i < path.length - 1; i++) {
                            const propName = path[i];
                            let prop = actProps.find(y => y.Name === propName);
                            if (prop) {
                                if (prop.IsObject) {
                                    actProps = prop.ObjectProperties;
                                } else {
                                    newPath.push(propName);
                                    throw new Error('Property ' + newPath.join('.') + ' is no subdocument.');
                                }
                            } else {
                                prop = {
                                    Name: propName,
                                    Path: newPath.join('.'),
                                    IsObject: true,
                                    ObjectProperties: []
                                };
                                if (i > 0) {
                                    prop.FullPath = prop.Path + '.' + prop.Name;
                                } else {
                                    prop.FullPath = prop.Name;
                                }
                                actProps = prop.ObjectProperties;
                            }
                            newPath.push(propName);
                        }
                        const newProp = {
                            Name: path[path.length - 1],
                            Path: newPath.join('.'),
                            FullPath: '',
                            ObjectProperties: [],
                            SimpleType: 'System.String' // ???
                        };
                        if (newPath.length > 0) {
                            newProp.FullPath = newProp.Path + '.' + newProp.Name;
                        } else {
                            newProp.FullPath = newProp.Name;
                        }
                        actProps.push(newProp);
                    }
                });
            }
        }
    }
}
