import { AFormula, FormulaGroups } from "./iformula";
import { ValueAndType } from "../basic/formulaEditor.model";
import { TranslateHelper } from "../../helpers/injector.helper";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";

export class SubString extends AFormula {
    static StaticID: string = 'f5c5bdf9-7eeb-44d7-a44a-adc6a0ef637d';

    ID(): string {
        return SubString.StaticID;
    }

    Name(): string {
        return 'SubString';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Zurueckgeben einer bestimmten Zeichenfolge');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 2;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '('
            + TranslateHelper.TranslatorInstance.instant('@@Text') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Start') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@{Anzahl}') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@CodeDescription') + ')';
    }

    MinVariableCount(): number {
        return 2;
    }

    MaxVariableCount(): number {
        return 4;
    }

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        retVal.Value = '';
        var text = '';
        var startIndex = 0;
        var length = null;
        if (args && args.length > 1) {
            if (typeof args[0].Value !== 'undefined' && args[0].Value != null) {
                text = String(args[0].Value);
            }
            if (typeof args[1].Value !== 'undefined' && args[1].Value != null) {
                startIndex = Number(args[1].Value);
                if (isNaN(startIndex)) {
                    startIndex = 0;
                } else if (startIndex < 0) {
                    startIndex = 0;
                }
            }
            if (args.length > 2 && typeof args[2].Value !== 'undefined' && args[2].Value != null) {
                length = Number(args[2].Value);
                if (isNaN(length)) {
                    length = null;
                }
            }
        }        
        if (startIndex < text.length) {
            if (length) {
                retVal.Value = text.substr(startIndex, length);
            } else {
                retVal.Value = text.substr(startIndex);
            }
        }
        return retVal;
    }
}