import { Component } from '@angular/core';
import { RestService } from '../../../../../services/rest.service';
import { ImportFile } from '../../../models/import.init.args';

@Component({
    selector: 'import-rest',
    templateUrl: 'import.rest.html',
    styleUrls: ['import.rest.css']
})
export class ImportRestComponent {

    public InitArgs;

    constructor(private _service: RestService) {

    }

    public async fileChangeAsync(event: FileList): Promise<any> {
        if (event.length > 0) {
            const file = event.item(0);
            //const sendObject = new ImportFile();
            //sendObject.MimeType = file.type;
            //sendObject.Title = file.name;
            //sendObject.RestID = this.InitArgs.SID;
            //sendObject.File = await file.arrayBuffer();
            //this._service.fileImport(sendObject).subscribe();

            const reader = new FileReader();
            reader.onload =  () => {
               const sendObject = new ImportFile();
               sendObject.MimeType = file.type;
               sendObject.Title = file.name;
                sendObject.RestID = this.InitArgs.SID;
                sendObject.Text = reader.result.toString().trim();
               this._service.fileImport(sendObject).subscribe();
            };
            reader.readAsText(file);
        }
    }
}