import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';
import { RegionSetting } from '../region.control';

@Component({
    selector: 'wf-correlation-settings',
    templateUrl: './correlation.settings.html',
    styleUrls: ['./correlation.settings.css']
})
export class CorrelationSettings extends WorkflowDialogContent {
    Data = new CorrelationSettingsData();
    Columns = [];
    SubGroupingDisabled = true;
    HasDateCol = false;

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'correlationWFModule';
        reg.Caption = '@@Korrelation';
        reg.GroupID = 'aioperations';
        reg.Index = 190;
        reg.SettingsControl = CorrelationSettings;
        reg.SettingsTypeHelper = new CorrelationSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private translate: TranslateService) {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        const list = [];
        if (dataSource && dataSource.Columns) {
            dataSource.Columns.forEach(col => {
                list.push({
                    Name: col.Name,
                    CompareSource: false,
                    CompareTarget: false,
                    Category: false,
                    Grouping: false,
                    SubGrouping: false,
                    DateCol: false
                });
            });
        }
        this.Columns = list;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(CorrelationSettingsData, json);
            if (!this.Data.AllColumns) {
                if (this.Data.CompareSourceColumns) {
                    this.Data.CompareSourceColumns.forEach(csc => {
                        list.some(entry => {
                            if (entry.Name === csc) {
                                entry.CompareSource = true;
                                return true;
                            }
                            return false;
                        });
                    });
                }
                if (this.Data.CompareTargetColumns) {
                    this.Data.CompareTargetColumns.forEach(ctc => {
                        list.some(entry => {
                            if (entry.Name === ctc) {
                                entry.CompareTarget = true;
                                return true;
                            }
                            return false;
                        });
                    });
                }
            }
            if (this.Data.CatColumns) {
                let subCol;
                this.Data.CatColumns.forEach(sc => {
                    list.some(entry => {
                        if (entry.Name === sc) {
                            entry.Category = true;
                            if (!entry.CompareSource && !entry.CompareTarget) {
                                if (this.Data.Grouping === entry.Name) {
                                    entry.Grouping = true;
                                    this.SubGroupingDisabled = false;
                                } else if (this.Data.SubGrouping === entry.Name) {
                                    subCol = entry;
                                    entry.SubGrouping = true;
                                }
                            }
                            return true;
                        }
                        return false;
                    });
                });
                if (subCol && this.SubGroupingDisabled) {
                    subCol.SubGrouping = false;
                }
            }
            if (this.Data.DateColumn) {
                const dateCol = list.find(x => x.Name === this.Data.DateColumn);
                if (dateCol && !dateCol.Category) {
                    dateCol.DateCol = true;
                    this.HasDateCol = true;
                }
            }
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Columns.length > 0) {
            if (!this.Data.AllColumns) {
                let compareSourceSet = false;
                let compareTargetSet = false;
                this.Columns.some(col => {
                    if (col.CompareSource) {
                        compareSourceSet = true;
                    }
                    if (col.CompareTarget) {
                        compareTargetSet = true;
                    }
                    return compareSourceSet && compareTargetSet;
                });
                if (!compareSourceSet) {
                    retVal.IsCorrect = false;
                    if (compareTargetSet) {
                        retVal.Error = '@@Bitte waehlen Sie eine oder mehrere Vergleichsspalte';
                    } else {
                        retVal.Error = '@@Bitte waehlen Sie eine oder mehrere Vergleichsspalten und zu vergleichende Spalten';
                    }
                } else if (!compareTargetSet) {
                    retVal.IsCorrect = false;
                    retVal.Error = '@@Bitte waehlen Sie eine oder mehrere zu vergleichende Spalten';
                }
            }
        }
        return retVal;
    }

    getResult() {
        const retVal = new CorrelationSettingsData();
        retVal.AllColumns = this.Data.AllColumns;
        retVal.Augments = this.Data.Augments;
        retVal.RegionSetting = this.Data.RegionSetting;
        this.Columns.forEach(col => {
            if (col.CompareSource) {
                retVal.CompareSourceColumns.push(col.Name);
            } else if (col.CompareTarget) {
                retVal.CompareTargetColumns.push(col.Name);
            }
            if (col.Category) {
                retVal.CatColumns.push(col.Name);
                if (!col.CompareSource && !col.CompareTarget) {
                    if (col.Grouping) {
                        retVal.Grouping = col.Name;
                    } else if (col.SubGrouping) {
                        retVal.SubGrouping = col.Name;
                    }
                }
            } else if (col.DateCol) {
                retVal.DateColumn = col.Name;
            }
        });
        return retVal;
    }

    categoryChanged(ev, col) {
        if (!ev || ev.checked === false) {
            if (col.Grouping) {
                col.Grouping = false;
                this.Columns.forEach(c => c.SubGrouping = false);
                this.SubGroupingDisabled = true;
            }
            if (col.SubGrouping) {
                col.SubGrouping = false;
            }
        }
    }

    groupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.Grouping = false);
            col.Grouping = true;
            col.SubGrouping = false;
            this.SubGroupingDisabled = false;
        } else {
            this.Columns.forEach(c => c.SubGrouping = false);
            this.SubGroupingDisabled = true;
        }
    }

    subGroupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.SubGrouping = false);
            col.SubGrouping = true;
        }
    }

    dateColChanged(ev, col) {
        if (ev) {
            if (ev.checked) {
                this.Columns.forEach(c => c.DateCol = false);
                col.DateCol = true;
                this.HasDateCol = true;
            } else {
                this.HasDateCol = false;
            }
        }
    }

    GetExpressionProperties() {
        return [
            {
                Caption: this.translate.instant('@@Vergleichsspalten'),
                Value: 'CompareSourceColumns'
            },
            {
                Caption: this.translate.instant('@@zu vergleichende Spalten'),
                Value: 'CompareTargetColumns'
            },
            {
                Caption: this.translate.instant('@@Kategorie'),
                Value: 'CatColumns'
            },
            {
                Caption: this.translate.instant('@@Gruppierung'),
                Value: 'Grouping'
            },
            {
                Caption: this.translate.instant('@@Untergruppierung'),
                Value: 'SubGrouping'
            },
            {
                Caption: this.translate.instant('@@Datum'),
                Value: 'DateColumn'
            },
            {
                Caption: this.translate.instant('@@Alle Spalten miteinander vergleichen'),
                Value: 'AllColumns'
            },
            {
                Caption: this.translate.instant('@@Augments'),
                Value: 'Augments'
            },
            {
                Caption: this.translate.instant('@@Land'),
                Value: 'RegionSetting.Country'
            },
            {
                Caption: this.translate.instant('@@Region'),
                Value: 'RegionSetting.Region'
            }
        ];
    }
}

// @dynamic
export class CorrelationSettingsData extends AServiceWorkflowData {
    CompareSourceColumns = [];
    CompareTargetColumns = [];
    DateColumn: string;
    Grouping: string;
    SubGrouping: string;
    CatColumns = [];
    AllColumns = false;
    Augments: number;
    @Type(() => RegionSetting)
    RegionSetting = new RegionSetting();

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.Correlation.CorrelationSettingsData';
    }
}

export class CorrelationSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new CorrelationSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const ds = state.get('DataSource');
            if (ds) {
                ds.Columns = [
                    {
                        Name: 'Category',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Subcategory',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Type',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Comparison',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Target',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Value',
                        DataTyp: 'System.Double'
                    }
                ];
            }
        }
    }
}
