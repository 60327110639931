import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { plainToClass } from "class-transformer";
import { TreeNode } from 'primeng/api';
import { LayoutHelper } from "../../../helpers/layout.helper";
import { DialogButton } from "../../../models/enums/dialogbutton.enum";
import { GitFileStatus } from "../../../models/enums/gitfilestatus.enum";
import { FileDiff } from "../../../models/git/filediff.model";
import { GitDiffType } from "../../../models/git/gitcomparison.model";
import { LayoutElement } from "../../../models/layoutelement.model";
import { WorkflowData } from "../../../models/workflow/workflow.model";
import { GitService } from "../../../services/git.service";
import { WorkflowService } from "../../../services/workflow.service";
import { BaseDialog } from "../../../components/dialogs/basedialog/base.dialog";
import { RequestNameDialog } from "../../../components/dialogs/reqeustname/reqeustname.dialog";
import { WorkflowEditOptions } from "../../../workflow/workflow.control";
import { GitHelper } from '../../../helpers/git.helper';
declare var GitgraphJS: any;

@Component({
    selector: 'git-settings',
    templateUrl: './git.settings.html',
    styleUrls: ['./git.settings.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitSettings implements OnInit, OnDestroy {

    public static GetSettingsEntry() {
        if (GitHelper.IsActive) {
            return {
                Caption: '@@Development',
                ID: 'development',
                Icon: 'development',
                Index: 4,
                Parent: '',
                Security: {
                    Name: 'evidanza.App.Shared.Security.DevelopmentRight',
                    Value: 1
                },
                Children: [{
                    Caption: '@@Git',
                    ID: 'git',
                    Icon: 'git',
                    Index: 1,
                    Parent: 'development',
                    Security: {
                        Name: 'evidanza.App.Shared.Security.DevelopmentRight',
                        Value: 1
                    },
                    Content: GitSettings,
                    Children: []
                }]
            };
        }
        return null;
    }

    //#region Properties
    InitArgs;

    Branches = [];
    References = [];
    Status;

    StagedFiles = [];
    UnstagedFiles = [];
    StagedSelectedCount = 0;
    UnstagedSelectedCount = 0;

    CommitFiles = [];
    CommitCount = 0;
    PullCount = 0;
    PushCount = 0;

    OldLayout;
    NewLayout;
    
    Changes: FileDiff[] = [];
    SelectedChanges: FileDiff[] = [];
    SelectedCommit;
    SelectedDiff;
    SelectedBranch;
    SelectedActiviyDiff;
    ActiveTabIndex = 0;
    editorOptions = {
        theme: 'vs-dark',
        readOnly: true
    };
    //#region Loading
    _Loading = false;
    get Loading() {
        return this._Loading;
    }
    set Loading(value) {
        this._Loading = value;
        this.cdRef.detectChanges();
    }
    //#endregion
    DocumentCompare;

    Comment = '';
    AutoPush = false;
    ObjectsTree = [];
    SelectedObject = null;
    DiffView = 'monaco';
    SelectedRepository = 'master';
    TempRep;
    TemplatesLoaded = false;
    EditOptions = new WorkflowEditOptions();
    BranchTree: TreeNode[] = [];
    //#endregion
    //#region Lifecycle
    constructor(private service: GitService, private cdRef: ChangeDetectorRef, private translate: TranslateService, private wfService: WorkflowService) {
       
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Git',
    //        ID: 'git',
    //        Icon: 'git',
    //        Index: 1,
    //        Parent: 'development',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.DevelopmentRight',
    //            Value: 1
    //        },
    //        Content: GitSettings,
    //        Children: []
    //    }
    //}

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.Refresh();
    }
    //#endregion
    //#region Data
    Refresh() {
        this.Loading = true;
        this.UnstagedFiles = [];
        this.StagedFiles = [];
        this.CommitFiles = [];
        this.CommitCount = 0;
        this.PushCount = 0;
        this.PullCount = 0;
        this.StagedSelectedCount = 0;
        this.UnstagedSelectedCount = 0;
        this.SelectedBranch = null;
        this.BranchTree = [];

        this.DocumentCompare = null;
        
        let LocalBranches: TreeNode = {};
        LocalBranches.label = "local";
        LocalBranches.expanded = true;
        LocalBranches.children = [];
        let RemoteBranches: TreeNode = {};
        RemoteBranches.label = "remote";
        RemoteBranches.expanded = true;
        RemoteBranches.children = [];
        this.service.GetBranches().subscribe((result) => {
            this.Branches = result;
            if (result && result.length > 0) {
                result.forEach((branch) => {
                    let item: TreeNode = {};
                    item.label = branch.Name;
                    item.data = branch;
                    if (branch.IsCheckedOut) {
                        this.SelectedBranch = branch;
                        item.label = "* " + branch.Name;
                    }
                    if (branch.IsRemote) {
                        RemoteBranches.children.push(item);
                    } else {
                        LocalBranches.children.push(item);
                    }
                })
                this.BranchTree.push(LocalBranches);
                this.BranchTree.push(RemoteBranches);
            }
        });
        this.service.GetStatus().subscribe((result) => {
            this.Status = result;
            if (result && result.Files && result.Files.length > 0) {
                result.Files.forEach((file) => {

                    if (file.X != ' ' && file.X != '?') {
                        this.StagedFiles.push(file);
                    }
                    if (file.Y != ' ' && (file.X == ' ' || file.X == '?')) {
                        this.UnstagedFiles.push(file);
                        this.UnstagedFiles.sort((a, b) => {
                            if (GitFileStatus[a.Y] > GitFileStatus[b.Y]) {
                                return 1;
                            }
                            if (GitFileStatus[a.Y] < GitFileStatus[b.Y]) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                });
                this.CommitCount = result.Files.length;
            }
            this.PushCount = result.PullsAvailable;

            this.service.GetDiff().subscribe((result) => {
                if (result && result.length > 0) {
                    this.Changes = result;
                }
                this.Loading = false;
                this.LoadActivities();
            });
        });
    }
    Commits = [];
    LoadActivities() {
        this.Loading = true;
        let selectedBranchIndex = 0;
        this.service.GetLog("1 year", null).subscribe((result) => {
            let retVal = [];
            if (result) {

                result.forEach((commit, index) => {
                    let list = commit.Refs ? commit.Refs.split(', ') : [];
                    let refs = [];
                    list.forEach((ref) => {
                        if (ref.indexOf('HEAD -> ') > -1) {
                            refs.push(ref.replace('HEAD -> ', ''));
                            selectedBranchIndex = index;
                        }
                        if (ref.indexOf('origin/' + this.SelectedBranch.Name) > -1) {
                            if (index >= selectedBranchIndex) {
                                this.PushCount = index - selectedBranchIndex;
                            }
                        }
                    });


                    let item = {
                        refs: refs,
                        hash: commit.CommitHash,
                        hashAbbrev: commit.AbbreviatedCommitHash,
                        tree: commit.TreeHash,
                        treeAbbrev: commit.AbbreviatedTreeHash,
                        parents: commit.ParentHashes ? commit.ParentHashes.split(' ') : [],
                        parentsAbbrev: commit.AbbreviatedParentHashes ? commit.AbbreviatedParentHashes.split(' ') : [],
                        author: {
                            name: commit.AuthorName,
                            email: commit.AuthorEMail,
                            timestamp: new Date(commit.AuthorDate).getTime()
                        },
                        committer: {
                            name: commit.CommitterName,
                            email: commit.CommitterEMail,
                            timestamp: new Date(commit.CommitterDate).getTime()
                        },
                        subject: commit.Subject,
                        body: commit.Body ? commit.Body : "",
                        notes: "",
                        stats: [],
                        onClick: (event) => {
                            this.CommitFiles = [];
                            this.SelectedActiviyDiff = null;
                            this.SelectedCommit = event;
                            this.Loading = true;
                            this.service.GetDiff(event.hash, event.parents[0]).subscribe((result) => {
                                this.CommitFiles = result;
                                this.Loading = false;
                            })
                            
                        }
                    };
                    retVal.push(item);
                });
            }
            this.Commits = retVal;
            if (this.ActiveTabIndex == 1) {
                this.RenderGraph();
            }
            this.Loading = false;
        });
    }
    //#endregion
    //#region GitGraph
    RenderGraph() {
        if (this.Commits) {
            const graphContainer = document.getElementById("graph-container");
            if (graphContainer.innerHTML) {
                graphContainer.innerHTML = null;
            }
            const gitgraph = GitgraphJS.createGitgraph(graphContainer);
            gitgraph.import(this.Commits);
        }
    }
    //#endregion
    TabChanged(event) {
        if (event.index == 1) {
            this.LoadActivities();
        }
    }
    //#region GitActions
    Commit() {
        this.Loading = true;
        this.service.Commit(this.Comment).subscribe((result) => {
            this.Comment = null;
            if (this.AutoPush) {
                this.service.Push().subscribe((retVal) => {
                    this.Refresh();
                });
            } else {
                this.Refresh();
            }
        });
    }
    Pull() {
        this.Loading = true;
        this.service.Pull().subscribe((result) => {
            this.Loading = false;
        });
    }
    Push() {
        this.Loading = true;
        this.service.Push().subscribe((result) => {
            this.Loading = false;
        });
    }
    Fetch() {
        this.Loading = true;
        this.service.Fetch().subscribe((result) => {
            this.Loading = false;
        });
    }
    Branch() {
        BaseDialog.ShowDialog({
            ContentType: RequestNameDialog,
            InitArgs: { Text: '', Placeholder: 'Name' },
            Title: '@@Name waehlen',
            Buttons: DialogButton.None,
            Handler: (result) => {
                if (result) {
                    this.Loading = true;

                    this.service.CreateBranch(result).subscribe((ret) => {
                        this.Refresh();
                    });                        
                } else {
                    return true;
                }
            }
        });
    }
    Merge() {
        this.Loading = true;
        this.service.MergeBranch("master").subscribe((result) => {
            this.Loading = false;
        });
    }
    Checkout(branch) {
        if (branch.IsRemote) {
            BaseDialog.ShowDialog({
                ContentType: RequestNameDialog,
                InitArgs: { Text: branch.Name.replace('remotes/origin',''), Placeholder: 'Name' },
                Title: '@@Name waehlen',
                Buttons: DialogButton.None,
                Handler: (result) => {
                    if (result) {
                        this.Loading = true;
                        this.service.Checkout(result, branch.Name).subscribe((ret) => {
                            this.Refresh();
                        });
                    } else {
                        return true;
                    }
                }
            });
        } else {
            this.Loading = true;
            this.service.Checkout(branch.Name).subscribe((result) => {
                this.Refresh();
            })
        }
    }
    Discard() {
        
    }
    //#endregion
    //#region DiffSelection
    ActiveChangeset;
    ShowDiff(file, files) {
        this.SelectedDiff = null;
        this.ActiveChangeset = null;
        if (file && this.Changes) {
            files.forEach((f) => f.ShowPreview = false);
            file.ShowPreview = true;
            let change = this.Changes.find((value) => {
                return file.FileName == value.From || file.FileName == value.To
            });
            if (change != null) {
                if (change.Chunks) {
                    change.Chunks.forEach((chunk) => {
                        if (chunk.Changes) {
                            chunk['OldText'] = null;
                            chunk['OldDocument'] = null;
                            chunk['NewText'] = null;
                            chunk['NewDocument'] = null;
                            if (chunk.Changes.length == 1) {
                                let ch = chunk.Changes[0];
                                let content = ch.Content;
                                let text = "";
                                if (ch.Content.indexOf('{') > -1) {
                                    text = ch.Content.substr(ch.Content.indexOf('{'));
                                    content = JSON.stringify(JSON.parse(text), null, 2);
                                }
                                if (chunk.Changes[0].Add) {
                                    chunk['OldDocument'] = {
                                        code: "",
                                        language: 'json'
                                    }
                                    chunk['NewDocument'] = {
                                        code: content,
                                        language: 'json'
                                    }
                                    chunk['NewText'] = text;
                                }
                                if (chunk.Changes[0].Delete) {
                                    chunk['OldDocument'] = {
                                        code: content,
                                        language: 'json'
                                    }
                                    chunk['OldText'] = text;
                                    chunk['NewDocument'] = {
                                        code: "",
                                        language: 'json'
                                    }
                                }
                            } else {
                                chunk.Changes.forEach((ch) => {
                                    if (ch.Content.indexOf('{') > -1) {
                                        let text = ch.Content.substr(ch.Content.indexOf('{'));
                                        let content = JSON.stringify(JSON.parse(text), null, 2);
                                        if (!chunk['OldDocument']) {
                                            chunk['OldDocument'] = {
                                                code: content,
                                                language: 'json'
                                            }
                                            chunk['OldText'] = text;
                                        } else {
                                            chunk['NewDocument'] = {
                                                code: content,
                                                language: 'json'
                                            }
                                            chunk['NewText'] = text;
                                        }
                                    } else {
                                        if (!chunk['OldDocument']) {
                                            chunk['OldDocument'] = {
                                                code: ch.Content,
                                                language: 'json'
                                            }
                                        } else {
                                            chunk['NewDocument'] = {
                                                code: ch.Content,
                                                language: 'json'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        if (chunk['OldText'] && chunk['NewText']) {
                            let changes = LayoutHelper.CompareText(chunk['OldText'], chunk['NewText'], {
                                CreateChangesTree: true,
                                DeepComparison:true
                            });
                            this.OldLayout = plainToClass(LayoutElement, JSON.parse(chunk['OldText']));
                            this.NewLayout = plainToClass(LayoutElement, JSON.parse(chunk['NewText']));
                            if (changes.HasChanges) {
                                this.ObjectsTree = changes.ChangesTree;
                            } else {
                                this.ObjectsTree = null;
                            }
                            this.ActiveChangeset = changes;
                        }
                    });
                }
                this.SelectedDiff = change;
                this.cdRef.detectChanges();
            }
        }
    }
    ShowActivityDiff(file, files) {
        this.SelectedDiff = null;
        if (this.CommitFiles) {
            this.CommitFiles.forEach((item) => {
                item.ShowPreview = false;
            });
        }
        if (file) {
            if (file.Chunks) {
                file.Chunks.forEach((chunk) => {
                    if (chunk.Changes) {
                        chunk['OldDocument'] = null;
                        chunk['OldText'] = null;
                        chunk['NewDocument'] = null;
                        chunk['NewText'] = null;
                        chunk.Changes.forEach((ch) => {
                            if (ch.Content.indexOf('{') > -1) {
                                let text = ch.Content.substr(ch.Content.indexOf('{'));
                                let content = JSON.stringify(JSON.parse(text), null, 2);
                                if (!chunk['OldDocument']) {
                                    chunk['OldDocument'] = {
                                        code: content,
                                        language: 'json'
                                    }
                                    //this.ParseLayout(text, "old");
                                } else {

                                    chunk['NewDocument'] = {
                                        code: content,
                                        language: 'json'
                                    }
                                    //this.ParseLayout(text, "new");
                                }
                            }
                        });
                    }
                });
            }
            file.ShowPreview = true;
            this.SelectedDiff = file;
            this.cdRef.detectChanges();
        }
    }
    //#endregion
    //#region SideBySide Overlay
    _SideBySideLayout = false;
    get SideBySideLayout() {
        return this._SideBySideLayout;
    }
    set SideBySideLayout(value) {
        this._SideBySideLayout = value;
        this.cdRef.detectChanges();
    }
    ShowLayoutSideBySide() {
        this.SideBySideLayout = true;
    }
    //#endregion
    //#region Staging
    CheckDisabled() {
        return this.SelectedDiff.From ? this.SelectedDiff.From.indexOf('json_evidanza_App_Shared_Layout') == -1 : true
    }
    StageAll() {
        this.Loading = true;
        this.service.StageAll().subscribe(() => {
            this.Refresh();
        })
    }
    async StageSelected() {
        this.Loading = true;
        let list = this.GetSelected(this.UnstagedFiles);
        if (list.length > 0) {
            this.service.Add(list).subscribe(() => {
                this.Refresh();
            });
        }
    }
    UnstageAll() {
        this.Loading = true;
        this.service.UnstageAll().subscribe(() => {
            this.Refresh();
        });
    }
    UnstageSelected() {
        this.Loading = true;
        let list = this.GetSelected(this.StagedFiles);
        if (list.length > 0) {
            this.service.Remove(list).subscribe(() => {
                this.Refresh();
            });
        }
    }
    CheckStagedSelectedCount() {
        this.StagedSelectedCount = this.CountSelected(this.StagedFiles);
    }
    CheckUnstagedSelectedCount() {
        this.UnstagedSelectedCount = this.CountSelected(this.UnstagedFiles);
    }
    GetSelected(Data) {
        let result = [];
        for (let i = 0; i < Data.length; i++) {
            let item = Data[i];
            if (item.Selected) {
                result.push(item.FileName);
            }
        }
        return result;
    }
    CountSelected(Data) {
        let count = 0;
        for (let i = 0; i < Data.length; i++) {
            let item = Data[i];
            if (item.Selected) {
                count += 1;
            }
        }
        return count;
    }
    //#endregion
    

    
    ChangeDiffView() {
        if (this.DiffView == 'monaco') {
            this.DiffView = 'objects';
        } else {
            this.DiffView = 'monaco';
        }
    }
    //CompareFiles() {
    //    this.ObjectsTree = [];

    //    let layoutelements: TreeNode = {};
    //    layoutelements.label = this.translate.instant("@@LayoutElements");
    //    layoutelements.children = [];
    //    layoutelements.expanded = true;
    //    let workflows: TreeNode = {};
    //    workflows.label = this.translate.instant("@@Workflows");
    //    workflows.children = [];
    //    workflows.expanded = true;
    //    if (this.DocumentCompare != null) {
    //        if (this.DocumentCompare['old'] && this.DocumentCompare['new']) {
    //            //#region LayoutElements
    //            if (this.DocumentCompare['old'].Compare && this.DocumentCompare['new'].Compare) {

    //                let source = this.DocumentCompare['old'].Compare;
    //                let sourceKeys = Object.keys(this.DocumentCompare['old'].Compare);

    //                let target = this.DocumentCompare['new'].Compare;
    //                let targetKeys = Object.keys(this.DocumentCompare['new'].Compare);

    //                sourceKeys.forEach((key) => {
    //                    if (!target[key]) {
    //                        let item: TreeNode = {};
    //                        item.label = source[key].Layout._Name;
    //                        item.data = "REMOVE"
    //                        item.key = key;
    //                        layoutelements.children.push(item);
    //                    } else {
    //                        if (target[key].ParentID == source[key].ParentID &&
    //                            target[key].Position == source[key].Position &&
    //                            target[key].JSON == source[key].JSON) {
    //                        } else {
    //                            let item: TreeNode = {};
    //                            item.label = source[key].Layout._Name;
    //                            item.key = key;
    //                            layoutelements.children.push(item);
    //                        }
    //                    }
    //                })
    //                targetKeys.forEach((key) => {
    //                    if (!source[key]) {
    //                        let item: TreeNode = {};
    //                        item.label = target[key].Layout._Name;
    //                        item.data = "ADD"
    //                        item.key = key;
    //                        layoutelements.children.push(item);
    //                    }
    //                })
    //            }
    //            //#endregion
    //            //#region Workflows
    //            if (this.DocumentCompare['old'].Workflows && this.DocumentCompare['new'].Workflows) {
    //                let source = this.DocumentCompare['old'].Workflows;
    //                let sourceKeys = Object.keys(this.DocumentCompare['old'].Workflows);

    //                let target = this.DocumentCompare['new'].Workflows;
    //                let targetKeys = Object.keys(this.DocumentCompare['new'].Workflows);
    //                sourceKeys.forEach((key) => {
    //                    if (!target[key]) {
    //                        let item: TreeNode = {};
    //                        item.label = source[key].Data.Caption;
    //                        item.data = "REMOVE"
    //                        item.key = key;
    //                        workflows.children.push(item);
    //                    } else {
    //                        if (target[key].JSON == source[key].JSON) {

    //                        } else {
    //                            let item: TreeNode = {};
    //                            item.label = source[key].Data.Caption;
    //                            item.key = key;
    //                            workflows.children.push(item);
    //                        }
    //                    }
    //                })
    //                targetKeys.forEach((key) => {
    //                    if (!source[key]) {
    //                        let item: TreeNode = {};
    //                        item.label = target[key].Data.Caption;
    //                        item.data = "ADD"
    //                        item.key = key;
    //                        workflows.children.push(item);
    //                    }
    //                })
    //            }
    //            //#endregion
    //            this.ObjectsTree = [layoutelements, workflows];
    //        }
    //    }
    //}
    //#region Comparison Overlay
    ShowComparison = false;
    ComparisonSource = null;
    ComparisonModified = null;
    ComparisonType = 'layout';
    OpenComparison(node) {
        let key = node.key;
        let sourcecontent = '';
        let modifiedcontent = '';
        let change = null;
        if (this.ActiveChangeset && this.ActiveChangeset.Changes && this.ActiveChangeset.Changes[key]) {
            change = this.ActiveChangeset.Changes[key];
            if (change.Type == GitDiffType.WORKFLOW) {
                this.ComparisonType = 'workflow';
                sourcecontent = change.Source;
                modifiedcontent = change.Modified;
                if (sourcecontent) {
                    let sourcemodules = sourcecontent['Data'].Modules;
                    if (sourcemodules.length > 0) {
                        sourcemodules.forEach((module) => {

                        });
                    }
                    this.ComparisonSource = sourcecontent['Data'];
                } else {
                    this.ComparisonSource = new WorkflowData();
                }
                if (modifiedcontent) {
                    let modifiedmodules = modifiedcontent['Data'].Modules;
                    if (modifiedmodules.length > 0) {
                        modifiedmodules.forEach((module) => {

                        });
                    }
                    this.ComparisonModified = modifiedcontent['Data'];
                } else {
                    this.ComparisonModified = new WorkflowData();
                }
                this.wfService.GetAllWorkflowTemplates().subscribe(result => {
                    if (result && result.length > 0) {
                        //if (this.Data && this.Data.Modules) {
                        //    this.Data.Modules.forEach(function (mod) {
                        //        if (mod.Module == TemplateSettingsData.ModuleID && mod.Settings) {
                        //            result.some(function (r) {
                        //                if (r.Id == mod.Settings.TemplateID) {
                        //                    mod.Settings.TemplateName = r.Name;
                        //                    return true;
                        //                }
                        //                return false;
                        //            });
                        //        }
                        //    });
                        //}
                        this.EditOptions.TemplateList = result;
                    }
                    this.TemplatesLoaded = true;
                    this.cdRef.detectChanges();
                });
            } else {
                this.ComparisonType = 'layout';
                sourcecontent = change.Source ? JSON.stringify(change.Source, null, 2) : '';
                if (typeof (change.Source) == 'string') {
                    sourcecontent = change.Source;
                }
                
                modifiedcontent = change.Modified ? JSON.stringify(change.Modified, null, 2) : '';
                if (typeof (change.Modified) == 'string') {
                    modifiedcontent = change.Modified;
                }
                this.ComparisonSource = {
                    code: sourcecontent,
                    language: 'json'
                }
                this.ComparisonModified = {
                    code: modifiedcontent,
                    language: 'json'
                }
            }
        }

        this.ShowComparison = true;
        this.cdRef.detectChanges();
    }
    CloseComparison() {
        this.ComparisonSource = null;
        this.ComparisonModified = null;
        this.ShowComparison = false;
        this.cdRef.detectChanges();
    }
    //#endregion
}