import { Component, EventEmitter, Input, Output, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { UnitType } from '../../../models/enums/unittype.enum';

@Component({
    selector: 'unittypeselection',
    templateUrl: './unittypeselection.theme.control.html',
    styleUrls: ['./unitselection.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitTypeSelectionControl implements AfterViewInit {

    UnitTypes = [];
    FullUnitTypes = [];
    //#region SelectedType
    SelectedTypeValue;

    @Input()
    get SelectedType() {
        return this.SelectedTypeValue;
    }
    set SelectedType(val) {
        this.SelectedTypeValue = val;
        this.SelectedTypeChange.emit(this.SelectedTypeValue);
    }

    @Output() SelectedTypeChange = new EventEmitter<any>();
    //#endregion
    //#region RemoveTypes
    RemoveTypesValue = [UnitType.fr];

    @Input()
    get RemoveTypes() {
        return this.RemoveTypesValue;
    }
    set RemoveTypes(val) {
        this.RemoveTypesValue = val;
        this.onRemoveTypesChanged();
        this.RemoveTypesChange.emit(this.RemoveTypesValue);
    }

    @Output() RemoveTypesChange = new EventEmitter<any>();
    //#endregion
    //#region Disabled
    DisabledValue;

    @Input()
    get Disabled() {
        return this.DisabledValue;
    }
    set Disabled(val) {
        this.DisabledValue = val;
        this.DisabledChange.emit(this.DisabledValue);
    }

    @Output() DisabledChange = new EventEmitter<any>();
    //#endregion
    @Output() selectionChange = new EventEmitter<any>();
    initialized = false;

    constructor(private cdRef: ChangeDetectorRef) {
        this.FullUnitTypes = EnumHelper.GetDropdownValues(UnitType);
    }

    ngAfterViewInit(): void {
        this.initialized = true;
        this.onRemoveTypesChanged();
    }

    onRemoveTypesChanged() {
        if (this.initialized) {
            if (this.RemoveTypes && this.RemoveTypes.length > 0) {
                this.UnitTypes = this.FullUnitTypes.filter(fu => !this.RemoveTypes.some(rt => rt == fu.type));
            } else {
                this.UnitTypes = [...this.FullUnitTypes];
            }
            this.cdRef.detectChanges();
        }
    }

    getShortCap(item) {
        switch (item) {
            case UnitType.Pixel: return 'px';
            case UnitType.Percent: return '%';
            case UnitType.Auto: return 'a';
            case UnitType.Rest: return 're';
            case UnitType.em: return 'em';
            case UnitType.fr: return 'fr';
            case UnitType.vw: return 'vw';
            case UnitType.vh: return 'vh';
            case UnitType.calc: return 'calc';
            default: return item;
        }
    }

    select(item) {
        this.SelectedType = item.type;
        this.selectionChange.emit();
    }
}
