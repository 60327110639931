import { ContainerSelectDialog } from '../components/dialogs/containerSelect.dialog';
import { RelationOverviewDialogComponent } from '../components/dialogs/relation.overview.dialog';
import { TranslatedStringDialogComponent } from '../components/dialogs/translatedstring.dialog';
import { ConditionalFilterDialog } from '../components/menutabs/filtertree/conditional.filter.dialog';
import { DeleteTaskDialog } from '../components/objectcreator/helper/delete.task.dialog';
import { ReplaceElementDialog } from '../components/objectcreator/helper/replace.element.dialog';

export const MAC_DIALOGS = [
    ContainerSelectDialog,
    RelationOverviewDialogComponent,
    TranslatedStringDialogComponent,
    DeleteTaskDialog,
    ReplaceElementDialog,
    ConditionalFilterDialog
];
