import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { RestService } from '../../../../../services/rest.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../../../base.list.settings';
import { ContainerRest } from '../../../models/rest.container.classobject';
import { TemplateRestDetail } from './template.rest.detail';

@Component({
    selector: 'template-rest',
    templateUrl: '../../../../base.list.settings.html'
})
export class TemplateRestComponent extends BaseListSettings {

    constructor(private _service: RestService, private _translate: TranslateService, factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this._service.loadTemplates(this.InitArgs.SID).subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r =>
                    list.push({
                        Caption: r.Caption,
                        ID: r.Value
                    }));
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.loadTemplate(data).subscribe(result => {
            if (result)
                this.setSelectedItem(new ContainerRest(result));
        });
    }

    getNewItem() {
        const retVal = new ContainerRest();
        retVal.Name = this._translate.instant('@@Neue Vorlage');
        retVal.RestID = this.InitArgs.SID;
        retVal.IsTemplate = true;
        return retVal;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Vorlage \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Vorlage \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Vorlage loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._service.remove(data).subscribe(() => handler());
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Vorlage \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Vorlage speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        this._service.saveTemplate(item).subscribe(r => {
            if (r)
                handler(new ContainerRest(r), (r as any).SID, r.Name);
        });
    }

    getContentType() {
        return TemplateRestDetail;
    }
}