import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';
import { SingleFeatureModelSettingsData } from '../singlefeaturemodel/single.feature.model.settings';

@Component({
    selector: 'wf-single-feature-prediction-settings',
    templateUrl: './single.feature.prediction.settings.html',
    styleUrls: ['./single.feature.prediction.settings.css']
})
export class SingleFeaturePredictionSettings extends WorkflowDialogContent {
    Data = new SingleFeaturePredictionSettingsData();
    Columns = [];
    HasDateCol = false;
    SubGroupingDisabled = true;

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'singleFeaturePredictionWFModule';
        reg.Caption = '@@Single feature prediction';
        reg.GroupID = 'aioperations';
        reg.Index = 210;
        reg.SettingsControl = SingleFeaturePredictionSettings;
        reg.SettingsTypeHelper = new SingleFeaturePredictionSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private translate: TranslateService) {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SingleFeaturePredictionSettingsData, json);
        }
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource) {
            const list = [];
            let groupCol, subGroupCol;
            dataSource.Columns.forEach(col => {
                const val = {
                    Name: col.Name,
                    DateCol: col.Name === this.Data.DateColumn,
                    ValCol: col.Name === this.Data.ValueColumn,
                    CatCol: this.Data.CatColumns && this.Data.CatColumns.some(cc => cc === col.Name),
                    Grouping: false,
                    SubGrouping: false
                };
                if (this.Data.Grouping && col.Name === this.Data.Grouping) {
                    groupCol = val;
                }
                if (this.Data.SubGrouping && col.Name === this.Data.SubGrouping) {
                    subGroupCol = val;
                }
                if (val.DateCol) {
                    this.HasDateCol = true;
                }
                list.push(val);
            });
            if (groupCol && groupCol.CatCol) {
                groupCol.Grouping = true;
                this.SubGroupingDisabled = false;
                if (subGroupCol && subGroupCol.CatCol && !subGroupCol.Grouping) {
                    subGroupCol.SubGrouping = true;
                }
            }
            this.Columns = list;
        }
    }

    getResult() {
        let dateCol, valCol, groupCol, subGroupCol;
        const catCols = [];
        this.Columns.forEach(c => {
            if (c.DateCol) {
                dateCol = c.Name;
            }
            if (c.ValCol) {
                valCol = c.Name;
            }
            if (c.CatCol) {
                catCols.push(c.Name);
                if (c.Grouping) {
                    groupCol = c.Name;
                } else if (c.SubGrouping) {
                    subGroupCol = c.Name;
                }
            }
        });
        this.Data.DateColumn = dateCol;
        this.Data.ValueColumn = valCol;
        this.Data.CatColumns = catCols;
        this.Data.Grouping = groupCol;
        if (groupCol && subGroupCol) {
            this.Data.SubGrouping = subGroupCol;
        } else {
            this.Data.SubGrouping = null;
        }
        if (!this.Data.DateColumn) {
            this.Data.Augments = 0;
            this.Data.Rounding = null;
        }
        if (typeof this.Data.StartTime === 'number' && typeof this.Data.EndTime === 'number') {
            if (this.Data.StartTime < 0) {
                this.Data.StartTime = 0;
            } else if (this.Data.StartTime > 22) {
                this.Data.StartTime = 22;
            }
            if (this.Data.EndTime <= this.Data.StartTime) {
                this.Data.EndTime = this.Data.StartTime + 1;
            } else if (this.Data.EndTime > 23) {
                this.Data.EndTime = 23;
            }
        } else {
            this.Data.StartTime = null;
            this.Data.EndTime = null;
        }
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Columns.length > 0) {
            let hasDateCol = false;
            let hasValCol = false;
            this.Columns.some(x => {
                if (x.DateCol) {
                    hasDateCol = true;
                }
                if (x.ValCol) {
                    hasValCol = true;
                }
                return hasDateCol && hasValCol;
            });
            if (!hasDateCol || !hasValCol || !this.Data.ModelKey) {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte setzen Sie Datumsspalte, Wertspalte und ModelKey';
            } else if (!this.Data.UseSteps && (!this.Data.StartDate || !this.Data.EndDate)) {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte setzen Sie ein Start- und Enddatum';
            }
        }
        return retVal;
    }

    GetExpressionProperties() {
        return [
            {
                Caption: this.translate.instant('@@Datumsspalte'),
                Value: 'DateColumn'
            },
            {
                Caption: this.translate.instant('@@Wertspalte'),
                Value: 'ValueColumn'
            },
            {
                Caption: this.translate.instant('@@Datums-Ebene'),
                Value: 'Rounding'
            },
            {
                Caption: this.translate.instant('@@Zusaetzliche Spalten'),
                Value: 'Augments'
            },
            {
                Caption: this.translate.instant('@@Datenzeitraum in Tagen'),
                Value: 'Days'
            },
            {
                Caption: this.translate.instant('@@Vorhersagezeitraum in Tagen'),
                Value: 'DaysOut'
            },
            {
                Caption: this.translate.instant('@@Steps verwenden'),
                Value: 'UseSteps'
            },
            {
                Caption: this.translate.instant('@@Steps'),
                Value: 'Steps'
            },
            {
                Caption: this.translate.instant('@@Kategorie'),
                Value: 'CatColumns'
            },
            {
                Caption: this.translate.instant('@@Gruppierung'),
                Value: 'Grouping'
            },
            {
                Caption: this.translate.instant('@@Untergruppierung'),
                Value: 'SubGrouping'
            },
            {
                Caption: this.translate.instant('@@Anfangsdatum'),
                Value: 'StartDate'
            },
            {
                Caption: this.translate.instant('@@Enddatum'),
                Value: 'EndDate'
            },
            {
                Caption: this.translate.instant('@@Zeittakt'),
                Value: 'Delta'
            },
            {
                Caption: this.translate.instant('@@Beginn (Stunde)'),
                Value: 'StartTime'
            },
            {
                Caption: this.translate.instant('@@Ende (Stunde)'),
                Value: 'EndTime'
            },
            {
                Caption: this.translate.instant('@@ModelEstimators'),
                Value: 'ModelEstimators'
            },
            {
                Caption: this.translate.instant('@@ModelDepth'),
                Value: 'ModelDepth'
            },
            {
                Caption: this.translate.instant('@@Land'),
                Value: 'RegionSetting.Country'
            },
            {
                Caption: this.translate.instant('@@Region'),
                Value: 'RegionSetting.Region'
            }
        ];
    }

    stepsChanged(ev) {
        if (ev && ev.value && this.Data) {
            this.Data.Steps = ev.value;
        }
    }

    dateColChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.DateCol = false);
            col.DateCol = true;
            this.HasDateCol = true;
        } else {
            this.HasDateCol = false;
        }
    }

    valColChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.ValCol = false);
            col.ValCol = true;
        }
    }

    catColChanged(ev, col) {
        if (!ev || !ev.checked) {
            if (col.Grouping) {
                col.Grouping = false;
                this.Columns.forEach(c => c.SubGrouping = false);
                this.SubGroupingDisabled = true;
            }
            if (col.SubGrouping) {
                col.SubGrouping = false;
            }
        }
    }

    groupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.Grouping = false);
            col.Grouping = true;
            col.SubGrouping = false;
            this.SubGroupingDisabled = false;
        } else {
            this.Columns.forEach(c => c.SubGrouping = false);
            this.SubGroupingDisabled = true;
        }
    }

    subGroupingChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(c => c.SubGrouping = false);
            col.SubGrouping = true;
        }
    }

    checkStartEnd(isStart) {
        if (this.Data && typeof this.Data.StartTime === 'number' && typeof this.Data.EndTime === 'number'
            && this.Data.StartTime >= this.Data.EndTime) {
            if (isStart) {
                this.Data.StartTime = this.Data.EndTime - 1;
            } else {
                this.Data.EndTime = this.Data.StartTime + 1;
            }
        }
    }

    estimatorsChanged(ev) {
        if (ev && ev.value) {
            this.Data.ModelEstimators = ev.value;
        }
    }

    depthChanged(ev) {
        if (ev && ev.value) {
            this.Data.ModelDepth = ev.value;
        }
    }
}

// @dynamic
export class SingleFeaturePredictionSettingsData extends SingleFeatureModelSettingsData {
    UseSteps = true;
    Steps = 50;
    ModelKey: string;
    StartDate;
    EndDate;
    Delta: string;
    StartTime = 0;
    EndTime = 23;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.SingleFeaturePrediction.SingleFeaturePredictionSettingsData';
    }
}

export class SingleFeaturePredictionSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new SingleFeaturePredictionSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const ds = state.get('DataSource');
            if (ds) {
                ds.Columns = [
                    {
                        Name: 'Category',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Subcategory',
                        DataTyp: 'System.String'
                    },
                    {
                        Name: 'Date',
                        DataTyp: 'System.DateTime'
                    },
                    {
                        Name: 'Predict',
                        DataTyp: 'System.Double'
                    },
                    {
                        Name: 'Accuracy',
                        DataTyp: 'System.Double'
                    }
                ];
            }
        }
    }
}
