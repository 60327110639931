import { ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { DimensionType, LevelType } from '../../../models/enums';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { AttributeChooserDialog } from '../dialogs/attribute.chooser.dialog';
import { ContainerAttributeEditDialog } from '../dialogs/container.attribute.edit.dialog';
import { RelQueryDefDialog } from '../dialogs/rel.query.def.dialog';
import { RelatedAttributeDialog } from '../dialogs/related.attributes.dialog';

@Component({
    selector: 'containerdimension-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerDimensionSettings extends BaseListSettings {

    private static GetContainerInfos(attributes) {
        const retVal = {
            KeyAttributes: 0,
            KeyAttr: null,
            AllKeysSet: true
        };
        attributes.forEach((attr) => {
            if (attr.IsKey) {
                retVal.KeyAttributes++;
                retVal.KeyAttr = attr;
            }
            if (typeof attr.KeyContainerElements !== 'number' || attr.KeyContainerElements < 0) {
                retVal.AllKeysSet = false;
            }
        });
        return retVal;
    }

    private static HasCircles(attributes: any[]): boolean {
        let circle = false;
        if (attributes) {
            const attrDict = new Map();
            attributes.forEach(attr => {
                attrDict.set(attr.ID, attr);
            });
            attributes.some(attr => {
                if (attr.RelatedAttributes) {
                    attr.RelatedAttributes.some(ra => {
                        circle = ContainerDimensionSettings.CheckSet(attrDict, new Set([attr.ID]), ra);
                        return circle;
                    });
                }
                return circle;
            });
        }
        return circle;
    }

    private static CheckSet(attrDict: Map<string, any>, set: Set<string>, id: string): boolean {
        if (set.has(id)) {
            return true;
        }
        let retVal = false;
        set.add(id);
        const attr = attrDict.get(id);
        if (attr && attr.RelatedAttributes) {
            attr.RelatedAttributes.some(ra => {
                retVal = ContainerDimensionSettings.CheckSet(attrDict, new Set(set.values()), ra);
                return retVal;
            });
        }
        return retVal;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerDimension',
            ID: 'containerdimension',
            Icon: 'table_chart',
            Index: 10,
            Parent: 'multidimensional',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.MultidimensionalContainerRight',
                Value: 2
            },
            Content: ContainerDimensionSettings
        };
    }

    constructor(private translate: TranslateService, private dataModelService: DataModelService,
        private settingsService: DataSettingsService, protected factoryResolver: ComponentFactoryResolver,
        protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerDimensionDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.setTableInfos([]);
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Multidimensional.ContainerDimension').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach((item) => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            this.settingsService.GetAllTableInfos(this.InitArgs.SID).subscribe((result) => {
                if (result && comp) {
                    comp.setTableInfos(result);
                }
            });
            const query = new DataSourceQuery(this.InitArgs.SID);
            query.DBUsages = [[268435456]]; // QueryEngine
            query.ResourceBase = 0; // Database
            this.dataModelService.GetDataSourcesByQuery(query).subscribe(ds => {
                if (ds) {
                    comp.DataSources = ds;
                }
            });
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((cd) => {
                if (cd) {
                    this.setSelectedItem(cd);
                }
            });
        }
    }

    getNewItem() {
        let dsID = null;
        if (this.Component && this.Component.DataSources && this.Component.DataSources.length > 0) {
            dsID = this.Component.DataSources[0].SID;
        }
        const name = this.translate.instant('@@Neue Dimension');
        return {
            DataModelID: this.InitArgs.SID,
            Name: name,
            DataSourceId: dsID,
            Attributes: [],
            Hierarchies: [],
            IsEditable: false,
            HasUnknownMember: false,
            Type: DimensionType.Other,
            IsCaseSensitive: false,
            SourceQuery: {
                Columns: [],
                Relations: []
            }
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Dimension \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Dimension \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Dimension loeschen');
        return retVal;
    }

    delete(data) {
        this.dataModelService.DeleteContainer(data).subscribe();
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Dimension \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Dimension speichern');
        return retVal;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const dim = this.Component.SelectedItem;
        if (dim) {
            const errorList = [];
            const warnings = [];
            if (!dim.DataSourceId) {
                errorList.push(this.translate.instant('@@Fehlende Datenquelle'));
            }
            if (dim.Attributes && dim.Attributes.length > 0) {
                const info = ContainerDimensionSettings.GetContainerInfos(dim.Attributes);
                if (info.KeyAttributes === 0) {
                    errorList.push(this.translate.instant('@@Kein Key-Attribut gesetzt'));
                } else if (info.KeyAttributes > 1) {
                    info.KeyAttr = null;
                    errorList.push(this.translate.instant('@@Mehr als ein Key-Attribut gesetzt'));
                }
                if (!info.AllKeysSet) {
                    errorList.push(this.translate.instant('@@Fehlende Keyspalte'));
                }

                // Relationen auf Zirkel pr�fen
                const circle = ContainerDimensionSettings.HasCircles(dim.Attributes);
                if (circle) {
                    errorList.push(this.translate.instant('@@Zirkelbezug in den Relationen'));
                }

                // pruefen, ob KeyAttribut Beziehung zu allen anderen hat
                if (info.KeyAttr && dim.Attributes.length > 1) {
                    if (!info.KeyAttr.RelatedAttributes) {
                        const list = [];
                        dim.Attributes.forEach((attr) => {
                            if (attr !== info.KeyAttr) {
                                list.push(attr.ID);
                            }
                        });
                        info.KeyAttr.RelatedAttributes = list;
                        warnings.push(this.translate.instant('@@Fehlende Relationen des Key-Attributs wurden automatisch ergaenzt'));
                    } else {
                        let added = false;
                        dim.Attributes.forEach((attr) => {
                            if (attr !== info.KeyAttr && !info.KeyAttr.RelatedAttributes.some(ra => ra === attr.ID)) {
                                info.KeyAttr.RelatedAttributes.push(attr.ID);
                                added = true;
                            }
                        });
                        if (added) {
                            warnings.push(this.translate.instant('@@Fehlende Relationen des Key-Attributs wurden automatisch ergaenzt'));
                        }
                    }
                }

                if (dim.Hierarchies && dim.Hierarchies.length > 0) {
                    // Hierarchien gegen Relationen abgleichen
                    const attrDict = new Map();
                    dim.Attributes.forEach(attr => {
                        attrDict.set(attr.ID, attr);
                    });

                    let missingAttr = false;
                    let wrongHierRel = false;
                    let noHierRel = false;
                    dim.Hierarchies.forEach(hier => {
                        for (let i = 0; i < hier.Attributes.length - 1; i++) {
                            const first = hier.Attributes[i];
                            const second = hier.Attributes[i + 1];
                            if (!first.IsAll && !second.IsAll) {
                                const secAttr = attrDict.get(second.Attribute);
                                if (secAttr) {
                                    const firstAttr = attrDict.get(first.Attribute);
                                    if (firstAttr) {
                                        if (!secAttr.RelatedAttributes || !secAttr.RelatedAttributes.some(ra => ra === firstAttr.ID)) {
                                            noHierRel = true;
                                        }
                                        if (firstAttr.RelatedAttributes && firstAttr.RelatedAttributes.some(ra => ra === secAttr.ID)) {
                                            wrongHierRel = true;
                                        }
                                    } else {
                                        missingAttr = true;
                                    }
                                } else {
                                    missingAttr = true;
                                }
                            }
                        }
                    });
                    if (missingAttr) {
                        errorList.push(this.translate.instant('@@Hierarchie-Attribut ohne zugehoeriges Attribut'));
                    }
                    if (wrongHierRel) {
                        errorList.push(this.translate.instant('@@Fehlerhafte Relationen zwischen Hierarchie-Ebenen'));
                    }
                    if (noHierRel) {
                        warnings.push(this.translate.instant('@@Hierarchie-Ebenen sollten Relationen besitzen'));
                    }
                }
            } else {
                if (dim.Hierarchies && dim.Hierarchies.length > 0) {
                    // Wenn keine Attribute gesetzt sind, aber Hierarchien vorhanden sind, fragen, ob die weg sollen
                    const deleteHiers = await MessageBoxHelper.ShowDialog(
                        new TranslateFormatText('@@Die Dimension enthaelt keine Attribute. Wollen Sie die leeren Hierarchien entfernen?'),
                        new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                    if (deleteHiers === MessageBoxResult.Yes) {
                        dim.Hierarchies = [];
                    } else {
                        // Attribute entfernen
                        dim.Hierarchies.forEach(hier => {
                            if (hier.Attributes) {
                                for (let i = 0; i < hier.Attributes.length; i++) {
                                    if (!hier.Attributes[i].IsAll) {
                                        hier.Attributes.splice(i, 1);
                                        i--;
                                    }
                                }
                            }
                        });
                    }
                }
            }
            if (errorList.length > 0) {
                retVal.IsCorrect = false;
                retVal.Error = errorList.concat(warnings).join('\n');
            } else if (retVal.IsCorrect && warnings.length > 0) {
                const text = new TranslateFormatText('@@Bitte ueberpruefen Sie folgende Einstellungen:\n{0}');
                text.FormatParams.push(warnings.join('\n'));
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            if (item.Attributes) {
                item.Attributes.forEach((attr) => {
                    // Wenn Key und NameSpalte gleich, NameSpalte entfernen (laut SW)
                    if (attr.KeyContainerElements === attr.NameContainerElements) {
                        attr.NameContainerElements = -1;
                    }
                });
            }
            if (item.Hierarchies) {
                // Pruefen, ob Hierarchie All-Attribut an erster Stelle hat
                item.Hierarchies.forEach(hier => {
                    if (hier.Attributes && hier.Attributes.length > 0 && !hier.IsCreatedParentChild) {
                        const first = hier.Attributes[0];
                        if (!first.IsAll) {
                            let all;
                            for (let i = 1; i < hier.Attributes.length; i++) {
                                if (hier.Attributes[i].IsAll) {
                                    all = hier.Attributes[i];
                                    hier.Attributes.splice(i, 1);
                                    break;
                                }
                            }
                            if (!all) {
                                all = {
                                    ID: UUID.UUID(),
                                    Attribute: null,
                                    IsAll: true
                                };
                            }
                            hier.Attributes.splice(0, 0, all);
                        }
                    }
                });
            }
            this.settingsService.SaveContainerDimension(item).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerdimension-detail',
    templateUrl: './containerdimension.settings.html',
    styleUrls: ['./containerdimension.settings.css']
})
export class ContainerDimensionDetail extends ADataModelDetail {
    SelectedHierarchy;
    //DimensionTypes;

    private tableInfos = [];
    private treeNodes = [];
    private tableCaptions = new Map();
    @ViewChild('attrInput') attrInput: ElementRef<HTMLInputElement>;
    attrCtrl = new UntypedFormControl();
    filteredAttributes: Observable<any[]>;
    separatorKeysCodes: number[] = [ENTER];
    DraggedIndex;
    DataSources = [];
    Rows = [];
    IsTimeDimension = false;

    constructor(private translate: TranslateService, private settingsService: DataSettingsService) {
        super();
        //this.DimensionTypes = EnumHelper.GetDropdownValues(DimensionType);
        this.filteredAttributes = this.attrCtrl.valueChanges.pipe(startWith(null),
            map((attr: string | null) => attr ? this._filter(attr) : this.getHierAttributes()));
    }

    setSelectedItem(item: any): void {
        this.SelectedHierarchy = null;
        let isTimeDim = false;
        const rows = [];
        if (item) {
            if (item.Attributes) {
                item.Attributes.forEach(att => {
                    const row = this.fillAttribute({}, att);
                    rows.push(row);
                });
            }
            isTimeDim = item.Type == DimensionType.Time;
        }
        this.IsTimeDimension = isTimeDim;
        this.Rows = rows;
        super.setSelectedItem(item);
        this.updateTree();
    }

    fillAttribute(row, attr) {
        row.ID = attr.ID;
        row.Name = attr.Name;
        row.Caption = TranslatedString.GetTranslation(attr.Caption);
        row.KeyColumn = this.tableCaptions.get(attr.KeyContainerElements);
        row.NameColumn = this.tableCaptions.get(attr.NameContainerElements);
        row.ParentColumn = this.tableCaptions.get(attr.ParentContainerElements);
        if (typeof attr.Type === 'number') {
            row.Type = LevelType[attr.Type];
        }
        row.IsKey = attr.IsKey ? 'done' : '';
        return row;
    }

    setTableInfos(ti) {
        this.tableInfos = ti;
        this.updateTree();
    }

    private updateTree() {
        const data = [];
        const captions = new Map();
        if (this.tableInfos && this.SelectedItem && this.SelectedItem.SourceQuery) {
            let index = 0;
            this.SelectedItem.SourceQuery.Columns.forEach(c => {
                const ti = this.tableInfos.find(t => t.SID === c.ContainerID);
                if (ti) {
                    const fi = ti.Fields.find(x => x.SID === c.FieldID);
                    if (fi) {
                        let parentNode = data.find(x => x.UniqueID === c.ContainerUniqueID);
                        if (!parentNode) {
                            parentNode = new AttributeNode(index++);
                            parentNode.Caption = ti.Caption + ' (' + c.ContainerUniqueID + ')';
                            parentNode.UniqueID = c.ContainerUniqueID;
                            parentNode.HasChildren = true;
                            parentNode.Children = [];
                            data.push(parentNode);
                        }
                        const childNode = new AttributeNode(index++);
                        childNode.Caption = fi.Caption + ' (' + c.UniqueID + ')';
                        childNode.UniqueID = c.UniqueID;
                        childNode.FullCaption = parentNode.Caption + ' -> ' + childNode.Caption;
                        childNode.DataType = fi.DataType;
                        captions.set(c.UniqueID, childNode.FullCaption);
                        parentNode.Children.push(childNode);
                    }
                }
            });
        }
        this.treeNodes = data;
        this.tableCaptions = captions;
    }

    addAttribute() {
        const sel = this.SelectedItem;
        if (sel && sel.Attributes) {
            const name = this.translate.instant('@@Neues Attribut');
            const attr = {
                ID: UUID.UUID(),
                Name: name,
                KeyContainerElements: -1,
                NameContainerElements: -1,
                ParentContainerElements: -1,
                RollUpContainerElements: -1,
                UnaryContainerElements: -1,
                RelatedAttributes: [],
                NameTemplate: [],
                Type: LevelType.Regular,
                IsKey: false,
                TranslatedCaption: name
            };
            this.showDialog(attr, at => {
                const row = this.fillAttribute({}, at);
                this.Rows.push(row);
                sel.Attributes.push(at);
            });
        }
    }

    addMultiAttributes() {
        const sel = this.SelectedItem;
        if (sel && sel.SourceQuery && this.tableInfos) {
            const args = {
                Columns: [...sel.SourceQuery.Columns],
                TableInfos: [...this.tableInfos]
            };
            BaseDialog.ShowDialog({
                ContentType: AttributeChooserDialog,
                InitArgs: args,
                Title: '@@Attribute auswaehlen',
                Handler: (r) => {
                    if (r) {
                        r.forEach(x => {
                            const attr = {
                                ID: UUID.UUID(),
                                Name: x.Name,
                                KeyContainerElements: x.ID,
                                NameContainerElements: -1,
                                ParentContainerElements: -1,
                                RollUpContainerElements: -1,
                                UnaryContainerElements: -1,
                                RelatedAttributes: [],
                                NameTemplate: [],
                                Type: LevelType.Regular,
                                IsKey: false,
                                TranslatedCaption: x.Name,
                                DataTyp: x.DataType
                            };
                            sel.Attributes.push(attr);
                            const row = this.fillAttribute({}, attr);
                            this.Rows.push(row);
                        });
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    editAttribute(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Attributes) {
            let attrIndex, attr;
            if (selected.Attributes.some((x, i) => {
                if (x.ID === row.ID) {
                    attrIndex = i;
                    attr = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(attr, (at) => {
                    this.fillAttribute(row, at);
                    selected.Attributes.splice(attrIndex, 1, at);
                });
            }
        }
    }

    showDialog(attr, handler) {
        const selected = this.SelectedItem;
        if (selected) {
            BaseDialog.ShowDialog({
                ContentType: ContainerAttributeEditDialog,
                InitArgs: {
                    TreeNodes: this.treeNodes,
                    Attribute: attr
                },
                Title: '@@Attribut bearbeiten',
                Handler: (at) => {
                    if (at) {
                        if (typeof at.KeyContainerElements !== 'number' || at.KeyContainerElements < 0) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie eine Key-Spalte'),
                                new TranslateFormatText('@@Fehlende Key-Spalte'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            return false;
                        }
                        if (at.IsKey === true) {
                            selected.Attributes.forEach(x => x.IsKey = false);
                            this.Rows.forEach(x => x.IsKey = '');
                        }
                        at.TranslatedCaption = TranslatedString.GetTranslation(at.Caption);
                        if (!at.TranslatedCaption) {
                            at.TranslatedCaption = at.Name;
                        }
                        handler(at);
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    deleteAttribute(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Attributes) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Attribut {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Attribut loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let attrIndex = -1;
                        let attr;
                        if (selected.Attributes.some((a, i) => {
                            if (a.ID === row.ID) {
                                attrIndex = i;
                                attr = a;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Attributes.splice(attrIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                            this.OnItemChanged();
                            // Hierarchien und Relationen korrigieren
                            if (selected.Hierarchies) {
                                selected.Hierarchies.forEach((hier) => {
                                    if (hier.Attributes) {
                                        for (let i = 0; i < hier.Attributes.length; i++) {
                                            if (hier.Attributes[i].Attribute === attr.ID) {
                                                hier.Attributes.splice(i, 1);
                                                i--;
                                            }
                                        }
                                    }
                                });
                            }
                            selected.Attributes.forEach((dimAttr) => {
                                if (dimAttr.RelatedAttributes) {
                                    for (let i = 0; i < dimAttr.RelatedAttributes.length; i++) {
                                        if (dimAttr.RelatedAttributes[i] === attr.ID) {
                                            dimAttr.RelatedAttributes.splice(i, 1);
                                            i--;
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
        }
    }

    addHierarchy() {
        if (this.SelectedItem) {
            const name = this.translate.instant('@@Neue Hierarchie');
            const hier = {
                ID: UUID.UUID(),
                Name: name,
                Caption: new TranslatedString(name),
                Attributes: [],
                TranslatedCaption: name
            };
            this.SelectedItem.Hierarchies.push(hier);
            this.SelectedHierarchy = hier;
            this.OnItemChanged();
        }
    }

    deleteHierarchy() {
        if (this.SelectedItem && this.SelectedHierarchy) {
            this.SelectedItem.Hierarchies.splice(this.SelectedItem.Hierarchies.indexOf(this.SelectedHierarchy), 1);
            this.SelectedHierarchy = null;
            this.OnItemChanged();
        }
    }

    hierCaptionChanged() {
        if (this.SelectedHierarchy) {
            this.SelectedHierarchy.TranslatedCaption = TranslatedString.GetTranslation(this.SelectedHierarchy.Caption);
            this.OnItemChanged();
        }
    }

    getAttributeCaption(id) {
        let retVal = '';
        if (this.SelectedItem && this.SelectedItem.Attributes) {
            this.SelectedItem.Attributes.some((attr) => {
                if (attr.ID === id) {
                    retVal = attr.TranslatedCaption;
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }

    addRelation() {
        if (this.SelectedItem && this.SelectedItem.Attributes) {
            const attributes = this.SelectedItem.Attributes;
            BaseDialog.ShowDialog({
                ContentType: RelatedAttributeDialog,
                InitArgs: attributes,
                Handler: (retVal) => {
                    if (retVal && retVal.LeftAttr && retVal.RightAttr) {
                        attributes.some((la) => {
                            if (la.ID === retVal.LeftAttr) {
                                la.RelatedAttributes.push(retVal.RightAttr);
                                return true;
                            }
                            return false;
                        });
                    }
                    return true;
                },
                Title: '@@RelatedAttributes'
            });
        }
    }

    getOtherAttributes(attr, rel) {
        const retVal = [];
        if (this.SelectedItem && this.SelectedItem.Attributes) {
            this.SelectedItem.Attributes.forEach((da) => {
                if (da !== attr && (rel === da.ID || !attr.RelatedAttributes || !attr.RelatedAttributes.some(ra => ra === da.ID))) {
                    retVal.push(da);
                }
            });
        }
        return retVal;
    }

    setRelAttr(ev, attr, index) {
        if (ev && ev.value && attr && attr.RelatedAttributes) {
            attr.RelatedAttributes.splice(index, 1, ev.value);
        }
    }

    removeRelation(attr, index) {
        if (attr && attr.RelatedAttributes) {
            attr.RelatedAttributes.splice(index, 1);
        }
    }

    dragStart(event, item) {
        this.DraggedIndex = item;
    }

    dragEnd(event) {
        this.DraggedIndex = null;
    }

    drop(ev) {
        if (typeof this.DraggedIndex === 'number' && this.DraggedIndex > 0 &&
            ev && ev.currentTarget && ev.currentTarget.nodeName === 'MAT-CHIP' && ev.currentTarget.parentNode &&
            this.SelectedHierarchy && this.SelectedHierarchy.Attributes) {
            let targetIndex = 0;
            for (let i = 0; i < ev.currentTarget.parentNode.children.length; i++) {
                const child = ev.currentTarget.parentNode.children[i];
                if (child.nodeName === 'MAT-CHIP') {
                    if (child === ev.currentTarget) {
                        if (ev.offsetY / child.clientHeight > 0.5) {
                            targetIndex++;
                        }
                        if (targetIndex < this.DraggedIndex) {
                            const attr = this.SelectedHierarchy.Attributes[this.DraggedIndex];
                            this.SelectedHierarchy.Attributes.splice(this.DraggedIndex, 1);
                            this.SelectedHierarchy.Attributes.splice(targetIndex, 0, attr);
                        } else if (targetIndex > this.DraggedIndex) {
                            const attr = this.SelectedHierarchy.Attributes[this.DraggedIndex];
                            this.SelectedHierarchy.Attributes.splice(this.DraggedIndex, 1);
                            this.SelectedHierarchy.Attributes.splice(targetIndex - 1, 0, attr);
                        }
                        break;
                    }
                    targetIndex++;
                }
            }
        }
    }

    attrSelected(ev) {
        if (ev && ev.option && ev.option.value && this.SelectedHierarchy && this.SelectedHierarchy.Attributes) {
            this.SelectedHierarchy.Attributes.push({
                ID: UUID.UUID(),
                Attribute: ev.option.value,
                IsAll: false
            });
            this.attrInput.nativeElement.value = '';
            this.attrCtrl.setValue(null);
            this.OnItemChanged();
        }
    }

    addHierAttr(ev) {
        if (ev && ev.value && this.SelectedHierarchy && this.SelectedHierarchy.Attributes) {
            const filtered = this._filter(ev.value);
            if (filtered.length === 1) {
                this.SelectedHierarchy.Attributes.push({
                    ID: UUID.UUID(),
                    Attribute: filtered[0].ID,
                    IsAll: false
                });
                this.attrInput.nativeElement.value = '';
                this.attrCtrl.setValue(null);
                this.OnItemChanged();
            }
        }
    }

    removeAttr(i) {
        if (this.SelectedHierarchy && this.SelectedHierarchy.Attributes) {
            this.SelectedHierarchy.Attributes.splice(i, 1);
            this.attrCtrl.setValue(null);
            this.OnItemChanged();
        }
    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.getHierAttributes().filter(attr => attr.TranslatedCaption.toLowerCase().indexOf(filterValue) >= 0);
    }

    getHierAttributes(): any[] {
        const retVal = [];
        if (this.SelectedItem && this.SelectedItem.Attributes) {
            const used = [];
            if (this.SelectedHierarchy && this.SelectedHierarchy.Attributes) {
                this.SelectedHierarchy.Attributes.forEach((hierAttr) => {
                    used.push(hierAttr.Attribute);
                });
            }
            this.SelectedItem.Attributes.forEach((attr) => {
                if (!used.some(u => attr.ID === u)) {
                    retVal.push(attr);
                }
            });
        }
        return retVal;
    }

    //processDimension() {
    //    const selected = this.SelectedItem;
    //    if (selected && selected.SID) {
    //        NotificationHelper.Info("Process Dimension gestartet.", "Information");
    //        this.settingsService.Process(selected.DataSourceId, selected.SID).subscribe(() => {
    //            NotificationHelper.Info("Process Dimension beendet.", "Information");
    //        });
    //    }
    //}

    editQueryDef() {
        const selected = this.SelectedItem;
        if (selected && selected.SourceQuery) {
            const usedIDs = [];
            selected.Attributes.forEach(attr => {
                if (typeof attr.KeyContainerElements === 'number' && attr.KeyContainerElements > 0) {
                    usedIDs.push(attr.KeyContainerElements);
                }
                if (typeof attr.NameContainerElements === 'number' && attr.NameContainerElements > 0) {
                    usedIDs.push(attr.NameContainerElements);
                }
                if (typeof attr.ParentContainerElements === 'number' && attr.ParentContainerElements > 0) {
                    usedIDs.push(attr.ParentContainerElements);
                }
                if (attr.TranslationNameContainerElements) {
                    const keys = Object.keys(attr.TranslationNameContainerElements);
                    keys.forEach(k => {
                        const val = attr.TranslationNameContainerElements[k];
                        if (typeof val === 'number' && val > 0) {
                            usedIDs.push(val);
                        }
                    });
                }
            });
            RelQueryDefDialog.ShowDialog(selected.SourceQuery, selected.DataModelID, usedIDs, r => {
                if (r) {
                    selected.SourceQuery = r;
                    this.OnItemChanged();
                    this.updateTree();
                }
            });
        }
    }

    dimTypeChanged() {
        if (this.SelectedItem) {
            this.SelectedItem.Type = this.IsTimeDimension ? DimensionType.Time : DimensionType.Other;
            this.OnItemChanged();
        }
    }
}

export class AttributeNode extends ABaseTreeNode {
    UniqueID;
    FullCaption;
    DataType;
}
