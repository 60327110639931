import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateFormatText} from "../../helpers/array.helpers";
import {MessageBoxButtons} from "../../models/enums/messageboxbuttons.enum";
import {MessageBoxIcon} from "../../models/enums/messageboxicon.enum";
import {MessageBoxResult} from "../../models/enums/messageboxresult.enum";
import {LayoutService} from "../../services/layout.service";
import {NotificationHelper} from "../../helpers/notification.helper";
import {MessageBoxHelper} from "../../components/dialogs/messagebox/messagebox.dialog";
import {RolesService} from "../../services/roles.service";

@Component({
    selector: 'user-roles-settings',
    templateUrl: './user-roles.settings.html',
    styleUrls: ['./user-roles.settings.css']
})
export class UserRoleSettings implements OnInit {

    // static GetSettingsEntry() {
    //     return {
    //         Caption: '@@User Roles',
    //         ID: 'user-roles',
    //         Icon: 'security',
    //         Index: 2,
    //         Parent: 'userCont',
    //         Security: {
    //             Name: 'evidanza.App.Shared.Security.UsermanagementRight',
    //             Value: 8
    //         },
    //         Content: UserRoleSettings
    //     };
    // }

    toggleWindow = true;
    roles = [];
    rolesList = [];
    SearchValue: string;

    SelectedItem: any = {
        SID: '',
        Name: '',
        CapsuleTag: '',
        IsAdministrator: false,
        Everyone: false
    }

    constructor(private service: RolesService, private cdRef: ChangeDetectorRef) {
    }


    ngOnInit(): void {
        this.GetUserRoles();
    }

    GetUserRoles() {
        this.loading(true);
        this.roles = [];
        this.rolesList = [];
        this.service.GetUserRoles().subscribe(res => {
            if (res) {
                this.roles = res;
                this.onSearch();
            }
            this.loading(false);
        });
    }

    onSearch() {
        let list = [];
        if (this.roles) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.roles.filter(x => x.Name.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.roles);
            }
        }
        this.rolesList = list;
    }

    onCreate() {
        this.SelectedItem = {
            SID: '',
            Name: '',
            CapsuleTag: '',
            IsAdministrator: false,
            Everyone: false
        };
        this.ToggleWindow();
    }

    onEdit(row) {
        this.ToggleWindow();
        this.SelectedItem = {
            SID: '',
            Name: '',
            CapsuleTag: '',
            IsAdministrator: false,
            Everyone: false
        }
        this.FindUserRole(row.Id);
    }

    FindUserRole(id) {
        this.loading(true);
        this.service.FindUserRole(id).subscribe({
            next: (res) => {
                this.SelectedItem = res;
                this.loading(false);
            },
            error: (err) => {
                this.loading(false);
                NotificationHelper.Error(err.error.Message, '@@Error');
            },

        })
    }

    onSaveUpdateRoles() {
        const data = {
            Name: this.SelectedItem.Name,
            IsAdministrator: this.SelectedItem.IsAdministrator,
            Everyone: this.SelectedItem.Everyone
        }
        if (this.SelectedItem.SID) {
            this.UpdateUserRole(this.SelectedItem);
        } else {
            this.AddUserRole(data);
        }

    }

    AddUserRole(data) {
        this.loading(true);
        this.service.AddUserRole({Role: data}).subscribe({
            next: (res) => {
                this.loading(false);
                this.refresh();
            },
            error: (err) => {
                if (err.error.Error) {
                    this.loading(false);
                    NotificationHelper.Error(err.error.Message, '@@Error');
                }
            }
        })
    }

    UpdateUserRole(data) {
        this.loading(true);
        this.service.UpdateUserRole({Role: data}).subscribe({
            next: (res) => {
                this.loading(false);
                this.refresh();
            },
            error: (err) => {
                if (err.error.Error) {
                    this.loading(false);
                    NotificationHelper.Error(err.error.Message, '@@Error');
                }
            }
        })
    }

    onDeleteRole(id) {
        this.loading(true)
        this.service.DeleteCompleteUserRole(id).subscribe(res => {
            this.loading(false)
            this.refresh();
        });
    }

    deleteDialog(row) {
        return new Promise<boolean>(resolve => {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('Are you sure you want to delete?'),
                new TranslateFormatText('Delete'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                if (retVal === MessageBoxResult.Yes) {
                    this.onDeleteRole(row.Id);
                }
            });
        });
    }

    /*
    * Update Grid list
    * Show Grid Window
    * */
    refresh() {
        this.GetUserRoles();
        this.toggleWindow = true;
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }

}
