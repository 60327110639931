import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'asdb-panel',
    templateUrl: './asdb.panel.html',
    styleUrls: ['./asdb.panel.css']
})
export class ASDBPanel extends BaseDataSourcePanel {

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: ASDBPanel,
            DBInfoType: ASDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = ASDBInfo;
    }

    addDimFormatString() {
        if (this.DBInfo) {
            if (!this.DBInfo.DimensionFormatStrings) {
                this.DBInfo.DimensionFormatStrings = [];
            }
            this.DBInfo.DimensionFormatStrings.push({
                DimensionID: '',
                FormatString: ''
            });
            this.onChange(null);
        }
    }

    deleteDimFormatString(i) {
        if (this.DBInfo && this.DBInfo.DimensionFormatStrings) {
            this.DBInfo.DimensionFormatStrings.splice(i, 1);
            this.onChange(null);
        }
    }
}

export class ASDBInfo extends ADBInfo {
    Server: string = null;
    Database: string = null;
    DefaultShowAttributes: boolean = false;
    PreloadingDimensions: string = null;
    MaxSecurityKeyCombinations = 1000000;
    DateFormatString: string = null;
    ConnectionTimeOut: number = 30;
    DimensionFormatStrings = null;
    SecurityPassthrough: boolean = false;

    getTypeName(): string {
        return 'evidanza.Database.AnalysisService.ASDBInfo';
    }
}
