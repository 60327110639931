import { Column } from '../../basic/column.model';
import { Row } from '../../basic/row.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { IPlanningTaskExecuter } from '../atask.model';
import { TaskHelper } from '../task.helper';
import { ConditionFormattingStyle } from '../conditional.model';
import { ConditionOperator } from '../../enums/conditionoperator.enum';

export class PlanningCellFormattingTaskExecuter implements IPlanningTaskExecuter {
    static TaskID = 'planningcellstyletask';

    Style: CellStyle;
    Merger: StyleMerger;
    Settings;
    Rows;
    Columns;
    Init(settings: any, Rows: Row[], Columns: Column[], context: any) {
        this.Merger = StyleMerger.GetStyleMerger(context);
        this.Rows = Rows;
        this.Columns = Columns;
        this.Settings = settings;
        if (settings) {
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
    }

    Execute() {
        if (this.Merger && (this.Style || (this.Settings.ConditionalFormatting && this.Settings.ConditionFormatingStyles && this.Settings.ConditionFormatingStyles.length > 0))) {
            if (this.Settings.StartCell && this.Settings.EndCell) {
                const StartRow = parseInt(this.Settings.StartCell.match(/\d/g).join('')) - 1;
                const EndRow = parseInt(this.Settings.EndCell.match(/\d/g).join('')) - 1;

                const StartColumnName = this.Settings.StartCell.replace(/[0-9]/g, '');
                const StartColumn = this.Columns.find((value) => {
                    return value.Name == StartColumnName;
                });
                const StartColumnIndex = this.Columns.indexOf(StartColumn);

                const EndColumnName = this.Settings.EndCell.replace(/[0-9]/g, '');
                const EndColumn = this.Columns.find((value) => {
                    return value.Name == EndColumnName;
                })
                const EndColumnIndex = this.Columns.indexOf(EndColumn);

                for (let i = StartRow; i <= EndRow; i++) {
                    let row = this.Rows[i];
                    for (let c = StartColumnIndex; c <= EndColumnIndex; c++) {

                        if (this.Settings.ConditionalFormatting) {
                            for (let f = 0; f < this.Settings.ConditionFormatingStyles.length; f++) {
                                this.SetConditionalStyle(row, this.Columns[c], this.Settings.ConditionFormatingStyles[f]);
                            }
                        } else {
                            if (this.Style && this.Merger) {
                                let colStyle = row.styles[this.Columns[c].Name];
                                row.styles[this.Columns[c].Name] = this.Merger.MergeStyle(parseInt(colStyle), this.Style);
                                if (this.Style.NumberFormat) {
                                    row.data[this.Columns[c].Name] = TaskHelper.GetFormatedValue(row['internal'][this.Columns[c].Name], this.Style.NumberFormat);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    SetConditionalStyle(Row, Column, condition: ConditionFormattingStyle) {
        let style = null;
        let val1 = 0;
        let val2 = 0;
        switch (condition.Condition.ConditionOperator)
        {
            case ConditionOperator.Equal:
                if (Row['internal'][Column.Name] === condition.Condition.Value1) {
                    style = condition.Style;
                }
                break;
            case ConditionOperator.NonEqual:
                if (Row['internal'][Column.Name] !== condition.Condition.Value1) {
                    style = condition.Style;
                }
                break;

            case ConditionOperator.Between:
                if (condition.Condition.Value1 > condition.Condition.Value2) {
                    val2 = condition.Condition.Value1;
                    val1 = condition.Condition.Value2;
                } else {
                    val1 = condition.Condition.Value1;
                    val2 = condition.Condition.Value2;
                }
                if (Row['internal'][Column.Name] > val1 && Row['internal'][Column.Name] < val2) {
                    style = condition.Style;
                }
                break;
            case ConditionOperator.NotBetween:
                if (condition.Condition.Value1 > condition.Condition.Value2) {
                    val2 = condition.Condition.Value1;
                    val1 = condition.Condition.Value2;
                } else {
                    val1 = condition.Condition.Value1;
                    val2 = condition.Condition.Value2;
                }
                if (Row['internal'][Column.Name] < val1 && Row['internal'][Column.Name] > val2) {
                    style = condition.Style;
                }
                break;

            case ConditionOperator.Less:
                if (Row['internal'][Column.Name] < condition.Condition.Value1) {
                    style = condition.Style;
                }break;
            case ConditionOperator.LessOrEqual:
                if (Row['internal'][Column.Name] <= condition.Condition.Value1) {
                    style = condition.Style;
                }
                break;

            case ConditionOperator.Greater:
                if (Row['internal'][Column.Name] > condition.Condition.Value1) {
                    style = condition.Style;
                }
                break;
            case ConditionOperator.GreatorOrEqual:
                if (Row['internal'][Column.Name] >= condition.Condition.Value1) {
                    style = condition.Style;
                }
                break;
            
            case ConditionOperator.IsNull:
                if (Row['internal'][Column.Name] == null) {
                    style = condition.Style;
                }
                break;
            case ConditionOperator.NotNull:
                if (Row['internal'][Column.Name] !== null) {
                    style = condition.Style;
                }
                break;
        }
        if (style) {
            Row.styles[Column.Name] = this.Merger.MergeStyle(parseInt(Row.styles[Column.Name]), style);
            if (style.NumberFormat) {
                Row.data[Column.Name] = TaskHelper.GetFormatedValue(Row['internal'][Column.Name], style.NumberFormat);
            }
        }
    }
}
