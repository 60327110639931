import { subtract } from 'mathjs';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult, AxisNode } from '../../datadescription/multi/multiresult.model';
import { ATask, ITaskExecuter } from '../atask.model';

export class RankTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    MeasureUniqueID: number;
    LevelUniqueID;

    Ascending = false;
    IgnoreNulls = false;

    Execute() {
    }
}

export class RankTaskExecuter implements ITaskExecuter {

    MeasureUniqueID = -1;
    LevelUniqueID = -1;

    Ascending = false;
    IgnoreNulls = false;

    Result: MultiResult;
    DataDescription: DataDescription;

    private static RankNodes(nodes: AxisNode[], oppositeNodes: AxisNode[], measure: AxisNode,
        result: MultiResult, ascending: boolean, ignoreNulls: boolean, cellAction, tryGetCellAction) {
        const visibleNodes = nodes.filter(x => x.Visible !== false);
        if (visibleNodes.length > 1) {
            MultiResultHelper.ExecuteOnAllNodesInternal(oppositeNodes, (oppNode) => {
                const sortedtuples: Map<number, AxisNode[]> = new Map();
                visibleNodes.forEach(lNode => {
                    let actVal = null;
                    const cellVal = cellAction(result, lNode, oppNode, measure, false);
                    if (cellVal) {
                        actVal = cellVal.InternalValue;
                    }
                    if (!ignoreNulls && actVal == null) {
                        actVal = 0;
                    }
                    if (actVal != null) {
                        if (!sortedtuples.has(actVal)) {
                            sortedtuples.set(actVal, []);
                        }
                        sortedtuples.get(actVal).push(lNode);
                    }
                });

                let rank = 1;
                const keys = Array.from(sortedtuples.keys()).sort((a, b) => {
                    if (ascending) {
                        return RankTaskExecuter.Sort(a, b);
                    } else {
                        return RankTaskExecuter.Sort(a, b) * -1;
                    }
                });
                keys.forEach(key => {
                    sortedtuples.get(key).forEach(tuple => {
                        const cellVal = tryGetCellAction(result, tuple, oppNode, measure);
                        cellVal.Node.InternalValue = rank;
                    });
                    rank += sortedtuples.get(key).length;
                });
            });
        }
    }

    static Sort(a, b) {
        if (typeof a === 'number') {
            if (typeof b === 'number') {
                const diff = subtract(a, b);
                if (diff < 0) {
                    return -1;
                } else if (diff > 0) {
                    return 1;
                }
            } else {
                return 1;
            }
        } else if (typeof b === 'number') {
            return -1;
        }
        return 0;
    }

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.Ascending === 'boolean') {
                this.Ascending = settings.Ascending;
            }
            if (typeof settings.IgnoreNulls === 'boolean') {
                this.IgnoreNulls = settings.IgnoreNulls;
            }
        }
    }

    Execute() {
        if (this.Result && this.DataDescription && this.LevelUniqueID >= -1) {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                const levelNodeList: AxisNode[][] = [];
                let oppNodes: AxisNode[] = [];
                let cellAction, tryGetCellAction;
                const noLevelIndex = {
                    Index: 0
                };
                if (!this.DataDescription.XLevelNodes.Areas.some(a => {
                    if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                        if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                            cellAction = MultiResultHelper.GetValueX;
                            tryGetCellAction = MultiResultHelper.TryGetCellOrNew;
                            levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.XAxis, this.LevelUniqueID));
                            oppNodes = this.Result.YAxis.Nodes;
                        } else {
                            this.DataDescription.YLevelNodes.Areas.some(area => {
                                if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                    cellAction = MultiResultHelper.GetValueX;
                                    tryGetCellAction = MultiResultHelper.TryGetCellOrNew;
                                    levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.XAxis, this.LevelUniqueID));
                                    oppNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex).Nodes;
                                    return true;
                                } else {
                                    if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                    } else {
                                        noLevelIndex.Index++;
                                    }
                                }
                                return false;
                            });
                        }
                        return true;
                    }
                    return false;
                })) {
                    noLevelIndex.Index = 0;
                    this.DataDescription.YLevelNodes.Areas.some(a => {
                        if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                            if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                cellAction = MultiResultHelper.GetValueY;
                                tryGetCellAction = (r, l, o, m) => {
                                    return MultiResultHelper.TryGetCellOrNew(r, o, l, m);
                                };
                                levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.YAxis, this.LevelUniqueID));
                                oppNodes = this.Result.XAxis.Nodes;
                            } else {
                                this.DataDescription.XLevelNodes.Areas.some(area => {
                                    if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                        cellAction = MultiResultHelper.GetValueY;
                                        tryGetCellAction = (r, l, o, m) => {
                                            return MultiResultHelper.TryGetCellOrNew(r, o, l, m);
                                        };
                                        const sections = MultiResultHelper.GetNodeSections(this.Result.YAxis, this.LevelUniqueID);
                                        levelNodeList.push(...sections);
                                        oppNodes =
                                            MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex).Nodes;
                                        return true;
                                    } else {
                                        if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                        } else {
                                            noLevelIndex.Index++;
                                        }
                                    }
                                    return false;
                                });
                            }
                            return true;
                        }
                        return false;
                    });
                }
                if (levelNodeList.length > 0 && oppNodes.length > 0 && cellAction && tryGetCellAction) {
                    levelNodeList.forEach(x => {
                        RankTaskExecuter.RankNodes(x, oppNodes, measure, this.Result, this.Ascending,
                            this.IgnoreNulls, cellAction, tryGetCellAction);
                    });
                }
            }
        }
    }
}
