import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'azuread-form',
    templateUrl: './azuread.form.html',
    styleUrls: ['./azuread.form.css']
})
export class AzureADForm implements OnInit {
    //#region Source
    SourceValue;

    @Input()
    get Source() {
        return this.SourceValue;
    }
    set Source(val) {
        this.SourceValue = val;
        this.SourceChange.emit(this.SourceValue);
    }

    @Output() SourceChange = new EventEmitter<boolean>();
    //#endregion
    //#region State
    stateValue;
    @Input()
    get state() {
        return this.stateValue;
    }
    set state(val) {
        this.stateValue = val;
        this.stateChange.emit(this.stateValue);
    }
    @Output() stateChange = new EventEmitter<boolean>();
    //#endregion

    ngOnInit(): void {
        this.checkFields();
    }

    fieldChanged() {
        this.checkFields();
        this.ItemChanged.emit();
    }

    checkFields() {
        this.state = true;
    }

    @Output() ItemChanged = new EventEmitter<any>();
}
