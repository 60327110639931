import { UUID } from 'angular2-uuid';
import { MultiCacheService } from '../../cache/multi/cache.service';
import { Axis, DataDescription, DynamicElement, HeterogenArea } from '../../models/datadescription/multi/datadescription.model';
import { LevelNode } from '../../models/datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../models/datadescription/multi/measureinfo.model';
import { AxisType } from '../../models/enums/query.enum';
import { SpecialElementType } from '../../models/enums/specialElementType';
import { TranslatedString } from '../../models/translatedstring.model';

export class DataDescriptionHelper {

    static GetAllNodes(axis: Axis): LevelNode[] {
        const result = [];
        axis.Areas.forEach(a => {
            result.push(...DataDescriptionHelper.GetLevels(a));
        });
        return result;
    }

    static FindNodeById(axis: Axis, uniqueid: number): LevelNode {
        let levelnode = null;
        axis.Areas.some(a => {
            return a.Tuples.some(t => {
                return t.Levels.some(l => {
                    if (l.UniqueID === uniqueid) {
                        levelnode = l;
                        return true;
                    }
                    return false;
                });
            });
        });
        return levelnode;
    }

    static GetLevels(value: HeterogenArea): LevelNode[] {
        const result = [];
        value.Tuples.forEach(t => {
            result.push(...t.Levels);
        });
        return result;
    }

    static GetValidDynamicRowElements(dd: DataDescription): DynamicElement[] {
        const elements = [];
        if (dd.DynamicRowElements.length > 0) {
            elements.push(...dd.DynamicRowElements.filter(x => x.Active && x.Parent != null && x.Formula != null));
        }
        return elements;
    }

    static GetMeasureAxis(dd: DataDescription): Axis {
        if (dd.YLevelNodes.Areas.some(a => a.Measures && a.Measures.length > 0)) {
            return dd.YLevelNodes;
        } else {
            return dd.XLevelNodes;
        }
    }

    static HasLevelsOnAxis(dd: DataDescription, axistype: AxisType): boolean {
        if (axistype === AxisType.X_Axis) {
            return dd.XLevelNodes.Areas.some(a => a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0));
        } else {
            return dd.YLevelNodes.Areas.some(a => a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0));
        }
    }

    static GetAllAreas(dd: DataDescription): HeterogenArea[] {
        const areas = [];
        areas.push(...dd.XLevelNodes.Areas);
        areas.push(...dd.YLevelNodes.Areas);
        return areas;
    }

    static GetAllMeasureInfos(dd: DataDescription, axis?: AxisType): MeasureInfo[] {
        const measureinfos = [];
        if (axis == null) {
            dd.XLevelNodes.Areas.forEach(a => {
                measureinfos.push(...a.Measures);
            });
            if (measureinfos.length === 0) {
                dd.YLevelNodes.Areas.forEach(a => {
                    measureinfos.push(...a.Measures);
                });
            }
        } else if (axis === AxisType.X_Axis) {
            dd.XLevelNodes.Areas.forEach(a => {
                measureinfos.push(...a.Measures);
            });
        } else {
            dd.YLevelNodes.Areas.forEach(a => {
                measureinfos.push(...a.Measures);
            });
        }
        return measureinfos;
    }

    static GetAllLevelNodesFlat(dd: DataDescription, axis?: AxisType): LevelNode[] {
        const levelnodes = [];
        if (axis == null) {
            levelnodes.push(...DataDescriptionHelper.GetAllNodes(dd.XLevelNodes));
            levelnodes.push(...DataDescriptionHelper.GetAllNodes(dd.YLevelNodes));
        } else if (axis === AxisType.X_Axis) {
            levelnodes.push(...DataDescriptionHelper.GetAllNodes(dd.XLevelNodes));
        } else if(axis === AxisType.Y_Axis) {
            levelnodes.push(...DataDescriptionHelper.GetAllNodes(dd.YLevelNodes));
        }
        return levelnodes;
    }

    static GetNodeFromUniqueID(dd: DataDescription, uniqueid: number): LevelNode {
        let levelNode = DataDescriptionHelper.FindNodeById(dd.XLevelNodes, uniqueid);
        if (levelNode == null) {
            levelNode = DataDescriptionHelper.FindNodeById(dd.YLevelNodes, uniqueid);
        }
        return levelNode;
    }

    static GetNextUniqueID(dd: DataDescription): number {
        return DataDescriptionHelper.GetMaxUniqueID(dd) + 1;
    }

    static GetMaxUniqueID(dd: DataDescription): number {
        let result = -1;
        result = DataDescriptionHelper.GetMax(dd.XLevelNodes, result);
        result = DataDescriptionHelper.GetMax(dd.YLevelNodes, result);
        return result;
    }

    private static GetMax(axis: Axis, result: number): number {
        axis.Areas.forEach(area => {
            result = Math.max(result, area.UniqueID);
            DataDescriptionHelper.GetLevels(area).forEach(level => {
                result = Math.max(result, level.UniqueID);
            });
            area.Measures.forEach(measure => {
                result = Math.max(result, measure.UniqueID);
            });
        });
        return result;
    }

    static FindAxisByAreaID(dd: DataDescription, id: number): Axis {
        let axis = this.FindAxisOrAreaByAreaIDInternal(dd.XLevelNodes, id, true);
        if (axis == null) {
            axis = this.FindAxisOrAreaByAreaIDInternal(dd.YLevelNodes, id, true);
        }
        return axis;
    }
    private static FindAxisOrAreaByAreaIDInternal(axis: Axis, id: number, findaxis: boolean): any {
        let result = null;
        axis.Areas.some(area => {
            if (area.UniqueID === id) {
                if (findaxis) {
                    result = axis;
                } else {
                    result = area;
                }
                return true;
            }
            return false;
        });
        return result;
    }

    static FindAreaByID(dd: DataDescription, id: number): HeterogenArea {
        let area = this.FindAxisOrAreaByAreaIDInternal(dd.XLevelNodes, id, false);
        if (area == null) {
            area = this.FindAxisOrAreaByAreaIDInternal(dd.YLevelNodes, id, false);
        }
        return area;
    }

    static async ReplaceLevelNodeByID(dd: DataDescription, id: number,  replaceID?: UUID): Promise<LevelNode> {
        let levelnode = DataDescriptionHelper.FindLevelNodeByID(dd, id);
        if (levelnode && replaceID) {
                await MultiCacheService.GetLevel(replaceID, dd.DataModelID).then(levelDB => {
                    if (levelDB) {
                        levelnode.Level = replaceID;
                        levelnode.HierarchieID = levelDB.Parent.ID; 
                        levelnode.DimensionID = levelDB.Parent.Parent.ID;
                        levelnode.Caption = new TranslatedString(levelDB.Caption);
                    }
                });
        }
        return levelnode;
    }
    static  FindLevelNodeByID(dd: DataDescription, id: number, remove?: boolean): LevelNode {
        let levelnode = this.FindLevelNodeByIDInternal(dd.XLevelNodes, id, remove);
        if (levelnode == null) {
            levelnode = this.FindLevelNodeByIDInternal(dd.YLevelNodes, id, remove);
        }
        return levelnode;
        
    }

    static FindLevelNodeByIDInternal(axis: Axis, id: number, remove?: boolean): LevelNode {
        let levelnode = null;
        axis.Areas.some(area => {
            return area.Tuples.some(tuple => {
                return tuple.Levels.some((l, i) => {
                    if (l.UniqueID === id) {
                        levelnode = l;
                        if (remove === true) {
                            tuple.Levels.splice(i, 1);
                        }
                        return true;
                    }
                    return false;
                });
            });
        });
        return levelnode;
    }

    static async ReplaceMeasureByID(dd: DataDescription, id: number, replaceID?: UUID): Promise<MeasureInfo> {
        let measureInfo = DataDescriptionHelper.FindMeasureByID(dd, id);
        if (measureInfo && replaceID) {
            await MultiCacheService.GetMeasure(replaceID, dd.DataModelID).then(mDB => {
                if (mDB) {
                    measureInfo.Measure = mDB.ID;
                    measureInfo.Caption = new TranslatedString(mDB.Caption);
                    measureInfo.Cube = mDB.Cube.ID;
                    measureInfo.SpecialElementType = SpecialElementType.None;

                    //const newM = new MeasureInfo();
                    //measureInfo.QueryAttribute = false;
                    //measureInfo.Attribute = newM.Attribute;
                    //measureInfo.AttributeHierarchy = newM.Attribute;
                    //measureInfo.Details = new MeasureInfoDetails();
                    //Variable = '';
                }
            });
        }
        return measureInfo;
    }

    static FindMeasureByID(dd: DataDescription, id: number, remove?: boolean): MeasureInfo {
       

        let measureinfo = this.FindMeasureByIDInternal(dd.XLevelNodes, id, remove);
        if (measureinfo == null) {
            measureinfo = this.FindMeasureByIDInternal(dd.YLevelNodes, id, remove);
        }
        return measureinfo;
    }

    private static FindMeasureByIDInternal(axis: Axis, id: number, remove?: boolean): MeasureInfo {
        let measureinfo = null;
        axis.Areas.some(area => {
            return area.Measures.some((m, i) => {
                if (m.UniqueID === id) {
                    measureinfo = m;
                    if (remove) {
                        area.Measures.splice(i, 1);
                    }
                    return true;
                }
                return false;
            });
        });
        return measureinfo;
    }

    static FindAreaByMeasureID(dd: DataDescription, id: number): HeterogenArea {
        let area = dd.XLevelNodes.Areas.find(area => area.Measures && area.Measures.some(m => m.UniqueID === id));
        if (area == null) {
            area = dd.YLevelNodes.Areas.find(area => area.Measures && area.Measures.some(m => m.UniqueID === id));
        }
        return area;
    }

    static FindAreaByLevelID(dd: DataDescription, id: number): HeterogenArea {
        let area =
            dd.XLevelNodes.Areas.find(area => area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === id)));
        if (area == null) {
            area =
                dd.YLevelNodes.Areas.find(area => area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === id)));
        }
        return area;
    }


}
