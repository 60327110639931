import { Injectable } from '@angular/core';
import { RequestBase } from './request-base';
import { Observable } from 'rxjs';
import { MultiResult } from '../models/datadescription/multi/multiresult.model';

@Injectable()
export class ReportingService extends RequestBase {
    BasePath = 'api/macreporting';

    ReadLayoutById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayoutById?id=' + id, this.options);
    }
    ReadLayoutByKey(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayoutByKey?key=' + key, this.options);
    }
    ReadLayouts(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLayouts', this.options);
    }
    AddLayout(data: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddLayout', data, this.options);
    }
    UpdateLayout(data: any, key: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/UpdateLayout?key=' + key, data, this.options);
    }
    RemoveLayout(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveLayout?id=' + id, this.options);
    }

    LoadExampleData(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadExampleData', this.options);
    }

    ExecuteQuery(data: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + 'api/queryengine/relational' + '/ExecuteQuery', data, this.options);
    }
}

@Injectable()
export class MultiReportingService extends RequestBase {
    BasePath = 'api/queryengine/multi/';

    GetDatabase(dataModelId) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + 'GetDatabase?dataModelId=' + dataModelId, this.options);
    }

    ExecuteQuery(dataModelId, queryDef): Observable<MultiResult> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + 'ExecuteQuery?dataModelId=' + dataModelId, queryDef, this.options);
    }

    GetMembers(datasourceID, levelID, parent, parentLevel) {
        let path = 'GetMembers?datasourceID=' + datasourceID + '&levelID=' + levelID;
        if (parent && parentLevel) {
            path += '&levelMemberId=' + parentLevel + '&parent=' + parent;
        }
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + path, this.options);
    }

    GetMemberFromDate(date, datasourceID, levelID) {
        const body = {
            Date: date,
            DatasourceID: datasourceID,
            LevelID: levelID
        };
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + 'GetMemberFromDate', body, this.options);
    }

    GetMemberFromDynamicTimePoint(dynTime, datasourceID, levelID) {
        const path = 'GetMemberFromDynamicTimePoint?dynTime=' + dynTime + '&datasourceID=' + datasourceID + '&levelID=' + levelID;
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + path, this.options);
    }

    GetNextOrPreviousMember(datasourceID, levelID, actual, next) {
        const p = 'GetNextOrPreviousMember?datasourceID=' + datasourceID + '&levelID=' + levelID + '&actual=' + actual + '&next=' + next;
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + p, this.options);
    }

    GetMemberCaptions(mcq) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + 'GetMemberCaptions', mcq, this.options);
    }
}
