import { add, subtract } from 'mathjs';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { AxisType } from '../../enums/query.enum';
import { TranslatedString } from '../../translatedstring.model';
import { ATask, ITaskExecuter } from '../atask.model';
import { SumRowTaskExecuter } from '../defaulttasks/sum.row.task';
import { CalculateTaskExecuter } from './calculate.model';

export class TopDownTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    MeasureUniqueID: number;
    LevelUniqueID;

    Top = false;
    DifferentLogic = false;
    SumRemaining = false;
    SumRemainingCaption: TranslatedString;
    RunInQuery = false;
    Count: number;

    Execute() {
    }
}

export class TopDownTaskExecuter implements ITaskExecuter {

    MeasureUniqueID = -1;
    LevelUniqueID = -1;


    Top = true;
    DifferentLogic = false;
    SumRemaining = false;
    SumRemainingCaption: TranslatedString;
    RunInQuery = false;
    Count: number;

    Result: MultiResult;
    DataDescription: DataDescription;
    Variables;
    CalcTaskExecuter: CalculateTaskExecuter[];

    static Sort(a, b) {
        if (typeof a.Value === 'number') {
            if (typeof b.Value === 'number') {
                const diff = subtract(a.Value, b.Value);
                if (diff < 0) {
                    return -1;
                } else if (diff > 0) {
                    return 1;
                }
            } else {
                return 1;
            }
        } else if (typeof b.Value === 'number') {
            return -1;
        }
        return 0;
    }

    async Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.Top === 'boolean') {
                this.Top = settings.Top;
            }
            if (typeof settings.DifferentLogic === 'boolean') {
                this.DifferentLogic = settings.DifferentLogic;
            }
            if (typeof settings.SumRemaining === 'boolean') {
                this.SumRemaining = settings.SumRemaining;
            }
            if (typeof settings.RunInQuery === 'boolean') {
                this.RunInQuery = settings.RunInQuery;
            }
            if (typeof settings.Count === 'number') {
                this.Count = settings.Count;
            }
            this.SumRemainingCaption = settings.SumRemainingCaption;
        }
        if (context) {
            this.Variables = context.Variables;
            this.CalcTaskExecuter = context.CalcTaskExecuter;
        }
    }

    async Execute() {
        if (this.Result && this.DataDescription && this.LevelUniqueID >= -1
            && this.MeasureUniqueID >= -1 && typeof this.Count === 'number') {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                let levelAxis, oppositeAxis, cellAction, levelArea, measureArea;
                this.DataDescription.XLevelNodes.Areas.some(a => {
                    if (a.Tuples) {
                        a.Tuples.some(t => {
                            if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                levelArea = a;
                                levelAxis = this.Result.XAxis;
                                oppositeAxis = this.Result.YAxis;
                                cellAction = MultiResultHelper.GetValueX;
                                return true;
                            }
                            return false;
                        });
                    }
                    if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                        measureArea = a;
                    }
                });
                if (levelArea) {
                    if (measureArea) {
                        if (levelArea !== measureArea) {
                            // TODO: Fehler ausgeben
                            return;
                        }
                    } else {
                        if (!this.DataDescription.YLevelNodes.Areas.some(a => {
                            if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                measureArea = a;
                                return true;
                            }
                            return false;
                        })) {
                            // TODO: Fehler ausgeben (Wert nicht gefunden)
                            return;
                        }
                    }
                } else {
                    if (measureArea) {
                        this.DataDescription.YLevelNodes.Areas.some(a => {
                            if (a.Tuples) {
                                return a.Tuples.some(t => {
                                    if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                        levelAxis = this.Result.YAxis;
                                        oppositeAxis = this.Result.XAxis;
                                        cellAction = MultiResultHelper.GetValueY;
                                        return true;
                                    }
                                    return false;
                                });
                            }
                            return false;
                        });
                    } else {
                        this.DataDescription.YLevelNodes.Areas.forEach(a => {
                            if (a.Tuples) {
                                a.Tuples.some(t => {
                                    if (t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID)) {
                                        if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                            levelAxis = this.Result.YAxis;
                                            oppositeAxis = this.Result.XAxis;
                                            cellAction = MultiResultHelper.GetValueY;
                                        } else {
                                            // TODO: Fehler ausgeben (Wert nicht in Area des Levels)
                                        }
                                        return true;
                                    }
                                    return false;
                                });
                            }
                        });
                    }
                }
                if (levelAxis && oppositeAxis && cellAction) {
                    const remaining = [];
                    let remCaption = TranslateHelper.TranslatorInstance.instant('@@Rest');
                    if (this.SumRemainingCaption) {
                        remCaption = TranslatedString.GetTranslation(this.SumRemainingCaption);
                    }
                    const sumNodes = MultiResultHelper.GetNodeSections(levelAxis, this.LevelUniqueID);
                    for (let i = 0; i < sumNodes.length; i++) {
                        const sn = sumNodes[i].filter(x => x.Visible !== false);
                        if (sn.length > 0) {
                            const sortList = [];
                            sn.forEach(lNode => {
                                let actVal = null;
                                oppositeAxis.Nodes.forEach(oppNode => {
                                    const cellVal = cellAction(this.Result, lNode, oppNode, measure, false);
                                    if (cellVal && cellVal.InternalValue != null) {
                                        if (actVal == null) {
                                            actVal = cellVal.InternalValue;
                                        } else {
                                            actVal = add(actVal, cellVal.InternalValue);
                                        }
                                    }
                                });
                                sortList.push({
                                    Node: lNode,
                                    Value: actVal
                                });
                            });

                            const remList = [];
                            let count = this.Count;
                            let remIndex;
                            if (count <= 0) {
                                const firstEntry = sortList[0];
                                if (firstEntry.Node.Parent) {
                                    firstEntry.Node.Parent.Children.splice(0);
                                    sortList.forEach(entry => {
                                        remList.push(entry.Node);
                                    });
                                } else {
                                    sortList.forEach(entry => {
                                        const index = levelAxis.Nodes.indexOf(entry.Node);
                                        if (index > -1) {
                                            levelAxis.Nodes.splice(index, 1);
                                        }
                                        remList.push(entry.Node);
                                    });
                                }
                            } else {
                                if (this.Top) {
                                    sortList.sort((a, b) => {
                                        return TopDownTaskExecuter.Sort(a, b) * -1;
                                    });
                                } else {
                                    sortList.sort((a, b) => {
                                        return TopDownTaskExecuter.Sort(a, b);
                                    });
                                }
                                const childList = [];
                                sortList.forEach(entry => {
                                    if (count > 0) {
                                        entry.Node.IsLastChild = false;
                                        childList.push(entry.Node);
                                        count--;
                                    } else {
                                        remList.push(entry.Node);
                                    }
                                });
                                if (sortList[0].Node.Parent) {
                                    if (childList.length > 0) {
                                        childList[childList.length - 1].IsLastChild = true;
                                    }
                                    sortList[0].Node.Parent.Children = childList;
                                } else {
                                    const axisNodes = [];
                                    let found = false;
                                    levelAxis.Nodes.forEach(x => {
                                        if (x.UniqueID === this.LevelUniqueID) {
                                            if (!found) {
                                                axisNodes.push(...childList);
                                                remIndex = axisNodes.length;
                                            }
                                            found = true;
                                        } else {
                                            x.IsLastChild = false;
                                            axisNodes.push(x);
                                        }
                                    });
                                    if (axisNodes.length > 0) {
                                        axisNodes[axisNodes.length - 1].IsLastChild = true;
                                    }
                                    levelAxis.Nodes = axisNodes;
                                }
                            }
                            if (this.SumRemaining) {
                                const remNode = new AxisNode({
                                    Position: levelAxis.ElementCount++,
                                    Axis: levelAxis.Type,
                                    Depth: sortList[0].Node.Depth,
                                    Caption: remCaption,
                                    MemberId: 'MaCTopDownRemainder',
                                    Measure: false,
                                    Parent: sortList[0].Node.Parent
                                });
                                if (remNode.Parent) {
                                    remNode.Parent.Children.push(remNode);
                                } else {
                                    if (typeof remIndex === 'number') {
                                        levelAxis.Nodes.splice(remIndex, 0, remNode);
                                    } else {
                                        levelAxis.Nodes.push(remNode);
                                    }
                                }
                                if (remList.length > 0) {
                                    remaining.push({
                                        SumNode: remNode,
                                        NodesToSum: remList,
                                        IsTopDown: true
                                    });
                                }
                            }
                        }
                    }
                    if (remaining.length > 0) {
                        if (levelAxis.Type === AxisType.Y_Axis) {
                            await SumRowTaskExecuter.CalcSumValues(remaining, null, [], null, this.DataDescription,
                                this.Result, this.Variables, this.CalcTaskExecuter);
                        } else {
                            await SumRowTaskExecuter.CalcSumValues([], null, remaining, null, this.DataDescription,
                                this.Result, this.Variables, this.CalcTaskExecuter);
                        }
                    }
                } else {
                    // TODO: Fehler ausgeben, Level nicht gefunden
                }
            }
        }
    }
}
