import { Type } from "class-transformer";
import { MethodType } from "../enums/methodtype.enum";

export class RequestParameters {
    Name: string;
    Value: string;
    Type: string;
}
// @dynamic
export class DataPreviewInfo {
    Name: string;
    Resource: string;
    ContainerType: string;
    @Type(() => RequestParameters)
    Parameters: RequestParameters[] = [];
    Methodtype: MethodType;
    Options: Map<string, string> = new Map();
    Fields: string[] = [];

    toJSON() {
        let result;
        result = Object.assign({}, this);
        let options = {};
        if (result.Options && typeof result.Options.forEach === 'function') {
            result.Options.forEach(function (v, k) {
                options[k] = v;
            });
            result.Options = options;
        }
        return result;
    }
}

