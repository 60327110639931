import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheService } from '../../../cache/cache.service';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { FormulaInfo, NonClickableVariablesNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { FormulaService } from '../../../services/formula.service';
import { JiraService } from '../../../services/jira.service';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';
import { ABaseTreeNode } from '../basetreecontrol/base.tree.control';

@Component({
    selector: 'jira-control',
    templateUrl: './jira.control.html',
    styleUrls: ['./jira.control.css']
})
export class JiraControl {
    JiraConfigValue: [];
    IssueValue;
    SaveEnabledValue;
    TextInnerHtml;
    Transitions;
    JiraUsersValue;
    JiraAssignee;

    @Input()
    get JiraUsers() {
        return this.JiraUsersValue;
    }
    set JiraUsers(val) {
        if (val) {
            this.JiraUsersValue = val;
            this.cdRef.detectChanges();
        }
    }

    @Input()
    get JiraConfig() {
        return this.JiraConfigValue;
    }
    set JiraConfig(val) {
        if (val) {
            this.JiraConfigValue = val;
            this.fillTransitions();
            this.cdRef.detectChanges();
        }
    }

    @Input()
    get Issue() {
        return this.IssueValue;
    }
    set Issue(val) {
        if (val) {
            this.IssueValue = val;
            if (this.IssueValue.RenderedFields && this.IssueValue.RenderedFields.description) {
                this.TextInnerHtml = this.sanitizer.bypassSecurityTrustHtml(this.IssueValue.RenderedFields.description);
            } else {
                this.TextInnerHtml = '';
            }
            if (this.IssueValue.Fields.assignee && this.IssueValue.Fields.assignee.accountId) {
                this.JiraAssignee = this.IssueValue.Fields.assignee.accountId;
            } else {
                this.JiraAssignee = 'null';
            }
            this.fillTransitions();
            this.cdRef.detectChanges();
            this.IssueChange.emit(this.IssueValue);
        }
    }

    @Output() IssueChange = new EventEmitter<any>();

    fillTransitions() {
        if (this.IssueValue && this.IssueValue.ID && this.JiraConfigValue) {
            this.jira.GetTransitions(this.JiraConfigValue, this.IssueValue.ID).subscribe(ret => {
                this.Transitions = ret;
                this.cdRef.detectChanges();
            });
        }
    }

    onStatusChanged(evt) {
        let trans = this.Transitions.find(x => x.StatusID == evt.value);
        this.jira.TransitionIssue(this.JiraConfigValue, this.IssueValue.ID, trans.ID).subscribe();
    }

    onAssigneeChanged() {
        if (this.JiraAssignee && this.JiraAssignee != "null") {
            this.jira.AssigneeIssue(this.JiraConfigValue, this.IssueValue.ID, this.JiraAssignee).subscribe(() => {
                this.IssueValue.Fields.assignee = { accountId: this.JiraAssignee };
                this.cdRef.detectChanges();
            });
        } else {
            this.jira.AssigneeIssue(this.JiraConfigValue, this.IssueValue.ID, null).subscribe(() => {
                this.IssueValue.Fields.assignee = null;
                this.cdRef.detectChanges();
            });
        }
    }

    close() {
        this.Close.emit({});
    }

    @Output() Close = new EventEmitter<any>();

    //@Input()
    //get SaveEnabled() {
    //    return this.SaveEnabledValue;
    //}
    //set SaveEnabled(val) {
    //    if (val) {
    //        this.SaveEnabledValue = val;
    //        this.SaveEnabledChange.emit(this.SaveEnabledValue);
    //    }
    //}

    //@Output() SaveEnabledChange = new EventEmitter<any>();

    OnItemChanged() {
        //this.SaveEnabled = true;
    }

    constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef, private jira: JiraService) {
    }

}

