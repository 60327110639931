import { ChangeDetectorRef, Component, Directive, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize, Type } from 'class-transformer';
import {
    DataSourceSelectionInfo, InteractivePropertyInfo
} from '../../../../components/common/datasourceselectioncontol/datasource.selection.control';
import {
    RequestOptionsColumn, RequestOptionsControl, RequestOptionsDialogArgs
} from '../../../../components/common/requestoptions/request.options.control';
import { BaseDialog } from '../../../../components/dialogs/basedialog/base.dialog';
import { ObjectPreviewDialog } from '../../../../components/dialogs/objectpreview/object.preview.dialog';
import { DataSourceHelper } from '../../../../helpers/datasource.helper';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { Row } from '../../../../models/basic/row.model';
import { DialogButton } from '../../../../models/enums/dialogbutton.enum';
import { MethodType } from '../../../../models/enums/methodtype.enum';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { RequestFilter } from '../../../../models/rest/requestfilter';
import { RequestOptions } from '../../../../models/rest/requestoptions';
import { DataPreviewInfo, RequestParameters } from '../../../../models/rest/requestparameters';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataPreviewService } from '../../../../services/data.preview.service';
import { DataSettingsService } from '../../../../services/data.settings.service';
import { DataModelService, DataSourceQuery, SelfServiceObjectService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerFieldEditDialog } from '../../../../settings/datamodel/dialogs/container.field.edit.dialog';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../../workflow/workflow.dialog';

@Directive()
export abstract class ADatasourceSettings extends ContainerWorkflowDialogContent {

    DataModels = [];
    SelectedDataModel;
    DataSourceQuery = new DataSourceQuery(null);
    DataSourceInfo = new DataSourceSelectionInfo();
    UseTable = true;
    Tables = [];
    SelectedTable;
    DeltaFilterColumns = [];
    SelectedDelta;
    Filter;
    TableTypes = [];
    AdHocSettings: DataPreviewInfo = new DataPreviewInfo();
    MethodTypes;
    OptionsList = [];
    StructureList = [];
    StructureOptions = [];
    AllTypeList = [];
    IsRelational = true;
    AdHocQueryable = false;

    //#region ShowFilter
    ShowFilterValue = true;

    @Input()
    get ShowFilter() {
        return this.ShowFilterValue;
    }
    set ShowFilter(val) {
        this.ShowFilterValue = val;
        this.ShowFilterChange.emit(this.ShowFilterValue);
    }

    @Output() ShowFilterChange = new EventEmitter<any>();
    //#endregion

    //#region ShowPreview
    ShowPreviewValue = true;

    @Input()
    get ShowPreview() {
        return this.ShowPreviewValue;
    }
    set ShowPreview(val) {
        this.ShowPreviewValue = val;
        this.ShowPreviewChange.emit(this.ShowPreviewValue);
    }

    @Output() ShowPreviewChange = new EventEmitter<any>();
    //#endregion

    //#region ShowStructure
    ShowStructureValue = true;

    @Input()
    get ShowStructure() {
        return this.ShowStructureValue;
    }
    set ShowStructure(val) {
        this.ShowStructureValue = val;
        this.ShowStructureChange.emit(this.ShowStructureValue);
    }

    @Output() ShowStructureChange = new EventEmitter<any>();
    //#endregion

    //#region IsExport
    IsExportValue = false;

    @Input()
    get IsExport() {
        return this.IsExportValue;
    }
    set IsExport(val) {
        this.IsExportValue = val;
        this.IsExportChange.emit(this.IsExportValue);
    }

    @Output() IsExportChange = new EventEmitter<any>();
    //#endregion

    //#region EditOptions
    @Input()
    get EditOptions() {
        return this.WFEditOptions;
    }
    set EditOptions(val) {
        this.WFEditOptions = val;
        this.EditOptionsChange.emit(this.WFEditOptions);
    }

    @Output() EditOptionsChange = new EventEmitter<any>();
    //#endregion

    @Output() DataSourceChanged = new EventEmitter<any>();
    onDataSourceChanged() {
        this.DataSourceChanged.emit(this.getResult());
    }

    constructor(protected dataService: DataModelService, protected settingsService: DataSettingsService,
        protected preview: DataPreviewService, protected translate: TranslateService) {
        super();
        this.HasExpressions = true;
        this.MethodTypes = EnumHelper.GetDropdownValues(MethodType);
        this.AllTypeList.push(...ContainerFieldEditDialog.AllTypes);
    }

    ngOnInit() {
        if (Array.isArray(this.FixedDataModels)) {
            this.DataModels = [...this.FixedDataModels];
        } else {
            if (this.WFEditOptions && this.WFEditOptions.ExternalOptions &&
                Array.isArray(this.WFEditOptions.ExternalOptions.FixedDataModels)) {
                this.DataModels = [...this.WFEditOptions.ExternalOptions.FixedDataModels];
            } else {
                this.dataService.GetModels().subscribe((result) => {
                    this.DataModels = result;
                });
            }
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            this.SelectedDataModel = data.DataModel;
            this.UseTable = data.UseTable;
            this.Filter = data.Filter;
            if (data.Settings) {
                const json = serialize(data.Settings);
                this.AdHocSettings = deserialize(DataPreviewInfo, json);
            }
            if (this.SelectedDataModel) {
                this.DataSourceQuery = DataSourceHelper.GetDataSourceQuery(this.SelectedDataModel, this.IsRelational, this.IsExport, true);
                this.dataService.GetDataSourcesByQuery(this.DataSourceQuery).subscribe((dataSources) => {
                    if (dataSources.some(ds => ds.SID === data.DataSource)) {
                        this.DataSourceInfo.DataSource = data.DataSource;
                        this.DataSourceInfo.InteractiveProperties = data.InteractiveProperties;
                        this.settingsService.GetContainerTablesByDataSource(this.SelectedDataModel, data.DataSource,
                            this.IsRelational, this.IsExportValue).subscribe((tables) => {
                                this.Tables = tables;
                                const selTable = tables.find(table => table.SID === data.ContainerTable);
                                if (selTable) {
                                    this.SelectedTable = selTable;
                                    this.SelectedDelta = data.DeltaColumn;
                                    this.onSelectedTableChanged();
                                }
                            });
                        this.settingsService.GetContainerTableTypes(data.DataSource,
                            this.IsRelational, this.IsExportValue).subscribe((result) => {
                                const tables = [];
                                Object.getOwnPropertyNames(result).forEach(function (name) {
                                    tables.push({ Caption: name, Key: result[name] });
                                });
                                this.TableTypes = tables;
                            });
                        if (this.AdHocSettings) {
                            if (this.AdHocSettings.ContainerType) {
                                this.dataService.GetAvailableOptions(data.DataSource, this.AdHocSettings).subscribe((result) => {
                                    if (result) {
                                        if (result.Options) {
                                            const list = [];
                                            result.Options.forEach(function (r) {
                                                list.push({ Data: r });
                                            });
                                            this.OptionsList = list;
                                        }
                                        this.AdHocQueryable = result.IsQueryable;
                                    }
                                });
                                if (this.IsRelational && this.ShowStructureValue) {
                                    const selectedCols = new Map();
                                    if (this.AdHocSettings.Fields) {
                                        this.AdHocSettings.Fields.forEach(function (field) {
                                            const parsed = JSON.parse(field);
                                            if (parsed.Name) {
                                                parsed.ColSelected = true;
                                                selectedCols.set(parsed.Name, parsed);
                                            }
                                        });
                                    }
                                    this.preview.GetStructureWithOptions(data.DataSource, this.AdHocSettings).subscribe((res) => {
                                        if (res) {
                                            let structure = [];
                                            if (res.Structure) {
                                                if (selectedCols.size > 0) {
                                                    res.Structure.forEach(function (s) {
                                                        const sel = selectedCols.get(s.Name);
                                                        if (sel) {
                                                            structure.push(sel);
                                                        } else {
                                                            s.ColSelected = false;
                                                            structure.push(s);
                                                        }
                                                    });
                                                } else {
                                                    structure = res.Structure;
                                                }
                                            }
                                            this.StructureList = structure;
                                            this.StructureOptions = res.Options;
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    abstract getResultInstance(): ADatasourceSettingsData;

    getResult(): any {
        const retVal = this.getResultInstance();
        retVal.DataModel = this.SelectedDataModel;
        retVal.DataSource = this.DataSourceInfo.DataSource;
        if (this.DataSourceInfo.InteractiveProperties) {
            retVal.InteractiveProperties = this.DataSourceInfo.InteractiveProperties.filter(x => x.Value);
        }
        retVal.UseTable = this.UseTable;
        if (this.UseTable) {
            if (this.SelectedTable) {
                retVal.ContainerTable = this.SelectedTable.SID;
                retVal.ContainerInternalID = this.SelectedTable.InternalID;
                retVal.DeltaColumn = this.SelectedDelta;
            }
            retVal.Filter = this.Filter;
        } else {
            retVal.Settings = this.AdHocSettings;
            if (retVal.Settings) {
                const dict = new Map();
                if (this.OptionsList) {
                    this.OptionsList.forEach(function (opt) {
                        if (opt.Data && opt.Data.Value) {
                            dict.set(opt.Data.Name, opt.Data.Value.toString());
                        }
                    });
                }
                retVal.Settings.Options = dict;
                const fields = [];
                if (this.StructureList) {
                    this.StructureList.forEach(function (entry) {
                        if (entry.ColSelected) {
                            const f = Object.assign({}, entry);
                            delete f.ColSelected;
                            fields.push(JSON.stringify(f));
                        }
                    });
                }
                retVal.Settings.Fields = fields;
                if (retVal.Settings.Parameters) {
                    const params = [];
                    retVal.Settings.Parameters.forEach(x => {
                        if (x.Name && x.Value) {
                            params.push(x);
                        }
                    });
                    retVal.Settings.Parameters = params;
                }
            }
        }
        return retVal;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.UseTable && this.SelectedTable && this.SelectedDelta) {
            const deltaCol = this.DeltaFilterColumns.find(x => x.ID === this.SelectedDelta);
            if (deltaCol && this.Filter && this.Filter.Columns && this.Filter.Columns.length > 0) {
                if (!this.Filter.Columns.some(x => x.Name === deltaCol.Name)) {
                    retVal.Error = '@@Die Delta-Filter-Spalte muss in der Abfrage verwendet werden.';
                    retVal.IsCorrect = false;
                }
            }
        }
        return retVal;
    }

    modelChanged() {
        this.DataSourceQuery = new DataSourceQuery(null);
        this.DataSourceInfo = new DataSourceSelectionInfo();
        this.Tables = [];
        this.SelectedTable = null;
        this.onSelectedTableChanged();
        this.Filter = null;
        this.AdHocSettings = new DataPreviewInfo();
        this.DataSourceQuery = DataSourceHelper.GetDataSourceQuery(this.SelectedDataModel, this.IsRelational, this.IsExport, true);
        this.onDataSourceChanged();
    }

    dataSourceChanged() {
        this.Tables = [];
        this.TableTypes = [];
        this.SelectedTable = null;
        this.onSelectedTableChanged();
        this.Filter = null;
        this.AdHocSettings = new DataPreviewInfo();
        this.settingsService.GetContainerTablesByDataSource(this.SelectedDataModel, this.DataSourceInfo.DataSource,
            this.IsRelational, this.IsExportValue).subscribe((tables) => {
                this.Tables = tables;
            });
        this.settingsService.GetContainerTableTypes(this.DataSourceInfo.DataSource,
            this.IsRelational, this.IsExportValue).subscribe((result) => {
            const tables = [];
            Object.getOwnPropertyNames(result).forEach(function (name) {
                tables.push({ Caption: name, Key: result[name] });
            });
            this.TableTypes = tables;
        });
        this.onDataSourceChanged();
    }

    tableChanged() {
        this.Filter = null;
        this.onDataSourceChanged();
        this.onSelectedTableChanged();
    }

    getFilterText() {
        const textList = [];
        if (this.Filter) {
            if (this.Filter.Columns && this.Filter.Columns.length > 0) {
                const list = [];
                this.Filter.Columns.forEach((col) => {
                    list.push(col.Name);
                });
                textList.push(this.translate.instant('@@Spalten') + ': ' + list.join(', '));
            }
            if (this.Filter.Filters && this.Filter.Filters.length > 0) {
                textList.push(this.translate.instant('@@Filter') + ': ' + RequestFilter.getRequestFilterText(this.Filter.Filters[0]));
            }
            if (this.Filter.Sort && this.Filter.Sort.length > 0) {
                const list = [];
                this.Filter.Sort.forEach(x => {
                    list.push(x.Name + ' (' + (x.Order === 0 ? 'ASC' : 'DESC') + ')');
                });
                textList.push(this.translate.instant('@@Sortierung') + ': ' + list.join(', '));
            }
        }
        if (textList.length > 0) {
            return textList.join('\n');
        } else {
            return this.translate.instant('@@Kein Filter');
        }
    }

    async editFilter() {
        const initArgs = new RequestOptionsDialogArgs();
        initArgs.Variables = this.Variables;
        initArgs.RequestOptions = this.Filter;
        if (this.SelectedTable) {
            const filterInfo = await this.dataService.GetFilterInfo(this.SelectedTable.SID).toPromise();
            if (filterInfo) {
                initArgs.ShowFilter = filterInfo.CanFilter;
                filterInfo.Columns.forEach(x => {
                    const col = new RequestOptionsColumn();
                    col.Name = x.Name;
                    col.Type = x.DataType;
                    col.CanFilter = filterInfo.CanFilter;
                    col.CanSelect = filterInfo.CanSelectColumns;
                    initArgs.Columns.push(col);
                });
            }
        }
        RequestOptionsControl.ShowDialog(initArgs, '@@Abfrageoptionen', (result) => {
            this.Filter = result;
        });
    }

    resetOptions() {
        if (this.AdHocSettings) {
            this.AdHocSettings.Fields = [];
            this.AdHocSettings.Options = new Map();
        }
        this.OptionsList = [];
        this.StructureList = [];
        this.StructureOptions = [];
        this.onDataSourceChanged();
    }

    updateOptions() {
        this.OptionsList = [];
        this.AdHocQueryable = false;
        this.onDataSourceChanged();
        if (this.AdHocSettings) {
            this.AdHocSettings.Options = new Map();
            if (this.DataSourceInfo.DataSource) {
                this.dataService.GetAvailableOptions(this.DataSourceInfo.DataSource, this.AdHocSettings).subscribe((result) => {
                    if (result) {
                        if (result.Options) {
                            const list = [];
                            result.Options.forEach(function (r) {
                                list.push({ Data: r });
                            });
                            this.OptionsList = list;
                        }
                        this.AdHocQueryable = result.IsQueryable;
                        this.onDataSourceChanged();
                    }
                });
            }
        }
    }

    updateStructure() {
        this.StructureList = [];
        this.StructureOptions = [];
        if (this.AdHocSettings) {
            this.AdHocSettings.Fields = [];
            if (this.DataSourceInfo.DataSource) {
                this.preview.GetStructureWithOptions(this.DataSourceInfo.DataSource, this.AdHocSettings).subscribe((result) => {
                    if (result) {
                        result.Structure.forEach(function (col) {
                            col.ColSelected = true;
                        });
                        this.StructureList = result.Structure;
                        this.StructureOptions = result.Options;
                    }
                });
            }
        }
    }

    addParameter() {
        if (this.AdHocSettings) {
            if (this.AdHocSettings.Parameters) {
                this.AdHocSettings.Parameters.push(new RequestParameters());
            } else {
                this.AdHocSettings.Parameters = [new RequestParameters()];
            }
        }
    }

    deleteParameter(i) {
        if (this.AdHocSettings && this.AdHocSettings.Parameters) {
            this.AdHocSettings.Parameters.splice(i, 1);
        }
    }

    columnSelectionChanged(ev, struct) {
        if (ev && struct) {
            struct.ColSelected = ev.checked;
        }
    }

    abstract showPreview();

    GetExpressionProperties() {
        const retVal = [];
        retVal.push({
            Caption: this.translate.instant('@@Datasource'),
            Value: 'DataSource'
        });
        if (this.UseTable) {
            retVal.push({
                Caption: this.translate.instant('@@Container'),
                Value: 'ContainerTable'
            });
            retVal.push({
                Caption: this.translate.instant('@@Filter'),
                Value: 'Filter'
            });
        } else {
            retVal.push({
                Caption: this.translate.instant('@@Resource'),
                Value: 'Settings.Resource'
            });
        }
        return retVal;
    }

    getColumnOption(col, opt) {
        if (col && opt) {
            return {
                Name: opt.Name,
                Type: opt.Type,
                Value: col[opt.PropertyName],
                Options: opt.Options
            };
        }
        return null;
    }

    setColumnOption(col, opt, val) {
        if (col && opt) {
            col[opt.PropertyName] = val;
        }
    }

    selectAll(ev) {
        if (ev) {
            this.StructureList.forEach(function (s) {
                s.ColSelected = ev.checked;
            });
        }
    }

    onSelectedTableChanged() {
        const oldDelta = this.SelectedDelta;
        this.DeltaFilterColumns = [];
        this.SelectedDelta = null;
        if (this.SelectedTable && this.SelectedTable.IsQueryable) {
            this.dataService.GetContainer(this.SelectedTable.SID).subscribe(result => {
                if (result && result.Fields) {
                    const deltaFields = [];
                    result.Fields.forEach(x => {
                        if (x.DataTyp === 'System.DateTime' || x.DataTyp === 'System.Int32' || x.DataTyp === 'System.Int64') {
                            deltaFields.push({
                                Caption: x.TranslatedCaption,
                                Name: x.Name,
                                ID: x.ID
                            });
                            if (x.ID === oldDelta) {
                                this.SelectedDelta = oldDelta;
                            }
                        }
                    });
                    this.DeltaFilterColumns = deltaFields;
                }
            });
        }
    }
}

@Component({
    selector: 'wf-datasource-settings',
    templateUrl: './datasource.settings.html',
    styleUrls: ['./datasource.settings.css']
})
export class DatasourceSettings extends ADatasourceSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'datasourceWFModule';
        reg.Caption = '@@Datenquelle relational';
        reg.GroupID = 'reldataoperations';
        reg.Index = 10;
        reg.SettingsControl = DatasourceSettings;
        reg.SettingsTypeHelper = new DatasourceSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(protected dataService: DataModelService, protected settingsService: DataSettingsService,
        protected preview: DataPreviewService, protected translate: TranslateService) {
        super(dataService, settingsService, preview, translate);
    }

    getResultInstance(): ADatasourceSettingsData {
        return new DatasourceSettingsData();
    }

    showPreview() {
        DataSourcePreviewPanel.ShowPreview(this.getResult(), this.translate.instant('@@Vorschau'));
    }
}

@Component({
    selector: 'wf-datasource-object-settings',
    templateUrl: './datasource.settings.html',
    styleUrls: ['./datasource.settings.css']
})
export class DatasourceObjectSettings extends ADatasourceSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'datasourceObjectWFModule';
        reg.Caption = '@@Objekt Datenquelle';
        reg.GroupID = 'objectoperations';
        reg.Index = 10;
        reg.SettingsControl = DatasourceObjectSettings;
        reg.SettingsTypeHelper = new DatasourceObjectSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(protected dataService: DataModelService, protected settingsService: DataSettingsService,
        protected preview: DataPreviewService, protected translate: TranslateService) {
        super(dataService, settingsService, preview, translate);
        this.IsRelational = false;
    }

    getResultInstance(): ADatasourceSettingsData {
        return new DatasourceObjectSettingsData();
    }

    showPreview() {
        ObjectPreviewDialog.ShowDialog(this.getResult(), this.translate.instant('@@Vorschau'));
    }
}

// @dynamic
export abstract class ADatasourceSettingsData extends AServiceWorkflowData {
    DataModel: string;
    DataSource: string;
    @Type(() => InteractivePropertyInfo)
    InteractiveProperties: InteractivePropertyInfo[] = [];
    UseTable = true;
    ContainerTable: string;
    ContainerInternalID: string;
    DeltaColumn: string;
    @Type(() => RequestOptions)
    Filter: RequestOptions;
    @Type(() => DataPreviewInfo)
    Settings: DataPreviewInfo;

    ExtendedSerialize(module) {
        super.ExtendedSerialize(module);
        module.Settings = serialize(this);
    }
}

// @dynamic
export class DatasourceSettingsData extends ADatasourceSettingsData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.DataSource.DatasourceSettingsData';
    }
}

// @dynamic
export class DatasourceObjectSettingsData extends ADatasourceSettingsData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.DataSource.DatasourceObjectSettingsData';
    }
}

export class DatasourceSettingsDataHelper extends WorkflowModuleSettingsHelper {

    static getColumns(module) {
        const promise = new Promise<any[]>((resolve, reject) => {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                if (settings.UseTable) {
                    if (settings.ContainerTable) {
                        const service = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
                        service.GetContainer(settings.ContainerTable).subscribe(result => {
                            let list = null;
                            if (result && result.Fields) {
                                if (settings.Filter && settings.Filter.Columns && settings.Filter.Columns.length > 0) {
                                    list = [];
                                    settings.Filter.Columns.forEach(col => {
                                        const field = result.Fields.find(x => x.Name === col.Name);
                                        if (field) {
                                            list.push(field);
                                        }
                                    });
                                } else {
                                    list = result.Fields;
                                }
                            }
                            resolve(list);
                        });
                    } else {
                        resolve(null);
                    }
                } else if (settings.Settings) {
                    if (settings.Settings.Fields && settings.Settings.Fields.length > 0) {
                        const list = [];
                        settings.Settings.Fields.forEach(field => {
                            list.push(JSON.parse(field));
                        });
                        resolve(list);
                    } else {
                        const preview = InjectorHelper.InjectorInstance.get<DataPreviewService>(DataPreviewService);
                        preview.GetStructurePreview(settings.DataSource, settings.Settings).subscribe(result => {
                            if (result) {
                                resolve(result);
                            } else {
                                resolve(null);
                            }
                        });
                    }
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new DatasourceSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const list = await DatasourceSettingsDataHelper.getColumns(module);
            if (list) {
                const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
                state.set('DataSource', { Columns: list, DataModel: settings.DataModel });
            }
        }
    }
}

export class DatasourceObjectSettingsDataHelper extends WorkflowModuleSettingsHelper {
    static getStructure(module) {
        const promise = new Promise<any[]>((resolve, reject) => {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                if (settings.UseTable) {
                    if (settings.ContainerTable) {
                        const service = InjectorHelper.InjectorInstance.get<SelfServiceObjectService>(SelfServiceObjectService);
                        service.GetStructurePreviewFromContainer(settings.ContainerTable).subscribe(result => {
                            resolve(result);
                        });
                    } else {
                        resolve(null);
                    }
                } else if (settings.Settings) {
                    const preview = InjectorHelper.InjectorInstance.get<SelfServiceObjectService>(SelfServiceObjectService);
                    preview.GetStructurePreview(settings.DataSource, settings.Settings).subscribe(result => {
                        resolve(result);
                    });
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new DatasourceObjectSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const result = await DatasourceObjectSettingsDataHelper.getStructure(module);
            if (result) {
                const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
                state.set('DataSource', { Properties: result, DataModel: settings.DataModel });
            }
        }
    }
}

@Component({
    selector: 'wf-datasource-preview',
    templateUrl: './datasource.preview.panel.html'
})
export class DataSourcePreviewPanel {
    Columns;
    Rows;

    public static ShowPreview(initArgs, title) {
        BaseDialog.ShowDialog({
            ContentType: DataSourcePreviewPanel,
            InitArgs: initArgs,
            Title: title,
            Buttons: DialogButton.Ok,
            HeightText: '100vh',
            WidthText: '100vw'
        });
    }

    public static ShowPreviewForContainer(containerID, title) {
        BaseDialog.ShowDialog({
            ContentType: DataSourcePreviewPanel,
            InitArgs: {
                UseTable: true,
                ContainerTable: containerID
            },
            Title: title,
            Buttons: DialogButton.Ok,
            HeightText: '100vh',
            WidthText: '100vw'
        });
    }

    constructor(private preview: DataPreviewService, private dataService: DataModelService, private cdRef: ChangeDetectorRef) {
    }

    PrepareColumns(result) {
        const cols = [];
        if (result) {
            const calcWidth = Math.floor(100 / result.length) + '%';
            result.forEach(function (f) {
                if (f.TranslatedCaption) {
                    f.Caption = f.TranslatedCaption;
                } else if (typeof f.Caption !== 'string') {
                    delete f.Caption;
                }
                f.CalculatedWidth = calcWidth;
                cols.push(f);
            });
        }
        this.Columns = cols;
        this.cdRef.detectChanges();
    }
    Initialize(args) {
        if (args) {
            if (args.UseTable) {
                let clone;
                if (args.Filter) {
                    clone = JSON.parse(JSON.stringify(args.Filter));
                    if (clone.Columns) {
                        const colcount = clone.Columns.length;
                        if (colcount > 0) {
                            const cols = [];
                            clone.Columns.forEach((f) => {
                                f.IsVisible = true;
                                if (!f.Caption) {
                                    f.Caption = f.Name;
                                }
                                f.Width = {
                                    Value: (100 / colcount),
                                    Type: 1
                                };
                                cols.push(f);
                            });
                            this.Columns = cols;
                        }
                    }
                } else {
                    clone = RequestOptions.CleanRequestOptions();
                }
                clone.Top = 100;
                if (!this.Columns) {
                    this.dataService.GetContainer(args.ContainerTable).subscribe((result) => {
                        if (result) {
                            this.PrepareColumns(result.Fields);
                        }
                    });
                }
                this.preview.ExecuteQuery(args.ContainerTable, clone).subscribe((result) => {
                    if (result) {
                        this.fillRows(result);
                        this.cdRef.detectChanges();
                    }
                });
            } else {
                if (args.Settings) {
                    if (args.Settings.Fields) {
                        const fieldList = [];
                        args.Settings.Fields.forEach(function (field) {
                            const parsed = JSON.parse(field);
                            fieldList.push(parsed);
                        });
                        this.PrepareColumns(fieldList);
                        args.Settings.Fields = fieldList;
                    }
                    if (!args.Settings.Name) {
                        args.Settings.Name = '';
                    }
                    if (!args.Settings.Methodtype) {
                        args.Settings.Methodtype = 0;
                    }
                }
                if (!this.Columns) {
                    this.preview.GetStructurePreview(args.DataSource, serialize(args.Settings)).subscribe((result) => {
                        if (result) {
                            this.PrepareColumns(result.Fields);
                        }
                    });
                }
                this.preview.GetPreview(args.DataSource, serialize(args.Settings)).subscribe((result) => {
                    if (result) {
                        this.fillRows(result);
                        this.cdRef.detectChanges();
                    }
                });
            }
        }
    }

    private fillRows(val) {
        if (Array.isArray(val)) {
            if (val.length > 0) {
                const pd = [];
                let count = 0;
                val.forEach((row) => {
                    const toPush = new Row();
                    toPush.index = count++;
                    toPush.data = row;
                    pd.push(toPush);
                });
                this.Rows = pd;
            } else {
                this.Rows = val;
            }
        } else {
            this.Rows = [];
        }
    }
}
