export class RowSelection {
    RowIndex: number;
    RowData: any;
}

export class CellSelection {
    RowIndex: number;
    ColumnName: string;
}

export class OSSelectionRange {
    XStart: number;
    XEnd: number;
    YStart: number;
    YEnd: number;
    UseXStartForShift = true;
    UseYStartForShift = true;

    constructor(init?: Partial<OSSelectionRange>) {
        Object.assign(this, init);
    }

    ContainsOnX(x: number): boolean {
        return this.XStart <= x && x <= this.XEnd;
    }

    ContainsOnY(y: number): boolean {
        return this.YStart <= y && y <= this.YEnd;
    }

    Contains(x: number, y: number) {
        return this.ContainsOnX(x) && this.ContainsOnY(y);
    }
}

export class CopySelection {
    StartCell: CellSelection;
    Selection: OSSelectionRange
}