import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'odbcdb-panel',
    templateUrl: './odbcdb.panel.html',
    styleUrls: ['./odbcdb.panel.css']
})
export class ODBCDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = OdbcDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: ODBCDBPanel,
            DBInfoType: OdbcDBInfo
        };
    }
}

export class OdbcDBInfo extends ADBInfo {
    ConnectionString: string = null;

    getTypeName(): string {
        return 'evidanza.Database.Odbc.OdbcDBInfo';
    }
}