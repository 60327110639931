import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';

export class DisableDialogCloseSettingsData {
}

export class DisableDialogCloseSettingsDataHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'disableDialogCloseModule';
        reg.Caption = '@@Dialog geoeffnet lassen';
        reg.GroupID = 'wfActions';
        reg.Index = 46;
        reg.SettingsTypeHelper = new DisableDialogCloseSettingsDataHelper();
        reg.Executer = DisableDialogCloseModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new DisableDialogCloseSettingsData();
    }
}

export class DisableDialogCloseModuleExecuter extends WorkflowModuleExecuter {
    static DisableID = 'Disable_Dialog_Module_ID';

    async execute(status: WorkflowStatus): Promise<number> {
        status.Context.set(DisableDialogCloseModuleExecuter.DisableID, true);
        return 0;
    }
}
