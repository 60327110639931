import { Component } from '@angular/core';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { SelfServiceObjectService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { GenerateReaderSettingsData } from '../../dataoperations/generatereader/generate.reader.settings';

@Component({
    selector: 'wf-generate-object-reader-settings',
    templateUrl: './generate.object.reader.settings.html'
})
export class GenerateObjectReaderSettings extends ContainerWorkflowDialogContent {
    Data = new GenerateReaderSettingsData();
    DBUsages = [[32, 2097152]]; // DocumentStoreWrite + ObjectWrite

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'generateObjectReaderWFModule';
        reg.Caption = '@@ObjectReader generieren';
        reg.GroupID = 'objectoperations';
        reg.Index = 20;
        reg.SettingsControl = GenerateObjectReaderSettings;
        reg.SettingsTypeHelper = new GenerateObjectReaderSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            this.Data.DataModel = data.DataModel;
            this.Data.DataSource = data.DataSource;
            this.Data.ContainerTable = data.Container;
            this.Data.ContainerInternalID = data.ContainerInternalID;
        }
    }

    getResult(): any {
        const retVal = new GenerateObjectReaderSettingsData();
        retVal.DataModel = this.Data.DataModel;
        retVal.DataSource = this.Data.DataSource;
        retVal.Container = this.Data.ContainerTable;
        retVal.ContainerInternalID = this.Data.ContainerInternalID;
        return retVal;
    }
}

export class GenerateObjectReaderSettingsData extends AServiceWorkflowData {
    DataModel: string;
    DataSource: string;
    Container: string;
    ContainerInternalID: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.GenerateReader.GenerateObjectReaderSettingsData';
    }
}

export class GenerateObjectReaderSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new GenerateObjectReaderSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                const service = InjectorHelper.InjectorInstance.get<SelfServiceObjectService>(SelfServiceObjectService);
                const result = await service.GetStructurePreviewFromContainer(settings.Container).toPromise();
                if (result) {
                    state.set('DataSource', { Properties: result, DataModel: settings.DataModel });
                }
            }
        }
    }
}
