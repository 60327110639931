import { deserialize, serialize } from 'class-transformer';
import { MultidimensionalChartKeys } from '../controls/chart.data.model';
import { SeriesSettings } from '../controls/chart.model';
import { Color } from '../style/color.model';
import { SeriesInfo } from '../tasks/chartformattasks/chart.format.model';
import { StyleMerger } from './styleMerger';

export class SeriesValueStyle {
    ID = 0;
    Color: string;
    Opacity: number;
    StrokeWidth: number;
}

export class ChartStyleMerger {
    private _MaxStyleNumber = 0;
    private _MaxMergeStyleNumber = 0;
    private _ResultingStyles = new Map<number, Map<number, SeriesValueStyle>>();
    private _Styles = new Map<number, SeriesValueStyle>();

    static GetSetting(info: SeriesInfo) {
        if (info.Settings) {
            const retVal = deserialize(SeriesSettings, serialize(info.Settings));
            ChartStyleMerger.ApplySeriesInfo(retVal, info);
            return retVal;
        }
        return ChartStyleMerger.GetEmptyStyle(info);
    }

    static GetChartStyleMerger(context): ChartStyleMerger {
        if (context) {
            if (!context.ChartStyleMerger) {
                context.ChartStyleMerger = new ChartStyleMerger();
            }
            return context.ChartStyleMerger;
        }
        return null;
    }

    static MergeSeriesSettings(existing: SeriesSettings, merge: SeriesSettings): SeriesSettings {
        if (existing) {
            if (merge) {
                const retVal = Object.assign({}, existing, merge);
                // Unterobjekte kopieren
                ['TrendLine', 'FallingColor', 'RisingColor'].forEach(prop => {
                    if (merge[prop]) {
                        retVal[prop] = Object.assign({}, merge[prop]);
                    } else if (existing[prop]) {
                        retVal[prop] = Object.assign({}, existing[prop]);
                    }
                });
                return retVal;
            } else {
                return deserialize(SeriesSettings, serialize(existing));
            }
        } else if (merge) {
            return deserialize(SeriesSettings, serialize(merge));
        }
        return null;
    }

    static GetSVStyle(style: SeriesSettings): SeriesValueStyle {
        let retVal: SeriesValueStyle;
        if (style) {
            if (style.Color) {
                retVal = new SeriesValueStyle();
                const col = Color.FromHex(style.Color);
                if (col.A >= 0 && col.A < 255) {
                    retVal.Opacity = Math.round((col.A / 255) * 100) / 100;
                }
                retVal.Color = Color.HexFromColor(col, true);
            }
            if (typeof style.LineWidth === 'number' && style.LineWidth > 0) {
                if (!retVal) {
                    retVal = new SeriesValueStyle();
                }
                retVal.StrokeWidth = style.LineWidth;
            }
        }
        return retVal;
    }

    static GetEmptyStyle(info: SeriesInfo) {
        const retVal = new SeriesSettings();
        ChartStyleMerger.ApplySeriesInfo(retVal, info);
        return retVal;
    }

    static GetStyleText(style: SeriesValueStyle) {
        const styles = [];
        if (style) {
            if (style.Color) {
                styles.push('color: ' + style.Color);
            }
            if (typeof style.Opacity === 'number' && style.Opacity >= 0 && style.Opacity <= 1) {
                styles.push('opacity: ' + style.Opacity);
            }
            if (typeof style.StrokeWidth === 'number' && style.StrokeWidth > 0) {
                styles.push('stroke-width: ' + style.StrokeWidth);
            }
        }
        return styles.join('; ');
    }

    private static ApplySeriesInfo(setting: SeriesSettings, info: SeriesInfo) {
        setting['ColumnName'] = info.Caption;
        setting['ColumnKey'] = info.Key;
        setting['StyleKey'] = '_' + info.Key + '_';
    }

    private static Equals(source: SeriesValueStyle, target: SeriesValueStyle): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            return source.Color === target.Color && source.Opacity === target.Opacity && source.StrokeWidth === target.Opacity;
        } else {
            return check.RetVal;
        }
    }

    constructor() {
        const svs = new SeriesValueStyle();
        this._Styles.set(svs.ID, svs);
        this._ResultingStyles.set(svs.ID, new Map());
    }

    MergeStyle(actStyleID: number, mergeStyle: SeriesValueStyle): number {
        let mergeID = mergeStyle['MergeID'];
        if (typeof mergeID !== 'number') {
            mergeID = ++this._MaxMergeStyleNumber;
            mergeStyle['MergeID'] = mergeID;
        }
        if (typeof actStyleID !== 'number') {
            actStyleID = 0;
        }
        const resultDict = this._ResultingStyles.get(actStyleID);
        let resultStyle = resultDict.get(mergeID);
        if (!resultStyle) {
            const actStyle = this._Styles.get(actStyleID);
            const merge = Object.assign({}, actStyle, mergeStyle);
            this._Styles.forEach(v => {
                if (!resultStyle && ChartStyleMerger.Equals(v, merge)) {
                    resultStyle = v;
                }
            });
            if (!resultStyle) {
                resultStyle = merge;
                merge.ID = ++this._MaxStyleNumber;
                this._Styles.set(merge.ID, merge);
                this._ResultingStyles.set(merge.ID, new Map());
            }
            resultDict.set(mergeID, resultStyle);
        }
        return resultStyle.ID;
    }

    GetStyleObject() {
        let retVal;
        if (this._Styles.size > 0) {
            this._Styles.forEach(style => {
                const styleText = ChartStyleMerger.GetStyleText(style);
                if (styleText) {
                    if (!retVal) {
                        retVal = {};
                    }
                    retVal['' + style.ID] = styleText;
                }
            });
        }
        return retVal;
    }
    GetBubbleStyles() {
        const retVal = [];
        this._Styles.forEach((v, k) => {
            if (v && v.Color) {
                const ss = new SeriesSettings();
                ss.Color = v.Color;
                ss.LegendLabel = MultidimensionalChartKeys.BubbleStyle + k;
                retVal.push(ss);
            }
        });
        return retVal;
    }
}
