import { RequestBase } from './request-base';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DynamicTimeSettingsService extends RequestBase {
    BasePath = 'api/settings/dynamictimesettings';

    // #region DynamicTimePoint
    GetDynamicTimePoints(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDynamicTimePoints', this.options);
    }

    GetDynamicTimePointsFiltered(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetDynamicTimePointsFiltered', filter, this.options);
    }

    GetDynamicTimePoint(sid): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDynamicTimePoint?sid=' + sid, this.options);
    }

    SaveDynamicTimePoint(dtp): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveDynamicTimePoint', dtp, this.options);
    }

    DeleteDynamicTimePoint(sid): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteDynamicTimePoint?sid=' + sid, this.options);
    }

    ExecuteTimeQuery(query): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteTimeQuery', query, this.options);
    }
    // #endregion
}
