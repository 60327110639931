import { plainToClass } from 'class-transformer';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { StyleBase } from '../../../models/layoutbase.model';
import { WorkflowStatus } from '../../../models/workflow/workflow.model';

export class LayoutWrapper {

    constructor(private status: WorkflowStatus) {
    }

    setStyleByID(id, style) {
        if (this.status && this.status.WorkflowLayoutService && this.status.WorkflowLayoutService.Layout) {
            const elem = LayoutHelper.GetElementByID(this.status.WorkflowLayoutService.Layout, id);
            if (elem && elem.Element) {
                const layoutStyle = plainToClass(StyleBase, style);
                if (Array.isArray(elem.Element.WorkflowStyles)) {
                    elem.Element.WorkflowStyles.push(layoutStyle);
                } else {
                    elem.Element.WorkflowStyles = [layoutStyle];
                }
                elem.Element.onWorkflowStylesChanged();
                return true;
            }
        }
        return false;
    }

    undoStyleByID(id) {
        if (this.status && this.status.WorkflowLayoutService && this.status.WorkflowLayoutService.Layout) {
            const elem = LayoutHelper.GetElementByID(this.status.WorkflowLayoutService.Layout, id);
            if (elem && elem.Element) {
                elem.Element.WorkflowStyles = [];
                elem.Element.onWorkflowStylesChanged();
                return true;
            }
        }
        return false;
    }

    setStyleByName(name, style) {
        if (this.status && this.status.WorkflowLayoutService) {
            const elem = this.status.WorkflowLayoutService.getElementByName(name);
            if (elem && elem.Element) {
                const layoutStyle = plainToClass(StyleBase, style);
                if (Array.isArray(elem.Element.WorkflowStyles)) {
                    elem.Element.WorkflowStyles.push(layoutStyle);
                } else {
                    elem.Element.WorkflowStyles = [layoutStyle];
                }
                elem.Element.onWorkflowStylesChanged();
                return true;
            }
        }
        return false;
    }

    undoStyleByName(name) {
        if (this.status && this.status.WorkflowLayoutService) {
            const elem = this.status.WorkflowLayoutService.getElementByName(name);
            if (elem && elem.Element) {
                elem.Element.WorkflowStyles = [];
                elem.Element.onWorkflowStylesChanged();
                return true;
            }
        }
        return false;
    }
}