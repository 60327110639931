import {
    AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef,
    EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { jsPlumb } from 'jsplumb';
import { DebugWorkflowConnectionPage } from '../debugconsole/workflow/debug.workflow.connection.page';
import { TranslateFormatText } from '../helpers/array.helpers';
import { ClientHelper } from '../helpers/client.helper';
import { DialogHelper } from '../helpers/injector.helper';
import { KeyboardShortcuts, Unlisten } from '../helpers/keyboard.helper';
import { NotificationHelper } from '../helpers/notification.helper';
import { FormulaInputMode } from '../models/enums/formulainputmode.enum';
import { WorkflowDebugHelper } from '../helpers/workflow.debug.helper';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../models/enums/messageboxresult.enum';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { BreakPointInfo, ServiceWorkflowInfo } from '../models/workflow/workflow.debug.settings';
import {
    WorkflowConnectorData, WorkflowData, WorkflowExpression, WorkflowModuleData, WorkflowSaveObject, WorkflowStatus
} from '../models/workflow/workflow.model';
import { WebsocketService } from '../services/websocket.service';
import { WF_REGISTRY, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowNodeHelper, WorkflowService, WorkflowTreeNode } from '../services/workflow.service';
import { FormulaEditorDialog } from '../components/common/formulaEditor/formulaEditor.control';
import { BaseDialog, DialogData } from '../components/dialogs/basedialog/base.dialog';
import { MessageBox, MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { TemplateSettingsData } from './modules/template/template.settings';
import { WorkflowDialog, WorkflowDialogContent } from './workflow.dialog';
import { WorkflowEngine } from './workflow.engine';
import { WorkflowParamDialog } from './workflow.param.dialog';
import { WorkflowErrorType } from '../models/enums/workflowerrortype.enum';
import { EnumHelper } from '../helpers/enum.helper';
import { StartWfModuleSettings } from './modules/startend/start.wfmodule';
import { MailSettingsService } from "../services/mailsettings.service";
import { WorkflowCommunicationService } from "../services/workflow-communication.service";

@Component({
    selector: 'workflow-control',
    templateUrl: './workflow.control.html',
    styleUrls: ['./workflow.control.css']
})
export class WorkflowControl implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
    @ViewChild('content', { static: false }) content: ElementRef;
    @ViewChild('container', { static: false }) container: ElementRef;
    wfcID = Math.round(Math.random() * 999999999);
    dragItem;
    jsPlumbInstance;
    Instance;
    SelectedItem = -1;
    CopiedWorkflow: string;
    NextID = 0;
    AllModuls = [];
    FilteredModuls = [];
    @Output() ContentChanged = new EventEmitter<WorkflowData>();
    lastConnectionMoved = false;
    panZoomController;
    zoomValue = 1;
    wfItemSize = 100;

    SettingsTop = 0;
    SettingsLeft = null;
    SettingsRight = 0;
    SettingsBottom = null;

    ErrorTypes = [];

    DialogContent;
    SearchValue: string;
    initInfo = {
        Initializing: false,
        Modules: 0
    };
    //#region EditMode
    EditModeValue: boolean = true;

    @Input()
    get EditMode() {
        return this.EditModeValue;
    }
    set EditMode(val) {
        this.EditModeValue = val;
        this.EditModeChange.emit(this.EditModeValue);
        //this.cdRef.detectChanges();
    }

    @Output() EditModeChange = new EventEmitter<any>();
    //#endregion
    //#region Workflow
    WorkflowValue;

    @Input()
    get Workflow() {
        return this.WorkflowValue;
    }
    set Workflow(val) {
        this.WorkflowValue = val;
        this.WorkflowChange.emit(this.WorkflowValue);
        //this.cdRef.detectChanges();
    }

    @Output() WorkflowChange = new EventEmitter<any>();
    //#endregion
    //#region SettingsPanelVisible
    SettingsPanelVisibleValue: boolean = false;

    get SettingsPanelVisible() {
        return this.SettingsPanelVisibleValue;
    }
    set SettingsPanelVisible(val) {
        this.SettingsPanelVisibleValue = val;
        this.cdRef.detectChanges();
    }
    @Input() disableOptions: boolean;

    //#endregion
    //#region Data
    DataValue: WorkflowData = new WorkflowData();

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val && val !== this.DataValue) {
            this.initInfo.Modules = val.Modules.length;
            this.initInfo.Initializing = this.initInfo.Modules > 0;
            if (this.initInfo.Initializing) {
                val.Modules.forEach((module) => {
                    const desc = WF_REGISTRY.get(module.Module);
                    if (desc) {
                        module.Width = desc.Width;
                        module.Height = desc.Height;
                        module.ViewType = desc.ViewType;
                        module.Group = desc.GroupID;
                        module.Icon = desc.Icon;
                        module.GroupIcon = desc.GroupIcon;
                    }
                })
            }
            if (this.jsPlumbInstance) {
                this.jsPlumbInstance.reset(false);
                if (!this.initInfo.Initializing) {
                    this.jsPlumbInstance.bind('connection', this.connection.bind(this));
                    this.jsPlumbInstance.bind('connectionDetached', this.connectionDetached.bind(this));
                    this.jsPlumbInstance.bind('connectionMoved', this.connectionMoved.bind(this));
                }
            }
            this.DataValue = val;
            // NextID raussuchen
            let nextID = 0;
            val.Modules.forEach(mod => {
                if (mod.ID >= nextID) {
                    nextID = mod.ID + 1;
                }
            });
            this.NextID = nextID;
            this.DataChange.emit(this.DataValue);
        }
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    //#region EditOptions
    EditOptionsValue: WorkflowEditOptions = new WorkflowEditOptions();

    @Input()
    get EditOptions() {
        return this.EditOptionsValue;
    }
    set EditOptions(val) {
        if (val) {
            this.EditOptionsValue = val;

            const isService = this.EditOptionsValue && this.EditOptionsValue.IsService;
            const glTmp = WorkflowNodeHelper.GetList(isService);
            if (this.EditOptionsValue && this.EditOptionsValue.TemplateList && this.EditOptionsValue.TemplateList.length > 0) {
                let index = -1;
                const templateGroup = new WorkflowTreeNode(index--);
                templateGroup.TranslateCaption = '@@Templates';
                templateGroup.IsExpanded = true;
                templateGroup.GroupID = 'wfTemplates';
                templateGroup.Icon = 'wf_group_templates';
                templateGroup.HasChildren = true;
                templateGroup.Children = [];
                this.EditOptionsValue.TemplateList.forEach(temp => {
                    const childNode = new WorkflowTreeNode(index--);
                    childNode.TranslateCaption = temp.Name;
                    childNode.Module = TemplateSettingsData.ModuleID;
                    childNode['TemplateID'] = temp.Id;
                    childNode.Draggable = true;
                    templateGroup.Children.push(childNode);
                });
                glTmp.push(templateGroup);
            }
            this.AllModuls = glTmp;
            this.UpdateFiltered();
            this.EditOptionsChange.emit(this.EditOptionsValue);
        }
    }

    @Output() EditOptionsChange = new EventEmitter<any>();
    //#endregion
    ModuleSettingsActiveState = [true, false, false, false];
    unlisten: Unlisten;
    //#region Lifecycle
    constructor(private translate: TranslateService, private wfService: WorkflowService, private keyboardShortcuts: KeyboardShortcuts,
        private factoryResolver: ComponentFactoryResolver, private cdRef: ChangeDetectorRef, private mailSettingService: MailSettingsService, private workflowCommunicationService: WorkflowCommunicationService) {
        this.Instance = this;
        this.ErrorTypes = EnumHelper.GetDropdownValues(WorkflowErrorType);
    }
    dragScope = "modules";

    mailAttachmentData = null;
    ngOnInit(): void {
        this.jsPlumbInstance = jsPlumb.getInstance({
            Connector: ["Flowchart", { stub: 50, gap: 5, cornerRadius: 10 }],
            PaintStyle: { stroke: "var(--workflow-connector-stroke-color)", strokeWidth: 2 },
            HoverPaintStyle: { stroke: "var(--workflow-connector-stroke-hover)", strokeWidth: 2 },
        });
        this.dragScope = this.jsPlumbInstance.getDefaultScope();
        this.jsPlumbInstance.registerConnectionTypes({
            "basic": {
                connector: ["Flowchart", {
                    stub: 50,
                    gap: 5,
                    cornerRadius: 10
                }],
                paintStyle: { stroke: "var(--workflow-connector-stroke-color)", strokeWidth: 2 },
                hoverPaintStyle: { stroke: "var(--workflow-connector-stroke-hover)", strokeWidth: 2 },
                cssClass: "connector-normal"
            }
        });
        if (!this.initInfo.Initializing) {

            this.jsPlumbInstance.bind('connection', this.connection.bind(this));
            this.jsPlumbInstance.bind('connectionDetached', this.connectionDetached.bind(this));
            this.jsPlumbInstance.bind('connectionMoved', this.connectionMoved.bind(this));
        }
        this.unlisten = this.keyboardShortcuts.listen({

            'del': (event: KeyboardEvent): void => {
                this.deleteSelectedItem();
            },
            'c.Control': (event: KeyboardEvent): void => {
                if (event.ctrlKey) {
                    this.copySelectedItem();
                }
            },
            'v.Control': (event: KeyboardEvent): void => {
                if (event.ctrlKey) {
                    this.pasteItem();
                }
            },
        }, {
            priority: 200,
            terminalWhitelist: ['F2', 'F12']
        });

        this.mailSettingService.sendAttachment$.subscribe((formData) => {
            this.mailAttachmentData = formData;
        });
    }
    disableEdit: boolean = false;
    ngAfterViewInit(): void {
        if (this.disableOptions === true) {
            this.disableEdit = true
        }
        if (this.DataValue) {
            if (!this.DataValue.Modules || this.DataValue.Modules.length == 0) {

                const startmodule = this.getModule(StartWfModuleSettings.ModuleID, { X: 50, Y: 50 });
                const endmodule = this.getModule('endWFModule', { X: 50, Y: 250 });
                this.DataValue.Modules = [startmodule, endmodule];

                setTimeout(() => {
                    const con = new WorkflowConnectorData();
                    con.ExitModule = startmodule.ID;
                    con.ExitOption = 0;
                    con.EntryModule = endmodule.ID;
                    con.EntryOption = 0;
                    this.AddConnection(con);
                    this.onContentChanged();
                }, 100);
            } else if (this.DataValue.Connectors) {
                this.CheckStartModule();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    dragModule;
    dragJSConnections;
    sourceConnection;
    CheckStartModule() {
        const checkDict = new Map<number, WorkflowModuleData>();
        this.DataValue.Modules.forEach(mod => {
            checkDict.set(mod.ID, mod);
        });
        this.DataValue.Connectors.forEach(con => {
            checkDict.delete(con.EntryModule);
            if (typeof con.EntryOption !== 'number') {
                con.EntryOption = 0;
            }
        });
        if (checkDict.size > 1) {
            console.log('More than one start module found!');
        }
        if (checkDict.size == 1) {
            const module = checkDict.entries().next().value;
            if (!(module && module[1].Module == StartWfModuleSettings.ModuleID)) {
                const startmodule = this.getModule(StartWfModuleSettings.ModuleID, { X: 50, Y: 50 });
                this.DataValue.Modules.push(startmodule);
                setTimeout(() => {
                    const con = new WorkflowConnectorData();
                    con.ExitModule = startmodule.ID;
                    con.ExitOption = 0;
                    con.EntryModule = module[0];
                    con.EntryOption = 0;
                    this.AddConnection(con);
                    this.onContentChanged();
                }, 100);
            }
        }
        if (checkDict.size === 0) {
            console.log('No start module found!');
        }
    }
    onInitModule() {
        if (this.initInfo.Initializing) {
            this.initInfo.Modules--;
            if (this.initInfo.Modules === 0) {
                this.initInfo.Initializing = false;
                if (this.DataValue) {
                    this.jsPlumbInstance.setSuspendDrawing(true);
                    this.DataValue.Connectors.forEach(con => {
                        if (typeof con.EntryOption !== 'number') {
                            con.EntryOption = 0;
                        }
                        this.AddConnection(con);
                    });
                    this.jsPlumbInstance.setSuspendDrawing(false, true);
                }
                this.jsPlumbInstance.bind('connection', this.connection.bind(this));
                this.jsPlumbInstance.bind('connectionDetached', this.connectionDetached.bind(this));
                this.jsPlumbInstance.bind('connectionMoved', this.connectionMoved.bind(this));
            }
        }
    }
    InitLineDrag(jsCon) {
        if (jsCon && jsCon.canvas && jsCon.canvas.attributes) {
            jsCon.canvas.ondragenter = (event) => {
                this.lineDragEnter(event, jsCon);
            }
            jsCon.canvas.onmouseenter = (event) => {
                if (this.dragItem && this.InternalDrag) {
                    this.internalLineDragEnter(event, jsCon);
                }
            }
        }
    }
    SyncWorkflowConnections() {
        this.DataValue.Connectors = [];
        const cons = this.jsPlumbInstance.getAllConnections();
        if (cons) {
            cons.forEach((con) => this.DataValue.Connectors.push(this.GetWorkflowConnection(con)));
        } else {
            console.log('Connectors lost!');
            console.trace();
        }
    }
    GetUUID(jsCon) {
        const result = [];
        if (jsCon && jsCon.endpoints) {
            jsCon.endpoints.forEach((con) => {
                result.push(con.getUuid());
            });
        }
        return result;
    }
    GetWorkflowConnection(jsCon) {
        const uuids = this.GetUUID(jsCon);
        const origSourceIDs = uuids[0].split('_');
        const origTargetIDs = uuids[1].split('_');
        const con = new WorkflowConnectorData();
        con.EntryModule = parseInt(origTargetIDs[1], 10);
        con.EntryOption = parseInt(origTargetIDs[2], 10);
        con.ExitModule = parseInt(origSourceIDs[1], 10);
        con.ExitOption = parseInt(origSourceIDs[2], 10);
        return con;
    }
    GetModuleConnections(item) {
        const retVal = [];
        const cons = this.jsPlumbInstance.getAllConnections();
        if (cons) {
            cons.forEach((con) => {
                const uuids = this.GetUUID(con);
                if (uuids && uuids.length > 0) {
                    uuids.forEach((uuid) => {
                        const ids = uuid.split('_');
                        if (ids[1] == item.ID) {
                            retVal.push(con);
                        }
                    });
                }
            });
        }
        return retVal;
    }
    LineEnterPosition = null;
    lineDragEnter(event, jsCon) {
        this._LAST_MOUSE_POSITION = { x: event.pageX, y: event.pageY };
        if (this.dragItem) {
            if (this.dragJSConnections && this.dragJSConnections.indexOf(jsCon) > -1) {
                return;
            }
            if (this.dragItem.Module === TemplateSettingsData.ModuleID) {
                return;
            }
            const uuids = this.GetUUID(jsCon);
            this.CleanDragOptions();
            this.sourceConnection = this.GetWorkflowConnection(jsCon);
            this.RemoveConnection(jsCon);

            const container = document.getElementById('wfcontainer');
            const containerrect = container.getBoundingClientRect();
            const x = Math.round((event.clientX - containerrect.left) / this.zoomValue);
            const y = Math.round((event.clientY - containerrect.top) / this.zoomValue);
            this.LineEnterPosition = { X: x, Y: y };
            this.dragModule = this.getModule(this.dragItem.Module, { X: x, Y: y });
            this.DataValue.Modules.push(this.dragModule);
            this.onContentChanged();

            setTimeout(() => {
                if (!this.dragJSConnections) {
                    this.dragJSConnections = [];
                }
                //#region Source
                const newsource = [uuids[0], 'entry_' + this.dragModule.ID + '_0']
                const sourceConnector = this.jsPlumbInstance.connect({
                    uuids: newsource
                });
                this.dragJSConnections.push(sourceConnector);
                this.InitLineDrag(sourceConnector);
                //#endregion


                const desc = WF_REGISTRY.get(this.dragModule.Module);
                let newtarget_point = 0;
                if (desc && desc.SettingsTypeHelper) {
                    const points = desc.SettingsTypeHelper.getExitPoints(this.dragModule.Settings);
                    if (points.length > 0) {
                        newtarget_point = points[0].ID
                    }
                }
                //#region Target
                const newtarget = ['exit_' + this.dragModule.ID + '_' + newtarget_point, uuids[1]]
                const targetConnector = this.jsPlumbInstance.connect({
                    uuids: newtarget
                });
                this.dragJSConnections.push(targetConnector);
                this.InitLineDrag(targetConnector);
                //#endregion
                this.cdRef.detectChanges();
            }, 100);
        }
    }
    internalLineDragEnter(event, jsCon) {
        if (this.dragItem && this.DropPossible) {
            if (this.dragJSConnections && this.dragJSConnections.indexOf(jsCon) > -1) {
                return;
            }
            const uuids = this.GetUUID(jsCon);
            this.CleanDragOptions();
            this.sourceConnection = this.GetWorkflowConnection(jsCon);
            this.RemoveConnection(jsCon);
            const container = document.getElementById('wfcontainer');
            const containerrect = container.getBoundingClientRect();
            const x = Math.round((event.clientX - containerrect.left) / this.zoomValue);
            const y = Math.round((event.clientY - containerrect.top) / this.zoomValue);
            this.LineEnterPosition = { X: x, Y: y };
            if (!this.dragJSConnections) {
                this.dragJSConnections = [];
            }
            //#region Source
            const newsource = [uuids[0], 'entry_' + this.dragItem.ID + '_0']
            const sourceConnector = this.jsPlumbInstance.connect({
                uuids: newsource
            });
            this.dragJSConnections.push(sourceConnector);
            this.InitLineDrag(sourceConnector);
            //#endregion


            //#region Target
            const desc = WF_REGISTRY.get(this.dragItem.Module);
            let newtarget_point = 0;
            if (desc && desc.SettingsTypeHelper) {
                const points = desc.SettingsTypeHelper.getExitPoints(this.dragItem.Settings);
                if (points.length > 0) {
                    newtarget_point = points[0].ID
                }
            }
            const newtarget = ['exit_' + this.dragItem.ID + '_' + newtarget_point, uuids[1]]
            const targetConnector = this.jsPlumbInstance.connect({
                uuids: newtarget
            });
            this.dragJSConnections.push(targetConnector);
            this.InitLineDrag(targetConnector);
            //#endregion
            this.cdRef.detectChanges();
        }
    }
    //#endregion

    //#region JSPlumb
    AddConnection(item: WorkflowConnectorData) {
        const connector = this.jsPlumbInstance.connect({
            uuids: ['exit_' + item.ExitModule + '_' + item.ExitOption, 'entry_' + item.EntryModule + '_' + item.EntryOption]
        });
        this.InitLineDrag(connector);
        const label = this.getConnectorLabel(item.ExitModule, item.ExitOption);
        if (label !== '' && connector && connector.setLabel) {
            connector.setLabel(label);
        }
        this.SyncWorkflowConnections();
    }
    RemoveConnection(item: WorkflowConnectorData): boolean {
        const cons = this.jsPlumbInstance.getAllConnections();
        if (cons.indexOf(item) > -1) {
            this.jsPlumbInstance.deleteConnection(item)
        }
        this.SyncWorkflowConnections();
        return true;
    }
    CleanDragOptions() {
        if (this.dragModule || this.InternalDrag) {
            if (this.dragJSConnections) {
                this.dragJSConnections.forEach((item) => {
                    this.RemoveConnection(item);
                });
                this.dragJSConnections = null;
            }
            if (!this.InternalDrag) {
                const moduleindex = this.DataValue.Modules.indexOf(this.dragModule);
                if (moduleindex > -1) {
                    this.DataValue.Modules.splice(moduleindex, 1);
                }
            }
            if (this.sourceConnection) {
                this.AddConnection(this.sourceConnection);
                this.sourceConnection = null;
            }
            this.LineEnterPosition = null;
            this.overallchange = { x: 0, y: 0 };
        }
    }
    connection(info, event) {
        if (this.lastConnectionMoved) {
            this.lastConnectionMoved = false;
        } else {
            const endType = info.targetEndpoint.getParameter('EndPointType');
            if (typeof endType === 'string') {
                const sourceType = this.getEndPointType(info.sourceEndpoint, []);
                if (typeof sourceType !== 'string' || sourceType !== endType) {
                    this.RemoveConnection(info.connection);
                    return;
                }
            }
            const con = this.GetWorkflowConnection(info.connection);
            this.InitLineDrag(info.connection);
            const label = this.getConnectorLabel(con.ExitModule, con.ExitOption);
            if (label !== '') {
                info.connection.setLabel(label);
            }
            this.SyncWorkflowConnections();
            this.onContentChanged();
        }
    }
    connectionMoved(info, event) {
        const endType = info.newTargetEndpoint.getParameter('EndPointType');
        if (typeof endType === 'string') {
            const sourceType = this.getEndPointType(info.newSourceEndpoint, []);
            if (typeof sourceType !== 'string' || sourceType !== endType) {
                this.RemoveConnection(info.connection);
                this.connectionDetached({
                    sourceEndpoint: info.originalSourceEndpoint,
                    targetEndpoint: info.originalTargetEndpoint
                }, null);
                return;
            }
        }
        this.lastConnectionMoved = true;
        const origSourceIDs = info.originalSourceEndpoint.getUuid().split('_');
        const origTargetIDs = info.originalTargetEndpoint.getUuid().split('_');
        let connection;
        this.DataValue.Connectors.some(con => {
            if (con.EntryModule === parseInt(origTargetIDs[1], 10) && con.EntryOption === parseInt(origTargetIDs[2], 10) &&
                con.ExitModule === parseInt(origSourceIDs[1], 10) && con.ExitOption === parseInt(origSourceIDs[2], 10)) {
                connection = con;
                return true;
            }
            return false;
        });
        if (connection) {
            if (info.index === 0) { // Source
                const newSourceIDs = info.newSourceEndpoint.getUuid().split('_');
                connection.ExitModule = parseInt(newSourceIDs[1], 10);
                connection.ExitOption = parseInt(newSourceIDs[2], 10);
                this.InitLineDrag(info.connection);
                const label = this.getConnectorLabel(connection.ExitModule, connection.ExitOption);
                if (label !== '') {
                    info.connection.setLabel(label);
                }
            } else { // Target
                const newTargetIDs = info.newTargetEndpoint.getUuid().split('_');
                connection.EntryModule = parseInt(newTargetIDs[1], 10);
                connection.EntryOption = parseInt(newTargetIDs[2], 10);
                this.InitLineDrag(info.connection);
            }
            this.SyncWorkflowConnections();
            this.onContentChanged();
        }
    }
    connectionDetached(info, event) {
        this.SyncWorkflowConnections();
        this.onContentChanged();
    }

    getEndPointType(endPoint, idList) {
        let retVal = null;
        if (endPoint) {
            retVal = endPoint.getParameter('EndPointType');
            if (!retVal && this.DataValue) {
                const idSplit = endPoint.getUuid().split('_');
                const previous = WorkflowDialog.getPreviousModule(parseInt(idSplit[1], 10),
                    this.DataValue.Connectors, this.DataValue.Modules, idList);
                if (previous) {
                    const prevEnd = this.jsPlumbInstance.getEndpoint('exit_' + previous.Module.ID + '_' + previous.Connector.ExitOption);
                    retVal = this.getEndPointType(prevEnd, idList);
                }
            }
        }
        return retVal;
    }
    getConnectorLabel(modID: number, exitID: number) {
        let retVal: string;
        if (this.DataValue) {
            const mod = this.DataValue.Modules.find(x => x.ID === modID);
            if (mod && mod.Settings) {
                const desc = WF_REGISTRY.get(mod.Module);
                if (desc && desc.SettingsTypeHelper) {
                    let settings = mod.Settings;
                    if (typeof settings === 'string') {
                        settings = JSON.parse(settings);
                    }
                    retVal = desc.SettingsTypeHelper.getExitPointLabel(settings, exitID);
                }
            }
        }
        if (retVal) {
            return this.translate.instant(retVal);
        }
        return '';
    }

    //#endregion
    //#region DragHandling
    onDragStart(ev, module) {
        this.dragItem = module;
        if (ev && ev.DragData && ev.DragData.length > 0) {
            this.dragItem = ev.DragData[0];
        }
    }
    onDragEnd() {
        this.dragItem = null;
        this.dragModule = null;
    }
    //#endregion

    //#region AddItem
    async itemDrop(event) {
        if (!this.LineEnterPosition) {
            this.CleanDragOptions();
            const di = this.dragItem;
            if (di) {
                const x = Math.round(event.offsetX / this.zoomValue);
                const y = Math.round(event.offsetY / this.zoomValue);
                if (di.Module === TemplateSettingsData.ModuleID) {
                    const addType = await MessageBoxHelper.ShowDialog(
                        new TranslateFormatText('@@Auf welche Art wollen Sie das Template hinzufuegen?'),
                        new TranslateFormatText('@@Template'), MessageBoxButtons.None, MessageBoxIcon.Question, null, null,
                        [{ Caption: '@@Kopie', ID: 0 }, { Caption: '@@Referenz', ID: 1 }]);
                    if (addType === 0) { // Kopie -> Template laden und Module hinzufügen
                        this.wfService.LoadWorkflow(di.TemplateID).subscribe((result) => {
                            if (result) {
                                const modDict = new Map();
                                result.Modules.forEach(mod => {
                                    // Start und Ende nicht mitkopieren!
                                    if (mod.Module != StartWfModuleSettings.ModuleID && mod.Module != 'endWFModule') {
                                        const newID = this.NextID++;
                                        modDict.set(mod.ID, newID);
                                        mod.ID = newID;
                                        mod.XPos += x;
                                        mod.YPos += y;
                                        delete mod['__id']; // __id vom BaseObject muss deleted werden, da sonst Objekt mit alter __id geladen wird

                                        const desc = WF_REGISTRY.get(mod.Module);
                                        if (desc) {
                                            mod.Width = desc.Width;
                                            mod.Height = desc.Height;
                                            mod.Group = desc.GroupID;
                                            mod.ViewType = desc.ViewType;
                                        }

                                        const deserialized = WorkflowSaveObject.DeserializeModuleSettings(mod);
                                        this.DataValue.Modules.push(deserialized);
                                    }
                                });
                                this.setItemSelected(null, -1);
                                const cons = [];
                                result.Connectors.forEach(con => {
                                    const newEntryID = modDict.get(con.EntryModule);
                                    if (newEntryID) {
                                        const newExitID = modDict.get(con.ExitModule);
                                        if (newExitID) {
                                            con.EntryModule = newEntryID;
                                            con.ExitModule = newExitID;
                                            if (typeof con.EntryOption !== 'number') {
                                                con.EntryOption = 0;
                                            }
                                            // Connector ID? Wird nirgendwo gesetzt
                                            // __id vom BaseObject muss deleted werden, da sonst Objekt mit alter __id geladen wird
                                            delete con['__id'];
                                            this.DataValue.Connectors.push(con);
                                            cons.push(con);
                                        }
                                    }
                                });
                                this.cdRef.detectChanges();
                                cons.forEach(con => {
                                    this.lastConnectionMoved = true;
                                    this.AddConnection(con);
                                });
                                this.onContentChanged();
                            }
                        });
                    } else { // Referenz -> Template Modul hinzufügen
                        const mod = this.getModule(di.Module, { X: x, Y: y });
                        mod.Settings = new TemplateSettingsData();
                        mod.Settings.TemplateID = di.TemplateID;
                        mod.Settings.TemplateName = di.Caption;
                        this.DataValue.Modules.push(mod);
                        this.setItemSelected(null, mod.ID);
                        this.onContentChanged();
                        this.cdRef.detectChanges();
                    }
                } else {
                    const mod = this.getModule(di.Module, { X: x, Y: y });
                    this.DataValue.Modules.push(mod);
                    this.setItemSelected(null, mod.ID);
                    this.onContentChanged();
                }
            }
        } else {
            if (this.dragItem) {
                this.setItemSelected(null, this.dragModule.ID);
            }
            this.sourceConnection = null;
            this.dragModule = null;
            this.dragJSConnections = null;
        }
    }

    private getModule(moduleString, offset) {
        const module = new WorkflowModuleData();
        module.ID = this.NextID++;
        module.XPos = offset.X;
        module.YPos = offset.Y;
        module.Module = moduleString;
        const desc = WF_REGISTRY.get(moduleString);
        if (desc) {
            module.Width = desc.Width;
            module.Height = desc.Height;
            module.Group = desc.GroupID;
            module.ViewType = desc.ViewType;
            module.Icon = desc.Icon;
            module.GroupIcon = desc.GroupIcon;
            if (desc.SettingsTypeHelper) {
                module.Settings = desc.SettingsTypeHelper.getEmptySettingsInstance();
                if (module.Settings && module.Settings.getTypeName) {
                    module.SettingsType = module.Settings.getTypeName();
                }
                if (this.EditOptionsValue && (this.EditOptionsValue.IsService || this.EditOptionsValue.IsTemplate)) {
                    WorkflowSaveObject.SerializeModuleSettings(module);
                }
            }
        }

        return module;
    }
    //#endregion
    onContentChanged() {
        this.ContentChanged.emit(this.DataValue);
    }

    CancelEdit() {
        if (this.DialogContent && this.SelectedModule) {
            if (typeof (this.SelectedModule.Settings) == 'string') {
                this.DialogContent.initialize(JSON.parse(this.SelectedModule.Settings));
            }
            if (typeof (this.SelectedModule.Settings) == 'object') {
                this.DialogContent.initialize(this.SelectedModule.Settings);
            }
        }
        this.SelectedItem = -1; // um speichern Nachfrage zu unterbinden
        this.setItemSelected(null, -1);
    }

    onClickSaveModule() {
        const Data = this.DataValue.Modules.find((value) => value.ID == this.SelectedItem);
        const isSendmailExtendedWFModule = Data?.Module == "sendmailExtendedWFModule";
        if (!isSendmailExtendedWFModule) {
            this.SaveModule();
            return;
        }
        if (this.mailAttachmentData) {
            this.mailSettingService.addAttachment(this.mailAttachmentData).subscribe({
                next: (res) => {
                    this.SaveModule();
                },
                error: (err) => {
                    NotificationHelper.Error("@@There is an error while uploading attachment. Please try again", "@@Error");
                }
            });
        } else {
            this.SaveModule();
        }
    }
    SaveModule(): boolean {
        const dataObj = {
            Data: this.DataValue.Modules.find((value) => value.ID == this.SelectedItem),
            WFData: null,
            WFEditOptions: null,
            Size: 100
        };
        if (this.Instance) {
            dataObj.WFData = this.Data;
            dataObj.WFEditOptions = this.EditOptions;
        }
        if (dataObj.Data && this.DialogContent) {
            const check = this.DialogContent.checkData();
            if (check) {
                if (check.IsCorrect) {

                    if (dataObj.Data.Instance && dataObj.Data.Instance.setCaption) {
                        dataObj.Data.Instance.setCaption();
                    }

                    const settings = this.DialogContent.getResult();
                    const desc = WF_REGISTRY.get(dataObj.Data.Module);
                    if (desc && desc.SettingsTypeHelper && desc.SettingsTypeHelper.MustUpdateExitPoints) {
                        const retVal = {
                            ExitPoints: null
                        };
                        let oldSettings = dataObj.Data.Settings;
                        if (oldSettings) {
                            if (settings) {
                                if (typeof oldSettings === 'string') {
                                    oldSettings = JSON.parse(oldSettings);
                                }
                                const oldEndPoints = desc.SettingsTypeHelper.getExitPoints(oldSettings);
                                const newEndPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                                if (oldEndPoints.length === newEndPoints.length && this.DialogContent.EndpointUpdateNecessary !== true) {
                                    for (let i = 0; i < oldEndPoints.length; i++) {
                                        if (!oldEndPoints[i].equals(newEndPoints[i])) {
                                            retVal.ExitPoints = newEndPoints;
                                            break;
                                        }
                                    }
                                } else {
                                    retVal.ExitPoints = newEndPoints;
                                }
                            } else {
                                retVal.ExitPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                            }
                        } else if (settings) {
                            retVal.ExitPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                        }
                        if (retVal.ExitPoints && retVal.ExitPoints.length) {
                            let error = retVal.ExitPoints.find((value) => value.ID === -1);
                            if (!error) {
                                error = new WorkflowExitInfo();
                                error.ID = -1;
                                error.Label = '@@InternalError';
                                error.Type = 'error';
                                retVal.ExitPoints.push(error);
                            }
                            if (dataObj.Data.Instance && dataObj.Data.Instance.updateEndPoints) {
                                dataObj.Data.Instance.updateEndPoints(retVal.ExitPoints);
                            }
                        }
                    }
                    dataObj.Data.Settings = settings;
                    if (!this.checkEditedExpressions(dataObj.Data)) {
                        return false;
                    }

                    if (dataObj.WFEditOptions) {
                        if (dataObj.WFEditOptions.IsService) {
                            WorkflowSaveObject.SerializeModuleSettings(dataObj.Data);
                        } else if (dataObj.WFEditOptions.IsTemplate) {
                            WorkflowSaveObject.SerializeModuleSettings(dataObj.Data);
                            if (this.DialogContent.HasExpressions) {
                                // Formeln als Expressions merken
                                if (!dataObj.Data.Expressions) {
                                    dataObj.Data.Expressions = [];
                                }
                                const expressions = dataObj.Data.Expressions;
                                const props = this.DialogContent.GetExpressionProperties();
                                props.forEach(prop => {
                                    let val = settings;
                                    const propList = prop.Value.split('.');
                                    propList.some(pl => {
                                        val = val[pl];
                                        return typeof val === 'undefined' || val == null;
                                    });
                                    let exp: WorkflowExpression;
                                    let index = 0;
                                    expressions.some(e => {
                                        if (e.Property === prop.Value) {
                                            exp = e;
                                            return true;
                                        }
                                        index++;
                                        return false;
                                    });
                                    if (typeof val === 'string' && val.length > 2 && val[0] === '$' && val[val.length - 1] === '$') {
                                        // Expression vorhanden
                                        if (!exp) {
                                            exp = new WorkflowExpression();
                                            exp.Property = prop.Value;
                                            expressions.push(exp);
                                        }
                                        exp.Formula = val.substring(1, val.length - 1);
                                    } else if (exp) {
                                        expressions.splice(index, 1);
                                    }
                                });
                            }
                        }
                    }
                    this.SettingsPanelVisible = false;
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText(check.Error), new TranslateFormatText('@@Fehler'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Error);
                    return false;
                }
            }
        }
        this.wfService.detectOnSaveChanges.next(true);
        return true;
    }
    SelectedItems = null;
    //#region Selection
    IsSelected(ID) {
        const result = (ID == this.SelectedItem || (this.SelectedItems && this.SelectedItems.indexOf(ID) > -1));
        return result
    }
    setItemSelected(event, id) {
        if (event && event.ctrlKey == true && id > -1) {
            if (this.SelectedItem) {
                if (!this.SelectedItems) {
                    this.SelectedItems = [this.SelectedItem];
                }
                this.SelectedItems.push(id);
                this.SelectedItems.forEach((item) => {
                    this.jsPlumbInstance.addToDragSelection(this.wfcID + '_' + item);
                })
                return;
            }
        } else {
            this.jsPlumbInstance.clearDragSelection();
            this.SelectedItems = null;
        }
        const tempvisibility = this.SettingsPanelVisible;
        if (this.SelectedItem != id || !tempvisibility) {
            this.CheckForSave().then((x) => {
                if (x) {
                    this.SetSelection(id);
                }
            });
        } else {
            this.SetSelection(id);
        }
    }
    CheckForSave() {
        return new Promise<boolean>(resolve => {
            if (this.DialogContent && this.SelectedModule) {
                const checkMod = {
                    Settings: this.DialogContent.getResult()
                };
                WorkflowSaveObject.SerializeModuleSettings(checkMod);
                let settings;
                if (typeof checkMod.Settings === 'string') {
                    settings = JSON.parse(checkMod.Settings);
                } else {
                    settings = JSON.parse(JSON.stringify(checkMod.Settings));
                }
                delete settings['ID'];
                let original;
                if (typeof (this.SelectedModule.Settings) === 'string') {
                    original = JSON.parse(this.SelectedModule.Settings);
                } else {
                    original = JSON.parse(JSON.stringify(this.SelectedModule.Settings));
                }
                delete original['ID'];
                if (JSON.stringify(settings) != JSON.stringify(original)) {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie vorher speichern?'),
                        new TranslateFormatText('@@Speichern'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then((x) => {
                            let retVal = true;
                            if (x === MessageBoxResult.Yes) {
                                retVal = this.SaveModule();
                            }
                            resolve(retVal);
                        });
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }
    isWorkflow = false;
    workflowModuleID;
    SetSelection(id) {
        this.isWorkflow = false;
        this.DialogContent = null;
        this.AllProperties = [];
        this.SettingsPanelVisible = id != -1;
        this.ModuleSettingsActiveState = [true, false, false, false];
        setTimeout(async () => {
            this.SelectedItem = id;
            this.SelectedModule = this.DataValue.Modules.find((value) => value.ID == id);

            if (this.viewContainerRef) {
                this.viewContainerRef.clear();
            }
            if (id > -1) {
                const dataObj = {
                    Data: this.DataValue.Modules.find((value) => value.ID == id),
                    WFData: null,
                    WFEditOptions: null,
                    Size: 100
                };
                if (this.Instance) {
                    dataObj.WFData = this.Data;
                    dataObj.WFEditOptions = this.EditOptions;
                }
                if (dataObj && dataObj.Data) {
                    if (dataObj?.Data?.Module === "execWFModule") {
                        this.isWorkflow = true;
                        this.workflowModuleID = dataObj.Data.ID;
                    }
                    const desc = WF_REGISTRY.get(dataObj.Data.Module);
                    if (desc) {
                        if (desc.SettingsControl) {
                            const factory = this.factoryResolver.resolveComponentFactory(desc.SettingsControl);
                            const component = factory.create(this.viewContainerRef.parentInjector);
                            const dc = <WorkflowDialogContent>component.instance;
                            dc.WFData = dataObj.WFData;
                            dc.WFEditOptions = dataObj.WFEditOptions;
                            dc.ModuleID = dataObj.Data.ID;
                            if (dc.UseActualState) {
                                if (dataObj.WFData) {
                                    const params = dataObj.WFData['Parameters'];
                                    if (params && params.length > 0) {
                                        const list = [];
                                        params.forEach(x => {
                                            list.push(x.Name);
                                        });
                                        WorkflowModuleSettingsHelper.AddStatusKeysToState(dc.ActualState, list);
                                    }
                                    const modList = [];
                                    const idList = [];
                                    let prevModule = WorkflowDialog.getPreviousModule(dataObj.Data.ID, dataObj.WFData.Connectors,
                                        dataObj.WFData.Modules, idList);
                                    while (prevModule != null) {
                                        modList.push(prevModule.Module);
                                        prevModule = WorkflowDialog.getPreviousModule(prevModule.Module.ID,
                                            dataObj.WFData.Connectors, dataObj.WFData.Modules, idList);
                                    }
                                    for (let i = modList.length - 1; i >= 0; i--) {
                                        const actMod = modList[i];
                                        const actDesc = WF_REGISTRY.get(actMod.Module);
                                        if (actDesc && actDesc.SettingsTypeHelper) {
                                            await actDesc.SettingsTypeHelper.fillActualState(actMod, dc.ActualState, dataObj.WFData);
                                        }
                                    }
                                }
                            }

                            let settings;
                            if (typeof dataObj.Data.Settings === 'object') {
                                settings = JSON.parse(JSON.stringify(dataObj.Data.Settings));
                            } else {
                                settings = WorkflowSaveObject.DeserializeModuleSettings(dataObj.Data).Settings;
                            }
                            dc.initialize(settings);
                            this.viewContainerRef.insert(component.hostView);
                            this.DialogContent = dc;
                            this.AllProperties = this.DialogContent.GetExpressionProperties();
                        }
                    }
                }

                const settingsPanel = document.getElementById(this.wfcID + '_SettingsPanel');
                if (settingsPanel && this.container && this.container.nativeElement) {
                    if (settingsPanel.clientHeight > this.container.nativeElement.clientHeight) {
                        this.SettingsBottom = 0;
                    } else {
                        this.SettingsBottom = null;
                    }
                }
            }
            this.cdRef.detectChanges();
        }, 50);
    }
    //#endregion
    //#region Copy/Paste/Delete
    deleteSelectedItem() {
        const data = this.DataValue;
        const selected = this.SelectedItem;
        if (data && selected > -1) {
            if (this.SelectedModule && this.SelectedModule.Module == StartWfModuleSettings.ModuleID) {
                NotificationHelper.Error("@@ThisModuleCannotBeRemoved", "@@Error");
            } else {
                const config = {
                    data: {
                        Data: {
                            Message: new TranslateFormatText('@@Wollen Sie den Baustein loeschen?'),
                            Title: new TranslateFormatText('@@Frage'),
                            Buttons: MessageBoxButtons.YesNo,
                            Icon: MessageBoxIcon.Question
                        }
                    }
                };
                const dialogRef = DialogHelper.DialogInstance.open(MessageBox, config);
                dialogRef.afterClosed().subscribe(retVal => {
                    if (retVal === MessageBoxResult.Yes) {
                        for (let i = 0; i < data.Modules.length; i++) {
                            const mod = data.Modules[i];
                            if (mod.ID === selected) {
                                const desc = WF_REGISTRY.get(mod.Module);
                                if (desc && desc.SettingsTypeHelper) {
                                    //const jsP = this.jsPlumbInstance;
                                    let settings = mod.Settings;
                                    if (typeof settings === 'string') {
                                        settings = JSON.parse(settings);
                                    }
                                    const exitPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                                    if (exitPoints && exitPoints.length) {
                                        let error = exitPoints.find((value) => value.ID == -1);
                                        if (!error) {
                                            error = new WorkflowExitInfo();
                                            error.ID = -1;
                                            error.Label = '@@InternalError';
                                            error.Type = 'error';
                                            exitPoints.push(error);
                                        }
                                    }
                                }
                                data.Modules.splice(i, 1);
                                break;
                            }
                        }
                        this.SyncWorkflowConnections();
                        const connectors = data.Connectors.filter(value => value.EntryModule === selected || value.ExitModule === selected);

                        for (let i = data.Connectors.length - 1; i >= 0; i--) {
                            const con = data.Connectors[i];
                            if (con.EntryModule === selected || con.ExitModule === selected) {
                                data.Connectors.splice(i, 1);
                            }
                        }
                        if (connectors && connectors.length == 2) {
                            const con = new WorkflowConnectorData();

                            if (connectors[0].ExitModule == selected) {
                                con.EntryModule = connectors[0].EntryModule;
                                con.EntryOption = connectors[0].EntryOption;
                                con.ExitModule = connectors[1].ExitModule;
                                con.ExitOption = connectors[1].ExitOption;
                            } else {
                                con.EntryModule = connectors[1].EntryModule;
                                con.EntryOption = connectors[1].EntryOption;
                                con.ExitModule = connectors[0].ExitModule;
                                con.ExitOption = connectors[0].ExitOption;
                            }
                            setTimeout(() => {
                                this.AddConnection(con);
                            }, 100);

                        }
                        this.SelectedItem = -1; // um speichern Nachfrage zu unterbinden
                        this.setItemSelected(null, -1);
                        this.onContentChanged();
                        this.cdRef.detectChanges();
                    }
                });
            }
        }
    }
    copySelectedItem() {
        if (this.DataValue && this.SelectedItem > -1) {
            this.DataValue.Modules.some(mod => {
                if (mod.ID === this.SelectedItem) {
                    if (mod.Module == StartWfModuleSettings.ModuleID) {
                        NotificationHelper.Warn('@@WorkflowModuleCopyNotPossible', '@@Copy');
                    } else {
                        const saveObj = new WorkflowSaveObject();
                        saveObj.Modules.push(mod);
                        this.CopiedWorkflow = JSON.stringify(saveObj);
                        NotificationHelper.Info('@@WorkflowModuleCopied', '@@Copy');
                    }
                    return true;
                }
                return false;
            });
        }
    }
    pasteItem() {
        if (this.CopiedWorkflow) {
            const saveObj = JSON.parse(this.CopiedWorkflow);
            if (saveObj.Modules && saveObj.Modules.length > 0) {
                let mod = saveObj.Modules[0];
                mod.ID = this.NextID++;
                mod.XPos += 20;
                mod.YPos += 20;
                delete mod.__id; // __id vom BaseObject muss deleted werden, da sonst Objekt mit alter __id geladen wird

                const desc = WF_REGISTRY.get(mod.Module);
                if (desc) {
                    mod.Width = desc.Width;
                    mod.Height = desc.Height;
                    mod.Group = desc.GroupID;
                    mod.ViewType = desc.ViewType;
                    mod.Icon = desc.Icon;
                    mod.GroupIcon = desc.GroupIcon;
                }

                mod = plainToClass(WorkflowModuleData, mod);

                this.DataValue.Modules.push(mod);
                this.onContentChanged();
                this.CopiedWorkflow = JSON.stringify(saveObj); // SaveObject nochmal serialisieren, um Position jedesmal zu erhoehen
                this.cdRef.detectChanges();
            }
        }
    }
    //#endregion
    async Test(debug: boolean) {
        let data = this.DataValue;
        if (data && this.EditOptionsValue) {
            if (this.EditOptionsValue.IsService) {
                const channel = UUID.UUID();
                const subScription = WebsocketService.MessageReceived.subscribe((response) => {
                    const parsed = JSON.parse(response);
                    if (parsed && parsed.Channels && parsed.Channels.some(x => x === channel) &&
                        parsed.Type === 'evidanza.App.Shared.Workflow.Rest.WorkflowClientMessage') {
                        const content = JSON.parse(parsed.Content);
                        if (content) {
                            switch (parsed.Level) {
                                case NotificationLevel.Error:
                                    NotificationHelper.Error(content.Message, content.Title);
                                    break;
                                case NotificationLevel.Warning:
                                    NotificationHelper.Warn(content.Message, content.Title);
                                    break;
                                case NotificationLevel.Information:
                                    NotificationHelper.Info(content.Message, content.Title);
                                    break;
                            }
                            if (content.Unsubscribe === true) {
                                subScription.unsubscribe();
                                WebsocketService.Unsubscribe([{ Channel: channel, Level: [2, 3, 4] }]);
                            }
                        }
                    }
                });
                WebsocketService.Subscribe([{ Channel: channel, Level: [2, 3, 4] }]);
                const context = {
                    RunID: channel,
                    SendNotification: true,
                    SaveObject: data,
                    DebugInfo: null,
                    EnableLogging: false
                };
                if (debug) {
                    DebugWorkflowConnectionPage.CheckForSelfAttach();
                    const debugClient = WorkflowDebugHelper.DebugClient.getValue();
                    if (debugClient) {
                        const settings = WorkflowDebugHelper.DebugSettings.getValue();
                        if (settings && settings.Workflows) {
                            const sw = [];
                            const cw = [];
                            if (settings.Workflows.ServiceWorkflows) {
                                sw.push(...settings.Workflows.ServiceWorkflows);
                            }
                            if (settings.Workflows.ConnectorWorkflows) {
                                cw.push(...settings.Workflows.ConnectorWorkflows);
                            }
                            if (sw.length > 0 || cw.length > 0) {
                                context.DebugInfo = {
                                    DebugClient: debugClient,
                                    ExecutingClient: ClientHelper.ClientID,
                                    ServiceWorkflows: sw,
                                    ConnectorWorkflows: cw,
                                    WaitingTime: settings.WaitTimeout
                                };
                            }
                        }
                        const swID = data['SID'];
                        if (!swID || !context.DebugInfo || !context.DebugInfo.ServiceWorkflows.some(x => x.ID === swID)) {
                            let modID;
                            const checkDict = {};
                            if (!data.Modules.some(mod => {
                                checkDict[mod.ID] = true;
                                if (mod.Module == 'startWFModule' || mod.Module == 'startModule') {
                                    modID = mod.ID;
                                    return true;
                                }
                                return false;
                            })) {
                                data.Connectors.forEach(con => {
                                    delete checkDict[con.EntryModule];
                                });
                                const keys = Object.keys(checkDict);
                                if (keys.length === 1) {
                                    modID = parseInt(keys[0], 10);
                                }
                            }
                            if (typeof modID === 'number') {
                                const bpi = new BreakPointInfo();
                                bpi.BreakOnStart = true;
                                bpi.ModuleID = modID;
                                const sw = new ServiceWorkflowInfo();
                                sw.ID = swID ?? '00000000-0000-0000-0000-000000000000';
                                sw.Breakpoints.push(bpi);
                                if (context.DebugInfo) {
                                    context.DebugInfo.ServiceWorkflows.push(sw);
                                } else {
                                    context.DebugInfo = {
                                        DebugClient: debugClient,
                                        ExecutingClient: ClientHelper.ClientID,
                                        ServiceWorkflows: [sw]
                                    };
                                    if (settings) {
                                        context.DebugInfo.WaitingTime = settings.WaitTimeout;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    const debugClient = WorkflowDebugHelper.DebugClient.getValue();
                    if (debugClient) {
                        const settings = WorkflowDebugHelper.DebugSettings.getValue();
                        if (settings && settings.ShowServiceLogsOnTest) {
                            context.EnableLogging = true;
                        }
                    }
                }
                this.wfService.ExecuteWorkflowExecutionContext(context).subscribe();
                return;
            } else if (this.EditOptionsValue.IsTemplate) {
                const json = JSON.stringify(data);
                data = plainToClass(WorkflowSaveObject, JSON.parse(json));
                if (data.Modules) {
                    const modules = [];
                    data.Modules.forEach(x => {
                        modules.push(WorkflowSaveObject.DeserializeModuleSettings(x));
                    });
                    data.Modules = modules;
                }
            }
        }
        const status = new WorkflowStatus('', '');
        const sub = status.Logger.OnError.subscribe((err) => {
            NotificationHelper.Error(err, '@@Workflow');
        });
        const warnSub = status.Logger.OnWarning.subscribe((err) => {
            NotificationHelper.Warn(err, '@@Workflow');
        });
        NotificationHelper.Info('@@Test gestartet.', '@@Workflow');
        try {
            const engine = new WorkflowEngine(data, status);
            await engine.startExecution();
        } catch (ex) {
            NotificationHelper.Error('Error while executing workflow (' + ex + ')', '@@Workflow');
        }
        NotificationHelper.Info('@@Test beendet.', '@@Workflow');
        sub.unsubscribe();
        warnSub.unsubscribe();
    }
    ShowParamDialog() {
        const wfso: any = this.DataValue;
        if (wfso) {
            const data: DialogData = {
                ContentType: WorkflowParamDialog,
                InitArgs: wfso.Parameters || [],
                Title: this.translate.instant('@@Workflow Parameter'),
                Handler: (result) => {
                    if (result) {
                        wfso.Parameters = result;
                        this.wfService.detectOnSaveChanges.next(true);
                    }
                    return true;
                }
            };
            BaseDialog.ShowDialog(data);
        }
    }
    ModuleSelectionVisible = true;

    resetSearch() {
        this.SearchValue = null;
        this.UpdateFiltered();
    }

    //#region Expressions
    AllProperties = [];
    Variables = [];
    checkEditedExpressions(result) {
        if (result && result.Expressions) {
            if (result.Expressions.some(exp => !exp.Property || !exp.Formula)) {
                MessageBoxHelper.ShowDialog(
                    new TranslateFormatText('@@Bitte verbegeben Sie fuer jede Expression eine Eigenschaft und eine Formel'),
                    new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                return false;
            }
        }
        return true;
    }
    getProps(actExp) {
        const props = [];
        const expressions = this.SelectedModule.Expressions;
        this.AllProperties.forEach(function (prop) {
            if (!expressions.some(exp => {
                if (exp.Property == prop.Value) {
                    return actExp != exp;
                }
                return false;
            })) {
                props.push(prop);
            }
        });
        return props;
    }

    addExpression() {
        this.SelectedModule.Expressions.push({
            Property: null,
            Formula: null
        });
    }

    showFormulaDialog(exp) {
        const args = {
            Variables: this.Variables,
            InputMode: FormulaInputMode.VariableName,
            Formula: exp.Formula
        };
        FormulaEditorDialog.ShowDialog(args, result => {
            if (result && result.Formula) {
                exp.Formula = result.Formula;
            }
            return true;
        });
    }

    deleteExpression(index) {
        this.SelectedModule.Expressions.splice(index, 1);
    }
    //#endregion

    SelectedModule;

    //#region Zoom
    onScroll(ev) {
        if (ev && ev.getModifierState('Control')) {
            const delta = ev.deltaY;
            if (typeof delta === 'number') {
                if (delta < 0) {
                    this.increaseZoom();
                } else {
                    this.decreaseZoom();
                }
            }
            ev.stopPropagation();
            ev.preventDefault();
        }
    }
    createStyle() {
        return {
            'zoom': this.zoomValue
        };
    }

    increaseZoom() {
        this.zoomValue += 0.05;
        this.jsPlumbInstance.setZoom(this.zoomValue, true);
    }

    decreaseZoom() {
        if (this.zoomValue > 0.05) {
            this.zoomValue -= 0.05;
            this.jsPlumbInstance.setZoom(this.zoomValue, true);
        }
    }

    resetZoom() {
        this.zoomValue = 1;
        this.jsPlumbInstance.setZoom(this.zoomValue, true);
        this.ContentLeft = 0;
        this.ContentTop = 0;
    }

    optimalZoom() {
        if (this.DataValue && this.DataValue.Modules && this.DataValue.Modules.length > 0 && this.container) {
            let minLeft = Number.MAX_SAFE_INTEGER;
            let minTop = Number.MAX_SAFE_INTEGER;
            let maxWidth = 0;
            let maxHeight = 0;
            this.DataValue.Modules.forEach(x => {
                const left = Math.max(0, x.XPos - 10);
                if (left < minLeft) {
                    minLeft = left;
                }
                let width = this.wfItemSize;
                if (typeof x.Width === 'number') {
                    width = x.Width;
                }
                const right = x.XPos + width;
                if (right > maxWidth) {
                    maxWidth = right;
                }
                const top = Math.max(0, x.YPos - 10);
                if (top < minTop) {
                    minTop = top;
                }
                let height = this.wfItemSize;
                if (typeof x.Height === 'number') {
                    height = x.Height;
                }
                const bottom = x.YPos + height;
                if (bottom > maxHeight) {
                    maxHeight = bottom;
                }
            });
            maxWidth += 10 - minLeft;
            maxHeight += 10 - minTop;
            this.ContentTop = -minTop;
            this.ContentLeft = -minLeft;
            const zoom = Math.min(this.container.nativeElement.offsetWidth / maxWidth,
                this.container.nativeElement.offsetHeight / maxHeight);
            this.zoomValue = Math.round(zoom * 100) / 100;
            this.jsPlumbInstance.setZoom(this.zoomValue, true);
        }
    }

    getZoomText() {
        return Math.round(this.zoomValue * 100);
    }
    //#endregion
    //#region Pan
    DragContentContainer = null;
    ContentTop = 0;
    ContentLeft = 0;
    InternalDrag = false;
    _LAST_MOUSE_POSITION = { x: null, y: null };
    DropPossible = false;
    ItemMouseDown(event, item) {
        this.DropPossible = this.GetModuleConnections(item).length == 0;
        this.dragItem = item;
        this.InternalDrag = true;
        this._LAST_MOUSE_POSITION = { x: event.pageX, y: event.pageY };
    }
    ItemMouseUp(event, item) {
        if (!this.LineEnterPosition) {
            this.CleanDragOptions();
        } else {
            //if (this.dragItem) {
            //    this.setItemSelected(null, this.dragItem.ID);
            //}
            this.sourceConnection = null;
            this.dragModule = null;
            this.dragJSConnections = null;
        }
    }
    ItemDblClicked(event, item) {
        this.ShowSettingsFullscreen = true;
    }
    showInnerWorkflow() {
        const execWF = this.DataValue.Modules.find((value) => value.ID == this.workflowModuleID);
        if (execWF?.Module === 'execWFModule') {
            if (execWF?.Settings) {
                let WfSettings = execWF?.Settings
                let id = WfSettings?.WorkflowID
                if (id) {
                    this.wfService.LoadWorkflow(id).subscribe(val => {
                        //console.log("valllll ---> ", val)
                        if (val === null) return NotificationHelper.Warn("You can only view service workflow", "Invalid Service Workflow");
                        const wf = plainToClass(WorkflowSaveObject, val);
                        let workflowData = this.workflowCommunicationService.getWorkflowStack()
                        workflowData.push({
                            Workflow: this.WorkflowValue,
                            Data: this.DataValue,
                        })
                        this.workflowCommunicationService.setWorkflowStack(workflowData)
                        this.workflowCommunicationService.setSelectedWorkflow(wf)
                        this.workflowCommunicationService.setDialogStatus("inner")
                    })
                }
            }
        }
        this.cdRef.detectChanges();
    }
    ContentMouseDown(event) {
        if (!this.dragItem) {
            this.DragContentContainer = {
                TopMin: this.container.nativeElement.clientHeight / this.zoomValue - this.content.nativeElement.clientHeight,
                LeftMin: this.container.nativeElement.clientWidth / this.zoomValue - this.content.nativeElement.clientWidth
            };
        }
        this._LAST_MOUSE_POSITION = { x: event.pageX, y: event.pageY };
    }
    overallchange = { x: 0, y: 0 };
    DisconnectThreshold = 40;
    ContentMouseMove(event) {
        const current_mouse_position = { x: event.pageX, y: event.pageY };
        this.CheckMoveThreshold(event)
        if (this.DragContentContainer) {
            const change_x = (current_mouse_position.x - this._LAST_MOUSE_POSITION.x) / this.zoomValue;
            const change_y = (current_mouse_position.y - this._LAST_MOUSE_POSITION.y) / this.zoomValue;

            /* Save mouse position */
            this._LAST_MOUSE_POSITION = current_mouse_position;

            const top = this.ContentTop;
            const left = this.ContentLeft;

            let top_new = top + change_y;
            let left_new = left + change_x;

            if (top_new > 0) {
                top_new = 0;
            } else if (top_new < this.DragContentContainer.TopMin) {
                top_new = this.DragContentContainer.TopMin;
            }

            if (left_new > 0) {
                left_new = 0;
            } else if (left_new < this.DragContentContainer.LeftMin) {
                left_new = this.DragContentContainer.LeftMin;
            }

            this.ContentTop = top_new;
            this.ContentLeft = left_new;
        }
    }
    ContentDragOver(event) {
        this.CheckMoveThreshold(event);
    }
    CheckMoveThreshold(event) {
        const current_mouse_position = { x: event.pageX, y: event.pageY };
        if (this.LineEnterPosition) {
            const change_x = current_mouse_position.x - this._LAST_MOUSE_POSITION.x;
            const change_y = current_mouse_position.y - this._LAST_MOUSE_POSITION.y;

            this._LAST_MOUSE_POSITION = current_mouse_position;

            this.overallchange.x += change_x;
            this.overallchange.y += change_y;
            if (this.overallchange.x > this.DisconnectThreshold || this.overallchange.x < -this.DisconnectThreshold ||
                this.overallchange.y > this.DisconnectThreshold || this.overallchange.y < -this.DisconnectThreshold) {
                this.CleanDragOptions();
            }
        }
    }
    ContentMouseUp(event) {
        if (this.dragItem && this.InternalDrag) {
            this.jsPlumbInstance.revalidate(this.wfcID + '_' + this.dragItem.ID);
            this.dragItem = null;
            this.InternalDrag = false;
            this.LineEnterPosition = null;
            if (this.DropPossible) {
                this.SyncWorkflowConnections();
            }
            this.DropPossible = false;
            this.ContentChanged.emit();
        }
        this.DragContentContainer = null;
    }
    //#endregion

    ShowSettingsFullscreen = false;
    SettingsFullscreen() {
        this.ShowSettingsFullscreen = !this.ShowSettingsFullscreen;
        this.cdRef.detectChanges();
    }


    UpdateFiltered() {
        this.FilteredModuls = [];
        if (this.AllModuls) {
            if (this.SearchValue) {
                this.FilteredModuls = this.getFilteredList(this.AllModuls, this.SearchValue.toLowerCase());
            }
            else {
                this.FilteredModuls = this.AllModuls;
            }
        }
    }

    getFilteredList(originalList: any[], toLower: string) {
        const list = [];
        if (originalList) {
            originalList.forEach(x => {
                if (x.HasChildren) {
                    if (x.TranslateCaption && this.translate.instant(x.TranslateCaption).toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    } else if (x.Caption && x.Caption.toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    } else {
                        const childList = this.getFilteredList(x.Children, toLower);
                        if (childList.length > 0) {
                            const groupNode = new WorkflowTreeNode(x.ID);
                            groupNode.TranslateCaption = x.TranslateCaption;
                            groupNode.Caption = x.Caption;
                            groupNode.IsExpanded = x.IsExpanded;
                            groupNode.HasChildren = true;
                            groupNode.Children = childList;
                            list.push(groupNode);
                        }
                    }
                } else if (x.TranslateCaption) {
                    if (this.translate.instant(x.TranslateCaption).toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    }
                } else if (x.Caption) {
                    if (x.Caption.toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    }
                }
            });
        }
        return list;
    }

    RemoveWorkflowItem(item) {
        this.SelectedItem = item;
        this.deleteSelectedItem();
    }
}

export class WorkflowEditOptions {
    IsService = false;
    IsTemplate = false;
    TemplateList = [];
    Layout;
    ExternalOptions: any = {};
}
