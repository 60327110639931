import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Point } from '../../../models/basic/point.model';
import { GradientType } from '../../../models/enums/gradienttype.enum';
import { Color } from '../../../models/style/color.model';
import { Gradient } from '../../../models/style/gradient.model';
import { GradientStopColor } from '../../../models/style/gradientstopcolor.model';

@Component({
    selector: 'gradient-picker',
    templateUrl: './gradientpicker.control.html',
    styleUrls: ['./gradientpicker.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientPicker {
    vertical = false;
    colors: GradientStopColor[] = [];
    gradientType: GradientType = GradientType.Solid;
    selectedColor: GradientStopColor;
    selectedIndex = -1;
    //#region Gradient
    @Input()
    get Gradient() {
        const retVal = new Gradient();
        switch (this.gradientType) {
            case GradientType.Solid:
                if (this.colors.length > 0) {
                    const clone = GradientStopColor.FromObject(this.colors[0]);
                    clone.Offset /= 100;
                    retVal.Colors.push(clone);
                } else {
                    retVal.Colors.push(new GradientStopColor());
                }
                break;
            case GradientType.Gradient:
                if (this.colors.length > 0) {
                    this.colors.forEach((color) => {
                        const clone = GradientStopColor.FromObject(color);
                        clone.Offset /= 100;
                        retVal.Colors.push(clone);
                    });
                } else {
                    retVal.Colors.push(new GradientStopColor());
                }
                break;
            default:
                return null;
        }
        retVal.Type = this.gradientType;
        if (this.vertical) {
            retVal.StartPoint = new Point();
            retVal.StartPoint.X = 0.5;
            retVal.EndPoint = new Point();
            retVal.EndPoint.X = 0.5;
            retVal.EndPoint.Y = 1;
        } else {
            retVal.StartPoint = new Point();
            retVal.StartPoint.Y = 0.5;
            retVal.EndPoint = new Point();
            retVal.EndPoint.X = 1;
            retVal.EndPoint.Y = 0.5;
        }
        return retVal;
    }
    set Gradient(val: Gradient) {
        if (val && val.StartPoint && val.EndPoint && val.Colors) {
            this.vertical = val.StartPoint.Y < val.EndPoint.Y;
            const colList = [];
            val.Colors.forEach((gsc) => {
                const newGSC = GradientStopColor.FromObject(gsc);
                newGSC.Offset *= 100;
                colList.push(newGSC);
            });
            this.colors = colList;
            if (val.Type) {    
                this.setGradientType(val.Type);
            } else {
                this.setGradientType(GradientType.Solid)
            }
        } else {
            this.gradientType = GradientType.Solid;
            this.vertical = false;
            this.colors = [];
            this.setSelectedColorStopInternal(-1);
        }
    }
    //#endregion

    get SelectedColor() {
        if (this.selectedColor) {
            return this.selectedColor.Color;
        }
        return null;
    }

    setSelectedColorStop(index): void {
        this.setSelectedColorStopInternal(index);
    }

    setGradientType(type): void {
        this.gradientType = type;
        if (this.gradientType === GradientType.Solid) {
            if (this.colors.length < 1) {
                this.colors.push(new GradientStopColor());
            }
            this.setSelectedColorStopInternal(0);
        } else if (this.gradientType === GradientType.Gradient) {
            if (this.colors.length < 1) {
                this.colors.push(new GradientStopColor());
            }
            if (this.colors.length < 2) {
                const gsc = new GradientStopColor();
                gsc.Color = Color.FromObject(this.colors[0].Color);
                gsc.Offset = 100;
                this.colors.push(gsc);
            }
            this.setSelectedColorStopInternal(0);
        } else {
            this.setSelectedColorStopInternal(-1);
        }
    }

    setSelectedColorStopInternal(index) {
        this.selectedIndex = index;
        if (index >= 0 && index < this.colors.length) {
            this.selectedColor = this.colors[index];
        } else {
            this.selectedColor = null;
        }
    }

    getGradients(): Object {
        return Gradient.getStyleObject(this.Gradient);
    }

    addColorStop() {
        const gsc = new GradientStopColor();
        gsc.Offset = 50;
        this.colors.push(gsc);
        this.setSelectedColorStopInternal(this.colors.length - 1);
    }

    removeColorStop() {
        if (this.selectedColor && this.colors.length > 2) {
            this.colors.splice(this.selectedIndex, 1);
            this.setSelectedColorStopInternal(0);
        }
    }

    setVertical(vertical) {
        this.vertical = vertical;
    }

    ColorChange(ev) {
        if (this.selectedColor) {
            this.selectedColor.Color = ev;
        }
    }
}
