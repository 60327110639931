import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { of } from 'rxjs';
import { switchMap, tap, catchError, finalize } from 'rxjs/operators';

import { WorkflowService } from 'src/app/services/workflow.service';
import { StandardRequestBase } from 'src/app/services/request-base';
import { MetaService } from 'src/app/services/meta.service';
import { JiraService } from 'src/app/services/jira.service';
import { LayoutService } from 'src/app/services/layout.service';
import { Layout } from 'src/app/models/layout.model';
import { NavigationHelper } from 'src/app/helpers/navigation.helper';
import { NavigationService } from 'src/app/services/navigation.service';
import { UsersService } from 'src/app/services/users.service';
import { NotificationHelper } from 'src/app/helpers/notification.helper';
import { PageEditClient } from 'src/app/helpers/page.edit.client';
import { LayoutEditService } from 'src/app/models/layout/layout.edit.client';
import { CreateDialogFlagService } from '../create.flag.service';

@Component({
  selector: 'lib-create-app',
  templateUrl: './create-app.component.html',
  styleUrls: ['./create-app.component.css'],
})
export class CreateAppComponent implements OnInit {
  // SelectedItem
  Workflows: any[];
  LocalUserManagement: any;
  Pages: any[];
  Themes: any[];
  CSSFiles: any[];
  Jiras: any[];
  FAVIcon: any;
  PWAIcon: any;
  TempLayout: any;
  Subscription: any;
  UserSub: any;
  ActiveUser: any;

  constructor(
    private wfService: WorkflowService,
    private standardService: StandardRequestBase,
    private service: MetaService,
    private jira: JiraService,
    private cdref: ChangeDetectorRef,
    private createDialogFlagService: CreateDialogFlagService
  ) {}
  @Input() SelectedItem: any = { LoginRequired: true };
  @Input() isEditing: boolean = false;
  @Input() isApplicationProcess: boolean = false;
  @Output() isApplicationProcessChange = new EventEmitter<boolean>();
  @Input() action: 'application' | 'workflow' | 'both' = 'both';
  @Output() closeDialog = new EventEmitter();

  onToggleProcess(newValue: boolean) {
    this.isApplicationProcess = newValue;
    this.isApplicationProcessChange.emit(this.isApplicationProcess);
  }

  handleCloseDialog() {
    this.onToggleProcess(false);
    this.isEditing = false;
    this.SelectedItem = {};
    this.createDialogFlagService.setShowCreateDialog({ isShow: false, action: 'both' });
    this.closeDialog.emit();
  }

  ngOnInit(): void {
    if (this.SelectedItem.FAVIcon) {
      this.FAVIcon = this.SelectedItem.FAVIcon;
    }
    if (this.SelectedItem.PWAIcon) {
      this.PWAIcon = this.SelectedItem.PWAIcon;
    }
    NavigationService.SelectedPage.next('layout');
    this.UserSub = UsersService.ActiveUser.subscribe((x) => {
      this.ActiveUser = x;
    });
    this.LoadLocalUser();
    this.LoadWorkflows();
    this.LoadAllPages();
    this.LoadAllThemes();
    this.LoadCSSFiles();
    this.Subscription = LayoutService.LayoutEditMode.subscribe((x) => {
      if (!x && this.TempLayout) {
        LayoutService.BaseLayoutEditMode.next(false);
        const sel = this.SelectedItem;
        if (sel) {
          const layout = sel[this.TempLayout.Key];
          if (layout) {
            const actLayout = JSON.stringify(layout);
            if (actLayout !== this.TempLayout.Value) {
              this.OnItemChanged();
            }
          }
        }
        this.TempLayout = null;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['SelectedItem']) {
      this.FAVIcon = changes['SelectedItem'].currentValue.FAVIcon;
      this.PWAIcon = changes['SelectedItem'].currentValue.PWAIcon;
    }
  }

  handleStartCreate() {
    this.onToggleProcess(true);
  }

  OnItemChanged() {
    this.cdref.detectChanges();
  }

  LoadWorkflows() {
    this.wfService.GetAllServiceWorkflows().subscribe((data) => {
      this.Workflows = data;
    });
  }

  LoadLocalUser() {
    this.standardService.executeGet('config/api/config', 'GetManagementStatus').subscribe((result) => {
      this.LocalUserManagement = result;
    });
  }

  LoadAllPages() {
    this.service.GetAllPages().subscribe((pages) => {
      if (pages) {
        this.Pages = pages;
      }
    });
  }

  LoadAllThemes() {
    this.service.GetThemes(null).subscribe((themes) => {
      this.Themes = themes;
    });
  }

  LoadCSSFiles() {
    this.service.GetCustomCSS(null).subscribe((files) => {
      this.CSSFiles = files;
    });
  }

  LoadJiras() {
    this.jira.GetAll().subscribe((jiras) => {
      this.Jiras = jiras;
    });
  }

  OnKeyInput(event) {
    return String.fromCharCode(event.charCode).match(/[^a-zA-Z0-9]/g) === null;
  }

  validateInput(event: KeyboardEvent) {
    const char = String.fromCharCode(event.charCode);
    if (!/^[a-zA-Z0-9]*$/.test(char)) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    // Prevent the default paste behavior
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');

    // Filter to keep only alphanumeric characters
    const filteredData = pastedData.replace(/[^a-zA-Z0-9]/g, '');

    // Initialize _key if it's undefined
    if (!this.SelectedItem._key) {
      this.SelectedItem._key = '';
    }

    // Update the model with the filtered pasted data
    this.SelectedItem._key += filteredData; // Adjust as needed
  }

  resetBaseLayout() {
    if (this.SelectedItem) {
      this.SelectedItem.BaseLayout = null;
      this.OnItemChanged();
    }
  }

  resetLoginLayout() {
    if (this.SelectedItem) {
      this.SelectedItem.LoginLayout = null;
      this.OnItemChanged();
    }
  }

  UploadFAV(ev) {
    if (ev && ev.target) {
      const selectedFile = ev.target.files[0];
      if (selectedFile) {
        const fr = new FileReader();
        let item = this.SelectedItem;
        let that = this;
        fr.addEventListener(
          'load',
          function () {
            item.FAVIcon = fr.result;
            that.FAVIcon = fr.result;
            that.OnItemChanged();
          },
          false
        );
        fr.readAsDataURL(selectedFile);
      }
    }
  }

  UploadPWA(ev) {
    if (ev && ev.target) {
      const selectedFile = ev.target.files[0];
      if (selectedFile) {
        const fr = new FileReader();
        let item = this.SelectedItem;
        let that = this;
        fr.addEventListener(
          'load',
          function () {
            item.PWAIcon = fr.result;
            that.PWAIcon = fr.result;
            that.OnItemChanged();
          },
          false
        );
        fr.readAsDataURL(selectedFile);
      }
    }
  }

  editLayout() {
    const sel = this.SelectedItem;
    if (sel) {
      if (sel.BaseLayout) {
        if (!(sel.BaseLayout instanceof Layout)) {
          sel.BaseLayout = plainToClass(Layout, sel.BaseLayout);
        }
      } else {
        sel.BaseLayout = NavigationHelper.GetDefaultBaseLayout();
      }
      LayoutService.SelectedLayout.next(sel.BaseLayout);
      // LayoutService.BaseLayoutEditMode.next(true);
      LayoutService.LayoutEditMode.next(true);
      this.TempLayout = {
        Key: 'BaseLayout',
        Value: JSON.stringify(sel.BaseLayout),
      };
    }
  }

  editLogin() {
    const sel = this.SelectedItem;
    if (sel) {
      if (sel.LoginLayout) {
        if (!(sel.LoginLayout instanceof Layout)) {
          sel.LoginLayout = plainToClass(Layout, sel.LoginLayout);
        }
      } else {
        sel.LoginLayout = NavigationHelper.GetDefaultLoginLayout();
      }
      LayoutService.SelectedLayout.next(sel.LoginLayout);
      LayoutService.LayoutEditMode.next(true);
      this.TempLayout = {
        Key: 'LoginLayout',
        Value: JSON.stringify(sel.LoginLayout),
      };
    }
  }

  onSave() {
    if (!this.SelectedItem._key) {
      NotificationHelper.Error('@@Error', 'Please enter application name.');
      return;
    }

    const layout = new Layout();
    layout['ElementType'] = 'grid';
    layout['Name'] = 'Base';
    layout['PageName'] = `${this.SelectedItem._key}Home`;
    let NewPageUrl: string;
    let CreatedPageResponse = { SID: '' };
    LayoutService.Loading.next(true);
    this.service
      .AddPage(JSON.stringify(layout), `/${this.SelectedItem._key}-page`)
      .pipe(
        switchMap((response) => {
          if (response) {
            CreatedPageResponse = response;
            this.SelectedItem.LandingPage = response.URL;
            NewPageUrl = response.URL;
            return this.service.SaveNavigationStructureToChangeLog(this.SelectedItem);
          } else {
            return of(null);
          }
        }),
        switchMap((response) => {
          if (response) {
            return this.service.GetAllPages();
          } else {
            return of(null);
          }
        }),
        switchMap((response) => {
          if (response) {
            this.Pages = response;
            return this.service.LoadPage(NewPageUrl);
          } else {
            return of(null);
          }
        }),
        tap((response) => {
          if (response) {
            NotificationHelper.Success('@@Success', '@@App created successfully!');
          }
          this.handleCloseDialog();
          this.editLandingPage(CreatedPageResponse);
        }),
        catchError((error) => {
          NotificationHelper.Error('@@Error', 'Failed to create application.');
          return of(null);
        }),
        finalize(() => {
          LayoutService.Loading.next(false);
          this.OnItemChanged();
        })
      )
      .subscribe();
  }

  editLandingPage(page) {
    this.service.LoadPage(page.URL).subscribe((l) => {
      if (l) {
        const layout = plainToClass(Layout, l);
        const editClient = new PageEditClient(layout, page, this.service);
        editClient.PageInfoChanged.subscribe((pi) => {
          let index = -1;
          if (
            this.Pages.some((p, i) => {
              if (p.SID == pi.SID) {
                index = i;
                return true;
              }
            })
          ) {
            this.Pages.splice(index, 1, pi);
            this.checkItemStyle(pi);
          }
        });
        LayoutEditService.SetNext(editClient, true);
      }
    });
  }

  checkItemStyle(data) {
    data.previewURL = data.URL.includes('workspace') ? '/' + data.URL.split('/')[2] : data.URL;
    if (data) {
      const style = {};
      if (data.IsCapsule) {
        style['font-style'] = 'italic';
      }
      if (data.IsOverridden) {
        style['font-weight'] = 'bold';
      }
      if (data.CanEditInfo) {
        if (data.CanEditInfo.CanEdit) {
          if (this.ActiveUser && data.CanEditInfo.EditUserID == this.ActiveUser.SID) {
            style['text-decoration'] = 'underline';
          }
        } else {
          style['text-decoration'] = 'line-through';
        }
      }
      data.Style = style;
    }
  }

  onUpdate() {
    LayoutService.Loading.next(true);
    this.SelectedItem._key = this.SelectedItem.NonModified;
    delete this.SelectedItem.NonModified;
    this.service.RequestNavigationStructureLock(this.SelectedItem).subscribe((response) => {
      if (response) {
        this.FAVIcon = null;
        this.PWAIcon = null;
        NotificationHelper.Success('@@Success', '@@App updated successfully!');
      }
      LayoutService.Loading.next(false);
      this.handleCloseDialog();
    });
  }

  ngOnDestroy(): void {
    NavigationService.SelectedPage.next('settings');
    if (this.UserSub) {
      this.UserSub.unsubscribe();
      this.UserSub = null;
    }
    if (this.Subscription) {
      this.Subscription.unsubscribe();
      this.Subscription = null;
    }
    this.onToggleProcess(false);
    this.isEditing = false;
  }
}
