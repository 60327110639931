import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'checkbox-theme-control',
    templateUrl: './checkbox.control.html',
    styleUrls: ['./checkbox.control.css']
})
export class CheckBoxThemeControl extends BaseThemeControl {
    //#region IsChecked
    @Input()
    get IsChecked() {
        if (typeof this.DataSourceInternal === 'boolean') {
            return this.DataSourceInternal;
        }
        return false;
    }
    set IsChecked(val) {
        this.DataSourceInternal = val;
        this.IsCheckedChange.emit(val);
        this.cdRef.detectChanges();
    }

    @Output() IsCheckedChange = new EventEmitter<any>();
    //#endregion
    //#region LabelPosition
    LabelPositionValue;
    @Input()
    get LabelPosition() {
        return this.LabelPositionValue;
    }
    set LabelPosition(val) {
        this.LabelPositionValue = val;
        this.LabelPositionChange.emit(this.LabelPositionValue);
        this.cdRef.detectChanges();
    }

    @Output() LabelPositionChange = new EventEmitter<any>();
    //#endregion
    //#region Caption
    CaptionValue;
    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion    @Output() OnCheckedChanged = new EventEmitter<any>();

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    onChange(ev) {
        this.EmitAction(ev);
        this.OnCheckedChanged.emit(ev.checked);
    }
}
