import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { IPageInfo } from 'ngx-virtual-scroller';
import { of, Subject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { FilterHelper } from '../../../helpers/filter.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { FlexContentAlignment, FlexItemAlignment } from '../../../models/enums/alignment.enum';
import { Orientation } from '../../../models/enums/orientation.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-repeat',
    templateUrl: './repeating.layout.html',
    styleUrls: ['./repeating.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepeatingLayout extends IBaseComponent {

    static Type: any = 'repeat';
    static Default = {
        Editable: true,
        Type: 'repeat'
    };
    DisableAutoLoad = false;
    _Items;
    _Items$;
    _RawItems;
    @Input()
    public get Items() {
        return this._Items;
    }
    public set Items(val) {
        this._RawItems = val;
        this._Items = of(val);
        if (this.ItemsChange) {
            this.ItemsChange.emit(this._Items);
        }
        this.RefreshCount += 1;
    }
    @Output()
    public ItemsChange = new EventEmitter();

    OrientationValue;
    @Input()
    public get Orientation() {
        if (this.LayoutElement) {
            return this.LayoutElement.Orientation;
        } else {
            return this.Orientation;
        }
    }
    public set Orientation(val) {
        if (this.LayoutElement) {
            this.LayoutElement.Orientation = val;
            if (this.OrientationChange) {
                this.OrientationChange.emit(this.LayoutElement.Orientation);
            }
        } else {
            this.OrientationValue = val;
            if (this.OrientationChange) {
                this.OrientationChange.emit(this.OrientationValue);
            }
        }
        this.cdRef.detectChanges();
    }
    @Output()
    public OrientationChange = new EventEmitter();

    differ;
    dragItem;
    dragType;
    RepeatElements;
    RepeatElements$;
    //#region DataSource
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        if (this.DataBindingProperties && this.DataBindingProperties.IsDataBinding && !this.DataBindingProperties.IsShared) {
            this.updateRepeatElements();
        }
        this.DataSourceChange.emit(this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    DataBindingProperties;
    RepeatDataSource;

    _Horizontal = false;
    get Horizontal() {
        return this._Horizontal;
    }
    set Horizontal(value) {
        this._Horizontal = value;
        this.cdRef.detectChanges();
    }

    constructor(private dataService: DataService, private settingsService: SettingsService,
        cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }
    onInit() {
        this.ControlInitialized();
        this.setInitialized();
    }

    onLayoutElementSet() {
        this.SetOrientation();
        if (this.RepeatDataSource) {
            this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
        }
        this.Items = this.LayoutElementValue.Elements;
        this.updateRepeatElements();
    }

    SetOrientation() {
        let result = false;
        switch (this.LayoutElementValue.Orientation) {
            case Orientation.VerticalWrapping:
            case Orientation.VerticalReverseWrapping:
            case Orientation.Horizontal: 
            case Orientation.HorizontalReverse: result = true; break;

            case Orientation.HorizontalWrapping:
            case Orientation.HorizontalReverseWrapping:
            case Orientation.Vertical:
            case Orientation.VerticalReverse: result = false; break;
        }
        this.Horizontal = result;
    }

    onLayoutElementChanged() {
        this.SetOrientation();
        if (this.RepeatDataSource) {
            this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
        }
        this.Items = this.LayoutElementValue.Elements;
        this.updateRepeatElements();
    }

    ControlInitialized() {
        this.cdRef.detectChanges();
        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            this.updateRepeatElements();
        });
        this.Subscriptions["ViewType"] = LayoutService.ViewType.subscribe(x => {
            if (x == ViewType.View) {
                this.updateRepeatElements();
            }
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.cdRef.detectChanges();
        });
        MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
            if (result) {
                this.DataBindingProperties = result;
                if (result.IsDataBinding) {
                    if (result.IsShared) {
                        // console.log('shared');
                        // this.listDataSource = new SharedDataBindingSource(this, result.Table, this.dataService, this.DisableAutoLoad);
                    } else {
                        this.updateRepeatElements();
                    }
                } else {
                    this.RepeatDataSource = new DataSourceRepeatSource(this, result.Table, this.dataService);
                    this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
                    
                    if (!this.DisableAutoLoad) {
                        this.RepeatDataSource.LoadData();
                    }
                    this.DisableAutoLoad = false;
                }
            } else {
                // this.listDataSource = new SimpleDataSource(this, { Fields: [] });
            }
            if (this.RepeatDataSource) {
                this.RepeatDataSource.Loading.subscribe((status) => {
                    if (!status) {
                        this.DataSourceValue = this.RepeatDataSource.DataSource;
                        this.updateRepeatElements();
                    }
                });
            }
        });
    }

    updateRepeatElements() {
        const repeatElements = [];
        if (Array.isArray(this.DataSourceValue) && Array.isArray(this._RawItems)) {
            const json = JSON.stringify(this._RawItems);
            this.DataSourceValue.forEach((entry, index) => {
                const elements = [];
                const arrayCopy = JSON.parse(json);
                arrayCopy.forEach(item => {
                    const copy = plainToClass(LayoutElement, item);
                    copy['Parent'] = this.LayoutElementValue;
                    elements.push(copy);
                });
                repeatElements.push({
                    Data: entry,
                    Index: index,
                    Elements: elements,
                    FlexFill: (elements && elements.length > 0) ? elements[0].FlexFill : null
                });
            });
        }
        this.RepeatElements$ = of(repeatElements);
        this.RepeatElements = repeatElements;
        this.RefreshCount += 1;
        this.cdRef.detectChanges();
    }
    onScroll(event: IPageInfo) {
        if (this.RepeatDataSource && this.RepeatDataSource.initialized) {
            if (event && event.endIndex !== this.RepeatDataSource.DataSource.length - 1) return;
            if (this.RepeatDataSource) {
                this.RepeatDataSource.onScroll();
            }
        }
    }
    ExecuteRefresh() {
        if (this.DataBindingProperties) {
            if (this.DataBindingProperties.IsDataBinding) {
                if (this.DataBindingProperties.IsShared) {
                    // this.listDataSource = new SharedDataBindingSource(this, result.Table, this.dataService, this.DisableAutoLoad);
                } else {
                    this.updateRepeatElements();
                }
            } else {
                if (!this.RepeatDataSource) {
                    this.RepeatDataSource = new DataSourceRepeatSource(this, this.DataBindingProperties.Table, this.dataService);
                }
                this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
                this.RepeatDataSource.LoadData();
            }
        }
        if (this.RepeatDataSource) {
            this.RepeatDataSource.Loading.subscribe((status) => {
                if (!status) {
                    this.DataSourceValue = this.RepeatDataSource.DataSource;
                    this.updateRepeatElements();
                }
            });
        }
    }
    itemDrop(event) {
        let insert;
        insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
        insert.Parent = this.LayoutElement;
        this.LayoutElement.Elements.push(insert);
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(insert),
            Index: this.LayoutElementValue.Elements.length - 1,
            ParentID: this.LayoutElementValue.ID
        });
        event.currentTarget.style.backgroundColor = 'transparent';
    }

    removeItem(item) {
        const index = this.LayoutElement.Elements.indexOf(item);
        if (index > -1) {
            this.LayoutElement.Elements.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
        }
    }

    itemSelected(item, event) {
        if (!item.Selected) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
        }
    }
    GetDropOrientation() {
        switch (this.LayoutElement.Orientation) {
            case Orientation.Horizontal:
            case Orientation.HorizontalReverse:
            case Orientation.HorizontalWrapping:
            case Orientation.HorizontalReverseWrapping: return 'horizontal';
            case Orientation.Vertical:
            case Orientation.VerticalReverse:
            case Orientation.VerticalWrapping:
            case Orientation.VerticalReverseWrapping: return 'vertiacal';
        }
    }
    cdkDrop(event: CdkDragDrop<any[]>) {
        const item = this._Items[event.previousIndex];
        moveItemInArray(this._Items, event.previousIndex, event.currentIndex);
        LayoutService.OnLayoutElementMoved({
            ElementID: item.ID,
            Index: event.currentIndex,
            OldParentID: this.LayoutElementValue.ID,
            NewParentID: this.LayoutElementValue.ID
        });
    }

    GetClass(item, source) {
        if (item && source) {
            source.FlexFill = item.FlexFill;
        }
        if (item && item.FlexFill) {
            return 'forcefill';
        }
        if (source && source.FlexFill) {
            return 'forcefill';
        }
    }

    FirstTimeVisible = false;
    handleIntersection(visible: boolean) {
        if (visible && !this.FirstTimeVisible) {
            this.FirstTimeVisible = true;
            this.cdRef.detectChanges();
        }
    }
}

export class DataSourceRepeatSource {
    DataSourceID;
    DataSource: any[] = [];

    Loading: Subject<boolean> = new Subject();
    DataSourceElement;
    pageIndex = 0;
    pageLength = 100;
    loaded = false;
    initialized = false;

    constructor(component: RepeatingLayout, table, private dataService: DataService) {
        this.DataSourceID = table.SID;
        this.DataSourceElement = MetaHelper.FindValidParent(component.Layout, component.LayoutElement);
    }

    onFilterChanged() {
        this.pageIndex = 0;
        this.loaded = false;
        this.DataSource = [];
        this.LoadData();
    }

    onScroll() {
        this.LoadData();
    }

    LoadData() {
        if (!this.loaded && this.DataSourceID) {
            this.Loading.next(true);
            const filter = FilterHelper.PrepareFilter(this.DataSourceElement);
            filter.StartRow = this.pageIndex * this.pageLength;
            filter.EndRow = filter.StartRow + this.pageLength;
            this.dataService.SearchObjects('dynamicdata', this.DataSourceID, filter).subscribe((data) => {
                this.initialized = true;
                this.pageIndex += 1;
                if (data && data.length > 0) {
                    this.DataSource = [...this.DataSource, ...data];
                } else {
                    this.loaded = true;
                }
                this.Loading.next(false);
            });
        }
    }
}
export class RepeatingPanel extends BasePanel {
    static override SIDS = ['a3e5be2b-ec03-4877-ad94-939b5b5c6e4e']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'a3e5be2b-ec03-4877-ad94-939b5b5c6e4e',
            ID: 'repeat',
            Caption: '@@Repeat',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'repeat';
            }
        });


        PROPERTIES.push({
            ID: "Orientation",
            Parent: "repeat",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Orientation",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: Orientation
            }
        });
        PROPERTIES.push({
            ID: "FlexAlignItems",
            Parent: "repeat",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@AlignItems",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: FlexItemAlignment
            }
        });
        PROPERTIES.push({
            ID: "FlexAlignContent",
            Parent: "repeat",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@AlignContent",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: FlexContentAlignment
            }
        });
        PROPERTIES.push({
            ID: "DisableAutoLoad",
            Parent: "repeat",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@DisableAutoLoad"
            }
        });
        PROPERTIES.push({
            ID: "UseVirtualScroll",
            Parent: "repeat",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseVirtualScroll"
            }
        });
        PROPERTIES.push({
            ID: "PageSize",
            Parent: "repeat",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@PageSize",
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
    }
}