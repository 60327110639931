import { MultiResult } from '../../../models/datadescription/multi/multiresult.model';

export abstract class AExecutionManipulation {
    Context;

    abstract BeforeSplit();
    abstract BeforeExecute();
    abstract AfterExecute(result: MultiResult);

    Clear() {
        this.Context = null;
    }
}
