import { plainToClass } from 'class-transformer';
import { ValueFormulaNodeCalculator } from '../models/calculation/formula.node.calculator';
import { DataDescriptionRelational } from '../models/datadescription/relational/datadescriptionrelational.model';
import { RelQueryDef } from '../models/query/relquerydef.model';
import { RelRequestColumn } from '../models/query/relrequestcolumn.model';
import { FilterHelper } from './filter.helper';
import { AdvancedFormulaParser } from './formula.parser';

export class RelQueryHelper {
    public static GenerateQuery(DataDescription: DataDescriptionRelational, context): RelQueryDef {
        const retVal = new RelQueryDef();
        if (DataDescription) {
            DataDescription = plainToClass(DataDescriptionRelational, DataDescription)
            if (DataDescription.Fields && DataDescription.Fields.length > 0) {
                retVal.Columns = [];
                DataDescription.Fields.forEach((field) => {
                    const column = new RelRequestColumn();
                    column.ContainerID = field.ContainerID;
                    column.ContainerUniqueID = field.ContainerUniqueID;
                    column.DatasourceID = field.DataSourceID;
                    column.FieldID = field.FieldID;
                    column.UniqueID = field.ID;
                    if (typeof field.Aggregation === 'number') {
                        column.Aggregation = field.Aggregation;
                    }                    
                    retVal.Columns.push(column);
                });
                if (DataDescription.Relations) {
                    retVal.Relations = DataDescription.Relations
                } else {
                    retVal.Relations = [];
                }
                if (DataDescription.WhereFilter && DataDescription.WhereFilter.Filters) {
                    let variables = [];
                    if (context) {
                        variables = FilterHelper.GetVariables(context.LayoutElement);
                    }
                    const calculator = new ValueFormulaNodeCalculator(false, variables, new Map());
                    const parser = new AdvancedFormulaParser();
                    DataDescription.WhereFilter.Filters.forEach(f => {
                        let clone = JSON.parse(JSON.stringify(f));
                        clone = FilterHelper.ReplaceVariables(clone, (value) => { return calculator.Calc(parser.Parse(value)).Value });
                        if (!retVal.Filters) {
                            retVal.Filters = [];
                        }
                        retVal.Filters.push(clone);
                    });
                }
            }
        }
        return retVal;
    }
}
