import { OnInit, Component, Inject, ComponentFactoryResolver, Output, Input, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "../../../services/data.service";

@Component({
    selector: 'definememberattributedialog',
    templateUrl: './definememberattribute.dialog.html',
    styleUrls: ['./definememberattribute.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DefineMemberAttributeDialog implements OnInit {

    Data;
  Caption;
    //#region Dimensions
    DimensionsValue;

    @Input()
    get Dimensions() {
        return this.DimensionsValue;
    }
    set Dimensions(val) {
        this.DimensionsValue = val;
        this.DimensionsChange.emit(this.DimensionsValue);
    }

    @Output() DimensionsChange = new EventEmitter();
    //#endregion

    constructor(private dialogRef: MatDialogRef<DefineMemberAttributeDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private factoryResolver: ComponentFactoryResolver, private dataService: DataService) {
        this.Data = data.Data;
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dataService.GetList("dynamic", "GetDimensions").subscribe((data) => { this.Dimensions = data; });
    }

    onNoClick() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(true);
    }
}
