import { Component } from '@angular/core';

@Component({
    selector: 'about-settings',
    templateUrl: './about.settings.html',
    styleUrls: ['./about.settings.css']
})
export class AboutSettings {    
    static GetSettingsEntry() {
        return {
            Caption: '@@Info',
            ID: 'info',
            Icon: 'info',
            Index: 50,
            Parent: 'about',
            Security: {
                Name: 'evidanza.App.Shared.Security.AboutRight',
                Value: 5
            },
            Content: AboutSettings
        };
    }    
}
