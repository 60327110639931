import { ChartType } from '../models/enums/charttype.enum';
import { SeriesType } from '../models/enums/seriestype.enum';
import { ChartSettings, GaugeChartSettings } from '../models/controls/chart.model';
import { CacheService } from '../cache/cache.service';
import { ThemeHelper } from './theme.helpers';
import moment from 'moment';
declare var google: any;

export class ChartData {
    public static GenerateData(ChartSettings, Data, Styles) {
        if (ChartSettings) {
            if (typeof ChartSettings.ChartType === 'number' && Data && Data.length > 0) {
                const dataTable = [];
                const colCaptions = [];
                const colKeys = [];
                switch (ChartSettings.ChartType) {
                    case ChartType.AnnotationChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions)) {
                            ChartSettings.Series.forEach(x => {
                                if (ChartData.FillColLists(x, colKeys, colCaptions)) {
                                    if (typeof x.AnnotationTitleColumn === 'string') {
                                        colKeys.push(x.AnnotationTitleColumn);
                                        colCaptions.push(x.AnnotationTitleColumn);
                                    } else {
                                        colKeys.push('');
                                        colCaptions.push('');
                                    }
                                    if (typeof x.AnnotationTextColumn === 'string') {
                                        colKeys.push(x.AnnotationTextColumn);
                                        colCaptions.push(x.AnnotationTextColumn);
                                    } else {
                                        colKeys.push('');
                                        colCaptions.push('');
                                    }
                                }
                            });
                            dataTable.push(colCaptions);
                            Data.forEach(x => {
                                const row = [];
                                let date = x[colKeys[0]];
                                if (!(date instanceof Date)) {
                                    date = new Date(date);
                                }
                                row.push(date);
                                for (let i = 1; i < colKeys.length; i++) {
                                    row.push(x[colKeys[i]]);
                                }
                                dataTable.push(row);
                            });
                        }
                        break;
                    case ChartType.AreaChart:
                    case ChartType.Bar:
                    case ChartType.BarChart:
                    case ChartType.ColumnChart:
                    case ChartType.Line:
                    case ChartType.LineChart:
                    case ChartType.ScatterChart:
                    case ChartType.SteppedAreaChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions)) {
                            if (Styles) {
                                ChartSettings.Series.forEach(x => {
                                    if (ChartData.FillColLists(x, colKeys, colCaptions)) {
                                        if (typeof x.StyleKey === 'string') {
                                            colKeys.push({ key: x.StyleKey });
                                            colCaptions.push({ role: 'style' });
                                        }
                                        if (typeof x.LabelColumn === 'string') {
                                            colKeys.push(x.LabelColumn);
                                            colCaptions.push({ role: 'annotation' });
                                        }
                                    }
                                });
                                dataTable.push(colCaptions);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        if (typeof k === 'object') {
                                            let styleVal = null;
                                            const styleKey = x[k.key];
                                            if (styleKey) {
                                                styleVal = Styles[styleKey];
                                            }
                                            row.push(styleVal);
                                        } else {
                                            row.push(x[k]);
                                        }
                                    });
                                    dataTable.push(row);
                                });
                            } else {
                                ChartSettings.Series.forEach(x => {
                                    if (ChartData.FillColLists(x, colKeys, colCaptions)) {
                                        if (typeof x.LabelColumn === 'string') {
                                            colKeys.push(x.LabelColumn);
                                            colCaptions.push({ role: 'annotation' });
                                        }
                                    }
                                });
                                dataTable.push(colCaptions);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        row.push(x[k]);
                                    });
                                    dataTable.push(row);
                                });
                            }
                        }
                        break;
                    case ChartType.Histogram:
                    case ChartType.heatmap:
                    case ChartType.polarArea:
                    case ChartType.radar:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions)) {
                            ChartSettings.Series.forEach(x => {
                                ChartData.FillColLists(x, colKeys, colCaptions);
                            });
                            dataTable.push(colCaptions);
                            Data.forEach(x => {
                                const row = [];
                                colKeys.forEach(k => {
                                    row.push(x[k]);
                                });
                                dataTable.push(row);
                            });
                        }
                        break;
                    case ChartType.ComboChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions)) {
                            if (Styles) {
                                ChartSettings.Series.forEach(x => {
                                    if (x.Type === SeriesType.candlesticks) {
                                        if (typeof x.Minimum === 'string' && typeof x.Initial === 'string'
                                            && typeof x.Final === 'string' && typeof x.Maximum === 'string') {
                                            colCaptions.push(x.Minimum);
                                            colCaptions.push(x.Initial);
                                            colCaptions.push(x.Final);
                                            colCaptions.push(x.Maximum);
                                            colKeys.push(x.Minimum);
                                            colKeys.push(x.Initial);
                                            colKeys.push(x.Final);
                                            colKeys.push(x.Maximum);
                                            if (typeof x.StyleKey === 'string') {
                                                colKeys.push({ key: x.StyleKey });
                                                colCaptions.push({ role: 'style' });
                                            }
                                            if (typeof x.LabelColumn === 'string') {
                                                colKeys.push(x.LabelColumn);
                                                colCaptions.push({ role: 'annotation' });
                                            }
                                        }
                                    } else if (ChartData.FillColLists(x, colKeys, colCaptions)) {
                                        if (typeof x.StyleKey === 'string') {
                                            colKeys.push({ key: x.StyleKey });
                                            colCaptions.push({ role: 'style' });
                                        }
                                        if (typeof x.LabelColumn === 'string') {
                                            colKeys.push(x.LabelColumn);
                                            colCaptions.push({ role: 'annotation' });
                                        }
                                    }
                                });
                                dataTable.push(colCaptions);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        if (typeof k === 'object') {
                                            let styleVal = null;
                                            const styleKey = x[k.key];
                                            if (styleKey) {
                                                styleVal = Styles[styleKey];
                                            }
                                            row.push(styleVal);
                                        } else {
                                            row.push(x[k]);
                                        }
                                    });
                                    dataTable.push(row);
                                });
                            } else {
                                ChartSettings.Series.forEach(x => {
                                    if (x.Type === SeriesType.candlesticks) {
                                        if (typeof x.Minimum === 'string' && typeof x.Initial === 'string'
                                            && typeof x.Final === 'string' && typeof x.Maximum === 'string') {
                                            colCaptions.push(x.Minimum);
                                            colCaptions.push(x.Initial);
                                            colCaptions.push(x.Final);
                                            colCaptions.push(x.Maximum);
                                            colKeys.push(x.Minimum);
                                            colKeys.push(x.Initial);
                                            colKeys.push(x.Final);
                                            colKeys.push(x.Maximum);
                                            if (typeof x.LabelColumn === 'string') {
                                                colKeys.push(x.LabelColumn);
                                                colCaptions.push({ role: 'annotation' });
                                            }
                                        }
                                    } else if (ChartData.FillColLists(x, colKeys, colCaptions)) {
                                        if (typeof x.LabelColumn === 'string') {
                                            colKeys.push(x.LabelColumn);
                                            colCaptions.push({ role: 'annotation' });
                                        }
                                    }
                                });
                                dataTable.push(colCaptions);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        row.push(x[k]);
                                    });
                                    dataTable.push(row);
                                });
                            }
                        }
                        break;
                    case ChartType.BubbleChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const bubbleSeries = ChartSettings.Series[0];
                            if (typeof bubbleSeries.XValue === 'string' && typeof bubbleSeries.YValue === 'string') {
                                if (typeof bubbleSeries.IDColumn === 'string') {
                                    colKeys.push(bubbleSeries.IDColumn);
                                } else {
                                    colKeys.push('');
                                }
                                colKeys.push(bubbleSeries.XValue);
                                colKeys.push(bubbleSeries.YValue);
                                if (typeof bubbleSeries.Grouping === 'string') {
                                    colKeys.push(bubbleSeries.Grouping);
                                    if (typeof bubbleSeries.Radius === 'string') {
                                        colKeys.push(bubbleSeries.Radius);
                                    }
                                } else if (typeof bubbleSeries.Radius === 'string') {
                                    colKeys.push('');
                                    colKeys.push(bubbleSeries.Radius);
                                }
                                dataTable.push(colKeys);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        row.push(x[k]);
                                    });
                                    dataTable.push(row);
                                });
                            }
                        }
                        break;
                    case ChartType.PieChart:
                    case ChartType.DonutChart:
                    case ChartType.radialBar:
                    case ChartType.GeoChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions) &&
                            ChartData.FillColLists(ChartSettings.Series[0], colKeys, colCaptions)) {
                            dataTable.push(colCaptions);
                            Data.forEach(x => {
                                const row = [];
                                colKeys.forEach(k => {
                                    row.push(x[k]);
                                });
                                dataTable.push(row);
                            });
                        }
                        break;
                    case ChartType.Map:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions) &&
                            ChartData.FillColLists(ChartSettings.Series[0], colKeys, colCaptions)) {
                            dataTable.push(colCaptions);
                            Data.forEach(x => {
                                const row = [];
                                colKeys.forEach(k => {
                                    row.push('' + x[k]);
                                });
                                dataTable.push(row);
                            });
                        }
                        break;
                    case ChartType.WordTree:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const wordTreeSeries = ChartSettings.Series[0];
                            if (typeof wordTreeSeries.IDColumn === 'string' && typeof wordTreeSeries.LabelColumn === 'string'
                                && typeof wordTreeSeries.ParentID === 'string' && typeof wordTreeSeries.ColumnName === 'string') {
                                colKeys.push(...[wordTreeSeries.IDColumn, wordTreeSeries.LabelColumn,
                                wordTreeSeries.ParentID, wordTreeSeries.ColumnName]);
                                dataTable.push(colKeys);
                                const firstRow = Data[0];
                                const idType = typeof firstRow[colKeys[0]];
                                if (idType === 'number') {
                                    Data.forEach(x => {
                                        const row = [];
                                        colKeys.forEach(k => {
                                            row.push(x[k]);
                                        });
                                        dataTable.push(row);
                                    });
                                } else {
                                    let index = 0;
                                    const map = new Map();
                                    const rows = [];
                                    Data.forEach(x => {
                                        const val = x[colKeys[0]];
                                        let id = map.get(val);
                                        if (typeof id !== 'number') {
                                            id = index++;
                                            map.set(val, id);
                                        }
                                        rows.push([id, x[colKeys[1]], -1, x[colKeys[3]]]);
                                    });
                                    for (let i = 0; i < rows.length; i++) {
                                        const val = Data[i][colKeys[2]];
                                        const id = map.get(val);
                                        if (typeof id === 'number') {
                                            rows[i][2] = id;
                                        }
                                    }
                                    dataTable.push(...rows);
                                }
                            }
                        }
                        break;
                    case ChartType.OrgChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const orgSeries = ChartSettings.Series[0];
                            if (typeof orgSeries.IDColumn === 'string') {
                                colKeys.push(orgSeries.IDColumn);
                                if (typeof orgSeries.ParentID === 'string') {
                                    colKeys.push(orgSeries.ParentID);
                                    if (typeof orgSeries.Description === 'string') {
                                        colKeys.push(orgSeries.Description);
                                    }
                                } else if (typeof orgSeries.Description === 'string') {
                                    colKeys.push('');
                                    colKeys.push(orgSeries.Description);
                                }
                                dataTable.push(colKeys);
                                Data.forEach(x => {
                                    const row = [];
                                    colKeys.forEach(k => {
                                        row.push('' + x[k]);
                                    });
                                    dataTable.push(row);
                                });
                            }
                        }
                        break;
                    case ChartType.Gauge:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions) &&
                            ChartData.FillColLists(ChartSettings.Series[0], colKeys, colCaptions)) {
                            dataTable.push(colCaptions);
                            let min = 0;
                            let max = 0;
                            Data.forEach(x => {
                                const row = [x[colKeys[0]]];
                                const val = x[colKeys[1]];
                                if (val < min) {
                                    min = val;
                                } else if (val > max) {
                                    max = val;
                                }
                                row.push(val);
                                dataTable.push(row);
                            });
                            let gauge = ChartSettings.GaugeChartSettings;
                            if (!gauge) {
                                gauge = new GaugeChartSettings();
                                ChartSettings.GaugeChartSettings = gauge;
                            }
                            gauge.MinValue = min;
                            gauge.MaxValue = max;
                        }

                        break;
                    case ChartType.TreeMap:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const treeMapSeries = ChartSettings.Series[0];
                            if (typeof treeMapSeries.IDColumn === 'string' && typeof treeMapSeries.ParentID === 'string'
                                && typeof treeMapSeries.ColumnName === 'string') {
                                if (typeof treeMapSeries.ColorValue === 'string') {
                                    dataTable.push([treeMapSeries.IDColumn, treeMapSeries.ParentID,
                                    treeMapSeries.ColumnName, treeMapSeries.ColorValue]);
                                    Data.forEach(x => {
                                        let parent = x[treeMapSeries.ParentID];
                                        if (typeof parent !== 'undefined' && parent != null) {
                                            parent = '' + parent;
                                        } else {
                                            parent = null;
                                        }
                                        dataTable.push(['' + x[treeMapSeries.IDColumn], parent,
                                        x[treeMapSeries.ColumnName], x[treeMapSeries.ColorValue]]);
                                    });
                                } else {
                                    dataTable.push([treeMapSeries.IDColumn, treeMapSeries.ParentID, treeMapSeries.ColumnName]);
                                    Data.forEach(x => {
                                        let parent = x[treeMapSeries.ParentID];
                                        if (typeof parent !== 'undefined' && parent != null) {
                                            parent = '' + parent;
                                        } else {
                                            parent = null;
                                        }
                                        dataTable.push(['' + x[treeMapSeries.IDColumn], parent, x[treeMapSeries.ColumnName]]);
                                    });
                                }
                            }
                        }
                        break;
                    case ChartType.Calendar:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions) &&
                            ChartData.FillColLists(ChartSettings.Series[0], colKeys, colCaptions)) {
                            dataTable.push(colCaptions);
                            Data.forEach(x => {
                                const row = [];
                                let date = x[colKeys[0]];
                                if (!(date instanceof Date)) {
                                    date = new Date(date);
                                }
                                row.push(date);
                                row.push(x[colKeys[1]]);
                                dataTable.push(row);
                            });
                        }
                        break;
                    case ChartType.Gantt:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const ganttSeries = ChartSettings.Series[0];
                            if (typeof ganttSeries.TaskID === 'string' && typeof ganttSeries.TaskName === 'string'
                                && typeof ganttSeries.Completed === 'string') {
                                const colKeys = [ganttSeries.TaskID, ganttSeries.TaskName];
                                let resource = ganttSeries.Grouping;
                                if (typeof resource === 'string') {
                                    colKeys.push(resource);
                                } else {
                                    resource = '';
                                    colKeys.push('Resource ID');
                                }
                                let start = ganttSeries.StartValue;
                                if (typeof start === 'string') {
                                    colKeys.push(start);
                                } else {
                                    start = '';
                                    colKeys.push('Start');
                                }
                                let end = ganttSeries.EndValue;
                                if (typeof end === 'string') {
                                    colKeys.push(end);
                                } else {
                                    end = '';
                                    colKeys.push('End');
                                }
                                let duration = ganttSeries.Duration;
                                if (typeof duration === 'string') {
                                    colKeys.push(duration);
                                } else {
                                    duration = '';
                                    colKeys.push('Duration');
                                }
                                colKeys.push(ganttSeries.Completed);
                                let dependencies = ganttSeries.Dependency;
                                if (typeof dependencies === 'string') {
                                    colKeys.push(dependencies);
                                } else {
                                    dependencies = '';
                                    colKeys.push('dependencies');
                                }
                                dataTable.push(colKeys);
                                Data.forEach(x => {
                                    const row = [x[ganttSeries.TaskID], x[ganttSeries.TaskName]];
                                    const resVal = x[resource];
                                    if (typeof resVal === 'string') {
                                        row.push(resVal);
                                    } else {
                                        row.push(null);
                                    }
                                    let startVal = x[start];
                                    if (!(startVal instanceof Date)) {
                                        startVal = new Date(startVal);
                                    }
                                    if (isNaN(startVal.getTime())) {
                                        row.push(null);
                                    } else {
                                        row.push(startVal);
                                    }
                                    let endVal = x[end];
                                    if (!(endVal instanceof Date)) {
                                        endVal = new Date(endVal);
                                    }
                                    if (isNaN(endVal.getTime())) {
                                        row.push(null);
                                    } else {
                                        row.push(endVal);
                                    }
                                    const durationVal = x[duration];
                                    if (typeof durationVal === 'number') {
                                        row.push(durationVal);
                                    } else {
                                        row.push(null);
                                    }
                                    row.push(x[ganttSeries.Completed]);
                                    const depVal = x[dependencies];
                                    if (typeof depVal === 'string') {
                                        row.push(depVal);
                                    } else {
                                        row.push(null);
                                    }
                                    dataTable.push(row);
                                });
                            }
                        }
                        break;
                    case ChartType.Timeline:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0) {
                            const timelineSeries = ChartSettings.Series[0];
                            if (typeof timelineSeries.ColumnName === 'string' && typeof timelineSeries.StartValue === 'string'
                                && typeof timelineSeries.EndValue === 'string') {
                                if (typeof timelineSeries.LabelColumn === 'string') {
                                    const colKeys = [timelineSeries.ColumnName, timelineSeries.LabelColumn,
                                    timelineSeries.StartValue, timelineSeries.EndValue];
                                    dataTable.push(colKeys);
                                    Data.forEach(x => {
                                        const row = [x[timelineSeries.ColumnName], x[colKeys[1]]];
                                        let startDate = x[timelineSeries.StartValue];
                                        if (!(startDate instanceof Date)) {
                                            startDate = new Date(startDate);
                                        }
                                        row.push(startDate);
                                        let endDate = x[timelineSeries.EndValue];
                                        if (!(endDate instanceof Date)) {
                                            endDate = new Date(endDate);
                                        }
                                        row.push(endDate);
                                        dataTable.push(row);
                                    });
                                } else {
                                    const colKeys = [timelineSeries.ColumnName, timelineSeries.StartValue, timelineSeries.EndValue];
                                    dataTable.push(colKeys);
                                    Data.forEach(x => {
                                        const row = [x[timelineSeries.ColumnName]];
                                        let startDate = x[timelineSeries.StartValue];
                                        if (!(startDate instanceof Date)) {
                                            startDate = new Date(startDate);
                                        }
                                        row.push(startDate);
                                        let endDate = x[timelineSeries.EndValue];
                                        if (!(endDate instanceof Date)) {
                                            endDate = new Date(endDate);
                                        }
                                        row.push(endDate);
                                        dataTable.push(row);
                                    });
                                }
                            }
                        }
                        break;
                    case ChartType.CandlestickChart:
                        if (ChartSettings.Series && ChartSettings.Series.length > 0 &&
                            ChartData.FillColLists(ChartSettings.AxisColumn, colKeys, colCaptions)) {
                            const correctSeries = [];
                            let showTotal = false;
                            ChartSettings.Series.forEach(x => {
                                if (typeof x.Minimum === 'string' && typeof x.Initial === 'string'
                                    && typeof x.Final === 'string' && typeof x.Maximum === 'string') {
                                    const seriesDescription = {
                                        Min: x.Minimum,
                                        Init: x.Initial,
                                        Fin: x.Final,
                                        Max: x.Maximum,
                                        HasStyleKey: typeof x.StyleKey === 'string' && Styles,
                                        StyleKey: x.StyleKey,
                                        HasLabel: typeof x.LabelColumn === 'string',
                                        Label: x.LabelColumn,
                                        MinMin: Number.MAX_VALUE,
                                        MinInit: Number.MAX_VALUE,
                                        MaxFinal: Number.MIN_VALUE,
                                        MaxMax: Number.MIN_VALUE,
                                        ShowTotal: x.ShowTotal == true
                                    };
                                    correctSeries.push(seriesDescription);
                                    colCaptions.push(x.Minimum);
                                    colCaptions.push(x.Initial);
                                    colCaptions.push(x.Final);
                                    colCaptions.push(x.Maximum);
                                    if (seriesDescription.HasStyleKey) {
                                        colCaptions.push({ role: 'style' });
                                    }
                                    if (seriesDescription.HasLabel) {
                                        colCaptions.push({ role: 'annotation' });
                                    }
                                    if (seriesDescription.ShowTotal) {
                                        showTotal = true;
                                    }
                                }
                            });
                            dataTable.push(colCaptions);
                            if (correctSeries.length > 0) {
                                let formatter;
                                if (showTotal && google && google.visualization) {
                                    if (ChartSettings.LabelAxis && typeof ChartSettings.LabelAxis.Format == 'string' && ChartSettings.LabelAxis.Format) {
                                        formatter = new google.visualization.DateFormat({ pattern: ChartSettings.LabelAxis.Format });
                                    } else {
                                        formatter = new google.visualization.DateFormat();
                                    }
                                }
                                Data.forEach(x => {
                                    const row = [];
                                    let axisValue = x[colKeys[0]];
                                    if (showTotal) {
                                        if (axisValue instanceof Date) {
                                            if (formatter) {
                                                axisValue = formatter.formatValue(axisValue);
                                            } else {
                                                axisValue = moment(axisValue).format();
                                            }
                                        }
                                        row.push('' + axisValue); // AxisValue
                                    } else {
                                        row.push(axisValue);
                                    }
                                    correctSeries.forEach(actSeries => {
                                        const actMin = x[actSeries.Min];
                                        if (actMin < actSeries.MinMin) {
                                            actSeries.MinMin = actMin;
                                        }
                                        row.push(actMin);
                                        const actInit = x[actSeries.Init];
                                        if (actInit < actSeries.MinInit) {
                                            actSeries.MinInit = actInit;
                                        }
                                        row.push(actInit);
                                        const actFin = x[actSeries.Fin];
                                        if (actFin > actSeries.MaxFinal) {
                                            actSeries.MaxFinal = actFin;
                                        }
                                        row.push(actFin);
                                        const actMax = x[actSeries.Fin];
                                        if (actMax > actSeries.MaxMax) {
                                            actSeries.MaxMax = actMax;
                                        }
                                        row.push(actMax);
                                        if (actSeries.HasStyleKey) {
                                            let styleVal = null;
                                            const styleKey = x[actSeries.StyleKey];
                                            if (styleKey) {
                                                styleVal = Styles[styleKey];
                                            }
                                            row.push(styleVal);
                                        }
                                        if (actSeries.HasLabel) {
                                            row.push(x[actSeries.Label]);
                                        }
                                    });
                                    dataTable.push(row);
                                });
                                if (showTotal) {
                                    const row = [];
                                    row.push('Total'); // AxisValue
                                    correctSeries.forEach(actSeries => {
                                        if (actSeries.ShowTotal) {
                                            row.push(actSeries.MinMin);
                                            row.push(actSeries.MinInit);
                                            row.push(actSeries.MaxFinal);
                                            row.push(actSeries.MaxMax);
                                            if (actSeries.HasStyleKey) {
                                                row.push(null);
                                            }
                                            if (actSeries.HasLabel) {
                                                row.push(null);
                                            }
                                        } else {
                                            row.push(null);
                                            row.push(null);
                                            row.push(null);
                                            row.push(null);
                                            if (actSeries.HasStyleKey) {
                                                row.push(null);
                                            }
                                            if (actSeries.HasLabel) {
                                                row.push(null);
                                            }
                                        }
                                    });
                                    dataTable.push(row);
                                }
                            }
                        }
                        break;
                    case ChartType.Sunburst:
                        return Data;
                        break;
                }
                return dataTable;
            }
        }
    }

    private static FillColLists(col, keys: string[], captions: string[]): boolean {
        if (col && typeof col.ColumnName === 'string') {
            captions.push(col.ColumnName);
            if (typeof col.ColumnKey === 'string') {
                keys.push(col.ColumnKey);
            } else {
                keys.push(col.ColumnName);
            }
            return true;
        }
        return false;
    }

    public static GetPalette(settings: ChartSettings) {
        return new Promise<string[]>(resolve => {
            if (settings) {
                if (settings.ChartPalette) {
                    CacheService.ReadChartPaletteValues(settings.ChartPalette).then(x => {
                        if (x && x.length > 0) {
                            resolve(x)
                        } else if (settings.SeriesColors && settings.SeriesColors.length > 0) {
                            resolve(settings.SeriesColors);
                        } else if (ThemeHelper.ActiveThemeChartPalette && ThemeHelper.ActiveThemeChartPalette.length > 0) {
                            resolve(ThemeHelper.ActiveThemeChartPalette);
                        } else {
                            resolve(null);
                        }
                    });
                } else if (settings.SeriesColors && settings.SeriesColors.length > 0) {
                    resolve(settings.SeriesColors);
                } else if (ThemeHelper.ActiveThemeChartPalette && ThemeHelper.ActiveThemeChartPalette.length > 0) {
                    resolve(ThemeHelper.ActiveThemeChartPalette);
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    }
}
