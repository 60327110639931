import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { MeasureHeaderTaskExecuter } from '../../../../models/tasks/formattasks/measureheader.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { AMeasurePanel, AMeasureTaskSettingsHelper } from '../../a.task.panel';

@Component({
    selector: 'measureheader-formatting-task-panel',
    templateUrl: './measureheader.formatting.task.panel.html'
})
export class MeasureHeaderFormattingTaskPanel extends AMeasurePanel {
    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = MeasureHeaderTaskExecuter.TaskID;
        reg.Caption = '@@Wertkopf';
        reg.SettingsControl = MeasureHeaderFormattingTaskPanel;
        reg.SettingsHelper = new MeasureHeaderFormattingTaskSettingsHelper();
        reg.Executer = MeasureHeaderTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }

}

export class MeasureHeaderFormattingTaskSettingsHelper extends AMeasureTaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsTableObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Wertkopfformatierung');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Wertkopfformatierung');
    }
}
