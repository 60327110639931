import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { LayoutHelper } from '../../../../helpers/layout.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';

@Component({
    selector: 'wf-set-control-properties-settings',
    templateUrl: './set.control.properties.settings.html'
})
export class SetControlPropertiesSettings extends FormulaWorkflowDialogContent {
    Data = new SetControlPropertiesSettingsData();

    static ModuleID = 'controlPropertyModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetControlPropertiesSettings.ModuleID;
        reg.Caption = '@@Control-Eigenschaft setzen';
        reg.GroupID = 'layout';
        reg.Index = 60;
        reg.SettingsControl = SetControlPropertiesSettings;
        reg.SettingsTypeHelper = new SetControlPropertiesSettingsDataHelper();
        reg.Executer = SetControlPropertiesModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetControlPropertiesSettingsData, json);
            this.Data.Controls.forEach(c => {
                this.controlChanged(c);
            });
        }
    }

    getResult(): any {
        const controls = [];
        this.Data.Controls.forEach(c => {
            if (c.ControlName && c.Properties) {
                const props = [];
                c.Properties.forEach(prop => {
                    if (prop.PropertyName && prop.Formula) {
                        props.push(prop);
                    }
                });
                if (props.length > 0) {
                    const control = new ControlEntry();
                    control.ControlName = c.ControlName;
                    control.Properties = props;
                    controls.push(control);
                }
            }
        });
        this.Data.Controls = controls;
        return this.Data;
    }

    addControl() {
        if (this.Data) {
            if (this.Data.Controls) {
                this.Data.Controls.push(new ControlEntry());
            } else {
                this.Data.Controls = [new ControlEntry()];
            }
        }
    }

    removeControl(i) {
        if (this.Data && this.Data.Controls) {
            this.Data.Controls.splice(i, 1);
        }
    }

    controlChanged(c) {
        if (c) {
            const list = [];
            if (this.WFEditOptions && this.WFEditOptions.Layout) {
                const elem = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, c.ControlName);
                if (elem && elem.Element && elem.Element.PropertyList) {
                    list.push(...elem.Element.PropertyList);
                }
            }
            c.ControlProps = list;
        }
    }

    addProp(c) {
        if (c) {
            if (c.Properties) {
                c.Properties.push(new ControlPropertyEntry());
            } else {
                c.Properties = [new ControlPropertyEntry()];
            }
        }
    }

    removeProp(c, i) {
        if (c && c.Properties) {
            c.Properties.splice(i, 1);
        }
    }

    propSelected(p, prop) {
        if (p && prop) {
            p.PropertyName = prop.PropertyName;
        }
    }
}

// @dynamic
export class SetControlPropertiesSettingsData {
    @Type(() => ControlEntry)
    Controls: ControlEntry[] = [];
}

// @dynamic
export class ControlEntry {
    ControlName: string;
    @Type(() => ControlPropertyEntry)
    Properties: ControlPropertyEntry[] = [];
}

export class ControlPropertyEntry {
    PropertyName: string;
    Formula: string;
}

export class SetControlPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new SetControlPropertiesSettingsData();
    }
    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Controls) {
            settings.Controls.forEach(x => {
                if (x.ControlName === oldName) {
                    x.ControlName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class SetControlPropertiesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const calculator = new WorkflowFormulaCalculator(status);
            status.ActualSettings.Controls.forEach(c => {
                if (c.ControlName && c.Properties && c.Properties.length > 0) {
                    const elem = status.WorkflowLayoutService.getElementByName(c.ControlName);
                    if (elem && elem.Element) {
                        c.Properties.forEach(prop => {
                            if (prop.PropertyName && prop.Formula) {
                                if (elem.Element.PropertyList &&
                                    elem.Element.PropertyList.some(x => x.PropertyName === prop.PropertyName)) {
                                    try {
                                        elem.Element[prop.PropertyName] = calculator.CalcFormula(prop.Formula);
                                        elem.Element.RefreshCount += 1;
                                        elem.Element.cdRef.detectChanges();
                                    } catch (ex) {
                                        const log = 'SetControlProperties module: Error while calculating value for property ' +
                                            prop.PropertyName + ' of control ' + c.ControlName + '(' + ex + ')';
                                        status.Logger.logWarning(log);
                                    }
                                } else {
                                    status.Logger.logWarning('SetControlProperties module: Property ' + prop.PropertyName +
                                        ' can not be set on element ' + c.ControlName);
                                }
                            }
                        });
                    } else {
                        status.Logger.logWarning('SetControlProperties module: Element with name \'' +
                            c.ControlName + '\' could not found.');
                    }
                }
            });
            return 0;
        } else {
            status.Logger.logError('SetControlProperties module: No settings found.');
        }
        return super.execute(status);
    }
}
