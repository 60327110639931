import { ChangeDetectorRef, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MediaSource, MEDIA_TYPE_DICT } from '../../../models/datamodel/mediasource.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { MediaService } from '../../../services/media.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { CapsuleHelper } from '../../../helpers/capsule.helper';
import { StandardRequestBase } from '../../../services/request-base';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { Router } from '@angular/router';
import config from '../../../../assets/config.json';
import {LayoutService} from "../../../services/layout.service";

@Component({
    selector: 'mediasource-settings',
    templateUrl: '../../base.list.settings.html'
})
export class MediaSourceSettings extends BaseListSettings {
    IsCapsuleInstance = false;

    static GetSettingsEntry() {
        // const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        // if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
        //     return null;
        // }
        return {
            Caption: '@@Medienquellen',
            ID: 'mediasources',
            Icon: 'image',
            Index: 3,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 256
            },
            Content: MediaSourceSettings
        };
    }

    constructor(private translate: TranslateService, private service: MediaService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private router: Router) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return MediaSourceDetail;
    }

    protected init() {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            this.isFreeUser = true;
            this.router.navigate(['/default/settings/access-denied']);
            return;
        }
        CapsuleHelper.GetCapsuleTags().then(r => {
            if (r) {
                this.IsCapsuleInstance = true;
            }
        });
    }

    loadList(handler) {
        this.service.Get().subscribe(sources => {
            const list = [];
            sources.forEach(function (source) {
                // if (source.Caption === 'FontRegistry' || source.Caption === 'IconRegistry') {
                //     list.push({
                //         Caption: source.Caption,
                //         ID: source.SID,
                //         IsCapsule: source.IsCapsule,
                //         IsOverridden: source.IsOverridden,
                //         CanNotDelete: true,
                //         CanNotEdit: true
                //     });
                //     return;
                // }
                list.push({
                    Caption: source.Caption,
                    ID: source.SID,
                    IsCapsule: source.IsCapsule,
                    IsOverridden: source.IsOverridden,
                    HideDelete:source.IsCapsule
                });
            });
            handler(list);
        });
    }

    loadData(data) {
        this.service.GetMediaSource(data).subscribe(source => {
            let ms;
            ms = plainToClass(MediaSource, source);
            const edit = ms && ms.NonEditable !== true;
            this.ShowDelete = edit;
            this.ShowSave = edit || this.IsCapsuleInstance;
            this.setSelectedItem(ms);
            this.Component.IsCapsuleInstance = this.IsCapsuleInstance;
        });
    }

    getNewItem() {
        const ds = new MediaSource();
        const name = 'DataSource_';
        let length = 1;
        if (this.ListItems.length > 0) {
            length = this.ListItems.length;
        }
        ds.Name = name + length;
        ds.Caption = new TranslatedString(this.translate.instant('@@New media source'));
        return ds;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete media source \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Media source \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Delete media source');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Media source \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Save media source');
        return retVal;
    }

    delete(data, handler) {
        this.service.Delete(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.Save(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        if (!item.SID) {
            item.SID = result.SID;
        }
        if (!item.Version) {
            item.Version = result.Version;
        }
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'mediasource-detail',
    templateUrl: './mediasource.settings.html',
    styleUrls: ['./mediasource.settings.css']
})
export class MediaSourceDetail extends BaseListDetail {
    IsCapsuleInstance;
    Clients = [];
    Subscription;
    SelectedMediaType;

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    constructor(private translate: TranslateService, private service: MediaService,
        private agentService: StandardRequestBase, private factoryResolver: ComponentFactoryResolver) {
        super();
    }


    setSelectedItem(item) {
        super.setSelectedItem(item);
        this.SetData();
    }

    ngOnInit() {
        this.service.GetMediaClientTypes().subscribe(clients => {
            this.Clients = clients;
            this.SetData();
        });
    }

    SetData() {
        if (this.Clients) {
            if (this.SelectedItem) {
                this.SelectedMediaType = this.SelectedItem.ClientType;
            }
            this.OnMediaChanged(true);
        }
    }

    OnMediaChanged(internal) {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (this.SelectedMediaType) {
                this.SelectedItem.ClientType = this.SelectedMediaType;
                var type = MEDIA_TYPE_DICT.get(this.SelectedMediaType);
                if (type) {
                    const factory = this.factoryResolver.resolveComponentFactory(type.ControlType);
                    const comp = factory.create(this.viewContainerRef.parentInjector);
                    this.viewContainerRef.insert(comp.hostView);
                    const component: any = comp.instance;
                    if (component) {
                        component.MediaSource = this.SelectedItem;
                        this.Subscription = component.OnDataChange.subscribe(() => { this.OnItemChanged(); });
                    }
                }
            }
        }
        if (!internal) {
            this.OnItemChanged();
        }
    }

    checkConnection() {
        this.service.CheckConnection(this.SelectedItem).subscribe(x => {
            if (x.Successfull) {
                NotificationHelper.Success('@@Successfully connected', '@@Success');
            } else {
                NotificationHelper.Error(x.Error, '@@Error');
            }
        });
    }

    Initialize(InitArgs) {
        if (InitArgs) {
            this.SelectedItem = InitArgs.Data;
        }
    }
}
