import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { FormulaNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';

@Component({
    selector: 'tree-settings-control',
    templateUrl: './tree.settings.control.html',
    styleUrls: ['./tree.settings.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSettingsControl implements OnInit {

    //#region Settings
    SettingsValue;

    @Input()
    get Settings() {
        return this.SettingsValue;
    }
    set Settings(val) {
        this.SettingsValue = val;
        this.checkParent();
        this.SettingsChange.emit(this.SettingsValue);
    }

    @Output() SettingsChange = new EventEmitter<any>();
    //#endregion

    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        const listFields = [];
        const listParents = [];
        const listIDColumns = [];
        const hasChildren = [];
        if (val) {
            const guidList = [];
            const intList = [];
            const longList = [];
            const stringList = [];
            val['Fields'].forEach((column) => {
                if (column.IsList) {
                    listFields.push(column);
                } else {
                    switch (column.Type) {
                        case 'c4ef25dd-c176-418c-836e-f26c52d7f59c': // AttributeClass
                            if (column.IsShared) {
                                listParents.push(column);
                            }
                            break;
                        case '983dad4c-fd88-4124-aed0-1dd1d177521a': // Guid
                            listParents.push(column);
                            guidList.push(column);
                            break;
                        case 'c02230ee-05fb-46d2-ab58-5b9dde1f32d5': // Int
                            intList.push(column);
                            break;
                        case 'a4299f61-eb3f-4b29-8c4e-144458d17048': // Long
                            longList.push(column);
                            break;
                        case '9b056ce1-ffb9-4168-9e00-977449f2f66d': // String
                            stringList.push(column);
                            break;
                        case '02414483-3194-474d-a3d1-0a1a8a36a9e0':
                            hasChildren.push(column);
                            break;
                    }
                }
            });
            if (guidList.length > 1) {
                listIDColumns.push(...guidList);
            }
            if (intList.length > 1) {
                listParents.push(...intList);
                listIDColumns.push(...intList);
            }
            if (longList.length > 1) {
                listParents.push(...longList);
                listIDColumns.push(...longList);
            }
            if (stringList.length > 1) {
                listParents.push(...stringList);
                listIDColumns.push(...stringList);
            }
        }
        this.ListFields = listFields;
        this.ListParents = listParents;
        this.ListIDColumns = listIDColumns;
        this.ListHasChildren = hasChildren;
        this.checkParent();
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    ListFields = [];
    ListParents = [];
    ListIDColumns = [];
    FilteredIDColumns = [];
    ListHasChildren = [];
    ChildTable;
    DisplayFormula = '';
    Variables = [];

    @Output() TreeSettingsChanged = new EventEmitter<any>();

    ngOnInit(): void {
        this.nextLevelSelected();
    }

    checkParent() {
        let filtered = [];
        if (this.SettingsValue && this.SettingsValue.ParentID) {
            const parent = this.ListParents.find(x => x.ID === this.SettingsValue.ParentID);
            if (parent) {
                filtered = this.ListIDColumns.filter(x => x !== parent && x.Type === parent.Type);
            }
        }
        this.FilteredIDColumns = filtered;
    }

    onLabelChanged() {
        if (this.SettingsValue) {
            const parser = new AdvancedFormulaParser();
            parser.SetVariables(this.Variables, FormulaInputMode.VariableName);
            try {
                this.SettingsValue.DisplayFormula = parser.Parse(this.DisplayFormula);
            } catch {
                if (this.SettingsValue.DisplayFormula) {
                    const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.SettingsValue.DisplayFormula);
                    if (formula) {
                        this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                    } else {
                        this.DisplayFormula = '';
                        this.SettingsValue.DisplayFormula = null;
                    }
                }
            }
            this.onSettingsChanged();
        }
    }

    editFormula() {
        if (this.SettingsValue) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables,
                Formula: this.DisplayFormula,
                InputMode: FormulaInputMode.VariableName
            }, (r) => {
                if (r) {
                    this.DisplayFormula = r.Formula;
                    this.onLabelChanged();
                }
                return true;
            });
        }
    }

    childListSelected() {
        if (this.SettingsValue) {
            this.SettingsValue.ParentID = null;
            this.SettingsValue.ParentCheckID = null;
            this.checkParent();
            this.onSettingsChanged();
        }
    }

    parentIDSelected() {
        if (this.SettingsValue) {
            this.SettingsValue.ChildList = null;
            this.SettingsValue.ParentCheckID = null;
            this.checkParent();
            this.onSettingsChanged();
        }
    }

    nextLevelSelected() {
        this.ChildTable = null;
        this.Variables = [];
        if (this.SettingsValue && this.SettingsValue.NextLevelID && this.DataSourceValue) {
            const field = this.DataSourceValue.Fields.find(x => x.ID === this.SettingsValue.NextLevelID);
            if (field && field.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                CacheService.ReadTable(field.AdvancedType).then((data) => {
                    if (data) {
                        const variables = [];
                        this.ChildTable = data;
                        this.ChildTable.Fields.forEach((column) => {
                            const vni = new VariablesNodeInformation();
                            vni.VariableID = column.ID;
                            vni.Name = column.TranslatedCaption;
                            variables.push(vni);
                        });
                        this.Variables = variables;
                        if (!this.SettingsValue.NextLevel) {
                            this.SettingsValue.NextLevel = {};
                        }
                    }
                });
            }
        }
        this.onSettingsChanged();
    }

    onSettingsChanged() {
        this.TreeSettingsChanged.emit();
    }
}
