import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';
import { FilterElementSettings } from '../../../models/datadescription/multi/filter/filterElementSettings.model';
import { FilterTreeComponent } from './filter.tree.control';

@Component({
    selector: 'filter-tree-dialog',
    templateUrl: './filter.tree.dialog.html'
})
export class FilterTreeDialog {

    @Input()
    FilterElementSettingsValue: FilterElementSettings;

    @ViewChild('filterTree') filterTree: FilterTreeComponent;

    public static ShowDialog(args, handler) {
        BaseDialog.ShowDialog({
            ContentType: FilterTreeDialog,
            InitArgs: args,
            Title: '@@Auswahl',
            Handler: handler
        });
    }

    constructor(private cdRef: ChangeDetectorRef) {
    }

    Initialize(args) {
        if (args) {          
            if (args.FilterElementSettingsValue) {
                this.FilterElementSettingsValue = args.FilterElementSettingsValue;
            }
        }
    }

    GetDialogResult() {
        return { FilterSelection: this.filterTree.FilterSelection };
    }

    onRootNodesFilled(ev) {
        this.cdRef.detectChanges();
    }
}
