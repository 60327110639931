import { ABaseElement } from './abaseelement.model';

export class MediaSource extends ABaseElement {
    URL: string;
    Port: number;
    UserName: string;
    Password: string;
    BucketName: string;
    Private: boolean;
    NonEditable: boolean;
    ClientType: string;
    ClientId: string;
    ClientSecret: string;
    AccessToken: string;
    RefreshToken: string;
    ExpireAt: Date;
    RedirectUrl: string;
    UserId: string;
    Scopes: string;
    TenantId: string;
    SASCredentials: string;
    Region: string;

    static GetURL(mediaSource, fileName?): string {
        let retVal = '';
        if (mediaSource) {
            let protocol = 'http://';
            if (mediaSource.Port === 443) {
                protocol = 'https://';
            }
            if (mediaSource.ClientType === 'e44ba8e2-1955-4e0c-bfcf-c0ae5b20618d') {
                retVal = "https://" + mediaSource.BucketName + ".s3." + mediaSource.Region + ".amazonaws.com";
            }
            else {
                retVal = protocol + mediaSource.URL + ':' + mediaSource.Port + '/' + mediaSource.BucketName;
            }
           
            if (fileName) {
                retVal += '/' + fileName;
            }
        }
        return retVal;
    }
}

export class MediaPanelInfo {
    MediaType: string;
    ControlType;
}

export const MEDIA_TYPE_DICT = new Map<string, MediaPanelInfo>([]);
