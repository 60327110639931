import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { LogLevel } from '../../../models/enums/loglevel.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { MonitoringService } from '../../../services/monitoring.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';

@Component({
    selector: 'logsmails-settings',
    templateUrl: '../../base.list.settings.html'
})
export class LogsMailSettings extends BaseListSettings {    

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Email',
    //        ID: 'logsemail',
    //        Icon: 'logsemail',
    //        Index: 4,
    //        Parent: 'about',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.PageSettingsRight',
    //            Value: 8
    //        },
    //        Content: LogsMailSettings
    //    };
    //}

    constructor(protected fr: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MonitoringService) {
        super(fr, cdRef);
    }

    getContentType() {
        return LogsMailDetail;
    }

    loadList(handler) {
        this.service.ReadLogMailTrigger().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(id) {
        this.service.ReadLogMailTriggerById(id).subscribe(result => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        return {
            Caption: new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer MailServer'))
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den MailServer \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@MailServer \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@MailServer loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@MailServer \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(TranslatedString.GetTranslation(sel.Caption));
        retVal.Title = new TranslateFormatText('@@MailServer speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.RemoveLogMailTrigger(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        this.service.AddOrUpdateLogMailTrigger(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'logsmails',
    templateUrl: './logsmails.settings.html',
    styleUrls: ['./logsmails.settings.css']
})
export class LogsMailDetail extends BaseListDetail {

    LogLevel = [];

    constructor() {
        super();
        this.LogLevel = EnumHelper.GetDropdownValues(LogLevel);
    }
}
