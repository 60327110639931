import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { WorkflowEditOptions } from "../../../../workflow/workflow.control";

@Component({
    selector: 'comparison-overlay',
    templateUrl: './comparison.overlay.html',
    styleUrls: ['./comparison.overlay.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparsionOverlay implements OnInit, OnDestroy {

    _Source;
    @Input()
    get Source() {
        return this._Source;
    }
    set Source(val) {
        this._Source = val;
        this.SourceChange.emit(val);
    }
    @Output() SourceChange = new EventEmitter<any>();

    _Modified;
    @Input()
    get Modified() {
        return this._Modified;
    }
    set Modified(val) {
        this._Modified = val;
        this.ModifiedChange.emit(val);
    }
    @Output() ModifiedChange = new EventEmitter<any>();

    _Type;
    @Input()
    get Type() {
        return this._Type;
    }
    set Type(val) {
        this._Type = val;
        this.TypeChange.emit(val);
    }
    @Output() TypeChange = new EventEmitter<any>();

    _ShowComparison;
    @Input()
    get ShowComparison() {
        return this._ShowComparison;
    }
    set ShowComparison(val) {
        this._ShowComparison = val;
        this.ShowComparisonChange.emit(val);
    }
    @Output() ShowComparisonChange = new EventEmitter<any>();

    editorOptions = {
        theme: 'vs-dark',
        readOnly: true
    };

    EditOptions = new WorkflowEditOptions();

    ngOnDestroy(): void {
        
    }
    ngOnInit(): void {

    }

    CloseComparison() {
        this.ShowComparison = false;
    }
}