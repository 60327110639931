import { ITaskExecuter } from '../atask.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { StyleMerger } from '../../styling/styleMerger';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { CellStyle } from '../../styling/cell.style';

export class LevelHeaderFormattingTaskExecuter implements ITaskExecuter {
    static TaskID = 'levelheaderformattingtask';

    LevelUniqueID = -1;
    Style: CellStyle;
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
    }

    Execute() {
        if (this.Style && this.Merger) {
            const levelID = this.LevelUniqueID;
            const dataDescription = this.DataDescription;
            const result = this.Result;
            if (levelID > -1 && dataDescription && result) {
                if (!dataDescription.XLevelNodes.Areas.some(area => {
                    if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === levelID))) {

                        const sections = MultiResultHelper.GetNodeSections(result.XAxis, levelID);
                        sections.forEach(sec => {
                            sec.forEach(xNode => {
                                xNode.StyleID = this.Merger.MergeStyle(xNode.StyleID, this.Style);
                            });
                        });
                        return true;
                    }
                    return false;
                })) {
                    dataDescription.YLevelNodes.Areas.some(area => {
                        if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === levelID))) {

                            const sections = MultiResultHelper.GetNodeSections(result.YAxis, levelID);
                            sections.forEach(sec => {
                                sec.forEach(yNode => {
                                    yNode.StyleID = this.Merger.MergeStyle(yNode.StyleID, this.Style);

                                });
                            });
                            return true;
                        }
                        return false;
                    });
                }
            }
        }
    }
}
