import { Component, OnInit } from '@angular/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { TranslatedStringElement } from '../../../models/translatedstring.model';
import { LanguageService } from '../../../services/language.service';

@Component({
    selector: 'dataobject-translate-detail',
    templateUrl: './dataobject.translate.dialog.html'
})
export class DataObjectTranslateDialog implements OnInit {
    AllLanguages = [];
    TranslatedFields;
    SelectedLanguage;
    Fields = [];

    static ShowDialog(container, handler) {
        BaseDialog.ShowDialog({
            ContentType: DataObjectTranslateDialog,
            Handler: (r) => {
                if (r) {
                    if (container) {
                        container.Fields = r;
                    }
                    if (typeof handler === 'function') {
                        handler();
                    }
                }
                return true;
            },
            Title: '@@Felder uebersetzen',
            InitArgs: {
                Container: container
            }
        });
    }

    constructor(private service: LanguageService) {
    }

    ngOnInit(): void {
        this.service.getLanguages().subscribe(x => {
            if (x) {
                this.AllLanguages = x;
            }
        });
    }

    Initialize(args) {
        if (args && args.Container && args.Container.Fields) {
            this.Fields = JSON.parse(JSON.stringify(args.Container.Fields));
        }
    }

    onLanguageChanged() {
        this.TranslatedFields = {};
        this.Fields.forEach(x => {
            let seri = x.Caption.SerializedTranslations.find(y => y.LCID == this.SelectedLanguage);
            if (!seri) {
                seri = new TranslatedStringElement();
                seri.LCID = this.SelectedLanguage;
                x.Caption.SerializedTranslations.push(seri);
            }
            this.TranslatedFields[x.ID] = seri;
        });
    }

    GetDialogResult() {
        return this.Fields;
    }
}
