import { Component } from '@angular/core';
import { DBPanelInfo, OpenAIDBInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../basedatasource.panel';

@Component({
    selector: 'openai-panel',
    templateUrl: './openai.panel.html',
    styleUrls: ['./openai.panel.css']
})
export class OpenAIPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = OpenAIDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: OpenAIPanel,
            DBInfoType: OpenAIDBInfo
        };
    }
}
