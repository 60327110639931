import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { TaskHelper } from '../task.helper';
import { AConditionalFormatingTask } from './aconditional.formatting.task';
import { MeasureTaskExecuter } from './measure.formatting.task';

export class ConditionalMeasureTaskExecuter extends AConditionalFormatingTask {
    static TaskID = 'conditionalmeasureformattingtask';

    MeasureUniqueID = -1;

    InitConditionalTaskSettings(settings: any) {
        if (settings && typeof settings.MeasureUniqueID === 'number') {
            this.MeasureUniqueID = settings.MeasureUniqueID;
        }
    }

    Execute() {
        if (this.ConditionFormatingStyles && this.ConditionFormatingStyles.length > 0 && this.Merger) {
            MeasureTaskExecuter.IterateMeasureNodes(this.MeasureUniqueID, this.DataDescription, this.Result, (x, y, m) => {
                this.ConditionFormatingStyles.forEach((cfs) => {
                    const isFullFilled = TaskHelper.CheckConditionObject(cfs.Condition, this.Result, x, y, m);
                    if (isFullFilled) {
                        if (cfs.FormatHeaders) {
                            if (cfs.Condition.LevelUniqueID && x.UniqueID === cfs.Condition.LevelUniqueID) {
                                x.StyleID = this.Merger.MergeStyle(x.StyleID, cfs.Style);
                            } else {
                                y.StyleID = this.Merger.MergeStyle(y.StyleID, cfs.Style);
                            }
                        }
                        const requiresCell = TaskHelper.RequiresCell(cfs.Style);
                        if (requiresCell) {
                            const cell = MultiResultHelper.GetCellOrNew(this.Result, x, y, m);
                            cell.StyleID = this.Merger.MergeStyle(cell.StyleID, cfs.Style);
                        } else {
                            const cell = MultiResultHelper.GetCellOrNull(this.Result, x, y, m);
                            if (cell) {
                                cell.StyleID = this.Merger.MergeStyle(cell.StyleID, cfs.Style);
                            }
                        }
                    }
                });
            }, null);
        }
    }
}
