export enum SortType
    {
        Code,
        Caption,
        Attribute,
        Measure
}

export enum TaskAxisLevel {
    XAxis = -11,
    YAxis =-22
}