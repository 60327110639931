import { Subject } from 'rxjs';

export class ContextEvent {
    Element: any;
    Event: MouseEvent;
}

export class ContextEventHelper {
    public static Open: Subject<ContextEvent> = new Subject();
    public static Close: Subject<any> = new Subject();
}
