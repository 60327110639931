import { BaseTriggerControl } from "./base.trigger.control";
import { Component } from "@angular/core";
import { CronBuilderDialog } from "../../../../components/common/cronbuilder/cron.builder.dialog";

@Component({
    selector: 'timetrigger-control',
    templateUrl: './time.trigger.control.html',
    styleUrls: ['./time.trigger.control.css']
})
export class TimeTriggerControl extends BaseTriggerControl {

    DataValue;

    ngOnInit(): void {
        this.OnTriggerSet();
    }

    OnTriggerSet() {
        var dv = {
            FixedTimes: [],
            CronExpressions: []
        };
        if (this.TriggerValue) {
            if (!this.TriggerValue.FixedTimes) {
                this.TriggerValue.FixedTimes = [];
            } else {
                this.TriggerValue.FixedTimes.forEach(function (ft) {
                    dv.FixedTimes.push({
                        Date: ft
                    });
                });
            }
            if (!this.TriggerValue.CronExpressions) {
                this.TriggerValue.CronExpressions = [];
            } else {
                this.TriggerValue.CronExpressions.forEach(function (ce) {
                    dv.CronExpressions.push({
                        Expression: ce
                    });
                });
            }
        }
        this.DataValue = dv;
    }

    addFixedTime() {
        var date = {
            Date: new Date()
        };
        if (this.TriggerValue) {
            this.TriggerValue.FixedTimes.push(date.Date);
            this.OnTriggerHasChanged();
        }
        if (this.DataValue) {
            this.DataValue.FixedTimes.push(date);
        }
    }

    onDateChanged(i) {
        if (this.TriggerValue && this.DataValue) {
            this.TriggerValue.FixedTimes[i] = this.DataValue.FixedTimes[i].Date;
            this.OnTriggerHasChanged();
        }
    }

    removeFixedTime(i) {
        if (this.TriggerValue) {
            this.TriggerValue.FixedTimes.splice(i, 1);
            this.OnTriggerHasChanged();
        }
        if (this.DataValue) {
            this.DataValue.FixedTimes.splice(i, 1);
        }
    }

    addCron() {
        var cron = {
            Expression: '* * * * * ? *'
        };
        if (this.TriggerValue) {
            this.TriggerValue.CronExpressions.push(cron.Expression);
            this.OnTriggerHasChanged();
        }
        if (this.DataValue) {
            this.DataValue.CronExpressions.push(cron);
        }
    }

    onCronChanged(i) {
        if (this.TriggerValue && this.DataValue) {
            this.TriggerValue.CronExpressions[i] = this.DataValue.CronExpressions[i].Expression;
            this.OnTriggerHasChanged();
        }
    }

    removeCron(i) {
        if (this.TriggerValue) {
            this.TriggerValue.CronExpressions.splice(i, 1);
            this.OnTriggerHasChanged();
        }
        if (this.DataValue) {
            this.DataValue.CronExpressions.splice(i, 1);
        }
    }

    showCronEditor(i) {
        if (this.DataValue) {
            var ce = this.DataValue.CronExpressions[i];
            var that = this;
            CronBuilderDialog.ShowDialog(ce.Expression, function (exp) {
                ce.Expression = exp;
                that.onCronChanged(i);
            });
        }
    }
}