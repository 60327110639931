import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef
} from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogHelper } from '../../../helpers/injector.helper';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { DialogResult } from '../../../models/enums/dialogresult.enum';

@Component({
    selector: 'base-dialog',
    templateUrl: 'base.dialog.html',
    styleUrls: ['./base.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseDialog implements AfterViewInit {

    DialogData: DialogData;
    DialogContent;
    AdditionalStyle;

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    public static ShowDialog(data: DialogData) {
        if (data) {
            const config = new MatDialogConfig();
            config.data = {
                Data: data
            };
            if (!data.Title && data.HideEmptyTitle != true) {
                data.Title = 'evidanza MaC';
            }
            if (typeof data.Buttons !== 'number') {
                data.Buttons = DialogButton.OkCancel;
            }
            if (data.FullScreen === true) {
                config.position = {
                    top: '0',
                    left: '0'
                };
                config.minWidth = '100%';
                config.height = '100%';
            } else {
                if (data.Height) {
                    config.height = data.Height + 'px';
                    config.maxHeight = 'initial';
                } else if (data.HeightText) {
                    config.height = data.HeightText;
                    config.maxHeight = 'initial';
                }
                if (data.Width) {
                    config.width = data.Width + 'px';
                } else if (data.WidthText) {
                    config.width = data.WidthText;
                }
            }
            if (data.AutoFocus === false) {
                config.autoFocus = false;
            }
            config.direction = RTLHelper.Direction=='ltr'?'ltr':'rtl';
            return DialogHelper.DialogInstance.open(BaseDialog, config);
        } else {
            console.error('No dialog-data defined!');
        }
        return null;
    }

    public static async ShowDialogAsync(data: DialogData): Promise<DialogResult> {
        const diaRef = BaseDialog.ShowDialog(data);
        if (diaRef) {
            return await diaRef.afterClosed().toPromise();
        }
        return DialogResult.None;
    }

    constructor(private dialogRef: MatDialogRef<BaseDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
        private factoryResolver: ComponentFactoryResolver, private cdRef: ChangeDetectorRef) {
        this.DialogData = data.Data;
        dialogRef.disableClose = true;
        if (dialogRef._containerInstance._config.height) {
            this.AdditionalStyle = new Map([['max-height', 'initial']]);
        }
    }

    ngAfterViewInit(): void {
        if (this.DialogData) {
            if (this.DialogData.ContentType) {
                const factory = this.factoryResolver.resolveComponentFactory(this.DialogData.ContentType);
                const component = factory.create(this.viewContainerRef.parentInjector);
                this.DialogContent = component.instance;
                this.DialogContent.BaseDialog = this;
                if (this.DialogData.InitArgs && typeof this.DialogContent.Initialize === 'function') {
                    this.DialogContent.Initialize(this.DialogData.InitArgs);
                }
                this.viewContainerRef.insert(component.hostView);
                component.location.nativeElement.classList.add('flex-column', 'flex-100', 'full', 'noscroll');
                component.changeDetectorRef.detectChanges();
            }
        }
    }

    async buttonClicked(button) {
        if (this.DialogContent) {
            if (typeof this.DialogContent.OnCustomButtonClicked === 'function') {
                await this.DialogContent.OnCustomButtonClicked(button);
            }
            if (this.DialogContent.CloseOnCustomButton === true) {
                await this.tryCloseDialog(DialogResult.None);
            }
        }
    }

    async cancelClicked() {
        const execute = await this.onExecuteClick(DialogButton.Cancel);
        if (execute) {
            if (this.DialogData && typeof this.DialogData.Handler === 'function') {
                this.DialogData.Handler(null);
            }
            this.dialogRef.close(DialogResult.Cancel);
        }
    }

    async okClicked() {
        const execute = await this.onExecuteClick(DialogButton.Ok);
        if (execute) {
            await this.tryCloseDialog(DialogResult.Ok);
        }
    }

    async tryCloseDialog(result: DialogResult) {
        let close = true;
        let pg = null;
        if(this.DialogContent.GetPageName){
            if(typeof this.DialogContent.GetPageName==="function"){
                const pgVal = await this.DialogContent.GetPageName();
                if(pgVal){
                    pg = pgVal
                }
            }
        }
        if (this.DialogData && typeof this.DialogData.Handler === 'function' && this.DialogContent &&
            typeof this.DialogContent.GetDialogResult === 'function') {
            const dr = await this.DialogContent.GetDialogResult();
            if(pg!==undefined && pg!==null){
                close = this.DialogData.Handler(dr,pg)
            }
            else close = this.DialogData.Handler(dr);
        }
        if (close) {
            this.dialogRef.close(result);
        }
    }

    async onExecuteClick(button: DialogButton): Promise<boolean> {
        let retVal = true;
        if (this.DialogContent && typeof this.DialogContent.OnDialogButtonClickAction === 'function') {
            retVal = await this.DialogContent.OnDialogButtonClickAction(button);
        }
        return retVal;
    }
}

export interface DialogData {
    ContentType;
    InitArgs?;
    Handler?;
    Title?: string;
    Buttons?: DialogButton;
    Height?: number;
    Width?: number;
    HeightText?: string;
    WidthText?: string;
    FullScreen?: boolean;
    AutoFocus?: boolean;
    HideEmptyTitle?: boolean;
}
