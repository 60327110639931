import { Component } from '@angular/core';
import {Router} from "@angular/router";
@Component({
  selector: 'lib-access-denied',
  styleUrls:['./403-page.component.css'],
  templateUrl:'./403-page.component.html'
})

export class AccessDeniedPage {

  constructor(private router: Router) {
  }
  goBack() {
    this.router.navigate(['/default/settings']);
  }
}
