import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ContainerEnumService, DataModelService } from '../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'enum-switch-advanced-settings',
    templateUrl: './enum.switch.advanced.settings.html'
})
export class EnumSwitchAdvancedSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'enumAdvancedWFModule';

    Data = new EnumSwitchAdvancedSettingsData();
    SystemEnums = [];
    DataModels = [];
    EnumsByDataModel = {};
    SelectedSystemEnum;
    SelectedDataModel;
    SelectedEnum;
    ShowSystemEnums = false;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = EnumSwitchAdvancedSettings.ModuleID;
        reg.Caption = '@@Enum';
        reg.GroupID = 'datachecks';
        reg.Index = 30;
        reg.Height = 80;
        reg.Width = 80;
        reg.SettingsControl = EnumSwitchAdvancedSettings;
        reg.SettingsTypeHelper = new EnumSwitchAdvancedSettingsDataHelper();
        reg.Executer = EnumSwitchAdvancedModuleExecuter;
        return reg;
    }

    private static checkExitIDs(enumValues, saveValues) {
        if (enumValues) {
            if (saveValues) {
                const existingIDs = {};
                saveValues.forEach(x => {
                    const enumVal = enumValues.find(y => y.ID === x.ValueID);
                    if (enumVal) {
                        enumVal.ExitID = x.ExitID;
                        enumVal.Selected = true;
                        existingIDs[x.ExitID] = true;
                    }
                });
                let startID = 1;
                enumValues.forEach(x => {
                    if (x.Selected !== true) {
                        while (existingIDs[startID] === true) {
                            startID++;
                        }
                        x.ExitID = startID++;
                    }
                });
            } else {
                enumValues.forEach((x, i) => {
                    x.ExitID = i + 1;
                    x.Selected = true;
                });
            }
        }
    }

    constructor(private service: ContainerEnumService, private dmService: DataModelService) {
        super();
    }

    ngOnInit(): void {
        this.dmService.GetModels().subscribe(dm => {
            if (dm) {
                this.DataModels = dm;
            }
            this.service.GetAllEnumInfos().subscribe(infos => {
                if (infos) {
                    infos.forEach(x => {
                        let saveValues;
                        if (x.IsServiceEnum) {
                            this.SystemEnums.push(x);
                            if (this.Data && this.Data.EnumID === x.ID) {
                                this.SelectedSystemEnum = x;
                                this.ShowSystemEnums = true;
                                saveValues = this.Data.EnumValues;
                            }
                        } else {
                            let dmEntry = this.EnumsByDataModel[x.DataModel];
                            if (!dmEntry) {
                                dmEntry = [];
                                this.EnumsByDataModel[x.DataModel] = dmEntry;
                            }
                            dmEntry.push(x);
                            if (this.Data && this.Data.EnumID === x.ID) {
                                this.SelectedDataModel = x.DataModel;
                                this.SelectedEnum = x;
                                this.ShowSystemEnums = false;
                                saveValues = this.Data.EnumValues;
                            }
                        }
                        EnumSwitchAdvancedSettings.checkExitIDs(x.Values, saveValues);
                    });
                }
            });
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(EnumSwitchAdvancedSettingsData, json);
        }
    }

    getResult(): any {
        const enumValues = [];
        const selectedEnum = this.ShowSystemEnums ? this.SelectedSystemEnum : this.SelectedEnum;
        if (selectedEnum) {
            if (selectedEnum.Values) {
                selectedEnum.Values.forEach(x => {
                    if (x.Selected === true) {
                        const evd = new EnumValueData();
                        evd.ExitID = x.ExitID;
                        evd.Name = x.Name;
                        evd.ValueID = x.ID;
                        enumValues.push(evd);
                    }
                });
            }
            this.Data.EnumID = selectedEnum.ID;
        } else {
            this.Data.EnumID = null;
        }
        this.Data.EnumValues = enumValues;
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.ValueToCheck) {
            const selectedEnum = this.ShowSystemEnums ? this.SelectedSystemEnum : this.SelectedEnum;
            if (!selectedEnum || !selectedEnum.Values.some(x => x.Selected === true)) {
                retVal.Error = '@@Bitte waehlen Sie einen Enum aus';
                retVal.IsCorrect = false;
            }
        } else {
            retVal.Error = '@@Bitte definieren Sie einen zu ueberpruefenden Wert';
            retVal.IsCorrect = false;
        }
        return retVal;
    }
}

export class EnumValueData {
    ValueID: string;
    Name: string;
    ExitID: number;
}

// @dynamic
export class EnumSwitchAdvancedSettingsData extends AServiceWorkflowData {
    ValueToCheck: string;
    EnumID: string;
    @Type(() => EnumValueData)
    EnumValues: EnumValueData[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.EnumSwitch.EnumSwitchAdvancedSettingsData';
    }
}

export class EnumSwitchAdvancedSettingsDataHelper extends WorkflowModuleSettingsHelper {

    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getEmptySettingsInstance() {
        return new EnumSwitchAdvancedSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = [];
        if (settings && settings.EnumValues) {
            settings.EnumValues.forEach(x => {
                const info = new WorkflowExitInfo();
                info.ID = x.ExitID;
                info.Label = x.Name;
                retVal.push(info);
            });
        }
        const def = new WorkflowExitInfo();
        def.Label = '@@Sonst';
        retVal.push(def);
        return retVal;
    }
}

export class EnumSwitchAdvancedModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const calculator = new WorkflowFormulaCalculator(status);
            let enumVal;
            try {
                enumVal = calculator.CalcFormula(status.ActualSettings.ValueToCheck);
            } catch (ex) {
                status.Logger.logError('Enum modul: Error while calculating formula ' +
                    status.ActualSettings.ValueToCheck + ' (' + ex + ').');
                return super.execute(status);
            }
            if (enumVal == null) {
                return 0;
            } else {
                const enumTable = await CacheService.ReadEnum(status.ActualSettings.EnumID);
                if (enumTable) {
                    let field = enumTable.AutoGenerated ?
                        enumTable.Fields.find(x => x.AutomatedValue === enumVal) :
                        enumTable.Fields.find(x => x.ID === enumVal);
                    if (field) {
                        const saveVal = status.ActualSettings.EnumValues.find(x => x.ValueID === field.ID);
                        if (saveVal) {
                            return saveVal.ExitID;
                        }
                    }
                    return 0;
                } else {
                    status.Logger.logError('Enum modul: Enum with id ' + status.ActualSettings.EnumID + ' not found.');
                }
            }
        } else {
            status.Logger.logError('Enum modul: No settings found.');
        }
        return super.execute(status);
    }
}
