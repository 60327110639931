import { OnInit, Input, Output, EventEmitter, Directive } from "@angular/core";

@Directive()
export abstract class BaseSchedulerControl implements OnInit {

    TriggerValue;

    //#region Trigger
    @Input()
    get Trigger() {
        return this.TriggerValue;
    }
    set Trigger(val) {
        this.TriggerValue = val;
        this.OnTriggerSet();
        this.TriggerChange.emit(val);
    }

    @Output() TriggerChange = new EventEmitter<any>();
    //#endregion

    ngOnInit(): void {
    }

    @Output() TriggerHasChanged = new EventEmitter<any>();
    OnTriggerHasChanged() {
        this.TriggerHasChanged.emit();
    }

    OnTriggerSet() {
    }
}