import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { ResourceLoginType, UserConfig } from '../../../../../models/userconfig.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'webdavdb-panel',
    templateUrl: './webdavdb.panel.html',
    styleUrls: ['./webdavdb.panel.css']
})
export class WebDAVDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = WebDAVDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: WebDAVDBPanel,
            DBInfoType: WebDAVDBInfo
        };
    }
}

export class WebDAVDBInfo extends ADBInfo {
    URL: string = null;
    LoginType: ResourceLoginType = ResourceLoginType.Anonymous;
    Username: string = null;
    Password: string = null;
    User: UserConfig;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.NonDatabase.WebDAVDBInfo';
    }
}