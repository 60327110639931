import { NgZone } from '@angular/core';
import moment from 'moment';
import { UsersService } from '../services/users.service';

export class LogoutHandler {
    private static LogoutTime = 0;
    private static TimerID;
    private static NGZone: NgZone;
    private static Initialized = false;
    private static ActualUser;
    private static LastActivity = '';

    public static Initialize(ngZone: NgZone) {
        LogoutHandler.NGZone = ngZone;
        UsersService.ActiveUser.subscribe(x => {
            LogoutHandler.ActualUser = x
            if (x && LogoutHandler.LogoutTime > 0) {
                LogoutHandler.Update(LogoutHandler.LogoutTime * 60000);
            }
        });
        if (LogoutHandler.ActualUser) {
            const settingsJSON = localStorage.getItem("GeneralSettings");
            if (settingsJSON) {
                const settings = JSON.parse(settingsJSON);
                if (settings && typeof settings.LogoutTime === 'number' && settings.LogoutTime > 0) {
                    const mom = localStorage.getItem('LastActivity')
                    if (mom) {
                        const now = moment();
                        const lastActivity = moment(mom);
                        const diff = now.diff(lastActivity);
                        const logOutMS = settings.LogoutTime * 60000;
                        if (diff > logOutMS) {
                            UsersService.LogoutStatic();
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    public static SetLogoutTime(generalSettings) {
        let logoutTime = 0;
        if (generalSettings && typeof generalSettings.LogoutTime === 'number' && generalSettings.LogoutTime > 0) {
            logoutTime = generalSettings.LogoutTime;
        }
        if (logoutTime !== LogoutHandler.LogoutTime) {
            LogoutHandler.LogoutTime = logoutTime;
            if (logoutTime > 0) {
                LogoutHandler.InitializeInternal();
                LogoutHandler.Update(LogoutHandler.LogoutTime * 60000);
            } else {
                clearTimeout(LogoutHandler.TimerID);
            }
        }
    }

    private static InitializeInternal() {
        if (!LogoutHandler.Initialized && LogoutHandler.NGZone) {
            LogoutHandler.Initialized = true;
            LogoutHandler.NGZone.runOutsideAngular(
                (): void => {
                    window.addEventListener("keydown", LogoutHandler.handleEvent);
                    window.addEventListener("mousemove", LogoutHandler.handleEvent);
                }
            );
        }
    }

    private static Update(timeout:number) {
        clearTimeout(LogoutHandler.TimerID);
        if (LogoutHandler.Initialized && LogoutHandler.ActualUser) {
            LogoutHandler.TimerID = setTimeout(() => {
                const mom = localStorage.getItem('LastActivity')
                if (mom) {
                    const now = moment();
                    const lastActivity = moment(mom);
                    const diff = now.diff(lastActivity);
                    const logOutMS = LogoutHandler.LogoutTime * 60000;
                    const nextTimeOut = logOutMS - diff;
                    if (nextTimeOut <= 0) {
                        UsersService.LogoutStatic();
                    } else {
                        LogoutHandler.Update(nextTimeOut);
                    }
                }
            }, timeout);
        }
    }

    private static handleEvent = (): void => {
        if (LogoutHandler.LogoutTime > 0) {
            const lastActivity = moment().format();
            if (lastActivity !== LogoutHandler.LastActivity) {
                LogoutHandler.LastActivity = lastActivity;
                localStorage.setItem('LastActivity', lastActivity);
            }
        }
    }
}