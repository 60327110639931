import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class GetProperty extends AFormula {
    static StaticID = '3b3d83da-fb28-443e-b9d8-c04c9216b97a';

    ID(): string {
        return GetProperty.StaticID;
    }

    Name(): string {
        return 'GetProperty';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Eigenschaft aus Objekt auslesen');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1007;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Objekt') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Eigenschaft') + ')';
    }

    MinVariableCount(): number {
        return 2;
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        let objVal;
        if (args && args.length > 1) {
            const obj = args[0];
            if (obj.Type === ValueType.Object) {
                const val = obj.Value;
                if (typeof val === 'object') {
                    const prop = args[1];
                    if (prop.Type === ValueType.String) {
                        const propVal = prop.Value;
                        if (typeof propVal === 'string') {
                            objVal = val[propVal];
                        }
                    }
                }
            }
        }
        return ValueAndType.GetValueAndTypeFromJSObject(objVal);
    }
}
