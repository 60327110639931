import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialog } from '../../../../../components/dialogs/basedialog/base.dialog';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { TranslateHelper } from '../../../../../helpers/injector.helper';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { UserConfig } from '../../../../../models/userconfig.model';
import { RestService } from '../../../../../services/rest.service';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';
import { AuthType } from '../../../../rest/models/auth.type';
import { BearerTokenAuthSettings, OAuth2Settings } from '../../../../rest/models/oauth2.settings';
import { AccessTokenDialog } from '../../../../rest/rest.overview/access.token.dialog/access.token.dialog';

@Component({
    selector: 'rest-panel',
    templateUrl: './rest.panel.html',
    styleUrls: ['./rest.panel.css']
})
export class RestPanel extends BaseDataSourcePanel {
    private _baseDialogRef: MatDialogRef<BaseDialog, any>;
    public authTypes: any[];
    public readonly createRestID = 'EB22681B-6CA2-4D45-A6C6-7AB619F102A9';
    public restBasises: any[] = [];

    constructor(private _restService: RestService ) {
        super();
        this.DBInfoType = RestDBInfo;
    }

    ngOnInit() {
        super.ngOnInit();

        this.authTypes = EnumHelper.GetDropdownValues(AuthType);
        this._restService.loadAll().subscribe(result => this.restBasises = result);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: RestPanel,
            DBInfoType: RestDBInfo
        };
    }

    public createAccessToken(): void {
        this._baseDialogRef = BaseDialog.ShowDialog({
            ContentType: AccessTokenDialog,
            Title: TranslateHelper.TranslatorInstance.instant('@@Zugriffstoken erstellen'),
            Handler: this.createAccessTokenClosed.bind(this),
            InitArgs: this.DBInfo.OAuth2Settings
        });
    }

    private createAccessTokenClosed(event: OAuth2Settings): boolean {
        if (event) {
            this._restService.getNewAccessToken(event).subscribe(this.getNewAccessTokenReceived.bind(this));
            return false;
        }
        return true;
    }

    private getNewAccessTokenReceived(result: any): void {
        if (result) {
            const dialogContent = this._baseDialogRef.componentInstance.DialogContent as AccessTokenDialog;
            dialogContent.updateTokenInfo(result);
            //this.SelectedItem.OAuth2Settings = dialogContent.GetDialogResult();
            this.DBInfo.OAuth2Settings = dialogContent.GetDialogResult();
            this._baseDialogRef.close();
            delete this._baseDialogRef;
        }
    }

    public typeChange(event: AuthType): void {
        this.DBInfo.AuthType = event;

        switch (this.DBInfo.AuthType) {
            case AuthType.OAuth2_0:
                delete this.DBInfo.OAuth2Settings;
                break;
            case AuthType.Basic:
                delete this.DBInfo.FixedUser;
                break;
            case AuthType.BearerToken:
                delete this.DBInfo.BearerTokenAuthSettings;
                break;
        }
        switch (event) {
            case AuthType.OAuth2_0:
                this.DBInfo.OAuth2Settings = new OAuth2Settings();
                break;
            case AuthType.Basic:
                this.DBInfo.FixedUser = new UserConfig();
                break;
            case AuthType.BearerToken:
                this.DBInfo.BearerTokenAuthSettings = new BearerTokenAuthSettings();
                break;
            default:
                delete this.DBInfo.BearerTokenAuthSettings;
                delete this.DBInfo.FixedUser;
                delete this.DBInfo.OAuth2Settings;
        }

    }
}

export class RestDBInfo extends ADBInfo {
    URL: string = null;
    AuthType: AuthType = AuthType.None;
    FixedUser: UserConfig ;
    OAuth2Settings: OAuth2Settings;
    BearerTokenAuthSettings: BearerTokenAuthSettings;
    RestBasis: string = null;

    public get TypeMap(): AuthType {
        return this.AuthType;
    }
    public set TypeMap(value: AuthType) {
       
        this.AuthType = value;
    }
    

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.NonDatabase.REST.RestDBInfo';
    }
}