import { LineType } from '../../models/enums/linetype.enum';
import { Operator } from '../../models/enums/operator.enum';
import { ADebugSessionData, DebugSessionStateData, DebugSessionStateDataType } from '../../models/workflow/debug.log.message';
import { BreakPointInfo } from '../../models/workflow/workflow.debug.settings';
import { IWaitForDebugHandler, StepIntoHandler, WorkflowDebugState, WorkflowStatus } from '../../models/workflow/workflow.model';
import { Class, Function, InstanceClass, LinePositionInfo, Module, ScriptStack, ScriptStepIntoHandler } from '../script.model';
import {
    ForScriptLine, IfScriptLine, LetScriptLine, ReturnScriptLine, ScriptLine, ScriptOperation,
    SetScriptLine, SwitchScriptLine, TryScriptLine, WhileScriptLine
} from '../scriptoperation.model';
import { ScriptParser } from './parser.script';
import { Registry } from './registry.script';
import { Global } from './wrapper/global.wrapper.script';
import { LayoutWrapper } from './wrapper/layout.wrapper.script';
import { StatusWrapper } from './wrapper/status.wrapper.script';

export class ScriptExecutioner implements IWaitForDebugHandler {
    private _Status: StatusWrapper;
    private _Module: Module;
    private _Function: Function;
    private _EndForEachFunction: Function;
    private _Instance: InstanceClass;
    private _Console = [];
    private _Stack: ScriptStack[] = [];
    private _GenerateLog = false;

    static GetExecutioner(script: string, status: WorkflowStatus, moduleID: number): Promise<ScriptExecutioner> {
        return new Promise<ScriptExecutioner>((resolve, reject) => {
            const retVal = new ScriptExecutioner(script, status, moduleID);
            retVal.ExecuteConstructor().then(() => resolve(retVal), () => reject());
        });
    }

    constructor(script: string, private _WorkflowStatus: WorkflowStatus, private ModuleID: number) {
        this._Status = new StatusWrapper(_WorkflowStatus);
        this._Module = new ScriptParser().GetModule(script);
        this._GenerateLog = _WorkflowStatus.GenerateLog;
    }

    private async ExecuteConstructor(): Promise<void> {
        try {
            const cl: Class = this._Module.Classes.get("Main");
            this._Function = cl.Functions.get("run");
            this._EndForEachFunction = cl.Functions.get("stop");
            this._Instance = new InstanceClass();
            this._Instance.Class = cl;
            const defaultProps = [];
            cl.Properties.forEach((v, k) => {
                if (v.Default) {
                    defaultProps.push({
                        Key: k,
                        Value: v.Default
                    });
                }
            });
            const stack = new ScriptStack();
            stack.Instance = this._Instance;
            stack.Function = this._Function;
            this._Stack.push(stack);
            try {
                for (let i = 0; i < defaultProps.length; i++) {
                    const defProp = defaultProps[i];
                    const propVal = await this.ExecuteOperation(defProp.Value);
                    this._Instance.Properties.set(defProp.Key, propVal);
                }
            } catch (ex) {
                throw new Error("Fehler beim erzeugen der Defaults der Properties!\n\r" + ex);
            }
            this._Stack.pop();
            const constr = cl.Functions.get("constructor");
            if (constr != null) {
                await this.ExecuteFunction(constr, [], this._Instance);
                this._Status.SetValue("consoleOutput", this._Console.join("\n\r"));
            }
        } catch (ex) {
            throw this.ThrowException(ex);
        }
    }

    private ThrowException(ex) {
        const msg: any = {};
        const stack = [];
        this._Stack.reverse().forEach((item) => {
            if (item.Function.Class != null) {
                stack.push(item.Function.Class.Name + "." + item.Function.Name + " (" + item.LinePosition + ")");
            }
            else {
                stack.push("anonymous (" + item.LinePosition + ")");
            }
        });
        msg.Stack = stack;
        msg.Console = this._Console.join("\n\r");
        let message = JSON.stringify(msg);
        if (ex) {
            message += ' ';
            if (ex.stack) {
                message += ex.stack;
            } else {
                message += ex;
            }
        }
        console.log(message);
        return new Error(message);
    }

    Execute(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this._Status.ActualEntryOption == 0) {
                this.ExecuteFunction(this._Function, null, this._Instance).then(x => {
                    this._Status.SetValue("consoleOutput", this._Console.join("\n\r"));
                    resolve(x.RetValue);
                }, (ex) => reject(this.ThrowException(ex)));
            } else {
                this.ExecuteFunction(this._EndForEachFunction, null, this._Instance).then(x => {
                    this._Status.SetValue("consoleOutput", this._Console.join("\n\r"));
                    resolve(x.RetValue);
                }, (ex) => reject(this.ThrowException(ex)));
            }
        });
    }

    private WaitForDebug(lineNumber: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this._Stack.length > 0) {
                const stack = this._Stack[this._Stack.length - 1];
                stack.LinePosition = lineNumber;
                stack.LinePositionInfo = null;
                if (this._WorkflowStatus.DebugStateHandler) {
                    this._WorkflowStatus.DebugStateHandler.WaitForDebug(this).then(() => resolve()).catch(ex => reject(ex));
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    private WaitForDebugLine(line: ScriptLine): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this._Stack.length > 0) {
                const stack = this._Stack[this._Stack.length - 1];
                stack.LinePosition = line.LineNumber;
                if (this._WorkflowStatus.DebugStateHandler) {
                    if (line && line.Operation) {
                        if (line.Operation.Operator === Operator.Function) {
                            this.GetFirstPart(line.Operation.Operators[line.Operation.Operators.length - 1].Operators).then(x => {
                                let actClass: Class;
                                if (x && x.Current) {
                                    if (x.Current instanceof Class && x.Current.TypescriptType == null) {
                                        actClass = x.Current;
                                    } else if (x.Current instanceof InstanceClass) {
                                        actClass = x.Current.Class;
                                    }
                                }
                                const lpi = new LinePositionInfo();
                                if (actClass) {
                                    lpi.CanStepInto = true;
                                    // TODO: ScriptLibraryID
                                }
                                stack.LinePositionInfo = lpi;
                                this._WorkflowStatus.DebugStateHandler.WaitForDebug(this).then(() => resolve()).catch(ex => reject(ex));
                            }).catch(ex => reject(ex));
                        //} else if (line.Operation.Operator === Operator.New) {
                            // TODO: ScriptLibrary
                        } else {
                            stack.LinePositionInfo = null;
                            this._WorkflowStatus.DebugStateHandler.WaitForDebug(this).then(() => resolve()).catch(ex => reject(ex));
                        }
                    } else {
                        stack.LinePositionInfo = null;
                        this._WorkflowStatus.DebugStateHandler.WaitForDebug(this).then(() => resolve()).catch(ex => reject(ex));
                    }
                } else {
                    stack.LinePositionInfo = null;
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    private async ExecuteFunction(func: Function, values: any[], instance: InstanceClass): Promise<ReturnValue> {
        const stack = new ScriptStack();
        let oldStack;
        if (this._Stack.length > 0) {
            oldStack = this._Stack[this._Stack.length - 1];
        }

        if (func.IsInlineFunction) {
            oldStack.Variables.forEach((item, key, map) => {
                stack.Variables.set(key, item);
            });
        }

        if (values != null) {
            for (let i = 0; i < func.Parameters.length; i++) {
                let para = func.Parameters[i];
                let val = null;
                if (values.length <= i || values[i] == null) {
                    if (para.Default != null) {
                        val = await this.ExecuteOperation(para.Default);
                    }
                    else {
                        val = null;
                    }
                }
                else {
                    val = values[i];
                }

                if (val != null) {
                    switch (para.Class) {
                        case "number":
                            stack.Variables.set(para.Name, parseFloat(val));
                            break;
                        case "boolean":
                            if (typeof val == 'string') {
                                stack.Variables.set(para.Name, val == 'true' ? true : false);
                            } else {
                                stack.Variables.set(para.Name, val);
                            }
                            break;
                        case "string":
                            stack.Variables.set(para.Name, "" + val);
                            break;
                        default:
                            stack.Variables.set(para.Name, val);
                            break;
                    }
                }
                else {
                    stack.Variables.set(para.Name, null);
                }
            }
        }

        stack.Instance = instance;
        stack.Function = func;
        this._Stack.push(stack);

        const retVal = await this.InterpreteLines(func.ScriptLines);
        let doWait = true;
        if (oldStack && oldStack.StepIntoHandler) {
            doWait = !oldStack.StepIntoHandler.SteppedOut && !oldStack.StepIntoHandler.SteppedOver;
            oldStack.StepIntoHandler.GoodToRemove = true;
            oldStack.StepIntoHandler = null;
        }
        if (doWait) {
            await this.WaitForDebug(func.LastLine);
        }
        this._Stack.pop();
        if (this._Stack.length > 0) {
            const newStack = this._Stack[this._Stack.length - 1];
            stack.Variables.forEach((v, k) => {
                if (Array.isArray(v)) {
                    newStack.Variables.set(k, v);
                }
            });
        }
        return retVal;
    }

    private async InterpreteLines(lines: any[]): Promise<ReturnValue> {
        let stop = false;
        let returned = false;
        let ret = null;

        let stack = this._Stack[this._Stack.length - 1];
        let variables = new Set(stack.Variables.keys());
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            if (this._GenerateLog) {
                let msg: any = {};
                let stack = [];
                this._Stack.reverse().forEach((item) => {
                    if (item.Function.Class != null) {
                        stack.push(item.Function.Class.Name + "." + item.Function.Name + " (" + item.LinePosition + ")");
                    }
                    else {
                        stack.push("anonymous (" + item.LinePosition + ")");
                    }
                });
                msg.Stack = stack;
                msg.Variables = new Map<string, any>();
                this._Stack[this._Stack.length - 1].Variables.forEach((obj, key, map) => { msg.Variables.set(key, obj); });
                msg.Console = this._Console.join("\n\r");
                console.log(msg);
            }

            await this.WaitForDebugLine(line);

            let retValue = await this.ExecuteLine(line);
            if (retValue != null) {
                ret = retValue.RetValue;
                stop = retValue.Stop;
                returned = retValue.Returned;
            }
            if (returned) {
                break;
            }
        }

        stack.Variables.forEach((value, key, map) => {
            if (!variables.has(key)) {
                stack.Variables.delete(key);
            }
        });

        const retVal = new ReturnValue();
        retVal.Returned = returned;
        retVal.Stop = stop;
        retVal.RetValue = ret;
        return retVal;
    }

    private async ExecuteLine(line: ScriptLine): Promise<ReturnValue> {
        switch (line.LineType) {
            case LineType.None:
            case LineType.Comment:
                return new ReturnValue();
            case LineType.Let:
                const let2 = line as LetScriptLine;
                let letVal = null;
                if (let2.Operation != null) {
                    letVal = await this.ExecuteOperation(let2.Operation);
                }
                this.SetVariable(let2.Name, letVal);
                return new ReturnValue();
            case LineType.Set:
                const set = line as SetScriptLine;
                const setVal = await this.ExecuteOperation(set.Operation);
                await this.SetOperation(set.SetOperation.Operators, setVal);
                return new ReturnValue();
            case LineType.If:
                const ifl = line as IfScriptLine;
                let ifRetVal = new ReturnValue();
                if (await this.ExecuteOperation(ifl.Operation)) {
                    ifRetVal = await this.InterpreteLines(ifl.SubScriptLines);
                    await this.WaitForDebug(ifl.IfEndLine);
                } else {
                    let found = false;
                    for (let i = 0; i < ifl.ElseIfScriptLine.length; i++) {
                        const elseif = ifl.ElseIfScriptLine[i];
                        await this.WaitForDebug(elseif.LineNumber);
                        found = await this.ExecuteOperation(elseif.Operation);
                        if (found) {
                            ifRetVal = await this.InterpreteLines(elseif.SubScriptLines);
                            await this.WaitForDebug(elseif.IfEndLine);
                            break;
                        }
                    }
                    if (!found && ifl.ElseScriptLines && ifl.ElseScriptLines.length > 0) {
                        ifRetVal = await this.InterpreteLines(ifl.ElseScriptLines);
                        await this.WaitForDebug(ifl.ElseEndLine);
                    }
                }
                return ifRetVal;
            case LineType.While:
                const wsl = line as WhileScriptLine;
                let whileResult = new ReturnValue();
                while (await this.ExecuteOperation(wsl.Operation)) {
                    whileResult = await this.InterpreteLines(wsl.SubScriptLines);
                    await this.WaitForDebug(wsl.EndLine);
                }
                return whileResult;
            case LineType.Switch:
                const ssl = line as SwitchScriptLine;
                const switchVal = await this.ExecuteOperation(ssl.Operation);
                let sub = ssl.DefaultScriptLines;
                for (let i = 0; i < ssl.CaseLines.length; i++) {
                    const actLine = ssl.CaseLines[i];
                    const lineVal = await this.ExecuteOperation(actLine.Operation);
                    if (switchVal == lineVal) {
                        sub = actLine.SubScriptLines;
                        break;
                    }
                }
                let switchRetVal = new ReturnValue();
                if (sub) {
                    switchRetVal = await this.InterpreteLines(sub);
                }
                return switchRetVal;
            case LineType.Break:
                const breakRetVal = new ReturnValue();
                breakRetVal.Stop = true;
                return breakRetVal;
            case LineType.Continute:
                const continueRetVal = new ReturnValue();
                continueRetVal.Returned = true;
                return continueRetVal;
            case LineType.For:
                const fo = line as ForScriptLine;
                let foResult = new ReturnValue();
                if (fo.IsForEach) {
                    const list = await this.ExecuteOperation(fo.LetSetOperation.Operation);
                    for (let i = 0; i < list.length; i++) {
                        this.SetVariable(fo.LetSetOperation.Name, list[i]);
                        foResult = await this.InterpreteLines(fo.SubScriptLines);
                        await this.WaitForDebug(fo.EndLine);
                        if (foResult && (foResult.Returned || foResult.Stop)) {
                            break;
                        }
                    }
                } else {
                    await this.ExecuteLine(fo.LetSetOperation)
                    while (await this.ExecuteOperation(fo.Operation)) {
                        foResult = await this.InterpreteLines(fo.SubScriptLines);
                        await this.WaitForDebug(fo.EndLine);
                        if (foResult && (foResult.Returned || foResult.Stop)) {
                            break;
                        }
                        await this.ExecuteLine(fo.SetLine);
                    }
                }
                this.RemoveVariable(fo.LetSetOperation.Name);
                return foResult;
            case LineType.Try:
                const tr = line as TryScriptLine;
                let tryRetVal = new ReturnValue();
                try {
                    tryRetVal = await this.InterpreteLines(tr.TryScriptLines);
                } catch (ex) {
                    if (tr.CatchScriptLines != null) {
                        if (tr.CatchExceptionName) {
                            this.SetVariable(tr.CatchExceptionName, ex);
                        }
                        try {
                            tryRetVal = await this.InterpreteLines(tr.CatchScriptLines);
                        } finally {
                            if (tr.CatchExceptionName) {
                                this.RemoveVariable(tr.CatchExceptionName);
                            }
                        }
                    }
                } finally {
                    if (tr.FinallyScriptLines != null) {
                        let st2 = this._Stack[this._Stack.length - 1];
                        let ln = st2.LinePosition;
                        tryRetVal = await this.InterpreteLines(tr.FinallyScriptLines);
                        st2.LinePosition = ln;
                    }
                }
                return tryRetVal;
            case LineType.Return:
                let retLine = line as ReturnScriptLine;
                const retVal = new ReturnValue();
                retVal.Returned = true;
                retVal.RetValue = await this.ExecuteOperation(retLine.Operation);
                return retVal;
            case LineType.Other:
                await this.ExecuteOperation(line.Operation);
                return new ReturnValue();
            case LineType.Throw:
                if (line.Operation != null) {
                    const throwVal = await this.ExecuteOperation(line.Operation);
                    throw throwVal;
                } else {
                    let st3 = this._Stack[this._Stack.length - 1];
                    const values = Array.from(st3.Variables.values()).reverse();
                    for (var mem of values) {
                        if (mem instanceof Error) {
                            throw mem;
                        }
                    }
                    throw new Error();
                }
            default:
                return new ReturnValue();
        }
    }

    private SetVariable(variable: string, value: any) {
        this._Stack[this._Stack.length - 1].Variables.set(variable, value);
    }

    private RemoveVariable(variable: string) {
        this._Stack[this._Stack.length - 1].Variables.delete(variable);
    }

    private async SetOperation(set: ScriptOperation[], val: any): Promise<void> {
        const parts = await this.GetFirstPart(set);
        if (parts.Last.Operator == Operator.ArrayAccess) {
            let current = parts.Current;
            if (current == null) {
                const peek = this._Stack[this._Stack.length - 1];
                if (peek.Variables.has(parts.Last.Name)) {
                    current = peek.Variables.get(parts.Last.Name);
                }
            } else if (current instanceof InstanceClass) {
                current = current.Properties.get(parts.Last.Name);
            } else if (typeof current == 'object') {
                current = current[parts.Last.Name];
            }
            for (let i = 0; i < parts.Last.Operators.length; i++) {
                const opVal = await this.ExecuteOperation(parts.Last.Operators[i]);
                if (Array.isArray(current)) {
                    if (i < parts.Last.Operators.length - 1) {
                        current = current[opVal];
                    } else {
                        current[opVal] = val;
                    }
                } else if (current instanceof Map) {
                    if (i < parts.Last.Operators.length - 1) {
                        current = current.get(opVal);
                    } else {
                        current.set(opVal, val);
                    }
                } else if (current instanceof Set) {
                    if (i < parts.Last.Operators.length - 1) {
                        current = current[opVal];
                    } else {
                        current[opVal] = val;
                    }
                } else if (current instanceof StatusWrapper) {
                    if (i < parts.Last.Operators.length - 1) {
                        current = current.GetValue('' + opVal);
                    } else {
                        current.SetValue('' + opVal, val);
                    }
                } else {
                    if (i < parts.Last.Operators.length - 1) {
                        current = this.GetObjectFromPath(current, '' + opVal);
                    } else {
                        this.SetObjectFromPath(current, '' + opVal, val);
                    }
                }
            }
        } else {
            if (parts.Current == null) {
                this.SetVariable(parts.Last.Name, val);
            } else if (parts.Current instanceof InstanceClass) {
                parts.Current.Properties.set(parts.Last.Name, val);
            } else if (parts.Current instanceof Map) {
                parts.Current.set(parts.Last.Name, val);
            } else {
                parts.Current[parts.Last.Name] = val;
            }
        }
    }

    private SetObjectFromPath(eo: any, path: string, value: any) {
        let splits = path.split('.');
        let ret = eo;

        for (let i = 0; i < splits.length - 1; i++) {
            if (ret instanceof Map) {
                ret = ret.get(splits[i]);
                if (ret == null) {
                    return;
                }
            }
            else if (typeof ret == 'object') {
                ret = ret[splits[i]];
            }
        }

        if (ret instanceof Map) {
            ret.set(splits[splits.length - 1], value);
        }
        else if (typeof ret == 'object') {
            ret[splits[splits.length - 1]] = value;
        }
    }

    private GetObjectFromPath(eo: any, path: string): any {
        let splits = path.split('.');
        let ret = eo;

        for (let i = 0; i < splits.length; i++) {
            if (ret instanceof Map) {
                ret = ret.get(splits[i]);
                if (ret == null) {
                    return null;
                }
            }
            else if (typeof ret == 'object') {
                ret = ret[splits[i]];
            }
        }

        return ret;
    }

    private GetFirstPart(operations: ScriptOperation[]): Promise<PartsReturn> {
        return new Promise<PartsReturn>((resolve, reject) => {
            this.IterateOperations(operations, this._Stack[this._Stack.length - 1]).then(x => {
                const retVal = new PartsReturn();
                retVal.Current = x;
                retVal.Last = operations[operations.length - 1];
                resolve(retVal);
            }).catch(ex => reject(ex));
        });
    }

    private GetFirstPart2(operations: ScriptOperation[]): Promise<Part2Return> {
        return new Promise<Part2Return>((resolve, reject) => {
            this.IterateOperations(operations, this._Stack[this._Stack.length - 1]).then(x => {
                const retVal = new Part2Return();
                retVal.Current = x;
                retVal.FunctionName = operations[operations.length - 1].Name;
                resolve(retVal);
            }).catch(ex => reject(ex));
        });
    }

    private async IterateOperations(operations: ScriptOperation[], funcStack: ScriptStack): Promise<any> {
        let result = null;
        if (operations) {
            for (let i = 0; i < operations.length - 1; i++) {
                result = await this.GetSingleOperation(operations[i], result, funcStack);
            }
        }
        return result;
    }

    private async GetSingleOperation(operation: ScriptOperation, current: any, funcStack: ScriptStack): Promise<any> {
        if (operation.Operator == Operator.Value) {
            return operation.Value;
        } else {
            let newCurrent;
            if (operation.Name == "this") {
                newCurrent = funcStack.Instance;
            } else if (current == null) {
                const peek = this._Stack[this._Stack.length - 1];
                if (peek.Variables.has(operation.Name)) {
                    newCurrent = peek.Variables.get(operation.Name);
                } else {
                    newCurrent = funcStack.Function.Class.Module.Imports.get(operation.Name);
                    if (newCurrent == null) {
                        newCurrent = funcStack.Function.Class.Module.Classes.get(operation.Name);
                        if (newCurrent == null) {
                            return operation.Name;
                        }
                    }
                }
            } else if (current instanceof InstanceClass) {
                newCurrent = current.Properties.get(operation.Name);
            } else if (Array.isArray(current)) {
                return current.length;
            } else if (current instanceof Map && (operation.Name == "length" || operation.Name == "size" || operation.Name == "count" || operation.Name == "Count")) {
                return current.size;
            } else if (current instanceof Class) {
                if (current.TypescriptType) {
                    const obj = Object.create(current.TypescriptType.prototype);
                    newCurrent = obj[operation.Name];
                } else {
                    newCurrent = await this.ExecuteOperation(current.Properties.get(operation.Name).Default);
                }
            } else if (current === 'Math') {
                return Math[operation.Name];
            } else if (current === 'JSON') {
                return JSON[operation.Name];
            } else {
                newCurrent = current[operation.Name];
            }

            if (operation.Operator == Operator.ArrayAccess) {
                for (let i = 0; i < operation.Operators.length; i++) {
                    const opVal = await this.ExecuteOperation(operation.Operators[i]);
                    if (Array.isArray(newCurrent)) {
                        newCurrent = newCurrent[opVal];
                    } else if (newCurrent instanceof InstanceClass) {
                        newCurrent = newCurrent.Properties.get('' + opVal);
                    } else if (newCurrent instanceof StatusWrapper) {
                        newCurrent = newCurrent.GetValue('' + opVal);
                    } else {
                        newCurrent = this.GetObjectFromPath(newCurrent, '' + opVal);
                    }
                }
            }
            return newCurrent;
        }
    }

    private async ReadValues(operators: any[]): Promise<any[]> {
        const retVal = [];
        if (operators) {
            for (let i = 0; i < operators.length; i++) {
                const opVal = await this.ExecuteOperation(operators[i]);
                retVal.push(opVal);
            }
        }
        return retVal;
    }

    private ExecuteOperation(operation: ScriptOperation): Promise<any> {
        if (operation.IsNegative) {
            return new Promise<any>((resolve, reject) => {
                this.ExecuteOperationInternal(operation).then(x => {
                    resolve(-x);
                }, y => reject(y));
            });
        } else {
            return this.ExecuteOperationInternal(operation);
        }
    }

    private async ExecuteOperationInternal(operation: ScriptOperation): Promise<any> {
        switch (operation.Operator) {
            case Operator.Add:
                const addValues = await this.ReadValues(operation.Operators);
                if (addValues.some((x) => typeof x == 'string')) {
                    return addValues.join('');
                } else {
                    let ret = addValues[0];
                    for (var i = 1; i < addValues.length; i++) {
                        ret += addValues[i];
                    }
                    return ret;
                }
            case Operator.Sub:
                const subValues = await this.ReadValues(operation.Operators);
                let subRet = subValues[0];
                for (var i = 1; i < subValues.length; i++) {
                    subRet -= subValues[i];
                }
                return subRet;
            case Operator.Div:
                const divValues = await this.ReadValues(operation.Operators);
                let divRet = divValues[0];
                for (var i = 1; i < divValues.length; i++) {
                    divRet /= divValues[i];
                }
                return divRet;
            case Operator.Multi:
                const multiValues = await this.ReadValues(operation.Operators);
                let multiRet = multiValues[0];
                for (var i = 1; i < multiValues.length; i++) {
                    multiRet *= multiValues[i];
                }
                return multiRet;
            case Operator.Equal:
                const eqVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const eqVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return eqVal1 == eqVal2;
            case Operator.Less:
                const lessVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const lessVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return lessVal1 < lessVal2;
            case Operator.Great:
                const greatVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const greatVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return greatVal1 > greatVal2;
            case Operator.LessEqual:
                const lessEqVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const lessEqVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return lessEqVal1 <= lessEqVal2;
            case Operator.GreatEqual:
                const greatEqVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const greatEqVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return greatEqVal1 >= greatEqVal2;
            case Operator.NotEqual:
                const nEVal1 = await this.ExecuteOperation(operation.Operators[0]);
                const nEVal2 = await this.ExecuteOperation(operation.Operators[1]);
                return nEVal1 != nEVal2;
            case Operator.Or:
                for (var i = 0; i < operation.Operators.length; i++) {
                    if (await this.ExecuteOperation(operation.Operators[i])) {
                        return true;
                    }
                }
                return false;
            case Operator.And:
                for (var i = 0; i < operation.Operators.length; i++) {
                    if (!(await this.ExecuteOperation(operation.Operators[i]))) {
                        return false;
                    }
                }
                return true;
            case Operator.Not:
                const notVal = await this.ExecuteOperation(operation.Operators[0]);
                return !notVal;
            case Operator.New:
                if (operation.Name == "Date") {
                    return new Date();
                } else if (operation.Name == "Map") {
                    return new Map<any, any>();
                } else if (operation.Name == "Error") {
                    if (operation.Operators.length > 0) {
                        const opVal = await this.ExecuteOperation(operation.Operators[0]);
                        return new Error(opVal + '');
                    } else {
                        return new Error();
                    }
                } else if (operation.Name == "LayoutHelper") {
                    return new LayoutWrapper(this._WorkflowStatus);
                } else {
                    var stack = this._Stack[this._Stack.length - 1];
                    let cla = stack.Function.Class.Module.Classes.get(operation.Name);
                    if (cla == null) {
                        const imp = stack.Function.Class.Module.Imports.get(operation.Name);
                        if (imp instanceof Class) {
                            cla = imp;
                        }
                        if (cla == null) {
                            cla = Registry.Modules.get('typescript').Classes.get(operation.Name);
                        }
                        if (cla == null) {
                            return {};
                        }
                    }
                    if (cla.TypescriptType != null) {
                        return Object.create(cla.TypescriptType.prototype);
                    } else {
                        const ic = new InstanceClass();
                        ic.Class = cla;
                        const defaultProps = [];
                        cla.Properties.forEach((v, k) => {
                            if (v.Default) {
                                defaultProps.push({
                                    Key: k,
                                    Value: v.Default
                                });
                            }
                        });
                        for (let i = 0; i < defaultProps.length; i++) {
                            const defProp = defaultProps[i];
                            const propVal = await this.ExecuteOperation(defProp.Value);
                            ic.Properties.set(defProp.Key, propVal);
                        }
                        const constr = cla.Functions.get("constructor");
                        if (constr != null) {
                            const values = await this.ReadValues(operation.Operators);
                            await this.ExecuteFunction(constr, values, ic);
                        }
                        return ic;
                    }
                }
            case Operator.NewArray:
                const arrayValues = await this.ReadValues(operation.Operators);
                return arrayValues;
            case Operator.NewDynamic:
                const nd = {};
                for (let i = 0; i < operation.Operators.length; i++) {
                    nd[operation.Operators[i].Name] = await this.ExecuteOperation(operation.Operators[i].Operators[0]);
                }
                return nd;
            case Operator.TypeOf:
                const typeOfVal = await this.ExecuteOperation(operation.Operators[0]);
                return typeof typeOfVal;
            case Operator.Function:
                const funcValues = await this.ReadValues(operation.Operators.slice(0, -1));
                const parts = await this.GetFirstPart2(operation.Operators[operation.Operators.length - 1].Operators);
                if (typeof parts.Current === 'string') {
                    let cla = Registry.Modules.get('typescript').Classes.get(parts.Current);
                    if (cla != null) {
                        return cla.TypescriptType[parts.FunctionName].apply(null, funcValues);
                    } else if (parts.Current == 'Global' && parts.FunctionName == 'getStatus') {
                        return this._Status;
                    } else if (parts.Current == 'console' && parts.FunctionName == 'log') {
                        console.log('' + funcValues[0]);
                        this._Console.push('' + funcValues[0]);
                        return null;
                    } else if (parts.Current == 'Date') {
                        return Date[parts.FunctionName].apply(parts.Current, funcValues);
                    } else if (parts.Current == 'Math') {
                        return Math[parts.FunctionName].apply(parts.Current, funcValues);
                    } else if (parts.Current == 'JSON') {
                        return JSON[parts.FunctionName].apply(parts.Current, funcValues);
                    } else {                        
                        return parts.Current[parts.FunctionName].apply(parts.Current, funcValues);
                    }
                } else if (parts.Current instanceof Class) {
                    if (parts.Current.TypescriptType != null) {
                        if (parts.Current.TypescriptType.name === Global.name && parts.FunctionName === 'getStatus') {
                            return this._Status;
                        }
                        return parts.Current.TypescriptType[parts.FunctionName].apply(null, funcValues);
                    } else {
                        const func = parts.Current.Functions.get(parts.FunctionName);
                        const ic = new InstanceClass();
                        ic.Class == parts.Current;
                        ic.IsStatic = true;
                        const retVal = await this.ExecuteFunction(func, funcValues, ic);
                        return retVal.RetValue;
                    }
                } else if (parts.Current instanceof InstanceClass) {
                    const func = parts.Current.Class.Functions.get(parts.FunctionName);
                    const retVal = await this.ExecuteFunction(func, funcValues, parts.Current);
                    return retVal.RetValue;
                } else {
                    if (parts.Current == null && Registry.WindowFunctions.indexOf(parts.FunctionName) > -1) {
                        return window[parts.FunctionName].apply(parts.Current, funcValues);
                    }
                    return parts.Current[parts.FunctionName].apply(parts.Current, funcValues);
                }
            case Operator.InlineFunction:
            case Operator.Value:
                return operation.Value;
            case Operator.Get:
                const getParts = await this.GetFirstPart(operation.Operators);
                const getRetVal = await this.GetSingleOperation(getParts.Last, getParts.Current, this._Stack[this._Stack.length - 1]);
                return getRetVal;
            case Operator.Enumerator:
                const enumRetVal = await this.ExecuteOperation(operation.Operators[0]);
                return enumRetVal;
            case Operator.If:
                let ifRetVal
                if (await this.ExecuteOperation(operation.Operators[0])) {
                    ifRetVal = await this.ExecuteOperation(operation.Operators[1]);
                } else {
                    ifRetVal = await this.ExecuteOperation(operation.Operators[2]);
                }
                return ifRetVal;
            case Operator.IncreaseAfter:
                let iaRetVal = await this.ExecuteOperation(operation.Operators[0]);
                await this.SetOperation(operation.Operators[0].Operators, iaRetVal + 1);
                return iaRetVal;
            case Operator.IncreaseBefore:
                let ibRetVal = await this.ExecuteOperation(operation.Operators[0]);
                await this.SetOperation(operation.Operators[0].Operators, ibRetVal + 1);
                return ibRetVal + 1;
            case Operator.DecreaseAfter:
                let daRetVal = await this.ExecuteOperation(operation.Operators[0]);
                await this.SetOperation(operation.Operators[0].Operators, daRetVal - 1);
                return daRetVal;
            case Operator.DecreaseBefore:
                let dbRetVal = await this.ExecuteOperation(operation.Operators[0]);
                await this.SetOperation(operation.Operators[0].Operators, dbRetVal - 1);
                return dbRetVal - 1;
            case Operator.Chain:
                let current = null;
                for (let i = 0; i < operation.Operators.length; i++) {
                    let iop = operation.Operators[i];
                    if (current != null) {
                        iop = this.Clone(iop);
                        const so = new ScriptOperation();
                        so.Operator = Operator.Value;
                        so.Value = current;
                        if (iop.Operator == Operator.Function) {
                            iop.Operators.Last().Operators.Insert(0, so);
                        }
                        else {
                            iop.Operators.Insert(0, so);
                        }
                    }
                    current = await this.ExecuteOperation(iop);
                }
                return null;
            default:
                return null;
        }
    }

    Clone(so: ScriptOperation): ScriptOperation {
        let ret = new ScriptOperation();
        ret.IsNegative = so.IsNegative;
        ret.Name = so.Name;
        ret.Operators = [];
        ret.Operator = so.Operator;
        ret.Type = so.Type;
        ret.Value = so.Value;
        so.Operators.forEach((item) => {
            ret.Operators.push(this.Clone(item));
        });
        return ret;
    }

    //#region Debug
    RemoveHandler(handler: StepIntoHandler): boolean {
        return handler && handler.WorkflowID === this._WorkflowStatus.WorkflowID && handler.ModuleID === this.ModuleID &&
            handler instanceof ScriptStepIntoHandler && handler.GoodToRemove;
    }
    GetNewHandler(): StepIntoHandler {
        if (this._Stack.length > 0) {
            const peek = this._Stack[this._Stack.length - 1];
            if (peek.LinePositionInfo && peek.LinePositionInfo.CanStepInto) {
                const retVal = new ScriptStepIntoHandler(this._WorkflowStatus.WorkflowID, this.ModuleID);
                peek.StepIntoHandler = retVal;
                return retVal;
            }
        }
        return null;
    }
    CheckBreakPoints(breakpoints: BreakPointInfo[]): boolean {
        if (breakpoints && this._Stack.length > 0) {
            const bp = breakpoints.find(x => x.ModuleID === this.ModuleID);
            if (bp && bp.AdditionalBreakPoints && Array.isArray(bp.AdditionalBreakPoints.Breakpoints)
                && bp.AdditionalBreakPoints.Breakpoints.length > 0) {
                const peek = this._Stack[this._Stack.length - 1];
                let libID;
                for (let i = this._Stack.length - 2; i >= 0; i--) {
                    const actStack = this._Stack[i];
                    if (actStack.LinePositionInfo && actStack.LinePositionInfo.CanStepInto && actStack.LinePositionInfo.ScriptLibraryID) {
                        libID = actStack.LinePositionInfo.ScriptLibraryID;
                        break;
                    }
                }
                if (libID) {
                    return bp.AdditionalBreakPoints.Breakpoints.some(x => x && x.LibraryID === libID && x.LineNumber === peek.LinePosition);
                }
                return bp.AdditionalBreakPoints.Breakpoints.some(x => x && !x.LibraryID && x.LineNumber === peek.LinePosition);
            }
        }
        return false;
    }
    GetStateData(runID: string, state: WorkflowDebugState): DebugSessionStateData {
        const dssd = new DebugSessionStateData();
        dssd.DataType = DebugSessionStateDataType.Module;
        const wfData = new ScriptDebugSessionData();
        wfData.ModuleID = this.ModuleID;
        wfData.PageSID = this._WorkflowStatus.WorkflowLayoutService.Layout['_Id'];
        wfData.WorkflowID = this._WorkflowStatus.WorkflowID;
        if (this._Stack.length > 0) {
            const peek = this._Stack[this._Stack.length - 1];
            for (let i = this._Stack.length - 2; i >= 0; i--) {
                const actStack = this._Stack[i];
                if (actStack.LinePositionInfo && actStack.LinePositionInfo.CanStepInto && actStack.LinePositionInfo.ScriptLibraryID) {
                    wfData.ScriptLibraryID = actStack.LinePositionInfo.ScriptLibraryID;
                    break;
                }
            }
            wfData.ActualLine = peek.LinePosition;
        }
        if (state.Breakpoints) {
            const bpList = state.Breakpoints[runID];
            if (bpList) {
                wfData.Breakpoints = bpList;
            }
        }
        dssd.Data = wfData;
        return dssd;
    }
    SetAdditionalStatusInfos(status) {
        if (this._Stack.length > 0 && status) {
            const scriptParams = {};
            let add = false;
            const peek = this._Stack[this._Stack.length - 1];
            if (peek.Instance instanceof InstanceClass) {
                const thisObj = {};
                peek.Instance.Properties.forEach((v, k) => {
                    if (v != null) {
                        thisObj[k] = v;
                        add = true;
                    }
                });
                if (add) {
                    scriptParams['this'] = thisObj;
                }
            }
            peek.Variables.forEach((v, k) => {
                if (v != null) {
                    scriptParams[k] = v;
                    add = true;
                }
            });
            if (add) {
                status['ScriptParameters'] = scriptParams;
            }
        }
    }
    //#endregion
}

class ReturnValue {
    RetValue: any;
    Returned = false;
    Stop = false;
}

class PartsReturn {
    Current: any;
    Last: ScriptOperation;
}

class Part2Return {
    FunctionName: string;
    Current: any;
}

export class ScriptDebugSessionData extends ADebugSessionData {
    ActualLine: number;
    ScriptLibraryID: string;
    IsConnectorScript: boolean;
}
