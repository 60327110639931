import { Component } from '@angular/core';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { ReadMultiPropertySettings } from './read.multi.property.settings';

@Component({
    selector: 'wf-readproperty-settings',
    templateUrl: './readProperty.settings.html',
    styleUrls: ['./readProperty.settings.css']
})
export class ReadPropertySettings extends WorkflowDialogContent {
    PropertyName: string;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readPropertyModule';
        reg.Caption = '@@Read property';
        reg.SettingsControl = ReadPropertySettings;
        reg.SettingsTypeHelper = new ReadPropertySettingsDataHelper();
        reg.Executer = ReadPropertyModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ReadMultiPropertySettings.ModuleID;
        return reg;
    }

    constructor() {
        super();
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            this.PropertyName = data.PropertyName;
        }
    }

    getResult(): any {
        const retVal = new ReadPropertySettingsData();
        retVal.PropertyName = this.PropertyName;
        return retVal;
    }

    GetExpressionProperties() {
        return [{
            Value: 'PropertyName'
        }];
    }
}

export class ReadPropertySettingsData {
    PropertyName: string;
}

export class ReadPropertySettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadPropertySettingsData();
    }
}

export class ReadPropertyModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        let val = status.ActualParameter;
        if (val) {
            if (status.ActualSettings) {
                if (typeof status.ActualSettings.PropertyName === 'string') {
                    const propList = status.ActualSettings.PropertyName.split('.');
                    for (let i = 0; i < propList.length - 1; i++) {
                        val = val[propList[i]];
                        if (typeof val === 'undefined' || val === null) {
                            status.Logger.logError('ReadProperty modul: Property ' + status.ActualSettings.PropertyName + ' not found.');
                            return -1;
                        }
                    }
                    status.ActualParameter = val[propList[propList.length - 1]];
                    return 0;
                } else {
                    status.Logger.logError('ReadProperty modul: No property name found.');
                }
            } else {
                status.Logger.logError('ReadProperty modul: No settings found.');
            }
        } else {
            status.Logger.logError('ReadProperty modul: No object found to read properties from.');
        }
        return super.execute(status);
    }
}
