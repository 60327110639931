import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {RequestBase} from './request-base';
import config from '../../assets/config.json'
import {RefreshTokenService} from './refresh-token.service';
import {catchError, switchMap} from "rxjs/operators";

@Injectable()
export class ExternalRestEndPoint extends RequestBase {

    static UpdateSettings: Subject<any> = new Subject();
    BasePath = 'api';
    static protocolConfig = {
        http: 'http://',
    };
    private addonsData = new Subject();
    getAddonsData$ = this.addonsData.asObservable();

    constructor(httpClient: HttpClient, private refreshTokenService: RefreshTokenService) {
        super(httpClient);
    }

    GetAddons(_filters = {}): Observable<any> {
        return this.http.get<any>(config.EXTERNAL_API_BASE_URL + this.BasePath + '/addon/external', {
            params: _filters
        });
    }

    GetAllPrebuiltTemplates(_filters = {}): Observable<any> {
        return this.http.get<any>(config.EXTERNAL_API_BASE_URL + this.BasePath + '/prebuiltapps/external', {
            params: _filters
        });
    }

    ApplyPrebuiltTemplate(obj): Observable<any> {
        return this.makeApplyPrebuiltTemplateRequest<any>(obj).pipe(
            catchError(error => {
                // If request fails with 401, try refreshing token and retry request
                if (error.status === 401) {
                    return this.refreshTokenService.RefreshToken().pipe(
                        switchMap((refreshResponse) => {
                            this.refreshTokenService.StoreTokens(refreshResponse.Data);
                            return this.makeApplyPrebuiltTemplateRequest<any>(obj)
                        }),
                        catchError(refreshError => throwError(refreshError))
                    );
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private makeApplyPrebuiltTemplateRequest<T>(obj): Observable<T> {
        const params = {...obj, ExternalAccessToken: localStorage.getItem(RefreshTokenService.ACCESS_TOKEN_NAME)}
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/prebuiltapps/ApplyTheme', params, this.options);
    }

    GetActivePrebuiltApp(obj?): Observable<any> {
        const params = {userId: JSON.parse(localStorage.getItem('user'))?.SID, ...obj}
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/meta/activeprebuilapp', {params});
    }

    GetAllUIThemes(_filters = {}): Observable<any> {
        return this.http.get<any>(config.EXTERNAL_API_BASE_URL + this.BasePath + '/uithemes/external', {
            params: _filters
        });
    }

    ApplyUITheme(obj): Observable<any> {
        const params = {...obj, ExternalAccessToken: localStorage.getItem(RefreshTokenService.ACCESS_TOKEN_NAME)}
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/uithemes/ApplyTheme', params, this.options);
    }

}
