import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import moment from "moment";
import {Router} from '@angular/router'
import { Comparer } from "../../../models/enums/comparer.enum";
import { UnitType } from "../../../models/enums/unittype.enum";
import { RequestFilter } from "../../../models/rest/requestfilter";
import { RequestOptions } from "../../../models/rest/requestoptions";
import { SchedulerService } from "../../../services/scheduler.service";
import config from "../../../../assets/config.json"
@Component({
    selector: 'jobs-logs',
    templateUrl: './jobs.logs.html',
    styleUrls: ['./jobs.logs.css']
})
export class JobsLogsComponent implements OnInit {
    Columns = [
        {
            Caption: '@@ExecutionTime',
            Data: 'ExecutionTime',
            Name: 'ExecutionTime',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.DateTime',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@UserName',
            Data: 'UserName',
            Name: 'UserName',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Name',
            Data: 'Name',
            Name: 'Name',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Successfull',
            Data: 'Successfull',
            Name: 'Successfull',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.Boolean',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        },
        {
            Caption: '@@Log',
            Data: 'Log',
            Name: 'Log',
            IsSortable: true,
            IsFilterable: true,
            Type: 'System.String',
            SearchValue: null,
            OrderState: 'NONE',
            FetchData: true,
            IsVisible: true,
            Width: {
                Type: UnitType.Pixel,
                Value: 200
            }
        }
    ];

    //#region AllUsers
    AllUsersValue = true;

    @Input()
    get AllUsers() {
        return this.AllUsersValue;
    }
    set AllUsers(val) {
        this.AllUsersValue = val;
        this.AllUsersChange.emit(this.AllUsersValue);
    }

    @Output() AllUsersChange = new EventEmitter<any>();
    //#endregion

    //#region ErrorsOnly
    ErrorsOnlyValue = true;

    @Input()
    get ErrorsOnly() {
        return this.ErrorsOnlyValue;
    }
    set ErrorsOnly(val) {
        this.ErrorsOnlyValue = val;
        this.ErrorsOnlyChange.emit(this.ErrorsOnlyValue);
    }

    @Output() ErrorsOnlyChange = new EventEmitter<any>();
    //#endregion
    SelectedRow;
    SelectedDate = new Date();
    Rows;
    User;
    ngOnInit(): void {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            this.router.navigate(['/default/settings/access-denied']);
			return null;
		}
        this.Refresh();
    }
    constructor(private service: SchedulerService, private router: Router) {
        this.User = localStorage.getItem('user');
        if (this.User) {
            this.User = JSON.parse(this.User);
        }
    }

    Refresh() {
        let filter: RequestOptions = new RequestOptions();
        filter.Filters = [];
        if (!this.AllUsers) {

            let userFilter = new RequestFilter();
            userFilter.Name = 'UserID';
            userFilter.Operator= Comparer.Equal;
            userFilter.Value = this.User.SID;
            
            filter.Filters.push(userFilter);
        }
        if (this.ErrorsOnly) {
            let errorFilter = new RequestFilter();
            errorFilter.Name = 'Successfull';
            errorFilter.Operator = Comparer.Equal;
            errorFilter.Value = false;
            filter.Filters.push(errorFilter);
        }
        if (this.SelectedDate) {
            let from = moment(this.SelectedDate);
            from.set('hour', 0);
            from.set('minute', 0);
            from.set('second', 0);
            from.set('millisecond', 0);
            from.add(from.utcOffset(),'minutes');
            let dateFromFilter = new RequestFilter();
            dateFromFilter.Name = 'ExecutionTime';
            dateFromFilter.Operator = Comparer.GreaterEqual;
            dateFromFilter.Value = from;
            filter.Filters.push(dateFromFilter);

            let to = moment(this.SelectedDate);
            to.add(1, 'day');
            to.set('hour', 0);
            to.set('minute', 0);
            to.set('second', 0);
            to.set('millisecond', 0);
            to.add(to.utcOffset(), 'minutes');
            let dateToFilter = new RequestFilter();
            dateToFilter.Name = 'ExecutionTime';
            dateToFilter.Operator = Comparer.SmallerEqual;
            dateToFilter.Value = to;
            filter.Filters.push(dateToFilter);
        }
        this.service.GetTriggerLogs(filter).subscribe((result) => {
            this.Rows = result;
        });
    }
    SelectionChanged(event) {
        this.SelectedRow = null;
        if (event && event.length == 1) {
            this.SelectedRow = event[0].RowData;
        }
    }
    DateChanged($event) {

    }
}
