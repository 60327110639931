import { Observable, Subject } from 'rxjs';
import { VariableHelper } from '../../helpers/variable.helper';
import { LayoutService } from '../../services/layout.service';
import { ViewType } from '../enums/viewtype.enum';

export interface ILayoutEditClient {
    get ViewMode(): boolean;
    get CanCheckOut(): boolean;
    get CanSave(): boolean;
    get Layout();


    onCheckOut();
    onSave();
    clear(): Promise<void>;
}

export class LayoutEditService {
    private static ActualLayoutEditClientSubject: Subject<ILayoutEditClient> = new Subject<ILayoutEditClient>();
    public static ActualLayoutEditClient: Observable<ILayoutEditClient> = LayoutEditService.ActualLayoutEditClientSubject;
    private static ActiveClients: { Client: ILayoutEditClient, SetLayoutMode: boolean }[] = [];
    public static LayoutEditClientChange: Subject<void> = new Subject<void>();

    public static SetNext(client: ILayoutEditClient, setLayoutMode: boolean) {
        if (client) {
            const clientObject = {
                Client: client,
                SetLayoutMode: setLayoutMode
            };
            LayoutEditService.ActiveClients.push(clientObject);
            LayoutEditService.SelectClient(clientObject);
        }
    }

    public static CloseActual() {
        LayoutService.ResolutionSelected.next(null);    //Cancel preview on edit cancel for page builder
        if (LayoutEditService.ActiveClients.length > 0) {
            const actVal = LayoutEditService.ActiveClients.pop();
            actVal.Client.clear().then(() => {
                let client;
                if (LayoutEditService.ActiveClients.length > 0) {
                    client = LayoutEditService.ActiveClients[LayoutEditService.ActiveClients.length - 1];
                }
                LayoutEditService.SelectClient(client);
            });
        } else {
            LayoutEditService.SelectClient(null);
        }
    }

    private static SelectClient(client: { Client: ILayoutEditClient, SetLayoutMode: boolean }) {
        if (client) {
            const layout = client.Client.Layout;
            VariableHelper.LoadVariables(layout);
            LayoutService.SelectedLayout.next(layout);
            LayoutService.SelectedItem.next(layout);
            LayoutEditService.ActualLayoutEditClientSubject.next(client.Client);
            if (LayoutService.LayoutEditMode.getValue() != client.SetLayoutMode) {
                LayoutService.LayoutEditMode.next(client.SetLayoutMode);
            }
        } else {
            LayoutEditService.ActualLayoutEditClientSubject.next(null);
            if (LayoutService.LayoutEditMode.getValue()) {
                LayoutService.LayoutEditMode.next(false);
            }
            if (LayoutService.ViewType.getValue() == ViewType.Edit) {
                LayoutService.ViewType.next(ViewType.View);
            }
        }
    }

    public static async CloseAll() {
        for (let i = LayoutEditService.ActiveClients.length - 1; i >= 0; i--) {
            const actClient = LayoutEditService.ActiveClients[i];
            await actClient.Client.clear();
        }
        LayoutEditService.ActiveClients = [];
        LayoutEditService.SelectClient(null);
    }
}
