import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EnumHelper } from "../../../helpers/enum.helper";
import { ContentType } from "../../../models/enums/contenttype.enum";

@Component({
  selector: 'content-menu-tab',
  templateUrl: './content.menu.tab.html',
    styleUrls: ['./content.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentMenuTab implements OnInit {

  //#region SelectedItem
    SelectedItemValue;

  
  @Input()
  get SelectedItem() {
    return this.SelectedItemValue;
  }
  set SelectedItem(val) {
    this.SelectedItemValue = val;
    this.SelectedItemChange.emit(this.SelectedItemValue);
  }

  @Output() SelectedItemChange = new EventEmitter<any>();
  //#endregion

  ContentTypes = [];

  constructor() {
    this.ContentTypes = EnumHelper.GetDropdownValues(ContentType);
  }
  ngOnInit(): void {
     
    }

   
    ContentTypeChanged() {
        switch (this.SelectedItemValue.ContentType) {
            case ContentType.Grid: this.SelectedItemValue.ElementType = 'grid'; break;
            case ContentType.Flex: this.SelectedItemValue.ElementType = 'flex'; break;
            case ContentType.Raster: this.SelectedItemValue.ElementType = 'raster'; break;
            case ContentType.Canvas: this.SelectedItemValue.ElementType = 'canvas'; break;
        }

       
    }
}
