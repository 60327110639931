import { DefineMemberAttributeDialog } from "../components/dialogs/definememberattribute/definememberattribute.dialog";
import { MessageBox } from "../components/dialogs/messagebox/messagebox.dialog";
import { TemplateDialog } from "../components/dialogs/template/template.dialog";
import { BaseDialog } from "../components/dialogs/basedialog/base.dialog";
import { TranslationDialog } from "../components/dialogs/translationdialog/translation.dialog";
import { AdvancedFilterDialog } from "../components/dialogs/advancedfilter/advancedfilter.dialog";
import { FormulaEditorDialog } from "../components/common/formulaEditor/formulaEditor.control";
import { FileExplorerDialog } from "../components/fileexplorer/fileexplorer.dialog";
import { ContentDialog } from "../components/dialogs/content/content.dialog";
import { CronBuilderDialog } from "../components/common/cronbuilder/cron.builder.dialog";
import { ObjectPreviewDialog } from "../components/dialogs/objectpreview/object.preview.dialog";
import { ActionDialog } from "../components/dialogs/actions/action.dialog";
import { MessageCenterDialog } from "../components/controls/messagecenter/messagecenter.dialog";
import { DataObjectFieldDialog } from "../settings/datamodel/dataobject/dataobject.field.dialog";
import { TranslationControl } from "../components/dialogs/translationdialog/translation.control";
import { DeploymentActionDialog } from "../components/dialogs/deploymentaction/deploymentaction.dialog";
import { WorkflowDebugDialog } from "../components/dialogs/workflowdebug/workflowdebug.dialog";
import { WorkflowNameChangeDialog } from "../components/dialogs/workflowdebug/workflow.name.change.dialog";
import { EventTreeDialog } from "../components/dialogs/eventtree/event.tree.dialog";
import { MonacoPreviewDialog } from "../components/dialogs/monacopreview/monaco.preview.dialog";
import { WebHookDialog } from "../components/dialogs/webhookdialog/webhook.dialog";
import { WorkflowUsageDialog } from "../appbuilder/menutabs/workflow/workflow.usage.dialog";
import { ConnectorWorkflowEditDialog } from "../settings/connector/connector.workflow.edit.dialog";
import { RequestNameDialog } from "../components/dialogs/reqeustname/reqeustname.dialog";
import { WorkflowEditDialog } from "../workflow/workflow.edit.dialog";
import { DataObjectTranslateDialog } from "../settings/datamodel/dataobject/dataobject.translate.dialog";
import { LayoutEditDialog } from "../components/dialogs/layouteditdialog/layout.edit.dialog";
import { ChangeKeyDialog } from "../components/dialogs/changekeydialog/change.key.dialog";
import { TourguideDialog } from "../components/dialogs/tourguide/tourguide.dialog/tourguide.dialog.component";

export const APP_DIALOGS = [
  DefineMemberAttributeDialog,
  MessageBox,
  TemplateDialog,
  BaseDialog,
  TranslationDialog,
  TranslationControl,
  WorkflowEditDialog,
  WorkflowDebugDialog,
  AdvancedFilterDialog,
  FormulaEditorDialog,
  FileExplorerDialog,
  ContentDialog,
  CronBuilderDialog,
  ObjectPreviewDialog,
  ActionDialog,
  MessageCenterDialog,
  DataObjectFieldDialog,
  DataObjectTranslateDialog,
  DeploymentActionDialog,
  WorkflowNameChangeDialog,
  EventTreeDialog,
  WebHookDialog,
  MonacoPreviewDialog,
  WorkflowUsageDialog,
  ConnectorWorkflowEditDialog,
  RequestNameDialog,
  LayoutEditDialog,
  ChangeKeyDialog,
  TourguideDialog,
];
