import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'monaco-theme-control',
    templateUrl: './monaco.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonacoThemeControl extends BaseThemeControl {
    editorOptions = {
        automaticLayout: true,
        language: 'css'
    };
    onChange(ev) {
        this.EmitAction(this.DataSourceInternal);
        this.ValueChanged.emit(this.DataSourceInternal);
    }
    @Output() ValueChanged = new EventEmitter<any>();

    editor;
    async init(editor) {
        this.editor = editor;

        let that = this;
        this.editor.onDidChangeModelContent(function (e) {
            that.ValueChanged.emit(e);
        });
    }
}
