import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AdvancedFilterComponent } from '../../../components/controls/advancedfilter/advancedfilter.component';
import { FilterHelper } from '../../../helpers/filter.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { RequestSort } from '../../../models/rest/requestsort';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { DynamicDataService } from '../../../services/dynamicdata.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { ReadDataSettings } from './read.data.settings';

@Component({
    selector: 'wf-ReadDataWF-settings',
    templateUrl: './read.settings.html',
    styleUrls: ['./read.settings.css']
})
export class ReadDataWFSettings extends FormulaWorkflowDialogContent implements AfterViewInit {

    SourceName: string;
    DestinationName: string;
    UseWorkflowValue: boolean;
    Sort = [];
    Columns: any;
    Filter: RequestFilter;
    @ViewChild('advanced') filterComponent: AdvancedFilterComponent;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'ReadDataWFDataWFWFModule';
        reg.Caption = '@@ReadData';
        reg.Index = 40;
        reg.SettingsControl = ReadDataWFSettings;
        reg.SettingsTypeHelper = new ReadDataWFSettingsDataHelper();
        reg.Executer = ReadDataWFDataWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ReadDataSettings.ModuleID;
        return reg;
    }

    ngAfterViewInit(): void {
        if (this.Filter) {
            this.filterComponent.SetFilter(this.Filter);
        }
    }

    SourceChanged() {
        if (this.WFEditOptions && this.WFEditOptions.Layout) {
            const elem = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, this.SourceName);
            MetaHelper.FindFlatTableProperties(this.WFEditOptions.Layout, elem).then((props) => {
                if (props) {
                    const list = [];
                    props.Columns.forEach(field => {
                        list.push({
                            IsFilterable: true,
                            PropertyName: field.Name,
                            Caption: field.Name,
                            Type: AttributeProperty.GetFilterType(field)
                        });
                    });
                    this.Columns = list;
                } else {
                    this.Columns = null;
                }
            });
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            if (data.SourceName) {
                this.SourceName = data.SourceName;
            }
            if (data.DestinationName) {
                this.DestinationName = data.DestinationName;
            }
            if (data.Filter) {
                this.Filter = data.Filter;
            }
            if (data.UseWorkflowValue != null) {
                this.UseWorkflowValue = data.UseWorkflowValue;
            }
            if (data.Sort) {
                const sort = [];
                data.Sort.forEach(x => {
                    sort.push({
                        Name: x.Name,
                        Order: x.Order
                    });
                });
                this.Sort = sort;
            }
        }
    }

    getResult(): any {
        const retVal = new ReadDataWFSettingsData();
        retVal.SourceName = this.SourceName;
        retVal.DestinationName = this.DestinationName;
        retVal.Filter = this.filterComponent.GetActualFilter();
        retVal.UseWorkflowValue = this.UseWorkflowValue;
        this.Sort.forEach((x, i) => {
            if (x.Name) {
                retVal.Sort.push({
                    Name: x.Name,
                    Order: x.Order,
                    Position: i
                });
            }
        });
        return retVal;
    }

    addSort() {
        this.Sort.push({
            Name: '',
            Order: 0
        });
    }

    removeSort(i) {
        this.Sort.splice(i, 1);
    }
}

export class ReadDataWFSettingsData {
    SourceName: string;
    DestinationName: string;
    UseWorkflowValue: boolean;
    Filter: RequestFilter;
    Sort: RequestSort[] = [];
}

export class ReadDataWFSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ReadDataWFSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const ReadDataWFDataWF = new WorkflowExitInfo();
        ReadDataWFDataWF.ID = 0;
        ReadDataWFDataWF.Label = '@@Success';
        const end = new WorkflowExitInfo();
        end.ID = 1;
        end.Label = '@@Error';
        return [ReadDataWFDataWF, end];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings) {
            if (settings.SourceName === oldName) {
                settings.SourceName = newName;
                retVal = true;
            }
            if (settings.DestinationName === oldName) {
                settings.DestinationName = newName;
                retVal = true;
            }
        }
        return retVal;
    }
}

export class ReadDataWFDataWFModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.SourceName) {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.SourceName);
                let DataSourceId = MetaHelper.FindDataSourceID(status.WorkflowLayoutService.Layout, elem);
                if (elem['DataSource']) {
                    DataSourceId = elem['DataSource'];
                }
                if (DataSourceId) {
                    const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
                    const options = new RequestOptions();
                    if (status.ActualSettings.Sort) {
                        options.Sort = status.ActualSettings.Sort;
                    }
                    if (status.ActualSettings.Filter) {
                        options.Filters = [];
                        const Filter = JSON.parse(JSON.stringify(status.ActualSettings.Filter));
                        if (FilterHelper.FindFilterValue(Filter, '$Value')) {
                            if (status.ActualSettings.SourceName) {
                                if (status.ActualSettings.UseWorkflowValue) {
                                    FilterHelper.ReplaceFilterValue(Filter, '$Value', status.ActualParameter);
                                    options.Filters.push(Filter);
                                } else if (elem && elem.Element && elem.Element.DataSource) {
                                    FilterHelper.ReplaceFilterValue(Filter, '$Value', elem.Element.DataSource);
                                    options.Filters.push(Filter);
                                } else {
                                    status.Logger.logWarning('ReadDataWFDataWF modul: No SearchSourceSource set.');
                                }
                            } else {
                                status.Logger.logWarning('ReadDataWFDataWF modul: No Source set.');
                            }
                        } else {
                            options.Filters.push(Filter);
                        }
                    }
                    const service = new DynamicDataService(http);
                    const result = await service.SearchObjects(DataSourceId, options).toPromise();
                    if (status.ActualSettings.DestinationName) {
                        const destElem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.DestinationName);
                        if (destElem) {
                            destElem.Element.DataSource = result;
                        } else {
                            status.Logger.logWarning('ReadDataWFDataWF modul: Element not found.');
                        }
                    }
                    if (result) {
                        status.ActualParameter = result;
                        return 0;
                    } else {
                        status.Logger.logError('ReadDataWFDataWF modul: Data could not be ReadDataWFDataWFd.');
                        return 1;
                    }
                } else {
                    status.Logger.logError('ReadDataWFDataWF modul: No DataSource set.');
                }
            } else {
                status.Logger.logError('ReadDataWFDataWF modul: No SourceElement found.');
            }
        } else {
            status.Logger.logError('ReadDataWFDataWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
