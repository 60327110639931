import { REGISTRY } from '../../services/dynamic.component.service';
import { WorkflowWizardControl } from '../../settings/workflowwizard/workflow.wizard.control';
import { WorkflowWizardEditItem } from '../../settings/workflowwizard/workflow.wizard.edit.item';
import { WorkflowWizardMenuTab } from '../../settings/workflowwizard/workflow.wizard.menu.tab';

export class ComponentRegistryMiddle {
    public static Initialize() {
        REGISTRY['workflow-edit-control'] = {
            Control: WorkflowWizardEditItem,
            type: '',
            page: [],
        };
        REGISTRY['workflow-wizard-control'] = {
            Control: WorkflowWizardControl,
            Icon: 'workflow',
            Label: '@@Workflow-Wizard',
            type: 'StaticControl',
            page: [1],
            MenuTab: WorkflowWizardMenuTab
        };
    }
}
