import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { HeterogenArea } from '../../../models/datadescription/multi/datadescription.model';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { PageStatus, ViewInfo, ViewInfoEntry, ViewSetting } from '../../../models/page.status';
import { TranslatedString } from '../../../models/translatedstring.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowFormulaCalculator } from '../../../workflow/modules/definevalue/define.value.settings';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';

@Component({
    selector: 'set-query-view-wf-settings',
    templateUrl: './set.query.view.settings.html',
    styles: ['.childNode { margin-left: 20px }']
})
export class SetQueryViewSettings extends FormulaWorkflowDialogContent {
    private static _ReportObjects = ['tablereportObject', 'chartreportObject'];

    Data = new SetQueryViewSettingsData();
    AllReportObjects = [];
    FilteredObjects = [];
    Queries = [];
    AxisNodes = [];
    VisibilityTypes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setQueryViewWFModule';
        reg.Caption = '@@Sicht anwenden';
        reg.GroupID = 'report';
        reg.Index = 10;
        reg.SettingsControl = SetQueryViewSettings;
        reg.SettingsTypeHelper = new SetQueryViewSettingsDataHelper();
        reg.Executer = SetQueryViewModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    private static FindObjects(element) {
        const retVal = [];
        if (element) {
            if (SetQueryViewSettings._ReportObjects.some(ro => element.ElementType === ro)) {
                retVal.push({
                    Caption: element.Name,
                    ID: element.ID,
                    Query: element.Query,
                    Selected: false
                });
            } else if (element.Elements) {
                element.Elements.forEach(child => {
                    retVal.push(...SetQueryViewSettings.FindObjects(child));
                });
            }
        }
        return retVal;
    }

    private static GetAxisNodes(area: HeterogenArea, values) {
        if (area) {
            const retVal = {
                Caption: TranslatedString.GetTranslation(area.Caption),
                Children: []
            };
            if (area.Tuples) {
                const levelNode = {
                    Caption: TranslateHelper.TranslatorInstance.instant('@@Level'),
                    Entries: []
                }
                area.Tuples.forEach(t => {
                    if (t.Levels) {
                        t.Levels.forEach(l => {
                            const lNode = {
                                Caption: TranslatedString.GetTranslation(l.Caption),
                                ID: l.UniqueID,
                                Value: VisibilityType.None
                            };
                            const val = values.find(v => v.ElementID === l.UniqueID);
                            if (val) {
                                lNode.Value = val.VisibleState;
                            }
                            levelNode.Entries.push(lNode);
                        });
                    }
                });
                if (levelNode.Entries.length > 0) {
                    retVal.Children.push(levelNode);
                }
            }
            if (area.Measures && area.Measures.length > 0) {
                const measureNode = {
                    Caption: TranslateHelper.TranslatorInstance.instant('@@Werte'),
                    Entries: []
                }
                area.Measures.forEach(m => {
                    const mNode = {
                        Caption: TranslatedString.GetTranslation(m.Caption),
                        ID: m.UniqueID,
                        Value: VisibilityType.None
                    };
                    const val = values.find(v => v.ElementID === m.UniqueID);
                    if (val) {
                        mNode.Value = val.VisibleState;
                    }
                    measureNode.Entries.push(mNode);
                });
                retVal.Children.push(measureNode);
            }
            if (retVal.Children.length > 0) {
                return retVal;
            }
        }
        return null;
    }

    private static GetSettings(nodes) {
        const retVal = [];
        if (nodes) {
            nodes.forEach(n => {
                if (n.Children) {
                    retVal.push(...SetQueryViewSettings.GetSettings(n.Children));
                } else if (n.Entries) {
                    n.Entries.forEach(x => {
                        if (x.Value !== VisibilityType.None) {
                            const vie = new ViewSetting();
                            vie.ElementID = x.ID;
                            vie.VisibleState = x.Value;
                            retVal.push(vie);
                        }
                    });
                }
            });
        }
        return retVal;
    }

    ngOnInit(): void {
        this.VisibilityTypes = EnumHelper.GetDropdownValues(VisibilityType);
        if (this.WFEditOptions && this.WFEditOptions.Layout) {
            this.AllReportObjects = SetQueryViewSettings.FindObjects(this.WFEditOptions.Layout);
            const filtered = [];
            this.AllReportObjects.forEach(x => {
                if (x.Query === this.Data.QueryID) {
                    filtered.push(x);
                }
            });
            this.FilteredObjects = filtered;
            if (this.WFEditOptions.Layout.Datadescriptions) {
                this.Queries = this.WFEditOptions.Layout.Datadescriptions.filter(x => !x.IsRelational);
                this.fillElements();
            }
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetQueryViewSettingsData, json);
        }
    }

    getResult(): any {
        const filtered = [];
        this.AllReportObjects.forEach(x => {
            if (x.Query === this.Data.QueryID && x.Selected) {
                filtered.push(x.ID);
            }
        });
        this.Data.ObjectIDs = filtered;
        this.Data.Settings = SetQueryViewSettings.GetSettings(this.AxisNodes);
        return this.Data;
    }

    onQueryChanged() {
        const filtered = [];
        this.AllReportObjects.forEach(x => {
            x.Selected = false;
            if (x.Query === this.Data.QueryID) {
                filtered.push(x);
            }
        });
        this.FilteredObjects = filtered;
        this.Data.Settings.splice(0);
        this.fillElements();
    }

    fillElements() {
        const axisNodes = [];
        const query = this.Queries.find(x => x.ID === this.Data.QueryID);
        if (query && query.Datadescription) {
            const xAxis = {
                Caption: TranslateHelper.TranslatorInstance.instant('@@X-Achse'),
                Children: []
            };
            query.Datadescription.XLevelNodes.Areas.forEach((area, i) => {
                const node = SetQueryViewSettings.GetAxisNodes(area, this.Data.Settings);
                if (node) {
                    if (!node.Caption) {
                        node.Caption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + (i + 1);
                    }
                    xAxis.Children.push(node);
                }
            });
            axisNodes.push(xAxis);
            const yAxis = {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Y-Achse'),
                Children: []
            };
            query.Datadescription.YLevelNodes.Areas.forEach((area, i) => {
                const node = SetQueryViewSettings.GetAxisNodes(area, this.Data.Settings);
                if (node) {
                    if (!node.Caption) {
                        node.Caption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + (i + 1);
                    }
                    yAxis.Children.push(node);
                }
            });
            axisNodes.push(yAxis);
        }
        this.AxisNodes = axisNodes;
    }
}

export class SetQueryViewSettingsData {
    QueryID;
    ObjectIDs = [];
    Settings: ViewSetting[] = [];
    ExecuteFormula: string;
}

export class SetQueryViewSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetQueryViewSettingsData();
    }
}

export class SetQueryViewModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const pageState = PageStatus.ActualState.getValue();
            if (pageState) {
                let addView = true;
                if (status.ActualSettings.ExecuteFormula) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    try {
                        const val = calculator.CalcFormula(status.ActualSettings.ExecuteFormula);
                        if (typeof val === 'boolean') {
                            addView = val;
                        }
                    } catch (e) {
                        status.Logger.logWarning('SetQueryView modul: Error while calculating formula ' +
                            status.ActualSettings.ExecuteFormula + ' (' + e + ').');
                    }
                }
                if (addView) {
                    let vi: ViewInfo;
                    if (pageState.ViewInfos) {
                        vi = pageState.ViewInfos.find(x => x.QueryID === status.ActualSettings.QueryID);
                        if (vi) {
                            if (!vi.Entries) {
                                vi.Entries = [];
                            }
                        } else {
                            vi = new ViewInfo();
                            vi.QueryID = status.ActualSettings.QueryID;
                            pageState.ViewInfos.push(vi);
                        }
                    } else {
                        vi = new ViewInfo();
                        vi.QueryID = status.ActualSettings.QueryID;
                        pageState.ViewInfos = [vi];
                    }
                    const vie = new ViewInfoEntry();
                    vie.WorkflowID = status.WorkflowID;
                    vie.ModuleID = this.ModulID;
                    if (status.ActualSettings.ObjectIDs) {
                        vie.ObjectIDs.push(...status.ActualSettings.ObjectIDs);
                    }
                    if (status.ActualSettings.Settings) {
                        vie.Settings.push(...status.ActualSettings.Settings);
                    }
                    vi.Entries.push(vie);
                } else {
                    if (pageState.ViewInfos) {
                        const vi: ViewInfo = pageState.ViewInfos.find(x => x.QueryID === status.ActualSettings.QueryID);
                        if (vi && vi.Entries) {
                            const wfID = status.WorkflowID;
                            for (let i = vi.Entries.length - 1; i >= 0; i--) {
                                const entry = vi.Entries[i];
                                if (entry.WorkflowID === wfID && entry.ModuleID === this.ModulID) {
                                    vi.Entries.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    }
                }
                return 0;
            } else {
                status.Logger.logError('SetQueryView module: No page state found.');
            }
        } else {
            status.Logger.logError('SetQueryView module: No settings found.');
        }
        return super.execute(status);
    }
}
