import {
    WF_GROUP_REGISTRY, WF_REGISTRY, WF_SERVICE_SETTINGS, WF_TYPE_REGISTRY, WorkflowExitTypeInfo
} from '../../services/workflow.service';
import { AudioSettings } from '../../workflow/modules/audio/audio.settings';
import { ConditionSettings, ConditionSettingsData } from '../../workflow/modules/condition/condition.settings';
import { ContainerChooser } from '../../workflow/modules/containeroperations/container.chooser';
import {
    ExecuteCountSettings, ExecuteCountSettingsData
} from '../../workflow/modules/containeroperations/executecount/execute.count.settings';
import {
    ExecuteDeleteSettings, ExecuteDeleteSettingsData
} from '../../workflow/modules/containeroperations/executedelete/execute.delete.settings';
import {
    ExecuteInsertSettings, ExecuteInsertSettingsData
} from '../../workflow/modules/containeroperations/executeinsert/execute.insert.settings';
import {
    ExecuteReadSettings, ExecuteReadSettingsData
} from '../../workflow/modules/containeroperations/executeread/execute.read.settings';
import {
    ExecuteReplaceSettings, ExecuteReplaceSettingsData
} from '../../workflow/modules/containeroperations/executereplace/execute.replace.settings';
import {
    ExecuteUpdateSettings, ExecuteUpdateSettingsData
} from '../../workflow/modules/containeroperations/executeupdate/execute.update.settings';
import { ConvertToImageSettings } from '../../workflow/modules/convertToImage/convertToImage.settings';
import { AddDataWFSettings } from '../../workflow/modules/dataoperations/add.settings';
import { DeleteDataWFModuleExecuter } from '../../workflow/modules/dataoperations/delete.settings';
import { DeleteByFilterSettings } from '../../workflow/modules/dataoperations/deletebyfilter.settings';
import { ReadDataSettings } from '../../workflow/modules/dataoperations/read.data.settings';
import { ReadDataWFSettings } from '../../workflow/modules/dataoperations/read.settings';
import { RefreshWFModuleExecuter } from '../../workflow/modules/dataoperations/refresh.settings';
import { SaveDataSettings } from '../../workflow/modules/dataoperations/save.settings';
import { DataSwitchSettings, DataSwitchSettingsData } from '../../workflow/modules/dataswitch/dataswitch.settings';
import { DefineDateSettings, DefineDateSettingsData } from '../../workflow/modules/definedate/define.date.settings';
import { DefineListSettings, DefineListSettingsData } from '../../workflow/modules/definelist/define.list.settings';
import {
    DefineObjectSettingsItem, DefineObjectsSettings, DefineObjectsSettingsData
} from '../../workflow/modules/defineobject/define.objects.settings';
import { DefineTextsSettings, DefineTextsSettingsData } from '../../workflow/modules/definetext/define.texts.settings';
import {
    DefineMultiValuesSettings, DefineMultiValuesSettingsData
} from '../../workflow/modules/definevalue/define.multi.values.settings';
import { DefineValueSettings, DefineValueSettingsData } from '../../workflow/modules/definevalue/define.value.settings';
import { DownloadSettings } from '../../workflow/modules/download/download.settings';
import {
    EnumSwitchAdvancedSettings, EnumSwitchAdvancedSettingsData
} from '../../workflow/modules/enumSwitch/enum.switch.advanced.settings';
import { EnumSwitchSettings, EnumSwitchSettingsData } from '../../workflow/modules/enumSwitch/enumSwitch.settings';
import { TriggerEventSettings } from '../../workflow/modules/events/trigger.event.settings';
import { TriggerLayoutEventSettings } from '../../workflow/modules/events/trigger.layout.event.settings';
import { ExecuteWorkflowData, ExecuteWorkflowSettings } from '../../workflow/modules/executeWorkflow/executeWorkflow.settings';
import { ForEachLoopSettings, ForEachLoopSettingsData } from '../../workflow/modules/forEach/forEachLoop.settings';
import { GenerateOutputSettings, GenerateOutputSettingsData } from '../../workflow/modules/generateoutput/generateoutput.settings';
import { AddToDataSourceModulExecuter } from '../../workflow/modules/layout/addToDatasource/addtodatasource.settings';
import { CloseDropDownSettings } from '../../workflow/modules/layout/closedropdown/close.dropdown.settings';
import { ElementPropertySettings } from '../../workflow/modules/layout/elemProperty/elemProperty.settings';
import { ReadControlPropertiesSettings } from '../../workflow/modules/layout/elemProperty/read.control.properties.settings';
import { SetControlPropertiesSettings } from '../../workflow/modules/layout/elemProperty/set.control.properties.settings';
import {
    ElementValueModuleExecuter, ElementValueSettings, SetDataSourceModulExecuter
} from '../../workflow/modules/layout/elemValue/elemValue.settings';
import { GetMultiElemValueSettings } from '../../workflow/modules/layout/elemValue/get.multi.elem.value.settings';
import { SetMultiElemValueSettings } from '../../workflow/modules/layout/elemValue/set.multi.elem.value.settings';
import { LayoutItemChooser } from '../../workflow/modules/layout/layout.item.chooser';
import { OpenDropDownSettings } from '../../workflow/modules/layout/opendropdown/open.dropdown.settings';
import {
    RemoveFromDataSourceModulExecuter
} from '../../workflow/modules/layout/removeFromDatasource/removefromdatasource.settings';
import { ScrollToSettings } from '../../workflow/modules/layout/scrollTo/scroll.to.settings';
import { SetFocusModulExecuter } from '../../workflow/modules/layout/setFocus/set.focus.settings';
import { RemoveMultiStyleSettings } from '../../workflow/modules/layout/setStyle/remove.multi.style.settings';
import { SetMultiStyleSettings } from '../../workflow/modules/layout/setStyle/set.multi.style.settings';
import { RemoveStyleModuleExecuter, SetStyleSettings } from '../../workflow/modules/layout/setStyle/set.style.settings';
import { LinkExternalSettings } from '../../workflow/modules/linkexternal/linkexternal.settings';
import { GetListCountSettingsData, GetListCountSettingsHelper } from '../../workflow/modules/listoperations/getlistcount.settings';
import { GetListItemSettings, GetListItemSettingsData } from '../../workflow/modules/listoperations/getlistitem.settings';
import {
    GetListItemCalcSettings, GetListItemCalcSettingsData
} from '../../workflow/modules/listoperations/getlistitemcalc.settings';
import { LoginSettings, LogoutModuleExecuter } from '../../workflow/modules/login/login.settings';
import {
    ReadNavigationParametersSettings, ReadNavigationParametersSettingsDataHelper
} from '../../workflow/modules/navigation/navigationparameters.settings';
import { OpenURLSettings } from '../../workflow/modules/navigation/openURL.settings';
import { AllowNavigationSettings, PreventNavigationSettings } from '../../workflow/modules/navigation/preventnavigation.settings';
import { ReadURLSettings } from '../../workflow/modules/navigation/read.url.settings';
import { URLNavigationSettings } from '../../workflow/modules/navigation/url.navigation.settings';
import { DeleteOfflineValueSettings } from '../../workflow/modules/offline/delete.offline.value.settings';
import { GetOfflineValueSettings } from '../../workflow/modules/offline/get.offline.value.settings';
import { SetOfflineValueSettings } from '../../workflow/modules/offline/set.offline.value.settings';
import { AddProfileSettings } from '../../workflow/modules/profile/add/add.profile.settings';
import { ApplyProfileSettings } from '../../workflow/modules/profile/apply/apply.profile.settings';
import { DeleteProfileSettings } from '../../workflow/modules/profile/delete/delete.profile.settings';
import { ReadProfilesSettings } from '../../workflow/modules/profile/read/read.profiles.settings';
import { UpdateProfileSettings } from '../../workflow/modules/profile/update/update.profile.settings';
import {
    ReadMultiPropertySettings, ReadMultiPropertySettingsData
} from '../../workflow/modules/readProperty/read.multi.property.settings';
import { ReadPropertySettings } from '../../workflow/modules/readProperty/readProperty.settings';
import {
    SetMultiPropertySettings, SetMultiPropertySettingsData
} from '../../workflow/modules/readProperty/set.multi.property.settings';
import { SetPropertySettings } from '../../workflow/modules/readProperty/setProperty.settings';
import {
    RestCallAdvancedSettings, RestCallAdvancedSettingsData, RestCallSettings, RestCallSettingsData
} from '../../workflow/modules/restCall/restCall.settings';
import {
    SaveToMediaSourceSettings, SaveToMediaSourceSettingsData
} from '../../workflow/modules/savetomediasource/savetomediasource.settings';
import { ExecuteScriptSettings, ExecuteScriptSettingsData } from '../../workflow/modules/script/execute.script.settings';
import { SendNotificationSettings } from '../../workflow/modules/sendnotification/send.notification.settings';
import { ServiceWorkflowUsageDialog } from '../../workflow/modules/service.workflow.usage.dialog';
import { EditImageSettings, EditImageSettingsData } from '../../workflow/modules/service/editimage/editimage.settings';
import {
    GetTriggerContextSettings, GetTriggerContextSettingsData
} from '../../workflow/modules/service/gettriggercontext/get.trigger.context.settings';
import { AddToRedisSettings, AddToRedisSettingsData } from '../../workflow/modules/service/redis/addtoredis.settings';
import { GetFromRedisSettings, GetFromRedisSettingsData } from '../../workflow/modules/service/redis/getfromredis.settings';
import {
    RemoveFromRedisSettings, RemoveFromRedisSettingsData
} from '../../workflow/modules/service/redis/removefromredis.settings';
import {
    SendMailExtendedSettings, SendMailExtendedSettingsData, SendMailSettings, SendMailSettingsData
} from '../../workflow/modules/service/sendmail/sendmail.settings';
// import { SendWebPushSettings } from '../../workflow/modules/service/sendwebpush/sendwebpush.settings';
import { StartModuleSettingsHelper, StartWorkflowSettingsData } from '../../workflow/modules/service/start/start.settings';
import { CloseDialogSettings } from '../../workflow/modules/showDialog/close.dialog.settings';
import { DisableDialogCloseSettingsDataHelper } from '../../workflow/modules/showDialog/disable.dialog.close.settings';
import { LayoutDialogContent } from '../../workflow/modules/showDialog/layout.dialog.content';
import { ShowContentDialogSettings } from '../../workflow/modules/showDialog/show.content.dialog.settings';
import { ShowMessageBoxSettings } from '../../workflow/modules/showDialog/show.messagebox.settings';
import { ShowDialogSettings } from '../../workflow/modules/showDialog/showDialog.settings';
import { TemplateDialogContent } from '../../workflow/modules/showDialog/template.dialog.content';
import { GetCurrentDateSettingsData, GetCurrentDateSettingsHelper } from '../../workflow/modules/simple/getCurrentDate.settings';
import { GetGeoLocationPropertiesSettings } from '../../workflow/modules/simple/getCurrentGeoLocation.settings';
import {
    GetCurrentLanguageSettings, GetCurrentLanguageSettingsData
} from '../../workflow/modules/simple/getCurrentLanguage.settings';
import {
    GetCurrentUserSettingsData, GetCurrentUserSettingsHelper, GetUserPropertiesSettings, GetUserPropertiesSettingsData
} from '../../workflow/modules/simple/getCurrentUser.settings';
import { SleepSettings, SleepSettingsData } from '../../workflow/modules/sleep/sleep.settings';
import { EndWfModuleHelper } from '../../workflow/modules/startend/end.wfmodule';
import { StartWfModuleData, StartWfModuleSettings } from '../../workflow/modules/startend/start.wfmodule';
import { TemplateAdvancedSettings } from '../../workflow/modules/template/template.advanced.settings';
import { TemplateSettings } from '../../workflow/modules/template/template.settings';
import {
    ReadMultiGlobalVariableSettings
} from '../../workflow/modules/variables/readGlobalVariable/read.multi.global.variable.settings';
import { ReadGlobalVariableSettings } from '../../workflow/modules/variables/readGlobalVariable/readGlobalVariable.settings';
import { ReadMultiVariableSettings } from '../../workflow/modules/variables/readVariable/read.multi.variable.settings';
import { ReadVariableSettings } from '../../workflow/modules/variables/readVariable/readVariable.settings';
import {
    SetMultiGlobalVariableSettings
} from '../../workflow/modules/variables/setGlobalVariable/set.multi.global.variable.settings';
import { SetGlobalVariableSettings } from '../../workflow/modules/variables/setGlobalVariable/setGlobalVariable.settings';
import { SetMultiVariableSettings } from '../../workflow/modules/variables/setVariable/set.multi.variable.settings';
import { SetVariableSettings } from '../../workflow/modules/variables/setVariable/setVariable.settings';
import { GetWorkflowStatusData, GetWorkflowStatusSettings } from '../../workflow/modules/wfStatus/getWFStatus.settings';
import { SetWorkflowStatusData, SetWorkflowStatusSettings } from '../../workflow/modules/wfStatus/setWFStatus.settings';
import { WorkflowContainerChooser } from '../../workflow/workflow.container.chooser';
import { WorkflowControl } from '../../workflow/workflow.control';
import { WorkflowDialog, WorkflowModuleDescriptionDialog } from '../../workflow/workflow.dialog';
import { WorkflowEditDialog } from '../../workflow/workflow.edit.dialog';
import { DisabledWorkflowEditDialog } from "../../workflow/disabled-workflow.edit.dialog";
import { WorkflowExpressionDialog } from '../../workflow/workflow.expression.dialog';
import { WorkflowItem } from '../../workflow/workflow.item';
import { WorkflowParamDialog } from '../../workflow/workflow.param.dialog';

export const APP_WORKFLOW = [
    WorkflowControl,
    WorkflowItem,
    WorkflowDialog,
    WorkflowModuleDescriptionDialog,
    WorkflowEditDialog,
    DisabledWorkflowEditDialog,
    ConditionSettings,
    ShowDialogSettings,
    EnumSwitchSettings,
    RestCallSettings,
    RestCallAdvancedSettings,
    ElementValueSettings,
    ElementPropertySettings,
    ScrollToSettings,
    SetWorkflowStatusSettings,
    GetWorkflowStatusSettings,
    ExecuteWorkflowSettings,
    WorkflowParamDialog,
    ForEachLoopSettings,
    ReadDataWFSettings,
    ReadDataSettings,
    DeleteByFilterSettings,
    AddDataWFSettings,
    WorkflowExpressionDialog,
    SendMailSettings,
    SendMailExtendedSettings,
    // SendWebPushSettings,
    GetListItemSettings,
    GetListItemCalcSettings,
    LayoutItemChooser,
    TemplateSettings,
    TemplateAdvancedSettings,
    OpenURLSettings,
    ReadPropertySettings,
    SetPropertySettings,
    SetVariableSettings,
    SetMultiVariableSettings,
    ReadVariableSettings,
    ReadMultiVariableSettings,
    SetGlobalVariableSettings,
    SetMultiGlobalVariableSettings,
    ReadGlobalVariableSettings,
    ReadMultiGlobalVariableSettings,
    ReadNavigationParametersSettings,
    AddToRedisSettings,
    GetFromRedisSettings,
    RemoveFromRedisSettings,
    DefineValueSettings,
    DefineMultiValuesSettings,
    EditImageSettings,
    DefineDateSettings,
    GenerateOutputSettings,
    SaveToMediaSourceSettings,
    DataSwitchSettings,
    DefineListSettings,
    SendNotificationSettings,
    CloseDropDownSettings,
    OpenDropDownSettings,
    SetStyleSettings,
    ReadMultiPropertySettings,
    SetMultiPropertySettings,
    DefineObjectsSettings,
    DefineObjectSettingsItem,
    DefineTextsSettings,
    GetUserPropertiesSettings,
    ExecuteDeleteSettings,
    ContainerChooser,
    ExecuteInsertSettings,
    ExecuteUpdateSettings,
    ExecuteReadSettings,
    ExecuteReplaceSettings,
    ExecuteCountSettings,
    ShowMessageBoxSettings,
    ShowContentDialogSettings,
    LayoutDialogContent,
    TemplateDialogContent,
    SetControlPropertiesSettings,
    ReadControlPropertiesSettings,
    SleepSettings,
    GetCurrentLanguageSettings,
    GetTriggerContextSettings,
    ExecuteScriptSettings,
    GetMultiElemValueSettings,
    SetMultiElemValueSettings,
    SetMultiStyleSettings,
    RemoveMultiStyleSettings,
    LoginSettings,
    LinkExternalSettings,
    URLNavigationSettings,
    GetGeoLocationPropertiesSettings,
    GetOfflineValueSettings,
    SetOfflineValueSettings,
    DeleteOfflineValueSettings,
    TriggerEventSettings,
    ReadURLSettings,
    SaveDataSettings,
    PreventNavigationSettings,
    AllowNavigationSettings,
    EnumSwitchAdvancedSettings,
    WorkflowContainerChooser,
    TriggerLayoutEventSettings,
    AudioSettings,
    CloseDialogSettings,
    StartWfModuleSettings,
    ConvertToImageSettings,
    DownloadSettings,
    ServiceWorkflowUsageDialog,
    AddProfileSettings,
    ApplyProfileSettings,
    DeleteProfileSettings,
    ReadProfilesSettings,
    UpdateProfileSettings
];

export class WorkflowRegistryHelper {

    static ExternalWorkflowRegistry: any[] = [ElementValueModuleExecuter, SetDataSourceModulExecuter, StartModuleSettingsHelper,
        GetListCountSettingsHelper, AddToDataSourceModulExecuter, RemoveFromDataSourceModulExecuter, GetCurrentUserSettingsHelper,
        GetCurrentDateSettingsHelper, ReadNavigationParametersSettingsDataHelper, SetFocusModulExecuter, RemoveStyleModuleExecuter,
        DisableDialogCloseSettingsDataHelper, RefreshWFModuleExecuter, DeleteDataWFModuleExecuter, EndWfModuleHelper,
        LogoutModuleExecuter];

    public static Build() {
        // Module
        APP_WORKFLOW.forEach(wf => {
            if (typeof wf['GetRegistry'] === 'function') {
                const reg = wf['GetRegistry']();
                WF_REGISTRY.set(reg.ID, reg);
            }
        });
        WorkflowRegistryHelper.ExternalWorkflowRegistry.forEach(wf => {
            if (typeof wf['GetRegistry'] === 'function') {
                const reg = wf['GetRegistry']();
                WF_REGISTRY.set(reg.ID, reg);
            }
        });

        // Gruppen
        WF_GROUP_REGISTRY.push({
            Caption: '@@Nicht zugeordnet',
            GroupID: '',
            Index: 110,
            ParentID: null,
            Icon:'wf_group_none'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Status',
            GroupID: 'wfStatus',
            Index: 100,
            ParentID: null,
            Icon: 'wf_group_status'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Profile',
            GroupID: 'profileGroup',
            Index: 103,
            ParentID: null,
            Icon: 'wf_group_profile'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Offline',
            GroupID: 'wfOffline',
            Index: 105,
            ParentID: null,
            Icon: 'wf_group_offline'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Variables',
            GroupID: 'variables',
            Index: 90,
            ParentID: null,
            Icon: 'wf_group_variables'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Events',
            GroupID: 'events',
            Index: 95,
            ParentID: null,
            Icon: 'wf_group_events'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Navigation',
            GroupID: 'navigation',
            Index: 97,
            ParentID: null,
            Icon: 'wf_group_navigation'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@ListOperations',
            GroupID: 'listoperations',
            Index: 80,
            ParentID: null,
            Icon: 'wf_group_listoperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@DataOperations',
            GroupID: 'dataoperations',
            Index: 30,
            ParentID: null,
            Icon: 'wf_group_dataoperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Container Operationen',
            GroupID: 'containeroperations',
            Index: 37,
            ParentID: null,
            Icon: 'wf_group_containeroperations'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@DataChecks',
            GroupID: 'datachecks',
            Index: 40,
            ParentID: null,
            Icon: 'wf_group_datachecks'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Generate Data',
            GroupID: 'generateData',
            Index: 20,
            ParentID: null,
            Icon: 'wf_group_generatedata'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Aktionen',
            GroupID: 'wfActions',
            Index: 15,
            ParentID: null,
            Icon: 'wf_group_actions'
        });
        WF_GROUP_REGISTRY.push({
            Caption: '@@Layout',
            GroupID: 'layout',
            Index: 10,
            ParentID: null,
            Icon: 'wf_group_layout'
        });
        // Gruppe, um Elemente in Auswahl auszublenden (z.B. Template)
        WF_GROUP_REGISTRY.push({
            Caption: '',
            GroupID: '-1',
            Index: 0,
            ParentID: null,
            Icon: 'wf_group_none'
        });

        WorkflowRegistryHelper.RefreshIcons();

        const contPath = 'evidanza.MiddleWare.Shared.Workflow.ContainerOperations.';
        // Service-Settings
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.StartWfModuleData', StartWfModuleData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Condition.ConditionSettingsData', ConditionSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.EnumSwitch.EnumSwitchSettingsData', EnumSwitchSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.EnumSwitch.EnumSwitchAdvancedSettingsData',
            EnumSwitchAdvancedSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.RestCall.RestCallSettingsData', RestCallSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.RestCall.RestCallAdvancedSettingsData',
            RestCallAdvancedSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.WFStatus.SetWorkflowStatusData', SetWorkflowStatusData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.WFStatus.GetWorkflowStatusData', GetWorkflowStatusData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ExecuteWorkflow.ExecuteWorkflowData', ExecuteWorkflowData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Start.StartWorkflowSettingsData', StartWorkflowSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ForEach.ForEachLoopSettingsData', ForEachLoopSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.SendMail.SendMailSettingsData', SendMailSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.SendMail.SendMailExtendedSettingsData',
            SendMailExtendedSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ListOperations.GetListItemSettingsData', GetListItemSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ListOperations.GetListCountSettingsData', GetListCountSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineList.GetListItemCalcSettingsData',
            GetListItemCalcSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Redis.AddToRedisSettingsData', AddToRedisSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Redis.GetFromRedisSettingsData', GetFromRedisSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Redis.RemoveFromRedisSettingsData', RemoveFromRedisSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GetCurrentUser.GetCurrentUserSettingsData',
            GetCurrentUserSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GetCurrentDate.GetCurrentDateSettingsData',
            GetCurrentDateSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineValue.DefineValueSettingsData', DefineValueSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineValue.DefineMultiValuesSettingsData',
            DefineMultiValuesSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.EditImage.EditImageSettingsData', EditImageSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineDate.DefineDateSettingsData', DefineDateSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GenerateOutput.GenerateOutputSettingsData',
            GenerateOutputSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.SaveToMediaSource.SaveToMediaSourceSettingsData',
            SaveToMediaSourceSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.MiddleWare.Shared.Workflow.DataOperations.DataSwitch.DataSwitchSettingsData',
            DataSwitchSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineList.DefineListSettingsData', DefineListSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ReadProperty.ReadMultiPropertySettingsData',
            ReadMultiPropertySettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ReadProperty.SetMultiPropertySettingsData',
            SetMultiPropertySettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineObject.DefineObjectsSettingsData', DefineObjectsSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.DefineText.DefineTextsSettingsData', DefineTextsSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GetCurrentUser.GetUserPropertiesSettingsData',
            GetUserPropertiesSettingsData);
        WF_SERVICE_SETTINGS.set(contPath + 'ExecuteDelete.ExecuteDeleteSettingsData', ExecuteDeleteSettingsData);
        WF_SERVICE_SETTINGS.set(contPath + 'ExecuteInsert.ExecuteInsertSettingsData', ExecuteInsertSettingsData);
        WF_SERVICE_SETTINGS.set(contPath + 'ExecuteUpdate.ExecuteUpdateSettingsData', ExecuteUpdateSettingsData);
        WF_SERVICE_SETTINGS.set(contPath + 'ExecuteRead.ExecuteReadSettingsData', ExecuteReadSettingsData);
        WF_SERVICE_SETTINGS.set(contPath + 'ExecuteCount.ExecuteCountSettingsData', ExecuteCountSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.ContainerOperations.ExecuteReplace.ExecuteReplaceSettingsData',
            ExecuteReplaceSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Sleep.SleepSettingsData', SleepSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GetCurrentLanguage.GetCurrentLanguageSettingsData',
            GetCurrentLanguageSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.GetTriggerContext.GetTriggerContextSettingsData',
            GetTriggerContextSettingsData);
        WF_SERVICE_SETTINGS.set('evidanza.App.Shared.Workflow.Modules.Script.ExecuteScriptSettingsData', ExecuteScriptSettingsData);

        // TypeInfos
        const forEachInfo = new WorkflowExitTypeInfo();
        forEachInfo.ID = 'forEach';
        forEachInfo.Color = '#0040FF';
        WF_TYPE_REGISTRY.set(forEachInfo.ID, forEachInfo);
        const mediaInfo = new WorkflowExitTypeInfo();
        mediaInfo.ID = 'media';
        mediaInfo.Color = '#A040FF';
        WF_TYPE_REGISTRY.set(mediaInfo.ID, mediaInfo);
        const errorInfo = new WorkflowExitTypeInfo();
        errorInfo.ID = 'error';
        errorInfo.Color = '#b3050b';
        WF_TYPE_REGISTRY.set(errorInfo.ID, errorInfo);
    }

    public static RefreshIcons() {
        let groups = {};
        if (WF_GROUP_REGISTRY != null) {
            WF_GROUP_REGISTRY.forEach((group) => {
                groups[group.GroupID] = group;
            })
        }
        WF_REGISTRY.forEach((module) => {
            if (module.GroupID && groups[module.GroupID]) {
                module.GroupIcon = groups[module.GroupID].Icon;
            }
        })
    }
}
