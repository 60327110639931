import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Row } from '../../models/basic/row.model';

@Component({
    selector: 'evi-content-column-container',
    templateUrl: './content.column.container.html',
    styleUrls: ['./content.column.container.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ContentColumnContainer implements OnInit {
    //#region Row
    RowValue: Row;

    @Input()
    get Row(): Row {
        return this.RowValue;
    }
    set Row(val: Row) {
        this.RowValue = val;
        this.checkPadding();
    }
    //#endregion
    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
    }
    //#endregion
    //#region ToolTip
    ShowToolTip;
    enter(event: MouseEvent) {
        if (event.target['offsetWidth'] < event.target['scrollWidth']) {
            this.ShowToolTip = true;
        } else {
            this.ShowToolTip = false;
        }
    }
    //#endregion
    //#region FirstContainer
    FirstContainerValue = false;

    @Input()
    get FirstContainer() {
        return this.FirstContainerValue;
    }
    set FirstContainer(val) {
        this.FirstContainerValue = val;
        this.checkPadding();
    }
    //#endregion
    //#region DataTable
    DataTableValue: any;

    @Input()
    get DataTable() {
        return this.DataTableValue;
    }
    set DataTable(val: any) {
        this.DataTableValue = val;
    }
    //#endregion
    @Output() KeyPress = new EventEmitter<any>();
    @Output() CellClicked = new EventEmitter<any>();

    PaddingStyle = {}
    Initialized = false;

    ngOnInit(): void {
        this.Initialized = true;
        this.checkPadding();
    }

    checkPadding() {
        if (this.Initialized) {
            const padStyle = {};
            if (this.FirstContainerValue && this.RowValue) {
                const depth = this.RowValue['Depth'];
                if (typeof depth === 'number' && depth > 0) {
                    if (this.DataTableValue && this.DataTableValue.RTL) {
                        padStyle['0'] = {
                            'padding-right': depth * 25 + 'px'
                        };
                    } else {
                        padStyle['0'] = {
                            'padding-left': depth * 25 + 'px'
                        };
                    }
                }
            }
            this.PaddingStyle = padStyle;
        }
    }

    KeyPressInternal(event) {
        this.KeyPress.emit(event);
    }

    OnCellClick(row, col, event) {
        this.OnContentClick({ Row: row, Column: col, Event: event });
    }

    OnContentClick(event) {
        this.CellClicked.emit(event);
    }
}
