import { Component, ViewChild } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../workflow.dialog';
import { AContainerSettingsData } from '../container.chooser';

@Component({
    selector: 'wf-execute-update-settings',
    templateUrl: './execute.update.settings.html'
})
export class ExecuteUpdateSettings extends ContainerWorkflowDialogContent {
    Data = new ExecuteUpdateSettingsData();
    @ViewChild('chooser') ContainerChooser;
    DBUsages = [[8192, 32]]; // RelationalWrite oder DocumentStoreWrite

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'executeUpdateWFModule';
        reg.Caption = '@@Update';
        reg.GroupID = 'containeroperations';
        reg.Index = 20;
        reg.SettingsControl = ExecuteUpdateSettings;
        reg.SettingsTypeHelper = new ExecuteUpdateSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ExecuteUpdateSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.ContainerTable) {
            const hasValues = this.Data.Values && this.Data.Values.length > 0 && this.Data.Values.some(x => {
                if (x.ColumnName && x.Value) {
                    return true;
                }
                return false;
            });
            if (!hasValues) {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte vergeben Sie mindestens einen Spaltenwert.';
            }
        } else {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie eine Tabelle und vergeben Sie mindestens einen Spaltenwert';
        }
        return retVal;
    }

    getResult(): any {
        if (this.Data) {
            if (this.Data.InteractiveProperties) {
                this.Data.InteractiveProperties = this.Data.InteractiveProperties.filter(x => x.Value);
            }
            if (this.Data.ContainerTable && this.ContainerChooser) {
                this.ContainerChooser.CheckActualFilter();
            }
            if (this.Data.Values) {
                const values = [];
                this.Data.Values.forEach(x => {
                    if (x.ColumnName && x.Value) {
                        values.push(x);
                    }
                });
                this.Data.Values = values;
            }
        }
        return this.Data;
    }
}

export class ExecuteUpdateSettingsData extends AContainerSettingsData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ContainerOperations.ExecuteUpdate.ExecuteUpdateSettingsData';
    }
}

export class ExecuteUpdateSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ExecuteUpdateSettingsData();
    }
}
