import { WorkflowEngine } from '../workflow/workflow.engine';
import { EventActionType } from '../models/enums/eventactiontype.enum';
import { WorkflowStatus } from '../models/workflow/workflow.model';
import { GLOBALEVENTREGISTRY, LAYOUTEVENTREGISTRY } from '../services/dynamic.component.service';
import { WorkflowService } from '../services/workflow.service';
import { VariableHelper } from './variable.helper';

// @dynamic
export class GlobalEventHelper {
    private static ActiveSubscriptions;
    private static BaseLayoutSubscriptions;
    private static ActiveLayoutEventSubscriptions;
    private static BaseLayoutLayoutEventSubscriptions;

    public static InitGlobalEvents(layout) {
        GlobalEventHelper.Unsubscribe(GlobalEventHelper.ActiveSubscriptions);
        GlobalEventHelper.ActiveSubscriptions = {};
        GlobalEventHelper.SubscribeGlobalEvents(GlobalEventHelper.ActiveSubscriptions,
            layout, layout.GlobalEvents, GLOBALEVENTREGISTRY);

        GlobalEventHelper.Unsubscribe(GlobalEventHelper.ActiveLayoutEventSubscriptions);
        GlobalEventHelper.ActiveLayoutEventSubscriptions = {};
        GlobalEventHelper.SubscribeLayoutEvents(GlobalEventHelper.ActiveLayoutEventSubscriptions, layout);
    }

    public static InitBaseLayout(layout) {
        GlobalEventHelper.Unsubscribe(GlobalEventHelper.BaseLayoutSubscriptions);
        GlobalEventHelper.BaseLayoutSubscriptions = {};
        GlobalEventHelper.SubscribeGlobalEvents(GlobalEventHelper.BaseLayoutSubscriptions,
            layout, layout.GlobalEvents, GLOBALEVENTREGISTRY);

        GlobalEventHelper.Unsubscribe(GlobalEventHelper.BaseLayoutLayoutEventSubscriptions);
        GlobalEventHelper.BaseLayoutLayoutEventSubscriptions = {};
        GlobalEventHelper.SubscribeLayoutEvents(GlobalEventHelper.BaseLayoutLayoutEventSubscriptions, layout);
    }

    private static Unsubscribe(subObject) {
        if (subObject) {
            const keys = Object.keys(subObject);
            if (keys && keys.length > 0) {
                keys.forEach((key) => {
                    subObject[key].unsubscribe();
                });
            }
        }
    }

    private static SubscribeGlobalEvents(subObject, layout, eventList, eventRegistry) {
        if (eventList) {
            eventList.forEach((event) => {
                if (event.Handlers && event.Handlers.length > 0) {
                    const baseEvent = eventRegistry.find((value) => value.ID == event.ID);
                    if (baseEvent && !subObject[baseEvent.ID]) {
                        subObject[baseEvent.ID] = baseEvent.Trigger.subscribe((data) => {
                            event.Handlers.forEach((action) => {
                                switch (action.ActionType) {
                                    case EventActionType.Workflow:
                                        const wf = layout.Workflows.find(item => item.ID === action.Action && item.Data);
                                        if (wf) {
                                            const status = new WorkflowStatus(wf.ID, wf.Caption);
                                            WorkflowStatus.CopyContext(WorkflowService.DialogStatusCopy.getValue(), status.Context);
                                            status.WorkflowLayoutService.init(layout, layout);
                                            status.ActualParameter = data;
                                            const engine = new WorkflowEngine(wf.Data, status);
                                            engine.startExecution();
                                            break;
                                        }
                                        break;
                                    case EventActionType.Variable:
                                        const v = VariableHelper.AvailableVariables[layout._Id].find((value) => value.ID == action.Action);
                                        if (v) {
                                            if (event.Write) {
                                                v.Value = data;
                                                VariableHelper.ValueChanged(layout, v);
                                            } else {
                                                // ??
                                            }
                                        }
                                        break;
                                }
                            });
                        });
                    }
                }
            });
        }
    }

    private static SubscribeLayoutEvents(subObject, layout) {
        if (layout.LayoutEvents) {
            const eventList = [];
            layout.LayoutEvents.forEach(x => {
                let actEvent = eventList.find(y => y.ID == x.EventID);
                if (!actEvent) {
                    actEvent = {
                        ID: x.EventID,
                        Handlers: []
                    };
                    eventList.push(actEvent);
                }
                actEvent.Handlers.push(x);
            });
            GlobalEventHelper.SubscribeGlobalEvents(subObject, layout, eventList, LAYOUTEVENTREGISTRY);
        }
    }
}
