import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalVariableService extends RequestBase {
    BasePath = 'api/globalvariable';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    LoadVariables(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadVariables', this.options);
    }

    LoadVariable(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadVariable?id=' + id, this.options);
    }

    SaveVariable(data: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveVariable', data, this.options);
    }

    DeleteVariable(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteVariable?id=' + id, this.options);
    }
}
