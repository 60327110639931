import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { ITaskExecuter } from '../atask.model';

export class SumXLevelFormattingTaskExecuter implements ITaskExecuter {
    static TaskID = 'sumxlevelformattingtask';

    IsStyleAppliedToValues = true;

    Style: CellStyle;
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            this.IsStyleAppliedToValues = settings.IsStyleAppliedToValues;
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
    }

    Execute() {
        if (this.Style && this.Merger) {
            if (this.Result.XAxis.SumNode) {
                this.Result.XAxis.SumNode.StyleID = this.Merger.MergeStyle(this.Result.XAxis.SumNode.StyleID, this.Style);
                if (this.IsStyleAppliedToValues) {
                    MultiResultHelper.ExecuteOnAllNodes(this.Result.YAxis, (yNode) => {
                        this.Result.Measures.Nodes.forEach(mNode => {
                            const cell = MultiResultHelper.GetCellOrNew(this.Result, this.Result.XAxis.SumNode, yNode, mNode);
                            cell.StyleID = this.Merger.MergeStyle(cell.StyleID, this.Style);
                        });
                    }, true);
                }
            }
        }
    }
}
