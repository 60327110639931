import { deserialize, serialize } from 'class-transformer';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageType } from '../models/enums/messagetype.enum';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { CollaborationService } from '../services/collaboration.service';
import { NavigationService } from '../services/navigation.service';
import { UsersService } from '../services/users.service';
import { WebsocketService, WebsocketSubscription } from '../services/websocket.service';
import { InjectorHelper, TranslateHelper } from './injector.helper';
import { NotificationHelper } from './notification.helper';

export const COLLABORATIONCHANNEL = 'CollaborationChannel';

export class CollaborationSettings {
    ShowNotifications = true;
    ShowSaveMessage = true;
}

export class CollaborationHelper {
    private static Service: CollaborationService;
    private static ChatMessages = {};
    private static ActiveUsers = {};
    private static CheckoutState = {};
    private static ChatSubscriptions = {};
    private static ActiveSubscriptionChannel: string;
    private static CachedActiveChannel: string;

    public static ActiveChannel = new BehaviorSubject<string>(null);
    public static ActualActiveUsers = new BehaviorSubject<any[]>(null);
    public static ActualChatMessages = new BehaviorSubject<any[]>(null);
    public static ActualCheckOutUser = new BehaviorSubject<any>(null);
    public static NewMessage = new Subject<string>();
    public static ForcedCheckout = new Subject<any>();
    public static Settings = new BehaviorSubject<CollaborationSettings>(new CollaborationSettings());

    public static Init() {
        WebsocketService.Active.subscribe((val) => {
            if (val === true && !CollaborationHelper.Service) {
                CollaborationHelper.Service = InjectorHelper.InjectorInstance.get<CollaborationService>(CollaborationService);
                WebsocketService.MessageReceived.subscribe((response) => {
                    const parsed = JSON.parse(response);
                    if (parsed.Level === NotificationLevel.System) {
                        if (parsed.Type === 'evidanza.App.Shared.Collaboration.SubscriptionInfo') {
                            const content = JSON.parse(parsed.Content);
                            if (content.Channel) {
                                CollaborationHelper.ActiveUsers[content.Channel] = content.Users;
                                if (content.Channel === CollaborationHelper.ActiveChannel.getValue()) {
                                    const user = UsersService.ActiveUser.getValue();
                                    if (user) {
                                        CollaborationHelper.ActualActiveUsers.next(content.Users.filter(x => x.ID !== user.SID));
                                    } else {
                                        CollaborationHelper.ActualActiveUsers.next([...content.Users]);
                                    }
                                }
                            }
                        } else if (parsed.Type === 'evidanza.App.Shared.Collaboration.CheckOutInfo') {
                            const content = JSON.parse(parsed.Content);
                            if (content.Channel) {
                                const oldUser = CollaborationHelper.CheckoutState[content.Channel];
                                CollaborationHelper.CheckoutState[content.Channel] = content.User;
                                if (content.Channel === CollaborationHelper.ActiveChannel.getValue()) {
                                    CollaborationHelper.ActualCheckOutUser.next(content.User);
                                    if (content.ForceCheckOut && content.User && oldUser) {
                                        const user = UsersService.ActiveUser.getValue();
                                        if (user && user.SID === oldUser.ID && user.SID !== content.User.ID) {
                                            CollaborationHelper.ForcedCheckout.next(content.User);
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
                WebsocketService.Subscribe([{ Channel: COLLABORATIONCHANNEL, Level: [0] }]);
                UsersService.BeforeLogout.subscribe(() => {
                    CollaborationHelper.ChangeActiveSubscription(false);
                    CollaborationHelper.ActiveSubscriptionChannel = null;
                    CollaborationHelper.SetActiveChannel(null);
                    CollaborationHelper.ChatMessages = {};
                    CollaborationHelper.UnsubscribeAll();
                });
                NavigationService.BeforeUnload.subscribe((closePrevented) => {
                    if (closePrevented) {
                        CollaborationHelper.ChangeActiveSubscription(false);
                    } else if (CollaborationHelper.Service && CollaborationHelper.ActiveSubscriptionChannel) {
                        CollaborationHelper.Service.TrySendUnsubscribe(CollaborationHelper.ActiveSubscriptionChannel);
                    }
                });
                NavigationService.CancelUnload.subscribe(() => {
                    CollaborationHelper.ChangeActiveSubscription(true);
                });
                NavigationService.OnUnload.subscribe(() => CollaborationHelper.UnsubscribeAll());

                const settingsJSON = localStorage.getItem('CollaborationSettings');
                if (settingsJSON) {
                    CollaborationHelper.Settings.next(deserialize(CollaborationSettings, settingsJSON));
                }
                if (CollaborationHelper.CachedActiveChannel) {
                    CollaborationHelper.SetActiveChannel(CollaborationHelper.CachedActiveChannel);
                    CollaborationHelper.CachedActiveChannel = null;
                }
            }
        });
    }

    private static ChangeActiveSubscription(subscribe: boolean) {
        if (CollaborationHelper.Service && CollaborationHelper.ActiveSubscriptionChannel) {
            CollaborationHelper.Service.SendSubscription({
                Subscribe: subscribe,
                Channel: CollaborationHelper.ActiveSubscriptionChannel
            }).subscribe();
        }
    }

    private static UnsubscribeAll() {
        const keys = Object.keys(CollaborationHelper.ChatSubscriptions);
        keys.forEach(x => {
            const sub = CollaborationHelper.ChatSubscriptions[x];
            if (sub) {
                sub.Unsubscribe();
            }
        });
        CollaborationHelper.ChatSubscriptions = {};
    }

    public static SendMessage(message: string) {
        if (message) {
            const channel = CollaborationHelper.ActiveChannel.getValue();
            if (channel) {
                const user = UsersService.ActiveUser.getValue();
                if (user) {
                    WebsocketSubscription.SendNotification(CollaborationHelper.CheckChannel(channel), 'ChatMessage', {
                        MessageTime: new Date(),
                        Message: message,
                        Sender: user.DisplayName,
                        SenderID: user.SID,
                        Channel: channel
                    });
                }
            }
        }
    }

    public static SetActiveChannel(channel: string) {
        if (CollaborationHelper.Service) {
            if (CollaborationHelper.ActiveChannel.getValue() !== channel) {
                if (channel) {
                    let activeUsers = CollaborationHelper.ActiveUsers[channel];
                    const checkOutUser = CollaborationHelper.CheckoutState[channel];
                    if (activeUsers && checkOutUser) {
                        CollaborationHelper.ActiveChannel.next(channel);
                        const messages = [];
                        const chatMessages = CollaborationHelper.ChatMessages[channel];
                        if (chatMessages) {
                            messages.push(...chatMessages);
                        }
                        const user = UsersService.ActiveUser.getValue();
                        if (user) {
                            activeUsers = activeUsers.filter(x => x.ID !== user.SID);
                        }
                        CollaborationHelper.ActualChatMessages.next(messages);
                        CollaborationHelper.ActualActiveUsers.next([...activeUsers]);
                        CollaborationHelper.ActualCheckOutUser.next(checkOutUser);
                    } else {
                        CollaborationHelper.Service.GetChannelInfo(channel).subscribe(ci => {
                            if (ci) {
                                CollaborationHelper.ActiveUsers[channel] = ci.Users;
                                CollaborationHelper.CheckoutState[channel] = ci.CheckOutUser;
                                CollaborationHelper.ActiveChannel.next(channel);
                                const messages = [];
                                const chatMessages = CollaborationHelper.ChatMessages[channel];
                                if (chatMessages) {
                                    messages.push(...chatMessages);
                                }
                                let actualActive = [...ci.Users];
                                const user = UsersService.ActiveUser.getValue();
                                if (user) {
                                    actualActive = ci.Users.filter(x => x.ID !== user.SID);
                                }
                                CollaborationHelper.ActualChatMessages.next(messages);
                                CollaborationHelper.ActualActiveUsers.next(actualActive);
                                CollaborationHelper.ActualCheckOutUser.next(ci.CheckOutUser);
                            } else {
                                delete CollaborationHelper.ActiveUsers[channel];
                                delete CollaborationHelper.CheckoutState[channel];
                                CollaborationHelper.ActiveChannel.next(null);
                                CollaborationHelper.ActualChatMessages.next(null);
                                CollaborationHelper.ActualActiveUsers.next(null);
                                CollaborationHelper.ActualCheckOutUser.next(null);
                            }
                        });
                    }
                } else {
                    CollaborationHelper.ActiveChannel.next(null);
                    CollaborationHelper.ActualChatMessages.next(null);
                    CollaborationHelper.ActualActiveUsers.next(null);
                    CollaborationHelper.ActualCheckOutUser.next(null);
                }
            }
        } else {
            CollaborationHelper.CachedActiveChannel = channel;
        }
    }

    private static CheckChannel(channel: string): string {
        if (channel) {
            if (channel[0] === '/') {
                if (channel.length === 1) {
                    channel = '$$emptychannel$$';
                } else {
                    channel = channel.substring(1);
                }
            }
            return channel.replace(/\//g, '_');
        }
        return channel;
    }

    public static Subscribe(channel: string, subscribe: boolean) {
        if (CollaborationHelper.Service && channel) {
            if (subscribe) {
                CollaborationHelper.ChatSubscriptions[channel] = new WebsocketSubscription(CollaborationHelper.CheckChannel(channel), 'ChatMessage', (m) => {
                    const content = JSON.parse(m);
                    if (content.Channel) {
                        let messageList = CollaborationHelper.ChatMessages[content.Channel];
                        if (!messageList) {
                            messageList = [];
                            CollaborationHelper.ChatMessages[content.Channel] = messageList;
                        }
                        const actUser = UsersService.ActiveUser.getValue();
                        content.OwnMessage = actUser && actUser.SID === content.SenderID;
                        const date = new Date(content.MessageTime);
                        content.MessageTimeText = date.toLocaleDateString(undefined, {
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        });
                        messageList.push(content);
                        if (content.Channel === CollaborationHelper.ActiveChannel.getValue()) {
                            CollaborationHelper.ActualChatMessages.next([...messageList]);
                        }
                        CollaborationHelper.NewMessage.next(content.Channel);
                        if (!content.OwnMessage) {
                            const settings = CollaborationHelper.Settings.getValue();
                            if (!settings || settings.ShowNotifications) {
                                TranslateHelper.TranslatorInstance.get('@@{0} schreibt', { 0: content.Sender }).subscribe(x => {
                                    NotificationHelper.Send(MessageType.Information, x, content.Message, false);
                                });
                            }
                        }
                    }
                });

                CollaborationHelper.ActiveSubscriptionChannel = channel;
            } else {
                const sub = CollaborationHelper.ChatSubscriptions[channel];
                if (sub) {
                    sub.Unsubscribe();
                }
                CollaborationHelper.ActiveSubscriptionChannel = null;
            }
            CollaborationHelper.Service.SendSubscription({
                Subscribe: subscribe,
                Channel: channel
            }).subscribe();
        }
    }

    public static CheckOut(checkOut: boolean, forceCheckOut: boolean) {
        if (CollaborationHelper.Service) {
            const channel = CollaborationHelper.ActiveChannel.getValue();
            if (channel) {
                CollaborationHelper.Service.SendCheckOut({
                    DoCheckOut: checkOut,
                    ForceCheckOut: forceCheckOut,
                    Channel: channel
                }).subscribe();
            }
        }
    }

    public static UpdateSettings(prop: string, val) {
        let settings = CollaborationHelper.Settings.getValue();
        if (!settings) {
            settings = new CollaborationSettings();
        }
        settings[prop] = val;
        CollaborationHelper.Settings.next(settings);
        localStorage.setItem('CollaborationSettings', serialize(settings));
    }

    public static GetActiveUserNames(channel) {
        const retVal = [];
        const users = CollaborationHelper.ActiveUsers[channel];
        if (users) {
            users.forEach(x => {
                retVal.push(x.Name);
            });
        }
        return retVal;
    }
}
