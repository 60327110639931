import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { DataDescriptionRelational } from '../../../models/datadescription/relational/datadescriptionrelational.model';
import { ObjectcreatorDataManager } from '../../objectcreator/helper/objectcreator.datamanager';

@Component({
    selector: 'datadescription-edit-dialog',
    templateUrl: 'datadescription.edit.dialog.html',
    styleUrls: ['./datadescription.edit.dialog.css']
})
export class DatadescriptionEditDialog {

    Data: DatadescriptionData = new DatadescriptionData();
    CloseOnCustomButton: boolean = true;
    CustomButtons = [{
        Caption: '@@Speichern'
    }];
    TemplatesLoaded = false;

    Initialize(args) {
        if (args) {
            if (args.Data) {
                const json = serialize(args.Data);
                this.Data = deserialize(DatadescriptionData, json);
                if (args.Data.IsRelational !== undefined) {
                    this.Data.IsRelationalValue = args.Data.IsRelational;
                }
                if (args.Data.Datadescription) {
                    const ddjson = serialize(args.Data.Datadescription);
                    if (this.Data.IsRelationalValue) {
                        this.Data.Datadescription = deserialize(DataDescriptionRelational, ddjson);
                    } else {
                        this.Data.Datadescription = deserialize(DataDescription, ddjson);
                    }
                }
            }
        }
    }

    GetDialogResult() {
        ObjectcreatorDataManager.DialogClosing.next();
        return this.Data;
    }
}
