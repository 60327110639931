import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MailSettingsService } from '../../../services/mailsettings.service';

@Component({
    selector: 'identity-mfa-mail-dialog',
    templateUrl: './identity.mfa.mail.dialog.html',
    styles: ['.tagList { width: 200px; border: 1px solid lightgray; padding: 0px 5px; }']
})
export class IdentityMFAMailDialog implements OnInit {
    MailProvider;
    MailProviders = [];
    Subject;
    MailText;
    TagList = [];
    Users = null;
    @ViewChild('mailText') mailTextInput: ElementRef;

    static ShowDialog(args) {
        BaseDialog.ShowDialog({
            ContentType: IdentityMFAMailDialog,
            Buttons: DialogButton.OkCancel,
            Title: '@@MFAMailDialogTitle',
            FullScreen: true,
            InitArgs: args
        });
    }

    constructor(private mailService: MailSettingsService) {
        this.TagList = [
            { Caption: '@@Link', Tag: '$$link$$' },
            { Caption: '@@Vorname', Tag: '$$firstname$$' },
            { Caption: '@@Nachname', Tag: '$$lastname$$' }
        ];
    }

    ngOnInit(): void {
        this.mailService.GetMailSettings().subscribe(x => {
            if (x && x.length > 0) {
                this.MailProviders = x;
                this.MailProvider = x[0].SID;
            }
        });
    }

    Initialize(args) {
        if (args && args.Users) {
            this.Users = args.Users;
        }
    }

    OnDialogButtonClickAction(button: DialogButton) {
        if (button === DialogButton.Ok) {
            if (this.MailProvider) {
                if (this.MailText) {
                    if (this.Subject) {
                        if (this.MailText.indexOf('$$link$$') < 0) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@MFAMissingLink'),
                                new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            return false;
                        } else {
                            this.mailService.SendMail({
                                MailSettings: this.MailProvider,
                                Type: 0,
                                Content: JSON.stringify({
                                    Subject: this.Subject,
                                    Body: this.MailText,
                                    Users: this.Users
                                })
                            }).subscribe();
                        }
                    } else {
                        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@MFAEmptySubject'),
                            new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                        return false;
                    }
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@MFAEmptyText'),
                        new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    return false;
                }
            } else {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@MFAChooseMailProvider'),
                    new TranslateFormatText('@@Information'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                return false;
            }
        }
        return true;
    }

    onDoubleClick(tag) {
        if (this.mailTextInput && this.mailTextInput.nativeElement) {
            let pos = this.mailTextInput.nativeElement.selectionStart || 0;
            const endPos = this.mailTextInput.nativeElement.selectionEnd || 0;
            const actText = this.mailTextInput.nativeElement.value;
            const front = actText.substring(0, pos);
            let back;
            if (endPos > pos) {
                back = actText.substring(endPos, actText.length);
            } else {
                back = actText.substring(pos, actText.length);
            }
            this.MailText = front + tag + back;
            pos = pos + tag.length;
            this.mailTextInput.nativeElement.selectionStart = pos;
            this.mailTextInput.nativeElement.selectionEnd = pos;
            this.mailTextInput.nativeElement.focus();
        }
    }
}
