import { ValueFormulaNodeCalculator } from '../models/calculation/formula.node.calculator';
import { TranslatedString } from '../models/translatedstring.model';
import { ValueAndType } from '../models/basic/formulaEditor.model';

export abstract class ACaptionGetter {
    static GetCaptionGetter(layoutElem, fieldList): ACaptionGetter {
        if (layoutElem) {
            if (layoutElem.DisplayFormula) {
                return new DisplayFormulaCaptionGetter(layoutElem.DisplayFormula, fieldList);
            } else if (layoutElem.DisplayMemberPath) {
                let fieldType = '';
                if (fieldList) {
                    fieldList.some(f => {
                        if (f.Name === layoutElem.DisplayMemberPath) {
                            fieldType = f.Type;
                            return true;
                        }
                        return false;
                    });
                }
                switch (fieldType) {
                    case 'a0c232a9-4ab7-444c-a7e2-d23899a5673b':
                        return new TranslatedStringCaptionGetter(layoutElem.DisplayMemberPath);
                    default:
                        return new StandardPropertyGetter(layoutElem.DisplayMemberPath);
                }
            }
        }
        return new DefaultCaptionGetter();
    }

    abstract GetCaption(element): string;
}

export class DefaultCaptionGetter extends ACaptionGetter {
    GetCaption(element: any): string {
        let retVal = '';
        if (typeof element !== 'undefined' && element !== null) {
            retVal += element;
        }
        return retVal;
    }
}

export class StandardPropertyGetter extends ACaptionGetter {
    constructor(private displayMemberPath: string) {
        super();
    }
    GetCaption(element: any): string {
        let retVal = '';
        if (typeof element !== 'undefined' && element !== null && element[this.displayMemberPath]) {
            retVal += element[this.displayMemberPath];
        }
        return retVal;
    }
}

export class DisplayFormulaCaptionGetter extends ACaptionGetter {
    constructor(private formula, private fieldList) {
        super();
        if (this.fieldList) {
            this.fieldList.forEach(f => {
                f.FormulaType = ValueAndType.GetValueTypeFromType(f.DataTyp);
            });
        }
    }

    GetCaption(element: any): string {
        let retVal = '';
        if (typeof element !== 'undefined' && element !== null) {
            const values = new Map();
            if (this.fieldList) {
                this.fieldList.forEach(f => {
                    values.set(f.ID, {
                        Type: f.FormulaType,
                        Value: element[f.Name]
                    });
                });
            }
            const calc = new ValueFormulaNodeCalculator(false, [], values);
            const result = calc.Calc(this.formula);
            if (result && result.Value) {
                retVal += result.Value;
            }
        }
        return retVal;
    }
}

export class TranslatedStringCaptionGetter extends ACaptionGetter {
    constructor(private displayMemberPath: string) {
        super();
    }
    GetCaption(element: any): string {
        let retVal = '';
        if (typeof element !== 'undefined' && element !== null) {
            retVal += TranslatedString.GetTranslation(element[this.displayMemberPath]);
        }
        return retVal;
    }
}
