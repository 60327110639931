import { BackgroundColorAction } from "../components/actions/style/backgroundcolor/backgroundcolor.action";
import { BoldAction } from "../components/actions/style/bold/bold.action";
import { BorderAction } from "../components/actions/style/border/border.action";
import { EmptyAction } from "../components/actions/style/empty/empty.action";
import { FontColorAction } from "../components/actions/style/fontcolor/fontcolor.action";
import { FontFamilyAction } from "../components/actions/style/fontfamily/fontfamily.action";
import { FontSizeAction } from "../components/actions/style/fontsize/fontsize.action";
import { HorizontalAlignmentAction } from "../components/actions/style/horizontalalignment/horizontalalignment.action";
import { ItalicAction } from "../components/actions/style/italic/italic.action";
import { UnderlineAction } from "../components/actions/style/underline/underline.action";
import { VerticalAlignmentAction } from "../components/actions/style/verticalalignment/verticalalignment.action";

export const APP_ACTIONS = [
    EmptyAction,
    BoldAction,
    UnderlineAction,
    ItalicAction,
    FontFamilyAction,
    FontSizeAction,
    HorizontalAlignmentAction,
    VerticalAlignmentAction,
    FontColorAction,
    BorderAction,
    BackgroundColorAction
];

