import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Font } from '../../../../models/style/font.model';


@Component({
  selector: 'italic-action',
  templateUrl: './italic.action.html',
  styleUrls: ['./italic.action.css'],
})
export class ItalicAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'italic';
    
     //#region Italic
     @Input()
     get Italic() {
         if (this.SelectedItemValue && this.SelectedItemValue.Font) {
 
             return this.SelectedItemValue.Font.Italic;
         }
         return false;
     }
     set Italic(val) {
         if (this.SelectedItemValue) {
             if (!this.SelectedItemValue.Font) {
                 this.SelectedItemValue.Font = new Font();
             }
             this.SelectedItemValue.Font.Italic = val;
         }
         this.ItalicChange.emit(val);
     }
 
     @Output() ItalicChange = new EventEmitter<any>();
     //#endregion

    ControlInitialized() {

    }
    ExecuteAction() {
        this.Italic = !this.Italic;
    }

    Visibility() {
        return true;
    }
}
