import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { MediaService } from '../../../services/media.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-savetomediasource-settings',
    templateUrl: './savetomediasource.settings.html'
})
export class SaveToMediaSourceSettings extends FormulaWorkflowDialogContent {
    Data = new SaveToMediaSourceSettingsData();
    MediaSources = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'savetomediasourceWFModule';
        reg.Caption = '@@In Medienquelle speichern';
        reg.GroupID = 'wfActions';
        reg.Index = 70;
        reg.SettingsControl = SaveToMediaSourceSettings;
        reg.SettingsTypeHelper = new SaveToMediaSourceSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: MediaService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetUsable().subscribe(result => {
            if (result) {
                this.MediaSources = result;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = JSON.stringify(data);
            this.Data = plainToClass(SaveToMediaSourceSettingsData, JSON.parse(json));
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class SaveToMediaSourceSettingsData extends AServiceWorkflowData {
    MediaSource: string;
    FileName: string;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.SaveToMediaSource.SaveToMediaSourceSettingsData';
    }
}

export class SaveToMediaSourceSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const wei = new WorkflowExitInfo();
        wei.Type = 'media';
        return [wei];
    }
    getEmptySettingsInstance() {
        return new SaveToMediaSourceSettingsData();
    }
}
