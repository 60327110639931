import { Component, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { AdvancedFilterComponent } from '../../controls/advancedfilter/advancedfilter.component';

@Component({
    selector: 'advancedfilterdialog',
    templateUrl: './advancedfilter.dialog.html',
    styleUrls: ['advancedfilter.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedFilterDialog implements AfterViewInit {

    Columns;
    Filter;
    SelectedItem;
    SystemVariables;

    @ViewChild('advanced') filterComponent: AdvancedFilterComponent;

    ngAfterViewInit(): void {
        if (this.Filter && this.filterComponent) {
            this.filterComponent.SetFilter(this.Filter);
        }
    }

    Initialize(args) {
        if (args) {
            this.Columns = args.Columns;
            this.Filter = args.Filter;
            this.SelectedItem = args.SelectedItem;
            this.SystemVariables = args.SystemVariables;
        }
    }

    GetDialogResult() {
        const retVal = {
            Filter: null
        };
        if (this.filterComponent) {
            retVal.Filter = this.filterComponent.GetActualFilter();
        }
        return retVal;
    }
}
