import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ThemeHelper } from '../../../helpers/theme.helpers';
import { PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'properties-menu-tab',
    templateUrl: './properties.menu.tab.html',
    styles: ['.objectHeader { padding-left: 24px; }'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesMenuTab implements OnInit, OnDestroy {

    Items = [];
    DynamicItems = [];
    Subscriptions = [];
    SelectedItem;
    FirstTime = true;

    static CheckVisible(): boolean {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected) {
            return true;
        }
        const sel = LayoutService.SelectedItems.getValue();
        if (sel) {
            return true;
        }
        return false;
    }
    constructor(public cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.RefreshItems();
        this.Subscriptions.push(LayoutService.SelectedItem.subscribe(x => {
            this.SelectedItem = x;
            this.updateItems();
            this.cdRef.detectChanges();
        }));
        this.Subscriptions.push(LayoutService.PropertyPanelsChanged.subscribe(() => {
            this.RefreshItems();
            this.updateItems();
            this.cdRef.detectChanges();
        }));
    }
    RefreshItems() {
        const propItems = [];
        const dynItems = [];
        PROPERTYGROUPS.forEach(x => {
            const item: any = {
                SID: x.SID ? x.SID : UUID.UUID(),
                ID: x.ID,
                Caption: x.Caption,
                Visible: false,
                PropertyGroup: x,
                Index: x.Index,
                Badge: x.Badge,
                BadgeSub: x.BadgeSub,
                BadgeColor: x.BadgeColor
            };
            if (x.Content) {
                item.Content = new ComponentPortal(x.Content);
                item.Visible = true;
            }
            if (typeof x.CheckOnChange === 'function') {
                item.CheckOnChange = x.CheckOnChange;
                dynItems.push(item);
            }
            propItems.push(item);
        });
        this.DynamicItems = dynItems;
        this.Items = propItems;
    }

    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach((sub) => {
                sub.unsubscribe()
            });
        }
    }

    updateItems() {
        let expanded;
        this.DynamicItems.forEach(x => {
            x.CheckOnChange(x);
            if (x.Visible && x.Expanded && !this.FirstTime) {
                x.Expanded = false;
                expanded = x;
            }
        });
        this.Items = [...this.Items];
        if (expanded) {
            expanded.Expanded = !this.Items.some(x => x.Visible && x.Expanded);
        }
        this.FirstTime = false;
    }
}
@Directive()
export abstract class APropertyTab implements OnInit, OnDestroy {

    Subscriptions = [];
    //#region Internal
    InternalValue = true;
    @Input()
    get Internal() {
        return this.InternalValue;
    }
    set Internal(val) {

        this.InternalValue = val;
        this.InternalChange.emit(this.InternalValue);
    }
    @Output() InternalChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.cdRef.detectChanges();
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItems
    SelectedItemsValue;
    @Input()
    get SelectedItems() {
        return this.SelectedItemsValue;
    }
    set SelectedItems(val) {
        this.SelectedItemsValue = val;
        this.SelectedItemsChange.emit(this.SelectedItemsValue);
        this.cdRef.detectChanges();
    }

    @Output() SelectedItemsChange = new EventEmitter<any>();
    //#endregion
    ActiveTheme = ThemeHelper.AcitveTheme;
    @Output() StyleChanged = new EventEmitter<any>();

    SelectedItemSubscription;

    constructor(public cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.Internal) {
            if (this.SelectedItemSubscription) {
                this.SelectedItemSubscription.unsubscribe();
            }
            this.SelectedItemSubscription = LayoutService.SelectedItem.subscribe((item) => {
                if (item) {
                    this.SelectedItem = item;
                    this.onItemSet();
                }
            });
            this.Subscriptions.push(this.SelectedItemSubscription);
            this.Subscriptions.push(LayoutService.SelectedItems.subscribe((items) => {
                this.SelectedItems = items;
                this.onItemSet();
            }));
            this.Subscriptions.push(LayoutService.SelectionChanged.subscribe((value) => {
                this.cdRef.detectChanges();
            }));
            this.Subscriptions.push(LayoutService.LayoutPropertyChangedEvent.subscribe((obj) => {
                if (this.SelectedItemValue) {
                    const props = obj[this.SelectedItemValue.ID];
                    if (Array.isArray(props) && props.length > 0) {
                        this.onLayoutPropsChanged(props);
                    }
                }
                this.cdRef.detectChanges();
            }));
        }
    }

    ngOnDestroy() {
        this.Subscriptions.forEach(x => { x.unsubscribe(); });
    }

    onItemSet() {
    }

    onLayoutPropsChanged(props) {
    }

    onStyleChanged(PropertyName) {
        if (this.SelectedItemValue && this.SelectedItemValue.ValuesChanged) {
            this.SelectedItemValue.ValuesChanged.next(PropertyName);
        }
        this.StyleChanged.emit();
        this.cdRef.detectChanges();
    }
}
