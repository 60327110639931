import { Component } from '@angular/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { MappingStructure } from '../../../models/rest/mapping.structure';
import { TranslatedString } from '../../../models/translatedstring.model';
import { RestService } from '../../../services/rest.service';
import {
    RestObjectPropertyDialog
} from '../../rest/rest.overview/end.point.settings/rest.objects/rest.object.property.dialog/rest.object.property.dialog';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { ObjectForDataSource } from './models/object.for.datasource';

@Component({
    selector: 'containerrest-detail',
    templateUrl: 'containerrest.detail.html',
    styleUrls: ['containerrest.detail.css']
})
export class ContainerRestDetail extends ADataModelDetail {
    public dataSources: any[];
    public objectForDataSourceItems = [];
    public selectedObjectForDataSourceItem: ObjectForDataSource;
    public templates: any[];
    public restObjects: any[];
    public Endpoints;

    public Loading = false;

    public SelectedDataSource;
    public SelectedRestObject;
    public ObjectID;
    public ContentType: string = '';

    public AllTypes: any[] = [];

    constructor(private _restService: RestService) {
        super();
    }

    ngOnInit() {
        this.objectForDataSourceItems = EnumHelper.GetDropdownValues(ObjectForDataSource);
        super.ngOnInit();
        this._restService.getDynamicTypes().subscribe((data) => {
            if (data) {
                this.AllTypes = data;
            }
        });
    }

    LoadRest() {
        if (this.SelectedDataSource) {
            this.Loading = true;
            this.templates = [];
            this.restObjects = [];
            this.Endpoints = [];
            this._restService.loadTemplates(this.SelectedDataSource.RestBasis).subscribe((templates) => {
                this.templates = templates;
                this._restService.loadRestObjects(this.SelectedDataSource.RestBasis).subscribe((objects) => {
                    this.restObjects = objects;
                    this._restService.loadEndPoints(this.SelectedDataSource.RestBasis).subscribe((result) => {
                        this.Endpoints = result;
                        this.Loading = false;
                    });
                });
            });
        }
    }
     
    setSelectedItem(item: any): void {
        super.setSelectedItem(item);
        if (item) {
            this.ObjectID = item.RestObjectID;
            this._restService.getDataSources().subscribe(result => {
                this.dataSources = result;
                this.DataSourceChanged();
                this.RestObjectChanged();
            });
        }
    }

    DataSourceChanged() {
        this.SelectedDataSource = null;
        if (this.SelectedItem && this.SelectedItem.DataSourceId && this.SelectedItem.DataSourceId !== '00000000-0000-0000-0000-000000000000') {
            this.SelectedDataSource = this.dataSources.find((ds) => ds.Value === this.SelectedItem.DataSourceId);
            this.LoadRest();
        }
    }
    RestObjectChanged() {
        this.SelectedRestObject = null;
        if (this.SelectedItem && this.SelectedItem.RestObjectID && this.SelectedItem.RestObjectID !== '00000000-0000-0000-0000-000000000000') {
            this._restService.loadRestObject(this.SelectedItem.RestObjectID).subscribe((result) => {
                this.SelectedRestObject = result;
                this.SelectedItem.Fields = [];
                result.Fields.forEach(f => {
                    delete f.__id;
                    if (f.Caption) {
                        delete f.Caption.__id
                    }
                    this.SelectedItem.Fields.push(f);
                });
                if (this.SelectedItem.RestObjectID !== this.ObjectID) {
                    this.SelectedItem.Mapping = new MappingStructure();
                    this.ObjectID = this.SelectedItem.RestObjectID;
                }
            });
        }
    }

    public addField(): void {
        const selected = this.SelectedItem;
        if (selected) {
            this.showDialog(null, (r) => {
                //selected.Fields.push(r);
                this.OnItemChanged();
                if (!this.SelectedItem.CustomFilterFields) {
                    this.SelectedItem.CustomFilterFields = [];
                }
                this.SelectedItem.CustomFilterFields.push(r);
                this.refreshFields();
            });
        }
    }
    GetCaption(caption) {
        return TranslatedString.GetTranslation(caption);
    }
    GetType(type) {
        var t = this.AllTypes.find(x => x.Value == type); 
        return t.Caption;
    }
    GetIcon(value) {
        return value ? 'done': '';
    }
    SearchValue;
    openChanged() {
        this.SearchValue = null;
    }
    showDialog(field, handler) {
        BaseDialog.ShowDialog({
            ContentType: RestObjectPropertyDialog,
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Feld definieren',
            InitArgs: {
                AllTypes: this.AllTypes,
                Field: field
            }
        });
    }

    public editField(row): void {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.CustomFilterFields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(field, (r) => {
                    //this.fillRow(row, r);
                    this.SelectedItem.CustomFilterFields.splice(this.SelectedItem.CustomFilterFields.indexOf(field), 1, r);
                    this.refreshFields();
                    //selected.Fields.splice(fieldIndex, 1, r);
                    this.OnItemChanged();
                });
            }
        }
    }
    refreshFields() {
        let array = [...this.SelectedItem.CustomFilterFields];
        this.SelectedItem.CustomFilterFields = array;
    }
    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.CustomFilterFields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            //selected.Fields.splice(fieldIndex, 1);
                            this.SelectedItem.CustomFilterFields.splice(fieldIndex, 1);
                            this.refreshFields();
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }
}