import { Component, Input } from '@angular/core';
import { MultiCacheService } from '../../../../../cache/multi/cache.service';
import { DataDescription } from '../../../../../models/datadescription/multi/datadescription.model';
import { MeasureInfo } from '../../../../../models/datadescription/multi/measureinfo.model';
import { OcDragItemType } from '../../../../../models/enums/oc.DragItemType';
import { Select } from '../../../../../models/selects/select.model';
import { DatadescriptionControl } from '../../../../dialogs/datadescription/datadescription.control';

@Component({
    selector: 'elementReferenceControl',
    templateUrl: './elementreference.control.html',
    styleUrls: ['./elementreference.control.css']
})
export class ElementReferenceControl {

    SelectedMeasureValue: MeasureInfo;
    @Input()
    get SelectedMeasure() {
        return this.SelectedMeasureValue;
    }
    set SelectedMeasure(value) {
        this.SelectedMeasureValue = value;
        if (value) {
            if (!this.SelectedMeasureValue.Details.ReferencingSelects) {
                this.SelectedMeasureValue.Details.ReferencingSelects = [];
            }
            this.ElementRefs = this.SelectedMeasureValue.Details.ReferencingSelects;
        }
    }

    DataDescriptionValue: DataDescription;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
    }

    elementRefsValue;
    @Input()
    get ElementRefs() {
        return this.elementRefsValue;
    }
    set ElementRefs(value) {
        if (Array.isArray(value)) {
            this.elementRefsValue = value;
        } else {
            this.elementRefsValue = [];
        }
    }

    Subscriptions = [];
    FilterItems;
    DraggedHierarchy;

    constructor() {
        this.Subscriptions.push(DatadescriptionControl.ObjectCreatorMPDragStart.subscribe(x => {
            this.DraggedHierarchy = null;
            if (x && x.DragType === OcDragItemType.Level && x.DragContent) {
                MultiCacheService.GetHierarchy(x.DragContent.DraggedID, this.DataDescription.DataModelID).then(y => {
                    this.DraggedHierarchy = y;
                });
            }
        }));
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

    delete(item) {
    }

    deleteAll() {
    }

    async itemDrop(event) {
        if (this.DraggedHierarchy) {
            const select = new Select();
            select.Hierarchy = this.DraggedHierarchy.ID;
        }
    }

    onContextMenu(event: MouseEvent, dditem: any) {
        event.preventDefault();
        event['OcDragItem'] = dditem;
        event['DropArea'] = this;
    }
}
