export class DateHelper {
    /**
     * Generates a date string of format yyyy-mm-dd hh:mm:ss
     * @param date
     */
    static GetSerializeString(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        return date.getFullYear() + '-' +
            DateHelper.GetNumericString(date.getMonth() + 1) + '-' +
            DateHelper.GetNumericString(date.getDate()) + ' ' +
            DateHelper.GetNumericString(date.getHours()) + ':' +
            DateHelper.GetNumericString(date.getMinutes()) + ':' +
            DateHelper.GetNumericString(date.getSeconds());
    }

    private static GetNumericString(num: number) {
        if (num < 10) {
            return '0' + num;
        }
        return '' + num;
    }
}
