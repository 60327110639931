import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'dynamodb-panel',
    templateUrl: './dynamodb.panel.html',
    styleUrls: ['./dynamodb.panel.css']
})
export class DynamoDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = DynamoDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: DynamoDBPanel,
            DBInfoType: DynamoDBInfo
        };
    }
}

export class DynamoDBInfo extends ADBInfo {
    Server: string = null;
    Port = 0;
    Database: string = null;
    Username: string = null;
    Password: string = null;

    getTypeName(): string {
        return 'evidanza.Database.AmazonDynamoDB.DynamoDBinfo';
    }
}
