import { UUID } from 'angular2-uuid';
import { plainToClass, Type } from 'class-transformer';
import { BehaviorSubject, Subject } from 'rxjs';
import { Base, JsonIgnore } from './base.model';
import { LayoutUnit } from './basic/layoutunit.model';
import { LayoutUnitThickness } from './basic/layoutunitthickness.model';
import { NavigationItem } from './basic/navigationitem.model';
import { Alignment } from './enums/alignment.enum';
import { ContentType } from './enums/contenttype.enum';
import { EventActionType } from './enums/eventactiontype.enum';
import { ViewType } from './enums/viewtype.enum';
import { Border } from './style/border.model';
import { Font } from './style/font.model';
import { Gradient } from './style/gradient.model';

// @dynamic
export class StyleBase extends Base {
    //#region Size
    @Type(() => LayoutUnit)
    Width: LayoutUnit;
    @Type(() => LayoutUnit)
    Height: LayoutUnit;
    @Type(() => LayoutUnit)
    MinWidth: LayoutUnit;
    @Type(() => LayoutUnit)
    MinHeight: LayoutUnit;
    @Type(() => LayoutUnit)
    MaxWidth: LayoutUnit;
    @Type(() => LayoutUnit)
    MaxHeight: LayoutUnit;
    @Type(() => LayoutUnit)
    TranslateX: LayoutUnit;
    @Type(() => LayoutUnit)
    TranslateY: LayoutUnit;
    @Type(() => LayoutUnit)
    TranslateZ: LayoutUnit;
    RotateX: number;
    RotateY: number;
    RotateZ: number;
    ScaleX: number;
    ScaleY: number;
    ScaleZ: number;
    ZIndex: number;
    TabIndex: number;
    //#endregion

    @Type(() => LayoutUnitThickness)
    Margin: LayoutUnitThickness;

    @Type(() => LayoutUnitThickness)
    Padding: LayoutUnitThickness;

    @Type(() => LayoutUnitThickness)
    Location: LayoutUnitThickness;

    //#region Styling
    @Type(() => Border)
    Border: Border;
    @Type(() => Font)
    Font: Font;
    @Type(() => Gradient)
    BackgroundColor: Gradient;
    HorizontalContentAlignment: Alignment;
    VerticalContentAlignment: Alignment;

    HorizontalTextAlignment: Alignment;
    VerticalTextAlignment: Alignment;
    //#endregion

    Visible: boolean;

    //#region Editable
    private _Editable: boolean;
    get Editable(): boolean {
        return this._Editable;
    }
    set Editable(val: boolean) {
        this._Editable = val;
        this.EditableChanged.next(val);
    }

    @JsonIgnore
    @Type(() => Subject)
    public EditableChanged = new Subject<boolean>();
    //#endregion

    static applyToObject(selItem: any, styleJSON: string): string[] {
        const retVal = [];
        if (typeof selItem === 'object') {
            const style = plainToClass(StyleBase, JSON.parse(styleJSON));
            if (style.Width && style.Width.isValid()) {
                selItem.Width = style.Width;
                retVal.push('Width');
            }
            if (style.Height && style.Height.isValid()) {
                selItem.Height = style.Height;
                retVal.push('Height');
            }
            if (style.TranslateX && style.TranslateX.isValid()) {
                selItem.TranslateX = style.TranslateX;
                retVal.push('TranslateX');
            }
            if (style.TranslateY && style.TranslateY.isValid()) {
                selItem.TranslateY = style.TranslateY;
                retVal.push('TranslateY');
            }
            if (style.TranslateZ && style.TranslateZ.isValid()) {
                selItem.TranslateZ = style.TranslateZ;
                retVal.push('TranslateZ');
            }
            if (typeof style.RotateX === 'number') {
                selItem.RotateX = style.RotateX;
                retVal.push('RotateX');
            }
            if (typeof style.RotateY === 'number') {
                selItem.RotateY = style.RotateY;
                retVal.push('RotateY');
            }
            if (typeof style.RotateZ === 'number') {
                selItem.RotateZ = style.RotateZ;
                retVal.push('RotateZ');
            }
            if (typeof style.ScaleX === 'number') {
                selItem.ScaleX = style.ScaleX;
                retVal.push('ScaleX');
            }
            if (typeof style.ScaleY === 'number') {
                selItem.ScaleY = style.ScaleY;
                retVal.push('ScaleY');
            }
            if (typeof style.ScaleZ === 'number') {
                selItem.ScaleZ = style.ScaleZ;
                retVal.push('ScaleZ');
            }
            if (typeof style.ZIndex === 'number') {
                selItem.ZIndex = style.ZIndex;
                retVal.push('ZIndex');
            }
            if (typeof style.TabIndex === 'number') {
                selItem.TabIndex = style.TabIndex;
                retVal.push('TabIndex');
            }
            if (style.Margin && style.Margin.isValid()) {
                selItem.Margin = style.Margin;
                retVal.push('Margin');
            }
            if (style.Padding && style.Padding.isValid()) {
                selItem.Padding = style.Padding;
                retVal.push('Padding');
            }
            if (style.Location && style.Location.isValid()) {
                selItem.Location = style.Location;
                retVal.push('Location');
            }
            if (style.Border && style.Border.isValid()) {
                selItem.Border = style.Border;
                retVal.push('Border');
            }
            if (style.Font) {
                if (selItem.Font) {
                    style.Font.applyToOtherFont(selItem.Font);
                } else {
                    selItem.Font = style.Font;
                }
                retVal.push('Font');
            }
            if (style.BackgroundColor) {
                selItem.BackgroundColor = style.BackgroundColor;
                retVal.push('BackgroundColor');
            }
            if (typeof style.HorizontalContentAlignment === 'number') {
                selItem.HorizontalContentAlignment = style.HorizontalContentAlignment;
                retVal.push('HorizontalContentAlignment');
            }
            if (typeof style.VerticalContentAlignment === 'number') {
                selItem.VerticalContentAlignment = style.VerticalContentAlignment;
                retVal.push('VerticalContentAlignment');
            }
            if (typeof style.HorizontalTextAlignment === 'number') {
                selItem.HorizontalTextAlignment = style.HorizontalTextAlignment;
                retVal.push('HorizontalTextAlignment');
            }
            if (typeof style.VerticalTextAlignment === 'number') {
                selItem.VerticalTextAlignment = style.VerticalTextAlignment;
                retVal.push('VerticalTextAlignment');
            }
            if (typeof style.Visible === 'boolean') {
                selItem.Visible = style.Visible;
                retVal.push('Visible');
            }
            if (typeof style._Editable === 'boolean') {
                selItem._Editable = style._Editable;
                retVal.push('_Editable');
            }
        }
        return retVal;
    }

    toJSON() {
        const result = Object.assign({}, this);
        delete result.EditableChanged;
        return result;
    }
}

// @dynamic
export class LayoutBase extends StyleBase {
    ID = UUID.UUID();
    //#region Position
    @Type(() => LayoutUnit)
    X: LayoutUnit;
    @Type(() => LayoutUnit)
    Y: LayoutUnit;
    //#endregion
    //#region Size
    @Type(() => LayoutUnit)
    Size: LayoutUnit;
    Scrollable: boolean;
    //#endregion

    @Type(() => LayoutUnitThickness)
    Location: LayoutUnitThickness;

    @JsonIgnore
    Selected = false;

    @JsonIgnore
    public ViewType: ViewType = ViewType.View;

    public ContentType: ContentType;
    _Name;
    get Name() {
        return this._Name;
    }
    set Name(val) {
        this._Name = val;
    }

    @JsonIgnore
    @Type(() => Subject)
    public ValuesChanged = new Subject<any>();
    @JsonIgnore
    @Type(() => Subject)
    public WorkflowStyleChanged = new Subject<string>();
    @Type(() => Subject)
    DataBindingChanged: Subject<any> = new Subject();
    @Type(() => Subject)
    ComponentChanged: Subject<any> = new Subject();

    @Type(() => BehaviorSubject)
    Initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);

    @Type(() => NavigationItem)
    public NavigationItems: NavigationItem[];
    //#region ColumnSettings

    //#region Column
    private _Column = 1;
    get Column(): number {
        return this._Column;
    }
    set Column(val: number) {
        this._Column = val;
    }
    //#endregion
    //#region ColSpan
    private _ColSpan: number;
    get ColSpan(): number {
        return this._ColSpan;
    }
    set ColSpan(val: number) {
        this._ColSpan = val;
    }
    //#endregion
    //#endregion
    //#region RowSettings

    //#region Row
    private _Row = 1;
    get Row(): number {
        return this._Row;
    }
    set Row(val: number) {
        this._Row = val;
    }
    //#endregion
    //#region RowSpan
    private _RowSpan: number;
    get RowSpan(): number {
        return this._RowSpan;
    }
    set RowSpan(val: number) {
        this._RowSpan = val;
    }
    //#endregion
    //#endregion

    //#region Events
    @Type(() => EventData)
    Events: EventData[] = [];
    //#endregion
    Element: any;
    CapsuleTag: string;
    constructor() {
        super();
        this.Visible = true;
    }
    toJSON() {
        const result = super.toJSON();
        delete result.Initialized;
        delete result.Selected;
        delete result.ViewType;
        delete result.ValuesChanged;
        delete result.WorkflowStyleChanged;
        delete result.DataBindingChanged;
        delete result.ComponentChanged;
        delete result.Element;
        delete result['Parent'];
        delete result['MetaTableProperties'];
        if (result['ElementType'] != 'raster') {
            delete result['RasterOptions'];
        }
        if (result['ElementType'] != 'grid') {
            delete result['ColumnDefinitions'];
            delete result['RowDefinitions'];
        }
        if (!(result['ElementType'] == 'button' || result['ElementType'] == 'upload' || result['ElementType'] == 'download' || result['ElementType'] == 'tablecolumnvisibility')) {
            delete result['IconMargin'];
            delete result['IconPadding'];
        }
        if (!(result['ElementType'] == 'button' || result['ElementType'] == 'upload' || result['ElementType'] == 'download' || result['ElementType'] == 'tablecolumnvisibility' ||
            result['ElementType'] == 'icon' || result['ElementType'] == 'usermenu' || result['ElementType'] == 'sidenav-toggle')) {
            delete result['Icon'];
            delete result['IconWidth'];
            delete result['IconHeight'];
        }
        if (this.Events) {
            if (this.Events.length == 0) {
                delete result.Events;
            } else {
                let notNull = this.Events.filter((v) => v != null && v.toJSON && v.toJSON() != null);
                if (!notNull) {
                    delete result.Events;
                }
            }
        }
        result['Name'] = result._Name;
        delete result._Name;
        if (result['ElementType'] === 'template' && result['LoadByReference']) {
            delete result['Elements'];
        }
        return result;
    }
}
// @dynamic
export class EventData {
    EventID: string;
    @Type(() => EventAction)
    Handlers: EventAction[] = [];
    toJSON? = () => {
        if (!this.Handlers || this.Handlers.length == 0) {
            return null;
        } else {
            return this;
        }
    }
}

export class EventAction {
    ActionType: EventActionType = EventActionType.Workflow;
    Action: string;
}



export class ElementProperty {
    Caption: string;
    PropertyName: string;
    DataType: string;

    constructor(propName: string, dataType: string, caption: string) {
        this.PropertyName = propName;
        this.DataType = dataType;
        this.Caption = caption;
    }
}
