import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    OnInit,
    Output,
    QueryList, ViewChild,
    ViewChildren
} from '@angular/core';
import {
    AbstractControl, AsyncValidatorFn,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {UsersService} from 'src/app/services/users.service';
import {NotificationHelper} from "../../helpers/notification.helper";
import {Router} from "@angular/router";
import {OTPDialogComponent} from "./OTPDialog/OTP-dialog";
import {MatDialog} from "@angular/material/dialog";
import {LayoutService} from "../../services/layout.service";
import {MediaService} from "../../services/media.service";
import {ThemeHelper} from "../../helpers/theme.helpers";

@Component({
    selector: 'lib-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

    @ViewChild('forgotForm') forgotForm;
    verifyEmail = true;
    verifyOTP = false;
    newPassword = false;
    newPassHide = true;
    confirmPassHide = true;
    errorMessage: string = '';
    showTooltip = false;
    hasCharacterLength = false;
    hasCapitalLetter = false;
    hasNumber = false;
    hasSpecialChar = false;
    hasAlphabit = false;

    iconURL: string = ''
    imageURL: string = '';
    userEmail;


    @Output() goBackLogin = new EventEmitter<boolean>();


    form: FormGroup;

    constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.buildForm();
        this.form.valueChanges.subscribe(() => {
            const _form = this.form.getRawValue();
            this.validatePassword(_form.password)
        });

        this.iconURL = '../../../assets/icons/ga_univers.svg';
        if (ThemeHelper.AcitveTheme.IsDarkTheme) {
            this.imageURL = '../../../assets/img/dark_login_illustration.png';
        }
        else {
            this.imageURL = '../../../assets/img/login_illustration.gif';
        }

        // MediaService.IconURL.subscribe(url => {
        //     if (url) {
        //         this.iconURL = url + 'ga_univers.svg';
        //     }
        // })
        // MediaService.ImageURL.subscribe(url => {
        //     if (url) {
        //         if (ThemeHelper.AcitveTheme.IsDarkTheme) {
        //             this.imageURL = url + 'dark_login_illustration.png';
        //             return;
        //         }
        //         this.imageURL = url + 'login_illustration.gif';
        //     }
        // })
    }

// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*?&])[A-Za-z\d@$!%^*?&]{8,}$/
    buildForm() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8),
                Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s\S])[a-zA-Z\d\s\S]{8,}$/)]
            ],
            confirmPassword: ['', Validators.required],
        });
    }

    toggleWindow(_window) {
        this.verifyEmail = false;
        this.verifyOTP = false;
        this.newPassword = false;
        if (_window == 'forgot-password') {
            this.verifyEmail = true;
        }
        if (_window == 'verify-otp') {
            this.verifyOTP = true;
        }
        if (_window == 'new-password') {
            this.newPassword = true;
        }

    }

    onVerifyEmail() {
        const _form = this.form.getRawValue();
        const isEmailValid = this.form.get('email').valid;
        if (_form && _form.email && isEmailValid) {
            const payload = {Email: _form.email};
            this.loading(true);
            this.usersService.verifyEmail(payload).subscribe({
                next: (res) => {
                    this.loading(false);
                    if (res['StatusCode'] == 200) {
                        const message = res['Message'] || '';
                        NotificationHelper.Success(`@@${message}`, '@@Success');
                        this.openOtpDialog();
                    }
                },
                error: (err) => {
                    this.loading(false);
                    // const message = err.error.Message || '';
                    // NotificationHelper.Error(`@@${message}`, '@@Error');
                }
            })
        } else {
            NotificationHelper.Error(`@@Please enter a valid email address`, '@@Error');
        }
    }

    onResetPassword() {
        const _form = this.form.getRawValue();

        if (_form && !_form.password) {
            NotificationHelper.Error(`Please enter your new password`, '@@Error');
            return;
        }

        if (this.errorMessage) {
            NotificationHelper.Error(`@@${this.errorMessage}`, '@@Error');
            return;
        }

        if (_form && _form.password != _form.confirmPassword) {
            NotificationHelper.Error(`@@Passwords do not match`, '@@Error');
            return;
        }

        if (_form && _form.password == _form.confirmPassword) {
            const payload = {Email: this.userEmail, NewPassword: _form.password};
            this.loading(true);
            this.usersService.resetPassword(payload).subscribe({
                next: (res) => {
                    this.loading(false);
                    if (res['StatusCode'] == 200) {
                        const message = res['Message'] || '';
                        NotificationHelper.Success(`@@${message}`, '@@Success');
                        this.goBack();
                    }
                },
                error: (err) => {
                    this.loading(false);
                    // const message = err.error.Message || '';
                    // NotificationHelper.Error(`@@${message}`, '@@Error');
                }
            })
        }
    }

    goBack() {
        this.goBackLogin.emit(true);
    }

    openOtpDialog(): void {
        const dialogRef = this.dialog.open(OTPDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {form: this.form.getRawValue()}
        });

        dialogRef.afterClosed().subscribe(isSuccess => {
            if (isSuccess) {
                this.userEmail = this.form.get('email').value;
                this.resetForm();
                this.toggleWindow('new-password');
            }
        });
    }

    resetForm() {
        this.form.reset();
        if (this.forgotForm) {
            this.forgotForm.resetForm();
        }
    }

    validatePassword(password) {
        this.hasCharacterLength = /^.{8,}$/.test(password);
        this.hasCapitalLetter = /[A-Z]/.test(password);
        this.hasNumber = /[0-9]/.test(password);
        this.hasSpecialChar = /(?=.*[^a-zA-Z\d\s])/.test(password);
        // !@#$%^&*(),.?
        this.hasAlphabit = /[a-z]/.test(password);

        if (password && password.length < 8) {
            this.errorMessage = 'Password must be at least 8 characters long.';
        } else if (!this.hasCapitalLetter) {
            this.errorMessage = 'Password must contain at least one capital letter.';
        } else if (!this.hasNumber) {
            this.errorMessage = 'Password must contain at least one number.';
        } else if (!this.hasSpecialChar) {
            this.errorMessage = 'Password must contain at least one special character.';
        } else if (!this.hasAlphabit) {
            this.errorMessage = 'Password must contain at least one alphabet character.';
        } else {
            this.errorMessage = '';
        }
        // return this.hasCapitalLetter && this.hasCapitalLetter && this.hasNumber && this.hasSpecialChar && this.hasAlphabit;
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

}

``

