/* tslint:disable: max-line-length */
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanDeactivate, Route, RouterStateSnapshot, Routes, UrlSegment, UrlSegmentGroup, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { ContentPage } from './components/pages/content.page';
import { SettingsPage } from './components/pages/settings.page';
import { LayoutService } from './services/layout.service';
import { NavigationService } from './services/navigation.service';
import { HelpCenterComponent } from './component_v2/help-center/help-center.component';
import {PersonalSettingsComponent} from "./component_v2/personal-settings/personal-settings.component";
import { DashboardComponent } from 'src/app/component_v2/dashboard/dashboard.component';
import { PermissionDeniedPage } from './components/pages/not-permitted.page';

export function CustomSettingsUrlMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
    return segments.length > 1 && segments[1].path === 'settings' ? ({ consumed: segments }) : null;
}

@Injectable()
export class CanDeactivateContentPage implements CanDeactivate<ContentPage>{
    canDeactivate(component: ContentPage, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const disableText = NavigationService.DisableBack.getValue();
        if (disableText) {
            if (LayoutService.PreventBrowserClose.getValue()) {
                if (confirm(disableText) == false) {
                    history.pushState(null, null, location.href);
                    return false;
                } else {
                    LayoutService.PreventBrowserClose.next(false);
                    NavigationService.DisableBack.next(null);
                }
            } else {
                NavigationService.DisableBack.next(null);
                if (confirm(disableText) == false) {
                    history.pushState(null, null, location.href);
                    return false;
                }
            }
        }
        return true;
    }
}

export const routes: Routes = [
    { path: 'default/settings/dashboard', component: DashboardComponent },
    { path: 'default/settings/front-pages', loadChildren: () => import('src/app/component_v2/front-pages/front-pages.module').then(m => m.FrontPagesModule) },
    { path: 'default/settings/help-center', component: HelpCenterComponent },
    { path: 'default/settings/personal-settings', component: PersonalSettingsComponent },
    { path: 'default/settings/access-denied', component: PermissionDeniedPage },
    { component: SettingsPage, matcher: CustomSettingsUrlMatcher },
    { path: '**', component: ContentPage, canDeactivate: [CanDeactivateContentPage] },
];
