import { Operation, ValueAndType } from '../basic/formulaEditor.model';
import { BinaryOperation } from '../enums/binaryoperation.enum';
import { CompareType } from '../enums/comparetype.enum';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';

export interface IFormula {
    ID(): string;
    Name(): string;
    Caption(): string;
    Description(): string;
    MinVariableCount(): number;
    MaxVariableCount(): number;
    Primitiv(): boolean;
    CanCalcNulls(): boolean;
    DontResolveRanges(): boolean;
    Advanced(): boolean;
    Group(): string;
    OrderID(): number;
    BinaryOperation(): BinaryOperation;
    CompareType(): CompareType;
    OperationType(): OperationType;
    OperationValue(): string;

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType;
    ToOperation(): Operation;
}

export abstract class AFormula implements IFormula {
    abstract ID(): string;
    abstract Name(): string;
    Caption(): string {
        return '@@' + this.Name();
    }
    Description(): string {
        return this.Name();
    }
    MinVariableCount(): number {
        return 1;
    }
    MaxVariableCount(): number {
        return 1;
    }
    Primitiv(): boolean {
        return false;
    }
    CanCalcNulls(): boolean {
        return false;
    }
    DontResolveRanges(): boolean {
        return false;
    }
    Advanced(): boolean {
        return false;
    }
    abstract Group(): string;
    abstract OrderID(): number;
    BinaryOperation(): BinaryOperation {
        return BinaryOperation.None;
    }
    CompareType(): CompareType {
        return CompareType.None;
    }
    abstract OperationType(): OperationType;
    abstract OperationValue(): string;

    abstract Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType;

    ToOperation(): Operation {
        const retVal = new Operation();
        switch (this.OperationType()) {
            case OperationType.Binary:
                retVal.BinaryOperation = this.BinaryOperation();
                break;
            case OperationType.Compare:
                retVal.CompareType = this.CompareType();
                break;
            case OperationType.Function:
                retVal.FunctionType = this.ID();
                break;
        }
        retVal.MaxOperandCount = this.MaxVariableCount();
        retVal.MinOperandCount = this.MinVariableCount();
        retVal.Name = this.Caption(); // hier uebersetzen?
        retVal.OperationType = this.OperationType();
        retVal.OperationValue = this.OperationValue();
        retVal.Description = this.Description();
        return retVal;
    }
}

export abstract class AAdvancedFormula extends AFormula {
    Advanced(): boolean {
        return true;
    }
    Group(): string {
        return '';
    }
    OperationType(): OperationType {
        return OperationType.None;
    }
    OperationValue(): string {
        return '';
    }
    OrderID(): number {
        return 0;
    }
}

export abstract class PrimitiveFormula extends AFormula {
    protected _ID: string;
    protected _Name: string;
    protected _MinVariableCount = 2;
    protected _MaxVariableCount = 2;

    ID(): string {
        return this._ID;
    }
    Name(): string {
        return this._Name;
    }
    MinVariableCount(): number {
        return this._MinVariableCount;
    }
    MaxVariableCount(): number {
        return this._MaxVariableCount;
    }
    Primitiv(): boolean {
        return true;
    }
}

export abstract class ComparerFormula extends PrimitiveFormula {
    CanCalcNulls(): boolean {
        return true;
    }
    Group(): string {
        return FormulaGroups.Comparisons;
    }
    OperationType(): OperationType {
        return OperationType.Compare;
    }
}

export abstract class RangeEnabledFormula extends AAdvancedFormula {
    protected _ID: string;
    protected _Name: string;
    protected _MinVariableCount = 1;
    protected _MaxVariableCount = 1;

    ID(): string {
        return this._ID;
    }
    Name(): string {
        return this._Name;
    }
    MinVariableCount(): number {
        return this._MinVariableCount;
    }
    MaxVariableCount(): number {
        return this._MaxVariableCount;
    }

    protected HandleNumericRange(vat: ValueAndType, handler) {
        if (vat && Array.isArray(vat.Value)) {
            vat.Value.forEach(function (val) {
                if (typeof val === 'undefined' || val == null || typeof val.Value === 'undefined' || val.Value == null) {
                    handler(null);
                } else {
                    handler(Number(val.Value));
                }
            });
        }
    }

    protected GetStringRange(vat: ValueAndType): string[] {
        const retVal = [];
        if (vat && Array.isArray(vat.Value)) {
            vat.Value.forEach(function (val) {
                if (typeof val === 'undefined' || val == null || typeof val.Value === 'undefined' || val.Value == null) {
                    retVal.push(null);
                } else {
                    retVal.push(String(val.Value));
                }
            });
        }
        return retVal;
    }
}

export class FormulaGroups {
    static GroupSeperator = '//';
    static BasicOperations = '@@Grundrechenarten';
    static Functions = '@@Funktionen';
    static Comparisons = '@@Vergleichsoperationen';
    static Binary = '@@Binaere Operationen';
}
