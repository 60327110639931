import { PropertyControl } from '../components/common/propertyControl/property.control';
import { FormulaEditor } from '../components/common/formulaEditor/formulaEditor.control';
import { ComponentFilterControl } from '../components/common/componentfilter/componentfilter.control';
import { TimeSpanControl } from '../components/common/timespanControl/timespan.control';
import { BaseAction } from '../components/actions/base.action';
import { ContextMenuComponent } from '../components/common/contextmenu/contextmenu.component';
import { BaseTreeControl } from '../components/common/basetreecontrol/base.tree.control';
import { DropDownControl } from '../components/common/dropdowncontrol/dropdown.control';
import { NavigationTabsControl } from '../components/common/navigationcontrol/navigation.tabs';
import { NavigationControl } from '../components/common/navigationcontrol/navigation.control';
import { IndeterminateCheckbox } from '../components/common/indeterminatecheckbox/indeterminate.checkbox';
import { RequestOptionsControl } from '../components/common/requestoptions/request.options.control';
import { MenuTabControl } from '../appbuilder/common/menutabcontrol/menu.tab.control';
import { AccordionMenuControl } from '../appbuilder/common/menutabcontrol/accordion.menu.control';
import { CapsuleTagControl } from '../components/common/capsuletagcontrol/capsule.tag.control';
import { LayoutEditControl } from '../appbuilder/common/layouteditcontrol/layout.edit.control';
import { DataSourceSelectionControl } from '../components/common/datasourceselectioncontol/datasource.selection.control';
import { ScriptEditor } from '../components/common/scripteditor/scripteditor.control';
import { LayoutUnitThicknessComponent } from '../appbuilder/controls/layoutunitthickness/layoutunitthickness.component';
import { UserOverviewControl } from '../components/common/collaboration/user.overview.control';
import { BranchSelectControl } from '../components/common/development/branchselect/branchselect.control';
import { RepositorySelectControl } from '../components/common/development/repositoryselect/repositoryselect.control';
import { LogsdialogComponent } from '../settings/logsettings/logsdialog.component';
import { ComparsionOverlay } from '../components/common/overlays/comparison/comparison.overlay';
import { JiraControl } from '../components/common/jiracontrol/jira.control';
import { LogsdialogsystemComponent } from '../settings/logsettings/logsdialogsystem.component';
import { UnitSelectionControl } from '../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { NavigationMenu } from "../components/common/navigationmenu/navigation.menu";
import {ListView} from "../component_v2/listview/listview.component"
import { CreateAppComponent } from '../component_v2/create-app/create-app.component';

export const APP_COMMON = [
    PropertyControl,
    FormulaEditor,
    ComponentFilterControl,
    TimeSpanControl,
    BaseAction,
    ContextMenuComponent,
    BaseTreeControl,
    DropDownControl,
    NavigationTabsControl,
    NavigationControl,
    IndeterminateCheckbox,
    RequestOptionsControl,
    MenuTabControl,
    AccordionMenuControl,
    CapsuleTagControl,
    LayoutEditControl,
    DataSourceSelectionControl,
    ScriptEditor,
    LayoutUnitThicknessComponent,
    UnitSelectionControl,
    UserOverviewControl,
    LogsdialogComponent,
    LogsdialogsystemComponent,
    BranchSelectControl,
    RepositorySelectControl,
    ComparsionOverlay,
    JiraControl,
    NavigationMenu,
    ListView,
    CreateAppComponent
];
