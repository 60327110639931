import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class GenerateNull extends AFormula {
    static StaticID = 'ff21272f-d117-410f-9967-a81d90fcdc08';

    ID(): string {
        return GenerateNull.StaticID;
    }

    Name(): string {
        return 'GenerateNull';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Null erzeugen');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1007;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '()';
    }

    MinVariableCount(): number {
        return 0;
    }

    MaxVariableCount(): number {
        return 0;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Type = ValueType.Null;
        retVal.Value = null;
        return retVal;
    }
}
