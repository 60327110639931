export enum QueryType {
  Auto,
  Normal,
  SubSelect,
  SubCube
}

export enum CellType {
  Double = 0x01,
  Long = 0x02,
  String = 0x04,
  DateTime = 0x08,
  ListBaseObject = 0x10
}

export enum MemberType {
  Unknown = 0,
  Regular = 1,
  All = 2,
  Measure = 3,
  Calculated = 4
}

export enum AxisType {
  None = 0,
  X_Axis = 1,
  Y_Axis = 2,
  Measure = 3
}

export enum AttributeUsage {
  Regular,
  Key,
  Parent
}

export enum MeasureDataType {
  SmallInt = 2,
  Integer = 3,
  Single = 4,
  Double = 5,
  Currency = 6,
  Date = 7,
  Boolean = 11,
  TinyInt = 16,
  UnsignedTinyInt = 17,
  UnsignedSmallInt = 18,
  UnsignedInt = 19,
  BigInt = 20,
  UnsignedBigInt = 21,
  WChar = 130,
  Inherited = 131
}

export enum MeasureAccumulation {
  None,
  YTD,
  MTD,
  WTD
}

export enum SelectionType {
  None,
  ObjectSelect,
  SlideSelect,
  SlideFilter,
  MasterChild,
  QuickLinks,
  DrillDown,
  DrillThrough,
  PowerGrid,
  PlanGrid,
  Reference,
  Security,
  Heterogen
}

export enum CodeDesc {
  /// <summary>
  /// Kein Text
  /// </summary>
  None,
  /// <summary>
  /// Nur der Code
  /// </summary>
  Code,
  /// <summary>
  /// Nur die Description.
  /// </summary>
  Description,
  /// <summary>
  /// Code + Description
  /// </summary>
  CodeDescription,
  /// <summary>
  /// Ein bestimmtes Attribut
  /// </summary>
  Attribute
}

export enum DynamicTimeType {
  Now,
  Filter,
  Fix,
  DBSelect
}

export enum RangeOrientation {
  End,
  Start,
}

export enum DefaultTaskPosition {
  BeforeDataTasks,
  BetweenDataAndFormatTasks,
  AfterFormatTasks
}
