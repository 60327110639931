import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Input() clickOutsideFunction: Function; // Input to receive the function to be executed

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onClick(event: Event): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      if (this.clickOutsideFunction) {
        console.log('click',this.clickOutsideFunction);
        this.clickOutsideFunction(); // Execute the provided function
      }
    }
  }
}
