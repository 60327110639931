import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-setproperty-settings',
    templateUrl: './setProperty.settings.html',
    styleUrls: ['./setProperty.settings.css']
})
export class SetPropertySettings extends FormulaWorkflowDialogContent {
    static MultiModuleID = 'setMultiPropertyModule';

    Data = new SetPropertySettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setPropertyModule';
        reg.Caption = '@@Set property';
        reg.SettingsControl = SetPropertySettings;
        reg.SettingsTypeHelper = new SetPropertySettingsDataHelper();
        reg.Executer = SetPropertyModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetPropertySettings.MultiModuleID;
        return reg;
    }

    constructor() {
        super();
        this.HasExpressions = true;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = JSON.stringify(data);
            this.Data = plainToClass(SetPropertySettingsData, JSON.parse(json));
        }
    }

    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [
            {
                Value: 'PropertyName'
            },
            {
                Value: 'Formula'
            }
        ];
    }
}

export class SetPropertySettingsData {
    PropertyName: string;
    Formula: string;
}

export class SetPropertySettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetPropertySettingsData();
    }
}

export class SetPropertyModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualParameter) {
            if (status.ActualSettings) {
                if (typeof status.ActualSettings.PropertyName === 'string') {
                    let val = status.ActualParameter;
                    const propList = status.ActualSettings.PropertyName.split('.');
                    for (let i = 0; i < propList.length - 1; i++) {
                        val = val[propList[i]];
                        if (typeof val === 'undefined' || val == null) {
                            propList.splice(-1, 1);
                            status.Logger.logError('SetProperty modul: Property ' + propList.join('.') + ' not found.');
                            return super.execute(status);
                        }
                    }
                    try {
                        const calculator = new WorkflowFormulaCalculator(status);
                        val[propList[propList.length - 1]] = calculator.CalcFormula(status.ActualSettings.Formula);
                        return 0;
                    } catch (ex) {
                        status.Logger.logWarning('SetMultiProperty modul: Error while calculating value for property ' +
                            status.ActualSettings.PropertyName + ' (' + ex + ')');
                    }
                } else {
                    status.Logger.logError('SetProperty modul: No property name found.');
                }
            } else {
                status.Logger.logError('SetProperty modul: No settings found.');
            }
        } else {
            status.Logger.logError('SetProperty modul: No object found to set properties on.');
        }
        return super.execute(status);
    }
}
