import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TimeHelper } from '../../helpers/time.helper';
import { LevelType } from '../../models/enums';
import { DynamicTimeType } from '../../models/enums/query.enum';
import { DynamicTimePoint } from '../../models/time/dynamictimepoint.model';
import { TranslatedString } from '../../models/translatedstring.model';
import { DynamicTimeSettingsService } from '../../services/dynamictimesettings.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { DynamicTimePointReferenceDialog } from './dtp.reference.dialog';
import config from '../../../assets/config.json'
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";
@Component({
    selector: 'dynamic-timepoint-settings',
    templateUrl: '../base.list.settings.html'
})
export class DynamicTimePointSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@Dynamischer Zeitpunkt',
            ID: 'dynamictimepoint',
            Icon: 'schedule',
            Index: 20,
            Parent: 'settings',
            Content: DynamicTimePointSettings
        };
    }

    init() {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            this.isFreeUser = true;
            this.router.navigate(['/default/settings/access-denied']);
            return;
        }
    }
    constructor(protected factoryResolver: ComponentFactoryResolver,
        protected cdRef: ChangeDetectorRef,
        private translate: TranslateService,
        private service: DynamicTimeSettingsService,
        private router: Router
    ) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return DynamicTimePointDetail;
    }

    loadList(handler) {
        this.service.GetDynamicTimePoints().subscribe(r => {
            if (r) {
                const list = [];
                r.forEach(entry => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.service.GetDynamicTimePoint(data).subscribe(dtp => {
            if (dtp) {
                this.setSelectedItem(DynamicTimePoint.plainToClass(dtp));
            }
        });
    }

    getNewItem() {
        const dtp = new DynamicTimePoint();
        dtp.Name = new TranslatedString(this.translate.instant('@@Neuer dynamischer Zeitpunkt'));
        dtp.Description = new TranslatedString();
        return dtp;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Dynamic time \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(TranslatedString.GetTranslation(sel.Name));
        retVal.Title = new TranslateFormatText('@@Dynamischen Zeitpunkt speichern');
        return retVal;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete dynamic time \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Dynamic time \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Dynamischen Zeitpunkt loeschen');
        return retVal;
    }

    delete(data) {
        this.service.DeleteDynamicTimePoint(data).subscribe();
    }

    saveInternal(item, handler) {
        if (item?.Name?.DefaultValue.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.SaveDynamicTimePoint(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'dynamic-timepoint-detail',
    templateUrl: './dynamic.timepoint.settings.html',
    styleUrls: ['./dynamic.timepoint.settings.css']
})
export class DynamicTimePointDetail extends BaseListDetail {
    StartDate;
    ShiftedDate;
    EndDate;
    Movement = new TimeMovementInfo();
    Fixations = [];

    FilterText = new Date(1999, 11, 31).toLocaleDateString().replace(/\d/g, '?');

    private static CheckVals(fixObjList, timeFix) {
        if (fixObjList && timeFix) {
            fixObjList.forEach(fixObj => {
                let val = timeFix.get(fixObj.LevelType);
                if (typeof val === 'number') {
                    if (val < fixObj.Min) {
                        val = fixObj.Min;
                        timeFix.set(LevelType.TimeHalfYears, val);
                    } else if (val > fixObj.Max) {
                        val = 2;
                        timeFix.set(LevelType.TimeHalfYears, val);
                    }
                    fixObj.Value = val;
                }
            });
        }
    }

    setSelectedItem(item: any): void {
        super.setSelectedItem(item);
        const movement = new TimeMovementInfo();
        if (this.SelectedItem) {
            if (this.SelectedItem.TimeMovement) {
                this.SelectedItem.TimeMovement.forEach((v, k) => {
                    if (typeof v === 'number') {
                        switch (k) {
                            case LevelType.TimeYears:
                                movement.Year = v;
                                break;
                            case LevelType.TimeHalfYears:
                                movement.Halfyear = v;
                                break;
                            case LevelType.TimeQuarters:
                                movement.Quarter = v;
                                break;
                            case LevelType.TimeMonths:
                                movement.Month = v;
                                break;
                            case LevelType.TimeWeeks:
                                movement.Week = v;
                                break;
                            case LevelType.TimeDays:
                                movement.Day = v;
                                break;
                        }
                    }
                });
            }
        }
        this.Movement = movement;
        this.UpdateFixations();
        this.calcStartDate();
    }

    UpdateFixations() {
        const fixations = [];
        if (this.SelectedItem) {
            switch (this.SelectedItem.FixationLevel) {
                case LevelType.TimeMonths:
                    break;
                case LevelType.TimeHalfYears:
                    fixations.push({
                        Caption: '@@Halbjahr',
                        Value: 1,
                        LevelType: LevelType.TimeHalfYears,
                        Min: 1,
                        Max: 2
                    });
                    fixations.push({
                        Caption: '@@Monat',
                        Value: 1,
                        LevelType: LevelType.TimeMonths,
                        Min: 1,
                        Max: 6
                    });
                    break;
                case LevelType.TimeQuarters:
                    fixations.push({
                        Caption: '@@Quartal',
                        Value: 1,
                        LevelType: LevelType.TimeQuarters,
                        Min: 1,
                        Max: 4
                    });
                    fixations.push({
                        Caption: '@@Monat',
                        Value: 1,
                        LevelType: LevelType.TimeMonths,
                        Min: 1,
                        Max: 3
                    });
                    break;
                case LevelType.TimeWeeks:
                    fixations.push({
                        Caption: '@@Woche',
                        Value: 1,
                        LevelType: LevelType.TimeWeeks,
                        Min: 1,
                        Max: 53
                    });
                    break;
            }
            if (fixations.length > 0 || this.SelectedItem.FixationLevel === LevelType.TimeMonths) {
                fixations.push({
                    Caption: '@@Tag',
                    Value: 1,
                    LevelType: LevelType.TimeDays,
                    Min: 1,
                    Max: 31
                });
                DynamicTimePointDetail.CheckVals(fixations, this.SelectedItem.TimeFixation);
            }
        }
        this.Fixations = fixations;
    }

    showAssignments() {
        const sel = this.SelectedItem;
        if (sel) {
            BaseDialog.ShowDialog({
                ContentType: DynamicTimePointReferenceDialog,
                InitArgs: {
                    References: sel.References
                },
                Handler: (r) => {
                    if (r) {
                        sel.References = r;
                    }
                    return true;
                }
            });
        }
    }

    OnStartChanged() {
        this.calcStartDate();
        this.OnItemChanged();
    }

    calcStartDate() {
        let startDate;
        if (this.SelectedItem && this.SelectedItem.TimeType !== DynamicTimeType.Filter) {
            startDate = TimeHelper.GetDateTimeFromDynamicTimePointTimeType(this.SelectedItem, null);
        }
        this.StartDate = startDate;
        this.calcShiftedDate();
    }

    calcShiftedDate() {
        let shiftedDate;
        if (this.SelectedItem) {
            shiftedDate = TimeHelper.GetDateTimeFromDynamicTimePointMovement(this.StartDate, this.SelectedItem.TimeMovement);
        }
        this.ShiftedDate = shiftedDate;
        this.calcEndDate();
    }

    calcEndDate() {
        this.EndDate = TimeHelper.GetDateTimeFromDynamicTimePointFixation(this.SelectedItem, this.ShiftedDate);
    }

    OnRefChanged() {
        this.UpdateFixations();
        this.calcEndDate();
        this.OnItemChanged();
    }

    setChange(lt, ev) {
        if (ev && ev.currentTarget && this.SelectedItem) {
            let val = parseInt(ev.currentTarget.value, 10);
            if (isNaN(val)) {
                val = 0;
            }
            if (!this.SelectedItem.TimeMovement) {
                this.SelectedItem.TimeMovement = new Map();
            }
            this.SelectedItem.TimeMovement.set(lt, val);
            this.calcShiftedDate();
            this.OnItemChanged();
        }
    }

    setRef(lt, val, ev) {
        if (this.SelectedItem) {
            if (!this.SelectedItem.TimeFixation) {
                this.SelectedItem.TimeFixation = new Map();
            }
            this.SelectedItem.TimeFixation.set(lt, val);
            this.calcEndDate();
            this.OnItemChanged();
        }
    }

    sundayShiftChanged() {
        this.calcEndDate();
        this.OnItemChanged();
    }
}

export class TimeMovementInfo {
    Year = 0;
    Halfyear = 0;
    Quarter = 0;
    Month = 0;
    Week = 0;
    Day = 0;
}
