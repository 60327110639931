import { equal, round, abs, max, min, random, floor, pow, sqrt, ceil } from 'mathjs';
import { CultureInfoWrapper } from './cultureinfo.wrapper.script';



export class MathWrapper {
    public static abs(value): any {
        return abs(value);
    }

    public static max(value1, value2): any {
        return max(value1, value2);
    }

    public static min(value1, value2): any {
        return min(value1, value2);
    }

    //ToDo
    public static toFixed(num: number, decimals, lcid = -1) {
        if (lcid > 0) {
            var cultureInfo = CultureInfoWrapper.toCultureInfo(lcid);
            console.log(cultureInfo);
            return num.toLocaleString(cultureInfo, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        }
        else {
            return num.toFixed( decimals);
        }
    }
    public static round(value, digitsValue = 0) {
        if (typeof value === 'number' && typeof digitsValue === 'number') {
            return round(value, digitsValue);
        }
        return round(value);
    }

    public static random() :any {
       return  random();
    }

    public static floor( value): any {
        return floor(value);
    }

    public static ceil( value): any {
        return ceil(value);
    }

    public static pow( value,  power) :any {
        return pow(value, power);
    }

    public static sqrt( value) :any {
        return sqrt(value);
    }
}
