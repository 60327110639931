import { NgModule } from "@angular/core";
import { ChartsModule } from "../components/chart/chart.module";
import { DataTableModule } from "../components/datatable/datatable.module";

@NgModule({
    imports: [
        DataTableModule,
        ChartsModule
    ],
    exports: [
        DataTableModule,
        ChartsModule
    ]
})
export class EviControlsModule { }