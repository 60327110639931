import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ClientHelper } from '../../helpers/client.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';
import { DebugWorkflowAttachDialog } from './debug.workflow.attach.dialog';

@Component({
    selector: 'debug-workflow-connection',
    templateUrl: './debug.workflow.connection.page.html',
    styleUrls: ['./debug.workflow.connection.page.css']
})
export class DebugWorkflowConnectionPage implements OnInit {
    AttachedClients = [];
    ShowAttach = false;
    SelectedClient;
    Selected

    private static SelfAttach = new Subject();

    static CheckForSelfAttach() {
        let debugClient = WorkflowDebugHelper.DebugClient.getValue();
        if (!debugClient) {
            if (localStorage.getItem('DebugClient')) {
                // Message anzeigen
                NotificationHelper.Warn('@@DebugAlreadyAttached', '@@Warning');
            } else {
                // AutoAttach
                DebugWorkflowConnectionPage.SelfAttach.next(null);
            }
        }
    }

    constructor(private cdRef: ChangeDetectorRef, private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.checkShowAttached();
        DebugWorkflowConnectionPage.SelfAttach.subscribe(() => {
            const attClient = {
                ClientID: ClientHelper.ClientID,
                Caption: this.translate.instant('@@OwnWindow')
            };
            this.AttachedClients.push(attClient);
            WorkflowDebugHelper.AttachToClients([attClient.ClientID]);
            this.cdRef.detectChanges();
        });
        WorkflowDebugHelper.OnAttachOrDetach.subscribe(() => {
            this.checkShowAttached();
        });
        WorkflowDebugHelper.DebugState.subscribe(x => {
            this.AttachedClients.some((client, j) => {
                if (client.ClientID === x.Sender) {
                    switch (x.Type) {
                        case 'WorkflowState':
                            this.SelectedClient = j;
                            if (client.RunningWorkflows) {
                                if (!client.RunningWorkflows.some((rw, i) => {
                                    if (rw.Data.RunID === x.Content.RunID) {
                                        rw.Data = x.Content;
                                        client.SelectedWorkflow = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    client.RunningWorkflows.push({
                                        Data: x.Content,
                                        Caption: x.Content.RunID
                                    });
                                    client.SelectedWorkflow = client.RunningWorkflows.length - 1;
                                }
                            } else {
                                client.RunningWorkflows = [{
                                    Data: x.Content,
                                    Caption: x.Content.RunID
                                }];
                                client.SelectedWorkflow = 0;
                            }
                            this.cdRef.detectChanges();
                            break;
                        case 'WorkflowFinished':
                            if (client.RunningWorkflows) {
                                let index;
                                if (client.RunningWorkflows.some((rw, i) => {
                                    if (rw.Data.RunID === x.Content.RunID) {
                                        index = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    client.RunningWorkflows.splice(index, 1);
                                    this.cdRef.detectChanges();
                                }
                            }
                            break;
                    }
                    return true;
                }
                return false;
            });
        });
    }

    checkShowAttached() {
        const debugClient = localStorage.getItem('DebugClient');
        this.ShowAttach = !debugClient || debugClient === ClientHelper.ClientID;
        this.cdRef.detectChanges();
    }

    onAttachClick() {
        if (this.ShowAttach) {
            const connectedClients = [];
            this.AttachedClients.forEach(x => connectedClients.push({ ClientID: x.ClientID, Caption: x.Caption }));
            DebugWorkflowAttachDialog.ShowDialog(connectedClients, (x) => {
                if (x && x.length > 0) {
                    const newClients = [];
                    x.forEach(y => {
                        this.AttachedClients.push(y);
                        newClients.push(y.ClientID)
                    });
                    WorkflowDebugHelper.AttachToClients(newClients);
                    this.cdRef.detectChanges();
                }
            });
        }
    }

    detachAll() {
        this.AttachedClients.splice(0);
        WorkflowDebugHelper.DetachAll();
    }

    detachClient(clientID, index) {
        this.AttachedClients.splice(index, 1);
        if (this.AttachedClients.length === 0) {
            WorkflowDebugHelper.DetachAll();
        } else {
            WorkflowDebugHelper.DetachClient(clientID);
        }
    }

    onCancel(client, j) {
        if (client && client.RunningWorkflows) {
            client.RunningWorkflows.splice(j, 1);
            this.cdRef.detectChanges();
        }
    }
}
