import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'lexbizz-panel',
    templateUrl: './lexbizz.panel.html',
    styleUrls:['./lexbizz.panel.css']
})
export class LexbizzPanel extends BaseDataSourcePanel {

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: LexbizzPanel,
            DBInfoType: LexbizzDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = LexbizzDBInfo;
    }   
}

export class LexbizzDBInfo extends ADBInfo {
    BaseUrl: string = null;
    EndpointName: string = null;
    EndpointVersion: string = null;
    Username: string = null;
    Password: string = null;
    Company: string = null;
    getTypeName(): string {
        return 'evidanza.Database.Lexbizz.LexbizzDBInfo';
    }
}
