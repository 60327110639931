import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { TranslateHelper } from '../../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';
import {
    TextClassificationColumn, TextClassificationModelSettingsData
} from '../textclassificationmodel/text.classification.model.settings';

@Component({
    selector: 'wf-text-classification-predict-settings',
    templateUrl: './text.classification.predict.settings.html',
    styleUrls: ['../textclassificationmodel/text.classification.css']
})
export class TextClassificationPredictSettings extends WorkflowDialogContent {
    Data = new TextClassificationPredictSettingsData();
    Columns = [];

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'textClassificationPredictWFModule';
        reg.Caption = '@@Text Classification Predict';
        reg.GroupID = 'aioperations';
        reg.Index = 250;
        reg.SettingsControl = TextClassificationPredictSettings;
        reg.SettingsTypeHelper = new TextClassificationPredictSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        reg.ObsoleteInfo = new ObsoleteInfo(); // GA-404
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(TextClassificationPredictSettingsData, json);
        }
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource && dataSource.Columns) {
            if (!this.Data.TextColumns) {
                this.Data.TextColumns = [];
            }
            const list = [];
            dataSource.Columns.forEach(col => {
                const val = {
                    Name: col.Name,
                    TextCol: false,
                    Length: 0
                };
                const textCol = this.Data.TextColumns.find(x => x.ColumnName === col.Name);
                if (textCol) {
                    val.TextCol = true;
                    val.Length = textCol.Length;
                }
                list.push(val);
            });
            this.Columns = list;
        }
    }

    getResult() {
        const textCols = [];
        this.Columns.forEach(col => {
            if (col.TextCol) {
                const tc = new TextClassificationColumn();
                tc.ColumnName = col.Name;
                tc.Length = col.Length;
                textCols.push(tc);
            }
        });
        this.Data.TextColumns = textCols;
        this.Data.TargetColumn = null;
        return this.Data;
    }

    maxFeatureChanged(ev) {
        if (ev && ev.value) {
            this.Data.MaxFeatures = ev.value;
        }
    }

    percentChanged(ev) {
        if (ev && ev.value) {
            this.Data.Percent = ev.value;
        }
    }

    GetExpressionProperties() {
        return [
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Textspalten'),
                Value: 'TextColumns'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@MaxFeatures'),
                Value: 'MaxFeatures'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Prozent'),
                Value: 'Percent'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@ModelKey'),
                Value: 'ModelKey'
            }
        ];
    }
}

// @dynamic
export class TextClassificationPredictSettingsData extends TextClassificationModelSettingsData {
    ModelKey: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.TextClassificationPredict.TextClassificationPredictSettingsData';
    }
}

export class TextClassificationPredictSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new TextClassificationPredictSettingsData();
    }
}
