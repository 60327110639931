import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class MailSettingsService extends RequestBase {
    private sendAttachment = new Subject<any>();
    sendAttachment$ = this.sendAttachment.asObservable();

    sendMailWithAttachment(data) {
        this.sendAttachment.next(data);
    }

    BasePath = 'api/mailsettings';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetMailSettings(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetMailSettings', this.options);
    }

    LoadMailSetting(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadMailSetting?id=' + id, this.options);
    }

    SaveMailSetting(ms): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveMailSetting', ms, this.options);
    }

    DeleteMailSetting(id): Observable<any[]> {
        return this.http.delete<any[]>(this.API_BASE_URL + this.BasePath + '/DeleteMailSetting?id=' + id, this.options);
    }

    SendMail(smr): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SendMail', smr, this.options);
    }

    // addAttachment(file) {
    //     return this.http.post(this.API_BASE_URL + 'api/workflow/attachment', file);
    // }
    addAttachment(file) {
        return this.http.post(this.API_BASE_URL + 'api/workflow/multipleattachment', file);
    }
}
