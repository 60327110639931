import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
declare var ApexCharts: any;

@Component({
    selector: 'apex-chart',
    template: ' <div #chart class="full"></div>'
})
export class ApexChartsControl implements AfterViewInit {
    apexChart;

    //#region
    OptionsValue = null;
    @Input()
    get Options() {
        return this.OptionsValue;
    }
    set Options(val) {
        this.OptionsValue = val;
      if (this.apexChart) {
        this.apexChart.updateOptions(val);
      } else {
        this.RenderChart();
      }
      this.OptionsChange.emit(val);
    }
    @Output() OptionsChange = new EventEmitter<any>();
    //#endregion

    @ViewChild('chart') chart;

  ngAfterViewInit(): void {
    this.RenderChart();
  }
  RenderChart() {
    if (this.OptionsValue && this.chart) {
      setTimeout(() => {
        this.apexChart = new ApexCharts(this.chart.nativeElement, this.OptionsValue);
        this.apexChart.render();
      }, 100);
    }
  }
}
