import { Component, ViewChild } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../workflow.dialog';
import { ABaseContainerSettingsData } from '../container.chooser';

@Component({
    selector: 'wf-execute-replace-settings',
    templateUrl: './execute.replace.settings.html'
})
export class ExecuteReplaceSettings extends ContainerWorkflowDialogContent {
    Data = new ExecuteReplaceSettingsData();
    @ViewChild('chooser') ContainerChooser;
    DBUsages = [[32]]; // DocumentStoreWrite
    VariableNodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'executeReplaceWFModule';
        reg.Caption = '@@Replace';
        reg.GroupID = 'containeroperations';
        reg.Index = 25;
        reg.SettingsControl = ExecuteReplaceSettings;
        reg.SettingsTypeHelper = new ExecuteReplaceSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ExecuteReplaceSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.ContainerTable) {
            if (this.ContainerChooser) {
                this.ContainerChooser.CheckActualFilter();
            }
            if (!this.Data.Filter || !this.Data.Filter.Filters || this.Data.Filter.Filters.length <= 0) {
                retVal.IsCorrect = false;
                if (!this.Data.UseActualParam && !this.Data.StatusKey) {
                    retVal.Error = '@@Bitte definieren Sie einen Filter und vergeben Sie einen Status-Key';
                } else {
                    retVal.Error = '@@Bitte definieren Sie einen Filter';
                }
            } else if (!this.Data.UseActualParam && !this.Data.StatusKey) {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte vergeben Sie einen Status-Key';
            }
        } else {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie eine Tabelle';
        }
        return retVal;
    }

    getResult(): any {
        if (this.Data) {
            if (this.Data.InteractiveProperties) {
                this.Data.InteractiveProperties = this.Data.InteractiveProperties.filter(x => x.Value);
            }
            if (this.Data.ContainerTable && this.ContainerChooser) {
                this.ContainerChooser.CheckActualFilter();
            }
        }
        return this.Data;
    }

    onVariableSelect(event) {
        if (event && this.Data) {
            this.Data.StatusKey = event.Caption;
        }
    }
}

export class ExecuteReplaceSettingsData extends ABaseContainerSettingsData {
    UseActualParam = false;
    StatusKey: string;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ContainerOperations.ExecuteReplace.ExecuteReplaceSettingsData';
    }
}

export class ExecuteReplaceSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ExecuteReplaceSettingsData();
    }
}
