import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogHelper, TranslateHelper } from '../../../helpers/injector.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { MessageBox, MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
declare let FontFace: any;

@Component({
    selector: 'font-settings',
    templateUrl: './font.settings.html',
    styleUrls: ['./font.settings.css']
})
export class FontSettings implements OnInit {
    Items = [];
    SelectedItem;
    HasChanges = false;
    @ViewChild('fileInput') fileInput;
    Uploading = false;
    UploadProgress = 0;
    Uploadindeterminate = false;

    FilteredItems = [];
    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterItems();
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Fonts',
            ID: 'fonts',
            Icon: 'title',
            Index: 3,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 8
            },
            Content: FontSettings
        };
    }

    constructor(private service: MediaService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    async refresh() {
        const check = await this.checkHasChanges();
        if (check) {
            this.SelectedItem = null;
            this.FilteredItems = [];
            this.Items = [];
            LayoutService.Loading.next(true);
            this.service.ListObjects(MediaService.FontSourceID, '').subscribe(Items => {
                if (Items) {
                    const list = [];
                    Items.forEach(font => {
                        list.push({
                            Name: font.Name.replace('.ttf', '')
                        });
                    });
                    this.Items = list;
                    this.FilterItems();
                    LayoutService.Loading.next(false);
                }
                this.cdRef.detectChanges();
            });
        }
    }

    setHasChanges() {
        this.HasChanges = true;
    }

    Upload(ev) {
        const icon = this.SelectedItem;
        if (icon && ev && ev.target) {
            const selectedFile = ev.target.files[0];
            if (selectedFile) {
                icon.ChangedFile = selectedFile;
                if (!icon.Name) {
                    icon.Name = selectedFile.name.replace('.ttf', '');
                }
                const fr = new FileReader();
                fr.addEventListener('load', function () {
                    icon.Preview = 'url(' + fr.result + ')';
                }, false);
                fr.readAsDataURL(selectedFile);
                this.HasChanges = true;
            }
        }
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                return this.save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                if (this.SelectedItem && !this.SelectedItem.IsNew) {
                    delete this.SelectedItem.ChangedFile;
                    delete this.SelectedItem.Preview;
                }
                this.HasChanges = false;
            }
        }
        return true;
    }

    save(): boolean {
        const icon = this.SelectedItem;
        if (icon) {
            const errorList = [];
            if (typeof icon.Name !== 'string' || icon.Name === '') {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Vergeben Sie einen Namen'));
            } else if (this.Items.some(i => i === icon.Name)) {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Name bereits vorhanden'));
            }
            if (!icon.ChangedFile) {
                errorList.push(TranslateHelper.TranslatorInstance.instant('@@Waehlen Sie eine Datei'));
            }
            if (errorList.length > 0) {
                const textVal = '@@Aenderungen konnten nicht gespeichert werden. Bitte ueberpruefen Sie folgende Einstellungen:\n{0}';
                const text = new TranslateFormatText(textVal);
                text.FormatParams.push(errorList.join('\n'));
                MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                return false;
            }
            const uploadData = new FormData();
            uploadData.append(icon.Name + '.ttf', icon.ChangedFile, icon.ChangedFile.name);
            this.service.UploadSpecific(MediaService.FontSourceID, uploadData).subscribe(event => {
                uploadData.forEach((value) => {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            this.Uploading = true;
                            break;
                        case HttpEventType.UploadProgress:
                            this.UploadProgress = Math.round(100 * event.loaded / event.total);
                            if (this.UploadProgress === 100) {
                                this.Uploadindeterminate = true;
                                this.Uploading = false;
                                this.UploadProgress = 0;
                            }
                            break;
                        case HttpEventType.Response:
                            this.Uploadindeterminate = false;
                            delete icon.ChangedFile;
                            delete icon.Preview;
                            if (icon.IsNew) {
                                delete icon.IsNew;
                                this.Items.push(icon);
                            }
                            this.cdRef.detectChanges();
                            const customFont = new FontFace(icon.Name, 'url(' + MediaService.FontURL.getValue() + icon.Name + '.ttf' + ')');

                            customFont.load().then((res) => {
                                const fonts: any = document['fonts'];
                                fonts.add(res);
                            }).catch((error) => {
                                console.log(error);
                            });
                            break;
                    }
                });
            });
            this.HasChanges = false;
        }
        return true;
    }

    addItem() {
        if (this.HasChanges) {
            const config = {
                data: {
                    Data: {
                        Message: new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                        Title: new TranslateFormatText('@@Frage'),
                        Buttons: MessageBoxButtons.YesNoAbort,
                        Icon: MessageBoxIcon.Question
                    }
                }
            };
            const dialogRef = DialogHelper.DialogInstance.open(MessageBox, config);
            dialogRef.afterClosed().subscribe(retVal => {
                if (retVal === MessageBoxResult.Yes) {
                    this.save();
                    this.OnAddNew();
                } else if (retVal === MessageBoxResult.Abort) {
                    return false;
                } else { // No
                    if (this.SelectedItem && !this.SelectedItem.IsNew) {
                        delete this.SelectedItem.ChangedFile;
                        delete this.SelectedItem.Preview;
                    }
                    this.HasChanges = false;
                    this.OnAddNew();
                }
            });
        } else {
            this.OnAddNew();
        }
    }

    OnAddNew() {
        if (this.SelectedItem) {
            this.SelectedItem.Selected = false;
        }
        this.SelectedItem = {
            Name: null,
            IsNew: true,
            Selected: true
        };
        this.HasChanges = true;
        if (this.fileInput) {
            setTimeout(() => {
                this.fileInput.nativeElement.click();
            }, 10);
        }
    }

    async deleteItem() {
        const sel = this.SelectedItem;
        if (sel) {
            const text = new TranslateFormatText('@@Are you sure you want to delete the font \'{0}\' ?');
            text.FormatParams.push(sel.Name);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.service.DeleteFile(MediaService.FontSourceID, sel.Name + '.ttf').subscribe(result => {
                    if (result) {
                        this.SelectedItem = null;
                        this.Items.splice(this.Items.indexOf(sel), 1);
                        this.FilterItems();
                        this.HasChanges = false;
                    }
                });
            }
        }
    }

    getImage() {
        const retVal = {};
        if (this.SelectedItem && this.SelectedItem.Preview) {
            retVal['background-image'] = this.SelectedItem.Preview;
        }
        return retVal;
    }

    async setSelected(icon) {
        const check = await this.checkHasChanges();
        if (check) {
            this.Items.forEach(i => { i.Selected = false; });
            icon.Selected = true;
            this.SelectedItem = icon;
        }
    }

    FilterItems() {
        if (this.SelectedItem) {
            this.SelectedItem.Selected = false;
            this.SelectedItem = null;
        }
        let val = this._SearchValue;
        if (val) {
            val = val.toLowerCase();
            this.FilteredItems = this.Items.filter(icon => {
                return icon.Name.toLowerCase().indexOf(val) >= 0;
            });
        } else {
            this.FilteredItems = this.Items;
        }
    }

    GetFont(name) {
        return {
            'font-family': name
        };
    }
}
