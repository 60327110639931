import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { UserSettingsService } from '../../../../services/usersettings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';

@Component({
    selector: 'wf-addprofile-settings',
    templateUrl: './add.profile.settings.html'
})
export class AddProfileSettings extends FormulaWorkflowDialogContent {
    Data = new AddProfileSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'addProfileModule';
        reg.Caption = '@@Add profile';
        reg.GroupID = 'profileGroup';
        reg.Index = 10;
        reg.SettingsControl = AddProfileSettings;
        reg.SettingsTypeHelper = new AddProfileSettingsDataHelper();
        reg.Executer = AddProfileModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private service: UserSettingsService) {
        super();
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(AddProfileSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data && !this.Data.ProfileName) {
            retVal.IsCorrect = false;
            retVal.Error = '@@SetNameError';
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }
}

export class AddProfileSettingsData {
    ProfileName: string;
}

export class AddProfileSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new AddProfileSettingsData();
    }
}

export class AddProfileModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const layout = status.WorkflowLayoutService.Layout;
        if (layout) {
            if (status.ActualSettings) {
                if (status.ActualSettings.ProfileName) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    const value = calculator.CalcFormula(status.ActualSettings.ProfileName);
                    const name = '' + value;
                    const service = InjectorHelper.InjectorInstance.get<UserSettingsService>(UserSettingsService);
                    status.ActualParameter = await service.CreateProfile({
                        Name: name,
                        LayoutID: layout._Id
                    }).toPromise();
                    const profileVariables = layout['ProfileVariables'];
                    if (profileVariables && profileVariables.length > 0) {
                        service.SaveValues(profileVariables, layout._Id, name);
                    }
                    return 0;
                } else {
                    status.Logger.logError('Add profile modul: No name set.');
                }
            } else {
                status.Logger.logError('Add profile modul: No settings found.');
            }
        } else {
            status.Logger.logError('Add profile modul: No layout found.');
        }
        return super.execute(status);
    }
}
