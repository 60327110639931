export const TaskRegistry = new Map<string, TaskRegistryEntry>();

export class TaskRegistryEntry {
    ID: string;
    Caption: string;
    SettingsControl;
    SettingsHelper: ATaskSettingsHelper;
    Executer;
    TaskType: TaskType;
}

export abstract class ATaskSettingsHelper {
    abstract getCaption(task, dd): string;
     canExecute(reportObject): boolean {
        return true;
    }

    async Gettooltip(task: any, dd: any): Promise<string> { return '' };       
}

export enum TaskType {
    DataTask,
    FormatTask
}
