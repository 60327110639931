import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Font } from '../../../../models/style/font.model';


@Component({
  selector: 'fontsize-action',
  templateUrl: './fontsize.action.html',
  styleUrls: ['./fontsize.action.css'],
})
export class FontSizeAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'fontsize';
    
    fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

    //#region SelectedFontSize
    @Input()
    get SelectedFontSize() {
        if (this.SelectedItemValue && this.SelectedItemValue.Font) {
            return this.SelectedItemValue.Font.FontSize;
        }
        return 0;
    }
    set SelectedFontSize(val) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Font) {
                this.SelectedItemValue.Font = new Font();
            }
            this.SelectedItemValue.Font.FontSize = val;
        }
        this.SelectedFontSizeChange.emit(val);
    }

    @Output() SelectedFontSizeChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {

    }
    ExecuteAction() {
        
    }

    Visibility() {
        return true;
    }
}
