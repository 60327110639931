import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'define-list-wf-settings',
    templateUrl: './define.list.settings.html',
    styleUrls: ['./define.list.settings.css']
})
export class DefineListSettings extends FormulaWorkflowDialogContent {
    Data = new DefineListSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'defineListWFModule';
        reg.Caption = '@@Liste definieren';
        reg.GroupID = 'generateData';
        reg.Index = 30;
        reg.SettingsControl = DefineListSettings;
        reg.SettingsTypeHelper = new DefineListSettingsDataHelper();
        reg.Executer = DefineListModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DefineListSettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Lists.forEach(x => {
            if (x.StatusKey || x.UseForState) {
                const entries = [];
                x.Entries.forEach(y => {
                    if (y.Formula) {
                        entries.push(y);
                    }
                });
                x.Entries = entries;
                values.push(x);
            }
        });
        this.Data.Lists = values;
        return this.Data;
    }

    addList() {
        if (this.Data) {
            if (this.Data.Lists) {
                this.Data.Lists.push(new DefineListEntry());
            } else {
                this.Data.Lists = [new DefineListEntry()];
            }
        }
    }

    removeList(i) {
        if (this.Data && this.Data.Lists) {
            this.Data.Lists.splice(i, 1);
        }
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Lists) {
            this.Data.Lists.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }

    addValue(val) {
        if (val) {
            if (val.Entries) {
                val.Entries.push(new DefineListValue());
            } else {
                val.Entries = [new DefineListValue()];
            }
        }
    }

    removeValue(val, i) {
        if (val && val.Entries) {
            val.Entries.splice(i, 1);
        }
    }
}

// @dynamic
export class DefineListSettingsData extends AServiceWorkflowData {
    @Type(() => DefineListEntry)
    Lists: DefineListEntry[] = [];
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineList.DefineListSettingsData';
    }
}

// @dynamic
export class DefineListEntry {
    @Type(() => DefineListValue)
    Entries: DefineListValue[] = [];
    StatusKey: string;
    UseForState = false;
    ExtendList = false;
}

export class DefineListValue {
    Formula: string;
}

export class DefineListSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new DefineListSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Lists) {
                const list = [];
                settings.Lists.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class DefineListModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Lists) {
                const calculator = new WorkflowFormulaCalculator(status);
                status.ActualSettings.Lists.forEach(x => {
                    let list = [];
                    if (x.Entries && x.Entries.length > 0) {
                        if (x.StatusKey && x.ExtendList) {
                            const stateList = status.Context.get(x.StatusKey);
                            if (Array.isArray(stateList)) {
                                list = stateList;
                            }
                        }
                        x.Entries.forEach(entry => {
                            try {
                                if (entry.Formula) {
                                    list.push(calculator.CalcFormula(entry.Formula));
                                }
                            } catch (ex) {
                                status.Logger.logWarning('DefineList modul: Error while calculating formula ' + entry.Formula + ' (' + ex + ').');
                            }
                        });
                    }
                    if (x.StatusKey) {
                        status.Context.set(x.StatusKey, list);
                    }
                    if (x.UseForState) {
                        status.ActualParameter = list;
                    }
                });
            }
            return 0;
        } else {
            status.Logger.logError('DefineList modul: No settings found.');
        }
        return super.execute(status);
    }
}
