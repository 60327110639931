import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    isDevMode,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotificationHelper} from "../../../helpers/notification.helper";
import {UsersService} from "../../../services/users.service";
import {LayoutService} from "../../../services/layout.service";

@Component({
    selector: 'app-otp-dialog',
    templateUrl: './OTP-dialog.html',
    styleUrls: ['./OTP-dialog.css']
})
export class OTPDialogComponent implements OnInit{

    form:any;
    showErrorAnimation = false;
    isCorrect = false;
    invalidOtp = false;

    remainingTime = 120; // Initial remaining time in seconds
    timerOn = true; // Flag to control the timer
    timerId: any; // Variable to hold the timer identifier
    displayTime: string;
    constructor(
        private cdRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<OTPDialogComponent>, private usersService: UsersService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.form = data.form;
    }

    ngOnInit() {
        this.startTimer();
        this.inputs.valueChanges.subscribe(() => {
            this.invalidOtp = false;
            this.isCorrect = false;
            this.onVerifyOTP();
        });
    }

    onClose(event): void {
        this.dialogRef.close(event);
    }

    // otp
    getFormArray(size: number): FormArray {
        const arr = [];

        for (let i = 0; i < size; i++) {
            arr.push(new FormControl(''));
        }

        return new FormArray(arr);
    }

    @Input()
    set size(size: number) {
        this.inputs = this.getFormArray(size);
        this.#size = size;
    }

    @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;

    #size = 6;
    #scheduledFocus: number = null;

    inputs = this.getFormArray(this.#size);

    onChange?: (value: string) => void;
    onTouched?: () => void;

    writeValue(value: string): void {
        if (isDevMode() && value?.length) {
            throw new Error('Otp input is not supposed to be prefilled with data');
        }

        // Reset all input values
        this.inputs.setValue(new Array(this.#size).fill(''));
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputs.disable();
        } else {
            this.inputs.enable();
        }
    }

    validate(control: AbstractControl<string, string>): ValidationErrors | null {
        if (!control.value || control.value.length < this.#size) {
            return {
                otpInput: 'Value is incorrect',
            };
        }

        return null;
    }

    handleKeyDown(e: KeyboardEvent, idx: number) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (idx > 0) {
                this.#scheduledFocus = idx - 1;
            }
        }
    }

    handleInput() {
        this.#updateWiredValue();

        if (this.#scheduledFocus != null) {
            this.#focusInput(this.#scheduledFocus);
            this.#scheduledFocus = null;
        }
    }

    handleKeyPress(e: KeyboardEvent, idx: number) {
        const isDigit = /\d/.test(e.key);

        if (e.key === 'v' && e.metaKey) {
            return true;
        }

        if (isDigit && idx + 1 < this.#size) {
            this.#scheduledFocus = idx + 1;
        }

        if (isDigit && this.inputs.controls[idx].value) {
            this.inputs.controls[idx].setValue('');
        }

        return isDigit;
    }

    handlePaste(e: ClipboardEvent, idx: number) {
        e.preventDefault();

        if (idx !== 0) {
            return;
        }

        const pasteData = e.clipboardData?.getData('text');
        const regex = new RegExp(`\\d{${this.#size}}`);

        if (!pasteData || !regex.test(pasteData)) {
            return;
        }

        for (let i = 0; i < pasteData.length; i++) {
            this.inputs.controls[i].setValue(pasteData[i]);
        }

        this.#focusInput(this.inputEls.length - 1);
        this.#updateWiredValue();
        this.onTouched();
    }

    handleFocus(e: FocusEvent) {
        (e.target as HTMLInputElement).select();
    }

    #focusInput(idx: number) {
        setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
    }

    #updateWiredValue() {
        setTimeout(() => this.onChange?.(this.inputs.value.join('')));
    }

    onVerifyOTP() {
        const arr = this.inputs.getRawValue();
        const OTP = arr.splice(0).join('');
        const _form = this.form;
        if (OTP && OTP.length == 6) {
            const payload = {Email: _form.email, OTP};
            this.usersService.verifyOTP(payload).subscribe({
                next: (res) => {
                    if (res['StatusCode'] == 200) {
                        this.isCorrect = true;
                        this.invalidOtp = false;
                    }
                },
                error: (err) => {
                    this.showErrorAnimation = true;
                    this.invalidOtp = true;
                    this.isCorrect = false;
                    setTimeout(() => {
                        this.showErrorAnimation = false;
                    }, 1000);
                    // const message = err.error.Message || '';
                    // NotificationHelper.Error(`@@${message}`, '@@Error');
                }
            })
        }
    }

    startTimer() {
        const timerFn = () => {
            let m:any = Math.floor(this.remainingTime / 60);
            let s:any = this.remainingTime % 60;

            // m = m < 10 ? '0' + m : m.toString();
            m = m < 10 ? + m : m.toString();
            s = s < 10 ? '0' + s : s.toString();
            this.displayTime = `${m}:${s}`;
            this.remainingTime--;

            if (this.remainingTime >= 0 && this.timerOn) {
                this.timerId = setTimeout(timerFn, 1000); // Call timer function recursively every second
            } else {
                this.isCorrect = false;
                this.invalidOtp = true;
            }
        };

        timerFn(); // Start the timer
    }

    onResendOTP() {
        const _form = this.form;
        if (_form && _form.email) {
            const payload = {Email: _form.email};
            this.loading(true);
            this.usersService.resendOTP(payload).subscribe({
                next: (res) => {
                    this.loading(false);
                    if (res['StatusCode'] == 200) {
                        const message = res['Message'] || '';
                        NotificationHelper.Success(`@@${message}`, '@@Success');
                        this.remainingTime = 120;
                        this.startTimer();
                    }
                },
                error: (err) => {
                    this.loading(false);
                    // const message = err.error.Message || '';
                    // NotificationHelper.Error(`@@${message}`, '@@Error');
                }
            })
        } else {
            NotificationHelper.Error(`@@Please enter your email address`, '@@Error');
        }
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }
}
