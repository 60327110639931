import { Component } from '@angular/core';
import { CacheService } from '../../../../cache/cache.service';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ARedisSettings } from './addtoredis.settings';

export class RemoveFromRedisSettingsData extends AServiceWorkflowData {
    Key = '';
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Redis.RemoveFromRedisSettingsData';
    }
}

export class RemoveFromRedisSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new RemoveFromRedisSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class RemoveFromRedisModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            CacheService.RemoveFromWorkflowCache(status.ActualSettings.Key);
            return 0;
        } else {
            status.Logger.logError('Add to cache modul: No settings found.');
        }
        return super.execute(status);
    }
}

@Component({
    selector: 'wf-removefromredis-settings',
    templateUrl: 'redis.settings.html'
})
export class RemoveFromRedisSettings extends ARedisSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'removeFromRedisWFModule';
        reg.Caption = '@@Aus dem Cache loeschen';
        reg.GroupID = 'wfStatus';
        reg.Index = 50;
        reg.SettingsControl = RemoveFromRedisSettings;
        reg.SettingsTypeHelper = new RemoveFromRedisSettingsDataHelper();
        reg.Executer = RemoveFromRedisModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data && data.Key) {
            if (data.Key) {
                this.ActualStatusKey = data.Key;
            }
        }
    }

    getResult(): any {
        const retVal = new RemoveFromRedisSettingsData();
        retVal.Key = this.ActualStatusKey;
        return retVal;
    }
}
