import { MethodType } from "../enums/methodtype.enum";


export class OfflineEndpoint {
    Name: string;
    Method: MethodType;
    execute(params?: any, body?: any) {

    }
}
export class OfflineEndpointCollection {
    BasePath: string;
    EndPoints: OfflineEndpoint[];
}