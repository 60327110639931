import { RequestBase } from "./request-base";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class RelSourceService extends RequestBase {
  BasePath = "api/relSource";

  GetRelContainer(query): Observable<any> {
    return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetRelContainer', query, this.options);
  }

  GetAllContainerTypes(dataModelID :string): Observable<any> {
    return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllContainerTypes?dataModelID=' + dataModelID, this.options);
  }
}
