import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowWizardService } from '../../../services/workflow.wizard.service';
import { WorkflowFormulaCalculator } from '../../../workflow/modules/definevalue/define.value.settings';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';
import { ShowWizardDialog } from './show.wizard.dialog';

@Component({
    selector: 'show-wizard-wf-settings',
    templateUrl: './show.wizard.settings.html'
})
export class ShowWizardSettings extends FormulaWorkflowDialogContent {
    Data = new ShowWizardSettingsData();
    Wizards = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'showWizardWFModule';
        reg.Caption = '@@Wizard anzeigen';
        reg.GroupID = 'wfActions';
        reg.Index = 120;
        reg.SettingsControl = ShowWizardSettings;
        reg.SettingsTypeHelper = new ShowWizardSettingsDataHelper();
        reg.Executer = ShowWizardModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private service: WorkflowWizardService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetAllWizards().subscribe(result => {
            if (result) {
                this.Wizards = result;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ShowWizardSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class ShowWizardSettingsData {
    Title: string;
    WizardID: string;
}

export class ShowWizardSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ShowWizardSettingsData();
    }
}

export class ShowWizardModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let title = 'Wizard';
            if (status.ActualSettings.Title) {
                try {
                    const calculator = new WorkflowFormulaCalculator(status);
                    title = calculator.CalcFormula(status.ActualSettings.Title);
                } catch (e) {
                    const t = 'Dialog modul: Error while calculating title \'' +
                        status.ActualSettings.Title + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            await ShowWizardDialog.ShowAsync(title, status.ActualSettings.WizardID);
            return 0;
        } else {
            status.Logger.logError('Show wizard modul: No settings found.');
        }
        return super.execute(status);
    }
}
