import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CellReferenceLevel, CellReferenceArea, CellReference } from '../../../models/planning/cell.reference.model';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { MultiReportingService } from '../../../services/reporting.service';

@Component({
    selector: 'reference-overview',
    templateUrl: './reference.overview.component.html'
})
export class ReferenceOverviewComponent {
    //#region References
    ReferencesValue = [];
    @Input()
    get References() {
        return this.ReferencesValue;
    }
    set References(val) {
        if (val !== this.ReferencesValue) {
            this.ReferencesValue = val;
            this.ReferencesChange.emit(this.ReferencesValue);
        }
    }
    @Output() ReferencesChange = new EventEmitter<any>();
    //#endregion
    //#region Data
    DataValue = [];
    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val !== this.DataValue) {
            this.DataValue = val;
            this.DataChange.emit(this.DataValue);
        }
    }
    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    SelectedData;
    Description;
    Members;
    constructor(private multiService: MultiReportingService) {
    }

    SelectedReference = null;
    AddReference() {
        var reference = new CellReference();
        reference.Name = '@@NewReference';
        this.References.push(reference);
        this.SelectedReference = reference;
        this.SetSelectedData();
    }

    RemoveRefernce() {
        this.References.splice(this.References.indexOf(this.SelectedReference), 1);
        this.SelectedReference = null;
        this.SelectedData = null;
    }
    SelectReference(ref) {
        this.SelectedReference = ref;
        this.SetSelectedData();
    }

    async SetSelectedData() {
        this.Description = {
            X: [],
            Y: []
        }
        this.SelectedData = null;
        if (this.SelectedReference.DataDescriptionID) {
            this.SelectedData = this.Data.find((value) => { return this.SelectedReference.DataDescriptionID == value.ID });
            if (this.SelectedData.IsRelational) {

            } else {
                if (!this.SelectedReference.X) {
                    this.SelectedReference.X = {};
                }
                if (!this.SelectedReference.Y) {
                    this.SelectedReference.Y = {};
                }
                this.FillAxis(this.SelectedData.Datadescription.XLevelNodes, this.Description.X, this.SelectedReference.X);
                this.FillAxis(this.SelectedData.Datadescription.YLevelNodes, this.Description.Y, this.SelectedReference.Y);
            }
        }
    }

    DataDescriptionSelected() {
        this.SelectedReference.X = {};
        this.SelectedReference.Y = {};
        this.SetSelectedData();
    }

    FillAxis(Node, Axis, ReferenceAxis) {
        if (!ReferenceAxis) {
            ReferenceAxis = {};
        }
        for (let i = 0; i < Node.Areas.length; i++) {
            let area = {
                Caption: 'Area ' + i,
                ID: i + '',
                Levels: [],
                Measures: []
            }
            if (!ReferenceAxis[area.ID]) {
                let refArea = new CellReferenceArea();
                refArea.AreaID = area.ID;
                refArea.Levels = {};
                if (i == 0) {
                    refArea.Selected = true;
                }
                ReferenceAxis[area.ID] = refArea;
            }
            let ll = {};
            Node.Areas[i].Tuples.forEach((tuple) => {
                tuple.Levels.forEach(async (level) => {
                    let lev = {
                        Caption: level.Caption.DefaultValue,
                        Value: level.UniqueID,
                        ID: level.Level,
                        Members: []
                    }
                    if (!ReferenceAxis[area.ID].Levels[lev.ID]) {
                        let refLevel = new CellReferenceLevel();
                        refLevel.LevelID = lev.Value;
                        ll[lev.ID] = refLevel;
                    } else {
                        ll[lev.ID] = ReferenceAxis[area.ID].Levels[lev.ID];
                    }
                    const cubeLevel = await MultiCacheService.GetLevel(level.Level, this.SelectedData.DataModelID);
                    if (cubeLevel) {
                        this.multiService.GetMembers(cubeLevel.Parent.Parent.DatasourceID, level.Level, null, null).subscribe(members => {
                            const memberList = [];
                            if (members) {
                                members.forEach(m => {
                                    memberList.push({
                                        Caption: m.Caption,
                                        Value: m.ID
                                    });
                                });
                            }
                            if (!this.Members) {
                                this.Members = {};
                            }
                            this.Members[level.Level] = memberList;
                        });
                    }
                    area.Levels.push(lev);
                });
            })
            ReferenceAxis[area.ID].Levels = ll;
            Node.Areas[i].Measures.forEach((measure) => {
                area.Measures.push({
                    Caption: measure.Caption.DefaultValue,
                    Value: measure.UniqueID,
                    ID: measure.Measure
                })
            })
            Axis.push(area);
        }
    }

    GetLevels(Nodes) {
        let retVal = [];
        Nodes.Areas.forEach((area) => {
            area.Tuples.forEach((tuple) => {
                tuple.Levels.forEach((level) => {
                    retVal.push(Object.assign({}, level));
                });
            });
        });
        return retVal;
    }
    GetMeasures(Nodes) {
        let retVal = [];
        Nodes.Areas.forEach((area) => {
            area.Measures.forEach((measure) => {
                retVal.push(Object.assign({}, measure));
            });
        });
        return retVal;
    }
}
