import { Component, OnInit } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataModelService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { GenerateReaderSettingsData } from '../../dataoperations/generatereader/generate.reader.settings';
import { RelToObjectColumnMapping } from '../../dataoperations/reltoobject/reltoobject.data';

@Component({
    selector: 'wf-objecttorel-settings',
    templateUrl: './objecttorel.settings.html',
    styleUrls: ['./objecttorel.settings.css']
})
export class ObjectToRelSettings extends ContainerWorkflowDialogContent implements OnInit {

    Data = new ObjectToRelSettingsData();
    TableData = new GenerateReaderSettingsData();
    DBUsages = [[8192, 512]]; // RelationalWrite + DataSourceWrite
    ActColumns = [];
    Properties = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'objecttorelWFModule';
        reg.Caption = '@@Zu Datenzeile konvertieren';
        reg.GroupID = 'objectoperations';
        reg.Index = 30;
        reg.SettingsControl = ObjectToRelSettings;
        reg.SettingsTypeHelper = new ObjectToRelSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    private static SetMappings(mappings: RelToObjectColumnMapping[], properties) {
        properties.forEach(p => {
            const map = mappings.find(x => x.Source === p.Name && x.IsList === p.IsList && x.IsObject === p.IsObject);
            let childMappings = [];
            if (map) {
                p.Target = map.Target;
                p.IsCommaSeparated = map.IsCommaSeparated;
                p.Separator = map.Separator;
                if (p.IsObject && map.ObjectMappings) {
                    childMappings = map.ObjectMappings;
                }
            } else {
                p.IsCommaSeparated = true;
                p.Separator = ',';
            }
            if (p.IsObject) {
                ObjectToRelSettings.SetMappings(childMappings, p.ObjectProperties);
            }
        });
    }

    private static GetMappings(properties): RelToObjectColumnMapping[] {
        const retVal = [];
        properties.forEach(p => {
            if (p.IsList && p.IsCommaSeparated) {
                if (p.Separator && p.Target) {
                    const map = new RelToObjectColumnMapping();
                    map.Source = p.Name;
                    map.Target = p.Target;
                    map.IsList = true;
                    map.IsCommaSeparated = true;
                    map.Separator = p.Separator;
                    retVal.push(map);
                }
            } else {
                if (p.IsObject) {
                    const childMappings = ObjectToRelSettings.GetMappings(p.ObjectProperties);
                    if (childMappings.length > 0) {
                        const map = new RelToObjectColumnMapping();
                        map.Source = p.Name;
                        map.IsList = p.IsList;
                        map.IsCommaSeparated = false;
                        map.IsObject = true;
                        map.ObjectMappings = childMappings;
                        retVal.push(map);
                    }
                } else if (p.Target) {
                    const map = new RelToObjectColumnMapping();
                    map.Source = p.Name;
                    map.Target = p.Target;
                    map.IsList = p.IsList;
                    map.IsCommaSeparated = false;
                    retVal.push(map);
                }
            }
        });
        return retVal;
    }

    private static CheckFields(properties, fields) {
        properties.forEach(p => {
            if (p.Target) {
                if (!fields.some(x => x.Name === p.Target)) {
                    p.Target = null;
                }
            }
            if (p.IsObject) {
                ObjectToRelSettings.CheckFields(p.ObjectProperties, fields);
            }
        });
    }

    constructor(private dataService: DataModelService) {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        super.initialize(data);
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            const json = JSON.stringify(dsInfo.Properties);
            this.Properties = JSON.parse(json);
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ObjectToRelSettingsData, json);
            ObjectToRelSettings.SetMappings(this.Data.Mappings, this.Properties);
            this.TableData.DataModel = this.Data.DataModel;
            this.TableData.DataSource = this.Data.DataSource;
            this.TableData.ContainerTable = this.Data.ExistingStructure;
            this.TableData.ContainerInternalID = this.Data.ContainerInternalID;
        }
    }

    getResult(): any {
        this.Data.Mappings = ObjectToRelSettings.GetMappings(this.Properties);
        this.Data.DataModel = this.TableData.DataModel;
        this.Data.DataSource = this.TableData.DataSource;
        this.Data.ExistingStructure = this.TableData.ContainerTable;
        this.Data.ContainerInternalID = this.TableData.ContainerInternalID;
        return this.Data;
    }

    useTableChanged() {
        if (this.Data.UseTable) {
            ObjectToRelSettings.CheckFields(this.Properties, this.ActColumns);
        }
    }

    onDataChanged() {
        this.ActColumns = [];
        if (this.Data.ExistingStructure) {
            this.dataService.GetContainer(this.Data.ExistingStructure).subscribe(result => {
                if (result) {
                    this.ActColumns = result.Fields;
                    ObjectToRelSettings.CheckFields(this.Properties, this.ActColumns);
                }
            });
        }
    }
}

// @dynamic
export class ObjectToRelSettingsData extends AServiceWorkflowData {
    UseTable = true;
    DataModel: string;
    DataSource: string;
    ExistingStructure: string;
    ContainerInternalID: string;
    @Type(() => RelToObjectColumnMapping)
    Mappings: RelToObjectColumnMapping[] = [];
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.ObjectToRel.ObjectToRelSettingsData';
    }
}


export class ObjectToRelSettingsDataHelper extends WorkflowModuleSettingsHelper {
    private static FillColumns(mappings: RelToObjectColumnMapping[], infos) {
        const retVal = [];
        if (mappings) {
            mappings.forEach(m => {
                if (m.IsList) {
                    if (m.IsCommaSeparated) {
                        if (m.Separator && m.Target) {
                            retVal.push({
                                Name: m.Target,
                                DataTyp: 'System.String'
                            });
                        }
                        return;
                    }
                }
                const prop = infos.find(x => x.Name === m.Source);
                if (m.IsObject) {
                    let childProps = [];
                    if (prop && prop.ObjectProperties) {
                        childProps = prop.ObjectProperties;
                    }
                    const childResult = ObjectToRelSettingsDataHelper.FillColumns(m.ObjectMappings, childProps);
                    retVal.push(...childResult);
                } else if (m.Target) {
                    const col = {
                        Name: m.Target,
                        DataTyp: 'System.String'
                    };
                    if (prop && prop.SimpleType) {
                        col.DataTyp = prop.SimpleType;
                    }
                    retVal.push(col);
                }
            });
        }
        return retVal;
    }
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        return [def];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'objData';
        return [def];
    }
    getEmptySettingsInstance() {
        return new ObjectToRelSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                let props = [];
                const ds = state.get('DataSource');
                if (ds && ds.Properties) {
                    props = ds.Properties;
                }
                const columns = ObjectToRelSettingsDataHelper.FillColumns(settings.Mappings, props);
                state.set('DataSource', { Columns: columns, DataModel: settings.DataModel });
            }
        }
    }
}
