import { WorkflowDialogContent } from '../../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowModuleSettingsHelper, WorkflowRegistry, WorkflowExitInfo } from '../../../../services/workflow.service';
import { Type, serialize, deserialize } from 'class-transformer';
import { PropertySetting } from '../../readProperty/read.multi.property.settings';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';

@Component({
    selector: 'wf-get-trigger-context-settings',
    templateUrl: './get.trigger.context.settings.html'
})
export class GetTriggerContextSettings extends WorkflowDialogContent {
    TriggerType = '30ecfa51-1f4d-4bb8-be7d-2ae2b63d21d3';
    TriggerProps = {
        '30ecfa51-1f4d-4bb8-be7d-2ae2b63d21d3': [
            { Name: 'FromName', Caption: '@@Absendername', StatusKey: '', UseForState: false },
            { Name: 'FromMail', Caption: '@@Absenderemail', StatusKey: '', UseForState: false },
            { Name: 'ToNames', Caption: '@@Empfaengernamen (Liste)', StatusKey: '', UseForState: false },
            { Name: 'ToMails', Caption: '@@Empfaengeradressen (Liste)', StatusKey: '', UseForState: false },
            { Name: 'CcNames', Caption: '@@Sekundaere Empfaengernamen (Liste)', StatusKey: '', UseForState: false },
            { Name: 'CcMails', Caption: '@@Sekundaere Empfaengeradressen (Liste)', StatusKey: '', UseForState: false },
            { Name: 'Subject', Caption: '@@Betreff', StatusKey: '', UseForState: false },
            { Name: 'Body', Caption: '@@Inhalt', StatusKey: '', UseForState: false },
            { Name: 'ReceiveDate', Caption: '@@Empfangsdatum', StatusKey: '', UseForState: false }
        ],
        'd50611a1-fcfa-4ed3-b556-fdfd0b5894e3': [
            { Name: 'FileName', Caption: '@@Dateiname', StatusKey: '', UseForState: false },
            { Name: 'FileBytes', Caption: '@@Inhalt', StatusKey: '', UseForState: false }
        ],
        '5873b3d5-0961-402e-ab06-eaf8d9e0e0be': [
            { Name: 'FileName', Caption: '@@Dateiname', StatusKey: '', UseForState: false },
            { Name: 'FileBytes', Caption: '@@Inhalt', StatusKey: '', UseForState: false }
        ],
        'cb954673-c9b4-4e13-9751-7aa2c3b9394b': [
            { Name: 'Content', Caption: '@@Inhalt', StatusKey: '', UseForState: false },
            { Name: 'DataModelID', Caption: '@@DataModelID', StatusKey: '', UseForState: false },
            { Name: 'DataModelName', Caption: '@@DataModelName', StatusKey: '', UseForState: false },
            { Name: 'ID', Caption: '@@WebHookID', StatusKey: '', UseForState: false },
            { Name: 'DataSourceRestID', Caption: '@@DataSourceID', StatusKey: '', UseForState: false }
        ],
        'c8a46062-73ed-4d1a-9bf1-1e96b77857b8': [
            { Name: 'Id', Caption: '@@Id', StatusKey: '', UseForState: false },
            { Name: 'Version', Caption: '@@Version', StatusKey: '', UseForState: false },
        ]
    };
    MailSettings = new TriggerMailSettings();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getTriggerContextWFModule';
        reg.Caption = '@@Trigger Kontext auslesen';
        reg.GroupID = 'generateData';
        reg.Index = 90;
        reg.SettingsControl = GetTriggerContextSettings;
        reg.SettingsTypeHelper = new GetTriggerContextSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(translate: TranslateService) {
        super();
        this.MailSettings.FileNameText = translate.instant('@@Dateiname');
        this.MailSettings.FileBytesText = translate.instant('@@Inhalt');
    }

    initialize(data: any) {
        if (data) {
            if (data.TriggerType) {
                this.TriggerType = data.TriggerType;
            }
            if (data.Properties) {
                const props = this.TriggerProps[this.TriggerType];
                data.Properties.forEach(x => {
                    const prop = props.find(y => y.Name === x.PropName);
                    if (prop) {
                        prop.StatusKey = x.StatusKey;
                        prop.UseForState = x.UseForState;
                    }
                });
            }
            if (data.MailSettings) {
                const json = serialize(data.MailSettings);
                this.MailSettings = deserialize(TriggerMailSettings, json);
            }
        }
    }

    getResult(): any {
        const retVal = new GetTriggerContextSettingsData();
        retVal.TriggerType = this.TriggerType;
        this.TriggerProps[this.TriggerType].forEach(x => {
            if (x.StatusKey || x.UseForState) {
                retVal.Properties.push({
                    PropName: x.Name,
                    StatusKey: x.StatusKey,
                    UseForState: x.UseForState
                });
            }
        });
        if (retVal.TriggerType === '30ecfa51-1f4d-4bb8-be7d-2ae2b63d21d3' &&
            (this.MailSettings.StatusKey || this.MailSettings.UseForState)) {
            retVal.MailSettings = this.MailSettings;
        }
        return retVal;
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked) {
            this.TriggerProps[this.TriggerType].forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
            this.MailSettings.UseForState = false;
        }
    }

    useForStateMailChecked(ev) {
        if (ev && ev.checked) {
            this.TriggerProps[this.TriggerType].forEach(x => {
                x.UseForState = false;
            });
        }
    }

    nameContainsChange(ev) {
        if (ev) {
            this.MailSettings.NameContains = ev.value === 1;
        }
    }
}

export class TriggerMailSettings {
    StatusKey: string;
    UseForState = false;
    FileNameText: string;
    FileBytesText: string;
    IndexText: string;
    NameContains = true;
    SearchText: string;
    FileEnding: string;
}

// @dynamic
export class GetTriggerContextSettingsData extends AServiceWorkflowData {
    TriggerType: string;
    @Type(() => PropertySetting)
    Properties: PropertySetting[] = [];
    @Type(() => TriggerMailSettings)
    MailSettings: TriggerMailSettings;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GetTriggerContext.GetTriggerContextSettingsData';
    }
}

export class GetTriggerContextSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetTriggerContextSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                const list = [];
                if (settings.Properties) {
                    settings.Properties.forEach(p => {
                        if (p.StatusKey) {
                            list.push(p.StatusKey);
                        }
                    });
                }
                if (settings.TriggerType === '30ecfa51-1f4d-4bb8-be7d-2ae2b63d21d3' &&
                    settings.MailSettings && settings.MailSettings.StatusKey) {
                    list.push(settings.MailSettings.StatusKey);
                }
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}
