import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { SideNav } from '../../../models/controls/sidenav.model';
import { SideNavMode } from '../../../models/enums/sidenavmode.enum';
import { SideNavPosition } from '../../../models/enums/sidenavposition.enum';
import { LayoutService } from '../../../services/layout.service';
import { SideNavService } from '../../../services/sidenav.service';
import { ABaseTreeNode, IDKeeper } from '../../common/basetreecontrol/base.tree.control';
import { Router } from '@angular/router';

@Component({
    selector: 'sidenav',
    templateUrl: './sidenav.layout.html',
    styleUrls: ['./sidenav.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavLayout implements OnInit, OnDestroy {
    opened: boolean;
    Subscriptions = {};
    RootNodes = [];
    Mode = SideNavMode[SideNavMode.over];
    Position = SideNavPosition[SideNavPosition.start];
    Style = {};
    @Input() shouldHideOverflow: boolean;
    private static BuildNodes(list, idKeeper: IDKeeper) {
        const retVal = [];
        if (list) {
            list.forEach(x => {
                if (!x.InVisible) {
                    const node = new NaviNode(idKeeper.NextID);
                    node.Caption = x.Label;
                    node.ToolTip = x.Label;
                    node.Icon = x.Icon;
                    node.Url = x.Url;
                    node.NodeId = x.Id;

                    const childNodes = SideNavLayout.BuildNodes(x.children, idKeeper);
                    if (childNodes && childNodes.length > 0) {
                        node.HasChildren = true;
                        node.Children = childNodes;
                    }

                    retVal.push(node);
                }
            });
        }
        return retVal;
    }

    constructor(private router: Router, public cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        //#region Handle Subscriptions
        this.Subscriptions['Open'] = SideNavService.Open.subscribe(() => { this.opened = true; this.cdRef.detectChanges(); });
        this.Subscriptions['Close'] = SideNavService.Close.subscribe(() => { this.opened = false; this.cdRef.detectChanges(); });
        this.Subscriptions['Toggle'] = SideNavService.Toggle.subscribe(() => { this.opened = !this.opened; this.cdRef.detectChanges(); });
        this.Subscriptions['SelectedNavigation'] = SideNavService.SelectedNavigation.subscribe((nav) => {
            this.opened = false;
            if (nav) {
                this.RootNodes = SideNavLayout.BuildNodes(nav.NavigationItems, new IDKeeper());
            } else {
                this.RootNodes = [];
            }
            this.cdRef.detectChanges();
        });
        this.Subscriptions['BaseLayout'] = LayoutService.BaseLayout.subscribe((layout) => {
            if (layout) {
                let sideNav = layout['SideNav'];
                if (!sideNav) {
                    sideNav = new SideNav();
                }
                this.Mode = SideNavMode[sideNav.Mode];
                this.Position = SideNavPosition[sideNav.Position];
                const style = {};
                if (sideNav.Width && sideNav.Width.Value) {
                    style['width'] = LayoutUnit.ConvertFrom(sideNav.Width);
                }
                if (sideNav.Height && sideNav.Height.Value) {
                    style['height'] = LayoutUnit.ConvertFrom(sideNav.Height);
                }
                this.Style = style;
            }
            this.cdRef.detectChanges();
        });
        //#endregion
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            if (this.Subscriptions[key].unsubscribe) {
                this.Subscriptions[key].unsubscribe();
            }
        });
    }

    NoContext(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
    }

    nodeClicked(ev) {
        if (ev && ev.length > 0) {
            const first = ev[0];
            if (first.Url) {
                this.router.navigateByUrl(first.Url);
                SideNavService.Close.next(null);
            }
        }
    }
}

export class NaviNode extends ABaseTreeNode {
    NodeId: string;
    Url: string;
}
