import { OfflineEndpointCollection } from '../models/offline/offline.model';
import { CapsuleEndpoints } from '../offline/endpoints/capsule.endpoints';
import { DynamicEndpoints } from '../offline/endpoints/dynamic.endpoints';
import { DynamicDataEndpoints } from '../offline/endpoints/dynamicdata.endpoints';
import { GlobalVariableEndpoints } from '../offline/endpoints/globalvariable.endpoints';
import { LanguageEndpoints } from '../offline/endpoints/language.endpoints';
import { MediaEndpoints } from '../offline/endpoints/media.endpoints';
import { MetaEndpoints } from '../offline/endpoints/meta.endpoints';
import { PermissionsEndpoints } from '../offline/endpoints/permissions.endpoints';
import { RolesEndpoints } from '../offline/endpoints/roles.endpoints';
import { SideNavEndpoints } from '../offline/endpoints/sidenav.endpoints';
import { TranslationsEndpoints } from '../offline/endpoints/translation.endpoints';
import { UserSettingsEndpoints } from '../offline/endpoints/usersettings.endpoints';

export const APP_OFFLINE_SERVICES = [
    new MetaEndpoints(),
    new MediaEndpoints(),
    new SideNavEndpoints(),
    new UserSettingsEndpoints(),
    new PermissionsEndpoints(),
    new DynamicEndpoints(),
    new DynamicDataEndpoints(),
    new RolesEndpoints(),
    new TranslationsEndpoints(),
    new LanguageEndpoints(),
    new GlobalVariableEndpoints(),
    new CapsuleEndpoints()
]


export class ServiceRegistryHelper {
    public static SERVICEREGISTRY = {};
    public static EXTERNAL_OFFLINE_SERVICES: OfflineEndpointCollection[] = [];
    public static Build() {
        APP_OFFLINE_SERVICES.forEach((service: OfflineEndpointCollection) => {
            const BasePath = service.BasePath;
            service.EndPoints.forEach((endpoint) => {
                this.SERVICEREGISTRY[BasePath + endpoint.Name + ":" + endpoint.Method] = endpoint;
            });  
        });
        this.EXTERNAL_OFFLINE_SERVICES.forEach((service: OfflineEndpointCollection) => {
            const BasePath = service.BasePath;
            service.EndPoints.forEach((endpoint) => {
                this.SERVICEREGISTRY[BasePath + endpoint.Name + ":" + endpoint.Method] = endpoint;
            });
        });
    }
}