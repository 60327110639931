import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';
import { SetGlobalVariableSettings, SetGlobalVariableSettingsData, SetGlobalVariableSettingsDataHelper, VariableEntry } from './setGlobalVariable.settings';

@Component({
    selector: 'wf-setMultiGlobalVariable-settings',
    templateUrl: './set.multi.global.variable.settings.html'
})
export class SetMultiGlobalVariableSettings extends FormulaWorkflowDialogContent {
    Data = new SetGlobalVariableSettingsData();
    GlobalVariables = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetGlobalVariableSettings.MultiModuleID;
        reg.Caption = '@@Globale Variablen setzen';
        reg.SettingsControl = SetMultiGlobalVariableSettings;
        reg.SettingsTypeHelper = new SetGlobalVariableSettingsDataHelper();
        reg.Executer = SetMultiGlobalVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetGlobalVariableSettingsData, json);
        }
        CacheService.ReadGlobalVariables().then((vars) => {
            if (vars) {
                const variableList = [];
                vars.forEach(x => {
                    const varObj = {
                        Caption: x.Name,
                        Name: x.Name
                    };
                    const dt = VariableHelper.GetTypeCaption(x.Type);
                    if (dt) {
                        varObj.Caption = x.Name + ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ')';
                    }
                    variableList.push(varObj);
                });
                this.GlobalVariables = variableList;
            }
        });
    }

    getResult(): any {
        const variables = [];
        this.Data.Variables.forEach((v) => {
            if (v.Name && (v.UseActualParam || v.Value)) {
                variables.push(v);
            }
        });
        this.Data.Variables = variables;
        return this.Data;
    }

    add() {
        if (this.Data) {
            if (this.Data.Variables) {
                this.Data.Variables.push(new VariableEntry());
            } else {
                this.Data.Variables = [new VariableEntry()];
            }
        }
    }

    delete(i) {
        if (this.Data && this.Data.Variables) {
            this.Data.Variables.splice(i, 1);
        }
    }
}

export class SetMultiGlobalVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variables && status.ActualSettings.Variables.length > 0) {
                const global: any = await CacheService.RefreshGlobalVariableValues();
                if (global) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    let success = true;
                    status.ActualSettings.Variables.some(v => {
                        let val;
                        if (v.UseActualParam) {
                            val = status.ActualParameter;
                        } else {
                            try {
                                val = calculator.CalcFormula(v.Value);
                            } catch (ex) {
                                success = false;
                                status.Logger.logError('SetGlobalVariable modul: Error while calculating formula ' +
                                    v.Value + ' for variable ' + v.Name + ' (' + ex + ').');
                                return true;
                            }
                        }
                        let nameFound = false;
                        let correctType = false;
                        global.some((g) => {
                            if (v.Name === g.Name) {
                                nameFound = true;
                                if (VariableHelper.CheckValueForType(g.Type, val)) {
                                    correctType = true;
                                    g.Value = val;
                                    return true;
                                }
                            }
                            return false;
                        });
                        if (nameFound) {
                            if (!correctType) {
                                success = false;
                                status.Logger.logError('SetGlobalVariable module: Types don\'t match for variable \'' + v.Name + '\'.');
                                return true;
                            }
                        } else {
                            success = false;
                            status.Logger.logError('SetGlobalVariable module: Variable \'' + v.Name + '\' not found.');
                            return true;
                        }
                        return false;
                    });
                    CacheService.GlobalVariablesValues.next(global);
                    if (success) {
                        return 0;
                    }
                } else {
                    status.Logger.logError('SetGlobalVariable module: No Global Variables found.');
                }
            }
        } else {
            status.Logger.logError('SetGlobalVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
