import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { SettingsService } from "../../../../../services/settings.service";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'dropbox-panel',
    templateUrl: './dropbox.panel.html',
    styleUrls: ['./dropbox.panel.css']
})
export class DrobBoxPanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = DrobBoxPanel;
    }

    get RedirectUrl(): string {
        var ret = SettingsService.API_BASE_URL.getValue() + 'config/api/media/oauth/authorizedropbox';
        this.MediaSource.RedirectUrl = ret;
        this.MediaSource.Private = true; 
        return ret;
    }

    set RedirectUrl(val) {
        if (val != this.MediaSource.RedirectUrl) {
            this.MediaSource.RedirectUrl = val;
        }
    }

    authenticate(): void {
        var authstring = 'https://www.dropbox.com/oauth2/authorize?client_id=' + this.MediaSource.ClientId + '&token_access_type=offline&response_type=code&redirect_uri=' + this.MediaSource.RedirectUrl + '&state=' + this.MediaSource.SID;
        window.open(authstring, '_blank');
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: DrobBoxPanel,
            MediaType: '4B1291FC-A6E4-458F-B00E-0E1BE71EB90F'.toLowerCase()
        };
    }
}