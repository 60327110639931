import { RequestFilter } from './requestfilter';
import { RequestSort } from './requestsort';
import { RequestColumn } from './requestcolumn';

export class RequestOptions {
	Filters: Array<RequestFilter> = [];
	Sort: Array<RequestSort> = [];
	Columns: Array<RequestColumn> = [];
	StartRow = 0;
	EndRow = 300;
  GroupBy?: Array<string> = null;
  Aggregations?: Array<any> = [];
	GroupByDate?: { Field: string, GroupingLevel:string } = null;
	static CleanRequestOptions(): RequestOptions {
		const retVal = new RequestOptions();
		retVal.StartRow = null;
		retVal.EndRow = null;
		return retVal;
	}
}
