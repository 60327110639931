import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface DialogSubject {
  isShow: boolean;
  action: 'application' | 'workflow' | 'both';
}

@Injectable({
  providedIn: 'root',
})
export class CreateDialogFlagService {
  private dialogSubject = new BehaviorSubject<DialogSubject>({ isShow: false, action: 'both' });
  showCreateDialog$ = this.dialogSubject.asObservable();

  setShowCreateDialog(value: DialogSubject) {
    this.dialogSubject.next(value);
  }
}
