import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { of } from 'rxjs';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { Overflow } from '../../../models/enums/overflow.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { UnitType } from '../../../models/enums/unittype.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { MultiPropertyChangeValue } from '../../../models/layout/layout.change.model';
import { LayoutBase } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-grid',
    templateUrl: './grid.layout.html',
    styleUrls: ['./grid.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridLayout extends IBaseComponent {

    static Type: any = 'grid';
    static Default = {
        Editable: true,
        Type: 'grid'
    };

    ItemsValue;
    get Items() {
        return this.ItemsValue;
    }
    set Items(val) {
        this.ItemsValue = of(val);
    }
    dragType;
    dragItem;
    EditItems;
    internalDrag = false;

    Dragging;

    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    onLayoutElementSet() {
        if (this.LayoutElement.ViewType === ViewType.Edit) {
            this.InitStyles();
        }
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();

    }
    onLayoutElementChanged() {
        if (this.LayoutElement.ViewType === ViewType.Edit) {
            this.InitStyles();
        }
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();
    }
    ControlInitialized() {
        let init = false;
        if (!Array.isArray(this.LayoutElement.ColumnDefinitions) || this.LayoutElement.ColumnDefinitions.length === 0) {
            const col = new LayoutBase();
            col.Size = new LayoutUnit();
            col.Size.Value = 1;
            col.Size.Type = UnitType.fr;
            this.LayoutElement.ColumnDefinitions = [col];
            init = true;
        }
        if (!Array.isArray(this.LayoutElement.RowDefinitions) || this.LayoutElement.RowDefinitions.length === 0) {
            const row = new LayoutBase();
            row.Size = new LayoutUnit();
            row.Size.Value = 1;
            row.Size.Type = UnitType.fr;
            this.LayoutElement.RowDefinitions = [row];
            init = true;
        }
        if (init) {
            this.LayoutElement.ValuesChanged.next();
        }

        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            if (this.LayoutElement.Selected) {
                this.dragItem = item.item;
                this.Dragging = true;
            }
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
        this.Subscriptions['viewtype'] = LayoutService.ViewType.subscribe((viewType) => {
            this.LayoutElement.ViewType = viewType;
            if (viewType === ViewType.Edit) {
                this.createEditItems();
            } else {
                this.EditItems = of([]);
            }
            this.cdRef.detectChanges();
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
    }


    //#region DragHandling
    itemDrop(event, item) {
        let insert;
        if (this.internalDrag) {
            insert = this.dragItem;
            this.dragItem.Column = item.Column;
            this.dragItem.Row = item.Row;
            this.dragItem.ColSpan = 1;
            this.dragItem.RowSpan = 1;
        } else {
            insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
            insert.Column = item.Column;
            insert.Row = item.Row;
            insert.ColSpan = 1;
            insert.RowSpan = 1;
            insert.Parent = this.LayoutElement;
            this.LayoutElement.Elements.push(insert);
        }
        this.dragItem = null;
        this.internalDrag = false;
        this.Dragging = null;
        this.LayoutElement.ValuesChanged.next();
        if (this.internalDrag) {
            LayoutService.OnLayoutElementMoved({
                ElementID: insert.ID,
                Index: this.LayoutElementValue.Elements.indexOf(insert),
                NewParentID: this.LayoutElementValue.ID,
                OldParentID: this.LayoutElementValue.ID,
            });
        } else {
            LayoutService.OnLayoutElementAdded({
                ElementContent: JSON.stringify(insert),
                Index: this.LayoutElementValue.Elements.length - 1,
                ParentID: this.LayoutElementValue.ID
            });
        }
        this.cdRef.detectChanges();
    }
    dragStart(event, item) {
        this.dragItem = item;
        let col = item.Column;
        if (item.ColSpan) {
            col += item.ColSpan - 1;
        }
        this.Dragging = { Column: col, Row: item.Row };
        this.internalDrag = true;
    }
    dragEnd(event, item) {
        delete item.Dragging;
        this.internalDrag = false;
        this.Dragging = null;
    }
    onDragEnter(event, item) {
        event.currentTarget.style.border = '2px solid var(--appbuilder-primary-color)';
    }
    onDragLeave(event, item) {
        event.currentTarget.style.border = '1px solid black';
    }
    //#endregion
    removeItem(item) {
        const index = this.LayoutElementValue.Elements.indexOf(item);
        if (index > -1) {
            this.LayoutElementValue.Elements.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
            this.cdRef.detectChanges();
        }
    }
    itemSelected(item, event) {
        if (!item.Selected && !this.FromBaseLayout) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
           
        }
    }
    trackByID(index, item) {
        return item.ID;
    }
    //#region Style
    InitStyles() {
        this.createEditItems();
    }
    createEditItems() {
        if (this.LayoutElement.ViewType === ViewType.Edit) {
            if (this.LayoutElement.RowDefinitions && this.LayoutElement.RowDefinitions.length > 0 &&
                this.LayoutElement.ColumnDefinitions && this.LayoutElement.ColumnDefinitions.length > 0) {
                const EditItems = [];
                for (let row = 1; row <= this.LayoutElement.RowDefinitions.length; row++) {
                    for (let col = 1; col <= this.LayoutElement.ColumnDefinitions.length; col++) {
                        const item = new LayoutElement();
                        item.Column = col;
                        item.Row = row;
                        //item.SetViewType(ViewType.Edit);
                        item.Editable = true;
                        EditItems.push(item);
                    }
                }
                this.EditItems = of(EditItems);
            }
        } else {
            this.EditItems = of([]);
        }
        this.cdRef.detectChanges();
    }
    //#endregion
}
export class GridPanel extends BasePanel {
    static override SIDS = ['9232597e-f071-49fe-a98a-8cae8cae58b0', '604d23ec-332b-4dd1-8c4d-0e16ae7dadc9', '62cb22ea-9801-4ece-b37b-f8786db9981c']
    static override ElementType = 'grid'
    private static SendColChange(cols) {
        const keys = Object.keys(cols);
        if (keys.length > 0) {
            const mlpcv: MultiPropertyChangeValue[] = [];
            mlpcv.push({
                ElementIDs: [GridPanel.SelectedItem.ID],
                Properties: [{
                    PropertyName: 'ColumnDefinitions',
                    Value: GridPanel.SelectedItem.ColumnDefinitions
                }]
            });
            keys.forEach(k => {
                mlpcv.push({
                    ElementIDs: cols[k],
                    Properties: [{
                        PropertyName: 'Column',
                        Value: parseInt(k, 10)
                    }]
                });
            });
            LayoutService.OnMultiLayoutPropertyChanged(mlpcv);
            GridPanel.SelectedItem.ValuesChanged.next();
        }
    }
    private static SendRowChange(rows) {
        const keys = Object.keys(rows);
        if (keys.length > 0) {
            const mlpcv: MultiPropertyChangeValue[] = [];
            mlpcv.push({
                ElementIDs: [GridPanel.SelectedItem.ID],
                Properties: [{
                    PropertyName: 'RowDefinitions',
                    Value: GridPanel.SelectedItem.RowDefinitions
                }]
            });
            keys.forEach(k => {
                mlpcv.push({
                    ElementIDs: rows[k],
                    Properties: [{
                        PropertyName: 'Row',
                        Value: parseInt(k, 10)
                    }]
                });
            });
            LayoutService.OnMultiLayoutPropertyChanged(mlpcv);
            GridPanel.SelectedItem.ValuesChanged.next();
        }
    }

    private static GetColRowCaption(item, index) {
        let retVal = (index + 1).toString() + '. ';
        if (item && item.Size) {
            if (item.Size.Type == UnitType.Auto) {
                retVal += 'auto';
            } else if (item.Size.Type == UnitType.Rest) {
                retVal += 'rest';
            } else if (item.Size.Value != null) {
                switch (item.Size.Type) {
                    case UnitType.em:
                        retVal += item.Size.Value + 'em';
                        break;
                    case UnitType.Percent:
                        retVal += item.Size.Value + '%';
                        break;
                    case UnitType.Pixel:
                        retVal += item.Size.Value + ' Pixel';
                        break;
                    case UnitType.fr:
                        retVal += item.Size.Value + 'fr';
                        break;
                    case UnitType.vw:
                        retVal += item.Size.Value + 'vw';
                        break;
                    case UnitType.vh:
                        retVal += item.Size.Value + 'vh';
                        break;
                    case UnitType.calc:
                        retVal += 'calc(' + item.Size.Value + ')';
                        break;
                }
            }
        }
        return retVal;
    }

    static InitPanel() {
        this.InitSelectedItem();
        PROPERTYGROUPS.push({
            SID: '9232597e-f071-49fe-a98a-8cae8cae58b0',
            ID: 'gridcolumns',
            Caption: '@@ColumnDefinitions',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckOnChange: GenericMenuTab.CheckOnChange,
            CheckVisibility: (item) => {
                return item.ElementType == 'grid';
            }
        });
        PROPERTIES.push({
            ID: "ColumnDefinitions",
            Parent: "gridcolumns",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Size",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Label: "@@Size",
                    InitArgs: {
                        RemoveTypes:[]
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item = new LayoutBase();
                    item.Size = new LayoutUnit();
                    item.Size.Value = 1;
                    item.Size.Type = UnitType.fr;
                    return item;
                },
                GetCaption: (item, index) => {
                    return GridPanel.GetColRowCaption(item, index);
                },
                DropEntry: (event, Component) => {
                    if (event.previousIndex !== event.currentIndex) {
                        if (GridPanel.SelectedItem && GridPanel.SelectedItem.ColumnDefinitions) {
                            moveItemInArray(GridPanel.SelectedItem.ColumnDefinitions, event.previousIndex, event.currentIndex);
                            const cols = {};
                            if (GridPanel.SelectedItem.Elements) {
                                GridPanel.SelectedItem.Elements.forEach(x => {
                                    let colVal;
                                    if (x.Column === event.previousIndex + 1) {
                                        x.Column = event.currentIndex + 1;
                                        colVal = '' + x.Column;
                                    } else if (event.currentIndex < event.previousIndex) {
                                        if (x.Column > event.currentIndex && x.Column <= event.previousIndex) {
                                            x.Column++;
                                            colVal = '' + x.Column;
                                        }
                                    } else {
                                        if (x.Column > event.previousIndex && x.Column <= event.currentIndex + 1) {
                                            x.Column--;
                                            colVal = '' + x.Column;
                                        }
                                    }
                                    if (colVal) {
                                        const list = cols[colVal];
                                        if (list) {
                                            list.push(x.ID);
                                        } else {
                                            cols[colVal] = [x.ID];
                                        }
                                    }
                                });
                            }
                            GridPanel.SendColChange(cols);
                        }
                    }
                },
                RemoveItem: (entry) => {
                    if (GridPanel.SelectedItem.ColumnDefinitions && GridPanel.SelectedItem.ColumnDefinitions.length > 1) {
                        const colIndex = GridPanel.SelectedItem.ColumnDefinitions.indexOf(entry);
                        if (colIndex > -1) {
                            GridPanel.SelectedItem.ColumnDefinitions.splice(colIndex, 1);
                            const length = GridPanel.SelectedItem.ColumnDefinitions.length;
                            if (length > 0) {
                                if (colIndex < length) {
                                    entry = GridPanel.SelectedItem.ColumnDefinitions[colIndex];
                                } else {
                                    entry = GridPanel.SelectedItem.ColumnDefinitions[length - 1];
                                }
                            } else {
                                entry = null;
                            }
                            const cols = {};
                            if (GridPanel.SelectedItem.Elements) {
                                GridPanel.SelectedItem.Elements.forEach(x => {
                                    let colVal;
                                    if (x.Column > colIndex + 1) {
                                        x.Column--;
                                        colVal = '' + x.Column;
                                    }
                                    if (x.Column > GridPanel.SelectedItem.ColumnDefinitions.length) {
                                        x.Column = GridPanel.SelectedItem.ColumnDefinitions.length;
                                        colVal = '' + x.Column;
                                    }
                                    if (colVal) {
                                        const list = cols[colVal];
                                        if (list) {
                                            list.push(x.ID);
                                        } else {
                                            cols[colVal] = [x.ID];
                                        }
                                    }
                                });
                            }
                            GridPanel.SendColChange(cols);
                        }
                    }
                }
            }
        });
        PROPERTYGROUPS.push({
            SID: '604d23ec-332b-4dd1-8c4d-0e16ae7dadc9',
            ID: 'gridrows',
            Caption: '@@RowDefinitions',
            Index: 101,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'grid';
            }
        });
        PROPERTIES.push({
            ID: "RowDefinitions",
            Parent: "gridrows",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Size",
                    Parent: "InputGroup",
                    Content: new ComponentPortal(UnitSelectionControl),
                    Label: "@@Size",
                    InitArgs: {
                        RemoveTypes: []
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item = new LayoutBase();
                    item.Size = new LayoutUnit();
                    item.Size.Value = 1;
                    item.Size.Type = UnitType.fr;
                    return item;
                },
                GetCaption: (item, index) => {
                    return GridPanel.GetColRowCaption(item, index);
                },
                DropEntry: (event, Component) => {
                    if (event.previousIndex !== event.currentIndex) {
                        if (GridPanel.SelectedItem && GridPanel.SelectedItem.RowDefinitions) {
                            moveItemInArray(this.SelectedItem.RowDefinitions, event.previousIndex, event.currentIndex);
                            const rows = {};
                            if (GridPanel.SelectedItem.Elements) {
                                GridPanel.SelectedItem.Elements.forEach(x => {
                                    let rowVal;
                                    if (x.Row === event.previousIndex + 1) {
                                        x.Row = event.currentIndex + 1;
                                        rowVal = '' + x.Row;
                                    } else if (event.currentIndex < event.previousIndex) {
                                        if (x.Row > event.currentIndex && x.Row <= event.previousIndex) {
                                            x.Row++;
                                            rowVal = '' + x.Row;
                                        }
                                    } else {
                                        if (x.Row > event.previousIndex && x.Row <= event.currentIndex + 1) {
                                            x.Row--;
                                            rowVal = '' + x.Row;
                                        }
                                    }
                                    if (rowVal) {
                                        const list = rows[rowVal];
                                        if (list) {
                                            list.push(x.ID);
                                        } else {
                                            rows[rowVal] = [x.ID];
                                        }
                                    }
                                });
                            }
                            GridPanel.SendRowChange(rows);
                        }
                    }
                },
                RemoveItem: (entry) => {
                    if (GridPanel.SelectedItem.RowDefinitions && GridPanel.SelectedItem.RowDefinitions.length > 1) {
                        const rowIndex = GridPanel.SelectedItem.RowDefinitions.indexOf(entry);
                        if (rowIndex > -1) {
                            GridPanel.SelectedItem.RowDefinitions.splice(rowIndex, 1);
                            const length = GridPanel.SelectedItem.RowDefinitions.length;
                            if (length > 0) {
                                if (rowIndex < length) {
                                    entry = GridPanel.SelectedItem.RowDefinitions[rowIndex];
                                } else {
                                    entry = GridPanel.SelectedItem.RowDefinitions[length - 1];
                                }
                            } else {
                                entry = null;
                            }
                            const rows = {};
                            if (GridPanel.SelectedItem.Elements) {
                                GridPanel.SelectedItem.Elements.forEach(x => {
                                    let rowVal;
                                    if (x.Row > rowIndex + 1) {
                                        x.Row--;
                                        rowVal = '' + x.Row;
                                    }
                                    if (x.Row > GridPanel.SelectedItem.RowDefinitions.length) {
                                        x.Row = GridPanel.SelectedItem.RowDefinitions.length;
                                        rowVal = '' + x.Row;
                                    }
                                    if (rowVal) {
                                        const list = rows[rowVal];
                                        if (list) {
                                            list.push(x.ID);
                                        } else {
                                            rows[rowVal] = [x.ID];
                                        }
                                    }
                                });
                            }
                            this.SendRowChange(rows);
                        }
                    }
                }
            }
        });
        PROPERTYGROUPS.push({
            SID: '62cb22ea-9801-4ece-b37b-f8786db9981c',
            ID: 'gridposition',
            Caption: '@@GridPosition',
            Index: 105,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['1fr','1fr'],
            Rows: ['auto'],
            CheckOnChange: GenericMenuTab.CheckOnChange,
            CheckVisibility: (item) => {
                return item && item.Parent && item.Parent.ElementType == 'grid';
            }
        });
        PROPERTIES.push({
            ID: "Column",
            Parent: "gridposition",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Column",
            InitArgs: {
                InputType: "number",
                Minimum:1
            }
        });
        PROPERTIES.push({
            ID: "Row",
            Parent: "gridposition",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Row",
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
        PROPERTIES.push({
            ID: "ColSpan",
            Parent: "gridposition",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@ColSpan",
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
        PROPERTIES.push({
            ID: "RowSpan",
            Parent: "gridposition",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@RowSpan",
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
        PROPERTIES.push({
            ID: "CellOverflow",
            Parent: "gridposition",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@CellOverflow",
            Column:1,
            ColSpan:2,
            InitArgs: {
                EnumSource: Overflow
            }
        });
    }
}