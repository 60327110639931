import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { MetaHelper } from '../../../../helpers/meta.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { ElementProperty } from '../../../../models/layoutbase.model';
import { LayoutElement } from '../../../../models/layoutelement.model';
import { LayoutService } from '../../../../services/layout.service';
import { IBaseComponent } from '../../base.component';

@Component({
    selector: 'evi-table-column-visibility',
    templateUrl: './columnvisibility.control.html',
    styleUrls: ['./columnvisibility.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EviTableColumnVisibilityControl extends IBaseComponent {
    
    static Type: any = 'tablecolumnvisibility';
    static Default = {
        Type: 'tablecolumnvisibility',
        Layout: {
            Caption: 'Column Visibility',
            ButtonType: 'mat-button',
            _Editable: true,
            Width: { Type: 0, Value:150},
            Height: {Type:0,Value:30}
        }
    };

    DataTable;
    Columns = [];
    FilteredColumns = [];

    FilterValue;
    get Filter() {
        return this.FilterValue;
    }
    set Filter(val) {
        this.FilterValue = val;
        this.setFiltered();
    }

    _ButtonLayout = new LayoutElement();
    get ButtonLayout() {
        return this._ButtonLayout;
    }

    constructor(cdref: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
        this.PropertyList.push(new ElementProperty('Filter', 'list', '@@Filter'));
    }

    ControlInitialized() {
        this.initConfig();
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.initConfig();
        });
    }

    initConfig() {
        this.Columns = [];
        if (this.LayoutElementValue) {
            this._ButtonLayout = plainToClass(LayoutElement, JSON.parse(JSON.stringify(this.LayoutElementValue)));
            if (this.LayoutElementValue.DataTableID) {
                this.DataTable = MetaHelper.FindElementByID(this.Layout, this.LayoutElementValue.DataTableID);
                if (this.DataTable) {
                    this.CreateColumns();
                }
            }
        }
    }

    CreateColumns() {
        if (this.DataTable && this.DataTable.Columns) {
            const columns = [];
            this.DataTable.Columns.filter(item => item.FetchData).forEach(col => {
                columns.push({
                    Caption: col.Caption ? col.Caption : col.Name,
                    Data: col
                });
            });
            this.Columns = columns;
            this.SortColumns();
            this.LoadUserSpecific();
            this.setFiltered();
        }
    }

    ButtonInitComplete() {
        this.LayoutElementValue.Element = this;
    }
    LoadUserSpecific() {
        if (VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElementValue.ID]) {
            const cols = VariableHelper.ActiveVariablesMap[this.Layout._Id + '||' + this.LayoutElementValue.ID].Value;
            if (cols) {
                this.Columns.forEach((item) => {
                    const col = cols.find((value) => value.Name == item.Data.Name);
                    if (col) {
                        item.Data.Position = col.Position;
                        item.Data.IsVisible = col.IsVisible;
                    }
                });
                this.SortColumns();
            }
            this.Refresh();
        }
    }

    Refresh() {
        if (this.DataTable) {
            this.DataTable.ValuesChanged.next();
        }
    }
    VisibilityChanged() {
        if (this.DataTable) {
            this.DataTable.ValuesChanged.next();

            VariableHelper.ValueChanged(this.Layout, {
                ID: this.LayoutElementValue.ID,
                Value: this.GetUserSpecificValue()
            });
        }
    }

    onDrop(event) {
        if (event.previousIndex !== event.currentIndex) {
            let previous, current;
            if (this.FilterValue && this.FilterValue.length > 0) {
                const actCol = this.FilteredColumns[event.previousIndex];
                previous = this.Columns.indexOf(actCol);
                if (event.currentIndex > event.previousIndex && event.currentIndex < this.FilteredColumns.length - 1) {
                    const targetCol = this.FilteredColumns[event.currentIndex + 1];
                    current = this.Columns.indexOf(targetCol) - 1;
                } else {
                    const targetCol = this.FilteredColumns[event.currentIndex];
                    current = this.Columns.indexOf(targetCol);
                }
            } else {
                previous = event.previousIndex;
                current = event.currentIndex;
            }
            moveItemInArray(this.Columns, previous, current);
            for (let i = 0; i < this.Columns.length; i++) {
                this.Columns[i].Data.Position = i;
            }
            this.setFiltered();
            this.VisibilityChanged();
        }
    }

    SortColumns() {
        this.Columns = this.Columns.sort((a, b) => {
            if (a.Data.Position > b.Data.Position) {
                return 1;
            }
            if (a.Data.Position < b.Data.Position) {
                return -1;
            }
            return 0;
        });
        for (let i = 0; i < this.Columns.length; i++) {
            this.Columns[i].Data.Position = i;
        }
    }

    GetUserSpecificValue() {
        const cols = [];
        this.Columns.forEach(x => {
            cols.push(x.Data);
        });
        return cols;
    }
    SetUserSpecificValue(val) {
        this.LoadUserSpecific();
    }

    setFiltered() {
        if (this.FilterValue && this.FilterValue.length > 0) {
            this.FilteredColumns = this.Columns.filter(x => this.FilterValue.indexOf(x.Data.ContainerFieldID) > -1);
        } else {
            this.FilteredColumns = [...this.Columns];
        }
    }
}
