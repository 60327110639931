import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { IPageInfo } from 'ngx-virtual-scroller';
import { of, Subject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { FilterHelper } from '../../../helpers/filter.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { FlexContentAlignment, FlexItemAlignment } from '../../../models/enums/alignment.enum';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { Orientation } from '../../../models/enums/orientation.enum';
import { PrimeFlexSizes } from '../../../models/enums/primeflexsizes.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-bootstraprepeat',
    templateUrl: './bootstraprepeat.layout.html',
    styleUrls: ['./bootstraprepeat.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BootstrapRepeatLayout extends IBaseComponent {

    static Type: any = 'bootstraprepeat';
    static Default = {
        Editable: true,
        Type: 'bootstraprepeat'
    };
    DisableAutoLoad = false;
    _Items;
    _Items$;
    _RawItems;
    @Input()
    public get Items() {
        return this._Items;
    }
    public set Items(val) {
        this._RawItems = val;
        this._Items = of(val);
        if (this.ItemsChange) {
            this.ItemsChange.emit(this._Items);
        }
        this.RefreshCount += 1;
    }
    @Output()
    public ItemsChange = new EventEmitter();

    RepeatElements;
    RepeatElements$;
    //#region DataSource
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        if (this.DataBindingProperties && this.DataBindingProperties.IsDataBinding && !this.DataBindingProperties.IsShared) {
            this.updateRepeatElements();
        }
        this.DataSourceChange.emit(this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    DataBindingProperties;
    RepeatDataSource;
    constructor(private dataService: DataService, private settingsService: SettingsService,
        cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }
    onInit() {
        this.ControlInitialized();
        this.setInitialized();
    }

    onLayoutElementSet() {
        if (this.LayoutElementValue && (!this.LayoutElementValue.Elements || this.LayoutElementValue.Elements.length == 0)) {
            let layout = new LayoutElement()
            layout.ElementType = 'grid'
            layout.ContentType = ContentType.Grid
            this.LayoutElementValue.Elements = [layout]
        }
        if (this.RepeatDataSource) {
            this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
        }
        this.Items = this.LayoutElementValue.Elements;
        this.updateRepeatElements();
    }
    onLayoutElementChanged() {
        if (this.LayoutElementValue && (!this.LayoutElementValue.Elements || this.LayoutElementValue.Elements.length == 0)) {
            let layout = new LayoutElement()
            layout.ElementType = 'grid'
            layout.ContentType = ContentType.Grid
            this.LayoutElementValue.Elements = [layout]
        }
        if (this.RepeatDataSource) {
            this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
        }
        this.Items = this.LayoutElementValue.Elements;
        this.updateRepeatElements();
    }

    ControlInitialized() {
        this.cdRef.detectChanges();
        this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
       
        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            this.updateRepeatElements();
        });
        this.Subscriptions["ViewType"] = LayoutService.ViewType.subscribe(x => {
            if (x == ViewType.View) {
                this.updateRepeatElements();
            }
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.cdRef.detectChanges();
        });
        MetaHelper.FindDataBindingProperties(this.Layout, this.LayoutElementValue).then(result => {
            if (result) {
                this.DataBindingProperties = result;
                if (result.IsDataBinding) {
                    if (result.IsShared) {
                        // console.log('shared');
                        // this.listDataSource = new SharedDataBindingSource(this, result.Table, this.dataService, this.DisableAutoLoad);
                    } else {
                        this.updateRepeatElements();
                    }
                } else {
                    this.RepeatDataSource = new DataSourceRepeatSource(this, result.Table, this.dataService);
                    this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
                    if (!this.DisableAutoLoad) {
                        this.RepeatDataSource.LoadData();
                    }
                    this.DisableAutoLoad = false;
                }
            } else {
                // this.listDataSource = new SimpleDataSource(this, { Fields: [] });
            }
            if (this.RepeatDataSource) {
                this.RepeatDataSource.Loading.subscribe((status) => {
                    if (!status) {
                        this.DataSourceValue = this.RepeatDataSource.DataSource;
                        this.updateRepeatElements();
                    }
                });
            }
        });
    }

    updateRepeatElements() {
        const repeatElements = [];
        if (Array.isArray(this.DataSourceValue) && Array.isArray(this._RawItems)) {
            const json = JSON.stringify(this._RawItems);
            this.DataSourceValue.forEach((entry, index) => {
                const elements = [];
                const arrayCopy = JSON.parse(json);
                arrayCopy.forEach(item => {
                    const copy = plainToClass(LayoutElement, item);
                    copy['Parent'] = this.LayoutElementValue;
                    elements.push(copy);
                });
                repeatElements.push({
                    Data: entry,
                    Index: index,
                    Elements: elements,
                    ClassDefinitions: this.LayoutElement.ClassDefinitions
                });
            });
        }
        this.RepeatElements$ = of(repeatElements);
        this.RepeatElements = repeatElements;
        this.RefreshCount += 1;
        this.cdRef.detectChanges();
    }
    ExecuteRefresh() {
        if (this.DataBindingProperties) {
            if (this.DataBindingProperties.IsDataBinding) {
                if (this.DataBindingProperties.IsShared) {
                    // this.listDataSource = new SharedDataBindingSource(this, result.Table, this.dataService, this.DisableAutoLoad);
                } else {
                    this.updateRepeatElements();
                }
            } else {
                if (!this.RepeatDataSource) {
                    this.RepeatDataSource = new DataSourceRepeatSource(this, this.DataBindingProperties.Table, this.dataService);
                }
                this.RepeatDataSource.pageLength = this.LayoutElementValue.PageSize ? this.LayoutElementValue.PageSize : 100;
                this.RepeatDataSource.onFilterChanged();
            }
        }
        if (this.RepeatDataSource) {
            this.RepeatDataSource.Loading.subscribe((status) => {
                if (!status) {
                    this.DataSourceValue = this.RepeatDataSource.DataSource;
                    this.updateRepeatElements();
                }
            });
        }
    }
    onScroll(event: IPageInfo) {
        if (this.RepeatDataSource && this.RepeatDataSource.initialized) {
            if (event.endIndex !== this.RepeatDataSource.DataSource.length - 1) return;
            if (this.RepeatDataSource) {
                this.RepeatDataSource.onScroll();
            }
        }
    }
    itemSelected(item, event) {
        if (!item.Selected) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
        }
    }
}

export class DataSourceRepeatSource {
    DataSourceID;
    DataSource: any[] = [];

    Loading: Subject<boolean> = new Subject();
    DataSourceElement;
    pageIndex = 0;
    pageLength = 100;
    loaded = false;
    initialized = false;

    constructor(component: BootstrapRepeatLayout, table, private dataService: DataService) {
        this.DataSourceID = table.SID;
        this.DataSourceElement = MetaHelper.FindValidParent(component.Layout, component.LayoutElement);
    }

    onFilterChanged() {
        this.pageIndex = 0;
        this.loaded = false;
        this.DataSource = [];
        this.LoadData();
    }

    onScroll() {
        this.LoadData();
    }

    LoadData() {
        if (!this.loaded && this.DataSourceID) {
            this.Loading.next(true);
            const filter = FilterHelper.PrepareFilter(this.DataSourceElement);
            filter.StartRow = this.pageIndex * this.pageLength;
            filter.EndRow = filter.StartRow + this.pageLength;
            this.dataService.SearchObjects('dynamicdata', this.DataSourceID, filter).subscribe((data) => {
                this.initialized = true;
                this.pageIndex += 1;
                if (data && data.length > 0) {
                    this.DataSource = [...this.DataSource, ...data];
                } else {
                    this.loaded = true;
                }
                this.Loading.next(false);
            });
        }
    }
}
export class BootstrapRepeatPanel extends BasePanel {
    static override SIDS = ['a3e5be2b-ec03-4877-ad94-939b5b5c6e4e']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'a3e5be2b-ec03-4877-ad94-939b5b5c6e4e',
            ID: 'bootstraprepeat',
            Caption: '@@BootstrapRepeat',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'bootstraprepeat';
            }
        });
        PROPERTIES.push({
            ID: "DisableAutoLoad",
            Parent: "bootstraprepeat",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@DisableAutoLoad"
            }
        });
        PROPERTIES.push({
            ID: "PageSize",
            Parent: "bootstraprepeat",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@PageSize",
            InitArgs: {
                InputType: "number",
                Minimum: 1
            }
        });
        PROPERTIES.push({
            ID: "ChildContentType",
            Parent: "bootstraprepeat",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ContentType",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: ContentType,
                Action: (ev) => {
                    ev.SelectedItem.Elements[0].ElementType = ContentType[ev.DataSource].toLowerCase();
                }
            }
        });
        PROPERTIES.push({
            ID: "ClassDefinitions",
            Parent: "bootstraprepeat",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Column",
                    Parent: "bootstraprepeat",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Column",
                    InitArgs: {
                        InputType: "number",
                        Minimum: 1,
                        Maximum: 12
                    }
                }, {
                    ID: "ColumnOffset",
                    Parent: "bootstraprepeat",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@ColumnOffset",
                    InitArgs: {
                        InputType: "number",
                        Minimum: 1,
                        Maximum: 12
                    }
                }, {
                    ID: "ResponsiveSize",
                    Parent: "bootstraprepeat",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@ResponsiveSize",
                    InitArgs: {
                        EnumSource: PrimeFlexSizes
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item: any = {};
                    item.Column = 3;
                    return item;
                },
                GetCaption: (item, index) => {
                    return item.Column
                }
            }
        });
        
    }
}