import { Subject } from 'rxjs';

export class IntersectHelper {
    Observer = null; 
    Columns = {};
    OnChange = new Subject<any>();
    constructor() {
        this.Observer = new IntersectionObserver(entries => {
            let change = false;
            entries.forEach(entry => {
                const column = this.Columns[entry.target.getAttribute('data')];
                if (column && column.isIntersecting !== entry.isIntersecting) {
                    column.isIntersecting = entry.isIntersecting;
                    change = true;
                }
            });
            if (change) {
                this.OnChange.next(null);
            }
        }, { threshold: 0 });
    }
}