import { AxisNode } from '../../datadescription/multi/multiresult.model';
import { SeriesSettings } from '../../controls/chart.model';

export class ChartSeriesResult {
    Series: SeriesInfo[] = [];
    Rows: RowInfo[] = [];
}

export class SeriesInfo {
    Caption: string;
    Key: string;
    Settings: SeriesSettings;
    AxisNode: AxisNode;
    MeasureNode: AxisNode;
    Values: SeriesValueInfo[] = [];
    LabelInfo: SeriesLabelInfo;

    public constructor(init?: Partial<SeriesInfo>) {
        Object.assign(this, init);
    }
}

export class SeriesLabelInfo {
    ShowCaption: boolean;
    ShowValue: boolean;
}

export class SeriesValueInfo {
    Index: number;
    Value;
    StyleID = 0;

    public constructor(init?: Partial<SeriesValueInfo>) {
        Object.assign(this, init);
    }
}

export class RowInfo {
    Caption: string;
    AxisNode: AxisNode;
    MeasureNode: AxisNode;

    public constructor(init?: Partial<RowInfo>) {
        Object.assign(this, init);
    }
}
