import { Axis, AxisNode, MultiResult, MultiNode } from '../models/datadescription/multi/multiresult.model';
import { HeterogenArea } from '../models/datadescription/multi/datadescription.model';

export class MultiResultHelper {

    static ExecuteOnAllNodes(axis: Axis, action, executeOnSumNodes = false) {
        if (axis && action) {
            MultiResultHelper.ExecuteOnAllNodesInternal(axis.Nodes, action, executeOnSumNodes);
            if (executeOnSumNodes && axis.SumNode) {
                action(axis.SumNode);
            }
        }
    }

    static ExecuteOnAllNodesInternal(nodes: AxisNode[], action, executeOnSumNodes = false) {
        if (nodes) {
            nodes.forEach(n => {
                action(n);
                MultiResultHelper.ExecuteOnAllNodesInternal(n.Children, action, executeOnSumNodes);
                if (executeOnSumNodes && n.SumNode) {
                    action(n.SumNode);
                }
            });
        }
    }

    static GetNodesFlat(axis: Axis, uniqueID: number): AxisNode[] {
        const retVal = [];
        if (axis) {
            retVal.push(...MultiResultHelper.GetNodesFlatInternal(axis.Nodes, uniqueID));
        }
        return retVal;
    }

    private static GetNodesFlatInternal(nodes: AxisNode[], uniqueID: number): AxisNode[] {
        const retVal = [];
        if (nodes) {
            nodes.forEach(n => {
                if (n.UniqueID === uniqueID) {
                    retVal.push(n);
                } else {
                    retVal.push(...MultiResultHelper.GetNodesFlatInternal(n.Children, uniqueID));
                }
            });
        }
        return retVal;
    }

    static GetNodeSections(axis: Axis, uniqueID: number): AxisNode[][] {
        const retVal = [];
        if (axis) {
            retVal.push(...MultiResultHelper.GetNodeSectionsInternal(axis.Nodes, uniqueID));
        }
        return retVal;
    }

    public static GetNodeSectionsInternal(nodes: AxisNode[], uniqueID: number): AxisNode[] {
        const retVal = [];
        if (nodes) {
            const innerResult = [];
            nodes.forEach(n => {
                if (n.UniqueID === uniqueID) {
                    innerResult.push(n);
                } else {
                    retVal.push(...MultiResultHelper.GetNodeSectionsInternal(n.Children, uniqueID));
                }
            });
            if (innerResult.length > 0) {
                retVal.push(innerResult);
            }
        }
        return retVal;
    }

    static MergeLevels(nodes: AxisNode[], levelDepth: number, columnAction) {
        if (levelDepth === 1) {
            columnAction(nodes);
        } else {
            const actNode = nodes[nodes.length - 1];
            if (actNode.Children && actNode.Children.length > 0) {
                actNode.Children.forEach(child => {
                    MultiResultHelper.MergeLevels([...nodes, child], levelDepth - 1, columnAction);
                });
            }
        }
    }

    static GetCellOrNull(result: MultiResult, x: AxisNode, y: AxisNode, m: AxisNode): MultiNode {
        if (result) {
            const xArray = result.Cells[x.Position];
            if (xArray) {
                const yArray = xArray[y.Position];
                if (yArray) {
                    return yArray[m.Position];
                }
            }
        }
        return null;
    }

    static GetCellOrNew(result: MultiResult, x: AxisNode, y: AxisNode, m: AxisNode): MultiNode {
        let xArray = result.Cells[x.Position];
        if (!xArray) {
            xArray = [];
            result.Cells[x.Position] = xArray;
        }
        let yArray = xArray[y.Position];
        if (!yArray) {
            yArray = [];
            xArray[y.Position] = yArray;
        }
        let mn = yArray[m.Position];
        if (!mn) {
            mn = new MultiNode({
                X: x.Position,
                Y: y.Position,
                M: m.Position
            });
            yArray[m.Position] = mn;
        }
        return mn;
    }

    static TryGetCellOrNew(result: MultiResult, x: AxisNode, y: AxisNode, m: AxisNode): { Node: MultiNode, IsNew: boolean } {
        const retVal = {
            Node: null,
            IsNew: false
        };
        let xArray = result.Cells[x.Position];
        if (!xArray) {
            xArray = [];
            result.Cells[x.Position] = xArray;
        }
        let yArray = xArray[y.Position];
        if (!yArray) {
            yArray = [];
            xArray[y.Position] = yArray;
        }
        let mn = yArray[m.Position];
        if (!mn) {
            retVal.IsNew = true;
            mn = new MultiNode({
                X: x.Position,
                Y: y.Position,
                M: m.Position
            });
            yArray[m.Position] = mn;
        }
        retVal.Node = mn;
        return retVal;
    }

    static GetValueX(result: MultiResult, measureAxisNode: AxisNode, oppNode: AxisNode, measure: AxisNode, getNew: boolean) {
        if (getNew) {
            return MultiResultHelper.GetCellOrNew(result, measureAxisNode, oppNode, measure);
        } else {
            return MultiResultHelper.GetCellOrNull(result, measureAxisNode, oppNode, measure);
        }
    }

    static GetValueY(result: MultiResult, measureAxisNode: AxisNode, oppNode: AxisNode, measure: AxisNode, getNew: boolean) {
        if (getNew) {
            return MultiResultHelper.GetCellOrNew(result, oppNode, measureAxisNode, measure);
        } else {
            return MultiResultHelper.GetCellOrNull(result, oppNode, measureAxisNode, measure);
        }
    }

    static GetNodesFromArea(area: HeterogenArea, allNodes: AxisNode[], noLevelIndex: { Index: number }) {
        const retVal = {
            Nodes: [],
            Levels: []
        };
        if (area) {
            let levelID;
            if (area.Tuples && area.Tuples.some(t => {
                if (t.Levels && t.Levels.length > 0) {
                    levelID = t.Levels[0].UniqueID;
                    retVal.Levels.push(...t.Levels);
                    return true;
                }
                return false;
            })) {
                allNodes.forEach(x => {
                    if (x.UniqueID === levelID) {
                        retVal.Nodes.push(x);
                    }
                });
            } else {
                let innerIndex = 0;
                allNodes.some(x => {
                    if (x.UniqueID === -1) {
                        if (innerIndex === noLevelIndex.Index) {
                            retVal.Nodes.push(x);
                            return true;
                        }
                        innerIndex++;
                    }
                    return false;
                });
                noLevelIndex.Index++;
            }
        }
        return retVal;
    }

    static ResetStyleIDs(result: MultiResult) {
        if (result) {
            if (result.XAxis) {
                if (result.XAxis.SumNode) {
                    result.XAxis.SumNode.StyleID = 0;
                }
                MultiResultHelper.ResetNodeStyles(result.XAxis.Nodes);
            }
            if (result.YAxis) {
                if (result.YAxis.SumNode) {
                    result.YAxis.SumNode.StyleID = 0;
                }
                MultiResultHelper.ResetNodeStyles(result.YAxis.Nodes);
            }
            if (result.Measures) {
                MultiResultHelper.ResetNodeStyles(result.Measures.Nodes);
            }
            if (result.Cells) {
                result.Cells.forEach(x => {
                    x.forEach(y => {
                        y.forEach(m => {
                            if (m) {
                                m.StyleID = 0;
                            }
                        });
                    });
                });
            }
        }
    }

    private static ResetNodeStyles(nodes: AxisNode[]) {
        if (nodes) {
            nodes.forEach(node => {
                node.StyleID = 0;
                if (node.SumNode) {
                    node.SumNode.StyleID = 0;
                }
                MultiResultHelper.ResetNodeStyles(node.Children);
            });
        }
    }

    public static FindAxisNodeByPosition(nodes: AxisNode[], position: number): AxisNode {
        let retVal;
        if (nodes) {
            nodes.some(n => {
                if (n.Position === position) {
                    retVal = n;
                    return true;
                }
                const childResult = MultiResultHelper.FindAxisNodeByPosition(n.Children, position);
                if (childResult) {
                    retVal = childResult;
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }

    public static FindAxisNodeByIDs(nodes: AxisNode[], uniqueID: number, memberID: string): AxisNode {
        let retVal;
        if (nodes) {
            nodes.some(n => {
                if (n.UniqueID === uniqueID && n.MemberId === memberID) {
                    retVal = n;
                    return true;
                }
                const childResult = MultiResultHelper.FindAxisNodeByIDs(n.Children, uniqueID, memberID);
                if (childResult) {
                    retVal = childResult;
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }
}
