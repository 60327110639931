import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { deserialize, serialize } from 'class-transformer';
import { Subject, Subscription } from 'rxjs';
import { DataDescription, HeterogenArea } from '../../models/datadescription/multi/datadescription.model';
import { AxisType } from '../../models/enums/query.enum';
import { RequestFilter } from '../../models/rest/requestfilter';
import { AdvancedFilterComponent } from '../controls/advancedfilter/advancedfilter.component';
import { DataTaskPanel } from './datataskpanel/datatask.panel';
import { FilterPanelControl } from './filter/filterpanel.control';
import { HeterogenAreaItemBasicInfo, HeterogenAreaItemSelectedInfo, ObjectcreatorDataManager } from './helper/objectcreator.datamanager';
import { MeasureDefinitionsControl } from './measuredefinition/measuredef.control';

@Component({
    selector: 'objectcreatorMaincontrol',
    templateUrl: './objectcreator.maincontrol.html',
    styleUrls: ['./objectcreator.maincontrol.css']
})
export class ObjectCreatorMainCtrl implements OnInit, AfterViewInit, OnDestroy {
   
    @ViewChild('dropAreaX') dropArea;
    @ViewChild('dropAreaY') dropAreaY;
    @ViewChild('dropAreaMeasure') dropAreaMeasure;
    @ViewChild('advancedFilter') advancedFilter: AdvancedFilterComponent;
    @ViewChild('measuredefinition') measuredefinition: MeasureDefinitionsControl;
    @ViewChild('filterControl') filterControl: FilterPanelControl;
    @ViewChild('taskOverview') taskOverview: DataTaskPanel;
    @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

    contextMenuPosition = { x: '0px', y: '0px' };

    ObjectcreatorDataManager: ObjectcreatorDataManager;
    OCManagerSub: Subscription;
    OCManagerDialogClosingSub: Subscription;

    filter: any[] = [];

    variable: any[] = [
        { "ID": 0, "Caption": "Available" },
        { "ID": 1, "Caption": "Ready" },
        { "ID": 2, "Caption": "Started" }
    ];

    Definition = null;
    SystemVariables = [];


    @Input()
    IsRelational: boolean;
    RelFieldFilter: any[] = [];

    DataDescriptionValue: any;

    @Input()
    get DataDescription() {
        if (this.DataDescriptionValue instanceof DataDescription) {
            if (this.DataDescriptionValue.XLevelNodes.Areas.length < 1) {
                const ha = new HeterogenArea();
                ha.UniqueID = this.ObjectcreatorDataManager.GetNextUniqueID();
                this.DataDescriptionValue.XLevelNodes.Areas.push(ha);
            }
            if (this.DataDescriptionValue.YLevelNodes.Areas.length < 1) {
                const ha = new HeterogenArea();
                ha.UniqueID = this.ObjectcreatorDataManager.GetNextUniqueID();
                this.DataDescriptionValue.YLevelNodes.Areas.push(ha);
            }
        }
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        if (value) {
            if (!(this.DataDescriptionValue instanceof DataDescription) &&
                (value instanceof DataDescription || value.XLevelNodes)) {
                const json = serialize(value);
                value = deserialize(DataDescription, json);
                this.clearOCSub();
                this.ObjectcreatorDataManager = new ObjectcreatorDataManager(value as DataDescription);
                this.OCManagerSub = ObjectcreatorDataManager.TasksChanged.subscribe(() => {
                    if (this.taskOverview) {
                        this.taskOverview.updateTaskList();
                    }
                });

                this.DataDescriptionValue = value;
                this.DataDescriptionChange.emit(value);                
            } else {
                this.DataDescriptionValue = value;
                this.DataDescriptionChange.emit(value);
            }
        }
    }
    @Output() DataDescriptionChange = new EventEmitter();

    public static ObjectCreatorMPDragStart: Subject<any> = new Subject<any>();

    //BaseSlideValue: BaseSlidePage;
    //@Input()
    //get BaseSlide() {
    //    return this.BaseSlideValue;

    //}
    //set BaseSlide(value) {
    //    this.BaseSlideValue = value;
    //    this.SystemVariables.push(...this.BaseSlideValue.ReportLayout.Variables);
    //}

    ReportObjectValue: any;
    @Input()
    get ReportObject() {
        return this.ReportObjectValue;

    }
    set ReportObject(value) {
        this.ReportObjectValue = value;
    }


    get RelationDefinition() {
        if (!this.Definition || this.Definition.Container != this.DataDescriptionValue.Containers || this.Definition.Relations != this.DataDescriptionValue.Relations) {
            const def = {
                Container: this.DataDescriptionValue.Containers,
                Relations: this.DataDescriptionValue.Relations,
                MaxID: 0,
                DataModelID: this.DataDescriptionValue.DataModell
            };
            this.Definition = def;
        }
        return this.Definition;
    }

    clearOCSub() {
        if (this.OCManagerSub) {
            this.OCManagerSub.unsubscribe();
            this.OCManagerSub = null;
        }
    }

    private EditWhereFilter() {
        const advancedFilter = this.advancedFilter.GetActualFilter();
        if (advancedFilter && advancedFilter.AdditionalInfos) {
            advancedFilter['FieldID'] = advancedFilter.AdditionalInfos.FieldID;
            advancedFilter['ContainerID'] = advancedFilter.AdditionalInfos.ContainerID;
            advancedFilter['FieldUniqueID'] = advancedFilter.AdditionalInfos.FieldUniqueID;
            advancedFilter['ContainerUniqueID'] = advancedFilter.AdditionalInfos.ContainerUniqueID;
        }
        if (advancedFilter && advancedFilter.Filters) {
            ObjectCreatorMainCtrl.SetFilterIds(advancedFilter.Filters);
        }
        this.DataDescriptionValue.WhereFilter = advancedFilter;
    }

    private static SetFilterIds(filters: RequestFilter[]) {
        if (filters && filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
                let filter = filters[i];
                if (filter.AdditionalInfos) {
                    filter['FieldUniqueID'] = filter.AdditionalInfos.FieldUniqueID;
                    filter['ContainerUniqueID'] = filter.AdditionalInfos.ContainerUniqueID;
                    filter['FieldID'] = filter.AdditionalInfos.FieldID;
                    filter['ContainerID'] = filter.AdditionalInfos.ContainerID;
                }
                if (filter.Filters) {
                    ObjectCreatorMainCtrl.SetFilterIds(filter.Filters);
                }
            }
        }
    }

    onDropAreaFieldsItemsChange(any) {
        ObjectcreatorDataManager.FieldsChanged.next();
        //if (this.IsRelational) {
        //  this.DataDescription.Fields = any;
        //}
    }

    onDropAreaMeasureItemsChange(any) {
        //if (this.IsRelational) {
        //  this.DataDescription.Fields = any;
        //}
    }

    onDropAreaXItemsChange(any) {
        //if (this.IsRelational) {
        //  this.DataDescription.Fields = any;
        //}
    }

    onDropAreaYItemsChange(any) {
        //if (this.IsRelational) {
        //  this.DataDescription.Fields = any;
        //}
    }

    ngOnInit(): void {
        this.OCManagerDialogClosingSub = ObjectcreatorDataManager.DialogClosing.subscribe(() => {
            if (this.DataDescriptionValue && this.IsRelational) {
                this.EditWhereFilter();
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.DataDescriptionValue && this.DataDescriptionValue.WhereFilter) {
            this.advancedFilter.SetFilter(this.DataDescriptionValue.WhereFilter);
        }
    }

    ngOnDestroy(): void {
        this.OCManagerDialogClosingSub.unsubscribe();
        this.clearOCSub();
    }

    onContextMenuAxis(event: MouseEvent, axisType: AxisType) {
        event.preventDefault();
        event['AxisType'] = axisType;
    }

    onContextMenu(event: MouseEvent, prop: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = new Array<{ key: string, value: any }>();

        if (event['OcDragItem'] !== undefined) {
            this.contextMenu.menuData.push({ key: 'OcDragItem', value: event['OcDragItem'] });
        }
        if (event['AreaItemType'] !== undefined) {
            this.contextMenu.menuData.push({ key: 'AreaItemType', value: event['AreaItemType'] });
        }
        if (event['AreaID'] !== undefined) {
            this.contextMenu.menuData.push({ key: 'AreaID', value: event['AreaID'] });
        }
        if (event['AxisType'] !== undefined) {
            this.contextMenu.menuData.push({ key: 'AxisType', value: event['AxisType'] });
        }
        if (event['DropArea'] !== undefined) {
            this.contextMenu.menuData.push({ key: 'DropArea', value: event['DropArea'] });
        }
        this.SetVisibleContextMenuItems(this.contextMenu.menuData);
        if (this.menuItems && this.menuItems.length > 0) {
            this.contextMenu.openMenu();
        }

    }

    SetVisibleContextMenuItems(data: Array<{ key: string, value: any }>) {
        this.menuItems = new Array<{ text: string, actionId: number, check: string }>();
        if (this.IsRelational) {
            this.menuItemsRelational.forEach(x => {
                if (this.CheckShowContextMenuItem(x.check)) {
                    this.menuItems.push(x);
                }
            });
        }
        else {
            this.menuItemsMultidimensional.forEach(x => {
                if (this.CheckShowContextMenuItem(x.check)) {
                    this.menuItems.push(x);
                }
            });
        }
    }

    CheckShowContextMenuItem(checkKey: string) {
        return this.contextMenu && this.contextMenu.menuData && this.contextMenu.menuData.findIndex(x => x.key == checkKey) >= 0;
    }

    onMatMenuClosed() {
        this.contextMenu.menuData = new Array<{ key: string, value: any }>();
    }

    menuItems: Array<{ text: string, actionId: number, check: string }>;

    menuItemsMultidimensional: Array<{ text: string, actionId: number, check: string }> = [
        { text: "@@Add area behind", actionId: 1, check: "AreaID" },
        { text: "@@Add area before", actionId: 2, check: "AreaID" },
        { text: "@@Delete area", actionId: 3, check: "AreaID" },
        { text: "@@Combine tuples", actionId: 4, check: "OcDragItem" },
        { text: "@@Resolve combined tuples", actionId: 5, check: "OcDragItem" },
        { text: "@@Delete item", actionId: 6, check: "OcDragItem" },
        { text: "@@Show/hide item", actionId: 10, check: "OcDragItem" },
        { text: "@@Rename item", actionId: 8, check: "OcDragItem" },
        { text: "@@Replace item", actionId: 11, check: "OcDragItem" },
        { text: "@@Move all measures to this area", actionId: 7, check: "AreaItemType" }
    ];

    menuItemsRelational: Array<{ text: string, actionId: number, check: string }> = [
        { text: "@@Delete field", actionId: 21, check: "OcDragItem" },
        { text: "@@Delete all fields", actionId: 22, check: "OcDragItem" }
    ];

    onContextMenuAction(id: number) {
        if (this.IsRelational) {
            switch (id) {
                case 21: //Item löschen
                    let cmData1: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData1) {
                        let item = cmData1.find(x => x.key == "OcDragItem");
                        let dropArea = cmData1.find(x => x.key == "DropArea");
                        if (item && item.value && dropArea && dropArea.value) {
                            dropArea.value.delete(item.value);
                        }
                    }
                    break;
                case 22: //alle Item löschen
                    let cmData2: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData2) {
                        let item = cmData2.find(x => x.key == "OcDragItem");
                        let dropArea = cmData2.find(x => x.key == "DropArea");
                        if (item && item.value && dropArea && dropArea.value) {
                            dropArea.value.deleteAll(item.value);
                        }
                    }
                    break;
                default:
            }
        }
        else {

            switch (id) {
                case 1: //"Area dahinter hinzufügen"
                    let cmData1: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData1) {
                        let axisTypeKV = cmData1.find(x => x.key == "AxisType");
                        let areaIDKV = cmData1.find(x => x.key == "AreaID");
                        if (axisTypeKV && areaIDKV) {
                            this.ObjectcreatorDataManager.AddHeterogenArea(axisTypeKV.value, areaIDKV.value, false);
                        }
                    }
                    break;
                case 2://"Area davor hinzufügen"
                    let cmData2: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData2) {
                        let axisTypeKV = cmData2.find(x => x.key == "AxisType");
                        let areaIDKV = cmData2.find(x => x.key == "AreaID");
                        if (axisTypeKV && areaIDKV) {
                            this.ObjectcreatorDataManager.AddHeterogenArea(axisTypeKV.value, areaIDKV.value, true);
                        }
                    }
                    break;
                case 3:// "Area entfernen"
                    let cmData3: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData3) {
                        let axisTypeKV = cmData3.find(x => x.key == "AxisType");
                        let areaIDKV = cmData3.find(x => x.key == "AreaID");
                        if (axisTypeKV && areaIDKV) {
                            this.ObjectcreatorDataManager.DeleteHeterogenArea(axisTypeKV.value, areaIDKV.value);
                        }
                    }
                    break;
                case 4:
                    let selInfo: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                    this.ObjectcreatorDataManager.BuildDBTupel(selInfo);
                    break;
                case 5:
                    let selInfo2: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                    this.ObjectcreatorDataManager.ResolveTupels(selInfo2);
                    break;
                case 6:
                    let selInfo3: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                    this.ObjectcreatorDataManager.DeleteDBHeterogenAreaElements(selInfo3);
                    break;
                case 7:// "Area entfernen"
                    let cmData7: Array<{ key: string, value: any }> = this.contextMenu.menuData;
                    if (cmData7) {
                        let axisTypeKV = cmData7.find(x => x.key === "AxisType");
                        let areaIDKV = cmData7.find(x => x.key === "AreaID");
                        if (axisTypeKV && areaIDKV) {
                            this.ObjectcreatorDataManager.MoveAllMeasuresToHeterogenArea(areaIDKV.value);
                        }
                    }
                    break;
                case 8:
                    let selInfo4: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                   this.ObjectcreatorDataManager.RenameDBHeterogenAreaElements(selInfo4);
                    break;
                case 10:
                    let selInfo10: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                    this.ObjectcreatorDataManager.HiddenDBHeterogenAreaElements(selInfo10);
                    break;
                case 11:
                    let selInfo11: HeterogenAreaItemSelectedInfo = this.GetSelectedInfoMultidimensional();
                    this.ObjectcreatorDataManager.ReplaceDBHeterogenAreaElement(selInfo11);
                    break;

                default:
            }
        }
    }



    GetSelectedInfoMultidimensional() {
        let selInfo: HeterogenAreaItemSelectedInfo = null;
        let cmData: Array<{ key: string, value: any }> = this.contextMenu.menuData;
        if (cmData) {
            let areaIDKV = cmData.find(x => x.key == "AreaID");
            let OcDragItemKV = cmData.find(x => x.key == "OcDragItem");
            let itemTypeKV = cmData.find(x => x.key == "AreaItemType");
            if (areaIDKV && areaIDKV.value !== undefined && OcDragItemKV && OcDragItemKV.value && itemTypeKV.value !== undefined) {
                selInfo = new HeterogenAreaItemSelectedInfo();
                let areaID = areaIDKV.value;
                let dragItem = OcDragItemKV.value;
                let itemType = itemTypeKV.value;
                selInfo.HeterogenAreaID = areaIDKV.value;
                selInfo.DropAreaType = itemType;
                let haBasicInfo: HeterogenAreaItemBasicInfo = new HeterogenAreaItemBasicInfo();
                haBasicInfo.AreaID = areaIDKV.value;
                haBasicInfo.UniqueID = dragItem.UniqueID;
                selInfo.SelectedItems.push(haBasicInfo);
            }
        }
        return selInfo;
    }
}
