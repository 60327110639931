import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ABaseCell } from './a.base.cell';

@Component({
    selector: 'evi-datatable-cell',
    templateUrl: './datatable.cell.html',
    styleUrls: ['./datatable.cell.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DataTableCell extends ABaseCell {
}
