export class UserConfig {
    SecurityProvider: string = null;
    UserName: string = null;
    Password: string = null;
}

export enum ResourceLoginType {
    Anonymous = 0,
    FixedUser = 1,
    UserStoredCredentials = 2,
    FixedAccount = 3,
    Impersonate = 4
}