import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowEngine } from '../../workflow.engine';
import { DisableDialogCloseModuleExecuter } from './disable.dialog.close.settings';

@Directive()
export abstract class AContentDialog implements OnInit, OnDestroy {
    InitArgs;
    Layout;
    BaseDialog;

    constructor(public cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.LoadLayout();
    }

    ngOnDestroy(): void {
        WorkflowService.ActiveDialog.next(null);
    }

    abstract LoadLayout();

    Initialize(args) {
        this.InitArgs = args;
        if (this.BaseDialog) {
            WorkflowService.ActiveDialog.next(this.BaseDialog);
        }
    }

    async GetDialogResult() {
        const retVal = {
            CanClose: true,
            Data: null
        };
        if (this.InitArgs && this.InitArgs.OkWorkflow && this.Layout && this.Layout.Workflows) {
            const wf = this.Layout.Workflows.find(x => x.ID === this.InitArgs.OkWorkflow);
            if (wf) {
                const status = new WorkflowStatus(wf.ID, wf.Caption);
                WorkflowStatus.CopyContext(WorkflowService.DialogStatusCopy.getValue(), status.Context);
                status.WorkflowLayoutService.init(this.Layout, null);
                const engine = new WorkflowEngine(wf.Data, status);
                await engine.startExecution();
                if (status.Context.get(DisableDialogCloseModuleExecuter.DisableID) === true) {
                    retVal.CanClose = false;
                }
                retVal.Data = status.ActualParameter;
            }
        }
        return retVal;
    }
}
