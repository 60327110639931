import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import {deserialize, plainToClass, serialize} from 'class-transformer';
import { AMenuTabContent } from '../../appbuilder/menutabs/controls/controls.menu.tab';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { Layout } from '../../models/layout.model';
import { LayoutElement } from '../../models/layoutelement.model';
import { TranslatedString } from '../../models/translatedstring.model';
import {WorkflowData, WorkflowSaveObject} from '../../models/workflow/workflow.model';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import {WF_REGISTRY, WorkflowService} from '../../services/workflow.service';
import { WorkflowWizardService } from '../../services/workflow.wizard.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { WorkflowWizardEditItem } from './workflow.wizard.edit.item';

@Component({
    selector: 'workflow-wizard-settings',
    templateUrl: '../base.list.settings.html'
})
export class WorkflowWizardSettings extends BaseListSettings {

    // Don't remove this code
    // Will be used in future

    // static GetSettingsEntry() {
    //     return {
    //         Caption: '@@Wizard',
    //         ID: 'wizard',
    //         Icon: 'paste',
    //         Index: 2,
    //         Security: {
    //             Name: 'evidanza.MiddleWare.Shared.Security.WorkflowWizardRight',
    //             Value: 1
    //         },
    //         Parent: 'workflow',
    //         Content: WorkflowWizardSettings
    //     };
    // }

    constructor(private service: WorkflowWizardService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    init() {
        NavigationService.SelectedPage.next('template');
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (LayoutService.ViewType.getValue() === 0) {
            LayoutService.ViewType.next(1);
        }
        NavigationService.SelectedPage.next('settings');
    }

    loadList(handler: any) {
        this.service.GetAllWizards().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((item) => {
                    list.push({
                        Caption: item.Caption,
                        ID: item.SID,
                        IsCapsule: item.IsCapsule,
                        IsOverridden: item.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }
    loadData(data: any) {
        if (data) {
            this.service.GetWizardObject(data).subscribe(result => {
                if (result) {
                    this.setSelectedItem(result);
                }
            });
        }
    }
    getNewItem() {
        return {
            Caption: new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer Wizard')),
            Workflow: new WorkflowSaveObject(),
            Pages: [],
        };
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Wizard \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Wizard \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Wizard loeschen');
        return retVal;
    }
    delete(data: any, handler: any) {
        this.service.DeleteWizardObject(data).subscribe(() => {
            handler();
        });
    }
    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Wizard \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(TranslatedString.GetTranslation(sel.Caption));
        retVal.Title = new TranslateFormatText('@@Wizard speichern');
        return retVal;
    }
    saveInternal(item: any, handler: any) {
        if (this.Component) {
            this.Component.beforeSave();
        }
        this.service.SaveWizardObject(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }
    getContentType() {
        return WorkflowWizardDetail;
    }
    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }
    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'workflow-wizard-detail',
    templateUrl: './workflow.wizard.settings.html',
    styleUrls: ['./workflow.wizard.settings.css']
})
export class WorkflowWizardDetail extends BaseListDetail implements OnDestroy {
    EditOptions;
    Pages = [];
    selectedNode;
    WorkflowModules = [];
    Subscription;
    SelectedTabIndex = 0;
    Caption;

    private static CountItems(item, type) {
        let count = 0;
        if (item.ElementType === type) {
            count += 1;
        }
        if (item && item.Elements && item.Elements.length > 0) {
            for (let i = 0; i < item.Elements.length; i++) {
                count += WorkflowWizardDetail.CountItems(item.Elements[i], type);
            }
        }
        return count;
    }

    constructor(private wfService: WorkflowService) {
        super();
        this.EditOptions = {
            IsService: true,
            IsTemplate: false,
            TemplateList: []
        };
    }

    ngOnInit() {
        this.Subscription = WorkflowWizardEditItem.EditItemInitialized.subscribe(editItem => {
            if (editItem && this.SelectedItem && this.SelectedItem.Workflow) {
                editItem.FillWithData(this.SelectedItem.Workflow);
            }
        });

        this.wfService.detectOnSaveChanges.subscribe((isUpdate) => {
            if(isUpdate) {
                const obj = JSON.parse(JSON.stringify(this.SelectedItem.Workflow));
                this.undoStack.push(obj);
            }
        })
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }

    updateCaption() {
        let caption = TranslateHelper.TranslatorInstance.instant('@@Details');
        if (this.SelectedItem) {
            caption += ' - ' + TranslatedString.GetTranslation(this.SelectedItem.Caption);
        }
        this.Caption = caption;
    }

    CaptionChanged(caption) {
        if (this.SelectedItem) {
            this.SelectedItem.Caption = caption;
            this.updateCaption();
            this.OnItemChanged();
        }
    }

    onTabChange(ev) {
        if (ev) {
            this.SelectedTabIndex = ev.index;
            if (this.SelectedTabIndex === 1 && this.selectedNode) {
                this.updateLayout(this.selectedNode.Layout);
            } else {
                this.updateLayout(null);
            }
        }
    }

    workflowChanged() {
        if (this.SelectedItem && this.SelectedItem.Workflow && this.SelectedItem.Workflow.Modules) {
            if (this.WorkflowModules) {
                if (this.WorkflowModules.length === this.SelectedItem.Workflow.Modules.length) {
                    for (let i = 0; i < this.WorkflowModules.length; i++) {
                        const wfm = this.WorkflowModules[i].Default.Layout.ModuleInfo;
                        const mod = this.SelectedItem.Workflow.Modules[i];
                        if (wfm.ID !== mod.ID) {
                            this.updateModules();
                            break;
                        }
                    }
                } else {
                    this.updateModules();
                }
            } else {
                this.updateModules();
            }
        }
        this.OnItemChanged();
        if(this.SelectedItem && this.SelectedItem.Workflow) {
            const obj = JSON.parse(JSON.stringify(this.SelectedItem.Workflow));
            this.undoStack.push(obj);
        }
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
            if (this.SelectedTabIndex === 1) {
                this.updateLayout(this.selectedNode.Layout);
            }
        }
    }

    setSelectedItem(item: any): void {
        this.selectedNode = null;
        const pages = [];
        if (item && item.Pages) {
            item.Pages.forEach(page => {
                pages.push({
                    Caption: TranslatedString.GetTranslation(page.Caption),
                    Layout: deserialize(Layout, page.Layout),
                    Data: page
                });
            });
        }
        this.Pages = pages;
        if (item) {
            if (pages.length === 0) {
                this.addItem();
            } else {
                this.onItemClick(pages[0]);
            }
        }
        super.setSelectedItem(item);
        this.updateModules();
        this.updateCaption();
    }

    beforeSave() {
        if (this.Pages && this.SelectedItem) {
            const pages = [];
            this.Pages.forEach(page => {
                page.Data.Layout = JSON.stringify(page.Layout);
                pages.push(page.Data);
            });
            this.SelectedItem.Pages = pages;
        }
    }

    moveUp() {
        if (this.selectedNode && this.Pages) {
            const index = this.Pages.indexOf(this.selectedNode);
            if (index > 0) {
                this.Pages.splice(index, 1);
                this.Pages.splice(0, 0, this.selectedNode);
            }
            this.OnItemChanged();
        }
    }

    moveOneUp() {
        if (this.selectedNode && this.Pages) {
            const index = this.Pages.indexOf(this.selectedNode);
            if (index > 0) {
                this.Pages.splice(index, 1);
                this.Pages.splice(index - 1, 0, this.selectedNode);
            }
            this.OnItemChanged();
        }
    }

    moveOneDown() {
        if (this.selectedNode && this.Pages) {
            const index = this.Pages.indexOf(this.selectedNode);
            if (index > -1 && index < this.Pages.length - 1) {
                this.Pages.splice(index, 1);
                this.Pages.splice(index + 1, 0, this.selectedNode);
            }
            this.OnItemChanged();
        }
    }

    moveDown() {
        if (this.selectedNode && this.Pages) {
            const index = this.Pages.indexOf(this.selectedNode);
            if (index > -1 && index < this.Pages.length - 1) {
                this.Pages.splice(index, 1);
                this.Pages.push(this.selectedNode);
            }
            this.OnItemChanged();
        }
    }

    addClick() {
        this.addItem();
        this.OnItemChanged();
    }

    addItem() {
        const template = new Layout();
        template.Name = TranslateHelper.TranslatorInstance.instant('@@Neue Seite');
        template['ElementType'] = 'template';
        template['Type'] = 'template';
        template.Elements = [new LayoutElement()];
        const page = {
            Caption: template.Name,
            Layout: template,
            Data: {
                Caption: new TranslatedString(template.Name),
                Layout: JSON.stringify(template),
            }
        };
        this.Pages.push(page);
        this.onItemClick(page);
    }

    deleteItem() {
        if (this.selectedNode && this.Pages && this.Pages.length > 1) {
            const index = this.Pages.indexOf(this.selectedNode);
            if (index > -1) {
                this.Pages.splice(index, 1);
                this.onItemClick(this.Pages[0]);
                this.OnItemChanged();
            }
        }
    }

    updateLayout(item) {
        let viewType = 1;
        if (item) {
            viewType = 0;
            item.SetViewType(0);
            if (!item['Type']) {
                item['Type'] = item['ElementType'];
            }
        }
        LayoutService.SelectedLayout.next(item);
        LayoutService.SelectedItem.next(item);
        if (LayoutService.ViewType.getValue() !== viewType) {
            LayoutService.ViewType.next(viewType);
        }
    }

    onPageCaptionChanged() {
        if (this.selectedNode) {
            this.selectedNode.Caption = TranslatedString.GetTranslation(this.selectedNode.Data.Caption);
        }
        this.OnItemChanged();
    }

    updateModules() {
        const modules = [];
        if (this.SelectedItem && this.SelectedItem.Workflow && this.SelectedItem.Workflow.Modules) {
            this.SelectedItem.Workflow.Modules.forEach(mod => {
                const desc = WF_REGISTRY.get(mod.Module);
                if (desc) {
                    modules.push({
                        Key: 'workflow-edit-control',
                        Caption: desc.Caption + ' (' + mod.ID + ')',
                        // Icon: reg.Icon,
                        Default: {
                            Type: 'workflow-edit-control',
                            Layout: {
                                ModuleInfo: {
                                    ID: mod.ID,
                                    Module: mod.Module
                                }
                            }
                        }
                    });
                }
            });
        }
        this.WorkflowModules = modules;
    }

    undoStack: any[] = [];
    redoStack: any[] = [];

    undo() {
        if (this.undoStack.length > 0) {
            let v = this.undoStack.pop();
            this.redoStack.push(v);
            this.setItemSelection(new WorkflowSaveObject());
            const wf = plainToClass(WorkflowSaveObject, v);
            this.setItemSelection(wf);
        }
    }

    redo(){
        if(this.redoStack.length > 0) {
            let v = this.redoStack.pop();
            this.undoStack.push(v);
            this.setItemSelection(new WorkflowSaveObject());
            const wf = plainToClass(WorkflowSaveObject, v);
            this.setItemSelection(wf);
        }
    }

    private setItemSelection(item) {
        this.SelectedItem.Workflow = item;
        this.cdRef.detectChanges();
    }

    resetStack() {
        this.undoStack = [];
        this.redoStack = [];
    }

    @HostListener('document:keydown', ['$event'])
    onKeyPress(event: KeyboardEvent){
        if(event.ctrlKey){
            if(event.key.toLowerCase() == 'z')
                this.undo();
            else if(event.key.toLowerCase() == 'y'){
                this.redo();
            }
        }
    }
}

@Component({
    selector: 'workflow-module-drag-panel',
    templateUrl: './workflow.module.drag.panel.html',
    styleUrls: ['./workflow.wizard.settings.css']
})
export class WorkflowModuleDragPanel extends AMenuTabContent {
    //#region Modules
    @Input()
    get Modules() {
        return this.Items;
    }
    set Modules(val) {
        if (Array.isArray(val)) {
            this.Items = val;
        } else {
            this.Items = [];
        }
        this.ModulesChange.emit(this.Items);
    }

    @Output() ModulesChange = new EventEmitter<any>();
    //#endregion
}
