import {
    Component, DoCheck, ElementRef, EventEmitter, Input, KeyValueChangeRecord, KeyValueDiffers, Output, ViewChild, ChangeDetectionStrategy
} from '@angular/core';
import { ABaseCell } from './a.base.cell';

@Component({
    selector: 'evi-spreadsheet-cell',
    templateUrl: './spreadsheet.cell.html',
    styleUrls: ['./spreadsheet.cell.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class SpreadSheetCell extends ABaseCell {

    @ViewChild('cell', { read: ElementRef })
    private inputRef: ElementRef;
    //#region Editable
    EditableValue;

    @Input()
    get Editable() {
        return this.EditableValue;
    }
    set Editable(val) {
        this.EditableValue = val;
    }
    //#endregion

    EditValue;

    @Output() KeyPress = new EventEmitter<any>();
    differ;

    constructor(diff: KeyValueDiffers) {
        super();
        this.differ = diff.find({}).create();
    }

    ngDoCheck(): void {
        if (this.RowValue) {
            const changes = this.differ.diff(this.RowValue.cols[this.Column.Name]);
            if (changes) {
                changes.forEachChangedItem((record: KeyValueChangeRecord<any, any>) => {
                    if (record.key === 'selected' && record.currentValue === true) {
                        this.inputRef.nativeElement.focus();
                    }
                    if (record.key === 'edit' && this.Row.cols[this.Column.Name].selected) {
                        this.inputRef.nativeElement.focus();
                    }
                });
            }
        }
    }   

    keyboardaction(event: KeyboardEvent) {
        //console.log(event);
    }

    CellEditInternal(Name) {
        const data = this.Row.cols[Name];
        if (data.editable !== false) {
            data.edit = !data.edit;
        }
        if (this.DataTableValue.IsPlanningTable) {
            this.EditValue = this.Row.internal[Name];
        }
    }
    ValueChanged() {
        if (this.DataTableValue.IsPlanningTable) {
            this.DataTableValue.ValueChanged.emit({
                RowIndex: this.Row.index,
                ColumnName: this.Column.Name,
                OldValue: this.Row.internal[this.Column.Name],
                NewValue: this.EditValue
            });
        }
    }

    keyPress(event) {
        this.KeyPress.emit({ event: event, column: this.Column });
    }

    mousedown(event: MouseEvent) {
        event.stopPropagation();
        this.DataTableValue.SelectionHelper.Selecting = true;
        this.DataTableValue.SelectionHelper.StartCell = {
            RowIndex: this.RowValue.index,
            ColumnName: this.Column.Name
        }
        console.log('MouseDown');
    }
    mouseup() {
        this.DataTableValue.SelectionHelper.EndCell = {
            RowIndex: this.RowValue.index,
            ColumnName: this.Column.Name
        }
        //SelectionHelper.Selecting = false;
        if (this.DataTableValue.SelectionHelper.Copying) {
            this.DataTableValue.SelectionHelper.Copying = false;
            this.DataTableValue.SelectionHelper.CopyComplete();
        }
        //else {
        //    SelectionHelper.SelectionComplete.next();
        //}
    }
    CopyDown(event: MouseEvent) {
        event.stopPropagation();
        this.DataTableValue.SelectionHelper.InitSelection();
        this.DataTableValue.SelectionHelper.Copying = true;
        this.DataTableValue.SelectionHelper.Selecting = false;
        this.DataTableValue.SelectionHelper.StartCell = {
            RowIndex: this.RowValue.index,
            ColumnName: this.Column.Name
        }
    }

    ShowCellCopy() {
        let retVal = false;
        if (this.DataTable.IsPlanningTable && this.Row && this.Row.cols && this.Row.cols[this.Column.Name].selected) {
            if (this.DataTableValue.OSSelectionRanges && this.DataTableValue.OSSelectionRanges.length == 1) {
                let range = this.DataTableValue.OSSelectionRanges[0];
                if (range.XEnd == range.XStart && range.YStart == range.YEnd) {
                    retVal = true;
                }
            }
        }
        return retVal;
    }
}
