import { Base, JsonIgnore } from '../../base.model';
import { AttributeProperty } from '../../ContainerClassObject';
import { ResourceBase } from '../../enums/resourcebase.enum';
import { RequestFilter } from '../../rest/requestfilter';

export class DataDescriptionRelational extends Base {

    Fields = [];
    Relations = [];
    WhereFilter: RequestFilter;
    Top: number;

    ContainersValue: any[] = [];
    get Containers() {
        return this.ContainersValue;
    }
    set Containers(val) {
        this.ContainersValue = val;
        this.SetColumnsFilter();
    }

    @JsonIgnore
    ColumnsFilter: any[] = [];
    @JsonIgnore
    SetColumnsFilter() {
        const columns: any[] = [];
        if (this.Containers) {
            this.Containers.forEach((container) => {
                if (container && container.Fields && container.ResourceBase != ResourceBase.Filebase) {
                    container.Fields.forEach(function (field) {
                        columns.push({
                            IsFilterable: true,
                            PropertyName: field.Caption,
                            Caption: field.Caption + ' [' + container.Caption + ']',
                            Type: AttributeProperty.GetFilterTypeFromType(field.FieldType),
                            AdditionalInfos: { FieldID: field.ID, ContainerID: container.SID, FieldUniqueID: field.UniqueID, ContainerUniqueID: container.UniqueID }
                        });
                    });
                }
            });
        }
        this.ColumnsFilter = columns;
    }

    Copy(): DataDescriptionRelational {
        var dd = new DataDescriptionRelational();
        return dd;
    }
}
