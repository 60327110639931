import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class WebHookService extends RequestBase {

    BasePath = 'api/webhook';

    constructor(http: HttpClient) {
        super(http);
    }
  
    //#region Themes
  
    GetAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAll', this.options);
    }
    GetByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetByID?id=' + id, this.options);
    }
    GetAllWithRestID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllWithRestID?restID=' + id, this.options);
    }
    GetNameByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetNameByID?id=' + id, this.options);
    }

    SaveNew(name): Observable<any> {
        return this.http.put<any>(this.API_BASE_URL + this.BasePath + '/SaveNew?name=' + name, this.options);
    }

    Save(item): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save',item, this.options);
    }

    Delete(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }
    //#endregion
}