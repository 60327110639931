import { Component } from '@angular/core';
import { AObjectPreviewSettings } from '../common/adatapreview.settings';

@Component({
    selector: 'container-json-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerJsonSettings extends AObjectPreviewSettings {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Container.ContainerJson';
    }

    getDBTypes(): number[][] {
        return [[1048576]]; // ObjectRead
    }

    getResourceBase(): number {
        return 1;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerJson',
            ID: 'containerjson',
            Icon: 'table_chart',
            Index: 10,
            Parent: 'objectcontainer',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
                Value: 2
            },
            Content: ContainerJsonSettings
        }
    }
}