import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Type } from 'class-transformer';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { DataModelService } from '../../../services/datamodel.service';
import { InteractivePropertyInfo } from '../../../components/common/datasourceselectioncontol/datasource.selection.control';

@Component({
    selector: 'container-chooser-control',
    templateUrl: './container.chooser.html'
})
export class ContainerChooser implements AfterViewInit {
    FilterColumns = [];
    @ViewChild('advanced') filterComponent;
    FilterInitialized = false;

    //#region Data
    DataValue: AContainerSettingsData;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        this.DataValue = val;
        this.DataChange.emit(this.DataValue);
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    //#region Variables
    VariablesValue = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        this.VariablesValue = val;
        this.VariablesChange.emit(this.VariablesValue);
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion

    //#region ShowFilter
    ShowFilterValue = false;

    @Input()
    get ShowFilter() {
        return this.ShowFilterValue;
    }
    set ShowFilter(val) {
        this.ShowFilterValue = val;
        this.ShowFilterChange.emit(this.ShowFilterValue);
    }

    @Output() ShowFilterChange = new EventEmitter<any>();
    //#endregion

    //#region ShowValues
    ShowValuesValue = false;

    @Input()
    get ShowValues() {
        return this.ShowValuesValue;
    }
    set ShowValues(val) {
        this.ShowValuesValue = val;
        this.ShowValuesChange.emit(this.ShowValuesValue);
    }

    @Output() ShowValuesChange = new EventEmitter<any>();
    //#endregion

    //#region DBUsages
    DBUsagesValue = [];

    @Input()
    get DBUsages() {
        return this.DBUsagesValue;
    }
    set DBUsages(val) {
        this.DBUsagesValue = val;
        this.DBUsagesChange.emit(this.DBUsagesValue);
    }

    @Output() DBUsagesChange = new EventEmitter<any>();
    //#endregion

    //#region FixedDataModels
    FixedDataModelsValue;

    @Input()
    get FixedDataModels() {
        return this.FixedDataModelsValue;
    }
    set FixedDataModels(val) {
        this.FixedDataModelsValue = val;
        this.FixedDataModelsChange.emit(this.FixedDataModelsValue);
    }

    @Output() FixedDataModelsChange = new EventEmitter<any>();
    //#endregion

    @Output() TableChanged = new EventEmitter<any>();

    constructor(private dataService: DataModelService) {
    }

    onDataChanged() {
        this.FilterColumns = [];
        if (this.DataValue) {
            this.DataValue.Filter = null;
            this.DataValue.Values = [];
            if (this.DataValue.ContainerTable) {
                this.dataService.GetFilterInfo(this.DataValue.ContainerTable).subscribe(result => {
                    if (result && result.CanFilter) {
                        const cols = [];
                        result.Columns.forEach(x => {
                            cols.push({
                                IsFilterable: true,
                                PropertyName: x.Name,
                                Caption: x.Caption,
                                Type: AttributeProperty.GetFilterTypeFromType(x.DataType)
                            });
                        });
                        this.FilterColumns = cols;
                    } else {
                        this.DataValue.ContainerTable = null;
                    }
                    this.updateFilter();
                });
            } else {
                this.updateFilter();
            }
        }
        this.TableChanged.emit();
    }


    onDataChecked() {
        this.FilterColumns = [];
        if (this.DataValue) {
            if (this.DataValue.ContainerTable) {
                this.dataService.GetFilterInfo(this.DataValue.ContainerTable).subscribe(result => {
                    if (result && result.CanFilter) {
                        const cols = [];
                        result.Columns.forEach(x => {
                            cols.push({
                                IsFilterable: true,
                                PropertyName: x.Name,
                                Caption: x.Caption,
                                Type: AttributeProperty.GetFilterTypeFromType(x.DataType)
                            });
                        });
                        this.FilterColumns = cols;
                    } else {
                        this.DataValue.Filter = null;
                        this.DataValue.Values = [];
                        this.DataValue.ContainerTable = null;
                    }
                    this.updateFilter();
                });
            } else {
                this.updateFilter();
            }
        }
    }

    ngAfterViewInit(): void {
        this.updateFilter();
    }

    updateFilter() {
        if (this.filterComponent) {
            let filter = new RequestFilter();
            if (this.DataValue && this.DataValue.Filter && this.DataValue.Filter.Filters && this.DataValue.Filter.Filters.length > 0) {
                filter = this.DataValue.Filter.Filters[0];
            }
            this.filterComponent.SetFilter(filter);
        }
    }

    CheckActualFilter() {
        if (this.filterComponent && this.DataValue) {
            const filter = this.filterComponent.GetActualFilter();
            if (filter && filter.Filters && filter.Filters.length > 0) {
                this.DataValue.Filter = RequestOptions.CleanRequestOptions();
                this.DataValue.Filter.Filters.push(filter);
            } else {
                this.DataValue.Filter = null;
            }
        }
    }

    afterExpand() {
        if (!this.FilterInitialized && this.filterComponent) {
            this.updateFilter();
            this.FilterInitialized = true;
        }
    }

    addValue() {
        if (this.DataValue) {
            if (this.DataValue.Values) {
                this.DataValue.Values.push(new ExecuteValue());
            } else {
                this.DataValue.Values = [new ExecuteValue()];
            }
        }
    }

    removeValue(i) {
        if (this.DataValue && this.DataValue.Values) {
            this.DataValue.Values.splice(i, 1);
        }
    }
}

// @dynamic
export abstract class ABaseContainerSettingsData extends AServiceWorkflowData {
    DataModel: string;
    DataSource: string;
    @Type(() => InteractivePropertyInfo)
    InteractiveProperties: InteractivePropertyInfo[] = [];
    ContainerTable: string;
    ContainerInternalID: string;
    @Type(() => RequestOptions)
    Filter: RequestOptions;
}

// @dynamic
export abstract class AContainerSettingsData extends ABaseContainerSettingsData {
    @Type(() => ExecuteValue)
    Values: ExecuteValue[] = [];
}

export class ExecuteValue {
    ColumnName: string;
    Value: string;
}
