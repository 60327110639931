import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';
import { InjectorHelper } from '../../helpers/injector.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { MetaHelper } from '../../helpers/meta.helper';
import { PropertyGroupDisplay } from '../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../models/enums/viewtype.enum';
import { MenuTabLabelPosition } from '../../models/menutab/menutab.property.model';
import { MetaService } from '../../services/meta.service';
import {
	PROPERTIES,
	PROPERTYGROUPS,
} from '../../services/dynamic.component.service';
import { LayoutService } from '../../services/layout.service';
import { ComboboxThemeControl } from '../controls/combobox/combobox.theme.control';
import { TextAreaThemeControl } from '../controls/textarea/textarea.theme.control';
import { CheckBoxThemeControl } from '../controls/checkbox/checkbox.control';
import { TogglebuttonThemeControl } from '../controls/togglebutton/togglebutton.theme.control';
import { ColorpickerThemeControl } from '../controls/colorpicker/colorpicker.theme.control';
import { GenericMenuTab } from '../menutabs/generic/generic.menu.tab';
import { BasePanel } from './base.panel';

export class SimpleReportingStylePanel extends BasePanel {
	static override SIDS = ['c10149a8-82d6-4b21-8ae3-11cf20aefe6d'];
	static override SetSelectedItem(item) {
		SimpleReportingStylePanel.CheckItem();
	}
	private static ResetDataBinding(LayoutElement, idList) {
		if (LayoutElement) {
			LayoutElement.DataSource = null;
			LayoutElement.DataSourceID = null;
			LayoutElement.DataModelID = null;
			LayoutElement.DataFilter = null;
			idList.push(LayoutElement.ID);
			if (LayoutElement.Elements && LayoutElement.Elements.length > 0) {
				LayoutElement.Elements.forEach((item) => {
					SimpleReportingStylePanel.ResetDataBinding(item, idList);
				});
			}
		}
	}
	private static Palettes: BehaviorSubject<any> = new BehaviorSubject(null);
	// to get all the initial data from the services
	private static CheckItem() {
		if (SimpleReportingStylePanel.SelectedItem) {
		}
	}

	private static chartPaletteService;
	static InitPanel() {
		SimpleReportingStylePanel.chartPaletteService =
			InjectorHelper.InjectorInstance.get<MetaService>(MetaService);

		this.InitSelectedItem();
		SimpleReportingStylePanel.chartPaletteService
			.ReadPaletteInfos()
			.subscribe((paletteInfos: any) => {
				if (paletteInfos) {
					SimpleReportingStylePanel.Palettes.next(paletteInfos);
				}
			});
		PROPERTYGROUPS.push({
			SID: 'c10149a8-82d6-4b21-8ae3-11cf20aefe6d',
			ID: 'ChartSettings',
			Caption: '@@Chart Settings',
			Index: 99,
			Content: GenericMenuTab,
			Display: PropertyGroupDisplay.Grid,
			Columns: ['1fr', '1fr', '1fr', '1fr'],
			Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
			CheckVisibilityAsync: (item) => {
				return new Promise((resolve) => {
					const selected = LayoutService.SelectedItem.getValue();
					if (
						selected &&
						selected.ElementType &&
						selected.ElementType === 'reporting'
					) {
						const layout = LayoutHelper.GetLayout();
						const resolution = LayoutHelper.GetActiveResolution(layout);
						MetaHelper.FindDataBindingProperties(resolution, selected).then(
							(result) => {
								resolve(!result || !result.IsDataBinding);
							}
						);
					} else {
						resolve(false);
					}
				});
			},
		});
		LayoutService.ViewType.subscribe((vt) => {
			if (vt == ViewType.Edit && SimpleReportingStylePanel.SelectedItem) {
				SimpleReportingStylePanel.CheckItem();
			}
		});
		const chartTypes = [
			// { Caption: '@@Area Chart', Value: 'areachart' },
			{ Caption: '@@Bar Chart', Value: 'bar' },
			// { Caption: '@@Column Chart', Value: 'columnchart' },
			{ Caption: '@@Doughnut Chart', Value: 'doughnut' },
			{ Caption: '@@Line Chart', Value: 'line' },
			{ Caption: '@@Pie Chart', Value: 'pie' },
			// { Caption: '@@Bubble Chart', Value: 'bubblechart' },
			{ Caption: '@@Polar Chart', Value: 'polarArea' },
			{ Caption: '@@Radar', Value: 'radar' },
		];

		PROPERTIES.push({
			ID: 'ChartType',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Chart Type',
			Column: 1,
			ColSpan: 4,
			Row: 1,
			InitArgs: {
				Placeholder: '@@Chart Type',
				Multiple: false,
				ItemsSource: chartTypes,
				ValueMemberPath: 'Value',
				DisplayMemberPath: 'Caption',
			},
		});
		PROPERTIES.push({
			ID: 'DisableAutoLoad',
			Parent: 'ChartSettings',
			Label: '@@Disable Auto Load',
			Content: new ComponentPortal(CheckBoxThemeControl),
			Column: 1,
			ColSpan: 4,
			Row: 2,
			LabelPosition: MenuTabLabelPosition.Left,
			InitArgs: {
				// Caption: '@@Disable Auto Load',
			},
		});
		PROPERTIES.push({
			ID: 'ChartPalette',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Chart Palette',
			// LabelPosition:MenuTabLabelPosition.Left,
			Column: 1,
			ColSpan: 4,
			Row: 3,
			InitArgs: {
				Placeholder: '@@Chart Palette',
				Multiple: false,
				ItemsSourceSub: SimpleReportingStylePanel.Palettes,
				ValueMemberPath: 'SID',
				DisplayMemberPath: 'Caption',
			},
		});
		const legendPositions = [
			{
				Caption: '@@Top',
				Value: 'top',
			},
			{
				Caption: '@@Bottom',
				Value: 'bottom',
			},
			{
				Caption: '@@Left',
				Value: 'left',
			},
			{
				Caption: '@@Right',
				Value: 'right',
			},
			{
				Caption: '@@Hide',
				Value: 'hide',
			},
		];

		PROPERTIES.push({
			ID: 'LegendPosition',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Legend Position',
			Column: 1,
			ColSpan: 4,
			Row: 4,
			InitArgs: {
				Multiple: false,
				ItemsSource: legendPositions,
				DisplayMemberPath: 'Caption',
				ValueMemberPath: 'Value',
			},
		});

		PROPERTIES.push({
			ID: 'XLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(TextAreaThemeControl),
			Label: '@@X Label',
			Column: 1,
			ColSpan: 4,
			Row: 5,
		});

		// create a new property group for the bold the x label
		PROPERTIES.push({
			ID: 'BoldXLabel',
			Parent: 'ChartSettings',
			Label: '@@Bold',
			Content: new ComponentPortal(TogglebuttonThemeControl),
			Column: 1,
			Row: 6,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.XLabel && item.XLabel.length > 0;
			},
			InitArgs: {
				Icon: 'bold',
				// Caption: '@@Disable Auto Load',
			},
		});

		// create a new property group for the italic the x label
		PROPERTIES.push({
			ID: 'ItalicXLabel',
			Parent: 'ChartSettings',
			Label: '@@Italic',
			Content: new ComponentPortal(TogglebuttonThemeControl),
			Column: 2,
			Row: 6,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.XLabel && item.XLabel.length > 0;
			},
			InitArgs: {
				Icon: 'italic',
				// Caption: '@@Disable Auto Load',
			},
		});
		PROPERTIES.push({
			ID: 'ColorXLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ColorpickerThemeControl),
			Label: '@@Color',
			Column: 3,
			Row: 6,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.XLabel && item.XLabel.length > 0;
			},
			InitArgs: {
				Icon: 'color_fill',
			},
		});

		// create a new property group for the y label font size
		const fontSizes = [
			{ Caption: '8', Value: '8' },
			{ Caption: '9', Value: '9' },
			{ Caption: '10', Value: '10' },
			{ Caption: '11', Value: '11' },
			{ Caption: '12', Value: '12' },
			{ Caption: '14', Value: '14' },
			{ Caption: '16', Value: '16' },
			{ Caption: '18', Value: '18' },
			{ Caption: '20', Value: '20' },
			{ Caption: '22', Value: '22' },
			{ Caption: '24', Value: '24' },
			{ Caption: '26', Value: '26' },
			{ Caption: '28', Value: '28' },
			{ Caption: '36', Value: '36' },
			{ Caption: '48', Value: '48' },
			{ Caption: '72', Value: '72' },
		];
		PROPERTIES.push({
			ID: 'FontSizeXLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Font Size',
			Column: 4,
			Row: 6,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.XLabel && item.XLabel.length > 0;
			},
			InitArgs: {
				Placeholder: '@@Font Size',
				Multiple: false,
				ItemsSource: fontSizes,
				ValueMemberPath: 'Value',
				DisplayMemberPath: 'Caption',
			},
		});
		// create a new property group input y label
		PROPERTIES.push({
			ID: 'YLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(TextAreaThemeControl),
			Label: '@@Y Label',
			Column: 1,
			ColSpan: 4,
			Row: 7,
		});

		// create a new property group for the bold the y label
		PROPERTIES.push({
			ID: 'BoldYLabel',
			Parent: 'ChartSettings',
			Label: '@@Bold',
			Content: new ComponentPortal(TogglebuttonThemeControl),
			Column: 1,
			Row: 8,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.YLabel && item.YLabel.length > 0;
			},
			InitArgs: {
				Icon: 'bold',
				// Caption: '@@Disable Auto Load',
			},
		});

		// create a new property group for the italic the y label

		PROPERTIES.push({
			ID: 'ItalicYLabel',
			Parent: 'ChartSettings',
			Label: '@@Italic',
			Content: new ComponentPortal(TogglebuttonThemeControl),
			Column: 2,
			Row: 8,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.YLabel && item.YLabel.length > 0;
			},
			InitArgs: {
				Icon: 'italic',
				// Caption: '@@Disable Auto Load',
			},
		});

		PROPERTIES.push({
			ID: 'ColorYLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ColorpickerThemeControl),
			Label: '@@Color',
			Column: 3,
			Row: 8,
			LabelPosition: MenuTabLabelPosition.Bottom,
			CheckVisibility: (item) => {
				return item.YLabel && item.YLabel.length > 0;
			},
			InitArgs: {
				Icon: 'color_fill',
			},
		});

		PROPERTIES.push({
			ID: 'FontSizeYLabel',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ComboboxThemeControl),
			Label: '@@Font Size',
			LabelPosition: MenuTabLabelPosition.Bottom,
			Column: 4,
			Row: 8,
			CheckVisibility: (item) => {
				return item.YLabel && item.YLabel.length > 0;
			},
			InitArgs: {
				Placeholder: '@@Font Size',
				Multiple: false,
				ItemsSource: fontSizes,
				ValueMemberPath: 'Value',
				DisplayMemberPath: 'Caption',
			},
		});
		PROPERTIES.push({
			ID: 'GridColor',
			Parent: 'ChartSettings',
			Content: new ComponentPortal(ColorpickerThemeControl),
			Label: '@@Grid Color',
			Column: 1,
			ColSpan: 4,
			Row: 9,
			LabelPosition: MenuTabLabelPosition.Left,
			InitArgs: {
				Icon: 'color_fill',
			},
		});
		PROPERTIES.push({
			ID: 'HideXAxisGrid',
			Parent: 'ChartSettings',
			Label: '@@Hide X Axis Grid',
			Content: new ComponentPortal(CheckBoxThemeControl),
			Column: 1,
			ColSpan: 4,
			Row: 10,
			LabelPosition: MenuTabLabelPosition.Left,
			CheckVisibility: (item) => { 
				const gridTypes = ['line', 'bar', 'radar'];
				return gridTypes.includes(item.ChartType);
			}
		});

		PROPERTIES.push({
			ID: 'HideYAxisGrid',
			Parent: 'ChartSettings',
			Label: '@@Hide Y Axis Grid',
			Content: new ComponentPortal(CheckBoxThemeControl),
			Column: 1,
			ColSpan: 4,
			Row: 11,
			LabelPosition: MenuTabLabelPosition.Left,
			CheckVisibility: (item) => {
				const gridTypes = ['line', 'bar', 'radar'];
				return gridTypes.includes(item.ChartType);
			},
		});
	}
}
