import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { WorkflowParameterType } from '../../../models/enums/workflowparametertype.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-AddDataWF-settings',
    templateUrl: 'add.settings.html',
    styleUrls: ['add.settings.css']
})
export class AddDataWFSettings extends FormulaWorkflowDialogContent {
    Data = new AddDataWFSettingsData();
    PropList = [];
    DataTypes;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'AddDataWFModule';
        reg.Caption = '@@AddItem';
        reg.GroupID = 'dataoperations';
        reg.Index = 10;
        reg.SettingsControl = AddDataWFSettings;
        reg.SettingsTypeHelper = new AddDataWFSettingsDataHelper();
        reg.Executer = AddDataWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
        this.DataTypes = EnumHelper.GetDropdownValues(WorkflowParameterType);
    }

    ngOnInit(): void {
        this.onNameChange();
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = JSON.stringify(data);
            this.Data = plainToClass(AddDataWFSettingsData, JSON.parse(json));
        }
    }

    getResult(): any {
        if (this.Data && this.Data.DefaultProps) {
            const filledProps = [];
            this.Data.DefaultProps.forEach(p => {
                if (p.Name && typeof p.Value !== 'undefined' && p.Value !== null) {
                    filledProps.push(p);
                }
            });
            this.Data.DefaultProps = filledProps;
        }
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }

    onNameChange() {
        this.PropList = [];
        if (this.Data && this.Data.ElemName && this.WFEditOptions && this.WFEditOptions.Layout) {
            const elem = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, this.Data.ElemName);
            if (elem) {
                MetaHelper.FindTableProperties(this.WFEditOptions.Layout, elem).then(tableProps => {
                    if (tableProps && tableProps.Fields) {
                        this.PropList = tableProps.Fields.slice();
                    }
                });
            }
        }
    }

    addProp() {
        if (this.Data) {
            if (this.Data.DefaultProps) {
                this.Data.DefaultProps.push({
                    Name: '',
                    Value: ''
                });
            } else {
                this.Data.DefaultProps = [{
                    Name: '',
                    Value: ''
                }];
            }
        }
    }

    propSelected(prop, item) {
        prop.Name = item.Name;
    }

    deleteParam(i) {
        this.Data.DefaultProps.splice(i, 1);
    }
}

export class AddDataWFSettingsData {
    ElemName: string;
    DefaultProps = [];
}

export class AddDataWFSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new AddDataWFSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class AddDataWFModuleExecuter extends WorkflowModuleExecuter {
    static async fillProperties(DataSource, item) {
        const table = await CacheService.ReadTable(item.AdvancedType);
        if (table) {
            const fields = table['Fields'];
            if (fields) {
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    if (field.IsList) {
                        DataSource[field.Name] = [];
                    } else if (field.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c' && !field.IsShared) {
                        DataSource[field.Name] = {};
                        await AddDataWFModuleExecuter.fillProperties(DataSource[field.Name], field);
                    }
                }
            }
        }
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        const DataSource = {};
                        const props = await MetaHelper.FindTableProperties(status.WorkflowLayoutService.Layout, elem);
                        if (props && props.Fields && props.Fields.length > 0) {
                            for (let i = 0; i < props.Fields.length; i++) {
                                const field = props.Fields[i];
                                if (field.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c' && !field.IsList && !field.IsShared) {
                                    DataSource[field.Name] = {};
                                    await AddDataWFModuleExecuter.fillProperties(DataSource[field.Name], field);
                                }
                            }
                        }
                        if (status.ActualSettings.DefaultProps) {
                            const calculator = new WorkflowFormulaCalculator(status);
                            status.ActualSettings.DefaultProps.forEach(p => {
                                try {
                                    DataSource[p.Name] = calculator.CalcFormula(p.Value);
                                } catch (ex) {
                                    status.Logger.logWarning('AddDataWF modul: Error while calculating property ' + p.Name +
                                        ' (Formula: ' + p.Value + ', Error: ' + ex + ')');
                                }
                            });
                        }
                        elem.Element.DataSource = DataSource;
                        status.ActualParameter = DataSource;
                        return 0;
                    } else {
                        status.Logger.logError('AddDataWF modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('AddDataWF modul: Element not found.');
                }
            } else {
                status.Logger.logError('AddDataWF modul: No element name found.');
            }
        } else {
            status.Logger.logError('AddDataWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
