import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class UserSettingsEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/usersettings/';
    EndPoints = [
        {
            Name: 'ReadByID',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
                        return value.ID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        {
            Name: 'ReadByLayout',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
                        return value.LayoutID == params['id'] && value.Profile == '<=!AutoSaveProfile!=>';
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        {
            Name: 'ReadByLayoutProfile',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
                        return value.LayoutID == params['layoutid'] && value.Profile == params['name'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //{
        //    Name: 'AddOrUpdate',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'Remove',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'RemoveByLayout',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'RemoveByLayoutAndItemAuto',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'RemoveByLayoutAndItemProfile',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'RemoveByLayoutProfile',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettings'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        {
            Name: 'ReadProfileByLayout',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettingsProfile'].filter((value) => {
                        return value.LayoutID == params['id'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //{
        //    Name: 'CreateProfile',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettingsProfile'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //{
        //    Name: 'DeleteProfile',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.UserSettings.UserSettingsProfile'].filter((value) => {
        //                return value.LayoutID == params['id'] && value.Profile == params['name'];
        //            }).toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
    ]
}