import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataModelService, DataSourceQuery } from '../services/datamodel.service';

@Component({
    selector: 'workflow-container-chooser',
    templateUrl: './workflow.container.chooser.html'
})
export class WorkflowContainerChooser implements OnInit {
    DataSourceQuery = new DataSourceQuery(null);
    DataModels = [];
    Tables = [];
    Initialized = false;

    //#region Data
    DataValue;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        this.DataValue = val;
        if (this.Initialized) {
            this.checkDataValue();
        }
        this.DataChange.emit(this.DataValue);
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    //#region Variables
    VariablesValue = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        this.VariablesValue = val;
        this.VariablesChange.emit(this.VariablesValue);
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion

    //#region DBUsages
    DBUsagesValue = [];

    @Input()
    get DBUsages() {
        return this.DBUsagesValue;
    }
    set DBUsages(val) {
        this.DBUsagesValue = val;
        this.DBUsagesChange.emit(this.DBUsagesValue);
    }

    @Output() DBUsagesChange = new EventEmitter<any>();
    //#endregion

    //#region IgnoreResourceBase
    IgnoreResourceBaseValue = true;

    @Input()
    get IgnoreResourceBase() {
        return this.IgnoreResourceBaseValue;
    }
    set IgnoreResourceBase(val) {
        this.IgnoreResourceBaseValue = val;
        this.IgnoreResourceBaseChange.emit(this.IgnoreResourceBaseValue);
    }

    @Output() IgnoreResourceBaseChange = new EventEmitter<any>();
    //#endregion

    //#region OnlyQueryable
    OnlyQueryableValue = false;

    @Input()
    get OnlyQueryable() {
        return this.OnlyQueryableValue;
    }
    set OnlyQueryable(val) {
        this.OnlyQueryableValue = val;
        this.OnlyQueryableChange.emit(this.OnlyQueryableValue);
    }

    @Output() OnlyQueryableChange = new EventEmitter<any>();
    //#endregion

    //#region FillInteractiveProperties
    FillInteractivePropertiesValue = true;

    @Input()
    get FillInteractiveProperties() {
        return this.FillInteractivePropertiesValue;
    }
    set FillInteractiveProperties(val) {
        this.FillInteractivePropertiesValue = val;
        this.FillInteractivePropertiesChange.emit(this.FillInteractivePropertiesValue);
    }

    @Output() FillInteractivePropertiesChange = new EventEmitter<any>();
    //#endregion

    //#region FixedDataModels
    FixedDataModelsValue;

    @Input()
    get FixedDataModels() {
        return this.FixedDataModelsValue;
    }
    set FixedDataModels(val) {
        this.FixedDataModelsValue = val;
        this.FixedDataModelsChange.emit(this.FixedDataModelsValue);
    }

    @Output() FixedDataModelsChange = new EventEmitter<any>();
    //#endregion

    @Output() OnDataChanged = new EventEmitter<any>();
    @Output() OnDataChecked = new EventEmitter<any>();

    constructor(private dataService: DataModelService) {
    }

    ngOnInit() {
        if (Array.isArray(this.FixedDataModelsValue)) {
            this.Initialized = true;
            this.DataModels = [...this.FixedDataModelsValue];
            this.checkDataValue();
        } else {
            this.dataService.GetModels().subscribe(models => {
                this.Initialized = true;
                if (models) {
                    this.DataModels = models;
                    this.checkDataValue();
                } else {
                    if (this.DataValue) {
                        this.DataValue.DataModel = null;
                        this.DataValue.DataSource = null;
                        this.DataValue.InteractiveProperties = [];
                        this.DataValue.ContainerTable = null;
                        this.DataValue.ContainerInternalID = null;
                        this.OnDataChanged.emit();
                    }
                }
            });
        }
    }

    modelChanged() {
        this.DataSourceQuery = new DataSourceQuery(null);
        this.Tables = [];
        if (this.DataValue) {
            this.DataValue.DataSource = null;
            this.DataValue.InteractiveProperties = [];
            this.DataValue.ContainerTable = null;
            this.DataValue.ContainerInternalID = null;
            if (this.DataValue.DataModel) {
                this.updateQuery();
            }
        }
        this.OnDataChanged.emit();
    }

    dataSourceChanged() {
        this.Tables = [];
        if (this.DataValue) {
            this.DataValue.ContainerTable = null;
            this.DataValue.ContainerInternalID = null;
            if (this.DataValue.DataModel && this.DataValue.DataSource) {
                this.DataSourceQuery['DataSourceID'] = this.DataValue.DataSource;
                this.dataService.GetContainerByDBUsage(this.DataSourceQuery).subscribe(tables => {
                    if (tables) {
                        this.Tables = tables;
                    }
                });
            }
        }
        this.OnDataChanged.emit();
    }

    tableChanged() {
        if (this.DataValue && this.Tables) {
            const table = this.Tables.find(x => x.SID === this.DataValue.ContainerTable);
            if (table) {
                this.DataValue.ContainerInternalID = table.InternalID;
            }
        }
        this.OnDataChanged.emit();
    }

    checkDataValue() {
        if (this.DataValue) {
            if (this.DataModels.some(x => x.SID === this.DataValue.DataModel)) {
                this.updateQuery();
                this.dataService.GetDataSourcesByQuery(this.DataSourceQuery).subscribe(dataSources => {
                    if (dataSources) {
                        if (dataSources.some(x => x.SID === this.DataValue.DataSource)) {
                            this.dataService.GetContainerByDBUsage(this.DataSourceQuery).subscribe(t => {
                                if (t) {
                                    this.Tables = t;
                                    if (t.some(x => x.SID === this.DataValue.ContainerTable)) {
                                        this.OnDataChecked.emit();
                                    } else {
                                        this.DataValue.ContainerTable = null;
                                        this.DataValue.ContainerInternalID = null;
                                        this.OnDataChanged.emit();
                                    }
                                } else {
                                    this.DataValue.ContainerTable = null;
                                    this.DataValue.ContainerInternalID = null;
                                    this.OnDataChanged.emit();
                                }
                            });
                        } else {
                            this.DataValue.DataSource = null;
                            this.DataValue.InteractiveProperties = [];
                            this.DataValue.ContainerTable = null;
                            this.DataValue.ContainerInternalID = null;
                            this.OnDataChanged.emit();
                        }
                    } else {
                        this.DataValue.DataSource = null;
                        this.DataValue.InteractiveProperties = [];
                        this.DataValue.ContainerTable = null;
                        this.DataValue.ContainerInternalID = null;
                        this.OnDataChanged.emit();
                    }
                });
            } else {
                if (this.DataModels.length === 1) {
                    this.DataValue.DataModel = this.DataModels[0].SID;
                    this.modelChanged();
                } else {
                    this.DataValue.DataModel = null;
                    this.DataValue.DataSource = null;
                    this.DataValue.InteractiveProperties = [];
                    this.DataValue.ContainerTable = null;
                    this.DataValue.ContainerInternalID = null;
                    this.OnDataChanged.emit();
                }
            }
        }
    }

    updateQuery() {
        const retVal = new DataSourceQuery(this.DataValue.DataModel);
        retVal.DBUsages = this.DBUsagesValue;
        retVal.IgnoreResourceBase = this.IgnoreResourceBaseValue;
        retVal.FillInteractiveProperties = this.FillInteractivePropertiesValue;
        retVal['OnlyQueryable'] = this.OnlyQueryableValue;
        retVal['DataSourceID'] = this.DataValue.DataSource;
        this.DataSourceQuery = retVal;
    }
}
