import { Component } from '@angular/core';
import { BaseDialog } from '../../../../../components/dialogs/basedialog/base.dialog';
import { RestService } from '../../../../../services/rest.service';
import { BaseListDetail } from '../../../../../settings/base.list.settings';
import { ContainerRest } from '../../../models/rest.container.classobject';
import { RestObject } from '../../../models/rest.object';
import { TemplateColumnDialog } from './template.column.dialog/template.column.dialog';

@Component({
    selector: 'template-rest-detail',
    templateUrl: 'template.rest.detail.html',
    styleUrls: ['template.rest.detail.css']
})
export class TemplateRestDetail extends BaseListDetail {

    public objects: any[];

    public selectedObject: RestObject;

    public get selectedObjectID(): string {
        return this.SelectedItem.ObjectForTemplate;
    }

    constructor(private _service: RestService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    setSelectedItem(item: ContainerRest) {
        if (item && !this.objects) {
            if (!this.objects)
                this._service.loadRestObjects(item.RestID).subscribe(result => {
                    const os = [];
                    result.forEach(r => os.push({
                        Caption: r.Caption,
                        Value: r.Value
                    }));
                    this.objects = os;
                });
            if (item.ObjectForTemplate) {
                this._service.loadRestObject(item.ObjectForTemplate).subscribe(result => {
                    this.selectedObject = result;
                    this.SelectedItem.Fields.forEach(f => {
                        const p = this.selectedObject.Fields.find(x => x.ID === f.ID);
                        if (p)
                            p.ColSelected = true;
                    });
                });
            }
        }
        super.setSelectedItem(item);
    }

    public addColumns(): void {
        const view = this.selectedObject;
        if (view) {
            BaseDialog.ShowDialog({
                ContentType: TemplateColumnDialog,
                InitArgs: view.Fields,
                Title: '@@Felder hunzufuegen',
                Handler: (result) => {
                    if (result) {
                        this.SelectedItem.Fields = result;
                        this.OnItemChanged();
                    }
                    return true;
                }
            });
        }
    }

    public objectSelectionChange(event: string): void {
        this.SelectedItem.ObjectForTemplate = event;
        this._service.loadRestObject(event).subscribe(result => {
            this.selectedObject = result
            this.SelectedItem.Fields = this.selectedObject.Fields;
            this.SelectedItem.Fields.forEach(f => f.ColSelected = true);
            this.OnItemChanged();
        });
    }
}