import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StringHelper } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { BodyType } from '../../../models/enums/bodytype.enum';
import { MethodType } from '../../../models/enums/methodtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { InterceptorSkipHeader } from '../../../services/interceptor.skip.header';
import { SettingsService } from '../../../services/settings.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

// @dynamic
export abstract class ARestCallSettings extends FormulaWorkflowDialogContent {

    Data: ARestCallSettingsData;
    RestTypes;
    BodyTypes;
    BodyContentTypes = ['application/json', 'application/xml'];

    constructor(protected translate: TranslateService) {
        super();
        this.RestTypes = EnumHelper.GetDropdownValues(MethodType);
        this.BodyTypes = EnumHelper.GetDropdownValues(BodyType);
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data) {
            const errorList = [];
            this.Data.Params.forEach(param => {
                if (!param.Value || param.Value === '') {
                    errorList.push(StringHelper.format(this.translate.instant('@@Fehlender Wert fuer Parameter {0}'), [param.Key]));
                }
                if (!param.Key || param.Key === '') {
                    errorList.push(StringHelper.format(this.translate.instant('@@Fehlender Schluessel fuer Parameterwert {0}'),
                        [param.Value]));
                }
            });
            this.Data.Headers.forEach(param => {
                if (!param.Value || param.Value === '') {
                    errorList.push(StringHelper.format(this.translate.instant('@@Fehlender Wert fuer Header {0}'), [param.Key]));
                }
                if (!param.Key || param.Key === '') {
                    errorList.push(StringHelper.format(this.translate.instant('@@Fehlender Schluessel fuer Headerwert {0}'),
                        [param.Value]));
                }
            });
            if (errorList.length > 0) {
                errorList.splice(0, 0,
                    this.translate.instant('@@Fehlerhafter Rest-Aufruf. Bitte korrigieren Sie folgende Einstellungen:\n'));
                retVal.IsCorrect = false;
                retVal.Error = errorList.join('\n');
            }
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }

    addParam() {
        this.Data.Params.push(new RestParamData());
    }

    removeParam(param) {
        const index = this.Data.Params.indexOf(param);
        if (index >= 0) {
            this.Data.Params.splice(index, 1);
        }
    }

    addHeader() {
        this.Data.Headers.push(new RestParamData());
    }

    removeHeader(header) {
        const index = this.Data.Headers.indexOf(header);
        if (index >= 0) {
            this.Data.Headers.splice(index, 1);
        }
    }

    onVariableSelect(event, param) {
        if (event && param) {
            param.Value = event.Caption;
        }
    }

    onBodyVariableSelect(event) {
        if (event) {
            this.Data.BodyContent = event.Caption;
        }
    }

    onPathSelect(event) {
        if (event) {
            this.Data.Path = event.Caption;
        }
    }

    onServiceURLChange() {
        if (this.Data.UseServiceURL) {
            if (!this.BodyContentTypes.some(x => x === this.Data.BodyContentType)) {
                this.Data.BodyContentType = 'application/json';
            }
        }
    }

    abstract getPreview(): string;
}

@Component({
    selector: 'restCall-settings',
    templateUrl: './restCall.settings.html',
    styleUrls: ['./restCall.settings.css']
})
export class RestCallSettings extends ARestCallSettings {
    VariableNodes: ABaseTreeNode[] = [];

    static ModuleID = 'restWFModule';

    public static getURL(status: WorkflowStatus) {
        let retVal = '';
        if (status) {
            const data = status.ActualSettings;
            if (data) {
                if (data.UseServiceURL) {
                    retVal = SettingsService.API_BASE_URL.getValue();
                } else {
                    if (data.Address) {
                        retVal += data.IsSSL ? 'https://' : 'http://';
                        retVal += data.Address + ':' + data.Port + '/';
                    } else {
                        retVal += SettingsService.API_BASE_URL.getValue();
                    }
                }
                if (data.Path) {
                    if (data.GetPathFromStatus === true) {
                        retVal += status.Context.get(data.Path);
                    } else {
                        retVal += data.Path;
                    }
                }
                if (data.Params && data.Params.length > 0) {
                    retVal += '?';
                    let first = true;
                    data.Params.forEach(v => {
                        if (!first) {
                            retVal += '&';
                        }
                        first = false;
                        let val = '';
                        if (v.ReadFromStatus) {
                            const stateVal = status.Context.get(v.Value);
                            if (stateVal) {
                                val = stateVal.toString();
                            }
                        } else {
                            val = v.Value;
                        }
                        retVal += v.Key + '=' + val;
                    });
                }
            }
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = RestCallSettings.ModuleID;
        reg.Caption = '@@Restaufruf';
        reg.GroupID = 'wfActions';
        reg.Index = 30;
        reg.SettingsControl = RestCallSettings;
        reg.SettingsTypeHelper = new RestCallSettingsDataHelper();
        reg.Executer = RestCallModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = RestCallAdvancedSettings.ModuleID;
        return reg;
    }

    constructor(protected translate: TranslateService) {
        super(translate);
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(RestCallSettingsData, json);
        } else {
            this.Data = new RestCallSettingsData();
        }
    }

    getPreview(): string {
        const state = new WorkflowStatus('', '');
        state.ActualSettings = this.Data;
        return RestCallSettings.getURL(state);
    }
}

@Component({
    selector: 'restCall-advanced-settings',
    templateUrl: './restcall.advanced.settings.html',
    styleUrls: ['./restCall.settings.css']
})
export class RestCallAdvancedSettings extends ARestCallSettings {

    static ModuleID = 'restAdvancedWFModule';

    public static getURL(status: WorkflowStatus) {
        let retVal = '';
        if (status) {
            const data = status.ActualSettings;
            if (data) {
                const calculator = new WorkflowFormulaCalculator(status);
                if (data.UseServiceURL) {
                    retVal = SettingsService.API_BASE_URL.getValue();
                } else {
                    if (data.Address) {
                        const address = calculator.CalcFormula(data.Address);
                        retVal += data.IsSSL ? 'https://' : 'http://';
                        retVal += address;
                        if (typeof data.Port === 'number' && data.Port > 0) {
                            retVal += ':' + data.Port;
                        }
                        retVal += '/';
                    } else {
                        retVal += SettingsService.API_BASE_URL.getValue();
                    }
                }
                if (data.Path) {
                    const path = calculator.CalcFormula(data.Path);
                    retVal += path;
                }
                if (data.Params && data.Params.length > 0) {
                    retVal += '?';
                    let first = true;
                    data.Params.forEach(v => {
                        if (!first) {
                            retVal += '&';
                        }
                        first = false;
                        const key = calculator.CalcFormula(v.Key);
                        const val = calculator.CalcFormula(v.Value);
                        retVal += key + '=' + val;
                    });
                }
            }
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = RestCallAdvancedSettings.ModuleID;
        reg.Caption = '@@Restaufruf';
        reg.GroupID = 'wfActions';
        reg.Index = 30;
        reg.SettingsControl = RestCallAdvancedSettings;
        reg.SettingsTypeHelper = new RestCallAdvancedSettingsDataHelper();
        reg.Executer = RestCallAdvancedModuleExecuter;
        return reg;
    }

    constructor(protected translate: TranslateService) {
        super(translate);
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(RestCallAdvancedSettingsData, json);
        } else {
            this.Data = new RestCallAdvancedSettingsData();
        }
    }

    getPreview(): string {
        try {
            const state = new WorkflowStatus('', '');
            state.ActualSettings = this.Data;
            return RestCallAdvancedSettings.getURL(state);
        } catch {
            return 'ERROR';
        }
    }
}

// @dynamic
export abstract class ARestCallSettingsData extends AServiceWorkflowData {
    Type: MethodType = MethodType.Get;
    UseServiceURL = false;
    IsSSL = false;
    IgnoreSSL = false;
    Address = '';
    Port = 0;
    Path = '';
    GetPathFromStatus = false;
    @Type(() => RestParamData)
    Params: RestParamData[] = [];
    @Type(() => RestParamData)
    Headers: RestParamData[] = [];
    AuthDefined = false;
    Username = '';
    Password = '';
    BodyType: BodyType = BodyType.SelfDefined;
    BodyContent = '';
    BodyContentType = 'application/json';    
}

export class RestCallSettingsData extends ARestCallSettingsData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.RestCall.RestCallSettingsData';
    }
}

export class RestCallAdvancedSettingsData extends ARestCallSettingsData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.RestCall.RestCallAdvancedSettingsData';
    }
}

export abstract class ARestCallSettingsDataHelper extends WorkflowModuleSettingsHelper {    

    getAdditionalCaption(settings) {
        if (settings && settings.Type) {
            return MethodType[settings.Type];
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Success';
        const error = new WorkflowExitInfo();
        error.ID = 1;
        error.Label = '@@Error';
        return [success, error];
    }
}

export class RestCallSettingsDataHelper extends ARestCallSettingsDataHelper {
    getEmptySettingsInstance() {
        return new RestCallSettingsData();
    }
}

export class RestCallAdvancedSettingsDataHelper extends ARestCallSettingsDataHelper {
    getEmptySettingsInstance() {
        return new RestCallAdvancedSettingsData();
    }
}

export class RestParamData {
    Key = '';
    Value = '';
    ReadFromStatus: false;
}

// @dynamic
export abstract class ARestCallModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let headers = new HttpHeaders();
            if (!status.ActualSettings.UseServiceURL) {
                headers = headers.append(InterceptorSkipHeader, '');
                if (status.ActualSettings.AuthDefined) {
                    const auth = 'Basic ' + btoa(status.ActualSettings.Username + ':' + status.ActualSettings.Password);
                    headers = headers.append('Authorization', auth);
                }
                headers = this.fillHeaders(headers, status);                
            }
            let b = null;
            if (status.ActualSettings.Type === MethodType.Post || status.ActualSettings.Type === MethodType.Put) {
                if (status.ActualSettings.BodyContentType) {
                    headers = headers.append('Content-Type', status.ActualSettings.BodyContentType);
                } else if (status.ActualSettings.UseServiceURL) {
                    headers = headers.append('Content-Type', 'application/json');
                }
                switch (status.ActualSettings.BodyType) {
                    case BodyType.ActualParameter:
                        b = status.ActualParameter;
                        break;
                    case BodyType.SelfDefined:
                        b = status.ActualSettings.BodyContent;
                        break;
                    case BodyType.Status:
                        b = status.Context.get(status.ActualSettings.BodyContent);
                        break;
                }
            }
            const url = this.getURL(status);
            const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
            let retVal = 0;
            switch (status.ActualSettings.Type) {
                case MethodType.Get:
                    status.ActualParameter = await http.get<any>(url, { headers: headers }).pipe(catchError((e: HttpErrorResponse) => {
                        status.Logger.logWarning('Rest modul: Error while executing GET (' + e.message + ')');
                        retVal = 1;
                        return of(null);
                    })).toPromise();
                    break;
                case MethodType.Delete:
                    status.ActualParameter = await http.delete<any>(url, { headers: headers }).pipe(catchError((e: HttpErrorResponse) => {
                        status.Logger.logWarning('Rest modul: Error while executing DELETE (' + e.message + ')');
                        retVal = 1;
                        return of(null);
                    })).toPromise();
                    break;
                case MethodType.Options:
                    status.ActualParameter = await http.options<any>(url, { headers: headers }).pipe(catchError((e: HttpErrorResponse) => {
                        status.Logger.logWarning('Rest modul: Error while executing OPTIONS (' + e.message + ')');
                        retVal = 1;
                        return of(null);
                    })).toPromise();
                    break;
                case MethodType.Post:
                    status.ActualParameter = await http.post<any>(url, b, { headers: headers }).pipe(catchError((e: HttpErrorResponse) => {
                        status.Logger.logWarning('Rest modul: Error while executing POST (' + e.message + ')');
                        retVal = 1;
                        return of(null);
                    })).toPromise();
                    break;
                case MethodType.Put:
                    status.ActualParameter = await http.put<any>(url, b, { headers: headers }).pipe(catchError((e: HttpErrorResponse) => {
                        status.Logger.logWarning('Rest modul: Error while executing PUT (' + e.message + ')');
                        retVal = 1;
                        return of(null);
                    })).toPromise();
                    break;
                default:
                    status.ActualParameter = null;
                    break;
            }
            return retVal;
        } else {
            status.Logger.logError('Rest modul: No settings found.');
        }
        return super.execute(status);
    }

    abstract fillHeaders(headers: HttpHeaders, status: WorkflowStatus): HttpHeaders;
    abstract getURL(status: WorkflowStatus): string;
}

export class RestCallModuleExecuter extends ARestCallModuleExecuter {
    fillHeaders(headers: HttpHeaders, status: WorkflowStatus): HttpHeaders {
        status.ActualSettings.Headers.forEach(h => {
            let val = '';
            if (h.ReadFromStatus) {
                const stateVal = status.Context.get(h.Value);
                if (stateVal) {
                    val = stateVal.toString();
                }
            } else {
                val = h.Value;
            }
            headers = headers.append(h.Key, val);
        });
        return headers;
    }
    getURL(status: WorkflowStatus): string {
        return RestCallSettings.getURL(status);
    }
}

export class RestCallAdvancedModuleExecuter extends ARestCallModuleExecuter {
    fillHeaders(headers: HttpHeaders, status: WorkflowStatus): HttpHeaders {
        const calculator = new WorkflowFormulaCalculator(status);
        status.ActualSettings.Headers.forEach(h => {
            const key = calculator.CalcFormula(h.Key);
            const val = calculator.CalcFormula(h.Value);
            headers = headers.append(key, val);
        });
        return headers;
    }
    getURL(status: WorkflowStatus): string {
        return RestCallAdvancedSettings.getURL(status);
    }
}
