import { MacTablePropertyMenuTab } from '../components/reportobjectmenus/table/mactable.menu.tab';
import { QuerySelectMenuTab } from '../components/reportobjectmenus/query/query.menu.tab';
import { ChartReportObjectMenuTab, ChartPropertyMenuTab } from '../components/reportobjectmenus/chart/chart.menu.tab';
import { SeriesReportObjectMenuControl } from '../components/reportobjectmenus/chart/series.menu.control';
import { FilterTreeMenuTab } from '../components/menutabs/filtertree/filtertree.menu.tab';
import { MacTableSumMenuTab } from '../components/reportobjectmenus/table/sum.menu.tab';
import { MacTableExpandMenuTab } from '../components/reportobjectmenus/table/expand.menu.tab';
import { MacTableFormatMenuTab } from '../components/reportobjectmenus/table/format.menu.tab';
import { MacTableSizeMenuTab } from '../components/reportobjectmenus/table/size.menu.tab';
import { MacTableFixMenuTab } from '../components/reportobjectmenus/table/fix.menu.tab';
import { FilterDropdownMenuTab } from '../components/menutabs/filtertree/filter.dropdown.menu.tab';
import { MacPlanningPropertyMenuTab } from '../components/reportobjectmenus/planning/planning.menu.tab';
import { QueriesSelectMenuTab } from '../components/reportobjectmenus/query/queries.menu.tab';
import { MacPlanningSizeMenuTab } from '../components/reportobjectmenus/planning/size.menu.tab';
import { MacPlanningActiveCellMenuTab } from '../components/reportobjectmenus/planning/activecell.menu.tab';
import { MacPlanningReferencesMenuTab } from '../components/reportobjectmenus/planning/references.menu.tab';
import { MacPlanningFormatMenuTab } from '../components/reportobjectmenus/planning/planning.format.menu.tab';
import { MacPlanningSheetsMenuTab } from '../components/reportobjectmenus/planning/sheets.menu.tab';
import { AxisMenuTab } from '../components/reportobjectmenus/chart/axis.menu.tab';

export const MAC_MENUTABS = [
    MacTablePropertyMenuTab,
    MacTableSumMenuTab,
    MacTableExpandMenuTab,
    MacTableFixMenuTab,
    MacTableFormatMenuTab,
    MacTableSizeMenuTab,
    QuerySelectMenuTab,
    QueriesSelectMenuTab,
    ChartReportObjectMenuTab,
    AxisMenuTab,
    SeriesReportObjectMenuControl,
    ChartPropertyMenuTab,
    FilterTreeMenuTab,
    FilterDropdownMenuTab,
    MacPlanningPropertyMenuTab,
    MacPlanningSizeMenuTab,
    MacPlanningActiveCellMenuTab,
    MacPlanningReferencesMenuTab,
    MacPlanningFormatMenuTab,
    MacPlanningSheetsMenuTab
];
