import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';
import { ThemeHelper } from '../../helpers/theme.helpers';
import { LayoutUnit } from '../../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../../models/basic/layoutunitthickness.model';
import { PropertyGroupDisplay } from '../../models/enums/propertygroupdisplay.enum';
import { TextOverflow } from '../../models/enums/textoverflow.enum';
import { MultiPropertyChangeValue } from '../../models/layout/layout.change.model';
import { MenuTabLabelPosition } from '../../models/menutab/menutab.property.model';
import { Font } from '../../models/style/font.model';
import { LayoutService } from '../../services/layout.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../services/dynamic.component.service';
import { MediaService } from '../../services/media.service';
import { ComboboxThemeControl } from '../controls/combobox/combobox.theme.control';
import { HeaderThemeControl } from '../controls/header/header.theme.control';
import { InputswitchThemeControl } from '../controls/inputswitch/inputswitch.theme.control';
import { LayoutUnitThicknessComponent } from '../controls/layoutunitthickness/layoutunitthickness.component';
import { TogglebuttongroupThemeControl } from '../controls/togglebuttongroup/togglebuttongroup.theme.control';
import { UnitSelectionControl } from '../controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../menutabs/generic/generic.menu.tab';
import { BasePanel } from './base.panel';

export class ContentPanel extends BasePanel {
    static override SIDS = ['dbefb974-2c57-44e2-a3b6-dcf4fe96c4a3']
    static Fonts: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    private static Font: BehaviorSubject<Font> = new BehaviorSubject<Font>(null)
    private static DefaultFonts;
    private static CustomFonts;
    private static CSSFonts;
    static override SetSelectedItem(item) {
        if (item) {
            if (!item.Font) {
                item.Font = new Font();
            }
            ContentPanel.Font.next(item.Font);
        } else {
            ContentPanel.Font.next(null);
        }
    }
    private static CheckFonts() {
        const FontFamilies = [];
        let UseGrouping = false;
        if (ContentPanel.CustomFonts && ContentPanel.CustomFonts.length > 0) {
            UseGrouping = true;
        }
        if (ContentPanel.CSSFonts && ContentPanel.CSSFonts.length > 0) {
            UseGrouping = true;
        }
        if (UseGrouping) {
            if (ContentPanel.CustomFonts && ContentPanel.CustomFonts.length > 0) {
                FontFamilies.push({
                    label: '@@Custom',
                    items: ContentPanel.CustomFonts
                })
            }
            if (ContentPanel.CSSFonts && ContentPanel.CSSFonts.length > 0) {
                FontFamilies.push({
                    label: '@@Variables',
                    items: ContentPanel.CSSFonts
                })
            }
            FontFamilies.push({
                label: '@@Regular',
                items: ContentPanel.DefaultFonts
            })
        } else {
            FontFamilies.push(...ContentPanel.DefaultFonts);
        }
        ContentPanel.Fonts.next(FontFamilies)
    }
    static InitPanel() {
        this.InitSelectedItem()
        this.InitSelectedObject()
        PROPERTYGROUPS.push({
            SID:'dbefb974-2c57-44e2-a3b6-dcf4fe96c4a3',
            ID: 'content',
            Caption: '@@Content',
            Index: 0,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Sections: [{
                ID: 'text',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['120px', 'calc(50% - 120px)', 'calc(50%)'],
                Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
            }, {
                ID: 'positions',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['1fr', '`1fr', '1fr', '1fr', '1fr', '1fr', '1fr'],
                Rows: ['auto', 'auto', 'auto', 'auto']
            },{
                ID: 'dimensions',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['50%', '50%'],
                Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
            }, {
                ID: 'mpp',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['50%', '50%'],
                Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
            }]
            
        });
        //#region Text
        PROPERTIES.push({
            ID: null,
            Section: 'text',
            Parent: 'content',
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: 1,
            InitArgs: {
                Text: '@@Text'
            }
        })
        //#region FetchFonts
        let DefaultFonts = [];
        MediaService.GetDefaultFonts().forEach(x => {
            DefaultFonts.push({
                Value: x,
                Style: {
                    'font-family': x
                }
            });
        });
        ContentPanel.DefaultFonts = DefaultFonts
        
        let UseGrouping = false;
        MediaService.CSSFontList.subscribe((result) => {
            let CustomFontList = [];
            if (result && result.length > 0) {
                UseGrouping = true;
                result.forEach(x => {
                    CustomFontList.push({
                        Value: x,
                        Style: {
                            'font-family': ThemeHelper.CustomFontsMap[x].Value
                        }
                    });
                });
            }
            ContentPanel.CSSFonts = CustomFontList;
            ContentPanel.CheckFonts();
        })
        MediaService.CustomFontList.subscribe((result) => {
            let CustomFontList = [];
            if (result && result.length > 0) {
                UseGrouping = true;
                result.forEach(x => {
                    CustomFontList.push({
                        Value: x,
                        Style: {
                            'font-family': x
                        }
                    });
                });
            }
            ContentPanel.CustomFonts = CustomFontList;
            ContentPanel.CheckFonts();
        });
        //#endregion
        PROPERTIES.push({
            ID: 'FontSize',
            Section: 'text',
            Parent: 'content',
            Content: new ComponentPortal(ComboboxThemeControl),
            DataSource: ContentPanel.Font,
            Column: 1,
            Row: 3,
            InitArgs: {
                Multiple: false,
                ItemsSource: [
                    { Label: '8', Value: 8 },
                    { Label: '9', Value: 9 },
                    { Label: '10', Value: 10 },
                    { Label: '11', Value: 11 },
                    { Label: '12', Value: 12 },
                    { Label: '14', Value: 14 },
                    { Label: '16', Value: 16 },
                    { Label: '18', Value: 18 },
                    { Label: '20', Value: 20 },
                    { Label: '22', Value: 22 },
                    { Label: '24', Value: 24 },
                    { Label: '26', Value: 26 },
                    { Label: '28', Value: 28 },
                    { Label: '36', Value: 36 },
                    { Label: '48', Value: 48 },
                    { Label: '72', Value: 72 }
                ],
                DisplayMemberPath: 'Label',
                ValueMemberPath: 'Value',
                Placeholder: '@@Size'
            }
        });
        PROPERTIES.push({
            ID: 'FontFamilyName',
            Section: 'text',
            Parent: 'content',
            Content: new ComponentPortal(ComboboxThemeControl),
            DataSource: ContentPanel.Font,
            Column: 2,
            ColSpan:2,
            Row: 3,
            InitArgs: {
                Multiple: false,
                UseGrouping: UseGrouping,
                DisplayMemberPath: 'Value',
                ValueMemberPath: 'Value',
                ItemsSourceSub: ContentPanel.Fonts,
                OptionStyle: 'Style',
                Placeholder: '@@Font'
            }
        });
        PROPERTIES.push({
            ID: 'TextOverflow',
            Section: 'text',
            Parent: 'content',
            Content: new ComponentPortal(ComboboxThemeControl),
            Column: 1,
            ColSpan: 3,
            Row: 4,
            InitArgs: {
                Multiple: false,
                EnumSource: TextOverflow,
                Placeholder: '@@TextOverflow'
            }
        });

        // !first positions //
        PROPERTIES.push({
            ID:null,
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: 1,
            InitArgs: {
                Text:'@@PositionOfText'
            }
        })
        PROPERTIES.push({
            ID: 'HorizontalTextAlignment',
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 1,
            ColSpan: 5,
            Row: 2,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: 'align_left_text',
                    Value: 0,
                    Tooltip: '@@LeftJustified'
                }, {
                    ButtonIcon: 'align_center_text',
                    Value: 1,
                    Tooltip: '@@Centered'
                }, {
                    ButtonIcon: 'align_right_text',
                    Value: 2,
                    Tooltip: '@@RightJustified'
                }]
            }
        });
        PROPERTIES.push({
            ID: 'VerticalTextAlignment',
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 5,
            ColSpan: 3,
            Row: 2,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: 'vertical_align_top_text',
                    Value: 0,
                    Tooltip: '@@Top'
                }, {
                    ButtonIcon: 'vertical_align_center_text',
                    Value: 1,
                    Tooltip: '@@Centered'
                }, {
                    ButtonIcon: 'vertical_align_bottom_text',
                    Value: 2,
                    Tooltip: '@@Bottom'
                }]
            }
        });
        // !second row // positions
        PROPERTIES.push({
            ID: null,
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: 3,
            InitArgs: {
                Text: '@@PositionOfControl'
            }
        })
        PROPERTIES.push({
            ID: 'HorizontalContentAlignment',
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 1,
            ColSpan: 5,
            Row: 4,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: 'align_left_content',
                    Value: 0,
                    Tooltip: '@@LeftJustified'
                }, {
                    ButtonIcon: 'align_center_content',
                    Value: 1,
                    Tooltip: '@@Centered'
                }, {
                    ButtonIcon: 'align_right_content',
                    Value: 2,
                    Tooltip: '@@RightJustified'
                    }],
                Placeholder: '@@Horizontal content align'
            }
        });
        PROPERTIES.push({
            ID: 'VerticalContentAlignment',
            Section: 'positions',
            Parent: 'content',
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 5,
            ColSpan: 3,
            Row: 4,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: 'vertical_align_top_content',
                    Value: 0,
                    Tooltip: '@@Top'
                }, {
                    ButtonIcon: 'vertical_align_center_content',
                    Value: 1,
                    Tooltip: '@@Centered'
                }, {
                    ButtonIcon: 'vertical_align_bottom_content',
                    Value: 2,
                    Tooltip: '@@Bottom'
                    }],
                Placeholder: '@@Vertical content align'
            }
        });
        // !end // positions
        //#endregion
        //#region Dim
        PROPERTIES.push({
            ID: null,
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: 3,
            InitArgs: {
                Text: '@@Dimensions'
            }
        })
        PROPERTIES.push({
            ID: 'Height',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'H',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize: '50px',
            Column: 1,
            Row: 4,
            InitArgs: {
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: 'Width',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'W',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize: '50px',
            Column: 2,
            Row: 4,
            InitArgs: {
                Minimum: 0
            }
        });

        PROPERTIES.push({
            ID: 'SetMinMaxSize',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: '@@SetMinMaxSize',
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 2,
            Row: 5,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        ContentPanel.SelectedItem.MinHeight = null;
                        ContentPanel.SelectedItem.MinWidth = null;
                        ContentPanel.SelectedItem.MaxHeight = null;
                        ContentPanel.SelectedItem.MaxWidth = null;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [item.SelectedItem.ID],
                            ['MinHeight', 'MinWidth', 'MaxHeight', 'MaxWidth'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                }
            }
        });

        PROPERTIES.push({
            ID: 'MinHeight',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'Min H',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize: '60px',
            Column: 1,
            Row: 6,
            InitArgs: {
                Minimum: 0
             },
            CheckVisibility: (item) => {
                return (item.MinHeight && item.MinHeight.toStyle() != '') ||
                    (item.MaxHeight && item.MaxHeight.toStyle() != '') ||
                    (item.MinWidth && item.MinWidth.toStyle() != '') ||
                    (item.MaxWidth && item.MaxWidth.toStyle() != '') ||
                    item.SetMinMaxSize == true
             }
        });

        PROPERTIES.push({
            ID: 'MaxHeight',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'Max H',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize: '60px',
            Column: 1,
            Row: 7,
            InitArgs: {
                Minimum: 0
            },
            CheckVisibility: (item) => {
                return (item.MinHeight && item.MinHeight.toStyle() != '') ||
                    (item.MaxHeight && item.MaxHeight.toStyle() != '') ||
                    (item.MinWidth && item.MinWidth.toStyle() != '') ||
                    (item.MaxWidth && item.MaxWidth.toStyle() != '') ||
                    item.SetMinMaxSize == true
            }
        });

        PROPERTIES.push({
            ID: 'MinWidth',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'Min W',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize: '60px',
            Column: 2,
            Row: 6,
            InitArgs: {
                Minimum: 0
            },
            CheckVisibility: (item) => {
                return (item.MinHeight && item.MinHeight.toStyle() != '') ||
                    (item.MaxHeight && item.MaxHeight.toStyle() != '') ||
                    (item.MinWidth && item.MinWidth.toStyle() != '') ||
                    (item.MaxWidth && item.MaxWidth.toStyle() != '') ||
                    item.SetMinMaxSize == true
            }
        });

        PROPERTIES.push({
            ID: 'MaxWidth',
            Section: 'dimensions',
            Parent: 'content',
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: 'Max W',
            LabelPosition: MenuTabLabelPosition.Left,
            LabelMinSize:'60px',
            Column: 2,
            Row: 7,
            InitArgs: {
                Minimum: 0
            },
            CheckVisibility: (item) => {
                return (item.MinHeight && item.MinHeight.toStyle() != '') ||
                    (item.MaxHeight && item.MaxHeight.toStyle() != '') ||
                    (item.MinWidth && item.MinWidth.toStyle() != '') ||
                    (item.MaxWidth && item.MaxWidth.toStyle() != '') ||
                    item.SetMinMaxSize == true
            }
        });
        //#endregion
        //#endregion
       
        PROPERTIES.push({
            ID: 'Margin',
            Section: 'mpp',
            Parent: 'content',
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Type: LayoutUnitThickness,
            Column: 1,
            Row: 8,
            ColSpan: 2,
            InitArgs: {
                IsBorderRadius: false,
                Title:'@@Margin'
            }
        });
        PROPERTIES.push({
            ID: 'Padding',
            Section: 'mpp',
            Parent: 'content',
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Type: LayoutUnitThickness,
            Column: 1,
            Row: 9,
            ColSpan: 2,
            InitArgs: {
                IsBorderRadius: false,
                Title: '@@Padding',
            }
        });
        PROPERTIES.push({
            ID: 'Location',
            Section: 'mpp',
            Parent: 'content',
            Content: new ComponentPortal(LayoutUnitThicknessComponent),
            Type: LayoutUnitThickness,
            Column: 1,
            Row: 10,
            ColSpan: 2,
            InitArgs: {
                IsBorderRadius: false,
                Title: '@@Location',

            }
        });
        //});
    }
}