import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { FilterElementSettings } from '../../../models/datadescription/multi/filter/filterElementSettings.model';
import { ABaseTreeNode } from '../../common/basetreecontrol/base.tree.control';
import { FilterTreeDialog } from '../../controls/filter/filter.tree.dialog';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';

@Component({
    selector: 'conditional-filter-dialog',
    templateUrl: './conditional.filter.dialog.html',
    styleUrls: ['./conditional.filter.dialog.css']
})
export class ConditionalFilterDialog implements OnInit {
    Initialized = false;
    DataModel;
    Dimension;
    Data: any = {};
    Cubes = [];
    Measures = [];
    TreeNodes = [];
    FilterItems = [];
    SystemVariables = [];

    private static GetDefaultSelectsText(item) {
        let count = 0;
        if (item && item.Defaults) {
            item.Defaults.forEach(ds => {
                count += ds.MemberIds ? ds.MemberIds.length : 0;
            });
        }
        return '[' + count + ']'
    }

    public static ShowDialog(data, dataModel, dimension, variables, handler) {
        BaseDialog.ShowDialog({
            ContentType: ConditionalFilterDialog,
            InitArgs: {
                Data: data,
                DataModel: dataModel,
                Dimension: dimension,
                Variables: variables
            },
            AutoFocus: false,
            Handler: (x) => {
                if (x) {
                    handler(x);
                }
                return true;
            },
            Title: '@@Define conditional filter'
        });
    }

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.Data && this.Data.Filter) {
            const filterItems = [];
            this.Data.Filter.forEach(f => {
                filterItems.push({
                    Data: f,
                    Description: {
                        Caption: '',
                        DefaultSelectsText: '[0]',
                        Levels: [],
                        DataSource: null
                    }
                });
            });
            this.FilterItems = filterItems;
        }
        if (this.DataModel && this.Dimension) {
            MultiCacheService.GetCubes(this.DataModel).then(cubes => {
                if (cubes) {
                    const cubeList = [];
                    cubes.forEach(cube => {
                        if (cube.Dimensions && cube.Dimensions.some(dim => dim.ID === this.Dimension)) {
                            const cubeEntry = {
                                Caption: cube.Caption,
                                ID: cube.ID,
                                Measures: [],
                                TreeNodes: [],
                            };
                            cube.Measures.forEach(m => {
                                cubeEntry.Measures.push({
                                    Caption: m.Caption,
                                    ID: m.ID
                                });
                            });
                            let id = 0;
                            cube.Dimensions.forEach(dim => {
                                //if (dim.ID !== this.Dimension && dim.Hierarchies && dim.Hierarchies.length > 0) {
                                    if (dim.Hierarchies && dim.Hierarchies.length > 0) {
                                    const node = new ABaseTreeNode(id++);
                                    node.Caption = dim.Caption;
                                    node.HasChildren = true;
                                    node.Children = [];
                                    node.Selectable = false;
                                    dim.Hierarchies.forEach(h => {
                                        const hNode = new FilterTreeNode(id++);
                                        hNode.Caption = h.Caption;
                                        hNode.DataSource = dim.DatasourceID;
                                        hNode.Dimension = dim.ID;
                                        hNode.Hierarchy = h.ID;
                                        h.Levels.forEach(l => hNode.Levels.push(l.ID));
                                        node.Children.push(hNode);
                                        const fi = this.FilterItems.find(x => x.Data.Hierarchy === h.ID && x.Data.Dimension === dim.ID);
                                        if (fi) {
                                            fi.Description.Caption = h.Caption;
                                            fi.Description.DefaultSelectsText = ConditionalFilterDialog.GetDefaultSelectsText(fi.Data);
                                            fi.Description.Levels = [...hNode.Levels];
                                            fi.Description.DataSource = dim.DatasourceID;
                                        }
                                    });
                                    cubeEntry.TreeNodes.push(node);
                                }
                            });
                            cubeList.push(cubeEntry);
                        }
                    });
                    this.Cubes = cubeList;
                    if (cubeList.length === 1 && this.Data && !this.Data.Cube) {
                        this.Data.Cube = cubeList[0].ID;
                    }
                    this.onCubeChanged();
                }
                this.Initialized = true;
                this.cdRef.detectChanges();
            });
        } else {
            this.Initialized = true;
            this.cdRef.detectChanges();
        }
    }

    Initialize(args) {
        if (args) {
            this.DataModel = args.DataModel;
            this.Dimension = args.Dimension;
            if (args.Data) {
                this.Data = args.Data;
            }
            if (args.Variables) {
                this.SystemVariables = args.Variables;
            }
        }
    }

    GetDialogResult() {
        if (this.Data) {
            const retVal = {
                Cube: this.Data.Cube,
                Measures: this.Data.Measures,
                Filter: []
            };
            this.FilterItems.forEach(fi => {
                if (fi.Data.Dimension && fi.Data.Hierarchy && (fi.Data.Formula || (fi.Data.Defaults && fi.Data.Defaults.length > 0))) {
                    retVal.Filter.push(fi.Data);
                }
            });
            return retVal;
        }
        return null;
    }

    onCubeChanged() {
        if (this.Data && this.Data.Cube) {
            const cube = this.Cubes.find(x => x.ID === this.Data.Cube);
            if (cube) {
                this.Measures = cube.Measures;
                this.TreeNodes = cube.TreeNodes;
                return;
            }
        }
        this.Measures = [];
        this.TreeNodes = [];
    }

    addFilter() {
        this.FilterItems.push({
            Data: {
                Defaults: []
            },
            Description: {
                Caption: '',
                DefaultSelectsText: '[0]',
                Levels: []
            }
        });
    }

    onHierarchyChanged(filter, node) {
        if (filter && node) {
            filter.Data.Dimension = node.Dimension;
            filter.Data.Hierarchy = node.Hierarchy;
            filter.Data.Defaults = [];
            filter.Description.Caption = node.Caption;
            filter.Description.DefaultSelectsText = '[0]';
            filter.Description.Levels = [...node.Levels];
            filter.Description.DataSource = node.DatasourceID;
        }
    }

    showFilterTreeDialog(filterItem) {
        if (filterItem && filterItem.Data && filterItem.Data.Hierarchy) {
            const fes = new FilterElementSettings();
            fes.CodeDesc = 3;
            fes.DataSource = filterItem.Description.DataSource;
            fes.DataModel = this.DataModel;
            fes.Hierarchy = filterItem.Data.Hierarchy;
            fes.Levels = filterItem.Description.Levels;
            fes.MultiSelect = true;
            fes.Relational = false;
            fes.SavedSelection = filterItem.Data.Defaults;
            const args = {
                FilterElementSettingsValue: fes
            };
            FilterTreeDialog.ShowDialog(args, result => {
                if (result && result.FilterSelection) {
                    filterItem.Data.Defaults = result.FilterSelection;
                    filterItem.Description.DefaultSelectsText = ConditionalFilterDialog.GetDefaultSelectsText(filterItem.Data);
                }
                return true;
            });
        }
    }

    clearSelects(item) {
        if (item) {
            item.Defaults = [];
            item.DefaultSelectsText = '[0]';
        }
    }

    delete(index) {
        this.FilterItems.splice(index, 1);
    }
}

export class FilterTreeNode extends ABaseTreeNode {
    DataSource;
    Dimension;
    Hierarchy;
    Levels = [];
}
