// workflow-communication.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataTableCommunicationService {

    private SortColumn = new BehaviorSubject<string>(null);
    private SortOrder = new BehaviorSubject<string>(null);
    setSortColumn(status: string): void {
        this.SortColumn.next(status);
    }
    getSortColumn(): Observable<any> {
        return this.SortColumn.asObservable();
    }

    setSortOrder(status: string): void {
        this.SortOrder.next(status);
    }
    getSortOrder(): Observable<any> {
        return this.SortOrder.asObservable();
    }

}
