import { BaseDataSourcePanel } from "../basedatasource.panel";
import { CouchBaseDBInfo, DBPanelInfo } from "../../../../../models/datamodel/adbinfo.model";
import { Component } from "@angular/core";

@Component({
    selector: 'couchbase-panel',
    templateUrl: './couchbase.panel.html',
    styleUrls: ['./couchbase.panel.css']
})
export class CouchBasePanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = CouchBaseDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: CouchBasePanel,
            DBInfoType: CouchBaseDBInfo
        };
    }
}