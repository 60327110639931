import { SETTINGS_LIST } from '../../components/pages/settings.page';
import { APP_DATAMODEL } from '../../imports/app.datamodel';
import { APP_MEDIATYPES } from '../../imports/app.mediatypes';
import { APP_SETTINGS } from '../../imports/app.settings';
import { TYPE_DICT } from '../../models/datamodel/adbinfo.model';
import { MEDIA_TYPE_DICT } from '../../models/datamodel/mediasource.model';
import { TRIGGER_DICT, WORKER_DICT } from '../../services/scheduler.service';
import { CCOTriggerControl } from '../../settings/scheduler/controls/triggercontrols/cco.trigger.control';
import { FileSystemTriggerControl } from '../../settings/scheduler/controls/triggercontrols/filesystem.trigger.control';
import { FTPTriggerControl } from '../../settings/scheduler/controls/triggercontrols/ftp.trigger.control';
import { MailTriggerControl } from '../../settings/scheduler/controls/triggercontrols/mail.trigger.control';
import { TimeTriggerControl } from '../../settings/scheduler/controls/triggercontrols/time.trigger.control';
import { WebHookTriggerControl } from '../../settings/scheduler/controls/triggercontrols/webhook.trigger.control';
import { CapsuleExportWorkerControl } from '../../settings/scheduler/controls/workercontrols/capsuleexport/capsule.export.worker.control';
import { CapsuleImportWorkerControl } from '../../settings/scheduler/controls/workercontrols/capsuleimport/capsule.import.worker.control';
import { DBBackupControl } from '../../settings/scheduler/controls/workercontrols/dbbackup/dbbackup.worker.control';
import { WorkflowWorkerControl } from '../../settings/scheduler/controls/workercontrols/workflow/workflow.worker.control';
import config from '../../../assets/config.json'
export class SettingsRegistryHelper {

    static ExternalRegistry = [];
    static ExternalDataSourceInfos = [];
    static ExternalTriggerInfos = [];
    static ExternalWorkerInfos = [];

    static Build() {
        SettingsRegistryHelper.BuildSettings();
        SettingsRegistryHelper.FillDataSourcePanelList();
        SettingsRegistryHelper.FillTriggerAndWorker();
    }

    private static BuildSettings() {
        const entries = new Map();
        SETTINGS_LIST.forEach((entry) => {
            entries.set(entry.ID, entry);
        });
        APP_SETTINGS.forEach((entry) => {
            if (typeof entry['GetSettingsEntry'] === 'function') {
                const setting = entry['GetSettingsEntry']();
                if (setting) {
                    entries.set(setting.ID, setting);
                }
            }
        });
        SettingsRegistryHelper.ExternalRegistry.forEach((entry) => {
            if (typeof entry['GetSettingsEntry'] === 'function') {
                const setting = entry['GetSettingsEntry']();
                if (setting) {
                    entries.set(setting.ID, setting);
                }
            }
        });
        let newList = [];
        entries.forEach((e) => {
            const freeUserRestrictedPages = ['mediasources', 'theme']
            let isFreeUser = false;
            const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
            if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
                isFreeUser = true;
            }
            let parent;
            if (e.Parent) {
                parent = entries.get(e.Parent);
            }
            if (parent) {
                if (!parent.Children) {
                    parent.Children = [];
                }
                if (isFreeUser && freeUserRestrictedPages.includes(e.ID)) {
                    const pageIndex = parent.Children.findIndex(item => item.ID === e.ID);
                    if (pageIndex >= 0) {
                        parent.Children.splice(pageIndex, 1);
                    }
                } else {
                    const pageIndex = parent.Children.findIndex(item => item.ID === e.ID);
                    if (pageIndex < 0) {
                        parent.Children.push(e);
                    }
                }
            } else {
                newList.push(e);
            }
        });
        newList = newList.sort((a, b) => {
            return a.Index - b.Index;
        });
        SETTINGS_LIST.splice(0);
        newList.forEach((c) => {
            if (c.Children) {
                SettingsRegistryHelper.Sort(c);
            }
            SETTINGS_LIST.push(c);
        });
    }

    private static Sort(entry) {
        if (entry && entry.Children) {
            const sorted = entry.Children.sort((a, b) => {
                return a.Index - b.Index;
            });
            entry.Children = sorted;
            entry.Children.forEach((c) => {
                if (c.Children) {
                    SettingsRegistryHelper.Sort(c);
                }
            });
        }
    }

    private static FillDataSourcePanelList() {
        APP_DATAMODEL.forEach(entry => {
            if (typeof entry['GetInfo'] === 'function') {
                const info = entry['GetInfo']();
                if (info.DBInfoType) {
                    const inst = new info.DBInfoType();
                    TYPE_DICT.set(inst.getTypeName(), info);
                }
            }
        });
        APP_MEDIATYPES.forEach(entry => {
            if (typeof entry['GetInfo'] === 'function') {
                const info = entry['GetInfo']();
                if (info) {
                    MEDIA_TYPE_DICT.set(info.MediaType, info);
                }
            }
        });
        SettingsRegistryHelper.ExternalDataSourceInfos.forEach(entry => {
            if (typeof entry['GetInfo'] === 'function') {
                const info = entry['GetInfo']();
                if (info.DBInfoType) {
                    const inst = new info.DBInfoType();
                    TYPE_DICT.set(inst.getTypeName(), info);
                }
            }
        });
    }

    private static FillTriggerAndWorker() {
        TRIGGER_DICT.set('6f369dc5-f978-4d24-9150-92b6bd4b78b4', TimeTriggerControl);
        TRIGGER_DICT.set('30ecfa51-1f4d-4bb8-be7d-2ae2b63d21d3', MailTriggerControl);
        TRIGGER_DICT.set('d50611a1-fcfa-4ed3-b556-fdfd0b5894e3', FTPTriggerControl);
        TRIGGER_DICT.set('5873b3d5-0961-402e-ab06-eaf8d9e0e0be', FileSystemTriggerControl);
        TRIGGER_DICT.set('cb954673-c9b4-4e13-9751-7aa2c3b9394b', WebHookTriggerControl);
        TRIGGER_DICT.set('c8a46062-73ed-4d1a-9bf1-1e96b77857b8', CCOTriggerControl);

        SettingsRegistryHelper.ExternalTriggerInfos.forEach(entry => {
            if (typeof entry['GetInfo'] === 'function') {
                const info = entry['GetInfo']();
                if (info.Key && info.Type) {
                    TRIGGER_DICT.set(info.Key, info.Type);
                }
            }
        });

        WORKER_DICT.set('27a0e2dc-4f61-4413-bceb-c367e5e40c4e', WorkflowWorkerControl);
        WORKER_DICT.set('c1345de9-8b61-4fcc-b68a-063d22ef54ba', CapsuleExportWorkerControl);
        WORKER_DICT.set('cd2e73ac-c67c-4f5c-9628-3042836ca011', CapsuleImportWorkerControl);
        WORKER_DICT.set('84b2396f-a86b-4e08-95a5-17743ca4ae4f', DBBackupControl);

        SettingsRegistryHelper.ExternalWorkerInfos.forEach(entry => {
            if (typeof entry['GetInfo'] === 'function') {
                const info = entry['GetInfo']();
                if (info.Key && info.Type) {
                    WORKER_DICT.set(info.Key, info.Type);
                }
            }
        });
    }
}
