import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { Concat } from '../../../models/enums/contact.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { SelfServiceSecurityService } from '../../../services/datamodel.service';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';
import { AdvancedFilterDialog } from '../../../components/dialogs/advancedfilter/advancedfilter.dialog';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'container-settings',
    templateUrl: './container.settings.html',
    styleUrls: ['./container.settings.css']
})
export class ContainerSecuritySettings implements OnInit {
    InitArgs;
    TreeSource = [];
    TreeSelection;
    Security;
    HasChanges = false;
    AccessOptions = [
        { Caption: '@@Lesen', Value: 1 },
        { Caption: '@@Schreiben', Value: 2 },
        { Caption: '@@Loeschen', Value: 3 }
    ];

    static getRequestFilterText(reqFilter, fields): string {
        if (reqFilter.Filters && reqFilter.Filters.length > 0) {
            if (reqFilter.Filters.length > 1) {
                const retVal = [];
                reqFilter.Filters.forEach((filter) => {
                    retVal.push(ContainerSecuritySettings.getRequestFilterText(filter, fields));
                });
                return '(' + retVal.join(reqFilter.ConcatOperator == Concat.And ? ' AND ' : ' OR ') + ')';
            } else {
                return ContainerSecuritySettings.getRequestFilterText(reqFilter.Filters[0], fields);
            }
        } else {
            if (reqFilter.FieldName) {
                return reqFilter.FieldName + ' ' + RequestFilter.GetTextForComparer(reqFilter.Operator) + ' ' + reqFilter.Value;
            }
            let name = reqFilter.FieldId;
            if (fields) {
                fields.some(f => {
                    if (f.ID == name) {
                        name = f.Caption;
                        return true;
                    }
                    return false;
                });
            }
            return name + ' ' + RequestFilter.GetTextForComparer(reqFilter.Operator) + ' ' + reqFilter.Value;
        }
    }

    private static BuildRequestFilter(filter, fields) {
        if (filter) {
            const retVal = new RequestFilter();
            if (filter.Filters && filter.Filters.length > 0) {
                retVal.Filters = [];
                retVal.ConcatOperator = filter.ConcatOperator;
                filter.Filters.forEach(f => {
                    retVal.Filters.push(ContainerSecuritySettings.BuildRequestFilter(f, fields));
                });
            } else {
                retVal.Operator = filter.Operator;
                retVal.Value = filter.Value;
                if (filter.FieldName) {
                    retVal.Name = filter.FieldName;
                } else {
                    retVal.Name = filter.FieldId;
                    if (fields) {
                        fields.some(f => {
                            if (f.ID == filter.FieldId) {
                                retVal.Name = f.Caption;
                                return true;
                            }
                            return false;
                        });
                    }
                }
            }
            return retVal;
        }
        return null;
    }

    private static BuildContainerFilter(filter, fields) {
        if (filter) {
            const retVal = {
                FieldId: null,
                FieldName: null,
                Value: null,
                Value2: null,
                Operator: 0,
                ConcatOperator: 0,
                Filters: null
            };
            if (filter.Filters && filter.Filters.length > 0) {
                retVal.Filters = [];
                retVal.ConcatOperator = filter.ConcatOperator;
                filter.Filters.forEach(f => {
                    retVal.Filters.push(ContainerSecuritySettings.BuildContainerFilter(f, fields));
                });
            } else {
                retVal.Operator = filter.Operator;
                retVal.Value = filter.Value;
                if (fields) {
                    fields.some(f => {
                        if (f.Caption == filter.Name) {
                            retVal.FieldId = f.ID;
                            return true;
                        }
                        return false;
                    });
                }
                if (!retVal.FieldId || retVal.FieldId === '00000000-0000-0000-0000-000000000000') {
                    retVal.FieldName = filter.Name;
                }
            }
            return retVal;
        }
        return null;
    }

    constructor(private service: SelfServiceSecurityService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.service.GetDataModelStructure().subscribe(result => {
            if (result) {
                const source = [];
                let index = 0;
                result.forEach(r => {
                    const mainNode = new ABaseTreeNode(index++);
                    mainNode.Caption = r.Caption;
                    mainNode.Selectable = false;
                    if (r.Children && r.Children.length > 0) {
                        const children = [];
                        r.Children.forEach(child => {
                            const ctn = plainToClass(ContainerTreeNode, child);
                            ctn.ID = index++;
                            children.push(ctn);
                        });
                        mainNode.HasChildren = true;
                        mainNode.Children = children;
                    }
                    source.push(mainNode);
                });
                this.TreeSource = source;
                this.cdRef.detectChanges();
            }
        });
    }

    async nodeSelect(ev) {
        const check = await this.checkHasChanges();
        if (check) {
            if (ev && ev.data) {
                this.TreeSelection = ev;
                this.Security = null;
                const args = this.InitArgs;
                if (args) {
                    this.service.GetSecurity(ev.data, args.Id).subscribe(result => {
                        if (result) {
                            this.Security = result;
                        } else {
                            this.Security = {
                                ContainerId: ev.data,
                                RoleId: args.Id,
                                Access: 0,
                                DataSecurity: [],
                                FieldSecurity: []
                            };
                        }
                        this.cdRef.detectChanges();
                    });
                }
            }
        }
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                this.HasChanges = false;
            }
        }
        return true;
    }

    save() {
        const sec = this.Security;
        if (sec) {
            if (sec.DataSecurity) {
                sec.DataSecurity.forEach(ds => {
                    if (ds.Filter && (!ds.Filter.Filters || ds.Filter.Filters.length === 0)) {
                        ds.Filter = null;
                    }
                });
            }
            this.service.SaveSecurity(sec).subscribe(result => {
                if (result) {
                    sec.SID = result.SID;
                    sec.Version = result.Version;
                }
                NotificationHelper.Success('@@Container Berechtigungen erfolgreich gespeichert', '@@Speichern');
            });
        }
        this.HasChanges = false;
    }

    async delete() {
        const sec = this.Security;
        if (sec && sec.SID) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Berechtigungen fuer Container {0} loeschen moechten?');
            let name = '';
            if (this.TreeSelection) {
                name = this.TreeSelection.Caption;
            }
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.TreeSelection = null;
                this.Security = null;
                this.service.DeleteSecurity(sec.SID).subscribe();
            }
        }
    }

    SetHasChanges() {
        this.HasChanges = true;
    }

    addDataSecurity() {
        if (this.Security) {
            if (this.Security.DataSecurity && this.Security.DataSecurity.length > 0) {
                const options = [1, 2, 3];
                this.Security.DataSecurity.forEach(ds => {
                    const index = options.indexOf(ds.Access);
                    if (index >= 0) {
                        options.splice(index, 1);
                    }
                });
                if (options.length > 0) {
                    this.Security.DataSecurity.push({
                        Access: options[0],
                        IsStar: false,
                        GroupId: 0,
                        Filter: null
                    });
                    this.HasChanges = true;
                }
            } else {
                this.Security.DataSecurity = [{
                    Access: 1,
                    IsStar: false,
                    GroupId: 0,
                    Filter: null
                }];
                this.HasChanges = true;
            }
        }
    }

    getOptions(ds) {
        if (ds && this.Security) {
            const retVal = [];
            const secs = this.Security.DataSecurity;
            this.AccessOptions.forEach(ao => {
                if (!secs.some(s => s != ds && s.Access == ao.Value)) {
                    retVal.push(ao);
                }
            });
            return retVal;
        } else {
            return this.AccessOptions;
        }
    }

    getFilterText(ds) {
        if (ds && ds.Filter && ds.Filter.Filters && ds.Filter.Filters.length > 0) {
            let fields = [];
            if (this.TreeSelection) {
                fields = this.TreeSelection.fields;
            }
            return ContainerSecuritySettings.getRequestFilterText(ds.Filter, fields);
        }
        return TranslateHelper.TranslatorInstance.instant('@@Kein Filter');
    }

    editFilter(ds) {
        if (ds) {
            const initArgs = {
                Filter: null,
                Columns: []
            };
            const fields = [];
            if (this.TreeSelection && this.TreeSelection.fields) {
                const list = [];
                this.TreeSelection.fields.forEach((field) => {
                    fields.push(field);
                    list.push({
                        IsFilterable: true,
                        PropertyName: field.Caption,
                        Caption: field.Caption,
                        Type: AttributeProperty.GetFilterType(field)
                    });
                });
                initArgs.Columns = list;
            }
            if (ds.Filter) {
                initArgs.Filter = ContainerSecuritySettings.BuildRequestFilter(ds.Filter, fields);
            }
            BaseDialog.ShowDialog({
                ContentType: AdvancedFilterDialog,
                InitArgs: initArgs,
                Handler: (result) => {
                    if (result) {
                        ds.Filter = ContainerSecuritySettings.BuildContainerFilter(result.Filter, fields);
                        this.HasChanges = true;
                    }
                    return true;
                }
            });
        }
    }

    removeFilter(i) {
        if (this.Security && this.Security.DataSecurity) {
            this.Security.DataSecurity.splice(i, 1);
        }
    }

    addFieldSecurity() {
        const sec = this.Security;
        if (sec) {
            const initArgs = {
                Columns: []
            };
            if (this.TreeSelection && this.TreeSelection.fields) {
                let fs = [];
                if (sec.FieldSecurity) {
                    fs = this.Security.FieldSecurity;
                }
                this.TreeSelection.fields.forEach(f => {
                    if (!fs.some(s => s.FieldId == f.ID)) {
                        initArgs.Columns.push({
                            Caption: f.Caption,
                            ID: f.ID,
                            Selected: false
                        });
                    }
                });
            }
            BaseDialog.ShowDialog({
                ContentType: ContainerFieldSecDialog,
                InitArgs: initArgs,
                Handler: (result) => {
                    if (result) {
                        result.forEach((col) => {
                            if (col.Selected === true) {
                                if (!sec.FieldSecurity) {
                                    sec.FieldSecurity = [];
                                }
                                sec.FieldSecurity.push({
                                    FieldId: col.ID,
                                    Access: 1,
                                    RecursiveSecurity: false
                                });
                                this.HasChanges = true;
                            }
                        });
                    }
                    return true;
                }
            });
        }
    }

    getFieldText(ds) {
        let retVal = '';
        if (ds) {
            retVal = ds.FieldId;
            if (this.TreeSelection && this.TreeSelection.fields) {
                this.TreeSelection.fields.some(f => {
                    if (f.ID == ds.FieldId) {
                        retVal = f.Caption;
                        return true;
                    }
                    return false;
                });
            }
        }
        return retVal;
    }

    removeFieldSec(i) {
        if (this.Security && this.Security.FieldSecurity) {
            this.Security.FieldSecurity.splice(i, 1);
        }
    }
}

@Component({
    selector: 'container-field-sec-dialog',
    template: '<div class="flex-row">' +
        '<mat-selection-list class="fill" (selectionChange)="colSelected($event)">' +
        '<mat-list-option *ngFor="let col of Columns" [value]="col">{{col.Caption}}</mat-list-option>' +
        '</mat-selection-list></div>',
    styleUrls: ['./container.settings.css']
})
export class ContainerFieldSecDialog {
    Columns = [];

    Initialize(args) {
        if (args && args.Columns) {
            this.Columns = args.Columns;
        }
    }

    GetDialogResult() {
        return this.Columns;
    }

    colSelected(ev) {
        if (ev && ev.option && ev.option.value) {
            ev.option.value.Selected = ev.option.selected;
        }
    }
}

export class ContainerTreeNode extends ABaseTreeNode {
    data;
    fields;
}
