import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import moment from 'moment';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { TagType } from '../../../models/enums/tagtype.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-textblock',
    templateUrl: './textblock.control.html',
    styleUrls: ['./textblock.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBlockControl extends IBaseComponent {
    static Type: any = 'textblock';
    static Default = { Type: 'textblock', Caption: 'Text',Layout: {
        _Editable: true,
            ...TextBlockControl.setDefaultProp()
    } };

    // set default properties
    static setDefaultProp() {
        return {
            Caption: 'Hello World!',
            Height: {Type: 0, Value: 30},
            Width: {Type: 0, Value: 150},
            // Font: {
            //     FontColor: null,
            //     FontFamilyName: null,
            //     FontSize: 14,
            //     Bold: null,
            //     Italic: null,
            //     Underline: null
            // }

        }
    }

    DataSourceText = '';
    DataBindingField;

    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption() {
        if (this.CaptionValue != null) {
            return this.CaptionValue;
        }
        return this.LayoutElement.Caption;
    }
    set Caption(val) {
        this.CaptionValue = val;
    }
    //#endregion
    //#region Tooltip
    TooltipValue;
    @Input()
    get Tooltip() {
        if (this.TooltipValue != null) {
            return this.TooltipValue;
        }
        return this.LayoutElement.Tooltip;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Caption', 'string', '@@Text'));
        this.PropertyList.push(new ElementProperty('Tooltip', 'string', '@@Tooltip'));
    }

    ControlInitialized() {
        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            this.CheckDataSourceText();
        });
        this.Subscriptions['LanguageChanged'] = LanguageService.SelectedLanguage.subscribe(() => {
            this.CheckDataSourceText();
        });
        this.OnDataBindingChanged();
    }

    OnDataBindingChanged() {
        super.OnDataBindingChanged();
        MetaHelper.FindTableProperties(this.Layout, this.LayoutElementValue).then((result) => {
            if (result && result.SID !== this.LayoutElementValue.DataSource && result.Fields) {
                this.DataBindingField = result.Fields.find(x => x.ID === this.LayoutElementValue.DataSource);
                this.CheckDataSourceText();
            } else {
                this.DataBindingField = null;
            }
        });
    }

    CheckDataSourceText() {
        if (this.DataBindingField && this.DataSourceValue != null) {
            if (this.DataBindingField.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') {
                EnumHelper.GetEnumValues(this.LayoutElementValue.DataSourceID).then((data) => {
                    let enumEntry;
                    if (data) {
                        enumEntry = data.find(x => x.Value === this.DataSourceValue);
                    }
                    if (enumEntry) {
                        this.DataSourceText = enumEntry.Caption;
                    } else {
                        this.DataSourceText = '' + this.DataSourceValue;
                    }
                    this.cdRef.detectChanges();
                });
                return;
            } else if (this.DataBindingField.Type === 'a0c232a9-4ab7-444c-a7e2-d23899a5673b') {
                this.DataSourceText = TranslatedString.GetTranslation(this.DataSourceValue);
            } else if (this.DataBindingField.Type === 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69' && this.LayoutElementValue.DateFormat) {
                this.DataSourceText = moment(this.DataSourceValue).format(this.LayoutElementValue.DateFormat);
            } else {
                this.DataSourceText = '' + this.DataSourceValue;
            }
        } else {
            this.DataSourceText = '';
        }
        this.cdRef.detectChanges();
    }
}
export class TextBlockPanel extends BasePanel {
    static override SIDS = ['c19b96db-010c-4869-9bb8-5683cfd98f4b']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'c19b96db-010c-4869-9bb8-5683cfd98f4b',
            ID: 'textblock',
            Caption: '@@TextBlock',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'textblock';
            }
        })
        PROPERTIES.push({
            ID: "Caption",
            Parent: "textblock",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Text",
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "Tooltip",
            Parent: "textblock",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Tooltip",
            Row: 2,
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "DateFormat",
            Parent: "textblock",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@DateFormat",
            Row: 3,
            CheckVisibility: (item) => {
                return item.DataSourceType == 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69'
            },
            InitArgs: {
                InputType: "text"
            }
        });
        
        PROPERTIES.push({
            ID: "TagType",
            Parent: "textblock",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label:'TagType',
            InitArgs: {
                Caption: "@@Select",
                EnumSource: TagType
            },
            Row: 4
        });
        PROPERTIES.push({
            ID: "BreakLine",
            Parent: "textblock",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Zeilenumbruch"
            },
            Row: 5
        });
    }
}