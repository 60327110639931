
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { RelSourceHelper } from '../../../helpers/relsource.helper';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { ContainerSelectDialog } from '../../dialogs/containerSelect.dialog';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';

export class TreeNode {
    children: TreeNode[];
    type: any;
    Id: any;
    name: any;
    UniqueID: any;
    ContainerID: any;
    ContainerUniqueID: any;
    DataSourceID: any;
    expanded: boolean;
}

/** Flat node with expandable and level information */
export class TreeFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    type: any;
    Id: any;
}


@Component({
    selector: 'objectcreatorsource-multipanel',
    templateUrl: './objectcreatorsource.multipanel.html'
})
export class ObjectCreatorSourceMultiPanel implements OnInit {

  static Type: any = 'objectcreatorsource-multipanel';
  static Default = { Type: 'objectcreatorsource-multipanel' };

    ContainerSelectDialog: ContainerSelectDialog;

  Subscriptions = [];

  //@Input()
  //SelectedItem;

  DataDescriptionDataValue: DatadescriptionData;

  @Input()
  get DataDescriptionData() {
    return this.DataDescriptionDataValue;
  }
  set DataDescriptionData(val) {
    this.DataDescriptionDataValue = val;
    this.Containers = val.Datadescription.ContainersValue;
  }


  ngOnInit(): void {
    //this.Subscriptions.push(LayoutService.SelectedItem.subscribe((item) => {
    //  this.SelectedItem = item;
    //}));
    }

    constructor() {

    }
    private _transformer = (node: TreeNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            Id: node.Id
        };
    }

    treeControl = new FlatTreeControl<TreeFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    Columns = [
        {
            Name: 'Name',
            Label: '@@Label',
            Type: 'String',
            Editable: false
        },
        {
            Name: 'InVisible',
            Label: '@@InVisible',
            Type: 'Boolean',
            Editable: true
        },
        {
            Name: 'Disabled',
            Label: '',
            Type: 'Boolean',
            Editable: true
        }
    ];


  get DataModelID() {
    return this.DataDescriptionData.DataModelID;
  }
    MaxUniqueID = 1;
    GetNextUniqueID() {
        return this.MaxUniqueID++;
    }
    UsedRelations = [];
    AllContainers = [];
    Containers = [];
    TreeSource =
        [
        ];
    dragNode: any;
    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<TreeFlatNode, TreeNode>();
    NodeToFields: Map<string, any[]> = new Map<string, any[]>();

    async itemDrop(event) {

        let item = JSON.parse(event.dataTransfer.getData('container'));
        let allContainers = JSON.parse(event.dataTransfer.getData('allcontainer'));
        if (this.Containers.length == 0) {
            item.UniqueID = this.MaxUniqueID++;
            this.Containers.push(item);
        }
        else {
            item.UniqueID = this.MaxUniqueID + 1;
            const retVal = await RelSourceHelper.GetValidRelation(this.DataModelID, item, this.Containers, this.UsedRelations, allContainers);
            if (retVal && retVal.Container.length > 0) {
                retVal.Container.forEach(c => {
                    if (this.MaxUniqueID < c.UniqueID) {
                        this.MaxUniqueID = c.UniqueID;
                    }
                    this.Containers.push(c);
                });
              this.UsedRelations.push(...retVal.RelDefs);
              //ToDo OC
                //if (this.BaseSlide.SelectedReportObjects) {
                //    this.BaseSlide.SelectedReportObjects.forEach((ro => {
                //        ro.DataDescription.Relations = this.UsedRelations;
                //    }));
                //}
                this.MaxUniqueID++;
            }
            else {
                const result = await MessageBoxHelper.ShowDialog(new TranslateFormatText(''), new TranslateFormatText('@@Hinzufuegen nicht moeglich, da keine weitere Verbindung mehr moeglich ist.'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
            }
        }
        this.UpdateTreeSource(null);
        // this.buildTree(this.Containers, 0);
    }


    UpdateTreeSource(node) {
        if (this.Containers) {
            if (node === null) {
                this.TreeSource = [];
                for (let i = 0; i < this.Containers.length; i++) {
                    if (this.Containers[i]) {
                        let Node = new TreeNode();
                        Node.name = this.Containers[i].Caption != null ? this.Containers[i].Caption : 'undefined';
                        Node.Id = this.Containers[i].SID;
                        Node.UniqueID = this.Containers[i].UniqueID;
                        Node.DataSourceID = this.Containers[i].DataSourceId;
                        Node.expanded = false;
                        if (this.Containers[i].Fields) {
                            //this.NodeToFields.set(Node.Id, this.Containers[i].Fields);
                            // let  children: any[];
                            Node.children = [];
                            for (let j = 0; j < this.Containers[i].Fields.length; j++) {
                                let node = this.Containers[i];
                                let field = new TreeNode();
                                field.name = this.Containers[i].Fields[j].Caption != null ? this.Containers[i].Fields[j].Caption : 'undefined';
                                if (!this.Containers[i].Fields[j].UniqueID) {
                                    this.Containers[i].Fields[j].UniqueID = this.MaxUniqueID++;
                                }
                                field.Id = this.Containers[i].Fields[j].ID;
                                field.UniqueID = this.Containers[i].Fields[j].UniqueID;
                                field.ContainerID = this.Containers[i].SID;
                                field.ContainerUniqueID = this.Containers[i].UniqueID;
                                field.DataSourceID = this.Containers[i].DataSourceId;
                                field.expanded = false;
                                this.NodeToFields.set(field.Id, [field]);
                                Node.children.push(field);
                                //children.push(field);
                            }
                            this.NodeToFields.set(Node.Id, Node.children);
                        }
                        this.TreeSource.push(Node);
                    }
                }
            }



            this.dataSource.data = this.TreeSource;
        }
    }

    //dragStart(event, item) {
    //  let fields = this.NodeToFields.get(item.Id);
    //  if (fields) {
    //    if (fields.length)
    //      //let t = JSON.stringify('sdfsdfsdf');
    //      event.dataTransfer.setData('fields', JSON.stringify(fields));
    //    event.dataTransfer.setData('caption', 'sdfsdfsdf');
    //  }
    //}



    hasChild = (_: number, node: TreeFlatNode) => node.expandable;

    onNodeDrop(event) {
        console.log('onNodeDrop');
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();
        return;
    }




    allowDrop(event) {
        event.preventDefault();
        event.dataTransfer.Move = event.dataTransfer.getData('container');
    }

    public dragOver(event: DragEvent) {
        //if (this.dragging) {
        event.preventDefault();
        // }
    };


  @Output() ShowContainerSelect = new EventEmitter<any>();
  OnShowContainerSelect(ev) {
      this.ShowContainerSelect.emit(ev);
    }


    refreshReportObject() {
        //ToDo OC this.BaseSlide.RefreshSelectedReportObjects();

    }
}
