import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import moment from 'moment';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { MultiStyleElem } from './set.multi.style.settings';

@Component({
    selector: 'remove-multi-style-settings',
    templateUrl: './remove.multi.style.settings.html'
})
export class RemoveMultiStyleSettings extends WorkflowDialogContent {
    static ModuleID = 'removeMultiStyleWFModule';

    Data = new RemoveMultiStyleSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = RemoveMultiStyleSettings.ModuleID;
        reg.Caption = '@@Styles zuruecksetzen';
        reg.GroupID = 'wfActions';
        reg.Index = 80;
        reg.SettingsControl = RemoveMultiStyleSettings;
        reg.SettingsTypeHelper = new RemoveMultiStyleSettingsDataHelper();
        reg.Executer = RemoveMultiStyleModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(RemoveMultiStyleSettingsData, json);
        }
    }

    getResult() {
        const elements = [];
        this.Data.Elements.forEach(x => {
            if (x.ElemName) {
                elements.push(x);
            }
        });
        this.Data.Elements = elements;
        return this.Data;
    }

    addElement() {
        if (this.Data) {
            if (this.Data.Elements) {
                this.Data.Elements.push(new MultiStyleElem());
            } else {
                this.Data.Elements = [new MultiStyleElem()];
            }
        }
    }

    removeElement(i) {
        if (this.Data && this.Data.Elements) {
            this.Data.Elements.splice(i, 1);
        }
    }
}

// @dynamic
export class RemoveMultiStyleSettingsData {
    @Type(() => MultiStyleElem)
    Elements: MultiStyleElem[] = [];
}

export class RemoveMultiStyleSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new RemoveMultiStyleSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Elements) {
            settings.Elements.forEach(elem => {
                if (elem.ElemName === oldName) {
                    elem.ElemName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class RemoveMultiStyleModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            status.ActualSettings.Elements.forEach(elemInfo => {
                const elem = status.WorkflowLayoutService.getElementByName(elemInfo.ElemName);
                if (elem) {
                    if (elem.Element) {
                        elem.Element.WorkflowStyles = [];
                        elem.Element.onWorkflowStylesChanged();
                    } else {
                        status.Logger.logWarning('RemoveMultiStyle modul: Control ' + elemInfo.ElemName + ' not found.');
                    }
                } else {
                    status.Logger.logWarning('RemoveMultiStyle modul: Element ' + elemInfo.ElemName + ' not found.');
                }
            });
            return 0;
        } else {
            status.Logger.logError('RemoveMultiStyle modul: No settings found.');
        }
        return super.execute(status);
    }
}
