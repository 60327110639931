import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';
import { PickerType } from 'src/app/models/enums/pickertype.enum';
import { PickerMode } from 'src/app/models/enums/pickermode.enum';

@Component({
    selector: 'datetime-theme-control',
    templateUrl: './datetime.control.html',
    styleUrls: ['./datetime.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeThemeControl extends  BaseThemeControl {
    ViewInitialized = false;
    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        this.cdRef.detectChanges();
    }

     //#region InputType
     InputTypeValue: string = "range";
     @Input()
     get InputType(): string {
         return this.InputTypeValue;
     }
     set InputType(val: string) {
         this.InputTypeValue = val;
         this.InputTypeChange.emit(this.InputTypeValue);
         if (this.ViewInitialized) {
             this.cdRef.detectChanges();
         }
     }
 
     @Output() InputTypeChange = new EventEmitter<string>();

    
    //#region IsChecked
    @Input()
    get IsChecked() {
        return this.DataSourceInternal;
    }
    set IsChecked(val) {
        this.DataSourceInternal = val;
        this.IsCheckedChange.emit(val);
        this.cdRef.detectChanges();
    }

    @Output() IsCheckedChange = new EventEmitter<any>();
 

    @Output() OnCheckedChanged = new EventEmitter<any>();

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
    }
    setFocus() {
        let control = document.getElementById("dt1");
        if (control) {
            control.focus();
        }
    }
    onDateChanged(ev) {
        this.EmitAction(ev);
        this.OnCheckedChanged.emit(ev.checked);
    }
}
