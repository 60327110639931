import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class GlobalVariableEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/globalvariable/';
    EndPoints = [
        //#region LoadVariables
        {
            Name: 'LoadVariables',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Variable'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region LoadVariable
        {
            Name: 'LoadVariable',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Variable'].filter((value) => {
                        return value._Id == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
    ]
}