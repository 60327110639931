import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { UnitSelectionControl } from '../../../appbuilder/controls/unittypeselection/unitselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { ExpandState } from '../../../models/enums/expandstate.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { UnitType } from '../../../models/enums/unittype.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-expansion-panel-layout',
    templateUrl: './expansion.panel.layout.html',
    styleUrls: ['./expansion.panel.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelLayout extends IBaseComponent {
    static Type: any = 'expansionpanel';
    static Default = { Type: 'expansionpanel' };

    Arrow = 'keyboard_arrow_right';
    ExpandState: ExpandState = ExpandState.Overlay;
    Hover = false;
    IconColStyle = {};
    ContentStyle = {};
    SelectedElement;
    @ViewChild('textCol', { read: ElementRef }) textCol: ElementRef;
    EditMode = false;

    ControlInitialized() {
        this.cdRef.detectChanges();
        if (this.LayoutElementValue.SelectedPanel) {
            const selected = this.LayoutElementValue.Elements.find(x => x.ID === this.LayoutElementValue.SelectedPanel);
            if (selected) {
                this.onItemClick(selected);
            } else {
                this.LayoutElementValue.SelectedPanel = null;
            }
        }
        if (!this.LayoutElementValue.ContentWidth) {
            const lu = new LayoutUnit();
            lu.Type = UnitType.Pixel;
            lu.Value = 200;
            this.LayoutElementValue.ContentWidth = lu;
        }
        this.Subscriptions['SelectedItem'] = LayoutService.SelectedItem.subscribe(x => {
            if (this.EditMode && this.LayoutElementValue.Elements.indexOf(x) > -1) {
                this.onItemClick(x);
            }
        });
        this.Subscriptions['EditMode'] = LayoutService.ViewType.subscribe(x => {
            this.EditMode = x === ViewType.Edit;
            this.checkContentWidth();
        });
        //this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
        //    this.LayoutElementValue.Elements.forEach((element) => {
        //        if (element.Element) {
        //            element.Element.cdRef.detectChanges();
        //        }
        //    })
        //});
    }

    checkContentWidth() {
        const contStyle = {};
        if (this.LayoutElementValue.ContentWidth) {
            contStyle['width'] = LayoutUnit.ToStyle(this.LayoutElementValue.ContentWidth);
        }
        this.ContentStyle = contStyle;
    }

    onLayoutElementChanged() {
        this.checkContentWidth();
    }

    onExpandClick() {
        if (this.LayoutElementValue.SelectedPanel) {
            this.LayoutElementValue.SelectedPanel = null;
            this.SelectedElement = null;
            this.ExpandState = ExpandState.None;
            this.updateArrow(false);
            this.setIconStyle(false);
        } else if (this.ExpandState === ExpandState.Expanded) {
            this.ExpandState = ExpandState.None;
            this.updateArrow(false);
            this.setIconStyle(false);
        } else {
            this.ExpandState = ExpandState.Expanded;
            this.updateArrow(true);
            this.setIconStyle(true);
        }
    }

    onMouseEnter() {
        this.Hover = true;
        if (this.ExpandState === ExpandState.None) {
            this.ExpandState = ExpandState.Overlay;
        }
    }

    updateArrow(expanded: boolean) {
        if (expanded) {
            this.Arrow = this.LayoutElementValue.ExpandToLeft ? 'keyboard_arrow_right' : 'keyboard_arrow_left';
        } else {
            this.Arrow = this.LayoutElementValue.ExpandToLeft ? 'keyboard_arrow_left' : 'keyboard_arrow_right';
        }
    }

    onItemClick(elem) {
        this.LayoutElementValue.SelectedPanel = elem.ID;
        this.SelectedElement = elem;
        this.ExpandState = ExpandState.None;
        this.Hover = false;
        this.updateArrow(true);
        this.setIconStyle(false);
    }

    setIconStyle(expanded: boolean) {
        const style = {};
        if (expanded && this.textCol) {
            if (this.LayoutElementValue.ExpandToLeft) {
                style['margin-left'] = this.textCol.nativeElement.offsetWidth + 'px';
            } else {
                style['margin-right'] = this.textCol.nativeElement.offsetWidth + 'px';
            }
        }
        this.IconColStyle = style;
    }
}

export class ExpansionPanelPanel extends BasePanel {
    static override SIDS = ['27f1a96d-2efd-4505-950a-ff0eea1841fc']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'27f1a96d-2efd-4505-950a-ff0eea1841fc',
            ID: 'expansionpanel',
            Caption: '@@ExpansionPanel',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'expansionpanel';
            }
        });
        //#endregion
        PROPERTIES.push({
            ID: "ContentWidth",
            Parent: "expansionpanel",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "@@Contentwidth",
            InitArgs: {
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "ExpandToLeft",
            Parent: "expansionpanel",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Nach links aufklappen"
            }
        });
        const ContentTypes = [
            { Caption: '@@Grid', Value: 'grid' },
            { Caption: '@@Raster', Value: 'raster' },
            { Caption: '@@Flex', Value: 'flex' },
            { Caption: '@@Canvas', Value: 'canvas' }
        ]
        MediaService.IconList.subscribe((icons) => {
            if (icons) {
                let Icons = [];
                icons.forEach((icon) => {
                    Icons.push({ Value: icon })
                })
                PROPERTIES.push({
                    ID: "Elements",
                    Parent: "expansionpanel",
                    Content: new ComponentPortal(ListBoxThemeControl),
                    Label: "@@Panels",
                    Properties: [
                        {
                            ID: "Name",
                            Parent: "expansionpanel",
                            Content: new ComponentPortal(TextboxThemeControl),
                            Label: "@@Name"
                        },
                        {
                            ID: "Caption",
                            Parent: "expansionpanel",
                            Content: new ComponentPortal(TextboxThemeControl),
                            Label: "@@Caption"
                        },
                        {
                            ID: "ElementType",
                            Parent: "expansionpanel",
                            Content: new ComponentPortal(ComboboxThemeControl),
                            Label: "@@ContentType",
                            Column: 1,
                            Row: 3,
                            InitArgs: {
                                Placeholder: "@@Select",
                                Multiple: false,
                                ItemsSource: ContentTypes,
                                DisplayMemberPath: "Caption",
                                ValueMemberPath: "Value"
                            }
                        },
                        {
                            ID: "Icon",
                            Parent: "expansionpanel",
                            Content: new ComponentPortal(ComboboxThemeControl),
                            Label: "@@Icon",
                            InitArgs: {
                                Placeholder: "@@Select",
                                Multiple: false,
                                ItemsSource: Icons,
                                DisplayMemberPath: 'Value',
                                ValueMemberPath: 'Value',
                                IconMemberPath: 'Value'
                            }
                        }
                    ],
                    InitArgs: {
                        GenerateNewItem: () => {
                            const le = new LayoutElement();
                            le.ElementType = 'grid';
                            le.Name = LayoutHelper.GetNextElementNameByPrefix(LayoutService.SelectedLayout.getValue(), 'panel');
                            le.Caption = le.Name;
                            return le;
                        }
                    }
                });
            }
        });
    }
}