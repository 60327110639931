import { Component } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { deserialize, serialize, Type } from 'class-transformer';
import { RoutingHelper } from '../../../helpers/injector.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { SideNavService } from '../../../services/sidenav.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-openurl-settings',
    templateUrl: './openURL.settings.html',
    styleUrls: ['./openURL.settings.css']
})
export class OpenURLSettings extends FormulaWorkflowDialogContent {
    Data = new OpenURLSettingsData();
    NavigationItems;

    private static GetNode(navItem, id: IDKeeper) {
        const retVal = new OpenURLNode(id.NextID);
        retVal.Caption = navItem.Label;
        retVal.data = navItem.Url;
        if (navItem.children) {
            retVal.HasChildren = true;
            retVal.Children = [];
            retVal.IsExpanded = true;
            navItem.children.forEach(function (ni) {
                const node = OpenURLSettings.GetNode(ni, id);
                retVal.Children.push(node);
            });
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'urlWFModule';
        reg.Caption = '@@URL oeffnen';
        reg.GroupID = 'navigation';
        reg.Index = 20;
        reg.SettingsControl = OpenURLSettings;
        reg.SettingsTypeHelper = new OpenURLSettingsDataHelper();
        reg.Executer = OpenURLModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(OpenURLSettingsData, seri);
        }
    }

    getResult(): any {
        return this.Data;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.Parameters.some(function (p) {
            return !p.Name || p.Name === '' || !p.Value || p.Value === '';
        })) {
            retVal.Error = '@@Bitte vergeben Sie fuer jeden Parameter einen Namen und einen Wert';
            retVal.IsCorrect = false;
        }
        return retVal;
    }

    ngOnInit() {
        if (!this.WFEditOptions || !this.WFEditOptions.IsTemplate) {
            const nav = SideNavService.SelectedNavigation.getValue();
            if (nav && nav.NavigationItems) {
                const list = [];
                const id = new IDKeeper();
                nav.NavigationItems.forEach(function (ni) {
                    const node = OpenURLSettings.GetNode(ni, id);
                    list.push(node);
                });
                this.NavigationItems = list;
            }
        }
    }

    nodeSelected(ev) {
        if (ev && ev.data) {
            this.Data.URL = ev.data;
        }
    }

    addParam() {
        this.Data.Parameters.push(new URLParameter());
    }

    removeParam(i) {
        this.Data.Parameters.splice(i, 1);
    }
}

export class OpenURLNode extends ABaseTreeNode {
    data;
}

// @dynamic
export class OpenURLSettingsData {
    Internal = true;
    ReadURLFromStatus = false;
    URL: string;
    @Type(() => URLParameter)
    Parameters: URLParameter[] = [];
    OpenInNewWindow = false;
}

export class URLParameter {
    Name: string;
    Manual: boolean;
    Value: string;
}

export class OpenURLSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new OpenURLSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Parameters) {
            settings.Parameters.forEach(x => {
                if (!x.Manual && x.Value === oldName) {
                    x.Value = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class OpenURLModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let url = status.ActualSettings.URL;
            if (status.ActualSettings.ReadURLFromStatus === true) {
                url = status.Context.get(url);
            }
            if (status.ActualSettings.Internal === true) {
                let options: NavigationExtras;
                if (status.ActualSettings.Parameters && status.ActualSettings.Parameters.length > 0) {
                    options = {
                        queryParams: {
                        }
                    };
                    const calculator = new WorkflowFormulaCalculator(status);
                    status.ActualSettings.Parameters.forEach(function (p) {
                        if (p.Manual) {
                            try {
                                options.queryParams[p.Name] = calculator.CalcFormula(p.Value);
                            } catch (ex) {
                                status.Logger.logWarning('URL modul: Error while calculating formula ' + p.Value + ' (' + ex + ').');
                            }
                        } else {
                            const elem = status.WorkflowLayoutService.getElementByName(p.Value);
                            if (elem && elem.Element) {
                                options.queryParams[p.Name] = elem.Element.DataSource;
                            } else {
                                status.Logger.logWarning('URL modul: Element not found.');
                            }
                        }
                    });
                }
                RoutingHelper.Router.navigate([url], options);
            } else {
                if (status.ActualSettings.Parameters && status.ActualSettings.Parameters.length > 0) {
                    const list = [];
                    const calculator = new WorkflowFormulaCalculator(status);
                    status.ActualSettings.Parameters.forEach(function (p) {
                        if (p.Manual) {
                            try {
                                list.push(p.Name + '=' + calculator.CalcFormula(p.Value));
                            } catch (ex) {
                                status.Logger.logWarning('URL modul: Error while calculating formula ' + p.Value + ' (' + ex + ').');
                            }
                        } else {
                            const elem = status.WorkflowLayoutService.getElementByName(p.Value);
                            if (elem && elem.Element) {
                                list.push(p.Name + '=' + elem.Element.DataSource);
                            } else {
                                status.Logger.logWarning('URL modul: Element not found.');
                            }
                        }
                    });
                    url += '?' + list.join('&');
                }
                const target = status.ActualSettings.OpenInNewWindow ? '_blank' : '_self';
                window.open(url, target);
            }
            return 0;
        } else {
            status.Logger.logError('URL modul: No settings found.');
        }
        return super.execute(status);
    }
}
