import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataPreviewInfo } from '../../../models/rest/requestparameters';
import { DataPreviewService } from '../../../services/data.preview.service';

@Component({
    selector: 'container-column-dialog',
    templateUrl: './containerview.column.dialog.html',
    styleUrls: ['./containerview.column.dialog.css']
})
export class ContainerColumnDialog implements OnInit {

    Args: ContainerColumnDialogArgs;
    AllColumns = [];
    StructureOptions = [];

    constructor(private preview: DataPreviewService,private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.Args && this.Args.DataSourceId && this.Args.PreviewInfo) {
            this.preview.GetStructureWithOptions(this.Args.DataSourceId, this.Args.PreviewInfo).subscribe((result) => {
                if (result) {
                    if (result.Structure) {
                        result.Structure.forEach(function (col) {
                            col.ColSelected = true;
                            col.ColVisible = true;
                        });
                        this.AllColumns = result.Structure;
                    }
                    if (result.Options) {
                        this.StructureOptions = result.Options;
                    }
                }
                this.cdRef.detectChanges();
            });
        }
    }

    Initialize(args) {
        this.Args = args;
    }

    GetDialogResult() {
        var selCols = [];
        this.AllColumns.forEach(function (col) {
            if (col.ColSelected && col.ColVisible) {
                delete col.ColSelected;
                delete col.ColVisible;
                selCols.push(col);
            }
        });
        return {
            SelectedColumns: selCols,
            Options: this.StructureOptions
        };
    }

    hideUsedChanged(ev) {
        if (typeof ev === 'boolean') {
            if (ev) {
                if (this.Args) {
                    var fields = this.Args.Fields;
                    if (fields && fields.length > 0) {
                        this.AllColumns.forEach(function (col) {
                            if (fields.some(function (f) {
                                return f.Name == col.Name && f.DataTyp == col.DataTyp;
                            })) {
                                col.ColVisible = false;
                            }
                        });
                    }
                }
            } else {
                this.AllColumns.forEach(function (col) {
                    col.ColVisible = true;
                });
            }
        }
    }

    selectAllChanged(ev) {
        if (typeof ev === 'boolean') {
            this.AllColumns.forEach(function (col) {
                col.ColSelected = ev;
            });
        }
    }
}

export class ContainerColumnDialogArgs {
    DataSourceId: string;
    PreviewInfo: DataPreviewInfo;
    Fields: any[] = [];
}