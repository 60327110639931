import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
    selector: 'reqeustname-dialog',
    templateUrl: './reqeustname.dialog.html',
    styleUrls: ['./reqeustname.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestNameDialog implements OnInit {
    Text = null;
    Placeholder = null;
    HasChanges = false;
    CustomButtons = [{
        Caption: '@@Speichern',
        ID: 0
    }, {
        Caption: '@@Abbrechen',
        ID: 1
    }];
    CloseOnCustomButton = true;
    result = null;
    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void { }

    Initialize(data) {
        if (data) {
            if (data.Text) {
                this.Text = data.Text;
            }
            if (data.Placeholder) {
                this.Placeholder = data.Placeholder;
            }
        }
    }

    GetDialogResult() {
        return this.result;
    }

    OnCustomButtonClicked(button) {
        if (button && button.ID === 0) {
            this.result = this.Text;
        } else {
            this.result = null;
        }
        return this.result;
    }
}
