export class NumberFormat {
    DecimalDigits = 0;
    ShowNumberGroupSeperator = true;
    Divisor = 1;
    Suffix: DivisorTypes = DivisorTypes.None;
    ChangeSign = false;
    CustomSuffix: string;
    CustomPraefix: string;
    WithoutSpaces = false;
}

export enum DivisorTypes {
    None,
    Percent,
    Thousand,
    HundredThousand,
    Million
}
