import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { RelSourceHelper } from '../../../helpers/relsource.helper';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { TranslatedString } from '../../../models/translatedstring.model';
import { ABaseTreeNode } from '../../common/basetreecontrol/base.tree.control';
import { ContainerSelectDialog } from '../../dialogs/containerSelect.dialog';
import { DatadescriptionControl, OCMPDragInfo } from '../../dialogs/datadescription/datadescription.control';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';

export class TreeNodeRelational extends ABaseTreeNode {
    Type: any;
    FieldID: any;
    ContainerID: any;
    ContainerUniqueID: any;
    DataSourceID: any;
    TranslatedCaption: TranslatedString;
}

@Component({
    selector: 'objectcreatorsource-relational',
    templateUrl: './objectcreatorsource.relational.html'
})
export class ObjectCreatorSourceRelational implements OnInit {

    static DragEnd = new Subject();

    DataDescriptionDataValue: DatadescriptionData;

    @Input()
    get DataDescriptionData() {
        return this.DataDescriptionDataValue;
    }
    set DataDescriptionData(val) {
        this.DataDescriptionDataValue = val;
    }

    ContainerSelectDialog: ContainerSelectDialog;

    get Containers() {

        if (this.DataDescriptionData.Datadescription) {
            if (!this.DataDescriptionData.Datadescription.ContainersValue) {
                this.DataDescriptionData.Datadescription.ContainersValue = [];
            }
            return this.DataDescriptionData.Datadescription.ContainersValue;
        }
        return [];
    }

    get DataModelID() {
        return this.DataDescriptionData.DataModelID;
    }

    MaxUniqueID = 1;
    UsedRelations = [];
    TreeSource = [];

    @Output() ShowContainerSelect = new EventEmitter<any>();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    addContainer() {
        this.ShowContainerSelect.emit({
            Query: {
                DataModelID: this.DataModelID
            }
        });
    }

    itemDrop(event) {
        const item = JSON.parse(event.dataTransfer.getData('container'));
        const allContainers = JSON.parse(event.dataTransfer.getData('allcontainer'));
        if (this.Containers.length == 0) {
            item.UniqueID = this.MaxUniqueID++;
            this.Containers.push(item);
            const dd = this.DataDescriptionData;
            if (dd.Datadescription) {
                dd.Datadescription.SetColumnsFilter();
            }
            this.UpdateTreeSource();
        } else {
            if (!this.Containers.some(x => x.SID === item.SID)) {
                item.UniqueID = this.MaxUniqueID + 1;
                RelSourceHelper.GetValidRelation(this.DataModelID, item, this.Containers, this.UsedRelations, allContainers).then(retVal => {
                    if (retVal) {
                        if (retVal.Container.length > 0) {
                            retVal.Container.forEach(c => {
                                if (this.MaxUniqueID < c.UniqueID) {
                                    this.MaxUniqueID = c.UniqueID;
                                }
                                this.Containers.push(c);
                            });
                            this.UsedRelations.push(...retVal.RelDefs);
                            this.DataDescriptionData.Datadescription.Relations = this.UsedRelations;
                            this.DataDescriptionData.Datadescription.SetColumnsFilter();
                            this.MaxUniqueID++;
                            this.UpdateTreeSource();
                        }
                    } else {
                        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Hinzufuegen nicht moeglich, da keine weitere Verbindung mehr moeglich ist.'), new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Error);
                    }
                });
            }
        }
    }

    UpdateTreeSource() {
        if (this.Containers) {
            const treeSource = [];
            this.Containers.forEach(cont => {
                if (cont) {
                    const Node = new TreeNodeRelational(cont.UniqueID);
                    Node.Caption = cont.Caption != null ? cont.Caption : 'undefined';
                    Node.FieldID = cont.SID;
                    Node.DataSourceID = cont.DataSourceId;
                    Node.IsExpanded = false;
                    Node.Draggable = true;
                    if (cont.Fields) {
                        Node.HasChildren = true;
                        Node.Children = [];
                        cont.Fields.forEach(f => {
                            if (!f.UniqueID) {
                                f.UniqueID = this.MaxUniqueID++;
                            }
                            const field = new TreeNodeRelational(f.UniqueID);
                            field.Caption = f.Caption != null ? f.Caption : 'undefined';
                            field.TranslatedCaption = new TranslatedString(field.Caption);
                            field.FieldID = f.ID;
                            field.ContainerID = cont.SID;
                            field.ContainerUniqueID = cont.UniqueID;
                            field.DataSourceID = cont.DataSourceId;
                            field.Draggable = true;
                            field.Type = f.FieldType;
                            Node.Children.push(field);
                        });
                    }
                    treeSource.push(Node);
                }
            });
            this.TreeSource = treeSource;
            this.cdRef.detectChanges();
        }
    }

    dragStart(event) {
        if (event && event.DragData && event.DragData.length > 0) {
            const item = event.DragData[0];
            if (item) {
                if (item.HasChildren) {
                    DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.Fields, item.Children));
                } else {
                    DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.Fields, [item]));
                }
            }
        }
    }

    dragEnd(ev) {
        ObjectCreatorSourceRelational.DragEnd.next(null);
        DatadescriptionControl.ObjectCreatorMPDragStart.next(null);
    }

    onNodeDrop(event) {
        console.log('onNodeDrop');
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();
        return;
    }

    allowDrop(event) {
        event.preventDefault();
        event.dataTransfer.Move = event.dataTransfer.getData('container');
    }

    ngOnInit() {
        this.UpdateTreeSource();
    }
}
