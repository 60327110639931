import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InlineWorker } from '../helpers/worker.helper';


@Injectable({
    providedIn: 'root'
})
export class WorkerService {

    ConvertToTree(content) {
        return new Promise((resolve, reject) => {
            let worker = new InlineWorker(() => {
                // START OF WORKER THREAD CODE
                const ConvertToTree = (TableProperties, SelectedItem) => {

                    let result = {
                        TreeData: [],
                        SelectedTreeNodes: []
                    }
                    if (TableProperties) {
                        const structure = {};
                        const childNodes = [];
                        TableProperties.Columns.forEach((field, index) => {
                            const parts = field.Name.split('.');
                            const item: any = {};
                            item.data = field;
                            item.label = parts[parts.length - 1];
                            item.key = '' + index;
                            if (field.Type2 == 'c4ef25dd-c176-418c-836e-f26c52d7f59c' || field.Type2 == 'a236172b-9aaa-4211-9fe9-b57daf3f3a29') {
                                item.children = [];
                                structure[field.Name + '.'] = item;
                            }
                            if (parts.length === 1) {
                                if (SelectedItem.Columns) {
                                    const col = SelectedItem.Columns.find((value) => value.FetchData && value.Name == field.Name);
                                    if (col) {
                                        result.SelectedTreeNodes.push(item);
                                    }
                                }
                                result.TreeData.push(item);
                            } else {
                                childNodes.push({
                                    Path: field.Name.substring(0, field.Name.length - item.label.length),
                                    Data: item
                                });
                            }
                        });
                        childNodes.forEach(x => {
                            const parent = structure[x.Path];
                            if (parent) {
                                parent.children.push(x.Data);
                                if (SelectedItem.Columns) {
                                    const col = SelectedItem.Columns.find((val) => val.FetchData && val.Name == x.Data.data.Name);
                                    if (col) {
                                        result.SelectedTreeNodes.push(x.Data);
                                    }
                                }
                            }
                        });
                    }
                    return result;
                }
                // @ts-ignore
                this.onmessage = (data) => {
                    let value = data.data;
                    let result = ConvertToTree(value.TableProperties, value.SelectedItem);
                    // @ts-ignore
                    this.postMessage(result);
                };
                // END OF WORKER THREAD CODE
            });
            worker.postMessage(content);
            worker.onmessage().subscribe((data) => {
                resolve(data.data);
                worker.terminate();
            });

            worker.onerror().subscribe((data) => {
                console.log(data);
            });
        });
    }
    MatchColumns(content) {
        return new Promise((resolve, reject) => {
            const worker = new InlineWorker(() => {
                // START OF WORKER THREAD CODE
                const Match = (TableProperties, SelectedItem) => {
                    let result = null;
                    if (TableProperties) {
                        const Columns = [];
                        const Lists = [];
                        TableProperties.Columns.forEach((col) => {
                            let found = false;

                            if (col.IsList) {
                                Lists.push(col.Name);
                            }

                            if (SelectedItem.Columns) {
                                let column = SelectedItem.Columns.find((savedcol) => savedcol.Name === col.Name && savedcol.TableName === col.TableName);
                                if (column) {
                                    found = true;
                                    if (col.IsList != column.IsList) {
                                        column.IsList = col.IsList;
                                    }
                                    if (!col.IsList) {
                                        if (Lists.length > 0) {
                                            Lists.forEach((name) => {
                                                if (column.Name.startsWith(name)) {
                                                    column.ParentIsList = true;
                                                }
                                            });
                                        }
                                    }

                                    Columns.push(column);
                                }
                            }
                            if (!found) {
                                col['Caption'] = col['Name'];
                                col['IsVisible'] = false;
                                col['FetchData'] = false;
                                if (Lists.length > 0) {
                                    Lists.forEach((name) => {
                                        if (col.Name.startsWith(name)) {
                                            col.ParentIsList = true;
                                        }
                                    });
                                }
                                Columns.push(col);
                            }
                        });
                        result = Columns;
                    }
                    return result;
                }
                // @ts-ignore
                this.onmessage = (data) => {
                    let value = data.data;
                    let result = Match(value.TableProperties, value.SelectedItem);
                    // @ts-ignore
                    this.postMessage(result);
                };
                // END OF WORKER THREAD CODE
            });
            worker.postMessage(content);
            worker.onmessage().subscribe((data) => {
                resolve(data.data);
                worker.terminate();
            });

            worker.onerror().subscribe((data) => {
                console.log(data);
            });
        });
    }
    ExecuteAndWaitForWorkflow(content): Subject<any> {
        let retSub = new Subject<any>();

        const worker = new InlineWorker(() => {
            let self = this;
            // START OF WORKER THREAD CODE
            const Connect = (URL, token, channel) => {
                // @ts-ignore
                let connection = new signalR.HubConnectionBuilder().withUrl(URL, {
                    skipNegotiation: true,
                    // @ts-ignore
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => {
                        return token;
                    }
                    // @ts-ignore
                }).configureLogging(signalR.LogLevel.None).build();
                try {
                    connection.start().then(function () {
                        connection.on('internal', function (message) {
                            const parsed = JSON.parse(message);
                            let chan = channel[0].Channel;
                            if (parsed.Channels && parsed.Type === 'evidanza.App.Shared.Workflow.Rest.WorkflowExecutionInfo') {
                                if (parsed.Channels.indexOf(chan) > -1) {
                                    let result = JSON.parse(parsed.Content);
                                    // @ts-ignore
                                    self.postMessage(result);
                                    worker.terminate();
                                }
                            }
                        });
                        connection.invoke("Subscribe", channel);
                        // @ts-ignore
                        self.postMessage("connected");
                    });

                } catch (err) {
                    console.log(err);
                }
            }
            // @ts-ignore
            this.onmessage = (data) => {
                let value = data.data;

                if (value && value.BaseUrl && value.WebsocketUrl && value.token) {
                    let url = value.BaseUrl.origin + '/assets/signalr/signalr.js';
                    // @ts-ignore
                    importScripts(url)
                    // @ts-ignore
                    Connect(value.WebsocketUrl, value.token, value.channel);

                }
            };
            // END OF WORKER THREAD CODE
        });
        worker.postMessage(content);
        worker.onmessage().subscribe((data) => {
            retSub.next(data.data);
        });

        worker.onerror().subscribe((data) => {
            console.log(data);
            worker.terminate();
        });
        return retSub;
    }
}