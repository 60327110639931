import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitDisplayName'
})
export class SplitDisplayNamePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    // Check if the value contains an '@' symbol and split it
    const splitValue = value?.split('@');
    return splitValue[0]; // Return the first part before the '@' sign
  }
}
