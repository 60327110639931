import { RequestBase } from "./request-base";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class LogsService extends RequestBase {
    BasePath = "config/api/traces";

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    Get(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Find', filter, this.options);
    }
    Count(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Count', filter, this.options);
    }
    Delete(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Delete', filter, this.options);
    }
}
