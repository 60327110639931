import { Component, Inject, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogResult } from "../../../models/enums/dialogresult.enum";
import { Action } from "../../../models/ribbon/action.model";
import { DEPLOYMENTACTIONS_REGISTRY } from "../../../registries/actions/actions.registry";
import { MetaService } from "../../../services/meta.service";

@Component({
    selector: 'deployment-action-dialog',
    templateUrl: 'deploymentaction.dialog.html',
    styleUrls: ['./deploymentaction.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentActionDialog implements AfterViewInit, OnDestroy{

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    CustomButtons = [];
    DeploymentAction;
    DialogData;
    Action;
    Subscription;
    Component;

    constructor(private dialogRef: MatDialogRef<Action>, @Inject(MAT_DIALOG_DATA) public data: any, private factoryResolver: ComponentFactoryResolver, private metaService: MetaService) {
        if (data && data.Data && data.Data.InitArgs && data.Data.InitArgs.Data && data.Data.InitArgs.Data.ActionType) {
            this.DialogData = data.Data;
            if (DEPLOYMENTACTIONS_REGISTRY.has(data.Data.InitArgs.Data.ActionType)) {
                this.DeploymentAction = data.Data.InitArgs.Data;
                this.Action = DEPLOYMENTACTIONS_REGISTRY.get(data.Data.InitArgs.Data.ActionType);
                this.CustomButtons = this.Action.Buttons;
                if (this.Action.Prepare) {
                    this.Action.Prepare(data.Data.InitArgs.Data);
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }
    
    ngAfterViewInit(): void {
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (this.Action) {
                let type = null;
                if (this.Action.Control) {
                    type = this.Action.Control;
                }
                if (type) {
                    const factory = this.factoryResolver.resolveComponentFactory(type);
                    const comp = factory.create(this.viewContainerRef.parentInjector);
                    this.viewContainerRef.insert(comp.hostView);
                    this.Component = comp.instance;
                    if (this.Component) {
                        if (this.Action.PreparationDone) {
                            this.Subscription = this.Action.PreparationDone.subscribe((value) => {
                                if (value) {
                                    this.Action.Properties.forEach((prop) => {
                                        this.Component[prop.Key] = prop.Value
                                    })
                                }
                            })
                        } else {
                            this.Action.Properties.forEach((prop) => {
                                this.Component[prop.Key] = prop.Value
                            })
                        }
                        this.Component.DialogHandle = this;
                    }
                }
                if (this.Action.Layout) {
                    //Layout holen
                }
            }
        }
    }

    OnCustomButtonClicked(button) {
        if (button) {
            if (button.Execute) {
                button.Execute();
            }
            if (button.CloseOnClick) {
                if (button.CloseStatus == DialogResult.Ok) {
                    this.DeploymentAction.Completed = true;
                    this.metaService.AddOrUpdateDeploymentAction(this.DeploymentAction).subscribe(() => {
                        if (this.DialogData.Handler) {
                            this.DialogData.Handler(button.CloseStatus);
                        }
                        this.dialogRef.close(button.CloseStatus);
                    });
                } else {
                    if (this.DialogData.Handler) {
                        this.DialogData.Handler(button.CloseStatus);
                    }
                    this.dialogRef.close(button.CloseStatus);
                }
            }
        }
    }
}