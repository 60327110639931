import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class KPIService extends RequestBase {
    BasePath = 'api/settings/kpi';

    GetKPIInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetKPIInfos', this.options);
    }

    LoadKPI(sid): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadKPI?sid=' + sid, this.options);
    }

    SaveKPI(kpi): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveKPI', kpi, this.options);
    }

    DeleteKPI(sid): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteKPI?sid=' + sid, this.options);
    }

    GetKPICategoryInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetKPICategoryInfos', this.options);
    }

    LoadKPICategory(sid): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadKPICategory?sid=' + sid, this.options);
    }

    SaveKPICategory(cat): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveKPICategory', cat, this.options);
    }

    DeleteKPICategory(sid): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteKPICategory?sid=' + sid, this.options);
    }

    GetKPIStructure(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetKPIStructure', this.options);
    }
}
