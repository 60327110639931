import { add, divide, multiply } from 'mathjs';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { DataDescriptionHelper } from '../../../components/query/ddhelper.query';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription, HeterogenArea } from '../../datadescription/multi/datadescription.model';
import { LevelNode } from '../../datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../datadescription/multi/measureinfo.model';
import { Axis, AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { AxisType, CellType } from '../../enums/query.enum';
import { SpecialElementType } from '../../enums/specialElementType';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { CalculateTaskExecuter } from '../datatasks/calculate.model';
import { CumulateTaskExecuter } from '../datatasks/cumulate.model';
import { PreviousLevelExecuter } from '../datatasks/previous.level.model';
import * as math from 'mathjs';
import { Aggregation } from '../../enums';
import { ValueAndType } from '../../basic/formulaEditor.model';
import { ValueType } from '../../enums/valuetype.enum';
import { Gradient } from '../../style/gradient.model';
import { GradientStopColor } from '../../style/gradientstopcolor.model';
import { GradientType } from '../../enums/gradienttype.enum';
import { Color } from '../../style/color.model';
import { Font } from '../../style/font.model';

export class SumRowInfo {
    ShowTotal = false;
    ShowRowsSummary = false;
    ShowTotalOnX = false;
    SumRowText: string;
}

export class SumRowTaskExecuter {

    private static async GetSortedMeasures(area: HeterogenArea, plTasks, measureNodes: AxisNode[], dmID) {
        const retVal = {
            Measures: [],
            ParentReferencedY: [],
            ParentReferencedX: [],
            Calculate: [],
            DistinctCount: [],
            HasNodes: false
        };
        if (area && area.Measures) {
            for (let i = 0; i < area.Measures.length; i++) {
                const m = area.Measures[i];
                const mNode = measureNodes.find(x => x.UniqueID === m.UniqueID);
                if (mNode) {
                    const nodeInfo = {
                        MeasureNode: mNode,
                        MeasureInfo: m
                    };
                    if (m.SpecialElementType === SpecialElementType.None) {
                        const plTask = plTasks.find(x => x.MeasureUniqueID === m.UniqueID);
                        if (plTask) {
                            if (plTask.Axis === AxisType.X_Axis) {
                                retVal.ParentReferencedX.push(nodeInfo);
                            } else {
                                retVal.ParentReferencedY.push(nodeInfo);
                            }
                        } else {
                            if (mNode.IsCalculated) {
                                retVal.DistinctCount.push(nodeInfo);
                            } else {
                                const dbMeasure = await MultiCacheService.GetMeasure(mNode.MemberId, dmID);
                                if (dbMeasure.Aggregator === Aggregation.DistinctCount) {
                                    retVal.DistinctCount.push(nodeInfo);
                                } else {
                                    retVal.Measures.push(nodeInfo);
                                }
                            }
                        }
                        retVal.HasNodes = true;
                    } else if (m.SpecialElementType === SpecialElementType.CalculatedElement) {
                        retVal.Measures.push(nodeInfo);
                        retVal.HasNodes = true;
                    }
                }
            }
        }
        return retVal;
    }

    private static GetSumInfos(nodes: AxisNode[], axis: Axis, sumRowText: string) {
        const retVal = [];
        nodes.forEach(x => {
            if (x.Children && x.Children.length > 0) {
                const visibleChildren = x.Children.filter(c => c.Visible !== false);
                if (visibleChildren.length > 0) {
                    if (!x.SumNode) {
                        x.SumNode = new AxisNode({
                            Position: axis.ElementCount++,
                            Axis: axis.Type,
                            Depth: x.Depth,
                            Caption: sumRowText,
                            Key: 'TotalRow_' + x.Position,
                            Measure: false,
                            Parent: x
                        });
                        retVal.push({
                            SumNode: x.SumNode,
                            NodesToSum: visibleChildren
                        });
                    }
                }
                retVal.push(...SumRowTaskExecuter.GetSumInfos(x.Children, axis, sumRowText));
            }
        });
        return retVal;
    }

    private static GetLastChild(nodes: AxisNode[]): AxisNode {
        if (nodes && nodes.length > 0) {
            for (let i = nodes.length - 1; i >= 0; i--) {
                const node = nodes[i];
                if (node.IsLastChild) {
                    return node;
                }
            }
            return nodes[nodes.length - 1];
        }
        return null;
    }

    private static CheckForLevelTask(levelTasks, measureID, independentID, sumTuples: AxisNode[]): boolean {
        if (levelTasks) {
            return levelTasks.some(rt => {
                if (rt.MeasureUniqueID === measureID) {
                    if (rt.LevelUniqueID === independentID) {
                        return true;
                    }
                    return sumTuples && sumTuples.some(st => st.UniqueID === rt.LevelUniqueID);
                }
                return false;
            });
        }
        return false;
    }

    private static HasReferencing(mi: MeasureInfo, ln: LevelNode, variables): boolean {
        if (ln && mi && mi.Details) {
            if (mi.Details.ReferencingSelects) {
                if (mi.Details.ReferencingSelects.some(x => {
                    if (x.Formula && variables) {
                        // Muss hier ne Formel berechnet werden???
                        let varKey = x.Formula;
                        if (varKey.startsWith('@')) {
                            varKey = varKey.substring(1);
                        }
                        const variable = variables[varKey];
                        if (Array.isArray(variable)) {
                            return variable.some(v => v.LevelId === ln.Level);
                        }
                    }
                    if (x.Defaults) {
                        return x.Defaults.some(d => d.LevelId === ln.Level);
                    }
                    return false;
                })) {
                    return true;
                }
            }
            if (mi.Details.FunctionParentReferencing && mi.Details.FunctionParentReferencing.PRHierarchy === ln.HierarchieID) {
                return true;
            }
        }
        return false;
    }

    private static FindUnaryOP(unaryOPFaktor: number, child: AxisNode): number {
        if (typeof child.UnaryOp === 'number') {
            if (child.UnaryOp === 1) { // Minus
                return -1;
            } else if (child.UnaryOp === 2) { // Tilde
                return 0;
            }
        }
        return unaryOPFaktor;
    }

    private static GetCellValue(matrix: MultiResult, mNode: AxisNode, xNode: AxisNode, yNode: AxisNode,
        cellValue: ValueAndType, unaryOPFaktor: number) {
        const cell = MultiResultHelper.GetCellOrNull(matrix, xNode, yNode, mNode);
        if (cell) {
            // TODO: ICheckSumRow (Grid-Line-Filter)
            switch (cell.CellType) {
                case CellType.Double:
                    cellValue.Type = ValueType.Double;
                    if (typeof cellValue.Value === 'number') {
                        cellValue.Value = add(cellValue.Value, multiply(cell.InternalValue, unaryOPFaktor));
                    } else {
                        cellValue.Value = multiply(cell.InternalValue, unaryOPFaktor);
                    }
                    break;
                case CellType.Long:
                    cellValue.Type = ValueType.Long;
                    if (typeof cellValue.Value === 'number') {
                        cellValue.Value = add(cellValue.Value, multiply(cell.InternalValue, unaryOPFaktor));
                    } else {
                        cellValue.Value = multiply(cell.InternalValue, unaryOPFaktor);
                    }
                    break;
            }
        }
    }

    private static SumUpChildren(matrix: MultiResult, mNode: AxisNode, xNode: AxisNode, children: AxisNode[],
        cellValue: ValueAndType, unaryOPFaktor: number) {
        children.forEach(child => {
            const unary = SumRowTaskExecuter.FindUnaryOP(unaryOPFaktor, child);
            if (child.Children && child.Children.length > 0) {
                SumRowTaskExecuter.SumUpChildren(matrix, mNode, xNode, child.Children, cellValue, unary);
            } else {
                SumRowTaskExecuter.GetCellValue(matrix, mNode, xNode, child, cellValue, unary);
            }
        });
    }

    private static SumUpChildrenX(matrix: MultiResult, mNode: AxisNode, yNode: AxisNode, children: AxisNode[],
        cellValue: ValueAndType, unaryOPFaktor: number) {
        children.forEach(child => {
            const unary = SumRowTaskExecuter.FindUnaryOP(unaryOPFaktor, child);
            if (child.Children && child.Children.length > 0) {
                SumRowTaskExecuter.SumUpChildrenX(matrix, mNode, yNode, child.Children, cellValue, unary);
            } else {
                SumRowTaskExecuter.GetCellValue(matrix, mNode, child, yNode, cellValue, unary);
            }
        });
    }

    private static CalcSumRows(sumInfos, sortedMeasures, xNode, result, rankTasks,
        cumTasks, datadescription, variables) {
        sumInfos.forEach(sI => {
            // Wenn Wert mit Levelsumme auf Y, Wert aus letzter Zelle nehmen
            sortedMeasures.ParentReferencedY.forEach(prM => {
                const lastSumNode = sI.NodesToSum[sI.NodesToSum.length - 1];
                const lastCell = MultiResultHelper.GetCellOrNull(result, xNode, lastSumNode, prM.MeasureNode);
                if (lastCell) {
                    const cell = MultiResultHelper.GetCellOrNew(result, xNode, sI.SumNode, prM.MeasureNode);
                    cell.InternalValue = lastCell.InternalValue;
                    cell.CellType = lastCell.CellType;
                }
            });
            // Wenn Wert DistinctCount
            sortedMeasures.DistinctCount.forEach(dc => {
                // und keinen Rang-Task hat, Wert der ParentZelle nehmen, sonst Summe leer lassen
                const rankTask = SumRowTaskExecuter.CheckForLevelTask(rankTasks, dc.MeasureNode.UniqueID, xNode.UniqueID, sI.NodesToSum);
                if (!rankTask) {
                    const pNode = sI.NodesToSum[sI.NodesToSum.length - 1].Parent;
                    if (pNode) {
                        const parentCell = MultiResultHelper.GetCellOrNull(result, xNode, pNode, dc.MeasureNode);
                        if (parentCell) {
                            const cell = MultiResultHelper.GetCellOrNew(result, xNode, sI.SumNode, dc.MeasureNode);
                            cell.InternalValue = parentCell.InternalValue;
                            cell.CellType = parentCell.CellType;
                        }
                    }
                }
            });
            // Für alle anderen Measures
            const allOthers = [...sortedMeasures.ParentReferencedX, ...sortedMeasures.Measures];
            allOthers.forEach(m => {
                // Prüfen, ob Wert kumuliert auf Y
                const cumTask = SumRowTaskExecuter.CheckForLevelTask(cumTasks, m.MeasureNode.UniqueID, xNode.UniqueID, sI.NodesToSum);
                if (cumTask) { // -> Wert des LastChild der AxisNode nehmen
                    if (sI.IsTopDown !== true) {
                        const lastNode = SumRowTaskExecuter.GetLastChild(sI.NodesToSum);
                        const lastCell = MultiResultHelper.GetCellOrNull(result, xNode, lastNode, m.MeasureNode);
                        if (lastCell) {
                            const cell = MultiResultHelper.GetCellOrNew(result, xNode, sI.SumNode, m.MeasureNode);
                            cell.InternalValue = lastCell.InternalValue;
                            cell.CellType = lastCell.CellType;
                        }
                    }
                } else {
                    const lastNode = sI.NodesToSum[sI.NodesToSum.length - 1];
                    const ln = DataDescriptionHelper.FindNodeById(datadescription.YLevelNodes, lastNode.UniqueID);
                    // prüfen, ob Referenzierung vorhanden
                    const hasReferencing = SumRowTaskExecuter.HasReferencing(m.MeasureInfo, ln, variables);
                    if (hasReferencing) {
                        // Wenn ja, Wert aus letzter Zelle nehmen
                        const lastCell = MultiResultHelper.GetCellOrNull(result, xNode, lastNode, m.MeasureNode);
                        if (lastCell) {
                            const cell = MultiResultHelper.GetCellOrNew(result, xNode, sI.SumNode, m.MeasureNode);
                            cell.InternalValue = lastCell.InternalValue;
                            cell.CellType = lastCell.CellType;
                        }
                    } else {
                        // Sonst, wenn kein Rang vorhanden, Summe über die SumNodes bilden
                        const hasRank =
                            SumRowTaskExecuter.CheckForLevelTask(rankTasks, m.MeasureNode.UniqueID, xNode.UniqueID, sI.NodesToSum);
                        if (!hasRank) {
                            if (m.MeasureInfo.SpecialElementType === SpecialElementType.CalculatedElement) {
                                sortedMeasures.Calculate.push(m.MeasureNode);
                            } else {
                                const sumCell = MultiResultHelper.GetCellOrNew(result, xNode, sI.SumNode, m.MeasureNode);
                                const aggType = m.MeasureInfo.Aggregation;
                                if (aggType === Aggregation.NotSet) {
                                    sI.NodesToSum.forEach(child => {
                                        const cell = MultiResultHelper.GetCellOrNull(result, xNode, child, m.MeasureNode);
                                        if (cell) {
                                            const vat = new ValueAndType();
                                            SumRowTaskExecuter.SumUpChildren(result, m.MeasureNode, xNode, [child], vat, 1);
                                            if (vat.Type === ValueType.Double) {
                                                if (sumCell.InternalValue == null) {
                                                    sumCell.InternalValue = vat.Value;
                                                    sumCell.CellType = CellType.Double;
                                                } else {
                                                    sumCell.InternalValue = add(sumCell.InternalValue, vat.Value);
                                                }
                                            } else if (vat.Type === ValueType.Long) {
                                                if (sumCell.InternalValue == null) {
                                                    sumCell.InternalValue = vat.Value;
                                                    sumCell.CellType = CellType.Long;
                                                } else {
                                                    sumCell.InternalValue = add(sumCell.InternalValue, vat.Value);
                                                }
                                            }
                                            if (cell.InternalValue != null && cell.Unit != null) {
                                                if (sumCell.Unit == null) {
                                                    sumCell.Unit = cell.Unit;
                                                } else if (sumCell.Unit !== cell.Unit) {
                                                    sumCell.Unit = '*';
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    if (aggType === Aggregation.None) {
                                        sumCell.InternalValue = null;
                                    } else if (aggType !== Aggregation.DistinctCount) {
                                        const sumCellValues = [];
                                        let usedUnit = null;
                                        sI.NodesToSum.forEach(child => {
                                            const cell = MultiResultHelper.GetCellOrNull(result, xNode, child, m.MeasureNode);
                                            if (cell) {
                                                if (cell.Unit) {
                                                    if (usedUnit == null) {
                                                        usedUnit = cell.Unit;
                                                    } else if (usedUnit !== cell.Unit) {
                                                        usedUnit = '*';
                                                    }
                                                }
                                                const unary = SumRowTaskExecuter.FindUnaryOP(1, child);
                                                if ((cell.CellType === CellType.Double || cell.CellType === CellType.Long) &&
                                                    typeof cell.InternalValue === 'number') {
                                                    sumCellValues.push(multiply(unary, cell.InternalValue));
                                                }
                                            }
                                        });
                                        if (sumCellValues.length > 0) {
                                            sumCell.Unit = usedUnit;
                                            switch (aggType) {
                                                case Aggregation.Avg:
                                                    const avgVal = SumRowTaskExecuter.AddValues(sumCellValues);
                                                    sumCell.InternalValue = divide(avgVal, sumCellValues.length);
                                                    sumCell.CellType = CellType.Double;
                                                    break;
                                                case Aggregation.Count:
                                                    sumCell.InternalValue = sumCellValues.length;
                                                    sumCell.CellType = CellType.Double;
                                                    break;
                                                case Aggregation.Max:
                                                    let maxVal = -Number.MAX_VALUE;
                                                    sumCellValues.forEach(x => {
                                                        if (x >= maxVal) {
                                                            maxVal = x;
                                                        }
                                                    });
                                                    sumCell.InternalValue = maxVal;
                                                    sumCell.CellType = CellType.Double;
                                                    break;
                                                case Aggregation.Min:
                                                    let minVal = Number.MAX_VALUE;
                                                    sumCellValues.forEach(x => {
                                                        if (x <= minVal) {
                                                            minVal = x;
                                                        }
                                                    });
                                                    sumCell.InternalValue = minVal;
                                                    sumCell.CellType = CellType.Double;
                                                    break;
                                                case Aggregation.Sum:
                                                    sumCell.InternalValue = SumRowTaskExecuter.AddValues(sumCellValues);
                                                    sumCell.CellType = CellType.Double;
                                                    break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
        });
    }

    private static CalcSumColumn(sumColumnInfo, sortedMeasures, yNode, result, rankTasks,
        cumTasks, datadescription, variables) {
        // Wenn Wert mit Levelsumme auf X, Wert aus letzter Zelle nehmen
        sortedMeasures.ParentReferencedX.forEach(prM => {
            const lastSumNode = sumColumnInfo.NodesToSum[sumColumnInfo.NodesToSum.length - 1];
            const lastCell = MultiResultHelper.GetCellOrNull(result, lastSumNode, yNode, prM.MeasureNode);
            if (lastCell) {
                const cell = MultiResultHelper.GetCellOrNew(result, sumColumnInfo.SumNode, yNode, prM.MeasureNode);
                cell.InternalValue = lastCell.InternalValue;
                cell.CellType = lastCell.CellType;
            }
        });
        // Wenn Wert DistinctCount
        sortedMeasures.DistinctCount.forEach(dc => {
            // und keinen Rang-Task hat, Wert der ParentZelle nehmen, sonst Summe leer lassen
            const hasRank =
                SumRowTaskExecuter.CheckForLevelTask(rankTasks, dc.MeasureNode.UniqueID, yNode.UniqueID, sumColumnInfo.NodesToSum);
            if (!hasRank) {
                const pNode = sumColumnInfo.NodesToSum[sumColumnInfo.NodesToSum.length - 1].Parent;
                if (pNode) {
                    const parentCell = MultiResultHelper.GetCellOrNull(result, pNode, yNode, dc.MeasureNode);
                    if (parentCell) {
                        const cell = MultiResultHelper.GetCellOrNew(result, sumColumnInfo.SumNode, yNode, dc.MeasureNode);
                        cell.InternalValue = parentCell.InternalValue;
                        cell.CellType = parentCell.CellType;
                    }
                }
            }
        });
        // Für alle anderen Measures
        const allOthers = [...sortedMeasures.ParentReferencedY, ...sortedMeasures.Measures];
        allOthers.forEach(m => {
            // Prüfen, ob Wert kumuliert auf X
            const cumTask =
                SumRowTaskExecuter.CheckForLevelTask(cumTasks, m.MeasureNode.UniqueID, yNode.UniqueID, sumColumnInfo.NodesToSum);
            if (cumTask) { // -> Wert des LastChild der AxisNode nehmen
                const lastNode = SumRowTaskExecuter.GetLastChild(sumColumnInfo.NodesToSum);
                const lastCell = MultiResultHelper.GetCellOrNull(result, lastNode, yNode, m.MeasureNode);
                if (lastCell) {
                    const cell = MultiResultHelper.GetCellOrNew(result, sumColumnInfo.SumNode, yNode, m.MeasureNode);
                    cell.InternalValue = lastCell.InternalValue;
                    cell.CellType = lastCell.CellType;
                }
            } else {
                const lastNode = sumColumnInfo.NodesToSum[sumColumnInfo.NodesToSum.length - 1];
                const ln = DataDescriptionHelper.FindNodeById(datadescription.XLevelNodes, lastNode.UniqueID);
                // prüfen, ob Referenzierung vorhanden
                const hasReferencing = SumRowTaskExecuter.HasReferencing(m.MeasureInfo, ln, variables);
                if (hasReferencing) {
                    // Wenn ja, Wert aus letzter Zelle nehmen
                    const lastCell = MultiResultHelper.GetCellOrNull(result, lastNode, yNode, m.MeasureNode);
                    if (lastCell) {
                        const cell = MultiResultHelper.GetCellOrNew(result, sumColumnInfo.SumNode, yNode, m.MeasureNode);
                        cell.InternalValue = lastCell.InternalValue;
                        cell.CellType = lastCell.CellType;
                    }
                } else {
                    // Sonst, wenn kein Rang vorhanden, Summe über die SumNodes bilden
                    const hasRank = SumRowTaskExecuter.CheckForLevelTask(rankTasks, m.MeasureNode.UniqueID,
                        yNode.UniqueID, sumColumnInfo.NodesToSum);
                    if (!hasRank) {
                        if (m.MeasureInfo.SpecialElementType === SpecialElementType.CalculatedElement) {
                            sortedMeasures.Calculate.push(m.MeasureNode);
                        } else {
                            const sumCell = MultiResultHelper.GetCellOrNew(result, sumColumnInfo.SumNode, yNode, m.MeasureNode);
                            const aggType = m.MeasureInfo.Aggregation;
                            if (aggType === Aggregation.NotSet) {
                                sumColumnInfo.NodesToSum.forEach(child => {
                                    const cell = MultiResultHelper.GetCellOrNull(result, child, yNode, m.MeasureNode);
                                    if (cell) {
                                        const vat = new ValueAndType();
                                        SumRowTaskExecuter.SumUpChildrenX(result, m.MeasureNode, yNode, [child], vat, 1);
                                        if (vat.Type === ValueType.Double) {
                                            if (sumCell.InternalValue == null) {
                                                sumCell.InternalValue = vat.Value;
                                                sumCell.CellType = CellType.Double;
                                            } else {
                                                sumCell.InternalValue = add(sumCell.InternalValue, vat.Value);
                                            }
                                        } else if (vat.Type === ValueType.Long) {
                                            if (sumCell.InternalValue == null) {
                                                sumCell.InternalValue = vat.Value;
                                                sumCell.CellType = CellType.Long;
                                            } else {
                                                sumCell.InternalValue = add(sumCell.InternalValue, vat.Value);
                                            }
                                        }
                                        if (cell.InternalValue != null && cell.Unit != null) {
                                            if (sumCell.Unit == null) {
                                                sumCell.Unit = cell.Unit;
                                            } else if (sumCell.Unit !== cell.Unit) {
                                                sumCell.Unit = '*';
                                            }
                                        }
                                    }
                                });
                            } else {
                                if (aggType === Aggregation.None) {
                                    sumCell.InternalValue = null;
                                } else if (aggType !== Aggregation.DistinctCount) {
                                    const sumCellValues = [];
                                    let usedUnit = null;
                                    sumColumnInfo.NodesToSum.forEach(child => {
                                        const cell = MultiResultHelper.GetCellOrNull(result, child, yNode, m.MeasureNode);
                                        if (cell) {
                                            if (cell.Unit) {
                                                if (usedUnit == null) {
                                                    usedUnit = cell.Unit;
                                                } else if (usedUnit !== cell.Unit) {
                                                    usedUnit = '*';
                                                }
                                            }
                                            const unary = SumRowTaskExecuter.FindUnaryOP(1, child);
                                            if ((cell.CellType === CellType.Double || cell.CellType === CellType.Long) &&
                                                typeof cell.InternalValue === 'number') {
                                                sumCellValues.push(multiply(unary, cell.InternalValue));
                                            }
                                        }
                                    });
                                    if (sumCellValues.length > 0) {
                                        sumCell.Unit = usedUnit;
                                        switch (aggType) {
                                            case Aggregation.Avg:
                                                const avgVal = SumRowTaskExecuter.AddValues(sumCellValues);
                                                sumCell.InternalValue = divide(avgVal, sumCellValues.length);
                                                sumCell.CellType = CellType.Double;
                                                break;
                                            case Aggregation.Count:
                                                sumCell.InternalValue = sumCellValues.length;
                                                sumCell.CellType = CellType.Double;
                                                break;
                                            case Aggregation.Max:
                                                let maxVal = -Number.MAX_VALUE;
                                                sumCellValues.forEach(x => {
                                                    if (x >= maxVal) {
                                                        maxVal = x;
                                                    }
                                                });
                                                sumCell.InternalValue = maxVal;
                                                sumCell.CellType = CellType.Double;
                                                break;
                                            case Aggregation.Min:
                                                let minVal = Number.MAX_VALUE;
                                                sumCellValues.forEach(x => {
                                                    if (x <= minVal) {
                                                        minVal = x;
                                                    }
                                                });
                                                sumCell.InternalValue = minVal;
                                                sumCell.CellType = CellType.Double;
                                                break;
                                            case Aggregation.Sum:
                                                sumCell.InternalValue = SumRowTaskExecuter.AddValues(sumCellValues);
                                                sumCell.CellType = CellType.Double;
                                                break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    private static AddValues(values: any[]) {
        const scope = {
            retVal: 0,
            actVal: 0
        };
        if (values) {
            values.forEach(x => {
                scope.actVal = x;
                math.evaluate('retVal = retVal + actVal', scope);
            });
        }
        return scope.retVal;
    }

    static async CalcSumValues(sumInfos, totalSumInfo, sumColInfos, totalColSum,
        datadescription, result, variables, calcExecuter) {
        if (totalColSum || totalSumInfo || sumInfos.length > 0 || sumColInfos.length > 0) {
            // TODO: Sparks???
            // Tasks auslesen, die relevant für Aufteilung der Measures sind
            let yLevelNodes;
            const plTasks = [];
            const cumTasksX = [];
            const cumTasksY = [];
            const rankTasks = [];
            if (datadescription.Tasks) {
                datadescription.Tasks.forEach(t => {
                    if (t.IsActive) {
                        if (t.TaskType === PreviousLevelExecuter.TaskID) {
                            plTasks.push(t);
                        } else if (t.TaskType === CumulateTaskExecuter.TaskID) {
                            if (!yLevelNodes) {
                                yLevelNodes = DataDescriptionHelper.GetAllNodes(datadescription.YLevelNodes);
                            }
                            if (yLevelNodes.some(l => l.UniqueID === t.LevelUniqueID)) {
                                cumTasksY.push(t);
                            } else {
                                cumTasksX.push(t);
                            }
                        } else if (t.TaskType === 'rank') { // TODO: RankTask
                            if (t.LevelUniqueID) {
                                rankTasks.push(t);
                            }
                        }
                    }
                });
            }
            const calcMeasures = [];
            const noLevelIndex = {
                Index: 0
            };
            if (datadescription.ShowMeasureOnAxis === AxisType.X_Axis) {
                if (totalSumInfo) {
                    sumInfos.splice(0, 0, totalSumInfo); // TotalSumInfo zur Liste hinzufügen
                }
                for (let i = 0; i < datadescription.XLevelNodes.Areas.length; i++) {
                    const area = datadescription.XLevelNodes.Areas[i];
                    // Werte der Area Sortieren und auf entsprechende Listen verteilen
                    const sortedMeasures = await SumRowTaskExecuter.GetSortedMeasures(area, plTasks,
                        result.Measures.Nodes, datadescription.DataModelID);
                    if (sortedMeasures.HasNodes) {
                        // über alle X-Nodes des Bereichs iterieren
                        const nodes = MultiResultHelper.GetNodesFromArea(area, result.XAxis.Nodes, noLevelIndex);
                        if (nodes.Nodes.length > 0) {
                            const leafNodes = [];
                            MultiResultHelper.ExecuteOnAllNodesInternal(nodes.Nodes, (node) => {
                                // und Summen berechnen
                                SumRowTaskExecuter.CalcSumRows(sumInfos, sortedMeasures, node, result,
                                    rankTasks, cumTasksY, datadescription, variables);
                                if (node.Visible !== false && (!node.Children || node.Children.length === 0)) {
                                    leafNodes.push(node);
                                }
                            });
                            // Wenn Totalspalte existiert, alle Blattknoten von X aufsummieren für alle Summenzeilen und alle Y-Nodes
                            if (totalColSum && leafNodes.length > 0) {
                                const sCInfo = {
                                    SumNode: totalColSum,
                                    NodesToSum: leafNodes
                                };
                                if (result.YAxis.SumNode) {
                                    SumRowTaskExecuter.CalcSumColumn(sCInfo, sortedMeasures, result.YAxis.SumNode, result,
                                        rankTasks, cumTasksX, datadescription, variables);
                                }
                                MultiResultHelper.ExecuteOnAllNodes(result.YAxis, (node) => {
                                    if (node.SumNode) {
                                        SumRowTaskExecuter.CalcSumColumn(sCInfo, sortedMeasures, node.SumNode, result, rankTasks,
                                            cumTasksX, datadescription, variables);
                                    }
                                    SumRowTaskExecuter.CalcSumColumn(sCInfo, sortedMeasures, node, result, rankTasks,
                                        cumTasksX, datadescription, variables);
                                });
                            }
                            sumColInfos.forEach(sci => {
                                if (result.YAxis.SumNode) {
                                    SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, result.YAxis.SumNode, result,
                                        rankTasks, cumTasksX, datadescription, variables);
                                }
                                MultiResultHelper.ExecuteOnAllNodes(result.YAxis, (node) => {
                                    if (node.SumNode) {
                                        SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, node.SumNode, result, rankTasks,
                                            cumTasksX, datadescription, variables);
                                    }
                                    SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, node, result, rankTasks,
                                        cumTasksX, datadescription, variables);
                                });
                            });
                        }
                    } else {
                        if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                        } else {
                            noLevelIndex.Index++;
                        }
                    }
                    calcMeasures.push(...sortedMeasures.Calculate);
                }
                // Berechnete Werte nachberechnen
                if (calcMeasures.length > 0) {
                    if (calcExecuter) {
                        const sumInfoNodes = [];
                        sumInfos.forEach(x => {
                            sumInfoNodes.push(x.SumNode);
                        });
                        calcExecuter.forEach(cte => {
                            if (calcMeasures.some(cm => cm.UniqueID === cte.MeasureUniqueID)) {
                                if (sumInfoNodes.length > 0) {
                                    if (cte.MeasureRootNodes) {
                                        cte.MeasureRootNodes.forEach(n => {
                                            cte.IterateMeasureAxis(n, {}, sumInfoNodes);
                                        });
                                    }
                                    if (totalColSum) {
                                        cte.IterateMeasureAxis(totalColSum, {}, sumInfoNodes);
                                    }
                                    sumColInfos.forEach(sci => {
                                        cte.IterateMeasureAxis(sci, {}, sumInfoNodes);
                                    });
                                }
                                if (totalColSum) {
                                    cte.IterateMeasureAxis(totalColSum, {}, cte.OppositeAxis.Nodes);
                                }
                                sumColInfos.forEach(sci => {
                                    cte.IterateMeasureAxis(sci, {}, cte.OppositeAxis.Nodes);
                                });
                            }
                        });
                    }
                }
            } else {
                let sumColInfo;
                if (totalColSum) {
                    const leafNodes = [];
                    MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (node) => {
                        if (node.Visible !== false && (!node.Children || node.Children.length === 0)) {
                            leafNodes.push(node);
                        }
                    });
                    if (leafNodes.length > 0) {
                        sumColInfo = {
                            SumNode: totalColSum,
                            NodesToSum: leafNodes
                        };
                    }
                }
                for (let i = 0; i < datadescription.YLevelNodes.Areas.length; i++) {
                    const area = datadescription.YLevelNodes.Areas[i];
                    // Werte der Area Sortieren und auf entsprechende Listen verteilen
                    const sortedMeasures = await SumRowTaskExecuter.GetSortedMeasures(area, plTasks,
                        result.Measures.Nodes, datadescription.DataModelID);
                    calcMeasures.push(...sortedMeasures.Calculate);
                    if (sortedMeasures.HasNodes) {
                        const areaNodes = MultiResultHelper.GetNodesFromArea(area, result.YAxis.Nodes, noLevelIndex);
                        // SumInfos der Area aufsammeln
                        const areaSumInfos = [];
                        if (totalSumInfo) {
                            const visibleAreaNodes = areaNodes.Nodes.filter(x => x.Visible !== false);
                            if (visibleAreaNodes.length > 0) {
                                areaSumInfos.push({
                                    SumNode: totalSumInfo.SumNode,
                                    NodesToSum: visibleAreaNodes
                                });
                            }
                        }
                        if (area.Tuples && area.Tuples.length > 0) {
                            sumInfos.forEach(si => {
                                const levelID = si.SumNode.Parent.UniqueID;
                                if (area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === levelID))) {
                                    areaSumInfos.push(si);
                                }
                            });
                        }
                        if (areaSumInfos.length > 0) {
                            // Über komplette X-Achse iterieren
                            MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (node) => {
                                // und Summen berechnen
                                SumRowTaskExecuter.CalcSumRows(areaSumInfos, sortedMeasures, node, result,
                                    rankTasks, cumTasksY, datadescription, variables);
                            });
                        }
                        // Wenn Totalspalte existiert, alle Blattknoten von X aufsummieren für alle Summenzeilen und alle Y-Nodes
                        if (sumColInfo) {
                            if (result.YAxis.SumNode) {
                                SumRowTaskExecuter.CalcSumColumn(sumColInfo, sortedMeasures, result.YAxis.SumNode, result,
                                    rankTasks, cumTasksX, datadescription, variables);
                            }
                            MultiResultHelper.ExecuteOnAllNodesInternal(areaNodes.Nodes, (node) => {
                                if (node.SumNode) {
                                    SumRowTaskExecuter.CalcSumColumn(sumColInfo, sortedMeasures, node.SumNode, result,
                                        rankTasks, cumTasksX, datadescription, variables);
                                }
                                SumRowTaskExecuter.CalcSumColumn(sumColInfo, sortedMeasures, node, result, rankTasks,
                                    cumTasksX, datadescription, variables);
                            });
                        }
                        sumColInfos.forEach(sci => {
                            if (result.YAxis.SumNode) {
                                SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, result.YAxis.SumNode, result,
                                    rankTasks, cumTasksX, datadescription, variables);
                            }
                            MultiResultHelper.ExecuteOnAllNodesInternal(areaNodes.Nodes, (node) => {
                                if (node.SumNode) {
                                    SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, node.SumNode, result,
                                        rankTasks, cumTasksX, datadescription, variables);
                                }
                                SumRowTaskExecuter.CalcSumColumn(sci, sortedMeasures, node, result, rankTasks,
                                    cumTasksX, datadescription, variables);
                            });
                        });
                    } else {
                        if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                        } else {
                            noLevelIndex.Index++;
                        }
                    }
                }
                // Berechnete Werte nachberechnen
                if (calcMeasures.length > 0) {
                    if (calcExecuter) {
                        const sumInfoNodes = [];
                        if (totalSumInfo) {
                            sumInfoNodes.push(totalSumInfo.SumNode);
                        }
                        sumInfos.forEach(x => {
                            sumInfoNodes.push(x.SumNode);
                        });
                        const opposite = [...result.XAxis.Nodes];
                        if (totalColSum) {
                            opposite.push(totalColSum);
                        }
                        calcExecuter.forEach(cte => {
                            if (calcMeasures.some(cm => cm.UniqueID === cte.MeasureUniqueID)) {
                                if (cte.MeasureRootNodes) {
                                    if (totalColSum) {
                                        cte.MeasureRootNodes.forEach(n => {
                                            cte.IterateMeasureAxis(n, {}, [totalColSum]);
                                        });
                                    }
                                    sumColInfos.forEach(sci => {
                                        cte.MeasureRootNodes.forEach(n => {
                                            cte.IterateMeasureAxis(n, {}, [sci]);
                                        });
                                    });
                                }
                                sumInfoNodes.forEach(n => {
                                    cte.IterateMeasureAxis(n, {}, opposite);
                                });
                            }
                        });
                    }
                }
            }
        }
    }

    static async Execute(result: MultiResult, dataDescription: DataDescription, sumRowInfo: SumRowInfo,
        variables, calcTaskExecuter: CalculateTaskExecuter[]) {
        if (sumRowInfo && result && dataDescription) {

            const sumInfos = [];
            let totalSumInfo;
            // Summenzeilen aufsammeln
            if (sumRowInfo.ShowTotal) {
                if (result.YAxis && result.YAxis.Nodes && !result.YAxis.SumNode) {
                    const visibleNodes = result.YAxis.Nodes.filter(x => x.Visible !== false);
                    if (visibleNodes.length > 0) {
                        result.YAxis.SumNode = new AxisNode({
                            Position: result.YAxis.ElementCount++,
                            Axis: result.YAxis.Type,
                            Depth: 0,
                            Caption: sumRowInfo.SumRowText,
                            Key: 'TotalRow',
                            Measure: false
                        });
                        totalSumInfo = {
                            SumNode: result.YAxis.SumNode,
                            NodesToSum: visibleNodes
                        };
                    }
                }
            }
            if (sumRowInfo.ShowRowsSummary) {
                if (result.YAxis && result.YAxis.Nodes) {
                    sumInfos.push(...SumRowTaskExecuter.GetSumInfos(result.YAxis.Nodes, result.YAxis, sumRowInfo.SumRowText));
                }
            }
            let totalColSum;
            if (sumRowInfo.ShowTotalOnX) {
                if (dataDescription.XLevelNodes.Areas.some(a => a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0))) {
                    if (!result.XAxis.SumNode) {
                        result.XAxis.SumNode = new AxisNode({
                            Position: result.XAxis.ElementCount++,
                            Axis: result.XAxis.Type,
                            Depth: 0,
                            Caption: sumRowInfo.SumRowText,
                            Key: 'TotalColumn',
                            Measure: false
                        });
                    }
                    totalColSum = result.XAxis.SumNode;
                }
            }
            await SumRowTaskExecuter.CalcSumValues(sumInfos, totalSumInfo, [], totalColSum,
                dataDescription, result, variables, calcTaskExecuter);
        }
    }
}

export class SumRowTaskFormatter {
    private static DoFormat(yNodes: AxisNode[], result: MultiResult, styleMerger: StyleMerger, cellStyle: CellStyle) {
        if (yNodes) {
            yNodes.forEach(node => {
                if (node.SumNode) {
                    node.SumNode.StyleID = styleMerger.MergeStyle(node.SumNode.StyleID, cellStyle);
                    MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (xNode) => {
                        result.Measures.Nodes.forEach(mNode => {
                            const cell = MultiResultHelper.GetCellOrNew(result, xNode, node.SumNode, mNode);
                            cell.StyleID = styleMerger.MergeStyle(cell.StyleID, cellStyle);
                        });
                    }, true);
                }
                SumRowTaskFormatter.DoFormat(node.Children, result, styleMerger, cellStyle);
            });
        }
    }

    private static GetSumStyle(): CellStyle {
        const sumStyle = new CellStyle();
        sumStyle.BackgroundColor = new Gradient();
        sumStyle.BackgroundColor.Type = GradientType.Solid;
        const color = new GradientStopColor();
        color.Color = new Color();
        color.Color.R = 211;
        color.Color.G = 211;
        color.Color.B = 211;
        sumStyle.BackgroundColor.Colors.push(color);
        sumStyle.Font = new Font();
        sumStyle.Font.Bold = true;
        sumStyle.Font.FontColor = null;
        return sumStyle;
    }

    static FormatSumRows(result: MultiResult, styleMerger: StyleMerger) {
        if (result && result.YAxis) {
            const sumStyle = SumRowTaskFormatter.GetSumStyle();
            if (result.YAxis.SumNode) {
                result.YAxis.SumNode.StyleID = styleMerger.MergeStyle(result.YAxis.SumNode.StyleID, sumStyle);
                MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (xNode) => {
                    result.Measures.Nodes.forEach(mNode => {
                        const cell = MultiResultHelper.GetCellOrNew(result, xNode, result.YAxis.SumNode, mNode);
                        cell.StyleID = styleMerger.MergeStyle(cell.StyleID, sumStyle);
                    });
                }, true);
            }
            SumRowTaskFormatter.DoFormat(result.YAxis.Nodes, result, styleMerger, sumStyle);
        }
    }
}
