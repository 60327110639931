import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../models/enums/dialogbutton.enum';
import { WorkflowService } from '../../services/workflow.service';

@Component({
    selector: 'service-workflow-usage-dialog',
    templateUrl: 'service.workflow.usage.dialog.html',
    styleUrls: ['./service.workflow.usage.dialog.css']
})
export class ServiceWorkflowUsageDialog implements OnInit {
    WFID;
    Result;
    Description;
    SelectedObject;

    static ShowDialog(id) {
        BaseDialog.ShowDialog({
            ContentType: ServiceWorkflowUsageDialog,
            InitArgs: id,
            Title: '@@Workflow Usage',
            Buttons: DialogButton.Ok,
            FullScreen: true
        });
    }

    constructor(private cdRef: ChangeDetectorRef, private wfService: WorkflowService) {
    }

    ngOnInit(): void {
        if (this.WFID) {
            this.wfService.GetUsage(this.WFID).subscribe(x => {
                if (x && x.UsedWorkflows) {
                    this.Result = x;
                    const paths = [];
                    x.Paths.forEach(p => {
                        const pathList = [];
                        p.forEach(pSub => {
                            pathList.push(x.UsedWorkflows[pSub]);
                        });
                        paths.push(pathList);
                    });
                    this.SelectedObject = x.UsedWorkflows[this.WFID];
                    this.SelectedObject.Selected = true;
                    this.Description = {
                        MainObject: this.SelectedObject,
                        Paths: paths
                    };
                    this.cdRef.detectChanges();
                }
            });
        }
    }

    Initialize(args) {
        this.WFID = args;
    }

    selectObject(obj) {
        if (obj) {
            if (this.Result && this.Result.UsedWorkflows) {
                Object.keys(this.Result.UsedWorkflows).forEach(k => {
                    this.Result.UsedWorkflows[k].Selected = false;
                });
            }
            obj.Selected = true;
            this.SelectedObject = obj;
        }
    }
}
