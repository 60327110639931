import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { CustomRestEndpointType } from '../../../models/enums/customrestendpointtype.enum';
import { MethodType } from '../../../models/enums/methodtype.enum';
import { WorkflowParameterType } from '../../../models/enums/workflowparametertype.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { CustomRestService } from '../../../services/customrest.service';
import { DataService } from '../../../services/data.service';
import { DataModelService } from '../../../services/datamodel.service';
import { SettingsService } from '../../../services/settings.service';
import { WorkflowService } from '../../../services/workflow.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import {NotificationHelper} from "../../../helpers/notification.helper";
import {LayoutService} from "../../../services/layout.service";

@Component({
    selector: 'customrest-settings',
    templateUrl: '../../base.list.settings.html'
})
export class CustomRestSettings extends BaseListSettings {
    Items = [];

    static GetSettingsEntry() {
        return {
            Caption: '@@Custom Rest',
            ID: 'CustomRest',
            Icon: 'api',
            Index: 24,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 512
            },
            Content: CustomRestSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private service: CustomRestService, private translate: TranslateService) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.service.ReadAll().subscribe(result => {
            if (result) {
                this.Items = result;
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption ? entry.Caption.Value : entry.Name,
                        ID: entry.SID,
                        IsCapsule: entry.ObjectStatus ? entry.ObjectStatus.IsFromCapsule : false,
                        IsOverridden: entry.ObjectStatus ? entry.ObjectStatus.IsOverridden : false
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this.setSelectedItem(this.Items.find(x => x.SID === data));
    }

    getNewItem() {
        return {
            Name: this.translate.instant('@@Neuer Endpunkt'),
            Value: '',
            Type: null
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Endpunkt \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Endpunkt \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Endpunkt loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Endpunkt \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Endpunkt speichern');
        return retVal;
    }

    delete(data: any, handler) {
        if (data) {
            this.service.Remove(data).subscribe((res) => {
                handler(res);
            });
        }
    }

    saveInternal(item: any, handler: any) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        if (item.AuthentificationHeaders) {
            item.AuthentificationHeaders = item.AuthentificationHeaders.filter(x => x.Key && x.Value);
        }
        if (item.HeaderWorkflowParamMapping) {
            item.HeaderWorkflowParamMapping = item.HeaderWorkflowParamMapping.filter(x => x.Key && x.Value);
        }
        this.service.AddOrUpdate(item).subscribe((result) => {
            if (result) {
                let caption = TranslatedString.GetTranslation(result.Caption);
                if (!caption) {
                    caption = result.Name;
                }
                handler(result, result.SID, caption);
            }
        });
    }

    getContentType() { return CustomRestDetail; }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        this.Items.push(result);
    }

    updateListItem(item, result) {
        item.IsCapsule = result.ObjectStatus.IsFromCapsule;
        item.IsOverridden = result.ObjectStatus.IsOverridden;
    }
}

@Component({
    selector: 'CustomRest-detail',
    templateUrl: './customrest.settings.html',
    styleUrls: ['./customrest.settings.css']
})
export class CustomRestDetail extends BaseListDetail {
    DataModels = [];
    DataSources = [];
    StaticTables = [];
    Workflows = [];
    Columns = [];
    StaticDataSource = false;
    EndpointTypes = [];
    BaseUrl = '';
    Parameters = null;
    StringParameters = [];
    HeaderText = null;
    get Url() {
        if (this.SelectedItem) {
            let retVal = this.BaseUrl;
            if (this.SelectedItem.MethodType == MethodType.Get) {
                retVal += 'get';
            } else {
                retVal += 'post';
            }
            if (this.SelectedItem.AuthentificationHeaders && this.SelectedItem.AuthentificationHeaders.some(x => x.Key && x.Value)) {
                retVal += 'Authenticate';
            }
            return retVal + '/' + this.SelectedItem.Name;
        } else {
            return null;
        }
    }
    constructor(private dataModelService: DataModelService, private dataService: DataService, private wfService: WorkflowService) {
        super();
        this.EndpointTypes = EnumHelper.GetDropdownValues(CustomRestEndpointType);
        this.BaseUrl = SettingsService.API_BASE_URL.getValue() + 'customrest/';
    }
    ngOnInit() {
        this.wfService.GetAllServiceWorkflows().subscribe((data => {
            this.Workflows = data;
        }));
        this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((data) => {
            this.StaticTables = data;
            this.dataModelService.GetModels().subscribe(models => {
                this.DataModels = models;
            });
        });
        super.ngOnInit();
    }

    modelSelected() {
        this.Columns = [];
        this.DataSources = [];
        this.SelectedItem.DataSourceID = null;
        this.dataService.GetItem('dynamic', 'GetContainersByDataModel', this.SelectedItem.DataModelID).subscribe((dataSources) => {
            this.DataSources = dataSources;
            this.cdRef.detectChanges();
        });
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (item) {
            if (this.SelectedItem.DataSourceID && this.StaticTables) {
                if (this.StaticTables.some(x => x.Value === this.SelectedItem.DataSourceID)) {
                    this.StaticDataSource = true;
                } else if (this.SelectedItem.DataModelID) {
                    this.dataService.GetItem('dynamic', 'GetContainersByDataModel', this.SelectedItem.DataModelID).subscribe((ds) => {
                        this.DataSources = ds;
                    });
                }
            }
        }
        this.EndpointTypeChanged();
        this.updateHeaderText();
    }

    EndpointTypeChanged() {
        const parameters = [];
        const stringParameters = [];
        if (this.SelectedItem) {
            if (this.SelectedItem.UseWorkflow) {
                const selectedWorkflow = this.Workflows.find((value) => value.Id == this.SelectedItem.WorkflowID);
                if (selectedWorkflow && selectedWorkflow.Parameters && selectedWorkflow.Parameters.length > 0) {
                    selectedWorkflow.Parameters.forEach((param) => {
                        if (param.Type == WorkflowParameterType.String) {
                            stringParameters.push(param.Name);
                        }
                    });
                    if (this.SelectedItem.HeaderWorkflowParamMapping) {
                        this.SelectedItem.HeaderWorkflowParamMapping = this.SelectedItem.HeaderWorkflowParamMapping.filter(x => {
                            return stringParameters.some(y => y == x.Value);
                        });
                    }
                    if (!this.SelectedItem.SetBodyToParam && this.SelectedItem.MethodType != MethodType.Get) {
                        selectedWorkflow.Parameters.forEach((param) => {
                            parameters.push({ Key: param.Name, Value: WorkflowParameterType[param.Type] });
                        });
                    }
                } else {
                    this.SelectedItem.WorkflowParam = null;
                    this.SelectedItem.HeaderWorkflowParamMapping = null;
                }
            } else {
                switch (this.SelectedItem.Type) {
                    case CustomRestEndpointType.Save:
                    case CustomRestEndpointType.ForceSave:
                        parameters.push({ Key: 'body', Value: 'any' }); break;
                    case CustomRestEndpointType.Delete:
                    case CustomRestEndpointType.ForceDelete:
                        parameters.push({ Key: 'id', Value: 'Guid' });
                        parameters.push({ Key: 'version', Value: 'Guid' });
                        break;
                    case CustomRestEndpointType.DeleteByFilter:
                    case CustomRestEndpointType.SearchObjects:
                    case CustomRestEndpointType.CountObjects:
                    case CustomRestEndpointType.OpenCachedQuery:
                    case CustomRestEndpointType.ExecuteObjectQuery:
                    case CustomRestEndpointType.ExecuteCount:
                    case CustomRestEndpointType.ExecuteQuery:
                        parameters.push({ Key: 'filter', Value: 'any' });
                        break;
                    case CustomRestEndpointType.CloseCachedQuery:
                    case CustomRestEndpointType.ExecuteCachedCountQuery:
                        parameters.push({ Key: 'id', Value: 'Guid' });
                        break;
                    case CustomRestEndpointType.ExecuteCachedQuery:
                        parameters.push({ Key: 'id', Value: 'Guid' });
                        parameters.push({ Key: 'start', Value: 'Integer' });
                        parameters.push({ Key: 'end', Value: 'Integer' });
                        break;
                }
            }
        }
        if (parameters.length > 0) {
            this.Parameters = JSON.stringify(parameters, null, 2);
        } else {
            this.Parameters = null;
        }
        this.StringParameters = stringParameters;
    }
    DataSourceChanged() {
        this.SelectedItem.WorkflowID = null;
        this.OnItemChanged();
    }
    WorkflowChanged() {
        this.SelectedItem.DataSourceID = null;
        this.SelectedItem.DataModelID = null;
        this.EndpointTypeChanged();
        this.OnItemChanged();
    }
    UpdateEndPoints() {
        this.EndpointTypeChanged();
        this.OnItemChanged();
    }
    addHeader() {
        if (this.SelectedItem) {
            if (this.SelectedItem.AuthentificationHeaders) {
                this.SelectedItem.AuthentificationHeaders.push({
                    Key: '',
                    Value: ''
                });
            } else {
                this.SelectedItem.AuthentificationHeaders = [{
                    Key: '',
                    Value: ''
                }];
            }
            this.onHeaderChanged();
        }
    }
    removeHeader(i) {
        if (this.SelectedItem && this.SelectedItem.AuthentificationHeaders && i < this.SelectedItem.AuthentificationHeaders.length) {
            this.SelectedItem.AuthentificationHeaders.splice(i, 1);
            this.onHeaderChanged();
        }
    }
    updateHeaderText() {
        let headerText;
        if (this.SelectedItem) {
            const copy = [];
            if (this.SelectedItem.AuthentificationHeaders) {
                copy.push(...this.SelectedItem.AuthentificationHeaders);
            }
            if (this.SelectedItem.HeaderWorkflowParamMapping) {
                this.SelectedItem.HeaderWorkflowParamMapping.forEach(x => {
                    copy.push({ Key: x.Key });
                });
            }
            if (copy.length > 0) {
                headerText = JSON.stringify(copy, null, 2);
            }
        }
        this.HeaderText = headerText;        
    }
    onHeaderChanged() {
        this.updateHeaderText();
        this.OnItemChanged();
    }
    useWorkflowChanged() {
        if (this.SelectedItem) {
            if (!this.SelectedItem.UseWorkflow && this.SelectedItem.MethodType == MethodType.Get) {
                this.SelectedItem.MethodType = MethodType.Post;
            }
            this.UpdateEndPoints();
        }
    }
    addMapping() {
        if (this.SelectedItem) {
            if (this.SelectedItem.HeaderWorkflowParamMapping) {
                this.SelectedItem.HeaderWorkflowParamMapping.push({
                    Key: '',
                    Value: ''
                });
            } else {
                this.SelectedItem.HeaderWorkflowParamMapping = [{
                    Key: '',
                    Value: ''
                }];
            }
            this.onHeaderChanged();
        }
    }
    removeMapping(i) {
        if (this.SelectedItem && this.SelectedItem.HeaderWorkflowParamMapping && i < this.SelectedItem.HeaderWorkflowParamMapping.length) {
            this.SelectedItem.HeaderWorkflowParamMapping.splice(i, 1);
            this.onHeaderChanged();
        }
    }
}
