import { deserialize, serialize } from 'class-transformer';
import { DataDescriptionHelper } from '../../components/query/ddhelper.query';
import { QueryExecuter } from '../../components/query/executer.query';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { MultiResultHelper } from '../../helpers/multiresult.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { TaskRegistry } from '../../helpers/task.registry';
import { Column } from '../basic/column.model';
import { Row } from '../basic/row.model';
import { CellSelection, RowSelection } from '../basic/selection.model';
import { Axis, AxisNode, MultiNode, MultiResult } from '../datadescription/multi/multiresult.model';
import { WhereSelects } from '../datadescription/multi/querydef.model';
import { VisibilityType } from '../enums/oc.enum';
import { AxisType, CellType, CodeDesc } from '../enums/query.enum';
import { PageStatus } from '../page.status';
import {
    NodeInfo, ReportTableColumnSettings, ReportTableExpandedNodeInfo, ReportTableSettings
} from '../reportobjects/reporttablesettings.model';
import { Border } from '../style/border.model';
import { BorderSide } from '../style/borderside.model';
import { Gradient } from '../style/gradient.model';
import { CellStyle } from '../styling/cell.style';
import { NumberFormat } from '../styling/number.format.model';
import { StyleMerger } from '../styling/styleMerger';
import { ITaskExecuter } from '../tasks/atask.model';
import { SumRowInfo, SumRowTaskExecuter, SumRowTaskFormatter } from '../tasks/defaulttasks/sum.row.task';
import { TranslatedString } from '../translatedstring.model';
import { TableDataSource } from './chart.datasource';

export class MultiDimensionalTableDataSource extends TableDataSource {

    private static _StandardDescriptionNodeWidth = 200;

    private Matrix: MultiResult;
    private _RootNodes = [];
    private _ColDescription = {};
    FixedRowsValue: Row[];

    private static GetColumns(node: AxisNode, levelLengths: number[], queryValues: ColumnQueryValues): Column[] {
        const retVal = [];
        if (levelLengths.length > 0) {
            if (node.Position !== -1 && node.Visible !== false) {
                MultiResultHelper.MergeLevels([node], levelLengths[0], nodeList => {
                    const captions = [];
                    const positions = [];

                    nodeList.forEach(x => {
                        let caption = '';
                        const cd: CodeDesc = x.CodeDescription;
                        switch (cd) {
                            case CodeDesc.Description:
                                caption = x.Caption;
                                break;
                            case CodeDesc.Code:
                                caption = x.Key;
                                break;
                            case CodeDesc.CodeDescription:
                                caption = x.Key + '_' + x.Caption;
                                break;
                            case CodeDesc.None:
                                caption = '';
                                break;
                            default:
                                caption = x.Caption;
                                break;
                        }
                        captions.push(caption);
                        positions.push('' + x.Position);
                    });

                    const toPushColumn = new Column();
                    toPushColumn.Caption = captions.join(' - ');
                    toPushColumn.Name = positions.join('_');

                    const lastNode = nodeList[nodeList.length - 1];
                    toPushColumn['StyleID'] = lastNode.StyleID;
                    const fixedChildren = [];
                    if (lastNode.Children && lastNode.Children.length > 0) {
                        const nextLevels = levelLengths.slice(1);
                        if (queryValues.Pivot) {
                            const childList = [];
                            lastNode.Children.forEach(child => {
                                const childNodes = MultiDimensionalTableDataSource.GetColumns(child, nextLevels, queryValues);
                                childList.push(...childNodes);
                            });
                            if (childList.length > 0) {
                                toPushColumn.Expandable = true;
                                toPushColumn.Children = childList;
                            }
                            if (queryValues.Measures) {
                                queryValues.Measures.forEach(m => {
                                    const measureCol = new Column();
                                    measureCol.Caption = m.Caption;
                                    measureCol.Name = toPushColumn.Name + '_' + m.Position;
                                    measureCol['StyleID'] = m.StyleID;
                                    measureCol['MeasureUniqueID'] = m.UniqueID;
                                    fixedChildren.push(measureCol);
                                    queryValues.ColumnDescriptions[measureCol.Name] = { X: lastNode.Position, M: m.Position };
                                });
                            }
                        } else {
                            lastNode.Children.forEach(child => {
                                const childNodes = MultiDimensionalTableDataSource.GetColumns(child, nextLevels, queryValues);
                                fixedChildren.push(...childNodes);
                            });
                        }
                    } else if (queryValues.Measures) {
                        queryValues.Measures.forEach(m => {
                            const measureCol = new Column();
                            measureCol.Caption = m.Caption;
                            measureCol.Name = toPushColumn.Name + '_' + m.Position;
                            measureCol['StyleID'] = m.StyleID;
                            measureCol['MeasureUniqueID'] = m.UniqueID;
                            fixedChildren.push(measureCol);
                            queryValues.ColumnDescriptions[measureCol.Name] = { X: lastNode.Position, M: m.Position };
                        });
                    }
                    if (fixedChildren.length > 0) {
                        toPushColumn.FixedChildren = fixedChildren;
                    } else if (!toPushColumn.Children || toPushColumn.Children.length === 0) {
                        queryValues.ColumnDescriptions[toPushColumn.Name] = { X: lastNode.Position };
                    }
                    retVal.push(toPushColumn);
                });
            }
        }
        return retVal;
    }    

    private static GetExpandedRows(row: Row): Row[] {
        const retVal = [];
        if (row.Children) {
            row.Children.forEach(child => {
                retVal.push(child);
                if (child.expanded) {
                    retVal.push(...MultiDimensionalTableDataSource.GetExpandedRows(child));
                }
            });
        }
        return retVal;
    }

    private static GetExpandedRowCount(row: Row): number {
        let retVal = 0;
        if (row.Children) {
            row.Children.forEach(child => {
                retVal++;
                if (child.expanded) {
                    retVal += MultiDimensionalTableDataSource.GetExpandedRowCount(child);
                }
            });
        }
        return retVal;
    }

    private static AppendMatrix(node: AxisNode, newMatrix: MultiResult, context: any) {
        if (context && context.ReloadInfo && context.ReloadInfo.ParentMatrix) {
            const oldMatrix = context.ReloadInfo.ParentMatrix;
            const children = [];
            const newOldY = new Map<number, number>();
            newMatrix.YAxis.Nodes.forEach(yNode => {
                yNode.Parent = node;
                MultiDimensionalTableDataSource.appendTupleYRecursive(yNode, newOldY, node.Depth + 1, oldMatrix.YAxis);
                children.push(yNode);
            });
            node.Children = children;

            if (newMatrix.YAxis.SumNode) {
                const snPos = newMatrix.YAxis.SumNode.Position;
                newMatrix.YAxis.SumNode.Position = oldMatrix.YAxis.ElementCount++;
                newOldY.set(snPos, newMatrix.YAxis.SumNode.Position);
                newMatrix.YAxis.SumNode.Depth = node.Depth + 1;
                node.SumNode = newMatrix.YAxis.SumNode;
            }

            const newOldX = new Map<number, number>();
            MultiDimensionalTableDataSource.appendTupleXRecursive(newMatrix.XAxis.Nodes, oldMatrix.XAxis.Nodes, newOldX);
            if (newMatrix.XAxis.SumNode && oldMatrix.XAxis.SumNode) {
                newOldX.set(newMatrix.XAxis.SumNode.Position, oldMatrix.XAxis.SumNode.Position);
            }

            const newOldM = new Map<number, number>();
            newMatrix.Measures.Nodes.forEach(mNode => {
                const mOrig = oldMatrix.Measures.Nodes.find(on => on.UniqueID === mNode.UniqueID);
                if (mOrig) {
                    newOldM.set(mNode.Position, mOrig.Position);
                } else {
                    const text = '@@Es kamen mehr Werte zurueck als bei der original Abfrage!';
                    NotificationHelper.Error(text, '@@Fehler');
                    throw new Error(text);
                }
            });

            newOldX.forEach((xNew, xOld) => {
                const xArrayOld = newMatrix.Cells[xOld];
                if (xArrayOld) {
                    let xArrayNew = oldMatrix.Cells[xNew];
                    if (!xArrayNew) {
                        xArrayNew = [];
                        oldMatrix.Cells[xNew] = xArrayNew;
                    }
                    newOldY.forEach((yNew, yOld) => {
                        const yArrayOld = xArrayOld[yOld];
                        if (yArrayOld) {
                            let yArrayNew = xArrayNew[yNew];
                            if (!yArrayNew) {
                                yArrayNew = [];
                                xArrayNew[yNew] = yArrayNew;
                            }
                            newOldM.forEach((mNew, mOld) => {
                                const mNodeOld = yArrayOld[mOld];
                                if (mNodeOld) {
                                    mNodeOld.X = xNew;
                                    mNodeOld.Y = yNew;
                                    mNodeOld.M = mNew;
                                    yArrayNew[mNew] = mNodeOld;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    private static appendTupleXRecursive(newNodes: AxisNode[], oldNodes: AxisNode[], newOldX: Map<number, number>) {
        newNodes.forEach(xTuple => {
            let xOrig;
            if (xTuple.DummyInfo) {
                xOrig = oldNodes.find(on => on.DummyInfo && on.DummyInfo.AreaID === xTuple.DummyInfo.AreaID);
            } else {
                xOrig = oldNodes.find(on => on.UniqueID === xTuple.UniqueID && on.MemberId === xTuple.MemberId);
            }
            if (xOrig) {
                newOldX.set(xTuple.Position, xOrig.Position);
                MultiDimensionalTableDataSource.appendTupleXRecursive(xTuple.Children, xOrig.Children, newOldX);
                if (xTuple.SumNode && xOrig.SumNode) {
                    newOldX.set(xTuple.SumNode.Position, xOrig.SumNode.Position);
                }
            } else {
                const text = '@@Es kamen mehr X-Elemente zurueck als bei der original Abfrage!';
                NotificationHelper.Error(text, '@@Fehler');
                throw new Error(text);
            }
        });
    }

    private static appendTupleYRecursive(node: AxisNode, newOldY: Map<number, number>, depth: number, axis: Axis) {
        const newPos = node.Position;
        node.Position = axis.ElementCount++;
        newOldY.set(newPos, node.Position);
        node.Depth = depth;
        if (node.Children) {
            node.Children.forEach(child => {
                MultiDimensionalTableDataSource.appendTupleYRecursive(child, newOldY, depth + 1, axis);
            });
        }
        if (node.SumNode) {
            const snPos = node.SumNode.Position;
            node.SumNode.Position = axis.ElementCount++;
            newOldY.set(snPos, node.SumNode.Position);
            node.SumNode.Depth = depth;
        }
    }

    private static async FormatMatrix(matrix: MultiResult, useClone: boolean, context) {
        if (matrix) {
            const styleMerger = StyleMerger.GetStyleMerger(context);

            SumRowTaskFormatter.FormatSumRows(matrix, styleMerger);

            let tableSettings;
            if (context.BaseReportObject && context.BaseReportObject.LayoutElement) {
                tableSettings = context.BaseReportObject.LayoutElement.TableSettings;
            }

            // Default Border
            let borderStyle;
            if (tableSettings && tableSettings.ShowLines === true) {
                borderStyle = new CellStyle();
                borderStyle.Border = new Border();
                borderStyle.Border.RightBorder = StyleMerger.GetBorder(1, 232, 232, 232);
                borderStyle.Border.BottomBorder = StyleMerger.GetBorder(1, 232, 232, 232);

                styleMerger['DefaultCellStyle'] = '' + styleMerger.MergeStyle(0, borderStyle);

                MultiResultHelper.ExecuteOnAllNodes(matrix.YAxis, (node) => {
                    node.StyleID = styleMerger.MergeStyle(node.StyleID, borderStyle);
                }, true);
            }

            let formatXNodes = false;
            if (context.DataDescriptionOriginal.ShowMeasureOnAxis === AxisType.X_Axis) {
                const headerLeaveStyle = new CellStyle();
                headerLeaveStyle.Border = new Border();
                headerLeaveStyle.Border.BottomBorder = StyleMerger.GetBorder(2, 127, 127, 127);
                if (tableSettings && tableSettings.HideMeasureHeader === true) {
                    headerLeaveStyle.Visible = false;
                    formatXNodes = true;
                }
                matrix.Measures.Nodes.forEach(node => {
                    node.StyleID = styleMerger.MergeStyle(node.StyleID, headerLeaveStyle);
                });
            } else {
                formatXNodes = true;
                if (borderStyle) {
                    matrix.Measures.Nodes.forEach(node => {
                        node.StyleID = styleMerger.MergeStyle(node.StyleID, borderStyle);
                    });
                }
            }
            if (formatXNodes) {
                const headerLeaveStyle = new CellStyle();
                headerLeaveStyle.Border = new Border();
                headerLeaveStyle.Border.BottomBorder = StyleMerger.GetBorder(2, 127, 127, 127);
                MultiResultHelper.ExecuteOnAllNodes(matrix.XAxis, (node) => {
                    if (!node.Children || node.Children.length === 0) {
                        node.StyleID = styleMerger.MergeStyle(node.StyleID, headerLeaveStyle);
                    }
                }, true);
            }

            // Default-Numeric-Formatierung
            const defStyle = new CellStyle();
            defStyle.HorizontalContentAlignment = 2;
            defStyle.NumberFormat = new NumberFormat();

            matrix.Cells.forEach((x, xPos) => {
                x.forEach((y, yPos) => {
                    y.forEach((m, mPos) => {
                        if (borderStyle) {
                            if (!m) {
                                m = new MultiNode({
                                    M: mPos,
                                    X: xPos,
                                    Y: yPos
                                });
                                y[mPos] = m;
                            }
                            m.StyleID = styleMerger.MergeStyle(m.StyleID, borderStyle);
                        }
                        if (m && (m.CellType === CellType.Double || m.CellType === CellType.Long)) {
                            m.StyleID = styleMerger.MergeStyle(m.StyleID, defStyle);
                        }
                    });
                });
            });
            const reportObject = context.BaseReportObject;
            const dd = useClone ? context.DataDescriptionClone : context.DataDescriptionOriginal;
            if (reportObject && reportObject.LayoutElement && reportObject.LayoutElement.FormatTasks) {
                for (let i = 0; i < reportObject.LayoutElement.FormatTasks.length; i++) {
                    const task = reportObject.LayoutElement.FormatTasks[i];
                    if (task && task.TaskType && PageStatus.GetTaskActiveStatus(task, reportObject.LayoutElement.ID) && task.QueryID === reportObject.LayoutElement.Query) {
                        const desc = TaskRegistry.get(task.TaskType);
                        if (desc && desc.Executer) {
                            const executer: ITaskExecuter = new desc.Executer();
                            await executer.Init(task, matrix, dd, context);
                            // TODO: IsValid
                            await executer.Execute();
                        }
                    }
                }
            }
        }
    }

    private static RemoveHiddenColumns(columns: Column[], hiddenColumns) {
        for (let i = 0; i < columns.length; i++) {
            const col = columns[i];
            if (hiddenColumns[col.Name] === true) {
                columns.splice(i, 1);
                i--;
            } else {
                let oldChildrenCount = 0;
                let newChildrenCount = 0;
                if (col.Children) {
                    oldChildrenCount += col.Children.length;
                    MultiDimensionalTableDataSource.RemoveHiddenColumns(col.Children, hiddenColumns);
                    newChildrenCount += col.Children.length;
                }
                if (col.FixedChildren) {
                    oldChildrenCount += col.FixedChildren.length;
                    MultiDimensionalTableDataSource.RemoveHiddenColumns(col.FixedChildren, hiddenColumns);
                    newChildrenCount += col.FixedChildren.length;
                }
                if (oldChildrenCount > 0 && newChildrenCount === 0) {
                    columns.splice(i, 1);
                    i--;
                }
            }
        }
    }

    private static SaveExpandedNodes(list, rows, currentPath) {
        if (rows) {
            rows.forEach(row => {
                if (row.Expandable && row.expanded) {
                    const info = new ReportTableExpandedNodeInfo();
                    info.Path.push(...currentPath);
                    const nodeInfo = new NodeInfo();
                    nodeInfo.UniqueID = row.UniqueID;
                    nodeInfo.MemberID = row.MemberID;
                    info.Path.push(nodeInfo);
                    list.push(info);
                    MultiDimensionalTableDataSource.SaveExpandedNodes(list, row.Children, [...info.Path]);
                }
            });
        }
    }

    private static HideEmptyColumns(columns: Column[], rows: Row[], colDescriptions) {
        const hiddenCols = {};
        let anyHidden = false;
        Object.keys(colDescriptions).forEach(cd => {
            let found = false;
            rows.some(row => {
                if (row.data) {
                    const val = row.data[cd];
                    if (val != null && val !== '') {
                        found = true;
                    }
                }
                return found;
            });
            if (!found) {
                hiddenCols[cd] = true;
                anyHidden = true;
            }
        });
        if (anyHidden) {
            MultiDimensionalTableDataSource.RemoveHiddenColumns(columns, hiddenCols);
        }
    }

    private static SplitFixedColumns(cols: Column[], column: { fixCount: number, index: number, copiedCols: string[] }): Column[] {
        const retVal = [];
        for (let i = 0; i < cols.length; i++) {
            const c = cols[i];
            if (c.Children && c.Children.length > 0) {
                const childLength = c.Children.length;
                const copied = MultiDimensionalTableDataSource.SplitFixedColumns(c.Children, column);
                if (copied.length < childLength) {
                    const json = serialize(c);
                    const copy = deserialize(Column, json);
                    column.copiedCols.push(copy.Name);
                    copy.Name += '_c';
                    copy.IsSticky = true;
                    copy.Children = copied;
                    retVal.push(copy);
                    break;
                } else {
                    c.IsSticky = true;
                    c.Children.push(...copied);
                    retVal.push(...cols.splice(i, 1));
                    i--;
                    if (column.index === column.fixCount) {
                        break;
                    }
                }
            } else {
                if (column.index < column.fixCount) {
                    c.IsSticky = true;
                    retVal.push(...cols.splice(i, 1));
                    i--;
                    column.index++;
                } else {
                    break;
                }
            }
        }
        return retVal;
    }

    private static GetExpandQueries(expandList: NodeInfo[][], axisNodes: AxisNode[]): ExpandQuery[] {
        const expandQueryMap = {};
        const expandQueryList = [];
        expandList.forEach(x => {
            if (x && x.length > 0) {
                const first = x[0];
                const key = first.UniqueID + '_' + first.MemberID;
                let expQ: ExpandQuery = expandQueryMap[key];
                if (expQ) {
                    if (x.length > 1) {
                        expQ.ExpandList.push(x.slice(1));
                    }
                } else {
                    const an = MultiResultHelper.FindAxisNodeByIDs(axisNodes, first.UniqueID, first.MemberID);
                    if (an && (!an.Children || an.Children.length === 0)) {
                        expQ = new ExpandQuery();
                        expQ.ExpandNode = an;
                        if (x.length > 1) {
                            expQ.ExpandList.push(x.slice(1));
                        }
                        expandQueryMap[key] = expQ;
                        expandQueryList.push(expQ);
                    }
                }
            }
        });
        return expandQueryList;
    }

    private static ExpandRows(rows: Row[]): Row[] {
        const result = [];
        rows.forEach(row => {
            result.push(row);
            if (row.Expandable && row.expanded && row.Children) {
                result.push(...MultiDimensionalTableDataSource.ExpandRows(row.Children));
            }
        });
        return result;
    }

    static SetWidthOnLeaves(columns: Column[], defaultWidth: number, columnSettings: ReportTableColumnSettings) {
        if (columns) {
            columns.forEach(col => {
                delete col['CalculatedWidth'];
                if (col.Children && col.Children.length > 0) {
                    MultiDimensionalTableDataSource.SetWidthOnLeaves(col.Children, defaultWidth, columnSettings);
                }
                if (col.FixedChildren && col.FixedChildren.length > 0) {
                    MultiDimensionalTableDataSource.SetWidthOnLeaves(col.FixedChildren, defaultWidth, columnSettings);
                } else {
                    let width = defaultWidth;
                    if (columnSettings) {
                        const id = col['MeasureUniqueID'];
                        if (typeof id === 'number') {
                            if (columnSettings.MeasuresWidths) {
                                const mWidth = columnSettings.MeasuresWidths[id];
                                if (typeof mWidth === 'number') {
                                    width = mWidth;
                                }
                            }
                        } else if (typeof columnSettings.NonMeasureWidth === 'number') {
                            width = columnSettings.NonMeasureWidth;
                        }
                    }
                    const widthObj: any = {
                        Value: width,
                        Type: 0 // Pixel
                    };
                    col.Width = widthObj;
                }
            });
        }
    }

    static GetFixedChildrenLeaves(column: Column): Column[] {
        const retVal = [];
        if (column.FixedChildren && column.FixedChildren.length > 0) {
            column.FixedChildren.forEach(child => {
                retVal.push(...MultiDimensionalTableDataSource.GetFixedChildrenLeaves(child));
            });
        } else {
            retVal.push(column);
        }
        return retVal;
    }

    constructor(protected reportObject) {
        super(reportObject);
    }

    async Refresh() {
        if (this.Loading.getValue()) {
            const text = '@@Das Objekt {0} wird gerade geladen. Erneute Aktualisierung wurde unterbunden.';
            const tft = new TranslateFormatText(text);
            tft.FormatParams.push(this.reportObject.LayoutElement.Name);
            NotificationHelper.Info(tft, new TranslateFormatText('@@Erneutes Laden'));
        } else {
            this.Loading.next(true);
            this.Initialized.next(true);
            try {
                this._RootNodes = [];

                if (this.reportObject.LayoutElement.TableSettings == null) {
                    this.reportObject.LayoutElement.TableSettings = new ReportTableSettings();
                }
                const tableSettings: ReportTableSettings = this.reportObject.LayoutElement.TableSettings;

                if (this.reportObject.DataDescription) {
                    this.reportObject.DataDescription.PowerGrid = tableSettings.ReloadOnExpand;
                }

                const executer = new QueryExecuter(this.reportObject.DataDescription, this.reportObject.Data.ID);

                const context = { BaseReportObject: this.reportObject };

                const matrix = await executer.execute(context, true);

                const allRows = [];
                const columns = [];
                let cellStyles = {};
                if (matrix) {
                    try {
                        const sri = new SumRowInfo();
                        sri.ShowTotal = tableSettings.ShowOnlySumRow || tableSettings.ShowSumRowTop || tableSettings.ShowSumRowBottom;
                        sri.ShowTotalOnX = tableSettings.ShowColumnSum;
                        sri.ShowRowsSummary = tableSettings.ShowRowSum;
                        sri.SumRowText = TranslatedString.GetTranslation(tableSettings.SumRowText);
                        if (!sri.SumRowText) {
                            sri.SumRowText = TranslateHelper.TranslatorInstance.instant('@@Gesamt');
                        }
                        if (sri.ShowTotal || sri.ShowTotalOnX || sri.ShowRowsSummary) {
                            // hier nicht Klon verwenden???
                            await SumRowTaskExecuter.Execute(matrix, this.reportObject.DataDescription, sri, context['Variables'], context['CalcTaskExecuter']);
                        }

                        await MultiDimensionalTableDataSource.FormatMatrix(matrix, true, context);

                        if (tableSettings.ReloadOnExpand && tableSettings.ReExpand && tableSettings.ExpandedYNodes) {
                            const nodeInfos = [];
                            tableSettings.ExpandedYNodes.forEach(x => {
                                if (x.Path && x.Path.length > 0) {
                                    nodeInfos.push(x.Path);
                                }
                            });
                            const expandQueryList = MultiDimensionalTableDataSource.GetExpandQueries(nodeInfos, matrix.YAxis.Nodes);
                            for (let i = 0; i < expandQueryList.length; i++) {
                                await this.ExpandMatrix(expandQueryList[i], matrix, context['StyleMerger']);
                            }
                        }

                        const result = this.GenerateColumnsAndRows(tableSettings, matrix, context);

                        this.Matrix = matrix;
                        this._RootNodes = result.Rows;
                        this._ColDescription = result.ColumnDescriptions;

                        if (tableSettings.ReExpand && tableSettings.ExpandedYNodes && tableSettings.ExpandedYNodes.length > 0) {
                            allRows.push(...MultiDimensionalTableDataSource.ExpandRows(result.Rows));
                        } else {
                            allRows.push(...result.Rows);
                        }
                        allRows.forEach((x, i) => {
                            x.index = i;
                        });

                        if (tableSettings.FixRows && tableSettings.FixRowsValue > 0) {
                            this.FixedRowsValue = allRows.splice(0, tableSettings.FixRowsValue);
                        } else {
                            this.FixedRowsValue = null;
                        }

                        columns.push(...result.Columns);

                        if (context['StyleMerger']) {
                            cellStyles = context['StyleMerger'].GetStyleObject();
                        }
                    } catch (e) {
                        console.log(e);
                        this.FixedRowsValue = null;
                    }
                } else {
                    this.FixedRowsValue = null;
                }
                if (columns.length === 0) {
                    const emptyCol = new Column();
                    emptyCol.Caption = '';
                    columns.push(emptyCol);
                }

                this.PagedData.next(allRows);
                this.cachedData = allRows;
                this.dataStream.next(this.cachedData);
                this.FixedRows.next(this.FixedRowsValue);
                this.Columns.next(columns);
                this.CellStyles.next(cellStyles);

                window.setTimeout(() => {
                    this.reportObject.cdRef.detectChanges();
                    window.setTimeout(() => {
                        this.reportObject.cdRef.detectChanges();
                    }, 150);
                }, 50);
            } finally {
                this.Loading.next(false);
            }
        }
    }

    private GenerateColumnsAndRows(tableSettings: ReportTableSettings, matrix: MultiResult, context): DataResult {
        const cols = [];
        let noLevelIndex = 0;
        let colSumCol: Column;
        if (matrix.XAxis.SumNode && tableSettings.ShowColumnSum) {
            colSumCol = new Column();
            colSumCol.Caption = TranslatedString.GetTranslation(tableSettings.SumRowText);
            if (!colSumCol.Caption) {
                colSumCol.Caption = TranslateHelper.TranslatorInstance.instant('@@Gesamt');
            }
            colSumCol.Name = '' + matrix.XAxis.SumNode.Position;
            colSumCol['StyleID'] = matrix.XAxis.SumNode.StyleID;
            cols.push(colSumCol);
        }
        const colDescriptions = {};
        const clonedDD = context.DataDescriptionClone;
        const measuresOnX = clonedDD.ShowMeasureOnAxis === AxisType.X_Axis;
        if (measuresOnX) {
            clonedDD.XLevelNodes.Areas.forEach(area => {
                if (area.Tuples && area.Tuples.length > 0) {
                    const firstTuple = area.Tuples[0];
                    if (firstTuple.Levels && firstTuple.Levels.length > 0) {
                        const idLevel = firstTuple.Levels[0];
                        const levelLengths = [];
                        area.Tuples.forEach(t => {
                            levelLengths.push(t.Levels.length);
                        });
                        const colQuery = new ColumnQueryValues();
                        colQuery.ColumnDescriptions = colDescriptions;
                        colQuery.Pivot = tableSettings.Pivot;
                        if (area.Measures && area.Measures.length > 0) {
                            colQuery.Measures = [];
                            area.Measures.forEach(x => {
                                if (x.VisibilityType !== VisibilityType.NonVisibleForce &&
                                    x.VisibilityType !== VisibilityType.NonVisibleDefault) {
                                    const m = matrix.Measures.Nodes.find(y => y.UniqueID === x.UniqueID);
                                    if (m) {
                                        colQuery.Measures.push(m);
                                        if (colSumCol) {
                                            const measureCol = new Column();
                                            measureCol.Caption = m.Caption;
                                            measureCol.Name = colSumCol.Name + '_' + m.Position;
                                            measureCol['StyleID'] = m.StyleID;
                                            measureCol['MeasureUniqueID'] = m.UniqueID;
                                            colDescriptions[measureCol.Name] = {
                                                M: m.Position,
                                                X: matrix.XAxis.SumNode.Position
                                            };
                                            colSumCol.FixedChildren.push(measureCol);
                                        }
                                    }
                                }
                            });
                        }
                        let found = false;
                        matrix.XAxis.Nodes.some(node => {
                            if (node.UniqueID === idLevel.UniqueID) {
                                const nodeCols = MultiDimensionalTableDataSource.GetColumns(node, levelLengths, colQuery);
                                cols.push(...nodeCols);
                                found = true;
                                return false;
                            } else if (found && node.MemberId === 'MaCTopDownRemainder') {
                                const remainderCol = new Column();
                                remainderCol.Caption = node.Caption;
                                remainderCol.Name = '' + node.Position;
                                remainderCol['StyleID'] = node.StyleID;
                                if (colQuery.Measures) {
                                    colQuery.Measures.forEach(m => {
                                        const measureCol = new Column();
                                        measureCol.Caption = m.Caption;
                                        measureCol.Name = node.Position + '_' + m.Position;
                                        measureCol['StyleID'] = m.StyleID;
                                        measureCol['MeasureUniqueID'] = m.UniqueID;
                                        colDescriptions[measureCol.Name] = {
                                            M: m.Position,
                                            X: node.Position
                                        };
                                        remainderCol.FixedChildren.push(measureCol);
                                    });
                                }
                                cols.push(remainderCol);
                            }
                            return found;
                        });
                    }
                } else if (area.Measures && area.Measures.length > 0) {
                    let innerIndex = 0;
                    let xPos;
                    if (matrix.XAxis.Nodes.length === 0) {
                        xPos = 0;
                    } else {
                        matrix.XAxis.Nodes.some(node => {
                            if (node.UniqueID === -1) { // Dummy Node
                                if (innerIndex < noLevelIndex) {
                                    innerIndex++;
                                } else {
                                    noLevelIndex++;
                                    xPos = node.Position;
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                    area.Measures.forEach(x => {
                        if (x.VisibilityType !== VisibilityType.NonVisibleForce && x.VisibilityType !== VisibilityType.NonVisibleDefault) {
                            const m = matrix.Measures.Nodes.find(y => y.UniqueID === x.UniqueID);
                            if (m) {
                                const measureCol = new Column();
                                measureCol.Caption = m.Caption;
                                measureCol.Name = xPos + '_' + m.Position;
                                measureCol['StyleID'] = m.StyleID;
                                measureCol['MeasureUniqueID'] = m.UniqueID;
                                cols.push(measureCol);
                                colDescriptions[measureCol.Name] = { M: m.Position, X: xPos };

                                if (colSumCol) {
                                    const sumMeasureCol = new Column();
                                    sumMeasureCol.Caption = m.Caption;
                                    sumMeasureCol.Name = colSumCol.Name + '_' + m.Position;
                                    sumMeasureCol['StyleID'] = m.StyleID;
                                    sumMeasureCol['MeasureUniqueID'] = m.UniqueID;
                                    colDescriptions[sumMeasureCol.Name] = { M: m.Position, X: matrix.XAxis.SumNode.Position };
                                    colSumCol.FixedChildren.push(sumMeasureCol);
                                }
                            }
                        }
                    });
                }
            });
            if (colSumCol && colSumCol.FixedChildren.length === 0) {
                colDescriptions[colSumCol.Name] = { X: matrix.XAxis.SumNode.Position };
            }
        } else {
            if (colSumCol) {
                colDescriptions[colSumCol.Name] = { X: matrix.XAxis.SumNode.Position };
            }
            const map = new Map<number, number[]>();
            clonedDD.XLevelNodes.Areas.forEach(area => {
                if (area.Tuples && area.Tuples.length > 0) {
                    const firstTuple = area.Tuples[0];
                    if (firstTuple.Levels && firstTuple.Levels.length > 0) {
                        const levelLengths = [];
                        area.Tuples.forEach(t => {
                            levelLengths.push(t.Levels.length);
                        });
                        map.set(firstTuple.Levels[0].UniqueID, levelLengths);
                    }
                }
            });
            const colQuery = new ColumnQueryValues();
            colQuery.ColumnDescriptions = colDescriptions;
            colQuery.Pivot = tableSettings.Pivot;
            matrix.XAxis.Nodes.forEach(node => {
                const mapEntry = map.get(node.UniqueID);
                if (mapEntry) {
                    const nodeCols = MultiDimensionalTableDataSource.GetColumns(node, mapEntry, colQuery);
                    cols.push(...nodeCols);
                } else {
                    const otherCol = new Column();
                    otherCol.Caption = node.Caption;
                    otherCol.Name = '' + node.Position;
                    otherCol['StyleID'] = node.StyleID;
                    cols.push(otherCol);
                }
            });
        }
        const styleMerger = context.StyleMerger;
        let sumRows;
        const allRows = [];
        let fixedRowCount = tableSettings.FixRows ? tableSettings.FixRowsValue : 0;
        if ((tableSettings.ShowOnlySumRow || tableSettings.ShowSumRowTop) && matrix.YAxis.SumNode) {
            const rowQuery = new RowQueryValues();
            rowQuery.Matrix = matrix;
            rowQuery.ColumnDescriptions = colDescriptions;
            rowQuery.StyleMerger = styleMerger;
            rowQuery.TableSettings = tableSettings;
            rowQuery.MeasuresOnX = measuresOnX;
            rowQuery.FixedRowCount = fixedRowCount;
            if (!measuresOnX) {
                rowQuery.Measures = [];
                clonedDD.YLevelNodes.Areas.forEach(area => {
                    area.Measures.forEach(m => {
                        if (m.VisibilityType !== VisibilityType.NonVisibleForce &&
                            m.VisibilityType !== VisibilityType.NonVisibleDefault) {
                            const mNode = matrix.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                            if (mNode) {
                                rowQuery.Measures.push(mNode);
                            }
                        }
                    });
                });
            }
            sumRows = this.GetRows(matrix.YAxis.SumNode, [1], 0, rowQuery, null, null);
            allRows.push(...sumRows);
        }

        const desColList = [];
        let noLevelCount = 0;
        const hiddenLevels = [];
        if (context.HiddenLevels) {
            hiddenLevels.push(...context.HiddenLevels);
        }
        const yMap = new Map<number, number[]>();
        let expRows;
        if (tableSettings.ReExpand && tableSettings.ExpandedYNodes) {
            expRows = [];
            tableSettings.ExpandedYNodes.forEach(x => {
                if (x.Path && x.Path.length > 0) {
                    expRows.push(x.Path);
                }
            });
        }
        const alternating = new AlternatingRowInfo();
        alternating.UseAlternatingColors = tableSettings.UseAlternatingColors;
        alternating.FirstAlternatingColor = tableSettings.FirstAlternatingColor;
        alternating.SecondAlternatingColor = tableSettings.SecondAlternatingColor;
        context.DataDescriptionOriginal.YLevelNodes.Areas.forEach(area => {
            if (area.Tuples && area.Tuples.length > 0) {
                const levelLengths = [];
                let firstTuple;
                area.Tuples.forEach(tuple => {
                    if (tuple.Levels) {
                        let levLengths = 0;
                        tuple.Levels.forEach((l) => {
                            if (!hiddenLevels.some(hl => hl === l.UniqueID) && !l.Hidden) {
                                while (desColList.length <= levLengths) {
                                    desColList.push({
                                        StyleID: 0,
                                        Name: 'descColumn_' + levLengths,
                                        LevelInfos: []
                                    });
                                }
                                desColList[levLengths].LevelInfos.push({
                                    Caption: l.toString(),
                                    UniqueID: l.UniqueID
                                });
                                levLengths++;
                            }
                        });
                        if (levLengths > 0) {
                            levelLengths.push(levLengths);
                            if (!firstTuple) {
                                firstTuple = tuple;
                            }
                        }
                    }
                });
                if (firstTuple) {
                    const idLevel = firstTuple.Levels[0];
                    yMap.set(idLevel.UniqueID, levelLengths);
                    if (!measuresOnX && !tableSettings.ShowOnlySumRow) {
                        const rowQuery = new RowQueryValues();
                        rowQuery.Matrix = matrix;
                        rowQuery.ColumnDescriptions = colDescriptions;
                        rowQuery.HideEmpty = tableSettings.HideEmptyRowsAndColumns;
                        rowQuery.MeasuresOnX = measuresOnX;
                        rowQuery.StyleMerger = styleMerger;
                        rowQuery.TableSettings = tableSettings;
                        rowQuery.FixedRowCount = fixedRowCount;
                        if (area.Measures && area.Measures.length > 0) {
                            rowQuery.Measures = [];
                            area.Measures.forEach(m => {
                                if (m.VisibilityType !== VisibilityType.NonVisibleForce &&
                                    m.VisibilityType !== VisibilityType.NonVisibleDefault) {
                                    const mNode = matrix.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                    if (mNode) {
                                        rowQuery.Measures.push(mNode);
                                    }
                                }
                            });
                        }
                        let found = false;
                        matrix.YAxis.Nodes.some(node => {
                            if (node.UniqueID === idLevel.UniqueID) {
                                const rL = this.GetRows(node, levelLengths, 0, rowQuery, expRows, alternating);
                                allRows.push(...rL);
                                found = true;
                                return false;
                            } else if (found && node.MemberId === 'MaCTopDownRemainder') {
                                rowQuery.HideEmpty = false;
                                const rL = this.GetRows(node, [1], 0, rowQuery, null, alternating);
                                allRows.push(...rL);
                            }
                            return found;
                        });
                    }
                }
            } else {
                if (!tableSettings.ShowOnlySumRow) {
                    if (area.Measures && area.Measures.length > 0) {
                        const rowQuery = new RowQueryValues();
                        rowQuery.Matrix = matrix;
                        rowQuery.ColumnDescriptions = colDescriptions;
                        rowQuery.HideEmpty = tableSettings.HideEmptyRowsAndColumns;
                        rowQuery.Measures = [];
                        rowQuery.MeasuresOnX = measuresOnX;
                        rowQuery.StyleMerger = styleMerger;
                        rowQuery.TableSettings = tableSettings;
                        rowQuery.FixedRowCount = fixedRowCount;
                        area.Measures.forEach(m => {
                            if (m.VisibilityType !== VisibilityType.NonVisibleForce &&
                                m.VisibilityType !== VisibilityType.NonVisibleDefault) {
                                const mNode = matrix.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                if (mNode) {
                                    rowQuery.Measures.push(mNode);
                                }
                            }
                        });
                        if (rowQuery.Measures.length > 0) {
                            let innerCount = 0;
                            matrix.YAxis.Nodes.some(node => {
                                if (node.UniqueID === -1) {
                                    if (innerCount === noLevelCount) {
                                        const rows = this.GetRows(node, [1], 0, rowQuery, expRows, alternating);
                                        allRows.push(...rows);
                                        return true;
                                    }
                                    innerCount++;
                                }
                                return false;
                            });
                        }
                    }
                    noLevelCount++;
                }
            }
        });
        if (measuresOnX) {
            if (!tableSettings.ShowOnlySumRow) {
                const rowQuery = new RowQueryValues();
                rowQuery.Matrix = matrix;
                rowQuery.ColumnDescriptions = colDescriptions;
                rowQuery.HideEmpty = tableSettings.HideEmptyRowsAndColumns;
                rowQuery.MeasuresOnX = measuresOnX;
                rowQuery.StyleMerger = styleMerger;
                rowQuery.TableSettings = tableSettings;
                rowQuery.FixedRowCount = fixedRowCount;
                matrix.YAxis.Nodes.some(node => {
                    const mapEntry = yMap.get(node.UniqueID);
                    const levelLengths = [1];
                    if (mapEntry) {
                        levelLengths.splice(0, 1, ...mapEntry);
                    }
                    const rL = this.GetRows(node, levelLengths, 0, rowQuery, expRows, alternating);
                    allRows.push(...rL);
                });
            }
        } else {
            if (tableSettings.HideMeasureHeader !== true) {
                desColList.push({
                    StyleID: 0,
                    Name: 'descColumn_M',
                    LevelInfos: []
                });
            }
        }
        if (tableSettings.ShowSumRowBottom) {
            if (sumRows) {
                allRows.push(...sumRows);
            } else if (matrix.YAxis.SumNode) {
                const rowQuery = new RowQueryValues();
                rowQuery.Matrix = matrix;
                rowQuery.ColumnDescriptions = colDescriptions;
                rowQuery.MeasuresOnX = measuresOnX;
                rowQuery.StyleMerger = styleMerger;
                rowQuery.TableSettings = tableSettings;
                rowQuery.FixedRowCount = fixedRowCount;
                if (!measuresOnX) {
                    rowQuery.Measures = [];
                    clonedDD.YLevelNodes.Areas.forEach(area => {
                        area.Measures.forEach(m => {
                            const mNode = matrix.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                            if (mNode) {
                                rowQuery.Measures.push(mNode);
                            }
                        });
                    });
                }
                sumRows = this.GetRows(matrix.YAxis.SumNode, [1], 0, rowQuery, null, null);
                allRows.push(...sumRows);
            }
        }

        if (tableSettings.FixColumns) {
            const info = {
                fixCount: tableSettings.FixColumnsValue - desColList.length,
                index: 0,
                copiedCols: []
            };
            if (info.fixCount > 0) {
                const copiedCols = MultiDimensionalTableDataSource.SplitFixedColumns(cols, info);
                cols.splice(0, 0, ...copiedCols);
                if (info.copiedCols.length > 0) {
                    allRows.forEach(row => {
                        info.copiedCols.forEach(cc => {
                            row[cc + '_c'] = row[cc];
                        });
                    });
                }
            }
        }

        let colWidth = 100;
        if (tableSettings.FixColumnWidth) {
            colWidth = tableSettings.FixColumnWidthValue;
        }
        MultiDimensionalTableDataSource.SetWidthOnLeaves(cols, colWidth, tableSettings.ColumnSettings);

        this.ExecuteDescriptionHeaderTasks(desColList, styleMerger);

        desColList.forEach((dc, i) => {
            const descColumn = new Column();
            descColumn.Caption = ' ';
            if (tableSettings.AutoDescriptionColumnHeader) {
                const captions = [];
                dc.LevelInfos.forEach(x => {
                    captions.push(x.Caption);
                });
                if (captions.length > 0) {
                    descColumn.Caption = captions.join(' / ');
                }
            }
            descColumn.Name = dc.Name;
            descColumn.IsSticky = tableSettings.FixDescriptionColumn || (tableSettings.FixColumns && tableSettings.FixColumnsValue > i);
            const descColWidth: any = {
                Type: 0, // Pixel
                Value: MultiDimensionalTableDataSource._StandardDescriptionNodeWidth
            };
            descColumn.Width = descColWidth;
            if (tableSettings.ColumnSettings && tableSettings.ColumnSettings.DescriptionColumnsWidths &&
                tableSettings.ColumnSettings.DescriptionColumnsWidths.length > i) {
                const savedWidth = tableSettings.ColumnSettings.DescriptionColumnsWidths[i];
                if (typeof savedWidth === 'number') {
                    descColumn.Width.Value = savedWidth;
                }
            }
            descColumn['StyleID'] = dc.StyleID;
            cols.splice(i, 0, descColumn);
        });

        if (tableSettings.HideEmptyRowsAndColumns) {
            MultiDimensionalTableDataSource.HideEmptyColumns(cols, allRows, colDescriptions);
        }

        return {
            Columns: cols,
            Rows: allRows,
            ColumnDescriptions: colDescriptions
        };
    }

    private GetRows(node: AxisNode, levelLengths: number[], depth: number, queryValues: RowQueryValues,
        expRows: NodeInfo[][], alternating: AlternatingRowInfo): Row[] {
        const retVal = [];
        if (node.Position !== -1 && node.Visible !== false && levelLengths.length > 0) {
            const idList = [];
            const rowDescs = [];
            MultiResultHelper.MergeLevels([node], levelLengths[0], nodeList => {
                const lastNode = nodeList[nodeList.length - 1];
                const yPos = lastNode.Position;
                const nodeRows = [];
                const row = {};
                const cols = {};
                const style = {};
                for (let i = 0; i < nodeList.length; i++) {
                    const x = nodeList[i];
                    let showCaption = true;
                    if (queryValues.TableSettings.CombineCells) {
                        const nodeKey = AxisNode.GetSearchChildrenKey(x);
                        if (idList.length <= i) {
                            idList[i] = nodeKey;
                        } else {
                            if (idList[i] != nodeKey) {
                                idList[i] = nodeKey;
                            } else {
                                showCaption = false;
                            }
                        }
                    }

                    let caption = '';
                    if (showCaption) {
                        const cd: CodeDesc = x.CodeDescription;

                        switch (cd) {
                            case CodeDesc.Description:
                                caption = x.Caption;
                                break;
                            case CodeDesc.Code:
                                caption = x.Key;
                                break;
                            case CodeDesc.CodeDescription:
                                caption = x.Key + '_' + x.Caption;
                                break;
                            case CodeDesc.None:
                                caption = '';
                                break;
                            default:
                                caption = x.Caption;
                                break;
                        }
                    }
                    row['descColumn_' + i] = caption;
                    cols['descColumn_' + i] = {
                        selected: false,
                        edit: false,
                        tabindex: 0,
                        editable: false
                    };
                    if (typeof x.StyleID === 'number') {
                        style['descColumn_' + i] = '' + x.StyleID;
                    }
                }

                if (queryValues.Measures) {
                    queryValues.Measures.forEach((m, mIndex) => {
                        const mRow = {
                            'descColumn_M': m.Caption
                        };
                        const mCols = {
                            'descColumn_M': {
                                selected: false,
                                edit: false,
                                tabindex: 0,
                                editable: false
                            }
                        };
                        const mStyle = {};
                        if (typeof m.StyleID === 'number') {
                            mStyle['descColumn_M'] = m.StyleID;
                        }
                        let hasValue = false;
                        Object.keys(queryValues.ColumnDescriptions).forEach(cd => {
                            mCols[cd] = {
                                selected: false,
                                edit: false,
                                tabindex: 0,
                                editable: false
                            };
                            let cellVal = '';
                            let cellStyle;
                            const xPos = queryValues.ColumnDescriptions[cd].X;
                            const xArray = queryValues.Matrix.Cells[xPos];
                            if (xArray) {
                                const yArray = xArray[yPos];
                                if (yArray) {
                                    const mVal = yArray[m.Position];
                                    if (mVal) {
                                        if (mVal.InternalValue != null) {
                                            cellVal = mVal.InternalValue;
                                            hasValue = true;
                                        }
                                        if (typeof mVal.StyleID === 'number') {
                                            cellStyle = '' + mVal.StyleID;
                                            if (queryValues.StyleMerger) {
                                                const newVal = queryValues.StyleMerger.ApplySpecialStyle(mVal);
                                                if (newVal) {
                                                    cellVal = newVal;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            mRow[cd] = cellVal;
                            if (cellStyle) {
                                mStyle[cd] = cellStyle;
                            } else if (queryValues.StyleMerger) {
                                mStyle[cd] = queryValues.StyleMerger['DefaultCellStyle'];
                            }
                        });
                        if (!queryValues.HideEmpty || hasValue) {
                            if (queryValues.TableSettings.CombineCells && mIndex > 0) {
                                Object.keys(row).forEach(rk => {
                                    mRow[rk] = '';
                                });
                            } else {
                                Object.assign(mRow, row);
                            }
                            Object.assign(mCols, cols)
                            Object.assign(mStyle, style);

                            const rowToPush = new Row();
                            rowToPush.data = mRow;
                            rowToPush.cols = mCols;
                            rowToPush.styles = mStyle;
                            rowToPush.Children = null;
                            rowToPush['YPos'] = yPos;
                            rowToPush['MPos'] = m.Position;
                            rowToPush['Depth'] = depth;
                            rowToPush['UniqueID'] = lastNode.UniqueID;
                            rowToPush['MemberID'] = lastNode.MemberId;
                            if (alternating) {
                                rowToPush.rowStyle = alternating.getRowStyle();
                            }
                            nodeRows.push(rowToPush);
                        }
                    });
                } else if (queryValues.MeasuresOnX) {
                    let hasValue = false;
                    Object.keys(queryValues.ColumnDescriptions).forEach(cd => {
                        cols[cd] = {
                            selected: false,
                            edit: false,
                            tabindex: 0,
                            editable: false
                        };
                        let cellVal = '';
                        let cellStyle;
                        const colDesc = queryValues.ColumnDescriptions[cd];
                        const xArray = queryValues.Matrix.Cells[colDesc.X];
                        if (xArray) {
                            const yArray = xArray[yPos];
                            if (yArray) {
                                const mVal = yArray[colDesc.M];
                                if (mVal) {
                                    if (mVal.InternalValue != null) {
                                        cellVal = mVal.InternalValue;
                                        hasValue = true;
                                    }
                                    if (typeof mVal.StyleID === 'number') {
                                        cellStyle = '' + mVal.StyleID;
                                        if (queryValues.StyleMerger) {
                                            const newVal = queryValues.StyleMerger.ApplySpecialStyle(mVal);
                                            if (newVal) {
                                                cellVal = newVal;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        row[cd] = cellVal;
                        if (cellStyle) {
                            style[cd] = cellStyle;
                        } else if (queryValues.StyleMerger) {
                            style[cd] = queryValues.StyleMerger['DefaultCellStyle'];
                        }
                    });
                    if (!queryValues.HideEmpty || hasValue) {
                        const rowToPush = new Row();
                        rowToPush.data = row;
                        rowToPush.cols = cols;
                        rowToPush.styles = style;
                        rowToPush.Children = null;
                        rowToPush['YPos'] = yPos;
                        rowToPush['Depth'] = depth;
                        rowToPush['UniqueID'] = lastNode.UniqueID;
                        rowToPush['MemberID'] = lastNode.MemberId;
                        if (alternating) {
                            rowToPush.rowStyle = alternating.getRowStyle();
                        }
                        nodeRows.push(rowToPush);
                    }
                }
                if (nodeRows.length > 0) {
                    if (queryValues.TableSettings.CombineCells && queryValues.TableSettings.ShowLines) {
                        nodeRows.forEach(nr => {
                            rowDescs.push({
                                List: [...idList],
                                Row: nr
                            });
                        });
                    }
                    retVal.push(...nodeRows);
                    const expandableRow = nodeRows[0];
                    expandableRow.Expandable = this.RowHasChildren(lastNode);
                    if (expandableRow.Expandable) {
                        let newAlternate;
                        if (alternating) {
                            newAlternate = alternating.clone();
                        }
                        if (expRows) {
                            const nextExpRows = [];
                            expRows.forEach(x => {
                                if (x.length > 0 && x[0].UniqueID === lastNode.UniqueID && x[0].MemberID === lastNode.MemberId) {
                                    expandableRow.expanded = true;
                                    if (x.length > 1) {
                                        nextExpRows.push(x.slice(1));
                                    }
                                }
                            });
                            if (expandableRow.expanded && lastNode.Children && lastNode.Children.length > 0) {
                                const nextLevels = levelLengths.slice(1);
                                const childList = [];

                                lastNode.Children.forEach(child => {
                                    const rows = this.GetRows(child, nextLevels, depth + 1, queryValues, nextExpRows, newAlternate);
                                    childList.push(...rows);
                                });
                                if (lastNode.SumNode) {
                                    const rows = this.GetRows(lastNode.SumNode, nextLevels, depth + 1, queryValues, nextExpRows, null);
                                    childList.push(...rows);
                                }
                                expandableRow.Children = childList;
                            }
                        }
                        expandableRow.RowExpand.subscribe(() => {
                            const saveExpRows = [];
                            MultiDimensionalTableDataSource.SaveExpandedNodes(saveExpRows, this._RootNodes, []);
                            queryValues.TableSettings.ExpandedYNodes = saveExpRows;
                            if (expandableRow.expanded) {
                                const childList = [];
                                if (expandableRow.Children) {
                                    childList.push(...MultiDimensionalTableDataSource.GetExpandedRows(expandableRow));
                                } else {
                                    if (lastNode.Children && lastNode.Children.length > 0) {
                                        const nextLevels = levelLengths.slice(1);
                                        lastNode.Children.forEach(child => {
                                            const rows = this.GetRows(child, nextLevels, depth + 1, queryValues, null, newAlternate);
                                            childList.push(...rows);
                                        });
                                        if (lastNode.SumNode) {
                                            const rows = this.GetRows(lastNode.SumNode, nextLevels, depth + 1, queryValues, null, null);
                                            childList.push(...rows);
                                        }
                                    } else {
                                        const loadingRow = new Row();
                                        loadingRow.data = {
                                            descColumn_0: TranslateHelper.TranslatorInstance.instant('@@Loading...')
                                        };
                                        loadingRow.cols = {
                                            descColumn_0: {
                                                selected: false,
                                                edit: false,
                                                tabindex: 0,
                                                editable: false
                                            }
                                        };
                                        loadingRow.styles = {
                                            descColumn_0: '0'
                                        };
                                        loadingRow['Depth'] = depth + 1;
                                        loadingRow['IsLoadingNode'] = true;
                                        childList.push(loadingRow);
                                        this.loadOnDemand(lastNode, queryValues.Matrix, queryValues.StyleMerger).then((styleObj) => {
                                            if (styleObj) {
                                                this.CellStyles.next(styleObj);
                                            }
                                            const nextLevels = levelLengths.slice(1);
                                            const newChildList = [];
                                            lastNode.Children.forEach(child => {
                                                const rows = this.GetRows(child, nextLevels, depth + 1, queryValues, null, newAlternate);
                                                newChildList.push(...rows);
                                            });
                                            if (lastNode.SumNode) {
                                                const rows = this.GetRows(lastNode.SumNode, nextLevels, depth + 1, queryValues, null, null);
                                                newChildList.push(...rows);
                                            }
                                            expandableRow.Children = newChildList;
                                            if (expandableRow.expanded) {
                                                this.updateRows(expandableRow, nodeRows.length, newChildList, queryValues.FixedRowCount, true);
                                            }
                                        });
                                    }
                                    expandableRow.Children = childList;
                                }
                                this.updateRows(expandableRow, nodeRows.length, childList, queryValues.FixedRowCount, false);
                            } else {
                                const rowCount = MultiDimensionalTableDataSource.GetExpandedRowCount(expandableRow);
                                if (queryValues.FixedRowCount > 0 && this.FixedRowsValue) {
                                    const fixedIndex = this.FixedRowsValue.indexOf(expandableRow);
                                    if (fixedIndex > -1) {
                                        const all = [...this.FixedRowsValue, ...this.cachedData];
                                        const index = fixedIndex + nodeRows.length;
                                        all.splice(index, rowCount);
                                        all.forEach((x, i) => { x.index = i; });
                                        this.FixedRowsValue = all.splice(0, queryValues.FixedRowCount);
                                        this.FixedRows.next(this.FixedRowsValue);
                                        this.cachedData = all;
                                        this.dataStream.next(this.cachedData);
                                        return;
                                    }
                                }

                                const index = this.cachedData.indexOf(expandableRow) + nodeRows.length;
                                this.cachedData.splice(index, rowCount);
                                for (let i = index; i < this.cachedData.length; i++) {
                                    this.cachedData[i].index = i + queryValues.FixedRowCount;
                                }
                                this.dataStream.next(this.cachedData);
                            }
                        });
                    }
                }
            });
            if (queryValues.StyleMerger && rowDescs.length > 1) {
                const defStyleNumText = queryValues.StyleMerger['DefaultCellStyle'];
                if (defStyleNumText) {
                    const defNum = Number(defStyleNumText);
                    if (!isNaN(defNum)) {
                        const defStyle = queryValues.StyleMerger.GetStyle(defNum);
                        if (defStyle && defStyle.Border && defStyle.Border.BottomBorder) {
                            const borderText = BorderSide.BorderToStyle(defStyle.Border.BottomBorder);
                            const noBottomBorderStyle = new CellStyle();
                            noBottomBorderStyle.Border = new Border();
                            noBottomBorderStyle.Border.BottomBorder = StyleMerger.GetBorder(1, 255, 255, 255, 0);
                            let last = rowDescs[rowDescs.length - 1];
                            for (let i = rowDescs.length - 2; i >= 0; i--) {
                                const act = rowDescs[i];
                                for (let j = act.List.length - 1; j >= 0; j--) {
                                    if (last.List[j] == act.List[j]) {
                                        const actStyle = act.Row.styles['descColumn_' + j];
                                        if (actStyle) {
                                            const num = Number(actStyle);
                                            if (!isNaN(num)) {
                                                const style = queryValues.StyleMerger.GetStyle(num);
                                                if (style && style.Border && style.Border.BottomBorder && BorderSide.BorderToStyle(style.Border.BottomBorder) == borderText) {
                                                    act.Row.styles['descColumn_' + j] = queryValues.StyleMerger.MergeStyle(num, noBottomBorderStyle);
                                                }
                                            }
                                        }
                                    }
                                }
                                last = act;
                            }
                        }
                    }
                }
            }
        }
        return retVal;
    }

    private updateRows(expandableRow, additionalCount, childList, fixedRowCount: number, checkLoading: boolean) {
        if (fixedRowCount > 0 && this.FixedRowsValue) {
            const fixedIndex = this.FixedRowsValue.indexOf(expandableRow);
            if (fixedIndex > -1) {
                const all = [...this.FixedRowsValue, ...this.cachedData];
                const index = fixedIndex + additionalCount;
                const splice = checkLoading && index < all.length && all[index].IsLoadingNode === true ? 1 : 0;
                all.splice(index, splice, ...childList);
                all.forEach((x, i) => { x.index = i; });
                this.FixedRowsValue = all.splice(0, fixedRowCount);
                this.FixedRows.next(this.FixedRowsValue);
                this.cachedData = all;
                this.dataStream.next(this.cachedData);
                return;
            }
        }
        const index = this.cachedData.indexOf(expandableRow) + additionalCount;
        const splice = checkLoading && index < this.cachedData.length && this.cachedData[index].IsLoadingNode === true ? 1 : 0;
        this.cachedData.splice(index, splice, ...childList);
        for (let i = index; i < this.cachedData.length; i++) {
            this.cachedData[i].index = i + fixedRowCount;
        }
        this.dataStream.next(this.cachedData);
    }

    private ExecuteDescriptionHeaderTasks(descriptionColumnList, styleMerger: StyleMerger) {
        if (styleMerger) {
            const headerLeaveStyle = new CellStyle();
            headerLeaveStyle.Border = new Border();
            headerLeaveStyle.Border.BottomBorder = StyleMerger.GetBorder(2, 127, 127, 127);
            descriptionColumnList.forEach(x => {
                x.StyleID = styleMerger.MergeStyle(x.StyleID, headerLeaveStyle);
            });
            if (this.reportObject && this.reportObject.LayoutElement && this.reportObject.LayoutElement.FormatTasks) {
                for (let i = 0; i < this.reportObject.LayoutElement.FormatTasks.length; i++) {
                    const task = this.reportObject.LayoutElement.FormatTasks[i];
                    if (task && task.TaskType === 'columndescriptionheaderformattingtask' && PageStatus.GetTaskActiveStatus(task, this.reportObject.LayoutElement.ID)
                        && task.QueryID === this.reportObject.LayoutElement.Query && task.Style) {
                        delete task.Style['MergeID'];
                        if (typeof task.LevelUniqueID === 'number' && task.LevelUniqueID > -1) {
                            descriptionColumnList.some(x => {
                                if (x.LevelInfos.some(l => l.UniqueID === task.LevelUniqueID)) {
                                    x.StyleID = styleMerger.MergeStyle(x.StyleID, task.Style);
                                    return true;
                                }
                                return false;
                            });
                        } else if (typeof task.ColumnIndex === 'number' && task.ColumnIndex > -1
                            && task.ColumnIndex < descriptionColumnList.length) {
                            const dc = descriptionColumnList[task.ColumnIndex];
                            dc.StyleID = styleMerger.MergeStyle(dc.StyleID, task.Style);
                        }
                    }
                }
            }
        }
    }

    GetSelectedCoordinatesFromRowSelection(selection: RowSelection[]): any[] {
        const retVal = [];
        if (selection && this.Matrix && this.reportObject) {
            const dd = this.reportObject.DataDescription;
            if (dd) {
                const measuresOnX = dd.ShowMeasureOnAxis === AxisType.X_Axis;
                selection.forEach(sel => {
                    if (typeof sel.RowIndex === 'number') {
                        const row = this.GetRowOnIndex(sel.RowIndex);
                        if (row) {
                            const selCor = new SelectedCoordinate();
                            if (typeof row.YPos === 'number') {
                                let yNode = MultiResultHelper.FindAxisNodeByPosition(this.Matrix.YAxis.Nodes, row.YPos);
                                while (yNode) {
                                    const ln = DataDescriptionHelper.FindLevelNodeByIDInternal(dd.YLevelNodes, yNode.UniqueID);
                                    if (ln) {
                                        const ws = new WhereSelects();
                                        ws.LevelId = ln.Level;
                                        ws.MemberIds = [yNode.MemberId];
                                        ws['MemberCaptions'] = [yNode.Caption];
                                        selCor.YPath.splice(0, 0, ws);
                                    } else {
                                        selCor.YPath.splice(0);
                                        break;
                                    }
                                    yNode = yNode.Parent;
                                }
                            }
                            if (!measuresOnX && typeof row.MPos === 'number') {
                                selCor.Measure = this.Matrix.Measures.Nodes.find(x => x.Position === row.MPos);
                            }
                            retVal.push(selCor);
                        }
                    }
                });
            }
        }
        return retVal;
    }

    GetSelectedCoordinatesFromCellSelection(selection: CellSelection[]): any[] {
        const retVal = [];
        if (selection && this.Matrix && this._ColDescription && this.reportObject) {
            const dd = this.reportObject.DataDescription;
            if (dd) {
                const measuresOnX = dd.ShowMeasureOnAxis === AxisType.X_Axis;
                selection.forEach(sel => {
                    const selCor = new SelectedCoordinate();
                    const colDesc = this._ColDescription[sel.ColumnName];
                    if (colDesc) {
                        if (typeof colDesc.X === 'number') {
                            let xNode = MultiResultHelper.FindAxisNodeByPosition(this.Matrix.XAxis.Nodes, colDesc.X);
                            while (xNode) {
                                const ln = DataDescriptionHelper.FindLevelNodeByIDInternal(dd.XLevelNodes, xNode.UniqueID);
                                if (ln) {
                                    const ws = new WhereSelects();
                                    ws.LevelId = ln.Level;
                                    ws.MemberIds = [xNode.MemberId];
                                    ws['MemberCaptions'] = [xNode.Caption];
                                    selCor.XPath.splice(0, 0, ws);
                                } else {
                                    selCor.XPath.splice(0);
                                    break;
                                }
                                xNode = xNode.Parent;
                            }
                        }
                        if (measuresOnX && typeof colDesc.M === 'number') {
                            selCor.Measure = this.Matrix.Measures.Nodes.find(x => x.Position === colDesc.M);
                        }
                    }
                    if (typeof sel.RowIndex === 'number') {
                        const row = this.GetRowOnIndex(sel.RowIndex);
                        if (row) {
                            if (typeof row.YPos === 'number') {
                                let yNode = MultiResultHelper.FindAxisNodeByPosition(this.Matrix.YAxis.Nodes, row.YPos);
                                while (yNode) {
                                    const ln = DataDescriptionHelper.FindLevelNodeByIDInternal(dd.YLevelNodes, yNode.UniqueID);
                                    if (ln) {
                                        const ws = new WhereSelects();
                                        ws.LevelId = ln.Level;
                                        ws.MemberIds = [yNode.MemberId];
                                        ws['MemberCaptions'] = [yNode.Caption];
                                        selCor.YPath.splice(0, 0, ws);
                                    } else {
                                        selCor.YPath.splice(0);
                                        break;
                                    }
                                    yNode = yNode.Parent;
                                }
                            }
                            if (!measuresOnX && typeof row.MPos === 'number') {
                                selCor.Measure = this.Matrix.Measures.Nodes.find(x => x.Position === row.MPos);
                            }
                        }
                    }
                    retVal.push(selCor);
                });
            }
        }
        return retVal;
    }

    private GetRowOnIndex(index: number) {
        if (index > -1) {
            if (this.FixedRowsValue) {
                if (index < this.FixedRowsValue.length) {
                    return this.FixedRowsValue[index];
                } else {
                    index -= this.FixedRowsValue.length;
                }
            }
            if (index < this.cachedData.length) {
                return this.cachedData[index];
            }
        }
        return null;
    }

    private RowHasChildren(node: AxisNode): boolean {
        let retVal = false;
        if (node) {
            if (node.Children && node.Children.length > 0) {
                retVal = true;
            } else if (this.reportObject && this.reportObject.LayoutElement && this.reportObject.LayoutElement.TableSettings &&
                this.reportObject.LayoutElement.TableSettings.ReloadOnExpand === true && this.reportObject.DataDescription &&
                this.reportObject.DataDescription.YLevelNodes && this.reportObject.DataDescription.YLevelNodes.Areas) {
                this.reportObject.DataDescription.YLevelNodes.Areas.some(area => {
                    return area && area.Tuples && area.Tuples.some((t, i) => {
                        if (t && t.Levels && t.Levels.some(l => l.UniqueID === node.UniqueID)) {
                            retVal = area.Tuples.length > i + 1;
                            return true;
                        }
                        return false;
                    });
                });
            }
        }
        return retVal;
    }

    private loadOnDemand(node: AxisNode, matrix: MultiResult, styleMerger: StyleMerger): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            const executer = new QueryExecuter(this.reportObject.DataDescription, this.reportObject.Data.ID);
            const context = {
                BaseReportObject: this.reportObject,
                ReloadInfo: {
                    ReloadTupleY: node,
                    ParentMatrix: matrix
                },
                StyleMerger: styleMerger
            };
            let styleCount = 0;
            if (styleMerger) {
                styleCount = styleMerger.StyleCount;
            }
            executer.execute(context, true).then(result => {
                const le = this.reportObject.LayoutElement;
                if (le && le.TableSettings && (le.TableSettings.ShowRowSum || le.TableSettings.ShowColumnSum)) {
                    const sri = new SumRowInfo();
                    sri.ShowTotal = le.TableSettings.ShowRowSum;
                    sri.ShowTotalOnX = le.TableSettings.ShowColumnSum;
                    sri.ShowRowsSummary = le.TableSettings.ShowRowSum;
                    // hier nicht Klon verwenden???
                    SumRowTaskExecuter.Execute(result, this.reportObject.DataDescription, sri,
                        context['Variables'], context['CalcTaskExecuter']).then(() => {
                            MultiDimensionalTableDataSource.FormatMatrix(result, true, context).then(() => {
                                try {
                                    MultiDimensionalTableDataSource.AppendMatrix(node, result, context);
                                    if (context.StyleMerger && context.StyleMerger.StyleCount !== styleCount) {
                                        resolve(context.StyleMerger.GetStyleObject());
                                    } else {
                                        resolve(null);
                                    }
                                } catch (e) {
                                    reject(e);
                                }
                            }).catch(e => reject(e));
                        }).catch(e => reject(e));
                } else {
                    MultiDimensionalTableDataSource.FormatMatrix(result, true, context).then(() => {
                        try {
                            MultiDimensionalTableDataSource.AppendMatrix(node, result, context);
                            if (context.StyleMerger && context.StyleMerger.StyleCount !== styleCount) {
                                resolve(context.StyleMerger.GetStyleObject());
                            } else {
                                resolve(null);
                            }
                        } catch (e) {
                            reject(e);
                        }
                    }).catch(e => reject(e));
                }
            }).catch(e => reject(e));
        });
        return promise;
    }

    private async ExpandMatrix(expandQuery: ExpandQuery, matrix: MultiResult, styleMerger: StyleMerger) {
        await this.loadOnDemand(expandQuery.ExpandNode, matrix, styleMerger);
        const nextExpQ = MultiDimensionalTableDataSource.GetExpandQueries(expandQuery.ExpandList, expandQuery.ExpandNode.Children);
        for (let i = 0; i < nextExpQ.length; i++) {
            await this.ExpandMatrix(nextExpQ[i], matrix, styleMerger);
        }
    }
}

export class SelectedCoordinate {
    XPath: WhereSelects[] = [];
    YPath: WhereSelects[] = [];
    Measure: AxisNode;
}

export class ColumnQueryValues {
    ColumnDescriptions;
    Measures: AxisNode[];
    Pivot = false;
}

export class RowQueryValues {
    Matrix: MultiResult;
    ColumnDescriptions;
    Measures: AxisNode[];
    StyleMerger: StyleMerger;
    TableSettings: ReportTableSettings;
    HideEmpty = false;
    MeasuresOnX = true;
    FixedRowCount = 0;
}

export class DataResult {
    Columns: Column[];
    Rows: Row[];
    ColumnDescriptions;
}

export class ExpandQuery {
    ExpandNode: AxisNode;
    ExpandList: NodeInfo[][] = [];
}

export class AlternatingRowInfo {
    UseAlternatingColors = false;
    FirstAlternatingColor: Gradient;
    SecondAlternatingColor: Gradient;
    StartIndex = 0;

    clone() {
        const retVal = new AlternatingRowInfo();
        retVal.UseAlternatingColors = this.UseAlternatingColors;
        retVal.FirstAlternatingColor = this.FirstAlternatingColor;
        retVal.SecondAlternatingColor = this.SecondAlternatingColor;
        retVal.StartIndex = this.StartIndex;
        return retVal;
    }

    getRowStyle() {
        let retVal = {};
        if (this.UseAlternatingColors) {
            retVal = Gradient.getStyleObject(this.StartIndex % 2 == 0 ? this.FirstAlternatingColor : this.SecondAlternatingColor);
            this.StartIndex++;
        }
        return retVal;
    }
}
