import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { EnumHelper } from '../../../helpers/enum.helper';
import { BaseThemeControl } from '../base.theme.control';
@Component({
    selector: 'combobox-theme-control',
    templateUrl: './combobox.theme.control.html',
    styleUrls: ['./combobox.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxThemeControl extends BaseThemeControl implements AfterViewInit {
    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
        this.CheckSelection();
        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(() => {
            this.CheckSelection();
        });
    }
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    @Input() showClearButton: boolean = true;

    CheckSelection() {
        if (this.DataSourceInternal && this.ItemsSource) {
            if (this.UseGrouping) {
                let item = null;
                for (var i = 0; i < this.ItemsSource.length; i++) {
                    let group = this.ItemsSource[i];
                    if (group.items) {
                        item = group.items.find((value) => {
                            return this.ValueMemberPath ? value[this.ValueMemberPath] == this.DataSourceInternal : value == this.DataSourceInternal
                        })
                        if (item) {
                            break;
                        }
                    }
                }
                this.Selection = item;
            } else {
                let item = this.ItemsSource.find((value) => {
                    return this.ValueMemberPath ? value[this.ValueMemberPath] == this.DataSourceInternal : value == this.DataSourceInternal
                })
                this.Selection = item;
            }

        }
    }

    Selection;
    ID = UUID.UUID()
    //#region Multiple
    MultipleValue = false;
    @Input()
    get Multiple(): boolean {
        return this.MultipleValue;
    }
    set Multiple(val: boolean) {
        this.MultipleValue = val;
        this.MultipleChange.emit(this.MultipleValue);
    }

    @Output() MultipleChange = new EventEmitter<boolean>();
    //#endregion
    //#region IconMemberPath
    IconMemberPathValue: string;
    @Input()
    get IconMemberPath(): string {
        return this.IconMemberPathValue;
    }
    set IconMemberPath(val: string) {
        this.IconMemberPathValue = val;
        this.IconMemberPathChange.emit(this.IconMemberPathValue);
    }

    @Output() IconMemberPathChange = new EventEmitter<string>();
    //#endregion

    //#region ValueMemberPath
    ValueMemberPathValue: string;
    @Input()
    get ValueMemberPath(): string {
        return this.ValueMemberPathValue;
    }
    set ValueMemberPath(val: string) {
        this.ValueMemberPathValue = val;
        this.ValueMemberPathChange.emit(this.ValueMemberPathValue);
    }

    @Output() ValueMemberPathChange = new EventEmitter<string>();
    //#endregion

    //#region DisplayMemberPath
    DisplayMemberPathValue: string;
    @Input()
    get DisplayMemberPath(): string {
        return this.DisplayMemberPathValue;
    }
    set DisplayMemberPath(val: string) {
        this.DisplayMemberPathValue = val;
        this.DisplayMemberPathChange.emit(this.DisplayMemberPathValue);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<string>();
    //#endregion
    //#region ShowClear
    ShowClearValue: boolean = true;
    @Input()
    get ShowClear(): boolean {
        return this.ShowClearValue;
    }
    set ShowClear(val: boolean) {
        this.ShowClearValue = val;
        this.ShowClearChange.emit(this.ShowClearValue);
    }

    @Output() ShowClearChange = new EventEmitter<boolean>();
    //#endregion
    //#region UseGrouping
    UseGroupingValue: boolean = false;
    @Input()
    get UseGrouping(): boolean {
        return this.UseGroupingValue;
    }
    set UseGrouping(val: boolean) {
        this.UseGroupingValue = val;
        this.UseGroupingChange.emit(this.UseGroupingValue);
    }

    @Output() UseGroupingChange = new EventEmitter<boolean>();
    //#endregion
    //#region OptionStyle
    OptionStyleValue: string;
    @Input()
    get OptionStyle(): string {
        return this.OptionStyleValue;
    }
    set OptionStyle(val: string) {
        this.OptionStyleValue = val;
        this.OptionStyleChange.emit(this.OptionStyleValue);
    }

    @Output() OptionStyleChange = new EventEmitter<string>();
    //#endregion
    //#region ItemsSourceSub
    ItemsSourceSubValue;
    @Input()
    get ItemsSourceSub() {
        return this.ItemsSourceSubValue;
    }
    set ItemsSourceSub(val) {
        //if (this.ItemsSourceSubValue) {
        //    this.ItemsSourceSubValue.unsubscribe();
        //}
        //if (val) {
        this.ItemsSourceSubValue = val.subscribe((items) => {
            this.ItemsSource = items;
        })
        //}
        this.ItemsSourceSubValue = val;
    }
    //#endregion
    //#region ItemsSource
    ItemsSourceValue: any[] = [];
    @Input()
    get ItemsSource(): any[] {
        return this.ItemsSourceValue;
    }
    set ItemsSource(val: any[]) {
        this.ItemsSourceValue = val;
        this.ItemsSourceChange.emit(this.ItemsSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() ItemsSourceChange = new EventEmitter<any[]>();
    //#endregion
    //#region EnumSource
    EnumSourceValue: any;
    @Input()
    get EnumSource(): any {
        return this.EnumSourceValue;
    }
    set EnumSource(val: any) {
        this.ItemsSourceValue = EnumHelper.GetDropdownValues(val);
        this.ValueMemberPath = 'type';
        this.DisplayMemberPath = 'value';
        this.EnumSourceValue = val;
        this.EnumSourceChange.emit(this.EnumSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() EnumSourceChange = new EventEmitter<any[]>();
    //#endregion
    @Output() SelectionChange = new EventEmitter<string>();

    onSelectionChange(ev) {
        this.EmitAction(ev);
        this.SelectionChange.emit(this.DataSourceInternal);
        this.valueChange.emit(ev);
    }

    clear() {
        this.DataSourceInternal = null;
        this.onSelectionChange({ originalEvent: null, value: null });
    }
}
