import { Component } from '@angular/core';
import { AObjectPreviewSettings } from '../common/adatapreview.settings';

@Component({
    selector: 'container-objectview-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerObjectViewSettings extends AObjectPreviewSettings {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Container.ContainerObjectView';
    }

    getDBTypes(): number[][] {
        return [[16]]; // DocumentStoreRead
    }

    getResourceBase(): number {
        return 0;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@ContainerObjectView',
            ID: 'containerobjectview',
            Icon: 'table_chart',
            Index: 30,
            Parent: 'objectcontainer',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
                Value: 16
            },
            Content: ContainerObjectViewSettings
        }
    }
}