import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'evi-treetableitem',
    templateUrl: './treetableitem.control.html',
    styleUrls: ['./treetableitem.control.css'],
    animations: [
        trigger('animateRow', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)'}),
                animate('200ms', style({ transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0)', opacity: 1 }),
                animate('200ms', style({ transform: 'translateY(-100%)' }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeTableItemControl {
    //#region Row
    RowValue;

    @Input()
    get Row() {
        return this.RowValue;
    }
    set Row(val) {
        this.RowValue = val;
        const marginStyle = {};
        if (this.RowValue) {
            marginStyle['margin-left'] = (this.RowValue['Depth'] * 24) + 'px';
        }
        this.MarginStyle = marginStyle;
        this.RowChange.emit(this.RowValue);
    }

    @Output() RowChange = new EventEmitter<any>();
    //#endregion
    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        const colWidth = {};
        if (this.ColumnsValue) {
            colWidth['flex'] = '1 1 ' + (Math.floor(100 / this.ColumnsValue.length)) + '%';
        }
        this.ColWidth = colWidth;
        this.ColumnsChange.emit(this.ColumnsValue);
    }

    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion
    //#region TreeControl
    TreeControlValue;

    @Input()
    get TreeControl() {
        return this.TreeControlValue;
    }
    set TreeControl(val) {
        this.TreeControlValue = val;
        this.TreeControlChange.emit(this.TreeControlValue);
    }

    @Output() TreeControlChange = new EventEmitter<any>();
    //#endregion

    MarginStyle = {};
    ColWidth = {};

    OnChange() {
        if (this.TreeControlValue) {
            this.TreeControlValue.OnChange();
        }
    }

    OnExpand() {
        this.Row.Expanded = !this.Row.Expanded;
        if (this.TreeControlValue) {
            this.TreeControlValue.OnExpand();
        }
    }
}
