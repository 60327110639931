import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';
import { TranslatedString } from '../../models/translatedstring.model';
import {
    DebugSessionMessage, DebugSessionState, DebugSessionStateDataType, NextStep, NextStepInfo
} from '../../models/workflow/debug.log.message';
import { BreakPointInfo } from '../../models/workflow/workflow.debug.settings';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { DebugService } from '../../services/debug.service';
import { WF_REGISTRY } from '../../services/workflow.service';

@Component({
    selector: 'debug-workflow-state',
    templateUrl: './debug.workflow.state.control.html',
    styleUrls: ['./debug.workflow.state.control.css']
})
export class DebugWorkflowStateControl implements AfterViewInit {
    Initialized = false;
    editorOptions = {
        automaticLayout: true,
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    Waiting = false;
    Script = '';
    StateSettings = {
        Breakpoints: []
    };
    StateData: {
        Key: string,
        DataType: DebugSessionStateDataType,
        Data
    };
    private LoadedWorkflows = {};

    //#region Data
    DataValue: DebugSessionState;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val !== this.DataValue) {
            this.DataValue = val;
            this.UpdateContent();
        }
    }
    //#endregion

    @Output() Cancel = new EventEmitter<any>();
    CaptionValue: string;
    @Output() CaptionChange = new EventEmitter<string>();

    constructor(private cdRef: ChangeDetectorRef, private service: DebugService, private _injector: Injector) {
    }

    ngAfterViewInit() {
        this.Initialized = true;
        this.UpdateContent();
    }

    changeCaption(caption: string) {
        if (caption !== this.CaptionValue) {
            this.CaptionValue = caption;
            this.CaptionChange.emit(this.CaptionValue);
        }
    }

    play(clientID, runID) {
        this.sendStepMessage(NextStep.Play);
    }

    stepInto(clientID, runID) {
        this.sendStepMessage(NextStep.Into);
    }

    stepOver(clientID, runID) {
        this.sendStepMessage(NextStep.Next);
    }

    stepOut(clientID, runID) {
        this.sendStepMessage(NextStep.Out);
    }

    cancel(clientID, runID) {
        this.sendStepMessage(NextStep.Cancel);
        this.Cancel.emit();
    }

    private sendStepMessage(nextStep: NextStep) {
        this.Waiting = true;
        const message = new DebugSessionMessage();
        message.Target = this.DataValue.ClientID;
        message.Type = 'NextStep';
        const content = new NextStepInfo();
        content.RunID = this.DataValue.RunID;
        content.StepIntoRunID = this.DataValue.StepIntoRunID;
        content.NextStep = nextStep;
        content.Breakpoints = this.StateSettings.Breakpoints;
        message.Content = content;
        WorkflowDebugHelper.SendDebugMessage(message);
    }

    private UpdateContent() {
        if (this.Initialized) {
            this.Waiting = false;
            if (this.DataValue) {
                this.Script = JSON.stringify(this.DataValue.Status, null, 4);
                if (this.DataValue.Data) {
                    const stateData = this.DataValue.Data.Data;
                    if (stateData) {
                        this.StateSettings = {
                            Breakpoints: stateData.Breakpoints ?? []
                        };
                        switch (this.DataValue.Data.DataType) {
                            case DebugSessionStateDataType.Workflow:
                                const wfKey = stateData.PageSID + '_' + stateData.WorkflowID;
                                if (this.StateData && this.StateData.DataType === this.DataValue.Data.DataType && this.StateData.Key === wfKey) {
                                    this.StateData.Data.Selection = {
                                        ModuleID: stateData.ModuleID,
                                        Start: stateData.Start
                                    };
                                    this.cdRef.detectChanges();
                                } else {
                                    this.GetWorkflow(stateData).then(wf => {
                                        this.StateData = {
                                            Key: wfKey,
                                            DataType: this.DataValue.Data.DataType,
                                            Data: {
                                                Workflow: wf,
                                                Selection: {
                                                    ModuleID: stateData.ModuleID,
                                                    Start: stateData.Start
                                                }
                                            }
                                        }
                                        this.changeCaption(wf.Caption + ' (' + this.DataValue.RunID + ')');
                                        this.cdRef.detectChanges();
                                    });
                                }
                                break;
                            case DebugSessionStateDataType.Module:
                                const modKey = stateData.PageSID + '_' + stateData.WorkflowID + '_' + stateData.ModuleID;
                                if (this.StateData && this.StateData.DataType === this.DataValue.Data.DataType &&
                                    this.StateData.Key === modKey && this.StateData.Data && this.StateData.Data.Handler) {
                                    let abp;
                                    const bpi = this.StateSettings.Breakpoints.find(bp => bp.ModuleID === stateData.ModuleID);
                                    if (bpi) {
                                        abp = bpi.AdditionalBreakPoints;
                                    }
                                    this.StateData.Data.Handler.updateControlData(this.StateData.Data.Data, abp, stateData);
                                    this.cdRef.detectChanges();
                                } else {
                                    this.GetWorkflow(stateData).then(wf => {
                                        let data;
                                        if (wf && wf.Data) {
                                            const mod = WorkflowSaveObject.DeserializeModuleSettings(wf.Data.Modules.find(x => x.ID === stateData.ModuleID));
                                            if (mod && mod.Settings) {
                                                const desc = WF_REGISTRY.get(mod.Module);
                                                if (desc && desc.SettingsTypeHelper) {
                                                    const abh = desc.SettingsTypeHelper.getAdditionalBreakpointHandler(mod.Settings);
                                                    if (abh) {
                                                        let breakpoints = stateData.Breakpoints ?? [];
                                                        let bpi = breakpoints.find(bp => bp.ModuleID === stateData.ModuleID);
                                                        if (!bpi) {
                                                            bpi = new BreakPointInfo();
                                                            bpi.ModuleID = stateData.ModuleID;
                                                            bpi.AdditionalBreakPoints = abh.EmptyData;
                                                            breakpoints.push(bpi);
                                                        } else if (!bpi.AdditionalBreakPoints) {
                                                            bpi.AdditionalBreakPoints = abh.EmptyData;
                                                        }
                                                        const controlData = {};
                                                        abh.updateControlData(controlData, bpi.AdditionalBreakPoints, stateData);
                                                        const injectorTokens = new WeakMap();
                                                        injectorTokens.set(abh.Token, controlData);
                                                        data = {
                                                            Key: modKey,
                                                            DataType: this.DataValue.Data.DataType,
                                                            Data: {
                                                                Handler: abh,
                                                                Data: controlData,
                                                                ModuleTemplate: new ComponentPortal(abh.ComponentType, null, new PortalInjector(this._injector, injectorTokens)),
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        this.StateData = data;
                                        this.changeCaption(wf.Caption + ' (' + this.DataValue.RunID + ')');
                                        this.cdRef.detectChanges();
                                    });
                                }
                                break;
                        }
                    } else {
                        this.StateData = null;
                        this.StateSettings = {
                            Breakpoints: []
                        };
                        this.changeCaption(this.DataValue.RunID);
                        this.cdRef.detectChanges();
                    }
                } else {
                    this.StateData = null;
                    this.StateSettings = {
                        Breakpoints: []
                    };
                    this.changeCaption(this.DataValue.RunID);
                    this.cdRef.detectChanges();
                }
            } else {
                this.Script = '';
                this.StateData = null;
                this.StateSettings = {
                    Breakpoints: []
                };
                this.changeCaption(this.DataValue.RunID);
                this.cdRef.detectChanges();
            }
        }
    }

    private GetWorkflow(data) {
        return new Promise<any>(resolve => {
            const key = data.PageSID + '_' + data.WorkflowID;
            const wf = this.LoadedWorkflows[key];
            if (wf) {
                resolve(wf);
            } else {
                if (data.AdditionalData) {
                    let wfData = JSON.parse(data.AdditionalData);
                    if (data.PageSID === 'service') {
                        wfData = {
                            Data: wfData,
                            Caption: TranslatedString.GetTranslation(wfData.Caption)
                        };
                    }
                    this.LoadedWorkflows[key] = wfData;
                    resolve(wfData);
                } else {
                    this.service.GetWorkflow(data.WorkflowID, data.PageSID).subscribe(x => {
                        if (x && (data.PageSID === 'service' || data.PageSID === 'template')) {
                            x = {
                                Data: x,
                                Caption: TranslatedString.GetTranslation(x.Caption)
                            }
                        }
                        this.LoadedWorkflows[key] = x;
                        resolve(x);
                    });
                }
            }
        });
    }
}
