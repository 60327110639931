import moment from 'moment';
import { Directionality } from '@angular/cdk/bidi';
import { Clipboard } from '@angular/cdk/clipboard';
import { Platform } from '@angular/cdk/platform';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { TranslateDefaultParser, TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ResizedEvent } from 'angular-resize-event';
import { plainToClass } from 'class-transformer';
import { SnotifyService } from 'ng-snotify';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { interval, Subject, Subscription } from "rxjs";
import { CacheService } from '../cache/cache.service';
import { DebugWindow } from '../debugconsole/debug.window';
import { AboutInfo } from '../helpers/about.info';
import { ClientHelper } from '../helpers/client.helper';
import { ClipboardHelper } from '../helpers/clipboard.helper';
import { GitHelper } from '../helpers/git.helper';
import { KeyboardShortcuts } from '../helpers/keyboard.helper';
import { LayoutHelper } from '../helpers/layout.helper';
import { LogoutHandler } from '../helpers/logout.handler';
import { NavigationHelper } from '../helpers/navigation.helper';
import { NetworkConnection } from '../helpers/network.helpers';
import { NotificationHelper } from '../helpers/notification.helper';
import { OfflineHelper } from '../helpers/offline.helper';
import { PermissionHelper } from '../helpers/permissions.helper';
import { PlatformHelper } from '../helpers/platform.helper';
import { RTLHelper } from '../helpers/rtl.helper';
import { ServiceRegistryHelper } from '../helpers/serviceregistry.helper';
import { ThemeHelper } from '../helpers/theme.helpers';
import { VariableHelper } from '../helpers/variable.helper';
import { WorkflowDebugHelper } from '../helpers/workflow.debug.helper';
import { MWSettingsRegistryHelper } from '../registries/settings/app.mw.settings';
import { MWWorkflowRegistryHelper } from '../registries/workflows/app.mw.workflow';
import { MACSettingsRegistryHelper } from '../registries/settings/mac.settings';
import { TaskRegistryHelper } from '../registries/tasks/mac.tasks';
import { MacWorkflowRegistryHelper } from '../registries/workflows/mac.workflows';
import { PageStatus } from '../models/page.status';
import { FormulaStatics } from '../models/basic/formulaEditor.model';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { OfflineStrategy } from '../models/enums/offlinestrategy.enum';
import { ViewType } from '../models/enums/viewtype.enum';
import { Layout } from '../models/layout.model';
import { Theme } from '../models/style/theme.model';
import { ActionRegistryHelper, DeploymentActionRegistryHelper } from '../registries/actions/actions.registry';
import { ComponentRegistryMac } from '../registries/controls/component.registry.mac.helper';
import { ComponentRegistryMiddle } from '../registries/controls/component.registry.middle.helper';
import { ComponentRegistryHelper } from '../registries/controls/component.registry';
import { SettingsRegistryHelper } from '../registries/settings/settings.registry';
import { WorkflowRegistryHelper } from '../registries/workflows/workflow.registry';
import { AppBuilderSettingsService } from '../services/appbuildersettings.service';
import { DataService } from '../services/data.service';
import { ContainerEnumService } from '../services/datamodel.service';
import { LAYOUTEVENTREGISTRY } from '../services/dynamic.component.service';
import { DynamicDataService } from '../services/dynamicdata.service';
import { GeneralSettingsService } from '../services/generalsettings.service';
import { GlobalVariableService } from '../services/globalvariable.service';
import { InterceptorSkipHeader } from '../services/interceptor.skip.header';
import { LanguageService } from '../services/language.service';
import { LayoutService } from '../services/layout.service';
import { MediaService } from '../services/media.service';
import { MetaService } from '../services/meta.service';
import { NavigationService } from '../services/navigation.service';
import { OfflineService } from '../services/offline.service';
import { PermissionsService } from '../services/permissions.service';
import { StandardRequestBase } from '../services/request-base';
import { RolesService } from '../services/roles.service';
import { SettingsService } from '../services/settings.service';
import { SideNavService } from '../services/sidenav.service';
import { UsersService } from '../services/users.service';
import { UserSettingsService } from '../services/usersettings.service';
import { WebPushService } from '../services/webpush.service';
import { WebsocketService } from '../services/websocket.service';
import { WorkerService } from '../services/worker.service';
import { WorkflowService } from '../services/workflow.service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';
declare let FontFace: any;

@Component({
    selector: 'appframework-root',
    templateUrl: './appframework.component.html',
    styleUrls: ['./appframework.component.css']
})
export class AppFrameworkComponent implements OnInit, OnDestroy {
    //#region Properties
    unlisten;
    viewType: ViewType;
    Layout;
    User;
    Startup = true;
    menuOpen = false;
    OverlayStatus;
    LastWidth;
    Width;
    Height;
    ContentWidth;
    Loading = false;
    WebSocketActive = false;
    NoStructurePermission = false;
    LayoutEditMode = false;
    ActiveUrl;

    private _dirChangeSubscription = Subscription.EMPTY;
    Direction = 'ltr';

    @ViewChild('framework') frameworkRoot: ElementRef;
    //#endregion
    //#region Lifecycle
    constructor(private keyboardShortcuts: KeyboardShortcuts,
        private swPush: SwPush,
        private webpush: WebPushService,
        private translate: TranslateService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private metaService: MetaService,
        private dataService: DataService,
        private rolesService: RolesService,
        private enumService: ContainerEnumService,
        private variableService: GlobalVariableService,
        private usersService: UsersService,
        private dynamicDataService: DynamicDataService,
        private router: Router,
        snotify: SnotifyService,
        private route: ActivatedRoute,
        private mediaService: MediaService,
        private languageService: LanguageService,
        private permissionService: PermissionsService,
        private generalSettingsService: GeneralSettingsService,
        private meta: Meta,
        private title: Title,
        private stdRequestBase: StandardRequestBase,
        private usersettingsServie: UserSettingsService,
        private materialCssVarsService: MaterialCssVarsService,
        private workflowService: WorkflowService,
        public workerService: WorkerService,
        private offlineService: OfflineService,
        private swUpdate: SwUpdate,
        private config: PrimeNGConfig,
        private cdRef: ChangeDetectorRef,
        dir: Directionality,
        private appbuilderService: AppBuilderSettingsService,
        private cb: Clipboard,
        location: LocationStrategy,
        public platform: Platform,
        ngZone: NgZone,
        private locationUrl: Location) {
        PlatformHelper.Initialize(platform);
        NetworkConnection.init();
        RTLHelper.Initialize(dir);
        this.Direction = RTLHelper.Direction;
        RTLHelper.DirectionChanged.subscribe((value) => {
            this.Direction = value;
        })
        router.events.forEach((ev) => {
            if (ev instanceof NavigationStart) {
                if (this.viewType === ViewType.Edit) {
                    LayoutService.ViewType.next(ViewType.View);
                }
                if (ev.navigationTrigger == 'popstate') {
                    NavigationService.PopState.next(ev);
                }
                NavigationService.NavigationStart.next(ev.url);
                //CollaborationHelper.SetActiveChannel(null);
            }
            if (ev instanceof NavigationEnd) {
                this.ActiveUrl = ev.url;
                NavigationService.ActiveURL.next(ev.url);
                if (ClientHelper.StartUpCompleted.getValue()) {
                    this.checkNavigationStruct().then();
                }
                this.cdRef.detectChanges();
                NavigationService.NavigationEnd.next(ev.url);
            }
        });

        NotificationHelper.Initialize(snotify, translate);
        ClipboardHelper.Init();
        LayoutService.Loading.subscribe((state) => {
            this.Loading = state;
        });

        this.User = this.usersService.initUserFromLocalStorage();
        const loggedOut = LogoutHandler.Initialize(ngZone);
        if (loggedOut) {
            this.User = null;
        }

        LayoutService.Loading.next(true);

        window.addEventListener('beforeunload', (e) => {
            let closePrevented = LayoutService.ViewType.getValue() == ViewType.Edit || LayoutService.PreventBrowserClose.getValue();
            NavigationService.BeforeUnload.next(closePrevented);
            if (closePrevented) {

                e.preventDefault();
                e.returnValue = '';

                setTimeout(() => {
                    setTimeout(() => {
                        NavigationService.CancelUnload.next(null);
                    }, 500);
                }, 50);

                return '';
            }

            delete e['returnValue'];
        });
        window.addEventListener('unload', (e) => {
            NavigationService.OnUnload.next(null);
        });
        location.onPopState((ev) => {
            NavigationService.OnPopState.next(null);
        });
    }
    SubscribeWebSocketMessage() {
        WebsocketService.MessageReceived.subscribe((response) => {
            const parsed = JSON.parse(response);
            if (parsed.Level == NotificationLevel.System && parsed.Type == 'evidanza.Core.Service.Meta.MetaUpdateNotification') {
                const content = JSON.parse(parsed.Content);
                if (content.Class == 'evidanza.App.Shared.Data.Theme') {
                    ThemeHelper.LoadThemes().then(() => {
                        this.CheckTheme(true);
                    });
                } else if (content.Class == 'evidanza.App.Shared.Data.CustomCSSVariable') {
                    ThemeHelper.LoadCustomCSSVariables();
                } else {
                    CacheService.RefreshSpecific(content.Class, content.ID);
                }
            } else if (parsed.Type === 'evidanza.App.Shared.Notification.ClientNotification' || parsed.Type === 'evidanza.Core.Base.Notification.UpdateMessage') {
                NotificationHelper.HandleClientNotification(parsed);
            } else if (parsed.Type === 'evidanza.App.Shared.Notification.ErrorMessage') {
                NotificationHelper.HandleErrorNotification(parsed);
            } else if (parsed.Type === 'evidanza.App.Shared.Meta.ChangeLogUndoNotification' && this.User) {
                const content = JSON.parse(parsed.Content);
                if (content && content.UserID == this.User.SID) {
                    NotificationHelper.HandleClientNotification(parsed);
                }
            }
        });
    }
    AppConfig;
    ngOnInit(): void {
        const currentUrl = this.locationUrl.path();
        this.config.filterMatchModeOptions = {
            text: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.CONTAINS
            ],
            numeric: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ],
            date: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ]
        }
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        this.http.get('assets/config.json', { headers }).subscribe((result) => {
            if (result) {
                this.AppConfig = result;
                if (result['CLIENT_VERSION'] == "MaC") {
                    //MaC
                    ComponentRegistryMac.Initialize();
                    MACSettingsRegistryHelper.Build();
                    TaskRegistryHelper.Build();
                    MacWorkflowRegistryHelper.Build();
                    NavigationService.SelectedPage.subscribe(p => {
                        PageStatus.ActualState.next(new PageStatus());
                    });
                    //MaC

                    //Middleware
                    ComponentRegistryMiddle.Initialize();
                    MWSettingsRegistryHelper.Build();
                    MWWorkflowRegistryHelper.Build();
                    //Middleware
                } else if (result['CLIENT_VERSION'] == "Middleware") {
                    //Middleware
                    ComponentRegistryMiddle.Initialize();
                    MWSettingsRegistryHelper.Build();
                    MWWorkflowRegistryHelper.Build();
                    //Middleware
                }
                if (result['API_BASE_URL']) {
                    SettingsService.API_BASE_URL.next(result['API_BASE_URL']);

                    ServiceRegistryHelper.Build();
                    OfflineHelper.Initialize(this.offlineService, this.stdRequestBase, this.dynamicDataService, UsersService.Login, UsersService.Logout);

                    const cache_active = !result['DEACTIVATE_CLIENT_CACHE'];

                    CacheService.Initialize(cache_active, this.metaService, this.dataService, this.enumService, this.mediaService,
                        this.permissionService, this.generalSettingsService, this.rolesService, this.variableService, this.workflowService).then(() => {
                            GitHelper.Initialize().then((initialized) => {
                                this.ConfigLoaded();
                            });
                        });
                }
                if (result['WEBSOCKET_URL']) {
                    SettingsService.WEBSOCKET_URL.next(result['WEBSOCKET_URL']);
                    WebsocketService.Initialize(result['WEBSOCKET_URL'], result['USE_WORKER']);
                    if (this.User) {
                        WebsocketService.Start();
                    }
                    WebsocketService.Active.subscribe((val) => {
                        if (val == true) {
                            CacheService.Refresh();
                            this.WebSocketActive = val;
                        }
                    });
                    this.SubscribeWebSocketMessage();
                }
            }
            if (!this.User) {
                if (this.AppConfig) {
                    let encrypted = this.encryptAPIKey(this.AppConfig["DefaultAccessToken"])
                    localStorage.setItem("token", encrypted)
                }
            }
        });
        LayoutHelper.InitSelectionListeners();
        LayoutService.OverlayStatus.subscribe((status) => {
            this.OverlayStatus = status;
            if (status) {
                LayoutService.TriggerEventsInEditMode.next(false);
            }
        });
        LayoutService.ResolutionSelected.subscribe((width) => {
            this.Width = this.ContentWidth;
            this.ContentWidth = width;
            if (width) {
                LayoutService.RootSize.next({ Width: width, Height: this.Height });
            } else {
                LayoutService.RootSize.next(null);
            }
            this.SetContentStyle();
        });
        LayoutService.ViewType.subscribe((vt) => {
            this.viewType = vt;
            LayoutService.OverlayStatus.next(vt === ViewType.Edit);
            this.cdRef.detectChanges();
        });
        LayoutService.LayoutEditMode.subscribe((x) => {
            this.LayoutEditMode = x;
            this.cdRef.detectChanges();
        });
        // if (currentUrl == "/default/settings") {
        // this.router.navigate([currentUrl+'/dashboard']);
        // }
    }

    ConfigLoaded() {

        if (this.swUpdate.isEnabled) {

            this.swUpdate.available.subscribe(() => {

                if (confirm("New version available. Load New Version?")) {

                    window.location.reload();
                }
            });
        }
        //#region GeneralSettings
        SettingsService.GeneralSettings.subscribe((settings) => {
            if (settings) {
                if (settings.GoogleMeasurementID) {
                    this.loadGoogleAnalytics(settings.GoogleMeasurementID);
                }
                localStorage.setItem("GeneralSettings", JSON.stringify(settings));
            } else {
                localStorage.removeItem("GeneralSettings");
            }
            LogoutHandler.SetLogoutTime(settings);
        });
        if (NetworkConnection.Online.getValue()) {
            this.generalSettingsService.ReadAll().subscribe((settings) => {
                if (settings && settings.length > 0) {
                    const actSetting = settings[0];
                    SettingsService.GeneralSettings.next(actSetting);
                    if (!this.User && actSetting.AnonymousUser && actSetting.AnonymousUser !== '00000000-0000-0000-0000-000000000000') {
                        this.usersService.executeAnonymousLogin(actSetting.AnonymousUser);
                    }
                    WebPushService.UseWebPush.next(actSetting.UseWebPush);
                    if (this.User && actSetting.UseWebPush) {
                        this.RequestSubscription();
                    }
                } else {
                    SettingsService.GeneralSettings.next({
                        Offline: {
                            Active: false,
                            Strategy: OfflineStrategy.NetworkFirst
                        }
                    });
                }
            });
        }
        //#endregion
        //#region QueryParams
        this.route.queryParams.subscribe((params) => {
            if (params) {
                NavigationService.Params.next(params);
                if (params['token']) {
                    const res = JSON.parse(params['token']);
                    if (res) {
                        this.usersService.setTokenResult(res).then(user => {
                            this.User = user;
                            const copy = {};
                            Object.keys(params).forEach(k => {
                                if (k != 'token') {
                                    copy[k] = params[k];
                                }
                            });
                            this.router.navigate([], { relativeTo: this.route, queryParams: copy });
                        });
                    }
                }
                if (params['providerid']) {
                    localStorage.setItem('providerid', params['providerid']);
                }
                if (params['userid']) {
                    this.usersService.findUser(params['userid']).subscribe((res) => {
                        if (res) {
                            this.User = res;
                            localStorage.setItem('user', JSON.stringify(this.User));
                        }
                    })
                }
            } else {
                NavigationService.Params.next(null);
            }
        });
        //#endregion
        //#region MetaTags
        this.metaService.GetTags().subscribe((tags) => {
            if (tags) {
                tags.forEach((tag) => {
                    if (tag.Key === "title") {
                        this.title.setTitle(tag.Value);
                    }
                    this.meta.updateTag({ name: tag.Key, content: tag.Value });
                });
            }
        });
        //#endregion
        VariableHelper.Initialize(this.usersettingsServie);
        //#region LoadCustomCSSVars
        ThemeHelper.Initialize(this.materialCssVarsService, this.metaService, this.appbuilderService);
        ThemeHelper.SetCustomVariables();
        ThemeHelper.SetTheme(null);
        //#endregion
        WorkflowDebugHelper.Init();
        //#region Translation
        this.translate.setDefaultLang('9');
        this.stdRequestBase.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            if (languages && languages.length > 0) {
                let lang;
                let savedLang = localStorage.getItem('SelectedLanguage');
                if (savedLang) {
                    lang = languages.find((value) => value.LCID == savedLang);
                } else {
                    let userLang = navigator.language;
                    lang = languages.find((value) => value.Name == userLang);
                }

                let defaultLang = languages.find(value => value.IsDefault);
                if (defaultLang) {
                    this.translate.setDefaultLang('' + defaultLang.LCID);
                }
                if (lang) {
                    this.translate.use('' + lang.LCID);
                    moment.locale(lang.Name);
                } else if (defaultLang) {
                    this.translate.use('' + defaultLang.LCID);
                }
            }
        });
        if (this.translate.parser instanceof TranslateDefaultParser) {
            this.translate.parser.templateMatcher = /{\s?([^{}\s]*)\s?}/g;
        }
        this.translate.onLangChange.subscribe((event) => {
            this.stdRequestBase.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
                if (languages && languages.length > 0) {
                    let lang;
                    let savedLang = localStorage.getItem('SelectedLanguage');
                    if (savedLang) {
                        lang = languages.find((value) => value.LCID == savedLang);
                    } else {
                        let userLang = navigator.language;
                        lang = languages.find((value) => value.Name == userLang);
                    }
                    if (lang) {
                        RTLHelper.Direction = lang.IsRightToLeft ? 'rtl' : 'ltr';
                        moment.locale(lang.Name);
                    }
                    LanguageService.SelectedLanguage.next(lang);
                    LanguageService.LanguageChanged.next(lang);
                }
            });
        })
        //#endregion

        ComponentRegistryHelper.Build();
        WorkflowRegistryHelper.Build();
        SettingsRegistryHelper.Build();
        ActionRegistryHelper.Build();
        DeploymentActionRegistryHelper.Build();
        FormulaStatics.Init();
        //CollaborationHelper.Init();

        //#region MediaSources
        this.mediaService.GetAll().subscribe((data) => {
            MediaService.MediaSources.next(data);
        });
        //#endregion
        //#region languages
        this.languageService.getLanguages().subscribe((data) => {
            LanguageService.Languages.next(data);
        });
        //#endregion
        //#region User
        UsersService.Login.subscribe((user) => {
            this.User = user;
            WebsocketService.Start();
            NotificationHelper.ClearMessages();
            CacheService.NavigationPermissionChanged.next(null);
            CacheService.RefreshGlobalVariableValues().then(() => { });

            if (this.User && WebPushService.UseWebPush.getValue()) {
                this.RequestSubscription();
            }
        });
        UsersService.BeforeLogout.subscribe(() => {
            var sub = WebPushService.ActiveSubscription.getValue();
            if (sub && this.User && WebPushService.UseWebPush.getValue()) {
                this.webpush.deleteSubscription(sub).subscribe(() => { });
            }
        });
        UsersService.Logout.subscribe(() => {
            this.User = null;
            WebsocketService.Stop();
            NotificationHelper.ClearMessages();
            LayoutService.ViewType.next(ViewType.View);
        });
        //#endregion
        //#region GlobalVariables
        if (this.User) {
            CacheService.RefreshGlobalVariableValues().then(() => { });
        }
        //#endregion

        LayoutService.ViewType.next(ViewType.View);
        LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
            //LayoutService.Selection.next(null);
        });
        LayoutService.Theme.subscribe((theme) => {
            if (theme) {
                localStorage.setItem('theme', theme);
            }
        });
        //#region Add Keyboard Listener
        this.unlisten = this.keyboardShortcuts.listen(
            {
                'F2': (event: KeyboardEvent): void => {
                    LayoutService.KeyPress.next(event);
                    event.preventDefault();
                },
                'F12': (event: KeyboardEvent): void => {
                    if (PermissionHelper.GetDebuggerPermission()) {
                        DebugWindow.SwitchDebug();
                        event.preventDefault();
                    }
                },
                'del': (event: KeyboardEvent): void => {
                    if (this.viewType == ViewType.Edit) {
                        LayoutService.KeyPress.next(event);
                        event.preventDefault();
                    }
                },
                's.Control': (event: KeyboardEvent): void => {
                    if (event.ctrlKey && this.viewType == ViewType.Edit) {
                        LayoutService.KeyPress.next(event);
                        event.preventDefault();
                    }
                },
                'i.Control': (event: KeyboardEvent): void => {
                    if (event.ctrlKey) {
                        AboutInfo.GetAboutInfo().then(x => {
                            if (x) {
                                this.cb.copy(x.getInfoText());
                            }
                        });
                        event.preventDefault();
                    }
                }
            },
            {
                priority: 100,
                terminalWhitelist: ['Escape']
            }
        );
        //#endregion

        this.metaService.ReadEventInfos().subscribe(x => {
            if (x) {
                x.forEach(y => {
                    LAYOUTEVENTREGISTRY.push({
                        ID: y.SID,
                        Trigger: new Subject<any>()
                    });
                });
            }
            this.LoadIcons();
        }, err => {
            console.log('Error while loading layout events: ' + err);
            this.LoadIcons();
        });
    }

    RequestSubscription() {
        this.swPush.requestSubscription({
            serverPublicKey: "BFpoSRrrbkqF4Y6wAaZ4HFL8qQYCG8tgN030sk14fN0zKeqTnfPJ35__1T0WOwTJkopyEGCjuLSMMVMNBNjC8rs"
        })
            .then(sub => this.webpush.SaveSubscription(sub).subscribe())
            .catch(err => console.error("Could not subscribe to notifications", err));

        this.swPush.notificationClicks.subscribe(({ action, notification }) => {
            let act = action.split('_');
            let payload = notification.data.actionsData[act[act.length - 1]];
            switch (act[0]) {
                case 'openurl':
                    window.open(payload, '_blank');
                    break;
                case 'close':
                    break;
            }
        });
    }

    LoadIcons() {
        this.mediaService.LoadAllIcons().subscribe(result => {
            if (result) {
                const imageURL = result.URL.replace('icons', 'images');
                MediaService.IconURL.next(result.URL);
                MediaService.ImageURL.next(imageURL);
                const iconList = [];
                const gadgetList = [];
                result.IconNames.forEach(icon => {
                    const sani = this.sanitizer.bypassSecurityTrustResourceUrl(result.URL + icon + '.svg');
                    this.iconRegistry.addSvgIcon(icon, sani);
                    if (icon.startsWith('gadget/')) {
                        gadgetList.push(icon);
                    } else {
                        iconList.push(icon);
                    }
                });
                MediaService.IconList.next(iconList);
                MediaService.GadgetList.next(gadgetList);
            } else {
                console.log('No icons loaded.');
            }
            //#region Navigation
            this.LoadFonts();
            //#endregion
        }, err => {
            //#region Navigation
            console.log('Error while loading icons: ' + err);
            this.LoadFonts();
            //#endregion
        });
    }

    LoadFonts() {
        this.mediaService.LoadAllFonts().subscribe(result => {
            if (result) {
                let fontClasses = [];
                if (!result.FontNames || result.FontNames.length == 0) {
                    fontClasses = null;
                }
                //fontClasses.cssRules = new CSSRuleList();
                result.FontNames.forEach(font => {
                    let customFont = new FontFace(font, 'url(' + result.URL + font + '.ttf' + ')');
                    fontClasses.push('.ql-font-' + font + ' { font-family:"' + font + '"}');
                    fontClasses.push('.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="' + font + '"]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="' + font + '"]::before { content: "' + font + '"; font-family: "' + font + '"; padding-right: 20px; }')
                    customFont.load().then((res) => {
                        const fonts: any = document['fonts'];
                        fonts.add(res);
                    }).catch((error) => {
                        console.log(error)
                    });
                });
                if (fontClasses != null) {
                    ThemeHelper.AppendCSS(fontClasses);
                }
                MediaService.FontURL.next(result.URL);
                MediaService.CustomFontList.next(result.FontNames);
            } else {
                console.log('No fonts loaded.');
            }
            this.LoadProfilePicURL();
        }, err => {
            console.log('Error while loading fonts: ' + err);
            this.LoadProfilePicURL();
        });
    }

    LoadProfilePicURL() {
        this.mediaService.GetProfilePicURL().subscribe(result => {
            if (result) {
                MediaService.ProfilePicURL.next(result);
            }
            //#region Navigation
            this.LoadNavigationItems();
            //#endregion
        }, err => {
            //#region Navigation
            console.log('Error while loading profile pic url: ' + err);
            this.LoadNavigationItems();
            //#endregion
        });
    }

    LoadNavigationItems() {
        SideNavService.Reload.subscribe(key => {
            const actStruct = SideNavService.SelectedNavigationStructure.getValue();
            if (actStruct === key) {
                this.setNavigation(key);
            }
        });
        CacheService.NavigationPermissionChanged.subscribe(() => {
            this.updateNavigationPermissions();
        });
        CacheService.ContentPermissionChanged.subscribe(key => {
            PermissionHelper.OnContentPermissionChanged(key);
        });
        PermissionHelper.Impersonate.subscribe(() => {
            if (ClientHelper.StartUpCompleted.getValue()) {
                this.updateNavigationPermissions();
            }
        });
        PermissionHelper.GetNavigationPermissions().then(() => {
            this.checkNavigationStruct().then(() => {
                if (this.User) {
                    PermissionHelper.GetNavigationPermissions().then(() => {
                        this.Startup = false;
                        LayoutService.Loading.next(false);
                        ClientHelper.StartUpCompleted.next(true);
                    });
                } else {
                    this.Startup = false;
                    LayoutService.Loading.next(false);
                    ClientHelper.StartUpCompleted.next(true);
                }
            });
        });
    }

    ngOnDestroy(): void {
        (this.unlisten) && this.unlisten();
        this._dirChangeSubscription.unsubscribe();
    }
    //#endregion
    ContentStyle;
    ScreenSizes = [
        {
            key: "sm",
            maxwidth: 768
        },
        {
            key: "md",
            minwidth: 768,
            maxwidth: 992
        },
        {
            key: "lg",
            minwidth: 992,
            maxwidth: 1200
        },
        {
            key: "xl",
            minwidth: 1200
        }
    ]
    ActiveSize;
    onResized(event: ResizedEvent) {
        if (event && event.newRect) {
            this.ContentWidth = event.newRect.width;
            let size = this.ScreenSizes.find((item) => (((item.minwidth < this.ContentWidth) || item.minwidth == null) && ((item.maxwidth > this.ContentWidth) || item.maxwidth == null)));
            if (size) {
                if (size.key != this.ActiveSize) {
                    this.ActiveSize = size.key;
                    NavigationService.ScreenSize.next(this.ActiveSize);
                }
            }

            if (this.ContentWidth != this.LastWidth) {
                this.LastWidth = this.ContentWidth;
                LayoutService.RootSize.next({ Width: this.ContentWidth, Height: this.Height });
                this.checkLoginLayoutSize();
            }

            this.SetContentStyle();
        }
    }
    SetContentStyle() {
        const result = {};
        if (this.ContentWidth && this.Layout) {
            result['max-width'] = this.ContentWidth + 'px';
            result['margin-left'] = 'auto';
            result['margin-right'] = 'auto';
        } else {
            result['width'] = '100%';
        }
        result['height'] = '100%';
        this.ContentStyle = result;
    }
    setNavigation(key) {
        if (key) {
            CacheService.ReadNavigation(key).then(x => {
                const navigation = NavigationHelper.BuildNavigation(x);
                SideNavService.SelectedNavigation.next(navigation);
                this.cdRef.detectChanges();
            });
        } else {
            SideNavService.SelectedNavigation.next({ NavigationItems: [] });
            this.cdRef.detectChanges();
        }
    }
    private generatePhrase(n: number): string {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        const startIndex = (n - 1) % 26;
        const firstPart = alphabet.slice(startIndex);
        const secondPart = alphabet.slice(0, 16 - firstPart.length);

        return (firstPart + secondPart).slice(0, 16);
    }
    private encryptAPIKey(apiKey: string): string {
        const secretKey = this.generatePhrase(this.AppConfig["Intervals"]);
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(16);

        const encrypted = CryptoJS.AES.encrypt(apiKey, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        // Combine IV and encrypted data (Base64 encode both)
        const result = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
        return result;
    }
    private setShowLoginVal(val: boolean = true, callingAgain: boolean = false) {
        this.ShowLogin = val;
        this.cdRef.detectChanges();
        return;
    }
    ShowLogin: boolean = true;
    private setShowLogin(x) {
        const currentUrl = window.location.pathname;
        //check if landing page Login Required is set to false in main navigation
        if (x !== null) {
            // console.log("nav nodesss ---> ", this.NavigationNodes)
            let currentUrlParts = currentUrl?.toLowerCase().split("/").slice(1, currentUrl.length);
            if (currentUrlParts.length > 1) {
                if (x["LoginRequired"] == false) {
                    //Check if landing page matches with current url
                    let navURL = "/" + x["_key"]?.toLowerCase() + x["LandingPage"]?.toLowerCase();
                    if (navURL == currentUrl?.toLowerCase()) {
                        this.setShowLoginVal(false);
                        return;
                    }
                }
                if (this.NavigationNodes) {
                    let publicNode = this.NavigationNodes.find((res) => {
                        let url = "/" + res["StructureKey"]?.toLowerCase() + res["Url"]?.toLowerCase();
                        if (url == currentUrl?.toLowerCase() && res["LoginRequired"] == false) {
                            return res
                        }
                    })
                    if (publicNode) {
                        this.setShowLoginVal(false);
                        return;
                    }
                    else {
                        this.setShowLoginVal(true);
                        return;
                    }
                }
                this.setShowLoginVal(true);
                return;
                //It should be matched with nodes as well as landing page
            }
            else if (currentUrlParts.length == 1) {
                //It should be matched with landing page only
                if (x["LoginRequired"] == false) {
                    let navURL = "/" + x["_key"]?.toLowerCase();
                    if (navURL == currentUrl?.toLowerCase()) {
                        this.setShowLoginVal(false);
                        return;
                    }
                }
                else {
                    this.setShowLoginVal(true);
                    return;
                }
            }
            else {
                this.setShowLoginVal(true);
                return;
            }

            // if (x["LoginRequired"] == false) {
            //     let currentUrlParts = currentUrl.split("/").slice(1, currentUrl.length);
            //     let navURL = "";
            //     if (currentUrlParts.length > 1) {
            //         navURL = "/" + x["_key"]?.toLowerCase() + x["LandingPage"]?.toLowerCase();
            //     }
            //     else if (currentUrlParts.length == 1) {
            //         navURL = "/" + x["_key"]?.toLowerCase();
            //     }
            //     //Currentl url is same as login page
            //     if (navURL == currentUrl?.toLowerCase()) {
            //         this.ShowLogin = false;
            //         this.isPublicNav = true;
            //         this.cdRef.detectChanges();
            //     }
            // }
        }
        else {
            this.setShowLoginVal(true, true);
            return;
        }
    }
    NavigationStructures;
    SelectedNavigationStructure;
    NavigationNodes;
    private checkNavigationStruct() {
        const promise = new Promise(resolve => {
            if (this.ActiveUrl) {
                const splits = this.ActiveUrl.split('/');
                if (splits.length > 1) {
                    const structKey = splits[1];
                    const actStruct = SideNavService.SelectedNavigationStructure.getValue();
                    if (structKey) {
                        let internalURL = '';
                        for (let i = 2; i < splits.length; i++) {
                            internalURL += '/' + splits[i];
                        }
                        const queryIndex = internalURL.indexOf('?');
                        if (queryIndex > -1) {
                            internalURL = internalURL.substring(0, queryIndex);
                        }
                        const toLower = structKey.toLowerCase();
                        if (actStruct && actStruct.toLowerCase() === toLower) {
                            this.metaService.GetNavigationStructureByKey(actStruct).subscribe(nav => {
                                if (nav) {
                                    this.SelectedNavigationStructure = nav;
                                }
                                this.metaService.GetNavigationNodesForStructure(actStruct).subscribe(nodes => {
                                    if (nodes.length) {
                                        this.NavigationNodes = nodes;
                                    }
                                    this.setShowLogin(nav)
                                })
                            })
                            SideNavService.InternalURL.next(internalURL);
                            resolve(true);
                        } else {
                            this.metaService.GetNavigationStructures().subscribe(nsList => {
                                this.NavigationStructures = nsList;
                                const ns = nsList.find(x => x.Caption.toLowerCase() === toLower);
                                if (ns) {
                                    this.metaService.LoadNavigationStructureByKey(ns.Caption).subscribe(x => {
                                        this.SelectedNavigationStructure = x;
                                        this.metaService.GetNavigationNodesForStructure(ns.Caption).subscribe(nodes => {
                                            this.NavigationNodes = nodes;
                                            this.setShowLogin(x);
                                            this.updateSideNav(x, internalURL);
                                        })
                                        this.setShowLogin(x);
                                        this.updateSideNav(x, internalURL);
                                        resolve(true);
                                    });
                                } else {
                                    this.updateSideNav(null, '');
                                    resolve(true);
                                }
                            });
                        }
                    } else {
                        this.metaService.GetDefaultNavigationStructure().subscribe(x => {
                            if (x) {
                                if (actStruct !== x._key) {
                                    this.updateSideNav(x, '');
                                }
                                let url = x._key;
                                if (x.LandingPage) {
                                    url += x.LandingPage;
                                }
                                this.router.navigateByUrl(url);
                                if (!ClientHelper.StartUpCompleted.getValue()) {
                                    this.checkNavigationStruct().then(() => {
                                        resolve(true);
                                    });
                                }
                            } else {
                                this.updateSideNav(null, '');
                                resolve(true);
                            }
                        });
                    }
                    return;
                }
            }
            this.updateSideNav(null, '');
            resolve(true);
        });
        return promise;
    }

    ActiveNavigation;
    DefaultLogin = NavigationHelper.GetDefaultLoginLayout();
    DefaultBaseLayout = NavigationHelper.GetDefaultBaseLayout();
    LoginLayout;
    ActiveLoginLayout;
    ActiveTheme;
    updateSideNav(sideNav, internalURL) {
        this.ActiveNavigation = sideNav;
        this.CheckTheme(false);
        if (sideNav) {
            SideNavService.SelectedNavigationStructure.next(sideNav._key);
            if (sideNav.BaseLayout) {
                let Elements = []
                if (sideNav._key === 'default') {
                    Elements = sideNav.BaseLayout.Elements.filter(x => (x.Name !== 'Header' && x.Name !== 'Body'));
                    let bodyElement = sideNav.BaseLayout.Elements.find(x => x.Name === 'Body');
                    bodyElement._Row = 1;
                    sideNav.BaseLayout.Elements = [bodyElement, ...Elements];
                    sideNav.BaseLayout._rowDefinitions = sideNav.BaseLayout._rowDefinitions.slice(1)
                    sideNav.BaseLayout.RowDefinitions = sideNav.BaseLayout.RowDefinitions.slice(1)
                }
                LayoutService.BaseLayout.next(plainToClass(Layout, sideNav.BaseLayout));
            } else {
                if (this.ShowLogin == false && !this.User) {
                    // Elements = this.DefaultBaseLayout.Elements.filter(x => (x._Name !== 'Header' && x._Name !== 'Content'));
                    // let bodyElement = this.DefaultBaseLayout.Elements.find(x => x._Name === 'Content');
                    // bodyElement.Row = 1;
                    // this.DefaultBaseLayout.Elements = [bodyElement, ...Elements];
                    // this.DefaultBaseLayout["RowDefinitions"] = this.DefaultBaseLayout["RowDefinitions"].slice(1);
                    let HeaderElement = this.DefaultBaseLayout.Elements.find((x) => x._Name == "Header")
                    if (HeaderElement) {
                        //Remove the user avatar from header only if no user is logged in
                        let updatedHeaderElements = HeaderElement["Elements"].filter((elem) => elem._Name !== "usermenu_1")
                        HeaderElement["Elements"] = updatedHeaderElements;
                        HeaderElement["ColumnDefinitions"] = HeaderElement["ColumnDefinitions"].slice(0, HeaderElement["ColumnDefinitions"].length - 1)
                    }
                    // this.DefaultBaseLayout.RowDefinitions = sideNav.BaseLayout.RowDefinitions.slice(1)
                }
                LayoutService.BaseLayout.next(this.DefaultBaseLayout);
            }
            let oldManifest = document.getElementById('manifest');
            if (oldManifest) {
                document.head.removeChild(oldManifest);
            }
            if (sideNav.PWAIcon) {
                let absurl = location.origin + "/" + sideNav._key;
                let iconUrl = sideNav.PWAIcon;
                let manifest = {
                    name: sideNav._key,
                    short_name: sideNav._key,
                    background_color: "#fafafa",
                    display: "standalone",
                    scope: absurl,
                    start_url: absurl,
                    theme_color: "#1976d2",
                    icons: [
                        {
                            "src": iconUrl,
                            "sizes": "72x72",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "96x96",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "128x128",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "144x144",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "152x152",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "192x192",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "384x384",
                            "type": "image/png",
                            "purpose": "maskable any"
                        },
                        {
                            "src": iconUrl,
                            "sizes": "512x512",
                            "type": "image/png",
                            "purpose": "maskable any"
                        }
                    ]
                };
                let content = encodeURIComponent(JSON.stringify(manifest));
                let url = "data:application/manifest+json," + content;
                let element = document.createElement('link');
                element.id = 'manifest';
                element.rel = 'manifest';
                element.href = url;
                document.head.appendChild(element);
            } else {
                let element = document.createElement('link');
                element.id = 'manifest';
                element.rel = 'manifest';
                element.href = 'manifest.webmanifest';
                document.head.appendChild(element);
            }
            //#region FavIcon
            let link = document.createElement('link'),
                oldLink = document.getElementById('favicon');
            link.id = 'favicon';
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            if (sideNav.FAVIcon) {
                link.rel = 'shortcut icon';
                link.href = sideNav.FAVIcon;
            } else {
                link.rel = 'icon';
                link.href = 'favicon.ico';
            }
            document.head.appendChild(link);
            //#endregion
            if (sideNav.CSSFiles) {
                ThemeHelper.LoadCSS(sideNav.CSSFiles)
            }
            if (sideNav.LoginLayout) {
                this.LoginLayout = plainToClass(Layout, sideNav.LoginLayout);
            } else {
                this.LoginLayout = null;
            }
            SideNavService.InternalURL.next(internalURL);
            this.setNavigation(sideNav._key);
            const permissions = PermissionHelper.ActiveNavigationPermissions.getValue();
            this.NoStructurePermission = permissions && permissions[sideNav._Id] === true;
            NotificationHelper.IgnoreErrorMessages = sideNav.IgnoreErrorMessages == true;
        } else {
            SideNavService.SelectedNavigationStructure.next(null);
            LayoutService.BaseLayout.next(null);
            this.LoginLayout = null;
            SideNavService.InternalURL.next('');
            this.setNavigation(null);
            this.NoStructurePermission = false;
            NotificationHelper.IgnoreErrorMessages = false;
        }
        this.checkLoginLayoutSize();
    }
    checkLoginLayoutSize() {
        let active = null;
        if (this.LoginLayout) {
            active = this.LoginLayout;
            const size = LayoutService.RootSize.getValue();
            if (size && active.Resolutions) {
                const sizeLayout = active.Resolutions.find((value) => size.Width > value.FromWidth && size.Width <= value.ToWidth);
                if (sizeLayout) {
                    active = sizeLayout;
                }
            }
        }
        this.ActiveLoginLayout = active;
    }
    CheckTheme(forceUpdate) {
        if (this.ActiveNavigation) {
            if (this.ActiveNavigation.DefaultTheme) {
                if (!ThemeHelper.AcitveTheme || ((!(this.ActiveNavigation.AvailableThemes && this.ActiveNavigation.AvailableThemes.length > 0 && ThemeHelper.AcitveTheme && this.ActiveNavigation.AvailableThemes.indexOf(ThemeHelper.AcitveTheme['SID']) > -1)) || forceUpdate)) {
                    this.SetTheme(this.ActiveNavigation.DefaultTheme);
                }
            } else {
                this.SetDefaultTheme();
            }
        } else {
            ThemeHelper.SetTheme(null);
            this.ActiveTheme = ThemeHelper.AcitveTheme;
        }
    }
    SetTheme(ID) {
        this.ActiveTheme = null;
        if (ID) {
            this.metaService.GetTheme(ID).subscribe((theme) => {
                // localStorage.setItem('themeIDs', JSON.stringify({SID: theme.SID,Version: theme.Version}));
                if (theme) {
                    theme = plainToClass(Theme, theme);
                    ThemeHelper.SetTheme(theme);
                    this.ActiveTheme = theme;
                } else {
                    this.SetDefaultTheme();
                }
            });
        } else {
            this.SetDefaultTheme();
        }
    }
    SetDefaultTheme() {
        this.metaService.GetDefaultTheme().subscribe((theme) => {
            if (theme) {
                theme = plainToClass(Theme, theme);
                ThemeHelper.SetTheme(theme);
            } else {
                ThemeHelper.SetTheme(null);
            }
            this.ActiveTheme = ThemeHelper.AcitveTheme;
        });
    }
    updateNavigationPermissions() {
        PermissionHelper.GetNavigationPermissions().then(() => {
            const actStruct = SideNavService.SelectedNavigationStructure.getValue();
            const permissions = PermissionHelper.ActiveNavigationPermissions.getValue();
            if (permissions && actStruct) {
                CacheService.ReadNavigationStructure(actStruct).then(ns => {
                    this.NoStructurePermission = permissions[ns._Id] === true;
                    this.cdRef.detectChanges();
                });
            }
            this.setNavigation(actStruct);
        });
    }
    online = true;
    ChangeOnlineStatus() {
        this.online = !this.online;
        NetworkConnection.Online.next(this.online);
    }
    loadGoogleAnalytics(trackingID: string): void {

        let gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

        let gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
    }
    static delay(ms) {
        return interval(ms);
    }
}
