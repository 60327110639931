import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitType } from '../../../models/enums/unittype.enum';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'unitselection',
    templateUrl: './unitselection.theme.control.html',
    styleUrls: ['./unitselection.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitSelectionControl extends BaseThemeControl {

    ClearButtonIsVisibile = false;

    
    //#region RemoveTypes
    RemoveTypesValue = [UnitType.fr, UnitType.Rest];

    @Input()
    get RemoveTypes() {
        return this.RemoveTypesValue;
    }
    set RemoveTypes(val) {
        this.RemoveTypesValue = val;
        this.RemoveTypesChange.emit(this.RemoveTypesValue);
    }

    @Output() RemoveTypesChange = new EventEmitter<any>();
    //#endregion
    //#region UseLock
    UseLockValue = false;
    @Input()
    get UseLock() {
        return this.UseLockValue;
    }
    set UseLock(val) {
        this.UseLockValue = val;
        this.UseLockChange.emit(this.UseLockValue);
        this.cdRef.detectChanges();
    }
    @Output() UseLockChange = new EventEmitter<any>();
    //#endregion
    //#region Icon
    IconValue: string;
    @Input()
    get Icon(): string {
        return this.IconValue;
    }
    set Icon(val: string) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
    }

    @Output() IconChange = new EventEmitter<string>();
    //#endregion
    //#region Min
    MinValue: string;
    @Input()
    get Min(): string {
        return this.MinValue;
    }
    set Min(val: string) {
        this.MinValue = val;
        this.MinChange.emit(this.MinValue);
    }

    @Output() MinChange = new EventEmitter<string>();
    //#endregion
    //#region Max
    MaxValue: string;
    @Input()
    get Max(): string {
        return this.MaxValue;
    }
    set Max(val: string) {
        this.MaxValue = val;
        this.MaxChange.emit(this.MaxValue);
    }

    @Output() MaxChange = new EventEmitter<string>();
    //#endregion
    //#region Rotate
    RotateValue: string='0';
    @Input()
    get Rotate(): string {
        return this.RotateValue;
    }
    set Rotate(val: string) {
        this.RotateValue = val;
        this.RotateChange.emit(this.RotateValue);
    }

    @Output() RotateChange = new EventEmitter<string>();
    //#endregion

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

   

    selectionChanged() {
        this.onStyleChanged();
    }

   
    clearFilter() {
        this.DataSourceInternal.Value = null;
        this.DataSourceInternal.Type = UnitType.Pixel;

        this.cdRef.detectChanges();
        this.onStyleChanged();
    }

}
