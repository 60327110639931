import { Observable } from 'rxjs';
import { InjectorHelper } from '../helpers/injector.helper';
import { CapsuleService } from '../services/capsule.service';

// @dynamic
export class CapsuleHelper {
    private static CapsuleTags: string[];
    private static _Loaded = false;
    private static _LoadProcess: Observable<any>;

    static GetCapsuleTags(): Promise<string[]> {
        return new Promise((resolve, reject) => {
            if (CapsuleHelper._Loaded) {
                resolve(CapsuleHelper.CapsuleTags);
            } else if (CapsuleHelper._LoadProcess) {
                CapsuleHelper._LoadProcess.subscribe(r => {
                    resolve(r);
                });
            } else {
                const service = InjectorHelper.InjectorInstance.get<CapsuleService>(CapsuleService);
                CapsuleHelper._LoadProcess = service.GetCapsuleTags();
                CapsuleHelper._LoadProcess.subscribe(r => {
                    CapsuleHelper._Loaded = true;
                    CapsuleHelper._LoadProcess = null;
                    CapsuleHelper.CapsuleTags = r;
                    resolve(r);
                });
            }
        });
    }
}
