import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { RestService } from '../../../../../services/rest.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../../../base.list.settings';
import { RestObject } from '../../../models/rest.object';
import { RestObjectDetailComponent } from './rest.objects.detail';

@Component({
    selector: 'rest-objects',
    templateUrl: '../../../../base.list.settings.html'
})
export class RestObjectsComponent extends BaseListSettings{

    constructor(private _service: RestService, private _translate: TranslateService, factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this._service.loadRestObjects(this.InitArgs.SID).subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r => list.push({
                    Caption: r.Caption,
                    ID: r.Value
                }));
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.loadRestObject(data).subscribe(r => {
            if (r) {
                this.setSelectedItem(new RestObject(r));
            }
        });
    }

    getNewItem() {
        const retVal = new RestObject();
        retVal.Name = this._translate.instant('@@Neue Objekt');
        retVal.RestID = this.InitArgs.SID;
        retVal.Fields = [];
        return retVal;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Restobjekt \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Restobjekt \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restobjekt loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._service.remove(data).subscribe(() => handler());
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Restobjekt \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restobjekt speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        this._service.saveRestobject(item).subscribe(r => {
            if (r)
                handler(new RestObject(r), (r as any).SID, r.Name);
        });
    }

    getContentType() {
        return RestObjectDetailComponent;
    }
}