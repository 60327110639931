import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { StepperOrientation } from '../../../models/enums/stepperorientation.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-stepper',
    templateUrl: 'stepper.control.html',
    styleUrls: ['stepper.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperControl extends IBaseComponent {
    public static Type = 'stepper';
    public static Default = { Caption: '@@Stepper', Type: 'stepper',Layout: {
        _Editable: true,
        Elements: [
            (() => {
                const elem = new LayoutElement();
                    elem.ContentType = ContentType.Grid;
                    elem.ElementType = 'grid';
                    elem.Name = 'Step 1'
                    return elem;
        })()
        ],
        Width: { Type: 0, Value: 400 },
        Height: { Type: 0, Value: 200 },
    } }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('stepchanged');
    }

    StepChanged(event) {
        const stepObject = { selectedIndex: event.selectedIndex, previouslySelectedIndex: event.previouslySelectedIndex};
        this.triggerEvent('stepchanged', stepObject);
    }

    ControlInitialized() {
        this.cdRef.detectChanges();
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
        if (this.LayoutElementValue) {
            const orientation = this.LayoutElementValue.orientation;
            if (orientation !== StepperOrientation.Horizontal || orientation !== StepperOrientation.Vertical) {
                this.LayoutElementValue.orientation = StepperOrientation.Horizontal;
            }
        }
    }
}
export class StepperPanel extends BasePanel {
    static override SIDS = ['d9f539bc-69f9-432c-b6f4-4492fcf26544']
    static override ElementType = 'stepper'
    static InitPanel() {
        this.InitSelectedItem();
        this.InitSelectedLayout();
        
        PROPERTYGROUPS.push({
            SID:'d9f539bc-69f9-432c-b6f4-4492fcf26544',
            ID: 'stepper',
            Caption: '@@Stepper',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'stepper';
            }
        })
        PROPERTIES.push({
            ID: "orientation",
            Parent: "stepper",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Orientation",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Vertical', Value: 0 },
                    { Caption: 'Horizontal', Value: 1 }
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value'
            }
        });
        PROPERTIES.push({
            ID: "Elements",
            Parent: "stepper",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Name",
                    Parent: "stepper",
                    Content: new ComponentPortal(TranslationTextBoxThemeControl),
                    Label: "@@Titel",
                    InitArgs: {
                        DataType: 'string'
                    }
                },
                {
                    ID: "ContentType",
                    Parent: "stepper",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@ContentType",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        EnumSource: ContentType,
                        Action: (ev) => {
                            ev.SelectedItem.ElementType = ContentType[ev.SelectedItem.ContentType].toLowerCase();
                        }
                    }
                }
            ],
            InitArgs: {
                DisplayMemberPath: 'Name',
                GenerateNewItem: () => {
                    const elem = new LayoutElement();
                    elem.ContentType = ContentType.Grid;
                    elem.ElementType = 'grid';
                    elem.Name = LayoutHelper.GetNextElementNameByPrefix(StepperPanel.SelectedLayout, StepperPanel.SelectedItem.Name + '_tab');
                    return elem;
                }
            }
        });
    }
}