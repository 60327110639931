import Dexie from 'dexie';
import { from, Observable } from 'rxjs';
import { Comparer } from '../models/enums/comparer.enum';
import { Concat } from '../models/enums/contact.enum';
import { NotificationLevel } from '../models/enums/notificationlevel.enum';
import { Order } from '../models/enums/order.enum';
import { SyncStatus } from '../models/enums/syncstatus.enum';
import { RequestColumn } from '../models/rest/requestcolumn';
import { RequestFilter } from '../models/rest/requestfilter';
import { RequestOptions } from '../models/rest/requestoptions';
import { TranslatedString } from '../models/translatedstring.model';
import { DynamicDataService } from '../services/dynamicdata.service';
import { OfflineService } from '../services/offline.service';
import { StandardRequestBase } from '../services/request-base';
//import { UsersService } from '../services/users.service';
import { WebsocketService } from '../services/websocket.service';
import { NetworkConnection } from './network.helpers';
import { NotificationHelper } from './notification.helper';

// @dynamic
export class OfflineHelper {
    //#region Properties
    private static User;
    private static Settings;
    private static WebSocketActive = false;
    private static offlineService: OfflineService;
    private static stdRequestBase: StandardRequestBase;
    private static dynamicDataService: DynamicDataService;
    private static Subscriptions = {};
    private static db;
    private static Online = true;
    public static Cache = {};
    private static ChangesValue;
    public static get Changes() {
        return this.ChangesValue;
    }
    public static set Changes(value) {
        this.ChangesValue = value;
    }
    public static LoadChanges() {
        let result = localStorage.getItem('offline_changes');
        if (result) {
            this.ChangesValue = JSON.parse(result);
        } else {
            this.ChangesValue = null;
        }
    }
    public static SaveChanges() {

        if (this.ChangesValue) {
            let ccos = Object.keys(this.ChangesValue);
            if (ccos && ccos.length > 0) {
                for (let i = ccos.length - 1; i >= 0; i--) {
                    let entries = Object.keys(this.ChangesValue[ccos[i]].Entries);
                    if (entries && entries.length >0) {
                        for (let j = 0; j < entries.length; j++) {
                            let entry = this.ChangesValue[ccos[i]].Entries[entries[j]];
                            if (entry.Status == 2) {
                                delete this.ChangesValue[ccos[i]].Entries[entries[j]];
                            }
                        }
                    }
                    entries = Object.keys(this.ChangesValue[ccos[i]].Entries);
                    if (!entries || entries.length == 0) {
                        delete this.ChangesValue[ccos[i]];
                    }
                }
            }

            ccos = Object.keys(this.ChangesValue);
            if (!ccos || ccos.length == 0) {
                localStorage.removeItem('offline_changes');
            } else {
                localStorage.setItem('offline_changes', JSON.stringify(this.ChangesValue));
            }
        }
    }
    private static get Version() {
        if (localStorage.getItem('db_version')) {
            return parseInt(localStorage.getItem('db_version'));
        }
        return null;
    }
    private static set Version(value:number) {
        localStorage.setItem('db_version', value+'');
    }

    private static get Schema() {
        if (localStorage.getItem('db_schema')) {
            return localStorage.getItem('db_schema');
        }
        return null;
    }
    private static set Schema(value: any) {
        localStorage.setItem('db_schema', value);
    }
    //#endregion
    //#region Workflow
    public static DeleteWorkflowData(key) {
        if (this.Settings != null && this.Settings.Offline && this.Settings.Offline.Active) {
            this.db['WorkflowOfflineStorage'].where('Key').equals(key).delete();
        } else {
            localStorage.removeItem('WorkflowOfflineStorage||' + key);
        }
    }
    public static SetWorkflowData(key, item) {
        if (item != null) {
            if (this.Settings != null && this.Settings.Offline && this.Settings.Offline.Active) {
                this.db['WorkflowOfflineStorage'].put({ Key: key, Value: JSON.stringify(item) });
            } else {
                localStorage.setItem('WorkflowOfflineStorage||' + key, JSON.stringify(item));
            }
        }
    }
    public static GetWorkflowData(key) {
        return new Promise((resolve) => {
            if (this.Settings != null && this.Settings.Offline && this.Settings.Offline.Active) {
                this.Query('WorkflowOfflineStorage', { Filters: [{ Name: 'Key', Operator: Comparer.Equal, Value: key }] }).subscribe((retVal) => {
                    if (retVal && retVal.length > 0) {
                        resolve(JSON.parse(retVal[0].Value));
                    } else {
                        resolve(null);
                    }
                });
            } else {
                let retVal = localStorage.getItem('WorkflowOfflineStorage||' + key);
                if (retVal) {
                    resolve(JSON.parse(retVal));
                } else {
                    resolve(null);
                }
            }
        });
    }
    //#endregion
    public static Initialize(service: OfflineService, stdRequestBase: StandardRequestBase, dynamicDataService: DynamicDataService, Login, Logout) {
        this.offlineService = service;
        this.stdRequestBase = stdRequestBase;
        this.dynamicDataService = dynamicDataService;

        this.User = localStorage.getItem('user');
        if (this.User) {
            this.User = JSON.parse(this.User);
        }

        let settings: any = localStorage.getItem("GeneralSettings");
        if (settings) {
            settings = JSON.parse(settings);
        }
        this.Settings = settings;

        this.Subscriptions['Login'] = Login.subscribe((user) => {
            this.User = user;
            this.PrepareOffline()
        });

        this.Subscriptions['Logout'] = Logout.subscribe(() => {
            if (this.User) {
                WebsocketService.Unsubscribe([{ Channel: this.User.SID + "||OfflineSync", Level: [0] }]);
                this.User = null;
            }
        });

        this.Subscriptions['Online'] = NetworkConnection.Online.subscribe((online) => {
            this.Online = online;
            if (online) {
                this.PrepareOffline();
            } 
        })

        this.Subscriptions['Active'] = WebsocketService.Active.subscribe((state) => {
            this.WebSocketActive = state;
            if (state) {
                this.PrepareOffline();
            }
        });

        this.Subscriptions['Message'] = WebsocketService.MessageReceived.subscribe((response) => {
            const parsed = JSON.parse(response);
            if (parsed.Level == NotificationLevel.System && parsed.Type == 'OfflineData') {
                if (parsed.Content) {
                    const notification = JSON.parse(parsed.Content);
                    if (notification.Data) {
                        const content = JSON.parse(notification.Data);
                        if (notification.Type == "SyncDate") {
                            localStorage.setItem('OfflineDate', new Date(content).toJSON());
                        } else {
                            if (Array.isArray(content)) {
                                content.forEach((item) => {
                                    this.db[notification.Type].put(item);
                                })
                            } else {
                                this.db[notification.Type].put(content);
                            }
                        }
                    }
                }
            } 
        });
        this.CreateDB();
    }
    private static async PrepareOffline() {
        if (this.User && this.WebSocketActive) {
            if (this.Settings != null && this.Settings.Offline && this.Settings.Offline.Active) {
                try {
                    await this.CreateDB();
                    WebsocketService.Subscribe([{ Channel: this.User.SID + "||OfflineSync", Level: [0] }]);
                    let date = new Date('1970-01-01T00:00:00Z');
                    if (localStorage.getItem('OfflineDate')) {
                        date = new Date(localStorage.getItem('OfflineDate'));
                    }
                    if (this.Online) {
                        this.StartServerSync();
                        this.offlineService.StartClientSync(date.toJSON()).subscribe();
                    }
                } catch(ex) {
                    console.log(ex);
                }
            }
        }
    }

    //#region Helper
    public static SetRestFilter(type, body) {
        let query = this.db[type];
        if (body) {
            if (body.Sort && body.Sort.length > 0) {
                body.Sort.forEach((sort) => {
                    if (sort.Order == Order.ASC) {
                        query = query.orderBy(sort.Name);
                    } else {
                        query = query.orderBy(sort.Name).reverse();
                    }
                })
            }
            if (body.Filters && body.Filters.length > 0) {
                query = query.filter((value) => {
                    let retVal = this.FillFilter(body.Filters, value, Concat.And);
                    return retVal;
                });
            }
            if (body.StartRow && body.EndRow) {
                query = query.offset(body.StartRow).limit(body.EndRow - body.StartRow);
            }
        }
        return query;
    }
    private static FillFilter(filters, value, concat) {
        let retVal = concat == Concat.And;
        for (let i = 0; i < filters.length; i++) {
            let filter = filters[i];

            if (filter.Filters != null && filter.Filters.length > 0) {
                retVal = this.FillFilter(filter.Filters, value, filter.Concat);
            }

            if (retVal == false && concat == Concat.And) {
                break;
            }
            var temp = false;
            switch (filter.Operator) {
                case Comparer.Equal:
                    temp = value[filter.Name] == filter.Value;
                    break;
                case Comparer.NotEqual:
                    temp = value[filter.Name] != filter.Value;
                    break;
                case Comparer.Between: break;
                case Comparer.NotBetween: break;
                case Comparer.In:
                    temp = filter.Value.indexOf(value[filter.Name]) > -1;
                    break;
                case Comparer.NotIn:
                    temp = filter.Value.indexOf(value[filter.Name]) == -1;
                    break;
                case Comparer.Greater:
                    temp = value[filter.Name] > filter.Value;
                    break;
                case Comparer.GreaterEqual:
                    temp = value[filter.Name] >= filter.Value;
                    break;
                case Comparer.Smaller:
                    temp = value[filter.Name] < filter.Value;
                    break;
                case Comparer.SmallerEqual:
                    temp = value[filter.Name] <= filter.Value;
                    break;
                case Comparer.Like:
                    temp = value[filter.Name].indexOf(filter.Value) > -1;
                    break;
                case Comparer.NotLike:
                    temp = value[filter.Name].indexOf(filter.Value) == -1;
                    break;
            }
            if (concat == Concat.Or) {
                if (temp) {
                    retVal = temp;
                }
            } else {
                retVal = temp;
            }
        }
        return retVal;
    }

    private static async FillLine(Line, table) {
        if (table && table.Fields && Line) {
            for (let f = 0; f < table.Fields.length; f++) {
                let field = table.Fields[f];
                if (field.Type == 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                    if (field.IsShared && !field.IsReverse) {
                        if (Line[field.Name]) {
                            if (field.IsList) {
                                let sub = await OfflineHelper.GetDB()[field.AdvancedType].where('_Id').anyOf(Line[field.Name]).toArray();
                                if (sub && sub.length > 0) {
                                    Line[field.Name] = sub;
                                    for (let s = 0; s < sub.length; s++) {
                                        await this.FillLine(sub[s], field);
                                    }
                                }
                            } else {
                                let sub = await OfflineHelper.GetDB()[field.AdvancedType].where('_Id').equals(Line[field.Name]).toArray();
                                if (sub && sub.length > 0) {
                                    Line[field.Name] = sub[0];
                                    await this.FillLine(sub[0], field);
                                }
                            }
                        }
                    } else {
                        if (field.IsList) {
                            for (let s = 0; s < Line[field.Name].length; s++) {
                                await this.FillLine(Line[field.Name][s], field);
                            }
                        } else {
                            await this.FillLine(Line[field.Name], field);
                        }
                    }
                }
                if (field.Type == 'a0c232a9-4ab7-444c-a7e2-d23899a5673b') {
                    Line[field.Name] = TranslatedString.GetTranslation(Line[field.Name]);
                }
            }
        }
    }
    private static async FillObjectFields(table) {
        if (table && table.Fields) {
            for (let i = 0; i < table.Fields.length; i++) {
                let field = table.Fields[i];
                if (field.Type == 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                    let values = await OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].where('SID').equals(field.AdvancedType).toArray();
                    if (values && values.length > 0) {
                        let res = values[0];
                        if (res && res.Fields) {
                            field.Fields = values[0].Fields
                            await this.FillObjectFields(field);
                        }
                    }
                }
            }
        }
    }

    public static FillFields(table): Promise<any> {
        return new Promise((resolve, reject) => {
            let retVal = {
                Columns: [{
                    "AllowNulls": true,
                    "IsCaseSensitive": false,
                    "IsPrimaryKey": false,
                    "IsUnique": false,
                    "Length": 0,
                    "NumericPrecision": 0,
                    "NumericScale": 0,
                    "Name": '_Id',
                    "TableName": table.Schema + '.' + table.Name,
                    "Type": "System.Guid",
                    "ContainerFieldID": "00000000-0000-0000-0000-000000000000",
                    "Type2": "00000000-0000-0000-0000-000000000000",
                    "Type3": "00000000-0000-0000-0000-000000000000"
                }, {
                    "AllowNulls": true,
                    "IsCaseSensitive": false,
                    "IsPrimaryKey": false,
                    "IsUnique": false,
                    "Length": 0,
                    "NumericPrecision": 0,
                    "NumericScale": 0,
                    "Name": '_Version',
                    "TableName": table.Schema + '.' + table.Name,
                    "Type": "System.Guid",
                    "ContainerFieldID": "00000000-0000-0000-0000-000000000000",
                    "Type2": "00000000-0000-0000-0000-000000000000",
                    "Type3": "00000000-0000-0000-0000-000000000000"
                }, {
                    "AllowNulls": true,
                    "IsCaseSensitive": false,
                    "IsPrimaryKey": false,
                    "IsUnique": false,
                    "Length": 0,
                    "NumericPrecision": 0,
                    "NumericScale": 0,
                    "Name": '_Status',
                    "TableName": table.Schema + '.' + table.Name,
                    "Type": "System.Int32",
                    "ContainerFieldID": "00000000-0000-0000-0000-000000000000",
                    "Type2": "00000000-0000-0000-0000-000000000000",
                    "Type3": "00000000-0000-0000-0000-000000000000"
                }],
                IsView: false,
                Name: table.Name,
                Schema: "data",
                ContainerTableID: table.SID,
                IsCaseSensitive: false
            };
            if (table && table.Fields) {
                OfflineHelper.FillFieldsInternal(table.Fields, "", retVal).then((columns) => {
                    retVal.Columns.push(...columns);
                    resolve(retVal);
                });
            } else {
                resolve(retVal);
            }
        })
    }
    private static async FillFieldsInternal(Fields, concat, table): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            let retVal = [];

            for (let i = 0; i < Fields.length; i++) {
                let field = Fields[i];
                field.Name = concat + field.Name;

                let column = {
                    "AllowNulls": field.IsNullable,
                    "IsCaseSensitive": false,
                    "IsPrimaryKey": false,
                    "IsUnique": false,
                    "Length": 0,
                    "NumericPrecision": 0,
                    "NumericScale": 0,
                    "Name": field.Name,
                    "TableName": table.Schema + '.' + table.Name,
                    "Type": field.DataTyp,
                    "ContainerFieldID": field.ID,
                    "Type2": field.Type,
                    "Type3": field.AdvancedType
                }

                retVal.push(column);

                if (field.Type == 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                    let values = await OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].where('SID').equals(field.AdvancedType).toArray();
                    if (values && values.length > 0) {
                        let res = values[0];
                        if (res && res.Fields) {
                            retVal.push(...await this.FillFieldsInternal(res.Fields, field.Name + '.', table));
                        }
                    }
                }
            }
            resolve(retVal);
        });
    }

    public static async GenerateFlatTableData(table, filter) {
        return new Promise(async (resolve, reject) => {
            let retVal = [];
            if (!filter) {
                filter = new RequestOptions();
            }
            if (!filter.Columns || filter.Columns.length == 0) {
                let flatTableDesc = await this.FillFields(table);
                flatTableDesc.Columns.forEach((column) => {
                    filter.Columns = [];
                    let item = new RequestColumn();
                    item.IsCommaSeparated = true;
                    item.Name = column.Name
                    filter.Columns.push(item);
                });
            }
            await this.FillObjectFields(table);

            let objects = await this.Query(table.SID, filter).toPromise();
            if (objects) {
                for (let o = 0; o < objects.length; o++) {
                    let obj = objects[o];
                    await this.FillLine(obj, table);

                    let line = {};

                    filter.Columns.forEach((column) => {
                        let split = column.Name.split('.');
                        if (split && split.length == 1) {
                            line[column.Name] = obj[column.Name];
                        } else if (split && split.length > 1) {
                            let item = obj;
                            for (let i = 0; i < split.length; i++) {
                                let path = split[i];
                                if (item[path]) {
                                    if (Array.isArray(item[path])) {
                                        if (column.IsCommaSeparated) {
                                            let result = "";
                                            for (let e = 0; e < item[path].length; e++) {
                                                let sub = split[i + 1];
                                                let entry = item[path][e][sub];
                                                if (e > 0) {
                                                    result += ', ';
                                                }
                                                result += entry;
                                            }
                                            i += 1;
                                            if (Array.isArray(line)) {
                                                for (let l = 0; l < line.length; l++) {
                                                    line[l][column.Name] = result;
                                                }
                                            } else {
                                                line[column.Name] = result;
                                            }
                                        } else {
                                            let lines = [];

                                            for (let e = 0; e < item[path].length; e++) {
                                                if (Array.isArray(line)) {
                                                    line.forEach((l) => {
                                                        let temp = JSON.parse(JSON.stringify(l));
                                                        lines.push(temp)
                                                        let sub = split[i + 1];
                                                        temp[column.Name] = item[path][e][sub];
                                                    })
                                                } else {
                                                    let temp = JSON.parse(JSON.stringify(line));
                                                    lines.push(temp);
                                                    let sub = split[i + 1];
                                                    temp[column.Name] = item[path][e][sub];
                                                }
                                            }
                                            i += 1;
                                            line = lines;
                                        }
                                    } else {
                                        if (Array.isArray(line)) {
                                            for (let l = 0; l < line.length; l++) {
                                                line[l][column.Name] = item[path];
                                            }
                                        } else {
                                            line[column.Name] = item[path];
                                        }
                                        item = item[path];
                                    }
                                }
                            }
                        }

                    })
                    if (Array.isArray(line)) {
                        retVal.push(...line);
                    } else {
                        retVal.push(line);
                    }
                }
                resolve(retVal);
            } else {
                resolve(retVal);
            }
        });
    }
    //#endregion
    //#region Query
    public static Query(type, filter): Observable<any> {
        let result = []; 
        if (this.db[type]) {
            if (!filter) {
                filter = new RequestOptions();
            }
            if (!filter.Filters) {
                filter.Filters = [];
            }

            let fil = new RequestFilter();
            fil.Name = "_Status";
            fil.Operator = Comparer.NotEqual;
            fil.Value = 2;
            filter.Filters.unshift(fil);

            result = this.SetRestFilter(type, filter).toArray();
        }
        return from(result);
    }
    public static GetDB(): Observable<any> {
        return this.db;
    }
    //#endregion
    //#region DB
    private static async CreateDB() {
        if (this.User) {
            let schema = {
                'evidanza.App.Shared.Layouts.Layout': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Layouts.Template': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Layouts.Widget': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Layouts.Ribbon': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Layouts.ContextMenu': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Permissions.Layout': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Permissions.Navigation': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Data.StyleObject': 'SID, _changed, _created',
                'evidanza.App.Shared.Data.Theme': 'SID, Name',
                'evidanza.App.Shared.Data.CustomCSSVariable': 'SID, Name',
                'evidanza.App.Shared.Data.MetaTag': '_Id, _changed, _created, Name',
                //'evidanza.App.Shared.Output.Layout': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Data.DeploymentAction': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.UserSettings.UserSettings': 'ID, LayoutID, UserID, ItemID, Profile',
                'evidanza.App.Shared.UserSettings.UserSettingsProfile': 'ID, LayoutID, UserID, Name',
                'evidanza.App.Shared.SelfService.DataModel': 'SID, Name',
                'evidanza.App.Shared.SelfService.DataSource': 'SID, Name',
                'evidanza.App.Shared.Dynamic.ContainerClassObject': 'SID, Name',
                'evidanza.App.Shared.Dynamic.ContainerEnum': 'SID, Name',
                'evidanza.App.Shared.Layouts.NavigationStructure': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Layouts.NavigationNode': '_Id, _changed, _created, Name',
                'evidanza.App.Shared.Workflow.Data.WorkflowTemplate': 'SID, Name',
                'evidanza.Core.Service.Meta.ChangeDates': 'SID, Name',
                'evidanza.Core.Service.Media.MediaSource': 'SID, Name, Private, SystemUsage, NonEditable',
                'evidanza.App.Shared.SelfService.REST.IconList': 'URL',
                'evidanza.App.Shared.SelfService.REST.FontList': 'URL',
                'evidanza.Core.Base.Security.Role': 'SID, Name',
                'evidanza.Core.ServiceBase.Config.LanguageSettings': 'LCID, Selected',
                'evidanza.Core.ServiceBase.Config.Translation': 'LCID',
                'evidanza.App.Shared.Variable': '_Id, _changed, _created, _Name',
                'evidanza.App.Shared.CapsuleTag': 'ID, Name',
                'WorkflowOfflineStorage':'Key'
            };
            if (NetworkConnection.Online.getValue()) {
                const tableList = await this.stdRequestBase.executeGet('api/selfservice/model', 'GetOfflineContainers').toPromise()
                tableList.forEach((tab) => {
                    schema[tab.SID] = '_Id, _Version, _Status, _changed, _changedby, _created, _createdby';
                    if (tab && tab.Fields) {
                        tab.Fields.forEach((field) => {
                            schema[tab.SID] += ', ' + field.Name;
                        });
                    }
                });
            }
            if (NetworkConnection.Online.getValue() && (!this.Schema || this.Schema != JSON.stringify(schema))) {
                this.Schema = JSON.stringify(schema);

                if (!this.Version) {
                    this.Version = 1;
                } else {
                    this.Version++;
                }
            }

            this.db = new Dexie('offline_data');
            try {
                this.db.version(this.Version).stores(JSON.parse(this.Schema));
            } catch (ex) {

            }
        }
    }
    public static DeleteDB(): Promise<any> {
        localStorage.removeItem('db_version');
        localStorage.removeItem('OfflineDate');
        this.Version = 1;
        return this.db.delete();
    }
    public static GenerateDB() {
        this.CreateDB();
    }
    public static async UpdateChanges(containerid, item) {

        if (!this.Changes) {
            this.Changes = {};
        }
        if (!this.Changes[containerid]) {
            let CCOS = await this.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].where("SID").equals(containerid).toArray();
            let container = null;
            if (CCOS && CCOS.length > 0) {
                container = CCOS[0];
            }
            this.Changes[containerid] = {
                ContainerID: containerid,
                ContainerName: container.Name,
                Entries: {}
            }
        }
        this.Changes[containerid].Entries[item._Id] = {
            Item: item,
            Status: 0,
            ContainerID: containerid,
            ContainerName: this.Changes[containerid].ContainerName
        };
        this.SaveChanges();
    }
    //#endregion
    //#region Sync
    public static StartClientSync(date: Date) {
        if (date == null) {
            date = new Date('1970-01-01T00:00:00Z');
        }
        if (this.Online) {
            this.offlineService.StartClientSync(date.toJSON()).subscribe();
        }
    }
    public static SyncSingleDocument(containerid, item):Promise<any> {
        return new Promise((resolve, reject) => {
            this.offlineService.SyncDocument(containerid, item).subscribe((result) => {
                if (result && result['Success']) {
                    item.Status = SyncStatus.Success;
                }
                this.SaveChanges();
                resolve(result);
            });
        });
    }
    public static ForceSyncDocument(item, itempath): Promise<any> {
        return new Promise((resolve, reject) => {
            this.offlineService.ForceSyncDocument(item.ContainerID, item[itempath]).subscribe((result) => {
                if (result && result['Success']) {
                    item.Status = SyncStatus.Success;
                }
                this.SaveChanges();
                resolve(result);
            });
        });
    }
    public static async StartServerSync() {
        this.LoadChanges();
        if (this.Changes) {
            let Errors = [];
            let keys = Object.keys(this.Changes);
            for (let i = 0; i < keys.length; i++) {
                let ccoChanges = this.Changes[keys[i]];
                if (ccoChanges && ccoChanges.Entries) {
                    let docKeys = Object.keys(ccoChanges.Entries);
                    for (let j = 0; j < docKeys.length; j++) {
                        let doc = ccoChanges.Entries[docKeys[j]];
                        let retVal = await this.SyncSingleDocument(doc.ContainerID, doc.Item);
                        if (retVal && retVal.Success) {
                            doc.Status = 2;
                            delete ccoChanges.Entries[docKeys[j]];
                        } else {
                            doc.Status = 3;
                            if (retVal.Document) {
                                doc.Original = JSON.parse(retVal.Document);
                            }
                            Errors.push(doc);
                        }
                    }
                }
            }
            if (Errors && Errors.length > 0) {
                NotificationHelper.Error("@@Server synchronisation completed with errors!", "@@Sync Error");
            }
            this.SaveChanges();
        }
    }
    //#endregion
}
