import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { PROPERTIES } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'generic-menu-tab',
    templateUrl: './generic.menu.tab.html',
    styleUrls: ['./generic.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericMenuTab extends APropertyTab {
    IsGrid = false;
    //#region PropertyGroup
    PropertyGroupValue;
    @Input()
    get PropertyGroup() {
        return this.PropertyGroupValue;
    }
    set PropertyGroup(val) {
        this.PropertyGroupValue = val;
        this.Sections = null;
        this.Properties = null;
        this.RawProperties = null;
        if (val) {
            const secObj = {}
            if (val.PropertyGroup) {
                this.IsGrid = (val.PropertyGroup.Display == PropertyGroupDisplay.Grid);
                if (val.PropertyGroup.Sections) {
                    const sections = [];
                    val.PropertyGroup.Sections.forEach((section) => {
                        const sec = {
                            ...section,
                            Properties: []
                        };
                        sections.push(sec);
                        secObj[sec.ID] = sec;
                    });
                    this.Sections = sections;
                }
            }
            const rawProperties = [];
            const properties = [];
            PROPERTIES.forEach(v => {
                if (v.Parent == val.ID) {
                    const prop = {
                        ...v
                    };
                    rawProperties.push(prop);
                    if (v.Section == null) {
                        properties.push(prop);
                    } else {
                        const sec = secObj[v.Section];
                        if (sec) {
                            sec.Properties.push(prop);
                        }
                    }
                }
            });
            this.RawProperties = rawProperties;
            this.Properties = properties;
        }
        this.PropertyGroupChange.emit(this.PropertyGroupValue);
    }
    @Output() PropertyGroupChange = new EventEmitter<any>();
    //#endregion

    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.Properties) {
            this.CheckVisibility();
            this.Properties.forEach((item) => {
                this.ComponentAttached(null, item);
            });
            if (this.Sections && this.Sections.length > 0) {
                this.Sections.forEach((section) => {
                    if (section.Properties) {
                        section.Properties.forEach((item) => {
                            this.ComponentAttached(null, item);
                        })
                    }
                })
            }
        }
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.cdRef.detectChanges();
    }



    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Context
    ContextValue;
    @Input()
    get Context() {
        return this.ContextValue;
    }
    set Context(val) {
        this.ContextValue = val;
        this.ContextChange.emit(this.ContextValue);
    }

    @Output() ContextChange = new EventEmitter<any>();
    //#endregion

    Properties = null;
    RawProperties = null;
    Sections = null;

    ngOnInit() {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.LayoutChanged.subscribe(() => {
            this.CheckVisibility();
        }))
        this.Subscriptions.push(LayoutService.SelectedObject.subscribe((val) => {
            this.CheckVisibility();
        }))
    }

    CheckVisibility() {
        if (this.SelectedItemValue && this.RawProperties) {
            const secObj = {}
            if (this.Sections) {
                this.Sections.forEach(sec => {
                    sec.Properties = [];
                    secObj[sec.ID] = sec;
                });
            }
            const properties = [];
            this.RawProperties.forEach(v => {
                if (!v.CheckVisibility || v.CheckVisibility(this.SelectedItemValue, null, this.ContextValue)) {
                    if (v.Section == null) {
                        properties.push(v);
                    } else {
                        const sec = secObj[v.Section];
                        if (sec) {
                            sec.Properties.push(v);
                        }
                    }
                }
            });
            this.Properties = properties;
            this.cdRef.detectChanges();
        }
    }
    static CheckOnChange(menuItem) {
        menuItem.Visible = false;
    }
    SetBinding(item, SelectedItem) {
        if (SelectedItem) {
            item.instance.SelectedItem = SelectedItem;
            if (item.ID) {
                if (SelectedItem[item.ID] == null && item.Type != null) {
                    SelectedItem[item.ID] = new item.Type();
                }
                if (item.Type == null || typeof (item.Type) == 'string') {
                    item.instance.PathBinding = item.ID;
                } else {
                    item.instance.DataSource = SelectedItem[item.ID];
                }
            } else {
                item.instance.DataSource = SelectedItem;
            }
        }
    }
    ComponentAttached(event, item) {
        if (event && item) {
            item.instance = event.instance;
        }
        if (this.SelectedItem && item && item.instance) {
            let SelectedItem = this.SelectedItem;
            if (item.DataSourceSub) {
                item.DataSourceSub.unsubscribe();
            }
            if (item.DataSource) {
                item.DataSourceSub = item.DataSource.subscribe((value) => {
                    SelectedItem = value;
                    this.SetBinding(item, SelectedItem);
                })
            } else {
                this.SetBinding(item, SelectedItem);
            }
            if (item.InitArgs) {
                item.instance.SetProperties(item.InitArgs);
            }
            if (item.Properties) {
                item.instance.Properties = item.Properties;
            }
            if (!item.instance.ExternalSubscriptions) {
                item.instance.ExternalSubscriptions = {};
            }
            if (item.instance.StyleChanged) {
                if (item.instance.ExternalSubscriptions["StyleChanged"]) {
                    item.instance.ExternalSubscriptions["StyleChanged"].unsubscribe();
                }
                item.instance.ExternalSubscriptions["StyleChanged"] = item.instance.StyleChanged.subscribe((val) => {
                    this.CheckVisibility();
                    this.onStyleChanged(item.ID);
                });
            }
            this.cdRef.detectChanges();
        }
    }
}
