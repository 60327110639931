import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { LayoutService } from '../../../services/layout.service';
import { FilteredControlService } from 'src/app/services/dynamic.component.service';

@Component({
  selector: 'accordion-menu-control',
  templateUrl: './accordion.menu.control.html',
  styleUrls: ['./accordion.menu.control.css'],
})
export class AccordionMenuControl implements OnDestroy, OnInit {
  ExpandedState = {
    Layout: true,
    Form: true,
    Reportobjects: true,
    Reportcontrols: true,
    Data: true,
    Block: true,
    Media: true,
    Advanced: true,
    Templates: true,
    Widgets: true,
  };
  //#region Items
  ItemsValue = [];
  RawItems = [];
  TabsCount: {};
  
  activeIndexes = [];
  @Input()
  get Items() {
    return this.ItemsValue;
  }
  set Items(val) {
    this.RawItems = val;
    this.CheckGroupVisibiltiy().then(() => {
      this.ItemsValue = this.RawItems.filter((value) => value.Visible).sort((a, b) => {
        if (a.Index > b.Index) {
          return 1;
        }
        if (a.Index < b.Index) {
          return -1;
        }
        return 0;
      });
      this.ItemsChange.emit(val);
      this.cdRef.detectChanges();
    });
  }
  @Output() ItemsChange = new EventEmitter<any>();
  //#endregion

  @Input() _activeIndexes: number[] = [];



  onOpened(item) {
    item.Expanded = true;
  }

  onClosed(item) {
    item.Expanded = false;
  }
  SelectedItem;
  Subscriptions = {};
  RefreshItems() {
    if (this.RawItems) {
      this.CheckGroupVisibiltiy().then(() => {
        this.ItemsValue = this.RawItems.filter((value) => value.Visible).sort((a, b) => {
          if (a.Index > b.Index) {
            return 1;
          }
          if (a.Index < b.Index) {
            return -1;
          }
          return 0;
        });
        this.cdRef.detectChanges();
      });
    }
  }
  CheckGroupVisibiltiy() {
    return new Promise((resolve) => {
      if (this.RawItems && this.SelectedItem) {
        let asynccount = 0;
        let received = 0;
        this.RawItems.forEach((item) => {
          if (item && item.PropertyGroup) {
            let pg = item.PropertyGroup;
            if (pg.SID && this.ExpandedState[pg.SID] == null) {
              this.ExpandedState[pg.SID] = false;
            }
            if (pg.CheckVisibility) {
              item.Visible = pg.CheckVisibility(this.SelectedItem);
            }
            if (pg.CheckVisibilityAsync) {
              asynccount += 1;

              pg.CheckVisibilityAsync(this.SelectedItem).then((value) => {
                item.Visible = value;
                received += 1;
                if (asynccount == received) {
                  resolve(true);
                }
              });
            }
          }
        });
        if (asynccount == 0) {
          resolve(true);
        }
      }
    });
  }

  constructor(private cdRef: ChangeDetectorRef,private filteredItemsCountService: FilteredControlService,
              protected filteredControlService:FilteredControlService) {}
  ngOnInit(): void {
    this.Unsubscribe();
    this.Subscriptions['PropertyPanelsChanged'] = LayoutService.PropertyPanelsChanged.subscribe(() => {
      this.RefreshItems();
    });
    this.Subscriptions['refresh'] = LayoutHelper.RefreshMenuTabs.subscribe(() => {
      this.RefreshItems();
    });
    this.Subscriptions['selecteditem'] = LayoutService.SelectedItem.subscribe((item) => {
      this.SelectedItem = item;
      this.RefreshItems();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['Items']) {
      const _items = [...changes['Items'].currentValue];
      if (_items[0].SID == 'Form'&&this.activeIndexes.length <= 0) {
        this.openAccordionMenu(_items);
      }
    }

  }

  openAccordionMenu(_items) {
    if(_items && _items.length > 0) {
      this.activeIndexes = _items.map((_, index) => index);
      this.cdRef.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.Unsubscribe();
  }
  Unsubscribe() {
    if (this.Subscriptions) {
      Object.keys(this.Subscriptions).forEach((key) => {
        this.Subscriptions[key].unsubscribe();
      });
    }
  }
  ComponentAttached(event, item) {
    event.instance.PropertyGroup = item;
  }
}
