import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { get } from 'scriptjs';
import { CacheService } from '../../cache/cache.service';
import { ChartData } from '../../helpers/chart.data';
import { ThemeHelper } from '../../helpers/theme.helpers';
import * as chartModel from '../../models/controls/chart.model';
import { AxisTextPosition } from '../../models/enums/axistextposition.enum';
import { ChartAnimationEasing } from '../../models/enums/chartanimationeasing.enum';
import { ChartComponent } from '../../models/enums/chartcomponent.enum';
import { ChartGeoResolution, ChartPieSliceText, ChartStackType, ChartTrendlineType } from '../../models/enums/chartcurvetype.enum';
import { ChartLegendAlignment } from '../../models/enums/chartlegendalignment.enum';
import { ChartLegendPosition } from '../../models/enums/chartlegendposition.enum';
import { ChartToolTipTrigger } from '../../models/enums/charttooltiptrigger.enum';
import { ChartType } from '../../models/enums/charttype.enum';
import { PointShape } from '../../models/enums/pointShape.enum';
import { SeriesType } from '../../models/enums/seriestype.enum';
import { WordTreeType } from '../../models/enums/wordtreetype.enum';
declare var google: any;

@Component({
    selector: 'evi-lib-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css']
})
export class ChartControl {

    //#region Properties
    CompType: ChartComponent = ChartComponent.Google;
    ChartPalette: string[];

    //#region Options
    ApexOptions: any = {};
    SunburstOptions: any = {};
    PrimeOptions: any = {};
    //#endregion

    Initialized = false;

    @ViewChild('googleChart') googleChart;
    GoogleWrapper;

    //#region ChartSettings
    ChartSettingsValue: chartModel.ChartSettings = chartModel.ChartSettings.GetDefault();

    @Input()
    get ChartSettings(): chartModel.ChartSettings {
        return this.ChartSettingsValue;
    }
    set ChartSettings(val) {
        if (typeof val === 'object' && val !== this.ChartSettingsValue && val !== null) {
            this.ChartSettingsValue = val;
            this.createOptions();
            this.ChartSettingsChange.emit(this.ChartSettingsValue);
        }
    }

    @Output() ChartSettingsChange = new EventEmitter<any>();
    //#endregion
    //#region Data
    Source;
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.updateData();
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region GoogleMapsAPIKey
    GoogleMapsAPIKeyValue = null;

    @Input()
    get GoogleMapsAPIKey() {
        return this.GoogleMapsAPIKeyValue;
    }
    set GoogleMapsAPIKey(val) {
        this.GoogleMapsAPIKeyValue = val;
        this.GoogleMapsAPIKeyChange.emit(this.GoogleMapsAPIKeyValue);
    }

    @Output() GoogleMapsAPIKeyChange = new EventEmitter<any>();
    //#endregion
    //#region DrawOnlyByDataSource
    DrawOnlyByDataSourceValue = false;

    @Input()
    get DrawOnlyByDataSource(): boolean {
        return this.DrawOnlyByDataSourceValue;
    }
    set DrawOnlyByDataSource(val) {
        this.DrawOnlyByDataSourceValue = val;
        this.DrawOnlyByDataSourceChartChange.emit(this.DrawOnlyByDataSourceValue);
    }

    @Output() DrawOnlyByDataSourceChartChange = new EventEmitter<boolean>();
    //#endregion

    get HasData(): boolean {
        if (this.DataSourceValue != null) {
            if (Array.isArray(this.DataSourceValue)) {
                return this.DataSourceValue.length > 0;
            } else if (typeof this.DataSourceValue === 'object' && Array.isArray(this.DataSourceValue.Data)) {
                return this.DataSourceValue.Data.length > 0;
            }
        }
        return false;
    }

    //#endregion
    //#region Lifecycle
    LoadGoogleCharts() {
        // Load the Visualization API and the corechart package.
        google.charts.load('current', { 'packages': ['corechart', 'map'], mapsApiKey: this.GoogleMapsAPIKeyValue });
        // Set a callback to run when the Google Visualization API is loaded.
        google.charts.setOnLoadCallback(() => {
            ChartControl.GoogleLoading.next(false);
            this.InitGoogleChartWrapper()
        });
    }
    InitGoogleChartWrapper() {
        if (google && google.visualization) {
            this.GoogleWrapper = new google.visualization.ChartWrapper();
            google.visualization.events.addListener(this.GoogleWrapper, 'error', (error: any) => {
                this.onGCError(error);
            });
            google.visualization.events.addListener(this.GoogleWrapper, 'select', (ev) => {
                if (ev && typeof ev.getSelection === 'function') {
                    const selection = ev.getSelection();
                    this.Selection(selection);
                } else if (this.GoogleWrapper) {
                    const chart = this.GoogleWrapper.getChart();
                    if (chart) {
                        const selection = chart.getSelection();
                        this.Selection(selection);
                    }
                }
            });
            if (this.GetCompType() === ChartComponent.Google) {
                this.CreateOptionsInternal();
            }
            this.Initialized = true;
        }
    }
    //#endregion
    //#region Checks
    GetCompType() {
        let ct;
        if (this.ChartSettingsValue && typeof this.ChartSettingsValue.ChartType === 'number') {
            ct = this.ChartSettingsValue.ChartType;
        }
        if (!ct) {
            this.CompType = ChartComponent.None;
            return;
        }
        if (ct === ChartType.radialBar || ct === ChartType.heatmap) { // Apex-Chart
            this.CompType = ChartComponent.Apex;
        } else if (ct === ChartType.radar || ct === ChartType.polarArea) { // NG Chart
            this.CompType = ChartComponent.PrimeNG;
        } else if (ct === ChartType.Sunburst) {
            this.CompType = ChartComponent.Sunburst;
        } else {
            this.CompType = ChartComponent.Google;
        }
        return this.CompType;
    }
    private static CheckPropValue(propValue, propType, defaultValue) {
        if (typeof propValue !== propType) {
            return defaultValue;
        }
        return propValue;
    }

    private static CheckStringValue(propValue, defaultValue: string, checkEmpty: boolean) {
        if (typeof propValue === 'string') {
            if (checkEmpty) {
                if (propValue === '') {
                    return defaultValue;
                }
            }
            return propValue;
        }
        return defaultValue;
    }

    private static CheckNumericPropValue(propValue, defaultValue: number, min: number, max: number) {
        let retVal = ChartControl.CheckPropValue(propValue, 'number', defaultValue);
        if (retVal < min) {
            retVal = min;
        } else if (retVal > max) {
            retVal = max;
        }
        return retVal;
    }

    private static CheckNumericPropValueMin(propValue, defaultValue: number, min: number) {
        let retVal = ChartControl.CheckPropValue(propValue, 'number', defaultValue);
        if (retVal < min) {
            retVal = min;
        }
        return retVal;
    }
    //#endregion

    private static GetTextValue(propValue, enumType, defaultValue) {
        let retVal = enumType[propValue];
        if (typeof retVal !== 'string') {
            retVal = enumType[defaultValue];
        }
        return retVal;
    }

    private static setAnimation(options, animationSettings: chartModel.AnimationSettings) {
        if (animationSettings) {
            options.animation = {
                duration: ChartControl.CheckPropValue(animationSettings.Duration, 'number', 1000),
                easing: ChartControl.GetTextValue(animationSettings.Easing, ChartAnimationEasing, ChartAnimationEasing.out),
                startup: ChartControl.CheckPropValue(animationSettings.StartUp, 'boolean', true)
            };
        }
    }

    private static setAxes(options, settings: chartModel.ChartSettings, isBar: boolean) {
        if (isBar) {
            if (settings.LabelAxis) {
                options.vAxis = {};
                ChartControl.setAxisSettings(options.vAxis, settings.LabelAxis);
            }
            if (settings.ValueAxes && settings.ValueAxes.length > 0) {
                const hAxes = [];
                settings.ValueAxes.forEach(va => {
                    const hAxis = {};
                    ChartControl.setContAxisSettings(hAxis, va);
                    hAxes.push(hAxis);
                });
                options.hAxes = hAxes;
            }
        } else {
            if (settings.LabelAxis) {
                options.hAxis = {};
                ChartControl.setAxisSettings(options.hAxis, settings.LabelAxis);
            }
            if (settings.ValueAxes && settings.ValueAxes.length > 0) {
                const vAxes = [];
                settings.ValueAxes.forEach(va => {
                    const vAxis = {};
                    ChartControl.setContAxisSettings(vAxis, va);
                    vAxes.push(vAxis);
                });
                options.vAxes = vAxes;
            }
        }
    }

    private static setAxisSettings(axis, settings: chartModel.AxisSettings) {
        if (axis && settings) {
            axis.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
            axis.titleTextStyle = ChartControl.getTextStyle(settings.TitleTextStyle);
            axis.textPosition = ChartControl.GetTextValue(settings.TextPosition, AxisTextPosition, AxisTextPosition.out);
            axis.textStyle = ChartControl.getTextStyle(settings.TextStyle);
            if (settings.GridLines) {
                axis.gridlines = {
                    color: ChartControl.CheckPropValue(settings.GridLines.Color, 'string', '#CCC'),
                    count: ChartControl.CheckNumericPropValueMin(settings.GridLines.Count, -1, -1)
                };
            }
            if (settings.MinorGridLines) {
                axis.minorGridlines = {
                    count: ChartControl.CheckNumericPropValueMin(settings.MinorGridLines.Count, -1, -1)
                };
                if (typeof settings.MinorGridLines.Color === 'string') {
                    axis.minorGridlines.color = settings.MinorGridLines.Color;
                }
            }
            if (settings.ReverseDirection === true) {
                axis.direction = -1;
            }
            if (typeof settings.Format === 'string' && settings.Format) {
                axis.format = settings.Format;
            }
        }
    }

    private static setContAxisSettings(axis, settings: chartModel.ContinuousAxisSettings) {
        if (axis && settings) {
            ChartControl.setAxisSettings(axis, settings);
            axis.logScale = settings.LogarithmicScaling === true;
            if (settings.Ticks && settings.Ticks.length > 0) {
                const ticks = [];
                settings.Ticks.forEach(t => {
                    if (typeof t.Caption === 'string') {
                        ticks.push({
                            v: t.Value,
                            f: t.Caption
                        });
                    } else {
                        ticks.push(t.Value);
                    }
                });
                axis.ticks = ticks;
            } else {
                axis.viewWindow = {};
                if (settings.MinValue) {
                    if (typeof settings.MinValue.Caption === 'string') {
                        axis.viewWindow.min = {
                            v: settings.MinValue.Value,
                            f: settings.MinValue.Caption
                        };
                    } else {
                        axis.viewWindow.min = settings.MinValue.Value;
                    }
                }
                if (settings.MaxValue) {
                    if (typeof settings.MaxValue.Caption === 'string') {
                        axis.viewWindow.max = {
                            v: settings.MaxValue.Value,
                            f: settings.MaxValue.Caption
                        };
                    } else {
                        axis.viewWindow.max = settings.MaxValue.Value;
                    }
                }
            }
        }
    }

    private static getTextStyle(textStyle: chartModel.ChartTextStyle) {
        const retVal: any = {};
        if (textStyle) {
            if (typeof textStyle.Color === 'string') {
                retVal.color = textStyle.Color;
            }
            if (typeof textStyle.FontName === 'string') {
                retVal.fontName = textStyle.FontName;
            }
            if (typeof textStyle.FontSize === 'number' && textStyle.FontSize > 0) {
                retVal.fontSize = textStyle.FontSize;
            }
            retVal.bold = textStyle.Bold === true;
            retVal.italic = textStyle.Italic === true;
        }
        return retVal;
    }

    private static setColorAxis(options, colorList: chartModel.ColorAxisValue[]) {
        if (colorList && colorList.length > 1) {
            const validColors = [];
            let allValuesSet = true;
            colorList.forEach(cv => {
                if (cv.Color) {
                    validColors.push(cv);
                    if (typeof cv.Value !== 'number') {
                        allValuesSet = false;
                    }
                }
            });
            if (validColors.length > 1) {
                options.colorAxis = {};
                if (allValuesSet) {
                    options.colorAxis.values = [validColors[0].Value];
                    options.colorAxis.colors = [validColors[0].Color];
                    for (let i = 1; i < validColors.length; i++) {
                        const cv = validColors[i];
                        for (let j = 0; j <= i; j++) {
                            if (j < options.colorAxis.values.length) {
                                if (cv.Value < options.colorAxis.values[j]) {
                                    options.colorAxis.values.splice(j, 0, cv.Value);
                                    options.colorAxis.colors.splice(j, 0, cv.Color);
                                    break;
                                }
                            } else {
                                options.colorAxis.values.push(cv.Value);
                                options.colorAxis.colors.push(cv.Color);
                            }
                        }
                    }
                } else {
                    options.colorAxis.colors = [];
                    validColors.forEach(cv => {
                        options.colorAxis.colors.push(cv.Color);
                    });
                }
            }
        }
    }

    private static setSize(options, settings: chartModel.ChartSettings) {
        if (typeof settings.Height === 'number' && settings.Height > 0) {
            options.height = settings.Height;
        } else {
            options.height = '100%';
        }
        if (typeof settings.Width === 'number' && settings.Width > 0) {
            options.width = settings.Width;
        } else {
            options.width = '100%';
        }
    }

    private static setLegend(options, settings: chartModel.LegendSettings) {
        if (settings) {
            options.legend = {
                position: ChartControl.GetTextValue(settings.Position, ChartLegendPosition, ChartLegendPosition.right)
            };
            const alignment = ChartLegendAlignment[settings.Alignment];
            if (typeof alignment === 'string') {
                options.legend.alignment = alignment;
            }
            options.textStyle = ChartControl.getTextStyle(settings.TextStyle);
        }
    }

    private static setSeries(options, props, series: chartModel.SeriesSettings[]) {
        if (series) {
            options.series = [];
            options.trendlines = [];
            series.forEach((item) => {
                const result: any = {};
                props.forEach(p => {
                    switch (p) {
                        case 'areaOpacity':
                            result.areaOpacity = ChartControl.CheckNumericPropValue(item.AreaOpacity, 0.3, 0, 1);
                            break;
                        case 'candleStick':
                            if (item.FallingColor) {
                                result.fallingColor = {};
                                if (typeof item.FallingColor.Fill === 'string') {
                                    result.fallingColor.fill = item.FallingColor.Fill;
                                }
                                if (typeof item.FallingColor.Stroke === 'string') {
                                    result.fallingColor.stroke = item.FallingColor.Stroke;
                                }
                                if (typeof item.FallingColor.StrokeWidth === 'number' && item.FallingColor.StrokeWidth >= 0) {
                                    result.fallingColor.strokeWidth = item.FallingColor.StrokeWidth;
                                }
                            }
                            if (item.RisingColor) {
                                result.risingColor = {};
                                if (typeof item.RisingColor.Fill === 'string') {
                                    result.risingColor.fill = item.RisingColor.Fill;
                                }
                                if (typeof item.RisingColor.Stroke === 'string') {
                                    result.risingColor.stroke = item.RisingColor.Stroke;
                                }
                                if (typeof item.RisingColor.StrokeWidth === 'number' && item.RisingColor.StrokeWidth >= 0) {
                                    result.risingColor.strokeWidth = item.RisingColor.StrokeWidth;
                                }
                            }
                            break;
                        case 'color':
                            if (typeof item.Color === 'string') {
                                result.color = item.Color;
                            }
                            break;
                        case 'curveType':
                            if (item.CurvedLine === true) {
                                result.curveType = 'function';
                            }
                            break;
                        case 'labelInLegend':
                            if (typeof item.LegendLabel === 'string') {
                                result.labelInLegend = item.LegendLabel;
                            }
                            break;
                        case 'lineDashStyle':
                            if (item.LineDashStyle && item.LineDashStyle.length > 1) {
                                result.lineDashStyle = item.LineDashStyle;
                            }
                            break;
                        case 'lineWidth':
                            if (typeof item.LineWidth === 'number' && item.LineWidth >= 0) {
                                result.lineWidth = item.LineWidth;
                            }
                            break;
                        case 'pointShape':
                            result.pointShape = ChartControl.GetTextValue(item.PointShape, PointShape, PointShape.circle);
                            break;
                        case 'pointSize':
                            if (typeof item.PointSize === 'number' && item.PointSize >= 0) {
                                result.pointSize = item.PointSize;
                            }
                            break;
                        case 'pointsVisible':
                            if (item.PointsVisible === false) {
                                result.pointsVisible = false;
                            }
                            break;
                        case 'targetAxisIndex':
                            if (typeof item.TargetAxis === 'number' && item.TargetAxis > 0) {
                                result.targetAxisIndex = item.TargetAxis;
                            }
                            break;
                        case 'trendlines':
                            options.trendlines.push(ChartControl.getTrendlineSetting(item.TrendLine));
                            break;
                        case 'type':
                            result.type = ChartControl.GetTextValue(item.Type, SeriesType, SeriesType.bars);
                            break;
                        case 'visibleInLegend':
                            if (item.VisibleInLegend === false) {
                                result.visibleInLegend = false;
                            }
                            break;
                    }
                });
                options.series.push(result);
            });
        }
    }

    private static setSeriesColors(options, settings: chartModel.ChartSettings, palette: string[]) {
        if (palette && palette.length > 0) {
            options.colors = palette.slice();
            return true;
        } else if (Array.isArray(settings.SeriesColors) && settings.SeriesColors.length > 0) {
            options.colors = settings.SeriesColors.slice();
            return true;
        } else if (ThemeHelper.ActiveThemeChartPalette && ThemeHelper.ActiveThemeChartPalette.length > 0) {
            options.colors = ThemeHelper.ActiveThemeChartPalette.slice();
            return true;
        }
        return false;
    }

    private static getTrendlineSetting(trendline: chartModel.TrendLineSettings) {
        if (trendline) {
            const retVal: any = {};
            if (typeof trendline.LineWidth === 'number') {
                if (trendline.LineWidth <= 0) {
                    return null;
                }
                retVal.lineWidth = trendline.LineWidth;
            }
            if (typeof trendline.Color === 'string') {
                retVal.color = trendline.Color;
            }
            if (trendline.VisibleInLegend === true && typeof trendline.LabelInLegend === 'string') {
                retVal.visibleInLegend = true;
                retVal.labelInLegend = trendline.LabelInLegend;
            }
            retVal.opacity = ChartControl.CheckNumericPropValue(trendline.Opacity, 1, 0, 1);
            retVal.pointSize = ChartControl.CheckNumericPropValueMin(trendline.PointSize, 1, 0);
            if (trendline.PointsVisible === false) {
                retVal.pointsVisible = false;
            }
            retVal.showR2 = trendline.ShowR2 === true;
            retVal.type = ChartControl.GetTextValue(trendline.Type, ChartTrendlineType, ChartTrendlineType.linear);
            retVal.degree = ChartControl.CheckNumericPropValueMin(trendline.Degree, 3, 1);
            return retVal;
        }
        return null;
    }

    private static setTooltip(options, settings: chartModel.TooltipSettings) {
        if (settings) {
            options.tooltip = {
                // text: ChartControl.CheckPropValue(this.ChartToolTipText, 'string', null),
                trigger: ChartControl.GetTextValue(settings.Trigger, ChartToolTipTrigger, ChartToolTipTrigger.focus),
                textStyle: ChartControl.getTextStyle(settings.TextStyle)
            };
        }
    }

    //#region ChartOptions
    private static ApexLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private static GoogleLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    createOptions() {
        if (this.DrawOnlyByDataSourceValue) {
            return;
        }
        this.Initialized = false;
        this.GetCompType();
        let apexsub;
        let googlesub;
        switch (this.CompType) {
            case ChartComponent.Apex:
                if (window['ApexCharts'] == null && !ChartControl.ApexLoading.getValue()) {
                    ChartControl.ApexLoading.next(true);
                    get("https://cdn.jsdelivr.net/npm/apexcharts", () => {
                        ChartControl.ApexLoading.next(false);
                        this.CreateOptionsInternal();
                    });
                } else if (ChartControl.ApexLoading.getValue()) {
                    if (!apexsub) {
                        const apexsub = ChartControl.ApexLoading.subscribe((res) => {
                            if (!res) {
                                apexsub.unsubscribe();
                                this.CreateOptionsInternal();
                            }
                        });
                    }
                } else {
                    this.CreateOptionsInternal();
                }
                break;
            case ChartComponent.Google:
                if (window['google'] == null && !ChartControl.GoogleLoading.getValue()) {
                    ChartControl.GoogleLoading.next(true);
                    get("https://www.gstatic.com/charts/loader.js", () => {
                        this.LoadGoogleCharts();
                    })
                } else if (ChartControl.GoogleLoading.getValue()) {
                    if (!googlesub) {
                        const googlesub = ChartControl.GoogleLoading.subscribe((res) => {
                            if (!res) {
                                googlesub.unsubscribe();
                                this.InitGoogleChartWrapper();
                            }
                        });
                    }
                } else {
                    this.InitGoogleChartWrapper()
                }
                break;
            case ChartComponent.PrimeNG:
                this.CreateOptionsInternal();
                break;
            case ChartComponent.Sunburst:
                this.CreateOptionsInternal();
                break;
        }
    }

    loadChartPalette() {
        return new Promise<string[]>(resolve => {
            if (this.ChartSettingsValue && this.ChartSettingsValue.ChartPalette) {
                CacheService.ReadChartPaletteValues(this.ChartSettingsValue.ChartPalette).then(x => {
                    this.ChartPalette = x;
                    resolve(x);
                });
            } else {
                resolve(null);
            }
        });
    }

    CreateOptionsInternal() {
        this.loadChartPalette().then(palette => {
            //#region GetChartType
            let ct;
            if (this.ChartSettingsValue && typeof this.ChartSettingsValue.ChartType === 'number') {
                ct = this.ChartSettingsValue.ChartType;
            }
            let chartType = ChartType[ct];
            //#endregion
            if (this.CompType === ChartComponent.Apex) { // Apex-Chart
                const options = ChartControl.GetApexChartOptions(this.ChartSettingsValue, palette);
                if (this.ApexOptions.series) {
                    options.series = this.ApexOptions.series;
                }
                if (this.ApexOptions.labels) {
                    options.labels = this.ApexOptions.labels;
                }
                this.ApexOptions = options;
                if (this.HasData) {
                    this.updateData();
                }
            } else if (this.CompType === ChartComponent.PrimeNG) { // NG Chart
                this.PrimeOptions = {
                    type: chartType,
                    options: ChartControl.GetPrimeChartsOptions(this.ChartSettingsValue, ct === ChartType.radar)
                };
                if (this.HasData) {
                    this.updateData();
                }
            } else if (this.CompType === ChartComponent.Sunburst) {
                const options = ChartControl.GetSunburstChartOptions(this.ChartSettingsValue);
                if (this.SunburstOptions.series) {
                    options.series = this.SunburstOptions.series;
                }
                if (this.SunburstOptions.labels) {
                    options.labels = this.SunburstOptions.labels;
                }

                this.SunburstOptions = options;
                if (this.HasData) {
                    this.updateData();
                }
            } else if (this.CompType === ChartComponent.Google) {
                if (this.GoogleWrapper) {
                    if (ct === ChartType.DonutChart) {
                        chartType = ChartType[ChartType.PieChart];
                    }
                    if (this.GoogleWrapper.getChartType() !== chartType) {
                        this.GoogleWrapper.setChartType(chartType);
                    }
                    let options = ChartControl.GetGoogleChartOptions(this.ChartSettingsValue, ct, palette);
                    try {
                        options = google.charts[chartType].convertOptions(options);
                    } catch { }
                    this.GoogleWrapper.setOptions(options);
                    if (this.HasData) {
                        this.updateData();
                    } else {
                        this.GoogleWrapper.setDataTable(null);
                        const chart = this.GoogleWrapper.getChart();
                        if (chart) {
                            chart.clearChart();
                        }
                    }
                }
            }
            this.Initialized = true;
        });
    }

    private static GetPrimeChartsOptions(settings: chartModel.ChartSettings, isRadar: boolean): any {
        const options: any = {};
        if (settings) {
            if (settings.Animation) {
                options.animation = {
                    duration: ChartControl.CheckNumericPropValueMin(settings.Animation.Duration, 1000, 0)
                };
                if (typeof settings.Animation.Easing === 'number') {
                    switch (settings.Animation.Easing) {
                        case ChartAnimationEasing.in:
                            options.animation.easing = 'easeInSine';
                            break;
                        case ChartAnimationEasing.inAndOut:
                            options.animation.easing = 'easeInOutSine';
                            break;
                        case ChartAnimationEasing.linear:
                            options.animation.easing = 'linear';
                            break;
                        case ChartAnimationEasing.out:
                            options.animation.easing = 'easeOutSine';
                            break;
                    }
                }
            }
            options.legend = { position: 'right' };
            if (settings.Legend) {
                if (typeof settings.Legend.Position === 'number') {
                    switch (settings.Legend.Position) {
                        case ChartLegendPosition.bottom:
                            options.legend.position = 'bottom';
                            break;
                        case ChartLegendPosition.left:
                            options.legend.position = 'left';
                            break;
                        case ChartLegendPosition.top:
                            options.legend.position = 'top';
                            break;
                        case ChartLegendPosition.none:
                            options.legend.display = false;
                            break;
                        default:
                            options.legend.position = 'right';
                            break;
                    }
                } else {
                    options.legend.position = 'right';
                }
                if (settings.Legend.TextStyle) {
                    options.legend.labels = {
                        fontSize: ChartControl.CheckNumericPropValueMin(settings.Legend.TextStyle.FontSize, 12, 1),
                        fontColor: ChartControl.CheckPropValue(settings.Legend.TextStyle.Color, 'string', '#666'),
                        fontFamily: ChartControl.CheckPropValue(settings.Legend.TextStyle.FontName, 'string', 'Arial')
                    };
                    if (settings.Legend.TextStyle.Bold === true) {
                        if (settings.Legend.TextStyle.Italic === true) {
                            options.legend.labels.fontStyle = 'bold italic';
                        } else {
                            options.legend.labels.fontStyle = 'bold';
                        }
                    } else if (settings.Legend.TextStyle.Italic === true) {
                        options.legend.labels.fontStyle = 'italic';
                    }
                }
            }
            if (typeof settings.Title === 'string' && settings.Title !== '' && settings.TitlePosition !== AxisTextPosition.none) {
                options.title = {
                    display: true,
                    text: settings.Title,
                };
            }

            if (isRadar) {
                const defSettings = settings.DefaultSeriesSetting;
                if (defSettings) {
                    options.elements = {};
                    const color = ChartControl.CheckPropValue(defSettings.Color, 'string', 'rgba(0, 0, 0, 0.1)');
                    if (defSettings.PointsVisible === false) {
                        options.elements.point = { radius: 0 };
                    } else {
                        options.elements.point = {
                            radius: ChartControl.CheckNumericPropValueMin(defSettings.PointSize, 3, 0),
                            backgroundColor: color,
                        };
                        if (typeof defSettings.PointShape === 'number') {
                            switch (defSettings.PointShape) {
                                case PointShape.circle:
                                    options.elements.point.pointStyle = 'circle';
                                    break;
                                case PointShape.diamond:
                                    options.elements.point.pointStyle = 'rectRot';
                                    break;
                                case PointShape.square:
                                    options.elements.point.pointStyle = 'rect';
                                    break;
                                case PointShape.star:
                                    options.elements.point.pointStyle = 'star';
                                    break;
                                case PointShape.triangle:
                                    options.elements.point.pointStyle = 'triangle';
                                    break;
                            }
                        }
                    }
                    options.elements.line = {
                        borderColor: color,
                        borderWidth: ChartControl.CheckNumericPropValueMin(defSettings.LineWidth, 3, 0)
                    };
                    if (Array.isArray(defSettings.LineDashStyle) && defSettings.LineDashStyle.length > 1) {
                        options.elements.line.borderDash = defSettings.LineDashStyle.slice();
                    }
                }
            } else {
                options.elements = {
                    arc: {}
                };
                if (settings.DefaultSeriesSetting && typeof settings.DefaultSeriesSetting.Color === 'string') {
                    options.elements.arc.backgroundColor = settings.DefaultSeriesSetting.Color;
                }
                if (settings.PieSettings) {
                    options.elements.arc.borderColor =
                        ChartControl.CheckPropValue(settings.PieSettings.SliceBorderColor, 'string', '#fff');
                }
            }
        }
        return options;
    }

    private static GetGoogleChartOptions(settings: chartModel.ChartSettings, ct: ChartType, palette: string[]): any {
        const options: any = {};
        if (settings) {
            switch (ct) {
                case ChartType.AnnotationChart:
                    const colorsSet = ChartControl.setSeriesColors(options, settings, palette);
                    if (colorsSet) {
                        if (settings.Series && settings.Series.length > 0) {
                            settings.Series.some((x, i) => {
                                if (i < options.colors.length) {
                                    if (x.Color) {
                                        options.colors.splice(i, 0, x.Color);
                                    }
                                    return false;
                                }
                                return true;
                            });
                        }
                    } else if (settings.Series && settings.Series.length > 0) {
                        let colors = [];
                        settings.Series.some(x => {
                            if (x.Color) {
                                colors.push(x.Color);
                                return false;
                            } else {
                                colors = null;
                                return true;
                            }
                        });
                        if (colors) {
                            options.colors = colors;
                        }
                    }
                    const as = settings.AnnotationSettings;
                    if (as) {
                        options.displayAnnotations = ChartControl.CheckPropValue(as.DisplayAnnotations, 'boolean', true);
                        options.displayAnnotationsFilter = ChartControl.CheckPropValue(as.DisplayAnnotationsFilter, 'boolean', false);
                        options.displayRangeSelector = ChartControl.CheckPropValue(as.DisplayRangeSelector, 'boolean', false);
                        options.displayZoomButtons = ChartControl.CheckPropValue(as.DisplayZoomButtons, 'boolean', false);
                        options.fill = ChartControl.CheckNumericPropValue(as.FillOpacity, 0, 0, 100);
                        options.table = {
                            sortAscending: ChartControl.CheckPropValue(as.SortAscending, 'boolean', false),
                            sortColumn: ChartControl.CheckPropValue(as.SortColumn, 'number', 0)
                        };
                        options.thickness = ChartControl.CheckNumericPropValue(as.Thickness, 0, 0, 10);
                    }
                    break;
                case ChartType.AreaChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    options.isStacked = ChartControl.GetTextValue(settings.IsStacked, ChartStackType, ChartStackType.false);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['areaOpacity', 'color', 'labelInLegend', 'lineDashStyle', 'lineWidth',
                        'pointShape', 'pointSize', 'pointsVisible', 'targetAxisIndex', 'trendlines', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Bar:
                case ChartType.BarChart:
                case ChartType.ColumnChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (typeof settings.BarGroupWidth === 'string') { options.bar = { groupWidth: settings.BarGroupWidth }; }
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, ct !== ChartType.ColumnChart);
                    ChartControl.setSize(options, settings);
                    options.isStacked = ChartControl.GetTextValue(settings.IsStacked, ChartStackType, ChartStackType.false);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['color', 'labelInLegend', 'targetAxisIndex', 'trendlines', 'visibleInLegend'],
                        settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.BubbleChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (settings.BubbleSettings) {
                        options.bubble = {
                            opacity: ChartControl.CheckNumericPropValue(settings.BubbleSettings.Opacity, 0.8, 0, 1),
                            stroke: ChartControl.CheckPropValue(settings.BubbleSettings.Stroke, 'string', null),
                            textStyle: ChartControl.getTextStyle(settings.BubbleSettings.TextStyle)
                        };
                        options.bubble.textStyle.auraColor = 'none';
                        options.sizeAxis = {
                            maxSize: ChartControl.CheckNumericPropValueMin(settings.BubbleSettings.MaxBubbleSize, 30, 1),
                            minSize: ChartControl.CheckNumericPropValueMin(settings.BubbleSettings.MinBubbleSize, 5, 1)
                        };
                        if (typeof settings.BubbleSettings.MaxBubbleValue === 'number') {
                            options.sizeAxis.maxValue = settings.BubbleSettings.MaxBubbleValue;
                        }
                        if (typeof settings.BubbleSettings.MinBubbleValue === 'number') {
                            options.sizeAxis.minValue = settings.BubbleSettings.MinBubbleValue;
                        }
                        options.colorAxis = {};
                        ChartControl.setColorAxis(options, settings.BubbleSettings.ColorValues);
                        if (settings.BubbleSettings.ShowLegend === true) {
                            options.colorAxis.legend = {
                                textStyle: ChartControl.getTextStyle(settings.BubbleSettings.LegendTextStyle)
                            };
                            if (typeof settings.BubbleSettings.LegendPosition === 'number') {
                                switch (settings.BubbleSettings.LegendPosition) {
                                    case ChartLegendAlignment.center:
                                        options.colorAxis.legend.position = 'in';
                                        break;
                                    case ChartLegendAlignment.end:
                                        options.colorAxis.legend.position = 'bottom';
                                        break;
                                    case ChartLegendAlignment.start:
                                        options.colorAxis.legend.position = 'top';
                                        break;
                                }
                            }
                            if (typeof settings.BubbleSettings.LegendNumberFormat === 'string') {
                                options.colorAxis.legend.numberFormat = settings.BubbleSettings.LegendNumberFormat;
                            }
                        }
                    }
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.Series && settings.Series.length > 1) {
                        const bubbleFormatSeries = {};
                        for (let i = 1; i < settings.Series.length; i++) {
                            const actSeries = settings.Series[i];
                            if (typeof actSeries.Color === 'string' && typeof actSeries.LegendLabel === 'string') {
                                bubbleFormatSeries[actSeries.LegendLabel] = {
                                    color: actSeries.Color,
                                    visibleInLegend: false
                                };
                            }
                        }
                        options.series = bubbleFormatSeries;
                    }
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Calendar:
                    const cal = settings.CalendarSettings;
                    if (cal) {
                        options.calendar = {
                            cellColor: {
                                stroke: ChartControl.CheckPropValue(cal.CellBorderStroke, 'string', '#fff'),
                                strokeOpacity: ChartControl.CheckNumericPropValue(cal.CellBorderOpacity, 1, 0, 1),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(cal.CellBorderStrokeWidth, 1, 0)
                            },
                            cellSize: ChartControl.CheckNumericPropValueMin(cal.CellSize, 16, 1),
                            dayOfWeekLabel: ChartControl.getTextStyle(cal.DayOfWeekLabel),
                            focusedCellColor: {
                                stroke: ChartControl.CheckPropValue(cal.FocusedCellBorderStroke, 'string', '#000'),
                                strokeOpacity: ChartControl.CheckNumericPropValue(cal.FocusedCellBorderOpacity, 1, 0, 1),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(cal.FocusedCellBorderStrokeWidth, 2, 0)
                            },
                            monthLabel: ChartControl.getTextStyle(cal.MonthLabel),
                            monthOutlineColor: {
                                stroke: ChartControl.CheckPropValue(cal.MonthBorderStroke, 'string', '#000'),
                                strokeOpacity: ChartControl.CheckNumericPropValue(cal.MonthBorderOpacity, 1, 0, 1),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(cal.MonthBorderStrokeWidth, 1, 0)
                            },
                            unusedMonthOutlineColor: {
                                stroke: ChartControl.CheckPropValue(cal.UnUsedMonthBorderStroke, 'string', '#c9c9c9'),
                                strokeOpacity: ChartControl.CheckNumericPropValue(cal.UnUsedMonthBorderOpacity, 1, 0, 1),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(cal.UnUsedMonthBorderStrokeWidth, 1, 0)
                            }
                        };
                        ChartControl.setColorAxis(options, cal.ColorValues);
                    }
                    ChartControl.setSize(options, settings);
                    break;
                case ChartType.CandlestickChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (typeof settings.BarGroupWidth === 'string') { options.bar = { groupWidth: settings.BarGroupWidth }; }
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['candleStick', 'color', 'labelInLegend',
                        'targetAxisIndex', 'trendlines', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.ComboChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (typeof settings.BarGroupWidth === 'string') { options.bar = { groupWidth: settings.BarGroupWidth }; }
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    options.isStacked = ChartControl.GetTextValue(settings.IsStacked, ChartStackType, ChartStackType.false);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    this.setSeries(options, ['areaOpacity', 'candleStick', 'color', 'curveType', 'labelInLegend',
                        'lineDashStyle', 'lineWidth', 'pointShape', 'pointSize', 'pointsVisible',
                        'targetAxisIndex', 'trendlines', 'type', 'visibleInLegend'],
                        settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.DonutChart:
                case ChartType.PieChart:
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setSize(options, settings);
                    ChartControl.setLegend(options, settings.Legend);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    const pie = settings.PieSettings;
                    if (pie) {
                        options.is3D = ChartControl.CheckPropValue(pie.Is3D, 'boolean', false);
                        if (ct === ChartType.DonutChart) {
                            options.pieHole = ChartControl.CheckNumericPropValue(pie.PieHole, 0.5, 0, 1);
                        }
                        options.pieSliceBorderColor = ChartControl.CheckPropValue(pie.SliceBorderColor, 'string', 'white');
                        options.pieSliceText = ChartControl.GetTextValue(pie.SliceText, ChartPieSliceText, ChartPieSliceText.percentage);
                        options.pieSliceTextStyle = ChartControl.getTextStyle(pie.SliceTextStyle);
                        options.pieStartAngle = ChartControl.CheckPropValue(pie.StartAngle, 'number', 0);
                        options.pieResidueSliceColor = ChartControl.CheckPropValue(pie.ResidueSliceColor, 'string', '#ccc');
                        if (typeof pie.ResidueSliceLabel === 'string') {
                            options.pieResidueSliceLabel = pie.ResidueSliceLabel;
                        }
                        options.sliceVisibilityThreshold = ChartControl.CheckNumericPropValue(pie.ResidueThreshold, 0.0014, 0, 1);
                        if (pie.Slices && pie.Slices.length > 0) {
                            options.slices = [];
                            pie.Slices.forEach((item) => {
                                const result = {
                                    offset: ChartControl.CheckNumericPropValue(item.Offset, 0, 0, 1),
                                    textStyle: ChartControl.getTextStyle(item.TextStyle)
                                };
                                if (typeof item.Color === 'string') {
                                    result['color'] = item.Color;
                                }
                                options.slices.push(result);
                            });
                        }
                    } else {
                        if (ct === ChartType.DonutChart) {
                            options.pieHole = 0.5;
                        }
                    }
                    break;
                case ChartType.Gantt:
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    const gantt = settings.GanttSettings;
                    if (gantt) {
                        options.gantt = {
                            arrow: {
                                angle: ChartControl.CheckPropValue(gantt.ArrowAngle, 'number', 45),
                                color: ChartControl.CheckPropValue(gantt.ArrowColor, 'string', '#000'),
                                length: ChartControl.CheckNumericPropValueMin(gantt.ArrowLength, 8, 1),
                                radius: ChartControl.CheckNumericPropValueMin(gantt.ArrowRadius, 15, 1),
                                width: ChartControl.CheckNumericPropValueMin(gantt.ArrowWidth, 1.4, 1)
                            },
                            barCornerRadius: ChartControl.CheckNumericPropValueMin(gantt.CornerRadius, 2, 0),
                            criticalPathEnabled: ChartControl.CheckPropValue(gantt.CriticalPathEnabled, 'boolean', true),
                            criticalPathStyle: {
                                stroke: ChartControl.CheckPropValue(gantt.CriticalPathStroke, 'string', null),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(gantt.CriticalPathStrokeWidth, 1.4, 0)
                            },
                            innerGridDarkTrack: { fill: ChartControl.CheckPropValue(gantt.GridDarkTrack, 'string', null) },
                            innerGridHorizLine: {
                                stroke: ChartControl.CheckPropValue(gantt.GridLineStroke, 'string', null),
                                strokeWidth: ChartControl.CheckNumericPropValueMin(gantt.GridLineStrokeWidth, 1, 0)
                            },
                            innerGridTrack: { fill: ChartControl.CheckPropValue(gantt.GridTrack, 'string', null) },
                            labelStyle: ChartControl.getTextStyle(gantt.LabelStyle),
                            percentEnabled: ChartControl.CheckPropValue(gantt.PercentEnabled, 'boolean', true),
                            shadowEnabled: ChartControl.CheckPropValue(gantt.ShadowEnabled, 'boolean', true),
                            shadowColor: ChartControl.CheckPropValue(gantt.ShadowColor, 'string', null),
                            shadowOffset: ChartControl.CheckNumericPropValueMin(gantt.ShadowOffset, 1, 0)
                        };
                        if (typeof gantt.BarHeight === 'number' && gantt.BarHeight > 0) {
                            options.gantt.barHeight = gantt.BarHeight;
                        }
                        if (typeof gantt.PercentFill === 'string') {
                            options.gantt.percentStyle = {
                                fill: gantt.PercentFill
                            };
                        }
                        if (typeof gantt.TrackHeight === 'number' && gantt.TrackHeight > 0) {
                            options.gantt.trackHeight = gantt.TrackHeight;
                        }
                    }
                    ChartControl.setSize(options, settings);
                    break;
                case ChartType.Gauge:
                    ChartControl.setAnimation(options, settings.Animation);
                    const gauge = settings.GaugeSettings;
                    if (gauge) {
                        if (gauge.GreenArea) {
                            options.greenColor = ChartControl.CheckPropValue(gauge.GreenArea.Color, 'string', '#109618');
                            if (typeof gauge.GreenArea.From === 'number') {
                                options.greenFrom = gauge.GreenArea.From;
                            }
                            if (typeof gauge.GreenArea.To === 'number') {
                                options.greenTo = gauge.GreenArea.To;
                            }
                        }
                        if (gauge.YellowArea) {
                            options.yellowColor = ChartControl.CheckPropValue(gauge.YellowArea.Color, 'string', '#FF9900');
                            if (typeof gauge.YellowArea.From === 'number') {
                                options.yellowFrom = gauge.YellowArea.From;
                            }
                            if (typeof gauge.YellowArea.To === 'number') {
                                options.yellowTo = gauge.YellowArea.To;
                            }
                        }
                        if (gauge.RedArea) {
                            options.redColor = ChartControl.CheckPropValue(gauge.RedArea.Color, 'string', '#DC3912');
                            if (typeof gauge.RedArea.From === 'number') {
                                options.redFrom = gauge.RedArea.From;
                            }
                            if (typeof gauge.RedArea.To === 'number') {
                                options.redTo = gauge.RedArea.To;
                            }
                        }
                        if (gauge.MajorTicks && gauge.MajorTicks.length > 1) { options.majorTicks = gauge.MajorTicks.slice(); }
                        options.minorTicks = ChartControl.CheckNumericPropValueMin(gauge.MinorTicks, 2, 0);
                        options.min = ChartControl.CheckPropValue(gauge.MinValue, 'number', 0);
                        options.max = ChartControl.CheckPropValue(gauge.MaxValue, 'number', 100);
                    }
                    ChartControl.setSize(options, settings);
                    break;
                case ChartType.GeoChart:
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (settings.GeoSettings) {
                        ChartControl.setColorAxis(options, settings.GeoSettings.ColorValues);
                        options.region = ChartControl.CheckStringValue(settings.GeoSettings.Region, 'world', true);
                        options.resolution = ChartControl.GetTextValue(settings.GeoSettings.Resolution,
                            ChartGeoResolution, ChartGeoResolution.countries);
                    }
                    options.displayMode = 'regions';
                    ChartControl.setSize(options, settings);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Histogram:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    if (typeof settings.BarGroupWidth === 'string') { options.bar = { groupWidth: settings.BarGroupWidth }; }
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    options.isStacked = ChartControl.GetTextValue(settings.IsStacked, ChartStackType, ChartStackType.false);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['color', 'labelInLegend', 'targetAxisIndex', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Interval:
                    // ???
                    break;
                case ChartType.Line:
                case ChartType.LineChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['color', 'curveType', 'labelInLegend', 'lineDashStyle', 'lineWidth', 'pointShape',
                        'pointSize', 'pointsVisible', 'targetAxisIndex ', 'trendlines', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Map:
                    // TODO
                    // options.mapType = ChartControl.GetTextValue(this.MapType, MapType, MapType.normal);
                    // options.showTooltip = ChartControl.CheckPropValue(this.ShowTooltip, 'boolean', false);
                    // options.useMapTypeControl = ChartControl.CheckPropValue(this.UseMapTypeControl, 'boolean', false);
                    break;
                case ChartType.OrgChart:
                    // ???
                    break;
                case ChartType.ScatterChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    ChartControl.setLegend(options, settings.Legend);
                    if (settings.SwitchOrientation === true) { options.orientation = 'vertical'; }
                    ChartControl.setSeries(options, ['color', 'labelInLegend', 'pointShape', 'pointSize', 'pointsVisible',
                        'trendlines', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.SteppedAreaChart:
                    ChartControl.setAnimation(options, settings.Animation);
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setAxes(options, settings, false);
                    ChartControl.setSize(options, settings);
                    options.isStacked = ChartControl.GetTextValue(settings.IsStacked, ChartStackType, ChartStackType.false);
                    ChartControl.setLegend(options, settings.Legend);
                    ChartControl.setSeries(options, ['areaOpacity', 'color', 'labelInLegend', 'lineDashStyle',
                        'targetAxisIndex', 'visibleInLegend'], settings.Series);
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    options.titlePosition = ChartControl.GetTextValue(settings.TitlePosition, AxisTextPosition, AxisTextPosition.out);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    break;
                case ChartType.Timeline:
                    options.backgroundColor = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', 'white');
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    options.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Arial');
                    ChartControl.setSize(options, settings);
                    ChartControl.setTooltip(options, settings.Tooltip);
                    const tl = settings.TimelineSettings;
                    if (tl) {
                        options.avoidOverlappingGridLines = ChartControl.CheckPropValue(tl.AvoidOverlap, 'boolean', true);
                        options.timeline = {
                            barLabelStyle: ChartControl.getTextStyle(tl.BarLabelStyle),
                            colorByRowLabel: ChartControl.CheckPropValue(tl.ColorByRowLabel, 'boolean', false),
                            groupByRowLabel: ChartControl.CheckPropValue(tl.GroupByRowLabel, 'boolean', true),
                            rowLabelStyle: ChartControl.getTextStyle(tl.RowLabelStyle),
                            showBarLabels: ChartControl.CheckPropValue(tl.ShowBarLabels, 'boolean', true),
                            showRowLabels: ChartControl.CheckPropValue(tl.ShowRowLabels, 'boolean', true),
                            singleColor: ChartControl.CheckPropValue(tl.SingleColor, 'string', null)
                        };
                    }
                    break;
                case ChartType.TreeMap:
                    if (typeof settings.FontName === 'string') { options.fontFamily = settings.FontName; }
                    if (typeof settings.FontSize === 'number' && settings.FontSize > 0) { options.fontSize = settings.FontSize; }
                    const tm = settings.TreeMapSettings;
                    if (tm) {
                        options.fontColor = ChartControl.CheckPropValue(tm.FontColor, 'string', '#ffffff');
                        options.headerColor = ChartControl.CheckPropValue(tm.HeaderColor, 'string', '#988f86');
                        options.headerHeight = ChartControl.CheckNumericPropValueMin(tm.HeaderHeight, 0, 0);
                        options.headerHighlightColor = ChartControl.CheckPropValue(tm.HeaderHighlightColor, 'string', null);
                        options.highlightOnMouseOver = ChartControl.CheckPropValue(tm.HighlightOnHover, 'boolean', false);
                        options.hintOpacity = ChartControl.CheckNumericPropValue(tm.HintOpacity, 0, 0, 1);
                        options.maxColor = ChartControl.CheckPropValue(tm.MaxColor, 'string', '#00dd00');
                        options.maxDepth = ChartControl.CheckNumericPropValueMin(tm.MaxDepth, 1, 0);
                        options.maxHighlightColor = ChartControl.CheckPropValue(tm.MaxHighlightColor, 'string', null);
                        options.maxPostDepth = ChartControl.CheckNumericPropValueMin(tm.MaxPostDepth, 0, 0);
                        options.maxColorValue = ChartControl.CheckPropValue(tm.MaxColorValue, 'number', null);
                        options.midColor = ChartControl.CheckPropValue(tm.MidColor, 'string', '#000000');
                        options.midHighlightColor = ChartControl.CheckPropValue(tm.MidHighlightColor, 'string', null);
                        options.minColor = ChartControl.CheckPropValue(tm.MinColor, 'string', '#dd0000');
                        options.minHighlightColor = ChartControl.CheckPropValue(tm.MinHighlightColor, 'string', null);
                        options.minColorValue = ChartControl.CheckPropValue(tm.MinColorValue, 'number', null);
                        options.noColor = ChartControl.CheckPropValue(tm.NoColor, 'string', '#000000');
                        options.noHighlightColor = ChartControl.CheckPropValue(tm.NoHighlightColor, 'string', null);
                        options.showScale = ChartControl.CheckPropValue(tm.ShowScale, 'boolean', false);
                        options.showTooltips = ChartControl.CheckPropValue(tm.ShowTooltips, 'boolean', true);
                        options.textStyle = ChartControl.getTextStyle(tm.TextStyle);
                        options.useWeightedAverageForAggregation =
                            ChartControl.CheckPropValue(tm.UseWeightedAverageForAggregation, 'boolean', false);
                    }
                    options.title = ChartControl.CheckPropValue(settings.Title, 'string', null);
                    break;
                case ChartType.WordTree:
                    ChartControl.setSeriesColors(options, settings, palette);
                    if (typeof settings.FontName === 'string') {
                        options.fontName = settings.FontName;
                    }
                    ChartControl.setSize(options, settings);
                    if (settings.WordTreeSettings) {
                        options.wordtree = {
                            type: ChartControl.GetTextValue(settings.WordTreeSettings.Type, WordTreeType, WordTreeType.suffix)
                        };
                    }
                    break;
            }
        }
        return options;
    }

    private static GetApexChartOptions(settings: chartModel.ChartSettings, palette: string[]): any {
        const options: any = { series: [] };
        if (settings) {
            options.chart = {};
            if (settings.Animation) {
                options.chart.animations = {
                    enabled: true,
                    speed: ChartControl.CheckNumericPropValueMin(settings.Animation.Duration, 800, 1)
                };
                if (typeof settings.Animation.Easing === 'number') {
                    switch (settings.Animation.Easing) {
                        case ChartAnimationEasing.in:
                            options.chart.animations.easing = 'easein';
                            break;
                        case ChartAnimationEasing.inAndOut:
                            options.chart.animations.easing = 'easeout';
                            break;
                        case ChartAnimationEasing.linear:
                            options.chart.animations.easing = 'linear';
                            break;
                        case ChartAnimationEasing.out:
                            options.chart.animations.easing = 'easeinout';
                            break;
                    }
                }
            }
            options.chart.background = ChartControl.CheckPropValue(settings.BackgroundColor, 'string', '#fff');
            options.chart.fontName = ChartControl.CheckPropValue(settings.FontName, 'string', 'Helvetica, Arial, sans-serif');
            ChartControl.setSize(options.chart, settings);
            options.chart.toolbar = { show: false };
            ChartControl.setSeriesColors(options, settings, palette);
            options.legend = {
                show: true,
                position: 'right'
            };
            if (settings.Legend) {
                if (settings.Legend.Position === ChartLegendPosition.none) {
                    options.legend.show = false;
                } else {
                    options.legend.position =
                        ChartControl.GetTextValue(settings.Legend.Position, ChartLegendPosition, ChartLegendPosition.right);
                    if (typeof settings.Legend.Alignment === 'number') {
                        switch (settings.Legend.Alignment) {
                            case ChartLegendAlignment.center:
                                options.legend.horizontalAlign = 'center';
                                break;
                            case ChartLegendAlignment.end:
                                options.legend.horizontalAlign = 'right';
                                break;
                            case ChartLegendAlignment.start:
                                options.legend.horizontalAlign = 'left';
                                break;
                        }
                    }
                    if (settings.Legend.TextStyle) {
                        if (typeof settings.Legend.TextStyle.FontName === 'string') {
                            options.legend.fontFamily = settings.Legend.TextStyle.FontName;
                        }
                        if (typeof settings.Legend.TextStyle.FontSize === 'number' && settings.Legend.TextStyle.FontSize > 0) {
                            options.legend.fontSize = settings.Legend.TextStyle.FontSize + 'px';
                        }
                    }
                }
            }
            if (typeof settings.Title === 'string') {
                options.title = {
                    text: settings.Title
                };
            }
            if (settings.Tooltip && settings.Tooltip.TextStyle) {
                options.tooltip = {
                    style: {}
                };
                if (typeof settings.Tooltip.TextStyle.FontName === 'string') {
                    options.tooltip.style.fontFamily = settings.Tooltip.TextStyle.FontName;
                }
                if (typeof settings.Tooltip.TextStyle.FontSize === 'number' && settings.Tooltip.TextStyle.FontSize > 0) {
                    options.tooltip.style.fontSize = settings.Tooltip.TextStyle.FontSize + 'px';
                }
            }
            if (settings.ChartType === ChartType.radialBar) {
                options.chart.type = 'radialBar';
            } else if (settings.ChartType === ChartType.heatmap) {
                options.chart.type = 'heatmap';
                if (settings.Series && settings.Series.length > 0) {
                    if (options.colors) {
                        settings.Series.some((x, i) => {
                            if (i < options.colors.length) {
                                if (x.Color) {
                                    options.colors.splice(i, 0, x.Color);
                                }
                                return false;
                            }
                            return true;
                        });
                    } else {
                        let colors = [];
                        settings.Series.some(x => {
                            if (x.Color) {
                                colors.push(x.Color);
                                return false;
                            } else {
                                colors = null;
                                return true;
                            }
                        });
                        if (colors) {
                            options.colors = colors;
                        }
                    }
                }
                if (settings.HeatMapSettings) {
                    options.plotOptions = {
                        heatmap: {
                            colorScale: {
                                ranges: []
                            }
                        }
                    };
                    if (settings.HeatMapSettings.ColorValues && settings.HeatMapSettings.ColorValues.length > 0) {
                        settings.HeatMapSettings.ColorValues.forEach(x => {
                            if (typeof x.From === 'number' && typeof x.To === 'number' && typeof x.Color === 'string') {
                                const colVal = {
                                    from: x.From,
                                    to: x.To,
                                    color: x.Color,
                                    name: ''
                                };
                                if (x.Caption) {
                                    colVal.name = x.Caption;
                                }
                                if (x.From > x.To) {
                                    colVal.from = x.To;
                                    colVal.to = x.From;
                                }
                                options.plotOptions.heatmap.colorScale.ranges.push(colVal);
                            }
                        });
                    }
                }
            }
        }
        return options;
    }
    private static GetSunburstChartOptions(settings: chartModel.ChartSettings): any {
        const options: any = {};
        if (settings) {
            if (settings.Series) {
                options.Label = settings.Series[0]['Label'];
                options.Children = settings.Series[0]['Children'];
                options.Value = settings.Series[0]['Value'];
                options.TooltipValue = settings.Series[0]['TooltipValue'];
                options.Color = settings.Series[0]['ColorField'];
                options.CenterRadius = settings['CenterRadius'];
                options.RadiusScaleExponent = settings['RadiusScaleExponent'];
                options.ShowLabels = settings['ShowLabels'];
                options.ShowTooltip = settings['ShowTooltip'];
                options.TooltipTitle = settings['TooltipTitle'];
                options.TooltipContent = settings['TooltipContent'];
                options.ExcludeRoot = settings['ExcludeRoot'];

            }
        }
        return options;
    }
    //#endregion
    //#region Data
    updateData() {
        let data, styles;
        if (this.DataSourceValue != null) {
            if (Array.isArray(this.DataSourceValue)) {
                data = this.DataSourceValue;
            } else if (typeof this.DataSourceValue === 'object' && Array.isArray(this.DataSourceValue.Data)) {
                data = this.DataSourceValue.Data;
                styles = this.DataSourceValue.Styles;
            }
        }
        this.Source = ChartData.GenerateData(this.ChartSettings, data, styles);
        switch (this.CompType) {
            case ChartComponent.Apex:
                const series = [];
                const labels = [];
                if (Array.isArray(this.Source) && this.Source.length > 1) {
                    if (this.ChartSettingsValue && typeof this.ChartSettingsValue.ChartType === 'number') {
                        if (this.ChartSettingsValue.ChartType === ChartType.radialBar) {
                            for (let i = 1; i < this.Source.length; i++) {
                                const row = this.Source[i];
                                if (Array.isArray(row) && row.length > 1) {
                                    labels.push(row[0]);
                                    series.push(row[1]);
                                }
                            }
                        } else if (this.ChartSettingsValue.ChartType === ChartType.heatmap) {
                            const firstRow = this.Source[0];
                            if (firstRow.length > 1) {
                                for (let i = 1; i < firstRow.length; i++) {
                                    series.push({
                                        name: firstRow[i],
                                        data: []
                                    });
                                }
                                for (let i = 1; i < this.Source.length; i++) {
                                    const row = this.Source[i];
                                    if (Array.isArray(row) && row.length > 1) {
                                        const xVal = row[0];
                                        for (let j = 1; j < row.length && j <= series.length; j++) {
                                            series[j - 1].data.push({
                                                x: xVal,
                                                y: row[j]
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                const options = Object.assign({}, this.ApexOptions);
                options.series = series;
                options.labels = labels;
                this.ApexOptions = options;
                break;
            case ChartComponent.Google:
                if (this.GoogleWrapper) {
                    if (Array.isArray(this.Source) && this.Source.length > 0) {
                        this.GoogleWrapper.setDataTable(google.visualization.arrayToDataTable(this.Source));
                        this.drawGoogleChart();
                    } else {
                        this.GoogleWrapper.setDataTable(null);
                        const chart = this.GoogleWrapper.getChart();
                        if (chart) {
                            chart.clearChart();
                        }
                    }
                }
                break;
            case ChartComponent.PrimeNG:
                const data = {
                    labels: [],
                    datasets: []
                };
                if (Array.isArray(this.Source) && this.Source.length > 0) {
                    if (this.ChartSettingsValue && typeof this.ChartSettingsValue.ChartType === 'number') {
                        const seriesRow = this.Source[0];
                        if (Array.isArray(seriesRow) && seriesRow.length > 1) {
                            if (this.ChartSettingsValue.ChartType === ChartType.radar) {
                                let ser: chartModel.SeriesSettings[] = [];
                                let colors = [];
                                if (this.ChartSettingsValue.Series) {
                                    ser = this.ChartSettingsValue.Series;
                                }
                                if (this.ChartPalette) {
                                    colors = this.ChartPalette;
                                } else if (this.ChartSettingsValue.SeriesColors) {
                                    colors = this.ChartSettingsValue.SeriesColors;
                                } else if (ThemeHelper.ActiveThemeChartPalette) {
                                    colors = ThemeHelper.ActiveThemeChartPalette;
                                }
                                for (let i = 1; i < seriesRow.length; i++) {
                                    const ds: any = {
                                        label: seriesRow[i],
                                        data: []
                                    };
                                    if (colors.length >= i) {
                                        const serColor = colors[i - 1];
                                        ds.pointBackgroundColor = serColor;
                                        ds.borderColor = serColor;
                                    }
                                    if (ser.length >= i) {
                                        const actSeries = ser[i - 1];
                                        if (typeof actSeries.Color === 'string') {
                                            ds.pointBackgroundColor = actSeries.Color;
                                            ds.borderColor = actSeries.Color;
                                        }
                                        if (actSeries.PointsVisible === false) {
                                            ds.pointRadius = 0;
                                        } else {
                                            if (typeof actSeries.PointSize === 'number' && actSeries.PointSize > 0) {
                                                ds.pointRadius = actSeries.PointSize;
                                            }
                                            if (typeof actSeries.PointShape === 'number') {
                                                switch (actSeries.PointShape) {
                                                    case PointShape.circle:
                                                        ds.pointStyle = 'circle';
                                                        break;
                                                    case PointShape.diamond:
                                                        ds.pointStyle = 'rectRot';
                                                        break;
                                                    case PointShape.square:
                                                        ds.pointStyle = 'rect';
                                                        break;
                                                    case PointShape.star:
                                                        ds.pointStyle = 'star';
                                                        break;
                                                    case PointShape.triangle:
                                                        ds.pointStyle = 'triangle';
                                                        break;
                                                }
                                            }
                                        }
                                        if (Array.isArray(actSeries.LineDashStyle) && actSeries.LineDashStyle.length > 1) {
                                            ds.borderDash = actSeries.LineDashStyle.slice();
                                        }
                                        if (typeof actSeries.LineWidth === 'number' && actSeries.LineWidth > 0) {
                                            ds.borderWidth = actSeries.LineWidth;
                                        }
                                    }
                                    data.datasets.push(ds);
                                }
                            } else if (this.ChartSettingsValue.ChartType === ChartType.polarArea) {
                                let ser: chartModel.SliceSettings[] = [];
                                let colors = [];
                                if (this.ChartSettingsValue) {
                                    if (this.ChartSettingsValue.PieSettings && this.ChartSettingsValue.PieSettings.Slices) {
                                        ser = this.ChartSettingsValue.PieSettings.Slices;
                                    }
                                    if (this.ChartPalette) {
                                        colors = this.ChartPalette;
                                    } else if (this.ChartSettingsValue.SeriesColors) {
                                        colors = this.ChartSettingsValue.SeriesColors;
                                    } else if (ThemeHelper.ActiveThemeChartPalette) {
                                        colors = ThemeHelper.ActiveThemeChartPalette;
                                    }
                                }
                                for (let i = 1; i < seriesRow.length; i++) {
                                    const ds: any = {
                                        label: seriesRow[i],
                                        data: []
                                    };
                                    if (colors.length >= i) {
                                        ds.backgroundColor = colors[i - 1];
                                    }
                                    if (ser.length >= i) {
                                        const actSeries = ser[i - 1];
                                        if (typeof actSeries.Color === 'string') {
                                            ds.backgroundColor = actSeries.Color;
                                        }
                                    }
                                    data.datasets.push(ds);
                                }
                            }
                            for (let i = 1; i < this.Source.length; i++) {
                                const dataRow = this.Source[i];
                                if (Array.isArray(dataRow) && dataRow.length > 0) {
                                    data.labels.push(dataRow[0]);
                                    for (let j = 1; j < dataRow.length && j <= data.datasets.length; j++) {
                                        data.datasets[j - 1].data.push(dataRow[j]);
                                    }
                                }
                            }
                        }
                    }
                    this.PrimeOptions.data = data;
                }
                break;
            case ChartComponent.Sunburst:
                if (Array.isArray(this.Source) && this.Source.length > 0) {
                    if (this.ChartSettingsValue && typeof this.ChartSettingsValue.ChartType === 'number') {
                        if (this.ChartSettingsValue.ChartType === ChartType.Sunburst) {
                            const options = Object.assign({}, this.SunburstOptions);
                            if (Array.isArray(this.Source) && this.Source.length > 1) {
                                let data = {};
                                data[options.Children ? options.Children : 'children'] = this.Source;
                                data[options.Label ? options.Label : 'name'] = '';
                                data[options.Value ? options.Value : 'value'] = '';
                                options.data = data;
                            } else {
                                options.data = this.Source
                            }

                            this.SunburstOptions = options;
                        }
                        //const seriesRow = this.Source[0];
                        //if (Array.isArray(seriesRow) && seriesRow.length > 1) {

                        //    //for (let i = 1; i < this.Source.length; i++) {
                        //    const dataRow = this.Source[i];
                        //    if (Array.isArray(dataRow) && dataRow.length > 0) {
                        //        data.labels.push(dataRow[0]);
                        //        for (let j = 1; j < dataRow.length && j <= data.datasets.length; j++) {
                        //            data.datasets[j - 1].data.push(dataRow[j]);
                        //        }
                        //    }
                        //}
                        //}
                    }
                }
                break;
        }
    }
    //#endregion
    drawGoogleChart() {
        setTimeout(() => {
            if (this.googleChart && this.GoogleWrapper && this.GoogleWrapper.getDataTable()) {
                this.GoogleWrapper.draw(this.googleChart.nativeElement);
            }
        }, 50);
    }

    onGCError(ev) {

    }

    onChartResize(event) {
        if (this.CompType === ChartComponent.Google) {
            this.drawGoogleChart();
        }
    }
    @Output() SelectionChanged = new EventEmitter<any>();
    Selection(event) {
        this.SelectionChanged.emit(event);
    }
}
