import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { DataSourceHelper } from '../../../../helpers/datasource.helper';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataModelService } from '../../../../services/datamodel.service';
import { StandardRequestBase } from '../../../../services/request-base';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-get-object-from-document-structure-settings-item',
    templateUrl: 'get.object.from.document.structure.settings.html'
})
export class GetObjectFromDocumentStructureSettings extends ContainerWorkflowDialogContent {
    Data = new GetObjectFromDocumentStructureSettingsData();
    VariableNodes: ABaseTreeNode[] = [];
    Templates = [];
    Models = [];
    Sources = [];
    Container = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getObjectFromDocumentStructureWFModule';
        reg.Caption = '@@Objekt aus Dokumentstruktur generieren';
        reg.GroupID = 'documentoperations';
        reg.Index = 20;
        reg.SettingsControl = GetObjectFromDocumentStructureSettings;
        reg.SettingsTypeHelper = new GetObjectFromDocumentStructureSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: StandardRequestBase, private dmService: DataModelService) {
        super();
    }

    ngOnInit() {
        this.service.executeGet('api/ocr', 'GetDocumentMatchings').subscribe(r => {
            if (r) {
                this.Templates = r;
            }
        });
        if (Array.isArray(this.FixedDataModels)) {
            this.Models = [...this.FixedDataModels];
            this.initData();
        } else {
            this.dmService.GetModels().subscribe(models => {
                if (models) {
                    this.Models = models;
                    this.initData();
                }
            });
        }
    }

    initData() {
        let dataModel;
        if (this.Data.DataModel && this.Models.some(x => x.SID === this.Data.DataModel)) {
            dataModel = this.Data.DataModel;
        } else if (this.Models.length === 1) {
            dataModel = this.Models[0].SID;
        }
        if (dataModel) {
            this.Data.DataModel = dataModel;
            const query = DataSourceHelper.GetDataSourceQuery(this.Data.DataModel, false, false, false);
            this.dmService.GetDataSourcesByQuery(query).subscribe(sources => {
                if (sources) {
                    this.Sources = sources;
                    if (this.Data.DataSource && sources.some(x => x.SID === this.Data.DataSource)) {
                        const params = 'dataModel=' + this.Data.DataModel + '&dataSource=' + this.Data.DataSource;
                        this.service.executeGet('api/dynamic', 'GetTablesByDataSource', params).subscribe(tables => {
                            if (tables) {
                                this.Container = tables;
                                if (!tables.some(x => x.SID === this.Data.Container)) {
                                    this.Data.Container = null;
                                    this.Data.ContainerInternalID = null;
                                }
                            }
                        });
                    } else {
                        this.Data.DataSource = null;
                        this.Data.Container = null;
                        this.Data.ContainerInternalID = null;
                    }
                } else {
                    this.Data.DataSource = null;
                    this.Data.Container = null;
                    this.Data.ContainerInternalID = null;
                }
            });
        } else {
            this.Data.DataModel = null;
            this.Data.DataSource = null;
            this.Data.Container = null;
            this.Data.ContainerInternalID = null;
        }
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetObjectFromDocumentStructureSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        const errors = [];
        if (!this.Data.StructureKey) {
            errors.push(TranslateHelper.TranslatorInstance.instant('@@Bitte vergeben Sie einen Status Key fuer das Dokument'));
        }
        if (!this.Data.Container) {
            errors.push(TranslateHelper.TranslatorInstance.instant('@@Bitte waehlen Sie einen Container'));
        }
        if (!this.Data.UseForState && !this.Data.StatusKey) {
            const text = '@@Bitte vergeben Sie einen Status Key fuer das Ergebnis oder setzen Sie das Ergebnis als aktuellen Parameter';
            errors.push(TranslateHelper.TranslatorInstance.instant(text));
        }
        if (errors.length > 0) {
            retVal.IsCorrect = false;
            retVal.Error = errors.join('\n');
        }
        return retVal;
    }

    getResult() {
        return this.Data;
    }

    modelChanged() {
        this.Data.DataSource = null;
        this.Data.Container = null;
        this.Data.ContainerInternalID = null;
        this.Sources = [];
        this.Container = [];
        const query = DataSourceHelper.GetDataSourceQuery(this.Data.DataModel, false, false, false);
        this.dmService.GetDataSourcesByQuery(query).subscribe(sources => {
            if (sources) {
                this.Sources = sources;
            }
        });
    }

    sourceChanged() {
        this.Data.Container = null;
        this.Data.ContainerInternalID = null;
        this.Container = [];
        const params = 'dataModel=' + this.Data.DataModel + '&dataSource=' + this.Data.DataSource;
        this.service.executeGet('api/dynamic', 'GetTablesByDataSource', params).subscribe(tables => {
            if (tables) {
                this.Container = tables;
            }
        });
    }

    tableChanged() {
        const table = this.Container.find(x => x.SID === this.Data.Container);
        if (table) {
            this.Data.ContainerInternalID = table.InternalID;
        } else {
            this.Data.ContainerInternalID = null;
        }
    }

    onVariableSelect(event) {
        if (event) {
            this.Data.StructureKey = event.Caption;
        }
    }
}

export class GetObjectFromDocumentStructureSettingsData extends AServiceWorkflowData {
    StructureKey: string;
    TemplateID: string;
    DataModel: string;
    DataSource: string;
    Container: string;
    ContainerInternalID: string;
    StatusKey: string;
    UseForState = false;
    GenerateReader = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DocumentOperations.GetObjectFromDocumentStructure.GetObjectFromDocumentStructureSettingsData';
    }
}

export class GetObjectFromDocumentStructureSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const found = new WorkflowExitInfo();
        found.ID = 0;
        if (settings && settings.GenerateReader) {
            found.Label = '@@Objekt-Reader erzeugt';
            found.Type = 'objData';
        } else {
            found.Label = '@@Objekt erzeugt';
        }
        const notFound = new WorkflowExitInfo();
        notFound.ID = 1;
        notFound.Label = '@@Kein passendes Template gefunden';
        return [found, notFound];
    }
    getEmptySettingsInstance() {
        return new GetObjectFromDocumentStructureSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}
