import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { ArrayHelpers, TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogResult } from '../../../models/enums/dialogresult.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { RequestColumn } from '../../../models/rest/requestcolumn';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataPreviewService } from '../../../services/data.preview.service';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { ContainerBusinessModelElementsDialog } from '../dialogs/container.businessmodel.elements.dialog';
import { ContainerBusinessModelLineDialog, VariableInfo } from '../dialogs/container.businessmodel.line.dialog';

@Component({
    selector: 'containerbusinessmodel-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerBusinessModelSettings extends BaseListSettings {

    //public static GetSettingsEntry() {
    //    return {
    //        Caption: '@@ContainerBusinessModel',
    //        ID: 'containerbusinessmodel',
    //        Icon: 'table_chart',
    //        Index: 15,
    //        Parent: 'relational',
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
    //            Value: 256
    //        },
    //        Content: ContainerBusinessModelSettings
    //    };
    //}

    constructor(private translate: TranslateService, private dataModelService: DataModelService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    loadList(handler: any) {
        const comp = this.Component;
        if (comp) {
            comp.Clear();
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Container.ContainerBusinessModel').subscribe((result) => {
                    if (result) {
                        const list = [];
                        result.forEach((item) => {
                            list.push({
                                Caption: item.Caption,
                                ID: item.SID,
                                IsCapsule: item.IsCapsule,
                                IsOverridden: item.IsOverridden
                            });
                        });
                        handler(list);
                    }
                });
            if (comp) {
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[4096]]; // RelationalRead
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                    comp.DataSources = sources;
                });
            }
        }
    }

    loadData(data: any) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((table) => {
                if (table) {
                    this.setSelectedItem(table);
                }
            });
        }
    }

    getNewItem() {
        return {
            DataModelID: this.InitArgs.SID,
            Name: this.translate.instant('@@Neues BusinessModel'),
            Lines: []
        };
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das BusinessModel \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@BusinessModel \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@BusinessModel loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        // nichts tun, gibt kein Delete
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@BusinessModel \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@BusinessModel speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        if (item) {
            this.dataModelService.SaveContainer('evidanza.MiddleWare.Shared.Container.ContainerBusinessModel', item).subscribe(result => {
                handler(result, result.SID, result.Caption);
            });
        }
    }

    getContentType() {
        return ContainerBusinessModelDetail;
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerbusinessmodel-detail',
    templateUrl: './containerbusinessmodel.settings.html',
    styleUrls: ['./containerbusinessmodel.settings.css']
})
export class ContainerBusinessModelDetail extends ADataModelDetail {
    DataSources = [];
    TableInfoDict = {};
    TableList = [];
    SelectedTable;
    RootNodes = [];
    VisibleLines = [];
    HasRestRow = false;
    DBElements: string[] = null;

    private static updateVisibleLines(lines, result) {
        lines.forEach(x => {
            result.push(x);
            if (x.Expanded && x.Children && x.Children.length > 0) {
                ContainerBusinessModelDetail.updateVisibleLines(x.Children, result);
            }
        });
    }

    private static getLine(line, depth) {
        const retVal = {
            Data: line,
            Depth: depth,
            Parent: null,
            Children: [],
            Expanded: false,
            Selected: false,
            FormulaText: null,
            Elements: null
        };
        return retVal;
    }

    private static fillLineObject(line, object) {
        object[line.Data.Key] = line.Data;
        line.Children.forEach(child => {
            ContainerBusinessModelDetail.fillLineObject(child, object);
        });
        return object;
    }

    private static UpdateFormulas(nodes: any[], checkKey: string, variableInfo: VariableInfo) {
        if (nodes) {
            nodes.forEach(x => {
                if (x.Data.Formula && x.Data.Formula.indexOf(checkKey) > -1) {
                    x.FormulaText = variableInfo.getFormulaText(x.Data.Formula);
                }
                ContainerBusinessModelDetail.UpdateFormulas(x.Children, checkKey, variableInfo);
            });
        }
    }

    private static UpdateAllFormulas(nodes: any[], variableInfo: VariableInfo) {
        if (nodes) {
            nodes.forEach(x => {
                if (x.Data.Formula) {
                    x.FormulaText = variableInfo.getFormulaText(x.Data.Formula);
                }
                ContainerBusinessModelDetail.UpdateAllFormulas(x.Children, variableInfo);
            });
        }
    }

    constructor(private service: DataSettingsService, private preview: DataPreviewService) {
        super();
    }

    setSelectedItem(item) {
        let oldElements;
        if (this.SelectedItem && item && this.SelectedItem.BaseTable === item.BaseTable && this.SelectedItem.KeyField === item.KeyField) {
            oldElements = this.DBElements;
        }
        super.setSelectedItem(item);
        this.TableList = [];
        this.SelectedTable = null;
        this.RootNodes = [];
        this.VisibleLines = [];
        this.HasRestRow = false;
        this.DBElements = null;
        if (item) {
            const rangeLines = [];
            if (item.Lines) {
                const rootNodes = [];
                const unsorted = [];
                const rowDict = {};
                const variables = [];
                item.Lines.forEach(x => {
                    const row = ContainerBusinessModelDetail.getLine(x, 0);
                    rowDict[x.Key] = row;
                    const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                    variables.push(variable);
                    if (x.ParentKey) {
                        const parent = rowDict[x.ParentKey];
                        if (parent) {
                            parent.Children.push(row);
                            row.Parent = parent;
                            row.Depth = parent.Depth + 1;
                        } else {
                            unsorted.push(row);
                        }
                    } else {
                        rootNodes.push(row);
                    }
                    if (x.IsRestRow) {
                        this.HasRestRow = true;
                    }
                    if (x.KeyRanges && x.KeyRanges.length > 0) {
                        rangeLines.push(row);
                    } else if (x.IncludeKeys && x.IncludeKeys.length > 0) {
                        if (x.ExcludeKeys && x.ExcludeKeys.length > 0) {
                            row.Elements = x.IncludeKeys.filter(y => x.ExcludeKeys.indexOf(y) < 0).join('\n');
                        } else {
                            row.Elements = x.IncludeKeys.join('\n');
                        }
                    }
                });
                unsorted.forEach(x => {
                    const parent = rowDict[x.Data.ParentKey];
                    if (parent) {
                        parent.Children.push(x);
                        x.Parent = parent;
                        x.Depth = parent.Depth + 1;
                    } else {
                        rootNodes.push(x);
                        x.Data.ParentKey = null;
                    }
                });
                const varInfo = new VariableInfo(variables);
                Object.keys(rowDict).forEach(x => {
                    const row = rowDict[x];
                    row.FormulaText = varInfo.getFormulaText(row.Data.Formula);
                });
                this.RootNodes = rootNodes;
                this.updateVisibleLines();
            }
            if (item.DataSourceId) {
                const actTables = this.TableInfoDict[item.DataSourceId];
                if (actTables) {
                    this.TableList = actTables;
                    if (item.BaseTable) {
                        this.SelectedTable = actTables.find(y => y.SID === item.BaseTable);
                        this.checkRangeLines(item, rangeLines, oldElements);
                    }
                } else {
                    this.service.GetTableInfosByDataSource(item.DataSourceId).subscribe(x => {
                        if (x) {
                            const list = x.filter(y => y.TableType !== 'evidanza.MiddleWare.Shared.Container.ContainerBusinessModel');
                            this.TableInfoDict[item.DataSourceId] = list;
                            this.TableList = list;
                            if (item.BaseTable) {
                                this.SelectedTable = list.find(y => y.SID === item.BaseTable);
                                this.checkRangeLines(item, rangeLines, oldElements);
                            }
                        }
                    });
                }
            }
        }
    }

    checkRangeLines(item, rangeLines, oldElements) {
        if (item === this.SelectedItem && rangeLines && rangeLines.length > 0) {
            if (oldElements) {
                this.DBElements = oldElements;
                rangeLines.forEach(x => {
                    this.setElementText(x);
                });
            } else if (this.SelectedTable) {
                const keyField = this.SelectedTable.Fields.find(x => x.SID === item.KeyField);
                const filter = RequestOptions.CleanRequestOptions();
                const keyCol = new RequestColumn();
                keyCol.Name = keyField.Name;
                filter.Columns.push(keyCol);
                this.preview.ExecuteQuery(item.BaseTable, filter).subscribe(rows => {
                    if (item === this.SelectedItem && rows) {
                        const keyList = [];
                        rows.forEach(x => {
                            keyList.push(x[keyField.Name]);
                        });
                        ArrayHelpers.sortAlphabetical(keyList);
                        this.DBElements = keyList;
                        rangeLines.forEach(x => {
                            this.setElementText(x);
                        });
                    }
                });
            }
        }
    }

    setElementText(line) {
        if (line && line.Data && this.DBElements) {
            const allNeg: any = {};
            const allPos: any = {};
            if (line.Data.ExcludeKeys) {
                line.Data.ExcludeKeys.forEach(x => {
                    allNeg[x] = true;
                });
            }
            if (line.Data.IncludeKeys) {
                line.Data.IncludeKeys.forEach(x => {
                    allPos[x] = true;
                });
            }
            if (line.Data.KeyRanges) {
                line.Data.KeyRanges.forEach((x: string) => {
                    let resultObj = allPos;
                    let trimmed = x.trim();
                    if (trimmed.startsWith('<>')) {
                        resultObj = allNeg;
                        trimmed = trimmed.substring(2);
                    }
                    const pointIndex = trimmed.indexOf('..');
                    if (pointIndex > -1) {
                        const startKey = trimmed.substring(0, pointIndex);
                        const endKey = trimmed.substring(pointIndex + 2);
                        const starStartIndex = startKey.indexOf('*');
                        const starEndIndex = endKey.indexOf('*');
                        if (starStartIndex > -1 || starEndIndex > -1) {
                            const shortStartKey = startKey.substring(starStartIndex + 1).toLowerCase();
                            const shortEndKey = endKey.substring(starEndIndex + 1).toLowerCase();
                            const startlength = shortStartKey.length;
                            const endlength = shortEndKey.length;
                            this.DBElements.forEach(elem => {
                                const elemStart = elem.substring(elem.length - startlength).toLowerCase();
                                const elemEnd = elem.substring(elem.length - endlength).toLowerCase();
                                if (elemStart >= shortStartKey && elemEnd <= shortEndKey) {
                                    resultObj[elem] = true;
                                }
                            });
                        } else {
                            const startToLower = startKey.toLowerCase();
                            const endToLower = endKey.toLowerCase();
                            this.DBElements.some(elem => {
                                const elemToLower = elem.toLowerCase();
                                if (elemToLower <= endToLower) {
                                    if (elemToLower >= startToLower) {
                                        resultObj[elem] = true;
                                    }
                                    return false;
                                }
                                return true;
                            });
                        }
                    } else {
                        const starIndex = trimmed.indexOf('*');
                        const qmIndex = trimmed.indexOf('?');
                        if (starIndex > -1 || qmIndex > -1) {
                            let match = trimmed.replace(/\?/g, '\\w{1}');
                            match = match.replace(/\*/g, '.*');
                            const regEx = new RegExp('^' + match + '$');
                            this.DBElements.forEach(elem => {
                                if (regEx.test(elem)) {
                                    resultObj[elem] = true;
                                }
                            });
                        } else {
                            if (this.DBElements.some(elem => elem === trimmed)) {
                                resultObj[trimmed] = true;
                            }
                        }
                    }
                });
            }
            Object.keys(allNeg).forEach(x => {
                delete allPos[x];
            });
            line.Elements = '\n' + Object.keys(allPos).join('\n');
        }
    }

    updateVisibleLines() {
        const lines = [];
        ContainerBusinessModelDetail.updateVisibleLines(this.RootNodes, lines);
        this.VisibleLines = lines;
    }

    Clear() {
        this.DataSources = [];
        this.TableInfoDict = {};
        this.TableList = [];
        this.SelectedTable = null;
        this.DBElements = null;
    }

    dataSourceChanged() {
        this.TableList = [];
        this.SelectedTable = null;
        this.DBElements = null;
        if (this.SelectedItem) {
            this.SelectedItem.BaseTable = null;
            this.SelectedItem.KeyField = null;
            this.SelectedItem.NameField = null;
            this.SelectedItem.Lines.forEach(line => {
                line.IncludeKeys = [];
                line.ExcludeKeys = [];
                line.KeyRanges = [];
            });
            if (this.SelectedItem.DataSourceId) {
                const actTables = this.TableInfoDict[this.SelectedItem.DataSourceId];
                if (actTables) {
                    this.TableList = actTables;
                } else {
                    this.service.GetTableInfosByDataSource(this.SelectedItem.DataSourceId).subscribe(x => {
                        if (x) {
                            const list = x.filter(y => y.TableType !== 'evidanza.MiddleWare.Shared.Container.ContainerBusinessModel');
                            this.TableInfoDict[this.SelectedItem.DataSourceId] = list;
                            this.TableList = list;
                        }
                    });
                }
            }
        }
    }

    baseTableChanged() {
        this.SelectedTable = null;
        this.DBElements = null;
        if (this.SelectedItem) {
            this.SelectedItem.KeyField = null;
            this.SelectedItem.NameField = null;
            this.SelectedItem.Lines.forEach(line => {
                line.IncludeKeys = [];
                line.ExcludeKeys = [];
                line.KeyRanges = [];
            });
            if (this.SelectedItem.BaseTable) {
                this.SelectedTable = this.TableList.find(x => x.SID === this.SelectedItem.BaseTable);
            }
        }
    }

    addMainRow(isRest: boolean) {
        if (this.SelectedItem) {
            ContainerBusinessModelLineDialog.ShowDialog(this.SelectedItem, {
                Unary: 0,
                ValueFilter: 0,
                IsRestRow: isRest
            }, (r) => {
                if (isRest) {
                    this.HasRestRow = true;
                }
                if (!this.SelectedItem.Lines) {
                    this.SelectedItem.Lines = [];
                }
                this.SelectedItem.Lines.push(r);
                const line = ContainerBusinessModelDetail.getLine(r, 0);
                if (r.Formula) {
                    const variables = [];
                    this.SelectedItem.Lines.forEach(x => {
                        const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                        variables.push(variable);
                    });
                    const vi = new VariableInfo(variables);
                    line.FormulaText = vi.getFormulaText(r.Formula);
                }
                this.RootNodes.push(line);
                this.VisibleLines.push(line);
                this.OnItemChanged();
            });
        }
    }

    addSubRow(line, i) {
        if (this.SelectedItem) {
            ContainerBusinessModelLineDialog.ShowDialog(this.SelectedItem, {
                ParentKey: line.Data.Key,
                Unary: 0,
                ValueFilter: 0
            }, (r) => {
                this.SelectedItem.Lines.push(r);
                const newLine = ContainerBusinessModelDetail.getLine(r, line.Depth + 1);
                newLine.Parent = line;
                if (r.Formula) {
                    const variables = [];
                    this.SelectedItem.Lines.forEach(x => {
                        const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                        variables.push(variable);
                    });
                    const vi = new VariableInfo(variables);
                    newLine.FormulaText = vi.getFormulaText(r.Formula);
                }
                line.Children.push(newLine);
                if (line.Expanded) {
                    this.VisibleLines.splice(i + line.Children.length, 0, newLine);
                } else {
                    this.expandedChanged(line);
                }
                this.OnItemChanged();
            });
        }
    }

    expandedChanged(line) {
        line.Expanded = !line.Expanded;
        this.updateVisibleLines();
    }

    addElements(line) {
        if (this.SelectedItem && this.SelectedItem.BaseTable && this.SelectedItem.KeyField && this.SelectedTable) {
            const keyField = this.SelectedTable.Fields.find(x => x.SID === this.SelectedItem.KeyField);
            if (keyField) {
                let nameField = keyField;
                if (this.SelectedItem.NameField) {
                    const nf = this.SelectedTable.Fields.find(x => x.SID === this.SelectedItem.NameField);
                    if (nf) {
                        nameField = nf;
                    }
                }
                ContainerBusinessModelElementsDialog.ShowDialog(this.SelectedItem, line.Data, keyField.Name, nameField.Name).then(x => {
                    if (x === DialogResult.Ok) {
                        if (line.Data.KeyRanges && line.Data.KeyRanges.length > 0) {
                            this.checkRangeLines(this.SelectedItem, [line], this.DBElements);
                        } else if (line.Data.IncludeKeys && line.Data.IncludeKeys.length > 0) {
                            line.Elements = line.Data.IncludeKeys.join('\n');
                        } else {
                            line.Elements = null;
                        }
                    }
                });
            }
        }
    }

    editLine(line) {
        if (this.SelectedItem) {
            ContainerBusinessModelLineDialog.ShowDialog(this.SelectedItem, line.Data, (r) => {
                const lineIndex = this.SelectedItem.Lines.indexOf(line.Data);
                if (lineIndex > -1) {
                    this.SelectedItem.Lines.splice(lineIndex, 1, r);

                    let vi;
                    let checkFormulaText = r.Name !== line.Data.Name;

                    if (line.Data.Key !== r.Key) {
                        if (!r.Name) { // Wenn Zeile keinen Namen mehr hat und sich Key geändert hat, muss FormelText geprüft werden
                            checkFormulaText = true;
                        }
                        const variables = [];
                        this.SelectedItem.Lines.forEach(x => {
                            if (x.ParentKey === line.Data.Key) {
                                x.ParentKey = r.Key;
                            }
                            if (x.Formula) {
                                const oldKey = '[' + line.Data.Key + ']';
                                while (x.Formula.indexOf(oldKey) > -1) {
                                    x.Formula = x.Formula.replace(oldKey, '[' + r.Key + ']');
                                }
                            }
                            const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                            variables.push(variable);
                        });
                        vi = new VariableInfo(variables);
                    }

                    if (r.Formula !== line.Data.Formula) {
                        if (!vi) {
                            const variables = [];
                            this.SelectedItem.Lines.forEach(x => {
                                const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                                variables.push(variable);
                            });
                            vi = new VariableInfo(variables);
                        }
                        line.FormulaText = vi.getFormulaText(r.Formula);
                    }

                    line.Data = r;

                    if (checkFormulaText) {
                        if (!vi) {
                            const variables = [];
                            this.SelectedItem.Lines.forEach(x => {
                                const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                                variables.push(variable);
                            });
                            vi = new VariableInfo(variables);
                        }
                        ContainerBusinessModelDetail.UpdateFormulas(this.RootNodes, '[' + r.Key + ']', vi);
                    }

                    this.OnItemChanged();
                }
            });
        }
    }

    deleteLine(line, i) {
        if (this.SelectedItem) {
            const childLines = ContainerBusinessModelDetail.fillLineObject(line, {});
            const keyList = Object.keys(childLines);
            if (this.SelectedItem.Lines.some(x =>
                x.Formula && !childLines[x.Key] && keyList.some(key => x.Formula.indexOf('[' + key + ']') > -1)
            )) {
                const text = '@@Die Zeile kann nicht geloescht werden, da mindestens eins der enthaltenen Elemente in einer Formel verwendet wird.';
                MessageBoxHelper.ShowDialog(new TranslateFormatText(text), new TranslateFormatText('@@Zeile loeschen'),
                    MessageBoxButtons.Ok, MessageBoxIcon.Information);
            } else {
                // TODO: CheckForSubRestRow
                const text = '@@Wollen Sie wirklich diese und alle untergeordneten Zeilen loeschen?';
                MessageBoxHelper.ShowDialog(new TranslateFormatText(text), new TranslateFormatText('@@Zeile loeschen'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Information).then(x => {
                        if (x === MessageBoxResult.Yes) {
                            const lines = [];
                            this.SelectedItem.Lines.forEach(x => {
                                if (!childLines[x.Key]) {
                                    lines.push(x);
                                }
                            });
                            this.SelectedItem.Lines = lines;
                            if (line.Data.IsRestRow) {
                                this.HasRestRow = false;
                            }
                            let childList = this.RootNodes;
                            if (line.Parent) {
                                childList = line.Parent.Children;
                            }
                            const childIndex = childList.indexOf(line);
                            if (childIndex > 0) {
                                childList.splice(childIndex, 1);
                            }
                            this.updateVisibleLines();
                        }
                    });
            }
        }
    }

    moveUp(line) {
        if (this.SelectedItem) {
            let lineIndex = -1;
            let previousIndex = -1;
            if (line.Data.ParentKey) {
                this.SelectedItem.Lines.some((l, i) => {
                    if (l === line.Data) {
                        lineIndex = i;
                        return true;
                    }
                    if (l.ParentKey === line.Data.ParentKey) {
                        previousIndex = i;
                    }
                    return false;
                });
            } else {
                this.SelectedItem.Lines.some((l, i) => {
                    if (l === line.Data) {
                        lineIndex = i;
                        return true;
                    }
                    if (!l.ParentKey) {
                        previousIndex = i;
                    }
                    return false;
                });
            }
            if (lineIndex > -1 && previousIndex > -1) {
                this.SelectedItem.Lines.splice(lineIndex, 1);
                this.SelectedItem.Lines.splice(previousIndex, 0, line.Data);
                let childList = this.RootNodes;
                if (line.Parent) {
                    childList = line.Parent.Children;
                }
                const childIndex = childList.indexOf(line);
                if (childIndex > 0) {
                    childList.splice(childIndex, 1);
                    childList.splice(childIndex - 1, 0, line);
                }
                this.updateVisibleLines();
            }
        }
    }

    moveDown(line) {
        if (this.SelectedItem) {
            let lineIndex = -1;
            let nextIndex = -1;
            if (line.Data.ParentKey) {
                this.SelectedItem.Lines.some((l, i) => {
                    if (l === line.Data) {
                        lineIndex = i;
                    } else if (lineIndex > -1 && l.ParentKey === line.Data.ParentKey) {
                        nextIndex = i;
                        return true;
                    }
                    return false;
                });
            } else {
                this.SelectedItem.Lines.some((l, i) => {
                    if (l === line.Data) {
                        lineIndex = i;
                    } else if (lineIndex > -1 && !l.ParentKey) {
                        nextIndex = i;
                        return true;
                    }
                    return false;
                });
            }
            if (lineIndex > -1 && nextIndex > -1) {
                this.SelectedItem.Lines.splice(lineIndex, 1);
                this.SelectedItem.Lines.splice(nextIndex, 0, line.Data);
                let childList = this.RootNodes;
                if (line.Parent) {
                    childList = line.Parent.Children;
                }
                const childIndex = childList.indexOf(line);
                if (childIndex > -1 && childIndex < childList.length - 1) {
                    childList.splice(childIndex, 1);
                    childList.splice(childIndex + 1, 0, line);
                }
                this.updateVisibleLines();
            }
        }
    }

    numberKeys() {
        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie wirklich alle Zeilen neu nummerieren?'),
            new TranslateFormatText('@@Achtung'), MessageBoxButtons.YesNo, MessageBoxIcon.Warning).then(x => {
                if (x === MessageBoxResult.Yes) {
                    const map = new Map<string, string>();
                    this.numberKeysInternal(this.RootNodes, '', map);
                    const variables = [];
                    this.SelectedItem.Lines.forEach(line => {
                        if (line.Formula) {
                            map.forEach((v, k) => {
                                while (line.Formula.indexOf(k) > -1) {
                                    line.Formula = line.Formula.replace(k, v);
                                }
                            });
                        }
                        const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(line);
                        variables.push(variable);
                    });
                    ContainerBusinessModelDetail.UpdateAllFormulas(this.RootNodes, new VariableInfo(variables));
                }
            });
    }

    private numberKeysInternal(nodes, prefix: string, keymapping: Map<string, string>) {
        if (nodes) {
            nodes.forEach((x, i) => {
                const oldKey = x.Data.Key;
                let newKey = (i < 9 ? '0' : '') + (i + 1);
                if (prefix) {
                    newKey = prefix + '.' + newKey;
                    x.Data.Key = newKey;
                } else {
                    x.Data.Key = newKey + '.';
                }
                const changeKey = '[' + UUID.UUID() + ']';
                keymapping.set(changeKey, '[' + newKey + ']');
                const oldFormulaKey = '[' + oldKey + ']';
                this.SelectedItem.Lines.forEach(line => {
                    if (line.Formula) {
                        while (line.Formula.indexOf(oldFormulaKey) > -1) {
                            line.Formula = line.Formula.replace(oldFormulaKey, changeKey);
                        }
                    }
                });
                this.numberKeysInternal(x.Children, newKey, keymapping);
            });
        }
    }
}
