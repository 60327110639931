import { Directive, EventEmitter, OnInit, Output } from "@angular/core";
import { MediaSource } from '../../../../models/datamodel/mediasource.model';

@Directive()
export class BaseMediaSourcePanel implements OnInit {
    MediaSourceType: any;
    MediaSource: MediaSource;

    ngOnInit(): void {        
    }

    @Output() OnDataChange = new EventEmitter<any>();
    onChange(ev) {
        this.OnDataChange.emit();
    }
}