import { Directive } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ConditionOperator } from '../../../models/enums/conditionoperator.enum';
import { ChartConditionFormatStyle } from '../../../models/tasks/conditional.model';
import { ATaskPanel } from '../a.task.panel';

@Directive()
export abstract class ConditionalSeriesFormattingPanel extends ATaskPanel {
    selectedNode;
    CompareTypes;

    constructor() {
        super();
        this.CompareTypes = EnumHelper.GetDropdownValues(ConditionOperator);
    }

    useCondChange(ev) {
        if (ev && ev.checked && this.SettingsValue) {
            if (!this.SettingsValue.Conditions || this.SettingsValue.Conditions.length === 0) {
                this.SettingsValue.Conditions = [new ChartConditionFormatStyle()];
            }
        }
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
        }
    }

    getCaption(cond) {
        let retVal = '';
        if (cond && cond.Condition) {
            retVal = ConditionOperator[cond.Condition.ConditionOperator];
            if (cond.Condition.ConditionOperator !== ConditionOperator.IsNull &&
                cond.Condition.ConditionOperator !== ConditionOperator.NotNull &&
                typeof cond.Condition.Value1 === 'number') {
                retVal += ' ' + cond.Condition.Value1;
                if ((cond.Condition.ConditionOperator === ConditionOperator.Between ||
                    cond.Condition.ConditionOperator === ConditionOperator.NotBetween) &&
                    typeof cond.Condition.Value2 === 'number') {
                    retVal += ' & ' + cond.Condition.Value2;
                }
            }
        }
        return retVal;
    }

    addItem() {
        if (this.SettingsValue) {
            const cond = new ChartConditionFormatStyle();
            if (this.SettingsValue.Conditions) {
                this.SettingsValue.Conditions.push(cond);
            } else {
                this.SettingsValue.Conditions = [cond];
            }
            this.selectedNode = cond;
        }
    }

    deleteItem() {
        if (this.selectedNode && this.SettingsValue && this.SettingsValue.Conditions && this.SettingsValue.Conditions.length > 1) {
            const index = this.SettingsValue.Conditions.indexOf(this.selectedNode);
            if (index > 0) {
                this.SettingsValue.Conditions.splice(index, 1);
                this.selectedNode = this.SettingsValue.Conditions[0];
            }
        }
    }

    moveUp() {
        if (this.selectedNode && this.SettingsValue && this.SettingsValue.Conditions) {
            const index = this.SettingsValue.Conditions.indexOf(this.selectedNode);
            if (index > 0) {
                this.SettingsValue.Conditions.splice(index, 1);
                this.SettingsValue.Conditions.splice(0, 0, this.selectedNode);
            }
        }
    }

    moveOneUp() {
        if (this.selectedNode && this.SettingsValue && this.SettingsValue.Conditions) {
            const index = this.SettingsValue.Conditions.indexOf(this.selectedNode);
            if (index > 0) {
                this.SettingsValue.Conditions.splice(index, 1);
                this.SettingsValue.Conditions.splice(index - 1, 0, this.selectedNode);
            }
        }
    }

    moveOneDown() {
        if (this.selectedNode && this.SettingsValue && this.SettingsValue.Conditions) {
            const index = this.SettingsValue.Conditions.indexOf(this.selectedNode);
            if (index > -1 && index < this.SettingsValue.Conditions.length - 1) {
                this.SettingsValue.Conditions.splice(index, 1);
                this.SettingsValue.Conditions.splice(index + 1, 0, this.selectedNode);
            }
        }
    }

    moveDown() {
        if (this.selectedNode && this.SettingsValue && this.SettingsValue.Conditions) {
            const index = this.SettingsValue.Conditions.indexOf(this.selectedNode);
            if (index > -1 && index < this.SettingsValue.Conditions.length - 1) {
                this.SettingsValue.Conditions.splice(index, 1);
                this.SettingsValue.Conditions.push(this.selectedNode);
            }
        }
    }
}
