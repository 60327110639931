import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBaseComponent } from '../components/controls/base.component';
import { MenuTabProperty } from '../models/menutab/menutab.property.model';

export const REGISTRY = new Map<string, IBaseComponent>();
export const MENUTABREGISTRY = [];
export const CONTROLGROUPS = [];
export const PROPERTYGROUPS = [];
export const PROPERTYPANELS = [];
export const PROPERTIES: MenuTabProperty[] = [];
export const WIDGETREGISTRY = new Map<string, IBaseComponent>();
export const GLOBALEVENTREGISTRY = [];
export const LAYOUTEVENTREGISTRY = [];

@Injectable()
export class DynamicComponentInjector {
  factoryResolver: ComponentFactoryResolver;
  rootViewContainer;
  constructor(factoryResolver: ComponentFactoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  //addDynamicComponent(DynamicComponent) {
  //  if (DynamicComponent && DynamicComponent.ElementType) {
  //    const factory = this.factoryResolver.resolveComponentFactory(REGISTRY[DynamicComponent.ElementType].Control);
  //    let component = factory.create(this.rootViewContainer.parentInjector);
  //    //(<IBaseComponent>component.instance).BindValues = DynamicComponent;
  //    //DynamicComponent.Instance = <IBaseComponent>component.instance;
  //    (<IBaseComponent>component.instance).LayoutElement = DynamicComponent;

  //    this.rootViewContainer.insert(component.hostView);
  //  } else {
  //      console.log("m���h");
  //  }
  //}
}

@Injectable({
  providedIn: 'root',
})
export class FilteredControlService {
  constructor() {}
  private filterdControls = new BehaviorSubject<string>('');

  filteredControlItems$ = this.filterdControls.asObservable();

  setfilterdControls(value: string) {
    this.filterdControls.next(value);
  }

}
