import { Output, Input, EventEmitter, Component } from '@angular/core';

@Component({
  selector: 'textblock-document-header',
  templateUrl: './textblock.document.header.html',
  styleUrls: ['./textblock.document.header.css']
})
export class TextBlockDocumentHeader {
  HeaderValue;
  @Input()
  get Header() {
    return this.HeaderValue;
  }
  set Header(val) {
    this.HeaderValue = val;
    this.HeaderValueChange.emit(val);
  }

  @Output() HeaderValueChange = new EventEmitter<any>();

  SubTextValue;
  @Input()
  get SubText() {
    return this.SubTextValue;
  }
  set SubText(val) {
    this.SubTextValue = val;
    this.SubTextValueChange.emit(val);
  }

  @Output() SubTextValueChange = new EventEmitter<any>();
}
