import { BorderThemeControl } from "../appbuilder/controls/borderselection/borderselection.theme.control";
import { BoxShadowThemeControl } from "../appbuilder/controls/boxshadow/boxshadow.theme.control";
import { ButtonThemeControl } from "../appbuilder/controls/button/button.theme.control";
import { CheckBoxThemeControl } from "../appbuilder/controls/checkbox/checkbox.control";
import { ColorpickerThemeControl } from "../appbuilder/controls/colorpicker/colorpicker.theme.control";
import { ComboboxThemeControl } from "../appbuilder/controls/combobox/combobox.theme.control";
import { DateTimeThemeControl } from "../appbuilder/controls/datetime/datetime.control";
import { FileSelectionThemeControl } from "../appbuilder/controls/fileselection/fileselection.theme.control";
import { FormulaTextBoxThemeControl } from "../appbuilder/controls/formulabox/formula.textbox.theme.control";
import { GradientpickerThemeControl } from "../appbuilder/controls/gradientpicker/gradientpicker.theme.control";
import { HeaderThemeControl } from "../appbuilder/controls/header/header.theme.control";
import { InputswitchThemeControl } from "../appbuilder/controls/inputswitch/inputswitch.theme.control";
import { ListBoxThemeControl } from "../appbuilder/controls/listbox/listbox.theme.control";
import { MonacoThemeControl } from "../appbuilder/controls/monaco/monaco.theme.control";
import { TextAreaThemeControl } from "../appbuilder/controls/textarea/textarea.theme.control";
import { TextboxThemeControl } from "../appbuilder/controls/textbox/textbox.theme.control";
import { TogglebuttonThemeControl } from "../appbuilder/controls/togglebutton/togglebutton.theme.control";
import { TogglebuttongroupThemeControl } from "../appbuilder/controls/togglebuttongroup/togglebuttongroup.theme.control";
import { TranslationTextBoxThemeControl } from "../appbuilder/controls/translation/translation.textbox.theme.control";

export const APP_THEME_CONTROLS = [
    ComboboxThemeControl,
    DateTimeThemeControl,
    TextboxThemeControl,
    InputswitchThemeControl,
    ColorpickerThemeControl,
    GradientpickerThemeControl,
    TogglebuttongroupThemeControl,
    TogglebuttonThemeControl,
    ButtonThemeControl,
    TranslationTextBoxThemeControl,
    CheckBoxThemeControl,
    ListBoxThemeControl,
    FormulaTextBoxThemeControl,
    FileSelectionThemeControl,
    TextAreaThemeControl,
    HeaderThemeControl,
    BorderThemeControl,
    BoxShadowThemeControl,
    MonacoThemeControl
]