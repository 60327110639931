import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class YearFormula extends AFormula {
    static StaticID = '7e99decd-df6c-4123-a4b7-39d0edfd0eec';

    ID(): string {
        return YearFormula.StaticID;
    }

    Name(): string {
        return 'Year';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Gibt in Verbindung mit einer Zeitangabe das Jahr aus');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 10;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Argument') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (maxType === ValueType.Datetime && args && args.length > 0) {
            const date = args[0].Value;
            if (date instanceof Date && !isNaN(date.getTime())) {
                retVal.Type = ValueType.Long;
                retVal.Value = date.getFullYear();
            }
        }
        return retVal;
    }
}
