import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult, MultiNode } from '../../datadescription/multi/multiresult.model';
import { ChartStyleMerger, SeriesValueStyle } from '../../styling/chart.style.merger';
import { ITaskExecuter } from '../atask.model';
import { TaskHelper } from '../task.helper';
import { ChartSeriesResult, SeriesInfo, SeriesValueInfo } from './chart.format.model';
import { AxisType } from '../../enums/query.enum';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { SeriesSettings } from '../../controls/chart.model';

export class ChartSeriesFormattingTaskExecuter implements ITaskExecuter {
    MeasureUniqueID = -1;
    LevelUniqueID = -1;
    LevelMember: string;
    OppositeLevel = -1;
    OppositeMember: string;
    UseConditions = false;
    Conditions = [];

    MeasuresOnSeries = false;
    Settings: SeriesSettings;
    SeriesValueStyle: SeriesValueStyle;
    Result: ChartSeriesResult;
    Merger: ChartStyleMerger;
    Matrix: MultiResult;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Matrix = result;
        if (dataDescription) {
            this.MeasuresOnSeries = dataDescription.ShowMeasureOnAxis === AxisType.Y_Axis;
        }
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.LevelMember === 'string') {
                this.LevelMember = settings.LevelMember;
            }
            if (typeof settings.OppositeLevel === 'number') {
                this.OppositeLevel = settings.OppositeLevel;
            }
            if (typeof settings.OppositeMember === 'string') {
                this.OppositeMember = settings.OppositeMember;
            }
            if (typeof settings.UseConditions === 'boolean') {
                this.UseConditions = settings.UseConditions;
            }
            if (settings.UseConditions && settings.Conditions) {
                const conditions = [];
                settings.Conditions.forEach(cond => {
                    if (cond.Condition && cond.Style) {
                        const svs = ChartStyleMerger.GetSVStyle(cond.Style);
                        if (svs) {
                            conditions.push({
                                Condition: cond.Condition,
                                Style: svs
                            });
                        }
                    }
                });
                this.Conditions = conditions;
                if (conditions.length > 0) {
                    this.Merger = ChartStyleMerger.GetChartStyleMerger(context);
                }
            }
            if (settings.Style) {
                this.Settings = settings.Style;
                this.SeriesValueStyle = ChartStyleMerger.GetSVStyle(this.Settings);
                if (this.SeriesValueStyle) {
                    this.Merger = ChartStyleMerger.GetChartStyleMerger(context);
                }
            }
        }
        if (context) {
            this.Result = context.ChartResult;
        }
    }

    Execute() {
        if (this.Result && this.Settings) {
            if (this.UseConditions && this.Conditions.length === 0) {
                return;
            }
            if (this.MeasuresOnSeries && this.MeasureUniqueID > -1) {
                if (this.LevelUniqueID > -1) {
                    if (this.LevelMember) {
                        this.Result.Series.forEach(series => {
                            if (series.MeasureNode && series.MeasureNode.UniqueID === this.MeasureUniqueID
                                && series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID
                                && series.AxisNode.MemberId === this.LevelMember) {
                                this.FormatSeries(series);
                            }
                        });
                    } else {
                        this.Result.Series.forEach(series => {
                            if (series.MeasureNode && series.MeasureNode.UniqueID === this.MeasureUniqueID
                                && series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID) {
                                this.FormatSeries(series);
                            }
                        });
                    }
                } else {
                    this.Result.Series.forEach(series => {
                        if (series.MeasureNode && series.MeasureNode.UniqueID === this.MeasureUniqueID) {
                            this.FormatSeries(series);
                        }
                    });
                }
            } else if (this.LevelUniqueID > -1) {
                if (this.LevelMember) {
                    this.Result.Series.forEach(series => {
                        if (series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID
                            && series.AxisNode.MemberId === this.LevelMember) {
                            this.FormatSeries(series);
                        }
                    });
                } else {
                    this.Result.Series.forEach(series => {
                        if (series.AxisNode && series.AxisNode.UniqueID === this.LevelUniqueID) {
                            this.FormatSeries(series);
                        }
                    });
                }
            }
        }
    }

    private FormatSeries(series: SeriesInfo) {
        if (!this.MeasuresOnSeries && this.MeasureUniqueID > -1) {
            if (this.Merger && series.Values) {
                if (this.OppositeLevel > -1) {
                    if (this.OppositeMember) {
                        series.Values.forEach(x => {
                            const row = this.Result.Rows[x.Index];
                            if (row.MeasureNode && row.MeasureNode.UniqueID === this.MeasureUniqueID
                                && row.AxisNode && row.AxisNode.UniqueID === this.OppositeLevel
                                && row.AxisNode.MemberId === this.OppositeMember) {
                                this.FormatValue(x, series);
                            }
                        });
                    } else {
                        series.Values.forEach(x => {
                            const row = this.Result.Rows[x.Index];
                            if (row.MeasureNode && row.MeasureNode.UniqueID === this.MeasureUniqueID
                                && row.AxisNode && row.AxisNode.UniqueID === this.OppositeLevel) {
                                this.FormatValue(x, series);
                            }
                        });
                    }
                } else {
                    series.Values.forEach(x => {
                        const row = this.Result.Rows[x.Index];
                        if (row.MeasureNode && row.MeasureNode.UniqueID === this.MeasureUniqueID) {
                            this.FormatValue(x, series);
                        }
                    });
                }
            }
        } else if (this.OppositeLevel > -1) {
            if (this.Merger && series.Values) {
                if (this.OppositeMember) {
                    series.Values.forEach(x => {
                        const row = this.Result.Rows[x.Index];
                        if (row.AxisNode && row.AxisNode.UniqueID === this.OppositeLevel
                            && row.AxisNode.MemberId === this.OppositeMember) {
                            this.FormatValue(x, series);
                        }
                    });
                } else {
                    series.Values.forEach(x => {
                        const row = this.Result.Rows[x.Index];
                        if (row.AxisNode && row.AxisNode.UniqueID === this.OppositeLevel) {
                            this.FormatValue(x, series);
                        }
                    });
                }
            }
        } else {
            if (!this.UseConditions) {
                series.Settings = ChartStyleMerger.MergeSeriesSettings(series.Settings, this.Settings);
            }
            if (this.Merger && series.Values) {
                series.Values.forEach(x => {
                    this.FormatValue(x, series);
                });
            }
        }
    }

    private FormatValue(val: SeriesValueInfo, series: SeriesInfo) {
        if (this.UseConditions) {
            this.Conditions.forEach(c => {
                if (c.Condition && c.Style) {
                    let mn = new MultiNode({
                        InternalValue: val.Value
                    });
                    if (typeof c.Condition.MeasureUniqueID === 'number') {
                        if (this.MeasuresOnSeries) {
                            if (c.Condition.MeasureUniqueID !== series.MeasureNode.UniqueID) {
                                mn = null;
                                const mNode = this.Matrix.Measures.Nodes.find(x => x.UniqueID === c.Condition.MeasureUniqueID);
                                if (mNode) {
                                    const row = this.Result.Rows[val.Index];
                                    mn = MultiResultHelper.GetCellOrNull(this.Matrix, row.AxisNode, series.AxisNode, mNode);
                                }
                            }
                        } else {
                            const row = this.Result.Rows[val.Index];
                            if (c.Condition.MeasureUniqueID !== row.MeasureNode.UniqueID) {
                                mn = null;
                                const mNode = this.Matrix.Measures.Nodes.find(x => x.UniqueID === c.Condition.MeasureUniqueID);
                                if (mNode) {
                                    mn = MultiResultHelper.GetCellOrNull(this.Matrix, row.AxisNode, series.AxisNode, mNode);
                                }
                            }
                        }
                    }
                    if (mn && TaskHelper.CheckCondition(mn, c.Condition.ConditionOperator, c.Condition.Value1, c.Condition.Value2)) {
                        val.StyleID = this.Merger.MergeStyle(val.StyleID, c.Style);
                    }
                }
            });
        } else {
            val.StyleID = this.Merger.MergeStyle(val.StyleID, this.SeriesValueStyle);
        }
    }
}
