import { NodeContentDirective } from '../components/common/basetreecontrol/base.tree.control';
import {
    DOMValidatorDirective, DOWValidatorDirective, HoursValidatorDirective,
    MonthValidatorDirective, SecondsValidatorDirective, YearValidatorDirective
} from '../components/common/cronbuilder/cron.builder';
import { DropDownButtonContentDirective, DropDownContentDirective } from '../components/common/dropdowncontrol/dropdown.control';
import { MenuTabMiddleContentDirective, MenuTabSpecialContentDirective } from '../appbuilder/common/menutabcontrol/menu.tab.control';
import { NavigationContentDirective } from '../components/common/navigationcontrol/navigation.control';
import { SeriesColumnsContentDirective } from '../appbuilder/menutabs/charts/series.menu.control';
import { FormatPipe } from '../helpers/array.helpers';
import { CallbackPipe, EnumToArrayPipe, FilterColumnsPipe, NavigationVisibilityPipe } from '../helpers/callback.pipe';
import { SplitDisplayNamePipe } from "../helpers/splitDisplayName.pipe";
import { ClickStopPropagation } from '../helpers/clickstoppropagation.directive';
import { FocusOnShowDirective } from '../helpers/focusonshow.directive';
import { DOMRectToStylePipe, GenerateLayoutCopyPipe } from '../helpers/render.helper';
import {
    BaseComponentStylePipe, BootstrapGridItemClassPipe, ButtonTextAlignmentPipe, CanvasItemPositionPipe, CarouselArrowSizePipe,
    CarouselSizePipe, ComboBoxFontPipe, ComboBoxHeightPipe, ComboBoxStylePipe, ComboDisplayNamePipe, ContentPositionPipe,
    ConvertLayoutElementPipe, CSSClassPipe, DataTableCellContentPipe, DataTableCellStylePipe, DataTableHeaderStylePipe,
    DataTableIconCellContentPipe, DisplayMemberPipe, DisplayNamePipe, FlexAlignPipe, FlexClassPipe, FlexItemPipe, FloatingLabelPipe,
    GenericItemLabelStylePipe, GenericItemStylePipe, GridAlignmentStylePipe, GridCellStylePipe, GridEditCellStylePipe, GridItemHoverPipe,
    GridItemStylePipe, GridStyleDefinitionPipe, HorizontalButtonStylePipe, IconStylePipe, InputGroupItemPositionPipe, InputGroupPipe,
    LayoutItemDisplayPipe, LayoutItemHoverPipe, LayoutItemStylePipe, LoadingStyleDefinitionPipe, PropertyGroupStylePipe, RepeatClassPipe,
    SectionStylePipe, ShadowPreviewPipe, TextareaStylePipe, TextblockStylePipe, TextBoxStylePipe, VerticalButtonStylePipe
} from '../helpers/style.helper';
import { UploadDndDirective } from '../helpers/upload.dnd.directive';
import { ClickOutsideDirective } from '../helpers/clickOutside.directive';
import { FileDragDropDirective } from '../helpers/file-drag-drop.directive';
export const APP_PIPES = [
    CallbackPipe,
    SplitDisplayNamePipe,
    EnumToArrayPipe,
    FormatPipe,
    NavigationVisibilityPipe,
    GridAlignmentStylePipe,
    GridItemStylePipe,
    GridCellStylePipe,
    GridStyleDefinitionPipe,
    BaseComponentStylePipe,
    IconStylePipe,
    LoadingStyleDefinitionPipe,
    CanvasItemPositionPipe,
    FlexAlignPipe,
    FlexClassPipe,
    FlexItemPipe,
    FloatingLabelPipe,
    GridEditCellStylePipe,
    DisplayNamePipe,
    DisplayMemberPipe,
    ComboDisplayNamePipe,
    HorizontalButtonStylePipe,
    VerticalButtonStylePipe,
    ConvertLayoutElementPipe,
    LayoutItemStylePipe,
    LayoutItemDisplayPipe,
    ButtonTextAlignmentPipe,
    RepeatClassPipe,
    ContentPositionPipe,
    FilterColumnsPipe,
    DataTableHeaderStylePipe,
    DataTableCellStylePipe,
    DataTableCellContentPipe,
    DataTableIconCellContentPipe,
    TextblockStylePipe,
    InputGroupPipe,
    InputGroupItemPositionPipe,
    TextareaStylePipe,
    CarouselSizePipe,
    CarouselArrowSizePipe,
    ComboBoxFontPipe,
    ComboBoxStylePipe,
    ComboBoxHeightPipe,
    GenerateLayoutCopyPipe,
    LayoutItemHoverPipe,
    GridItemHoverPipe,
    DOMRectToStylePipe,
    TextBoxStylePipe,
    PropertyGroupStylePipe,
    GenericItemStylePipe,
    SectionStylePipe,
    GenericItemLabelStylePipe,
    ShadowPreviewPipe,
    BootstrapGridItemClassPipe,
    CSSClassPipe
];

export const APP_DIRECTIVES = [
    ClickStopPropagation,
    SecondsValidatorDirective,
    HoursValidatorDirective,
    DOMValidatorDirective,
    MonthValidatorDirective,
    DOWValidatorDirective,
    YearValidatorDirective,
    FocusOnShowDirective,
    NodeContentDirective,
    DropDownButtonContentDirective,
    DropDownContentDirective,
    SeriesColumnsContentDirective,
    NavigationContentDirective,
    MenuTabSpecialContentDirective,
    MenuTabMiddleContentDirective,
    UploadDndDirective,
    ClickOutsideDirective,
    FileDragDropDirective
];
