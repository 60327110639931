import { SeriesSettings } from '../controls/chart.model';
import { ConditionOperator } from '../enums/conditionoperator.enum';
import { CellStyle } from '../styling/cell.style';

export class ConditionObject {
    MeasureUniqueID: number;
    LevelUniqueID: number;
    LevelMember: string;
    Value1: number;
    Value2: number;
    InfoText: string;
    ConditionOperator: ConditionOperator = ConditionOperator.Equal;
}

export class ConditionFormattingStyle {
    Condition: ConditionObject = new ConditionObject();
    FormatHeaders = false;
    Style: CellStyle = new CellStyle();
}

export class ChartConditionFormatStyle {
    Condition: ConditionObject = new ConditionObject();
    Style: SeriesSettings = new SeriesSettings();
}
