import { Component, OnInit } from "@angular/core";
import { EnumHelper } from '../../../helpers/enum.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { OfflineStrategy } from '../../../models/enums/offlinestrategy.enum';
import { AppBuilderSettingsService } from '../../../services/appbuildersettings.service';
import { StandardRequestBase } from '../../../services/request-base';
import { WF_GROUP_REGISTRY } from "../../../services/workflow.service";

@Component({
    selector: 'appbuilder-settings',
    templateUrl: './appbuilder.settings.html',
    styleUrls: ['./appbuilder.settings.css']
})
export class AppBuilderSettings implements OnInit {
    Settings:any = {};
    
    static GetSettingsEntry() {
        return {
            Caption: '@@AppBuilder',
            ID: 'appbuilder',
            Icon: 'desktop_windows',
            Index: 8,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 2
            },
            Content: AppBuilderSettings
        };
    }
    WFGroups;
    constructor(private service: AppBuilderSettingsService, private standardService: StandardRequestBase) {
        this.WFGroups = WF_GROUP_REGISTRY;
    }

    ngOnInit(): void {
        this.Refresh();
    }

    Refresh() {
        this.service.ReadAll().subscribe((settings) => {
            if (settings && settings.length > 0) {
                this.Settings = settings[0];
                //SettingsService.GeneralSettings.next(settings[0]);
            }
        });
    }

    Save() {
        this.service.AddOrUpdate(this.Settings).subscribe(() => {
            NotificationHelper.Success('@@Erfolgreich gespeichert', '@@Speichern');
            this.Refresh();
        });
    }
}
