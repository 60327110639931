export enum Operator {
    None,
    Add,
    Sub,
    Div,
    Multi,
    Equal,
    Less,
    Great,
    LessEqual,
    GreatEqual,
    NotEqual,
    Or,
    And,
    Not,
    New,
    NewArray,
    NewDynamic,
    Function,
    Value,
    Void,
    Get,
    Enumerator,
    ArrayAccess,
    VariableProperty,
    If,
    InlineFunction,
    Increase,
    Decrease,
    IncreaseBefore,
    DecreaseBefore,
    IncreaseAfter,
    DecreaseAfter,
    Set,
    TypeOf,
    Chain,
}