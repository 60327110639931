import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { ConnectionStatusEnum } from '../models/enums/connectionstatusenum.enum';

//dynamic
export class NetworkConnection {

    public static status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

    public static Online: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private static online$: Observable<Event> = null;
    private static offline$: Observable<Event> = null;

    private static OnlineSub = null;

    public static init() {
        if (this.OnlineSub) {
            this.OnlineSub.unsubscribe();
        }
        this.OnlineSub = NetworkConnection.Online.subscribe(() => {
            this.WriteLog();
        })
        NetworkConnection.Online.next(window.navigator.onLine);
        NetworkConnection.online$ = fromEvent(window, 'online');
        NetworkConnection.offline$ = fromEvent(window, 'offline');

        NetworkConnection.online$.subscribe(e => {
            NetworkConnection.status = ConnectionStatusEnum.Online;
            NetworkConnection.Online.next(true);
        });

        NetworkConnection.offline$.subscribe(e => {
            NetworkConnection.status = ConnectionStatusEnum.Offline;
            NetworkConnection.Online.next(false);
        });
    }

    private static WriteLog() {
        console.log('['+new Date().toJSON() + '] Online:' + NetworkConnection.Online.getValue());
    }

    constructor() {
        NetworkConnection.init();
    }

}