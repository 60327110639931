import { Type } from 'class-transformer';
import { Subject } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { LayoutUnit } from './basic/layoutunit.model';
import { ViewType } from './enums/viewtype.enum';
import { MultiPropertyChangeValue } from './layout/layout.change.model';
import { LayoutBase } from './layoutbase.model';
import { Variable } from './variable.model';
// @dynamic
export class LayoutElement extends LayoutBase {
    ElementType: string;
    Settings: string;
    RowDefinitions: any;
    Caption: string;
    _Name;
    @Type(() => LayoutElement)
    Elements: LayoutElement[] = [];
    @Type(() => Subject)
    ElementSet: Subject<any> = new Subject();
    //#region X
    x;
    //#endregion
    //#region Y
    y;
    //#endregion
    //#region Width
    get width() {
        return LayoutUnit.ConvertFrom(this.Width);
    }
    set width(value) {
        this.Width = LayoutUnit.ConvertTo(value);
    }
    //#endregion
    //#region Height
    get height() {
        return LayoutUnit.ConvertFrom(this.Height);
    }
    set height(value) {
        this.Height = LayoutUnit.ConvertTo(value);
    }
    //#endregion

    //#region Size
    get size() {
        return LayoutUnit.ConvertFrom(this.Size);
    }
    set size(value) {
        this.Size = LayoutUnit.ConvertTo(value);
    }
    //#endregion
    cols: number;
    rows: number;
    Variables: Variable[];

    SetViewType(vt: ViewType) {
        if (this.ViewType != vt) {
            this.ViewType = vt;
            if (this.Elements) {
                this.Elements.forEach((item) => {
                    item.SetViewType(vt);
                });
            }
        }
    }

    toJSON() {
        const result = super.toJSON();
        delete result.ElementSet;
        if (result.Variables && result.Variables.length > 0) {
            const copyList = [];
            result.Variables.forEach((v) => {
                const varCopy = Object.assign({}, v);
                delete varCopy.Value;
                copyList.push(varCopy);
            });
            result.Variables = copyList;
        }
        return result;
    }
    copy() {
        const result = new LayoutElement();
        const keys = Object.keys(this);
        keys.forEach((key) => {
            result[key] = this[key];
        });
        return result;
    }
    init(item) {
        const keys = Object.keys(item);
        keys.forEach((key) => {
            this[key] = item[key];
        });
    }
}
// @dynamic
export class MultiLayoutElement extends LayoutBase {
    constructor() {
        super();
        if (this.ValuesChanged) {
            this.ValuesChanged.subscribe((x) => {
                if (this.LayoutElements && this.LayoutElements.length > 0) {
                    if (typeof x === 'string') {
                        const val = this[x];
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.Properties.push({
                            PropertyName: x,
                            Value: val
                        });
                        this.LayoutElements.forEach((element) => {
                            element[x] = val;
                            element.ValuesChanged.next();
                            mlpcv.ElementIDs.push(element.ID);
                        });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                    } else if (Array.isArray(x) && x.length > 0) {
                        const mlpcv = new MultiPropertyChangeValue();
                        x.forEach(y => {
                            const val = this[y];
                            mlpcv.Properties.push({
                                PropertyName: y,
                                Value: val
                            });
                            this.LayoutElements.forEach(element => {
                                element[y] = val;
                                element.ValuesChanged.next();
                                mlpcv.ElementIDs.push(element.ID);
                            });
                        });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                    }
                }
            });
        }
    }

    LayoutElements = [];
}
