import { BehaviorSubject, Subject } from 'rxjs';
import { Params } from '@angular/router';

import { RequestBase } from './request-base';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class MacNavigationService extends RequestBase {
    BasePath = "api/navigation";

    GetDetail(detailID: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDetail?id=' + detailID, this.options);
    }

    Open(detailID: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Open?id=' + detailID, this.options);
    }

    AddFolder(data: string, id: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddFolder?id=' + id, data, this.options);
    }

    Cut(cutID, toID) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Cut?id=' + toID, cutID, this.options);
    }

    Copy(copyID, toID) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Copy?id=' + toID, copyID, this.options);
    }

    Delete(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }

    GetFolderView(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetFolderView', filter, this.options);
    }
}


export class NavigationService {
    public static Params: BehaviorSubject<Params> = new BehaviorSubject(null);
    public static SelectedPage: BehaviorSubject<any> = new BehaviorSubject(null);
    public static ScreenSize: BehaviorSubject<any> = new BehaviorSubject(null);
    public static NavigationStart: Subject<any> = new Subject();
    public static PopState: Subject<any> = new Subject();
    public static NavigationEnd: Subject<any> = new Subject();
    public static ActiveURL: BehaviorSubject<any> = new BehaviorSubject(null);
    public static Refresh: Subject<any> = new Subject();
    public static OnPopState: Subject<any> = new Subject();
    public static DisableBack: BehaviorSubject<string> = new BehaviorSubject(null);
    public static BeforeUnload: Subject<boolean> = new Subject();
    public static CancelUnload: Subject<any> = new Subject();
    public static OnUnload: Subject<any> = new Subject();
    public static NavOpenChanged: Subject<any> = new Subject();
}
