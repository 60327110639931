import { Component } from '@angular/core';
import { DataTypeHelper } from '../../../helpers/datatype.helper';
import { EnumHelper } from '../../../helpers/enum.helper';
import { Aggregation, LevelType } from '../../../models/enums';

@Component({
    selector: 'container-tabular-column-edit-dialog',
    templateUrl: './container.tabular.column.edit.dialog.html'
})
export class ContainerTabularColumnEditDialog {
    Column;
    Variables = [];
    DataTypes;
    Aggregations;
    LevelTypes;

    constructor() {
        this.DataTypes = DataTypeHelper.GetDataTypes();
        this.Aggregations = EnumHelper.GetDropdownValues(Aggregation);
        this.LevelTypes = EnumHelper.GetDropdownValues(LevelType);
    }

    Initialize(args) {
        if (args) {
            if (args.Variables) {
                this.Variables = args.Variables;
            }
            if (args.Column) {
                this.Column = args.Column;
            }
        }
    }

    GetDialogResult() {
        return this.Column;
    }
}
