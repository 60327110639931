import { BehaviorSubject, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Color } from '../models/style/color.model';
import { WorkflowSaveObject } from '../models/workflow/workflow.model';
import { DataService } from '../services/data.service';
import { ContainerEnumService } from '../services/datamodel.service';
import { GeneralSettingsService } from '../services/generalsettings.service';
import { GlobalVariableService } from '../services/globalvariable.service';
import { MediaService } from '../services/media.service';
import { MetaService } from '../services/meta.service';
import { PermissionsService } from '../services/permissions.service';
import { RolesService } from '../services/roles.service';
import { SideNavService } from '../services/sidenav.service';
import { UsersService } from '../services/users.service';
import { WorkflowService } from '../services/workflow.service';
import { InMemCache } from './drivers/inmem.driver';
import { NoCache } from './drivers/nocache.driver';
import { CacheItem } from './models/cacheitem.model';
import { CacheTable } from './models/cachetable.model';

// @dynamic
export class CacheService {

    private static db;

    private static metaService: MetaService;
    private static dataService: DataService;
    private static enumService: ContainerEnumService;
    private static mediaService: MediaService;
    private static rolesService: RolesService;
    private static workflowService: WorkflowService;
    private static permissionService: PermissionsService;
    private static variableService: GlobalVariableService;
    private static gss: GeneralSettingsService;
    private static tables: CacheTable[] = [];

    public static ContentPermissionChanged: Subject<string> = new Subject<string>();
    public static NavigationPermissionChanged: Subject<any> = new Subject<any>();
    public static RolesChanged: Subject<any> = new Subject<any>();
    public static GlobalVariablesValues: BehaviorSubject<any> = new BehaviorSubject(null);

    public static Initialize(active, metaService: MetaService, dataService: DataService, enumService: ContainerEnumService,
        mediaService: MediaService, permissionService: PermissionsService, gss: GeneralSettingsService,
        rolesService: RolesService, variableService: GlobalVariableService, workflowService: WorkflowService) {
        this.workflowService = workflowService;
        this.metaService = metaService;
        this.dataService = dataService;
        this.enumService = enumService;
        this.mediaService = mediaService;
        this.permissionService = permissionService;
        this.rolesService = rolesService;
        this.variableService = variableService;
        this.gss = gss;

        this.BuildDefaultTables(['layouts', 'templates', 'widgets', 'navigation', 'navigationstructure', 'tables', 'flat_tables',
            'flat_tables_recursive', 'enum_values', 'enums', 'mediasources', 'layoutpermissions', 'navigationpermissions', 'roles',
            'workflow_cache', 'workflow_templates', 'ribbon', 'context_menu', 'globalvariable', 'styles', 'flat_data_tables', 'chartpalette']);

        const item = new CacheTable();
        item.name = 'changedates';
        CacheService.tables.push(item);

        UsersService.Logout.subscribe(() => {
            CacheService.db.Clear('workflow_cache');
            CacheService.db.Clear('globalvariable');
        });

        const promise = new Promise((resolve) => {
            if (active) {
                new InMemCache().Initialize('evidanza', 7).then((db) => {
                    CacheService.db = db;
                    CacheService.Create();
                    resolve(true);
                });
                // if (!self.indexedDB) {
                //    try {
                //        new WebSQL().Initialize('evidanza', 7).then((db) => {
                //            CacheService.db = db;
                //            CacheService.Create();
                //            resolve(true);
                //        });
                //    } catch {
                //        new NoCache().Initialize('evidanza', 7).then((db) => {
                //            CacheService.db = db;
                //            CacheService.Create();
                //            resolve(true);
                //        });
                //    }
                // } else {
                //    new IndexedDB().Initialize('evidanza', 7).then((db) => {
                //        CacheService.db = db;
                //        CacheService.Create();
                //        resolve(true);
                //    });
                // }
            } else {
                new NoCache().Initialize('evidanza', 7).then((db) => {
                    CacheService.db = db;
                    CacheService.Create();
                    resolve(true);
                });
            }
        });
        return promise;
    }
    private static BuildDefaultTables(names) {
        names.forEach((name) => {
            const item = new CacheTable();
            item.name = name;
            CacheService.tables.push(item);
        });
    }

    private static Create() {
        CacheService.db.CreateTables(this.tables);
        CacheService.Refresh();
    }
    public static Refresh() {
        CacheService.db.ReadAll('changedates').subscribe((local) => {
            if (local) {
                this.metaService.ReadChangeDates().subscribe((result) => {
                    if (result && local) {
                        result.forEach((remote) => {
                            CacheService.CheckToDelete(remote, local);
                        });
                    }
                });
            }
        });
    }
    public static RefreshSpecific(classname, id) {
        if (id) {
            const tables = CacheService.MapClassname(classname);
            tables.forEach((table) => {
                CacheService.db.Delete(table, id);
            });
            if (classname == 'evidanza.App.Shared.Layouts.NavigationNode') {
                SideNavService.Reload.next(id);
            } else if (classname == 'evidanza.App.Shared.Permissions.Layout') {
                CacheService.ContentPermissionChanged.next(id);
            }
        }
        if (classname == 'evidanza.App.Shared.Permissions.Navigation') {
            CacheService.NavigationPermissionChanged.next(null);
        } else if (classname === 'evidanza.App.Shared.Variable') {
            CacheService.UpsertGlobalVariables();
        }
    }
    private static CheckToDelete(remote, local) {
        const toDelete = local.filter((element) => {
            return element.key.indexOf(remote.Class) > -1 && new Date(element.value) < new Date(remote.ChangeDate);
        });

        if (toDelete && toDelete.length > 0) {
            const tables = CacheService.MapClassname(remote.Class);
            tables.forEach((table) => {
                toDelete.forEach((item) => {
                    const id = CacheService.ResolveID(remote.Class, item.key);
                    if (id) {
                        CacheService.db.Delete(table, id);
                        CacheService.db.Delete('changedates', item.key);
                    }
                });
            });
        }
    }
    private static Drop(tablename) {
        CacheService.db.transaction((tx) => {
            tx.executeSql('Drop table ' + tablename);
        });
    }
    private static MapClassname(classname) {
        const result = [];
        switch (classname) {
            case 'evidanza.App.Shared.Layouts.Layout': result.push('layouts'); break;
            case 'evidanza.App.Shared.Permissions.Layout': result.push('layoutpermissions'); break;
            case 'evidanza.App.Shared.Permissions.Navigation': result.push('navigationpermissions'); break;
            case 'evidanza.App.Shared.Layouts.Template': result.push('templates'); break;
            case 'evidanza.App.Shared.Layouts.Widget': result.push('widgets'); break;
            case 'evidanza.App.Shared.Layouts.NavigationNode': result.push('navigation'); break;
            case 'evidanza.App.Shared.Layouts.NavigationStructure': result.push('navigationstructure'); break;
            case 'evidanza.App.Shared.Dynamic.ContainerClassObject':
                result.push('tables');
                result.push('flat_tables');
                result.push('flat_tables_recursive');
                result.push('flat_data_tables');
                break;
            case 'evidanza.Core.Service.Media.MediaSource': result.push('mediasources'); break;
            case 'evidanza.Core.ServiceBase.Security.Role': result.push('roles'); break;
            case 'evidanza.App.Shared.Dynamic.ContainerEnum': result.push('enums'); result.push('enum_values'); break;
            case 'evidanza.App.Shared.Layouts.Ribbon': result.push('ribbon'); break;
            case 'evidanza.App.Shared.Layouts.ContextMenu': result.push('context_menu'); break;
            case 'evidanza.App.Shared.Variable': result.push('globalvariable'); break;
            case 'evidanza.App.Shared.Data.StyleObject': result.push('styles'); break;
            case 'evidanza.App.Shared.Data.ChartPalette': result.push('chartpalette'); break;
        }
        return result;
    }
    private static UpsertChangeDate(tablename) {
        const entry = new CacheItem(tablename, new Date().toJSON());
        CacheService.db.ReadKey('changedates', entry.key).subscribe((result) => {
            if (result) {
                CacheService.db.Update('changedates', entry);
            } else {
                CacheService.db.Insert('changedates', entry);
            }
        });
    }
    private static ResolveID(remoteclass, localclass) {
        if (localclass.length > remoteclass.length) {
            const result = localclass.substr(remoteclass.length + 1);
            return result;
        } else {
            return null;
        }
    }
    //#region Layout
    //public static ReadLayout(key) {
    //    const promise = new Promise((resolve) => {
    //        CacheService.db.ReadKey('layouts', key, CacheService.metaService.ReadLayoutByKey(key)).subscribe((result) => {
    //            if (!result) {
    //                resolve(null);
    //            } else {
    //                resolve((result.value));
    //            }
    //        });
    //    });
    //    return promise;
    //}
    //public static ContainsLayout(key) {
    //    return new Promise<boolean>((resolve) => {
    //        CacheService.db.ReadKey('layouts', key).subscribe((result) => {
    //            resolve(result != null);
    //        });
    //    });
    //}
    //#endregion
    //#region Template
    //public static ReadTemplate(key) {
    //    const promise = new Promise((resolve) => {
    //        CacheService.db.ReadKey('templates', key, CacheService.metaService.ReadTemplateById(key)).subscribe((result) => {
    //            if (!result) {
    //                resolve(null);
    //            } else {
    //                resolve((result.value));
    //            }
    //        });
    //    });
    //    return promise;
    //}
    //public static ReadTemplateSub(key) {
    //    return CacheService.db.ReadKey('templates', key, CacheService.metaService.ReadTemplateById(key)).pipe(map((val: any) => { return val.value }))
    //}
    //public static RemoveTemplate(key) {
    //    return CacheService.db.Delete('templates', key)
    //}
    //#endregion
    //#region Widget
    public static ReadWidget(key) {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('widgets', key, CacheService.metaService.ReadWidgetById(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion
    //#region Navigation
    public static ReadNavigation(key) {
        const promise = new Promise<any[]>((resolve) => {
            CacheService.db.ReadKey('navigation', key, CacheService.metaService.GetNavigationNodesForStructure(key)).subscribe((result) => {
                if (!result) {
                    resolve([]);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    public static ReadNavigationStructure(key) {
        const promise = new Promise<any>((resolve) => {
            CacheService.db.ReadKey('navigationstructure', key, CacheService.metaService.GetNavigationStructureByKey(key)).subscribe((r) => {
                if (!r) {
                    resolve(null);
                } else {
                    resolve((r.value));
                }
            });
        });
        return promise;
    }
    //#endregion
    //#region Ribbon
    public static ReadRibbon(key) {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('ribbon', key, CacheService.metaService.ReadRibbonByKey(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion    //#region Style
    public static ReadStyle(key) {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('styles', key, CacheService.metaService.GetControlStyle(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    public static ReadStyleSub(key) {
        return CacheService.db.ReadKey('styles', key, CacheService.metaService.GetControlStyle(key)).pipe(map((val: any) => { return val.value }))
    }
    //#endregion    //#region ContextMenu
    public static ReadContextMenu(key) {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('context_menu', key, CacheService.metaService.ReadContextMenuByKey(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion


    //#region Table
    public static ReadTable(key) {
        const promise = new Promise((resolve) => {
            if (key != null) {
                CacheService.db.ReadKey('tables', key, CacheService.dataService.GetListWithID('dynamic', 'GetTable', key)).subscribe((r) => {
                    if (!r) {
                        resolve(null);
                    } else {
                        resolve((r.value));
                    }
                });
            }
        });
        return promise;
    }
    public static ReadTableSub(key) {
        //const promise = new Promise((resolve) => {
            if (key != null) {
                return CacheService.db.ReadKey('tables', key, CacheService.dataService.GetListWithID('dynamic', 'GetTable', key)).pipe(map((val:any) => { return val.value }))
                //    .subscribe((r) => {
                //    if (!r) {
                //        resolve(null);
                //    } else {
                //        resolve((r.value));
                //    }
                //});
            }
        //});
        //return promise;
        return of(null);
    }
    //#endregion

    //#region FlatTable
    public static ReadFlatTable(key: string, recursive: boolean) {
        const table = recursive ? 'flat_tables_recursive' : 'flat_tables';
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey(table, key, CacheService.dataService.GetFlatTable(key, recursive)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }

    public static ReadFlatDataTable(key) {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('flat_data_tables', key, CacheService.dataService.GetFlatDataTable(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion

    //#region WorkflowTemplate
    //public static ReadWorkflowTemplate(key): Promise<WorkflowSaveObject> {
    //    const promise = new Promise<WorkflowSaveObject>((resolve) => {
    //        CacheService.db.ReadKey('workflow_templates', key, CacheService.workflowService.GetWorkflow(key)).subscribe((result) => {
    //            if (!result) {
    //                resolve(null);
    //            } else {
    //                resolve((result.value));
    //            }
    //        });
    //    });
    //    return promise;
    //}
    //#endregion

    //#region MediaSource
    public static ReadMediaSource(key): any {
        const promise = new Promise((resolve, reject) => {
            CacheService.db.ReadKey('mediasources', key, CacheService.mediaService.GetMediaSource(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    public static ReadMediaSourceSub(key) {
        return CacheService.db.ReadKey('mediasources', key, CacheService.mediaService.GetMediaSource(key)).pipe(map((val: any) => { return val.value }))
    }
    //#endregion

    //#region LayoutPermissions
    public static ReadLayoutPermission(key): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('layoutpermissions', key, CacheService.permissionService.ReadByKey(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion
    //#region NavigationPermissions
    public static ReadNavigationPermission(key): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('navigationpermissions', key, CacheService.permissionService.ReadNavigationByKey(key)).subscribe((result) => {
                    if (!result) {
                        resolve(null);
                    } else {
                        resolve((result.value));
                    }
                });
        });
        return promise;
    }
    //#endregion
    //#region Roles
    private static InsertRoles(key, value) {
        CacheService.db.Insert('roles', new CacheItem(key, value))
        CacheService.UpsertChangeDate('evidanza.Core.ServiceBase.Security.Role_' + key);
    }
    public static ReadRole(key): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('roles', key, CacheService.rolesService.FindRole(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    public static ReadRoles(): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadAll('roles').subscribe((result) => {
                if (!result || (result && result.length === 0)) {
                    CacheService.rolesService.ReadRoles().subscribe((data) => {
                        if (data) {
                            data.forEach((role) => {
                                CacheService.InsertRoles(role.SID, (role));
                            });
                            resolve(data);
                        } else {
                            resolve(null);
                        }
                    });
                } else {
                    const retVal = [];
                    result.forEach((role) => {
                        retVal.push((role.content));
                    });
                    resolve(retVal);
                }
            });
        });
        return promise;
    }
    //#endregion
    //#region Enums
    public static ReadEnum(key): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('enums', key, CacheService.enumService.GetEnum(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion
    //#region EnumValues
    public static ReadEnumValues(key): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadKey('enum_values', key, CacheService.enumService.GetEnumValues(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
        return promise;
    }
    //#endregion

    //#region WorkflowCache
    public static WriteToWorkflowCache(key, value) {
        CacheService.db.ReadKey('workflow_cache', key).subscribe((result) => {
            if (result) {
                CacheService.db.Update('workflow_cache', new CacheItem(key, (value)));
            } else {
                CacheService.db.Insert('workflow_cache', new CacheItem(key, (value)));
            }
        });
    }

    public static GetFromWorkflowCache(key) {
        return new Promise((resolve) => {
            CacheService.db.ReadKey('workflow_cache', key).subscribe((result) => {
                if (result) {
                    resolve((result.value));
                } else {
                    resolve(null);
                }
            });
        });
    }

    public static RemoveFromWorkflowCache(key) {
        CacheService.db.Delete('workflow_cache', key);
    }
    //#endregion

    //#region GlobalVariable
    public static ReadGlobalVariables(): any {
        const promise = new Promise((resolve) => {
            CacheService.db.ReadAll('globalvariable').subscribe((result) => {
                if (!result || (result && result.length === 0)) {
                    CacheService.variableService.LoadVariables().subscribe((data) => {
                        if (data) {
                            data.forEach((variable) => {
                                CacheService.AddGlobalVariable(variable._Id, (variable));
                            });
                            resolve(data);
                        } else {
                            resolve(null);
                        }
                    });
                } else {
                    const retVal = [];
                    result.forEach((variable) => {
                        retVal.push((variable.value));
                    });
                    resolve(retVal);
                }
            });
        });
        return promise;
    }

    public static AddGlobalVariable(key, value) {
        CacheService.db.Insert('globalvariable', new CacheItem(key, value))
        CacheService.UpsertChangeDate('evidanza.App.Shared.Variable_' + key);
    }

    public static UpsertGlobalVariables() {
        CacheService.variableService.LoadVariables().subscribe((data) => {
            if (data) {
                data.forEach((variable) => {
                    const key = variable._Id;
                    const value = (variable);
                    CacheService.db.ReadKey('globalvariable', key).subscribe((result) => {
                        if (result) {
                            CacheService.db.Update('globalvariable', new CacheItem(key, value));
                        } else {
                            CacheService.db.Insert('globalvariable', new CacheItem(key, value));
                        }
                    });
                });
            }
        });
    }

    public static ReadGlobalVariable(key): any {
        return new Promise((resolve) => {
            CacheService.db.ReadKey('globalvariable', key, CacheService.variableService.LoadVariable(key)).subscribe((result) => {
                if (result) {
                    resolve((result.value));
                } else {
                    resolve(null);
                }
            });
        });
    }

    public static DeleteGlobalVariable(key) {
        CacheService.db.Delete('globalvariable', key);
    }

    public static RefreshGlobalVariableValues() {
        return new Promise((resolve) => {
            CacheService.ReadGlobalVariables().then((gvars) => {
                if (gvars && gvars.length > 0) {
                    const gvalues = CacheService.GlobalVariablesValues.getValue();
                    const gvaluekeys = {};
                    if (gvalues && gvalues.length > 0) {
                        for (let i = 0; i < gvalues.length; i++) {
                            gvaluekeys[gvalues[i].Name] = i;
                        }
                    }
                    const result = [];

                    gvars.forEach((value) => {
                        const item = Object.assign({}, value);
                        if (gvaluekeys[value.Name] !== null && gvalues !== null) {
                            item.Value = gvalues[gvaluekeys[value.Name]].Value;
                        }
                        result.push(item);
                    });
                    CacheService.GlobalVariablesValues.next(result);
                    resolve(result);
                } else {
                    CacheService.GlobalVariablesValues.next([]);
                    resolve([]);
                }
            });
        });
    }
    //#endregion

    //#region ChartPalette
    public static ReadChartPalette(key) {
        return new Promise<any>(resolve => {
            CacheService.db.ReadKey('chartpalette', key, CacheService.metaService.ReadPalette(key)).subscribe((result) => {
                if (!result) {
                    resolve(null);
                } else {
                    resolve((result.value));
                }
            });
        });
    }

    public static ReadChartPaletteValues(key) {
        return new Promise<string[]>(resolve => {
            CacheService.ReadChartPalette(key).then(x => {
                let list;
                if (x && x.Palette && x.Palette.length > 0) {
                    list = [];
                    x.Palette.forEach(c => list.push(Color.HexFromColor(c, true)));
                }
                resolve(list);
            });
        });
    }
    //#endregion
}
