import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { CacheService } from '../../../cache/cache.service';
import { RequestOptionsControl } from '../../../components/common/requestoptions/request.options.control';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { DataService } from '../../../services/data.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DynamicDataService } from '../../../services/dynamicdata.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { ElementValueSettingsData } from '../layout/elemValue/elemValue.settings';

@Component({
    selector: 'wf-readdata-settings',
    templateUrl: './save.settings.html',
    styleUrls: ['./save.settings.css']
})
export class SaveDataSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'saveDataWFModule';

    DataModelID: string;
    DataSource: string;
    UseAdvanced: boolean;
    ElemName: string;
    Formula: string;
    UseActualParam: boolean;
    RequestOptions = RequestOptions.CleanRequestOptions();

    @ViewChild('advanced') filterComponent: RequestOptionsControl;
    DataModels = [];
    DataSources = [];
    StaticTables = [];
    StaticDataSource = false;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'SaveDataWFModule';
        reg.Caption = '@@SaveItem';
        reg.GroupID = 'dataoperations';
        reg.Index = 30;
        reg.SettingsControl = SaveDataSettings;
        reg.SettingsTypeHelper = new SaveDataWFSettingsDataHelper();
        reg.Executer = SaveDataWFModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private dataModelService: DataModelService, private dataService: DataService) {
        super();
    }

    ngOnInit(): void {
        this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((data) => {
            this.StaticTables = data;
            this.dataModelService.GetModels().subscribe(models => {
                this.DataModels = models;
                if (this.DataSource) {
                    if (data.some(x => x.Value === this.DataSource)) {
                        this.StaticDataSource = true;
                    } else if (this.DataModelID) {
                        const query = new DataSourceQuery(this.DataModelID);
                        query.DBUsages = [[8192, 32]]; // RelationalWrite oder DocumentStoreWrite
                        query.IgnoreResourceBase = true;
                        query['OnlyQueryable'] = true;
                        this.dataModelService.GetContainerByDBUsage(query).subscribe((dataSources) => {
                            this.DataSources = dataSources;
                            if (!dataSources.some(x => x.SID === this.DataSource)) {
                                this.DataSource = null;
                            }
                        });
                    }
                }
            });
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            data = JSON.parse(JSON.stringify(data));
            if (data.DataModelID) {
                this.DataModelID = data.DataModelID;
            }
            if (data.DataSource) {
                this.DataSource = data.DataSource;
            }
            if (data.ElemName) {
                this.ElemName = data.ElemName;
            }
            if (data.Formula) {
                this.Formula = data.Formula;
            }
            this.UseActualParam = data.UseActualParam;
            this.UseAdvanced = data.UseAdvanced;
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (!this.UseAdvanced) {
            if (!this.ElemName) {
                retVal.Error = '@@Bitte waehlen Sie ein Element.';
                retVal.IsCorrect = false;
            }
        } else {
            if (!this.DataSource) {
                retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
                retVal.IsCorrect = false;
            } else {
                if (this.StaticDataSource) {
                    if (!this.StaticTables.some(x => x.Value === this.DataSource)) {
                        retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
                        retVal.IsCorrect = false;
                    }
                } else {
                    if (!this.DataSources.some(x => x.SID === this.DataSource)) {
                        retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
                        retVal.IsCorrect = false;
                    }
                }
            }
        }
        return retVal;
    }

    getResult(): any {
        const retVal = new SaveDataSettingsData();
        retVal.DataSource = this.DataSource;
        if (!this.StaticTables.some(x => x.Value === this.DataSource)) {
            retVal.DataModelID = this.DataModelID;
        }
        retVal.UseAdvanced = this.UseAdvanced;
        retVal.ElemName = this.ElemName;
        retVal.DataSource = this.DataSource;
        retVal.Formula = this.Formula;
        retVal.UseActualParam = this.UseActualParam;
        return retVal;
    }

    modelSelected() {
        this.RequestOptions = RequestOptions.CleanRequestOptions();
        this.DataSources = [];
        this.DataSource = null;
        const query = new DataSourceQuery(this.DataModelID);
        query.DBUsages = [[8192, 32]]; // RelationalWrite oder DocumentStoreWrite
        query.IgnoreResourceBase = true;
        query['OnlyQueryable'] = true;
        this.dataModelService.GetContainerByDBUsage(query).subscribe((dataSources) => {
            this.DataSources = dataSources;
        });
    }

    readTableProperties() {
        this.RequestOptions = RequestOptions.CleanRequestOptions();
    }
}


export class SaveDataSettingsData {
    UseAdvanced: boolean;
    ElemName: string;
    DataModelID: string;
    DataSource: string;
    UseActualParam: boolean;
    Formula: string;
}

export class SaveDataWFSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new ElementValueSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const saveDataWF = new WorkflowExitInfo();
        saveDataWF.ID = 2;
        saveDataWF.Label = '@@Erfolgreich neu angelegt';
        const updateDataWF = new WorkflowExitInfo();
        updateDataWF.ID = 0;
        updateDataWF.Label = '@@Erfolgreich gespeichert';
        const end = new WorkflowExitInfo();
        end.ID = 1;
        end.Label = '@@Error';
        return [saveDataWF, updateDataWF, end];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class SaveDataWFModuleExecuter extends WorkflowModuleExecuter {
    private static async CheckSavePossible(table, DataSource) {
        const retVal = [];
        if (table && table.Fields && DataSource != null) {
            for (let i = 0; i < table.Fields.length; i++) {
                const field = table.Fields[i];
                const entry = DataSource[field.Name];
                if (entry == null) {
                    if (field.IsMandatory || !field.IsNullable) {
                        retVal.push(field);
                    }
                } else {
                    if (field.IsList) {
                        if (Array.isArray(entry)) {
                            if (field.IsMandatory && entry.length === 0) {
                                retVal.push(field);
                                continue;
                            }
                        } else {
                            retVal.push(field);
                            continue;
                        }
                    }
                    if (field.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c' && (!field.IsShared || field.IsReverse)) {
                        const fieldTable = await CacheService.ReadTable(field.AdvancedType);
                        if (field.IsList) {
                            if (Array.isArray(entry)) {
                                for (let j = 0; j < entry.length; j++) {
                                    const fieldErrors = await SaveDataWFModuleExecuter.CheckSavePossible(fieldTable, entry[j]);
                                    retVal.push(...fieldErrors);
                                }
                            }
                        } else {
                            const fieldErrors = await SaveDataWFModuleExecuter.CheckSavePossible(fieldTable, entry);
                            retVal.push(...fieldErrors);
                        }
                    }
                }
            }
        }
        return retVal;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.UseAdvanced) {
                const DataSourceId = status.ActualSettings.DataSource;
                if (DataSourceId) {
                    const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
                    const service = new DynamicDataService(http);
                    const table: any = await CacheService.ReadTable(DataSourceId);
                    let DataSource: any = {};
                    if (status.ActualSettings.UseActualParam) {
                        DataSource = status.ActualParameter;
                    } else {
                        const calculator = new WorkflowFormulaCalculator(status);
                        DataSource = calculator.CalcFormula(status.ActualSettings.Formula);
                    }
                    if (DataSource != null) {
                        const errors = await SaveDataWFModuleExecuter.CheckSavePossible(table, DataSource);
                        if (errors.length > 0) {
                            const list = [];
                            errors.forEach((error) => {
                                status.Logger.logError('SaveDataWF module: No Value set for field(' + error.Name + ')');
                                list.push(error.TranslatedCaption);
                            });
                            status.ActualParameter = list;
                            return 1;
                        } else {
                            const result = await service.SaveEntry(DataSourceId, DataSource).pipe(take(1)).toPromise();
                            status.ActualParameter = DataSource;
                            if (result) {
                                if (result.Success) {
                                    Object.assign(DataSource, result.UpdateValues);
                                    return result.IsNewEntry ? 2 : 0;
                                } else {
                                    status.Logger.logError('SaveDataWF modul: Data could not be saved. (' + result.Error + ')');
                                    return 1;
                                }
                            } else {
                                status.Logger.logError('SaveDataWF modul: Data could not be saved.');
                                return 1;
                            }
                        }
                    } else {
                        status.Logger.logError('SaveDataWF modul: No DataSource set.');
                    }
                }
            } else {
                if (typeof status.ActualSettings.ElemName === 'string') {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                    if (elem) {
                        if (elem.Element) {
                            const ds = elem.Element.DataSource;
                            if (ds) {
                                const DataSourceId = MetaHelper.FindDataSourceID(status.WorkflowLayoutService.Layout, elem);
                                if (DataSourceId) {
                                    const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
                                    const service = new DynamicDataService(http);
                                    const table: any = await CacheService.ReadTable(DataSourceId);
                                    const errors = await SaveDataWFModuleExecuter.CheckSavePossible(table, ds);
                                    if (errors.length > 0) {
                                        const list = [];
                                        errors.forEach((error) => {
                                            list.push(error.TranslatedCaption);
                                            status.Logger.logError('SaveDataWF module: No Value set for field(' + error.Name + ')');
                                        });
                                        status.ActualParameter = list;
                                        return 1;
                                    } else {
                                        const result = await service.SaveEntry(DataSourceId, ds).pipe(take(1)).toPromise();
                                        status.ActualParameter = ds;
                                        if (result) {
                                            if (result.Success) {
                                                Object.assign(ds, result.UpdateValues);
                                                return result.IsNewEntry ? 2 : 0;
                                            } else {
                                                status.Logger.logError('SaveDataWF modul: Data could not be saved. (' + result.Error + ')');
                                                return 1;
                                            }
                                        } else {
                                            status.Logger.logError('SaveDataWF modul: Data could not be saved.');
                                            return 1;
                                        }
                                    }
                                } else {
                                    status.Logger.logError('SaveDataWF modul: No DataSource set.');
                                }
                            } else {
                                status.Logger.logError('SaveDataWF modul: No Data found.');
                            }
                        } else {
                            status.Logger.logError('SaveDataWF modul: Control not found.');
                        }
                    } else {
                        status.Logger.logError('SaveDataWF modul: Element not found.');
                    }
                } else {
                    status.Logger.logError('SaveDataWF modul: No element name found.');
                }
            }
        } else {
            status.Logger.logError('SaveDataWF modul: No settings found.');
        }
        return super.execute(status);
    }
}
