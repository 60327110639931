import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import {  jsPlumb } from 'jsplumb';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { RequestNameDialog } from '../../../components/dialogs/reqeustname/reqeustname.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { KeyboardShortcuts } from '../../../helpers/keyboard.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { DataModel } from '../../../models/datamodel/datamodel.model';
import { DataSource } from '../../../models/datamodel/datasource.model';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery, SelfServiceObjectService } from '../../../services/datamodel.service';
import { MetaService } from '../../../services/meta.service';
import { DataModelDetail } from '../../../settings/datamodel/datamodel.settings';
import { DataSourceDetail } from '../../../settings/datamodel/datasource/datasource.settings';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { DataPreviewDetail, ObjectPreviewDetail } from '../../datamodel/common/adatapreview.settings';
import { ContainerViewDetail } from '../../datamodel/containerview/containerview.settings';
import { ContainerRelationEditDialog, RelationShip, RelationType } from '../../datamodel/dialogs/containerrelation.edit.dialog';
import * as go from 'gojs';

@Component({
    selector: 'erd-edit-settings',
    templateUrl:'erd.edit.settings.html',
    styleUrls: ['erd.edit.settings.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class ERDEditSettings implements OnInit {
  
   
    SelectedDataModelValue = null;
    get SelectedDataModel() {
        return this.SelectedDataModelValue;
    }
    set SelectedDataModel(value) {
        this.SelectedDataModelValue = value;
        if (value) {
            //this.EditMode = false;
            this.ResetInstance();

            this.LoadDataSources();
            this.LoadContainers();
        }
    }
    InitArgs;
    DataModels = [];
    DataSources = [];
    Relations = [];
    Tables = {};
    TablesLoaded = false;
    Containers = [];
    
     sourcelabel = "";
     targetlabel = "";

    ContainersLoaded = false;
    DataSourceMenu = [];
    ERDS = [];
    Endpoints = {};
    Connections = {};
    SelectedERD;
    RefreshModules = 0;
    EditMode = false;
    initInfo = {
        Initializing: false,
        Modules: 0
    };
    LinkData:{
        from: string;
        to: string;
        text: string;
        toText: string;
    }[]=[];

    //#region Loading
    LoadingValue = false;
    get Loading() {
        return this.LoadingValue;
    }
    set Loading(value) {
        this.LoadingValue = value;
        this.cdRef.detectChanges();
    }
    //#endregion
    Data = {
        Caption: '',
        Modules: [],
        DataSourceColors: {}
    };

    erdLinkDataArray=[];
     nodeDataArray: NodeData[]=[];
     nodeLinkArray=[];
     erdLists=[];
    zoomValue = 1;
    jsPlumbInstance;
    unlisten;
    ERD;
    ERDSelected = false;

    ShowCCO = false;
    lastConnectionMoved = false;
    getDBTypes(container): number[][] {
        switch (container.ContainerType) {
            case 'evidanza.MiddleWare.Shared.Container.ContainerExcel':
            case 'evidanza.MiddleWare.Shared.Container.ContainerCSV':
                return [[256]];
            case 'evidanza.MiddleWare.Shared.Container.ContainerJson':
            case 'evidanza.MiddleWare.Shared.Container.ContainerXML':
                return [[1048576]];
        }
        return [[256]];
    }
    getResourceBase(container): number {
        switch (container.ContainerType) {
            case 'evidanza.MiddleWare.Shared.Container.ContainerExcel':
            case 'evidanza.MiddleWare.Shared.Container.ContainerCSV':
            case 'evidanza.MiddleWare.Shared.Container.ContainerJson':
            case 'evidanza.MiddleWare.Shared.Container.ContainerXML':
                return 1;
        }
        return 0;
    }
    @ViewChild('myDiagramDiv') diagramRef!: ElementRef ;
    @ViewChild('datasourceMenuTrigger') datasourceMenuTrigger: MatMenuTrigger;
    //#region Lifecycle
    constructor(private dataService: DataModelService, private dataSettingsService: DataSettingsService, private metaService: MetaService, private dataObjectService: SelfServiceObjectService, protected factoryResolver: ComponentFactoryResolver,
        private keyboardShortcuts: KeyboardShortcuts, private translate: TranslateService, private zone: NgZone, protected cdRef: ChangeDetectorRef) {
    }
    
    ngOnInit(): void {
        this.ResetInstance();
        this.LoadDataModels();    
        this.LoadERD();
        //this.LoadERDS();
        this.unlisten = this.keyboardShortcuts.listen({

            'del': (event: KeyboardEvent): void => {
                //this.deleteSelectedItem();
            },
            'c.Control': (event: KeyboardEvent): void => {
                if (event.ctrlKey) {
                    //this.copySelectedItem();
                }
            },
            'v.Control': (event: KeyboardEvent): void => {
                if (event.ctrlKey) {
                    //this.pasteItem();
                }
            },
        }, {
            priority: 200,
            terminalWhitelist: ['F2']
        });
    }
    drawDiagram()
    {
        const $ = go.GraphObject.make;  // for conciseness in defining templates
        const existingDiagram = go.Diagram.fromDiv(this.diagramRef.nativeElement);

        if (existingDiagram) {
            existingDiagram.div = null; // Clear the div association to remove the existing diagram
          }
        let myDiagram  =
            new go.Diagram(this.diagramRef.nativeElement,  // must name or refer to the DIV HTML element
              {
                allowDelete: false,
                allowCopy: false,
                layout: $(go.ForceDirectedLayout),
                "undoManager.isEnabled": true,
              });
            
      var colors = {
        'red': '#be4b15',
        'green': '#52ce60',
        'blue': '#6ea5f8',
        'lightred': '#fd8852',
        'lightblue': '#afd4fe',
        'lightgreen': '#b9e986',
        'pink': '#faadc1',
        'purple': '#d689ff',
        'orange': '#fdb400',
      }

      // the template for each attribute in a node's array of item data
      var itemTempl =
        $(go.Panel, "Horizontal",
          $(go.Shape,
            { desiredSize: new go.Size(10, 10), strokeJoin: "round", strokeWidth: 3, stroke: null, margin: 2 },
            new go.Binding("figure", "figure"),
            new go.Binding("fill", "color"),
            new go.Binding("stroke", "color")),
          $(go.TextBlock,
            {
              stroke: "#333333",
              font: "bold 14px sans-serif"
            },
            new go.Binding("text", "name"))
        );

      // define the Node template, representing an entity
      myDiagram.nodeTemplate =
        $(go.Node, "Auto",  // the whole node panel
          {
            selectionAdorned: true,
            resizable: true,
            layoutConditions: go.Part.LayoutStandard & ~go.Part.LayoutNodeSized,
            fromSpot: go.Spot.AllSides,
            toSpot: go.Spot.AllSides,
            isShadowed: true,
            shadowOffset: new go.Point(3, 3),
            shadowColor: "#C5C1AA"
          },
          new go.Binding("location", "location").makeTwoWay(),
          // whenever the PanelExpanderButton changes the visible property of the "LIST" panel,
          // clear out any desiredSize set by the ResizingTool.
          new go.Binding("desiredSize", "visible", v => new go.Size(NaN, NaN)).ofObject("LIST"),
          // define the node's outer shape, which will surround the Table
          $(go.Shape, "RoundedRectangle",
            { fill: 'white', stroke: "#eeeeee", strokeWidth: 3 }),
          $(go.Panel, "Table",
            { margin: 8, stretch: go.GraphObject.Fill },
            $(go.RowColumnDefinition, { row: 0, sizing: go.RowColumnDefinition.None }),
            // the table header
            $(go.TextBlock,
              {
                row: 0, alignment: go.Spot.Center,
                margin: new go.Margin(0, 24, 0, 2),  // leave room for Button
                font: "bold 16px sans-serif"
              },
              new go.Binding("text", "key")),
            // the collapse/expand button
            $("PanelExpanderButton", "LIST",  // the name of the element whose visibility this button toggles
              { row: 0, alignment: go.Spot.TopRight }),
            // the list of Panels, each showing an attribute
            $(go.Panel, "Vertical",
              {
                name: "LIST",
                row: 1,
                padding: 2,
                alignment: go.Spot.TopLeft,
                defaultAlignment: go.Spot.Left,
                stretch: go.GraphObject.Horizontal,
                itemTemplate: itemTempl
              },
              new go.Binding("itemArray", "items"))
          )  // end Table Panel
        );  // end Node

      // define the Link template, representing a relationship
      myDiagram.linkTemplate =
        $(go.Link,  // the whole link panel
          {
            selectionAdorned: true,
            layerName: "Foreground",
            reshapable: true,
            routing: go.Link.AvoidsNodes,
            corner: 2,
            curve: go.Link.JumpOver
          },
          $(go.Shape,  // the link shape
            { stroke: "#303B45", strokeWidth: 2.5 }),
          $(go.TextBlock,  // the "from" label
            {
                text: "Node Text", visible: false,
              textAlign: "center",
              font: "bold 14px sans-serif",
              stroke: "#1967B3",
              segmentIndex: 0,
              segmentOffset: new go.Point(NaN, NaN),
              segmentOrientation: go.Link.OrientUpright
            },
            new go.Binding("text", "text")),
          $(go.TextBlock,  // the "to" label
            {
              textAlign: "center",
              font: "bold 14px sans-serif",
              stroke: "#1967B3",
              segmentIndex: -1,
              segmentOffset: new go.Point(NaN, NaN),
              segmentOrientation: go.Link.OrientUpright
            },
            new go.Binding("text", "toText"))
        );
     
      myDiagram.model = new go.GraphLinksModel(
        {
          copiesArrays: true,
          copiesArrayObjects: true,
          nodeDataArray: this.nodeDataArray,
          linkDataArray: this.LinkData
        });
    }


    //#endregion
    //#region DataModel
    LoadDataModels() {
        this.Loading = true;
        this.SelectedDataModel = null;
        this.DataModels = [];
        this.dataService.GetModels().subscribe((result) => {
            if (result && result.length > 0) {
                this.DataModels = result;
                if (result.length == 1) {
                    this.SelectedDataModel = result[0];
                }
            }
            this.Loading = false;
            this.cdRef.detectChanges();
        });
    }
    DataModelSelected(item) {
        if (item == 'new') {
            const dm = new DataModel();
            dm.Name = 'DataModel_' + this.DataModels.length;
            dm.Caption = new TranslatedString(this.translate.instant('@@Neues DataModel'));
            BaseDialog.ShowDialog({
                ContentType: DataModelDetail,
                InitArgs: { Data: dm },
                Title: 'NewDataModel',
                Buttons: DialogButton.OkCancel,
                Handler: (result) => {
                    if (result) {
                        item = result;
                        //this.dataService.SaveModel(item).subscribe(result => {
                        //    if (result) {
                        //        this.LoadDataModels();
                        //    }
                        //});
                    }
                    return true;
                },
                HeightText: '100vh',
                WidthText: '100vw',
                AutoFocus: false
            });
        } else {
            this.SelectedDataModel = item;
        }
    }
    //#endregion
    //#region DataSource
    LoadDataSources() {
        this.DataSources = [];
        this.DataSourceMenu = [];
        this.dataService.GetDataSources(this.SelectedDataModel.SID).subscribe((result) => {
            if (result && result.length > 0) {
                this.DataSources = result;
                this.Tables = {};
                this.TablesLoaded = false;
                const count = this.DataSources.length;
                let i = 0;
                this.DataSources.forEach(async (ds) => {
                    let retVal = await this.LoadTables(ds);
                    i += 1;
                    if (i == count) {
                        this.TablesLoaded = true;
                        this.Compare();
                    }
                });
                this.cdRef.detectChanges();
            }
        });
    }
    NewDataSourceSelected() {
        let datasource = new DataSource();
        datasource.DataModelID = this.SelectedDataModel.SID;
        datasource.Name = 'DataSource_' + this.DataSources.length;
        datasource.Caption = new TranslatedString(this.translate.instant('@@Neue Datenquelle'));
        BaseDialog.ShowDialog({
            ContentType: DataSourceDetail,
            InitArgs: { Data: datasource },
            Title: 'NewDataSource',
            Buttons: DialogButton.OkCancel,
            Handler: (result) => {
                if (result) {
                    this.dataService.SaveDataSource(result).subscribe(result => {
                        if (result) {
                            this.LoadDataModels();
                        }
                    });
                }
                return true;
            },
            HeightText: '100vh',
            WidthText: '100vw',
            AutoFocus: false
        });
    }
    DataSourceSelected(item) {
        if (this.datasourceMenuTrigger) {
            this.datasourceMenuTrigger.closeMenu();
        }
        if (item) {
            if (item.data && item.data.Type) {
                this.dataService.GetDataSource(item.data.SID).subscribe((ds) => {
                    if (ds) {
                        BaseDialog.ShowDialog({
                            ContentType: DataSourceDetail,
                            InitArgs: { Data: ds },
                            Title: 'EditDataSource',
                            Buttons: DialogButton.OkCancel,
                            Handler: (result) => {
                                if (result) {
                                    this.dataService.SaveDataSource(result).subscribe(result => {
                                        if (result) {
                                            this.LoadDataModels();
                                        }
                                    });
                                }
                                return true;
                            },
                            HeightText: '100vh',
                            WidthText: '100vw',
                            AutoFocus: false
                        });
                    }
                })
            } else {
                if (item) {
                    if (item.IsContainer) {
                        this.EditContainer(item.Container, false);
                    } else {
                        this.Add(null, item);
                    }
                }
            }
        }
    }
    LoadTables(ds):Promise<any> {
        return new Promise((resolve) => {
            this.dataService.GetTables(ds.SID).subscribe((result) => {
                let item = {
                    name: ds.Caption,
                    data: ds,
                    datasource: ds,
                    IsDataSource: true
                }
                if (result && result.length > 0) {
                    item['children'] = [];
                    result.sort((a, b) => {
                        if (a.Name > b.Name) {
                            return 1;
                        }
                        if (a.Name < b.Name) {
                            return -1;
                        }
                        return 0;
                    }).forEach((table) => {
                        let sub = {
                            name: table.Name,
                            data: table,
                            datasource:ds
                        }
                        item['children'].push(sub);
                    });
                }
                this.Tables[ds.SID] = result;
                this.DataSourceMenu.push(item);
                resolve(true);
            });
        });
    }
    //#endregion
    //#region Container
    LoadContainers() {
        this.ContainersLoaded = false;
        this.dataService.GetContainers(this.SelectedDataModel.SID).subscribe((result) => {
            this.Containers = result;
            //this.Data.Modules = [];
            if (result && result.length > 0) {
                let modules = [...this.Data.Modules];
                this.Data.Modules = [];
                result.forEach((cont) => {
                    let found = modules.find((value) => value.ID == cont.SID);
                    if (found) {
                        found.Data = cont;
                        this.Data.Modules.push(found);
                    } // else {
                    //    this.Data.Modules.push(item);
                    //}
                });
            }
            this.ContainersLoaded = true;
            this.Compare();
            this.dataSettingsService.GetAllRelations(this.SelectedDataModel.SID).subscribe((relations) => {
                this.Relations = relations;
                this.FillConnectors();
                this.Loading = false;
            });
        });
    }
    async Compare() {
        if (this.TablesLoaded && this.ContainersLoaded) {
            //this.Loading = true;
            let count = 0;
            let index = 0;
            for (let i = 0; i < this.Containers.length; i++) {
                let container = this.Containers[i];
                const dsm = this.DataSourceMenu.find((item) => item.data.SID == container.DataSourceId);
                if (dsm) {
                    let dsmi = dsm.children.find((item) => item.name == container.Resource || item.name == container.Name);
                    if (dsmi) {
                        if (this.Data && this.Data.Modules && this.Data.Modules.length > 0) {
                            let found = this.Data.Modules.find((value) => value.ID == container.SID);
                            dsmi.IsSelected = found ? true : false;
                        } else {
                            dsmi.IsSelected = false;
                        }

                        dsmi.IsContainer = true;
                        dsmi.Container = container;
                    }
                }
            }
            this.RefreshModules += 1;
            this.cdRef.detectChanges();
        }
    }
    ContainerSelected(Item, event) {
        event.stopPropagation();
        if (this.ERDSelected) {
            if (Item.IsSelected) {
                this.Data.Modules.splice(this.Data.Modules.indexOf(Item.Container), 1);
                let node =  this.nodeDataArray.find(a=>a.key==Item.Container.Resource)
                this.nodeDataArray.splice(this.nodeDataArray.indexOf(node),1)
                this.drawDiagram();
            } else {
                let item = {
                    X: 0,
                    Y: 0,
                    ID: Item.Container.SID,
                    Data: Item.Container,
                    Color: Math.floor(Math.random() * 16777215).toString(16)
                }
               this.Data.Modules.push(item);

     //#region ERD implementation
    //Start Node Model Creation
               var colors = {
                'red': '#be4b15',
                'green': '#52ce60',
                'blue': '#6ea5f8',
                'lightred': '#fd8852',
                'lightblue': '#afd4fe',
                'lightgreen': '#b9e986',
                'pink': '#faadc1',
                'purple': '#d689ff',
                'orange': '#fdb400',
              }
             
              let ERDS=[];
              let Node=[];
              Item.Container.Fields.forEach((module) => {
                let erd ={
                    name:module.TranslatedCaption,
                    isKey:false,
                    figure:'Decision',
                    color:colors.red,
                    id:module.ID
                };
              ERDS.push(erd);
              });
              let dynamicNodeData: NodeData = {
                key: Item.Container.Resource,
                items: ERDS,
                SID: Item.Container.SID,
              };
            
        this.nodeDataArray.push(dynamicNodeData)
   //End Node Model Creation

   // Start Node Link Creation 
   if (this.Relations && this.Relations.length > 0) {

    this.Relations.forEach((rel) => {
        let relkeys = [];

        if (rel.ElementRelations) {
            relkeys = Object.keys(rel.ElementRelations);
        }

        if (relkeys && relkeys.length > 0) {
            for (let i = 0; i < relkeys.length; i++) {
                let sourceID = rel.PrimaryContainer;
                let sourceFieldID = relkeys[i];
                let Source = this.nodeDataArray.find((value) => value.SID == sourceID);
                let SourceField = null;
                if (Source) {
                    SourceField = Source.items.find((value) => value.id == sourceFieldID);
                }
                let targetID = rel.SecondaryContainer;
                let targetFieldID = rel.ElementRelations[sourceFieldID];
                let Target = this.nodeDataArray.find((value) => value.SID == targetID);
                let TargetField = null;
                if (Target) {
                    TargetField = Target.items.find((value) => value.id == targetFieldID);
                }
                if (Source && Target) {
                    switch (rel.RelationShip) {
                        case RelationShip.ManyToMany:
                            this.sourcelabel = "m";
                            this.targetlabel = "n";
                            break;
                        case RelationShip.OneToMany:
                            this.sourcelabel = "1";
                            this.targetlabel = "n";
                            break;
                        case RelationShip.ManyToOne:
                            this.sourcelabel = "n";
                            this.targetlabel = "1";
                            break;
                    }

                    let node ={
                        from: Source.key,
                        to:Target.key,
                        text:this.sourcelabel,
                        toText:this.targetlabel,
                    };
                     let existRel=this.LinkData.find(a=>a.from==Source.key && a.to==Target.key)
                     if(existRel==null)
                        this.LinkData.push(node);
                    let index = Source.items.indexOf(SourceField);
                    index = Target.items.indexOf(TargetField);
                    };
                    };
        }
    });
        this.drawDiagram();
}            
   //END Node Link Creation 
  //#endregion
            }
            this.RefreshModules += 1;
            this.cdRef.detectChanges();
            this.ResetInstance();
        }
    }
    CreateContainer(item) {
        let retVal:any = {
            DataModelID: this.SelectedDataModel.SID,
            Name: item.name,
            Caption: null,
            Schema: item.data.Schema,
            DataSourceId: item.datasource.SID,
            Resource: item.name,
            ContainerType: '',
            Fields: [],
            Options: [],
            StructureOptions: [],
            SID: null,
            Version: null
        };
        if (item.data) {
            retVal.Caption = item.data.Caption;
            retVal.SID = item.data.SID;
            retVal.Version = item.data.Version;
        }
        if (item.datasource) {
            switch (item.datasource.Type) {
                case 'evidanza.Database.MySQLImpl.MySQLDBInfo':
                case 'evidanza.Database.PostgreSql.PGDBInfo':
                case 'evidanza.Database.SQLServer.SQLDBInfo':
                    retVal.SQLStatement = item.SQLStatement;
                    retVal.IsQuery = item.IsQuery?true:false;
                    retVal.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerView';
                    break;
                case 'evidanza.App.Shared.NonDatabase.MSDB.MSDBInfo':
                    if (item.name.endsWith('.xlsx') || item.name.endsWith('.xls')) {
                        retVal.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerExcel';
                    }
                    if (item.name.endsWith('.csv')) {
                        retVal.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerCSV';
                    }
                    if (item.name.endsWith('.json')) {
                        retVal.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerJson';
                    }
                    if (item.name.endsWith('.xml')) {
                        retVal.ContainerType = 'evidanza.MiddleWare.Shared.Container.ContainerXML';
                    }
                    break;
            }
        }
        return retVal;
    }
    Add(event, item) {
        if (event) {
            event.stopPropagation();
            if (this.datasourceMenuTrigger) {
                this.datasourceMenuTrigger.closeMenu();
            }
        }
        let retVal = this.CreateContainer(item);
        BaseDialog.ShowDialog({
            ContentType: RequestNameDialog,
            InitArgs: { Text: '', Placeholder: 'Name' },
            Title: '@@Name waehlen',
            Buttons: DialogButton.None,
            Handler: (result) => {
                if (result) {
                    this.Loading = true;
                    return new Promise((resolve) => {
                        //retVal.Name = result;
                        retVal.Caption = new TranslatedString(result);
                        this.dataService.SaveContainer(retVal.ContainerType, retVal).subscribe((result) => {
                            if (result) {
                                retVal.SID = result.SID;
                                retVal.Version = result.Version;
                                this.dataSettingsService.GetDataPreview(retVal.SID).subscribe((preview) => {
                                    const list = [];
                                    this.Loading = false;
                                    if (preview && preview.Options) {
                                        preview.Options.forEach((opt) => {
                                            list.push({ Data: opt });
                                        });

                                    }
                                    retVal.Options = list;
                                    this.EditContainer(retVal, true);
                                    resolve(true);
                                });
                            }
                        })
                    })
                } else {
                    return true;
                }
            }
        });
    }
    Remove(event, item) {
        if (event) {
            event.stopPropagation();
            if (this.datasourceMenuTrigger) {
                this.datasourceMenuTrigger.closeMenu();
            }
        }
        this.Loading = true;
        this.dataService.DeleteContainer(item.SID).subscribe(() => {
            this.SelectedDataModel = this.SelectedDataModelValue;
            this.Loading = false;
            NotificationHelper.Success("@@Delete Successful", "@@Delete Container");
        });
    }
    Edit(event, item) {
        if (event) {
            event.stopPropagation();
            if (this.datasourceMenuTrigger) {
                this.datasourceMenuTrigger.closeMenu();
            }
        }
        this.EditContainer(item.Container, false);

    }
    async EditContainer(container, LoadFields) {
        if (container) {

            let contentType: any;
            switch (container.ContainerType) {
                case 'evidanza.App.Shared.Dynamic.ContainerClassObject':
                    break;
                case 'evidanza.MiddleWare.Shared.Container.ContainerExcel':
                case 'evidanza.MiddleWare.Shared.Container.ContainerCSV':
                    contentType = DataPreviewDetail;
                    break;
                case 'evidanza.MiddleWare.Shared.Container.ContainerJson':
                case 'evidanza.MiddleWare.Shared.Container.ContainerXML':
                    contentType = ObjectPreviewDetail;
                    break;
                case 'evidanza.MiddleWare.Shared.Container.ContainerView':
                    contentType = ContainerViewDetail;
                    break;
            }

            if (contentType) {
                const dataSourceQuery = new DataSourceQuery(container.DataModelID);
                dataSourceQuery.DBUsages = this.getDBTypes(container);
                dataSourceQuery.ResourceBase = this.getResourceBase(container);
                let ds = await this.dataService.GetDataSourcesByQuery(dataSourceQuery).toPromise();

                BaseDialog.ShowDialog({
                    ContentType: contentType,
                    InitArgs: { Data: container, DataSources: ds, LoadFields: LoadFields },
                    Title: 'Edit Container',
                    Buttons: DialogButton.OkCancel,
                    Handler: (item) => {
                        if (container.ContainerType == 'evidanza.MiddleWare.Shared.Container.ContainerView') {
                            if (item) {
                                const data = Object.assign({}, item);
                                if (typeof data.IsQuery === 'boolean' && !data.IsQuery) {
                                    data.SQLStatement = null;
                                }

                                delete data.IsQuery;
                                delete data.Resource;
                                this.dataService.SaveContainer('evidanza.MiddleWare.Shared.Container.ContainerView', data).subscribe((result) => {
                                    if (result) {
                                        container.SID = result.SID;
                                        this.Loading = false;
                                        this.SelectedDataModel = this.SelectedDataModelValue;
                                        NotificationHelper.Success("@@Save Successful", "@@Save Container");

                                    }
                                });
                            }
                        } else {
                            if (item) {

                                this.Loading = true;
                                const saveReq = {
                                    SID: item.SID,
                                    Name: item.Name,
                                    Caption: item.Caption,
                                    CapsuleTag: item.CapsuleTag,
                                    DataSourceId: item.DataSourceId,
                                    Resource: item.Resource,
                                    DataModelID: container.DataModelID,
                                    Fields: [],
                                    TypeName: container.ContainerType,
                                    Options: {}
                                };
                                if (item.Fields) {
                                    item.Fields.forEach((field) => {
                                        saveReq.Fields.push(JSON.stringify(field));
                                    });
                                }
                                if (item.Options) {
                                    item.Options.forEach((opt) => {
                                        if (opt.Data && opt.Data.Value != null) {
                                            saveReq.Options[opt.Data.Name] = opt.Data.Value.toString();
                                        }
                                    });
                                }

                                this.dataSettingsService.SaveDataPreview(saveReq).subscribe((result) => {
                                    if (result) {
                                        container.SID = result.SID;
                                        this.Loading = false;
                                        this.SelectedDataModel = this.SelectedDataModelValue;
                                        NotificationHelper.Success("@@Save Successful", "@@Save Container");
                                    }
                                });
                            }
                        }
                        
                        return true;
                    },
                    HeightText: '100vh',
                    WidthText: '100vw',
                    AutoFocus: false
                });
            }
        }

    }
    //#endregion
    //#region Relations
    saveRelation(rel, Container, isNew) {
        if (rel) {
            this.Loading = true;
            let crsr = {
                Relation: rel,
                ActContainer: null
            };
            if (Container) {
                crsr.ActContainer = Container.SID;
            }
            this.dataSettingsService.SaveRelation(crsr).subscribe((result) => {
                NotificationHelper.Success("@@Relation erfolgreich gespeichert", "@@Relation speichern");
                this.getRelations();
                this.Loading = false;
            });
        }
    }
    async deleteRelation(rel) {

        let retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Sind Sie sicher, dass Sie die Relation loeschen wollen?'),
            new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
        if (retVal == MessageBoxResult.Yes) {
            this.Loading = true;
            this.dataService.DeleteContainer(rel.SID).subscribe(() => {
                NotificationHelper.Success("@@Relation erfolgreich geloescht", "@@Relation loeschen");
                this.getRelations();
                this.Loading = false;
            });
            
        }
    }
    getRelations() {
        this.dataSettingsService.GetAllRelations(this.SelectedDataModel.SID).subscribe((relations) => {
            this.Relations = relations;
            this.zone.runOutsideAngular(() => {
                this.Data.Modules.forEach((module) => {
                    this.jsPlumbInstance.removeAllEndpoints(module.ID);
                });
                //this.FillConnectors();
            });
        });
    }
    //#endregion
    //#region ERD
    compareObjects(o1: any, o2: any) {
        if (typeof (o1) == 'object' && typeof (o2) == 'object') {
            return o1._Id == o2._Id;
        } else {
            return false;
        }
    }
    LoadERD() {
        if (this.InitArgs) {
            this.Loading = true;
            let ID = this.InitArgs.ID;
            this.Data = { Caption: '', Modules: [], DataSourceColors: [] };
            this.metaService.ReadERDById(ID).subscribe((result) => {
                if (result) {
                    this.Data = result;
                }
                this.ERDSelected = true;
                if (this.DataSources) {
                    if (!this.Data.DataSourceColors) {
                        this.Data.DataSourceColors = {};
                    }
                    this.DataSources.forEach((ds) => {
                        if (!this.Data.DataSourceColors[ds.SID]) {
                            this.Data.DataSourceColors[ds.SID] = '#' + Math.floor(Math.random() * 16777215).toString(16);
                        }
                    });
                }
                this.ResetInstance();
                this.LoadContainers();
            });
        }
    }
    //LoadERDS() {
    //    this.Loading = true;
    //    this.metaService.ReadERDs().subscribe((result) => {
    //        if (result) {
    //            this.ERDS = result;
    //        }
    //    });
    //}
    //ERDChanged() {
    //    if (typeof (this.Data) == 'string') {
    //        BaseDialog.ShowDialog({
    //            ContentType: RequestNameDialog,
    //            InitArgs: { Text: '', Placeholder: 'Name' },
    //            Title: '@@Name waehlen',
    //            Buttons: DialogButton.None,
    //            Handler: (result) => {
    //                if (result) {
    //                    this.Data = { Caption: result, Modules: [], DataSourceColors: [] };
    //                }
    //                this.Save();
    //                this.ERDS.push(this.Data);

    //                this.SelectedDataModel = this.SelectedDataModelValue;
    //                return true;
    //            }
    //        });
    //    } else {
    //        this.Loading = true;
    //        let ID = this.Data['_Id'];
    //        this.Data = { Caption: '', Modules: [], DataSourceColors:[] };
    //        this.metaService.ReadERDById(ID).subscribe((result) => {
    //            if (result) {
    //                this.Data = result;
    //            }
    //            if (this.DataSources) {
    //                if (!this.Data.DataSourceColors) {
    //                    this.Data.DataSourceColors = {};
    //                }
    //                this.DataSources.forEach((ds) => {
    //                    if (!this.Data.DataSourceColors[ds.SID]) {
    //                        this.Data.DataSourceColors[ds.SID] = '#'+Math.floor(Math.random() * 16777215).toString(16);
    //                    }
    //                });
    //            }
    //            this.ResetInstance();
    //            this.LoadContainers();
    //        })
    //    }
    //    this.ERDSelected = true;
    //}
    Save() {
        this.Loading = true;
        this.metaService.AddOrUpdateERD(this.Data).subscribe((result) => {
            if (result) {
                NotificationHelper.Success("@@Save Successful", "@@Save ERD");
                this.Data['_Id'] = result.SID;
                this.Data['_version'] = result.Version;
            } else {
                NotificationHelper.Error("@@Something went wrong", "@@Save ERD");
            }
            this.Loading = false;
        });
    }
    //DeleteERD() {
    //    this.Loading = true;
    //    this.metaService.RemoveERD(this.Data['_Id']).subscribe((result) => {
    //        if (result) {
    //            NotificationHelper.Success("@@Delete Successful", "@@Delete ERD");
    //        } else {
    //            NotificationHelper.Error("@@Something went wrong", "@@Delete ERD");
    //        }
    //        this.Data = {
    //            Caption: '',
    //            Modules: [],
    //            DataSourceColors: {}
    //        };
    //        this.LoadERDS();
    //        this.ERDSelected = false;
    //        this.EditMode = false;
    //        this.ResetInstance();
    //        this.Loading = false;
    //    });
    //}
    //#endregion
    //#region Zoom
    getZoomText() {
        return Math.round(this.zoomValue * 100);
    }
    increaseZoom() {
        this.zoomValue += 0.05;
        this.jsPlumbInstance.setZoom(this.zoomValue);
    }
    decreaseZoom() {
        if (this.zoomValue > 0.05) {
            this.zoomValue -= 0.05;
            this.jsPlumbInstance.setZoom(this.zoomValue);
        }
    }
    resetZoom() {
        this.zoomValue = 1;
        this.jsPlumbInstance.setZoom(this.zoomValue);
    }
    //#endregion
    //#region jsplumb
    ResetInstance() {
        if (this.jsPlumbInstance) {
            this.jsPlumbInstance.reset();
        }
        this.Loading = true;
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.jsPlumbInstance = jsPlumb.getInstance({
                    Connector: 'Flowchart'
                });
                if (!this.initInfo.Initializing) {
                    this.jsPlumbInstance.bind('connection', this.connection.bind(this));
                    this.jsPlumbInstance.bind('connectionDetached', this.connectionDetached.bind(this));
                    this.jsPlumbInstance.bind('connectionMoved', this.connectionMoved.bind(this));
                }
                this.RefreshModules += 1;                
               // this.FillConnectors();
                this.Loading = false;
            }, 50);
        });
        
    }
    connection(info, event) {
        if (this.lastConnectionMoved) {
            this.lastConnectionMoved = false;
        } else {
            //const endType = info.targetEndpoint.getParameter('EndPointType');
            //if (typeof endType === 'string') {
            //    const sourceType = this.getEndPointType(info.sourceEndpoint, []);
            //    if (typeof sourceType !== 'string' || sourceType !== endType) {
            //        this.jsPlumbInstance.deleteConnection(info.connection, { fireEvent: false });
            //        return;
            //    }
            //}
            const origSourceIDs = info.sourceEndpoint.getUuid().split('_');
            const origTargetIDs = info.targetEndpoint.getUuid().split('_');

            
            let sourceFieldID = origSourceIDs[1];
            let sourceID = origSourceIDs[0];
            let targetFieldID = origTargetIDs[1];
            let targetID = origTargetIDs[0];
            if (this.Connections[sourceID + '_' + sourceFieldID + '||' + targetID + '_' + targetFieldID]) {

            } else {
                let Source = this.Data.Modules.find((value) => value.ID == sourceID);
                let Target = this.Data.Modules.find((value) => value.ID == targetID);

                if (Source && Target) {
                    if (Source && Source.Data.DataModelID) {
                        let rel = {};
                        rel[sourceFieldID] = targetFieldID;

                        let relation = this.Relations.find((value) => value.PrimaryContainer == sourceID && value.SecondaryContainer == targetID && value.ElementRelations && value.ElementRelations[sourceFieldID] && value.ElementRelations[sourceFieldID] == targetFieldID);
                        if (!relation) {
                            var args = {
                                DataModelID: Source.Data.DataModelID,
                                IsNew: false,
                                SID: null,
                                PrimaryContainer: sourceID,
                                SecondaryContainer: targetID,
                                ElementRelations: rel,
                                JoinType: RelationType.Inner,
                                RelationShip: RelationShip.OneToMany
                            };
                            var that = this;
                            BaseDialog.ShowDialog({
                                ContentType: ContainerRelationEditDialog,
                                InitArgs: args,
                                Handler: function (rel) {
                                    that.saveRelation(rel, Source, true);
                                    return true;
                                },
                                Title: args.IsNew ? '@@Neue Relation' : '@@Relation bearbeiten'
                            });
                        }
                    }
                }
            }
        }
    }
    connectionDetached(info, event) {
        const origSourceIDs = info.sourceEndpoint.getUuid().split('_');
        const origTargetIDs = info.targetEndpoint.getUuid().split('_');
        //for (let i = 0; i < this.DataValue.Connectors.length; i++) {
        //    const con = this.DataValue.Connectors[i];
        //    if (con.EntryModule === parseInt(origTargetIDs[1], 10) && con.EntryOption === parseInt(origTargetIDs[2], 10) &&
        //        con.ExitModule === parseInt(origSourceIDs[1], 10) && con.ExitOption === parseInt(origSourceIDs[2], 10)) {
        //        this.DataValue.Connectors.splice(i, 1);
        //        this.onContentChanged();
        //        break;
        //    }
        //}
    }

    connectionMoved(info, event) {
        const endType = info.newTargetEndpoint.getParameter('EndPointType');
        //if (typeof endType === 'string') {
        //    const sourceType = this.getEndPointType(info.newSourceEndpoint, []);
        //    if (typeof sourceType !== 'string' || sourceType !== endType) {
        //        this.jsPlumbInstance.deleteConnection(info.connection, { fireEvent: false });
        //        this.connectionDetached({
        //            sourceEndpoint: info.originalSourceEndpoint,
        //            targetEndpoint: info.originalTargetEndpoint
        //        }, null);
        //        return;
        //    }
        //}
        //this.lastConnectionMoved = true;
        //const origSourceIDs = info.originalSourceEndpoint.getUuid().split('_');
        //const origTargetIDs = info.originalTargetEndpoint.getUuid().split('_');
        //let connection;
        //this.DataValue.Connectors.some(con => {
        //    if (con.EntryModule === parseInt(origTargetIDs[1], 10) && con.EntryOption === parseInt(origTargetIDs[2], 10) &&
        //        con.ExitModule === parseInt(origSourceIDs[1], 10) && con.ExitOption === parseInt(origSourceIDs[2], 10)) {
        //        connection = con;
        //        return true;
        //    }
        //    return false;
        //});
        //if (connection) {
        //    if (info.index === 0) { // Source
        //        const newSourceIDs = info.newSourceEndpoint.getUuid().split('_');
        //        connection.ExitModule = parseInt(newSourceIDs[1], 10);
        //        connection.ExitOption = parseInt(newSourceIDs[2], 10);
        //        const label = this.getConnectorLabel(connection.ExitModule, connection.ExitOption);
        //        if (label !== '') {
        //            info.connection.setLabel(label);
        //        }
        //    } else { // Target
        //        const newTargetIDs = info.newTargetEndpoint.getUuid().split('_');
        //        connection.EntryModule = parseInt(newTargetIDs[1], 10);
        //        connection.EntryOption = parseInt(newTargetIDs[2], 10);
        //    }
        //    this.onContentChanged();
        //}
    }

    elementMoved(event, module) {
        if (module) {
            module.X = event.finalPos[0];
            module.Y = event.finalPos[1];
        }
    }
    FillConnectors() {
        if (this.Data && this.Data.Modules) {
            if (this.EditMode) {
                this.Data.Modules.forEach((module) => {
                    this.jsPlumbInstance.draggable(module.ID, {
                        getConstrainingRectangle: () => [99999, 99999],
                        containment: true,
                        stop: (e) => { this.elementMoved(e, module); }
                    });
                    if (module.Data && module.Data.Fields && module.Data.ContainerType != "evidanza.App.Shared.Dynamic.ContainerClassObject") {
                        let percent = 1.0 / (module.Data.Fields.length + 1);
                        for (let i = 0; i < module.Data.Fields.length; i++) {
                            let field = module.Data.Fields[i];
                            const expoInfo = {
                                anchor: [[0, (percent * (i + 1)) + (percent / 2)], [1, (percent * (i + 1)) + (percent / 2)]],
                                isTarget: true,
                                isSource: true,
                                endpoint: ['Rectangle', { width: 10, height: 10 }],
                                maxConnections: -1,
                                uuid: module.Data.SID + '_' + field.ID,
                            };

                            this.Endpoints[module.Data.SID + '_' + field.ID] = this.jsPlumbInstance.addEndpoint(module.ID, expoInfo);
                        }

                    }
                });
            }
            if (this.Relations && this.Relations.length > 0) {
                this.Relations.forEach((rel) => {
                    let relkeys = [];
                    if (rel.ElementRelations) {
                        relkeys = Object.keys(rel.ElementRelations);
                    }
                    if (relkeys && relkeys.length > 0) {
                        for (let i = 0; i < relkeys.length; i++) {
                            let sourceID = rel.PrimaryContainer;
                            let sourceFieldID = relkeys[i];
                            let Source = this.Data.Modules.find((value) => value.ID == sourceID);
                            let SourceField = null;
                            if (Source) {
                                SourceField = Source.Data.Fields.find((value) => value.ID == sourceFieldID);
                            }
                            

                            let targetID = rel.SecondaryContainer;
                            let targetFieldID = rel.ElementRelations[sourceFieldID];
                            let Target = this.Data.Modules.find((value) => value.ID == targetID);
                            let TargetField = null;
                            if (Target) {
                                TargetField = Target.Data.Fields.find((value) => value.ID == targetFieldID);
                            }
                            if (Source && Target) {
                                let sourcelabel = "";
                                let targetlabel = "";
                                switch (rel.RelationShip) {
                                    case RelationShip.ManyToMany:
                                        sourcelabel = "m";
                                        targetlabel = "n";
                                        break;
                                    case RelationShip.OneToMany:
                                        sourcelabel = "1";
                                        targetlabel = "n";
                                        break;
                                    case RelationShip.ManyToOne:
                                        sourcelabel = "n";
                                        targetlabel = "1";
                                        break;
                                }

                                let SourcePercent = 1.0 / (Source.Data.Fields.length + 1);
                                let index = Source.Data.Fields.indexOf(SourceField);
                                let expoInfo = {
                                    anchor: [[0, (SourcePercent * (index + 1)) + (SourcePercent / 2)], [1, (SourcePercent * (index + 1)) + (SourcePercent / 2)]],
                                    isTarget: false,
                                    isSource: true,
                                    endpoint: ['Rectangle', { width: 10, height: 10 }],
                                    maxConnections: -1,
                                    uuid: sourceID + '_' + sourceFieldID + '_' + targetID + '_' + targetFieldID,
                                    overlays: [
                                        
                                    ]
                                };
                                if (this.Endpoints[sourceID + '_' + sourceFieldID]) {
                                    this.jsPlumbInstance.deleteEndpoint(this.Endpoints[sourceID + '_' + sourceFieldID]);
                                }
                                this.Endpoints[sourceID + '_' + sourceFieldID + '_' + targetID + '_' + targetFieldID] = this.jsPlumbInstance.addEndpoint(sourceID, expoInfo);
                                let TargetPercent = 1.0 / (Target.Data.Fields.length + 1);
                                index = Target.Data.Fields.indexOf(TargetField);
                                expoInfo = {
                                    anchor: [[0, (TargetPercent * (index + 1)) + (TargetPercent / 2)], [1, (TargetPercent * (index + 1)) + (TargetPercent / 2)]],
                                    isTarget: true,
                                    isSource: false,
                                    endpoint: ['Rectangle', { width: 10, height: 10 }],
                                    maxConnections: -1,
                                    uuid: targetID + '_' + targetFieldID + '_' + sourceID + '_' + sourceFieldID,
                                    overlays: [
                                        
                                    ]
                                };
                                if (this.Endpoints[targetID + '_' + targetFieldID]) {
                                    this.jsPlumbInstance.deleteEndpoint(this.Endpoints[targetID + '_' + targetFieldID]);
                                }
                                this.Endpoints[targetID + '_' + targetFieldID + '_' + sourceID + '_' + sourceFieldID] = this.jsPlumbInstance.addEndpoint(targetID, expoInfo);

                                let left = '&#11164;';
                                let right = '&#11166;';
                                let leftArrow = 0;
                                let rightArrow = 0;
                                switch (rel.JoinType) {
                                    case RelationType.Inner:
                                        leftArrow = 1;
                                        rightArrow = -1;
                                        break;
                                    case RelationType.Left:
                                        leftArrow = 1;
                                        rightArrow = 1;
                                        break;
                                    case RelationType.Outer:
                                        leftArrow = -1;
                                        rightArrow = 1;
                                        break;
                                    case RelationType.Right:
                                        leftArrow = -1;
                                        rightArrow = -1;
                                        break;
                                    case RelationType.Union: break;
                                }
                                let color = 'black';//'#' + Math.floor(Math.random() * 16777215).toString(16);
                                let connectOptions = {
                                    connector: ['Flowchart', { stub: [40, 0], cornerRadius: 1, gap: 10 }],
                                    uuids: [
                                        sourceID + '_' + sourceFieldID + '_' + targetID + '_' + targetFieldID,
                                        targetID + '_' + targetFieldID + '_' + sourceID + '_' + sourceFieldID
                                    ],
                                    endpoint: ['Rectangle', { width: 10, height: 10 }],
                                    paintStyle: { outlineStroke: color, outlineWidth: 8 },
                                    overlays: []
                                };
                                if (rel.JoinType == RelationType.Union) {
                                    connectOptions.connector = ['Flowchart', { stub: [20, 0], cornerRadius: 1, gap: 0 }],
                                        connectOptions.overlays = [
                                            ["Custom", {
                                                create: function (component) {
                                                    return $("<div style=\"width:20px;height:20px;\"><div class=\"connectorUnion\"></div></div>");
                                                },
                                                location: 0.3
                                            }],
                                            ["Custom", {
                                                create: function (component) {
                                                    return $("<div style=\"width:20px;height:20px;\"><div class=\"connectorUnion\"></div></div>");
                                                },
                                                location: 0.7
                                            }],
                                            ['Label', { label: sourcelabel, location: 0.3, cssClass: 'endpointLabel' }],
                                            ['Label', { label: targetlabel, location: 0.7, cssClass: 'endpointLabel' }]
                                        ]
                                } else {
                                    connectOptions.overlays = [
                                        ["Arrow", { location: 0.3, cssClass: 'connectorLabel', foldback: 0.9, direction: leftArrow, paintStyle: { fill: color }, }],
                                        ["Arrow", { location: 0.7, cssClass: 'connectorLabel', foldback: 0.9, direction: rightArrow, paintStyle: { fill: color }, }],
                                        ['Label', { label: sourcelabel, location: 0.3, cssClass: 'endpointLabel' }],
                                        ['Label', { label: targetlabel, location: 0.7, cssClass: 'endpointLabel' }]
                                    ]
                                }
                                this.Connections[sourceID + '_' + sourceFieldID + '||' + targetID + '_' + targetFieldID] = this.jsPlumbInstance.connect(connectOptions);
                                if (this.Connections[sourceID + '_' + sourceFieldID + '||' + targetID + '_' + targetFieldID]) {
                                    let that = this;
                                    this.Connections[sourceID + '_' + sourceFieldID + '||' + targetID + '_' + targetFieldID].bind("click", function (connection, originalEvent) {
                                        let Source = that.Data.Modules.find((value) => value.ID == sourceID);
                                        let Target = that.Data.Modules.find((value) => value.ID == targetID);

                                        if (Source && Target && that.EditMode) {
                                            if (Source && Source.Data.DataModelID) {
                                                let rel = {};
                                                rel[sourceFieldID] = targetFieldID;

                                                let relation = that.Relations.find((value) => value.PrimaryContainer == sourceID && value.SecondaryContainer == targetID && value.ElementRelations && value.ElementRelations[sourceFieldID] && value.ElementRelations[sourceFieldID] == targetFieldID);
                                                if (relation) {
                                                    var args = relation;
                                                    args.ShowDelete = true;
                                                    BaseDialog.ShowDialog({
                                                        ContentType: ContainerRelationEditDialog,
                                                        InitArgs: args,
                                                        Buttons: DialogButton.None,
                                                        Handler: function (rel) {
                                                            if (rel != null) {
                                                                if (rel == 'DELETE') {
                                                                    that.deleteRelation(args);
                                                                } else {
                                                                    that.saveRelation(rel, Source, true);
                                                                }
                                                            }
                                                            return true;
                                                        },
                                                        Title: args.IsNew ? '@@Neue Relation' : '@@Relation bearbeiten'
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
            }
            this.cdRef.detectChanges();
        }
    }
    GetEndpoint(source, field, position) {
        
    }
    //#endregion
    ChangeEditMode() {
        this.Loading = true;
        this.Connections = {};
        this.EditMode = !this.EditMode;
        LayoutHelper.setTimeout(10, 20).then(() => {
            this.zone.runOutsideAngular(() => {
                this.Data.Modules.forEach((module) => {
                    this.jsPlumbInstance.removeAllEndpoints(module.ID);
                });
                //this.FillConnectors();
                this.Loading = false;
            });
        });
    }
    showPreview(item) {
        if (item && item.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(item.SID, '@@Vorschau');
        }
    }
   
}
interface NodeData {
    key: string;
    SID:string;
    items: {
      name: string;
      iskey: boolean;
      figure: string;
      color: string;
      id:string
    }[];
    
  }

