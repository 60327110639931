import { Directive, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { deserialize, serialize } from "class-transformer";
import { DataSource } from "../../../../models/datamodel/datasource.model";

@Directive()
export class BaseDataSourcePanel implements OnInit {

    DBInfo;
    DBInfoType: any;
    DataSourceValue: DataSource;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        if (this.DataSourceValue) {
            if (this.DataSourceValue.DBInfo) {
                this.DBInfo = deserialize(this.DBInfoType, serialize(this.DataSourceValue.DBInfo));
            } else {
                this.DBInfo = new this.DBInfoType();
            }
            this.DataSourceValue.DBInfo = this.DBInfo;
        } else {
            this.DBInfo = new this.DBInfoType();
        }
        this.DataSourceChange.emit(this.DataSourceValue);
    }
    @Output() DataSourceChange = new EventEmitter<any>();

    ngOnInit(): void {        
    }

    @Output() OnDataChange = new EventEmitter<any>();
    onChange(ev) {
        this.OnDataChange.emit();
    }
}