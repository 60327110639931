import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export class IndexOf extends AFormula {
    static StaticID: string = '4d2b45ea-6967-455c-8c67-cc00aaf5a22e';

    ID(): string {
        return IndexOf.StaticID;
    }

    Name(): string {
        return 'IndexOf';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Ausgabe des Index einer Zeichenkette innerhalb einer Zeichenkette');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 13;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '('
            + TranslateHelper.TranslatorInstance.instant('@@Text') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Search') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@StartIndex') + ')';
    }

    MinVariableCount(): number {
        return 2;
    }

    MaxVariableCount(): number {
        return 3;
    }

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.Long;
        var text = '';
        var search = '';
        var startIndex = null;
        if (args && args.length >= 2) {
            if (typeof args[0].Value !== 'undefined' && args[0].Value != null) {
                text = String(args[0].Value);
            }
            if (typeof args[1].Value !== 'undefined' && args[1].Value != null) {
                search = String(args[1].Value);
            }
            if (args.length > 2) {
                startIndex = Number(args[2].Value);
                if (isNaN(startIndex)) {
                    startIndex = null;
                } else {
                    startIndex--;
                    if (startIndex < 0) {
                        startIndex = 0;
                    }
                    if (startIndex >= text.length) {
                        startIndex = null;
                    }
                }
            }
        }
        if (startIndex == null) {
            retVal.Value = text.indexOf(search);
        } else {
            retVal.Value = text.indexOf(search, startIndex);
        }
        return retVal;
    }
}