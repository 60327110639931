import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationTextBoxDialog } from '../../../components/controls/translationtextbox/translation.textbox.dialog';
import { TranslationDialog } from '../../../components/dialogs/translationdialog/translation.dialog';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { TranslatedString } from '../../../models/translatedstring.model';
import { LayoutService } from '../../../services/layout.service';
import { StandardRequestBase } from '../../../services/request-base';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'translationtextbox-theme-control',
    templateUrl: './translation.textbox.theme.control.html',
    styleUrls: ['./translation.textbox.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationTextBoxThemeControl extends BaseThemeControl {
    AllLanguages = [];
    //#region DataType
    DataTypeValue = 'translatedtext';;
    @Input()
    get DataType() {
        return this.DataTypeValue;
    }
    set DataType(val) {
        this.DataTypeValue = val;
        this.DataTypeChange.emit(this.DataTypeValue);
    }

    @Output() DataTypeChange = new EventEmitter<any>();
    //#endregion

    get DefaultValue() {
        if (this.DataType == 'translatedtext') {
            if (this.DataSourceInternal) {
                return this.DataSourceInternal.DefaultValue;
            }
        }
        if (this.DataType == 'string') {
            return this.DataSourceInternal
        }
        return null;
    }
    set DefaultValue(text) {
        if (this.DataType == 'translatedtext') {
            if (this.DataSourceInternal) {
                this.DataSourceInternal.DefaultValue = text;
            } else {
                this.DataSourceInternal = {
                    DefaultValue: text,
                    SerializedTranslations: []
                };
            }
        }
        if (this.DataType == 'string') {
            this.DataSourceInternal = text;
        }
    }

    IsRtl = false;
    constructor(private dialog: MatDialog, cdRef: ChangeDetectorRef, private requestService: StandardRequestBase) {
        super(cdRef);
        requestService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            let list = [];
            if (languages) {
                list.push(...languages);
            }
            list.unshift({ LCID: "default", Caption:"Default" })
            this.AllLanguages = list;
            this.FillLanguages();
            this.cdRef.detectChanges();
        });

        this.Subscriptions['DataSourceInternalChange'] = this.DataSourceInternalChange.subscribe(x => {
            this.FillLanguages();
        });

        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
    }
    FillLanguages() {
        if (this.AllLanguages && this.AllLanguages.length > 0) {
            if (this.DataSourceInternal == null) {
                this.AllLanguages.forEach((lang) => lang.Value = null);
            } else {
                this.AllLanguages[0].Value = this.DataSourceInternal.DefaultValue;
                if (this.DataSourceInternal.SerializedTranslations) {
                    this.AllLanguages.forEach((trans) => {
                        if (trans.LCID != 'default') {
                            let found = this.DataSourceInternal.SerializedTranslations.find((value) => value.LCID == trans.LCID);
                            if (found) {
                                trans.Value = found.Value;
                            } else {
                                trans.Value = null;
                            }
                        }
                        trans.Valid = true;
                    });
                }
            }
        }
    }

    langChanged(lang) {
        if (lang.LCID == 'default') {
            if (this.DataSourceInternal) {
                this.DataSourceInternal.DefaultValue = lang.Value;
            } else {
                this.DataSourceInternal = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: []
                };
            }
        } else {
            if (this.DataSourceInternal) {
                let trans = this.DataSourceInternal.SerializedTranslations.find((val) => val.LCID == lang.LCID);
                if (lang.Value == "") {
                    if (trans) {
                        this.DataSourceInternal.SerializedTranslations.splice(this.DataSourceInternal.SerializedTranslations.indexOf(trans), 1);
                    }
                } else {
                    if (trans) {
                        trans.Value = lang.Value;
                    } else {
                        this.DataSourceInternal.SerializedTranslations.push({ LCID: lang.LCID, Value: lang.Value });
                    }
                }
            } else {
                this.DataSourceInternal = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: [{ LCID: lang.LCID, Value: lang.Value }]
                };
            }
        }
        
    }

    openDialog() {
        if (this.DataType == 'translatedtext') {
            if (this.DataSourceInternal) {
                this.dialog.open(TranslationTextBoxDialog, { data: { Data: this.DataSourceInternal } });
            } else {
                const ts = new TranslatedString();
                const dialogRef = this.dialog.open(TranslationTextBoxDialog, { data: { Data: ts } });
                dialogRef.afterClosed().subscribe(() => {
                    this.DataSourceInternal = ts;
                });
            }
        }
        if (this.DataType == 'string') {
            TranslationDialog.ShowDialog(this.DataSourceInternal);
        }
    }
    getLanguageName(lcid: number): string {
        var retVal = '';
        let lang = this.AllLanguages.find((lang) => lang.LCID == lcid);
        if(lang) {
            retVal = lang.Caption;
        }
        return retVal;
    }
}
