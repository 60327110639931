import { Injectable } from '@angular/core';
import { NativeDateTimeAdapter, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './settings.service';

/** Whether the browser supports the Intl API. */
export const GA_SUPPORTS_INTL_API = typeof Intl !== 'undefined';

@Injectable()
export class GADateTimeAdapter extends NativeDateTimeAdapter {
    Calendar: string;
    SettingsSub;

    private static getDateText(dtf: Intl.DateTimeFormat, date: Date) {
        const d = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds()
            )
        );
        const text = dtf.format(d);
        return text.replace(/[\u200e\u200f]/g, '');
    }

    private CheckSettings() {
        if (!this.SettingsSub) {
            let settings = localStorage.getItem("GeneralSettings");
            if (settings) {
                const gs = JSON.parse(settings);
                if (gs && gs.Calendar) {
                    this.Calendar = gs.Calendar;
                }
            }
            this.SettingsSub = SettingsService.GeneralSettings.subscribe((settings) => {
                if (settings && settings.Calendar) {
                    this.Calendar = settings.Calendar;
                } else {
                    this.Calendar = null;
                }
            });
        }
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (GA_SUPPORTS_INTL_API) {
            this.CheckSettings();
            if (this.Calendar) {
                const dtf = new Intl.DateTimeFormat(this.getLocale(), {
                    month: style,
                    timeZone: 'utc',
                    calendar: this.Calendar
                });
                const retVal = [];
                for (let i = 0; i < 12; i++) {
                    retVal.push(GADateTimeAdapter.getDateText(dtf, new Date(2022, i, 1)));
                }
                return retVal;
            }
        }
        return super.getMonthNames(style);
    }

    getYearName(date: Date): string {
        if (GA_SUPPORTS_INTL_API) {
            this.CheckSettings();
            if (this.Calendar) {
                const dtf = new Intl.DateTimeFormat(this.getLocale(), {
                    year: 'numeric',
                    timeZone: 'utc',
                    calendar: this.Calendar
                });
                return GADateTimeAdapter.getDateText(dtf, date);
            }
        }
        return super.getYearName(date);
    }

    format(date: Date, displayFormat: any): string {
        this.CheckSettings();
        if (this.Calendar) {
            if (displayFormat) {
                displayFormat.calendar = this.Calendar;
            } else {
                displayFormat = {
                    calendar: this.Calendar
                };
            }
        }
        return super.format(date, displayFormat);
    }
}

@Injectable()
export class GAIntl extends OwlDateTimeIntl {
    Defaults = {};

    constructor(private translateService: TranslateService) {
        super();
        this.Defaults['cancelBtnLabel'] = this.cancelBtnLabel;
        this.Defaults['setBtnLabel'] = this.setBtnLabel;

        this.translate();
        translateService.onLangChange.subscribe(() => {
            this.translate();
            this.changes.next();
        });
    }

    translate() {
        let cancel = this.translateService.instant('@@OWLDTCancel');
        if (cancel == 'OWLDTCancel') { // nicht gefunden
            cancel = this.Defaults['cancelBtnLabel'];
        }
        this.cancelBtnLabel = cancel;
        let set = this.translateService.instant('@@OWLDTSet');
        if (set == 'OWLDTSet') { // nicht gefunden
            set = this.Defaults['setBtnLabel'];
        }
        this.setBtnLabel = set;
    }
}
