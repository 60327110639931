import { LayoutUnit } from './layoutunit.model';
import { Type } from 'class-transformer';

// @dynamic
export class LayoutUnitThickness {
    @Type(() => LayoutUnit)
    Left: LayoutUnit = new LayoutUnit();
    @Type(() => LayoutUnit)
    Right: LayoutUnit = new LayoutUnit();
    @Type(() => LayoutUnit)
    Top: LayoutUnit = new LayoutUnit();
    @Type(() => LayoutUnit)
    Bottom: LayoutUnit = new LayoutUnit();
    isValid() {
        return (this.Left && this.Left.isValid()) || (this.Top && this.Top.isValid()) ||
            (this.Right && this.Right.isValid()) || (this.Bottom && this.Left.isValid());
    }
    toJSON() {
        let left = LayoutUnit.ToStyle(this.Left);
        let right = LayoutUnit.ToStyle(this.Right);
        let top = LayoutUnit.ToStyle(this.Top);
        let bottom = LayoutUnit.ToStyle(this.Bottom);
        if (left || right || top || bottom) {
            return this
        } else {
            return null
        }
    }
}
