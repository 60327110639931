import { Observable, of, Subject } from "rxjs";
import { CacheItem } from "../models/cacheitem.model";
import { CacheTable } from "../models/cachetable.model";
import { IDriver } from "../models/idriver";

export class NoCache implements IDriver{
    db: any;
    Requests: any;

    public Initialize(db, version:number) {
        let promise = new Promise((resolve) => {
            console.info('Using no cache!');
            resolve(this);
        });
        return promise;
    }
   //#region Table
    public CreateTable(name:string): boolean {
        return true;
    }
    public CreateTables(tables: CacheTable[]): boolean {
        return true;
    }
    //#endregion
    //#region Create
    public Insert(table, entry: CacheItem): boolean {
        return true;
    }
    //#endregion
    //#region Read
    public ReadKey(table, key: string, request?): Observable<CacheItem> {
        if (request) {
            if (!this.Requests) {
                this.Requests = {};
            }
            if (!this.Requests[table]) {
                this.Requests[table] = {};
            }
            if (!this.Requests[table][key]) {
                return new Observable<CacheItem>((subscriber) => {
                    this.Requests[table][key] = new Subject();
                    request.subscribe((result) => {
                        let item = new CacheItem(key, result);
                        this.Requests[table][key].next(item);
                        subscriber.next(item);
                        delete this.Requests[table][key];
                    });
                });
            } else {
                return this.Requests[table][key];
            }
        } else {
            return of(null);
        }
    }
    public ReadAll(table: any): Observable<CacheItem[]> {
        return of(null);
    }
    //#endregion
    //#region Update
    public Update(table: any, entry: CacheItem): boolean {
        throw new Error("Method not implemented.");
    }
    //#endregion
    //#region Delete
    public Clear(table): boolean {
        return true;
    }
   
    public Delete(table: any, key: any): boolean {
        return true;
    }
    //#endregion
}