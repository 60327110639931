import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';


@Injectable()
export class GitService extends RequestBase {
    BasePath = "api/development/git";
   
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }
    IsGitActive() {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/IsGitActive', this.options);
    }
    Pull(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Pull', this.options);
    }
    Fetch(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Fetch', this.options);
    }
    GetBranches(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetBranches', this.options);
    }
    GetStatus(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetStatus', this.options);
    }
    GetRefLog(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetRefLog', this.options);
    }
    Checkout(branch, remote=null): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Checkout?branch='+branch+((remote!=null)?'&remote='+remote : ''), this.options);
    }
    GetDiff(source = null, target = null): Observable<any> {
        if (source && target) {
            return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDiff?source=' + source + '&target=' + target, this.options);
        }
        if (source) {
            return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDiff?source=' + source, this.options);
        }
        if (target) {
            return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDiff?target=' + target, this.options);
        }
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDiff', this.options);
    }
    GetLog(count, skip): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetLog?count='+count+'&skip='+skip, this.options);
    }
    Add(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Add', data, this.options);
    }
    Remove(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Remove', data, this.options);
    }
    StageAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/StageAll', this.options);
    }
    UnstageAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/UnstageAll', this.options);
    }
    Commit(text): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Commit',text, this.options);
    }
    Push(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Push', this.options);
    }
    CreateBranch(branch): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/CreateBranch?branch='+branch, this.options);
    }
    MergeBranch(branch): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/MergeBranch?branch='+branch, this.options);
    }
    DeleteBranch(branch): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/DeleteBranch?branch='+branch, this.options);
    }  

    ReadRepositories(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadRepositories', this.options);
    }  
    CreateRepository(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CreateRepository', data, this.options);
    }  
    RemoveRepository(name): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveRepository?name=' + name, this.options);
    }  
}
