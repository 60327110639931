import { Type } from "class-transformer";
import { LayoutUnit } from "../basic/layoutunit.model";
import { ControlStyle } from "../enums/controlstyle.enum";
import { TranslatedString } from "../translatedstring.model";
import { Color } from "./color.model";
import { Font } from "./font.model";

// @dynamic
export class Theme {
    Capting: TranslatedString;
    Name: string;
    SID: string;
    IsDefault: boolean;
    IsDarkTheme: boolean;
    ControlStyle: ControlStyle;
    @Type(() => Color)
    Primary: Color;
    PrimaryGradient:string;
    @Type(() => Color)
    Accent: Color;
    @Type(() => Color)
    Warn: Color;
    @Type(() => Color)
    BorderColor: any = new Color();
    @Type(() => LayoutUnit)
    BorderThickness: any = new LayoutUnit();
    @Type(() => LayoutUnit)
    BorderRadiusBottomLeft: any = new LayoutUnit();
    @Type(() => LayoutUnit)
    BorderRadiusBottomRight: any = new LayoutUnit();
    @Type(() => LayoutUnit)
    BorderRadiusTopLeft: any = new LayoutUnit();
    @Type(() => LayoutUnit)
    BorderRadiusTopRight: any = new LayoutUnit();

    @Type(() => Color)
    BorderFocusColor: Color;
    @Type(() => Color)
    ToolTipBackground: Color;
    @Type(() => Color)
    ToolTipColor: Color;
    @Type(() => Color)
    BorderHoverColor: Color;
    @Type(() => Color)
    ShadowColor: Color;
    @Type(() => Color)
    Background: Color;
    @Type(() => Color)
    Foreground: Color;
    @Type(() => Color)
    Hover: Color;
    @Type(() => Color)
    HighlightHover: Color;
    @Type(() => Color)
    DataTableHeaderBackground: Color;
    @Type(() => Color)
    DataTableHeaderForeground: Color;
    @Type(() => Color)
    DataTableCellText: Color;
    @Type(() => Color)
    DataTableCellTextHover: Color;
    @Type(() => Color)
    DataTableCellBackgroundHover: Color;
    @Type(() => Color)
    DataTableLine: Color;
    @Type(() => Color)
    SpreadSheetHeaderBackground: Color;
    @Type(() => Color)
    SpreadSheetHeaderForeground: Color;
    @Type(() => Color)
    SpreadSheetCellText: Color;
    @Type(() => Color)
    SpreadSheetCellTextHover: Color;
    @Type(() => Color)
    SpreadSheetCellBackgroundHover: Color;
    @Type(() => Color)
    SpreadSheetLine: Color;
   
    @Type(() => Color)
    DarkAccentText: Color;
    @Type(() => Color)
    LightAccentText: Color;
     
    @Type(() => Color)
    DarkWarnText: Color;
    @Type(() => Color)
    LightWarnText: Color;
    @Type(() => Color)
    BackgroundStatusBar: Color;
    @Type(() => Color)
    BackgroundAppBar: Color;
    @Type(() => Color)
    BackgroundBackground: Color;
    @Type(() => Color)
    DarkmodeBackground: Color;
    @Type(() => Color)
    PaperColor: Color;
    @Type(() => Color)
    DarkmodePaperColor: Color;
    @Type(() => Color)
    BackgroundHover: Color;
    @Type(() => Color)
    BackgroundCard: Color;
    @Type(() => Color)
    BackgroundDialog: Color;
    @Type(() => Color)
    BackgroundDisabledButton: Color;
    @Type(() => Color)
    BackgroundRaisedButton: Color;
    @Type(() => Color)
    BackgroundFocusedButton: Color;
    @Type(() => Color)
    BackgroundSelectedButton: Color;
    @Type(() => Color)
    BackgroundSelectedDisabledButton: Color;
    @Type(() => Color)
    BackgroundDisabledButtonToggle: Color;
    @Type(() => Color)
    BackgroundUnselectedChip: Color;
    @Type(() => Color)
    BackgroundDisabledListOption: Color;
    @Type(() => Color)
    ForegroundBase: Color;
    @Type(() => Color)
    ForegroundDivider: Color;
    @Type(() => Color)
    ForegroundDividers: Color;
    @Type(() => Color)
    ForegroundDisabled: Color;
    @Type(() => Color)
    ForegroundDisabledButton: Color;
    @Type(() => Color)
    ForegroundDisabledText: Color;
    @Type(() => Color)
    ForegroundElevation: Color;
    @Type(() => Color)
    ForegroundHintText: Color;
    @Type(() => Color)
    ForegroundSecondaryText: Color;
    @Type(() => Color)
    ForegroundIcon: Color;
    @Type(() => Color)
    ForegroundIcons: Color;
    @Type(() => Color)
    ForegroundText: Color;
    @Type(() => Color)
    DarkmodeForegroundText: Color;
    @Type(() => Color)
    ForegroundTextHover: Color;
    @Type(() => Color)
    ForegroundSliderMin: Color;
    @Type(() => Color)
    ForegroundSliderOff: Color;
    @Type(() => Color)
    ForegroundSliderOffActive: Color;
    @Type(() => Font)
    Font: Font;

    ChartPalette: string;
    
    static GenerateFallback():Theme {
        let retVal = new Theme();
        retVal.IsDarkTheme = false;
        retVal.Primary = Color.FromHex('#739AF5');
        retVal.Accent = Color.FromHex('#97bf0d');
        retVal.Warn = Color.FromHex('#F6C050');
        retVal.DataTableLine = Color.FromHex('#a9a9a9');
        retVal.SpreadSheetLine = Color.FromHex('#b9b9b9');

        return retVal;
    }

        // string Primary50 : Color;
        // string Primary100: Color;
        // string Primary200: Color;
        // string Primary300: Color;
        // string Primary400 : Color;
        // string Primary500: Color;
        // string Primary600: Color;
        // string Primary700: Color;
        // string Primary800: Color;
        // string Primary900: Color;
        // string PrimaryA100: Color;
        // string PrimaryA200: Color;
        // string PrimaryA400: Color;
        // string PrimaryA700: Color;
        // string PrimaryContrast50: Color;
        // string PrimaryContrast100: Color;
        // string PrimaryContrast200: Color;
        // string PrimaryContrast300: Color;
        // string PrimaryContrast400: Color;
        // string PrimaryContrast500: Color;
        // string PrimaryContrast600: Color;
        // string PrimaryContrast700: Color;
        // string PrimaryContrast800: Color;
        // string PrimaryContrast900: Color;
        // string PrimaryContrastA100: Color;
        // string PrimaryContrastA200: Color;
        // string PrimaryContrastA400: Color;
        // string PrimaryContrastA700: Color;
        // string Accent50: Color;
        // string Accent100: Color;
        // string Accent200: Color;
        // string Accent300: Color;
        // string Accent400: Color;
        // string Accent500: Color;
        // string Accent600: Color;
        // string Accent700: Color;
        // string Accent800: Color;
        // string Accent900: Color;
        // string AccentA100: Color;
        // string AccentA200: Color;
        // string AccentA400: Color;
        // string AccentA700: Color;
        // string Warn50: Color;
        // string Warn100: Color;
        // string Warn200: Color;
        // string Warn300: Color;
        // string Warn400: Color;
        // string Warn500: Color;
        // string Warn600: Color;
        // string Warn700: Color;
        // string Warn800: Color;
        // string Warn900: Color;
        // string WarnA100: Color;
        // string WarnA200: Color;
        // string WarnA400: Color;
        // string WarnA700: Color;

}
