import { Component } from '@angular/core';
import { EventActionType } from '../../../models/enums/eventactiontype.enum';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';

@Component({
    selector: 'workflow-usage-dialog',
    templateUrl: './workflow.usage.dialog.html'
})
export class WorkflowUsageDialog {
    WorkflowID;
    DirectUsage = [];
    LinkedUsage = [];

    private static FindEvents(layoutElem, wfID, directList, linkedList) {
        if (layoutElem) {
            if (layoutElem.Events) {
                layoutElem.Events.forEach(ev => {
                    if (ev.Handlers) {
                        ev.Handlers.forEach(handler => {
                            if (handler.ActionType === EventActionType.Workflow) {
                                if (handler.Action === wfID) {
                                    directList.push({
                                        Element: layoutElem.Name,
                                        Event: ev.EventID
                                    });
                                } else {
                                    linkedList.forEach(linked => {
                                        if (linked.EntryID === handler.Action) {
                                            linked.Handlers.push({
                                                Element: layoutElem.Name,
                                                Event: ev.EventID
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
            if (layoutElem.Elements) {
                layoutElem.Elements.forEach(elem => {
                    WorkflowUsageDialog.FindEvents(elem, wfID, directList, linkedList);
                });
            }
        }
    }

    static ShowDialog(layout, workflow) {
        BaseDialog.ShowDialog({
            ContentType: WorkflowUsageDialog,
            InitArgs: {
                Layout: layout,
                Workflow: workflow
            },
            Title: workflow.Caption
        });
    }

    Initialize(args) {
        if (args && args.Layout && args.Workflow) {
            this.WorkflowID = args.Workflow.ID;
            if (args.Layout.Workflows) {
                const linkList = [];
                const captions = {};
                args.Layout.Workflows.forEach(wf => {
                    if (wf.Data && wf.Data.Modules) {
                        captions[wf.ID] = wf.Caption;
                        wf.Data.Modules.forEach(mod => {
                            if (mod.Module === 'execWFModule' && mod.Settings && mod.Settings.IsServiceWorkflow === false) {
                                linkList.push([wf.ID, mod.Settings.WorkflowID]);
                            }
                        });
                    }
                });
                for (let i = 0; i < linkList.length; i++) {
                    const actLink = linkList[i];
                    const lastLink = actLink[actLink.length - 1];
                    for (let j = 0; j < linkList.length; j++) {
                        if (i !== j) {
                            const innerLink = linkList[j];
                            if (innerLink[0] === lastLink) {
                                const newEntry = [...actLink, innerLink.slice(1)];
                                linkList.push(newEntry);
                            }
                        }
                    }
                }
                linkList.forEach(x => {
                    const entryID = x[0];
                    let add = entryID === args.Workflow.ID;
                    const entry = {
                        EntryID: entryID,
                        EntryCaption: captions[entryID],
                        Chain: [],
                        Handlers: []
                    };
                    for (let i = 1; i < x.length; i++) {
                        const nextID = x[i];
                        entry.Chain.push({
                            ID: nextID,
                            Caption: captions[nextID]
                        });
                        if (nextID === args.Workflow.ID) {
                            add = true;
                        }
                    }
                    if (add) {
                        this.LinkedUsage.push(entry);
                    }
                });
            }
            WorkflowUsageDialog.FindEvents(args.Layout, args.Workflow.ID, this.DirectUsage, this.LinkedUsage);
        }
    }
}
