import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { CSSVarType } from '../../../models/enums/cssvartype.enum';
import { Color } from '../../../models/style/color.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'customcssvars-settings',
    templateUrl: '../../base.list.settings.html'
})
export class CustomCSSVarsSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;
    Fonts = [];
    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return CustomCSSVarsDetail;
    }

    loadList(handler) {
        this.service.GetCustomCSSVariables(null).subscribe((result) => {
            const list = [];
            result.forEach((entry) => {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
    }

    loadData(data) {
        this.service.GetCustomCSSVariable(data).subscribe((result) => {
            if (result) {
                this.setSelectedItem(result);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@Neue CSSVariable')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die CSSVariable \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@CSSVariable \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@CSSVariable loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@CSSVariable \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@CSSVariable speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteCustomCSSVariable(data).subscribe((result) => {
            handler(result);
        });
    }

    saveInternal(item, handler) {
        if (item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }

        if (typeof item.VariableName === 'string' && !item.VariableName.startsWith('--')) {
            item.VariableName = '--' + item.VariableName;
        }
        this.service.SaveCustomCSSVariable(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@CSSVariable',
            ID: 'cssvar',
            Icon: 'style',
            Index: 5,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 16
            },
            Content: CustomCSSVarsSettings
        };
    }
}

@Component({
    selector: 'customcssvars-detail',
    templateUrl: './customcssvars.settings.html',
    styleUrls: ['./customcssvars.settings.css']
})
export class CustomCSSVarsDetail extends BaseListDetail {
    Types;
    fontSizes = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
    FillStyle = {};
    Fonts = [];
    SelectedColor;
    
    constructor(private mediaService:MediaService) {
        super();
        this.Types = EnumHelper.GetDropdownValues(CSSVarType);
        LayoutService.Loading.next(true);
        this.mediaService.ListObjects(MediaService.FontSourceID, '').subscribe(Items => {
            if (Items) {
                const list = [];
                Items.forEach(font => {
                    list.push({
                        Name: font.Name.replace('.ttf', '')
                    });
                });
                this.Fonts = list;
                LayoutService.Loading.next(false);
            }
        });
    }

    ColorChanged(value) {
        this.SelectedItem['Value'] = Color.HexFromColor(value);
        this.updateFillStyle();
        this.OnItemChanged();
    }

    updateFillStyle() {
        const style = {};
        if (this.SelectedItem && this.SelectedItem.Value) {
            style['fill'] = this.SelectedItem.Value;
        }
        this.FillStyle = style;
    }

    menuKeyDown(ev) {
        MenuKeyDownHelper.onKeyDown(ev);
    }

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (this.SelectedItem) {
            this.SelectedColor = Color.FromHex(this.SelectedItem.Value);
        }
        this.updateFillStyle();
    }
}
