import { BaseWorkerControl } from "../base.worker.control";
import { Component } from "@angular/core";

@Component({
    selector: 'capsule-import-worker-control',
    templateUrl: './capsule.import.worker.control.html',
    styleUrls: ['./capsule.import.worker.control.css']
})
export class CapsuleImportWorkerControl extends BaseWorkerControl {
    Settings;

    OnTriggerSet() {
        if (this.TriggerValue && this.TriggerValue.ExecutionContext) {
            this.Settings = JSON.parse(this.TriggerValue.ExecutionContext);
        }
        if (!this.Settings) {
            this.Settings = {
                Type: 0,
                Url: null,
                Data: null
            };
        }
        if (this.TriggerValue) {
            this.TriggerValue.ExecutionContextAsObject = this.Settings;
        }
    }

    Upload(ev) {
        var settings = this.Settings;
        if (settings && ev && ev.target) {
            let selectedFile = ev.target.files[0];
            if (selectedFile) {
                var fr = new FileReader();
                var that = this;
                fr.addEventListener('load', function () {
                    let data = fr.result.toString();
                    let index = data.indexOf('base64,');
                    if (index >= 0) {
                        settings.Data = data.substring(index + 7);
                        settings.Url = selectedFile.name;
                        that.OnTriggerHasChanged();
                    }
                }, false);
                fr.readAsDataURL(selectedFile);
            }
        }
    }    
}