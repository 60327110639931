import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-content',
    templateUrl: './content.control.html',
    styleUrls: ['./content.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentControl extends IBaseComponent {
    static Type: any = 'content';
    static Default = { Type: 'content' };

    LayoutEditDailog = false;
    SelectedPage = 'content';
    ControlInitialized() {
        this.cdRef.detectChanges();
    }
}
