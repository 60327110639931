import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslatedString } from '../../../models/translatedstring.model';
import { ContainerEnumService, DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataSourcePreviewPanel } from '../../../workflow/modules/dataoperations/datasource/datasource.settings';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';

@Component({
    selector: 'containerview-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerEnumTableSettings extends BaseListSettings {

    public static GetSettingsEntry() {
        return {
            Caption: '@@ContainerEnumTables',
            ID: 'containerenumtables',
            Icon: 'table_chart',
            Index: 50,
            Parent: 'relational',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 64
            },
            Content: ContainerEnumTableSettings
        };
    }

    constructor(private service: ContainerEnumService, private translate: TranslateService, private dataModelService: DataModelService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerEnumTableDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Container.ContainerEnumTable').subscribe((result) => {
                    if (result) {
                        const list = [];
                        result.forEach((item) => {
                            list.push({
                                Caption: item.Caption,
                                ID: item.SID,
                                IsCapsule: item.IsCapsule,
                                IsOverridden: item.IsOverridden
                            });
                        });
                        handler(list);
                    }
                });
            if (comp) {
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[4096]]; // RelationalRead
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                    if (sources) {
                        comp.DataSources = sources;
                    }
                });
                this.service.GetEnumsByDataModel(this.InitArgs.SID).subscribe(result => {
                    if (result) {
                        comp.Enums = result;
                    }
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((view) => {
                if (view) {
                    this.setSelectedItem(view);
                }
            });
        }
    }

    getNewItem() {
        return {
            DataModelID: this.InitArgs.SID,
            Name: this.translate.instant('@@Neue ContainerEnumTable')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete the ContainerEnumTable \'{0}\' ?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@ContainerEnumTable \'{0}\' successfully deleted');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@ContainerEnumTable delete');
        return retVal;
    }

    delete(data, handler) {
        this.dataModelService.DeleteContainer(data).subscribe(res => {
            handler(res)
        });
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@ContainerEnumTable \'{0}\' saved successfully');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@ContainerEnumTable save');
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            this.dataModelService.SaveContainer('evidanza.MiddleWare.Shared.Container.ContainerEnumTable', item).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerenumtable-detail',
    templateUrl: './container.enum.table.settings.html',
    styleUrls: ['./container.enum.table.settings.css']
})
export class ContainerEnumTableDetail extends ADataModelDetail {
    DataSources = [];
    Enums = [];
    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            DataSourcePreviewPanel.ShowPreviewForContainer(this.SelectedItem.SID, '@@Vorschau');
        }
    }
}
