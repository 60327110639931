import { BaseDataSourcePanel } from "../basedatasource.panel";
import { PGDBInfo, DBPanelInfo } from "../../../../../models/datamodel/adbinfo.model";
import { Component } from "@angular/core";

@Component({
    selector: 'postgredb-panel',
    templateUrl: './postgredb.panel.html',
    styleUrls: ['./postgredb.panel.css']
})
export class PostgreSQLPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = PGDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: PostgreSQLPanel,
            DBInfoType: PGDBInfo
        };
    }
}