import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Alignment } from '../../../../models/enums/alignment.enum';


@Component({
  selector: 'horizontalalignment-action',
  templateUrl: './horizontalalignment.action.html',
  styleUrls: ['./horizontalalignment.action.css'],
})
export class HorizontalAlignmentAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'horizontalalignment';

    //#region HorizontalAlignment
    @Input()
    get HorizontalAlignment() {
        if (this.SelectedItemValue) {
            return this.SelectedItemValue.HorizontalContentAlignment;
        }
        return Alignment.Start;
    }
    set HorizontalAlignment(val) {
        if (this.SelectedItemValue) {
            this.SelectedItemValue.HorizontalContentAlignment = val;
        }
        this.HorizontalAlignmentChange.emit(val);
    }

    @Output() HorizontalAlignmentChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {

    }
    setLeft() {
        this.HorizontalAlignment = Alignment.Start;
    }

    setCenterHor() {
        this.HorizontalAlignment = Alignment.Center;
    }

    setRight() {
        this.HorizontalAlignment = Alignment.End;
    }


    ExecuteAction() {
        
    }

    Visibility() {
        return true;
    }
}
