import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { MultiLayoutWorkflowChangeValue } from '../../../models/layout/layout.change.model';
import { LayoutService } from '../../../services/layout.service';
import { WF_REGISTRY } from '../../../services/workflow.service';
import { BaseDialog, DialogData } from '../basedialog/base.dialog';

export class WorkflowChangeDialogInitArgs {
    Info: string;
    WorkflowList: string[] = [];
    Question: string;
    ShowAbort: boolean;
}

@Component({
    selector: 'workflow-name-change-dialog',
    templateUrl: './workflow.name.change.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowNameChangeDialog {
    InitArgs: WorkflowChangeDialogInitArgs;
    CloseOnCustomButton = true;
    CustomButtons = [
        { Caption: '@@Ja', ID: MessageBoxResult.Yes },
        { Caption: '@@Nein', ID: MessageBoxResult.No }
    ];
    Result = MessageBoxResult.Abort;

    static CheckWorkflowChange(layout, oldName, newName): Promise<MessageBoxResult> {
        const retVal = new Promise<MessageBoxResult>((resolve, reject) => {
            if (layout && layout.Workflows && oldName !== newName) {
                const changedWorkflows = [];
                layout.Workflows.forEach(wf => {
                    if (wf.Data && wf.Data.Modules) {
                        const changedModules = [];
                        wf.Data.Modules.forEach(mod => {
                            if (mod.Module && mod.Settings) {
                                const desc = WF_REGISTRY.get(mod.Module);
                                if (desc && desc.SettingsTypeHelper) {
                                    const copy = JSON.parse(JSON.stringify(mod.Settings));
                                    const changed = desc.SettingsTypeHelper.changeSettingsOnElementNameChange(copy, oldName, newName);
                                    if (changed) {
                                        changedModules.push({
                                            Module: mod,
                                            ChangedSettings: copy
                                        });
                                    }
                                }
                            }
                        });
                        if (changedModules.length > 0) {
                            changedWorkflows.push({
                                Workflow: wf,
                                ChangedModules: changedModules
                            });
                        }
                    }
                });
                if (changedWorkflows.length > 0) {
                    const initArgs = new WorkflowChangeDialogInitArgs();
                    initArgs.Info = '@@Folgende Workflows sind von der Namensaenderung betroffen:';
                    initArgs.Question = '@@Workflows abaendern?';
                    changedWorkflows.forEach(x => {
                        initArgs.WorkflowList.push(x.Workflow.Caption);
                    });
                    initArgs.ShowAbort = true;
                    const args: DialogData = {
                        ContentType: WorkflowNameChangeDialog,
                        InitArgs: initArgs,
                        Buttons: DialogButton.None,
                        Handler: (r) => {
                            if (r === MessageBoxResult.Yes) {
                                const mlwcv = new MultiLayoutWorkflowChangeValue();
                                mlwcv.LayoutID = layout.ID;
                                changedWorkflows.forEach(wf => {
                                    wf.ChangedModules.forEach(mod => {
                                        if (mod.ChangedSettings) {
                                            mod.Module.Settings = mod.ChangedSettings;
                                        }
                                    });
                                    mlwcv.Workflows.push(JSON.stringify(wf.Workflow));
                                });
                                LayoutService.OnLayoutWorkflowsChanged(mlwcv);
                            }
                            resolve(r);
                            return true;
                        },
                        Title: '@@Workflows abaendern?'
                    };
                    BaseDialog.ShowDialog(args);
                    return;
                }
            }
            resolve(MessageBoxResult.Yes);
        });
        return retVal;
    }

    private static ReadLayoutStructure(layoutElement, parents, structure, templatePath) {
        const elemName = templatePath + layoutElement.Name;
        const structEntry = {
            Parents: parents,
            Variables: []
        };
        structure[elemName] = structEntry;
        if (layoutElement.Variables) {
            layoutElement.Variables.forEach(v => {
                structEntry.Variables.push(v.Name);
            });
        }
        if (layoutElement.Elements) {
            const newParents = [...parents, elemName];
            if (layoutElement.ElementType === 'template') {
                templatePath += layoutElement.Name + '.';
            }
            layoutElement.Elements.forEach(child => {
                WorkflowNameChangeDialog.ReadLayoutStructure(child, newParents, structure, templatePath);
            });
        }
    }

    private static FindParentVariable(layoutStructure, elemName, parentName, oldName): boolean {
        if (!layoutStructure.Structure) {
            layoutStructure.Structure = {};
            WorkflowNameChangeDialog.ReadLayoutStructure(layoutStructure.Layout, [], layoutStructure.Structure, '');
        }
        const elemStruct = layoutStructure.Structure[elemName];
        return elemStruct && elemStruct.Parents.some(x => {
            if (x === parentName) {
                const parentStuct = layoutStructure.Structure[x];
                return parentStuct && parentStuct.Variables.some(v => v === oldName);
            }
            return false;
        });
    }

    private static GetVariableChangeWorkflows(layout, elemName, oldName, newName) {
        const changedWorkflows = [];
        const lS = {
            Layout: layout
        };
        layout.Workflows.forEach(wf => {
            if (wf.Data && wf.Data.Modules) {
                const changedModules = [];
                wf.Data.Modules.forEach(mod => {
                    if (mod.Settings) {
                        if (mod.Module === 'readLayoutVariableModule') {
                            if (mod.Settings.Variable && mod.Settings.Variable.Name === oldName) {
                                if (mod.Settings.ElemName === elemName ||
                                    WorkflowNameChangeDialog.FindParentVariable(lS, mod.Settings.ElemName, elemName, oldName)) {
                                    const copy = JSON.parse(JSON.stringify(mod.Settings));
                                    copy.Variable.Name = newName;
                                    changedModules.push({
                                        Module: mod,
                                        ChangedSettings: copy
                                    });
                                }
                            }
                        } else if (mod.Module === 'readMultiLayoutVariableModule') {
                            if (mod.Settings.Variables && mod.Settings.Variables.length > 0) {
                                const copy = JSON.parse(JSON.stringify(mod.Settings));
                                let change = false;
                                copy.Variables.forEach(v => {
                                    if (v.Variable === oldName) {
                                        if (v.ElemName === elemName ||
                                            WorkflowNameChangeDialog.FindParentVariable(lS, v.ElemName, elemName, oldName)) {
                                            v.Variable = newName;
                                            change = true;
                                        }
                                    }
                                });
                                if (change) {
                                    changedModules.push({
                                        Module: mod,
                                        ChangedSettings: copy
                                    });
                                }
                            }
                        } else if (mod.Module === 'setLayoutVariableModule') {
                            if (mod.Settings.Variables && mod.Settings.Variables.length > 0) {
                                const copy = JSON.parse(JSON.stringify(mod.Settings));
                                let change = false;
                                let nameCorrect = false;
                                copy.Variables.some(v => {
                                    if (v.Name === oldName) {
                                        if (!nameCorrect) {
                                            nameCorrect = copy.ElemName === elemName ||
                                                WorkflowNameChangeDialog.FindParentVariable(lS, copy.ElemName, elemName, oldName);
                                            if (!nameCorrect) {
                                                return true;
                                            }
                                        }
                                        v.Name = newName;
                                        change = true;
                                    }
                                    return false;
                                });
                                if (change) {
                                    changedModules.push({
                                        Module: mod,
                                        ChangedSettings: copy
                                    });
                                }
                            }
                        } else if (mod.Module === 'setMultiLayoutVariableModule') {
                            if (mod.Settings.Elements && mod.Settings.Elements.length > 0) {
                                const copy = JSON.parse(JSON.stringify(mod.Settings));
                                let change = false;
                                copy.Elements.forEach(x => {
                                    let nameCorrect = false;
                                    x.Variables.some(v => {
                                        if (v.Name === oldName) {
                                            if (!nameCorrect) {
                                                nameCorrect = x.ElemName === elemName ||
                                                    WorkflowNameChangeDialog.FindParentVariable(lS, x.ElemName, elemName, oldName);
                                                if (!nameCorrect) {
                                                    return true;
                                                }
                                            }
                                            v.Name = newName;
                                            change = true;
                                        }
                                        return false;
                                    });
                                });
                                if (change) {
                                    changedModules.push({
                                        Module: mod,
                                        ChangedSettings: copy
                                    });
                                }
                            }
                        }
                    }
                });
                if (changedModules.length > 0) {
                    changedWorkflows.push({
                        Workflow: wf,
                        ChangedModules: changedModules
                    });
                }
            }
        });
        return changedWorkflows;
    }

    static CheckWorkflowChangeVariable(layout, elemName, oldName, newName): Promise<MessageBoxResult> {
        const retVal = new Promise<MessageBoxResult>((resolve, reject) => {
            if (layout && layout.Workflows && oldName !== newName) {
                const changedWorkflows = WorkflowNameChangeDialog.GetVariableChangeWorkflows(layout, elemName, oldName, newName);
                if (changedWorkflows.length > 0) {
                    const initArgs = new WorkflowChangeDialogInitArgs();
                    initArgs.Info = '@@Folgende Workflows sind von der Namensaenderung betroffen:';
                    initArgs.Question = '@@Workflows abaendern?';
                    changedWorkflows.forEach(x => {
                        initArgs.WorkflowList.push(x.Workflow.Caption);
                    });
                    initArgs.ShowAbort = true;
                    const args: DialogData = {
                        ContentType: WorkflowNameChangeDialog,
                        InitArgs: initArgs,
                        Buttons: DialogButton.None,
                        Handler: (r) => {
                            if (r === MessageBoxResult.Yes) {
                                const mlwcv = new MultiLayoutWorkflowChangeValue();
                                mlwcv.LayoutID = layout.ID;
                                changedWorkflows.forEach(wf => {
                                    wf.ChangedModules.forEach(mod => {
                                        if (mod.ChangedSettings) {
                                            mod.Module.Settings = mod.ChangedSettings;
                                        }
                                    });
                                    mlwcv.Workflows.push(JSON.stringify(wf.Workflow));
                                });
                                LayoutService.OnLayoutWorkflowsChanged(mlwcv);
                            }
                            resolve(r);
                            return true;
                        },
                        Title: '@@Workflows abaendern?'
                    };
                    BaseDialog.ShowDialog(args);
                    return;
                }
            }
            resolve(MessageBoxResult.Yes);
        });
        return retVal;
    }

    static CheckVariableDelete(layout, elemName, variableName): Promise<MessageBoxResult> {
        const retVal = new Promise<MessageBoxResult>((resolve, reject) => {
            if (layout && layout.Workflows) {
                const cw = WorkflowNameChangeDialog.GetVariableChangeWorkflows(layout, elemName, variableName, variableName);
                if (cw.length > 0) {
                    const initArgs = new WorkflowChangeDialogInitArgs();
                    initArgs.Info = '@@Folgende Workflows sind vom Loeschen der Variable betroffen:';
                    initArgs.Question = '@@Variable trotzdem loeschen?';
                    cw.forEach(x => {
                        initArgs.WorkflowList.push(x.Workflow.Caption);
                    });
                    initArgs.ShowAbort = false;
                    const args: DialogData = {
                        ContentType: WorkflowNameChangeDialog,
                        InitArgs: initArgs,
                        Buttons: DialogButton.None,
                        Handler: (r) => {
                            resolve(r);
                            return true;
                        },
                        Title: '@@Loeschen'
                    };
                    BaseDialog.ShowDialog(args);
                    return;
                }
            }
            resolve(MessageBoxResult.Yes);
        });
        return retVal;
    }

    static CheckObjectDelete(layout, objectNames): Promise<MessageBoxResult> {
        const retVal = new Promise<MessageBoxResult>((resolve, reject) => {
            if (layout && layout.Workflows) {
                const changedWorkflows = [];
                layout.Workflows.forEach(wf => {
                    if (wf.Data && wf.Data.Modules &&
                        wf.Data.Modules.some(mod => {
                            if (mod.Module && mod.Settings) {
                                const desc = WF_REGISTRY.get(mod.Module);
                                if (desc && desc.SettingsTypeHelper) {
                                    return objectNames.some(name =>
                                        desc.SettingsTypeHelper.changeSettingsOnElementNameChange(mod.Settings, name, name));
                                }
                            }
                            return false;
                        })) {
                        changedWorkflows.push(wf.Caption);
                    }
                });
                if (changedWorkflows.length > 0) {
                    const initArgs = new WorkflowChangeDialogInitArgs();
                    initArgs.Info = '@@Folgende Workflows sind vom Loeschen des Elements betroffen:';
                    initArgs.Question = '@@Element trotzdem loeschen?';
                    initArgs.WorkflowList = changedWorkflows;
                    initArgs.ShowAbort = false;
                    const args: DialogData = {
                        ContentType: WorkflowNameChangeDialog,
                        InitArgs: initArgs,
                        Buttons: DialogButton.None,
                        Handler: (r) => {
                            resolve(r);
                            return true;
                        },
                        Title: '@@Loeschen'
                    };
                    BaseDialog.ShowDialog(args);
                    return;
                }
            }
            resolve(MessageBoxResult.Yes);
        });
        return retVal;
    }

    Initialize(args) {
        this.InitArgs = args;
        if (this.InitArgs && this.InitArgs.ShowAbort === true) {
            this.CustomButtons.push({ Caption: '@@Abbrechen', ID: MessageBoxResult.Abort });
        }
    }

    GetDialogResult() {
        return this.Result;
    }

    OnCustomButtonClicked(button) {
        if (button) {
            this.Result = button.ID;
        }
    }
}
