import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';

@Component({
    selector: 'getWFStatus-settings',
    templateUrl: './getWFStatus.settings.html',
    styleUrls: ['./getWFStatus.settings.css']
})
export class GetWorkflowStatusSettings extends FormulaWorkflowDialogContent {
    ActualStatusKey = '';
    VariableNodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getStatusWFModule';
        reg.Caption = '@@Statuswert auslesen';
        reg.GroupID = 'wfStatus';
        reg.Index = 20;
        reg.SettingsControl = GetWorkflowStatusSettings;
        reg.SettingsTypeHelper = new GetWorkflowStatusDataHelper();
        reg.Executer = GetWorkflowStatusExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data && data.StatusKey) {
            this.ActualStatusKey = data.StatusKey;
        }
    }

    getResult(): any {
        const retVal = new GetWorkflowStatusData();
        retVal.StatusKey = this.ActualStatusKey;
        return retVal;
    }

    onVariableSelect(event) {
        if (event) {
            this.ActualStatusKey = event.Caption;
        }
    }
}

export class GetWorkflowStatusData extends AServiceWorkflowData {
    StatusKey = '';
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.WFStatus.GetWorkflowStatusData';
    }
}

export class GetWorkflowStatusDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new GetWorkflowStatusData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.StatusKey) {
            return settings.StatusKey;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class GetWorkflowStatusExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.StatusKey) {
                status.ActualParameter = status.Context.get(status.ActualSettings.StatusKey);
                return 0;
            } else {
                status.Logger.logError('GetStatus modul: No status key found.');
            }
        } else {
            status.Logger.logError('GetStatus modul: No settings found.');
        }
        return super.execute(status);
    }
}
