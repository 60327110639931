import { AxisNode } from '../datadescription/multi/multiresult.model';

export class MultidimensionalChartKeys {
    static AxisKey = 'xAxis';
    static GroupingKey = 'grouping';
    static BubbleStyle = 'BubbleStyle_';
}

export class BubbleRow {
    Caption: string;
    XCoord: BubbleRowData;
    YCoord: BubbleRowData;
    Radius: BubbleRowData;
    MeasureAxisNode: AxisNode;
    OppositeAxisNode: AxisNode;
    GroupingKey: string;
    GroupingValue: string;
    StyleID = 0;

    toRow() {
        const row = {};
        row[MultidimensionalChartKeys.AxisKey] = this.Caption;
        row[this.XCoord.Caption] = this.XCoord.Value;
        row[this.YCoord.Caption] = this.YCoord.Value;
        row[this.GroupingKey] = this.StyleID > 0 ? MultidimensionalChartKeys.BubbleStyle + this.StyleID : this.GroupingValue;
        row[this.Radius.Caption] = this.Radius.Value;
        return row;
    }
}

export class BubbleRowData {
    UniqueID: number;
    Caption: string;
    Value: any;
}
