import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { LayoutTree } from '../../../../components/controls/layout.tree';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { SetMultiStyleSettings } from '../setStyle/set.multi.style.settings';

@Component({
    selector: 'elemProperty-settings',
    templateUrl: './elemProperty.settings.html',
    styleUrls: ['./elemProperty.settings.css']
})
export class ElementPropertySettings extends WorkflowDialogContent implements AfterViewInit {
    ElemName = '';
    PropName: string;
    UseWorkflowValue = false;
    PropValue;

    ElemProps = [];
    PropType: string;
    TypeValues;

    @ViewChild('lTree') Tree: LayoutTree;

    //#region SelectedNode
    SelectedNodeValue;

    @Input()
    get SelectedNode() {
        return this.SelectedNodeValue;
    }
    set SelectedNode(val) {
        if (val && val !== this.SelectedNodeValue) {
            this.SelectedNodeValue = val;
            this.updateLayout();
            this.SelectedNodeChange.emit(this.SelectedNodeValue);
        }
    }

    @Output() SelectedNodeChange = new EventEmitter<any>();
    //#endregion

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'elemPropertyModule';
        reg.Caption = '@@Eigenschaft setzen';
        reg.SettingsControl = ElementPropertySettings;
        reg.SettingsTypeHelper = new ElementPropertySettingsDataHelper();
        reg.Executer = ElementPropertyModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetMultiStyleSettings.ModuleID;
        return reg;
    }

    constructor(private cdRef: ChangeDetectorRef) {
        super();
        this.HasExpressions = true;
        this.TypeValues = [
            { Caption: '@@Text', Value: 'string' },
            { Caption: '@@Bool', Value: 'boolean' },
            { Caption: '@@Zahl', Value: 'number' }
        ];
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }, {
            Value: 'PropName'
        }];
    }

    ngAfterViewInit(): void {
        if (this.Tree) {
            if (this.Tree.Initialized) {
                this.SelectedNode = this.Tree.getNodeByElemName(this.ElemName);
                this.cdRef.detectChanges();
            } else {
                this.Tree.Initialized.subscribe(() => {
                    this.SelectedNode = this.Tree.getNodeByElemName(this.ElemName);
                    this.cdRef.detectChanges();
                });
            }
        }
    }

    initialize(data: any) {
        if (data) {
            if (data.ElemName) {
                this.ElemName = data.ElemName;
                this.PropName = data.PropName;
                this.UseWorkflowValue = data.UseWorkflowValue;
                this.PropValue = data.PropValue;
                if (this.WFEditOptions && this.WFEditOptions.IsTemplate && this.PropValue) {
                    this.PropType = typeof this.PropValue;
                }
            }
        }
    }

    getResult(): any {
        const retVal = new ElementPropertySettingsData();
        retVal.ElemName = this.ElemName;
        retVal.PropName = this.PropName;
        retVal.UseWorkflowValue = this.UseWorkflowValue;
        retVal.PropValue = this.PropValue;
        return retVal;
    }

    updateLayout() {
        if (this.Tree && this.Tree.IsInitialized) {
            let props = [];
            let elemName = '';
            if (this.SelectedNodeValue) {
                const data = this.SelectedNodeValue.data;
                if (data) {
                    elemName = data.Name;
                    if (data.Element && data.Element.PropertyList) {
                        props = data.Element.PropertyList;
                    }
                }
                if (this.SelectedNodeValue.longName) {
                    elemName = this.SelectedNodeValue.longName;
                }
            }
            this.ElemName = elemName;
            this.ElemProps = props;
            this.propNameChanged(this.PropName);
        }
    }

    propNameChanged(ev) {
        let propName = '';
        let propType = '';
        if (this.ElemProps) {
            this.ElemProps.some(function (prop) {
                if (prop.PropertyName === ev) {
                    propName = ev;
                    propType = prop.DataType;
                    return true;
                }
                return false;
            });
        }
        this.PropName = propName;
        this.PropType = propType;
    }
}

export class ElementPropertySettingsData {
    ElemName: string;
    PropName: string;
    UseWorkflowValue: boolean;
    PropValue;
}

export class ElementPropertySettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ElementPropertySettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class ElementPropertyModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    elem[status.ActualSettings.PropName] = status.ActualSettings.UseWorkflowValue ?
                        status.ActualParameter : status.ActualSettings.PropValue;
                    if (elem.Element && typeof elem.Element.createBaseStyle === 'function') {
                        elem.Element.createBaseStyle();
                    }
                    return 0;
                } else {
                    status.Logger.logError('ElementValue modul: Element not found.');
                }
            } else {
                status.Logger.logError('ElementValue modul: No element name found.');
            }
        } else {
            status.Logger.logError('ElementValue modul: No settings found.');
        }
        return super.execute(status);
    }
}
