import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'bigquery-panel',
    templateUrl: './bigquery.panel.html',
    styleUrls: ['./bigquery.panel.css']
})
export class BigQueryPanel extends BaseDataSourcePanel {

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: BigQueryPanel,
            DBInfoType: BigQueryDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = BigQueryDBInfo;
    }   
}

export class BigQueryDBInfo extends ADBInfo {
    ProjectId: string = null;
    JsonString: string = null;
    getTypeName(): string {
        return 'evidanza.Database.BigQuery.BigQueryDBInfo';
    }
}
