import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RelToObjectColumnMapping } from './reltoobject.data';

@Component({
    selector: 'wf-reltoobject-object-settings',
    templateUrl: './reltoobject.object.settings.html',
    styleUrls: ['./reltoobject.object.settings.css']
})
export class RelToObjectObjectSettings {
    //#region AdHocObject
    AdHocObjectValue = false;
    @Input()
    get AdHocObject() {
        return this.AdHocObjectValue;
    }
    set AdHocObject(val) {
        this.AdHocObjectValue = val;
        this.AdHocObjectChange.emit(val);
    }

    @Output() AdHocObjectChange = new EventEmitter<any>();
    //#endregion

    //#region NoColumns
    NoColumnsValue = false;
    @Input()
    get NoColumns() {
        return this.NoColumnsValue;
    }
    set NoColumns(val) {
        this.NoColumnsValue = val;
        this.NoColumnsChange.emit(val);
    }

    @Output() NoColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region ColumnsValue
    ColumnsValue = [];
    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        this.ColumnsChange.emit(val);
    }

    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region ParentProps
    PropListValue = [];
    @Input()
    get PropList() {
        return this.PropListValue;
    }
    set PropList(val) {
        this.PropListValue = val;
        this.PropListChange.emit(val);
    }

    @Output() PropListChange = new EventEmitter<any>();
    //#endregion

    //#region ParentProps
    ParentPropsValue = [];
    @Input()
    get ParentProps() {
        return this.ParentPropsValue;
    }
    set ParentProps(val) {
        this.ParentPropsValue = val;
        this.ParentPropsChange.emit(val);
    }

    @Output() ParentPropsChange = new EventEmitter<any>();
    //#endregion

    addMapping() {
        if (this.PropListValue) {
            this.PropListValue.push(new RelToObjectColumnMapping());
        }
    }

    deleteMapping(i) {
        if (this.PropListValue) {
            this.PropListValue.splice(i, 1);
        }
    }

    commaSeperatedChanged(ev, index) {
        if (ev) {
            if (!ev.checked) {
                this.PropListValue.forEach((v, i) => {
                    if (i !== index) {
                        v.IsCommaSeparated = true;
                    }
                });
            }
        }
    }
}
