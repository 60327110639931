import cronstrue from 'cronstrue';
import { TranslateHelper } from './injector.helper';

export class CronHelper {

    static GetCronObject(val: string): CronObject {
        let cr = new CronObject();
        if (val && val != '') {
            var splits = val.split(' ');
            cr.Seconds = splits[0];
            cr.SecondsCorrect = CronHelper.IsValidMinOrSecValue(cr.Seconds);
            if (splits.length > 1) {
                cr.Minutes = splits[1];
                cr.MinutesCorrect = CronHelper.IsValidMinOrSecValue(cr.Minutes);
                if (splits.length > 2) {
                    cr.Hours = splits[2];
                    cr.HoursCorrect = CronHelper.IsValidHourValue(cr.Hours);
                    if (splits.length > 3) {
                        cr.DayOfMonth = splits[3];
                        cr.DayOfMonthCorrect = CronHelper.IsValidDOMValue(cr.DayOfMonth);
                        if (splits.length > 4) {
                            cr.Month = splits[4];
                            cr.MonthCorrect = CronHelper.IsValidMonthValue(cr.Month);
                            if (splits.length > 5) {
                                cr.DayOfWeek = splits[5];
                                cr.DayOfWeekCorrect = CronHelper.IsValidDOWValue(cr.DayOfWeek);
                                if (splits.length > 6) {
                                    cr.Year = splits[6];
                                    cr.YearCorrect = CronHelper.IsValidYearValue(cr.Year);
                                }
                            }
                        }
                    }
                }
            }
        }
        return cr;
    }

    static IsValidMinOrSecValue(val: string): boolean {
        return CronHelper.IsValidMinOrSecValueInternal(val, true);
    }

    private static IsValidMinOrSecValueInternal(val: string, checkForStar: boolean): boolean {
        if (val.length > 0) {
            if (val.length > 1) {
                if (val.length > 2) {
                    // Laenge > 2 -> Muss '/', '-' oder ',' enthalten
                    // Erst nach Komma splitten
                    var splits = val.split(',');
                    if (splits.length > 1) {
                        var retVal = true;
                        splits.some(function (s) {
                            if (!CronHelper.IsValidMinOrSecValueInternal(s, false)) {
                                retVal = false;
                            }
                            return !retVal;
                        });
                        return retVal;
                    } else {
                        splits = val.split('/');
                        if (splits.length > 1) {
                            // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 59
                            if (splits.length == 2) {
                                var secondNumber = CronHelper.IsValidMinOrSecNumber(splits[1]);
                                if (secondNumber.IsValid) {
                                    var minusSplits = splits[0].split('-');
                                    if (minusSplits.length > 1) {
                                        if (minusSplits.length == 2) {
                                            var firstNumber = CronHelper.IsValidMinOrSecNumber(minusSplits[0]);
                                            if (firstNumber.IsValid) {
                                                var sNumber = CronHelper.IsValidMinOrSecNumber(minusSplits[1]);
                                                if (sNumber.IsValid) {
                                                    return firstNumber.Number <= sNumber.Number;
                                                }
                                            }
                                        }
                                    } else {
                                        var firstNumber = CronHelper.IsValidMinOrSecNumber(splits[0]);
                                        return firstNumber.IsValid;
                                    }
                                }
                            }
                        } else {
                            splits = val.split('-');
                            // Enthaelt '-' -> zwei Zahlen
                            if (splits.length == 2) {
                                var firstNumber = CronHelper.IsValidMinOrSecNumber(splits[0]);
                                if (firstNumber.IsValid) {
                                    var secondNumber = CronHelper.IsValidMinOrSecNumber(splits[1]);
                                    if (secondNumber.IsValid) {
                                        return firstNumber.Number <= secondNumber.Number;
                                    }
                                }
                            } else {
                                var number = CronHelper.IsValidMinOrSecNumber(val);
                                return number.IsValid;
                            }
                        }
                    }
                } else {
                    // Laenge = 2 -> zweistellige Zahl
                    return val[0] >= '1' && val[0] <= '5' && val[1] >= '0' && val[1] <= '9';
                }
            } else {
                // Laenge = 1 -> * oder einstellige Zahl
                if (checkForStar) {
                    if (val[0] == '*') {
                        return true;
                    }
                }
                return val[0] >= '0' && val[0] <= '9';
            }
        }
        return false;
    }

    static IsValidHourValue(val: string): boolean {
        return CronHelper.IsValidHourValueInternal(val, true);
    }

    private static IsValidHourValueInternal(val: string, checkForStar: boolean): boolean {
        if (val.length > 0) {
            if (val.length > 1) {
                if (val.length > 2) {
                    // Laenge > 2 -> Muss '/', '-' oder ',' enthalten
                    // Erst nach Komma splitten
                    var splits = val.split(',');
                    if (splits.length > 1) {
                        var retVal = true;
                        splits.some(function (s) {
                            if (!CronHelper.IsValidHourValueInternal(s, false)) {
                                retVal = false;
                            }
                            return !retVal;
                        });
                        return retVal;
                    } else {
                        splits = val.split('/');
                        if (splits.length > 1) {
                            // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 23
                            if (splits.length == 2) {
                                var secondNumber = CronHelper.IsValidHourNumber(splits[1]);
                                if (secondNumber.IsValid) {
                                    var minusSplits = splits[0].split('-');
                                    if (minusSplits.length > 1) {
                                        if (minusSplits.length == 2) {
                                            var firstNumber = CronHelper.IsValidHourNumber(minusSplits[0]);
                                            if (firstNumber.IsValid) {
                                                var sNumber = CronHelper.IsValidHourNumber(minusSplits[1]);
                                                if (sNumber.IsValid) {
                                                    return firstNumber.Number <= sNumber.Number;
                                                }
                                            }
                                        }
                                    } else {
                                        var firstNumber = CronHelper.IsValidHourNumber(splits[0]);
                                        return firstNumber.IsValid;
                                    }
                                }
                            }
                        } else {
                            splits = val.split('-');
                            // Enthaelt '-' -> zwei Zahlen
                            if (splits.length == 2) {
                                var firstNumber = CronHelper.IsValidHourNumber(splits[0]);
                                if (firstNumber.IsValid) {
                                    var secondNumber = CronHelper.IsValidHourNumber(splits[1]);
                                    if (secondNumber.IsValid) {
                                        return firstNumber.Number <= secondNumber.Number;
                                    }
                                }
                            } else {
                                var number = CronHelper.IsValidHourNumber(val);
                                return number.IsValid;
                            }
                        }
                    }
                } else {
                    // Laenge = 2 -> zweistellige Zahl
                    if (val[0] == '1') {
                        return val[1] >= '0' && val[1] <= '9';
                    } else if (val[0] == '2') {
                        return val[1] >= '0' && val[1] <= '3';
                    }
                }
            } else {
                // Laenge = 1 -> * oder einstellige Zahl
                if (checkForStar) {
                    if (val[0] == '*') {
                        return true;
                    }
                }
                return val[0] >= '0' && val[0] <= '9';
            }
        }
        return false;
    }

    private static MonthList = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    static IsValidMonthValue(val: string): boolean {
        return CronHelper.IsValidMonthValueInternal(val, true);
    }

    private static IsValidMonthValueInternal(val: string, checkForStar: boolean): boolean {
        if (val.length > 0) {
            if (val.length > 1) {
                if (val.length > 2) {
                    if (val.length == 3) {
                        // Wenn Laenge 3 auf Monatsname pruefen
                        var tl = val.toLowerCase();
                        if (CronHelper.MonthList.some(function (m) { return tl == m; })) {
                            return true;
                        }
                    }
                    // Laenge > 2 -> Muss '/', '-' oder ',' enthalten
                    // Erst nach Komma splitten
                    var splits = val.split(',');
                    if (splits.length > 1) {
                        var retVal = true;
                        splits.some(function (s) {
                            if (!CronHelper.IsValidMonthValueInternal(s, false)) {
                                retVal = false;
                            }
                            return !retVal;
                        });
                        return retVal;
                    } else {
                        splits = val.split('/');
                        if (splits.length > 1) {
                            // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 12
                            if (splits.length == 2) {
                                var secondNumber = CronHelper.IsValidMonthName(splits[1], false);
                                if (secondNumber.IsValid) {
                                    var minusSplits = splits[0].split('-');
                                    if (minusSplits.length > 1) {
                                        if (minusSplits.length == 2) {
                                            var firstNumber = CronHelper.IsValidMonthName(minusSplits[0], true);
                                            if (firstNumber.IsValid) {
                                                var sNumber = CronHelper.IsValidMonthName(minusSplits[1], true);
                                                if (sNumber.IsValid) {
                                                    return firstNumber.Number <= sNumber.Number;
                                                }
                                            }
                                        }
                                    } else {
                                        var firstNumber = CronHelper.IsValidMonthName(splits[0], true);
                                        return firstNumber.IsValid;
                                    }
                                }
                            }
                        } else {
                            splits = val.split('-');
                            // Enthaelt '-' -> zwei Zahlen
                            if (splits.length == 2) {
                                var firstNumber = CronHelper.IsValidMonthName(splits[0], true);
                                if (firstNumber.IsValid) {
                                    var secondNumber = CronHelper.IsValidMonthName(splits[1], true);
                                    if (secondNumber.IsValid) {
                                        return firstNumber.Number <= secondNumber.Number;
                                    }
                                }
                            } else {
                                var number = CronHelper.IsValidMonthName(val, true);
                                return number.IsValid;
                            }
                        }
                    }
                } else {
                    // Laenge = 2 -> zweistellige Zahl
                    return val[0] == '1' && val[1] >= '0' && val[1] <= '2';
                }
            } else {
                // Laenge = 1 -> * oder einstellige Zahl
                if (checkForStar) {
                    if (val[0] == '*') {
                        return true;
                    }
                }
                return val[0] >= '1' && val[0] <= '9';
            }
        }
        return false;
    }

    static IsValidDOMValue(val: string): boolean {
        return CronHelper.IsValidDOMValueInternal(val, true);
    }

    private static IsValidDOMValueInternal(val: string, checkForStar: boolean): boolean {
        var retVal = false;
        if (val.length > 0) {
            if (val.length > 1) {
                if (val.length > 2) {
                    // Laenge > 2
                    if (checkForStar) {
                        if (val[0].toLowerCase() == 'l') {
                            // Wenn string mit L beginnt
                            if (val[1] == '-') { // Muss ein - und eine Zahl zwischen 1 und 31 folgen
                                if (val.length == 3) {
                                    return val[2] >= '1' && val[2] <= '9';
                                } else if (val.length == 4) {
                                    if (val[2] >= '1' && val[2] <= '2') {
                                        return val[3] >= '0' && val[3] <= '9';
                                    } else if (val[2] == '3') {
                                        return val[3] == '0' || val[3] == '1';
                                    }
                                }
                            }
                        } else if (val.length == 3 && val[2].toLowerCase() == 'w') {
                            // Wenn string mit W endet und 3 Zeichen lang ist -> zweistellige Zahl + W
                            if (val[0] >= '1' && val[0] <= '2') {
                                return val[1] >= '0' && val[1] <= '9';
                            } else if (val[0] == '3') {
                                return val[1] == '0' || val[1] == '1';
                            }
                        }
                    }

                    // Laenge > 2 -> Muss '/', '-' oder ',' enthalten
                    // Erst nach Komma splitten
                    var splits = val.split(',');
                    if (splits.length > 1) {
                        var retVal = true;
                        splits.some(function (s) {
                            if (!CronHelper.IsValidDOMValueInternal(s, false)) {
                                retVal = false;
                            }
                            return !retVal;
                        });
                        return retVal;
                    } else {
                        splits = val.split('/');
                        if (splits.length > 1) {
                            // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 31
                            if (splits.length == 2) {
                                var secondNumber = CronHelper.IsValidDayOfMonth(splits[1]);
                                if (secondNumber.IsValid) {
                                    var minusSplits = splits[0].split('-');
                                    if (minusSplits.length > 1) {
                                        if (minusSplits.length == 2) {
                                            var firstNumber = CronHelper.IsValidDayOfMonth(minusSplits[0]);
                                            if (firstNumber.IsValid) {
                                                var sNumber = CronHelper.IsValidDayOfMonth(minusSplits[1]);
                                                if (sNumber.IsValid) {
                                                    return firstNumber.Number <= sNumber.Number;
                                                }
                                            }
                                        }
                                    } else {
                                        var firstNumber = CronHelper.IsValidDayOfMonth(splits[0]);
                                        return firstNumber.IsValid;
                                    }
                                }
                            }
                        } else {
                            splits = val.split('-');
                            // Enthaelt '-' -> zwei Zahlen
                            if (splits.length == 2) {
                                var firstNumber = CronHelper.IsValidDayOfMonth(splits[0]);
                                if (firstNumber.IsValid) {
                                    var secondNumber = CronHelper.IsValidDayOfMonth(splits[1]);
                                    if (secondNumber.IsValid) {
                                        return firstNumber.Number <= secondNumber.Number;
                                    }
                                }
                            } else {
                                var number = CronHelper.IsValidDayOfMonth(val);
                                return number.IsValid;
                            }
                        }
                    }
                } else {
                    // Laenge = 2 -> einstellige Zahl + W oder LW oder zweistellige Zahl
                    if (checkForStar) {
                        if (val[1].toLowerCase() == 'w') {
                            return val[0].toLowerCase() == 'l' || (val[0] >= '1' && val[0] <= '9');
                        }
                    }
                    if (val[0] >= '1' && val[0] <= '2') {
                        return val[1] >= '0' && val[1] <= '9';
                    } else if (val[0] == '3') {
                        return val[1] == '0' || val[1] == '1';
                    }                   
                }
            } else {
                // Laenge = 1 -> * oder ? oder L oder einstellige Zahl
                var actVal = val[0];
                if (checkForStar) {
                    if (actVal == '*' || actVal == '?' || actVal.toLowerCase() == 'l') {
                        return true;
                    }
                }
                return actVal >= '1' && actVal <= '9';
            }
        }
        return retVal;
    }

    private static DayOfWeekList = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    static IsValidDOWValue(val: string): boolean {
        return CronHelper.IsValidDOWValueInternal(val, true);
    }

    private static IsValidDOWValueInternal(val: string, checkForStar: boolean): boolean {
        if (val.length > 0) {
            if (val.length > 1) {
                if (val.length > 2) {
                    if (val.length == 3) {
                        // Auf WochenTag und # pruefen
                        var tl = val.toLowerCase();
                        if (CronHelper.DayOfWeekList.some(function (m) { return tl == m; })) {
                            return true;
                        }
                        if (val[1] == '#') {
                            return val[0] >= '1' && val[0] <= '7' && val[2] >= '1' && val[2] <= '5';
                        }
                    }
                    var hashInd = val.indexOf('#');
                    if (hashInd > -1) {
                        // Wenn # existiert
                        if (hashInd == 0 || hashInd == val.length - 1 || val.indexOf('/') > -1 || val.indexOf('-') > -1 || (hashInd < val.length - 1 && val.indexOf('#', hashInd + 1))) {
                            // HashIndex erstes oder letztes Zeichen und / oder - oder noch ein Hash existiert -> falsch
                            return false;
                        }
                    }

                    // Laenge > 2 -> Muss '/', '-' oder ',' enthalten
                    // Erst nach Komma splitten
                    var splits = val.split(',');
                    if (splits.length > 1) {
                        var retVal = true;
                        splits.some(function (s) {
                            if (!CronHelper.IsValidDOWValueInternal(s, false)) {
                                retVal = false;
                            }
                            return !retVal;
                        });
                        return retVal;
                    } else {
                        splits = val.split('/');
                        if (splits.length > 1) {
                            // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 31
                            if (splits.length == 2) {
                                var secondNumber = CronHelper.IsValidDayOfWeek(splits[1], false);
                                if (secondNumber.IsValid) {
                                    var minusSplits = splits[0].split('-');
                                    if (minusSplits.length > 1) {
                                        if (minusSplits.length == 2) {
                                            var firstNumber = CronHelper.IsValidDayOfWeek(minusSplits[0], true);
                                            if (firstNumber.IsValid) {
                                                var sNumber = CronHelper.IsValidDayOfWeek(minusSplits[1], true);
                                                if (sNumber.IsValid) {
                                                    return firstNumber.Number <= sNumber.Number;
                                                }
                                            }
                                        }
                                    } else {
                                        var firstNumber = CronHelper.IsValidDayOfWeek(splits[0], true);
                                        return firstNumber.IsValid;
                                    }
                                }
                            }
                        } else {
                            splits = val.split('-');
                            // Enthaelt '-' -> zwei Zahlen
                            if (splits.length == 2) {
                                var firstNumber = CronHelper.IsValidDayOfWeek(splits[0], true);
                                if (firstNumber.IsValid) {
                                    var secondNumber = CronHelper.IsValidDayOfWeek(splits[1], true);
                                    if (secondNumber.IsValid) {
                                        return firstNumber.Number <= secondNumber.Number;
                                    }
                                }
                            } else {
                                var number = CronHelper.IsValidDayOfWeek(val, true);
                                return number.IsValid;
                            }
                        }
                    }

                } else {
                    // Laenge = 2 -> einstellige Zahl + L
                    if (checkForStar && val[1].toLowerCase() == 'l') {
                        return val[0] >= '1' && val[0] <= '7';
                    }
                }
            } else {
                // Laenge = 1 -> * oder ? oder L oder einstellige Zahl
                var actVal = val[0];
                if (checkForStar) {
                    if (actVal == '*' || actVal == '?' || actVal.toLowerCase() == 'l') {
                        return true;
                    }
                }
                return actVal >= '1' && actVal <= '7';
            }
        }
        return false;
    }

    static IsValidYearValue(val: string): boolean {
        return CronHelper.IsValidYearValueInternal(val, true);
    }

    private static IsValidYearValueInternal(val: string, checkForStar: boolean): boolean {
        if (val.length > 0) {
            if (val.length > 1) {
                // Erst nach Komma splitten
                var splits = val.split(',');
                if (splits.length > 1) {
                    var retVal = true;
                    splits.some(function (s) {
                        if (!CronHelper.IsValidYearValueInternal(s, false)) {
                            retVal = false;
                        }
                        return !retVal;
                    });
                    return retVal;
                } else {
                    splits = val.split('/');
                    if (splits.length > 1) {
                        // Enthaelt '/' -> von/bis oder Zahl + Zahl zw 0 und 129
                        if (splits.length == 2) {
                            var secondNumber = CronHelper.IsValidYearIncrement(splits[1]);
                            if (secondNumber.IsValid) {
                                var minusSplits = splits[0].split('-');
                                if (minusSplits.length > 1) {
                                    if (minusSplits.length == 2) {
                                        var firstNumber = CronHelper.IsValidYearNumber(minusSplits[0]);
                                        if (firstNumber.IsValid) {
                                            var sNumber = CronHelper.IsValidYearNumber(minusSplits[1]);
                                            if (sNumber.IsValid) {
                                                return firstNumber.Number <= sNumber.Number;
                                            }
                                        }
                                    }
                                } else {
                                    var firstNumber = CronHelper.IsValidYearNumber(splits[0]);
                                    return firstNumber.IsValid;
                                }
                            }
                        }
                    } else {
                        splits = val.split('-');
                        // Enthaelt '-' -> zwei Zahlen
                        if (splits.length == 2) {
                            var firstNumber = CronHelper.IsValidYearNumber(splits[0]);
                            if (firstNumber.IsValid) {
                                var secondNumber = CronHelper.IsValidYearNumber(splits[1]);
                                if (secondNumber.IsValid) {
                                    return firstNumber.Number <= secondNumber.Number;
                                }
                            }
                        } else {
                            var number = CronHelper.IsValidYearNumber(val);
                            return number.IsValid;
                        }
                    }
                }
            } else {
                // Laenge = 1 -> *
                if (checkForStar) {
                    if (val[0] == '*') {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private static IsValidMinOrSecNumber(val: string) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '0' && val[0] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 2) {
            if (val[0] >= '1' && val[0] <= '5' && val[1] >= '0' && val[1] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        }
        return retVal;
    }

    private static IsValidHourNumber(val: string) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '0' && val[0] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 2) {
            if (val[0] == '1') {
                if (val[1] >= '0' && val[1] <= '9') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            } else if (val[0] == '2') {
                if (val[1] >= '0' && val[1] <= '3') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            }
        }
        return retVal;
    }

    private static IsValidMonthName(val: string, checkName: boolean) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '1' && val[0] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 2) {
            if (val[0] == '1') {
                if (val[1] >= '0' && val[1] <= '2') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            }
        } else if (checkName && val.length == 3) {
            var tl = val.toLowerCase();
            for (var i = 0; i < CronHelper.MonthList.length; i++) {
                if (tl == CronHelper.MonthList[i]) {
                    retVal.IsValid = true;
                    retVal.Number = i + 1;
                    break;
                }
            }
        }
        return retVal;
    }

    private static IsValidDayOfMonth(val: string) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '1' && val[0] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 2) {
            if (val[0] == '1' || val[0] == '2') {
                if (val[1] >= '0' && val[1] <= '9') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            } else if (val[0] == '3') {
                if (val[1] == '0' || val[1] == '1') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            }
        }
        return retVal;
    }

    private static IsValidDayOfWeek(val: string, checkName: boolean) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '1' && val[0] <= '7') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (checkName && val.length == 3) {
            var tl = val.toLowerCase();
            for (var i = 0; i < CronHelper.DayOfWeekList.length; i++) {
                if (tl == CronHelper.DayOfWeekList[i]) {
                    retVal.IsValid = true;
                    retVal.Number = i + 1;
                    break;
                }
            }
        }
        return retVal;
    }

    private static IsValidYearIncrement(val: string) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 1) {
            if (val[0] >= '0' && val[1] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 2) {
            if (val[0] >= '1' && val[0] <= '9' && val[1] >= '0' && val[1] <= '9') {
                retVal.Number = parseInt(val);
                retVal.IsValid = true;
            }
        } else if (val.length == 3) {
            if (val[0] == '1') {
                if (val[1] >= '0' && val[1] <= '2' && val[2] >= '0' && val[2] <= '9') {
                    retVal.Number = parseInt(val);
                    retVal.IsValid = true;
                }
            }
        }
        return retVal;
    }

    private static IsValidYearNumber(val: string) {
        var retVal = {
            IsValid: false,
            Number: -1
        };
        while (val[0] == '0' && val.length > 1) {
            val = val.substr(1);
        }
        if (val.length == 4) {
            if (val[0] == '1') {
                if (val[1] == '9') {
                    if (val[2] >= '7' && val[2] <= '9' && val[3] >= '0' && val[3] <= '9') {
                        retVal.Number = parseInt(val);
                        retVal.IsValid = true;
                    }
                }
            } else if (val[0] == '2') {
                if (val[1] == '0') {
                    if (val[2] >= '0' && val[2] <= '9' && val[3] >= '0' && val[3] <= '9') {
                        retVal.Number = parseInt(val);
                        retVal.IsValid = true;
                    }
                }
            }
        }
        return retVal;
    }
}

export class CronObject {
    Seconds: string = '*';
    SecondsCorrect: boolean = true;
    Minutes: string = '*';
    MinutesCorrect: boolean = true;
    Hours: string = '*';
    HoursCorrect: boolean = true;
    DayOfMonth: string = '*';
    DayOfMonthCorrect: boolean = true;
    Month: string = '*';
    MonthCorrect: boolean = true;
    DayOfWeek: string = '?';
    DayOfWeekCorrect: boolean = true;
    Year: string = '*';
    YearCorrect: boolean = true;

    GetExpression(): string {
        return [this.Seconds, this.Minutes, this.Hours, this.DayOfMonth, this.Month, this.DayOfWeek, this.Year].join(' ');
    }

    GetDescription(): string {
        if (this.IsValid()) {
            var exp = this.GetExpression();
            // TODO: �bersetzung
            return cronstrue.toString(exp);
        } else {
            return TranslateHelper.TranslatorInstance.instant('@@Ungueltiger Ausdruck');
        }
    }

    IsValid(): boolean {
        if (this.SecondsCorrect && this.MinutesCorrect && this.HoursCorrect && this.DayOfMonthCorrect && this.MonthCorrect && this.DayOfWeekCorrect && this.YearCorrect) {
            if (this.DayOfMonth == '?') {
                return this.DayOfWeek != '?';
            } else {
                return this.DayOfWeek == '?';
            }
        }
        return false;
    }
}