import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { DataDescription, HeterogenArea } from '../../../models/datadescription/multi/datadescription.model';
import { MeasureFunctionAccumulation, MeasureFunctionOffset, MeasureInfo } from '../../../models/datadescription/multi/measureinfo.model';
import { Aggregation, DimensionType, LevelType } from '../../../models/enums';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { AxisType } from '../../../models/enums/query.enum';
import { SpecialElementType } from '../../../models/enums/specialElementType';
import { DynamicTimeRange } from '../../../models/time/dynamictimerange.model';
import { TimeRangeReference } from '../../../models/time/timerangereference.model';
import { DataDescriptionHelper } from '../../query/ddhelper.query';
import { ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';

@Component({
    selector: 'measuredefinitions',
    templateUrl: './measuredef.control.html',
    styleUrls: ['./measuredef.control.css']
})
export class MeasureDefinitionsControl implements OnInit, OnDestroy {

    VisibilityTypes = [];
    ASFunctionTypes = [];
    DataDescriptionValue: DataDescription;
    SelectedMeasure: MeasureInfo;
    SelectedAreaID = -1;
    Areas = [];
    timeLevels: any[];

    MeasureToArea = new Map<number, number>();

    get ReferencingSelects() {
        if (!this.SelectedMeasure.Details.ReferencingSelects) {
            this.SelectedMeasure.Details.ReferencingSelects = [];
        }
        return this.SelectedMeasure.Details.ReferencingSelects;
    }

    get FunctionOffset() {
        if (!this.SelectedMeasure.Details.FunctionOffset) {
            this.SelectedMeasure.Details.FunctionOffset = new MeasureFunctionOffset();
        }
        return this.SelectedMeasure.Details.FunctionOffset;
    }

    get FunctionAccumulation() {
        if (!this.SelectedMeasure.Details.FunctionAccumulation) {
            this.SelectedMeasure.Details.FunctionAccumulation = new MeasureFunctionAccumulation();
        }
        return this.SelectedMeasure.Details.FunctionAccumulation;
    }

    get MeasureDynamicTimeReferencing() {
        if (!this.SelectedMeasure.Details.MeasureDynamicTimeReferencing) {
            this.SelectedMeasure.Details.MeasureDynamicTimeReferencing = new DynamicTimeRange();
        }
        return this.SelectedMeasure.Details.MeasureDynamicTimeReferencing;
    }

    get TimeRangeReference() {
        if (!this.SelectedMeasure.Details.TimeRangeReference) {
            this.SelectedMeasure.Details.TimeRangeReference = new TimeRangeReference();
        }
        return this.SelectedMeasure.Details.TimeRangeReference;
    }

    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
        if (this.DataDescriptionValue && this.DataDescriptionValue.Filter) {
            this.AllMeasures = this.DataDescriptionValue.MeasureInfos;
        }
        this.GetMeasuresAndAreas();
    }

    _SearchValue;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterList();
    }

    AllMeasuresValue: MeasureInfo[] = [];
    MeasureItems: MeasureInfo[] = [];

    @Input()
    get AllMeasures() {
        return this.AllMeasuresValue;
    }
    set AllMeasures(value) {
        this.AllMeasuresValue = [];
        this._SearchValue = '';
        if (Array.isArray(value)) {
            this.AllMeasuresValue = value;
        }

        this.MeasureItems = this.AllMeasuresValue;
    }
    MCSub;
    constructor() {
        this.AllMeasures = [];
        this.VisibilityTypes = EnumHelper.GetDropdownValues(VisibilityType);
        this.ASFunctionTypes = EnumHelper.GetDropdownValues(Aggregation);
    }
    ngOnDestroy(): void {
        if (this.MCSub) {
            this.MCSub.unsubscribe();
        }
    }
    ngOnInit(): void {
        this.MCSub = ObjectcreatorDataManager.MeasureChanged.subscribe(() => {
            this.AllMeasures = null;
            this.Areas = [];
            window.setTimeout(() => {
                this.GetMeasuresAndAreas();
            }, 50);
        });
    }

    GetMeasuresAndAreas() {
        const measureinfos = [];
        let index = 0;
        this.DataDescription.XLevelNodes.Areas.forEach(a => {
            if (a.Measures && a.Measures.length > 0) {
                const areaInfo = { Caption: a.Caption ? a.Caption.toString() : null, UniqueID: a.UniqueID };
                if (!areaInfo.Caption) {
                    areaInfo.Caption = 'X - ' + index;
                }
                this.Areas.push(areaInfo);
                a.Measures.forEach(mi => {
                    ObjectcreatorDataManager.Gettooltip(mi, this.DataDescription).then(tt => {
                        mi["Tooltip"] = tt;

                    });
                    measureinfos.push(mi);
                    this.MeasureToArea.set(mi.UniqueID, a.UniqueID);
                });
            }
            index++;
        });
        if (measureinfos.length === 0) {
            index = 0;
            this.DataDescription.YLevelNodes.Areas.forEach(a => {
                if (a.Measures && a.Measures.length > 0) {
                    const areaInfo = { Caption: a.Caption ? a.Caption.toString() : null, UniqueID: a.UniqueID };
                    if (!areaInfo.Caption) {
                        areaInfo.Caption = 'Y - ' + index;
                    }
                    this.Areas.push(areaInfo);
                    a.Measures.forEach(mi => {
                        
                        measureinfos.push(mi);
                        this.MeasureToArea.set(mi.UniqueID, a.UniqueID);
                    });
                }
            });
        }
        this.AllMeasures = measureinfos;
    }

    async measureSelected(item) {
        this.SelectedMeasure = item;
        this.timeLevels = await this.GetOffsetLevels();
    }

    AreaFilterChanged() {
        this.FilterList();
    }

    FilterList() {
        let allMeasures = this.AllMeasures;
        if (this.SelectedAreaID >= 0) {
            allMeasures = allMeasures.filter(item => this.MeasureToArea.get(item.UniqueID) === this.SelectedAreaID);
        }
        this.MeasureItems = allMeasures.filter(item =>
            (!this._SearchValue || this._SearchValue === '' || item.Caption.toString().search(this._SearchValue) !== -1));
    }

    async  GetOffsetLevels(): Promise<any[]> {
        const retVal = [];
        // Zur Nutzung des Period-Tags PP bei berechneten Measures zulassen (Ticket 8276)
        if (this.SelectedMeasure != null || this.SelectedMeasure.SpecialElementType === SpecialElementType.CalculatedElement) {
            const area: HeterogenArea = DataDescriptionHelper.FindAreaByMeasureID(this.DataDescription, this.SelectedMeasure.UniqueID);
            if (area != null) {
                const levels = DataDescriptionHelper.GetLevels(area);
                const otherAxis = this.DataDescription.ShowMeasureOnAxis === AxisType.X_Axis ?
                    this.DataDescription.YLevelNodes : this.DataDescription.XLevelNodes;
                levels.push(...DataDescriptionHelper.GetAllNodes(otherAxis));
                for (let i = 0; i < levels.length; i++) {
                    const cubeLevel = await MultiCacheService.GetLevel(levels[i].Level, this.DataDescription.DataModelID);
                    if (cubeLevel && cubeLevel.Parent && cubeLevel.Parent.Parent &&
                        cubeLevel.Parent.Parent.DimensionType === DimensionType.Time) {
                        cubeLevel.Parent.Levels.forEach(l => {
                            if (l.LevelType !== LevelType.All) {
                                retVal.push({ Caption: l.Caption, Value: l.ID });
                            }
                        });
                        break;
                    }
                }
            }
        }
        return retVal;
    }

    onContextMenu(event: MouseEvent, dditem: any) {
        event.preventDefault();
        event['OcDragItem'] = dditem;
        event['DropArea'] = this;
    }
}
