import { multiply } from 'mathjs';
import { FormulaStatics, ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { FormulaGroups, PrimitiveFormula } from './iformula';

export class Multiplication extends PrimitiveFormula {
    static StaticID = 'b1fc1f52-ba41-46f2-a9ab-c6e2a23aa758';

    constructor() {
        super();
        this._ID = Multiplication.StaticID;
        this._Name = 'Multiplikation';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
        this._MinVariableCount = 1;
    }

    CanCalcNulls(): boolean {
        return true;
    }
    Group(): string {
        return FormulaGroups.BasicOperations;
    }
    OrderID(): number {
        return 3;
    }
    OperationType(): OperationType {
        return OperationType.Multiplication;
    }
    OperationValue(): string {
        return '*';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && (maxType === ValueType.Double || maxType === ValueType.Long)) {
            let calcVal = 1;
            args.some((item) => {
                if (typeof item.Value === 'undefined' || item.Value === null) {
                    calcVal = null;
                    return true;
                }
                calcVal = multiply(calcVal, item.Value);
                if (isNaN(calcVal)) {
                    calcVal = null;
                    return true;
                }
                return false;
            });
            if (calcVal !== null) {
                retVal.Type = maxType;
                retVal.Value = calcVal;
            }
        }
        return retVal;
    }
}
