import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { LayoutHelper } from '../../../../helpers/layout.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-read-control-properties-settings',
    templateUrl: './read.control.properties.settings.html'
})
export class ReadControlPropertiesSettings extends WorkflowDialogContent {
    Data = new ReadControlPropertiesSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readControlPropertyModule';
        reg.Caption = '@@Control-Eigenschaft auslesen';
        reg.GroupID = 'layout';
        reg.Index = 70;
        reg.SettingsControl = ReadControlPropertiesSettings;
        reg.SettingsTypeHelper = new ReadControlPropertiesSettingsDataHelper();
        reg.Executer = ReadControlPropertiesModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadControlPropertiesSettingsData, json);
            this.Data.Controls.forEach(c => {
                this.controlChanged(c);
            });
        }
    }

    getResult(): any {
        const controls = [];
        this.Data.Controls.forEach(c => {
            if (c.ControlName && c.Properties) {
                const props = [];
                c.Properties.forEach(prop => {
                    if (prop.PropertyName && (prop.StatusKey || prop.UseForState)) {
                        props.push(prop);
                    }
                });
                if (props.length > 0) {
                    const control = new ReadControlEntry();
                    control.ControlName = c.ControlName;
                    control.Properties = props;
                    controls.push(control);
                }
            }
        });
        this.Data.Controls = controls;
        return this.Data;
    }

    useForStateChecked(prop) {
        if (prop.UseForState) {
            this.Data.Controls.forEach(c => {
                c.Properties.forEach(p => {
                    p.UseForState = false;
                });
            });
            prop.UseForState = true;
        }
    }

    addControl() {
        if (this.Data) {
            if (this.Data.Controls) {
                this.Data.Controls.push(new ReadControlEntry());
            } else {
                this.Data.Controls = [new ReadControlEntry()];
            }
        }
    }

    removeControl(i) {
        if (this.Data && this.Data.Controls) {
            this.Data.Controls.splice(i, 1);
        }
    }

    controlChanged(c) {
        if (c) {
            const list = [];
            if (this.WFEditOptions && this.WFEditOptions.Layout) {
                const elem = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, c.ControlName);
                if (elem && elem.Element && elem.Element.PropertyList) {
                    list.push(...elem.Element.PropertyList);
                }
            }
            c.ControlProps = list;
        }
    }

    addProp(c) {
        if (c) {
            if (c.Properties) {
                c.Properties.push(new ControlPropertyEntry());
            } else {
                c.Properties = [new ControlPropertyEntry()];
            }
        }
    }

    removeProp(c, i) {
        if (c && c.Properties) {
            c.Properties.splice(i, 1);
        }
    }

    propSelected(p, prop) {
        if (p && prop) {
            p.PropertyName = prop.PropertyName;
        }
    }
}

// @dynamic
export class ReadControlPropertiesSettingsData {
    @Type(() => ReadControlEntry)
    Controls: ReadControlEntry[] = [];
}

// @dynamic
export class ReadControlEntry {
    ControlName: string;
    @Type(() => ControlPropertyEntry)
    Properties: ControlPropertyEntry[] = [];
}

export class ControlPropertyEntry {
    PropertyName: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadControlPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new ReadControlPropertiesSettingsData();
    }
    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Controls) {
            settings.Controls.forEach(x => {
                if (x.ControlName === oldName) {
                    x.ControlName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class ReadControlPropertiesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            status.ActualSettings.Controls.forEach(c => {
                if (c.ControlName && c.Properties && c.Properties.length > 0) {
                    const elem = status.WorkflowLayoutService.getElementByName(c.ControlName);
                    if (elem && elem.Element) {
                        c.Properties.forEach(prop => {
                            if (prop.PropertyName) {
                                if (elem.Element.PropertyList &&
                                    elem.Element.PropertyList.some(x => x.PropertyName === prop.PropertyName)) {
                                    const val = elem.Element[prop.PropertyName];
                                    if (prop.StatusKey) {
                                        status.Context.set(prop.StatusKey, val);
                                    }
                                    if (prop.UseForState) {
                                        status.ActualParameter = val;
                                    }
                                } else {
                                    status.Logger.logWarning('ReadControlProperties module: Property ' + prop.PropertyName +
                                        ' can not be read on element ' + c.ControlName);
                                }
                            }
                        });
                    } else {
                        status.Logger.logWarning('ReadControlProperties module: Element with name \'' +
                            c.ControlName + '\' could not found.');
                    }
                }
            });
            return 0;
        } else {
            status.Logger.logError('ReadControlProperties module: No settings found.');
        }
        return super.execute(status);
    }
}