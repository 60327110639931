import { Component } from '@angular/core';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../../../workflow/workflow.dialog';

export class LuisSettings extends AServiceWorkflowData {
    Formula: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.Luis.LuisSettingsData';
    }
}

export class LuisDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new LuisSettings();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

@Component({
    selector: 'luis-wf-settings',
    templateUrl: './luis.settings.html',
    styleUrls: ['./luis.settings.css']
})
export class LuisValueSettings extends FormulaWorkflowDialogContent {

    Formula: string;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'languageUnderstandingWF';
        reg.Caption = '@@Luis';
        reg.GroupID = 'aioperations';
        reg.Index = 260;
        reg.SettingsControl = LuisValueSettings;
        reg.SettingsTypeHelper = new LuisDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data && data.Formula) {
            this.Formula = data.Formula;
        }
    }

    getResult(): any {
        const retVal = new LuisSettings();
        retVal.Formula = this.Formula;
        return retVal;
    }
}
