import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClientHelper } from '../../helpers/client.helper';
import { WorkflowDebugHelper } from '../../helpers/workflow.debug.helper';

@Component({
    selector: 'debug-workflow-log',
    templateUrl: './debug.workflow.log.html',
    styleUrls: ['./debug.workflow.log.css']
})
export class DebugWorkflowLog implements OnInit, OnDestroy {
    LogSub;
    ShowLogs = false;
    Logs = [];
    @ViewChild('logDIV') logDIV: ElementRef;
    cdRefTimeOut = -1;
    ClientID = ClientHelper.ClientID;
    SelectedLog;
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnDestroy(): void {
        if (this.LogSub) {
            this.LogSub.unsubscribe();
            this.LogSub = null;
        }
    }

    ngOnInit(): void {
        WorkflowDebugHelper.DebugSettings.subscribe(x => {
            const showLogs = x.ShowWorkflowLog || x.ShowServiceLogsOnTest;
            if (showLogs !== this.ShowLogs) {
                if (showLogs) {
                    this.LogSub = WorkflowDebugHelper.NextLog.subscribe(log => {
                        this.Logs.push({
                            Message: log.Message,
                            Foreign: log.ClientID !== this.ClientID,
                            Service: log.ClientID === 'service',
                            Script: log.Status ? JSON.stringify(log.Status, null, 4) : null
                        });
                        this.tryScroll();
                    });
                } else if (this.LogSub) {
                    this.LogSub.unsubscribe();
                    this.LogSub = null;
                }
            }
            this.ShowLogs = showLogs;
        });
    }

    tryScroll() {
        clearTimeout(this.cdRefTimeOut);
        this.cdRefTimeOut = window.setTimeout(() => {
            this.cdRef.detectChanges();
            if (this.logDIV && this.logDIV.nativeElement) {
                this.logDIV.nativeElement.scrollTop = this.logDIV.nativeElement.scrollHeight;
            }
        }, 50);
    }

    clear() {
        this.Logs.splice(0);
        this.SelectedLog = null;
        this.tryScroll();
    }
}
