import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import { DialogHelper } from '../../../../helpers/injector.helper';
import { LayoutService } from '../../../../services/layout.service';

export class ReportingPreviewInitArgs {
	data: any;
}

@Component({
	selector: 'preview-dialogue-component',
	templateUrl: './preview-dialogue.component.html',
	styleUrls: ['./preview-dialogue.component.css'],
})
export class ReportingPreviewComponent implements OnInit {
	editorOptions = {
		language: 'json',
		readOnly: true,
		// scrollBeyondLastLine: false,
	};
	static OpenDialog(
		template: ReportingPreviewInitArgs,
		handler?: (x: any) => void
	) {
		const config = new MatDialogConfig();
		config.data = template;
		config.panelClass = 'preview-dialogue-component';
		config.disableClose = false;
		const diaRef = DialogHelper.DialogInstance.open(
			ReportingPreviewComponent,
			config
		);
		if (handler) {
			diaRef.afterClosed().subscribe((x) => {
				handler(x);
			});
		}
	}

	PreviewData;
	constructor(
		private dialogRef: MatDialogRef<ReportingPreviewComponent>,
		@Inject(MAT_DIALOG_DATA) public _template: ReportingPreviewInitArgs,
		private cdRef: ChangeDetectorRef
	) {
		this.PreviewData = JSON.stringify(_template, null, 2);
	}

	ngOnInit(): void {}

	onUseTemplate(template) {
		this.dialogRef.close(template);
	}

	loading(value) {
		LayoutService.Loading.next(value);
		this.cdRef.detectChanges();
	}
}
