//#region Angular
//#endregion
//#region HTTP

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
//#endregion
//#region Routing
import { Router, RouterModule } from '@angular/router';
//#endregion
//#region Translate
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import 'chart.js/dist/chart.min.js';
import { SnotifyService, ToastDefaults } from 'ng-snotify';
import { TreeDragDropService } from 'primeng/api';
//#endregion
import { routes } from '../app.routing';
//#endregion
//#region Injector
import { DialogHelper, InjectorHelper, RoutingHelper, TranslateHelper } from '../helpers/injector.helper';
import { APP_ACTIONS } from '../imports/app.actions';
import { APP_COMMON } from '../imports/app.common';
import { APP_CONTROLS, APP_STATIC_CONTROLS } from '../imports/app.controls';
import { APP_DATAMODEL } from '../imports/app.datamodel';
//#endregion
//#region includes
import { APP_DECLARATIONS } from '../imports/app.declarations';
import { APP_DIALOGS } from '../imports/app.dialogs';
import { APP_EXPORTS, MW_DIALOGS } from '../imports/app.exports';
import { APP_DIRECTIVES, APP_PIPES } from '../imports/app.helpers';
import { APP_LAYOUTS } from '../imports/app.layouts';
import { APP_MEDIATYPES } from '../imports/app.mediatypes';
import { APP_MENUTABS } from '../imports/app.menutabs';
import { OthersModule } from '../imports/app.others';
import { APP_PAGES } from '../imports/app.pages';
import { APP_SERVICES } from '../imports/app.services';
import { APP_SETTINGS } from '../imports/app.settings';
import { APP_THEME_CONTROLS } from '../imports/app.themecontrols';
import { APP_WIDGETS } from '../imports/app.widgets';
import { APP_WIZARDS } from '../imports/app.wizards';
import { EviControlsModule } from '../imports/evi.controls';
import { MaterialModule } from '../imports/material.module';
import { PrimeNgModule } from '../imports/primeng.module';
import { APP_DEPLOYMENTACTIONS_CONTROLS } from '../registries/actions/actions.registry';
import { APP_WORKFLOW } from '../registries/workflows/workflow.registry';
import { BasicAuthInterceptor } from '../services/basic_auth.interceptor';
import { AppMissingTranslationHandler, TranslationLoader } from '../services/translation.service';
import { AppFrameworkComponent } from './appframework.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_DATAMODEL_SETTINGS, APP_MW_SETTINGS, APP_MW_PIPES } from '../registries/settings/app.mw.settings';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MW_SERVICES } from '../imports/app.mw.services';
import { MW_WORKFLOW } from '../registries/workflows/app.mw.workflow';
import { DateTimeAdapter, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { MW_EXPORTS } from '../imports/app.exports';
import { QuillModule } from 'ngx-quill'
import { MW_COMPONENTS } from '../imports/app.components';
import { MAC_SERVICES, MAC_DIRECTIVES } from '../imports/mac.services';
import { APP_MULTIPANELS } from '../imports/app.multipanels';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MAC_DIALOGS } from '../imports/mac.dialogs';
import { MAC_SETTINGS } from '../registries/settings/mac.settings';
import { MAC_MENUTABS } from '../imports/mac.menutabs';
import { MAC_TASKS } from '../registries/tasks/mac.tasks';
import { MAC_WORKFLOWS } from '../registries/workflows/mac.workflows';
import { AngularSplitModule } from 'angular-split';
import { GridsterModule } from 'angular-gridster2';
import { TabViewModule } from 'primeng/tabview';
import { AngularDraggableModule } from 'angular2-draggable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DataTableTranslateHelper } from '../helpers/translate.helper';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { GADateTimeAdapter, GAIntl } from '../services/owl.services';
import { GoogleMapsModule} from '@angular/google-maps';
import {TourMatMenuModule} from "ngx-ui-tour-md-menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { RefreshTokenInterceptor } from '../services/refresh.interceptor';
import { SharedModule } from '../shared-module/shared.module';
import { GoogleSigninComponent } from "../component_v2/google-signin/google-signin.component";
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationLoader(http);
}

export const TooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
}

//#endregion
@NgModule({
    declarations: [
        AppFrameworkComponent,
        // HeaderComponent,
        // MessageCenterDialog,
        APP_DECLARATIONS,
        APP_MENUTABS,
        APP_CONTROLS,
        APP_STATIC_CONTROLS,
        APP_THEME_CONTROLS,
        APP_DIALOGS,
        APP_PAGES,
        APP_LAYOUTS,
        APP_PIPES,
        APP_DIRECTIVES,
        APP_WORKFLOW,
        APP_ACTIONS,
        APP_DATAMODEL,
        APP_COMMON,
        APP_SETTINGS,
        APP_WIZARDS,
        APP_WIDGETS,
        APP_DEPLOYMENTACTIONS_CONTROLS,
        APP_MEDIATYPES,
        MW_COMPONENTS,
        APP_MW_SETTINGS,
        APP_DATAMODEL_SETTINGS,
        MW_WORKFLOW,
        APP_MW_PIPES,
        MW_DIALOGS,
        APP_MULTIPANELS,
        MAC_DIALOGS,
        MAC_SETTINGS,
        MAC_MENUTABS,
        MAC_TASKS,
        MAC_DIRECTIVES,
        MAC_WORKFLOWS,
        GoogleSigninComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        EviControlsModule,
        InfiniteScrollModule,
        MaterialModule,
        PrimeNgModule,
        MatButtonModule,
        MatIconModule,
        OwlDateTimeModule,
        QuillModule.forRoot(),
        OwlNativeDateTimeModule,
        MonacoEditorModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OthersModule,
        HttpClientModule,
        PrimeNgModule,
        VirtualScrollerModule,
        ContextMenuModule,
        DragDropModule,
        GridsterModule,
        TabViewModule,
        AngularDraggableModule,
        GoogleMapsModule,
        NgxJsonViewerModule,
        AngularSplitModule.forRoot(),
        RouterModule.forRoot(routes, {
            useHash: false
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: AppMissingTranslationHandler
            }
        }),
        MaterialCssVarsModule.forRoot({
            isAutoContrast: true,
            darkThemeClass: 'isDarkTheme',
            lightThemeClass: 'isLightTheme',
        }),
        TourMatMenuModule.forRoot(),
    ],
    providers: [
        //provideHttpClient(withJsonpSupport()),
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipOptions },
        /*{ provide: OWL_DATE_TIME_LOCALE, useValue: 'de' },*/
        { provide: DateTimeAdapter, useClass: GADateTimeAdapter },
        { provide: OwlDateTimeIntl, useClass: GAIntl },
        SnotifyService,
        TreeDragDropService,
        APP_SERVICES,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        MW_SERVICES,
        MAC_SERVICES
    ],
    exports: [
        AppFrameworkComponent,
        APP_EXPORTS,
        APP_DIRECTIVES,
        APP_PIPES,
        MW_EXPORTS,
        APP_MULTIPANELS,
        APP_CONTROLS,
        ContextMenuModule,
        MAC_DIALOGS,
        MAC_SETTINGS,
        MAC_MENUTABS,
        MAC_TASKS,
        MAC_DIRECTIVES,
    ]
})
export class AppFrameworkModule {
    constructor(private injector: Injector, private dialog: MatDialog, private translate: TranslateService, private router: Router) {
        InjectorHelper.InjectorInstance = this.injector;
        DialogHelper.DialogInstance = this.dialog;
        TranslateHelper.TranslatorInstance = this.translate;
        DataTableTranslateHelper.TranslatorInstance = this.translate;
        RoutingHelper.Router = this.router;
    }
}
