export class ContainerTableHelper {
    static CheckTableAndFieldNames(containerTable): TableNameCheck {
        let retVal = new TableNameCheck();
        // TODO: Auf Tabellennamen und Feldnamen checken
        return retVal;
    }
}

export class TableNameCheck {
    InvalidTableName: boolean = false;
    InvalidColumnNames: string[] = [];
}