import { ClientHelper } from '../../helpers/client.helper';
import { LogStatus } from '../enums/logstatus.enum';
import { BreakPointInfo } from './workflow.debug.settings';

export class DebugLogMessage {
    ClientID = ClientHelper.ClientID;
    WorkflowID: string;
    ModuleID: string;
    Type: LogStatus;
    Message: string;
    Status: any;
    User: any;
    StackTrace: any;
    ScriptStack: any;
    Timestamp: Date;
}

export class DebugLogMessageList {
    List: DebugLogMessage[] = [];
}

export class DebugAttachInfo {
    ClientID: string;
    URL: string;
}

export class DebugSessionMessage {
    Sender = ClientHelper.ClientID;
    Target: string;
    Type: string;
    Content: any;
}

export class DebugSessionState {
    RunID: string;
    StepIntoRunID: string;
    ClientID: string;
    CanStepInto: boolean;
    CanStepOut: boolean;
    Status: any;
    Data: DebugSessionStateData;
}

export class DebugSessionStateData {
    DataType: DebugSessionStateDataType;
    Data: any;
}

export abstract class ADebugSessionData {
    WorkflowID: string;
    PageSID: string;
    ModuleID: number;
    Breakpoints: BreakPointInfo[] = [];
    AdditionalData: string;
}

export class WorkflowDebugSessionData extends ADebugSessionData {
    Start: boolean;
}

export enum DebugSessionStateDataType {
    Workflow,
    Module
}

export enum NextStep {
    Play,
    Next,
    Into,
    Out,
    Cancel
}

export class NextStepInfo {
    RunID: string;
    StepIntoRunID: string;
    NextStep: NextStep;
    Breakpoints: BreakPointInfo[] = [];
}
