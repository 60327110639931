import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { ContainerClassObject } from '../../../models/ContainerClassObject';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataService } from '../../../services/data.service';
import { ContainerEnumService, DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { OfflineService } from '../../../services/offline.service';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { ObjectPreviewDialog } from '../../../components/dialogs/objectpreview/object.preview.dialog';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { DataObjectFieldDialog } from './dataobject.field.dialog';
import { DataObjectTranslateDialog } from './dataobject.translate.dialog';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { TranslateHelper } from '../../../helpers/injector.helper';
//import { TranslateHelper } from '../../../public_api';

@Component({
    selector: 'dataobject-settings',
    templateUrl: '../../base.list.settings.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataObjectSettings extends BaseListSettings {

    constructor(private translate: TranslateService, private dataService: DataService,
        private dataModelService: DataModelService, private enumService: ContainerEnumService,
        private offlineService: OfflineService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = PermissionHelper.IsAdmin();
    }

    getContentType() {
        return DataObjectDetail;
    }

    loadList(handler) {
        this.Component.DataSources = [];
        this.Component.AllEnums = [];
        this.Component.AllTables = [];
        this.Component.AllStaticTables = [];
        if (this.InitArgs) {
            this.dataService.GetItem('dynamic', 'GetTablesByDataModel', this.InitArgs.SID).subscribe((data) => {
                if (data) {
                    const list = [];
                    data.forEach(table => {
                        list.push({
                            Caption: table.Caption,
                            ID: table.SID,
                            IsCapsule: table.IsCapsule,
                            IsOverridden: table.IsOverridden
                        });
                    });
                    handler(list);
                    this.Component.AllTables = list;
                }
            });
            const query = new DataSourceQuery(this.InitArgs.SID);
            query.DBUsages = [[16, 1048576], [32, 2097152]]; // [DocumentStoreRead or ObjectRead] and [DocumentStoreWrite or ObjectWrite]
            query.ResourceBase = 0;
            this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                this.Component.DataSources = sources;
            });
            this.enumService.GetEnumsByDataModel(this.InitArgs.SID).subscribe(enums => {
                if (enums) {
                    const allEnums = [];
                    enums.forEach(en => {
                        allEnums.push({
                            Caption: en.Caption,
                            Value: en.SID
                        });
                    });
                    this.Component.AllEnums = allEnums;
                }
            });
            this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((tables) => { 
                if (tables) {
                    
                    this.Component.AllStaticTables = tables;
                }
            });
        }
    }

    loadData(data) {
        this.dataService.GetListWithID('dynamic', 'GetTable', data).subscribe((result) => {
            if (result) {
                const cco = plainToClass(ContainerClassObject, result);
                this.setSelectedItem(cco);
            }
        });
    }

    getNewItem() {
        const newContainer = new ContainerClassObject();
        newContainer.DataModelID = this.InitArgs.SID;
        newContainer.Name = this.translate.instant('@@Neues Objekt');
        const comp = this.Component;
        if (comp && comp.DataSources && comp.DataSources.length > 0) {
            newContainer.DataSourceId = comp.DataSources[0].SID;
        }
        return newContainer;

    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Objekt \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Objekt \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Objekt loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Objekt \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Objekt speichern');
        return retVal;
    }

    delete(data, handler) {
        this.dataService.Delete('dynamic', data).subscribe((res) => {
            handler(res);
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const sel = this.Component.SelectedItem;
        if (sel) {
            const errors = [];
            if (!sel.Name) {
                errors.push('@@Bitte vergeben Sie einen Namen');
            }
            if (!sel.DataSourceId) {
                errors.push('@@Bitte setzen Sie eine Datenquelle');
            }

            if (sel.SID) {
                const check = await this.offlineService.Check(sel.SID, sel.StoreOffline).toPromise();
                if (check != null && check.length > 0) {
                    let error = '@@Bitte aendern sie die offline Einstellungen dieser Tabellen: ';
                    let i = 0;
                    check.forEach((item) => {
                        if (i > 0) {
                            error += ', ';
                        }
                        i++;
                        error += item.TranslatedCaption;
                    });
                    errors.push(error);
                }
            }
            if (errors.length > 0) {
                retVal.Error = errors.join('\n');
                retVal.IsCorrect = false;
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        // if (this.Component && this.Component.FieldChanged) {
        //    const text = '@@Sie haben ein bestehendes Feld des Datenobjekts geaendert. ' +
        //        'Daher werden beim Speichern saemtliche Eintraege der Datentabelle des Datenobjekts geloescht und in allen ' +
        //        'Datenobjekten, die dieses Datenobjekt als Unterobjekt verwenden, die entsprechenden Unterobjekte entfernt. ' +
        //        'Wollen Sie fortfahren?';
        //    MessageBoxHelper.ShowDialog(new TranslateFormatText(text), new TranslateFormatText('@@Warnung'),
        //        MessageBoxButtons.YesNo, MessageBoxIcon.Warning).then(x => {
        //            if (x === MessageBoxResult.Yes) {
        //                item.Fields.forEach((prop) => {
        //                    delete prop.AttributeType;
        //                });
        //                this.dataService.Save('dynamic', item).subscribe(result => {
        //                    if (result) {
        //                        handler(result, result.SID, result.Caption);
        //                    }
        //                });
        //            } else {
        //                LayoutService.Loading.next(false);
        //            }
        //        });
        // } else {
            item.Fields.forEach((prop) => {
                delete prop.AttributeType;
            });
            this.dataService.Save('dynamic', item).subscribe(result => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        // }
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'dataobject-detail',
    templateUrl: './dataobject.settings.html',
    styleUrls: ['./dataobject.settings.css']
})
export class DataObjectDetail extends ADataModelDetail {
    AllTypes = [];
    DataSources;
    AllTables = [];
    AllStaticTables = [];
    AllEnums = [];
    Rows = [];
    FieldChanged = false;

    constructor(private dataService: DataService, cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.dataService.GetList('dynamic', 'GetDynamicTypes').subscribe((data) => {
            if (data) {
                this.AllTypes = data;
            }
            this.AllTypes.push({ Caption: TranslateHelper.TranslatorInstance.instant('@@Static'), Value : '9DFCE24E-FCB8-4CD8-B724-EFB87810EE80' });
        });
   
    }

    setSelectedItem(item) {
        this.FieldChanged = false;
        const rows = [];
        if (item && item.Fields) {
            item.Fields.forEach(f => {
                const row = this.fillRow({}, f);
                rows.push(row);
            });
        }
        this.Rows = rows;
        super.setSelectedItem(item);
    }

    fillRow(row, f) {
        row.ID = f.ID;
        row.Name = f.Name;
        row.Caption = TranslatedString.GetTranslation(f.Caption);
        const type = this.AllTypes.find(x => x.Value === f.Type);
        if (type) {
            row.Type = type.Caption;
        } else {
            row.Type = f.Type;
        }
        row.Primary = f.IsPrimary ? 'done' : '';
        row.ReadOnly = f.IsReadOnly ? 'done' : '';
        row.Mandatory = f.IsMandatory ? 'done' : '';
        row.IsNullable = f.IsNullable ? 'done' : '';
        row.List = f.IsList ? 'done' : '';
        if (f.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c' || f.Type === '9DFCE24E-FCB8-4CD8-B724-EFB87810EE80') { // AttributeClass && STATIC
            const table = this.AllStaticTables.find(x => x.Value === f.AdvancedType);
            if (table) {
                row.Table = table.Caption;
               // row.Type = '9DFCE24E-FCB8-4CD8-B724-EFB87810EE80';
            } else {
                const table = this.AllTables.find(x => x.ID === f.AdvancedType);
                if (table) {
                    row.Table = table.Caption;
                } else {
                    row.Table = f.AdvancedType;
                } 
            }
        }  else if (f.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') { // Enum
            const table = this.AllEnums.find(x => x.Value === f.AdvancedType);
            if (table) {
                row.Table = table.Caption;
            } else {
                row.Table = f.AdvancedType;
            }
        } else {
            row.Table = '';
        }
        row.Shared = f.IsShared ? 'done' : '';
        row.Reverse = f.IsReverse ? 'done' : '';
        row.Default = f.Default;
        return row;
    }

    addField() {
        const selected = this.SelectedItem;
        if (selected) {
            this.showDialog(null, (r) => {
                selected.Fields.push(r);
                const row = this.fillRow({}, r);
                this.Rows.push(row);
                this.OnItemChanged();
            });
        }
    }

    translateFields() {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            DataObjectTranslateDialog.ShowDialog(selected, () => {
                this.FieldChanged = true;
                this.Rows.forEach(row => {
                    const field = selected.Fields.find(x => x.ID == row.ID);
                    if (field) {
                        row.Caption = TranslatedString.GetTranslation(field.Caption);
                    }
                });
                this.OnItemChanged();
            });
        }
    }

    editField(row) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex;
            let field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(field, (r) => {
                    this.fillRow(row, r);
                    this.FieldChanged = true;
                    selected.Fields.splice(fieldIndex, 1, r);
                    this.OnItemChanged();
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }

    showDialog(field, handler) {
        const args = {
            Field: field,
            AllTables: this.AllTables,
            AllStaticTables : this.AllStaticTables,
            AllEnums: this.AllEnums,
            AllTypes: this.AllTypes
        };
        BaseDialog.ShowDialog({
            ContentType: DataObjectFieldDialog,
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Feld definieren',
            InitArgs: args
        });
    }

    showPreview() {
        if (this.SelectedItem && this.SelectedItem.SID) {
            ObjectPreviewDialog.ShowContainerPreview(this.SelectedItem.SID, '@@Vorschau');
        }
    }
}
