import { AfterViewInit, ChangeDetectorRef, Component, Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { plainToClass, serialize, Type } from 'class-transformer';
import { BaseDialog } from '../../../../components/dialogs/basedialog/base.dialog';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { DataModelService } from '../../../../services/datamodel.service';
import { ExportService } from '../../../../services/export.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { ADatasourceSettings, DatasourceObjectSettingsData, DatasourceSettingsData } from '../datasource/datasource.settings';

@Directive()
export abstract class AExportSettings extends WorkflowDialogContent implements AfterViewInit {
    Data;
    ExportType: ExportType = ExportType.Export;
    Settings;
    ExportStatus;
    ExportColumns = [];
    MappedColumns = [];
    TargetColumns = [];
    IsAdHocTarget = false;
    IsDataExport = true;

    @ViewChild('target') targetComponent: ADatasourceSettings;

    constructor(protected service: ExportService, protected dataService: DataModelService, protected cdRef: ChangeDetectorRef) {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        const ds = this.ActualState.get('DataSource');
        if (ds) {
            this.ExportColumns = ds.Columns;
        }
        if (data) {
            this.Data = data;
            if (data.ExportType) {
                this.ExportType = data.ExportType;
            }
            switch (this.ExportType) {
                case ExportType.Export:
                    let expData = plainToClass(ExportSettings, data.Settings);
                    if (expData) {
                        if (expData.MappedColumns) {
                            const mappedCols = [];
                            expData.MappedColumns.forEach(mc => {
                                mappedCols.push({
                                    Source: {
                                        Name: mc.SourceField,
                                        DataTyp: mc.FieldType
                                    },
                                    Target: mc.TargetField,
                                    PossibleTargets: []
                                });
                            });
                            this.MappedColumns = mappedCols;
                        }
                    } else {
                        expData = new ExportSettings();
                    }
                    this.Settings = expData;
                    break;
                case ExportType.Connection:
                    const conData = plainToClass(ConnectionSettings, data.Settings);
                    if (conData) {
                        this.Settings = conData;
                    } else {
                        this.Settings = new ConnectionSettings();
                    }
                    break;
            }
            this.onTargetChanged(data.Target);
        }
    }

    protected abstract getResultInstance();

    getResult(): any {
        const retVal = this.getResultInstance();
        if (this.targetComponent) {
            retVal.Target = this.targetComponent.getResult();
        }
        retVal.ExportType = this.ExportType;
        if (this.Settings) {
            retVal.Settings = this.Settings;
            if (this.ExportType === ExportType.Export) {
                const columns = [];
                this.MappedColumns.forEach(mc => {
                    if (mc.Target) {
                        columns.push({
                            SourceField: mc.Source.Name,
                            TargetField: mc.Target,
                            FieldType: mc.Source.DataTyp
                        });
                    }
                });
                retVal.Settings.MappedColumns = columns;
            }
        }
        return retVal;
    }

    ngAfterViewInit(): void {
        if (this.targetComponent && this.Data) {
            this.targetComponent.initialize(this.Data.Target);
            this.cdRef.detectChanges();
        }
    }

    onTargetChanged(target) {
        const oldsettings = this.Settings;
        this.Settings = null;
        this.ExportStatus = null;
        const oldMap = this.MappedColumns;
        this.MappedColumns = [];
        this.TargetColumns = [];
        this.IsAdHocTarget = false;
        if (target) {
            if (target.UseTable) {
                if (target.ContainerTable) {
                    this.service.GetSupportsStatus(target.ContainerTable).subscribe((result) => {
                        this.setExportStatus(result, oldsettings);
                        if (this.Settings instanceof ExportSettings) {
                            this.updateMappedColumns(oldMap, target);
                        }
                    });
                }
            } else {
                if (target.DataSource && target.Settings && target.Settings.ContainerType) {
                    this.service.GetSupportStatusByPreview(target.DataSource, target.Settings).subscribe((result) => {
                        this.setExportStatus(result, oldsettings);
                        if (this.Settings instanceof ExportSettings) {
                            this.updateMappedColumns(oldMap, target);
                        }
                    });
                }
            }
        }
    }

    setExportStatus(result, oldsettings) {
        this.ExportStatus = result;
        if (oldsettings instanceof ExportSettings) {
            if (result.SupportsExports) {
                this.ExportType = ExportType.Export;
                this.Settings = oldsettings;
                return;
            }
        } else if (oldsettings instanceof ConnectionSettings) {
            if (result.SupportsConnectionExport) {
                this.ExportType = ExportType.Connection;
                this.Settings = oldsettings;
                return;
            }
        }
        switch (this.ExportType) {
            case ExportType.Export:
                if (result.SupportsExports) {
                    this.Settings = new ExportSettings();
                } else if (result.SupportsConnectionExport) {
                    this.ExportType = ExportType.Connection;
                    this.Settings = new ConnectionSettings();
                }
                break;
            case ExportType.Connection:
                if (result.SupportsConnectionExport) {
                    this.Settings = new ConnectionSettings();
                } else if (result.SupportsExports) {
                    this.ExportType = ExportType.Export;
                    this.Settings = new ExportSettings();
                }
                break;
        }
    }

    updateMappedColumns(mappedCols, target) {
        if (target) {
            if (target.UseTable) {
                if (target.ContainerTable) {
                    this.dataService.GetContainer(target.ContainerTable).subscribe((result) => {
                        if (result && result.Fields && result.Fields.length > 0) {
                            this.TargetColumns = result.Fields;
                            const mcList = [];
                            const ec = this.ExportColumns;
                            mappedCols.forEach(mc => {
                                if (mc.Source && mc.Target) {
                                    ec.some(expC => {
                                        if (expC.Name === mc.Source.Name && expC.DataTyp === mc.Source.DataTyp) {
                                            return result.Fields.some(resField => {
                                                if (resField.Name === mc.Target && resField.DataTyp === mc.Source.DataTyp) {
                                                    const mapInfo = {
                                                        Source: expC,
                                                        Target: resField.Name,
                                                        PossibleTargets: []
                                                    };
                                                    result.Fields.forEach(field => {
                                                        if (field.DataTyp === expC.DataTyp) {
                                                            mapInfo.PossibleTargets.push({ Name: field.Name });
                                                        }
                                                    });
                                                    mcList.push(mapInfo);
                                                    return true;
                                                }
                                                return false;
                                            });
                                        }
                                        return false;
                                    });
                                }
                            });
                            this.MappedColumns = mcList;
                        }
                    });
                }
            } else if (target.DataSource && target.Settings) {
                this.IsAdHocTarget = true;
                const mcList = [];
                const ec = this.ExportColumns;
                mappedCols.forEach(mc => {
                    if (mc.Source && mc.Target) {
                        ec.some(expC => {
                            if (expC.Name === mc.Source.Name) {
                                const mapInfo = {
                                    Source: expC,
                                    Target: mc.Target,
                                    PossibleTargets: []
                                };
                                mcList.push(mapInfo);
                                return true;
                            }
                            return false;
                        });
                    }
                });
                this.MappedColumns = mcList;
            }
        }
    }

    exportTypeChanged(ev) {
        switch (this.ExportType) {
            case ExportType.Export:
                this.Settings = new ExportSettings();
                break;
            case ExportType.Connection:
                this.Settings = new ConnectionSettings();
                break;
        }
    }

    addMappingColumns() {
        const cols = [];
        const mc = this.MappedColumns;
        if (this.ExportColumns) {
            this.ExportColumns.forEach(col => {
                if (!mc || !mc.some(m => m.Source === col)) {
                    cols.push({
                        Caption: col.Name + ' (' + col.DataTyp + ')',
                        Column: col,
                        Selected: false
                    });
                }
            });
        }
        const tc = this.TargetColumns;
        const adHoc = this.IsAdHocTarget;
        BaseDialog.ShowDialog({
            ContentType: ColumnDialog,
            InitArgs: cols,
            Title: '@@Spalten hinzufuegen',
            Handler: result => {
                if (result) {
                    result.forEach(r => {
                        const mapInfo = {
                            Source: r.Column,
                            Target: null,
                            PossibleTargets: []
                        };
                        if (adHoc) {
                            mapInfo.Target = r.Column.Name;
                        } else {
                            tc.forEach(field => {
                                if (field.DataTyp === r.Column.DataTyp) {
                                    mapInfo.PossibleTargets.push({ Name: field.Name });
                                }
                            });
                            if (mapInfo.PossibleTargets.length > 0) {
                                mapInfo.Target = mapInfo.PossibleTargets[0];
                            }
                        }
                        mc.push(mapInfo);
                    });
                    this.cdRef.detectChanges();
                }
                return true;
            }
        });
    }

    removeMappingColumn(i) {
        this.MappedColumns.splice(i, 1);
    }

    GetExpressionProperties() {
        const retVal = [];
        if (this.targetComponent && this.targetComponent.UseTable) {
            retVal.push({
                Caption: TranslateHelper.TranslatorInstance.instant('@@Datasource'),
                Value: 'Target.DataSource'
            });
            retVal.push({
                Caption: TranslateHelper.TranslatorInstance.instant('@@Container'),
                Value: 'Target.ContainerTable'
            });
        } else {
            retVal.push({
                Caption: TranslateHelper.TranslatorInstance.instant('@@Resource'),
                Value: 'Target.Settings.Resource'
            });
        }
        retVal.push({
            Caption: TranslateHelper.TranslatorInstance.instant('@@Settings'),
            Value: 'Settings'
        });
        return retVal;
    }
}

@Component({
    selector: 'wf-exportdata-settings',
    templateUrl: './exportdata.settings.html',
    styleUrls: ['./exportdata.settings.css']
})
export class ExportDataSettings extends AExportSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'exportDataWFModule';
        reg.Caption = '@@Daten exportieren';
        reg.GroupID = 'reldataoperations';
        reg.Index = 20;
        reg.SettingsControl = ExportDataSettings;
        reg.SettingsTypeHelper = new ExportDataSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(protected service: ExportService, protected dataService: DataModelService, protected cdRef: ChangeDetectorRef) {
        super(service, dataService, cdRef);
    }

    protected getResultInstance() {
        return new ExportDataSettingsData();
    }
}

@Component({
    selector: 'wf-exportobject-settings',
    templateUrl: './exportdata.settings.html',
    styleUrls: ['./exportdata.settings.css']
})
export class ExportObjectSettings extends AExportSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'exportObjectWFModule';
        reg.Caption = '@@Objekte exportieren';
        reg.GroupID = 'objectoperations';
        reg.Index = 20;
        reg.SettingsControl = ExportObjectSettings;
        reg.SettingsTypeHelper = new ExportObjectSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(protected service: ExportService, protected dataService: DataModelService, protected cdRef: ChangeDetectorRef) {
        super(service, dataService, cdRef);
        this.IsDataExport = false;
    }

    protected getResultInstance() {
        return new ExportObjectSettingsData();
    }
}

@Component({
    selector: 'wf-exportdata-column-dialog',
    templateUrl: './exportdata.column.dialog.html',
    styleUrls: ['./exportdata.settings.css']
})
export class ColumnDialog {
    ColumnsValue = [];

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        this.ColumnsChange.emit(val);
    }
    @Output() ColumnsChange = new EventEmitter<any>();


    Initialize(args) {
        if (args) {
            this.ColumnsValue = args;
        }
    }

    GetDialogResult() {
        const retVal = [];
        this.ColumnsValue.forEach(x => {
            if (x.Selected) {
                retVal.push(x);
            }
        });
        return retVal;
    }

    selectAll(ev) {
        if (ev) {
            this.ColumnsValue.forEach(x => {
                x.Selected = ev.checked;
            });
        }
    }
}

export abstract class AExportSettingsData {
    ExportType: ExportType = ExportType.Export;
    Settings;

    ExtendedSerialize(module) {
        const tmpSettings = Object.assign({}, this);
        if (tmpSettings.Settings) {
            tmpSettings.Settings = JSON.stringify(tmpSettings.Settings);
        }
        module.SettingsType = this.getTypeName();
        module.Settings = serialize(tmpSettings);
    }

    ExtendedDeserialize() {
        if (this.Settings) {
            const deserializedSettings = JSON.parse(this.Settings);
            switch (this.ExportType) {
                case ExportType.Export:
                    this.Settings = plainToClass(ExportSettings, deserializedSettings);
                    break;
                case ExportType.Connection:
                    this.Settings = plainToClass(ConnectionSettings, deserializedSettings);
                    break;
            }
        }
    }

    protected abstract getTypeName();
}

// @dynamic
export class ExportDataSettingsData extends AExportSettingsData {
    @Type(() => DatasourceSettingsData)
    Target: DatasourceSettingsData;

    protected getTypeName() {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ExportData.ExportDataSettingsData';
    }
}

// @dynamic
export class ExportObjectSettingsData extends AExportSettingsData {
    @Type(() => DatasourceObjectSettingsData)
    Target: DatasourceObjectSettingsData;

    protected getTypeName() {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ExportData.ExportObjectSettingsData';
    }
}

export class ExportSettings {
    EnableBlockModus = false;
    TriggerEnabled = false;
    BlockLines = 0;
    MappedColumns = [];
}

export class ConnectionSettings {
    Sql: string;
}

export enum ExportType {
    Export,
    Connection
}

export abstract class AExportSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.ID = 0;
        def.Type = this.getEntryPointType();
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }

    abstract getEntryPointType(): string;

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class ExportDataSettingsDataHelper extends AExportSettingsDataHelper {
    getEntryPointType(): string {
        return 'relData';
    }
    getEmptySettingsInstance() {
        return new ExportDataSettingsData();
    }
}

export class ExportObjectSettingsDataHelper extends AExportSettingsDataHelper {
    getEntryPointType(): string {
        return 'objData';
    }
    getEmptySettingsInstance() {
        return new ExportObjectSettingsData();
    }
}
