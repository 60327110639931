import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export class ToLower extends AFormula {
    static StaticID: string = '315fe230-e16a-4e19-8163-e1b4a8e6cd80';

    ID(): string {
        return ToLower.StaticID;
    }

    Name(): string {
        return 'ToLower';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Zeichenkette alles klein schreiben');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1001;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Text') + '; ' + TranslateHelper.TranslatorInstance.instant('@@CodeDescription') + ')';
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        var text = '';
        if (args && args.length > 0) {
            if (typeof args[0].Value === 'string' && args[0].Value != null) {
                text = args[0].Value;
            }
        }
        retVal.Value = text.toLowerCase();
        return retVal;
    }
}