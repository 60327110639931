import { RelFilterColumn } from "./relfiltercolumn.model";
import { RelSortColumn } from "./relsortcolumn.model";
import { RelRequestColumn } from "./relrequestcolumn.model";
import { RelColumn } from "./relcolumn.model";
import { RelationDefinition } from "./relationdefinition.model";

export class RelQueryDef
{
  public AdditionalCount: number = -1;
  public Filters: RelFilterColumn[];
  public Sort: RelSortColumn[];
  public Columns: RelRequestColumn[];
  public AdditionalColumns: RelColumn[];
  public StartRow: number;
  public EndRow: number;
  public Top: number;
  public Relations: RelationDefinition[];
}
