import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlStyle } from '../../models/enums/controlstyle.enum';

// @dynamic
@Directive()
export abstract class BaseThemeControl implements OnDestroy, AfterViewInit {
    //#region ID
    ControlIDDValue;
    @Input()
    get ControlID() {
        return this.ControlIDDValue;
    }
    set ControlID(val) {
        this.ControlIDDValue = val;
        this.ControlIDChange.emit(this.ControlIDDValue);
    }
    @Output() ControlIDChange = new EventEmitter<any>();
    //#endregion

    //#region ControlStyle
    ControlStyleValue: ControlStyle = ControlStyle.Bootstrap;
    @Input()
    get ControlStyle(): ControlStyle {
        return this.ControlStyleValue;
    }
    set ControlStyle(val: ControlStyle) {
        this.ControlStyleValue = val;
        this.ControlStyleChange.emit(this.ControlStyleValue);
    }
    @Output() ControlStyleChange = new EventEmitter<ControlStyle>();
    //#endregion

    //#region ExternalStyle
    ExternalStyleValue;
    @Input()
    get ExternalStyle() {
        return this.ExternalStyleValue;
    }
    set ExternalStyle(val) {
        this.ExternalStyleValue = val;
        this.ExternalStyleChange.emit(this.ExternalStyleValue);
    }
    @Output() ExternalStyleChange = new EventEmitter<any>();
    //#endregion


    //#region Placeholder
    PlaceholderValue: string;
    @Input()
    get Placeholder(): string {
        return this.PlaceholderValue;
    }
    set Placeholder(val: string) {
        this.PlaceholderValue = val;
        this.PlaceholderChange.emit(this.PlaceholderValue);
    }
    @Output() PlaceholderChange = new EventEmitter<string>();
    //#endregion
    //#region PathBinding
    PathBindingValue: string;
    @Input()
    get PathBinding(): string {
        return this.PathBindingValue;
    }
    set PathBinding(val: string) {
        this.PathBindingValue = val;
        this.PathBindingChange.emit(this.PathBindingValue);
        this.cdRef.detectChanges();
    }
    @Output() PathBindingChange = new EventEmitter<string>();
    //#endregion
    //#region DataSource
    DataSourceValue;
    @Input()
    get DataSource() {
        if (this.PathBindingValue) {
            return this.SelectedItemValue[this.PathBindingValue];
        }
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (this.PathBindingValue) {
            this.SelectedItemValue[this.PathBindingValue] = val;
        } else {
            this.DataSourceValue = val;
        }
        this.DataSourceChange.emit(this.DataSource);
        this.DataSourceInternalChange.emit(this.DataSource);
        this.cdRef.detectChanges();
    }
    @Output() DataSourceChange = new EventEmitter<any>();

    @Input()
    get DataSourceInternal() {
        if (this.PathBindingValue) {
            return this.SelectedItemValue[this.PathBindingValue];
        }
        return this.DataSourceValue;
    }
    set DataSourceInternal(val) {
        if (this.PathBindingValue) {
            this.SelectedItemValue[this.PathBindingValue] = val;
        } else {
            this.DataSourceValue = val;
        }
        this.DataSourceChange.emit(this.DataSource);
        this.DataSourceInternalChange.emit(this.DataSource);
        this.StyleChanged.emit();
        this.cdRef.detectChanges();
    }
    @Output() DataSourceInternalChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
        this.cdRef.detectChanges();
    }
    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region TabIndex
    TabIndexValue: number;
    @Input()
    get TabIndex(): number {
        return this.TabIndexValue;
    }
    set TabIndex(val: number) {
        this.TabIndexValue = val;
        this.TabIndexChange.emit(this.TabIndexValue);
    }
    @Output() TabIndexChange = new EventEmitter<number>();
    //#endregion

    //#region Disabled
    DisabledValue = false;
    @Input()
    get Disabled(): boolean {
        return this.DisabledValue;
    }
    set Disabled(val: boolean) {
        this.DisabledValue = val;
        this.DisabledChange.emit(this.DisabledValue);
        this.cdRef.detectChanges();
    }
    @Output() DisabledChange = new EventEmitter<boolean>();
    //#endregion

    //#region Required
    RequiredValue = false;
    @Input()
    get Required(): boolean {
        return this.RequiredValue;
    }
    set Required(val: boolean) {
        this.RequiredValue = val;
        this.RequiredChange.emit(this.RequiredValue);
    }
    @Output() RequiredChange = new EventEmitter<boolean>();
    //#endregion

    //#region Tooltip
    TooltipValue: string;
    @Input()
    get Tooltip(): string {
        return this.TooltipValue;
    }
    set Tooltip(val: string) {
        this.TooltipValue = val;
        this.TooltipChange.emit(this.TooltipValue);
    }
    @Output() TooltipChange = new EventEmitter<string>();
    //#endregion
    Subscriptions = {}
    ViewInit = false;
    cdRef: ChangeDetectorRef;
    constructor(cdRef: ChangeDetectorRef) {
        this.cdRef = cdRef;
    }
    ngAfterViewInit(): void {
        this.ViewInit = true;
    }
    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            if (this.Subscriptions[key].unsubscribe) {
                this.Subscriptions[key].unsubscribe();
            }
        });
    }

    @Output() StyleChanged = new EventEmitter<any>();
    onStyleChanged() {
        this.StyleChanged.emit();
        this.cdRef.detectChanges();
    }

    @Output() LayoutPropertyChanged = new EventEmitter<any>();
    onLayoutPropertyChanged(event) {
        this.LayoutPropertyChanged.emit();
        this.cdRef.detectChanges();
    }

    SetProperties(props) {
        if (props) {
            let keys = Object.keys(props);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                this[key] = props[key];
            }
        }
    }

    get BaseStyle() {
        return {
            'height': '33px',
            'line-height': '13px',
            'font-size': '13px'
        }
    }

    ExternalSubscriptions;
    Action;
    EmitAction(ev) {
        if (this.Action) {
            this.Action({
                Event: ev,
                SelectedItem: this.SelectedItemValue,
                DataSource: this.DataSource,
                Source: this
            });
        }
    }
}
