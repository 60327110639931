import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { ConditionalMeasureTaskExecuter } from '../../../../models/tasks/formattasks/conditionalmeasure.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { MultiReportingService } from '../../../../services/reporting.service';
import { AMeasureTaskSettingsHelper } from '../../a.task.panel';
import { AConditionalFormattingTaskPanel } from './aconditional.formatting.task.panel';

@Component({
    selector: 'conditional-formatting-task-panel',
    templateUrl: './conditional.formatting.task.panel.html'
})
export class MeasureConditionalFormattingTaskPanel extends AConditionalFormattingTaskPanel {
    StyleVal = {};
   
    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = ConditionalMeasureTaskExecuter.TaskID;
        reg.Caption = '@@Bedingte Wertformatierung';
        reg.SettingsControl = MeasureConditionalFormattingTaskPanel;
        reg.SettingsHelper = new MeasureConditionalTaskSettingsHelper();
        reg.Executer = ConditionalMeasureTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    TaskTypeIDValue = ConditionalMeasureTaskExecuter.TaskID;
    @Input()
    get TaskTypeID() {
        return this.TaskTypeIDValue;
    }
    set TaskTypeID(val) {
        this.TaskTypeIDValue = val;
    }

    constructor(public service: MultiReportingService, public cdRef: ChangeDetectorRef) {
        super(service, cdRef);
    }
}

export class MeasureConditionalTaskSettingsHelper extends AMeasureTaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsTableObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        let caption = super.getCaption(task, dd);
        if (caption) {
            caption += '-' + TranslateHelper.TranslatorInstance.instant('@@Bedingt');
            return caption;
        }
        return TranslateHelper.TranslatorInstance.instant('@@Bedingte Wertformatierung');
    }
}
