import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowViewType } from '../../../models/enums/workflowviewtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'start-wf-settings',
    templateUrl: './start.settings.html'
})
export class StartWfModuleSettings extends FormulaWorkflowDialogContent {
    Data = new StartWfModuleData();

    static ModuleID = 'startWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = StartWfModuleSettings.ModuleID;
        reg.GroupID = '-1';
        reg.Caption = '@@Start';
        reg.SettingsControl = StartWfModuleSettings;
        reg.SettingsTypeHelper = new StartWfModuleHelper();
        reg.Executer = StartWfModuleExecuter;
        reg.ViewType = WorkflowViewType.Start;
        reg.Width = 50;
        reg.Height = 50;
        return reg;
    }

    ngOnInit(): void {
        
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(StartWfModuleData, json);
            if (this.Data && this.Data.StatusKey == null) {
                this.Data.StatusKey = 'event';
            }
        }
    }

    getResult() {
        return this.Data;
    }
}

export class StartWfModuleData extends AServiceWorkflowData {
    StatusKey: string = "event";
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.StartWfModuleData';
    }
}

export class StartWfModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (!status.ActualSettings.StatusKey) {
                status.Context.set("event", status.ActualParameter);
            } else {
                status.Context.set(status.ActualSettings.StatusKey, status.ActualParameter);
            }
            return 0;
        } else {
            status.Context.set("event", status.ActualParameter);
        }
        
        return 0;
    }
}

export class StartWfModuleHelper extends WorkflowModuleSettingsHelper {
   
    getEntryPoints(): WorkflowExitInfo[] {
        return [];
    }
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new StartWfModuleData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}
