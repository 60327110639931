import { CacheService } from '../../cache/cache.service';
import { AdvancedFormulaParser } from '../../helpers/formula.parser';
import { TranslateHelper } from '../../helpers/injector.helper';
import { FormulaNodeInformation, Operation, ValueAndType } from '../basic/formulaEditor.model';
import { BinaryOperation } from '../enums/binaryoperation.enum';
import { CompareType } from '../enums/comparetype.enum';
import { FormulaInputMode } from '../enums/formulainputmode.enum';
import { FormulaNodeType } from '../enums/formulanodetype.enum';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { Variable } from '../variable.model';
import { Addition } from './addition';
import { And } from './and';
import { Division } from './division';
import { Equal } from './equal';
import { FormulaRegistry } from './formula.registry';
import { Greater } from './greater';
import { GreaterEqual } from './greaterequal';
import { Multiplication } from './multiplication';
import { NotEqual } from './notequal';
import { Or } from './or';
import { Smaller } from './smaller';
import { SmallerEqual } from './smallerequal';
import { Subtraction } from './subtraction';

export abstract class FormulaNodeCalculator {
    protected _NullAs0: boolean;
    protected _SystemVariableList: Variable[] = [];
    private _SystemVariableDict: Map<string, ValueAndType> = new Map();
    protected _GlobalVariableList: Variable[] = [];
    private _GlobalVariableDict: Map<string, ValueAndType> = new Map();
    private _VariableNotFound: Set<string> = new Set<string>();

    static GetVariableValue(variable: Variable, nullAs0: boolean): ValueAndType {
        const retVal = new ValueAndType();
        if (variable) {
            if (typeof variable.Value !== 'undefined' && variable.Value !== null) {
                retVal.Type = ValueAndType.GetValueTypeFromType(variable.Type);
                retVal.Value = variable.Value;
            } else if (variable.Formula) {
                const parser = new AdvancedFormulaParser();
                parser.SetVariables([], FormulaInputMode.AliasKey);
                const fni = parser.Parse(variable.Formula);
                const calculator = new ValueFormulaNodeCalculator(nullAs0, [], new Map());
                const tmp = calculator.Calc(fni);
                if (tmp) {
                    retVal.Type = tmp.Type;
                    retVal.Value = tmp.Value;
                }
            }
        }
        return retVal;
    }

    constructor(nullAs0: boolean, variableList: Variable[]) {
        this._NullAs0 = nullAs0;
        this._SystemVariableList = variableList ? variableList : [];
        const globalvarableList = CacheService.GlobalVariablesValues.getValue();
        if (globalvarableList) {
            this._GlobalVariableList = globalvarableList;
        }
    }

    protected abstract GetVariableValue(item: FormulaNodeInformation): ValueAndType;

    Calc(node: FormulaNodeInformation): ValueAndType {
        let retVal = new ValueAndType();

        if (node.Operation.OperationType === OperationType.None) {
            retVal = this.GetValue(node);
        } else {
            retVal = this.CalcOperation(node.Operation, node.Operands);
        }
        this.CheckNegative(node, retVal);

        return retVal;
    }

    protected GetValue(item: FormulaNodeInformation): ValueAndType {
        switch (item.Type) {
            case FormulaNodeType.Value:
                return {
                    Type: ValueType.Double,
                    Value: item.Value
                };
            case FormulaNodeType.TextValue:
                return {
                    Type: ValueType.String,
                    Value: item.TextValue
                };
            case FormulaNodeType.DateValue:
                return {
                    Type: ValueType.Datetime,
                    Value: item.DateValue
                };
            case FormulaNodeType.BoolValue:
                return {
                    Type: ValueType.Bool,
                    Value: item.BoolValue
                };
            case FormulaNodeType.Operation:
                return this.CalcOperation(item.Operation, item.Operands);
            case FormulaNodeType.Variable:
                return this.GetVariableValue(item);
            case FormulaNodeType.None:
                return {
                    Type: ValueType.String,
                    Value: TranslateHelper.TranslatorInstance.instant('@@n. def.')
                };
            case FormulaNodeType.Placeholder:
                throw TranslateHelper.TranslatorInstance.instant('@@Formel enhaelt noch Platzhalter');
            case FormulaNodeType.SystemVariable:
                if (item.VariableID && item.VariableID.length > 0) {
                    let id = item.VariableID;
                    if (id[0] === '@') {
                        id = id.substring(1);
                    }
                    if (!this._VariableNotFound.has(id)) {
                        let tmp = this._SystemVariableDict.get(id);
                        if (!tmp) {
                            const sysVar = this._SystemVariableList.find(x => x.Name === id);
                            if (sysVar) {
                                tmp = FormulaNodeCalculator.GetVariableValue(sysVar, this._NullAs0);
                                this._SystemVariableDict.set(id, tmp);
                            } else {
                                tmp = this._GlobalVariableDict.get(id);
                                if (!tmp) {
                                    const globVar = this._GlobalVariableList.find(x => x.Name === id);
                                    if (globVar) {
                                        tmp = FormulaNodeCalculator.GetVariableValue(globVar, this._NullAs0);
                                        this._GlobalVariableDict.set(id, tmp);
                                    }
                                }
                            }
                        }
                        if (tmp) {
                            return {
                                Type: tmp.Type,
                                Value: tmp.Value
                            };
                        } else {
                            this._VariableNotFound.add(id);
                        }
                    }
                }
                break;
        }
        return null;
    }

    protected CalcOperation(fo: Operation, nodes: FormulaNodeInformation[]): ValueAndType {
        switch (fo.OperationType) {
            case OperationType.Addition:
                return this.CalcFormula(Addition.StaticID, nodes);
            case OperationType.Subtraktion:
                return this.CalcFormula(Subtraction.StaticID, nodes);
            case OperationType.Multiplication:
                return this.CalcFormula(Multiplication.StaticID, nodes);
            case OperationType.Division:
                return this.CalcFormula(Division.StaticID, nodes);
            case OperationType.Function:
                return this.CalcFormula(fo.FunctionType, nodes);
            case OperationType.Compare:
                switch (fo.CompareType) {
                    case CompareType.Greater:
                        return this.CalcFormula(Greater.StaticID, nodes);
                    case CompareType.Smaller:
                        return this.CalcFormula(Smaller.StaticID, nodes);
                    case CompareType.Equal:
                        return this.CalcFormula(Equal.StaticID, nodes);
                    case CompareType.GreaterEqual:
                        return this.CalcFormula(GreaterEqual.StaticID, nodes);
                    case CompareType.SmallerEqual:
                        return this.CalcFormula(SmallerEqual.StaticID, nodes);
                    case CompareType.NotEqual:
                        return this.CalcFormula(NotEqual.StaticID, nodes);
                }
                break;
            case OperationType.Binary:
                switch (fo.BinaryOperation) {
                    case BinaryOperation.And:
                        return this.CalcFormula(And.StaticID, nodes);
                    case BinaryOperation.Or:
                        return this.CalcFormula(Or.StaticID, nodes);
                }
                break;
        }
        return new ValueAndType();
    }

    protected CheckNegative(node: FormulaNodeInformation, vat: ValueAndType) {
        if (vat && node.SignIsNegative && (vat.Type === ValueType.Long || vat.Type === ValueType.Double)) {
            vat.Value = -vat.Value;
        }
    }

    private CalcFormula(guid: string, nodes: FormulaNodeInformation[]): ValueAndType {
        let retVal = new ValueAndType();
        const formula = FormulaRegistry.get(guid);
        if (nodes && nodes.length >= formula.MinVariableCount() && nodes.length <= formula.MaxVariableCount()) {
            const breakOnNull = !this._NullAs0 && !formula.CanCalcNulls();
            const values: ValueAndType[] = [];
            let maxType = ValueType.Null;
            if (!nodes.some(node => {
                let vat = this.GetValue(node);
                if (vat == null) {
                    if (breakOnNull) {
                        return true;
                    } else {
                        vat = {
                            Type: ValueType.Double,
                            Value: 0
                        };
                    }
                } else {
                    this.CheckNegative(node, vat);
                }
                if (vat.Type === ValueType.Range) {
                    if (Array.isArray(vat.Value)) {
                        vat.Value.forEach(v => {
                            if (maxType < v.Type) {
                                maxType = v.Type;
                            }
                        });
                    }
                } else if (maxType < vat.Type) {
                    maxType = vat.Type;
                }
                values.push(vat);
                return false;
            })) {
                retVal = formula.Calc(this, maxType, values);
            }
        }
        return retVal;
    }
}

export class ValueFormulaNodeCalculator extends FormulaNodeCalculator {
    private _Values: Map<string, ValueAndType>;

    constructor(nullAs0: boolean, variableList: Variable[], values: Map<string, ValueAndType>) {
        super(nullAs0, variableList);
        this.ReplaceNulls(values);
        this._Values = values;
    }

    private ReplaceNulls(values: Map<string, ValueAndType>) {
        if (values) {
            if (this._NullAs0) {
                const replaceList = [];
                values.forEach((v, k) => {
                    if (v) {
                        if ((typeof v.Value === 'undefined' || v.Value === null) &&
                            (v.Type === ValueType.Double || v.Type === ValueType.Long)) {
                            v.Value = 0;
                        }
                    } else {
                        replaceList.push(k);
                    }
                });
                replaceList.forEach(k => {
                    const vt = new ValueAndType();
                    vt.Type = ValueType.Double;
                    vt.Value = 0;
                    values.set(k, vt);
                });
            }
        }
    }

    protected GetVariableValue(item: FormulaNodeInformation): ValueAndType {
        const tmp = this._Values.get(item.VariableID);
        if (tmp) {
            return {
                Type: tmp.Type,
                Value: tmp.Value
            };
        }
        return null;
    }

    AddValue(key: string, val: ValueAndType) {
        if (this._NullAs0) {
            if (val) {
                if ((typeof val.Value === 'undefined' || val.Value === null) &&
                    (val.Type === ValueType.Double || val.Type === ValueType.Long)) {
                    val.Value = 0;
                }
            } else {
                val = new ValueAndType();
                val.Type = ValueType.Double;
                val.Value = 0;
            }
        }
        this._Values.set(key, val);
    }
}

export class NumericFormulaNodeCalculator extends FormulaNodeCalculator {

    private _ValuesSelfService: number[];

    constructor(nullAs0: boolean, variableList: Variable[], values: number[]) {
        super(nullAs0, variableList);
        this._ValuesSelfService = values;
    }

    protected GetVariableValue(item: FormulaNodeInformation): ValueAndType {
        if (this._ValuesSelfService && item.DirectVariablePosition && item.DirectVariablePosition > 0 &&
            item.DirectVariablePosition < this._ValuesSelfService.length) {
            const val = this._ValuesSelfService[item.DirectVariablePosition];
            if (typeof val === 'number' && !Number.isNaN(val)) {
                return {
                    Type: ValueType.Double,
                    Value: val
                };
            }
        }
        return null;
    }

    Calc2(node: FormulaNodeInformation): number {
        const retVal = this.Calc(node);
        if (typeof retVal.Value === 'number') {
            return retVal.Value;
        }
        return Number.NaN;
    }
}
