import { deserialize, plainToClass, serialize } from 'class-transformer';
import { QueryExecuter } from '../../components/query/executer.query';
import { MultiResultHelper } from '../../helpers/multiresult.helper';
import { TaskRegistry } from '../../helpers/task.registry';
import { Axis, DataDescription } from '../datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../datadescription/multi/multiresult.model';
import { VisibilityType } from '../enums/oc.enum';
import { AxisType } from '../enums/query.enum';
import { ChartStyleMerger, SeriesValueStyle } from '../styling/chart.style.merger';
import { ITaskExecuter } from '../tasks/atask.model';
import { ChartSeriesResult, RowInfo, SeriesInfo, SeriesValueInfo } from '../tasks/chartformattasks/chart.format.model';
import { ChartDataSource } from './chart.datasource';
import { PageStatus } from '../page.status';
import { AxisTick, ChartSettings, SeriesSettings } from '../controls/chart.model';
import { ChartType } from '../enums/charttype.enum';
import { CacheService } from '../../cache/cache.service';
import { ThemeHelper } from '../../helpers/theme.helpers';
import { BubbleRow, BubbleRowData, MultidimensionalChartKeys } from '../controls/chart.data.model';

export class MultidimensionalChartDataSource extends ChartDataSource {   

    DataDescription: DataDescription;
    QueryID;
    LastMatrix: MultiResult;
    LastDataDescription: DataDescription;

    constructor(protected reportObject) {
        super(reportObject);
        if (reportObject && reportObject.Data) {
            this.QueryID = reportObject.Data.ID;
            this.DataDescription = plainToClass(DataDescription, reportObject.Data.Datadescription);
        }
    }

    private static GetSeries(result: MultiResult, dataDescription: DataDescription): ChartSeriesResult {
        const seriesList: SeriesInfo[] = [];
        const noLevelIndex = {
            Index: 0
        };
        dataDescription.YLevelNodes.Areas.forEach(a => {
            const areaNodes = MultiResultHelper.GetNodesFromArea(a, result.YAxis.Nodes, noLevelIndex);
            const mNodes = [];
            if (a.Measures && a.Measures.length > 0) {
                a.Measures.forEach(m => {
                    if (m.VisibilityType !== VisibilityType.NonVisibleDefault && m.VisibilityType !== VisibilityType.NonVisibleForce) {
                        const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                        if (mNode) {
                            mNodes.push(mNode);
                        }
                    }
                });
            }
            areaNodes.Nodes.forEach(n => {
                if (n.Visible !== false) {
                    if (areaNodes.Levels.length > 1) {
                        MultiResultHelper.MergeLevels([n], areaNodes.Levels.length, (nodeList) => {
                            const captions = [];
                            nodeList.forEach(nl => {
                                captions.push(nl.Caption);
                            });
                            const lastNode = nodeList[nodeList.length - 1];
                            if (mNodes.length > 0) {
                                mNodes.forEach(mNode => {
                                    seriesList.push(new SeriesInfo({
                                        Caption: [...captions, mNode.Caption].join(' - '),
                                        Key: lastNode.Position + '_' + mNode.Position,
                                        AxisNode: lastNode,
                                        MeasureNode: mNode
                                    }));
                                });
                            } else {
                                seriesList.push(new SeriesInfo({
                                    Caption: captions.join(' - '),
                                    Key: '' + lastNode.Position,
                                    AxisNode: lastNode
                                }));
                            }
                        });
                    } else {
                        if (mNodes.length > 0) {
                            mNodes.forEach(mNode => {
                                seriesList.push(new SeriesInfo({
                                    Caption: n.UniqueID === -1 ? mNode.Caption : n.Caption + ' - ' + mNode.Caption,
                                    Key: n.Position + '_' + mNode.Position,
                                    AxisNode: n,
                                    MeasureNode: mNode
                                }));
                            });
                        } else {
                            seriesList.push(new SeriesInfo({
                                Caption: n.Caption,
                                Key: '' + n.Position,
                                AxisNode: n
                            }));
                        }
                    }
                }
            });
        });

        const retVal = new ChartSeriesResult();
        if (seriesList.length > 0) {
            noLevelIndex.Index = 0;
            dataDescription.XLevelNodes.Areas.forEach(a => {
                const areaNodes = MultiResultHelper.GetNodesFromArea(a, result.XAxis.Nodes, noLevelIndex);
                const mNodes = [];
                if (a.Measures && a.Measures.length > 0) {
                    a.Measures.forEach(m => {
                        if (m.VisibilityType !== VisibilityType.NonVisibleDefault && m.VisibilityType !== VisibilityType.NonVisibleForce) {
                            const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                            if (mNode) {
                                mNodes.push(mNode);
                            }
                        }
                    });
                }
                areaNodes.Nodes.forEach(opp => {
                    if (opp.Visible !== false) {
                        if (areaNodes.Levels.length > 1) {
                            MultiResultHelper.MergeLevels([opp], areaNodes.Levels.length, (nodeList) => {
                                const captions = [];
                                nodeList.forEach(nl => {
                                    captions.push(nl.Caption);
                                });
                                const lastNode = nodeList[nodeList.length - 1];
                                if (mNodes.length > 0) {
                                    mNodes.forEach(mNode => {
                                        seriesList.forEach(x => {
                                            const cell = MultiResultHelper.GetCellOrNull(result, lastNode, x.AxisNode, mNode);
                                            if (cell) {
                                                x.Values.push(new SeriesValueInfo({
                                                    Index: retVal.Rows.length,
                                                    Value: cell.InternalValue
                                                }));
                                            }
                                        });
                                        retVal.Rows.push(new RowInfo({
                                            Caption: [...captions, mNode.Caption].join(' - '),
                                            AxisNode: lastNode,
                                            MeasureNode: mNode
                                        }));
                                    });
                                } else {
                                    seriesList.forEach(x => {
                                        if (x.MeasureNode) {
                                            const cell = MultiResultHelper.GetCellOrNull(result, lastNode, x.AxisNode, x.MeasureNode);
                                            if (cell) {
                                                x.Values.push(new SeriesValueInfo({
                                                    Index: retVal.Rows.length,
                                                    Value: cell.InternalValue
                                                }));
                                            }
                                        }
                                    });
                                    retVal.Rows.push(new RowInfo({
                                        Caption: captions.join(' - '),
                                        AxisNode: lastNode
                                    }));
                                }
                            });
                        } else {
                            if (mNodes.length > 0) {
                                mNodes.forEach(mNode => {
                                    seriesList.forEach(x => {
                                        const cell = MultiResultHelper.GetCellOrNull(result, opp, x.AxisNode, mNode);
                                        if (cell) {
                                            x.Values.push(new SeriesValueInfo({
                                                Index: retVal.Rows.length,
                                                Value: cell.InternalValue
                                            }));
                                        }
                                    });
                                    retVal.Rows.push(new RowInfo({
                                        Caption: opp.UniqueID === -1 ? mNode.Caption : opp.Caption + ' - ' + mNode.Caption,
                                        AxisNode: opp,
                                        MeasureNode: mNode
                                    }));
                                });
                            } else {
                                seriesList.forEach(x => {
                                    if (x.MeasureNode) {
                                        const cell = MultiResultHelper.GetCellOrNull(result, opp, x.AxisNode, x.MeasureNode);
                                        if (cell) {
                                            x.Values.push(new SeriesValueInfo({
                                                Index: retVal.Rows.length,
                                                Value: cell.InternalValue
                                            }));
                                        }
                                    }
                                });
                                retVal.Rows.push(new RowInfo({
                                    Caption: opp.Caption,
                                    AxisNode: opp
                                }));
                            }
                        }
                    }
                });
            });
            seriesList.forEach(series => {
                if (series.Values.some(x => x.Value != null)) {
                    retVal.Series.push(series);
                }
            });
        }
        return retVal;
    }

    private static FillRows(measureAxisDD: Axis, oppositeAxisDD: Axis, measureAxisNodes: AxisNode[], oppositeAxisNodes: AxisNode[],
        measureNodes: AxisNode[], settingsSeries: SeriesSettings[], cellGetter) {
        const seriesList = [];
        const noLevelIndex = {
            Index: 0
        };
        measureAxisDD.Areas.forEach(a => {
            let areaNodes;
            if (a.Measures) {
                a.Measures.forEach(m => {
                    const mNode = measureNodes.find(x => x.UniqueID === m.UniqueID);
                    if (mNode) {
                        if (!areaNodes) {
                            areaNodes = MultiResultHelper.GetNodesFromArea(a, measureAxisNodes, noLevelIndex);
                        }
                        settingsSeries.forEach(s => {
                            if (s['ColumnName'] === ('' + m.UniqueID)) {
                                const json = serialize(s);
                                areaNodes.Nodes.forEach(n => {
                                    if (n.Visible !== false) {
                                        if (areaNodes.Levels.length > 1) {
                                            MultiResultHelper.MergeLevels([n], areaNodes.Levels.length, (nodeList) => {
                                                const series = deserialize(SeriesSettings, json);
                                                const captions = [];
                                                nodeList.forEach(nl => {
                                                    captions.push(nl.Caption);
                                                });
                                                captions.push(mNode.Caption);
                                                series['ColumnName'] = captions.join(' - ');
                                                const lastNode = nodeList[nodeList.length - 1];
                                                series['ColumnKey'] = lastNode.Position + '_' + mNode.Position;
                                                seriesList.push({
                                                    Series: series,
                                                    AxisNode: lastNode,
                                                    MeasureNode: mNode,
                                                    HasValue: false
                                                });
                                            });
                                        } else {
                                            const series = deserialize(SeriesSettings, json);
                                            if (n.UniqueID === -1) {
                                                series['ColumnName'] = mNode.Caption;
                                            } else {
                                                series['ColumnName'] = n.Caption + ' - ' + mNode.Caption;
                                            }
                                            series['ColumnKey'] = n.Position + '_' + mNode.Position;
                                            seriesList.push({
                                                Series: series,
                                                AxisNode: n,
                                                MeasureNode: mNode,
                                                HasValue: false
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
            if (!areaNodes) {
                if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                } else {
                    noLevelIndex.Index++;
                }
            }
        });

        const retVal = {
            Rows: [],
            Series: []
        };
        if (seriesList.length > 0) {
            noLevelIndex.Index = 0;
            oppositeAxisDD.Areas.forEach(a => {
                const areaNodes = MultiResultHelper.GetNodesFromArea(a, oppositeAxisNodes, noLevelIndex);
                areaNodes.Nodes.forEach(opp => {
                    if (opp.Visible !== false) {
                        if (areaNodes.Levels.length > 1) {
                            MultiResultHelper.MergeLevels([opp], areaNodes.Levels.length, (nodeList) => {
                                const captions = [];
                                nodeList.forEach(nl => {
                                    captions.push(nl.Caption);
                                });
                                const row = {};
                                row[MultidimensionalChartKeys.AxisKey] = captions.join(' - ');
                                const lastNode = nodeList[nodeList.length - 1];
                                seriesList.forEach(x => {
                                    const cell = cellGetter(x.AxisNode, x.MeasureNode, lastNode);
                                    if (cell) {
                                        row[x.Series['ColumnKey']] = cell.InternalValue;
                                        if (!x.HasValue) {
                                            x.HasValue = typeof cell.InternalValue !== 'undefined' && cell.InternalValue !== null;
                                        }
                                    }
                                });
                                retVal.Rows.push(row);
                            });
                        } else {
                            const row = {};
                            row[MultidimensionalChartKeys.AxisKey] = opp.Caption;
                            seriesList.forEach(x => {
                                const cell = cellGetter(x.AxisNode, x.MeasureNode, opp);
                                if (cell) {
                                    row[x.Series['ColumnKey']] = cell.InternalValue;
                                    if (!x.HasValue) {
                                        x.HasValue = typeof cell.InternalValue !== 'undefined' && cell.InternalValue !== null;
                                    }
                                }
                            });
                            retVal.Rows.push(row);
                        }
                    }
                });
            });
            seriesList.forEach(series => {
                if (series.HasValue) {
                    retVal.Series.push(series.Series);
                }
            });
        }
        return retVal;
    }

    private static GetBubbleResult(measureAxisDD: Axis, oppositeAxisDD: Axis, measureAxisNodes: AxisNode[], oppositeAxisNodes: AxisNode[],
        measureNodes: AxisNode[], settingsSeries: SeriesSettings, cellGetter) {
        const seriesList = [];
        const noLevelIndex = {
            Index: 0
        };
        let groupingKey = MultidimensionalChartKeys.GroupingKey;
        let xMeasure, yMeasure, rMeasure;
        measureAxisDD.Areas.some(a => {
            if (a.Measures) {
                a.Measures.forEach(m => {
                    const mNode = measureNodes.find(x => x.UniqueID === m.UniqueID);
                    if (mNode) {
                        if (settingsSeries['XValue'] === ('' + m.UniqueID)) {
                            xMeasure = mNode;
                        }
                        if (settingsSeries['YValue'] === ('' + m.UniqueID)) {
                            yMeasure = mNode;
                        }
                        if (settingsSeries['Radius'] === ('' + m.UniqueID)) {
                            rMeasure = mNode;
                        }
                    }
                });
                if (xMeasure && yMeasure && rMeasure) {
                    const areaNodes = MultiResultHelper.GetNodesFromArea(a, measureAxisNodes, noLevelIndex);
                    if (areaNodes.Levels.length > 0) {
                        const levelCaptions = [];
                        areaNodes.Levels.forEach(x => {
                            levelCaptions.push(x.toString());
                        });
                        groupingKey = levelCaptions.join(' - ');
                    }
                    areaNodes.Nodes.forEach(n => {
                        if (n.Visible !== false) {
                            if (areaNodes.Levels.length > 1) {
                                MultiResultHelper.MergeLevels([n], areaNodes.Levels.length, (nodeList) => {
                                    const captions = [];
                                    nodeList.forEach(nl => {
                                        captions.push(nl.Caption);
                                    });
                                    const lastNode = nodeList[nodeList.length - 1];
                                    seriesList.push({
                                        Grouping: captions.join(' - '),
                                        AxisNode: lastNode
                                    });
                                });
                            } else {
                                seriesList.push({
                                    Grouping: n.UniqueID === -1 ? '' : n.Caption,
                                    AxisNode: n
                                });
                            }
                        }
                    });
                    return true;
                }
            }
            return false;
        });

        const retVal = {
            Rows: [],
            Series: []
        };

        if (seriesList.length > 0) {
            const json = serialize(settingsSeries);
            const series = deserialize(SeriesSettings, json);
            series['IDColumn'] = MultidimensionalChartKeys.AxisKey;
            series['XValue'] = xMeasure.Caption;
            series['YValue'] = yMeasure.Caption;
            series['Grouping'] = groupingKey;
            series['Radius'] = rMeasure.Caption;
            retVal.Series.push(series);
            noLevelIndex.Index = 0;
            oppositeAxisDD.Areas.forEach(a => {
                const areaNodes = MultiResultHelper.GetNodesFromArea(a, oppositeAxisNodes, noLevelIndex);
                areaNodes.Nodes.forEach(opp => {
                    if (opp.Visible !== false) {
                        if (areaNodes.Levels.length > 1) {
                            MultiResultHelper.MergeLevels([opp], areaNodes.Levels.length, (nodeList) => {
                                const captions = [];
                                nodeList.forEach(nl => {
                                    captions.push(nl.Caption);
                                });
                                const caption = captions.join(' - ');
                                const lastNode = nodeList[nodeList.length - 1];
                                seriesList.forEach(x => {
                                    const xCell = cellGetter(x.AxisNode, xMeasure, lastNode);
                                    if (xCell && xCell.InternalValue != null) {
                                        const yCell = cellGetter(x.AxisNode, yMeasure, lastNode);
                                        if (yCell && yCell.InternalValue != null) {
                                            const rCell = cellGetter(x.AxisNode, rMeasure, lastNode);
                                            if (rCell && rCell.InternalValue != null) {
                                                const row = new BubbleRow();
                                                row.Caption = caption;
                                                row.GroupingKey = groupingKey;
                                                row.GroupingValue = x.Grouping;
                                                row.MeasureAxisNode = x.AxisNode;
                                                row.OppositeAxisNode = lastNode;
                                                const rad = new BubbleRowData();
                                                rad.Caption = rMeasure.Caption;
                                                rad.UniqueID = rMeasure.UniqueID;
                                                rad.Value = rCell.InternalValue;
                                                row.Radius = rad;
                                                const xCoord = new BubbleRowData();
                                                xCoord.Caption = xMeasure.Caption;
                                                xCoord.UniqueID = xMeasure.UniqueID;
                                                xCoord.Value = xCell.InternalValue;
                                                row.XCoord = xCoord;
                                                const yCoord = new BubbleRowData();
                                                yCoord.Caption = yMeasure.Caption;
                                                yCoord.UniqueID = yMeasure.UniqueID;
                                                yCoord.Value = yCell.InternalValue;
                                                row.YCoord = yCoord;
                                                retVal.Rows.push(row);
                                            }
                                        }
                                    }
                                });
                            });
                        } else {
                            seriesList.forEach(x => {
                                const xCell = cellGetter(x.AxisNode, xMeasure, opp);
                                if (xCell && xCell.InternalValue != null) {
                                    const yCell = cellGetter(x.AxisNode, yMeasure, opp);
                                    if (yCell && yCell.InternalValue != null) {
                                        const rCell = cellGetter(x.AxisNode, rMeasure, opp);
                                        if (rCell && rCell.InternalValue != null) {
                                            const row = new BubbleRow();
                                            row.Caption = opp.Caption;
                                            row.GroupingKey = groupingKey;
                                            row.GroupingValue = x.Grouping;
                                            row.MeasureAxisNode = x.AxisNode;
                                            row.OppositeAxisNode = opp;
                                            const rad = new BubbleRowData();
                                            rad.Caption = rMeasure.Caption;
                                            rad.UniqueID = rMeasure.UniqueID;
                                            rad.Value = rCell.InternalValue;
                                            row.Radius = rad;
                                            const xCoord = new BubbleRowData();
                                            xCoord.Caption = xMeasure.Caption;
                                            xCoord.UniqueID = xMeasure.UniqueID;
                                            xCoord.Value = xCell.InternalValue;
                                            row.XCoord = xCoord;
                                            const yCoord = new BubbleRowData();
                                            yCoord.Caption = yMeasure.Caption;
                                            yCoord.UniqueID = yMeasure.UniqueID;
                                            yCoord.Value = yCell.InternalValue;
                                            row.YCoord = yCoord;
                                            retVal.Rows.push(row);
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            });
        }
        return retVal;
    }

    private static GetCandleResult(measureAxisDD: Axis, oppositeAxisDD: Axis, measureAxisNodes: AxisNode[], oppositeAxisNodes: AxisNode[],
        measureNodes: AxisNode[], settingsSeries: SeriesSettings[], cellGetter) {
        const seriesList = [];
        const noLevelIndex = {
            Index: 0
        };
        measureAxisDD.Areas.forEach(a => {
            if (a.Measures) {
                let areaNodes;
                settingsSeries.forEach(s => {
                    let min, init, final, max;
                    a.Measures.forEach(m => {
                        const mNode = measureNodes.find(x => x.UniqueID === m.UniqueID);
                        if (mNode) {
                            if (s['Minimum'] === ('' + m.UniqueID)) {
                                min = mNode;
                            }
                            if (s['Initial'] === ('' + m.UniqueID)) {
                                init = mNode;
                            }
                            if (s['Final'] === ('' + m.UniqueID)) {
                                final = mNode;
                            }
                            if (s['Maximum'] === ('' + m.UniqueID)) {
                                max = mNode;
                            }
                        }
                    });
                    if (min && init && final && max) {
                        if (!areaNodes) {
                            areaNodes = MultiResultHelper.GetNodesFromArea(a, measureAxisNodes, noLevelIndex);
                        }
                        const json = serialize(s);
                        areaNodes.Nodes.forEach(n => {
                            if (n.Visible !== false) {
                                if (areaNodes.Levels.length > 1) {
                                    MultiResultHelper.MergeLevels([n], areaNodes.Levels.length, (nodeList) => {
                                        const series = deserialize(SeriesSettings, json);
                                        const captions = [];
                                        nodeList.forEach(nl => {
                                            captions.push(nl.Caption);
                                        });
                                        series.LegendLabel = captions.join(' - ');
                                        const lastNode = nodeList[nodeList.length - 1];
                                        series['Minimum'] = min.Position + '_' + lastNode.Position;
                                        series['Initial'] = init.Position + '_' + lastNode.Position;
                                        series['Final'] = final.Position + '_' + lastNode.Position;
                                        series['Maximum'] = max.Position + '_' + lastNode.Position;
                                        seriesList.push({
                                            Series: series,
                                            Min: min,
                                            Init: init,
                                            Final: final,
                                            Max: max,
                                            AxisNode: lastNode,
                                            HasValue: false
                                        });
                                    });
                                } else {
                                    const series = deserialize(SeriesSettings, json);
                                    series.LegendLabel = n.UniqueID === -1 ? '' : n.Caption;
                                    series['Minimum'] = min.Position + '_' + n.Position;
                                    series['Initial'] = init.Position + '_' + n.Position;
                                    series['Final'] = final.Position + '_' + n.Position;
                                    series['Maximum'] = max.Position + '_' + n.Position;
                                    seriesList.push({
                                        Series: series,
                                        Min: min,
                                        Init: init,
                                        Final: final,
                                        Max: max,
                                        AxisNode: n,
                                        HasValue: false
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });

        const retVal = {
            Rows: [],
            Series: []
        };

        if (seriesList.length > 0) {
            noLevelIndex.Index = 0;
            oppositeAxisDD.Areas.forEach(a => {
                const areaNodes = MultiResultHelper.GetNodesFromArea(a, oppositeAxisNodes, noLevelIndex);
                areaNodes.Nodes.forEach(opp => {
                    if (opp.Visible !== false) {
                        if (areaNodes.Levels.length > 1) {
                            MultiResultHelper.MergeLevels([opp], areaNodes.Levels.length, (nodeList) => {
                                const captions = [];
                                nodeList.forEach(nl => {
                                    captions.push(nl.Caption);
                                });
                                const row = {};
                                row[MultidimensionalChartKeys.AxisKey] = captions.join(' - ');
                                const lastNode = nodeList[nodeList.length - 1];
                                seriesList.forEach(x => {
                                    const minCell = cellGetter(x.AxisNode, x.Min, lastNode);
                                    if (minCell) {
                                        row[x.Series['Minimum']] = minCell.InternalValue;
                                    }
                                    const initCell = cellGetter(x.AxisNode, x.Init, lastNode);
                                    if (initCell) {
                                        row[x.Series['Initial']] = initCell.InternalValue;
                                    }
                                    const finalCell = cellGetter(x.AxisNode, x.Final, lastNode);
                                    if (finalCell) {
                                        row[x.Series['Final']] = finalCell.InternalValue;
                                    }
                                    const maxCell = cellGetter(x.AxisNode, x.Max, lastNode);
                                    if (maxCell) {
                                        row[x.Series['Maximum']] = maxCell.InternalValue;
                                    }
                                });
                                retVal.Rows.push(row);
                            });
                        } else {
                            const row = {};
                            row[MultidimensionalChartKeys.AxisKey] = opp.Caption;
                            seriesList.forEach(x => {
                                const minCell = cellGetter(x.AxisNode, x.Min, opp);
                                if (minCell) {
                                    row[x.Series['Minimum']] = minCell.InternalValue;
                                }
                                const initCell = cellGetter(x.AxisNode, x.Init, opp);
                                if (initCell) {
                                    row[x.Series['Initial']] = initCell.InternalValue;
                                }
                                const finalCell = cellGetter(x.AxisNode, x.Final, opp);
                                if (finalCell) {
                                    row[x.Series['Final']] = finalCell.InternalValue;
                                }
                                const maxCell = cellGetter(x.AxisNode, x.Max, opp);
                                if (maxCell) {
                                    row[x.Series['Maximum']] = maxCell.InternalValue;
                                }
                            });
                            retVal.Rows.push(row);
                        }
                    }
                });
            });
            seriesList.forEach(series => {
                retVal.Series.push(series.Series);
            });
        }
        return retVal;
    }

    Refresh(chartSettings: ChartSettings) {
        if (this.DataDescription && chartSettings) {
            this.Loading.next(true);
            const executer = new QueryExecuter(this.DataDescription, this.QueryID);
            const context = {
                BaseReportObject: this.reportObject
            };
            executer.execute(context, true).then(matrix => {
                this.LastDataDescription = context['DataDescriptionClone'];
                this.LastMatrix = matrix;
                this.UpdateChart(chartSettings);
                this.Loading.next(false);
            });
        } else {
            this.LastMatrix = null;
            this.LastDataDescription = null;
            this.UpdateChart(chartSettings);
        }
    }

    async UpdateChart(chartSettings: ChartSettings) {
        const data = {
            Data: null,
            Settings: null
        };
        if (chartSettings) {
            const json = serialize(chartSettings);
            const settings = deserialize(ChartSettings, json);
            if (this.LastMatrix && this.LastDataDescription) {
                const matrix = this.LastMatrix;
                const dd = this.LastDataDescription;
                let result;
                const axisCol = {
                    ColumnName: MultidimensionalChartKeys.AxisKey,
                    ColumnKey: MultidimensionalChartKeys.AxisKey
                };
                switch (settings.ChartType) {
                    case ChartType.AreaChart:
                    case ChartType.BarChart:
                    case ChartType.ColumnChart:
                    case ChartType.ComboChart:
                    case ChartType.LineChart:
                    case ChartType.ScatterChart:
                    case ChartType.SteppedAreaChart:
                        settings['AxisColumn'] = axisCol;
                        const seriesResult = MultidimensionalChartDataSource.GetSeries(matrix, dd);
                        if (seriesResult.Rows.length > 0 && seriesResult.Series.length > 0) {
                            const context = {
                                ChartResult: seriesResult
                            };
                            let seriesMap = {};
                            if (this.reportObject) {
                                const le = this.reportObject.LayoutElement;
                                if (le) {
                                    if (le.UsePaletteForSingleSeries && seriesResult.Series.length == 1) {
                                        const values = seriesResult.Series[0].Values;
                                        if (values && values.length > 0) {
                                            let colors: string[];
                                            if (chartSettings.ChartPalette) {
                                                colors = await CacheService.ReadChartPaletteValues(chartSettings.ChartPalette);
                                            }
                                            if (!colors || colors.length == 0) {
                                                if (Array.isArray(chartSettings.SeriesColors) && chartSettings.SeriesColors.length > 0) {
                                                    colors = chartSettings.SeriesColors;
                                                } else if (ThemeHelper.ActiveThemeChartPalette) {
                                                    colors = ThemeHelper.ActiveThemeChartPalette;
                                                }
                                            }
                                            if (colors && colors.length > 0) {
                                                const colorStyles = [];
                                                colors.forEach(x => {
                                                    const svs = new SeriesValueStyle();
                                                    svs.Color = x;
                                                    colorStyles.push(svs);
                                                });
                                                const merger = ChartStyleMerger.GetChartStyleMerger(context);
                                                values.forEach(v => {
                                                    const svs = colorStyles[(v.Index % colorStyles.length)];
                                                    v.StyleID = merger.MergeStyle(v.StyleID, svs);
                                                });
                                            }
                                        }
                                    }
                                    if (le.FormatTasks) {
                                        for (let i = 0; i < le.FormatTasks.length; i++) {
                                            const task = le.FormatTasks[i];
                                            if (task && task.TaskType && task.QueryID === le.Query &&
                                                PageStatus.GetTaskActiveStatus(task, le.ID)) {
                                                const desc = TaskRegistry.get(task.TaskType);
                                                if (desc && desc.Executer) {
                                                    if (!desc.SettingsHelper || desc.SettingsHelper.canExecute(this.reportObject)) {
                                                        const executer: ITaskExecuter = new desc.Executer();
                                                        // hier nicht Klon verwenden???
                                                        await executer.Init(task, matrix, dd, context);
                                                        // TODO: IsValid
                                                        await executer.Execute();
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (le.AdditionalAxisMapping) {
                                        const entry = le.AdditionalAxisMapping[le.Query];
                                        if (entry) {
                                            seriesMap = entry;
                                        }
                                    }
                                }
                            }
                            const rows = [];
                            seriesResult.Rows.forEach(row => {
                                const dataRow = {};
                                dataRow[MultidimensionalChartKeys.AxisKey] = row.Caption;
                                rows.push(dataRow);
                            });
                            settings.Series = [];

                            const onlyPos = [true, true];
                            seriesResult.Series.forEach(s => {
                                const setting = ChartStyleMerger.GetSetting(s);
                                let seriesKey = s.AxisNode.UniqueID + '_';
                                if (s.MeasureNode) {
                                    seriesKey += s.MeasureNode.UniqueID;
                                }
                                if (seriesMap[seriesKey] === true) {
                                    setting.TargetAxis = 1;
                                } else {
                                    setting.TargetAxis = 0;
                                }
                                const labelKey = setting['ColumnKey'] + '_SeriesLabel';
                                if (s.LabelInfo && (s.LabelInfo.ShowCaption || s.LabelInfo.ShowValue)) {
                                    setting['LabelColumn'] = labelKey;
                                }
                                settings.Series.push(setting);
                                s.Values.forEach(v => {
                                    const row = rows[v.Index];
                                    row[setting['ColumnKey']] = v.Value;
                                    if (typeof v.Value === 'number' && v.Value < 0) {
                                        onlyPos[setting.TargetAxis] = false;
                                    }
                                    if (v.StyleID > -1) {
                                        row[setting['StyleKey']] = '' + v.StyleID;
                                    }
                                    if (s.LabelInfo) {
                                        if (s.LabelInfo.ShowCaption) {
                                            let label = row[MultidimensionalChartKeys.AxisKey];
                                            if (s.LabelInfo.ShowValue) {
                                                label += ' - ' + v.Value;
                                            }
                                            row[labelKey] = label;
                                        } else if (s.LabelInfo.ShowValue) {
                                            row[labelKey] = v.Value;
                                        }
                                    }
                                });
                            });
                            onlyPos.forEach((op, i) => {
                                if (op) {
                                    // Bei Achsen mit nur positiven Werten den MinValue auf 0 setzen, weil das GoogleChart sonst bei zu grossen Werten die BaseLine abschneidet
                                    if (settings.ValueAxes && settings.ValueAxes.length > i) {
                                        const axis = settings.ValueAxes[i];
                                        if ((!axis.Ticks || axis.Ticks.length === 0) && !axis.MinValue) {
                                            axis.MinValue = new AxisTick();
                                            axis.MinValue.Value = 0;
                                        }
                                    }
                                }
                            });
                            let styles;
                            const csm: ChartStyleMerger = context['ChartStyleMerger'];
                            if (csm) {
                                styles = csm.GetStyleObject();
                            }
                            data.Data = {
                                Data: rows,
                                Styles: styles
                            };
                        }
                        break;
                    case ChartType.Histogram:
                    case ChartType.heatmap:
                    case ChartType.polarArea:
                    case ChartType.radar:
                        if (settings.Series.length > 0) {
                            settings['AxisColumn'] = axisCol;
                            if (dd.ShowMeasureOnAxis === AxisType.Y_Axis) {
                                result = MultidimensionalChartDataSource.FillRows(dd.YLevelNodes, dd.XLevelNodes, matrix.YAxis.Nodes,
                                    matrix.XAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, oppPos, mAxisPos, mPos);
                                    });
                            } else {
                                result = MultidimensionalChartDataSource.FillRows(dd.XLevelNodes, dd.YLevelNodes, matrix.XAxis.Nodes,
                                    matrix.YAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, mAxisPos, oppPos, mPos);
                                    });
                            }
                            settings.Series = result.Series;
                            data.Data = result.Rows;
                        }
                        break;
                    case ChartType.PieChart:
                    case ChartType.DonutChart:
                    case ChartType.radialBar:
                    case ChartType.Gauge:
                        if (settings.Series.length > 0) {
                            settings['AxisColumn'] = axisCol;
                            if (dd.ShowMeasureOnAxis === AxisType.Y_Axis) {
                                result = MultidimensionalChartDataSource.FillRows(dd.YLevelNodes, dd.XLevelNodes, matrix.YAxis.Nodes,
                                    matrix.XAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, oppPos, mAxisPos, mPos);
                                    });
                            } else {
                                result = MultidimensionalChartDataSource.FillRows(dd.XLevelNodes, dd.YLevelNodes, matrix.XAxis.Nodes,
                                    matrix.YAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, mAxisPos, oppPos, mPos);
                                    });
                            }
                            if (result.Series.length > 1) {
                                result.Series.splice(1);
                            }
                            settings.Series = result.Series;
                            data.Data = result.Rows;
                        }
                        break;
                    case ChartType.BubbleChart:
                        if (settings.Series.length > 0) {
                            if (dd.ShowMeasureOnAxis === AxisType.Y_Axis) {
                                result = MultidimensionalChartDataSource.GetBubbleResult(dd.YLevelNodes, dd.XLevelNodes, matrix.YAxis.Nodes,
                                    matrix.XAxis.Nodes, matrix.Measures.Nodes, settings.Series[0], (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, oppPos, mAxisPos, mPos);
                                    });
                            } else {
                                result = MultidimensionalChartDataSource.GetBubbleResult(dd.XLevelNodes, dd.YLevelNodes, matrix.XAxis.Nodes,
                                    matrix.YAxis.Nodes, matrix.Measures.Nodes, settings.Series[0], (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, mAxisPos, oppPos, mPos);
                                    });
                            }
                            const context = {
                                BubbleResult: result,
                                ChartStyleMerger: null
                            };
                            if (this.reportObject) {
                                const le = this.reportObject.LayoutElement;
                                if (le && le.FormatTasks) {
                                    for (let i = 0; i < le.FormatTasks.length; i++) {
                                        const task = le.FormatTasks[i];
                                        if (task && task.TaskType && task.QueryID === le.Query &&
                                            PageStatus.GetTaskActiveStatus(task, le.ID)) {
                                            const desc = TaskRegistry.get(task.TaskType);
                                            if (desc && desc.Executer) {
                                                if (!desc.SettingsHelper || desc.SettingsHelper.canExecute(this.reportObject)) {
                                                    const executer: ITaskExecuter = new desc.Executer();
                                                    // hier nicht Klon verwenden???
                                                    await executer.Init(task, matrix, dd, context);
                                                    // TODO: IsValid
                                                    await executer.Execute();
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            const dataRows = [];
                            result.Rows.forEach(x => dataRows.push(x.toRow()));
                            if (context.ChartStyleMerger) {
                                const styleSeries = context.ChartStyleMerger.GetBubbleStyles();
                                result.Series.push(...styleSeries);
                            }
                            settings.Series = result.Series;
                            data.Data = dataRows;
                        }
                        break;
                    case ChartType.CandlestickChart:
                        if (settings.Series.length > 0) {
                            settings['AxisColumn'] = axisCol;
                            if (dd.ShowMeasureOnAxis === AxisType.Y_Axis) {
                                result = MultidimensionalChartDataSource.GetCandleResult(dd.YLevelNodes, dd.XLevelNodes, matrix.YAxis.Nodes,
                                    matrix.XAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, oppPos, mAxisPos, mPos);
                                    });
                            } else {
                                result = MultidimensionalChartDataSource.GetCandleResult(dd.XLevelNodes, dd.YLevelNodes, matrix.XAxis.Nodes,
                                    matrix.YAxis.Nodes, matrix.Measures.Nodes, settings.Series, (mAxisPos, mPos, oppPos) => {
                                        return MultiResultHelper.GetCellOrNull(matrix, mAxisPos, oppPos, mPos);
                                    });
                            }
                            settings.Series = result.Series;
                            data.Data = result.Rows;
                            if (result.Series && result.Series.length == 1 && result.Rows && result.Rows.length > 0 && this.reportObject) {
                                const le = this.reportObject.LayoutElement;
                                if (le && le.UsePaletteForSingleSeries) {
                                    let colors: string[];
                                    if (chartSettings.ChartPalette) {
                                        colors = await CacheService.ReadChartPaletteValues(chartSettings.ChartPalette);
                                    }
                                    if (!colors || colors.length == 0) {
                                        if (Array.isArray(chartSettings.SeriesColors) && chartSettings.SeriesColors.length > 0) {
                                            colors = chartSettings.SeriesColors;
                                        } else if (ThemeHelper.ActiveThemeChartPalette) {
                                            colors = ThemeHelper.ActiveThemeChartPalette;
                                        }
                                    }
                                    if (colors && colors.length > 0) {
                                        const styleKey = '_PalleteStyle_';
                                        result.Series[0]['StyleKey'] = styleKey;
                                        result.Rows.forEach((row, i) => {
                                            row[styleKey] = '' + (i % colors.length);
                                        });
                                        const styles = {};
                                        colors.forEach((y, i) => {
                                            styles['' + i] = y;
                                        });
                                        data.Data = {
                                            Data: result.Rows,
                                            Styles: styles
                                        };
                                    }
                                }
                            }
                        }
                        break;
                }
            }
            data.Settings = settings;
        }
        this.Data.next(data);
    }
}
