import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, KeyValueDiffers, Output, ViewChild } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventAddArg, EventApi, EventChangeArg, EventClickArg, EventRemoveArg, FullCalendarComponent } from '@fullcalendar/angular';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { CalendarView } from '../../../models/enums/calendarview.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { ComponentFilterControl } from '../../common/componentfilter/componentfilter.control';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-scheduler',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['scheduler.control.scss'],
    templateUrl: 'scheduler.control.html'
})
export class SchedulerControl extends IBaseComponent {

    static Type: any = 'scheduler';
    static Default = { Source: [], Type: 'scheduler', Layout: {
        _Editable: true,
        Width: { Type: 0, Value: 400 },
        Height: {Type: 0, Value: 350}
    } };

    calendarOptions: CalendarOptions;
    viewDate: Date = new Date();

    refresh: Subject<any> = new Subject();

    events: EventApi[] = [];

    activeDayIsOpen = true;

    @ViewChild('calendar') calendarComponent: FullCalendarComponent;

    //#region ItemsSource
    ItemsSourceValue;

    @Input()
    get ItemsSource() {
        return this.ItemsSourceValue;
    }
    set ItemsSource(val) {
        if (val != this.ItemsSourceValue) {
            this.ItemsSourceValue = val;
            this.buildEvents();
            this.ItemsSourceChange.emit(this.ItemsSourceValue);
        }
    }
    @Output() ItemsSourceChange = new EventEmitter<any>();
    //#endregion

    //#region DataSource
    get DataSource() {
        return this.ItemsSource;
    }
    set DataSource(val) {
        this.ItemsSource = val;
    }
    //#endregion

    differ;
    differObject;
    obeservedItems = [];
    DataSourceID;

    constructor(private dataService: DataService, differs: KeyValueDiffers, cdRef: ChangeDetectorRef,
        @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);

        this.differ = differs.find([]).create();
        this.differObject = differs.find({}).create();

        this.EventList.push('selectionchanged');
        this.EventList.push('timechanged');
        // this.EventList.push('delete');
        // this.EventList.push('edit');
        // this.EventList.push('OnFocus');
    }

    buildEvents() {
        this.events = [];
        this.obeservedItems = [];
        if (this.ItemsSourceValue) {
            this.ItemsSourceValue.forEach((i) => {
                const item: any = {
                    title: 'New event',
                    start: new Date(),
                    end: new Date(),
                    meta: i
                };
                if (this.LayoutElement) {
                    if (this.LayoutElement.IsDraggable != null) {
                        item.draggable = this.LayoutElement.IsDraggable;
                    }
                    if (this.LayoutElement.IsResizeable) {
                        item.resizable = {
                            beforeStart: true,
                            afterEnd: true
                        };
                    }
                    if (this.LayoutElement.TitleMemberPath) {
                        item.title = i[this.LayoutElement.TitleMemberPath];
                    }
                    if (this.LayoutElement.StartMemberPath) {
                        item.start = new Date(i[this.LayoutElement.StartMemberPath]);
                    }
                    if (this.LayoutElement.EndMemberPath) {
                        item.end = new Date(i[this.LayoutElement.EndMemberPath]);
                    }
                    if (this.LayoutElement.AllDayMemberPath) {
                        item.allDay = i[this.LayoutElement.AllDayMemberPath];
                    }
                }
                this.events.push(item);
            });
            this.GenerateCalendarOptions();
        }
    }

    ControlInitialized() {
        this.ExecuteRefresh();
    }

    onLayoutElementChanged() {
        this.ExecuteRefresh();
    }

    GenerateCalendarOptions() {
        this.calendarOptions = null;
        setTimeout(() => {
            const temp = {
                headerToolbar: {
                    left: '', // 'prev, today, next',
                    center: '', // 'title',
                    right: ''  // 'dayGridMonth, timeGridWeek, timeGridDay, listWeek'
                },
                initialView: 'listWeek',
                initialEvents: [],
                weekends: true,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                select: this.handleDateSelect.bind(this),
                eventClick: this.handleEventClick.bind(this),
                eventsSet: this.handleEvents.bind(this),
                eventAdd: this.handleAdd.bind(this),
                eventChange: this.handleChange.bind(this),
                eventRemove: this.handleRemove.bind(this),
                events: this.events
            };
            if (this.LayoutElementValue) {
                if (this.LayoutElement.headerToolbar) {
                    if (this.LayoutElement.headerToolbar.left) {
                        for (let i = 0; i < this.LayoutElement.headerToolbar.left.length; i++) {
                            if (i > 0) {
                                temp.headerToolbar.left += ',';
                            }
                            temp.headerToolbar.left += this.LayoutElement.headerToolbar.left[i];
                        }
                    }
                    if (this.LayoutElement.headerToolbar.center) {
                        for (let i = 0; i < this.LayoutElement.headerToolbar.center.length; i++) {
                            if (i > 0) {
                                temp.headerToolbar.center += ',';
                            }
                            temp.headerToolbar.center += this.LayoutElement.headerToolbar.center[i];
                        }
                    }
                    if (this.LayoutElement.headerToolbar.right) {
                        for (let i = 0; i < this.LayoutElement.headerToolbar.right.length; i++) {
                            if (i > 0) {
                                temp.headerToolbar.right += ',';
                            }
                            temp.headerToolbar.right += this.LayoutElement.headerToolbar.right[i];
                        }
                    }
                }
                if (this.LayoutElement.DefaultView != null) {
                    temp.initialView = CalendarView[this.LayoutElement.DefaultView];
                }
            }
            this.calendarOptions = temp;
            this.cdRef.detectChanges();
        }, 5);
    }

    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
    }

    ExecuteRefresh() {
        if (this.LayoutElement) {
            this.GenerateCalendarOptions();
            this.DataSourceID = MetaHelper.FindDataSourceID(this.Layout, this.LayoutElement);
            if (this.DataSourceID) {
                const options = new RequestOptions();
                if (this.Variables && this.LayoutElement.Filter) {
                    this.LayoutElement.Filter.forEach((fil) => {
                        if (this.Variables[fil.Filter]) {
                            const item = new RequestFilter();
                            item.Name = fil.Name;
                            item.Operator = fil.Comparer;
                            item.Value = this.Variables[fil.Filter];
                            FilterHelper.AddSearchFilter(options, item);
                        }
                    });
                }
                this.dataService.SearchObjects('dynamicdata', this.DataSourceID, options).subscribe((data) => {
                    this.ItemsSource = data;
                    this.cdRef.detectChanges();
                });
            }
        }
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        calendarApi.addEvent({
            id: UUID.UUID(),
            title: 'NewEvent',
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay,
            meta: {}
        });
    }

    handleEventClick(clickInfo: EventClickArg) {
        this.triggerEvent('selectionchanged', clickInfo.event.extendedProps['meta']);
    }

    handleEvents(events: EventApi[]) {
        this.events = events;
    }

    handleAdd(addInfo: EventAddArg) {
    }

    handleChange(changeInfo: EventChangeArg) {
        if (this.LayoutElement.StartMemberPath) {
            changeInfo.event.extendedProps['meta'][this.LayoutElement.StartMemberPath] = changeInfo.event.start;
        }
        if (this.LayoutElement.EndMemberPath) {
            changeInfo.event.extendedProps['meta'][this.LayoutElement.EndMemberPath] = changeInfo.event.end;
        }
        this.triggerEvent('timechanged', changeInfo.event.extendedProps['meta']);
    }

    handleRemove(removeInfo: EventRemoveArg) {
        this.triggerEvent('delete', removeInfo.event.extendedProps['meta']);
    }
}
export class SchedulerPanel extends BasePanel {
    static override SIDS = ['b16b17c8-551f-4fa0-976c-63c71b4b09bc']
    private static headerToolbarSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static TitleTableProperties: BehaviorSubject<any> = new BehaviorSubject(null);
    private static DateTableProperties: BehaviorSubject<any> = new BehaviorSubject(null);
    private static BooleanTableProperties: BehaviorSubject<any> = new BehaviorSubject(null);
    private static TableProperties;
    static override SetSelectedItem(item) {
        if (SchedulerPanel.SelectedItem && SchedulerPanel.SelectedItem.ElementType == 'scheduler') {
            if (!SchedulerPanel.SelectedItem.headerToolbar) {
                SchedulerPanel.SelectedItem.headerToolbar = {
                    left: null,
                    center: null,
                    right: null
                };
            }
            SchedulerPanel.headerToolbarSub.next(SchedulerPanel.SelectedItem.headerToolbar);
        }
        SchedulerPanel.ReadTableProperties();
    }
    static override SetSelectedLayout(item) {
        SchedulerPanel.ReadTableProperties();
    }
    static InitPanel() {
        this.InitSelectedItem();
        this.InitSelectedLayout();
        PROPERTYGROUPS.push({
            SID:'b16b17c8-551f-4fa0-976c-63c71b4b09bc',
            ID: 'scheduler',
            Caption: '@@Scheduler',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'scheduler';
            }
        });
        PROPERTIES.push({
            ID: "DefaultView",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DefaultView",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: CalendarView
            }
        })
        PROPERTIES.push({
            ID: "TitleMemberPath",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@TitleMemberPath",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: SchedulerPanel.TitleTableProperties,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "StartMemberPath",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@StartMemberPath",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: SchedulerPanel.DateTableProperties,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "EndMemberPath",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@EndMemberPath",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: SchedulerPanel.DateTableProperties,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "AllDayMemberPath",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@AllDayMemberPath",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: SchedulerPanel.BooleanTableProperties,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "IsDraggable",
            Parent: "scheduler",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@IsDraggable"
            }
        });
        PROPERTIES.push({
            ID: "IsResizeable",
            Parent: "scheduler",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@IsResizeable"
            }
        });
        PROPERTIES.push({
            ID: "Filter",
            Parent: "scheduler",
            Content: new ComponentPortal(ComponentFilterControl)
        });

        let ToolBarValues = [{ Value: 'prev' }, { Value: 'today' },{ Value: 'next' },{ Value: 'title' },{ Value: 'dayGridMonth' },{ Value: 'timeGridWeek' },{ Value: 'timeGridDay' }, { Value: 'listWeek' }];

        PROPERTIES.push({
            ID: "left",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ToolbarLeft",
            DataSource: SchedulerPanel.headerToolbarSub,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: true,
                ItemsSource: ToolBarValues,
                DisplayMemberPath: "Value",
                ValueMemberPath: "Value"
            }
        });
        PROPERTIES.push({
            ID: "center",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ToolbarCenter",
            DataSource: SchedulerPanel.headerToolbarSub,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: true,
                ItemsSource: ToolBarValues,
                DisplayMemberPath: "Value",
                ValueMemberPath:"Value"
            }
        });
        PROPERTIES.push({
            ID: "right",
            Parent: "scheduler",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ToolbarRight",
            DataSource: SchedulerPanel.headerToolbarSub,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: true,
                ItemsSource: ToolBarValues,
                DisplayMemberPath: "Value",
                ValueMemberPath: "Value"
            }
        });
    }
    private static ReadTableProperties() {
        SchedulerPanel.TableProperties = null;
        // SchedulerPanel.TitleTableProperties.next([]);
        // SchedulerPanel.DateTableProperties.next([]);
        // SchedulerPanel.BooleanTableProperties.next([]);
        if (SchedulerPanel.SelectedLayout && SchedulerPanel.SelectedItem) {
            const ID = MetaHelper.FindDataSourceID(SchedulerPanel.SelectedLayout, SchedulerPanel.SelectedItem);
            if (ID) {
                CacheService.ReadTable(ID).then((result) => {
                    SchedulerPanel.TableProperties = result;
                    SchedulerPanel.TitleTableProperties.next(result['Fields'].filter((element, index, array) => {
                        return element.Type === '9b056ce1-ffb9-4168-9e00-977449f2f66d';
                    }))
                    SchedulerPanel.DateTableProperties.next(result['Fields'].filter((element, index, array) => {
                        return element.Type === 'cfcb4295-9d1e-49b4-b466-3fe74d1b0c69';
                    }))
                    SchedulerPanel.BooleanTableProperties.next(result['Fields'].filter((element, index, array) => {
                        return element.Type === '02414483-3194-474d-a3d1-0a1a8a36a9e0';
                    }))
                });
            }
        }
    }
}