import { Component } from '@angular/core';
import { CacheService } from '../../../../cache/cache.service';
import { FormulaNodeCalculator } from '../../../../models/calculation/formula.node.calculator';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-readGlobalVariable-settings',
    templateUrl: './readGlobalVariable.settings.html',
    styleUrls: ['./readGlobalVariable.settings.css']
})
export class ReadGlobalVariableSettings extends WorkflowDialogContent {
    static MultiModuleID = 'readMultiGlobalVariableModule';

    GlobalVariables = [];
    Variable;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readGlobalVariableModule';
        reg.Caption = '@@Globale Variable auslesen';
        reg.SettingsControl = ReadGlobalVariableSettings;
        reg.SettingsTypeHelper = new ReadGlobalVariableSettingsDataHelper();
        reg.Executer = ReadGlobalVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ReadGlobalVariableSettings.MultiModuleID;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.Variable) {
                this.Variable = data.Variable;
            }
            CacheService.ReadGlobalVariables().then((vars) => {
                if (vars) {
                    this.GlobalVariables = vars;
                }
            });
        }
    }

    getResult(): any {
        const retVal = new ReadGlobalVariableSettingsData();
        retVal.Variable = this.Variable;
        return retVal;
    }

    compareFn(item1, item2) {
        return item1 && item2 && item1.Name === item2.Name;
    }
}

export class ReadGlobalVariableSettingsData {
    Variable: any;
}

export class ReadGlobalVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadGlobalVariableSettingsData();
    }
}

export class ReadGlobalVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variable) {
                const global: any = await CacheService.RefreshGlobalVariableValues();
                if (global && global.length > 0) {
                    const variable = global.find(x => x.Name === status.ActualSettings.Variable.Name);
                    if (variable) {
                        const vat = FormulaNodeCalculator.GetVariableValue(variable, false);
                        if (vat) {
                            status.ActualParameter = vat.Value;
                        } else {
                            status.ActualParameter = null;
                        }
                        return 0;
                    } else {
                        status.Logger.logError('ReadGlobalVariable module: Global variable ' +
                            status.ActualSettings.Variable.Name + ' could not be found.');
                    }
                } else {
                    status.Logger.logError('ReadGlobalVariable module: No global variables found.');
                }
            } else {
                status.Logger.logError('ReadGlobalVariable module: No variable found.');
            }
        } else {
            status.Logger.logError('ReadGlobalVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
