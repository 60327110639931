import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class OfflineService extends RequestBase {

    BasePath = 'api/offline';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }
    
    StartClientSync(date): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/StartClientSync?date=' + date, this.options);
    }

    SyncDocument(containerid, item): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SyncDocument?containerid=' + containerid, item, this.options);
    }

    ForceSyncDocument(containerid, item): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ForceSyncDocument?containerid=' + containerid, item, this.options);
    }
    Check(containerid, offline): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Check?ID=' + containerid+"&Offline="+ offline, this.options);
    }
}