import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild
} from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { CacheService } from '../../../cache/cache.service';
import { ACaptionGetter, DefaultCaptionGetter } from '../../../helpers/acaptiongetter';
import { EnumHelper } from '../../../helpers/enum.helper';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { Comparer } from '../../../models/enums/comparer.enum';
import { Order } from '../../../models/enums/order.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { RequestSort } from '../../../models/rest/requestsort';
import { DataService } from '../../../services/data.service';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { ComponentFilterControl } from '../../common/componentfilter/componentfilter.control';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-autocomplete',
    templateUrl: './autocomplete.control.html',
    styleUrls: ['./autocomplete.control.css'],
    host: {
        '(document:click)': 'handleClick($event)',
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteControl extends IBaseComponent {
    static Type: any = 'autocomplete';
    static Default = { Caption: 'Autocomplete', Type: 'autocomplete',Layout: {
        _Editable: true,
        Width: { Type: 0, Value:300},
        Height: {Type:0,Value:50}
    } };
    //#region DisplayMemberPath
    @Input()
    get DisplayMemberPath() {
        return this.LayoutElement.DisplayMemberPath;
    }
    set DisplayMemberPath(val) {
        this.LayoutElement.DisplayMemberPath = val;
        this.DisplayMemberPathChange.emit(this.LayoutElement.DisplayMemberPath);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<any>();
    //#endregion
    //#region ValueMemberPath
    @Input()
    get ValueMemberPath() {
        return this.LayoutElement.ValueMemberPath;
    }
    set ValueMemberPath(val) {
        this.LayoutElement.ValueMemberPath = val;
        this.ValueMemberPathChange.emit(this.LayoutElement.ValueMemberPath);
    }

    @Output() ValueMemberPathChange = new EventEmitter<any>();
    //#endregion
    _Selection;
    get Selection() {
        return this._Selection;
    }
    set Selection(value) {

        this._Selection = value;

        if (this.Multiple) {
            this.SearchValue = null;
            if (!this.Values) {
                this.Values = [];
            }
            if (!this.DataSource || !Array.isArray(this.DataSource)) {
                this.internal = true;
                this.DataSource = [];
            }
            let list = [];
            this._Selection.forEach((val) => {
                if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                    list.push(val);
                } else {
                    list.push(val[this.ValueMemberPath]);
                }
            });
            this.DataSource = list;
        } else {
            if (this.DisplayMemberPath) {
                this.SearchValue = value[this.DisplayMemberPath];
            } else {
                this.SearchValue = value;
            }
            this.internal = true;
            if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                this.DataSource = value;
            } else {
                this.DataSource = value[this.ValueMemberPath];
            }
        }
    }

    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (val !== this.DataSourceValue) {
            this.DataSourceValue = val;
            if (!this.internal) {
                if (!this.LayoutElementValue.IsEnum) {
                    if (val) {
                        if (this.ValueMemberPath && !this.LayoutElementValue.IsReverse && this.LayoutElementValue) {
                            //#region Create RequestOptions
                            const options = new RequestOptions();
                            options.StartRow = 0;
                            options.EndRow = 10;
                            options.Filters = [];
                            const filter = new RequestFilter();
                            filter.Name = this.ValueMemberPath;
                            filter.Operator = Comparer.Equal;
                            filter.Value = this.DataSource;
                            let sendRequest = true;
                            if (this.Multiple && val && val.length > 0) {
                                options.EndRow = val.length;
                                filter.Operator = Comparer.In;
                            }
                            if (this.Multiple && ((val && val.length == 0) || !val)) {
                                sendRequest = false;
                            }
                            options.Filters.push(filter);
                            //#endregion
                            //#region FetchData
                            if (sendRequest) {
                                this.Subscriptions['data'] = this.dataService.SearchObjects('dynamicdata',
                                    this.DataSourceID, options).subscribe((data) => {
                                        if (this.Multiple) {
                                            this.Values = data;
                                            this._Selection = data;
                                        } else {
                                            if (this.DisplayMemberPath) {
                                                this.SearchValue = data[0][this.DisplayMemberPath];
                                            } else {
                                                this.SearchValue = data[0];
                                            }
                                        }
                                        this.cdRef.detectChanges();
                                    });
                            }
                            //#endregion
                        } else {
                            if (this.Multiple) {
                                this.Values = [];
                                if (!Array.isArray(val)) {
                                    this.Values.push(val);
                                } else {
                                    this.Values.push(...val);
                                }
                            } else {
                                if (this.DisplayMemberPath) {
                                    this.SearchValue = val[this.DisplayMemberPath];
                                } else {
                                    this.SearchValue = val;
                                }
                            }
                            this.cdRef.detectChanges();
                        }
                    }
                } else {
                    this.DisplayMemberPath = 'Caption';
                    this.ValueMemberPath = 'Value';
                    EnumHelper.GetEnumValues(this.DataSourceID).then((data) => {
                        if (data) {
                            data.forEach((x) => {
                                if (this.Multiple) {
                                    this.Values = [];
                                    if (!Array.isArray(val)) {
                                        data.forEach((item) => {
                                            if (item.Value === val) {
                                                this.Values.push(item);
                                            }
                                        });
                                    } else {
                                        data.forEach((item) => {
                                            val.forEach((it) => {
                                                if (item.Value === it) {
                                                    this.Values.push(item);
                                                }
                                            });
                                        });
                                    }
                                } else {
                                    data.forEach((item) => {
                                        if (item.Value === val) {
                                            this.SearchValue = item.Caption;
                                        }
                                    });
                                }
                            });
                            this.cdRef.detectChanges();
                        }
                    });
                }
            } else {
                this.internal = false;
            }
            this.triggerEvent('DataSourceChanged', this.DataSourceValue);
            this.DataSourceChange.emit(this.DataSourceValue);
        }
        if (!val) {
            this.SearchValue = null;
            this.Values = null;
        }
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region Source
    SourceValue = [];
    Source$;
    @Input()
    get Source() {
        return this.SourceValue;
    }
    set Source(val) {
        if (!val) {
            this.Change.emit(null);
        }
        let array = [{}];
        //array = [...array, ...val];
        //this.Source$ = of(array);
        if (val.length == 0) {
            this.Source$ = of([...array, ...val]);
        } else {
            this.Source$ = of([...val]);
        }
        
        this.SourceValue = val;
        this.SourceChange.emit(this.SourceValue);
    }

    @Output() SourceChange = new EventEmitter<any>();
    //#endregion
    //#region Multiple
    @Input()
    get Multiple() {
        return this.LayoutElement.Multiple;
    }
    set Multiple(val) {
        this.LayoutElement.Multiple = val;
        this.MultipleChange.emit(this.LayoutElement.Multiple);
    }

    @Output() MultipleChange = new EventEmitter<any>();
    //#endregion
    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion
    //#region Loading
    LoadingValue;

    @Input()
    get Loading() {
        return this.LoadingValue;
    }
    set Loading(val) {
        this.LoadingValue = val;
        this.LoadingChange.emit(this.LoadingValue);
        this.cdRef.detectChanges();
    }

    @Output() LoadingChange = new EventEmitter<any>();
    //#endregion
    EnumSource;
    SearchValue;
    Values;
    internal;
    LimitReached = false;
    Filter: RequestOptions;
    PageSize = 30;
    Pages = [];

    @Output() Change = new EventEmitter<any>();
    @Output() ScrollUp = new EventEmitter<any>();
    @Output() ScrollDown = new EventEmitter<any>();
    @Output() SelectionChange = new EventEmitter<any>();
    @ViewChild('textBox', { read: ElementRef }) textBox: ElementRef<HTMLInputElement>;

    elementRef;
    DataSourceID;
    popupOpen = false;
    //#region Lifecycle
    constructor(cdRef: ChangeDetectorRef, myElement: ElementRef, private dataService: DataService, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.elementRef = myElement;
        this.EventList.push('SelectionChanged');
        this.EventList.push('OnFocus');
    }
    ControlInitialized() {
        this.Refresh();
    }
    onLayoutElementChanged() {
        this.Refresh();
    }
    Refresh() {
        this.Filter = new RequestOptions();
        this.Filter.StartRow = 0;
        this.Filter.EndRow = this.PageSize;
        if (this.LayoutElementValue.IsEnum) {
            EnumHelper.GetEnumValues(this.LayoutElementValue.DataSourceID).then((data) => {
                this.Source = data;
                this.EnumSource = [...data];
                this.DisplayMemberPath = 'Caption';
                this.ValueMemberPath = 'Value';
            });
        }
        this.DataSourceID = MetaHelper.FindDataSourceID(this.Layout, this.LayoutElement);
        if (this.LayoutElementValue.DataSourceID && this.LayoutElementValue.DataSourceID !== '00000000-0000-0000-0000-000000000000') {
            CacheService.ReadTable(this.LayoutElementValue.DataSourceID).then((result) => {
                this.DataSourceID = this.LayoutElementValue.DataSourceID;
                this.FieldList = result['Fields'];
                this.UpdateCaptionGetter();
            });
        } else {
            MetaHelper.FindTableProperties(this.LayoutValue, this.LayoutElementValue).then(result => {
                if (result && result.IsEnum) {
                    EnumHelper.GetEnumValues(result.SID).then((data) => {
                        this.DisplayMemberPath = 'Caption';
                        this.ValueMemberPath = 'Value';
                        this.cdRef.detectChanges();
                    });
                } else if (result && result.Fields) {
                    const field = result.Fields.find(x => x.ID === this.LayoutElementValue.DataSource);
                    if (field && MetaHelper.FieldFitsForElementType(field, this.LayoutElementValue.ElementType)) {
                        this.DataSourceID = this.LayoutElementValue.DataSourceID;
                        this.FieldList = result.Fields;
                        this.UpdateCaptionGetter();
                    }
                }
            });
        }
    }
    //#endregion

    //#region Popup
    change() {
        this.popupOpen = true;
        this.searchAutoComplete(this.SearchValue);
        this.Change.emit(this.SearchValue);
    }

    changePopup() {
        this.popupOpen = !this.popupOpen;
        this.searchAutoComplete(null);
        this.Change.emit();
    }
    openPopUp() {
        this.popupOpen = !this.popupOpen;
    }
    handleClick(event) {
        let clickedComponent = event.target;
        let inside = false;
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        if (!inside) {
            this.popupOpen = false;
        }
    }
    //#endregion
    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
    }

    valueSelected(value) {
        this.popupOpen = false;
        if (!value) {
            this.SearchValue = null;
            this.internal = true;
            this.DataSource = null;
        } else {
            if (this.Multiple) {
                this.SearchValue = null;
                if (!this.Values) {
                    this.Values = [];
                }
                if (!this.DataSource || !Array.isArray(this.DataSource)) {
                    this.internal = true;
                    this.DataSource = [];
                }
                this.Values.push(value);
                if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                    this.DataSource.push(value);
                } else {
                    this.DataSource.push(value[this.ValueMemberPath]);
                }
            } else {
                if (this.DisplayMemberPath) {
                    this.SearchValue = value[this.DisplayMemberPath];
                } else {
                    this.SearchValue = value;
                }
                this.internal = true;
                if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                    this.DataSource = value;
                } else {
                    this.DataSource = value[this.ValueMemberPath];
                }
            }
        }
        this.triggerEvent('SelectionChanged', this.DataSource);
        this.SelectionChange.emit(this.DataSource);
    }
    remove(value: any): void {
        if (typeof (value) == 'object') {
            const list = [];
            if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                list.push(...this.Values);
            } else {
                this.Values.forEach((v) => {
                    list.push(v[this.ValueMemberPath]);
                });
            }
            this.internal = true;
            this.DataSource = list;
        } else {
            const index = this.Values.indexOf(value);
            if (index >= 0) {
                this.Values.splice(index, 1);
                const list = [];
                if (!this.ValueMemberPath || this.LayoutElement.IsReverse) {
                    list.push(...this.Values);
                } else {
                    this.Values.forEach((v) => {
                        list.push(v[this.ValueMemberPath]);
                    });
                }
                this.internal = true;
                this.DataSource = list;
            }
        }
    }
    //#region Scroll
    onScroll() {
        this.ScrollDown.emit(this.SearchValue);
        if (this.Filter) {
            this.Filter.StartRow = this.Filter.StartRow + this.PageSize;
            this.Filter.EndRow = this.Filter.StartRow + this.PageSize;
        }
        this.searchAutoComplete(this.SearchValue);
    }

    onScrollUp() {
        this.ScrollUp.emit(this.SearchValue);
        if (this.Filter) {
            this.Filter.StartRow = this.Filter.StartRow - this.PageSize;
            if (this.Filter.StartRow < 0) {
                this.Filter.StartRow = 0;
            }
            this.Filter.EndRow = this.Filter.StartRow + this.PageSize;
        }
        this.searchAutoComplete(this.SearchValue);
    }
    //#endregion
    private resetSource() {
        this.Filter.StartRow = 0;
        this.Filter.EndRow = 0;
        this.Source = [];
        this.Pages = [];
        this.LimitReached = false;
    }
    search(event) {
        this.searchAutoComplete(event.query);
    }
    private searchAutoComplete(value: any) {
        if (this.LayoutElement.ControlStyling) {
            console.log("AutoComplete", this.Source)
            this.Source = this.LayoutElement.Source
            return;
        }
        if (!this.Source) {
            this.Source = [];
        }
        if (!this.LayoutElementValue.IsEnum) {
            let page = 0;
            if (this.Filter.StartRow > 0) {
                page = this.Filter.EndRow / this.Filter.StartRow;
            }

            if (this.Filter) {
                if (value) {
                    if (this.Filter && this.Filter.Filters) {
                        const filter = new RequestFilter();
                        filter.Name = this.DisplayMemberPath;
                        filter.Operator = Comparer.Like;
                        filter.Value = value;
                        if (FilterHelper.CheckFilterValueChanges(this.Filter, filter)) {
                            FilterHelper.AddSearchFilter(this.Filter, filter);
                            this.resetSource();
                        }
                    }
                } else {
                    if (this.Filter.Filters.length === 1) {
                        this.resetSource();
                    }
                    this.Filter.Filters = [];
                }
                if (this.Variables && this.LayoutElement.Filter) {
                    this.LayoutElement.Filter.forEach((fil) => {
                        if (this.Variables[fil.Filter]) {
                            const item = new RequestFilter();
                            item.Name = fil.Name;
                            item.Operator = fil.Comparer;
                            item.Value = this.Variables[fil.Filter];
                            FilterHelper.AddSearchFilter(this.Filter, item);
                        }
                    });
                }
                if (this.LayoutElement.Order) {
                    this.Filter.Sort = [];
                    const sort = new RequestSort();
                    sort.Name = this.DisplayMemberPath;
                    sort.Order = this.LayoutElement.Order;
                    this.Filter.Sort.push(sort);
                }
            }
            this.Loading = true;
            if (this.LayoutElementValue) {
                this.Subscriptions['data'] = this.dataService.SearchObjects('dynamicdata',
                    this.DataSourceID, this.Filter).subscribe((data) => {
                    if (data && data.length) {
                        this.Source = data;
                    } else {
                        this.LimitReached = true;
                    }
                    this.Loading = false;
                }, (error) => {
                    this.Loading = false;
                });
            }
        } else {
            if (value) {
                this.Source = this.EnumSource.filter((element, index, array) => {
                    const val = element[this.DisplayMemberPath];
                    return val && val.toLowerCase().indexOf(value.toLowerCase()) > -1;
                });
            } else {
                this.Source = [...this.EnumSource];
            }
        }
    }

    setFocus() {

        if (this.textBox && this.textBox.nativeElement) {
            this.textBox.nativeElement.focus();
        }
    }
    private CaptionGetter: ACaptionGetter = new DefaultCaptionGetter();
    FieldList = [];
    UpdateCaptionGetter() {
        this.CaptionGetter = ACaptionGetter.GetCaptionGetter(this.LayoutElementValue, this.FieldList);
    }
}
export class AutocompletePanel extends BasePanel {
    static override SIDS = ['8e8eaf91-8499-4262-a4b4-43cb595990dc']
    private static DisplayMemberPathSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static ValueMemberPathSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static VariablesSub: BehaviorSubject<any> = new BehaviorSubject(null);
    private static TableProperties;
    static override SetSelectedItem(item) {
        AutocompletePanel.ReadTableProperties();
    }
    static InitPanel() {
        this.InitSelectedItem();
        PROPERTYGROUPS.push({
            SID:'8e8eaf91-8499-4262-a4b4-43cb595990dc',
            ID: 'autocomplete',
            Caption: '@@Autocomplete',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'autocomplete';
            }
        })
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "autocomplete",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "DisplayMemberPath",
            Parent: "autocomplete",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DisplayMemberPath",
            CheckVisibility: (item) => {
                return AutocompletePanel.TableProperties && AutocompletePanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: AutocompletePanel.DisplayMemberPathSub,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "ValueMemberPath",
            Parent: "autocomplete",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@ValueMemberPath",
            CheckVisibility: (item) => {
                return AutocompletePanel.TableProperties && AutocompletePanel.TableProperties.Fields;
            },
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSourceSub: AutocompletePanel.ValueMemberPathSub,
                ValueMemberPath: "Name",
                DisplayMemberPath: "TranslatedCaption"
            }
        });
        PROPERTIES.push({
            ID: "Order",
            Parent: "autocomplete",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Order",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: Order
            }
        });
        PROPERTIES.push({
            ID: "UseFloatingLabel",
            Parent: "autocomplete",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@UseFloatingLabel"
            }
        });
        PROPERTIES.push({
            ID: "Filter",
            Parent: "autocomplete",
            Content: new ComponentPortal(ComponentFilterControl)
        });
    }
    static ReadTableProperties() {
        if (AutocompletePanel.SelectedItem) {
            const variables = [];
            AutocompletePanel.TableProperties = null;
            if (!AutocompletePanel.SelectedItem.IsEnum) {
                if (AutocompletePanel.SelectedItem.DataSourceID) {
                    CacheService.ReadTable(AutocompletePanel.SelectedItem.DataSourceID).then((data) => {
                        if (data) {
                            AutocompletePanel.TableProperties = data;

                            AutocompletePanel.TableProperties.Fields.forEach((column) => {
                                const vni = new VariablesNodeInformation();
                                vni.VariableID = column.ID;
                                vni.Name = column.TranslatedCaption;
                                variables.push(vni);
                            });
                            AutocompletePanel.VariablesSub.next(variables);
                            let fields = JSON.stringify(AutocompletePanel.TableProperties.Fields)
                            let DisplayMemberFields = JSON.parse(fields);
                            DisplayMemberFields.unshift({ Name: "", TranslatedCaption: "" });
                            AutocompletePanel.DisplayMemberPathSub.next(DisplayMemberFields);

                            let ValueMemberFields = JSON.parse(fields);

                            ValueMemberFields.unshift({ Name: '_Id', TranslatedCaption: "System.ID" });
                            ValueMemberFields.unshift({ Name: null, TranslatedCaption: "@@None" });
                            AutocompletePanel.ValueMemberPathSub.next(ValueMemberFields);
                            //this.Variables = variables;
                        }
                    });
                }
            }
        }
    }
}