import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { BorderThemeControl } from '../../appbuilder/controls/borderselection/borderselection.theme.control';
import { ColorpickerThemeControl } from '../../appbuilder/controls/colorpicker/colorpicker.theme.control';
import { ComboboxThemeControl } from '../../appbuilder/controls/combobox/combobox.theme.control';
import { GradientpickerThemeControl } from '../../appbuilder/controls/gradientpicker/gradientpicker.theme.control';
import { HeaderThemeControl } from '../../appbuilder/controls/header/header.theme.control';
import { InputswitchThemeControl } from '../../appbuilder/controls/inputswitch/inputswitch.theme.control';
import { TogglebuttonThemeControl } from '../../appbuilder/controls/togglebutton/togglebutton.theme.control';
import { TogglebuttongroupThemeControl } from '../../appbuilder/controls/togglebuttongroup/togglebuttongroup.theme.control';
import { GenericMenuTab } from '../../appbuilder/menutabs/generic/generic.menu.tab';
import { ContentPanel } from '../../appbuilder/panels/content.panel';
import { MultiNode } from '../../models/datadescription/multi/multiresult.model';
import { PropertyGroupDisplay } from '../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../models/menutab/menutab.property.model';
import { Border } from '../../models/style/border.model';
import { Font } from '../../models/style/font.model';
import { Gradient } from '../../models/style/gradient.model';
import { CellStyle } from '../../models/styling/cell.style';
import { NumberFormat } from '../../models/styling/number.format.model';
import { StyleMerger } from '../../models/styling/styleMerger';
import { TaskHelper } from '../../models/tasks/task.helper';
import { PROPERTIES } from '../../services/dynamic.component.service';
import { MediaService } from '../../services/media.service';

@Component({
    selector: 'style-control',
    templateUrl: './style.control.html'
})
export class StyleControl implements OnInit {
    StylePreview = {};
    StyleText = TaskHelper.GetFormatedCellValue(new MultiNode({ InternalValue: 1234.56 }), new NumberFormat());
    Gadgets;
    Content;

    ShowGadgetValue;
    @Input()
    get ShowGadget() {
        return this.ShowGadgetValue;
    }
    set ShowGadget(val) {
        this.ShowGadgetValue = val;
        this.ShowGadgetChange.emit(this.ShowGadgetValue);
    }
    @Output() ShowGadgetChange = new EventEmitter<any>();

    StyleValue;
    @Input()
    get Style() {
        return this.StyleValue;
    }
    set Style(val) {
        this.StyleValue = val;
        this.StyleChange.emit(this.StyleValue);
        this.updatePreview();
        if (val && val.NumberFormat) {
            this.StyleText = TaskHelper.GetFormatedCellValue(new MultiNode({ InternalValue: 1234.56 }), val.NumberFormat);
        } else {
            this.StyleText = TaskHelper.GetFormatedCellValue(new MultiNode({ InternalValue: 1234.56 }), new NumberFormat());
        }
        StylePanel.SetSelectedItem(val);
    }
    @Output() StyleChange = new EventEmitter<any>();

    constructor() {
        const gadgets = MediaService.GadgetList.getValue();
        if (gadgets) {
            this.Gadgets = gadgets;
        } else {
            this.Gadgets = [];
        }
    }
    ngOnInit(): void {
        const propGroup = {
            SID: 'cbb52f91-f4ac-4e16-bdb6-9d78cb492543',
            ID: 'taskStylePanel',
            Caption: '@@TaskStylePanel',
            Index: 0,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Sections: [{
                ID: 'text',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['120px', 'calc(50% - 120px)', 'calc(50%)'],
                Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
            }, {
                ID: 'style',
                Display: PropertyGroupDisplay.Grid,
                Columns: ['33%', '33%', '33%'],
                Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']
            }]
        };
        this.Content = {
            SID: propGroup.SID,
            ID: propGroup.ID,
            Caption: propGroup.Caption,
            Visible: true,
            PropertyGroup: propGroup,
            Index: propGroup.Index,
            Content: new ComponentPortal(propGroup.Content)
        };
    }

    updatePreview() {
        if (this.StyleValue) {
            const style = plainToClass(CellStyle, this.StyleValue);
            this.StylePreview = StyleMerger.GetStyleObject(style);
        } else {
            this.StylePreview = {};
        }
        this.StylePreview['height'] = '40px';
    }

    onNFChange(nf) {
        if (this.StyleValue) {
            if (this.StyleValue.NumberFormat !== nf) {
                this.StyleValue.NumberFormat = nf;
            }
            this.StyleText = TaskHelper.GetFormatedCellValue(new MultiNode({ InternalValue: 1234.56 }), this.StyleValue.NumberFormat);
        }
    }
}

export class StylePanel {
    private static Font: BehaviorSubject<Font> = new BehaviorSubject<Font>(null);

    static SetSelectedItem(item) {
        if (item) {
            if (!item.Font) {
                item.Font = new Font();
            }
            StylePanel.Font.next(item.Font);
        } else {
            StylePanel.Font.next(null);
        }
    }

    static InitPanel() {
        let row = 1;
        //#region Text
        PROPERTIES.push({
            ID: null,
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Text: '@@Text'
            }
        });
        //#region FetchFonts
        let UseGrouping = false;
        const cssfontList = MediaService.CSSFontList.getValue();
        if (cssfontList && cssfontList.length > 0) {
            UseGrouping = true;
        } else {
            const customFontList = MediaService.CustomFontList.getValue();
            UseGrouping = customFontList && customFontList.length > 0;
        }

        //#endregion
        PROPERTIES.push({
            ID: "FontSize",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(ComboboxThemeControl),
            DataSource: StylePanel.Font,
            Column: 1,
            Row: row,
            InitArgs: {
                Multiple: false,
                ItemsSource: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
                Placeholder: "@@Font size"
            }
        });
        PROPERTIES.push({
            ID: "FontFamilyName",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(ComboboxThemeControl),
            DataSource: StylePanel.Font,
            Column: 2,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Multiple: false,
                UseGrouping: UseGrouping,
                DisplayMemberPath: 'Value',
                ValueMemberPath: 'Value',
                ItemsSourceSub: ContentPanel.Fonts,
                OptionStyle: 'Style',
                Placeholder: "@@Font"
            }
        });
        //#region Alignment
        PROPERTIES.push({
            ID: null,
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Text: '@@PositionOfText'
            }
        })
        PROPERTIES.push({
            ID: "HorizontalTextAlignment",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: row,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: "align_left_text",
                    Value: 0,
                    Tooltip: "@@LeftJustified"
                }, {
                    ButtonIcon: "align_center_text",
                    Value: 1,
                    Tooltip: "@@Centered"
                }, {
                    ButtonIcon: "align_right_text",
                    Value: 2,
                    Tooltip: "@@RightJustified"
                }]
            }
        });
        PROPERTIES.push({
            ID: "VerticalTextAlignment",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 3,
            Row: row++,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: "vertical_align_top_text",
                    Value: 0,
                    Tooltip: "@@Top"
                }, {
                    ButtonIcon: "vertical_align_center_text",
                    Value: 1,
                    Tooltip: "@@Centered"
                }, {
                    ButtonIcon: "vertical_align_bottom_text",
                    Value: 2,
                    Tooltip: "@@Bottom"
                }]
            }
        });
        PROPERTIES.push({
            ID: null,
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(HeaderThemeControl),
            Column: 1,
            ColSpan: 3,
            Row: row++,
            InitArgs: {
                Text: '@@PositionOfControl'
            }
        })
        PROPERTIES.push({
            ID: "HorizontalContentAlignment",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 1,
            ColSpan: 2,
            Row: row,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: "align_left_content",
                    Value: 0,
                    Tooltip: "@@LeftJustified"
                }, {
                    ButtonIcon: "align_center_content",
                    Value: 1,
                    Tooltip: "@@Centered"
                }, {
                    ButtonIcon: "align_right_content",
                    Value: 2,
                    Tooltip: "@@RightJustified"
                }],
                Placeholder: "@@Horizontal content align"
            }
        });
        PROPERTIES.push({
            ID: "VerticalContentAlignment",
            Section: "text",
            Parent: "taskStylePanel",
            Content: new ComponentPortal(TogglebuttongroupThemeControl),
            Column: 3,
            Row: row++,
            InitArgs: {
                Multiple: false,
                Buttons: [{
                    ButtonIcon: "vertical_align_top_content",
                    Value: 0,
                    Tooltip: "@@Top"
                }, {
                    ButtonIcon: "vertical_align_center_content",
                    Value: 1,
                    Tooltip: "@@Centered"
                }, {
                    ButtonIcon: "vertical_align_bottom_content",
                    Value: 2,
                    Tooltip: "@@Bottom"
                }],
                Placeholder: "@@Vertical content align"
            }
        });
        //#endregion
        //#endregion

        //#region Style
        row = 1;
        PROPERTIES.push({
            ID: "Bold",
            Parent: "taskStylePanel",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: StylePanel.Font,
            Label: "@@Bold",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 1,
            Row: row,
            InitArgs: {
                Icon: 'bold'
            }
        });
        PROPERTIES.push({
            ID: "Italic",
            Parent: "taskStylePanel",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: StylePanel.Font,
            Label: "@@Italic",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 2,
            Row: row,
            InitArgs: {
                Icon: 'italic'
            }
        });
        PROPERTIES.push({
            ID: "Underline",
            Parent: "taskStylePanel",
            Section: 'style',
            Content: new ComponentPortal(TogglebuttonThemeControl),
            DataSource: StylePanel.Font,
            Label: "@@Underline",
            LabelPosition: MenuTabLabelPosition.Bottom,
            Column: 3,
            Row: row++,
            InitArgs: {
                Icon: 'underline'
            }
        });
        PROPERTIES.push({
            ID: "FontColor",
            Parent: "taskStylePanel",
            Section: 'style',
            Content: new ComponentPortal(ColorpickerThemeControl),
            DataSource: StylePanel.Font,
            Label: "@@FontColor",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 3,
            Row: row++,
            InitArgs: {
                Icon: 'font_color'
            }
        });
        PROPERTIES.push({
            ID: "BackgroundColor",
            Parent: "taskStylePanel",
            Section: 'style',
            Content: new ComponentPortal(GradientpickerThemeControl),
            Type: Gradient,
            Label: "@@BackgroundColor",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 3,
            Row: row++,
            InitArgs: {
                Icon: 'background_color'
            }
        });
        //#region Border
        PROPERTIES.push({
            ID: "UseBorder",
            Parent: "taskStylePanel",
            Section: 'border',
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@UseBorder",
            LabelPosition: MenuTabLabelPosition.Right,
            Icon: 'use_border',
            Column: 1,
            ColSpan: 3,
            Row: row++,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        delete item.SelectedItem.Border;
                    }
                }
            }
        });
        PROPERTIES.push({
            ID: "Border",
            Parent: "taskStylePanel",
            Section: 'border',
            Content: new ComponentPortal(BorderThemeControl),
            Type: Border,
            Column: 1,
            ColSpan: 3,
            Row: row++,
            CheckVisibility: (item) => {
                let borderSet = (item.Border && item.Border.toJSON && item.Border.toJSON() != null);
                if (item.UseBorder == null) {
                    item.UseBorder = borderSet;
                }
                return item.UseBorder || borderSet;
            }
        });
        //#endregion
        //#endregion
    }
}
