// vjs-player.component.ts
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';

@Component({
    selector: 'app-vjs-player',
    template: '<video #target class="video-js  vjs-big-play-centered"  controls webkit-playsinline="true" playsinline="true"   preload="none" muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true"></video>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('target', { static: true }) target: ElementRef;
    @Input() options: {
        controls: boolean,
        fluid: boolean,
        aspectRatio: string,
        autoplay: any,
        muted:any,
        sources: {
            src: string,
            type: string,
        }[],
    };
    player: videojs.Player;

    constructor(
        private elementRef: ElementRef,
    ) { }

    ngOnInit() {
        this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
            
        });
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}