import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { FixedStruct, OrderStruct, PagingStruct, SelectStruct, StructureType, UpdateStruct, WhereStruct } from '../../../../../models/rest/mapping.structure';
import { DataModelService } from '../../../../../services/datamodel.service';

@Component({
    selector: 'base-mapping',
    templateUrl: 'base.mapping.html',
    styleUrls:['base.mapping.css']
})
export class BaseMappingForm {

    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Fields
    FieldsValue;
    @Input()
    get Fields() {
        return this.FieldsValue;
    }
    set Fields(val) {
        this.FieldsValue = val;
        this.FieldsChange.emit(this.FieldsValue);
    }

    @Output() FieldsChange = new EventEmitter<any>();
    //#endregion

    //#region CustomFields
    CustomFieldsValue;
    @Input()
    get CustomFields() {
        return this.CustomFieldsValue;
    }
    set CustomFields(val) {
        this.CustomFieldsValue = val;
        this.CustomFieldsChange.emit(this.CustomFieldsValue);
    }

    @Output() CustomFieldsChange = new EventEmitter<any>();
    //#endregion

    @Output() ValuesChanged = new EventEmitter<any>();

    StructureTypes;

    constructor(private _translate: TranslateService, private _dataModelService: DataModelService) {
        this.StructureTypes = EnumHelper.GetDropdownValues(StructureType)
    }

    StructSelected() {
        switch (this.SelectedItem.StructureType) {
            case StructureType.WHERE: this.SelectedItemValue.Where = new WhereStruct(); break;
            case StructureType.ORDER: this.SelectedItemValue.Order = new OrderStruct(); break;
            case StructureType.SELECT: this.SelectedItemValue.Select = new SelectStruct(); break;
            case StructureType.UPDATE: this.SelectedItemValue.Update = new UpdateStruct(); break;
            case StructureType.FIXED: this.SelectedItemValue.Fixed = new FixedStruct(); break;
            case StructureType.PAGING: this.SelectedItemValue.Paging = new PagingStruct(); break;
        }
    }
    ItemValuesChanged() {
        this.ValuesChanged.emit();
    }
}