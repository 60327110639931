import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { InjectorHelper } from '../../helpers/injector.helper';
import { MultiReportingService } from '../../services/reporting.service';
import { Cube, Database, Dimension, Hierarchy, Level, Measure } from './models/database.model';

export class MultiCacheService {

    private static cubes: Map<UUID, Map<UUID, Cube>> = new Map<UUID, Map<UUID, Cube>>();
    private static dimensions: Map<UUID, Map<UUID, Dimension>> = new Map<UUID, Map<UUID, Dimension>>();
    private static hierarchies: Map<UUID, Map<UUID, Hierarchy>> = new Map<UUID, Map<UUID, Hierarchy>>();
    private static level: Map<UUID, Map<UUID, Level>> = new Map<UUID, Map<UUID, Level>>();
    private static measures: Map<UUID, Map<UUID, Measure>> = new Map<UUID, Map<UUID, Measure>>();
    private static databases: Map<UUID, Database> = new Map<UUID, Database>();
    private static InitializingObjects: Map<UUID, BehaviorSubject<boolean>> = new Map<UUID, BehaviorSubject<boolean>>();

    private static FillCacheAndSetParents(dmID: any, database: Database) {
        database.Cubes.forEach(c => {
            c.Measures.forEach(m => {
                m.Cube = c;
                MultiCacheService.measures.get(dmID).set(m.ID, m);
            });
            const dimlist: Dimension[] = [];
            c.Dimensions.forEach(d => {
                const dim = database.Dimensions.find(x => x.ID === d);
                if (dim) {
                    dimlist.push(dim);
                }
            });
            c.Dimensions = dimlist;
            MultiCacheService.cubes.get(dmID).set(c.ID, c);
        });
        database.Dimensions.forEach(d => {
            d.Hierarchies.forEach(h => {
                h.Levels.forEach(l => {
                    l.Parent = h;
                    MultiCacheService.level.get(dmID).set(l.ID, l);
                });
                h.Parent = d;
                MultiCacheService.hierarchies.get(dmID).set(h.ID, h);
            });
            MultiCacheService.dimensions.get(dmID).set(d.ID, d);
        });
    }

    /**
     * Wartet bis die Datenbank initialisiert wurde und gibt zur�ck, ob sie gerade frisch initialisiert wurde
     */
    public static async AwaitIsInitialized(dmID: any): Promise<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
            const sub = MultiCacheService.InitializingObjects.get(dmID);
            if (sub) {
                let fresh = false;
                sub.subscribe(x => {
                    if (x) {
                        resolve(fresh);
                    } else {
                        fresh = true;
                    }
                });
            } else {
                MultiCacheService.RefreshMultiCache(dmID).then(() => {
                    resolve(true);
                }, () => {
                    reject();
                });
            }
        });
        return promise;
    }

    public static async GetDatabase(dmID: any): Promise<Database> {
        const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

        let database = MultiCacheService.databases.get(dmID);
        if (!database && !fresh) {
            await MultiCacheService.RefreshMultiCache(dmID);
            database = MultiCacheService.databases.get(dmID);
        }
        return database;
    }

    public static async GetCubes(dmID: any): Promise<Cube[]> {
        const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

        let cubes = MultiCacheService.cubes.get(dmID);
        if (cubes && cubes.size > 0) {
            return [...cubes.values()];
        }
        if (!fresh) {
            await MultiCacheService.RefreshMultiCache(dmID);
            cubes = MultiCacheService.cubes.get(dmID);
            if (cubes) {
                return [...cubes.values()];
            }
        }
        return null;
    }

    public static async GetCube(cubeid: UUID, dmID: any): Promise<Cube> {
        if (cubeid) {
            const fresh = await MultiCacheService.AwaitIsInitialized(dmID);
            let cubes = MultiCacheService.cubes.get(dmID);
            if (cubes) {
                const cube = cubes.get(cubeid);
                if (cube) {
                    return cube;
                }
            }
            if (!fresh) {
                await MultiCacheService.RefreshMultiCache(dmID);
                cubes = MultiCacheService.cubes.get(dmID);
                if (cubes) {
                    return cubes.get(cubeid);
                }
            }
        }
        return null;
    }

    public static async GetDimensionsByCube(cubeid: UUID, dmID: any): Promise<Dimension[]> {
        if (cubeid) {
            const cube = await MultiCacheService.GetCube(cubeid, dmID);
            if (cube) {
                return [...cube.Dimensions];
            }
        }
        return null;
    }

    public static async GetDimensions(dmID: any): Promise<Dimension[]> {
        const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

        let dimDict = MultiCacheService.dimensions.get(dmID);
        if (dimDict) {
            return [...dimDict.values()];
        }
        if (!fresh) {
            await MultiCacheService.RefreshMultiCache(dmID);
            dimDict = MultiCacheService.dimensions.get(dmID);
            if (dimDict) {
                return [...dimDict.values()];
            }
        }
        return null;
    }

    public static async GetDimension(dimensionid: UUID, dmID: any): Promise<Dimension> {
        if (dimensionid) {
            const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

            let dimensions = MultiCacheService.dimensions.get(dmID);
            if (dimensions) {
                const dim = dimensions.get(dimensionid);
                if (dim) {
                    return dim;
                }
            }
            if (!fresh) {
                await MultiCacheService.RefreshMultiCache(dmID);
                dimensions = MultiCacheService.dimensions.get(dmID);
                if (dimensions) {
                    return dimensions.get(dimensionid);
                }
            }
        }
        return null;
    }

    public static async GetHierarchy(hierarchyid: UUID, dmID: any): Promise<Hierarchy> {
        if (hierarchyid) {
            const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

            let hierarchies = MultiCacheService.hierarchies.get(dmID);
            if (hierarchies) {
                const hier = hierarchies.get(hierarchyid);
                if (hier) {
                    return hier;
                }
            }
            if (!fresh) {
                await MultiCacheService.RefreshMultiCache(dmID);
                hierarchies = MultiCacheService.hierarchies.get(dmID);
                if (hierarchies) {
                    return hierarchies.get(hierarchyid);
                }
            }
        }
        return null;
    }



    public static async GetLevel(levelid: UUID, dmID: any): Promise<Level> {
        if (levelid) {
            const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

            let level = MultiCacheService.level.get(dmID);
            if (level) {
                const l = level.get(levelid);
                if (l) {
                    return l;
                }
            }
            if (!fresh) {
                await MultiCacheService.RefreshMultiCache(dmID);
                level = MultiCacheService.level.get(dmID);
                if (level) {
                    return level.get(levelid);
                }
            }
        }
        return null;
    }

    public static async GetMeasure(measureid: UUID, dmID: any): Promise<Measure> {
        if (measureid) {
            const fresh = await MultiCacheService.AwaitIsInitialized(dmID);

            let measures = MultiCacheService.measures.get(dmID);
            if (measures) {
                const m = measures.get(measureid);
                if (m) {
                    return m;
                }
            }
            if (!fresh) {
                await MultiCacheService.RefreshMultiCache(dmID);
                measures = MultiCacheService.measures.get(dmID);
                if (measures) {
                    return measures.get(measureid);
                }
            }
        }
        return null;
    }

    private static DropMultiCache(dmID) {
        MultiCacheService.cubes.set(dmID, new Map<UUID, Cube>());
        MultiCacheService.dimensions.set(dmID, new Map<UUID, Dimension>());
        MultiCacheService.hierarchies.set(dmID, new Map<UUID, Hierarchy>());
        MultiCacheService.level.set(dmID, new Map<UUID, Level>());
        MultiCacheService.measures.set(dmID, new Map<UUID, Measure>());
        MultiCacheService.databases.delete(dmID);
        MultiCacheService.InitializingObjects.delete(dmID);
    }

    private static async RefreshMultiCache(dmID) {
        const promise = new Promise((resolve, reject) => {
            MultiCacheService.DropMultiCache(dmID);
            const sub = new BehaviorSubject(false);
            MultiCacheService.InitializingObjects.set(dmID, sub);
            const service = InjectorHelper.InjectorInstance.get<MultiReportingService>(MultiReportingService);
            service.GetDatabase(dmID).subscribe((result) => {
                if (result) {
                    const database = plainToClass(Database, result);
                    MultiCacheService.databases.set(dmID, database);
                    MultiCacheService.FillCacheAndSetParents(dmID, database);
                }
                sub.next(true);
                resolve(null);
            }, () => {
                reject();
            });
        });
        return promise;
    }
}
