import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { MetaHelper } from '../../../helpers/meta.helper';
import { FormulaNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';

@Component({
    selector: 'tree-menu-tab',
    templateUrl: './tree.menu.tab.html',
    styleUrls: ['./tree.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeMenuTab extends ALayoutMenuTab {
    TableProperties;
    DisplayFormula = '';
    Variables = [];
    Layout;

    onLayoutSet() {
        this.ReadTableProperties();
    }

    ReadTableProperties() {
        this.TableProperties = null;
        this.Variables = [];
        if (this.Layout && this.SelectedItemValue) {
            const dsID = MetaHelper.FindDataSourceID(this.Layout, this.SelectedItemValue);
            if (dsID) {
                CacheService.ReadTable(dsID).then((data) => {
                    if (data) {
                        const variables = [];
                        this.TableProperties = data;
                        this.TableProperties.Fields.forEach((column) => {
                            const vni = new VariablesNodeInformation();
                            vni.VariableID = column.ID;
                            vni.Name = column.TranslatedCaption;
                            variables.push(vni);
                        });
                        this.Variables = variables;
                    }
                });
            }
        }
    }

    onLabelChanged() {
        if (this.SelectedItemValue) {
            const parser = new AdvancedFormulaParser();
            parser.SetVariables(this.Variables, FormulaInputMode.VariableName);
            try {
                this.SelectedItemValue.DisplayFormula = parser.Parse(this.DisplayFormula);
            } catch {
                if (this.SelectedItemValue.DisplayFormula) {
                    const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.SelectedItemValue.DisplayFormula);
                    if (formula) {
                        this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                    } else {
                        this.DisplayFormula = '';
                        this.SelectedItemValue.DisplayFormula = null;
                    }
                }
            }
            this.onStyleChanged('DisplayFormula');
        }
    }

    editFormula() {
        if (this.SelectedItemValue) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables,
                Formula: this.DisplayFormula,
                InputMode: FormulaInputMode.VariableName
            }, (r) => {
                if (r) {
                    this.DisplayFormula = r.Formula;
                    this.onLabelChanged();
                }
                return true;
            });
        }
    }

    onItemSet() {
        this.DisplayFormula = '';
        this.ReadTableProperties();
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.TreeSettings) {
                this.SelectedItemValue.TreeSettings = {};
            }
            if (this.SelectedItemValue.DisplayFormula) {
                const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.SelectedItemValue.DisplayFormula);
                if (formula) {
                    this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                }
            }
        }
    }
}
