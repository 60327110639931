import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry, WorkflowService } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-close-dialog-settings',
    templateUrl: './close.dialog.settings.html'
})
export class CloseDialogSettings extends WorkflowDialogContent {
    Data = new CloseDialogSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'closeDialogModule';
        reg.Caption = '@@Dialog schliessen';
        reg.GroupID = 'wfActions';
        reg.Index = 47;
        reg.SettingsControl = CloseDialogSettings;
        reg.SettingsTypeHelper = new CloseDialogSettingsDataHelper();
        reg.Executer = CloseDialogModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(CloseDialogSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class CloseDialogSettingsData {
    CloseButtonType: DialogButton = DialogButton.Ok;
}

export class CloseDialogSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new CloseDialogSettingsData();
    }
}

export class CloseDialogModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const dialog = WorkflowService.ActiveDialog.getValue();
        if (dialog) {
            if (status.ActualSettings) {
                if (status.ActualSettings.CloseButtonType === DialogButton.Ok) {
                    await dialog.okClicked();
                } else {
                    await dialog.cancelClicked();
                }
            } else {
                status.Logger.logError('Close dialog modul: No settings found.');
            }
            return 0;
        } else {
            status.Logger.logError('Close dialog modul: No dialog found.');
        }
        return super.execute(status);
    }
}
