import { BaseWorkerControl } from '../base.worker.control';
import { Component } from '@angular/core';
import { MediaService } from '../../../../../services/media.service';
import { plainToClass } from 'class-transformer';

@Component({
    selector: 'dbbackup-control',
    templateUrl: './dbbackup.worker.control.html',
    styleUrls:['./dbbackup.worker.css']
})
export class DBBackupControl extends BaseWorkerControl {
    MediaSources = [];
    Settings: DBBackupWorkerSettings;

    constructor(private service: MediaService) {
        super();
    }

    ngOnInit(): void {
        this.service.Get().subscribe(result => {
            if (result) {
                this.MediaSources = result;
            }
        });
    }

    OnTriggerSet() {
        let settings;
        if (this.TriggerValue && this.TriggerValue.ExecutionContext) {
            try {
                const json = JSON.parse(this.TriggerValue.ExecutionContext);
                if (json) {
                    settings = plainToClass(DBBackupWorkerSettings, json);
                }
            } catch { }
        }
        if (!settings) {
            settings = new DBBackupWorkerSettings();
        }
        this.Settings = settings;
        if (this.TriggerValue) {
            this.TriggerValue.ExecutionContextAsObject = settings;
        }
    }

    mediasourcechanged() {
        this.OnTriggerHasChanged();
    }
}

export class DBBackupWorkerSettings {
    MediaSourceID: string;
    Name: string;
    WithData: boolean;

    toJSON() {
        const retVal = new DBBackupWorkerSettings();
        retVal.MediaSourceID = this.MediaSourceID;
        retVal.Name = this.Name;
        retVal.WithData = this.WithData;
        return retVal;
    }
}
