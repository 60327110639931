import { MultiCacheService } from '../../../cache/multi/cache.service';
import { DateHelper } from '../../../helpers/date.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { FilterMultidimensional } from '../../../models/datadescription/multi/filter/filterMultidimensional.model';
import { MultiResult } from '../../../models/datadescription/multi/multiresult.model';
import { WhereSelects } from '../../../models/datadescription/multi/querydef.model';
import { DynamicTimeSettingsService } from '../../../services/dynamictimesettings.service';
import { AExecutionManipulation } from './a.execution.manipulation';

export class DynamicTimeMeasureReferencingManipulation extends AExecutionManipulation {
    async BeforeSplit() {
        if (this.Context && this.Context.DataDescriptionClone && this.Context.DataDescriptionClone.MeasureInfos) {
            let variables = {};
            if (this.Context.Variables) {
                variables = this.Context.Variables;
            }
            for (let i = 0; i < this.Context.DataDescriptionClone.MeasureInfos.length; i++) {
                const measure = this.Context.DataDescriptionClone.MeasureInfos[i];
                // Nur richtige Measures mit Dynamischer-Zeitreferenzierung überprüfen
                if (measure.Details && measure.Details.MeasureDynamicTimeReferencing
                    && measure.Details.MeasureDynamicTimeReferencing.LevelID && measure.Measure) {
                    const level = await MultiCacheService.GetLevel(measure.Details.MeasureDynamicTimeReferencing.LevelID,
                        this.Context.DataDescriptionClone.DataModelID);
                    if (level) {

                        let startDateCalculated: Date;
                        let endDateCalculated: Date;
                        if (measure.Details.MeasureDynamicTimeReferencing.DynamicStartTime) {
                            const query = {
                                Start: measure.Details.MeasureDynamicTimeReferencing.DynamicStartTime,
                                End: measure.Details.MeasureDynamicTimeReferencing.DynamicEndTime,
                                Filter: null
                            };
                            if (measure.Details.MeasureDynamicTimeReferencing.FilterVariable) {
                                const varValue = variables[measure.Details.MeasureDynamicTimeReferencing.FilterVariable];
                                if (Array.isArray(varValue) && varValue.length > 0) {
                                    query.Filter = varValue[0];
                                }
                            }
                            const service = InjectorHelper.InjectorInstance.get<DynamicTimeSettingsService>(DynamicTimeSettingsService);
                            const queryResult = await service.ExecuteTimeQuery(query).toPromise();
                            if (queryResult && queryResult.Start && queryResult.End) {
                                startDateCalculated = new Date(queryResult.Start);
                                endDateCalculated = new Date(queryResult.End);
                            }
                        } else if (measure.Details.MeasureDynamicTimeReferencing.DynamicEndTime) {
                            const query = {
                                Start: measure.Details.MeasureDynamicTimeReferencing.DynamicEndTime,
                                Filter: null
                            };
                            if (measure.Details.MeasureDynamicTimeReferencing.FilterVariable) {
                                const varValue = variables[measure.Details.MeasureDynamicTimeReferencing.FilterVariable];
                                if (Array.isArray(varValue) && varValue.length > 0) {
                                    query.Filter = varValue[0];
                                }
                            }
                            const service = InjectorHelper.InjectorInstance.get<DynamicTimeSettingsService>(DynamicTimeSettingsService);
                            const queryResult = await service.ExecuteTimeQuery(query).toPromise();
                            if (queryResult && queryResult.Start) {
                                endDateCalculated = new Date(queryResult.Start);
                            }
                        }
                        if (startDateCalculated || endDateCalculated) {
                            measure.Details.ReferencingSelects.some(ref => {
                                if (ref.IsDynamicTimeRef) {
                                    const index = measure.Details.ReferencingSelects.indexOf(ref);
                                    if (index > -1) {
                                        measure.Details.ReferencingSelects.splice(index, 1);
                                    }
                                    return true;
                                }
                                return false;
                            });

                            const refSelect = new FilterMultidimensional();
                            refSelect.Hierarchy = level.Parent.ID;
                            refSelect.Dimension = level.Parent.Parent.ID;
                            refSelect['IsDynamicTimeRef'] = true;
                            const selectWhere = new WhereSelects();
                            selectWhere.LevelId = level.ID;
                            if (startDateCalculated) {
                                selectWhere.Start = DateHelper.GetSerializeString(startDateCalculated);
                            }
                            if (endDateCalculated) {
                                selectWhere.End = DateHelper.GetSerializeString(endDateCalculated);
                            }
                            refSelect.Defaults.push(selectWhere);
                            measure.Details.ReferencingSelects.push(refSelect);
                        }
                    }
                }
            }
        }
    }

    async BeforeExecute() {
    }

    async AfterExecute(result: MultiResult) {
    }
}
