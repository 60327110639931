import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestBase } from "./request-base";

@Injectable()
export class PlanningService extends RequestBase {
    BasePath = 'api/planning';

    CheckOut(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CheckOut', coord, this.options);
    }
    CheckIn(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CheckIn', coord, this.options);
    }
    SaveValues(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveValues', coord, this.options);
    }
    CreateShadowCopy(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CreateShadowCopy', coord, this.options);
    }
    ApplyShadowCopy(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ApplyShadowCopy', coord, this.options);
    }
    UndoShadowCopy(coord): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/UndoShadowCopy', coord, this.options);
    }
}