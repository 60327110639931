export enum monitoringChartFunctions {
    CountByType,
    CountByDay,
    CountByHour,
    CountByLevel,
    CountWorkflowByUser,
    CountWorkflowByType,
    CountWorkflowSuccessful,
    CountByDayWorkflow
}
