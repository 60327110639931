import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[click-stop-propagation]'
})
export class ClickStopPropagation {
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();
  }
}

export class MenuKeyDownHelper {
    static onKeyDown(event: any): void {
        if (event && event.key !== 'Escape') {
            event.stopPropagation();
        }
    }
}
