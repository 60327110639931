import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslatedString } from '../../models/translatedstring.model';
import { KPIService } from '../../services/kpi.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'kpi-settings',
    templateUrl: '../base.list.settings.html'
})
export class KPISettings extends BaseListSettings {

    //public static GetSettingsEntry() {
    //    return {
    //        Caption: '@@KPIs',
    //        ID: 'kpisettings',
    //        Icon: 'table_chart',
    //        Index: 30,
    //        Parent: 'data',
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.KPIRight',
    //            Value: 1
    //        },
    //        Content: KPISettings
    //    };
    //}

    constructor(private service: KPIService, private translate: TranslateService,
        protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.service.GetKPIInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((item) => {
                    list.push({
                        Caption: item.Caption,
                        ID: item.SID
                    });
                });
                handler(list);
            }
        });
    }
    loadData(data: any) {
        if (data) {
            this.service.LoadKPI(data).subscribe(result => {
                if (result) {
                    this.setSelectedItem(result);
                }
            });
        }
    }
    getNewItem() {
        return {
            Caption: new TranslatedString(this.translate.instant('@@Neue KPI'))
        };
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die KPI \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@KPI \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@KPI loeschen');
        return retVal;
    }
    delete(data: any, handler: any) {
        this.service.DeleteKPI(data).subscribe(() => {
            handler();
        });
    }
    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@KPI \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@KPI speichern');
        return retVal;
    }
    saveInternal(item: any, handler: any) {
        this.service.SaveKPI(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }
    getContentType() {
        return KPIDetail;
    }
    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }
}

@Component({
    selector: 'kpi-settings-detail',
    templateUrl: './kpi.settings.html',
})
export class KPIDetail extends BaseListDetail {
    Categories = [];

    constructor(private service: KPIService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetKPICategoryInfos().subscribe(result => {
            if (result) {
                this.Categories = result;
            }
        });
    }
}
