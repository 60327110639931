import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { of } from 'rxjs';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { ListBoxThemeControl } from '../../../appbuilder/controls/listbox/listbox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { PrimeFlexSizes } from '../../../models/enums/primeflexsizes.enum';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';



@Component({
    selector: 'evi-bootstrap-grid',
    templateUrl: './bootstrapgrid.layout.html',
    styleUrls: ['./bootstrapgrid.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BootstrapGridLayout extends IBaseComponent {

    static Type: any = 'bootstrapgrid';
    static Default = {
        Editable: true,
        Type: 'bootstrapgrid'
    };

    ItemsValue;
    get Items() {
        return this.ItemsValue;
    }
    set Items(val) {
        this.ItemsValue = of(val);
    }
    dragType;
    dragItem;
    internalDrag = false;
    Rows=[]
    Dragging;

    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    onLayoutElementSet() {
        if (this.LayoutElementValue.Elements) {
            this.LayoutElementValue.Elements.forEach((item) => {
                if (item.Row != null) {

                }
            })
        }
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();

    }
    onLayoutElementChanged() {
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();
    }
    ControlInitialized() {
        let init = false;
        if (init) {
            this.LayoutElement.ValuesChanged.next();
        }

        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            if (this.LayoutElement.Selected) {
                this.dragItem = item.item;
                this.Dragging = true;
            }
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
        this.Subscriptions['viewtype'] = LayoutService.ViewType.subscribe((viewType) => {
            this.LayoutElement.ViewType = viewType;
            this.cdRef.detectChanges();
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
    }
    //#region DragHandling
    itemDrop(event, item) {
        let insert;
        insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
        insert.Parent = this.LayoutElement;
        insert.ClassDefinitions = [{Column:3}];
        this.LayoutElement.Elements.push(insert);
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(insert),
            Index: this.LayoutElementValue.Elements.length - 1,
            ParentID: this.LayoutElementValue.ID
        });
        this.cdRef.detectChanges();
    }
    //#endregion
    removeItem(item) {
        const index = this.LayoutElementValue.Elements.indexOf(item);
        if (index > -1) {
            this.LayoutElementValue.Elements.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
            this.cdRef.detectChanges();
        }
    }
    itemSelected(item, event) {
        if (!item.Selected && !this.FromBaseLayout) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
           
        }
    }
    trackByID(index, item) {
        return item.ID;
    }
    cdkDrop(event: CdkDragDrop<any[]>) {
        const item = this.LayoutElementValue.Elements[event.previousIndex];
        moveItemInArray(this.LayoutElementValue.Elements, event.previousIndex, event.currentIndex);
        LayoutService.OnLayoutElementMoved({
            ElementID: item.ID,
            Index: event.currentIndex,
            OldParentID: this.LayoutElementValue.ID,
            NewParentID: this.LayoutElementValue.ID
        });
    }
}
export class BootstrapGridPanel extends BasePanel {
    static override SIDS = ['9c674571-fa72-4031-9204-6c0316f691a7']
    
    static InitPanel() {
        this.InitSelectedItem();
        PROPERTYGROUPS.push({
            SID: '9c674571-fa72-4031-9204-6c0316f691a7',
            ID: 'bootstrapgridposition',
            Caption: '@@GridPosition',
            Index: 105,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['1fr'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item && item.Parent && item.Parent.ElementType == 'bootstrapgrid';
            }
        });
        PROPERTIES.push({
            ID: "ClassDefinitions",
            Parent: "bootstrapgridposition",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Column",
                    Parent: "bootstrapgridposition",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Column",
                    InitArgs: {
                        InputType: "number",
                        Minimum: 1,
                        Maximum: 12
                    }
                }, {
                    ID: "ColumnOffset",
                    Parent: "bootstrapgridposition",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@ColumnOffset",
                    InitArgs: {
                        InputType: "number",
                        Minimum: 1,
                        Maximum: 12
                    }
                }, {
                    ID: "ResponsiveSize",
                    Parent: "bootstrapgridposition",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@ResponsiveSize",
                    InitArgs: {
                        EnumSource: PrimeFlexSizes
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    const item:any = {};
                    item.Column = 3;
                    return item;
                },
                GetCaption: (item, index) => {
                    return item.Column
                }
            }
        });
    }
}