import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class IsNull extends AFormula {
    static StaticID = '044604a3-2770-4a31-abf1-7f5f5ce9d893';

    ID(): string {
        return IsNull.StaticID;
    }
    Name(): string {
        return 'IsNull';
    }
    Group(): string {
        return FormulaGroups.Functions;
    }
    OrderID(): number {
        return 2;
    }
    OperationType(): OperationType {
        return OperationType.Function;
    }
    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Argument') + ')';
    }
    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Type = ValueType.Bool;
        retVal.Value = false;
        if (args && args.length > 0) {
            const val = args[0];
            retVal.Value = val.Value === null || typeof val.Value === 'undefined';
        }
        return retVal;
    }
}
