import { ChartsMenuTab } from '../appbuilder/menutabs/charts/charts.menu.tab';
import { SeriesMenuControl } from '../appbuilder/menutabs/charts/series.menu.control';
import {
  ColumnVisibilityMenuTab,
  ColumnVisibilityPropertyMenuTab,
} from '../appbuilder/menutabs/columnvisibility/columnvisibility.menu.tab';
import { ContentMenuTab } from '../appbuilder/menutabs/content/content.menu.tab';
import {
  AMenuTabContent,
  AdvancedAccordion,
  ControlsMenuTab,
  ControlsMenuTabContent,
  LayoutAccordion,
  StaticAccordion,
  FormAccordion,
  DataAccordion,
  BlockAccordion,
  MediaAccordion,
  TemplateAccordion,
  WidgetAccordion,
} from '../appbuilder/menutabs/controls/controls.menu.tab';
import { CustomEventsMenuTab } from '../appbuilder/menutabs/customevents/customevents.menu.tab';
import { DataBindingMenuTab } from '../appbuilder/menutabs/databinding/databinding.menu.tab';
import {
  DataTableColumnsMenuTab,
  DataTableFixedColumnsMenuTab,
  DataTableIconsMappingDialog,
  DataTableSettingsMenuTab,
  DataTableStyleMenuTab,
} from '../appbuilder/menutabs/datatable/datatable.menu.tab';
import { EventsMenuTab } from '../appbuilder/menutabs/events/events.menu.tab';
import { GenericMenuTab } from '../appbuilder/menutabs/generic/generic.menu.tab';
import { LayoutMenuTab } from '../appbuilder/menutabs/layout/layout.menu.tab';
import { ListBoxMenuTab, ListBoxPropertyMenuTab, SimpleListBoxMenuTab } from '../appbuilder/menutabs/listbox/listbox.menu.tab';
import { PageSettingsMenuTab } from '../appbuilder/menutabs/pagesettings/pagesettings.menu.tab';
import { PropertiesMenuTab } from '../appbuilder/menutabs/properties/properties.menu.tab';
import { ResolutionMenuTab } from '../appbuilder/menutabs/resolution/resolution.menu.tab';
import { WorkflowMenuTabStatic } from '../appbuilder/menutabs/statics/workflow/workflow.static.menu.tab';
import { TemplateMenuTab } from '../appbuilder/menutabs/template/template.menu.tab';
import { TreeMenuTab } from '../appbuilder/menutabs/tree/tree.menu.tab';
import { TreeSettingsControl } from '../appbuilder/menutabs/tree/tree.settings.control';
import { VariablesMenuTab } from '../appbuilder/menutabs/variables/variables.menu.tab';
import { LogsWidgetMenu } from '../appbuilder/menutabs/widget/joblogs/joblogs.widget.menu';
import { JobsWidgetMenu } from '../appbuilder/menutabs/widget/jobs/jobs.widget.menu';
import { WidgetMenuTab } from '../appbuilder/menutabs/widget/widget.menu.tab';
import { WorkflowMenuTab } from '../appbuilder/menutabs/workflow/workflow.menu.tab';

export const APP_MENUTABS = [
  ContentMenuTab,
  WorkflowMenuTab,
  EventsMenuTab,
  ListBoxMenuTab,
  SimpleListBoxMenuTab,
  DataTableSettingsMenuTab,
  DataTableColumnsMenuTab,
  DataTableStyleMenuTab,
  DataTableFixedColumnsMenuTab,
  DataTableIconsMappingDialog,
  ChartsMenuTab,
  SeriesMenuControl,
  VariablesMenuTab,
  TreeMenuTab,
  TreeSettingsControl,
  DataBindingMenuTab,
  WorkflowMenuTabStatic,
  AMenuTabContent,
  ControlsMenuTabContent,
  AdvancedAccordion,
  StaticAccordion,
  FormAccordion,
  DataAccordion,
  BlockAccordion,
  MediaAccordion,
  LayoutAccordion,
  TemplateAccordion,
  WidgetAccordion,
  ControlsMenuTab,
  LayoutMenuTab,
  ResolutionMenuTab,
  PropertiesMenuTab,
  ListBoxPropertyMenuTab,
  ColumnVisibilityMenuTab,
  ColumnVisibilityPropertyMenuTab,
  PageSettingsMenuTab,
  CustomEventsMenuTab,
  GenericMenuTab,
  TemplateMenuTab,
  WidgetMenuTab,
  JobsWidgetMenu,
  LogsWidgetMenu,
];