import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { TranslateHelper } from '../../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-text-classification-model-settings',
    templateUrl: './text.classification.model.settings.html',
    styleUrls: ['./text.classification.css']
})
export class TextClassificationModelSettings extends WorkflowDialogContent {
    Data = new TextClassificationModelSettingsData();
    Columns = [];

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'textClassificationModelWFModule';
        reg.Caption = '@@Text Classification Model';
        reg.GroupID = 'aioperations';
        reg.Index = 240;
        reg.SettingsControl = TextClassificationModelSettings;
        reg.SettingsTypeHelper = new TextClassificationModelSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        reg.ObsoleteInfo = new ObsoleteInfo(); // GA-404
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(TextClassificationModelSettingsData, json);
        }
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource && dataSource.Columns) {
            if (!this.Data.TextColumns) {
                this.Data.TextColumns = [];
            }
            const list = [];
            let targetCol;
            dataSource.Columns.forEach(col => {
                const val = {
                    Name: col.Name,
                    TextCol: false,
                    Length: 0,
                    TargetCol: false
                };
                const textCol = this.Data.TextColumns.find(x => x.ColumnName === col.Name);
                if (textCol) {
                    val.TextCol = true;
                    val.Length = textCol.Length;
                } else if (this.Data.TargetColumn === col.Name) {
                    targetCol = val;
                }
                list.push(val);
            });
            if (targetCol) {
                targetCol.TargetCol = true;
            }
            this.Columns = list;
        }
    }

    getResult() {
        const textCols = [];
        this.Columns.forEach(col => {
            if (col.TextCol) {
                const tc = new TextClassificationColumn();
                tc.ColumnName = col.Name;
                tc.Length = col.Length;
                textCols.push(tc);
            } else if (col.TargetCol) {
                this.Data.TargetColumn = col.Name;
            }
        });
        this.Data.TextColumns = textCols;
        return this.Data;
    }

    targetColChanged(ev, col) {
        if (ev && ev.checked) {
            this.Columns.forEach(x => x.TargetCol = false);
            col.TargetCol = true;
        }
    }

    maxFeatureChanged(ev) {
        if (ev && ev.value) {
            this.Data.MaxFeatures = ev.value;
        }
    }

    percentChanged(ev) {
        if (ev && ev.value) {
            this.Data.Percent = ev.value;
        }
    }

    GetExpressionProperties() {
        return [
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Textspalten'),
                Value: 'TextColumns'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@MaxFeatures'),
                Value: 'MaxFeatures'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Zielspalte'),
                Value: 'TargetColumn'
            },
            {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Prozent'),
                Value: 'Percent'
            }
        ];
    }
}

// @dynamic
export class TextClassificationModelSettingsData extends AServiceWorkflowData {
    @Type(() => TextClassificationColumn)
    TextColumns: TextClassificationColumn[] = [];
    MaxFeatures = 5000;
    TargetColumn: string;
    Percent = 0.8;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.AI.TextClassificationModel.TextClassificationModelSettingsData';
    }
}

export class TextClassificationColumn {
    ColumnName: string;
    Length: number;
}

export class TextClassificationModelSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getEmptySettingsInstance() {
        return new TextClassificationModelSettingsData();
    }
}
