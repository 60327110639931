import { BaseWorkerControl } from "../base.worker.control";
import { Component, ViewChild, ElementRef } from "@angular/core";
import { UUID } from "angular2-uuid";
import { StandardRequestBase } from "../../../../../services/request-base";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { MediaService } from "../../../../../services/media.service";
import { DataModelService } from "../../../../../services/datamodel.service";

@Component({
    selector: 'capsule-export-worker-control',
    templateUrl: './capsule.export.worker.control.html',
    styleUrls: ['./capsule.export.worker.control.css']
})
export class CapsuleExportWorkerControl extends BaseWorkerControl {
    Settings;
    Version = {
        Major: 1,
        Minor: 0,
        Build: 0,
        Revision: 0
    };

    constructor(private service: StandardRequestBase, private mediaService: MediaService, private dmService: DataModelService) {
        super();
        this.filteredLangs = this.langCtrl.valueChanges.pipe(
            startWith(null),
            map((lang: string | null) => this._filter(lang)));
        this.filteredMS = this.mediaCtrl.valueChanges.pipe(
            startWith(null),
            map((ms: string | null) => this._filterMedia(ms)));
        this.filteredModels = this.modelCtrl.valueChanges.pipe(
            startWith(null),
            map((ms: string | null) => this._filterModel(ms)));
        this.filteredDS = this.dataSourcesCtrl.valueChanges.pipe(
            startWith(null),
            map((ms: string | null) => this._filterDataSources(ms)));
    }

    ngOnInit(): void {
        this.service.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(result => {
            if (result) {
                this.AllLanguages = result;
            }
        });
        this.mediaService.Get().subscribe(sources => {
            if (sources) {
                this.AllMediaSources = sources;
            }
        });
        this.dmService.GetModels().subscribe(result => {
            if (result) {
                this.AllDataModels = result;
            }
        });
        this.dmService.GetDataSourceInfos().subscribe(result => {
            if (result) {
                this.AllDataSources = result;
            }
        });
    }

    OnTriggerSet() {
        if (this.TriggerValue && this.TriggerValue.ExecutionContext) {
            this.Settings = JSON.parse(this.TriggerValue.ExecutionContext);
        }
        if (!this.Settings) {
            this.Settings = {
                Id: UUID.UUID(),
                Version: "1.0.0.0",
                Name: '',
                IsBackup: false,
                IncludeData: false,
                IncludeMailSettings: false,
                Languages: [],
                IncludeDataModels: [],
                IncludeMediaSources: [],
                ExludeMetaObjects: [],
                Target: 1,
                MediaTarget: {},
                Path: null
            };
        } else {
            if (this.Settings.Version) {
                var splits = this.Settings.Version.split();
                if (splits.length == 4) {
                    this.Version.Major = parseInt(splits[0]);
                    this.Version.Minor = parseInt(splits[1]);
                    this.Version.Build = parseInt(splits[2]);
                    this.Version.Revision = parseInt(splits[3]);
                } else {
                    this.Settings.Version = "1.0.0.0";
                }
            } else {
                this.Settings.Version = "1.0.0.0";
            }
            if (!this.Settings.MediaTarget) {
                this.Settings.MediaTarget = {}
            }
        }
        if (this.TriggerValue) {
            this.TriggerValue.ExecutionContextAsObject = this.Settings;
        }
    }

    OnVersionChanged() {
        if (this.Settings) {
            this.Settings.Version = this.Version.Major + '.' + this.Version.Minor + '.' + this.Version.Build + '.' + this.Version.Revision;
            this.OnTriggerHasChanged();
        }
    }

    //#region Languages
    separatorKeysCodes: number[] = [ENTER, COMMA];
    AllLanguages = [];
    langCtrl = new UntypedFormControl();
    filteredLangs: Observable<string[]>;
    @ViewChild('langInput') langInput: ElementRef<HTMLInputElement>;

    getLanguages() {
        var retVal = [];
        if (this.Settings && this.Settings.Languages) {
            this.Settings.Languages.forEach(lang => {
                this.AllLanguages.some(al => {
                    if (al.LCID == lang) {
                        retVal.push(al);
                        return true;
                    }
                    return false;
                });
            });
        }
        return retVal;
    }

    removeLang(lang) {
        if (this.Settings && this.Settings.Languages) {
            var index = this.Settings.Languages.indexOf(lang);
            if (index >= 0 && index < this.Settings.Languages.length) {
                this.Settings.Languages.splice(index, 1);
            }
        }
    }

    langSelected(ev) {
        if (this.Settings) {
            if (!this.Settings.Languages) {
                this.Settings.Languages = [];
            }
            this.Settings.Languages.push(ev.option.value);
        }
        this.langInput.nativeElement.value = '';
        this.langCtrl.setValue(null);
    }

    private _filter(value: string): any[] {
        var languages = [];
        if (this.Settings && this.Settings.Languages) {
            languages = this.Settings.Languages;
        }
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();
            return this.AllLanguages.filter(lang => lang.Caption.toLowerCase().indexOf(filterValue) === 0 && languages.indexOf(lang.LCID) < 0);
        }
        if (languages.length == 0) {
            return this.AllLanguages;
        }
        return this.AllLanguages.filter(lang => languages.indexOf(lang.LCID) < 0);
    }
    //#endregion

    //#region MediaSources
    mediaCtrl = new UntypedFormControl();
    filteredMS: Observable<string[]>;
    @ViewChild('mediaInput') mediaInput: ElementRef<HTMLInputElement>;
    AllMediaSources = [];

    getMediaSources() {
        var retVal = [];
        if (this.Settings && this.Settings.IncludeMediaSources) {
            this.Settings.IncludeMediaSources.forEach(ms => {
                this.AllMediaSources.some(am => {
                    if (am.SID == ms) {
                        retVal.push(am);
                        return true;
                    }
                    return false;
                });
            });
        }
        return retVal;
    }

    removeMedia(media) {
        if (this.Settings && this.Settings.IncludeMediaSources) {
            var index = this.Settings.IncludeMediaSources.indexOf(media);
            if (index >= 0 && index < this.Settings.IncludeMediaSources.length) {
                this.Settings.IncludeMediaSources.splice(index, 1);
            }
        }
    }

    mediaSelected(ev) {
        if (this.Settings) {
            if (!this.Settings.IncludeMediaSources) {
                this.Settings.IncludeMediaSources = [];
            }
            this.Settings.IncludeMediaSources.push(ev.option.value);
        }
        this.mediaInput.nativeElement.value = '';
        this.mediaCtrl.setValue(null);
    }

    private _filterMedia(value: string): any[] {
        var media = [];
        if (this.Settings && this.Settings.IncludeMediaSources) {
            media = this.Settings.IncludeMediaSources;
        }
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();
            return this.AllMediaSources.filter(ms => ms.Caption.toLowerCase().indexOf(filterValue) === 0 && media.indexOf(ms.SID) < 0);
        }
        if (media.length == 0) {
            return this.AllMediaSources;
        }
        return this.AllMediaSources.filter(ms => media.indexOf(ms.SID) < 0);
    }
    //#endregion

    //#region DataModels
    modelCtrl = new UntypedFormControl();
    filteredModels: Observable<string[]>;
    @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;
    AllDataModels = [];
    SelectedModel;

    getModels() {
        var retVal = [];
        if (this.Settings && this.Settings.IncludeDataModels) {
            this.Settings.IncludeDataModels.forEach(ms => {
                this.AllDataModels.some(am => {
                    if (am.SID == ms.Id) {
                        retVal.push(am);
                        return true;
                    }
                    return false;
                });
            });
        }
        return retVal;
    }

    removeModel(model) {
        if (this.Settings && this.Settings.IncludeDataModels) {
            var index = -1;
            var actIndex = 0;
            this.Settings.IncludeDataModels.some(dm => {
                if (dm.Id == model) {
                    index = actIndex;
                    if (this.SelectedModel && this.SelectedModel.Id == model) {
                        this.SelectedModel = null;
                        this.dsInput.nativeElement.value = '';
                        this.dataSourcesCtrl.setValue(null);
                    }
                    return true;
                }
                actIndex++;
            });
            if (index >= 0) {
                this.Settings.IncludeDataModels.splice(index, 1);
            }
        }
    }

    modelSelected(ev) {
        if (this.Settings) {
            if (!this.Settings.IncludeDataModels) {
                this.Settings.IncludeDataModels = [];
            }
            this.Settings.IncludeDataModels.push({
                Id: ev.option.value,
                IncludeDataSources: []
            });
        }
        this.modelInput.nativeElement.value = '';
        this.modelCtrl.setValue(null);
    }

    private _filterModel(value: string): any[] {
        var media = [];
        if (this.Settings && this.Settings.IncludeDataModels) {
            this.Settings.IncludeDataModels.forEach(dm => {
                media.push(dm.Id);
            });
        }
        if (typeof value === 'string') {
            const filterValue = value.toLowerCase();
            return this.AllDataModels.filter(ms => ms.Caption.toLowerCase().indexOf(filterValue) === 0 && media.indexOf(ms.SID) < 0);
        }
        if (media.length == 0) {
            return this.AllDataModels;
        }
        return this.AllDataModels.filter(ms => media.indexOf(ms.SID) < 0);
    }

    onModelSelected(id) {
        this.SelectedModel = null;
        if (this.Settings && this.Settings.IncludeDataModels) {
            this.Settings.IncludeDataModels.some(dm => {
                if (dm.Id == id) {
                    this.SelectedModel = dm;
                    return true;
                }
                return false;
            });
        }
        this.dsInput.nativeElement.value = '';
        this.dataSourcesCtrl.setValue(null);
    }

    isModelSelected(id) {
        return this.SelectedModel && this.SelectedModel.Id == id;
    }
    //#endregion

    //#region DataSources
    dataSourcesCtrl = new UntypedFormControl();
    filteredDS: Observable<string[]>;
    @ViewChild('dsInput') dsInput: ElementRef<HTMLInputElement>;
    AllDataSources = [];

    getDataSources() {
        var retVal = [];
        if (this.SelectedModel && this.SelectedModel.IncludeDataSources) {
            this.SelectedModel.IncludeDataSources.forEach(ds => {
                this.AllDataSources.some(as => {
                    if (as.SID == ds.Id) {
                        retVal.push(as);
                        return true;
                    }
                    return false;
                });
            });
        }    
        return retVal;
    }

    removeDataSource(ds) {
        if (this.SelectedModel && this.SelectedModel.IncludeDataSources) {
            var index = -1;
            var actIndex = 0;
            this.SelectedModel.IncludeDataSources.some(dm => {
                if (dm.Id == ds) {
                    index = actIndex;
                    return true;
                }
                actIndex++;
            });
            if (index >= 0) {
                this.SelectedModel.IncludeDataSources.splice(index, 1);
            }
        }
    }

    dsSelected(ev) {
        if (this.SelectedModel) {
            if (!this.SelectedModel.IncludeDataSources) {
                this.SelectedModel.IncludeDataSources = [];
            }
            this.SelectedModel.IncludeDataSources.push({
                Id: ev.option.value
            });
        }
        this.dsInput.nativeElement.value = '';
        this.dataSourcesCtrl.setValue(null);
    }

    private _filterDataSources(value: string): any[] {
        if (this.SelectedModel) {
            var sources = [];
            if (this.SelectedModel.IncludeDataSources) {
                this.SelectedModel.IncludeDataSources.forEach(ds => {
                    sources.push(ds.Id);
                });
            }
            if (typeof value === 'string') {
                const filterValue = value.toLowerCase();
                return this.AllDataSources.filter(ms => ms.DataModelID == this.SelectedModel.Id && ms.Caption.toLowerCase().indexOf(filterValue) === 0 && sources.indexOf(ms.SID) < 0);
            }
            return this.AllDataSources.filter(ms => ms.DataModelID == this.SelectedModel.Id && sources.indexOf(ms.SID) < 0);
        }
        return [];
    }
    //#endregion
}