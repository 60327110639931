import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { MessageSettingsService } from '../../../settings/messagesettings/MessageSettingsService';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-sendmessage-settings',
    templateUrl: './sendmessage.settings.html',
    styleUrls: ['./sendmessage.settings.css']
})
export class SendMessageSettings extends FormulaWorkflowDialogContent {
    Data = new SendMessageSettingsData();
    SettingsList = [];
    VariableList = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'sendMessageWFModule';
        reg.Caption = '@@Nachricht senden';
        reg.GroupID = 'wfActions';
        reg.Index = 120;
        reg.SettingsControl = SendMessageSettings;
        reg.SettingsTypeHelper = new SendMessageSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        reg.ObsoleteInfo = new ObsoleteInfo();
        return reg;
    }

    constructor(private service: MessageSettingsService) {
        super();
    }

    ngOnInit(): void {
        this.service.LoadMessageSettingsInfos().subscribe(result => {
            if (result) {
                this.SettingsList = result;
                this.updateVariables();
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SendMessageSettingsData, json);
        }
    }

    getResult(): any {
        this.Data.Variables = this.VariableList;
        return this.Data;
    }

    fromStatusChanged(ev) {
        if (ev && ev.checked) {
            this.Data.CustomText = '';
        }
    }

    fromStatusChangedVar(ev, v) {
        if (ev && ev.checked) {
            v.Text = '';
        }
    }

    updateVariables() {
        this.VariableList = [];
        const data = this.Data;
        if (data.SettingsID) {
            this.service.GetMessageSettingVariables(this.Data.SettingsID).subscribe(result => {
                if (result) {
                    const list = [];
                    result.forEach(x => {
                        let v = data.Variables.find(y => y.Key === x);
                        if (!v) {
                            v = new SendMessageVariable();
                            v.Key = x;
                        }
                        list.push(v);
                    });
                    this.VariableList = list;
                }
            });
        }
    }
}

// @dynamic
export class SendMessageSettingsData extends AServiceWorkflowData {
    SettingsID: string;
    CustomText: string;
    FromStatus = false;
    ReplaceVariables = false;
    @Type(() => SendMessageVariable)
    Variables: SendMessageVariable[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.SendMessage.SendMessageSettingsData';
    }
}

export class SendMessageVariable {
    Key: string;
    Text: string;
}

export class SendMessageSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SendMessageSettingsData();
    }
}
