import { Component } from '@angular/core';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { BaseDialog } from '../basedialog/base.dialog';

@Component({
    selector: 'layout.edit.dialog',
    templateUrl: './layout.edit.dialog.html'
})
export class LayoutEditDialog {
    DoEdit = false;

    static ShowDialog(handler) {
        BaseDialog.ShowDialog({
            ContentType: LayoutEditDialog,
            Buttons: DialogButton.Ok,
            AutoFocus: false,
            Handler: (r) => {
                handler(r);
                return true;
            },
            Title: '@@EditLayout'
        });
    }

    GetDialogResult() {
        return this.DoEdit;
    }
}
