import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { TemplateEditClient } from '../../../helpers/template.edit.client';
import { Layout } from '../../../models/layout.model';
import { LayoutEditService } from '../../../models/layout/layout.edit.client';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';

@Component({
    selector: 'template-menu-tab',
    templateUrl: './template.menu.tab.html',
    styleUrls: ['./template.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateMenuTab extends ALayoutMenuTab {
    Template;

    static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        const layout = LayoutService.SelectedLayout.getValue();
        menuItem.Visible = selected && selected.ElementType == 'template' && selected != layout;
    }

    constructor(cdRef: ChangeDetectorRef, private metaService: MetaService) {
        super(cdRef);
    }

    ngOnInit() {
        super.ngOnInit();
        this.metaService.LoadTemplateInfo(this.SelectedItem._Id).subscribe(x => {
            this.Template = x;
            this.cdRef.detectChanges();
        });
    }

    edit() {
        if (this.Template && this.Template.CanEditInfo && this.Template.CanEditInfo.CanEdit) {
            this.metaService.LoadTemplate(this.Template.SID).subscribe(template => {
                if (template) {
                    const layout = plainToClass(Layout, template);
                    const editClient = new TemplateEditClient(layout, this.Template, this.metaService);
                    editClient.TemplateInfoChanged.subscribe(pi => {
                        this.Template = pi;
                        this.cdRef.detectChanges();
                    });
                    LayoutEditService.SetNext(editClient, true);
                }
            });
        }
    }
}
