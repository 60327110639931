import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { TypeNameLoader } from '../../../components/controls/layout.tree';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow/workflow.dialog';

@Component({
    selector: 'read-selected-coordinates-wf-settings',
    templateUrl: './read.selected.coordinates.settings.html'
})
export class ReadSelectedCoordinatesSettings extends WorkflowDialogContent {
    Data = new ReadSelectedCoordinatesSettingsData();
    NodeLoader: TypeNameLoader;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readSelectedCoordinatesWFModule';
        reg.Caption = '@@Koordinaten auslesen';
        reg.GroupID = 'report';
        reg.Index = 20;
        reg.SettingsControl = ReadSelectedCoordinatesSettings;
        reg.SettingsTypeHelper = new ReadSelectedCoordinatesSettingsDataHelper();
        reg.Executer = ReadSelectedCoordinatesModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.NodeLoader = new TypeNameLoader();
        this.NodeLoader.TypeNames.push('tablereportObject');
        this.NodeLoader.TypeNames.push('chartreportObject');
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadSelectedCoordinatesSettingsData, json);
        }
    }

    getResult(): any {
        const objects = [];
        this.Data.Objects.forEach(obj => {
            if (obj.ObjectName && (obj.UseForState || obj.StatusKey)) {
                objects.push(obj);
            }
        });
        this.Data.Objects = objects;
        return this.Data;
    }

    addObject() {
        if (this.Data) {
            if (this.Data.Objects) {
                this.Data.Objects.push(new CoordinateObjectData());
            } else {
                this.Data.Objects = [new CoordinateObjectData()];
            }
        }
    }

    removeObject(i) {
        if (this.Data && this.Data.Objects) {
            this.Data.Objects.splice(i, 1);
        }
    }

    useForStateChecked(ev, obj) {
        if (ev && ev.checked && this.Data && this.Data.Objects) {
            this.Data.Objects.forEach(x => {
                x.UseForState = false;
            });
            obj.UseForState = true;
        }
    }
}

// @dynamic
export class CoordinateObjectData {
    ObjectName: string;
    StatusKey: string;
    UseForState = false;
}

// @dynamic
export class ReadSelectedCoordinatesSettingsData {
    @Type(() => CoordinateObjectData)
    Objects: CoordinateObjectData[] = [];
}

export class ReadSelectedCoordinatesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadSelectedCoordinatesSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Objects) {
                const list = [];
                settings.Objects.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class ReadSelectedCoordinatesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Objects) {
                status.ActualSettings.Objects.forEach(obj => {
                    const elem = status.WorkflowLayoutService.getElementByName(obj.ObjectName);
                    if (elem) {
                        if (elem.Element) {
                            if (typeof elem.Element.GetSelectedCoordinates === 'function') {
                                const coordinates = elem.Element.GetSelectedCoordinates();
                                if (obj.StatusKey) {
                                    status.Context.set(obj.StatusKey, coordinates);
                                }
                                if (obj.UseForState === true) {
                                    status.ActualParameter = coordinates;
                                }
                            } else {
                                status.Logger.logWarning('ReadCoordinates modul: Control with name ' +
                                    obj.ObjectName + ' does not deliver coordinates.');
                            }
                        } else {
                            status.Logger.logWarning('ReadCoordinates modul: Control with name ' + obj.ObjectName + ' not found.');
                        }
                    } else {
                        status.Logger.logWarning('ReadCoordinates modul: Element with name ' + obj.ObjectName + ' not found.');
                    }
                });
                return 0;
            }
        } else {
            status.Logger.logError('ReadCoordinates module: No settings found.');
        }
        return super.execute(status);
    }
}
