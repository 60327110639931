import { Output, Input, EventEmitter, Component } from '@angular/core';

@Component({
  selector: 'default-group-header',
  templateUrl: './default.group.header.html',
  styleUrls: ['./default.group.header.css']
})
export class DefaultGroupHeader {
  HeaderValue;
  @Input()
  get Header() {
    return this.HeaderValue;
  }
  set Header(val) {
    this.HeaderValue = val;
    this.HeaderValueChange.emit(val);
  }

  @Output() HeaderValueChange = new EventEmitter<any>();
}
