import { WorkflowErrorType } from '../models/enums/workflowerrortype.enum';
import { DebugSessionStateData, DebugSessionStateDataType, WorkflowDebugSessionData } from '../models/workflow/debug.log.message';
import { BreakPointInfo } from '../models/workflow/workflow.debug.settings';
import {
    IWaitForDebugHandler,
    StepIntoHandler,
    WorkflowConnectorData, WorkflowData, WorkflowDebugState, WorkflowModuleData, WorkflowModuleExecuter, WorkflowStatus
} from '../models/workflow/workflow.model';
import { WF_REGISTRY } from '../services/workflow.service';

export class WorkflowEngine implements IWaitForDebugHandler {
    private _ModulDict: Map<number, WorkflowModuleData>;
    private _ConnectorDict: Map<number, Map<number, WorkflowConnectorData>>;
    private _ActualModul: WorkflowModuleData;
    private _WorkflowErrorType: WorkflowErrorType = WorkflowErrorType.None;
    private _IsExecuting = false;
    private _ExecuterDict: Map<number, WorkflowModuleExecuter>;
    private _StartOfModule = false;
    CheckExpressions = false;
    PageSID;

    constructor(saveObj: WorkflowData, private _Status: WorkflowStatus) {
        if (!saveObj) {
            throw new Error('Workflow-settings not set!');
        }
        if (!_Status) {
            throw new Error('Workflow-status not set!');
        }

        _Status.GenerateLog = saveObj.GenerateLog;

        const checkDict = new Map<number, WorkflowModuleData>();
        const modDict = new Map<number, WorkflowModuleData>();
        this._WorkflowErrorType = saveObj.ErrorType;
        saveObj.Modules.forEach(mod => {
            modDict.set(mod.ID, mod);
            checkDict.set(mod.ID, mod);
        });
        this._ModulDict = modDict;

        const conDict = new Map<number, Map<number, WorkflowConnectorData>>();
        saveObj.Connectors.forEach(con => {
            let innerDict = conDict.get(con.ExitModule);
            if (!innerDict) {
                innerDict = new Map<number, WorkflowConnectorData>();
                conDict.set(con.ExitModule, innerDict);
            }
            innerDict.set(con.ExitOption, con);
            checkDict.delete(con.EntryModule);
            if (typeof con.EntryOption !== 'number') {
                con.EntryOption = 0;
            }
        });
        this._ConnectorDict = conDict;

        if (checkDict.size > 1) {
            throw new Error('More than one start module found!');
        }
        if (checkDict.size === 0) {
            throw new Error('No start module found!');
        }
        this._ActualModul = checkDict.values().next().value;
        this._ExecuterDict = new Map();
        this.PageSID = this._Status.WorkflowLayoutService.Layout['_Id'];
    }

    async startExecution() {
        if (this._Status.DebugStateHandler) {
            this._Status.DebugStateHandler.Init();
        }
        this._IsExecuting = true;
        this._Status.Logger.SetErrorType(this._WorkflowErrorType);
        this._Status.Logger.logInfo('Execution started.');
        while (this._IsExecuting) {
            this._StartOfModule = true;
            if (this._Status.DebugStateHandler) {
                await this._Status.DebugStateHandler.WaitForDebug(this);
            }
            const nextStep = await this.executeActualModul();
            this._StartOfModule = false;
            if (this._Status.DebugStateHandler) {
                await this._Status.DebugStateHandler.WaitForDebug(this);
            }
            this._IsExecuting = this.findNextStep(nextStep);
        }
        this._Status.Logger.logInfo('Execution finished.');
        if (this._Status.DebugStateHandler) {
            this._Status.DebugStateHandler.OnWorkflowFinished();
        }
    }

    private async executeActualModul(): Promise<number> {
        const desc = WF_REGISTRY.get(this._ActualModul.Module);
        this._Status.Logger.SetActualModule(this._ActualModul);
        if (desc) {
            let action = this._ExecuterDict.get(this._ActualModul.ID);
            if (!action) {
                if (desc.Executer) {
                    action = <WorkflowModuleExecuter>(new desc.Executer());
                    if (action) {
                        action.ModulID = this._ActualModul.ID;
                        this._ExecuterDict.set(this._ActualModul.ID, action);
                    }
                }
            }
            if (action) {
                this._Status.Logger.logInfo('Execution started');
                if (this.CheckExpressions) {
                    this._Status.Logger.logInfo('Check for expressions started');
                    if (this._ActualModul.Expressions && this._ActualModul.Expressions.length > 0) {
                        const state = this._Status;
                        const settings = this._ActualModul.Settings;
                        if (settings) {
                            this._ActualModul.Expressions.forEach(exp => {
                                // Hier gegenbenenfalls irgendwann mal Formeln berechnen
                                const val = state.Context.get(exp.Formula);
                                settings[exp.Property] = val;
                            });
                        }
                    }
                    this._Status.Logger.logInfo('Check for expressions finished');
                }
                this._Status.ActualSettings = this._ActualModul.Settings;
                try {
                    const retVal = await action.execute(this._Status);
                    this._Status.Logger.logInfo('Execution finished');
                    this._Status.Logger.SetActualModule(null);
                    return retVal;
                } catch (ex) {
                    this._Status.Logger.logError('Exception while executing: ' + ex.toString());
                }
            } else {
                this._Status.Logger.logError('Action could not be found.');
            }
        } else {
            this._Status.Logger.logError('Module with id ' + this._ActualModul.ID + ' could not be found.');
        }
        this._Status.Logger.SetActualModule(null);
        return -1;
    }    

    private findNextStep(nextStep: number): boolean {
        this._Status.ActualEntryOption = 0;
        let nextID = -1;
        if (nextStep > -1) {
            
            const loop = this._Status.GetActualLoop();
            if (loop) {
                const loopDict = this._ConnectorDict.get(loop.ModulID);
                if (loopDict) {
                    const loopcon = loopDict.get(1);
                    if (loopcon && loopcon.EntryModule === this._ActualModul.ID) {
                        nextID = loop.ModulID;
                    }
                }
            }

            if (nextID < 0) {
                const innerDict = this._ConnectorDict.get(this._ActualModul.ID);
                if (innerDict) {
                    const connector = innerDict.get(nextStep);
                    if (connector) {
                        nextID = connector.EntryModule;
                        this._Status.ActualEntryOption = connector.EntryOption;
                    }
                }
            }
            if (nextID < 0 && loop) {
                nextID = loop.ModulID;
            }
        } else {
            const innerDict = this._ConnectorDict.get(this._ActualModul.ID);
            if (innerDict) {
                const connector = innerDict.get(nextStep);
                if (connector) {
                    nextID = connector.EntryModule;
                    this._Status.ActualEntryOption = connector.EntryOption;
                }
            }
        }
        if (nextID >= 0) {
            const nextModul = this._ModulDict.get(nextID);
            if (nextModul) {
                this._ActualModul = nextModul;
                return true;
            }
        }
        return false;
    }

    //#region Debug
    RemoveHandler(handler: StepIntoHandler): boolean {
        return !this._StartOfModule && this._ActualModul && handler &&
            handler.WorkflowID === this._Status.WorkflowID &&
            handler.ModuleID === this._ActualModul.ID;
    }
    GetNewHandler(): StepIntoHandler {
        if (this._StartOfModule && this._ActualModul) {
            const desc = WF_REGISTRY.get(this._ActualModul.Module);
            if (desc && desc.SettingsTypeHelper) {
                let settings = this._ActualModul.Settings;
                if (typeof settings === 'string') {
                    settings = JSON.parse(settings);
                }
                return desc.SettingsTypeHelper.getStepIntoHandler(settings, this._Status.WorkflowID, this._ActualModul.ID);
            }
        }
        return null;
    }
    CheckBreakPoints(breakpoints: BreakPointInfo[]): boolean {
        if (breakpoints && this._ActualModul) {
            const bp = breakpoints.find(x => x.ModuleID === this._ActualModul.ID);
            if (bp) {
                if (this._StartOfModule) {
                    return bp.BreakOnStart;
                } else {
                    return bp.BreakOnEnd;
                }
            }
        }
        return false;
    }
    GetStateData(runID: string, state: WorkflowDebugState): DebugSessionStateData {
        const dssd = new DebugSessionStateData();
        dssd.DataType = DebugSessionStateDataType.Workflow;
        const wfData = new WorkflowDebugSessionData();
        wfData.ModuleID = this._ActualModul.ID;
        wfData.PageSID = this.PageSID;
        wfData.WorkflowID = this._Status.WorkflowID;
        wfData.Start = this._StartOfModule;
        if (state.Breakpoints) {
            const bpList = state.Breakpoints[runID];
            if (Array.isArray(bpList)) {
                wfData.Breakpoints = bpList;
            }
        }
        if (this._Status.DebugStateHandler) {
            wfData.AdditionalData = this._Status.DebugStateHandler.GetAndRemoveAdditionalData(wfData.WorkflowID);
        }
        dssd.Data = wfData;
        return dssd;
    }
    SetAdditionalStatusInfos(status) {
    }
    //#endregion
}
