import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { DateHelper } from '../../../helpers/date.helper';
import { PickerMode } from '../../../models/enums/pickermode.enum';
import { StartView } from '../../../models/enums/pickerstartview.enum';
import { PickerType } from '../../../models/enums/pickertype.enum';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from 'src/app/helpers/layout.helper';
import { DateTimeThemeControl } from 'src/app/appbuilder/controls/datetime/datetime.control';
import { TextboxThemeControl } from 'src/app/appbuilder/controls/textbox/textbox.theme.control';

@Component({
    selector: 'evi-datetimepicker',
    templateUrl: './datetimepicker.control.html',
    styleUrls: ['./datetimepicker.control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimePickerControl extends IBaseComponent {

    static Type: any = 'datetimepicker';
    static Default = {
        Source: [], Type: 'datetimepicker', Layout: {
            _Editable: true,
            ...DateTimePickerControl.setDefaultProp()
        }
    };

    // set default properties
    static setDefaultProp() {
        return {
            Height: {Type: 0, Value: 52},
            Width: {Type: 0, Value: 250},
            UseBorder: true,
            Border: {
                LeftBorder: {
                    Thickness: {
                        Type: 0,
                        Value: 2
                    },
                    Style: 0,
                    Color: {
                        StartPoint: {
                            X: 0,
                            Y: 0
                        },
                        EndPoint: {
                            X: 0,
                            Y: 0
                        },
                        Colors: [
                            {
                                Color: {
                                    A: 255,
                                    R: 185,
                                    G: 219,
                                    B: 237
                                },
                                Offset: 0
                            }
                        ],
                        Type: 1
                    }
                },
                TopBorder: {
                    Thickness: {
                        Type: 0,
                        Value: 2
                    },
                    Style: 0,
                    Color: {
                        StartPoint: {
                            X: 0,
                            Y: 0
                        },
                        EndPoint: {
                            X: 0,
                            Y: 0
                        },
                        Colors: [
                            {
                                Color: {
                                    A: 255,
                                    R: 185,
                                    G: 219,
                                    B: 237
                                },
                                Offset: 0
                            }
                        ],
                        Type: 1
                    }
                },
                RightBorder: {
                    Thickness: {
                        Type: 0,
                        Value: 2
                    },
                    Style: 0,
                    Color: {
                        StartPoint: {
                            X: 0,
                            Y: 0
                        },
                        EndPoint: {
                            X: 0,
                            Y: 0
                        },
                        Colors: [
                            {
                                Color: {
                                    A: 255,
                                    R: 185,
                                    G: 219,
                                    B: 237
                                },
                                Offset: 0
                            }
                        ],
                        Type: 1
                    }
                },
                BottomBorder: {
                    Thickness: {
                        Type: 0,
                        Value: 2
                    },
                    Style: 0,
                    Color: {
                        StartPoint: {
                            X: 0,
                            Y: 0
                        },
                        EndPoint: {
                            X: 0,
                            Y: 0
                        },
                        Colors: [
                            {
                                Color: {
                                    A: 255,
                                    R: 185,
                                    G: 219,
                                    B: 237
                                },
                                Offset: 0
                            }
                        ],
                        Type: 1
                    }
                },
                BorderRadius: null
            }

        }
    }

    SpecificDays;
    SpecificMonths;
    FixedRange;
    RangeType;
    DynamicRange;
    SpecificDMY;
    SpecificDMY_Number;
    SpecificDMY_Type;
    //#region DataSource
    DataSourceValue;
    selectedPastFutureDate;
    selectedDynamicCustomDateType;
    ExplicitMinDate;
    ExplicitMaxDate;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
        this.cdRef.detectChanges();
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    //#region SelectedDate
    SelectedDateValue;

    @Input()
    get SelectedDate() {
        return this.SelectedDateValue;
    }
    set SelectedDate(val) {
        this.SelectedDateValue = val;
        this.SelectedDateChange.emit(this.SelectedDateValue);
        this.cdRef.detectChanges();
    }

    @Output() SelectedDateChange = new EventEmitter<any>();
    //#endregion

    //region PickerType
    @Input()
    get PickerType() {
        if (!this.LayoutElement.PickerType) {
            return PickerType[0];
        } else {
            return PickerType[this.LayoutElement.PickerType];
        }
    }
    set PickerType(val) {
        this.LayoutElement.PickerType = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //region PickerMode
    @Input()
    get PickerMode() {
        if (!this.LayoutElement.PickerMode) {
            return PickerMode[0];
        } else {
            return PickerMode[this.LayoutElement.PickerMode];
        }

    }
    set PickerMode(val) {
        this.LayoutElement.PickerMode = val;
        this.cdRef.detectChanges();
    }



    //#endregion

    //region StartView
    @Input()
    get StartView() {
        if (!this.LayoutElement.StartView) {
            return StartView[0];
        } else {
            return StartView[this.LayoutElement.StartView];
        }

    }
    set StartView(val) {
        this.LayoutElement.StartView = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
        this.cdRef.detectChanges();
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    @Output() DateChanged = new EventEmitter<any>();


    constructor(translate: TranslateService, private dateTimeAdapter: DateTimeAdapter<any>, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('datechanged');
        this.setLocale(translate.currentLang);
        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setLocale(event.lang);
        });
    }

    ControlInitialized() {
        LayoutService.SelectedItem.subscribe((item) => {
            this.RangeType = this.LayoutElement.RangeType;
            this.FixedRange = this.LayoutElement.FixedRange?.toString();
            this.DynamicRange = this.LayoutElement.DynamicRange;
            this.SpecificDays = this.LayoutElement.SpecificDays?.toString();
            this.SpecificDMY = this.LayoutElement.SpecificDMY
            this.SpecificDMY_Number = this.LayoutElement.SpecificDMY_Number
            this.SpecificDMY_Type = this.LayoutElement.SpecificDMY_Type;
            this.selectedPastFutureDate = this.LayoutElement.PastFutureDate;
            this.selectedDynamicCustomDateType = this.LayoutElement.DynamicCustomDateType;
            this.SpecificMonths = this.LayoutElement.SpecificMonths;
            this.ExplicitMinDate = this.LayoutElement.ExplicitMinDate;
            this.ExplicitMaxDate = this.LayoutElement.ExplicitMaxDate;
        });
    }

    private getDayOfYear(date: Date): number {
        const startOfYear = new Date(date.getFullYear(), 0, 1); // January 1st
        const timeDifference = date.getTime() - startOfYear.getTime();
        const dayOfYear = Math.floor(timeDifference / (24 * 60 * 60 * 1000)) + 1;
        return dayOfYear;
    }


    myFilter = (d: Date): boolean => {
        const selectedMonth = this.dateTimeAdapter.getMonth(d)
        const dayOfWeek = this.dateTimeAdapter.getDay(d);
        if (this.LayoutElement.UseValidation === 1) {
            //Specific days conditions
            if (this.SpecificMonths && this.SpecificDays) {
                return this.SpecificMonthCheck(selectedMonth) && this.SpecificDayCheck(dayOfWeek);
            }
            else if (this.SpecificMonths) {
                return this.SpecificMonthCheck(selectedMonth);
            }
            else if (this.SpecificDays) {
                return this.SpecificDayCheck(dayOfWeek);
            }
            else return true
        }
        else if (this.LayoutElement.UseValidation === 2) {
            //Range conditions
            if ((this.RangeType === 0 && this.FixedRange && this.SpecificDays) || (this.RangeType === 1 && this.DynamicRange && this.SpecificDays)) {
                //Range and specifc days condition
                return this.RangeCheck(d, this.RangeType) && this.SpecificDayCheck(dayOfWeek);
            }
            else if ((this.RangeType === 0 && this.FixedRange) || (this.RangeType === 1 && this.DynamicRange)) {
                return this.RangeCheck(d, this.RangeType);
            }
            else return true;

        }
        else if (this.LayoutElement.UseValidation === 3) {
            //SpecificDMY
            if (this.SpecificDMY !== undefined && this.SpecificDMY_Number !== undefined && this.SpecificDMY_Type !== undefined && this.SpecificDays) {
                //SpecificDMY and specific days
                return this.SpecificDMYCheck(d) && this.SpecificDayCheck(dayOfWeek);
            }
            else if (this.SpecificDMY !== undefined && this.SpecificDMY_Number !== undefined && this.SpecificDMY_Type !== undefined) {
                //Specific DMY
                return this.SpecificDMYCheck(d)
            }
            return true;
        }
        else if (this.LayoutElement.UseValidation === 4 && this.selectedPastFutureDate !== undefined && this.selectedDynamicCustomDateType === 0) {
            //Past/Future
            if (this.SpecificDays && this.selectedPastFutureDate !== undefined && this.selectedDynamicCustomDateType === 0) {
                return this.PastFutureDateCheck(d) && this.SpecificDayCheck(dayOfWeek)
            }
            else if (this.selectedPastFutureDate !== undefined && this.selectedDynamicCustomDateType === 0) {
                return this.PastFutureDateCheck(d)
            }
            else return true;
        }
        else if (this.LayoutElement.UseValidation === 4 && this.selectedPastFutureDate !== undefined && this.selectedDynamicCustomDateType === 1) {
            //Past/Future
            if (this.SpecificDays) {
                return this.SpecificDayCheck(dayOfWeek)
            }
            else return true;
        }
        else return true
    }

    PastFutureDateCheck(d: Date) {
        const currentDate = new Date();
        if (this.selectedPastFutureDate === 0 && this.selectedDynamicCustomDateType === 0) {
            return d <= currentDate;
        }
        else if (this.selectedPastFutureDate === 1 && this.selectedDynamicCustomDateType === 0) {
            return d >= currentDate;
        }
    }
    RangeCheck(d: Date, Type: string): boolean {
        if (Type == '0') {
            return this.FixedRangeCheck(d);
        }
        else {
            if (this.DynamicRange) {
                return this.DynamicRangeCheck(d);
            }
        }
    }

    FixedRangeCheck(d: Date): boolean {
        if (this.FixedRange != undefined) {
            const dateStrings = this.FixedRange.split(',');
            if (dateStrings.length === 2) {
                const startDate = new Date(dateStrings[0].trim());
                const endDate = new Date(dateStrings[1].trim());
                if ((startDate != null && endDate != null)) {
                    return d >= startDate && d <= endDate;
                }
            }
        }
    }

    SpecificDayCheck(dayOfWeek: Number): boolean {
        return this.SpecificDays.includes(dayOfWeek)
    }

    SpecificMonthCheck(selectedMonth: Number): boolean {
        return this.SpecificMonths.includes(selectedMonth)
    }

    DynamicRangeCheck(d: Date): boolean {
        const currentDate = new Date();
        let endDate: Date = null;
        if (this.DynamicRange > 0) {
            // If DynamicRange is non-negative, calculate the end date as the current date plus DynamicRange days.
            endDate = new Date(currentDate.getTime() + this.DynamicRange * 24 * 60 * 60 * 1000);
            return d >= currentDate && d <= endDate;
        } else if (this.DynamicRange < 0) {
            // If DynamicRange is negative, calculate the end date as the current date minus the absolute value of DynamicRange days.
            endDate = new Date(currentDate.getTime() - Math.abs(this.DynamicRange) * 24 * 60 * 60 * 1000);
            return d <= currentDate && d >= endDate;
        }
        else return false
    }


    SpecificDMYCheck(d: Date): boolean {
        if (this.SpecificDMY_Number !== '') {
            if (this.SpecificDMY_Type !== '') {
                let selectedDate = new Date(d);
                if (this.SpecificDMY_Type == '0') {
                    if (this.SpecificDMY == '0') {
                        const selectedDayOfYear = this.getDayOfYear(d);
                        return selectedDayOfYear >= 1 && selectedDayOfYear <= this.SpecificDMY_Number;
                    }
                    else if (this.SpecificDMY == '1') {
                        const lastDayOfYear = new Date(d.getFullYear(), 11, 31);
                        const firstDayOfRange = new Date(
                            lastDayOfYear.getFullYear(),
                            lastDayOfYear.getMonth(),
                            lastDayOfYear.getDate() - this.SpecificDMY_Number + 1
                        );
                        return d >= firstDayOfRange && d <= lastDayOfYear;
                    }
                }
                else if (this.SpecificDMY_Type == '1') {
                    const selectedWeekNumber = this.SpecificDMY_Number;

                    if (this.SpecificDMY == '0') { // First weeks of the year
                        const startOfSelectedWeek = new Date(selectedDate.getFullYear(), 0, 1);
                        const endOfSelectedWeek = new Date(startOfSelectedWeek);
                        endOfSelectedWeek.setDate(startOfSelectedWeek.getDate() + (7 * selectedWeekNumber - 1));
                        return d >= startOfSelectedWeek && d <= endOfSelectedWeek;
                    } else if (this.SpecificDMY == '1') { // Last weeks of the year
                        const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
                        const startOfLastWeek = new Date(endOfYear);
                        startOfLastWeek.setDate(endOfYear.getDate() - 7 * selectedWeekNumber + 1); // Adjust the calculation
                        const endOfSelectedWeek = new Date(endOfYear);

                        return d >= startOfLastWeek && d <= endOfSelectedWeek;
                    }

                }
                else {
                    if (this.SpecificDMY == '0') {
                        const selectedMonth = d.getMonth();
                        return selectedMonth >= 0 && selectedMonth < this.SpecificDMY_Number;
                    }
                    else if (this.SpecificDMY == '1') {
                        const selectedMonth = d.getMonth();
                        const firstMonthOfLast8Months = 12 - this.SpecificDMY_Number; // December (11) - 7 = May 
                        return selectedMonth >= firstMonthOfLast8Months && selectedMonth <= 11;
                    }
                }
            }
        }
    }

    setLocale(lang) {
        const langinfo = LanguageService.Languages.getValue().find(l => l.LCID.toString() === lang);
        if (langinfo) {
            this.dateTimeAdapter.setLocale(langinfo.Name);
            //this.cdRef.detectChanges();
        }
    }

    onDateChanged(ev) {
        this.SelectedDate = this.DataSource;
        this.DateChanged.emit(this.SelectedDate);
        let json = '';
        if (this.SelectedDate) {
            json = DateHelper.GetSerializeString(this.SelectedDate);
        }
        this.triggerEvent('datechanged', json);
    }



    setFocus() {
        let control = document.getElementById(this.LayoutElement.ID);
        if (control) {
            control.focus();
        }
    }

}
export class DateTimePickerPanel extends BasePanel {
    private static removeValidations(SelectedItem = null, validations: string[] = []): void {
        if (SelectedItem !== null && validations.length > 0) {
            validations.forEach((validation) => {
                delete SelectedItem[validation]
            })
        }
    }
    static override SIDS = ['a01941d2-eeab-42c4-be8a-2e49cef5cf44']
    static validations = ["PastFutureDate", "DynamicCustomDateType",
        "ExplicitMinDate", "SpecificDays",
        "SpecificMonths", "ExplicitMaxDate",
        "RangeType", "FixedRange",
        "DynamicRange", "SpecificDMY",
        "SpecificDMY_Number", "SpecificDMY_Type"]
    static InitPanel() {

        PROPERTYGROUPS.push({
            SID: 'a01941d2-eeab-42c4-be8a-2e49cef5cf44',
            ID: 'datetimepicker',
            Caption: '@@DateTimePicker',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'datetimepicker';
            }
        });
        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "datetimepicker",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "PickerType",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@PickerType",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: PickerType
            }
        })
        PROPERTIES.push({
            ID: "PickerMode",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@PickerMode",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: PickerMode
            }
        })
        PROPERTIES.push({
            ID: "StartView",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@StartView",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                EnumSource: StartView
            }
        })
        PROPERTIES.push({
            ID: "FirstDayOfWeek",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@FirstDayOfWeek",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Monday', Value: 1 },
                    { Caption: '@@Tuesday', Value: 2 },
                    { Caption: '@@Wednesday', Value: 3 },
                    { Caption: '@@Thursday', Value: 4 },
                    { Caption: '@@Friday', Value: 5 },
                    { Caption: '@@Saturday', Value: 6 },
                    { Caption: '@@Sunday', Value: 0 }
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value'
            }
        })
        PROPERTIES.push({
            ID: "UseValidation",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@UseValidation",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Specific', Value: 1 },
                    { Caption: '@@Range', Value: 2 },
                    { Caption: '@@First/Last', Value: 3 },
                    { Caption: '@@Past/Future', Value: 4 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (ev) => {
                    let selectedValidation = ev.SelectedItem.UseValidation;
                    if (selectedValidation === 1) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "SpecificMonths"
                        ))
                    }
                    else if (selectedValidation === 2) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "SpecificMonths"
                            && validation !== "RangeType" && validation !== "FixedRange"
                            && validation !== "DynamicRange"
                        ))
                    }
                    else if (selectedValidation === 3) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "SpecificMonths"
                            && validation !== "SpecificDMY" && validation !== "SpecificDMY_Number"
                            && validation !== "SpecificDMY_Type"
                        ))
                    }
                    else if (selectedValidation === 4) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "SpecificMonths"
                            && validation !== "PastFutureDate" && validation !== "DynamicCustomDateType"
                            && validation !== "ExplicitMinDate" && validation !== "ExplicitMaxDate"
                        ))
                    }
                }
            }
        })
        PROPERTIES.push({
            ID: "Inline",
            Parent: "datetimepicker",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Inline"
            }
        });
        PROPERTIES.push({
            ID: "Hour12Timer",
            Parent: "datetimepicker",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Hour12Timer"
            }
        });
        // PROPERTIES.push({
        //     ID: "Disabled",
        //     Parent: "datetimepicker",
        //     Content: new ComponentPortal(CheckBoxThemeControl),
        //     InitArgs: {
        //         Caption: "@@Disabled"
        //     }
        // });
        // PROPERTIES.push({
        //     ID: "UseValidation",
        //     Parent: "datetimepicker",
        //     Content: new ComponentPortal(CheckBoxThemeControl),
        //     InitArgs: {
        //         Caption: "@@UseValidation",
        //         Action: (ev) => {
        //             LayoutHelper.RefreshMenuTabs.next(null);
        //         }
        //     }
        // });

        PROPERTIES.push({
            ID: "PastFutureDate",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Past/Future Date",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Past Only', Value: 0 },
                    { Caption: '@@Future Only', Value: 1 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (ev) => {
                    this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                        validation !== "SpecificDays" && validation !== "PastFutureDate"
                        && validation!=="DynamicCustomDateType"
                    ))
                }
            },

            CheckVisibility: (item) => {
                return item.UseValidation === 4;
            },
        });
        PROPERTIES.push({
            ID: "DynamicCustomDateType",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Dynamic/Custom Date",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Dynamic', Value: 0 },
                    { Caption: '@@Custom', Value: 1 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (ev) => {
                    const DynamicCustomDateType = ev.SelectedItem.DynamicCustomDateType
                    if (DynamicCustomDateType === 0) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "PastFutureDate"
                            && validation !== "DynamicCustomDateType"
                        ))
                    }
                    else if (DynamicCustomDateType === 1) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "SpecificDays" && validation !== "PastFutureDate"
                            && validation !== "DynamicCustomDateType"
                        ))
                    }
                }
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.PastFutureDate
                });
                return item.UseValidation === 4 && (DateType == '0' || DateType == '1');
            },
        });
        PROPERTIES.push({
            ID: "ExplicitMinDate",
            Parent: "datetimepicker",
            Content: new ComponentPortal(DateTimeThemeControl),
            Label: "@@Past Date",
            InitArgs: {
                InputType: 'single',
                Action: (ev) => {
                    this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                        validation !== "SpecificDays" && validation !== "PastFutureDate"
                        && validation !== "DynamicCustomDateType" && validation !== "ExplicitMinDate"
                    ))
                }
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.PastFutureDate
                });
                return item.UseValidation === 4 && item.DynamicCustomDateType == '1' && DateType == '0';
            }
        });
        PROPERTIES.push({
            ID: "ExplicitMaxDate",
            Parent: "datetimepicker",
            Content: new ComponentPortal(DateTimeThemeControl),
            Label: "@@Future Date",
            InitArgs: {
                InputType: 'single',
                Action: (ev) => {
                    this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                        validation !== "SpecificDays" && validation !== "PastFutureDate"
                        && validation !== "DynamicCustomDateType" && validation !== "ExplicitMaxDate"
                    ))
                }
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.PastFutureDate
                });
                return item.UseValidation === 4 && item.DynamicCustomDateType == '1' && DateType == '1';
            },
        });
        PROPERTIES.push({
            ID: "SpecificDays",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Specific Days",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: true,
                ItemsSource: [
                    { Caption: '@@Monday', Value: 1 },
                    { Caption: '@@Tuesday', Value: 2 },
                    { Caption: '@@Wednesday', Value: 3 },
                    { Caption: '@@Thursday', Value: 4 },
                    { Caption: '@@Friday', Value: 5 },
                    { Caption: '@@Saturday', Value: 6 },
                    { Caption: '@@Sunday', Value: 0 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
            },
            CheckVisibility: (item) => {
                return item.UseValidation;
            },
        });

        PROPERTIES.push({
            ID: "SpecificMonths",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Specific Months",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: true,
                ItemsSource: [
                    { Caption: '@@January', Value: 0 },
                    { Caption: '@@February', Value: 1 },
                    { Caption: '@@March', Value: 2 },
                    { Caption: '@@April', Value: 3 },
                    { Caption: '@@May', Value: 4 },
                    { Caption: '@@June', Value: 5 },
                    { Caption: '@@July', Value: 6 },
                    { Caption: '@@August', Value: 7 },
                    { Caption: '@@September', Value: 8 },
                    { Caption: '@@October', Value: 9 },
                    { Caption: '@@November', Value: 10 },
                    { Caption: '@@December', Value: 11 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
            },
            CheckVisibility: (item) => {
                return item.UseValidation === 1;
            },
        });
        PROPERTIES.push({
            ID: "RangeType",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Fixed Range/Dynamic Range",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Fixed Range', Value: 0 },
                    { Caption: '@@Dynamic Range', Value: 1 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (ev) => {
                    const rangeType = ev.SelectedItem.RangeType
                    if (rangeType === 0) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "RangeType" && validation !== "FixedRange" && validation!=="SpecificDays"
                        ))
                    }
                    else if (rangeType === 1) {
                        this.removeValidations(ev.SelectedItem, this.validations.filter(validation =>
                            validation !== "RangeType" && validation !== "DynamicRange" && validation!=="SpecificDays"
                        ))
                    }

                }
            },

            CheckVisibility: (item) => {
                return item.UseValidation === 2;
            },
        });
        PROPERTIES.push({
            ID: "FixedRange",
            Parent: "datetimepicker",
            Content: new ComponentPortal(DateTimeThemeControl),
            Label: "@@Fixed Range Date",
            InitArgs: {
                InputType: "range",
                PickerType: "calendar"
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.RangeType
                });

                return item.UseValidation === 2 && DateType == '0';
            },
        });

        PROPERTIES.push({
            ID: "DynamicRange",
            Parent: "datetimepicker",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Dynamic Range Date",
            InitArgs: {
                InputType: 'Number',
                // Minimum: 0
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.RangeType
                });

                return item.UseValidation === 2 && DateType == '1';
            },

        });
        PROPERTIES.push({
            ID: "SpecificDMY",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@First/Last",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@First', Value: 0 },
                    { Caption: '@@Last', Value: 1 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
            },

            CheckVisibility: (item) => {
                return item.UseValidation === 3;
            },
        });

        PROPERTIES.push({
            ID: "SpecificDMY_Number",
            Parent: "datetimepicker",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Number",
            InitArgs: {
                InputType: 'Number',
                Minimum: 0
            },
            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.SpecificDMY
                });

                return item.UseValidation === 3;
            },
        });

        PROPERTIES.push({
            ID: "SpecificDMY_Type",
            Parent: "datetimepicker",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Day/Week/Month",
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: [
                    { Caption: '@@Days', Value: 0 },
                    { Caption: '@@Weeks', Value: 1 },
                    { Caption: '@@Months', Value: 2 },
                ],
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
            },

            CheckVisibility: (item) => {
                let DateType;
                LayoutService.SelectedItem.subscribe((item) => {
                    DateType = item.SpecificDMY
                });
                return item.UseValidation === 3;
            },
        });

    }

}


