import { Component } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { BaseDialog } from '../basedialog/base.dialog';
import { MessageBoxHelper } from '../messagebox/messagebox.dialog';

@Component({
    selector: 'change-key-dialog',
    templateUrl: './change.key.dialog.html'
})
export class ChangeKeyDialog {
    Result = '';
    ForbiddenKeys = [];
    CheckKey = false;
    PageName = "";
    ShowPageName: boolean = false;
    static ShowDialog(forbiddenKeys: string[], checkKey: boolean, handler, PageName: string = "", ShowPageName: boolean = false) {
        BaseDialog.ShowDialog({
            ContentType: ChangeKeyDialog,
            InitArgs: {
                ForbiddenKeys: forbiddenKeys,
                CheckKey: checkKey,
                PageName: PageName,
                ShowPageName: ShowPageName
            },
            Title: '@@ChangeKeyDialog',
            Handler: (x, pg) => {
                if (x && handler) {
                    if (x && pg) {
                        handler(x, pg);
                    }
                    else handler(x);
                }
                return true;
            }
        })
    }

    static ShowMessage(x) {
        if (typeof x === 'number') {
            let text: TranslateFormatText;
            let header: TranslateFormatText;
            switch (x) {
                case 1: // EmptyKey
                    text = new TranslateFormatText('@@EmptyKeyText');
                    header = new TranslateFormatText('@@EmptyKeyHeader');
                    break;
                case 2: // ObjectNotFound
                    text = new TranslateFormatText('@@ObjectNotFoundText');
                    header = new TranslateFormatText('@@ObjectNotFoundHeader');
                    break;
                case 3: // SameKey
                    text = new TranslateFormatText('@@SameKeyText');
                    header = new TranslateFormatText('@@SameKeyHeader');
                    break;
                case 4: // NoChange
                    text = new TranslateFormatText('@@NoChangeText');
                    header = new TranslateFormatText('@@NoChangeHeader');
                    break;
                case 5: // KeyExisting
                    text = new TranslateFormatText('@@KeyAlreadyExistingText');
                    header = new TranslateFormatText('@@KeyAlreadyExistingHeader');
                    break;
                case 6: // IsLocked
                    text = new TranslateFormatText('@@ObjectLockedText');
                    header = new TranslateFormatText('@@ObjectLockedHeader');
                    break;
                case 7: // IsCapsule
                    text = new TranslateFormatText('@@CapsuleObjectText');
                    header = new TranslateFormatText('@@CapsuleObjectHeader');
                    break;

                case 8: // Empty page name
                    text = new TranslateFormatText('@@EmptyPageName');
                    header = new TranslateFormatText('@@EmptyPageHeader');
                    break;
                default:
                    return;
            }
            MessageBoxHelper.ShowDialog(text, header, MessageBoxButtons.Ok, MessageBoxIcon.Error);
        }
    }

    Initialize(data) {
        if (data) {
            if (Array.isArray(data.ForbiddenKeys)) {
                data.ForbiddenKeys.forEach(fk => {
                    if (typeof fk === 'string') {
                        this.ForbiddenKeys.push(fk.toLowerCase());
                    }
                });
            }
            if (typeof data.CheckKey === 'boolean') {
                this.CheckKey = data.CheckKey;
            }
            if (typeof data.PageName === 'string') {
                this.PageName = data.PageName;
            }
            if (data?.ShowPageName !== undefined && data?.ShowPageName !== null) {
                this.ShowPageName = data.ShowPageName;
            }
        }
    }

    GetDialogResult() {
        return this.Result;
    }
    GetPageName() {
        return this.PageName;
    }
    OnDialogButtonClickAction(button) {
        if (button == DialogButton.Ok) {
            if (this.ShowPageName === true && this.PageName === "") {
                ChangeKeyDialog.ShowMessage(8);
                return false
            }
            if (this.Result) {
                const toLower = this.Result.toLowerCase();
                if (this.ForbiddenKeys.some(x => x == toLower)) {
                    ChangeKeyDialog.ShowMessage(4);
                    return false;
                }
            } else {
                ChangeKeyDialog.ShowMessage(1);
                return false;
            }
        }
        return true;
    }
}
