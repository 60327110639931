import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import {
    AnnotationChartSettings, AxisSettings, BubbleChartSettings, CalendarChartSettings, ChartSettings, ColorAxisValue,
    ColorRangeValue, ContinuousAxisSettings, GeoChartSettings, HeatMapChartSettings, LegendSettings, SeriesSettings
} from '../../../models/controls/chart.model';
import { ChartGeoResolution, ChartStackType } from '../../../models/enums/chartcurvetype.enum';
import { ChartLegendPosition } from '../../../models/enums/chartlegendposition.enum';
import { ChartType } from '../../../models/enums/charttype.enum';
import { SeriesType } from '../../../models/enums/seriestype.enum';
import { Color } from '../../../models/style/color.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { NavigationService } from '../../../services/navigation.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'charts-menu-tab',
    templateUrl: './charts.menu.tab.html',
    styleUrls: ['./charts.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartsMenuTab extends APropertyTab {
    get ShowLegend(): boolean {
        if (this.ChartSettingsValue && this.ChartSettingsValue.Legend) {
            return this.ChartSettingsValue.Legend.Position !== ChartLegendPosition.none;
        }
        return true;
    }
    set ShowLegend(val: boolean) {
        if (this.ChartSettingsValue) {
            if (val) {
                if (this.ChartSettingsValue.Legend && this.ChartSettingsValue.Legend.Position === ChartLegendPosition.none) {
                    this.ChartSettingsValue.Legend.Position = ChartLegendPosition.right;
                }
            } else {
                if (!this.ChartSettingsValue.Legend) {
                    this.ChartSettingsValue.Legend = new LegendSettings();
                }
                this.ChartSettingsValue.Legend.Position = ChartLegendPosition.none;
            }
        }
    }

    ChartSettingsValue: ChartSettings = ChartSettings.GetDefault();
    Layout;
    DefaultAxisColumns = [];
    NumericColumns = [];
    TextColumns = [];
    DateColumns = [];
    ListColumns = [];
    charttypes;
    resolutions;
    Initialized = false;
    AxisIsDateColumn = false;
    ChartPalettes = [];

    DefaultChartStackType;

    private static CheckPropList(col: SeriesSettings, list: { Prop: string, List: any[] }[]) {
        if (col && list) {
            list.forEach(x => {
                const colName = col[x.Prop];
                if (colName && !x.List.some(y => y.Name === colName)) {
                    col[x.Prop] = null;
                }
            });
        }
    }

    constructor(cdRef: ChangeDetectorRef, private service: MetaService) {
        super(cdRef);
        this.charttypes = EnumHelper.GetDropdownValues(ChartType);
        this.resolutions = EnumHelper.GetDropdownValues(ChartGeoResolution);
        this.DefaultChartStackType = EnumHelper.GetDropdownValues(ChartStackType);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const page = NavigationService.SelectedPage.getValue();
        if (page === 'content' || page === 'widget' || page === 'template' || page === 'output' || page === 'layout') {
            this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
                this.Layout = layout;
                this.checkLayout();
            }));
        }
        this.service.ReadPaletteInfos().subscribe(x => {
            if (x) {
                this.ChartPalettes = x;
            }
        });
    }

    checkLayout() {
        this.DefaultAxisColumns = [];
        this.NumericColumns = [];
        this.TextColumns = [];
        this.DateColumns = [];
        if (this.Layout) {
            MetaHelper.FindTableProperties(this.Layout, this.SelectedItem).then((result) => {
                this.Initialized = true;
                if (result && result.Fields) {
                    const defCols = [];
                    const numCols = [];
                    const textCols = [];
                    const dateCols = [];
                    const listCols = [];
                    result.Fields.forEach((column) => {
                        if (column.DataTyp === 'System.Double' || column.DataTyp === 'System.Int32' || column.DataTyp === 'System.Int64') {
                            numCols.push(column);
                            defCols.push(column);
                        } else if (column.DataTyp === 'System.String') {
                            textCols.push(column);
                            defCols.push(column);
                        } else if (column.DataTyp === 'System.DateTime') {
                            dateCols.push(column);
                            defCols.push(column);
                        }
                        if (column.IsList) {
                            listCols.push(column);
                        }
                    });
                    this.DefaultAxisColumns = defCols;
                    this.NumericColumns = numCols;
                    this.TextColumns = textCols;
                    this.DateColumns = dateCols;
                    this.ListColumns = listCols;
                    this.updateChartType();
                }
            });
        } else {
            this.updateChartType();
        }
    }

    onItemSet() {
        if (this.SelectedItem) {
            if (this.SelectedItem.ChartSettings) {
                this.SelectedItem.ChartSettings = plainToClass(ChartSettings, this.SelectedItem.ChartSettings);
            } else {
                this.SelectedItem.ChartSettings = ChartSettings.GetDefault();
            }
            this.ChartSettingsValue = this.SelectedItem.ChartSettings;
        } else {
            this.ChartSettingsValue = ChartSettings.GetDefault();
        }
        this.updateChartType();
    }

    updateChartType() {
        if (this.Initialized) {
            const axisColumn = this.ChartSettingsValue['AxisColumn'];
            switch (this.ChartSettingsValue.ChartType) {
                case ChartType.AnnotationChart:
                    if (!this.ChartSettingsValue.AnnotationSettings) {
                        this.ChartSettingsValue.AnnotationSettings = new AnnotationChartSettings();
                    }
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DateColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [
                                { Prop: 'ColumnName', List: this.NumericColumns },
                                { Prop: 'AnnotationTitleColumn', List: this.TextColumns },
                                { Prop: 'AnnotationTextColumn', List: this.TextColumns },
                            ]);
                        });
                    }
                    break;
                case ChartType.AreaChart:
                case ChartType.Bar:
                case ChartType.BarChart:
                case ChartType.ColumnChart:
                case ChartType.Line:
                case ChartType.LineChart:
                case ChartType.ScatterChart:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.Histogram:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.polarArea:
                case ChartType.radar:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.heatmap:
                    if (!this.ChartSettingsValue.HeatMapSettings) {
                        this.ChartSettingsValue.HeatMapSettings = new HeatMapChartSettings();
                    }
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                    break;
                case ChartType.ComboChart:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            if (col.Type === SeriesType.candlesticks) {
                                ChartsMenuTab.CheckPropList(col, [
                                    { Prop: 'Minimum', List: this.NumericColumns },
                                    { Prop: 'Initial', List: this.NumericColumns },
                                    { Prop: 'Final', List: this.NumericColumns },
                                    { Prop: 'Maximum', List: this.NumericColumns }
                                ]);
                            } else {
                                ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                            }
                        });
                    }
                    break;
                case ChartType.BubbleChart:
                    if (!this.ChartSettingsValue.BubbleSettings) {
                        this.ChartSettingsValue.BubbleSettings = new BubbleChartSettings();
                    }
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, []);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.TextColumns },
                            { Prop: 'XValue', List: this.NumericColumns },
                            { Prop: 'YValue', List: this.NumericColumns },
                            { Prop: 'Radius', List: this.NumericColumns },
                            { Prop: 'Grouping', List: this.NumericColumns.concat(this.TextColumns) }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.PieChart:
                case ChartType.DonutChart:
                case ChartType.Gauge:
                case ChartType.radialBar:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.GeoChart:
                    if (!this.ChartSettingsValue.GeoSettings) {
                        this.ChartSettingsValue.GeoSettings = new GeoChartSettings();
                    }
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Map:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.SteppedAreaChart:
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.WordTree:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'LabelColumn', List: this.TextColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'ColumnName', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.OrgChart:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'Description', List: this.DefaultAxisColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.TreeMap:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'ColumnName', List: this.NumericColumns },
                            { Prop: 'ColorValue', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Calendar:
                    if (!this.ChartSettingsValue.CalendarSettings) {
                        this.ChartSettingsValue.CalendarSettings = new CalendarChartSettings();
                    }
                    if (axisColumn) {
                        ChartsMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DateColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'ColumnName', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }

                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.Gantt:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'TaskID', List: this.TextColumns },
                            { Prop: 'TaskName', List: this.TextColumns },
                            { Prop: 'Grouping', List: this.TextColumns },
                            { Prop: 'StartValue', List: this.DateColumns },
                            { Prop: 'EndValue', List: this.DateColumns },
                            { Prop: 'Duration', List: this.NumericColumns },
                            { Prop: 'Completed', List: this.NumericColumns },
                            { Prop: 'Dependency', List: this.TextColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Timeline:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        const concatList = this.DateColumns.concat(this.NumericColumns);
                        ChartsMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'ColumnName', List: this.TextColumns },
                            { Prop: 'StartValue', List: concatList },
                            { Prop: 'EndValue', List: concatList },
                            { Prop: 'LabelColumn', List: this.TextColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.CandlestickChart:
                    if (axisColumn) {
                        const colName = axisColumn.ColumnName;
                        if (colName && !this.DefaultAxisColumns.some(x => x.Name === colName)) {
                            axisColumn.ColumnName = null;
                        }
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartsMenuTab.CheckPropList(col, [
                                { Prop: 'Minimum', List: this.NumericColumns },
                                { Prop: 'Initial', List: this.NumericColumns },
                                { Prop: 'Final', List: this.NumericColumns },
                                { Prop: 'Maximum', List: this.NumericColumns }
                            ]);
                        });
                    }
                    break;
                case ChartType.Sunburst:
                    if (axisColumn) {
                        const colName = axisColumn.ColumnName;
                        if (colName && !this.DefaultAxisColumns.some(x => x.Name === colName)) {
                            axisColumn.ColumnName = null;
                        }
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (!this.ChartSettingsValue['CenterRadius']) {
                        this.ChartSettingsValue['CenterRadius'] = 0.1;
                    }
                    if (!this.ChartSettingsValue['RadiusScaleExponent']) {
                        this.ChartSettingsValue['RadiusScaleExponent'] = 0.5;
                    }
                    break;
            }
        }
    }

    optionChanged(prop: string) {
        if (this.SelectedItemValue && this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.ChartPropChanged === 'function') {
            this.SelectedItemValue.Element.ChartPropChanged(prop);
        }
        this.onStyleChanged('ChartSettings');
    }

    UsePaletteForSingleSeriesChanged() {
        if (this.SelectedItemValue && this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.ChartPropChanged === 'function') {
            this.SelectedItemValue.Element.ChartPropChanged(null);
        }
        this.onStyleChanged('UsePaletteForSingleSeries');
    }

    onChartTypeChanged() {
        this.updateChartType();
        this.optionChanged('ChartType');
    }

    addSeriesColumn() {
        if (this.ChartSettingsValue.Series) {
            this.ChartSettingsValue.Series.push(new SeriesSettings());
        } else {
            this.ChartSettingsValue.Series = [new SeriesSettings()];
        }
        this.optionChanged('Series');
    }

    deleteSeriesColumn(i) {
        if (this.ChartSettingsValue.Series) {
            this.ChartSettingsValue.Series.splice(i, 1);
            this.optionChanged('Series');
        }
    }

    onDefaultAxisChanged() {
        if (this.ChartSettingsValue) {
            const axisCol = this.ChartSettingsValue['AxisColumn'];
            const colName = axisCol.ColumnName;
            const col = this.DefaultAxisColumns.find(x => x.Name === colName);
            if (col) {
                if (!this.ChartSettingsValue.LabelAxis) {
                    this.ChartSettingsValue.LabelAxis = new AxisSettings();
                }
                this.ChartSettingsValue.LabelAxis.Title = col.TranslatedCaption;
            }
            this.checkAxisDateColumn();
        }
        this.optionChanged('AxisColumn');
    }

    addColor(list) {
        if (list) {
            list.push(new ColorAxisValue());
            this.optionChanged('ColorValues.Color');
        }
    }

    getColor(series) {
        if (series && typeof series.Color === 'string') {
            return Color.FromHex(series.Color);
        }
        return new Color();
    }

    setColor(col, ev) {
        if (col && ev) {
            col.Color = Color.HexFromColor(ev, true);
            this.optionChanged('ColorValues.Color');
        }
    }

    changeColor(series: SeriesSettings) {
        if (series) {
            const changedColor = series['ChangedColor'];
            if (changedColor) {
                series.Color = Color.HexFromColor(changedColor, true);
                this.optionChanged('Series.Color');
                delete series['ChangedColor'];
            }
        }
    }

    clearColor(series: SeriesSettings) {
        if (series) {
            series.Color = null;
            this.optionChanged('Series.Color');
        }
    }

    addColorRange() {
        if (this.ChartSettingsValue && this.ChartSettingsValue.HeatMapSettings) {
            if (this.ChartSettingsValue.HeatMapSettings.ColorValues) {
                this.ChartSettingsValue.HeatMapSettings.ColorValues.push(new ColorRangeValue());
            } else {
                this.ChartSettingsValue.HeatMapSettings.ColorValues = [new ColorRangeValue()];
            }
            this.optionChanged('HeatMapSettings.ColorValues');
        }
    }

    deleteColorRange(i) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.HeatMapSettings && this.ChartSettingsValue.HeatMapSettings.ColorValues) {
            this.ChartSettingsValue.HeatMapSettings.ColorValues.splice(i, 1);
            this.optionChanged('HeatMapSettings.ColorValues');
        }
    }

    checkAxisDateColumn() {
        const axisColumn = this.ChartSettingsValue['AxisColumn'];
        this.AxisIsDateColumn = axisColumn && axisColumn.ColumnName &&
            this.DateColumns.concat(this.NumericColumns).some(x => x.Name === axisColumn.ColumnName);
    }

    addPaletteColor() {
        if (this.ChartSettingsValue) {
            if (this.ChartSettingsValue.SeriesColors) {
                this.ChartSettingsValue.SeriesColors.push('#000000');
            } else {
                this.ChartSettingsValue.SeriesColors = ['#000000'];
            }
            this.optionChanged('SeriesColors');
        }
    }
    clearPalette() {
        if (this.ChartSettingsValue) {
            this.ChartSettingsValue.SeriesColors = [];
            this.optionChanged('SeriesColors');
        }
    }
    DropPaletteEntry(event) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.SeriesColors && event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.ChartSettingsValue.SeriesColors, event.previousIndex, event.currentIndex);
            this.optionChanged('SeriesColors');
        }
    }
    paletteColorChanged(event, i) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.SeriesColors && i < this.ChartSettingsValue.SeriesColors.length) {
            if (event == null) {
                this.ChartSettingsValue.SeriesColors.splice(i, 1);
            } else {
                this.ChartSettingsValue.SeriesColors.splice(i, 1, Color.HexFromColor(event, true));
            }
            this.optionChanged('SeriesColors');
        }
    }
    getPaletteColor(col) {
        return Color.FromHex(col);
    }

    LabelAxisActiveChanged(ev) {
        if (ev) {
            if (this.ChartSettingsValue.LabelAxis == null) {
                this.ChartSettingsValue.LabelAxis = new AxisSettings();
            }
        } else {
            this.ChartSettingsValue.LabelAxis = null;
        }
    }

    FirstValueAxisActiveChanged(ev) {
        if (ev) {
            if (this.ChartSettingsValue.ValueAxes == null || this.ChartSettingsValue.ValueAxes.length == 0) {
                this.ChartSettingsValue.ValueAxes = [new ContinuousAxisSettings()];
            } else if (this.ChartSettingsValue.ValueAxes[0] == null) {
                this.ChartSettingsValue.ValueAxes[0] = new ContinuousAxisSettings();
            }
        } else if (this.ChartSettingsValue.ValueAxes && this.ChartSettingsValue.ValueAxes.length > 0) {
            if (this.ChartSettingsValue.ValueAxes.length == 1 || (this.ChartSettingsValue.ValueAxes.length == 2 && this.ChartSettingsValue.ValueAxes[1] == null)) {
                this.ChartSettingsValue.ValueAxes = null;
            } else {
                this.ChartSettingsValue.ValueAxes[0] = null;
            }
        }
    }

    SecondValueAxisActiveChanged(ev) {
        if (ev) {
            if (this.ChartSettingsValue.ValueAxes) {
                if (this.ChartSettingsValue.ValueAxes.length == 0) {
                    this.ChartSettingsValue.ValueAxes = [null, new ContinuousAxisSettings()];
                } else if (this.ChartSettingsValue.ValueAxes.length == 1) {
                    this.ChartSettingsValue.ValueAxes.push(new ContinuousAxisSettings());
                }
            } else {
                this.ChartSettingsValue.ValueAxes = [null, new ContinuousAxisSettings()];
            }
        } else if (this.ChartSettingsValue.ValueAxes && this.ChartSettingsValue.ValueAxes.length > 1) {
            if (this.ChartSettingsValue.ValueAxes[0] == null) {
                this.ChartSettingsValue.ValueAxes = null;
            } else {
                this.ChartSettingsValue.ValueAxes[1] = null;
            }
        }
    }
}
