import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'macplanning-format-menu-tab',
    templateUrl: './planning.format.menu.tab.html'
})
export class MacPlanningFormatMenuTab implements OnDestroy {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.SelectedItemValue.TableSize == null) {
            this.SelectedItemValue.TableSize = {
                Columns: 26,
                Rows: 100
            };
        }
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Layout
    LayoutValue;

    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.LayoutChange.emit(this.LayoutValue);
    }

    @Output() LayoutChange = new EventEmitter<any>();
    //#endregion
    Subscriptions = {};
    constructor() {
        this.Subscriptions['Layout'] = LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
        });
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }

    SettingsChanged() {
        this.SelectedItem.ValuesChanged.next();
    }
}
