import { Type } from 'class-transformer';
import { Input, EventEmitter, Output, Directive } from "@angular/core";
import { DataDescriptionRelational } from "./relational/datadescriptionrelational.model";
import { DataDescription } from "./multi/datadescription.model";
import { UUID } from "angular2-uuid";
import { TranslatedString } from '../translatedstring.model';




@Directive()
export class DatadescriptionData {
  //@Type(() => WorkflowModuleData)
  //Modules: WorkflowModuleData[] = [];
  //@Type(() => WorkflowConnectorData)
  //Connectors: WorkflowConnectorData[] = [];

  Datadescription: any = new DataDescriptionRelational();
  DataModelID: UUID;
  IsRelationalValue = true;


  @Input()
  get IsRelational() {
    return this.IsRelationalValue;
  }
  set IsRelational(val) {
    if (!this.Datadescription) {
      this.Datadescription = this.IsRelationalValue ? new DataDescriptionRelational() : new DataDescription();
      this.IsRelationalChange.emit(val);
    }
    else {
      if (val === false && this.Datadescription instanceof DataDescriptionRelational ||
        val === true && this.Datadescription instanceof DataDescription) {
          this.Datadescription = val ? new DataDescriptionRelational() : new DataDescription();
          this.IsRelationalChange.emit(val);
      }

      this.IsRelationalValue = val;
    }
  }



  @Output() IsRelationalChange = new EventEmitter<any>();


  
}




// @dynamic
@Directive()
export class DatadescriptionSaveObject extends DatadescriptionData {
  @Type(() => TranslatedString)
  Caption: TranslatedString;
  @Type(() => TranslatedString)
  Description: TranslatedString;

  //public static DeserializeModuleSettings(mod) {
  //  if (mod) {
  //    const retVal = Object.assign({}, mod);
  //    if (typeof mod.Settings === 'string') {
  //      const settings = JSON.parse(mod.Settings);
  //      retVal.Settings = settings;
  //      if (mod.SettingsType) {
  //        const dataType = WF_SERVICE_SETTINGS.get(mod.SettingsType);
  //        if (dataType) {
  //          const dataTypeInstance: any = plainToClass(dataType, settings);
  //          if (typeof dataTypeInstance.ExtendedDeserialize === 'function') {
  //            dataTypeInstance.ExtendedDeserialize();
  //          }
  //          retVal.Settings = dataTypeInstance;
  //        }
  //      }
  //    }
  //    return retVal;
  //  }
  //  return null;
  //}

  //public static SerializeModuleSettings(mod) {
  //  if (mod) {
  //    if (typeof mod.Settings === 'object') {
  //      if (typeof mod.Settings.ExtendedSerialize === 'function') {
  //        mod.Settings.ExtendedSerialize(mod);
  //      } else {
  //        mod.Settings = JSON.stringify(mod.Settings);
  //      }
  //    }
  //  }
  //}
}

