import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { FormulaInfo, NonClickableVariablesNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { FormulaService } from '../../../services/formula.service';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';
import { ABaseTreeNode } from '../basetreecontrol/base.tree.control';

@Component({
    selector: 'formula-editor',
    templateUrl: './formulaEditor.control.html',
    styleUrls: ['./formulaEditor.control.css']
})
export class FormulaEditor {
    VariableNode: VariableNode;
    SystemVariableNode: VariableNode;
    VariableNodeIndex = 0;
    VariableNodeList;
    OperationList = [];
    FunctionNodeList;
    FormulaOK: boolean;
    FormulaParser = new AdvancedFormulaParser();
    formulaTimerID = -1;
    Mentions;
    GlobalMentions = [];

    //#region Variables
    VariablesValue: VariablesNodeInformation[] = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        if (val) {
            this.VariablesValue = val;
            this.updateNodes();
            this.VariablesChange.emit(this.VariablesValue);
        }
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion

    //#region SystemVariables
    SystemVariablesValue = [];

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        if (val) {
            this.SystemVariablesValue = val;
            const list = [];
            const mentions = [];
            if (Array.isArray(val)) {
                val.forEach(sv => {
                    const node = new VariableNode(this.VariableNodeIndex++);
                    node.Caption = sv.Name;
                    node.data = {
                        AliasKey: '@' + sv.Name,
                        Name: '@' + sv.Name,
                    };
                    list.push(node);
                    mentions.push(sv.Name);
                });
                this.updateMentions(mentions);
            }
            this.SystemVariableNode.Children = list;
            this.SystemVariablesChange.emit(this.SystemVariablesValue);
        }
    }

    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion

    //#region Operations
    OperationsValue: FormulaInfo[] = [];

    @Input()
    get Operations() {
        return this.OperationsValue;
    }
    set Operations(val) {
        if (val) {
            this.OperationsValue = val;
            this.updateOperations();
            this.OperationsChange.emit(this.OperationsValue);
        }
    }

    @Output() OperationsChange = new EventEmitter<any>();
    //#endregion

    //#region Formula
    FormulaValue: string;

    @Input()
    get Formula() {
        return this.FormulaValue;
    }
    set Formula(val) {
        if (this.FormulaValue !== val) {
            this.FormulaValue = val;
            this.onFormulaChanged();
            this.FormulaChange.emit(this.FormulaValue);
        }
    }

    @Output() FormulaChange = new EventEmitter<any>();
    //#endregion

    //#region InputMode
    InputModeValue: FormulaInputMode = FormulaInputMode.AliasKey;

    @Input()
    get InputMode() {
        return this.InputModeValue;
    }
    set InputMode(val) {
        if (val) {
            this.InputModeValue = val;
            this.updateNodes();
            this.InputModeChange.emit(this.InputModeValue);
        }
    }

    @Output() InputModeChange = new EventEmitter<any>();
    //#endregion

    private getNodeList(vL: VariablesNodeInformation[], cL: VariablesNodeInformation[], mode: FormulaInputMode): VariableNode[] {
        const retVal = [];
        if (vL) {
            vL.forEach(vni => {
                let value;
                let toolTip;
                switch (mode) {
                    case FormulaInputMode.AliasKey:
                        cL.push(vni);
                        value = vni.AliasKey + ' [ ' + vni.Name + ' ]';
                        toolTip = value;
                        if (vni.ToolTip) {
                            toolTip = vni.AliasKey + ' [ ' + vni.ToolTip + ' ]';
                        }
                        break;
                    case FormulaInputMode.VariableName:
                        cL.push(vni);
                        value = vni.Name;
                        toolTip = value;
                        if (vni.ToolTip) {
                            toolTip = vni.Name + ' [ ' + vni.ToolTip + ' ]';
                        }
                        break;
                }
                const node = new VariableNode(this.VariableNodeIndex++);
                node.Caption = value;
                node.ToolTip = toolTip;
                node.data = vni;
                node.Children = this.getNodeList(vni.Children, cL, mode);
                node.HasChildren = node.Children.length > 0;
                if (vni instanceof NonClickableVariablesNodeInformation) {
                    node.Caption = vni.Name;
                    node.IsExpanded = vni.Expand;
                }
                retVal.push(node);
            });
        }
        return retVal;
    }

    constructor(private cdRef: ChangeDetectorRef) {
        this.VariableNode = new VariableNode(this.VariableNodeIndex++);
        this.VariableNode.TranslateCaption = '@@Variablen';
        this.VariableNode.HasChildren = true;
        this.VariableNode.Children = [];
        this.VariableNode.IsExpanded = true;

        this.SystemVariableNode = new VariableNode(this.VariableNodeIndex++);
        this.SystemVariableNode.TranslateCaption = '@@Systemvariablen';
        this.SystemVariableNode.HasChildren = true;
        this.SystemVariableNode.Children = [];
        this.SystemVariableNode.IsExpanded = true;

        this.VariableNodeList = [this.VariableNode, this.SystemVariableNode];

        const globalVariables = CacheService.GlobalVariablesValues.getValue();
        if (globalVariables && globalVariables.length > 0) {
            const globalVariableNode = new VariableNode(this.VariableNodeIndex++);
            globalVariableNode.TranslateCaption = '@@Globale Variablen';
            globalVariableNode.HasChildren = true;
            globalVariableNode.Children = [];
            globalVariableNode.IsExpanded = true;

            globalVariables.forEach(sv => {
                const node = new VariableNode(this.VariableNodeIndex++);
                node.Caption = sv.Name;
                node.data = {
                    AliasKey: '@' + sv.Name,
                    Name: '@' + sv.Name,
                };
                globalVariableNode.Children.push(node);
                this.GlobalMentions.push(sv.Name);
            });
            this.VariableNodeList.push(globalVariableNode);
            this.updateMentions([]);
        }
    }

    updateMentions(systemVariables) {
        const mentions = [];
        mentions.push(...systemVariables);
        mentions.push(...this.GlobalMentions);
        this.Mentions = mentions;
    }

    updateNodes() {
        const checkList = [];
        this.VariableNode.Children = this.getNodeList(this.VariablesValue, checkList, this.InputModeValue);
        this.FormulaParser.SetVariables(checkList, this.InputModeValue);
    }

    updateOperations(): any {
        const functionList = [];
        const basicList = [];
        const compList = [];
        const binaryList = [];
        const otherList = [];
        let funcIndex = 1;
        if (this.OperationsValue) {
            this.OperationsValue.forEach((op) => {
                switch (op.Group) {
                    case FormulaGroupEnum.BasicOperations:
                        basicList.push(op);
                        break;
                    case FormulaGroupEnum.Binary:
                        binaryList.push(op);
                        break;
                    case FormulaGroupEnum.Comparisons:
                        compList.push(op);
                        break;
                    case FormulaGroupEnum.Functions:
                        const funcNode = new VariableNode(funcIndex++);
                        funcNode.Caption = op.Name;
                        funcNode.ToolTip = op.Description || op.Name;
                        funcNode.data = op;
                        functionList.push(funcNode);
                        break;
                    default:
                        otherList.push(op);
                        break;
                }
            });
        }
        if (functionList.length > 0) {
            const node = new VariableNode(0);
            node.TranslateCaption = '@@Funktionen';
            node.HasChildren = true;
            node.Children = functionList;
            this.FunctionNodeList = [node];
        } else {
            this.FunctionNodeList = null;
        }
        this.OperationList = basicList.concat(compList).concat(binaryList).concat(otherList);
    }

    checkFormula() {
        let isOk = true;
        if (typeof this.FormulaValue === 'string' && this.FormulaValue !== '') {
            try {
                this.FormulaParser.Parse(this.FormulaValue);
            } catch {
                isOk = false;
            }
        } else {
            isOk = null;
        }
        
        if (this.FormulaOK !== isOk) {
            this.FormulaOK = isOk;
            this.cdRef.detectChanges();
        }
    }

    onFormulaChanged() {
        clearTimeout(this.formulaTimerID);
        const that = this;
        this.formulaTimerID = window.setTimeout(() => {
            that.checkFormula();
        }, 500);
    }

    onVariableNodeClicked(ev) {
        if (ev && ev.length > 0) {
            const firstNode = ev[0];
            if (firstNode && firstNode.data && !(firstNode.data instanceof NonClickableVariablesNodeInformation)) {
                switch (this.InputModeValue) {
                    case FormulaInputMode.AliasKey:
                        this.appendText(firstNode.data.AliasKey);
                        break;
                    case FormulaInputMode.VariableName:
                        this.appendText(firstNode.data.Name);
                        break;
                }
            }
        }
    }

    onFunctionNodeClicked(ev) {
        if (ev && ev.length > 0) {
            const firstNode = ev[0];
            if (firstNode && firstNode.data) {
                this.appendText(firstNode.data.Operation);
            }
        }
    }

    appendText(text) {
        let input:any = document.getElementById("EditorText");
        if (input) {
            let pos = input.selectionStart || 0;
            const endPos = input.selectionEnd || 0;
            const actText = input.value;
            const front = actText.substring(0, pos);
            let back;
            if (endPos > pos) {
                back = actText.substring(endPos, actText.length);
            } else {
                back = actText.substring(pos, actText.length);
            }
            this.Formula = front + text + back;
            pos = pos + text.length;
            input.selectionStart = pos;
            input.selectionEnd = pos;
            input.focus();
        }
    }
}

@Component({
    selector: 'formula-editor-dialog',
    template: '<formula-editor class="flex-column full noscroll" [Variables]="Variables" [SystemVariables]="SystemVariables"' +
        '[Operations]="Operations" [InputMode]="InputMode" [(Formula)]="Formula"></formula-editor>'
})
export class FormulaEditorDialog implements OnInit {
    Variables = [];
    SystemVariables = [];
    Operations = [];
    FormulaGroup: FormulaGroupEnum = FormulaGroupEnum.All;
    InputMode: FormulaInputMode = FormulaInputMode.AliasKey;
    Formula = '';

    public static ShowDialog(args, handler) {
        BaseDialog.ShowDialog({
            ContentType: FormulaEditorDialog,
            InitArgs: args,
            Title: '@@Formeleditor',
            Handler: handler
        });
    }

    private static CheckVariables(variables, functions, resultList, inputMode) {
        variables.forEach(v => {
            if (v.Children && v.Children.length > 0) {
                FormulaEditorDialog.CheckVariables(v.Children, functions, resultList, inputMode);
            } else {
                const key = (inputMode == FormulaInputMode.AliasKey ? v.AliasKey : v.Name).toLowerCase();
                if (functions[key]) {
                    resultList.push(key);
                }
            }
        })
    }

    constructor(private service: FormulaService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        const query = {
            Group: this.FormulaGroup
        };
        this.service.GetFormulaInfos(query).subscribe(result => {
            this.Operations = result;
            this.cdRef.detectChanges();
            if (result) {
                const functions = {};
                let check = false;
                result.forEach(x => {
                    if (x.Group == FormulaGroupEnum.Functions) {
                        check = true;
                        functions[x.Name.toLowerCase()] = true;
                    }
                });
                if (this.Variables && check) {
                    const resultList = [];
                    FormulaEditorDialog.CheckVariables(this.Variables, functions, resultList, this.InputMode);
                    if (resultList.length > 0) {
                        const text = new TranslateFormatText('@@VariablesLikeFormulas{0}');
                        text.FormatParams.push(resultList.join(', '));
                        MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warning'),
                            MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                    }
                }
            }
        });
    }

    Initialize(args) {
        if (args) {
            if (args.Variables) {
                this.Variables = args.Variables;
            }
            if (args.SystemVariables) {
                this.SystemVariables = args.SystemVariables;
            }
            if (args.FormulaGroup) {
                this.FormulaGroup = args.FormulaGroup;
            }
            if (args.InputMode) {
                this.InputMode = args.InputMode;
            }
            if (args.Formula) {
                this.Formula = args.Formula;
            }
        }
    }

    GetDialogResult() {
        return { Formula: this.Formula };
    }
}

export class VariableNode extends ABaseTreeNode {
    data: any;
}
