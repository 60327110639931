import { Component, EventEmitter, Input, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { MessageBoxHelper } from '../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../helpers/array.helpers';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { ConditionOperator } from '../../../../models/enums/conditionoperator.enum';
import { MessageBoxButtons } from '../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../../models/enums/messageboxresult.enum';
import { CellStyle } from '../../../../models/styling/cell.style';
import { ConditionFormattingStyle } from '../../../../models/tasks/conditional.model';
import { PlanningCellFormattingTaskExecuter } from '../../../../models/tasks/planning/cell.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ATaskPanel } from '../../a.task.panel';

@Component({
    selector: 'cell-planning-task-panel',
    templateUrl: './cell.planning.task.panel.html'
})
export class CellPlanningTaskPanel extends ATaskPanel {

    StyleVal: any;
    CompareTypes: any[];
    ActualCondition: any;
    ListItems = [];
    selectedNode;

    //#region TaskListConditionalValue
    TaskListConditionalValue = [];
    @Input()
    get TaskListConditional() {
        return this.TaskListConditionalValue;
    }
    set TaskListConditional(val) {
        this.TaskListConditionalValue = val;
        this.TaskListConditionalValueChange.emit(val);
    }

    @Output() TaskListConditionalValueChange = new EventEmitter<any>();
    //#endregion

    constructor() {
        super();
        this.CompareTypes = EnumHelper.GetDropdownValues(ConditionOperator);
    }

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = PlanningCellFormattingTaskExecuter.TaskID;
        reg.Caption = '@@Cell';
        reg.SettingsControl = CellPlanningTaskPanel;
        reg.SettingsHelper = new ContentPlanningTaskSettingsHelper();
        reg.Executer = PlanningCellFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
            if (!this.SettingsValue.ConditionFormatingStyles) {
                this.SettingsValue.ConditionFormatingStyles = [];
            }
            this.TaskListConditionalValue = this.SettingsValue.ConditionFormatingStyles;
            this.updateTaskList(true);
        }
    }    

    async onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
            let task = null;
            if (selection && this.TaskListConditionalValue) {
                task = this.TaskListConditionalValue[selection.ID];
            }
            this.setSelectedItem(task);
        }
    }

    addItem() {
        if (this.TaskListConditionalValue.length > 0) {
            this.updateTaskList(false);
        }
        const cond = new ConditionFormattingStyle();

        const item = {
            Caption: this.CompareTypes.find(x => x.type === cond.Condition.ConditionOperator).value,
            ID: this.ListItems.length
        };
        item.Caption += cond.Condition.Value1 ? ' ' + cond.Condition.Value1 : '';
        item.Caption += cond.Condition.Value2 ? ' ' + cond.Condition.Value2 : '';

        this.ListItems.push(item);
        this.TaskListConditionalValue.push(cond);
        this.selectedNode = item;
        this.setSelectedItem(cond);
    }

    updateTaskList(selectFirst = false) {
        if (this.TaskListConditionalValue.length === 0) {
            this.addItem();
        } else {

            this.ListItems = [];
            this.TaskListConditionalValue.forEach((task, i) => {
                const item = {
                    Caption: this.CompareTypes.find(x => x.type === task.Condition.ConditionOperator).value,
                    ID: this.ListItems.length
                };
                item.Caption += task.Condition.Value1 ? ' ' + task.Condition.Value1 : '';
                item.Caption += task.Condition.Value2 ? ' ' + task.Condition.Value2 : '';
                this.ListItems.push(item);
            });
            const selectItem = selectFirst ? this.TaskListConditionalValue[0] : null;
            this.setSelectedItem(selectItem);
        }
    }

    setSelectedItem(item) {
        this.ActualCondition = item;
        if (this.ActualCondition) {
            this.StyleVal = this.ActualCondition.Style;
        }
    }


    async deleteItem() {
        const sel = this.selectedNode;
        if (sel) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Task \'{0}\' loeschen moechten?');
            text.FormatParams.push(sel.Caption);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {

                if (this.TaskListConditionalValue) {
                    this.TaskListConditionalValue.splice(sel.ID, 1);
                }
                this.ListItems.splice(sel.ID, 1);

                this.selectedNode = null;
                const selectItem = this.TaskListConditionalValue.length > 0 ? this.TaskListConditionalValue[0] : null;
                this.setSelectedItem(selectItem);
            }
        }
    }

    moveUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(0, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(0, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveOneUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(this.selectedNode.ID - 1, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(this.selectedNode.ID - 1, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveOneDown() {
        if (this.selectedNode && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0 &&
            this.selectedNode.ID < this.TaskListConditionalValue.length - 1) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(this.selectedNode.ID + 1, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(this.selectedNode.ID + 1, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveDown() {
        if (this.selectedNode && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0 &&
            this.selectedNode.ID < this.TaskListConditionalValue.length - 1) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.push(task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.push(this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }
}

export class ContentPlanningTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsPlanningObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        if (task && (task.StartCell || task.EndCell)) {
            return task.StartCell + ':' + task.EndCell;
        }
        return TranslateHelper.TranslatorInstance.instant('@@Contentformatierung');
    }
}
