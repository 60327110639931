import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'header-theme-control',
    templateUrl: './header.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderThemeControl extends BaseThemeControl {
    TextValue = '';
    get Text() {
        return this.TextValue
    }
    set Text(value) {
        this.TextValue = value;
        this.cdRef.detectChanges();
    }
}
