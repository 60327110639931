import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from '../../services/request-base';
import { MessageSetting } from './models/MessageSetting';

@Injectable()
export class MessageSettingsService extends RequestBase {
    BasePath = 'api/settings/messagesettings';

    SaveMessageSetting(ms: MessageSetting): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveMessageSetting', ms, this.options);
    }

    CreateStream(stream: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CreateStream', stream, this.options);
    }

    LoadMessageSettings(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadMessageSettings', this.options);
    }

    RemoveMessageSetting(sid: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/RemoveMessageSetting', sid, this.options);
    }

    LoadMessageSettingsInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadMessageSettingsInfos', this.options);
    }

    GetMessageSettingVariables(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetMessageSettingVariables?id=' + id, this.options);
    }

    GetAllUsers(ignoredusers: string[] = []): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllUsers', this.options);
    }

    GetStreams(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetStreams', this.options);
    }
}
