import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'columnvisibility-menu-tab',
    templateUrl: './columnvisibility.menu.tab.html',
    styleUrls: ['./columnvisibility.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnVisibilityMenuTab extends APropertyTab{

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    Layout;
    Elements;
    constructor(cdRef: ChangeDetectorRef) {        
        super(cdRef);
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
            this.Elements = [];
            this.Elements.push(...this.GetTables(this.Layout));
        }));
    }

    GetTables(layout) {
        let retVal = [];
        if (layout.Elements && layout.Elements.length > 0) {
            for (let i = 0; i < layout.Elements.length; i++) {
                let item = layout.Elements[i];
                if (item.Elements && item.Elements.length > 0) {
                    retVal.push(...this.GetTables(item));
                }
                if (item.ElementType == "datatable") {
                    retVal.push(item);
                }
            }
        }
        return retVal;
    }
}

@Component({
    selector: 'columnvisibility-property-menu-tab',
    template: '<columnvisibility-menu-tab [(SelectedItem)]="SelectedItem"></columnvisibility-menu-tab>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnVisibilityPropertyMenuTab extends APropertyTab {
}
