import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../basedatasource.panel';

@Component({
    selector: 'tsbdb-panel',
    templateUrl: './tsbdb.panel.html',
    styleUrls: ['./tsbdb.panel.css']
})
export class TSBDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = TSBDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: TSBDBPanel,
            DBInfoType: TSBDBInfo
        };
    }
}

export class TSBDBInfo extends ADBInfo {
    ServerURL: string = null;
    SSPI: boolean = false;
    User: string = null;
    Password: string = null;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.SpeedBase.TSBDBInfo';
    }
}