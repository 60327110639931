import { FormulaNodeInformation, FormulaStatics, VariablesNodeInformation } from '../models/basic/formulaEditor.model';
import { BinaryOperation } from '../models/enums/binaryoperation.enum';
import { CompareType } from '../models/enums/comparetype.enum';
import { FormulaInputMode } from '../models/enums/formulainputmode.enum';
import { FormulaNodeType } from '../models/enums/formulanodetype.enum';
import { OperationType } from '../models/enums/operationtype.enum';
import { StringHelper } from './array.helpers';
import { TranslateHelper } from './injector.helper';

export class AdvancedFormulaParser {
    Variables: Map<string, VariablesNodeInformation>;

    private static GetNextSubString(formula: string, terminators: string[]): string {
        let retVal = formula;
        let openBrackets = 0;
        let open2 = false;
        let open3 = false;
        let startindex = 0;

        if (terminators.indexOf('-') > -1) {
            const trim = formula.trim();
            if (trim.length > 0 && trim[0] === '-') {
                startindex = formula.indexOf('-') + 1;
            }
        }
        for (let i = startindex; i < formula.length; i++) {
            if (openBrackets === 0 && !open2 && !open3 && terminators.indexOf(formula[i]) > -1) {
                if (formula[i] !== '-') {
                    retVal = formula.substr(0, i);
                    break;
                } else {
                    const charBefore = formula[i - 1];
                    if (charBefore !== '*' && charBefore !== '/' && charBefore !== '(' &&
                        charBefore !== '<' && charBefore !== '>' && charBefore !== '=') {
                        retVal = formula.substr(0, i);
                        break;
                    }
                }
            }
            switch (formula[i]) {
                case '[':
                case '(':
                    if (!open2) {
                        openBrackets++;
                    }
                    break;
                case ']':
                case ')':
                    if (!open2) {
                        openBrackets--;
                    }
                    break;
                case '"':
                    open2 = !open2;
                    break;
                case '\'':
                    if (!open2) {
                        open3 = !open3;
                    }
                    break;
            }
        }

        return retVal;
    }

    private static AddNodeAbove(retVal: FormulaNodeInformation, type: OperationType): FormulaNodeInformation {
        const tmp = new FormulaNodeInformation();
        tmp.Operation.OperationType = type;
        tmp.Type = FormulaNodeType.Operation;
        tmp.AddOperand(retVal);
        return tmp;
    }

    Parse(formula: string): FormulaNodeInformation {
        let retVal = this.GenerateNextNode(formula, 0);
        if (retVal.Operation && retVal.Operation.OperationType === OperationType.None) {
            if (retVal.Operands.length > 1) {
                retVal.Type = FormulaNodeType.Operation;
                retVal.Operation.OperationType = OperationType.Addition;
            } else if (retVal.Operands.length > 0) {
                retVal = retVal.Operands[0];
            }
        }
        retVal.SetParents();
        return retVal;
    }

    SetVariables(variables: VariablesNodeInformation[], mode: FormulaInputMode) {
        const dict = new Map<string, VariablesNodeInformation>();
        switch (mode) {
            case FormulaInputMode.AliasKey:
                variables.forEach(function (v) {
                    if (v.AliasKey) {
                        dict.set(v.AliasKey.toUpperCase().trim(), v);
                    }
                });
                break;
            case FormulaInputMode.VariableName:
                variables.forEach(function (v) {
                    if (v.Name) {
                        dict.set(v.Name.toUpperCase().trim(), v);
                    }
                });
                break;
        }
        this.Variables = dict;
    }

    private GenerateNextNode(toParse: string, depth: number): FormulaNodeInformation {
        let retVal = new FormulaNodeInformation();
        if (depth > 250) {
            throw TranslateHelper.TranslatorInstance.instant('@@Formel-Parser Fehler wegen Rekursionstiefe.');
        }
        let isNegative = false;
        while (toParse.length > 0) {
            const firstchar = toParse[0];
            let substring = '';
            switch (firstchar) {
                case ' ':
                    toParse = toParse.substring(1);
                    continue;
                case '-':
                    isNegative = true;
                    if (retVal.Operands.length > 0) {
                        if (retVal.Type !== FormulaNodeType.Operation) {
                            retVal.Operation.OperationType = OperationType.Addition;
                            retVal.Type = FormulaNodeType.Operation;
                        } else if (retVal.Operation.OperationType !== OperationType.Addition) {
                            retVal = AdvancedFormulaParser.AddNodeAbove(retVal, OperationType.Addition);
                        }
                    }
                    toParse = toParse.substring(substring.length + 1);
                    continue;
                case '+':
                    substring = AdvancedFormulaParser.GetNextSubString(toParse.substring(1), ['-', '+', '<', '>', '=']);
                    if (retVal.Type !== FormulaNodeType.Operation) {
                        retVal.Operation.OperationType = OperationType.Addition;
                        retVal.Type = FormulaNodeType.Operation;
                    } else if (retVal.Operation.OperationType !== OperationType.Addition) {
                        retVal = AdvancedFormulaParser.AddNodeAbove(retVal, OperationType.Addition);
                    }
                    this.AddNextNode(substring, retVal, depth);
                    toParse = toParse.substring(substring.length + 1);
                    break;
                case '/':
                    substring = AdvancedFormulaParser.GetNextSubString(toParse.substring(1), ['-', '+', '/', '*', '<', '>', '=']);
                    if (retVal.Type !== FormulaNodeType.Operation) {
                        retVal.Operation.OperationType = OperationType.Division;
                        retVal.Type = FormulaNodeType.Operation;
                        this.AddNextNode(substring, retVal, depth);
                    } else if (retVal.Operation.OperationType !== OperationType.Division) {
                        retVal = this.ReArangeOperands(retVal, OperationType.Division, substring, depth);
                    } else {
                        this.AddNextNode(substring, retVal, depth);
                    }
                    toParse = toParse.substring(substring.length + 1);
                    break;
                case '*':
                    substring = AdvancedFormulaParser.GetNextSubString(toParse.substring(1), ['-', '+', '/', '*', '<', '>', '=']);
                    if (retVal.Type !== FormulaNodeType.Operation) {
                        retVal.Operation.OperationType = OperationType.Multiplication;
                        retVal.Type = FormulaNodeType.Operation;
                        this.AddNextNode(substring, retVal, depth);
                    } else if (retVal.Operation.OperationType !== OperationType.Multiplication) {
                        retVal = this.ReArangeOperands(retVal, OperationType.Multiplication, substring, depth);
                    } else {
                        this.AddNextNode(substring, retVal, depth);
                    }
                    toParse = toParse.substring(substring.length + 1);
                    break;
                case '>':
                    if (toParse.length > 1 && toParse[1] === '=') {
                        retVal = this.CreateComparer(toParse, retVal, CompareType.GreaterEqual, 2, depth);
                    } else {
                        retVal = this.CreateComparer(toParse, retVal, CompareType.Greater, 1, depth);
                    }
                    toParse = '';
                    break;
                case '<':
                    if (toParse.length > 1 && toParse[1] === '=') {
                        retVal = this.CreateComparer(toParse, retVal, CompareType.SmallerEqual, 2, depth);
                    } else if (toParse.length > 1 && toParse[1] === '>') {
                        retVal = this.CreateComparer(toParse, retVal, CompareType.NotEqual, 2, depth);
                    } else {
                        retVal = this.CreateComparer(toParse, retVal, CompareType.Smaller, 1, depth);
                    }
                    toParse = '';
                    break;
                case '=':
                    retVal = this.CreateComparer(toParse, retVal, CompareType.Equal, 1, depth);
                    toParse = '';
                    break;
                case '(':
                    substring = AdvancedFormulaParser.GetNextSubString(toParse.substring(1), [')']);
                    this.AddNextNode(substring, retVal, depth);
                    toParse = toParse.substring(substring.length + 2);
                    if (isNegative) {
                        retVal.Operands[retVal.Operands.length - 1].SignIsNegative = isNegative;
                    }
                    break;
                case '@':
                    substring = AdvancedFormulaParser.GetNextSubString(toParse, ['-', '+', '/', '*', '(', ')', '<', '>', '=']);
                    const variablename = substring.trim();
                    const sysVar = new FormulaNodeInformation();
                    sysVar.SignIsNegative = isNegative;
                    sysVar.Name = variablename;
                    sysVar.AliasKey = variablename;
                    sysVar.VariableID = variablename;
                    sysVar.SaveTag = variablename;
                    sysVar.Type = FormulaNodeType.SystemVariable;
                    toParse = toParse.substring(substring.length);
                    retVal.AddOperand(sysVar);
                    break;
                default:
                    substring = AdvancedFormulaParser.GetNextSubString(toParse, ['(']);
                    const ssTrimToLower = substring.trim().toLowerCase();
                    const formulaKey = FormulaStatics._KeyWords.get(ssTrimToLower);
                    if (formulaKey) {
                        toParse = toParse.substring(substring.length + 1);
                        let toadd = retVal;
                        if (retVal.Type === FormulaNodeType.Operation) {
                            toadd = this.ReArangeOperands(retVal, OperationType.None, null, depth);
                        }
                        let substring2 = AdvancedFormulaParser.GetNextSubString(toParse, [')']);
                        const substrlength = substring2.length;
                        if (ssTrimToLower === 'and') {
                            toadd.Operation.OperationType = OperationType.Binary;
                            toadd.Operation.BinaryOperation = BinaryOperation.And;
                        } else if (ssTrimToLower === 'or') {
                            toadd.Operation.OperationType = OperationType.Binary;
                            toadd.Operation.BinaryOperation = BinaryOperation.Or;
                        } else {
                            toadd.Operation.OperationType = OperationType.Function;
                            toadd.Operation.FunctionType = formulaKey;
                        }
                        toadd.Type = FormulaNodeType.Operation;

                        while (true) {
                            const sstring = AdvancedFormulaParser.GetNextSubString(substring2, [';']);
                            this.AddNextNode(sstring.trim(), toadd, depth);
                            if (substring2.length === sstring.length) {
                                break;
                            }
                            substring2 = substring2.substring(sstring.length + 1);
                        }
                        toParse = toParse.substring(substrlength + 1);
                        retVal.SignIsNegative = isNegative;
                    } else {
                        const tmp = new FormulaNodeInformation();
                        tmp.SignIsNegative = isNegative;
                        substring = AdvancedFormulaParser.GetNextSubString(toParse, ['-', '+', '/', '*', '(', ')', '<', '>', '=']);
                        let trimmedsubstring = substring.trim();
                        if (firstchar >= '0' && firstchar <= '9') {
                            for (let i = trimmedsubstring.length - 1; i >= 0; i--) {
                                if (trimmedsubstring[i] === ',') {
                                    trimmedsubstring = trimmedsubstring.replace('.', '').replace(',', '.');
                                    break;
                                }
                            }
                            tmp.Value = parseFloat(trimmedsubstring);
                            tmp.Type = FormulaNodeType.Value;
                        } else {
                            if (substring[0] === '"') {
                                if (trimmedsubstring.length > 1 && trimmedsubstring[trimmedsubstring.length - 1] === '"') {
                                    tmp.TextValue = trimmedsubstring.substr(1, trimmedsubstring.length - 2);
                                    tmp.Type = FormulaNodeType.TextValue;
                                } else {
                                    throw TranslateHelper.TranslatorInstance.instant('@@Text endet nicht mit ".');
                                }
                            } else if (substring[0] === '\'') {
                                tmp.DateValue = new Date(trimmedsubstring.substr(1, trimmedsubstring.length - 2));
                                tmp.Type = FormulaNodeType.DateValue;
                            } else if (substring.toLowerCase() === 'true') {
                                tmp.BoolValue = true;
                                tmp.Type = FormulaNodeType.BoolValue;
                            } else if (substring.toLowerCase() === 'false') {
                                tmp.BoolValue = false;
                                tmp.Type = FormulaNodeType.BoolValue;
                            } else {
                                tmp.Type = FormulaNodeType.Variable;
                                this.SetTag(tmp, trimmedsubstring);
                            }
                        }
                        toParse = toParse.substring(substring.length);
                        retVal.AddOperand(tmp);
                    }
                    break;
            }
            isNegative = false;
        }

        if (retVal.Operands.length > 1 && retVal.Type === FormulaNodeType.None && retVal.Operation.OperationType === OperationType.None) {
            retVal.Type = FormulaNodeType.Operation;
            retVal.Operation.OperationType = OperationType.Addition;
        }
        return retVal;
    }

    private AddNextNode(sstring: string, retVal: FormulaNodeInformation, depth: number) {
        const node = this.GenerateNextNode(sstring, depth + 1);
        if (node.Type === FormulaNodeType.None && node.Operands.length === 1) {
            retVal.AddOperand(node.Operands[0]);
        } else {
            retVal.AddOperand(node);
        }
    }

    private ReArangeOperands(retVal: FormulaNodeInformation, type: OperationType, toParse: string, depth: number) {
        const tmp = new FormulaNodeInformation();
        tmp.Operation.OperationType = type;
        tmp.Type = FormulaNodeType.Operation;
        switch (retVal.Operation.OperationType) {
            case OperationType.Compare:
            case OperationType.Subtraktion:
            case OperationType.Addition:
                tmp.AddOperand(retVal.Operands[retVal.Operands.length - 1]);
                retVal.AddOperand(tmp);
                if (toParse == null) {
                    return tmp;
                }
                break;
            case OperationType.Function:
            case OperationType.Multiplication:
            case OperationType.Division:
                tmp.AddOperand(retVal);
                retVal = tmp;
                break;
        }
        if (toParse != null) {
            this.AddNextNode(toParse, tmp, depth);
        }
        return retVal;
    }

    private CreateComparer(toParse: string, retVal: FormulaNodeInformation, ct: CompareType, length: number, depth: number):
        FormulaNodeInformation {
        if (retVal.Type !== FormulaNodeType.Operation) {
            retVal.Type = FormulaNodeType.Operation;
            retVal.Operation.OperationType = OperationType.Compare;
            retVal.Operation.CompareType = ct;
        } else if (retVal.Operation.OperationType !== OperationType.Compare) {
            retVal = AdvancedFormulaParser.AddNodeAbove(retVal, OperationType.Compare);
            retVal.Operation.CompareType = ct;
        }
        this.AddNextNode(toParse.substring(length), retVal, depth);
        return retVal;
    }

    private SetTag(formula: FormulaNodeInformation, variablename: string) {
        if (this.Variables) {
            const variab = this.Variables.get(variablename.toUpperCase());
            if (variab) {
                formula.Name = variab.Name;
                formula.AliasKey = variab.AliasKey;
                formula.VariableID = variab.VariableID;
                formula.SaveTag = variab.VariableObject;
            } else {
                throw StringHelper.format(TranslateHelper.TranslatorInstance.instant('@@Variable {0} nicht gefunden.'), [variablename]);
            }
        } else {
            formula.Name = variablename;
            formula.AliasKey = variablename;
            formula.VariableID = variablename;
            formula.SaveTag = variablename;
        }
    }
}
