import { ChangeDetectorRef, Component, ComponentFactoryResolver,OnInit } from '@angular/core';
import { deserialize, plainToClass, serialize } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { EnumHelper } from '../../helpers/enum.helper';
import { TranslateHelper } from '../../helpers/injector.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { VariableHelper } from '../../helpers/variable.helper';
import { ContentType } from '../../models/enums/contenttype.enum';
import { Layout } from '../../models/layout.model';
import { LayoutElement } from '../../models/layoutelement.model';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../models/enums/dialogbutton.enum';
@Component({
    selector: 'templates-settings',
    templateUrl: '../base.list.settings.html'
})
export class TemplateSettings extends BaseListSettings {

    SelectedTemplateText;

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Templates',
    //        ID: 'templates',
    //        Icon: 'paste',
    //        Index: 60,
    //        Parent: 'layout',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.LayoutRight',
    //            Value: 32
    //        },
    //        Content: TemplateSettings
    //    };
    //}

    constructor(private metaService: MetaService, protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.DisableSaveWithoutChange = false;
        this.CopyType = "TemplateCopy";
        this.ShowCopy = true;
    }

    init(): void {
        NavigationService.SelectedPage.next('template');
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        NavigationService.SelectedPage.next('settings');
    }

    getContentType() {
        return TemplateDetail;
    }

    loadList(handler) {
        this.metaService.ReadTemplateInfos().subscribe((templates) => {
            if (templates) {
                const list = [];
                templates.forEach(wf => {
                    list.push({
                        Caption: wf.Caption,
                        ID: wf.SID,
                        IsCapsule: wf.IsCapsule,
                        IsOverridden: wf.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.metaService.ReadTemplateById(data).subscribe(result => {
            const item = plainToClass(Layout, result);
            this.setSelectedItem(item);
        });
    }

    getNewItem() {
        const template = new Layout();
        template.Name = TranslateHelper.TranslatorInstance.instant('@@Neues Template');
        template['ElementType'] = 'template';
        template['Type'] = 'template';
        template.ContentType = ContentType.Grid;
        const le = new LayoutElement();
        le.ElementType = 'grid';
        template.Elements = [le];
        return template;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Template \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Template \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Template loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Template \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Template speichern');
        return retVal;
    }

    delete(data, handler) {
        this.metaService.RemoveTemplate(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        this.metaService.AddOrUpdateTemplate(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item._Id = result.SID;
        item._version = result.Version;
        this.SelectedTemplateText = JSON.stringify(item);
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    getCopyItem(selected): Promise<any> {
        return new Promise(resolve => {
            if (selected) {
                const json = JSON.stringify(selected);
                const copy = deserialize(Layout, json);
                resolve(copy);
            } else {
                resolve(null);
            }
        });
    }
    getDuplicateItem(selected): Promise<any> {
        return new Promise(resolve => {
            if (selected) {
                const json = JSON.stringify(selected);
                const copy = deserialize(Layout, json);
                delete copy['SID'];
                delete copy['_Id'];
                delete copy['Version'];
                delete copy['_version'];
                delete copy['ObjectStatus'];
                if (copy._Name) {
                    copy._Name += ' - (Copy)';
                }
                setTimeout(() => {
                    resolve(copy);
                }, 100);
            } else {
                resolve(null);
            }
        });
    }
    getPasteItem(): Promise<any> {
        return new Promise(resolve => {
            const json = serialize(ClipboardHelper.ClipboardContent.content);
            const copy = deserialize(Layout, json);
            if (copy['_Id']) {
                this.metaService.ReadTemplateById(copy['_Id']).subscribe(result => {
                    if (!result) {
                        resolve(copy);
                    } else {
                        delete copy['SID'];
                        delete copy['_Id'];
                        delete copy['Version'];
                        delete copy['_version'];
                        delete copy['ObjectStatus'];
                        if (copy._Name) {
                            copy._Name += ' - (Copy)';
                        }
                        setTimeout(() => {
                            resolve(copy);
                        }, 100);
                    }
                });
            } else {
                resolve(copy);
            }
        });
    }
    protected setSelectedItem(item) {
        let text;
        if (item) {
            if (!item['Type']) {
                item['Type'] = item['ElementType'];
            }
            text = JSON.stringify(item);
        }
        this.Component.SelectedItem = item;
        this.SelectedTemplateText = text;
        this.cdRef.detectChanges();
    }

    async checkHasChanges() {
        if (!this.HasChanges && typeof this.SelectedTemplateText === 'string' && this.Component.SelectedItem) {
            const text = JSON.stringify(this.Component.SelectedItem);
            this.HasChanges = this.SelectedTemplateText !== text;
        }
        return await super.checkHasChanges();
    }

    protected setHasChanges(hasChanges) {
        super.setHasChanges(hasChanges);
        if (hasChanges === false) {
            let text;
            if (this.Component && this.Component.SelectedItem) {
                text = JSON.stringify(this.Component.SelectedItem);
            }
            this.SelectedTemplateText = text;
        }        
    }
}

@Component({
    selector: 'templates-detail',
    templateUrl: './templates.settings.html',
    styleUrls: ['./templates.settings.css']
})
export class TemplateDetail extends BaseListDetail {
    ContentTypes;

    constructor() {
        super();
        this.ContentTypes = EnumHelper.GetDropdownValues(ContentType);
    }
    
    showDialog() {
        const templateID = this.SelectedItem._Id;
            TemplateInfo.showDialog(templateID)
    }
    onSelectionChanged() {
        if (this.SelectedItem.Elements && this.SelectedItem.Elements.length > 0) {
            let type = '';
            switch (this.SelectedItem['ContentType']) {
                case ContentType.Grid: type = 'grid'; break;
                case ContentType.Flex: type = 'flex'; break;
                case ContentType.Raster: type = 'raster'; break;
                case ContentType.Canvas: type = 'canvas'; break;
                default: type = 'grid'; break;
            }
            this.SelectedItem.Elements[0]['ElementType'] = type;
        }
    }
    
    editLayout() {
        if (this.SelectedItem) {
            LayoutHelper.CheckTemplate(this.SelectedItem);
            VariableHelper.LoadVariables(this.SelectedItem);
            LayoutService.SelectedLayout.next(this.SelectedItem);
            LayoutService.LayoutEditMode.next(true);
        }
    }
}
@Component({
    selector: 'templates-info',
    templateUrl: './templateInfo.html',
    styleUrls: ['./templates.settings.css']
})
export class TemplateInfo  implements OnInit {
    ContentTypes;
    templateInfo;
    expandedSections:string[] = [];
    templateID:string = '';
    constructor(private templateService:MetaService, protected cdRef: ChangeDetectorRef) {
        this.ContentTypes = EnumHelper.GetDropdownValues(ContentType);
    }
    ngOnInit(): void {
        this.templateService.GetTemplateUsage(this.templateID).subscribe(useInfo => {
            this.templateInfo = useInfo;
            this.cdRef.detectChanges();
        }
        )
    }
    static showDialog(id) {
		BaseDialog.ShowDialog({
			ContentType: TemplateInfo,
			InitArgs: id,
			Title: '@@Template Usage',
			Buttons: DialogButton.Ok,
			FullScreen: true,
        });
    }
    toggleExpandable(sectionName:string) {
        if (this.expandedSections.includes(sectionName)) {
            this.expandedSections = this.expandedSections.filter(s => s !== sectionName)
            return
        }
        this.expandedSections.push(sectionName);
    }
    Initialize(args) {
        this.templateID = args;
    }    
}