import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowRegistry } from '../../../../services/workflow.service';
import { ElementValueSettings, ElementValueSettingsDataHelper } from '../elemValue/elemValue.settings';

export class AddToDataSourceModulExecuter extends WorkflowModuleExecuter {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'addToDataSourceModule';
        reg.Caption = '@@Wert zu Liste hinzufuegen';
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = AddToDataSourceModulExecuter;
        reg.GroupID = 'layout';
        reg.Index = 30;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualParameter) {
            if (status.ActualSettings) {
                if (typeof status.ActualSettings.ElemName === 'string') {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                    if (elem) {
                        if (elem.Element) {
                            const ds = elem.Element.DataSource;
                            if (Array.isArray(ds)) {
                                ds.push(status.ActualParameter);
                                elem.Element.DataSource = ds;
                                return 0;
                            } else {
                                status.Logger.logError('AddToDataSource modul: DataSource not of type array.');
                            }
                        } else {
                            status.Logger.logError('AddToDataSource modul: Control not found.');
                        }
                    } else {
                        status.Logger.logError('AddToDataSource modul: Element not found.');
                    }
                } else {
                    status.Logger.logError('AddToDataSource modul: No element name found.');
                }
            } else {
                status.Logger.logError('AddToDataSource modul: No settings found.');
            }
        } else {
            status.Logger.logError('AddToDataSource modul: No element found to add.');
        }
        return super.execute(status);
    }
}
