import { UUID } from 'angular2-uuid';
import { TranslatedString } from './translatedstring.model';

export class ContainerClassObject {
    Fields = [];
    Name: string;
    DeleteComplete: boolean;
    UseVersioning: boolean;
    Editable: boolean;
    DataModelID: any;
    DataSourceId: any;
    StoreOffline: boolean;

    constructor() {
        this.Name = '';
        this.DeleteComplete = false;
        this.UseVersioning = false;
        this.Editable = false;
        this.StoreOffline = false;
    }
}

export class AttributeProperty {
    Type: string;
    IsList: boolean;
    Index: number;
    IsMandatory: boolean;
    IsNullable: boolean;
    IsShared: boolean;
    IsReverse: boolean;
    IsReadOnly: boolean;
    IsPrimary: boolean;
    ID: string;
    Name: string;
    Caption: TranslatedString;
    AdvancedType: string;
    DataTyp: string;
    Default;

    constructor() {
        this.Type = '';
        this.IsList = false;
        this.Index = 0;
        this.IsMandatory = false;
        this.IsNullable = true;
        this.IsShared = false;
        this.IsReverse = false;
        this.IsReadOnly = false;
        this.IsPrimary = false;
        this.ID = UUID.UUID();
        this.Name = '';
    }

    public static GetFilterType(ap): string {
        if (ap) {
            if (ap.DataTyp) {
                return AttributeProperty.GetFilterTypeFromType(ap.DataTyp);
            }
            if (ap.Type) {
                return AttributeProperty.GetFilterTypeFromType(ap.Type);
            }
        }
        return '';
    }

    public static GetFilterTypeFromType(type): string {
        if (type) {
            switch (type) {
                case 'System.DateTime':
                    return 'date';
                case 'System.String':
                case 'System.Guid':
                    return 'string';
                case 'System.Int64':
                case 'System.Decimal':
                case 'System.Int32':
                case 'System.Double':
                case 'System.Int16':
                    return 'number';
                case 'System.Boolean':
                    return 'boolean';
            }
        }
        return '';
    }
}
