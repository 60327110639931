import { UnitType } from '../enums/unittype.enum';

export class LayoutUnit {
    Type: UnitType = UnitType.Pixel;
    Value: number;

    static ConvertTo(value: any): LayoutUnit {
        let result = new LayoutUnit();
        if (value) {
            value = value + '';
            if (value.indexOf('%') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('%')));
                result.Type = UnitType.Percent;
            } else if (value.indexOf('px') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('px')));
                result.Type = UnitType.Pixel;
            } else if (value.indexOf('em') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('em')));
                result.Type = UnitType.em;
            } else if (value.indexOf('fr') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('fr')));
                result.Type = UnitType.fr;
            } else if (value.indexOf('vh') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('vh')));
                result.Type = UnitType.fr;
            } else if(value.indexOf('vw') > 0) {
                result.Value = Number(value.substr(0, value.indexOf('vw')));
                result.Type = UnitType.fr;
            } else if (value.indexOf('calc') > 0) {
                result.Value = value.substr(value.indexOf('calc') + 4, value.length() -1);
                result.Type = UnitType.calc;
            } else if (value == 'auto') {
                result.Type = UnitType.Auto;
            } else {
                result.Value = Number(value);
                result.Type = UnitType.Percent;
            }
        } else {
            result = null;
        }
        return result;
    }
    static ConvertFrom(value: LayoutUnit): any {
        if (value) {
            switch (value.Type) {
                case UnitType.Percent: return value.Value + '%';
                case UnitType.Pixel: return value.Value + 'px';
                case UnitType.em: return value.Value + 'em';
                case UnitType.fr: return value.Value + 'fr';
                case UnitType.vw: return value.Value + 'vw';
                case UnitType.vh: return value.Value + 'vh';
                case UnitType.calc: return 'calc(' + value.Value + ')';
                case UnitType.Rest:
                case UnitType.Auto: return 'auto';
                default: return value.Value;
            }
        }
    }
    static ToStyle(unit) {
        if (unit && unit.Type > -1 && (unit.Value != undefined || unit.Type == UnitType.Auto)) {
            switch (unit.Type) {
                case UnitType.Pixel:
                    return unit.Value + "px";
                case UnitType.Percent:
                    return unit.Value + "%";
                case UnitType.Auto:
                    return "auto";
                case UnitType.em:
                    return unit.Value + "em";
                case UnitType.fr:
                    return unit.Value + "fr";
                case UnitType.vw:
                    return unit.Value + "vw";
                case UnitType.vh:
                    return unit.Value + "vh";
                case UnitType.calc:
                    return 'calc(' + unit.Value + ')';
            }
        }
        return '';
    }
    toStyle() {
        return LayoutUnit.ToStyle(this);
    }
    isValid(): boolean {
        if (typeof this.Type === 'number') {
            switch (this.Type) {
                case UnitType.Pixel:
                case UnitType.Percent:
                case UnitType.em:
                case UnitType.fr:
                case UnitType.vw:
                case UnitType.vh:
                    return typeof this.Value === 'number';
                case UnitType.Auto:
                case UnitType.Rest:
                case UnitType.calc:
                    return true;
            }
        }
        return false;
    }
    toJSON() {
        let style = this.toStyle()
        if (style || this.Type == UnitType.Rest) {
            return this
        } else {
            return null
        }
    }
}
