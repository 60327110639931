import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Comparer } from "../../../models/enums/comparer.enum";
import { DialogButton } from "../../../models/enums/dialogbutton.enum";
import { DialogResult } from "../../../models/enums/dialogresult.enum";
import { RequestFilter } from "../../../models/rest/requestfilter";
import { RequestOptions } from "../../../models/rest/requestoptions";
import { MetaService } from "../../../services/meta.service";
import { BaseDialog } from "../../dialogs/basedialog/base.dialog";
import { DeploymentActionDialog } from "../../dialogs/deploymentaction/deploymentaction.dialog";

@Component({
    selector: 'actions-widget',
    templateUrl: './actions.widget.html',
    styleUrls: ['./actions.widget.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsWidget implements OnInit, OnDestroy {

    Actions;

    constructor(private service: MetaService) {
    }
    ngOnInit(): void {
        this.Refresh();
    }
    ngOnDestroy(): void {
        
    }

    Refresh() {
        let options = new RequestOptions();
        options.Filters = [];
        let filter = new RequestFilter();
        filter.Name = 'Completed';
        filter.Operator = Comparer.Equal;
        filter.Value = false;
        options.Filters.push(filter);

        this.service.ReadDeploymentActions(options).subscribe((result) => {
            this.Actions = result;
        })
    }
    StartTask(action) {
        BaseDialog.ShowDialog({
            ContentType: DeploymentActionDialog,
            InitArgs: { Data: action },
            Title: action.Name,
            Buttons: DialogButton.None,
            Handler: (result) => {
                if (result == DialogResult.Ok) {
                    this.Refresh();
                }
                return true;
            },
            HeightText: '100vh',
            WidthText: '100vw',
            AutoFocus: false
        });
    }
}
