import { AboutControl } from '../components/common/aboutcontrol/about.control';
import { CronBuilder } from '../components/common/cronbuilder/cron.builder';
import { FormulaTextBox } from '../components/common/formulaEditor/formulatextbox.control';
import { UnitTypeSelectionControl } from '../appbuilder/controls/unittypeselection/unittypeselection.theme.control';
import { AccordionControl } from '../components/controls/accordion/accordion.control';
import { AdvancedFilterComponent } from '../components/controls/advancedfilter/advancedfilter.component';
import { ApplicationSwitcherComponent } from '../components/controls/appswitch/appswitch.component';
import { AutocompleteControl } from '../components/controls/autocomplete/autocomplete.control';
import { ButtonControl } from '../components/controls/button/button.control';
import { CarouselControl } from '../components/controls/carousel/carousel.control';
import { ChartsControl } from '../components/controls/charts/charts.control';
import { CheckBoxControl } from '../components/controls/checkbox/checkbox.control';
import { ChipListControl } from '../components/controls/chiplist/chiplist.control';
import { ComboBoxControl } from '../components/controls/combobox/combobox.control';
import { ContentControl } from '../components/controls/content/content.control';
import { DataCapsuleTagControl } from '../components/controls/datacapsuletag/data.capsule.tag.control';
import { DateTimePickerControl } from '../components/controls/datetimepicker/datetimepicker.control';
import { DownloadControl } from '../components/controls/download/download.control';
import { DropdownComponent } from '../components/controls/dropdown/dropdown.component';
import { EditorControl } from '../components/controls/editor/editor.control';
import { EmbedControl } from '../components/controls/embed/embed.control';
import { ColorPickerComponent } from '../components/controls/gradientpicker/colorpicker.control';
import { ColorPickerMenuComponent } from '../components/controls/gradientpicker/colorpickermenu.control';
import { GradientPicker } from '../components/controls/gradientpicker/gradientpicker.control';
import { IconControl } from '../components/controls/icon/icon.control';
import { IFrameControl } from '../components/controls/iframe/iframe.control';
import { ImageControl } from '../components/controls/image/image.control';
import { ImageEditorControl } from '../components/controls/imageeditor/imageeditor.control';
import { InputGroupControl } from '../components/controls/inputgroup/inputgroup.control';
import { LanguageMenu, LanguageMenuControl } from '../components/controls/languagemenu/language.menu';
import { LayoutItem } from '../components/controls/layoutitem/layout.item.component';
import { ListBoxControl } from '../components/controls/listbox/listbox.control';
import { SimpleListBoxControl } from '../components/controls/listbox/simple.listbox.control';
import { ListDetailControl } from '../components/controls/listdetail/listdetail.control';
import { LoadingComponent } from '../components/controls/loading/loading.component';
import { LoginControl } from '../components/controls/login/login.control';
import { MarkerControl } from '../components/controls/marker/marker.control';
import { MessageCenterControl } from '../components/controls/messagecenter/messagecenter.control';
import { NavigationTreeControl } from '../components/controls/navigationtree/navigationtree.control';
import { NavigationTreeItem } from '../components/controls/navigationtree/navigationtree.item';
import { ObjectViewerControl } from '../components/controls/objectviewer/objectviewer.control';
import { PageIndexComponent } from '../components/controls/output/page.index.component';
import { SumComponent } from '../components/controls/output/sum.component';
import { PDFViewerControl } from '../components/controls/pdfviewer/pdfviewer.control';
import { ProfileManagementControl } from '../components/controls/profilemanagement/profilemanagement.control';
import { QRComponent } from '../components/controls/qr/qr.component';
import { RadioButtonControl } from '../components/controls/radiobutton/radiobutton.control';
import { SchedulerControl } from '../components/controls/scheduler/scheduler.control';
import { SideNavControl } from '../components/controls/sidenav/sidenav.control';
import { SliderControl } from '../components/controls/slider/slider.control';
import { StepperControl } from '../components/controls/stepper/stepper.control';
import { TabControl } from '../components/controls/tab/tab.control';
import { EviTableColumnVisibilityControl } from '../components/controls/tables/columnvisibility/columnvisibility.control';
import { DataTableControl, EviTableHeaderCheckbox } from '../components/controls/tables/datatable/datatable.control';
import { PrimeTableControl } from '../components/controls/tables/primetable/primetable.control';
import { TextAreaControl } from '../components/controls/textarea/textarea.control';
import { TextBlockControl } from '../components/controls/textblock/textblock.control';
import { TextBoxControl } from '../components/controls/textbox/textbox.control';
import { ThemeSwitchControl } from '../components/controls/themeswitch/themeswitch.control';
import { TimespanControl } from '../components/controls/timespan/timespan.control';
import { ToggleButtonControl } from '../components/controls/togglebutton/togglebutton.control';
import { TranslationTextBoxComponent } from '../components/controls/translationtextbox/translation.textbox.component';
import { TranslationTextBox } from '../components/controls/translationtextbox/translation.textbox.control';
import { TranslationTextBoxDialog } from '../components/controls/translationtextbox/translation.textbox.dialog';
import { TreeControl } from '../components/controls/tree/tree.control';
import { TreeTableControl } from '../components/controls/treetable/treetable.control';
import { TreeTableItemControl } from '../components/controls/treetable/treetableitem.control';
import { UploadControl } from '../components/controls/upload/upload.control';
import { UserMenuControl } from '../components/controls/usermenu/usermenu.control';
import { VariableDefinitionControl } from '../components/controls/variable/variabledefinition.control';
import { VideoControl } from '../components/controls/video/video.control';
import { VjsPlayerComponent } from '../components/controls/video/videojs.player';
import { FileExplorerControl } from '../components/fileexplorer/fileexplorer.control';
import { SideNavLayout } from '../components/layouts/sidenav/sidenav.layout';
import { ChangeDataSourceAction } from '../settings/actions/datasource/changedatasource.action';
import { JobsLogsComponent } from '../settings/jobs/joblogs/jobs.logs';
import { JobsQueueComponent } from '../settings/jobs/queue/jobs.queue';
import { DataSourceStaticControl } from '../components/staticclasscontrols/datasource/datasource.static.control';
import { WorkflowStaticControl } from '../components/staticclasscontrols/workflow/workflow.static.control';
import { DebugWindow } from '../debugconsole/debug.window';
import { DebugWorkflowLog } from '../debugconsole/workflow/debug.workflow.log';
import { DebugWorkflowSettingsPage } from '../debugconsole/workflow/debug.workflow.settings.page';
import { DebugWorkflowConnectionPage } from '../debugconsole/workflow/debug.workflow.connection.page';
import { DebugWorkflowAttachDialog } from '../debugconsole/workflow/debug.workflow.attach.dialog';
import { DebugWorkflowBreakPointControl } from '../debugconsole/workflow/debug.workflow.breakpoint.control';
import { DebugWorkflowStateControl } from '../debugconsole/workflow/debug.workflow.state.control';
import {
    DebugScriptBreakpointControl, DebugScriptBreakpointSettingsControl
} from '../debugconsole/workflow/debug.script.breakpoint.control';
import { HrefControl } from '../components/controls/href/href.control';
import { HyperPayControl } from '../components/controls/hyperpay/hyperpay.control';
import { DefaultGroupHeader } from '../components/common/default.group.header';
import { TextBlockDocumentHeader } from '../components/common/textblock.document.header';
import { VariableChooser } from '../components/common/variable.chooser';
import { FilterTreeControl, FilterTreeComponent } from '../components/controls/filter/filter.tree.control';
import { FilterTreeDialog } from '../components/controls/filter/filter.tree.dialog';
import { FromToFilter } from '../components/controls/filter/from.to.filter';
import { ContainerSelectDialog } from '../components/dialogs/containerSelect.dialog';
import { DatadescriptionControl } from '../components/dialogs/datadescription/datadescription.control';
import { DatadescriptionEditDialog } from '../components/dialogs/datadescription/datadescription.edit.dialog';
import { DatadescriptionMenuTab } from '../components/menutabs/datadescription/datadescription.menu.tab';
import { FromToFilterMenuTab } from '../components/menutabs/fromtofilter/from.to.filter.menu.tab';
import { ObjectCreatorSourceMultiDimensional } from '../components/multipanels/objectcreatorsource/objectcreatorsource.multidim';
import { ObjectCreatorSourceRelational } from '../components/multipanels/objectcreatorsource/objectcreatorsource.relational';
import { ObjectCreatorSourceTreeBase } from '../components/multipanels/objectcreatorsource/objectcreatorsourcetree.base';
import { ObjectCreatorSourceTreeLevel } from '../components/multipanels/objectcreatorsource/objectcreatorsourcetree.level';
import { ObjectCreatorSourceTreeMeasure } from '../components/multipanels/objectcreatorsource/objectcreatorsourcetree.measure';
import { ObjectCreatorSourceTreeSpecial } from '../components/multipanels/objectcreatorsource/objectcreatorsourcetree.special';
import { FilterPanelControl } from '../components/objectcreator/filter/filterpanel.control';
import { MeasureDefinitionsControl } from '../components/objectcreator/measuredefinition/measuredef.control';
import {
    DynamicTimeReferenceControl
} from '../components/objectcreator/measuredefinition/panels/dynamictimereference/dynamictimereference.control';
import { ElementReferenceControl } from '../components/objectcreator/measuredefinition/panels/elementreference/elementreference.control';
import { ElementeReferenceTree } from '../components/objectcreator/measuredefinition/panels/elementreference/elementreference.tree';
import { ParentReferenceControl } from '../components/objectcreator/measuredefinition/panels/parentreference/parentreference.control';
import { AxisControl } from '../components/objectcreator/multidimensional/axis.control';
import { HeterogenAreaControl } from '../components/objectcreator/multidimensional/heterogenArea.control';
import { HeterogenSubAreaControl } from '../components/objectcreator/multidimensional/heterogenSubArea.control';
import { ObjectCreatorDropAreaCtrl } from '../components/objectcreator/objectcreator.droparea';
import { ObjectCreatorMainCtrl } from '../components/objectcreator/objectcreator.maincontrol';
import { ChartReportObject } from '../components/reportobjectcontrols/chart/chart.reportobject';
import { MacTableControl } from '../components/reportobjectcontrols/table/table.reportobject';
import { MultidimensionalVariablenItemControl } from '../components/objectcreator/variablenitem/multidimvariable.control';
import { FilterDropdownControl } from '../components/controls/filter/filter.dropdown.control';
import { NumberFormatPanel } from '../components/common/number.format.panel';
import { StyleControl } from '../components/common/style.control';
import { DataTaskPanel } from '../components/objectcreator/datataskpanel/datatask.panel';
import { ChartSeriesSettingsControl } from '../components/common/chart.series.settings.control';
import { MacPlanningControl } from '../components/reportobjectcontrols/planning/planning.reportobject';
import { ReferenceOverviewComponent } from '../components/controls/reference/reference.overview.component';
import { ReferenceAxisComponent } from '../components/controls/reference/reference.axis.component';
import { MeasureDefinitionRelationalControl } from '../components/objectcreator/measuredefrel/measuredef.rel.control';
import { JobInfoDialog } from '../settings/jobs/queue/job.info.dialog';
import { ReportControlsAccordion, ReportObjectsAccordion } from '../appbuilder/menutabs/controls/controls.menu.tab';
import { GMapControl } from '../components/controls/gmap/gmap.control';
import {SimpleReportingControl} from "../components/controls/simplereporting/simplereporting.component"
import { ReportingPreviewComponent } from "../components/controls/simplereporting/preview-dialogue/preview-dialogue.component"
import { ChartWrapperComponent } from '../components/controls/simplereporting/chartwrapper.component';
import {BasicDataTableControl} from "../components/controls/tables/basic-ptable/basic-datatable.control";

export const APP_CONTROLS = [
    LayoutItem,
    TextBoxControl,
    TextAreaControl,
    GradientPicker,
    CheckBoxControl,
    ComboBoxControl,
    ButtonControl,
    ToggleButtonControl,
    GMapControl,
    RadioButtonControl,
    DateTimePickerControl,
    SideNavControl,
    TreeControl,
    ListBoxControl,
    SimpleListBoxControl,
    ContentControl,
    TranslationTextBox,
    TranslationTextBoxDialog,
    TranslationTextBoxComponent,
    ListDetailControl,
    SideNavLayout,
    NavigationTreeControl,
    NavigationTreeItem,
    SliderControl,
    ImageControl,
    ObjectViewerControl,
    MarkerControl,
    TextBlockControl,
    LanguageMenu,
    LanguageMenuControl,
    AdvancedFilterComponent,
    LoginControl,
    LoadingComponent,
    UserMenuControl,
    EditorControl,
    DataTableControl,
    BasicDataTableControl,
    AccordionControl,
    TabControl,
    IFrameControl,
    EmbedControl,
    AutocompleteControl,
    CarouselControl,
    VideoControl,
    ChartsControl,
    UploadControl,
    SchedulerControl,
    FileExplorerControl,
    TreeTableControl,
    TreeTableItemControl,
    CronBuilder,
    ColorPickerComponent,
    FormulaTextBox,
    VariableDefinitionControl,
    ImageEditorControl,
    ChipListControl,
    ThemeSwitchControl,
    MessageCenterControl,
    UnitTypeSelectionControl,
    DropdownComponent,
    AboutControl,
    StepperControl,
    PDFViewerControl,
    ApplicationSwitcherComponent,
    JobsLogsComponent,
    JobsQueueComponent,
    JobInfoDialog,
    VjsPlayerComponent,
    ChangeDataSourceAction,
    EviTableColumnVisibilityControl,
    PageIndexComponent,
    SumComponent,
    ColorPickerMenuComponent,
    ProfileManagementControl,
    IconControl,
    TimespanControl,
    PrimeTableControl,
    InputGroupControl,
    DataCapsuleTagControl,
    EviTableHeaderCheckbox,
    DownloadControl,
    DebugWindow,
    DebugWorkflowLog,
    DebugWorkflowSettingsPage,
    DebugWorkflowConnectionPage,
    DebugWorkflowAttachDialog,
    DebugWorkflowBreakPointControl,
    DebugWorkflowStateControl,
    DebugScriptBreakpointControl,
    DebugScriptBreakpointSettingsControl,
    QRComponent,
    HrefControl,
    HyperPayControl,
    DefaultGroupHeader,
    TextBlockDocumentHeader,
    ObjectCreatorMainCtrl,
    ObjectCreatorDropAreaCtrl,
    ObjectCreatorSourceMultiDimensional,
    ObjectCreatorSourceRelational,
    ObjectCreatorSourceTreeBase,
    ObjectCreatorSourceTreeMeasure,
    ObjectCreatorSourceTreeLevel,
    ObjectCreatorSourceTreeSpecial,
    AxisControl,
    HeterogenAreaControl,
    HeterogenSubAreaControl,
    ReportControlsAccordion,
    ReportObjectsAccordion,
    // BaseReportObject,
    DatadescriptionMenuTab,
    DatadescriptionEditDialog,
    DatadescriptionControl,
    ContainerSelectDialog,
    MacTableControl,
    MacPlanningControl,
    ChartReportObject,
    FilterTreeControl,
    FilterTreeComponent,
    FilterDropdownControl,
    FilterTreeDialog,
    FilterPanelControl,
    MeasureDefinitionsControl,
    ElementReferenceControl,
    ElementeReferenceTree,
    ParentReferenceControl,
    DynamicTimeReferenceControl,
    VariableChooser,
    FromToFilter,
    FromToFilterMenuTab,
    MultidimensionalVariablenItemControl,
    StyleControl,
    NumberFormatPanel,
    DataTaskPanel,
    ChartSeriesSettingsControl,
    ReferenceOverviewComponent,
    ReferenceAxisComponent,
    MeasureDefinitionRelationalControl,
    SimpleReportingControl,
    ReportingPreviewComponent,
    ChartWrapperComponent
];

export const APP_STATIC_CONTROLS = [
    DataSourceStaticControl,
    WorkflowStaticControl
];
