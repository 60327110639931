import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class MetaEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/meta/';
    EndPoints = [
        {
            Name: 'ReadChangeDates',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query('evidanza.Core.Service.Meta.ChangeDates', null);
            }
        },
        {
            Name: 'GetAllThemes',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query('evidanza.App.Shared.Data.Theme', null);
            }
        },
        {
            Name: 'GetThemes',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    if (body && body.Filters) {
                        body.Filters.forEach((filter) => {
                            if (filter.Name == '_Id') {
                                filter.Name = 'SID'
                            }
                        });
                    }
                    OfflineHelper.Query('evidanza.App.Shared.Data.Theme', body).subscribe((result) => {
                        let retVal = [];
                        if (result) {
                            result.forEach((item) => {
                                retVal.push({
                                    Caption: item.Caption.DefaultValue,
                                    SID:item.SID
                                })
                            })
                        }
                        observer.next(retVal);
                    });
                });
            }
        },
        {
            Name: 'GetDefaultTheme',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Data.Theme'].filter((value) => {
                        return value.IsDefault == true;
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'GetTheme',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Data.Theme'].filter((value) => {
                        return value.SID == params['id'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'GetTags',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query('evidanza.App.Shared.Data.MetaTag', null);
            }
        },
        {
            Name: 'GetAllCustomCSSVariables',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
            }
        },
        {
            Name: 'ReadLayoutById',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.Layout'].filter((value) => {
                        return value._Id == params['id'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'ReadLayoutByKey',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.Layout'].filter((value) => {
                        return value._key == params['key'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'ReadLayouts',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query('evidanza.App.Shared.Layouts.Layout', null);
            }
        },
        //{
        //    Name: 'AddOrUpdateLayout',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Layouts.Layout', null);
        //    }
        //},
        //{
        //    Name: 'AddOrUpdateLayoutWithKey',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Layouts.Layout', null);
        //    }
        //},
        //{
        //    Name: 'RemoveLayout',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Layouts.Layout', null);
        //    }
        //},
        //{
        //    Name: 'CopyLayout',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Layouts.Layout', null);
        //    }
        //},
    ]
}