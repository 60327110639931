import { HttpClient } from '@angular/common/http';
import { deserialize, plainToClass, serialize } from 'class-transformer';
import { ChartData } from '../../helpers/chart.data';
import { InjectorHelper } from '../../helpers/injector.helper';
import { RelQueryHelper } from '../../helpers/relquery.helper';
import { ReportingService } from '../../services/reporting.service';
import { ChartSettings } from '../controls/chart.model';
import { DataDescriptionRelational } from '../datadescription/relational/datadescriptionrelational.model';
import { ChartDataSource } from './chart.datasource';

export class RelationalChartDataSource extends ChartDataSource {
    DataDescription: DataDescriptionRelational;
    DataService: ReportingService;
    LastData;
    FieldMap;

    constructor(protected reportObject) {
        super(reportObject);
        const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
        this.DataService = new ReportingService(http);
        if (reportObject && reportObject.Data) {
            this.DataDescription = plainToClass(DataDescriptionRelational, reportObject.Data.Datadescription);
        }
    }

    Refresh(chartSettings: ChartSettings) {
        if (this.DataDescription && this.DataDescription.Fields && chartSettings) {
            const dateFields = [];
            this.FieldMap = new Map();
            this.DataDescription.Fields.forEach(x => {
                this.FieldMap.set('' + x.ID, x);
                if (x.Type === 'System.DateTime') {
                    dateFields.push(x.Name);
                }
            });
            this.Loading.next(true);
            this.DataService.ExecuteQuery(RelQueryHelper.GenerateQuery(this.DataDescription, this.reportObject)).subscribe((result) => {
                if (result) {
                    if (result.length > 0 && dateFields.length > 0) {
                        result.forEach(x => {
                            dateFields.forEach(y => {
                                const val = x[y];
                                if (typeof val !== 'undefined' && val !== null && !(val instanceof Date)) {
                                    x[y] = new Date(val);
                                }
                            });
                        });
                    }
                    this.LastData = result;
                    this.UpdateChart(chartSettings);
                }
                this.Loading.next(false);
            });
        } else {
            this.LastData = null;
            this.FieldMap = new Map();
            this.UpdateChart(chartSettings);
        }
    }

    UpdateChart(chartSettings: ChartSettings) {
        if (chartSettings && this.LastData) {
            const json = serialize(chartSettings);
            const settings = deserialize(ChartSettings, json);
            if (settings.Series) {
                if (this.reportObject) {
                    const le = this.reportObject.LayoutElement;
                    if (le && le.UsePaletteForSingleSeries &&
                        settings.Series && settings.Series.length == 1) {
                        ChartData.GetPalette(settings).then(x => {
                            if (x && x.length > 0) {
                                const first = settings.Series[0];
                                const field = this.FieldMap.get(first['ColumnName']);
                                if (field) {
                                    first['ColumnKey'] = first['ColumnName'];
                                    first['ColumnName'] = field.Caption;
                                }
                                const styleKey = '_PalleteStyle_';
                                first['StyleKey'] = styleKey;
                                this.LastData.forEach((row, i) => {
                                    row[styleKey] = '' + (i % x.length);
                                });
                                const styles = {};
                                x.forEach((y, i) => {
                                    styles['' + i] = y;
                                });
                                this.Data.next({
                                    Data: {
                                        Data: this.LastData,
                                        Styles: styles
                                    },
                                    Settings: settings
                                });
                            } else {
                                settings.Series.forEach(s => {
                                    const field = this.FieldMap.get(s['ColumnName']);
                                    if (field) {
                                        s['ColumnKey'] = s['ColumnName'];
                                        s['ColumnName'] = field.Caption;
                                    }
                                });
                                this.Data.next({
                                    Data: this.LastData,
                                    Settings: settings
                                });
                            }
                        });
                        return;
                    }
                }
                settings.Series.forEach(s => {
                    const field = this.FieldMap.get(s['ColumnName']);
                    if (field) {
                        s['ColumnKey'] = s['ColumnName'];
                        s['ColumnName'] = field.Caption;
                    }
                });
            }
            this.Data.next({
                Data: this.LastData,
                Settings: settings
            });
        } else {
            this.Data.next({
                Data: null,
                Settings: null
            });
        }
    }
}
