import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { KPIService } from '../../../../services/kpi.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { KPINode } from '../fillkpi/fill.kpi.settings';

@Component({
    selector: 'wf-read-kpi-settings-item',
    templateUrl: 'read.kpi.settings.html'
})
export class ReadKPISettings extends WorkflowDialogContent {
    Data = new ReadKPISettingsData();
    KPINodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readKPIModule';
        reg.Caption = '@@KPIs auslesen';
        reg.GroupID = '-1';
        reg.Index = 20;
        reg.SettingsControl = ReadKPISettings;
        reg.SettingsTypeHelper = new ReadKPISettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: KPIService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetKPIStructure().subscribe(result => {
            const kpiDict = new Map();
            if (result && result.Categories) {
                let index = 0;
                const kpiNodes = [];
                result.Categories.forEach(cat => {
                    const btn = new ABaseTreeNode(index++);
                    btn.Caption = cat.Caption;
                    btn.Selectable = false;
                    btn.HasChildren = true;
                    const children = [];
                    cat.KPIs.forEach(kpi => {
                        const kNode = new KPINode(index++);
                        kNode.Caption = kpi.Caption;
                        kNode.SID = kpi.SID;
                        kpiDict.set(kpi.SID, kpi.Caption);
                        children.push(kNode);
                    });
                    btn.Children = children;
                    kpiNodes.push(btn);
                });
                this.KPINodes = kpiNodes;
            }
            this.Data.KPIs.forEach(x => {
                const caption = kpiDict.get(x.ID);
                if (caption) {
                    x['Caption'] = caption;
                } else {
                    x.ID = null;
                    x['Caption'] = '';
                }
            });
        });
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadKPISettingsData, json);
        }
    }

    getResult(): any {
        const kpis = [];
        this.Data.KPIs.forEach(kpi => {
            if (kpi.ID) {
                delete kpi['Caption'];
                const values = [];
                kpi.Values.forEach(val => {
                    if (val.Key && (val.UseForState || val.StatusKey)) {
                        values.push(val);
                    }
                });
                kpi.Values = values;
                if (kpi.ReadMultiple) {
                    if (kpi.Values.length > 0) {
                        kpis.push(kpi);
                    }
                } else if (kpi.UseForState || kpi.StatusKey) {
                    kpis.push(kpi);
                }
            }
        });
        this.Data.KPIs = kpis;
        return this.Data;
    }

    onKPISelected(kpi, ev) {
        if (kpi && ev) {
            kpi.ID = ev.SID;
            kpi.Caption = ev.Caption;
        }
    }

    addKPI() {
        if (this.Data) {
            const newItem = new ReadKPIItem();
            newItem['Caption'] = '';
            if (this.Data.KPIs) {
                this.Data.KPIs.push(newItem);
            } else {
                this.Data.KPIs = [newItem];
            }
        }
    }

    removeKPI(i) {
        if (this.Data && this.Data.KPIs) {
            this.Data.KPIs.splice(i, 1);
        }
    }

    addKPIItem(kpi) {
        if (kpi) {
            if (kpi.Values) {
                kpi.Values.push(new ReadKPIItemEntry());
            } else {
                kpi.Values = [new ReadKPIItemEntry()];
            }
        }
    }

    removeKPIItem(kpi, i) {
        if (kpi && kpi.Values) {
            kpi.Values.splice(i, 1);
        }
    }

    onUseForStateChecked(val, ev) {
        if (ev && ev.checked) {
            this.Data.KPIs.forEach(kpi => {
                kpi.UseForState = false;
                kpi.Values.forEach(v => {
                    v.UseForState = false;
                });
            });
            val.UseForState = true;
        }
    }
}

export class ReadKPIItemEntry {
    Key: string;
    StatusKey: string;
    UseForState = false;
}

// @dynamic
export class ReadKPIItem {
    ID: string;
    StatusKey: string;
    UseForState = false;
    ReadMultiple = false;
    @Type(() => ReadKPIItemEntry)
    Values: ReadKPIItemEntry[] = [];
}

// @dynamic
export class ReadKPISettingsData extends AServiceWorkflowData {
    @Type(() => ReadKPIItem)
    KPIs: ReadKPIItem[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.KPIOperations.ReadKPI.ReadKPISettingsData';
    }
}

export class ReadKPISettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadKPISettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.KPIs) {
                const list = [];
                settings.KPIs.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}
