import { Page } from '../../models/enums/page.enum';
import { CONTROLGROUPS, MENUTABREGISTRY, PROPERTYGROUPS, REGISTRY } from '../../services/dynamic.component.service';
import { FilterTreeControl } from '../../components/controls/filter/filter.tree.control';
import { DatadescriptionMenuTab } from '../../components/menutabs/datadescription/datadescription.menu.tab';
import { ChartReportObject } from '../../components/reportobjectcontrols/chart/chart.reportobject';
import { MacTableControl } from '../../components/reportobjectcontrols/table/table.reportobject';
import { ChartPropertyMenuTab } from '../../components/reportobjectmenus/chart/chart.menu.tab';
import { QuerySelectMenuTab } from '../../components/reportobjectmenus/query/query.menu.tab';
import { MacTablePropertyMenuTab } from '../../components/reportobjectmenus/table/mactable.menu.tab';
import { FilterTreeMenuTab } from '../../components/menutabs/filtertree/filtertree.menu.tab';
import { FromToFilter } from '../../components/controls/filter/from.to.filter';
import { FromToFilterMenuTab } from '../../components/menutabs/fromtofilter/from.to.filter.menu.tab';
import { FilterDropdownControl } from '../../components/controls/filter/filter.dropdown.control';
import { FilterDropdownMenuTab } from '../../components/menutabs/filtertree/filter.dropdown.menu.tab';
import { MacPlanningControl } from '../../components/reportobjectcontrols/planning/planning.reportobject';
import { MacPlanningPropertyMenuTab } from '../../components/reportobjectmenus/planning/planning.menu.tab';
import { QueriesSelectMenuTab } from '../../components/reportobjectmenus/query/queries.menu.tab';
import { StylePanel } from '../../components/common/style.control';
import { LayoutService } from '../../services/layout.service';
import { ViewType } from '../../models/enums/viewtype.enum';
import { ReportControlsAccordion, ReportObjectsAccordion } from '../../appbuilder/menutabs/controls/controls.menu.tab';
import config from '../../../assets/config.json'
export class ComponentRegistryMac {
    private static ControlTypes = ['tablereportObject', 'chartreportObject'];
    private static MulitControlTypes = ['planningreportObject'];

    public static Initialize() {
        MENUTABREGISTRY.push({ ID: 'client_datadescription', Icon: 'datadescription', Tooltip: '@@Datadescription', Index: 64, Content: DatadescriptionMenuTab, Position: 2 });
        MENUTABREGISTRY.push({ ID: 'client_chart', Icon: 'chart', Tooltip: '@@Chartsettings', Index: 65, Content: ChartPropertyMenuTab, CheckVisible: ChartPropertyMenuTab.CheckVisible,Position: 2 });
        MENUTABREGISTRY.push({ ID: 'client_tablesettings', Icon: 'table_chart', Tooltip: '@@Tablesettings', Index: 66, Content: MacTablePropertyMenuTab, CheckVisible: MacTablePropertyMenuTab.CheckVisible,Position: 2 });
        MENUTABREGISTRY.push({ ID: 'client_planningsettings', Icon: 'table_chart', Tooltip: '@@Planningsettings', Index: 66, Content: MacPlanningPropertyMenuTab, CheckVisible: MacPlanningPropertyMenuTab.CheckVisible,Position: 2 });
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
		const isHaveAccess = userRole && userRole.length > 0 && userRole[0] !== config.FREE_USER_ID
        if (isHaveAccess) {
            CONTROLGROUPS.push({ SID: 'Reportobjects', Caption: '@@BI and Reporting', Index: 21, Content: ReportObjectsAccordion });
        }

        REGISTRY['tablereportObject'] = { 
            Control: MacTableControl,
            Icon: 'text_fields',
            Label: '@@Tabelle',
            type: 'Reportobject',
            page: [Page.App, Page.Layout]
        };
        REGISTRY['planningreportObject'] = {
            Control: MacPlanningControl,
            Icon: 'text_fields',
            Label: '@@Planning',
            type: 'Reportobject',
            page: [Page.None]
        };
        REGISTRY['chartreportObject'] = {
            Control: ChartReportObject,
            Icon: 'chart',
            Label: '@@Chart',
            type: 'Reportobject',
            page: [Page.App, Page.Layout]
        };
        REGISTRY[FilterTreeControl.Type] = {
            Control: FilterTreeControl,
            Icon: 'device_hub',
            Label: '@@FilterTree',
            type: 'Reportobject',
            page: [Page.App, Page.Layout],
            MenuTab: FilterTreeMenuTab
        };
        REGISTRY[FilterDropdownControl.Type] = {
            Control: FilterDropdownControl,
            Icon: 'arrow_down',
            Label: '@@FilterDropdown',
            type: 'Reportobject',
            page: [Page.App, Page.Layout],
            MenuTab: FilterDropdownMenuTab
        };
        REGISTRY[FromToFilter.Type] = {
            Control: FromToFilter,
            Icon: 'calendar',
            Label: '@@Von-Bis',
            type: 'Reportobject',
            page: [Page.App, Page.Layout],
            MenuTab: FromToFilterMenuTab
        };
        PROPERTYGROUPS.push({
            Caption: '@@Query',
            Index: 8800,
            Content: QuerySelectMenuTab,
            CheckOnChange: ComponentRegistryMac.CheckMenuTab
        });
        PROPERTYGROUPS.push({
            Caption: '@@Query',
            Index: 8800,
            Content: QueriesSelectMenuTab,
            CheckOnChange: ComponentRegistryMac.CheckMultiMenuTab
        });
        let Initialized = false;
        LayoutService.ViewType.subscribe((vt) => {
            if (vt == ViewType.Edit && !Initialized) {
                StylePanel.InitPanel();
            }
        });
    }

    private static CheckMenuTab(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        menuItem.Visible = selected && ComponentRegistryMac.ControlTypes.indexOf(selected.ElementType) > -1;
    }
    private static CheckMultiMenuTab(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        menuItem.Visible = selected && ComponentRegistryMac.MulitControlTypes.indexOf(selected.ElementType) > -1;
    }
}
