import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';


@Component({
    selector: 'togglebutton-theme-control',
    templateUrl: './togglebutton.theme.control.html',
    styleUrls: ['./togglebutton.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TogglebuttonThemeControl extends BaseThemeControl {
    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption(): string {
        return this.CaptionValue;
    }
    set Caption(val: string) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<string>();
    //#endregion
    //#region CaptionPosition
    CaptionPositionValue: string;
    @Input()
    get CaptionPosition(): string {
        return this.CaptionPositionValue;
    }
    set CaptionPosition(val: string) {
        this.CaptionPositionValue = val;
        this.CaptionPositionChange.emit(this.CaptionPositionValue);
    }

    @Output() CaptionPositionChange = new EventEmitter<string>();
    //#endregion
    //#region Icon
    IconValue
    @Input()
    get Icon() {
        return this.IconValue;
    }
    set Icon(val) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
        this.cdRef.detectChanges();
    }

    @Output() IconChange = new EventEmitter<any>();
    //#endregion
}
