import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScriptLibraryService } from '../../../services/scriptlibrary.service';
import { Registry } from '../../../script/modules/registry.script';
import { Class } from '../../../script/script.model';
declare var monaco: any;

@Component({
    selector: 'evi-scripteditor',
    templateUrl: './scripteditor.control.html',
    styleUrls: ['./scripteditor.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScriptEditor implements AfterViewInit {
    editorOptions = {
        automaticLayout: true,
        language: 'typescript'
    };
    editor;
    Service: ScriptLibraryService;
    Initialized=false;
    //#region Script
    ScriptValue
    ConnectorIdValue;

    @Input()
    get ConnectorId() {
        return this.ConnectorIdValue;
    }
    set ConnectorId(val) {
        this.ConnectorIdValue = val;
    }

    @Input()
    get Script() {
        return this.ScriptValue;
    }
    set Script(val) {
        if (!val) {
            this.ScriptValue = 'import { WorkflowStatus, Global } from "System";\r\nexport class Main {\r\n    constructor() {\r\n    }\r\n    run(): void {\r\n    }\r\n    stop(): void {\r\n    }\r\n}';
        } else {
            this.ScriptValue = val;
        }
        this.ScriptChange.emit(this.ScriptValue);
        this.cdRef.detectChanges();
    }

    @Output() ScriptChange = new EventEmitter<any>();
    //#endregion

    //#region IsClientScript
    IsClientScriptValue: boolean = false;
    @Input()
    get IsClientScript() {
        return this.IsClientScriptValue;
    }
    set IsClientScript(val) {
        this.IsClientScriptValue = val;
        this.IsClientScriptChange.emit(this.IsClientScriptValue);
    }

    @Output() IsClientScriptChange = new EventEmitter<any>();
    //#endregion
    @Output() EditorInitialized = new EventEmitter<any>();
    constructor(private service: ScriptLibraryService, private cdRef: ChangeDetectorRef) {
        this.Service = service;
    }
    ngAfterViewInit(): void {
        this.SizeChanged();
    }

    async init(editor) {
        this.editor = editor;

        monaco.languages.typescript.typescriptDefaults.setExtraLibs("");

        //#region compiler options
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES2016,
            allowNonTsExtensions: true,
            moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
            module: monaco.languages.typescript.ModuleKind.CommonJS,
            noEmit: true,
            typeRoots: ["node_modules/@types"]
        });
        monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: false,
            noSyntaxValidation: false
        });
        //#endregion
        //#region Load Script Registry
        if (this.IsClientScript) {
            Registry.Modules.forEach((module, key) => {
                var mod = "";
                mod += "declare module '" + key + "' {";
                module.Classes.forEach((cls, key) => {
                    mod += this.CreateLibrarySchema(cls, key);
                });
                mod += "}";

                let libUri = 'node_modules/@types/' + key + '/index.d.ts';
                monaco.languages.typescript.typescriptDefaults.addExtraLib(mod, libUri);
            });
        } 
        this.Service.GetScriptImports(this.ConnectorIdValue, this.IsClientScript).subscribe((result) => {
            if (result) {
                let files = Object.keys(result);
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    let libSource = result[file];
                    let libUri = 'node_modules/@types/' + file + '/index.d.ts';
                    monaco.languages.typescript.typescriptDefaults.addExtraLib(libSource, libUri);
                }
            }
        });
        
        //#endregion
        //#region Highlighting
        //const allLangs = await monaco.languages.getLanguages();
        //let lang: any = allLangs.find(({ id }) => id === 'typescript');
        //let ts = await lang.loader();
        //console.log(ts);
        
        //lang = allLangs.find(({ id }) => id === 'csharp');
        //let csharp = await lang.loader();
        //console.log(csharp);
        //ts.language = csharp.language;
        //for (let key in customTokenizer) {
        //    const value = customTokenizer[key];
        //    if (key === 'tokenizer') {
        //        for (let category in value) {
        //            const tokenDefs = value[category];
        //            if (!jsLang.tokenizer.hasOwnProperty(category)) {
        //                jsLang.tokenizer[category] = [];
        //            }
        //            if (Array.isArray(tokenDefs)) {
        //                jsLang.tokenizer[category].unshift.apply(jsLang.tokenizer[category], tokenDefs)
        //            }
        //        }
        //    } else if (Array.isArray(value)) {
        //        if (!jsLang.hasOwnProperty(key)) {
        //            jsLang[key] = [];
        //        }
        //        jsLang[key].unshift.apply(jsLang[key], value)
        //    }
        //}
        //#endregion
        this.SizeChanged();
        this.Initialized = true;
        let that = this;
        this.editor.onDidChangeModelContent(function (e) {
            that.TextChanged.emit(e);
        });
        this.EditorInitialized.emit(this.editor);
        this.cdRef.detectChanges();
    }

    SizeChanged() {
        setTimeout(() => {
            if (this.editor) {
                this.editor.layout();
            }
        }, 100);
    }
    onResized(event) {
        this.SizeChanged();
    }

    GetEditorStyle() {
        return {
            'min-height': '200px',
            'min-width': '400px',
            'height': this.Initialized?'100%':'200px'
        }
    }
    @Output() TextChanged = new EventEmitter<any>();
    OnItemChanged(event) {
        this.TextChanged.emit(event);
    }

    private CreateLibrarySchema(cls: Class, name:string) {
        var retVal = "";

        retVal += "export declare class " + name + "{";

        cls.Properties.forEach((item, key) => {
            var line = "";
            var value = item;
            if (value.IsPublic) {
                line += "public ";
            }
            else {
                line += "private ";
            }
            if (value.IsStatic) {
                line += "static ";
            }
            if (value.IsReadOnly) {
                line += "readonly ";
            }
            line += value.Name;
            if (value.Class) {
                line += ":";
                line += value.Class;
            }
            line += ";";
            retVal += line;
        });
        cls.Functions.forEach((item, key) => {
            var line = ""
            var value = item;
            if (!value.IsInlineFunction) {
                if (value.IsPublic) {
                    line += "public ";
                }
                else {
                    line += "private ";
                }
                if (value.IsStatic) {
                    line += "static ";
                }
                line += value.Name;
                line += "(";
                for (var i = 0; i < value.Parameters.length; i++) {
                    var parameter = value.Parameters[i];
                    line += parameter.Name;
                    if (parameter.IsOptional) {
                        line += "?";
                    }
                    line += ":";
                    line += parameter.Class;
                    if (i + 1 < value.Parameters.length) {
                        line += ", ";
                    }
                }
                line += ")";
                if (value.ReturnClass) {
                    line += ":";
                    line += value.ReturnClass;
                }
                line += ";";
                retVal+=line;
            }
        });

        retVal += "}";
        return retVal;
    }
}
