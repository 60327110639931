import { Component } from '@angular/core';
import { deserialize, plainToClass, serialize, Type } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { StyleBase } from '../../../../models/layoutbase.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { MetaService } from '../../../../services/meta.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { ElementValueSettings, ElementValueSettingsDataHelper } from '../elemValue/elemValue.settings';
import { RemoveMultiStyleSettings } from './remove.multi.style.settings';
import { SetMultiStyleSettings } from './set.multi.style.settings';

@Component({
    selector: 'wf-setstyle-settings',
    templateUrl: './set.style.settings.html'
})
export class SetStyleSettings extends WorkflowDialogContent {
    static ModuleID = 'setStyleWFModule';

    Data = new SetStyleSettingsData();
    StyleList = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetStyleSettings.ModuleID;
        reg.Caption = '@@Style anwenden';
        reg.GroupID = 'wfActions';
        reg.Index = 70;
        reg.SettingsControl = SetStyleSettings;
        reg.SettingsTypeHelper = new SetStyleSettingsDataHelper();
        reg.Executer = SetStyleModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetMultiStyleSettings.ModuleID;
        return reg;
    }

    constructor(private metaService: MetaService) {
        super();
        this.HasExpressions = true;
    }

    ngOnInit() {
        this.metaService.GetControlStyles().subscribe(result => {
            if (result) {
                this.StyleList = result;
            }
        });
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetStyleSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }
}

// @dynamic
export class SetStyleSettingsData {
    ElemName: string;
    StyleRef: string;
    @Type(() => StyleBase)
    Layout: StyleBase = new StyleBase();
}

export class SetStyleSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new SetStyleSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class SetStyleModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        let layoutStyle;
                        if (status.ActualSettings.StyleRef) {
                            const style = await CacheService.ReadStyle(status.ActualSettings.StyleRef);
                            if (style) {
                                const json = style['StyleJSON'];
                                if (json) {
                                    layoutStyle = plainToClass(StyleBase, JSON.parse(json));
                                }
                            }
                        } else {
                            layoutStyle = plainToClass(StyleBase, status.ActualSettings.Layout);
                        }
                        if (layoutStyle) {
                            if (Array.isArray(elem.Element.WorkflowStyles)) {
                                elem.Element.WorkflowStyles.push(layoutStyle);
                            } else {
                                elem.Element.WorkflowStyles = [layoutStyle];
                            }
                            elem.Element.onWorkflowStylesChanged();
                        }
                        return 0;
                    } else {
                        status.Logger.logError('SetStyle modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('SetStyle modul: Element not found.');
                }
            } else {
                status.Logger.logError('SetStyle modul: No element name found.');
            }
        } else {
            status.Logger.logError('SetStyle modul: No settings found.');
        }
        return super.execute(status);
    }
}

export class RemoveStyleModuleExecuter extends WorkflowModuleExecuter {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'removeStyleWFModule';
        reg.Caption = '@@Style zuruecksetzen';
        reg.GroupID = 'wfActions';
        reg.Index = 80;
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = RemoveStyleModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = RemoveMultiStyleSettings.ModuleID;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        elem.Element.WorkflowStyles = [];
                        elem.Element.onWorkflowStylesChanged();
                        return 0;
                    } else {
                        status.Logger.logError('RemoveStyle modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('RemoveStyle modul: Element not found.');
                }
            } else {
                status.Logger.logError('RemoveStyle modul: No element name found.');
            }
        } else {
            status.Logger.logError('RemoveStyle modul: No settings found.');
        }
        return super.execute(status);
    }
}
