import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, Directive, ViewChild } from '@angular/core';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Directive()
export abstract class APythonSettings extends WorkflowDialogContent implements AfterViewInit {
    AllColumns = [];
    PythonType = 0;
    Modules = [];
    ReturnType = 0;
    CollectData = false;
    NewColumns = [];
    Parameters = [];
    Code;
    editorOptions = {
        language: 'python'
    };
    @ViewChild('editor') editor;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    UsedColCaption: string;
    NewColCaption: string;
    AddNewColCaption: string;
    DeleteColCaption: string;

    constructor() {
        super();
        this.UseActualState = true;
    }

    ngAfterViewInit(): void {
        this.fullScreenChanged(false);
    }

    initialize(data: any) {
        if (data) {
            if (typeof data.PythonType === 'number') {
                this.PythonType = data.PythonType;
            }
            if (data.UsedModules) {
                this.Modules.push(...data.UsedModules);
            }
            if (typeof data.ReturnType === 'number') {
                this.ReturnType = data.ReturnType;
            }
            if (typeof data.CollectData === 'boolean') {
                this.CollectData = data.CollectData;
            }
            if (data.Parameters) {
                data.Parameters.forEach(p => {
                    const newParam = {
                        Name: p.Name,
                        FromStatus: false,
                        Type: 'System.String'
                    };
                    if (typeof p.FromStatus === 'boolean' && p.FromStatus) {
                        newParam.FromStatus = true;
                        newParam['Value'] = p.Value;
                    } else if (p.Value) {
                        const val = JSON.parse(p.Value);
                        newParam['Value'] = val.Value;
                        if (val.Type) {
                            newParam.Type = val.Type;
                        }
                    }
                    this.Parameters.push({ Data: newParam });
                });
            }
            if (data.Code) {
                this.Code = data.Code;
            }
        }
    }

    fillResult(retVal: APythonSettingsData) {
        retVal.PythonType = this.PythonType;
        retVal.UsedModules = this.Modules;
        retVal.ReturnType = this.ReturnType;
        retVal.CollectData = this.CollectData;
        this.Parameters.forEach(p => {
            if (p.Data) {
                if (typeof p.Data.FromStatus === 'boolean' && p.Data.FromStatus) {
                    retVal.Parameters.push({
                        Name: p.Data.Name,
                        FromStatus: true,
                        Value: p.Data.Value
                    });
                } else if (p.Data.Type) {
                    retVal.Parameters.push({
                        Name: p.Data.Name,
                        FromStatus: false,
                        Value: JSON.stringify({
                            Value: p.Data.Value,
                            Type: p.Data.Type
                        })
                    });
                }
            }
        });
        retVal.Code = this.Code;
    }

    removeModule(i) {
        if (this.Modules) {
            this.Modules.splice(i, 1);
        }
    }

    addModule(event) {
        const val = (event.value || '').trim();
        if (val) {
            if (!this.Modules) {
                this.Modules = [];
            }
            this.Modules.push(val);
        }
        if (event.input) {
            event.input.value = '';
        }
    }

    columnSelectionChanged(ev) {
        if (ev && ev.options) {
            ev.options.forEach(option => {
                if (option.value) {
                    option.value.IsSelected = option.selected;
                }
            });
        }
    }

    addColumn() {
        if (!this.NewColumns) {
            this.NewColumns = [];
        }
        this.NewColumns.push({ Name: TranslateHelper.TranslatorInstance.instant(this.AddNewColCaption) });
    }

    deleteColumn(i) {
        if (this.NewColumns) {
            this.NewColumns.splice(i, 1);
        }
    }

    addParam() {
        if (!this.Parameters) {
            this.Parameters = [];
        }
        this.Parameters.push({
            Data: {
                Name: TranslateHelper.TranslatorInstance.instant('@@Neuer Parameter'),
                FromStatus: false,
                Type: 'System.String'
            }
        });
    }

    deleteParam(i) {
        if (this.Parameters) {
            this.Parameters.splice(i, 1);
        }
    }

    fullScreenChanged(fullScreen) {
        if (this.editor && this.editor._editor) {
            if (fullScreen) {
                this.editor._editor.layout();
            } else {
                this.editor._editor.layout({ width: 400, height: 300 });
            }
        }
    }
}

@Component({
    selector: 'wf-python-settings',
    templateUrl: './python.settings.html',
    styleUrls: ['./python.settings.css']
})
export class PythonSettings extends APythonSettings {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'pythonWFModule';
        reg.Caption = '@@Python Relational';
        reg.GroupID = 'aioperations';
        reg.Index = 150;
        reg.SettingsControl = PythonSettings;
        reg.SettingsTypeHelper = new PythonSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UsedColCaption = '@@Verwendete Spalten';
        this.NewColCaption = '@@Neue Spalten';
        this.AddNewColCaption = '@@Neue Spalte';
        this.DeleteColCaption = '@@Spalte loeschen';
    }

    initialize(data: any) {
        super.initialize(data);
        let usedColumns = [];
        if (data) {
            if (data.UsedColumns) {
                usedColumns = data.UsedColumns;
            }
            if (data.NewColumns) {
                data.NewColumns.forEach(nc => {
                    this.NewColumns.push({ Name: nc });
                });
            }
        }
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource && dataSource.Columns) {
            dataSource.Columns.forEach(col => {
                this.AllColumns.push({
                    Name: col.Name,
                    IsSelected: usedColumns.some(uc => col.Name === uc)
                });
            });
        }
    }

    getResult() {
        const retVal = new PythonSettingsData();
        super.fillResult(retVal);
        this.AllColumns.forEach(ac => {
            if (ac.IsSelected) {
                retVal.UsedColumns.push(ac.Name);
            }
        });
        this.NewColumns.forEach(nc => {
            retVal.NewColumns.push(nc.Name);
        });
        return retVal;
    }
}

export abstract class APythonSettingsData extends AServiceWorkflowData {
    PythonType = 0;
    UsedModules: string[] = [];
    ReturnType = 0;
    CollectData = false;
    Parameters = [];
    Code: string;
}

export class PythonSettingsData extends APythonSettingsData {    
    UsedColumns: string[] = [];
    NewColumns: string[] = [];    

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.Python.PythonSettingsData';
    }
}

export class PythonSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        if (settings) {
            if (settings.ReturnType === 1) {
                retVal.Type = 'relData';
            } else if (settings.ReturnType === 2) {
                retVal.Type = 'objData';
            }
        }
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new PythonSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && settings) {
                const newColumns = [];
                if (ds.Columns) {
                    settings.UsedColumns.forEach(gc => {
                        ds.Columns.some(col => {
                            if (col.Name === gc) {
                                newColumns.push(col);
                                return true;
                            }
                            return false;
                        });
                    });
                }
                settings.NewColumns.forEach(nc => {
                    newColumns.push({
                        // Hier hab ich momentan nur den Spaltennamen
                        Name: nc
                    });
                });
                ds.Columns = newColumns;
            }
        }
    }
}
