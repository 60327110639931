import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CacheService } from '../../cache/cache.service';
import { LayoutHelper } from '../../helpers/layout.helper';
import { MetaHelper } from '../../helpers/meta.helper';
import { ThemeHelper } from '../../helpers/theme.helpers';
import { VariableHelper } from '../../helpers/variable.helper';
import { EventActionType } from '../../models/enums/eventactiontype.enum';
import { ViewType } from '../../models/enums/viewtype.enum';
import { ElementProperty, EventData } from '../../models/layoutbase.model';
import { LayoutElement } from '../../models/layoutelement.model';
import { WorkflowStatus } from '../../models/workflow/workflow.model';
import { LanguageService } from '../../services/language.service';
import { LayoutService } from '../../services/layout.service';
import { WorkflowService } from '../../services/workflow.service';
import { WorkflowEngine } from '../../workflow/workflow.engine';

// @dynamic
@Directive()
export abstract class IBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    static Default;

    Type;
    // Source;
    MediaSourceValue;
    StyleRef: any;
    WorkflowStyles = [];
    EditableByWorkflow;
    RefreshCount = 0;
    Parent;
    //#region Initialized
    _Initialized = false;
    @Input()
    get Initialized(): boolean {
        return this._Initialized;
    }
    set Initialized(val: boolean) {
        if (val === true && !this._Initialized) {
            this.onInit();
        }
        this._Initialized = val;
        this.InitializedChange.emit(this._Initialized);
    }
    @Output() InitializedChange = new EventEmitter<boolean>();
    //#endregion
    //#region FromBaseLayout
    FromBaseLayoutValue = false;

    @Input()
    get FromBaseLayout() {
        return this.FromBaseLayoutValue;
    }
    set FromBaseLayout(val) {
        this.FromBaseLayoutValue = val;
        this.FromBaseLayoutChange.emit(this.FromBaseLayoutValue);
        this.cdRef.detectChanges();
    }

    @Output() FromBaseLayoutChange = new EventEmitter<any>();
    //#endregion
    //#region Editable
    @Input()
    get Editable(): boolean {
        if (typeof this.EditableByWorkflow === 'boolean') {
            return this.EditableByWorkflow;
        }
        return this.LayoutElement.Editable;
    }
    set Editable(val: boolean) {
        this.LayoutElement.Editable = val;
        this.EditableChange.emit(this.LayoutElement.Editable);
    }
    @Output() EditableChange = new EventEmitter<boolean>();
    //#endregion
    //#region Disabled
    _Disabled;
    @Input()
    get Disabled(): boolean {
        return this._Disabled;
    }
    set Disabled(val: boolean) {
        this._Disabled = val;
        this.DisabledChange.emit(this._Disabled);
    }
    @Output() DisabledChange = new EventEmitter<boolean>();
    //#endregion
    //#region Required
    get Required(): boolean {
        if (this.LayoutElement) {
            if (this.LayoutElement.IsRequired != null) {
                return this.LayoutElement.IsRequired;
            } else if (this.LayoutElement.Required != null) {
                return this.LayoutElement.Required;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    //#endregion
    //#region Error
    _Error: string;
    get Error(): string {
        return this._Error;
    }
    set Error(value) {
        this._Error = value;
    }
    //#endregion
    //#region Layout
    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.LayoutChange.emit(this.LayoutValue);
    }
    LayoutValue;

    @Output() LayoutChange = new EventEmitter<any>();
    //#endregion
    //#region LayoutElement
    LayoutElementValue: any = new LayoutElement();
    ExternalLayoutElement = false;
    LayoutElementBinding;
    @Output()
    public LayoutElementChange = new EventEmitter<any>();
    @Input()
    public get LayoutElement(): any {
        return this.LayoutElementValue;
    }
    public set LayoutElement(val: any) {
        this.ExternalLayoutElement = true;
        this.LayoutElementValue = val;
        this.LayoutElementValue['Element'] = this;
        if (this.LayoutElementValue.ElementSet) {
            this.LayoutElementValue.ElementSet.next();
        }
        if (this.LayoutElementValue.StyleRef) {
            this.StyleRef = {};
            this.StyleChanged();
        }
        if (this.LayoutElementValue.ComponentChanged) {
            const sub = this.Subscriptions['ComponentChanged'];
            if (sub) {
                sub.unsubscribe();
            }
            this.Subscriptions['ComponentChanged'] = this.LayoutElementValue.ComponentChanged.subscribe((x) => {
                this.StyleChanged();
            });
        }
        if (this.LayoutElementValue.ValuesChanged) {
            this.Subscriptions['ValuesChanged'] = this.LayoutElementValue.ValuesChanged.subscribe((x) => {
                if (this.LayoutElementValue && this.LayoutElementValue.MediaSource) {
                    CacheService.ReadMediaSourceSub(this.LayoutElementValue.MediaSource).subscribe((source) => {
                        this.MediaSourceValue = source;
                        this.StyleChanged();
                        this.onLayoutElementChangedInternal();
                        this.firePropertyChange(x);
                    });
                } else {
                    this.StyleChanged();
                    this.onLayoutElementChangedInternal();
                    this.firePropertyChange(x);
                }
            });
        }
        if (this.LayoutElementValue && this.LayoutElementValue.MediaSource) {
            CacheService.ReadMediaSourceSub(this.LayoutElementValue.MediaSource).subscribe((source) => {
                this.MediaSourceValue = source;
                this.RefreshCount = 1;
                this.LayoutElementValue.ValuesChanged.next();
                this.cdRef.detectChanges();
            });
        }
        if (!this.LayoutElementValue.ID) {
            this.LayoutElementValue.ID = UUID.UUID();
        }
        if (this.LayoutElementValue.Variables && VariableHelper.ActiveVariablesMap) {
            this.LayoutElementValue.Variables.forEach((item) => {
                if (VariableHelper.ActiveVariablesMap[this.LayoutElementValue._Id + '||' + item.ID]) {
                    item.Value = VariableHelper.ActiveVariablesMap[this.LayoutElementValue._Id + '||' + item.ID].Value;
                }
            });
        }
        this.fillEvents();

        this.StyleChanged();
        this.onLayoutElementSetInternal();
        this.LayoutElementChange.emit(this.LayoutElementValue);
        this.LayoutElementBinding = of(this.LayoutElementValue);
    }
    //#endregion
    //#region DataSource
    DataSource$;
    DataSourceValue;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (val != this.DataSourceValue) {
            this.DataSourceValue = val;
            this.DataSource$ = of(val);
            this.DataSourceChange.emit(this.DataSourceValue);
            this.cdRef.detectChanges();
        }
        this.triggerEvent('DataSourceChanged', this.DataSourceValue);
    }
    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    //#region Events
    EventList: string[] = [];
    //#endregion
    //#region ElementProperties
    PropertyList: ElementProperty[] = [];
    //#endregion
    //#region Variables
    Variables = {};
    //#endregion

    AppBuilderPanel = null;

    @Output() OnControlInitialized = new EventEmitter<any>();
    @Output() StyleChange = new EventEmitter<any>();

    Subscriptions = {};
    AppBuilder;

    ActiveTheme = ThemeHelper.AcitveTheme;
    public AfterViewInit: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public OnInit: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public OnDestroy: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    constructor(public cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        this.EventList.push('OnInit');
        this.EventList.push('OnDestroy');
        this.EventList.push('MouseEnter');
        this.EventList.push('MouseLeave');
        this.EventList.push('MouseClick');
        this.EventList.push('DataSourceChanged');
    }
    onInit() {        
        this.Subscriptions['Overlay'] = LayoutService.OverlayStatus.subscribe((overlay) => {
            this.AppBuilder = overlay;
        });
        this.Subscriptions['ActiveThemeChanged'] = ThemeHelper.ActiveThemeChanged.subscribe((overlay) => {
            this.ActiveTheme = ThemeHelper.AcitveTheme;
            this.cdRef.detectChanges();
        });
        LanguageService.SelectedLanguage.subscribe(() => {
            this.cdRef.detectChanges();
        });
        
        this.ControlInitialized();
        if (this.LayoutElementValue.Elements) {
            let count = this.LayoutElementValue.Elements.length;
            if (count > 0) {
                this.LayoutElementValue.Elements.forEach(x => {
                    const init = x.Initialized.getValue();
                    if (init === true) {
                        count--;
                        if (count === 0) {
                            this.setInitialized();
                        }
                    } else {
                        const sub = x.Initialized.subscribe(y => {
                            if (y === true) {
                                sub.unsubscribe();
                                count--;
                                if (count === 0) {
                                    this.setInitialized();
                                }
                            }
                        });
                    }
                });
                return;
            }
        }
        this.setInitialized();
    }

    setInitialized() {
        this.OnControlInitialized.emit();
        this.triggerEvent('OnInit');
        if (this.LayoutElement && this.LayoutElement.Initialized) {
            this.LayoutElementValue.Initialized.next(true);
        }
    }

    abstract ControlInitialized();
    onLayoutElementSetInternal() {
        this.onLayoutElementSet();
        if (this.LayoutElement.Elements && this.LayoutElement.Elements.length > 1) {
            this.LayoutElementValue.Elements.forEach((element) => {
                element.Parent = this.LayoutElementValue;
            })
        }
        this.cdRef.detectChanges();
        this.RefreshCount += 1;
    }
    onLayoutElementSet() {
    }
    onLayoutElementChangedInternal() {
        this.onLayoutElementChanged();
        if (this.LayoutElement && this.LayoutElement.Parent && this.LayoutElement.Parent.Element && this.LayoutElement.Parent.Element.RefreshView) {
            this.LayoutElement.Parent.Element.RefreshView();
        }
        this.cdRef.detectChanges();
        this.RefreshCount += 1;
    }
    onLayoutElementChanged() {
    }
    RefreshView() {
        this.cdRef.detectChanges();
        this.RefreshCount += 1;
    }
    //#region Events
    fillEvents() {
        const that = this;
        const le = this.LayoutElement;
        if (le && le.Events) {
            this.EventList.forEach((ev) => {
                let found = le.Events.find((value) => value?.EventID === ev);
                if (!found) {
                    if (le.CustomEvents && le.ElementType === 'template') {
                        found = le.CustomEvents.find((lev) => lev.Name === ev);
                        if (found) {
                            const evData = new EventData();
                            evData.EventID = ev;
                            le.Events.push(evData);
                        }
                    } else {
                        const evData = new EventData();
                        evData.EventID = ev;
                        le.Events.push(evData);
                    }
                } else if (found.Handlers && found.Handlers.length > 0) {
                    found.Handlers.forEach((item) => {
                        if (item.ActionType == EventActionType.Variable) {
                            if (!VariableHelper.VariableSubscriptions) {
                                VariableHelper.VariableSubscriptions = {};
                            }
                            if (VariableHelper.VariableSubscriptions[that.Layout._Id + '||' + item.Action]) {
                                VariableHelper.VariableSubscriptions[that.Layout._Id + '||' + item.Action].unsubscribe();
                            }
                            VariableHelper.VariableSubscriptions[that.Layout._Id + '||' + item.Action] = new Subject<any>();
                            VariableHelper.VariableSubscriptions[that.Layout._Id + '||' + item.Action].subscribe((value) => {
                                that.DataSource = value;
                            });
                        }
                    });
                }
            });
        }
    }

    triggerEvent(event: string, eventData?: any) {
        if (event) {
            const isInEditMode = LayoutService.ViewType.getValue() === ViewType.Edit;
            if (isInEditMode) {
                const doTrigger = LayoutService.TriggerEventsInEditMode.getValue();
                if (!doTrigger) {
                    return;
                }
            }
            const le = this.LayoutElement;
            if (le && le.Events) {
                let actionList;
                le.Events.some((lev) => {
                    if (lev?.EventID === event) {
                        actionList = lev.Handlers;
                        return true;
                    }
                    return false;
                });
                if (actionList) {
                    actionList.forEach((action) => {
                        switch (action.ActionType) {
                            case EventActionType.Workflow:
                                const layout = this.LayoutValue;

                                let parentPath;
                                if (layout === le) {
                                    parentPath = [layout];
                                } else {
                                    parentPath = MetaHelper.FindParentPath(layout, le);
                                }
                                if (!parentPath && this.LayoutValue.ElementType == 'template') {
                                    parentPath = [LayoutHelper.GetActiveResolution(LayoutService.SelectedLayout.getValue())];
                                }
                                if (parentPath) {
                                    for (let i = parentPath.length - 1; i >= 0; i--) {
                                        const parent = parentPath[i];
                                        if (parent.Workflows) {
                                            const wf = parent.Workflows.find(item => item.ID === action.Action && item.Data);
                                            if (wf) {
                                                const status = new WorkflowStatus(wf.ID, wf.Caption);
                                                WorkflowStatus.CopyContext(WorkflowService.DialogStatusCopy.getValue(), status.Context);
                                                status.WorkflowLayoutService.init(parent, le);
                                                status.ActualParameter = eventData;
                                                const engine = new WorkflowEngine(wf.Data, status);
                                                engine.startExecution();
                                                break;
                                            }
                                        }
                                    }
                                }
                                break;
                            case EventActionType.Variable:
                                const av = VariableHelper.AvailableVariables[this.Layout._Id];
                                if (av) {
                                    const variable = av.find((value) => value.ID == action.Action);
                                    if (variable) {
                                        if (action.Write) {
                                            variable.Value = this.DataSource;
                                            VariableHelper.ValueChanged(this.Layout, variable);
                                        } else {
                                            this.DataSource = variable.Value;
                                        }
                                    }
                                }
                                break;
                        }
                    });
                }
            }
        }
    }
    //#endregion
    //#region Style
    onWorkflowStylesChanged() {
        let editable;
        let containsVisibility = false;
        [...this.WorkflowStyles].reverse().some(x => {
            if (typeof x.Visible === 'boolean') {
                if (x.Visible) {
                    containsVisibility = true;
                }
            }
            if (typeof x.Editable === 'boolean') {
                editable = x.Editable;
                return true;
            }
            return false;
        });

        if (containsVisibility) {
            const elements = [];
            this.findTable(this.LayoutElement.Elements, elements);
            if (elements.length > 0) {
                elements.forEach((element) => {
                    if (element.Element) {
                        element.Element.Rerender();
                    }
                });
            }
        }

        this.EditableByWorkflow = editable;
        this.RefreshCount += 1;
        this.cdRef.detectChanges();
        this.LayoutElement.WorkflowStyleChanged.next();
    }
    findTable(elements, result) {
        elements.forEach(element => {
            if (element.ElementType == 'datatable') {
                result.push(element);
            }
            if (element.Elements) {
                this.findTable(element.Elements, result);
            }
        });
    }

    StyleChanged() {
        if (this.LayoutElementValue.StyleRef && typeof (this.LayoutElementValue.StyleRef) == 'string') {
            this.StyleRef = {};
            CacheService.ReadStyleSub(this.LayoutElementValue.StyleRef).subscribe(result => {
                if (result && result['StyleJSON']) {
                    const style = JSON.parse(result['StyleJSON']);
                    Object.keys(style).forEach(s => {
                        this.StyleRef[s] = style[s];
                    });
                    this.Initialized = true;
                    this.RefreshCount += 1;
                    this.cdRef.detectChanges();
                    this.StyleChange.emit(this.LayoutElement);
                }
            });
        } else {
            this.StyleRef = null;
            this.Initialized = true;
            this.RefreshCount += 1;
            this.cdRef.detectChanges();
            this.StyleChange.emit(this.LayoutElement);
        }
    }
    //#endregion

    HandleResize(itemComponent) {
        if (this.LayoutElement) {
            if (itemComponent.left) {
                this.LayoutElement.X = itemComponent.left;
            }
            if (itemComponent.top) {
                this.LayoutElement.Y = itemComponent.top;
            }
            if (itemComponent.height) {
                this.LayoutElement.Height = itemComponent.height;
            }
            if (itemComponent.width) {
                this.LayoutElement.Width = itemComponent.width;
            }
        }
    }
    //#region Lifecycle
    ngAfterViewInit(): void {
        this.AfterViewInit.next(true);
    }

    ngOnInit(): void {
        this.OnInit.next(true);
    }

    SetProperties() {
        return  new Observable((subscriber) => {
            if (this.data) {
                this.Layout = this.data.Layout;
                this.Disabled = this.data.Disabled;
                this.DataSource = this.data.DataSource;
                this.LayoutElement = this.data.LayoutElement;
                this.Parent = this.data.Parent;
                this.FromBaseLayout = this.data.FromBaseLayout;
            }
            subscriber.next(true);
        });
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            if (this.Subscriptions[key].unsubscribe) {
                this.Subscriptions[key].unsubscribe();
            }
        });
        this.OnDestroy.next(true);
        this.triggerEvent('OnDestroy');
    }
    //#endregion

    OnDataBindingChanged() {
        this.LayoutElement.DataBindingChanged.next(null);
    }
    GetUserSpecificValue() {
        return this.DataSourceValue;
    }
    SetUserSpecificValue(val) {
        this.DataSourceValue = val;
    }

    firePropertyChange(prop) {
        if (typeof prop === 'string') {
            LayoutService.OnLayoutPropertyChanged(this.LayoutElementValue.ID, prop, this.LayoutElementValue[prop]);
        } else if (Array.isArray(prop) && prop.length > 0) {
            const multiChange = [];
            prop.forEach(y => {
                multiChange.push({
                    PropertyName: y,
                    Value: this.LayoutElementValue[y]
                });
            });
            LayoutService.OnMultiLayoutPropertyChanged([{
                ElementIDs: [this.LayoutElementValue.ID],
                Properties: multiChange
            }]);
        }
    }

    handleIntersection(visible: boolean) {
    }
}
