import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { RequestColumn } from '../../../models/rest/requestcolumn';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataPreviewService } from '../../../services/data.preview.service';

@Component({
    selector: 'container-businessmodel-elements-dialog',
    templateUrl: './container.businessmodel.elements.dialog.html'
})
export class ContainerBusinessModelElementsDialog implements OnInit {
    InitArgs;
    AllRows = [];
    Ranges = [];
    Loading = true;

    rangeCtrl = new UntypedFormControl();
    FilteredChipRows: Observable<string[]>;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    @ViewChild('rangeInput') rangeInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    static ShowDialog(model, line, keyCol, nameCol) {
        return BaseDialog.ShowDialogAsync({
            InitArgs: {
                Model: model,
                Line: line,
                KeyCol: keyCol,
                NameCol: nameCol
            },
            ContentType: ContainerBusinessModelElementsDialog,
            Buttons: DialogButton.OkCancel,
            Title: '@@Zeile bearbeiten',
            Width: 600
        });
    }

    constructor(private preview: DataPreviewService) {
        this.FilteredChipRows = this.rangeCtrl.valueChanges.pipe(startWith(null), map((value: string | null) => {
            const retVal = [];
            if (value && value.length > 1) {
                const filterValue = value.toLowerCase();
                this.AllRows.forEach(x => {
                    if (x.SearchName.indexOf(filterValue) === 0) {
                        retVal.push(x.Key);
                    }
                });
            }
            return retVal;
        }));
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.Ranges.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.rangeCtrl.setValue(null);
    }

    remove(index: number): void {
        this.Ranges.splice(index, 1);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.Ranges.push(event.option.viewValue);
        this.rangeInput.nativeElement.value = '';
        this.rangeCtrl.setValue(null);
    }

    ngOnInit(): void {
        if (this.InitArgs && this.InitArgs.Model && this.InitArgs.Line) {
            if (this.InitArgs.Line.KeyRanges) {
                this.Ranges = [...this.InitArgs.Line.KeyRanges];
            }
            const filter = RequestOptions.CleanRequestOptions();
            const keyCol = new RequestColumn();
            keyCol.Name = this.InitArgs.KeyCol;
            filter.Columns.push(keyCol);
            const nameCol = new RequestColumn();
            nameCol.Name = this.InitArgs.NameCol;
            filter.Columns.push(nameCol);
            this.preview.ExecuteQuery(this.InitArgs.Model.BaseTable, filter).subscribe(rows => {
                if (rows) {                    
                    const lineIncl = {};
                    if (this.InitArgs.Line.IncludeKeys) {
                        this.InitArgs.Line.IncludeKeys.forEach(ik => {
                            lineIncl[ik] = true;
                        });
                    }
                    const lineExcl = {};
                    if (this.InitArgs.Line.ExcludeKeys) {
                        this.InitArgs.Line.ExcludeKeys.forEach(ek => {
                            lineExcl[ek] = true;
                        });
                    }
                    const allRows = [];
                    rows.forEach(row => {
                        const key = row[this.InitArgs.KeyCol];
                        const name = key + " " + row[this.InitArgs.NameCol];
                        const rowItem = {
                            Key: key,
                            Name: name,
                            SearchName: name.toLowerCase(),
                            Included: false
                        }
                        if (lineExcl[key] === true) {
                            rowItem.Included = null;
                        } else if (lineIncl[key] === true) {
                            rowItem.Included = true;
                        }
                        allRows.push(rowItem);
                    });
                    this.AllRows = allRows;
                }
                this.Loading = false;
            });
        }
    }

    Initialize(args) {
        if (args) {
            this.InitArgs = args;
        }
    }

    OnDialogButtonClickAction(button: DialogButton) {
        if (button === DialogButton.Ok) {
            if (this.InitArgs.Line) {
                const include = [];
                const exclude = [];
                this.AllRows.forEach(x => {
                    if (x.Included == null) {
                        exclude.push(x.Key);
                    } else if (x.Included) {
                        include.push(x.Key);
                    }
                });
                this.InitArgs.Line.IncludeKeys = include;
                this.InitArgs.Line.ExcludeKeys = exclude;
                this.InitArgs.Line.KeyRanges = this.Ranges;
            }
        }
        return true;
    }
}
