import { RequestBase } from "./request-base";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class LanguageService extends RequestBase {

    static Languages: BehaviorSubject<any[]> = new BehaviorSubject(null);
    public static SelectedLanguage: BehaviorSubject<any> = new BehaviorSubject(null);
    public static LanguageChanged: Subject<any> = new Subject();
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getLanguages(): Observable<any> {
        return this.http.get(this.API_BASE_URL + 'config/api/config/GetSelectedLanguages', this.options);
    }

}