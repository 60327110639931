import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export class ToUpper extends AFormula {
    static StaticID: string = 'bc194b0e-4d43-403e-9cce-073128cd8f5b';

    ID(): string {
        return ToUpper.StaticID;
    }

    Name(): string {
        return 'ToUpper';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Zeichenkette alles gross schreiben');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1000;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Text') + '; ' + TranslateHelper.TranslatorInstance.instant('@@CodeDescription') + ')';
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        var text = '';
        if (args && args.length > 0) {
            if (typeof args[0].Value === 'string' && args[0].Value != null) {
                text = args[0].Value;
            }
        }
        retVal.Value = text.toUpperCase();
        return retVal;
    }
}