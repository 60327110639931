import { PropertyMapper } from '../../../helpers/property.mapper';
import { TranslatedString } from '../../../models/translatedstring.model';

export class RestItem {
    public SID: string;
    public Caption: TranslatedString;
    public DefaultPayloadPath: string;
    //public URL = '';
    //public Type: AuthType;
    //public OAuth2Settings: OAuth2Settings;
    //public BasicAuthSettings: BasicAuthSettings;
    //public BearerTokenAuthSettings: BearerTokenAuthSettings;

    //public get TypeMap(): AuthType {
    //    return this.Type;
    //}
    //public set TypeMap(value: AuthType) {
    //    switch (this.Type) {
    //        case AuthType.OAuth2_0:
    //            delete this.OAuth2Settings;
    //            break;
    //        case AuthType.Basic:
    //            delete this.BasicAuthSettings;
    //            break;
    //        case AuthType.BearerToken:
    //            delete this.BearerTokenAuthSettings;
    //            break;
    //    }
    //    switch (value) {
    //        case AuthType.OAuth2_0:
    //            this.OAuth2Settings = new OAuth2Settings();
    //            break;
    //        case AuthType.Basic:
    //            this.BasicAuthSettings = new BasicAuthSettings();
    //            break;
    //        case AuthType.BearerToken:
    //            this.BearerTokenAuthSettings = new BearerTokenAuthSettings();
    //            break;
    //        default:
    //            delete this.BearerTokenAuthSettings;
    //            delete this.BasicAuthSettings;
    //            delete this.OAuth2Settings;
    //    }
    //    this.Type = value;
    //}

    constructor(value: RestItem = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);
            //if (value.OAuth2Settings) {
            //    this.OAuth2Settings = new OAuth2Settings(value.OAuth2Settings);
            //} else if (value.BasicAuthSettings) {
            //    this.BasicAuthSettings = new BasicAuthSettings(value.BasicAuthSettings);
            //} else if (value.BearerTokenAuthSettings) {
            //    this.BearerTokenAuthSettings = new BearerTokenAuthSettings(value.BearerTokenAuthSettings);
            //}
        }
    }
}