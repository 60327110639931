import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { LayoutService } from '../../../services/layout.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'databinding-menu-tab',
    templateUrl: './databinding.menu.tab.html',
    styleUrls: ['./databinding.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataBindingMenuTab extends APropertyTab {

    get DataSource() {
        if (this.TableProperties && this.TableProperties.Fields) {
            return this.TableProperties.Fields.find(x => x.ID === this.SelectedItemValue.DataSource);
        }
        return null;
    }
    _DataSource;
    set DataSource(val) {
        this._DataSource = val;
        
    }
    DataSourceChanged() {
        const changedProps = [];
        let val = this._DataSource;
        if (val) {
            if (this.SelectedItemValue.DataSource != val.ID) {
                this.SelectedItemValue.DataSource = val.ID;
                changedProps.push('DataSource');
            }
            const dsID = val.AdvancedType !== '00000000-0000-0000-0000-000000000000' ? val.AdvancedType : val.Type;
            if (this.SelectedItemValue.DataSourceID != dsID) {
                this.SelectedItemValue.DataSourceID = dsID;
                changedProps.push('DataSourceID');
            }
            if (this.SelectedItemValue.DataSourceType != val.Type) {
                this.SelectedItemValue.DataSourceType = val.Type;
                changedProps.push('DataSourceType');
            }
            if (val.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') {
                if (this.SelectedItemValue.IsEnum != true) {
                    this.SelectedItemValue.IsEnum = true;
                    changedProps.push('IsEnum');
                }
            } else if (this.SelectedItemValue.IsEnum != null) {
                delete this.SelectedItemValue.IsEnum;
                changedProps.push('IsEnum');
            }
            if (val.IsList) {
                if (this.SelectedItemValue.Multiple != true) {
                    this.SelectedItemValue.Multiple = true;
                    changedProps.push('Multiple');
                }
            } else if (this.SelectedItemValue.Multiple != false) {
                this.SelectedItemValue.Multiple = false;
                changedProps.push('Multiple');
            }
            if (val.IsShared && !val.IsReverse) {
                if (!this.SelectedItemValue.ValueMemberPath) {
                    if (val.KeyColumns && val.KeyColumns.length > 0) {
                        if (val.KeyColumns.length === 1) {
                            this.SelectedItemValue.ValueMemberPath = val.KeyColumns[0];
                            changedProps.push('ValueMemberPath');
                        } else {
                            this.SelectedItemValue.ValueMemberPath = null;
                            changedProps.push('ValueMemberPath');
                        }
                    } else {
                        this.SelectedItemValue.ValueMemberPath = '_Id';
                        changedProps.push('ValueMemberPath');
                    }
                }
            } else if (this.SelectedItemValue.ValueMemberPath != null) {
                this.SelectedItemValue.ValueMemberPath = null;
                changedProps.push('ValueMemberPath');
            }
            if (val.IsMandatory || !val.IsNullable) {
                if (this.SelectedItemValue.IsRequired != true) {
                    this.SelectedItemValue.IsRequired = true;
                    changedProps.push('IsRequired');
                }
            } else if (this.SelectedItemValue.IsRequired != null) {
                delete this.SelectedItemValue.IsRequired;
                changedProps.push('IsRequired');
            }
            if (this.SelectedItemValue.IsReverse != val.IsReverse) {
                this.SelectedItemValue.IsReverse = val.IsReverse;
                changedProps.push('IsReverse');
            }
            if (this.SelectedItemValue.IsShared != val.IsShared) {
                this.SelectedItemValue.IsShared = val.IsShared;
                changedProps.push('IsShared');
            }
        } else {
            if (this.SelectedItemValue.DataSource != null) {
                this.SelectedItemValue.DataSource = null;
                changedProps.push('DataSource');
            }
            if (this.SelectedItemValue.DataSourceID != null) {
                this.SelectedItemValue.DataSourceID = null;
                changedProps.push('DataSourceID');
            }
            if (this.SelectedItemValue.DataSourceType != null) {
                this.SelectedItemValue.DataSourceType = null;
                changedProps.push('DataSourceType');
            }
            if (this.SelectedItemValue.IsEnum != null) {
                delete this.SelectedItemValue.IsEnum;
                changedProps.push('IsEnum');
            }
            if (this.SelectedItemValue.Multiple != false) {
                this.SelectedItemValue.Multiple = false;
                changedProps.push('Multiple');
            }
            if (this.SelectedItemValue.ValueMemberPath != null) {
                this.SelectedItemValue.ValueMemberPath = null;
                changedProps.push('ValueMemberPath');
            }
            if (this.SelectedItemValue.IsRequired != null) {
                delete this.SelectedItemValue.IsRequired;
                changedProps.push('IsRequired');
            }
            if (this.SelectedItemValue.IsReverse != false) {
                this.SelectedItemValue.IsReverse = false;
                changedProps.push('IsReverse');
            }
            if (this.SelectedItemValue.IsShared != false) {
                this.SelectedItemValue.IsShared = false;
                changedProps.push('IsShared');
            }
        }
        if (changedProps.length > 0) {
            this.onStyleChanged(changedProps);
        }
        if (this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.OnDataBindingChanged === 'function') {
            this.SelectedItemValue.Element.OnDataBindingChanged();
        }
        LayoutService.LayoutChanged.next(null);
    }
    TableProperties;
    Layout;

    static CheckOnChange(menuItem) {
        menuItem.Visible = false;
        const selected = LayoutService.SelectedItem.getValue();
        if (selected) {
            const layout = LayoutHelper.GetLayout();
            const resolution = LayoutHelper.GetActiveResolution(layout);
            DataBindingMenuTab.GetTableProperties(resolution, selected).then((x) => {
                if (x) {
                    menuItem.Visible = true;
                    LayoutHelper.RefreshMenuTabs.next(null);
                }
            });
        }
    }

    private static GetTableProperties(layout, selected) {
        const promise = new Promise((resolve, reject) => {
            if (layout && selected && layout !== selected) {
                MetaHelper.FilterTableProperties(layout, selected).then((result) => {
                    if (result && result.Fields && result.Fields.length > 0) {
                        if (selected['DataModelID'] && selected['DataModelID'] !== '00000000-0000-0000-0000-000000000000' &&
                            selected['DataSource'] === result.SID) {
                            resolve(null);
                        } else {
                            resolve(result);
                        }
                    } else {
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
            this.ReadTableProperties();
        }));
    }

    onItemSet() {
        this.ReadTableProperties();
    }

    ReadTableProperties() {
        this.TableProperties = null;
        const layout = this.Layout;
        const resolution = LayoutHelper.GetActiveResolution(layout);
        DataBindingMenuTab.GetTableProperties(resolution, this.SelectedItemValue).then((x) => {
            this.TableProperties = x;
            this.cdRef.detectChanges();
        });
    }
}
