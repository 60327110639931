import { DataCheck, WorkflowDialogContent } from '../../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowModuleSettingsHelper, WorkflowExitInfo, WorkflowRegistry } from '../../../../services/workflow.service';
import { CacheService } from '../../../../cache/cache.service';

export class AddToRedisSettingsData extends AServiceWorkflowData {
    Key = '';
    KeepActualParam = true;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Redis.AddToRedisSettingsData';
    }
}

export class AddToRedisSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new AddToRedisSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class AddToRedisModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualParameter) {
                CacheService.WriteToWorkflowCache(status.ActualSettings.Key, status.ActualParameter);
                if (!status.ActualSettings.KeepActualParam) {
                    status.ActualParameter = null;
                }
                return 0;
            } else {
                status.Logger.logError('Add to cache modul: No data found.');
            }
        } else {
            status.Logger.logError('Add to cache modul: No settings found.');
        }
        return super.execute(status);
    }
}

export abstract class ARedisSettings extends WorkflowDialogContent {
    static ForbiddenRedisKeys = ['UserInfo_'];
    ActualStatusKey = '';
    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.WFEditOptions && this.WFEditOptions.IsService &&
            ARedisSettings.ForbiddenRedisKeys.some(x => this.ActualStatusKey.startsWith(x))) {
            retVal.Error = '@@Forbidden Key';
            retVal.IsCorrect = false;
        }
        return retVal;
    }
}

@Component({
    selector: 'wf-addtoredis-settings',
    templateUrl: '../../wfStatus/setWFStatus.settings.html',
    styleUrls: ['../../wfStatus/setWFStatus.settings.css']
})
export class AddToRedisSettings extends ARedisSettings {
    KeepParam = true;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'addToRedisWFModule';
        reg.Caption = '@@In den Cache schreiben';
        reg.GroupID = 'wfStatus';
        reg.Index = 30;
        reg.SettingsControl = AddToRedisSettings;
        reg.SettingsTypeHelper = new AddToRedisSettingsDataHelper();
        reg.Executer = AddToRedisModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data && data.Key) {
            if (data.Key) {
                this.ActualStatusKey = data.Key;
            }
            if (typeof data.KeepActualParam === 'boolean') {
                this.KeepParam = data.KeepActualParam;
            }
        }
    }

    getResult(): any {
        const retVal = new AddToRedisSettingsData();
        retVal.Key = this.ActualStatusKey;
        retVal.KeepActualParam = this.KeepParam;
        return retVal;
    }
}
