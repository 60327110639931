import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ToastUiImageEditorComponent } from "ngx-tui-image-editor"

const includeUIOptions = {
    includeUI: {
        initMenu: "filter"
    }
};
const editorDefaultOptions = {
    cssMaxWidth: 700,
    cssMaxHeight: 500
};

//interface IImageEditor extends ImageEditor {
//    off(eventName: string): void;
//}

@Component({
    selector: "evidanza-image-editor",
    templateUrl: 'imageeditor.control.html',
    styleUrls: ['imageeditor.control.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageEditorControl implements AfterViewInit, OnDestroy {

    @ViewChild(ToastUiImageEditorComponent) editorComponent: ToastUiImageEditorComponent;



    @Input() includeUI = true;
    @Input() options;
    editorInstance;
    @Output() InstanceCreated = new EventEmitter<any>();
    //@Output() addText = new EventEmitter<IAddTextEvent>();
    //@Output() mousedown = new EventEmitter<IMousedownEvent>();
    //@Output() objectActivated = new EventEmitter<IGraphicObjectProps>();
    //@Output() objectMoved = new EventEmitter<IGraphicObjectProps>();
    //@Output() objectScaled = new EventEmitter<IGraphicObjectProps>();
    //@Output() redoStackChanged = new EventEmitter<number>();
    //@Output() textEditing = new EventEmitter<void>();
    //@Output() undoStackChanged = new EventEmitter<number>();
    @Output() Save = new EventEmitter<number>();
    @Output() Cancel = new EventEmitter<number>();

    @ViewChild("imageEditor") editorRef: ElementRef;

    ngAfterViewInit() {
        this.editorInstance = this.editorComponent.editorInstance;
        let options = editorDefaultOptions;
        if (this.includeUI) {
            options = Object.assign(includeUIOptions, this.options);
        }
        //this.editorInstance = new ImageEditor(
        //    this.editorRef.nativeElement,
        //    options
        //);
        const that = this;
        this.editorInstance.loadImageFromURL = (function () {
            const cached_function = that.editorInstance.loadImageFromURL;
            function waitUntilImageEditorIsUnlocked(imageEditor) {
                return new Promise((resolve, reject) => {
                    const interval = setInterval(() => {
                        if (!imageEditor._invoker._isLocked) {
                            clearInterval(interval);
                            resolve(true);
                        }
                    }, 100);
                })
            }
            return function () {
                const args = arguments;
                return waitUntilImageEditorIsUnlocked(that.editorInstance).then(() => { return cached_function.apply(this, args) });
            };
        })();


        //window.addEventListener("resize", function () {
        //    that.editorInstance['ui'].resizeEditor(null)
        //})
        //this.editorRef.nativeElement.onmousewheel=((e) => {
        //    var imageOriginalSize = {
        //        width: this.editorInstance['_graphics'].canvasImage.width,
        //        height: this.editorInstance['_graphics'].canvasImage.height
        //    };
        //    var wDelta = e.wheelDelta || e.deltaY;
        //    var imageEditorWindow = e.currentTarget;
        //    var scrollContainer = $('.tui-image-editor-wrap');
        //    var initWidth = imageEditorWindow.style.width;
        //    var initHeight = imageEditorWindow.style.height;
        //    var scrollContainerInitial = {
        //        top: scrollContainer.scrollTop(),
        //        left: scrollContainer.scrollLeft(),
        //        height: scrollContainer[0].scrollHeight,
        //        width: scrollContainer[0].scrollWidth
        //    };
        //    var mousePosition = {
        //        top: e.clientY - $(imageEditorWindow).offset().top,
        //        left: e.clientX - $(imageEditorWindow).offset().left
        //    };
        //    var newWidth;
        //    var newHeight;
        //    var offsetY;
        //    var offsetX;
        //    // Zoom step 10%
        //    if (wDelta > 0) {
        //        newWidth = parseInt(initWidth, 10) * 1.1;
        //        newHeight = parseInt(initHeight, 10) * 1.1;
        //        // Limit maximum zoom by image resolution
        //        if (newWidth > imageOriginalSize.width || newHeight > imageOriginalSize.height) {
        //            newWidth = imageOriginalSize.width;
        //            newHeight = imageOriginalSize.height;
        //        }
        //    } else {
        //        newWidth = parseInt(initWidth, 10) * 0.9;
        //        newHeight = parseInt(initHeight, 10) * 0.9;
        //        // Limit minimum zoom by 0.5 of original container size
        //        if (parseInt(imageEditorWindow.dataset.minWidth, 10) * 0.5 > parseInt(newWidth, 10)) {
        //            newWidth = parseInt(imageEditorWindow.dataset.minWidth, 10) * 0.5;
        //            newHeight = parseInt(imageEditorWindow.dataset.minHeight, 10) * 0.5;
        //        }
        //    }
        //    scrollContainer[0].style.width = newWidth + 'px';
        //    scrollContainer[0].style.height = newHeight + 'px';
        //    $(imageEditorWindow).find('canvas, .tui-image-editor-canvas-container')
        //        .css('max-width', imageEditorWindow.style.width)
        //        .css('max-height', imageEditorWindow.style.height);

        //    // Save initial size of container
        //    if (imageEditorWindow.dataset.minHeight === undefined) {
        //        imageEditorWindow.dataset.minHeight = initHeight;
        //        imageEditorWindow.dataset.minWidth = initWidth;
        //    }

        //    // Calculate scroll offset for new position
        //    offsetY = (scrollContainer[0].scrollHeight - scrollContainerInitial.height)
        //        * (mousePosition.top / scrollContainerInitial.height);
        //    offsetX = (scrollContainer[0].scrollWidth - scrollContainerInitial.width)
        //        * (mousePosition.left / scrollContainerInitial.width);

        //    scrollContainer.scrollTop(scrollContainerInitial.top + offsetY);
        //    scrollContainer.scrollLeft(scrollContainerInitial.left + offsetX);

        //    e.preventDefault();
        //    e.stopPropagation();
        //});
        //// Prevent scroll with wheel
        //$('.tui-image-editor-wrap')[0].onwheel = function () { return false; };
        //// Prevent overlapping from toolbar
        //$('.tui-image-editor-wrap').css('height', 'calc(100% - 150px)');
        this.InstanceCreated.emit(this.editorInstance);
        this.addEventListeners();
    }
    ngOnDestroy() {
        this.removeEventListeners();
        //this.editorInstance.destroy();
    }

    private addEventListeners() {
        //this.editorInstance.on(editorEvents.addText, event =>
        //    this.addText.emit(event)
        //);
        //this.editorInstance.on(editorEvents.mousedown, (event, originPointer) =>
        //    this.mousedown.emit({ event, originPointer })
        //);
        //this.editorInstance.on(editorEvents.objectActivated, event =>
        //    this.objectActivated.emit(event)
        //);
        //this.editorInstance.on(editorEvents.objectMoved, event =>
        //    this.objectMoved.emit(event)
        //);
        //this.editorInstance.on(editorEvents.objectScaled, event =>
        //    this.objectScaled.emit(event)
        //);
        //this.editorInstance.on(editorEvents.redoStackChanged, event =>
        //    this.redoStackChanged.emit(event)
        //);
        //this.editorInstance.on(editorEvents.textEditing, () =>
        //    this.textEditing.emit()
        //);
        //this.editorInstance.on(editorEvents.undoStackChanged, event =>
        //    this.undoStackChanged.emit(event)
        //);
    }

    private removeEventListeners() {
        //(<IImageEditor>this.editorInstance).off(editorEvents.addText);
        //(<IImageEditor>this.editorInstance).off(editorEvents.mousedown);
        //(<IImageEditor>this.editorInstance).off(editorEvents.objectActivated);
        //(<IImageEditor>this.editorInstance).off(editorEvents.objectMoved);
        //(<IImageEditor>this.editorInstance).off(editorEvents.objectScaled);
        //(<IImageEditor>this.editorInstance).off(editorEvents.redoStackChanged);
        //(<IImageEditor>this.editorInstance).off(editorEvents.textEditing);
        //(<IImageEditor>this.editorInstance).off(editorEvents.undoStackChanged);
    }

    cancel() {
        this.Cancel.emit();
    }
    save() {
        this.Save.emit();
    }
}
