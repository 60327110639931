import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class ToObject extends AFormula {
    static StaticID = '062c9d5b-ca2a-4351-9291-364f7cdb5f9c';

    ID(): string {
        return ToObject.StaticID;
    }

    Name(): string {
        return 'ToObject';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Objekt aus JSON-String generieren');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1006;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Text') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && args.length > 0) {
            const first = args[0];
            if (first.Type === ValueType.String) {
                const val = first.Value;
                if (typeof val !== 'undefined' && val !== null) {
                    retVal.Type = ValueType.Object;
                    retVal.Value = JSON.parse(val);
                }
            }
        }
        return retVal;
    }
}
