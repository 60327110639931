import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'rolapdb-panel',
    templateUrl: './rolapdb.panel.html',
    styleUrls: ['./rolapdb.panel.css']
})
export class RolapDBPanel extends BaseDataSourcePanel {
    static GetInfo(): DBPanelInfo {
        return {
            ControlType: RolapDBPanel,
            DBInfoType: RDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = RDBInfo;
    }
}

export class RDBInfo extends ADBInfo {
    MaximumLines = -1;
    TimeOut = 60000; // 1 min = 60000 ms

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Rolap.RDBInfo';
    }
}
