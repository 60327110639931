import { RequestBase } from "./request-base";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { monitoringChartFunctions } from "../models/enums/monitoringchartfunctions.enum";

@Injectable()
export class MonitoringService extends RequestBase {
    BasePath = "api/monitoring";

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ExecuteChartRefresh(filter, functionType: monitoringChartFunctions): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/' + monitoringChartFunctions[functionType], filter, this.options);
    }

    CountByType(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountByType', filter, this.options);
    }

    CountByDay(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountByDay', filter, this.options);
    }

    CountByHour(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountByHour', filter, this.options);
    }

    CountByLevel(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountByLevel', filter, this.options);
    }

    CountWorkflowByUser(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountWorkflowByUser', filter, this.options);
    }

    CountWorkflowByType(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountWorkflowByType', filter, this.options);
    }

    CountWorkflowSuccessful(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountWorkflowSuccessful', filter, this.options);
    }

    CountByDayWorkflow(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountByDayWorkflow', filter, this.options);
    }



    ReadLogMailTriggerById(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLogMailTriggerById?id=' + id, this.options);
    }

    ReadLogMailTrigger(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadLogMailTrigger', this.options);
    }

    AddOrUpdateLogMailTrigger(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateLogMailTrigger', data, this.options);
    }

    RemoveLogMailTrigger(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/RemoveLogMailTrigger?id=' + id, this.options);
    }

    Count(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Count', filter, this.options);
    }
    Delete(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Delete', filter, this.options);
    }
}


