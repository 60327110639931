import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class ConnectorService extends RequestBase {
    BasePath = 'api/selfservice/connector';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    //#region Connector
    GetConnectorInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetConnectorInfos', this.options);
    }
    GetConnector(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetConnector?id=' + id, this.options);
    }
    SaveConnector(con): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveConnector', con, this.options);
    }
    GetConnectorWorkflow(id, before): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetConnectorWorkflow?id=' + id + '&before=' + before, this.options);
    }
    //#endregion

    //#region Connector
    GetScriptInfos(connector): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetScriptInfos?connector=' + connector, this.options);
    }
    GetScript(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetScript?id=' + id, this.options);
    }
    SaveScript(script): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveScript', script, this.options);
    }
    //#endregion

    //#region WorkflowMapping
    GetWorkflowMappingStructure(connector): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflowMappingStructure?connector=' + connector, this.options);
    }
    GetWorkflowMapping(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflowMapping?id=' + id, this.options);
    }
    SaveWorkflowMapping(mapping): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflowMapping', mapping, this.options);
    }
    DeleteWorkflowMapping(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteWorkflowMapping?id=' + id, this.options);
    }
    //#endregion
}
