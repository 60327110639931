import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'minio-panel',
    templateUrl: './minio.panel.html',
    styleUrls: ['./minio.panel.css']
})
export class MinioPanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = MinioPanel;
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: MinioPanel,
            MediaType: '4EB395D4-5B74-47DE-9951-EE308B1724D8'.toLowerCase()
        };
    }
}