import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { TranslatedString } from '../../models/translatedstring.model';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';
import { WorkflowEditOptions } from '../../workflow/workflow.control';

@Component({
    selector: 'connector-workflow-edit-dialog',
    template: '<workflow-control *ngIf="SelectedItem" class="flex-row full" [(Data)]="SelectedItem" [EditOptions]="EditOptions"></workflow-control>'
})
export class ConnectorWorkflowEditDialog {
    SelectedItem: WorkflowSaveObject;
    EditOptions: WorkflowEditOptions;

    static ShowDialog(connector, workflow, handler) {
        BaseDialog.ShowDialog({
            InitArgs: {
                Connector: connector,
                Workflow: workflow
            },
            ContentType: ConnectorWorkflowEditDialog,
            FullScreen: true,
            Handler: (r) => {
                if (r) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Workflow bearbeiten'
        });
    }

    Initialize(args) {
        this.EditOptions = new WorkflowEditOptions();
        this.EditOptions.IsService = true;
        if (args && args.Workflow) {
            if (args.Connector) {
                let caption = TranslatedString.GetTranslation(args.Connector.Caption);
                if (!caption) {
                    caption = args.Connector.Name;
                }
                this.EditOptions.ExternalOptions.FixedDataModels = [{
                    Caption: caption,
                    SID: args.Connector.SID
                }];
            }
            if (args.Workflow) {
                const json = serialize(args.Workflow);
                this.SelectedItem = deserialize(WorkflowSaveObject, json);
            }
        }
    }

    GetDialogResult() {
        return this.SelectedItem;
    }
}
