import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { DatasourceObjectSettingsDataHelper } from '../../dataoperations/datasource/datasource.settings';

@Component({
    selector: 'wf-addproperties-settings',
    templateUrl: './add.properties.settings.html'
})
export class AddPropertiesSettings extends WorkflowDialogContent {
    Data = new AddPropertiesSettingsData();
    SourceProps = [];
    LookupProps = [];
    AddProps = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'addPropsWFModule';
        reg.Caption = '@@Eigenschaften ergaenzen';
        reg.GroupID = 'objectoperations';
        reg.Index = 45;
        reg.SettingsControl = AddPropertiesSettings;
        reg.SettingsTypeHelper = new AddPropertiesSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    public static ReadOtherDataSource(workflowData, moduleID, entryOption) {
        const promise = new Promise<any[]>((resolve, reject) => {
            if (workflowData && workflowData.Connectors) {
                let lookUpMod;
                workflowData.Connectors.some(con => {
                    if (con.EntryModule === moduleID && con.EntryOption === entryOption) {
                        return workflowData.Modules.some(mod => {
                            if (mod.ID === con.ExitModule && mod.Module === 'datasourceObjectWFModule') {
                                lookUpMod = mod;
                                return true;
                            }
                            return false;
                        });
                    }
                    return false;
                });
                DatasourceObjectSettingsDataHelper.getStructure(lookUpMod).then((list) => {
                    resolve(list);
                }, (r) => { reject(r); });
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(AddPropertiesSettingsData, json);
        }
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo && dsInfo.Properties) {
            this.SourceProps.push(...dsInfo.Properties);
        }
        AddPropertiesSettings.ReadOtherDataSource(this.WFData, this.ModuleID, 1).then((list) => {
            if (list) {
                this.LookupProps.push(...list);
                this.fillAddProps(list);
            }
        });
    }

    getResult() {
        const mapping = [];
        this.Data.LookUpMapping.forEach(x => {
            if (x.DataSourceProperty && x.DataSourceProperty.PropertyName && x.LookUpProperty && x.LookUpProperty.PropertyName) {
                mapping.push(x);
            }
        });
        this.Data.LookUpMapping = mapping;
        const addProps = [];
        this.AddProps.forEach(x => {
            if (x.Selected) {
                const addProp = new AdditionalProperty();
                addProp.OriginalPropertyName = x.OldName;
                if (x.NewName) {
                    addProp.NewPropertyName = x.NewName;
                } else {
                    addProp.NewPropertyName = x.OldName;
                }
                addProps.push(addProp);
            }
        });
        this.Data.AdditionalProperties = addProps;
        return this.Data;
    }

    fillAddProps(list) {
        if (list) {
            list.forEach(x => {
                if (x.IsList) {
                    return;
                }
                if (x.IsObject) {
                    this.fillAddProps(x.ObjectProperties);
                } else {
                    const addProp = {
                        OldName: x.FullPath,
                        NewName: x.FullPath,
                        Selected: false
                    };
                    if (this.Data && this.Data.AdditionalProperties) {
                        const selected = this.Data.AdditionalProperties.find(y => y.OriginalPropertyName === x.FullPath);
                        if (selected) {
                            addProp.NewName = selected.NewPropertyName;
                            addProp.Selected = true;
                        }
                    }
                    this.AddProps.push(addProp);
                }
            });
        }
    }

    addSearchProp() {
        if (this.Data) {
            const pm = new PropertiesMapping();
            pm.DataSourceProperty = new PropertyMappingSetting();
            pm.LookUpProperty = new PropertyMappingSetting();
            if (this.Data.LookUpMapping) {
                this.Data.LookUpMapping.push(pm);
            } else {
                this.Data.LookUpMapping = [pm];
            }
        }
    }

    removeSearchProp(i) {
        if (this.Data && this.Data.LookUpMapping) {
            this.Data.LookUpMapping.splice(i, 1);
        }
    }

    selectAll(ev) {
        if (ev && this.AddProps) {
            this.AddProps.forEach(s => {
                s.Selected = ev.checked;
            });
        }
    }
}

export class AdditionalProperty {
    OriginalPropertyName: string;
    NewPropertyName: string;
}

export class PropertyMappingSetting {
    PropertyName: string;
    Settings;
}

// @dynamic
export class PropertiesMapping {
    @Type(() => PropertyMappingSetting)
    DataSourceProperty: PropertyMappingSetting;
    @Type(() => PropertyMappingSetting)
    LookUpProperty: PropertyMappingSetting;
}

// @dynamic
export class AddPropertiesSettingsData extends AServiceWorkflowData {
    @Type(() => PropertiesMapping)
    LookUpMapping: PropertiesMapping[] = [];
    @Type(() => AdditionalProperty)
    AdditionalProperties: AdditionalProperty[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.AddProperties.AddPropertiesSettingsData';
    }
}

export class AddPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    private static FindProp(propList, propName: string) {
        if (propList && propName) {
            const pointIndex = propName.indexOf('.');
            if (pointIndex > -1) {
                const realPropName = propName.substring(0, pointIndex);
                const prop = propList.find(x => x.Name === realPropName);
                if (prop) {
                    const nextPath = propName.substring(pointIndex + 1);
                    return AddPropertiesSettingsDataHelper.FindProp(prop.ObjectProperties, nextPath);
                }
            } else {
                return propList.find(x => x.Name === propName);
            }
        }
        return null;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Erfolgreich';
        success.Type = 'objData';
        const noSuccess = new WorkflowExitInfo();
        noSuccess.ID = 1;
        noSuccess.Label = '@@Nicht erfolgreich';
        return [success, noSuccess];
    }

    getEmptySettingsInstance() {
        return new AddPropertiesSettingsData();
    }

    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'objData';
        const lookUp = new WorkflowExitInfo();
        lookUp.ID = 1;
        lookUp.Label = '@@LookUp';
        lookUp.Type = 'objData';
        return [def, lookUp];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && settings && settings.AdditionalProperties && settings.AdditionalProperties.length > 0) {
                const propList = await AddPropertiesSettings.ReadOtherDataSource(wfData, module.ID, 1);
                if (propList) {
                    settings.AdditionalProperties.forEach(addProp => {
                        const prop = AddPropertiesSettingsDataHelper.FindProp(propList, addProp.OriginalPropertyName);
                        if (prop) {
                            const newProp = JSON.parse(JSON.stringify(prop));
                            newProp.Name = addProp.NewPropertyName;
                            ds.Properties.push(newProp);
                        }
                    });
                }
            }
        }
    }
}
