import { Component } from '@angular/core';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { ValueAndType, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { ValueFormulaNodeCalculator } from '../../../models/calculation/formula.node.calculator';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';

export class DefineValueSettingsData extends AServiceWorkflowData {
    Formula: string;

     getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineValue.DefineValueSettingsData';
     }
}

export class DefineValueSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new DefineValueSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class DefineValueModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.Formula === 'string') {
                const calculator = new WorkflowFormulaCalculator(status);
                status.ActualParameter = calculator.CalcFormula(status.ActualSettings.Formula);
                return 0;
            } else {
                status.Logger.logError('DefineValue modul: No formula found.');
            }
        } else {
            status.Logger.logError('DefineValue modul: No settings found.');
        }
        return super.execute(status);
    }
}

export class WorkflowFormulaCalculator {
    private Parser = new AdvancedFormulaParser();
    private Calculator: ValueFormulaNodeCalculator;

    constructor(status: WorkflowStatus) {
        const params = [];
        const values = new Map();
        if (status.ActualParameter !== null && typeof status.ActualParameter === 'object') {
            const keys = Object.keys(status.ActualParameter);
            keys.forEach((key) => {
                params.push({
                    Name: key,
                    VariableID: key,
                    AliasKey: key
                });
                values.set(key, ValueAndType.GetValueAndTypeFromJSObject(status.ActualParameter[key]));
            });
        }
        status.Context.forEach((v, k) => {
            params.push({
                Name: k,
                VariableID: k,
                AliasKey: k
            });
            values.set(k, ValueAndType.GetValueAndTypeFromJSObject(v));
        });
        const sysVariables = status.WorkflowLayoutService.getElementVariables();
        this.Parser.SetVariables(params, FormulaInputMode.VariableName);
        this.Calculator = new ValueFormulaNodeCalculator(false, sysVariables, values);
    }

    CalcFormula(formula: string): any {
        const formulaDescription = this.Parser.Parse(formula);
        return this.Calculator.Calc(formulaDescription).Value;
    }

    CalcAndAddToStatus(formula: string, key: string) {
        const formulaDescription = this.Parser.Parse(formula);
        const formulaValue = this.Calculator.Calc(formulaDescription);
        const retVal = formulaValue.Value;
        const vni = new VariablesNodeInformation();
        vni.Name = key;
        vni.AliasKey = key;
        vni.VariableID = key;
        this.Parser.Variables.set(key.toUpperCase().trim(), vni);
        this.Calculator.AddValue(key, formulaValue);
        return retVal;
    }
}

@Component({
    selector: 'define-value-wf-settings',
    templateUrl: './define.value.settings.html',
    styleUrls: ['./define.value.settings.css']
})
export class DefineValueSettings extends FormulaWorkflowDialogContent {
    static MultiModuleID = 'defineMultiValuesWFModule';

    Formula: string;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'defineValueWFModule';
        reg.Caption = '@@Wert definieren';
        reg.SettingsControl = DefineValueSettings;
        reg.SettingsTypeHelper = new DefineValueSettingsDataHelper();
        reg.Executer = DefineValueModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = DefineValueSettings.MultiModuleID;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data && data.Formula) {
            this.Formula = data.Formula;
        }
    }

    getResult(): any {
        const retVal = new DefineValueSettingsData();
        retVal.Formula = this.Formula;
        return retVal;
    }
}
