import { DataModelDetail } from '../settings/datamodel/datamodel.settings';
import { DataSourceDetail } from '../settings/datamodel/datasource/datasource.settings';
import { LiteDBPanel } from '../settings/datamodel/datasource/datasourcepanels/litedb/litedb.panel';
import { MongoDBPanel } from '../settings/datamodel/datasource/datasourcepanels/mongodb/mongodb.panel';
import { PostgreSQLPanel } from '../settings/datamodel/datasource/datasourcepanels/postgredb/postgredb.panel';
import { SQLDBPanel } from '../settings/datamodel/datasource/datasourcepanels/sqldb/sqldb.panel';
import { MediaSourceSettings, MediaSourceDetail } from '../settings/datamodel/mediasource/mediasource.settings';
import { ContainerEnumSettings, ContainerEnumDetail } from '../settings/datamodel/enum/containerenum.settings';
import { MySQLDBPanel } from '../settings/datamodel/datasource/datasourcepanels/mysqldb/mysqldb.panel';
import { OracleDBPanel } from '../settings/datamodel/datasource/datasourcepanels/oracledb/oracledb.panel';
import { ODBCDBPanel } from '../settings/datamodel/datasource/datasourcepanels/odbcdb/odbcdb.panel';
import { DataObjectSettings, DataObjectDetail } from '../settings/datamodel/dataobject/dataobject.settings';
import { CouchBasePanel } from '../settings/datamodel/datasource/datasourcepanels/couchbase/couchbase.panel';
import { MSDBPanel } from '../settings/datamodel/datasource/datasourcepanels/mediasourcedb/msdb.panel';
import { NoDBPanel } from '../settings/datamodel/datasource/datasourcepanels/nodatabase/nodatabase.panel';
import { ConnectorsPanel } from '../settings/datamodel/datasource/datasourcepanels/connectors/connectors.panel';
import { OpenAIPanel } from '../settings/datamodel/datasource/datasourcepanels/openai/openai.panel';

export const APP_DATAMODEL = [
    DataModelDetail,
    DataSourceDetail,
    LiteDBPanel,
    MongoDBPanel,
    PostgreSQLPanel,
    SQLDBPanel,
    OpenAIPanel,
    MediaSourceSettings,
    MediaSourceDetail,
    DataObjectSettings,
    DataObjectDetail,
    ContainerEnumSettings,
    ContainerEnumDetail,
    MySQLDBPanel,
    ODBCDBPanel,
    CouchBasePanel,
    MSDBPanel,
    NoDBPanel,
    ConnectorsPanel
];
