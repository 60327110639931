import { ATask, ITaskExecuter } from '../atask.model';
import { MultiResult, MultiNode, AxisNode, Axis } from '../../datadescription/multi/multiresult.model';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { AxisType } from '../../enums/query.enum';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { add } from 'mathjs';
import { DataDescriptionHelper } from '../../../components/query/ddhelper.query';

export class PreviousLevel extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;
    Execute() {
        throw new Error('Method not implemented.');
    }
}

export class PreviousLevelExecuter implements ITaskExecuter {
    static TaskID = 'previousLevel';

    MeasureUniqueID = -1;
    Previous: number;
    Axis: AxisType = AxisType.Y_Axis;
    Result: MultiResult;
    DataDescription: DataDescription;
    Context;

    private static ReDimNodes(oldNodes: AxisNode[], newNodes: AxisNode[], axis: Axis, dict: Map<number, number>) {
        if (oldNodes && newNodes && axis) {
            oldNodes.forEach(an => {
                const existing = newNodes.find(x => x.UniqueID === an.UniqueID && x.MemberId === an.MemberId);
                if (existing) {
                    dict.set(existing.Position, an.Position);
                    PreviousLevelExecuter.ReDimNodes(an.Children, existing.Children, axis, dict);
                } else {
                    const clone = AxisNode.clone(an, true);
                    clone.Position = axis.ElementCount++;
                    dict.set(clone.Position, an.Position);
                    newNodes.push(clone);
                }
            });
        }
    }

    Init(settings, result, dataDescription, context) {
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.Previous === 'number') {
                this.Previous = settings.Previous;
            }
            if (typeof settings.Axis === 'number') {
                this.Axis = settings.Axis;
            }
        }
        this.Result = result;
        this.DataDescription = dataDescription;
        this.Context = context;
    }

    Execute() {
        if (this.Result && this.DataDescription && this.MeasureUniqueID >= -1 && this.Previous > 0) {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                const noLevelIndex = {
                    Index: 0
                };
                if (this.DataDescription.XLevelNodes.Areas.some(a => {
                    if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                        const map = this.ReDimXArea(a.UniqueID);
                        const rootNodes = MultiResultHelper.GetNodesFromArea(a, this.Result.XAxis.Nodes, noLevelIndex);
                        if (rootNodes.Nodes.length > 0) {
                            if (this.Axis === AxisType.X_Axis) {
                                MultiResultHelper.ExecuteOnAllNodes(this.Result.YAxis, (y) => {
                                    let rootVal: MultiNode;
                                    rootNodes.Nodes.forEach(xNode => {
                                        const cell = MultiResultHelper.GetCellOrNull(this.Result, xNode, y, measure);
                                        if (cell && cell.InternalValue != null) {
                                            if (rootVal) {
                                                rootVal.InternalValue = add(rootVal.InternalValue, cell.InternalValue);
                                            } else {
                                                rootVal = new MultiNode({
                                                    CellType: cell.CellType,
                                                    InternalValue: cell.InternalValue
                                                });
                                            }
                                        }
                                    });
                                    if (rootVal) {
                                        rootNodes.Nodes.forEach(xNode => {
                                            const cell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, y, measure);
                                            if (cell.IsNew) {
                                                cell.Node.CellType = rootVal.CellType;
                                            } else {
                                                this.SetValueOnChildrenX(xNode.Children, measure, y, [rootVal, cell.Node]);
                                            }
                                            cell.Node.InternalValue = rootVal.InternalValue;
                                        });
                                    }
                                });
                            } else {
                                let reloadMeasure;
                                if (this.Context && this.Context.ReloadInfo && this.Context.ReloadInfo.ReloadTupleY
                                    && this.Context.ReloadInfo.ParentMatrix && map) {
                                    reloadMeasure = this.Context.ReloadInfo.ParentMatrix.Measures.Nodes.find(m =>
                                        m.UniqueID === this.MeasureUniqueID);
                                }
                                if (reloadMeasure) {
                                    const parentCells = [];
                                    let reloadNode = this.Context.ReloadInfo.ReloadTupleY;
                                    while (reloadNode) {
                                        parentCells.push(reloadNode);
                                        reloadNode = reloadNode.Parent;
                                    }
                                    MultiResultHelper.ExecuteOnAllNodesInternal(rootNodes.Nodes, (xNode) => {
                                        const mappedID = map.get(xNode.Position);
                                        this.Result.YAxis.Nodes.forEach(yNode => {
                                            const cell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, yNode, measure);
                                            const cellList = [cell.Node];
                                            parentCells.some(rY => {
                                                const xArray = this.Context.ReloadInfo.ParentMatrix.Cells[mappedID];
                                                if (xArray) {
                                                    const yArray = xArray[rY.Position];
                                                    if (yArray) {
                                                        const rCell = yArray[reloadMeasure.Position];
                                                        if (rCell) {
                                                            cellList.splice(0, 0, rCell);
                                                            return false;
                                                        }
                                                    }
                                                }
                                                return true;
                                            });
                                            this.SetValueOnChildrenY(yNode.Children, measure, xNode, cellList);
                                            const index = Math.max(0, cellList.length - 1 - this.Previous);
                                            const prevCell = cellList[index];
                                            cell.Node.CellType = prevCell.CellType;
                                            cell.Node.InternalValue = prevCell.InternalValue;
                                        });
                                    });
                                } else {
                                    MultiResultHelper.ExecuteOnAllNodesInternal(rootNodes.Nodes, (xNode) => {
                                        const rootMap = new Map<number, MultiNode>();
                                        this.Result.YAxis.Nodes.forEach(yNode => {
                                            const cell = MultiResultHelper.GetCellOrNull(this.Result, xNode, yNode, measure);
                                            if (cell && cell.InternalValue != null) {
                                                let rootVal = rootMap.get(yNode.UniqueID);
                                                if (rootVal) {
                                                    rootVal.InternalValue = add(rootVal.InternalValue, cell.InternalValue);
                                                } else {
                                                    rootVal = new MultiNode({
                                                        CellType: cell.CellType,
                                                        InternalValue: cell.InternalValue
                                                    });
                                                    rootMap.set(yNode.UniqueID, rootVal);
                                                }
                                            }
                                        });
                                        this.Result.YAxis.Nodes.forEach(yNode => {
                                            const rootVal = rootMap.get(yNode.UniqueID);
                                            if (rootVal) {
                                                const cell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, yNode, measure);
                                                if (cell.IsNew) {
                                                    cell.Node.CellType = rootVal.CellType;
                                                } else {
                                                    this.SetValueOnChildrenY(yNode.Children, measure, xNode, [rootVal, cell.Node]);
                                                }
                                                cell.Node.InternalValue = rootVal.InternalValue;
                                            }
                                        });
                                    });
                                }
                            }
                        }
                        return true;
                    } else {
                        if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                        } else {
                            noLevelIndex.Index++;
                        }
                    }
                    return false;
                })) {
                } else {
                    noLevelIndex.Index = 0;
                    this.DataDescription.YLevelNodes.Areas.some(a => {
                        if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                            const rootNodes = MultiResultHelper.GetNodesFromArea(a, this.Result.YAxis.Nodes, noLevelIndex);
                            if (rootNodes.Nodes.length > 0) {
                                let map: Map<number, number>;
                                this.DataDescription.XLevelNodes.Areas.forEach(xArea => {
                                    const areaMap = this.ReDimXArea(xArea.UniqueID);
                                    if (areaMap) {
                                        if (!map) {
                                            map = new Map<number, number>();
                                        }
                                        areaMap.forEach((v, k) => {
                                            map.set(k, v);
                                        });
                                    }
                                });
                                if (this.Axis === AxisType.Y_Axis) {
                                    let reloadMeasure;
                                    if (this.Context && this.Context.ReloadInfo && this.Context.ReloadInfo.ReloadTupleY
                                        && this.Context.ReloadInfo.ParentMatrix && map) {
                                        reloadMeasure = this.Context.ReloadInfo.ParentMatrix.Measures.Nodes.find(m =>
                                            m.UniqueID === this.MeasureUniqueID);
                                    }
                                    if (reloadMeasure) {
                                        const parentCells = [];
                                        let reloadNode = this.Context.ReloadInfo.ReloadTupleY;
                                        while (reloadNode) {
                                            parentCells.push(reloadNode);
                                            reloadNode = reloadNode.Parent;
                                        }
                                        MultiResultHelper.ExecuteOnAllNodes(this.Result.XAxis, (xNode) => {
                                            const mappedID = map.get(xNode.Position);
                                            rootNodes.Nodes.forEach(yNode => {
                                                const cell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, yNode, measure);
                                                const cellList = [cell.Node];
                                                parentCells.some(rY => {
                                                    const xArray = this.Context.ReloadInfo.ParentMatrix.Cells[mappedID];
                                                    if (xArray) {
                                                        const yArray = xArray[rY.Position];
                                                        if (yArray) {
                                                            const rCell = yArray[reloadMeasure.Position];
                                                            if (rCell) {
                                                                cellList.splice(0, 0, rCell);
                                                                return false;
                                                            }
                                                        }
                                                    }
                                                    return true;
                                                });
                                                this.SetValueOnChildrenY(yNode.Children, measure, xNode, cellList);
                                                const index = Math.max(0, cellList.length - 1 - this.Previous);
                                                const prevCell = cellList[index];
                                                cell.Node.CellType = prevCell.CellType;
                                                cell.Node.InternalValue = prevCell.InternalValue;
                                            });
                                        });
                                    } else {
                                        MultiResultHelper.ExecuteOnAllNodes(this.Result.XAxis, (x) => {
                                            let rootVal: MultiNode;
                                            rootNodes.Nodes.forEach(yNode => {
                                                const cell = MultiResultHelper.GetCellOrNull(this.Result, x, yNode, measure);
                                                if (cell && cell.InternalValue !=null) {
                                                    if (rootVal) {
                                                            rootVal.InternalValue = add(rootVal.InternalValue, cell.InternalValue);
                                                    } else {
                                                        rootVal = new MultiNode({
                                                            CellType: cell.CellType,
                                                            InternalValue: cell.InternalValue
                                                        });
                                                    }
                                                }
                                            });
                                            if (rootVal) {
                                                rootNodes.Nodes.forEach(yNode => {
                                                    const cell = MultiResultHelper.TryGetCellOrNew(this.Result, x, yNode, measure);
                                                    if (cell.IsNew) {
                                                        cell.Node.CellType = rootVal.CellType;
                                                    } else {
                                                        this.SetValueOnChildrenY(yNode.Children, measure, x, [rootVal, cell.Node]);
                                                    }
                                                    cell.Node.InternalValue = rootVal.InternalValue;
                                                });
                                            }
                                        });
                                    }
                                } else {
                                    MultiResultHelper.ExecuteOnAllNodesInternal(rootNodes.Nodes, (yNode) => {
                                        const rootMap = new Map<number, MultiNode>();
                                        this.Result.XAxis.Nodes.forEach(xNode => {
                                            const cell = MultiResultHelper.GetCellOrNull(this.Result, xNode, yNode, measure);
                                            if (cell && cell.InternalValue != null) {
                                                let rootVal = rootMap.get(xNode.UniqueID);
                                                if (rootVal) {
                                                    rootVal.InternalValue = add(rootVal.InternalValue, cell.InternalValue);
                                                } else {
                                                    rootVal = new MultiNode({
                                                        CellType: cell.CellType,
                                                        InternalValue: cell.InternalValue
                                                    });
                                                    rootMap.set(xNode.UniqueID, rootVal);
                                                }
                                            }
                                        });
                                        this.Result.XAxis.Nodes.forEach(xNode => {
                                            const rootVal = rootMap.get(xNode.UniqueID);
                                            if (rootVal) {
                                                const cell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, yNode, measure);
                                                if (cell.IsNew) {
                                                    cell.Node.CellType = rootVal.CellType;
                                                } else {
                                                    this.SetValueOnChildrenX(xNode.Children, measure, yNode, [rootVal, cell.Node]);
                                                }
                                                cell.Node.InternalValue = rootVal.InternalValue;
                                            }
                                        });
                                    });
                                }
                            }
                            return true;
                        } else {
                            if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                            } else {
                                noLevelIndex.Index++;
                            }
                        }
                        return false;
                    });
                }
            }
        }
    }

    SetValueOnChildrenX(childList: AxisNode[], measure: AxisNode, yNode: AxisNode, cellList: MultiNode[]) {
        childList.forEach(child => {
            const childCell = MultiResultHelper.TryGetCellOrNew(this.Result, child, yNode, measure);
            this.SetValueOnChildrenX(child.Children, measure, yNode, [...cellList, childCell.Node]);
            const index = Math.max(0, cellList.length - this.Previous);
            const cell = cellList[index];
            if (childCell.IsNew) {
                childCell.Node.CellType = cell.CellType;
            }
            childCell.Node.InternalValue = cell.InternalValue;
        });
    }

    SetValueOnChildrenY(childList: AxisNode[], measure: AxisNode, xNode: AxisNode, cellList: MultiNode[]) {
        childList.forEach(child => {
            const childCell = MultiResultHelper.TryGetCellOrNew(this.Result, xNode, child, measure);
            this.SetValueOnChildrenY(child.Children, measure, xNode, [...cellList, childCell.Node]);
            const index = Math.max(0, cellList.length - this.Previous);
            const cell = cellList[index];
            if (childCell.IsNew) {
                childCell.Node.CellType = cell.CellType;
            }
            childCell.Node.InternalValue = cell.InternalValue;
        });
    }

    private ReDimXArea(areaID: number): Map<number, number> {
        if (this.Result && this.Context && this.Context.ReloadInfo) {
            if (this.Context.ReloadInfo.ReDimedAreas) {
                const retVal = this.Context.ReloadInfo.ReDimedAreas.get(areaID);
                if (retVal) {
                    return retVal;
                }
            }
            if (this.Context.ReloadInfo.ParentMatrix && this.Context.DataDescriptionOriginal) {
                const area = this.Context.DataDescriptionOriginal.XLevelNodes.Areas.find(a => a.UniqueID === areaID);
                if (area) {
                    const axis = this.Context.ReloadInfo.ParentMatrix.XAxis;
                    const levels = DataDescriptionHelper.GetLevels(area);
                    let levelID;
                    const axisNodes = [];
                    axis.Nodes.some(n => {
                        if (typeof levelID === 'number') {
                            if (levelID === n.UniqueID) {
                                axisNodes.push(n);
                            } else {
                                return true;
                            }
                        } else if (levels.some(l => l.UniqueID === n.UniqueID)) {
                            levelID = n.UniqueID;
                            axisNodes.push(n);
                        }
                        return false;
                    });

                    if (axisNodes.length > 0) {
                        let lastIndex;
                        const reloadAxisNodes = [];
                        this.Result.XAxis.Nodes.some((x, i) => {
                            if (x.UniqueID === levelID) {
                                lastIndex = i;
                                reloadAxisNodes.push(x);
                            } else {
                                return reloadAxisNodes.length > 0;
                            }
                            return false;
                        });
                        if (reloadAxisNodes.length > 0) {
                            const retVal = new Map<number, number>();
                            const newNodes = [];
                            axisNodes.forEach(an => {
                                const existing = reloadAxisNodes.find(x => x.MemberId === an.MemberId);
                                if (existing) {
                                    retVal.set(existing.Position, an.Position);
                                    PreviousLevelExecuter.ReDimNodes(an.Children, existing.Children, this.Result.XAxis, retVal);
                                } else {
                                    const clone = AxisNode.clone(an, true);
                                    clone.Position = this.Result.XAxis.ElementCount++;
                                    retVal.set(clone.Position, an.Position);
                                    newNodes.push(clone);
                                }
                            });
                            this.Result.XAxis.Nodes.splice(lastIndex + 1, 0, ...newNodes);
                            if (!this.Context.ReloadInfo.ReDimedAreas) {
                                this.Context.ReloadInfo.ReDimedAreas = new Map<number, Map<number, number>>();
                            }
                            this.Context.ReloadInfo.ReDimedAreas.set(areaID, retVal);
                            return retVal;
                        }
                    }
                }
            }
        }
        return null;
    }
}
