import { Component } from "@angular/core";
import { EnumHelper } from "../../../../../helpers/enum.helper";
import { DBPanelInfo, LiteDBInfo } from "../../../../../models/datamodel/adbinfo.model";
import { FileMode } from "../../../../../models/enums/filemode.enum";
import { BaseDataSourcePanel } from "../basedatasource.panel";

@Component({
    selector: 'litedb-panel',
    templateUrl: './litedb.panel.html',
    styleUrls: ['./litedb.panel.css']
})
export class LiteDBPanel extends BaseDataSourcePanel {

    FileModes = [];

    constructor() {
        super();
        this.DBInfoType = LiteDBInfo;
        this.FileModes = EnumHelper.GetDropdownValues(FileMode);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: LiteDBPanel,
            DBInfoType: LiteDBInfo
        };
    }
}