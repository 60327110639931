import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { BubbleRow } from '../../controls/chart.data.model';
import { SeriesSettings } from '../../controls/chart.model';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { AxisType } from '../../enums/query.enum';
import { ChartStyleMerger, SeriesValueStyle } from '../../styling/chart.style.merger';
import { ITaskExecuter } from '../atask.model';
import { TaskHelper } from '../task.helper';

export class BubbleSeriesFormattingTaskExecuter implements ITaskExecuter {
    LevelUniqueID = -1;
    LevelMember: string;
    UseConditions = false;
    Conditions = [];
    MeasuresOnX = false;

    Settings: SeriesSettings;
    SeriesValueStyle: SeriesValueStyle;
    Result: { Rows: BubbleRow[], Series: SeriesSettings[] };
    Merger: ChartStyleMerger;
    Matrix: MultiResult;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Matrix = result;
        if (dataDescription) {
            this.MeasuresOnX = dataDescription.ShowMeasureOnAxis == AxisType.X_Axis;
        }
        if (context) {
            this.Result = context.BubbleResult;
            if (dataDescription && context.BaseReportObject && context.BaseReportObject.LayoutElement &&
                context.BaseReportObject.LayoutElement.ChartSettings && context.BaseReportObject.LayoutElement.ChartSettings.Series &&
                context.BaseReportObject.LayoutElement.ChartSettings.Series.length > 0) {
                const first = context.BaseReportObject.LayoutElement.ChartSettings.Series[0];
                if (first) {
                    const rMeasureID = first['Radius'];
                    if (rMeasureID) {
                        let area;
                        if (this.MeasuresOnX) {
                            if (dataDescription.XLevelNodes && dataDescription.XLevelNodes.Areas) {
                                area = dataDescription.XLevelNodes.Areas.find(x => x.Measures && x.Measures.some(y => '' + y.UniqueID == rMeasureID));
                            }
                        } else {
                            if (dataDescription.YLevelNodes && dataDescription.YLevelNodes.Areas) {
                                area = dataDescription.YLevelNodes.Areas.find(x => x.Measures && x.Measures.some(y => '' + y.UniqueID == rMeasureID));
                            }
                        }
                        if (area && area.Tuples && area.Tuples.length > 0) {
                            const firstTuple = area.Tuples[0];
                            if (firstTuple && firstTuple.Levels && firstTuple.Levels.length > 0) {
                                this.LevelUniqueID = firstTuple.Levels[firstTuple.Levels.length - 1].UniqueID;
                            }
                        }
                    }
                }
            }
        }
        if (settings) {
            if (typeof settings.LevelMember === 'string') {
                this.LevelMember = settings.LevelMember;
            }
            if (typeof settings.UseConditions === 'boolean') {
                this.UseConditions = settings.UseConditions;
            }
            if (settings.UseConditions && settings.Conditions) {
                const conditions = [];
                settings.Conditions.forEach(cond => {
                    if (cond.Condition && cond.Style) {
                        const svs = ChartStyleMerger.GetSVStyle(cond.Style);
                        if (svs) {
                            conditions.push({
                                Condition: cond.Condition,
                                Style: svs
                            });
                        }
                    }
                });
                if (conditions.length > 0) {
                    this.Merger = ChartStyleMerger.GetChartStyleMerger(context);
                }
                this.Conditions = conditions;
            }
            if (settings.Style) {
                this.Settings = settings.Style;
                this.SeriesValueStyle = ChartStyleMerger.GetSVStyle(this.Settings);
                if (this.SeriesValueStyle) {
                    this.Merger = ChartStyleMerger.GetChartStyleMerger(context);
                }
            }
        }
    }

    Execute() {
        if (this.Result && this.Result.Rows && this.Settings) {
            if (this.UseConditions && this.Conditions.length === 0) {
                return;
            }
            if (!this.UseConditions && this.Result.Series) {
                this.Result.Series.forEach(x => ChartStyleMerger.MergeSeriesSettings(x, this.Settings));
            }
            if (this.Merger) {
                if (this.LevelUniqueID > -1 && this.LevelMember) {
                    this.Result.Rows.forEach(x => {
                        if (x.MeasureAxisNode && x.MeasureAxisNode.UniqueID == this.LevelUniqueID && x.MeasureAxisNode.Key == this.LevelMember) {
                            this.FormatRow(x);
                        }
                    });
                } else {
                    this.Result.Rows.forEach(x => {
                        this.FormatRow(x);
                    });
                }
            }
        }
    }

    private FormatRow(row: BubbleRow) {
        if (this.UseConditions) {
            this.Conditions.forEach(c => {
                if (c.Condition && c.Style) {
                    let mn = new MultiNode({
                        InternalValue: row.Radius.Value
                    });
                    if (typeof c.Condition.MeasureUniqueID === 'number') {
                        if (c.Condition.MeasureUniqueID !== row.Radius.UniqueID) {
                            mn = null;
                            const mNode = this.Matrix.Measures.Nodes.find(x => x.UniqueID === c.Condition.MeasureUniqueID);
                            if (mNode) {
                                if (this.MeasuresOnX) {
                                    mn = MultiResultHelper.GetCellOrNull(this.Matrix, row.MeasureAxisNode, row.OppositeAxisNode, mNode);
                                } else {
                                    mn = MultiResultHelper.GetCellOrNull(this.Matrix, row.OppositeAxisNode, row.MeasureAxisNode, mNode);
                                }
                            }
                        }
                    }
                    if (mn && TaskHelper.CheckCondition(mn, c.Condition.ConditionOperator, c.Condition.Value1, c.Condition.Value2)) {
                        row.StyleID = this.Merger.MergeStyle(row.StyleID, c.Style);
                    }
                }
            });
        } else {
            row.StyleID = this.Merger.MergeStyle(row.StyleID, this.SeriesValueStyle);
        }
    }
}
