import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'textarea-theme-control',
    templateUrl: './textarea.theme.control.html',
    styleUrls: ['./textarea.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaThemeControl extends BaseThemeControl {

}
