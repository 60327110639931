import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartControl } from './chart.component';
import { ApexChartsControl } from './apexcharts.component';
import { ChartModule } from 'primeng/chart';
import { AngularResizeEventModule } from 'angular-resize-event';
import { SunburstControl } from './sunburst.component';

@NgModule({
    declarations: [
        ChartControl,
        ApexChartsControl,
        SunburstControl
    ],
    imports: [
        CommonModule,
        FormsModule,
        ScrollingModule,
        ChartModule,
        AngularResizeEventModule
    ],
    exports: [
        ChartControl
    ]
})
export class ChartsModule { }
