import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { FormulaNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { StandardRequestBase } from '../../../services/request-base';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'formulatextbox-theme-control',
    templateUrl: './formula.textbox.theme.control.html',
    styleUrls: ['./formula.textbox.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormulaTextBoxThemeControl extends BaseThemeControl {
    Mentions = [];
    GlobalMentions = [];
    //#region Variables
    VariablesValue = [];
    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        this.VariablesValue = val;
    }
    //#endregion
    //#region VariablesSub
    VariablesSubValue;
    @Input()
    get VariablesSub() {
        return this.VariablesSubValue;
    }
    set VariablesSub(val) {
        if (this.VariablesSubValue) {
            this.VariablesSubValue.unsubscribe();
        }
        if (val) {
            this.VariablesSubValue = val.subscribe((items) => {
                this.Variables = items;
            })
        }
    }
    //#endregion
    //#region SystemVariables
    SystemVariablesValue = [];

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        if (val) {
            this.SystemVariablesValue = val;
            const list = [];
            if (Array.isArray(val)) {
                val.forEach(sv => {
                    list.push(sv.Name);
                });
            }
            this.updateMentions(list);
            this.SystemVariablesChange.emit(this.SystemVariablesValue);
        }
    }

    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion
    //#region InputMode
    InputModeValue = FormulaInputMode.VariableName;

    @Input()
    get InputMode() {
        return this.InputModeValue;
    }
    set InputMode(val) {
        this.InputModeValue = val;
        this.InputModeChange.emit(this.InputModeValue);
    }

    @Output() InputModeChange = new EventEmitter<any>();
    //#endregion

    //#region InputMode
    FormulaGroupValue = FormulaGroupEnum.All;

    @Input()
    get FormulaGroup() {
        return this.FormulaGroupValue;
    }
    set FormulaGroup(val) {
        this.FormulaGroupValue = val;
        this.FormulaGroupChange.emit(this.FormulaGroupValue);
    }

    @Output() FormulaGroupChange = new EventEmitter<any>();
    //#endregion
    //#region DisplayFormula
    DisplayFormulaValue;

    @Input()
    get DisplayFormula() {
        return this.DisplayFormulaValue;
    }
    set DisplayFormula(val) {
        this.DisplayFormulaValue = val;
        this.DisplayFormulaChange.emit(this.DisplayFormulaValue);
    }

    @Output() DisplayFormulaChange = new EventEmitter<any>();
    //#endregion
    IsRtl = false;
    constructor(private dialog: MatDialog, cdRef: ChangeDetectorRef, private requestService: StandardRequestBase) {
        super(cdRef);

        this.Subscriptions['DataSourceInternalChange'] = this.DataSourceInternalChange.subscribe(x => {
            if (x) {
                const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.DataSourceInternal);
                if (formula) {
                    this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                }
            }
        });
        this.Subscriptions['PathBinding'] = this.PathBindingChange.subscribe(x => {
            if (this.DataSourceInternal) {
                const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.DataSourceInternal);
                if (formula) {
                    this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                }
            }
        })
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
    }
    updateMentions(systemVariables) {
        const mentions = [];
        mentions.push(...systemVariables);
        mentions.push(...this.GlobalMentions);
        this.Mentions = mentions;
    }
    
    openDialog() {
        const args = {
            Variables: this.Variables,
            SystemVariables: this.SystemVariables,
            InputMode: this.InputModeValue,
            FormulaGroup: this.FormulaGroupValue,
            Formula: this.DisplayFormula ? this.DisplayFormula:this.DataSourceInternal
        };
        FormulaEditorDialog.ShowDialog(args, (r) => {
            if (r) {
                this.DisplayFormula = r.Formula;
                this.OnFormulaChanged();
            }
            return true;
        });
    }
    OnFormulaChanged() {
        if (this.DisplayFormula) {
            const parser = new AdvancedFormulaParser();
            parser.SetVariables(this.Variables, FormulaInputMode.VariableName);
            try {
                this.DataSourceInternal = parser.Parse(this.DisplayFormula);
            } catch {
                if (this.DataSourceInternal) {
                    const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.DataSourceInternal);
                    if (formula) {
                        this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                    } else {
                        this.DisplayFormula = '';
                        this.DataSourceInternal = null;
                    }
                }
            }
            this.onStyleChanged();
            this.displayMemberChanged();
        } else {
            this.DataSourceInternal = null;
        }
    }
    displayMemberChanged() {
        if (this.SelectedItemValue && this.SelectedItemValue.Element &&
            typeof this.SelectedItemValue.Element.UpdateCaptionGetter === 'function') {
            this.SelectedItemValue.Element.UpdateCaptionGetter();
        }
    }
}
