import { IBaseComponent } from '../base.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { BasePanel } from '../../../appbuilder/panels/base.panel';

@Component({
    selector: 'evi-page-index',
    templateUrl: './page.index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageIndexComponent extends IBaseComponent {
    static Type: any = 'pageindex';
    static Default = { Type: 'pageindex', Caption: 'PageIndex' };

    ControlInitialized() {
    }
}
export class PageIndexPanel extends BasePanel {
    static override SIDS = ['6311bcb6-4997-43da-9264-c3bf15604066']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'6311bcb6-4997-43da-9264-c3bf15604066',
            ID: 'pageindex',
            Caption: '@@Seitenzahl',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'pageindex';
            }
        })

        PROPERTIES.push({
            ID: "ShowPageCount",
            Parent: "pageindex",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Gesamtseitenzahl anzeigen"
            }
        })
    }
}