import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { InjectorHelper } from '../../helpers/injector.helper';
import { PermissionHelper } from '../../helpers/permissions.helper';
import { DataModel } from '../../models/datamodel/datamodel.model';
import { TranslatedString } from '../../models/translatedstring.model';
import { DataModelService } from '../../services/datamodel.service';
import { NavigationService } from '../../services/navigation.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { DataObjectSettings } from './dataobject/dataobject.settings';
import { DataSourceSettings } from './datasource/datasource.settings';
import { ContainerEnumSettings } from './enum/containerenum.settings';
import config from '../../../assets/config.json'
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'datamodel-settings',
    templateUrl: '../base.list.settings.html'
})
export class DataModelSettings extends BaseListSettings {

    constructor(private dataService: DataModelService, protected factoryResolver: ComponentFactoryResolver,
        private translate: TranslateService, protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = PermissionHelper.IsAdmin();
    }

    getContentType() {
        return DataModelDetail;
    }

    loadList(handler) {
        this.dataService.GetModels().subscribe(models => {
            if (models) {
                const list = [];
                models.forEach(function (model) {
                    list.push({
                        Caption: model.Caption,
                        ID: model.SID,
                        IsCapsule: model.IsCapsule,
                        IsOverridden: model.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.dataService.GetDataModel(data).subscribe(dataModel => {
            if (dataModel) {
                const dm = plainToClass(DataModel, dataModel);
                this.setSelectedItem(dm);
            }
        });
    }

    getNewItem() {
        const dm = new DataModel();
        dm.Name = 'DataModel_' + this.ListItems.length + 1;
        dm.Caption = new TranslatedString(this.translate.instant('@@New DataModel'));
        return dm;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete the DataModel \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@DataModel \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@DataModel delete');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@DataModel \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@DataModel save');
        return retVal;
    }

    delete(data, handler) {
        this.dataService.DeleteModel(data).subscribe((res) => {
            handler(res);
        });
        const node = DataModelSettings.ModelNode.getValue();
        if (node && node.Children) {
            for (let i = 0; i < node.Children.length; i++) {
                if (node.Children[i].ID == data) {
                    node.Children.splice(i, 1);
                    break;
                }
            }
        }
    }

    saveInternal(item, handler) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.dataService.SaveModel(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        if (!item.SID) {
            item.SID = result.SID;
        }
        if (!item.Version) {
            item.Version = result.Version;
        }
        const node = DataModelSettings.ModelNode.getValue();
        if (node && node.Children) {
            const child = DataModelSettings.CreateModelNode(item);
            node.Children.push(child);
        }
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    static ModelNode: BehaviorSubject<any> = new BehaviorSubject(null);

    static GetSettingsEntry() {
        return {
            Caption: '@@DataModel',
            ID: 'dataModel',
            Icon: 'description',
            Index: 0,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 2
            },
            Children: [],
            UpdateFunction: DataModelSettings.UpdateModelNodes
        };
    }

    static UpdateModelNodes(nav) {
        DataModelSettings.ModelNode.next(nav);
        if (nav) {
            nav.Children = [];
            const ds = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
            ds.GetModels().subscribe(models => {
                const list = [];
                list.push({
                    Caption: '@@Uebersicht',
                    ID: 'dm-overview',
                    Icon: 'menu',
                    Index: 0,
                    Content: DataModelSettings
                });
                if (models) {
                    models.forEach((model, i) => {
                        const node = DataModelSettings.CreateModelNode(model);
                        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
                        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
                            node["Children"] = node.Children.filter((child) => child.ID != "multidimensional")
                        }
                        node['Index'] = i + 1;
                        list.push(node);
                    });
                }
                nav.Children = list;
                NavigationService.Refresh.next(null);
            });
        }
    }

    private static CreateModelNode(model) {
        const retVal = {
            Caption: model.Caption,
            ID: model.SID,
            Icon: 'radio_button_unchecked',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 2
            },
            Children: [
                {
                    Caption: '@@Datenquellen',
                    ID: 'datasources',
                    Icon: 'storage',
                    Index: 0,
                    Security: {
                        Name: 'evidanza.App.Shared.Security.DataRight',
                        Value: 4
                    },
                    Content: DataSourceSettings,
                    InitArgs: model
                },
                {
                    Caption: '@@Datenstruktur',
                    ID: 'datastructure',
                    Security: {
                        Name: 'evidanza.App.Shared.Security.DataRight',
                        Value: 8
                    },
                    Icon: 'description',
                    Index: 1,
                    Children: [
                        {
                            Caption: '@@Datenobjekte',
                            ID: 'dataobjects',
                            Icon: 'assignment',
                            Index: 0,
                            Security: {
                                Name: 'evidanza.App.Shared.Security.DataRight',
                                Value: 4096
                            },
                            Content: DataObjectSettings,
                            InitArgs: model
                        },
                        {
                            Caption: '@@Enums',
                            ID: 'enums',
                            Icon: 'list',
                            Index: 1,
                            Security: {
                                Name: 'evidanza.App.Shared.Security.DataRight',
                                Value: 8192
                            },
                            Content: ContainerEnumSettings,
                            InitArgs: model
                        }
                    ]
                }
            ]
        };
        DataModelRegistry.Registry.forEach((child, i) => {
            const regEntry = DataModelSettings.GetRegEntry(model, child);
            regEntry.Index = i + 2;
            retVal.Children.push(regEntry);
        });
        return retVal;
    }

    private static GetRegEntry(model, entry) {
        const retVal = Object.assign({}, entry);
        retVal.ID = entry.ID;
        if (entry.Children) {
            retVal.Children = [];
            entry.Children.forEach((child, i) => {
                const regEntry = DataModelSettings.GetRegEntry(model, child);
                regEntry.Index = i;
                retVal.Children.push(regEntry);
            });
        } else {
            retVal.InitArgs = model;
        }
        return retVal;
    }
}

@Component({
    selector: 'datamodel-detail',
    templateUrl: './datamodel.settings.html',
    styleUrls: ['./datamodel.settings.css']
})
export class DataModelDetail extends BaseListDetail {
    Initialize(InitArgs) {
        if (InitArgs) {
            this.SelectedItem = InitArgs.Data;
        }
    }
    GetDialogResult() {
        return this.SelectedItem;
    }
}

export class DataModelRegistry {
    static Registry = [];
}
