import { LayoutService } from "../../services/layout.service";

export class BasePanel {
    static SIDS: string[] = null;
    static ElementType:string = null;
    static SelectedItem;
    private static SelectedItemInternal;
    static InitSelectedItem() {
        LayoutService.SelectedItem.subscribe((item) => {
            if (this.ElementType) {
                if (item && item.ElementType == this.ElementType) {
                    this.SelectedItem = item;
                    this.SelectedItemInternal = item;
                    this.SetSelectedItem(item);
                } else {
                    this.SelectedItem = null;
                    this.SelectedItemInternal = null;
                    this.SetSelectedItem(null);
                }
            } else {
                this.SelectedItem = item;
                this.SelectedItemInternal = item;
                this.SetSelectedItem(item);
            }
        });
    }
    static InitSelectedObject() {
        LayoutService.SelectedObject.subscribe((item) => {
            if (item) {
                if (this.SelectedItem != item) {
                    if (this.ElementType) {
                        if (item && item.ElementType == this.ElementType) {
                            this.SelectedItem = item;
                            this.SetSelectedItem(item);
                        } else {
                            this.SelectedItem = null;
                            this.SetSelectedItem(null);
                        }
                    } else {
                        this.SelectedItem = item;
                        this.SetSelectedItem(item);
                    }
                }
            } else {
                this.SelectedItem = this.SelectedItemInternal;
                this.SetSelectedItem(this.SelectedItem);
            }
            
        });
    }
    static SetSelectedItem(item) {

    }

    static SelectedLayout;
    static InitSelectedLayout() {
        LayoutService.SelectedLayout.subscribe((layout) => {
            this.SelectedLayout = layout;
            this.SetSelectedLayout(layout);
        });
    }
    static SetSelectedLayout(item) {

    }
    static InitPanel() { }

}