import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { SideNavService } from '../../../services/sidenav.service';


@Component({
    selector: 'evi-navigationtree-item',
    templateUrl: './navigationtree.item.html',
    styleUrls: ['./navigationtree.item.css'],
    animations: [
        trigger('animateRow', [
            transition(':enter', [
                style({ backgroundColor: 'yellow' }),
                animate(300)
            ]),
            transition(':leave', [
                animate(300, style({ backgroundColor: 'yellow' }))
            ]),
            state('*', style({ backgroundColor: 'green' })),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationTreeItem implements OnInit, OnDestroy {

    //#region Item
    ItemValue;

    @Input()
    get Item() {
        return this.ItemValue;
    }
    set Item(val) {
        this.ItemValue = val;
        this.ItemChange.emit(this.ItemValue);
    }

    @Output() ItemChange = new EventEmitter<any>();
    //#endregion

    Subscriptions = {};
    HasVisibleChildren = false;

    ngOnInit(): void {
        this.Subscriptions['ActiveNavigationPermissions'] = PermissionHelper.ActiveNavigationPermissions.subscribe((perm) => {
            if (perm) {
                const actPerm = perm[this.ItemValue.Id];
                if (actPerm != null) {
                    this.ItemValue.InVisible = actPerm;
                } else {
                    this.ItemValue.InVisible = false;
                }
                this.HasVisibleChildren = this.ItemValue.children && this.ItemValue.children.some(x => {
                    if (x.IsVisible) {
                        const childPerm = perm[this.ItemValue.Id];
                        if (childPerm != null) {
                            return !childPerm;
                        }
                        return true;
                    }
                    return false;
                });
            } else {
                this.ItemValue.InVisible = false;
                this.HasVisibleChildren = this.ItemValue.children && this.ItemValue.children.some(x => x.IsVisible);
            }
        });
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }

    //#region ToolTip
    ShowToolTip;
    enter(event: MouseEvent) {
        if (event.target['offsetWidth'] < event.target['scrollWidth']) {
            this.ShowToolTip = true;
        } else {
            this.ShowToolTip = false;
        }
    }
    //#endregion

    closeNav() {
        SideNavService.Close.next(null);
    }
}
