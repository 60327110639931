import { UUID } from 'angular2-uuid';
import { CacheService } from '../../../cache/cache.service';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { FormulaNodeCalculator } from '../../../models/calculation/formula.node.calculator';
import { MultiResult } from '../../../models/datadescription/multi/multiresult.model';
import { WhereDef, WhereSelects } from '../../../models/datadescription/multi/querydef.model';
import { AExecutionManipulation } from './a.execution.manipulation';

export class MasterChildManipulation extends AExecutionManipulation {
    BeforeSplit() {
    }
    async BeforeExecute() {
        if (this.Context && this.Context.BaseReportObject &&
            this.Context.BaseReportObject.LayoutElement && this.Context.QueryDefinitions) {
            const variables = this.Context.BaseReportObject.LayoutElement.MasterChildVariables;
            if (variables) {
                const nameMap = new Map();
                const layout = this.Context.BaseReportObject.Layout;
                const defs = new Map<UUID, WhereDef>();
                let global;
                for (let i = 0; i < variables.length; i++) {
                    const v = variables[i];
                    let variable;
                    if (v.IsGlobal) {
                        if (!global) {
                            global = await CacheService.RefreshGlobalVariableValues();
                        }
                        variable = global.find(x => x.Name === v.Variable);
                    } else {
                        let mapEntry = nameMap.get(v.ElemName);
                        if (!mapEntry) {
                            mapEntry = FilterHelper.GetVariableList(v.ElemName, layout);
                            nameMap.set(v.ElemName, mapEntry);
                        }
                        if (mapEntry.ElemFound) {
                            variable = mapEntry.Variables.find(x => x.Name === v.VariableName);
                        }
                    }
                    if (variable) {
                        const vat = FormulaNodeCalculator.GetVariableValue(variable, false).Value;
                        if (Array.isArray(vat)) {
                            for (let j = 0; j < vat.length; j++) {
                                const ws: WhereSelects = vat[j];
                                const level = await MultiCacheService.GetLevel(ws.LevelId, this.Context.DataDescriptionClone.DataModelID);
                                let def = defs.get(level.Parent.ID);
                                if (def) {
                                    const levFixed = def.FixedMemberIds.find(x => x.LevelId === ws.LevelId);
                                    if (levFixed) {
                                        // TODO: Was wenn Start End???
                                        ws.MemberIds.forEach(x => {
                                            if (levFixed.MemberIds.indexOf(x) < 0) {
                                                levFixed.MemberIds.push(x);
                                            }
                                        });
                                    } else {
                                        def.FixedMemberIds.push(ws);
                                    }
                                } else {
                                    def = new WhereDef();
                                    def.DimensionId = level.Parent.Parent.ID;
                                    def.HierarchyId = level.Parent.ID;
                                    def.FixedMemberIds = [ws];
                                    defs.set(level.Parent.ID, def);
                                }
                            }
                        }
                    }
                }
                if (defs.size > 0) {
                    // -> gewichten ???
                    this.Context.QueryDefinitions.forEach(qd => {
                        // Cube-Abhängigkeit???
                        defs.forEach((def, key) => {
                            const copy = JSON.parse(JSON.stringify(def));
                            if (!qd.Where.some((x, i) => {
                                if (x.HierarchyId === key) {
                                    qd.Where.splice(i, 1, copy);
                                    return true;
                                }
                                return false;
                            })) {
                                qd.Where.push(copy);
                            }
                        });
                    });
                }
            }
        }
    }
    AfterExecute(result: MultiResult) {
    }
}
