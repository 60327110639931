import { Type } from 'class-transformer';
import { Gradient } from '../style/gradient.model';
import { TranslatedString } from '../translatedstring.model';

export class ReportTableColumnSettings {
    DescriptionColumnsWidths: number[];
    MeasuresWidths;
    NonMeasureWidth: number;
}

export class NodeInfo {
    UniqueID: number;
    MemberID: string;
}

export class ReportTableExpandedNodeInfo {
    Path: NodeInfo[] = [];
}

export class ReportTableSettings {

    //#region Aufklappen
    ReExpand = false;
    ReloadOnExpand = true;
    Pivot = false;
    ExpandedYNodes: ReportTableExpandedNodeInfo[] = [];
    //#endregion

    //#region Fixieren
    FixDescriptionColumn = true;
    FixColumns = false;
    FixColumnsValue = 0;
    FixRows = false;
    FixRowsValue = 0;
    //#endregion

    //#region Format
    ShowLines = true;
    HideDescriptionColumnHeader = false;
    HideMeasureHeader = false;
    AutoDescriptionColumnHeader = false;
    HideEmptyRowsAndColumns = false;
    UseAlternatingColors = false;
    FirstAlternatingColor: Gradient;
    SecondAlternatingColor: Gradient;
    CombineCells = false;
    IgnoreHover = false;
    //#endregion

    //#region Size
    OptColumnWidthContent = false;
    OptColumnWidthDescription = false;
    FixColumnHeight = false;
    FixColumnHeightValue = 20;
    FixColumnWidth = false;
    FixColumnWidthValue = 100;
    ColumnSettings: ReportTableColumnSettings;
    //#endregion

    //#region Summen
    ShowOnlySumRow = false;
    ShowSumRowTop = false;
    ShowSumRowBottom = false;
    ShowColumnSum = false;
    ShowRowSum = false;
    @Type(() => TranslatedString)
    SumRowText: TranslatedString;
    //#endregion
}
