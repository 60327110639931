import { Observable, Subject } from 'rxjs';

export class InlineWorker {

    private readonly worker: Worker;
    private onMessage = new Subject<MessageEvent>();
    private onError = new Subject<ErrorEvent>();

    constructor(func) {

        const WORKER_ENABLED = !!(Worker);

        if (WORKER_ENABLED) {
            const functionBody = func.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '');

            this.worker = new Worker(URL.createObjectURL(
                new Blob([functionBody], { type: 'text/javascript' })
            ));

            this.worker.onmessage = (data) => {
                this.onMessage.next(data);
            };

            this.worker.onerror = (data) => {
                this.onError.next(data);
            };

        } else {
            throw new Error('WebWorker is not enabled');
        }
    }

    postMessage(data) {
        this.worker.postMessage(JSON.parse(JSON.stringify(data)));
    }

    onmessage(): Observable<MessageEvent> {
        return this.onMessage.asObservable();
    }

    onerror(): Observable<ErrorEvent> {
        return this.onError.asObservable();
    }

    terminate() {
        if (this.worker) {
            this.worker.terminate();
        }
    }
}