import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { AgentService } from '../../services/agent.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'agent-settings',
    templateUrl: '../base.list.settings.html'
})
export class AgentSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: AgentService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return AgentDetail;
    }

    loadList(handler) {
        this.service.Get(null).subscribe((result) => {
            const list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
    }

    loadData(data) {
        this.service.GetByID(data).subscribe((result) => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        return {
            AgentName: TranslateHelper.TranslatorInstance.instant('@@New agent configuration')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete agent configuration \'{0}\' ?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Agent configuration \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Delete');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Agent configuration \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(sel.AgentName);
        retVal.Title = new TranslateFormatText('@@Save');
        return retVal;
    }

    delete(data, handler) {
        this.service.Delete(data).subscribe((result) => {
            handler(result);
        });
    }

    saveInternal(item, handler) {
        if(item?.AgentName.trim() == '') {
            NotificationHelper.Error('Agent Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.Save(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Agent',
            ID: 'agent',
            Icon: 'schema',
            Index: 40,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 32768
            },
            Content: AgentSettings
        };
    }
}

@Component({
    selector: 'agent-detail',
    templateUrl: './agent.settings.html',
    styleUrls: ['./agent.settings.css']
})
export class AgentDetail extends BaseListDetail {
    Rows = [];

    constructor(private service: AgentService) {
        super();
    }

    setSelectedItem(item) {
        this.Rows = [];
        if (item && item.SID) {
            this.service.GetLogs(item.SID).subscribe(x => {
                if (x) {
                    this.Rows = x;
                    this.cdRef.detectChanges();
                }
            });
        }
        super.setSelectedItem(item);
    }

    DownloadConfig() {
        this.service.GetConfigFile(this.SelectedItem.SID).subscribe(x => {
            if (x) {
                var dataStr = "data:text/json;charset=UTF-8," + encodeURIComponent(x);
                var dlAnchorElem = document.getElementById('downloadAnchorElem');
                dlAnchorElem.setAttribute("href", dataStr);
                dlAnchorElem.setAttribute("download", "config_" + this.SelectedItem.AgentName + ".json");
                dlAnchorElem.click();
            }
        });      
    }
}
