import { Component } from '@angular/core';
import { ChartDataSource } from '../../../models/datasource/chart.datasource';
import { MultidimensionalChartDataSource } from '../../../models/datasource/chart.multidimensional.datasource';
import { RelationalChartDataSource } from '../../../models/datasource/chart.relational.datasource';
import { AxisTextPosition } from '../../../models/enums/axistextposition.enum';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'chart-reportobject',
    templateUrl: './chart.reportobject.html',
    styleUrls: ['./chart.reportobject.css']
})
export class ChartReportObject extends IBaseComponent {
    static Type = 'chartreportObject';
    static Default = {
        Caption: 'Charts', Type: 'chartreportObject', Layout:
            { vAxisTextPosition: AxisTextPosition.out, hAxisTextPosition: AxisTextPosition.out }
    };

    Loading = true;
    PageInitialized = false;
    DisableAutoLoad = false;
    EmptyQuery = false;
    //#region Data
    Source: ChartDataSource;
    ChartData = [];
    ChartSettings;
    Data;
    //#endregion

    ControlInitialized() {
        if (this.LayoutElementValue) {
            this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        }
        if (this.LayoutValue && this.LayoutValue.Initialized) {
            this.Subscriptions['pageInitialized'] = this.LayoutValue.Initialized.subscribe((x) => {
                if (x) {
                    this.PageInitialized = true;
                    this.InitializeDataSource();
                }
            });
        }
    }

    ChartPropChanged(prop) {
        try {
            if (this.Source && this.LayoutElementValue.ChartSettings) {
                this.Source.UpdateChart(this.LayoutElementValue.ChartSettings);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    onLayoutElementSet() {
        let data;
        if (this.LayoutElementValue && this.LayoutElementValue.Query && this.Layout &&
            this.Layout.Datadescriptions && this.Layout.Datadescriptions.length > 0) {
            data = this.Layout.Datadescriptions.find((dd) => dd.ID === this.LayoutElementValue.Query);
        }
        this.Data = data;
        this.InitializeDataSource();
    }

    onLayoutElementChanged() {
        if (this.LayoutElementValue && this.Data && this.LayoutElementValue.Query != this.Data.ID) {
            setTimeout(() => { this.onLayoutElementSet(); }, 10);
        } else {
            setTimeout(() => { this.InitializeDataSource(); }, 10);
        }
    }

    OnDataBindingChanged() {
        if (this.LayoutElementValue && this.LayoutElementValue.ChartSettings) {
            this.LayoutElementValue.ChartSettings.Series = [];
        }
        super.OnDataBindingChanged();
    }

    InitializeDataSource() {
        const loadingSub = this.Subscriptions['DSLoading'];
        if (loadingSub) {
            loadingSub.unsubscribe();
            delete this.Subscriptions['DSLoading'];
        }
        const dataSub = this.Subscriptions['DSData'];
        if (dataSub) {
            dataSub.unsubscribe();
            delete this.Subscriptions['DSData'];
        }
        if (this.Data && this.PageInitialized) {
            this.Source = this.Data.IsRelational ? new RelationalChartDataSource(this) : new MultidimensionalChartDataSource(this);
            this.Subscriptions['DSLoading'] = this.Source.Loading.subscribe((res) => {
                this.Loading = res;
                this.cdRef.detectChanges();
            });
            this.Subscriptions['DSData'] = this.Source.Data.subscribe((res) => {
                if (res && res.Data && res.Settings) {
                    console.log('Chart Data', res)
                    this.ChartData = res.Data;
                    this.ChartSettings = res.Settings;
                    this.EmptyQuery = false;
                } else {
                    this.ChartData = null;
                    this.ChartSettings = null;
                    this.EmptyQuery = true;
                }
                this.cdRef.detectChanges();
            });
            if (this.DisableAutoLoad) {
                this.DisableAutoLoad = false;
            } else {
                this.ExecuteRefresh();
            }
        } else {
            this.Source = null;
            this.ChartData = null;
            this.ChartSettings = null;
        }
    }

    ExecuteRefresh() {
        if (this.Source && this.LayoutElementValue.ChartSettings) {
            this.Source.Refresh(this.LayoutElementValue.ChartSettings);
        }
    }
}
