import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogHelper } from '../../../helpers/injector.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { RTLHelper } from '../../../helpers/rtl.helper';

@Component({
    selector: 'messagebox',
    templateUrl: 'messagebox.dialog.html',
    styleUrls: ['./messagebox.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBox {

    DialogData;

    constructor(dialogRef: MatDialogRef<MessageBox>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.DialogData = data.Data;
        dialogRef.disableClose = data.Data && data.Data.Buttons !== MessageBoxButtons.None;
    }
}

export class MessageBoxHelper {
    static async ShowDialog(message: TranslateFormatText, title?: TranslateFormatText, buttons?: MessageBoxButtons, icon?: MessageBoxIcon,
        width?: number, height?: number, customButtons?: MessageBoxCustomButton[]): Promise<MessageBoxResult> {
        const config = new MatDialogConfig();
        if (!title) {
            title = new TranslateFormatText('evidanza MaC');
        }
        config.data = {
            Data: {
                Message: message,
                Title: title,
                Buttons: (typeof buttons === 'number') ? buttons : MessageBoxButtons.OkCancel,
                Icon: icon || MessageBoxIcon.None,
                CustomButtons: customButtons || []
            }
        };
        if (typeof width === 'number') {
            config.width = width + 'px';
        } else {
            config.width = '400px';
        }
        if (typeof height === 'number') {
            config.height = height + 'px';
        }
        config.maxWidth = '80vw';
        config.maxHeight = '80vh';
        config.direction = RTLHelper.Direction == 'rtl' ? 'rtl' : 'ltr';
        const dialogRef = DialogHelper.DialogInstance.open(MessageBox, config);
        const retVal = await dialogRef.afterClosed().toPromise();
        return retVal;
    }
}

export interface MessageBoxCustomButton {
    Caption: string;
    ID: number;
}

