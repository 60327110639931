import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { LayoutService } from '../../services/layout.service';
import { TranslationService } from '../../services/translation.service';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { DataCheck } from '../../workflow/workflow.dialog';

@Component({
    selector: 'translations-settings',
    templateUrl: './translations.settings.html',
    styleUrls: ['./translations.settings.css']
})
export class TranslationsSettings implements AfterViewInit {

    SearchValue;
    ListItems = [];
    FilteredItems = [];
    HasChanges = false;
    SelectedKey;
    SelectedItem;
    ShowMissing = false;

    toggleWindow = true;

    static GetSettingsEntry() {
        return {
            Caption: '@@Uebersetzungen',
            ID: 'translations',
            Icon: 'translate',
            Index: 4,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 32
            },
            Content: TranslationsSettings
        };
    }

    constructor(private service: TranslationService, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.refresh();
    }

    UpdateFiltered() {
        let list = [];
        if (this.ListItems) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.ListItems);
            }
        }
        this.FilteredItems = list;
    }

    resetSearch() {
        this.SearchValue = null;
        this.UpdateFiltered();
    }

    onItemClick(data) {
        if (this.SelectedKey !== data) {
            this.checkHasChanges().then(x => {
                if (x) {
                    this.SelectedKey = data;
                    this.setSelectedItem(null);
                    this.service.GetTranslationsForKey(data).subscribe(result => {
                        this.setSelectedItem(result);
                    });
                }
            });
        }
    }

    saveClick() {
        this.save(true);
        this.ToggleWindow()
    }

    addItem() {
        this.checkHasChanges().then(x => {
            if (x) {
                this.SelectedKey = null;
                this.setSelectedItem({
                    Key: '',
                    Translations: [],
                    IsNew: true
                });
            }
        });
    }

    refresh() {
        this.checkHasChanges().then(x => {
            if (x) {
                LayoutService.Loading.next(true);
                this.refreshInternal();
            }
        });
    }

    private refreshInternal() {
        this.SearchValue = null;
        this.ListItems = [];
        this.FilteredItems = [];
        this.setSelectedItem(null);
        (
            this.ShowMissing ?
            this.service.GetTranslationKeysWithMissingTranslations() :
            this.service.GetAllTranslationKeys()
        ).subscribe(keys => {
            if (keys) {
                ArrayHelpers.sortAlphabetical(keys);
                this.ListItems = keys;
                this.UpdateFiltered();
            }
            LayoutService.Loading.next(false);
            this.cdRef.detectChanges();
        });
    }

    async deleteItem(item: any) {
        if (item) {
            await this.onItemClick(item);
        }
        const sel = this.SelectedKey;
        if (sel) {
            const question = new TranslateFormatText('@@Are you sure you want to delete the translation \'{0}\'?');
            question.FormatParams.push(sel);
            MessageBoxHelper.ShowDialog(question, new TranslateFormatText('@@Uebersetzung loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        this.SelectedKey = null;
                        this.setSelectedItem(null);
                        LayoutService.Loading.next(true);
                        this.service.DeleteTranslation(sel).subscribe(() => {
                            this.setHasChanges(false);
                            this.refreshInternal();
                            const success = new TranslateFormatText('@@Translation \'{0}\' successfully deleted.');
                            success.FormatParams.push(sel);
                            NotificationHelper.Success(success, new TranslateFormatText('@@Uebersetzung loeschen'));
                        });
                    }
                });
        }
    }

    checkHasChanges() {
        return new Promise<boolean>(resolve => {
            if (this.HasChanges) {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Do you want to save the changes?'),
                    new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question).then(retVal => {
                        if (retVal === MessageBoxResult.Yes) {
                            this.save(false).then(x => resolve(x));
                        } else if (retVal === MessageBoxResult.Abort) {
                            resolve(false);
                        } else { // No
                            this.setHasChanges(false);
                            resolve(true);
                        }
                    });
            } else {
                resolve(true);
            }
        });
    }

    setHasChanges(hasChanges: boolean) {
        this.HasChanges = hasChanges;
        LayoutService.PreventBrowserClose.next(this.HasChanges);
    }

    save(fromSaveButton: boolean) {
        return new Promise<boolean>(resolve => {
            LayoutService.Loading.next(true);
            this.checkCanSave().then(x => {
                if (x.IsCorrect) {
                    const item = this.SelectedItem;
                    const saveVal = {
                        Key: item.Key,
                        CapsuleTag: item.CapsuleTag,
                        Translations: []
                    };
                    if (item.Translations) {
                        item.Translations.forEach(t => {
                            if (t.Changed === true) {
                                saveVal.Translations.push({
                                    LCID: t.LCID,
                                    Value: t.Value
                                });
                            }
                            delete t.Changed;
                        });
                    }
                    this.service.SaveTranslationForKey(saveVal).subscribe(() => {
                        NotificationHelper.Info('@@The new translations will only be displayed after the page has been refreshed.',
                            '@@Aktualisieren');
                        this.setHasChanges(false);
                        LayoutService.Loading.next(false);
                        const text = new TranslateFormatText('@@Translation \'{0}\' saved successfully.');
                        text.FormatParams.push(item.Key);
                        NotificationHelper.Success(text, new TranslateFormatText('@@Save translation'));
                        if (item.IsNew) {
                            delete item.IsNew;
                            this.ListItems.push(item.Key);
                            this.UpdateFiltered();
                        }
                        if (fromSaveButton) {
                            this.SelectedKey = item.Key;
                        }
                        resolve(true);
                    });
                } else {
                    const t = '@@Changes could not be saved. Please check the following settings:\n{0}';
                    const text = new TranslateFormatText(t);
                    text.FormatParams.push(x.Error);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Warning'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                    LayoutService.Loading.next(false);
                    resolve(false);
                }
            });
        });
    }

    checkCanSave() {
        return new Promise<DataCheck>(resolve => {
            if (this.SelectedItem) {
                if (this.SelectedItem.Key) {
                    if (this.SelectedItem.IsNew) {
                        this.service.GetTranslationsForKey(this.SelectedItem.Key).subscribe(x => {
                            const retVal = new DataCheck();
                            if (x && x.Translations && x.Translations.length > 0) {
                                retVal.Error = 'Key already exists. Please assign a different key.';
                                retVal.IsCorrect = false;
                            }
                            resolve(retVal);
                        });
                    } else {
                        resolve(new DataCheck());
                    }
                } else {
                    const retVal = new DataCheck();
                    retVal.Error = '@@Please assign a key.';
                    retVal.IsCorrect = false;
                    resolve(retVal);
                }
            } else {
                const retVal = new DataCheck();
                retVal.Error = '@@No object selected.';
                retVal.IsCorrect = false;
                resolve(retVal);
            }
        });
    }

    setSelectedItem(item) {
        this.SelectedItem = item;
        this.cdRef.detectChanges();
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
