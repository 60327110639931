import { Component, Inject, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GitHelper } from 'src/app/helpers/git.helper';
import { PermissionHelper } from 'src/app/helpers/permissions.helper';
import { LayoutService } from 'src/app/services/layout.service';
import { MediaService } from 'src/app/services/media.service';
import { UsersService } from 'src/app/services/users.service';
import { AboutControl } from 'src/app/components/common/aboutcontrol/about.control';
import { Theme } from 'src/app/models/style/theme.model';
import { ThemeHelper } from 'src/app/helpers/theme.helpers';
4;
import { MetaService } from 'src/app/services/meta.service';
import { Router } from '@angular/router';
// import { IBaseComponent } from '../base.component';
// import { PROPERTIES, PROPERTYGROUPS } from 'src/app/services/dynamic.component.service';

@Component({
	selector: 'lib-user-menu',
	templateUrl: './usermenu.component.html',
	styleUrls: ['./header.component.scss'],
})
export class UserMenuControl {
	static Type: any = 'usermenu';
	Subscriptions = {};
	static Default = {
		Editable: true,
		Type: 'usermenu',
		Variant: 'named',
	};
	CurrentTheme: Theme;
	IsChecked: boolean;
	user;
	AllowImpersonate = false;
	URL: string;
	GitActive = false;
	Branches = [];
	SelectedBranch;
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	constructor(
		private metaService: MetaService,
		private usersService: UsersService,
		@Inject(LayoutService.CONTAINER_DATA) public data,
		private router: Router
	) {}

	// @Output() VariantChange = new EventEmitter<any>();

	ngOnInit() {
		this.Subscriptions['Theme'] = this.metaService
			.GetDefaultTheme()
			.subscribe((theme: Theme) => {
				this.CurrentTheme = theme;
				if (theme.IsDarkTheme) {
					this.IsChecked = true;
				}
			});
		this.GitActive = GitHelper.IsActive;
		if (this.GitActive) {
			this.Branches = GitHelper.LocalBranches;
			this.Subscriptions['BranchesChanged'] =
				GitHelper.BranchesChanged.subscribe((branches) => {
					this.Branches = GitHelper.LocalBranches;
				});
			this.SelectedBranch = GitHelper.SelectedBranch;
		}
		this.user = localStorage.getItem('user');
		if (this.user) {
			this.user = JSON.parse(this.user);
			this.updateImage();
		}
		this.AllowImpersonate = PermissionHelper.GetImpersonatePermission();
	}

	SelectedBranchChanged() {
		GitHelper.SelectedBranch.next(this.SelectedBranch);
	}

	logout() {
		this.usersService.logout();
		this.trigger.closeMenu();
	}

	getInitials() {
		if (!this.user) return '';
		const { FirstName, LastName } = this.user;
		let initials = '';
		// Handle case when FirstName exists
		if (FirstName && FirstName.length > 0) {
			initials += FirstName[0]; // Always add the first character of FirstName
			if (!LastName || (LastName.length === 1 && LastName[0] === ' ')) {
				const firstNameParts = FirstName.split(' ');
				if (firstNameParts.length > 1 && firstNameParts[1].length > 0) {
					initials += firstNameParts[1][0];
				} else if (FirstName.length > 1) {
					initials += FirstName[1];
				}
			}
		}
		if (initials.length < 2 && LastName && LastName.length > 0) {
			initials += LastName[0];
		}
		return initials.padEnd(2, 'X');
	}
	updateImage() {
		if (this.user && this.user.Image) {
			const url = MediaService.ProfilePicURL.getValue();
			if (url) {
				this.URL = url + this.user.SID + '/' + this.user.Image;
			}
		}
	}

	languageSelected() {
		// const text = '@@Bitte denken Sie daran auch die Spracheneinstellung Ihres Browsers anzupassen. Einige Controls (z.B.DatetimePicker) und Formatierungen verhalten sich sonst moeglicherweise nicht wie erwartet.';
		// MessageBoxHelper.ShowDialog(new TranslateFormatText(text), null, MessageBoxButtons.Ok, MessageBoxIcon.Information);
	}

	OpenInfoDialog() {
		AboutControl.ShowDialog();
		this.trigger.closeMenu();
	}

	setDarkTheme(isDark: boolean) {
		const darkTheme: Theme = this.CurrentTheme;
		darkTheme.IsDarkTheme = isDark;
		this.metaService.SaveTheme(darkTheme).subscribe((result) => {
			ThemeHelper.SetTheme(darkTheme);
		});
	}
	changeThemeMode() {
		if (this.IsChecked) {
			this.setDarkTheme(true);
		} else {
			this.setDarkTheme(false);
		}
	}

	onProfileSetting() {
		this.router.navigate(['default/settings/personal-settings']);
		this.trigger.closeMenu();
	}
}
