import { Component } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TaskRegistry, TaskType } from '../../helpers/task.registry';
import { DialogButton } from '../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { CalculateTaskExecuter } from '../../models/tasks/datatasks/calculate.model';
import { BaseDialog } from '../dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'new-task-dialog',
    templateUrl: './new.task.dialog.html'
})
export class NewTaskDialog {
    AllTasks = [];
    SelectedTask;
    IgnoreList = [CalculateTaskExecuter.TaskID];

    static ShowDialog(taskType: TaskType, reportObject, handler) {
        BaseDialog.ShowDialog({
            ContentType: NewTaskDialog,
            InitArgs: {
                TaskType: taskType,
                ReportObject: reportObject
            },
            Title: '@@Neuer Task',
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            }
        });
    }

    Initialize(args) {
        if (args) {
            const taskList = [];
            TaskRegistry.forEach(v => {
                if (v.TaskType === args.TaskType && !this.IgnoreList.some(x => x === v.ID) &&
                    (!args.ReportObject || (v.SettingsHelper && v.SettingsHelper.canExecute(args.ReportObject)))) {
                    taskList.push({
                        Caption: v.Caption,
                        Value: v.ID
                    });
                }
            });
            this.AllTasks = taskList;
        }
    }

    GetDialogResult() {
        return this.SelectedTask;
    }

    OnDialogButtonClickAction(button) {
        if (button === DialogButton.Ok) {
            if (!this.SelectedTask) {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Neuer Task'),
                    new TranslateFormatText('@@Bitte waehlen Sie einen Task aus.'),
                    MessageBoxButtons.Ok, MessageBoxIcon.Information);
                return false;
            }
        }
        return true;
    }
}
