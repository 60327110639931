import { HttpClient } from '@angular/common/http';
import { InjectorHelper } from '../../helpers/injector.helper';
import { RelQueryHelper } from '../../helpers/relquery.helper';
import { ReportingService } from '../../services/reporting.service';
import { Column } from '../basic/column.model';
import { LayoutUnit } from '../basic/layoutunit.model';
import { Row } from '../basic/row.model';
import { VisibilityType } from '../enums/oc.enum';
import { PagingType } from '../enums/pagingtype.enum';
import { UnitType } from '../enums/unittype.enum';
import { ReportTableSettings } from '../reportobjects/reporttablesettings.model';
import { Border } from '../style/border.model';
import { Gradient } from '../style/gradient.model';
import { CellStyle } from '../styling/cell.style';
import { StyleMerger } from '../styling/styleMerger';
import { TranslatedString } from '../translatedstring.model';
import { TableDataSource } from './chart.datasource';

export class RelationalTableDataSource extends TableDataSource {

    public UseCaching;
    public PagingType: PagingType = PagingType.Scroll;
    private DataService: ReportingService;

    constructor(protected reportObject) {
        super(reportObject);
        const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
        this.DataService = new ReportingService(http);
    }

    public Refresh() {
        this.fetchedPages = new Set<number>();
        this.Initialized.next(true);
        this.Loading.next(true);
        if (this.reportObject.LayoutElement.TableSettings == null) {
            this.reportObject.LayoutElement.TableSettings = new ReportTableSettings();
        }
        const tableSettings: ReportTableSettings = this.reportObject.LayoutElement.TableSettings;
        const dd = this.reportObject.DataDescription;
        const query = RelQueryHelper.GenerateQuery(dd, this.reportObject);
        this.DataService.ExecuteQuery(query).subscribe((result) => {
            const columns = [];
            const hiddenCols = {};
            if (dd && dd.Fields && dd.Fields.length > 0) {
                dd.Fields.forEach((field) => {
                    if (typeof field.VisibilityType === 'number') {
                        switch (field.VisibilityType) {
                            case VisibilityType.NonVisibleDefault:
                            case VisibilityType.NonVisibleForce:
                                return;
                        }
                    }
                    const width = new LayoutUnit();
                    width.Type = UnitType.Percent;
                    let caption = TranslatedString.GetTranslation(field.TranslatedCaption);
                    if (!caption) {
                        caption = field.Caption
                    };
                    columns.push({
                        Caption: caption,
                        Name: field.ID,
                        Width: width
                    });
                    hiddenCols[field.ID] = true;
                });
            }
            const rows = [];
            if (result) {
                this.FilteredCount = result.length;
                if (result) {
                    let tabIndex = 0;
                    result.forEach((item, i) => {
                        const cols = {};
                        columns.forEach((column) => {
                            cols[column.Name] = {
                                selected: false,
                                edit: false,
                                tabindex: tabIndex++,
                                editable: false
                            };
                            if (item) {
                                const val = item[column.Name];
                                if (val != null && val !== '') {
                                    delete hiddenCols[column.Name];
                                }
                            }
                        });
                        const row = new Row();
                        row.data = item;
                        row.cols = cols;
                        row.index = i;
                        if (tableSettings.UseAlternatingColors) {
                            row.rowStyle = Gradient.getStyleObject(i % 2 == 0 ? tableSettings.FirstAlternatingColor : tableSettings.SecondAlternatingColor);
                        }
                        rows.push(row);
                    });
                }
            }
            if (tableSettings.HideEmptyRowsAndColumns) {
                for (let i = columns.length - 1; i >= 0; i--) {
                    if (hiddenCols[columns[i].Name] === true) {
                        columns.splice(i, 1);
                    }
                }
            }
            let fixedColCount = 0;
            if (tableSettings.FixColumns) {
                fixedColCount = tableSettings.FixColumnsValue;
            }
            if (tableSettings.FixColumnWidth) {
                columns.forEach((col,i) => {
                    col.Width.Type = UnitType.Pixel;
                    col.Width.Value = tableSettings.FixColumnWidthValue;
                    col.IsSticky = i < fixedColCount;
                });
            } else {
                const widthVal = 100 / columns.length;
                columns.forEach((col,i) => {
                    col.Width.Value = widthVal;
                    col.IsSticky = i < fixedColCount;
                });
            }
            this.FormatResult(columns, rows).then(cellStyles => {
                let fixedRows = null;
                if (tableSettings.FixRows && tableSettings.FixRowsValue > 0) {
                    fixedRows = rows.splice(0, tableSettings.FixRowsValue);
                }
                this.PagedData.next(rows);
                this.cachedData = rows;
                this.dataStream.next(this.cachedData);
                this.FixedRows.next(fixedRows);
                this.Columns.next(columns);
                this.CellStyles.next(cellStyles);
                this.Loading.next(false);
            });
        });
    }

    private async FormatResult(columns: Column[], rows: Row[]) {
        const styleMerger = new StyleMerger();

        const styleMatrix = {};

        // Default Border
        if (this.reportObject && this.reportObject.LayoutElement) {
            const tableSettings = this.reportObject.LayoutElement.TableSettings;
            if (tableSettings && tableSettings.ShowLines === true) {
                const borderStyle = new CellStyle();
                borderStyle.Border = new Border();
                borderStyle.Border.RightBorder = StyleMerger.GetBorder(1, 232, 232, 232);
                borderStyle.Border.BottomBorder = StyleMerger.GetBorder(1, 232, 232, 232);

                const defaultCellStyle = styleMerger.MergeStyle(0, borderStyle);

                for (let i = 0; i < rows.length; i++) {
                    const styles = {};
                    columns.forEach(col => {
                        styles[col.Name] = defaultCellStyle;
                    });
                    styleMatrix[i] = styles;
                }
            }
        }

        const headerLeaveStyle = new CellStyle();
        headerLeaveStyle.Border = new Border();
        headerLeaveStyle.Border.BottomBorder = StyleMerger.GetBorder(2, 127, 127, 127);
        const styleID = styleMerger.MergeStyle(0, headerLeaveStyle);
        columns.forEach(col => {
            col['StyleID'] = styleID;
        });

        rows.forEach((row, i) => {
            const styles = styleMatrix[i];
            if (styles) {
                Object.keys(styles).forEach(k => {
                    styles[k] = '' + styles[k];
                });
                row.styles = styles;
            }
        });

        return styleMerger.GetStyleObject();
    }

    GetSelectedCoordinatesFromRowSelection(selection: import("../basic/selection.model").RowSelection[]): any[] {
        throw new Error('Method not implemented.');
    }
    GetSelectedCoordinatesFromCellSelection(selection: import("../basic/selection.model").CellSelection[]): any[] {
        throw new Error('Method not implemented.');
    }
}
