export enum ConditionOperator {
    Equal = 1,
    NonEqual = 2,
    Between = 3,
    NotBetween = 4,
    Less = 5,
    Greater = 6,
    GreatorOrEqual = 7,
    LessOrEqual = 8,
    IsNull = 9,
    NotNull = 10
}