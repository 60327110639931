import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'oracledb-panel',
    templateUrl: './oracledb.panel.html',
    styleUrls: ['./oracledb.panel.css']
})
export class OracleDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = OracleDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: OracleDBPanel,
            DBInfoType: OracleDBInfo
        };
    }
}

export class OracleDBInfo extends ADBInfo {
    Server: string = null;
    Port = 1521;
    Database: string = null;
    Username: string = null;
    Password: string = null;
    SSPI = false;
    //HasConnectionString = true;
    //ConnectionString: string;

    getTypeName(): string {
        return 'evidanza.Database.Oracle.OracleDBInfo';
    }
}