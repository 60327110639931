import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { TableDataSource } from '../../../models/datasource/chart.datasource';
import { MultiDimensionalTableDataSource } from '../../../models/datasource/table.multidimensional.datasource';
import { RelationalTableDataSource } from '../../../models/datasource/table.relational.datasource';
import { AxisType } from '../../../models/enums/query.enum';
import { TableStyle } from '../../../models/enums/tablestyle.enum';
import { ReportTableColumnSettings, ReportTableSettings } from '../../../models/reportobjects/reporttablesettings.model';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-mactable',
    templateUrl: './table.reportobject.html',
    styleUrls: ['./table.reportobject.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class MacTableControl extends IBaseComponent {

    static Type: any = 'tablereportObject';
    static Default = { Type: 'tablereportObject' };
    //#region Properties
    initialized = false;
    ViewInitialized = false;
    PageInitialized = false;
    DisableAutoLoad = false;
    EmptyQuery = false;
    //#region Data
    Source: TableDataSource;
    FixedRows;
    //#region DataDescription
    DataDescriptionValue;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(val) {
        this.DataDescriptionValue = val;
        if (!this.DataValue.IsRelational) {
            this.DataDescriptionValue = plainToClass(DataDescription, this.DataDescription);
        }
        this.InitializeDataSource();
        this.DataDescriptionChange.emit(this.DataDescriptionValue);
    }

    @Output() DataDescriptionChange = new EventEmitter<any>();
    //#endregion
    //#region Data
    DataValue;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        this.DataValue = val;
        if (val && val.Datadescription) {
            this.DataDescription = val.Datadescription;
        }
        this.DataChange.emit(this.DataValue);
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion
    //#endregion

    //#region ColumnsExpandable
    get ColumnsExpandable() {
        if (this.LayoutElementValue && this.LayoutElementValue.TableSettings) {
            return this.LayoutElementValue.TableSettings.Pivot === true;
        }
        return false;
    }
    //#endregion

    get LineHeight() {
        if (this.LayoutElementValue && this.LayoutElementValue.TableSettings &&
            this.LayoutElementValue.TableSettings.FixColumnHeight === true) {
            return this.LayoutElementValue.TableSettings.FixColumnHeightValue;
        }
        return null;
    }

    //#region IsPreview
    IsPreviewValue = false;
    @Input()
    get IsPreview() {
        return this.IsPreviewValue;
    }
    set IsPreview(val) {
        this.IsPreviewValue = val;
        this.IsPreviewChange.emit(this.IsPreviewValue);
    }
    @Output() IsPreviewChange = new EventEmitter<any>();
    //#endregion

    get ShowHeader() {
        if (this.LayoutElementValue && this.LayoutElementValue.TableSettings) {
            return this.LayoutElementValue.TableSettings.HideDescriptionColumnHeader !== true;
        } else {
            return true;
        }
    }
    get IgnoreHover() {
        if (this.LayoutElementValue && this.LayoutElementValue.TableSettings) {
            return this.LayoutElementValue.TableSettings.IgnoreHover == true;
        } else {
            return false;
        }
    }
    CellStyles = {};
    RowSelection = [];
    CellSelection = [];
    Columns;
    IsRtl = false;
    //#endregion

    //#region LifeCycle
    constructor(private cdref: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
        // this.EventList.push('RowSelectionChanged');
        this.EventList.push('CellSelectionChanged');
        this.EventList.push('ValueChanged');
        this.EventList.push('OnFocus');
        this.EventList.push('Click');
        this.EventList.push('DoubleClick');
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
    }

    onLayoutElementSet() {
        this.initialized = false;
        if (this.LayoutElement && this.LayoutElement.Query && this.Layout && this.Layout.Datadescriptions) {
            this.Layout.Datadescriptions.some((dd) => {
                if (dd.ID === this.LayoutElement.Query) {
                    this.Data = dd;
                    return true;
                }
                return false;
            });
        }
    }

    onLayoutElementChanged() {
        this.initialized = false;
        setTimeout(() => { this.InitializeDataSource(); }, 10);
    }

    ControlInitialized() {
        if (this.LayoutElementValue) {
            this.DisableAutoLoad = this.LayoutElementValue.DisableAutoLoad;
        }
        if (this.LayoutValue && this.LayoutValue.Initialized) {
            this.Subscriptions['pageInitialized'] = this.LayoutValue.Initialized.subscribe((x) => {
                if (x) {
                    this.PageInitialized = true;
                    this.InitializeDataSource();
                }
            });
        }
    }

    InitializeDataSource() {
        const initSub = this.Subscriptions['Initialized'];
        if (initSub) {
            initSub.unsubscribe();
            delete this.Subscriptions['Initialized'];
        }
        const pdSub = this.Subscriptions['PagedData'];
        if (pdSub) {
            pdSub.unsubscribe();
            delete this.Subscriptions['PagedData'];
        }
        const colSub = this.Subscriptions['columns'];
        if (colSub) {
            colSub.unsubscribe();
            delete this.Subscriptions['columns'];
        }
        const styleSub = this.Subscriptions['cellStyles'];
        if (styleSub) {
            styleSub.unsubscribe();
            delete this.Subscriptions['cellStyles'];
        }
        const fixedSub = this.Subscriptions['fixedRows'];
        if (fixedSub) {
            fixedSub.unsubscribe();
            delete this.Subscriptions['fixedRows'];
        }
        if (this.DataValue && (this.PageInitialized || this.IsPreviewValue)) {
            if (this.DataValue.IsRelational) {
                this.Source = new RelationalTableDataSource(this);
            } else {
                this.DataDescription.DataModelID = this.DataValue.DataModelID;
                this.Source = new MultiDimensionalTableDataSource(this);
            }
            this.Subscriptions['columns'] = this.Source.Columns.subscribe((res) => {
                this.Columns = res;
                this.cdRef.detectChanges();
            });
            this.Subscriptions['fixedRows'] = this.Source.FixedRows.subscribe((res) => {
                this.FixedRows = res;
            });
            this.Subscriptions['cellStyles'] = this.Source.CellStyles.subscribe((res) => {
                this.CellStyles = res;
            });
            this.Subscriptions['Initialized'] = this.Source.Initialized.subscribe((res) => {
                if (res != this.initialized) {
                    this.initialized = res;
                    this.cdRef.detectChanges();
                }
            });
            this.Subscriptions['PagedData'] = this.Source.PagedData.subscribe((res) => {
                if (res && res.length > 0) {
                    this.EmptyQuery = false;
                } else {
                    this.EmptyQuery = true;
                }
            });
            if (this.DisableAutoLoad) {
                this.DisableAutoLoad = false;
            } else {
                this.Source.Refresh();
            }
        }
    }

    OnDataBindingChanged() {
        window.setTimeout(() => {
            this.onLayoutElementSet();
        }, 200);
    }

    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        this.cdref.detectChanges();
        super.ngAfterViewInit();
    }
    //#endregion
    //#region Actions
    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
        this.triggerEvent('Click', this);
    }
    doubleClicked() {
        this.triggerEvent('DoubleClick', this);
    }
    RowSelectionChanged(item) {
        this.RowSelection = item;
        this.triggerEvent('RowSelectionChanged', item);
    }
    CellSelectionChanged(item) {
        this.CellSelection = item;
        this.triggerEvent('CellSelectionChanged', item);
    }
    ValueChanged(item) {
        this.triggerEvent('ValueChanged', item);
    }
    SortChanged(sort) {
        this.ExecuteRefresh();
    }
    FilterChanged(filter) {
        this.ExecuteRefresh();
    }
    ExecuteRefresh() {
        if (this.Source) {
            this.Source.Refresh();
        }
    }
    onColumnExpanded(col) {
        if (col && this.LayoutElementValue && this.Columns && this.DataValue && !this.DataValue.IsRelational) {
            if (!this.LayoutElementValue.TableSettings) {
                this.LayoutElementValue.TableSettings = new ReportTableSettings();
            }
            if (!this.LayoutElementValue.TableSettings.ColumnSettings) {
                this.LayoutElementValue.TableSettings.ColumnSettings = new ReportTableColumnSettings();
            }
            const colSettings = this.LayoutElementValue.TableSettings.ColumnSettings;
            if (col.Name.startsWith('descColumn_')) { // Beschreibungsspalte
                colSettings.DescriptionColumnsWidths = [];
                this.Columns.some(c => {
                    if (c.Name.startsWith('descColumn_')) {
                        colSettings.DescriptionColumnsWidths.push(c.CalculatedWidth);
                        return false;
                    }
                    return true;
                });
            } else {
                let update = false;
                const leaves = MultiDimensionalTableDataSource.GetFixedChildrenLeaves(col);
                if (this.DataDescriptionValue && this.DataDescriptionValue.ShowMeasureOnAxis === AxisType.X_Axis) {
                    if (!colSettings.MeasuresWidths) {
                        colSettings.MeasuresWidths = {};
                    }
                    leaves.forEach(l => {
                        const id = l['MeasureUniqueID'];
                        if (typeof id === 'number') {
                            colSettings.MeasuresWidths[id] = l['CalculatedWidth'];
                            update = true;
                        }
                    });
                } else {
                    colSettings.NonMeasureWidth = leaves[0]['CalculatedWidth'];
                    update = true;
                }
                if (update) {
                    const noDesc = [];
                    this.Columns.forEach(c => {
                        if (!c.Name.startsWith('descColumn_')) {
                            noDesc.push(c);
                        }
                    });
                    let colWidth = 100;
                    if (this.LayoutElementValue.TableSettings.FixColumnWidth) {
                        colWidth = this.LayoutElementValue.TableSettings.FixColumnWidthValue;
                    }
                    MultiDimensionalTableDataSource.SetWidthOnLeaves(noDesc, colWidth, colSettings);
                }
            }
        }
    }
    //#endregion

    GetSelectedCoordinates() {
        if (this.Source) {
            if (this.LayoutElement.TableStyle === TableStyle.DataTable) {
                return this.Source.GetSelectedCoordinatesFromRowSelection(this.RowSelection);
            } else {
                return this.Source.GetSelectedCoordinatesFromCellSelection(this.CellSelection);
            }
        }
        return [];
    }

    IgnoreIntersect = false;
    BeforeScreenShot() {
        this.IgnoreIntersect = true;
        this.cdref.detectChanges();
    }
    AfterScreenShot() {
        this.IgnoreIntersect = false;
        this.cdref.detectChanges();
    }
}
