import { sqrt } from 'mathjs';
import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class SquareRoot extends AFormula {
    static StaticID = 'c5559f29-c210-424b-93bf-4af0982d63b2';

    ID(): string {
        return SquareRoot.StaticID;
    }
    Name(): string {
        return 'Sqrt';
    }
    Caption(): string {
        return '@@Wurzel';
    }
    Description(): string {
        return '@@Wurzel Berechnung';
    }
    Group(): string {
        return FormulaGroups.Functions;
    }
    OrderID(): number {
        return 1020;
    }
    OperationType(): OperationType {
        return OperationType.Function;
    }
    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Wert') + ')';
    }
    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && args.length > 0) {
            const first = args[0];
            if (first.Type === ValueType.Double || first.Type === ValueType.Long) {
                retVal.Type = ValueType.Double;
                retVal.Value = sqrt(first.Value);
            }
        }
        return retVal;
    }
}
