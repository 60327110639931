import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowViewType } from '../../../models/enums/workflowviewtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-dataswitch-settings',
    templateUrl: './dataswitch.settings.html'
})
export class DataSwitchSettings extends FormulaWorkflowDialogContent {
    Conditions = [];

    static ModuleID = 'dataswitchWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = DataSwitchSettings.ModuleID;
        reg.Caption = '@@Data-Switch';
        reg.GroupID = 'datachecks';
        reg.Height = 80;
        reg.Width = 80;
        reg.Index = 20;
        reg.SettingsControl = DataSwitchSettings;
        reg.SettingsTypeHelper = new DataSwitchSettingsDataHelper();
        reg.Executer = DataSwitchModuleExecuter;
        reg.ViewType = WorkflowViewType.Condition;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            const obj = deserialize(DataSwitchSettingsData, json);
            this.Conditions = obj.Conditions;
        }
    }

    getResult() {
        const retVal = new DataSwitchSettingsData();
        retVal.Conditions = this.Conditions;
        return retVal;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        this.Conditions.some(cond => {
            if (!cond.Name || !cond.Formula) {
                retVal.Error = '@@Bitte vergeben Sie fuer jede Bedingung einen Namen und eine Formel.';
                retVal.IsCorrect = false;
                return true;
            }
            return false;
        });
        return retVal;
    }

    addCondition() {
        this.Conditions.push(new DataSwitchCondition());
    }

    removeCondition(i) {
        this.Conditions.splice(i, 1);
    }

    onDrop(ev) {
        moveItemInArray(this.Conditions, ev.previousIndex, ev.currentIndex);
        this.EndpointUpdateNecessary = true;
    }
}

// @dynamic
export class DataSwitchSettingsData extends AServiceWorkflowData {
    @Type(() => DataSwitchCondition)
    Conditions: DataSwitchCondition[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.DataSwitch.DataSwitchSettingsData';
    }
}

export class DataSwitchCondition {
    Name: string;
    Formula: string;
}

export class DataSwitchSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = [];

        if (settings && settings.Conditions) {
            let index = 1;
            settings.Conditions.forEach(cond => {
                const wei = new WorkflowExitInfo();
                wei.ID = index++;
                wei.Label = cond.Name;
                retVal.push(wei);
            });
        }

        const def = new WorkflowExitInfo();
        def.Label = '@@Sonst';
        retVal.push(def);

        return retVal;
    }
    getEmptySettingsInstance() {
        return new DataSwitchSettingsData();
    }
}

export class DataSwitchModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let retVal = 0;
            if (status.ActualSettings.Conditions) {
                const calculator = new WorkflowFormulaCalculator(status);
                status.ActualSettings.Conditions.some((x, i) => {
                    const val = calculator.CalcFormula(x.Formula);
                    if (typeof val === 'boolean') {
                        if (val) {
                            retVal = i + 1;
                            return true;
                        }
                    } else {
                        status.Logger.logWarning('DataSwitch modul: Result of condition ' + x.Name + ' not of type boolean.');
                    }
                    return false;
                });
            }
            return retVal;
        } else {
            status.Logger.logError('DataSwitch modul: No settings found.');
        }
        return super.execute(status);
    }
}
