import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { Class, Module } from '../script.model';
import { ScriptParser } from './parser.script';
import { CultureInfoWrapper } from './wrapper/cultureinfo.wrapper.script';
import { Global } from './wrapper/global.wrapper.script';
import { LayoutWrapper } from './wrapper/layout.wrapper.script';
import { MathWrapper } from './wrapper/math.wrapper.script';
import { StatusWrapper } from './wrapper/status.wrapper.script';

// @dynamic
export class Registry {
    static WindowFunctions = ['encodeURI', 'encodeURIComponent', 'decodeURI', 'decodeURIComponent', 'parseInt', 'parseFloat'];

    static Modules = new Map<string, Module>();

    static create(module: string, name: string): any {
        return Object.create(Registry.Modules.get(module).Classes.get(name).TypescriptType.prototype);
    }

    static initializeRegistry(service: ScriptLibraryService) {
        Registry.Modules.clear();

        const typescript = new Module();
        Registry.Modules.set('typescript', typescript);
        typescript.Classes.set('Set', new Class(Set));
        typescript.Classes.set('Map', new Class(Map));
        typescript.Classes.set('Array', new Class(Array));
        typescript.Classes.set("Object", new Class(Object));

        const system = new Module();
        Registry.Modules.set('System', system);
        system.Classes.set('WorkflowStatus', new Class(StatusWrapper));
        system.Classes.set('Global', new Class(Global));
        system.Classes.set('Math', new Class(MathWrapper));
        system.Classes.set('CultureInfo', new Class(CultureInfoWrapper));
        system.Classes.set('LayoutHelper', new Class(LayoutWrapper));
        system.Classes.forEach((item, key) => {
            system.Exports.set(key, item);
        });

        service.LoadClientScripts().subscribe(result => {
            if (result) {
                result.forEach(x => {
                    Registry.Modules.set(x.Name, new ScriptParser().GetModule(x.Text));
                });
            }
        });
    }
}
