import { from, Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class MediaEndpoints extends OfflineEndpointCollection {
    BasePath = 'config/api/media/';
    EndPoints = [
        {
            Name: 'GetEditable',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.Core.Service.Media.MediaSource'].where('NonEditable').equals(false).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    SID: value.SID
                                });
                            });
                        }
                        observer.next(retVal);
                    })
                });
            }
        },
        {
            Name: 'GetUsable',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.Core.Service.Media.MediaSource'].where('SystemUsage').equals(false).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    SID: value.SID
                                });
                            });
                        }
                        observer.next(retVal);
                    })
                });
            }
        },
        {
            Name: 'Get',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.Core.Service.Media.MediaSource'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    SID: value.SID
                                });
                            });
                        }
                        observer.next(retVal);
                    })
                });
            }
        },
        {
            Name: 'GetMediaSource',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.Core.Service.Media.MediaSource'].filter((value) => {
                        return value.SID === params['sid'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);    
                        }
                    })
                });
            }
        },
        {
            Name: 'GetAll',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return from(OfflineHelper.GetDB()['evidanza.Core.Service.Media.MediaSource'].toArray());
            }
        },
        {
            Name: 'LoadAllIcons',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.SelfService.REST.IconList'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        {
            Name: 'LoadAllFonts',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.SelfService.REST.FontList'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        {
            Name: 'GetProfilePicURL',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    observer.next('https://minio.portal.evidanza.cloud/avatar/');
                });
            }
        }
    ]
}