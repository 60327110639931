import { Subject } from 'rxjs';
import { OSSelectionRange, CopySelection, CellSelection } from '../models/basic/selection.model';
import { Column } from '../models/basic/column.model';

export class SelectionHelper {
    StartCell = new CellSelection();
    EndCell = new CellSelection();

    ColumnHighlight = '';

    private CurrentCellValue = new CellSelection();
    private MaxRow = Number.MIN_SAFE_INTEGER;
    private MinRow = Number.MAX_SAFE_INTEGER;

    private MaxColumn = Number.MIN_SAFE_INTEGER;
    private MinColumn = Number.MAX_SAFE_INTEGER;

    SetCurrentCell(value: CellSelection) {
        this.CheckRows();
        this.CheckCols();
        if (value.RowIndex > this.MaxRow) {
            this.MaxRow = value.RowIndex;
        }
        if (value.RowIndex < this.MinRow) {
            this.MinRow = value.RowIndex;
        }
        let col = this.cols.indexOf(value.ColumnName);
        if (col > this.MaxColumn) {
            this.MaxColumn = col;
        }
        if (col < this.MinColumn) {
            this.MinColumn = col;
        }
        this.ColumnHighlight = value.ColumnName;
        this.CurrentCellValue = value;
    }

    InitSelection() {
        this.MaxRow = Number.MIN_SAFE_INTEGER;
        this.MinRow = Number.MAX_SAFE_INTEGER;

        this.MaxColumn = Number.MIN_SAFE_INTEGER;
        this.MinColumn = Number.MAX_SAFE_INTEGER;
    }

    private rows = [];
    private cols = [];

    Table = {};
    Columns:Column[] = [];

    private CheckRows() {
        let rows;
        if (this.Table['DataSourceValue']) {
            rows = this.Table['DataSourceValue'].cachedData;
        }
        if (this.Table['RowsValue']) {
            rows = this.Table['RowsValue'];
        }
        if (rows) {
            if (this.rows.length !== rows.length) {
                this.rows = rows;
            }
        }
    }
    private CheckCols() {
        if (this.rows.length > 0) {
            let cols = Object.keys(this.rows[0].cols);
            if (this.cols.length !== cols.length) {
                this.cols = cols;
            }
        }
    }
    private Selection = new OSSelectionRange();
    SetHighlighting() {
        if (this.Table) {
            this.CheckRows();
            this.CheckCols();

            let StartRow = this.StartCell.RowIndex;
            let EndRow = this.CurrentCellValue.RowIndex;
            if (StartRow > EndRow) {
                let temp = StartRow;
                StartRow = EndRow;
                EndRow = temp;
            }
            let RowSpan = EndRow - StartRow;

            let StartColumn = this.cols.indexOf(this.StartCell.ColumnName);
            let EndColumn = this.cols.indexOf(this.CurrentCellValue.ColumnName);

            if (StartColumn > EndColumn) {
                let temp = StartColumn;
                StartColumn = EndColumn;
                EndColumn = temp;
            }
            let ColSpan = EndColumn - StartColumn;
            this.ClearHighlight();

            this.Selection.YStart = StartRow;
            this.Selection.YEnd = EndRow;
            this.Selection.XStart = StartColumn;
            this.Selection.XEnd = EndColumn;

            for (let row = StartRow; row <= EndRow; row++) {
                for (let col = StartColumn; col <= EndColumn; col++) {
                    this.rows[row].cols[this.cols[col]].copyhighlight = true;
                }
            }
        }
    }

    Selecting = false;
    Copying = false;
    SelectionComplete() {
        console.log('Selection complete');
        console.log(this.StartCell);
        console.log(this.EndCell);
        if (this.rows) {
            let StartCol = this.cols.indexOf(this.StartCell.ColumnName);
            let EndCol = this.cols.indexOf(this.EndCell.ColumnName);

            if (StartCol > EndCol) {
                let temp = StartCol;
                StartCol = EndCol;
                EndCol = temp;
            }

            this.ClearSelection();

            this.Table['OSSelectionRanges'] = [new OSSelectionRange({
                YStart: this.StartCell.RowIndex,
                YEnd: this.EndCell.RowIndex,
                XStart: StartCol,
                XEnd: EndCol
            })];

            const selections = {};
            this.Table['OSSelectionRanges'].forEach(range => {
                for (let y = range.YStart; y <= range.YEnd; y++) {
                    const rangeRow = this.rows[y];
                    for (let x = range.XStart; x <= range.XEnd; x++) {
                        const colName = this.cols[x];
                        rangeRow.cols[colName].selected = true;
                        selections[y + '_' + x] = {
                            ColumnName: colName,
                            RowIndex: y
                        };
                    }
                }
            });
            const selectionList = [];
            Object.keys(selections).forEach(x => {
                selectionList.push(selections[x]);
            });
            this.Table['CellSelectionChanged'].emit(selectionList);
        }
    };
    private ClearHighlight() {
        for (let row = this.MinRow; row <= this.MaxRow; row++) {
            for (let col = this.MinColumn; col <= this.MaxColumn; col++) {
                this.rows[row].cols[this.cols[col]].copyhighlight = false;
            }
        }
        this.rows[this.StartCell.RowIndex].cols[this.StartCell.ColumnName].copyhighlight = false;
        for (let col = this.MinColumn; col <= this.MaxColumn; col++) {
            this.rows[this.StartCell.RowIndex].cols[this.cols[col]].copyhighlight = false;
        }
    }
    private ClearSelection() {
        let OSSelectionRanges = this.Table['OSSelectionRanges'];
        if (OSSelectionRanges.length > 0) {
            if (OSSelectionRanges.length > 0) {
                OSSelectionRanges.forEach(range => {
                    for (let y = range.YStart; y <= range.YEnd; y++) {
                        const rangeRow = this.rows[y];
                        for (let x = range.XStart; x <= range.XEnd; x++) {
                            rangeRow.cols[this.cols[x]].selected = false;
                        }
                    }
                });
            }
        }
    }

    CopyComplete() {
        this.ClearHighlight();
        this.ClearSelection();

        this.Table['OSSelectionRanges'] = [Object.assign({},this.Selection)];

        const selections = {};
        this.Table['OSSelectionRanges'].forEach(range => {
            for (let y = range.YStart; y <= range.YEnd; y++) {
                const rangeRow = this.rows[y];
                for (let x = range.XStart; x <= range.XEnd; x++) {
                    const colName = this.cols[x];
                    rangeRow.cols[colName].selected = true;
                    selections[y + '_' + x] = {
                        ColumnName: colName,
                        RowIndex: y
                    };
                }
            }
        });
        const selectionList = [];
        Object.keys(selections).forEach(x => {
            selectionList.push(selections[x]);
        });
        this.Table['CellSelectionChanged'].emit(selectionList);

        let result = new CopySelection();
        result.StartCell = this.StartCell;
        result.Selection = this.Selection;

        this.Table['CopySelectionChanged'].emit(result);
    }
    private OldRow = 0;
    SetDescriptionHover() {
        //#region RowHighlight
        if (this.rows[this.OldRow] && this.rows[this.OldRow].cols && this.rows[this.OldRow].cols['fixedColumn']) {
            this.rows[this.OldRow].cols['fixedColumn'].highlight = false;
        }
        this.OldRow = this.CurrentCellValue.RowIndex;
        if (this.rows[this.CurrentCellValue.RowIndex] && this.rows[this.CurrentCellValue.RowIndex].cols && this.rows[this.CurrentCellValue.RowIndex].cols['fixedColumn']) {
            this.rows[this.CurrentCellValue.RowIndex].cols['fixedColumn'].highlight = true;
        }
        //#endregion
    }
}