import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APropertyTab } from '../../properties/properties.menu.tab';

@Component({
    selector: 'workflow-menu-tab-static',
    templateUrl: './workflow.static.menu.tab.html',
    styleUrls: ['./workflow.static.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowMenuTabStatic extends APropertyTab {
}
