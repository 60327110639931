import { CodeDesc } from '../../../enums/query.enum';
import { UUID } from 'angular2-uuid';
import { FilterMultidimensional } from './filterMultidimensional.model';
import { WhereSelects } from '../querydef.model';

export class FilterElementSettings {
    CodeDesc: CodeDesc;
    DataModel: UUID;
    Hierarchy: UUID;
    Levels = [];
    MultiSelect: boolean;
    Relational: boolean;
    DataSource: any;
    Container: any;
    Field: any;
    FilterItems: FilterMultidimensional[];
    SavedSelection: WhereSelects[];
}
