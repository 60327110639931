const IGNORE_FIELDS = new Map<string, string[]>();
export function JsonIgnore(cls: any, name: string) {
    let clsName = cls.constructor.name;
    let list: string[];

    if (IGNORE_FIELDS.has(clsName)) {
        list = IGNORE_FIELDS.get(clsName);
    } else {
        list = [];
        IGNORE_FIELDS.set(clsName, list);
    }

    list.push(name);
}

export class Base {
    toJson(): any {
        let json = {};
        let ignore = IGNORE_FIELDS.get(this.constructor.name);
        Object.getOwnPropertyNames(this).filter(name => (!ignore || (ignore && ignore.indexOf(name) < 0))).forEach(name => {
            let value = this[name];
            if (value != null && value != undefined) {
                if (value instanceof Array) {
                    let newArray = [];
                    for (var i = 0; i < value.length; i++) {
                        let iValue = value[i];
                        if (iValue.subJson) {
                            iValue = iValue.subJson();
                        }
                        newArray.push(iValue);
                    }
                    value = newArray;
                }
                else if (value.subJson) {
                    value = value.subJson();
                }
            }
            json[name] = value;
        });
        return JSON.stringify(json);
    }

    subJson() {
        let json = {};
        let ignore = IGNORE_FIELDS.get(this.constructor.name);

        Object.getOwnPropertyNames(this).filter(name => (!ignore || (ignore && ignore.indexOf(name) < 0))).forEach(name => {
            let value = this[name];
            if (value != null && value != undefined) {
                if (value instanceof Array) {
                    let newArray = [];
                    for (var i = 0; i < value.length; i++) {
                        let iValue = value[i];
                        if (iValue.subJson) {
                            iValue = iValue.subJson();
                        }
                        newArray.push(iValue);
                    }
                    value = newArray;
                }
                else if (value.subJson) {
                    value = value.subJson();
                }
            }
            json[name] = value;
        });

        return json;
    }
}
