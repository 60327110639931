import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';


@Injectable()
export class GeneralSettingsService extends RequestBase {
    BasePath = "api/generalsettings";

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ReadByID(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByID?id=' + id, this.options);
    }
    Read(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Read', filter, this.options);
    }
    ReadAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadAll', this.options);
    }
    
    AddOrUpdate(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdate', data, this.options);
    }

    Remove(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Remove?id=' + id, this.options);
    }

    GetDefaultNavigationStructure() {
        return new Promise(resolve => {
            this.ReadAll().subscribe(gsList => {
                let defaultNS;
                if (gsList && gsList.length > 0) {
                    const first = gsList[0];
                    if (first && first.DefaultNavigationStructure &&
                        first.DefaultNavigationStructure != '00000000-0000-0000-0000-000000000000') {
                        defaultNS = first.DefaultNavigationStructure;
                    }
                }
                resolve(defaultNS);
            });
        });
    }
}
