import { Component } from '@angular/core';
import { StandardRequestBase } from '../../../services/request-base';

@Component({
    selector: 'container-attribute-edit-dialog',
    templateUrl: './container.attribute.edit.dialog.html'
})
export class ContainerAttributeEditDialog {
    Attribute;
    KeyName = '';
    NameName = '';
    ParentName = '';
    UnaryName = '';
    RollUpName = '';
    TreeNodes = [];
    LevelTypes;
    Languages = [];

    constructor(private service: StandardRequestBase) {
        this.LevelTypes = [
            { value: 'Regular', type: 0 },
            { value: 'All', type: 1 },
            { value: 'Calculated', type: 2 },
            { value: 'Time', type: 4 },
            { value: 'TimeYears', type: 20 },
            { value: 'TimeHalfYears', type: 36 },
            { value: 'TimeQuarters', type: 68 },
            { value: 'TimeMonths', type: 132 },
            { value: 'TimeWeeks', type: 260 },
            { value: 'TimeDays', type: 516 },
            { value: 'TimeHours', type: 772 },
            { value: 'TimeMinutes', type: 1028 },
            { value: 'TimeSeconds', type: 2052 }
        ];
    }

    ngOnInit(): void {
        this.service.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            if (languages) {
                languages.forEach(x => x.ElementCaption = '');
                this.Languages = languages;
            }
            if (this.Attribute) {
                const nameDict = {};
                this.TreeNodes.forEach(parentNode => {
                    parentNode.Children.forEach(child => {
                        nameDict[child.UniqueID] = child.FullCaption;
                    });
                });
                if (typeof this.Attribute.KeyContainerElements === 'number') {
                    this.KeyName = nameDict[this.Attribute.KeyContainerElements] ?? '';
                }
                if (typeof this.Attribute.NameContainerElements === 'number') {
                    this.NameName = nameDict[this.Attribute.NameContainerElements] ?? '';
                }
                if (typeof this.Attribute.ParentContainerElements === 'number') {
                    this.ParentName = nameDict[this.Attribute.ParentContainerElements] ?? '';
                }
                if (typeof this.Attribute.UnaryContainerElements === 'number') {
                    this.UnaryName = nameDict[this.Attribute.UnaryContainerElements] ?? '';
                }
                if (typeof this.Attribute.RollUpContainerElements === 'number') {
                    this.RollUpName = nameDict[this.Attribute.RollUpContainerElements] ?? '';
                }
                if (this.Attribute.TranslationNameContainerElements) {
                    const keys = Object.keys(this.Attribute.TranslationNameContainerElements);
                    keys.forEach(k => {
                        const lang = this.Languages.find(x => '' + x.LCID === k);
                        if (lang) {
                            lang.UniqueID = this.Attribute.TranslationNameContainerElements[k];
                            lang.ElementCaption = nameDict[lang.UniqueID] ?? '';
                        }
                    });
                }
            }
        });
    }

    Initialize(args) {
        if (args) {
            if (args.TreeNodes) {
                this.TreeNodes = args.TreeNodes;
            }
            if (args.Attribute) {
                const json = JSON.stringify(args.Attribute);
                this.Attribute = JSON.parse(json);
            }
        }
    }

    GetDialogResult() {
        let add = false;
        const translations = {};
        this.Languages.forEach(x => {
            if (typeof x.UniqueID === 'number' && x.UniqueID > -1) {
                add = true;
                translations[x.LCID] = x.UniqueID;
            }
        });
        if (add) {
            this.Attribute.TranslationNameContainerElements = translations;
        } else {
            this.Attribute.TranslationNameContainerElements = null;
        }
        return this.Attribute;
    }

    keyChanged(field) {
        if (this.Attribute) {
            if (field) {
                this.Attribute.KeyContainerElements = field.UniqueID;
                this.Attribute.DataTyp = field.DataType;
                this.KeyName = field.FullCaption;
            } else {
                this.Attribute.KeyContainerElements = -1;
                this.Attribute.DataTyp = null;
                this.KeyName = '';
            }
        }
    }

    nameChanged(field) {
        if (this.Attribute) {
            if (field) {
                this.Attribute.NameContainerElements = field.UniqueID;
                this.NameName = field.FullCaption;
            } else {
                this.Attribute.NameContainerElements = -1;
                this.NameName = '';
            }
        }
    }

    parentChanged(field) {
        if (this.Attribute) {
            if (field) {
                this.Attribute.ParentContainerElements = field.UniqueID;
                this.ParentName = field.FullCaption;
            } else {
                this.Attribute.ParentContainerElements = -1;
                this.ParentName = '';
            }
        }
    }

    unaryChanged(field) {
        if (this.Attribute) {
            if (field) {
                this.Attribute.UnaryContainerElements = field.UniqueID;
                this.UnaryName = field.FullCaption;
            } else {
                this.Attribute.UnaryContainerElements = -1;
                this.UnaryName = '';
            }
        }
    }


    rollupChanged(field) {
        if (this.Attribute) {
            if (field) {
                this.Attribute.RollUpContainerElements = field.UniqueID;
                this.RollUpName = field.FullCaption;
            } else {
                this.Attribute.RollUpContainerElements = -1;
                this.RollUpName = '';
            }
        }
    }

    translationChanged(field, lang) {
        if (field) {
            lang.UniqueID = field.UniqueID;
            lang.ElementCaption = field.FullCaption;
        } else {
            lang.UniqueID = null;
            lang.ElementCaption = '';
        }
    }
}
