import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { ABaseTreeNode } from '../components/common/basetreecontrol/base.tree.control';
import { BaseDialog } from '../components/dialogs/basedialog/base.dialog';
import { WorkflowType } from '../models/enums/workflowtype.enum';
import { WorkflowViewType } from '../models/enums/workflowviewtype.enum';
import { IAdditionalBreakPointHandler, StepIntoHandler, WorkflowSaveObject } from '../models/workflow/workflow.model';
import { RequestBase } from './request-base';

@Injectable()
export class WorkflowService extends RequestBase {
    static DialogStatusCopy: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    static ActiveDialog: BehaviorSubject<BaseDialog> = new BehaviorSubject<BaseDialog>(null);
    detectOnSaveChanges = new Subject();

    BasePath = 'api/workflow';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetAllServiceWorkflows(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllServiceWorkflows', this.options);
    }

    GetAllWorkflowTemplates(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllWorkflowTemplates', this.options);
    }

    GetWorkflowGroups(service: boolean): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflowGroups?service=' + service, this.options);
    }

    GetWorkflowGroup(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflowGroup?id=' + id, this.options);
    }

    SaveWorkflowGroup(group): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflowGroup', group, this.options);
    }

    GetWorkflow(id): Observable<WorkflowSaveObject> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflow?id=' + id, this.options);
    }

    LoadWorkflow(id): Observable<WorkflowSaveObject> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadWorkflow?id=' + id, this.options);
    }

    RequestWorkflowLock(wf, isService: boolean): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/RequestWorkflowLock?isService=' + isService, wf, this.options);
    }

    SaveWorkflowToChangeLog(wf): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflowToChangeLog', wf, this.options);
    }

    SaveWorkflowTemplateToChangeLog(wf): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflowTemplateToChangeLog', wf, this.options);
    }

    SaveWorkflow(wf): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflow', wf, this.options);
    }

    SaveWorkflowTemplate(wf): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWorkflowTemplate', wf, this.options);
    }

    GetServiceModuls(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetServiceModuls', this.options);
    }

    DeleteWorkflow(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteWorkflow?id=' + id, this.options);
    }

    TryDeleteWorkflow(id, isService, ignoreVersions): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/TryDeleteWorkflow?id=' + id +
            '&isService=' + isService + '&ignoreVersions=' + ignoreVersions;
        return this.http.delete<any>(path, this.options);
    }

    DebugIsAlive(channel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/DebugIsAlive?channel=' + channel, this.options);
    }

    ExecuteWorkflow(wf, runID, doLog): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/ExecuteWorkflow?runID=' + runID + '&enableLogging=' + doLog;
        return this.http.post<any>(path, wf, this.options);
    }

    ExecuteWorkflowExecutionContext(context): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteWorkflowExecutionContext', context, this.options);
    }

    ExecuteWorkflowData(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteWorkflowData', data, this.options);
    }

    ExecuteAndWaitForWorkflow(data, channel): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteAndWaitForWorkflow?channel=' + channel, data, this.options);
    }

    NextWorkflowDebugStep(channel, untilBreakpoint): Observable<any> {
        const p = this.API_BASE_URL + this.BasePath + '/NextWorkflowDebugStep?channel=' + channel + '&untilbreakpoint=' + untilBreakpoint;
        return this.http.get<any>(p, this.options);
    }

    CancelWorkflowDebug(channel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/CancelWorkflowDebug?channel=' + channel, this.options);
    }

    GetUsage(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetUsage?id=' + id, this.options);
    }

    SendDebugMessage(channel, message): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SendDebugMessage?channel=' + channel, message, this.options);
    }
}

export const WF_REGISTRY = new Map<string, WorkflowRegistry>();
export const WF_GROUP_REGISTRY: WorkflowGroupDescription[] = [];
export const WF_SERVICE_SETTINGS = new Map<string, any>();
export const WF_TYPE_REGISTRY = new Map<string, WorkflowExitTypeInfo>();

export class WorkflowNodeHelper {
    private static ServiceList: any[] = null;
    private static ClientList: any[] = null;

    public static GetList(service: boolean) {
        const retVal = [];
        if (service) {
            if (!WorkflowNodeHelper.ServiceList) {
                WorkflowNodeHelper.ServiceList = WorkflowNodeHelper.FillList(true);
            }
            retVal.push(...WorkflowNodeHelper.ServiceList);
        } else {
            if (!WorkflowNodeHelper.ClientList) {
                WorkflowNodeHelper.ClientList = WorkflowNodeHelper.FillList(false);
            }
            retVal.push(...WorkflowNodeHelper.ClientList);
        }
        return retVal;
    }

    private static FillList(isService: boolean) {
        let index = 0;
        const groupDict = new Map<string, WorkflowTreeNode>();
        const parentDict = new Map<string, WorkflowTreeNode[]>();
        WF_GROUP_REGISTRY.forEach(v => {
            const group = new WorkflowTreeNode(index++);
            group.TranslateCaption = v.Caption;
            group.Index = v.Index;
            group.GroupID = v.GroupID;
            group.IsExpanded = true;
            group.HasChildren = true;
            group.Children = [];
            group.Icon = v.Icon;
            groupDict.set(v.GroupID, group);
            if (v.ParentID) {
                let list = parentDict.get(v.ParentID);
                if (!list) {
                    list = [];
                    parentDict.set(v.ParentID, list);
                }
                list.push(group);
            }
        });
        WF_REGISTRY.forEach((module, key) => {
            if (!module.ObsoleteInfo) {
                let found;
                if (module.WorkflowType === WorkflowType.Both) {
                    found = true;
                } else {
                    if (isService) {
                        found = module.WorkflowType === WorkflowType.Service;
                    } else {
                        found = module.WorkflowType === WorkflowType.Client;
                    }
                }
                if (found) {
                    let group = groupDict.get(module.GroupID);
                    if (!group) {
                        group = groupDict.get('');
                    }
                    if (group) {
                        const childNode = new WorkflowTreeNode(index++);
                        childNode.TranslateCaption = module.Caption;
                        childNode.Index = module.Index;
                        childNode.Module = key;
                        childNode.Icon = module.Icon;
                        childNode.Draggable = true;
                        group.Children.push(childNode);
                    }
                }
            }
        });
        // ModuleNodes ordnen
        groupDict.forEach(gr => {
            gr.Children.sort((a, b) => a['Index'] - b['Index']);
        });
        // Gruppierung bauen
        const subGroups = [];
        parentDict.forEach((v, k) => {
            const parent = groupDict.get(k);
            if (parent) {
                v.sort((a, b) => a['Index'] - b['Index']);
                v.forEach(x => {
                    subGroups.push(x.GroupID);
                });
                parent.Children.splice(0, 0, ...v);
            }
        });
        // Untergruppen aus HauptDict entfernen
        subGroups.forEach(x => {
            groupDict.delete(x);
        });
        const glTmp = [];
        groupDict.delete('-1');
        groupDict.forEach(gr => {
            if (gr.Children.length > 0) {
                glTmp.push(gr);
            }
        });
        glTmp.sort((a, b) => a.Index - b.Index);
        return glTmp;
    }
}

export class WorkflowTreeNode extends ABaseTreeNode {
    Index: number;
    Module: string;
    GroupID: string;
}

export class WorkflowRegistry {
    ID: string;
    Caption: string;
    GroupID = '';
    GroupIcon = '';
    Icon = '';
    Index = 0;
    SettingsControl;
    SettingsTypeHelper: WorkflowModuleSettingsHelper;
    Executer;
    WorkflowType: WorkflowType = WorkflowType.Both;
    ObsoleteInfo: ObsoleteInfo;
    ViewType: WorkflowViewType = WorkflowViewType.Action;
    Height: number = 40;
    Width: number = 160;
}

export class ObsoleteInfo {
    ReplacedBy: string;
}

export class WorkflowGroupDescription {
    GroupID = '';
    Caption: string;
    Index = 0;
    ParentID;
    Icon = '';
}

export abstract class WorkflowModuleSettingsHelper {
    MustUpdateExitPoints = false;

    public static AddStatusKeysToState(state, keys) {
        if (state && Array.isArray(keys) && keys.length > 0) {
            let list = state.get('statusKeys');
            if (!list) {
                list = [];
                state.set('statusKeys', list);
            }
            keys.forEach(x => {
                if (!list.some(y => y === x)) {
                    list.push(x);
                }
            });
        }
    }

    protected static GetSettingsFromModule(module) {
        if (module) {
            if (typeof module.Settings === 'string') {
                return JSON.parse(module.Settings);
            }
            return module.Settings;
        }
        return null;
    }

    getAdditionalCaption(settings) {
        return null;
    }

    async fillActualState(module, state, wfData) {
    }

    getEntryPoints(): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    abstract getExitPoints(settings): WorkflowExitInfo[];

    abstract getEmptySettingsInstance(): any;

    getExitPointLabel(settings: any, id: number): string {
        const exitPoints = this.getExitPoints(settings);
        let retVal = '';
        exitPoints.some(ep => {
            if (ep.ID === id) {
                retVal = ep.Label;
                return true;
            }
            return false;
        });
        return retVal;
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        return false;
    }

    getStepIntoHandler(settings, workflowID: string, moduleID: number): StepIntoHandler {
        return null;
    }

    getAdditionalBreakpointHandler(settings): IAdditionalBreakPointHandler {
        return null;
    }
}

export class WorkflowExitInfo {
    ID = 0;
    Label = '';
    Type;

    equals(other: WorkflowExitInfo): boolean {
        return other && other.ID === this.ID && other.Label === this.Label && other.Type === this.Type;
    }
}

export class WorkflowExitTypeInfo {
    ID: string;
    Color: string;
}
