import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import 'quill-emoji/dist/quill-emoji.js';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { ThemeHelper } from '../../../helpers/theme.helpers';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { StandardRequestBase } from '../../../services/request-base';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-editor',
    templateUrl: './editor.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorControl extends IBaseComponent {

    static Type: any = 'editor';
    static Default = { Type: 'editor',Layout: {
        _Editable: true,
        Width:{Type: 0,Value:400},
        Height:{Type: 0,Value:300},
    } };

    quill;

    IsInEditModeValue = false;
    get IsInEditMode() {
        return this.IsInEditModeValue;
    }
    set IsInEditMode(value) {
        this.IsInEditModeValue = value;
        if (this.Initialized) {
            this.cdRef.detectChanges();
        }
    }
    //#region DataSource
    get DataSourceInnerHtml() {
        if (this.DataSourceValue) {
            return this.sanitizer.bypassSecurityTrustHtml(this.DataSourceValue);
        }
        return '';
    }
    //#endregion

    //#region Caption
    @Input()
    get Text() {
        return this.LayoutElement.Text;
    }
    set Text(val) {
        this.LayoutElement.Text = val;
        if (val) {
            this.TextInnerHtml = this.sanitizer.bypassSecurityTrustHtml(val);
        } else {
            this.TextInnerHtml = '';
        }
        this.TextChange.emit(this.LayoutElement.Text);
        this.cdRef.detectChanges();
    }
    TextInnerHtml;
    //#endregion

    LanguageInnerHtml;
    customOptions;
    modules;

    @Output() TextChange = new EventEmitter<string>();

    FontList;
    FontSizes;

    SelectedColor;
    SelectedBackgroundColor;

    IsTranslatedString = false;
    AllLanguages = [];
    
    constructor(private sanitizer: DomSanitizer, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private requestService: StandardRequestBase) {
        super(cdRef, data);
        this.EventList.push('textchanged');
        this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe((viewType) => {
            this.IsInEditMode = viewType === ViewType.Edit;
        });

        this.FontList = MediaService.CustomFontList.getValue();
        MediaService.CustomFontList.subscribe((fonts) => {
            this.FontList = fonts;
            this.InitOptions();
        });

        this.Subscriptions['DataSourceChange'] = this.DataSourceChange.subscribe(x => {
            if (this.IsTranslatedString) {
                this.FillLanguages();
            }
        });
        this.Subscriptions['LanguageChanged'] = LanguageService.LanguageChanged.subscribe(() => {
            if (this.IsTranslatedString) {
                this.FillLanguages();
            }
        });

        this.InitOptions();
    }

    InitOptions() {
        this.customOptions = [];
        let sizeItem = { 'size': [] };
        if (ThemeHelper.CustomFontSizes) {
            let sizes = [];
            ThemeHelper.CustomFontSizes.forEach((size) => {
                sizes.push(size + 'px');
            });
            this.customOptions.push({
                import: 'attributors/style/size',
                whitelist: sizes
            });
            sizeItem = { 'size': [false, ...sizes] };
            this.FontSizes = sizes;
        }
        let fontItem = { 'font': [] };
        if (this.FontList && this.FontList.length > 0) {
            this.customOptions.push({
                import: 'attributors/class/font',
                whitelist: this.FontList
            });
            fontItem = { 'font': [ false, ...this.FontList ]};
        }
        this.modules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction
                [sizeItem],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': ['#F00', '#0F0', '#00F', '#000', '#FFF', 'color-picker'] }, { 'background': ['#F00', '#0F0', '#00F', '#000', '#FFF', 'color-picker'] }],          // dropdown with defaults from theme
                [fontItem],
                [{ 'align': [] }],
                ['emoji'],
                ['clean'],                                         // remove formatting button
                ['link', 'image', 'video'],                         // link and image, video
            ],
            'emoji-shortname': true,
            'emoji-textarea': false,
            'emoji-toolbar': true
        }
    }

    ControlInitialized() {
        
    }

    onLayoutElementSet() {
        this.IsTranslatedString = (this.LayoutElement.DataSourceType == "a0c232a9-4ab7-444c-a7e2-d23899a5673b");
        if (this.IsTranslatedString) {
            this.requestService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
                let list = [];
                if (languages) {
                    list.push(...languages);
                }
                list.unshift({ LCID: "default", Caption: "Default" })
                this.AllLanguages = list;
                this.FillLanguages();
                this.cdRef.detectChanges();
            });
        }
        if (this.Text) {
            this.TextInnerHtml = this.sanitizer.bypassSecurityTrustHtml(this.Text);
        } else {
            this.TextInnerHtml = '';
        }
        this.cdRef.detectChanges();
    }
    onLayoutElementChanged() {
        if (this.IsTranslatedString) {
            this.requestService.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
                let list = [];
                if (languages) {
                    list.push(...languages);
                }
                list.unshift({ LCID: "default", Caption: "Default" })
                this.AllLanguages = list;
                this.FillLanguages();
                this.cdRef.detectChanges();
            });
        }
        if (this.Text) {
            this.TextInnerHtml = this.sanitizer.bypassSecurityTrustHtml(this.Text);
        } else {
            this.TextInnerHtml = '';
        }
        this.cdRef.detectChanges();
    }

    FillLanguages() {
        if (this.AllLanguages && this.AllLanguages.length > 0) {
            if (this.DataSource == null) {
                this.AllLanguages.forEach((lang) => lang.Value = null);
            } else {
                this.AllLanguages[0].Value = this.DataSource.DefaultValue;
                if (this.DataSource.SerializedTranslations) {
                    this.AllLanguages.forEach((trans) => {
                        if (trans.LCID != 'default') {
                            let found = this.DataSource.SerializedTranslations.find((value) => value.LCID == trans.LCID);
                            if (found) {
                                trans.Value = found.Value;
                            } else {
                                trans.Value = null;
                            }
                        }
                    });
                }
            }
            if (!this.LayoutElement.Editable) {
                let text = TranslatedString.GetTranslation(this.DataSourceValue);
                this.LanguageInnerHtml = this.sanitizer.bypassSecurityTrustHtml(text);
            } else {
                this.LanguageInnerHtml = '';
            }
            this.cdRef.detectChanges();
        }
    }
    getLanguageName(lcid: number): string {
        var retVal = '';
        let lang = this.AllLanguages.find((lang) => lang.LCID == lcid);
        if (lang) {
            retVal = lang.Caption;
        }
        return retVal;
    }
    langChanged(lang) {
        if (lang.LCID == 'default') {
            if (this.DataSourceValue) {
                this.DataSourceValue.DefaultValue = lang.Value;
            } else {
                this.DataSource = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: []
                };
            }
        } else {
            if (this.DataSourceValue) {
                let trans = this.DataSource.SerializedTranslations.find((val) => val.LCID == lang.LCID);
                if (lang.Value == "") {
                    if (trans) {
                        this.DataSource.SerializedTranslations.splice(this.DataSource.SerializedTranslations.indexOf(trans), 1);
                    }
                } else {
                    if (trans) {
                        trans.Value = lang.Value;
                    } else {
                        this.DataSource.SerializedTranslations.push({ LCID: lang.LCID, Value: lang.Value });
                    }
                }
            } else {
                this.DataSource = {
                    DefaultValue: lang.Value,
                    SerializedTranslations: [{ LCID: lang.LCID, Value: lang.Value }]
                };
            }
        }

    }
    setFocus() {
        if (this.quill) {
            if (this.quill && typeof this.quill.focus === 'function') {
                this.quill.focus();
            }
        }
    }

    onChange(ev) {
        if (this.LayoutElement.SaveToLayout) {
            this.triggerEvent('textchanged', this.Text);
            this.TextChange.emit(this.Text);
        } else {
            this.triggerEvent('textchanged', this.DataSourceValue);
            this.TextChange.emit(this.DataSourceValue);
        }
    }

    onKeyDown(ev) {
        if (ev && ev.key === 'Delete') {
            ev.stopPropagation();
        }
    }
    created(event) {
        if (event) {
            this.quill = event;
            var toolbar = this.quill.getModule('toolbar');
            toolbar.addHandler('color', (value) => {
                if (value === 'color-picker') {
                    var picker = document.getElementById('color-picker');
                    if (!picker) {
                        picker = document.createElement('input');
                        picker.id = 'color-picker';
                        picker['type'] = 'color';
                        picker.style.display = 'none';
                        picker['value'] = '#FF0000';
                        document.body.appendChild(picker);
                        let that = this;
                        picker.addEventListener('change', function () {
                            that.quill.format('color', picker['value']);
                        }, false);
                    }
                    picker.click();
                } else {
                    this.quill.format('color', value);
                }
            });
        }
    }
    ColorChanged(value) {
        if (this.quill) {
            this.quill.format('color', this.SelectedColor, 'user');
        }
    }
    BackgroundChanged(value) {
        if (this.quill) {
            this.quill.format('background-color', this.SelectedBackgroundColor);
        }
    }
    SelectionChanged(event) {
        if (this.quill && event && event.range) {
            this.SelectedColor = null;
            this.SelectedBackgroundColor = null;
            let format = this.quill.getFormat(event.range.index, event.range.length);
            if (format) {
                if (format.color) {
                    this.SelectedColor = format.color;
                } 
                if (format.background) {
                    this.SelectedBackgroundColor = format.background;
                } 
            }
        }
    }
}
export class EditorPanel extends BasePanel {
    static override SIDS = ['740deb8f-019f-429b-b700-a394b6a971cd']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'740deb8f-019f-429b-b700-a394b6a971cd',
            ID: 'Editor',
            Caption: '@@Editor',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'editor';
            }
        })

        PROPERTIES.push({
            ID: "Placeholder",
            Parent: "Editor",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Placeholder",
            Column: 1,
            Row: 1,
            InitArgs: {
                DataType: 'string'
            }
        })
        PROPERTIES.push({
            ID: "SaveToLayout",
            Parent: "Editor",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Inhalt im Layout speichern"
            },
            Column: 1,
            Row: 2
        })
        
    }
}