import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { EnumHelper } from '../../../helpers/enum.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageType } from '../../../models/enums/messagetype.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-sendnotification-settings',
    templateUrl: './send.notification.settings.html'
})
export class SendNotificationSettings extends FormulaWorkflowDialogContent {
    Data = new SendNotificationSettingsData();
    MessageTypes;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'sendnotificationWFModule';
        reg.Caption = '@@Send notification';
        reg.GroupID = 'wfActions';
        reg.Index = 70;
        reg.SettingsControl = SendNotificationSettings;
        reg.SettingsTypeHelper = new SendNotificationSettingsDataHelper();
        reg.Executer = SendNotificationModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.MessageTypes = EnumHelper.GetDropdownValues(MessageType);
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SendNotificationSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class SendNotificationSettingsData {
    Title: string;
    Message: string;
    MessageType: MessageType = MessageType.Information;
}

export class SendNotificationSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SendNotificationSettingsData();
    }
}

export class SendNotificationModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let title = '';
            let message = '';
            const calculator = new WorkflowFormulaCalculator(status);
            if (status.ActualSettings.Title) {
                try {
                    const titleVal = calculator.CalcFormula(status.ActualSettings.Title);
                    if (titleVal) {
                        title += titleVal;
                    }
                } catch (e) {
                    const t = 'SendNotification modul: Error while calculating title \'' +
                        status.ActualSettings.Title + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            if (status.ActualSettings.Message) {
                try {
                    const messageVal = calculator.CalcFormula(status.ActualSettings.Message);
                    if (messageVal) {
                        message += messageVal;
                    }
                } catch (e) {
                    const t = 'SendNotification modul: Error while calculating message \'' +
                        status.ActualSettings.Message + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            switch (status.ActualSettings.MessageType) {
                case MessageType.Error:
                    NotificationHelper.Error(message, title);
                    break;
                case MessageType.Success:
                    NotificationHelper.Success(message, title);
                    break;
                case MessageType.Warning:
                    NotificationHelper.Warn(message, title);
                    break;
                default: // MessageType.Information
                    NotificationHelper.Info(message, title);
                    break;
            }
            return 0;
        } else {
            status.Logger.logError('SendNotification modul: No settings found.');
        }
        return super.execute(status);
    }
}
