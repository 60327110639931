import { ChangeDetectorRef, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { DEPLOYMENTACTIONS_REGISTRY } from '../../registries/actions/actions.registry';
import { LayoutService } from '../../services/layout.service';
import { MetaService } from '../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'deployactions-settings',
    templateUrl: '../base.list.settings.html'
})
export class DeploymentActionsSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return DeploymentActionsDetail;
    }

    loadList(handler) {
        this.service.ReadDeploymentActionInfos().subscribe((result) => {
            const list = [];
            result.forEach(item => {
                list.push({
                    Caption: item.Caption,
                    ID: item.SID,
                    IsCapsule: item.IsCapsule,
                    IsOverridden: item.IsOverridden
                });
            });
            handler(list);
        });
    }

    loadData(data) {
        this.service.ReadDeploymentActionById(data).subscribe((result) => {
            if (result) {
                const item = result;
                this.setSelectedItem(item);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@Neue Aktion'),
            Completed: false
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Aktion \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Aktion \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Aktion loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Aktion \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Aktion speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.RemoveDeploymentAction(data).subscribe((result) => {
            handler(result);
        });
    }

    saveInternal(item, handler) {
        this.service.AddOrUpdateDeploymentAction(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item._Id = result.SID;
        item._version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@DeploymentActions',
    //        ID: 'deployaction',
    //        Icon: 'deployaction',
    //        Index: 40,
    //        Parent: 'settings',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.PageSettingsRight',
    //            Value: 64
    //        },
    //        Content: DeploymentActionsSettings
    //    };
    //}
}

@Component({
    selector: 'deploy-actions-detail',
    templateUrl: './deployactions.settings.html',
    styleUrls: ['./deployactions.settings.css']
})
export class DeploymentActionsDetail extends BaseListDetail {

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
    ActionTypes;
    Subscription;
    constructor(private factoryResolver: ComponentFactoryResolver) {
        super();
        let actions = []
        DEPLOYMENTACTIONS_REGISTRY.forEach((value) => {
            actions.push(value);
        })
        this.ActionTypes = actions;
    }
    
    setSelectedItem(item) {
        this.SelectedItem = item;
        this.OnActionChanged(true);
    }
    OnActionChanged(internal) {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (this.SelectedItem) {
                let type = null;
                if (this.SelectedItem.ActionType && DEPLOYMENTACTIONS_REGISTRY.has(this.SelectedItem.ActionType)) {
                    type = DEPLOYMENTACTIONS_REGISTRY.get(this.SelectedItem.ActionType).Settings;
                }
                if (type) {
                    const factory = this.factoryResolver.resolveComponentFactory(type);
                    const comp = factory.create(this.viewContainerRef.parentInjector);
                    this.viewContainerRef.insert(comp.hostView);
                    const component: any = comp.instance;
                    if (component) {
                        component.SelectedItem = this.SelectedItem;
                        this.Subscription = component.OnValuesChanged.subscribe(() => {
                            this.OnItemChanged();
                        });
                    }
                }
            }
        }
        if (!internal) {
            this.OnItemChanged();
        }
    }
}