import { CacheService } from '../cache/cache.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SideNavService } from '../services/sidenav.service';
import { UsersService } from '../services/users.service';
import config from '../../assets/config.json'
export class ImpersonateInfo {
    Rights;
    constructor(public Roles: string[]) {
    }
}

export class PermissionHelper {
	public static ActiveContentPermissions: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);
	public static BaseLayoutPermissions: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);
	public static ActiveNavigationPermissions: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);
	public static Impersonate: BehaviorSubject<ImpersonateInfo> =
		new BehaviorSubject<ImpersonateInfo>(null);
	public static EditPermission: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public static ContentPermissionChanged: Subject<string> =
		new Subject<string>();

	private static GetRoles() {
		const roles = PermissionHelper.Impersonate.getValue();
		if (roles) {
			return roles.Roles;
		}
		const userJSON = localStorage.getItem('user');
		if (userJSON) {
			const user = JSON.parse(userJSON);
			return user.EffectiveRoles;
		}
		return null;
	}

	public static GetContentPermissions(layoutKey) {
		const promise = new Promise((resolve, reject) => {
			const pSub =
				layoutKey === 'baselayout'
					? PermissionHelper.BaseLayoutPermissions
					: PermissionHelper.ActiveContentPermissions;
			const roles = PermissionHelper.GetRoles();
			if (roles) {
				const length = roles.length;
				if (length > 0) {
					const structKey =
						SideNavService.SelectedNavigationStructure.getValue();
					if (structKey) {
						CacheService.ReadNavigationStructure(structKey).then(
							(navStruct) => {
								if (navStruct) {
									let count = 0;
									const permissions = {};
									roles.forEach((role) => {
										const key = role + '_' + navStruct._Id + '_' + layoutKey;
										CacheService.ReadLayoutPermission(key).then((perm) => {
											PermissionHelper.FillPermission(permissions, perm);
											count += 1;
											if (count === length) {
												pSub.next(permissions);
												resolve(true);
											}
										});
									});
								} else {
									pSub.next(null);
									resolve(true);
								}
							}
						);
						return;
					}
				}
			}
			pSub.next(null);
			resolve(true);
		});
		return promise;
	}
	private static FillPermission(permissions, perm) {
		if (permissions && perm && perm.Elements) {
			const fields = Object.keys(perm.Elements);
			if (fields) {
				fields.forEach((field) => {
					const source = perm.Elements[field];
					let target = permissions[field];
					if (!target) {
						target = {};
						permissions[field] = target;
					}
					if (source['Disabled']) {
						target['Disabled'] = source['Disabled'];
					}
					if (source['InVisible']) {
						target['InVisible'] = source['InVisible'];
					}
				});
			}
		}
	}
	public static GetNavigationPermissions() {
		const promise = new Promise((resolve, reject) => {
			const roles = PermissionHelper.GetRoles();
			if (roles) {
				const length = roles.length;
				if (length > 0) {
					let count = 0;
					const permissions = {};
					roles.forEach((role) => {
						CacheService.ReadNavigationPermission(role).then((perm) => {
							PermissionHelper.FillInvisible(permissions, perm);
							count += 1;
							if (count === length) {
								PermissionHelper.ActiveNavigationPermissions.next(permissions);
								resolve(true);
							}
						});
					});
				} else {
					PermissionHelper.ActiveNavigationPermissions.next(null);
					resolve(true);
				}
			} else {
				PermissionHelper.ActiveNavigationPermissions.next(null);
				resolve(true);
			}
		});
		return promise;
	}
	private static FillInvisible(permissions, perm) {
		if (permissions && perm && perm.Elements) {
			const fields = Object.keys(perm.Elements);
			if (fields) {
				fields.forEach((field) => {
					const source = perm.Elements[field];
					if (typeof source === 'object' && source['InVisible']) {
						permissions[field] = true;
					}
				});
			}
		}
	}
	public static IsAdmin() {
		const userRights = UsersService.ActualUserRights.getValue();
		return userRights && userRights.IsAdmin === true;
	}
	public static GetRight(type, allowed) {
		const userRights = UsersService.ActualUserRights.getValue();
		if (userRights) {
			if (userRights.IsAdmin === true) {
				return true;
			}
			if (userRights.Rights) {
				const exceptFreeAccess = [
					'evidanza.App.Shared.Security.UsermanagementRight',
					'evidanza.App.Shared.Security.AboutRight',
					'evidanza.App.Shared.Security.PageSettingsRight',
				];
				const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
				if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
					if (exceptFreeAccess.includes(type)) {
						return false;
					}
				}
				return (userRights.Rights[type] & allowed) === allowed;
			}
		}
		return false;
	}

	public static GetAppBuilderPermission() {
		return PermissionHelper.GetRight(
			'evidanza.Core.Base.Security.DeveloperRight',
			1
		);
	}
	public static GetDebuggerPermission() {
		return PermissionHelper.GetRight(
			'evidanza.Core.Base.Security.DeveloperRight',
			2
		);
	}

	public static GetImpersonatePermission() {
		return (
			PermissionHelper.GetRight(
				'evidanza.Core.Base.Security.UserAdminRight',
				32768
			) && PermissionHelper.GetRight('evidanza.Core.ServiceBase.AccessRight', 2)
		);
	}

	public static OnContentPermissionChanged(key: string) {
		if (key) {
			const splits = key.split('_');
			if (splits.length === 3) {
				const roles = PermissionHelper.GetRoles();
				if (roles && roles.some((x) => x === splits[0])) {
					const structKey =
						SideNavService.SelectedNavigationStructure.getValue();
					if (structKey && structKey === splits[1]) {
						if (splits[2] === 'baselayout') {
							PermissionHelper.GetContentPermissions('baselayout').then(
								() => {}
							);
						} else {
							PermissionHelper.ContentPermissionChanged.next(splits[2]);
						}
					}
				}
			}
		}
	}
}
