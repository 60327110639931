import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError, from, Subject} from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';
import { RefreshTokenService } from './refresh-token.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isRefreshing: boolean = false;
    refreshedToken: Subject<string> = new Subject<string>();
  constructor(private refreshService: RefreshTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const refreshUrls = ['api/addon/external', 'api/uithemes/external', 'api/prebuiltapps/external',];
    
    if (refreshUrls.some(keyword => request.url.includes(keyword))) {
      return from(this.refreshService.Authorize()).pipe(
        switchMap(accessToken => {
          if (accessToken) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`
              }
            });
          }
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401 && accessToken) {
                  if(!this.isRefreshing){
                      this.isRefreshing = true;
                      // Token expired or unauthorized, attempt to refresh token
                      return this.refreshService.RefreshToken().pipe(
                          switchMap((refreshResponse: any) => {
                              this.isRefreshing = false;
                              // Update access token in the AuthService and retry the request
                              this.refreshService.StoreTokens(refreshResponse.Data);
                              request = request.clone({
                                  setHeaders: {
                                      Authorization: `Bearer ${refreshResponse.Data.AccessToken}`
                                  }
                              });
                              this.refreshedToken.next(refreshResponse.Data.AccessToken);
                              return next.handle(request);
                          }),
                          catchError((refreshError) => {
                              // Handle refresh token error
                              return throwError(refreshError);
                          })
                      );
                  }else{
                      return from(this.refreshedToken).pipe(
                          switchMap(token=>{
                              request = request.clone({
                                  setHeaders: {
                                      Authorization: `Bearer ${token}`
                                  }
                              });
                              return next.handle(request);
                          })
                      );
                  }
              } else {
                return throwError(error);
              }
            })
          );
        }),
        catchError((authError) => {
          // Handle authorization error
          return throwError(authError);
        }),
        finalize(() => {
          // Any final logic can go here
        })
      );
    }
    return next.handle(request);
  }
}