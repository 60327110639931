import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataModelService, DataSourceQuery } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-delete-resource-settings-item',
    templateUrl: 'delete.resource.settings.html'
})
export class DeleteResourceSettings extends FormulaWorkflowDialogContent {
    Data = new DeleteResourceSettingsData();
    DataModels = [];
    DataSources = [];
    VariableNodes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'DeleteResourceWFModule';
        reg.Caption = '@@Resource loeschen';
        reg.GroupID = 'fileoperations';
        reg.Index = 15;
        reg.SettingsControl = DeleteResourceSettings;
        reg.SettingsTypeHelper = new DeleteResourceSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: DataModelService, private translate: TranslateService) {
        super();
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DeleteResourceSettingsData, json);
        }
        this.service.GetModels().subscribe(x => {
            if (x) {
                this.DataModels = x;
            }
            if (this.Data.DataModelID) {
                if (this.DataModels.some(y => y.SID === this.Data.DataModelID)) {
                    this.loadDataSources();
                } else {
                    this.Data.DataModelID = null;
                    this.Data.DataSource = null;
                }
            }
        });
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        const errors = [];
        if (!this.Data.DataSource) {
            errors.push(this.translate.instant('@@Bitte setzen Sie eine Datenquelle'));
        }
        if (!this.Data.FileName) {
            errors.push(this.translate.instant('@@Bitte vergeben Sie einen Dateinamen'));
        }
        if (errors.length > 0) {
            retVal.IsCorrect = false;
            retVal.Error = errors.join('\n');
        }
        return retVal;
    }

    getResult() {
        return this.Data;
    }

    dataModelChanged() {
        this.Data.DataSource = null;
        this.loadDataSources();
    }

    loadDataSources() {
        this.DataSources = [];
        if (this.Data.DataModelID) {
            const query = new DataSourceQuery(this.Data.DataModelID);
            query.ResourceBase = 1; // Filebase
            this.service.GetDataSourcesByQuery(query).subscribe(x => {
                if (x) {
                    this.DataSources = x;
                }
            });
        }
    }

    onFileNameSelect(event) {
        if (event) {
            this.Data.FileName = event.Caption;
        }
    }
}

export class DeleteResourceSettingsData extends AServiceWorkflowData {
    DataModelID: string;
    DataSource: string;
    FileName: string;
    GetFileNameFromStatus = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.FileOperations.DeleteResource.DeleteResourceSettingsData';
    }
}

export class DeleteResourceSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new DeleteResourceSettingsData();
    }
}
