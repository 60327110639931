export class IntersectionHelper {
    private static Observer: IntersectionObserver;

    private static Handlers = {};

    public static Observe(element, handler) {
        if (!IntersectionHelper.Observer) {
            IntersectionHelper.Observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    const id = entry.target.getAttribute('id');
                    const handler = IntersectionHelper.Handlers[id];
                    if (handler && handler.isIntersecting !== entry.isIntersecting) {
                        handler.isIntersecting = entry.isIntersecting;
                        handler.handler(entry.isIntersecting);
                    }
                });
            });
        }
        const id = element.getAttribute('id');
        if (!IntersectionHelper.Handlers[id]) {
            IntersectionHelper.Handlers[id] = {
                handler: handler
            };
            IntersectionHelper.Observer.observe(element);
        }
    }

    public static Unobserve(element) {
        const id = element.getAttribute('id');
        delete IntersectionHelper.Handlers[id];
        IntersectionHelper.Observer.unobserve(element);
    }
}