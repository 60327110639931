import { Component, OnDestroy, OnInit } from "@angular/core"
import { TourService } from "ngx-ui-tour-md-menu"
import { MatIconRegistry } from "@angular/material/icon"
import { DomSanitizer } from "@angular/platform-browser"
import icons from "../assets/icons/svg-icons.json"

// import { ComponentRegistry } from './helpers/component.registry.helper';
// import { MACSettingsRegistryHelper } from './imports/mac.settings';
// import { TaskRegistryHelper } from './imports/mac.tasks';
// import { PageStatus } from './models/page.status';
// import { NavigationService } from './services/navigation.service';
// import { MacWorkflowRegistryHelper } from './imports/mac.workflows';
// import { MWSettingsRegistryHelper } from './imports/app.mw.settings';
// import { MWWorkflowRegistryHelper } from './imports/app.mw.workflow';
// import { InterceptorSkipHeader } from './services/interceptor.skip.header';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  // svg icons
  svg_icons = [
    { name: 'apps', svg: icons.apps },
    { name: '_SYSTEM_LAYOUT', svg: icons.aspect_ratio },
    { name: '_SYSTEM_DATA', svg: icons.folder_managed },
    { name: '_SYSTEM_USER_MANAGEMENT', svg: icons.manage_accounts },
    { name: '_SYSTEM_CORP_IDENTITY', svg: icons.edit_square },
    { name: '_SYSTEM_SETTINGS', svg: icons.manufacturing },
    { name: '_SYSTEM_ABOUT', svg: icons.info },
    { name: '_SYSTEM_PAGES', svg: icons.tab },
    { name: 'create_new_folder', svg: icons.create_new_folder },
    { name: 'file-upload', svg: icons.file_upload },
    { name: 'publish', svg: icons.file_upload },
    { name: 'refresh', svg: icons.refresh },
    { name: 'add', svg: icons.add },
    { name: 'settings_add', svg: icons.add },
    { name: 'workflow_add', svg: icons.add },
    { name: 'add2', svg: icons.add2 },
    { name: 'save', svg: icons.save },
    { name: 'settings_save', svg: icons.save },
    { name: 'delete', svg: icons.delete },
    { name: 'settings_delete', svg: icons.delete },
    { name: 'workflow_delete', svg: icons.delete },
    { name: 'layouttree_delete', svg: icons.delete },
    { name: 'edit', svg: icons.edit },
    { name: 'border_color', svg: icons.edit },
    { name: 'settings_edit', svg: icons.edit },
    { name: 'workflow_edit', svg: icons.edit },
    { name: 'border-pencil', svg: icons.edit },
    { name: 'layouttree_edit', svg: icons.edit },
    { name: 'settings_copy', svg: icons.copy },
    { name: 'workflow_copy', svg: icons.copy },
    { name: 'copy', svg: icons.copy },
    { name: 'file_copy', svg: icons.copy },
    { name: 'layouttree_copy', svg: icons.copy },
    { name: 'paste', svg: icons.paste },
    { name: 'settings_paste', svg: icons.paste },
    { name: 'workflow_paste', svg: icons.paste },
    { name: 'layouttree_paste', svg: icons.paste },
    { name: 'duplicate', svg: icons.duplicate },
    { name: 'settings_duplicate', svg: icons.duplicate },
    { name: 'workflow_duplicate', svg: icons.duplicate },
    { name: 'layouttree_duplicate', svg: icons.duplicate },
    { name: 'close', svg: icons.close },
    { name: 'clear', svg: icons.close },
    { name: 'cancel', svg: icons.close },
    { name: 'info', svg: icons.info },
    { name: 'settings_info', svg: icons.info },
    { name: 'error', svg: icons.error },
    { name: 'help', svg: icons.help },
    { name: 'warning', svg: icons.warning },
    { name: 'play_arrow', svg: icons.play_arrow },
    { name: 'debug_play', svg: icons.play_arrow },
    { name: 'bug', svg: icons.bug },
    { name: 'list_add', svg: icons.list_add },
    { name: 'search', svg: icons.search },
    { name: 'zoom_in', svg: icons.zoom_in },
    { name: 'zoom_out', svg: icons.zoom_out },
    { name: 'zoom_reset', svg: icons.zoom_reset },
    { name: 'fullscreen', svg: icons.fullscreen },
    { name: 'toolbox_resolution', svg: icons.fullscreen },
    { name: 'keyboard_arrow_left', svg: icons.chevron_left },
    { name: 'keyboard_arrow_right', svg: icons.chevron_right },
    { name: 'keyboard_arrow_right', svg: icons.chevron_right },
    { name: 'keyboard_arrow_up', svg: icons.expand_less },
    { name: 'keyboard_arrow_down', svg: icons.expand_more },
    { name: 'arrow_forward', svg: icons.arrow_forward },
    { name: 'assignment', svg: icons.assignment },
    { name: 'list', svg: icons.list },
    { name: 'table_chart', svg: icons.table_chart },
    { name: 'bold', svg: icons.bold },
    { name: 'italic', svg: icons.italic },
    { name: 'underline', svg: icons.underline },
    { name: 'color_fill', svg: icons.color_fill },
    { name: 'done', svg: icons.done },
    { name: 'done_all', svg: icons.done_all },
    { name: 'more_vert', svg: icons.more_vert },
    { name: 'more_horiz', svg: icons.more_horiz },
    { name: 'menu', svg: icons.menu },
    { name: 'wf_group_actions', svg: icons.wf_group_actions },
    { name: 'wf_group_datachecks', svg: icons.wf_group_datachecks },
    { name: 'wf_group_dataoperations', svg: icons.wf_group_dataoperations },
    { name: 'wf_group_events', svg: icons.wf_group_events },
    { name: 'wf_group_generatedata', svg: icons.wf_group_generatedata },
    { name: 'wf_group_listoperations', svg: icons.wf_group_listoperations },
    { name: 'wf_group_navigation', svg: icons.wf_group_navigation },
    { name: 'wf_group_offline', svg: icons.wf_group_offline },
    { name: 'wf_group_status', svg: icons.wf_group_status },
    { name: 'wf_group_variables', svg: icons.wf_group_variables },
    {
      name: 'wf_group_containeroperations',
      svg: icons.wf_group_containeroperations,
    },
    { name: 'wf_group_fileoperations', svg: icons.wf_group_fileoperations },
    {
      name: 'wf_group_documentoperations',
      svg: icons.wf_group_documentoperations,
    },
    {
      name: 'wf_group_reldataoperations',
      svg: icons.wf_group_reldataoperations,
    },
    {
      name: 'wf_group_objectoperations',
      svg: icons.wf_group_objectoperations,
    },
    { name: 'grid_guides', svg: icons.grid_guides },
    { name: 'wf_group_aioperations', svg: icons.wf_group_aioperations },
    { name: 'wf_group_none', svg: icons.wf_group_none },
    { name: 'toolbox_textbox', svg: icons.toolbox_textbox },
    { name: 'toolbox_inputgroup', svg: icons.toolbox_inputgroup },
    { name: 'toolbox_textarea', svg: icons.toolbox_textarea },
    { name: 'toolbox_translationbox', svg: icons.toolbox_translationbox },
    { name: 'toolbox_editor', svg: icons.toolbox_editor },
    { name: 'toolbox_qr', svg: icons.toolbox_qr },
    { name: 'toolbox_table', svg: icons.toolbox_table },
    { name: 'toolbox_columnvisibility', svg: icons.toolbox_columnvisibility },
    { name: 'toolbox_checkbox', svg: icons.toolbox_checkbox },
    { name: 'toolbox_combobox', svg: icons.toolbox_combobox },
    { name: 'toolbox_dropdown', svg: icons.toolbox_dropdown },
    { name: 'toolbox_button', svg: icons.toolbox_button },
    { name: 'toolbox_togglebutton', svg: icons.toolbox_togglebutton },
    { name: 'toolbox_sidenav', svg: icons.toolbox_sidenav },
    { name: 'toolbox_themeswitch', svg: icons.toolbox_themeswitch },
    { name: 'toolbox_messagecenter', svg: icons.toolbox_messagecenter },
    { name: 'toolbox_radiobutton', svg: icons.toolbox_radiobutton },
    { name: 'toolbox_datetimepicker', svg: icons.toolbox_datetimepicker },
    { name: 'toolbox_timespan', svg: icons.toolbox_timespan },
    { name: 'toolbox_scheduler', svg: icons.toolbox_scheduler },
    { name: 'toolbox_tree', svg: icons.toolbox_tree },
    { name: 'toolbox_listbox', svg: icons.toolbox_listbox },
    { name: 'toolbox_listdetail', svg: icons.toolbox_listdetail },
    { name: 'toolbox_chips', svg: icons.toolbox_chips },
    { name: 'toolbox_slider', svg: icons.toolbox_slider },
    { name: 'toolbox_image', svg: icons.toolbox_image },
    { name: 'toolbox_icon', svg: icons.toolbox_icon },
    { name: 'toolbox_objectviewer', svg: icons.toolbox_objectviewer },
    { name: 'toolbox_marker', svg: icons.toolbox_marker },
    { name: 'toolbox_profilemanagement', svg: icons.toolbox_profilemanagement },
    { name: 'toolbox_textblock', svg: icons.toolbox_textblock },
    { name: 'maps', svg: icons.maps },
    { name: 'toolbox_languagemenu', svg: icons.toolbox_languagemenu },
    { name: 'toolbox_usermenu', svg: icons.toolbox_usermenu },
    { name: 'toolbox_accordion', svg: icons.toolbox_accordion },
    { name: 'toolbox_stepper', svg: icons.toolbox_stepper },
    { name: 'toolbox_tab', svg: icons.toolbox_tab },
    { name: 'toolbox_iframe', svg: icons.toolbox_iframe },
    { name: 'toolbox_embed', svg: icons.toolbox_embed },
    { name: 'toolbox_autocomplete', svg: icons.toolbox_autocomplete },
    { name: 'toolbox_video', svg: icons.toolbox_video },
    { name: 'toolbox_chart', svg: icons.toolbox_chart },
    { name: 'toolbox_upload', svg: icons.toolbox_upload },
    { name: 'toolbox_download', svg: icons.toolbox_download },
    { name: 'toolbox_loading', svg: icons.toolbox_loading },
    { name: 'chart', svg: icons.chart },
    { name: 'toolbox_capsuletag', svg: icons.toolbox_capsuletag },
    { name: 'toolbox_raster', svg: icons.toolbox_raster },
    { name: 'toolbox_grid', svg: icons.toolbox_grid },
    { name: 'toolbox_flex', svg: icons.toolbox_flex },
    { name: 'toolbox_canvas', svg: icons.toolbox_canvas },
    { name: 'toolbox_repeat', svg: icons.toolbox_repeat },
    { name: 'toolbox_expansionpanel', svg: icons.toolbox_expansionpanel },
    { name: 'toolbox_widget', svg: icons.toolbox_widget },
    { name: 'toolbox_workflow', svg: icons.toolbox_workflow },
    { name: 'toolbox_datasource', svg: icons.toolbox_datasource },
    { name: 'toolbox_properties', svg: icons.toolbox_properties },
    { name: 'toolbox_job', svg: icons.toolbox_job },
    { name: 'toolbox_logs', svg: icons.toolbox_logs },
    {
      name: 'toolbox_actionwidgettoolbox_actionwidget',
      svg: icons.toolbox_actionwidgettoolbox_actionwidget,
    },
    { name: 'toolbox_clientworkflow', svg: icons.toolbox_clientworkflow },
    { name: 'toolbox_resolution', svg: icons.toolbox_resolution },
    { name: 'toolbox_settings', svg: icons.toolbox_settings },
    {
      name: 'vertical_align_center_text',
      svg: icons.vertical_align_center_text,
    },
    { name: 'align_right_text', svg: icons.align_right_text },
    { name: 'vertical_align_bottom', svg: icons.vertical_align_bottom },
    { name: 'align_left_text', svg: icons.align_left_text },
    { name: 'align_center', svg: icons.align_center },
    { name: 'align_center_content', svg: icons.align_center_content },
    { name: 'align_center_text', svg: icons.align_center_text },
    { name: 'align_left', svg: icons.align_left },
    { name: 'align_left_content', svg: icons.align_left_content },
    { name: 'align_right', svg: icons.align_right },
    { name: 'align_right_content', svg: icons.align_right_content },
    {
      name: 'vertical_align_bottom_content',
      svg: icons.vertical_align_bottom_content,
    },
    {
      name: 'vertical_align_bottom_text',
      svg: icons.vertical_align_bottom_text,
    },
    { name: 'vertical_align_center', svg: icons.vertical_align_center },
    {
      name: 'vertical_align_center_content',
      svg: icons.vertical_align_center_content,
    },
    { name: 'vertical_align_top', svg: icons.vertical_align_top },
    {
      name: 'vertical_align_top_content',
      svg: icons.vertical_align_top_content,
    },
    { name: 'vertical_align_top_text', svg: icons.vertical_align_top_text },
    { name: 'font_color', svg: icons.font_color },
    { name: 'background_color', svg: icons.background_color },
    { name: 'image', svg: icons.image },
    { name: 'use_border', svg: icons.use_border },
    { name: 'datadescription', svg: icons.datadescription },
    { name: 'layoutunit_single', svg: icons.layoutunit_single },
    { name: 'layoutunit_multi', svg: icons.layoutunit_multi },
    { name: 'layoutunit_toall', svg: icons.layoutunit_toall },
    { name: 'layoutunit_none', svg: icons.layoutunit_none },
    { name: 'calendar', svg: icons.calendar },
    { name: 'device_hub', svg: icons.device_hub },
    { name: 'text_fields', svg: icons.text_fields },
    { name: 'toolbox_carousel', svg: icons.toolbox_carousel },
    { name: 'toolbox_href', svg: icons.toolbox_href },
    { name: 'arrow_down', svg: icons.arrow_down },
    { name: 'message', svg: icons.message },
    { name: 'workflow', svg: icons.device_hub },
    { name: 'android-icon', svg: icons.android_icon },
    { name: 'apple-icon', svg: icons.apple_icon },
    { name: 'ar-SA', svg: icons.ar_SA },
    { name: 'en-US', svg: icons.en_US },
    { name: 'globalvariable', svg: icons.globalvariable },
    { name: 'eye', svg: icons.eye },
    { name: 'language', svg: icons.language },
    { name: 'contact', svg: icons.contact },
    { name: 'report', svg: icons.report },
    { name: 'color_reset', svg: icons.color_reset },
    { name: 'gradient', svg: icons.gradient },
    { name: '_SYSTEM_DASHBOARD', svg: icons.dashboard },
    { name: 'coffee', svg: icons.coffee },
    { name: 'anchor', svg: icons.anchor },
    { name: 'move_down', svg: icons.move_down },
    { name: 'keyboard_command', svg: icons.keyboad_command },
    { name: 'event_list', svg: icons.event_list },
    { name: 'terminal', svg: icons.terminal },
    { name: 'flight', svg: icons.flight },
    { name: 'cycle', svg: icons.cycle },
    { name: 'integration', svg: icons.integration },
    { name: 'edit_new', svg: icons.edit_new },
    { name: 'display_settings', svg: icons.display_settings },
    { name: 'rocket_launch', svg: icons.rocket_launch },
  ];

  // Initialized = false;

  //#region Lifecycle
  constructor(public tourService: TourService, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    // register svg icons
    this.svg_icons.forEach((icon) => {
      this.registerIcon(icon.name, icon.svg);
    });
  }

  ngOnInit(): void {
    // const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    // this.http.get('assets/config.json', { headers }).subscribe((result) => {
    //     if (result) {
    //         if (result['CLIENTVERSION'] == "Macframework") {
    //             console.log(result['CLIENTVERSION']);
    //             //MACCCCC
    //             // ComponentRegistry.Initialize();
    //             // MACSettingsRegistryHelper.Build();
    //             // TaskRegistryHelper.Build();
    //             // MacWorkflowRegistryHelper.Build();
    //             // NavigationService.SelectedPage.subscribe(p => {
    //             //     PageStatus.ActualState.next(new PageStatus());
    //             // });
    //             //MACCCCC
    //         } else if (result['CLIENTVERSION'] == "Middleframework") {
    //             //Middle
    //             console.log(result['CLIENTVERSION']);
    //             // ComponentRegistry.Initialize();
    //             // MWSettingsRegistryHelper.Build();
    //             // MWWorkflowRegistryHelper.Build();
    //             this.Initialized = true;
    //             //Middle
    //         }
    //     }
    // });
  }

  registerIcon(name: string, svgContent: string): void {
    this.iconRegistry.addSvgIconLiteral(name, this.sanitizer.bypassSecurityTrustHtml(svgContent));
  }

  ngOnDestroy(): void {}

  //#endregion
}
