import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { BinaryOperation } from "../enums/binaryoperation.enum";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { FormulaGroups, PrimitiveFormula } from "./iformula";

export class And extends PrimitiveFormula {
    static StaticID: string = "ca7dce01-6439-4e7f-95fb-2e0ae91d8403";

    constructor() {
        super();
        this._ID = And.StaticID;
        this._Name = 'And';
        this._MaxVariableCount = 10000;
    }

    Group(): string {
        return FormulaGroups.Binary;
    }
    OrderID(): number {
        return 1;
    }
    OperationType(): OperationType {
        return OperationType.Binary;
    }
    BinaryOperation(): BinaryOperation {
        return BinaryOperation.And;
    }
    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Wert1') + '; ...; ' + TranslateHelper.TranslatorInstance.instant('@@WertX') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal: ValueAndType = {
            Type: ValueType.Bool,
            Value: true
        };
        if (args) {
            args.some(function (item) {
                if (item.Type == ValueType.Bool) {
                    if (typeof item.Value === 'boolean') {
                        retVal.Value = retVal.Value && item.Value;
                    } else {
                        retVal.Value = false;
                    }
                }
                return !retVal.Value;
            });
        }
        return retVal;
    }
}