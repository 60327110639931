import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Color } from '../../../../models/style/color.model';
import { Font } from '../../../../models/style/font.model';
import { BaseAction } from '../../base.action';

@Component({
  selector: 'fontcolor-action',
  templateUrl: './fontcolor.action.html',
  styleUrls: ['./fontcolor.action.css'],
})
export class FontColorAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'fontcolor';
    
   //#region SelectedFontColor
   @Input()
   get SelectedFontColor() {
       if (this.SelectedItemValue && this.SelectedItemValue.Font) {
           return Color.HexFromColor(this.SelectedItemValue.Font.FontColor);
       }
       return '';
   }
   set SelectedFontColor(val) {
       if (this.SelectedItemValue) {
           if (!this.SelectedItemValue.Font) {
               this.SelectedItemValue.Font = new Font();
           }
           this.SelectedItemValue.Font.FontColor = Color.FromHex(val);
       }
       this.SelectedFontColorChange.emit(val);
   }

   @Output() SelectedFontColorChange = new EventEmitter<any>();
   //#endregion

    ControlInitialized() {
    }

    ExecuteAction() {
    }

    Visibility() {
        return true;
    }
}
