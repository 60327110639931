import { Directionality } from "@angular/cdk/bidi";
import { Subject } from "rxjs";

// @dynamic
export class RTLHelper {
    static Directionality: Directionality;
    public static Initialize(dir: Directionality) {
        this.Directionality = dir;
    }
    private static _Direction = 'ltr';
    static get Direction() {
        return this._Direction;
    }
    static set Direction(value) {
        this._Direction = value;
        this.DirectionChanged.next(value);
    }
    public static DirectionChanged: Subject<string> = new Subject();
}
