import { Component } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DatadescriptionData } from '../../../models/datadescription/datadescriptiondata.model';
import { DatadescriptionDescription } from '../../../models/datadescription/dataDescriptionDescription.model';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { LayoutService } from '../../../services/layout.service';
import { ABaseTreeNode, IDKeeper } from '../../common/basetreecontrol/base.tree.control';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';
import { DatadescriptionEditDialog } from '../../dialogs/datadescription/datadescription.edit.dialog';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'Datadescription-menu-tab',
    templateUrl: './datadescription.menu.tab.html',
    styleUrls: ['./datadescription.menu.tab.css']
})
export class DatadescriptionMenuTab extends ALayoutMenuTab {

    SelectedNode;
    RootNodes = [];
    IDKeeper = new IDKeeper();

    get DatadescriptionCaption() {
        if (this.SelectedNode && this.SelectedNode.Datadescription) {
            return this.SelectedNode.Datadescription.Caption;
        }
        return '';
    }
    set DatadescriptionCaption(val) {
        if (this.SelectedNode && this.SelectedNode.Datadescription && val !== this.SelectedNode.Datadescription.Caption) {
            this.SelectedNode.Datadescription.Caption = val;
        }
    }

    private static buildTreeRecursive(layout, nodeList, editable, idKeeper: IDKeeper) {
        if (layout.Elements) {
            layout.Elements.forEach(elem => {
                if (elem.ElementType === 'template') {
                    const mainNode = new DatadescriptionNode(idKeeper.NextID);
                    mainNode.Caption = elem.Name;
                    mainNode.HasChildren = true;
                    mainNode.Children = [];
                    mainNode.editable = editable && elem.LoadByReference !== true;
                    if (elem.Datadescriptions) {
                        elem.Datadescriptions.forEach(wf => {
                            const node = new DatadescriptionNode(idKeeper.NextID);
                            node.Caption = wf.Caption;
                            node.Datadescription = wf.Datadescription;
                            node.editable = mainNode.editable;
                            node.parentLayout = elem;
                            mainNode.Children.push(node);
                        });
                    }
                    nodeList.push(mainNode);
                    DatadescriptionMenuTab.buildTreeRecursive(elem, mainNode.Children, mainNode.editable, idKeeper);
                } else {
                    DatadescriptionMenuTab.buildTreeRecursive(elem, nodeList, editable, idKeeper);
                }
            });
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.buildTree();
    }

    buildTree() {
        const data = [];
        const layout = this.Layout;
        this.IDKeeper.reset();
        if (layout) {
            if (layout.Datadescriptions) {
                layout.Datadescriptions.forEach(wf => {
                    const node = new DatadescriptionNode(this.IDKeeper.NextID);
                    node.Caption = wf.Caption;
                    node.Datadescription = wf;
                    node.editable = true;
                    node.parentLayout = layout;
                    data.push(node);
                });
            }
            DatadescriptionMenuTab.buildTreeRecursive(layout, data, true, this.IDKeeper);
        }
        this.RootNodes = data;
    }

    onCaptionChanged() {
        if (this.SelectedNode && this.SelectedNode.Datadescription) {
            this.SelectedNode.Caption = this.SelectedNode.Datadescription.Caption;
            LayoutService.OnLayoutPropertyChanged(this.SelectedNode.parentLayout.ID, 'Datadescriptions',
                this.SelectedNode.parentLayout.Datadescriptions);
            this.cdRef.detectChanges();
        }
    }

    AddDatadescription() {
        const layout = this.Layout;
        if (layout) {
            const newDD = new DatadescriptionDescription();
            newDD.Caption = 'New Datadescription';
            newDD.ID = UUID.UUID();
            const node = new DatadescriptionNode(this.IDKeeper.NextID);
            node.Caption = newDD.Caption;
            node.Datadescription = newDD;
            node.editable = true;
            node.parentLayout = layout;
            this.showDialog(node, () => {
                if (!layout.Datadescriptions) {
                    layout.Datadescriptions = [];
                }
                layout.Datadescriptions.push(newDD);
                const nodes = this.RootNodes.slice();
                nodes.splice(layout.Datadescriptions.length - 1, 0, node);
                this.RootNodes = nodes;
                this.SelectedNode = node;
            });
        }
    }

    AddCopyDatadescription() {
        const layout = this.Layout;
        const oldDD = this.SelectedNode.Datadescription;
        if (layout) {
            const newDD = new DatadescriptionDescription();
            newDD.Caption = oldDD.Caption + ' (Kopie)';
            newDD.ID = UUID.UUID();
            newDD['DataModelID'] = oldDD.DataModelID;
            newDD.Datadescription = oldDD.Datadescription;
            newDD.Datadescription['ID'] = UUID.UUID();
            newDD['IsRelational'] = oldDD.IsRelational;
            const node = new DatadescriptionNode(this.IDKeeper.NextID);
            node.Caption = newDD.Caption;
            node.Datadescription = newDD;
            node.editable = true;
            node.parentLayout = layout;
            this.showDialog(node, () => {
                if (!layout.Datadescriptions) {
                    layout.Datadescriptions = [];
                }
                layout.Datadescriptions.push(newDD);
                const nodes = this.RootNodes.slice();
                nodes.splice(layout.Datadescriptions.length - 1, 0, node);
                this.RootNodes = nodes;
                this.SelectedNode = node;
            });

        }
    }

    EditDatadescription() {
        if (this.SelectedNode && this.SelectedNode.Datadescription && this.SelectedNode.editable) {
            this.showDialog(this.SelectedNode, null);
        }
    }

    async DeleteDatadescription() {
        const node = this.SelectedNode;
        if (node && node.Datadescription && node.editable) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Datadescription {0} loeschen moechten?');
            text.FormatParams.push(node.Datadescription.Caption);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Datadescription loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                node.parentLayout.Datadescriptions.splice(node.parentLayout.Datadescriptions.indexOf(node.Datadescription), 1);
                this.buildTree();
                this.SelectedNode = null;
                LayoutService.OnLayoutPropertyChanged(node.parentLayout.ID, 'Datadescriptions', node.parentLayout.Datadescriptions);
            }
        }
    }

    showDialog(node, handler) {
        if (node.Datadescription) {
            BaseDialog.ShowDialog({
                ContentType: DatadescriptionEditDialog,
                InitArgs: { Data: node.Datadescription, Layout: node.parentLayout },
                Title: node.Datadescription.Caption,
                Buttons: DialogButton.Cancel,
                Handler: (result) => {
                    if (result instanceof DatadescriptionData) {
                        node.Datadescription.Datadescription = result.Datadescription;
                        node.Datadescription.DataModelID = result.DataModelID;
                        node.Datadescription.IsRelational = result.IsRelational;
                        if (typeof handler === 'function') {
                            handler();
                        }
                        LayoutService.OnLayoutPropertyChanged(node.parentLayout.ID, 'Datadescriptions', node.parentLayout.Datadescriptions);
                    }
                    return true;
                },
                HeightText: '100vh',
                WidthText: '100vw',
                AutoFocus: false
            });
        }
    }
}

export class DatadescriptionNode extends ABaseTreeNode {
    Datadescription;
    editable = false;
    parentLayout;
}
