import { UUID } from 'angular2-uuid';
import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class NewGuid extends AFormula {
    static StaticID = '55b4b581-adbe-421a-8ffe-db6bb78a48f4';

    ID(): string {
        return NewGuid.StaticID;
    }
    Name(): string {
        return 'NewGuid';
    }
    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Neue Guid');
    }
    Group(): string {
        return FormulaGroups.Functions;
    }
    OrderID(): number {
        return 1500;
    }
    OperationType(): OperationType {
        return OperationType.Function;
    }
    OperationValue(): string {
        return this.Name() + '()';
    }
    MinVariableCount(): number {
        return 0;
    }
    MaxVariableCount(): number {
        return 0;
    }
    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        return {
            Type: ValueType.Object,
            Value: UUID.UUID()
        };
    }
}
