import { Component } from '@angular/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { FtpDataConnectionType, FtpEncryptionMode } from '../../../../../models/enums';
import { ResourceLoginType, UserConfig } from '../../../../../models/userconfig.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'ftpdb-panel',
    templateUrl: './ftpdb.panel.html',
    styleUrls: ['./ftpdb.panel.css']
})
export class FTPDBPanel extends BaseDataSourcePanel {

    FTPTypes = [];
    DataConnectionTypes = [];
    EncryptionModes = [];

    constructor() {
        super();
        this.DBInfoType = FTPDBInfo;
        this.FTPTypes = EnumHelper.GetDropdownValues(FtpConnectionType);
        this.DataConnectionTypes = EnumHelper.GetDropdownValues(FtpDataConnectionType);
        this.EncryptionModes = EnumHelper.GetDropdownValues(FtpEncryptionMode);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: FTPDBPanel,
            DBInfoType: FTPDBInfo
        };
    }
}

export class FTPDBInfo extends ADBInfo {
    Server: string = null;
    Port: number = 0;
    LoginType: ResourceLoginType = ResourceLoginType.Anonymous;
    Username: string = null;
    Password: string = null;
    FtpType: FtpConnectionType = FtpConnectionType.Ftp;
    User: UserConfig = null;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.NonDatabase.FtpDBInfo';
    }
}

export enum FtpConnectionType {
    Ftp,
    SFtp,
    SCP
}