import { Component, ViewChild } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../workflow.dialog';
import { ABaseContainerSettingsData } from '../container.chooser';

@Component({
    selector: 'wf-execute-count-settings',
    templateUrl: './execute.count.settings.html'
})
export class ExecuteCountSettings extends ContainerWorkflowDialogContent {
    Data = new ExecuteCountSettingsData();
    @ViewChild('chooser') ContainerChooser;
    DBUsages = [[4096, 16]]; // RelationalRead oder DocumentStoreRead

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'executeCountWFModule';
        reg.Caption = '@@Count';
        reg.GroupID = 'containeroperations';
        reg.Index = 50;
        reg.SettingsControl = ExecuteCountSettings;
        reg.SettingsTypeHelper = new ExecuteCountSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ExecuteCountSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (!this.Data.ContainerTable) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie eine Tabelle';
        }
        return retVal;
    }

    getResult(): any {
        if (this.Data.InteractiveProperties) {
            this.Data.InteractiveProperties = this.Data.InteractiveProperties.filter(x => x.Value);
        }
        if (this.Data.ContainerTable && this.ContainerChooser) {
            this.ContainerChooser.CheckActualFilter();
        }
        return this.Data;
    }
}

export class ExecuteCountSettingsData extends ABaseContainerSettingsData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ContainerOperations.ExecuteCount.ExecuteCountSettingsData';
    }
}

export class ExecuteCountSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ExecuteCountSettingsData();
    }
}
