import { Component } from "@angular/core";
import { MediaPanelInfo } from "../../../../../models/datamodel/mediasource.model";
import { BaseMediaSourcePanel } from "../basemediasource.panel";

@Component({
    selector: 'auzureblob-panel',
    templateUrl: './auzureblob.panel.html',
    styleUrls: ['./auzureblob.panel.css']
})
export class AzureBlobPanel extends BaseMediaSourcePanel {
    constructor() {
        super();
        this.MediaSourceType = AzureBlobPanel;
    }

    static GetInfo(): MediaPanelInfo {
        return { 
            ControlType: AzureBlobPanel,
            MediaType: 'A4281B52-C92A-4AB0-81CE-50275F7CFE02'.toLowerCase()
        };
    }
}