import { Component } from '@angular/core';
import { Type } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import {
    StepIntoHandler, WorkflowModuleExecuter, WorkflowParameter, WorkflowSaveObject, WorkflowStatus
} from '../../../models/workflow/workflow.model';
import {
    ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry, WorkflowService
} from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowEngine } from '../../workflow.engine';

@Component({
    selector: 'wf-template-settings',
    templateUrl: './template.settings.html',
    styleUrls: ['./template.settings.css']
})
export class TemplateSettings extends WorkflowDialogContent {
    static readonly ModuleID = 'wfTemplateModule';

    ParameterValues = [];
    OrigData;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = TemplateSettings.ModuleID;
        reg.Caption = '@@Template';
        reg.GroupID = '-1';
        reg.SettingsControl = TemplateSettings;
        reg.SettingsTypeHelper = new TemplateSettingsDataHelper();
        reg.Executer = TemplateModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = TemplateSettingsData.ModuleID;
        return reg;
    }

    constructor(private wfService: WorkflowService) {
        super();
    }

    initialize(data: any) {
        if (data) {
            this.OrigData = data;
            this.wfService.LoadWorkflow(data.TemplateID).subscribe((wf) => {
                const paramList = [];
                const paraValues = data.ParameterValues || [];
                if (wf && wf.Parameters && wf.Parameters.length > 0) {
                    wf.Parameters.forEach(p => {
                        const par: any = {
                            Value: {
                                Name: p.Name,
                                Type: WorkflowParameter.GetPropertyTypeFromParameterType(p.Type)
                            }
                        };
                        paraValues.some(pv => {
                            if (pv.ParameterName === par.Value.Name) {
                                par.Value.Value = pv.Value;
                                return true;
                            }
                            return false;
                        });
                        paramList.push(par);
                    });
                }
                this.ParameterValues = paramList;
            });
        }
    }

    getResult(): any {
        if (this.OrigData) {
            const paramList = [];
            this.ParameterValues.forEach(pv => {
                if (typeof pv.Value.Value !== 'undefined' && pv.Value.Value !== null) {
                    const tpv = new TemplateParameterValue();
                    tpv.ParameterName = pv.Value.Name;
                    tpv.Value = pv.Value.Value;
                    paramList.push(tpv);
                }
            });
            this.OrigData.ParameterValues = paramList;
        }
        return this.OrigData;
    }
}
// @dynamic
export class TemplateSettingsData {
    static readonly ModuleID = 'wfTemplateAdvancedModule';

    TemplateID: string;
    @Type(() => TemplateParameterValue)
    ParameterValues: TemplateParameterValue[] = [];
    TemplateName: string;
}

export class TemplateParameterValue {
    ParameterName: string;
    Value: any;
}

export class TemplateSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new TemplateSettingsData();
    }
    getAdditionalCaption(settings) {
        if (settings) {
            return settings.TemplateName;
        }
        return super.getAdditionalCaption(settings);
    }
    getStepIntoHandler(settings, workflowID: string, moduleID: number): StepIntoHandler {
        if (settings && settings.TemplateID) {
            return new StepIntoHandler(workflowID, moduleID);
        }
        return null;
    }
}

export class TemplateModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const wfService = InjectorHelper.InjectorInstance.get<WorkflowService>(WorkflowService);
            const wf: WorkflowSaveObject = await wfService.LoadWorkflow(status.ActualSettings.TemplateID).toPromise();
            if (wf) {
                if (wf.Modules) {
                    const modules = [];
                    wf.Modules.forEach(x => {
                        modules.push(WorkflowSaveObject.DeserializeModuleSettings(x));
                    });
                    wf.Modules = modules;
                }
                if (wf.Parameters && wf.Parameters.length > 0) {
                    const paramValues = status.ActualSettings.ParameterValues || [];
                    if (wf.Parameters.some(wfp => {
                        let value = wfp.Default;
                        paramValues.some(pv => {
                            if (pv.ParameterName === wfp.Name && typeof pv.Value !== 'undefined' && pv.Value !== null) {
                                value = pv.Value;
                                return true;
                            }
                            return false;
                        });
                        if (typeof value !== 'undefined' && value != null) {
                            status.Context.set(wfp.Name, value);
                        } else if (wfp.Required) {
                            let caption = TranslatedString.GetTranslation(wf.Caption);
                            if (caption) {
                                caption = ' for workflow ' + caption;
                            }
                            status.Logger.logError('Template modul: Required parameter ' + wfp.Name + caption + ' not set.');
                            return true;
                        }
                        return false;
                    })) {
                        return super.execute(status);
                    }
                }
                const newStatus = new WorkflowStatus(status.ActualSettings.TemplateID, TranslatedString.GetTranslation(wf.Caption), status);
                newStatus.ActualParameter = status.ActualParameter;
                newStatus.Logger = status.Logger;
                WorkflowStatus.CopyContext(status.Context, newStatus.Context);
                newStatus.WorkflowLayoutService = status.WorkflowLayoutService;
                newStatus.WorkflowID = status.ActualSettings.TemplateID;
                const engine = new WorkflowEngine(wf, newStatus);
                engine.CheckExpressions = true;
                engine.PageSID = 'template';
                await engine.startExecution();
                status.ActualParameter = newStatus.ActualParameter;
                return 0;
            } else {
                status.Logger.logError('Template modul: Template with id ' + status.ActualSettings.TemplateID + ' not found.');
            }
        } else {
            status.Logger.logError('Template modul: No settings found.');
        }
        return super.execute(status);
    }
}
