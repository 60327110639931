import { ChangeDetectorRef, Component } from '@angular/core';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { DimensionType } from '../../../models/enums';
import { Comparer } from '../../../models/enums/comparer.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataModelService } from '../../../services/datamodel.service';
import { DynamicTimeSettingsService } from '../../../services/dynamictimesettings.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'fromto-filter-menutab',
    templateUrl: './from.to.filter.menu.tab.html',
    styles: ['.mTop { margin-top: 10px; }']
})
export class FromToFilterMenuTab extends APropertyTab {

    AllDataModels = [];
    DynamicTimePoints = [];
    Dimensions = [];
    SelectedDimension;
    SelectedHierarchy;
    SelectedLevel;
    Layout;

    constructor(private dataModelService: DataModelService, private dynService: DynamicTimeSettingsService, cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = LayoutHelper.GetActiveResolution(layout);
        }));
        this.dataModelService.GetModels().subscribe(models => {
            if (models) {
                this.AllDataModels = models;
                this.cdRef.detectChanges();
            }
        });
        const filterCol = new RequestFilter();
        filterCol.Name = 'TimeType';
        filterCol.Operator = Comparer.In;
        filterCol.Value = [0, 2];
        const filter = RequestOptions.CleanRequestOptions();
        filter.Filters.push(filterCol);
        this.dynService.GetDynamicTimePointsFiltered(filter).subscribe(x => {
            if (x) {
                this.DynamicTimePoints = x;
                this.cdRef.detectChanges();
            }
        });
    }

    relationalChanged() {
        this.onItemSet();
        this.onStyleChanged('Relational');
    }

    onItemSet() {
        this.Dimensions = [];
        this.SelectedDimension = null;
        this.SelectedHierarchy = null;
        const selected = this.SelectedItemValue;
        if (selected && !selected.Relational && selected.DataModel) {
            MultiCacheService.GetDimensions(selected.DataModel).then(dimensions => {
                if (dimensions) {
                    const timeDims = [];
                    let dimFound = false;
                    dimensions.forEach(dim => {
                        if (dim.DimensionType === DimensionType.Time) {
                            timeDims.push(dim);
                            if (!dimFound && dim.Hierarchies && selected.Level) {
                                dim.Hierarchies.some(hier => {
                                    if (hier.Levels && hier.Levels.some(l => l.ID === selected.Level)) {
                                        this.SelectedDimension = dim;
                                        this.SelectedHierarchy = hier;
                                        dimFound = true;
                                    }
                                    return dimFound;
                                });
                            }
                        }
                    });
                    this.Dimensions = timeDims;
                }
            });
        }
    }

    dataModelChanged() {
        this.Dimensions = [];
        this.SelectedDimension = null;
        this.SelectedHierarchy = null;
        if (this.SelectedItemValue) {
            const changeProps = ['DataModel'];
            if (this.SelectedItemValue.Level != null) {
                this.SelectedItemValue.Level = null;
                changeProps.push('Level');
            }
            if (this.SelectedItemValue.LevelType != null) {
                this.SelectedItemValue.LevelType = null;
                changeProps.push('LevelType');
            }
            if (this.SelectedItemValue.DataModel) {
                MultiCacheService.GetDimensions(this.SelectedItemValue.DataModel).then(dimensions => {
                    if (dimensions) {
                        const timeDims = [];
                        dimensions.forEach(dim => {
                            if (dim.DimensionType === DimensionType.Time) {
                                timeDims.push(dim);
                            }
                        });
                        this.Dimensions = timeDims;
                    }
                });
            }
            this.onStyleChanged(changeProps);
        }
    }

    dimChanged() {
        this.SelectedHierarchy = null;
        if (this.SelectedItemValue) {
            const changeProps = [];
            if (this.SelectedItemValue.Level != null) {
                this.SelectedItemValue.Level = null;
                changeProps.push('Level');
            }
            if (this.SelectedItemValue.LevelType != null) {
                this.SelectedItemValue.LevelType = null;
                changeProps.push('LevelType');
            }
            this.onStyleChanged(changeProps);
        }
    }

    hierarchyChanged() {
        if (this.SelectedItemValue) {
            const changeProps = [];
            if (this.SelectedItemValue.Level != null) {
                this.SelectedItemValue.Level = null;
                changeProps.push('Level');
            }
            if (this.SelectedItemValue.LevelType != null) {
                this.SelectedItemValue.LevelType = null;
                changeProps.push('LevelType');
            }
            this.onStyleChanged(changeProps);
        }
    }

    levelChanged() {
        if (this.SelectedItemValue) {
            const changeProps = ['Level'];
            let lt = null;
            if (this.SelectedItemValue.Level) {
                const lev = this.SelectedHierarchy.Levels.find(x => x.ID === this.SelectedItemValue.Level);
                if (lev) {
                    lt = lev.LevelType;
                }
            }
            if (this.SelectedItemValue.LevelType != lt) {
                this.SelectedItemValue.LevelType = lt;
                changeProps.push('LevelType');
            }
            this.onStyleChanged(changeProps);
        }
    }
}
