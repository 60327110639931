import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { MetaHelper } from '../../../helpers/meta.helper';
import { FormulaNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { Order } from '../../../models/enums/order.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'simple-listbox-menu-tab',
    templateUrl: './listbox.menu.tab.html',
    styleUrls: ['./listbox.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleListBoxMenuTab extends APropertyTab {

    DisplayFormula = '';
    Variables = [];
    TablePropertiesValue;
    Order;

    @Input()
    get TableProperties() {
        return this.TablePropertiesValue;
    }
    set TableProperties(val) {
        this.TablePropertiesValue = val;
    }

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
        this.Order = EnumHelper.GetDropdownValues(Order);
    }

    displayMemberChanged() {
        if (this.SelectedItemValue && this.SelectedItemValue.Element &&
            typeof this.SelectedItemValue.Element.UpdateCaptionGetter === 'function') {
            this.SelectedItemValue.Element.UpdateCaptionGetter();
        }
    }

    onLabelChanged() {
        if (this.SelectedItemValue) {
            const parser = new AdvancedFormulaParser();
            parser.SetVariables(this.Variables, FormulaInputMode.VariableName);
            try {
                this.SelectedItemValue.DisplayFormula = parser.Parse(this.DisplayFormula);
            } catch {
                if (this.SelectedItemValue.DisplayFormula) {
                    const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.SelectedItemValue.DisplayFormula);
                    if (formula) {
                        this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                    } else {
                        this.DisplayFormula = '';
                        this.SelectedItemValue.DisplayFormula = null;
                    }
                }
            }
            this.displayMemberChanged();
            this.onStyleChanged('DisplayFormula');
        }
    }

    editFormula() {
        if (this.SelectedItemValue) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables,
                Formula: this.DisplayFormula,
                InputMode: FormulaInputMode.VariableName
            }, (r) => {
                if (r) {
                    this.DisplayFormula = r.Formula;
                    this.onLabelChanged();
                }
                return true;
            });
        }
    }
}

@Component({
    selector: 'listbox-menu-tab',
    templateUrl: './listbox.menu.tab.html',
    styleUrls: ['./listbox.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxMenuTab extends ALayoutMenuTab {

    DisplayFormula = '';
    Variables = [];
    TableProperties;
    Order;
    DataBindingChangedSubscription;

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
        this.Order = EnumHelper.GetDropdownValues(Order);
    }

    onItemSet() {
        if (this.DataBindingChangedSubscription != null) {
            this.DataBindingChangedSubscription.unsubscribe();
            this.DataBindingChangedSubscription = null;
        }
        if (this.SelectedItemValue.DataBindingChanged) {
            this.DataBindingChangedSubscription = this.SelectedItem.DataBindingChanged.subscribe((Property) => {
                this.LoadTableProperties();
            });
        }
        this.LoadTableProperties();
    }

    onLayoutSet() {
        this.LoadTableProperties();
    }

    LoadTableProperties() {
        this.DisplayFormula = '';
        this.Variables = [];
        this.TableProperties = null;
        const sel = this.SelectedItemValue;
        if (sel) {
            if (this.Layout) {
                MetaHelper.FindDataBindingProperties(this.Layout, sel).then(result => {
                    if (result) {
                        const fields = result.Table.Fields;
                        if (fields) {
                            const variables = [];
                            fields.forEach(field => {
                                const vni = new VariablesNodeInformation();
                                vni.VariableID = field.ID;
                                vni.Name = field.TranslatedCaption;
                                variables.push(vni);
                            });
                            this.Variables = variables;
                        }
                        this.TableProperties = result.Table;
                        this.cdRef.detectChanges();
                    }
                });
            }
            if (sel.DisplayFormula) {
                const formula = FormulaNodeInformation.ToFormulaNodeInformation(sel.DisplayFormula);
                if (formula) {
                    this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                }
            }
        }
    }

    displayMemberChanged() {
        if (this.SelectedItemValue && this.SelectedItemValue.Element &&
            typeof this.SelectedItemValue.Element.UpdateCaptionGetter === 'function') {
            this.SelectedItemValue.Element.UpdateCaptionGetter();
        }
    }

    onLabelChanged() {
        if (this.SelectedItemValue) {
            const parser = new AdvancedFormulaParser();
            parser.SetVariables(this.Variables, FormulaInputMode.VariableName);
            try {
                this.SelectedItemValue.DisplayFormula = parser.Parse(this.DisplayFormula);
            } catch {
                if (this.SelectedItemValue.DisplayFormula) {
                    const formula = FormulaNodeInformation.ToFormulaNodeInformation(this.SelectedItemValue.DisplayFormula);
                    if (formula) {
                        this.DisplayFormula = formula.ToString(null, VariableDisplay.NodeName);
                    } else {
                        this.DisplayFormula = '';
                        this.SelectedItemValue.DisplayFormula = null;
                    }
                }
            }
            this.displayMemberChanged();
            this.onStyleChanged('DisplayFormula');
        }
    }

    editFormula() {
        if (this.SelectedItemValue) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables,
                Formula: this.DisplayFormula,
                InputMode: FormulaInputMode.VariableName
            }, (r) => {
                if (r) {
                    this.DisplayFormula = r.Formula;
                    this.onLabelChanged();
                }
                return true;
            });
        }
    }
}

@Component({
    selector: 'listbox-property-menu-tab',
    template: '<listbox-menu-tab [(SelectedItem)]="SelectedItem"></listbox-menu-tab>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxPropertyMenuTab extends APropertyTab {
}
