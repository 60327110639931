import { Directive, Component, TemplateRef, ContentChild, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';


@Directive({
    selector: '[buttonContent]'
})
export class DropDownButtonContentDirective { }

@Directive({
    selector: '[dropDownContent]'
})
export class DropDownContentDirective { }

@Component({
    selector: 'dropdown-control',
    templateUrl: './dropdown.control.html',
    styleUrls: ['./dropdown.control.css']
})
export class DropDownControl {
    @ContentChild(DropDownButtonContentDirective, { read: TemplateRef }) buttonContentTemplate;
    @ContentChild(DropDownContentDirective, { read: TemplateRef }) dropDownContentTemplate;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    DropDownTextValue = '';
    @Input()
    get DropDownText(): string {
        return this.DropDownTextValue;
    }
    set DropDownText(val: string) {
        this.DropDownTextValue = val;
    }

    EmptyTextValue = '';
    @Input()
    get EmptyText(): string {
        return this.EmptyTextValue;
    }
    set EmptyText(val: string) {
        this.EmptyTextValue = val;
    }

    MenuClassValue;
    @Input()
    get MenuClass(): string {
        return this.MenuClassValue;
    }
    set MenuClass(val: string) {
        this.MenuClassValue = val;
    }

    MenuPanelClassValue;
    @Input()
    get MenuPanelClass(): string {
        return this.MenuPanelClassValue;
    }
    set MenuPanelClass(val: string) {
        this.MenuPanelClassValue = val;
    }

    @Output() DropDownOpened = new EventEmitter<any>();
    @Output() DropDownClosed = new EventEmitter<any>();

    onOpened() {
        this.DropDownOpened.emit();
    }

    onClosed() {
        this.DropDownClosed.emit();
    }

    close() {
        if (this.menuTrigger) {
            this.menuTrigger.closeMenu();
        }
    }

    open() {
        if (this.menuTrigger) {
            this.menuTrigger.openMenu();
        }
    }

    setFocus() {
        if (this.menuTrigger) {
            this.menuTrigger.focus();
        }
    }
}
