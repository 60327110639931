export class CellReference {
    public Name: string;
    public DataDescriptionID: string;
    public X: {};
    public Y: {};
}
export class CellReferenceArea {
    public Selected: boolean;
    public AreaID: string;
    public Levels: {};
    public MeasureID: string;
}
export class CellReferenceLevel {
    public Selected: boolean;
    public MemberID: string;
    public LevelID: string;
}