import { DataDescription } from "../../../../../models/datadescription/multi/datadescription.model";
import { Input, Component } from "@angular/core";
import { MeasureInfo, MeasureFunctionParentReferencing } from "../../../../../models/datadescription/multi/measureinfo.model";



@Component({
    selector: 'parentreferenceControl',
    templateUrl: './parentreference.control.html',
    styleUrls: ['./parentreference.control.css']
})
export class ParentReferenceControl {


    DataDescriptionValue: DataDescription;
    MeasureFunctionParentReferencing: MeasureFunctionParentReferencing;

    @Input()
    get SelectedMeasure() {
        return this.SelectedMeasureValue;
    }
    set SelectedMeasure(value) {
        this.SelectedMeasureValue = value;
        if (this.SelectedMeasureValue && this.SelectedMeasureValue.Details) {
            if (!this.SelectedMeasureValue.Details.FunctionParentReferencing) {
                this.SelectedMeasure.Details.FunctionParentReferencing = new MeasureFunctionParentReferencing();
            }
            this.MeasureFunctionParentReferencing = this.SelectedMeasureValue.Details.FunctionParentReferencing;
        }
    }


    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;

    }

    SelectedMeasureValue: MeasureInfo;

  

    async allDimensionChanged(item) {
    }
}