import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { ResourceLoginType, UserConfig } from '../../../../../models/userconfig.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'fsdb-panel',
    templateUrl: './filesystemdb.panel.html',
    styleUrls: ['./filesystemdb.panel.css']
})
export class FSDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = FSDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: FSDBPanel,
            DBInfoType: FSDBInfo
        };
    }
}

export class FSDBInfo extends ADBInfo {
    SourcePath: string = null;
    LoginType: ResourceLoginType = ResourceLoginType.Anonymous;
    Username: string = null;
    Password: string = null;
    User: UserConfig;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.NonDatabase.FSDBInfo';
    }
}