import { OnInit, Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { StandardRequestBase } from '../../../services/request-base';

@Component({
    selector: 'translation-control',
    templateUrl: './translation.control.html',
    styleUrls:['./translation.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationControl implements OnInit {

    Languages = [];

    //#region TranslationEntry
    TranslationEntryValue;
    @Input()
    get TranslationEntry() {
        return this.TranslationEntryValue;
    }
    set TranslationEntry(val) {
        this.TranslationEntryValue = val;
        this.checkValues();
        this.TranslationEntryChange.emit(this.TranslationEntryValue);
    }
    @Output() TranslationEntryChange = new EventEmitter<any>();
    //#endregion

    //#region DisableKey
    DisableKeyValue = true;
    @Input()
    get DisableKey() {
        return this.DisableKeyValue;
    }
    set DisableKey(val) {
        this.DisableKeyValue = val;
        this.DisableKeyChange.emit(this.DisableKeyValue);
    }
    @Output() DisableKeyChange = new EventEmitter<any>();
    //#endregion

    @Output() TranslationChanged = new EventEmitter<any>();

    constructor(private service: StandardRequestBase, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.service.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            if (languages) {
                const list = [];
                languages.forEach(x => {
                    list.push({
                        Caption: x.Caption,
                        LCID: x.LCID,
                        Value: ''
                    });
                });
                this.Languages = list;
                this.checkValues();
            }
        });
    }

    checkValues() {
        const te = this.TranslationEntryValue;
        if (te && te.Translations) {
            this.Languages.forEach(lang => {
                const trans = te.Translations.find(x => x.LCID === lang.LCID);
                if (trans) {
                    lang.Value = trans.Value;
                } else {
                    lang.Value = '';
                }
            });
        } else {
            this.Languages.forEach(x => { x.Value = ''; });
        }
        this.cdRef.detectChanges();
    }

    languageChanged(lang) {
        const te = this.TranslationEntryValue;
        if (te) {
            if (te.Translations) {
                const trans = te.Translations.find(x => x.LCID === lang.LCID);
                if (trans) {
                    trans.Value = lang.Value;
                    trans.Changed = true;
                } else {
                    te.Translations.push({ LCID: lang.LCID, Value: lang.Value, Changed: true });
                }
            } else {
                te.Translations = [{ LCID: lang.LCID, Value: lang.Value, Changed: true }];
            }
            this.onTranslationChanged();
        }
    }

    onTranslationChanged() {
        this.TranslationChanged.emit();
    }
}
