import { Component, Input } from '@angular/core';

@Component({
    selector: 'region-control',
    templateUrl: 'region.control.html'
})
export class RegionControl {
    DataValue = new RegionSetting();

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val !== this.DataValue) {
            this.DataValue = val;
            this.checkRegions();
        }
    }

    Regions = [];

    Countries = [{
        Caption: '@@Deutschland',
        Value: 'Germany',
        Regions: [
            { Caption: '@@Baden-Wuerttemberg', Value: 'BadenWurttemberg' },
            { Caption: '@@Bayern', Value: 'Bavaria' },
            { Caption: '@@Berlin', Value: 'Berlin' },
            { Caption: '@@Brandenburg', Value: 'Brandenburg' },
            { Caption: '@@Bremen', Value: 'Bremen' },
            { Caption: '@@Hamburg', Value: 'Hamburg' },
            { Caption: '@@Hessen', Value: 'Hesse' },
            { Caption: '@@Mecklenburg-Vorpommern', Value: 'MecklenburgVorpommern' },
            { Caption: '@@Niedersachsen', Value: 'LowerSaxony' },
            { Caption: '@@Nordrhein-Westfalen', Value: 'NorthRhineWestphalia' },
            { Caption: '@@Rheinland-Pfalz', Value: 'RhinelandPalatinate' },
            { Caption: '@@Saarland', Value: 'Saarland' },
            { Caption: '@@Sachsen', Value: 'Saxony' },
            { Caption: '@@Sachsen-Anhalt', Value: 'SaxonyAnhalt' },
            { Caption: '@@Schleswig-Holstein', Value: 'SchleswigHolstein' },
            { Caption: '@@Thueringen', Value: 'Thuringia' },
        ]
    }, {
        Caption: '@@Oesterreich',
        Value: 'Austria',
        Regions: []
    }, {
        Caption: '@@Schweiz',
        Value: 'Switzerland',
        Regions: [
            { Caption: '@@Genf', Value: 'Geneva' },
            { Caption: '@@Waadt', Value: 'Vaud' },
        ]
    }];

    checkRegions() {
        const regions = [];
        if (this.DataValue) {
            if (this.DataValue.Country) {
                const country = this.Countries.find(x => x.Value === this.DataValue.Country);
                if (country) {
                    regions.push(...country.Regions);
                }
            }
            if (this.DataValue.Region && !regions.some(x => x.Value === this.DataValue.Region)) {
                this.DataValue.Region = null;
            }
        }
        this.Regions = regions;
    }
}

export class RegionSetting {
    Country: string;
    Region: string;
}
