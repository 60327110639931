import { Directive, Input, OnInit } from '@angular/core';

@Directive()
export abstract class ABaseCell implements OnInit {
    //#region Row
    RowValue;

    @Input()
    get Row() {
        return this.RowValue;
    }
    set Row(val) {
        if (val !== this.RowValue) {
            this.RowValue = val;
            this.checkCellStyle();
        }
    }
    //#endregion
    //#region Column
    ColumnValue;

    @Input()
    get Column() {
        return this.ColumnValue;
    }
    set Column(val) {
        if (val !== this.ColumnValue) {
            this.ColumnValue = val;
            this.checkCellStyle();
        }
    }
    //#endregion
    //#region DataTable
    DataTableValue: any;

    @Input()
    get DataTable() {
        return this.DataTableValue;
    }
    set DataTable(val: any) {
        this.DataTableValue = val;
        if (this.DataTable && this.DataTable.DataSource && this.DataTable.DataSource.ValuesChanged) {
            this.DataTable.DataSource.ValuesChanged.subscribe((res) => {
                this.checkCellStyle();
            })
        }
    }
    //#endregion
    ActCellStyle = {};
    ShowToolTip = false;
    Initialized = false;

    ngOnInit(): void {
        this.Initialized = true;
        this.checkCellStyle();
    }

    //#region ToolTip
    enter(event: MouseEvent) {
        if (event.target['offsetWidth'] < event.target['scrollWidth']) {
            this.ShowToolTip = true;
        } else {
            this.ShowToolTip = false;
        }
        if (this.DataTableValue.IsPlanningTable) {
            this.DataTableValue.SelectionHelper.SetCurrentCell({
                RowIndex: this.RowValue.index,
                ColumnName: this.Column.Name
            });
            this.DataTableValue.SelectionHelper.SetDescriptionHover();
            if (this.DataTableValue.SelectionHelper.Copying) {
                this.DataTableValue.SelectionHelper.SetHighlighting();
            }

        }
    }
    //#endregion

    checkCellStyle() {
        if (this.Initialized) {
            let actStyle = {};
            if (this.RowValue) {
                if (this.RowValue.rowStyle) {
                    actStyle = Object.assign(actStyle, this.RowValue.rowStyle);
                }
                if (this.RowValue.styles && this.ColumnValue && this.DataTableValue.CellStylesValue) {
                    const rowStyle = this.RowValue.styles[this.ColumnValue.Name];
                    if (rowStyle) {
                        const rowStyleVal = this.DataTableValue.CellStylesValue[rowStyle];
                        if (rowStyleVal) {
                            actStyle = Object.assign(actStyle, rowStyleVal);
                        }
                    }
                }
            }
            this.ActCellStyle = actStyle;
        }
    }
}
