import { Component, OnInit } from '@angular/core';
import { ArrayHelpers } from '../../helpers/array.helpers';
import { EnumHelper } from '../../helpers/enum.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { OfflineStrategy } from '../../models/enums/offlinestrategy.enum';
import { GeneralSettingsService } from '../../services/generalsettings.service';
import { MetaService } from '../../services/meta.service';
import { StandardRequestBase } from '../../services/request-base';
import { SettingsService } from '../../services/settings.service';

@Component({
    selector: 'general-settings',
    templateUrl: './general.settings.html',
    styleUrls: ['./general.settings.css']
})
export class GeneralSettings implements OnInit {
    GeneralSettings = {
        Offline: {
            Active: false,
            Strategy: OfflineStrategy.NetworkFirst
        },
        AnonymousUser: null,
        ShowMissingTranslations: false,
        GoogleMeasurementID: null,
        LogoutTime: 0,
        GoogleMapsAPIKey: null,
        UseWebPush: false,
        Calendar: null,
        DefaultNavigationStructure: null
    }
    Strategies = [];
    Users = [];
    NavigationStructures = [];
    Calendars = ['buddhist', 'chinese', 'coptic', 'dangi', 'ethioaa', 'ethiopic', 'gregory', 'hebrew', 'indian', 'islamic',
        'islamic-umalqura', 'islamic-tbla', 'islamic-civil', 'islamic-rgsa', 'iso8601', 'japanese', 'persian', 'roc', 'islamicc'];

    static GetSettingsEntry() {
        return {
            Caption: '@@General',
            ID: 'general',
            Icon: 'gpp_maybe',
            Index: 0,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 2
            },
            Content: GeneralSettings
        };
    }

    constructor(private service: GeneralSettingsService, private standardService: StandardRequestBase,
        private metaService: MetaService) {
        this.Strategies = EnumHelper.GetDropdownValues(OfflineStrategy);
    }

    ngOnInit(): void {
        this.Refresh();
        this.standardService.executeGet('config/api/dbusers', 'ReadUsers').subscribe(result => {
            if (result) {
                ArrayHelpers.sortAlphabetical(result, 'DisplayName');
                this.Users = result;
            }
        });
    }

    Refresh() {
        this.service.ReadAll().subscribe((settings) => {
            if (settings && settings.length > 0) {
                this.GeneralSettings = settings[0];
                SettingsService.GeneralSettings.next(settings[0]);
            }
        });
        this.metaService.GetNavigationStructures().subscribe(x => {
            if (x) {
                this.NavigationStructures = x;
            } else {
                this.NavigationStructures = [];
            }
        });
    }

    Save() {
        this.service.AddOrUpdate(this.GeneralSettings).subscribe(() => {
            NotificationHelper.Success('@@Erfolgreich gespeichert', '@@Speichern');
            this.Refresh();
        });
    }
}
