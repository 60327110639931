import { Component } from '@angular/core';
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { Gradient } from '../../../models/style/gradient.model';
import { ATableMenuTab } from './mactable.menu.tab';

@Component({
    selector: 'mactable-format-menu-tab',
    templateUrl: './format.menu.tab.html'
})
export class MacTableFormatMenuTab extends ATableMenuTab {
    FirstColor = {};
    SecondColor = {};

    private static GetColorPreviewStyle() {
        return {
            'height': '20px',
            'width': '20px',
            'border-radius': '5px',
            'border': '1px solid lightgray'
        };
    }

    OnItemSet() {
        this.FirstColor = MacTableFormatMenuTab.GetColorPreviewStyle();
        this.SecondColor = MacTableFormatMenuTab.GetColorPreviewStyle();
        if (this.SelectedItemValue && this.SelectedItemValue.TableSettings) {
            Object.assign(this.FirstColor, Gradient.getStyleObject(this.SelectedItemValue.TableSettings.FirstAlternatingColor));
            Object.assign(this.SecondColor, Gradient.getStyleObject(this.SelectedItemValue.TableSettings.SecondAlternatingColor));
        }
    }

    onMenuClosed(gradient, first) {
        if (this.SelectedItemValue && this.SelectedItemValue.TableSettings) {
            const color = Object.assign(MacTableFormatMenuTab.GetColorPreviewStyle(), Gradient.getStyleObject(gradient));
            if (first) {
                this.SelectedItemValue.TableSettings.FirstAlternatingColor = gradient;
                this.FirstColor = color;
                this.SettingsChanged('FirstAlternatingColor');
            } else {
                this.SelectedItemValue.TableSettings.SecondAlternatingColor = gradient;
                this.SecondColor = color;
                this.SettingsChanged('SecondAlternatingColor');
            }
        }
    }

    menuKeyDown(ev) {
        MenuKeyDownHelper.onKeyDown(ev);
    }
}
