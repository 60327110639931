import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Column } from '../basic/column.model';
import { Row } from '../basic/row.model';
import { CellSelection, RowSelection } from '../basic/selection.model';
import { ChartSettings } from '../controls/chart.model';

export abstract class ChartDataSource {

    public Data: Subject<any> = new Subject();
    public Loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(protected reportObject) {
    }

    abstract Refresh(chartSettings: ChartSettings);
    abstract UpdateChart(chartSettings: ChartSettings);
}

export abstract class TableDataSource extends DataSource<any | undefined> {

    Initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    Loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    PagedData: Subject<any> = new Subject();
    CellStyles: Subject<any> = new Subject();
    Columns: Subject<Column[]> = new Subject();
    FixedRows: Subject<Row[]> = new Subject<Row[]>();
    FilteredCount: number = 0;
    cachedData = Array.from<any>({ length: this.FilteredCount });
    protected dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedData);
    protected subscription = new Subscription();

    protected _pageSize = 300;
    protected fetchedPages = new Set<number>();
    get pageSize() {
        return this._pageSize;
    }
    set pageSize(val) {
        this._pageSize = val;
        this.fetchedPages = new Set<number>();
    }

    constructor(protected reportObject) {
        super();
    }

    fetchPage(page: number) {
    }

    private getPageForIndex(index: number): number {
        return Math.floor(index / this.pageSize);
    }

    connect(collectionViewer: CollectionViewer): Observable<(any | undefined)[]> {
        this.subscription = new Subscription();
        this.subscription.add(collectionViewer.viewChange.subscribe(range => {
            const startPage = this.getPageForIndex(range.start);
            const endPage = this.getPageForIndex(range.end - 1);
            for (let i = startPage; i <= endPage; i++) {
                this.fetchPage(i);
            }
        }));
        this.dataStream = new BehaviorSubject<(any | undefined)[]>(this.cachedData);
        return this.dataStream;
    }

    disconnect(): void {
        this.subscription.unsubscribe();
        this.dataStream.unsubscribe();
    }

    abstract Refresh();
    abstract GetSelectedCoordinatesFromRowSelection(selection: RowSelection[]): any[];
    abstract GetSelectedCoordinatesFromCellSelection(selection: CellSelection[]): any[];
}
