import { BaseTriggerControl } from './base.trigger.control';
import { Component } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { MailAuthType } from '../../../../models/enums/mailauthtype.enum';
import { MailProvider } from '../../../../models/enums/mailprovider.enum';

@Component({
    selector: 'mailtrigger-control',
    templateUrl: './mail.trigger.control.html',
    styleUrls: ['./mail.trigger.control.css']
})
export class MailTriggerControl extends BaseTriggerControl {

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    AuthTypes = [];
    Providers = [];
    constructor() {
        super()
        this.AuthTypes = EnumHelper.GetDropdownValues(MailAuthType)
        this.Providers = EnumHelper.GetDropdownValues(MailProvider)

    }
    addFrom(event): void {
        if (this.TriggerValue) {
            if (!this.TriggerValue.FromSearch) {
                this.TriggerValue.FromSearch = [];
            }
            this.add(event, this.TriggerValue.FromSearch);
        }
    }

    addTo(event): void {
        if (this.TriggerValue) {
            if (!this.TriggerValue.ToSearch) {
                this.TriggerValue.ToSearch = [];
            }
            this.add(event, this.TriggerValue.ToSearch);
        }
    }

    addSub(event): void {
        if (this.TriggerValue) {
            if (!this.TriggerValue.SubjectSearch) {
                this.TriggerValue.SubjectSearch = [];
            }
            this.add(event, this.TriggerValue.SubjectSearch);
        }
    }

    addAtt(event): void {
        if (this.TriggerValue) {
            if (!this.TriggerValue.AttachmentSearch) {
                this.TriggerValue.AttachmentSearch = [];
            }
            this.add(event, this.TriggerValue.AttachmentSearch);
        }
    }

    add(event, list) {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            list.push({ SearchValue: value.trim() });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }
}
