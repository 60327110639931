import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class RepeatingChooserDescription {
    Depth = 0;
    Name = '';
    Repeatings: RepeatingChooserDescription[] = [];
}

@Component({
    selector: 'repeating-chooser',
    templateUrl: './repeating.chooser.html'
})
export class RepeatingChooser implements OnInit {
    //#region NameList
    NameListValue: string[];

    @Input()
    get NameList(): string[] {
        return this.NameListValue;
    }
    set NameList(val: string[]) {
        this.NameListValue = val;
        this.setSelected();
        this.NameListChange.emit(this.NameListValue);
    }

    @Output() NameListChange = new EventEmitter<any>();
    //#endregion

    //#region Description
    DescriptionValue: RepeatingChooserDescription;

    @Input()
    get Description(): RepeatingChooserDescription {
        return this.DescriptionValue;
    }
    set Description(val: RepeatingChooserDescription) {
        this.DescriptionValue = val;
        this.setSelected();
        this.DescriptionChange.emit(this.DescriptionValue);
    }

    @Output() DescriptionChange = new EventEmitter<any>();
    //#endregion

    SelectedRepeating: RepeatingChooserDescription;

    @Output() SelectionChanged = new EventEmitter<any>();

    ngOnInit(): void {
        this.setSelected();
    }

    setSelected() {
        let selected;
        if (this.DescriptionValue && this.NameListValue) {
            if (this.NameListValue.length > this.DescriptionValue.Depth) {
                const name = this.NameListValue[this.DescriptionValue.Depth];
                if (name) {
                    selected = this.DescriptionValue.Repeatings.find(x => x.Name === name);
                }
            }
        }
        this.SelectedRepeating = selected;
    }

    onSelectionChange() {
        if (this.DescriptionValue && this.NameListValue) {
            this.NameListValue.splice(this.DescriptionValue.Depth);
            if (this.SelectedRepeating) {
                this.NameListValue[this.DescriptionValue.Depth] = this.SelectedRepeating.Name;
            }
            this.SelectionChanged.emit();
        }
    }
}