import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'sapb1db-panel',
    templateUrl: './sapb1db.panel.html',
    styleUrls:['./sapb1db.panel.css']
})
export class SapB1DBPanel extends BaseDataSourcePanel {
    //Authenticates;
    //Protocols;

    constructor() {
        super();
        this.DBInfoType = SAPB1DBInfo;
        //this.Authenticates = EnumHelper.GetDropdownValues(AuthenticationType);
        //this.Protocols = EnumHelper.GetDropdownValues(SecurityProtocolType);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: SapB1DBPanel,
            DBInfoType: SAPB1DBInfo
        };
    }
}

export class SAPB1DBInfo extends ADBInfo {
    Server: string = null;
    Port: number = null;
    Database: string = null;
    Username: string = null;
    Password: string = null;
    //Domain: string;
    //Proxy: string;
    //TokenUrl: string;
    //TokenParams: string;
    //ParamFile: string;
    //Authenticate: AuthenticationType = AuthenticationType.None;
    //TlsProtocol: SecurityProtocolType = SecurityProtocolType.Tls12;

    getTypeName(): string {
        return 'evidanza.Database.SAPB1.SAPB1DBInfo';
    }
}

//export enum SecurityProtocolType {
//    //
//    // Summary:
//    //     Specifies the Secure Socket Layer (SSL) 3.0 security protocol.
//    Ssl3 = 48,
//    //
//    // Summary:
//    //     Specifies the Transport Layer Security (TLS) 1.0 security protocol.
//    Tls = 192,
//    //
//    // Summary:
//    //     Specifies the Transport Layer Security (TLS) 1.1 security protocol.
//    Tls11 = 768,
//    //
//    // Summary:
//    //     Specifies the Transport Layer Security (TLS) 1.2 security protocol.
//    Tls12 = 3072
//}

//export enum AuthenticationType {
//    None,
//    Basic,
//    Token,
//    Oauth2,
//    Ntlm,
//    Digest
//}
