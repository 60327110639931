import { Axis } from '../../../models/datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../../../models/datadescription/multi/multiresult.model';
import { MemberType } from '../../../models/enums/query.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataDescriptionHelper } from '../ddhelper.query';
import { AExecutionManipulation } from './a.execution.manipulation';

export class AllElementRenamingManipulation extends AExecutionManipulation {

    private static RenameNodes(nodes: AxisNode[], ddAxis: Axis, cache: Map<number, string>) {
        if (nodes && ddAxis) {
            nodes.forEach(node => {
                if (node.Type === MemberType.All) {
                    if (cache.has(node.UniqueID)) {
                        node.Caption = cache.get(node.UniqueID);
                    } else {
                        let caption = node.Caption;
                        const levelNode = DataDescriptionHelper.FindLevelNodeByIDInternal(ddAxis, node.UniqueID);
                        if (levelNode && levelNode.Caption) {
                            caption = TranslatedString.GetTranslation(levelNode.Caption);
                        }
                        node.Caption = caption;
                        cache.set(node.UniqueID, caption);
                    }
                }
                AllElementRenamingManipulation.RenameNodes(node.Children, ddAxis, cache);
            });
        }
    }

    BeforeSplit() {
    }
    BeforeExecute() {
    }
    AfterExecute(result: MultiResult) {
        if (result && this.Context) {
            const dd = this.Context.DataDescriptionClone;
            if (dd) {
                if (result.XAxis) {
                    AllElementRenamingManipulation.RenameNodes(result.XAxis.Nodes, dd.XLevelNodes, new Map<number, string>());
                }
                if (result.YAxis) {
                    AllElementRenamingManipulation.RenameNodes(result.YAxis.Nodes, dd.YLevelNodes, new Map<number, string>());
                }
            }
        }
    }
}
