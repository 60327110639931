import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output, SimpleChanges
} from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { TranslationTextBoxThemeControl } from '../../../appbuilder/controls/translation/translation.textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-timespan',
    templateUrl: './timespan.control.html',
    styleUrls: ['./timespan.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimespanControl extends IBaseComponent {
    static Type: any = 'timespan';
    static Default = { Source: 'timespan', Placeholder: 'Please type ...', Type: 'timespan',Layout: {
        _Editable: true,
            Height: {Type: 0, Value: 250},
            Width: {Type: 0, Value: 250},
    } };

    isEditable = true;

    Valid = true;
    KeyDownTimeOut;

    //#region Placeholder
    @Input()
    get Placeholder() {
        return this.LayoutElement.Placeholder;
    }
    set Placeholder(val) {
        this.CheckLayoutElement();
        this.LayoutElement.Placeholder = val;
        this.PlaceholderChange.emit(this.LayoutElement.Placeholder);
    }

    @Output() PlaceholderChange = new EventEmitter<any>();
    //#endregion

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        // this.EventList.push('valuechanged');
    }
    MetaTable;
    MetaTableProperties;
    DataSourceProperty;
    @Output() ValueChange = new EventEmitter<string>();

    ControlInitialized() {
    }

    ngOnInit() {
        this.isEditable = !this.Editable || this.Disabled;
        this.Subscriptions['Editable'] = this.LayoutElement.EditableChanged.subscribe((value) => this.isEditable = !this.Editable || this.Disabled);
    }


    onChange(ev) {
        this.ValueChange.emit(ev);
    }
    CheckLayoutElement() {
        if (!this.LayoutElement) {
            this.LayoutElement = new LayoutElement();
        }
    }
}
export class TimespanPanel extends BasePanel {
    static override SIDS = ['24a52a82-98e5-401e-a740-517bfc8097fd']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'24a52a82-98e5-401e-a740-517bfc8097fd',
            ID: 'timespan',
            Caption: '@@TimeSpan',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'timespan';
            }
        });
        PROPERTIES.push({
            ID: "Caption",
            Parent: "timespan",
            Content: new ComponentPortal(TranslationTextBoxThemeControl),
            Label: "@@Caption",
            InitArgs: {
                DataType: 'string'
            }
        });
        PROPERTIES.push({
            ID: "ShowMilliseconds",
            Parent: "timespan",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@ShowMilliseconds"
            }
        });
        PROPERTIES.push({
            ID: "Horizontal",
            Parent: "timespan",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Horizontal"
            }
        });
    }
}