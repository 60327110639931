import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { LayoutService } from '../../services/layout.service';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { Registry } from '../../script/modules/registry.script';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'scriptlibrary-settings',
    templateUrl: '../base.list.settings.html'
})
export class ScriptLibrarySettings extends BaseListSettings {

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: ScriptLibraryService) {
        super(factoryResolver, cdRef);
        Registry.initializeRegistry(service);
    }

    getContentType() {
        return ScriptLibraryDetail;
    }

    loadList(handler) {
        const list = [];
        this.service.Get(null).subscribe((result) => {
            var list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
        handler(list);
    }

    loadData(data) {
        this.setSelectedItem(null);
        this.service.GetByID(data).subscribe((result) => {
            if (result) {
                const item = result;
                this.setSelectedItem(item);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@Neues Script'),
            Text: '', //'export class Main {\n\trun(): void {\n\t\t//TODO writeCode\n\t}\n}'
            IsServerScript: true 
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Script \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Script \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Script loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Script \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Script speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.Delete(data).subscribe((result) => {
            handler(result);
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        return new Promise((resolve, reject) => {
            if (this.Component.editor) {
                this.Component.editor.getAction('editor.action.formatDocument').run().then(() => {
                    resolve(new DataCheck());
                });
            } else {
                resolve(new DataCheck());
            }
        });
    }
   
    saveInternal(item, handler) {
        this.service.Save(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@ScriptLibrary',
    //        ID: 'scriptlibrary',
    //        Icon: 'scriptlibrary',
    //        Index: 30,
    //        Parent: 'data',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.DataRight',
    //            Value: 2048
    //        },
    //        Content: ScriptLibrarySettings
    //    };
    //}
}

@Component({
    selector: 'script-library-detail',
    templateUrl: './scriptlibrary.settings.html',
    styleUrls: ['./scriptlibrary.settings.css']
})
export class ScriptLibraryDetail extends BaseListDetail {
    editorOptions = {
        language: 'typescript'
    };

    editor;

    EditorInit(editor) {
        this.editor = editor;
    }

    TextChanged(event) {
        console.log(event);
    }
}
