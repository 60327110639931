import { OnInit, Component } from '@angular/core';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';

@Component({
    selector: 'containerpath-chooser',
    templateUrl: './containerpath.chooser.dialog.html',
    styleUrls: ['containerpath.chooser.dialog.css']
})
export class ContainerPathChooserDialog implements OnInit {
    Args;
    SelectedItem;

    ngOnInit(): void {
        var args = this.Args;
        if (args && args.Paths && args.Containers) {
            args.Paths.forEach(function (path) {
                var captionList = [];
                if (path.PathList.length > 0) {
                    var actCont = path.PathList[0].FromContainer;
                    var actCap = actCont;
                    args.Containers.some(function (c) {
                        if (c.SID == actCont) {
                            actCap = c.Caption;
                            return true;
                        }
                        return false;
                    });
                    captionList.push(actCap);
                    path.PathList.forEach(function (p) {
                        if (p.FromContainer == actCont) {
                            actCont = p.ToContainer;
                            actCap = actCont;
                            args.Containers.some(function (c) {
                                if (c.SID == actCont) {
                                    actCap = c.Caption;
                                    return true;
                                }
                                return false;
                            });
                            captionList.push(actCap);
                        } else {
                            throw 'Wrong path!';
                        }
                    });
                }
                path.Caption = captionList.join(' -> ');
            });
        }
    }

    Initialize(args) {
        if (args) {
            this.Args = args;
        }
    }

    GetDialogResult() {
        var retVal = null;
        if (this.SelectedItem) {
            retVal = this.SelectedItem.PathList;
        }
        return retVal;
    }

    OnDialogButtonClickAction(button) {
        if (button == DialogButton.Ok) {
            if (!this.SelectedItem) {
                return false;
            }
        }
        return true;
    }

    onSelectionChanged(ev) {
        this.SelectedItem = null;
        if (ev && ev.options && ev.options.length > 0) {
            const option = ev.options[0];
            if (option && option.selected) {
                ev.source.deselectAll();
                option.selected = true;
                if (option.value) {
                    this.SelectedItem = option.value;
                    if (!option.value.Structure) {
                        var structureList = [];
                        option.value.Structure = structureList;
                        var args = this.Args;
                        if (args && args.Containers && args.Relations && option.value.PathList.length > 0) {
                            var actCont = option.value.PathList[0].FromContainer;
                            var actContObj;
                            args.Containers.some(function (c) {
                                if (c.SID == actCont) {
                                    actContObj = c;
                                    return true;
                                }
                                return false;
                            });
                            if (actContObj) {
                                structureList.push(actContObj.Caption);
                            } else {
                                structureList.push(actCont);
                            }
                            option.value.PathList.forEach(function (p) {
                                var relStructure = {
                                    Tables: [],
                                    RelTypes: []
                                };
                                structureList.push(relStructure);
                                if (p.Path.length > 0) {

                                    var relCont = actCont;
                                    var relContObj = actContObj;

                                    var actPath = p.Path[0];
                                    var actRel;
                                    args.Relations.some(function (r) {
                                        if (r.SID == actPath) {
                                            actRel = r;
                                            return true;
                                        }
                                        return false;
                                    });
                                    var table = {
                                        Caption: relCont,
                                        Fields: []
                                    };
                                    if (actRel) {
                                        if (actRel.PrimaryContainer == relCont) {
                                            relStructure.Tables.push(table);
                                            if (relContObj) {
                                                table.Caption = relContObj.Caption;
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    var field = k;
                                                    relContObj.Fields.some(function (f) {
                                                        if (f.SID == k) {
                                                            field = f.Caption;
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                    table.Fields.push(field);
                                                });
                                            } else {
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    table.Fields.push(k);
                                                });
                                            }
                                            relStructure.RelTypes.push('arrow_forward');

                                            relCont = actRel.SecondaryContainer;
                                            relContObj = null;
                                            args.Containers.some(function (c) {
                                                if (c.SID == relCont) {
                                                    relContObj = c;
                                                    return true;
                                                }
                                                return false;
                                            });
                                            table = {
                                                Caption: relCont,
                                                Fields: []
                                            };
                                            relStructure.Tables.push(table);
                                            if (relContObj) {
                                                table.Caption = relContObj.Caption;
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    var field = actRel.ElementRelations[k];
                                                    relContObj.Fields.some(function (f) {
                                                        if (f.SID == field) {
                                                            field = f.Caption;
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                    table.Fields.push(field);
                                                });
                                            } else {
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    table.Fields.push(actRel.ElementRelations[k]);
                                                });
                                            }
                                        } else if (actRel.SecondaryContainer == relCont) {
                                            relStructure.Tables.push(table);
                                            if (relContObj) {
                                                table.Caption = relContObj.Caption;
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    var field = actRel.ElementRelations[k];
                                                    relContObj.Fields.some(function (f) {
                                                        if (f.SID == field) {
                                                            field = f.Caption;
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                    table.Fields.push(field);
                                                });
                                            } else {
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    table.Fields.push(actRel.ElementRelations[k]);
                                                });
                                            }
                                            relStructure.RelTypes.push('arrow_back');

                                            relCont = actRel.PrimaryContainer;
                                            relContObj = null;
                                            args.Containers.some(function (c) {
                                                if (c.SID == relCont) {
                                                    relContObj = c;
                                                    return true;
                                                }
                                                return false;
                                            });
                                            table = {
                                                Caption: relCont,
                                                Fields: []
                                            };
                                            relStructure.Tables.push(table);
                                            if (relContObj) {
                                                table.Caption = relContObj.Caption;
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    var field = k;
                                                    relContObj.Fields.some(function (f) {
                                                        if (f.SID == k) {
                                                            field = f.Caption;
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                    table.Fields.push(field);
                                                });
                                            } else {
                                                Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                    table.Fields.push(k);
                                                });
                                            }
                                        } else {
                                            throw 'Wrong relation!';
                                        }
                                    } else {
                                        throw 'Relation not found!';
                                    }
                                    for (var i = 1; i < p.Path.length; i++) {
                                        actPath = p.Path[i];
                                        actRel = null;
                                        args.Relations.some(function (r) {
                                            if (r.SID == actPath) {
                                                actRel = r;
                                                return true;
                                            }
                                            return false;
                                        });
                                        if (actRel) {
                                            if (actRel.PrimaryContainer == relCont) {
                                                if (relContObj) {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        var field = k;
                                                        relContObj.Fields.some(function (f) {
                                                            if (f.SID == k) {
                                                                field = f.Caption;
                                                                return true;
                                                            }
                                                            return false;
                                                        });
                                                        if (!table.Fields.some(function (f) { return f == field; })) {
                                                            table.Fields.push(field);
                                                        }
                                                    });
                                                } else {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        if (!table.Fields.some(function (f) { return f == k; })) {
                                                            table.Fields.push(k);
                                                        }
                                                    });
                                                }
                                                relStructure.RelTypes.push('arrow_forward');

                                                relCont = actRel.SecondaryContainer;
                                                relContObj = null;
                                                args.Containers.some(function (c) {
                                                    if (c.SID == relCont) {
                                                        relContObj = c;
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                                table = {
                                                    Caption: relCont,
                                                    Fields: []
                                                };
                                                relStructure.Tables.push(table);
                                                if (relContObj) {
                                                    table.Caption = relContObj.Caption;
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        var field = actRel.ElementRelations[k];
                                                        relContObj.Fields.some(function (f) {
                                                            if (f.SID == field) {
                                                                field = f.Caption;
                                                                return true;
                                                            }
                                                            return false;
                                                        });
                                                        table.Fields.push(field);
                                                    });
                                                } else {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        table.Fields.push(actRel.ElementRelations[k]);
                                                    });
                                                }
                                            } else if (actRel.SecondaryContainer == relCont) {
                                                if (relContObj) {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        var field = actRel.ElementRelations[k];
                                                        relContObj.Fields.some(function (f) {
                                                            if (f.SID == field) {
                                                                field = f.Caption;
                                                                return true;
                                                            }
                                                            return false;
                                                        });
                                                        if (!table.Fields.some(function (f) { return f == field; })) {
                                                            table.Fields.push(field);
                                                        }
                                                    });
                                                } else {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        var field = actRel.ElementRelations[k];
                                                        if (!table.Fields.some(function (f) { return f == field; })) {
                                                            table.Fields.push(field);
                                                        }
                                                    });
                                                }
                                                relStructure.RelTypes.push('arrow_back');

                                                relCont = actRel.PrimaryContainer;
                                                relContObj = null;
                                                args.Containers.some(function (c) {
                                                    if (c.SID == relCont) {
                                                        relContObj = c;
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                                table = {
                                                    Caption: relCont,
                                                    Fields: []
                                                };
                                                relStructure.Tables.push(table);
                                                if (relContObj) {
                                                    table.Caption = relContObj.Caption;
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        var field = k;
                                                        relContObj.Fields.some(function (f) {
                                                            if (f.SID == k) {
                                                                field = f.Caption;
                                                                return true;
                                                            }
                                                            return false;
                                                        });
                                                        table.Fields.push(field);
                                                    });
                                                } else {
                                                    Object.keys(actRel.ElementRelations).forEach(function (k) {
                                                        table.Fields.push(k);
                                                    });
                                                }
                                            } else {
                                                throw 'Wrong relation!';
                                            }
                                        } else {
                                            throw 'Relation not found!';
                                        }
                                    }

                                }
                                actCont = p.ToContainer;
                                actContObj = null;
                                args.Containers.some(function (c) {
                                    if (c.SID == actCont) {
                                        actContObj = c;
                                        return true;
                                    }
                                    return false;
                                });
                                if (actContObj) {
                                    structureList.push(actContObj.Caption);
                                } else {
                                    structureList.push(actCont);
                                }
                            });
                        }
                    }
                }
            }
        }
    }
}