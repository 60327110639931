import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { JiraService } from '../../services/jira.service';
import { LayoutService } from '../../services/layout.service';
import { DataCheck } from '../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'jira-settings',
    templateUrl: '../base.list.settings.html'
})
export class JiraSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: JiraService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return JiraDetail;
    }

    loadList(handler) {
        const list = [];
        this.service.Get(null).subscribe((result) => {
            var list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
        handler(list);
    }

    loadData(data) {
        this.setSelectedItem(null);
        this.service.GetByID(data).subscribe((result) => {
            if (result) {
                const item = result;
                this.setSelectedItem(item);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@Neue Jira Konfiguration'),
            Text: '', //'export class Main {\n\trun(): void {\n\t\t//TODO writeCode\n\t}\n}'
            IsServerScript: true
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Jira Konfiguration \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Jira Konfiguration \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Jira Konfiguration loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Jira Konfiguration \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Jira Konfiguration speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.Delete(data).subscribe((result) => {
            handler(result);
        });
    }

    protected async checkCanSave(): Promise<DataCheck> {
        return new Promise((resolve, reject) => {
            resolve(new DataCheck());
        });
    }

    saveInternal(item, handler) {
        this.service.Save(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Jira',
    //        ID: 'jira',
    //        Icon: 'jira',
    //        Index: 40,
    //        Parent: 'data',
    //        Security: {
    //            Name: 'evidanza.App.Shared.Security.DataRight',
    //            Value: 16384
    //        },
    //        Content: JiraSettings
    //    };
    //}
}

@Component({
    selector: 'jira-detail',
    templateUrl: './jira.settings.html',
    styleUrls: ['./jira.settings.css']
})
export class JiraDetail extends BaseListDetail {
    JiraProjects: null;

    constructor(private service: JiraService) {
        super();
    }

    OnJiraChanged() {
        if (this.SelectedItem && this.SelectedItem.URL && this.SelectedItem.Username && this.SelectedItem.APIKey) {
            this.service.GetAllProjects(this.SelectedItem).subscribe(result => {
                this.JiraProjects = result;
            });
            this.OnItemChanged();
        } else {
            this.JiraProjects = null;
        }
    }

    setSelectedItem(item) {
        if (item && item.URL && item.Username && item.APIKey) {
            this.service.GetAllProjects(item).subscribe(result => {
                this.JiraProjects = result;
            });
        } else {
            this.JiraProjects = null;
        }
        super.setSelectedItem(item);
    }
}
