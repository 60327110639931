import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularResizeEventModule } from 'angular-resize-event';
import { AngularDraggableModule } from 'angular2-draggable';
import { DataTableFilterPipe } from '../../helpers/datatable.filter';
import { DataTableSortPipe } from '../../helpers/datatable.sort';
import { OperatorComponent } from '../common/operator.component';
import { CellContent } from './cell.content';
import { ContentColumnContainer } from './content.column.container';
import { DataTableCell } from './datatable.cell';
import { DataTableComponent } from './datatable.component';
import { DataTableRow } from './datatable.row';
import { HeaderColumnContainer } from './header.column.container';
import { SpreadSheetCell } from './spreadsheet.cell';

@NgModule({
    declarations: [
        DataTableComponent,
        DataTableRow,
        DataTableCell,
        CellContent,
        DataTableFilterPipe,
        DataTableSortPipe,
        OperatorComponent,
        SpreadSheetCell,
        HeaderColumnContainer,
        ContentColumnContainer
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ScrollingModule,
        MatExpansionModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSelectModule,
        AngularDraggableModule,
        AngularResizeEventModule
    ],
    exports: [DataTableComponent]
})
export class DataTableModule { }
