import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { ChartType } from '../../../../models/enums/charttype.enum';
import { AxisType } from '../../../../models/enums/query.enum';
import { ChartSeriesLabelTaskExecuter } from '../../../../models/tasks/chartformattasks/chart.series.label.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ATaskPanel } from '../../a.task.panel';
import { ChartSeriesFormattingPanel } from './chart.series.formatting.panel';

@Component({
    selector: 'chart-series-label-task-panel',
    templateUrl: './chart.series.label.panel.html'
})
export class ChartSeriesLabelPanel extends ATaskPanel implements OnInit {
    static TaskID = 'chartserieslabel';
    Series = [];
    Measures = [];
    MeasuresOnSeries = false;
    SelectedSeries;

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = ChartSeriesLabelPanel.TaskID;
        reg.Caption = '@@Reihenbeschriftung';
        reg.SettingsControl = ChartSeriesLabelPanel;
        reg.SettingsHelper = new ChartSeriesLabelTaskSettingsHelper();
        reg.Executer = ChartSeriesLabelTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    ngOnInit(): void {
        if (this.DataDescription) {
            this.Series = ChartSeriesFormattingPanel.getAxisNodes(this.DataDescription.YLevelNodes, this.levelUniqueToUUID, this.Measures);
            this.Series.splice(0, 0, {
                Caption: TranslateHelper.TranslatorInstance.instant('@@Alle'),
                LevelID: -1,
                MeasureID: -1
            });
            this.MeasuresOnSeries = this.DataDescription.ShowMeasureOnAxis === AxisType.Y_Axis;
            if (this.SettingsValue) {
                let mID = this.MeasuresOnSeries ? this.SettingsValue.MeasureUniqueID : null;
                if (this.SettingsValue.LevelUniqueID) {
                    this.SelectedSeries = this.Series.find(x => x.LevelID === this.SettingsValue.LevelUniqueID && x.MeasureID === mID);
                } else if (mID) {
                    this.SelectedSeries = this.Series.find(x => x.LevelID === null && x.MeasureID === mID);
                }
            }
        }
    }

    onSeriesChanged() {
        if (this.SettingsValue) {
            if (this.SelectedSeries) {
                if (this.MeasuresOnSeries && this.SelectedSeries.MeasureID !== this.SettingsValue.MeasureUniqueID) {
                    this.SettingsValue.MeasureUniqueID = this.SelectedSeries.MeasureID;
                }
                if (this.SelectedSeries.LevelID !== this.SettingsValue.LevelUniqueID) {
                    this.SettingsValue.LevelUniqueID = this.SelectedSeries.LevelID;
                }
            } else {
                this.SettingsValue.MeasureUniqueID = null;
                this.SettingsValue.LevelUniqueID = null;
            }
        }
    }
}

export class ChartSeriesLabelTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        if (TaskHelper.IsChartObject(reportObject) && reportObject.LayoutElement.ChartSettings) {
            switch (reportObject.LayoutElement.ChartSettings.ChartType) {
                case ChartType.AreaChart:
                case ChartType.BarChart:
                case ChartType.ColumnChart:
                case ChartType.ComboChart:
                case ChartType.LineChart:
                case ChartType.ScatterChart:
                case ChartType.SteppedAreaChart:
                    return true;
            }
        }
        return false;
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Reihenbeschriftung');
    }
}