import { LoginComponent } from '../component_v2/login/login.component';
import { ContentPage } from '../components/pages/content.page';
import { NotFound404Page } from '../components/pages/404-page/not-found404.page';
import { PermissionDeniedPage } from '../components/pages/not-permitted.page';
import { SettingsPage } from '../components/pages/settings.page';
import {HeaderComponent} from "../component_v2/header/header.component";
import {ForgotPasswordComponent} from "../component_v2/forgot-password/forgot-password.component";
import {OTPDialogComponent} from "../component_v2/forgot-password/OTPDialog/OTP-dialog";
import { AccessDeniedPage } from '../components/pages/403-page/403-page.component';
import {IntegrationComponent} from "../components/integration/integration.component";
import { DashboardComponent } from '../component_v2/dashboard/dashboard.component';
import { WithoutDashboardComponent } from '../component_v2/dashboard/without-dashboard/without-dashboard.component';
import { MyAppsComponent } from '../component_v2/dashboard/my-apps/my-apps.component';

export const APP_PAGES = [
    NotFound404Page,
    ContentPage,
    SettingsPage,
    PermissionDeniedPage,
    LoginComponent,
    ForgotPasswordComponent,
    OTPDialogComponent,
    HeaderComponent,
    AccessDeniedPage,
    IntegrationComponent,
    DashboardComponent,
    WithoutDashboardComponent,
    MyAppsComponent
];
