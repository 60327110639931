import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { LoginError } from '../../../models/enums/loginerror.enum';
import { ProviderChoice } from '../../../models/enums/providerchoice.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { SecurityService } from '../../../services/security.service';
import { UsersService } from '../../../services/users.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'login-wf-settings',
    templateUrl: './login.settings.html'
})
export class LoginSettings extends FormulaWorkflowDialogContent {
    Data = new LoginSettingsData();
    SecurityProviderTypes = [];

    static ModuleID = 'loginWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = LoginSettings.ModuleID;
        reg.Caption = '@@Login'
        reg.GroupID = 'wfActions';
        reg.Index = 100;
        reg.SettingsControl = LoginSettings;
        reg.SettingsTypeHelper = new LoginSettingsDataHelper();
        reg.Executer = LoginModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private service: SecurityService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetSecurityProviders().subscribe((types) => {
            if (types) {
                this.SecurityProviderTypes = types;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(LoginSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }
}

export class LoginSettingsData {
    UseControlForName = true;
    Name: string;
    UseControlForPassword = true;
    Password: string;
    UseControlForMFACode = true;
    MFACode: string;
    ProviderChoice: ProviderChoice = ProviderChoice.Control;
    Provider: string;
}

export class LoginSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Success';

        const error = new WorkflowExitInfo();
        error.ID = LoginError.Password;
        error.Label = '@@WrongPassword';

        const auth = new WorkflowExitInfo();
        auth.ID = LoginError.Authorization;
        auth.Label = '@@NoAuthorization';

        const dis = new WorkflowExitInfo();
        dis.ID = LoginError.Disabled;
        dis.Label = '@@Disabled';

        const mfa = new WorkflowExitInfo();
        mfa.ID = LoginError.MFA;
        mfa.Label = '@@MFA';

        return [success, error, auth, dis, mfa];
    }

    getEmptySettingsInstance() {
        return new LoginSettingsData();
    }
}

export class LoginModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const service = InjectorHelper.InjectorInstance.get<SecurityService>(SecurityService);
            const providers = await service.GetSecurityProviders().toPromise();
            let loginId = UUID.UUID();

            if (providers) {
                const calculator = new WorkflowFormulaCalculator(status);
                let providerID;
                switch (status.ActualSettings.ProviderChoice) {
                    case ProviderChoice.Control:
                        const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.Provider);
                        if (elem && elem.Element && elem.Element.DataSource) {
                            providerID = '' + elem.Element.DataSource;
                        }
                        break;
                    case ProviderChoice.Fixed:
                        providerID = status.ActualSettings.Provider;
                        break;
                    case ProviderChoice.Formula:
                        providerID = calculator.CalcFormula(status.ActualSettings.Provider);
                        break;
                }
                let openedwindow;
                let selectedProvider;
                if (providerID) {
                    selectedProvider = providers.find(x => x.Id === providerID);
                }
                if (!selectedProvider) {
                    selectedProvider = providers.find(x => x.IsDefault);
                }
                if (selectedProvider) {
                    if (selectedProvider.Url) {
                        if (selectedProvider.Id === '727bd278-906c-4cf8-a803-379ef57f2c89') { //twitter
                            document.cookie = "hajer" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            var date = new Date();
                            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
                            var expires = "; expires=" + date.toUTCString();
                            document.cookie = 'hajer' + "=" + (loginId || "") + expires + "; path=/";
                            openedwindow = window.open((selectedProvider.Url), '_blank');
                        } else {
                            openedwindow = window.open((selectedProvider.Url + '&state=' + selectedProvider.Id + '|' + loginId), '_blank');
                        }
                    }
                    providerID = selectedProvider.Id;
                }
                const credentials = {
                    UserName: '',
                    Password: '',
                    ProviderId: providerID,
                    LoginId: loginId,
                    MFACode: ''
                };
                if (status.ActualSettings.UseControlForName) {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.Name);
                    if (elem && elem.Element && elem.Element.DataSource) {
                        credentials.UserName = '' + elem.Element.DataSource;
                    }
                } else if (status.ActualSettings.Name) {
                    credentials.UserName = calculator.CalcFormula(status.ActualSettings.Name);
                }
                if (status.ActualSettings.UseControlForMFACode) {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.MFACode);
                    if (elem && elem.Element && elem.Element.DataSource) {
                        credentials.MFACode = '' + elem.Element.DataSource;
                    }
                } else if (status.ActualSettings.MFACode) {
                    credentials.MFACode = calculator.CalcFormula(status.ActualSettings.MFACode);
                }
                if (status.ActualSettings.UseControlForPassword) {
                    const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.Password);
                    if (elem && elem.Element && elem.Element.DataSource) {
                        credentials.Password = '' + elem.Element.DataSource;
                    }
                } else if (status.ActualSettings.Password) {
                    credentials.Password = calculator.CalcFormula(status.ActualSettings.Password);
                }
                const userService = InjectorHelper.InjectorInstance.get<UsersService>(UsersService);
                let ret = await userService.executeLogin(credentials);
                if (ret == 0 && openedwindow) {
                    openedwindow.close();
                }
                return ret;
            } else {
                status.Logger.logError('Login modul: No providers found.');
            }
        } else {
            status.Logger.logError('Login modul: No settings found.');
        }
        return -1;
    }
}

export class LogoutSettingsData { }

export class LogoutSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new LogoutSettingsData();
    }
}

export class LogoutModuleExecuter extends WorkflowModuleExecuter {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'logoutWFModule';
        reg.Caption = '@@Logout'
        reg.GroupID = 'wfActions';
        reg.Index = 110;
        reg.SettingsTypeHelper = new LogoutSettingsDataHelper();
        reg.Executer = LogoutModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        UsersService.LogoutStatic();
        return 0;
    }
}
