import { Component, OnInit } from '@angular/core';
import { MultiCacheService } from '../../../../cache/multi/cache.service';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { SeriesSettings } from '../../../../models/controls/chart.model';
import { ChartType } from '../../../../models/enums/charttype.enum';
import { AxisType } from '../../../../models/enums/query.enum';
import { ChartSeriesFormattingTaskExecuter } from '../../../../models/tasks/chartformattasks/chart.series.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { MultiReportingService } from '../../../../services/reporting.service';
import { ConditionalSeriesFormattingPanel } from '../conditional.series.formatting.panel';

@Component({
    selector: 'chart-series-formatting-task-panel',
    templateUrl: './chart.series.formatting.panel.html'
})
export class ChartSeriesFormattingPanel extends ConditionalSeriesFormattingPanel implements OnInit {
    static TaskID = 'chartseriesformatting';
    Series = [];
    SelectedSeries;
    Members = [];
    Axis = [];
    SelectedAxis;
    AxisMembers = [];
    Measures = [];
    MemberMap = new Map();
    ChartType = ChartType.ColumnChart;
    MeasuresOnSeries = false;

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = ChartSeriesFormattingPanel.TaskID;
        reg.Caption = '@@Reihe';
        reg.SettingsControl = ChartSeriesFormattingPanel;
        reg.SettingsHelper = new ChartSeriesFormattingTaskSettingsHelper();
        reg.Executer = ChartSeriesFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    static getAxisNodes(axis, levelDict, measureList) {
        const retVal = [];
        if (axis && axis.Areas) {
            axis.Areas.forEach(area => {
                if (area.Tuples && area.Tuples.length > 0) {
                    if (area.Measures && area.Measures.length > 0) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels && tuple.Levels.length > 0) {
                                const captions = [];
                                tuple.Levels.forEach(lev => {
                                    captions.push(lev.toString());
                                });
                                const lastLevel = tuple.Levels[tuple.Levels.length - 1];
                                levelDict.set(lastLevel.UniqueID, lastLevel.Level);
                                retVal.push({
                                    Caption: [...captions, TranslateHelper.TranslatorInstance.instant('@@Alle Werte')].join(' - '),
                                    LevelID: lastLevel.UniqueID,
                                    MeasureID: null
                                });
                                area.Measures.forEach(m => {
                                    retVal.push({
                                        Caption: [...captions, m.toString()].join(' - '),
                                        LevelID: lastLevel.UniqueID,
                                        MeasureID: m.UniqueID
                                    });
                                });
                            }
                        });
                        area.Measures.forEach(m => {
                            measureList.push({
                                Caption: m.toString(),
                                Value: m.UniqueID
                            });
                        });
                    } else {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels && tuple.Levels.length > 0) {
                                const captions = [];
                                tuple.Levels.forEach(lev => {
                                    captions.push(lev.toString());
                                });
                                const lastLevel = tuple.Levels[tuple.Levels.length - 1];
                                levelDict.set(lastLevel.UniqueID, lastLevel.Level);
                                retVal.push({
                                    Caption: captions.join(' - '),
                                    LevelID: lastLevel.UniqueID,
                                    MeasureID: null
                                });
                            }
                        });
                    }
                } else if (area.Measures) {
                    area.Measures.forEach(m => {
                        retVal.push({
                            Caption: m.toString(),
                            LevelID: null,
                            MeasureID: m.UniqueID
                        });
                        measureList.push({
                            Caption: m.toString(),
                            Value: m.UniqueID
                        });
                    });
                }
            });
        }
        return retVal;
    }

    constructor(private service: MultiReportingService) {
        super();
    }

    ngOnInit(): void {
        if (this.LayoutElementValue && this.LayoutElementValue.ChartSettings &&
            typeof this.LayoutElementValue.ChartSettings.ChartType === 'number') {
            this.ChartType = this.LayoutElementValue.ChartSettings.ChartType;
        }
        if (this.DataDescription) {
            this.Series = ChartSeriesFormattingPanel.getAxisNodes(this.DataDescription.YLevelNodes, this.levelUniqueToUUID, this.Measures);
            this.Axis = ChartSeriesFormattingPanel.getAxisNodes(this.DataDescription.XLevelNodes, this.levelUniqueToUUID, this.Measures);
            this.MeasuresOnSeries = this.DataDescription.ShowMeasureOnAxis === AxisType.Y_Axis;
            if (this.SettingsValue) {
                let mID = null;
                let oppM = null;
                if (this.SettingsValue.MeasureUniqueID) {
                    if (this.MeasuresOnSeries) {
                        mID = this.SettingsValue.MeasureUniqueID;
                    } else {
                        oppM = this.SettingsValue.MeasureUniqueID;
                    }
                }
                if (this.SettingsValue.LevelUniqueID) {
                    this.SelectedSeries = this.Series.find(x => x.LevelID === this.SettingsValue.LevelUniqueID && x.MeasureID === mID);
                    this.loadMembers(this.levelUniqueToUUID.get(this.SettingsValue.LevelUniqueID), (m) => {
                        this.Members = m;
                    });
                } else if (mID) {
                    this.SelectedSeries = this.Series.find(x => x.LevelID === null && x.MeasureID === mID);
                }
                if (this.SettingsValue.OppositeLevel) {
                    this.SelectedAxis = this.Axis.find(x => x.LevelID === this.SettingsValue.OppositeLevel && x.MeasureID === oppM);
                    this.loadMembers(this.levelUniqueToUUID.get(this.SettingsValue.OppositeLevel), (m) => {
                        this.AxisMembers = m;
                    });
                } else if (oppM) {
                    this.SelectedAxis = this.Axis.find(x => x.LevelID === null && x.MeasureID === oppM);
                }
            }
        }
    }

    onSeriesChanged(isSeries) {
        if (this.SettingsValue) {
            if (isSeries) {
                if (this.SelectedSeries) {
                    if (this.MeasuresOnSeries && this.SelectedSeries.MeasureID !== this.SettingsValue.MeasureUniqueID) {
                        this.SettingsValue.MeasureUniqueID = this.SelectedSeries.MeasureID;
                    }
                    if (this.SelectedSeries.LevelID !== this.SettingsValue.LevelUniqueID) {
                        this.SettingsValue.LevelUniqueID = this.SelectedSeries.LevelID;
                        this.SettingsValue.LevelMember = null;
                        this.loadMembers(this.levelUniqueToUUID.get(this.SettingsValue.LevelUniqueID), (m) => {
                            this.Members = m;
                        });
                    }
                } else {
                    if (this.MeasuresOnSeries) {
                        this.SettingsValue.MeasureUniqueID = -1;
                    }
                    this.SettingsValue.LevelUniqueID = -1;
                    this.SettingsValue.LevelMember = null;
                    this.Members = [];
                }
            } else {
                if (this.SelectedAxis) {
                    if (!this.MeasuresOnSeries && this.SelectedAxis.MeasureID !== this.SettingsValue.MeasureUniqueID) {
                        this.SettingsValue.MeasureUniqueID = this.SelectedAxis.MeasureID;
                    }
                    if (this.SelectedAxis.LevelID !== this.SettingsValue.OppositeLevel) {
                        this.SettingsValue.OppositeLevel = this.SelectedAxis.LevelID;
                        this.SettingsValue.OppositeMember = null;
                        this.loadMembers(this.levelUniqueToUUID.get(this.SettingsValue.OppositeLevel), (m) => {
                            this.AxisMembers = m;
                        });
                    }
                } else {
                    if (!this.MeasuresOnSeries) {
                        this.SettingsValue.MeasureUniqueID = -1;
                    }
                    this.SettingsValue.OppositeLevel = -1;
                    this.SettingsValue.OppositeMember = null;
                    this.AxisMembers = [];
                }
            }
        }
    }

    loadMembers(level, memberAction) {
        if (level) {
            const members = this.MemberMap.get(level);
            if (members) {
                memberAction(members);
            } else {
                const list = [];
                if (this.DataDescriptionValue && this.DataDescriptionValue.DataModelID) {
                    MultiCacheService.GetLevel(level, this.DataDescriptionValue.DataModelID).then(l => {
                        this.service.GetMembers(l.Parent.Parent.DatasourceID, level, null, null).subscribe(result => {
                            if (result) {
                                result.forEach(m => {
                                    list.push({
                                        Caption: m.Caption,
                                        Value: m.ID
                                    });
                                });
                            }
                            this.MemberMap.set(level, list);
                            memberAction(list);
                        });
                    });
                } else {
                    this.MemberMap.set(level, list);
                    memberAction(list);
                }
            }
        } else {
            memberAction([]);
        }
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (!this.SettingsValue.Style) {
                this.SettingsValue.Style = new SeriesSettings();
            }
        }
    }
}

export class ChartSeriesFormattingTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        if (TaskHelper.IsChartObject(reportObject) && reportObject.LayoutElement.ChartSettings) {
            switch (reportObject.LayoutElement.ChartSettings.ChartType) {
                case ChartType.AreaChart:
                case ChartType.BarChart:
                case ChartType.ColumnChart:
                case ChartType.ComboChart:
                case ChartType.LineChart:
                case ChartType.ScatterChart:
                case ChartType.SteppedAreaChart:
                    return true;
            }
        }
        return false;
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Reihenformatierung');
    }
}
