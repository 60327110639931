import { moveItemInArray } from '@angular/cdk/drag-drop';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { DataDescription, HeterogenArea, Tuple } from '../../../models/datadescription/multi/datadescription.model';
import { LevelNode } from '../../../models/datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../../models/datadescription/multi/measureinfo.model';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { AxisType } from '../../../models/enums/query.enum';
import { SpecialElementType } from '../../../models/enums/specialElementType';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';
import { TranslatedStringDialogComponent } from '../../dialogs/translatedstring.dialog';
import { DataDescriptionHelper } from '../../query/ddhelper.query';
import { DeleteTaskDialog } from './delete.task.dialog';
import { ReplaceElementDialog } from './replace.element.dialog';

export class ObjectcreatorDataManager {

    DataDescription: DataDescription

    public static AxisChanged: Subject<AxisType> = new Subject<AxisType>();

    public static AreaChanged: Subject<HeterogenAreaItemSelectedInfo> = new Subject<HeterogenAreaItemSelectedInfo>();

    public static MeasureChanged: Subject<any> = new Subject<any>();

    public static MeasureVariablesChanged: Subject<any> = new Subject<any>();

    public static LevelVariablesChanged: Subject<any> = new Subject<any>();

    public static TasksChanged = new Subject<any>();

    public static FieldsChanged = new Subject<void>();

    public static DialogClosing = new Subject<void>();

    async Gettooltip(item: any): Promise<string> {
        return ObjectcreatorDataManager.Gettooltip(item, this.DataDescription);
    }

    public static async Gettooltip(item: any, DataDescription: DataDescription): Promise<string> {
        let tooltip = '';
        if (item instanceof MeasureInfo) {
            const measure = item as MeasureInfo;
            const mDB = await MultiCacheService.GetMeasure(measure.Measure, DataDescription.DataModelID);
            if (mDB) {
                const measureCube = mDB?.Cube?.Name ? mDB.Cube.Name : '';
                const measureFolder = mDB?.Folder ? ' > ' + mDB.Folder : '';
                const measureName = mDB?.Name ? ' > ' + mDB.Name : '';


                tooltip = measureCube + measureFolder + measureName + ' #' + mDB.Caption;
                if (measure.VisibilityType) {
                    const vTypes = EnumHelper.GetDropdownValues(VisibilityType);
                    let res = vTypes.find(x => x.type == measure.VisibilityType);
                    const visibilityText = res?.value ? res.value : '';

                    tooltip += ' #' + visibilityText;
                }
                if (measure.Details.FunctionOffset.OffsetLevel) {
                    const levelDB = await MultiCacheService.GetLevel(measure.Details.FunctionOffset.OffsetLevel, DataDescription.DataModelID);
                    if (levelDB) {
                        tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@Periodenreferenzierung: ') + levelDB.Caption + "  " + measure.Details.FunctionOffset.OffsetValue.toString();
                    }
                }
                if (measure.Details.ContainsAnyReferencing) {
                    tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@enthaelt Referenzierungen');
                }
                if (measure.Details.FunctionAccumulation) {
                    tooltip += ' #' + measure.Details.FunctionAccumulation.toString();
                }
            }
            else {
                const specialelementTypes = EnumHelper.GetDropdownValues(SpecialElementType);
                let res = specialelementTypes.find(x => x.type == measure.SpecialElementType);
                const seText = res?.value ? res.value : '';

                tooltip = measure.Caption.toString() + " - " + seText;
            }

        }
        else if (item instanceof LevelNode) {
            const levelNode = item as LevelNode;
            const levelDB = await MultiCacheService.GetLevel(levelNode.Level, DataDescription.DataModelID);

            if (levelDB) {

                const levelName = levelDB?.Name ? ' > ' + levelDB.Name : '';
                const hierDBName = levelDB?.Parent?.Name ? ' > ' + levelDB.Parent.Name : '';
                const dimDBName = levelDB?.Parent?.Parent?.Name ? levelDB.Parent.Parent.Name : '';



                tooltip = dimDBName + hierDBName + levelName + ' #' + levelDB.Caption;
                if (levelDB.LevelType) {
                    tooltip += ' #' + levelDB.LevelType.toString();
                }

                if (item.Hidden) {
                    tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@Sichtbar');
                }
                else {
                    tooltip += ' #' + TranslateHelper.TranslatorInstance.instant('@@Ausgeblendet');
                }
            }

        }
        return tooltip;
    }



    constructor(dd: DataDescription) {
        this.DataDescription = dd;
    }

    GetNextUniqueID() {
        return DataDescriptionHelper.GetNextUniqueID(this.DataDescription);
    }

    AddHeterogenArea(axisType: AxisType, uniqueIDBefore: number, addBefore: boolean = false) {
        if (this.DataDescription) {
            const newHA = new HeterogenArea();
            newHA.UniqueID = DataDescriptionHelper.GetNextUniqueID(this.DataDescription);
            const axis = this.DataDescription.LevelNodes.find(ln => ln.AxisType === axisType);
            if (axis) {
                for (let i = 0; i < axis.Areas.length; i++) {
                    const heterogenArea = axis.Areas[i];
                    if (heterogenArea.UniqueID === uniqueIDBefore) {
                        if (addBefore) {
                            axis.Areas.splice(i, 0, newHA);
                        } else {
                            axis.Areas.splice(i + 1, 0, newHA);
                        }
                        ObjectcreatorDataManager.AxisChanged.next(axisType);
                        break;
                    }
                }
            }
        }
    }

    MoveHeterogenAreaUp(axisType: AxisType, heterogenAreaID: number) {
        if (this.DataDescription) {
            const axis = this.DataDescription.LevelNodes.find(ln => ln.AxisType === axisType);
            if (axis) {
                for (let i = 0; i < axis.Areas.length; i++) {
                    const heterogenArea = axis.Areas[i];
                    if (heterogenArea.UniqueID === heterogenAreaID) {
                        if (i < axis.Areas.length - 1) {
                            axis.Areas.splice(i, 1);
                            axis.Areas.splice(i - 1, 0, heterogenArea);
                        }
                        ObjectcreatorDataManager.AxisChanged.next(axisType);
                        break;
                    }
                }
            }
        }
    }

    MoveHeterogenAreaDown(axisType: AxisType, heterogenAreaID: number) {
        if (this.DataDescription) {
            const axis = this.DataDescription.LevelNodes.find(ln => ln.AxisType === axisType);
            if (axis) {
                for (let i = 0; i < axis.Areas.length; i++) {
                    const heterogenArea = axis.Areas[i];
                    if (heterogenArea.UniqueID === heterogenAreaID) {
                        if (i < axis.Areas.length - 1) {
                            axis.Areas.splice(i, 1);
                            axis.Areas.splice(i + 1, 0, heterogenArea);
                        }
                        ObjectcreatorDataManager.AxisChanged.next(axisType);
                        break;
                    }
                }
            }
        }
    }

    DeleteHeterogenArea(axisType: AxisType, heterogenAreaID: number) {
        if (this.DataDescription) {
            var axis = this.DataDescription.LevelNodes.find(ln => ln.AxisType === axisType);
            if (axis) {
                for (let i = 0; i < axis.Areas.length; i++) {
                    let heterogenArea = axis.Areas[i];
                    if (heterogenArea.UniqueID === heterogenAreaID) {
                        axis.Areas.splice(i, 1);
                        ObjectcreatorDataManager.AxisChanged.next(axisType);
                        break;
                    }
                }
            }
        }
    }

    MoveAllMeasuresToHeterogenArea(heterogenAreaID: number) {
        if (this.DataDescription) {
            let ha: HeterogenArea = null;
            let axisType = AxisType.None;
            let allmeasures: MeasureInfo[] = []
            this.DataDescription.LevelNodes.forEach(axis => {
                axis.Areas.forEach(area => {
                    if (area.UniqueID == heterogenAreaID) {
                        ha = area;
                        axisType = axis.AxisType;
                    }
                    allmeasures.push(...area.Measures);
                    area.Measures.splice(0, area.Measures.length);
                });
            });
            if (ha != null) {
                ha.Measures = allmeasures;
                ObjectcreatorDataManager.AxisChanged.next(axisType);
            }
        }
    }


    GetAllVariableMeasures(): MeasureInfo[] {
        let allmeasures: MeasureInfo[] = []

        let multiDa
        if (this.DataDescription) {
            this.DataDescription.LevelNodes.forEach(axis => {
                axis.Areas.forEach(area => {
                    area.Measures.forEach(measure => {
                        if (measure.SpecialElementType == SpecialElementType.Variable) {
                            allmeasures.push(measure);
                        }
                    });
                });
            });
        }
        return allmeasures;
    }

    GetAllVariablelevels(): LevelNode[] {
        let allLevels: LevelNode[] = []
        if (this.DataDescription) {
            this.DataDescription.LevelNodes.forEach(axis => {
                axis.Areas.forEach(area => {
                    area.Tuples.forEach(tuple => {
                        tuple.Levels.forEach(level => {
                            if (level.SpecialElementType == SpecialElementType.Variable) {
                                allLevels.push(level);
                            }
                        });
                    });
                });
            });
        }
        return allLevels;
    }


    MoveDBHeterogenAreaElements(info: HeterogenAreaItemSelectedInfo, previousIndex: number, moveIndex: number) {
        if (info != null && this.DataDescription != null && (info.NewDestinationHeterogenAreaID !== info.HeterogenAreaID || previousIndex != moveIndex)) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                if (info.DropAreaType == OcDragItemType.Level) {
                    let index = -1;
                    let moveLevel = null;
                    for (let i = 0; i < ha.Tuples.length; i++) {
                        let tuple = ha.Tuples[i];
                        for (let j = 0; j < tuple.Levels.length; j++) {
                            let level = tuple.Levels[j];
                            index++;
                            if (index == previousIndex) {
                                moveLevel = level;
                                tuple.Levels.splice(j, 1);
                                //Leeres Tupel löschen
                                if (tuple.Levels.length == 0) {
                                    ha.Tuples.splice(i, 1);
                                }
                                break;
                            }
                        }
                        if (moveLevel !== null) {
                            break;
                        }
                    }

                    if (moveLevel != null) {
                        //an neue Position  setzen
                        index = -1;
                        if (info.NewDestinationHeterogenAreaID != info.HeterogenAreaID) {
                            ha = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.NewDestinationHeterogenAreaID);
                        }
                        let moveFinished = false;
                        for (let i = 0; i < ha.Tuples.length; i++) {
                            let tuple = ha.Tuples[i];
                            for (let j = 0; j < tuple.Levels.length; j++) {
                                index++;
                                if (index === moveIndex) {

                                    let newTuple = new Tuple();
                                    newTuple.Levels.push(moveLevel);
                                    ha.Tuples.splice(index, 0, newTuple);
                                    moveFinished = true;
                                    break;
                                }
                            }
                            if (moveFinished) {
                                //ObjectcreatorDataManager.AreaChanged.next(info);
                                break;
                            }
                        }
                        if (!moveFinished && index + 1 === moveIndex) { //am Schluss

                            let newTuple = new Tuple();
                            newTuple.Levels.push(moveLevel);
                            ha.Tuples.push(newTuple);
                            moveFinished = true;
                            //ObjectcreatorDataManager.AreaChanged.next(info);
                        }


                    }
                }
                else if (info.DropAreaType == OcDragItemType.Measure) {
                    if (info.NewDestinationHeterogenAreaID != info.HeterogenAreaID) {
                        let newHa = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.NewDestinationHeterogenAreaID);
                        let oldHa = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
                        let moveMeasure = oldHa.Measures[previousIndex];
                        oldHa.Measures.splice(previousIndex, 1);
                        newHa.Measures.splice(moveIndex, 0, moveMeasure);
                    }
                    else //gleiche DataArea
                    {
                        moveItemInArray(ha.Measures, previousIndex, moveIndex);
                        // ObjectcreatorDataManager.AreaChanged.next(info);
                    }
                }
                ObjectcreatorDataManager.AreaChanged.next(info);
            }
        }
    }

    RenameDBHeterogenAreaElements(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length == 1 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                if (info.DropAreaType == OcDragItemType.Level) {
                    let li = DataDescriptionHelper.FindLevelNodeByID(this.DataDescription, info.SelectedItems[0].UniqueID)
                    TranslatedStringDialogComponent.showDialog(li.Caption, () => {

                        ObjectcreatorDataManager.AreaChanged.next(info);
                        if (li.SpecialElementType === SpecialElementType.Variable) {
                            ObjectcreatorDataManager.LevelVariablesChanged.next(null);
                        }
                    });


                }
                else if (info.DropAreaType == OcDragItemType.Measure) {
                    let measureInfo = DataDescriptionHelper.FindMeasureByID(this.DataDescription, info.SelectedItems[0].UniqueID)
                    TranslatedStringDialogComponent.showDialog(measureInfo.Caption, () => {

                        ObjectcreatorDataManager.AreaChanged.next(info);
                        if (measureInfo.SpecialElementType === SpecialElementType.Variable) {
                            ObjectcreatorDataManager.MeasureVariablesChanged.next(null);
                        }

                        ObjectcreatorDataManager.MeasureChanged.next(null);
                    });

                }
            }
        }
    }


    HiddenDBHeterogenAreaElements(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length == 1 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                if (info.DropAreaType == OcDragItemType.Level) {
                    const li = DataDescriptionHelper.FindLevelNodeByID(this.DataDescription, info.SelectedItems[0].UniqueID)

                    li.Hidden = !li.Hidden;

                }
                else if (info.DropAreaType == OcDragItemType.Measure) {
                    let measureInfo = DataDescriptionHelper.FindMeasureByID(this.DataDescription, info.SelectedItems[0].UniqueID)
                    if (measureInfo.VisibilityType === VisibilityType.NonVisibleDefault || measureInfo.VisibilityType === VisibilityType.NonVisibleForce) {
                        measureInfo.VisibilityType = VisibilityType.VisibleDefault;
                    }
                    else if (!measureInfo.VisibilityType || measureInfo.VisibilityType === VisibilityType.VisibleDefault || measureInfo.VisibilityType === VisibilityType.VisibleForce) {
                        measureInfo.VisibilityType = VisibilityType.NonVisibleDefault
                    }
                }
                ObjectcreatorDataManager.AreaChanged.next(info);
            }
        }
    }

    DeleteDBHeterogenAreaElements(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length > 0 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                if (info.DropAreaType == OcDragItemType.Level) {
                    info.SelectedItems.forEach(selItem => {
                        for (let i = 0; i < ha.Tuples.length; i++) {
                            let tuple = ha.Tuples[i];
                            let levelNode = tuple.Levels.find(x => x.UniqueID == selItem.UniqueID);
                            if (levelNode) {
                                let levelNodeIndex = tuple.Levels.indexOf(levelNode);
                                tuple.Levels.splice(levelNodeIndex, 1);
                                //Leeres Tupel löschen
                                if (tuple.Levels.length == 0) {
                                    ha.Tuples.splice(i, 1);
                                }
                                if (levelNode.SpecialElementType == SpecialElementType.Variable) {
                                    ObjectcreatorDataManager.LevelVariablesChanged.next(null);
                                }
                                DeleteTaskDialog.CheckTasks(this.DataDescription, selItem.UniqueID, false);
                                break;
                            }
                        }
                    });
                }
                else if (info.DropAreaType == OcDragItemType.Measure) {
                    info.SelectedItems.forEach(selItem => {
                        let mi: MeasureInfo = ha.Measures.find(x => x.UniqueID == selItem.UniqueID);
                        if (mi != null) {
                            let index = ha.Measures.indexOf(mi);
                            ha.Measures.splice(index, 1);
                            ObjectcreatorDataManager.MeasureChanged.next(null);
                            if (mi.SpecialElementType == SpecialElementType.Variable) {
                                ObjectcreatorDataManager.MeasureVariablesChanged.next(null);
                            }
                            DeleteTaskDialog.CheckTasks(this.DataDescription, selItem.UniqueID, true);
                        }
                    });
                }
                ObjectcreatorDataManager.AreaChanged.next(info);
            }
        }
    }

    async ReplaceDBHeterogenAreaElement(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length > 0 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null && info.SelectedItems.length > 0) {
                ObjectcreatorDataManager.ReplaceElementChoose(this.DataDescription, info);
            }
        }
    }

    static ReplaceElementChoose(dataDescription, info) {
        const isMeasure = info.DropAreaType === OcDragItemType.Measure
        const uniqueID = info.SelectedItems[0].UniqueID;
        BaseDialog.ShowDialog({
            ContentType: ReplaceElementDialog,
            Height: 600,
            Width: 600,
            InitArgs: {
                DataDescription: dataDescription,
                ID: uniqueID,
                IsMeasure: isMeasure
            },
            Buttons: DialogButton.None,
            Title: isMeasure ? '@@Wert zum Austausch waehlen' : '@@Level zum Austausch waehlen',
            Handler: (r) => {
                if (r) {
                    dataDescription = r;
                    ObjectcreatorDataManager.AreaChanged.next(info);
                }
                return true;
            }
        });
    }

    //#region Tuples
    BuildDBTupel(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length > 0 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                let tupleCurrent: Tuple = null;
                let tupelFirstNode: LevelNode = null;
                let indexTuple = 0;


                for (let i = 0; i < ha.Tuples.length; i++)//each(var tuple in ha.Tuples)
                {
                    let tuple: Tuple = ha.Tuples[i];
                    if (tuple.Levels != null) {
                        let ln: LevelNode = tuple.Levels.find(x => x.UniqueID == info.SelectedItems[0].UniqueID);
                        //falls Level schon in HA enthalten ist index anpassen und bereits vorhandenes Element löschen
                        if (ln != null) {
                            tupelFirstNode = ln;
                            indexTuple = i;//ha.Tuples.indexOf(tuple);
                            tupleCurrent = tuple;
                            //tuple.Levels.splice(tuple.Levels.indexOf(ln), 1);
                            //if (tuple.Levels.length === 0) {
                            //    tupleCurrent = tuple;
                            //}
                            //else {
                            //    tupleCurrent = new Tuple();
                            //    ha.Tuples.splice(indexTuple + 1, 0, tupleCurrent);
                            //}
                            break;
                        }
                    }
                }
                if (tupleCurrent != null) {
                    //tupleCurrent.Levels = [];

                    //if (tupelFirstNode != null) {
                    //    tupleCurrent.Levels.push(tupelFirstNode);
                    //}

                    if (info.SelectedItems.length > 1) {

                        info.SelectedItems.forEach(selItem => {
                            if (tupelFirstNode != null && tupelFirstNode.UniqueID != selItem.UniqueID) {
                                var ln = DataDescriptionHelper.FindLevelNodeByID(this.DataDescription, selItem.UniqueID, true);
                                if (ln !== null) {
                                    tupleCurrent.Levels.push(ln);
                                }
                            }
                        });
                    }
                    else { //momentan geht er immer hierher
                        if (ha.Tuples.length > indexTuple) {
                            const tupeltoConnect = ha.Tuples[indexTuple + 1];

                            for (let i = 0; i < tupeltoConnect.Levels.length; i++) {
                                const levelnode = tupeltoConnect.Levels[i];
                                tupeltoConnect.Levels.splice(0, 1);
                                tupleCurrent.Levels.push(levelnode);
                            }
                            ha.Tuples.splice(indexTuple + 1, 1);
                        }
                    }
                }
                ObjectcreatorDataManager.AreaChanged.next(info);
            }
        }
    }

    ResolveTupels(info: HeterogenAreaItemSelectedInfo) {
        if (info != null && info.SelectedItems.length > 0 && this.DataDescription != null) {
            let ha: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.DataDescription, info.HeterogenAreaID);
            if (ha != null) {
                info.SelectedItems.forEach(item => {
                    for (let i = 0; i < ha.Tuples.length; i++)//each(var tuple in ha.Tuples)
                    {
                        let tuple = ha.Tuples[i];
                        if (tuple.Levels != null) {
                            var ln = tuple.Levels.find(x => x.UniqueID == item.UniqueID);

                            if (ln != null) {
                                if (tuple.Levels.length > 1) {
                                    let insertIndex = ha.Tuples.indexOf(tuple);
                                    ha.Tuples.splice(insertIndex, 1);//(tuple);
                                    for (let j = 0; j < tuple.Levels.length; j++)//each(var level in tuple.Levels)
                                    {
                                        let level = tuple.Levels[j];
                                        let newTuple = new Tuple();
                                        newTuple.Levels.push(level);
                                        ha.Tuples.splice(insertIndex++, 0, newTuple);
                                    }
                                }
                            }
                        }
                    }
                });
                ObjectcreatorDataManager.AreaChanged.next(info);
            }
        }
    }
    // #endregion

    AddTask(task) {
        this.DataDescription.Tasks.push(task);
        ObjectcreatorDataManager.TasksChanged.next(null);
    }
}

export class HeterogenAreaItemBasicInfo {
    AreaID: number;

    UniqueID: number;

    MDBaseInternalID: number;

    ItemType: OcDragItemType;

    IsSpecialElement: boolean

    IsCalculated: boolean;
    IsEmptyElement: boolean
}


export class HeterogenAreaItemSelectedInfo {
    HeterogenAreaID: number;

    NewDestinationHeterogenAreaIDValue: number = -1;
    get NewDestinationHeterogenAreaID() {
        return this.NewDestinationHeterogenAreaIDValue === -1 ? this.HeterogenAreaID : this.NewDestinationHeterogenAreaIDValue;
    }

    AxisType: AxisType;

    NewDestinationAxisType: AxisType;

    DropAreaType: OcDragItemType;
    /// <summary>
    ///Index der HA in der Achse
    /// </summary>
    IndexInsideAxis: number;

    /// <summary>
    ///HA is in der Achse im ersten Index
    /// </summary>
    HA_IsFirstIndex: boolean;

    HAElementDBID: UUID

    AxisContainsMeasures: boolean;

    /// <summary>
    ///HA is in der Achse im letzten Index
    /// </summary>
    HA_IsLastIndex: boolean;

    SelectedItems: HeterogenAreaItemBasicInfo[] = [];

}
