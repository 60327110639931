import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { PlanType } from '../../../models/enums/plantype.enum';
import { CellReferenceArea, CellReferenceLevel } from '../../../models/planning/cell.reference.model';
import { MultiReportingService } from '../../../services/reporting.service';

@Component({
    selector: 'macplanning-active-cell-menu-tab',
    templateUrl: './activecell.menu.tab.html'
})
export class MacPlanningActiveCellMenuTab implements OnDestroy {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItem && this.SelectedItem.Element) {
            if (this.SelectedItem.Element.ActiveCell) {
                this.Subscriptions['ActiveCell'] = this.SelectedItem.Element.ActiveCell.subscribe((item) => {
                    this.ActiveCell = item;
                });
            }
        }
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    ActiveCellValue;
    get ActiveCell() {
        return this.ActiveCellValue;
    }
    set ActiveCell(val) {
        if (val && Array.isArray(val) && val.length == 1) {
            if (this.ActiveCellValue) {
                if (this.SelectedItemValue.CellDescriptions && this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet] && this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex] && this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName]) {
                    let cell = this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName];
                    if (!(cell.ViewFormula || cell.EditFormula || cell.UseReference)) {
                        delete this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName];
                        if (Object.keys(this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex]).length == 0) {
                            delete this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex];
                        }
                    }
                }
            }
            this.ActiveCellValue = val[0];
            if (this.SelectedItemValue && this.ActiveCell) {
                if (!this.SelectedItemValue.CellDescriptions) {
                    this.SelectedItemValue.CellDescriptions = {};
                }
                if (!this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet]) {
                    this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet] = {};
                }
                if (!this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex]) {
                    this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex] = {};
                }
                if (!this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName]) {
                    this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName] = {};
                }
                if (!this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName].Reference) {
                    this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName].Reference = {
                        X: {},
                        Y: {}
                    }
                }
            }
            if (this.Cell.Reference.UseLevel) {
                this.ReferenceType = 'level';
            }
            if (this.Cell.Reference.UseMeasure) {
                this.ReferenceType = 'measure';
            }
            if (this.Cell.Reference.UseFullReference) {
                this.ReferenceType = 'full';
            }
            
            this.ReferenceTypeChanged();
            let cells = [];
            let rows = Object.keys(this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet]);
            rows.forEach((row) => {
                let columns = Object.keys(this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][row]);
                columns.forEach((column) => {
                    if (this.ActiveCell.RowIndex != row || this.ActiveCell.ColumnName != column) {
                        cells.push({
                            Name: column + (parseInt(row) + 1)
                        });
                    }
                })
            })
            this.UsedCells = cells.sort((a, b) => {
                if (a.Name < b.Name) {
                    return -1;
                }
                if (a.Name > b.Name) {
                    return 1;
                }
                if (a.Name < b.Name) {
                    return 0;
                }
            });
            this.SetSelectedData();
        } else {
            this.ActiveCellValue = null;
        }
    }
    ReferenceType;
    UsedCells;
    Members;
    Subscriptions = {};
    PlanTypes;
    get Variables() {
        let items = [];
        if (this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName].References) {
            items.push(...this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName].References);
        }
        if (this.SelectedItem.References) {
            items.push(...this.SelectedItem.References);
        }
        let sheets = []
        if (this.SelectedItemValue.Sheets) {
            this.SelectedItemValue.Sheets.forEach((sheet) => {
                if (sheet.Name !== this.ActiveCell.Sheet) {
                    let item = {
                        Name: sheet.Name,
                        Children: [

                        ]
                    }
                    let cells = [];
                    let rows = Object.keys(this.SelectedItemValue.CellDescriptions[sheet.Name]);
                    rows.forEach((row) => {
                        let columns = Object.keys(this.SelectedItemValue.CellDescriptions[sheet.Name][row]);
                        columns.forEach((column) => {
                            if (!(sheet.Name == this.ActiveCell.Sheet && this.ActiveCell.RowIndex == row && this.ActiveCell.ColumnName == column)) {
                                cells.push({
                                    Name: sheet.Name + '.' + column + (parseInt(row) + 1)
                                });
                            }
                        })
                    })
                    item.Children = cells;
                    sheets.push(item);
                }
            });
        }
        return [{
            Name: 'References',
            Children: [...items]
        }, {
            Name: "Cells",
            Children: this.UsedCells
        }, {
            Name: "Sheets",
            Children: sheets
        }];
    }
    constructor(private multiService: MultiReportingService) {
        this.PlanTypes = EnumHelper.GetDropdownValues(PlanType);
    }  

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }

    SettingsChanged() {
        this.SelectedItem.ValuesChanged.next();
    }
    SelectedData;
    get Reference() {
        return this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName].Reference;
    };
    get Data() {
        return this.SelectedItem.Element.DataValue;
    }
    get Cell() {
        return this.SelectedItemValue.CellDescriptions[this.ActiveCell.Sheet][this.ActiveCell.RowIndex][this.ActiveCell.ColumnName];
    }
    Description;
    Axis = ['X', 'Y'];
    Areas = null;
    async SetSelectedData() {
        this.Description = {
            X: [],
            Y: []
        }
        this.SelectedData = null;
        if (this.Reference.DataDescriptionID) {
            this.SelectedData = this.Data.find((value) => { return this.Reference.DataDescriptionID == value.ID });
            if (this.SelectedData.IsRelational) {

            } else {
                if (!this.Reference.X) {
                    this.Reference.X = {};
                }
                if (!this.Reference.Y) {
                    this.Reference.Y = {};
                }
                this.FillAxis(this.SelectedData.Datadescription.XLevelNodes, this.Description.X, this.Reference.X);
                this.FillAxis(this.SelectedData.Datadescription.YLevelNodes, this.Description.Y, this.Reference.Y);
                this.AxisSelected();
            }
        }
    }
    ReferenceTypeChanged() {
        this.Cell.Reference.UseLevel = false;
        this.Cell.Reference.UseMeasure = false;
        this.Cell.Reference.UseFullReference = false;
        if (this.ReferenceType == 'level') {
            this.Cell.Reference.UseLevel = true;
        }
        if (this.ReferenceType == 'measure') {
            this.Cell.Reference.UseMeasure = true;
        }
        if (this.ReferenceType == 'full') {
            this.Cell.Reference.UseFullReference = true;
        }
    }
    DataDescriptionSelected() {
        this.Reference.X = {};
        this.Reference.Y = {};
        this.SetSelectedData();
        this.CheckRefresh();
    }
    AxisSelected() {
        if (this.Cell.Reference.Axis) {
            this.Areas = Object.keys(this.Description[this.Cell.Reference.Axis]);
        }
        this.CheckRefresh();
    }
    MeasureSelected(Measures) {
        this.Cell.Reference.MeasureCaption = null;
        if (Measures) {
            let measure = Measures.find((value) => {
                return value.Value == this.Cell.Reference.Measure;
            });
            if (measure) {
                this.Cell.Reference.MeasureCaption = measure.Caption;
            }
        }
        this.CheckRefresh();
    }
    LevelSelected(Levels) {
        this.Cell.Reference.LevelID = null;
        if (Levels) {
            let level = Levels.find((value) => {
                return value.ID == this.Cell.Reference.Level;
            });
            if (level) {
                this.Cell.Reference.LevelID = level.Value;
            }
        }
        this.CheckRefresh();
    }
    
    FillAxis(Node, Axis, ReferenceAxis) {
        if (!ReferenceAxis) {
            ReferenceAxis = {};
        }
        for (let i = 0; i < Node.Areas.length; i++) {
            let area = {
                Caption: 'Area ' + i,
                ID: i + '',
                Levels: [],
                Measures: []
            }
            if (!ReferenceAxis[area.ID]) {
                let refArea = new CellReferenceArea();
                refArea.AreaID = area.ID;
                refArea.Levels = {};
                if (i == 0) {
                    refArea.Selected = true;
                }
                ReferenceAxis[area.ID] = refArea;
            }
            let ll = {};
            Node.Areas[i].Tuples.forEach((tuple) => {
                tuple.Levels.forEach(async (level) => {
                    let lev = {
                        Caption: level.Caption.DefaultValue,
                        Value: level.UniqueID,
                        ID: level.Level,
                        Members: []
                    }
                    if (!ReferenceAxis[area.ID].Levels[lev.ID]) {
                        let refLevel = new CellReferenceLevel();
                        refLevel.LevelID = lev.Value;
                        ll[lev.ID] = refLevel;
                    } else {
                        ll[lev.ID] = ReferenceAxis[area.ID].Levels[lev.ID];
                    }
                    const cubeLevel = await MultiCacheService.GetLevel(level.Level, this.SelectedData.DataModelID);
                    if (cubeLevel) {
                        this.multiService.GetMembers(cubeLevel.Parent.Parent.DatasourceID, level.Level, null, null).subscribe(members => {
                            const memberList = [];
                            if (members) {
                                members.forEach(m => {
                                    memberList.push({
                                        Caption: m.Caption,
                                        Value: m.ID
                                    });
                                });
                            }
                            if (!this.Members) {
                                this.Members = {};
                            }
                            this.Members[level.Level] = memberList;
                        });
                    }
                    area.Levels.push(lev);
                });
            })
            ReferenceAxis[area.ID].Levels = ll;
            Node.Areas[i].Measures.forEach((measure) => {
                area.Measures.push({
                    Caption: measure.Caption.DefaultValue,
                    Value: measure.UniqueID,
                    ID: measure.Measure
                })
            })
            Axis.push(area);
        }
    }

    CheckRefresh() {
        let retVal = false;
        if (this.Cell.ViewFormula) {
            retVal = true;
        }
        if (this.Cell.UseReference) {
            if (this.Cell.Reference.UseLevel &&
                this.Cell.Reference.DataDescriptionID != null &&
                this.Cell.Reference.Axis != null &&
                this.Cell.Reference.Area != null &&
                this.Cell.Reference.Level != null &&
                this.Cell.Reference.MemberIndex != null) {
                retVal = true;
            }
            if (this.Cell.Reference.UseMeasure &&
                this.Cell.Reference.DataDescriptionID != null &&
                this.Cell.Reference.Axis != null &&
                this.Cell.Reference.Area != null &&
                this.Cell.Reference.Measure != null &&
                this.Cell.Reference.XLevel != null &&
                this.Cell.Reference.YLevel != null) {
                retVal = true;
            }
            if (this.Cell.Reference.UseFullReference) {
                retVal = true;
            }
        }
        if (retVal) {
            this.SelectedItemValue.Element.RefreshCalculations();
        }
    }
}