import { VisibilityType } from './enums/oc.enum';
import { BehaviorSubject } from 'rxjs';
import { UUID } from 'angular2-uuid';

export class PageStatus {
    ViewInfos: ViewInfo[] = [];
    TaskActiveInfos: Map<UUID, any> = new Map();

    static ActualState: BehaviorSubject<PageStatus> = new BehaviorSubject(new PageStatus());

    static GetTaskActiveStatus(task, objectID): boolean {
        const pagestatus = PageStatus.ActualState.getValue();
        const activities = pagestatus.TaskActiveInfos.get(objectID)
        if (typeof activities !== 'undefined') {
            const setting = activities.find(x => x.TaskID === task.ID);
            if (typeof setting !== 'undefined') {
                switch (setting.ActivityType) {
                    case TaskActivityType.SetActive:
                        return true;
                    case TaskActivityType.SetInactive:
                        return false;
                    case TaskActivityType.SwitchActivity:
                        if (typeof setting.ActualState === 'undefined') {
                            setting.ActualState = !task.IsActive;
                        } else {
                            setting.ActualState = !setting.ActualState;
                        }
                        return setting.ActualState;
                    default:
                        return true;
                }
            }
        }
        return task.IsActive;
    }
}

export class ViewInfo {
    QueryID;
    Entries: ViewInfoEntry[] = [];
}

export class ViewInfoEntry {
    WorkflowID;
    ModuleID;
    ObjectIDs = [];
    Settings: ViewSetting[] = [];
}

export class ViewSetting {
    ElementID: number;
    VisibleState: VisibilityType = VisibilityType.None;
}

export class ActivityData {
    ObjectID;
    QueryDataTasks: TaskActivity[] = [];
    ObjectDataTasks: TaskActivity[] = [];
    ObjectFormatTasks: TaskActivity[] = [];
}

export class TaskActivity {
    TaskID;
    ActivityType: TaskActivityType = TaskActivityType.None;
}
export enum TaskActivityType {
    None,
    SetActive,
    SetInactive,
    SwitchActivity
}
