import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { UserSettingsService } from '../../../../services/usersettings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-readprofiles-settings',
    templateUrl: './read.profiles.settings.html'
})
export class ReadProfilesSettings extends WorkflowDialogContent {
    Data = new ReadProfilesSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'readProfilesModule';
        reg.Caption = '@@Read profiles';
        reg.GroupID = 'profileGroup';
        reg.Index = 30;
        reg.SettingsControl = ReadProfilesSettings;
        reg.SettingsTypeHelper = new ReadProfilesSettingsDataHelper();
        reg.Executer = ReadProfilesModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadProfilesSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data && !this.Data.StatusKey && !this.Data.UseForState) {
            retVal.IsCorrect = false;
            retVal.Error = '@@SetKeyOrStateError';
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }
}

export class ReadProfilesSettingsData {
    StatusKey: string;
    UseForState = false;
}

export class ReadProfilesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadProfilesSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class ReadProfilesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const layout = status.WorkflowLayoutService.Layout;
        if (layout) {
            if (status.ActualSettings) {
                const service = InjectorHelper.InjectorInstance.get<UserSettingsService>(UserSettingsService);
                const retVal = await service.ReadProfileByLayout(layout._Id).toPromise();
                if (status.ActualSettings.StatusKey) {
                    status.Context.set(status.ActualSettings.StatusKey, retVal);
                }
                if (status.ActualSettings.UseForState) {
                    status.ActualParameter = retVal;
                }
                return 0;
            } else {
                status.Logger.logError('Read profiles modul: No settings found.');
            }
        } else {
            status.Logger.logError('Read profiles modul: No layout found.');
        }
        return super.execute(status);
    }
}
