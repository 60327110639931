import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Color } from '../../../models/style/color.model';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'boxshadow-theme-control',
    templateUrl: './boxshadow.theme.control.html',
    styleUrls:['./boxshadow.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxShadowThemeControl extends BaseThemeControl {
    SelectedEntry;
    SelectionChanged(item) {
        if (item) {
            if (!item.Color) {
                item.Color = '#000000';
            }
            if (item.ShiftRight == null) {
                item.ShiftRight = 0;
            }
            if (item.ShiftDown == null) {
                item.ShiftDown = 3;
            }
            if (item.Blur == null) {
                item.Blur = 6;
            }
            if (item.Spread == null) {
                item.Spread = 0;
            }
            if (item.Opacity == null) {
                item.Opacity = 0.16;
            }
            this.SelectedEntryColor = Color.FromHex(item.Color);
            this.SelectedEntryColor.A = item.Opacity * 255;

        } else {
            this.SelectedEntryColor = null;
        }
    }
    onStyleChanged() {
        this.StyleChanged.emit();
    }
    SelectedEntryColor;
}
