import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { GetMultiElemValueSettings } from './get.multi.elem.value.settings';
import { SetMultiElemValueSettings } from './set.multi.elem.value.settings';

@Component({
    selector: 'elemValue-settings',
    templateUrl: './elemValue.settings.html'
})
export class ElementValueSettings extends WorkflowDialogContent {
    Data = new ElementValueSettingsData();

    constructor() {
        super();
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ElementValueSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }
}

export class ElementValueSettingsData {
    ElemName: string;
}

export class ElementValueSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ElementValueSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class ElementValueModuleExecuter extends WorkflowModuleExecuter {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'elemValueModule';
        reg.Caption = '@@Wert auslesen';
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = ElementValueModuleExecuter;
        reg.GroupID = 'layout';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = GetMultiElemValueSettings.ModuleID;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        status.ActualParameter = elem.Element.DataSource;
                        return 0;
                    } else {
                        status.Logger.logError('ElementValue modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('ElementValue modul: Element not found.');
                }
            } else {
                status.Logger.logError('ElementValue modul: No element name found.');
            }
        } else {
            status.Logger.logError('ElementValue modul: No settings found.');
        }
        return super.execute(status);
    }
}

export class SetDataSourceModulExecuter extends WorkflowModuleExecuter {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setDataSourceModule';
        reg.Caption = '@@Wert setzen';
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = SetDataSourceModulExecuter;
        reg.GroupID = 'layout';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetMultiElemValueSettings.ModuleID;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        elem.Element.DataSource = status.ActualParameter;
                        return 0;
                    } else {
                        status.Logger.logError('SetDataSource modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('SetDataSource modul: Element not found.');
                }
            } else {
                status.Logger.logError('SetDataSource modul: No element name found.');
            }
        } else {
            status.Logger.logError('SetDataSource modul: No settings found.');
        }
        return super.execute(status);
    }
}
