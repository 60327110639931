import { WorkflowModuleSettingsHelper, WorkflowExitInfo, WorkflowRegistry } from "../../../services/workflow.service";
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from "../../../models/workflow/workflow.model";

export class GetListCountSettingsHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetListCountSettingsData();
    }

    public static GetRegistry(): WorkflowRegistry {
        let reg = new WorkflowRegistry();
        reg.ID = 'getlistcountWFModule';
        reg.GroupID = 'listoperations';
        reg.Caption = "@@Listenlaenge auslesen";
        reg.SettingsTypeHelper = new GetListCountSettingsHelper();
        reg.Executer = GetListCountModuleExecuter;
        return reg;
    }
}

export class GetListCountSettingsData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ListOperations.GetListCountSettingsData';
    }
}

export class GetListCountModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (Array.isArray(status.ActualParameter)) {
                status.ActualParameter = status.ActualParameter.length;
                return 0;
            } else {
                status.Logger.logError("GetListCount modul: No enumerable parameter set.");
            }
        } else {
            status.Logger.logError("GetListCount modul: No settings found.");
        }
        return super.execute(status);
    }
}