import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { StringHelper, TranslateFormatText } from '../../../helpers/array.helpers';
import { ContainerPathHelper } from '../../../helpers/containerpath.helper';
import { EnumHelper } from '../../../helpers/enum.helper';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { FormulaNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { Aggregation, MeasureType } from '../../../models/enums';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import { RelQueryDefDialog } from '../dialogs/rel.query.def.dialog';

@Component({
    selector: 'containercube-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerCubeSettings extends BaseListSettings {

    public static GetSettingsEntry() {
        return {
            Caption: '@@ContainerCube',
            ID: 'containercube',
            Icon: 'table_chart',
            Index: 20,
            Parent: 'multidimensional',
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.MultidimensionalContainerRight',
                Value: 4
            },
            Content: ContainerCubeSettings
        };
    }

    constructor(private translate: TranslateService, private dataModelService: DataModelService,
        private settingsService: DataSettingsService, protected factoryResolver: ComponentFactoryResolver,
        protected cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ContainerCubeDetail;
    }

    loadList(handler) {
        const comp = this.Component;
        if (comp) {
            comp.ContainerDimensions = [];
            comp.setTableInfos([]);
            comp.DataSources = [];
        }
        if (this.InitArgs) {
            this.dataModelService.GetContainersOfType(this.InitArgs.SID,
                'evidanza.MiddleWare.Shared.Multidimensional.ContainerCube').subscribe((result) => {
                if (result) {
                    const list = [];
                    result.forEach(item => {
                        list.push({
                            Caption: item.Caption,
                            ID: item.SID,
                            IsCapsule: item.IsCapsule,
                            IsOverridden: item.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            if (comp) {
                this.settingsService.GetAllContainerDimensions(this.InitArgs.SID).subscribe((result) => {
                    if (result) {
                        comp.ContainerDimensions = result;
                    }
                });
                this.settingsService.GetAllTableInfos(this.InitArgs.SID).subscribe((result) => {
                    if (result && comp) {
                        comp.setTableInfos(result);
                    }
                });
                const query = new DataSourceQuery(this.InitArgs.SID);
                query.DBUsages = [[268435456]]; // QueryEngine
                query.ResourceBase = 0; // Database
                this.dataModelService.GetDataSourcesByQuery(query).subscribe(ds => {
                    if (ds) {
                        comp.DataSources = ds;
                    }
                });
            }
        }
    }

    loadData(data) {
        if (data) {
            this.dataModelService.GetContainer(data).subscribe((cd) => {
                if (cd) {
                    this.setSelectedItem(cd);
                }
            });
        }
    }

    getNewItem() {
        let dsID = null;
        if (this.Component && this.Component.DataSources && this.Component.DataSources.length > 0) {
            dsID = this.Component.DataSources[0].SID;
        }
        const name = this.translate.instant('@@Neuer Cube');
        return {
            DataModelID: this.InitArgs.SID,
            Name: name,
            DataSourceId: dsID,
            Dimensions: [],
            Measures: [],
            Partitions: [{
                ID: UUID.UUID(),
                SourceQuery: {
                    Columns: [],
                    Relations: []
                },
                Slices: [], // ???
                Mappings: {
                }
            }],
            Folders: [],
            IsWritable: false,
            IsCaseSensitive: false,
            IndexCluster: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Cube \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Cube \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Cube loeschen');
        return retVal;
    }

    delete(data) {
        this.dataModelService.DeleteContainer(data).subscribe();
    }

    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts;
        retVal.Text = new TranslateFormatText('@@Cube \'{0}\' erfolgreich gespeichert');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Cube speichern');
        return retVal;
    }

    protected async checkCanSave(): Promise<DataCheck> {
        const retVal = new DataCheck();
        const comp = this.Component;
        if (comp) {
            const cube = comp.SelectedItem;
            if (cube) {
                const errorList = [];
                const missingMappings = [];
                if (cube.Partitions && cube.Partitions.length > 0) {
                    cube.Partitions.forEach(p => {
                        missingMappings.push([]);
                        if (!p.Mappings) {
                            p.Mappings = {
                            };
                        }
                    });
                } else {
                    cube.Partitions = [{
                        ID: UUID.UUID(),
                        SourceQuery: {
                            Columns: [],
                            Relations: []
                        },
                        Slices: [], // ???
                        Mappings: {
                        }
                    }];
                    missingMappings.push([]);
                }
                if (!cube.DataSourceId) {
                    errorList.push(this.translate.instant('@@Fehlende Datenquelle'));
                }
                if (cube.Dimensions) {
                    const dims = comp.ContainerDimensions;
                    const noDim = [];
                    const noKeyAttr = [];
                    let hasCheckOut = false;
                    const noCheckOutAttr = [];
                    const allAttributes = new Set();
                    cube.Dimensions.forEach(dim => {
                        for (let i = 0; i < cube.Partitions.length; i++) {
                            const dimMapping = cube.Partitions[i].Mappings[dim.ID];
                            if (typeof dimMapping !== 'number' || dimMapping < 0) {
                                missingMappings[i].push(dim.TranslatedCaption);
                            }
                        }
                        if (dim.ContainerDimension) {
                            const dimAttributes = comp.getAttrList(dim);
                            dimAttributes.forEach(attr => {
                                allAttributes.add(attr);
                            });
                            if (dim.IncludedAttributes && dim.IncludedAttributes.length > 0) {
                                dims.some(cd => {
                                    if (cd.SID === dim.ContainerDimension) {
                                        cd.Attributes.some(attr => {
                                            if (attr.IsKey) {
                                                if (!dim.IncludedAttributes.some(ia => ia === attr.SID)) {
                                                    if (!dim.IncludedAttributes.some(ia => ia === dim.AlternateKeyAttribute)) {
                                                        noKeyAttr.push(dim.TranslatedCaption);
                                                    }
                                                }
                                                return true;
                                            }
                                            return false;
                                        });
                                        return true;
                                    }
                                    return false;
                                });
                            }
                            if (dim.IsCheckOut) {
                                if (dim.CheckOutAttribute) {
                                    hasCheckOut = true;
                                } else {
                                    noCheckOutAttr.push(dim.TranslatedCaption);
                                }
                            }
                        } else {
                            noDim.push(dim.TranslatedCaption);
                        }
                    });
                    if (noDim.length > 0) {
                        errorList.push(StringHelper.format(this.translate.instant('@@Fehlende Containerdimension (Dimensionen: {0})'),
                            [noDim.join(', ')]));
                    }
                    if (noKeyAttr.length > 0) {
                        errorList.push(StringHelper.format(this.translate.instant('@@Fehlendes KeyAttribut (Dimensionen: {0})'),
                            [noKeyAttr.join(', ')]));
                    }
                    if (cube.IsWritable) {
                        if (!hasCheckOut) {
                            errorList.push(this.translate.instant('@@Fehlende CheckOutDimension'));
                        }
                        if (noCheckOutAttr.length > 0) {
                            errorList.push(StringHelper.format(this.translate.instant('@@Fehlendes CheckOutAttribut (Dimensionen: {0})'),
                                [noCheckOutAttr.join(', ')]));
                        }
                    }

                    // Pr�fen, ob alle Attribute in IndexCluster verbaut sind
                    if (allAttributes.size > 0) {
                        if (cube.IndexCluster) {
                            cube.IndexCluster.forEach(ic => {
                                for (let i = 0; i < ic.Attributes.length; i++) {
                                    if (!allAttributes.delete(ic.Attributes[i])) {
                                        ic.Attributes.splice(i, 1);
                                        i--;
                                    }
                                }
                            });
                        }
                        if (allAttributes.size > 0) {
                            if (cube.IndexCluster && cube.IndexCluster.length > 0) {
                                const first = cube.IndexCluster[0];
                                allAttributes.forEach(attr => {
                                    first.Attributes.push(attr);
                                });
                            }
                        }
                    }
                }
                let showClusterWarning = false;
                if (cube.IndexCluster && cube.IndexCluster.length > 0) {
                    for (let i = 0; i < cube.IndexCluster.length; i++) {
                        const ic = cube.IndexCluster[i];
                        if (ic.Attributes.length === 0) {
                            cube.IndexCluster.splice(i, 1);
                            i--;
                        } else if (ic.Attributes.length < 2 || ic.Attributes.length > 5) {
                            showClusterWarning = true;
                        }
                    }
                }

                // Formeln checken
                if (cube.Measures) {
                    const parser = new AdvancedFormulaParser();
                    parser.SetVariables(comp.Variables, FormulaInputMode.AliasKey);
                    const wrongFormulas = [];
                    cube.Measures.forEach(m => {
                        for (let i = 0; i < cube.Partitions.length; i++) {
                            const dimMapping = cube.Partitions[i].Mappings[m.ID];
                            if (typeof dimMapping !== 'number' || dimMapping < 0) {
                                missingMappings[i].push(m.TranslatedCaption);
                            }
                        }
                        if (typeof m.FormulaText === 'string' && m.FormulaText !== '') {
                            try {
                                m.Formula = parser.Parse(m.FormulaText);
                                m.Aggregator = Aggregation.Sum;
                            } catch {
                                wrongFormulas.push(m.TranslatedCaption);
                            }
                        }
                    });
                    if (wrongFormulas.length > 0) {
                        errorList.push(StringHelper.format(this.translate.instant('@@Fehlerhafte Formeln (Werte: {0})'),
                            [wrongFormulas.join(', ')]));
                    }
                }
                let index = 1;
                missingMappings.forEach(innerlist => {
                    if (innerlist.length > 0) {
                        errorList.push(StringHelper.format(this.translate.instant('@@Fehlende Mappings ({0}) fuer Partition {1}'),
                            [missingMappings.join(', '), index.toString()]));
                    }
                    index++;
                });

                if (errorList.length > 0) {
                    retVal.IsCorrect = false;
                    retVal.Error = errorList.join('\n');
                } else if (retVal.IsCorrect && showClusterWarning) {
                    MessageBoxHelper.ShowDialog(
                        new TranslateFormatText('@@Bitte Cluster-Groessen pruefen. (Optimale Groesse: 2-5 Attribute)'),
                        new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                }
            }
        }
        return retVal;
    }

    saveInternal(item, handler) {
        if (item) {
            this.settingsService.SaveContainerCube(item).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containercube-detail',
    templateUrl: './containercube.settings.html',
    styleUrls: ['./containercube.settings.css']
})
export class ContainerCubeDetail extends ADataModelDetail {
    SelectedDimension;
    SelectedMeasure;
    SelectedPartition;
    ContainerDimensions = [];
    Variables = [];
    VariableIndex = 1;
    AggregationFunctions;
    MeasureTypes;
    DataSources = [];

    treeControl = new FlatTreeControl<any>(node => node.Fields ? 0 : 1, node => node.Fields);
    treeFlattener = new MatTreeFlattener<any, any>(node => node, node => node.Fields ? 0 : 1, node => node.Fields, node => node.Fields);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    DimensionPartitionMappings = [];
    MeasurePartitionMappings = [];
    private tableInfos = [];
    private tableCaptions = new Map();
    DraggedIndex;

    constructor(private translate: TranslateService, private settingsService: DataSettingsService) {
        super();
        this.AggregationFunctions = EnumHelper.GetDropdownValues(Aggregation);
        this.MeasureTypes = EnumHelper.GetDropdownValues(MeasureType);
    }

    hasChild = (_: number, node: any) => node.Fields;

    setSelectedItem(item: any): void {
        this.SelectedDimension = null;
        this.SelectedMeasure = null;
        this.SelectedPartition = null;
        this.Variables = [];
        this.VariableIndex = 1;
        if (item) {
            if (item.Measures) {
                let index = 1;
                const variables = [];
                const keys = new Map<string, string>();
                item.Measures.forEach(m => {
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = m.ID;
                    vni.AliasKey = ContainerPathHelper.GetExcelColumnName(index++);
                    vni.Name = m.TranslatedCaption;
                    variables.push(vni);
                    keys.set(vni.VariableID, vni.AliasKey);
                });
                item.Measures.forEach(m => {
                    if (m.Formula) {
                        m.Formula = plainToClass(FormulaNodeInformation, m.Formula);
                        FormulaNodeInformation.ApplyAliasKeys(keys, m.Formula);
                        m.FormulaText = m.Formula.ToString(null, VariableDisplay.AliasKey);
                    }
                });
                this.Variables = variables;
                this.VariableIndex = index;
            }
            if (item.Partitions && item.Partitions.length > 0) {
                let index = 1;
                item.Partitions.forEach(p => {
                    p.Caption = this.translate.instant('@@Partition') + ' ' + index++;
                });
                this.SelectedPartition = item.Partitions[0];
            }
        }
        super.setSelectedItem(item);
        this.selectedPartitionChanged();
    }

    setTableInfos(ti) {
        this.tableInfos = ti;
        this.updateTree();
    }

    private updateTree() {
        const data = [];
        const captions = new Map();
        if (this.tableInfos && this.SelectedPartition) {
            const sc = this.SelectedPartition.SourceQuery;
            if (sc) {
                sc.Columns.forEach(c => {
                    const ti = this.tableInfos.find(t => t.SID === c.ContainerID);
                    if (ti) {
                        const fi = ti.Fields.find(x => x.SID === c.FieldID);
                        if (fi) {
                            let parentNode = data.find(x => x.UniqueID === c.ContainerUniqueID);
                            if (!parentNode) {
                                parentNode = {
                                    Caption: ti.Caption + ' (' + c.ContainerUniqueID + ')',
                                    UniqueID: c.ContainerUniqueID,
                                    Fields: []
                                };
                                data.push(parentNode);
                            }
                            const childNode = {
                                Caption: fi.Caption + ' (' + c.UniqueID + ')',
                                UniqueID: c.UniqueID
                            };
                            captions.set(c.UniqueID, parentNode.Caption + ' -> ' + childNode.Caption);
                            parentNode.Fields.push(childNode);
                        }
                    }
                });
            }
        }
        this.dataSource.data = data;
        this.tableCaptions = captions;
    }

    processCube() {
        const selected = this.SelectedItem;
        if (selected && selected.SID) {
            NotificationHelper.Info("Process Cube gestartet.", "Information");
            this.settingsService.Process(selected.DataSourceId, selected.SID).subscribe(() => {
                NotificationHelper.Info("Process Cube beendet.", "Information");
            });
        }
    }

    selectedPartitionChanged() {
        this.DimensionPartitionMappings = [];
        this.MeasurePartitionMappings = [];
        if (this.SelectedPartition && this.SelectedItem) {
            const mappings = this.SelectedPartition.Mappings;
            if (mappings) {
                Object.keys(mappings).forEach(k => {
                    let map;
                    if (this.SelectedItem.Dimensions) {
                        map = this.SelectedItem.Dimensions.find(x => x.ID === k);
                    }
                    if (map) {
                        this.DimensionPartitionMappings.push({
                            Key: k,
                            Value: mappings[k],
                            Caption: TranslatedString.GetTranslation(map.Caption)
                        });
                    } else if (this.SelectedItem.Measures) {
                        map = this.SelectedItem.Measures.find(x => x.ID === k);
                        if (map) {
                            this.MeasurePartitionMappings.push({
                                Key: k,
                                Value: mappings[k],
                                Caption: TranslatedString.GetTranslation(map.Caption)
                            });
                        }
                    }
                });
            }
        }
        this.updateTree();
    }

    addPartition() {
        if (this.SelectedItem) {
            const part = {
                ID: UUID.UUID(),
                SourceQuery: {
                    Columns: [],
                    Relations: []
                },
                Slices: [], // ???
                Mappings: {
                }
            };
            this.SelectedItem.Dimensions.forEach(d => {
                part.Mappings[d.ID] = -1;
            });
            this.SelectedItem.Measures.forEach(m => {
                part.Mappings[m.ID] = -1;
            });
            this.SelectedItem.Partitions.push(part);
            part['Caption'] = this.translate.instant('@@Partition') + ' ' + this.SelectedItem.Partitions.length;
            this.SelectedPartition = part;
            this.selectedPartitionChanged();
            this.OnItemChanged();
        }
    }

    deletePartition() {
        if (this.SelectedItem && this.SelectedPartition) {
            const index = this.SelectedItem.Partitions.indexOf(this.SelectedPartition);
            if (index >= 0) {
                this.SelectedItem.Partitions.splice(index, 1);
                if (this.SelectedItem.Partitions.length > 0) {
                    let i = 1;
                    this.SelectedItem.Partitions.forEach(p => {
                        p.Caption = this.translate.instant('@@Partition') + ' ' + i++;
                    });
                }
                this.SelectedPartition = null;
                this.selectedPartitionChanged();
                this.OnItemChanged();
            }
        }
    }

    selectedDimensionChanged() {
        let id;
        let attributes = [];
        let alternate;
        if (this.SelectedDimension) {
            id = this.SelectedDimension.ContainerDimension;
            if (this.SelectedDimension.IncludedAttributes && this.SelectedDimension.IncludedAttributes.length > 0) {
                attributes = this.SelectedDimension.IncludedAttributes;
                alternate = this.SelectedDimension.AlternateKeyAttribute;
            }
        }
        this.ContainerDimensions.forEach(dim => {
            if (dim.SID === id && attributes.length > 0) {
                dim.Attributes.forEach(attr => {
                    attr.InUse = attributes.some(a => a === attr.SID);
                    attr.IsAlternateKey = attr.SID === alternate;
                });
            } else {
                dim.Attributes.forEach(attr => {
                    attr.InUse = true;
                    attr.IsAlternateKey = false;
                });
            }
        });
    }

    addCubeDim() {
        if (this.SelectedItem) {
            const name = this.translate.instant('@@Neue Dimension');
            const dim = {
                ID: UUID.UUID(),
                Name: name,
                ContainerDimension: null,
                IsCheckOut: false,
                IncludedAttributes: [],
                AlternateKeyAttribute: null,
                CheckOutAttribute: null,
                TranslatedCaption: name
            };
            this.SelectedItem.Partitions.forEach(p => {
                p.Mappings[dim.ID] = -1;
            });
            if (this.SelectedPartition) {
                this.DimensionPartitionMappings.push({
                    Key: dim.ID,
                    Value: -1,
                    Caption: name
                });
            }
            this.SelectedItem.Dimensions.push(dim);
            this.SelectedDimension = dim;
            this.selectedDimensionChanged();
            this.OnItemChanged();
        }
    }

    deleteCubeDim() {
        if (this.SelectedItem && this.SelectedDimension) {
            const index = this.SelectedItem.Dimensions.indexOf(this.SelectedDimension);
            if (index >= 0) {
                this.checkRemoveFromIndexCluster();
                this.SelectedItem.Dimensions.splice(index, 1);
                this.SelectedItem.Partitions.forEach(p => {
                    delete p.Mappings[this.SelectedDimension.ID];
                });
                if (this.SelectedPartition) {
                    let pIndex = -1;
                    this.DimensionPartitionMappings.some((pm, i) => {
                        if (pm.Key === this.SelectedDimension.ID) {
                            pIndex = i;
                            return true;
                        }
                        return false;
                    });
                    if (pIndex > -1) {
                        this.DimensionPartitionMappings.splice(pIndex, 1);
                    }
                }
                this.SelectedDimension = null;
                this.selectedDimensionChanged();
                this.OnItemChanged();
            }
        }
    }

    dimCaptionChanged() {
        if (this.SelectedDimension) {
            this.SelectedDimension.TranslatedCaption = TranslatedString.GetTranslation(this.SelectedDimension.Caption);
            this.DimensionPartitionMappings.some(pm => {
                if (pm.Key === this.SelectedDimension.ID) {
                    pm.Caption = this.SelectedDimension.TranslatedCaption;
                    return true;
                }
                return false;
            });
            this.OnItemChanged();
        }
    }

    containerDimensionChanged() {
        if (this.SelectedDimension) {
            this.checkRemoveFromIndexCluster();
            this.SelectedDimension.IncludedAttributes = [];
            this.SelectedDimension.CheckOutAttribute = null;
            this.SelectedDimension.AlternateKeyAttribute = null;
            const id = this.SelectedDimension.ContainerDimension;
            if (id) {
                this.ContainerDimensions.forEach(cd => {
                    if (cd.SID === id) {
                        cd.Attributes.forEach(attr => {
                            attr.InUse = true;
                            attr.IsAlternateKey = false;
                            this.addToIndexCluster(attr.SID);
                        });
                    } else {
                        cd.Attributes.forEach(attr => {
                            attr.InUse = true;
                            attr.IsAlternateKey = false;
                        });
                    }
                });
            }
            this.OnItemChanged();
        }
    }

    getAttributes() {
        let retVal = [];
        if (this.SelectedDimension && this.SelectedDimension.ContainerDimension && this.ContainerDimensions) {
            const scd = this.SelectedDimension.ContainerDimension;
            this.ContainerDimensions.some(cd => {
                if (cd.SID === scd) {
                    retVal = cd.Attributes;
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }

    isInUse(id) {
        if (this.SelectedDimension) {
            return this.SelectedDimension.IncludedAttributes.length === 0 ||
                this.SelectedDimension.IncludedAttributes.some(ia => ia === id);
        }
        return false;
    }

    attrUsedChanged(ev, id) {
        if (this.SelectedDimension) {
            if (typeof ev === 'boolean') {
                if (ev) {
                    this.SelectedDimension.IncludedAttributes.push(id);
                    this.addToIndexCluster(id);
                    this.OnItemChanged();
                } else {
                    if (this.SelectedDimension.IncludedAttributes.length === 0) {
                        const dimID = this.SelectedDimension.ContainerDimension;
                        const attributes = this.SelectedDimension.IncludedAttributes;
                        this.ContainerDimensions.some(cd => {
                            if (cd.SID === dimID) {
                                cd.Attributes.forEach(attr => {
                                    if (attr.SID !== id) {
                                        attributes.push(attr.SID);
                                        this.addToIndexCluster(attr.SID);
                                    }
                                });
                                return true;
                            }
                            return false;
                        });
                    } else {
                        const index = this.SelectedDimension.IncludedAttributes.indexOf(id);
                        if (index >= 0) {
                            this.SelectedDimension.IncludedAttributes.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                    this.revomeFromIndexCluster(id);
                }
            }
        }
    }

    isAlternateKey(id) {
        if (this.SelectedDimension) {
            return this.SelectedDimension.AlternateKeyAttribute === id;
        }
        return false;
    }

    alternateKeyChanged(ev, id) {
        if (this.SelectedDimension) {
            if (typeof ev === 'boolean') {
                if (ev) {
                    this.SelectedDimension.AlternateKeyAttribute = id;
                } else {
                    this.SelectedDimension.AlternateKeyAttribute = null;
                }
                this.OnItemChanged();
            }
        }
    }

    attrCheckoutChanged(ev, id) {
        if (this.SelectedDimension) {
            this.SelectedDimension.CheckOutAttribute = ev === true ? id : null;
            this.OnItemChanged();
        }
    }

    addCubeMeasure() {
        if (this.SelectedItem) {
            const name = this.translate.instant('@@Neuer Wert');
            const measure = {
                ID: UUID.UUID(),
                Name: name,
                Caption: new TranslatedString(name),
                TranslatedCaption: name,
                Formula: null,
                Aggregator: Aggregation.Sum,
                Type: MeasureType.Double,
                FormulaText: null
            };
            this.SelectedItem.Partitions.forEach(p => {
                p.Mappings[measure.ID] = -1;
            });
            if (this.SelectedPartition) {
                this.MeasurePartitionMappings.push({
                    Key: measure.ID,
                    Value: -1,
                    Caption: name
                });
            }
            this.SelectedItem.Measures.push(measure);
            const vni = new VariablesNodeInformation();
            vni.VariableID = measure.ID;
            vni.AliasKey = ContainerPathHelper.GetExcelColumnName(this.VariableIndex++);
            vni.Name = name;
            this.Variables.push(vni);
            this.SelectedMeasure = measure;
            this.OnItemChanged();
        }
    }

    deleteCubeMeasure() {
        if (this.SelectedItem && this.SelectedMeasure) {
            const index = this.SelectedItem.Measures.indexOf(this.SelectedMeasure);
            if (index >= 0) {
                this.SelectedItem.Measures.splice(index, 1);
                for (let i = 0; i < this.Variables.length; i++) {
                    if (this.Variables[i].VariableID === this.SelectedMeasure.ID) {
                        this.Variables.splice(i, 1);
                        break;
                    }
                }
                this.SelectedItem.Partitions.forEach(p => {
                    delete p.Mappings[this.SelectedMeasure.ID];
                });
                if (this.SelectedPartition) {
                    let pIndex = -1;
                    this.MeasurePartitionMappings.some((pm, i) => {
                        if (pm.Key === this.SelectedMeasure.ID) {
                            pIndex = i;
                            return true;
                        }
                        return false;
                    });
                    if (pIndex > -1) {
                        this.MeasurePartitionMappings.splice(pIndex, 1);
                    }
                }
                this.SelectedMeasure = null;
                this.OnItemChanged();
            }
        }
    }

    measureCaptionChanged() {
        if (this.SelectedMeasure) {
            const id = this.SelectedMeasure.ID;
            const caption = TranslatedString.GetTranslation(this.SelectedMeasure.Caption);
            this.SelectedMeasure.TranslatedCaption = caption;
            this.Variables.some(v => {
                if (v.VariableID === id) {
                    v.Name = caption;
                    return true;
                }
                return false;
            });
            this.MeasurePartitionMappings.some(pm => {
                if (pm.Key === id) {
                    pm.Caption = caption;
                    return true;
                }
                return false;
            });
            this.OnItemChanged();
        }
    }

    getCaption(cfi) {
        return this.tableCaptions.get(cfi);
    }

    openFormulaDialog() {
        const measure = this.SelectedMeasure;
        if (measure) {
            FormulaEditorDialog.ShowDialog({
                Variables: this.Variables,
                Formula: measure.FormulaText
            }, (r) => {
                if (r) {
                    measure.FormulaText = r.Formula;
                    this.OnItemChanged();
                }
                return true;
            });
        }
    }

    addClusterIndex() {
        if (this.SelectedItem && this.SelectedItem.IndexCluster) {
            this.SelectedItem.IndexCluster.push({
                CanUnload: false,
                BuildAggregate: true,
                Attributes: []
            });
            this.OnItemChanged();
        }
    }

    deleteIndexCluster(i) {
        if (this.SelectedItem && this.SelectedItem.IndexCluster && i >= 0 && i < this.SelectedItem.IndexCluster.length &&
            this.SelectedItem.IndexCluster.length > 1) {
            const delCluster = this.SelectedItem.IndexCluster[i];
            if (delCluster.Attributes && delCluster.Attributes.length > 0) {
                const prevCluster = (i === 0) ? this.SelectedItem.IndexCluster[1] : this.SelectedItem.IndexCluster[i - 1];
                prevCluster.Attributes.push(...delCluster.Attributes);
            }
            this.SelectedItem.IndexCluster.splice(i, 1);
            this.OnItemChanged();
        }
    }

    unloadChanged(ev, i) {
        if (ev === true && this.SelectedItem && this.SelectedItem.IndexCluster && i >= 0 && i < this.SelectedItem.IndexCluster.length) {
            for (let j = 0; j < this.SelectedItem.IndexCluster.length; j++) {
                if (j !== i) {
                    this.SelectedItem.IndexCluster[j].CanUnload = false;
                    this.OnItemChanged();
                }
            }
        }
    }

    dragStart(event, cluster, index) {
        this.DraggedIndex = {
            Cluster: cluster,
            Index: index
        };
    }

    dragEnd(event) {
        this.DraggedIndex = null;
    }

    drop(ev, cluster, index) {
        if (this.SelectedItem && this.SelectedItem.IndexCluster && this.DraggedIndex) {
            if (cluster === this.DraggedIndex.Cluster) {
                if (index === this.DraggedIndex.Index) {
                    this.DraggedIndex = null;
                    return;
                }
                if (ev.currentTarget) {
                    if (ev.offsetY / ev.currentTarget.clientHeight > 0.5) {
                        index++;
                        if (index === this.DraggedIndex.Index) {
                            this.DraggedIndex = null;
                            return;
                        }
                    }
                }
                if (index > this.DraggedIndex.Index) {
                    index--;
                }
                const targetCluster = this.SelectedItem.IndexCluster[cluster];
                const attr = targetCluster.Attributes[this.DraggedIndex.Index];
                targetCluster.Attributes.splice(this.DraggedIndex.Index, 1);
                targetCluster.Attributes.splice(index, 0, attr);
            } else {
                if (ev.currentTarget) {
                    if (ev.offsetY / ev.currentTarget.clientHeight > 0.5) {
                        index++;
                    }
                }
                const sourceCluster = this.SelectedItem.IndexCluster[this.DraggedIndex.Cluster];
                const attr = sourceCluster.Attributes[this.DraggedIndex.Index];
                sourceCluster.Attributes.splice(this.DraggedIndex.Index, 1);
                this.SelectedItem.IndexCluster[cluster].Attributes.splice(index, 0, attr);
            }
            this.DraggedIndex = null;
            this.OnItemChanged();
        }
    }

    getAttributeCaption(attr) {
        let retVal = '';
        this.ContainerDimensions.some(cd => {
            return cd.Attributes.some(dimAttr => {
                if (dimAttr.SID === attr) {
                    retVal = dimAttr.Caption;
                    return true;
                }
                return false;
            });
        });
        return retVal;
    }

    addToIndexCluster(id) {
        if (this.SelectedItem) {
            if (this.SelectedItem.IndexCluster && this.SelectedItem.IndexCluster.length > 0) {
                let add = true;
                this.SelectedItem.IndexCluster.some(ic => {
                    if (ic.Attributes.some(attr => attr === id)) {
                        add = false;
                    }
                    return !add;
                });
                if (add) {
                    this.SelectedItem.IndexCluster[0].Attributes.push(id);
                    this.OnItemChanged();
                }
            } else {
                this.SelectedItem.IndexCluster = [{
                    CanUnload: false,
                    BuildAggregate: true,
                    Attributes: [id]
                }];
                this.OnItemChanged();
            }
        }
    }

    revomeFromIndexCluster(id) {
        if (this.SelectedItem && this.SelectedItem.IndexCluster) {
            for (let i = 0; i < this.SelectedItem.IndexCluster.length; i++) {
                const cluster = this.SelectedItem.IndexCluster[i];
                for (let j = 0; j < cluster.Attributes.length; j++) {
                    if (cluster.Attributes[j] === id) {
                        cluster.Attributes.splice(j, 1);
                        this.OnItemChanged();
                        return;
                    }
                }
            }
        }
    }

    checkRemoveFromIndexCluster() {
        if (this.SelectedDimension) {
            const attrList = this.getAttrList(this.SelectedDimension);
            if (attrList.length > 0) {
                if (this.SelectedItem) {
                    const dimID = this.SelectedDimension.ID;
                    this.SelectedItem.Dimensions.some(cd => {
                        if (cd.ID !== dimID) {
                            const cdAttrList = this.getAttrList(cd);
                            cdAttrList.some(cdAttr => {
                                for (let i = 0; i < attrList.length; i++) {
                                    if (attrList[i] === cdAttr) {
                                        attrList.splice(i, 1);
                                        i--;
                                    }
                                }
                                return attrList.length === 0;
                            });
                        }
                        return attrList.length === 0;
                    });
                }
                attrList.forEach(attr => {
                    this.revomeFromIndexCluster(attr);
                });
            }
        }
    }

    getAttrList(dim) {
        let attrList = [];
        if (dim.IncludedAttributes && dim.IncludedAttributes.length > 0) {
            attrList = attrList.concat(dim.IncludedAttributes);
        } else {
            const dimCD = dim.ContainerDimension;
            if (dimCD) {
                this.ContainerDimensions.some(cd => {
                    if (cd.SID === dimCD) {
                        cd.Attributes.forEach(dimAttr => {
                            attrList.push(dimAttr.SID);
                        });
                        return true;
                    }
                    return false;
                });
            }
        }
        return attrList;
    }

    editQueryDef() {
        if (this.SelectedItem) {
            const part = this.SelectedPartition;
            if (part && part.SourceQuery) {
                const usedIDs = [];
                Object.keys(part.Mappings).forEach(k => {
                    const val = part.Mappings[k];
                    if (typeof val === 'number' && val >= 0) {
                        usedIDs.push(val);
                    }
                });
                RelQueryDefDialog.ShowDialog(part.SourceQuery, this.SelectedItem.DataModelID, usedIDs, r => {
                    if (r) {
                        part.SourceQuery = r;
                        this.OnItemChanged();
                        this.updateTree();
                    }
                });
            }
        }
    }

    mappingChanged(pm, node) {
        pm.Value = node.UniqueID;
        if (this.SelectedPartition) {
            this.SelectedPartition.Mappings[pm.Key] = pm.Value;
            this.OnItemChanged();
        }
    }
}
