import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalEventHelper } from '../../../helpers/globalevent.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { VariableHelper } from '../../../helpers/variable.helper';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'baselayout',
    templateUrl: './base.layout.html',
    styleUrls: ['./base.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseLayout implements OnInit, OnDestroy, AfterViewInit {

    _Layout;
    get Layout() {
        return this._Layout;
    }
    set Layout(value) {
        this._Layout = value;
        this.cdRef.detectChanges();
    }

    _BaseLayout;
    get BaseLayout() {
        return this._BaseLayout;
    }
    set BaseLayout(value) {
        this._BaseLayout = value;
        this.cdRef.detectChanges();
    }
    Subscriptions = {};
    Initialized = false;
    LayoutSet = false;
    EditOpen = false;

    ngAfterViewInit(): void {
        this.Initialized = true;
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        const keys = Object.keys(this.Subscriptions);
        keys.forEach((key) => {
            this.Subscriptions[key].unsubscribe();
        });
    }
    constructor(public cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.Subscriptions['BaseLayout'] = LayoutService.BaseLayout.subscribe(async(result) => {
            if (result) {
                this.BaseLayout = result;
                this.Layout = LayoutHelper.GetActiveResolution(this.BaseLayout);
                await VariableHelper.LoadVariables(this.Layout);
                GlobalEventHelper.InitBaseLayout(this.Layout);
                this.SetPermissionType(this.Layout);
                this.Layout.SetViewType(ViewType.View);
                PermissionHelper.GetContentPermissions('baselayout').then(() => {
                    this.cdRef.detectChanges();
                });
            } else {
                this.Layout = null;
            }
            this.LayoutSet = true;
            this.cdRef.detectChanges();
        });
        this.Subscriptions['Impersonate'] = PermissionHelper.Impersonate.subscribe((roles) => {
            if (this.Layout) {
                PermissionHelper.GetContentPermissions('baselayout').then(() => {
                    this.cdRef.detectChanges();
                });
            }
        });
        this.Subscriptions["LayoutEditDialog"] = LayoutService.LayoutEditDialogOpen.subscribe((open) => {
            this.EditOpen = open;
        });

        this.Subscriptions['RootSize'] = LayoutService.RootSize.subscribe(async (size) => {
            if (!this.EditOpen && size && this.Layout && this.BaseLayout && this.BaseLayout.Resolutions) {
                this.Layout = LayoutHelper.GetActiveResolution(this.BaseLayout);

                await VariableHelper.LoadVariables(this.Layout);
                GlobalEventHelper.InitBaseLayout(this.Layout);
                if (!this.Layout.ElementType) {
                    if (this.Layout.ContentType) {
                        switch (this.Layout.ContentType) {
                            case ContentType.Grid: this.Layout.ElementType = 'grid'; break;
                            case ContentType.Flex: this.Layout.ElementType = 'flex'; break;
                            case ContentType.Raster: this.Layout.ElementType = 'raster'; break;
                            case ContentType.Canvas: this.Layout.ElementType = 'canvas'; break;
                        }
                    } else {
                        this.Layout.ElementType = 'grid';
                    }
                }
                this.SetPermissionType(this.Layout);
                this.Layout.SetViewType(ViewType.View);
                PermissionHelper.GetContentPermissions('baselayout').then(() => {
                    this.cdRef.detectChanges();
                });
            }
        });
    }

    SetPermissionType(layout) {
        layout['PermissionType'] = 'baselayout';
        if (layout.Elements && layout.Elements.length > 0) {
            layout.Elements.forEach((item) => {
                this.SetPermissionType(item);
            });
        }
    }
}
