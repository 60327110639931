import { add, divide, floor } from 'mathjs';
import { FormulaStatics, ValueAndType } from '../basic/formulaEditor.model';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { RangeEnabledFormula } from './iformula';

export abstract class AAvg extends RangeEnabledFormula {
    protected _CountNull = true;
    protected _CountZero = true;

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && (maxType === ValueType.Long || maxType === ValueType.Double)) {
            let count = 0;
            let value = null;
            args.forEach((item) => {
                if (typeof item.Value !== 'undefined' && item.Value !== null) {
                    if (item.Type === ValueType.Range) {
                        let sum = null;
                        this.HandleNumericRange(item, (val) => {
                            if (val === null || isNaN(val)) {
                                if (this._CountNull) {
                                    count++;
                                }
                            } else {
                                if (sum === null) {
                                    sum = val;
                                } else {
                                    sum = add(sum, val);
                                }
                                if (val !== 0 || this._CountZero) {
                                    count++;
                                }
                            }
                        });
                        if (sum !== null) {
                            if (value === null) {
                                value = sum;
                            } else {
                                value = add(value, sum);
                            }
                        }
                    } else {
                        if (value === null) {
                            value = item.Value;
                        } else {
                            value = add(value, item.Value);
                        }
                        if (value !== 0 || this._CountZero) {
                            count++;
                        }
                    }
                } else if (this._CountNull) {
                    count++;
                }
            });
            if (count > 0 && value !== null) {
                retVal.Type = maxType;
                retVal.Value = divide(value, count);
                if (maxType === ValueType.Long) {
                    retVal.Value = floor(retVal.Value);
                }
            }
        }
        return retVal;
    }
}

export class Avg extends AAvg {
    static StaticID = 'a72f1a6e-1b89-47f7-b6a3-a18fd8c676d5';

    constructor() {
        super();
        this._ID = Avg.StaticID;
        this._Name = 'Avg';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
    }
}

export class AvgNonEmpty extends AAvg {
    static StaticID = '675b6398-8182-4530-8146-3985bf1b5e82';

    constructor() {
        super();
        this._ID = AvgNonEmpty.StaticID;
        this._Name = 'AvgNonEmpty';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
        this._CountNull = false;
    }
}

export class AvgNonZero extends AAvg {
    static StaticID = '9c25aa22-27f3-4a6d-a304-ae4b07874340';

    constructor() {
        super();
        this._ID = AvgNonZero.StaticID;
        this._Name = 'AvgNonZero';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
        this._CountNull = false;
        this._CountZero = false;
    }
}
