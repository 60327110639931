import { ChangeDetectorRef, Component } from '@angular/core';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { Comparer } from '../../../models/enums/comparer.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataModelService } from '../../../services/datamodel.service';
import { DynamicTimeSettingsService } from '../../../services/dynamictimesettings.service';
import { LayoutService } from '../../../services/layout.service';
import { RelSourceService } from '../../../services/relSource.service';
import { ConditionalFilterDialog } from './conditional.filter.dialog';

@Component({
    selector: 'filtertree-menutab',
    templateUrl: './filtertree.menu.tab.html'
})
export class FilterTreeMenuTab extends APropertyTab {

    AllDataModels = [];
    Tables = [];
    SelectedTable;
    Dimensions = [];
    Levels = [];
    SelectedDimension;
    SelectedHierarchy;
    Layout;
    DynamicTimePoints = [];

    constructor(private dataModelService: DataModelService, private relService: RelSourceService,
        private dtService: DynamicTimeSettingsService,  cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = LayoutHelper.GetActiveResolution(layout);
        }));
        this.dataModelService.GetModels().subscribe(models => {
            if (models) {
                this.AllDataModels = models;
            }
        });
        const filterCol = new RequestFilter();
        filterCol.Name = 'TimeType';
        filterCol.Operator = Comparer.In;
        filterCol.Value = [0, 2];
        const filter = RequestOptions.CleanRequestOptions();
        filter.Filters.push(filterCol);
        this.dtService.GetDynamicTimePointsFiltered(filter).subscribe(x => {
            if (x) {
                this.DynamicTimePoints = x;
            }
        });
    }

    onItemSet() {
        this.Dimensions = [];
        this.Levels = [];
        this.SelectedDimension = null;
        this.SelectedHierarchy = null;
        this.Tables = [];
        this.SelectedTable = null;
        const selected = this.SelectedItemValue;
        if (selected && selected.DataModel) {
            if (selected.Relational) {
                this.relService.GetRelContainer({ DataModelID: selected.DataModel }).subscribe(x => {
                    if (x) {
                        this.Tables = x;
                        if (selected.Container) {
                            this.SelectedTable = this.Tables.find(tab => tab.SID === selected.Container);
                        }
                    }
                });
            } else {
                MultiCacheService.GetDimensions(selected.DataModel).then(dimensions => {
                    if (dimensions) {
                        this.Dimensions = dimensions;
                        const levels = [];
                        if (selected.Hierarchy) {
                            this.Dimensions.some(dim => {
                                let dimFound = false;
                                dim.Hierarchies.some(hier => {
                                    if (hier.ID === selected.Hierarchy) {
                                        dimFound = true;
                                        this.SelectedDimension = dim;
                                        this.SelectedHierarchy = hier;
                                        this.SelectedHierarchy.Levels.forEach(x => {
                                            levels.push({
                                                Caption: x.Caption,
                                                ID: x.ID,
                                                Selected: selected.Levels && selected.Levels.some(l => x.ID === l)
                                            });
                                        });
                                        return true;
                                    }
                                    return false;
                                });
                                return dimFound;
                            });
                        }
                        this.Levels = levels;
                    }
                });
            }
        }
    }

    dataModelChanged() {
        this.Dimensions = [];
        this.SelectedDimension = null;
        this.SelectedHierarchy = null;
        this.Levels = [];
        this.Tables = [];
        this.SelectedTable = null;
        const changeProps = ['DataModel'];
        if (this.SelectedItemValue) {
            if (this.SelectedItemValue.DataSource != null) {
                this.SelectedItemValue.DataSource = null;
                changeProps.push('DataSource');
            }
            if (this.SelectedItemValue.Container != null) {
                this.SelectedItemValue.Container = null;
                changeProps.push('Container');
            }
            if (this.SelectedItemValue.Field != null) {
                this.SelectedItemValue.Field = null;
                changeProps.push('Field');
            }
            if (this.SelectedItemValue.Hierarchy != null) {
                this.SelectedItemValue.Hierarchy = null;
                changeProps.push('Hierarchy');
            }
            if (!Array.isArray(this.SelectedItemValue.Levels) || this.SelectedItemValue.Levels.length > 0) {
                this.SelectedItemValue.Levels = [];
                changeProps.push('Levels');
            }
            if (this.SelectedItemValue.DataModel) {
                if (this.SelectedItemValue.Relational) {
                    this.relService.GetRelContainer({ DataModelID: this.SelectedItemValue.DataModel }).subscribe(x => {
                        if (x) {
                            this.Tables = x;
                        }
                    });
                } else {
                    MultiCacheService.GetDimensions(this.SelectedItemValue.DataModel).then(dimensions => {
                        if (dimensions) {
                            this.Dimensions = dimensions;
                        }
                    });
                }
            }
        }
        this.onStyleChanged(changeProps);
    }

    dimChanged() {
        this.SelectedHierarchy = null;
        this.Levels = [];
        if (this.SelectedItemValue) {
            const changeProps = [];
            if (this.SelectedItemValue.DataSource != null) {
                this.SelectedItemValue.DataSource = null;
                changeProps.push('DataSource');
            }
            if (this.SelectedItemValue.Hierarchy != null) {
                this.SelectedItemValue.Hierarchy = null;
                changeProps.push('Hierarchy');
            }
            if (!Array.isArray(this.SelectedItemValue.Levels) || this.SelectedItemValue.Levels.length > 0) {
                this.SelectedItemValue.Levels = [];
                changeProps.push('Levels');
            }
            this.onStyleChanged(changeProps);
        }
    }

    hierarchyChanged() {
        const levels = [];
        if (this.SelectedHierarchy) {
            if (this.SelectedItemValue) {
                const changeProps = [];
                if (this.SelectedHierarchy.Parent) {
                    if (this.SelectedItemValue.DataSource != this.SelectedHierarchy.Parent.DatasourceID) {
                        this.SelectedItemValue.DataSource = this.SelectedHierarchy.Parent.DatasourceID;
                        changeProps.push('DataSource');
                    }
                }
                if (this.SelectedItemValue.Hierarchy != this.SelectedHierarchy.ID) {
                    this.SelectedItemValue.Hierarchy = this.SelectedHierarchy.ID;
                    changeProps.push('Hierarchy');
                }
                if (!Array.isArray(this.SelectedItemValue.Levels) || this.SelectedItemValue.Levels.length > 0) {
                    this.SelectedItemValue.Levels = [];
                    changeProps.push('Levels');
                }
                this.onStyleChanged(changeProps);
            }
            this.SelectedHierarchy.Levels.forEach(x => {
                levels.push({
                    Caption: x.Caption,
                    ID: x.ID,
                    Selected: false
                });
            });
        }
        this.Levels = levels;
    }

    levelChanged(lev) {
        if (this.SelectedItemValue && lev) {
            if (lev.Selected) {
                if (this.SelectedItemValue.Levels) {
                    this.SelectedItemValue.Levels.push(lev.ID);
                } else {
                    this.SelectedItemValue.Levels = [lev.ID];
                }
                this.onStyleChanged('Levels');
            } else if (this.SelectedItemValue.Levels) {
                const index = this.SelectedItemValue.Levels.indexOf(lev.ID);
                if (index > -1) {
                    this.SelectedItemValue.Levels.splice(index, 1);
                    this.onStyleChanged('Levels');
                }
            }
        }
    }

    tableChanged() {
        if (this.SelectedItemValue) {
            const changeProps = [];
            if (this.SelectedItemValue.Field != null) {
                this.SelectedItemValue.Field = null;
                changeProps.push('Field');
            }
            if (this.SelectedTable) {
                if (this.SelectedItemValue.Container != this.SelectedTable.SID) {
                    this.SelectedItemValue.Container = this.SelectedTable.SID;
                    changeProps.push('Container');
                }
                if (this.SelectedItemValue.DataSource != this.SelectedTable.DataSourceId) {
                    this.SelectedItemValue.DataSource = this.SelectedTable.DataSourceId;
                    changeProps.push('DataSource');
                }
            } else {
                if (this.SelectedItemValue.Container != null) {
                    this.SelectedItemValue.Container = null;
                    changeProps.push('Container');
                }
                if (this.SelectedItemValue.DataSource != null) {
                    this.SelectedItemValue.DataSource = null;
                    changeProps.push('DataSource');
                }
            }
            this.onStyleChanged(changeProps);
        }
    }

    relationalChanged() {
        this.onItemSet();
        this.onStyleChanged('Relational');
    }

    codeDescChanged() {
        if (this.SelectedItemValue) {
            if (this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.codeDescChanged === 'function') {
                this.SelectedItemValue.Element.codeDescChanged();
            }
            this.onStyleChanged('CodeDesc');
        }
    }

    editFilter() {
        const sel = this.SelectedItemValue;
        if (sel) {
            const variables = FilterHelper.GetVariables(this.Layout);
            ConditionalFilterDialog.ShowDialog(sel.ConditionalFilter, sel.DataModel, this.SelectedDimension.ID, variables, (x) => {
                sel.ConditionalFilter = x;
                this.onStyleChanged('ConditionalFilter');
            });
        }
    }

    clearFilter() {
        if (this.SelectedItemValue && this.SelectedItemValue.ConditionalFilter != null) {
            this.SelectedItemValue.ConditionalFilter = null;
            this.onStyleChanged('ConditionalFilter');
        }
    }
}
