import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { LevelFormattingTaskExecuter } from '../../../../models/tasks/formattasks/level.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ALevelPanel, ALevelTaskSettingsHelper } from '../../a.task.panel';

@Component({
    selector: 'level-formatting-task-panel',
    templateUrl: './level.formatting.task.panel.html'
})
export class LevelFormattingTaskPanel extends ALevelPanel {

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = LevelFormattingTaskExecuter.TaskID;
        reg.Caption = '@@Level';
        reg.SettingsControl = LevelFormattingTaskPanel;
        reg.SettingsHelper = new LevelFormattingTaskSettingsHelper();
        reg.Executer = LevelFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }    
}

export class LevelFormattingTaskSettingsHelper extends ALevelTaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsTableObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Levelformatierung');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Levelformatierung');
    }
}
