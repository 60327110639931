import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SideNavService {
    static Open: Subject<boolean> = new Subject();
    static Close: Subject<boolean> = new Subject();
    static Toggle: Subject<boolean> = new Subject();
    static Reload: Subject<string> = new Subject();
    static SelectedNavigationStructure: BehaviorSubject<string> = new BehaviorSubject(null);
    static InternalURL: BehaviorSubject<string> = new BehaviorSubject(null);
    static SelectedNavigation: BehaviorSubject<any> = new BehaviorSubject(null);
    static SystemURLs = ['settings'];

    static IsSystemURL(url: string) {
        if (url) {
            const toLower = url.toLowerCase();
            return SideNavService.SystemURLs.some(x => '/' + x.toLowerCase() === toLower);
        }
        return true;
    }
}
