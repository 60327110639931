import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { IBaseComponent } from '../../components/controls/base.component';
import { LayoutService } from '../../services/layout.service';
import { WorkflowWizardService } from '../../services/workflow.wizard.service';

@Component({
    selector: 'workflow-wizard-control',
    templateUrl: './workflow.wizard.control.html'
})
export class WorkflowWizardControl extends IBaseComponent {
    static Type: any = 'workflow-wizard-control';
    static Default = {
        Type: 'workflow-wizard-control'
    };

    WizardObject;

    constructor(private service: WorkflowWizardService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }    

    ControlInitialized() {
        this.UpdateWizard();
    }

    UpdateWizard() {
        this.WizardObject = null;
        if (this.LayoutElementValue && this.LayoutElementValue.WizardID) {
            this.service.GetWizardObject(this.LayoutElementValue.WizardID).subscribe(result => {
                if (result) {
                    this.WizardObject = result;
                }
            });
        }
    }
}
