import { UUID } from 'angular2-uuid';
import { FileMode } from '../enums/filemode.enum';
import { GuidRepresentation } from '../enums/guidrepresentation';
import { MongoAuthMechanism } from '../enums/mongoauthmechanism.enum';
import { MongoDBConnectionType } from '../enums/mongodbconnectiontype.enum';
import { MySqlSslMode } from '../enums/mysqlsslmode.enum';

export abstract class ADBInfo {
    Key = UUID.UUID();
    IsActive = true;
    Caption: string;
    Description: string;
    AgentId: string;
    UseAgent = false;
    InteractiveProperties: string[] = [];

    abstract getTypeName(): string;
}

export class LiteDBInfo extends ADBInfo {
    Server: string;
    Port = 0;
    Database: string;
    EncryptionKey: string;
    CacheSize = 5000;
    Mode: FileMode = FileMode.Exclusive;
    Flush = false;

    getTypeName(): string {
        return 'evidanza.Database.LiteDBImpl.LiteDBInfo';
    }
}

export class MongoDBInfo extends ADBInfo {
    ConnectionType: MongoDBConnectionType = 0;
    Server: string = null;
    Port = 0;
    Database: string = null;
    Username: string = null;
    Password: string = null;
    UseSsl: false;
    AuthMechanism: MongoAuthMechanism = 0;
    GuidRepresentation: GuidRepresentation = GuidRepresentation.CSharpLegacy;

    getTypeName(): string {
        return 'evidanza.Database.MongoDBImpl.MongoDBInfo';
    }
}

export class OpenAIDBInfo extends ADBInfo {
    ApiKey: string = null;
    getTypeName(): string {
        return 'evidanza.Integrations.OpenAI.OpenAIDBInfo';
    }
}
export class PGDBInfo extends ADBInfo {
    Server: string = null;
    Port = 0;
    Database: string = null;
    Username: string = null;
    Password: string = null;
    DTExecPath: string = null;
    SSPI = false;
    DeactivateRelReporting = false;
    Timeout = 30;
    Encrypt = false;
    Pooling = true;

    getTypeName(): string {
        return 'evidanza.Database.PostgreSql.PGDBInfo';
    }
}

export class SQLDBInfo extends ADBInfo {
    Server: string;
    Port = 0;
    Database: string;
    Username: string;
    Password: string;
    DTExecPath: string;
    SSPI = true;
    DeactivateRelReporting = false;
    Timeout = 30;
    Encrypt = false;
    IsAzure = false;
    UseAzureAD = false;
    getTypeName(): string {
        return 'evidanza.Database.SQLServer.SQLDBInfo';
    }
}

export class MySQLDBInfo extends ADBInfo {
    Server: string = null;
    Port = 0;
    Database: string = null;
    Username: string = null;
    Password: string = null;
    SSPI = false;
    SslMode: MySqlSslMode = MySqlSslMode.None;

    getTypeName(): string {
        return 'evidanza.Database.MySQLImpl.MySQLDBInfo';
    }
}

export class OdbcDBInfo extends ADBInfo {
    ConnectionString: string;

    getTypeName(): string {
        return 'evidanza.Core.Database.Odbc.OdbcDBInfo';
    }
}

export class CouchBaseDBInfo extends ADBInfo {
    Server: string = null;
    Port = 0;
    Database: string = null;
    Username: string = null;
    Password: string = null;

    getTypeName(): string {
        return 'evidanza.Database.Couchbase.CouchBaseDBInfo';
    }
}

export class MSDBInfo extends ADBInfo {
    MediaSource: string = null;

    getTypeName(): string {
        return 'evidanza.App.Shared.NonDatabase.MSDB.MSDBInfo';
    }
}

export class ConnDBInfo extends ADBInfo {
    ConnectorId: string = null;
    Values: any = {} ;

    getTypeName(): string {
        return 'evidanza.App.Shared.SelfService.Connector.ConnDBInfo';
    }
}


export class NoDatasourceDBInfo extends ADBInfo {
    getTypeName(): string {
        return 'evidanza.App.Shared.SelfService.Connector.NoDatasourceDBInfo';
    }
}

export class DBPanelInfo {
    DBInfoType;
    ControlType;
}

export const TYPE_DICT = new Map<string, DBPanelInfo>([
]);
