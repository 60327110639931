import { AmazonDrivePanel } from "../settings/datamodel/mediasource/mediasourcepanel/amazondrive/amazondrive.panel";
import { AzureBlobPanel } from "../settings/datamodel/mediasource/mediasourcepanel/auzureblob/auzureblob.panel";
import { BoxPanel } from "../settings/datamodel/mediasource/mediasourcepanel/box/box.panel";
import { DrobBoxPanel } from "../settings/datamodel/mediasource/mediasourcepanel/dropbox/dropbox.panel";
import { GoogleDrivePanel } from "../settings/datamodel/mediasource/mediasourcepanel/googledrive/googledrive.panel";
import { MinioPanel } from "../settings/datamodel/mediasource/mediasourcepanel/minio/minio.panel";
import { OneDrivePanel } from "../settings/datamodel/mediasource/mediasourcepanel/onedrive/onedrive.panel";
import { SharePointPanel } from "../settings/datamodel/mediasource/mediasourcepanel/sharepoint/sharepoint.panel";

export const APP_MEDIATYPES = [
    MinioPanel,
    DrobBoxPanel,
    GoogleDrivePanel,
    SharePointPanel,
    OneDrivePanel,
    AmazonDrivePanel,
    BoxPanel,
    AzureBlobPanel
];