import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult } from '../../datadescription/multi/multiresult.model';
import { CodeDesc } from '../../enums/query.enum';
import { ATask, ITaskExecuter } from '../atask.model';

export class CodeDescriptionTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    CodeDescription: CodeDesc = CodeDesc.Description;
    LevelUniqueID;

    Execute() {
    }
}

export class CodeDescriptionTaskExecuter implements ITaskExecuter {

    CodeDescription: CodeDesc = CodeDesc.Description;
    LevelUniqueID = -1;

    Result: MultiResult;
    DataDescription: DataDescription;

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.CodeDescription === 'number') {
                this.CodeDescription = settings.CodeDescription;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
        }
    }

    Execute() {
        if (this.Result && this.DataDescription && this.LevelUniqueID >= -1) {
            let axisNodes = MultiResultHelper.GetNodesFlat(this.Result.XAxis, this.LevelUniqueID);
            if (!axisNodes || axisNodes.length === 0) {
                axisNodes = MultiResultHelper.GetNodesFlat(this.Result.YAxis, this.LevelUniqueID);
            }
            if (axisNodes) {
                axisNodes.forEach(an => {
                    an.CodeDescription = this.CodeDescription;
                });
            }
        }
    }
}
