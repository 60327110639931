import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { WhereProps } from '../../../../../models/rest/mapping.structure';
import { DataModelService } from '../../../../../services/datamodel.service';


@Component({
    selector: 'where-mapping',
    templateUrl: 'where.mapping.html',
    styleUrls:['where.mapping.css']
})
export class WhereMappingForm {

    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Fields
    FieldsValue;
    @Input()
    get Fields() {
        return this.FieldsValue;
    }
    set Fields(val) {
        this.FieldsValue = val;
        this.SetCombinedFields();
        this.FieldsChange.emit(this.FieldsValue);
    }

    @Output() FieldsChange = new EventEmitter<any>();
    //#endregion
    //#region CustomFields
    CustomFieldsValue;
    @Input()
    get CustomFields() {
        return this.CustomFieldsValue;
    }
    set CustomFields(val) {
        this.CustomFieldsValue = val;
        this.SetCombinedFields();
        this.CustomFieldsChange.emit(this.CustomFieldsValue);
    }

    @Output() CustomFieldsChange = new EventEmitter<any>();
    //#endregion
    CombinedFields = [];
    SetCombinedFields() {
        let retVal = [];
        if (this.FieldsValue) {
            retVal.push(...this.FieldsValue);
        }
        if (this.CustomFieldsValue) {
            retVal.push(...this.CustomFieldsValue);
        }
        this.CombinedFields = retVal;
    }
    @Output() ValuesChanged = new EventEmitter<any>();

    Properties;

    constructor(private _translate: TranslateService, private _dataModelService: DataModelService) {
        this.Properties = EnumHelper.GetDropdownValues(WhereProps)
    }
    ItemValuesChanged() {
        this.ValuesChanged.emit();
    }
}