export enum LayoutChangeType {
    PropertyChange,
    PositionChange,
    ElementAdded,
    ElementRemoved,
    MultiPropertyChange,
    WorkflowChange,
    WorkflowAdded,
    WorkflowRemoved,
    ResolutionAdded,
    ResolutionRemoved,
    MultiPositionChange,
}
