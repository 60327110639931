import { UUID } from "angular2-uuid";
import { Type, plainToClass } from "class-transformer";
import { Base, JsonIgnore } from "../base.model";

export class DynamicTymeRangeCubeDimInfo {
  Cube: UUID;
  Dimension: UUID;

  clone(): DynamicTymeRangeCubeDimInfo {
    let retVal;
    retVal = plainToClass(DynamicTymeRangeCubeDimInfo, JSON.parse(JSON.stringify(this)));
    return retVal;
  }
}

// @dynamic
export class DynamicTimeRange extends Base {
  DynamicStartTime: UUID;
    DynamicEndTime: UUID;
    FilterVariable: string;
    DynamicTimeList: UUID[] = [];
    LevelID: UUID;
    HierarchyID: UUID;
  UseDynamicTimeList: boolean = false;
  @Type(() => DynamicTymeRangeCubeDimInfo)
  SelectedTimeDimensions: DynamicTymeRangeCubeDimInfo[] = [];

  @JsonIgnore
  FilterStartMember: UUID;
  @JsonIgnore
  FilterEndMember: UUID;

  get IsValid(): boolean {
    if (this.UseDynamicTimeList) {
      return this.DynamicTimeList.length > 0;
    }
    return this.DynamicStartTime != undefined;
  }

  clone(): DynamicTimeRange {
    let retVal;
    retVal = plainToClass(DynamicTimeRange, JSON.parse(JSON.stringify(this)));
    return retVal;
  }

  public constructor(init?: Partial<DynamicTimeRange>) {
    super();
    Object.assign(this, init);
  }
}
