import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class ChangeLogService extends RequestBase {
    BasePath = 'api/changelog';

    GetChangeLogs(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetChangeLogs', this.options);
    }

    GetChangeLogDetail(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetChangeLogDetail?id=' + id, this.options);
    }

    UndoChangeLog(request): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/UndoChangeLog', request, this.options);
    }

    SaveChangeLog(request): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveChangeLog', request, this.options);
    }
}
