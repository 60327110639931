import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APropertyTab } from '../properties/properties.menu.tab';
import { VariableHelper } from '../../../helpers/variable.helper';
import { UUID } from 'angular2-uuid';
import { WorkflowNameChangeDialog } from '../../../components/dialogs/workflowdebug/workflow.name.change.dialog';
import { LayoutService } from '../../../services/layout.service';
import { MetaHelper } from '../../../helpers/meta.helper';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'variables-menu-tab',
    templateUrl: './variables.menu.tab.html',
    styleUrls: ['./variables.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariablesMenuTab extends APropertyTab {
    static BadgeValue: BehaviorSubject<number> = new BehaviorSubject(0)
    SelectedVariable;
    DataTypes = VariableHelper.DataTypes;
    EditOptions = {
        Edit: false,
        OldName: '',
        NewName: ''
    };
    Layout;
    TemplatePath = null;

    static CheckOnChange(menuItem) {
        const selectedItem = LayoutService.SelectedItem.getValue();
        if (selectedItem && selectedItem.Variables) {
            VariablesMenuTab.BadgeValue.next(selectedItem.Variables.length);
        } else {
            VariablesMenuTab.BadgeValue.next(0);
        }
        const selected = LayoutService.SelectedItems.getValue();
        if (selected && selected.length > 1) {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        } else {
            menuItem.Visible = true;
            menuItem.Expanded = false;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = layout;
        }));
    }

    onItemSet() {
        this.SelectedVariable = null;
        this.TemplatePath = null;
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Variables) {
                this.SelectedItemValue.Variables = [];
            }
        }
        this.cdRef.detectChanges();
    }

    add() {
        if (this.SelectedItemValue) {
            const item = {
                ID: UUID.UUID(),
                Name: 'Var1',
                Value: null,
                Type: null,
                Formula: null
            };
            if (this.SelectedItemValue.Variables && this.SelectedItemValue.Variables.length > 0) {
                let index = this.SelectedItemValue.Variables.length + 1;
                let name = 'Var' + index++;
                while (this.SelectedItemValue.Variables.some(x => x.Name === name)) {
                    name = 'Var' + index++;
                }
                item.Name = name;
                this.SelectedItemValue.Variables.push(item);
            } else {
                this.SelectedItemValue.Variables = [item];
            }
            this.SelectedVariable = item;
            this.edit();
            this.onVariablesEdited();
            LayoutService.RefreshTree.next(true);
        }
    }

    delete() {
        this.checkDelete().then(index => {
            if (index > -1) {
                this.SelectedItemValue.Variables.splice(index, 1);
                if (index < this.SelectedItemValue.Variables.length) {
                    this.SelectedVariable = this.SelectedItemValue.Variables[index];
                } else if (this.SelectedItemValue.Variables.length > 0) {
                    this.SelectedVariable = this.SelectedItemValue.Variables[this.SelectedItemValue.Variables.length - 1];
                } else {
                    this.SelectedVariable = null;
                }
                this.onVariablesEdited();
                LayoutService.RefreshTree.next(true);
            }
        });
    }

    checkDelete(): Promise<number> {
        const retVal = new Promise<number>((resolve, reject) => {
            let index = -1;
            if (this.SelectedItemValue && this.SelectedItemValue.Variables && this.SelectedVariable) {
                index = this.SelectedItemValue.Variables.indexOf(this.SelectedVariable);
                if (index > -1 && this.Layout && this.Layout.Workflows && this.Layout.Workflows.length > 0) {
                    if (this.TemplatePath == null) {
                        let templatePath = '';
                        const parents = MetaHelper.FindParentPath(this.Layout, this.SelectedItemValue);
                        if (parents) {
                            parents.forEach(x => {
                                if (x.ElementType === 'template') {
                                    templatePath += x.Name + '.';
                                }
                            });
                        }
                        this.TemplatePath = templatePath;
                    }
                    const name = this.TemplatePath + this.SelectedItemValue.Name;
                    WorkflowNameChangeDialog.CheckVariableDelete(this.Layout, name, this.SelectedVariable.Name).then(x => {
                        if (x === MessageBoxResult.Yes) {
                            resolve(index);
                        } else {
                            resolve(-1);
                        }
                    });
                    return;
                }
            }
            resolve(index);
        });
        return retVal;
    }

    edit() {
        if (this.SelectedVariable) {
            this.EditOptions.NewName = this.SelectedVariable.Name;
            this.EditOptions.OldName = this.SelectedVariable.Name;
            this.EditOptions.Edit = true;
        }
    }

    accept() {
        if (this.SelectedVariable) {
            if (this.SelectedItemValue && this.Layout && this.Layout.Workflows && this.Layout.Workflows.length > 0) {
                if (this.TemplatePath == null) {
                    let templatePath = '';
                    const parents = MetaHelper.FindParentPath(this.Layout, this.SelectedItemValue);
                    if (parents) {
                        parents.forEach(x => {
                            if (x.ElementType === 'template') {
                                templatePath += x.Name + '.';
                            }
                        });
                    }
                    this.TemplatePath = templatePath;
                }
                const name = this.TemplatePath + this.SelectedItemValue.Name;
                WorkflowNameChangeDialog.CheckWorkflowChangeVariable(this.Layout, name,
                    this.EditOptions.OldName, this.EditOptions.NewName).then(x => {
                        if (x !== MessageBoxResult.Abort) {
                            this.SelectedVariable.Name = this.EditOptions.NewName;
                            this.cancel();
                            this.onVariablesEdited();
                        }
                });
            } else {
                this.SelectedVariable.Name = this.EditOptions.NewName;
                this.cancel();
                this.onVariablesEdited();
            }
        }
    }

    cancel() {
        this.EditOptions = {
            Edit: false,
            OldName: '',
            NewName: ''
        };
    }

    onVariablesEdited() {
        if (this.SelectedItemValue.Variables) {
            VariablesMenuTab.BadgeValue.next(this.SelectedItemValue.Variables.length);
        } else {
            VariablesMenuTab.BadgeValue.next(0);
        }
        LayoutService.OnLayoutPropertyChanged(this.SelectedItemValue.ID, 'Variables', this.SelectedItem.Variables);
    }
}
