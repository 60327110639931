import { Component, Input } from '@angular/core';
import { InjectorHelper } from '../../../../../helpers/injector.helper';
import { DataDescription } from '../../../../../models/datadescription/multi/datadescription.model';
import { MeasureInfo } from '../../../../../models/datadescription/multi/measureinfo.model';
import { MultiReportingService } from '../../../../../services/reporting.service';
import { MultidimensionalTreeLogic } from '../../../../controls/filter/filter.tree.control';

@Component({
    selector: 'elementrefence-tree',
    templateUrl: './elementreference.tree.html'
})
export class ElementeReferenceTree {
    
    Logic;
    RootNodes = [];
    ReferenceSelects = [];

    DataDescriptionValue: DataDescription;
    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
        
    }

    MeasureValue: MeasureInfo;

    @Input()
    get Measure() {
        return this.MeasureValue;
    }
    set Measure(value) {
      
        this.MeasureValue = value;
        if (this.MeasureValue && this.MeasureValue.Details && this.MeasureValue.Details.ReferencingSelects) {
            this.ReferenceSelects = this.MeasureValue.Details.ReferencingSelects;
        }
    }
 

    //constructor(private relService: ReportingService, private service: MultiReportingService) {
       // this.EventList.push('selectionChanged');
   // }

    ControlInitialized() {
        this.InitLogic();
    }

    InitLogic() {
        this.Logic = null;
        this.RootNodes = [];
        this.ReferenceSelects = [];
       
                this.Logic = new MultidimensionalTreeLogic(InjectorHelper.InjectorInstance.get<MultiReportingService>(MultiReportingService));
                this.Logic.initialize(this).then(x => {
                    if (x) {
                        this.RootNodes = x;
                    }
                });
    }

    onLayoutElementChanged() {
        this.InitLogic();
    }

    onSingleSelect(ev) {
        //if (!this.LayoutElementValue.MultiSelect) {
        //    this.FilterSelection.splice(0);
        //    if (ev && ev.length > 0) {
        //        if (this.LayoutElementValue.Relational) {
        //            this.FilterSelection.push(ev[0].Caption);
        //        } else {
        //            const first = ev[0];
        //            this.FilterSelection.push({
        //                LevelId: first.LevelID,
        //                MemberIds: [first.MemberID]
        //            });
        //        }
        //    }
        //    this.fireSelect();
        //}
    }

    onMultiSelect(ev, node) {
        //if (ev) {
        //    if (this.LayoutElementValue.Relational) {
        //        if (ev.checked) {
        //            this.FilterSelection.push(node.Caption);
        //            this.fireSelect();
        //        } else {
        //            const index = this.FilterSelection.indexOf(node.Caption);
        //            if (index > -1) {
        //                this.FilterSelection.splice(index, 1);
        //                this.fireSelect();
        //            }
        //        }
        //    } else {
        //        let levItem = this.FilterSelection.find(x => x.LevelId === node.LevelID);
        //        if (ev.checked) {
        //            if (!levItem) {
        //                levItem = {
        //                    LevelId: node.LevelID,
        //                    MemberIds: []
        //                };
        //                this.FilterSelection.push(levItem);
        //            }
        //            levItem.MemberIds.push(node.MemberID);
        //            this.fireSelect();
        //        } else {
        //            if (levItem && levItem.MemberIds) {
        //                const index = levItem.MemberIds.indexOf(node.MemberID);
        //                if (index > -1) {
        //                    levItem.MemberIds.splice(index, 1);
        //                    this.fireSelect();
        //                }
        //            }
        //        }
        //    }
        //}
    }

    //fireSelect() {
    //    this.triggerEvent('selectionChanged', [...this.FilterSelection]);
    //}

    //codeDescChanged() {
    //    if (this.LayoutElementValue && !this.LayoutElementValue.Relational) {
    //        FilterTreeControl.updateCaption(this.RootNodes, this.LayoutElementValue.CodeDesc);
    //    }
    //}
}

//export class MultidimensionalTreeLogic implements ITreeLogic {

//    Datasource;
//    LevelList;
//    CodeDesc = 0;
//    private _IdKeeper = new IDKeeper();

//    constructor(private service: MultiReportingService) {
//    }

//    initialize(layout): Promise<IBaseTreeNode[]> {
//        const promise = new Promise<IBaseTreeNode[]>((resolve, reject) => {
//            if (layout && layout.DataModel && layout.DataSource && layout.Hierarchy && layout.Levels) {
//                this.Datasource = layout.DataSource;
//                if (typeof layout.CodeDesc === 'number') {
//                    this.CodeDesc = layout.CodeDesc;
//                }
//                MultiCacheService.GetHierarchy(layout.Hierarchy, layout.DataModel).then(hier => {
//                    if (hier) {
//                        const levels = [];
//                        hier.Levels.forEach(l => {
//                            if (layout.Levels.some(ll => ll === l.ID)) {
//                                levels.push(l);
//                            }
//                        });
//                        this.LevelList = levels;
//                        if (levels.length > 0) {
//                            this.loadChildren(new MultiTreeNode(-1)).then(children => {
//                                resolve(children);
//                            });
//                        } else {
//                            resolve([]);
//                        }
//                    } else {
//                        resolve([]);
//                    }
//                }, () => { resolve([]); });
//            } else {
//                resolve([]);
//            }
//        });
//        return promise;
//    }

//    loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]> {
//        const promise = new Promise<IBaseTreeNode[]>((resolve, reject) => {
//            if (node instanceof MultiTreeNode) {
//                if (node.Index > -1 && node.Index < this.LevelList.length) {
//                    const nodeLevel = this.LevelList[node.Index];
//                    this.service.GetMembers(this.Datasource, nodeLevel.ID, node.MemberID).subscribe(members => {
//                        const retVal = [];
//                        if (members) {
//                            const nextIndex = node.Index + 1;
//                            const hasChildren = nextIndex < this.LevelList.length;
//                            members.forEach(m => {
//                                const memberNode = new MultiTreeNode(this._IdKeeper.NextID);
//                                memberNode.MemberKey = m.Key;
//                                memberNode.LevelID = nodeLevel.ID;
//                                memberNode.MemberCaption = m.Caption;
//                                memberNode.updateCaption(this.CodeDesc);
//                                memberNode.MemberID = m.ID;
//                                memberNode.Index = nextIndex;
//                                memberNode.HasChildren = hasChildren;
//                                retVal.push(memberNode);
//                            });
//                        }
//                        resolve(retVal);
//                    });
//                } else {
//                    resolve([]);
//                }
//            } else {
//                resolve([]);
//            }
//        });
//        return promise;
//    }
//}

//export class MultiTreeNode extends ABaseTreeNode {
//    Index = 0;
//    MemberKey = '';
//    MemberCaption = '';
//    LevelID = '00000000-0000-0000-0000-000000000000';
//    MemberID = '00000000-0000-0000-0000-000000000000';

//    updateCaption(codeDesc) {
//        if (codeDesc === 1) { // Code
//            this.Caption = this.MemberKey;
//        } else if (codeDesc === 3) { // Code & Description
//            this.Caption = this.MemberKey + ' ' + this.MemberCaption;
//        } else {
//            this.Caption = this.MemberCaption;
//        }
//    }
//}
