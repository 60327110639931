import { deserialize, serialize } from 'class-transformer';
import { BehaviorSubject, Subject } from 'rxjs';
import { DebugAttachInfo, DebugLogMessage, DebugLogMessageList, DebugSessionMessage, NextStepInfo } from '../models/workflow/debug.log.message';
import { WorkflowDebugSettings } from '../models/workflow/workflow.debug.settings';
import { NavigationService } from '../services/navigation.service';
import { SideNavService } from '../services/sidenav.service';
import { UsersService } from '../services/users.service';
import { WebsocketSubscription } from '../services/websocket.service';
import { WorkflowService } from '../services/workflow.service';
import { ClientHelper } from './client.helper';
import { InjectorHelper } from './injector.helper';
import { NotificationHelper } from './notification.helper';
import { PermissionHelper } from './permissions.helper';

export class WorkflowDebugHelper {
    private static SettingsSubscription: WebsocketSubscription;
    private static LogSubscription: WebsocketSubscription;
    private static DebugSubscription: WebsocketSubscription;
    private static CheckAttachTimeOut;
    public static DebugSettings = new BehaviorSubject<WorkflowDebugSettings>(null);
    public static NextLog = new Subject<DebugLogMessage>();
    public static ClientIDAnswer = new Subject<DebugAttachInfo>();
    public static DebugClient = new BehaviorSubject<string>(null);
    public static OnAttachOrDetach = new Subject();
    public static NextStep = new Subject<NextStepInfo>();
    public static DebugState = new Subject<DebugSessionMessage>();

    public static Init() {
        WorkflowDebugHelper.UpdateSettingsInternal();
        WorkflowDebugHelper.DebugSettings.subscribe(x => {
            if (x && x.ShowServiceLogsOnTest) {
                if (!WorkflowDebugHelper.LogSubscription) {
                    const user = UsersService.ActiveUser.getValue();
                    if (user) {
                        WorkflowDebugHelper.LogSubscription = new WebsocketSubscription('workflowLog_' + user.SID, 'DebugLogMessage', (x) => {
                            const dlml = deserialize(DebugLogMessageList, x);
                            if (dlml && dlml.List && dlml.List.length > 0 && dlml.List[0].ClientID !== ClientHelper.ClientID) {
                                dlml.List.forEach(dlm => {
                                    WorkflowDebugHelper.NextLog.next(dlm);
                                });
                            }
                        });
                    }
                }
            } else if (WorkflowDebugHelper.LogSubscription) {
                WorkflowDebugHelper.LogSubscription.Unsubscribe();
                WorkflowDebugHelper.LogSubscription = null;
            }
        });
        WorkflowDebugHelper.SubscribeToWorkflowDebugChannel();
        NavigationService.OnUnload.subscribe(() => {
            WorkflowDebugHelper.Unsubscribe();
        });
        UsersService.BeforeLogout.subscribe(x => {
            WorkflowDebugHelper.Unsubscribe();
        });
        UsersService.Login.subscribe(x => {
            WorkflowDebugHelper.SubscribeToWorkflowDebugChannel();
            WorkflowDebugHelper.UpdateSettingsInternal();
        });
        const client = localStorage.getItem('DebugClient');
        if (client && client !== ClientHelper.ClientID) {
            WorkflowDebugHelper.SendOnWorkflowDebugChannel({
                Type: 'CheckAttachRequest',
                Content: {
                    SendingClient: ClientHelper.ClientID,
                    CheckClient: client
                }
            });
            this.CheckAttachTimeOut = setTimeout(() => {
                WorkflowDebugHelper.DetachAll();
            }, 5000);
        }
    }

    private static HandleDebugChannelMessage(message: string) {
        if (message) {
            const parsed = JSON.parse(message);
            if (parsed && parsed.Type) {
                const cont = parsed.Content;
                switch (parsed.Type) {
                    case 'UpdateSettings':
                        WorkflowDebugHelper.UpdateSettingsInternal();
                        NotificationHelper.Info('@@SettingsRefresh', '@@Information');
                        break;
                    case 'ClientIDRequest':
                        if (cont && cont.RequestingClient !== ClientHelper.ClientID) {
                            window.setTimeout(() => {
                                WorkflowDebugHelper.SendClientID();
                            }, 50);
                        }
                        break;
                    case 'ClientIDAnswer':
                        if (cont) {
                            WorkflowDebugHelper.ClientIDAnswer.next(cont);
                        }
                        break;
                    case 'AttachRequest':
                        if (WorkflowDebugHelper.CheckAttachTimeOut) {
                            clearTimeout(WorkflowDebugHelper.CheckAttachTimeOut);
                        }
                        WorkflowDebugHelper.OnAttachOrDetach.next(null);
                        if (cont && cont.AttachedClients && cont.DebugClient !== ClientHelper.ClientID) {
                            if (cont.AttachedClients.some(x => x === ClientHelper.ClientID)) {
                                WorkflowDebugHelper.DebugClient.next(cont.DebugClient);
                                WorkflowDebugHelper.SubscribeToDebugSession(cont.DebugClient);
                            }
                        }
                        break;
                    case 'DetachRequest':
                        WorkflowDebugHelper.OnAttachOrDetach.next(null);
                        if (!cont || cont === ClientHelper.ClientID) {
                            WorkflowDebugHelper.DebugClient.next(null);
                            if (WorkflowDebugHelper.DebugSubscription) {
                                WorkflowDebugHelper.DebugSubscription.Unsubscribe();
                                WorkflowDebugHelper.DebugSubscription = null;
                            }
                        }
                        break;
                    case 'CheckAttachRequest':
                        if (cont && cont.CheckClient === ClientHelper.ClientID) {
                            window.setTimeout(() => {
                                WorkflowDebugHelper.SendOnWorkflowDebugChannel({
                                    Type: 'CheckAttachAnswer',
                                    Content: cont.SendingClient                                    
                                });
                            }, 50);
                        }
                        break;
                    case 'CheckAttachAnswer':
                        if (cont === ClientHelper.ClientID) {
                            if (WorkflowDebugHelper.CheckAttachTimeOut) {
                                clearTimeout(WorkflowDebugHelper.CheckAttachTimeOut);
                            }
                        }
                        break;                    
                }
            }
        }
    }

    private static UpdateSettingsInternal() {
        if (PermissionHelper.GetDebuggerPermission()) {
            const settings = localStorage.getItem('WorkflowDebugSettings');
            WorkflowDebugHelper.DebugSettings.next(deserialize(WorkflowDebugSettings, settings ?? '{}'));
        } else {
            WorkflowDebugHelper.DebugSettings.next(new WorkflowDebugSettings());
        }
    }

    private static Unsubscribe() {
        if (WorkflowDebugHelper.SettingsSubscription) {
            WorkflowDebugHelper.SettingsSubscription.Unsubscribe();
            WorkflowDebugHelper.SettingsSubscription = null;
        }
        if (WorkflowDebugHelper.LogSubscription) {
            WorkflowDebugHelper.LogSubscription.Unsubscribe();
            WorkflowDebugHelper.LogSubscription = null;
        }
        if (WorkflowDebugHelper.DebugSubscription) {
            WorkflowDebugHelper.DebugSubscription.Unsubscribe();
            WorkflowDebugHelper.DebugSubscription = null;
        }
        const dc = localStorage.getItem('DebugClient');
        if (dc && dc === ClientHelper.ClientID) {
            WorkflowDebugHelper.DetachAll();
        }
    }

    public static UpdateSettings(settings: WorkflowDebugSettings) {
        if (!settings) {
            settings = new WorkflowDebugSettings();
        }
        localStorage.setItem('WorkflowDebugSettings', serialize(settings));
        WorkflowDebugHelper.SendOnWorkflowDebugChannel({
            Type: 'UpdateSettings'
        });
    }

    public static RequestClientIDs() {
        WorkflowDebugHelper.SendOnWorkflowDebugChannel({
            Type: 'ClientIDRequest',
            Content: {
                RequestingClient: ClientHelper.ClientID
            }
        });
    }

    private static SendClientID() {
        const dai = new DebugAttachInfo();
        dai.ClientID = ClientHelper.ClientID;
        dai.URL = SideNavService.InternalURL.getValue();
        WorkflowDebugHelper.SendOnWorkflowDebugChannel({
            Type: 'ClientIDAnswer',
            Content: dai
        });
    }

    public static AttachToClients(attachClients) {
        const debugClient = localStorage.getItem('DebugClient');
        let send = false;
        if (!debugClient) {
            localStorage.setItem('DebugClient', ClientHelper.ClientID);
            if (attachClients.some(x => x === ClientHelper.ClientID)) {
                WorkflowDebugHelper.DebugClient.next(ClientHelper.ClientID);
            }
            send = true;
            WorkflowDebugHelper.SubscribeToDebugSession(ClientHelper.ClientID);
        } else {
            send = debugClient === ClientHelper.ClientID;
        }
        if (send) {
            WorkflowDebugHelper.SendOnWorkflowDebugChannel({
                Type: 'AttachRequest',
                Content: {
                    AttachedClients: attachClients,
                    DebugClient: ClientHelper.ClientID
                }
            });
        }
    }

    public static DetachAll() {
        localStorage.removeItem('DebugClient');
        WorkflowDebugHelper.DebugClient.next(null);
        if (WorkflowDebugHelper.DebugSubscription) {
            WorkflowDebugHelper.DebugSubscription.Unsubscribe();
            WorkflowDebugHelper.DebugSubscription = null;
        }
        WorkflowDebugHelper.SendOnWorkflowDebugChannel({
            Type: 'DetachRequest'
        });
    }

    public static DetachClient(clientID: string) {
        if (clientID === ClientHelper.ClientID) {
            WorkflowDebugHelper.DebugClient.next(null);
        } else {
            WorkflowDebugHelper.SendOnWorkflowDebugChannel({
                Type: 'DetachRequest',
                Content: clientID
            });
        }
    }

    public static SendDebugMessage(message: DebugSessionMessage) {
        if (message) {
            if (message.Sender === message.Target) {
                WorkflowDebugHelper.HandleDebugSessionMessage(message);
            } else {
                const client = localStorage.getItem('DebugClient');
                if (client) {
                    const wfService = InjectorHelper.InjectorInstance.get<WorkflowService>(WorkflowService);
                    wfService.SendDebugMessage('debugSession_' + client, message).subscribe();
                }
            }
        }
    }

    private static HandleDebugSessionMessage(message: DebugSessionMessage) {
        switch (message.Type) {
            case 'WorkflowState':
            case 'WorkflowFinished':
                WorkflowDebugHelper.DebugState.next(message);
                break;
            case 'NextStep':
                window.setTimeout(() => {
                    WorkflowDebugHelper.NextStep.next(message.Content);
                }, 100);
                break;
        }
    }

    private static SubscribeToWorkflowDebugChannel() {
        if (WorkflowDebugHelper.SettingsSubscription) {
            WorkflowDebugHelper.SettingsSubscription.Unsubscribe();
            WorkflowDebugHelper.SettingsSubscription = null;
        }
        const user = UsersService.ActiveUser.getValue();
        if (user) {
            WorkflowDebugHelper.SettingsSubscription = new WebsocketSubscription('workflowDebugChannel_' + user.SID, 'WorkflowDebugMessage', (x) => {
                WorkflowDebugHelper.HandleDebugChannelMessage(x);
            });
        }
    }

    private static SendOnWorkflowDebugChannel(message) {
        const user = UsersService.ActiveUser.getValue();
        if (user) {
            WebsocketSubscription.SendNotification('workflowDebugChannel_' + user.SID, 'WorkflowDebugMessage', message);
        }
    }

    private static SubscribeToDebugSession(clientID) {
        if (WorkflowDebugHelper.DebugSubscription) {
            WorkflowDebugHelper.DebugSubscription.Unsubscribe();
            WorkflowDebugHelper.DebugSubscription = null;
        }
        WorkflowDebugHelper.DebugSubscription = new WebsocketSubscription('debugSession_' + clientID, 'evidanza.App.Shared.Workflow.Data.DebugSessionMessage', (m) => {
            const message = deserialize(DebugSessionMessage, m);
            if (message && message.Target === ClientHelper.ClientID) {
                WorkflowDebugHelper.HandleDebugSessionMessage(message);
            }
        });
    }
}
