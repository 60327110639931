import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'button-theme-control',
    templateUrl: './button.theme.control.html',
    styleUrls: ['./buttom.theme.control.css'],
    // styles: [`::ng-deep .mat-button,
    // .mat-raised-button,
    // .mat-icon-button,
    // .mat-stroked-button,
    // .mat-flat-button,
    // .mat-fab,
    // .mat-mini-fab {
    //     background: var(--appbuilder-primary-color) !important;
    //     color: var(--appbuilder-hover-color) !important;
    // }`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonThemeControl extends BaseThemeControl {

    //#region Caption
    CaptionValue;
    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region CaptionPosition
    CaptionPositionValue
    @Input()
    get CaptionPosition() {
        return this.CaptionPositionValue;
    }
    set CaptionPosition(val) {
        this.CaptionPositionValue = val;
        this.CaptionPositionChange.emit(this.CaptionPositionValue);
        this.cdRef.detectChanges();
    }

    @Output() CaptionPositionChange = new EventEmitter<any>();
    //#endregion

    //#region Tooltip
    TooltipValue
    @Input()
    get Tooltip() {
        return this.TooltipValue;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.TooltipChange.emit(this.TooltipValue);
        this.cdRef.detectChanges();
    }

    @Output() TooltipChange = new EventEmitter<any>();
    //#endregion

    //#region Icon
    IconValue
    @Input()
    get Icon() {
        return this.IconValue;
    }
    set Icon(val) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
        this.cdRef.detectChanges();
    }

    @Output() IconChange = new EventEmitter<any>();
    //#endregion

    @Output() Clicked = new EventEmitter<any>();
    onClick(ev) {
        this.EmitAction(ev);
        this.Clicked.emit(ev);
    }
}
