import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { HeterogenArea, Tuple } from '../../../models/datadescription/multi/datadescription.model';
import { LevelNode } from '../../../models/datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../../models/datadescription/multi/measureinfo.model';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { AxisType } from '../../../models/enums/query.enum';
import { SpecialElementType } from '../../../models/enums/specialElementType';
import { TranslatedString } from '../../../models/translatedstring.model';
import { TranslatedStringDialogComponent } from '../../dialogs/translatedstring.dialog';
import { DataDescriptionHelper } from '../../query/ddhelper.query';
import { CalculateTaskPanel } from '../../taskpanels/calculate/calculate.task.panel';
import { HeterogenAreaItemSelectedInfo, ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';
import { LevelOCItem } from '../objectcreator.droparea';

@Component({
    selector: 'heterogenSubArea',
    templateUrl: './heterogenSubArea.control.html',
    styleUrls: ['./heterogenSubArea.control.css']
})
export class HeterogenSubAreaControl implements OnInit, OnDestroy {

    Subscriptions = [];

    @Input()
    ObjectcreatorDataManager: ObjectcreatorDataManager;

    @Input()
    IsMeasureArea = false;

    @Input()
    AreaUniqueID: number;

    @Input()
    AxisType: AxisType = AxisType.None;

    @Input()
    OcDragItemType: OcDragItemType;

    @Output() DropAreaDroping = new EventEmitter<any>();

    haSubAreaItemsValue = [];

    @Input()
    get haSubAreaItems() {
        return this.haSubAreaItemsValue;
    }
    set haSubAreaItems(value) {
        this.objectCreatorItems = [];
        this.haSubAreaItemsValue = [];
        this.haSubAreaItemsValue = value;

        if (this.ObjectcreatorDataManager) {
            this.GetOCItems();
        }
    }

    GetOCItems() {
        this.objectCreatorItems = [];
        if (Array.isArray(this.haSubAreaItemsValue)) {
            if (this.OcDragItemType === OcDragItemType.Level) {
                const tuples = this.haSubAreaItemsValue as Tuple[];
                tuples.forEach(tuple => {
                    if (tuple.Levels) {
                        const tupleLevellength = tuple.Levels.length;
                        tuple.Levels.forEach((level, k) => {
                            const item: LevelOCItem = new LevelOCItem();
                            item.Level = level.Level;
                            item.UniqueID = level.UniqueID;
                            item.Caption = level.Caption.toString();
                            item.IsStartTupelLevel = tupleLevellength > 1 && k === 0;
                            item.IsEndTupelLevel = tupleLevellength > 1 && k === tupleLevellength - 1;
                            item.IsMultiTupelLevel = tupleLevellength > 1;
                            item.IsHidden = level.Hidden;
                            this.ObjectcreatorDataManager.Gettooltip(level).then(tt => {
                                item.Tooltip = tt;
                            });
                            this.objectCreatorItems.push(item);
                        });
                    }
                });
            } else if (this.OcDragItemType === OcDragItemType.Measure) {

                const measures = this.haSubAreaItemsValue as MeasureInfo[];
                measures.forEach(m => {
                    const item: LevelOCItem = new LevelOCItem();
                    item.UniqueID = m.UniqueID;
                    item.Caption = m.Caption.toString();
                    item.IsHidden = m.VisibilityType == VisibilityType.NonVisibleDefault || m.VisibilityType == VisibilityType.NonVisibleForce;
                    this.ObjectcreatorDataManager.Gettooltip(m).then(tt => {
                        item.Tooltip = tt;

                    });
                    this.objectCreatorItems.push(item);
                });
            }

        }
    }

    objectCreatorItemsValue = [];

    @Input()
    get objectCreatorItems() {
        return this.objectCreatorItemsValue;
    }
    set objectCreatorItems(value) {
        this.objectCreatorItemsValue = value;
    }

    ngOnInit(): void {
        this.GetOCItems();
        this.Subscriptions.push(ObjectcreatorDataManager.AreaChanged.subscribe(haInfo => {
            const areaId = haInfo.HeterogenAreaID;
            if (this.AreaUniqueID === areaId) {
                const area: HeterogenArea = DataDescriptionHelper.FindAreaByID(this.ObjectcreatorDataManager.DataDescription, areaId);
                if (haInfo.DropAreaType === OcDragItemType.Level && this.OcDragItemType === OcDragItemType.Level) {
                    this.haSubAreaItems = area.Tuples;
                    //this.GetOCItems();
                } else if (haInfo.DropAreaType === OcDragItemType.Measure && this.OcDragItemType === OcDragItemType.Measure) {
                    this.haSubAreaItems = area.Measures;
                   // this.GetOCItems();
                }
               
            }
        }));
    }


    

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

    async onDropAreaDropingElements(event) {
        // verschiebung innerhalb
        if (event) {
            if (typeof event.previousIndex === 'number' && typeof event.currentIndex === 'number') {
                if (event.item ) { // zwischen den droplists (Achse, Areas...)
                    if (this.OcDragItemType === event.dropAreaType && (event.dropAreaType === event.item.OcDragItemType)) {
                        const selInfo = new HeterogenAreaItemSelectedInfo();
                        selInfo.NewDestinationHeterogenAreaIDValue = this.AreaUniqueID;
                        selInfo.HeterogenAreaID = event.item.AreaUniqueID;
                        selInfo.DropAreaType = this.OcDragItemType;
                        selInfo.AxisType = event.item.AxisType;
                        selInfo.NewDestinationAxisType = this.AxisType;

                        this.ObjectcreatorDataManager.MoveDBHeterogenAreaElements(selInfo, event.previousIndex, event.currentIndex);
                        this.haSubAreaItems = this.haSubAreaItems;
                    }
                } else {  // innerhalb einer Droplist
                    if (this.haSubAreaItems.length > 1) {
                        const selInfo = new HeterogenAreaItemSelectedInfo();
                        selInfo.HeterogenAreaID = this.AreaUniqueID;
                        selInfo.DropAreaType = this.OcDragItemType;
                        this.ObjectcreatorDataManager.MoveDBHeterogenAreaElements(selInfo, event.previousIndex, event.currentIndex);
                    }
                }
            }
            else {
                const index = event.Index;
                const toDropItemIds = event.Items;

                if (toDropItemIds && toDropItemIds.length > 0) {
                    for (let i = 0; i < toDropItemIds.length; i++) {
                        const dropID = toDropItemIds[i];

                        if (this.OcDragItemType === OcDragItemType.Level) {
                            const levelDB = await MultiCacheService.GetLevel(dropID, this.ObjectcreatorDataManager.DataDescription.DataModelID);
                            if (levelDB) {
                                const levelnode = new LevelNode();
                                levelnode.Axis = AxisType.X_Axis;
                                levelnode.Level = dropID;
                                levelnode.HierarchieID = levelDB.Parent.ID;
                                levelnode.DimensionID = levelDB.Parent.Parent.ID;
                                levelnode.UniqueID = this.ObjectcreatorDataManager.GetNextUniqueID();
                                levelnode.Caption = new TranslatedString(levelDB.Caption);
                                const tuple = new Tuple();
                                tuple.Levels.push(levelnode);
                                this.haSubAreaItems.splice(index, 0, tuple);
                            }
                        } else if (this.OcDragItemType === OcDragItemType.Measure) {
                            const mDB = await MultiCacheService.GetMeasure(dropID, this.ObjectcreatorDataManager.DataDescription.DataModelID);
                            if (mDB) {
                                const measureInfo = new MeasureInfo();
                                measureInfo.UniqueID = this.ObjectcreatorDataManager.GetNextUniqueID();
                                measureInfo.Measure = mDB.ID;
                                measureInfo.Caption = new TranslatedString(mDB.Caption);
                                measureInfo.Cube = mDB.Cube.ID;
                                this.haSubAreaItems.splice(index, 0, measureInfo);
                                ObjectcreatorDataManager.MeasureChanged.next(null);
                            }
                        }
                    }
                    this.haSubAreaItems = this.haSubAreaItems;
                    this.DropAreaDroping.next(event);
                } else if (!toDropItemIds && event.SpecialElement) {
                    const itemInfo = this.OcDragItemType === OcDragItemType.Measure ? new MeasureInfo() : new LevelNode();
                    itemInfo.UniqueID = this.ObjectcreatorDataManager.GetNextUniqueID();
                    if (event.SpecialElement === SpecialElementType.CalculatedElement && this.OcDragItemType === OcDragItemType.Measure) {
                        itemInfo.Caption = new TranslatedString('Calculated');
                        const task = CalculateTaskPanel.GetNewTask(itemInfo.UniqueID);
                        this.ObjectcreatorDataManager.AddTask(task);
                    } else {
                        if (event.SpecialElement === SpecialElementType.EmptyElement && this.OcDragItemType === OcDragItemType.Measure) {
                            itemInfo.Caption = new TranslatedString('Empty');
                        } else if (event.SpecialElement === SpecialElementType.Variable) {
                            itemInfo.Caption = new TranslatedString('Variable');
                        }
                    }
                    await TranslatedStringDialogComponent.showDialog(itemInfo.Caption, () => {
                        itemInfo.SpecialElementType = event.SpecialElement;
                        if (this.OcDragItemType === OcDragItemType.Level) {
                            const tuple = new Tuple();
                            tuple.Levels.push(itemInfo as LevelNode);
                            this.haSubAreaItems.splice(index, 0, tuple);
                            this.haSubAreaItems = this.haSubAreaItems;
                            ObjectcreatorDataManager.LevelVariablesChanged.next(null);
                        } else { // Measure
                            this.haSubAreaItems.splice(index, 0, itemInfo);
                            this.haSubAreaItems = this.haSubAreaItems;
                            ObjectcreatorDataManager.MeasureVariablesChanged.next(null);
                        }
                        this.DropAreaDroping.next(event);
                    });
                }
            }
        }
    }
}
