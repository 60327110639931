import { OnInit, Input, Output, EventEmitter, Component, Directive, ViewChild, AfterViewInit } from "@angular/core";
import { CronHelper, CronObject } from "../../../helpers/cron.helper";
import { ValidationErrors, AbstractControl, Validator, NG_VALIDATORS } from "@angular/forms";

@Component({
    selector: 'cron-builder',
    templateUrl: './cron.builder.html',
    styleUrls: ['./cron.builder.css']
})
export class CronBuilder implements OnInit, AfterViewInit {
    CronObject: CronObject;

    //#region CronString
    CronStringValue;

    @Input()
    get CronString() {
        return this.CronStringValue;
    }
    set CronString(val) {
        this.CronStringValue = val;
        this.generateCronObject();
        this.CronStringChange.emit(this.CronStringValue);
    }

    @Output() CronStringChange = new EventEmitter<any>();
    //#endregion

    @ViewChild('seconds') seconds;
    @ViewChild('minutes') minutes;
    @ViewChild('hours') hours;
    @ViewChild('dom') dom;
    @ViewChild('month') month;
    @ViewChild('dow') dow;
    @ViewChild('year') year;

    ngOnInit(): void {
        this.generateCronObject();
    }

    ngAfterViewInit(): void {
        [this.seconds, this.minutes, this.hours, this.dom, this.month, this.dow, this.year].forEach(function (c) {
            if (c && c.control) {
                c.control.markAsTouched();
            }
        });
    }

    generateCronObject(): any {
        this.CronObject = CronHelper.GetCronObject(this.CronStringValue);
    }

    onSecondsChanged() {
        if (this.CronObject) {
            this.CronObject.SecondsCorrect = CronHelper.IsValidMinOrSecValue(this.CronObject.Seconds);
            this.OnCronObjectChanged();
        }
    }

    onMinutesChanged() {
        if (this.CronObject) {
            this.CronObject.MinutesCorrect = CronHelper.IsValidMinOrSecValue(this.CronObject.Minutes);
            this.OnCronObjectChanged();
        }
    }

    onHoursChanged() {
        if (this.CronObject) {
            this.CronObject.HoursCorrect = CronHelper.IsValidHourValue(this.CronObject.Hours);
            this.OnCronObjectChanged();
        }
    }

    onDayOfMonthChanged() {
        if (this.CronObject) {
            this.CronObject.DayOfMonthCorrect = CronHelper.IsValidDOMValue(this.CronObject.DayOfMonth);
            this.OnCronObjectChanged();
        }
    }

    onMonthChanged() {
        if (this.CronObject) {
            this.CronObject.MonthCorrect = CronHelper.IsValidMonthValue(this.CronObject.Month);
            this.OnCronObjectChanged();
        }
    }

    onDayOfWeekChanged() {
        if (this.CronObject) {
            this.CronObject.DayOfWeekCorrect = CronHelper.IsValidDOWValue(this.CronObject.DayOfWeek);
            this.OnCronObjectChanged();
        }
    }

    onYearChanged() {
        if (this.CronObject) {
            this.CronObject.YearCorrect = CronHelper.IsValidYearValue(this.CronObject.Year);
            this.OnCronObjectChanged();
        }
    }

    @Output() CronObjectChanged = new EventEmitter<CronObject>();
    OnCronObjectChanged() {
        this.CronObjectChanged.emit(this.CronObject);
    }
}

@Directive({
    selector: '[cronSeconds]',
    providers: [{ provide: NG_VALIDATORS, useClass: SecondsValidatorDirective, multi: true }]
})
export class SecondsValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidMinOrSecValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}

@Directive({
    selector: '[cronHours]',
    providers: [{ provide: NG_VALIDATORS, useClass: HoursValidatorDirective, multi: true }]
})
export class HoursValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidHourValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}

@Directive({
    selector: '[cronDOM]',
    providers: [{ provide: NG_VALIDATORS, useClass: DOMValidatorDirective, multi: true }]
})
export class DOMValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidDOMValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}

@Directive({
    selector: '[cronMonth]',
    providers: [{ provide: NG_VALIDATORS, useClass: MonthValidatorDirective, multi: true }]
})
export class MonthValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidMonthValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}

@Directive({
    selector: '[cronDOW]',
    providers: [{ provide: NG_VALIDATORS, useClass: DOWValidatorDirective, multi: true }]
})
export class DOWValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidDOWValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}

@Directive({
    selector: '[cronYear]',
    providers: [{ provide: NG_VALIDATORS, useClass: YearValidatorDirective, multi: true }]
})
export class YearValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        if (control.value && !CronHelper.IsValidYearValue(control.value)) {
            return { value: control.value };
        }
        return null;
    }
}