import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { MappingStructure, RestMapping } from '../../../models/rest/mapping.structure';
import { DataModelService } from '../../../services/datamodel.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ContainerRestDetail } from './containerrest.detail';

@Component({
    selector: 'container-rest-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerRestSettings extends BaseListSettings {

    private readonly _typeName = 'evidanza.MiddleWare.Shared.Container.ContainerRest';

    ShowDelete = false;

    constructor(private _translate: TranslateService, private _dataModelService: DataModelService, cfr: ComponentFactoryResolver, cdf: ChangeDetectorRef) {
        super(cfr, cdf);
    }

    loadList(handler: any) {
        if (this.InitArgs) {
            this._dataModelService.GetContainersOfType(this.InitArgs.SID, this._typeName).subscribe(result => {
                const list = [];
                result.forEach(item => {
                    list.push({
                        Caption: item.Caption,
                        ID: item.SID,
                        IsCapsule: item.IsCapsule,
                        IsOverridden: item.IsOverridden
                    });
                });
                handler(list);
            });
        }
    }

    loadData(data: any) {
        if (data) {
            this._dataModelService.GetContainer(data).subscribe((res) => {
                if (res) {
                    if (!res.Mapping) {
                        res.Mapping = new MappingStructure();
                    } else {
                        if (!res.Mapping.Create) {
                            res.Mapping.Create = new RestMapping();
                        }
                        if (!res.Mapping.Read) {
                            res.Mapping.Read = [];
                        }
                        if (!res.Mapping.Update) {
                            res.Mapping.Update = [];
                        }
                        if (!res.Mapping.Replace) {
                            res.Mapping.Replace = new RestMapping();
                        }
                        if (!res.Mapping.Delete) {
                            res.Mapping.Delete = [];
                        }
                        if (!res.Mapping.Count) {
                            res.Mapping.Count = [];
                        }
                    }
                    this.setSelectedItem(res);
                }
            });
        }
    }

    getNewItem() {
        const name = this._translate.instant('@@Neuer Container');
        const item = {
            DataModelID: this.InitArgs.SID,
            Name: name,
            Mapping: new MappingStructure(),
            DataSourceId: null,
            Resource: null,
            Fields: [],
            Options: [],
            StructureOptions: []
        };
        return item;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Restschnitstelle \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Restschnitstelle \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restschnitstelle loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._dataModelService.DeleteContainer(data).subscribe(() => {
            handler();
        });
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Restcontainer \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restcontainer speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        if (item) {
            const data = Object.assign({}, item);
            this._dataModelService.SaveContainer(this._typeName, data).subscribe((result) => {
                if (result) {
                    handler(result, result.SID, result.Caption);
                }
            });
        }
    }

    getContentType() {
        return ContainerRestDetail;
    }

    protected handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@ContainerRest',
    //        ID: 'containerrest',
    //        Icon: 'table_chart',
    //        Index: 40,
    //        Parent: 'objectcontainer',
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
    //            Value: 32
    //        },
    //        Content: ContainerRestSettings
    //    }
    //}
}