import { Component, OnInit, Renderer2 } from '@angular/core';
import config from '../../../assets/config.json'
@Component({
	selector: 'lib-help-center',
	templateUrl: './help-center.component.html',
	styleUrls: ['./help-center.component.css'],
})
export class HelpCenterComponent implements OnInit {
	constructor(private renderer: Renderer2) {}

	isHaveChatAccess = true;
	ngOnInit(): void {
		const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
		this.isHaveChatAccess = userRole && userRole.length > 0 && userRole[0] !== config.FREE_USER_ID 
	}
}
