import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { GitHelper } from '../../../../helpers/git.helper';
import { LayoutService } from '../../../../services/layout.service';
import { IBaseComponent } from '../../../controls/base.component';

@Component({
    selector: 'evi-branch-select',
    templateUrl: 'branchselect.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BranchSelectControl extends IBaseComponent {
    

    static Type: any = 'branchselect';
    static Default = {
        Editable: true,
        Type: 'branchselect'
    };

    GitActive = false;
    Branches = [];
    SelectedBranch;

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ngOnInit() {
        super.onInit();
        this.GitActive = GitHelper.IsActive;
        if (this.GitActive) {
            this.SelectedBranch = GitHelper.SelectedBranch.getValue();
            this.Branches = GitHelper.Branches;
            this.cdRef.detectChanges();
            this.Subscriptions['BranchesChanged'] = GitHelper.BranchesChanged.subscribe((branches) => {
                this.Branches = GitHelper.LocalBranches;
                this.cdRef.detectChanges();
            });
            
        }
    }

    ControlInitialized() {
        
    }

    SelectedBranchChanged() {
        GitHelper.SelectedBranch.next(this.SelectedBranch);
        window.location.reload();
    }
}
