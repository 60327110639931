import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'cassandra-panel',
    templateUrl: './cassandra.panel.html',
    styleUrls: ['./cassandra.panel.css']
})
export class CassandraPanel extends BaseDataSourcePanel {
    static GetInfo(): DBPanelInfo {
        return {
            ControlType: CassandraPanel,
            DBInfoType: CassandraDBInfo
        };
    }

    get Hosts() {
        return this.DBInfo.Hosts.join(', ');
    }
    set Hosts(val) {
        this.DBInfo.Hosts = val.split(', ');
    }

    constructor() {
        super();
        this.DBInfoType = CassandraDBInfo;
    }
}

export class CassandraDBInfo extends ADBInfo {
    Hosts = [];
    Database = null;
    Username = null;
    Port = 9042;
    Password = null;


    getTypeName(): string {
        return 'evidanza.Database.CassandraImpl.CassandraDBInfo';
    }
}
