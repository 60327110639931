import { Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
// @dynamic
export class InjectorHelper {
    static InjectorInstance: Injector;
}
// @dynamic
export class DialogHelper {
    static DialogInstance: MatDialog;
}
// @dynamic
export class TranslateHelper {
    static TranslatorInstance: TranslateService;
}
// @dynamic
export class RoutingHelper {
    static Router: Router;
}