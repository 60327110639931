import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { FormulaNodeCalculator } from '../../../../models/calculation/formula.node.calculator';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';
import { ReadVariableSettings } from './readVariable.settings';

@Component({
    selector: 'wf-readMultiVariable-settings',
    templateUrl: './read.multi.variable.settings.html'
})
export class ReadMultiVariableSettings extends WorkflowDialogContent {
    Data = new ReadMultiVariableSettingsData();
    NameMap = new Map();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ReadVariableSettings.MultiModuleID;
        reg.Caption = '@@Variablen auslesen';
        reg.SettingsControl = ReadMultiVariableSettings;
        reg.SettingsTypeHelper = new ReadMultiVariableSettingsDataHelper();
        reg.Executer = ReadMultiVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReadMultiVariableSettingsData, json);
            this.Data.Variables.forEach(x => {
                this.updateVariables(x);
            });
        }
    }

    getResult(): any {
        const variables = [];
        this.Data.Variables.forEach(x => {
            if (x.ElemName && x.Variable && (x.UseForState || x.StatusKey)) {
                delete x['ClientVariables'];
                variables.push(x);
            }
        });
        this.Data.Variables = variables;
        return this.Data;
    }

    add() {
        if (this.Data) {
            if (this.Data.Variables) {
                this.Data.Variables.push(new MultiVariableEntry());
            } else {
                this.Data.Variables = [new MultiVariableEntry()];
            }
        }
    }

    delete(i) {
        if (this.Data && this.Data.Variables) {
            this.Data.Variables.splice(i, 1);
        }
    }

    updateVariables(elem) {
        if (elem) {
            const variableList = [];
            if (elem.ElemName) {
                let mapEntry = this.NameMap.get(elem.ElemName);
                if (!mapEntry) {
                    mapEntry = [];
                    const layoutVars = FilterHelper.GetVariableList(elem.ElemName, this.WFEditOptions.Layout);
                    layoutVars.Variables.forEach(x => {
                        const varObj = {
                            Caption: x.Name,
                            Name: x.Name
                        };
                        const dt = VariableHelper.GetTypeCaption(x.Type);
                        if (dt) {
                            varObj.Caption = x.Name + ' (' + TranslateHelper.TranslatorInstance.instant(dt) + ')';
                        }
                        mapEntry.push(varObj);
                    });
                    this.NameMap.set(elem.ElemName, mapEntry);
                }
                variableList.push(...mapEntry);
            }
            elem.ClientVariables = variableList;
        }
    }

    elemNameChanged(elem) {
        if (elem) {
            elem.Variable = null;
            this.updateVariables(elem);
        }
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Variables) {
            this.Data.Variables.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }
}

// @dynamic
export class ReadMultiVariableSettingsData {
    @Type(() => MultiVariableEntry)
    Variables: MultiVariableEntry[] = [];
}

export class MultiVariableEntry {
    ElemName: string;
    Variable: string;
    StatusKey: string;
    UseForState = false;
}

export class ReadMultiVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadMultiVariableSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Variables) {
                const list = [];
                settings.Variables.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Variables) {
            settings.Variables.forEach(variable => {
                if (variable.ElemName === oldName) {
                    variable.ElemName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class ReadMultiVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variables) {
                status.ActualSettings.Variables.forEach(v => {
                    const mapEntry = status.WorkflowLayoutService.getVariablesByName(v.ElemName);
                    if (mapEntry.ElemFound) {
                        if (mapEntry.Variables.length === 0) {
                            status.Logger.logWarning('ReadVariable module: Element ' + v.ElemName + ' has no variables defined.');
                            return;
                        }
                    } else {
                        status.Logger.logWarning('ReadVariable module: Element ' + v.ElemName + ' not found.');
                        return;
                    }
                    const variable = mapEntry.Variables.find(x => x.Name === v.Variable);
                    if (variable) {
                        const vat = FormulaNodeCalculator.GetVariableValue(variable, false).Value;
                        if (v.StatusKey) {
                            status.Context.set(v.StatusKey, vat);
                        }
                        if (v.UseForState) {
                            status.ActualParameter = vat;
                        }
                    } else {
                        status.Logger.logWarning('ReadVariable module: Variable ' +
                            v.Variable + ' could not be found on element ' + v.ElemName + '.');
                    }
                });
            }
            return 0;
        } else {
            status.Logger.logError('ReadVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
