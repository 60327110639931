export enum LayoutPosition {
    absolute,
    fixed,
    inherit,
    initial,
    relative,
    revert,
    static,
    sticky,
    unset
}