import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Font } from '../../../../models/style/font.model';
import { LayoutService } from '../../../../services/layout.service';
import { MediaService } from '../../../../services/media.service';
import { BaseAction } from '../../base.action';

@Component({
  selector: 'fontfamily-action',
  templateUrl: './fontfamily.action.html',
  styleUrls: ['./fontfamily.action.css'],
})
export class FontFamilyAction extends BaseAction implements OnInit, OnDestroy {
    
    public static Name = 'fontfamily';

    fontFamilies = [];

    //#region SelectedFontFamily
    @Input()
    get SelectedFontFamily() {
        if (this.SelectedItemValue && this.SelectedItemValue.Font) {
            return this.SelectedItemValue.Font.FontFamilyName;
        }
        return '';
    }
    set SelectedFontFamily(val) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Font) {
                this.SelectedItemValue.Font = new Font();
            }
            this.SelectedItemValue.Font.FontFamilyName = val;
        }
        this.SelectedFontFamilyChange.emit(val);
    }

    @Output() SelectedFontFamilyChange = new EventEmitter<any>();
    //#endregion

    constructor(protected factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(factoryResolver, cdRef, data);
        this.fontFamilies = MediaService.GetFontList();
    }
    ControlInitialized() {

    }
    ExecuteAction() {
    }

    Visibility() {
        return true;
    }
    GetFontFamily(ff) {
        const result = {};
        result['font-family'] = ff;
        return result;
    }
}
