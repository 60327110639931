import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataModelService } from '../../../../../services/datamodel.service';

@Component({
    selector: 'fixed-mapping',
    templateUrl: 'fixed.mapping.html',
    styleUrls:['fixed.mapping.css']
})
export class FixedMappingForm {

    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    @Output() ValuesChanged = new EventEmitter<any>();

    constructor(private _translate: TranslateService, private _dataModelService: DataModelService) {
    }

    ItemValuesChanged() {
        this.ValuesChanged.emit();
    }
}