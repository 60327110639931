import { Component, OnInit } from '@angular/core';
import { EnumHelper } from '../../../../../../helpers/enum.helper';
import { ContainerFieldEditDialog } from '../../../../../datamodel/dialogs/container.field.edit.dialog';
import { EndpointParameter, ParameterType } from '../../../../models/end.point.description';

@Component({
    selector: 'end-point-parameter-dialog',
    templateUrl: 'end.point.parameter.dialog.html',
    styleUrls: ['end.point.parameter.dialog.css']
})
export class EndpointParameterDialog implements OnInit {

    public parameterTypes: any[];
    public currentParameter = new EndpointParameter();
    public AllTypeList: any[];
    

    ngOnInit(): void {
        this.parameterTypes = EnumHelper.GetDropdownValues(ParameterType);
        this.AllTypeList = ContainerFieldEditDialog.AllTypes;
    }

    public Initialize(arg: EndpointParameter): void {
        this.currentParameter = arg;
    }

    public GetDialogResult() {
        return this.currentParameter;
    }
    public getEnumValue(value: ParameterType): string {
        return ParameterType[value];
    }

}