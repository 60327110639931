import { Component, Input, OnInit } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { LevelNode } from '../../../models/datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../../models/datadescription/multi/measureinfo.model';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';

@Component({
    selector: 'multidimvariable-control',
    templateUrl: './multidimvariable.control.html',
    styleUrls: ['./multidimvariable.control.css']
})
export class MultidimensionalVariablenItemControl implements OnInit {

    EditItemTypeLevelValue: boolean = false;

    VisibilityTypes = [];
    DataDescriptionValue: DataDescription;
    SelectedItem: any;
    ObjectcreatorDataManagerValue: ObjectcreatorDataManager;
    VariablenItems = [];

    get EditItemTypeLevel() {
        return this.EditItemTypeLevelValue;
    }
    set EditItemTypeLevel(value) {
        if (this.EditItemTypeLevel != value) {
            this.EditItemTypeLevelValue = value;
            if (value) {
                this.VariablenItems = this.FilteredVariablenLevels;
            }
            else {
                this.VariablenItems = this.FilteredVariablenMeasures;
            }
            if (this.ObjectcreatorDataManager) {
                this.AllVariablenLevels = this.ObjectcreatorDataManagerValue.GetAllVariablelevels();
                this.AllVariablenMeasures = this.ObjectcreatorDataManagerValue.GetAllVariableMeasures();
            }
        }
    }


    @Input()
    get DataDescription() {
        return this.DataDescriptionValue;
    }
    set DataDescription(value) {
        this.DataDescriptionValue = value;
    }


    @Input()
    get ObjectcreatorDataManager() {
        return this.ObjectcreatorDataManagerValue;
    }
    set ObjectcreatorDataManager(value) {
        this.ObjectcreatorDataManagerValue = value;

    }

    _SearchValue;


    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterList();
    }


    AllVariablenLevelsValue: LevelNode[] = [];
    FilteredVariablenLevels: LevelNode[] = [];
    @Input()
    get AllVariablenLevels() {
        return this.AllVariablenLevelsValue;
    }
    set AllVariablenLevels(value) {
        this.AllVariablenLevelsValue = [];
        this._SearchValue == '';
        if (Array.isArray(value)) {
            this.AllVariablenLevelsValue = value;
        }
        this.FilteredVariablenLevels = this.AllVariablenLevelsValue;
    }

    AllVariablenMeasuresValue: MeasureInfo[] = [];
    FilteredVariablenMeasures: MeasureInfo[] = [];
    @Input()
    get AllVariablenMeasures() {
        return this.AllVariablenMeasuresValue;
    }
    set AllVariablenMeasures(value) {
        this.AllVariablenMeasuresValue = [];
        this._SearchValue == '';
        if (Array.isArray(value)) {
            this.AllVariablenMeasuresValue = value;
        }
        this.FilteredVariablenMeasures = value;
    }



    constructor() {
        this.VisibilityTypes = EnumHelper.GetDropdownValues(VisibilityType);
    }
    ngOnInit(): void {
        if (this.ObjectcreatorDataManager) {
            this.AllVariablenLevels = this.ObjectcreatorDataManagerValue.GetAllVariablelevels();
            this.AllVariablenMeasures = this.ObjectcreatorDataManagerValue.GetAllVariableMeasures();
            this.VariablenItems = this.EditItemTypeLevelValue ? this.AllVariablenLevels : this.AllVariablenMeasures;
            ObjectcreatorDataManager.MeasureVariablesChanged.subscribe(() => {
                this.AllVariablenMeasures = this.ObjectcreatorDataManagerValue.GetAllVariableMeasures();
                if (this.EditItemTypeLevel == false) {
                    this.VariablenItems = null;
                    window.setTimeout(() => {
                        this.VariablenItems = this.AllVariablenMeasures;
                    }, 50);
                }
            });
            ObjectcreatorDataManager.LevelVariablesChanged.subscribe(() => {
                this.AllVariablenLevels = this.ObjectcreatorDataManagerValue.GetAllVariablelevels();
                if (this.EditItemTypeLevel) {
                    this.VariablenItems = null;
                    window.setTimeout(() => {
                        this.VariablenItems = this.AllVariablenLevels;
                    }, 50);
                }
            });
        }
    }

    async itemSelected(item) {
        this.SelectedItem = item;
    }

    FilterList() {

        this.FilteredVariablenMeasures = this.AllVariablenMeasures.filter(item =>
            (!this._SearchValue || this._SearchValue == '' || item.Caption.toString().search(this._SearchValue) !== -1));

        this.FilteredVariablenLevels = this.AllVariablenLevels.filter(item =>
            (!this._SearchValue || this._SearchValue == '' || item.Caption.toString().search(this._SearchValue) !== -1));
    }





    onContextMenu(event: MouseEvent, dditem: any) {
        event.preventDefault();
        event['OcDragItem'] = dditem;
        event['DropArea'] = this;
    }
}
