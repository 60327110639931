import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'macplanning-table-sheets-menu-tab',
    templateUrl: './sheets.menu.tab.html',
    styleUrls: ['./sheets.menu.tab.css']
})
export class MacPlanningSheetsMenuTab {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.SelectedItemValue.Sheets == null) {
            this.SelectedItemValue.Sheets = [{
                Name: 'Sheet 1',
                TableSize: {
                    Columns: 26,
                    Rows: 100,
                    LineHeight: 25
                }
            }];
        }
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    SelectedSheet;

    SettingsChanged() {
        this.SelectedItem.ValuesChanged.next();
    }

    Add() {
        const item = {
            Name: 'NewSheet',
            TableSize: {
                Columns: 26,
                Rows: 100,
                LineHeight: 25
            }
        };
        this.SelectedItemValue.Sheets.push(item);
        this.SelectedSheet = item;
    }

    Remove() {
        this.SelectedItemValue.Sheets.splice(this.SelectedItemValue.Sheets.indexOf(this.SelectedSheet), 1);
        this.SelectedSheet = null;
    }
    SelectItem(ref) {
        this.SelectedSheet = ref;
    }
}
