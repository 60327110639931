import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { ATaskSettingsHelper, TaskRegistryEntry, TaskType } from '../../../../helpers/task.registry';
import { CellStyle } from '../../../../models/styling/cell.style';
import { SumFormattingTaskExecuter } from '../../../../models/tasks/formattasks/sum.formatting.task';
import { TaskHelper } from '../../../../models/tasks/task.helper';
import { ATaskPanel } from '../../a.task.panel';

@Component({
    selector: 'sum-formatting-task-panel',
    templateUrl: './sum.formatting.task.panel.html'
})
export class SumFormattingTaskPanel extends ATaskPanel {

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = SumFormattingTaskExecuter.TaskID;
        reg.Caption = '@@Summe';
        reg.SettingsControl = SumFormattingTaskPanel;
        reg.SettingsHelper = new SumFormattingTaskSettingsHelper();
        reg.Executer = SumFormattingTaskExecuter;
        reg.TaskType = TaskType.FormatTask;
        return reg;
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (this.SettingsValue.Style) {
                if (!(this.SettingsValue.Style instanceof CellStyle)) {
                    this.SettingsValue.Style = plainToClass(CellStyle, this.SettingsValue.Style);
                }
            } else {
                this.SettingsValue.Style = new CellStyle();
            }
        }
    }    
}

export class SumFormattingTaskSettingsHelper extends ATaskSettingsHelper {
    canExecute(reportObject: any): boolean {
        return TaskHelper.IsTableObject(reportObject);
    }
    getCaption(task: any, dd: any) {
        return TranslateHelper.TranslatorInstance.instant('@@Summenformatierung');
    }
}
