import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';

@Component({
    selector: 'erd-overview-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ERDOverviewSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return ERDOverviewDetail;
    }

    loadList(handler) {
        const list = [];
        this.service.ReadERDs().subscribe((result) => {
            var list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Caption,
                    ID: entry._Id
                });
            });
            handler(list);
        });
        handler(list);
    }

    loadData(data) {
        this.service.ReadERDById(data).subscribe((result) => {
            if (result) {
                const item = result;
                this.setSelectedItem(item);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Caption: TranslateHelper.TranslatorInstance.instant('@@Neues ERD'),
            Modules: [],
            DataSourceColors: []
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das ERD \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@ERD \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@ERD loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@ERD \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@ERD speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.RemoveERD(data).subscribe((result) => {
            handler(result);
        });
    }

    saveInternal(item, handler) {
        this.service.AddOrUpdateERD(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, item.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item._Id = result.SID;
        item._version = result.Version;
    }
}

@Component({
    selector: 'erd-overview-detail',
    templateUrl: './erd.overview.settings.html',
    styleUrls: ['./erd.overview.settings.css']
})
export class ERDOverviewDetail extends BaseListDetail {
}