import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import {ContainerEnumService, DataModelService} from "../../../services/datamodel.service";

@Component({
    selector: 'dataobject-field-detail',
    templateUrl: './dataobject.field.dialog.html'
})
export class DataObjectFieldDialog {
    SelectedField: AttributeProperty;
    AllTypes = [];
    AllTables = [];
    AllStaticTables = [];
    AllEnums = [];
    EnumValues = [];

    constructor(private containerEnumService: ContainerEnumService) {
        this.SelectedField = new AttributeProperty();
        this.SelectedField.Type = '9b056ce1-ffb9-4168-9e00-977449f2f66d';
    }

    Initialize(args) {
        if (args) {
            if (args.Field) {
                const json = serialize(args.Field);
                this.SelectedField = deserialize(AttributeProperty, json);

            }
            this.AllTypes = args.AllTypes;
            this.AllTables = args.AllTables;
            this.AllStaticTables = args.AllStaticTables;
            this.AllEnums = args.AllEnums;

            // set enum's default value
            if(args && args.Field) {
                const _selectedEnum = this.AllEnums.find(x => x.Value == args.Field.AdvancedType);
                if(_selectedEnum) {
                    this.GetEnumById(_selectedEnum.Value);
                }
            }

            if (this.SelectedField?.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') { // AttributeClass && STATIC
                const table = this.AllStaticTables.find(x => x.Value === this.SelectedField.AdvancedType);
                if (table) {
                    this.SelectedField.Type = '9DFCE24E-FCB8-4CD8-B724-EFB87810EE80';
                }
            }
        }
    }

    GetDialogResult() {
        if (this.SelectedField.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c' || this.SelectedField.Type === '9DFCE24E-FCB8-4CD8-B724-EFB87810EE80') {
            this.SelectedField.Type = 'c4ef25dd-c176-418c-836e-f26c52d7f59c';
            if (!this.SelectedField.AdvancedType || this.SelectedField.AdvancedType === '00000000-0000-0000-0000-000000000000') {
                const warning =
                    new TranslateFormatText('@@Sie verwenden den Feldtyp Klasse ohne eine entsprechende Tabelle selektiert zu haben.');
                MessageBoxHelper.ShowDialog(warning, new TranslateFormatText('@@Hinweis'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
            }
        } else if (this.SelectedField.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') {
            if (!this.SelectedField.AdvancedType || this.SelectedField.AdvancedType === '00000000-0000-0000-0000-000000000000') {
                const warning =
                    new TranslateFormatText('@@Sie verwenden den Feldtyp Enum ohne einen entsprechenden Enum selektiert zu haben.');
                MessageBoxHelper.ShowDialog(warning, new TranslateFormatText('@@Hinweis'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
            }
        } else if (this.SelectedField.Type === 'a236172b-9aaa-4211-9fe9-b57daf3f3a29') {
            // Bei User Shared auf true setzen
            this.SelectedField.IsShared = true;
        }
        return this.SelectedField;
    }

    IsSharedChanged(ev) {
        if (ev && ev.checked === false) {
            this.SelectedField.IsReverse = false;
        }
    }

    onTypeChange() {
        this.SelectedField.Default = null;
    }

    onEnumSelection(id) {
        this.GetEnumById(id);
    }

    GetEnumById(id) {
        if(id) {
            this.containerEnumService.GetEnum(id).subscribe((data) => {
                this.EnumValues = data.Fields;
            })
        }
    }

    onCancelDefault() {
        this.SelectedField.Default = '';
    }
}
