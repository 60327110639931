import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LanguageService } from '../../../services/language.service';
import { LayoutService } from '../../../services/layout.service';
import { MultiReportingService } from '../../../services/reporting.service';
import { IBaseComponent } from '../base.component';
import { FilterTreeComponent, FilterTreeControl, MultidimensionalTreeLogic } from './filter.tree.control';

@Component({
    selector: 'filter-dropdown-control',
    templateUrl: './filter.dropdown.control.html',
    styleUrls: ['./filter.dropdown.control.css']
})
export class FilterDropdownControl extends IBaseComponent {
    static Type = 'filterdropdown';
    static Default = {
        Caption: 'FilterDropdown',
        Type: 'filterdropdown',
        Layout: {
            Height: {Type: 0, Value: 50},
            Width: {Type: 0, Value: 200},
        }
    };

    SelectionText = '';    
    WasOpened = false;
    ShowLoading = true;
    IsRtl = false;

    @ViewChild('treeControl') treeControl: FilterTreeComponent;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    ActualSelectionValue;
    get ActualSelection() {
        return this.ActualSelectionValue;
    }
    set ActualSelection(val) {
        this.ActualSelectionValue = val;
        this.setSelectionText();
        FilterTreeControl.updateVariables(this.LayoutElementValue, this.LayoutValue, val);
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private service: MultiReportingService) {
        super(cdRef, data);
        this.EventList.push('selectionChanged');
        this.IsRtl = RTLHelper.Direction === 'rtl';
        this.PropertyList.push(new ElementProperty('ActualSelection', 'list', '@@ActualSelection'));
        this.Subscriptions['RTL'] = RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir === 'rtl';
        });
        this.Subscriptions['LanguageChanged'] = LanguageService.LanguageChanged.subscribe(() => {
            if (!this.LayoutElementValue.Relational && this.LayoutElementValue.DataSource) {
                const actSel = this.ActualSelectionValue;
                if (actSel && actSel.length > 0) {
                    const query = {
                        DataSource: this.LayoutElementValue.DataSource,
                        SelectedMembers: actSel
                    };
                    this.service.GetMemberCaptions(query).subscribe(list => {
                        if (list) {
                            list.forEach(x => {
                                const sel = actSel.find(y => y.LevelId == x.LevelId);
                                if (sel) {
                                    sel.MemberIds.forEach((m, i) => {
                                        const newCaption = x.Captions[m];
                                        if (newCaption) {
                                            sel.MemberCaptions[i] = newCaption;
                                        }
                                    });
                                }
                            });
                            this.setSelectionText();
                        }
                    });
                }
            }
        });
    }

    setInitialized() {
        FilterTreeControl.GetDynamicTimeInit(this.LayoutElementValue, this.LayoutValue).then(x => {
            if (x) {
                this.LayoutElementValue.SavedSelection = [x];
                this.ActualSelection = this.LayoutElementValue.SavedSelection;
            }
            super.setInitialized();
        });
    }

    ControlInitialized() {
        if (this.LayoutElementValue.SaveSelection && Array.isArray(this.LayoutElementValue.SavedSelection)) {
            this.ActualSelection = this.LayoutElementValue.SavedSelection;
        }
    }

    onSelectionChange(ev) {
        this.ActualSelection = ev;
        FilterTreeControl.updateSavedSelection(this.LayoutElementValue, ev);
        if (!this.LayoutElementValue.MultiSelect && this.menuTrigger) {
            this.menuTrigger.closeMenu();
        }
        this.triggerEvent('selectionChanged', ev);
    }

    setSelectionText() {
        let selText = '';
        if (this.ActualSelectionValue && this.ActualSelectionValue.length > 0) {
            if (this.LayoutElementValue.Relational) {
                selText = this.ActualSelectionValue.join(', ');
            } else {
                let first = true;
                this.ActualSelectionValue.forEach(x => {
                    if (!first) {
                        selText += ', ';
                    }
                    first = false;
                    selText += x.MemberCaptions.join(', ');
                });
            }
        }
        this.SelectionText = selText;
        this.cdRef.detectChanges();
    }

    codeDescChanged() {
        if (this.treeControl) {
            this.treeControl.codeDescChanged();
        }
    }

    onLayoutElementChanged() {
        if (this.treeControl) {
            this.treeControl.InitLogic();
        }
    }

    onMenuOpened() {
        this.WasOpened = true;
        if (this.LayoutElementValue.ConditionalFilter && this.treeControl) {
            this.ShowLoading = true;
            this.treeControl.RefreshLogic();
        }
    }

    clearSelection() {
        if (this.treeControl) {
            this.treeControl.clearSelection();
        } else {
            this.onSelectionChange([]);
        }
    }

    onRootNodesFilled(filled) {
        this.ShowLoading = !filled;
        this.cdRef.detectChanges();
    }

    getNextOrPrevious(ev, next) {
        let levelID;
        let memberID = '';
        let ss;
        const le = this.LayoutElementValue;
        if (this.ActualSelectionValue && this.ActualSelectionValue.length > 0 && !le.MultiSelect) {
            ss = this.ActualSelectionValue[0];
        }
        if (ss && ss.LevelId && ss.MemberIds && ss.MemberIds.length > 0) {
            levelID = ss.LevelId;
            memberID = ss.MemberIds[0];
        } else if (le.Levels && le.Levels.length > 0) {
            levelID = le.Levels[0];
        }
        if (levelID) {
            if (le.ConditionalFilter) {
                MultiCacheService.GetLevel(levelID, le.DataModel).then(l => {
                    MultidimensionalTreeLogic.ExecuteDependendFilter(l, le.ConditionalFilter, le.DataModel, null, le, this.service).then(x => {
                        let selected;
                        if (x.length > 0) {
                            if (memberID) {
                                x.some((an, i) => {
                                    if (an.Key == memberID) {
                                        if (next) {
                                            if (i == x.length - 1) {
                                                selected = x[0];
                                            } else {
                                                selected = x[i + 1];
                                            }
                                        } else {
                                            if (i == 0) {
                                                selected = x[x.length - 1];
                                            } else {
                                                selected = x[i - 1];
                                            }
                                        }
                                        return true;
                                    }
                                    return false;
                                });
                            } else {
                                selected = x[next ? 0 : x.length - 1];
                            }
                        }
                        if (selected) {
                            this.setSelectedEntry(selected.Key, selected.Caption, selected.MemberId, levelID);
                        } else {
                            this.onSelectionChange([]);
                        }
                    });
                });             
            } else if (le.DataSource) {
                this.service.GetNextOrPreviousMember(le.DataSource, levelID, memberID, next).subscribe(x => {
                    if (x) {
                        this.setSelectedEntry(x.Key, x.Caption, x.ID, levelID);
                    } else {
                        this.onSelectionChange([]);
                    }
                });
            }
        }
        ev.stopPropagation();
    }

    private setSelectedEntry(memberKey, memberCaption, memberID, levelID) {
        let codeDesc = 0;
        if (typeof this.LayoutElementValue.CodeDesc === 'number') {
            codeDesc = this.LayoutElementValue.CodeDesc;
        }
        let caption;
        if (codeDesc === 1) { // Code
            caption = memberKey;
        } else if (codeDesc === 3) { // Code & Description
            caption = memberKey + ' ' + memberCaption;
        } else {
            caption = memberCaption;
        }
        this.onSelectionChange([{
            LevelId: levelID,
            MemberIds: [memberID],
            MemberCaptions: [caption]
        }]);
    }
}
