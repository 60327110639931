import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { ContainerEnumService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-replaceenum-settings',
    templateUrl: './replaceenum.settings.html',
    styleUrls: ['./replaceenum.settings.css']
})
export class ReplaceEnumSettings extends WorkflowDialogContent {
    Data = new ReplaceEnumSettingsData();
    Properties = [];
    DataModel;
    Enums = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'replaceEnumWFModule';
        reg.Caption = '@@Enum-Werte ersetzen';
        reg.GroupID = 'objectoperations';
        reg.Index = 50;
        reg.SettingsControl = ReplaceEnumSettings;
        reg.SettingsTypeHelper = new ReplaceEnumSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: ContainerEnumService) {
        super();
        this.UseActualState = true;
    }

    ngOnInit(): void {
        if (this.DataModel) {
            this.service.GetEnumsByDataModel(this.DataModel).subscribe(result => {
                if (result) {
                    this.Enums = result;
                }
            });
        }
    }

    initialize(data: any) {
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            const json = JSON.stringify(dsInfo.Properties);
            this.Properties = JSON.parse(json);
            this.DataModel = dsInfo.DataModel;
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ReplaceEnumSettingsData, json);
            this.Data.EnumEntries.forEach(x => {
                this.onEnumChanged(x);
            });
        }
    }

    getResult(): any {
        const props = [];
        this.Data.EnumEntries.forEach(x => {
            if (x.PropertyName && x.EnumID) {
                delete x['EnumValues'];
                props.push(x);
            }
        });
        this.Data.EnumEntries = props;
        return this.Data;
    }

    addProp() {
        if (this.Data) {
            if (this.Data.EnumEntries) {
                this.Data.EnumEntries.push(new ReplaceEnumEntry());
            } else {
                this.Data.EnumEntries = [new ReplaceEnumEntry()];
            }
        }
    }

    removeProp(i) {
        if (this.Data && this.Data.EnumEntries) {
            this.Data.EnumEntries.splice(i, 1);
        }
    }

    onEnumChanged(prop) {
        if (prop) {
            if (prop.EnumID) {
                CacheService.ReadEnumValues(prop.EnumID).then(data => {
                    prop.EnumValues = data;
                    if (!data.some(x => x.Value === prop.DefaultValue)) {
                        prop.DefaultValue = null;
                    }
                });
            } else {
                prop.EnumValues = [];
                prop.DefaultValue = null;
            }
        }
    }
}

// @dynamic
export class ReplaceEnumSettingsData extends AServiceWorkflowData {
    @Type(() => ReplaceEnumEntry)
    EnumEntries: ReplaceEnumEntry[] = [];
    ReplaceID = false;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.ReplaceEnum.ReplaceEnumSettingsData';
    }
}

export class ReplaceEnumEntry {
    PropertyName: string;
    EnumID: string;
    FillWithDefault = false;
    DefaultValue: string;
}

export class ReplaceEnumSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const info = new WorkflowExitInfo();
        info.Type = 'objData';
        return [info];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'objData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new ReplaceEnumSettingsData();
    }
}
