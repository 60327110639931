import { Component, OnInit } from "@angular/core";
import { SchedulerService } from "../../services/scheduler.service";
import { JobsLogsComponent } from "./joblogs/jobs.logs";
import { JobsQueueComponent } from "./queue/jobs.queue";
@Component({
    selector: 'jobs-settings',
    templateUrl: './jobs.settings.html',
    styleUrls: ['./jobs.settings.css']
})
export class JobsSettings implements OnInit {
    ngOnInit(): void {
    }
    constructor(private service: SchedulerService) {
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@Jobs',
            ID: 'jobs',
            Icon: 'work',
            Index: 6,
            Parent: 'about',
            Security: {
                Name: 'evidanza.App.Shared.Security.AboutRight',
                Value: 3
            },
            Content: JobsSettings,
            Children: [
                {
                    Caption: '@@Logs',
                    ID: 'job-logs',
                    Icon: 'rss_feed',
                    Index: 1,
                    Content: JobsLogsComponent
                },
                {
                    Caption: '@@Queue',
                    ID: 'job-queue',
                    Icon: 'queue',
                    Index: 2,
                    Content: JobsQueueComponent
                }
            ]
        }
    }
}