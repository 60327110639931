import { ComparerFormula } from "./iformula";
import { ValueAndType } from "../basic/formulaEditor.model";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { CompareType } from "../enums/comparetype.enum";
import { ValueType } from "../enums/valuetype.enum";

export class Greater extends ComparerFormula {
    static StaticID: string = "a4999078-6792-4aac-a510-f55c8dfe596e";

    constructor() {
        super();
        this._ID = Greater.StaticID;
        this._Name = "Groesser";
    }

    OrderID(): number {
        return 1;
    }
    CompareType(): CompareType {
        return CompareType.Greater;
    }
    OperationValue(): string {
        return '>';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal: ValueAndType = {
            Type: ValueType.Bool,
            Value: false
        };
        if (args && args.length >= 2 && args[0] && args[1] && args[0].Type != ValueType.Null && args[1].Type != ValueType.Null) {
            if (args[0].Type == ValueType.String || args[1].Type == ValueType.String) {
                retVal.Value = String(args[0].Value).localeCompare(String(args[1].Value)) > 0;
            } else if (args[0].Type == ValueType.Datetime || args[1].Type == ValueType.Datetime) {
                var now = new Date();
                var d1, d2;
                if (args[0].Value instanceof Date && !isNaN(args[0].Value.getTime())) {
                    d1 = args[0].Value;
                } else {
                    d1 = now;
                }
                if (args[1].Value instanceof Date && !isNaN(args[1].Value.getTime())) {
                    d2 = args[1].Value;
                } else {
                    d2 = now;
                }
                retVal.Value = d1 > d2;
            } else if (args[0].Type == ValueType.Double || args[1].Type == ValueType.Double || args[0].Type == ValueType.Long || args[1].Type == ValueType.Long) {
                retVal.Value = Number(args[0].Value) > Number(args[1].Value);
            }
        }
        return retVal;
    }
}