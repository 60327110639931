import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { ACaptionGetter, DefaultCaptionGetter } from '../../../helpers/acaptiongetter';
import { FilterHelper } from '../../../helpers/filter.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { Comparer } from '../../../models/enums/comparer.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { DataService } from '../../../services/data.service';
import { LayoutService } from '../../../services/layout.service';
import { ABaseTreeNode, IBaseTreeNode, IDKeeper, ITreeLogic } from '../../common/basetreecontrol/base.tree.control';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-tree',
    templateUrl: './tree.control.html',
    styleUrls: ['./tree.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeControl extends IBaseComponent {
    static Type: any = 'tree';
    static Default = {
        Editable: true,
        Type: 'tree',
        Layout: {
            _Editable: true,
        }
    };

    //#region SelectedNodes
    SelectedNodesValue = [];

    @Input()
    get SelectedNodes() {
        return this.SelectedNodesValue;
    }
    set SelectedNodes(val) {
        this.SelectedNodesValue = val;
    }
    //#endregion

    //#region Nodes
    NodesValue = [];

    @Input()
    get Nodes() {
        return this.NodesValue;
    }
    set Nodes(val) {
        this.NodesValue = val;
        this.NodesChange.emit(this.NodesValue);
    }

    @Output() NodesChange = new EventEmitter<any>();
    //#endregion

    //#region DisplayMemberPath
    @Input()
    get DisplayMemberPath() {
        return this.LayoutElementValue.DisplayMemberPath;
    }
    set DisplayMemberPath(val) {
        this.LayoutElementValue.DisplayMemberPath = val;
        this.DisplayMemberPathChange.emit(this.LayoutElementValue.DisplayMemberPath);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<any>();
    //#endregion

    //#region MultipleSelection
    @Input()
    get MultipleSelection() {
        return this.LayoutElementValue.MultipleSelection;
    }
    set MultipleSelection(val) {
        this.LayoutElementValue.MultipleSelection = val;
        this.MultipleSelectionChange.emit(this.LayoutElementValue.MultipleSelection);
    }

    @Output() MultipleSelectionChange = new EventEmitter<any>();
    //#endregion

    @Output() SelectionChanged = new EventEmitter<any>();
    @Output() DoubleClick = new EventEmitter<any>();

    loaded = false;
    DataSourceID;
    pageIndex = 0;
    pageLength = 100;
    idKeeper = new IDKeeper();
    CaptionGetter: ACaptionGetter;
    FieldList = [];
    Logic: MainTreeLoader;

    static GetNode(data: any, id: number, level: number, captionGetter: ACaptionGetter, icon: string) {
        const node = new TreeDataNode(id);
        if (data) {
            node.Caption = data.toString();
            node.Data = data;
            if (captionGetter) {
                node.Caption = captionGetter.GetCaption(data);
            }
            if (icon) {
                node.Icon = data[icon];
            }
        }
        node.Level = level;
        return node;
    }

    constructor(private dataService: DataService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EventList.push('selectionchanged');
        this.EventList.push('doubleclick');
    }

    ControlInitialized() {
        this.DataSourceID = MetaHelper.FindDataSourceID(this.Layout, this.LayoutElementValue);
        if (this.DataSourceID) {
            CacheService.ReadTable(this.DataSourceID).then((result) => {
                if (result) {
                    const fields = result['Fields'];
                    if (fields) {
                        this.FieldList = fields;
                    }
                }
                this.ExecuteRefresh();
            });
        }
    }

    onSelectionChanged(event) {
        this.SelectedNodes = event;
        this.SelectionChanged.emit(event);
        this.triggerEvent('selectionchanged', event);
    }

    onDoubleClick(event) {
        this.DoubleClick.emit(event);
        this.triggerEvent('doubleclick', event);
    }

    onScrollDown() {
        if (this.LayoutElementValue) {
            this.LoadData();
        }
    }

    LoadData() {
        if (!this.loaded && this.DataSourceID) {
            const dse = MetaHelper.FindValidParent(this.Layout, this.LayoutElementValue);
            const filter = FilterHelper.PrepareFilter(dse);
            if (!this.Nodes) {
                this.Nodes = [];
            }
            if (this.Variables && this.Layout.Filter) {
                this.Layout.Filter.forEach((fil) => {
                    if (this.Variables[fil.Filter]) {
                        const item = new RequestFilter();
                        item.Name = fil.Name;
                        item.Operator = fil.Comparer;
                        item.Value = this.Variables[fil.Filter];
                        FilterHelper.AddSearchFilter(filter, item);
                    }
                });
            }
            filter.StartRow = null; this.pageIndex * this.pageLength;
            filter.EndRow = null; filter.StartRow + this.pageLength;
            this.dataService.SearchObjects('dynamicdata', this.DataSourceID, filter).subscribe((data) => {
                if (!data) {
                    this.loaded = true;
                } else {
                    const nodes = this.Nodes.slice();
                    this.pageIndex += 1;
                    data.forEach(x => {
                        const node = TreeControl.GetNode(x, this.idKeeper.NextID, 0, this.CaptionGetter, this.LayoutElementValue.Icon);
                        if (this.Logic && this.Logic.NextLoader) {
                            node.HasChildren = this.Logic.NextLoader.hasChildren(x);
                        }
                        nodes.push(node);
                    });
                    this.Nodes = nodes;
                }
                this.cdRef.detectChanges();
            });
        }
    }

    ExecuteRefresh() {
        this.loaded = false;
        this.pageIndex = 0;
        this.Nodes = [];
        this.idKeeper.reset();
        this.CaptionGetter = ACaptionGetter.GetCaptionGetter(this.LayoutElementValue, this.FieldList);
        if (this.LayoutElementValue) {
            this.Logic = new MainTreeLoader(this.DataSourceID, this.FieldList, this.dataService, this.idKeeper, this.LayoutElementValue);
        } else {
            this.Logic = null;
        }
        this.LoadData();
    }
}

export class TreeDataNode extends ABaseTreeNode {
    Data;
    Level = 0;
}

export abstract class ATreeDataLoader implements ITreeLogic {
    CaptionGetter: ACaptionGetter = new DefaultCaptionGetter();
    IsRecursive = false;
    NextLoader: ATreeDataLoader;

    constructor(protected actField, protected service: DataService, protected idKeeper: IDKeeper) {
    }

    abstract loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]>;
    abstract hasChildren(data): boolean;
}

export abstract class AChildListLoader extends ATreeDataLoader {
    CheckForValue = false;
    ColName = '_Id';
    DataSourceID;
    Icon;

    async loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]> {
        const retVal = [];
        if (node && node['Data'] && this.actField && this.actField.IsList) {
            const children = node['Data'][this.actField.Name];
            let data;
            if (this.actField.IsShared && !this.actField.IsReverse) {
                const filter = RequestOptions.CleanRequestOptions();
                filter.Filters = [];
                filter.Columns = [];
                const colFilter = new RequestFilter();
                colFilter.Name = this.ColName;
                filter.Filters.push(colFilter);
                if (this.CheckForValue) {
                    if (children != null) {
                        colFilter.Operator = Comparer.Equal;
                        colFilter.Value = children;
                        data = await this.service.SearchObjects('dynamicdata', this.DataSourceID, filter).toPromise();
                    }
                } else if (Array.isArray(children)) {
                    colFilter.Operator = Comparer.In;
                    colFilter.Value = children;
                    data = await this.service.SearchObjects('dynamicdata', this.DataSourceID, filter).toPromise();
                }
            } else if (Array.isArray(children)) {
                data = children;
            }
            if (data) {
                let level = 0;
                if (typeof node['Level'] === 'number') {
                    level = node['Level'];
                }
                level++;
                data.forEach(x => {
                    const dataNode = TreeControl.GetNode(x, this.idKeeper.NextID, level, this.CaptionGetter, this.Icon);
                    if (this.IsRecursive) {
                        dataNode.HasChildren = this.hasChildren(x);
                    } else if (this.NextLoader) {
                        dataNode.HasChildren = this.NextLoader.hasChildren(x);
                    }
                    retVal.push(dataNode);
                });
            }
        }
        return retVal;
    }

    hasChildren(data) {
        if (data && this.actField) {
            const val = data[this.actField.Name];
            if (this.CheckForValue) {
                return val != null;
            } else {
                return Array.isArray(val) && val.length > 0;
            }
        }
        return false;
    }
}

export class MainTreeLoader implements ITreeLogic {

    NextLoader: ATreeDataLoader;

    constructor(protected dataSourceID, protected fieldList, protected service: DataService,
        protected idKeeper: IDKeeper, private layout) {
        if (this.layout && this.layout.TreeSettings) {
            if (this.layout.TreeSettings.Recursive) {
                if (this.layout.TreeSettings.ParentID) {
                    this.NextLoader = new RecursiveParentIDLoader(dataSourceID,
                        fieldList.find(x => x.ID === this.layout.TreeSettings.ParentID),
                        fieldList.find(x => x.ID === this.layout.TreeSettings.ParentCheckID),
                        fieldList.find(x => x.ID === this.layout.TreeSettings.HasChildren),
                        service, idKeeper, ACaptionGetter.GetCaptionGetter(layout, fieldList), this.layout.Icon);
                } else if (this.layout.TreeSettings.ChildList) {
                    this.NextLoader = new RecursiveChildListLoader(dataSourceID,
                        fieldList.find(x => x.ID === this.layout.TreeSettings.ChildList), service, idKeeper,
                        ACaptionGetter.GetCaptionGetter(layout, fieldList), this.layout.Icon);
                }
            } else {
                const childField = fieldList.find(x => x.ID === this.layout.TreeSettings.NextLevelID);
                if (childField) {
                    this.NextLoader = new ChildListLoader(childField, service, idKeeper, this.layout.TreeSettings);
                }
            }
        }
    }

    async loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]> {
        if (node && this.NextLoader) {
            const level = node['Level'];
            if (typeof level === 'number') {
                let nextLoader = this.NextLoader;
                for (let i = 0; i < level && nextLoader; i++) {
                    if (nextLoader.IsRecursive) {
                        break;
                    }
                    nextLoader = nextLoader.NextLoader;
                }
                if (nextLoader) {
                    return nextLoader.loadChildren(node);
                }
            }
        }
        return [];
    }
}

export class RecursiveParentIDLoader extends ATreeDataLoader {
    private checkFieldName: string;

    constructor(private dataSourceID, protected parentField, protected parentCheckField, protected hasChildField,
        protected service: DataService, protected idKeeper: IDKeeper, protected captionGetter: ACaptionGetter, protected icon) {
        super(parentField, service, idKeeper);
        if (parentField) {
            if (parentField.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                this.checkFieldName = '_Id';
            } else if (parentCheckField) {
                if (parentField.Type === parentCheckField.Type && parentField.Name !== parentCheckField.Name) {
                    this.checkFieldName = parentCheckField.Name;
                }
            } else if (parentField.Type === '983dad4c-fd88-4124-aed0-1dd1d177521a') {
                this.checkFieldName = '_Id';
            }
        }
        this.CaptionGetter = captionGetter;
        this.IsRecursive = true;
    }

    async loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]> {
        const retVal = [];
        if (this.checkFieldName) {
            const filter = new RequestOptions();
            filter.Filters = [];
            filter.Columns = [];
            let level = 0;
            if (node) {
                if (node['Data']) {
                    const colFilter = new RequestFilter();
                    colFilter.Name = this.actField.Name;
                    colFilter.Operator = Comparer.Equal;
                    colFilter.Value = node['Data'][this.checkFieldName];
                    filter.Filters.push(colFilter);
                }
                if (typeof node['Level'] === 'number') {
                    level = node['Level'];
                }
            }
            level++;
            const data = await this.service.SearchObjects('dynamicdata', this.dataSourceID, filter).toPromise();
            if (data) {
                data.forEach(x => {
                    const dataNode = TreeControl.GetNode(x, this.idKeeper.NextID, level, this.CaptionGetter, this.icon);
                    dataNode.HasChildren = this.hasChildren(x);
                    retVal.push(dataNode);
                });
            }
        }
        return retVal;
    }

    hasChildren(data) {
        if (this.hasChildField && data) {
            return data[this.hasChildField.Name];
        }
        return true;
    }
}

export class RecursiveChildListLoader extends AChildListLoader {
    constructor(protected dataSourceID, protected childListField, protected service: DataService,
        protected idKeeper: IDKeeper, protected captionGetter: ACaptionGetter, protected icon) {
        super(childListField, service, idKeeper);
        this.DataSourceID = dataSourceID;
        this.CaptionGetter = captionGetter;
        this.IsRecursive = true;
        this.Icon = icon;
    }
}

export class ChildListLoader extends AChildListLoader {

    constructor(protected nextField, protected service: DataService, protected idKeeper: IDKeeper, protected nextLevel) {
        super(nextField, service, idKeeper);
        if (nextField) {
            this.DataSourceID = nextField.AdvancedType;
            if (nextField.IsShared && !nextField.IsReverse && nextField.KeyColumns && nextField.KeyColumns.length === 1) {
                this.CheckForValue = true;
                this.ColName = nextField.KeyColumns[0];
            }
            if (nextLevel && nextField.IsList && nextField.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') {
                this.Icon = nextLevel.Icon;
                CacheService.ReadTable(nextField.AdvancedType).then((result) => {
                    if (result) {
                        const fields = result['Fields'];
                        if (fields) {
                            this.CaptionGetter = ACaptionGetter.GetCaptionGetter(nextLevel, fields);
                            if (nextLevel.NextLevel) {
                                if (nextLevel.NextLevel.Recursive) {
                                    if (nextLevel.NextLevel.ParentID) {
                                        this.NextLoader = new RecursiveParentIDLoader(this.DataSourceID,
                                            fields.find(x => x.ID === nextLevel.NextLevel.ParentID),
                                            fields.find(x => x.ID === nextLevel.NextLevel.ParentCheckID),
                                            fields.find(x => x.ID === nextLevel.NextLevel.HasChildren),
                                            service, idKeeper, this.CaptionGetter, nextLevel.Icon);
                                    } else if (nextLevel.NextLevel.ChildList) {
                                        this.NextLoader = new RecursiveChildListLoader(this.DataSourceID,
                                            fields.find(x => x.ID === nextLevel.NextLevel.ChildList),
                                            service, idKeeper, this.CaptionGetter, nextLevel.Icon);
                                    }
                                } else {
                                    const childField = fields.find(x => x.ID === nextLevel.NextLevel.NextLevelID);
                                    if (childField) {
                                        this.NextLoader = new ChildListLoader(childField, service, idKeeper, nextLevel.NextLevel);
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }
    }
}
