import { Component, OnDestroy, Input, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { MatListOption, MatSelectionListChange } from "@angular/material/list";
import { DispatcherTimer } from "./dispatcher.timer";
import { MessageSettingsService } from "../MessageSettingsService";


@Component({
    selector: 'add-user',
    templateUrl: 'add.user.html',
    styleUrls: ['add.user.css']
})

export class AddFriendsComponent implements OnDestroy, AfterViewInit {
    private _SelectedUsers: string[] = [];
    private _Users: any[] = [];
    private _Timer: DispatcherTimer;
    private _Subscribes: any[] = [];
    public AllSelected = false;
    private _MeToo = false;
    private _VisibleUsers: string[];

    private _SearchUsers: any[] = [];
    public get SearchUsers(): any[] {
        return this._SearchUsers;
    }

    private _SearchText: string = "";
    public get SearchText(): string {
        return this._SearchText;
    }
    public set SearchText(value: string) {
        if (value != this._SearchText) {
            this._SearchText = value;
            this._Timer.Start();
        }
    }

    @Input() public get SelectedUsers(): string[] {
        return this._SelectedUsers;
    }
    public set SelectedUsers(value: string[]) {
        this._SelectedUsers = value;
    }
    @Output() public SelectedUsersChange = new EventEmitter<string[]>();

    constructor(private _MessageService: MessageSettingsService) {

    }

    public UserSelectionChange(value: MatListOption): void {
        if (value.selected) {
            this._SelectedUsers.push(value.value.SID);
        } else {
            let index: number = this._SelectedUsers.indexOf(value.value.SID);
            if (index > -1) {
                this._SelectedUsers.splice(index, 1);
            }
        }
    }

    public IsSelected(value: any): boolean {
        let index: number = this._SelectedUsers.indexOf(value.SID);
        return index > -1;
    }

    private Timer_Tick(): void {
        if (this._SearchText.length > 0) {
            this._SearchUsers = [];
            let regex = new RegExp(this._SearchText, "i");
            this._Users.forEach((user) => {
                if (regex.exec(user.FirstName + ' ' + user.LastName) !== null)
                    this.SearchUsers.push(user);
            });
        } else {
            this._SearchUsers = this._Users;
        }
    }

    ngOnDestroy(): void {
        this._Subscribes.forEach((sub) => {
            sub.unsubscribe()
        });
    }

    public SelectionChange(event: MatSelectionListChange): void {
        if (event && event.options && event.options.length > 0) {
            const option = event.options[0];
            if (option.selected) {
                if (option.value) {
                    this._SelectedUsers.push(option.value.SID);
                } else {
                    this.AllSelected = true;
                    this._SelectedUsers = this._Users.map(x => x.SID);
                }
            } else {
                if (option.value) {
                    let index: number = this._SelectedUsers.indexOf(option.value.SID);
                    if (index > -1)
                        this._SelectedUsers.splice(index, 1);
                } else {
                    this.AllSelected = false;
                    this._SelectedUsers = [];
                }
            }
            this.SelectedUsersChange.next(this._SelectedUsers);
        }
    }

    public GetDialogResult() {
        let users: any[] = [];
        this._SelectedUsers.forEach(su => {
            this._Users.some(function (u) {
                if (u.SID == su) {
                    users.push(u);
                    return true;
                }
                return false;
            });
        });
        return users;
    }

    public Initialize(arg: IAddFriendsComponent): void {
        arg.SelectedUsers.forEach(a => {
            this._SelectedUsers.push(a.SID);
        });
        this._MeToo = arg.MeToo;
        this._VisibleUsers = arg.VisibleUsers;
    }

    ngAfterViewInit(): void {
        this._Timer = new DispatcherTimer(300);
        this._Subscribes.push(this._Timer.Tick.subscribe(this.Timer_Tick.bind(this)));

        this._MessageService.GetAllUsers().subscribe((users: any[]) => {
            users.forEach(u => {
                if (this._VisibleUsers) {
                    if (this._VisibleUsers.findIndex(x => x === u.SID) > -1)
                        this._Users.push(u)
                } else {
                    this._Users.push(u)
                }
            });
            this._Users.forEach(u => this._SearchUsers.push(u));
        });
    }
}

export interface IAddFriendsComponent {
    MeToo?: boolean;
    SelectedUsers: any[];
    VisibleUsers?: string[];
}
