import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';


// @dynamic
export class TranslationLoader extends RequestBase implements TranslateLoader {
    BasePath = 'base/translations';
    static GeneralSettings: any;
    constructor(http: HttpClient) {
        super(http);
    }

    getTranslation(lang: string): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetTranslations?culture=' + lang, this.options);
    }
}

@Injectable()
export class TranslationService extends RequestBase {
    BasePath = 'base/translations';

    constructor(http: HttpClient) {
        super(http);
    }

    GetAllTranslationKeys(): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetAllTranslationKeys', this.options);
    }

    GetTranslationKeysWithMissingTranslations(): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetTranslationKeysWithMissingTranslations', this.options);
    }

    GetTranslationsForKey(key): Observable<any> {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/GetTranslationsForKey?key=' + key, this.options);
    }

    SaveTranslationForKey(trans): Observable<any> {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/SaveTranslationForKey', trans, this.options);
    }

    DeleteTranslation(key): Observable<any> {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/DeleteTranslation?key=' + key, this.options);
    }
}

export class AppMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (!TranslationLoader.GeneralSettings) {
            let settings = localStorage.getItem("GeneralSettings");

            if (settings) {
                settings = JSON.parse(settings);
                TranslationLoader.GeneralSettings = settings;
            }
        }
        if (params) {
            let retVal = params.key;
            if (retVal) {
                if (retVal.startsWith('@@') && !TranslationLoader.GeneralSettings.ShowMissingTranslations) {
                    retVal = retVal.substring(2);
                }
                if (params.translateService && params.translateService.parser) {
                    retVal = params.translateService.parser.interpolate(retVal, params.interpolateParams);
                }
                return retVal;
            }
        }
        return '';
    }
}
