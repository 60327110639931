import { Component } from '@angular/core';
import { LevelType } from '../../../models/enums';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { DatadescriptionControl, OCMPDragInfo } from '../../dialogs/datadescription/datadescription.control';
import { ObjectCreatorSourceTreeBase, TreeNodeSource } from './objectcreatorsourcetree.base';

@Component({
    selector: 'objectcreatorsourcetreebase-level',
    templateUrl: './objectcreatorsourcetree.base.html',
    styleUrls: ['./objectcreatorsourcetree.css']
})
export class ObjectCreatorSourceTreeLevel extends ObjectCreatorSourceTreeBase {

    UpdateTreeSource(node) {
        this.TreeSource = [];
        if (this.DbItems && node == null) {
            let treeIdNumber = 0;
            this.DbItems.forEach(cubeDbItem => {
                const cubeNode = new TreeNodeSource(++treeIdNumber);
                this.NodeToDbItem.set(cubeDbItem.ID, cubeDbItem);
                cubeNode.Caption = cubeDbItem.Caption != null ? cubeDbItem.Caption : 'undefined';
                cubeNode.SID = cubeDbItem.ID;
                cubeNode.DataSourceID = cubeDbItem.DataSourceId;
                cubeNode.IsExpanded = false;
                cubeNode.Children = [];
                cubeNode.HasChildren = true;
                cubeNode.Icon = 'cube';
                this.TreeSource.push(cubeNode);
                cubeDbItem.Dimensions.forEach(dimDbItem => {
                    const dimNode = new TreeNodeSource(++treeIdNumber);
                    this.NodeToDbItem.set(dimDbItem.ID, dimDbItem);
                    dimNode.Caption = dimDbItem.Caption != null ? dimDbItem.Caption : 'undefined';
                    dimNode.SID = dimDbItem.ID;
                    dimNode.DataSourceID = dimDbItem.DataSourceId;
                    dimNode.IsExpanded = false;
                    dimNode.HasChildren = true;
                    dimNode.Children = [];
                    dimNode.Icon = 'dimension';
                    cubeNode.Children.push(dimNode);
                    if (dimDbItem.Hierarchies) {
                        dimDbItem.Hierarchies.forEach(hierarchy => {
                            const hierNode = new TreeNodeSource(++treeIdNumber);
                            this.NodeToDbItem.set(hierarchy.ID, hierarchy);
                            hierNode.Caption = hierarchy.Caption != null ? hierarchy.Caption : 'undefined';
                            hierNode.SID = hierarchy.ID;
                            hierNode.DataSourceID = dimDbItem.DataSourceId;
                            hierNode.IsExpanded = false;
                            hierNode.Icon = 'attribute';
                            hierNode.HasChildren = !hierarchy.IsAttributeHierarchy;

                            if (hierarchy.Levels && !hierarchy.IsAttributeHierarchy) {
                                hierNode.Children = [];
                                hierNode.HasChildren = true;
                                hierNode.Icon = 'hierarchy';
                                hierarchy.Levels.forEach(level => {
                                    const levelNode = new TreeNodeSource(++treeIdNumber);
                                    this.NodeToDbItem.set(level.ID, level);
                                    levelNode.Caption = level.Caption != null ? level.Caption : 'undefined';
                                    levelNode.SID = level.ID;
                                    levelNode.DataSourceID = dimDbItem.DataSourceId;
                                    levelNode.IsExpanded = false;
                                    levelNode.HasChildren = false;
                                    levelNode.Icon = 'dimension';
                                    hierNode.Children.push(levelNode);
                                });
                            }
                            if (hierNode.Children.length === 1) { // nur ein Kind
                                dimNode.Children.push(hierNode.Children[0]);
                            } else {
                                dimNode.Children.push(hierNode);
                            }
                        });
                    }
                });
            });
            this.SortTreeListAlphabetical(this.TreeSource, false);
            this.TreeSource.forEach(x => {
                if (x.Children) {
                    this.SortTreeListAlphabetical(x.Children, false);
                    x.Children.forEach(y => {
                        if (y.Children) {
                            this.SortTreeListAlphabetical(y.Children, false);
                        }
                    });
                }
            });
        }
    }

    dragStart(event) {
        if (event && event.DragData && event.DragData.length > 0) {
            const node = event.DragData[0];
            if (node) {
                const dbItem = this.NodeToDbItem.get(node.SID);
                const dropItems = this.GetAllDropItems(dbItem);
                if (dropItems && dropItems.length > 0) {
                    const data = {
                        DraggedID: node.SID,
                        DropIDs: []
                    };
                    dropItems.forEach(item => {
                        data.DropIDs.push(item.ID);
                    });
                    DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.Level, data));
                    
                }
            }
        }
    }

    GetAllDropItems(dbItem) {
        let dropItems = [];
        if (dbItem.Dimensions) {
            dbItem.Dimensions.forEach(dim => {
                const r = this.GetAllDropItems(dim);
                if (r) {
                    dropItems.push(...r);
                }
            });
        } else {
            if (dbItem.Hierarchies || dbItem.Attributes) {
                if (dbItem.Hierarchies) {
                    dbItem.Hierarchies.forEach(h => {
                        let hierLevels = [];
                        h.Levels.forEach(l => {
                            hierLevels.push(l);
                        });
                        if (hierLevels.length > 1) {
                            hierLevels = hierLevels.filter(x => x.LevelType !== LevelType.All);
                        }
                        hierLevels.forEach(hl => {
                            if (!dropItems.find(di => di.ID === hl.ID)) {
                                dropItems.push(hl);
                            }
                        });
                    });
                }
            } else if (dbItem.Levels) {
                dbItem.Levels.forEach(l => {
                    dropItems.push(l);
                });
                if (dropItems.length > 1) {
                    dropItems = dropItems.filter(x => x.LevelType !== LevelType.All);
                }
            } else {
                dropItems.push(dbItem);
            }
        }
        return dropItems;
    }

    dragEnd(ev) {
        ObjectCreatorSourceTreeLevel.DragEnd.next(null);
        DatadescriptionControl.ObjectCreatorMPDragStart.next(null);
    }
}
