import { Component } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { CumulateTaskExecuter } from '../../../models/tasks/datatasks/cumulate.model';
import { ALevelMeasurePanel, AMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'cumulate-task-panel',
    templateUrl: './cumulate.task.panel.html'
})
export class CumulateTaskPanel extends ALevelMeasurePanel {
    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = CumulateTaskExecuter.TaskID;
        reg.Caption = '@@Kumulieren';
        reg.SettingsControl = CumulateTaskPanel;
        reg.SettingsHelper = new CumulateSettingsHelper();
        reg.Executer = CumulateTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

    ngOnInit(): void {
        this.Levels.push({
            Caption: TranslateHelper.TranslatorInstance.instant('@@Keine Auswahl'),
            Value: -1
        });
        super.ngOnInit();

    }
}

export class CumulateSettingsHelper extends AMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Kumulieren');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Kumulieren');
    }
}
