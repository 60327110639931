import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MediaSource } from '../models/datamodel/mediasource.model';
import { ThemeHelper } from '../helpers/theme.helpers';

@Injectable()
export class MediaService extends RequestBase {


    static IconSourceID = '8fbbd705-6aae-4070-a6bb-472b45c1bebd';
    static FontSourceID = '7f6cda84-bdf8-4030-a554-dca723b11067';
    static MediaSources: BehaviorSubject<MediaSource[]> = new BehaviorSubject(null);
    static IconURL: BehaviorSubject<string> = new BehaviorSubject(null);
    static ImageURL: BehaviorSubject<string> = new BehaviorSubject(null);
    static IconList: BehaviorSubject<string[]> = new BehaviorSubject(null);
    static GadgetList: BehaviorSubject<string[]> = new BehaviorSubject(null);
    static FontURL: BehaviorSubject<string> = new BehaviorSubject(null);
    static CustomFontList: BehaviorSubject<string[]> = new BehaviorSubject(null);
    static CSSFontList: BehaviorSubject<string[]> = new BehaviorSubject(null);
    static ProfilePicURL: BehaviorSubject<string> = new BehaviorSubject(null);
    static FontSizeList = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];


    BasePath = 'config/api/media';
    IconBasePath = 'api/addon';
    static GetDefaultFonts(): string[] {
        return ['Arial', 'Arial Black', 'Arial Unicode MS', 'Calibri', 'Cambria', 'Cambria Math', 'Comic Sans MS', 'Candara',
            'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Georgia', 'Lucida Sans Unicode', 'Portable User Interface', 'Segoe UI',
            'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Wingdings', 'Wingdings 2', 'Wingdings 3'];
    }
    static GetFontList(): string[] {
        let retVal = MediaService.CustomFontList.getValue();
        if (!retVal || retVal.length === 0) {
            retVal = ['Arial', 'Arial Black', 'Arial Unicode MS', 'Calibri', 'Cambria', 'Cambria Math', 'Comic Sans MS', 'Candara',
                'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Georgia', 'Lucida Sans Unicode', 'Portable User Interface', 'Segoe UI',
                'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Wingdings', 'Wingdings 2', 'Wingdings 3'];
        } else {
            if (ThemeHelper.CustomFonts) {
                retVal.push(...ThemeHelper.CustomFonts);
            }
        }
        return retVal;
    }
    static GetCustomFontList(): string[] {
        let retVal = [];
        if (ThemeHelper.CustomFonts) {
            retVal.push(...ThemeHelper.CustomFonts);
        }
        return retVal;
    }

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    Get(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/Get', this.options);
    }

    GetEditable(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetEditable', this.options);
    }
    GetUsable(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetUsable', this.options);
    }
    GetAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAll', this.options);
    }

    Save(model: MediaSource): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save', model, this.options);
    }

    Delete(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Delete?id=' + id, this.options);
    }

    GetMediaSource(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetMediaSource?sid=' + id, this.options);
    }
    LoadImage(id, filename): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetImage?MediaSourceID=' + id + '&fileName=' + filename, this.options);
    }
    UploadFile(MediaSourceId: string, form: any): Observable<any> {
        const req = new HttpRequest('POST', this.API_BASE_URL + this.BasePath + '/UploadFile?MediaSourceID=' + MediaSourceId, form);
        return this.http.request(req);
    }
    UploadSpecific(MediaSourceId: string, form: any): Observable<any> {
        const req = new HttpRequest('POST', this.API_BASE_URL + this.BasePath + '/UploadSpecific?MediaSourceID=' + MediaSourceId, form, {
            reportProgress: true
        });
        return this.http.request(req);
    }
    ListObjects(MediaSourceId: string, Prefix: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ListObjects?MediaSourceID=' + MediaSourceId + '&Prefix=' + Prefix,
            this.options);
    }
    CreateFolder(MediaSourceId: string, Prefix: string): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CreateFolder?MediaSourceID=' + MediaSourceId + '&Prefix=' + Prefix,
            null, this.options);
    }
    DeleteFile(MediaSourceId: string, Prefix: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteFile?MediaSourceID=' + MediaSourceId + '&Prefix=' + Prefix,
            this.options);
    }
    LoadAllIcons(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadAllIcons', this.options);
    }
    LoadAllFonts(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/LoadAllFonts', this.options);
    }
    GetProfilePicURL(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetProfilePicURL', this.options);
    }
    GetMediaClientTypes(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetMediaClientTypes', this.options);
    }

    CheckConnection(MediaSource: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CheckConnection', MediaSource, this.options);
    }


    private getEventMessage(event: HttpEvent<any>, form: FormData) {
        form.forEach((value) => {
            switch (event.type) {
                case HttpEventType.Sent:
                    return `Uploading file '${value['name']}' of size ${value['size']}.`;

                case HttpEventType.UploadProgress:
                    // Compute and show the % done:
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    return `File '${value['name']}' is ${percentDone}% uploaded.`;

                case HttpEventType.Response:
                    return `File '${value['name']}' was completely uploaded!`;

                default:
                    return `File '${value['name']}' surprising upload event: ${event.type}.`;
            }
        });

    }

    GetAddonIcons(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.IconBasePath + '/icons', this.options);
    }

    UploadAddonIcons(data): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers,
        };
        return this.http.post<any>(this.API_BASE_URL + this.IconBasePath + '/icons', data,  httpOptions);
    }

    UpdateAddonIcons(data, id): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers,
        };
        return this.http.put<any>(this.API_BASE_URL + this.IconBasePath + '/icons/' + id, data,  httpOptions);
    }

    DeleteAddonIcons(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.IconBasePath + '/icons/' + id,  this.options);
    }

}
