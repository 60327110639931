import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { IBaseComponent } from '../../controls/base.component';
import { WorkflowEditOptions } from '../../../workflow/workflow.control';

@Component({
    selector: 'workflow-settings-component',
    templateUrl: './workflow.static.control.html'
})
export class WorkflowStaticControl extends IBaseComponent {
    static Type: any = 'workflow';
    static Default = { Type: 'workflow' };

    ShowControl = true;

    //#region EditOptions
    EditOptions = new WorkflowEditOptions();
    //#endregion

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        if (val !== this.DataSourceValue) {
            this.ShowControl = false;
            this.DataSourceValue = val;
            window.setTimeout(() => {
                this.ShowControl = true;
            }, 100);
            this.DataSourceChange.emit(this.DataSourceValue);
        }
    }

    constructor(cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.EditOptions.IsService = true;
    }
    onLayoutElementSet() {
        this.Subscriptions['ValuesChanged'] = this.LayoutElementValue.ValuesChanged.subscribe(() => {
            this.ControlInitialized();
        });
    }
    onLayoutElementChanged() {
        this.ControlInitialized();
    }

    ControlInitialized() {
        if (this.LayoutElement) {
            const EditOptions = new WorkflowEditOptions();
            EditOptions.IsService = this.LayoutElement.IsService ? true : false;
            EditOptions.IsTemplate = this.LayoutElement.IsTemplate ? true : false;
            this.EditOptions = EditOptions;
            setTimeout(() => {
                this.cdRef.detectChanges();
            }, 200);
            this.cdRef.detectChanges();
        }
    }
}
