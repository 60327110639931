import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class DynamicEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/dynamic/';
    EndPoints = [
        //#region GetAllTables
        {
            Name: 'GetAllStaticTables',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Layout'].filter((value) => {
                        return value._Id == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetAllTables
        {
            Name: 'GetAllTables',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetAllEnums
        {
            Name: 'GetAllEnums',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerEnum'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetNonCCOContainersByDataModel
        {
            Name: 'GetNonCCOContainersByDataModel',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    // liefert eigentlich das falsche Ergebnis... (NonCCO!!!)
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.DataModelID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetTablesByDataModel
        {
            Name: 'GetTablesByDataModel',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.DataModelID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetContainersByDataModel
        //{
        //    Name: 'GetContainersByDataModel',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Navigation'].toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
         //#endregion
        //#region GetEnumsByDataModel
        {
            Name: 'GetEnumsByDataModel',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerEnum'].filter((value) => {
                        return value.DataModelID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetTablesByDataSource
        {
            Name: 'GetTablesByDataSource',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.DataModelID == params['dataModel'] && value.DataSourceId == params['dataSource'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.TranslatedCaption,
                                    Value: value.SID
                                });
                            });
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
         //#endregion
        //#region GetContainersByDataSource
        //{
        //    Name: 'GetContainersByDataSource',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Navigation'].toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
         //#endregion
        //#region GetDynamicTypes
        //{
        //    Name: 'GetDynamicTypes',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Navigation'].toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //#endregion
        //#region GetTable
        {
            Name: 'GetTable',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.SID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetFlatTable
        {
            Name: 'GetFlatTable',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.SID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let table = values[0];
                            OfflineHelper.FillFields(table).then((retVal) => {
                                observer.next(retVal);
                            });
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetEnum
        {
            Name: 'GetEnum',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerEnum'].filter((value) => {
                        return value.SID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            observer.next(values[0]);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetEnumValues
        {
            Name: 'GetEnumValues',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerEnum'].filter((value) => {
                        return value.SID == params['id']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let enu = values[0];
                            let retVal = [];
                            if (enu.Fields) {
                                enu.Fields.forEach((value) => {
                                    retVal.push({
                                        Caption: value.TranslatedCaption,
                                        Value: value.ID,
                                        Name: value.Name,
                                        Translation: value.Caption
                                    });
                                });
                            }
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        //#region GetTableEntries
        //{
        //    Name: 'GetTableEntries',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Navigation'].toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //#endregion
        //#region GetDimensions
        //{
        //    Name: 'GetDimensions',
        //    execute(params?: any, body?: any) {
        //        return new Observable((observer) => {
        //            OfflineHelper.GetDB()['evidanza.App.Shared.Permissions.Navigation'].toArray().then((values) => {
        //                if (values && values.length > 0) {
        //                    observer.next(values[0]);
        //                } else {
        //                    observer.next(null);
        //                }
        //            })
        //        });
        //    }
        //},
        //#endregion
    ]
}