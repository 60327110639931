import { Component, OnInit } from '@angular/core';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';

@Component({
    selector: 'related-attributes-dialog',
    templateUrl: 'related.attributes.dialog.html',
    styleUrls: ['related.attributes.dialog.css']
})
export class RelatedAttributeDialog implements OnInit {
    Attributes = [];
    LeftAttr;
    RightAttr;

    ngOnInit(): void {
    }

    OnDialogButtonClickAction(button) {
        if (button == DialogButton.Ok) {
            if (!this.LeftAttr && !this.RightAttr) {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie ein Quell- und ein Ziel-Attribut'));
                return false;
            }
        }
        return true;
    }

    Initialize(args) {
        if (args) {
            this.Attributes = args;
        }
    }

    GetDialogResult() {
        return {
            LeftAttr: this.LeftAttr.ID,
            RightAttr: this.RightAttr.ID
        };
    }

    getOtherAttributes(attr) {
        var retVal = [];
        if (this.Attributes) {
            if (!attr) {
                return this.Attributes;
            }
            this.Attributes.forEach(function (da) {
                if (da != attr && (!attr.RelatedAttributes || !attr.RelatedAttributes.some(function (ra) { return ra == da.ID; }))) {
                    retVal.push(da);
                }
            });
        }
        return retVal;
    }
}