import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, serialize } from 'class-transformer';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { ResourceType } from '../saveresource/save.resource.settings';

@Component({
    selector: 'wf-write-to-tcp-settings-item',
    templateUrl: 'write.to.tcp.settings.html'
})
export class WriteToTCPSettings extends FormulaWorkflowDialogContent {
    Data = new WriteToTCPSettingsData();
    VariableNodes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'writeToTCPWFModule';
        reg.Caption = '@@Per TCP senden';
        reg.GroupID = '-1';
        reg.Index = 20;
        reg.SettingsControl = WriteToTCPSettings;
        reg.SettingsTypeHelper = new WriteToTCPSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private translate: TranslateService) {
        super();
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(WriteToTCPSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        const errors = [];
        if (!this.Data.Host) {
            errors.push(this.translate.instant('@@Bitte definieren Sie einen Host'));
        }
        if (!this.Data.Value) {
            switch (this.Data.ResourceType) {
                case ResourceType.Bytes:
                    errors.push(this.translate.instant('@@Bitte setzen Sie einen Status Key'));
                    break;
                case ResourceType.Text:
                    if (this.Data.GetFromStatus) {
                        errors.push(this.translate.instant('@@Bitte setzen Sie einen Status Key'));
                    } else {
                        errors.push(this.translate.instant('@@Bitte definieren Sie den Datei-Inhalt'));
                    }
                    break;
            }
        }
        if (errors.length > 0) {
            retVal.IsCorrect = false;
            retVal.Error = errors.join('\n');
        }
        return retVal;
    }

    getResult() {
        if (this.Data.ResourceType === ResourceType.Bytes) {
            this.Data.GetFromStatus = true;
        }
        return this.Data;
    }

    onHostSelect(event) {
        if (event) {
            this.Data.Host = event.Caption;
        }
    }
}

export class WriteToTCPSettingsData extends AServiceWorkflowData {
    Host: string;
    Port = 0;
    GetHostFromStatus = false;
    ResourceType: ResourceType = ResourceType.Text;
    GetFromStatus = false;
    Value: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.FileOperations.WriteToTCP.WriteToTCPSettingsData';
    }
}

export class WriteToTCPSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new WriteToTCPSettingsData();
    }
}
