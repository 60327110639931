import { Component } from '@angular/core';
import { TranslateHelper } from '../../../../helpers/injector.helper';
import { WebHookService } from '../../../../services/webhook.service';
import { BaseTriggerControl } from './base.trigger.control';

@Component({
    selector: 'webhooktrigger-control',
    templateUrl: './webhook.trigger.control.html',
    styleUrls: ['./webhook.trigger.control.css']
})
export class WebHookTriggerControl extends BaseTriggerControl {
    Webhooks;



    getwebhookinfotext(){
        if (this.TriggerValue) {
            return TranslateHelper.TranslatorInstance.instant('Neue Webhooks bitte bei Einstellung -> Restschnittstellen -> Webhooks erstellen.');
        }
        else {
            return TranslateHelper.TranslatorInstance.instant('Die Webhookdefinition ist bei Einstellung -> Restschnittstellen -> Webhooks ersichtlich.');
        }
    }

    constructor(private service: WebHookService) {
        super();
    }


    ngOnInit(): void {
        this.service.GetAll().subscribe(r => {
            if (r) {
                this.Webhooks = r;
            }
        });
    }

    onPageChanged(ev) {
        //if (ev) {
        //    if (ev.value === '') {
               
        //        WebHookDialog.ShowDialog((retVal) => {
        //            if (retVal) {
        //                this.TriggerValue.WebHookId = retVal.SID;

        //                const newwh = {
        //                    Caption: retVal.Name,
        //                    SID: retVal.SID
        //                };
                       
        //                this.Webhooks.push(newwh);
        //            }
        //            return true;
        //        });
        //    }
        //}
    }
}
  

