import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ClientHelper } from '../helpers/client.helper';
import { GitHelper } from '../helpers/git.helper';
import { TranslateHelper } from '../helpers/injector.helper';
import { NotificationHelper } from '../helpers/notification.helper';
import { PermissionHelper } from '../helpers/permissions.helper';
import { InterceptorSkipHeader } from './interceptor.skip.header';
import { LayoutService } from './layout.service';
import { MediaService } from './media.service';
import { SideNavService } from './sidenav.service';
import { UsersService } from './users.service';
import configData from '../../assets/config.json';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // private systemIconURL = "https://s3.ap-southeast-1.amazonaws.com/mediasource/";


    static TryFetch(path: string, actMethod: 'DELETE' | 'GET' | 'POST' | 'PUT', body?) {
        const init: RequestInit = {
            method: actMethod,
            keepalive: true
        };
        const credentials = JSON.parse(localStorage.getItem('credentials'));
        let newHeaders;
        if (credentials) {
            newHeaders = BasicAuthInterceptor.GetHeaders('Basic ' + btoa(credentials.UserName + ':' + credentials.Password));
            newHeaders.AuthProvider = credentials.ProviderId;
        } else {
            let auth = null;
            const token = localStorage.getItem('token');
            if (token) {
                auth = 'Bearer ' + token;
            }
            newHeaders = BasicAuthInterceptor.GetHeaders(auth);
        }
        newHeaders['Content-Type'] = 'application/json';
        init.headers = newHeaders;
        if (body && (actMethod === 'POST' || actMethod === 'PUT')) {
            init.body = JSON.stringify(body);
        }
        return fetch(path, init);
    }

    private static GetHeaders(authorization: string) {
        const newHeaders: any = {
            ClientID: ClientHelper.ClientID
        };
        if (authorization) {
            newHeaders.Authorization = authorization;
        }
        const impersonate = PermissionHelper.Impersonate.getValue();
        if (impersonate && impersonate.Roles && impersonate.Roles.length > 0) {
            newHeaders.ImpersonateRoles = impersonate.Roles.join(',');
        }
        const application = SideNavService.SelectedNavigationStructure.getValue();
        if (application) {
            newHeaders.Application = application;
        }
        const lang = TranslateHelper.TranslatorInstance.currentLang;
        if (lang) {
            newHeaders.ClientLanguage = lang;
        }
        if (GitHelper.IsActive) {
            const repository = GitHelper.SelectedRepository.getValue();
            if (repository) {
                newHeaders.Repository = repository;
            }
        }
        return newHeaders;
    }

    constructor(private usersService: UsersService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Icons ignorieren
        const iconURL = MediaService.IconURL.getValue();
        if (iconURL && request.url.startsWith(iconURL)) {
            return next.handle(request);
        }
        // ignore addon icons
        const addonIconURL = this.usersService.minioIconLink?.getValue();
        if (addonIconURL && request.url.startsWith(addonIconURL)) {
            return next.handle(request);
        }

        // Aufrufe, die die Authentifizierung �berspringen sollen
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            return next.handle(request.clone({ headers }));
        }

        //#region BasicAuth
        const credentials = JSON.parse(localStorage.getItem('credentials'));
        if (credentials) {
            const newHeaders = BasicAuthInterceptor.GetHeaders('Basic ' + btoa(credentials.UserName + ':' + credentials.Password));
            newHeaders.AuthProvider = credentials.ProviderId;
            request = request.clone({
                setHeaders: newHeaders
            });
            return next.handle(request);
        }
        //#endregion

        //#region TokenAuth
        const token = localStorage.getItem('token');
        if (token) {
            if (request.url.indexOf('/config/api/users/refreshtoken?refreshtoken') >= 0) {
                return next.handle(this.addAuthenticationToken(request)).pipe(map((event: HttpEvent<any>) => {
                    return this.CheckResponse(event);
                })).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                    return this.CheckResponse(err);
                }));
            }
            if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                    filter(result => result !== null)).pipe(take(1))
                    .pipe(switchMap(() => next.handle(this.addAuthenticationToken(request))))
                    .pipe(map((event: HttpEvent<any>) => {
                        return this.CheckResponse(event);
                    })).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                        return this.CheckResponse(err);
                    }));
            } else {
                const expiresat = moment(localStorage.getItem('expiresat'));
                const actMoment = moment().add(5, 'm');
                if (expiresat < actMoment && !(request.url.indexOf('/config/api/users/refreshtoken?refreshtoken') >= 0) &&
                    !this.refreshTokenInProgress) {
                    this.refreshTokenInProgress = true;
                    this.refreshTokenSubject.next(null);
                    return this.usersService.refreshToken()
                        .pipe(switchMap((value: any) => {
                            UsersService.SetToken(value);
                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(token);
                            return next.handle(this.addAuthenticationToken(request))
                                .pipe(map((event: HttpEvent<any>) => {
                                    return this.CheckResponse(event);
                                })).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                                    return this.CheckResponse(err);
                                }));
                        })).pipe(catchError((err: any) => {
                            this.refreshTokenInProgress = false;

                            this.usersService.logout();
                            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                                return this.CheckResponse(event);
                            })).pipe(tap((event: HttpEvent<any>) => { }, (err2: any) => {
                                return this.CheckResponse(err2);
                            }));
                        }));
                } else {
                    return next.handle(this.addAuthenticationToken(request)).pipe(map((event: HttpEvent<any>) => {
                        return this.CheckResponse(event);
                    })).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                        const startUpComplete = ClientHelper.StartUpCompleted.getValue();
                        return this.CheckResponse(err, !startUpComplete);
                    }));
                }
            }
        }
        //#endregion

        //#region NoAuth
        if (!credentials && !token) {
            const newHeaders = BasicAuthInterceptor.GetHeaders(null);
            request = request.clone({
                setHeaders: newHeaders
            });
            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                return this.CheckResponse(event);
            })).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                return this.CheckResponse(err);
            }));
        }
        //#endregion
    }

    CheckResponse(event, logout = true): HttpResponse<any> {
        if (event instanceof HttpResponse) {
            if (event.body && typeof event.body.Status === 'number') {
                if (event.body.Status > 0) {
                    // NotificationHelper.Error(event.body.StackTrace, event.body.Message);
                    const error = event['error'] || null;
                    this.ShowErrorNotification(error);
                    LayoutService.Loading.next(false);
                }
                let retVal;
                if (typeof event.body.Data === 'undefined') {
                    retVal = event.clone({
                        body: null
                    });
                } else {
                    retVal = event.clone({
                        body: event.body.Data
                    });
                }
                return retVal;
            }
        } else if (event instanceof HttpErrorResponse) {
            if (logout && event.status === 401) {
                this.usersService.logout();
            } else {
                if (event.error && typeof event.error.Status === 'number' &&
                    typeof event.error.StackTrace === 'string' && typeof event.error.Message === 'string') {
                    const error = event['error'] || null;
                    this.ShowErrorNotification(error);
                    // NotificationHelper.Error(event.error.StackTrace, event.error.Message);
                } else {
                    // NotificationHelper.Error(event.message, '@@RequestFailed');
                    const error = event['error'] || null;
                    this.ShowErrorNotification(error);
                }
                LayoutService.Loading.next(false);
            }
        }
        return event;
    }

    addAuthenticationToken(request) {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            return request;
        }
        const newHeaders = BasicAuthInterceptor.GetHeaders('Bearer ' + accessToken);
        return request.clone({
            setHeaders: newHeaders
        });
    }

    ShowErrorNotification(error) {
        if(error?.Error) {
            NotificationHelper.Error('@@' + error.Message, '@@Error');
        }
        else {
            NotificationHelper.Error('@@An unexpected error has occurred, please contact support', '@@System Error');
        }
    }
}
