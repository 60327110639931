import { Component } from '@angular/core';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { TypeNameLoader } from '../../../../components/controls/layout.tree';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-closedropdown-settings',
    templateUrl: './close.dropdown.settings.html'
})
export class CloseDropDownSettings extends WorkflowDialogContent {
    ElemName: string;
    NodeLoader: TypeNameLoader;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'closeDropDownWFModule';
        reg.Caption = '@@Dropdown schliessen';
        reg.GroupID = 'wfActions';
        reg.Index = 70;
        reg.SettingsControl = CloseDropDownSettings;
        reg.SettingsTypeHelper = new CloseDropDownSettingsDataHelper();
        reg.Executer = CloseDropDownModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.HasExpressions = true;
        this.NodeLoader = new TypeNameLoader();
        this.NodeLoader.TypeNames.push('dropdown');
    }

    initialize(data: any) {
        if (data && data.ElemName) {
            this.ElemName = data.ElemName;
        }
    }

    getResult(): any {
        const retVal = new CloseDropDownSettingsData();
        retVal.ElemName = this.ElemName;
        return retVal;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }
}

export class CloseDropDownSettingsData {
    ElemName: string;
}

export class CloseDropDownSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEmptySettingsInstance() {
        return new CloseDropDownSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class CloseDropDownModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        if (typeof elem.Element.close === 'function') {
                            elem.Element.close();
                        }
                        return 0;
                    } else {
                        status.Logger.logError('CloseDropDown modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('CloseDropDown modul: Element not found.');
                }
            } else {
                status.Logger.logError('CloseDropDown modul: No element name found.');
            }
        } else {
            status.Logger.logError('CloseDropDown modul: No settings found.');
        }
        return super.execute(status);
    }
}
