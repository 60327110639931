import { Component } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';
import { SendMessageVariable } from './sendmessage.settings';

@Component({
    selector: 'wf-trigger-message-settings',
    templateUrl: './trigger.message.settings.html'
})
export class TriggerMessageSettings extends FormulaWorkflowDialogContent {
    Data = new TriggerMessageSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'triggerMessageWFModule';
        reg.Caption = '@@Nachricht triggern';
        reg.GroupID = 'wfActions';
        reg.Index = 125;
        reg.SettingsControl = TriggerMessageSettings;
        reg.SettingsTypeHelper = new TriggerMessageSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        reg.ObsoleteInfo = new ObsoleteInfo();
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(TriggerMessageSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }

    addVariable() {
        if (this.Data) {
            if (this.Data.Variables) {
                this.Data.Variables.push(new SendMessageVariable());
            } else {
                this.Data.Variables = [new SendMessageVariable()];
            }
        }
    }

    removeVariable(i) {
        if (this.Data && this.Data.Variables) {
            this.Data.Variables.splice(i, 1);
        }
    }
}

// @dynamic
export class TriggerMessageSettingsData extends AServiceWorkflowData {
    TriggerID = UUID.UUID();
    @Type(() => SendMessageVariable)
    Variables: SendMessageVariable[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.SendMessage.TriggerMessageSettingsData';
    }
}

export class TriggerMessageSettingsDataHelper extends WorkflowModuleSettingsHelper  {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new TriggerMessageSettingsData();
    }
}
