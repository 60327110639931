import { BorderSide } from './borderside.model';
import { Type } from 'class-transformer';
import { BorderRadius } from './borderradius.model';

// @dynamic
export class Border {
    @Type(() => BorderSide)
    LeftBorder: BorderSide = new BorderSide();
    @Type(() => BorderSide)
    TopBorder: BorderSide = new BorderSide();
    @Type(() => BorderSide)
    RightBorder: BorderSide = new BorderSide();
    @Type(() => BorderSide)
    BottomBorder: BorderSide = new BorderSide();
    @Type(() => BorderRadius)
    BorderRadius: BorderRadius = new BorderRadius();
    toJSON() {
        if ((this.LeftBorder && this.LeftBorder.toJSON()) ||
            (this.TopBorder && this.TopBorder.toJSON()) ||
            (this.RightBorder && this.RightBorder.toJSON()) ||
            (this.BottomBorder && this.BottomBorder.toJSON()) ||
            this.BorderRadius.toJSON()) {
            return this;
        } else {
            return null;
        }
        
    }
    isValid() {
        return (this.LeftBorder && this.LeftBorder.isValid()) || (this.TopBorder && this.TopBorder.isValid()) ||
            (this.RightBorder && this.RightBorder.isValid()) || (this.BottomBorder && this.BottomBorder.isValid());
    }
}
export class BorderInfo {
    HasTopBorder = false;
    TopBorderStyle: string;
    HasBottomBorder = false;
    BottomBorderStyle: string;
    HasLeftBorder = false;
    LeftBorderStyle: string;
    HasRightBorder = false;
    RightBorderStyle: string;
    BorderStyle = {};

    static FromBorder(border: Border): BorderInfo {
        const retVal = new BorderInfo();
        if (border) {
            if (border.LeftBorder) {
                retVal.LeftBorderStyle = border.LeftBorder.toStyle();
                if (retVal.LeftBorderStyle !== '') {
                    retVal.BorderStyle['border-left'] = retVal.LeftBorderStyle;
                    retVal.HasLeftBorder = true;
                }
            }
            if (border.TopBorder) {
                retVal.TopBorderStyle = border.TopBorder.toStyle();
                if (retVal.TopBorderStyle !== '') {
                    retVal.BorderStyle['border-top'] = retVal.TopBorderStyle;
                    retVal.HasTopBorder = true;
                }
            }
            if (border.RightBorder) {
                retVal.RightBorderStyle = border.RightBorder.toStyle();
                if (retVal.RightBorderStyle !== '') {
                    retVal.BorderStyle['border-right'] = retVal.RightBorderStyle;
                    retVal.HasRightBorder = true;
                }
            }
            if (border.BottomBorder) {
                retVal.BottomBorderStyle = border.BottomBorder.toStyle();
                if (retVal.BottomBorderStyle !== '') {
                    retVal.BorderStyle['border-bottom'] = retVal.BottomBorderStyle;
                    retVal.HasBottomBorder = true;
                }
            }
            if (border.BorderRadius) {
                if (border.BorderRadius.TopLeft && border.BorderRadius.TopLeft.toStyle() !== '') {
                    retVal.BorderStyle['border-top-left-radius'] = border.BorderRadius.TopLeft.toStyle();
                }
                if (border.BorderRadius.TopRight && border.BorderRadius.TopRight.toStyle() !== '') {
                    retVal.BorderStyle['border-top-right-radius'] = border.BorderRadius.TopRight.toStyle();
                }
                if (border.BorderRadius.BottomLeft && border.BorderRadius.BottomLeft.toStyle() !== '') {
                    retVal.BorderStyle['border-bottom-left-radius'] = border.BorderRadius.BottomLeft.toStyle();
                }
                if (border.BorderRadius.BottomRight && border.BorderRadius.BottomRight.toStyle() !== '') {
                    retVal.BorderStyle['border-bottom-right-radius'] = border.BorderRadius.BottomRight.toStyle();
                }
            }
        }
        return retVal;
    }
}