import { Component, OnInit } from '@angular/core';
import {TourService} from "ngx-ui-tour-md-menu";

@Component({
  selector: 'lib-tour-step-template',
  templateUrl: './tour-step-template.component.html',
  styleUrls: ['./tour-step-template.component.css']
})
export class TourStepTemplateComponent implements OnInit {

  constructor(public tourService: TourService) { }

  ngOnInit(): void {
  }

}
