import { Component } from '@angular/core';
import { TaskRegistry } from '../../../helpers/task.registry';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';

@Component({
    selector: 'delete-task-dialog',
    templateUrl: './delete.task.dialog.html',
    styles: ['.imageDiv { margin: 10px 0px 10px 10px; }', '.textDiv { margin: 11px; }']
})
export class DeleteTaskDialog {

    InitArgs;
    Text;
    CustomButtons = [
        { Caption: '@@Tasks loeschen', ID: 1 },
        { Caption: '@@Tasks inaktiv setzen', ID: 2 }
    ];
    CloseOnCustomButton = true;
    Result;
    
    static CheckTasks(dataDescription, uniqueID, isMeasure) {
        const captionList = [];
        (isMeasure ? dataDescription.Tasks.filter(x => x.MeasureUniqueID === uniqueID) :
            dataDescription.Tasks.filter(x => x.LevelUniqueID === uniqueID)).forEach(t => {
                let caption = t.TaskType;
                const desc = TaskRegistry.get(t.TaskType);
                if (desc && desc.SettingsHelper) {
                    caption = desc.SettingsHelper.getCaption(t, dataDescription);
                }
                captionList.push(caption);
            });
        if (captionList.length > 0) {
            BaseDialog.ShowDialog({
                ContentType: DeleteTaskDialog,
                InitArgs: {
                    CaptionList: captionList,
                    Tasks: dataDescription.Tasks.slice(),
                    ID: uniqueID,
                    IsMeasure: isMeasure
                },
                Buttons: DialogButton.None,
                Title: '@@Tasks loeschen oder inaktiv setzen',
                Handler: (r) => {
                    if (r) {
                        dataDescription.Tasks = r;
                    }
                    return true;
                }
            });
        }
    }

    Initialize(args) {
        if (args) {
            this.InitArgs = args;
            this.Text = args.IsMeasure ? '@@Die folgenden Tasks sind vom Loeschen der Werte betroffen:' :
                '@@Die folgenden Tasks sind vom Loeschen der Level betroffen:';
        }
    }

    GetDialogResult() {
        return this.Result;
    }

    OnCustomButtonClicked(but) {
        if (this.InitArgs && this.InitArgs.Tasks) {
            switch (but.ID) {
                case 1: // Loeschen
                    if (this.InitArgs.IsMeasure) {
                        this.Result = this.InitArgs.Tasks.filter(x => x.MeasureUniqueID !== this.InitArgs.ID);
                    } else {
                        this.Result = this.InitArgs.Tasks.filter(x => x.LevelUniqueID !== this.InitArgs.ID);
                    }
                    break;
                case 2: // Inaktiv
                    if (this.InitArgs.IsMeasure) {
                        this.InitArgs.Tasks.forEach(x => {
                            if (x.MeasureUniqueID === this.InitArgs.ID) {
                                x.IsActive = false;
                            }
                        });
                    } else {
                        this.InitArgs.Tasks.forEach(x => {
                            if (x.LevelUniqueID === this.InitArgs.ID) {
                                x.IsActive = false;
                            }
                        });
                    }
                    this.Result = this.InitArgs.Tasks;
                    break;
            }
        }
    }
}
