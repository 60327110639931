import { Component } from '@angular/core';
import { RestService } from '../../../services/rest.service';
import { BaseListDetail } from '../../../settings/base.list.settings';
import { ImportFile } from '../models/import.init.args';

@Component({
    selector: 'rest-overview',
    templateUrl: 'rest.overview.component.html',
    styleUrls: ['rest.overview.component.css']
})
export class RestOverviewComponent extends BaseListDetail {

    constructor(private _restService: RestService) {
        super();
    }

    public async fileChangeAsync(event: FileList) {
        if (event.length > 0) {
            const file = event.item(0);
            const reader = new FileReader();
            reader.onload = () => {
                const sendObject = new ImportFile();
                sendObject.MimeType = file.type;
                sendObject.Title = file.name;
                sendObject.RestID = this.SelectedItem.SID;
                sendObject.Text = reader.result.toString().trim();
                this._restService.fileImport(sendObject).subscribe();
            };
            reader.readAsText(file);
        }
    }
}
