import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationLevel } from '../../../models/enums/notificationlevel.enum';
import { TriggerQueueState } from '../../../models/enums/triggerqueuestate.enum';
import { SchedulerService } from '../../../services/scheduler.service';
import { WebsocketService } from '../../../services/websocket.service';
import { JobInfoDialog } from './job.info.dialog';
import config from '../../../../assets/config.json'
@Component({
    selector: 'jobs-queue',
    templateUrl: './jobs.queue.html',
    styleUrls: ['./jobs.queue.css']
})
export class JobsQueueComponent implements OnInit, OnDestroy {
    //#region AllUsers
    AllUsersValue = false;
    @Input()
    get AllUsers() {
        return this.AllUsersValue;
    }
    set AllUsers(val) {
        this.AllUsersValue = val;
        this.AllUsersChange.emit(this.AllUsersValue);
    }

    @Output() AllUsersChange = new EventEmitter<any>();
    //#endregion

    //#region ShowAllUsers
    ShowAllUsersValue = true;

    @Input()
    get ShowAllUsers() {
        return this.ShowAllUsersValue;
    }
    set ShowAllUsers(val) {
        this.ShowAllUsersValue = val;
        this.ShowAllUsersChange.emit(this.ShowAllUsersValue);
    }

    @Output() ShowAllUsersChange = new EventEmitter<any>();
    //#endregion

    Rows;
    TriggerStates = TriggerQueueState;
    MessageSubscription;
    constructor(private service: SchedulerService, private cdRef: ChangeDetectorRef, private router: Router) {
    }
    ngOnInit(): void {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            console.log('Free user')
            this.router.navigate(['/default/settings/access-denied']);
			return;
		}
        this.Refresh();
        this.MessageSubscription = WebsocketService.MessageReceived.subscribe((response) => {
            const parsed = JSON.parse(response);
            if (parsed.Level === NotificationLevel.System && parsed.Type === 'TriggerUpdate') {
                this.Refresh();
            }
        });
        WebsocketService.Subscribe([{ Channel: 'TriggerUpdate', Level: [0] }]);
    }
    ngOnDestroy(): void {
        if (this.MessageSubscription) {    
            this.MessageSubscription.unsubscribe();
            WebsocketService.Unsubscribe([{ Channel: 'TriggerUpdate', Level: [0] }]);
        }
    }

    Refresh() {
        this.Rows = null;
        this.service.GetTriggerStateInfos(this.AllUsersValue).subscribe(result => {
            if (result && result.length > 0) {
                const rows = [];
                result.forEach(x => {
                    x.ChildRow = false;
                    rows.push(x);
                    x.RunInfos.forEach(ri => {
                        ri.ChildRow = true;
                        ri.TriggerName = ri.Name;                        
                        rows.push(ri);
                    });
                });
                this.Rows = rows;
            }
            this.cdRef.detectChanges();
        });
    }

    GetTaskInfo(row) {
        JobInfoDialog.ShowDialog(row.RunID);
    }

    CancelTask(row) {
        this.service.CancelTrigger(row.RunID).subscribe();
    }
}
