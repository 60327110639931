import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuKeyDownHelper } from '../../../helpers/clickstoppropagation.directive';
import { Point } from '../../../models/basic/point.model';
import { GradientType } from '../../../models/enums/gradienttype.enum';
import { Gradient } from '../../../models/style/gradient.model';
import { BaseThemeControl } from '../base.theme.control';


@Component({
    selector: 'gradientpicker-theme-control',
    templateUrl: './gradientpicker.theme.control.html',
    styleUrls: ['./gradientpicker.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientpickerThemeControl extends BaseThemeControl implements OnInit {
    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption(): string {
        return this.CaptionValue;
    }
    set Caption(val: string) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<string>();
    //#endregion
    //#region Icon
    IconValue: string;
    @Input()
    get Icon(): string {
        return this.IconValue;
    }
    set Icon(val: string) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
    }

    @Output() IconChange = new EventEmitter<string>();
    //#endregion

    //#region ColorSet
    ColorSetValue: boolean = false;
    get ColorSet(): boolean {
        return this.ColorSetValue;
    }
    set ColorSet(val: boolean) {
        this.ColorSetValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    //#region Active
    ActiveValue: boolean = false;
    get Active(): boolean {
        return this.ActiveValue;
    }
    set Active(val: boolean) {
        this.ActiveValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion
    ActiveChanged(val) {
        if (!val) {
            this.clearValue();
        }
    }
    ngOnInit(): void {
        this.DataSourceInternalChange.subscribe((value) => {
            this.RefreshInternalValues();
        })
        this.SelectedItemChange.subscribe((value) => {
            this.RefreshInternalValues();
        })
        this.RefreshInternalValues();
    }
    RefreshInternalValues() {
        this.Active = false;
        this.ColorSet = false;
        this.updateFillStyle();
        let styleobj = Gradient.getStyleObject(this.DataSourceInternal);
        if (Object.keys(styleobj).length > 0) {
            this.Active = true;
            this.ColorSet = true;
        }
    }
    menuKeyDown(ev) {
        MenuKeyDownHelper.onKeyDown(ev);
    }
    FillStyle = {};
    updateFillStyle() {
        const fillStyle = {};
        if (this.DataSourceInternal) {
            const gradient = Gradient.getStyleObject(this.DataSourceInternal);
            if (gradient) {
                if (this.DataSourceInternal.Type === GradientType.Solid) {
                    const bgc = gradient['background-color'];
                    if (bgc) {
                        fillStyle['fill'] = bgc;
                    }
                } else {
                    Object.assign(fillStyle, gradient);
                }
            }
        }
        this.FillStyle = fillStyle;
        this.cdRef.detectChanges();
    }
    SetDataSource(color) {
        if (color) {
            this.DataSourceInternal.StartPoint = color.StartPoint;
            this.DataSourceInternal.EndPoint = color.EndPoint;
            this.DataSourceInternal.Colors = color.Colors;
            this.DataSourceInternal.Type = color.Type;
            this.ColorSet = true;
            this.StyleChanged.emit();
        } else {
            let styleobj = Gradient.getStyleObject(this.DataSourceInternal);
            if (Object.keys(styleobj).length > 0) {
                this.DataSourceInternal.StartPoint = new Point();
                this.DataSourceInternal.EndPoint = new Point();
                this.DataSourceInternal.Colors = [];
                this.DataSourceInternal.Type = GradientType.None;
                this.StyleChanged.emit();
            }
            this.ColorSet = false;
        }
        
        this.updateFillStyle();
    }

    clearValue(): void {
        this.DataSourceInternal.StartPoint = null;
        this.DataSourceInternal.EndPoint = null;
        this.DataSourceInternal.Colors = null;
        this.DataSourceInternal.Type = null;
        this.ColorSet = false;
        this.StyleChanged.emit();
        this.updateFillStyle();
    }
}
