import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataModelService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-generate-reader-settings',
    templateUrl: './generate.reader.settings.html'
})
export class GenerateReaderSettings extends ContainerWorkflowDialogContent {
    Data = new GenerateReaderSettingsData();
    Columns = [];
    DBUsages = [[8192, 512]]; // RelationalWrite + DataSourceWrite

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'generateReaderWFModule';
        reg.Caption = '@@DataReader generieren';
        reg.GroupID = 'reldataoperations';
        reg.Index = 30;
        reg.SettingsControl = GenerateReaderSettings;
        reg.SettingsTypeHelper = new GenerateReaderSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private dataService: DataModelService) {
        super();
    }

    onDataChecked() {
        this.Columns = [];
        if (this.Data.ContainerTable) {
            this.dataService.GetContainer(this.Data.ContainerTable).subscribe(result => {
                if (result && result.Fields) {
                    const columns = [];
                    result.Fields.forEach(x => {
                        columns.push({
                            Caption: x.TranslatedCaption,
                            Name: x.Name,
                            Selected: this.Data.Columns.some(y => y === x.Name)
                        });
                    });
                    this.Columns = columns;
                }
            });
        }
    }

    onDataChanged() {
        this.Columns = [];
        if (this.Data.ContainerTable) {
            this.dataService.GetContainer(this.Data.ContainerTable).subscribe(result => {
                if (result && result.Fields) {
                    const columns = [];
                    result.Fields.forEach(x => {
                        columns.push({
                            Caption: x.TranslatedCaption,
                            Name: x.Name,
                            Selected: true
                        });
                    });
                    this.Columns = columns;
                }
            });
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GenerateReaderSettingsData, json);
        }
    }

    getResult(): any {
        const columns = [];
        this.Columns.forEach(x => {
            if (x.Selected) {
                columns.push(x.Name);
            }
        });
        this.Data.Columns = columns;
        return this.Data;
    }

    selectAll(ev) {
        if (ev) {
            this.Columns.forEach(x => {
                x.Selected = ev.checked;
            });
        }
    }
}

export class GenerateReaderSettingsData extends AServiceWorkflowData {
    DataModel: string;
    DataSource: string;
    ContainerTable: string;
    ContainerInternalID: string;
    Columns: string[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.GenerateReader.GenerateReaderSettingsData';
    }
}

export class GenerateReaderSettingsDataHelper extends WorkflowModuleSettingsHelper {
    static getColumns(settings) {
        const promise = new Promise<any[]>((resolve, reject) => {
            if (settings) {
                if (settings.ContainerTable) {
                    const service = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
                    service.GetContainer(settings.ContainerTable).subscribe(result => {
                        let list = null;
                        if (result && result.Fields) {
                            if (settings.Columns && settings.Columns.length > 0) {
                                list = [];
                                settings.Columns.forEach(col => {
                                    const field = result.Fields.find(x => x.Name === col);
                                    if (field) {
                                        list.push(field);
                                    }
                                });
                            } else {
                                list = result.Fields;
                            }
                        }
                        resolve(list);
                    });
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
        return promise;
    }
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        return [def];
    }
    getEmptySettingsInstance() {
        return new GenerateReaderSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const list = await GenerateReaderSettingsDataHelper.getColumns(settings);
            if (list) {
                state.set('DataSource', { Columns: list, DataModel: settings.DataModel });
            }
        }
    }
}
