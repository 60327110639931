import { CumulateTaskPanel } from '../../components/taskpanels/cumulate/cumulate.task.panel';
import { TaskRegistry } from '../../helpers/task.registry';
import { TaskOverview } from '../../components/taskpanels/task.overview';
import { NewTaskDialog } from '../../components/taskpanels/new.task.dialog';
import { PreviousLevelTaskPanel } from '../../components/taskpanels/previouslevel/previous.level.task.panel';
import { CalculateTaskPanel } from '../../components/taskpanels/calculate/calculate.task.panel';
import { FilterTaskPanel } from '../../components/taskpanels/filter/filter.task.panel';
import { DataSortTaskPanel } from '../../components/taskpanels/datasort/dataSort.task.panel';
import { TopDownTaskPanel } from '../../components/taskpanels/topdown/topdown.task.panel';
import { MeasureFormattingTaskPanel } from '../../components/taskpanels/formatting/measure/measure.formatting.task.panel';
import { CodeDescriptionTaskPanel } from '../../components/taskpanels/codedescription/codedescription.task.panel';
import { LevelFormattingTaskPanel } from '../../components/taskpanels/formatting/level/level.formatting.task.panel';
import { MeasureConditionalFormattingTaskPanel } from '../../components/taskpanels/formatting/conditionalformat/measureconditional.formatting.task.panel';
import { LevelConditionalFormattingTaskPanel } from '../../components/taskpanels/formatting/conditionalformat/levelconditional.formatting.task.panel';
import { SumFormattingTaskPanel } from '../../components/taskpanels/formatting/sum/sum.formatting.task.panel';
import { ChartSeriesFormattingPanel } from '../../components/taskpanels/chartformatting/series/chart.series.formatting.panel';
import { SumXLevelFormattingTaskPanel } from '../../components/taskpanels/formatting/sum/sum.xlevel.formatting.task.panel';
import { LevelHeaderFormattingTaskPanel } from '../../components/taskpanels/formatting/levelheader/levelheader.formatting.task.panel';
import { MeasureHeaderFormattingTaskPanel } from '../../components/taskpanels/formatting/measureheader/measureheader.formatting.task.panel';
import { ColumndescriptionheaderFormattingTaskPanel } from '../../components/taskpanels/formatting/columndescriptionheader/columndescriptionheader.formatting.task.panel';
import { EditTaskPanel } from '../../components/taskpanels/edit/edit.task.panel';
import { RankTaskPanel } from '../../components/taskpanels/rank/rank.task.panel';
import { HeaderPlanningTaskPanel } from '../../components/taskpanels/planning/header/header.planning.task.panel';
import { RowDescPlanningTaskPanel } from '../../components/taskpanels/planning/row/rowdesc.planning.task.panel';
import { ContentPlanningTaskPanel } from '../../components/taskpanels/planning/content/content.planning.task.panel';
import { CellPlanningTaskPanel } from '../../components/taskpanels/planning/cell/cell.planning.task.panel';
import { ChartSeriesLabelPanel } from '../../components/taskpanels/chartformatting/series/chart.series.label.panel';
import { BubbleSeriesFormattingPanel } from '../../components/taskpanels/chartformatting/bubbleseries/bubble.series.formatting.panel';

export const MAC_TASKS = [
    TaskOverview,
    NewTaskDialog,
    CumulateTaskPanel,
    PreviousLevelTaskPanel,
    CalculateTaskPanel,
    FilterTaskPanel,
    DataSortTaskPanel,
    TopDownTaskPanel,
    MeasureFormattingTaskPanel,
    CodeDescriptionTaskPanel,
    LevelFormattingTaskPanel,
    MeasureConditionalFormattingTaskPanel,
    LevelConditionalFormattingTaskPanel,
    SumFormattingTaskPanel,
    ChartSeriesFormattingPanel,
    SumXLevelFormattingTaskPanel,
    LevelHeaderFormattingTaskPanel,
    MeasureHeaderFormattingTaskPanel,
    ColumndescriptionheaderFormattingTaskPanel,
    EditTaskPanel,
    RankTaskPanel,
    HeaderPlanningTaskPanel,
    RowDescPlanningTaskPanel,
    ContentPlanningTaskPanel,
    CellPlanningTaskPanel,
    ChartSeriesLabelPanel,
    BubbleSeriesFormattingPanel
];

export class TaskRegistryHelper {
    public static Build() {
        MAC_TASKS.forEach(t => {
            if (typeof t['GetRegistry'] === 'function') {
                const reg = t['GetRegistry']();
                TaskRegistry.set(reg.ID, reg);
            }
        });
    }
}
