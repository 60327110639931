import { Component } from "@angular/core";
import { deserialize, serialize } from "class-transformer";
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, WorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { AggregateSettings } from "../aggregate/aggregate.settings";

@Component({
    selector: 'wf-pivot-settings',
    templateUrl: './pivot.settings.html',
    styleUrls: ['./pivot.settings.css']
})
export class PivotSettings extends WorkflowDialogContent {
    Data = new PivotSettingsData();
    Columns = [];
    Aggregations;

    constructor() {
        super();
        this.UseActualState = true;
        this.Aggregations = AggregateSettings.GetAggregations();
    }

    initialize(data: any) {
        var ds = this.ActualState.get('DataSource');
        if (ds) {
            this.Columns = ds.Columns;
        }
        if (data) {
            let json = serialize(data);
            this.Data = deserialize(PivotSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }

    getColumns(col) {
        var columns = [];
        var data = this.Data;
        this.Columns.forEach(function (allCol) {
            var name = allCol.Name;
            if (name == col || (name != data.AggregateColumn && name != data.GroupColumn && name != data.PivotColumn)) {
                columns.push(allCol);
            }
        });
        return columns;
    }

    checkData() {
        var retVal = new DataCheck();
        if (!this.Data.PivotColumn || this.Data.PivotColumn == '' || !this.Data.AggregateColumn || this.Data.AggregateColumn == '' || !this.Data.Aggregation) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte vergeben Sie eine Pivot-Spalte, eine Aggregations-Spalte und einen Aggregationstyp';
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        let reg = new WorkflowRegistry();
        reg.ID = 'pivotWFModule';
        reg.Caption = "@@Pivot";
        reg.GroupID = 'reldataoperations';
        reg.Index = 130;
        reg.SettingsControl = PivotSettings;
        reg.SettingsTypeHelper = new PivotSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }
}

export class PivotSettingsData extends AServiceWorkflowData {
    PivotColumn: string;
    AggregateColumn: string;
    Aggregation: number = 1;
    GroupColumn: string;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.Pivot.PivotSettingsData';
    }
}

export class PivotSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        let retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new PivotSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        let def = new WorkflowExitInfo();
        def.Type = 'relData';
        let fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = "@@ForEach";
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state && module && module.Settings) {
            var ds = state.get('DataSource');
            if (ds) {
                // Spalten leeren, da ich die Pivot-Spalten nicht wei�
                ds.Columns = [];
            }
        }
    }
}