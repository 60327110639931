import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { ChangeLogService } from '../../services/changelog.service';
import { LayoutService } from '../../services/layout.service';
import { UsersService } from '../../services/users.service';
import { SaveChangeLogDialog } from './save.changelog.dialog';

@Component({
    selector: 'changelog-overview',
    templateUrl: './changelog.overview.html',
    styleUrls: ['./changelog.overview.css']
})
export class ChangeLogOverview implements OnInit {
    Entries = [];
    SelectedUser;
    SearchValue;
    TypeList = [];
    selectedNode;
    SelectedEntry;
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    toggleWindow = true;

    static GetSettingsEntry() {
        return {
            Caption: '@@ChangeLog Overview',
            ID: 'changelog-overview',
            Icon: 'description',
            Index: 20,
            Parent: 'overview',
            Security: {
                Name: 'evidanza.App.Shared.Security.AboutRight',
                Value: 6
            },
            Content: ChangeLogOverview
        };
    }

    constructor(private cdRef: ChangeDetectorRef, private service: ChangeLogService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        LayoutService.Loading.next(true);
        this.SearchValue = null;
        this.Entries = [];
        this.SelectedUser = null;
        this.TypeList = [];
        this.selectedNode = null;
        this.SelectedEntry = null;
        this.toggleWindow = true;
        this.service.GetChangeLogs().subscribe(x => {
            if (x && x.length > 0) {
                let selected = x[0];
                if (x.length > 1) {
                    const activeUser = UsersService.ActiveUser.getValue();
                    x.forEach(y => {
                        if (y.ChangeLogTypes) {
                            y.ChangeLogTypes.forEach(clt => clt.IsExpanded = true);
                        }
                        if (activeUser && activeUser.SID == y.UserID) {
                            selected = y;
                        }
                    });
                    this.Entries = x;
                } else {
                    if (selected.ChangeLogTypes) {
                        selected.ChangeLogTypes.forEach(clt => clt.IsExpanded = true);
                    }
                }
                this.SelectedUser = selected;
                this.UpdateFiltered();
            }
            LayoutService.Loading.next(false);
        });
    }

    onUserChanged() {
        this.selectedNode = null;
        this.SelectedEntry = null;
        this.UpdateFiltered();
    }


    UpdateFiltered() {
        const list = [];
        if (this.SelectedUser && this.SelectedUser.ChangeLogTypes) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                this.SelectedUser.ChangeLogTypes.forEach(x => {
                    const typeEntry = {
                        ObjectType: x.ObjectType,
                        ChangeLogInfos: x.ChangeLogInfos.filter(y => y.Caption.toLowerCase().indexOf(toLower) > -1),
                        IsExpanded: true
                    };
                    if (typeEntry.ChangeLogInfos.length > 0) {
                        list.push(typeEntry);
                    }
                });
            } else {
                list.push(...this.SelectedUser.ChangeLogTypes);
            }
        }
        this.TypeList = list;
        this.cdRef.detectChanges();
    }

    onItemClick(cli) {
        if (this.selectedNode != cli) {
            LayoutService.Loading.next(true);
            this.selectedNode = cli;
            this.service.GetChangeLogDetail(cli.ID).subscribe(x => {
                if (x) {
                    x.Content = {
                        'code': x.Content,
                        'language': 'json'
                    };
                    x.SaveObject = {
                        'code': x.SaveObject,
                        'language': 'json'
                    };
                }
                this.SelectedEntry = x;
                this.cdRef.detectChanges();
                LayoutService.Loading.next(false);
            });
        }
    }

    // onDeleteItem(cli) {
    //     this.selectedNode = cli;
    //     this.undo();
    // }

    undo() {
        const sel = this.SelectedUser;
        if (sel && sel.ChangeLogTypes) {
            const list = [];
            sel.ChangeLogTypes.forEach(clt => {
                if (clt && clt.ChangeLogInfos) {
                    clt.ChangeLogInfos.forEach(cli => {
                        if (cli && cli.Selected) {
                            list.push(cli.ID);
                        }
                    });
                }
            });
            const request = {
                UserID: sel.UserID,
                UndoAll: list.length == 0,
                IDs: list
            };
            let tft = new TranslateFormatText('@@UndoChangeLog');
            if (list.length > 0) {
                tft = new TranslateFormatText('@@UndoChangeLog{0}');
                tft.FormatParams.push('' + list.length);
            }
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@UndoChangeLogHeader'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.service.UndoChangeLog(request).subscribe(() => {
                            NotificationHelper.Success('@@SuccessfullUndoChangeLog', '@@UndoChangeLogHeader');
                            this.refresh();
                        });
                    }
                });
        }
    }

    save() {
        const sel = this.SelectedUser;
        if (sel && sel.ChangeLogTypes) {
            const list = [];
            sel.ChangeLogTypes.forEach(clt => {
                if (clt && clt.ChangeLogInfos) {
                    clt.ChangeLogInfos.forEach(cli => {
                        if (cli && cli.Selected) {
                            list.push(cli.ID);
                        }
                    });
                }
            });
            SaveChangeLogDialog.ShowDialog(list.length, (overwrite) => {
                this.service.SaveChangeLog({
                    UserID: sel.UserID,
                    UndoAll: list.length == 0,
                    IDs: list,
                    Overwrite: overwrite
                }).subscribe(() => {
                    NotificationHelper.Success('@@SuccessfullSavedChangeLog', '@@SaveChangeLogHeader');
                    this.refresh();
                });
            });
        }
    }

    onSelectChange(node, ev) {
        if (node && node.ChangeLogInfos && ev) {
            node.ChangeLogInfos.forEach(cli => cli.Selected = ev.checked);
        }
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
