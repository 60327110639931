import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { AdvancedFilterDialog } from '../../dialogs/advancedfilter/advancedfilter.dialog';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';

@Component({
    selector: 'component-filter',
    templateUrl: './componentfilter.control.html'
})
export class ComponentFilterControl {
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.TableProperties = null;
        if (this.SelectedItemValue && this.SelectedItemValue.DataSourceID) {
            if (!this.SelectedItemValue.IsEnum) {
                CacheService.ReadFlatTable(this.SelectedItemValue.DataSourceID, true).then((data) => {
                    if (data) {
                        this.TableProperties = data;
                        this.cdRef.detectChanges();
                    }
                });
            }
        }
        this.checkFilterText();
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region TableProperties
    TableProperties;
    //#endregion
    FilterText;

    @Output() FilterChanged = new EventEmitter<any>();
    @Output() StyleChanged = new EventEmitter<any>();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    private checkFilterText() {
        if (this.SelectedItem && this.SelectedItem.Filter && this.SelectedItem.Filter.Filters &&
            this.SelectedItem.Filter.Filters.length > 0) {
            this.FilterText = RequestFilter.getRequestFilterText(this.SelectedItem.Filter.Filters[0]);
        } else {
            this.FilterText = null;
        }
    }

    editFilter() {
        if (this.SelectedItem) {
            const initArgs = {
                Filter: null,
                Columns: [],
                SystemVariables: FilterHelper.GetVariables(this.SelectedItem)
            };
            if (this.TableProperties && this.TableProperties.Columns) {
                const list = [];
                this.TableProperties.Columns.forEach((field) => {
                    list.push({
                        IsFilterable: true,
                        PropertyName: field.Name,
                        Caption: field.Name,
                        Type: AttributeProperty.GetFilterType(field)
                    });
                });
                initArgs.Columns = list;
            }
            if (this.SelectedItem.Filter && this.SelectedItem.Filter.Filters && this.SelectedItem.Filter.Filters.length > 0) {
                initArgs.Filter = this.SelectedItem.Filter.Filters[0];
            }
            const layout = this.SelectedItem;
            BaseDialog.ShowDialog({
                ContentType: AdvancedFilterDialog,
                InitArgs: initArgs,
                Handler: (result) => {
                    if (result) {
                        const filter = result.Filter;
                        if (filter && (filter.Name || filter.Filters || filter.Value)) {
                            if (!layout.Filter) {
                                layout.Filter = new RequestOptions();
                            }
                            layout.Filter.Filters = [filter];
                        } else if (layout.Filter) {
                            layout.Filter.Filters = [];
                        }
                        this.checkFilterText();
                        this.FilterChanged.emit();
                        this.StyleChanged.emit();
                    }
                    return true;
                }
            });
        }
    }
}
