import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { EnumHelper } from '../../helpers/enum.helper';
import { LayoutUnit } from '../../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../../models/basic/layoutunitthickness.model';
import { ContentType } from '../../models/enums/contenttype.enum';
import { GradientType } from '../../models/enums/gradienttype.enum';
import { OutputPageType } from '../../models/enums/outputpagetype.enum';
import { UnitType } from '../../models/enums/unittype.enum';
import { Layout } from '../../models/layout.model';
import { Border } from '../../models/style/border.model';
import { Gradient } from '../../models/style/gradient.model';
import { GradientStopColor } from '../../models/style/gradientstopcolor.model';
import { LayoutService } from '../../services/layout.service';
import { OutputService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import { RepeatingChooserDescription } from './repeating.chooser';
import {NotificationHelper} from "../../helpers/notification.helper";

@Component({
    selector: 'output-settings',
    templateUrl: '../base.list.settings.html'
})
export class OutputSettings extends BaseListSettings {
    ShowDuplicateButton = false;
    
    static GetSettingsEntry() {
        return {
            Caption: '@@Output Templates',
            ID: 'output',
            Icon: 'download',
            Index: 25,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 1024
            },
            Content: OutputSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private service: OutputService, private translate: TranslateService) {
        super(factoryResolver, cdRef);
        this.ShowCopy = true;
        this.CopyType = "OutputCopy";
        this.ShowDuplicateButton = false;
        this.ShowCopyButton = true;
    }

    init() {
        NavigationService.SelectedPage.next('output');
        this.ShowDuplicateButton = false;
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        NavigationService.SelectedPage.next('settings');
    }

    loadList(handler: any) {
        this.service.ReadOutputInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(function (entry) {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this.service.ReadOutputById(data).subscribe(
            (result) => {
                if (result) {
                    this.setSelectedItem(result);
                }
            }
        );
    }

    getNewItem() {
        const name = this.translate.instant('@@Single Page');
        const size = { Height: 1122, Width: 794 };
        return {
            Name: this.translate.instant('@@Neues Template'),
            RootLayout: OutputDetail.GetNewPage(this.translate.instant('@@RootLayout'), size),
            Pages: [{
                ID: UUID.UUID(),
                Name: name + ' 1',
                OutputPageType: OutputPageType.Single,
                MultiPageRepeatNames: [],
                Content: {
                    BaseLayout: OutputDetail.GetNewPage(this.translate.instant('@@Single Page BaseLayout'), size),
                    Single: OutputDetail.GetNewPage(name, size)
                }
            }]
        };
    }

    getCopyItem(sel) {
        return new Promise(resolve => {
            if (sel) {
                const copy = JSON.parse(JSON.stringify(sel));
                resolve(copy);
            } else {
                resolve(null);
            }
        });
    }
    getDuplicateItem(sel) {
        return new Promise(resolve => {
            if (sel) {
                const copy = JSON.parse(JSON.stringify(sel));
                delete copy._Id;
                copy.Name += ' (copy)';
                resolve(copy);
            } else {
                resolve(null);
            }
        });
    }
    getPasteItem(): Promise<any> {
        return new Promise(resolve => {
            const copy = JSON.parse(JSON.stringify(ClipboardHelper.ClipboardContent.content));
            if (copy._Id) {
                this.service.ReadOutputById(copy._Id).subscribe((result) => {
                    if (!result) {
                        resolve(copy);
                    } else {
                        delete copy._Id;
                        copy.Name += ' (copy)';
                        resolve(copy);
                    }
                });
            } else {
                resolve(copy);
            }
        });
    }
    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Template \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Template \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Template loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Template \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Template speichern');
        return retVal;
    }

    delete(data: any, handler) {
        if (data) {
            this.service.RemoveOutput(data).subscribe((res) => {
                handler(res);
            });
        }
    }

    saveInternal(item: any, handler: any) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.AddOrUpdateOutput(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    getContentType() { return OutputDetail; }

    handleNew(item, result) {
        item._Id = result.SID;
        item._version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'output-detail',
    templateUrl: './output.settings.html',
    styleUrls: ['./output.settings.css']
})
export class OutputDetail extends BaseListDetail {
    OutputPageTypes = [];
    Subscription;
    TempBaseLayout;
    LastEditInfo;
    DataSource = [{}];
    PageContents = {};
    DataModels;
    DataSources;
    PageEdit = false;

    private static GetBorder() {
        const border = new Border();
        border.BottomBorder.Thickness.Type = UnitType.Pixel;
        border.BottomBorder.Thickness.Value = 1;
        border.BottomBorder.Color = OutputDetail.GetBorderColor();
        border.LeftBorder.Thickness.Type = UnitType.Pixel;
        border.LeftBorder.Thickness.Value = 1;
        border.LeftBorder.Color = OutputDetail.GetBorderColor();
        border.RightBorder.Thickness.Type = UnitType.Pixel;
        border.RightBorder.Thickness.Value = 1;
        border.RightBorder.Color = OutputDetail.GetBorderColor();
        border.TopBorder.Thickness.Type = UnitType.Pixel;
        border.TopBorder.Thickness.Value = 1;
        border.TopBorder.Color = OutputDetail.GetBorderColor();
        return border;
    }

    private static GetBorderColor() {
        const gradient = new Gradient();
        gradient.Type = GradientType.Solid;
        gradient.Colors.push(new GradientStopColor());
        return gradient;
    }

    private static GetMargin() {
        const margin = new LayoutUnitThickness();
        margin.Bottom.Type = UnitType.Auto;
        margin.Left.Type = UnitType.Auto;
        margin.Right.Type = UnitType.Auto;
        margin.Top.Type = UnitType.Auto;
        return margin;
    }

    private static ChangeCopy(copy) {
        copy.ID = UUID.UUID();
        if (copy.Elements) {
            copy.Elements.forEach(x => OutputDetail.ChangeCopy(x));
        }
    }

    private static ReadRepeatings(elem, result: RepeatingChooserDescription) {
        if (elem) {
            if (elem.ElementType === 'repeat') {
                const childRepeats = {
                    Depth: result.Depth + 1,
                    Name: elem.Name,
                    Repeatings: []
                };
                if (elem.Elements) {
                    elem.Elements.forEach(x => {
                        OutputDetail.ReadRepeatings(x, childRepeats);
                    });
                }
                result.Repeatings.push(childRepeats);
            } else if (elem.Elements) {
                elem.Elements.forEach(x => {
                    OutputDetail.ReadRepeatings(x, result);
                });
            }
        }
    }

    private static JoinRepList(list: RepeatingChooserDescription[]): RepeatingChooserDescription[] {
        const retVal = [];
        if (list && list.length > 0) {
            const first = list[0];
            first.Repeatings.forEach(rep => {
                const nextList = [rep];
                for (let i = 1; i < list.length; i++) {
                    const sameRep = list[i].Repeatings.find(x => x.Name === rep.Name);
                    if (sameRep) {
                        nextList.push(sameRep);
                    } else {
                        break;
                    }
                }
                if (nextList.length === list.length) {
                    retVal.push({
                        Depth: rep.Depth,
                        Name: rep.Name,
                        Repeatings: OutputDetail.JoinRepList(nextList)
                    });
                }
            });
        }
        return retVal;
    }

    static GetNewPage(name: string, size: { Height: number, Width: number }) {
        const item = new Layout();
        item.Name = name;
        item.ContentType = ContentType.Canvas;
        item['ElementType'] = 'canvas';
        item.Height = new LayoutUnit();
        item.Height.Type = UnitType.Pixel;
        item.Height.Value = size.Height;
        item.Width = new LayoutUnit();
        item.Width.Type = UnitType.Pixel;
        item.Width.Value = size.Width;
        item.Border = OutputDetail.GetBorder();
        item.Margin = OutputDetail.GetMargin();
        item['UsePage'] = true;
        return item;
    }

    constructor(private translate: TranslateService, private service: OutputService) {
        super();
        this.OutputPageTypes = EnumHelper.GetDropdownValues(OutputPageType);
    }

    ngOnInit() {
        this.service.GetModelInfos().subscribe(x => {
            if (x) {
                this.DataModels = x;
                const ds = {};
                x.forEach(dm => {
                    ds[dm.SID] = dm.Container;
                });
                this.DataSources = ds;
            }
        });
        this.Subscription = LayoutService.LayoutEditMode.subscribe(x => {            
            if (this.LastEditInfo) {
                if (x) {
                    this.TempBaseLayout = JSON.stringify(this.LastEditInfo.PageContent);
                } else if (this.TempBaseLayout) {
                    const actLayout = JSON.stringify(this.LastEditInfo.PageContent);
                    if (actLayout !== this.TempBaseLayout) {
                        switch (this.LastEditInfo.Source) {
                            case 'MultiStart':
                            case 'MultiMiddle':
                            case 'MultiEnd':
                                this.readRepeatings(this.LastEditInfo.Page);
                                break;
                        }
                        this.OnItemChanged();
                    }
                    this.TempBaseLayout = null;
                    this.LastEditInfo = null;
                }
            }
            window.setTimeout(() => {
                this.PageEdit = x;
                if (this.cdRef) {
                    this.cdRef.detectChanges();
                }
            }, x ? 0 : 100);
        });
    }

    ngOnDestroy(): void {
        if (this.Subscription) {
            this.Subscription.unsubscribe();
        }
    }

    setSelectedItem(item) {
        this.PageContents = {};
        if (item) {
            if (item.RootLayout) {
                item.RootLayout = plainToClass(Layout, item.RootLayout);
            }
            if (item.Pages) {
                item.Pages.forEach(page => {
                    const pc: any = {};
                    this.PageContents[page.ID] = pc;
                    if (page.Content) {
                        switch (page.OutputPageType) {
                            case OutputPageType.Single:
                                page.Content.BaseLayout = plainToClass(Layout, page.Content.BaseLayout);
                                page.Content.Single = plainToClass(Layout, page.Content.Single);
                                pc.SingleContent = page.Content;
                                break;
                            case OutputPageType.Multi:
                                page.Content.BaseLayout = plainToClass(Layout, page.Content.BaseLayout);
                                page.Content.Single = plainToClass(Layout, page.Content.Single);
                                page.Content.MultiStart = plainToClass(Layout, page.Content.MultiStart);
                                page.Content.MultiMiddle = plainToClass(Layout, page.Content.MultiMiddle);
                                page.Content.MultiEnd = plainToClass(Layout, page.Content.MultiEnd);
                                pc.MultiContent = page.Content;
                                break;
                        }
                    }
                    this.readRepeatings(page);
                });
            }
        }
        super.setSelectedItem(item);
    }

    readRepeatings(page) {
        const repList = [];
        const pc = this.PageContents[page.ID];
        if (pc && pc.MultiContent) {
            if (pc.MultiContent.MultiStart && pc.MultiContent.MultiStart.UsePage) {
                const msRep = {
                    Depth: 0,
                    Name: '',
                    Repeatings: []
                };
                OutputDetail.ReadRepeatings(pc.MultiContent.MultiStart, msRep);
                repList.push(msRep);
            }
            if (pc.MultiContent.MultiMiddle && pc.MultiContent.MultiMiddle.UsePage) {
                const mmRep = {
                    Depth: 0,
                    Name: '',
                    Repeatings: []
                };
                OutputDetail.ReadRepeatings(pc.MultiContent.MultiMiddle, mmRep);
                repList.push(mmRep);
            }
            if (pc.MultiContent.MultiEnd && pc.MultiContent.MultiEnd.UsePage) {
                const meRep = {
                    Depth: 0,
                    Name: '',
                    Repeatings: []
                };
                OutputDetail.ReadRepeatings(pc.MultiContent.MultiEnd, meRep);
                repList.push(meRep);
            }
        }
        if (repList.length > 0) {
            if (repList.length === 1) {
                pc.Repeatings = repList[0];
            } else {
                pc.Repeatings = {
                    Depth: 0,
                    Name: '',
                    Repeatings: OutputDetail.JoinRepList(repList)
                };
            }
        } else {
            pc.Repeatings = {
                Depth: 0,
                Name: '',
                Repeatings: []
            }
        }
        let actRep = pc.Repeatings;
        if (page.MultiPageRepeatNames) {
            for (let i = 0; i < page.MultiPageRepeatNames.length; i++) {
                const name = page.MultiPageRepeatNames[i];
                actRep = actRep.Repeatings.find(x => x.Name === name);
                if (actRep == null) {
                    page.MultiPageRepeatNames.splice(i);
                    break;
                }
            }
        }
    }

    AddSingle() {
        if (this.SelectedItem) {
            const name = this.translate.instant('@@Single Page');
            let index = 1;
            let pageName = name + ' ' + index++;
            if (this.SelectedItem.Pages) {
                while (this.SelectedItem.Pages.some(p => p.Name === pageName)) {
                    pageName = name + ' ' + index++;
                }
            }
            const lpo = this.GetLastPageOrientation();
            const page = {
                ID: UUID.UUID(),
                Name: pageName,
                OutputPageType: OutputPageType.Single,
                MultiPageRepeatNames: [],
                Content: {
                    BaseLayout: OutputDetail.GetNewPage(this.translate.instant('@@Single Page BaseLayout'), lpo),
                    Single: OutputDetail.GetNewPage(name, lpo)
                }
            }
            this.PageContents[page.ID] = {
                SingleContent: page.Content
            };
            if (this.SelectedItem.Pages) {
                this.SelectedItem.Pages.push(page);
            } else {
                this.SelectedItem.Pages = [page];
            }
            this.OnItemChanged();
        }
    }

    AddMulti() {
        if (this.SelectedItem) {
            const name = this.translate.instant('@@Multi Page');
            let index = 1;
            let pageName = name + ' ' + index++;
            if (this.SelectedItem.Pages) {
                while (this.SelectedItem.Pages.some(p => p.Name === pageName)) {
                    pageName = name + ' ' + index++;
                }
            }
            const lpo = this.GetLastPageOrientation();
            const page = {
                ID: UUID.UUID(),
                Name: pageName,
                OutputPageType: OutputPageType.Multi,
                MultiPageRepeatNames: [],
                Content: {
                    BaseLayout: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page BaseLayout'), lpo),
                    Single: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page Single'), lpo),
                    MultiStart: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiStart'), lpo),
                    MultiMiddle: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiMiddle'), lpo),
                    MultiEnd: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiEnd'), lpo)
                }
            }
            this.PageContents[page.ID] = {
                MultiContent: page.Content
            };
            if (this.SelectedItem.Pages) {
                this.SelectedItem.Pages.push(page);
            } else {
                this.SelectedItem.Pages = [page];
            }
            this.OnItemChanged();
        }
    }

    private GetLastPageOrientation() {
        const retVal = {
            Height: 1122,
            Width: 794
        };
        if (this.SelectedItem && this.SelectedItem.Pages && this.SelectedItem.Pages.length > 0) {
            const last = this.SelectedItem.Pages[this.SelectedItem.Pages.length - 1];
            let pageContent = last.Content.BaseLayout;
            if (pageContent && pageContent.Height && pageContent.Width && pageContent.Width.Value > pageContent.Height.Value) {
                retVal.Height = 794;
                retVal.Width = 1122;
            }
        }
        return retVal;
    }

    Remove(index) {
        this.SelectedItem.Pages.splice(index, 1);
        this.OnItemChanged();
    }

    EditRoot() {
        this.LastEditInfo = {
            Source: 'RootLayout',
            PageContent: this.SelectedItem.RootLayout
        };
        this.editInternal();
    }

    EditPage(page, source) {
        this.LastEditInfo = {
            Page: page,
            Source: source,
            PageContent: page.Content[source]
        };
        this.editInternal();
    }

    private editInternal() {
        if (this.LastEditInfo) {
            const elem = this.LastEditInfo.PageContent;
            if (elem) {
                elem.Border = OutputDetail.GetBorder();
                elem.Margin = OutputDetail.GetMargin();
                LayoutService.SelectedLayout.next(elem);
                LayoutService.LayoutEditMode.next(true);
            }
        }
    }

    CopyPage(elem, i) {
        const copy = JSON.parse(JSON.stringify(elem));
        copy.ID = UUID.UUID();
        copy.Name = copy.Name + ' (copy)';
        const copyPC: any = {};
        this.PageContents[copy.ID] = copyPC;
        if (copy.Content) {
            switch (copy.OutputPageType) {
                case OutputPageType.Single:
                    copy.Content.BaseLayout = plainToClass(Layout, copy.Content.BaseLayout);
                    OutputDetail.ChangeCopy(copy.Content.BaseLayout);
                    copy.Content.Single = plainToClass(Layout, copy.Content.Single);
                    OutputDetail.ChangeCopy(copy.Content.Single);
                    copyPC.SingleContent = copy.Content;
                    break;
                case OutputPageType.Multi:
                    copy.Content.BaseLayout = plainToClass(Layout, copy.Content.BaseLayout);
                    OutputDetail.ChangeCopy(copy.Content.BaseLayout);
                    copy.Content.Single = plainToClass(Layout, copy.Content.Single);
                    OutputDetail.ChangeCopy(copy.Content.Single);
                    copy.Content.MultiStart = plainToClass(Layout, copy.Content.MultiStart);
                    OutputDetail.ChangeCopy(copy.Content.MultiStart);
                    copy.Content.MultiMiddle = plainToClass(Layout, copy.Content.MultiMiddle);
                    OutputDetail.ChangeCopy(copy.Content.MultiMiddle);
                    copy.Content.MultiEnd = plainToClass(Layout, copy.Content.MultiEnd);
                    OutputDetail.ChangeCopy(copy.Content.MultiEnd);
                    copyPC.MultiContent = copy.Content;
                    const repeat = this.PageContents[elem.ID].Repeatings;
                    if (repeat) {
                        copyPC.Repeatings = JSON.parse(JSON.stringify(repeat));
                    }
                    break;
            }
        }
        this.SelectedItem.Pages.splice(i + 1, 0, copy);
        this.OnItemChanged();
    }

    copyPageContent(page, source, target) {
        const pageContent = page.Content[source];
        if (pageContent) {
            const copy = plainToClass(Layout, JSON.parse(JSON.stringify(pageContent)));
            OutputDetail.ChangeCopy(copy);
            page.Content[target] = copy;
            if (target === 'MultiStart' || target === 'MultiMiddle' || target === 'MultiEnd') {
                this.readRepeatings(page);
            }
            this.OnItemChanged();
        }
    }

    SwitchOrientation(elem) {
        if (elem.Content) {
            const pc = this.PageContents[elem.ID];
            switch (elem.OutputPageType) {
                case OutputPageType.Single:
                    this.switchPageOrientation(elem.Content.BaseLayout);
                    this.switchPageOrientation(elem.Content.Single);
                    if (pc.MultiContent) {
                        this.switchPageOrientation(pc.MultiContent.BaseLayout);
                        this.switchPageOrientation(pc.MultiContent.Single);
                        this.switchPageOrientation(pc.MultiContent.MultiStart);
                        this.switchPageOrientation(pc.MultiContent.MultiMiddle);
                        this.switchPageOrientation(pc.MultiContent.MultiEnd);
                    }
                    break;
                case OutputPageType.Multi:
                    this.switchPageOrientation(elem.Content.BaseLayout);
                    this.switchPageOrientation(elem.Content.Single);
                    this.switchPageOrientation(elem.Content.MultiStart);
                    this.switchPageOrientation(elem.Content.MultiMiddle);
                    this.switchPageOrientation(elem.Content.MultiEnd);
                    if (pc.SingleContent) {
                        this.switchPageOrientation(pc.SingleContent.BaseLayout);
                        this.switchPageOrientation(pc.SingleContent.Single);
                    }
                    break;
            }
            this.OnItemChanged();
        }
    }

    private switchPageOrientation(elem) {
        if (elem) {
            if (elem.Height && elem.Width && elem.Width.Value > elem.Height.Value) {
                elem.Height = new LayoutUnit();
                elem.Height.Type = UnitType.Pixel;
                elem.Height.Value = 1122;
                elem.Width = new LayoutUnit();
                elem.Width.Type = UnitType.Pixel;
                elem.Width.Value = 794;
            } else {
                elem.Height = new LayoutUnit();
                elem.Height.Type = UnitType.Pixel;
                elem.Height.Value = 794;
                elem.Width = new LayoutUnit();
                elem.Width.Type = UnitType.Pixel;
                elem.Width.Value = 1122;
            }
            if (elem.ValuesChanged) {
                elem.ValuesChanged.next();
            }
        }
    }

    MoveToFirst(page, index) {
        this.SelectedItem.Pages.splice(index, 1);
        this.SelectedItem.Pages.splice(0, 0, page);
        this.OnItemChanged();
    }

    MoveUp(page, index) {
        this.SelectedItem.Pages.splice(index, 1);
        this.SelectedItem.Pages.splice(index - 1, 0, page);
        this.OnItemChanged();
    }

    MoveDown(page, index) {
        this.SelectedItem.Pages.splice(index, 1);
        this.SelectedItem.Pages.splice(index + 1, 0, page);
        this.OnItemChanged();
    }

    MoveToLast(page, index) {
        this.SelectedItem.Pages.splice(index, 1);
        this.SelectedItem.Pages.push(page);
        this.OnItemChanged();
    }

    pageTypeChanged(page) {
        let pc = this.PageContents[page.ID];
        if (!pc) {
            pc = {};
            this.PageContents[page.ID] = pc;
        }
        switch (page.OutputPageType) {
            case OutputPageType.Single:
                pc.MultiContent = page.Content;
                if (!pc.SingleContent) {
                    const size = {
                        Height: 1122,
                        Width: 794
                    };
                    if (page.Content) {
                        const baseCopy = plainToClass(Layout, JSON.parse(JSON.stringify(page.Content.BaseLayout)));
                        OutputDetail.ChangeCopy(baseCopy);
                        const singleCopy = plainToClass(Layout, JSON.parse(JSON.stringify(page.Content.Single)));
                        OutputDetail.ChangeCopy(singleCopy);
                        pc.SingleContent = {
                            BaseLayout: baseCopy,
                            Single: singleCopy
                        };
                    } else {
                        pc.SingleContent = {
                            BaseLayout: OutputDetail.GetNewPage(this.translate.instant('@@Single Page BaseLayout'), size),
                            Single: OutputDetail.GetNewPage(this.translate.instant('@@Single Page'), size)
                        };
                    }
                }
                page.Content = pc.SingleContent;
                break;
            case OutputPageType.Multi:
                pc.SingleContent = page.Content;
                if (!pc.MultiContent) {
                    const size = {
                        Height: 1122,
                        Width: 794
                    };
                    if (page.Content) {
                        const bl = page.Content.BaseLayout;
                        if (bl.Height && bl.Width && bl.Width.Value > bl.Height.Value) {
                            size.Height = 794;
                            size.Width = 1122;
                        }
                        const baseCopy = plainToClass(Layout, JSON.parse(JSON.stringify(page.Content.BaseLayout)));
                        OutputDetail.ChangeCopy(baseCopy);
                        const singleCopy = plainToClass(Layout, JSON.parse(JSON.stringify(page.Content.Single)));
                        OutputDetail.ChangeCopy(singleCopy);
                        pc.MultiContent = {
                            BaseLayout: baseCopy,
                            Single: singleCopy
                        };
                    } else {
                        pc.MultiContent = {
                            BaseLayout: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page BaseLayout'), size),
                            Single: OutputDetail.GetNewPage(this.translate.instant('@@Multi Page Single'), size),
                        };
                    }
                    pc.MultiContent.MultiStart = OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiStart'), size);
                    pc.MultiContent.MultiMiddle = OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiMiddle'), size);
                    pc.MultiContent.MultiEnd = OutputDetail.GetNewPage(this.translate.instant('@@Multi Page MultiEnd'), size);
                }
                page.Content = pc.MultiContent;
                break;
        }
        this.OnItemChanged();
    }

    dataModelChanged(page) {
        page.DataSource = null;
        this.dataSourceChanged(page);
    }

    dataSourceChanged(page) {
        if (page.Content) {
            const pc = this.PageContents[page.ID];
            switch (page.OutputPageType) {
                case OutputPageType.Single:
                    page.Content.BaseLayout.DataSource = page.DataSource;
                    page.Content.Single.DataSource = page.DataSource;
                    if (pc.MultiContent) {
                        pc.MultiContent.BaseLayout.DataSource = page.DataSource;
                        pc.MultiContent.Single.DataSource = page.DataSource;
                        pc.MultiContent.MultiStart.DataSource = page.DataSource;
                        pc.MultiContent.MultiMiddle.DataSource = page.DataSource;
                        pc.MultiContent.MultiEnd.DataSource = page.DataSource;
                    }
                    break;
                case OutputPageType.Multi:
                    page.Content.BaseLayout.DataSource = page.DataSource;
                    page.Content.Single.DataSource = page.DataSource;
                    page.Content.MultiStart.DataSource = page.DataSource;
                    page.Content.MultiMiddle.DataSource = page.DataSource;
                    page.Content.MultiEnd.DataSource = page.DataSource;
                    if (pc.SingleContent) {
                        pc.SingleContent.BaseLayout.DataSource = page.DataSource;
                        pc.SingleContent.Single.DataSource = page.DataSource;
                    }
                    break;
            }
            this.OnItemChanged();
        }
    }
}
