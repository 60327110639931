import { AFormula, FormulaGroups } from "./iformula";
import { ValueAndType } from "../basic/formulaEditor.model";
import { TranslateHelper } from "../../helpers/injector.helper";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";

export class DateAdd extends AFormula {
    static StaticID: string = '8381c267-0583-4758-8141-2285dd201fd3';

    ID(): string {
        return DateAdd.StaticID;
    }

    Name(): string {
        return 'DateAdd';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Ermoeglicht das Aufaddieren von Jahren, Monaten oder Tagen auf einen Datumswert');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 101;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '('
            + TranslateHelper.TranslatorInstance.instant('@@Datumswert') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Jahr') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Monat') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Tag') + ')';
    }

    MinVariableCount(): number {
        return 4;
    }

    MaxVariableCount(): number {
        return 4;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        if (args && args.length >= 4) {
            var date = args[0].Value;
            if (date instanceof Date) {
                var ms = date.getTime();
                if (!isNaN(ms)) {
                    var retDate = new Date(ms);
                    var year = Number(args[1].Value);
                    if (isNaN(year)) {
                        year = 0;
                    }
                    var month = Number(args[2].Value);
                    if (isNaN(month)) {
                        month = 0;
                    }
                    retDate.setMonth(retDate.getMonth() + year * 12 + month);
                    var day = Number(args[3].Value);
                    if (!isNaN(day)) {
                        retDate = new Date(retDate.getTime() + day * 24 * 60 * 60 * 1000)
                    }
                    retVal.Type = ValueType.Datetime;
                    retVal.Value = retDate;
                }
            }
        }            
        return retVal;
    }
}