import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class CustomRestService extends RequestBase {

    BasePath = 'api/customrest';

    constructor(http: HttpClient) {
        super(http);
    }
  
    Read(filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Read', filter, this.options);
    }
    ReadAll(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadAll', this.options);
    }
    ReadByID(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ReadByID?id=' + id, this.options);
    }

    AddOrUpdate(data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdate', data, this.options);
    }

    Remove(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/Remove?id=' + id, this.options);
    }
}