import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';


@Component({
    selector: 'togglebuttongroup-theme-control',
    templateUrl: './togglebuttongroup.theme.control.html',
    styleUrls: ['./togglebuttongroup.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TogglebuttongroupThemeControl extends BaseThemeControl {
    

    value: any;
    

    //#region Buttons
    ButtonsValue;
    get Buttons() {
        return this.ButtonsValue;
    }
    set Buttons(val) {
        this.ButtonsValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion  

    clearValue(): void {
        this.DataSourceInternal = null;
    }

    onValChanged(val) {
        this.DataSourceInternal = val;
    }

    
}
