import { Component } from '@angular/core';
import { OfflineHelper } from '../../../helpers/offline.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'set-offline-value-settings',
    templateUrl: './set.offline.value.settings.html',
    styleUrls: ['./set.offline.value.settings.css']
})
export class SetOfflineValueSettings extends WorkflowDialogContent {
    ActualStatusKey = '';
    KeepParam = true;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setOfflineValueWFModule';
        reg.Caption = '@@Offlinewert setzen';
        reg.GroupID = 'wfOffline';
        reg.Index = 10;
        reg.SettingsControl = SetOfflineValueSettings;
        reg.SettingsTypeHelper = new SetOfflineValueDataHelper();
        reg.Executer = SetOfflineValueExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.StatusKey) {
                this.ActualStatusKey = data.StatusKey;
            }
            if (typeof data.KeepActualParam === 'boolean') {
                this.KeepParam = data.KeepActualParam;
            }
        }
    }

    getResult(): any {
        const retVal = new SetOfflineValueData();
        retVal.StatusKey = this.ActualStatusKey;
        retVal.KeepActualParam = this.KeepParam;
        return retVal;
    }
}

export class SetOfflineValueData extends AServiceWorkflowData {
    StatusKey = '';
    KeepActualParam = true;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.WFOffline.SetOfflineValue';
    }
}

export class SetOfflineValueDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new SetOfflineValueData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.StatusKey) {
            return settings.StatusKey;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class SetOfflineValueExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.StatusKey) {
                OfflineHelper.SetWorkflowData(status.ActualSettings.StatusKey, status.ActualParameter);
                if (!status.ActualSettings.KeepActualParam) {
                    status.ActualParameter = null;
                }
                return 0;
            } else {
                status.Logger.logError('SetOfflineValue module: No status key found.');
            }
        } else {
            status.Logger.logError('SetOfflineValue module: No settings found.');
        }
        return super.execute(status);
    }
}
