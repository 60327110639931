import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, KeyValueDiffers, Output, ViewEncapsulation } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { BehaviorSubject } from 'rxjs';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { Raster } from '../../../models/layout/raster.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-raster',
    templateUrl: './raster.layout.html',
    styleUrls: ['./raster.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RasterLayout extends IBaseComponent {

    static Type: any = 'raster';
    static Default: GridsterConfig = {
        Options: {
            gridType: GridType.Fit,
            maxCols: 30,
            minCols: 30,
            maxRows: 20,
            minRows: 20,
            minItemCols: 1,
            maxItemCols: 30,
            minItemRows: 1,
            maxItemRows: 20,
            outerMargin: false,
            margin: 0,
            compactType: CompactType.None,
            enableEmptyCellDrop: true,
            displayGrid: DisplayGrid.OnDragAndResize,
            pushItems: false,
            draggable: {
                enabled: true,
                ignoreContentClass: 'gridster-item-content',
                ignoreContent: true,
                dragHandleClass: 'drag-handler'
            },
            resizable: {
                enabled: false,
                handles: {
                    s: true,
                    e: true,
                    n: true,
                    w: true,
                    se: true,
                    ne: true,
                    sw: true,
                    nw: true
                }
            }
        }, Items: null, Editable: true, Type: 'raster'
    };

    TempOptions;
    dragItem;
    //#region Options
    @Input()
    get Options(): GridsterConfig {
        return this.LayoutElement.RasterOptions;
    }
    set Options(val: GridsterConfig) {
        if (val) {
            this.CheckLayoutElement();
            this.LayoutElement.RasterOptions = val;
            this.LayoutElement.RasertOptions.emptyCellDropCallback = this.emptyCellClick.bind(this);
            this.changedOptions();
            this.OptionsChange.emit(val);
        }
    }

    @Output() OptionsChange = new EventEmitter<GridsterConfig>();
    //#endregion
    //#region Items
    ItemsValue: Array<GridsterItem>;

    @Input()
    get Items(): Array<GridsterItem> {
        return this.ItemsValue;
    }
    set Items(val: Array<GridsterItem>) {
        this.ItemsValue = val;
        this.ItemsChange.emit(this.ItemsValue);
    }

    @Output() ItemsChange = new EventEmitter<Array<GridsterItem>>();
    //#endregion

    //#region Editable
    EditableValue: boolean;

    @Input()
    get Editable(): boolean {
        return this.EditableValue;
    }
    set Editable(val: boolean) {
        this.EditableValue = val;

        this.changedOptions();

        this.EditableChange.emit(this.EditableValue);
    }

    @Output() EditableChange = new EventEmitter<boolean>();
    //#endregion
    differ;
    dragType;
    gridster;
    constructor(private settingsService: SettingsService, differs: KeyValueDiffers,
        cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.differ = differs.find([]).create();
    }

    ControlInitialized() {
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
    }
    Init = true;
    onLayoutElementSet() {
        this.Editable = this.LayoutElementValue.Editable;
        if (!this.LayoutElementValue['RasterOptions']) {
            this.LayoutElementValue['RasterOptions'] = JSON.parse(JSON.stringify(RasterLayout.Default.Options));
        }

        this.LayoutElementValue['RasterOptions'].initCallback = this.onGridInit.bind(this);
        this.Init = false;
        this.cdRef.detectChanges();
        if (this.LayoutElementValue.ValuesChanged) {
            this.Subscriptions['ValuesChanged'] = this.LayoutElementValue.ValuesChanged.subscribe((viewType) => {
                this.changedOptions();
            });
        }
        const sub = this.Subscriptions['Editable'];
        if (sub) {
            sub.unsubscribe();
        }
        this.Subscriptions['Editable'] = this.LayoutElement.EditableChanged.subscribe((value) => this.Editable = value);
        this.ItemsValue = this.LayoutElementValue.Elements;

        this.Subscriptions['ViewType'] = LayoutService.ViewType.subscribe((viewType) => {
            if (viewType === ViewType.Edit) {
                this.LayoutElementValue['RasterOptions'].pushItems = true;
                this.LayoutElementValue['RasterOptions'].draggable = {
                    enabled: true,
                    ignoreContentClass: 'gridster-item-content',
                    ignoreContent: true,
                    dragHandleClass: 'drag-handler'
                }
                this.LayoutElementValue['RasterOptions'].resizable.enabled = true;
            } else {
                this.LayoutElementValue['RasterOptions'].pushItems = this.LayoutElement['pushItems'] ? true : false;
                this.LayoutElementValue['RasterOptions'].draggable.enabled = this.LayoutElement['draggable'] ? true : false;
                this.LayoutElementValue['RasterOptions'].resizable.enabled = this.LayoutElement['resizable'] ? true : false;
            }
            this.changedOptions();
        });
    }

    // ngDoCheck() {
    //    let valuesChanged = false;
    //    // if (this.LayoutElement['RasterOptions']) {
    //    //    const changes = this.differ.diff(this.LayoutElement['RasterOptions']);
    //    //    if (changes) {
    //    //        valuesChanged = true;
    //    //    }
    //    // }
    //    if (valuesChanged) {
    //        this.changedOptions();
    //    }
    // }

    onGridInit(gridster) {
        this.gridster = gridster;
    }

    changedOptions() {
        const opt = this.LayoutElementValue['RasterOptions'];
        if (opt && opt.api && typeof opt.api.optionsChanged === 'function') {
            opt.api.optionsChanged();
            this.cdRef.detectChanges();
        }
    }

    removeItem(item) {
        const index = this.Items.indexOf(item);
        if (index > -1) {
            this.Items.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
            this.cdRef.detectChanges();
        }
    }

    itemSelected(item, event) {
        if (!item.Selected && !this.FromBaseLayout) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
        }
    }
    emptyCellClick(event: MouseEvent, empty: GridsterItem): void {
        this.addItem(empty);
    }

    itemDrop(event) {
        const empty = this.gridster.emptyCell.getValidItemFromEvent(event);
        this.addItem(empty);
    }

    addItem(empty: GridsterItem) {
        let item;
        item = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
        item.x = empty.x;
        item.y = empty.y;
        item.cols = 2;
        item.rows = 1;
        item.Parent = this.LayoutElement;
        this.Items.push(item);
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(item),
            Index: this.LayoutElementValue.Elements.length - 1,
            ParentID: this.LayoutElementValue.ID
        });
        this.cdRef.detectChanges();
    }

    CheckLayoutElement() {
        if (!this.LayoutElement) {
            this.LayoutElement = new Raster();
        }
    }
}
export class RasterPanel extends BasePanel {
    static override SIDS = ['49989bd2-f50d-4bc9-a2cc-2acc9ba0cb74']
    static override ElementType = 'raster'
    static DataSource: BehaviorSubject<any> = new BehaviorSubject(null);
    static override SetSelectedItem(item) {
        if (item && item.ElementType == RasterPanel.ElementType) {
            if (!item.RasterOptions) {
                item.RasterOptions = JSON.parse(JSON.stringify(RasterLayout.Default.Options));
            }
            RasterPanel.DataSource.next(item.RasterOptions);
        }
    }
    static InitPanel() {
        this.InitSelectedItem()
        PROPERTYGROUPS.push({
            SID:'49989bd2-f50d-4bc9-a2cc-2acc9ba0cb74',
            ID: 'raster',
            Caption: '@@Raster',
            Index: 6,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == RasterPanel.ElementType;
            }
        });
        //LayoutService.SelectedItem.subscribe((item) => {
        //    if (item && item.ElementType == RasterPanel.ElementType) {
        //        RasterPanel.SetSelectedItem(item);
        //    }
        //});
        
        PROPERTIES.push({
            ID: "maxCols",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MaximumColumns",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "minCols",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MinimumColumns",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "fixedColWidth",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@FixedColumnWidth",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "maxRows",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MaximumRows",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "minRows",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MinimumRows",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "fixedRowHeight",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@FixedRowHeight",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "maxItemCols",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MaximumItemColumns",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "minItemCols",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MinimumItemColumns",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "maxItemRows",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MaximumItemRows",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "minItemRows",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@MinimumItemRows",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        const GridTypes = [];
        const gtkeys = Object.keys(GridType);
        gtkeys.forEach((key) => {
            GridTypes.push({ value: key, type: GridType[key] });
        });
        PROPERTIES.push({
            ID: "gridType",
            Parent: "raster",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Grid type",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: GridTypes,
                DisplayMemberPath: "value",
                ValueMemberPath: "type"
            }
        })

        const DisplayGrids = [];
        const dgkeys = Object.keys(DisplayGrid);
        dgkeys.forEach((key) => {
            DisplayGrids.push({ value: key, type: DisplayGrid[key] });
        });
        PROPERTIES.push({
            ID: "displayGrid",
            Parent: "raster",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DisplayGrid",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                Placeholder: "@@Select",
                Multiple: false,
                ItemsSource: DisplayGrids,
                DisplayMemberPath: "value",
                ValueMemberPath: "type"
            }
        })



        PROPERTIES.push({
            ID: "mobileBreakpoint",
            Parent: "raster",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@mobileBreakpoint",
            DataSource: RasterPanel.DataSource,
            InitArgs: {
                InputType: "number",
                Minimum: 0
            }
        });
        PROPERTIES.push({
            ID: "pushItems",
            Parent: "raster",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@PushItems"
            }
        });
        PROPERTIES.push({
            ID: "draggable",
            Parent: "raster",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Draggable"
            }
        });
        PROPERTIES.push({
            ID: "resizable",
            Parent: "raster",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@Resizable"
            }
        });
    }
}