import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { NetworkConnection } from '../../../helpers/network.helpers';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { OfflineHelper } from '../../../helpers/offline.helper';
import { MessageType } from '../../../models/enums/messagetype.enum';
import { SyncStatus } from '../../../models/enums/syncstatus.enum';
import { ReceivedMessage } from '../../../models/notification/receivedmessage.model';
import { MonacoPreviewDialog } from '../../dialogs/monacopreview/monaco.preview.dialog';
import moment from 'moment';
import config from '../../../../assets/config.json';
@Component({
    selector: 'evi-messagecenterdialog',
    templateUrl: './messagecenter.dialog.html',
    styleUrls: ['./messagecenter.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageCenterDialog implements OnInit, OnDestroy {

    MessageTypes = [];
    SelectedMessageType = -1;
    SelectedMessage: MessageCenterMessage;

    OfflinePossible;
    Online = false;

    Subscriptions = [];
    SyncDate;
    Changes;
    AllMessages = [];
    Messages = [];

    constructor(private dialogRef: MatDialogRef<MessageCenterDialog>, private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.MessageTypes.push({ Caption: '@@Alle', Value: -1 });
        this.MessageTypes.push({ Caption: '@@Information', Value: MessageType.Information });
        this.MessageTypes.push({ Caption: '@@Exception', Value: MessageType.Error });
        this.MessageTypes.push({ Caption: '@@Warning', Value: MessageType.Warning });
        this.MessageTypes.push({ Caption: '@@Success', Value: MessageType.Success });
        this.SelectedMessageType = this.MessageTypes[0].Value;

        this.OfflinePossible = false;
        let settings: any = localStorage.getItem("GeneralSettings");
        if (settings) {
            settings = JSON.parse(settings);
        }
        if (settings != null) {
            if (settings.Offline && settings.Offline.Active) {
                this.OfflinePossible = true;
            }
        }
    }
    isHaveJobAccess = true;
    ngOnInit(): void {
        const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
        if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
            this.isHaveJobAccess = false;
		}
        OfflineHelper.LoadChanges();
        this.Changes = OfflineHelper.Changes;
        this.Subscriptions.push(NetworkConnection.Online.subscribe((online) => {
            this.Online = online;
        }));
        this.Subscriptions.push(NotificationHelper.ReceivedMessages.subscribe(x => {
            if (x) {
                this.AllMessages = [...x];
            } else {
                this.AllMessages = [];
            }
            this.filterMessages();
        }));
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => {
            x.unsubscribe();
        });
    }

    filterMessages() {
        const messages = [];
        if (this.SelectedMessageType < 0) {
            this.AllMessages.forEach(x => {
                messages.push(new MessageCenterMessage(x));
            });
        } else {
            this.AllMessages.forEach(x => {
                if (x.MessageType === this.SelectedMessageType) {
                    messages.push(new MessageCenterMessage(x));
                }
            });
        }
        this.Messages = messages;
    }

    okClicked() {
        this.dialogRef.close();
    }

    clearClicked() {
        this.SelectedMessage = null;
        NotificationHelper.ClearMessages();
    }

    messageTypeClicked() {
        this.SelectedMessage = null;
        this.filterMessages();
    }

    onItemClick(item) {
        if (this.SelectedMessage !== item) {
            this.SelectedMessage = item;
            this.SelectedMessage.setRead();
        }
    }

    copy() {
        if (this.SelectedMessage) {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = this.SelectedMessage.getMessageCopyText();
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }

    ResetDB() {
        OfflineHelper.DeleteDB().then((result) => {
            OfflineHelper.GenerateDB();
            OfflineHelper.StartClientSync(null);
        });
    }
    StartClientSync() {
        OfflineHelper.StartClientSync(new Date(this.SyncDate));
    }
    StartServerSync() {
        OfflineHelper.StartServerSync();
    }
    OpenDocument(item) {
        let args = {
            Document: JSON.stringify(item.Item, null, '\t')
        }
        if (item.Status == SyncStatus.Error) {
            args['Original'] = JSON.stringify(item.Original, null, '\t');
        }
        MonacoPreviewDialog.ShowDialog(args, '@@DocumentPreview');
    }

    GetIcon(item) {
        let retVal = "";
        switch (item.Status) {
            case SyncStatus.Success: retVal = "sync_success"; break;
            case SyncStatus.Waiting: retVal = "sync_waiting"; break;
            case SyncStatus.Processing: retVal = "sync_processing"; break;
            case SyncStatus.Error: retVal = "sync_error"; break;
        }
        return retVal;
    }
    ItemAction(item) {
        if (item.Status == SyncStatus.Waiting) {
            item.Status = SyncStatus.Processing;
            OfflineHelper.SyncSingleDocument(item.ContainerID, item.Item).then((result) => {
                if (result && result['Success']) {
                    item.Status = SyncStatus.Success;
                } else {
                    item.Status = SyncStatus.Error;
                    if (result.Document) {
                        item.Original = JSON.parse(result.Document);
                    }
                }
            })
        }
    }
    TakeOffline(item) {
        if (item && item.Item) {
            OfflineHelper.ForceSyncDocument(item, 'Item').then((result) => {
                if (result && result['Success']) {
                    item.Status = SyncStatus.Success;
                } else {
                    item.Status = SyncStatus.Error;
                    if (result.Document) {
                        item.Original = JSON.parse(result.Document);
                    }
                }
            });
        }
    }
    TakeOnline(item) {
        if (item && item.Original) {
            OfflineHelper.ForceSyncDocument(item, 'Original').then((result) => {
                if (result && result['Success']) {
                    item.Status = SyncStatus.Success;
                } else {
                    item.Status = SyncStatus.Error;
                    if (result.Document) {
                        item.Original = JSON.parse(result.Document);
                    }
                }
            });
        }
    }
}

export class MessageCenterMessage {
    private static Styles = {
        0: { background: 'rgb(77,174,81)' },
        1: { background: 'rgb(254,152,2)' },
        2: { background: 'rgb(244,67,54)' },
        3: { background: 'rgb(31,135,229)' }
    };

    Title;
    ShortTitle;
    MediumTitle;
    CreateDateTextShort;
    Content;
    ContentShort;
    Style;
    get Read() {
        return this.message.Read;
    }

    constructor(private message: ReceivedMessage) {
        this.Title = message.Title;
        if (this.Title && this.Title.length > 25) {
            this.ShortTitle = this.Title.substring(0, 22) + '...';
            if (this.Title.length > 60) {
                this.MediumTitle = this.Title.substring(0, 57) + '...';
            } else {
                this.MediumTitle = this.Title;
            }
        } else {
            this.ShortTitle = this.Title;
            this.MediumTitle = this.Title;
        }
        this.CreateDateTextShort = moment(message.CreateDate).format('LLLL');
        if (this.CreateDateTextShort && this.CreateDateTextShort.length > 150) {
            this.CreateDateTextShort = this.CreateDateTextShort.substring(0, 147) + '...';
        }
        this.Content = message.Content;
        if (this.Content && this.Content.length > 150) {
            this.ContentShort = this.Content.substring(0, 147) + '...';
        } else {
            this.ContentShort = this.Content;
        }
        this.Style = MessageCenterMessage.Styles[message.MessageType];
    }

    setRead() {
        this.message.Read = true;
    }

    getMessageCopyText() {
        const translate = TranslateHelper.TranslatorInstance;
        let text = translate.instant('@@Titel:') + ' ' + this.message.Title + '\n\n';
        text += translate.instant('@@Zeit:') + ' ' + this.message.CreateDate.toString() + '\n\n';
        text += translate.instant('@@Nachricht:') + ' ' + this.message.Content;
        return text;
    }
}
