import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { AdvancedFilterComponent } from '../../controls/advancedfilter/advancedfilter.component';
import { AttributeProperty } from '../../../models/ContainerClassObject';
import { RequestColumn } from '../../../models/rest/requestcolumn';
import { RequestSort } from '../../../models/rest/requestsort';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';

@Component({
    selector: 'request-options-control',
    templateUrl: './request.options.control.html',
    styleUrls: ['./request.options.control.css']
})
export class RequestOptionsControl {

    SelectColumns = [];
    FilterColumns = [];
    SortColumns = [];
    FilterInitialized = false;
    IsDialog = false;

    //#region ShowSelectColumns
    ShowSelectColumnsValue = true;
    @Input()
    get ShowSelectColumns() {
        return this.ShowSelectColumnsValue;
    }
    set ShowSelectColumns(val) {
        this.ShowSelectColumnsValue = val;
        this.ShowSelectColumnsChange.emit(val);
    }
    @Output() ShowSelectColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region ShowFilter
    ShowFilterValue = true;
    @Input()
    get ShowFilter() {
        return this.ShowFilterValue;
    }
    set ShowFilter(val) {
        this.ShowFilterValue = val;
        this.ShowFilterChange.emit(val);
    }
    @Output() ShowFilterChange = new EventEmitter<any>();
    //#endregion

    //#region ShowSortColumns
    ShowSortColumnsValue = true;
    @Input()
    get ShowSortColumns() {
        return this.ShowSortColumnsValue;
    }
    set ShowSortColumns(val) {
        this.ShowSortColumnsValue = val;
        this.ShowSortColumnsChange.emit(val);
    }
    @Output() ShowSortColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region Columns
    ColumnsValue = [];
    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        if (val !== this.ColumnsValue) {
            this.ColumnsValue = val;
            this.checkColumns();
            this.checkData();
            this.ColumnsChange.emit(this.ColumnsValue);
        }
    }
    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region RequestOptions
    RequestOptionsValue = RequestOptions.CleanRequestOptions();
    @Input()
    get RequestOptions() {
        return this.RequestOptionsValue;
    }
    set RequestOptions(val) {
        if (val !== this.RequestOptionsValue) {
            this.RequestOptionsValue = val;
            this.checkData();
            this.RequestOptionsChange.emit(this.RequestOptionsValue);
        }
    }
    @Output() RequestOptionsChange = new EventEmitter<any>();
    //#endregion

    //#region Variables
    VariablesValue = [];
    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        this.VariablesValue = val;
        this.VariablesChange.emit(val);
    }
    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion

    //#region SystemVariables
    SystemVariablesValue = [];

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        this.SystemVariablesValue = val;
        this.SystemVariablesChange.emit(this.SystemVariablesValue);
    }
    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion

    @ViewChild('advanced') filterComponent: AdvancedFilterComponent;

    static ShowDialog(args: RequestOptionsDialogArgs, title: string, handler) {
        BaseDialog.ShowDialog({
            ContentType: RequestOptionsControl,
            Handler: (r) => {
                if (r && typeof handler === 'function') {
                    handler(r);
                }
                return true;
            },
            InitArgs: args,
            Title: title
        });
    }

    checkColumns() {
        const selectColumns = [];
        const filterColumns = [];
        const sortColumns = [];
        if (Array.isArray(this.ColumnsValue)) {
            this.ColumnsValue.forEach(x => {
                if (x.CanSelect === true) {
                    selectColumns.push({
                        Name: x.Name,
                        Selected: false
                    });
                }
                if (x.CanFilter === true) {
                    filterColumns.push({
                        IsFilterable: true,
                        PropertyName: x.Name,
                        Caption: x.Name,
                        Type: AttributeProperty.GetFilterTypeFromType(x.Type)
                    });
                }
                if (x.CanSort === true) {
                    sortColumns.push({
                        Name: x.Name
                    });
                }
            });
        } else {
            this.ColumnsValue = [];
        }
        this.SelectColumns = selectColumns;
        this.FilterColumns = filterColumns;
        this.SortColumns = sortColumns;
    }

    checkData() {
        if (this.RequestOptionsValue) {
            if (this.RequestOptionsValue.Columns) {
                this.SelectColumns.forEach(x => {
                    x.Selected = this.RequestOptionsValue.Columns.some(y => y.Name === x.Name);
                });
            }
            if (this.RequestOptionsValue.Filters && this.RequestOptionsValue.Filters.length > 0 && this.filterComponent) {
                this.filterComponent.SetFilter(this.RequestOptionsValue.Filters[0]);
            }
        }
    }

    afterExpand() {
        if (!this.FilterInitialized) {
            const sel = this.RequestOptionsValue;
            if (sel && sel.Filters && sel.Filters.length > 0 && this.filterComponent) {
                this.filterComponent.SetFilter(sel.Filters[0]);
                this.FilterInitialized = true;
            }
        }
    }

    applyFilter() {
        if (this.filterComponent && this.RequestOptionsValue) {
            const filterList = [];
            const filter = this.filterComponent.GetActualFilter();
            if (filter) {
                filterList.push(filter);
            }
            this.RequestOptionsValue.Filters = filterList;
        }
    }

    selectAll(event) {
        if (event) {
            const selected = [];
            if (event.checked) {
                this.SelectColumns.forEach(x => {
                    x.Selected = true;
                    const col = new RequestColumn();
                    col.Name = x.Name;
                    selected.push(col);
                });
            } else {
                this.SelectColumns.forEach(x => {
                    x.Selected = false;
                });
            }
            if (this.RequestOptionsValue) {
                this.RequestOptionsValue.Columns = selected;
            }
        }
    }

    onSelectChange(event, name) {
        if (event && this.RequestOptionsValue) {
            if (event.checked) {
                if (this.RequestOptionsValue.Columns) {
                    if (!this.RequestOptionsValue.Columns.some(x => x.Name === name)) {
                        const col = new RequestColumn();
                        col.Name = name;
                        this.RequestOptionsValue.Columns.push(col);
                    }
                } else {
                    const col = new RequestColumn();
                    col.Name = name;
                    this.RequestOptionsValue.Columns = [col];
                }
            } else if (this.RequestOptionsValue.Columns) {
                for (let i = this.RequestOptionsValue.Columns.length - 1; i >= 0; i--) {
                    if (this.RequestOptionsValue.Columns[i].Name === name) {
                        this.RequestOptionsValue.Columns.splice(i, 1);
                    }
                }
            }
        }
    }

    addSort() {
        if (this.RequestOptionsValue) {
            if (this.RequestOptionsValue.Sort) {
                this.RequestOptionsValue.Sort.push(new RequestSort());
            } else {
                this.RequestOptionsValue.Sort = [new RequestSort()];
            }
        }
    }

    removeSort(i) {
        if (this.RequestOptionsValue && this.RequestOptionsValue.Sort) {
            this.RequestOptionsValue.Sort.splice(i, 1);
        }
    }
    //#region Dialog
    Initialize(args) {
        this.IsDialog = true;
        if (args) {
            if (args.ShowSelectColumns === false) {
                this.ShowSelectColumns = false;
            }
            if (args.ShowFilter === false) {
                this.ShowFilter = false;
            }
            if (args.ShowSortColumns === false) {
                this.ShowSortColumns = false;
            }
            if (args.Columns) {
                this.Columns = args.Columns;
            }
            if (args.RequestOptions) {
                this.RequestOptions = JSON.parse(JSON.stringify(args.RequestOptions));
            }
            if (Array.isArray(args.Variables)) {
                this.VariablesValue = args.Variables;
            }
            if (Array.isArray(args.SystemVariables)) {
                this.SystemVariablesValue = args.SystemVariables;
            }
        }
    }

    GetDialogResult() {
        this.applyFilter();
        return this.RequestOptions;
    }
    //#endregion
}

export class RequestOptionsDialogArgs {
    ShowSelectColumns = true;
    ShowFilter = true;
    ShowSortColumns = true;
    Columns: RequestOptionsColumn[] = [];
    RequestOptions = new RequestOptions();
    Variables;
    SystemVariables;
}

export class RequestOptionsColumn {
    Name: string;
    Type: string;
    CanSelect = true;
    CanFilter = true;
    CanSort = true;
}
