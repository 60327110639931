import { WorkflowDialogContent } from '../../../workflow.dialog';
import { Component } from '@angular/core';
import { WorkflowModuleSettingsHelper, WorkflowExitInfo, WorkflowRegistry } from '../../../../services/workflow.service';
import { serialize, deserialize } from 'class-transformer';
import { MediaService } from '../../../../services/media.service';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';

export class EditImageSettingsData extends AServiceWorkflowData {
    MediaSource: string;
    FileName: string;
    ReadFileNameFromStatus = false;
    NewHeight = 100;
    NewWidth = 100;
    KeepRatio = true;
    EditOriginal = false;
    Suffix = 'thumbnail';

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.EditImage.EditImageSettingsData';
    }
}

export class EditImageSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new EditImageSettingsData();
    }
}

@Component({
    selector: 'wf-editimage-settings',
    templateUrl: './editimage.settings.html',
    styleUrls: ['./editimage.settings.css']
})
export class EditImageSettings extends WorkflowDialogContent {
    Data = new EditImageSettingsData();
    MediaSources = [];

    static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'editImageWFModule';
        reg.Caption = '@@Bild bearbeiten';
        reg.GroupID = 'wfActions';
        reg.Index = 60;
        reg.SettingsControl = EditImageSettings;
        reg.SettingsTypeHelper = new EditImageSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: MediaService) {
        super();
    }

    ngOnInit(): void {
        this.service.Get().subscribe(r => {
            if (r) {
                this.MediaSources = r;
            }
        });
    }

    initialize(data: any) {
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(EditImageSettingsData, seri);
        }
    }

    getResult() {
        return this.Data;
    }
}
