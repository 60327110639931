import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class WeekFormula extends AFormula {
    static StaticID = '5c78899d-1c3d-4182-8631-ab32f01c3c23';

    ID(): string {
        return WeekFormula.StaticID;
    }

    Name(): string {
        return 'Week';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Gibt in Verbindung mit einer Zeitangabe die Woche aus');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 10;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Argument') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (maxType === ValueType.Datetime && args && args.length > 0) {
            const date = args[0].Value;
            if (date instanceof Date && !isNaN(date.getTime())) {
                retVal.Type = ValueType.Long;
                // TODO: Culture???
                const tdt = new Date(date.valueOf());
                const dayn = (date.getDay() + 6) % 7;
                tdt.setDate(tdt.getDate() - dayn + 3);
                const firstThursday = tdt.valueOf();
                tdt.setMonth(0, 1);
                if (tdt.getDay() !== 4) {
                    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
                }
                retVal.Value = 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
            }
        }
        return retVal;
    }
}
