import { MultiCacheService } from '../../../cache/multi/cache.service';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { FormulaNodeInformation, ValueAndType, VariablesNodeInformation } from '../../basic/formulaEditor.model';
import { ValueFormulaNodeCalculator } from '../../calculation/formula.node.calculator';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { LevelType } from '../../enums';
import { FormulaInputMode } from '../../enums/formulainputmode.enum';
import { FormulaNodeType } from '../../enums/formulanodetype.enum';
import { AxisType } from '../../enums/query.enum';
import { SpecialElementType } from '../../enums/specialElementType';
import { ValueType } from '../../enums/valuetype.enum';
import { TranslatedString } from '../../translatedstring.model';
import { ATask, ITaskExecuter } from '../atask.model';
import { CalculateTaskExecuter } from './calculate.model';

export class EditTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;


    MeasureUniqueID: number;
    LevelUniqueID;
    Formula: string;
    IgnoreNotVisibleMeasures = false;

    Execute() {
    }
}

export class EditTaskExecuter implements ITaskExecuter {
    static TaskID = 'edit';

    LevelUniqueID = -1;
    MeasureUniqueID = -1;
    Formula: FormulaNodeInformation;
    IgnoreNotVisibleMeasures = false;
    Result: MultiResult;
    DataDescription: DataDescription;

  // MeasureRootNodes: AxisNode[];
    //OppositeAxis: Axis;
    UsedKeys;
    GetValue;
    Variables = [];
    //MeasureNode: AxisNode;
    CellAction;

    static async GetMeasuresAndLevelsForCalcTask(dd: DataDescription, uniqueID) {
        const retVal = {
            Measures: [],
            Levels: []
        };
        if (dd) {
            const calcTasks = [];
            if (dd.Tasks) {
                dd.Tasks.some(t => {
                    if (t.TaskType === EditTaskExecuter.TaskID) {
                        if (t.MeasureUniqueID === uniqueID || t.LevelUniqueID === uniqueID) {
                            return true;
                        }
                    } else if (t.TaskType === CalculateTaskExecuter.TaskID) {
                        calcTasks.push(t.MeasureUniqueID);
                    }
                    return false;
                });
            }
            let taskArea;
            const measures = [];
            const xLevels = [];
            dd.XLevelNodes.Areas.forEach((area, i) => {
                let areaCaption;
                if (dd.XLevelNodes.Areas.length > 1) {
                    areaCaption = TranslatedString.GetTranslation(area.Caption);
                    if (!areaCaption) {
                        areaCaption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + (i + 1);
                    }
                }
                if (area.Tuples) {
                    area.Tuples.forEach(t => {
                        if (t.Levels) {
                            t.Levels.forEach(l => {
                                xLevels.push(l);
                                if (l.UniqueID === uniqueID) {
                                    taskArea = {
                                        Caption: areaCaption,
                                        Area: area
                                    };
                                }
                            });
                        }
                    });
                }
                if (area.Measures && area.Measures.length > 0) {
                    area.Measures.forEach(m => {
                        if (m.SpecialElementType === SpecialElementType.None) {
                            measures.push({
                                AreaCaption: areaCaption,
                                Measure: m
                            });
                        } else if (m.SpecialElementType === SpecialElementType.CalculatedElement) {
                            if (m.UniqueID === uniqueID) {
                                taskArea = {
                                    Caption: areaCaption,
                                    Area: area
                                };
                            } else if (calcTasks.some(ct => ct === m.UniqueID)) {
                                measures.push({
                                    AreaCaption: areaCaption,
                                    Measure: m
                                });
                            }
                        }
                    });
                }
            });
            // Measures auf X
            const measureAxisLevels = [];
            const oppositeLevels = [];
            if (measures.length > 0) {
                measureAxisLevels.push(...xLevels);
                dd.YLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(t => {
                            if (t.Levels) {
                                oppositeLevels.push(...t.Levels);
                            }
                        });
                    }
                });
            } else {
                oppositeLevels.push(...xLevels);
                dd.YLevelNodes.Areas.forEach((area, i) => {
                    let areaCaption;
                    if (dd.YLevelNodes.Areas.length > 1) {
                        areaCaption = TranslatedString.GetTranslation(area.Caption);
                        if (!areaCaption) {
                            areaCaption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + i;
                        }
                    }
                    if (area.Tuples) {
                        area.Tuples.forEach(t => {
                            if (t.Levels) {
                                t.Levels.forEach(l => {
                                    measureAxisLevels.push(l);
                                    if (l.UniqueID === uniqueID) {
                                        taskArea = {
                                            Caption: areaCaption,
                                            Area: area
                                        };
                                    }
                                });
                            }
                        });
                    }
                    if (area.Measures && area.Measures.length > 0) {
                        area.Measures.forEach(m => {
                            if (m.SpecialElementType === SpecialElementType.None) {
                                measures.push({
                                    AreaCaption: areaCaption,
                                    Measure: m
                                });
                            } else if (m.SpecialElementType === 1) {
                                if (m.UniqueID === uniqueID) {
                                    taskArea = {
                                        Caption: areaCaption,
                                        Area: area
                                    };
                                } else if (calcTasks.some(ct => ct === m.UniqueID)) {
                                    measures.push({
                                        AreaCaption: areaCaption,
                                        Measure: m
                                    });
                                }
                            }
                        });
                    }
                });
            }
            for (let i = 0; i < measureAxisLevels.length; i++) {
                const level = await MultiCacheService.GetLevel(measureAxisLevels[i].Level, dd.DataModelID);
                if (!level || level.LevelType !== LevelType.All) {
                    measures.splice(0);
                    if (taskArea && taskArea.Area.Measures) {
                        taskArea.Area.Measures.forEach(m => {
                            if (m.SpecialElementType === SpecialElementType.None) {
                                measures.push({
                                    AreaCaption: taskArea.Caption,
                                    Measure: m
                                });
                            } else if (m.SpecialElementType === 1) {
                                if (calcTasks.some(ct => ct === m.UniqueID)) {
                                    measures.push({
                                        AreaCaption: taskArea.Caption,
                                        Measure: m
                                    });
                                }
                            }
                        });
                    }
                    break;
                }
            }
            retVal.Measures.push(...measures);
            if (taskArea && taskArea.Area.Tuples) {
                taskArea.Area.Tuples.forEach(t => {
                    if (t.Levels) {
                        retVal.Levels.push(...t.Levels);
                    }
                });
            }
            retVal.Levels.push(...oppositeLevels);
        }
        return retVal;
    }

    static GetAllVariableKeys(fni: FormulaNodeInformation) {
        const retVal: any = {};
        if (fni) {
            if (fni.Type === FormulaNodeType.Variable) {
                if (fni.VariableID) {
                    retVal[fni.VariableID] = 0;
                }
            } else if (fni.Type === FormulaNodeType.Operation) {
                fni.Operands.forEach(x => {
                    const opKeys = EditTaskExecuter.GetAllVariableKeys(x);
                    Object.assign(retVal, opKeys);
                });
            }
        }
        return retVal;
    }

    async Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings && ((settings.MeasureUniqueID && settings.MeasureUniqueID > 0) || (settings.LevelUniqueID && settings.LevelUniqueID > 0))) {
            this.MeasureUniqueID = settings.MeasureUniqueID;
            this.LevelUniqueID = settings.LevelUniqueID;
            this.IgnoreNotVisibleMeasures = settings.IgnoreNotVisibleMeasures;
            if (settings.Formula) {
                const variables = [];
                const variableInfo = await EditTaskExecuter.GetMeasuresAndLevelsForCalcTask(dataDescription, settings.MeasureUniqueID > 0 ?
                    settings.MeasureUniqueID : settings.LevelUniqueID);
                variableInfo.Measures.forEach(m => {
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = vni.AliasKey = 'M' + m.Measure.UniqueID;
                    variables.push(vni);
                });
                variableInfo.Levels.forEach(l => {
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = vni.AliasKey = 'L' + l.UniqueID;
                    variables.push(vni);
                });
                this.Variables = variables;
                const parser = new AdvancedFormulaParser();
                parser.SetVariables(variables, FormulaInputMode.AliasKey);
                this.Formula = parser.Parse(settings.Formula);
                if (this.Formula && this.Result && this.DataDescription) {
                    this.UsedKeys = {
                        MeasureIDs: [],
                        LevelIDs: []
                    };
                    const allKeysObj = EditTaskExecuter.GetAllVariableKeys(this.Formula);
                    Object.keys(allKeysObj).forEach(key => {
                        if (key.length > 1) {
                            if (key[0] === 'M') {
                                const id = parseInt(key.substring(1), 10);
                                if (!isNaN(id)) {
                                    this.UsedKeys.MeasureIDs.push({
                                        ID: id,
                                        ExternalPosition: null,
                                        Visible: true
                                    });
                                }
                            } else if (key[0] === 'L') {
                                const id = parseInt(key.substring(1), 10);
                                if (!isNaN(id)) {
                                    this.UsedKeys.LevelIDs.push(id);
                                }
                            }
                        }
                    });
                 
                    if (context) {
                        if (!context.CalcTaskExecuter) {
                            context.CalcTaskExecuter = [this];
                        } else {
                            context.CalcTaskExecuter.push(this);
                        }
                    }
                }
            }
        }
    }

    Execute() {
        if (this.LevelUniqueID && this.LevelUniqueID > 0) {
            let axisNodes = MultiResultHelper.GetNodesFlat(this.Result.XAxis, this.LevelUniqueID);
            if (axisNodes && axisNodes.length > 0) {
                axisNodes.forEach(n => {
                    this.IterateAxis(n, {}, this.Result.YAxis.Nodes);
                });
            }
            else {
                let axisNodes = MultiResultHelper.GetNodesFlat(this.Result.YAxis, this.LevelUniqueID);
                if (axisNodes && axisNodes.length > 0) {
                    axisNodes.forEach(n => {
                        this.IterateAxis(n, {}, this.Result.XAxis.Nodes);
                    });
                }
            }
        }
        else if (this.MeasureUniqueID && this.MeasureUniqueID > 0) {
            let node = this.Result.Measures.Nodes.find(x => x.UniqueID === this.MeasureUniqueID);
            if (node) {
                if (node.Axis === AxisType.X_Axis) {
                    this.IterateAxis(node, {}, this.Result.YAxis.Nodes);
                }
                else {
                    this.IterateAxis(node, {}, this.Result.XAxis.Nodes);
                }
            }
        }

    }

    IterateAxis(node: AxisNode, levelInfo, oppNodes: AxisNode[]) {
        const axisClone = Object.assign({}, levelInfo);
        axisClone['' + node.UniqueID] = node;
        oppNodes.forEach(oppNode => {
            this.IterateOppositeAxis(node, oppNode, axisClone);
        });
    }

    IterateOppositeAxis(measureNode: AxisNode, oppNode: AxisNode, levelInfo) {
        const axisClone = Object.assign({}, levelInfo);
        axisClone['' + oppNode.UniqueID] = oppNode;
        this.CalcValue(measureNode, oppNode, levelInfo);
        if (oppNode.Children) {
            oppNode.Children.forEach(child => {
                this.IterateOppositeAxis(measureNode, child, levelInfo);
            });
        }
    }

    CalcValue(measureNode, oppNode, levelInfo) {
        const values = new Map<string, ValueAndType>();
        this.UsedKeys.MeasureIDs.forEach(mID => {
            let cellVal;
            if (this.IgnoreNotVisibleMeasures !== true || mID.Visible) { // Sichtbarkeit noch anders pruefen?
                if (mID.ExternalPosition) {
                    cellVal = this.GetValue(this.Result, mID.ExternalPosition.Level, oppNode, mID.ExternalPosition.Measure, false);
                } else {
                    const m = this.Result.Measures.Nodes.find(x => x.UniqueID === mID.ID);
                    if (m) {
                        cellVal = this.GetValue(this.Result, measureNode, oppNode, m, false);
                    }
                }
            }
            if (cellVal) {
                values.set('M' + mID.ID, ValueAndType.GetValueAndTypeFromJSObject(cellVal.InternalValue));
            } else {
                values.set('M' + mID.ID, new ValueAndType());
            }
        });
        this.UsedKeys.LevelIDs.forEach(lID => {
            const levelNode = levelInfo['' + lID];
            if (levelNode) {
                values.set('L' + lID, {
                    Type: ValueType.Object,
                    Value: levelNode
                });
            }
        });

        const fnc = new ValueFormulaNodeCalculator(false, this.Variables, values);
        const value = fnc.Calc(this.Formula);

        measureNode.Caption = value.Value;
        
       // const cell = this.GetValue(this.Result, measureNode, oppNode, this.MeasureNode, true);
        //if (value) {
        //    switch (value.Type) {
        //        case ValueType.Double:
        //            cell.CellType = CellType.Double;
        //            break;
        //        case ValueType.Long:
        //            cell.CellType = CellType.Long;
        //            break;
        //        case ValueType.Datetime:
        //            cell.CellType = CellType.DateTime;
        //            break;
        //        default:
        //            cell.CellType = CellType.String;
        //            break;
        //    }
        //    cell.InternalValue = value.Value;
        //}
        //if (this.CellAction) {
        //    this.CellAction(cell);
        //}
    }
}
