import { Component, OnInit } from "@angular/core";
import { TranslateFormatText } from "../helpers/array.helpers";
import { EnumHelper } from "../helpers/enum.helper";
import { DialogButton } from "../models/enums/dialogbutton.enum";
import { MessageBoxButtons } from "../models/enums/messageboxbuttons.enum";
import { MessageBoxIcon } from "../models/enums/messageboxicon.enum";
import { WorkflowParameterType } from "../models/enums/workflowparametertype.enum";
import { WorkflowParameter } from "../models/workflow/workflow.model";
import { MessageBoxHelper } from "../components/dialogs/messagebox/messagebox.dialog";

@Component({
    selector: 'workflow-param-dialog',
    templateUrl: './workflow.param.dialog.html',
    styleUrls: ['./workflow.param.dialog.css']
})
export class WorkflowParamDialog implements OnInit {

    Parameters = [];
    DataTypes = [];

    ngOnInit(): void {
        this.DataTypes = EnumHelper.GetDropdownValues(WorkflowParameterType);
    }

    Initialize(data) {
        if (data) {
            var params = [];
            data.forEach(function (entry) {
                params.push(JSON.parse(JSON.stringify(entry)));
            });
            this.Parameters = params;
        }
    }

    GetDialogResult() {
        return this.Parameters;
    }

    OnDialogButtonClickAction(button) {
        if (button == DialogButton.Ok) {
            var contains = this.ContainsEmptyOrDoubles();
            if (contains) {
                MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie unterschiedliche Namen fuer alle Parameter'), null, MessageBoxButtons.Ok, MessageBoxIcon.Information);
                return false;
            }
        }
        return true;
    }

    ContainsEmptyOrDoubles(): boolean {
        if (this.Parameters.length > 0) {
            var actParam = this.Parameters[0];
            if (typeof actParam.Name !== 'string' || actParam.Name == '') {
                return true;
            } else if (this.Parameters.length > 1) {
                for (var i = 1; i < this.Parameters.length; i++) {
                    var indexParam = this.Parameters[i];
                    if (typeof indexParam.Name !== 'string' || indexParam.Name == '' || indexParam.Name == actParam.Name) {
                        return true;
                    }
                }
                for (var i = 1; i < this.Parameters.length - 1; i++) {
                    actParam = this.Parameters[i];
                    for (var j = i + 1; j < this.Parameters.length; j++) {
                        if (this.Parameters[j].Name == actParam.Name) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    addParam() {
        this.Parameters.push(new WorkflowParameter());
    }

    deleteParam(index) {
        this.Parameters.splice(index, 1);
    }
}