import { OnInit, Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'variabledefinition',
    templateUrl: './variabledefinition.control.html',
    styleUrls: ['./variabledefinition.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableDefinitionControl implements OnInit {

    DataTypes = [{ Caption: '@@Text', Value: 'System.String' },
    { Caption: '@@Bool', Value: 'System.Boolean' },
    { Caption: '@@Ganzzahl', Value: 'System.Int32' },
    { Caption: '@@Gleitzahl', Value: 'System.Double' },
    { Caption: '@@Datum', Value: 'System.DateTime' }];

    //#region SystemVariables
    SystemVariablesValue;

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        this.SystemVariablesValue = val;
        this.SystemVariablesChange.emit(this.SystemVariablesValue);
    }

    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
    }

    OnFormulaChanged(event, variable) {
        variable.Value = event;
    }

    deleteItem(variable) {
        this.SelectedItem.Variables.splice(this.SelectedItem.Variables.indexOf(variable), 1);
    }

    addItem() {
        if (!this.SelectedItem.Variables) {
            this.SelectedItem.Variables = [];
        }
        this.SelectedItem.Variables.push({
            Name: this.translate.instant('@@Neue Variable'),
            Value: "",
            Type: this.DataTypes[0]
        });
    }
}