import { Component } from '@angular/core';
import { deserialize, plainToClass, serialize } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { DialogResult } from '../../../models/enums/dialogresult.enum';
import { DialogType } from '../../../models/enums/dialogtype.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { Layout } from '../../../models/layout.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { MetaService } from '../../../services/meta.service';
import {
    WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry, WorkflowService
} from '../../../services/workflow.service';
import { IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { BaseDialog, DialogData } from '../../../components/dialogs/basedialog/base.dialog';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { OpenURLNode, URLParameter } from '../navigation/openURL.settings';
import { LayoutDialogContent } from './layout.dialog.content';
import { TemplateDialogContent } from './template.dialog.content';

@Component({
    selector: 'wf-show-content-dialog-settings',
    templateUrl: './show.content.dialog.settings.html'
})
export class ShowContentDialogSettings extends FormulaWorkflowDialogContent {
    Data = new ShowContentDialogSettingsData();
    Pages = [];
    Templates;
    Buttons;
    Workflows = [];

    private static GetNode(navItem, id: IDKeeper) {
        const retVal = new OpenURLNode(id.NextID);
        retVal.Caption = navItem.Label;
        retVal.data = navItem.Url;
        if (navItem.children) {
            retVal.HasChildren = true;
            retVal.Children = [];
            retVal.IsExpanded = true;
            navItem.children.forEach(function (ni) {
                const node = ShowContentDialogSettings.GetNode(ni, id);
                retVal.Children.push(node);
            });
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'showContentDialogWFModule';
        reg.Caption = '@@Dialog anzeigen';
        reg.GroupID = 'wfActions';
        reg.Index = 45;
        reg.SettingsControl = ShowContentDialogSettings;
        reg.SettingsTypeHelper = new ShowContentDialogSettingsDataHelper();
        reg.Executer = ShowContentDialogModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private metaService: MetaService) {
        super();
        this.Buttons = EnumHelper.GetDropdownValues(DialogButton);
    }

    ngOnInit() {
        this.getWorkflows();        
        this.metaService.GetAllPages().subscribe(pages => {
            if (pages) {
                const pageList = [];
                pages.forEach(page => {
                    pageList.push({
                        Caption: page.Caption,
                        URL: page.URL
                    });
                });
                ArrayHelpers.sortAlphabetical(pageList, 'Caption');
                this.Pages = pageList;
            }
        });
        if (!this.WFEditOptions || !this.WFEditOptions.IsTemplate) {
            this.metaService.ReadTemplateInfos().subscribe((templates) => {
                this.Templates = templates;
            });
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ShowContentDialogSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }

    onLayoutChanged() {
        this.Data.OkWorkflow = null;
        this.getWorkflows();
    }

    getWorkflows() {
        this.Workflows = [];
        if (this.Data.Type === DialogType.Content) {
            if (this.Data.URL) {
                this.metaService.LoadPage(this.Data.URL).subscribe(result => {
                    this.fillWorkflows(result);
                });
            }
        } else {
            if (this.Data.Template) {
                this.metaService.LoadTemplate(this.Data.Template).subscribe((result) => {
                    this.fillWorkflows(result);
                });
            }
        }
    }

    fillWorkflows(result) {
        if (result && result.Workflows) {
            const workflows = [];
            result.Workflows.forEach(x => {
                workflows.push({
                    Caption: x.Caption,
                    ID: x.ID
                });
            });
            this.Workflows = workflows;
        }
    }

    nodeSelected(ev) {
        if (ev && ev.data) {
            this.Data.URL = ev.data;
            this.onLayoutChanged();
        }
    }

    addParam() {
        this.Data.Parameters.push(new URLParameter());
    }

    removeParam(i) {
        this.Data.Parameters.splice(i, 1);
    }
}

export class ShowContentDialogSettingsData {
    Title: string;
    Type: DialogType = DialogType.Content;
    Parameters = [];
    URL: string;
    Template: string;
    Buttons: DialogButton = DialogButton.Ok;
    OkWorkflow: string;
    StatusKey: string;
    UseForState = false;
}

export class ShowContentDialogSettingsDataHelper extends WorkflowModuleSettingsHelper {

    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = [];
        if (settings) {
            switch (settings.Buttons) {
                case DialogButton.None:
                    const none = new WorkflowExitInfo();
                    none.ID = DialogResult.None;
                    retVal.push(none);
                    break;
                case DialogButton.Ok:
                    const ok = new WorkflowExitInfo();
                    ok.ID = DialogResult.Ok;
                    retVal.push(ok);
                    break;
                case DialogButton.OkCancel:
                    const ok2 = new WorkflowExitInfo();
                    ok2.ID = DialogResult.Ok;
                    ok2.Label = '@@OK';
                    retVal.push(ok2);
                    const cancel = new WorkflowExitInfo();
                    cancel.ID = DialogResult.Cancel;
                    cancel.Label = '@@Abbrechen';
                    retVal.push(cancel);
                    break;
                case DialogButton.Cancel:
                    const cancel2 = new WorkflowExitInfo();
                    cancel2.ID = DialogResult.Cancel;
                    retVal.push(cancel2);
                    break;
            }
        }
        return retVal;
    }

    getEmptySettingsInstance() {
        return new ShowContentDialogSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey && (settings.Buttons === DialogButton.Ok || settings.Buttons === DialogButton.OkCancel)) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Parameters) {
            settings.Parameters.forEach(x => {
                if (!x.Manual && x.Value === oldName) {
                    x.Value = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class ShowContentDialogModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let dialogResult;
            const data: DialogData = {
                ContentType: null,
                Buttons: status.ActualSettings.Buttons,
                InitArgs: {
                    OkWorkflow: status.ActualSettings.OkWorkflow
                },
                Handler: (e) => {
                    if (e) {
                        dialogResult = e.Data;
                        return e.CanClose === true;
                    }
                    return true;
                },
                AutoFocus: false,
                HideEmptyTitle: true
            };
            if (status.ActualSettings.Title) {
                try {
                    const calculator = new WorkflowFormulaCalculator(status);
                    data.Title = calculator.CalcFormula(status.ActualSettings.Title);
                } catch (e) {
                    const t = 'Dialog modul: Error while calculating title \'' +
                        status.ActualSettings.Title + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            switch (status.ActualSettings.Type) {
                case DialogType.Template:
                    data.ContentType = TemplateDialogContent;
                    data.InitArgs.Template = status.ActualSettings.Template;
                    break;
                case DialogType.Content:
                    data.ContentType = LayoutDialogContent;
                    data.InitArgs.URL = status.ActualSettings.URL;
                    if (status.ActualSettings.Parameters) {
                        const params = [];
                        const calculator = new WorkflowFormulaCalculator(status);
                        status.ActualSettings.Parameters.forEach(x => {
                            const copy = JSON.parse(JSON.stringify(x));
                            if (x.Manual && x.Value) {
                                try {
                                    copy.Value = calculator.CalcFormula(x.Value);
                                } catch (e) {
                                    status.Logger.logWarning('Dialog modul: Error while calculating formula \'' +
                                        x.Value + '\' for parameter \'' + x.Name + '\'');
                                }
                            }
                            params.push(copy);
                        });
                        data.InitArgs.Parameters = params;
                    }
                    break;
            }
            if (data.ContentType) {
                const contextCopy = new Map<string, any>();
                WorkflowStatus.CopyContext(status.Context, contextCopy);
                const tempContext = WorkflowService.DialogStatusCopy.getValue();
                WorkflowService.DialogStatusCopy.next(contextCopy);
                const retVal = await BaseDialog.ShowDialogAsync(data);
                if (retVal === DialogResult.Ok) {
                    if (status.ActualSettings.StatusKey) {
                        status.Context.set(status.ActualSettings.StatusKey, dialogResult);
                    }
                    if (status.ActualSettings.UseForState === true) {
                        status.ActualParameter = dialogResult;
                    }
                }
                WorkflowService.DialogStatusCopy.next(tempContext);
                return retVal;
            } else {
                status.Logger.logError('Dialog modul: No dialog content type set.');
            }
        } else {
            status.Logger.logError('Dialog modul: No settings found.');
        }
        return super.execute(status);
    }
}
