import { Component } from '@angular/core';

@Component({
  selector: 'dtp-reference-dialog',
  templateUrl: './dtp.reference.dialog.html',
  styleUrls: ['./dtp.reference.dialog.css']
})
export class DynamicTimePointReferenceDialog {
  RefList;
  ActRef;
  ActTarget;

  Initialize(args) {
    const list = [];
    if (args && args.References) {
      list.push(...args.References);
    }
    this.RefList = list;
  }

  GetDialogResult() {
    return this.RefList;
  }

  deleteRef(i) {
    this.RefList.splice(i, 1);
  }

  deleteAll() {
    this.RefList = [];
  }

  addRef() {
    if (this.ActRef && this.ActTarget) {
      this.RefList.push({
        ReferenceDate: this.ActRef,
        TargetDate: this.ActTarget
      });
      this.ActRef = null;
      this.ActTarget = null;
    }
  }
}
