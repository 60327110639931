import { serialize, deserialize } from 'class-transformer';
import { UnitType } from '../enums/unittype.enum';
import { BorderSide } from '../style/borderside.model';
import { LayoutUnit } from '../basic/layoutunit.model';
import { Gradient } from '../style/gradient.model';
import { GradientStopColor } from '../style/gradientstopcolor.model';
import { Color } from '../style/color.model';
import { Border } from '../style/border.model';
import { GradientType } from '../enums/gradienttype.enum';
import { CellStyle } from './cell.style';
import { NumberFormat } from './number.format.model';
import { MultiNode } from '../datadescription/multi/multiresult.model';
import { TaskHelper } from '../tasks/task.helper';
import { Font } from '../style/font.model';
import { BorderRadius } from '../style/borderradius.model';

export class MergeStyle extends CellStyle {
    ID = 0;
}

export class StyleMerger {
    private _MaxStyleNumber = 0;
    private _MaxMergeStyleNumber = 0;
    private _ResultingStyles = new Map<number, Map<number, MergeStyle>>();
    private _Styles = new Map<number, MergeStyle>();

    get StyleCount() {
        return this._Styles.size;
    }

    static GetStyleMerger(context): StyleMerger {
        if (context) {
            if (!context.StyleMerger) {
                context.StyleMerger = new StyleMerger();
            }
            return context.StyleMerger;
        }
        return null;
    }

    private static Equals(source: MergeStyle, target: MergeStyle): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            if (source.HorizontalContentAlignment !== target.HorizontalContentAlignment) {
                return false;
            }
            if (source.VerticalContentAlignment !== target.VerticalContentAlignment) {
                return false;
            }
            if (source.HorizontalTextAlignment !== target.HorizontalTextAlignment) {
                return false;
            }
            if (source.VerticalTextAlignment !== target.VerticalTextAlignment) {
                return false;
            }
            if (source.Visible !== target.Visible) {
                return false;
            }
            if (source.Gadget !== target.Gadget) {
                return false;
            }
            const fontCheck = StyleMerger.CheckObjects(source.Font, target.Font);
            if (fontCheck.DoCheck) {
                if (source.Font.Bold !== target.Font.Bold) {
                    return false;
                }
                if (source.Font.Italic !== target.Font.Italic) {
                    return false;
                }
                if (source.Font.Underline !== target.Font.Underline) {
                    return false;
                }
                if (source.Font.FontFamilyName !== target.Font.FontFamilyName) {
                    return false;
                }
                if (source.Font.FontSize !== target.Font.FontSize) {
                    return false;
                }
                const fontColorCheck = StyleMerger.CheckObjects(source.Font.FontColor, target.Font.FontColor);
                if (fontColorCheck.DoCheck) {
                    if (source.Font.FontColor.R !== target.Font.FontColor.R) {
                        return false;
                    }
                    if (source.Font.FontColor.G !== target.Font.FontColor.G) {
                        return false;
                    }
                    if (source.Font.FontColor.B !== target.Font.FontColor.B) {
                        return false;
                    }
                    if (source.Font.FontColor.A !== target.Font.FontColor.A) {
                        return false;
                    }
                } else if (!fontColorCheck.RetVal) {
                    return false;
                }
            } else if (!fontCheck.RetVal) {
                return false;
            }
            if (!StyleMerger.NumberFormatEquals(source.NumberFormat, target.NumberFormat)) {
                return false;
            }
            if (!Gradient.IsSameGradient(source.BackgroundColor, target.BackgroundColor)) {
                return false;
            }
            return StyleMerger.BorderEquals(source.Border, target.Border);
        } else {
            return check.RetVal;
        }
    }

    private static NumberFormatEquals(source: NumberFormat, target: NumberFormat): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            if (source.DecimalDigits !== target.DecimalDigits) {
                return false;
            }
            if (source.ShowNumberGroupSeperator !== target.ShowNumberGroupSeperator) {
                return false;
            }
            if (source.Divisor !== target.Divisor) {
                return false;
            }
            if (source.ChangeSign !== target.ChangeSign) {
                return false;
            }
            if (source.Suffix !== target.Suffix) {
                return false;
            }
            if (source.CustomSuffix !== target.CustomSuffix) {
                return false;
            }
            if (source.CustomPraefix !== target.CustomPraefix) {
                return false;
            }
            if (source.CustomPraefix !== target.CustomPraefix) {
                return false;
            }
            return true;
        } else {
            return check.RetVal;
        }
    }

    private static BorderEquals(source: Border, target: Border): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            if (!StyleMerger.BorderSideEquals(source.LeftBorder, target.LeftBorder)) {
                return false;
            }
            if (!StyleMerger.BorderSideEquals(source.TopBorder, target.TopBorder)) {
                return false;
            }
            if (!StyleMerger.BorderSideEquals(source.RightBorder, target.RightBorder)) {
                return false;
            }
            if (!StyleMerger.BorderSideEquals(source.BottomBorder, target.BottomBorder)) {
                return false;
            }
            const brCheck = StyleMerger.CheckObjects(source.BorderRadius, target.BorderRadius);
            if (brCheck.DoCheck) {
                return StyleMerger.LayoutUnitEquals(source.BorderRadius.BottomLeft, target.BorderRadius.BottomLeft) &&
                    StyleMerger.LayoutUnitEquals(source.BorderRadius.BottomRight, target.BorderRadius.BottomRight) &&
                    StyleMerger.LayoutUnitEquals(source.BorderRadius.TopLeft, target.BorderRadius.TopLeft) &&
                    StyleMerger.LayoutUnitEquals(source.BorderRadius.TopRight, target.BorderRadius.TopRight);
            } else {
                return brCheck.RetVal;
            }
        } else {
            return check.RetVal;
        }
    }

    private static BorderSideEquals(source: BorderSide, target: BorderSide): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            return source.Style === target.Style && Gradient.IsSameGradient(source.Color, target.Color) &&
                StyleMerger.LayoutUnitEquals(source.Thickness, target.Thickness);
        } else {
            return check.RetVal;
        }
    }

    private static LayoutUnitEquals(source: LayoutUnit, target: LayoutUnit): boolean {
        const check = StyleMerger.CheckObjects(source, target);
        if (check.DoCheck) {
            return source.Type === target.Type && source.Value === target.Value;
        } else {
            return check.RetVal;
        }
    }

    static CheckObjects(source, target) {
        const retVal = {
            DoCheck: false,
            RetVal: true
        };
        if (source) {
            if (target) {
                retVal.DoCheck = true;
            } else {
                retVal.RetVal = false;
            }
        } else if (target) {
            retVal.RetVal = false;
        }
        return retVal;
    }

    private static Merge(existingStyle: MergeStyle, mergeStyle: CellStyle): MergeStyle {
        const result = new MergeStyle();
        if (existingStyle.Font) {
            result.Font = deserialize(Font, serialize(existingStyle.Font));
            if (mergeStyle.Font) {
                if (typeof mergeStyle.Font.Bold === 'boolean') {
                    result.Font.Bold = mergeStyle.Font.Bold;
                }
                if (typeof mergeStyle.Font.Italic === 'boolean') {
                    result.Font.Italic = mergeStyle.Font.Italic;
                }
                if (typeof mergeStyle.Font.Underline === 'boolean') {
                    result.Font.Underline = mergeStyle.Font.Underline;
                }
                if (typeof mergeStyle.Font.FontFamilyName === 'string') {
                    result.Font.FontFamilyName = mergeStyle.Font.FontFamilyName;
                }
                if (typeof mergeStyle.Font.FontSize === 'number') {
                    result.Font.FontSize = mergeStyle.Font.FontSize;
                }
                if (mergeStyle.Font.FontColor) {
                    result.Font.FontColor = deserialize(Color, serialize(mergeStyle.Font.FontColor));
                }
            }
        } else if (mergeStyle.Font) {
            result.Font = deserialize(Font, serialize(mergeStyle.Font));
        }
        // Numberformat wird komplett übernommen
        if (mergeStyle.NumberFormat) {
            result.NumberFormat = deserialize(NumberFormat, serialize(mergeStyle.NumberFormat));
        } else if (existingStyle.NumberFormat) {
            result.NumberFormat = deserialize(NumberFormat, serialize(existingStyle.NumberFormat));
        }
        if (typeof mergeStyle.HorizontalContentAlignment === 'number') {
            result.HorizontalContentAlignment = mergeStyle.HorizontalContentAlignment;
        } else {
            result.HorizontalContentAlignment = existingStyle.HorizontalContentAlignment;
        }
        if (typeof mergeStyle.VerticalContentAlignment === 'number') {
            result.VerticalContentAlignment = mergeStyle.VerticalContentAlignment;
        } else {
            result.VerticalContentAlignment = existingStyle.VerticalContentAlignment;
        }
        if (typeof mergeStyle.HorizontalTextAlignment === 'number') {
            result.HorizontalTextAlignment = mergeStyle.HorizontalTextAlignment;
        } else {
            result.HorizontalTextAlignment = existingStyle.HorizontalTextAlignment;
        }
        if (typeof mergeStyle.VerticalTextAlignment === 'number') {
            result.VerticalTextAlignment = mergeStyle.VerticalTextAlignment;
        } else {
            result.VerticalTextAlignment = existingStyle.VerticalTextAlignment;
        }
        if (existingStyle.Border) {
            result.Border = deserialize(Border, serialize(existingStyle.Border));
            if (mergeStyle.Border) {
                result.Border.TopBorder = StyleMerger.MergeBorderSide(mergeStyle.Border.TopBorder, result.Border.TopBorder);
                result.Border.RightBorder = StyleMerger.MergeBorderSide(mergeStyle.Border.RightBorder, result.Border.RightBorder);
                result.Border.BottomBorder = StyleMerger.MergeBorderSide(mergeStyle.Border.BottomBorder, result.Border.BottomBorder);
                result.Border.LeftBorder = StyleMerger.MergeBorderSide(mergeStyle.Border.LeftBorder, result.Border.LeftBorder);
                const mBR = mergeStyle.Border.BorderRadius;
                if (mBR) {
                    const rBR = result.Border.BorderRadius;
                    if (rBR) {
                        rBR.TopLeft = StyleMerger.MergeLayoutUnit(mBR.TopLeft, rBR.TopLeft);
                        rBR.TopRight = StyleMerger.MergeLayoutUnit(mBR.TopRight, rBR.TopRight);
                        rBR.BottomRight = StyleMerger.MergeLayoutUnit(mBR.BottomRight, rBR.BottomRight);
                        rBR.TopLeft = StyleMerger.MergeLayoutUnit(mBR.TopLeft, rBR.TopLeft);
                    } else {
                        result.Border.BorderRadius = deserialize(BorderRadius, serialize(mBR));
                    }
                }
            }
        } else if (mergeStyle.Border) {
            result.Border = deserialize(Border, serialize(mergeStyle.Border));
        }
        if (mergeStyle.BackgroundColor) {
            result.BackgroundColor = deserialize(Gradient, serialize(mergeStyle.BackgroundColor));
        } else if (existingStyle.BackgroundColor) {
            result.BackgroundColor = deserialize(Gradient, serialize(existingStyle.BackgroundColor));
        }
        if (typeof mergeStyle.Visible === 'boolean') {
            result.Visible = mergeStyle.Visible;
        } else {
            result.Visible = existingStyle.Visible;
        }
        if (typeof mergeStyle.Gadget === 'string') {
            result.Gadget = mergeStyle.Gadget;
        } else {
            result.Gadget = existingStyle.Gadget;
        }
        return result;
    }

    private static MergeBorderSide(mergeSide: BorderSide, resultSide: BorderSide): BorderSide {
        if (mergeSide) {
            if (resultSide) {
                resultSide.Thickness = StyleMerger.MergeLayoutUnit(mergeSide.Thickness, resultSide.Thickness);
                if (mergeSide.Color) {
                    resultSide.Color = deserialize(Gradient, serialize(mergeSide.Color));
                }
                if (typeof mergeSide.Style === 'number') {
                    resultSide.Style = mergeSide.Style;
                }
            } else {
                return deserialize(BorderSide, serialize(mergeSide));
            }
        }
        return resultSide;
    }

    private static MergeLayoutUnit(mergeUnit: LayoutUnit, resultUnit: LayoutUnit): LayoutUnit {
        if (mergeUnit && mergeUnit.Value > 0) {
            if (!resultUnit) {
                resultUnit = new LayoutUnit();
            }
            resultUnit.Type = mergeUnit.Type;
            resultUnit.Value = mergeUnit.Value;
        }
        return resultUnit;
    }

    public static GetStyleObject(style: CellStyle) {
        const styleObj = {};
        if (style) {
            if (style.Font) {
                if (typeof style.Font.Bold === 'boolean' && style.Font.Bold) {
                    styleObj['font-weight'] = 'bold';
                }
                if (typeof style.Font.Italic === 'boolean' && style.Font.Italic) {
                    styleObj['font-style'] = 'italic';
                }
                if (typeof style.Font.Underline === 'boolean' && style.Font.Underline) {
                    styleObj['text-decoration'] = 'underline';
                }
                if (typeof style.Font.FontFamilyName === 'string' && style.Font.FontFamilyName) {
                    styleObj['font-family'] = style.Font.FontFamilyName;
                }
                if (typeof style.Font.FontSize === 'number' && style.Font.FontSize > 0) {
                    styleObj['font-size'] = style.Font.FontSize + 'pt';
                }
                if (style.Font.FontColor) {
                    const hex = Color.HexFromColor(style.Font.FontColor);
                    if (hex) {
                        styleObj['color'] = hex;
                    }
                }
            }
            if (typeof style.HorizontalContentAlignment === 'number') {
                switch (style.HorizontalContentAlignment) {
                    case 0:
                        styleObj['justify-content'] = 'flex-start';
                        break;
                    case 1:
                        styleObj['justify-content'] = 'center';
                        break;
                    case 2:
                        styleObj['justify-content'] = 'flex-end';
                        break;
                }
            }
            if (typeof style.VerticalContentAlignment === 'number') {
                switch (style.VerticalContentAlignment) {
                    case 0:
                        styleObj['align-items'] = 'flex-start';
                        break;
                    case 1:
                        styleObj['align-items'] = 'center';
                        break;
                    case 2:
                        styleObj['align-items'] = 'flex-end';
                        break;
                }
            }
            if (typeof style.HorizontalTextAlignment === 'number') {
                switch (style.HorizontalTextAlignment) {
                    case 0:
                        styleObj['justify-content'] = 'flex-start';
                        styleObj['text-align'] = 'start';
                        break;
                    case 1:
                        styleObj['justify-content'] = 'center';
                        styleObj['text-align'] = 'center';
                        break;
                    case 2:
                        styleObj['justify-content'] = 'flex-end';
                        styleObj['text-align'] = 'end';
                        break;
                }
            }
            if (typeof style.VerticalTextAlignment === 'number') {
                switch (style.VerticalTextAlignment) {
                    case 0:
                        styleObj['align-items'] = 'flex-start';
                        break;
                    case 1:
                        styleObj['align-items'] = 'center';
                        break;
                    case 2:
                        styleObj['align-items'] = 'flex-end';
                        break;
                }
            }
            if (style.Border) {
                if (style.Border.LeftBorder) {
                    const borderStyle = style.Border.LeftBorder.toStyle();
                    if (borderStyle) {
                        styleObj['border-left'] = borderStyle;
                    }
                }
                if (style.Border.TopBorder) {
                    const borderStyle = style.Border.TopBorder.toStyle();
                    if (borderStyle) {
                        styleObj['border-top'] = borderStyle;
                    }
                }
                if (style.Border.RightBorder) {
                    const borderStyle = style.Border.RightBorder.toStyle();
                    if (borderStyle) {
                        styleObj['border-right'] = borderStyle;
                    }
                }
                if (style.Border.BottomBorder) {
                    const borderStyle = style.Border.BottomBorder.toStyle();
                    if (borderStyle) {
                        styleObj['border-bottom'] = borderStyle;
                    }
                }
                if (style.Border.BorderRadius) {
                    if (style.Border.BorderRadius.TopLeft) {
                        const radius = style.Border.BorderRadius.TopLeft.toStyle();
                        if (radius) {
                            styleObj['border-top-left-radius'] = radius;
                        }
                    }
                    if (style.Border.BorderRadius.TopRight) {
                        const radius = style.Border.BorderRadius.TopRight.toStyle();
                        if (radius) {
                            styleObj['border-top-right-radius'] = radius;
                        }
                    }
                    if (style.Border.BorderRadius.BottomLeft) {
                        const radius = style.Border.BorderRadius.BottomLeft.toStyle();
                        if (radius) {
                            styleObj['border-bottom-left-radius'] = radius;
                        }
                    }
                    if (style.Border.BorderRadius.BottomRight) {
                        const radius = style.Border.BorderRadius.BottomRight.toStyle();
                        if (radius) {
                            styleObj['border-bottom-right-radius'] = radius;
                        }
                    }
                }
            }
            if (style.BackgroundColor) {
                Object.assign(styleObj, Gradient.getStyleObject(style.BackgroundColor));
            }
            if (style.Visible === false) {
                styleObj['display'] = 'none';
            }
        }
        return styleObj;
    }

    public static GetBorder(thickness, colorR, colorG, colorB, colorA = 255) {
        const retVal = new BorderSide();
        retVal.Thickness = new LayoutUnit();
        retVal.Thickness.Type = UnitType.Pixel;
        retVal.Thickness.Value = thickness;
        retVal.Color = new Gradient();
        const color = new GradientStopColor();
        color.Color = new Color();
        color.Color.R = colorR;
        color.Color.G = colorG;
        color.Color.B = colorB;
        color.Color.A = colorA;
        retVal.Color.Colors.push(color);
        retVal.Color.Type = GradientType.Solid;
        return retVal;
    }

    constructor() {
        const s = new MergeStyle();
        this._Styles.set(s.ID, s);
        this._ResultingStyles.set(s.ID, new Map());
    }

    MergeStyle(actStyleID: number, mergeStyle: CellStyle): number {
        let mergeID = mergeStyle['MergeID'];
        if (typeof mergeID !== 'number') {
            mergeID = ++this._MaxMergeStyleNumber;
            mergeStyle['MergeID'] = mergeID;
        }
        if (typeof actStyleID !== 'number') {
            actStyleID = 0;
        }
        const resultDict = this._ResultingStyles.get(actStyleID);
        let resultStyle = resultDict.get(mergeID);
        if (!resultStyle) {
            const actStyle = this._Styles.get(actStyleID);
            const merge = StyleMerger.Merge(actStyle, mergeStyle);

            this._Styles.forEach(v => {
                if (!resultStyle && StyleMerger.Equals(v, merge)) {
                    resultStyle = v;
                }
            });

            if (!resultStyle) {
                resultStyle = merge;
                merge.ID = ++this._MaxStyleNumber;
                this._Styles.set(merge.ID, merge);
                this._ResultingStyles.set(merge.ID, new Map());
            }
            resultDict.set(mergeID, resultStyle);
        }
        return resultStyle.ID;
    }

    GetStyleObject() {
        const retVal = {};
        this._Styles.forEach(style => {
            retVal['' + style.ID] = StyleMerger.GetStyleObject(style);
        });
        return retVal;
    }

    ApplySpecialStyle(cell: MultiNode): any {
        if (typeof cell.StyleID === 'number') {
            const style = this._Styles.get(cell.StyleID);
            if (style) {
                if (style.Gadget) {
                    return {
                        CellContentType: 1,
                        Value: style.Gadget
                    };
                }
                return TaskHelper.GetFormatedCellValue(cell, style.NumberFormat);
            }
        }
        return null;
    }

    GetStyle(styleID: number) {
        return this._Styles.get(styleID);
    }
}
