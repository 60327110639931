import { Type } from 'class-transformer';
import { Point } from '../basic/point.model';
import { GradientType } from '../enums/gradienttype.enum';
import { Color } from './color.model';
import { GradientStopColor } from './gradientstopcolor.model';

// @dynamic
export class Gradient {
    StartPoint: Point = new Point();
    EndPoint: Point = new Point();
    @Type(() => GradientStopColor)
    Colors: GradientStopColor[] = [];
    Type: GradientType;

    // TODO: Image, Hatch???

    public static IsSameGradient(gradient1: Gradient, gradient2: Gradient): boolean {
        if (gradient1) {
            if (gradient2) {
                if (gradient1.Type == gradient2.Type) {
                    if (gradient1.StartPoint.X == gradient2.StartPoint.X && gradient1.StartPoint.Y == gradient2.StartPoint.Y) {
                        if (gradient1.EndPoint.X == gradient2.EndPoint.X && gradient1.EndPoint.Y == gradient2.EndPoint.Y) {
                            if (gradient1.Colors.length == gradient2.Colors.length) {
                                var colorSort1 = gradient1.Colors.concat().sort(function (a, b) {
                                    return a.Offset - b.Offset;
                                });
                                var colorSort2 = gradient2.Colors.concat().sort(function (a, b) {
                                    return a.Offset - b.Offset;
                                });
                                var retVal = true;
                                for (var i = 0; i < colorSort1.length; i++) {
                                    if (!GradientStopColor.IsSameGradientStop(colorSort1[i], colorSort2[i])) {
                                        retVal = false;
                                        break;
                                    }
                                }
                                return retVal;
                            }
                        }
                    }
                }
            }
            return false;
        } else {
            if (gradient2) {
                return false;
            }
            return true;
        }
    }

    static getStyleObject(gradient: Gradient) {
        if (gradient && gradient.Colors && gradient.Colors.length > 0) {
            if (gradient.Type === GradientType.Solid) {
                const color = gradient.Colors[0].Color;
                if (color) {
                    return { 'background-color': Color.GetStyleText(color) };
                }
            } else if (gradient.Type === GradientType.Gradient) {
                if (gradient.Colors.length > 1) {
                    let direction = '';
                    let directionLG = 'to ';
                    if (gradient.StartPoint.X < gradient.EndPoint.X) {
                        direction += 'left';
                        directionLG += 'right';
                    } else if (gradient.StartPoint.X > gradient.EndPoint.X) {
                        direction += 'right';
                        directionLG += 'left';
                    }
                    if (gradient.StartPoint.Y < gradient.EndPoint.Y) {
                        if (direction != '') {
                            direction += ' ';
                            directionLG += ' ';
                        }
                        direction += 'top';
                        directionLG += 'bottom';
                    } else if (gradient.StartPoint.Y > gradient.EndPoint.Y) {
                        if (direction != '') {
                            direction += ' ';
                            directionLG += ' ';
                        }
                        direction += 'bottom';
                        directionLG += 'top';
                    }
                    const sorted = gradient.Colors.concat().sort((a, b) => {
                        return a.Offset - b.Offset;
                    });
                    let colors = '';
                    sorted.forEach((color) => {
                        colors += ', ' + Color.GetStyleText(color.Color) + ' ' + Math.round(color.Offset * 100) + '%';
                    });
                    const bg = '-webkit-linear-gradient(' + direction + colors + '),' +
                        'linear-gradient(' + directionLG + colors + '),' +
                        Color.GetStyleText(sorted[0].Color);
                    return {
                        'background': bg,
                        'filter': 'progid:DXImageTransform.Microsoft.gradient(startColorstr="' + Color.GetStyleText(sorted[0].Color) +
                            '",endColorstr="' + Color.GetStyleText(sorted[sorted.length - 1].Color) + '",GradientType=1)'
                    };
                } else {
                    const color = gradient.Colors[0].Color;
                    if (color) {
                        return { 'background-color': Color.GetStyleText(color) };
                    }
                }
            }
        }
        return {};
    }

    static Solid(color: Color): Gradient {
        if (color) {
            const retVal = new Gradient();
            retVal.Type = GradientType.Solid;
            const cs = new GradientStopColor();
            cs.Color = color;
            retVal.Colors.push(cs);
            return retVal;
        }
        return null;
    }
}
