import {
	AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, Directive,
	EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import { ArrayHelpers, TranslateFormatText } from '../helpers/array.helpers';
import { ClipboardHelper } from '../helpers/clipboard.helper';
import { NotificationHelper } from '../helpers/notification.helper';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../models/enums/messageboxresult.enum';
import { LayoutService } from '../services/layout.service';
import { MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { DataCheck } from '../workflow/workflow.dialog';

// @dynamic
@Directive()
export abstract class BaseListSettings
	implements OnInit, AfterViewInit, OnDestroy {
	ListItems = [];
	FilteredItems = [];
	selectedNode;
	HasChanges = false;
	Component;
	Subscription;
	InitArgs;
	ShowSave = true;
	ShowAdd = true;
	ShowCopy = false;
	ShowRefresh = true;
	ShowDelete = true;
	ShowFilter = true;
	SearchValue: string;
	isFreeUser: boolean = false;
	ShowCopyButton = false;
	ShowDuplicateButton = true;
	ShowPasteButton = false;

	toggleWindow = true;

	CopyTypeValue = null;
	get CopyType() {
		return this.CopyTypeValue;
	}
	set CopyType(value) {
		this.CopyTypeValue = value;
		this.InitClipboard();
	}

	DisableSaveWithoutChange = true;

	@ViewChild('dynamic', { read: ViewContainerRef })
	viewContainerRef: ViewContainerRef;

	get SaveDisabled(): boolean {
		if (!this.Component || !this.Component.SelectedItem) {
			return true;
		}
		if (this.DisableSaveWithoutChange) {
			return !this.HasChanges;
		}
		return false;
	}

	constructor(
		protected factoryResolver: ComponentFactoryResolver,
		protected cdRef: ChangeDetectorRef
	) { }
	ClipboardChanged;
	InitClipboard() {
		if (ClipboardHelper.Inititalized) {
			this.ShowCopyButton = true;
			this.ShowDuplicateButton = false;
			if (
				ClipboardHelper.ClipboardContent &&
				ClipboardHelper.ClipboardContent.type &&
				ClipboardHelper.ClipboardContent.type == this.CopyType
			) {
				this.ShowPasteButton = true;
			} else {
				this.ShowPasteButton = false;
			}
			if (this.ClipboardChanged) {
				this.ClipboardChanged.unsubscribe();
			}
			this.ClipboardChanged = ClipboardHelper.ClipboardChanged.subscribe(
				(data: any) => {
					if (data && data.type && data.type == this.CopyType) {
						this.ShowPasteButton = true;
					} else {
						this.ShowPasteButton = false;
					}
					this.cdRef.detectChanges();
				}
			);
		} else {
			this.ShowCopyButton = true;
			this.ShowDuplicateButton = false;
		}
	}

	ngOnInit(): void {
		this.init();

		this.InitClipboard();
	}
	ngAfterViewInit(): void {
		const factory = this.factoryResolver.resolveComponentFactory(
			this.getContentType()
		);
		if (this.viewContainerRef) {
			const comp = this.viewContainerRef.createComponent(factory);
			this.Component = comp.instance;
			this.Subscription = this.Component.ItemChanged.subscribe(() => {
				this.setHasChanges(true);
			});
			this.ComponentSet();
			this.refresh();
		}
	}

	ngOnDestroy(): void {
		if (this.Subscription) {
			this.Subscription.unsubscribe();
		}
	}

	protected ComponentSet() {
		this.Component.cdRef = this.cdRef;
		this.Component.InitArgs = this.InitArgs;
	}

	protected init() { }

	async refresh() {
		const check = await this.checkHasChanges();
		if (check) {
			LayoutService.Loading.next(true);
			this.refreshInternal();
		}
	}
	async refreshData() {
		this.refreshInternal();
	}

	private refreshInternal() {
		this.SearchValue = null;
		this.ListItems = [];
		this.FilteredItems = [];
		this.selectedNode = null;
		this.setSelectedItem(null);
		this.HasChanges = false;
		this.loadList((result) => {
			if (result) {
				ArrayHelpers.sortAlphabetical(result, 'Caption');
				this.ListItems = result;
				this.UpdateFiltered();
				LayoutService.Loading.next(false);
			}
			this.cdRef.detectChanges();
		});
	}

	abstract loadList(handler);

	async onItemClick(selection) {
		if (this.selectedNode !== selection) {
			const check = await this.checkHasChanges();
			if (check) {
				this.selectedNode = selection;
				this.setSelectedItem(null);
				if (selection && selection.ID) {
					this.loadData(selection.ID);
				}
			}
		}
	}

	abstract loadData(data);

	async addItem() {
		const check = await this.checkHasChanges();
		if (check) {
			this.selectedNode = null;
			const item = this.getNewItem();
			this.setSelectedItem(item);
		}
	}

	abstract getNewItem(): any;

	async deleteItem(item?: any) {
		if (item) {
			await this.onItemClick(item);
		}
		const sel = this.selectedNode;
		if (sel) {
			const text = this.getDeleteText(sel);
			const retVal = await MessageBoxHelper.ShowDialog(
				text.Question,
				text.Title,
				MessageBoxButtons.YesNo,
				MessageBoxIcon.Question
			);
			if (retVal === MessageBoxResult.Yes) {
				this.selectedNode = null;
				this.setSelectedItem(null);
				LayoutService.Loading.next(true);
				this.delete(sel.ID, (result) => {
					this.setHasChanges(false);
					this.refreshInternal();
					NotificationHelper.Success(text.Success, text.Title);
				});
				this.ListItems.splice(this.ListItems.indexOf(sel), 1);
				this.UpdateFiltered();
			}
		}
	}

	async copyItem() {
		const check = await this.checkHasChanges();
		if (check) {
			const sel = this.Component.SelectedItem;
			this.getCopyItem(sel).then((x) => {
				if (ClipboardHelper.Inititalized) {
					let entry = {
						type: this.CopyType,
						content: x,
					};
					ClipboardHelper.CopyToClipboard(entry);
					NotificationHelper.Info(
						'@@CopyToClipboard',
						'@@ElementCopiedToClipboard'
					);
					this.selectedNode = null;
					this.setSelectedItem(null);
				} else {
					NotificationHelper.Info(
						'@@Clipboard Permissions not granted',
						'@@ClipboardPermission'
					);
				}
			});
		}
	}

	async duplicateItem() {
		const check = await this.checkHasChanges();
		if (check) {
			const sel = this.Component.SelectedItem;
			this.getDuplicateItem(sel).then((x) => {
				this.setSelectedItem(x);
				this.setHasChanges(true);
			});
		}
	}

	pasteItem() {
		this.selectedNode = null;
		this.setSelectedItem(null);
		this.getPasteItem().then((x) => {
			this.setSelectedItem(x);
			this.setHasChanges(true);
		});
	}
	async getPasteItem(): Promise<any> {
		return new Promise((resolve) => {
			resolve(null);
		});
	}

	getCopyItem(selected): Promise<any> {
		return new Promise((resolve) => {
			resolve(null);
		});
	}
	getDuplicateItem(selected): Promise<any> {
		return new Promise((resolve) => {
			resolve(null);
		});
	}

	abstract getDeleteText(sel): DeleteTexts;
	abstract delete(data, handler);

	async checkHasChanges() {
		if (this.HasChanges) {
			const retVal = await MessageBoxHelper.ShowDialog(
				new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
				new TranslateFormatText('@@Frage'),
				MessageBoxButtons.YesNoAbort,
				MessageBoxIcon.Question
			);
			if (retVal === MessageBoxResult.Yes) {
				return await this.save(false);
			} else if (retVal === MessageBoxResult.Abort) {
				return false;
			} else {
				// No
				this.setHasChanges(false);
			}
		}
		return true;
	}

	abstract getSaveSuccessText(sel): SaveTexts;

	async save(fromSaveButton: boolean, isPublishUsed: boolean = false): Promise<boolean> {
		LayoutService.Loading.next(true);
		const dc = await this.checkCanSave();
		if (dc.IsCorrect) {
			const sel = this.Component.SelectedItem;
			if (!this.checkValidation(sel)) {
				return;
			}
			if (sel) {
				this.saveInternal(sel, (result, id, label) => {
					this.setHasChanges(false);
					LayoutService.Loading.next(false);
					const text = this.getSaveSuccessText(sel);
					NotificationHelper.Success(text.Text, text.Title);
					let item = this.ListItems.find((x) => x.ID === id);
					if (item) {
						if (item.Caption != label) {
							item.Caption = label;
							ArrayHelpers.sortAlphabetical(this.ListItems, 'Caption');
							this.UpdateFiltered();
							this.refreshInternal();
						}
						this.updateListItem(item, result);
					} else {
						this.handleNew(sel, result);
						item = { Caption: label, ID: id };
						this.ListItems.push(item);
						ArrayHelpers.sortAlphabetical(this.ListItems, 'Caption');
						this.UpdateFiltered();
						this.refreshInternal();
					}
					if (fromSaveButton) {
						this.selectedNode = item;
					}
				});
			} else {
				LayoutService.Loading.next(false);
			}
			if (!isPublishUsed) {
				this.ToggleWindow();
			} else {
				this.refresh()
			}
		} else {
			if (dc.IsShowPopup) {
				const t =
					'@@Changes could not be saved. Please check the following settings:\n{0}';
				const text = new TranslateFormatText(t);
				text.FormatParams.push(dc.Error);
				MessageBoxHelper.ShowDialog(
					text,
					new TranslateFormatText('@@Warnung'),
					MessageBoxButtons.Ok,
					MessageBoxIcon.Warning
				);
				LayoutService.Loading.next(false);
			} else {
				// NotificationHelper.Error(dc.Error, new TranslateFormatText('@@Fehler'));
				LayoutService.Loading.next(false);
			}
		}
		return dc.IsCorrect;
	}

	checkValidation(sel) {
		if (!sel.hasOwnProperty('Name')) {
			return true;
		}
		if (sel?.Name == '' || sel?.Name == null) {
			NotificationHelper.Error('Name field is required', '@@Error');
			LayoutService.Loading.next(false);
			return false;
		}
		else {
			return true;
		}
	}

	protected async checkCanSave(): Promise<DataCheck> {
		return new DataCheck();
	}

	abstract saveInternal(item, handler);
	protected handleNew(item, result) { }
	protected updateListItem(item, result) { }

	async saveClick() {
		await this.save(true);
	}

	abstract getContentType();

	protected setSelectedItem(item) {
		this.Component.setSelectedItem(item);
		this.cdRef.detectChanges();
	}

	protected setHasChanges(hasChanges) {
		if (typeof hasChanges === 'boolean') {
			this.HasChanges = hasChanges;
			LayoutService.PreventBrowserClose.next(this.HasChanges);
			if (hasChanges) {
				this.OnChange();
			}
		}
	}

	protected OnChange() { }

	UpdateFiltered() {
		let list = [];
		if (this.ListItems) {
			if (this.ShowFilter && this.SearchValue) {
				const toLower = this.SearchValue.toLowerCase();
				list = this.ListItems.filter(
					(x) => x.Caption.toLowerCase().indexOf(toLower) > -1
				);
			} else {
				list.push(...this.ListItems);
			}
		}
		this.FilteredItems = list;
	}

	resetSearch() {
		this.SearchValue = null;
		this.UpdateFiltered();
	}

	ToggleWindow() {
		this.setHasChanges(false);
		this.toggleWindow = !this.toggleWindow;
	}
}

@Directive()
export abstract class BaseListDetail implements OnInit {
	SelectedItem;
	cdRef: ChangeDetectorRef;
	@Output() ItemChanged = new EventEmitter<any>();

	ngOnInit(): void {
	}

	OnItemChanged() {
		this.ItemChanged.emit();
		if (this.cdRef) {
			this.cdRef.detectChanges();
		}
	}

	setSelectedItem(item) {
		this.SelectedItem = item;
		if (this.cdRef) {
			this.cdRef.detectChanges();
		}
	}
	CopyToClipboard(value) {

	}
}

export class DeleteTexts {
	Question: TranslateFormatText;
	Success: TranslateFormatText;
	Title = new TranslateFormatText('@@Loeschen');
}

export class SaveTexts {
	Text: TranslateFormatText;
	Title = new TranslateFormatText('@@Speichern');
}
