import { Component } from '@angular/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'cosmosdb-panel',
    templateUrl: './cosmosdb.panel.html',
    styleUrls: ['./cosmosdb.panel.css']
})
export class CosmosDBPanel extends BaseDataSourcePanel {
    DBAPITypes = [];;

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: CosmosDBPanel,
            DBInfoType: CosmosDBInfo
        };
    }

    constructor() {
        super();
        this.DBInfoType = CosmosDBInfo;
        this.DBAPITypes = EnumHelper.GetDropdownValues(CosmosDBApi);
    }
}

export class CosmosDBInfo extends ADBInfo {
    Server = null;
    Database = null;
    Username = null;
    Port = null;
    Password = null;
    UseSsl = false;
    CosmosDBApi = CosmosDBApi.MongoAPI;

    getTypeName(): string {
        return 'evidanza.Database.CosmosDB.CosmosDBInfo';
    }
}

export enum CosmosDBApi {
    MongoAPI,
    SqlAPI
}