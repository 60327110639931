import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { FormulaEditorDialog } from '../../../../components/common/formulaEditor/formulaEditor.control';
import { DataTypeHelper } from '../../../../helpers/datatype.helper';
import { VariablesNodeInformation } from '../../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../../models/enums/formulainputmode.enum';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-editcolumns-settings',
    templateUrl: './editcolumns.settings.html',
    styleUrls: ['./editcolumns.settings.css']
})
export class EditColumnsSettings extends FormulaWorkflowDialogContent {
    EditedColumns = [];
    DSColumns = [];
    DataTypes = DataTypeHelper.GetDataTypes();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'editcolumnsWFModule';
        reg.Caption = '@@Spalten bearbeiten';
        reg.GroupID = 'reldataoperations';
        reg.Index = 80;
        reg.SettingsControl = EditColumnsSettings;
        reg.SettingsTypeHelper = new EditColumnsSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource) {
            this.DSColumns = dataSource.Columns;
        }
        if (data) {
            const json = serialize(data);
            const obj = deserialize(EditColumnsSettingsData, json);
            this.EditedColumns = obj.EditedColumns;
        }
    }

    getResult(): any {
        const retVal = new EditColumnsSettingsData();
        retVal.EditedColumns = this.EditedColumns;
        return retVal;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.EditedColumns.some(col => !col.ColumnName || !col.Formula)) {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte vergeben Sie fuer jede bearbeitete Spalte eine Ziel-Spalte und eine Formel';
        }
        return retVal;
    }

    addColumn() {
        this.EditedColumns.push(new ColumnEditSetting());
    }

    removeColumn(i) {
        this.EditedColumns.splice(i, 1);
    }

    showFormulaDialog(col, index) {
        const variables = [...this.Variables];
        for (let i = 0; i < index; i++) {
            const editCol = this.EditedColumns[i];
            if (editCol.NewColumn === true && editCol.ColumnName) {
                const vni = new VariablesNodeInformation();
                vni.Name = editCol.ColumnName;
                variables.push(vni);
            }
        }
        this.DSColumns.forEach(dsCol => {
            const vni = new VariablesNodeInformation();
            vni.Name = dsCol.Name;
            variables.push(vni);
        });
        variables.sort((a, b) => {
            if (a.Name < b.Name) {
                return -1;
            }
            if (a.Name > b.Name) {
                return 1;
            }
            return 0;
        });
        const args = {
            Variables: variables,
            InputMode: FormulaInputMode.VariableName,
            Formula: col.Formula
        };
        FormulaEditorDialog.ShowDialog(args, result => {
            if (result && result.Formula) {
                col.Formula = result.Formula;
            }
            return true;
        });
    }

    onColumnChanged(col) {
        if (col) {
            col.NewType = null;
        }
    }
}
// @dynamic
export class EditColumnsSettingsData extends AServiceWorkflowData {
    @Type(() => ColumnEditSetting)
    EditedColumns: ColumnEditSetting[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.EditColumns.EditColumnsSettingsData';
    }
}

export class ColumnEditSetting {
    ColumnName: string;
    NewColumn = false;
    Formula: string;
    NewType: string;
}

export class EditColumnsSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new EditColumnsSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && ds.Columns && settings && settings.EditedColumns) {
                settings.EditedColumns.forEach(ec => {
                    let col;
                    if (ec.NewColumn) {
                        col = {
                            Name: ec.ColumnName
                        };
                        ds.Columns.push(col);
                    }
                    if (ec.NewType) {
                        if (!col) {
                            col = ds.Columns.find(x => x.Name === ec.ColumnName);
                        }
                        if (col) {
                            col.DataTyp = ec.NewType;
                        }
                    }                    
                });
            }
        }
    }
}
