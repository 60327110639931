import { Component } from '@angular/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { DBPanelInfo, MongoDBInfo } from '../../../../../models/datamodel/adbinfo.model';
import { GuidRepresentation } from '../../../../../models/enums/guidrepresentation';
import { MongoAuthMechanism } from '../../../../../models/enums/mongoauthmechanism.enum';
import { MongoDBConnectionType } from '../../../../../models/enums/mongodbconnectiontype.enum';
import { BaseDataSourcePanel } from '../basedatasource.panel';

@Component({
    selector: 'mongodb-panel',
    templateUrl: './mongodb.panel.html',
    styleUrls: ['./mongodb.panel.css']
})
export class MongoDBPanel extends BaseDataSourcePanel {
    ConnectionTypes = [];
    AuthMechanisms = [];
    GuidRepresentations = [];
    constructor() {
        super();
        this.DBInfoType = MongoDBInfo;
        this.ConnectionTypes = EnumHelper.GetDropdownValues(MongoDBConnectionType);
        this.AuthMechanisms = EnumHelper.GetDropdownValues(MongoAuthMechanism);
        this.GuidRepresentations = EnumHelper.GetDropdownValues(GuidRepresentation);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: MongoDBPanel,
            DBInfoType: MongoDBInfo
        };
    }
}
