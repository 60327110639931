import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { UserSettingsService } from '../../../../services/usersettings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';

@Component({
    selector: 'wf-deleteprofile-settings',
    templateUrl: './delete.profile.settings.html'
})
export class DeleteProfileSettings extends FormulaWorkflowDialogContent {
    Data = new DeleteProfileSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'deleteProfileModule';
        reg.Caption = '@@Delete profile';
        reg.GroupID = 'profileGroup';
        reg.Index = 40;
        reg.SettingsControl = DeleteProfileSettings;
        reg.SettingsTypeHelper = new DeleteProfileSettingsDataHelper();
        reg.Executer = DeleteProfileModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DeleteProfileSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data && !this.Data.ProfileName) {
            retVal.IsCorrect = false;
            retVal.Error = '@@SetIDError';
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }
}

export class DeleteProfileSettingsData {
    ProfileName: string;
}

export class DeleteProfileSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new DeleteProfileSettingsData();
    }
}

export class DeleteProfileModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const layout = status.WorkflowLayoutService.Layout;
        if (layout) {
        if (status.ActualSettings) {
            if (status.ActualSettings.ProfileName) {
                const calculator = new WorkflowFormulaCalculator(status);
                const value = calculator.CalcFormula(status.ActualSettings.ProfileName);
                const service = InjectorHelper.InjectorInstance.get<UserSettingsService>(UserSettingsService);
                await service.DeleteProfileAndValues(layout._Id, '' + value).toPromise();
                return 0;
            } else {
                status.Logger.logError('Delete profile modul: No id found.');
            }
        } else {
            status.Logger.logError('Delete profile modul: No settings found.');
            }
        } else {
            status.Logger.logError('Delete profile modul: No layout found.');
        }
        return super.execute(status);
    }
}
