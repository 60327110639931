import { Pipe, PipeTransform } from '@angular/core';
import { Comparer } from '../models/enums/comparer.enum';
import { RequestFilter } from '../models/rest/requestfilter';

@Pipe({
    name: 'datatablefilter',
    pure: false
})
export class DataTableFilterPipe implements PipeTransform {
  transform(items: any[], filter: RequestFilter[]): any {
    if (!items || !filter) {
        return items;
    }
    if (!filter || (filter && filter.length == 0)) {
      return items;
    } else {
      return items.filter((item) => {
        var result = true;
        filter.some((fil) => {
          switch (fil.Operator) {
            case Comparer.Between:

              break;
            case Comparer.Equal:
              if (!(item.data[fil.Name] == fil.Value)) {
                result = false;
              }
              break;
            case Comparer.Greater:
              if (!(item.data[fil.Name] > fil.Value)) {
                result = false;
              }
              break;
            case Comparer.GreaterEqual:
              if (!(item.data[fil.Name] >= fil.Value)) {
                result = false;
              }
              break;
            case Comparer.In:

              break;
            case Comparer.Like:
              if (!(item.data[fil.Name].indexOf(fil.Value)>-1)) {
                result = false;
              }
              break;
            case Comparer.NotBetween:

              break;
            case Comparer.NotEqual:
              if (!(item.data[fil.Name] != fil.Value)) {
                result = false;
              }
              break;
            case Comparer.NotIn:

              break;
            case Comparer.Smaller:
              if (!(item.data[fil.Name] < fil.Value)) {
                result = false;
              }
              break;
            case Comparer.SmallerEqual:
              if (!(item.data[fil.Name] <= fil.Value)) {
                result = false;
              }
              break;
          }
          if (!result) {
            return result;
          }
        })
        return result;
      });
    }
  }
}
