import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'inputswitch-theme-control',
    templateUrl: './inputswitch.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputswitchThemeControl extends BaseThemeControl {
    onChange(ev) {
        this.EmitAction(this.DataSourceInternal);
        this.ValueChanged.emit(this.DataSourceInternal);
    }
    @Output() ValueChanged = new EventEmitter<any>();
}
