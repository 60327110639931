import { ValueAndType } from '../basic/formulaEditor.model';
import { CompareType } from '../enums/comparetype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { ComparerFormula } from './iformula';

export class NotEqual extends ComparerFormula {
    static StaticID: string = '39a55ad6-ad95-451c-a74c-2dead533615e';

    constructor() {
        super();
        this._ID = NotEqual.StaticID;
        this._Name = 'Ungleich';
    }

    OrderID(): number {
        return 6;
    }
    CompareType(): CompareType {
        return CompareType.NotEqual;
    }
    OperationValue(): string {
        return '<>';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal: ValueAndType = {
            Type: ValueType.Bool,
            Value: false
        };
        if (args && args.length >= 2 && args[0] && args[1]) {
            if (args[0] && args[1]) {
                if (args[0].Type == ValueType.Null && args[1].Type == ValueType.Null) {
                    retVal.Value = true;
                } else if (args[0].Type == ValueType.String || args[1].Type == ValueType.String) {
                    retVal.Value = String(args[0].Value) === String(args[1].Value);
                } else if (args[0].Type == ValueType.Datetime || args[1].Type == ValueType.Datetime) {
                    const now = new Date();
                    let d1, d2;
                    if (args[0].Value instanceof Date && !isNaN(args[0].Value.getTime())) {
                        d1 = args[0].Value;
                    } else {
                        d1 = now;
                    }
                    if (args[1].Value instanceof Date && !isNaN(args[1].Value.getTime())) {
                        d2 = args[1].Value;
                    } else {
                        d2 = now;
                    }
                    retVal.Value = d1 === d2;
                } else if (args[0].Type == ValueType.Double || args[1].Type == ValueType.Double || args[0].Type == ValueType.Long || args[1].Type == ValueType.Long) {
                    retVal.Value = Number(args[0].Value) === Number(args[1].Value);
                } else if (args[0].Type == ValueType.Bool && args[1].Type == ValueType.Bool) {
                    retVal.Value = args[0].Value === args[1].Value;
                }
            } else {
                retVal.Value = true;
            }
        }
        retVal.Value = !retVal.Value;
        return retVal;
    }
}
