import { Component } from '@angular/core';
import { OfflineHelper } from '../../../helpers/offline.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'get-offline-value-settings',
    templateUrl: './get.offline.value.settings.html',
    styleUrls: ['./get.offline.value.settings.css']
})
export class GetOfflineValueSettings extends FormulaWorkflowDialogContent {
    ActualStatusKey = '';
    VariableNodes: ABaseTreeNode[] = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getOfflineValueWFModule';
        reg.Caption = '@@Offlinewert auslesen';
        reg.GroupID = 'wfOffline';
        reg.Index = 10;
        reg.SettingsControl = GetOfflineValueSettings;
        reg.SettingsTypeHelper = new GetOfflineValueDataHelper();
        reg.Executer = GetOfflineValueExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data && data.StatusKey) {
            this.ActualStatusKey = data.StatusKey;
        }
    }

    getResult(): any {
        const retVal = new GetOfflineValueData();
        retVal.StatusKey = this.ActualStatusKey;
        return retVal;
    }

    onVariableSelect(event) {
        if (event) {
            this.ActualStatusKey = event.Caption;
        }
    }
}

export class GetOfflineValueData extends AServiceWorkflowData {
    StatusKey = '';
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.WFOffline.GetOfflineValue';
    }
}

export class GetOfflineValueDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new GetOfflineValueData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.StatusKey) {
            return settings.StatusKey;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
}

export class GetOfflineValueExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.StatusKey) {
                status.ActualParameter = await OfflineHelper.GetWorkflowData(status.ActualSettings.StatusKey);
                return 0;
            } else {
                status.Logger.logError('GetOfflineValue module: No status key found.');
            }
        } else {
            status.Logger.logError('GetOfflineValue module: No settings found.');
        }
        return super.execute(status);
    }
}
