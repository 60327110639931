import { Component } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { Alignment } from '../../../models/enums/alignment.enum';
import { BorderStyle } from '../../../models/enums/borderstyle.enum';
import { ConditionDataType } from '../../../models/enums/conditiondatatype.enum';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { GradientType } from '../../../models/enums/gradienttype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { EnumSwitchAdvancedSettings } from './enum.switch.advanced.settings';

@Component({
    selector: 'enumSwitch-settings',
    templateUrl: './enumSwitch.settings.html',
    styleUrls: ['./enumSwitch.settings.css']
})
export class EnumSwitchSettings extends WorkflowDialogContent {
    ActualEnumType: string;
    EnumTypes;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'enumWFModule';
        reg.Caption = '@@Enum';
        reg.GroupID = 'datachecks';
        reg.Height = 80;
        reg.Width = 80;
        reg.Index = 30;
        reg.SettingsControl = EnumSwitchSettings;
        reg.SettingsTypeHelper = new EnumSwitchSettingsDataHelper();
        reg.Executer = EnumSwitchModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = EnumSwitchAdvancedSettings.ModuleID;
        return reg;
    }

    constructor() {
        super();
        const list = [];
        ENUM_MAP.forEach((v, k) => {
            list.push(k);
        });
        this.EnumTypes = list;
    }

    initialize(data: any) {
        if (data && data.EnumType) {
            this.ActualEnumType = data.EnumType;
        }
    }

    getResult(): any {
        const retVal = new EnumSwitchSettingsData();
        if (this.ActualEnumType) {
            retVal.EnumType = this.ActualEnumType;
        }
        return retVal;
    }
}

export const ENUM_MAP = new Map<string, any>([
    ['Alignment', Alignment],
    ['BorderStyle', BorderStyle],
    ['ContentType', ContentType],
    ['GradientType', GradientType],
    ['ConditionDataType', ConditionDataType],
]);

export class EnumSwitchSettingsData extends AServiceWorkflowData {
    EnumType = '';

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.EnumSwitch.EnumSwitchSettingsData';
    }
}

export class EnumSwitchSettingsDataHelper extends WorkflowModuleSettingsHelper {

    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getEmptySettingsInstance() {
        return new EnumSwitchSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.EnumType) {
            return settings.EnumType;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = [];
        if (settings && settings.EnumType) {
            const enumType = ENUM_MAP.get(settings.EnumType);
            if (enumType) {
                const values = EnumHelper.GetDropdownValues(enumType);
                values.forEach((val) => {
                    const info = new WorkflowExitInfo();
                    info.ID = val.type;
                    info.Label = val.value;
                    retVal.push(info);
                });
            }
        }
        return retVal;
    }
}

export class EnumSwitchModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings && status.ActualSettings.EnumType) {
            const enumType = ENUM_MAP.get(status.ActualSettings.EnumType);
            if (enumType) {
                const val = enumType[status.ActualParameter];
                if (val) {
                    return enumType[val];
                }
            } else {
                status.Logger.logError('Enum modul: Enum type not set.');
            }
        } else {
            status.Logger.logError('Enum modul: Enum type not set.');
        }
        return super.execute(status);
    }
}
