import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Row } from '../../models/basic/row.model';

@Component({
    selector: 'evi-datatable-row',
    templateUrl: './datatable.row.html',
    styleUrls: ['./datatable.row.css'],
    animations: [
        trigger('animateExpandable', [
            state('collapsed', style({ transform: 'rotate(0deg)', opacity: 1 })),
            state('collapsedRTL', style({ transform: 'rotate(180deg)', opacity: 1 })),
            state('expanded', style({ transform: 'rotate(90deg)', opacity: 1 })),
            transition('collapsed => expanded', [
                animate('200ms')
            ]),
            transition('expanded => collapsed', [
                animate('200ms')
            ]),
            transition('collapsedRTL => expanded', [
                animate('200ms')
            ]),
            transition('expanded => collapsedRTL', [
                animate('200ms')
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DataTableRow {

    //#region Row
    RowValue: Row;

    @Input()
    get Row(): Row {
        return this.RowValue;
    }
    set Row(val: Row) {
        this.RowValue = val;
    }
    //#endregion

    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
    }
    //#endregion

    //#region DataTable
    DataTableValue: any;

    @Input()
    get DataTable() {
        return this.DataTableValue;
    }
    set DataTable(val: any) {
        this.DataTableValue = val;
    }
    //#endregion

    RowEditInternal(row) {
        this.RowEdit.emit(row);
    }
    @Output() RowEdit = new EventEmitter<any>();

    RowEditAcceptInternal(row) {
        this.RowEditAccept.emit(row);
    }
    @Output() RowEditAccept = new EventEmitter<any>();

    RowEditCancelInternal(row) {
        this.RowEditCancel.emit(row);
    }
    @Output() RowEditCancel = new EventEmitter<any>();

    RowClickInternal(event) {
        this.RowClick.emit(event);
    }
    @Output() RowClick = new EventEmitter<any>();

    RowSelectedInternal(row) {
        this.RowSelected.emit(row);
    }
    @Output() RowSelected = new EventEmitter<any>();

    KeyPressInternal(event) {
        event['row'] = this.Row;
        this.KeyPress.emit(event);
    }

    @Output() KeyPress = new EventEmitter<any>();

    ExpandClick(event) {
        this.Row.expanded = !this.Row.expanded;
        this.Row.RowExpand.next(this.Row.expanded);
        event.stopPropagation();
    }

    //#region ToolTip
    ShowToolTip;
    enter(event: MouseEvent) {
        if (event.target['offsetWidth'] < event.target['scrollWidth']) {
            this.ShowToolTip = true;
        } else {
            this.ShowToolTip = false;
        }
    }
    //#endregion
    GetCustomStyle() {
        //if (this.DataTable && this.DataTable.TableStyle == TableStyle.DataTable && this.DataTable.CustomBaseStyle && this.DataTable.CustomBaseStyle.Line) {
        //    return {
        //        'border-bottom': '1px solid ' + this.DataTable.CustomBaseStyle.Line
        //    }
        //}
    }
}
