import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { Color } from '../../../models/style/color.model';
import { TranslatedString } from '../../../models/translatedstring.model';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import {NotificationHelper} from "../../../helpers/notification.helper";
import {LayoutService} from "../../../services/layout.service";

@Component({
    selector: 'chart-palette-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ChartPaletteSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@ChartPalette',
            ID: 'chartpalette',
            Icon: 'insert_chart_outlined',
            Index: 7,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 128
            },
            Content: ChartPaletteSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.service.ReadPaletteInfos().subscribe(result => {
            const list = [];
            result.forEach(function (entry) {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
    }
    loadData(data: any) {
        this.service.ReadPalette(data).subscribe(x => {
            this.setSelectedItem(x);
        });
    }
    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@New chart palette')
        };
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete the chart palette \'{0}\' ?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Chart palette \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Delete');
        return retVal;
    }
    delete(data: any, handler: any) {
        this.service.DeletePalette(data).subscribe(() => {
            handler();
        });
    }
    getSaveSuccessText(sel: any): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Chart palette \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Save');
        return retVal;
    }
    saveInternal(item: any, handler: any) {
        if (item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.service.SavePalette(item).subscribe(result => {
            handler(result, result.SID, result.Caption);
        });
    }
    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }
    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
    getContentType() {
        return ChartPaletteDetail;
    }
}

@Component({
    selector: 'chart-palette-detail',
    templateUrl: './chart.palette.settings.html'
})
export class ChartPaletteDetail extends BaseListDetail {
    addPaletteColor() {
        if (this.SelectedItem) {
            if (this.SelectedItem.Palette) {
                this.SelectedItem.Palette.push(Color.FromHex('#000000'));
            } else {
                this.SelectedItem.Palette = [Color.FromHex('#000000')];
            }
            this.OnItemChanged();
        }
    }
    clearPalette() {
        if (this.SelectedItem) {
            delete this.SelectedItem.Palette;
            this.OnItemChanged();
        }
    }
    DropPaletteEntry(event) {
        if (this.SelectedItem && this.SelectedItem.Palette && event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.SelectedItem.Palette, event.previousIndex, event.currentIndex);
            this.OnItemChanged();
        }
    }
    paletteColorChanged(event, i) {
        if (this.SelectedItem && this.SelectedItem.Palette) {
            if (event == null && i < this.SelectedItem.Palette.length) {
                this.SelectedItem.Palette.splice(i, 1);
            }
            this.OnItemChanged();
        }
    }
}
