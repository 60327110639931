import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Inject, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ViewType } from '../../models/enums/viewtype.enum';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { IBaseComponent } from '../controls/base.component';

@Component({
    selector: 'base-action',
    templateUrl: './base.action.html',
    styleUrls: ['./base.action.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseAction extends IBaseComponent {

    static Name = '';
    static Type: any = 'action';
    static Default = { Type: 'action' };

    @Input()
    public get MarginVisible(): any {
        return this.Visibility();
    }

    //#region SelectedItem
    SelectedItemValue: any;

    @Input()
    public get SelectedItem(): any {
        return this.SelectedItemValue;
    }
    public set SelectedItem(val: any) {
        this.SelectedItemValue = val;
        this.OnItemSet();
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }
    @Output()
    public SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Action
    ActionValue: any;

    @Input()
    public get Action(): any {
        return this.ActionValue;
    }
    public set Action(val: any) {
        this.ActionValue = val;
        if (this.ActionChange) {
            this.ActionChange.emit(this.ActionValue);
        }
    }
    @Output()
    public ActionChange = new EventEmitter<any>();
    //#endregion
    //#region ViewType
    ViewTypeValue;
    @Input()
    get ViewType() {
        if (this.Action) {
            return this.Action.ViewType;
        } else {
            return this.ViewTypeValue;
        }
    }
    set ViewType(val) {
        if (this.Action) {
            this.Action.ViewType = val;
            this.ViewTypeChange.emit(this.Action.ViewType);
        } else {
            this.ViewTypeValue = val;
            this.ViewTypeChange.emit(this.ViewTypeValue);
        }
    }

    @Output() ViewTypeChange = new EventEmitter<any>();
    //#endregion

    Component;
    @Output() EditAction = new EventEmitter<any>();

    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    constructor(protected factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ngOnInit(): void {
        this.Subscriptions['Selection'] = LayoutService.SelectedItem.subscribe((item) => {
            this.SelectedItem = item;
        });
        this.Subscriptions['SelectedPage'] = NavigationService.SelectedPage.subscribe((page) => {
            if (page === 'ribbon') {
                this.ViewType = ViewType.Edit;
            } else {
                this.ViewType = ViewType.View;
            }
        });
        if (this.Action && this.Action.ComponentChanged) {
            this.Action.ComponentChanged.subscribe(() => {
                this.DrawComponent();
            });
        }
        super.ngOnInit();
    }

    onLayoutElementSet() {
        this.Action = this.LayoutElementValue;
    }

    ControlInitialized() {
        this.DrawComponent();
    }

    DrawComponent() {
        if (this.viewContainerRef) {
            this.viewContainerRef.clear();
            if (this.Action && this.Action.Component) {
                const factory = this.factoryResolver.resolveComponentFactory(this.Action.Component);
                const comp = this.viewContainerRef.createComponent(factory);
                comp.location.nativeElement.classList.add('full');
                comp.location.nativeElement.classList.add('flex-center');
                this.Component = comp.instance;
                (<BaseAction>this.Component).Action = this.Action;
            }
        }
    }

    GetSize() {
        switch (this.Action.Size) {
            case 0: return 'small';
            case 1: return 'medium';
            case 2: return 'large';
        }
    }

    edit() {
        this.EditAction.emit(true);
    }

    abstract ExecuteAction();
    abstract Visibility();

    CheckClose() {
        if (!this.Action.KeepOpen) {
            LayoutService.CloseContextMenu.next(null);
        }
    }
    GetPadding() {
        const result = {};
        if (this.Action.IsContextMenu) {
            result['padding'] = '0.5em 2em 0.5em 0.75em';
        }
        return result;
    }
    OnItemSet() {
    }
}
