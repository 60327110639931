import { UUID } from "angular2-uuid";
import { Subject } from "rxjs";
import { UserSettingsService } from "../services/usersettings.service";

// @dynamic
export class VariableHelper {
    private static usersettingsService: UserSettingsService = null;
    public static Initialize(service) {
        this.usersettingsService = service;
    }
    static DataTypes = [
        { Caption: '@@Text', Value: 'System.String' },
        { Caption: '@@Bool', Value: 'System.Boolean' },
        { Caption: '@@Ganzzahl', Value: 'System.Int32' },
        { Caption: '@@Gleitzahl', Value: 'System.Double' },
        { Caption: '@@Datum', Value: 'System.DateTime' },
        { Caption: '@@Objekt', Value: 'System.Object' },
        { Caption: '@@Liste', Value: 'List' }
    ];

    public static GetTypeCaption(dataType) {
        const dt = VariableHelper.DataTypes.find(x => x.Value === dataType);
        if (dt) {
            return dt.Caption;
        }
        return '';
    }

    public static GetTypeListTypeFromJSType(jsType) {
        if (typeof jsType === 'string') {
            switch (jsType) {
                case 'string':
                    return 'System.String';
                case 'boolean':
                    return 'System.Boolean';
                case 'number':
                    return 'System.Double';
                case 'date':
                    return 'System.DateTime';
                case 'object':
                    return 'System.Object';
            }
        }
        return null;
    }

    public static CheckValueForType(type: string, val: any): boolean {
        const valType = typeof val;
        if (valType !== 'undefined' && val !== null) {
            switch (type) {
                case 'System.String':
                    return valType === 'string';
                case 'System.Boolean':
                    return valType === 'boolean';
                case 'System.Int32':
                case 'System.Double':
                    return valType === 'number';
                case 'System.DateTime':
                    return val instanceof Date;
                case 'System.Object':
                    return valType === 'object';
                case 'List':
                    return Array.isArray(val);
            }
        }
        return true;
    }

    public static ValueChanged(Layout, variable) {
        if (Layout && Layout.AutoSaveVariables && Layout.AutoSaveVariables.length > 0) {
            var item = Layout.AutoSaveVariables.find((value) => value.ID == variable.ID);
            if (item) {
                if (this.VariableSubscriptions[Layout._Id + '||' + variable.ID]) {
                    this.VariableSubscriptions[Layout._Id + '||' + variable.ID].next(variable.Value);
                }
                this.usersettingsService.AddOrUpdate({
                    LayoutID: Layout._Id,
                    Value: JSON.stringify(variable.Value),
                    ItemID: variable.ID
                }).subscribe();
            }
        }
    }
    public static FindLayout(Layout, ItemID) {
        if (Layout.Variables && Layout.Variables.length > 0) {
            let variable = Layout.Variables.find((value) => value.ID == ItemID);
            if (variable) {
                return Layout;
            }
        } 
        if (Layout.Elements && Layout.Elements.length > 0) {
            for (let i = 0; i < Layout.Elements.length; i++) {
                let element = Layout.Elements[i];
                let variable = this.FindLayout(element, ItemID);
                if (variable) {
                    return variable;
                }
            }
        }
    }

    public static ControlChanged(Layout, Element) {

    }

    public static LoadVariables(Layout) {
        return new Promise((resolve, reject) => {
            if (!this.ActiveVariables) {
                this.ActiveVariables = {};
            }
            if (!this.ActiveVariablesMap) {
                this.ActiveVariablesMap = {};
            }
            if (!this.AvailableVariables) {
                this.AvailableVariables = {};
            }
            if (!this.AvailableControls) {
                this.AvailableControls = {};
            }

            VariableHelper.AvailableVariables[Layout._Id] = [];
            VariableHelper.AvailableControls[Layout._Id] = [];

            this.FetchVariablesAndControls(Layout, Layout);
            if (Layout.AutoSaveVariables || Layout.ProfileVariables) {
                this.usersettingsService.ReadByLayout(Layout._Id).subscribe((variables) => {
                    if (variables) {
                        VariableHelper.ActiveVariables[Layout._Id] = variables;
                        if (variables.length > 0) {
                            variables.forEach((item) => {
                                if (item.Value) {
                                    item.Value = JSON.parse(item.Value);
                                }
                                const toSet = VariableHelper.AvailableVariables[Layout._Id].find((value) => value.ID == item.ItemID);
                                if (toSet) {
                                    toSet.Value = item.Value;
                                }
                                VariableHelper.ActiveVariablesMap[item.LayoutID + '||' + item.ItemID] = item;
                            });
                        }
                    }
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }
    private static FetchVariablesAndControls(Layout, LayoutItem) {
        if (LayoutItem) {
            if (LayoutItem.Elements && LayoutItem.Elements.length > 0) {
                LayoutItem.Elements.forEach((item) => this.FetchVariablesAndControls(Layout, item));
            }
            if (LayoutItem.Variables && LayoutItem.Variables.length > 0) {
                LayoutItem.Variables.forEach((item) => {
                    if (!item.ID) {
                        item.ID = UUID.UUID();
                    }
                })
                if (!VariableHelper.AvailableVariables[Layout._Id]) {
                    VariableHelper.AvailableVariables[Layout._Id] = [];
                }
                VariableHelper.AvailableVariables[Layout._Id].push(...LayoutItem.Variables);
            }
            if (LayoutItem.ElementType == 'datatable' || LayoutItem.ElementType == 'tablecolumnvisibility') {
                if (!VariableHelper.AvailableControls[Layout._Id]) {
                    VariableHelper.AvailableControls[Layout._Id] = [];
                }
                VariableHelper.AvailableControls[Layout._Id].push(LayoutItem);
            }
        }
    }
    //public static ValueChanged: Subject<any> = new Subject();
    //public static ControlChanged: Subject<ControlChanges> = new Subject();

    public static VariableSubscriptions = {};
    public static ActiveVariables = null;
    public static ActiveVariablesMap = null;
    public static AvailableVariables = null;
    public static AvailableControls = null;
}
export class ControlChanges {
    ID: string;
    Value: any;
}