import { subtract } from 'mathjs';
import { FormulaStatics, ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { FormulaGroups, PrimitiveFormula } from './iformula';

export class Subtraction extends PrimitiveFormula {
    static StaticID = 'f0bc0a39-2a60-4432-bf62-f537deb8b72b';

    constructor() {
        super();
        this._ID = Subtraction.StaticID;
        this._Name = 'Subtraktion';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
        this._MinVariableCount = 1;
    }

    CanCalcNulls(): boolean {
        return true;
    }
    Group(): string {
        return FormulaGroups.BasicOperations;
    }
    OrderID(): number {
        return 2;
    }
    OperationType(): OperationType {
        return OperationType.Subtraktion;
    }
    OperationValue(): string {
        return '-';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && (maxType === ValueType.Long || maxType === ValueType.Double || maxType === ValueType.String)) {
            let value = null;
            args.forEach((item) => {
                if (typeof item.Value !== 'undefined' && item.Value !== null) {
                    if (value === null) {
                        value = item.Value;
                    } else {
                        value = subtract(value, item.Value);
                    }
                }
            });
            if (value !== null) {
                retVal.Value = value;
                retVal.Type = maxType;
            }
        }
        return retVal;
    }
}
