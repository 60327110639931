import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';

export class UnionDatasourceSettingsData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.UnionDatasource.UnionDatasourceSettingsData';
    }
}

export class UnionDatasourceSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        let retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new UnionDatasourceSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        var def = new WorkflowExitInfo();
        def.Type = 'relData';
        var secondSource = new WorkflowExitInfo();
        secondSource.ID = 1;
        secondSource.Label = "@@Weitere Quelle";
        secondSource.Type = 'relData';
        return [def, secondSource];
    }
    public static GetRegistry(): WorkflowRegistry {
        let reg = new WorkflowRegistry();
        reg.ID = 'uniondatasourceWFModule';
        reg.Caption = "@@Datenquellen vereinen";
        reg.GroupID = 'reldataoperations';
        reg.Index = 40;
        reg.SettingsTypeHelper = new UnionDatasourceSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }
}