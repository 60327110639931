import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { deserialize, plainToClass } from "class-transformer"
import { MessageBoxHelper } from "../../components/dialogs/messagebox/messagebox.dialog"
import { TranslateFormatText } from "../../helpers/array.helpers"
import { CapsuleHelper } from "../../helpers/capsule.helper"
import { ClipboardHelper } from "../../helpers/clipboard.helper"
import { NotificationHelper } from "../../helpers/notification.helper"
import { TemplateEditClient } from "../../helpers/template.edit.client"
import { ContentType } from "../../models/enums/contenttype.enum"
import { MessageBoxButtons } from "../../models/enums/messageboxbuttons.enum"
import { MessageBoxIcon } from "../../models/enums/messageboxicon.enum"
import { MessageBoxResult } from "../../models/enums/messageboxresult.enum"
import { Layout } from "../../models/layout.model"
import { LayoutEditService } from "../../models/layout/layout.edit.client"
import { LayoutElement } from "../../models/layoutelement.model"
import { LayoutService } from "../../services/layout.service"
import { MetaService } from "../../services/meta.service"
import { NavigationService } from "../../services/navigation.service"
import { SideNavService } from "../../services/sidenav.service"
import { UsersService } from "../../services/users.service"
import { VersioningOverview } from "../versioningoverview/versioning.overview"
import { TemplateInfo } from "./templates.settings"

@Component({
  selector: "templates-version-settings",
  templateUrl: "./templates.version.settings.html",
  styleUrls: ["../pages/pages.version.settings.css"],
})
export class TemplatesVersionSettings implements OnInit, OnDestroy {
  ShowCopyButton = true
  ShowDuplicateButton = false
  ShowPasteButton = false
  ShowCapsules = false
  CopyType = "TemplateCopy"
  ClipboardChanged
  Templates = []
  ActiveUser
  UserSub
  captionFilter

  static GetSettingsEntry() {
    return {
      Caption: "@@Templates",
      ID: "templates",
      Icon: "assignment",
      Index: 60,
      Parent: "layout",
      Security: {
        Name: "evidanza.App.Shared.Security.LayoutRight",
        Value: 32,
      },
      Content: TemplatesVersionSettings,
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private service: MetaService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    NavigationService.SelectedPage.next("template")
    this.UserSub = UsersService.ActiveUser.subscribe((x) => {
      this.ActiveUser = x
    })
    if (ClipboardHelper.Inititalized) {
      this.ShowCopyButton = true
      this.ShowDuplicateButton = false
      if (
        ClipboardHelper.ClipboardContent &&
        ClipboardHelper.ClipboardContent.type &&
        ClipboardHelper.ClipboardContent.type == this.CopyType
      ) {
        this.ShowPasteButton = true
      } else {
        this.ShowPasteButton = false
      }
      this.ClipboardChanged = ClipboardHelper.ClipboardChanged.subscribe(
        (data: any) => {
          if (data && data.type && data.type == this.CopyType) {
            this.ShowPasteButton = true
          } else {
            this.ShowPasteButton = false
          }
        }
      )
    } else {
      this.ShowCopyButton = true
      this.ShowDuplicateButton = false
    }
    CapsuleHelper.GetCapsuleTags().then((r) => {
      if (r) {
        this.ShowCapsules = true
      }
    })
    this.refresh()
  }

  ngOnDestroy(): void {
    NavigationService.SelectedPage.next("settings")
    if (this.UserSub) {
      this.UserSub.unsubscribe()
      this.UserSub = null
    }
    if (this.ClipboardChanged) {
      this.ClipboardChanged.unsubscribe()
      this.ClipboardChanged = null
    }
  }

  checkItemStyle(data) {
    if (data) {
      const style = {}
      if (data.IsCapsule) {
        style["font-style"] = "italic"
      }
      if (data.IsOverridden) {
        style["font-weight"] = "bold"
      }
      if (data.CanEditInfo) {
        if (data.CanEditInfo.CanEdit) {
          if (
            this.ActiveUser &&
            data.CanEditInfo.EditUserID == this.ActiveUser.SID
          ) {
            style["text-decoration"] = "underline"
          }
        } else {
          style["text-decoration"] = "line-through"
        }
      }
      data.Style = style
    }
  }

  addItem() {
    const template = new Layout()
    template.Name = this.translate.instant("@@Neues Template")
    template["ElementType"] = "template"
    template["Type"] = "template"
    template.ContentType = ContentType.Grid
    const le = new LayoutElement()
    le.ElementType = "grid"
    le.Name = "Base"
    template.Elements = [le]
    this.setEditClient(template)
  }

  setEditClient(template) {
    let sid = null
    if (this.ActiveUser) {
      sid = this.ActiveUser.SID
    }
    const editClient = new TemplateEditClient(
      template,
      {
        IsNew: true,
        CanEditInfo: {
          CanEdit: true,
          EditUserID: sid,
        },
      },
      this.service
    )
    editClient.TemplateInfoChanged.subscribe((ti) => {
      this.Templates.push(ti)
      this.checkItemStyle(ti)
    })
    LayoutEditService.SetNext(editClient, true)
  }

  refresh() {
    LayoutService.Loading.next(true)
    this.Templates = []
    this.service.LoadAllTemplates().subscribe((result) => {
      if (result) {
        result.forEach((x) => this.checkItemStyle(x))
        this.Templates = result
      }
      LayoutService.Loading.next(false)
      this.cdRef.detectChanges()
    })
  }

  editLayout(page) {
    this.service.LoadTemplate(page.SID).subscribe((l) => {
      if (l) {
        const layout = plainToClass(Layout, l)
        const editClient = new TemplateEditClient(layout, page, this.service)
        editClient.TemplateInfoChanged.subscribe((pi) => {
          let index = -1
          if (
            this.Templates.some((p, i) => {
              if (p.SID == pi.SID) {
                index = i
                return true
              }
            })
          ) {
            this.Templates.splice(index, 1, pi)
            this.checkItemStyle(pi)
          }
        })
        LayoutEditService.SetNext(editClient, true)
      }
    })
  }

  copyItem(page) {
    if (page) {
      this.service.LoadTemplate(page.SID).subscribe((l) => {
        if (l && ClipboardHelper.Inititalized) {
          const layout = plainToClass(Layout, l)
          const entry = {
            type: this.CopyType,
            content: JSON.stringify(layout),
          }
          ClipboardHelper.CopyToClipboard(entry)
          NotificationHelper.Info(
            "@@CopyToClipboard",
            "@@ElementCopiedToClipboard"
          )
        } else {
          NotificationHelper.Info(
            "@@Clipboard Permissions not granted",
            "@@ClipboardPermission"
          )
        }
      })
    }
  }

  duplicateItem(page) {
    if (page) {
      this.service.LoadTemplate(page.SID).subscribe((l) => {
        if (l) {
          const layout = plainToClass(Layout, l)
          this.setDuplicateItem(JSON.stringify(layout))
        }
      })
    }
  }

  private setDuplicateItem(json) {
    const copy = deserialize(Layout, json)
    delete copy["_Id"]
    delete copy["_version"]
    copy.Name += " (copy)"
    this.setEditClient(copy)
  }

  pasteItem() {
    this.setDuplicateItem(ClipboardHelper.ClipboardContent.content)
  }

  deleteItem(template) {
    if (template && template.CanEditInfo) {
      if (template.CanEditInfo.CanEdit) {
        if (
          this.ActiveUser &&
          template.CanEditInfo.EditUserID == this.ActiveUser.SID
        ) {
          return
        }
      } else {
        return
      }
      const tft = new TranslateFormatText(
        "@@Sind Sie sicher, dass Sie das Template '{0}' loeschen moechten?"
      )
      tft.FormatParams.push(template.Caption)
      MessageBoxHelper.ShowDialog(
        tft,
        new TranslateFormatText("@@Template loeschen"),
        MessageBoxButtons.YesNo,
        MessageBoxIcon.Question
      ).then((x) => {
        if (x == MessageBoxResult.Yes) {
          this.deleteItemInternal(template, false)
        }
      })
    }
  }

  private deleteItemInternal(template, ignoreVersions: boolean) {
    this.service
      .TryDeleteTemplate(template.SID, ignoreVersions)
      .subscribe((result) => {
        if (result) {
          if (result.Deleted) {
            this.refresh()
            const success = new TranslateFormatText(
              "@@Template '{0}' erfolgreich geloescht."
            )
            success.FormatParams.push(template.Caption)
            NotificationHelper.Success(success, "@@Template loeschen")
          } else if (result.IsLocked) {
            const text = new TranslateFormatText("@@LockedBy{0}")
            text.FormatParams.push(result.LockedBy)
            MessageBoxHelper.ShowDialog(
              text,
              new TranslateFormatText("@@LockedObject"),
              MessageBoxButtons.Ok,
              MessageBoxIcon.Information
            )
          } else if (result.MultipleVersions) {
            const text = new TranslateFormatText("@@MultipleVersionsInfoText")
            text.FormatParams.push(result.LockedBy)
            MessageBoxHelper.ShowDialog(
              text,
              new TranslateFormatText("@@MultipleVersionsInfoHeader"),
              MessageBoxButtons.None,
              MessageBoxIcon.Information,
              500,
              null,
              [
                { Caption: "@@DeleteAllVersions", ID: 0 },
                { Caption: "@@GoToVersionsOverview", ID: 1 },
                { Caption: "@@Cancel", ID: 2 },
              ]
            ).then((result) => {
              if (result == 0) {
                this.deleteItemInternal(template, true)
              } else if (result == 1) {
                const navStruct =
                  SideNavService.SelectedNavigationStructure.getValue()
                if (navStruct) {
                  this.router.navigateByUrl(navStruct + VersioningOverview.Path)
                }
              }
            })
          }
        }
      })
  }

  showInfo(template) {
    if (template) {
      TemplateInfo.showDialog(template.SID)
    }
  }
}
