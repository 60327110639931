import { TranslateHelper } from '../../helpers/injector.helper';
import { ValueAndType } from '../basic/formulaEditor.model';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { AFormula, FormulaGroups } from './iformula';

export class ToJSON extends AFormula {
    static StaticID = '94169bb8-53b6-4a02-aae4-303dc3adccd4';

    ID(): string {
        return ToJSON.StaticID;
    }

    Name(): string {
        return 'ToJSON';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Zu JSON-String konvertieren');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1005;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Wert') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Type = ValueType.String;
        retVal.Value = '';
        if (args && args.length > 0) {
            const val = args[0].Value;
            if (typeof val !== 'undefined' && val !== null) {
                retVal.Value = JSON.stringify(val);
            }
        }
        return retVal;
    }
}
