import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TextboxThemeControl } from '../../../appbuilder/controls/textbox/textbox.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ElementProperty } from '../../../models/layoutbase.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../base.component';
import { ButtonControl } from '../button/button.control';

@Component({
    selector: 'evi-download',
    templateUrl: './download.control.html',
    styleUrls: ['./download.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadControl extends IBaseComponent {
    static Type: any = 'download';
    static Default = { Placeholder: 'Please type ...', Type: 'download',Layout: {
        Caption: 'Download',
        ButtonType: 'mat-button',
        _Editable: true,
        Width: { Type: 0, Value:150},
        Height: {Type:0,Value:40}
    } };
    //#region Filename
    FilenameValue;
    @Input()
    get Filename() {
        if (this.FilenameValue != null) {
            return this.FilenameValue;
        }
        return this.LayoutElement.Filename;
    }
    set Filename(val) {
        this.FilenameValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    
    ButtonLayout;
    @ViewChild('button') button: ButtonControl;

    constructor(private mediaService: MediaService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.PropertyList.push(new ElementProperty('Filename', 'string', '@@Filename'));
    }

    ControlInitialized() {
        this.SetButtonLayout();
    }

    private SetButtonLayout() {
        if (this.LayoutElementValue) {
            this.ButtonLayout = plainToClass(LayoutElement, JSON.parse(JSON.stringify(this.LayoutElementValue)));
        }
    }

    onLayoutElementSet() {
        this.SetButtonLayout();
    }
    onLayoutElementChanged() {
        this.SetButtonLayout();
    }

    download() {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.DataSource;
        downloadLink.download = this.Filename;
        downloadLink.click();
    }
}
export class DownloadPanel extends BasePanel {
    static override SIDS = ['c6bc24d8-1b5d-42f8-98b3-88c28ea65a1d']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'c6bc24d8-1b5d-42f8-98b3-88c28ea65a1d',
            ID: 'download',
            Caption: '@@Download',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'download';
            }
        });

    
        PROPERTIES.push({
            ID: "Filename",
            Parent: "download",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Filename",
            CheckVisibility: (item) => {
                return !item.UseForBlob;
            }
        });
    }
}