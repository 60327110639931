import {ChangeDetectorRef, Component, Inject, OnInit, Renderer2} from '@angular/core';
import {TemplateModel} from "../../models/theme-template/template.model";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MetaService} from "../../services/meta.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageBoxHelper} from "../dialogs/messagebox/messagebox.dialog";
import {TranslateFormatText} from "../../helpers/array.helpers";
import {MessageBoxButtons} from "../../models/enums/messageboxbuttons.enum";
import {MessageBoxIcon} from "../../models/enums/messageboxicon.enum";
import {MessageBoxResult} from "../../models/enums/messageboxresult.enum";
import {NotificationHelper} from "../../helpers/notification.helper";
import {tap} from "rxjs/operators";
import {forkJoin, Observable} from "rxjs";
import {LayoutService} from "../../services/layout.service";
import {MobileConfigService} from "../../services/mobile-config.service";
import {DOCUMENT, PlatformLocation} from "@angular/common";
import { re } from 'mathjs';

@Component({
    selector: 'lib-generate-mobile-app',
    templateUrl: './generate-mobile-app.component.html',
    styleUrls: ['./generate-mobile-app.component.css']
})
export class GenerateMobileAppComponent implements OnInit {

    static GetSettingsEntry() {
        return {
            Caption: '@@Generate Mobile App',
            ID: 'generate-mobile-app',
            Icon: 'phonelink_setup',
            Index: 7,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 2
            },
            Content: GenerateMobileAppComponent
        };
    }

    public files: any[] = [];
    public filesUrl: any[] = [];
    public iconUrl = '';
    public combinedUrl: any[] = [];
    toggleWindow = true;
    mandatoryFlag = false;
    showImageDialog = false;
    uploadedFiles: any[] = [];
    imageSrc: any;
    pagesCount: any;
    selectedThumbnail: any;
    selectedImages = [];
    themeTemplate: TemplateModel[] = [];
    mobileAppList = [];
    themeTemplateList: TemplateModel[] = [];
    Pages = [];
    appConfigurationForm;
    ImageURLs = [];
    config;
    SearchValue: string;
    thumbnailMaxSize = 150 * 1024; // max size of thubmnail in bytes
    primaryColor = "#4b7bd6ff";
    baseURL = '';
    domain = '';
    showAppLinks: boolean = false;
    appsVersion : string;
    generateLabel: string = "Start Building";


    constructor(private fb: FormBuilder, private service: MobileConfigService, private metaService: MetaService, private cdRef: ChangeDetectorRef, private _snackBar: MatSnackBar, private cdr: ChangeDetectorRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
        this.baseURL = document.location.protocol + '//' + document.location.hostname;
        const domain = this.getMainDomain(this.baseURL).replace(/[^a-zA-Z0-9.\s]/g,'');
        this.domain = domain == 'localhost' ? 'com.localhost' : domain;
    }


    ngOnInit(): void {
        this.buildForm();
        this.GetMobileApp();
        this.primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
        
    }


    buildForm() {
        this.appConfigurationForm = this.fb.group({
            Id: [null],
            AppIcon: [null, Validators.required],
            AppName: [null, [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z0-9 ]*$/)]],
            Identifier: [`${this.domain}`, Validators.required],
            BaseUrl: [this.baseURL, [Validators.required]],
            Email: [null, [Validators.required, Validators.email]],
            KeyStoreFile: [null, Validators.required],
            KeyStorePassword: [null, Validators.required],
            Alias: [null, Validators.required],
            AliasPassword: [null, Validators.required],
         //   IOSDevelopmentCertificate: [null, Validators.required],
            AppleIOSCertificate: [null, Validators.required],
            TeamId: [null, [Validators.required, Validators.maxLength(15), Validators.pattern(/^[a-zA-Z0-9 ]*$/)]],
            ProvisioningProfileName: [null, [Validators.required, Validators.maxLength(80)]],
            ProvisioningProfile: [null, [Validators.required]],
            UDID: [null],
            PrimaryColor: [this.primaryColor],
            BuildStatus: ["NOT_STARTED"],
        })
    }

    isValidExtension(file, allowed: string[]) {
        if (file) {
            const fileName = file.name.toLowerCase();
            const extension = fileName.split('.').pop();
            const isAllowed = allowed.includes('.' + extension);
        //    console.log('isAllowed', isAllowed);
       //     console.log('extension', extension);
            if(!isAllowed) {
                // this._snackBar.open(`Unsupported file type. Please upload a ${allowed} format`, 'Close', {
                //     duration: 3000,
                // });
                NotificationHelper.Error(`Unsupported file type. Please upload a ${allowed} format`, '@@Error');
            }
            return isAllowed;
        }
        return true; // Default to true if no file is selected
    }

    setIdentifier(value) {
        const name = value.replace(/\s/g, "");
        const Identifier = `${this.domain + '.' + name}`;
        this.appConfigurationForm.patchValue({
            Identifier
        })
    }

    onSearch() {
        let list = [];
        if (this.themeTemplate) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.themeTemplate.filter(x => x.Name.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.themeTemplate);
            }
        }
        this.themeTemplateList = list;
    }

    onCreate() {
        this.buildForm();
        this.mandatoryFlag = false;
        this.ToggleWindow();
    }

    onEdit(row) {
        this.ToggleWindow();
       // console.log('row update', row);
        this.iconUrl = `data:image/png;base64,${row.AppIcon}`;
        row.Id = 123;// dummy id;
        this.appConfigurationForm.patchValue(row);
       // console.log('row form data', this.appConfigurationForm.getRawValue());
    }

    onGenerate(row) {
        if (row.IOSAppLink && row.IOSAppLink.trim() !== '' && row.AndroidAppLink && row.AndroidAppLink.trim() !== '' ) {
            const params = {
                buttonType:  "YesNo",
                title: "Warning",
                message: "Generating a new mobile version will discard the previously generated version. Are you sure you want to proceed?",
                data: row,
            }
            this.showDialogBox(params);
        }
        else{
            this.generateMobileAppFunction(row);
       }
        
        
    }


    GetMobileApp() {
        this.loading(true);
        this.mobileAppList = [];
        this.service.GetMobileApp().subscribe(res => {
            if (res && res?.Status != 0) {
                const list = [res];
                this.mobileAppList = list;
                if(list[0].IOSAppLink != "" && list[0].AndroidAppLink != ""){
                  this.showAppLinks = true;
                  this.appsVersion = list[0].Version;
                  this.generateLabel = "Re-build Apps" ;
                }
            } else {
                this.mobileAppList = [];
            }
            this.loading(false);
        });
    }

    browseFile(event, field, extension) {
        const file = event.target.files[0];
        const isValid = this.isValidExtension(file, extension);
        if (isValid) {
            this.appConfigurationForm.patchValue({
                [field]: file
            })
        } else {
            this.appConfigurationForm.patchValue({
                [field]: null
            })
        }
    }

    async browseIcon(event, field, extension) {
        const file = event.target.files[0];
        const isValidExt = this.isValidExtension(file, extension);
        const isValidFile = await this.checkAspectRatioAndSize(file);
        if (isValidFile && isValidExt) {
            this.appConfigurationForm.patchValue({
                [field]: file
            });
            this.iconUrl = await this.getFileURL(file);
            this.cdr.detectChanges();
        } else {
            this.appConfigurationForm.patchValue({
                [field]: null
            });
            this.iconUrl = '';
        }
        this.getFileURL(file);
    }

    readURL(event: Event): void {
        const file = event.target['files'][0] ?? null;
        if (file['size'] > this.thumbnailMaxSize) {
            NotificationHelper.Error("Image size is too large. Please select an image that is 150 KB or smaller.", '@@Error');
            return;
        }
        this.selectedThumbnail = file;
        if (this.selectedThumbnail) {
            const file = this.selectedThumbnail as File;
            const reader = new FileReader();
            reader.onload = e => {
                this.imageSrc = reader.result;
                // this.cdr.detectChanges();
            };
            reader.readAsDataURL(file);
        }
    }

    uploadFile(selectedFile, type) {
        if (selectedFile) {
            const prefix = this.appConfigurationForm.get('Name').value;
            const _object = {file: selectedFile, PathPrefix: prefix};
            return this.service.UploadImage(_object).pipe(
                tap(url => {
                    if (type == 'Thumbnail') {
                        this.appConfigurationForm.patchValue({
                            ThumbnailURL: url.ImageURL

                        });
                    }
                    if (type == 'pages') {
                        this.ImageURLs = [...this.ImageURLs, url.ImageURL];
                    }
                })
            )
        }
        return null;
    }

    generateMobileAppFunction (appData): void{
        this.loading(true);
        
        this.service.GenerateAppBuild().subscribe({
        next: (res) => {
        this.loading(false);
        NotificationHelper.Info(`Your build is currently underway. Please keep an eye on your inbox, including your spam folder, for updates at ${appData.Email}`, 'Build Underway');
      
        this.refresh();
        },
        error: error => {
            if(error.error.Error && error.error.Error != undefined){
                this.loading(false);
                // NotificationHelper.Error(error.error.Message, '@@Error');
                return;
            }
        }})
    }

    getInvalidFields(): string[] {
        const emptyFields = [];
        // Iterate over form controls and check if they are empty
        Object.keys(this.appConfigurationForm.controls).forEach(field => {
            const control = this.appConfigurationForm.get(field);
            if (control.invalid) {
                const formatField = field.replace(/([a-z])([A-Z])/g, '$1 $2')
                emptyFields.push(formatField);
            }
        });

        return emptyFields;
    }
    SaveAppInfo() {
        if (this.appConfigurationForm.get('AppName').hasError('pattern')) {
            NotificationHelper.Error("Special characters are not allowed in App Name", '@@Error');
            return;
        }
        if (!this.appConfigurationForm.valid) {
            const invalidFields = this.getInvalidFields();
            if(invalidFields.length > 1) {
                NotificationHelper.Error(`Please fill out the fields: ${invalidFields.join(', ')}.`, '@@Error');
            }
            else {
                NotificationHelper.Error(`Please fill out the field: ${invalidFields.join(', ')}.`, '@@Error');
            }
            // NotificationHelper.Error("Please fill out mandatory fields", '@@Error');
            return;
        }
        const data = this.appConfigurationForm.getRawValue();
        // create form data object
        const formData = new FormData()
        formData.append('AppIcon', data['AppIcon']);
        formData.append('AppName', data['AppName']);
        formData.append('Identifier', data['Identifier']);
        formData.append('BaseUrl', data['BaseUrl']);
        formData.append('Email', data['Email']);
        formData.append('KeyStoreFile', data['KeyStoreFile']);
        formData.append('KeyStorePassword', data['KeyStorePassword'].trim());
        formData.append('Alias', data['Alias'].trim());
        formData.append('AliasPassword', data['AliasPassword'].trim());
        formData.append('IOSDevelopmentCertificate', data['IOSDevelopmentCertificate']);
        formData.append('AppleIOSCertificate', data['AppleIOSCertificate']);
        formData.append('ProvisioningProfile', data['ProvisioningProfile']);
        formData.append('TeamId', data['TeamId'].trim());
        formData.append('ProvisioningProfileName', data['ProvisioningProfileName'].trim());
        formData.append('UDID', data['UDID']);
        formData.append('PrimaryColor', data['PrimaryColor']);
        this.loading(true)
        this.service.SaveAppInfo(formData).subscribe((res) => {
            this.loading(false);
            NotificationHelper.Success("App Info Saved Successfully", '@@Success');
            this.refresh();
        })
    }

    UpdateAppInfo() {
        if (this.appConfigurationForm.get('AppName').hasError('pattern')) {
            NotificationHelper.Error("Special characters are not allowed in App Name", '@@Error');
            return;
        }
        const _invalidFields = this.getInvalidFields();
        const checkFields = ['App Name', 'Email', 'Base Url', 'Team Id', 'Provisioning Profile Name'];
        const invalidFields = _invalidFields.filter(field => checkFields.includes(field));
        if(invalidFields && invalidFields.length > 0) {
            if(invalidFields.length > 1) {
                NotificationHelper.Error(`Please fill out the fields: ${invalidFields.join(', ')}.`, '@@Error');
            }
            else {
                NotificationHelper.Error(`Please fill out the field: ${invalidFields.join(', ')}.`, '@@Error');
            }
            return;
        }
        const data = this.appConfigurationForm.getRawValue();
       // console.log('data update', data);
        // create form data object

        const formData = new FormData()
        formData.append('AppName', data['AppName']);
        formData.append('Identifier', data['Identifier']);
        formData.append('BaseUrl', data['BaseUrl']);
        formData.append('Email', data['Email']);
        formData.append('Alias', data['Alias'].trim());
        formData.append('UDID', data['UDID']);
        formData.append('TeamId', data['TeamId'].trim());
        formData.append('ProvisioningProfileName', data['ProvisioningProfileName'].trim());
        if (data['AppIcon']) {
            formData.append('AppIcon', data['AppIcon']);
        }
        if (data['KeyStoreFile']) {
            formData.append('KeyStoreFile', data['KeyStoreFile']);
        }
        if (data['KeyStorePassword']) {
            formData.append('KeyStorePassword', data['KeyStorePassword'].trim());
        }
        if (data['AliasPassword']) {
            formData.append('AliasPassword', data['AliasPassword'].trim());
        }
        // if (data['IOSDevelopmentCertificate']) {
        //     formData.append('IOSDevelopmentCertificate', data['IOSDevelopmentCertificate']);
        // }
        if (data['AppleIOSCertificate']) {
            formData.append('AppleIOSCertificate', data['AppleIOSCertificate']);
        }
        if (data['ProvisioningProfile']) {
            formData.append('ProvisioningProfile', data['ProvisioningProfile']);
        }
        this.loading(true)
        this.service.UpdateAppInfo(formData).subscribe((res) => {
            this.loading(false);
            NotificationHelper.Success("App Info Updated Successfully", '@@Success');
            this.refresh();
        })
    }

    refresh() {
        this.GetMobileApp();
        this.toggleWindow = true;
        this.mandatoryFlag = false;
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }

    onCancel() {
        this.appConfigurationForm.reset();
        this.markControlsAsUntouched(this.appConfigurationForm);
        this.ToggleWindow();
    }

    markControlsAsUntouched(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            formGroup.get(key).markAsPristine();
            formGroup.get(key).markAsUntouched();
        });
    }

    async getFileURL(file: any): Promise<string> {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const fileURL = e.target.result;
                resolve(fileURL);
            };

            reader.readAsDataURL(file);
        });
    }

    // check aspect ration and size
    async checkAspectRatioAndSize(file): Promise<boolean> {
        const maxSizeInBytes = 1024 * 1024; // 1 MB

        if (file.size > maxSizeInBytes) {
            // this._snackBar.open("Icon size is too large. Please select a smaller Icon", 'Close', {
            //     duration: 1000,
            // });
            NotificationHelper.Error(`Icon size is too large. Please select a smaller Icon`, '@@Error');
            return false;
        }
        return new Promise<boolean>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    if (!this.checkAspectRatio(img)) {
                        // this._snackBar.open("Icon aspect ratio should 1024 * 1024", 'Close', {
                        //     duration: 2000,
                        // });
                        NotificationHelper.Error(`Icon aspect ratio should 1024 * 1024`, '@@Error');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            };

            reader.readAsDataURL(file);
        });
    }

    checkAspectRatio(img: HTMLImageElement): boolean {
        const aspectRatio = 1; // 1024 * 1024
        const imageAspectRatio = img.width / img.height;
        return Math.abs(imageAspectRatio - aspectRatio) <= 0.01;
    }

    getMainDomain(url: string): string {
        const hostname = new URL(url).hostname;
        const parts = hostname.split('.'); // split the hostname into parts using dot as a separator
        return parts.slice(-2).reverse().join('.'); // return the last two parts, which represent the main domain

    }

    showDialogBox(_params = {
        buttonType:  "",
        title: "",
        message: "",
        data:"",
    }) {
        return new Promise<boolean>(resolve => {
            MessageBoxHelper.ShowDialog(new TranslateFormatText(_params.message),
                new TranslateFormatText(_params.title), MessageBoxButtons[_params.buttonType], MessageBoxIcon[_params.title]).then(retVal => {
                if(retVal === MessageBoxResult.Yes){
                   this.generateMobileAppFunction(_params.data);
                }
                if (retVal === MessageBoxResult.No || MessageBoxResult.Cancel) {
                    return false;
                }
            });

        });

       
    }

    onDownload(downloadFile : string){
        const link = document.createElement('a');
        if(this.mobileAppList.length > 0){
            link.href =  (downloadFile == "android") ?  this.mobileAppList[0].AndroidAppLink : this.mobileAppList[0].IOSAppLink;
            link.setAttribute('download', "");
            link.click();
        }
    }
    

}
