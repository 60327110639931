import { StepIntoHandler } from '../models/workflow/workflow.model';
import { ScriptOperation } from './scriptoperation.model';

// @dynamic
export class Script {

}

export class ScriptBreakPoint {
    public LineNumber: number;
}

export class Module {
    public Imports = new Map<string, any>();
    public Exports = new Map<string, any>();
    public Classes = new Map<string, Class>();
    public Properties = new Map<string, Property>();
}

export class Class {
    public TypescriptType: any;
    public Name: string;
    public Module: Module;
    public Functions = new Map<string, Function>();
    public Properties = new Map<string, Property>();
    public Constants = new Map<string, any>();

    constructor(type: any = null) {
        this.TypescriptType = type;
    }
}

export class Function {
    public Name: string;
    public IsPublic: boolean;
    public IsStatic: boolean;
    public Parameters : Parameter[] = [];
    public ReturnClass: string;
    public Script: string;
    public Class: Class;
    public IsInlineFunction: boolean;
    public LastLine: number;

    public ScriptLines = [];

    getLineOnLineNumber(lineNumber: number) {
        let retVal;
        if (this.ScriptLines) {
            this.ScriptLines.some(x => {
                retVal = x.getLineOnLineNumber(lineNumber);
                if (retVal) {
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }
}

export class Property {
    public Name: string;
    public Class: string;
    public Default: ScriptOperation;
    public IsPublic: boolean;
    public IsStatic: boolean;
    public IsReadOnly: boolean;
}

export class Parameter {
    public Name: string;
    public Class: string;
    public Default: ScriptOperation;
    public IsOptional: boolean;
}

export class InstanceClass {
    public IsStatic: boolean;
    public Class: Class;
    public get ClassName(): string {
        return this.Class?.Name;
    }
    public Properties = new Map<string, any>();
}

export class ScriptStepIntoHandler extends StepIntoHandler {
    GoodToRemove: boolean;
}

export class ScriptStack {
    public LinePosition: number;
    public LinePositionInfo: LinePositionInfo;
    public Variables = new Map<string, any>();
    public Instance: any;
    public Function: Function;
    public StepIntoHandler: ScriptStepIntoHandler;
}

export class LinePositionInfo {
    CanStepInto = false;
    ScriptLibraryID: string;
}
