import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumHelper } from '../../helpers/enum.helper';
import { SeriesSettings } from '../../models/controls/chart.model';
import { ChartType } from '../../models/enums/charttype.enum';
import { SeriesType } from '../../models/enums/seriestype.enum';
import { Color } from '../../models/style/color.model';

@Component({
    selector: 'chart-series-settings-control',
    templateUrl: './chart.series.settings.control.html',
    styleUrls: ['./chart.series.settings.control.css']
})
export class ChartSeriesSettingsControl implements OnInit {
    SeriesColorStyle = {};
    SeriesType = SeriesType.bars;
    SeriesTypes;
    SeriesColor = new Color();

    //#region SeriesSettings
    SeriesSettingsValue: SeriesSettings;
    @Input()
    get SeriesSettings() {
        return this.SeriesSettingsValue;
    }
    set SeriesSettings(val) {
        if (val !== this.SeriesSettingsValue) {
            this.SeriesSettingsValue = val;
            if (val && val.Color) {
                this.SeriesColor = Color.FromHex(this.SeriesSettingsValue.Color);
                this.SeriesColorStyle['fill'] = val.Color;
            } else {
                this.SeriesColor = new Color();
                delete this.SeriesColorStyle['fill'];
            }
            this.SeriesSettingsChange.emit(this.SeriesSettingsValue);
        }
    }
    @Output() SeriesSettingsChange = new EventEmitter<any>();
    //#endregion

    //#region SeriesType
    ChartTypeValue: ChartType = ChartType.ColumnChart;
    @Input()
    get ChartType() {
        return this.ChartTypeValue;
    }
    set ChartType(val) {
        if (val !== this.ChartTypeValue) {
            this.ChartTypeValue = val;
            this.ChartTypeChange.emit(this.ChartTypeValue);
        }
    }
    @Output() ChartTypeChange = new EventEmitter<any>();
    //#endregion

    constructor() {
        this.SeriesTypes = EnumHelper.GetDropdownValues(SeriesType);
    }

    ngOnInit(): void {
        switch (this.ChartTypeValue) {
            case ChartType.AreaChart:
                this.SeriesType = SeriesType.area;
                break;
            case ChartType.Line:
                this.SeriesType = SeriesType.line;
                break;
            case ChartType.CandlestickChart:
                this.SeriesType = SeriesType.candlesticks;
                break;
            case ChartType.SteppedAreaChart:
                this.SeriesType = SeriesType.steppedArea;
                break;
            case ChartType.ComboChart:
                if (this.SeriesSettingsValue && typeof this.SeriesSettingsValue.Type === 'number') {
                    this.SeriesType = this.SeriesSettingsValue.Type;
                } else {
                    this.SeriesType = SeriesType.bars;
                }
                break;
            default:
                this.SeriesType = SeriesType.bars;
        }
    }

    lineWidthChanged(ev) {
        if (ev && ev.value && this.SeriesSettingsValue) {
            this.SeriesSettingsValue.LineWidth = ev.value;
        }
    }

    colorChanged(val) {
        if (this.SeriesSettingsValue && val) {
            this.SeriesSettingsValue.Color = Color.HexFromColor(val);
            this.SeriesColorStyle['fill'] = this.SeriesSettingsValue.Color;
        }
    }
}
