import { Type } from 'class-transformer';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';

// @dynamic
export class RelToObjectColumnMapping {
    Source: string;
    Target: string;
    IsList = false;
    IsCommaSeparated = true;
    Separator = ',';
    SimpleListType: string;
    ListID: string[] = [];
    IsObject = false;
    @Type(() => RelToObjectColumnMapping)
    ObjectMappings: RelToObjectColumnMapping[] = [];
}

// @dynamic
export class RelToObjectSettingsData extends AServiceWorkflowData {
    UseTable = true;
    DataModel: string;
    DataSource: string;
    ExistingStructure: string;
    ContainerInternalID: string;
    @Type(() => RelToObjectColumnMapping)
    Mappings: RelToObjectColumnMapping[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.RelToObject.RelToObjectSettingsData';
    }
}