import { Component } from '@angular/core';
import { BaseDialog } from './basedialog/base.dialog';

@Component({
  selector: 'app-relation-overview-dialog',
  templateUrl: './relation.overview.dialog.html',
  styleUrls: ['./relation.overview.dialog.css']
})
export class RelationOverviewDialogComponent {

  InitArgs;

  static showDialog(container, relations, dataModel, handler) {
    BaseDialog.ShowDialog({
      ContentType: RelationOverviewDialogComponent,
      Title: 'Container',
      InitArgs: {
        Container: container,
        Relations: relations,
        DataModelID: dataModel
      },
      Handler: r => {
        if (r && handler) {
          handler(r);
        }
        return true;
      },
      HeightText: '100vh',
      WidthText: '100vw'
    });
  }

  Initialize(args) {
    if (args && args.Container && args.Relations) {
      this.InitArgs = {
        Container: args.Container.slice(),
        Relations: args.Relations.slice(),
        DataModelID: args.DataModelID
      };
    }
  }

  GetDialogResult() {
    return this.InitArgs;
  }
}
