import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import listPlugin from '@fullcalendar/list'; // a plugin
import timePlugin from '@fullcalendar/timegrid'; // a plugin
import { GridsterModule } from 'angular-gridster2';
import { MentionModule } from 'angular-mentions';
import { ResizableModule } from 'angular-resizable-element';
import { AngularResizeEventModule } from 'angular-resize-event';
import { AngularDraggableModule } from 'angular2-draggable';
import { QueryBuilderModule } from "angular2-query-builder";
import { QRCodeModule } from 'angularx-qrcode';
import { SnotifyModule } from 'ng-snotify';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { QuillModule } from 'ngx-quill';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastUiImageEditorModule } from "ngx-tui-image-editor";
import { LazyLoadImageModule } from 'ng-lazyload-image';


FullCalendarModule.registerPlugins([
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    timePlugin
]);

@NgModule({
    imports: [
        FullCalendarModule,
        GridsterModule,
        AngularDraggableModule,
        DragDropModule,
        SlickCarouselModule,
        QueryBuilderModule,
        SnotifyModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        InfiniteScrollModule,
        ToastUiImageEditorModule,
        QRCodeModule,
        ResizableModule,
        QuillModule.forRoot(),
        MonacoEditorModule.forRoot(),
        NgxJsonViewerModule,
        PdfViewerModule,
        MentionModule,
        AngularResizeEventModule,
        Ng2GoogleChartsModule,
        LazyLoadImageModule
    ],
    exports: [
        FullCalendarModule,
        GridsterModule,
        AngularDraggableModule,
        DragDropModule,
        SlickCarouselModule,
        QueryBuilderModule,
        SnotifyModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        InfiniteScrollModule,
        ToastUiImageEditorModule,
        QRCodeModule,
        ResizableModule,
        QuillModule,
        MonacoEditorModule,
        NgxJsonViewerModule,
        PdfViewerModule,
        MentionModule,
        AngularResizeEventModule,
        Ng2GoogleChartsModule,
        LazyLoadImageModule
    ]
})
export class OthersModule { }
