export enum Comparer {
    Equal,
    NotEqual,
    Between,
    NotBetween,
    Greater,
    Smaller,
    GreaterEqual,
    SmallerEqual,
    Like,
    In,
    NotIn,
    SizeEqual,
    SizeGreater,
    SizeGreaterEqual,
    SizeSmaller,
    SizeSmallerEqual,
    NotLike
}
