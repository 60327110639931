import { ChartAnimationEasing } from '../enums/chartanimationeasing.enum';
import { AxisTextPosition } from '../enums/axistextposition.enum';
import { ChartLegendAlignment } from '../enums/chartlegendalignment.enum';
import { ChartLegendPosition } from '../enums/chartlegendposition.enum';
import { ChartStackType, ChartTrendlineType, ChartPieSliceText, ChartGeoResolution } from '../enums/chartcurvetype.enum';
import { PointShape } from '../enums/pointShape.enum';
import { SeriesType } from '../enums/seriestype.enum';
import { ChartToolTipTrigger } from '../enums/charttooltiptrigger.enum';
import { ChartType } from '../enums/charttype.enum';
import { WordTreeType } from '../enums/wordtreetype.enum';
import { Type } from 'class-transformer';

export class AnimationSettings {
    Duration: number;
    Easing: ChartAnimationEasing;
    StartUp: boolean;

    static GetDefault(): AnimationSettings {
        const retVal = new AnimationSettings();
        retVal.Duration = 0;
        retVal.Easing = ChartAnimationEasing.linear;
        retVal.StartUp = false;
        return retVal;
    }
}

export class ChartTextStyle {
    Color: string;
    FontName: string;
    FontSize: number;
    Bold: boolean;
    Italic: boolean;
}

export class GridLineSettings {
    Color: string;
    Count: number;
}

// @dynamic
export class AxisSettings {
    ReverseDirection: boolean;

    @Type(() => GridLineSettings)
    GridLines: GridLineSettings;
    @Type(() => GridLineSettings)
    MinorGridLines: GridLineSettings;

    TextPosition: AxisTextPosition;
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;

    Title: string;
    @Type(() => ChartTextStyle)
    TitleTextStyle: ChartTextStyle;

    Format: string;
}

export class AxisTick {
    Caption: string;
    Value;
}

// @dynamic
export class ContinuousAxisSettings extends AxisSettings {
    LogarithmicScaling: boolean;
    @Type(() => AxisTick)
    Ticks: AxisTick[] = [];
    @Type(() => AxisTick)
    MinValue: AxisTick;
    @Type(() => AxisTick)
    MaxValue: AxisTick;
}

// @dynamic
export class LegendSettings {
    Alignment: ChartLegendAlignment;
    Position: ChartLegendPosition;
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;
}

export class CandleStickColorSettings {
    Fill: string;
    Stroke: string;
    StrokeWidth: number;
}

export class TrendLineSettings {
    Color: string;
    LabelInLegend: string;
    VisibleInLegend: boolean;
    LineWidth: number;
    Opacity: number;
    PointSize: number;
    PointsVisible: boolean;
    ShowR2: boolean;
    Type: ChartTrendlineType;
    Degree: number;
}

// @dynamic
export class SeriesSettings {
    Color: string;
    LegendLabel: string;
    VisibleInLegend: boolean;
    TargetAxis: number;
    Type: SeriesType;
    @Type(() => TrendLineSettings)
    TrendLine: TrendLineSettings;

    AreaOpacity: number;

    CurvedLine: boolean;
    LineDashStyle: number[];
    LineWidth: number;
    PointsVisible: boolean;

    PointShape: PointShape;
    PointSize: number;

    @Type(() => CandleStickColorSettings)
    FallingColor: CandleStickColorSettings;
    @Type(() => CandleStickColorSettings)
    RisingColor: CandleStickColorSettings;
}

// @dynamic
export class TooltipSettings {
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;
    Trigger: ChartToolTipTrigger;
}

export class AnnotationChartSettings {
    DisplayAnnotations: boolean;
    DisplayAnnotationsFilter: boolean;
    DisplayRangeSelector: boolean;
    DisplayZoomButtons: boolean;
    FillOpacity: number;
    SortAscending: boolean;
    SortColumn: number;
    Thickness: number;
}

export class ColorAxisValue {
    Color: string;
    Value: number;
}

// @dynamic
export class BubbleChartSettings {
    Opacity: number;
    Stroke: string;
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;
    MaxBubbleSize: number;
    MaxBubbleValue: number;
    MinBubbleSize: number;
    MinBubbleValue: number;

    @Type(() => ColorAxisValue)
    ColorValues: ColorAxisValue[] = [];

    ShowLegend: boolean;
    LegendPosition: ChartLegendAlignment;
    @Type(() => ChartTextStyle)
    LegendTextStyle: ChartTextStyle;
    LegendNumberFormat: string;
}

// @dynamic
export class SliceSettings {
    Color: string;
    Offset: number;
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;
}

// @dynamic
export class PieChartSettings {
    Is3D: boolean;
    PieHole: number;
    SliceBorderColor: string;
    SliceText: ChartPieSliceText;
    @Type(() => ChartTextStyle)
    SliceTextStyle: ChartTextStyle;
    StartAngle: number;
    ResidueSliceColor: string;
    ResidueSliceLabel: string;
    ResidueThreshold: number;

    @Type(() => SliceSettings)
    Slices: SliceSettings[] = [];
}

// @dynamic
export class GeoChartSettings {
    Region: string;
    Resolution: ChartGeoResolution;
    @Type(() => ColorAxisValue)
    ColorValues: ColorAxisValue[] = [];
}

export class MapChartSettings {
    // ???
}

export class WordTreeChartSettings {
    Type: WordTreeType;
}

export class GaugeArea {
    Color: string;
    From: number;
    To: number;
}

// @dynamic
export class GaugeChartSettings {
    @Type(() => GaugeArea)
    GreenArea: GaugeArea;
    @Type(() => GaugeArea)
    YellowArea: GaugeArea;
    @Type(() => GaugeArea)
    RedArea: GaugeArea;
    MajorTicks: string[] = [];
    MinorTicks: number;
    MinValue: number;
    MaxValue: number;
}

// @dynamic
export class TreeMapChartSettings {
    FontColor: string;
    HeaderColor: string;
    HeaderHeight: number;
    HeaderHighlightColor: string;
    HighlightOnHover: boolean;
    HintOpacity: number;
    MaxColor: string;
    MaxDepth: number;
    MaxHighlightColor: string;
    MaxPostDepth: number;
    MaxColorValue: number;
    MidColor: string;
    MidHighlightColor: string;
    MinColor: string;
    MinHighlightColor: string;
    MinColorValue: number;
    NoColor: string;
    NoHighlightColor: string;
    ShowScale: boolean;
    ShowTooltips: boolean;
    @Type(() => ChartTextStyle)
    TextStyle: ChartTextStyle;
    UseWeightedAverageForAggregation: boolean;
}

// @dynamic
export class GanttChartSettings {
    ArrowAngle: number;
    ArrowColor: string;
    ArrowLength: number;
    ArrowRadius: number;
    ArrowWidth: number;
    CornerRadius: number;
    BarHeight: number;
    CriticalPathEnabled: boolean;
    CriticalPathStroke: string;
    CriticalPathStrokeWidth: number;
    GridLineStroke: string;
    GridLineStrokeWidth: number;
    GridTrack: string;
    GridDarkTrack: string;
    @Type(() => ChartTextStyle)
    LabelStyle: ChartTextStyle;
    PercentEnabled: boolean;
    PercentFill: string;
    ShadowEnabled: boolean;
    ShadowColor: string;
    ShadowOffset: number;
    TrackHeight: number;
}

// @dynamic
export class CalendarChartSettings {
    @Type(() => ColorAxisValue)
    ColorValues: ColorAxisValue[] = [];
    CellBorderStroke: string;
    CellBorderStrokeWidth: number;
    CellBorderOpacity: number;
    CellSize: number;
    @Type(() => ChartTextStyle)
    DayOfWeekLabel: ChartTextStyle;
    FocusedCellBorderStroke: string;
    FocusedCellBorderStrokeWidth: number;
    FocusedCellBorderOpacity: number;
    @Type(() => ChartTextStyle)
    MonthLabel: ChartTextStyle;
    MonthBorderStroke: string;
    MonthBorderStrokeWidth: number;
    MonthBorderOpacity: number;
    UnUsedMonthBorderStroke: string;
    UnUsedMonthBorderStrokeWidth: number;
    UnUsedMonthBorderOpacity: number;
}

// @dynamic
export class TimelineChartSettings {
    AvoidOverlap: boolean;
    @Type(() => ChartTextStyle)
    BarLabelStyle: ChartTextStyle;
    ColorByRowLabel: boolean;
    GroupByRowLabel: boolean;
    @Type(() => ChartTextStyle)
    RowLabelStyle: ChartTextStyle;
    ShowBarLabels: boolean;
    ShowRowLabels: boolean;
    SingleColor: string;
}

export class ColorRangeValue {
    Color: string;
    From: number;
    To: number;
    Caption: string;
}

// @dynamic
export class HeatMapChartSettings {
    @Type(() => ColorRangeValue)
    ColorValues: ColorRangeValue[] = [];
}

// @dynamic
export class ChartSettings {

    ChartType: ChartType;

    BackgroundColor: string;
    ChartPalette: string;
    SeriesColors: string[] = [];
    @Type(() => AnimationSettings)
    Animation: AnimationSettings;

    FontSize: number;
    FontName: string;

    AxisTextPosition: AxisTextPosition;
    @Type(() => AxisSettings)
    LabelAxis: AxisSettings;
    @Type(() => ContinuousAxisSettings)
    ValueAxes: ContinuousAxisSettings[] = [];

    Height: number;
    Width: number;

    IsStacked: ChartStackType;
    BarGroupWidth: string;
    InterpolateNulls: boolean;
    SwitchOrientation: boolean;
    ReverseCategories: boolean;

    @Type(() => LegendSettings)
    Legend: LegendSettings;

    @Type(() => SeriesSettings)
    Series: SeriesSettings[] = [];

    @Type(() => SeriesSettings)
    DefaultSeriesSetting: SeriesSettings;

    Title: string;
    TitlePosition: AxisTextPosition;

    Tooltip: TooltipSettings;

    @Type(() => AnnotationChartSettings)
    AnnotationSettings: AnnotationChartSettings;
    @Type(() => BubbleChartSettings)
    BubbleSettings: BubbleChartSettings;
    @Type(() => PieChartSettings)
    PieSettings: PieChartSettings;
    @Type(() => GeoChartSettings)
    GeoSettings: GeoChartSettings;
    @Type(() => MapChartSettings)
    MapSettings: MapChartSettings;
    @Type(() => WordTreeChartSettings)
    WordTreeSettings: WordTreeChartSettings;
    @Type(() => GaugeChartSettings)
    GaugeSettings: GaugeChartSettings;
    @Type(() => TreeMapChartSettings)
    TreeMapSettings: TreeMapChartSettings;
    @Type(() => GanttChartSettings)
    GanttSettings: GanttChartSettings;
    @Type(() => CalendarChartSettings)
    CalendarSettings: CalendarChartSettings;
    @Type(() => TimelineChartSettings)
    TimelineSettings: TimelineChartSettings;
    @Type(() => HeatMapChartSettings)
    HeatMapSettings: HeatMapChartSettings;

    static GetDefault(): ChartSettings {
        const retVal = new ChartSettings();
        retVal.ChartType = ChartType.ColumnChart;
        retVal.BackgroundColor = 'white';
        retVal.FontName = 'Arial';
        return retVal;
    }
}
