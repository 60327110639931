import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SecurityService } from '../../../services/security.service';
import { UsersService } from '../../../services/users.service';

@Component({
    selector: 'login',
    templateUrl: './login.control.html',
    styleUrls: ['./login.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginControl {
    error = false;
    showCredentials = true;
    selectedProvider;
    SecurityProviderTypes;
    credentials = {
        UserName: '',
        Password: '',
        ProviderId: null
    };
    loading = {
        status: false,
        text: 'Loading ...'
    };
    isScanner = false;

    constructor(private usersService: UsersService, private securityService: SecurityService) {
        this.securityService.GetSecurityProviders().subscribe((types) => {
            if (types) {
                this.SecurityProviderTypes = types;
                let found = false;
                this.SecurityProviderTypes.forEach((provider) => {
                    if (provider.IsDefault) {
                        this.credentials.ProviderId = provider.Id;
                        if (provider.Url) {
                            found = true;
                        }
                        if (provider.Type === '31e75470-8458-48e5-af70-d8a6e8840b37') {
                            this.isScanner = true;
                            found = true;
                        }
                        this.selectedProvider = provider;
                    }
                })
                this.showCredentials = !found;
            }
        });
    }

    onKeydown(event) {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    login() {
        this.loading.status = true;
        if (!this.showCredentials && !this.isScanner) {
            window.location.href = (this.selectedProvider.Url + '&state=' + this.credentials.ProviderId + '|' + window.location.href);
        } else {
            this.usersService.executeLogin(this.credentials).then(x => {
                this.loading.status = false;
                this.error = !x;
            });
        }
    }

    selectionChanged() {

        if (this.SecurityProviderTypes) {
            this.isScanner = false;
            let found = false;
            this.SecurityProviderTypes.forEach((provider) => {
                if (provider.Id == this.credentials.ProviderId) {
                    if (provider.Url) {
                        found = true;
                    }
                    if (provider.Type === '31e75470-8458-48e5-af70-d8a6e8840b37') {
                        this.isScanner = true;
                        found = true;
                    }
                    this.selectedProvider = provider;
                }
            })
            this.showCredentials = !found;
        }
    }
}
