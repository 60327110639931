import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { DataModel } from '../models/datamodel/datamodel.model';
import { DataSource } from '../models/datamodel/datasource.model';
import { ResourceBase } from '../models/enums/resourcebase.enum';

@Injectable()
export class DataModelService extends RequestBase {
    BasePath = 'api/selfservice/model';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetModels(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetModels', this.options);
    }

    SaveModel(model: DataModel): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveModel', model, this.options);
    }

    DeleteModel(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteModel?sid=' + id, this.options);
    }

    GetDataModel(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataModel?sid=' + id, this.options);
    }

    GetDataSources(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataSources?dataModelId=' + id, this.options);
    }

    GetDataSourcesByQuery(dataSourceQuery: DataSourceQuery): Observable<DataSourceInfo[]> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetDataSourcesByQuery', dataSourceQuery, this.options);
    }

    GetDataSourceInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataSourceInfos', this.options);
    }

    SaveDataSource(source: DataSource): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveDataSource', source, this.options);
    }

    CheckConnection(source: DataSource): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CheckConnection', source, this.options);
    }

    DeleteSource(id: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteSource?sid=' + id, this.options);
    }

    GetDataSource(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataSource?sid=' + id, this.options);
    }

    GetTables(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTables?dataSourceId=' + id, this.options);
    }

    GetDrivers(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDrivers', this.options);
    }

    GetSortedDrivers(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDrivers?sort=false', this.options);
    }

    GetContainer(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetContainer?sid=' + id, this.options);
    }
    GetContainers(id: string, typeName: string = null): Observable<any> {
        const params = id + (typeName ? '&typeName=' + typeName : '');
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetContainers?dataModelId=' + params, this.options);
    }

    GetContainersOfType(dataModel: string, typeName: string) {
        const path = this.API_BASE_URL + this.BasePath + '/GetContainersOfType?dataModelId=' + dataModel + '&typeName=' + typeName;
        return this.http.get<any>(path, this.options);
    }

    DeleteContainer(sid: string) {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteContainer?sid=' + sid, this.options);
    }

    SaveContainer(typeName: string, container) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveContainer?typeName=' + typeName, container, this.options);
    }

    GetAvailableOptions(dataSource, optInfo): Observable<any> {
        const path = this.API_BASE_URL + 'api/selfservice/preview/GetAvailableOptions?dataSourceId=' + dataSource;
        return this.http.post<any>(path, optInfo, this.options);
    }

    GetContainerByDBUsage(query) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetContainerByDBUsage', query, this.options);
    }

    GetFilterInfo(containerID) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetFilterInfo?containerID=' + containerID, this.options);
    }
}

@Injectable()
export class SelfServiceObjectService extends RequestBase {
	BasePath = 'api/selfservice/object';

	ExecuteObjectQuery(containerID, filter): Observable<any> {
		const path =
			this.API_BASE_URL +
			this.BasePath +
			'/ExecuteObjectQuery?containerId=' +
			containerID;
		return this.http.post<any>(path, filter, this.options);
	}
	ExecuteObjectQueryExtended(containerID, filter): Observable<any> {
		const path =
			this.API_BASE_URL +
			'api/dynamicdata' +
			'/ExecuteObjectQueryExtended?containerId=' +
			containerID;
		return this.http.post<any>(path, filter, this.options);
	}
	GetObjectPreview(
		dataSource,
		previewInfo,
		rowCount?: number
	): Observable<any> {
		let path =
			this.API_BASE_URL +
			this.BasePath +
			'/GetObjectPreview?dataSourceId=' +
			dataSource;
		if (rowCount) {
			path += '&rowcount=' + rowCount;
		}
		return this.http.post<any>(path, previewInfo, this.options);
	}

	GetStructurePreview(dataSource, previewInfo): Observable<any> {
		const path =
			this.API_BASE_URL +
			this.BasePath +
			'/GetStructurePreview?dataSourceId=' +
			dataSource;
		return this.http.post<any>(path, previewInfo, this.options);
	}

	GetStructurePreviewFromContainer(container): Observable<any> {
		const path =
			this.API_BASE_URL +
			this.BasePath +
			'/GetStructurePreviewFromContainer?container=' +
			container;
		return this.http.get<any>(path, this.options);
	}

	GetStructurePreviewForMapping(container, withID): Observable<any> {
		const path =
			this.API_BASE_URL +
			this.BasePath +
			'/GetStructurePreviewForMapping?container=' +
			container +
			'&withID=' +
			withID;
		return this.http.get<any>(path, this.options);
	}
}

// @Injectable()
// export class dynamicDataService extends RequestBase {
// 	BasePath = 'api/dynamicdata';
// 	ExecuteObjectQueryExtended(containerID, filter): Observable<any> {
// 		const path =
// 			this.API_BASE_URL +
// 			this.BasePath +
// 			'/ExecuteObjectQueryExtended?containerId=' +
// 			containerID;
// 		return this.http.post<any>(path, filter, this.options);
// 	}
// }
@Injectable()
export class ContainerEnumService extends RequestBase {
    BasePath = 'api/dynamic';

    GetEnumsByDataModel(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetEnumsByDataModel?id=' + id, this.options);
    }

    GetEnum(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetEnum?id=' + id, this.options);
    }

    GetEnumValues(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetEnumValues?id=' + id, this.options);
    }

    SaveEnum(val): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveEnum', val, this.options);
    }

    DeleteEnum(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteEnum?id=' + id, this.options);
    }

    GetAllEnumInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllEnumInfos', this.options);
    }
}

@Injectable()
export class SelfServiceSecurityService extends RequestBase {
    BasePath = 'api/selfservice/security';

    GetDataModelStructure(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataModelStructure', this.options);
    }

    GetSecurity(container, role): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/GetSecurity?container=' + container + '&role=' + role;
        return this.http.get<any>(path, this.options);
    }

    SaveSecurity(sec): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveSecurity', sec, this.options);
    }

    DeleteSecurity(sid): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteSecurity?sid=' + sid, this.options);
    }

    DeleteCompleteRole(roleId): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteCompleteRole?roleId=' + roleId, this.options);
    }
}

export class DataSourceQuery {
    DBInfoType: string;
    DBUsages: number[][];
    ResourceBase: ResourceBase;
    IgnoreResourceBase = false;
    FillInteractiveProperties = false;

    constructor(public DataModelID: string) {
    }
}

export class DataSourceInfo {
    Caption: string;
    SID: string;
    DataModelID: string;
    InteractiveProperties: string[];
}
