import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { Type } from 'class-transformer';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { TemplateLayout } from '../../../components/layouts/template/template.layout';
import { ClientHelper } from '../../../helpers/client.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { WorkflowDebugHelper } from '../../../helpers/workflow.debug.helper';
import { NotificationLevel } from '../../../models/enums/notificationlevel.enum';
import { NextStep } from '../../../models/workflow/debug.log.message';
import {
    AServiceWorkflowData, StepIntoHandler, WorkflowData, WorkflowModuleExecuter, WorkflowStatus
} from '../../../models/workflow/workflow.model';
import { LayoutService } from '../../../services/layout.service';
import { WebsocketService } from '../../../services/websocket.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry, WorkflowService } from '../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowEditDialog } from '../../workflow.edit.dialog';
import { WorkflowEngine } from '../../workflow.engine';

@Component({
    selector: 'execwf-settings',
    templateUrl: './executeWorkflow.settings.html',
    styleUrls: ['./executeWorkflow.settings.css']
})
export class ExecuteWorkflowSettings extends FormulaWorkflowDialogContent {
    Nodes: WorkflowNode[] = [];
    VariableNodes: ABaseTreeNode[] = [];
    SelectedNode;
    ParamSettings = [];
    WaitForFinish = true;
    StartNewTrigger = false;
    ExecuteInService = false;
    SearchValue:string = null;
    FilteredNodes: WorkflowNode[] = [];
    ServiceNodeSelected = false;

    static ModuleID = 'execWFModule';

    private static getLayoutNodesRecursive(layout, nodeList, selection, templateName, id: IDKeeper) {
        if (layout.Elements) {
            layout.Elements.forEach((elem) => {
                if (elem.ElementType === TemplateLayout.Type) {
                    const node = new WorkflowNode(id.NextID);
                    node.Caption = elem.Name;
                    node.Selectable = false;
                    const tmpName = templateName ? templateName + '.' + (elem.Name || '') : (elem.Name || '');
                    if (elem.Workflows) {
                        node.HasChildren = true;
                        node.Children = [];
                        node.IsExpanded = true;
                        elem.Workflows.forEach((wf) => {
                            const wfNode = new WorkflowNode(id.NextID);
                            wfNode.Caption = wf.Caption;
                            wfNode.data = {
                                ID: tmpName + '.' + wf.ID.toString(),
                                IsService: false
                            };
                            if (selection && selection.WorkflowID === wfNode.data.ID) {
                                selection.SelectedNode = wfNode;
                            }
                            node.Children.push(wfNode);
                        });
                    }
                    nodeList.push(node);
                    ExecuteWorkflowSettings.getLayoutNodesRecursive(elem, node.Children, selection, tmpName, id);
                } else {
                    ExecuteWorkflowSettings.getLayoutNodesRecursive(elem, nodeList, selection, templateName, id);
                }
            });
        }
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ExecuteWorkflowSettings.ModuleID;
        reg.Caption = '@@Workflow ausfuehren';
        reg.GroupID = 'wfActions';
        reg.Index = 10;
        reg.SettingsControl = ExecuteWorkflowSettings;
        reg.SettingsTypeHelper = new ExecuteWorkflowDataHelper();
        reg.Executer = ExecuteWorkflowExecuter;
        return reg;
    }

    constructor(private translate: TranslateService, private wfService: WorkflowService, private dialog: MatDialog) {
        super();
    }
   
    initialize(data: any) {
        super.initialize(data);
        let index = 0;
        const varNodes = [];
        this.Variables.forEach(x => {
            const btn = new ABaseTreeNode(index++);
            btn.Caption = x.Name;
            varNodes.push(btn);
        });
        this.VariableNodes = varNodes;
        if (data) {
            this.WaitForFinish = data.WaitForFinish;
            this.ExecuteInService = data.ExecuteInService;
            this.StartNewTrigger = data.StartNewTrigger;
            const id = new IDKeeper();
            if (this.WFEditOptions && this.WFEditOptions.Layout) {
                const clientNodeList = [];
                let selection;
                if (!data.IsServiceWorkflow) {
                    selection = {
                        WorkflowID: data.WorkflowID,
                        SelectedNode: null
                    };
                }
                if (this.WFEditOptions.Layout.Workflows) {
                    this.WFEditOptions.Layout.Workflows.forEach((wf) => {
                        const wfNode = new WorkflowNode(id.NextID);
                        wfNode.Caption = wf.Caption;
                        wfNode.SortCaption = wf.Caption.toLowerCase();
                        wfNode.data = {
                            ID: wf.ID.toString(),
                            IsService: false,
                            CanEdit: true
                        };
                        clientNodeList.push(wfNode);
                        if (selection && wfNode.data.ID === selection.WorkflowID) {
                            selection.SelectedNode = wfNode;
                        }
                    });
                }
                ExecuteWorkflowSettings.getLayoutNodesRecursive(this.WFEditOptions.Layout, clientNodeList, selection, null, id);
                if (clientNodeList.length > 0) {
                    clientNodeList.sort((a, b) => {
                        if (a.SortCaption < b.SortCaption) {
                            return -1;
                        }
                        if (a.SortCaption > b.SortCaption) {
                            return 1;
                        }
                        return 0;
                    });
                    const clientNode = new WorkflowNode(id.NextID);
                    clientNode.TranslateCaption = '@@Client';
                    clientNode.Selectable = false;
                    clientNode.Children = clientNodeList;
                    clientNode.HasChildren = true;
                    clientNode.IsExpanded = true;
                    this.Nodes.push(clientNode);
                    this.FilteredNodes = this.Nodes;
                    if (selection) {
                        this.SelectedNode = selection.SelectedNode;
                    }
                }
            }
            this.wfService.GetAllServiceWorkflows().subscribe(result => {
                if (result.length > 0) {
                    let selNode;
                    const serviceNode = new WorkflowNode(id.NextID);
                    serviceNode.TranslateCaption = '@@Service';
                    serviceNode.Selectable = false;
                    serviceNode.HasChildren = true;
                    serviceNode.Children = [];
                    serviceNode.IsExpanded = true;
                    const nodeList = [];
                    result.forEach((wf) => {
                        const wfNode = new WorkflowNode(id.NextID);
                        wfNode.Caption = wf.Name;
                        wfNode.data = {
                            ID: wf.Id,
                            IsService: true,
                            Parameters: wf.Parameters
                        };
                        nodeList.push(wfNode);
                        if (data.IsServiceWorkflow) {
                            if (wfNode.data.ID === data.WorkflowID) {
                                selNode = wfNode;
                            }
                        }
                    });
                    serviceNode.Children = nodeList;
                    this.Nodes = this.Nodes.concat([serviceNode]);
                    this.FilteredNodes = this.Nodes;
                    if (selNode) {
                        this.SelectedNode = selNode;
                        if (data.ParamMapping) {
                            const newParamSettings = [];
                            data.ParamMapping.forEach((ps) => {
                                newParamSettings.push({
                                    Name: ps.Name,
                                    SetValue: ps.SetValue,
                                    Value: ps.Value
                                });
                            });
                            this.ParamSettings = newParamSettings;
                        }
                        this.onSelect(selNode);
                    }
                }
            });
        }
    }

    resetSearch() {
        this.SearchValue = null;
        this.UpdateFiltered();
    }

    UpdateFiltered() {
        this.FilteredNodes = [];
        if (this.Nodes) {
            if (this.SearchValue) {
                this.FilteredNodes = this.getFilteredList(this.Nodes, this.SearchValue.toLowerCase());
            }
            else {
                this.FilteredNodes = this.Nodes;
            }
        }
    }

    getFilteredList(originalList: any[], toLower: string) {
        const list = [];
        if (originalList) {
            originalList.forEach(x => {
                if (x.HasChildren) {
                    const childList = this.getFilteredList(x.Children, toLower);
                    if (childList.length > 0) {
                        const groupNode = new WorkflowNode(x.NextID);
                        groupNode.TranslateCaption = '@@Client';
                        groupNode.Selectable = false;
                        groupNode.Children = childList;
                        groupNode.HasChildren = true;
                        groupNode.IsExpanded = true;
                        list.push(groupNode);
                    }
                } else if (x.TranslateCaption) {
                    if (this.translate.instant(x.TranslateCaption).toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    }
                } else if (x.Caption) {
                    if (x.Caption.toLowerCase().indexOf(toLower) > -1) {
                        list.push(x);
                    }
                }
            });
        }
        return list;
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.SelectedNode && this.SelectedNode.data) {
            if (this.SelectedNode.data.IsService) {
                this.ParamSettings.some((ps) => {
                    if (ps.Required && ps.Value == null) {
                        retVal.IsCorrect = false;
                        retVal.Error = '@@Bitte setzen Sie alle Pflicht-Parameter';
                        return true;
                    }
                    return false;
                });
            }
        } else {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie einen Workflow';
        }
        return retVal;
    }

    getResult(): any {
        const retVal = new ExecuteWorkflowData();
        if (this.SelectedNode && this.SelectedNode.data) {
            retVal.WaitForFinish = this.WaitForFinish;
            retVal.StartNewTrigger = this.StartNewTrigger;
            retVal.ExecuteInService = this.ExecuteInService;
            retVal.IsServiceWorkflow = this.SelectedNode.data.IsService;
            retVal.WorkflowID = this.SelectedNode.data.ID;
            if (retVal.IsServiceWorkflow) {
                this.ParamSettings.forEach((ps) => {
                    const setting = new WorkflowParamMapping();
                    setting.Name = ps.Name;
                    setting.SetValue = ps.SetValue;
                    setting.Value = ps.Value;
                    retVal.ParamMapping.push(setting);
                });
            }
        }
        return retVal;
    }

    onSelect(event) {
        const newParamSettings = [];
        const oldSettings = this.ParamSettings;
        if (event && event.data) {
            this.ServiceNodeSelected = event.data.IsService;
        }
        if (event && event.data && event.data.IsService && event.data.Parameters) {
            event.data.Parameters.forEach((p) => {
                let oldSetting;
                if (oldSettings) {
                    oldSettings.some((o) => {
                        if (o.Name === p.Name) {
                            oldSetting = o;
                            return true;
                        }
                        return false;
                    });
                }
                if (!oldSetting) {
                    oldSetting = {
                        Name: p.Name,
                        SetValue: false
                    };
                }
                oldSetting.Type = p.Type;
                oldSetting.Required = p.Required;
                newParamSettings.push(oldSetting);
            });
        }
        this.ParamSettings = newParamSettings;
    }

    onVariableSelect(event, p) {
        if (event && p) {
            p.Value = event.Caption;
        }
    }

    JumpToWorkFlow() {
        const sN = this.SelectedNode;
        if (sN && sN.data && sN.data.CanEdit && this.WFEditOptions && this.WFEditOptions.Layout && this.WFEditOptions.Layout.Workflows) {
            const wf = this.WFEditOptions.Layout.Workflows.find(w => w.ID === sN.data.ID);
            if (wf) {
                const dialogRef = this.dialog.open(WorkflowEditDialog, {
                    data: { Data: wf.Data, Layout: this.WFEditOptions.Layout, Workflow: wf },
                    panelClass: 'workflowedit-dialog-container',
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result instanceof WorkflowData) {
                        wf.Data = result;
                        sN.Caption = wf.Caption;
                        sN.SortCaption = wf.Caption.toLowerCase();
                        LayoutService.OnLayoutWorkflowsChanged({
                            LayoutID: this.WFEditOptions.Layout.ID,
                            Workflows: [JSON.stringify(wf)]
                        });
                    }
                    return true;
                });
            }
        }
    }
}

export class WorkflowNode extends ABaseTreeNode {
    data;
    SortCaption;
}

// @dynamic
export class ExecuteWorkflowData extends AServiceWorkflowData {
    IsServiceWorkflow = false;
    WorkflowID: string;
    @Type(() => WorkflowParamMapping)
    ParamMapping: WorkflowParamMapping[] = [];
    WaitForFinish = true;
    StartNewTrigger = false;
    ExecuteInService = false;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ExecuteWorkflow.ExecuteWorkflowData';
    }
}

export class WorkflowParamMapping {
    Name: string;
    SetValue = false;
    Value;
}

export class ExecuteWorkflowDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ExecuteWorkflowData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getStepIntoHandler(settings, workflowID: string, moduleID: number): StepIntoHandler {
        return new StepIntoHandler(workflowID, moduleID);
    }
}

export class ExecuteWorkflowExecuter extends WorkflowModuleExecuter {
    private static FindWorkflow(layout, id, templateName) {
        let retVal = {
            Workflow: null,
            Layout: null,
        };
        if (layout) {
            if (layout.Workflows) {
                layout.Workflows.some((wf) => {
                    const checkName = templateName ? templateName + '.' + wf.ID : wf.ID.toString();
                    if (checkName === id) {
                        retVal.Workflow = wf;
                        retVal.Layout = layout;
                        return true;
                    }
                    return false;
                });
                if (retVal.Workflow) {
                    return retVal;
                }
            }
            if (layout.Elements) {
                layout.Elements.some((elem) => {
                    let tmpName = templateName;
                    if (elem.ElementType === TemplateLayout.Type) {
                        if (tmpName) {
                            tmpName += '.' + (elem.Name || '');
                        } else {
                            tmpName = (elem.Name || '');
                        }
                    }
                    const selWF = ExecuteWorkflowExecuter.FindWorkflow(elem, id, tmpName);
                    if (selWF && selWF.Workflow) {
                        retVal = selWF;
                        return true;
                    }
                    return false;
                });
            }
        }
        return retVal;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        return new Promise((resolve) => {
            if (status.ActualSettings) {
                if (status.ActualSettings.WorkflowID) {
                    if (status.ActualSettings.IsServiceWorkflow) {
                        const wfData = new ExecuteWorkflowData();
                        wfData.WorkflowID = status.ActualSettings.WorkflowID;
                        wfData.IsServiceWorkflow = true;
                        wfData.ExecuteInService = status.ActualSettings.ExecuteInService;
                        if (status.ActualSettings.ParamMapping) {
                            status.ActualSettings.ParamMapping.forEach((pm) => {
                                const newPM = new WorkflowParamMapping();
                                newPM.Name = pm.Name;
                                newPM.SetValue = true;
                                if (pm.SetValue) {
                                    newPM.Value = pm.Value;
                                } else {
                                    newPM.Value = status.Context.get(pm.Value);
                                }
                                wfData.ParamMapping.push(newPM);
                            });
                        }
                        const wfService = InjectorHelper.InjectorInstance.get<WorkflowService>(WorkflowService);
                        const context = {
                            WorkflowData: wfData,
                            SendResult: false,
                            DebugInfo: null,
                            EnableLogging: false
                        };
                        const debugClient = WorkflowDebugHelper.DebugClient.getValue();
                        if (debugClient) {
                            const settings = WorkflowDebugHelper.DebugSettings.getValue();
                            if (status.DebugStateHandler && status.DebugStateHandler.LastNextStep === NextStep.Into) {
                                context.DebugInfo = {
                                    RunID: status.DebugStateHandler.RunID,
                                    DebugClient: debugClient,
                                    ExecutingClient: ClientHelper.ClientID
                                };
                                if (settings) {
                                    if (settings.Workflows) {
                                        if (settings.Workflows.ServiceWorkflows) {
                                            context.DebugInfo.ServiceWorkflows = [...settings.Workflows.ServiceWorkflows];
                                        }
                                        if (settings.Workflows.ConnectorWorkflows) {
                                            context.DebugInfo.ConnectorWorkflows = [...settings.Workflows.ConnectorWorkflows];
                                        }
                                    }
                                    context.DebugInfo.WaitingTime = settings.WaitTimeout;
                                }
                            } else if (settings && settings.ShowServiceLogsOnTest) {
                                context.EnableLogging = true;
                            }
                        }
                        if (status.ActualSettings.WaitForFinish || context.DebugInfo) {
                            WebsocketService.WaitForWebSocketService().then(() => {
                                const channel = UUID.UUID();
                                const subScription = WebsocketService.MessageReceived.subscribe((response) => {
                                    const parsed = JSON.parse(response);
                                    if (parsed.Level === NotificationLevel.System &&
                                        parsed.Channels && parsed.Channels.some(x => x === channel) &&
                                        parsed.Type === 'evidanza.App.Shared.Workflow.Rest.WorkflowExecutionInfo') {
                                        subScription.unsubscribe();
                                        WebsocketService.Unsubscribe([{ Channel: channel, Level: [0] }]);
                                        const wfInfo = JSON.parse(parsed.Content);
                                        if (wfInfo.Successfull) {
                                            status.ActualParameter = wfInfo.Data;
                                            if (context.DebugInfo && typeof wfInfo.LastNextStep === 'number') {
                                                status.DebugStateHandler.UpdateState(wfInfo.LastNextStep);
                                            }
                                            resolve(0);
                                        } else {
                                            status.ActualParameter = wfInfo.Errors;
                                            status.Logger.logWarning('ExecuteWF modul: Error(s) while executing service workflow with id ' + wfData.WorkflowID + ':\n' + wfInfo.Errors.join('\n'));
                                            resolve(-1);
                                        }
                                    }
                                });
                                WebsocketService.Subscribe([{ Channel: channel, Level: [0] }]).then(() => {
                                    context.SendResult = true;
                                    wfService.ExecuteAndWaitForWorkflow(context, channel).subscribe();
                                });
                            });
                        } else {
                            wfService.ExecuteWorkflowExecutionContext(context).subscribe();
                            resolve(0);
                        }                        
                    } else {
                        const layout = status.WorkflowLayoutService.Layout;
                        if (layout) {
                            const selWF = ExecuteWorkflowExecuter.FindWorkflow(layout, status.ActualSettings.WorkflowID, null);
                            if (selWF && selWF.Workflow) {
                                const newStatus = new WorkflowStatus(selWF.Workflow.ID, selWF.Workflow.Caption, status);
                                newStatus.ActualParameter = status.ActualParameter;
                                WorkflowStatus.CopyContext(status.Context, newStatus.Context);
                                if (layout === selWF.Layout) {
                                    newStatus.WorkflowLayoutService = status.WorkflowLayoutService;
                                } else {
                                    newStatus.WorkflowLayoutService.init(selWF.Layout, null);
                                }
                                const engine = new WorkflowEngine(selWF.Workflow.Data, newStatus);
                                if (status.ActualSettings.WaitForFinish) {
                                    engine.startExecution().then(() => {
                                        status.ActualParameter = newStatus.ActualParameter;
                                        resolve(0);
                                    });
                                } else {
                                    engine.startExecution();
                                    resolve(0);
                                }
                            } else {
                                status.Logger.logError('ExecuteWF modul: Workflow with id ' + status.ActualSettings.WorkflowID + ' not found.');
                                resolve(-1);
                            }
                        } else {
                            status.Logger.logError('ExecuteWF modul: No layout found.');
                            resolve(-1);
                        }
                    }
                    return;
                } else {
                    status.Logger.logError('ExecuteWF modul: No workflow id found.');
                    resolve(-1);
                }
            } else {
                status.Logger.logError('ExecuteWF modul: No settings found.');
                resolve(-1);
            }
        })
    }
}
