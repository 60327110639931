import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class DynamicDataEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/dynamicdata/';
    EndPoints = [
        //#region SearchObjects
        {
            Name: 'SearchObjects',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return OfflineHelper.Query(params['classid'], body);
            }
        },
        //#endregion
        //#region Save
        {
            Name: 'Save',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    if (body) {
                        let status = 3;
                        const user = JSON.parse(localStorage.getItem('user'));
                        if (!body._Id) {
                            status = 1;
                        }
                        body._Status = status;
                        body._changed = new Date().toJSON();
                        body._changedby = user.DisplayName;
                        if (status === 1) {
                            body._Id = UUID.UUID();
                            body._Version = UUID.UUID();
                            body._created = new Date().toJSON();
                            body._createdby = user.DisplayName;
                        }
                        OfflineHelper.GetDB()[params['classid']].put(body);
                        OfflineHelper.UpdateChanges(params['classid'], body);
                        observer.next([body._Id, body._Version]);
                    }
                });
            }
        },
        //#endregion
        //#region SaveEntry
        {
            Name: 'SaveEntry',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    if (body) {
                        const user = JSON.parse(localStorage.getItem('user'));
                        if (!body._Id) {
                            body._Id = UUID.UUID();
                            body._Version = UUID.UUID();
                            body._created = new Date().toJSON();
                            body._createdby = user.DisplayName;
                            body._Status = 1;
                        } else {
                            body._Status = 3;
                        }
                        body._changed = new Date().toJSON();
                        body._changedby = user.DisplayName;
                        OfflineHelper.GetDB()[params['classid']].put(body);
                        OfflineHelper.UpdateChanges(params['classid'], body);
                        observer.next([body._Id, body._Version]);
                    }
                });
            }
        },
        //#endregion
        //#region Delete
        {
            Name: 'Delete',
            Method: MethodType.Delete,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()[params['classid']].filter((value) => {
                        return value._Id == params['id'] && value._Version == params['version'];
                    }).toArray().then((result) => {
                        if (result && result.length === 1) {
                            const user = JSON.parse(localStorage.getItem('user'));
                            const item = result[0];
                            item._changed = new Date().toJSON();
                            item._changedby = user.DisplayName;
                            item._Status = 2;
                            OfflineHelper.GetDB()[params['classid']].put(item);
                            OfflineHelper.UpdateChanges(params['classid'], body);
                            observer.next(true);
                        }
                    });
                });
            }
        },
        //#endregion
        //#region DeleteEntry
        {
            Name: 'DeleteEntry',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    if (body) {
                        OfflineHelper.GetDB()[params['classid']].filter((value) => {
                            return value._Id == body._Id && value._Version == body._Version;
                        }).toArray().then((result) => {
                            if (result && result.length === 1) {
                                const user = JSON.parse(localStorage.getItem('user'));
                                const item = result[0];
                                item._changed = new Date().toJSON();
                                item._changedby = user.DisplayName;
                                item._Status = 2;
                                OfflineHelper.GetDB()[params['classid']].put(item);
                                OfflineHelper.UpdateChanges(params['classid'], body);
                                observer.next(true);
                            }
                        });
                    }
                });
            }
        },
        //#endregion
        //#region CountObjects
        {
            Name: 'CountObjects',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.Query(params['classid'], body).subscribe((result) => {
                        if (result) {
                            observer.next(result.length);
                        } else {
                            observer.next(0);
                        }
                    });
                });
            }
        },
        //#endregion
        //#region ExecuteCount
        {
            Name: 'ExecuteCount',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.Query(params['containerid'], body).subscribe((result) => {
                        if (result) {
                            observer.next(result.length);
                        } else {
                            observer.next(0);
                        }
                    });
                });
            }
        },
        //#endregion
        //#region ExecuteCountQuery
        {
            Name: 'ExecuteCountQuery',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.Query(params['queryid'], body).subscribe((result) => {
                        if (result) {
                            observer.next(result.length);
                        } else {
                            observer.next(0);
                        }
                    });
                });
            }
        },
        //#endregion
        //#region OpenQuery
        {
            Name: 'OpenQuery',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {

                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.SID == params['containerid']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let table = values[0];
                            OfflineHelper.GenerateFlatTableData(table, body).then((result) => {
                                if (result) {
                                    let ID = UUID.UUID();
                                    OfflineHelper.Cache[ID] = result;
                                    observer.next(ID);
                                } else {
                                    observer.next(null);
                                }
                            });
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        //#endregion
        //#region CloseQuery
        {
            Name: 'CloseQuery',
            Method: MethodType.Delete,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    delete OfflineHelper.Cache[params['queryid']];
                    observer.next(true);
                });
            }
        },
        //#endregion
        //#region ExecuteQuery
        {
            Name: 'ExecuteQuery',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    if (OfflineHelper.Cache[params['queryid']]) {
                        let start = params['startRow'];
                        let end = params['endRow'] - start;
                        if (end + start > OfflineHelper.Cache[params['queryid']]) {
                            end = OfflineHelper.Cache[params['queryid']].length - start;
                        }
                        observer.next(OfflineHelper.Cache[params['queryid']].slice(start, end));
                    }
                });
            }
        },
        //#endregion
        //#region ExecuteQuery
        {
            Name: 'ExecuteQuery',
            Method: MethodType.Post,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.Dynamic.ContainerClassObject'].filter((value) => {
                        return value.SID == params['containerid']
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            let table = values[0];
                            OfflineHelper.GenerateFlatTableData(table, body).then((retVal) => {
                                observer.next(retVal);
                            });
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
        
    ]
}