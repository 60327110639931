import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { RestService } from '../../../../../services/rest.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../../../base.list.settings';
import { MappingRestDetailComponent } from './mapping.rest.detail';

@Component({
    selector: 'mapping-rest',
    templateUrl: '../../../../base.list.settings.html'
})
export class MappingRestComponent extends BaseListSettings{

    constructor(private _service: RestService, private _translate: TranslateService, factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this._service.loadAllRestMappingClass(this.InitArgs.SID).subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r => list.push({
                    Caption: r.Caption,
                    ID: r.Value
                }));
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.loadRestMappingClassById(data).subscribe(r => {
            if (r) {
                this.setSelectedItem(r);
            }
        });
    }

    getNewItem() {
        const item = {
            Name: this._translate.instant('Neues mapping'),
            RestID: this.InitArgs.SID,
            AttributeClassID: '00000000-0000-0000-0000-000000000000',
            AttributeMappingClassID: '00000000-0000-0000-0000-000000000000',
            Fields: []
        };
        return item;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Mapping \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Mapping \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Mapping loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._service.removeRestMappingClass(data).subscribe(() => handler());
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Mapping \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Mapping speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        this._service.saveRestMappingClass(item).subscribe(r => {
            if (r)
                handler(r, r._Id, r.Name);
        });
    }

    getContentType() {
        return MappingRestDetailComponent;
    }
}