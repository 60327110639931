import { AFormula, FormulaGroups } from './iformula';
import { ValueAndType } from '../basic/formulaEditor.model';
import { TranslateHelper } from '../../helpers/injector.helper';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { abs } from 'mathjs';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';

export class AbsFormula extends AFormula {
    static StaticID = '1aa5d721-1d13-46ae-b254-b11276320f61';

    ID(): string {
        return AbsFormula.StaticID;
    }

    Name(): string {
        return 'Abs';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Angabe des Betrags des jeweiligen Werts');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 10;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Argument') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        if (args && args.length > 0) {
            const val = args[0];
            if (val && (val.Type === ValueType.Long || val.Type === ValueType.Double)) {
                const absVal = abs(val.Value);
                if (!isNaN(absVal)) {
                    retVal.Type = val.Type;
                    retVal.Value = absVal;
                }
            }
        }
        return retVal;
    }
}
