// workflow-communication.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowCommunicationService {
  // private selectedWorkflowItemSubject = new BehaviorSubject<any>(null);
  private workflowStack = [];
  private selectedWorkflow=null;
  private dialogStatus = new BehaviorSubject<string>(null);
  // setSelectedWorkflowItem(item: any): void {
  //   this.selectedWorkflowItemSubject.next(item);
  // }

  setDialogStatus(status: string): void {
    this.dialogStatus.next(status);
  }
  getDialogStatus(): Observable<any> {
    return this.dialogStatus.asObservable();
  }

  setSelectedWorkflow(workflow) {
    return this.selectedWorkflow = workflow;
  }

  getSelectedWorkflow() {
    return this.selectedWorkflow
  }
  // getSelectedWorkflowItem(): Observable<any> {
  //   return this.selectedWorkflowItemSubject.asObservable();
  // }
  getWorkflowStack() {
    return this.workflowStack;
  }
  setWorkflowStack(workflowData) {
    return this.workflowStack = workflowData;
  }
}
