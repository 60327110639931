import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Color } from '../../../models/style/color.model';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'lib-color-picker',
    templateUrl: './colorpicker.control.html',
    styleUrls: ['./colorpicker.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, AfterViewInit {

    //@ViewChild('gradientColorPickerElement') gradientColorPicker;

    CustomCSSVariables = [];
    SelectedCSSVariable;
    SelectedColorHex = '';
    SelectedColorHexAlpha = '';
    SelectedColorValue = new Color();
    Gradient = ''
    Style = {};
    @Input()
    get SelectedColor() {
        return this.SelectedColorValue;
    }
    set SelectedColor(val) {
        if (val) {
            this.SelectedCSSVariable = val.ColorReference;
            const hex = Color.HexFromColor(val)
            this.SelectedColorHexAlpha = hex;
            this.setGradientValue(hex)
            this.SelectedColorHex = Color.HexFromColor(val, true);
            this.SelectedColorValue = val;
        } else {
            this.SelectedCSSVariable = null;
            this.SelectedColorHexAlpha = '';
            this.SelectedColorHex = '';
            this.SelectedColorValue = new Color();
        }
        this.updateStyle(true);
        this.cdRef.detectChanges();
    }
    @Input()
    get InitialGradient() {
        return this.Gradient
    }
    set InitialGradient(value: string) {
        this.Gradient = value;
    }
    constructor(private cdRef: ChangeDetectorRef) {}

    UseVariablesValue = true;
    @Input()
    get UseVariables() {
        return this.UseVariablesValue;
    }
    set UseVariables(val) {
        this.UseVariablesValue = val;
    }

    UseSystemVariablesValue = true;
    @Input()
    get UseSystemVariables() {
        return this.UseSystemVariablesValue;
    }
    set UseSystemVariables(val) {
        this.UseSystemVariablesValue = val;
    }
    @Output() ColorChanged = new EventEmitter<Color>();
    onColorChanged() {
        this.ColorChanged.emit(this.SelectedColorValue);
        this.GradientColor.emit(this.Gradient)
    }
    @Input() InputGradient;
    @Output() GradientColor = new EventEmitter<string>();
    gradientChange(ev) {
        this.Gradient = ev
        this.GradientColor.emit(this.Gradient)
        this.onColorChanged()
    }
    InternalUpdate = false;
    
    setGradientValue(hex) {
        if (hex) {    
            this.Gradient = `linear-gradient(180deg, ${hex},${this.createTint(hex,0.7)})`
        }
    }
    createTint(hexColor, tintAmount) {
        // Remove the "#" symbol if it exists
        hexColor = hexColor?.replace("#", "");

        // Parse the hex color into its red, green, and blue components
        const r = parseInt(hexColor.slice(0, 2), 16);
        const g = parseInt(hexColor.slice(2, 4), 16);
        const b = parseInt(hexColor.slice(4, 6), 16);
        
        // Calculate the tinted color by adding white
        const newR = r + (255 - r) * tintAmount;
        const newG = g + (255 - g) * tintAmount;
        const newB = b + (255 - b) * tintAmount;

        // Convert the tinted RGB values back to hexadecimal
        const tintedHexColor = `#${Math.round(newR).toString(16)}${Math.round(newG).toString(16)}${Math.round(newB).toString(16)}`;
        return tintedHexColor;
    }
    ngOnInit(): void {
        if (this.UseVariablesValue) {
            if (this.UseSystemVariablesValue) {
                this.CustomCSSVariables = [...LayoutService.SystemCSSVariables, ...LayoutService.CustomCSSVariables];
            } else {
                this.CustomCSSVariables = [...LayoutService.CustomCSSVariables];
            }
        }
    }

    ngAfterViewInit(): void {
        //if (this.gradientColorPicker) {
        //    this.InternalUpdate = true;
        //    const hex = Color.HexFromColor(this.SelectedColorValue, true);
        //    if (hex) {
        //        this.gradientColorPicker.updateTmpSelectedColor(hex);
        //    } else {
        //        this.gradientColorPicker.updateTmpSelectedColor('#000000');
        //    }
        //    this.InternalUpdate = false;
        //}
    }

    pickerChanged(ev) {
        if (!this.InternalUpdate) {
            const color = Color.FromHex(ev.value);
            if (color.R !== this.SelectedColorValue.R || color.G !== this.SelectedColorValue.G || color.B !== this.SelectedColorValue.B) {
                color.A = this.SelectedColorValue.A;
                this.SelectedColor = color;
                this.onColorChanged();
            }
        }
    }

    hexChanged(ev) {
        if (ev && ev.target) {
            const hex = ev.target.value;
            this.SelectedColor = Color.FromHex(hex);
            this.setGradientValue(hex);
            this.onColorChanged();
        }
    }

    rgbaChanged() {
        this.onRGBAChange(true);
    }

    aChanged() {
        this.onRGBAChange(false);
    }

    onRGBAChange(updatePicker: boolean) {
        this.SelectedColorValue.ColorReference = null;
        this.SelectedCSSVariable = null;
        this.SelectedColorHex = Color.HexFromColor(this.SelectedColorValue, true);
        this.SelectedColorHexAlpha = Color.HexFromColor(this.SelectedColorValue);
        this.onColorChanged();
        this.updateStyle(updatePicker);
    }

    OnItemChanged() {
        this.SelectedColor = Color.FromReference(this.SelectedCSSVariable);
        this.onColorChanged();
    }

    updateStyle(updatePicker: boolean) {
        const result = {};
        result['background-color'] = Color.GetStyleText(this.SelectedColorValue);
        if (this.SelectedColorValue.R == null && this.SelectedColorValue.G == null && this.SelectedColorValue.B == null) {
            result['color'] = 'black';
        } else {
            const o =
                Math.round((this.SelectedColorValue.R * 299 + this.SelectedColorValue.G * 587 + this.SelectedColorValue.B * 114) / 1000);
            result['color'] = o > 125 ? 'black' : 'white';
        }
        this.Style = result;
        this.cdRef.detectChanges();
        //if (updatePicker) {
        //    const hex = Color.HexFromColor(this.SelectedColorValue, true);
        //    if (hex) {
        //        this.InternalUpdate = true;
        //        this.gradientColorPicker.updateTmpSelectedColor(hex);
        //        this.InternalUpdate = false;
        //    }
        //}
    }
}
