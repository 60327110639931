import { Component } from '@angular/core';
import moment from 'moment';
import { EnumHelper } from '../../helpers/enum.helper';
import { LogLevel } from '../../models/enums/loglevel.enum';

@Component({
    selector: 'app-logsdialogsystem',
    templateUrl: './logsdialogsystem.component.html',
    styleUrls: ['./logsdialogsystem.component.css']
})

export class LogsdialogsystemComponent {

    Message;
    Date;
    LogLevels;
    LogLevel;

    constructor() {

    }

    Initialize(event) {
        if (event) {
            this.Message = event;
            this.Date = moment(event.data.MessageTime).startOf('day').format('MM/DD/YYYY').toString();
            this.LogLevels = EnumHelper.GetDropdownValues(LogLevel);
            this.LogLevel = this.LogLevels[event.data.MessageLevel].value;
            
        }
    }
}