import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { ABaseTreeNode } from '../../common/basetreecontrol/base.tree.control';
import { BaseDialog} from '../basedialog/base.dialog';

@Component({
    selector: 'event-tree-dialog',
    templateUrl: 'event.tree.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTreeDialog {
    RootNodes = [];

    static ShowDialog(layout) {
        BaseDialog.ShowDialog({
            ContentType: EventTreeDialog,
            InitArgs: { Layout: layout },
            Title: '@@Event-Uebersicht',
            Buttons: DialogButton.Ok
        });
    }

    private static Sort(a, b) {
        const captionA = a.Caption.toLowerCase();
        const captionB = b.Caption.toLowerCase();
        if (captionA < captionB) {
            return -1;
        }
        if (captionA > captionB) {
            return 1;
        }
        return 0;
    }

    private static GetEvents(elem, data) {
        if (elem.Events) {
            elem.Events.forEach(x => {
                if (x.Handlers && x.Handlers.length > 0) {
                    let list = data[x.EventID];
                    if (!list) {
                        list = [];
                        data[x.EventID] = list;
                    }
                    list.push(elem.Name);
                }
            });
        }
        if (elem.Elements) {
            elem.Elements.forEach(x => {
                EventTreeDialog.GetEvents(x, data);
            });
        }
    }

    Initialize(args) {
        if (args && args.Layout) {
            const data = {};
            EventTreeDialog.GetEvents(args.Layout, data);
            const keys = Object.keys(data);
            const rootNodes = [];
            let id = 0;
            keys.forEach(k => {
                const node = new ABaseTreeNode(id++);
                node.Caption = k;
                node.HasChildren = true;
                node.Children = [];
                node.IsExpanded = true;
                data[k].forEach(ev => {
                    const child = new ABaseTreeNode(id++);
                    child.Caption = ev;
                    node.Children.push(child);
                });
                node.Children.sort(EventTreeDialog.Sort);
                rootNodes.push(node);
            });
            rootNodes.sort(EventTreeDialog.Sort);
            this.RootNodes = rootNodes;
        }
    }
}
