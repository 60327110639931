import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { UserSettingsService } from '../../../../services/usersettings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';

@Component({
    selector: 'wf-updateprofile-settings',
    templateUrl: './update.profile.settings.html'
})
export class UpdateProfileSettings extends FormulaWorkflowDialogContent {
    Data = new UpdateProfileSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'updateProfileModule';
        reg.Caption = '@@Update profile';
        reg.GroupID = 'profileGroup';
        reg.Index = 20;
        reg.SettingsControl = UpdateProfileSettings;
        reg.SettingsTypeHelper = new UpdateProfileSettingsDataHelper();
        reg.Executer = UpdateProfileModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(UpdateProfileSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data && !this.Data.ProfileName) {
            retVal.IsCorrect = false;
            retVal.Error = '@@SetProfileNameError';
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }
}

export class UpdateProfileSettingsData {
    ProfileName: string;
}

export class UpdateProfileSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new UpdateProfileSettingsData();
    }
}

export class UpdateProfileModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const layout = status.WorkflowLayoutService.Layout;
        if (layout) {
            const profileVariables = layout['ProfileVariables'];
            if (profileVariables && profileVariables.length > 0) {
                if (status.ActualSettings) {
                    if (status.ActualSettings.ProfileName) {
                        const calculator = new WorkflowFormulaCalculator(status);
                        const value = calculator.CalcFormula(status.ActualSettings.ProfileName);
                        const service = InjectorHelper.InjectorInstance.get<UserSettingsService>(UserSettingsService);
                        service.SaveValues(profileVariables, layout._Id, '' + value);
                        return 0;
                    } else {
                        status.Logger.logError('Update profile modul: No profilename set.');
                    }
                } else {
                    status.Logger.logError('Update profile modul: No settings found.');
                }
            } else {
                status.Logger.logWarning('Update profile modul: No profile variables found.');
            }
        } else {
            status.Logger.logError('Update profile modul: No layout found.');
        }
        return super.execute(status);
    }
}
