import { DataDescription, HeterogenArea } from '../../models/datadescription/multi/datadescription.model';
import { LevelNode } from '../../models/datadescription/multi/levelnode.model';
import { Axis, AxisNode, DummyInfo, MultiNode, MultiResult } from '../../models/datadescription/multi/multiresult.model';
import { QueryDef } from '../../models/datadescription/multi/querydef.model';
import { AxisType, MemberType } from '../../models/enums/query.enum';
import { DataDescriptionHelper } from './ddhelper.query';
import { QueryHelper } from './queryhelper.query';

export class Merger {
    static Merge(dd: DataDescription, results: QueryDef[]): MultiResult {
        let retVal: MultiResult;

        if (results.length > 0) {
            if (results.length === 1) {
                retVal = results[0].MultiResult;
            } else {
                const resultmerge1: QueryDef[] = [];
                const measuremergelist = Merger.GetMeasureMergeList(results);
                for (let i = 0; i < measuremergelist.length; i++) {
                    const item = measuremergelist[i];
                    if (item.length > 1) {
                        resultmerge1.push(Merger.Merge4D(item, dd));
                    } else {
                        resultmerge1.push(item[0]);
                    }
                }

                if (resultmerge1.length > 0) {
                    if (resultmerge1.length === 1) {
                        retVal = resultmerge1[0].MultiResult;
                    } else {
                        // MergePositions f�r zweiten Merge clearen und neu setzen lassen
                        resultmerge1.forEach(rm => {
                            rm.MultiResult.Measures.Nodes.forEach(m => Merger.ResetMergePositions(m));
                            rm.MultiResult.XAxis.Nodes.forEach(m => Merger.ResetMergePositions(m));
                            rm.MultiResult.YAxis.Nodes.forEach(m => Merger.ResetMergePositions(m));
                        });

                        retVal = Merger.Merge3D(resultmerge1, dd);
                    }
                }
            }
        }

        if (retVal) {
            if (!QueryHelper.IsEmptyMultiResult(retVal)) {

                if (retVal.XAxis.Nodes.length === 0) {
                    let areaID = 0;
                    if (dd.XLevelNodes && dd.XLevelNodes.Areas && dd.XLevelNodes.Areas.length > 0) {
                        areaID = dd.XLevelNodes.Areas[0].UniqueID;
                    }
                    Merger.AddDummyNode(retVal.XAxis, [0], 0, areaID);
                }
                Merger.SetLastChild(retVal.XAxis.Nodes);
                if (retVal.YAxis.Nodes.length === 0) {
                    let areaID = 0;
                    if (dd.YLevelNodes && dd.YLevelNodes.Areas && dd.YLevelNodes.Areas.length > 0) {
                        areaID = dd.XLevelNodes.Areas[0].UniqueID;
                    }
                    Merger.AddDummyNode(retVal.YAxis, [0], 0, areaID);
                }
                Merger.SetLastChild(retVal.YAxis.Nodes);
                const measureaxis = DataDescriptionHelper.GetMeasureAxis(dd);
                let index = 0;
                measureaxis.Areas.forEach(a => {
                    a.Measures.forEach(mi => {
                        if (!retVal.Measures.Nodes.some(x => x.UniqueID === mi.UniqueID)) {
                            let an = new AxisNode({
                                UniqueID: mi.UniqueID,
                                Position: retVal.Measures.ElementCount++,
                                Axis: AxisType.Measure,
                                Depth: 0,
                                Caption: mi.ToStringWithNewLine(),
                                Key: mi.Key,
                                Measure: true,
                                Type: MemberType.Measure
                            });
                            retVal.Measures.Nodes.splice(index, 0, an);
                        }
                        index++;
                    });
                });
            }
        }
        return retVal;
    }

    private static ResetMergePositions(node: AxisNode) {
        if (node) {
            if (node.MergePositions) {
                node.MergePositions.clear();
            }
            if (node.Children) {
                node.Children.forEach(child => Merger.ResetMergePositions(child));
            }
        }
    }

    private static SetLastChild(nodes: AxisNode[]) {
        if (nodes && nodes.length > 0) {
            nodes.forEach(n => {
                n.IsLastChild = false;
                Merger.SetLastChild(n.Children);
            });
            nodes[nodes.length - 1].IsLastChild = true;
        }
    }

    private static Merge3D(results: QueryDef[], dd: DataDescription): MultiResult {

        let multiResult = new MultiResult();

        for (var i = 0; i < results.length; i++) {
            results[i].Index = i;
        }

        //X_Axis
        multiResult.XAxis = Merger.MergeAxis3D(results, AxisType.X_Axis, dd.XLevelNodes, dd.PowerGrid);
        //Y_Axis
        multiResult.YAxis = Merger.MergeAxis3D(results, AxisType.Y_Axis, dd.YLevelNodes, dd.PowerGrid);

        //Measure_Axis
        multiResult.Measures = Merger.MergeMeasure(results);

        let xnodes = Merger.GetAllNodes(multiResult.XAxis.Nodes, results.length);
        let ynodes = Merger.GetAllNodes(multiResult.YAxis.Nodes, results.length);
        let mnodes = Merger.GetAllNodes(multiResult.Measures.Nodes, results.length);

        xnodes.forEach(xnode => {
            ynodes.forEach(ynode => {
                mnodes.forEach(mnode => {
                    Merger.SetValues(multiResult, results, xnode, ynode, mnode);
                });
            });
        });

        return multiResult;
    }

    private static Merge4D(results: QueryDef[], dd: DataDescription): QueryDef {
        let res = new QueryDef();
        res.MultiResult = new MultiResult();

        for (var i = 0; i < results.length; i++) {
            results[i].Index = i;
        }

        //X_Axis
        res.MultiResult.XAxis = Merger.MergeAxis4D(results, AxisType.X_Axis, dd.XLevelNodes.Areas.find(x => x.UniqueID == results[0].HeterogenPosition.get(AxisType.X_Axis)), dd.PowerGrid, { Position: 0, UseOldPosition: false });

        //Y_Axis
        res.MultiResult.YAxis = Merger.MergeAxis4D(results, AxisType.Y_Axis, dd.YLevelNodes.Areas.find(x => x.UniqueID == results[0].HeterogenPosition.get(AxisType.Y_Axis)), dd.PowerGrid, { Position: 0, UseOldPosition: false });

        //Measure_Axis
        res.MultiResult.Measures = Merger.MergeMeasure(results);

        let xnodes = Merger.GetAllNodes(res.MultiResult.XAxis.Nodes, results.length);
        let ynodes = Merger.GetAllNodes(res.MultiResult.YAxis.Nodes, results.length);
        let mnodes = Merger.GetAllNodes(res.MultiResult.Measures.Nodes, results.length);

        xnodes.forEach(xnode => {
            ynodes.forEach(ynode => {
                mnodes.forEach(mnode => {
                    Merger.SetValues(res.MultiResult, results, xnode, ynode, mnode);
                });
            });
        });

        res.HeterogenPosition = results[0].HeterogenPosition;
        res.MeasureOnAxis = results[0].MeasureOnAxis;
        return res;
    }
    private static SetValues(multiresult: MultiResult, results: QueryDef[], xnode: AxisNode, ynode: AxisNode, mnode: AxisNode) {
        ynode.MergePositions.forEach((yValue, yKey) => {
            if (xnode.MergePositions.has(yKey) && mnode.MergePositions.has(yKey)) {
                const xValue = xnode.MergePositions.get(yKey);
                const mValue = mnode.MergePositions.get(yKey);
                const mn: MultiNode = QueryHelper.GetNodeFromMultiResult(results[yKey].MultiResult, xValue, yValue, mValue);
                if (mn) {
                    QueryHelper.SetNodeInMultiResult(multiresult, xnode.Position, ynode.Position, mnode.Position, mn);
                }
            }
        });
    }

    private static GetAllNodes(nodes: AxisNode[], results: number) {
        let retVal: AxisNode[] = [];
        if (nodes && nodes.length > 0) {
            nodes.forEach(n => {
                retVal.push(n);
                retVal.push(...Merger.GetAllNodes(n.Children, null));
            });
        } else if (results) {
            let an = new AxisNode();
            an.Position = 0;
            for (var i = 0; i < results; i++) {
                an.MergePositions.set(i, 0);
            }
            retVal.push(an);
        }
        return retVal;
    }

    private static MergeMeasure(results: QueryDef[]): Axis {
        const measureaxis = new Axis({
            Type: AxisType.Measure
        });
        let poscounter = 0;
        for (var i = 0; i < results.length; i++) {
            results[i].MultiResult.Measures.Nodes.forEach(m => {
                if (measureaxis.Nodes.length == 0) {
                    measureaxis.Nodes.push(Merger.CloneNode(m, null, i, 0, poscounter++));
                } else {
                    let searchednode = measureaxis.Nodes.find(x => x.UniqueID == m.UniqueID);
                    if (searchednode) {
                        if (!searchednode.MergePositions) {
                            searchednode.MergePositions = new Map<number, number>();
                        }
                        searchednode.MergePositions.set(i, m.Position);
                    } else {
                        measureaxis.Nodes.push(Merger.CloneNode(m, null, i, 0, poscounter++));
                    }
                }
            });
        }
        measureaxis.ElementCount = poscounter;
        return measureaxis;
    }

    private static GetPositions(defs: QueryDef[], axistype: AxisType): Map<number, QueryDef[]> {
        const positions = new Map<number, QueryDef[]>();
        defs.forEach(d => {
            const pos = d.HeterogenPosition.get(axistype);
            let resList = positions.get(pos);
            if (resList) {
                resList.push(d);
            } else {
                positions.set(pos, [d]);
            }
        });
        return positions;
    }

    private static MergeAxis3D(results: QueryDef[], axistype: AxisType, levelnodes: import('../../models/datadescription/multi/datadescription.model').Axis, powergrid: boolean): Axis {
        const retVal = new Axis();
        retVal.Type = axistype;
        const pcCount = {
            Position: 0,
            UseOldPosition: false
        }
        Merger.GetPositions(results, axistype).forEach((list, pos) => {
            const result = Merger.MergeAxis4D(list, axistype, levelnodes.Areas.find(x => x.UniqueID == pos), powergrid, pcCount);
            Merger.AddChildNodes(retVal, result.Nodes);
        });
        retVal.ElementCount = pcCount.Position;
        return retVal;
    }

    private static AddChildNodes(retVal: Axis, nodes: AxisNode[]) {
        if (retVal.Nodes.length == 0) {
            retVal.Nodes.push(...nodes);
        } else {
            nodes.forEach(an => {
                if (an.UniqueID === -1) {
                    retVal.Nodes.push(an);
                } else {
                    let searchednode = retVal.Nodes.find(x => x.MemberId == an.MemberId && x.UniqueID == an.UniqueID);
                    if (searchednode) {
                        Merger.AddChildNodesInternal(searchednode, an.Children);
                    } else {
                        retVal.Nodes.push(an);
                    }
                }
            });
        }
    }
    private static AddChildNodesInternal(node: AxisNode, children: AxisNode[]) {
        children.forEach(child => {
            let searchednode = node.ChildrenDict.get(AxisNode.GetSearchChildrenKey(child));
            if (searchednode) {
                Merger.AddChildNodesInternal(searchednode, child.Children);
            } else {
                node.addChild(child);
            }
        });

        node.Children.forEach(an => {
            an.Parent = node;
        });
    }

    private static MergeAxis4D(results: QueryDef[], axistype: AxisType, area: HeterogenArea, powergrid: boolean, poscount: { Position: number, UseOldPosition: boolean }): Axis {
        let retVal = new Axis();
        if (area) {
            const levels = DataDescriptionHelper.GetLevels(area);
            if (levels.length == 0) {
                const mergePosList = [];
                results.forEach(x => {
                    mergePosList.push(x.Index);
                });
                Merger.AddDummyNode(retVal, mergePosList, poscount.Position++, area.UniqueID);
            } else {
                let left = axistype == AxisType.X_Axis ? results[0].MultiResult.XAxis : results[0].MultiResult.YAxis;
                if (results.length > 1) {
                    for (var i = 1; i < results.length; i++) {
                        let right = axistype == AxisType.X_Axis ? results[i].MultiResult.XAxis : results[i].MultiResult.YAxis;
                        let leftLevelOrder: number[] = [];
                        Merger.GetLevelOrder(left.Nodes, leftLevelOrder);
                        let rightLevelOrder: number[] = [];
                        Merger.GetLevelOrder(right.Nodes, rightLevelOrder);
                        left = Merger.MergeNodes(left, leftLevelOrder, right, rightLevelOrder, levels, results[0].Index, results[i].Index, poscount, powergrid, i == 1);
                    }
                } else {
                    let leftLevelOrder: number[] = [];
                    Merger.GetLevelOrder(left.Nodes, leftLevelOrder);
                    left = Merger.MergeNodes(left, leftLevelOrder, null, null, levels, results[0].Index, 0, poscount, powergrid, true);
                }
                retVal = left;
            }
        }
        retVal.Type = axistype;
        retVal.ElementCount = poscount.Position;
        return retVal;
    }
    private static GetLevelOrder(nodes: AxisNode[], levelorder: number[]) {
        if (nodes.length > 0) {
            levelorder.push(nodes[0].UniqueID);
            Merger.GetLevelOrder(nodes[0].Children, levelorder);
        }
    }

    private static MergeNodes(left: Axis, leftlevelorder: number[], right: Axis, rightlevelorder: number[], levels: LevelNode[], leftpos: number, rightpos: number, poscount: { Position: number, UseOldPosition: boolean }, powergrid: boolean, first: boolean): Axis {
        const retVal = new Axis();
        let lastLeftNodes: AxisNode[];
        let lastRightNodes: AxisNode[];
        for (var i = 0; i < levels.length; i++) {
            let level = levels[i];
            let leftaxisnodes = Merger.GetAxisNodes(level.UniqueID, left.Nodes)
            let rightaxisnodes;
            if (right) {
                rightaxisnodes = Merger.GetAxisNodes(level.UniqueID, right.Nodes)
            }
            if (leftaxisnodes.length > 0) {
                lastLeftNodes = leftaxisnodes;
                if (!first) {
                    poscount.UseOldPosition = true;
                }
                for (var k = 0; k < leftaxisnodes.length; k++) {
                    let newAxisnodes = Merger.AddNode(retVal, leftaxisnodes[k], level, leftlevelorder, levels, 0, 0, leftpos, poscount, powergrid);
                    if (newAxisnodes && newAxisnodes.length > 0 && rightaxisnodes && rightaxisnodes.length == 0) {
                        newAxisnodes.forEach(newAxisnode => {
                            if (!newAxisnode.MergePositions) {
                                newAxisnode.MergePositions = new Map<number, number>();
                            }
                            if (!newAxisnode.MergePositions.has(rightpos)) {
                                let pos = 0;
                                if (lastRightNodes && lastRightNodes.length > 0) {
                                    let parent = newAxisnode.Parent;
                                    while (parent) {
                                        let parentAxisNode = lastRightNodes.find(x => x.MemberId == parent.MemberId);
                                        if (parentAxisNode) {
                                            pos = parentAxisNode.Position;
                                            break;
                                        }
                                        parent = parent.Parent;
                                    }
                                }
                                newAxisnode.MergePositions.set(rightpos, pos);
                            }
                        });
                    }
                }
                poscount.UseOldPosition = false;
            }
            if (rightaxisnodes && rightaxisnodes.length > 0) {
                lastRightNodes = rightaxisnodes;
                for (var k = 0; k < rightaxisnodes.length; k++) {
                    let newAxisnodes = Merger.AddNode(retVal, rightaxisnodes[k], level, rightlevelorder, levels, 0, 0, rightpos, poscount, powergrid);
                    if (newAxisnodes && newAxisnodes.length > 0 && leftaxisnodes.length == 0) {
                        newAxisnodes.forEach(newAxisnode => {
                            if (!newAxisnode.MergePositions) {
                                newAxisnode.MergePositions = new Map<number, number>();
                            }
                            if (!newAxisnode.MergePositions.has(leftpos)) {
                                let pos = 0;
                                if (lastLeftNodes && lastLeftNodes.length > 0) {
                                    let parent = newAxisnode.Parent;
                                    while (parent) {
                                        let parentAxisNode = lastLeftNodes.find(x => x.MemberId == parent.MemberId);
                                        if (parentAxisNode) {
                                            pos = parentAxisNode.Position;
                                            break;
                                        }
                                        parent = parent.Parent;
                                    }
                                }
                                newAxisnode.MergePositions.set(leftpos, pos);
                            }
                        });
                    }
                }
            }
        }
        return retVal;
    }
    private static AddDummyNode(retVal: Axis, mergePosList: number[], poscounter: number, areaID: number) {
        const dummy = new AxisNode({
            Position: poscounter,
            Axis: retVal.Type,
            Depth: 0,
            Caption: 'Dummy',
            Key: '0',
            Measure: false
        });
        dummy.DummyInfo = new DummyInfo();
        dummy.DummyInfo.AreaID = areaID;
        mergePosList.forEach(mergePos => {
            dummy.MergePositions.set(mergePos, 0);
        });
        retVal.Nodes.push(dummy);
    }
    private static AddNode(axis: Axis, ln: AxisNode, searchedLevel: LevelNode, levelorder: number[], targetLevel: LevelNode[], targetdepth: number, depth: number, pos: number, poscounter: { Position: number, UseOldPosition: boolean }, powergrid: boolean): AxisNode[] {
        let parents = Merger.GetParents(ln);
        let newAxisNodes = [];
        if (parents.length == 0) {
            if (axis.Nodes.length == 0) {
                let newAxisNode = Merger.CloneNode(ln, null, pos, 0, poscounter.UseOldPosition ? ln.Position : poscounter.Position++)
                axis.Nodes.push(newAxisNode);
                newAxisNodes.push(newAxisNode);
            } else {
                let searchednode = axis.Nodes.find(x => x.MemberId == ln.MemberId);
                if (searchednode) {
                    if (!searchednode.MergePositions) {
                        searchednode.MergePositions = new Map<number, number>();
                        searchednode.MergePositions.set(pos, ln.Position);
                    } else if (!searchednode.MergePositions.has(pos)) {
                        searchednode.MergePositions.set(pos, ln.Position);
                    }
                } else {
                    let newAxisNode = Merger.CloneNode(ln, null, pos, 0, poscounter.UseOldPosition ? ln.Position : poscounter.Position++)
                    axis.Nodes.push(newAxisNode);
                    newAxisNodes.push(newAxisNode);
                }
            }
        } else {
            if (targetLevel[targetdepth].UniqueID == levelorder[depth]) {
                for (var i = 0; i < axis.Nodes.length; i++) {
                    let child = axis.Nodes[i];
                    if (AxisNode.GetSearchChildrenKey(child) == AxisNode.GetSearchChildrenKey(parents[depth])) {
                        newAxisNodes.push(...Merger.AddNodeInternal(child, parents, ln, depth + 1, searchedLevel, levelorder, targetLevel, targetdepth + 1, pos, poscounter, powergrid));
                    }

                }
            } else {
                for (var i = 0; i < axis.Nodes.length; i++) {
                    let child = axis.Nodes[i];
                    newAxisNodes.push(...Merger.AddNodeInternal(child, parents, ln, depth, searchedLevel, levelorder, targetLevel, targetdepth + 1, pos, poscounter, powergrid));
                }
            }
        }
        return newAxisNodes;
    }
    private static AddNodeInternal(an: AxisNode, parents: AxisNode[], ln: AxisNode, depth: number, searchedLevel: LevelNode, levelorder: number[], targetLevel: LevelNode[], targetdepth: number, pos: number, poscounter: { Position: number, UseOldPosition: boolean }, powergrid: boolean): AxisNode[] {
        let newAxisNodes = [];
        if (searchedLevel == null || searchedLevel.UniqueID === ((powergrid && an.Axis == AxisType.Y_Axis) ? levelorder[depth] : targetLevel[targetdepth].UniqueID)) {
            if (an.Children.length == 0) {
                let newAxisNode = Merger.CloneNode(ln, an, pos, targetdepth, poscounter.UseOldPosition ? ln.Position : poscounter.Position++);
                an.addChild(newAxisNode)
                newAxisNodes.push(newAxisNode);
            } else {
                let searchednode = an.ChildrenDict.get(AxisNode.GetSearchChildrenKey(ln));
                if (searchednode) {
                    if (!searchednode.MergePositions) {
                        searchednode.MergePositions = new Map<number, number>();
                        searchednode.MergePositions.set(pos, ln.Position);
                    } else if (!searchednode.MergePositions.has(pos)) {
                        searchednode.MergePositions.set(pos, ln.Position);
                    }
                } else {
                    let newAxisNode = Merger.CloneNode(ln, an, pos, targetdepth, poscounter.UseOldPosition ? ln.Position : poscounter.Position++);
                    an.addChild(newAxisNode)
                    newAxisNodes.push(newAxisNode);
                }
            }
        } else {
            if (targetLevel[targetdepth].UniqueID == levelorder[depth]) {
                for (var i = 0; i < an.Children.length; i++) {
                    let child = an.Children[i];
                    if (AxisNode.GetSearchChildrenKey(child) == AxisNode.GetSearchChildrenKey(parents[depth])) {
                        newAxisNodes.push(...Merger.AddNodeInternal(child, parents, ln, depth + 1, searchedLevel, levelorder, targetLevel, targetdepth + 1, pos, poscounter, powergrid));
                    }

                }
            } else {
                for (var i = 0; i < an.Children.length; i++) {
                    let child = an.Children[i];
                    newAxisNodes.push(...Merger.AddNodeInternal(child, parents, ln, depth, searchedLevel, levelorder, targetLevel, targetdepth + 1, pos, poscounter, powergrid));
                }
            }
        }
        return newAxisNodes;
    }

    private static CloneNode(node: AxisNode, parent: AxisNode, hpos: number, depth: number, pos: number) {
        const retVal = new AxisNode();

        retVal.Axis = node.Axis;
        retVal.Caption = node.Caption;
        retVal.Key = node.Key;
        retVal.Children = [];
        if (node.MergePositions) {
            node.MergePositions.forEach((v, k) => {
                retVal.MergePositions.set(k, v);
            });
        }
        if (!retVal.MergePositions.has(hpos)) {
            retVal.MergePositions.set(hpos, node.Position);
        }
        retVal.Parent = parent;
        retVal.Depth = depth;
        retVal.MemberId = node.MemberId;
        retVal.IsCalculated = node.IsCalculated;
        retVal.UnaryOp = node.UnaryOp;
        retVal.Position = pos;
        retVal.UniqueID = node.UniqueID;
        retVal.SearchChildrenKeyInternal = node.SearchChildrenKeyInternal;
        retVal.Type = node.Type;
        return retVal;
    }

    private static GetParents(ln: AxisNode): AxisNode[] {
        let retVal = [];
        while (ln.Parent) {
            retVal.push(ln.Parent);
            ln = ln.Parent;
        }
        retVal.reverse();

        return retVal;
    }
    private static GetAxisNodes(levelUniqueID: number, axisnodes: AxisNode[]): AxisNode[] {
        let retVal: AxisNode[] = [];
        if (axisnodes.length > 0) {
            if (axisnodes[0].UniqueID == levelUniqueID) {
                retVal.push(...axisnodes);
            } else {
                axisnodes.forEach(x => {
                    retVal.push(...Merger.GetAxisNodes(levelUniqueID, x.Children))
                });
            }
        }
        return retVal;
    }

    private static GetMeasureMergeList(results: QueryDef[]): QueryDef[][] {

        let tmp = new Map<string, QueryDef[]>();
        results.forEach(r => {
            if (r.MultiResult) {
                let key = Merger.GetPositionKey(r.HeterogenPosition.get(AxisType.X_Axis)) + '_'
                    + Merger.GetPositionKey(r.HeterogenPosition.get(AxisType.Y_Axis)) + '_'
                    + Merger.GetPositionKey(r.HeterogenPosition.get(AxisType.Measure));
                let defs = tmp.get(key);
                if (!defs) {
                    defs = [];
                }
                defs.push(r);
                tmp.set(key, defs);
            }
        });

        let retVal: QueryDef[][] = [];
        tmp.forEach(value => {
            retVal.push(value);
        });

        return retVal;
    }
    private static GetPositionKey(pos: number): string {
        return typeof pos === 'number' ? pos.toString() : '';
    }
}
