import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { FilterHelper } from '../../../helpers/filter.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { EventActionType } from '../../../models/enums/eventactiontype.enum';
import { EventAction } from '../../../models/layoutbase.model';
import { WorkflowData } from '../../../models/workflow/workflow.model';
import { LayoutService } from '../../../services/layout.service';
import { NavigationService } from '../../../services/navigation.service';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { TemplateLayout } from '../../../components/layouts/template/template.layout';
import { WorkflowEditDialog } from '../../../workflow/workflow.edit.dialog';
import { APropertyTab } from '../properties/properties.menu.tab';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LayoutChangeType } from '../../../models/enums/layoutchangetype.enum';

@Component({
    selector: 'events-menu-tab',
    templateUrl: './events.menu.tab.html',
    styleUrls: ['./events.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsMenuTab extends APropertyTab {
    static BadgeValue: BehaviorSubject<number> = new BehaviorSubject(0);
    Events: SelectItem[] = [];
    SelectedEvent;
    EventHandlers = {};
    SelectedEventHandler = {};

    EventHandlerTypes = [];
    Workflows = null;
    Variables = null;
    Layout;
    ContentLayout;

    static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        menuItem.Visible = selected && selected.Events && selected.Events.length > 0;
        if (selected && selected.Events) {
            let count = 0;
            selected.Events.forEach((event) => {
                if (event && event.Handlers) {
                    count += event.Handlers.length;
                }
            })
            EventsMenuTab.BadgeValue.next(count);
        } else {
            EventsMenuTab.BadgeValue.next(0);
        }
        
    }

    constructor(cdRef: ChangeDetectorRef, public dialog: MatDialog) {
        super(cdRef);
        this.EventHandlerTypes = EnumHelper.GetDropdownValues(EventActionType);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const page = NavigationService.SelectedPage.getValue();
        if (page === 'content' || page === 'widget' || page === 'template' || page === 'output' || page === 'layout') {
            this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
                this.ContentLayout = layout;
                this.Layout = LayoutHelper.GetActiveResolution(layout);
                
                this.updateWorkflowItems();
                this.updateVariables();
            }));
        }
        this.Subscriptions.push(LayoutService.LayoutChanged.subscribe((result) => {
            if (result && (
                result.LayoutChangeType == LayoutChangeType.WorkflowAdded ||
                result.LayoutChangeType == LayoutChangeType.WorkflowChange ||
                result.LayoutChangeType == LayoutChangeType.WorkflowRemoved
            )) {
                this.updateWorkflowItems();
            }
        }))
    }

    private getNode(v): any {
        let caption = '';
        if (v.Action) {
            switch (v.ActionType) {
                case EventActionType.Workflow:
                    if (this.Workflows) {
                        const wf = this.Workflows.find(w => w.ID === v.Action);
                        if (wf) {
                            caption = wf.Caption + ' ';
                        }
                    }
                    break;
                case EventActionType.Variable:
                    if (this.Variables) {
                        const variable = this.Variables.find(w => w.ID === v.Action);
                        if (variable) {
                            caption = variable.Name + ' ';
                        }
                    }
                    break;
            }
        }
        caption += '(' + EventActionType[v.ActionType] + ')';
        return {
            label: caption,
            data: v
        };
    }
    ActiveChanged(ev, event) {
        if (!ev && event) {
            if (this.SelectedItemValue && this.SelectedItemValue.Events) {
                let item = this.SelectedItemValue.Events.find((value) => value.EventID == event.label);
                if (item) {
                    item.Handlers = [];
                    if (this.EventHandlers[event.label]) {
                        this.EventHandlers[event.label] = item.Handlers;
                    }
                    this.SelectedEventHandler[event.label] = null;
                    this.updateEvents();
                    this.onEventChanged();
                    LayoutService.RefreshTree.next(true);
                }
            }
            this.cdRef.detectChanges();
        }
    }
    onItemSet() {
        this.updateWorkflowItems();
        this.updateVariables();
        this.SelectedEvent = null;
        this.SelectedEventHandler = {};
    }
    updateEvents() {
        if (this.Workflows && this.Variables) {
            const events = [];
            this.EventHandlers = {};
            if (this.SelectedItem && this.SelectedItem.Events) {
                let index = 1;
                this.SelectedItem.Events.forEach((v) => {
                    if (v) {
                        const handlers = [];
                        v.Handlers.forEach((h) => {
                            const node = this.getNode(h);
                            handlers.push(node);
                        });
                        this.EventHandlers[v.EventID] = handlers;
                        events.push({ label: v.EventID, value: { id: index++, data: v.Handlers }, active: (v.Handlers&&v.Handlers.length > 0) });
                    }
                });
            }
            this.Events = events;
        }
    }
    updateVariables() {
        this.Variables = [];
        if (this.SelectedItem && this.Layout) {
            this.Variables = FilterHelper.GetVariables(this.SelectedItem);
            this.updateEvents();
        }
        this.cdRef.detectChanges();
    }
    updateWorkflowItems() {
        this.Workflows = [];
        const workflows = [];
        if (this.SelectedItem && this.Layout) {
            let parentPath;
            if (this.Layout === this.SelectedItem) {
                parentPath = [this.Layout];
            } else {
                parentPath = MetaHelper.FindParentPath(this.Layout, this.SelectedItem);
            }
            if (parentPath) {
                let pCaption = '';
                parentPath.forEach((p) => {
                    if (p.ElementType === TemplateLayout.Type) {
                        pCaption += p.Name + ' - ';
                    }
                    if (p.Workflows) {
                        p.Workflows.forEach((wf) => {
                            workflows.push({
                                Caption: pCaption + wf.Caption,
                                ID: wf.ID
                            });
                        });
                    }
                });
                ArrayHelpers.sortAlphabetical(workflows, 'Caption');
            }
            this.Workflows = workflows;
            this.updateEvents();
        }
        this.cdRef.detectChanges();
    }

    onEventChange(event) {
        if (event && event !== this.SelectedEvent) {
            this.SelectedEvent = event;
            this.SelectedEventHandler = {};
        }
        this.cdRef.detectChanges();
    }

    addEventHandler(id) {
        if (this.EventHandlers[id]) {
            const ev = new EventAction();
            const node = this.getNode(ev);
            if (this.EventHandlers[id]) {
                this.EventHandlers[id].push(node);
            }
            let event = this.Events.find((value) => value.label == id);
            if (event && event.value) {
                event.value.data.push(node.data);
            }
            this.SelectedEventHandler[id] = node;
            this.onEventChanged();
            LayoutService.RefreshTree.next(true);
        }
    }

    removeEventHandler(id) {
        if (this.EventHandlers[id]) {
            let event = this.Events.find((value) => value.label == id);
            if (event && event.value) {
                let ev = event.value.data.find((value) => value == this.SelectedEventHandler[id].data);
                if (ev) {
                    event.value.data.splice(event.value.data.indexOf(ev), 1);
                }
            }
            const index = this.EventHandlers[id].indexOf(this.SelectedEventHandler[id]);
            if (index > -1) {
                this.EventHandlers[id].splice(index, 1);
                if (index < this.EventHandlers[id].length) {
                    this.SelectedEventHandler[id] = this.EventHandlers[id][index];
                } else if (this.EventHandlers[id].length > 0) {
                    this.SelectedEventHandler[id] = this.EventHandlers[id][this.EventHandlers[id].length - 1];
                } else {
                    this.SelectedEventHandler[id] = null;
                }
                this.onEventChanged();
                LayoutService.RefreshTree.next(true);
            }

        }
    }

    onHandlerChanged(id) {
        if (this.SelectedEventHandler[id]) {
            const node = this.getNode(this.SelectedEventHandler[id].data);
            this.SelectedEventHandler[id].label = node.label;
            this.onEventChanged();
        }
    }
    UpdateBadge() {
        if (this.SelectedItemValue && this.SelectedItemValue.Events) {
            let count = 0;
            this.SelectedItemValue.Events.forEach((event) => {
                if (event && event.Handlers) {
                    count += event.Handlers.length;
                }
            })
            EventsMenuTab.BadgeValue.next(count);
        } else {
            EventsMenuTab.BadgeValue.next(0);
        }
    }
    onEventChanged() {
        if (this.SelectedItemValue) {
            LayoutService.OnLayoutPropertyChanged(this.SelectedItemValue.ID, 'Events', this.SelectedItemValue.Events);
            this.UpdateBadge();
        }
        this.cdRef.detectChanges();
    }

    EditWorkflow(item) {
        const wf = this.Layout.Workflows.find(w => w.ID === item.data.Action);
        if (wf) {
            this.showDialog(wf, () => {
                LayoutService.OnLayoutWorkflowsChanged({
                    LayoutID: this.Layout.ID,
                    Workflows: [JSON.stringify(wf)]
                });
            });
        }
    }
    showDialog(workflow, handler) {
        if (workflow) {
            const dialogRef = this.dialog.open(WorkflowEditDialog, {
                data: { Data: workflow.Data, Layout: this.Layout },
                panelClass: 'workflowedit-dialog-container',
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result instanceof WorkflowData) {
                    workflow.Data = result;
                    if (typeof handler === 'function') {
                        handler();
                    }
                }
                this.cdRef.detectChanges();
                return true;
            });
        }
    }
}
