import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { LayoutElement } from '../../../models/layoutelement.model';
import { LayoutService } from '../../../services/layout.service';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'listbox-theme-control',
    templateUrl: './listbox.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxThemeControl extends BaseThemeControl {
    ClearButtonPlaceholder = false;
    ClearButtonInputType = false;
    ClearButtonInputMode = false;

    //#region SelectedEntry
    SelectedEntryValue;
    @Input()
    get SelectedEntry() {
        return this.SelectedEntryValue;
    }
    set SelectedEntry(val) {
        this.SelectedEntryValue = val;
        this.CheckVisibility();
        this.SelectedEntryChange.emit(this.SelectedEntryValue);
    }

    @Output() SelectedEntryChange = new EventEmitter<any>();
    //#endregion
    DisplayMemberPathValue = "Name";
    @Input()
    get DisplayMemberPath() {
        return this.DisplayMemberPathValue;
    }
    set DisplayMemberPath(val) {
        this.DisplayMemberPathValue = val;
        this.cdRef.detectChanges();
    }
    //#region Properties
    PropertiesValue;
    RawProperties;
    @Input()
    get Properties() {
        return this.PropertiesValue;
    }
    set Properties(val) {
        if (val) {
            const rawProperties = [];
            val.forEach(v => {
                rawProperties.push({
                    ...v
                });
            });
            this.RawProperties = rawProperties;
        } else {
            this.RawProperties = null;
        }
        this.CheckVisibility();
        this.PropertiesChange.emit(this.PropertiesValue);
    }

    @Output() PropertiesChange = new EventEmitter<any>();
    //#endregion

    //#region UseCountAsCaption
    UseCountAsCaptionValue = false;
    @Input()
    get UseCountAsCaption() {
        return this.UseCountAsCaptionValue;
    }
    set UseCountAsCaption(val) {
        this.UseCountAsCaptionValue = val;
        this.UseCountAsCaptionChange.emit(this.UseCountAsCaptionValue);
    }

    @Output() UseCountAsCaptionChange = new EventEmitter<any>();
    //#endregion
    inputTypes = [];
    inputModes = [];

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.Subscriptions['SelectedItem'] = LayoutService.SelectedItem.subscribe(() => {
            this.SelectedEntry = null;
            this.cdRef.detectChanges();
        });
    }

    AddItem() {
        const item = this.GenerateNewItem();
        this.SelectedEntry = item;
        if (!this.DataSourceInternal) {
            this.DataSourceInternal = [];
        }
        this.DataSourceInternal.push(item);
        this.onStyleChanged();
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(item),
            Index: this.DataSourceInternal.length - 1,
            ParentID: this.SelectedItemValue.ID
        });
    }

    GenerateNewItem() {
        return {};
    }

    DropEntry(event) {
        if (event.previousIndex !== event.currentIndex) {
            if (this.DataSourceInternal) {
                const item = this.DataSourceInternal[event.previousIndex];
                moveItemInArray(this.DataSourceInternal, event.previousIndex, event.currentIndex);
                this.onStyleChanged();
                LayoutService.OnLayoutElementMoved({
                    ElementID: item.ID,
                    Index: event.currentIndex,
                    OldParentID: this.SelectedItemValue.ID,
                    NewParentID: this.SelectedItemValue.ID
                });
            }
        }
    }

    // detect style changes on click of remove button
    onRemoveStyleChanges() {
        this.onStyleChanged();
    }

    RemoveItem(entry) {
        if (this.SelectedEntry && this.DataSourceInternal) {
            const index = this.DataSourceInternal.indexOf(this.SelectedEntry);
            if (index > -1) {
                if (this.SelectedEntry instanceof LayoutElement) {
                    let templatePath = '';
                    let Layout = LayoutService.SelectedLayout.getValue();
                    if (Layout && Layout.Workflows) {
                        const parents = MetaHelper.FindParentPath(Layout, this.SelectedEntry);
                        if (parents) {
                            parents.forEach(x => {
                                if (x.ElementType === 'template') {
                                    templatePath += x.Name + '.';
                                }
                            });
                        }
                    }
                    LayoutHelper.OnDeleteElement(this.SelectedEntry, templatePath, Layout, () => {
                        const itemID = this.SelectedEntry.ID;
                        let itemName = itemID;
                        if (this.SelectedEntry.Name) {
                            itemName = this.SelectedEntry.Name;
                        }
                        this.DataSourceInternal.splice(index, 1);
                        const length = this.DataSourceInternal.length;
                        if (length > 0) {
                            if (index < length) {
                                this.SelectedEntry = this.DataSourceInternal[index];
                            } else {
                                this.SelectedEntry = this.DataSourceInternal[length - 1];
                            }
                        } else {
                            this.SelectedEntry = null;
                        }
                        this.onStyleChanged();
                        LayoutService.OnLayoutElementRemoved({
                            ElementID: itemID,
                            ElementName: itemName,
                            ParentID: this.SelectedItem.ID
                        });
                    });   
                } else {
                    const itemID = this.SelectedEntry.ID;
                    this.DataSourceInternal.splice(index, 1);
                    const length = this.DataSourceInternal.length;
                    if (length > 0) {
                        if (index < length) {
                            this.SelectedEntry = this.DataSourceInternal[index];
                        } else {
                            this.SelectedEntry = this.DataSourceInternal[length - 1];
                        }
                    } else {
                        this.SelectedEntry = null;
                    }
                    this.onStyleChanged();
                    LayoutService.OnLayoutElementRemoved({
                        ElementID: itemID,
                        ElementName: itemID,
                        ParentID: this.SelectedItem.ID
                    });
                }
            }
        }
    }

    onButtonStyleChanged(prop) {
        if (this.SelectedItem) {
            if (this.SelectedItem.ValuesChanged) {
                this.SelectedItem.ValuesChanged.next(prop);
            }
            this.StyleChanged.emit();
            this.cdRef.detectChanges();
        }
    }
    CheckVisibility() {
        if (this.SelectedEntry && this.RawProperties) {
            this.PropertiesValue = this.RawProperties.filter(v => !v.CheckVisibility || v.CheckVisibility(this.SelectedItemValue, this.SelectedEntry));            
        } else {
            this.PropertiesValue = [];
        }
        if (this.ViewInit) {
            this.cdRef.detectChanges();
        }
    }
    GetCaption(data, i) {
        if (this.UseCountAsCaptionValue) {
            return i+1;
        } else {
            return data[this.DisplayMemberPath];
        }
    }
    SetDataSource(item) {
        if (this.SelectedEntry && item && item.instance) {
            item.instance.SelectedItem = this.SelectedEntry;
            if (this.SelectedItem[item.ID] == null && item.Type != null) {
                this.SelectedItem[item.ID] = new item.Type();
            }
            if (item.Type == null || typeof (item.Type) == 'string') {
                item.instance.PathBinding = item.ID;
            } else {
                item.instance.DataSourceInternal = this.SelectedEntry[item.ID];
            }
        }
        if (item.InitArgs) {
            item.instance.SetProperties(item.InitArgs);
        }
        if (item.Properties) {
            item.instance.Properties = item.Properties;
        }
        if (!item.instance.ExternalSubscriptions) {
            item.instance.ExternalSubscriptions = {};
        }
    }
    ComponentAttached(event, item) {
        if (event && item) {
            item.instance = event.instance;
        }
        if (this.SelectedEntry && item && item.instance) {
            this.SetDataSource(item);
            if (item.SelectedChangeSub) {
                item.SelectedChangeSub.unsubscribe();
                item.SelectedChangeSub = null;
            }
            item.SelectedChangeSub = this.SelectedEntryChange.subscribe(() => {
                this.SetDataSource(item);
            });
            if (item.instance.StyleChanged) {
                if (item.instance.ExternalSubscriptions["StyleChanged"]) {
                    item.instance.ExternalSubscriptions["StyleChanged"].unsubscribe();
                }
                item.instance.ExternalSubscriptions["StyleChanged"] = item.instance.StyleChanged.subscribe((val) => {
                    this.CheckVisibility();
                    this.onStyleChanged();
                });
            }
            this.cdRef.detectChanges();
        }
    }
}