import { Component } from '@angular/core';
import { BaseDialog } from '../../../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../../../models/translatedstring.model';
import { RestService } from '../../../../../services/rest.service';
import { BaseListDetail } from '../../../../../settings/base.list.settings';
import { RestObjectPropertyDialog } from './rest.object.property.dialog/rest.object.property.dialog';

@Component({
    selector: 'rest-object-detail',
    templateUrl: 'rest.objects.detail.html',
    styleUrls: ['rest.objects.detail.css']
})
export class RestObjectDetailComponent extends BaseListDetail {

    public AllTypes: any[] = [];
    public Rows: any[] = [];
    public AllObjects: any[] = [];

    constructor(private _restService: RestService) {
        super();
    }

    ngOnInit() {
        this._restService.getDynamicTypes().subscribe((data) => {
            if (data) {
                this.AllTypes = data;
            }
        });
        super.ngOnInit();
    }

    public addField(): void {
        const selected = this.SelectedItem;
        if (selected) {
            this.showDialog(null, (r) => {
                selected.Fields.push(r);
                this.OnItemChanged();
                const row = this.fillRow({}, r);
                this.Rows.push(row);
            });
        }
    }

    public fillRow(row, f): void {
        row.ID = f.ID;
        row.Name = f.Name;
        row.Caption = TranslatedString.GetTranslation(f.Caption);
        const type = this.AllTypes.find(x => x.Value == f.Type);
        if (type) {
            row.Type = type.Caption;
        } else {
            row.Type = f.Type;
        }
        row.Primary = f.IsPrimary ? 'done' : '';
        row.ReadOnly = f.IsReadOnly ? 'done' : '';
        row.Mandatory = f.IsMandatory ? 'done' : '';
        row.IsNullable = f.IsNullable ? 'done' : '';
        row.List = f.IsList ? 'done' : '';
        if (f.Type === 'c4ef25dd-c176-418c-836e-f26c52d7f59c') { // AttributeClass
            const obj = this.AllObjects.find(x => x.Value === f.AdvancedType);
            if (obj) {
                row.Table = obj.Caption;
            } else {
                row.Table = f.AdvancedType;
            }
        } else {
            row.Table = '';
        }
        row.Shared = f.IsShared ? 'done' : '';
        row.Reverse = f.IsReverse ? 'done' : '';
        row.Default = f.Default;
        return row;
    }

    showDialog(field, handler) {
        BaseDialog.ShowDialog({
            ContentType: RestObjectPropertyDialog,
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Feld definieren',
            InitArgs: {
                AllTypes: this.AllTypes,
                Field: field,
                AllObjects: this.AllObjects
            }
        });
    }

    setSelectedItem(item): void {
        if (item && item.Fields) {
            const rows = this.Rows = [];
            this._restService.loadRestObjects(item.RestID).subscribe((data) => {
                if (data) {
                    this.AllObjects = data;
                    //fill rows only after getting all objects for selected rest item. Should be like that for display ref classes for field.
                    item.Fields.forEach(f => {
                        rows.push(this.fillRow({}, f));
                    });

                    this.Rows = rows;
                }
            });                                 
        }
        super.setSelectedItem(item);
    }

    public editField(row): void {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(field, (r) => {
                    this.fillRow(row, r);
                    selected.Fields.splice(fieldIndex, 1, r);
                    this.OnItemChanged();
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }
}