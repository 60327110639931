import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterceptorSkipHeader } from '../services/interceptor.skip.header';
import { StandardRequestBase } from '../services/request-base';
import { InjectorHelper, TranslateHelper } from './injector.helper';
import moment from 'moment';

export class AboutInfo {
    ClientVersion = '';
    ServerVersions = [];

    static GetAboutInfo(): Promise<AboutInfo>  {
        return new Promise<AboutInfo>((resolve, reject) => {
            const info = new AboutInfo();
            const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
            const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
            const ts = new Date().getTime();
            http.get('assets/version.json?ts=' + ts, { headers }).subscribe((client) => {
                if (client) {
                    info.ClientVersion = client['Value'] ?? '';
                }
                const service = InjectorHelper.InjectorInstance.get<StandardRequestBase>(StandardRequestBase);
                service.executeGet('api/version', 'Get').subscribe((server) => {
                    if (server) {
                        info.ServerVersions = server;
                    }
                    resolve(info);
                }, e => reject(e));
            }, e => reject(e));
        });
    }

    getInfoText() {
        const retList = [
            TranslateHelper.TranslatorInstance.instant('@@Instance') + ': ' + window.location.host,
            TranslateHelper.TranslatorInstance.instant('@@Date') + ': ' + moment().format('lll'),
            TranslateHelper.TranslatorInstance.instant('@@ClientVersion') + ': ' + this.ClientVersion,
        ];
        this.ServerVersions.forEach(x => {
            retList.push(x.Name + ': ' + x.Value);
        });
        return retList.join('\n');
    }
}
