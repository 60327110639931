import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { BorderStyle } from '../../../models/enums/borderstyle.enum';
import { Border } from '../../../models/style/border.model';
import { BorderRadius } from '../../../models/style/borderradius.model';
import { BorderSide } from '../../../models/style/borderside.model';
import { Color } from '../../../models/style/color.model';
import { Gradient } from '../../../models/style/gradient.model';
import { GradientStopColor } from '../../../models/style/gradientstopcolor.model';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'borderselection-theme-control',
    templateUrl: './borderselection.theme.control.html',
    styleUrls: ['./borderselection.theme.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorderThemeControl extends BaseThemeControl implements OnInit {
    

    //#region Caption
    CaptionValue;
    @Input()
    get Caption() {
        return this.CaptionValue;
    }
    set Caption(val) {
        this.CaptionValue = val;
        this.cdRef.detectChanges();
    }
    //#endregion

    //#region CaptionPosition
    CaptionPositionValue
    @Input()
    get CaptionPosition() {
        return this.CaptionPositionValue;
    }
    set CaptionPosition(val) {
        this.CaptionPositionValue = val;
        this.CaptionPositionChange.emit(this.CaptionPositionValue);
        this.cdRef.detectChanges();
    }

    @Output() CaptionPositionChange = new EventEmitter<any>();
    //#endregion

    //#region Tooltip
    TooltipValue
    @Input()
    get Tooltip() {
        return this.TooltipValue;
    }
    set Tooltip(val) {
        this.TooltipValue = val;
        this.TooltipChange.emit(this.TooltipValue);
        this.cdRef.detectChanges();
    }

    @Output() TooltipChange = new EventEmitter<any>();
    //#endregion

    //#region Icon
    IconValue
    @Input()
    get Icon() {
        return this.IconValue;
    }
    set Icon(val) {
        this.IconValue = val;
        this.IconChange.emit(this.IconValue);
        this.cdRef.detectChanges();
    }

    @Output() IconChange = new EventEmitter<any>();
    //#endregion
    //#region BorderStyle
    BorderStyles = [];
    BorderStyleValue;
    get BorderStyle() {
        return this.BorderStyleValue;
    }
    set BorderStyle(value) {
        this.BorderStyleValue = value;
        
        this.cdRef.detectChanges();
    }
    BorderStyleChanged() {
        this.DataSourceInternal.TopBorder.Style = this.BorderStyle;
        this.DataSourceInternal.LeftBorder.Style = this.BorderStyle;
        this.DataSourceInternal.RightBorder.Style = this.BorderStyle;
        this.DataSourceInternal.BottomBorder.Style = this.BorderStyle;
        this.StyleChanged.emit();
    }
    //#endregion
    //#region BorderSize
    BorderSizeValue =new LayoutUnit();
    get BorderSize() {
        return this.BorderSizeValue;
    }
    set BorderSize(value) {
        this.BorderSizeValue = value;
        
        this.cdRef.detectChanges();
    }
    SizeChanged() {
        this.DataSourceInternal.TopBorder.Thickness = JSON.parse(JSON.stringify(this.BorderSize));
        this.DataSourceInternal.LeftBorder.Thickness = JSON.parse(JSON.stringify(this.BorderSize));
        this.DataSourceInternal.RightBorder.Thickness = JSON.parse(JSON.stringify(this.BorderSize));
        this.DataSourceInternal.BottomBorder.Thickness = JSON.parse(JSON.stringify(this.BorderSize));
        this.StyleChanged.emit();
    }
    //#endregion
    //#region
    BorderRadiusValue = new LayoutUnit();
    get BorderRadius() {
        return this.BorderRadiusValue;
    }
    set BorderRadius(value) {
        this.BorderRadiusValue = value;
        
        this.cdRef.detectChanges();
    }
    RadiusChanged() {
        this.DataSourceInternal.BorderRadius.BottomLeft = JSON.parse(JSON.stringify(this.BorderRadius));
        this.DataSourceInternal.BorderRadius.TopLeft = JSON.parse(JSON.stringify(this.BorderRadius));
        this.DataSourceInternal.BorderRadius.BottomRight = JSON.parse(JSON.stringify(this.BorderRadius));
        this.DataSourceInternal.BorderRadius.TopRight = JSON.parse(JSON.stringify(this.BorderRadius));
        this.StyleChanged.emit();
    }
    //#endregion
    //#region BorderColor
    BorderColorValue = new Color();
    get BorderColor() {
        return this.BorderColorValue;
    }
    set BorderColor(value) {
        this.BorderColorValue = value;
        
        this.cdRef.detectChanges();
    }
    ColorChanged() {
        this.DataSourceInternal.TopBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
        this.DataSourceInternal.LeftBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
        this.DataSourceInternal.RightBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
        this.DataSourceInternal.BottomBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
        this.StyleChanged.emit();
    }
    //#endregion

    get TopLeft() {
        let retVal = {}
        retVal['border-top-left-radius'] = LayoutUnit.ToStyle(this.DataSourceInternal.BorderRadius.TopLeft);
        return retVal;
    }
    get TopRight() {
        let retVal = {}
        retVal['border-top-right-radius'] = LayoutUnit.ToStyle(this.DataSourceInternal.BorderRadius.TopRight);
        return retVal;
    }
    get BottomLeft() {
        let retVal = {}
        retVal['border-bottom-left-radius'] = LayoutUnit.ToStyle(this.DataSourceInternal.BorderRadius.BottomLeft);
        return retVal;
    }
    get BottomRight() {
        let retVal = {}
        retVal['border-bottom-right-radius'] = LayoutUnit.ToStyle(this.DataSourceInternal.BorderRadius.BottomRight);
        return retVal;
    }
    DifferentRadiusValue = false;
    get DifferentRadius() {
        return this.DifferentRadiusValue;
    }
    set DifferentRadius(value) {
        this.DifferentRadiusValue = value;
    }
    DifferentBorder = false;
    DifferentBorderValueChanged(event) {
        if (!this.DifferentBorder) {
            if (this.DataSourceInternal) {
                this.DataSourceInternal.TopBorder = new BorderSide();
                this.BorderStyleValue = this.DataSourceInternal.TopBorder.Style;
                this.BorderSizeValue = this.DataSourceInternal.TopBorder.Thickness;
                this.DataSourceInternal.TopBorder.Color = Gradient.Solid(Color.FromHex('#000000'));
                this.BorderColorValue = this.DataSourceInternal.TopBorder.Color.Colors[0].Color;


                this.DataSourceInternal.TopBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
                this.DataSourceInternal.TopBorder.Thickness = this.BorderSize;
                this.DataSourceInternal.TopBorder.Style = this.BorderStyle;

                this.DataSourceInternal.LeftBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
                this.DataSourceInternal.LeftBorder.Thickness = this.BorderSize;
                this.DataSourceInternal.LeftBorder.Style = this.BorderStyle;

                this.DataSourceInternal.RightBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
                this.DataSourceInternal.RightBorder.Thickness = this.BorderSize;
                this.DataSourceInternal.RightBorder.Style = this.BorderStyle;

                this.DataSourceInternal.BottomBorder.Color = Gradient.Solid(Color.FromObject(this.BorderColor));
                this.DataSourceInternal.BottomBorder.Thickness = this.BorderSize;
                this.DataSourceInternal.BottomBorder.Style = this.BorderStyle;
                this.StyleChanged.emit();
            }
        }
    }
    TopBorderSet = false;
    LeftBorderSet = false;
    RightBorderSet = false;
    BottomBorderSet = false;
    get SelectedBorder() {
        if (this.DataSourceInternal && this.SelectedBorderPath) {
            switch (this.SelectedBorderPath) {
                case 'left':
                    return this.DataSourceInternal.LeftBorder;
                case 'right':
                    return this.DataSourceInternal.RightBorder;
                case 'bottom':
                    return this.DataSourceInternal.BottomBorder;
                case 'top':
                    return this.DataSourceInternal.TopBorder;
            }
        }
        return null;
    }
    SelectedBorderPath = null;
    SetSelectedBorder(value) {
        if (this.SelectedBorderPath != value) {
            this.SelectedBorderPath = value;
        } else {
            this.SelectedBorderPath = null;
        }
    }
    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
        this.BorderStyles = EnumHelper.GetDropdownValues(BorderStyle);
        this.DataSourceInternalChange.subscribe((value) => {
            if (value) {
                this.TopBorderSet = (value.TopBorder && BorderSide.BorderToStyle(value.TopBorder) != '');
                this.LeftBorderSet = (value.LeftBorder && BorderSide.BorderToStyle(value.LeftBorder) != '');
                this.RightBorderSet = (value.RightBorder && BorderSide.BorderToStyle(value.RightBorder) != '');
                this.BottomBorderSet = (value.BottomBorder && BorderSide.BorderToStyle(value.BottomBorder) != '');
                if (value.TopBorder && value.LeftBorder && value.RightBorder && value.BottomBorder) {
                    let tb = BorderSide.BorderToStyle(value.TopBorder)
                    let lb = BorderSide.BorderToStyle(value.LeftBorder)
                    let rb = BorderSide.BorderToStyle(value.RightBorder)
                    let bb = BorderSide.BorderToStyle(value.BottomBorder)
                    if (!(tb || lb || rb ||bb)) {
                        this.DifferentBorder = false;
                    } else {
                        if (tb == lb && tb == rb && tb == bb) {
                            this.DifferentBorder = false;
                            this.BorderStyleValue = value.TopBorder.Style;
                            this.BorderSizeValue = value.TopBorder.Thickness;
                            this.BorderColorValue = value.TopBorder.Color.Colors[0].Color;
                        } else {
                            this.DifferentBorder = true;
                        }
                    }
                } 
                if (value.BorderRadius) {
                    let tl = LayoutUnit.ToStyle(value.BorderRadius.TopLeft)
                    let tr = LayoutUnit.ToStyle(value.BorderRadius.TopRight)
                    let bl = LayoutUnit.ToStyle(value.BorderRadius.BottomLeft)
                    let br = LayoutUnit.ToStyle(value.BorderRadius.BottomRight)
                    if (!(tl || tr || bl || br)) {
                        this.DifferentRadiusValue = false;
                    } else {
                        if (tl && tl == tr && tl == bl && tl == br) {
                            this.DifferentRadiusValue = false;
                            this.BorderRadiusValue = value.BorderRadius.BottomLeft;

                        } else {
                            this.DifferentRadiusValue = true;
                        }
                    }
                }
                this.cdRef.detectChanges();
            }
        })
    }
    ngOnInit(): void {
        //this.BorderSize = new LayoutUnit();
        //this.BorderRadius = new LayoutUnit();
    }

    onClick(ev) {
        this.EmitAction(ev);
    }
    onStyleChanged() {
        this.StyleChanged.emit();
    }
    ClearBorderSide() {
        switch (this.SelectedBorderPath) {
            case 'left':
                this.DataSourceInternal.LeftBorder = new BorderSide();
                this.LeftBorderSet = false;
                break;
            case 'right':
                this.DataSourceInternal.RightBorder = new BorderSide();
                this.RightBorderSet = false;
                break
            case 'bottom':
                this.DataSourceInternal.BottomBorder = new BorderSide();
                this.BottomBorderSet = false;
                break
            case 'top':
                this.DataSourceInternal.TopBorder = new BorderSide();
                this.TopBorderSet = false;
                break
        }
        this.SetSelectedBorder(null);
        this.StyleChanged.emit();
        this.cdRef.detectChanges();
    }
    onBorderStyleChanged() {
        switch (this.SelectedBorderPath) {
            case 'left':
                this.LeftBorderSet = true;
                break;
            case 'right':
                this.RightBorderSet = true;
                break
            case 'bottom':
                this.BottomBorderSet = true;
                break
            case 'top':
                this.TopBorderSet = true;
                break
        }
        this.StyleChanged.emit();
    }
}
