import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { InjectorHelper } from '../../../../helpers/injector.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { UserSettingsService } from '../../../../services/usersettings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { DataCheck, FormulaWorkflowDialogContent } from '../../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../../definevalue/define.value.settings';

@Component({
    selector: 'wf-applyprofile-settings',
    templateUrl: './apply.profile.settings.html'
})
export class ApplyProfileSettings extends FormulaWorkflowDialogContent {
    Data = new ApplyProfileSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'applyProfileModule';
        reg.Caption = '@@Apply profile';
        reg.GroupID = 'profileGroup';
        reg.Index = 40;
        reg.SettingsControl = ApplyProfileSettings;
        reg.SettingsTypeHelper = new ApplyProfileSettingsDataHelper();
        reg.Executer = ApplyProfileModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ApplyProfileSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data && !this.Data.ProfileName) {
            retVal.IsCorrect = false;
            retVal.Error = '@@SetProfileNameError';
        }
        return retVal;
    }

    getResult(): any {
        return this.Data;
    }
}

export class ApplyProfileSettingsData {
    ProfileName: string;
}

export class ApplyProfileSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ApplyProfileSettingsData();
    }
}

export class ApplyProfileModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const layout = status.WorkflowLayoutService.Layout;
        if (layout) {
            if (status.ActualSettings) {
                if (status.ActualSettings.ProfileName) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    const value = calculator.CalcFormula(status.ActualSettings.ProfileName);
                    const service = InjectorHelper.InjectorInstance.get<UserSettingsService>(UserSettingsService);
                    await service.ChangeProfile(layout._Id, '' + value);
                    return 0;
                } else {
                    status.Logger.logError('Apply profile modul: No profilename set.');
                }
            } else {
                status.Logger.logError('Apply profile modul: No settings found.');
            }
        } else {
            status.Logger.logError('Apply profile modul: No layout found.');
        }
        return super.execute(status);
    }
}
