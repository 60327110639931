import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { deserialize, plainToClass, serialize } from 'class-transformer';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { ArrayHelpers, TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { EnumHelper } from '../../helpers/enum.helper';
import { TranslateHelper } from '../../helpers/injector.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { WorkflowErrorType } from '../../models/enums/workflowerrortype.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { WorkflowSaveObject } from '../../models/workflow/workflow.model';
import { Registry } from '../../script/modules/registry.script';
import { LayoutService } from '../../services/layout.service';
import { ScriptLibraryService } from '../../services/scriptlibrary.service';
import { SideNavService } from '../../services/sidenav.service';
import { UsersService } from '../../services/users.service';
import { WorkflowService } from '../../services/workflow.service';
import { ServiceWorkflowUsageDialog } from '../../workflow/modules/service.workflow.usage.dialog';
import {WorkflowControl, WorkflowEditOptions} from '../../workflow/workflow.control';
import { VersioningOverview } from '../versioningoverview/versioning.overview';
import { EditWorkflowGroupDialog } from './edit.workflow.group.dialog';

@Component({
    selector: 'workflow-version-settings',
    templateUrl: './workflow.version.settings.html',
    styleUrls: ['./workflow.version.settings.css']
})
export class WorkflowVersionSettings implements OnInit, OnDestroy {
    InitArgs: WorkflowEditOptions = new WorkflowEditOptions();
    @ViewChild('workflowControl') workflowControl: WorkflowControl;
    CopyType = null;
    ShowCopyButton = true;
    ShowDuplicateButton = false;
    ShowPasteButton = false;
    SearchValue: string;
    ClipboardChanged;
    HasChanges = false;
    EditMode = false;
    SelectedItem;
    ListItems = [];
    Groups = [];
    RootNodes = [];
    Unsorted;
    selectedNode;
    Caption;
    ErrorTypes = [];
    ActiveUser;
    UserChanged;
    toggleWindow = true;

    static GetSettingsEntry() {
        const serviceOptions = new WorkflowEditOptions();
        serviceOptions.IsService = true;
        const templateOptions = new WorkflowEditOptions();
        templateOptions.IsTemplate = true;
        return {
            Caption: '@@Workflow',
            ID: 'workflow',
            Icon: 'device_hub',
            Index: 1,
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 16
            },
            Parent: 'data',
            Children: [
                {
                    Caption: '@@Client',
                    ID: 'wf-templates',
                    Icon: 'assignment',
                    Index: 0,
                    Parent: 'workflow',
                    Security: {
                        Name: 'evidanza.App.Shared.Security.DataRight',
                        Value: 32
                    },
                    Content: WorkflowVersionSettings,
                    InitArgs: templateOptions
                },
                {
                    Caption: '@@Service',
                    ID: 'wf-service',
                    Icon: 'settings_system_daydream',
                    Index: 1,
                    Parent: 'workflow',
                    Security: {
                        Name: 'evidanza.App.Shared.Security.DataRight',
                        Value: 64
                    },
                    Content: WorkflowVersionSettings,
                    InitArgs: serviceOptions
                }
            ]
        };
    }

    constructor(private cdRef: ChangeDetectorRef, private service: WorkflowService, lib: ScriptLibraryService, private router: Router) {
        Registry.initializeRegistry(lib);
        this.ErrorTypes = EnumHelper.GetDropdownValues(WorkflowErrorType);
        this.Unsorted = {
            Name: '@@Nicht zugeordnet',
            Children: [],
            IsExpanded: true
        };
    }

    ngOnInit(): void {
        this.CopyType = this.InitArgs.IsService ? 'ServiceWorkflowCopy' : 'ClientLibraryWorkflowCopy';
        if (ClipboardHelper.Inititalized) {
            this.ShowCopyButton = true;
            this.ShowDuplicateButton = false;
            if (ClipboardHelper.ClipboardContent && ClipboardHelper.ClipboardContent.type && ClipboardHelper.ClipboardContent.type == this.CopyType) {
                this.ShowPasteButton = true;
            } else {
                this.ShowPasteButton = false;
            }
            this.ClipboardChanged = ClipboardHelper.ClipboardChanged.subscribe((data: any) => {
                if (data && data.type && data.type == this.CopyType) {
                    this.ShowPasteButton = true;
                } else {
                    this.ShowPasteButton = false;
                }
                this.cdRef.detectChanges();
            });
        }
        this.UserChanged = UsersService.ActiveUser.subscribe(x => this.ActiveUser = x);
        this.refresh();
        this.service.detectOnSaveChanges.subscribe((isUpdate) => {
            if(isUpdate) {
                const obj = JSON.parse(JSON.stringify(this.SelectedItem));
                this.undoStack.push(obj);
            }
        })

    }
    ngOnDestroy(): void {
        if (this.ClipboardChanged) {
            this.ClipboardChanged.unsubscribe();
            this.ClipboardChanged = null;
        }
        if (this.UserChanged) {
            this.UserChanged.unsubscribe();
            this.UserChanged = null;
        }
    }

    refresh() {
        this.checkHasChanges().then(x => {
            if (x) {
                LayoutService.Loading.next(true);
                this.SearchValue = null;
                this.EditMode = false;
                this.ListItems = [];
                this.Groups = [];
                this.UpdateFiltered();
                this.selectedNode = null;
                this.setSelectedItem(null);
                this.setHasChanges(false);
                this.service.GetWorkflowGroups(this.InitArgs.IsService).subscribe(x => {
                    if (x) {
                        this.Groups = x;
                    }
                    const getFunc = this.InitArgs.IsService ? this.service.GetAllServiceWorkflows() : this.service.GetAllWorkflowTemplates();
                    getFunc.subscribe(result => {
                        if (result) {
                            result.forEach(x => this.checkItemStyle(x));
                            this.ListItems = result;
                            this.UpdateFiltered();
                        }
                        LayoutService.Loading.next(false);
                        this.cdRef.detectChanges();
                    });
                });
            }
        });
    }

    checkItemStyle(data) {
        if (data) {
            const style = {};
            if (data.IsCapsule) {
                style['font-style'] = 'italic';
            }
            if (data.IsOverridden) {
                style['font-weight'] = 'bold';
            }
            if (data.CanEditInfo) {
                if (data.CanEditInfo.CanEdit) {
                    if (this.ActiveUser && data.CanEditInfo.EditUserID == this.ActiveUser.SID) {
                        style['text-decoration'] = 'underline';
                    }
                } else {
                    style['text-decoration'] = 'line-through';
                }
            }
            data.Style = style;
        }
    }

    onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.checkHasChanges().then(check => {
                if (check) {
                    LayoutService.Loading.next(true);
                    this.selectedNode = selection;
                    this.setSelectedItem(null);
                    if (selection) {
                        if (selection.Id) {
                            this.service.LoadWorkflow(selection.Id).subscribe(result => {
                                if (result) {
                                    const wf = plainToClass(WorkflowSaveObject, result);
                                    this.setSelectedItem(wf);
                                    this.EditMode = this.ActiveUser && selection.CanEditInfo && selection.CanEditInfo.EditUserID == this.ActiveUser.SID;
                                }
                                LayoutService.Loading.next(false);
                            });
                        }
                    }
                }
            });
        }
        this.resetStack();
    }

    private setSelectedItem(item) {
        this.EditMode = false;
        this.SelectedItem = item;
        this.cdRef.detectChanges();
        this.updateCaption();
    }

    private updateCaption() {
        let caption = TranslateHelper.TranslatorInstance.instant('@@Details');
        if (this.SelectedItem) {
            caption += ' - ' + TranslatedString.GetTranslation(this.SelectedItem.Caption);
        }
        this.Caption = caption;
    }

    CaptionChanged(caption) {
        if (this.SelectedItem) {
            this.SelectedItem.Caption = caption;
            this.updateCaption();
            this.OnItemChanged();
        }
    }

    OnItemChanged() {
        this.setHasChanges(true);
        const obj = JSON.parse(JSON.stringify(this.SelectedItem));
        const isExist = this.undoStack.find(x => JSON.stringify(x) == JSON.stringify(obj));
        this.undoStack.push(obj);
    }

    undoStack: any[] = [];
    redoStack: any[] = [];

    undo() {
        if (this.undoStack.length > 0) {
            let v = this.undoStack.pop();
            this.redoStack.push(v);
            this.setSelectedItem(null);
            const wf = plainToClass(WorkflowSaveObject, v);
            this.setSelectedItem(wf);
            this.EditMode = true;
        }
    }

    redo(){
        if(this.redoStack.length > 0) {
            let v = this.redoStack.pop();
            this.undoStack.push(v);
            this.setSelectedItem(null);
            const wf = plainToClass(WorkflowSaveObject, v);
            this.setSelectedItem(wf);
            this.EditMode = true;
        }
    }

    resetStack() {
        this.undoStack = [];
        this.redoStack = [];
    }

    @HostListener('document:keydown', ['$event'])
    onKeyPress(event: KeyboardEvent){
        if(event.ctrlKey){
            if(event.key.toLowerCase() == 'z')
                this.undo();
            else if(event.key.toLowerCase() == 'y'){
                this.redo();
            }
        }
    }

    private checkHasChanges(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            if (this.HasChanges) {
                if (this.selectedNode && this.selectedNode.CanEditInfo && !this.selectedNode.CanEditInfo.CanEdit) {
                    resolve(true);
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                        new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question).then(retVal => {
                            if (retVal === MessageBoxResult.Yes) {
                                if (this.EditMode) {
                                    this.save(false);
                                    resolve(true);
                                } else {
                                    this.setEditMode(false).then(x => {
                                        resolve(x);
                                    });
                                }
                            } else if (retVal === MessageBoxResult.Abort) {
                                resolve(false);
                            } else { // No
                                this.setHasChanges(false);
                                resolve(true);
                            }
                        });
                }
            } else {
                resolve(true);
            }
        });
    }

    private setHasChanges(hasChanges) {
        if (typeof hasChanges === 'boolean') {
            this.HasChanges = hasChanges;
            LayoutService.PreventBrowserClose.next(this.HasChanges);
        }
    }

    save(fromSaveButton: boolean) {
        const sel = this.SelectedItem;
        if(sel?.Caption?.DefaultValue.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        if (sel) {
            LayoutService.Loading.next(true);
            const saveFunc = this.InitArgs.IsService ? this.service.SaveWorkflowToChangeLog(sel) : this.service.SaveWorkflowTemplateToChangeLog(sel);
            saveFunc.subscribe(result => {
                if (result) {
                    this.setHasChanges(false);
                    const text = new TranslateFormatText('@@Workflow \'{0}\' saved successfully.');
                    text.FormatParams.push(result.Name);
                    NotificationHelper.Success(text, new TranslateFormatText('@@Workflow speichern'));
                    let sort = false;
                    let index = -1;
                    if (this.ListItems.some((x, i) => {
                        if (x.Id === result.Id) {
                            index = i;
                            sort = x.Name != result.Name;                            
                            return true;
                        }
                        return false;
                    })) {
                        this.ListItems.splice(index, 1, result);                        
                    } else { // neu
                        sort = true;
                        sel.SID = result.Id;
                        sel.Version = result.Version;
                        this.ListItems.push(result);
                    }
                    this.checkItemStyle(result);
                    if (sort) {
                        ArrayHelpers.sortAlphabetical(this.ListItems, 'Name');
                    }
                    this.UpdateFiltered();                    
                    if (fromSaveButton) {
                        this.selectedNode = result;
                    }
                    this.toggleWindow = true;
                } else {
                    NotificationHelper.Error(new TranslateFormatText('@@AlreadySavedByAdmin'),
                        new TranslateFormatText('@@Workflow speichern'));
                }
                LayoutService.Loading.next(false);
            });
        }
    }

    UpdateFiltered() {
        this.Unsorted.Children = [];
        const rootNodes = [];
        if (this.ListItems) {
            let list = [];
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.ListItems.filter(x => x.Name.toLowerCase().indexOf(toLower) > -1);
            } else {
                list = this.ListItems;
            }
            const grouping = {};
            list.forEach(x => {
                let rootNode;
                if (x.Grouping) {
                    rootNode = grouping[x.Grouping];
                    if (!rootNode) {
                        this.Groups.some(group => {
                            if (group.Id == x.Grouping) {
                                rootNode = {
                                    Name: group.Name,
                                    Id: group.Id,
                                    Children: [],
                                    IsExpanded: true
                                };
                                grouping[x.Grouping] = rootNode;
                                return true;
                            }
                            return false;
                        });
                    }
                }
                if (rootNode) {
                    rootNode.Children.push(x);
                } else {
                    this.Unsorted.Children.push(x);
                }
            });
            Object.keys(grouping).forEach(x => {
                rootNodes.push(grouping[x]);
            });
        }
        this.RootNodes = rootNodes;
    }

    setEditMode(fromButton) {
        return new Promise<boolean>(resolve => {
            if (this.SelectedItem) {
                this.service.RequestWorkflowLock(this.SelectedItem, this.InitArgs.IsService).subscribe(x => {
                    if (x && x.CanEditInfo) {
                        let sort = false;
                        let index = -1;
                        if (this.ListItems.some((y, i) => {
                            if (x.Id === y.Id) {
                                index = i;
                                sort = x.Name != y.Name;
                                return true;
                            }
                            return false;
                        })) {
                            this.ListItems.splice(index, 1, x);
                            if (sort) {
                                ArrayHelpers.sortAlphabetical(this.ListItems, 'Name');
                            }
                            this.UpdateFiltered();                            
                            this.checkItemStyle(x);
                            if (fromButton) {
                                this.selectedNode = x;
                            }
                        }
                        if (x.CanEditInfo.CanEdit) {
                            this.EditMode = true;
                            this.setHasChanges(false);
                            const text = new TranslateFormatText('@@Workflow \'{0}\' saved successfully.');
                            text.FormatParams.push(x.Name);
                            NotificationHelper.Success(text, new TranslateFormatText('@@Workflow speichern'));
                            resolve(true);
                        } else {
                            const text = new TranslateFormatText(x.CanEditInfo.NewVersion ? '@@AlreadySavedBy{0}' : '@@LockedBy{0}');
                            text.FormatParams.push(x.CanEditInfo.EditUserName);
                            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                                MessageBoxButtons.Ok, MessageBoxIcon.Information);
                            resolve(false);
                        }
                        this.cdRef.detectChanges();
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    addItem() {
        this.checkHasChanges().then(check => {
            if (check) {
                this.selectedNode = null;
                this.setSelectedItem(null);
                const workflow = new WorkflowSaveObject();
                workflow.Caption = new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer Workflow'));
                this.setSelectedItem(workflow);
                this.EditMode = true;
            }
        });
    }

    copyItem() {
        if (this.SelectedItem) {
            if (ClipboardHelper.Inititalized) {
    
                const json = JSON.stringify(this.SelectedItem);
                let entry = {
                    type: this.CopyType,
                    content: deserialize(WorkflowSaveObject, json)
                }
                ClipboardHelper.CopyToClipboard(entry);
                NotificationHelper.Info("@@CopyToClipboard", "@@ElementCopiedToClipboard");
            }else {
                NotificationHelper.Info("@@Clipboard Permissions not granted","@@ClipboardPermission");
            }
        }
    }

    pasteItem() {
        this.checkHasChanges().then(check => {
            if (check) {
                this.selectedNode = null;
                this.setSelectedItem(null);
                const json = serialize(ClipboardHelper.ClipboardContent.content);
                this.pasteCopy(json);
            }
        });
    }

    duplicateItem() {
        this.checkHasChanges().then(check => {
            if (check && this.SelectedItem) {
                const copy = deserialize(WorkflowSaveObject, JSON.stringify(this.SelectedItem));
                delete copy['SID'];
                delete copy['Version'];
                delete copy['ObjectStatus'];
                if (copy.Caption) {
                    copy.Caption.DefaultValue += ' - (Copy)';
                    if (copy.Caption.SerializedTranslations) {
                        copy.Caption.SerializedTranslations.forEach(x => {
                            x.Value += ' - (Copy)';
                        });
                    }
                }
                setTimeout(() => {
                    this.setSelectedItem(copy);
                    this.setHasChanges(true);
                    this.EditMode = true;
                }, 100);
                // console.log('josn', json)
                // this.pasteCopy(json);
            }
        });
    }

    private pasteCopy(json) {
        if (json) {
            const copy = deserialize(WorkflowSaveObject, json);
            delete copy['SID'];
            delete copy['Version'];
            delete copy['ObjectStatus'];
            if (copy.Caption) {
                copy.Caption.DefaultValue += ' - (Copy)';
                if (copy.Caption.SerializedTranslations) {
                    copy.Caption.SerializedTranslations.forEach(x => {
                        x.Value += ' - (Copy)';
                    });
                }
            }
            setTimeout(() => {
                this.setSelectedItem(copy);
                this.setHasChanges(true);
                this.EditMode = true;
            }, 100);
        }
    }

    onDeleteItem(wf) {
        this.selectedNode !== wf;
        this.deleteItem();
    }

    deleteItem() {
        const sel = this.selectedNode;
        if (sel && !this.EditMode) {
            if (sel.CanEditInfo && !sel.CanEditInfo.CanEdit) {
                return;
            }
            const tft = new TranslateFormatText('@@Are you sure you want to delete workflow \'{0}\'?');
            tft.FormatParams.push(sel.Name);
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Workflow loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x == MessageBoxResult.Yes) {
                        this.deleteItemInternal(sel, false);
                    }
                });
        }
    }

    private deleteItemInternal(sel, ignoreVersions: boolean) {
        this.service.TryDeleteWorkflow(sel.Id, this.InitArgs.IsService, ignoreVersions).subscribe(result => {
            if (result) {
                if (result.Deleted) {
                    this.setHasChanges(false);
                    this.refresh();
                    const success = new TranslateFormatText('@@Workflow \'{0}\' successfully deleted.');
                    success.FormatParams.push(sel.Name);
                    NotificationHelper.Success(success, '@@Workflow loeschen');
                } else if (result.IsLocked) {
                    const text = new TranslateFormatText('@@LockedBy{0}');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@LockedObject'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else if (result.MultipleVersions) {
                    const text = new TranslateFormatText('@@MultipleVersionsInfoText');
                    text.FormatParams.push(result.LockedBy);
                    MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@MultipleVersionsInfoHeader'),
                        MessageBoxButtons.None, MessageBoxIcon.Information, 500, null, [
                        { Caption: '@@DeleteAllVersions', ID: 0 },
                        { Caption: '@@GoToVersionsOverview', ID: 1 },
                        { Caption: '@@Cancel', ID: 2 }
                    ]).then(result => {
                        if (result == 0) {
                            this.deleteItemInternal(sel, true);
                        } else if (result == 1) {
                            const navStruct = SideNavService.SelectedNavigationStructure.getValue();
                            if (navStruct) {
                                this.router.navigateByUrl(navStruct + VersioningOverview.Path);
                            }
                        }
                    });
                }
            }
        });
    }

    DescriptionChanged(description) {
        if (this.SelectedItem) {
            this.SelectedItem.Description = description;
            this.OnItemChanged();
        }
    }

    ShowUsage() {
        if (this.SelectedItem) {
            ServiceWorkflowUsageDialog.ShowDialog(this.SelectedItem.SID);
        }
    }

    OnContentChanged() {
        if (this.SelectedItem) {
            this.OnItemChanged();
        }
    }

    onOptionChange() {
        const sel = this.SelectedItem;
        if (sel && sel.Grouping == '$$NewEntry$$') {
            sel.Grouping = null;
            EditWorkflowGroupDialog.NewWorkflowGroup(this.InitArgs.IsService, (x) => {
                this.Groups.push(x);
                ArrayHelpers.sortAlphabetical(this.Groups, 'Name');
                sel.Grouping = x.Id;
            });
        }
    }

    EditGroup(id) {
        EditWorkflowGroupDialog.EditWorkflowGroup(id, (x) => {
            const group = this.Groups.find(y => y.Id == x.Id);
            if (group) {
                group.Name = x.Name;
                ArrayHelpers.sortAlphabetical(this.Groups, 'Name');
                this.UpdateFiltered();
            }
        });
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
