import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { DialogHelper } from '../../../helpers/injector.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { MetaHelper } from '../../../helpers/meta.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { Layout } from '../../../models/layout.model';
import { LayoutElement } from '../../../models/layoutelement.model';
import { DataService } from '../../../services/data.service';
import { DataModelService } from '../../../services/datamodel.service';
import { PROPERTYGROUPS, PROPERTYPANELS, REGISTRY } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { SideNavService } from '../../../services/sidenav.service';
import { MessageBoxHelper } from '../../dialogs/messagebox/messagebox.dialog';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { UnitType } from '../../../models/enums/unittype.enum';

export class LayoutWizardInitArgs {
    Page: any;
    CheckURL = false;
    ShowCancel = false;
}

@Component({
    selector: 'layout-wizard-dialog',
    templateUrl: './layout.wizard.dialog.html',
    styleUrls: ['./layout.wizard.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutWizardDialog {

    SelectedAction = 'empty';
    Pages;
    SelectedPage;
    Models;
    DynamicTables;

    ListLayout;
    ListBox;

    TableLayout;
    Table;

    ActLayout;

    TableProperties;
    FlatTableProperties;
    Entries;
    Details;

    Page;
    CheckURL = false;
    ShowCancel = false;

    static ShowDialog(page: LayoutWizardInitArgs, handler?) {

        const config = new MatDialogConfig();
        config.data = page;
        config.width = '800px';
        const diaRef = DialogHelper.DialogInstance.open(LayoutWizardDialog, config);
        if (handler) {
            diaRef.afterClosed().subscribe(x => {
                handler(x);
            });
        }
    }

    constructor(private dialogRef: MatDialogRef<LayoutWizardDialog>, @Inject(MAT_DIALOG_DATA) public data: LayoutWizardInitArgs,
        private service: MetaService, private dataModelService: DataModelService, private dataService: DataService) {
        dialogRef.disableClose = true;
        this.Page = data.Page;
        this.CheckURL = data.CheckURL;
        this.ShowCancel = data.ShowCancel;
    }

    cancel() {
        this.dialogRef.close(null);
    }

    tryClose(layout) {
        if (this.CheckURL) {
            if (this.Page.URL) {
                LayoutHelper.checkURL(this.Page);
                if (SideNavService.IsSystemURL(this.Page.URL)) {
                    const text = new TranslateFormatText('@@Die angegebene URL gleicht einer System-URL. Bitte aendern Sie die URL.');
                    const title = new TranslateFormatText('@@Ungueltige URL');
                    MessageBoxHelper.ShowDialog(text, title, MessageBoxButtons.Ok, MessageBoxIcon.Information);
                } else {
                    this.service.GetAllPages().subscribe(pages => {
                        const toLower = this.Page.URL.toLowerCase();
                        if (pages && pages.some(p => p.URL.toLowerCase() === toLower)) {
                            const text = '@@Es existiert bereits eine Seite zu der angegebenen URL. Bitte aendern Sie die URL.';
                            const tText = new TranslateFormatText(text);
                            const title = new TranslateFormatText('@@Ungueltige URL');
                            MessageBoxHelper.ShowDialog(tText, title, MessageBoxButtons.Ok, MessageBoxIcon.Information);
                        } else {
                            this.Page.Layout = layout;
                            this.dialogRef.close(this.Page);
                        }
                    });
                }
            } else {
                const text = new TranslateFormatText('@@Bitte vergeben Sie eine URL.');
                const title = new TranslateFormatText('@@Ungueltige URL');
                MessageBoxHelper.ShowDialog(text, title, MessageBoxButtons.Ok, MessageBoxIcon.Information);
            }
        } else {
            this.Page.Layout = layout;
            this.dialogRef.close(this.Page);
        }
    }

    onActionChanged() {
        switch (this.SelectedAction) {
            case 'copy':
                if (!this.Pages) {
                    this.service.GetAllPages().subscribe(r => {
                        if (r) {
                            r.forEach(x => {
                                x.Caption += ' (' + x.URL + ')';
                            });
                            r.sort((a, b) => {
                                if (a.Caption < b.Caption) {
                                    return -1;
                                }
                                if (a.Caption > b.Caption) {
                                    return 1;
                                }
                                return 0;
                            });
                            this.Pages = r;
                        }
                    });
                }
                break;
            case 'listdetail':
                if (!this.ListLayout) {
                    this.ListLayout = plainToClass(Layout, this.GetListLayout());
                    this.ListBox = this.ListLayout.Elements[1];
                }
                this.ActLayout = this.ListLayout;
                if (!this.Models) {
                    this.dataModelService.GetModels().subscribe(models => {
                        this.Models = models;
                    });
                }
                break;
            case 'tabledetail':
                if (!this.TableLayout) {
                    this.TableLayout = plainToClass(Layout, this.GetTableLayout());
                    this.Table = this.TableLayout.Elements[0].Elements[1];
                }
                this.ActLayout = this.TableLayout;
                if (!this.Models) {
                    this.dataModelService.GetModels().subscribe(models => {
                        this.Models = models;
                    });
                }
                break;
        }
        LayoutWizardDialog.setParents(this.ActLayout);
    }

    private static setParents(layout) {
        if (layout && layout.Elements) {
            layout.Elements.forEach(child => {
                child.Parent = layout;
                LayoutWizardDialog.setParents(child);
            });
        }
    }

    checkTableProps() {
        this.TableProperties = null;
        this.FlatTableProperties = null;
        this.Entries = null;
        this.Details = null;
        this.DynamicTables = null;
        if (this.ActLayout && this.ActLayout.DataModelID) {
            this.dataService.GetItem('dynamic', 'GetTablesByDataModel', this.ActLayout.DataModelID).subscribe((data) => {
                if (data) {
                    this.DynamicTables = data;
                    if (this.ActLayout.DataSource && data.some(x => x.SID === this.ActLayout.DataSource)) {
                        this.DataSourceSelected();
                    }
                }
            });
        }
    }

    DataSourceType = 2;
    dstype = [
        { Caption: 'None', Value: null },
        { Caption: 'Static', Value: 0 },
        { Caption: 'Container', Value: 1 },
        { Caption: 'Dynamic', Value: 2 },
    ]

    DataSourceTypeSelected(event) {
        const type = event.value;
        if(type == 0) {
            this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((data) => {
            });
        }
        if(type == 1 || type == 2) {
            this.dataModelService.GetModels().subscribe(models => {
                this.Models = models;
            });
        }


    }

    DataModelSelected() {
            this.TableProperties = null;
            this.FlatTableProperties = null;
            this.Entries = null;
            this.Details = null;
            if (this.ActLayout && this.ActLayout.DataModelID) {
                if(this.DataSourceType == 2) {
                    this.dataService.GetItem('dynamic', 'GetTablesByDataModel', this.ActLayout.DataModelID).subscribe((data) => {
                        this.DynamicTables = data;
                    });
                }
                else {
                    this.dataService.GetItem('dynamic', 'GetNonCCOContainersByDataModel', this.ActLayout.DataModelID).subscribe((data) => {
                        this.DynamicTables = data;
                    });
                }
            }
    }

    DataSourceSelected() {
        const item = this.SelectedAction === 'listdetail' ? this.ListBox : this.Table;
        MetaHelper.FindTableProperties(this.ActLayout, item).then((result) => {
            if (result) {
                this.TableProperties = result;
                this.Entries = {};
                this.Details = {};
                for (let i = 0; i < result.Fields.length; i++) {
                    const column = result.Fields[i];
                    const retVal = {
                        Visible: true,
                        ValidControls: MetaHelper.GetValidControls(column),
                        Position: i + 1,
                        Expanded: false,
                        Key: column.ID
                    };
                    this.Entries[column.ID] = retVal;
                    this.Details[column.ID] = new LayoutElement();
                    this.Details[column.ID].Editable = true;
                    this.Details[column.ID].DataSource = column.ID;
                    this.Details[column.ID].Name = column.TranslatedCaption;
                    this.Details[column.ID].Placeholder = column.TranslatedCaption;
                    this.Details[column.ID].DataSourceID = column.AdvancedType !== '00000000-0000-0000-0000-000000000000' ?
                        column.AdvancedType : column.Type;
                    if (column.Type === '876cf188-243b-49ed-91ab-b2cf27216a30') {
                        this.Details[column.ID].IsEnum = true;
                    } else {
                        delete this.Details[column.ID].IsEnum;
                    }
                    if (column.IsList) {
                        this.Details[column.ID].Multiple = true;
                    } else {
                        this.Details[column.ID].Multiple = false;
                    }
                    if (column.IsShared && !column.IsReverse) {
                        if (!this.Details[column.ID].ValueMemberPath) {
                            if (column.KeyColumns && column.KeyColumns.length > 0) {
                                if (column.KeyColumns.length === 1) {
                                    this.Details[column.ID].ValueMemberPath = column.KeyColumns[0];
                                }
                            } else {
                                this.Details[column.ID].ValueMemberPath = '_Id';
                            }
                        }
                    } else {
                        this.Details[column.ID].ValueMemberPath = null;
                    }
                    this.Details[column.ID].IsReverse = column.IsReverse;
                }
            }
        });
        MetaHelper.FindFlatTableProperties(this.ActLayout, item).then((result) => {
            if (result) {
                this.FlatTableProperties = result;
            }
        });
    }

    elementTypeChanged(id) {
        if (this.Details && this.Entries) {
            const layout = this.Details[id];
            const entry = this.Entries[id];
            if (entry) {
                entry.GenericSettings = null;
                entry.ElementType = null;
                if (layout && layout.ElementType) {
                    entry.ElementType = layout.ElementType;
                    const compReg = REGISTRY[layout.ElementType];
                    if (compReg && compReg.Panel) {
                        if (PROPERTYPANELS && PROPERTYPANELS.indexOf(compReg.Panel) == -1) {
                            PROPERTYPANELS.push(compReg.Panel);
                            compReg.Panel.InitPanel();
                            LayoutService.PropertyPanelsChanged.next(null);
                        }
                        const panel = PROPERTYGROUPS.find((val) => compReg.Panel.SIDS && compReg.Panel.SIDS.indexOf(val.SID) > -1);
                        if (panel) {
                            const item: any = {
                                SID: panel.SID ? panel.SID : UUID.UUID(),
                                ID: panel.ID,
                                Caption: panel.Caption,
                                Visible: false,
                                PropertyGroup: panel,
                                Index: panel.Index,
                                Badge: panel.Badge,
                                BadgeSub: panel.BadgeSub
                            };
                            if (panel.Content) {
                                item.Content = new ComponentPortal(panel.Content);
                                item.Visible = true;
                            }
                            if (typeof panel.CheckOnChange === 'function') {
                                item.CheckOnChange = panel.CheckOnChange;
                            }
                            entry.GenericSettings = item;
                            entry.ElementType = null;
                        }
                    }
                }
            }
        }
    }

    onFieldDrop(ev) {
        if (ev && this.TableProperties) {
            if (ev.currentIndex !== ev.previousIndex) {
                moveItemInArray(this.TableProperties.Fields, ev.previousIndex, ev.currentIndex);
                this.TableProperties.Fields.forEach((x, i) => {
                    this.Entries[x.ID].Position = i + 1;
                });
            }
        }
    }

    okClick() {
        switch (this.SelectedAction) {
            case 'empty':
                const layout = new Layout();
                layout['ElementType'] = 'grid';
                layout['Name'] = 'Base';
                this.tryClose(layout);
                break;
            case 'copy':
                if (this.SelectedPage) {
                    this.service.LoadPage(this.SelectedPage).subscribe(r => {
                        if (r) {
                            delete r['_Id'];
                            this.tryClose(r);
                        } else {
                            const text = new TranslateFormatText('@@Layout mit URL {0} konnte nicht gefunden werden.');
                            text.FormatParams.push(this.SelectedPage);
                            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Fehler'),
                                MessageBoxButtons.Ok, MessageBoxIcon.Error);
                        }
                    });
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte waehlen Sie eine Seite zum Kopieren aus.'),
                        new TranslateFormatText('@@Seite kopieren'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                }
                break;
            case 'listdetail':
                if (this.ListLayout) {
                    if (this.ListLayout.DataModelID && this.ListLayout.DataSource) {
                        if (this.Entries) {
                            const keys = Object.keys(this.Entries);
                            const toSort = [];
                            keys.forEach((key) => {
                                const val = this.Entries[key];
                                if (val.Visible) {
                                    toSort.push(val);
                                }
                            });
                            const res = toSort.sort((a, b) => a.Position > b.Position ? 1 : -1);
                            res.forEach((col) => {
                                const detail = this.Details[col['Key']];
                                if (detail.ElementType) {
                                    if (detail.ElementType === 'combobox') {
                                        const height = new LayoutUnit();
                                        height.Type = UnitType.Pixel;
                                        height.Value = 40;
                                        detail.Height = height;
                                    }
                                    this.ListLayout.Elements[2].Elements[1].Elements.push(detail);
                                }
                            });
                        }
                        this.tryClose(this.ListLayout);
                    } else {
                        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte waehlen Sie einen Container aus.'),
                            new TranslateFormatText('@@ListDetail'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    }
                }
                break;
            case 'tabledetail':
                if (this.TableLayout) {
                    if (this.TableLayout.DataModelID && this.TableLayout.DataSource) {
                        if (this.Table && this.FlatTableProperties && this.FlatTableProperties.Columns) {
                            if (!this.Table.Columns) {
                                this.Table.Columns = [];
                            }
                            if (!this.Table.Columns.some(x => x.Name == '_Id')) {
                                const idCol = this.FlatTableProperties.Columns.find(x => x.Name == '_Id');
                                if (idCol) {
                                    idCol['Caption'] = idCol['Name'];
                                    idCol['IsVisible'] = false;
                                    idCol['FetchData'] = true;
                                    this.Table.Columns.splice(0, 0, idCol);
                                }
                            }
                        }
                        if (this.Entries) {
                            const keys = Object.keys(this.Entries);
                            const toSort = [];
                            keys.forEach((key) => {
                                const val = this.Entries[key];
                                if (val.Visible) {
                                    toSort.push(val);
                                }
                            });
                            const res = toSort.sort((a, b) => a.Position > b.Position ? 1 : -1);
                            res.forEach((col) => {
                                const detail = this.Details[col['Key']];
                                if (detail.ElementType) {
                                    if (detail.ElementType === 'combobox') {
                                        const height = new LayoutUnit();
                                        height.Type = UnitType.Pixel;
                                        height.Value = 40;
                                        detail.Height = height;
                                    }
                                    this.TableLayout.Elements[1].Elements[1].Elements.push(detail);
                                }
                            });
                        }
                        this.tryClose(this.TableLayout);
                    } else {
                        MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte waehlen Sie einen Container aus.'),
                            new TranslateFormatText('@@TableDetail'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    }
                }
                break;
        }
    }
    GetListLayout() {
        return {
            'Visible': true,
            '_Column': 1,
            '_Row': 1,
            'Events': [{
                'Handlers': [],
                'EventID': 'OnInit'
            }, {
                'Handlers': [],
                'EventID': 'OnDestroy'
            }
            ],
            'Elements': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [{
                    'Handlers': [],
                    'EventID': 'OnInit'
                }, {
                    'Handlers': [],
                    'EventID': 'OnDestroy'
                }
                ],
                'Elements': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [{
                        'Handlers': [{
                            'ActionType': 0,
                            'Action': '20c0d4ed-4f3d-606c-cf15-111ee2462890'
                        }
                        ],
                        'EventID': 'click'
                    }
                    ],
                    'Elements': [],
                    'ElementType': 'button',
                    '_Editable': true,
                    'ID': 'dcec2872-28ab-3bc0-73b8-1b12fd4dfe30',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Name': 'Add',
                    'Caption': 'Add',
                    'ButtonType': 'mat-icon-button',
                    'Icon': 'add'
                }
                ],
                '_ColSpan': 1,
                '_RowSpan': 1,
                'ElementType': 'flex',
                '_Editable': true,
                'ID': 'dce48bdb-3646-9c2a-d3e8-7222e3e55ba9',
                'Width': {},
                'Height': {},
                'Margin': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Padding': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Orientation': 0,
                'Name': 'listAction'
            }, {
                'Visible': true,
                '_Column': 1,
                '_Row': 2,
                'Events': [{
                    'Handlers': [{
                        'ActionType': 0,
                        'Action': '64b9bfd7-9f52-a45d-745e-f0033b8764e3'
                    }
                    ],
                    'EventID': 'selectionchanged'
                }, {
                    'Handlers': [],
                    'EventID': 'OnFocus'
                }
                ],
                'Elements': [],
                '_ColSpan': 1,
                '_RowSpan': 1,
                'ElementType': 'listbox',
                'ID': '46270580-5a2c-0a34-0532-3f1c6149149f',
                'Width': {
                    'Value': 398,
                    'Type': 0
                },
                'Height': {},
                'Margin': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Padding': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Name': 'List',
                'Order': 0,
                'Border': {
                    'LeftBorder': {
                        'Thickness': {
                            'Value': 1,
                            'Type': 0
                        },
                        'Style': 0
                    },
                    'TopBorder': {
                        'Thickness': {
                            'Value': 1,
                            'Type': 0
                        },
                        'Style': 0
                    },
                    'RightBorder': {
                        'Thickness': {
                            'Value': 1,
                            'Type': 0
                        },
                        'Style': 0
                    },
                    'BottomBorder': {
                        'Thickness': {
                            'Value': 1,
                            'Type': 0
                        },
                        'Style': 0
                    }
                }
            }, {
                'Visible': false,
                '_Column': 2,
                '_Row': 2,
                'Events': [{
                    'Handlers': [],
                    'EventID': 'OnInit'
                }, {
                    'Handlers': [],
                    'EventID': 'OnDestroy'
                }
                ],
                'Elements': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 2,
                    'Events': [{
                        'Handlers': [],
                        'EventID': 'OnInit'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnDestroy'
                    }
                    ],
                    'Elements': [{
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': '8dd55b0b-025a-055c-cbde-e56bc9d4d7bf'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '399f1075-a49a-3e31-60d7-1af5b669101f',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Delete',
                        'ButtonType': 'mat-raised-button',
                        'Caption': 'Delete'
                    }, {
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': 'a3905fb9-5d63-afd9-1bcf-b38adb82daf2'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '30fa6d94-11e3-aaad-7bc5-62b32dfbddeb',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Save',
                        'Caption': 'Save',
                        'ButtonType': 'mat-raised-button'
                    }, {
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': 'f1de230d-618d-f800-a950-cc601184c7e6'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '1350632a-1cd4-01a5-d4a2-9092aaa2f2f1',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Cancel',
                        'Caption': 'Cancel',
                        'ButtonType': 'mat-raised-button'
                    }
                    ],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'flex',
                    '_Editable': true,
                    'ID': 'cc59a603-b0c9-41a6-64ee-93b979e018bc',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Orientation': 0,
                    'Name': 'Actions'
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [{
                        'Handlers': [],
                        'EventID': 'OnInit'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnDestroy'
                    }
                    ],
                    'Elements': [],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'flex',
                    '_Editable': true,
                    'ID': '3b9813b2-da60-016f-4fa7-8611a2a71971',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Name': 'Data',
                    'Orientation': 1,
                    'Scrollable': true,
                }
                ],
                '_ColSpan': 1,
                '_RowSpan': 1,
                'ElementType': 'grid',
                '_Editable': true,
                'ID': '5774a459-3667-b4c1-402c-f83dd62d4116',
                'ColumnDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 1,
                        'Value': 100
                    }
                }
                ],
                'RowDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 5,
                        'Value': 1
                    }
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Value': 50,
                        'Type': 0
                    }
                }
                ],
                'Width': {},
                'Height': {},
                'Margin': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Padding': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Name': 'Detail'
            }
            ],
            'Resolutions': [],
            'Workflows': [{
                'Caption': 'Add',
                'ID': '20c0d4ed-4f3d-606c-cf15-111ee2462890',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 267,
                        'YPos': 255,
                        'Module': 'AddDataWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 362,
                        'YPos': 494,
                        'Module': 'setMultiStyleWFModule',
                        'Settings': {
                            'Styles': [{
                                'Elements': [{
                                    'ElemName': 'Detail'
                                }],
                                'Layout': {
                                    'Visible': true
                                }
                            }]
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }
                    ]
                }
            }, {
                'Caption': 'StartVisibility',
                'ID': 'f1de230d-618d-f800-a950-cc601184c7e6',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 362,
                        'YPos': 296,
                        'Module': 'removeMultiStyleWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Detail'
                            }]
                        }
                    }
                    ],
                    'Connectors': []
                }
            }, {
                'Caption': 'Save',
                'ID': 'a3905fb9-5d63-afd9-1bcf-b38adb82daf2',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 240,
                        'YPos': 340,
                        'Module': 'SaveDataWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 2,
                        'XPos': 261,
                        'YPos': 538,
                        'Module': 'RefreshWFModule',
                        'Settings': {
                            'ElemName': 'List'
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 2,
                        'ExitModule': 0,
                        'ExitOption': 2
                    }
                    ]
                }
            }, {
                'Caption': 'Delete',
                'ID': '8dd55b0b-025a-055c-cbde-e56bc9d4d7bf',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 499,
                        'YPos': 192,
                        'Module': 'DeleteDataWFWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 522,
                        'YPos': 363,
                        'Module': 'RefreshWFModule',
                        'Settings': {
                            'ElemName': 'List'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 2,
                        'XPos': 575,
                        'YPos': 556,
                        'Module': 'removeMultiStyleWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Detail'
                            }]
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 2,
                        'ExitModule': 1,
                        'ExitOption': 0
                    }
                    ]
                }
            }, {
                'Caption': 'SetValue',
                'ID': '64b9bfd7-9f52-a45d-745e-f0033b8764e3',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 367,
                        'YPos': 124,
                        'Module': 'setMultiElemValueWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Data',
                                'GetFromState': true
                            }]
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 490,
                        'YPos': 363,
                        'Module': 'setMultiStyleWFModule',
                        'Settings': {
                            'Styles': [{
                                'Elements': [{
                                    'ElemName': 'Detail'
                                }],
                                'Layout': {
                                    'Visible': true
                                }
                            }]
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }
                    ]
                }
            }
            ],
            'ID': 'b70ec9c0-4b6c-7268-0a9a-24417314ec1a',
            'ElementType': 'grid',
            '_Editable': true,
            'ColumnDefinitions': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 0,
                    'Value': 400
                }
            }, {
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 5,
                    'Value': 1
                }
            }
            ],
            'RowDefinitions': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 0,
                    'Value': 50
                }
            }, {
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 5,
                    'Value': 1
                }
            }
            ],
            'Width': {},
            'Height': {},
            'Margin': {
                'Left': {},
                'Right': {},
                'Top': {},
                'Bottom': {}
            },
            'Padding': {
                'Left': {
                    'Type': 0,
                    'Value': 20
                },
                'Right': {
                    'Type': 0,
                    'Value': 20
                },
                'Top': {
                    'Type': 0,
                    'Value': 20
                },
                'Bottom': {
                    'Type': 0,
                    'Value': 20
                }
            },
            'Name': 'Base',
            'DataFilter': {
                'Filters': [],
                'Sort': [{
                    'Name': 'Nachname',
                    'Order': 0
                }
                ],
                'Columns': [],
                'StartRow': 0,
                'EndRow': 100
            }
        };
    }
    GetTableLayout() {
        return {
            'Visible': true,
            '_Column': 1,
            '_Row': 1,
            'Events': [{
                'Handlers': [],
                'EventID': 'OnInit'
            }, {
                'Handlers': [],
                'EventID': 'OnDestroy'
            }
            ],
            'Elements': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [{
                    'Handlers': [],
                    'EventID': 'OnInit'
                }, {
                    'Handlers': [],
                    'EventID': 'OnDestroy'
                }
                ],
                'Elements': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [{
                        'Handlers': [],
                        'EventID': 'OnInit'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnDestroy'
                    }
                    ],
                    'Elements': [{
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': '7061fbf0-0135-8330-7b74-b5fdcd4d3d72'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '6accca19-fc0f-b0cd-0071-6151be9054c8',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Add',
                        'Caption': 'Add',
                        'ButtonType': 'mat-icon-button',
                        'Icon': 'add'
                    }
                    ],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'flex',
                    '_Editable': true,
                    'ID': '43f00c67-d5b2-cd93-43f2-6c041766a02d',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Orientation': 0,
                    'Name': 'listAction'
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 2,
                    'Events': [{
                        'Handlers': [{
                            'ActionType': 0,
                            'Action': 'ea4791c1-33cb-b604-2b73-1bbe68673c8e'
                        }
                        ],
                        'EventID': 'SelectionChanged'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnFocus'
                    }
                    ],
                    'Elements': [],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'datatable',
                    'ID': '1f263113-a68d-18b0-94c3-5bad4341a3f1',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Columns': [],
                    'Name': 'Table',
                    'PagingType': 0,
                    'SelectMode': 0
                }
                ],
                'ElementType': 'grid',
                '_Editable': true,
                'ID': '359a6595-4290-2c98-ec7f-176999753a62',
                'ColumnDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 1,
                        'Value': 100
                    }
                }
                ],
                'RowDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 0,
                        'Value': 50
                    }
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 5,
                        'Value': 1
                    }
                }
                ],
                'Width': {},
                'Height': {},
                'Margin': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Padding': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Name': 'List',
                'FlexFill': true
            }, {
                'Visible': false,
                '_Column': 1,
                '_Row': 1,
                'Events': [{
                    'Handlers': [],
                    'EventID': 'OnInit'
                }, {
                    'Handlers': [],
                    'EventID': 'OnDestroy'
                }
                ],
                'Elements': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 2,
                    'Events': [{
                        'Handlers': [],
                        'EventID': 'OnInit'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnDestroy'
                    }
                    ],
                    'Elements': [{
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': '0f6a5d74-9312-7060-b492-2d34debc47b6'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '6117df21-9fd7-06ea-fda6-711f0fc57326',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Delete',
                        'Caption': 'Delete',
                        'ButtonType': 'mat-raised-button'
                    }, {
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': '209ea2dd-1bf5-a940-695f-57def88360ee'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': '95faaa0c-fa66-9380-ef67-22310d47d2e8',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Save',
                        'Caption': 'Save',
                        'ButtonType': 'mat-raised-button'
                    }, {
                        'Visible': true,
                        '_Column': 1,
                        '_Row': 1,
                        'Events': [{
                            'Handlers': [{
                                'ActionType': 0,
                                'Action': 'd5ad4779-bfd4-1bc4-9c0c-2f646ae5d013'
                            }
                            ],
                            'EventID': 'click'
                        }
                        ],
                        'Elements': [],
                        'ElementType': 'button',
                        '_Editable': true,
                        'ID': 'c87f03a8-63c8-e4b7-2dbf-d28538054134',
                        'Width': {},
                        'Height': {},
                        'Margin': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Padding': {
                            'Left': {},
                            'Right': {},
                            'Top': {},
                            'Bottom': {}
                        },
                        'Name': 'Cancel',
                        'Caption': 'Cancel',
                        'ButtonType': 'mat-raised-button'
                    }
                    ],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'flex',
                    '_Editable': true,
                    'ID': 'a48f051b-1608-421c-a0f0-2776548bf6d1',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Orientation': 0,
                    'Name': 'Actions'
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [{
                        'Handlers': [],
                        'EventID': 'OnInit'
                    }, {
                        'Handlers': [],
                        'EventID': 'OnDestroy'
                    }
                    ],
                    'Elements': [],
                    '_ColSpan': 1,
                    '_RowSpan': 1,
                    'ElementType': 'flex',
                    '_Editable': true,
                    'ID': 'cdd52d28-337a-5b27-507b-90b83023dc08',
                    'Width': {},
                    'Height': {},
                    'Margin': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Padding': {
                        'Left': {},
                        'Right': {},
                        'Top': {},
                        'Bottom': {}
                    },
                    'Orientation': 1,
                    'Name': 'Data',
                    'Scrollable': true
                }
                ],
                'ElementType': 'grid',
                '_Editable': true,
                'ID': 'bd877cfc-e8c0-fda4-287e-b9f6576a190b',
                'ColumnDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 1,
                        'Value': 100
                    }
                }
                ],
                'RowDefinitions': [{
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Type': 5,
                        'Value': 1
                    }
                }, {
                    'Visible': true,
                    '_Column': 1,
                    '_Row': 1,
                    'Events': [],
                    'Size': {
                        'Value': 50,
                        'Type': 0
                    }
                }
                ],
                'Width': {
                    'Value': 500,
                    'Type': 0
                },
                'Height': {},
                'Margin': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Padding': {
                    'Left': {},
                    'Right': {},
                    'Top': {},
                    'Bottom': {}
                },
                'Name': 'Detail'
            }
            ],
            'Resolutions': [],
            'Workflows': [{
                'Caption': 'StartVisibility',
                'ID': 'd5ad4779-bfd4-1bc4-9c0c-2f646ae5d013',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 308,
                        'YPos': 227,
                        'Module': 'removeMultiStyleWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Detail'
                            }]
                        }
                    }
                    ],
                    'Connectors': []
                }
            }, {
                'Caption': 'Add',
                'ID': '7061fbf0-0135-8330-7b74-b5fdcd4d3d72',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 352,
                        'YPos': 306,
                        'Module': 'AddDataWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 356,
                        'YPos': 496,
                        'Module': 'setMultiStyleWFModule',
                        'Settings': {
                            'Styles': [{
                                'Elements': [{
                                    'ElemName': 'Detail'
                                }],
                                'Layout': {
                                    'Visible': true
                                }
                            }]
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }
                    ]
                }
            }, {
                'Caption': 'Delete',
                'ID': '0f6a5d74-9312-7060-b492-2d34debc47b6',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 191,
                        'YPos': 46,
                        'Module': 'DeleteDataWFWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 238,
                        'YPos': 247,
                        'Module': 'RefreshWFModule',
                        'Settings': {
                            'ElemName': 'Table'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 2,
                        'XPos': 541,
                        'YPos': 392,
                        'Module': 'removeMultiStyleWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Detail'
                            }]
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 2,
                        'ExitModule': 1,
                        'ExitOption': 0
                    }
                    ]
                }
            }, {
                'Caption': 'Save',
                'ID': '209ea2dd-1bf5-a940-695f-57def88360ee',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 301,
                        'YPos': 98,
                        'Module': 'SaveDataWFModule',
                        'Settings': {
                            'ElemName': 'Data'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 242,
                        'YPos': 275,
                        'Module': 'RefreshWFModule',
                        'Settings': {
                            'ElemName': 'Table'
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 2
                    }
                    ]
                }
            }, {
                'Caption': 'SetData',
                'ID': 'ea4791c1-33cb-b604-2b73-1bbe68673c8e',
                'Data': {
                    'Modules': [{
                        'Expressions': [],
                        'ID': 0,
                        'XPos': 8,
                        'YPos': 1178,
                        'Module': 'setMultiElemValueWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Data',
                                'GetFromState': true
                            }]
                        }
                    }, {
                        'Expressions': [],
                        'ID': 1,
                        'XPos': 8,
                        'YPos': 1328,
                        'Module': 'setMultiStyleWFModule',
                        'Settings': {
                            'Styles': [{
                                'Elements': [{
                                    'ElemName': 'Detail'
                                }],
                                'Layout': {
                                    'Visible': true
                                }
                            }]
                        }
                    }, {
                        'Expressions': [],
                        'ID': 3,
                        'XPos': 466,
                        'YPos': 582,
                        'Module': 'getlistitemcalcWFModule',
                        'Settings': {
                            'IndexType': 0
                        }
                    }, {
                        'Expressions': [],
                        'ID': 4,
                        'XPos': 464,
                        'YPos': 726,
                        'Module': 'readMultiPropertyModule',
                        'Settings': {
                            'Properties': [{
                                'PropName': '_Id',
                                'UseForState': true
                            }]
                        }
                    }, {
                        'Expressions': [],
                        'ID': 5,
                        'XPos': 466,
                        'YPos': 872,
                        'Module': 'ReadDataWFDataWFWFModule',
                        'Settings': {
                            'SourceName': 'Table',
                            'DestinationName': 'Data',
                            'Filter': {
                                'ConcatOperator': 0,
                                'Filters': [{
                                    'Name': '_Id',
                                    'Value': '$Value',
                                    'Operator': 0
                                }
                                ]
                            },
                            'UseWorkflowValue': true
                        }
                    }, {
                        'Expressions': [],
                        'ID': 6,
                        'XPos': 9,
                        'YPos': 1031,
                        'Module': 'getlistitemcalcWFModule',
                        'Settings': {
                            'IndexType': 0
                        }
                    }, {
                        'Expressions': [],
                        'ID': 7,
                        'XPos': 824,
                        'YPos': 34,
                        'Module': 'setStatusWFModule',
                        'Settings': {
                            'StatusKey': 'Selection',
                            'KeepActualParam': true
                        }
                    }, {
                        'Expressions': [],
                        'ID': 8,
                        'XPos': 988,
                        'YPos': 30,
                        'Module': 'getlistcountWFModule',
                        'Settings': {}
                    }, {
                        'Expressions': [],
                        'ID': 9,
                        'XPos': 990,
                        'YPos': 166,
                        'Module': 'setStatusWFModule',
                        'Settings': {
                            'StatusKey': 'Count',
                            'KeepActualParam': true
                        }
                    }, {
                        'Expressions': [],
                        'ID': 10,
                        'XPos': 992,
                        'YPos': 312,
                        'Module': 'conditionWFModule',
                        'Settings': {
                            'DataType': 2,
                            'Settings': {
                                'Operator': 1,
                                'Value1': 1,
                                'Value2': 0
                            }
                        }
                    }, {
                        'Expressions': [],
                        'ID': 11,
                        'XPos': 466,
                        'YPos': 448,
                        'Module': 'getStatusWFModule',
                        'Settings': {
                            'StatusKey': 'Selection'
                        }
                    }, {
                        'Expressions': [],
                        'ID': 12,
                        'XPos': 1704,
                        'YPos': 470,
                        'Module': 'removeMultiStyleWFModule',
                        'Settings': {
                            'Elements': [{
                                'ElemName': 'Detail'
                            }]
                        }
                    }, {
                        'Expressions': [],
                        'ID': 13,
                        'XPos': 712,
                        'YPos': 1030,
                        'Module': 'showMessageBoxWFModule',
                        'Settings': {
                            'Text': '"Dieser Eintrag wurde nicht gefunden"',
                            'Title': '"Meldung"',
                            'Icon': 1,
                            'Buttons': 1
                        }
                    }
                    ],
                    'Connectors': [{
                        'EntryOption': 0,
                        'EntryModule': 1,
                        'ExitModule': 0,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 4,
                        'ExitModule': 3,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 5,
                        'ExitModule': 4,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 6,
                        'ExitModule': 5,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 0,
                        'ExitModule': 6,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 8,
                        'ExitModule': 7,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 9,
                        'ExitModule': 8,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 10,
                        'ExitModule': 9,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 11,
                        'ExitModule': 10,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 3,
                        'ExitModule': 11,
                        'ExitOption': 0
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 12,
                        'ExitModule': 10,
                        'ExitOption': 1
                    }, {
                        'EntryOption': 0,
                        'EntryModule': 13,
                        'ExitModule': 5,
                        'ExitOption': 1
                    }
                    ]
                }
            }
            ],
            'ID': '849bbfd5-64dc-bc4e-251d-6b706cbda67f',
            'ElementType': 'flex',
            '_Editable': true,
            'ColumnDefinitions': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 1,
                    'Value': 100
                }
            }
            ],
            'RowDefinitions': [{
                'Visible': true,
                '_Column': 1,
                '_Row': 1,
                'Events': [],
                'Size': {
                    'Type': 1,
                    'Value': 100
                }
            }
            ],
            'Width': {},
            'Height': {
                'Value': 100,
                'Type': 1
            },
            'Margin': {
                'Left': {},
                'Right': {},
                'Top': {},
                'Bottom': {}
            },
            'Padding': {
                'Left': {},
                'Right': {},
                'Top': {},
                'Bottom': {}
            },
            'DataFilter': {
                'Filters': [],
                'Sort': [{
                    'Name': 'Name',
                    'Order': 0
                }
                ],
                'Columns': [],
                'StartRow': 0,
                'EndRow': 100
            },
            'Name': 'Base',
            'ContentType': 2,
            'Orientation': 0,
            'FlexFill': true,
            '_deleteddate': '',
            '_deletedby': ''
        };
    }
}
