import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ComponentFactoryResolver, ContentChild, Directive, EventEmitter,
    Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, filter, map, mergeMap, of} from 'rxjs';
import {NavigationService} from '../../../services/navigation.service';
import {PermissionDeniedPage} from '../../pages/not-permitted.page';
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {GlobalSearchService} from "../../../services/global-search.service";
@Directive({
    selector: '[navigationContent]'
})
export class NavigationContentDirective {
}

@Component({
	selector: 'evi-nav',
	templateUrl: './navigation.control.html',
	styleUrls: ['./navigation.control.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class NavigationControl implements OnInit, OnDestroy {
	Instance;
	componentRef;
	SelectedNavigation;
	Path = [];
	ActiveURL;
	BaseURL;

	TabInstance;
	isNavOpen: boolean = false;

	@ContentChild(NavigationContentDirective, { read: TemplateRef })
	navigationContentTemplate;
	@ViewChild('dynamic', { read: ViewContainerRef })
	viewContainerRef: ViewContainerRef;

	//#region NavigationItems
	NavigationItemsValue = [];

	@Input()
	get NavigationItems() {
		return this.NavigationItemsValue;
	}

	set NavigationItems(val) {
		this.NavigationItemsValue = val;
		this.SearchComponent(undefined, 'Navigations');
		this.NavigationItemsChange.emit(this.NavigationItemsValue);
		this.cdRef.detectChanges();
	}

	NavOpenChange(value: boolean) {
		this.isNavOpen = value;
	}
	@Output() NavigationItemsChange = new EventEmitter<any>();
	//#endregion

	//#region SelectionHandler
	SelectionHandlerValue;

	@Input()
	get SelectionHandler() {
		return this.SelectionHandlerValue;
	}

	set SelectionHandler(val) {
		this.SelectionHandlerValue = val;
		this.SelectionHandlerChange.emit(this.SelectionHandlerValue);
	}

	@Output() SelectionHandlerChange = new EventEmitter<any>();
	//#endregion

	//#region ShowPath
	ShowPathValue = false;

	@Input()
	get ShowPath() {
		return this.ShowPathValue;
	}

	set ShowPath(val) {
		this.ShowPathValue = val;
		this.ShowPathChange.emit(this.ShowPathValue);
	}

	@Output() ShowPathChange = new EventEmitter<any>();
	//#endregion
	//#region DeepLinking
	DeepLinkingValue = true;

	@Input()
	get DeepLinking() {
		return this.DeepLinkingValue;
	}

	set DeepLinking(val) {
		this.DeepLinkingValue = val;
		this.DeepLinkingChange.emit(this.DeepLinkingValue);
	}

	@Output() DeepLinkingChange = new EventEmitter<any>();
	//#endregion
	//#region SelectionHandler
	ContentStyleValue = {};

	@Input()
	get ContentStyle() {
		return this.ContentStyleValue;
	}

	set ContentStyle(val) {
		this.ContentStyleValue = val;
		this.ContentStyleChange.emit(this.ContentStyleValue);
	}

	@Output() ContentStyleChange = new EventEmitter<any>();
	//#endregion
	SelectionSet: Subject<any> = new Subject();

	NavOpen = false;
	Navs = {};
	private NavSubject = new Subject<any>();
	_Nav$ = this.NavSubject.asObservable();

	@ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

	DropdownOpen: boolean = false;

	toggleDropdown(): void {
		this.DropdownOpen = !this.DropdownOpen;
	}

	constructor(
		private factoryResolver: ComponentFactoryResolver,
		private router: Router,
		public cdRef: ChangeDetectorRef,
		public globalSearchService: GlobalSearchService
	) {
		this.Instance = this;
		NavigationService.ActiveURL.subscribe((url) => {
			this.ActiveURL = url;
			this.SearchComponent(undefined, 'constructor');
		});
		NavigationService.Refresh.subscribe((url) => {
			this.SearchComponent(undefined, 'constructor');
			this.cdRef.detectChanges();
		});
	}

	ngOnInit(): void {
		NavigationService.NavOpenChanged.subscribe((value) => {
			if (value.Value == false) {
				delete this.Navs[value.Instance];
			} else {
				this.Navs[value.Instance] = value.Value;
			}
			if (Object.keys(this.Navs).length > 0) {
				this.NavOpen = true;
			} else {
				this.NavOpen = false;
			}
			this.cdRef.detectChanges();
		});
		this._Nav$.subscribe((nav) => {
			// this.findTabInstance(nav);
		});
	}

	ngOnDestroy(): void {
		if (this.componentRef) {
			this.componentRef.destroy();
			this.componentRef = null;
		}
	}

	NavigateToHome(homeNav:any) {
		this.router.navigate(['/default/settings/dashboard'])
	}

	SearchComponent(notSetTab?: boolean, testStr?: string) {
		if (
			this.DeepLinking &&
			this.ActiveURL &&
			this.NavigationItemsValue &&
			this.NavigationItemsValue.length > 0
		) {
			this.ResetSelected();

			let url = this.ActiveURL.substring(
				this.ActiveURL.indexOf('settings') + 9
			);
			this.BaseURL = this.ActiveURL.substring(
				0,
				this.ActiveURL.indexOf('settings') + 9
			);
			let split = url.split('/');
			let toSearch = this.NavigationItemsValue;
			if (split && split.length > 0) {
				for (let i = 0; i < split.length; i++) {
					let path = split[i];
					let found = null;
					toSearch.forEach((item) => {
						item.Selected = false;
						if (item.ID.toLowerCase() == path.toLowerCase()) {
							item.Selected = true;
							found = item.Children;
						}
					});
					if (found) {
						toSearch = found;
					}
				}
			}
			// if (toSearch[0] && !notSetTab) {
			// 	const instance = this.findArrayWithMatchedId(this.NavigationItems, toSearch[0]?.ID)
			// 	this.TabInstance = instance[instance.length - 1]

			// }
			this.SelectionSet.next(true);
		}
	}

	ResetSelected(items?) {
		if (!items) {
			items = this.NavigationItemsValue;
		}
		items.forEach((item) => {
			item.Selected = false;
			if (item.Children && item.Children.length > 0) {
				this.ResetSelected(item.Children);
			}
		});
	}

	async CheckComponent() {
		if (
			this.SelectionHandlerValue &&
			typeof this.SelectionHandlerValue.CheckComponent === 'function'
		) {
			return await this.SelectionHandlerValue.CheckComponent();
		}
		return true;
	}

	// findChildrenById(arr, targetId) {
	// 	arr.map((obj) => {
	// 		if (obj.ID === targetId) {
	// 			return obj.Children || [];
	// 		}
	// 		if (obj.Children) {
	// 			const foundChildren = this.findChildrenById(obj.Children, targetId);
	// 			if (foundChildren.length > 0) {
	// 				return foundChildren;
	// 			}
	// 		}
	// 	});
	// 	return [];
	//   }

	findArrayWithMatchedId(arr, targetId, parentArray = []) {
		let foundArray = null;

		arr.some((obj) => {
			if (obj.ID === targetId) {
				foundArray = parentArray;
				return true; // Exit the loop if a match is found
			}
			if (obj.Children) {
				const childrenArray = parentArray.concat(obj); // Concatenate the current object to the parent array
				foundArray = this.findArrayWithMatchedId(
					obj.Children,
					targetId,
					childrenArray
				);
				return foundArray !== null; // Exit the loop if a match is found in the recursion
			}
			return false;
		});

		return foundArray;
	}

	async SetComponent(nav) {
		this.SelectedNavigation = nav;
		const path = [];
		let children = this.NavigationItemsValue;
		while (children) {
			const selected = children.find((x) => x.Selected);
			if (selected && selected !== nav) {
				path.push(selected);
				children = selected.Children;
			} else {
				break;
			}
		}
		this.Path = path;
		let component;
		if (this.viewContainerRef) {
			this.viewContainerRef.clear();
			if (nav && nav.Content) {
				if (nav.SecurityValue) {
					const factory = this.factoryResolver.resolveComponentFactory(
						nav.Content
					);
					this.componentRef = this.viewContainerRef.createComponent(factory);
					component = this.componentRef.instance;
					if (nav.InitArgs) {
						component.InitArgs = nav.InitArgs;
					}
				} else {
					const factory =
						this.factoryResolver.resolveComponentFactory(PermissionDeniedPage);
					this.componentRef = this.viewContainerRef.createComponent(factory);
					component = this.componentRef.instance;
				}
			}
		}
		if (
			this.SelectionHandlerValue &&
			typeof this.SelectionHandlerValue.SetComponent === 'function'
		) {
			this.SelectionHandlerValue.SetComponent(nav, component);
		}
		this.cdRef.detectChanges();
	}

	OnNavClicked(nav) {
		// if (!nav.Children) {
		// 	const instance = this.findArrayWithMatchedId(this.NavigationItemsValue, nav.ID)
		// 	if (instance) {
		// 		this.TabInstance = instance[instance.length-1]
		// 	}
		// } else {
		// 	this.TabInstance = nav
		// }
		if (
			this.SelectionHandlerValue &&
			typeof this.SelectionHandlerValue.OnNavClicked === 'function'
		) {
			this.SelectionHandlerValue.OnNavClicked(nav);
		}
	}

	async parentClicked(parent) {
		if (this.DeepLinking) {
			if (this.NavigationItems) {
				let path = this.findItem(parent, this.NavigationItems);
				let url = '';
				let count = 0;
				path.forEach((p) => {
					if (count > 0) {
						url += '/';
					}
					url += p;
					count += 1;
				});

				if (this.BaseURL[this.BaseURL.length - 1] != '/') {
					this.BaseURL += '/';
				}
				this.router.navigate([this.BaseURL + url]);
				parent.NaviTabInstance.childnav.NavOpenValue = true;
				this.SetComponent(parent);
			}
		} else {
			if (parent && parent.NaviTabInstance) {
				await parent.NaviTabInstance.selectItem(null);
			}
		}
	}

	findItem(nav, items) {
		let retVal = [];
		if (nav != null && items && items.length > 0) {
			for (let i = 0; i < items.length; i++) {
				let item = items[i];
				if (nav == item) {
					retVal.push(item.ID);
				}
				if (item.Children && item.Children.length > 0) {
					let result = this.findItem(nav, item.Children);
					if (result && result.length > 0) {
						retVal.push(item.ID);
						retVal.push(...result);
					}
				}
			}
		}
		return retVal;
	}

	dropdownSelection: any;
	tabChanged(nav: any) {
		if (nav) {
			this.dropdownSelection = nav;
		}
	}
}

