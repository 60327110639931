import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { VariableHelper } from '../../helpers/variable.helper';
import { GlobalVariableService } from '../../services/globalvariable.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'globalvariable-settings',
    templateUrl: '../base.list.settings.html'
})
export class GlobalVariableSettings extends BaseListSettings {

    Variables = [];

    static GetSettingsEntry() {
        return {
            Caption: '@@Globale Variablen',
            ID: 'globalvariable',
            Icon: 'stars',
            Index: 24,
            Parent: 'data',
            Security: {
                Name: 'evidanza.App.Shared.Security.DataRight',
                Value: 512
            },
            Content: GlobalVariableSettings
        };
    }

    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef,
        private variableService: GlobalVariableService, private translate: TranslateService) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this.variableService.LoadVariables().subscribe(result => {
            if (result) {
                this.Variables = result;
                const list = [];
                result.forEach(function (entry) {
                    list.push({
                        Caption: entry.Name,
                        ID: entry._Id,
                        IsCapsule: entry._iscapsule,
                        IsOverridden: entry._isoverriden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        const variable = this.Variables.find(x => x._Id === data);
        if (variable) {
            this.setSelectedItem(variable);
        }
    }

    getNewItem() {
        return {
            Name: this.translate.instant('@@Neue Variable'),
            Value: '',
            Type: null
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Variable \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Variable \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Variable loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Variable \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Variable speichern');
        return retVal;
    }

    delete(data: any, handler) {
        if (data) {
            this.variableService.DeleteVariable(data).subscribe((res) => {
                handler(res);
            });
        }
    }

    saveInternal(item: any, handler: any) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.variableService.SaveVariable(item).subscribe((result) => {
            if (result) {
                handler(result, result._Id, result.Name);
            }
        });
    }

    getContentType() { return GlobalVariableDetail; }

    handleNew(item, result) {
        item._Id = result._Id;
        item.Version = result.Version;
        this.Variables.push(result);
    }

    updateListItem(item, result) {
        item.IsCapsule = result._iscapsule;
        item.IsOverridden = result._isoverriden;
    }
}

@Component({
    selector: 'globalvariable-detail',
    templateUrl: './globalvariable.settings.html',
    styleUrls: ['./globalvariable.settings.css']
})
export class GlobalVariableDetail extends BaseListDetail {
    DataTypes = VariableHelper.DataTypes;
    OnFormulaChanged(event) {
        this.SelectedItem.Value = event;
    }
}
