import { Input, Output, EventEmitter, Component } from '@angular/core';
import { ILayoutTreeNodeLoader } from '../../../components/controls/layout.tree';

@Component({
    selector: 'layout-item-chooser',
    templateUrl: './layout.item.chooser.html',
    styleUrls: ['./layout.item.chooser.css']
})
export class LayoutItemChooser {
    //#region ElementName
    ElementNameValue: string;

    @Input()
    get ElementName() {
        return this.ElementNameValue;
    }
    set ElementName(val) {
        if (val !== this.ElementNameValue) {
            this.ElementNameValue = val;
            this.ElementNameChange.emit(this.ElementNameValue);
        }
    }

    @Output() ElementNameChange = new EventEmitter<any>();
    //#endregion

    //#region ShowLayoutTree
    LayoutValue;

    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.LayoutChange.emit(this.LayoutValue);
    }

    @Output() LayoutChange = new EventEmitter<any>();
    //#endregion

    //#region PlaceHolder
    PlaceHolderValue = '@@Element';

    @Input()
    get PlaceHolder() {
        return this.PlaceHolderValue;
    }
    set PlaceHolder(val) {
        if (val) {
            this.PlaceHolderValue = val;
            this.PlaceHolderChange.emit(this.PlaceHolderValue);
        }
    }

    @Output() PlaceHolderChange = new EventEmitter<any>();
    //#endregion

    //#region NodeLoader
    NodeLoaderValue: ILayoutTreeNodeLoader;

    @Input()
    get NodeLoader() {
        return this.NodeLoaderValue;
    }
    set NodeLoader(val: ILayoutTreeNodeLoader) {
        this.NodeLoaderValue = val;
        this.NodeLoaderChange.emit(this.NodeLoaderValue);
    }

    @Output() NodeLoaderChange = new EventEmitter<any>();
    //#endregion

    //#region ShowRoot
    ShowRootValue = true;

    @Input()
    get ShowRoot() {
        return this.ShowRootValue;
    }
    set ShowRoot(val) {
        this.ShowRootValue = val;
        this.ShowRootChange.emit(this.ShowRootValue);
    }

    @Output() ShowRootChange = new EventEmitter<any>();
    //#endregion

    //#region ShowInfo
    ShowInfoValue;

    @Input()
    get ShowInfo() {
        return this.ShowInfoValue;
    }
    set ShowInfo(val) {
        this.ShowInfoValue = val;
        this.ShowInfoChange.emit(this.ShowInfoValue);
    }

    @Output() ShowInfoChange = new EventEmitter<any>();
    //#endregion

    @Output() SelectionChanged = new EventEmitter<any>();

    nodeSelected(ev) {
        if (ev) {
            if (ev.longName) {
                this.ElementName = ev.longName;
            } else if (ev.data && ev.data.Name) {
                this.ElementName = ev.data.Name;
            }
            this.onChange();
        }
    }

    onChange() {
        this.SelectionChanged.emit(this.ElementName);
    }
}
