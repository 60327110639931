import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

export class GetCurrentGeoLocationSettingsData {
    StatusKey;
    UseForState;
}

export class GetCurrentGeoLocationModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (navigator.geolocation) {
                var position = await this.getCoordinates();
                if (status.ActualSettings.StatusKey) {
                    status.Context.set(status.ActualSettings.StatusKey, position);
                }
                if (status.ActualSettings.UseForState) {
                    status.ActualParameter = position;
                }
                return 0;
            } else {
                status.Logger.logError('GetGeoLocation module: Location not supported!');
            }
        } else {
            status.Logger.logError('GetGeoLocation module: No settings found.');
        }
        return super.execute(status);
    }
    private getCoordinates() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve(this.cloneAsObject(position));
            });
        });
    }
    private cloneAsObject(obj) {
        if (obj === null || !(obj instanceof Object)) {
            return obj;
        }
        var temp = (obj instanceof Array) ? [] : {};
        for (var key in obj) {
            temp[key] = this.cloneAsObject(obj[key]);
        }
        return temp;
    }
}


export class GetCurrentGeoLocationSettingsHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getCurrentGeoLocationModule';
        reg.Caption = '@@Aktuelle GeoLocation auslesen';
        reg.Index = 50;
        reg.SettingsTypeHelper = new GetCurrentGeoLocationSettingsHelper();
        reg.Executer = GetCurrentGeoLocationModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = GetGeoLocationPropertiesSettings.ModuleID;
        return reg;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetCurrentGeoLocationSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

@Component({
    selector: 'get-geolocation-properties-wf-settings',
    templateUrl: './get.geolocation.properties.settings.html'
})
export class GetGeoLocationPropertiesSettings extends WorkflowDialogContent {
    static ModuleID = 'getGeoLocationPropertiesWFModule';

    Data = new GetCurrentGeoLocationSettingsData();
    Properties = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = GetGeoLocationPropertiesSettings.ModuleID;
        reg.Caption = '@@Aktuelle Location auslesen';
        reg.GroupID = 'generateData';
        reg.Index = 50;
        reg.SettingsControl = GetGeoLocationPropertiesSettings;
        reg.SettingsTypeHelper = new GetCurrentGeoLocationSettingsHelper();
        reg.Executer = GetCurrentGeoLocationModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetCurrentGeoLocationSettingsData, json);
        }
    }

    getResult() {
        return this.Data;
    }
}
