import { Component } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-logsdialog',
    templateUrl: './logsdialog.component.html',
    styleUrls: ['./logsdialog.component.css']
})

export class LogsdialogComponent {

    Message;
    Date;

    constructor() {

    }

    Initialize(event) {
        if (event) {
            this.Message = event;
            this.Date = moment(event.data.ExecutionTime).startOf('day').format('MM/DD/YYYY').toString();
        }
    }
}