import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { RequestBase } from './request-base';
import { IdName } from '../models/basic/idname.model';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityService extends RequestBase {
    BasePath = "config/api/security";

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    readGroups(): Observable<IdName[]> {
        return this.http.get<IdName[]>(this.API_BASE_URL + this.BasePath + '/getgroups', this.options);
    }
    readGroup(id: string) {
        return this.http.get(this.API_BASE_URL + this.BasePath + '/getgroup?Id=' + id, this.options);
    }
    addOrUpdateGroup(group) {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/addorupdategroup', group, this.options).pipe(
            catchError(this.handleError('create', []))
        );
    }
    addUserToGroup(group, user) {
        let data = {
            userid: user.ID,
            groupid: group.ID
        }
        return this.http.post(this.API_BASE_URL + this.BasePath + '/addusertogroup', data, this.options).pipe(
            catchError(this.handleError('addUser', []))
        );
    }
    deleteGroup(id: string) {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/deletegroup?id=' + id, this.options).pipe(
            catchError(this.handleError('delete', []))
        );
    }
    removeUserFromGroup(groupid: string, userid: string) {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/removeuserfromgroup?groupid=' + groupid + "&userid=" + userid, this.options).pipe(
            catchError(this.handleError('removeUser', []))
        );
    }
    LinkAccounts(userid, value) {
        return this.http.post(this.API_BASE_URL + this.BasePath + '/LinkAccounts?userid=' + userid, value, this.options).pipe(
            catchError(this.handleError('LinkAccounts', []))
        );
    }
    UnlinkAccounts(userid, key) {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/UnlinkAccounts?userid=' + userid + '&key=' + key, this.options).pipe(
            catchError(this.handleError('LinkAccounts', []))
        );
    }
    GetBoundAccount(key: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetBoundAccount?key=' + key, this.options).pipe(
            catchError(this.handleError('GetBoundAccount', []))
        );
    }
    GetSecurityProviders(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetSecurityProviders?apibase=' + this.API_BASE_URL, this.options).pipe(
            catchError(this.handleError('GetSecurityProviders', []))
        );
    }
    GetSecurityProviderInfos(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetSecurityProviderInfos', this.options).pipe(
            catchError(this.handleError('GetSecurityProviderInfos', []))
        );
    }
    GetSecurityProviderTypes(): Observable<IdName[]> {
        return this.http.get<IdName[]>(this.API_BASE_URL + this.BasePath + '/GetSecurityProviderTypes', this.options).pipe(
            catchError(this.handleError('GetSecurityProviderTypes', []))
        );
    }
    GetSecurityProviderConfig(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetSecurityProviderConfig', this.options).pipe(
            catchError(this.handleError('GetSecurityProviderConfig', []))
        );
    }
    GetSecurityProviderConfigByKey(key): Observable<any> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetSecurityProviderConfigByKey?key=' + key, this.options).pipe(
            catchError(this.handleError('GetSecurityProviderConfigByKey', []))
        );
    }
    DeleteSecurityProviderConfig(key) {
        return this.http.delete(this.API_BASE_URL + this.BasePath + '/DeleteSecurityProviderConfig?key=' + key, this.options).pipe(
            catchError(this.handleError('DeleteSecurityProviderConfig', []))
        );
    }
    AddOrUpdateSecurityProviderConfig(data) {
        let toSend = Object.assign({}, data);
        toSend.Config = JSON.stringify(toSend.Config);
        return this.http.post(this.API_BASE_URL + this.BasePath + '/AddOrUpdateSecurityProviderConfig', toSend, this.options).pipe(
            catchError(this.handleError('AddOrUpdateSecurityProviderConfig', []))
        );
    }
}