export enum LineType {
    None,
    Comment,
    Let,
    Set,
    If,
    ElseIf,
    While,
    Switch,
    Case,
    Break,
    Continute,
    For,
    Try,
    Catch,
    Finally,
    Return,
    Throw,
    Other
}