import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MFATypes } from '../../../models/enums/mfatype.enum';
import { MailSettingsService } from '../../../services/mailsettings.service';

@Component({
    selector: 'identity-form',
    templateUrl: './identity.form.html'
})
export class IdentityForm implements OnInit {
    //#region Source
    SourceValue;
    MFATypes = EnumHelper.GetDropdownValues(MFATypes);
    MFADisabled = true;

    @Input()
    get Source() {
        return this.SourceValue;
    }
    set Source(val) {
        this.SourceValue = val;
        this.SourceChange.emit(this.SourceValue);
    }

    @Output() SourceChange = new EventEmitter<boolean>();
    //#endregion
    //#region State
    stateValue;
    @Input()
    get state() {
        return this.stateValue;
    }
    set state(val) {
        this.stateValue = val;
        this.stateChange.emit(this.stateValue);
    }
    @Output() stateChange = new EventEmitter<boolean>();
    //#endregion

    constructor(private cdRef: ChangeDetectorRef, private mailService: MailSettingsService) {
    }

    ngOnInit(): void {
        this.checkFields();
        this.mailService.GetMailSettings().subscribe(x => {
            if (x && x.length > 0) {
                this.MFADisabled = false;
                this.cdRef.detectChanges();
            }
        });
    }

    fieldChanged() {
        this.checkFields();
        this.ItemChanged.emit();
    }

    checkFields() {
        this.state = true;
    }

    @Output() ItemChanged = new EventEmitter<any>();
}
