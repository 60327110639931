import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { TaskRegistry } from '../../../helpers/task.registry';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { ActivityData, PageStatus, TaskActivity, TaskActivityType } from '../../../models/page.status';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../../workflow/workflow.dialog';

@Component({
    selector: 'set-task-activity-wf',
    templateUrl: './set.task.activity.html',
    styleUrls: ['./set.task.activity.css']
})
export class SetTaskActivity extends FormulaWorkflowDialogContent {
    private static _ReportObjects = ['tablereportObject', 'chartreportObject'];

    Data = new SetTaskActivityData();
    AllReportObjects = [];
    Queries = [];
    ActivityDataSettings = [];


    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setTaskActivityWFModule';
        reg.Caption = '@@Tasks schalten';
        reg.GroupID = 'report';
        reg.Index = 15;
        reg.SettingsControl = SetTaskActivity;
        reg.SettingsTypeHelper = new SetTaskActivityDataHelper();
        reg.Executer = SetTaskActivityModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    private static FindObjects(element) {
        const retVal = [];
        if (element) {
            if (SetTaskActivity._ReportObjects.some(ro => element.ElementType === ro)) {
                retVal.push({
                    Caption: element.Name,
                    ID: element.ID,
                    Query: element.Query,
                    DataTasks: element.DataTasks,
                    FormatTasks: element.FormatTasks
                });
            } else if (element.Elements) {
                element.Elements.forEach(child => {
                    retVal.push(...SetTaskActivity.FindObjects(child));
                });
            }
        }
        return retVal;
    }

    ngOnInit(): void {
        if (this.WFEditOptions && this.WFEditOptions.Layout) {
            this.AllReportObjects = SetTaskActivity.FindObjects(this.WFEditOptions.Layout);
            if (this.WFEditOptions.Layout.Datadescriptions) {
                this.Queries = this.WFEditOptions.Layout.Datadescriptions.filter(x => !x.IsRelational);
                this.fillElementsInitial();
            }
        }
    }

    fillElementsInitial() {
        if (this.Data && this.Data.ActivityData) {
            const activityDataSettings = [];
            this.Data.ActivityData.forEach(data => {
                const taskssetting = {
                    ObjectID: data.ObjectID,
                    QueryDataTasks: [],
                    ObjectDataTasks: [],
                    ObjectFormatTasks: []
                };
                if (taskssetting.ObjectID) {
                    const object = this.AllReportObjects.find(x => x.ID === taskssetting.ObjectID);
                    if (object) {
                        const query = this.Queries.find(x => x.ID === object.Query);
                        let dd;
                        if (query) {
                            dd = query.Datadescription;
                            query.Datadescription.Tasks.forEach(qTask => {
                                const qTaskSetting = this.getTaskListItem(qTask, dd);
                                const existingTaskSetting = data.QueryDataTasks.find(x => x.TaskID === qTask.ID);
                                if (existingTaskSetting) {
                                    qTaskSetting.ActivityType = existingTaskSetting.ActivityType;
                                }
                                taskssetting.QueryDataTasks.push(qTaskSetting);
                            });
                        }
                        object.DataTasks.forEach(dTask => {
                            const dTaskSetting = this.getTaskListItem(dTask, dd);
                            const existingTaskSetting = data.ObjectDataTasks.find(x => x.TaskID === dTask.ID);
                            if (existingTaskSetting) {
                                dTaskSetting.ActivityType = existingTaskSetting.ActivityType;
                            }
                            taskssetting.ObjectDataTasks.push(dTaskSetting);
                        });
                        object.FormatTasks.forEach(fTask => {
                            const fTaskSetting = this.getTaskListItem(fTask, dd);
                            const existingTaskSetting = data.ObjectFormatTasks.find(x => x.TaskID === fTask.ID);
                            if (existingTaskSetting) {
                                fTaskSetting.ActivityType = existingTaskSetting.ActivityType;
                            }
                            taskssetting.ObjectFormatTasks.push(fTaskSetting);
                        });
                    }
                }

                activityDataSettings.push(taskssetting);
            });
            this.ActivityDataSettings = activityDataSettings;
        }
    }

    getTaskListItem(task, dd): any {
        if (task.TaskType) {
            const desc = TaskRegistry.get(task.TaskType);
            if (desc) {
                const item = {
                    Caption: task.TaskType,
                    TaskID: task.ID,
                    ActivityType: TaskActivityType.None
                };
                if (desc.SettingsHelper && dd) {
                    item.Caption = desc.SettingsHelper.getCaption(task, dd);
                }
                return item;
            }
        }
        return null;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetTaskActivityData, json);
        }
    }

    getResult(): any {
        const taskssettings: ActivityData[] = [];
        this.ActivityDataSettings.forEach(data => {
            const taskssetting: ActivityData = new ActivityData()
            taskssetting.ObjectID = data.ObjectID;
            data.QueryDataTasks.forEach(dtask => {
                if (dtask.ActivityType !== TaskActivityType.None) {
                    taskssetting.QueryDataTasks.push({
                        TaskID: dtask.TaskID,
                        ActivityType: dtask.ActivityType
                    });
                }
            });
            data.ObjectDataTasks.forEach(dtask => {
                if (dtask.ActivityType !== TaskActivityType.None) {
                    taskssetting.ObjectDataTasks.push({
                        TaskID: dtask.TaskID,
                        ActivityType: dtask.ActivityType
                    });
                }
            });
            data.ObjectFormatTasks.forEach(dtask => {
                if (dtask.ActivityType !== TaskActivityType.None) {
                    taskssetting.ObjectFormatTasks.push({
                        TaskID: dtask.TaskID,
                        ActivityType: dtask.ActivityType
                    });
                }
            });
            taskssettings.push(taskssetting);
        });
        this.Data.ActivityData = taskssettings;
        return this.Data;
    }

    addObject() {
        const taskssetting = {
            QueryDataTasks: [],
            ObjectDataTasks: [],
            ObjectFormatTasks: []
        };
        if (this.ActivityDataSettings) {
            this.ActivityDataSettings.push(taskssetting);
        } else {
            this.ActivityDataSettings = [taskssetting];
        }
    }

    removeObject(i) {
        if (this.ActivityDataSettings) {
            this.ActivityDataSettings.splice(i, 1);
        }
    }

    onObjectChanged(i) {
        if (this.ActivityDataSettings) {
            const actualSetting = this.ActivityDataSettings[i];
            actualSetting.QueryDataTasks = [];
            actualSetting.ObjectDataTasks = [];
            actualSetting.ObjectFormatTasks = [];
            const object = this.AllReportObjects.find(x => x.ID === actualSetting.ObjectID);
            if (object) {
                const query = this.Queries.find(x => x.ID === object.Query);
                let dd;
                if (query) {
                    dd = query.Datadescription;
                    query.Datadescription.Tasks.forEach(qTask => {
                        const qTaskSetting = this.getTaskListItem(qTask, dd);
                        actualSetting.QueryDataTasks.push(qTaskSetting);
                    });
                }
                object.DataTasks.forEach(dTask => {
                    const dTaskSetting = this.getTaskListItem(dTask, dd);
                    actualSetting.ObjectDataTasks.push(dTaskSetting);
                });
                object.FormatTasks.forEach(fTask => {
                    const fTaskSetting = this.getTaskListItem(fTask, dd);
                    actualSetting.ObjectFormatTasks.push(fTaskSetting);
                });
            }
        }
    }
}


export class SetTaskActivityDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetTaskActivityData();
    }
}

export class SetTaskActivityModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const pageState = PageStatus.ActualState.getValue();
            if (pageState) {
                status.ActualSettings.ActivityData.forEach(tasksetting => {
                    const flattasks: TaskActivity[] = [];
                    flattasks.push(...tasksetting.QueryDataTasks);
                    flattasks.push(...tasksetting.ObjectDataTasks);
                    flattasks.push(...tasksetting.ObjectFormatTasks);
                    pageState.TaskActiveInfos.set(tasksetting.ObjectID, flattasks);
                });
                return 0;
            } else {
                status.Logger.logError('SetQueryView module: No page state found.');
            }
        } else {
            status.Logger.logError('SetQueryView module: No settings found.');
        }
        return super.execute(status);
    }
}

export class SetTaskActivityData {
    ActivityData: ActivityData[] = [];
}
