import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { TranslationService } from '../../../services/translation.service';
import { BaseDialog } from '../basedialog/base.dialog';

@Component({
    selector: 'translation-dialog',
    templateUrl: './translation.dialog.html',
    styleUrls: ['./translation.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationDialog implements OnInit {

    HasChanges = false;
    CustomButtons = [{
        Caption: '@@Speichern',
        ID: 0
    }, {
        Caption: '@@Abbrechen',
        ID: 1
    }];
    CloseOnCustomButton = true;
    Key;
    TranslationEntry;
    Loading = true;

    public static ShowDialog(key: string) {
        if (key) {
            BaseDialog.ShowDialog({
                ContentType: TranslationDialog,
                InitArgs: { Key: key },
                Title: '@@Uebersetzung pflegen',
                Buttons: DialogButton.None
            });
        }
    }

    constructor(private service: TranslationService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.Key) {
            this.service.GetTranslationsForKey(this.Key).subscribe(result => {
                this.TranslationEntry = result;
                this.Loading = false;
                this.cdRef.detectChanges();
            });
        } else {
            this.Loading = false;
            this.cdRef.detectChanges();
        }
    }

    Initialize(data) {
        if (data && data.Key) {
            this.Key = data.Key;
        }
    }

    OnCustomButtonClicked(button) {
        if (button && button.ID === 0) {
            if (this.HasChanges && this.TranslationEntry) {
                this.HasChanges = false;
                const saveVal = {
                    Key: this.TranslationEntry.Key,
                    CapsuleTag: this.TranslationEntry.CapsuleTag,
                    Translations: []
                };
                if (this.TranslationEntry.Translations) {
                    this.TranslationEntry.Translations.forEach(x => {
                        if (x.Changed === true) {
                            saveVal.Translations.push({
                                LCID: x.LCID,
                                Value: x.Value
                            });
                        }
                        delete x.Changed;
                    });
                }
                this.service.SaveTranslationForKey(saveVal).subscribe();
            }
        }
    }
}
