import { Component } from '@angular/core';
import { BaseDialog } from '../../components/dialogs/basedialog/base.dialog';

@Component({
    selector: 'save-changelog-dialog',
    templateUrl: './save.changelog.dialog.html'
})
export class SaveChangeLogDialog {
    Text = '@@ChangeLogText';
    ItemCount = [];
    Overwrite = false;

    static ShowDialog(count, handler) {
        BaseDialog.ShowDialog({
            ContentType: SaveChangeLogDialog,
            InitArgs: {
                Count: count
            },
            Handler: (r) => {
                if (typeof r === 'boolean' && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@SaveChangeLogHeader',
            Width: 400
        });
    }

    Initialize(args) {
        if (args && typeof args.Count === 'number' && args.Count > 0) {
            this.Text += '{0}';
            this.ItemCount.push('' + args.Count);
        }
    }

    GetDialogResult() {
        return this.Overwrite;
    }
}
