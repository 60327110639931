import { Subject, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class SettingsService {
    Add: Subject<boolean> = new Subject();
    Edit: Subject<boolean> = new Subject();
    Delete: Subject<any> = new Subject();
    //SaveContent: Subject<boolean> = new Subject();
    Refresh: Subject<boolean> = new Subject();
    Loading: Subject<boolean> = new Subject();

    Bold: Subject<any> = new Subject();
    Italic: Subject<any> = new Subject();
    Underline: Subject<any> = new Subject();
    FontFamily: Subject<any> = new Subject();
    FontSize: Subject<any> = new Subject();
    FontColor: Subject<any> = new Subject();

    BackgroundColor: Subject<any> = new Subject();

    HorizontalAlignment: Subject<any> = new Subject();
    VerticalAlignment: Subject<any> = new Subject();

    HorizontalTextAlignment: Subject<any> = new Subject();
    VerticalTextAlignment: Subject<any> = new Subject();

    DragStart: Subject<any> = new Subject();
    DragEnd: Subject<any> = new Subject();

    SelectedItem: BehaviorSubject<any> = new BehaviorSubject(null);
    public static GeneralSettings: Subject<any> = new Subject();

    ButtonType: Subject<any> = new Subject();
    InputType: Subject<any> = new Subject();
    Minimum: Subject<any> = new Subject();
    Maximum: Subject<any> = new Subject();
    DisplayMemberPath: Subject<any> = new Subject();
    LBMultiple: Subject<any> = new Subject();
    LBCheckbox: Subject<any> = new Subject();
    LBFilter: Subject<any> = new Subject();
    ShowTime: Subject<any> = new Subject();
    TimeOnly: Subject<any> = new Subject();
    MonthNavigator: Subject<any> = new Subject();
    YearNavigator: Subject<any> = new Subject();
    DateFormat: Subject<any> = new Subject();
    YearRange: Subject<any> = new Subject();
    DateSelectionMode: Subject<any> = new Subject();
    DateNumberOfMonths: Subject<any> = new Subject();
    DateShowButtonBar: Subject<any> = new Subject();
    DateView: Subject<any> = new Subject();
    public static API_BASE_URL: BehaviorSubject<string> = new BehaviorSubject("http://localhost:8100/");
    public static WEBSOCKET_URL: BehaviorSubject<string> = new BehaviorSubject("http://localhost:8100/");
}
