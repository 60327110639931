import moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { CacheItem } from "../models/cacheitem.model";
import { CacheTable } from "../models/cachetable.model";
import { IDriver } from "../models/idriver";

export class InMemCache implements IDriver{
    db: any;
    dbVersion;
    Cache: any;
    Requests: any;
    public Initialize(db, version:number) {
        return new Promise((resolve) => {
            this.Cache = {};
            this.db = db;
            this.dbVersion = version;
            //console.info('Using in memory cache!');
            resolve(this);
        });
    }
   //#region Table
    public CreateTable(name): boolean {
        if (!this.Cache[name]) {
            this.Cache[name] = {};
        }
        return true;
    }
    public CreateTables(tables: CacheTable[]): boolean {
        tables.forEach((table) => {
            if (!this.Cache[table.name]) {
                this.Cache[table.name] = {};
            }
        });
        return true;
    }
    //#endregion
    //#region Create
    public Insert(table, entry: CacheItem): boolean {
        if (!this.Cache[table]) {
            this.Cache[table] = {};
        }
        this.Cache[table][entry.key] = entry.value ? entry.value : "<={empty}=>";
        return true;
    }
    //#endregion
    //#region Read
    public ReadKey(table, key: string, request?): Observable<CacheItem> {
        if (!this.Cache[table]) {
            return of(null);
        }
        if (this.Cache[table][key]) {
            if (this.Cache[table][key] == "<={empty}=>") {
                return of(null);
            }
            return of(new CacheItem(key, this.Cache[table][key]));
        } else {
            if (request) {
                    if (!this.Requests) {
                        this.Requests = {};
                    }
                    if (!this.Requests[table]) {
                        this.Requests[table] = {};
                    }
                if (!this.Requests[table][key]) {
                    return new Observable<CacheItem>((subscriber) => {

                        this.Requests[table][key] = new Subject();
                        request.subscribe((result) => {
                            //console.log(moment().format("hh.mm.ss:SSS") + " FetchData " + table + "||" + key + ' || ' + end.diff(start));
                            let item = new CacheItem(key, result);
                            this.Insert(table, item);
                            this.Requests[table][key].next(item);
                            subscriber.next(item);
                            delete this.Requests[table][key];
                        });
                    });
                } else {
                    return this.Requests[table][key];
                }
            } else {
                return of(null);
            }
        }
    }
    public ReadAll(table: string): Observable<CacheItem[]> {
        if (!this.Cache[table]) {
            return of(null);
        }
        const result = [];
        Object.keys(this.Cache[table]).forEach((key) => {
            if (this.Cache[table][key]) {
                result.push(new CacheItem(key, this.Cache[table][key]));
            }
        });
        return of(result);
    }
    //#endregion
    //#region Update
    public Update(table: string, entry: CacheItem): boolean {
        if (!this.Cache[table]) {
            return false;
        }
        this.Cache[table][entry.key] = entry.value ? entry.value : "<={empty}=>"
            
        return true;
    }
    //#endregion
    //#region Delete
    public Clear(table): boolean {
        this.Cache[table] = {};
        return true;
    }
   
    public Delete(table: string, key: string): boolean {
        if (this.Cache[table]) {
            delete this.Cache[table][key];
            return true;
        } else {
            return false;
        }
    }
    //#endregion
}