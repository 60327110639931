import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper, TranslateFormatText } from '../helpers/array.helpers';
import { WorkflowViewType } from '../models/enums/workflowviewtype.enum';
import { TranslatedString } from '../models/translatedstring.model';
import { WorkflowModuleData } from '../models/workflow/workflow.model';
import { WF_REGISTRY, WF_TYPE_REGISTRY, WorkflowExitInfo } from '../services/workflow.service';

@Component({
    selector: 'workflow-item',
    templateUrl: './workflow.item.html',
    styleUrls: ['./workflow.item.css']
})
export class WorkflowItem implements AfterViewInit, OnDestroy {
    //#region WFControl
    WFControlValue;

    @Input()
    get WFControl() {
        return this.WFControlValue;
    }
    set WFControl(val) {
        this.WFControlValue = val;
        this.WFControlChange.emit(this.WFControlValue);
    }

    @Output() WFControlChange = new EventEmitter<any>();
    //#endregion
    //#region ElemID
    ElemIDValue;

    @Input()
    get ElemID() {
        return this.ElemIDValue;
    }
    set ElemID(val) {
        this.ElemIDValue = val;
        this.ElemIDChange.emit(this.ElemIDValue);
    }

    @Output() ElemIDChange = new EventEmitter<any>();
    //#endregion
    //#region Data
    DataValue: WorkflowModuleData;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        this.DataValue = val;
        this.setCaption();
        if (this.DataValue) {
            //this.BreakpointSet = this.DataValue.BreakpointSet;
            this.DataValue.Instance = this;
        }
        this.DataChange.emit(this.DataValue);
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion

    //#region Data
    IsSelectedValue = false;

    @Input()
    get IsSelected() {
        return this.IsSelectedValue;
    }
    set IsSelected(val) {
        this.IsSelectedValue = val;
        this.IsSelectedChange.emit(this.IsSelectedValue);
    }

    @Output() IsSelectedChange = new EventEmitter<any>();
    //#endregion

    Caption: string;
    AdditionalCaption: string;
    AdditionalTranslateCaption: TranslateFormatText;
    Description: string;
    ActualExitPoints = [];
    ActualEntryPoints = [];
    ObsoleteText: TranslateFormatText;
    @Output() OnInit = new EventEmitter<any>();

    constructor(private dialog: MatDialog, private translate: TranslateService, private cdRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        if (this.WFControlValue) {
            const jsPlumb = this.WFControlValue.jsPlumbInstance;
            if (jsPlumb) {
                jsPlumb.draggable(this.ElemIDValue, {
                    getConstrainingRectangle: () => [99999, 99999],
                    containment: true, stop: (e) => { this.elementMoved(e); }
                });
                if (this.DataValue) {
                    const desc = WF_REGISTRY.get(this.DataValue.Module);
                    if (desc) {
                        if (desc.SettingsTypeHelper) {
                            this.ActualEntryPoints.forEach((ep) => {
                                jsPlumb.deleteEndpoint(ep);
                            });
                            const entryPoints = desc.SettingsTypeHelper.getEntryPoints();
                            const percent = 1.0 / (entryPoints.length + 1);
                            for (let i = 0; i < entryPoints.length; i++) {
                                const exPo = entryPoints[i];
                                const expoInfo = {
                                    anchor: [percent * (i + 1), 0, 0, -1],
                                    isTarget: true,
                                    endpoint: ['Rectangle', { width: 10, height: 10 }],
                                    maxConnections: -1,
                                    uuid: 'entry_' + this.DataValue.ID + '_' + exPo.ID
                                };
                                if (typeof exPo.Type === 'string') {
                                    expoInfo['parameters'] = {
                                        EndPointType: exPo.Type
                                    };
                                    const info = WF_TYPE_REGISTRY.get(exPo.Type);
                                    if (info) {
                                        expoInfo['paintStyle'] = {
                                            fill: info.Color
                                        };
                                    }
                                }
                                const ep = jsPlumb.addEndpoint(this.ElemIDValue, expoInfo);
                                if (exPo.Label) {
                                    const label = this.translate.instant(exPo.Label);
                                    ep.addOverlay(['Label', { label: label, cssClass: 'exitPointLabel', location: [0.5, 2.5] }]);
                                    ep.bind('mouseover', (exP, ev) => {
                                        exP.showOverlays();
                                    });
                                    ep.bind('mouseout', (exP, ev) => {
                                        exP.hideOverlays();
                                    });
                                    ep.hideOverlays();
                                }
                                this.ActualEntryPoints.push(ep);
                            }
                            let settings = this.DataValue.Settings;
                            if (typeof settings === 'string') {
                                settings = JSON.parse(settings);
                            }
                            const exitPoints = desc.SettingsTypeHelper.getExitPoints(settings);

                            if (exitPoints && exitPoints.length) {
                                let error = exitPoints.find((value) => value.ID == -1);
                                if (!error) {
                                    error = new WorkflowExitInfo();
                                    error.ID = -1;
                                    error.Label = "@@InternalError";
                                    error.Type = "error";
                                    exitPoints.push(error);
                                }
                            }

                            this.updateEndPoints(exitPoints);
                        }
                        if (desc.ObsoleteInfo) {
                            const text = new TranslateFormatText('@@Baustein ist veraltet.');
                            const replacedBy = WF_REGISTRY.get(desc.ObsoleteInfo.ReplacedBy);
                            if (replacedBy) {
                                text.TranslateText = '@@Baustein ist veraltet. Bitte ersetzen durch \'{0}.\'';
                                text.FormatParams.push(this.translate.instant(replacedBy.Caption));
                            }
                            this.ObsoleteText = text;
                        }
                    }
                }
            }
        }
        this.OnInit.emit(this);
    }

    ngOnDestroy(): void {
        this.ActualExitPoints.forEach(ep => {
            const jsPlumb = this.WFControlValue.jsPlumbInstance;
            if (jsPlumb) {
                jsPlumb.deleteEndpoint(ep);
            }
            ep.unbind();
        });
        this.ActualEntryPoints.forEach(ep => {
            const jsPlumb = this.WFControlValue.jsPlumbInstance;
            if (jsPlumb) {
                jsPlumb.deleteEndpoint(ep);
            }
            ep.unbind();
        });
    }

    elementMoved(event) {
        if (this.DataValue) {
            this.DataValue.XPos = event.finalPos[0];
            this.DataValue.YPos = event.finalPos[1];
            this.onContentChanged();
        }
    }

    onContentChanged() {
        if (this.WFControlValue) {
            this.WFControlValue.onContentChanged();
        }
    }
    setCaption() {
        let caption = null;
        let additionalTranslateCaption = null;
        let additionalCaption = null;
        const descList = [];
        if (this.DataValue) {
            const desc = WF_REGISTRY.get(this.DataValue.Module);
            if (desc) {
                if (desc.Caption) {
                    caption = desc.Caption;
                    descList.push(this.translate.instant(desc.Caption));
                }
                let settings = this.DataValue.Settings;
                if (settings && desc.SettingsTypeHelper) {
                    if (typeof settings === 'string') {
                        settings = JSON.parse(settings);
                    }
                    const addCaption = desc.SettingsTypeHelper.getAdditionalCaption(settings);
                    if (addCaption instanceof TranslateFormatText) {
                        additionalTranslateCaption = addCaption;
                        descList.push(StringHelper.format(this.translate.instant(addCaption.TranslateText), addCaption.FormatParams));

                    } else if (typeof addCaption === 'string') {
                        additionalCaption = addCaption;
                        descList.push(addCaption);
                    }
                }
            }
            if (this.DataValue.Description) {
                descList.push(TranslatedString.GetTranslation(this.DataValue.Description));
            }
        }
        this.Caption = caption;
        this.AdditionalTranslateCaption = additionalTranslateCaption;
        this.AdditionalCaption = additionalCaption;
        if (descList.length > 0) {
            this.Description = descList.join('\n');
        } else {
            this.Description = null;
        }
    }
    updateEndPoints(exitPoints: WorkflowExitInfo[]) {
        if (this.WFControlValue) {
            const jsPlumb = this.WFControlValue.jsPlumbInstance;
            if (jsPlumb) {
                if (this.DataValue) {
                    const desc = WF_REGISTRY.get(this.DataValue.Module);
                    if (desc && desc.SettingsTypeHelper) {
                        this.ActualExitPoints.forEach((ep) => {
                            jsPlumb.deleteEndpoint(ep);
                        });
                        const percent = 1.0 / (exitPoints.length + 1);
                        for (let i = 0; i < exitPoints.length; i++) {
                            const exPo = exitPoints[i];

                            const expoInfo = {
                                anchor: [],
                                isSource: true,
                                endpoint: ['Dot', { radius: 5 }],
                                uuid: 'exit_' + this.DataValue.ID + '_' + exPo.ID,
                            };
                            let pos = (percent * (i + 1)) + (percent / 2);
                            let per = percent * (i + 1);
                            switch (this.DataValue.ViewType) {
                                case WorkflowViewType.Condition:
                                    let hpos = 0;
                                    if (pos > 0.5) {
                                        hpos = pos - 0.5;
                                    } else {
                                        hpos = 0.5 - pos;
                                    }
                                    let vpos = 0;
                                    if (per > 0.5) {
                                        vpos = per - 0.5;
                                    } else {
                                        vpos = 0.5 - per;
                                    }
                                    expoInfo.anchor = [[per, 1-vpos],[hpos, pos], [1-hpos, pos]];
                                    break;
                                case WorkflowViewType.Start:
                                case WorkflowViewType.Action:
                                default:
                                    expoInfo.anchor = [[per, 1, 0, 1], [0, pos], [1, pos]];
                                    break;
                            }

                            if (typeof exPo.Type === 'string') {
                                expoInfo['parameters'] = {
                                    EndPointType: exPo.Type
                                };
                                const info = WF_TYPE_REGISTRY.get(exPo.Type);
                                if (info) {
                                    expoInfo['paintStyle'] = {
                                        fill: info.Color
                                    };
                                }
                            }
                            const ep = jsPlumb.addEndpoint(this.ElemIDValue, expoInfo);
                            if (exPo.Label) {
                                const label = this.translate.instant(exPo.Label);
                                ep.addOverlay(['Label', { label: label, cssClass: 'exitPointLabel', location: [0.5, 2.5] }]);
                                ep.bind('mouseover', (exP, ev) => {
                                    exP.showOverlays();
                                });
                                ep.bind('mouseout', (exP, ev) => {
                                    exP.hideOverlays();
                                });
                                ep.hideOverlays();
                            }
                            this.ActualExitPoints.push(ep);
                        }
                    }
                }
            }
        }
    }
    @Output() RemoveClicked = new EventEmitter<any>();
    RemoveItem() {
        this.RemoveClicked.emit(this.DataValue.ID);
    }
}
