import { Component, OnInit, Renderer2 } from '@angular/core';
declare var LiveAgent: any;
@Component({
  selector: 'lib-live-agent-chat-button',
  templateUrl: './live-agent-chat-button.component.html',
  styleUrls: ['./live-agent-chat-button.component.css']
})
export class LiveAgentChatButtonComponent implements OnInit {

	constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const scriptUrl = 'https://ga-universe.ladesk.com/scripts/track.js';
    const node = this.renderer.createElement('script');
    node.src = scriptUrl;
    node.id = 'la_x2s6df8d';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = (e) => {
      const chatButtonContainer = document.getElementById('chatButton');
      if (chatButtonContainer) {
        LiveAgent.createButton('avx216f2', chatButtonContainer);
      }
    };
    this.renderer.appendChild(document.head, node);
  }
} 
