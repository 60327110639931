import { Directive } from '@angular/core';
import { BaseListDetail } from '../../base.list.settings';

@Directive()
export abstract class ADataModelDetail extends BaseListDetail {
    InitArgsValue;
    get InitArgs() {
        return this.InitArgsValue;
    }
    set InitArgs(val) {
        this.InitArgsValue = val;
        if (val && val.IsConnector) {
            this.ShowCapsuleTags = false;
        } else {
            this.ShowCapsuleTags = true;
        }
    }

    ShowCapsuleTags = true;
}
