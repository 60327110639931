import { Subject } from 'rxjs';
import { QueryExecuter } from '../../components/query/executer.query';
import { AdvancedFormulaParser } from '../../helpers/formula.parser';
import { InjectorHelper } from '../../helpers/injector.helper';
import { TaskRegistry } from '../../helpers/task.registry';
import { PlanningService } from '../../services/planning.service';
import { Column } from '../basic/column.model';
import { ValueAndType } from '../basic/formulaEditor.model';
import { LayoutUnit } from '../basic/layoutunit.model';
import { Row } from '../basic/row.model';
import { CellSelection, CopySelection, RowSelection } from '../basic/selection.model';
import { ValueFormulaNodeCalculator } from '../calculation/formula.node.calculator';
import { GradientType } from '../enums/gradienttype.enum';
import { PlanType } from '../enums/plantype.enum';
import { UnitType } from '../enums/unittype.enum';
import { VariableDisplay } from '../enums/variabledisplay.enum';
import { PlanElement, PlanValue, PlanValues } from '../planning/planvalues.model';
import { Border } from '../style/border.model';
import { BorderSide } from '../style/borderside.model';
import { Color } from '../style/color.model';
import { Gradient } from '../style/gradient.model';
import { GradientStopColor } from '../style/gradientstopcolor.model';
import { CellStyle } from '../styling/cell.style';
import { StyleMerger } from '../styling/styleMerger';
import { IPlanningTaskExecuter } from '../tasks/atask.model';
import { PlanningCellFormattingTaskExecuter } from '../tasks/planning/cell.formatting.task';
import { PlanningContentFormattingTaskExecuter } from '../tasks/planning/content.formatting.task';
import { PlanningHeaderFormattingTaskExecuter } from '../tasks/planning/header.formatting.task';
import { PlanningRowDescFormattingTaskExecuter } from '../tasks/planning/rowdesc.formatting.task';
import { TaskHelper } from '../tasks/task.helper';
import { TableDataSource } from './chart.datasource';

export class PlanningTableDataSource extends TableDataSource {

    ActiveSheet;
    //#region Properties
    private Matrices: {};
    private References;
    private Cells;
    private ExecutionContext;
    private CustomContentStyle;
    Columns: Subject<any> = new Subject();
    private ColumnsValue = {};
    Data: Subject<any> = new Subject();
    RowsValue = {};
    private planningService: PlanningService;
    //#endregion

    constructor(protected reportObject) {
        super(reportObject);
        this.planningService = InjectorHelper.InjectorInstance.get<PlanningService>(PlanningService);
    }

    GetSelectedCoordinatesFromRowSelection(selection: RowSelection[]): any[] {
        throw new Error("Method not implemented.");
    }
    GetSelectedCoordinatesFromCellSelection(selection: CellSelection[]): any[] {
        throw new Error("Method not implemented.");
    }

    async Refresh() {
        this.Initialized.next(true);
        this.Loading.next(true);
        this.ExecutionContext = {};
        this.ExecutionContext['Styles'] = { BaseReportObject: this.reportObject };
        this.Matrices = {};
        if (this.reportObject.DataValue) {
            for (let i = 0; i < this.reportObject.DataValue.length; i++) {
                let data = this.reportObject.DataValue[i];
                if (!data.IsRelational) {
                    this.ExecutionContext[data.ID] = { BaseReportObject: this.reportObject };
                    const executer = new QueryExecuter(this.reportObject.DataDescriptions[data.ID], data.ID);
                    this.Matrices[data.ID] = await executer.execute(this.ExecutionContext[data.ID], true);
                }
            }
        }
        this.GenerateColumnsAndRows();
    }
    async RefreshStyles() {
        for (let she = 0; she < this.reportObject.LayoutElement.Sheets.length; she++) {
            let sheet = this.reportObject.LayoutElement.Sheets[she];
            const styleMerger = StyleMerger.GetStyleMerger(this.ExecutionContext['Styles']);
            await this.CreateCustomGridStyles(this.cachedData, this.ColumnsValue[sheet.Name]);
            await this.CreateCustomCellStyles(this.cachedData, this.ColumnsValue[sheet.Name]);

            let cellStyles = {};
            if (styleMerger) {
                cellStyles = styleMerger.GetStyleObject();
            }
            this.CellStyles.next(cellStyles);
        }
        this.Data.next(this.RowsValue);
    }
    RefreshCalculations() {
        this.RefreshCellsAndReferences();
        for (let she = 0; she < this.reportObject.LayoutElement.Sheets.length; she++) {
            let sheet = this.reportObject.LayoutElement.Sheets[she];
            let refKeys = Object.keys(this.References[sheet.Name]);
            let refcount = refKeys.length;
            for (let i = 0; i < refcount; i++) {
                let reference = this.References[sheet.Name][refKeys[i]];
                reference.Value = this.GetMatrixValue(reference, sheet.Name);
            }
            let cellKeys = Object.keys(this.Cells[sheet.Name]);
            let cellcount = cellKeys.length;
            for (let i = 0; i < cellcount; i++) {
                let cell = this.Cells[sheet.Name][cellKeys[i]];
                if (cell.Formula) {
                    this.RowsValue[sheet.Name][cell.Row].data[cell.Column] = this.GetFormulaValue(cell.Formula);
                }
                this.RowsValue[sheet.Name][cell.Row]['internal'][cell.Column] = this.RowsValue[sheet.Name][cell.Row].data[cell.Column];
                if (this.CustomContentStyle && this.CustomContentStyle.NumberFormat) {
                    this.RowsValue[sheet.Name][cell.Row].data[cell.Column] = TaskHelper.GetFormatedValue(this.RowsValue[sheet.Name][cell.Row]['internal'][cell.Column], this.CustomContentStyle.NumberFormat);
                }
            }
        }
        this.RefreshStyles();
    }
    RefreshCellsAndReferences() {
        this.References = {};
        this.Cells = {};
        for (let she = 0; she < this.reportObject.LayoutElement.Sheets.length; she++) {
            let sheet = this.reportObject.LayoutElement.Sheets[she];
            for (let i = 0; i < sheet.TableSize.Rows; i++) {
                this.ColumnsValue[sheet.Name].forEach((col) => {
                    if (col.Name != 'fixedColumn') {
                        if (this.reportObject.LayoutElement.CellDescriptions && this.reportObject.LayoutElement.CellDescriptions[sheet.Name] && this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i] && this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name]) {
                            let formula = this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].ViewFormula;
                            if (this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].UseReference) {
                                if (!this.References[sheet.Name]) {
                                    this.References[sheet.Name] = {}
                                }
                                this.References[sheet.Name][col.Name + (i + 1)] = this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].Reference;
                                if (!formula) {
                                    formula = col.Name + (i + 1);
                                }
                            }
                            if (!this.Cells[sheet.Name]) {
                                this.Cells[sheet.Name] = {}
                            }
                            this.Cells[sheet.Name][col.Name + (i + 1)] = {
                                Formula: formula,
                                Row: i,
                                Column: col.Name
                            };
                        }
                    }
                })
            }
        }
    }

    async SaveChanges(values) {
        let retVal = {};
        if (values) {
            values.forEach((value) => {
                let cellRef = this.References[value.Sheet][value.ColumnName + (value.RowIndex + 1)];
                if (cellRef.DataDescriptionID && !retVal[cellRef.DataDescriptionID]) {
                    retVal[cellRef.DataDescriptionID] = {}
                }
                let item = new PlanValue();
                item.Value = parseFloat(value.NewValue);
                item.OldValue = value.OldValue;
                item.Type = cellRef.PlanType != null ? cellRef.PlanType : PlanType.Equal;
                item.XElements = [];
                item.YElements = [];
                if (cellRef.UseMeasure) {
                    item.MeasureUniqueId = cellRef.Measure;
                    let X = this.References[value.Sheet][cellRef.XLevel];
                    let Y = this.References[value.Sheet][cellRef.YLevel];
                    if (!retVal[cellRef.DataDescriptionID][item.MeasureUniqueId]) {
                        retVal[cellRef.DataDescriptionID][item.MeasureUniqueId] = [];
                    }
                    item[X.Axis + 'Elements'].push(this.CreatePlanElement(X, X.Axis));
                    item[Y.Axis + 'Elements'].push(this.CreatePlanElement(Y, Y.Axis));
                    retVal[cellRef.DataDescriptionID][item.MeasureUniqueId].push(item);
                } else if (cellRef.UseFullReference) {
                    let X = this.GetActiveArea(cellRef.X);
                    item['XElements'].push(this.CreatePlanElement(cellRef, 'X'));
                    let Y = this.GetActiveArea(cellRef.Y);
                    item['YElements'].push(this.CreatePlanElement(cellRef, 'Y'));
                    if (X.MeasureID != null) {
                        item.MeasureUniqueId = X.MeasureID;
                    } else if (Y.MeasureID != null) {
                        item.MeasureUniqueId = Y.MeasureID;
                    }
                }
            })
            let datadescriptions = Object.keys(retVal);
            for (let ds = 0; ds < datadescriptions.length; ds++) {
                let dsid = datadescriptions[ds];
                let measureIDS = Object.keys(retVal[dsid]);
                measureIDS.forEach((measure) => {
                    let queryDef = null;
                    this.ExecutionContext[dsid].QueryDefinitions.forEach((qd) => {
                        let query = qd.Measures.find((value) => {
                            return value.UniqueID == measure
                        })
                        if (query) {
                            queryDef = qd;
                        }
                    })
                    let saveObject = new PlanValues();
                    saveObject.Values = retVal[dsid][measure];
                    saveObject.Query = queryDef;
                    this.planningService.SaveValues(saveObject).subscribe();
                })
            }
        }
    }
    CreatePlanElement(Reference, Axis) {
        let level = this.GetMatrixLevel(this.Matrices[Reference.DataDescriptionID][Axis + 'Axis'], Reference, Axis);

        let element = new PlanElement();
        element.ElementId = level.Key;
        element.UniqueId = level.UniqueID;
        return element;
    }
    private GetMatrixLevel(Matrix, Reference, Axis) {
        let retVal = null;

        if (Reference.MemberIndex != null) {
            let nodes = this.GetLevelNodes(Matrix.Nodes, Reference);
            retVal = nodes[Reference.MemberIndex];
        }

        if (Reference != null) {
            let area = this.GetActiveArea(Reference[Axis]);
            let levels = Object.keys(area.Levels);
            let items = Matrix.Nodes;
            for (let levelindex = 0; levelindex < levels.length; levelindex++) {
                let level = area.Levels[levels[levelindex]];
                if (level.MemberID == null) {
                    break;
                }
                let res = items.find((value) => {
                    return value.UniqueID == level.LevelID && value.MemberId == level.MemberID;
                });
                if (res) {
                    retVal = res;
                }
                if (res.Children && res.Children.length > 0) {
                    items = res.Children;
                } else {
                    break;
                }
            }
        }
        return retVal;
    }
    private async GenerateColumnsAndRows() {
        const styleMerger = StyleMerger.GetStyleMerger(this.ExecutionContext['Styles']);

        await this.CreateDefaultGridStyles(styleMerger);
        await this.CreateCustomGridStyles(null, null);
        if (this.reportObject.LayoutElement.Sheets && this.reportObject.LayoutElement.Sheets.length > 0) {
            this.Cells = {};
            this.References = {};
            //#region Create Rows & Fill References/Cells
            for (let she = 0; she < this.reportObject.LayoutElement.Sheets.length; she++) {
                let sheet = this.reportObject.LayoutElement.Sheets[she];
                if (!sheet.TableSize) {
                    this.reportObject.LayoutElement.TableSize = {
                        Columns: 26,
                        Rows: 100
                    }
                }

                //#region Columns
                if (!this.ColumnsValue[sheet.Name]) {
                    this.ColumnsValue[sheet.Name] = [];
                }
                const cols = [];
                cols.push({
                    Caption: '',
                    Name: 'fixedColumn',
                    Data: 'fixedColumn',
                    IsSortable: false,
                    IsFilterable: false,
                    IsCommaSeparated: false,
                    OrderState: 'NONE',
                    Width: {
                        Value: 50,
                        Type: UnitType.Pixel
                    },
                    SearchValue: null,
                    IsVisible: true,
                    IsSticky: true,
                    Prefix: '',
                    Suffix: '',
                    NumericPrecision: 0,
                    DateFormat: '',
                    Expandable: false,
                    StyleID: parseInt(styleMerger["DefaultHeaderStyle"])
                });
                const chars = this.GenerateCharArray(sheet.TableSize.Columns);
                for (let i = 0; i < chars.length; i++) {
                    const char = chars[i];
                    cols.push({
                        Caption: char,
                        Name: char,
                        Data: char,
                        IsSortable: false,
                        IsFilterable: false,
                        IsCommaSeparated: false,
                        OrderState: 'NONE',
                        Width: {
                            Value: 100,
                            Type: UnitType.Pixel
                        },
                        SearchValue: null,
                        IsVisible: true,
                        IsSticky: false,
                        Prefix: '',
                        Suffix: '',
                        NumericPrecision: 0,
                        DateFormat: '',
                        Expandable: false,
                        StyleID: parseInt(styleMerger["DefaultHeaderStyle"])
                    });
                }
                this.ColumnsValue[sheet.Name] = cols;
                //#endregion
                //#region Rows
                if (!this.RowsValue[sheet.Name]) {
                    this.RowsValue[sheet.Name] = [];
                } 
                const rows = [];
                this.reportObject.LayoutElement.References.forEach((ref) => {
                    this.References[ref.Name] = ref;
                });
                for (let i = 0; i < sheet.TableSize.Rows; i++) {
                    let row = new Row();
                    row.data = {};
                    row['internal'] = {};
                    row.index = i;
                    const colDesc = {};
                    cols.forEach((col) => {
                        colDesc[col.Name] = {};
                    })
                    row.cols = colDesc;
                    const styleDesc = {};
                    cols.forEach((col) => {
                        if (col.Name != 'fixedColumn') {
                            styleDesc[col.Name] = styleMerger["DefaultCellStyle"];
                        } else {
                            styleDesc[col.Name] = styleMerger["DefaultRowDescStyle"];
                        }
                    })
                    row.styles = styleDesc;
                    cols.forEach((col) => {
                        if (col.Name != 'fixedColumn') {
                            if (this.reportObject.LayoutElement.CellDescriptions && this.reportObject.LayoutElement.CellDescriptions[sheet.Name] && this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i] && this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name]) {
                                let formula = this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].ViewFormula;
                                if (this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].UseReference) {
                                    if (!this.References[sheet.Name]) {
                                        this.References[sheet.Name] = {};
                                    }
                                    this.References[sheet.Name][col.Name + (i + 1)] = this.reportObject.LayoutElement.CellDescriptions[sheet.Name][i][col.Name].Reference;
                                    if (!formula) {
                                        formula = col.Name + (i + 1);
                                    }
                                }
                                if (!this.Cells[sheet.Name]) {
                                    this.Cells[sheet.Name] = {};
                                }
                                this.Cells[sheet.Name][col.Name + (i + 1)] = {
                                    Formula: formula,
                                    Row: i,
                                    Column: col.Name
                                };
                            } else {
                                row.data[col.Name] = '';
                                row['internal'][col.Name] = null;
                            }
                        }
                    });
                    row.data['fixedColumn'] = i + 1;
                    rows.push(row);
                }
                if (this.References && this.References[sheet.Name]) {
                    let refKeys = Object.keys(this.References[sheet.Name]);
                    let refcount = refKeys.length;
                    for (let i = 0; i < refcount; i++) {
                        let reference = this.References[sheet.Name][refKeys[i]];
                        reference.Value = this.GetMatrixValue(reference, sheet.Name);
                    }
                }
                await this.CreateCustomCellStyles(rows, cols);
                this.RowsValue[sheet.Name] = rows;
                //#endregion
            }
            //#endregion
            //#region Fill Row values
            for (let she = 0; she < this.reportObject.LayoutElement.Sheets.length; she++) {
                let sheet = this.reportObject.LayoutElement.Sheets[she];
                if (this.Cells && this.Cells[sheet.Name]) {
                    Object.keys(this.Cells[sheet.Name]).forEach(async (key) => {
                        let cell = this.Cells[sheet.Name][key];
                        if (cell.Formula) {
                            this.RowsValue[sheet.Name][cell.Row].data[cell.Column] = await this.GetFormulaValue(cell.Formula);
                        }
                        this.RowsValue[sheet.Name][cell.Row]['internal'][cell.Column] = this.RowsValue[sheet.Name][cell.Row].data[cell.Column];
                        if (this.CustomContentStyle && this.CustomContentStyle.NumberFormat) {
                            this.RowsValue[sheet.Name][cell.Row].data[cell.Column] = TaskHelper.GetFormatedValue(this.RowsValue[sheet.Name][cell.Row]['internal'][cell.Column], this.CustomContentStyle.NumberFormat);
                        }
                    })
                }
            }
            //#endregion
        }
        
        this.Columns.next(this.ColumnsValue);
        this.Data.next(this.RowsValue);

        let cellStyles = {};
        if (styleMerger) {
            cellStyles = styleMerger.GetStyleObject();
        }
        this.CellStyles.next(cellStyles);

        this.Loading.next(false);
    }

    CellCopy(value: CopySelection) {
        const cols = Object.keys(this.RowsValue[this.ActiveSheet.Name][0].cols);
        let ColumnIndex = cols.indexOf(value.StartCell.ColumnName);

        const Parser = new AdvancedFormulaParser();

        if (value.Selection.YStart == value.StartCell.RowIndex) {
            for (let row = value.Selection.YStart, rowindex = 0; row <= value.Selection.YEnd; row++, rowindex++) {
                if (row != value.StartCell.RowIndex) {
                    this.UpdateDescription(cols, 'row', row, rowindex, Parser, value.StartCell);
                }
                let cell = {
                    RowIndex: row,
                    ColumnName: value.StartCell.ColumnName
                }
                if (value.Selection.XStart == ColumnIndex) {
                    for (let col = value.Selection.XStart + 1, colindex = 1; col <= value.Selection.XEnd; col++, colindex++) {
                        this.UpdateDescription(cols, 'column', col, colindex, Parser, cell);
                    }
                }
                if (value.Selection.XEnd == ColumnIndex) {
                    for (let col = value.Selection.XEnd - 1, colindex = -1; col >= value.Selection.XStart; col--, colindex--) {
                        this.UpdateDescription(cols, 'column', col, colindex, Parser, cell);
                    }
                }
            }
        } else if (value.Selection.YEnd == value.StartCell.RowIndex) {
            for (let row = value.Selection.YEnd, rowindex = 0; row >= value.Selection.YStart; row--, rowindex--) {
                if (row != value.StartCell.RowIndex) {
                    this.UpdateDescription(cols, 'row', row, rowindex, Parser, value.StartCell);
                }
                let cell = {
                    RowIndex: row,
                    ColumnName: value.StartCell.ColumnName
                }
                if (value.Selection.XStart == ColumnIndex) {
                    for (let col = value.Selection.XStart + 1, colindex = 1; col <= value.Selection.XEnd; col++, colindex++) {
                        this.UpdateDescription(cols, 'column', col, colindex, Parser, cell);
                    }
                }
                if (value.Selection.XEnd == ColumnIndex) {
                    for (let col = value.Selection.XEnd - 1, colindex = -1; col >= value.Selection.XStart; col--, colindex--) {
                        this.UpdateDescription(cols, 'column', col, colindex, Parser, cell);
                    }
                }
            }
        }

        this.RefreshCalculations();
    }
    UpdateDescription(cols, type, index, value, Parser, start) {
        let CellDescriptions = this.reportObject.LayoutElement.CellDescriptions[this.ActiveSheet.Name];
        let StartCell = {};
        if (CellDescriptions[start.RowIndex] && CellDescriptions[start.RowIndex][start.ColumnName]) {
            StartCell = CellDescriptions[start.RowIndex][start.ColumnName];
        }
        let RowValue = 0;
        let ColumnName = '';
        if (type == 'column') {
            if (StartCell['Reference'] && StartCell['Reference'].XLevel) {
                RowValue = parseInt(StartCell['Reference'].XLevel.match(/\d/g).join(''));
                ColumnName = StartCell['Reference'].XLevel.replace(/[0-9]/g, '');
            }
            let Description = JSON.parse(JSON.stringify(StartCell));
            
            if (Description.ViewFormula) {
                const formulaDescription = Parser.Parse(Description.ViewFormula);
                this.UpdateFormula(formulaDescription, cols, type, value);
                Description.ViewFormula = formulaDescription.ToString(null, VariableDisplay.NodeName);
            }
            if (Description.UseReference) {
                if (Description.Reference.UseLevel) {
                    Description.Reference.MemberIndex += value;
                }
                if (Description.Reference.UseMeasure) {
                    Description.Reference.XLevel = cols[cols.indexOf(ColumnName) + value] + RowValue;
                }
                this.References[this.ActiveSheet.Name][cols[index] + (start.RowIndex + 1)] = Description.Reference;
            } else {
                delete this.References[this.ActiveSheet.Name][cols[index] + (start.RowIndex + 1)];
            }

            CellDescriptions[start.RowIndex][cols[index]] = Description;
            this.Cells[this.ActiveSheet.Name][cols[index] + (start.RowIndex + 1)] = {
                Formula: Description.ViewFormula ? Description.ViewFormula : cols[index] + (start.RowIndex + 1),
                Row: start.RowIndex,
                Column: cols[index]
            };
        }
        if (type == 'row') {
            if (StartCell['Reference'] && StartCell['Reference'].YLevel) {
                RowValue = parseInt(StartCell['Reference'].YLevel.match(/\d/g).join(''));
                ColumnName = StartCell['Reference'].YLevel.replace(/[0-9]/g, '');
            }
            if (!CellDescriptions[index]) {
                CellDescriptions[index] = {};
            }
            let Description = JSON.parse(JSON.stringify(StartCell));
            
            if (Description.ViewFormula) {
                const formulaDescription = Parser.Parse(Description.ViewFormula);
                this.UpdateFormula(formulaDescription, cols, type, value);
                Description.ViewFormula = formulaDescription.ToString(null, VariableDisplay.NodeName);
            }
            if (Description.UseReference) {
                if (Description.Reference.UseLevel) {
                    Description.Reference.MemberIndex += value;
                }
                if (Description.Reference.UseMeasure) {
                    Description.Reference.YLevel = ColumnName + (RowValue + value);
                }
                this.References[this.ActiveSheet.Name][start.ColumnName + (index + 1)] = Description.Reference;
            } else {
                delete this.References[this.ActiveSheet.Name][start.ColumnName + (index + 1)];
            }
            CellDescriptions[index][start.ColumnName] = Description;
            this.Cells[this.ActiveSheet.Name][start.ColumnName + (index+1)] = {
                Formula: Description.ViewFormula ? Description.ViewFormula : start.ColumnName + (index + 1),
                Row: index,
                Column: start.ColumnName
            };
        }
    }
    UpdateFormula(operand, cols, type, value) {
        let operandName = null;
        let sheet = null;
        let containsd = false;
        if (operand.Name) {
            containsd = operand.Name.indexOf('$') !== -1;
            let val = this.GetOperandAndSheet(operand);
            operandName = val.Operand;
            sheet = val.Sheet;
        }
        if (operand.VariableID != null) {
            if (this.References[sheet][operandName] || this.Cells[sheet][operandName]) {
                if (!containsd) {
                    const RowValue = parseInt(operandName.match(/\d/g).join(''));
                    const ColumnName = operandName.replace(/[0-9]/g, '');
                    if (type == 'column') {
                        operand.Name = cols[cols.indexOf(ColumnName) + value] + RowValue;
                    }
                    if (type == 'row') {
                        operand.Name = ColumnName + (RowValue + value);
                    }
                    if (sheet !== this.ActiveSheet.Name) {
                        operand.Name = sheet + '.' + operand.Name;
                    }
                    operand.AliasKey = operand.Name;
                    operand.SaveTag = operand.Name;
                    operand.VariableID = operand.Name;
                }
            }
        }
        if (operand.Operands && operand.Operands.length > 0) {
            for (let i = 0; i < operand.Operands.length; i++) {
                this.UpdateFormula(operand.Operands[i], cols, type, value);
            }
        }
    }

    //#region Styles
    private GetBorder(thickness, colorR, colorG, colorB) {
        const retVal = new BorderSide();
        retVal.Thickness = new LayoutUnit();
        retVal.Thickness.Type = UnitType.Pixel;
        retVal.Thickness.Value = thickness;
        retVal.Color = new Gradient();
        const color = new GradientStopColor();
        color.Color = new Color();
        color.Color.R = colorR;
        color.Color.G = colorG;
        color.Color.B = colorB;
        retVal.Color.Colors.push(color);
        retVal.Color.Type = GradientType.Solid;
        return retVal;
    }
    private CreateDefaultGridStyles(styleMerger) {
        // Default Border
        const borderStyle = new CellStyle();
        borderStyle.Border = new Border();
        borderStyle.Border.RightBorder = this.GetBorder(1, 232, 232, 232);
        borderStyle.Border.BottomBorder = this.GetBorder(1, 232, 232, 232);
        styleMerger["DefaultCellStyle"] = '' + styleMerger.MergeStyle(0, borderStyle);


        const headerStyle = new CellStyle();
        headerStyle.Border = new Border();
        headerStyle.Border.BottomBorder = this.GetBorder(2, 127, 127, 127);
        headerStyle.Border.TopBorder = this.GetBorder(2, 127, 127, 127);
        headerStyle.Border.RightBorder = this.GetBorder(2, 127, 127, 127);
        headerStyle.BackgroundColor = new Gradient();
        const color = new GradientStopColor();
        color.Color = new Color();
        color.Color.R = 170;
        color.Color.G = 170;
        color.Color.B = 170;
        headerStyle.BackgroundColor.Colors.push(color);
        headerStyle.BackgroundColor.Type = GradientType.Solid;

        styleMerger["DefaultHeaderStyle"] = '' + styleMerger.MergeStyle(0, headerStyle);

        const rowsDescLeaveStyle = new CellStyle();
        rowsDescLeaveStyle.Border = new Border();
        rowsDescLeaveStyle.Border.BottomBorder = this.GetBorder(2, 127, 127, 127);
        rowsDescLeaveStyle.Border.LeftBorder = this.GetBorder(2, 127, 127, 127);
        rowsDescLeaveStyle.Border.RightBorder = this.GetBorder(2, 127, 127, 127);
        rowsDescLeaveStyle.BackgroundColor = new Gradient();
        const cellcolor = new GradientStopColor();
        cellcolor.Color = new Color();
        cellcolor.Color.R = 170;
        cellcolor.Color.G = 170;
        cellcolor.Color.B = 170;
        rowsDescLeaveStyle.BackgroundColor.Colors.push(cellcolor);
        rowsDescLeaveStyle.BackgroundColor.Type = GradientType.Solid;

        styleMerger["DefaultRowDescStyle"] = '' + styleMerger.MergeStyle(0, rowsDescLeaveStyle);
    }
    private async CreateCustomGridStyles(Rows, Columns) {
        if (this.reportObject && this.reportObject.LayoutElement && this.reportObject.LayoutElement.FormatTasks) {
            for (let i = 0; i < this.reportObject.LayoutElement.FormatTasks.length; i++) {
                const task = this.reportObject.LayoutElement.FormatTasks[i];
                if (task && task.TaskType && task.IsActive === true &&
                        (task.TaskType == PlanningContentFormattingTaskExecuter.TaskID ||
                        task.TaskType == PlanningHeaderFormattingTaskExecuter.TaskID ||
                        task.TaskType == PlanningRowDescFormattingTaskExecuter.TaskID)) {
                    const desc = TaskRegistry.get(task.TaskType);
                    if (desc && desc.Executer) {
                        const executer: IPlanningTaskExecuter = new desc.Executer();
                        await executer.Init(task, Rows, Columns, this.ExecutionContext['Styles']);
                        await executer.Execute();
                    }
                    if (task.TaskType == PlanningContentFormattingTaskExecuter.TaskID) {
                        this.CustomContentStyle = task.Style;
                    }
                }
            }
        }
    }
    private async CreateCustomCellStyles(Rows: Row[], Columns: Column[]) {
        if (this.reportObject && this.reportObject.LayoutElement && this.reportObject.LayoutElement.Sheets && this.ActiveSheet && this.reportObject.LayoutElement.Sheets[this.ActiveSheet.Name] && this.reportObject.LayoutElement.Sheets[this.ActiveSheet.Name].FormatTasks) {
            for (let i = 0; i < this.reportObject.LayoutElement.FormatTasks.length; i++) {
                const task = this.reportObject.LayoutElement.FormatTasks[i];
                if (task && task.TaskType && task.IsActive === true && task.TaskType == PlanningCellFormattingTaskExecuter.TaskID) {
                    const desc = TaskRegistry.get(task.TaskType);
                    if (desc && desc.Executer) {
                        const executer: IPlanningTaskExecuter = new desc.Executer();
                        await executer.Init(task, Rows, Columns, this.ExecutionContext['Styles']);
                        await executer.Execute();
                    }
                }
            }
        }
    }
    //#endregion
    //#region Helper
    private GenerateCharArray(size) {
        const result = [];
        const aIndex = 'A'.charCodeAt(0);
        const zIndex = 'Z'.charCodeAt(0);
        const count = (zIndex - aIndex) + 1;
        for (let i = 0; i < size; ++i) {
            let t = 0;
            if (i < count) {
                t = 0;
            } else if (i > 0) {
                t = Math.floor(i / count);
            }
            let retVal = '';
            if (t > 0) {
                retVal += String.fromCharCode(aIndex + (t-1));
            }
            let index = aIndex + (i - (count * t));
            retVal += String.fromCharCode(index);
            result.push(retVal);
        }
        return result;
    }
    //#endregion
    //#region DataHandling
    private GetMatrixValue(Variable, Sheet) {
        let retVal = null;
        if (Variable.DataDescriptionID && this.Matrices[Variable.DataDescriptionID]) {
            if (Variable.UseLevel || Variable.UseMeasure) {
                retVal = this.GetReferenceValue(Variable, Sheet);
            } else {
                let xArea = this.GetActiveArea(Variable.X);
                let x = this.GetPosition(this.Matrices[Variable.DataDescriptionID].XAxis, Variable.X, xArea.Index);
                let yArea = this.GetActiveArea(Variable.Y);
                let y = this.GetPosition(this.Matrices[Variable.DataDescriptionID].YAxis, Variable.Y, yArea.Index);
                let measureID = 0;
                if (xArea.MeasureID != null) {
                    measureID = xArea.MeasureID;
                }
                if (yArea.MeasureID != null) {
                    measureID = yArea.MeasureID;
                }
                let m = this.GetMeasurePosition(this.Matrices[Variable.DataDescriptionID].Measures, measureID);

                if (x != null && y != null && m != null) {
                    let cell = this.Matrices[Variable.DataDescriptionID].Cells[x][y][m];
                    retVal = ValueAndType.GetValueAndTypeFromJSObject(cell.InternalValue);
                }
            }
        }
        return retVal;
    }
    private GetActiveArea(Axis) {
        let retVal = null;
        let areas = Object.keys(Axis);
        for (let areaindex = 0; areaindex < areas.length; areaindex++) {
            let area = Axis[areas[areaindex]];
            if (area.Selected) {
                area.Index = areas[areaindex];
                retVal = area;
            }
        }
        return retVal;
    }
    private GetPosition(Matrix, Axis, Area) {
        let retVal = null;
        if (Area != null) {
            let area = Axis[Area];
            let levels = Object.keys(area.Levels);
            let items = Matrix.Nodes;
            for (let levelindex = 0; levelindex < levels.length; levelindex++) {
                let level = area.Levels[levels[levelindex]];
                if (level.MemberID == null) {
                    break;
                }
                let res = items.find((value) => {
                    return value.UniqueID == level.LevelID && value.MemberId == level.MemberID;
                });
                if (res) {
                    retVal = res.Position;
                }
                if (res.Children && res.Children.length > 0) {
                    items = res.Children;
                } else {
                    break;
                }
            }
        }
        return retVal;
    }
    private GetMeasurePosition(Matrix, MeasureID) {
        let retVal = null;
        let res = Matrix.Nodes.find((value) => {
            return value.UniqueID == MeasureID;
        });
        if (res) {
            retVal = res.Position;
        }
        return retVal;
    }
    private GetFormulaValue(formula) {
        let retVal = '';
        if (formula) {
            const Parser = new AdvancedFormulaParser();
            const formulaDescription = Parser.Parse(formula);
            let Values = new Map();
            let Variables = [];
            
            this.FillVariablesAndValues(formulaDescription, Values, Variables);

            let circular = false;
            for (let [k, v] of Values) {
                if (v != null && v.Value == '!CircularDependency!') {
                    circular = true;
                }
            }
            if (circular) {
                retVal = '!CircularDependecy!';
            } else {
                let Calculator = new ValueFormulaNodeCalculator(false, Variables ? Variables : [], Values);
                try {
                    const res = Calculator.Calc(formulaDescription);
                    if (res != null) {
                        retVal = res.Value;
                    }
                } catch {
                    retVal = '!CalculationError!'
                }
            }
        }
        return retVal;
    }
    private GetReferenceValue(reference, Sheet) {
        let retVal = null;
        if (reference.UseLevel) {
            if (reference.Axis == 'X') {
                let nodes = this.GetLevelNodes(this.Matrices[reference.DataDescriptionID].XAxis.Nodes, reference);
                if (nodes && nodes.length > reference.MemberIndex && reference.MemberIndex >= 0) {
                    retVal = ValueAndType.GetValueAndTypeFromJSObject(nodes[reference.MemberIndex].Caption);
                }
            }
            if (reference.Axis == 'Y') {
                let nodes = this.GetLevelNodes(this.Matrices[reference.DataDescriptionID].YAxis.Nodes, reference);
                if (nodes && nodes.length > reference.MemberIndex && reference.MemberIndex >= 0) {
                    retVal = ValueAndType.GetValueAndTypeFromJSObject(nodes[reference.MemberIndex].Caption);
                }
            }
        }
        if (reference.UseMeasure) {
            let x = null;
            if (reference.XLevel && this.References[Sheet][reference.XLevel]) {
                let ref = this.References[Sheet][reference.XLevel];
                if (ref) {
                    let ds = this.ExecutionContext[reference.DataDescriptionID].BaseReportObject.DataDescriptionsValue[ref.DataDescriptionID];
                    if (ds) {
                        let nodes = this.GetLevelNodes(this.Matrices[ref.DataDescriptionID].XAxis.Nodes, ref);

                        if (nodes && nodes.length > ref.MemberIndex) {
                            x = nodes[ref.MemberIndex].Position;
                        }
                    }
                }
            }
            let y = null;
            if (reference.YLevel && this.References[Sheet][reference.YLevel]) {
                let ref = this.References[Sheet][reference.YLevel];
                if (ref) {
                    let ds = this.ExecutionContext[reference.DataDescriptionID].BaseReportObject.DataDescriptionsValue[ref.DataDescriptionID];
                    if (ds) {
                        let nodes = this.GetLevelNodes(this.Matrices[ref.DataDescriptionID].YAxis.Nodes, ref);
                        if (nodes && nodes.length > ref.MemberIndex) {
                            y = nodes[ref.MemberIndex].Position;
                        }
                    }
                }
            }
            if (x != null && y != null) {
                let node = this.Matrices[reference.DataDescriptionID].Measures.Nodes.find((value) => {
                    return value.UniqueID == reference.Measure;
                });
                if (node) {
                    let m = node.Position;

                    let cell = this.Matrices[reference.DataDescriptionID].Cells[x][y][m];
                    retVal = ValueAndType.GetValueAndTypeFromJSObject(cell?cell.InternalValue:'');
                    reference.Value = retVal;
                }
            }
        }
        return retVal;
    }
    private GetLevelNodes(Nodes, reference) {
        let retVal = [];
        for (let i = 0; i < Nodes.length; i++) {
            let node = Nodes[i];
            if (node.UniqueID == reference.Level) {
                retVal.push(node);
            }
            if (node.Children && node.Children.length > 0) {
                retVal.push(...this.GetLevelNodes(node.Children, reference));
            }
        }
        return retVal;
    }
    private FillVariablesAndValues(operand, Values, Variables) {
        let retVal = {
            Variable: null,
            Value: null
        }
        let val = this.GetOperandAndSheet(operand);
        let operandName = val.Operand;
        let sheet = val.Sheet;
        if (operand.VariableID != null) {
            if (this.References[sheet][operandName]) {
                retVal.Value = this.References[sheet][operandName].Value;
            } else if (this.Cells[sheet][operandName]) {
                if (!this.Cells[sheet][operandName].Calculated) {
                    if (!this.CheckCircular(operand, this.Cells[sheet][operandName].Formula,null)) {
                        this.Cells[sheet][operandName].Value = this.GetFormulaValue(this.Cells[sheet][operandName].Formula);
                        this.Cells[sheet][operandName].Calculated = true;
                    } else {
                        this.Cells[sheet][operandName].Value = '!CircularDependency!';
                        this.Cells[sheet][operandName].Calculated = true;
                    }
                }
                retVal.Value = ValueAndType.GetValueAndTypeFromJSObject(this.Cells[sheet][operandName].Value);
            }
            retVal.Variable = operandName;
            Values.set(operand.Name, retVal.Value);
            Variables.push(retVal.Variable);
        }
        if (operand.Operands && operand.Operands.length > 0) {
            for (let i = 0; i < operand.Operands.length; i++) {
                this.FillVariablesAndValues(operand.Operands[i], Values, Variables);
            }
        }
    }
    GetOperandAndSheet(operand) {
        let retVal = {
            Operand: null,
            Sheet: null
        };

        let operandName = null;
        if (operand.Name) {
            operandName = operand.Name.replace('$', '');
        }
        let sheet = this.ActiveSheet.Name;
        if (operandName && operandName.indexOf('.') > -1) {
            let name = operandName.substring(0, operand.Name.indexOf('.'));
            if (this.Cells[name]) {
                sheet = name;
                operandName = operandName.substring(operand.Name.indexOf('.') + 1);
            }
        }

        retVal.Operand = operandName;
        retVal.Sheet = sheet;

        return retVal;
    }
    private CheckCircular(operand, formula, operands) {
        let retVal = false;
        let val = this.GetOperandAndSheet(operand);
        let operandName = val.Operand;
        let sheet = val.Sheet;
        if (formula) {
            const Parser = new AdvancedFormulaParser();
            const formulaDescription = Parser.Parse(formula);
            if (formulaDescription.Operands && formulaDescription.Operands.length > 0) {
                for (let i = 0; i < formulaDescription.Operands.length; i++) {
                    let op = formulaDescription.Operands[i];
                    if (op.Name) {
                        let opName = op.Name.replace('$', '');
                        if (opName != null && operandName != null && operandName == opName) {
                            return true;
                        }
                        if (opName && this.Cells[sheet][opName]) {
                            retVal = this.CheckCircular(operand, this.Cells[sheet][opName].Formula, null);
                        }
                    }
                    if (op.Operands.length > 0) {
                        retVal = this.CheckCircular(operand, null, op.Operands);
                    }
                }
            }
        }
        if (operands) {
            for (let i = 0; i < operands.Operands.length; i++) {
                let op = operands.Operands[i];
                if (op.Name) {
                    let opName = op.Name.replace('$', '');
                    if (opName != null && operandName != null && operandName == opName) {
                        return true;
                    }
                    if (opName && this.Cells[sheet][opName]) {
                        retVal = this.CheckCircular(operand, this.Cells[sheet][opName].Formula, null);
                    }
                }
                if (op.Operands.length > 0) {
                    retVal = this.CheckCircular(operand, null, op.Operands);
                }
            }
        }
        return retVal;;
    }
    //#endregion
}