import { AFormula, FormulaGroups } from "./iformula";
import { ValueAndType } from "../basic/formulaEditor.model";
import { TranslateHelper } from "../../helpers/injector.helper";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";

export class IfThenElse extends AFormula {
    static StaticID: string = '11c59750-0b1d-4f76-88f4-3c67555a7b20';

    ID(): string {
        return IfThenElse.StaticID;
    }

    Name(): string {
        return 'IfThenElse';
    }

    Caption(): string {
        return '@@Wenn Dann Sonst';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Pruefen von Bedingungen mittels Vergleichsoperatoren');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 1;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '('
            + TranslateHelper.TranslatorInstance.instant('@@Bedingung') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Dann') + '; '
            + TranslateHelper.TranslatorInstance.instant('@@Sonst') + ')';
    }

    MinVariableCount(): number {
        return 3;
    }

    MaxVariableCount(): number {
        return 3;
    }

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        if (args && args.length >= 3 && args[0].Type == ValueType.Bool && typeof args[0].Value === 'boolean') {
            if (args[0].Value) {
                retVal = args[1];
            } else {
                retVal = args[2];
            }
        }
        return retVal;
    }
}