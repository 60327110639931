export class EndpointBody {
    public Contents: Content[] = [];
}

export class EndpointResponseBody extends EndpointBody {
    public StatusCode: number;
}

export class EndpointRequestBody extends EndpointBody {
    public Required: boolean;
}

export class EndpointParameter {
    Name: string;
    AllowEmptyValue: boolean;
    Description: string;
    Type: string;
    Required: false;
    ParameterType: ParameterType
}

export class Content {
    ContentType: string;
    ObjectID: string;
    PayloadPath: string;
}

export enum ParameterType {
    /// <summary>
    /// Parameters that are appended to the URL.
    /// </summary>
    Query,
    /// <summary>
    /// Custom headers that are expected as part of the request.
    /// </summary>
    Header,
    /// <summary>
    /// Used together with Path Templating, where the parameter value is actually part of the operation's URL
    /// </summary>
    Path,
    /// <summary>
    /// Used to pass a specific cookie value to the API.
    /// </summary>
    Cookie,
    /// <summary>
    /// Parameter type not provided
    /// </summary>
    None
}