import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export abstract class AToFormula extends AFormula {
    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 10;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Argument') + '[; ' + TranslateHelper.TranslatorInstance.instant('@@Sprachcode') + '])';
    }

    MaxVariableCount(): number {
        return 2;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        if (args && args.length > 0) {
            if (typeof args[0].Value !== 'undefined' && args[0].Value != null) {

                // TODO: Culture???

                retVal = this.GetValue(args[0].Value, null);                
            }
        }
        return retVal;
    }

    abstract GetValue(val, culture): ValueAndType;
}

export class ToDoubleFormula extends AToFormula {
    static StaticID: string = 'ccb329e0-1d81-4262-9557-e04352082f9a';

    ID(): string {
        return ToDoubleFormula.StaticID;
    }

    Name(): string {
        return 'ToDouble';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Umwandlung des Zahlenformats zu Double');
    }

    GetValue(val, culture): ValueAndType {
        var number = Number(val);
        if (isNaN(number)) {
            number = 0;
        }
        return {
            Type: ValueType.Double,
            Value: number
        };
    }
}

export class ToLongFormula extends AToFormula {
    static StaticID: string = 'e1bbe120-8019-47ca-8341-facc551ab642';

    ID(): string {
        return ToLongFormula.StaticID;
    }

    Name(): string {
        return 'ToLong';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Umwandlung des Zahlenformats zu Long');
    }

    GetValue(val, culture): ValueAndType {
        var number = Number(val);
        if (isNaN(number)) {
            number = 0;
        } else {
            number = Math.floor(number);
        }
        return {
            Type: ValueType.Long,
            Value: number
        };
    }
}

export class ToStringFormula extends AToFormula {
    static StaticID: string = '5891c5b9-7434-45c8-814f-340352b45e56';

    ID(): string {
        return ToStringFormula.StaticID;
    }

    Name(): string {
        return 'ToString';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Umwandlung in einen string');
    }

    GetValue(val, culture): ValueAndType {
        return {
            Type: ValueType.String,
            Value: "" + val
        };
    }
}