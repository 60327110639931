export enum UnitType {
    Pixel,
    Percent,
    em,
    Auto,
    Rest,
    fr,
    vw,
    vh,
    calc
}
