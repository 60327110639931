import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APropertyTab } from '../../properties/properties.menu.tab';
import { WIDGETREGISTRY } from '../../../../services/dynamic.component.service';

@Component({
    selector: 'joblogs-widget-menu',
    templateUrl: './joblogs.widget.menu.html',
    styleUrls: ['./joblogs.widget.menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogsWidgetMenu extends APropertyTab {
    onItemSet() {
        if (!this.SelectedItem['WidgetProperties']) {
            this.SelectedItem['WidgetProperties'] = {
                ErrorsOnly: true,
                AllUsers: true
            };
            this.ValuesChanged('WidgetProperties');
        }
    }
    ValuesChanged(prop) {
        this.onStyleChanged(prop);
        WIDGETREGISTRY['JobsLogs'].Changes.next(true);
    }
}
