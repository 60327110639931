import { ABaseElement } from "./abaseelement.model";
import { TranslatedString } from "../translatedstring.model";
import { Type } from "class-transformer";
// @dynamic
export class DataModel extends ABaseElement {
    DefaultDataSource: string;
    //SpeedBaseSettings SpeedBaseSettings ???
    MaxSaveCombinations: number = 100000;
    @Type(() => TranslatedString)
    Description: TranslatedString;
    SelectTopRows: number = 1000;
}