import { Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef, ContentChild } from "@angular/core";
import { MatMenu } from "@angular/material/menu";

@Component({
    selector: "nested-menu",
    templateUrl: "./nested.menu.html",
    styleUrls: ["./nested.menu.css"]
})
export class NestedMenuComponent implements OnInit {
    @ViewChild("menu", { static: true }) menu: MatMenu;
    @Input() items: { name: string, children: any[], data: any }[];
    @Input() DisplayMemberPath: string;
    @Input() ShowNewEntry: boolean = false;
    @Input() CaptionNewEntry: string;
    @Input() ValueMemberPath: string;
    @Input() Multiple: string;
    @Output() ItemSelected = new EventEmitter<any>();
    @Output() ItemsSelected = new EventEmitter<any>();
    @Output() NewEntrySelected = new EventEmitter<any>();

    @ContentChild(TemplateRef)
    layoutTemplate: TemplateRef<any>;
    constructor() { }

    ngOnInit() { }
    click(item) {
        this.ItemSelected.emit(item);
    }
    NewEntry() {
        this.NewEntrySelected.emit();
    }
}
