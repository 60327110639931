import { DeploymentAction, PrepareFunc } from "./deploymentaction.model"
import { InjectorHelper } from "../../helpers/injector.helper";
import { DataModelService } from "../../services/datamodel.service";
import { ChangeDataSourceAction } from "../../settings/actions/datasource/changedatasource.action";
import { BehaviorSubject } from "rxjs";
import { DataSourceDetail } from "../../settings/datamodel/datasource/datasource.settings";
import { plainToClass } from "class-transformer";
import { DataSource } from "../datamodel/datasource.model";
import { DialogResult } from "../enums/dialogresult.enum";

export class ChangeDataSourceDeploymentAction implements DeploymentAction {
    SelectedItem;
    
    ID = '2a1b1a71-e311-4bac-8884-997da4630271';
    Name = '@@ChangeDataSource';
    Control = DataSourceDetail;
    Layout = null;
    Properties = null;
    Settings = ChangeDataSourceAction;
    Prepare = (item) => {
        this.PreparationDone.next(false);
        let service = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
        service.GetDataSource(item.DataSourceID).subscribe((result) => {
            this.SelectedItem = plainToClass(DataSource, result);
            this.Properties = [{
                Key: 'SelectedItem', Value: this.SelectedItem
            }]
            this.PreparationDone.next(true);
        })
    };
    PreparationDone = new BehaviorSubject(false);
    Buttons = [
        {
            Caption: '@@Save',
            Execute: () => {
                let service = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
                service.SaveDataSource(this.SelectedItem).subscribe();
            },
            CloseOnClick: true,
            CloseStatus: DialogResult.Ok
        },
        {
            Caption: '@@Cancel',
            Execute: null,
            CloseOnClick: true,
            CloseStatus: DialogResult.Cancel
        }
    ]
}
