import { BaseTreeControl } from '../components/common/basetreecontrol/base.tree.control';
import { CapsuleTagControl } from '../components/common/capsuletagcontrol/capsule.tag.control';
import { DataSourceSelectionControl } from '../components/common/datasourceselectioncontol/datasource.selection.control';
import {
    DropDownButtonContentDirective, DropDownContentDirective, DropDownControl
} from '../components/common/dropdowncontrol/dropdown.control';
import { FormulaEditor } from '../components/common/formulaEditor/formulaEditor.control';
import { FormulaTextBox } from '../components/common/formulaEditor/formulatextbox.control';
import { IndeterminateCheckbox } from '../components/common/indeterminatecheckbox/indeterminate.checkbox';
import { JiraControl } from '../components/common/jiracontrol/jira.control';
import { MenuTabControl } from '../appbuilder/common/menutabcontrol/menu.tab.control';
import { PropertyControl } from '../components/common/propertyControl/property.control';
import { RequestOptionsControl } from '../components/common/requestoptions/request.options.control';
import { TimeSpanControl } from '../components/common/timespanControl/timespan.control';
import { AdvancedFilterComponent } from '../components/controls/advancedfilter/advancedfilter.component';
import { ButtonControl } from '../components/controls/button/button.control';
import { CheckBoxControl } from '../components/controls/checkbox/checkbox.control';
import { ComboBoxControl } from '../components/controls/combobox/combobox.control';
import { DateTimePickerControl } from '../components/controls/datetimepicker/datetimepicker.control';
import { ColorPickerComponent } from '../components/controls/gradientpicker/colorpicker.control';
import { GradientPicker } from '../components/controls/gradientpicker/gradientpicker.control';
import { LayoutTree } from '../components/controls/layout.tree';
import { ListBoxControl } from '../components/controls/listbox/listbox.control';
import { SimpleListBoxControl } from '../components/controls/listbox/simple.listbox.control';
import { LoadingComponent } from '../components/controls/loading/loading.component';
import { DataTableControl } from '../components/controls/tables/datatable/datatable.control';
import { TextBoxControl } from '../components/controls/textbox/textbox.control';
import { TranslationTextBox } from '../components/controls/translationtextbox/translation.textbox.control';
import { TreeControl } from '../components/controls/tree/tree.control';
import { VariableDefinitionControl } from '../components/controls/variable/variabledefinition.control';
import { TranslationDialog } from '../components/dialogs/translationdialog/translation.dialog';
import { TemplateLayout } from '../components/layouts/template/template.layout';
import { ControlsMenuTab } from '../appbuilder/menutabs/controls/controls.menu.tab';
import { PropertiesMenuTab } from '../appbuilder/menutabs/properties/properties.menu.tab';
import { ABaseElementNameControl } from '../settings/common/abaseelement.name.control';
import { DBInfoInteractivePropertyControl } from '../settings/datamodel/datasource/datasourcepanels/dbinfo.interactive.property.control';
import { LayoutItemChooser } from '../workflow/modules/layout/layout.item.chooser';
import { WorkflowContainerChooser } from '../workflow/workflow.container.chooser';
import { WorkflowControl } from '../workflow/workflow.control';
import { GenericMenuTab } from '../appbuilder/menutabs/generic/generic.menu.tab';
import { RequestNameDialog } from "../components/dialogs/reqeustname/reqeustname.dialog";
import { QueryRelationOverview } from '../settings/datamodel/common/query.relation.overview';
import {BasicDataTableControl} from "../components/controls/tables/basic-ptable/basic-datatable.control";

export const MW_DIALOGS = [
    RequestNameDialog
];

export const MW_EXPORTS = [
    QueryRelationOverview
];

export const APP_EXPORTS = [
    PropertyControl,
    ABaseElementNameControl,
    TranslationDialog,
    TranslationTextBox,
    FormulaEditor,
    BaseTreeControl,
    TreeControl,
    AdvancedFilterComponent,
    IndeterminateCheckbox,
    RequestOptionsControl,
    ControlsMenuTab,
    PropertiesMenuTab,
    MenuTabControl,
    LayoutTree,
    CheckBoxControl,
    ListBoxControl,
    SimpleListBoxControl,
    ButtonControl,
    ComboBoxControl,
    TextBoxControl,
    LoadingComponent,
    FormulaTextBox,
    CapsuleTagControl,
    TimeSpanControl,
    DropDownControl,
    DropDownContentDirective,
    DropDownButtonContentDirective,
    TemplateLayout,
    DateTimePickerControl,
    WorkflowControl,
    ColorPickerComponent,
    VariableDefinitionControl,
    LayoutItemChooser,
    GradientPicker,
    DataSourceSelectionControl,
    DBInfoInteractivePropertyControl,
    WorkflowContainerChooser,
    DataTableControl,
    BasicDataTableControl,
    JiraControl,
    GenericMenuTab
];
