import { Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ConditionDataType } from '../../../models/enums/conditiondatatype.enum';
import { ConditionOperator } from '../../../models/enums/conditionoperator.enum';
import { TextComparison } from '../../../models/enums/textcomparison.enum';
import { WorkflowViewType } from '../../../models/enums/workflowviewtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'condition-settings',
    templateUrl: './condition.settings.html',
    styleUrls: ['./condition.settings.css']
})
export class ConditionSettings extends WorkflowDialogContent {

    DataTypes = [];
    ConditionTypes = [];
    TextComparisons = [];
    SelectedDataType: ConditionDataType = ConditionDataType.Bool;
    SelectedSettings: any = new BoolTypeSettings();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'conditionWFModule';
        reg.Caption = '@@Bedingung';
        reg.GroupID = 'datachecks';
        reg.Index = 10;
        reg.Height = 80;
        reg.Width = 80;
        reg.SettingsControl = ConditionSettings;
        reg.SettingsTypeHelper = new ConditionSettingsDataHelper();
        reg.Executer = ConditionModuleExecuter;
        reg.ViewType = WorkflowViewType.Condition;
        return reg;
    }

    constructor() {
        super();
        this.DataTypes = EnumHelper.GetDropdownValues(ConditionDataType);
        this.ConditionTypes = EnumHelper.GetDropdownValues(ConditionOperator);
        this.TextComparisons = EnumHelper.GetDropdownValues(TextComparison);
    }

    initialize(data: any) {
        if (data) {
            if (typeof data.DataType === 'number') {
                this.SelectedDataType = data.DataType;
            }
            switch (this.SelectedDataType) {
                case ConditionDataType.Bool:
                    const boolData = plainToClass(BoolTypeSettings, data.Settings);
                    if (boolData) {
                        this.SelectedSettings = boolData;
                    } else {
                        this.SelectedSettings = new BoolTypeSettings();
                    }
                    break;
                case ConditionDataType.Date:
                    const dateData = plainToClass(DateTypeSettings, data.Settings);
                    if (dateData) {
                        this.SelectedSettings = dateData;
                    } else {
                        this.SelectedSettings = new DateTypeSettings();
                    }
                    break;
                case ConditionDataType.Numeric:
                    const numData = plainToClass(NumericTypeSettings, data.Settings);
                    if (numData) {
                        this.SelectedSettings = numData;
                    } else {
                        this.SelectedSettings = new NumericTypeSettings();
                    }
                    break;
                case ConditionDataType.Text:
                    const textData = plainToClass(TextTypeSettings, data.Settings);
                    if (textData) {
                        this.SelectedSettings = textData;
                    } else {
                        this.SelectedSettings = new TextTypeSettings();
                    }
                    break;
            }
        }
    }

    getResult(): any {
        const retVal = new ConditionSettingsData();
        retVal.DataType = this.SelectedDataType;
        retVal.Settings = this.SelectedSettings;
        return retVal;
    }

    onDataTypeChanged(e) {
        switch (this.SelectedDataType) {
            case ConditionDataType.Bool:
                if (!(this.SelectedSettings instanceof BoolTypeSettings)) {
                    this.SelectedSettings = new BoolTypeSettings();
                }
                break;
            case ConditionDataType.Date:
                if (!(this.SelectedSettings instanceof DateTypeSettings)) {
                    this.SelectedSettings = new DateTypeSettings();
                }
                break;
            case ConditionDataType.Numeric:
                if (!(this.SelectedSettings instanceof NumericTypeSettings)) {
                    this.SelectedSettings = new NumericTypeSettings();
                }
                break;
            case ConditionDataType.Text:
                if (!(this.SelectedSettings instanceof TextTypeSettings)) {
                    this.SelectedSettings = new TextTypeSettings();
                }
                break;
        }
    }
}

export class ConditionSettingsData {
    DataType: ConditionDataType = ConditionDataType.Bool;
    Settings: any;

    ExtendedSerialize(module) {
        const tmpSettings = Object.assign({}, this);
        if (tmpSettings.Settings) {
            tmpSettings.Settings = JSON.stringify(tmpSettings.Settings);
        }
        module.SettingsType = 'evidanza.App.Shared.Workflow.Modules.Condition.ConditionSettingsData';
        module.Settings = JSON.stringify(tmpSettings);
    }

    ExtendedDeserialize() {
        if (this.Settings) {
            const deserializedSettings = JSON.parse(this.Settings);
            switch (this.DataType) {
                case ConditionDataType.Bool:
                    this.Settings = plainToClass(BoolTypeSettings, deserializedSettings);
                    break;
                case ConditionDataType.Date:
                    this.Settings = plainToClass(DateTypeSettings, deserializedSettings);
                    break;
                case ConditionDataType.Numeric:
                    this.Settings = plainToClass(NumericTypeSettings, deserializedSettings);
                    break;
                case ConditionDataType.Text:
                    this.Settings = plainToClass(TextTypeSettings, deserializedSettings);
                    break;
            }
        }
    }
}

export class ConditionSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ConditionSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && typeof settings.DataType === 'number' && settings.Settings) {
            switch (settings.DataType) {
                case ConditionDataType.Bool:
                    if (typeof settings.Settings.CheckIsFalse === 'boolean') {
                        if (settings.Settings.CheckIsFalse) {
                            return new TranslateFormatText('@@Bool: Falsch');
                        }
                        return new TranslateFormatText('@@Bool: Wahr');
                    }
                    break;
                case ConditionDataType.Date:
                    if (typeof settings.Settings.Operator === 'number' && settings.Settings.Date1 && settings.Settings.Date2) {
                        switch (settings.Settings.Operator) {
                            case ConditionOperator.Between:
                                const between = new TranslateFormatText('@@Datum: Zwischen {0} und {1}');
                                between.FormatParams.push(settings.Settings.Date1.toDateString());
                                between.FormatParams.push(settings.Settings.Date2.toDateString());
                                return between;
                            case ConditionOperator.Equal:
                                const equal = new TranslateFormatText('@@Datum: Gleich {0}');
                                equal.FormatParams.push(settings.Settings.Date1.toDateString());
                                return equal;
                            case ConditionOperator.Greater:
                                const greater = new TranslateFormatText('@@Datum: Groesser {0}');
                                greater.FormatParams.push(settings.Settings.Date1.toDateString());
                                return greater;
                            case ConditionOperator.GreatorOrEqual:
                                const greaterOrEqual = new TranslateFormatText('@@Datum: Groessergleich {0}');
                                greaterOrEqual.FormatParams.push(settings.Settings.Date1.toDateString());
                                return greaterOrEqual;
                            case ConditionOperator.IsNull:
                                return new TranslateFormatText('@@Datum: Gleich null');
                            case ConditionOperator.Less:
                                const less = new TranslateFormatText('@@Datum: Kleiner {0}');
                                less.FormatParams.push(settings.Settings.Date1.toDateString());
                                return less;
                            case ConditionOperator.LessOrEqual:
                                const lessOrEqual = new TranslateFormatText('@@Datum: Kleinergleich {0}');
                                lessOrEqual.FormatParams.push(settings.Settings.Date1.toDateString());
                                return lessOrEqual;
                            case ConditionOperator.NonEqual:
                                const nonEqual = new TranslateFormatText('@@Datum: Ungleich {0}');
                                nonEqual.FormatParams.push(settings.Settings.Date1.toDateString());
                                return nonEqual;
                            case ConditionOperator.NotBetween:
                                const notBetween = new TranslateFormatText('@@Datum: Nicht zwischen {0} und {1}');
                                notBetween.FormatParams.push(settings.Settings.Date1.toDateString());
                                notBetween.FormatParams.push(settings.Settings.Date2.toDateString());
                                return notBetween;
                            case ConditionOperator.NotNull:
                                return new TranslateFormatText('@@Datum: Nicht null');
                        }
                    }
                    break;
                case ConditionDataType.Numeric:
                    if (typeof settings.Settings.Operator === 'number' && settings.Settings.Value1 && settings.Settings.Value2) {
                        switch (settings.Settings.Operator) {
                            case ConditionOperator.Between:
                                const between = new TranslateFormatText('@@Wert: Zwischen {0} und {1}');
                                between.FormatParams.push(settings.Settings.Value1.toDateString());
                                between.FormatParams.push(settings.Settings.Value2.toDateString());
                                return between;
                            case ConditionOperator.Equal:
                                const equal = new TranslateFormatText('@@Wert: Gleich {0}');
                                equal.FormatParams.push(settings.Settings.Value1.toDateString());
                                return equal;
                            case ConditionOperator.Greater:
                                const greater = new TranslateFormatText('@@Wert: Groesser {0}');
                                greater.FormatParams.push(settings.Settings.Value1.toDateString());
                                return greater;
                            case ConditionOperator.GreatorOrEqual:
                                const greaterOrEqual = new TranslateFormatText('@@Wert: Groessergleich {0}');
                                greaterOrEqual.FormatParams.push(settings.Settings.Value1.toDateString());
                                return greaterOrEqual;
                            case ConditionOperator.IsNull:
                                return new TranslateFormatText('@@Wert: Gleich null');
                            case ConditionOperator.Less:
                                const less = new TranslateFormatText('@@Wert: Kleiner {0}');
                                less.FormatParams.push(settings.Settings.Value1.toDateString());
                                return less;
                            case ConditionOperator.LessOrEqual:
                                const lessOrEqual = new TranslateFormatText('@@Wert: Kleinergleich {0}');
                                lessOrEqual.FormatParams.push(settings.Settings.Value1.toDateString());
                                return lessOrEqual;
                            case ConditionOperator.NonEqual:
                                const nonEqual = new TranslateFormatText('@@Wert: Ungleich {0}');
                                nonEqual.FormatParams.push(settings.Settings.Value1.toDateString());
                                return nonEqual;
                            case ConditionOperator.NotBetween:
                                const notBetween = new TranslateFormatText('@@Wert: Nicht zwischen {0} und {1}');
                                notBetween.FormatParams.push(settings.Settings.Value1.toDateString());
                                notBetween.FormatParams.push(settings.Settings.Value2.toDateString());
                                return notBetween;
                            case ConditionOperator.NotNull:
                                return new TranslateFormatText('@@Wert: Nicht null');
                        }
                    }
                    break;
                case ConditionDataType.Text:
                    if (typeof settings.Settings.Text === 'string') {
                        let retVal: TranslateFormatText = null;
                        switch (settings.Settings.CompareType) {
                            case TextComparison.Contains:
                                retVal = new TranslateFormatText('@@Beinhaltet {0}');
                                retVal.FormatParams.push(settings.Settings.Text);
                                break;
                            case TextComparison.EndsWith:
                                retVal = new TranslateFormatText('@@Endet mit {0}');
                                retVal.FormatParams.push(settings.Settings.Text);
                                break;
                            case TextComparison.IsNullOrEmpty:
                                retVal = new TranslateFormatText('@@Ist leer');
                                break;
                            case TextComparison.NonEquals:
                                retVal = new TranslateFormatText('@@Ungleich {0}');
                                retVal.FormatParams.push(settings.Settings.Text);
                                break;
                            case TextComparison.StartsWith:
                                retVal = new TranslateFormatText('@@Beginnt mit {0}');
                                retVal.FormatParams.push(settings.Settings.Text);
                                break;
                            default: // Equals
                                retVal = new TranslateFormatText('@@Gleich {0}');
                                retVal.FormatParams.push(settings.Settings.Text);
                                break;
                        }
                        return retVal;
                    }
                    break;
            }
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const truePoint = new WorkflowExitInfo();
        truePoint.ID = 0;
        truePoint.Label = '@@Wahr';
        const falsePoint = new WorkflowExitInfo();
        falsePoint.ID = 1;
        falsePoint.Label = '@@Falsch';
        return [truePoint, falsePoint];
    }
}

export class BoolTypeSettings {
    CheckIsFalse = false;
}

export class DateTypeSettings {
    Operator: ConditionOperator = ConditionOperator.Equal;
    Date1 = new Date();
    Date2 = new Date();
}

export class NumericTypeSettings {
    Operator: ConditionOperator = ConditionOperator.Equal;
    Value1 = 0;
    Value2 = 0;
}

export class TextTypeSettings {
    Text = '';
    CompareType: TextComparison = TextComparison.Equals;
    IgnoreCase = false;
}

export class ConditionModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings && typeof status.ActualSettings.DataType === 'number' && status.ActualSettings.Settings) {
            const settings = status.ActualSettings.Settings;
            let retVal: boolean;
            switch (status.ActualSettings.DataType) {
                case ConditionDataType.Bool:
                    if (typeof status.ActualParameter === 'boolean') {
                        if (typeof settings.CheckIsFalse === 'boolean' && settings.CheckIsFalse) {
                            retVal = !status.ActualParameter;
                        } else {
                            retVal = status.ActualParameter;
                        }
                    } else {
                        status.Logger.logError('Bool Condition: Actual parameter not of type boolean.');
                    }
                    break;
                case ConditionDataType.Date:
                    if (status.ActualParameter instanceof Date) {
                        if (settings.Operator === ConditionOperator.NotNull) {
                            retVal = true;
                        } else {
                            const stateDate = new Date(status.ActualParameter.getFullYear(), status.ActualParameter.getMonth(),
                                status.ActualParameter.getDate());
                            const date1 = new Date(settings.Date1.getFullYear(), settings.Date1.getMonth(), settings.Date1.getDate());
                            switch (settings.Operator) {
                                case ConditionOperator.Equal:
                                    retVal = stateDate === date1;
                                    break;
                                case ConditionOperator.NonEqual:
                                    retVal = stateDate !== date1;
                                    break;
                                case ConditionOperator.Between:
                                    const date2 = new Date(settings.Date2.getFullYear(), settings.Date2.getMonth(),
                                        settings.Date2.getDate());
                                    if (date1 <= date2) {
                                        retVal = date1 <= stateDate && stateDate <= date2;
                                    } else {
                                        retVal = date2 <= stateDate && stateDate <= date1;
                                    }
                                    break;
                                case ConditionOperator.NotBetween:
                                    const date22 = new Date(settings.Date2.getFullYear(), settings.Date2.getMonth(),
                                        settings.Date2.getDate());
                                    if (date1 <= date22) {
                                        retVal = stateDate < date1 || stateDate > date22;
                                    } else {
                                        retVal = stateDate < date22 || stateDate > date1;
                                    }
                                    break;
                                case ConditionOperator.Less:
                                    retVal = stateDate < date1;
                                    break;
                                case ConditionOperator.Greater:
                                    retVal = stateDate > date1;
                                    break;
                                case ConditionOperator.GreatorOrEqual:
                                    retVal = stateDate >= date1;
                                    break;
                                case ConditionOperator.LessOrEqual:
                                    retVal = stateDate <= date1;
                                    break;
                            }
                        }
                    } else if (settings.Operator === ConditionOperator.IsNull) {
                        retVal = true;
                    } else {
                        status.Logger.logError('Date Condition: Actual parameter not of type datetime.');
                    }
                    break;
                case ConditionDataType.Numeric:
                    if (typeof status.ActualParameter === 'number') {
                        if (settings.Operator === ConditionOperator.NotNull) {
                            retVal = true;
                        } else {
                            switch (settings.Operator) {
                                case ConditionOperator.Equal:
                                    retVal = status.ActualParameter === settings.Value1;
                                    break;
                                case ConditionOperator.NonEqual:
                                    retVal = status.ActualParameter !== settings.Value1;
                                    break;
                                case ConditionOperator.Between:
                                    if (settings.Value1 <= settings.Value2) {
                                        retVal = settings.Value1 <= status.ActualParameter && status.ActualParameter <= settings.Value2;
                                    } else {
                                        retVal = settings.Value2 <= status.ActualParameter && status.ActualParameter <= settings.Value1;
                                    }
                                    break;
                                case ConditionOperator.NotBetween:
                                    if (settings.Value1 <= settings.Value2) {
                                        retVal = status.ActualParameter < settings.Value1 || status.ActualParameter > settings.Value2;
                                    } else {
                                        retVal = status.ActualParameter < settings.Value2 || status.ActualParameter > settings.Value1;
                                    }
                                    break;
                                case ConditionOperator.Less:
                                    retVal = status.ActualParameter < settings.Value1;
                                    break;
                                case ConditionOperator.Greater:
                                    retVal = status.ActualParameter > settings.Value1;
                                    break;
                                case ConditionOperator.GreatorOrEqual:
                                    retVal = status.ActualParameter >= settings.Value1;
                                    break;
                                case ConditionOperator.LessOrEqual:
                                    retVal = status.ActualParameter <= settings.Value1;
                                    break;
                            }
                        }
                    } else if (settings.Operator === ConditionOperator.IsNull) {
                        retVal = true;
                    } else {
                        status.Logger.logError('Numeric Condition: Error while converting actual parameter to double.');
                    }
                    break;
                case ConditionDataType.Text:
                    if (typeof status.ActualParameter !== 'undefined' && status.ActualParameter !== null) {
                        let text = status.ActualParameter.toString();
                        let checkText = settings.Text;
                        if (typeof settings.IgnoreCase === 'boolean' && settings.IgnoreCase) {
                            text = text.toLowerCase();
                            checkText = checkText.toLowerCase();
                        }
                        switch (settings.CompareType) {
                            case TextComparison.Contains:
                                retVal = text.indexOf(checkText) !== -1;
                                break;
                            case TextComparison.EndsWith:
                                retVal = text.indexOf(checkText, text.length - checkText.length) !== -1;
                                break;
                            case TextComparison.IsNullOrEmpty:
                                retVal = typeof text !== 'string' || text === null || text === '';
                                break;
                            case TextComparison.NonEquals:
                                retVal = text !== checkText;
                                break;
                            case TextComparison.StartsWith:
                                retVal = text.slice(0, checkText.length) === checkText;
                                break;
                            default: // Equals
                                retVal = text === checkText;
                                break;
                        }
                    } else {
                        status.Logger.logError('Text Condition: Actual parameter not set.');
                    }
                    break;
            }
            if (typeof retVal === 'boolean') {
                status.ActualParameter = retVal;
                return retVal ? 0 : 1;
            } else {
                status.Logger.logError('Condition modul: Condition could not be checked.');
            }
        } else {
            status.Logger.logError('Condition modul: No settings found.');
        }
        return super.execute(status);
    }
}
