import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { LayoutElement } from '../../../models/layoutelement.model';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'layout-edit-control',
    templateUrl: './layout.edit.control.html'
})
export class LayoutEditControl implements OnInit, OnDestroy {
    Layout;
    BaseLayout;
    Subscriptions = [];
    ContentStyle;
    LastItem;
    ngOnInit(): void {
        LayoutService.LayoutEditDialogOpen.next(true);
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe(l => {
            this.Layout = l;
            this.BaseLayout = l;
            let viewType = ViewType.View;
            if (l) {
                viewType = ViewType.Edit;
                l.SetViewType(ViewType.Edit);
            }
            LayoutService.SelectedItem.next(l);
            if (LayoutService.ViewType.getValue() !== viewType) {
                LayoutService.ViewType.next(viewType);
            }
        }));
        this.Subscriptions.push(LayoutService.RootSize.subscribe(async (size) => {
            const result = {};
            let item = this.BaseLayout;

            if (size != null) {
                result['max-width'] = size.Width + 'px';
                result['margin-left'] = 'auto';
                result['margin-right'] = 'auto';

                let resolution = this.BaseLayout.Resolutions.find((value) => size.Width > value.FromWidth && size.Width <= value.ToWidth);
                if (resolution) {
                    item = resolution;
                    item.SetViewType(ViewType.Edit);
                    if (!resolution.Elements || resolution.Elements.length == 0) {
                        let element = new LayoutElement();
                        element.ElementType = "content";
                        resolution.Elements.push(element);
                    }
                    LayoutService.SelectedResolution.next(resolution);
                }
                else {
                    LayoutService.SelectedResolution.next(null);
                }
            } else {
                result['width'] = '100%';
                LayoutService.SelectedResolution.next(null);
            }
            if (this.Layout != item) {
                LayoutService.SelectedLayout.next(item);
            }
            this.Layout = item;
            result['height'] = '100%';
            this.ContentStyle = result;
        }));
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
        LayoutService.LayoutEditDialogOpen.next(false);
    }

    itemSelected() {
        if (this.Layout && !this.Layout.Selected) {
            LayoutService.SelectedItem.next(this.BaseLayout);
        }
    }
}
