import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export abstract class AActualDate extends AFormula {
    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Ausgabe des aktuellen Datums');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 100;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '()';
    }

    MinVariableCount(): number {
        return 0;
    }

    MaxVariableCount(): number {
        return 0;
    }
}

export class ActualDate extends AActualDate {
    static StaticID: string = 'a036a575-934e-4d5f-b703-3c9fe34807cc';

    ID(): string {
        return ActualDate.StaticID;
    }

    Name(): string {
        return 'ActualDate';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        return {
            Type: ValueType.Datetime,
            Value: new Date()
        };
    }
}

export class TodayFormula extends AActualDate {
    static StaticID: string = '3a240f9b-3b90-493b-b62a-0986d60e37c8';

    ID(): string {
        return TodayFormula.StaticID;
    }

    Name(): string {
        return 'Today';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const date = new Date();
        return {
            Type: ValueType.Datetime,
            Value: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        };
    }
}