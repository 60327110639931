import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
import { deserialize, serialize } from 'class-transformer';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { ABaseTreeNode, IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TemplateLayout } from '../../../components/layouts/template/template.layout';
import { ArrayHelpers, TranslateFormatText } from '../../../helpers/array.helpers';
import { ClipboardHelper } from '../../../helpers/clipboard.helper';
import { EnumHelper } from '../../../helpers/enum.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { LayoutChangeType } from '../../../models/enums/layoutchangetype.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { WorkflowErrorType } from '../../../models/enums/workflowerrortype.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { WorkflowData, WorkflowDescription, WorkflowSaveObject } from '../../../models/workflow/workflow.model';
import { LayoutService } from '../../../services/layout.service';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowEditDialog } from '../../../workflow/workflow.edit.dialog';
import { DisabledWorkflowEditDialog } from "../../../workflow/disabled-workflow.edit.dialog";
import { WorkflowUsageDialog } from './workflow.usage.dialog';
import { TranslateHelper } from "../../../helpers/injector.helper";
import { WorkflowCommunicationService } from "../../../services/workflow-communication.service";
@Component({
    selector: 'workflow-menu-tab',
    templateUrl: './workflow.menu.tab.html',
    styleUrls: ['./workflow.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowMenuTab extends ALayoutMenuTab {

    SelectedNode: WorkflowNode;
    RootNodes = [];
    IDKeeper = new IDKeeper();
    ErrorTypes = [];
    Tree;
    EditedWorkflow;
    previousLayout;

    private static buildTreeRecursive(layout, nodeList, editable, idKeeper: IDKeeper) {
        if (layout.Elements) {
            layout.Elements.forEach(elem => {
                if (elem.ElementType === TemplateLayout.Type) {
                    const mainNode = new WorkflowNode(idKeeper.NextID);
                    mainNode.Caption = elem.Name;
                    mainNode.HasChildren = true;
                    mainNode.Children = [];
                    mainNode.editable = editable && elem.LoadByReference !== true;
                    if (elem.Workflows) {
                        elem.Workflows.forEach(wf => {
                            const node = new WorkflowNode(idKeeper.NextID);
                            node.Caption = wf.Caption;
                            node.workflow = wf;
                            node.editable = mainNode.editable;
                            node.parentLayout = elem;
                            node.parentNode = mainNode;
                            mainNode.Children.push(node);
                        });
                    }
                    nodeList.push(mainNode);
                    WorkflowMenuTab.buildTreeRecursive(elem, mainNode.Children, mainNode.editable, idKeeper);
                } else {
                    WorkflowMenuTab.buildTreeRecursive(elem, nodeList, editable, idKeeper);
                }
            });
        }
    }
    constructor(cdRef: ChangeDetectorRef, public dialog: MatDialog, private service: WorkflowService,
        private workflowCommunicationService: WorkflowCommunicationService) {
        super(cdRef);
        this.ErrorTypes = EnumHelper.GetDropdownValues(WorkflowErrorType);
    }
    ShowCopyButton = false;
    ShowDuplicateButton = true;
    ShowPasteButton = false;
    ngOnInit() {
        super.ngOnInit();
        this.buildTree();

        if (ClipboardHelper.Inititalized) {
            this.ShowCopyButton = true;
            this.ShowDuplicateButton = false;
            if (ClipboardHelper.ClipboardContent && ClipboardHelper.ClipboardContent.type && ClipboardHelper.ClipboardContent.type == "ClientWorkflowCopy") {
                this.ShowPasteButton = true;
            } else {
                this.ShowPasteButton = false;
            }
            ClipboardHelper.ClipboardChanged.subscribe((data: any) => {
                if (data && data.type && data.type == "ClientWorkflowCopy") {
                    this.ShowPasteButton = true;
                } else {
                    this.ShowPasteButton = false;
                }
            });
        } else {
            this.ShowCopyButton = false;
            this.ShowDuplicateButton = true;
        }

        this.Subscriptions.push(LayoutService.LayoutWorkflowsChanged.subscribe((x) => {
            this.buildTree();
            if (x && x.ChangeValue && this.EditedWorkflow != null) {
                switch (x.LayoutChangeType) {
                    case LayoutChangeType.WorkflowChange:
                        if (x.ChangeValue.Workflows && x.ChangeValue.Workflows.some(wf => JSON.parse(wf).ID === this.EditedWorkflow.ID)) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@ActualEditWorkflow changed'),
                                new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);

                        }
                        break;
                    case LayoutChangeType.WorkflowRemoved:
                        if (x.ChangeValue.WorkflowID === this.EditedWorkflow.ID) {
                            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@ActualEditWorkflow removed'),
                                new TranslateFormatText('@@Warnung'), MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                        }
                        break;
                }

            }
        }));
        this.workflowCommunicationService.getDialogStatus().subscribe(res => {
            if (res !== null) {
                let selectedWorkflow = this.workflowCommunicationService.getSelectedWorkflow()
                let stack = this.workflowCommunicationService.getWorkflowStack()
                if (selectedWorkflow !== null && res === "inner") {
                    const serialized = serialize(selectedWorkflow)
                    const itemData = deserialize(WorkflowData, serialized)
                    const Data = itemData
                    let workflowDescription = new WorkflowDescription()
                    workflowDescription.Caption = selectedWorkflow?.Caption?.Value
                    workflowDescription.ID = selectedWorkflow?.SID
                    workflowDescription.Data = itemData;
                    // this.dialog.closeAll()
                    const dialogRef = this.dialog.open(DisabledWorkflowEditDialog, {
                        data: { Data: Data, Layout: this.previousLayout, Workflow: workflowDescription },
                        panelClass: 'workflowedit-dialog-container',
                    });
                }
                else if (selectedWorkflow === null && res === "outer") {
                    this.workflowCommunicationService.setWorkflowStack([])
                    // this.workflowCommunicationService.setSelectedWorkflowItem(null)
                    this.EditWorkflow()
                }
            }
            else if (res === null){
                this.dialog.closeAll()
            }
        })
        // this.workflowCommunicationService.getSelectedWorkflowItem().subscribe(res=>{
        //     const stack = this.workflowCommunicationService.getWorkflowStack()
        //     if(res===null && stack.length>0 && this.SelectedNode){
        //         this.workflowCommunicationService.setWorkflowStack([])
        //         this.workflowCommunicationService.setSelectedWorkflowItem(null)
        //         this.EditWorkflow()
        //     }
        //     else if (res !== null && stack.length > 0 && this.SelectedNode) {
        //         const serialized = serialize(res)
        //         const itemData = deserialize(WorkflowData, serialized)
        //         const Data = itemData
        //         let workflowDescription = new WorkflowDescription()
        //         workflowDescription.Caption = res?.Caption?.Value
        //         workflowDescription.ID = res?.SID
        //         workflowDescription.Data = itemData;
        //         this.dialog.closeAll()
        //         const dialogRef = this.dialog.open(DisabledWorkflowEditDialog, {
        //             data: { Data: Data, Layout: this.previousLayout, Workflow: workflowDescription },
        //             panelClass: 'workflowedit-dialog-container',
        //         });
        //     }
        // })
    }
    TreeInit(event) {
        this.Tree = event;
    }
    buildTree() {
        const data = [];
        const layout = this.Layout;
        this.IDKeeper.reset();
        if (layout) {
            if (layout.Workflows) {
                ArrayHelpers.sortAlphabetical(layout.Workflows, 'Caption');
                layout.Workflows.forEach(wf => {
                    const node = new WorkflowNode(this.IDKeeper.NextID);
                    node.Caption = wf.Caption;
                    node.workflow = wf;
                    node.editable = true;
                    node.parentLayout = layout;
                    data.push(node);
                });
            }
            WorkflowMenuTab.buildTreeRecursive(layout, data, true, this.IDKeeper);
        }
        this.RootNodes = data;
    }

    onCaptionChanged() {
        if (this.SelectedNode && this.SelectedNode.workflow) {
            this.SelectedNode.Caption = this.SelectedNode.workflow.Caption;
            if (this.Tree) {
                this.Tree.cdRef.detectChanges();
            }
            this.cdRef.detectChanges();
            this.onWFChanged();
        }
    }

    onWFChanged() {
        if (this.SelectedNode && this.SelectedNode.workflow) {
            LayoutService.OnLayoutWorkflowsChanged({
                LayoutID: this.SelectedNode.parentLayout.ID,
                Workflows: [JSON.stringify(this.SelectedNode.workflow)]
            });
        }
    }

    AddWorkflow() {
        const layout = this.Layout;
        if (layout) {
            const newWF = new WorkflowDescription();
            newWF.Caption = 'New Workflow';
            newWF.ID = UUID.UUID();
            const node = new WorkflowNode(this.IDKeeper.NextID);
            node.Caption = newWF.Caption;
            node.workflow = newWF;
            node.editable = true;
            node.parentLayout = layout;
            this.showDialog(node, () => {
                if (!layout.Workflows) {
                    layout.Workflows = [];
                }
                layout.Workflows.push(newWF);
                const nodes = [...this.RootNodes];
                nodes.splice(layout.Workflows.length - 1, 0, node);
                this.RootNodes = nodes;
                this.SelectedNode = node;
                LayoutService.OnLayoutWorkflowAdded({
                    LayoutID: layout.ID,
                    Workflow: JSON.stringify(newWF)
                });
            });
        }
    }

    CopyWorkflow() {
        if (this.SelectedNode && this.SelectedNode.workflow && this.SelectedNode.editable && this.SelectedNode.parentLayout) {
            if (ClipboardHelper.Inititalized) {
                let entry = {
                    type: "ClientWorkflowCopy",
                    content: this.SelectedNode.workflow
                }
                ClipboardHelper.CopyToClipboard(entry);
                NotificationHelper.Info("@@CopyToClipboard", "@@ElementCopiedToClipboard");
            } else {
                const json = serialize(this.SelectedNode.workflow);
                const copy = deserialize(WorkflowDescription, json);
                // copy.Caption += ' (Kopie)';
                const _translateText = TranslateHelper.TranslatorInstance.instant('@@Kopie')
                copy.Caption += ` (${_translateText})`;
                copy.ID = UUID.UUID();
                const node = new WorkflowNode(this.IDKeeper.NextID);
                node.Caption = copy.Caption;
                node.workflow = copy;
                node.editable = true;
                node.parentLayout = this.SelectedNode.parentLayout;
                node.parentLayout.Workflows.push(copy);
                if (this.SelectedNode.parentNode) {
                    this.SelectedNode.parentNode.Children.push(node);
                } else {
                    const nodes = [...this.RootNodes];
                    nodes.splice(this.Layout.Workflows.length - 1, 0, node);
                    this.RootNodes = nodes;
                }
                this.SelectedNode = node;
                LayoutService.OnLayoutWorkflowAdded({
                    LayoutID: node.parentLayout.ID,
                    Workflow: JSON.stringify(copy)
                });
            }
        }
    }

    CreateTemplate() {
        if (this.SelectedNode && this.SelectedNode.workflow && this.SelectedNode.editable) {
            let workflowtemplate = new WorkflowSaveObject();
            workflowtemplate.Caption = new TranslatedString();
            workflowtemplate.Caption.DefaultValue = this.SelectedNode.workflow.Caption;
            workflowtemplate.Modules = this.SelectedNode.workflow.Data.Modules;
            for (var m of workflowtemplate.Modules) {
                m.Settings = JSON.stringify(m.Settings);
            }
            workflowtemplate.ErrorType = this.SelectedNode.workflow.Data.ErrorType;
            workflowtemplate.Connectors = this.SelectedNode.workflow.Data.Connectors;
            workflowtemplate.GenerateLog = this.SelectedNode.workflow.Data.GenerateLog;
            this.service.SaveWorkflowTemplate(workflowtemplate).subscribe();
        }
    }

    DuplicateWorkflow() {
        if (this.SelectedNode && this.SelectedNode.workflow && this.SelectedNode.editable && this.SelectedNode.parentLayout) {
            const json = serialize(this.SelectedNode.workflow);
            const copy = deserialize(WorkflowDescription, json);
            // copy.Caption += ' (Kopie)';
            const _translateText = TranslateHelper.TranslatorInstance.instant('@@Kopie')
            copy.Caption += ` (${_translateText})`;
            copy.ID = UUID.UUID();
            const node = new WorkflowNode(this.IDKeeper.NextID);
            node.Caption = copy.Caption;
            node.workflow = copy;
            node.editable = true;
            node.parentLayout = this.SelectedNode.parentLayout;
            node.parentLayout.Workflows.push(copy);
            if (this.SelectedNode.parentNode) {
                this.SelectedNode.parentNode.Children.push(node);
            } else {
                const nodes = [...this.RootNodes];
                nodes.splice(this.Layout.Workflows.length - 1, 0, node);
                this.RootNodes = nodes;
            }
            this.SelectedNode = node;
            LayoutService.OnLayoutWorkflowAdded({
                LayoutID: node.parentLayout.ID,
                Workflow: JSON.stringify(copy)
            });
        }
    }
    PasteWorkflow() {
        let item = ClipboardHelper.ClipboardContent.content;
        if (item) {
            const json = serialize(item);
            const copy = deserialize(WorkflowDescription, json);
            // copy.Caption += ' (Kopie)';
            const _translateText = TranslateHelper.TranslatorInstance.instant('@@Kopie')
            copy.Caption += ` (${_translateText})`;
            copy.ID = UUID.UUID();
            const node = new WorkflowNode(this.IDKeeper.NextID);
            node.Caption = copy.Caption;
            node.workflow = copy;
            node.editable = true;
            node.parentLayout = this.Layout;
            node.parentLayout.Workflows.push(copy);
            if (this.SelectedNode && this.SelectedNode.parentNode) {
                this.SelectedNode.parentNode.Children.push(node);
            } else {
                const nodes = [...this.RootNodes];
                nodes.splice(this.Layout.Workflows.length - 1, 0, node);
                this.RootNodes = nodes;
            }
            this.SelectedNode = node;
            LayoutService.OnLayoutWorkflowAdded({
                LayoutID: node.parentLayout.ID,
                Workflow: JSON.stringify(copy)
            });
        }
    }
    EditWorkflow() {
        if (this.SelectedNode && this.SelectedNode.workflow && this.SelectedNode.editable) {
            this.showDialog(this.SelectedNode, () => {
                this.RootNodes = [...this.RootNodes];
                LayoutService.OnLayoutWorkflowsChanged({
                    LayoutID: this.SelectedNode.parentLayout.ID,
                    Workflows: [JSON.stringify(this.SelectedNode.workflow)]
                });
            });
        }
    }

    DeleteWorkflow() {
        const node = this.SelectedNode;
        if (node && node.workflow && node.editable) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Workflow {0} loeschen moechten?');
            text.FormatParams.push(node.workflow.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Workflow loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
                    if (retVal === MessageBoxResult.Yes) {
                        const index = node.parentLayout.Workflows.indexOf(node.workflow);
                        if (index > -1) {
                            node.parentLayout.Workflows.splice(index, 1);
                            this.buildTree();
                            this.SelectedNode = null;
                            LayoutService.OnLayoutWorkflowRemoved({
                                LayoutID: node.parentLayout.ID,
                                WorkflowID: node.workflow.ID,
                                WorkflowCaption: node.workflow.Caption
                            });
                        }
                        this.cdRef.detectChanges();
                    }
                });
        }
    }
    showDialog(node, handler) {
        // console.log('nodeeee ---> ',node)
        if (node.workflow) {
            this.previousLayout = node.parentLayout
            this.EditedWorkflow = node.workflow;
            const dialogRef = this.dialog.open(WorkflowEditDialog, {
                data: { Data: node.workflow.Data, Layout: node.parentLayout, Workflow: node.workflow },
                panelClass: 'workflowedit-dialog-container',
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result instanceof WorkflowData) {
                    node.workflow.Data = result;
                    node.Caption = node.workflow.Caption;
                    if (typeof handler === 'function') {
                        handler();
                    }
                }
                this.EditedWorkflow = null;
                this.cdRef.detectChanges();
            });
        }
    }

    showUsage() {
        if (this.SelectedNode && this.SelectedNode.workflow && this.Layout) {
            WorkflowUsageDialog.ShowDialog(this.Layout, this.SelectedNode.workflow);
        }
    }
}

export class WorkflowNode extends ABaseTreeNode {
    workflow;
    editable = false;
    parentLayout;
    parentNode;
}
