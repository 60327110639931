export enum ChartType {
    AnnotationChart,//0
    AreaChart,//1
    BarChart,//2
    Bar,//3
    BubbleChart,//4
    ColumnChart,//5
    ComboChart,//6
    CandlestickChart,//7
    DonutChart,//8
    GeoChart,//9
    Histogram,//10
    LineChart,//11
    Line,//12
    Map,//13
    PieChart,//14
    ScatterChart,//15
    SteppedAreaChart,//16
    //Trendline,//17
    //WaterfallChart,//18
    WordTree,//17
    OrgChart,//18
    Gauge,//19
    radar,//20
    polarArea,//21
    TreeMap,//22
    Gantt,//23
    Calendar,//24
    Timeline,//25
    Interval,//26
    radialBar,//27
    heatmap,//28
    Sunburst//29
}