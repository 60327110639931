import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseDialog } from '../basedialog/base.dialog';
import { WebHookService } from '../../../services/webhook.service';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';

@Component({
    selector: 'webhook-dialog',
    templateUrl: './webhook.dialog.html',
    styleUrls: ['./webhook.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebHookDialog implements OnInit {

    result;
    _Name = "";
    @Input()
    get Name() {

        return this._Name;
    }
    set Name(val) {
        this._Name = val;
    }

    HasChanges = false;
    CustomButtons = [{
        Caption: '@@Speichern',
        ID: 0
    }, {
        Caption: '@@Abbrechen',
        ID: 1
    }];
    CloseOnCustomButton = true;
    Loading = true;

    public static ShowDialog(handler) {
        BaseDialog.ShowDialog({
            ContentType: WebHookDialog,
            InitArgs: null,
            Title: '@@Neuer Webhook anlegen',
            Buttons: DialogButton.None,
            Handler: handler
        });


    }


    GetDialogResult() {

        return this.result;
    }


    constructor(private service: WebHookService) {
    }

    ngOnInit(): void {
    }

    Initialize(data) {

    }


    async OnCustomButtonClicked(button) {
        this.result = null;
        if (button && button.ID === 0) {
            if (this.Name !== "") {
                const retVal = await this.service.SaveNew(this._Name).toPromise();
                    if (retVal) {

                        this.result = {};
                            this.result['Key'] = retVal.AdditionalInfos[0];
                            this.result['Name'] = retVal.Caption;
                            this.result['SID'] = retVal.SID;


                        NotificationHelper.Success('@@Webhookkey:' + this.result['Key'], '@@Webhooksetting has been successfully saved');
                    }
            }
        }
    }

}
