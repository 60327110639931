import { Component } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BaseDialog } from '../../../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../../../models/enums/messageboxresult.enum';
import { DataService } from '../../../../../services/data.service';
import { RestService } from '../../../../../services/rest.service';
import { BaseListDetail } from '../../../../../settings/base.list.settings';
import { MappingRestValuesDialog } from './mapping.rest.values.dialog/mapping.rest.values.dialog';

@Component({
    selector: 'mapping-rest-detail',
    templateUrl: 'mapping.rest.detail.html',
    styleUrls: ['mapping.rest.detail.css']
})
export class MappingRestDetailComponent extends BaseListDetail {
    public AttributeValues: any[] = [];
    public Rows: any[] = [];
    public Columns: any[] = [];
    public AllContainerClassObjects: any[] = [];
    public SelectedAttributeClassObject;
    public SelectedAttributeMappingObject;
    constructor(private _restService: RestService, private dataService: DataService) {
        super();
    }

    public addField(): void {
        this.showDialog(null, (r) => {
            this.OnItemChanged();
            r['ID'] = UUID.UUID();
            const row = this.fillRow({}, r);
            this.SelectedItem.Fields.push(r);
        });
    }

    public editField(row): void {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            let fieldIndex, field;
            if (selected.Fields.some((x, i) => {
                if (x.ID === row.ID) {
                    fieldIndex = i;
                    field = x;
                    return true;
                }
                return false;
            })) {
                this.showDialog(field, (r) => {
                    this.fillRow(row, r);
                    selected.Fields.splice(fieldIndex, 1, r);
                    this.OnItemChanged();
                });
            }
        }
    }

    deleteField(row, index) {
        const selected = this.SelectedItem;
        if (selected && selected.Fields) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Feld {0} loeschen moechten?');
            text.FormatParams.push(row.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Feld loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        let fieldIndex = -1;
                        if (selected.Fields.some((f, i) => {
                            if (f.ID === row.ID) {
                                fieldIndex = i;
                                return true;
                            }
                            return false;
                        })) {
                            selected.Fields.splice(fieldIndex, 1);
                            this.Rows.splice(index, 1);
                            this.OnItemChanged();
                        }
                    }
                });
        }
    }

    public fillRow(row, f): void {
        row = f;
        return row;
    }

    setSelectedItem(item): void {
        super.setSelectedItem(item);
        if (item /*&& item.Fields*/) {
            //const rows = this.Rows = [];
            this._restService.loadAllContainerClassObjects().subscribe((data) => {
                if (data) {
                    this.AllContainerClassObjects = data;
                    this.AttributeMappingObjectChanged();
                    //item.Fields.forEach(f => {
                    //    rows.push(this.fillRow({}, f));
                    //});

                    //this.Rows = rows;
                }
            });
        }
    }

    AttributeMappingObjectChanged(): void {
        this.SelectedAttributeMappingObject = null;
        if (this.SelectedItem && this.SelectedItem.AttributeMappingClassID && this.SelectedItem.AttributeMappingClassID !== '00000000-0000-0000-0000-000000000000') {
            this._restService.loadContainerClassObjectByID(this.SelectedItem.AttributeMappingClassID).subscribe((result) => {
                this.SelectedAttributeMappingObject = result;
                //ToDo: Fill selecteditem.Fields with actual data with mappings
                //this.SelectedItem.Fields = [];
                this.Columns = [];
                result.Fields.forEach(f => {
                    delete f.__id;
                    if (f.Caption) {
                        delete f.Caption.__id
                    }
                    if(f.Name != 'MandantID') //MandantID field should not be shown in Columns
                    this.Columns.push(f);
                });
            });
            this._restService.getDataFromAttributeClass(this.SelectedItem.AttributeClassID).subscribe((result) => {
                this.AttributeValues = result;
            });
        }
    }

    showDialog(field, handler) {
        BaseDialog.ShowDialog({
            ContentType: MappingRestValuesDialog,
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Feld definieren',
            InitArgs: {
                Field: field,
                Columns: this.Columns,
                AttributeValues: this.AttributeValues
            }
        });
    }

    getFriendlyName(columnType: string, value: string) {
        if (columnType === '983dad4c-fd88-4124-aed0-1dd1d177521a') {
            var attr = this.AttributeValues.find(x => x.Value === value);
            if (attr) {
                return attr.Caption;
            }
            return value;
        }
        return value;
    }
}