import { Component } from '@angular/core';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { AServiceWorkflowData, WorkflowStatus, LoopWorkflowModuleExecuter } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';

@Component({
    selector: 'wf-foreach-settings',
    templateUrl: './forEachLoop.settings.html',
    styleUrls: ['./forEachLoop.settings.css']
})
export class ForEachLoopSettings extends WorkflowDialogContent {
    UseParameter = true;
    Count = 0;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'forEachWFModule';
        reg.Caption = '@@For-Schleife';
        reg.GroupID = 'listoperations';
        reg.SettingsControl = ForEachLoopSettings;
        reg.SettingsTypeHelper = new ForEachLoopSettingsDataHelper();
        reg.Executer = ForEachLoopModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            this.UseParameter = data.UseParameter;
            this.Count = data.Count;
        }
    }

    getResult(): any {
        const retVal = new ForEachLoopSettingsData();
        retVal.UseParameter = this.UseParameter;
        retVal.Count = this.Count;
        return retVal;
    }
}

export class ForEachLoopSettingsData extends AServiceWorkflowData {
    UseParameter = true;
    Count = 0;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ForEach.ForEachLoopSettingsData';
    }
}

export class ForEachLoopSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ForEachLoopSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const forEach = new WorkflowExitInfo();
        forEach.ID = 0;
        forEach.Label = '@@Schleife';
        const end = new WorkflowExitInfo();
        end.ID = 1;
        end.Label = '@@Ende';
        end.Type = 'forEach';
        return [forEach, end];
    }
}

export class ForEachLoopModuleExecuter extends LoopWorkflowModuleExecuter {
    private FirstExecute = true;
    private List;
    private Length = -1;
    private ActIndex = 0;

    async execute(status: WorkflowStatus): Promise<number> {
        if (this.FirstExecute) {
            this.FirstExecute = false;
            status.AddLoop(this);
            if (status.ActualSettings) {
                if (status.ActualSettings.UseParameter) {
                    if (status.ActualParameter) {
                        if (Array.isArray(status.ActualParameter)) {
                            this.List = status.ActualParameter;
                            this.Length = status.ActualParameter.length;
                            return this.executeInternal(status);
                        } else {
                            const num = parseInt(status.ActualParameter, 10);
                            if (isNaN(num)) {
                                status.Logger.logError('ForEach modul: No enumerable parameter set.');
                            } else {
                                this.Length = num;
                                return this.executeInternal(status);
                            }
                        }
                    } else {
                        status.Logger.logError('ForEach modul: No actual parameter set.');
                    }
                } else {
                    this.Length = status.ActualSettings.Count;
                    return this.executeInternal(status);
                }
            } else {
                status.Logger.logError('ForEach modul: No settings found.');
            }
        } else {
            return this.executeInternal(status);
        }
        return super.execute(status);
    }

    private executeInternal(status: WorkflowStatus): number {
        if (this.ActIndex < this.Length) {
            if (this.List) {
                status.ActualParameter = this.List[this.ActIndex];
            } else {
                status.ActualParameter = this.ActIndex;
            }
            this.ActIndex++;
            return 0;
        }
        status.ActualParameter = null;
        if (status.RemoveLoop(this)) {
            return 1;
        }
        return -1;
    }

    reset() {
        this.FirstExecute = true;
        this.List = null;
        this.Length = -1;
        this.ActIndex = 0;
    }
}
