import { PropertyMapper } from '../../../helpers/property.mapper';
import { TranslatedString } from '../../../models/translatedstring.model';

export class RestObject {
    public SID: string;
    public Caption: TranslatedString;
    public Name: string;
    public RestID: string;
    public Fields: any[];
    public UseVersioning: boolean;
    public DeleteComplete: boolean;

    constructor(value: RestObject = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);           
        }
    }
}