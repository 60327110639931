import { Component } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { ConditionOperator } from '../../../models/enums/conditionoperator.enum';
import { FilterTaskExecuter } from '../../../models/tasks/datatasks/filter.model';
import { ALevelMeasurePanel, ALevelMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'filter-task-panel',
    templateUrl: './filter.task.panel.html'
})
export class FilterTaskPanel extends ALevelMeasurePanel {
    CompareTypes;
    OppositeLevels = [];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = 'filter';
        reg.Caption = '@@Filter';
        reg.SettingsControl = FilterTaskPanel;
        reg.SettingsHelper = new FilterSettingsHelper();
        reg.Executer = FilterTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

    constructor() {
        super();
        this.CompareTypes = EnumHelper.GetDropdownValues(ConditionOperator);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.Levels.push({
            Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle X-Level'),
            Value: -1
        });
        this.Levels.push({
            Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle Y-Level'),
            Value: -2
        });
    }

    onLevelChanged() {
        const opposite = [];
        if (this.SettingsValue && this.SettingsValue.LevelUniqueID && this.DataDescriptionValue) {
            opposite.push({
                Caption: 'All',
                Value: -1
            });
            if (this.DataDescriptionValue.XLevelNodes.Areas.some(a => a.Tuples.some(t =>
                t.Levels.some(l => l.UniqueID === this.SettingsValue.LevelUniqueID)))) {
                this.DataDescription.YLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    opposite.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                });
                            }
                        });
                    }
                });
                opposite.push({
                    Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle Y-Level'),
                    Value: -2
                });
            } else {
                this.DataDescription.XLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(tuple => {
                            if (tuple.Levels) {
                                tuple.Levels.forEach(lev => {
                                    opposite.push({
                                        Caption: lev.toString(),
                                        Value: lev.UniqueID
                                    });
                                });
                            }
                        });
                    }
                });
                opposite.push({
                    Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle X-Level'),
                    Value: -2
                });
            }
        }
        this.OppositeLevels = opposite;
    }

    OnSettingsSet() {
        this.onLevelChanged();
    }
}

export class FilterSettingsHelper extends ALevelMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Filter');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Filter');
    }
}
