import { OnInit, Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: 'evi-navigationtree',
  templateUrl: './navigationtree.control.html',
    styleUrls: ['./navigationtree.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationTreeControl implements OnInit {

  //#region ItemsSource
  ItemsSourceValue;

  @Input()
  get ItemsSource() {
    return this.ItemsSourceValue;
  }
  set ItemsSource(val) {
    this.ItemsSourceValue = val;
    this.ItemsSourceChange.emit(this.ItemsSourceValue);
  }

  @Output() ItemsSourceChange = new EventEmitter<any>();
  //#endregion

  constructor() {
  }

  ngOnInit(): void {

  }
}
