import { add } from 'mathjs';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult, AxisNode } from '../../datadescription/multi/multiresult.model';
import { ATask, ITaskExecuter } from '../atask.model';
import { AxisType } from '../../enums/query.enum';

export class CumulateTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    LevelUniqueID;
    MeasureUniqueID;

    Execute() {
    }
}

export class CumulateTaskExecuter implements ITaskExecuter {
    static TaskID = 'cumulate';

    LevelUniqueID: number;
    MeasureUniqueID = -1;
    Result: MultiResult;
    DataDescription: DataDescription;

    private static CumulateNodes(nodes: AxisNode[], oppositeNodes: AxisNode[], measure: AxisNode,
        result: MultiResult, cellAction, tryGetCellAction, recursive: boolean) {
        const visibleNodes = nodes.filter(x => x.Visible !== false);
        if (visibleNodes.length > 1) {
            MultiResultHelper.ExecuteOnAllNodesInternal(oppositeNodes, (oppNode) => {
                let actVal = null;
                visibleNodes.forEach(lNode => {
                    if (actVal == null) {
                        const cellVal = cellAction(result, lNode, oppNode, measure, false);
                        if (cellVal) {
                            actVal = cellVal.InternalValue;
                        }
                    } else {
                        const cellVal = tryGetCellAction(result, lNode, oppNode, measure);
                        if (cellVal.IsNew) {
                            cellVal.Node.InternalValue = actVal;
                        } else {
                            if (cellVal.Node.InternalValue != null) {
                                actVal = add(actVal, cellVal.Node.InternalValue);
                            }
                            cellVal.Node.InternalValue = actVal;
                        }
                    }
                    if (recursive) {
                        CumulateTaskExecuter.CumulateNodes(lNode.Children, oppositeNodes,
                            measure, result, cellAction, tryGetCellAction, recursive);
                    }
                });
            });
        }
    }

    Init(settings, result, dataDescription, context) {
        if (settings) {
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
        }
        this.Result = result;
        this.DataDescription = dataDescription;
    }

    Execute() {
        if (this.Result && this.DataDescription && typeof this.LevelUniqueID === 'number' && this.MeasureUniqueID >= -1) {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                const levelNodeList: AxisNode[][] = [];
                let oppNodes: AxisNode[] = [];
                let cellAction, tryGetCellAction;
                let recursive = false;
                const noLevelIndex = {
                    Index: 0
                };
                if (this.LevelUniqueID === -1) { // Alle X
                    cellAction = MultiResultHelper.GetValueX;
                    tryGetCellAction = MultiResultHelper.TryGetCellOrNew;
                    recursive = true;
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.X_Axis) { // Area auf X suchen
                        this.DataDescription.XLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                levelNodeList.push(MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex).Nodes);
                                oppNodes = this.Result.YAxis.Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    } else {
                        this.DataDescription.YLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                levelNodeList.push(this.Result.XAxis.Nodes);
                                oppNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex).Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    }
                } else if (this.LevelUniqueID === -2) { // Alle Y
                    cellAction = MultiResultHelper.GetValueY;
                    tryGetCellAction = (r, l, o, m) => {
                        return MultiResultHelper.TryGetCellOrNew(r, o, l, m);
                    };
                    recursive = true;
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.Y_Axis) { // Area auf Y suchen
                        this.DataDescription.YLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                levelNodeList.push(MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex).Nodes);
                                oppNodes = this.Result.XAxis.Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    } else {
                        this.DataDescription.XLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                levelNodeList.push(this.Result.YAxis.Nodes);
                                oppNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex).Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    }
                } else if (this.LevelUniqueID > -1) {
                    if (!this.DataDescription.XLevelNodes.Areas.some(a => {
                        if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                            if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                cellAction = MultiResultHelper.GetValueX;
                                tryGetCellAction = MultiResultHelper.TryGetCellOrNew;
                                levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.XAxis, this.LevelUniqueID));
                                oppNodes = this.Result.YAxis.Nodes;
                            } else {
                                this.DataDescription.YLevelNodes.Areas.some(area => {
                                    if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                        cellAction = MultiResultHelper.GetValueX;
                                        tryGetCellAction = MultiResultHelper.TryGetCellOrNew;
                                        levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.XAxis, this.LevelUniqueID));
                                        oppNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex).Nodes;
                                        return true;
                                    } else {
                                        if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                        } else {
                                            noLevelIndex.Index++;
                                        }
                                    }
                                    return false;
                                });
                            }
                            return true;
                        }
                        return false;
                    })) {
                        noLevelIndex.Index = 0;
                        this.DataDescription.YLevelNodes.Areas.some(a => {
                            if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                                if (a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                    cellAction = MultiResultHelper.GetValueY;
                                    tryGetCellAction = (r, l, o, m) => {
                                        return MultiResultHelper.TryGetCellOrNew(r, o, l, m);
                                    };
                                    levelNodeList.push(...MultiResultHelper.GetNodeSections(this.Result.YAxis, this.LevelUniqueID));
                                    oppNodes = this.Result.XAxis.Nodes;
                                } else {
                                    this.DataDescription.XLevelNodes.Areas.some(area => {
                                        if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                            cellAction = MultiResultHelper.GetValueY;
                                            tryGetCellAction = (r, l, o, m) => {
                                                return MultiResultHelper.TryGetCellOrNew(r, o, l, m);
                                            };
                                            const sections = MultiResultHelper.GetNodeSections(this.Result.YAxis, this.LevelUniqueID);
                                            levelNodeList.push(...sections);
                                            oppNodes =
                                                MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex).Nodes;
                                            return true;
                                        } else {
                                            if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                            } else {
                                                noLevelIndex.Index++;
                                            }
                                        }
                                        return false;
                                    });
                                }
                                return true;
                            }
                            return false;
                        });
                    }
                }
                if (levelNodeList.length > 0 && oppNodes.length > 0 && cellAction && tryGetCellAction) {
                    levelNodeList.forEach(x => {
                        CumulateTaskExecuter.CumulateNodes(x, oppNodes, measure, this.Result, cellAction, tryGetCellAction, recursive);
                    });
                }
            }
        }
    }
}
