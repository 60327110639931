import { Component } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { SortType, TaskAxisLevel } from '../../../models/enums/sortType.enum';
import { DataSortTaskExecuter } from '../../../models/tasks/datatasks/datasort.model';
import { ALevelMeasurePanel, ALevelMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'dataSort-task-panel',
    templateUrl: './dataSort.task.panel.html'
})
export class DataSortTaskPanel extends ALevelMeasurePanel {
    SortTypes: any[];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = 'dataSort';
        reg.Caption = '@@Sortieren';
        reg.SettingsControl = DataSortTaskPanel;
        reg.SettingsHelper = new DataSortSettingsHelper();
        reg.Executer = DataSortTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.Levels?.length > 1) {
            this.Levels.push({
                Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle X-Level'),
                Value: TaskAxisLevel.XAxis
            });
            this.Levels.push({
                Caption: TranslateHelper.TranslatorInstance.instant('@@Ueber alle Y-Level'),
                Value: TaskAxisLevel.YAxis
            });
        }
    }


    constructor() {
        super();
        this.SortTypes = EnumHelper.GetDropdownValues(SortType).filter(x => x.type != SortType.Attribute);
    }
}

export class DataSortSettingsHelper extends ALevelMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Sortieren');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Sortieren');
    }
}
