import { Component } from '@angular/core';
import { OcDragItemType } from '../../../models/enums/oc.DragItemType';
import { DatadescriptionControl, OCMPDragInfo } from '../../dialogs/datadescription/datadescription.control';
import { ObjectCreatorSourceTreeBase, TreeNodeSource } from './objectcreatorsourcetree.base';

@Component({
    selector: 'objectcreatorsourcetreebase-measure',
    templateUrl: './objectcreatorsourcetree.base.html',
    styleUrls: ['./objectcreatorsourcetree.css']
})
export class ObjectCreatorSourceTreeMeasure extends ObjectCreatorSourceTreeBase {

    UpdateTreeSource(node) {
        if (this.DbItems) {
            if (node == null) {
                this.TreeSource = [];
                const cubeNodes = new Map<string, TreeNodeSource>();
                const cubeItems = this.DbItems;
                for (let h = 0; h < cubeItems.length; h++) {
                    const cubeDbItem = cubeItems[h];
                    if (!cubeDbItem.UniqueID) {
                        cubeDbItem.UniqueID = this.MaxUniqueID++;
                    }
                    const folderNodes = new Map<string, TreeNodeSource>();
                    const measureNodesOnRoot: TreeNodeSource[] = [];

                    const cubeNode = new TreeNodeSource(cubeDbItem.UniqueID);
                    cubeNode.Children = [];
                    cubeNode.HasChildren = true;
                    cubeNode.Caption = cubeDbItem.Caption != null ? cubeDbItem.Caption : 'undefined';
                    cubeNode.SID = cubeDbItem.ID;
                    cubeNode.Icon = "cube";
                    cubeNode.IsExpanded = false;
                    cubeNodes.set(cubeDbItem.ID != null ? cubeDbItem.Caption : 'undefined', cubeNode);

                    const measureItems = cubeItems[h].Measures;
                    for (let i = 0; i < measureItems.length; i++) {
                        if (measureItems[i]) {
                            const dbItem = measureItems[i];
                            if (!dbItem.UniqueID) {
                                dbItem.UniqueID = this.MaxUniqueID++;
                            }
                            if (dbItem.Folder && !folderNodes.has(dbItem.Folder)) {
                                const folderNode = new TreeNodeSource(dbItem.UniqueID);
                                folderNode.Children = [];
                                folderNode.HasChildren = true;
                                folderNode.Caption = dbItem.Folder;
                                folderNode.SID = dbItem.ID;
                                folderNode.DataSourceID = measureItems[i].DataSourceId;
                                folderNode.IsExpanded = false;
                                folderNodes.set(dbItem.Folder, folderNode);
                            }
                            if (!measureItems[i].UniqueID) {
                                measureItems[i].UniqueID = this.MaxUniqueID++;
                            }
                            const Node = new TreeNodeSource(measureItems[i].UniqueID);
                            Node.Caption = measureItems[i].Caption != null ? measureItems[i].Caption : 'undefined';
                            Node.SID = measureItems[i].ID;
                            Node.DataSourceID = measureItems[i].DataSourceId;
                            Node.IsExpanded = false;
                            Node.Icon = "bar_chart";
                            if (dbItem.Folder && !folderNodes.has(dbItem.Folder)) {
                                let folderNode: TreeNodeSource;
                                folderNode = folderNodes.get(dbItem.Folder);
                                folderNode.Children.push(Node);
                            }
                            else {
                                measureNodesOnRoot.push(Node);
                            }
                        }
                    }

                    if (folderNodes.size > 0) {
                        cubeNode.HasChildren = true;
                        const allFolderChildren = [];
                        folderNodes.forEach((value: TreeNodeSource, key: string) => {
                            if (value && value.HasChildren)
                                allFolderChildren.push(value);
                        });
                        this.SortTreeListAlphabetical(allFolderChildren, true);
                        cubeNode.Children.push(...allFolderChildren);
                    }
                    if (measureNodesOnRoot) {
                        this.SortTreeListAlphabetical(measureNodesOnRoot, true);
                        cubeNode.Children.push(...measureNodesOnRoot);
                    }
                }
                if (cubeNodes.size > 0) {
                    cubeNodes.forEach((value: TreeNodeSource, key: string) => {
                        this.TreeSource.push(value);
                    });
                    this.SortTreeListAlphabetical(this.TreeSource, false);
                }
            }
        }
    }

    dragStart(event) {
        if (event && event.DragData && event.DragData.length > 0) {
            const dbItem = event.DragData[0];
            if (dbItem) {
                const idsToDrop = [];
                if (dbItem.Children) {
                    dbItem.Children.forEach(c => {
                        idsToDrop.push(c.SID);
                    });
                } else {
                    idsToDrop.push(dbItem.SID);
                }
                DatadescriptionControl.ObjectCreatorMPDragStart.next(new OCMPDragInfo(OcDragItemType.Measure, {
                    DropIDs: idsToDrop
                }));
            }
        }
    }

    dragEnd(ev) {
        ObjectCreatorSourceTreeMeasure.DragEnd.next(null);
        DatadescriptionControl.ObjectCreatorMPDragStart.next(null);
    }
}
