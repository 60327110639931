import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class VersioningService extends RequestBase {
    BasePath = 'api/versioning';

    GetVersionInfos(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetVersionInfos', this.options);
    }

    GetVersionContent(type, sid, version): Observable<any> {
        const p = this.API_BASE_URL + this.BasePath + '/GetVersionContent?objectType=' + type + '&sid=' + sid + '&version=' + version;
        return this.http.get<any>(p, this.options);
    }

    ChangeActiveVersion(type, sid, version): Observable<any> {
        const p = this.API_BASE_URL + this.BasePath + '/ChangeActiveVersion?objectType=' + type + '&sid=' + sid + '&version=' + version;
        return this.http.get<any>(p, this.options);
    }

    DeleteInactiveVersion(type, sid, version): Observable<any> {
        const p = this.API_BASE_URL + this.BasePath + '/DeleteInactiveVersion?objectType=' + type + '&sid=' + sid + '&version=' + version;
        return this.http.delete<any>(p, this.options);
    }
}
