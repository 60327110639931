import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { SelfServiceObjectService } from '../../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent } from '../../../../workflow/workflow.dialog';
import { GenerateReaderSettingsData } from '../generatereader/generate.reader.settings';
import { RelToObjectColumnMapping, RelToObjectSettingsData } from './reltoobject.data';

@Component({
    selector: 'wf-reltoobject-settings',
    templateUrl: './reltoobject.settings.html',
    styleUrls: ['./reltoobject.settings.css']
})
export class RelToObjectSettings extends ContainerWorkflowDialogContent {

    Data = new RelToObjectSettingsData();
    TableData = new GenerateReaderSettingsData();
    ActTableProps = [];
    Columns = [];
    DBUsages = [[32, 2097152]]; // DocumentStoreWrite + ObjectWrite

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'reltoobjectWFModule';
        reg.Caption = '@@Zu Objekt konvertieren';
        reg.GroupID = 'reldataoperations';
        reg.Index = 110;
        reg.SettingsControl = RelToObjectSettings;
        reg.SettingsTypeHelper = new RelToObjectSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    private static MapProperties(propList, mappings): RelToObjectColumnMapping[] {
        const retVal = [];
        if (propList) {
            if (!mappings) {
                mappings = [];
            }
            propList.forEach(p => {
                let map = mappings.find(x => x.Target === p.Name && x.IsList === p.IsList && x.IsObject === p.IsObject);
                if (!map) {
                    map = new RelToObjectColumnMapping();
                    map.IsList = p.IsList;
                    map.IsObject = p.IsObject;
                    map.Target = p.Name;
                    map.SimpleListType = p.SimpleType;
                }
                if (p.IsObject) {
                    map.ObjectMappings = RelToObjectSettings.MapProperties(p.ObjectProperties, map.ObjectMappings);
                }
                retVal.push(map);
            });
        }
        return retVal;
    }

    private static CheckProperties(mapList: RelToObjectColumnMapping[]): RelToObjectColumnMapping[] {
        const retVal = [];
        if (mapList) {
            mapList.forEach(p => {
                if (p.Target) {
                    if (p.IsList) {
                        if ((p.IsCommaSeparated && p.Separator && p.SimpleListType) || (p.ListID && p.ListID.length > 0)) {
                            if (p.IsObject) {
                                p.ObjectMappings = RelToObjectSettings.CheckProperties(p.ObjectMappings);
                                if (p.ObjectMappings && p.ObjectMappings.length > 0) {
                                    retVal.push(p);
                                }
                            } else if (p.Source) {
                                retVal.push(p);
                            }
                        }
                    } else {
                        if (p.IsObject) {
                            p.ObjectMappings = RelToObjectSettings.CheckProperties(p.ObjectMappings);
                            if (p.ObjectMappings && p.ObjectMappings.length > 0) {
                                retVal.push(p);
                            }
                        } else if (p.Source) {
                            retVal.push(p);
                        }
                    }
                }
            });
        }
        return retVal;
    }

    constructor(private objectService: SelfServiceObjectService) {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        super.initialize(data);
        const dsInfo = this.ActualState.get('DataSource');
        if (dsInfo) {
            this.Columns = dsInfo.Columns;
        }
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(RelToObjectSettingsData, json);
            this.TableData.DataModel = this.Data.DataModel;
            this.TableData.DataSource = this.Data.DataSource;
            this.TableData.ContainerTable = this.Data.ExistingStructure;
            this.TableData.ContainerInternalID = this.Data.ContainerInternalID;
        }
    }

    getResult(): any {
        this.Data.Mappings = RelToObjectSettings.CheckProperties(this.Data.Mappings);
        this.Data.DataModel = this.TableData.DataModel;
        this.Data.DataSource = this.TableData.DataSource;
        this.Data.ExistingStructure = this.TableData.ContainerTable;
        this.Data.ContainerInternalID = this.TableData.ContainerInternalID;
        return this.Data;
    }

    useTableChanged() {
        if (this.Data.UseTable) {
            this.Data.Mappings = RelToObjectSettings.MapProperties(this.ActTableProps, this.Data.Mappings);
        } else {
            this.Data.Mappings = RelToObjectSettings.CheckProperties(this.Data.Mappings);
        }
    }

    onDataChanged() {
        this.ActTableProps = [];
        if (this.TableData.ContainerTable) {
            this.objectService.GetStructurePreviewForMapping(this.TableData.ContainerTable, true).subscribe(result => {
                if (result) {
                    this.ActTableProps = result;
                    this.Data.Mappings = RelToObjectSettings.MapProperties(this.ActTableProps, this.Data.Mappings);
                }
            });
        } else {
            this.Data.Mappings = [];
        }
    }
}



export class RelToObjectSettingsDataHelper extends WorkflowModuleSettingsHelper {
    private static GetProperties(mapList, path, columns) {
        const retVal = [];
        if (mapList) {
            let newPath = path;
            if (newPath) {
                newPath += '.';
            }
            mapList.forEach(m => {
                let simpleType = 'System.String';
                const map = columns.find(x => x.Name === m.Source);
                if (map && map.DataTyp) {
                    simpleType = map.DataTyp;
                }
                const prop = {
                    Name: m.Target,
                    Path: path,
                    IsObject: m.IsObject,
                    IsList: m.IsList,
                    SimpleType: simpleType,
                    ObjectProperties: [],
                    FullPath: newPath + m.Target
                };
                if (m.IsObject) {
                    prop.ObjectProperties = RelToObjectSettingsDataHelper.GetProperties(m.ObjectMappings, prop.FullPath, columns);
                }
                retVal.push(prop);
            });
        }
        return retVal;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'objData';
        return [def];
    }
    getEmptySettingsInstance() {
        return new RelToObjectSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'relData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings) {
                let columns = [];
                const ds = state.get('DataSource');
                if (ds && ds.Columns) {
                    columns = ds.Columns;
                }
                const props = RelToObjectSettingsDataHelper.GetProperties(settings.Mappings, '', columns);
                state.set('DataSource', { Properties: props, DataModel: settings.DataModel });
            }
        }
    }
}
