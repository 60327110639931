import { Subject } from "rxjs";
import { ViewType } from "../models/enums/viewtype.enum";
import { LayoutService } from "../services/layout.service";
import { NotificationHelper } from "./notification.helper";

export class ClipboardHelper {
    public static Inititalized = false;
    public static Init() {
        if (navigator && navigator.permissions) {
            const perm: any = { name: "clipboard-read" };
            navigator.permissions.query(perm).then((result) => {
                // If permission to read the clipboard is granted or if the user will
                // be prompted to allow it, we proceed.

                if (result.state == "granted" ) {
                    ClipboardHelper.Inititalized = true;
                    document.addEventListener('visibilitychange', () => {
                        if (document.visibilityState === 'visible') {
                            ClipboardHelper.Refresh();
                        }
                    })
                    LayoutService.ViewType.subscribe((value) => {
                        if (value == ViewType.Edit) {
                            ClipboardHelper.Refresh();
                        }
                    })
                    ClipboardHelper.Refresh();
                    LayoutService.SelectedItem.subscribe((value) => {
                        ClipboardHelper.Refresh();
                    });
                }
                // else if (result.state == "prompt") {
                //     NotificationHelper.Info("Clipboard permission", "Please allow clipboard access to use the clipboard feature.");
                // }
            });
        }
    }
    private static ReadContent(data) {
        if (data != ClipboardHelper.RawContent) {
            if (data && data.startsWith("{")) {
                ClipboardHelper.CopyToClipboard(JSON.parse(data));
            }
        }
    }
    public static Refresh() {
        try {
            if (document.hasFocus()) {
                navigator.clipboard.readText().then((data) => {
                    ClipboardHelper.ReadContent(data);
                });
            } else {
                ClipboardHelper.ReadContent(null);
            }
        } catch {
            ClipboardHelper.ReadContent(null);
        }
    }

    public static ClipboardContent = null;
    private static RawContent = null;
    public static ClipboardChanged = new Subject();

    public static SetClipboard(data) {
        if (data != ClipboardHelper.RawContent) {
            ClipboardHelper.RawContent = JSON.stringify(data);
            ClipboardHelper.ClipboardContent = data;
            ClipboardHelper.ClipboardChanged.next(ClipboardHelper.ClipboardContent);
        }
    }
    public static CopyToClipboard(data) {
        if (data != ClipboardHelper.RawContent) {
            ClipboardHelper.RawContent = JSON.stringify(data);
            ClipboardHelper.ClipboardContent = data;
            if (document.hasFocus()) {
                navigator.clipboard.writeText(ClipboardHelper.RawContent).then(() => {
                    ClipboardHelper.ClipboardChanged.next(ClipboardHelper.ClipboardContent);
                }).catch((err) => { 
                    NotificationHelper.Error("@@Unable to copy to clipboard.","@@Clipboard error");
                });
            }
        }
    }
}