import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { Comparer } from '../../../models/enums/comparer.enum';
import { Concat } from '../../../models/enums/contact.enum';
import { RequestFilter } from '../../../models/rest/requestfilter';

@Component({
    selector: 'advancedfilter',
    templateUrl: './advancedfilter.component.html',
    styleUrls: ['advancedfilter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedFilterComponent {

    AdditionalInfos: Array<{ key: string, value: any }> = new Array<{ key: string, value: any }>();

    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        const actConfig: QueryBuilderConfig = {
            fields: { }
        };
        if (val) {
            actConfig.getOperators = this.getOperatorsForField.bind(this);
            actConfig.getInputType = this.getInputType.bind(this);
            val.forEach((column) => {
                if (column.IsFilterable) {
                    actConfig.fields[column.PropertyName] = {
                        name: column.Caption,
                        type: column.Type.toLowerCase()
                    };
                    this.AdditionalInfos.push({ key: column.PropertyName, value: column.AdditionalInfos });
                }
            });
        } else {
            actConfig.addRule = (p) => {
                p.rules = p.rules.concat([{
                    field: '',
                    value: '',
                    operator: '='
                }]);
            };
            actConfig.getInputType = (f, o) => {
                if (o === 'in' || o === 'notin') {
                    return 'multistring';
                }
                return 'string';
            };
            actConfig.getOperators = (f, fo) => {
                return ['=', '!=', '<', '<=', '>', '>=', 'like', 'notlike', 'in', 'notin'];
            };
            actConfig.getOptions = (f) => {
                return [];
            };
        }
        this.config = actConfig;
        this.ColumnsChange.emit(this.ColumnsValue);
    }

    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion
    //#region Variables
    VariablesValue = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        if (val) {
            this.VariablesValue = val;
            this.VariablesChange.emit(this.VariablesValue);
        }
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion
    //#region SystemVariables
    SystemVariablesValue = [];

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        this.SystemVariablesValue = val;
        this.SystemVariablesChange.emit(this.SystemVariablesValue);
    }
    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion

    AllTypes = ['string', 'date', 'number', 'boolean', 'float'];
    Operators: Map<string, string[]>;
    UseTextInput = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    public query = {
        condition: 'and',
        rules: [
        ]
    };

    public config: QueryBuilderConfig = {
        fields: {}
    };

    private static BuildFilter(rules, filters, additionalInfos: Array<{ key: string, value: any[] }>) {
        rules.forEach((rule) => {
            if (rule.condition) {
                const filter = new RequestFilter();
                filter.ConcatOperator = rule.condition === 'and' ? Concat.And : Concat.Or;
                filter.Filters = [];
                filter.IsOptional = rule.IsOptional;
                AdvancedFilterComponent.BuildFilter(rule.rules, filter.Filters, additionalInfos);
                filters.push(filter);
            } else {
                const filter = new RequestFilter();
                filter.Name = rule.field;
                filter.Value = rule.value;
                filter.IsOptional = rule.IsOptional;
                filter.Operator = RequestFilter.GetComparerFromText(rule.operator);
                if (filter.Operator === Comparer.Between || filter.Operator === Comparer.NotBetween) {
                    filter.Value2 = rule.value2;
                }
                if (additionalInfos) {
                    const addInfo = additionalInfos.find(x => x.key === rule.field);
                    filter.AdditionalInfos = addInfo ? addInfo.value : null;
                }
                filters.push(filter);
            }
        });
    }

    private static BuildRule(filters, rules) {
        filters.forEach((filter) => {
            if (filter.Filters && filter.Filters.length > 0) {
                const rule = {
                    condition: filter.ConcatOperator === Concat.And ? 'and' : 'or',
                    rules: []
                };
                AdvancedFilterComponent.BuildRule(filter.Filters, rule.rules);
                rules.push(rule);
            } else {
                const rule = {
                    field: filter.Name,
                    value: filter.Value,
                    value2: (filter.Operator === Comparer.Between || filter.Operator === Comparer.NotBetween) ? filter.Value2 : null,
                    operator: RequestFilter.GetTextForComparer(filter.Operator),
                    IsOptional: filter.IsOptional
                };
                rules.push(rule);
            }
        });
    }

    constructor(public cdRef: ChangeDetectorRef) {
        const map = new Map<string, string[]>();
        this.AllTypes.forEach((type) => {
            map.set(type, RequestFilter.GetComparerForType(type));
        });
        this.Operators = map;
    }

    getOperatorsForField(field, fieldObject) {
        let retVal;
        if (fieldObject && fieldObject.type) {
            retVal = this.Operators.get(fieldObject.type);
        }
        if (retVal) {
            return retVal;
        }
        return [];
    }

    getInputType(field, op) {
        if (this.config) {
            const configField = this.config.fields[field];
            if (configField) {
                if (op === 'in' || op === 'notin') {
                    return 'multistring';
                }
                return configField.type;
            }
        }
        return '';
    }

    SetFilter(filter) {
        if (filter) {
            if (filter.ConcatOperator) {
                switch (filter.ConcatOperator) {
                    case Concat.And:
                        this.query.condition = 'and';
                        break;
                    case Concat.Or:
                        this.query.condition = 'or';
                        break;
                }
            }
            const rules = [];
            if (filter.Filters && filter.Filters.length > 0) {
                AdvancedFilterComponent.BuildRule(filter.Filters, rules);
            }
            this.query.rules = rules;
            this.cdRef.detectChanges();
        }
    }

    GetActualFilter() {
        let filter = new RequestFilter();
        filter.ConcatOperator = this.query.condition === 'and' ? Concat.And : Concat.Or;
        if (this.query.rules && this.query.rules.length > 0) {
            filter.Filters = [];
            AdvancedFilterComponent.BuildFilter(this.query.rules, filter.Filters, this.AdditionalInfos);
        } else {
            filter = null;
        }
        return filter;
    }

    add(event: MatChipInputEvent, rule): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            if (!Array.isArray(rule.value)) {
                rule.value = [value.trim()];
            } else {
                rule.value.push(value.trim());
            }
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(entry, rule): void {
        if (Array.isArray(rule.value)) {
            const index = rule.value.indexOf(entry);
            if (index >= 0) {
                rule.value.splice(index, 1);
            }
        }
    }
}
