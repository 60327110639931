import { ChangeDetectorRef, Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { MailSettingsService } from '../../../../services/mailsettings.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { FormulaWorkflowDialogContent, WorkflowDialogContent } from '../../../workflow.dialog';
import { LayoutService } from "../../../../services/layout.service";

@Component({
    selector: 'wf-sendmail-settings',
    templateUrl: './sendmail.settings.html'
})
export class SendMailSettings extends WorkflowDialogContent {
    MailSettings = [];
    Data = new SendMailSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'sendmailWFModule';
        reg.Caption = '@@Email senden';
        reg.GroupID = 'wfActions';
        reg.Index = 40;
        reg.SettingsControl = SendMailSettings;
        reg.SettingsTypeHelper = new SendMailSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SendMailExtendedSettings.ModuleID;
        return reg;
    }

    constructor(private service: MailSettingsService) {
        super();
    }

    ngOnInit() {
        this.service.GetMailSettings().subscribe(result => {
            if (result) {
                this.MailSettings = result;
            }
        });
    }

    initialize(data: any) {
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(SendMailSettingsData, seri);
        }
    }

    getResult() {
        return this.Data;
    }
}

export abstract class AMailSettingsData extends AServiceWorkflowData {
    MailServer: string;
    To: string;
    Subject: string;
    Body: string;
    IsBodyHtml: boolean;
    fileName: any;
    previousFileName: any;
    files: string
}

export class SendMailSettingsData extends AMailSettingsData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.SendMail.SendMailSettingsData';
    }
}

export class SendMailSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SendMailSettingsData();
    }
}

@Component({
    selector: 'wf-sendmail-extended-settings',
    templateUrl: './sendmail.extended.settings.html'
})
export class SendMailExtendedSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'sendmailExtendedWFModule';
    MailSettings = [];
    Data = new SendMailExtendedSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SendMailExtendedSettings.ModuleID;
        reg.Caption = '@@Email senden';
        reg.GroupID = 'wfActions';
        reg.Index = 40;
        reg.SettingsControl = SendMailExtendedSettings;
        reg.SettingsTypeHelper = new SendMailExtendedSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: MailSettingsService, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.service.GetMailSettings().subscribe(result => {
            if (result) {
                this.MailSettings = result;
            }
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(SendMailExtendedSettingsData, seri);
            this.Data.previousFileName = this.Data.fileName;
        }
    }

    getResult() {
        return this.Data;
    }

    check(ev) {
        this.Data.Body = '';
    }

    addAttachment(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const files: File[] = Array.from(input.files); // Convert FileList to array
            const formData: FormData = new FormData();

            files.forEach(file => formData.append('files', file));
            let filesData = files.map(file => file.name)
            this.Data.fileName = filesData.join(', ');
            this.Data.files = JSON.stringify(filesData)

            if (files.length > 0) {
                this.service.sendMailWithAttachment(formData);
            } else {
                this.service.sendMailWithAttachment(null);
            }
        }
    }


    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }
}

export class SendMailExtendedSettingsData extends AMailSettingsData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.SendMail.SendMailExtendedSettingsData';
    }
}

export class SendMailExtendedSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SendMailExtendedSettingsData();
    }
}
