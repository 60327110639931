import { PropertyMapper } from '../../../helpers/property.mapper';
import { ClientAuthentication, CodeChallengeMethod } from './auth.type';

export class OAuth2Settings{
    public GrantType: string; //Use constans from GrantType
    public CallbackURL = '';
    public AuthURL = '';
    public AccessTokenURL = '';
    public ClientID = '';
    public ClientSecret = '';
    public Scope = '';
    public State = '';
    public ClientAuthentication: ClientAuthentication;
    public CodeChallengeMethod: CodeChallengeMethod;
    public CodeVerifier = '';
    public UserName = '';
    public Password = '';
    public AccessToken = '';
    public RefreshToken = '';
    public TokenType = '';

    constructor(value: OAuth2Settings = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);
        }
    }
}

export class BasicAuthSettings {
    public UserName = '';
    public Password = '';

    constructor(value: BasicAuthSettings = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);
        }
    }
}

export class BearerTokenAuthSettings {
    public Token = '';

    constructor(value: BearerTokenAuthSettings = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);
        }
    }
}