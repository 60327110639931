import { FilterHelper } from '../../helpers/filter.helper';
import { InjectorHelper } from '../../helpers/injector.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { TaskRegistry } from '../../helpers/task.registry';
import { FormulaNodeCalculator } from '../../models/calculation/formula.node.calculator';
import { DataDescription } from '../../models/datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../../models/datadescription/multi/multiresult.model';
import { QueryDef } from '../../models/datadescription/multi/querydef.model';
import { AxisType } from '../../models/enums/query.enum';
import { PageStatus } from '../../models/page.status';
import { ITaskExecuter } from '../../models/tasks/atask.model';
import { StandardRequestBase } from '../../services/request-base';
import { DataDescriptionHelper } from './ddhelper.query';
import { AExecutionManipulation } from './Manipulations/a.execution.manipulation';
import { AllElementRenamingManipulation } from './Manipulations/all.element.renaming.manipulation';
import { DynamicTimeMeasureReferencingManipulation } from './Manipulations/dynamic.time.measure.referencing.manipulation';
import { MasterChildManipulation } from './Manipulations/master.child.manipulation';
import { ReloadExecutionManipulation } from './Manipulations/ReloadExecutionManipulation';
import { ViewManipulation } from './Manipulations/view.manipulation';
import { Merger } from './merger.query';
import { QueryHelper } from './queryhelper.query';

export class QueryExecuter {

    Manipulations: AExecutionManipulation[] = [
        new ViewManipulation(),
        new DynamicTimeMeasureReferencingManipulation(),
        new MasterChildManipulation(),
        new AllElementRenamingManipulation(),
        new ReloadExecutionManipulation()
    ];

    constructor(private dataDescription: DataDescription, private queryID) {
    }

    /// <summary>
    /// Executes the query and returns the resulting flat matrix.
    /// </summary>
    /// <param name='context'>The execution context for the query.</param>
    /// <returns>The flat matrix that was created out of the query.</returns>
    async execute(context, executeTasks: boolean): Promise<MultiResult> {
        // let matrix: FlatMatrix = null;

        // if (!MultiCacheService.GetIsInitialized(this.DataDescription.DataModell)) {
        //  await MultiCacheService.RefreshMultiCache(this.DataDescription.DataModell);
        // }
        context.Variables = {};
        const reportObject = context.BaseReportObject;

        if (reportObject) {
            if (reportObject.CheckForEmptyHeterogenAreas) {
                for (let i = 0; i < this.dataDescription.LevelNodes.length; i++) {
                    const ln = this.dataDescription.LevelNodes[i];
                    let hasNoLevelArea = false;
                    let hasLevelArea = false;
                    if (ln.Areas.some(a => {
                        const lc = a.LevelCount;
                        if (lc === 0) {
                            hasNoLevelArea = true;
                        } else {
                            hasLevelArea = true;
                        }
                        return hasNoLevelArea && hasLevelArea;
                    })) {
                        const warn = '@@Es koennen keine heterogenen Bereiche mit und ohne Levels auf einer Achse gemischt werden.';
                        NotificationHelper.Warn(warn, '@@Achtung');
                        break;
                    }
                }
            }
            if (reportObject.LayoutElement) {
                const variableList = FilterHelper.GetVariables(reportObject.LayoutElement);
                variableList.forEach(x => {
                    const vat = FormulaNodeCalculator.GetVariableValue(x, false).Value;
                    if (typeof vat !== 'undefined' && vat !== null) {
                        context.Variables[x.Name] = vat;
                    }
                });
            }
        }

        if (await QueryHelper.CheckFlatLevelCount(this.dataDescription)) {
            NotificationHelper.Warn('Mindestens eines der verbundenen Level ist nicht in allen verwendeten Cubes vorhanden. Dies kann zu doppelten oder fehlenden Zeilen/Spalten fuehren.', 'Achtung');
        }

        const ddclone = this.dataDescription.clone();
        ddclone.PowerGridDepth = this.dataDescription.PowerGridDepth;

        // #warning EffectiveUserName muss noch gesetzt werden
        // ddclone.EffectiveUserName = ???
        // INetSession session = BIS.Base.Session.Context.State.GetValue<INetSession>();
        // if (ddclone.EffectiveUserName == null && session.EffictiveUsername != null) {
        //  ddclone.EffectiveUserName = session.EffictiveUsername;
        // }

        context.DataDescriptionOriginal = this.dataDescription;
        context.DataDescriptionClone = ddclone;

        for (let i = 0; i < this.Manipulations.length; i++) {
            this.Manipulations[i].Context = context;
            await this.Manipulations[i].BeforeSplit();
        }

        //const start1 = new Date().getTime();
        const definitions: QueryDef[] = [];
        if (ddclone.XLevelNodes.HasAreas) {
            for (let i = 0; i < ddclone.XLevelNodes.Areas.length; i++) {
                if (ddclone.YLevelNodes.HasAreas) {
                    for (let j = 0; j < ddclone.YLevelNodes.Areas.length; j++) {
                        const defs = await QueryHelper.GetQueryDef(ddclone, ddclone.XLevelNodes.Areas[i], ddclone.YLevelNodes.Areas[j]);
                        definitions.push(...defs);
                    }
                } else {
                    const defs = await QueryHelper.GetQueryDef(ddclone, ddclone.XLevelNodes.Areas[i], null);
                    definitions.push(...defs);
                }
            }
        } else if (ddclone.YLevelNodes.HasAreas) {
            for (let k = 0; k < ddclone.YLevelNodes.Areas.length; k++) {
                const defs = await QueryHelper.GetQueryDef(ddclone, null, ddclone.YLevelNodes.Areas[k]);
                definitions.push(...defs);
            }
        }
        //const end1 = new Date().getTime();
        //console.log('b: ' + (end1 - start1));
        // Prüfen, ob es auf X oder Y eine Description mit kompletter Achse in einem heterogenen Bereich gibt
        // und diese nach vorne stellen um Merger - Reihenfolge positiv zu beeinflussen
        let yfullDescription;
        let xfullDescription;

        definitions.forEach(d => {
            d.Variables = Object.assign({}, context.Variables);
            const xAxisDef = d.Axis.find(x => x.Type === AxisType.X_Axis);
            const yAxisDef = d.Axis.find(x => x.Type === AxisType.Y_Axis);
            if (!yfullDescription && yAxisDef) {
                const lc = ddclone.YLevelNodes.Areas.find(x => x.UniqueID === d.HeterogenPosition.get(AxisType.Y_Axis)).LevelCount;
                if (yAxisDef.Levels.length === lc) {
                    yfullDescription = d;
                }
            }
            if (!xfullDescription && xAxisDef) {
                const lc = ddclone.XLevelNodes.Areas.find(x => x.UniqueID === d.HeterogenPosition.get(AxisType.X_Axis)).LevelCount;
                if (xAxisDef.Levels.length === lc) {
                    xfullDescription = d;
                }
            }
        });

        if (xfullDescription) {
            definitions.splice(definitions.indexOf(xfullDescription), 1);
            definitions.unshift(xfullDescription);
        }

        if (yfullDescription) {
            definitions.splice(definitions.indexOf(yfullDescription), 1);
            definitions.unshift(yfullDescription);
        }

        context.QueryDefinitions = definitions;

        for (let i = 0; i < this.Manipulations.length; i++) {
            await this.Manipulations[i].BeforeExecute();
        }

        const results: Promise<MultiResult>[] = [];
        const service = InjectorHelper.InjectorInstance.get<StandardRequestBase>(StandardRequestBase);
        for (let i = 0; i < definitions.length; i++) {

            results.push(this.ExecuteQuery(definitions[i], service));
        }

        //let start = new Date().getTime();

        await Promise.all(results)
            .then(res => res.forEach(mr => {
                const definition = definitions.find(d => d.ID === mr.ID);
                if (definition) {
                    let count = 0;
                    if (mr.XAxis.Nodes.length > 0) {
                        count++;
                    }
                    if (mr.YAxis.Nodes.length > 0) {
                        count++;
                    }
                    if (mr.Measures.Nodes.length > 0) {
                        count++;
                    }
                    if (count === 0) {
                        return;
                    }
                    if (count === 1) {
                        if (mr.Cells.length === 0) {
                            return;
                        }
                        if (mr.Cells.length === 1) {
                            const first = mr.Cells[0];
                            if (first.length === 0) {
                                return;
                            }
                            if (first.length === 1) {
                                const sec = first[0];
                                if (sec.every(x => x == null)) {
                                    return;
                                }
                            }
                        }
                    }


                    definition.MultiResult = mr;
                    definition.MultiResult.XAxis.Nodes.forEach(x => {
                        this.SetAxisNodeParents(x);
                    });
                    definition.MultiResult.YAxis.Nodes.forEach(y => {
                        this.SetAxisNodeParents(y);
                    });
                    definition.MultiResult.Measures.Nodes.forEach(m => {
                        this.SetAxisNodeParents(m);
                    });
                }
            }))
            .catch(reason => console.log(reason));
        //let end = new Date().getTime();
        //console.log('Network: ' + (end - start));
        //start = new Date().getTime();
        const mergeResult = Merger.Merge(ddclone, definitions);
        //end = new Date().getTime();
        //console.log('Merger: ' + (end - start));

        for (let i = 0; i < this.Manipulations.length; i++) {
            await this.Manipulations[i].AfterExecute(mergeResult);
            this.Manipulations[i].Clear();
        }

        if (executeTasks) {
            //start = new Date().getTime();
            await this.ExecuteTasks(context, mergeResult);
            //end = new Date().getTime();
            //console.log('Tasks: ' + (end - start));
        }

        return mergeResult;
        // if (!mr || QueryHelper.IsEmptyMultiResult(mr)) {
        //    matrix = new FlatMatrix();
        //    matrix.IsEmpty = true;
        //    matrix.IsEmptyQuery = true;
        // } else {
        //    // #warning Manipulations -> BeforeFlat();

        //    let axis = [...mr.Measures.Nodes];
        //    mr.Measures.Nodes = [];
        //    ddclone.MeasureInfos.forEach(mi => {
        //        const axisnode = axis.find(x => x.UniqueID == mi.UniqueID);
        //        if (axisnode) {
        //            mr.Measures.Nodes.push(axisnode);
        //        }
        //    });

        //    matrix = await FlatConverter.Convert(mr, ddclone);
        //    matrix.IsEmptyQuery = matrix.Cells.length == 0;

        //    //  #warning MaxColumnCount muss noch geladen werden (Config aus dem Backstage)
        //    if (this.CalcColumnCount(mr, ddclone) > 2000) {
        //        throw new Error('Die Abfrage erzeugte zu viele Elemente auf der X-Achse.
        //          Bitte schränken Sie die Abfrage entsprechend ein.');
        //    }
        // }
    }

    /// <summary>
    /// Executes the default, data and format tasks on the specified flat matrix.
    /// </summary>
    private async ExecuteTasks(context: any, matrix: MultiResult) {

        // First all default tasks need to run
        // This does not include format tasks usually
        // TODO: DefaultTasks
        if (context.BaseReportObject && context.BaseReportObject.LayoutElement) {
            // Task aktive/inaktiv Status holen 
            // Kann durch WF verändert worden sein
            const pageState = PageStatus.ActualState.getValue();
            
            for (let i = 0; i < context.DataDescriptionClone.Tasks.length; i++) {
                await this.ExecuteTask(context.DataDescriptionClone.Tasks[i], context, matrix, context.BaseReportObject.LayoutElement.ID);
            }

            if (context.BaseReportObject.LayoutElement.DataTasks) {
                for (let i = 0; i < context.BaseReportObject.LayoutElement.DataTasks.length; i++) {
                    const task = context.BaseReportObject.LayoutElement.DataTasks[i];
                    if (task && task.QueryID === this.queryID) {
                        await this.ExecuteTask(task, context, matrix, context.BaseReportObject.LayoutElement.ID);
                    }
                }
            }
        }
    }

    private async ExecuteTask(settings, context: any, matrix: MultiResult, objectID) {
        if (settings && settings.TaskType && PageStatus.GetTaskActiveStatus(settings, objectID)) {
            const desc = TaskRegistry.get(settings.TaskType);
            if (desc && desc.Executer) {
                const executer: ITaskExecuter = new desc.Executer();
                await executer.Init(settings, matrix, context.DataDescriptionClone, context);
                // TODO: IsValid
                await executer.Execute();
            }
        }
    }

    CalcColumnCount(matrix: MultiResult, ddclone: DataDescription): number {
        const columncountmodel = { columncount: 0 };
        this.CalcColumnCountInternal(matrix.XAxis.Nodes, columncountmodel);
        const visibleMeasures = DataDescriptionHelper.GetAllMeasureInfos(ddclone, ddclone.ShowMeasureOnAxis).filter(x => x.IsVisible);
        if (visibleMeasures && visibleMeasures.length > 0) {
            columncountmodel.columncount = columncountmodel.columncount * visibleMeasures.length;
        }
        return columncountmodel.columncount;
    }
    CalcColumnCountInternal(children: AxisNode[], countModel: { columncount: number; }) {
        for (let i = 0; i < children.length; i++) {
            const node = children[i];
            if (node.Children.length === 0) {
                countModel.columncount++;
            } else {
                this.CalcColumnCountInternal(node.Children, countModel);
            }
        }
    }
    SetAxisNodeParents(parent: AxisNode) {
        parent.Children.forEach(child => {
            child.Parent = parent;
            this.SetAxisNodeParents(child);
        });
    }

    private async ExecuteQuery(def: QueryDef, service: StandardRequestBase): Promise<MultiResult> {
        return service.executePost(def, 'api/queryengine/multi', 'ExecuteQuery', 'dataModelId=' + def.DataModell).toPromise();
    }
}
