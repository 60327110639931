import { WorkflowDialogContent } from '../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo, ObsoleteInfo } from '../../../services/workflow.service';
import { GetListItemCalcSettings } from './getlistitemcalc.settings';

@Component({
    selector: 'wf-getlistitem-settings',
    templateUrl: './getlistitem.settings.html',
    styleUrls: ['./getlistitem.settings.css']
})
export class GetListItemSettings extends WorkflowDialogContent {
    IndexState = 0;
    IndexValue = 1;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getlistitemWFModule';
        reg.Caption = '@@Listeneintrag auslesen';
        reg.SettingsControl = GetListItemSettings;
        reg.SettingsTypeHelper = new GetListItemSettingsDataHelper();
        reg.Executer = GetListItemModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = GetListItemCalcSettings.ModuleID;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (typeof data.GetLastItem === 'boolean' && data.GetLastItem) {
                this.IndexState = 1;
            } else {
                let index = 0;
                if (typeof data.Index === 'number') {
                    index = data.Index;
                    if (index < 0) {
                        index = 0;
                    }
                }
                if (index > 0) {
                    this.IndexState = 2;
                }
                this.IndexValue = index + 1;
            }
        }
    }

    getResult() {
        const retVal = new GetListItemSettingsData();
        if (this.IndexState === 0) {
            retVal.Index = 0;
        } else if (this.IndexState === 1) {
            retVal.GetLastItem = true;
        } else {
            if (this.IndexValue < 1) {
                retVal.Index = 0;
            } else {
                retVal.Index = this.IndexValue - 1;
            }
        }
        return retVal;
    }

    onChange(ev) {
        if (ev && ev.value) {
            this.IndexState = ev.value;
        }
    }
}

export class GetListItemSettingsData extends AServiceWorkflowData {
    GetLastItem = false;
    Index = 0;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ListOperations.GetListItemSettingsData';
    }
}

export class GetListItemSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new GetListItemSettingsData();
    }
}

export class GetListItemModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (Array.isArray(status.ActualParameter)) {
                let index = 0;
                if (typeof status.ActualSettings.GetLastItem === 'boolean' && status.ActualSettings.GetLastItem) {
                    index = status.ActualParameter.length - 1;
                } else if (typeof status.ActualSettings.Index === 'number' && status.ActualSettings.Index > 0) {
                    if (status.ActualSettings.Index < status.ActualParameter.length) {
                        index = status.ActualSettings.Index;
                    } else {
                        index = status.ActualParameter.length;
                    }
                }
                status.ActualParameter = status.ActualParameter[index];
                return 0;
            } else {
                status.Logger.logError('GetListItem modul: No enumerable parameter set.');
            }
        } else {
            status.Logger.logError('GetListItem modul: No settings found.');
        }
        return super.execute(status);
    }
}
