import { DatadescriptionService } from '../services/datadescription.service';
import { MacNavigationService } from '../services/navigation.service';
import { RelSourceService } from '../services/relSource.service';
import { MultiReportingService, ReportingService } from '../services/reporting.service';
import { DynamicTimeSettingsService } from '../services/dynamictimesettings.service';
import { SeriesColumnsContentDirective } from '../components/reportobjectmenus/chart/series.menu.control';
import { PlanningService } from '../services/planning.service';

export const MAC_SERVICES = [
    RelSourceService,
    ReportingService,
    DynamicTimeSettingsService,
    MacNavigationService,
    DatadescriptionService,
    MultiReportingService,
    PlanningService
];

export const MAC_DIRECTIVES = [
    SeriesColumnsContentDirective
];
