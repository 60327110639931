import { Component } from '@angular/core';
import { Type } from 'class-transformer';
import { CacheService } from '../../../../cache/cache.service';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { Variable } from '../../../../models/variable.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-setGlobalVariable-settings',
    templateUrl: './setGlobalVariable.settings.html',
    styleUrls: ['./setGlobalVariable.settings.css']
})
export class SetGlobalVariableSettings extends WorkflowDialogContent {
    static MultiModuleID = 'setMultiGlobalVariableModule';

    Variables = [];
    GlobalVariables = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setGlobalVariableModule';
        reg.Caption = '@@Globale Variable setzen';
        reg.SettingsControl = SetGlobalVariableSettings;
        reg.SettingsTypeHelper = new SetGlobalVariableSettingsDataHelper();
        reg.Executer = SetGlobalVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetGlobalVariableSettings.MultiModuleID;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.Variables) {
                const list = [];
                data.Variables.forEach(function (v) {
                    list.push({
                        Data: {
                            Name: v.Name,
                            Type: VariableHelper.GetTypeListTypeFromJSType(typeof v.Value),
                            Value: v.Value
                        },
                        UseParam: v.UseActualParam
                    });
                });
                this.Variables = list;
            }
        }
        CacheService.ReadGlobalVariables().then((vars) => {
            if (vars) {
                this.GlobalVariables = vars;
            }
        });
    }

    getResult(): any {
        const retVal = new SetGlobalVariableSettingsData();
        this.Variables.forEach(function (v) {
            if (v.Data && v.Data.Name) {
                const ve = new VariableEntry();
                ve.Name = v.Data.Name;
                if (v.UseParam) {
                    ve.UseActualParam = true;
                    retVal.Variables.push(ve);
                } else if (v.Data.Value) {
                    ve.Value = v.Data.Value;
                    retVal.Variables.push(ve);
                }
            }
        });
        return retVal;
    }

    add() {
        this.Variables.push({
            Data: new Variable(),
            UseParam: false
        });
    }

    delete(i) {
        this.Variables.splice(i, 1);
    }

    compareFn(item1, item2) {
        return item1 && item2 && item1.Name === item2.Name;
    }
}

// @dynamic
export class SetGlobalVariableSettingsData {
    @Type(() => VariableEntry)
    Variables: VariableEntry[] = [];
}

export class VariableEntry {
    Name: string;
    Value;
    UseActualParam = false;
}

export class SetGlobalVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetGlobalVariableSettingsData();
    }
}

export class SetGlobalVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variables && status.ActualSettings.Variables.length > 0) {
                const global: any = await CacheService.RefreshGlobalVariableValues();
                if (global) {
                    let success = true;
                    status.ActualSettings.Variables.some(v => {
                        const val = v.UseActualParam ? status.ActualParameter : v.Value;
                        let nameFound = false;
                        let correctType = false;
                        global.some((g) => {
                            if (v.Name === g.Name) {
                                nameFound = true;
                                if (VariableHelper.CheckValueForType(g.Type, val)) {
                                    correctType = true;
                                    g.Value = val;
                                    return true;
                                }
                            }
                            return false;
                        });
                        if (nameFound) {
                            if (!correctType) {
                                success = false;
                                status.Logger.logError('SetGlobalVariable module: Types don\'t match for variable \'' + v.Name + '\'.');
                                return true;
                            }
                        } else {
                            success = false;
                            status.Logger.logError('SetGlobalVariable module: Variable \'' + v.Name + '\' not found.');
                            return true;
                        }
                        return false;
                    });
                    CacheService.GlobalVariablesValues.next(global);
                    if (success) {
                        return 0;
                    }
                } else {
                    status.Logger.logError('SetGlobalVariable module: No Global Variables found.');
                }
            }
        } else {
            status.Logger.logError('SetGlobalVariable module: No settings found.');
        }
        return super.execute(status);
    }
}
