import { Component } from "@angular/core";
import { EnumHelper } from "../../../../../helpers/enum.helper";
import { DBPanelInfo, MySQLDBInfo } from "../../../../../models/datamodel/adbinfo.model";
import { MySqlSslMode } from "../../../../../models/enums/mysqlsslmode.enum";
import { BaseDataSourcePanel } from "../basedatasource.panel";

@Component({
    selector: 'mysqldb-panel',
    templateUrl: './mysqldb.panel.html',
    styleUrls: ['./mysqldb.panel.css']
})
export class MySQLDBPanel extends BaseDataSourcePanel {

    SslModes = [];

    constructor() {
        super();
        this.DBInfoType = MySQLDBInfo;
        this.SslModes = EnumHelper.GetDropdownValues(MySqlSslMode);
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: MySQLDBPanel,
            DBInfoType: MySQLDBInfo
        };
    }
}