import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import moment from 'moment';
import { DataTableTranslateHelper } from '../../helpers/translate.helper';
import { CellContentType } from '../../models/basic/cell.content.model';

@Component({
    selector: 'evi-cell-content',
    template: '<span *ngIf="CellContentType==0">{{DataValue}}</span><mat-icon *ngIf="CellContentType==1" svgIcon="{{DataValue}}"></mat-icon>',
    changeDetection: ChangeDetectionStrategy.Default
})
export class CellContent {
    DataValue = '';
    CellContentType: CellContentType = CellContentType.Text;

    //#region Row
    RowValue;

    @Input()
    get Row() {
        return this.RowValue;
    }
    set Row(val) {
        this.RowValue = val;
        this.SetData();
    }
    //#endregion

    //#region Column
    ColumnValue;

    @Input()
    get Column() {
        return this.ColumnValue;
    }
    set Column(val) {
        this.ColumnValue = val;
        this.SetData();
    }
    //#endregion
    //#region DataTable
    DataTableValue;

    @Input()
    get DataTable() {
        return this.DataTableValue;
    }
    set DataTable(val) {
        this.DataTableValue = val;
        if (this.DataTable && this.DataTable.DataSourceValuesChanged) {
            this.DataTable.DataSourceValuesChanged.subscribe((res) => {
                this.SetData();
            })
        }
    }
    //#endregion
    static GetCellValue(data, column): string {
        let result = '';
        if (column) {
            if (column.Prefix) {
                result += column.Prefix;
            }
            if (data !== null && typeof (data) !== 'undefined') {
                if (column.Type === 'System.DateTime' && column.DateFormat) {
                    result += moment(data).format(column.DateFormat);
                } else if (column.Type === 'System.Double' && column.NumericPrecision != null) {
                    result += parseFloat(data).toFixed(column.NumericPrecision);
                } else if (column.IsEnum && column.EnumSource) {
                    const kv = column.EnumSource.find(x => x.Value === data);
                    if (kv) {
                        if (DataTableTranslateHelper.TranslatorInstance) {
                            result += DataTableTranslateHelper.TranslatorInstance.instant(kv.Caption);
                        } else {
                            result += kv.Caption;
                        }
                    }
                } else {
                    result += data;
                }
            }
            if (column.Suffix) {
                result += column.Suffix;
            }
        }
        return result;
    }

    SetData() {
        if (this.ColumnValue && this.RowValue) {
            const data = this.RowValue.data[this.ColumnValue.Name];
            if (data != null && typeof data === 'object' && typeof data.CellContentType === 'number') {
                switch (data.CellContentType) {
                    case CellContentType.Icon:
                        this.CellContentType = CellContentType.Icon;
                        this.DataValue = data.Value;
                        break;
                    default:
                        this.CellContentType = CellContentType.Text;
                        this.DataValue = CellContent.GetCellValue(data.Value, this.ColumnValue);
                        break;
                }
            } else {
                this.CellContentType = CellContentType.Text;
                this.DataValue = CellContent.GetCellValue(data, this.ColumnValue);
            }
        } else {
            this.CellContentType = CellContentType.Text;
            this.DataValue = '';
        }
    }
}
