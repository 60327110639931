import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import moment from 'moment';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { NotificationHelper } from '../../helpers/notification.helper';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { LayoutService } from '../../services/layout.service';
import { VersioningService } from '../../services/versioning.service';

@Component({
    selector: 'versioning-overview',
    templateUrl: './versioning.overview.html',
    styleUrls: ['./versioning.overview.css']
})
export class VersioningOverview implements OnInit {
    SearchValue;
    Range;
    RangeSet;
    TypeList = [];
    selectedNode;
    Result = [];
    SelectedVersion;
    SelectedCompare;
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    toggleWindow = true;

    static Path = '/settings/about/overview/versioning-overview';

    static GetSettingsEntry() {
        return {
            Caption: '@@Versioning Overview',
            ID: 'versioning-overview',
            Icon: 'description',
            Index: 30,
            Parent: 'overview',
            Security: {
                Name: 'evidanza.App.Shared.Security.AboutRight',
                Value: 7
            },
            Content: VersioningOverview
        };
    }

    constructor(private cdRef: ChangeDetectorRef, private service: VersioningService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        LayoutService.Loading.next(true);
        this.SearchValue = null;
        this.TypeList = [];
        this.selectedNode = null;
        this.Result = [];
        this.SelectedVersion = null;
        this.SelectedCompare = null;
        this.toggleWindow = true;
        this.service.GetVersionInfos().subscribe(viList => {
            if (viList) {
                this.Result = viList;
                viList.forEach(vi => {
                    vi.Items.forEach(item => {
                        item.Versions.forEach(v => {
                            v.ChangeDateMoment = moment(v.ChangeDate);
                            v.Caption = v.ChangedBy + ' - ' + v.ChangeDateMoment.format('lll');
                        });
                    });
                });
            }
            this.updateFiltered();
            LayoutService.Loading.next(false);
        });
    }

    updateFiltered() {
        let toLower;
        if (this.SearchValue) {
            toLower = this.SearchValue.toLowerCase();
        }
        let from;
        let to;
        if (Array.isArray(this.Range) && this.Range.length > 0) {
            const first = this.Range[0];
            if (first) {
                from = moment(first);
            }
            if (this.Range.length > 1) {
                const second = this.Range[1];
                if (second) {
                    to = moment(second);
                }
            }
        }
        const list = [];
        if (toLower || from || to) {
            this.Result.forEach(x => {
                if (toLower && x.Caption.toLowerCase().indexOf(toLower) > -1) {
                    list.push(x);
                } else {
                    const innerList = [];
                    x.Items.forEach(item => {
                        if (toLower && item.Caption.toLowerCase().indexOf(toLower) > -1) {
                            innerList.push(item);
                        } else if (item.Versions.some(v => {
                            if (toLower && v.Caption.toLowerCase().indexOf(toLower) > -1) {
                                return true;
                            } else if (from) {
                                if (from.isSameOrBefore(v.ChangeDateMoment)) {
                                    if (to) {
                                        return to.isSameOrAfter(v.ChangeDateMoment);
                                    } else {
                                        return true;
                                    }
                                }
                            } else if (to) {
                                return to.isSameOrAfter(v.ChangeDateMoment);
                            }
                            return false;
                        })) {
                            innerList.push(item);
                        }
                    });
                    if (innerList.length > 0) {
                        list.push({
                            Caption: x.Caption,
                            Items: innerList
                        });
                    }
                }
            });
        } else {
            list.push(...this.Result);
        }
        this.TypeList = list;
        this.cdRef.detectChanges();
    }

    deleteVersion() {
        const sel = this.SelectedVersion;
        if (sel && sel.Version && !sel.Version.IsActive && !sel.Version.IsCapsule) {
            MessageBoxHelper.ShowDialog(new TranslateFormatText('@@DeleteInactiveVersion'), new TranslateFormatText('@@DeleteVersion'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(result => {
                    if (result == MessageBoxResult.Yes) {
                        this.service.DeleteInactiveVersion(sel.ObjectType, sel.Item.SID, sel.Version.Version).subscribe(x => {
                            if (typeof x === 'boolean' && x) {
                                let index;
                                if (sel.Item.Versions.some((y, i) => {
                                    if (y.Version == sel.Version.Version) {
                                        index = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    sel.Item.Versions.splice(index, 1);
                                    this.SelectedVersion = null;
                                    this.SelectedCompare = null;
                                    const tft = new TranslateFormatText('@@SuccessfullyDeletedVersion{0}');
                                    tft.FormatParams.push(sel.Version.Caption);
                                    NotificationHelper.Success(tft, '@@DeleteVersion');
                                }
                                this.refresh();
                            } else {
                                const tft = new TranslateFormatText('@@FailedToDeleteVersion{0}');
                                tft.FormatParams.push(sel.Version.Caption);
                                NotificationHelper.Error(tft, '@@DeleteVersion');
                            }
                        });
                    }
                });
        }
    }

    onItemClick(vers, item) {
        let selected;
        let selCompare;
        this.Result.some(x => {
            const realItem = x.Items.find(y => y.SID == item.SID);
            if (realItem) {
                const version = realItem.Versions.find(v => v.Version == vers.Version);
                if (version) {
                    this.selectedNode = vers;
                    selected = {
                        ObjectType: x.ObjectType,
                        Caption: realItem.Caption + ' (' + version.Caption + ')',
                        Item: realItem,
                        Version: version
                    };
                    if (!version.IsActive) {
                        selCompare = realItem.Versions.find(v => v.IsActive);
                    }
                    if (!version.Content) {
                        version.Content = {
                            code: '',
                            language: 'json'
                        };
                        LayoutService.Loading.next(true);
                        this.service.GetVersionContent(x.ObjectType, realItem.SID, version.Version).subscribe(content => {
                            version.Content = {
                                code: content,
                                language: 'json'
                            };
                            this.cdRef.detectChanges();
                            LayoutService.Loading.next(false);
                        });
                    }
                }
                return true;
            }
            return false;
        });
        this.SelectedVersion = selected;
        this.SelectedCompare = selCompare;
        this.onCompareChanged();
        this.cdRef.detectChanges();
    }

    onCompareChanged() {
        const sel = this.SelectedVersion;
        const comp = this.SelectedCompare;
        if (sel && comp) {
            if (!comp.Content) {
                comp.Content = {
                    code: '',
                    language: 'json'
                };
                LayoutService.Loading.next(true);
                this.service.GetVersionContent(sel.ObjectType, sel.Item.SID, comp.Version).subscribe(content => {
                    comp.Content = {
                        code: content,
                        language: 'json'
                    };
                    this.cdRef.detectChanges();
                    LayoutService.Loading.next(false);
                });
            }
        }
    }

    changeActive() {
        const sel = this.SelectedVersion;
        if (sel && sel.Version && !sel.Version.IsActive) {
            const tft = new TranslateFormatText('@@ChangeActiveToUser{0}AndDate{1}');
            tft.FormatParams.push(sel.Version.ChangedBy);
            tft.FormatParams.push(sel.Version.ChangeDateMoment.format('lll'));
            MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@ChangeActive'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(result => {
                    if (result == MessageBoxResult.Yes) {
                        this.service.ChangeActiveVersion(sel.ObjectType, sel.Item.SID, sel.Version.Version).subscribe(x => {
                            if (x) {
                                let index;
                                if (x.OldActive && sel.Item.Versions.some((y, i) => {
                                    if (y.Version == x.OldActive.Version) {
                                        index = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    x.OldActive.ChangeDateMoment = moment(x.OldActive.ChangeDate);
                                    x.OldActive.Caption = x.OldActive.ChangedBy + ' - ' + x.OldActive.ChangeDateMoment.format('lll');
                                    x.OldActive.Content = {
                                        code: x.OldActive.Content,
                                        language: 'json'
                                    };
                                    sel.Item.Versions.splice(index, 1, x.OldActive);
                                    if (this.SelectedCompare && this.SelectedCompare.Version == x.OldActive.Version) {
                                        this.SelectedCompare = x.OldActive;
                                    }
                                }                                
                                if (x.NewActive && sel.Item.Versions.some((y, i) => {
                                    if (y.Version == x.NewActive.Version) {
                                        index = i;
                                        return true;
                                    }
                                    return false;
                                })) {
                                    x.NewActive.ChangeDateMoment = moment(x.NewActive.ChangeDate);
                                    x.NewActive.Caption = x.NewActive.ChangedBy + ' - ' + x.NewActive.ChangeDateMoment.format('lll');
                                    x.NewActive.Content = {
                                        code: x.NewActive.Content,
                                        language: 'json'
                                    };
                                    sel.Item.Versions.splice(index, 1, x.NewActive);
                                    sel.Caption = sel.Item.Caption + ' (' + x.NewActive.Caption + ')';
                                    sel.Version = x.NewActive;
                                    this.selectedNode = x.NewActive;
                                }
                                this.cdRef.detectChanges();
                                NotificationHelper.Success('@@SuccessfullyChangedActiveVersion', '@@ChangeActive');
                            } else {
                                NotificationHelper.Error('@@FailedToChangedActiveVersion', '@@ChangeActive');
                            }
                        });
                    }
                });
        }
    }

    dateChanged(ev) {
        let rangeSet = false;
        if (ev && Array.isArray(ev.value) && ev.value.length > 0) {
            if (ev.value[0]) {
                rangeSet = true;
            }
        }
        this.RangeSet = rangeSet;
        this.updateFiltered();
    }

    clearRange() {
        this.Range = null;
        this.RangeSet = false;
        this.updateFiltered();
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
