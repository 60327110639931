import { Component, InjectionToken, Input } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { deserialize, serialize } from 'class-transformer';
import { DebugScriptBreakpointSettingsControl } from '../../../debugconsole/workflow/debug.script.breakpoint.control';
import { InjectorHelper } from '../../../helpers/injector.helper';
import {
    AServiceWorkflowData, IAdditionalBreakPointHandler, StepIntoHandler, WorkflowModuleExecuter, WorkflowStatus
} from '../../../models/workflow/workflow.model';
import { ScriptLibraryService } from '../../../services/scriptlibrary.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { ScriptExecutioner } from '../../../script/modules/executer.script';
import { ScriptParser } from '../../../script/modules/parser.script';
import { DataCheck, WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-execute-script-settings',
    templateUrl: './execute.script.settings.html',
    styles: ['.editor { width: 100%; height: 100%!important }']
})
export class ExecuteScriptSettings extends WorkflowDialogContent {
    Data = new ExecuteScriptSettingsData();
    editorOptions = {
        language: 'typescript'
    };
    editor;
    Service: ScriptLibraryService;
    modelUri: string = "file:///schema";
    @Input() schema: any;

    IsClientWorkflow = false;
    ConnectorId;

    static ModuleID = 'executeScriptWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ExecuteScriptSettings.ModuleID;
        reg.Caption = '@@Skript ausfuehren';
        reg.GroupID = 'wfActions';
        reg.Index = 45;
        reg.SettingsControl = ExecuteScriptSettings;
        reg.SettingsTypeHelper = new ExecuteScriptSettingsDataHelper();
        reg.Executer = ScriptModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.IsClientWorkflow = !this.WFEditOptions.IsService;
            if (this.WFEditOptions.ExternalOptions && this.WFEditOptions.ExternalOptions.FixedDataModels && this.WFEditOptions.ExternalOptions.FixedDataModels.length > 0) {
                this.ConnectorId = this.WFEditOptions.ExternalOptions.FixedDataModels[0].SID;
            }
            this.Data = deserialize(ExecuteScriptSettingsData, json);
            this.Service = InjectorHelper.InjectorInstance.get<ScriptLibraryService>(ScriptLibraryService);

        }
    }
    checkData() {
        if (this.editor) {
            this.editor.getAction('editor.action.formatDocument').run().then(() => {
                
            });
            return new DataCheck();
        } else {
            return new DataCheck();
        }
    }
    getResult(): any {
        this.Data.ID = UUID.UUID();
        return this.Data;
    }

    EditorInit(editor) {
        this.editor = editor;
    }
}

export class ExecuteScriptSettingsData extends AServiceWorkflowData {
    Script: string;
    UseJObject: boolean;
    ID: string;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Script.ExecuteScriptSettingsData';
    }
}

export class ExecuteScriptSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.ID = 0;
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        const retVal = new ExecuteScriptSettingsData();
        retVal.Script = 'import { WorkflowStatus, Global } from "System";\r\nexport class Main {\r\n    constructor() {\r\n    }\r\n    run(): void {\r\n    }\r\n    stop(): void {\r\n    }\r\n}';
        return retVal;
    }
    getStepIntoHandler(settings, workflowID: string, moduleID: number): StepIntoHandler {
        return new StepIntoHandler(workflowID, moduleID);
    }
    getAdditionalBreakpointHandler(settings) {
        if (settings && settings.Script) {
            return new ExecuteScriptAdditionalBreakpointHandler(settings.Script);
        }
        return super.getAdditionalBreakpointHandler(settings);
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Script) {
                try {
                    let sp = new ScriptParser();
                    let module = sp.GetModule(settings.Script);
                    //Falls es Konstellationen gibt, wo das setzen des Status auf eine Variable nach (Zeilenweise betrachtet) dem Zugriff auf den Status passiert. Dann Zeilen wieder einkommentiern.
                    //sp.StatusValueNames = [];
                    //module = sp.GetModule(settings.Script);
                    WorkflowModuleSettingsHelper.AddStatusKeysToState(state, sp.StatusValueNames);
                } catch (ex) {
                    console.log("Error while parsing script: " + ex);
                }
            }
        }
    }
}

export class ExecuteScriptAdditionalBreakpointHandler implements IAdditionalBreakPointHandler {
    get Token(): InjectionToken<{}> {
        return DebugScriptBreakpointSettingsControl.SCRIPT_DEBUG_DATA;
    }
    get ComponentType(): any {
        return DebugScriptBreakpointSettingsControl;
    }
    get EmptyData(): any {
        return {
            Breakpoints: []
        };
    }

    constructor(private script: string) {
    }

    checkBreakPointDataForRemove(bpData: any): boolean {
        if (bpData) {
            return !bpData.Breakpoints || bpData.Breakpoints.length === 0;
        }
        return false;
    }

    updateControlData(controlData, data, debugData) {
        controlData.Script = this.script;
        if (data && data.Breakpoints) {
            controlData.Breakpoints = data.Breakpoints;
        } else {
            controlData.Breakpoints = [];
        }
        let actLine = -1;
        let external;
        if (debugData) {
            if (typeof debugData.ActualLine === 'number' && debugData.ActualLine > -1) {
                actLine = debugData.ActualLine;
            }
            if (debugData.ScriptLibraryID) {
                external = {
                    ScriptLibraryID: debugData.ScriptLibraryID,
                    IsConnectorScript: debugData.IsConnectorScript
                };
            }
        }
        controlData.ActualLine = actLine;
        controlData.ExternalScript = external;
    }
}

export class ScriptModuleExecuter extends WorkflowModuleExecuter {
    private _Executioner: ScriptExecutioner;
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (!status.ActualSettings.Script) {
                status.Logger.logError("Script modul: No script defined.");
                return -1;
            }
            try {
                if (this._Executioner == null) {
                    this._Executioner = await ScriptExecutioner.GetExecutioner(status.ActualSettings.Script, status, this.ModulID);
                }
                const obj = await this._Executioner.Execute();
                if (obj != null) {
                    status.ActualParameter = obj;
                }
                return 0;
            }
            catch (ex)
            {
                status.Logger.logError("Script modul: Error while executing script (" + ex + ")");
            }
            return -1;
        } else {
            status.Logger.logError('DataSwitch modul: No settings found.');
        }
        return super.execute(status);
    }
}