import { BaseTriggerControl } from './base.trigger.control';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component } from '@angular/core';

@Component({
    selector: 'ftptrigger-control',
    templateUrl: './ftp.trigger.control.html',
    styleUrls: ['./ftp.trigger.control.css']
})
export class FTPTriggerControl extends BaseTriggerControl {
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    add(event) {
        if (this.TriggerValue) {
            const input = event.input;
            const value = event.value;

            // Add our fruit
            if ((value || '').trim()) {
                if (!this.TriggerValue.FileSearch) {
                    this.TriggerValue.FileSearch = [];
                }
                this.TriggerValue.FileSearch.push({ SearchValue: value.trim() });
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }
        }
    }

    SFTPChanged(ev) {
        if (ev && ev.checked && this.TriggerValue) {
            this.TriggerValue.Anonymous = false;
        }
        this.OnTriggerHasChanged();
    }
}
