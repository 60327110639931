import { LayoutUnit } from "../basic/layoutunit.model";
import { Type } from "class-transformer";
// @dynamic
export class BorderRadius {
  @Type(() => LayoutUnit)
  TopLeft: LayoutUnit = new LayoutUnit();
  @Type(() => LayoutUnit)
  TopRight: LayoutUnit = new LayoutUnit();
  @Type(() => LayoutUnit)
  BottomLeft: LayoutUnit = new LayoutUnit();
  @Type(() => LayoutUnit)
  BottomRight: LayoutUnit = new LayoutUnit();
    toJSON() {
        let topleft = LayoutUnit.ToStyle(this.TopLeft);
        let topright = LayoutUnit.ToStyle(this.TopRight);
        let bottomleft = LayoutUnit.ToStyle(this.BottomLeft);
        let bottomright = LayoutUnit.ToStyle(this.BottomRight);
        if (topleft || topright || bottomleft || bottomright) {
            return this;
        } else {
            return null;
        }
    
  }
}
