import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'property-control',
    templateUrl: './property.control.html',
    styleUrls: ['./property.control.css']
})
export class PropertyControl {

    //#region OnlySetProperty
    OnlySetPropertyValue = false;

    @Input()
    get OnlySetProperty() {
        return this.OnlySetPropertyValue;
    }
    set OnlySetProperty(val) {
        if (val) {
            this.OnlySetPropertyValue = val;
            this.OnlySetPropertyChange.emit(this.OnlySetPropertyValue);
        }
    }

    @Output() OnlySetPropertyChange = new EventEmitter<any>();
    //#endregion

    //#region Horizontal
    HorizontalValue = true;

    @Input()
    get Horizontal() {
        return this.HorizontalValue;
    }
    set Horizontal(val) {
        if (val) {
            this.HorizontalValue = val;
            this.HorizontalChange.emit(this.HorizontalValue);
        }
    }

    @Output() HorizontalChange = new EventEmitter<any>();
    //#endregion

    //#region PropertyData
    PropertyDataValue: IPropertyData;

    @Input()
    get PropertyData() {
        return this.PropertyDataValue;
    }
    set PropertyData(val) {
        if (val) {
            this.PropertyDataValue = val;
            this.PropertyDataChange.emit(this.PropertyDataValue);
        }
    }

    @Output() PropertyDataChange = new EventEmitter<any>();
    //#endregion

    //#region TypeValues
    TypeValuesValue;

    @Input()
    get TypeValues() {
        return this.TypeValuesValue;
    }
    set TypeValues(val) {
        if (val) {
            this.TypeValuesValue = val;
            this.TypeValuesChange.emit(this.TypeValuesValue);
        }
    }

    @Output() TypeValuesChange = new EventEmitter<any>();
    //#endregion
    //#region ValuesValues
    ValuesValue;

    @Input()
    get Values() {
        return this.ValuesValue;
    }
    set Values(val) {
        if (val) {
            this.ValuesValue = val;
            this.ValuesChange.emit(this.ValuesValue);
        }
    }

    @Output() ValuesChange = new EventEmitter<any>();
    //#endregion
    @Output() ValueChanged = new EventEmitter<any>();

    static GetTypeList() {
        return [{ Caption: '@@Text', Value: 'System.String' }, { Caption: '@@Bool', Value: 'System.Boolean' },
            { Caption: '@@Ganzzahl', Value: 'System.Int32' }, { Caption: '@@Gleitzahl', Value: 'System.Double' },
            { Caption: '@@Datum', Value: 'System.DateTime' }];
    }

    constructor() {
        this.TypeValuesValue = PropertyControl.GetTypeList();
    }

    compareFn(item1, item2) {
        return item1 && item2 && item1.Name === item2.Name;
    }

    onValueChanged() {
        this.ValueChanged.emit(this.PropertyDataValue.Value);
    }
}

export interface IPropertyData {
    Name: string;
    Type: string;
    Value: any;
    Options?: any[];
}
