import { Subject } from 'rxjs';

export class Row {
    edit: boolean = false;
    selected: boolean = false;
    expanded: boolean = false;
    index: number = 0;
    data: any = {};
    styles: any = {};
    rowStyle: any = {};
    cols: any = {};
    Expandable: boolean = false;
    Children: Row[];
    RowExpand: Subject<boolean> = new Subject();
    constructor() { }
}
