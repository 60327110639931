import { RequestBase } from './request-base';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class WorkflowWizardService extends RequestBase {
    BasePath = "api/workflow/wizard";

    GetAllWizards(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllWizards', this.options);
    }

    GetWizardObject(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWizardObject?id=' + id, this.options);
    }

    SaveWizardObject(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveWizardObject', obj, this.options);
    }

    DeleteWizardObject(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteWizardObject?id=' + id, this.options);
    }
}
