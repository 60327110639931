import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, ViewChild } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { TranslateHelper } from '../../helpers/injector.helper';
import { MetaService } from '../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'layout-events-settings',
    templateUrl: '../base.list.settings.html'
})
export class LayoutEventsSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@Events',
            ID: 'layout-events',
            Icon: 'bolt',
            Index: 80,
            Parent: 'layout',
            Security: {
                Name: 'evidanza.App.Shared.Security.LayoutRight',
                Value: 128
            },
            Content: LayoutEventsSettings
        };
    }

    constructor(private metaService: MetaService, protected fR: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(fR, cdRef);
    }

    getContentType() {
        return LayoutEventsDetail;
    }

    loadList(handler) {
        this.metaService.ReadEventInfos().subscribe((events) => {
            if (events) {
                const list = [];
                events.forEach(wf => {
                    list.push({
                        Caption: wf.Caption,
                        ID: wf.SID,
                        IsCapsule: wf.IsCapsule,
                        IsOverridden: wf.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data) {
        this.metaService.ReadEvent(data).subscribe(result => {
            this.setSelectedItem(result);
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@Neues Event')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Event \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Event \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Event loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Event \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Name);
        retVal.Title = new TranslateFormatText('@@Event speichern');
        return retVal;
    }

    delete(data, handler) {
        this.metaService.DeleteEvent(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        if(item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        this.metaService.AddOrUpdateEvent(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item._Id = result.SID;
        item._version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'layout-events-detail',
    templateUrl: './layout.events.settings.html'
})
export class LayoutEventsDetail extends BaseListDetail {

    @ViewChild('nameInput') nameInput: ElementRef;

    setSelectedItem(item) {
        super.setSelectedItem(item);
        if (item && this.nameInput && this.nameInput.nativeElement) {
            if (typeof this.nameInput.nativeElement.focus === 'function') {
                this.nameInput.nativeElement.focus();
                if (typeof this.nameInput.nativeElement.select === 'function') {
                    window.setTimeout(() => {
                        this.nameInput.nativeElement.select();
                    }, 200);
                }
            }
        }
    }
}
