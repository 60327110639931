import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '../../../cache/cache.service';
import { CollaborationHelper } from '../../../helpers/collaboration.helper';
import { LayoutChangeStatusHandler } from '../../../helpers/layout.change.status.handler';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { GitDiffType } from '../../../models/git/gitcomparison.model';
import { WorkflowData } from '../../../models/workflow/workflow.model';
import { JiraService } from '../../../services/jira.service';
import { MediaService } from '../../../services/media.service';
import { SideNavService } from '../../../services/sidenav.service';
import { UsersService } from '../../../services/users.service';

@Component({
    selector: 'user-overview-control',
    templateUrl: './user.overview.control.html',
    styleUrls: ['./user.overview.control.css']
})
export class UserOverviewControl implements OnInit, OnDestroy {
    ChatMessages = [];
    SystemMessages = [];
    ActualMessage;
    URL;
    ActualUsers = [];
    Subscriptions = [];
    ShowNotifications = true;
    ShowSaveMessage = true;
    ChatStyle = {};
    ActivityStyle = {};
    SelectedIndexValue = 0;
    SelectedJiraTicket;
    NavStruct;
    JiraConfig;
    JiraLabelKey;
    JiraTickets;
    JiraIssueTypes;
    SaveEnabled = false;
    JiraPriorities;
    JiraStatuses;
    JiraUsers;

    SelectedNewTicketType;

    SearchStatuses = [];
    SearchUsers = [];
    SearchPriorities = [];
    SortPriorities;
    SortSummary;

    @Input()
    get SelectedIndex() {
        return this.SelectedIndexValue;
    }
    set SelectedIndex(val) {
        this.SelectedIndexValue = val;
        if (val === 0) {
            this.ChatStyle = {};
        } else if (val === 1) {
            this.ActivityStyle = {};
        }
    }

    @ViewChild('messageDIV') messageDIV: ElementRef;
    @ViewChild('activityDIV') activityDIV: ElementRef;
    @ViewChild('jiraDetailsDIV') jiraDetailsDIV: ElementRef;

    constructor(private cdRef: ChangeDetectorRef, private jira: JiraService, private translate: TranslateService) {
    }

    ngOnInit() {
        const settings = CollaborationHelper.Settings.getValue();
        if (settings) {
            this.ShowNotifications = settings.ShowNotifications;
            this.ShowSaveMessage = settings.ShowSaveMessage;
        }
        this.Subscriptions.push(CollaborationHelper.ActualChatMessages.subscribe(x => {
            if (x) {
                this.ChatMessages = x;
            } else {
                this.ChatMessages = [];
            }
            this.cdRef.detectChanges();
            if (this.messageDIV && this.messageDIV.nativeElement) {
                this.messageDIV.nativeElement.scrollTop = this.messageDIV.nativeElement.scrollHeight;
            }
            if (this.SelectedIndexValue === 0) {
                this.ChatStyle = {};
            } else if (this.ChatMessages.length > 0) {
                this.ChatStyle = {
                    'font-weight': 'bold'
                };
            }
        }));
        this.Subscriptions.push(LayoutChangeStatusHandler.LayoutChangeHandlerMessages.subscribe(x => {
            if (x) {
                this.SystemMessages = x;
            } else {
                this.SystemMessages = [];
            }
            this.cdRef.detectChanges();
            if (this.activityDIV && this.activityDIV.nativeElement) {
                this.activityDIV.nativeElement.scrollTop = this.activityDIV.nativeElement.scrollHeight;
            }
            if (this.SelectedIndexValue === 1) {
                this.ActivityStyle = {};
            } else if (this.SystemMessages.length > 0) {
                this.ActivityStyle = {
                    'font-weight': 'bold'
                };
            }
        }));
        this.Subscriptions.push(CollaborationHelper.ActualActiveUsers.subscribe(x => {
            if (x) {
                this.ActualUsers = x;
            } else {
                this.ActualUsers = [];
            }
            this.cdRef.detectChanges();
        }));
        this.URL = MediaService.ProfilePicURL.getValue();

        const navStruct = SideNavService.SelectedNavigationStructure.getValue();
        CacheService.ReadNavigationStructure(navStruct).then(x => {
            if (x.Jira) {
                this.JiraLabelKey = CollaborationHelper.ActiveChannel.getValue().substring(1);
                this.NavStruct = x;
                this.jira.GetByID(x.Jira).subscribe(y => {
                    this.JiraConfig = y;
                    this.JiraConfig.Project = x.Project;
                    this.jira.GetIssueTypes(this.JiraConfig).subscribe(types => {
                        this.JiraIssueTypes = types;
                        this.JiraIssueTypes.forEach(x => x.ID = '' + x.ID);
                    });
                    this.jira.GetPriorities(this.JiraConfig).subscribe(prios => { this.JiraPriorities = prios; });
                    this.jira.GetStatuses(this.JiraConfig, true).subscribe(stats => { this.JiraStatuses = stats; });
                    this.jira.GetUsers(this.JiraConfig).subscribe(users => { this.JiraUsers = users; });
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

    onKeyDown(ev) {
        if (ev && ev.key === 'Enter') {
            this.sendMessage();
        }
    }

    sendMessage() {
        if (this.ActualMessage) {
            CollaborationHelper.SendMessage(this.ActualMessage);
            this.ActualMessage = null;
        }
    }

    newJiraTicket(ev) {
        if (ev.value) {
            this.SelectedNewTicketType = null;
            let user = UsersService.ActiveUser.getValue();
            if (user) {
                let ticket = {
                    Fields: {
                        summary: this.translate.instant('@@Neues Ticket'),
                        labels: [this.JiraLabelKey, user.DisplayName],
                        issuetype: { id: ev.value.ID },
                        project: { key: this.JiraConfig.Project }
                    }
                };
                this.jira.SaveIssue({ Config: this.JiraConfig, Issue: ticket }).subscribe(ret => {
                    
                    this.jira.GetIssueByKey({ Config: this.JiraConfig, Key: ret.Key }).subscribe(ret2 => {
                        this.JiraTickets.push(ret2);
                        this.cdRef.detectChanges();
                        this.SelectedJiraTicket = ret2;
                        this.editJiraTicket(ret2);
                    });
                });
            }
        }
    }

    saveJiraTicket() {
        if (this.SelectedJiraTicket && this.SelectedJiraTicket.Key) {
            this.jira.SaveIssue(this.SelectedJiraTicket);
        } else {
            this.jira.UpdateIssue(this.SelectedJiraTicket);
        }
    }

    editJiraTicket(ticket) {
        //if (this.SaveEnabled) {
        //    const retVal = MessageBoxHelper.ShowDialog(new TranslateFormatText('Sie haben nicht gespeicherte Änderungen, wollen Sie diese jetzt speichern?'), new TranslateFormatText('Nicht gespeichert.'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
        //        if (x === MessageBoxResult.Yes) {
        //            this.saveJiraTicket();
        //        }
        //    });
        //}
        this.SaveEnabled = false;
        this.SelectedJiraTicket = ticket;
    }

    refreshJiraTicket() {
        let jirasearch = {
            Config: this.JiraConfig,
            Fields: null,
            Search: ['labels in (' + this.JiraLabelKey + ')'],
            OrderBy: []
        };

        if (this.SearchPriorities.length > 0) {
            let tmp = [];
            this.SearchPriorities.forEach(x => tmp.push(x.ID));
            let search = 'priority in (' + tmp.join(',') + ')';
            jirasearch.Search.push(search);
        }
        if (this.SearchStatuses.length > 0) {
            let search = 'status in (' + this.SearchStatuses.join(',') + ')';
            jirasearch.Search.push(search);
        }
        if (this.SearchUsers.length > 0) {
            let tmp = [];
            this.SearchUsers.forEach(x => tmp.push(x.AccountId));
            let search = 'assignee in (' + tmp.join(',') + ')';
            jirasearch.Search.push(search);
        }
        if (this.SortPriorities && this.SortPriorities != "null") {
            let orderby = 'priority ' + this.SortPriorities;
            jirasearch.OrderBy.push(orderby);
        }
        if (this.SortSummary && this.SortSummary != "null") {
            let orderby = 'summary ' + this.SortSummary;
            jirasearch.OrderBy.push(orderby);
        }


        this.SelectedJiraTicket = null;
        this.jira.SearchIssues(jirasearch).subscribe(result => {
            this.JiraTickets = result;
            this.cdRef.detectChanges();
        });
    }

    closeJiraTicket() {
        this.SelectedJiraTicket = null;
        this.cdRef.detectChanges();
  }

    onNotificationChange() {
        CollaborationHelper.UpdateSettings('ShowNotifications', this.ShowNotifications);
    }

    onSaveChange() {
        CollaborationHelper.UpdateSettings('ShowSaveMessage', this.ShowSaveMessage);
    }

    ObjectsTree;

    onTabChanged(event) {
        if (this.SelectedIndexValue == 0) {
            if (this.messageDIV && this.messageDIV.nativeElement) {
                this.messageDIV.nativeElement.scrollTop = this.messageDIV.nativeElement.scrollHeight;
            }
        } else if (this.SelectedIndexValue == 1) {
            if (this.activityDIV && this.activityDIV.nativeElement) {
                this.activityDIV.nativeElement.scrollTop = this.activityDIV.nativeElement.scrollHeight;
            }
        } else if (this.SelectedIndexValue == 2) {
            //let changes = LayoutHelper.GetActiveChanges();
            //this.ActiveChangeset = changes;
            //if (changes && changes.ChangesTree) {
            //    this.ObjectsTree = changes.ChangesTree;
            //}
        } else if (this.JiraConfig && this.SelectedIndexValue == 3) {
            if (!this.JiraTickets) {
                this.refreshJiraTicket();
            }
        }
    }
    ActiveChangeset;
    ComparisonSource;
    ComparisonModified;
    ComparisonType;
    ShowComparison = false;
    OpenComparison(node) {
        let key = node.key;
        let sourcecontent = '';
        let modifiedcontent = '';
        let change = null;
        if (this.ActiveChangeset && this.ActiveChangeset.Changes && this.ActiveChangeset.Changes[key]) {
            change = this.ActiveChangeset.Changes[key];
            if (change.Type == GitDiffType.WORKFLOW) {
                this.ComparisonType = 'SideBySideWorkflow';
                sourcecontent = change.Source;
                modifiedcontent = change.Modified;
                if (sourcecontent) {
                    let sourcemodules = sourcecontent['Data'].Modules;
                    if (sourcemodules.length > 0) {
                        sourcemodules.forEach((module) => {

                        });
                    }
                    this.ComparisonSource = sourcecontent['Data'];
                } else {
                    this.ComparisonSource = new WorkflowData();
                }
                if (modifiedcontent) {
                    let modifiedmodules = modifiedcontent['Data'].Modules;
                    if (modifiedmodules.length > 0) {
                        modifiedmodules.forEach((module) => {

                        });
                    }
                    this.ComparisonModified = modifiedcontent['Data'];
                } else {
                    this.ComparisonModified = new WorkflowData();
                }
                //this.wfService.GetAllWorkflowTemplates().subscribe(result => {
                //    if (result && result.length > 0) {
                //        //if (this.Data && this.Data.Modules) {
                //        //    this.Data.Modules.forEach(function (mod) {
                //        //        if (mod.Module == TemplateSettingsData.ModuleID && mod.Settings) {
                //        //            result.some(function (r) {
                //        //                if (r.Id == mod.Settings.TemplateID) {
                //        //                    mod.Settings.TemplateName = r.Name;
                //        //                    return true;
                //        //                }
                //        //                return false;
                //        //            });
                //        //        }
                //        //    });
                //        //}
                //        this.EditOptions.TemplateList = result;
                //    }
                //this.TemplatesLoaded = true;
                //    this.cdRef.detectChanges();
                //});
            }  else {
                this.ComparisonType = 'Text';
                sourcecontent = change.Source ? JSON.stringify(change.Source, null, 2) : '';
                if (typeof (change.Source) == 'string') {
                    sourcecontent = change.Source;
                }

                modifiedcontent = change.Modified ? JSON.stringify(change.Modified, null, 2) : '';
                if (typeof (change.Modified) == 'string') {
                    modifiedcontent = change.Modified;
                }
                this.ComparisonSource = {
                    code: sourcecontent,
                    language: 'json'
                }
                this.ComparisonModified = {
                    code: modifiedcontent,
                    language: 'json'
                }
            }
            this.ShowComparison = true;
        }
        this.cdRef.detectChanges();
    }
}
