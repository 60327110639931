import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { ConnectorService } from '../../../services/connector.service';
import { DataCheck } from '../../../workflow/workflow.dialog';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';

@Component({
    selector: 'connector-scripts-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ConnectorScriptsSettings extends BaseListSettings {
    constructor(protected cfr: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: ConnectorService) {
        super(cfr, cdRef);
        this.ShowDelete = false;
    }

    getContentType() {
        return ConnectorScriptsDetail;
    }

    loadList(handler) {
        if (this.InitArgs) {
            this.service.GetScriptInfos(this.InitArgs.SID).subscribe((result) => {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden
                    });
                });
                handler(list);
            });
        } else {
            handler([]);
        }
    }

    loadData(data) {
        this.service.GetScript(data).subscribe((result) => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        return {
            ConnectorId: this.InitArgs ? this.InitArgs.SID : null,
            Script: {
                Name: TranslateHelper.TranslatorInstance.instant('@@Neues Script'),
                Text: '',
                IsServerScript: true
            }
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Script \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Script \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Script loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Script \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Script speichern');
        return retVal;
    }

    delete(data, handler) {
        // nichts tun
    }

    protected async checkCanSave(): Promise<DataCheck> {
        return new Promise((resolve, reject) => {
            if (this.Component.editor) {
                this.Component.editor.getAction('editor.action.formatDocument').run().then(() => {
                    resolve(new DataCheck());
                });
            } else {
                resolve(new DataCheck());
            }
        });
    }

    saveInternal(item, handler) {
        this.service.SaveScript(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'connector-scripts-detail',
    templateUrl: './connector.scripts.settings.html'
})
export class ConnectorScriptsDetail extends BaseListDetail {
    editorOptions = {
        language: 'typescript'
    };

    editor;

    EditorInit(editor) {
        this.editor = editor;
    }
}
