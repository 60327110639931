import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, HostListener,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {UUID} from 'angular2-uuid';
import {PermissionHelper} from '../../../helpers/permissions.helper';
import {RTLHelper} from '../../../helpers/rtl.helper';
import {ContextEventHelper} from '../../../models/contextevent.model';
import {NavigationService} from '../../../services/navigation.service';
import {UsersService} from '../../../services/users.service';
import {SETTINGS_LIST} from "../../pages/settings.page";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {Subject} from "rxjs";

@Component({
    selector: 'evi-nav-menu',
    templateUrl: './navigation.menu.html',
    styleUrls: ['./navigation.menu.css'],
    animations: [
        trigger('slideInOut', [
            state('in', style({transform: 'translateX(0)'})),
            transition('void => *', [
                style({transform: 'translateX(-100%)'}),
                animate('0.3s ease-in-out')
            ]),
            transition('* => void', [
                animate('0.3s ease-in-out', style({transform: 'translateX(-100%)'}))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMenu implements OnInit, AfterViewInit {
    NavMode = 'side';
    NavOpenValue = true;
    LastLevelDropdownValue = false;
    listOnHover = -1;

    get NavOpen() {
        return this.NavOpenValue;
    }

    set NavOpen(value) {
        this.NavOpenValue = value;
        NavigationService.NavOpenChanged.next({ Value: value, Instance: this.ID })
    }

    get LastLevelDropdown() {
        return this.LastLevelDropdownValue
    };
    
    set LastLevelDropdown(value) { 
        this.LastLevelDropdownValue = value;
    }


    @Input() isNavOpen: boolean;
    

    ngOnChanges(changes: SimpleChanges) { 
        console.log('Changes', this.isNavOpen);
        if(this.isNavOpen) this.onClickItem(this.SelectedNavigation)
    }

    openTimeOut = -1;

    ViewInitialized = false;
    Style = {};
    NavStyle = {};
    SelectedNavigation;
    ID = UUID.UUID();
    @ViewChild('iconnav') iconnav;
    @ViewChild('childnav') childnav;

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        if (!this.SubNavValue) return; // Navigation is already closed
        // Check if the clicked element is outside of the sub navigation div
        const navElement = document.querySelector('.side-navigation');
        if (!navElement || !navElement.contains(event.target as Node)) {
            this.SubNavValue = false;
        }
    }

    SubNavValue: boolean;

    @Input()
    get SubNavOpen() {
        return this.SubNavValue;
    }

    set SubNavOpen(val) {
        this.SubNavValue = val;
    }

    TabIndexValue = 6;

    @Input()
    get TabIndex() {
        return this.TabIndexValue;
    }

    set TabIndex(val) {
        this.TabIndexValue = val;
    }

    NavLevelIndex(nav) {
        const _defaultIndex = 10;
        if (nav) {
            if (nav?.NaviTabInstance?.LevelValue == 1 && nav?.Children && nav?.Children?.length > 0) {
                const child = nav?.Children?.find(x => x?.Children != null);
                if (!child) {
                    this.TabIndexValue = 2;
                } else {
                    this.TabIndexValue = _defaultIndex;
                }
            } else {
                if (nav?.NaviTabInstance?.LevelValue == 0) {
                    const _selected = nav?.Children?.find(x => x.Selected);
                    if (_selected) {
                        this.NavLevelIndex(_selected);
                    }
                }
            }
        }

    }

    //#region NavigationItems
    NavigationItemsValue = [];

    @Input()
    get NavigationItems() {
        return this.NavigationItemsValue;
    }

    set NavigationItems(val) {
        if (val != this.NavigationItemsValue) {
            this.NavigationItemsValue = val;
            if (val) {
                val.forEach(x => {
                    x.NaviTabInstance = this;
                    let user = UsersService.ActiveUser.getValue();
                    if (user) {
                        if (user.IsAdministrator) {
                            x.SecurityValue = true;
                        } else {
                            if (x.Security) {
                                x.SecurityValue = PermissionHelper.GetRight(x.Security.Name, x.Security.Value);
                            } else {
                                x.SecurityValue = true;
                            }
                        }
                    }
                });
            }
            if (this.ViewInitialized) {
                this.checkNavi();
            }
            this.NavigationItemsChange.emit(this.NavigationItemsValue);
        }
        this.cdRef.detectChanges();
    }

    @Output() NavigationItemsChange = new EventEmitter<any>();


    //#endregion
    //#region DeepLinking
    DeepLinkingValue;

    @Input()
    get DeepLinking() {
        return this.DeepLinkingValue;
    }

    set DeepLinking(val) {
        this.DeepLinkingValue = val;
        this.DeepLinkingChange.emit(this.DeepLinkingValue);
    }

    @Output() DeepLinkingChange = new EventEmitter<any>();
    //#endregion
    //#region Level
    LevelValue = 0;

    @Input()
    get Level() {
        return this.LevelValue;
    }

    set Level(val) {
        this.LevelValue = val;
        this.generateStyle();
        this.LevelChange.emit(this.LevelValue);
    }

    @Output() LevelChange = new EventEmitter<any>();
    //#endregion

    //#region SelectionHandler
    SelectionSetSubscription;
    SelectionHandlerValue;

    @Input()
    get SelectionHandler() {
        return this.SelectionHandlerValue;
    }

    set SelectionHandler(val) {
        this.SelectionHandlerValue = val;
        if (this.SelectionSetSubscription) {
            this.SelectionSetSubscription.unsubscribe();
        }
        if (val && val.SelectionSet) {
            this.SelectionSetSubscription = val.SelectionSet.subscribe(() => {
                let nav = this.NavigationItemsValue.find((value) => {
                    return value.Selected;
                });
                this.SelectedNavigation = nav;
                this.cdRef.detectChanges();
            });
        }
        this.SelectionHandlerChange.emit(this.SelectionHandlerValue);
    }

    @Output() SelectionHandlerChange = new EventEmitter<any>();

    //#endregion

    constructor(private cdRef: ChangeDetectorRef, private router: Router) {
    }

    ngOnInit(): void {
        this.generateStyle();
    }

    ngAfterViewInit(): void {
        this.checkNavi();
        this.ViewInitialized = true;
    }
    onClickItem(nav) {
        event.stopPropagation();
        this.SubNavValue = true;
        this.cdRef.detectChanges();
        this.itemClicked(nav);
    }
    
    
    async itemClicked(nav) {
        await this.selectItem(nav);
        if (this.SelectionHandlerValue && typeof this.SelectionHandlerValue.OnNavClicked === 'function') {
            this.SelectionHandlerValue.OnNavClicked(nav);
        }
    }

    generateStyle() {
        const mod = (this.LevelValue % 5);
        const rgb = 94 + 40 * mod;
        this.Style['background-color'] = 'rgb(' + rgb + ',' + rgb + ',' + rgb + ')';
        if (mod < 4) {
            this.Style['color'] = 'rgb(245,245,245)';
            this.NavStyle['background-color'] = 'rgb(' + (rgb + 40) + ',' + (rgb + 40) + ',' + (rgb + 40) + ')';
            if (mod < 3) {
                this.NavStyle['color'] = 'rgb(245,245,245)';
            } else {
                this.NavStyle['color'] = 'rgb(94, 94, 94)';
            }
        } else {
            this.NavStyle['background-color'] = 'rgb(94, 94, 94)';
            this.NavStyle['color'] = 'rgb(245,245,245)';
        }
    }

    async selectItem(nav) {
        this.NavLevelIndex(nav);
        if (nav !== this.SelectedNavigation) {
            if (this.SelectionHandlerValue && typeof this.SelectionHandlerValue.CheckComponent === 'function') {
                const retVal = await this.SelectionHandlerValue.CheckComponent();
                if (!retVal) {
                    return;
                }
            }
            if (this.DeepLinking) {
                if (nav != null) {
                    if (this.SelectionHandlerValue && this.SelectionHandlerValue.NavigationItems) {
                        let path = this.findItem(nav, this.SelectionHandlerValue.NavigationItems);
                        let url = "";
                        let count = 0;
                        path.forEach((p) => {
                            if (count > 0) {
                                url += "/";
                            }
                            url += p;
                            count += 1;
                        });

                        this.NavigationItemsValue.forEach(function (n) {
                            n.Selected = false;
                        })
                        if (nav) {
                            nav.Selected = true;
                            window.clearTimeout(this.openTimeOut);
                            // this.NavOpen = false;
                            // this.NavMode = 'over';
                        } else {
                            // this.NavOpen = true;
                            // this.NavMode = 'side';
                        }
                        if (this.SelectionHandlerValue.BaseURL[this.SelectionHandlerValue.BaseURL.length - 1] != '/') {
                            this.SelectionHandlerValue.BaseURL += '/';
                        }
                        if (this.SelectionHandlerValue.ActiveURL.indexOf(this.SelectionHandlerValue.BaseURL + url) == -1) {
                            this.router.navigate([this.SelectionHandlerValue.BaseURL + url]);
                        }
                        this.SelectedNavigation = nav;
                    }
                }
            } else {
                this.NavigationItemsValue.forEach(function (n) {
                    n.Selected = false;
                });
                if (nav) {
                    nav.Selected = true;
                    window.clearTimeout(this.openTimeOut);
                    this.NavOpen = false;
                    this.NavMode = 'over';
                } else {
                    this.NavOpen = true;
                    this.NavMode = 'side';
                }
                this.SelectedNavigation = nav;
                this.cdRef.detectChanges();
            }
            if (this.SelectionHandlerValue && typeof this.SelectionHandlerValue.SetComponent === 'function') {
                this.SelectionHandlerValue.SetComponent(nav);
            }
        }
        this.cdRef.detectChanges();
    }

    findItem(nav, items) {
        let retVal = [];
        if (nav != null && items && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (nav == item) {
                    retVal.push(item.ID);
                }
                if (item.Children && item.Children.length > 0) {
                    let result = this.findItem(nav, item.Children)
                    if (result && result.length > 0) {
                        retVal.push(item.ID);
                        retVal.push(...result);
                    }
                }
            }
        }
        return retVal;
    }

    checkNavi() {
        let selected;
        if (this.NavigationItemsValue) {
            selected = this.NavigationItemsValue.find(x => x.Selected);
        }
        this.selectItem(selected);

    }

    onMouseEnter(i) {
        this.listOnHover = i;
        // if (this.NavMode === 'over') {
        //     this.openTimeOut = window.setTimeout(() => {
        //         this.NavOpen = true;
        //         ContextEventHelper.Close.next(null);
        //         if (this.childnav) {
        //             this.childnav.closeNav();
        //         }
        //         this.cdRef.detectChanges();
        //     }, 500);
        // }
    }

    onMouseLeave(i) {
        this.listOnHover = -1;
        // if (this.NavMode === 'over') {
        //     window.clearTimeout(this.openTimeOut);
        // }
    }
    @Output() NavOpenChange = new EventEmitter<boolean>();
    closeNav() {
        if (this.NavMode === 'over') {
            this.NavOpen = false;
            this.NavOpenChange.emit(false);
            if (this.childnav) {
                this.childnav.closeNav();
            }
            this.cdRef.detectChanges();
        }
    }

    onScroll(ev) {
        if (ev && ev.target && this.iconnav && this.iconnav.nativeElement) {
            this.iconnav.nativeElement.scrollTop = ev.target.scrollTop;
        }
    }

    GetLevelStyle() {
        if (RTLHelper.Direction == 'rtl') {
            return {'right': ((this.LevelValue * 40) + 20) + 'px'}
        } else {
            return {'left': ((this.LevelValue * 40) + 20) + 'px'}
        }
    }

    GetLevelStyleWithCheck() {
        if (RTLHelper.Direction == 'rtl') {
            return {'right': this.NavMode == 'over' ? ((this.LevelValue + 1) * 40) + 'px' : '0px', 'left': 0}
        } else {
            return {'left': this.NavMode == 'over' ? ((this.LevelValue + 1) * 40) + 'px' : '0px', 'right': 0}
        }
    }
}
