import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, Output } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { SecurityService } from '../../services/security.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';

@Component({
    selector: 'user-provider-settings',
    templateUrl: '../base.list.settings.html'
})
export class UserProviderSettings extends BaseListSettings {
    NewIndex = 0;

    //#region User
    UserValue;

    @Input()
    get User() {
        return this.UserValue;
    }
    set User(val) {
        if (this.UserValue !== val) {
            this.UserValue = val;
            this.CheckButtons();
            this.UserChange.emit(this.UserValue);
            if (this.Component) {
                this.refresh();
            }
        }
    }

    @Output() UserChange = new EventEmitter<any>();
    //#endregion

    //#region AllProviders
    AllProvidersValue = [];

    @Input()
    get AllProviders() {
        return this.AllProvidersValue;
    }
    set AllProviders(val) {
        if (this.AllProvidersValue !== val) {
            this.AllProvidersValue = val;
            this.AllProvidersChange.emit(this.AllProvidersValue);
        }
    }

    @Output() AllProvidersChange = new EventEmitter<any>();
    //#endregion

    //#region User
    IsManagedLocallyValue;

    @Input()
    get IsManagedLocally() {
        return this.IsManagedLocallyValue;
    }
    set IsManagedLocally(val) {
        if (this.IsManagedLocallyValue !== val) {
            this.IsManagedLocallyValue = val;
            this.CheckButtons();
            this.UserChange.emit(this.IsManagedLocally);
            if (this.Component) {
                this.refresh();
            }
        }
    }

    @Output() IsManagedLocallyChange = new EventEmitter<any>();
    //#endregion

    CheckButtons() {
        this.ShowAdd = this.IsManagedLocally && !this.UserValue.IsManagedByPortal;
        this.ShowDelete = this.IsManagedLocally && !this.UserValue.IsManagedByPortal;
    }

    constructor(private service: SecurityService, protected fr: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(fr, cdRef);
        this.ShowSave = false;
    }

    getContentType() {
        return UserProviderDetail;
    }

    loadList(handler) {
        let newIndex = 0;
        if (this.Component && this.UserValue) {
            const providers = this.AllProvidersValue;
            const list = [];
            if (this.UserValue.BoundAccounts) {
                this.UserValue.BoundAccounts.forEach((ba) => {
                    let caption = ba;
                    const key = ba.split('|')[0];
                    providers.some((p) => {
                        if (p.Id === key) {
                            caption = p.Name;
                            return true;
                        }
                        return false;
                    });
                    list.push({ Caption: caption, ID: ba });
                });
            }
            if (this.UserValue.NewAccounts) {
                this.UserValue.NewAccounts.forEach((na) => {
                    na.Key = '' + (newIndex++);
                    na.ListItem.ID = na.Key;
                    list.push(na.ListItem);
                });
            }
            handler(list);
        }
        this.NewIndex = newIndex;
    }

    loadData(data) {
        this.Component.IsManagedByPortal = this.UserValue.IsManagedByPortal || !this.IsManagedLocally;
        let item;
        if (this.UserValue) {
            if (this.UserValue.ChangedAccounts) {
                item = this.UserValue.ChangedAccounts.get(data);
            }
            if (!item && this.UserValue.NewAccounts) {
                item = this.UserValue.NewAccounts.find(x => x.Key === data);
                if (item) {
                    const availableProviders = this.getAvailableProviders();
                    if (item.SelectedProvider) {
                        availableProviders.splice(0, 0, item.SelectedProvider);
                    }
                    item.Providers = availableProviders;
                }
            }
        }
        if (item) {
            this.setSelectedItem(item);
        } else {
            const splits = data.split('|');
            const key = splits[0];
            if (key === '9d252462-2180-4339-9354-53bb9a233afc' ||
                this.AllProvidersValue.some(x => x.Type === '31e75470-8458-48e5-af70-d8a6e8840b37' && x.Id === key)) {
                this.service.GetBoundAccount(data).subscribe(result => {
                    if (result) {
                        const newItem = {
                            Key: data,
                            User: {
                                ID: result.ID,
                                UserName: result.UserName,
                                OldUserName: result.UserName,
                                FirstName: result.Properties.FirstName,
                                LastName: result.Properties.LastName,
                                Email: result.Properties.Email,
                                Phone: result.Properties.Phone
                            },
                            IsNew: false,
                            Providers: [],
                            SelectedProvider: null,
                        };
                        this.AllProvidersValue.some((p) => {
                            if (p.Id === key) {
                                newItem.Providers.push(p);
                                newItem.SelectedProvider = p;
                                return true;
                            }
                            return false;
                        });
                        this.setSelectedItem(newItem);
                    }
                });
            } else {
                const newItem = {
                    Key: data,
                    User: {
                        UserName: splits[2]
                    },
                    IsNew: false,
                    Providers: [],
                    SelectedProvider: null,
                };
                this.AllProvidersValue.some(function (p) {
                    if (p.Id === key) {
                        newItem.Providers.push(p);
                        newItem.SelectedProvider = p;
                        return true;
                    }
                    return false;
                });
                this.setSelectedItem(newItem);
            }
        }
    }

    getNewItem() {
        var retVal = null;
        let availableProviders = this.getAvailableProviders();
        if (availableProviders.length > 0) {
            let listItem = { Caption: availableProviders[0].Name, ID: '' + (this.NewIndex++) };
            retVal = {
                Key: listItem.ID,
                User: {},
                ListItem: listItem,
                IsNew: true,
                Providers: availableProviders,
                SelectedProvider: availableProviders[0]
            }
            if (this.UserValue) {
                if (!this.UserValue.NewAccounts) {
                    this.UserValue.NewAccounts = [];
                }
                this.UserValue.NewAccounts.push(retVal);
            }
            this.ListItems.push(listItem);
            this.selectedNode = listItem;
        }
        return retVal;
    }

    getAvailableProviders() {
        const availableProviders = [];
        const usedKeys = [];
        if (this.UserValue) {
            if (this.UserValue.BoundAccounts) {
                this.UserValue.BoundAccounts.forEach(function (ba) {
                    usedKeys.push(ba.split('|')[0]);
                });
            }
            if (this.UserValue.NewAccounts) {
                this.UserValue.NewAccounts.forEach(function (na) {
                    if (na.SelectedProvider) {
                        usedKeys.push(na.SelectedProvider.Id);
                    }
                });
            }
        }
        this.AllProvidersValue.forEach((p) => {
            if (!usedKeys.some(k => k === p.Id)) {
                availableProviders.push(p);
            }
        });
        return availableProviders;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den UserProvider \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@UserProvider \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@UserProvider loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@UserProvider \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@UserProvider speichern');
        return retVal;
    }

    delete(data, handler) {
        if (this.UserValue) {
            if (this.UserValue.BoundAccounts) {
                let index = this.UserValue.BoundAccounts.indexOf(data);
                if (index >= 0) {
                    this.UserValue.BoundAccounts.splice(index, 1);
                    if (this.UserValue.ChangedAccounts) {
                        this.UserValue.ChangedAccounts.delete(data);
                    }
                    this.OnProviderChanged();
                    handler();
                    return;
                }
            }
            if (this.UserValue.NewAccounts) {
                for (var i = 0; i < this.UserValue.NewAccounts; i++) {
                    if (this.UserValue.NewAccounts[i].Key === data) {
                        this.UserValue.NewAccounts.splice(i, 1);
                        this.OnProviderChanged();
                        handler();
                        return;
                    }
                }
            }
        }
    }

    saveInternal(item, handler) {
        throw new Error('Method not implemented.');
    }

    @Output() ProviderChanged = new EventEmitter<any>();
    OnProviderChanged() {
        this.ProviderChanged.emit();
    }

    protected OnChange() {
        this.HasChanges = false;
        if (this.Component.SelectedItem) {
            if (this.Component.SelectedItem.IsNew === false) {
                if (this.UserValue) {
                    if (!this.UserValue.ChangedAccounts) {
                        this.UserValue.ChangedAccounts = new Map();
                    }
                    this.UserValue.ChangedAccounts.set(this.Component.SelectedItem.Key, this.Component.SelectedItem);
                }
            }
            this.OnProviderChanged();
        }
    }
}

@Component({
    selector: 'user-provider-detail',
    templateUrl: './user.provider.settings.html',
    styleUrls: ['./user.provider.settings.css']
})
export class UserProviderDetail extends BaseListDetail {
    IsManagedByPortal = false;

    providerSelected() {
        if (this.SelectedItem) {
            const prov = this.SelectedItem.SelectedProvider;
            if (prov) {
                const li = this.SelectedItem.ListItem;
                if (li) {
                    li.Caption = prov.Name;
                }
            }
        }
        this.OnItemChanged();
    }

    onShowPasswordClick() {
        if (this.SelectedItem) {
            this.SelectedItem.ShowPassword = true;
        }
    }
}