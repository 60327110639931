import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumHelper } from '../../../../../helpers/enum.helper';
import { OrderProps } from '../../../../../models/rest/mapping.structure';
import { DataModelService } from '../../../../../services/datamodel.service';

@Component({
    selector: 'order-mapping',
    templateUrl: 'order.mapping.html',
    styleUrls: ['order.mapping.css']
})
export class OrderMappingForm {

    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region Fields
    FieldsValue;
    @Input()
    get Fields() {
        return this.FieldsValue;
    }
    set Fields(val) {
        this.FieldsValue = val;
        this.FieldsChange.emit(this.FieldsValue);
    }

    @Output() FieldsChange = new EventEmitter<any>();
    //#endregion

    @Output() ValuesChanged = new EventEmitter<any>();

    Properties;

    constructor(private _translate: TranslateService, private _dataModelService: DataModelService) {
        this.Properties = EnumHelper.GetDropdownValues(OrderProps);
    }
    ItemValuesChanged() {
        this.ValuesChanged.emit();
    }
}