import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../imports/material.module';
import { PrimeNgModule } from '../imports/primeng.module';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';


import { SidebarComponent } from "src/app/component_v2/sidebar/sidebar.component";
import { TourGuideComponent } from "src/app/component_v2/sidebar/tour-guide/tour-guide.component";

@NgModule({
  declarations: [SidebarComponent, TourGuideComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    MatMenuModule
  ],
  exports: [SidebarComponent, TourGuideComponent]  // Exporting the component
})
export class SharedModule { }
