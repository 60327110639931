import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, filter, take, map } from 'rxjs/operators';
import config from '../../assets/config.json';
import { InterceptorSkipHeader } from './interceptor.skip.header';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {
  BASE_URL = config.EXTERNAL_API_BASE_URL;
  LICENSE_KEY = config.LISCENES_KEY;
  API_PATH = `${this.BASE_URL}api/addon`;
  static ACCESS_TOKEN_NAME = 'GA-External-Access-Token';
  static REFRESH_TOKEN_NAME = 'GA-External-Refresh-Token';

  constructor(private http: HttpClient) {}

  Authorize(): Observable<string | null> {
    const accessToken = localStorage.getItem(RefreshTokenService.ACCESS_TOKEN_NAME);
    if (!accessToken) {
      const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
      return this.http.post<any>(`${this.API_PATH}/authorize`, { licenseKey: this.LICENSE_KEY }, { headers }).pipe(
        catchError(error => this.handleError(error)),
        map(result => {
          if (result && result.Data) {
            this.StoreTokens(result.Data);
            return result.Data.AccessToken;
          }
          return null;
        })
      );
    } else {
      return of(accessToken);
    }
  }

  RefreshToken(): Observable<any> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http.post(`${this.API_PATH}/refreshtoken`, { RefreshToken: localStorage.getItem(RefreshTokenService.REFRESH_TOKEN_NAME) }, { headers }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  StoreTokens(data: any): void {
    if (data) {
      localStorage.setItem(RefreshTokenService.ACCESS_TOKEN_NAME, data.AccessToken);
      localStorage.setItem(RefreshTokenService.REFRESH_TOKEN_NAME, data.RefreshToken);
    }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // Handle error appropriately (e.g., log, display message)
    return throwError(() => error);
  }

  getAccessToken(): Observable<string | null> {
    return of(localStorage.getItem(RefreshTokenService.ACCESS_TOKEN_NAME));
  }
}
