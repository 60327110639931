import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EnumHelper } from "../../../helpers/enum.helper";
import { ActionSize } from "../../../models/enums/actionsize.enum";
import { Action } from "../../../models/ribbon/action.model";
import { ACTIONS_REGISTRY } from "../../../registries/actions/actions.registry";

@Component({
    selector: 'action-dialog',
    templateUrl: 'action.dialog.html',
    styleUrls: ['./action.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionDialog implements OnInit{

    DialogData;
    Type;
    ContainerType;

    Sizes;
    Registry = [...Array.from(ACTIONS_REGISTRY.keys())];

    constructor(private dialogRef: MatDialogRef<Action>, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.DialogData = data.data;
            this.Type = data.type;
            this.ContainerType = data.container;
        }
        this.Sizes = EnumHelper.GetDropdownValues(ActionSize);
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
    }

    Ok() {
        this.dialogRef.close({
            data: this.DialogData,
            action: 'save'
        });
    }
    Cancel() {
        this.dialogRef.close(null);
    }
    Delete() {
        this.dialogRef.close({
            data: this.DialogData,
            action: 'delete'
        })
    }
}