import { TranslatedString } from '../translatedstring.model';

export class EnumValue {
    private EnumServiceValue;

    get Value() {
        if (this.EnumServiceValue) {
            return this.EnumServiceValue.Value;
        }
        return null;
    }

    get Caption() {
        if (this.EnumServiceValue) {
            if (this.EnumServiceValue.Translation) {
                return TranslatedString.GetTranslation(this.EnumServiceValue.Translation);
            }
            if (this.EnumServiceValue.Name) {
                return this.EnumServiceValue.Name;
            }
            return this.EnumServiceValue.Caption;
        }
        return null;
    }

    constructor(enumServiceValue) {
        this.EnumServiceValue = enumServiceValue;
    }
}
