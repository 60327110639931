import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { ArrayHelpers } from '../../../helpers/array.helpers';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { DataService } from '../../../services/data.service';
import { DataModelService } from '../../../services/datamodel.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { AddDataWFModuleExecuter } from '../dataoperations/add.settings';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-define-objects-settings',
    templateUrl: 'define.objects.settings.html',
    styles: ['.objSettings { border: 1px solid lightgray; border-radius: 3px; margin-bottom: 2px; padding: 2px;}']
})
export class DefineObjectsSettings extends FormulaWorkflowDialogContent {
    DataModels = [];
    StaticTables = [];
    Initialized = false;
    Data = new DefineObjectsSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'defineObjectWFModule';
        reg.Caption = '@@Objekte definieren';
        reg.GroupID = 'generateData';
        reg.Index = 15;
        reg.SettingsControl = DefineObjectsSettings;
        reg.SettingsTypeHelper = new DefineObjectsSettingsDataHelper();
        reg.Executer = DefineObjectsModuleExecuter;
        return reg;
    }

    constructor(private dataModelService: DataModelService, private dataService: DataService) {
        super();
    }

    ngOnInit(): void {
        this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((data) => {
            this.StaticTables = data;
            this.dataModelService.GetModels().subscribe(models => {
                this.DataModels = models;
                this.Initialized = true;
            });
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(DefineObjectsSettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Objects.forEach(x => {
            if (x.StatusKey || x.UseForState) {
                const props = [];
                if (x.DefaultProps) {
                    x.DefaultProps.forEach(p => {
                        if (p.Name && p.Formula) {
                            props.push(p);
                        }
                    });
                }
                x.DefaultProps = props;
                values.push(x);
            }
        });
        this.Data.Objects = values;
        return this.Data;
    }

    addObject() {
        if (this.Data) {
            if (this.Data.Objects) {
                this.Data.Objects.push(new ObjectSetting());
            } else {
                this.Data.Objects = [new ObjectSetting()];
            }
        }
    }

    objDeleted(i) {
        if (this.Data && this.Data.Objects) {
            this.Data.Objects.splice(i, 1);
        }
    }

    stateChecked(index) {
        if (this.Data && this.Data.Objects) {
            for (let i = 0; i < this.Data.Objects.length; i++) {
                if (i !== index) {
                    this.Data.Objects[i].UseForState = false;
                }
            }
        }
    }

    showFormulaDialog(val, i) {
        const variables = [...this.Variables];
        if (this.Data && this.Data.Objects) {
            let added = false;
            for (let j = 0; j < i; j++) {
                const prevVal = this.Data.Objects[j];
                if (prevVal.StatusKey && !variables.some(x => x.Name === prevVal.StatusKey)) {
                    const vni = new VariablesNodeInformation();
                    vni.AliasKey = prevVal.StatusKey;
                    vni.Name = prevVal.StatusKey;
                    vni.VariableID = prevVal.StatusKey;
                    variables.push(vni);
                    added = true;
                }
            }
            if (added) {
                ArrayHelpers.sortAlphabetical(variables, 'Name');
            }
        }
        const args = {
            InputMode: FormulaInputMode.VariableName,
            Formula: val.Formula,
            Variables: variables
        };
        FormulaEditorDialog.ShowDialog(args, (result) => {
            if (result && result.Formula) {
                val.Formula = result.Formula;
            }
            return true;
        });
    }
}

@Component({
    selector: 'wf-define-objects-settings-item',
    templateUrl: 'define.objects.settings.item.html'
})
export class DefineObjectSettingsItem implements OnInit {

    DataSources = [];
    StaticDataSource = false;
    Columns = [];

    DataValue;
    @Input()
    get Data() {
        return this.DataValue
    }
    set Data(val) {
        this.DataValue = val;
        this.DataChange.emit(val);
    }
    @Output() DataChange = new EventEmitter<any>();

    StaticTablesValue = [];
    @Input()
    get StaticTables() {
        return this.StaticTablesValue
    }
    set StaticTables(val) {
        this.StaticTablesValue = val;
        this.StaticTablesChange.emit(val);
    }
    @Output() StaticTablesChange = new EventEmitter<any>();

    DataModelsValue = [];
    @Input()
    get DataModels() {
        return this.DataModelsValue
    }
    set DataModels(val) {
        this.DataModelsValue = val;
        this.DataModelsChange.emit(val);
    }
    @Output() DataModelsChange = new EventEmitter<any>();

    @Output() UseForStateChecked = new EventEmitter<any>();
    @Output() Deleted = new EventEmitter<any>();
    @Output() ShowFormulaClicked = new EventEmitter<any>();

    constructor(private dataService: DataService) {
    }

    ngOnInit(): void {
        if (this.DataValue) {
            if (this.DataValue.DataSource) {
                if (this.StaticTablesValue.some(x => x.Value === this.DataValue.DataSource)) {
                    this.StaticDataSource = true;
                    this.fillColumns();
                } else if (this.DataValue.DataModelID) {
                    this.dataService.GetItem('dynamic', 'GetTablesByDataModel', this.DataValue.DataModelID).subscribe((dataSources) => {
                        this.DataSources = dataSources;
                        if (dataSources.some(x => x.SID === this.DataValue.DataSource)) {
                            this.fillColumns();
                        } else {
                            this.DataValue.DataSource = null;
                        }
                    });
                }
            }
        }
    }

    staticChange() {
        this.Columns = [];
        if (this.DataValue) {
            this.DataValue.DataSource = null;
        }
    }

    modelSelected() {
        this.Columns = [];
        if (this.DataValue) {
            this.DataValue.DataSource = null;
        }
        this.DataSources = [];
        this.dataService.GetItem('dynamic', 'GetTablesByDataModel', this.DataValue.DataModelID).subscribe((dataSources) => {
            this.DataSources = dataSources;
        });
    }

    readTableProperties() {
        this.Columns = [];
        this.fillColumns();
    }

    fillColumns() {
        if (this.DataValue && this.DataValue.DataSource) {
            CacheService.ReadFlatTable(this.DataValue.DataSource, false).then((data) => {
                if (data) {
                    const cols = data['Columns'];
                    if (cols) {
                        this.Columns = cols;
                    }
                }
            });
        }
    }

    addValue() {
        if (this.DataValue) {
            if (this.DataValue.DefaultProps) {
                this.DataValue.DefaultProps.push(new DefaultProp());
            } else {
                this.DataValue.DefaultProps = [new DefaultProp()];
            }
        }
    }

    propSelected(prop, item) {
        prop.Name = item.Name;
    }

    removeValue(i) {
        if (this.DataValue && this.DataValue.DefaultProps) {
            this.DataValue.DefaultProps.splice(i, 1);
        }
    }

    useForStateChecked(ev) {
        if (ev && ev.checked) {
            this.UseForStateChecked.emit();
        }
    }

    remove() {
        this.Deleted.emit();
    }

    showFormulaDialog(val) {
        this.ShowFormulaClicked.emit(val);
    }
}

// @dynamic
export class DefineObjectsSettingsData extends AServiceWorkflowData {
    @Type(() => ObjectSetting)
    Objects: ObjectSetting[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.DefineObject.DefineObjectsSettingsData';
    }
}

// @dynamic
export class ObjectSetting {
    DataModelID: string;
    DataSource: string;
    @Type(() => DefaultProp)
    DefaultProps: DefaultProp[] = [];
    StatusKey: string;
    UseForState = false;
}

export class DefaultProp {
    Name: string;
    Formula: string;
}

export class DefineObjectsSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new DefineObjectsSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Objects) {
                const list = [];
                settings.Objects.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class DefineObjectsModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Objects) {
                for (let i = 0; i < status.ActualSettings.Objects.length; i++) {
                    const objSetting = status.ActualSettings.Objects[i];
                    const obj = {};
                    if (objSetting.DataSource) {
                        await AddDataWFModuleExecuter.fillProperties(obj, { AdvancedType: objSetting.DataSource });
                    }
                    if (objSetting.DefaultProps) {
                        const calculator = new WorkflowFormulaCalculator(status);
                        objSetting.DefaultProps.forEach(x => {
                            let val = obj;
                            const propList = x.Name.split('.');
                            for (let i = 0; i < propList.length - 1; i++) {
                                val = val[propList[i]];
                                if (typeof val !== 'object') {
                                    propList.splice(-1, 1);
                                    status.Logger.logWarning('DefineObjects modul: Property ' + propList.join('.') + ' not found.');
                                    return;
                                }
                            }
                            try {
                                val[propList[propList.length - 1]] = calculator.CalcFormula(x.Formula);
                            } catch (ex) {
                                status.Logger.logWarning('DefineObjects modul: Error while calculating value for property ' +
                                    x.Name + ' (' + ex + ')');
                            }
                        });
                    }
                    if (objSetting.StatusKey) {
                        status.Context.set(objSetting.StatusKey, obj);
                    }
                    if (objSetting.UseForState) {
                        status.ActualParameter = obj;
                    }
                }
            }
            return 0;
        } else {
            status.Logger.logError('DefineObjects modul: No settings found.');
        }
        return super.execute(status);
    }
}
