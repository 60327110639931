import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'indeterminate-checkbox',
    templateUrl: './indeterminate.checkbox.html'
})
export class IndeterminateCheckbox {
    //#region Checked
    CheckedValue: boolean;
    @Input()
    get Checked(): boolean {
        return this.CheckedValue;
    }
    set Checked(val: boolean) {
        this.CheckedValue = val;
        this.CheckedChange.emit(this.CheckedValue);
    }

    @Output() CheckedChange = new EventEmitter<any>();
    //#endregion

    //#region Caption
    CaptionValue: string;
    @Input()
    get Caption(): string {
        return this.CaptionValue;
    }
    set Caption(val: string) {
        this.CaptionValue = val;
        this.CaptionChange.emit(this.CaptionValue);
    }

    @Output() CaptionChange = new EventEmitter<any>();
    //#endregion

    onChange() {
        if (this.CheckedValue === true) {
            this.Checked = false;
        } else if (this.CheckedValue === false) {
            this.Checked = null;
        } else {
            this.Checked = true;
        }
    }
}
