import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../../helpers/array.helpers';
import { NotificationHelper } from '../../../../../helpers/notification.helper';
import { WebHookService } from '../../../../../services/webhook.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../../../base.list.settings';
import { WebhookRestDetailComponent } from './webhook.rest.detail';

@Component({
    selector: 'webhook-rest',
    templateUrl: '../../../../base.list.settings.html'
})
export class WebhookRestComponent extends BaseListSettings{

    constructor(private _service: WebHookService, private _translate: TranslateService, factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        //this._service.GetAllWithRestID().subscribe(result => {
        //    if (result) {
        //        const list = [];
        //        result.forEach(r => list.push({
        //            Caption: r.Caption,
        //            Value: r.SID
        //        }));
        //        handler(list);
        //    }
        //});
        this._service.GetAllWithRestID(this.InitArgs.SID).subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r => list.push({
                    Caption: r.Caption,
                    ID: r.SID
                }));
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.GetByID(data).subscribe(r => {
            if (r) {
                this.setSelectedItem(r);
            }
        });
    }

    getNewItem() {
        const item = {
            Name: 'neuerwebhookpath',
            RestID: this.InitArgs.SID,
            IsNew : true
        };
        return item;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Webhook \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@v \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Webhook loeschen');
        return retVal;
    }

    delete(id: any, handler: any) {
        this._service.Delete(id).subscribe(() => handler());
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Webhook \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Webhook speichern');
        return retVal;
    }

    saveInternal(item: any, handler: any) {
        const isNew = item.IsNew;
        this._service.Save(item).subscribe(r => {
            if (r) {
                if (isNew) {
                    NotificationHelper.Success('@@Webhookkey:' + r['Key'], '@@Webhooksetting has been successfully saved');
                }
                handler(r, r._Id, r.Name);
            }
        });
    }

    getContentType() {
        return WebhookRestDetailComponent;
    }



}