import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { serialize } from 'class-transformer';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { SelfServiceObjectService } from '../../../services/datamodel.service';
import { BaseDialog } from '../basedialog/base.dialog';

@Component({
    selector: 'object-preview-dialog',
    templateUrl: './object.preview.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectPreviewDialog {
    Result = '';
    Loading = true;
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    static ShowDialog(initArgs, title) {
        BaseDialog.ShowDialog({
            ContentType: ObjectPreviewDialog,
            InitArgs: initArgs,
            Title: title,
            Buttons: DialogButton.Ok,
            HeightText: '100vh',
            WidthText: '100vw'
        });
    }

    static ShowContainerPreview(containerID, title) {
        ObjectPreviewDialog.ShowDialog({
            UseTable: true,
            ContainerTable: containerID
        }, title);
    }

    constructor(private preview: SelfServiceObjectService, private cdRef: ChangeDetectorRef) {
    }

    Initialize(args) {
        if (args) {
            if (args.UseTable) {
                let clone;
                if (args.Filter) {
                    clone = JSON.parse(JSON.stringify(args.Filter));
                } else {
                    clone = new RequestOptions();
                }
                clone.Top = 100;
                this.preview.ExecuteObjectQuery(args.ContainerTable, clone).subscribe((result) => {
                    if (result && result.length > 0) {
                        const list = [];
                        result.forEach(r => {
                            list.push(JSON.stringify(r, null, 4));
                        });
                        this.Result = list.join('\n\n\t----------\n\n');
                    }
                    this.Loading = false;
                    this.cdRef.detectChanges();
                });
            } else {
                if (args.Settings) {
                    if (args.Settings.Fields) {
                        const fieldList = [];
                        args.Settings.Fields.forEach((field) => {
                            const parsed = JSON.parse(field);
                            fieldList.push(parsed);
                        });
                        args.Settings.Fields = fieldList;
                    }
                    if (!args.Settings.Name) {
                        args.Settings.Name = '';
                    }
                    if (!args.Settings.Methodtype) {
                        args.Settings.Methodtype = 0;
                    }
                }
                this.preview.GetObjectPreview(args.DataSource, serialize(args.Settings)).subscribe((result) => {
                    if (result) {
                        const list = [];
                        result.forEach(r => {
                            list.push(JSON.stringify(r, null, 4));
                        });
                        this.Result = list.join(',\n');
                    }
                    this.Loading = false;
                    this.cdRef.detectChanges();
                });
            }
        }
    }
}
