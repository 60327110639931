import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild,
    Directive, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, TemplateRef
} from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { RTLHelper } from '../../../helpers/rtl.helper';
import { DropState } from '../../../models/enums/dropstate.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxHelper} from '../../dialogs/messagebox/messagebox.dialog';

export interface IBaseTreeNode {
    ID: number;
    Checkable: boolean;
    Draggable: boolean;
    Selectable: boolean;
    Icon: string;
    Caption: string;
    TranslateCaption: string;
    ToolTip: string;
    IsExpanded: boolean;
    HasChildren: boolean;
    Children: IBaseTreeNode[];
}

export class ABaseTreeNode implements IBaseTreeNode {
    ID: number;
    Checkable = false;
    Draggable = false;
    Selectable = true;
    Icon: string;
    Caption: string;
    TranslateCaption: string;
    ToolTip: string;
    IsExpanded = false;
    HasChildren = false;
    Children: IBaseTreeNode[];

    constructor(id: number) {
        this.ID = id;
    }
}

export class IDKeeper {
    private ID = 0;
    get NextID() { return this.ID++; }
    private resetID = 0;

    constructor(startID?: number) {
        if (typeof startID === 'number') {
            this.resetID = startID;
            this.reset();
        }
    }

    reset() {
        this.ID = this.resetID;
    }
}

export class TreeNode {
    private IsSelectedValue = false;
    IsLoading = false;
    Children: TreeNode[];
    private InternalParent: TreeNode;
    private level = 0;
    private InternalDropState: DropState = DropState.None;
    private DropStyle = {};

    constructor(private node: IBaseTreeNode, parentNode: TreeNode) {
        this.Parent = parentNode;
    }

    get OriginalNode(): IBaseTreeNode { return this.node; }
    get Level(): number { return this.level; }
    get ID(): number { return this.node.ID; }
    get Checkable(): boolean { return this.node.Checkable; }
    get Draggable(): boolean { return this.node.Draggable; }
    get Selectable(): boolean { return this.node.Selectable; }
    get Icon(): string { return this.node.Icon; }
    get Caption(): string { return this.node.Caption; }
    get TranslateCaption(): string { return this.node.TranslateCaption; }
    get ToolTip(): string { return this.node.ToolTip; }
    get Expandable(): boolean { return this.node.HasChildren; }

    get IsExpanded(): boolean { return this.node.HasChildren && this.node.IsExpanded; }
    set IsExpanded(val: boolean) { this.node.IsExpanded = val; }

    get IsSelected(): boolean { return this.IsSelectedValue; }
    set IsSelected(val: boolean) {
        if (this.Selectable) {
            this.IsSelectedValue = val;
        }
    }

    get DropState(): DropState { return this.InternalDropState; }
    set DropState(val: DropState) {
        this.InternalDropState = val;
        const style = {};
        switch (val) {
            case DropState.After:
                style['border-bottom'] = '3px solid var(--primary-color)';
                break;
            case DropState.Before:
                style['border-top'] = '3px solid var(--primary-color)';
                break;
            case DropState.Inner:
                style['background-color'] = 'var(--primary-color)';
                break;
        }
        this.DropStyle = style;
    }

    get Parent(): TreeNode {
        return this.InternalParent;
    }
    set Parent(val: TreeNode) {
        this.InternalParent = val;
        if (val) {
            this.level = val.Level + 1;
        } else {
            this.level = 0;
        }
    }
}


export interface ITreeLogic {
    loadChildren(node: IBaseTreeNode): Promise<IBaseTreeNode[]>;
}

export interface IDropChecker {
    checkCanDrop(dropArgs);
}

@Injectable()
export class TreeNodeDataSource {

    dataChange = new BehaviorSubject<TreeNode[]>([]);
    handlingModelChange = false;

    get data(): TreeNode[] { return this.dataChange.value; }

    LogicValue: ITreeLogic;
    @Input()
    get Logic(): ITreeLogic {
        return this.LogicValue;
    }
    set Logic(val) {
        this.LogicValue = val;
    }

    private OrigRootNodesValue: IBaseTreeNode[] = [];
    private RootNodesValue: TreeNode[] = [];
    get RootNodes(): TreeNode[] {
        return this.RootNodesValue;
    }

    private static generateChildTreeNodes(tn: TreeNode, level: number, expandedNodes: TreeNode[]): TreeNode[] {
        if (tn) {
            const orig = tn.OriginalNode;
            if (orig && orig.HasChildren && orig.IsExpanded) {
                expandedNodes.push(tn);
                const retVal = [];
                tn.Children = [];
                orig.Children.forEach(n => {
                    const child = new TreeNode(n, tn);
                    tn.Children.push(child);
                    retVal.push(child);
                    const childChilds = TreeNodeDataSource.generateChildTreeNodes(child, level + 1, expandedNodes);
                    if (childChilds) {
                        retVal.push(...childChilds);
                    }
                });
                return retVal;
            }
        }
        return null;
    }

    private static getAllExpandedNodes(tnList: TreeNode[], expandedNodes: TreeNode[]): TreeNode[] {
        const retVal = [];
        tnList.forEach(tn => {
            retVal.push(tn);
            if (tn.IsExpanded) {
                expandedNodes.push(tn);
                const childList = TreeNodeDataSource.getAllExpandedNodes(tn.Children, expandedNodes);
                retVal.push(...childList);
            }
        });
        return retVal;
    }

    constructor(private treeControl: FlatTreeControl<TreeNode>, private cdRef: ChangeDetectorRef) { }

    init(rootNodes: IBaseTreeNode[]) {
        const value = [];
        const rootNodesValue = [];
        const expanded = [];
        rootNodes.forEach(n => {
            const tn = new TreeNode(n, null);
            value.push(tn);
            rootNodesValue.push(tn);
            const childs = TreeNodeDataSource.generateChildTreeNodes(tn, 1, expanded);
            if (childs) {
                value.push(...childs);
            }
        });
        this.treeControl.dataNodes = value;
        this.handlingModelChange = true;
        this.treeControl.expansionModel.clear();
        this.treeControl.expansionModel.select(...expanded);
        this.handlingModelChange = false;
        this.dataChange.next(value);
        this.RootNodesValue = rootNodesValue;
        this.OrigRootNodesValue = rootNodes;
    }

    connect(collectionViewer: CollectionViewer): Observable<TreeNode[]> {
        this.treeControl.expansionModel.changed.subscribe(change => {
            if (!this.handlingModelChange) {
                this.handlingModelChange = true;
                this.handleExpansionChange(change as SelectionChange<TreeNode>);
                this.handlingModelChange = false;
            }
        });
        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    async handleExpansionChange(change: SelectionChange<TreeNode>) {
        if (change.added) {
            const newList = change.added.slice();
            for (let i = 0; i < newList.length; i++) {
                await this.toggleNode(newList[i], true);
            }
        }
        if (change.removed) {
            const newList = change.removed.slice();
            for (let i = newList.length - 1; i >= 0; i--) {
                await this.toggleNode(newList[i], false);
            }
        }
        this.cdRef.detectChanges();
    }

    /**
     * Toggle the node, remove from display list
     */
    async toggleNode(node: TreeNode, expand: boolean) {
        const index = this.data.indexOf(node);
        if (!node.Expandable || index < 0) { // If no children, or cannot find the node, no op
            return;
        }

        node.IsLoading = true;

        if (expand) {
            const allNodes = [];
            if (node.Children) {
                const expanded = [];
                allNodes.push(...TreeNodeDataSource.getAllExpandedNodes(node.Children, expanded));
                this.treeControl.expansionModel.select(...expanded);
            } else {
                const children = [];
                if (node.OriginalNode.Children) {
                    const expanded = [];
                    node.OriginalNode.Children.forEach(cn => {
                        const tn = new TreeNode(cn, node);
                        children.push(tn);
                        allNodes.push(tn);
                        const childs = TreeNodeDataSource.generateChildTreeNodes(tn, tn.Level + 1, expanded);
                        if (childs) {
                            allNodes.push(...childs);
                        }
                    });
                    this.treeControl.expansionModel.select(...expanded);
                } else if (this.LogicValue) {
                    const childNodes = await this.LogicValue.loadChildren(node.OriginalNode);
                    if (childNodes) {
                        childNodes.forEach(cn => {
                            children.push(new TreeNode(cn, node));
                        });
                        allNodes.push(...children);
                    }
                }
                node.Children = children;
            }
            this.data.splice(index + 1, 0, ...allNodes);
        } else {
            let count = 0;
            for (let i = index + 1; i < this.data.length
                && this.data[i].Level > node.Level; i++ , count++) { }
            this.data.splice(index + 1, count);
        }
        node.IsExpanded = expand;
        // notify the change
        this.dataChange.next(this.data);
        node.IsLoading = false;
    }
}

@Directive({
    selector: '[nodeContent]'
})
export class NodeContentDirective { }

@Component({
    selector: 'base-tree-control',
    templateUrl: './base.tree.control.html',
    styleUrls: ['./base.tree.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseTreeControl implements OnInit, OnDestroy {
    treeControlValue: FlatTreeControl<TreeNode>;
    @Input()
    get treeControl(): FlatTreeControl<TreeNode> {
        return this.treeControlValue;
    }
    set treeControl(val) {
        this.treeControlValue = val;
        this.treeControlChange.emit(val);
    }
    @Output() treeControlChange = new EventEmitter<any>();

    dataSource: TreeNodeDataSource;
    checklistSelection = new SelectionModel<TreeNode>(true);
    dragState;

    @Output() treeControlSet = new EventEmitter<BaseTreeControl>();

    RootNodesValue: IBaseTreeNode[];
    @Input()
    get RootNodes(): IBaseTreeNode[] {
        return this.RootNodesValue;
    }
    set RootNodes(val) {
        if (val !== this.RootNodesValue) {
            this.RootNodesValue = val;
            this.SelectedNodeValue = null;
            this.dataSource.init(val);
            this.RootNodesChange.emit(val);
        }
    }
    @Output() RootNodesChange = new EventEmitter<any>();

    DragTypeValue: string;
    @Input()
    get DragType(): string {
        return this.DragTypeValue;
    }
    set DragType(val) {
        this.DragTypeValue = val;
        this.DragTypeChange.emit(val);
    }
    @Output() DragTypeChange = new EventEmitter<any>();

    InternalDragValue: boolean;
    @Input()
    get InternalDrag(): boolean {
        return this.InternalDragValue;
    }
    set InternalDrag(val) {
        this.InternalDragValue = val;
        this.InternalDragChange.emit(val);
    }
    @Output() InternalDragChange = new EventEmitter<any>();

    DragOnIconValue: boolean;
    @Input()
    get DragOnIcon(): boolean {
        return this.DragOnIconValue;
    }
    set DragOnIcon(val) {
        this.DragOnIconValue = val;
        this.DragOnIconChange.emit(val);
    }
    @Output() DragOnIconChange = new EventEmitter<any>();

    InternalDropCheckerValue: IDropChecker;
    @Input()
    get InternalDropChecker(): IDropChecker {
        return this.InternalDropCheckerValue;
    }
    set InternalDropChecker(val) {
        this.InternalDropCheckerValue = val;
    }

    @Input()
    get Logic(): ITreeLogic {
        return this.dataSource.Logic;
    }
    set Logic(val) {
        this.dataSource.Logic = val;
    }

    MultipleSelectionValue = false;
    @Input()
    get MultipleSelection(): boolean {
        return this.MultipleSelectionValue;
    }
    set MultipleSelection(val) {
        this.MultipleSelectionValue = val;
    }

    SelectedNodeValue: IBaseTreeNode;
    @Input()
    get SelectedNode(): IBaseTreeNode {
        return this.SelectedNodeValue;
    }
    set SelectedNode(val) {
        if (val !== this.SelectedNodeValue) {
            this.SelectedNodeValue = val;
            BaseTreeControl.executeOnAllNodes(this.dataSource.RootNodes, (tn) => {
                tn.IsSelected = tn.OriginalNode == val;
            });
            this.SelectedNodeChange.emit(val);
        }
    }
    @Output() SelectedNodeChange = new EventEmitter<any>();

    SelectedNodesValue: IBaseTreeNode[];
    @Input()
    get SelectedNodes(): IBaseTreeNode[] {
        return this.SelectedNodesValue;
    }
    set SelectedNodes(val) {
        if (val !== this.SelectedNodesValue) {
            this.SelectedNodesValue = val;
            BaseTreeControl.executeOnAllNodes(this.dataSource.RootNodes, (tn) => {
                tn.IsSelected = val.some((value) => tn.OriginalNode == value);
            });
            this.SelectedNodesChange.emit(val);
        }
    }
    @Output() SelectedNodesChange = new EventEmitter<any>();

    @ContentChild(NodeContentDirective, { read: TemplateRef }) nodeContentTemplate;

    @Output() SelectionChanged = new EventEmitter<any>();
    @Output() CheckedChanged = new EventEmitter<any>();
    @Output() DragStart = new EventEmitter<any>();
    @Output() DragEnd = new EventEmitter<any>();
    @Output() InternalDrop = new EventEmitter<any>();
    @Output() DoubleClick = new EventEmitter<any>();

    IsRtl = false;
    Subscriptions = [];

    private static executeOnAllNodes(nodes: TreeNode[], func: (n: TreeNode) => void) {
        if (nodes && func) {
            nodes.forEach(n => {
                func(n);
                BaseTreeControl.executeOnAllNodes(n.Children, func);
            });
        }
    }

    constructor(public cdRef: ChangeDetectorRef) {
        this.treeControl = new FlatTreeControl<TreeNode>((n) => n.Level, (n) => n.Expandable);
        this.dataSource = new TreeNodeDataSource(this.treeControl, this.cdRef);
    }
    ngOnInit(): void {
        this.treeControlSet.emit(this);
        this.IsRtl = RTLHelper.Direction == 'rtl';
        this.Subscriptions.push(RTLHelper.DirectionChanged.subscribe((dir) => {
            this.IsRtl = dir == 'rtl';
            this.cdRef.detectChanges();
        }));
    }
    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

    hasChild = (_: number, nodeData: TreeNode) => nodeData.Expandable;
    nodeClicked(ev: MouseEvent, node: TreeNode) {
        if (node.Selectable) {
            const selNodes = [];
            if (ev && ev.ctrlKey && this.MultipleSelectionValue) {
                node.IsSelected = !node.IsSelected;
                BaseTreeControl.executeOnAllNodes(this.dataSource.RootNodes, (tn) => {
                    if (tn.IsSelected) {
                        selNodes.push(tn.OriginalNode);
                    }
                });
                if (this.SelectedNodeValue) {
                    this.SelectedNodeValue = null;
                    this.SelectedNodeChange.emit(null);
                }
            } else {
                BaseTreeControl.executeOnAllNodes(this.dataSource.RootNodes, (tn) => { tn.IsSelected = false; });
                node.IsSelected = true;
                selNodes.push(node.OriginalNode);
                this.SelectedNode = node.OriginalNode;
            }
            this.SelectedNodes = selNodes;
            this.SelectionChanged.emit(selNodes);
        }
    }

    dragStart(ev) {
        const dragContext = {
            Caption: '',
            DraggedTreeNodes: [],
            DragData: [],
            CanDrop: true,
            DropInfoText: null,
            Event: ev,
            DropNode: null
        };
        const captions = [];
        this.treeControl.dataNodes.forEach(dn => {
            if (dn.IsSelected && dn.Draggable) {
                captions.push(dn.Caption);
                dragContext.DraggedTreeNodes.push(dn);
                dragContext.DragData.push(dn.OriginalNode);
            }
        });
        dragContext.Caption = captions.join(', ');
        this.dragState = dragContext;
        ev.dataTransfer.setData("context", dragContext);
        this.DragStart.emit(dragContext);
    }

    dragEnd(ev) {
        this.dragState = null;
        this.DragEnd.emit(ev);
    }

    async dragOver(ev, node) {
        node.DropState = DropState.None;
        const ds = this.dragState;
        if (ds) {
            const oldDropNode = ds.DropNode;
            ds.DropNode = node;
            const nodeChanged = oldDropNode !== node;
            if (oldDropNode && nodeChanged) {
                oldDropNode.DropState = DropState.None;
            }
            if (ev.currentTarget) {
                if (!ev.currentTarget.DropHeight) {
                    ev.currentTarget.DropHeight = ev.currentTarget.offsetHeight;
                }
                const pos = ev.offsetY / ev.currentTarget.DropHeight;
                let target = null;
                if (ds.DropNode.Parent) {
                    target = ds.DropNode.Parent.OriginalNode;
                }
                if (pos <= 0.25) {
                    node.DropState = DropState.Before;
                } else if (pos >= 0.75) {
                    node.DropState = DropState.After;
                } else {
                    node.DropState = DropState.Inner;
                    target = ds.DropNode.OriginalNode;
                }
                if (ds.DraggedTreeNodes.some(x => x === node)) {
                    return;
                }
                if (node.Expandable && !node.OriginalNode.Children && this.Logic) {
                    return;
                }
                if (this.InternalDropCheckerValue) {
                    const dropState = {
                        DragState: ds,
                        Target: target,
                        Cancel: false
                    };
                    await this.InternalDropCheckerValue.checkCanDrop(dropState);
                    if (dropState.Cancel) {
                        return;
                    }
                }
            }
        }
    }

    onInternalDrop(ev) {
        if (this.dragState) {
            if (this.dragState.CanDrop === false) {
                if (this.dragState.DropInfoText !== null) {
                    MessageBoxHelper.ShowDialog(this.dragState.DropInfoText, new TranslateFormatText('@@Drop'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                }
            } else {
                const dropNode = this.dragState.DropNode;
                if (dropNode) {
                    if (ev && dropNode.DropState !== DropState.None) {
                        const dropInfo = {
                            DropTarget: null,
                            DropIndex: 0,
                            DroppedNodes: []
                        };
                        let dropList;
                        let dropIndex;
                        if (dropNode.DropState === DropState.Inner) {
                            dropNode.OriginalNode.IsExpanded = true;
                            dropNode.OriginalNode.HasChildren = true;
                            if (!dropNode.OriginalNode.Children) {
                                dropNode.OriginalNode.Children = [];
                            }
                            dropInfo.DropTarget = dropNode.OriginalNode;
                            dropList = dropNode.OriginalNode.Children;
                            dropIndex = dropNode.OriginalNode.Children.length;
                        } else {
                            dropList = this.RootNodesValue;
                            if (dropNode.Parent) {
                                dropInfo.DropTarget = dropNode.Parent.OriginalNode;
                                dropList = dropNode.Parent.OriginalNode.Children;
                            }
                            dropIndex = dropList.indexOf(dropNode.OriginalNode);
                            if (dropNode.DropState === DropState.After) {
                                dropIndex++;
                            }
                        }
                        dropInfo.DropIndex = dropIndex;
                        const droppedNodes = [];
                        const spliceIndices = [];
                        this.dragState.DraggedTreeNodes.forEach(dragNode => {
                            const dropNodeInfo = {
                                Node: dragNode.OriginalNode,
                                RemovedFrom: null
                            };
                            let dragList = this.RootNodesValue;
                            if (dragNode.Parent) {
                                dropNodeInfo.RemovedFrom = dragNode.Parent.OriginalNode;
                                dragList = dragNode.Parent.OriginalNode.Children;
                            }
                            const origIndex = dragList.indexOf(dragNode.OriginalNode);
                            if (origIndex >= 0) {
                                if (dragList === dropList) {
                                    spliceIndices.push(origIndex);
                                    if (origIndex < dropIndex) {
                                        dropInfo.DropIndex--;
                                    }
                                } else {
                                    dragList.splice(origIndex, 1);
                                    if (dragNode.Parent) {
                                        dragNode.Parent.OriginalNode.HasChildren = dragList.length > 0;
                                    }
                                }
                            }
                            droppedNodes.push(dragNode.OriginalNode);
                            dropInfo.DroppedNodes.push(dropNodeInfo);
                        });
                        spliceIndices.sort((a, b) => b - a);
                        spliceIndices.forEach(x => {
                            dropList.splice(x, 1);
                        });
                        dropList.splice(dropInfo.DropIndex, 0, ...droppedNodes);
                        const selNode = this.SelectedNodeValue;
                        this.RootNodes = this.RootNodesValue.slice();
                        if (!this.MultipleSelectionValue && selNode) {
                            BaseTreeControl.executeOnAllNodes(this.dataSource.RootNodes, (tn) => {
                                tn.IsSelected = tn.OriginalNode === selNode;
                            });
                        }
                        this.InternalDrop.emit(dropInfo);
                    }
                    dropNode.DropState = DropState.None;
                }
            }
            this.dragState = null;
        }
    }



    onDoubleClick() {
        if (!this.MultipleSelection) {
            this.DoubleClick.emit(this.SelectedNodeValue);
        }
    }

    //#region Selection
    todoItemSelectionToggle(node: TreeNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
        this.onCheckedChanged();
    }

    todoLeafItemSelectionToggle(node: TreeNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
        this.onCheckedChanged();
    }

    onCheckedChanged() {
        const selection = [];
        this.checklistSelection.selected.forEach(x => {
            selection.push(x.OriginalNode);
        });
        this.CheckedChanged.emit(selection);
    }

    checkAllParentsSelection(node: TreeNode): void {
        let parent: TreeNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    checkRootNodeSelection(node: TreeNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TreeNode): TreeNode | null {
        const currentLevel = node.Level;

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (currentNode.Level < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    descendantsAllSelected(node: TreeNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.length === 0) {
            return false;
        }
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    descendantsPartiallySelected(node: TreeNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        if (descendants.length === 0) {
            return false;
        }
        let selected = false;
        let deselected = false;
        descendants.some(child => {
            if (this.checklistSelection.isSelected(child)) {
                selected = true;
            } else {
                deselected = true;
            }
            return selected && deselected;
        });
        return selected && deselected;
    }
    //#endregion
}
