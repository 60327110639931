import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { FormulaEditorDialog } from './formulaEditor.control';

@Component({
    selector: 'formula-textbox',
    templateUrl: './formulatextbox.control.html',
    styleUrls: ['./formulaEditor.control.css']
})
export class FormulaTextBox {

    Mentions = [];
    GlobalMentions = [];

    //#region Formula
    FormulaValue;

    @Input()
    get Formula() {
        return this.FormulaValue;
    }
    set Formula(val) {
        this.FormulaValue = val;
        this.FormulaChange.emit(this.FormulaValue);
    }

    @Output() FormulaChange = new EventEmitter<any>();
    //#endregion
    //#region Variables
    VariablesValue = [];

    @Input()
    get Variables() {
        return this.VariablesValue;
    }
    set Variables(val) {
        if (val) {
            this.VariablesValue = val;
            this.VariablesChange.emit(this.VariablesValue);
        }
    }

    @Output() VariablesChange = new EventEmitter<any>();
    //#endregion
    //#region ControlStyle
    ControlStyleValue = 1;

    @Input()
    get ControlStyle() {
        return this.ControlStyleValue;
    }
    set ControlStyle(val) {
        this.ControlStyleValue = val;
        this.ControlStyleChange.emit(this.ControlStyleValue);
    }

    @Output() ControlStyleChange = new EventEmitter<any>();
    //#endregion
    //#region SystemVariables
    SystemVariablesValue = [];

    @Input()
    get SystemVariables() {
        return this.SystemVariablesValue;
    }
    set SystemVariables(val) {
        if (val) {
            this.SystemVariablesValue = val;
            const list = [];
            if (Array.isArray(val)) {
                val.forEach(sv => {
                    list.push(sv.Name);
                });
            }
            this.updateMentions(list);
            this.SystemVariablesChange.emit(this.SystemVariablesValue);
        }
    }

    @Output() SystemVariablesChange = new EventEmitter<any>();
    //#endregion
    //#region PlaceHolder
    PlaceHolderValue = '@@Formel';

    @Input()
    get PlaceHolder() {
        return this.PlaceHolderValue;
    }
    set PlaceHolder(val) {
        this.PlaceHolderValue = val;
        this.PlaceHolderChange.emit(this.PlaceHolderValue);
    }

    @Output() PlaceHolderChange = new EventEmitter<any>();
    //#endregion

    //#region InputMode
    InputModeValue = FormulaInputMode.VariableName;

    @Input()
    get InputMode() {
        return this.InputModeValue;
    }
    set InputMode(val) {
        this.InputModeValue = val;
        this.InputModeChange.emit(this.InputModeValue);
    }

    @Output() InputModeChange = new EventEmitter<any>();
    //#endregion

    //#region InputMode
    FormulaGroupValue = FormulaGroupEnum.All;

    @Input()
    get FormulaGroup() {
        return this.FormulaGroupValue;
    }
    set FormulaGroup(val) {
        this.FormulaGroupValue = val;
        this.FormulaGroupChange.emit(this.FormulaGroupValue);
    }

    @Output() FormulaGroupChange = new EventEmitter<any>();
    //#endregion

    @Output() FormulaChanged = new EventEmitter<any>();

    constructor() {
        const globalVariables = CacheService.GlobalVariablesValues.getValue();
        if (globalVariables && globalVariables.length > 0) {
            globalVariables.forEach(sv => {
                this.GlobalMentions.push(sv.Name);
            });
            this.updateMentions([]);
        }
    }

    updateMentions(systemVariables) {
        const mentions = [];
        mentions.push(...systemVariables);
        mentions.push(...this.GlobalMentions);
        this.Mentions = mentions;
    }

    showFormulaDialog() {
        const args = {
            Variables: this.Variables,
            SystemVariables: this.SystemVariables,
            InputMode: this.InputModeValue,
            FormulaGroup: this.FormulaGroupValue,
            Formula: this.Formula
        };
        FormulaEditorDialog.ShowDialog(args, result => {
            if (result && result.Formula) {
                this.Formula = result.Formula;
                this.OnFormulaChanged();
            }
            return true;
        });
    }

    OnFormulaChanged() {
        this.FormulaChanged.emit(this.Formula);
    }
}
