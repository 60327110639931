import { CacheService } from '../cache/cache.service';
import { EnumValue } from '../models/enums/enum.value';

export class EnumHelper {
    public static GetDropdownValues(value) {
        let result = [];
        Object.keys(value).slice(0, Object.keys(value).length / 2).forEach((key) => {
            result.push({ type: value[value[key]], value: value[key] })
        });
        return result;
    }
    public static GetSourceValues(value) {
        let result = [];
        Object.keys(value).slice(0, Object.keys(value).length / 2).forEach((key) => {
            result.push({ Value: value[value[key]], Caption: value[key] })
        });
        return result;
    }

    public static GetEnumValues(id) {
        return new Promise<EnumValue[]>(resolve => {
            CacheService.ReadEnumValues(id).then(x => {
                const list = [];
                if (x) {
                    x.forEach(y => {
                        list.push(new EnumValue(y));
                    });
                }
                resolve(list);
            });
        });
    }
}
