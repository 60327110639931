import { WorkflowViewType } from '../../../models/enums/workflowviewtype.enum';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';

export class EndWfModuleData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.EndWfModuleData';
    }
}

export class EndWfModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        return 0;
    }
}

export class EndWfModuleHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'endWFModule';
        reg.Caption = '@@End';
        reg.SettingsTypeHelper = new EndWfModuleHelper();
        reg.Executer = EndWfModuleExecuter;
        reg.ViewType = WorkflowViewType.End;
        reg.Width = 50;
        reg.Height = 50;
        return reg;
    }
   
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [];
    }

    getEmptySettingsInstance() {
        return new EndWfModuleData();
    }
}
