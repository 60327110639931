import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'fileexplorer-dialog',
    templateUrl: 'fileexplorer.dialog.html',
    styleUrls: ['./fileexplorer.dialog.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileExplorerDialog {
    MediaSourceID;
    Selection = null;
    ImageInstance;
    FixedPath;

    constructor(private dialogRef: MatDialogRef<FileExplorerDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.MediaSourceID = data.MediaSourceID;
            this.ImageInstance = data.ImageInstance;
            this.FixedPath = data.FixedPath;
        }
    }

    okClicked() {
        this.dialogRef.close(this.Selection);
    }

    cancelClicked() {
        this.dialogRef.close();
    }

    onFileUploaded(ev) {
        if (this.ImageInstance && typeof this.ImageInstance.onFileUploaded === 'function') {
            this.ImageInstance.onFileUploaded(ev);
        }
    }
}
