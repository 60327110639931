import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import moment from 'moment';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'set-multi-elem-value-settings',
    templateUrl: './set.multi.elem.value.settings.html'
})
export class SetMultiElemValueSettings extends WorkflowDialogContent {
    static ModuleID = 'setMultiElemValueWFModule';

    Data = new SetMultiElemValueSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetMultiElemValueSettings.ModuleID;
        reg.Caption = '@@Werte setzen';
        reg.GroupID = 'layout';
        reg.Index = 20;
        reg.SettingsControl = SetMultiElemValueSettings;
        reg.SettingsTypeHelper = new SetMultiElemValueSettingsDataHelper();
        reg.Executer = SetMultiElemValueModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetMultiElemValueSettingsData, json);
        }
    }

    getResult() {
        const elements = [];
        this.Data.Elements.forEach(x => {
            if (x.ElemName && (x.StatusKey || x.GetFromState)) {
                elements.push(x);
            }
        });
        this.Data.Elements = elements;
        return this.Data;
    }

    addElement() {
        if (this.Data) {
            if (this.Data.Elements) {
                this.Data.Elements.push(new SetElemInfo());
            } else {
                this.Data.Elements = [new SetElemInfo()];
            }
        }
    }

    removeElement(i) {
        if (this.Data && this.Data.Elements) {
            this.Data.Elements.splice(i, 1);
        }
    }
}

export class SetElemInfo {
    ElemName: string;
    StatusKey: string;
    GetFromState = false;
}

// @dynamic
export class SetMultiElemValueSettingsData {
    @Type(() => SetElemInfo)
    Elements: SetElemInfo[] = [];
}

export class SetMultiElemValueSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetMultiElemValueSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Elements) {
            settings.Elements.forEach(x => {
                if (x.ElemName === oldName) {
                    x.ElemName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class SetMultiElemValueModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            status.ActualSettings.Elements.forEach(elemInfo => {
                const elem = status.WorkflowLayoutService.getElementByName(elemInfo.ElemName);
                if (elem) {
                    if (elem.Element) {
                        if (elemInfo.GetFromState) {
                            elem.Element.DataSource = status.ActualParameter;
                        } else if (elemInfo.StatusKey) {
                            elem.Element.DataSource = status.Context.get(elemInfo.StatusKey);
                        }
                    } else {
                        status.Logger.logWarning('SetMultiElementValue modul: Control ' + elemInfo.ElemName + ' not found.');
                    }
                } else {
                    status.Logger.logWarning('SetMultiElementValue modul: Element ' + elemInfo.ElemName + ' not found.');
                }
            });
            return 0;
        } else {
            status.Logger.logError('SetMultiElementValue modul: No settings found.');
        }
        return super.execute(status);
    }
}
