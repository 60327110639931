import { Component, OnInit } from "@angular/core";
import { TranslateFormatText } from "../../../helpers/array.helpers";
import { CronHelper, CronObject } from "../../../helpers/cron.helper";
import { DialogButton } from "../../../models/enums/dialogbutton.enum";
import { MessageBoxButtons } from "../../../models/enums/messageboxbuttons.enum";
import { MessageBoxIcon } from "../../../models/enums/messageboxicon.enum";
import { BaseDialog } from "../../dialogs/basedialog/base.dialog";
import { MessageBoxHelper } from "../../dialogs/messagebox/messagebox.dialog";

@Component({
    selector: 'cron-builder-dialog',
    templateUrl: './cron.builder.dialog.html',
    styleUrls: ['./cron.builder.dialog.css']
})
export class CronBuilderDialog implements OnInit {
    Expression = '* * * * * ? *';
    Description;
    CronObject: CronObject;

    ngOnInit(): void {
    }

    Initialize(args) {
        if (args && args.Expression) {
            this.Expression = args.Expression;
        }
        this.CronObject = CronHelper.GetCronObject(this.Expression);
    }

    GetDialogResult() {
        var retVal = {
            Expression: null
        };
        if (this.CronObject && this.CronObject.IsValid()) {
            retVal.Expression = this.CronObject.GetExpression();
        }
        return retVal;
    }

    OnDialogButtonClickAction(button) {
        var retVal = true;
        if (button == DialogButton.Ok) {
            if (this.CronObject) {
                if (!this.CronObject.IsValid()) {
                    retVal = false;
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte korrigieren Sie den Ausdruck.'), new TranslateFormatText('@@Ungueltiger Ausdruck'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                }
            }
        }
        return retVal;
    }

    objChanged(cron: CronObject) {
        this.CronObject = cron;
        this.Description = null;
    }

    showDesc() {
        if (this.CronObject) {
            this.Description = this.CronObject.GetDescription();
        }
    }

    static ShowDialog(exp, handler) {
        let args;
        if (exp) {
            args = {
                Expression: exp
            };
        }
        BaseDialog.ShowDialog({
            ContentType: CronBuilderDialog,
            InitArgs: args,
            Title: '@@CronBuilder',
            Handler: function (r) {
                if (r && handler) {
                    handler(r.Expression);
                }
                return true;
            }
        });
    }
}