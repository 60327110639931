import { WorkflowRegistryHelper } from './workflow.registry';
import { WF_GROUP_REGISTRY } from '../../services/workflow.service';
import { ReadSelectedCoordinatesSettings } from '../../workflow/modules/readselectedcoordinates/read.selected.coordinates.settings';
import { SetQueryViewSettings } from '../../workflow/modules/setqueryview/set.query.view.settings';
import { SetTaskActivity } from '../../workflow/modules/settaskactivity/set.task.activity';

export const MAC_WORKFLOWS = [
    SetQueryViewSettings,
    ReadSelectedCoordinatesSettings,
    SetTaskActivity
];

export class MacWorkflowRegistryHelper {
    public static Build() {
        // Module
        MAC_WORKFLOWS.forEach(wf => {
            if (typeof wf['GetRegistry'] === 'function') {
                WorkflowRegistryHelper.ExternalWorkflowRegistry.push(wf);
            }
        });

        // Gruppen
        WF_GROUP_REGISTRY.push({
            Caption: '@@Report Funktionen',
            GroupID: 'report',
            Index: 40,
            ParentID: null,
            Icon:'wf_group_report'
        });
    }
}
