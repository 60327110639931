import { Component } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { RankTaskExecuter } from '../../../models/tasks/datatasks/rank.model';
import { ALevelMeasurePanel, ALevelMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'rank-task-panel',
    templateUrl: './rank.task.panel.html'
})
export class RankTaskPanel extends ALevelMeasurePanel {
    CompareTypes: any[];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = 'rank';
        reg.Caption = '@@Rang';
        reg.SettingsControl = RankTaskPanel;
        reg.SettingsHelper = new RankSettingsHelper();
        reg.Executer = RankTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }
}

export class RankSettingsHelper extends ALevelMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Rang');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Rang');
    }
}
