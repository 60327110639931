import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'dbinfo-interactive-property-control',
    templateUrl: './dbinfo.interactive.property.control.html'
})
export class DBInfoInteractivePropertyControl implements OnInit {
    DBInfoValue;
    @Input()
    get DBInfo() {
        return this.DBInfoValue;
    }
    set DBInfo(val) {
        this.DBInfoValue = val;
        this.DBInfoChange.emit(this.DBInfoValue);
    }
    @Output() DBInfoChange = new EventEmitter<any>();

    PropertyNameValue: string;
    @Input()
    get PropertyName(): string {
        return this.PropertyNameValue;
    }
    set PropertyName(val: string) {
        this.PropertyNameValue = val;
        this.PropertyNameChange.emit(this.PropertyNameValue);
    }
    @Output() PropertyNameChange = new EventEmitter<any>();

    PropInteractive = false;
    @Output() PropertyInteractiveChanged = new EventEmitter<any>();

    ngOnInit(): void {
        if (this.DBInfoValue && this.DBInfoValue.InteractiveProperties && this.PropertyNameValue) {
            this.PropInteractive = this.DBInfoValue.InteractiveProperties.some(x => x === this.PropertyNameValue);
        }
    }

    onChange() {
        if (this.DBInfoValue && this.PropertyNameValue) {
            if (this.PropInteractive) {
                if (this.DBInfoValue.InteractiveProperties) {
                    if (!this.DBInfoValue.InteractiveProperties.some(x => x === this.PropertyNameValue)) {
                        this.DBInfoValue.InteractiveProperties.push(this.PropertyNameValue);
                        this.PropertyInteractiveChanged.emit();
                    }
                } else {
                    this.DBInfoValue.InteractiveProperties = [this.PropertyNameValue];
                    this.PropertyInteractiveChanged.emit();
                }
            } else if (this.DBInfoValue.InteractiveProperties) {
                const length = this.DBInfoValue.InteractiveProperties.length;
                this.DBInfoValue.InteractiveProperties = this.DBInfoValue.InteractiveProperties.filter(x => x !== this.PropertyNameValue);
                if (length > this.DBInfoValue.InteractiveProperties.length) {
                    this.PropertyInteractiveChanged.emit();
                }
            }
        }
    }
}
