import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { TranslatedString } from '../../../models/translatedstring.model';
import { ContainerEnumService, DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';

@Component({
    selector: 'containerenum-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerEnumSettings extends BaseListSettings {

    constructor(private service: ContainerEnumService, private dataModelService: DataModelService,
        protected fR: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef) {
        super(fR, cdRef);
        this.ShowDelete = PermissionHelper.IsAdmin();
    }

    getContentType() {
        return ContainerEnumDetail;
    }

    loadList(handler) {
        this.Component.DataSources = [];
        if (this.InitArgs) {
            this.service.GetEnumsByDataModel(this.InitArgs.SID).subscribe(result => {
                if (result) {
                    const list = [];
                    result.forEach((e) => {
                        list.push({
                            Caption: e.Caption,
                            ID: e.SID,
                            IsCapsule: e.IsCapsule,
                            IsOverridden: e.IsOverridden
                        });
                    });
                    handler(list);
                }
            });
            const query = new DataSourceQuery(this.InitArgs.SID);
            query.DBUsages = [[4096]]; // RelationalRead
            query.ResourceBase = 0;
            this.dataModelService.GetDataSourcesByQuery(query).subscribe(sources => {
                this.Component.DataSources = sources;
            });
        }
    }

    loadData(data) {
        this.service.GetEnum(data).subscribe(result => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        const ds: any = {};
        const name = 'Enum_';
        let length = 1;
        if (this.ListItems.length > 0) {
            length = this.ListItems.length + 1;
        }
        ds.Name = name + length;
        ds.Fields = [];
        if (this.InitArgs) {
            ds.DataModelID = this.InitArgs.SID;
        }
        const comp = this.Component;
        if (comp && comp.DataSources && comp.DataSources.length > 0) {
            ds.DataSourceId = comp.DataSources[0].SID;
        }
        return ds;
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie das Enum \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Enum \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Enum loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Enum \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@Enum speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteEnum(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        this.service.SaveEnum(item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
        item.InternalID = result.InternalID;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'containerenum-detail',
    templateUrl: './containerenum.settings.html',
    styleUrls: ['./containerenum.settings.css']
})
export class ContainerEnumDetail extends ADataModelDetail {
    DataSources;

    addEnumEntry() {
        if (this.SelectedItem) {
            if (!this.SelectedItem.Fields) {
                this.SelectedItem.Fields = [];
            }
            this.SelectedItem.Fields.push({
                ID: UUID.UUID(),
                Name: 'EnumValue_' + (this.SelectedItem.Fields.length + 1)
            });
            this.OnItemChanged();
        }
    }

    removeEnumEntry(i) {
        if (this.SelectedItem && this.SelectedItem.Fields) {
            this.SelectedItem.Fields.splice(i, 1);
            this.OnItemChanged();
        }
    }

    fieldNameChanged(field) {
        if (field) {
            field.Name = field.Name.replace(/[\W]/gi, '');
            if (field.Caption && !field.Caption.DefaultValue) {
                field.Caption.DefaultValue = field.Name;
            }
            this.OnItemChanged();
        }
    }

    onDrop(ev) {
        if (ev && ev.currentIndex !== ev.previousIndex && this.SelectedItem && this.SelectedItem.Fields) {
            moveItemInArray(this.SelectedItem.Fields, ev.previousIndex, ev.currentIndex);
            this.OnItemChanged();
        }
    }
}
