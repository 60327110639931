import { UUID } from "angular2-uuid";
import { Type } from "class-transformer";
import { Base } from "../../base.model";
import { AxisType, CodeDesc } from "../../enums/query.enum";
import { SpecialElementType } from "../../enums/specialElementType";
import { TranslatedString } from "../../translatedstring.model";

//export class CustomContent {
//  UniqueID: UUID;
//  UniqueKey: string;
//  Caption: string;
//  UnqiueName: string;
//}

//export class PlaningModuleDesignerJump {
//  IsActive: boolean = false;
//  Report: UUID;
//  LinkCube: UUID;
//  OnlyCheckOut: boolean = false;
//}
// @dynamic
export class LevelNode extends Base {
  /// <summary>
  /// Der Level
  /// </summary>
  Level: UUID;
  /// <summary>
  /// Dimension des Levels
  /// </summary>
  DimensionID: UUID;
  /// <summary>
  /// HierarchieID des Levels
  /// </summary>
  HierarchieID: UUID;
  /// <summary>
  /// Caption des Levels, zur Identifikation, falls Level auf Datenbank-Ebene nicht mehr vorhanden
  /// </summary>
  IdentityString: string;


  /// <summary>
  /// Die interne ID.
  /// </summary>
  UniqueID: number = -1;
  /// <summary>
  /// Die zugehörige Achse (X oder Y Achse)
  /// </summary>
  Axis: AxisType;
  /// <summary>
  /// Die Überschrift der LevelInfo
  /// </summary>
  @Type(() => TranslatedString)
  Caption: TranslatedString;
  /// <summary>
  /// Die Attribute die mit abgefragt werden sollen.
  /// </summary>
  Attributes: string[] = [];
  /// <summary>
  /// Wie die Level dargestellt werden soll.
  /// </summary>
  LevelDisplayType: CodeDesc = CodeDesc.Description;
  LevelDisplayAttribute: string;
  /// <summary>
  /// Gibt an, ob die Spalten dieses Levels in einer Tabelle angezeigt werden sollen oder nicht
  /// </summary>
    Hidden: boolean;

    Variable: string = '';

    SpecialElementType: SpecialElementType;
  //@Type(() => PlaningModuleDesignerJump)
  //ModuleDesignerJump: PlaningModuleDesignerJump;
  /// <summary>
  /// Custom Inhalt, falls die LevelNode weder einen CubeLevel noch ein Relationales Feld hat.
  /// </summary>
  //@Type(() => CustomContent)
  //Custom: CustomContent;

  /// <summary>
  /// Die Tiefe der Level in der Datadescription.
  /// </summary>
  //@JsonIgnore
  ///Depth: number;
  

  //@JsonIgnore
  //HeterogenAreaIndex: number;
    

  constructor();
  constructor(axistype?: AxisType) {
    super();
    if (axistype) {
      this.Axis = axistype;
    }
  }

  toString(): string {
    let result = "";
    if (this.Caption) {
      result = TranslatedString.GetTranslation(this.Caption);
    }
    //else if (this.Custom) {
    //  result = this.Custom.Caption;
    //}
    if (result == "" && this.IdentityString) {
      result = this.IdentityString;
    }
    return result;
  }
}
