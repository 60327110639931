export enum BackgroundSize {
    auto,
    contain,
    cover,
    inherit,
    initial,
    unset
}

export enum BackgroundPosition {
    bottom,
    center,
    left,
    right,
    top,
    inherit,
    initial,
    unset
}

export enum BackgroundRepeat {
    'no-repeat',
    repeat,
    'repeat-x',
    'repeat-y',
    round,
    space,
    inherit,
    initial,
    unset
}
