export class NavigationItem {
    Label: string;
    Url: string;
    IsVisible: boolean;
    IsNavigable: boolean;
    Id: string;
    Icon: string;
    children: NavigationItem[];
}

export class NavigationStructureItem {
    Label: string;
    Url: string;
    Icon: string;
    children: NavigationStructureItem[];
}
