import { OnInit, Component, Input, TemplateRef, EventEmitter, Output } from "@angular/core";
import { ContainerSelectDialog } from "../../dialogs/containerSelect.dialog";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { ObjectCreatorSourceMultiPanel } from "./objectcreatorsource.multipanel";
import { Subject } from "rxjs";
import { SpecialElementType } from "../../../models/enums/specialElementType";
import { ABaseTreeNode, IBaseTreeNode } from "../../common/basetreecontrol/base.tree.control";


export class TreeNodeSource extends ABaseTreeNode{
  SID: any;
  type: any;
  ParentID: any;
    DataSourceID: any;
    SpecialElementType: SpecialElementType = SpecialElementType.None;

  constructor(id: number, draggable =true) {
    super(id);
    super.Draggable = draggable;
    }



}


@Component({
    selector: 'objectcreatorsourcetreebase',
    templateUrl: './objectcreatorsourcetree.base.html',
    styleUrls: ['./objectcreatorsourcetree.css']
})
export class ObjectCreatorSourceTreeBase {

    @Input() templateRef: TemplateRef<any>;
    constructor() { }

    MultiPanelValue: ObjectCreatorSourceMultiPanel;

    static DragEnd = new Subject();

    @Input()
    get MultiPanel() {
        return this.MultiPanelValue;
    }
    set MultiPanel(val) {
        this.MultiPanelValue = val;
    }

    @Output() SelectedNodeChange = new EventEmitter<any>();


    get BaseSlide() {
        return null;
        //ToDo OC return this.MultiPanel.BaseSlide;
    }

    ////#region Tree Container
    //private _transformer = (node: TreeNodeSource, level: number) => {
    //  return {
    //    expandable: !!node.children && node.children.length > 0,
    //    name: node.name,
    //    level: level,
    //    Id: node.Id
    //  };
    //}

    //treeControl = new FlatTreeControl<TreeFlatNodeSource>(
    //  node => node.level, node => node.expandable);

    //treeFlattener = new MatTreeFlattener(
    //  this._transformer, node => node.level, node => node.expandable, node => node.children);

    //dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    get DataModelID() {
        if (this.BaseSlide && this.BaseSlide.ReportLayout) {
            return this.BaseSlide.ReportLayout.DataModelID;
        }
        return "";
    }

    MaxUniqueID = 1;
    GetNextUniqueID() {
        return this.MaxUniqueID++;
    }





    DbItems = [];
    TreeSource =
        [
        ];


    SortTreeListAlphabetical(list: IBaseTreeNode[], recursiv: boolean) {
        if (list) {
            list.sort(function (a: IBaseTreeNode, b: IBaseTreeNode) {
                let textA = (a && typeof a.Caption === 'string') ? a.Caption.toUpperCase() : '';
                let textB = (b && typeof b.Caption === 'string') ? b.Caption.toUpperCase() : '';

                return textA.localeCompare(textB);
            });
            if (recursiv) {
                list.forEach(item => {
                    let tnd: IBaseTreeNode = item;
                    if (tnd && tnd.Children) {
                        this.SortTreeListAlphabetical(tnd.Children, true);
                    }
                });
            }
        }
    }

    dragNode: any;
    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    //flatNodeMap = new Map<TreeFlatNodeSource, TreeNodeSource>();
    NodeToDbItem: Map<string, any> = new Map<string, any>();


    UpdateTreeSource(node) {

    }

    dragStart(event) {
        //let fields = this.NodeToDbItem.get(item.Id);
        //if (fields) {
        //  if (fields.length)
        //    //let t = JSON.stringify("sdfsdfsdf");
        //    event.dataTransfer.setData("fields", JSON.stringify(fields));
        //  event.dataTransfer.setData("caption", "sdfsdfsdf");

        //}
    }

     SelectedNode = null;
    selectionChange   (event) {
        if (event) {
            this.SelectedNode = event;
            this.SelectedNodeChange.emit(this.SelectedNode);
        }
    }

    dragEnd(ev) {

    }
}
