import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { deserialize, serialize } from "class-transformer";
import { WorkflowData, WorkflowDescription } from "../models/workflow/workflow.model";
import { WorkflowService } from "../services/workflow.service";
import { TemplateSettings, TemplateSettingsData } from "./modules/template/template.settings";
import { WorkflowControl, WorkflowEditOptions } from "./workflow.control";
import { WorkflowCommunicationService } from "../services/workflow-communication.service";
@Component({
    selector: 'disabled=workflow-edit-dialog',
    templateUrl: 'disabled-workflow.edit.dialog.html',
    styleUrls: ['./workflow.edit.dialog.css']
})
export class DisabledWorkflowEditDialog implements OnInit {

    Data: WorkflowData = new WorkflowData();
    CloseOnCustomButton: boolean = true;
    CustomButtons = [{
        Caption: '@@Speichern'
    }];
    TemplatesLoaded = false;
    EditOptions = new WorkflowEditOptions();
    Workflow;
    selectedWorkflowItem: any;
    saveDisabled: boolean = false;
    @ViewChild('workflowControl') workflowControl: WorkflowControl;

    constructor(private dialogRef: MatDialogRef<DisabledWorkflowEditDialog>, private wfService: WorkflowService, private cdRef: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: any, private workflowCommunicationService: WorkflowCommunicationService) {
        if (data) {
            if (data.Data) {
                var json = serialize(data.Data);
                this.Data = deserialize(WorkflowData, json);
            }
            this.EditOptions.Layout = data.Layout;
            this.Workflow = data.Workflow;
        }
    }

    ngOnInit(): void {
        this.wfService.GetAllWorkflowTemplates().subscribe(result => {
            if (result && result.length > 0) {
                if (this.Data && this.Data.Modules) {
                    this.Data.Modules.forEach(function (mod) {
                        if ((mod.Module == TemplateSettingsData.ModuleID || mod.Module == TemplateSettings.ModuleID) && mod.Settings) {
                            result.some(function (r) {
                                if (r.Id == mod.Settings.TemplateID) {
                                    mod.Settings.TemplateName = r.Name;
                                    return true;
                                }
                                return false;
                            });
                        }
                    });
                }
                this.EditOptions.TemplateList = result;
            }
            this.TemplatesLoaded = true;
            this.cdRef.detectChanges();
        });

        this.wfService.detectOnSaveChanges.subscribe((isUpdate) => {
            if (isUpdate) {
                const obj = JSON.parse(JSON.stringify(this.Data));
                this.undoStack.push(obj);
            }
        })
    }
    Save() {
        if (this.workflowControl != null) {
            this.workflowControl.CheckForSave().then((x) => {
                if (x) {
                    const json = JSON.stringify(this.Data);
                    const result = deserialize(WorkflowData, json);
                    this.dialogRef.close(result);
                }
            });
        } else {
            const json = JSON.stringify(this.Data);
            const result = deserialize(WorkflowData, json);
            this.dialogRef.close(result);
        }
    }
    Cancel() {
        this.workflowCommunicationService.setSelectedWorkflow(null)
        this.workflowCommunicationService.setDialogStatus("outer")
        this.dialogRef.close(null);
    }

    undoStack: any[] = [];
    redoStack: any[] = [];

    OnContentChanged() {
        if (this.Data) {
            this.OnItemChanged();
        }
    }

    OnItemChanged() {
        const obj = JSON.parse(JSON.stringify(this.Data));
        this.undoStack.push(obj);
    }

    undo() {
        if (this.undoStack.length > 0) {
            let v = this.undoStack.pop();
            this.redoStack.push(v);
            this.setSelectedItem(new WorkflowData());
            const json = serialize(v);
            const wf = deserialize(WorkflowData, json);
            this.setSelectedItem(wf);
        }
    }

    redo() {
        if (this.redoStack.length > 0) {
            let v = this.redoStack.pop();
            this.undoStack.push(v);
            this.setSelectedItem(new WorkflowData());
            const json = serialize(v);
            const wf = deserialize(WorkflowData, json);
            this.setSelectedItem(wf);
        }
    }

    private setSelectedItem(item) {
        this.Data = item;
        this.cdRef.detectChanges();
    }

    resetStack() {
        this.undoStack = [];
        this.redoStack = [];
    }

    @HostListener('document:keydown', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (event.ctrlKey) {
            if (event.key.toLowerCase() == 'z')
                this.undo();
            else if (event.key.toLowerCase() == 'y') {
                this.redo();
            }
        }
    }
}

