import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class NumberCircleService extends RequestBase {
    BasePath = 'api/nc';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetNumberInfos(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this.BasePath + '/GetNumberInfos', this.options);
    }

    GetNumberDetailInfo(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetNumberDetailInfo?id=' + id, this.options);
    }

    SaveNumbers(nc): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveNumbers', nc, this.options);
    }

    DeleteNumbers(ncId): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/DeleteNumbers?ncId=' + ncId, this.options);
    }
}
