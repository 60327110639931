import { AExecutionManipulation } from './a.execution.manipulation';
import { MultiResult } from '../../../models/datadescription/multi/multiresult.model';
import { UUID } from 'angular2-uuid';
import { WhereDef, WhereSelects } from '../../../models/datadescription/multi/querydef.model';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';

export class ReloadExecutionManipulation extends AExecutionManipulation {

    private static SetTupleDepth(areas, depth) {
        if (areas) {
            areas.forEach(area => {
                if (area && area.Tuples) {
                    area.Tuples.splice(depth);
                }
            });
        }
    }

    private static FindLevelNode(area, id) {
        let retVal = null;
        if (area && area.Tuples) {
            area.Tuples.some(t => {
                if (t && t.Levels) {
                    return t.Levels.some(l => {
                        if (l && l.UniqueID === id) {
                            retVal = l;
                            return true;
                        }
                        return false;
                    });
                }
                return false;
            });
        }
        return retVal;
    }

    BeforeSplit() {
        // TODO: Zoom???
        const dd = this.Context.DataDescriptionClone;
        if (dd && dd.YLevelNodes && dd.PowerGrid === true) {
            const loadOnDemandFrom = typeof this.Context.LoadOnDemandFromLevel === 'number' ? this.Context.LoadOnDemandFromLevel : 0;
            const loadOnDemandTo = typeof this.Context.LoadOnDemandToLevel === 'number' ? this.Context.LoadOnDemandToLevel : 0;
            let tupleY;
            if (this.Context.ReloadInfo) {
                tupleY = this.Context.ReloadInfo.ReloadTupleY;
            }
            if (tupleY) {
                const tupleArea = dd.YLevelNodes.Areas.find(area => {
                    return area && area.Tuples && area.Tuples.some(t => {
                        return t && t.Levels && t.Levels.some(l => l.UniqueID === tupleY.UniqueID);
                    });
                });
                if (tupleArea) {
                    const tuples = [];
                    const nextDepth = tupleY.Depth + 1;
                    if (tupleArea.Tuples.length > nextDepth) {
                        if (loadOnDemandTo === nextDepth) { // Wenn nachladen bis zu aufgeklappter Ebene definiert ist
                            if (loadOnDemandFrom === 0) { // und nachladen ab nicht definiert ist
                                for (let i = loadOnDemandTo; i < tupleArea.Tuples.length; i++) { // alle Ebenen ab aufgeklappter Ebene laden
                                    tuples.push(tupleArea.Tuples[i]);
                                }
                                dd.PowerGrid = false;
                            } else if (loadOnDemandFrom > loadOnDemandTo + 2) { // und nachladen ab größer nachladen bis + 2 definiert ist
                                // alle Ebenen ab aufgeklappter Ebene bis eins vor nachladen ab laden
                                for (let i = loadOnDemandTo; i < tupleArea.Tuples.length && i < loadOnDemandFrom - 1; i++) {
                                    tuples.push(tupleArea.Tuples[i]);
                                }
                                dd.PowerGrid = false;
                            } else {
                                tuples.push(tupleArea.Tuples[nextDepth]); // sonst nur nächste Ebene laden
                            }
                        } else if (loadOnDemandTo === 0 && loadOnDemandFrom > tupleY.Depth + 3) {
                            // Wenn nachladen bis nicht definiert ist und nachladen ab größer TupelTiefe + 3 definiert ist
                            // alle Ebenen ab aufgeklappter Ebene bis eins vor nachladen ab laden
                            for (let i = nextDepth; i < tupleArea.Tuples.Count && i < loadOnDemandFrom - 1; i++) {
                                tuples.push(tupleArea.Tuples[i]);
                            }
                            dd.PowerGrid = false;
                        } else { // sonst nur nächste Ebene laden
                            tuples.push(tupleArea.Tuples[nextDepth]);
                        }
                    }
                    tupleArea.Tuples = tuples;
                    dd.YLevelNodes.Areas = [tupleArea];
                } else {
                    dd.YLevelNodes.Areas = [];
                }
            } else {
                let tupleDepth = 1;
                // Wenn nachladen bis nicht definiert ist und nachladen ab größer 2 definiert ist
                if (loadOnDemandTo === 0 && loadOnDemandFrom > 2) {
                    // alle Tupel bis Ebene vor nachladen ab laden
                    tupleDepth = loadOnDemandFrom - 1;
                    dd.PowerGrid = false;
                }
                ReloadExecutionManipulation.SetTupleDepth(dd.YLevelNodes.Areas, tupleDepth);
            }
        }
    }

    BeforeExecute() {
        if (this.Context.QueryDefinitions && this.Context.DataDescriptionClone &&
            this.Context.DataDescriptionOriginal && this.Context.ReloadInfo) {
            const defs = new Map<UUID, WhereDef>();
            let tupleY = this.Context.ReloadInfo.ReloadTupleY;
            if (tupleY) {
                const tupleArea = this.Context.DataDescriptionOriginal.YLevelNodes.Areas.find(area => {
                    return area && area.Tuples && area.Tuples.some(t => {
                        return t && t.Levels && t.Levels.some(l => l.UniqueID === tupleY.UniqueID);
                    });
                });
                if (tupleArea) {
                    while (tupleY) {
                        const ln = ReloadExecutionManipulation.FindLevelNode(tupleArea, tupleY.UniqueID);
                        if (ln) {
                            let def = defs.get(ln.HierarchieID);
                            if (def) {
                                let levFixed = def.FixedMemberIds.find(x => x.LevelId === ln.Level);
                                if (levFixed) {
                                    if (levFixed.MemberIds.indexOf(tupleY.MemberId) < 0) {
                                        levFixed.MemberIds.push(tupleY.MemberId);
                                    }
                                } else {
                                    levFixed = new WhereSelects();
                                    levFixed.LevelId = ln.Level;
                                    levFixed.MemberIds = [tupleY.MemberId];
                                    def.FixedMemberIds.push(levFixed);
                                }
                            } else {
                                def = new WhereDef();
                                def.DimensionId = ln.DimensionID;
                                def.HierarchyId = ln.HierarchieID;
                                const levFixed = new WhereSelects();
                                levFixed.LevelId = ln.Level;
                                levFixed.MemberIds = [tupleY.MemberId];
                                def.FixedMemberIds = [levFixed];
                                defs.set(ln.HierarchieID, def);
                            }
                        }
                        tupleY = tupleY.Parent;
                    }
                }
            }
            if (defs.size > 0) {
                // -> gewichten ???
                this.Context.QueryDefinitions.forEach(qd => {
                    // Cube-Abhängigkeit???
                    defs.forEach((def, key) => {
                        const copy = JSON.parse(JSON.stringify(def));
                        if (!qd.Where.some((x, i) => {
                            if (x.HierarchyId === key) {
                                qd.Where.splice(i, 1, copy);
                                return true;
                            }
                            return false;
                        })) {
                            qd.Where.push(copy);
                        }
                    });
                });
            }
        }
    }

    AfterExecute(result: MultiResult) {
    }
}
