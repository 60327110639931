import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiReportingService } from '../../../services/reporting.service';

@Component({
    selector: 'reference-axis',
    templateUrl: './reference.axis.component.html'
})
export class ReferenceAxisComponent {
    //#region Description
    DescriptionValue = [];
    @Input()
    get Description() {
        return this.DescriptionValue;
    }
    set Description(val) {
        if (val !== this.DescriptionValue) {
            this.DescriptionValue = val;
            this.DescriptionChange.emit(this.DescriptionValue);
        }
    }
    @Output() DescriptionChange = new EventEmitter<any>();
    //#endregion
    //#region Axis
    AxisValue = [];
    @Input()
    get Axis() {
        return this.AxisValue;
    }
    set Axis(val) {
        if (val !== this.AxisValue) {
            this.AxisValue = val;
            this.AxisChange.emit(this.AxisValue);
        }
    }
    @Output() AxisChange = new EventEmitter<any>();
    //#endregion
    //#region Members
    MembersValue = [];
    @Input()
    get Members() {
        return this.MembersValue;
    }
    set Members(val) {
        if (val !== this.MembersValue) {
            this.MembersValue = val;
            this.MembersChange.emit(this.MembersValue);
        }
    }
    @Output() MembersChange = new EventEmitter<any>();
    //#endregion

    SelectedData;
    AreaIndex;
    AreaIndexChanged() {
        let area = this.Description[this.AreaIndex];
        let areas = Object.keys(this.Axis);
        areas.forEach((a) => {
            if (area.ID == this.Axis[a].AreaID) {
                this.Axis[a].Selected = true;
            } else {
                this.Axis[a].Selected = false;
            }
        })
    }

    constructor(private multiService: MultiReportingService) {
    }

    CheckRefresh() {
        this.ValuesChanged.emit();
    }
    @Output() ValuesChanged = new EventEmitter<any>();
}
