import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';
import { Font } from '../../../../models/style/font.model';


@Component({
  selector: 'underline-action',
  templateUrl: './underline.action.html',
  styleUrls: ['./underline.action.css'],
})
export class UnderlineAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'underline';

    //#region Underline
    @Input()
    get Underline() {
        if (this.SelectedItemValue && this.SelectedItemValue.Font) {
            return this.SelectedItemValue.Font.Underline;
        }
        return false;
    }
    set Underline(val) {
        if (this.SelectedItemValue) {
            if (!this.SelectedItemValue.Font) {
                this.SelectedItemValue.Font = new Font();
            }
            this.SelectedItemValue.Font.Underline = val;
        }
        this.UnderlineChange.emit(val);
    }

    @Output() UnderlineChange = new EventEmitter<any>();
    //#endregion

    ControlInitialized() {

    }
    ExecuteAction() {
        this.Underline = !this.Underline;
    }

    Visibility() {
        return true;
    }
}
