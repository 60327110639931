import { QueryDef } from "../datadescription/multi/querydef.model";
import { PlanType } from "../enums/plantype.enum";

export class PlanValues {
    Query: QueryDef;
    Values: PlanValue[];
}
export class PlanValue {
    Value: number;
    OldValue: number;
    MeasureUniqueId: number;
    AlternateMeasureUniqueId: number;
    Type: PlanType;
    XElements: PlanElement[];
    YElements: PlanElement[];
}
export class PlanElement {
    UniqueId: number;
    ElementId: string;
}