
// import { ContainerOdbcSettings } from '../components/settings/datamodel/containerodbc/containerodbc.settings';
import { RestParameterFilter, CaptionFilterPipe, ERDFilterPipe, DataTypePipe } from '../../helpers/filter.helper';
import { SettingsRegistryHelper } from './settings.registry';
import { ADataPreviewSettings, AObjectPreviewSettings, APreviewSettings, DataPreviewDetail, ObjectPreviewDetail } from '../../settings/datamodel/common/adatapreview.settings';
import { ContainerRelationOverview } from '../../settings/datamodel/common/containerrelation.overview';
import { LoginInfoControl } from '../../settings/datamodel/common/logininfo.control';
import { QueryRelationOverview } from '../../settings/datamodel/common/query.relation.overview';
import { ContainerBusinessModelDetail, ContainerBusinessModelSettings } from '../../settings/datamodel/containerbusinessmodel/containerbusinessmodel.settings';
import { ContainerCSVSettings } from '../../settings/datamodel/containercsv/containercsv.settings';
import { ContainerCubeDetail, ContainerCubeSettings } from '../../settings/datamodel/containercube/containercube.settings';
import { ContainerDatetimeDetail, ContainerDatetimeSettings } from '../../settings/datamodel/containerdatetime/containerdatetime.settings';
import { ContainerDimensionDetail, ContainerDimensionSettings } from '../../settings/datamodel/containerdimension/containerdimension.settings';
import { ContainerEmailSettings } from '../../settings/datamodel/containeremail/containeremail.settings';
import { ContainerEnumTableDetail, ContainerEnumTableSettings } from '../../settings/datamodel/containerenumtable/container.enum.table.settings';
import { ContainerExcelSettings } from '../../settings/datamodel/containerexcel/containerexcel.settings';
import { ContainerJsonSettings } from '../../settings/datamodel/containerjson/containerjson.settings';
import { ContainerObjectViewSettings } from '../../settings/datamodel/containerobjectview/containerobjectview.settings';
import { ContainerRestDetail } from '../../settings/datamodel/containerrest/containerrest.detail';
import { ContainerRestSettings } from '../../settings/datamodel/containerrest/containerrest.settings';
import { BaseMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/base.mapping';
import { FixedMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/fixed.mapping';
import { OrderMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/order.mapping';
import { PagingMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/paging.mapping';
import { SelectMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/select.mapping';
import { UpdateMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/update.mapping';
import { WhereMappingForm } from '../../settings/datamodel/containerrest/mapping/forms/where.mapping';
import { RestMappingControl } from '../../settings/datamodel/containerrest/mapping/rest.mapping';
import { ContainerTableDetail, ContainerTableSettings  } from '../../settings/datamodel/containertable/containertable.settings';
import { ContainerTabularDetail, ContainerTabularSettings } from '../../settings/datamodel/containertabular/containertabular.settings';
import { ContainerViewDetail, ContainerViewSettings } from '../../settings/datamodel/containerview/containerview.settings';
import { ContainerWebFeedSettings } from '../../settings/datamodel/containerwebfeed/containerwebfeed.settings';
import { ContainerXMLSettings } from '../../settings/datamodel/containerxml/containerxml.settings';
import { DataModelRegistry } from '../../settings/datamodel/datamodel.settings';
import { ASDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/asdb/asdb.panel';
import { BigQueryPanel } from '../../settings/datamodel/datasource/datasourcepanels/bigquery/bigquery.panel';
import { CassandraPanel } from '../../settings/datamodel/datasource/datasourcepanels/cassandra/cassandra.panel';
import { CosmosDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/cosmosdb/cosmosdb.panel';
import { CratePanel } from '../../settings/datamodel/datasource/datasourcepanels/crate/crate.panel';
import { DB2Panel } from '../../settings/datamodel/datasource/datasourcepanels/db2/db2.panel';
import { DynamoDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/dynamodb/dynamodb.panel';
import { FSDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/filesystemdb/filesystemdb.panel';
import { FTPDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/ftpdb/ftpdb.panel';
import { ImapPanel } from '../../settings/datamodel/datasource/datasourcepanels/imapclient/imap.panel';
import { LexbizzPanel } from '../../settings/datamodel/datasource/datasourcepanels/lexbizz/lexbizz.panel';
import { ODBCDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/odbcdb/odbcdb.panel';
import { OracleDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/oracledb/oracledb.panel';
import { RestPanel } from '../../settings/datamodel/datasource/datasourcepanels/rest/rest.panel';
import { RolapDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/rolapdb/rolapdb.panel';
import { SambaDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/sambadb/sambadb.panel';
import { SapB1DBPanel } from '../../settings/datamodel/datasource/datasourcepanels/sapb1db/sapb1db.panel';
import { TabularPanel } from '../../settings/datamodel/datasource/datasourcepanels/tabular/tabular.panel';
import { TSBDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/tsbdb/tsbdb.panel';
import { WebDAVDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/webdavdb/webdavdb.panel';
import { WebDBPanel } from '../../settings/datamodel/datasource/datasourcepanels/webdb/webdb.panel';
import { AttributeChooserDialog } from '../../settings/datamodel/dialogs/attribute.chooser.dialog';
import { ContainerAttributeEditDialog } from '../../settings/datamodel/dialogs/container.attribute.edit.dialog';
import { ContainerBusinessModelElementsDialog } from '../../settings/datamodel/dialogs/container.businessmodel.elements.dialog';
import { ContainerBusinessModelLineDialog } from '../../settings/datamodel/dialogs/container.businessmodel.line.dialog';
import { ContainerFieldEditDialog } from '../../settings/datamodel/dialogs/container.field.edit.dialog';
import { ContainerTabularColumnEditDialog } from '../../settings/datamodel/dialogs/container.tabular.column.edit.dialog';
import { ContainerPathChooserDialog } from '../../settings/datamodel/dialogs/containerpath.chooser.dialog';
import { ContainerRelationEditDialog } from '../../settings/datamodel/dialogs/containerrelation.edit.dialog';
import { ContainerColumnDialog } from '../../settings/datamodel/dialogs/containerview.column.dialog';
import { RelQueryDefDialog } from '../../settings/datamodel/dialogs/rel.query.def.dialog';
import { RelatedAttributeDialog } from '../../settings/datamodel/dialogs/related.attributes.dialog';
import { TabularColumnDialog } from '../../settings/datamodel/dialogs/tabular.column.dialog';
import { ERDEditSettings } from '../../settings/erd/edit/erd.edit.settings';
import { ERDSettings } from '../../settings/erd/erd.settings';
import { ERDOverviewDetail, ERDOverviewSettings } from '../../settings/erd/overview/erd.overview.settings';
import { KPICategoryDetail, KPICategorySettings } from '../../settings/kpi/kpi.category.settings';
import { KPIDetail, KPISettings } from '../../settings/kpi/kpi.settings';
import { TAppMessageWorkerControl } from '../../settings/messagesettings/Trigger/tapp.message.worker.control';
import { AddFriendsComponent } from '../../settings/messagesettings/User/add.user';
import { AccessTokenDialog } from '../../settings/rest/rest.overview/access.token.dialog/access.token.dialog';
import { EndpointContentTypeDialog } from '../../settings/rest/rest.overview/end.point.settings/end.point.rest/end.point.content.type.dialog/end.point.content.type.dialog';
import { EndpointParameterDialog } from '../../settings/rest/rest.overview/end.point.settings/end.point.rest/end.point.parameter.dialog/end.point.parameter.dialog';
import { EndPointRestComponent } from '../../settings/rest/rest.overview/end.point.settings/end.point.rest/end.point.rest';
import { EndPointRestDetailComponent } from '../../settings/rest/rest.overview/end.point.settings/end.point.rest/end.pont.rest.detail';
import { ImportRestComponent } from '../../settings/rest/rest.overview/end.point.settings/import.rest/import.rest';
import { MappingRestComponent } from '../../settings/rest/rest.overview/end.point.settings/mappings.rest/mapping.rest';
import { MappingRestDetailComponent } from '../../settings/rest/rest.overview/end.point.settings/mappings.rest/mapping.rest.detail';
import { MappingRestValuesDialog } from '../../settings/rest/rest.overview/end.point.settings/mappings.rest/mapping.rest.values.dialog/mapping.rest.values.dialog';
import { RestObjectPropertyDialog } from '../../settings/rest/rest.overview/end.point.settings/rest.objects/rest.object.property.dialog/rest.object.property.dialog';
import { RestObjectsComponent } from '../../settings/rest/rest.overview/end.point.settings/rest.objects/rest.objects';
import { RestObjectDetailComponent } from '../../settings/rest/rest.overview/end.point.settings/rest.objects/rest.objects.detail';
import { TemplateColumnDialog } from '../../settings/rest/rest.overview/end.point.settings/templates.rest/template.column.dialog/template.column.dialog';
import { TemplateRestDetail } from '../../settings/rest/rest.overview/end.point.settings/templates.rest/template.rest.detail';
import { TemplateRestComponent } from '../../settings/rest/rest.overview/end.point.settings/templates.rest/templates.rest';
import { WebhookRestComponent } from '../../settings/rest/rest.overview/end.point.settings/webhook.rest/webhook.rest';
import { WebhookRestDetailComponent } from '../../settings/rest/rest.overview/end.point.settings/webhook.rest/webhook.rest.detail';
import { RestOverviewSettings } from '../../settings/rest/rest.overview/rest.overview';
import { RestOverviewComponent } from '../../settings/rest/rest.overview/rest.overview.component';
import { WorkflowWizardComponent } from '../../settings/workflowwizard/workflow.wizard.component';
import { WorkflowWizardControl } from '../../settings/workflowwizard/workflow.wizard.control';
import { WorkflowWizardEditItem } from '../../settings/workflowwizard/workflow.wizard.edit.item';
import { WorkflowWizardMenuTab } from '../../settings/workflowwizard/workflow.wizard.menu.tab';
import { WorkflowModuleDragPanel, WorkflowWizardDetail, WorkflowWizardSettings } from '../../settings/workflowwizard/workflow.wizard.settings';
import { Dynamics365Panel } from '../../settings/datamodel/datasource/datasourcepanels/dymanics365/dynamics365.panel';
import { AgentDetail, AgentSettings } from '../../settings/agent/agent.settings';
import config from '../../../assets/config.json'
export const APP_MW_SETTINGS = [
    KPISettings,
    KPIDetail,
    KPICategorySettings,
    KPICategoryDetail,
    WorkflowWizardSettings,
    WorkflowWizardDetail,
    WorkflowModuleDragPanel,
    WorkflowWizardEditItem,
    WorkflowWizardControl,
    WorkflowWizardComponent,
    WorkflowWizardMenuTab,
    RestOverviewComponent,
    AccessTokenDialog,
    EndpointParameterDialog,
    EndpointContentTypeDialog,
    EndPointRestComponent,
    EndPointRestDetailComponent,
    RestOverviewSettings,
    RestObjectsComponent,
    RestObjectDetailComponent,
    RestObjectPropertyDialog,
    TemplateRestComponent,
    WebhookRestComponent,
    WebhookRestDetailComponent,
    TemplateRestDetail,
    TemplateColumnDialog,
    MappingRestComponent,
    MappingRestDetailComponent,
    MappingRestValuesDialog,
    ImportRestComponent,
    RestMappingControl,
    BaseMappingForm,
    WhereMappingForm,
    OrderMappingForm,
    UpdateMappingForm,
    SelectMappingForm,
    FixedMappingForm,
    PagingMappingForm,
    ERDSettings,
    ERDEditSettings,
    ERDOverviewSettings,
    ERDOverviewDetail
];

export const APP_MW_PIPES = [
    RestParameterFilter,
    CaptionFilterPipe,
    ERDFilterPipe,
    DataTypePipe
];

export const APP_DATAMODEL_SETTINGS = [
    APreviewSettings,
    ContainerTableSettings,
    ContainerTableDetail,
    ContainerViewSettings,
    ContainerViewDetail,
    ContainerColumnDialog,
    ADataPreviewSettings,
    AObjectPreviewSettings,
    DataPreviewDetail,
    ObjectPreviewDetail,
    ContainerExcelSettings,
    ContainerCSVSettings,
    ContainerDatetimeSettings,
    ContainerDatetimeDetail,
    ContainerJsonSettings,
    ContainerEmailSettings,
    ContainerObjectViewSettings,
    ContainerXMLSettings,
    ContainerWebFeedSettings,
    ContainerRelationOverview,
    ContainerRelationEditDialog,
    ContainerRestSettings,
    ContainerRestDetail,
    FTPDBPanel,
    FSDBPanel,
    WebDAVDBPanel,
    WebDBPanel,
    LoginInfoControl,
    ContainerTabularSettings,
    ContainerTabularDetail,
    TabularColumnDialog,
    TSBDBPanel,
    ContainerDimensionSettings,
    ContainerDimensionDetail,
    RelatedAttributeDialog,
    ContainerPathChooserDialog,
    ContainerCubeDetail,
    ContainerCubeSettings,
    ASDBPanel,
    TabularPanel,
    LexbizzPanel,
    ImapPanel,
    RestPanel,
    BigQueryPanel,
    CosmosDBPanel,
    CratePanel,
    RelQueryDefDialog,
    QueryRelationOverview,
    RolapDBPanel,
    SambaDBPanel,
    DynamoDBPanel,
    OracleDBPanel,
    CassandraPanel,
    AttributeChooserDialog,
    SapB1DBPanel,
    ODBCDBPanel,
    DB2Panel,
    Dynamics365Panel,
    ContainerFieldEditDialog,
    ContainerAttributeEditDialog,
    ContainerTabularColumnEditDialog,
    AddFriendsComponent,
    TAppMessageWorkerControl,
    ContainerEnumTableSettings,
    ContainerEnumTableDetail,
    ContainerBusinessModelSettings,
    ContainerBusinessModelDetail,
    ContainerBusinessModelLineDialog,
    ContainerBusinessModelElementsDialog
];

export class MWSettingsRegistryHelper {
    static Build() {
        MWSettingsRegistryHelper.BuildSettings();
        MWSettingsRegistryHelper.FillDataSourcePanelList();
        MWSettingsRegistryHelper.FillSchedulerWorker();
    }

    private static BuildSettings() {
        APP_MW_SETTINGS.forEach(function (entry) {
            if (typeof entry['GetSettingsEntry'] === 'function') {
                SettingsRegistryHelper.ExternalRegistry.push(entry);
            }
        });

        const rel = {
            Caption: '@@Relational',
            ID: 'relational',
            Icon: 'description',
            Index: 2,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RelationalContainerRight',
                Value: 1
            },
            Children: []
        };
        const obj = {
            Caption: '@@Objekt',
            ID: 'objectcontainer',
            Icon: 'description',
            Index: 3,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
                Value: 1
            },
            Children: []
        };
        //const tab = {
        //    Caption: '@@Tabular',
        //    ID: 'tabularcontainer',
        //    Icon: 'description',
        //    Index: 4,
        //    Security: {
        //        Name: 'evidanza.MiddleWare.Shared.Security.TabularContainerRight',
        //        Value: 1
        //    },
        //    Content: ContainerTabularSettings
        //};
        const multi = {
            Caption: '@@Multidimensional',
            ID: 'multidimensional',
            Icon: 'description',
            Index: 5,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.MultidimensionalContainerRight',
                Value: 1
            },
            Children: []
        };
        APP_DATAMODEL_SETTINGS.forEach(function (entry) {
            if (typeof entry['GetSettingsEntry'] === 'function') {
                const setting = entry['GetSettingsEntry']();
                if (setting.Parent === rel.ID) {
                    rel.Children.push(setting);
                } else if (setting.Parent === obj.ID) {
                    obj.Children.push(setting);
                }
                else if (setting.Parent === multi.ID) {
                    // const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
                    // if(userRole && userRole.length > 0 && userRole[0] !== config.FREE_USER_ID)
                    multi.Children.push(setting);
                }
            }
        });
        if (rel.Children.length > 0) {
            rel.Children.sort((a, b) => { return a.Index - b.Index; });
            DataModelRegistry.Registry.push(rel);
        }
        if (obj.Children.length > 0) {
            obj.Children.sort((a, b) => { return a.Index - b.Index; });
            DataModelRegistry.Registry.push(obj);
        }
        //DataModelRegistry.Registry.push(tab);
        if (multi.Children.length > 0) {
            multi.Children.sort((a, b) => { return a.Index - b.Index; });
            DataModelRegistry.Registry.push(multi);
        }
    }

    private static FillDataSourcePanelList() {
        SettingsRegistryHelper.ExternalDataSourceInfos.push(FTPDBPanel);
        //SettingsRegistryHelper.ExternalDataSourceInfos.push(FSDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(WebDAVDBPanel);
        //SettingsRegistryHelper.ExternalDataSourceInfos.push(WebDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(TSBDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(ASDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(RolapDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(SambaDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(SapB1DBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(ODBCDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(TabularPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(LexbizzPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(ImapPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(RestPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(BigQueryPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(OracleDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(DynamoDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(CassandraPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(CosmosDBPanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(CratePanel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(DB2Panel);
        SettingsRegistryHelper.ExternalDataSourceInfos.push(Dynamics365Panel);
    }

    private static FillSchedulerWorker() {
        SettingsRegistryHelper.ExternalWorkerInfos.push(TAppMessageWorkerControl);
    }
}
