import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateFormatText } from '../../../../helpers/array.helpers';
import { BodyMapping, MappingParameter, ParameterType, RestMapping } from '../../../../models/rest/mapping.structure';
import { RestService } from '../../../../services/rest.service';
import { DeleteTexts } from '../../../../settings/base.list.settings';

@Component({
    selector: 'rest-mapping',
    templateUrl: 'rest.mapping.html',
    styleUrls:['rest.mapping.css']
})
export class RestMappingControl {

    private readonly _typeName = 'evidanza.MiddleWare.Shared.Container.ContainerRest';

    //#region DataSource
    DataSourceValue;
    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion

    //#region Endpoints
    EndpointsValue;
    @Input()
    get Endpoints() {
        return this.EndpointsValue;
    }
    set Endpoints(val) {
        this.EndpointsValue = val;
        this.EndpointsChange.emit(this.EndpointsValue);
    }

    @Output() EndpointsChange = new EventEmitter<any>();
    //#endregion
    //#region Fields
    FieldsValue;
    @Input()
    get Fields() {
        return this.FieldsValue;
    }
    set Fields(val) {
        this.FieldsValue = val;
        this.FieldsChange.emit(this.FieldsValue);
    }

    @Output() FieldsChange = new EventEmitter<any>();
    //#endregion
    //#region CustomFields
    CustomFieldsValue;
    @Input()
    get CustomFields() {
        return this.CustomFieldsValue;
    }
    set CustomFields(val) {
        this.CustomFieldsValue = val;
        this.CustomFieldsChange.emit(this.CustomFieldsValue);
    }

    @Output() CustomFieldsChange = new EventEmitter<any>();
    //#endregion
    //#region Objects
    ObjectsValue;
    @Input()
    get Objects() {
        return this.ObjectsValue;
    }
    set Objects(val) {
        this.ObjectsValue = val;
        this.ObjectsChange.emit(this.ObjectsValue);
    }

    @Output() ObjectsChange = new EventEmitter<any>();
    //#endregion

    @Output() ValuesChanged = new EventEmitter<any>();

    SelectedItem;
    SelectedEndpoint;
    RestObject;
    Parameters = {};
    BodyParameters = {}
    UrlParams = [];
    HeaderParams = [];
    QueryParams = [];
    Body;
    EndpointID;

    constructor(private _translate: TranslateService, private _restService: RestService) {
    }
    

    addItem() {
        const item = new RestMapping();
        item.Name = this._translate.instant('@@NewMapping');
        this.DataSource.push(item);
        this.SelectedItem = item;
        this.Reset();
        this.ValuesChanged.emit();
    }

    deleteItem() {
        this.DataSource.splice(this.DataSource.indexOf(this.SelectedItem), 1);
        this.SelectedItem = null;
        this.Reset();
        this.ValuesChanged.emit();
    }

    onItemClick(item) {
        this.SelectedItem = item;
        this.EndpointID = this.SelectedItem.EndpointID;
        this.OnItemChanged();
    }
    EndpointChanged() {
        this.ValuesChanged.emit();
        this.OnItemChanged();
    }
    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Restschnitstelle \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Restschnitstelle \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restschnitstelle loeschen');
        return retVal;
    }

    Reset() {
        this.UrlParams = [];
        this.HeaderParams = [];
        this.QueryParams = [];
        this.Body = [];
        this.RestObject = null;
        this.Parameters = {};
        this.BodyParameters = {};
    }
    ItemValuesChanged() {
        this.ValuesChanged.emit();
    }
    SearchValue;
    openChanged() {
        this.SearchValue = null;
    }
    OnItemChanged() {
        this.Reset();
        if (this.SelectedItem.EndpointID !== this.EndpointID) {
            this.EndpointID = this.SelectedItem.EndpointID;
            this.SelectedItem.Parameters = null;
            this.SelectedItem.Body = null;
        }
        this.SelectedEndpoint = this.Endpoints.find(item => item.SID === this.SelectedItem.EndpointID);
        if (this.SelectedEndpoint && this.SelectedEndpoint.Parameters && this.SelectedEndpoint.Parameters.length > 0) {
            this.SelectedEndpoint.Parameters.forEach((param) => {
                this.Parameters[param.ParameterType + '_' + param.Name] = param;
                if (!this.SelectedItem.Parameters) {
                    this.SelectedItem.Parameters = [];
                }
                if (this.SelectedItem.Parameters) {
                    let itemParam = this.SelectedItem.Parameters.find((item) => item.ParameterName === param.Name && item.ParameterType === param.ParameterType);
                    if (!itemParam) {
                        itemParam = new MappingParameter();
                        itemParam.ParameterType = param.ParameterType;
                        itemParam.ParameterName = param.Name;
                        itemParam.IsRequired = param.Required;
                        this.SelectedItem.Parameters.push(itemParam);
                    }

                    switch (itemParam.ParameterType) {
                        case ParameterType.Header: this.HeaderParams.push(itemParam); break;
                        case ParameterType.Path: this.UrlParams.push(itemParam); break;
                        case ParameterType.Query: this.QueryParams.push(itemParam); break;
                    }
                }
            });
        }
        if (this.SelectedEndpoint.RequestBody && this.SelectedEndpoint.RequestBody.Contents && this.SelectedEndpoint.RequestBody.Contents.length > 0 && this.SelectedEndpoint.RequestBody.Contents[0].ObjectID !== '00000000-0000-0000-0000-000000000000') {
            const RestObject = this.ObjectsValue.find((item) => item.Value === this.SelectedEndpoint.RequestBody.Contents[0].ObjectID);
            this._restService.loadRestObject(RestObject.Value).subscribe((obj) => {
                this.RestObject = obj;
                if (!this.SelectedItem.Body) {
                    this.SelectedItem.Body = new BodyMapping();
                    this.SelectedItem.Body.ObjectID = obj.SID;
                }
                if (!this.SelectedItem.Body.Parameters) {
                    this.SelectedItem.Body.Parameters = [];
                }
                if (obj && obj.Fields && obj.Fields.length > 0) {
                    obj.Fields.forEach((field) => {
                        let itemParam = this.SelectedItem.Body.Parameters.find((item) => item.ParameterName === field.Name);
                        const split = field.DataTyp.split('.');
                        field.DataType = split[split.length - 1];
                        this.BodyParameters[field.Name] = field;
                        if (!itemParam) {
                            itemParam = new MappingParameter();
                            itemParam.ParameterName = field.Name;
                        }
                        this.SelectedItem.Body.Parameters.push(itemParam);
                        this.Body.push(itemParam);
                    })
                }
                
            })
            
        }
    }
}