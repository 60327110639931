import { ComponentPortal } from "@angular/cdk/portal"

export class MenuTabProperty {
    ID: string
    Section?: string
    Parent: string
    Content: any
    Type?: any
    
    Column?: number = 1
    Row?: number = 1
    ColSpan?: number = 1
    RowSpan?: number = 1
    InitArgs?: any;
    Path?: MenuTabPropertyPath
    DataSource?: any;
    Label?: string
    LabelPosition?: MenuTabLabelPosition = MenuTabLabelPosition.None;
    LabelMinSize?: string;
    Icon?: string
    IconPosition?: MenuTabLabelPosition = MenuTabLabelPosition.None;
    Properties?:MenuTabProperty[]
    CheckVisibility? = (item, entry?, context?) => {
        return true;
    }
}
export class MenuTabPropertyPath {
    Path: string
    Type: any
    Sub?: MenuTabPropertyPath
}
export enum MenuTabLabelPosition {
    None,
    Top,
    Left,
    Right,
    Bottom
}