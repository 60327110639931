import { BaseDataSourcePanel } from '../basedatasource.panel';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MSDBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { MediaService } from '../../../../../services/media.service';

@Component({
    selector: 'msdb-panel',
    templateUrl: './msdb.panel.html',
    styleUrls: ['./msdb.panel.css']
})
export class MSDBPanel extends BaseDataSourcePanel {

    MediaSources = [];

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: MSDBPanel,
            DBInfoType: MSDBInfo
        };
    }

    constructor(private service: MediaService, private cdRef: ChangeDetectorRef) {
        super();
        this.DBInfoType = MSDBInfo;
    }

    ngOnInit(): void {
        this.service.Get().subscribe(result => {
            if (result) {
                this.MediaSources = result;
            }
            this.cdRef.detectChanges();
        });
    }
}
