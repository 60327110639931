import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { BaseThemeControl } from '../base.theme.control';

@Component({
    selector: 'textbox-theme-control',
    templateUrl: './textbox.theme.control.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextboxThemeControl extends BaseThemeControl implements AfterViewInit {
    ViewInitialized = false;
    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        this.cdRef.detectChanges();
    }

    //#region InputType
    InputTypeValue: string;
    @Input()
    get InputType(): string {
        return this.InputTypeValue;
    }
    set InputType(val: string) {
        this.InputTypeValue = val;
        this.InputTypeChange.emit(this.InputTypeValue);
        if (this.ViewInitialized) {
            this.cdRef.detectChanges();
        }
    }

    @Output() InputTypeChange = new EventEmitter<string>();
    //#endregion

    //#region Minimum
    MinimumValue: string;
    @Input()
    get Minimum(): string {
        return this.MinimumValue;
    }
    set Minimum(val: string) {
        this.MinimumValue = val;
        this.MinimumChange.emit(this.MinimumValue);
    }

    @Output() MinimumChange = new EventEmitter<string>();
    //#endregion

    //#region Maximum
    MaximumValue: string;
    @Input()
    get Maximum(): string {
        return this.MaximumValue;
    }
    set Maximum(val: string) {
        this.MaximumValue = val;
        this.MaximumChange.emit(this.MaximumValue);
    }

    @Output() MaximumChange = new EventEmitter<string>();
    //#endregion
    //#region Mentions
    MentionsValue: string;
    @Input()
    get Mentions(): string {
        return this.MentionsValue;
    }
    set Mentions(val: string) {
        this.MentionsValue = val;
        this.MentionsChange.emit(this.MentionsValue);
    }

    @Output() MentionsChange = new EventEmitter<string>();
    //#endregion
    //#region Nachkommastellen
    DecimalPositionValue: string;
    @Input()
    get DecimalPositions(): string {
        return this.DecimalPositionValue;
    }
    set DecimalPositions(val: string) {
        this.DecimalPositionValue = val;
        if (this.DataSourceInternal && this.DecimalPositionValue && Number(this.DecimalPositionValue) > 0) {
            this.DataSourceInternal = Number(this.DataSourceInternal).toFixed(Number(this.DecimalPositionValue));
        }
        this.DecimalPositionsChange.emit(this.DecimalPositionValue);
    }

    @Output() DecimalPositionsChange = new EventEmitter<string>();
    //#endregion

    //#region Step
    StepValue: string;
    @Input()
    get Step(): string {
        if (this.StepValue !== undefined) {
            return this.StepValue;
        }
        return 'any;';
    }
    set Step(val: string) {
        this.StepValue = val;
        this.StepChange.emit(this.StepValue);
    }

    @Output() StepChange = new EventEmitter<string>();
    //#endregion

    //#region events
    @Output() Changed = new EventEmitter<string>();
    onChange(ev) {
        if (this.InputType === 'number') {

            let setMaxValue = false;
            let setMinValue = false;

            let valueNumberTemp = Number(this.DataSourceInternal);
            if (this.DataSourceInternal < this.Minimum) {
                valueNumberTemp = Number(this.Minimum);
                setMinValue = true;

            } else if (this.DataSourceInternal > this.Maximum) {
                valueNumberTemp = Number(this.Maximum);
                setMaxValue = true;
            }

            if (this.DecimalPositions && Number(this.DecimalPositions) > 0) {

                const valueString = valueNumberTemp.toString();
                valueNumberTemp = Number(valueNumberTemp.toFixed(Number(this.DecimalPositions)));

                if (!setMaxValue && !setMinValue && valueString !== valueNumberTemp.toString()) {
                    NotificationHelper.Info('@@Der Wert wurde auf die maximale Nachkommastellenanzahl von {0} gesetzt.', '@@maximal {0} Nachkommstellen zulaessig', { 0: this.DecimalPositions }, { 0: this.DecimalPositions });
                }
            }

            if (setMaxValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Maximum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Maximum ueberschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }
            if (setMinValue) {
                NotificationHelper.Info('@@Der Wert wurde auf das Minimum {0} gesetzt. Die maximale Nachkommastellenanzahl von {1} wurde beachtet.', '@@Minimum unterschritten', { 0: valueNumberTemp, 1: this.DecimalPositions });
            }

            if (!isNaN(valueNumberTemp)) {
                this.DataSourceInternal = valueNumberTemp;
            }
        }
        if (this.DataSourceInternal != null) {
            const eventValue = '' + this.DataSourceInternal;
            this.Changed.emit(eventValue);
        }
    }   

    //#endregion

    
}
