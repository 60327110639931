import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'evi-listbox',
    templateUrl: './simple.listbox.control.html',
    styleUrls: ['./listbox.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleListBoxControl {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        if (this.SelectedItemValue !== val) {
            this.SelectedItemValue = val;
            this.SelectedItemChange.emit(this.SelectedItemValue);
        }
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region ItemsSource
    ItemsSourceValue = [];

    @Input()
    get ItemsSource() {
        return this.ItemsSourceValue;
    }
    set ItemsSource(val) {
        this.ItemsSourceValue = val;
        this.ItemsSourceChange.emit(val);
    }

    @Output() ItemsSourceChange = new EventEmitter<any>();
    //#endregion

    //#region DisplayMemberPath
    DisplayMemberPathValue;

    @Input()
    get DisplayMemberPath() {
        return this.DisplayMemberPathValue;
    }
    set DisplayMemberPath(val) {
        this.DisplayMemberPathValue = val;
        this.DisplayMemberPathChange.emit(this.DisplayMemberPathValue);
    }

    @Output() DisplayMemberPathChange = new EventEmitter<any>();
    //#endregion


    @Output() ItemClicked = new EventEmitter<any>();

    onItemClick(item) {
        if (this.SelectedItem !== item) {
            this.SelectedItem = item;
            this.ItemClicked.emit(item);
        }
    }
}
