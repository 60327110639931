export enum DimensionType {
    Unknown = 0,
    Time = 1,
    Measure = 2,
    Other = 3,
    No_Aggr = 4,
    Quantitative = 5,
    Accounts = 6,
    Customers = 7,
    Products = 8,
    Scenario = 9,
    Utility = 10,
    Currency = 11,
    Rates = 12,
    Channel = 13,
    Promotion = 14,
    Organization = 15,
    BillOfMaterials = 16,
    Geography = 17
}

export enum LevelType {
    Regular = 0,
    All = 1,
    Calculated = 2,
    Time = 4,
    Reserved1 = 8,
    TimeYears = 20,
    TimeHalfYears = 36,
    TimeQuarters = 68,
    TimeMonths = 132,
    TimeWeeks = 260,
    TimeDays = 516,
    TimeHours = 772,
    TimeMinutes = 1028,
    TimeSeconds = 2052,
    TimeUndefined = 4100,
    OrgUnit = 4113,
    BomResource = 4114,
    Quantitative = 4115,
    Account = 4116,
    Scenario = 4117,
    Utility = 4118,
    Customer = 4129,
    CustomerGroup = 4130,
    CustomerHousehold = 4131,
    Product = 4145,
    ProductGroup = 4146,
    Person = 4161,
    Company = 4162,
    CurrencySource = 4177,
    CurrencyDestination = 4178,
    Channel = 4193,
    Representative = 4194,
    Promotion = 4209,
    GeoContinent = 8193,
    GeoRegion = 8194,
    GeoCountry = 8195,
    GeoStateOrProvince = 8196,
    GeoCounty = 8197,
    GeoCity = 8198,
    GeoPostalCode = 8199,
    GeoPoint = 8200
}

export enum Aggregation {
    None = 0,
    Sum = 1,
    Max = 2,
    Min = 3,
    Avg = 4,
    Count = 5,
    NotSet = 6,
    DistinctCount = 7
}

export enum MeasureType {
    Double,
    Long,
    DateTime,
    String
}

export enum FtpEncryptionMode {
    None,
    Implicit,
    Explicit,
    Auto
}

export enum FtpDataConnectionType {
    //
    // Summary:
    //     This type of data connection attempts to use the EPSV command and if the server
    //     does not support EPSV it falls back to the PASV command before giving up unless
    //     you are connected via IPv6 in which case the PASV command is not supported.
    AutoPassive = 0,
    //
    // Summary:
    //     Passive data connection. EPSV is a better option if it's supported. Passive connections
    //     connect to the IP address dictated by the server which may or may not be accessible
    //     by the client for example a server behind a NAT device may give an IP address
    //     on its local network that is inaccessible to the client. Please note that IPv6
    //     does not support this type data connection. If you ask for PASV and are connected
    //     via IPv6 EPSV will automatically be used in its place.
    PASV = 1,
    //
    // Summary:
    //     Same as PASV except the host supplied by the server is ignored and the data connection
    //     is made to the same address that the control connection is connected to. This
    //     is useful in scenarios where the server supplies a private/non-routable network
    //     address in the PASV response. It's functionally identical to EPSV except some
    //     servers may not implement the EPSV command. Please note that IPv6 does not support
    //     this type data connection. If you ask for PASV and are connected via IPv6 EPSV
    //     will automatically be used in its place.
    PASVEX = 2,
    //
    // Summary:
    //     Extended passive data connection, recommended. Works the same as a PASV connection
    //     except the server does not dictate an IP address to connect to, instead the passive
    //     connection goes to the same address used in the control connection. This type
    //     of data connection supports IPv4 and IPv6.
    EPSV = 3,
    //
    // Summary:
    //     This type of data connection attempts to use the EPRT command and if the server
    //     does not support EPRT it falls back to the PORT command before giving up unless
    //     you are connected via IPv6 in which case the PORT command is not supported.
    AutoActive = 4,
    //
    // Summary:
    //     Active data connection, not recommended unless you have a specific reason for
    //     using this type. Creates a listening socket on the client which requires firewall
    //     exceptions on the client system as well as client network when connecting to
    //     a server outside of the client's network. In addition the IP address of the interface
    //     used to connect to the server is the address the server is told to connect to
    //     which, if behind a NAT device, may be inaccessible to the server. This type of
    //     data connection is not supported by IPv6. If you specify PORT and are connected
    //     via IPv6 EPRT will automatically be used instead.
    PORT = 5,
    //
    // Summary:
    //     Extended active data connection, not recommended unless you have a specific reason
    //     for using this type. Creates a listening socket on the client which requires
    //     firewall exceptions on the client as well as client network when connecting to
    //     a server outside of the client's network. The server connects to the IP address
    //     it sees the client coming from. This type of data connection supports IPv4 and
    //     IPv6.
    EPRT = 6
}