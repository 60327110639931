import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { LayoutService } from '../../../services/layout.service';
import { APropertyTab } from '../properties/properties.menu.tab';

@Component({
    selector: 'customevents-menu-tab',
    templateUrl: './customevents.menu.tab.html',
    styleUrls: ['./customevents.menu.tab.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomEventsMenuTab extends APropertyTab {
    Layout;
    SelectedEvent;

    static CheckOnChange(menuItem) {
        const selected = LayoutService.SelectedItem.getValue();
        const layout = LayoutService.SelectedLayout.getValue();
        if (selected && layout && (layout.ElementType === 'template' || layout.ElementType === 'widget')) {
            if (selected.ElementType === 'template' || selected.ElementType === 'widget') {
                menuItem.Visible = true;
                menuItem.Expanded = true;
            } else {
                menuItem.Visible = false;
                menuItem.Expanded = false;
            }
        } else {
            menuItem.Visible = false;
            menuItem.Expanded = false;
        }
    }

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
        this.Layout = LayoutService.SelectedLayout.getValue();
    }


    onItemSet() {
        this.SelectedEvent = null;
        if (this.SelectedItem) {
            if (this.SelectedItem.ElementType === 'template' || this.SelectedItem.ElementType === 'widget') {
                if (!this.SelectedItem.CustomEvents) {
                    this.SelectedItem.CustomEvents = [];
                }
                this.Layout = this.SelectedItem;
            }
        }
    }

    add() {
        if (this.Layout) {
            const item = {
                ID: UUID.UUID(),
                Name: 'Var1',
                Value: null,
                Type: null,
                Formula: null
            };
            if (this.Layout.CustomEvents && this.Layout.CustomEvents.length > 0) {
                let index = this.Layout.CustomEvents.length + 1;
                let name = 'Var' + index++;
                while (this.Layout.CustomEvents.some(x => x.Name === name)) {
                    name = 'Var' + index++;
                }
                item.Name = name;
                this.Layout.CustomEvents.push(item);
            } else {
                this.Layout.CustomEvents = [item];
            }
            this.SelectedEvent = item;
            this.onEventsEdited();
        }
    }

    delete() {
        if (this.Layout && this.Layout.CustomEvents && this.SelectedEvent) {
            const index = this.Layout.CustomEvents.indexOf(this.SelectedEvent);
            if (index > -1) {
                this.Layout.CustomEvents.splice(index, 1);
                if (index < this.Layout.CustomEvents.length) {
                    this.SelectedEvent = this.Layout.CustomEvents[index];
                } else if (this.Layout.CustomEvents.length > 0) {
                    this.SelectedEvent = this.Layout.CustomEvents[this.Layout.CustomEvents.length - 1];
                } else {
                    this.SelectedEvent = null;
                }
                this.onEventsEdited();
            }
        }
    }

    onEventsEdited() {
        if (this.Layout) {
            LayoutService.OnLayoutPropertyChanged(this.Layout.ID, 'CustomEvents', this.Layout.CustomEvents);
        }
    }
}
