import { Column } from '../../basic/column.model';
import { Row } from '../../basic/row.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { IPlanningTaskExecuter } from '../atask.model';

export class PlanningContentFormattingTaskExecuter implements IPlanningTaskExecuter {
    static TaskID = 'planningcontentstyletask';

    Style: CellStyle;
    Merger: StyleMerger;
    Rows: Row[];
    Columns: Column[];

    Init(settings: any, Rows: Row[], Columns: Column[], context: any) {
        this.Merger = StyleMerger.GetStyleMerger(context);
        this.Rows = Rows;
        this.Columns = Columns;
        if (settings) {
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
        }
    }

    Execute() {
        if (this.Style && this.Merger) {
            let oldStyle = this.Merger["DefaultCellStyle"];
            this.Merger["DefaultCellStyle"] = this.Merger.MergeStyle(parseInt(this.Merger["DefaultCellStyle"]), this.Style);
            let newStyle = this.Merger["DefaultCellStyle"];
            if (this.Rows && this.Columns) {
                this.Rows.forEach((row) => {
                    this.Columns.forEach((column) => {
                        if (row.styles[column.Name] == oldStyle) {
                            row.styles[column.Name] = newStyle;
                        }
                    })
                })
            }
        }
    }
}
