import { Component } from '@angular/core';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { APythonSettings, APythonSettingsData } from '../../dataoperations/python/python.settings';

@Component({
    selector: 'wf-python-object-settings',
    templateUrl: '../../dataoperations/python/python.settings.html',
    styleUrls: ['../../dataoperations/python/python.settings.css']
})
export class PythonObjectSettings extends APythonSettings {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'pythonObjectWFModule';
        reg.Caption = '@@Python Object';
        reg.GroupID = 'aioperations';
        reg.Index = 151;
        reg.SettingsControl = PythonObjectSettings;
        reg.SettingsTypeHelper = new PythonObjectSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UsedColCaption = '@@Verwendete Eigenschaften';
        this.NewColCaption = '@@Neue Eigenschaften';
        this.AddNewColCaption = '@@Neue Eigenschaft';
        this.DeleteColCaption = '@@Eigenschaft loeschen';
    }

    initialize(data: any) {
        super.initialize(data);
        let usedProps = [];
        if (data) {
            if (data.UsedProps) {
                usedProps = data.UsedProps;
            }
            if (data.NewProps) {
                data.NewProps.forEach(nc => {
                    this.NewColumns.push({ Name: nc });
                });
            }
        }
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource && dataSource.Properties) {
            dataSource.Properties.forEach(prop => {
                this.AllColumns.push({
                    Name: prop.Name,
                    IsSelected: usedProps.some(uc => prop.Name === uc)
                });
            });
        }
    }

    getResult() {
        const retVal = new PythonObjectSettingsData();
        super.fillResult(retVal);
        this.AllColumns.forEach(ac => {
            if (ac.IsSelected) {
                retVal.UsedProps.push(ac.Name);
            }
        });
        this.NewColumns.forEach(nc => {
            retVal.NewProps.push(nc.Name);
        });
        return retVal;
    }
}

export class PythonObjectSettingsData extends APythonSettingsData {
    UsedProps: string[] = [];
    NewProps: string[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ObjectOperations.AI.PythonObjectSettingsData';
    }
}

export class PythonObjectSettingsDataHelper extends WorkflowModuleSettingsHelper {
    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        if (settings && (settings.ReturnType === 1 || settings.ReturnType === 2)) {
            retVal.Type = 'objData';
        }
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new PythonObjectSettingsData();
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const def = new WorkflowExitInfo();
        def.Type = 'objData';
        const fe = new WorkflowExitInfo();
        fe.ID = 1;
        fe.Label = '@@ForEach';
        fe.Type = 'forEach';
        return [def, fe];
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && settings) {
                const newProps = [];
                if (ds.Properties && settings.UsedProps) {
                    settings.UsedProps.forEach(gc => {
                        ds.Properties.some(col => {
                            if (col.Name === gc) {
                                newProps.push(col);
                                return true;
                            }
                            return false;
                        });
                    });
                }
                if (settings.NewProps) {
                    settings.NewProps.forEach(np => {
                        newProps.push({
                            Name: np,
                            FullPath: np,
                            ObjectProperties: [],
                            SimpleType: 'System.String' // ???
                        });
                    });
                }
                ds.Properties = newProps;
            }
        }
    }
}
