import { WorkflowType } from "../../../../models/enums/workflowtype.enum";
import { AServiceWorkflowData } from "../../../../models/workflow/workflow.model";
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from "../../../../services/workflow.service";
import { StartWfModuleSettings } from "../../startend/start.wfmodule";

export class StartModuleSettingsHelper extends WorkflowModuleSettingsHelper {

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new StartWorkflowSettingsData();
    }

    public static GetRegistry(): WorkflowRegistry {
        let startReg = new WorkflowRegistry();
        startReg.ID = 'startModule';
        startReg.Caption = '@@Start';
        startReg.SettingsTypeHelper = new StartModuleSettingsHelper();
        startReg.WorkflowType = WorkflowType.Service;
        startReg.ObsoleteInfo = new ObsoleteInfo();
        startReg.ObsoleteInfo.ReplacedBy = StartWfModuleSettings.ModuleID;
        return startReg;
    }
}

export class StartWorkflowSettingsData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.Start.StartWorkflowSettingsData';
    }
}
