import { ComponentPortal } from "@angular/cdk/portal";
import { BehaviorSubject } from "rxjs";
import { CURSOR_TYPES } from "../../models/basic/cursortypes";
import { LayoutUnit } from "../../models/basic/layoutunit.model";
import { LayoutPosition } from "../../models/enums/layoutposition.enum";
import { Overflow } from "../../models/enums/overflow.enum";
import { PrimeFlexSizes } from "../../models/enums/primeflexsizes.enum";
import { PropertyGroupDisplay } from "../../models/enums/propertygroupdisplay.enum";
import { WhiteSpace } from "../../models/enums/whitespace.enum";
import { MultiPropertyChangeValue } from "../../models/layout/layout.change.model";
import { StyleBase } from "../../models/layoutbase.model";
import { MenuTabLabelPosition } from "../../models/menutab/menutab.property.model";
import { PROPERTIES, PROPERTYGROUPS } from "../../services/dynamic.component.service";
import { LayoutService } from "../../services/layout.service";
import { CheckBoxThemeControl } from "../controls/checkbox/checkbox.control";
import { ComboboxThemeControl } from "../controls/combobox/combobox.theme.control";
import { InputswitchThemeControl } from "../controls/inputswitch/inputswitch.theme.control";
import { TextboxThemeControl } from "../controls/textbox/textbox.theme.control";
import { UnitSelectionControl } from "../controls/unittypeselection/unitselection.theme.control";
import { GenericMenuTab } from "../menutabs/generic/generic.menu.tab";
import { BasePanel } from "./base.panel";

export class AdvancedPanel extends BasePanel {
    static override SIDS = ['1FE28779-54CC-4478-9A19-10BD93C59262']
    static InitPanel() {
        this.InitSelectedItem()
        this.InitSelectedObject()
        this.InitSelectedLayout()
        PROPERTYGROUPS.push({
            SID:'1FE28779-54CC-4478-9A19-10BD93C59262',
            ID: 'Advanced',
            Caption: '@@Advanced',
            Index: 3,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['1fr', '1fr'],
            Rows: ['auto'],
        });

        let row = 1;
        PROPERTIES.push({
            ID: "Editable",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Editable",
            LabelPosition: MenuTabLabelPosition.Right,
            Row: row++,
            Column: 1,
            ColSpan: 2,
            CheckVisibility: (item,entry, context) => {
                return context != 'wf'
            }
        });
        PROPERTIES.push({
            ID: "Visible",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Visible",
            LabelPosition: MenuTabLabelPosition.Right,
            Row: row++,
            Column: 1,
            ColSpan: 2,
            CheckVisibility: (item,entry, context) => {
                return context != 'wf'
            }
        });
        PROPERTIES.push({
            ID: "HandleIntersection",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@HandleIntersection",
            LabelPosition: MenuTabLabelPosition.Right,
            Row: row++,
            Column: 1,
            ColSpan: 2,
            CheckVisibility: (item, entry, context) => {
                return context != 'wf' && ['repeat', 'carousel'].indexOf(item.ElementType) > -1;
            }
        });
        PROPERTIES.push({
            ID: "IsRequired",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Required",
            LabelPosition: MenuTabLabelPosition.Right,
            CheckVisibility: (item) => {
                return item.DataSource && !item.DataModelID && item.IsRequired;
            },
            InitArgs: {
                Disabled: true
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "Required",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Required",
            LabelPosition: MenuTabLabelPosition.Right,
            CheckVisibility: (item) => {
                return item.DataSource && !item.DataModelID && !item.IsRequired;
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "Overflow",
            Parent: "Advanced",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Overflow",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: Overflow
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "LayoutPosition",
            Parent: "Advanced",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Position",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: LayoutPosition
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "WhiteSpace",
            Parent: "Advanced",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@WhiteSpace",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: WhiteSpace
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });

        const cursors = [];
        CURSOR_TYPES.forEach(x => {
            cursors.push({
                Value: x,
                Style: { cursor: x }
            });
        });
        PROPERTIES.push({
            ID: "Cursor",
            Parent: "Advanced",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Cursor",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                ItemsSource: cursors,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Value",
                OptionStyle: "Style"
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });





        PROPERTIES.push({
            ID: "SetRotate",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@SetRotate",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        AdvancedPanel.SelectedItem.RotateX = null;
                        AdvancedPanel.SelectedItem.RotateY = null;
                        AdvancedPanel.SelectedItem.RotateZ = null;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [item.SelectedItem.ID],
                            ['RotateX', 'RotateY', 'RotateZ'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                    LayoutService.LayoutChanged.next(null);
                }
            }
        });
        PROPERTIES.push({
            ID: "RotateX",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "X",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType:"number"
            },
            CheckVisibility: (item) => {
                return item.RotateX != null || item.RotateY != null|| item.RotateZ != null || item.SetRotate
            }
        });
        PROPERTIES.push({
            ID: "RotateY",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "Y",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            },
            CheckVisibility: (item) => {
                return item.RotateX != null || item.RotateY != null || item.RotateZ != null || item.SetRotate
            }
        });
        PROPERTIES.push({
            ID: "RotateZ",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "Z",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            },
            CheckVisibility: (item) => {
                return item.RotateX != null || item.RotateY != null || item.RotateZ != null || item.SetRotate
            }
        });

        PROPERTIES.push({
            ID: "SetTranslate",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@SetTranslate",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        AdvancedPanel.SelectedItem.TranslateX = null;
                        AdvancedPanel.SelectedItem.TranslateY = null;
                        AdvancedPanel.SelectedItem.TranslateZ = null;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [item.SelectedItem.ID],
                            ['TranslateX', 'TranslateY', 'TranslateZ'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                    LayoutService.LayoutChanged.next(null);
                }
            }
        });
        PROPERTIES.push({
            ID: "TranslateX",
            Parent: "Advanced",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "X",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            },
            CheckVisibility: (item) => {
                return item.TranslateX != null || item.TranslateY != null || item.TranslateZ != null || item.SetTranslate
            }
        });
        PROPERTIES.push({
            ID: "TranslateY",
            Parent: "Advanced",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "Y",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            },
            CheckVisibility: (item) => {
                return item.TranslateX != null || item.TranslateY != null || item.TranslateZ != null || item.SetTranslate
            }
        });
        PROPERTIES.push({
            ID: "TranslateZ",
            Parent: "Advanced",
            Content: new ComponentPortal(UnitSelectionControl),
            Type: LayoutUnit,
            Label: "Z",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            },
            CheckVisibility: (item) => {
                return item.TranslateX != null || item.TranslateY != null || item.TranslateZ != null || item.SetTranslate
            }
        });

        PROPERTIES.push({
            ID: "SetScale",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@SetScale",
            LabelPosition: MenuTabLabelPosition.Right,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                Action: (item) => {
                    if (item && !item.Event) {
                        AdvancedPanel.SelectedItem.ScaleX = null;
                        AdvancedPanel.SelectedItem.ScaleY = null;
                        AdvancedPanel.SelectedItem.ScaleZ = null;
                        const mlpcv = new MultiPropertyChangeValue();
                        mlpcv.ElementIDs = [item.SelectedItem.ID],
                            ['ScaleX', 'ScaleY', 'ScaleZ'].forEach(x => {
                                mlpcv.Properties.push({
                                    PropertyName: x,
                                    Value: null
                                });
                            });
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        if (item.SelectedItem.ValuesChanged) {
                            item.SelectedItem.ValuesChanged.next();
                        }
                    }
                    LayoutService.LayoutChanged.next(null);
                }
            }
        });
        PROPERTIES.push({
            ID: "ScaleX",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "X",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number",
                Step:0.01
            },
            CheckVisibility: (item) => {
                return item.ScaleX != null || item.ScaleY != null || item.ScaleZ != null || item.SetScale
            }
        });
        PROPERTIES.push({
            ID: "ScaleY",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "Y",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number", Step: 0.01
            },
            CheckVisibility: (item) => {
                return item.ScaleX != null || item.ScaleY != null || item.ScaleZ != null || item.SetScale
            }
        });
        PROPERTIES.push({
            ID: "ScaleZ",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "Z",
            LabelPosition: MenuTabLabelPosition.Left,
            Column: 1,
            ColSpan: 2,
            Row: row++,
            InitArgs: {
                InputType: "number",Step: 0.01
            },
            CheckVisibility: (item) => {
                return item.ScaleX != null || item.ScaleY != null || item.ScaleZ != null || item.SetScale
            }
        });




        PROPERTIES.push({
            ID: "ZIndex",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Z-Index",
            Column: 1,
            Row: row,
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "TabIndex",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            Label: "@@Tab-Index",
            Column: 2,
            Row: row++,
            InitArgs: {
                InputType: "number"
            }
        });
        PROPERTIES.push({
            ID: "UseBoxed",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@UseBoxed",
            LabelPosition: MenuTabLabelPosition.Right,
            CheckVisibility: (item) => {
                return item.ElementType == 'repeat' || item.ElementType == 'flex' || item.ElementType == 'grid' || item.ElementType == 'raster' || item.ElementType == 'bootstrapgrid' || item.ElementType == 'bootstraprepeat' || item.ElementType == 'canvas';
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "BoxedSize",
            Parent: "Advanced",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@BoxedSize",
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                EnumSource: PrimeFlexSizes
            },
            CheckVisibility: (item) => {
                return item.UseBoxed;
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "FlexFill",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@FlexFill",
            LabelPosition: MenuTabLabelPosition.Right,
            CheckVisibility: (item) => {
                return item.Parent ? (item.Parent.ElementType == 'repeat' || item.Parent.ElementType == 'flex') : false;
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "DisableNavigation",
            Parent: "Advanced",
            Content: new ComponentPortal(InputswitchThemeControl),
            Label: "@@Disable Navigation",
            LabelPosition: MenuTabLabelPosition.Right,
            CheckVisibility: (item) => {
                return this.SelectedItem == this.SelectedLayout;
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
        PROPERTIES.push({
            ID: "DisableNavigationText",
            Parent: "Advanced",
            Content: new ComponentPortal(TextboxThemeControl),
            InitArgs: {
                InputType: "text"
            },
            CheckVisibility: (item) => {
                return this.SelectedItem == this.SelectedLayout && item.DisableNavigation == true;
            },
            Row: row++,
            Column: 1,
            ColSpan: 2
        });
    }
}