import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
	selector: 'lib-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
	LayoutEditMode = false;
	constructor(private cdRef: ChangeDetectorRef) {
	}
	ngOnInit(): void {
		LayoutService.LayoutEditMode.subscribe((x) => {
			this.LayoutEditMode = x;
			this.cdRef.detectChanges();
	});
	}
}
