import { Component } from '@angular/core';
import { Type } from 'class-transformer';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { LayoutHelper } from '../../../../helpers/layout.helper';
import { VariableHelper } from '../../../../helpers/variable.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { Variable } from '../../../../models/variable.model';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'wf-setVariable-settings',
    templateUrl: './setVariable.settings.html',
    styleUrls: ['./setVariable.settings.css']
})
export class SetVariableSettings extends WorkflowDialogContent {
    static MultiModuleID = 'setMultiLayoutVariableModule';

    ElemName: string;
    Variables = [];
    SystemVariables = [];
    ElemNameInit = true;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setLayoutVariableModule';
        reg.Caption = '@@Variable setzen';
        reg.SettingsControl = SetVariableSettings;
        reg.SettingsTypeHelper = new SetVariableSettingsDataHelper();
        reg.Executer = SetVariableModuleExecuter;
        reg.GroupID = 'variables';
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = SetVariableSettings.MultiModuleID;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.ElemName) {
                this.ElemName = data.ElemName;
            }
            if (data.Variables) {
                const list = [];
                data.Variables.forEach(function (v) {
                    list.push({
                        Data: {
                            Name: v.Name,
                            Type: VariableHelper.GetTypeListTypeFromJSType(typeof v.Value),
                            Value: v.Value
                        },
                        UseParam: v.UseActualParam
                    });
                });
                this.Variables = list;
            }
        }
    }

    getResult(): any {
        const retVal = new SetVariableSettingsData();
        retVal.ElemName = this.ElemName;
        this.Variables.forEach(function (v) {
            if (v.Data && v.Data.Name) {
                const ve = new VariableEntry();
                ve.Name = v.Data.Name;
                if (v.UseParam) {
                    ve.UseActualParam = true;
                    retVal.Variables.push(ve);
                } else if (typeof v.Data.Value !== 'undefined' && v.Data.Value !== null) {
                    ve.Value = v.Data.Value;
                    retVal.Variables.push(ve);
                }
            }
        });
        return retVal;
    }

    addVariable() {
        this.Variables.push({
            Data: new Variable(),
            UseParam: false
        });
    }

    deleteVariable(i) {
        this.Variables.splice(i, 1);
    }

    SelectedElemChanged() {
        if (this.ElemNameInit) {
            this.ElemNameInit = false;
        } else {
            this.Variables = [];
        }

        const element = LayoutHelper.getElementByName(this.WFEditOptions.Layout.Elements, this.ElemName);
        if (element) {
            const vars = FilterHelper.GetVariables(element);
            const sysVars = [];
            vars.forEach(x => {
                const variable = new Variable();
                variable.Name = x.Name;
                variable.Type = x.Type;
                sysVars.push(variable);
            });
            this.SystemVariables = sysVars;
        }
    }

    compareFn(item1, item2) {
        return item1 && item2 && item1.Name === item2.Name;
    }
}

// @dynamic
export class SetVariableSettingsData {
    ElemName: string;
    @Type(() => VariableEntry)
    Variables: VariableEntry[] = [];
}

export class VariableEntry {
    Name: string;
    Value;
    UseActualParam = false;
    UseVariable = false;
}

export class SetVariableSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetVariableSettingsData();
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class SetVariableModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.Variables && status.ActualSettings.Variables.length > 0) {
                if (typeof status.ActualSettings.ElemName === 'string') {
                    const variableResult = status.WorkflowLayoutService.getVariablesByName(status.ActualSettings.ElemName);
                    if (variableResult.ElemFound) {
                        if (variableResult.Variables.length > 0) {
                            let success = true;
                            status.ActualSettings.Variables.some(v => {
                                const val = v.UseActualParam ? status.ActualParameter : v.Value;
                                let nameFound = false;
                                let correctType = false;
                                variableResult.Variables.some(ev => {
                                    if (ev.Name === v.Name) {
                                        nameFound = true;
                                        if (VariableHelper.CheckValueForType(ev.Type, val)) {
                                            correctType = true;
                                            ev.Value = val;
                                            VariableHelper.ValueChanged(status.WorkflowLayoutService.Layout, ev);
                                            return true;
                                        }
                                    }
                                    return false;
                                });
                                if (nameFound) {
                                    if (!correctType) {
                                        success = false;
                                        status.Logger.logError('SetVariable module: Types don\'t match for variable \'' +
                                            v.Name + '\'.');
                                        return true;
                                    }
                                } else {
                                    success = false;
                                    status.Logger.logError('SetVariable module: Variable \'' + v.Name + '\' not found');
                                    return true;
                                }
                                return false;
                            });
                            if (success) {
                                return 0;
                            }
                        } else {
                            status.Logger.logError('SetVariable modul: No variables found.');
                        }
                    } else {
                        status.Logger.logError('SetVariable modul: Element not found.');
                    }
                } else {
                    status.Logger.logError('SetVariable modul: No element name found.');
                }
            }
        } else {
            status.Logger.logError('SetVariable modul: No settings found.');
        }
        return super.execute(status);
    }
}
