import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { LayoutService } from '../../../services/layout.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';
import { SetPropertySettingsData, SetPropertySettings } from './setProperty.settings';

@Component({
    selector: 'wf-setmultiproperty-settings',
    templateUrl: './set.multi.property.settings.html'
})
export class SetMultiPropertySettings extends FormulaWorkflowDialogContent {

    Data = new SetMultiPropertySettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetPropertySettings.MultiModuleID;
        reg.Caption = '@@Set properties';
        reg.SettingsControl = SetMultiPropertySettings;
        reg.SettingsTypeHelper = new SetMultiPropertySettingsDataHelper();
        reg.Executer = SetMultiPropertyModuleExecuter;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(SetMultiPropertySettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Properties.forEach(x => {
            if (x.PropertyName && x.Formula) {
                values.push(x);
            }
        });
        this.Data.Properties = values;
        return this.Data;
    }

    addValue() {
        if (this.Data) {
            if (this.Data.Properties) {
                this.Data.Properties.push(new SetPropertySettingsData());
            } else {
                this.Data.Properties = [new SetPropertySettingsData()];
            }
        }
    }

    removeValue(i) {
        if (this.Data && this.Data.Properties) {
            this.Data.Properties.splice(i, 1);
        }
    }
}

// @dynamic
export class SetMultiPropertySettingsData extends AServiceWorkflowData {
    @Type(() => SetPropertySettingsData)
    Properties: SetPropertySettingsData[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.ReadProperty.SetMultiPropertySettingsData';
    }
}

export class SetMultiPropertySettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new SetMultiPropertySettingsData();
    }
}

export class SetMultiPropertyModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualParameter) {
            if (status.ActualSettings) {
                const calculator = new WorkflowFormulaCalculator(status);
                status.ActualSettings.Properties.forEach(prop => {
                    if (prop.PropertyName && prop.Formula) {
                        let val = status.ActualParameter;
                        const propList = prop.PropertyName.split('.');
                        for (let i = 0; i < propList.length - 1; i++) {
                            val = val[propList[i]];
                            if (typeof val !== 'object') {
                                propList.splice(-1, 1);
                                status.Logger.logWarning('SetMultiProperty modul: Property ' + propList.join('.') + ' not found.');
                                return;
                            }
                        }
                        try {
                            val[propList[propList.length - 1]] = calculator.CalcFormula(prop.Formula);
                            LayoutService.PropertyChanged.next(prop);
                        } catch (ex) {
                            status.Logger.logWarning('SetMultiProperty modul: Error while calculating value for property ' +
                                prop.PropertyName + ' (' + ex + ')');
                        }
                    }
                });
                return 0;
            } else {
                status.Logger.logError('SetMultiProperty modul: No settings found.');
            }
        } else {
            status.Logger.logError('SetMultiProperty modul: No object found to set properties on.');
        }
        return super.execute(status);
    }
}
