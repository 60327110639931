import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { SideNavService } from '../../../services/sidenav.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-nav-layout',
    templateUrl: './navigation.layout.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationLayout extends IBaseComponent {
    static Type: any = 'navicontrol';
    static Default = {
        Editable: true,
        Type: 'navicontrol'
    };

    NavigationItems = [];
    Instance;
    URL;
    dragType;
    dragItem;
    ContentItem;
    IsLayoutPage = false;

    private static BuildStructure(navList, url) {
        const retVal = [];
        if (navList) {
            navList.forEach(x => {
                if (x.IsVisible && !x.InVisible) {
                    let icon = 'radio_button_unchecked';
                    if (x.Icon) {
                        icon = x.Icon;
                    }
                    if (x.Url) {
                        retVal.push({
                            Caption: x.Label,
                            Selected: url === x.Url,
                            Icon: icon,
                            Url: x.Url
                        });
                    } else if (x.children && x.children.length > 0) {
                        const children = NavigationLayout.BuildStructure(x.children, url);
                        if (children.length > 0) {
                            retVal.push({
                                Caption: x.Label,
                                Selected: children.some(y => y.Selected),
                                Icon: icon,
                                Children: children
                            });
                        }
                    }
                }
            });
        }
        return retVal;
    }

    constructor(private router: Router, private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.Instance = this;
        this.URL = router.url;
        this.Subscriptions['Router'] = router.events.subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                this.URL = ev.url;
                this.checkNav(SideNavService.SelectedNavigation.getValue());
            }
        });
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
    }
    ControlInitialized() {
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.checkNav(SideNavService.SelectedNavigation.getValue());
        this.Subscriptions['SelectedNavigation'] = SideNavService.SelectedNavigation.subscribe((nav) => { this.checkNav(nav); });
    }

    onLayoutElementSet() {
        if (this.LayoutElementValue && this.LayoutElementValue.Elements && this.LayoutElementValue.Elements.length > 0) {
            this.ContentItem = this.LayoutElementValue.Elements[0];
        } else {
            this.ContentItem = null;
        }
    }

    checkNav(nav) {
        if (this.URL === '/layout') {
            this.IsLayoutPage = true;
        } else {
            this.IsLayoutPage = false;
            if (nav) {
                this.NavigationItems = NavigationLayout.BuildStructure(nav.NavigationItems, this.URL);
            } else {
                this.NavigationItems = [];
            }
        }
    }

    OnNavClicked(nav) {
        if (nav && nav.Url) {
            this.router.navigateByUrl(nav.Url);
        }
    }

    itemSelected(item) {
        if (!item.Selected) {
            LayoutService.SelectedItem.next(item);
        }
    }

    removeItem(item) {
        if (this.LayoutElementValue && this.LayoutElementValue.Elements) {
            const index = this.LayoutElementValue.Elements.indexOf(item);
            if (index > -1) {
                this.LayoutElementValue.Elements.splice(index, 1);
                this.onLayoutElementSet();
                LayoutService.SelectedItem.next(null);
                LayoutService.OnLayoutElementRemoved({
                    ElementID: item.ID,
                    ElementName: item.Name ? item.Name : item.ID,
                    ParentID: this.LayoutElementValue.ID
                });
            }
        }
    }

    itemDrop(ev) {
        if (this.LayoutElementValue) {
            if (!this.LayoutElementValue.Elements) {
                this.LayoutElementValue.Elements = [];
            }
            if (this.LayoutElementValue.Elements.length === 0) {
                let insert;
                insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
                insert.Parent = this.LayoutElement;
                this.LayoutElement.Elements.push(insert);
                LayoutService.OnLayoutElementAdded({
                    ElementContent: JSON.stringify(insert),
                    Index: 0,
                    ParentID: this.LayoutElementValue.ID
                });
            }
        }
        ev.currentTarget.style.backgroundColor = 'transparent';
    }
}
