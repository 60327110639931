import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { MultiResult } from '../../../models/datadescription/multi/multiresult.model';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { PageStatus } from '../../../models/page.status';
import { AExecutionManipulation } from './a.execution.manipulation';

export class ViewManipulation extends AExecutionManipulation {
    async BeforeSplit() {
        if (this.Context && this.Context.BaseReportObject && this.Context.BaseReportObject.LayoutElement) {
            const query = this.Context.BaseReportObject.LayoutElement.Query;
            if (query) {
                const state = PageStatus.ActualState.getValue();
                if (state && state.ViewInfos) {
                    const vi = state.ViewInfos.find(x => x.QueryID === query);
                    if (vi && vi.Entries) {
                        const dd: DataDescription = this.Context.DataDescriptionClone;
                        if (dd) {
                            const levelMap = new Map<number, VisibilityType>();
                            const measures = [];
                            [dd.XLevelNodes, dd.YLevelNodes].forEach(axis => {
                                axis.Areas.forEach(area => {
                                    area.Tuples.forEach(t => {
                                        t.Levels.forEach(l => {
                                            levelMap.set(l.UniqueID, VisibilityType.None);
                                        });
                                    });
                                    area.Measures.forEach(m => {
                                        measures.push(m);
                                    });
                                });
                            });
                            vi.Entries.forEach(entry => {
                                if (entry.Settings && entry.Settings.length > 0) {
                                    if (entry.ObjectIDs && entry.ObjectIDs.length > 0 &&
                                        !entry.ObjectIDs.some(x => x === this.Context.BaseReportObject.LayoutElement.ID)) {
                                        return;
                                    }
                                    entry.Settings.forEach(setting => {
                                        if (setting.VisibleState !== VisibilityType.None) {
                                            if (levelMap.has(setting.ElementID)) {
                                                const val = levelMap.get(setting.ElementID);
                                                if (val !== VisibilityType.NonVisibleForce && val !== VisibilityType.VisibleForce) {
                                                    levelMap.set(setting.ElementID, setting.VisibleState);
                                                }
                                            } else {
                                                const measure = measures.find(x => x.UniqueID === setting.ElementID);
                                                if (measure && measure.VisibilityType !== VisibilityType.NonVisibleForce &&
                                                    measure.VisibilityType !== VisibilityType.VisibleForce) {
                                                    measure.VisibilityType = setting.VisibleState;
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                            const hiddenLevels = [];
                            [dd.XLevelNodes, dd.YLevelNodes].forEach(axis => {
                                axis.Areas.forEach(area => {
                                    for (let i = area.Tuples.length - 1; i >= 0; i--) {
                                        const t = area.Tuples[i];
                                        for (let j = t.Levels.length - 1; j >= 0; j--) {
                                            const id = t.Levels[j].UniqueID;
                                            const v = levelMap.get(id);
                                            if (v === VisibilityType.NonVisibleDefault || v === VisibilityType.NonVisibleForce) {
                                                t.Levels.splice(j, 1);
                                                hiddenLevels.push(id);
                                            }
                                        }
                                        if (t.Levels.length === 0) {
                                            area.Tuples.splice(i, 1);
                                        }
                                    }
                                });
                            });
                            this.Context.HiddenLevels = hiddenLevels;
                        }
                    }
                }
            }
        }
    }
    async BeforeExecute() {
    }
    async AfterExecute(result: MultiResult) {
    }
}
