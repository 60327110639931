import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { NotificationLevel } from '../../../models/enums/notificationlevel.enum';
import { SchedulerService } from '../../../services/scheduler.service';
import { WebsocketService } from '../../../services/websocket.service';

@Component({
    selector: 'job-info-dialog',
    templateUrl: './job.info.dialog.html'
})
export class JobInfoDialog implements OnInit {

    RunID;
    Result;
    editorOptions = {
        language: 'json',
        readOnly: true,
        scrollBeyondLastLine: false
    };

    static ShowDialog(runID) {
        BaseDialog.ShowDialog({
            ContentType: JobInfoDialog,
            InitArgs: {
                RunID: runID
            },
            FullScreen: true,
            Buttons: DialogButton.Ok
        });
    }

    constructor(private service: SchedulerService, private cdRef: ChangeDetectorRef) {
    }

    Initialize(args) {
        if (args && args.RunID) {
            this.RunID = args.RunID;
        }
    }

    ngOnInit(): void {
        if (this.RunID) {
            WebsocketService.WaitForWebSocketService().then(() => {
                const channel = UUID.UUID();
                const subScription = WebsocketService.MessageReceived.subscribe((response) => {
                    const parsed = JSON.parse(response);
                    if (parsed.Level === NotificationLevel.System &&
                        parsed.Channels && parsed.Channels.some(x => x === channel) &&
                        parsed.Type === 'evidanza.Core.Base.Scheduler.TriggerDetails') {
                        subScription.unsubscribe();
                        WebsocketService.Unsubscribe([{ Channel: channel, Level: [0] }]);
                        this.Result = parsed.Content;
                        this.cdRef.detectChanges();
                    }
                });
                WebsocketService.Subscribe([{ Channel: channel, Level: [0] }]).then(() => {
                    this.service.GetTriggerDetails(this.RunID, channel).subscribe();
                });
            });
        }
    }
}
