import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { TranslatedString } from '../../../models/translatedstring.model';
import { NavigationService } from '../../../services/navigation.service';
import { RestService } from '../../../services/rest.service';
import { BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import { RestItem } from '../models/rest.item';
import { EndPointRestComponent } from './end.point.settings/end.point.rest/end.point.rest';
import { MappingRestComponent } from './end.point.settings/mappings.rest/mapping.rest';
import { RestObjectsComponent } from './end.point.settings/rest.objects/rest.objects';
import { TemplateRestComponent } from './end.point.settings/templates.rest/templates.rest';
import { WebhookRestComponent } from './end.point.settings/webhook.rest/webhook.rest';
import { RestOverviewComponent } from './rest.overview.component';

@Component({
    selector: 'rest-overview-settings',
    templateUrl: '../../base.list.settings.html'
})
export class RestOverviewSettings extends BaseListSettings {
    static ModelNode: BehaviorSubject<any> = new BehaviorSubject(null);

    public Rows: any[] = [];

    //public static GetSettingsEntry() {
    //    return {
    //        Caption: '@@Restschnittstelle',
    //        ID: 'rests',
    //        Icon: 'rest_api',
    //        Index: 40,
    //        Parent: 'data',
    //        Children: [],
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
    //            Value: 1
    //        },
    //        UpdateFunction: RestOverviewSettings.UpdateModelNodes
    //    };
    //}
    public static CreateNode(r) {
        const settings = {
            Caption: r.Caption.DefaultValue,
            ID: r.SID,
            Icon: 'rest_api',
            Index: 0,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
                Value: 2
            },
            Children: []
        };

        //#region Children
        const children = [];
        let i = 0;
        children.push({
            Caption: '@@Objekte',
            ID: 'objects',
            Icon: 'description',
            Index: i++,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
                Value: 4
            },
            Content: RestObjectsComponent,
            InitArgs: r
        });
        children.push({
            Caption: '@@Endpunkte',
            ID: 'endpoints',
            Icon: 'endpoint',
            Index: i++,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
                Value: 8
            },
            Content: EndPointRestComponent,
            InitArgs: r
        });
        children.push({
            Caption: '@@Vorlagen',
            ID: 'templates',
            Icon: 'paste',
            Index: i++,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
                Value: 16
            },
            Content: TemplateRestComponent,
            InitArgs: r
        });
        children.push({
            Caption: '@@Mappings',
            ID: 'mappings',
            Icon: 'compare_arrows',
            Index: i++,
            Security: {
                Name: 'evidanza.MiddleWare.Shared.Security.RestRight',
                Value: 32
            },
            Content: MappingRestComponent,
            InitArgs: r
        });
        children.push({
            Caption: '@@Webhooks',
            ID: r.SID + '_webhooks',
            Icon: 'paste',
            Index: i++,
            Content: WebhookRestComponent,
            InitArgs: r
        });
        settings.Children = children;
        //#endregion
        return settings;
    }

    public static UpdateModelNodes(nav) {
        if (nav) {
            RestOverviewSettings.ModelNode.next(nav);
            nav.Children = [];
            let index = 0;
            const list = [];
            list.push({
                Caption: '@@Uebersicht',
                ID: 'rest-overview',
                Icon: 'menu',
                Index: index++,
                Content: RestOverviewSettings
            });

            const service = InjectorHelper.InjectorInstance.get<RestService>(RestService);
            if (service) {
                service.loadAll().subscribe(result => {
                    result.forEach(r => {
                        const item = RestOverviewSettings.CreateNode(r);
                        item.Index = index++;
                        list.push(item);
                    });
                    nav.Children = list;
                    NavigationService.Refresh.next(null);
                });
            }
        }
    }

    constructor(private _service: RestService, private _translate: TranslateService,
        factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef) {
        super(factoryResolver, cdRef);
    }

    loadList(handler: any) {
        this._service.LoadAllRestItemInfos().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach(r => {
                    list.push({
                        Caption: r.Caption,
                        ID: r.SID,
                        IsCapsule: r.IsCapsule,
                        IsOverridden: r.IsOverridden
                    });
                });
                handler(list);
            }
        });
    }

    loadData(data: any) {
        this._service.load(data).subscribe(result => {
            if (result) {
                this.setSelectedItem(new RestItem(result));
            }
        });
    }

    getNewItem() {
        const retVal = new RestItem();
        retVal.Caption = new TranslatedString(this._translate.instant('@@Neue Rest'));
        retVal.DefaultPayloadPath = '';
        return retVal;
    }

    getDeleteText(sel: any): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Restschnitstelle \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@Restschnitstelle \'{0}\' erfolgreich geloescht');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@Restschnitstelle loeschen');
        return retVal;
    }

    delete(data: any, handler: any) {
        this._service.remove(data).subscribe(() => {
            handler();
            const node = RestOverviewSettings.ModelNode.getValue();
            if (node && node.Children) {
                let index = -1;
                for (let i = 0; i < node.Children.length; i++) {
                    if (data === node.Children[i].ID) {
                        index = i;
                    }
                }
                if (index > -1) {
                    node.Children.splice(index, 1);
                }
            }
        });
    }

    getSaveSuccessText(sel: any): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@Restschnitstelle \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(TranslatedString.GetTranslation(sel.Caption));
        retVal.Title = new TranslateFormatText('@@Restschnitstelle speichern');
        return retVal;
    }
    handleNew(item, result) {
        if (!item.SID) {
            item.SID = result.SID;
        }
        if (!item.Version) {
            item.Version = result.Version;
        }
        const node = RestOverviewSettings.ModelNode.getValue();
        if (node && node.Children) {
            const child = RestOverviewSettings.CreateNode(item);
            child.Index = node.Children.length;
            node.Children.push(child);
        }
    }
    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    saveInternal(item: any, handler: any) {
        this._service.save(item as RestItem).subscribe(result => {
            if (result) {
                const node = RestOverviewSettings.ModelNode.getValue();
                if (node && node.Children) {
                    node.Children.forEach((n) => {
                        if (n.ID === result.SID) {
                            n.Caption = result.Caption;
                        }
                    });
                }
                handler(result, result.SID, result.Caption);
            }
        });
    }

    getContentType() {
        return RestOverviewComponent;
    }
}
