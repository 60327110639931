import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class DataService extends RequestBase {
    BasePath = 'api/data';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetList(endpoint, method): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + 'api/' + endpoint + '/' + method, this.options);
    }

    GetListWithID(endpoint, method, id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + 'api/' + endpoint + '/' + method + '?id=' + id, this.options);
    }
    GetFlatTable(id, recursive): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + 'api/dynamic/GetFlatTable?id=' + id + '&recursive=' + recursive, this.options);
    }
    GetFlatDataTable(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + 'api/dynamic/GetFlatDataTable?id=' + id, this.options);
    }
    GetItem(endpoint, method, id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + 'api/' + endpoint + '/' + method + '?id=' + id, this.options);
    }

    Save(endpoint, data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + 'api/' + endpoint + '/Save', data, this.options);
    }

    Delete(endpoint, id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + 'api/' + endpoint + '/Delete?id=' + id, this.options);
    }

    SearchObjects(endpoint, classId, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + 'api/' + endpoint + '/SearchObjects?classId=' + classId, filter, this.options);
    }
    CountObjects(endpoint, classId, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + 'api/' + endpoint + '/CountObjects?classId=' + classId, filter, this.options);
    }
}
