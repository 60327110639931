import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { DataModelService } from '../../../services/datamodel.service';

@Component({
    selector: 'change-datasource-action',
    templateUrl: './changedatasource.action.html',
    styleUrls: ['./changedatasource.action.css']
})
export class ChangeDataSourceAction implements OnInit {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    @Output() OnValuesChanged = new EventEmitter<any>();
    DataModels;
    DataSources;
    constructor(private dataService: DataModelService) {

    }

    ngOnInit(): void {
        this.dataService.GetModels().subscribe(models => {
            if (models) {
                var list = [];
                models.forEach(function (model) {
                    list.push({ Caption: model.Caption, ID: model.SID });
                });
                this.DataModels = list;
            }
        });
        this.LoadDataSources();
    }
    LoadDataSources() {
        this.DataSources = [];
        if (this.SelectedItem && this.SelectedItem.DataModelID) {
            this.dataService.GetDataSources(this.SelectedItem.DataModelID).subscribe(sources => {
                const list = [];
                sources.forEach(function (source) {
                    list.push({ Caption: source.Caption, ID: source.SID });
                });
                this.DataSources = list;
            });
        }
    }

    ValuesChanged() {
        this.OnValuesChanged.emit();
    }
    DataModelChanged() {
        this.LoadDataSources();
        this.ValuesChanged();
    }
}