import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowRegistry } from '../../../../services/workflow.service';
import { ElementValueSettings, ElementValueSettingsDataHelper } from '../elemValue/elemValue.settings';

export class SetFocusModulExecuter extends WorkflowModuleExecuter {

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'setFocusModule';
        reg.Caption = '@@Focus setzen';
        reg.GroupID = 'layout';
        reg.Index = 50;
        reg.SettingsControl = ElementValueSettings;
        reg.SettingsTypeHelper = new ElementValueSettingsDataHelper();
        reg.Executer = SetFocusModulExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {
                    if (elem.Element) {
                        if (typeof elem.Element.setFocus === 'function') {
                            elem.Element.setFocus();
                        }
                        return 0;
                    } else {
                        status.Logger.logError('SetFocus modul: Control not found.');
                    }
                } else {
                    status.Logger.logError('SetFocus modul: Element not found.');
                }
            } else {
                status.Logger.logError('SetFocus modul: No element name found.');
            }
        } else {
            status.Logger.logError('SetFocus modul: No settings found.');
        }
        return super.execute(status);
    }
}
