import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'callback',
  pure: false
})
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({
    name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
    transform(data: Object) : any {
        let keys = [];
        for (var enumMember in data) {
            var isValueProperty = parseInt(enumMember, 10) >= 0
            if (isValueProperty) {
                keys.push({ key: enumMember, value: data[enumMember] });
            }
        }
        return keys;
    }
}


@Pipe({
    name: 'NavigationVisibility'
})
export class NavigationVisibilityPipe implements PipeTransform {
    transform(data: any[]): any {
        return data.filter((value) => value.SecurityValue);
    }
}

@Pipe({
    name: 'filtercolumns',
    pure: false
})
export class FilterColumnsPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        return items.filter(item => item.FetchData);
    }
}