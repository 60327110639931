import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { CapsuleHelper } from '../../../helpers/capsule.helper';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { OpenAIDBInfo, TYPE_DICT } from '../../../models/datamodel/adbinfo.model';
import { DataSource } from '../../../models/datamodel/datasource.model';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { DataModelService } from '../../../services/datamodel.service';
import { StandardRequestBase } from '../../../services/request-base';
import { DataCheck } from '../../../workflow/workflow.dialog';
import {
	BaseListSettings,
	DeleteTexts,
	SaveTexts,
} from '../../base.list.settings';
import { ADataModelDetail } from '../common/adatamodeldetail';
import config from '../../../../assets/config.json'
import { LayoutService } from "../../../services/layout.service";
@Component({
	selector: 'datasource-settings',
	templateUrl: '../../base.list.settings.html',
})
export class DataSourceSettings extends BaseListSettings {
	constructor(
		private translate: TranslateService,
		private dataService: DataModelService,
		protected factoryResolver: ComponentFactoryResolver,
		protected cdRef: ChangeDetectorRef
	) {
		super(factoryResolver, cdRef);
		this.ShowDelete = PermissionHelper.IsAdmin();
	}

	getContentType() {
		return DataSourceDetail;
	}

	loadList(handler) {
		if (this.InitArgs) {
			this.dataService
				.GetDataSources(this.InitArgs.SID)
				.subscribe((sources) => {
					const list = [];
					sources.forEach((source) => {
						list.push({
							Caption: source.Caption,
							ID: source.SID,
							IsCapsule: source.IsCapsule,
							IsOverridden: source.IsOverridden,
						});
					});
					handler(list);
				});
		}
	}

	loadData(data) {
		this.dataService.GetDataSource(data).subscribe((datasource) => {
			const ds = plainToClass(DataSource, datasource);
			this.setSelectedItem(ds);
		});
	}

	getNewItem() {
		const ds = new DataSource();
		const name = 'DataSource_';
		let length = 1;
		if (this.ListItems.length > 0) {
			length = this.ListItems.length + 1;
		}
		ds.Name = name + length;
		ds.Caption = new TranslatedString(
			this.translate.instant('@@Neue Datenquelle')
		);
		if (this.InitArgs) {
			ds.DataModelID = this.InitArgs.SID;
		}
		return ds;
	}

	// getDeleteText(sel): DeleteTexts {
	// 	const retVal = new DeleteTexts();
	// 	retVal.Question = new TranslateFormatText(
	// 		"@@Sind Sie sicher, dass Sie die Datenquelle '{0}' loeschen moechten?"
	// 	);
	// 	retVal.Question.FormatParams.push(sel.Caption);
	// 	retVal.Success = new TranslateFormatText(
	// 		"@@Datenquelle '{0}' erfolgreich geloescht."
	// 	);
	// 	retVal.Success.FormatParams.push(sel.Caption);
	// 	retVal.Title = new TranslateFormatText('@@Datenquelle loeschen');
	// 	return retVal;
	// }

	getSaveSuccessText(sel): SaveTexts {
		let caption = TranslatedString.GetTranslation(sel.Caption);
		if (!caption) {
			caption = sel.Name;
		}
		const retVal = new SaveTexts();
		retVal.Text = new TranslateFormatText(
			"@@Datenquelle '{0}' erfolgreich gespeichert."
		);
		retVal.Text.FormatParams.push(caption);
		retVal.Title = new TranslateFormatText('@@Datenquelle speichern');
		return retVal;
	}

	delete(data, handler) {
		this.dataService.DeleteSource(data).subscribe((res) => {
			handler(res);
		});
	}
	getDeleteText(sel): DeleteTexts {
		const retVal = new DeleteTexts();
		retVal.Question = new TranslateFormatText('@@Are you sure you want to delete data source \'{0}\'?');
		retVal.Question.FormatParams.push(sel.Caption);
		retVal.Success = new TranslateFormatText('@@Data source \'{0}\' successfully deleted.');
		retVal.Success.FormatParams.push(sel.Caption);
		retVal.Title = new TranslateFormatText('@@Delete data source');
		return retVal;
	}

	// 	getSaveSuccessText(sel): SaveTexts {
	// 		let caption = TranslatedString.GetTranslation(sel.Caption);
	// 		if (!caption) {
	// 				caption = sel.Name;
	// 		}
	// 		const retVal = new SaveTexts();
	// 		retVal.Text = new TranslateFormatText('@@Data source \'{0}\' saved successfully.');
	// 		retVal.Text.FormatParams.push(caption);
	// 		retVal.Title = new TranslateFormatText('@@Save data source');
	// 		return retVal;
	// }
	async checkHasChanges() {
		if (this.HasChanges) {
			const retVal = await MessageBoxHelper.ShowDialog(
				new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
				new TranslateFormatText('@@Frage'),
				MessageBoxButtons.YesNoAbort,
				MessageBoxIcon.Question
			);
			if (retVal === MessageBoxResult.Yes) {
				if (
					this.Component &&
					this.Component.SelectedItem &&
					this.Component.SelectedItem.Connection &&
					this.Component.SelectedItem.Connection.indexOf('Password') > -1
				) {
					let conn = JSON.parse(this.Component.SelectedItem.Connection);
					if (!conn.Password) {
						let passwd = await MessageBoxHelper.ShowDialog(
							new TranslateFormatText(
								'@@Es wurde kein Passwort gesetzt, wollen Sie die Aenderungen speichern?'
							),
							new TranslateFormatText('@@Frage'),
							MessageBoxButtons.YesNoAbort,
							MessageBoxIcon.Question
						);
						if (passwd === MessageBoxResult.Yes) {
							return await this.save(false);
						} else if (passwd === MessageBoxResult.Abort) {
							return false;
						} else {
							// No
							this.setHasChanges(false);
						}
					} else {
						return await this.save(false);
					}
				} else {
					return await this.save(false);
				}
			} else if (retVal === MessageBoxResult.Abort) {
				return false;
			} else {
				// No
				this.setHasChanges(false);
			}
		}
		return true;
	}
	// Function to validate if the object contains all properties of the class
	protected validateObjectProperties(obj, optionalProps = []) {
		for (let key in obj) {
			if (
				obj.hasOwnProperty(key) &&
				(obj[key] === null || obj[key] === undefined)
			) {
				if (optionalProps.indexOf(key) < 0)
					return false; // If any property value is null or undefined, return false
			}
		}
		return true; // All properties have non-null values
	}
	protected async checkCanSave(): Promise<DataCheck> {
		const retVal = new DataCheck();
		if (this.Component) {
			const selItem = this.Component.SelectedItem;
			if (selItem) {
				if (selItem.DBInfo) {
					if (!this.validateObjectProperties(selItem.DBInfo, ["DTExecPath"])) {
						retVal.Error = 'Please fill in all fields';
						retVal.IsCorrect = false;
						retVal.IsShowPopup = false;
						return retVal;
					}
					if (
						this.Component.SelectedDBInfo &&
						this.Component.SelectedDBInfo.OnlyAgent &&
						!selItem.DBInfo.AgentId
					) {
						retVal.Error = 'Please set an agent';
						retVal.IsCorrect = false;
					}
				} else {
					retVal.Error = 'Please define a data source';
					retVal.IsCorrect = false;
				}
			}
		}
		return retVal;
	}

	saveInternal(item, handler) {
		if (item?.Name.trim() == '') {
			NotificationHelper.Error('Name field is required', '@@Error');
			LayoutService.Loading.next(false);
			return;
		}
		this.dataService.SaveDataSource(item).subscribe((result) => {
			if (result) {
				handler(result, result.SID, result.Caption);
			}
		});
	}

	handleNew(item, result) {
		item.SID = result.SID;
		item.Version = result.Version;
		item.InternalID = result.InternalID;
	}

	updateListItem(item, result) {
		item.IsCapsule = result.IsCapsule;
		item.IsOverridden = result.IsOverridden;
	}
}

@Component({
	selector: 'datasource-detail',
	templateUrl: './datasource.settings.html',
	styleUrls: ['./datasource.settings.css'],
})
export class DataSourceDetail extends ADataModelDetail {
	Drivers = [];
	Agents = [];
	SelectedDBInfo;
	Subscription;
	HasCapsules = false;

	@Input() isDisabledDbType = false;
	@Input() ShowAgents = true;

	@ViewChild('dynamic', { read: ViewContainerRef })
	viewContainerRef: ViewContainerRef;

	@Input()
	get DataSource() {
		return this.SelectedItem;
	}
	set DataSource(val) {
		if (val != this.SelectedItem) {
			this.setSelectedItem(val);
			this.DataSourceChange.emit(val);
		}
	}
	@Output() DataSourceChange = new EventEmitter<any>();

	get SelectedAgent() {
		if (
			this.SelectedItem &&
			this.SelectedItem.DBInfo &&
			this.SelectedItem.DBInfo.AgentId
		) {
			return this.SelectedItem.DBInfo.AgentId;
		}
		return '0';
	}
	set SelectedAgent(val) {
		if (this.SelectedItem && this.SelectedItem.DBInfo) {
			if (val === '0') {
				this.SelectedItem.DBInfo.AgentId = null;
				this.SelectedItem.DBInfo.UseAgent = false;
			} else {
				this.SelectedItem.DBInfo.AgentId = val;
				this.SelectedItem.DBInfo.UseAgent = true;
			}
		}
	}

	constructor(
		private translate: TranslateService,
		private dataService: DataModelService,
		private agentService: StandardRequestBase,
		private factoryResolver: ComponentFactoryResolver
	) {
		super();
	}

	ngOnInit() {
		this.dataService.GetDrivers().subscribe((drivers) => {
			if (drivers) {
				let driversToAdd = drivers
				const userRole = JSON.parse(localStorage.getItem('user'))?.Roles;
				if (userRole && userRole.length > 0 && userRole[0] === config.FREE_USER_ID) {
					this.Drivers = drivers.filter(driver => driver.Name !== 'ROLAP' && TYPE_DICT.has(driver.DBInfoType))
					return;
				}
				this.Drivers = driversToAdd.filter((x) => TYPE_DICT.has(x.DBInfoType));
			}
			this.SetData();
		});
		this.agentService
			.executeGet('config/api/agent', 'GetAllAgents')
			.subscribe((agents) => {
				const agentList = [];
				agentList.push({
					Name: this.translate.instant('@@None'),
					Id: '0',
				});
				if (agents) {
					agentList.push(...agents);
				}
				this.Agents = agentList;
				this.SetData();
			});
		CapsuleHelper.GetCapsuleTags().then((r) => {
			this.HasCapsules = r != null;
		});
	}

	setSelectedItem(item) {
		super.setSelectedItem(item);
		this.SetData();
	}
	SetData() {
		if (this.SelectedItem && this.Drivers && this.Agents) {
			let selectedDBInfo;
			if (this.SelectedItem) {
				if (this.SelectedItem.Connection) {
					this.SelectedItem.DBInfo = JSON.parse(this.SelectedItem.Connection);
					const type = TYPE_DICT.get(this.SelectedItem.DBInfoType);
					if (type) {
						this.SelectedItem.DBInfo = plainToClass(
							type.DBInfoType,
							this.SelectedItem.DBInfo
						);
					}
				}
				selectedDBInfo = this.Drivers.find(
					(x) => x.DBInfoType === this.SelectedItem.DBInfoType
				);
			}
			this.SelectedDBInfo = selectedDBInfo;
			this.OnDriverChanged(true);
		}
	}

	OnDriverChanged(internal) {
		if (this.Subscription) {
			this.Subscription.unsubscribe();
		}
		if (this.viewContainerRef) {
			this.viewContainerRef.clear();
			if (this.SelectedDBInfo) {
				const type = TYPE_DICT.get(this.SelectedDBInfo.DBInfoType);
				this.ShowAgents=true;
				if(this.SelectedDBInfo?.DBInfoType=="evidanza.Integrations.OpenAI.OpenAIDBInfo"){
					this.ShowAgents=false;
				}
				//this.ShowAgents=false;
				if (type) {
					const factory = this.factoryResolver.resolveComponentFactory(
						type.ControlType
					);
					const comp = factory.create(this.viewContainerRef.parentInjector);
					this.viewContainerRef.insert(comp.hostView);
					const component: any = comp.instance;
					if (component) {
						component.DataSource = this.SelectedItem;
						this.Subscription = component.OnDataChange.subscribe(() => {
							this.OnItemChanged();
						});
					}
				}
			}
		}
		if (!internal) {
			this.OnItemChanged();
		}
	}
	Initialize(InitArgs) {
		if (InitArgs) {
			this.SelectedItem = InitArgs.Data;
		}
	}
	GetDialogResult() {
		return this.SelectedItem;
	}
	checkConnection() {
		if (this.SelectedItem?.DBInfo instanceof OpenAIDBInfo) {
			if (!this.SelectedItem?.DBInfo?.ApiKey) {
				NotificationHelper.Error(
					new TranslateFormatText('@@OpenAI API key required'),
					"@@Error"
				)
				return;
			}
		}
		this.dataService.CheckConnection(this.SelectedItem).subscribe((x) => {
			if (x.Successfull) {
				NotificationHelper.Success('@@Successfully connected', '@@Success');
			} else {
				NotificationHelper.Error(x.Error, '@@Error');
			}
		});
	}
}
