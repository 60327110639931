import { WorkflowStatus } from '../../../models/workflow/workflow.model';

export class StatusWrapper {
    private _Status: WorkflowStatus;
    constructor(status: WorkflowStatus) {
        this._Status = status;
    }

    public get ActualParameter(): any {
        return this._Status.ActualParameter;
    }

    public set ActualParameter(actPara: any) {
        this._Status.ActualParameter = actPara;
    }

    public get ActualEntryOption(): number {
        return this._Status.ActualEntryOption;
    }

    public set ActualEntryOption(actEntry: number) {
        this._Status.ActualEntryOption = actEntry;
    }

    public SetValue(key: string, value: any) {
        this._Status.Context.set(key, value);
    }

    public GetValue(key: string): any {
        return this._Status.Context.get(key);
    }

    //public void SetCacheValue(string key, object value) {
    //    _Status.SetCacheValue(key, value);
    //}

    //public object GetCacheValue(string key) {
    //    return _Status.GetCacheValue(key);
    //}

    public LogInfo(text: string) {
        this._Status.Logger.logInfo(text);
    }

    public LogError(text: string) {
        this._Status.Logger.logError(text);
    }

    public LogWarning(text: string) {
        this._Status.Logger.logWarning(text);
    }

    //    public bool AddToList(string key, object value) {
    //        if (_Status.TryGetValue(out var list, key))
    //            {
    //                if (list is JArray jarray)
    //        {
    //            jarray.Add((value is JToken) ? (JToken)value: JToken.FromObject(value));
    //        } 
    //                else if (list is IList l)
    //        {
    //            l.Add(value);
    //        }
    //        return true;
    //    }
    //return false;
    //        }
}
