import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { LanguageService } from '../../services/language.service';
import { StandardRequestBase } from '../../services/request-base';
import { MessageBoxHelper } from '../../components/dialogs/messagebox/messagebox.dialog';
import { NotificationHelper } from '../../helpers/notification.helper';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: 'language-settings',
    templateUrl: './languages.settings.html',
    styleUrls: ['./languages.settings.css']
})
export class LanguageSettings implements OnInit {
    AllLanguages = [];
    FilteredLanguages = [];
    SelectedLanguages = [];
    HasChanges = false;
    SearchValue = '';
    ShowOnlySelected = false;
    languageService: LanguageService;

    toggleWindow = true;

    static GetSettingsEntry() {
        return {
            Caption: '@@Sprachen',
            ID: 'languages',
            Icon: 'language',
            Index: 3,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 16
            },
            Content: LanguageSettings
        };
    }

    constructor(private service: StandardRequestBase, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        LayoutService.Loading.next(true);
        this.service.executeGet('config/api/config', 'GetLanguageSettings').subscribe(result => {
            if (result) {
                this.AllLanguages = result;
                this.SelectedLanguages = this.AllLanguages.filter(x => x.Selected);
                this.change();
            }
            LayoutService.Loading.next(false);
        });
    }

    saveClick() {
        const settings = {
            SelectedLanguages: [],
            DefaultLanguage: -1,
            Details: {}
        };
        this.AllLanguages.forEach(lang => {
            if (lang.Selected) {
                settings.SelectedLanguages.push(lang.LCID);
                if (lang.IsDefault) {
                    settings.DefaultLanguage = lang.LCID;
                }
                settings.Details[lang.LCID] = {
                    Caption: lang.Translation
                };
            }
        });
        this.service.executePost(settings, 'config/api/config', 'SaveLanguageSettings').subscribe(result => {
            this.toggleWindow = true;
            NotificationHelper.Success(new TranslateFormatText('@@Speichern'), new TranslateFormatText('@@Erfolgreich gespeichert'));
        });
        this.HasChanges = false;
    }

    onSelect(ev) {
        if (ev && ev.options && ev.options.length > 0) {
            ev.options.forEach(option => {
                option.value.Selected = option.selected;
                if (!option.selected) {
                    option.value.IsDefault = false;
                }
            });
            this.HasChanges = true;
            this.SelectedLanguages = this.AllLanguages.filter(x => x.Selected);
            this.change();
        }
    }

    changeSelected(lang, selected) {
        lang.Selected = selected;
        if (!selected) {
            lang.IsDefault = false;
        }
        this.HasChanges = true;
        this.SelectedLanguages = this.AllLanguages.filter(x => x.Selected);
        this.change();
    }

    onDefaultChanged(lang) {
        if (lang.IsDefault) {
            this.SelectedLanguages.forEach(x => x.IsDefault = false);
            lang.IsDefault = true;
        }
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.saveClick();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            }
            this.HasChanges = false;
        }
        return true;
    }

    resetSearch() {
        this.SearchValue = '';
        this.change();
    }
    change() {
        if (this.ShowOnlySelected) {
            if (this.SearchValue) {
                const sv = this.SearchValue.toLocaleLowerCase();
                this.FilteredLanguages = this.AllLanguages.filter(x => x.Selected && x.Caption.toLocaleLowerCase().indexOf(sv) > -1);
            } else {
                this.FilteredLanguages = this.AllLanguages.filter(x => x.Selected);
            }
        } else {
            if (this.SearchValue) {
                const sv = this.SearchValue.toLocaleLowerCase();
                this.FilteredLanguages = this.AllLanguages.filter(x => x.Caption.toLocaleLowerCase().indexOf(sv) > -1);
            } else {
                this.FilteredLanguages = this.AllLanguages;
            }
        }
        this.cdRef.detectChanges();
    }

    ToggleWindow() {
        this.toggleWindow = !this.toggleWindow;
    }
}
