import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutElement, MultiLayoutElement } from '../../../models/layoutelement.model';
import { LayoutService } from '../../../services/layout.service';
import { NavigationService } from '../../../services/navigation.service';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { LayoutTree } from '../../../components/controls/layout.tree';

@Component({
    selector: 'layout-menu-tab',
    templateUrl: './layout.menu.tab.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutMenuTab extends ALayoutMenuTab {
    private static LayoutTreeState: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    ChangeRootType = false;
    Elements;
    @ViewChild('treeControl') tree;

    ngOnInit() {
        super.ngOnInit();
        this.Subscriptions.push(NavigationService.SelectedPage.subscribe((page) => {
            const newCRT = page === 'content';
            if (newCRT !== this.ChangeRootType) {
                this.ChangeRootType = newCRT;
                this.cdRef.detectChanges();
            }
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        let state = null;
        if (this.Elements) {
            if (this.Layout) {
                state = {
                    LayoutKey: this.Layout._key,
                    ExpandStates: {}
                };
                LayoutTree.FillState(this.Elements, state.ExpandStates);
            }
            this.Elements = null;
        }
        LayoutMenuTab.LayoutTreeState.next(state);
    }

    ItemSelected(item) {
        if (item) {
            LayoutService.SelectedItem.next(item.data);
        } else {
            LayoutService.SelectedItem.next(null);
        }
    }
    ItemsSelected(item) {
        if (item && item.length > 1) {
            const list: LayoutElement[] = [];
            const positions = [];
            item.forEach((val) => {
                list.push(val.data);
                let element = document.getElementById('LE_' + val.data.ID);
                if (element) {
                    positions.push({ rect: element.getBoundingClientRect(), element: val.data });
                }
            });
            const element = new MultiLayoutElement();
            element.Name = 'MultiSelect';
            element.LayoutElements = list;
            LayoutService.SelectedItem.next(element);
            // todo create layoutElment with list
            LayoutService.SelectedItems.next(list);
            //if (positions.length > 0) {
            //    LayoutService.SelectedItemsPositon.next(positions);
            //}
        } else {
            LayoutService.SelectedItems.next(null);
        }
    }

    onRefresh(nodes) {
        if (nodes) {
            if (this.Elements) {
                this.Elements = nodes;
            } else {
                this.Elements = nodes;
                if (this.Layout) {
                    const state = LayoutMenuTab.LayoutTreeState.getValue();
                    if (state && state.LayoutKey === this.Layout._key && state.ExpandStates) {
                        LayoutTree.ApplyState(nodes, state.ExpandStates);
                    }
                }
            }
        }
    }
    CollapseAll() {
        if (this.tree) {
            this.tree.changeExpandState(false);
        }
    }
    OpenAll() {
        if (this.tree) {
            this.tree.changeExpandState(true);
        }
    }

    ChangeExpanded(items, value) {
        if (items && items.length > 0) {
            items.forEach((item) => {
                if (item.Children && item.Children.length > 0) {
                    item.IsExpanded = value;
                    this.ChangeExpanded(item.Children, value);
                }
            });
        }
    }
}
