import { from, Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class SideNavEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/sidenav/';
    EndPoints = [
        {
            Name: 'GetAllPages',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.Layout'].filter((value) => {
                        return value._key !== 'baselayout';
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value.PageName ? value.PageName : value._key,
                                    SID: value._Id,
                                    Name: value.PageName,
                                    URL: value._key,
                                    CapsuleTag: value.CapsuleTag
                                });
                            });
                        }
                        observer.next(retVal);
                    });
                });
            }
        },
        {
            Name: 'GetNavigationStructures',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.NavigationStructure'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push({
                                    Caption: value._key,
                                    SID: value._Id,
                                    Version: value._version
                                });
                            });
                        }
                        observer.next(retVal);
                    });
                });
            }
        },
        {
            Name: 'GetNavigationStructure',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.NavigationStructure'].filter((value) => {
                        return value._Id == params['id'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push(value);
                            });
                        }
                        if (retVal.length > 0) {
                            observer.next(retVal[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'GetNavigationStructureByKey',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.NavigationStructure'].filter((value) => {
                        return value._key == params['key'];
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push(value);
                            });
                        }
                        if (retVal.length > 0) {
                            observer.next(retVal[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        {
            Name: 'GetDefaultNavigationStructure',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    let retVal = [];
                    let defaultNS;
                    const settings = localStorage.getItem("GeneralSettings");
                    if (settings) {
                        const settingsObj = JSON.parse(settings);
                        if (settingsObj && settingsObj.DefaultNavigationStructure &&
                            settingsObj.DefaultNavigationStructure != '00000000-0000-0000-0000-000000000000') {
                            defaultNS = settingsObj.DefaultNavigationStructure;
                        }
                    }
                    OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.NavigationStructure'].filter((value) => {
                        if (defaultNS) {
                            return value._Id == defaultNS;
                        }
                        return value._key == 'default';
                    }).toArray().then((values) => {
                        if (values && values.length > 0) {
                            values.forEach((value) => {
                                retVal.push(value);
                            });
                        }
                        if (retVal.length > 0) {
                            observer.next(retVal[0]);
                        } else {
                            observer.next(null);
                        }
                    });
                });
            }
        },
        //{
        //    Name: 'UpdatePage',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
        //    }
        //},
        //{
        //    Name: 'AddOrUpdateNavigationStructureWithKey',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
        //    }
        //},
        //{
        //    Name: 'DeleteNavigationStructure',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
        //    }
        //},
        {
            Name: 'GetNavigationNodesForStructure',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return from(OfflineHelper.GetDB()['evidanza.App.Shared.Layouts.NavigationNode'].filter((value) => {
                    return value.StructureKey == params['key'];
                }).toArray());
            }
        },
        //{
        //    Name: 'SaveNavigationNode',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
        //    }
        //},
        //{
        //    Name: 'DeleteNavigationNodes',
        //    execute(params?: any, body?: any) {
        //        return OfflineHelper.Query('evidanza.App.Shared.Data.CustomCSSVariable', null);
        //    }
        //}
    ]
}