import { Observable } from 'rxjs';
import { OfflineHelper } from '../../helpers/offline.helper';
import { MethodType } from '../../models/enums/methodtype.enum';
import { OfflineEndpointCollection } from '../../models/offline/offline.model';

export class CapsuleEndpoints extends OfflineEndpointCollection {
    BasePath = 'api/capsule/';
    EndPoints = [
        //#region GetCapsuleTags
        {
            Name: 'GetCapsuleTags',
            Method: MethodType.Get,
            execute(params?: any, body?: any) {
                return new Observable((observer) => {
                    OfflineHelper.GetDB()['evidanza.App.Shared.CapsuleTag'].toArray().then((values) => {
                        if (values && values.length > 0) {
                            let retVal = [];
                            values.forEach((value) => {
                                retVal.push(value.Name)
                            })
                            observer.next(retVal);
                        } else {
                            observer.next(null);
                        }
                    })
                });
            }
        },
        //#endregion
    ]
}