import {
    AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Directive, Inject, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArrayHelpers, TranslateFormatText } from '../helpers/array.helpers';
import { VariablesNodeInformation } from '../models/basic/formulaEditor.model';
import { MessageBoxButtons } from '../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../models/enums/messageboxicon.enum';
import { WorkflowData, WorkflowExpression, WorkflowModuleData, WorkflowSaveObject } from '../models/workflow/workflow.model';
import { WF_REGISTRY, WorkflowExitInfo, WorkflowModuleSettingsHelper } from '../services/workflow.service';
import { BaseDialog } from '../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../components/dialogs/messagebox/messagebox.dialog';
import { WorkflowEditOptions } from './workflow.control';
import { WorkflowExpressionDialog } from './workflow.expression.dialog';

@Component({
    selector: 'workflow-dialog',
    templateUrl: 'workflow.dialog.html',
    styleUrls: ['./workflow.dialog.css']
})
export class WorkflowDialog implements AfterViewInit {

    Caption;
    DialogContent: WorkflowDialogContent;
    Fullscreen = false;
    NewExpressions;

    Data: WorkflowModuleData;
    WFData: WorkflowData;
    WFEditOptions: WorkflowEditOptions;
    @ViewChild('dynamic', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

    public static getPreviousModule(modID, connectors, modules, idList) {
        let retVal = null;
        if (idList.some(id => id === modID)) {
            console.log('Circular dependency');
        } else {
            idList.push(modID);
            connectors.some(con => {
                if (con.EntryModule === modID && con.EntryOption === 0) {
                    modules.some(mod => {
                        if (mod.ID === con.ExitModule) {
                            retVal = {
                                Module: mod,
                                Connector: con
                            };
                            return true;
                        }
                        return false;
                    });
                    if (retVal) {
                        return true;
                    }
                }
                return false;
            });
        }
        return retVal;
    }

    constructor(private dialogRef: MatDialogRef<WorkflowDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
        private factoryResolver: ComponentFactoryResolver, private cdRef: ChangeDetectorRef) {
        this.Data = data.Data;
        this.WFData = data.WFData;
        this.WFEditOptions = data.WFEditOptions;
        dialogRef.disableClose = true;
    }

    async ngAfterViewInit() {
        if (this.Data) {
            const desc = WF_REGISTRY.get(this.Data.Module);
            if (desc) {
                this.Caption = desc.Caption;
                if (desc.SettingsControl) {
                    const factory = this.factoryResolver.resolveComponentFactory(desc.SettingsControl);
                    const component = factory.create(this.viewContainerRef.parentInjector);
                    const dc = <WorkflowDialogContent>component.instance;
                    dc.WFData = this.WFData;
                    dc.WFEditOptions = this.WFEditOptions;
                    dc.ModuleID = this.Data.ID;
                    if (dc.UseActualState) {
                        if (this.WFData) {
                            const params = this.WFData['Parameters'];
                            if (params && params.length > 0) {
                                const list = [];
                                params.forEach(x => {
                                    list.push(x.Name);
                                });
                                WorkflowModuleSettingsHelper.AddStatusKeysToState(dc.ActualState, list);
                            }
                            const modList = [];
                            const idList = [];
                            let prevModule = WorkflowDialog.getPreviousModule(this.Data.ID,
                                this.WFData.Connectors, this.WFData.Modules, idList);
                            while (prevModule != null) {
                                modList.push(prevModule.Module);
                                prevModule = WorkflowDialog.getPreviousModule(prevModule.Module.ID,
                                    this.WFData.Connectors, this.WFData.Modules, idList);
                            }
                            for (let i = modList.length - 1; i >= 0; i--) {
                                const actMod = modList[i];
                                const actDesc = WF_REGISTRY.get(actMod.Module);
                                if (actDesc && actDesc.SettingsTypeHelper) {
                                    await actDesc.SettingsTypeHelper.fillActualState(actMod, dc.ActualState, this.WFData);
                                }
                            }
                        }
                    }

                    let settings;
                    if (typeof this.Data.Settings === 'object') {
                        settings = JSON.parse(JSON.stringify(this.Data.Settings));
                    } else {
                        settings = WorkflowSaveObject.DeserializeModuleSettings(this.Data).Settings;
                    }
                    dc.initialize(settings);
                    this.viewContainerRef.insert(component.hostView);
                    this.DialogContent = dc;
                    console.log(dc);
                }
                this.cdRef.detectChanges();
            }
        }
    }

    switchFullscreen() {
        if (this.Fullscreen) {
            this.Fullscreen = false;
            this.dialogRef.updatePosition();
            this.dialogRef.updateSize();
        } else {
            this.Fullscreen = true;
            this.dialogRef.updatePosition({
                top: '0',
                left: '0'
            });
            this.dialogRef.updateSize('100%', '100%');
        }
        if (this.DialogContent) {
            this.DialogContent.fullScreenChanged(this.Fullscreen);
        }
    }

    save() {
        const retVal = {
            ExitPoints: null
        };
        if (this.Data && this.DialogContent) {
            const check = this.DialogContent.checkData();
            if (check) {
                if (check.IsCorrect) {
                    const settings = this.DialogContent.getResult();
                    const desc = WF_REGISTRY.get(this.Data.Module);
                    if (desc && desc.SettingsTypeHelper && desc.SettingsTypeHelper.MustUpdateExitPoints) {
                        let oldSettings = this.Data.Settings;
                        if (oldSettings) {
                            if (settings) {
                                if (typeof oldSettings === 'string') {
                                    oldSettings = JSON.parse(oldSettings);
                                }
                                const oldEndPoints = desc.SettingsTypeHelper.getExitPoints(oldSettings);
                                const newEndPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                                if (oldEndPoints.length === newEndPoints.length && this.DialogContent.EndpointUpdateNecessary !== true) {
                                    for (let i = 0; i < oldEndPoints.length; i++) {
                                        if (!oldEndPoints[i].equals(newEndPoints[i])) {
                                            retVal.ExitPoints = newEndPoints;
                                            break;
                                        }
                                    }
                                } else {
                                    retVal.ExitPoints = newEndPoints;
                                }
                            } else {
                                retVal.ExitPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                            }
                        } else if (settings) {
                            retVal.ExitPoints = desc.SettingsTypeHelper.getExitPoints(settings);
                        }
                        if (retVal.ExitPoints && retVal.ExitPoints.length) {
                            let error = retVal.ExitPoints.find((value) => value.ID === -1);
                            if (!error) {
                                error = new WorkflowExitInfo();
                                error.ID = -1;
                                error.Label = '@@InternalError';
                                error.Type = 'error';
                                retVal.ExitPoints.push(error);
                            }
                        }
                    }
                    this.Data.Settings = settings;
                    if (this.NewExpressions) {
                        this.Data.Expressions = this.NewExpressions;
                    }
                    if (this.WFEditOptions) {
                        if (this.WFEditOptions.IsService) {
                            WorkflowSaveObject.SerializeModuleSettings(this.Data);
                        } else if (this.WFEditOptions.IsTemplate) {
                            WorkflowSaveObject.SerializeModuleSettings(this.Data);
                            if (this.DialogContent.HasExpressions) {
                                // Formeln als Expressions merken
                                if (!this.Data.Expressions) {
                                    this.Data.Expressions = [];
                                }
                                const expressions = this.Data.Expressions;
                                const props = this.DialogContent.GetExpressionProperties();
                                props.forEach(prop => {
                                    let val = settings;
                                    const propList = prop.Value.split('.');
                                    propList.some(pl => {
                                        val = val[pl];
                                        return typeof val === 'undefined' || val == null;
                                    });
                                    let exp: WorkflowExpression;
                                    let index = 0;
                                    expressions.some(e => {
                                        if (e.Property === prop.Value) {
                                            exp = e;
                                            return true;
                                        }
                                        index++;
                                        return false;
                                    });
                                    if (typeof val === 'string' && val.length > 2 && val[0] === '$' && val[val.length - 1] === '$') {
                                        // Expression vorhanden
                                        if (!exp) {
                                            exp = new WorkflowExpression();
                                            exp.Property = prop.Value;
                                            expressions.push(exp);
                                        }
                                        exp.Formula = val.substring(1, val.length - 1);
                                    } else if (exp) {
                                        expressions.splice(index, 1);
                                    }
                                });
                            }
                        }
                    }
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText(check.Error), new TranslateFormatText('@@Fehler'),
                        MessageBoxButtons.Ok, MessageBoxIcon.Error);
                    return;
                }
            }
        }
        this.dialogRef.close(retVal);
    }

    editExpressions() {
        const wfData: any = this.WFData;
        const args = {
            Properties: this.DialogContent.GetExpressionProperties(),
            Expressions: this.Data.Expressions,
            Variables: []
        };
        if (this.NewExpressions) {
            args.Expressions = this.NewExpressions;
        }
        if (wfData && wfData.Parameters) {
            wfData.Parameters.forEach(param => {
                const vni = new VariablesNodeInformation();
                vni.Name = param.Name;
                args.Variables.push(vni);
            });
        }
        BaseDialog.ShowDialog({
            ContentType: WorkflowExpressionDialog,
            Handler: this.checkEditedExpressions.bind(this),
            InitArgs: args,
            Title: '@@Expressions'
        });
    }

    checkEditedExpressions(result) {
        if (result && result.Expressions) {
            if (result.Expressions.some(exp => !exp.Property || !exp.Formula)) {
                MessageBoxHelper.ShowDialog(
                    new TranslateFormatText('@@Bitte verbegeben Sie fuer jede Expression eine Eigenschaft und eine Formel'),
                    new TranslateFormatText('@@Fehler'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                return false;
            }
            this.NewExpressions = result.Expressions;
        }
        return true;
    }

    editDescription() {
        const data = this.Data;
        if (data) {
            BaseDialog.ShowDialog({
                ContentType: WorkflowModuleDescriptionDialog,
                Handler: (r) => {
                    if (r) {
                        data.Description = r;
                    }
                    return true;
                },
                InitArgs: {
                    Description: data.Description
                },
                Title: '@@Beschreibung',
                Width : 600
            });
        }
    }
}

@Directive()
export class WorkflowDialogContent implements OnInit {
    WFData: WorkflowData;
    WFEditOptions: WorkflowEditOptions;
    ModuleID: number;
    UseActualState = false;
    ActualState = new Map<string, any>();
    HasExpressions = false;
    EndpointUpdateNecessary = false;

    ngOnInit(): void { }
    initialize(data: any) { }
    checkData(): DataCheck { return new DataCheck(); }
    getResult(): any {
        return null;
    }
    GetExpressionProperties() {
        return [];
    }
    fullScreenChanged(fullScreen) { }
}

@Directive()
export class FormulaWorkflowDialogContent extends WorkflowDialogContent {
    Variables = [];

    constructor() {
        super();
        this.UseActualState = true;
    }

    initialize(data: any) {
        const statusKeys = this.ActualState.get('statusKeys');
        if (statusKeys) {
            const list = [];
            statusKeys.forEach(x => {
                const vni = new VariablesNodeInformation();
                vni.AliasKey = x;
                vni.Name = x;
                vni.VariableID = x;
                list.push(vni);
            });
            ArrayHelpers.sortAlphabetical(list, 'Name');
            this.Variables = list;
        }
    }
}

@Directive()
export class ContainerWorkflowDialogContent extends FormulaWorkflowDialogContent {
    FixedDataModels;

    initialize(data: any) {
        super.initialize(data);
        if (this.WFEditOptions && this.WFEditOptions.ExternalOptions) {
            this.FixedDataModels = this.WFEditOptions.ExternalOptions.FixedDataModels;
        }
    }
}

export class DataCheck {
    IsCorrect = true;
    Error = '';
    IsShowPopup = true;
}

@Component({
    selector: 'workflow-module-description-dialog',
    template: '<translation-textbox [(TranslatedString)]="Description" Placeholder="{{\'@@Beschreibung\'|translate}}"' +
        ' [Editable]="true" [Multiline]="true"></translation-textbox>'
})
export class WorkflowModuleDescriptionDialog {
    Description;

    Initialize(args) {
        if (args && args.Description) {
            this.Description = args.Description;
        }
    }

    GetDialogResult() {
        return this.Description;
    }
}
