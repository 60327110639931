import { DataSourceQuery } from "../services/datamodel.service";


export class DataSourceHelper {
    static GetDataSourceQuery(dataModelId: string, relational: boolean, exp: boolean, fillProps: boolean): DataSourceQuery {
        const retVal = new DataSourceQuery(dataModelId);
        if (relational) {
            if (exp) {
                retVal.DBUsages = [[8192, 512]] // RelationalWrite + DataSourceWrite
            } else {
                retVal.DBUsages = [[4096, 256]] // RelationalRead + DataSourceRead
            }
        } else {
            if (exp) {
                retVal.DBUsages = [[32, 2097152]] // DocumentStoreWrite + ObjectWrite
            } else {
                retVal.DBUsages = [[16, 1048576]] // DocumentStoreRead + ObjectRead
            }
        }
        retVal.IgnoreResourceBase = true;
        retVal.FillInteractiveProperties = fillProps;
        return retVal;
    }
}
