import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import { CacheService } from '../../../cache/cache.service';
import { ContextEvent, ContextEventHelper } from '../../../models/contextevent.model';
import { Action } from '../../../models/ribbon/action.model';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'context-menu',
    templateUrl: './contextmenu.component.html',
    styleUrls: ['./contextmenu.component.css']
})
export class ContextMenuComponent {
    overlayRef: OverlayRef | null;
    sub: Subscription;
    @ViewChild('contextMenu') contextMenu: TemplateRef<any>;

    Items;
    Component;

    constructor(public overlay: Overlay, public viewContainerRef: ViewContainerRef) {
        ContextEventHelper.Open.subscribe((event) => {
            this.open(event);
        });
        ContextEventHelper.Close.subscribe((event) => {
            this.close();
        });
        LayoutService.CloseContextMenu.subscribe((event) => {
            this.close();
        });
    }

    open(event: ContextEvent) {
        event.Event.preventDefault();
        this.Component = event.Element;
        this.Items = this.Component.Elements
        const x = event.Event.x;
        const y = event.Event.y;
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo({ x, y })
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                }
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close()
        });
        if (this.Component.ElementType && (!this.Items || this.Items.length == 0)) {
            CacheService.ReadContextMenu(this.Component.ElementType).then((result) => {
                if (result) {
                    let res = plainToClass(Action, result);
                    this.Items = res['Elements'];
                }
            });
        }
        this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
            $implicit: null
        }));

        this.sub = LayoutService.HostClick.subscribe((event: ContextEvent) => {
            const clickTarget = event.Event.target as HTMLElement;
            if (!!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget)) {
                this.close();
            }
        });
    }

    close(): void {
        this.sub && this.sub.unsubscribe();
        this.Component = null;
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}