import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";
import { GitService } from "../services/git.service";
import { InjectorHelper } from "./injector.helper";

export class GitHelper {
    public static IsActive = false;
    public static Branches = [];
    public static get LocalBranches() {
        return this.Branches.filter((value) => !value.IsRemote);
    }
    public static get RemoteBranches() {
        return GitHelper.Branches.filter((value) => value.IsRemote);
    }
    public static BranchesChanged: Subject<any> = new Subject<any>();

    public static Repositories = [];
    public static RepositoriesChanged: Subject<any> = new Subject<any>();

    public static InitComplete = false;
    private static Service: GitService;
    private static handleError(error: HttpErrorResponse) {

        return ;
    }
    public static Initialize() {
        return new Promise((resolve) => {
            const http = InjectorHelper.InjectorInstance.get<HttpClient>(HttpClient);
            GitHelper.Service = new GitService(http);

            GitHelper.Service.IsGitActive().subscribe((result) => {
                GitHelper.IsActive = result;
                resolve(result);
                if (result) {
                    GitHelper.GetRepositories().then((successful) => {
                        GitHelper.GetBranches().then((succesful) => {
                            GitHelper.InitComplete = true;
                        });
                    })
                }
            }, (err) => {
                GitHelper.IsActive = false;
                resolve(false);
            });
            let repository = localStorage.getItem('SelectedRepository');
            if (repository) {
                repository = JSON.parse(repository);
                GitHelper.SelectedRepository.next(repository);
            }
            GitHelper.SelectedRepository.subscribe((value) => {
                if (value == null) {
                    localStorage.removeItem('SelectedRepository');
                } else if (!(typeof (value) == 'string' && value == "INITVALUE")) {
                    localStorage.setItem("SelectedRepository", JSON.stringify(value));
                }
            })            
        })
    }
    private static GetBranches() {
        return new Promise((resolve) => {
            this.Service.GetBranches().subscribe((branches) => {
                GitHelper.Branches = branches;
                if (branches && branches.length > 0) {
                    let branch = branches.find((value) => value.IsCheckedOut);
                    if (branch != null) {
                        GitHelper.SelectedBranch = branch;
                    }
                }
                GitHelper.BranchesChanged.next(branches);
                resolve(true);
            });
        })
    }
    private static GetRepositories() {
        return new Promise((resolve) => {
            this.Service.ReadRepositories().subscribe((items) => {
                GitHelper.Repositories = items;
                if (items && items.length > 0) {
                    let item = items.find((value) => value.IsCheckedOut);
                    if (item != null) {
                        this.SelectedBranch = item;
                    }
                }
                GitHelper.RepositoriesChanged.next(items);
                resolve(true);
            });
        })
    }
    public static SelectedBranch: BehaviorSubject<any> = new BehaviorSubject("INITVALUE");
    public static SelectedRepository: BehaviorSubject<any> = new BehaviorSubject(null);   
    
    //static UpdateRepositories() {
    //    let retVal = [];
    //    retVal.push()
    //    if (GitHelper.Repositories && GitHelper.Repositories.length > 0) {
    //        GitHelper.Repositories.forEach((repository) => {
    //            retVal.push({
    //                Caption: repository.Name,
    //                ID: repository.Name,
    //                Icon: 'git_repository',
    //                Index: 4,
    //                Parent: '',
    //                Security: {
    //                    Name: 'evidanza.App.Shared.Security.DevelopmentRight',
    //                    Value: 1
    //                },
    //                Content: GitSettings,
    //                InitArgs: repository.Name
    //            });
    //        })
    //    }
    //    GitHelper.SettingsEntry.Children = retVal;
    //}
}