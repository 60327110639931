import { Component, OnInit } from '@angular/core';
import { InjectorHelper } from '../../helpers/injector.helper';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';
import { ERDEditSettings } from './edit/erd.edit.settings';
import { ERDOverviewSettings } from './overview/erd.overview.settings';

@Component({
    selector: 'erd-settings',
    template: '<div></div>',
    styleUrls: ['./erd.settings.css']
})
export class ERDSettings implements OnInit {
    ngOnInit(): void {

    }
    // private static SettingsEntry = {
    //     Caption: '@@ERD',
    //     ID: 'erd',
    //     Icon: 'share',
    //     Index: 0,
    //     Parent: 'data',
    //     Security: {
    //         Name: 'evidanza.App.Shared.Security.DataRight',
    //         Value: 2
    //     },
    //     Content: ERDSettings,
    //     Children: [],
    //     UpdateFunction: ERDSettings.UpdateEntries
    // };
    constructor() {
    }
    static UpdateEntries() {
            let service = InjectorHelper.InjectorInstance.get<MetaService>(MetaService);
        service.ReadERDs().subscribe((result) => {
            let list: any = [{
                Caption: '@@Overview',
                ID: 'overview',
                Icon: 'share',
                Index: 0,
                Parent: 'erd',
                Security: {
                    Name: 'evidanza.App.Shared.Security.DataRight',
                    Value: 2
                },
                Content: ERDOverviewSettings
            }];

            // if (result) {
            //     for (let i = 0; i < result.length; i++) {
            //         let item = result[i];
            //         let entry = {
            //             Caption: item.Caption,
            //             ID: item._Id,
            //             Icon: 'share',
            //             Index: i + 1,
            //             Parent: 'erd',
            //             Security: {
            //                 Name: 'evidanza.App.Shared.Security.DataRight',
            //                 Value: 2
            //             },
            //             InitArgs: { ID: item._Id },
            //             Content: ERDEditSettings
            //         };
            //         list.push(entry);
            //     }
            // }
            // ERDSettings.SettingsEntry.Children = list;
            NavigationService.Refresh.next(null);
        });
    }
    // static GetSettingsEntry() {
    //    ERDSettings.UpdateEntries();
    //    return ERDSettings.SettingsEntry;
    // }
}