import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { Axis, DataDescription, HeterogenArea } from "../../../models/datadescription/multi/datadescription.model";
import { AxisType } from '../../../models/enums/query.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';

@Component({
    selector: 'axiscontrol',
    templateUrl: './axis.control.html',
    styleUrls: ['./axis.control.css']
})
export class AxisControl implements OnInit, OnDestroy {

    Subscriptions = [];

    @Input()
    OrientationHA = 'horizontal';

    @Input()
    DataDescription: DataDescription;

    @Input()
    ObjectcreatorDataManager: ObjectcreatorDataManager;

    @Input()
    AxisCaption = 'Achse';

    AreaList = [];
    AxisDDValue: Axis;
    @Input()
    get AxisDD() {
        return this.AxisDDValue;
    }
    set AxisDD(value) {
        this.AxisDDValue = value;
        this.AreaList = [];
        window.setTimeout(() => {
            const list = [];
            if (value && value.Areas) {
                value.Areas.forEach((area, i) => {
                    let caption = TranslatedString.GetTranslation(area.Caption);
                    if (!caption) {
                        caption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + (i + 1);
                    }
                    list.push({
                        Caption: caption,
                        Area: area
                    });
                });
            }
            this.AreaList = list;
            this.cdRef.detectChanges();
        }, 50);
        this.AxisDDChange.emit(value);
    }
    @Output() AxisDDChange = new EventEmitter();

    get AxisType(): AxisType {
        return this.AxisDD.AxisType;
    }

    get IsMeasureOnAxis(): boolean {
        if (this.DataDescription) {
            const axistype = this.DataDescription.ShowMeasureOnAxis;
            return axistype === this.AxisType;
        }
        return false;
    }

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.Subscriptions.push(ObjectcreatorDataManager.AxisChanged.subscribe(axisType => {
            if (this.AxisType === axisType) {
                if (axisType === AxisType.X_Axis) {
                    this.AxisDD = this.ObjectcreatorDataManager.DataDescription.XLevelNodes;
                } else if (axisType === AxisType.Y_Axis) {
                    this.AxisDD = this.ObjectcreatorDataManager.DataDescription.YLevelNodes;
                }
            }
        }));
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(x => x.unsubscribe());
    }

    onContextMenu(event: MouseEvent, ha: HeterogenArea) {
        event.preventDefault();
        event['AreaID'] = ha.UniqueID;
    }
}
