import { Component, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { MediaService } from "src/app/services/media.service"
import { SecurityService } from "src/app/services/security.service"
import { UsersService } from "src/app/services/users.service"
import { ThemeHelper } from "src/app/helpers/theme.helpers"
import { NotificationHelper } from "../../helpers/notification.helper"
import { Router } from "@angular/router"
import { SideNavService } from "src/app/services/sidenav.service"
import { NavigationService } from "../../services/navigation.service"
import { SettingsRegistryHelper } from "../../registries/settings/settings.registry";
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: "lib-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  imagePath = "/assets/login_illustration.gif"
  loginForm: FormGroup
  error = {
    status: false,
    message: "",
  }
  hide = true
  showCredentials = true
  selectedProvider
  SecurityProviderTypes
  credentials = {
    UserName: "",
    Password: "",
    ProviderId: null,
     UID:""
  }
  loading = {
    status: false,
    text: "Loading ...",
  }
  isScanner = false
  iconURL: string = ""
  imageURL: string = ""
  googleIconURL: string = ""

  loginScreen = true

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", Validators.required),
    })
    this.iconURL = "../../../assets/icons/ga_univers.svg"
    this.googleIconURL = "../../../assets/icons/google_icon.svg"
    if (ThemeHelper.AcitveTheme.IsDarkTheme) {
      this.imageURL = "../../../assets/img/login_banner.jpg"
    } else {
      this.imageURL = "../../../assets/img/login_banner.jpg"
    }
    // MediaService.IconURL.subscribe(url => {
    //     if (url) {
    //         this.iconURL = url + 'ga_univers.svg';
    //     }
    // })

    // MediaService.ImageURL.subscribe(url => {
    //     if (url) {
    //         if (ThemeHelper.AcitveTheme.IsDarkTheme) {
    //             this.imageURL = url + 'dark_login_illustration.png';
    //             return;
    //         }
    //         this.imageURL = url + 'login_illustration.gif';
    //     }
    // })
  }

  loginUser() {
    console.log("loginUser", this.loginForm)
  }

  constructor(
    private usersService: UsersService,
    private securityService: SecurityService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.securityService.GetSecurityProviders().subscribe((types) => {
      if (types) {
        this.SecurityProviderTypes = types
        let found = false
        this.SecurityProviderTypes.forEach((provider) => {
          if (provider.IsDefault) {
            this.credentials.ProviderId = provider.Id
            if (provider.Url) {
              found = true
            }
            if (provider.Type === "31e75470-8458-48e5-af70-d8a6e8840b37") {
              this.isScanner = true
              found = true
            }
            this.selectedProvider = provider
          }
        })
        this.showCredentials = !found
      }
    })
  }

  // selectionChanged() {
  //   if (this.SecurityProviderTypes) {
  //     this.isScanner = false
  //     let found = false
  //     this.SecurityProviderTypes.forEach((provider) => {
  //       if (provider.Id == this.credentials.ProviderId) {
  //         if (provider.Url) {
  //           found = true
  //         }
  //         if (provider.Type === "31e75470-8458-48e5-af70-d8a6e8840b37") {
  //           this.isScanner = true
  //           found = true
  //         }
  //         this.selectedProvider = provider
  //       }
  //     })
  //     this.showCredentials = !found
  //   }
  // }

  // LoginUser() {
  //   if (
  //     this.loginForm.value.email === "" ||
  //     this.loginForm.value.password === ""
  //   ) {
  //     NotificationHelper.Error(
  //       "@@Please enter your email and password.",
  //       "@@Error"
  //     )
  //     return
  //   }
  //   this.credentials.UserName = this.loginForm.value.email
  //   this.credentials.Password = this.loginForm.value.password
  //   this.loading.status = true
  //   this.usersService.executeLogin(this.credentials).then((x) => {
  //     // console.log('login user: ',x)
  //     if (x === 0) {
  //       this.router.navigate(["default/settings/dashboard"]).then(() => {
  //         // this.usersService.GetCurrentUser().subscribe(user => {
  //         //     if (user?.Roles[0] === config?.FREE_USER_ID) {
  //         //         window.location.reload()
  //         //     }
  //         // })
  //         SettingsRegistryHelper.Build()
  //         NavigationService.Refresh.next(null)
  //       })
  //     }
  //     this.error = {
  //       status: Boolean(x),
  //       message: this.translate.instant(
  //         "@@Benutzername oder Passwort falsch! Bitte kontrollieren Sie die Eingabe."
  //       ),
  //     }
  //     if (x == 1) {
  //       NotificationHelper.Error(
  //         "@@Invalid username or password. Please check your input.",
  //         "@@Error"
  //       )
  //     }
  //     if (x == 3) {
  //       NotificationHelper.Error(
  //         "@@Your account is not activated, Please contact the administrator.",
  //         "@@Error"
  //       )
  //     }
  //     this.loading.status = false
  //   })
  // }

  
    selectionChanged() {
        if (this.SecurityProviderTypes) {
            this.isScanner = false;
            let found = false;
            this.SecurityProviderTypes.forEach((provider) => {
                if (provider.Id == this.credentials.ProviderId) {
                    if (provider.Url) {
                        found = true;
                    }
                    if (provider.Type === '31e75470-8458-48e5-af70-d8a6e8840b37') {
                        this.isScanner = true;
                        found = true;
                    }
                    this.selectedProvider = provider;
                }
            });
            this.showCredentials = !found;
        }
    }
    handleGoogleSignin(credentials:string){
        if(credentials){
            let ssoTokenRequest = {
                Token:credentials
            }
            this.loading.status=true;
            this.usersService.executeSSOLogin(ssoTokenRequest).then(x=>{
                if (x === 0) {
                    this.router.navigate(['default/settings/dashboard']).then(() => {
                        SettingsRegistryHelper.Build();
                        NavigationService.Refresh.next(null);
                        LayoutService.Loading.next(false);
                    });
                }
                this.error = {
                    status: Boolean(x),
                    message: this.translate.instant('@@Benutzername oder Passwort falsch! Bitte kontrollieren Sie die Eingabe.')
                };
                if (x == 1) {
                    NotificationHelper.Error('@@Invalid Google Account. Please try again.', '@@Error')
                }
                if (x == 3) {
                    NotificationHelper.Error('@@Your account is not activated, Please contact the administrator.', '@@Error')
                }
                this.loading.status = false;
            })
        }
    }
    LoginUser() {
        if (this.loginForm.value.email === "" || this.loginForm.value.password === "") {
            NotificationHelper.Error('@@Please enter your email and password.', '@@Error');
            return;
        }
        this.credentials.UserName = this.loginForm.value.email;
        this.credentials.Password = this.loginForm.value.password;
        this.loading.status = true;
        this.usersService.executeLogin(this.credentials).then(x => {
            // console.log('login user: ',x)
            if (x === 0) {
                this.router.navigate(['default/settings/dashboard']).then(() => {
                    // this.usersService.GetCurrentUser().subscribe(user => {
                    //     if (user?.Roles[0] === config?.FREE_USER_ID) {
                    //         window.location.reload()
                    //     }
                    // })
                    SettingsRegistryHelper.Build();
                    NavigationService.Refresh.next(null);
                });
            }
            this.error = {
                status: Boolean(x),
                message: this.translate.instant('@@Benutzername oder Passwort falsch! Bitte kontrollieren Sie die Eingabe.')
            };
            if (x == 1) {
                NotificationHelper.Error('@@Invalid username or password. Please check your input.', '@@Error')
            }
            if (x == 3) {
                NotificationHelper.Error('@@Your account is not activated, Please contact the administrator.', '@@Error')
            }
            this.loading.status = false;
        });
    }

    goBackLogin(event) {
        this.loginScreen = true;
    }
}
