import { Component } from '@angular/core';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { CCOType } from '../../../../models/enums/ccotype.enum';
import { DataService } from '../../../../services/data.service';
import { BaseTriggerControl } from './base.trigger.control';

@Component({
    selector: 'ccotrigger-control',
    templateUrl: './cco.trigger.control.html',
    styleUrls: ['./cco.trigger.control.css']
})
export class CCOTriggerControl extends BaseTriggerControl {

    CCOTypes: any[];
    Container: any[];


    constructor( private dataService: DataService) {
        super();
    }


    ngOnInit(): void {
        this.CCOTypes = EnumHelper.GetDropdownValues(CCOType);
     
        this.dataService.GetItem('dynamic', 'GetAllTables', '').subscribe((data) => {
                this.Container = data;
            });
    }

   
  
}



