import { QueryType } from "../../enums/query.enum";

export class QuerySettings {
  SelType: QueryType = QueryType.Auto;
  UseOwnReferenceLogic: boolean = true;
  ParallelProcessing: boolean = true;
  AutomatedSolveOrder: boolean = true;
  AggregateSQL: boolean = false;
  UseGroupBy: boolean = true;
  RecalcParentChild: boolean = false;
  RecalcRBM: boolean = false;
}
