import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { jsPlumb } from 'jsplumb';
import { StringHelper, TranslateFormatText } from '../../helpers/array.helpers';
import { WorkflowViewType } from '../../models/enums/workflowviewtype.enum';
import { BreakPointInfo, BreakPointSelection, ServiceWorkflowInfo } from '../../models/workflow/workflow.debug.settings';
import { IAdditionalBreakPointHandler, WorkflowData, WorkflowModuleData } from '../../models/workflow/workflow.model';
import { WF_REGISTRY, WF_TYPE_REGISTRY, WorkflowExitInfo } from '../../services/workflow.service';

@Component({
    selector: 'debug-workflow-breakpoint-control',
    templateUrl: './debug.workflow.breakpoint.control.html',
    styleUrls: ['./debug.workflow.breakpoint.control.css', '../../workflow/workflow.item.css']
})
export class DebugWorkflowBreakPointControl implements OnInit, AfterViewInit {
    zoomValue = 1;
    zoomStyle = {};
    zoomText = '100';
    jsPlumbInstance;
    Initialized = false;
    wfItemSize = 100;
    DataDescription = {
        Modules: [],
        EndPoints: {},
        Connectors: []
    };
    additionalTemplate: {
        Portal: Portal<any>,
        WFItem: any,
        BreakPoint: BreakPointInfo
    }

    //#region Data
    DataValue: WorkflowData = new WorkflowData();

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        if (val && val !== this.DataValue) {
            this.DataValue = val;
            this.generateDataDescription();
            this.updateJSPlumb();
        }
    }
    //#endregion

    //#region Settings
    SettingsValue: ServiceWorkflowInfo = new ServiceWorkflowInfo();

    @Input()
    get Settings() {
        return this.SettingsValue;
    }
    set Settings(val) {
        if (val && val !== this.SettingsValue) {
            this.SettingsValue = val;
            this.applySettings();
        }
    }
    //#endregion

    //#region SelectedModule
    SelectedModuleValue: BreakPointSelection;

    @Input()
    get SelectedModule() {
        return this.SelectedModuleValue;
    }
    set SelectedModule(val) {
        this.SelectedModuleValue = val;
        this.applySettings();
    }
    //#endregion

    //#region CanEdit
    CanEditValue = false;

    @Input()
    get CanEdit() {
        return this.CanEditValue;
    }
    set CanEdit(val) {
        this.CanEditValue = val;
    }
    //#endregion

    @Output() BreakpointChanged = new EventEmitter<any>();

    @ViewChild('contentDIV', { read: ElementRef }) contentDIV: ElementRef;

    constructor(private cdRef: ChangeDetectorRef, private translate: TranslateService, private _injector: Injector) {
    }

    ngOnInit(): void {
        this.jsPlumbInstance = jsPlumb.getInstance({
            Connector: ["Flowchart", { stub: 50, gap: 5, cornerRadius: 10 }],
            PaintStyle: { stroke: "var(--workflow-connector-stroke-color)", strokeWidth: 2 }
        });
        this.jsPlumbInstance.registerConnectionTypes({
            "basic": {
                connector: ["Flowchart", {
                    stub: 50,
                    gap: 5,
                    cornerRadius: 10
                }],
                paintStyle: { stroke: "var(--workflow-connector-stroke-color)", strokeWidth: 2 },
                cssClass: "connector-normal"
            }
        });
    }

    ngAfterViewInit(): void {
        this.Initialized = true;
        this.updateJSPlumb();
        this.applySettings();
    }

    generateDataDescription() {
        const dd = {
            ID: UUID.UUID(),
            Modules: [],
            EndPoints: {},
            Connectors: []
        };
        if (this.DataValue) {
            this.DataValue.Modules.forEach(mod => {
                this.addModule(mod, dd);
            });
            this.DataValue.Connectors.forEach(con => {
                if (typeof con.EntryOption !== 'number') {
                    con.EntryOption = 0;
                }
                const conInfo = {
                    Connection: {
                        uuids: ['exit_' + con.ExitModule + '_' + con.ExitOption, 'entry_' + con.EntryModule + '_' + con.EntryOption],
                        detachable: false
                    },
                    Label: ''
                };
                const mod = this.DataValue.Modules.find(x => x.ID === con.ExitModule);
                if (mod && mod.Settings) {
                    const desc = WF_REGISTRY.get(mod.Module);
                    if (desc && desc.SettingsTypeHelper) {
                        let settings = mod.Settings;
                        if (typeof settings === 'string') {
                            settings = JSON.parse(settings);
                        }
                        conInfo.Label = desc.SettingsTypeHelper.getExitPointLabel(settings, con.ExitOption);
                    }
                }
                dd.Connectors.push(conInfo);
            });
        }
        this.DataDescription = dd;
        this.cdRef.detectChanges();
    }

    private addModule(mod, dd) {
        const modEntry = {
            ID: mod.ID,
            Module: mod.Module,
            idAttr: dd.ID + '_' + mod.ID,
            Caption: null,
            AdditionalTranslateCaption: null,
            AdditionalCaption: null,
            Description: null,
            Style: {
                top: mod.YPos + 'px',
                left: mod.XPos + 'px',
                width: this.wfItemSize + 'px',
                height: this.wfItemSize + 'px'
            },
            BreakOnStart: false,
            BreakOnEnd: false,
            HasAdditional: false,
            StartSelected: false,
            EndSelected: false,
            AdditionalBreakpointHandler: null,
            Group: null,
            ViewType: null,
            Icon: null,
            GroupIcon: null,
            Height: 100,
            Width: 100
        };
        dd.Modules.push(modEntry);
        const desc = WF_REGISTRY.get(mod.Module);
        if (desc) {
            modEntry.Width = desc.Width;
            modEntry.Style.width = desc.Width + 'px';
            modEntry.Height = desc.Height;
            modEntry.Style.height = desc.Height + 'px';
            modEntry.ViewType = desc.ViewType;
            modEntry.Group = desc.GroupID;
            modEntry.Icon = desc.Icon;
            modEntry.GroupIcon = desc.GroupIcon;
            const descList = [];
            if (desc.Caption) {
                modEntry.Caption = desc.Caption;
                descList.push(this.translate.instant(desc.Caption));
            }
            if (desc.SettingsTypeHelper) {
                const epList = [];
                const entryPoints = desc.SettingsTypeHelper.getEntryPoints();
                const percent = 1.0 / (entryPoints.length + 1);
                for (let i = 0; i < entryPoints.length; i++) {
                    const exPo = entryPoints[i];
                    const expoInfo = {
                        anchor: [percent * (i + 1), 0, 0, -1],
                        endpoint: ['Rectangle', { width: 10, height: 10 }],
                        maxConnections: -1,
                        uuid: 'entry_' + mod.ID + '_' + exPo.ID,
                        Label: exPo.Label,
                        enabled: false
                    };
                    if (typeof exPo.Type === 'string') {
                        expoInfo['parameters'] = {
                            EndPointType: exPo.Type
                        };
                        const info = WF_TYPE_REGISTRY.get(exPo.Type);
                        if (info) {
                            expoInfo['paintStyle'] = {
                                fill: info.Color
                            };
                        }
                    }
                    epList.push(expoInfo);
                }
                let settings = mod.Settings;
                if (settings) {
                    if (typeof settings === 'string') {
                        settings = JSON.parse(settings);
                    }
                    const ac = desc.SettingsTypeHelper.getAdditionalCaption(settings);
                    if (ac instanceof TranslateFormatText) {
                        modEntry.AdditionalTranslateCaption = ac;
                        descList.push(StringHelper.format(this.translate.instant(ac.TranslateText), ac.FormatParams));

                    } else if (typeof ac === 'string') {
                        modEntry.AdditionalCaption = ac;
                        descList.push(ac);
                    }
                }
                modEntry.AdditionalBreakpointHandler = desc.SettingsTypeHelper.getAdditionalBreakpointHandler(settings);
                const exitPoints = desc.SettingsTypeHelper.getExitPoints(settings);

                if (exitPoints && exitPoints.length) {
                    let error = exitPoints.find((value) => value.ID == -1);
                    if (!error) {
                        error = new WorkflowExitInfo();
                        error.ID = -1;
                        error.Label = "@@InternalError";
                        error.Type = "error";
                        exitPoints.push(error);
                    }
                }

                const percentEnd = 1.0 / (exitPoints.length + 1);
                for (let i = 0; i < exitPoints.length; i++) {
                    const exPo = exitPoints[i];
                    const expoInfo = {
                        anchor: [],
                        endpoint: ['Dot', { radius: 5 }],
                        uuid: 'exit_' + mod.ID + '_' + exPo.ID,
                        Label: exPo.Label,
                        enabled: false
                    };
                    let pos = (percentEnd * (i + 1)) + (percentEnd / 2);
                    let per = percentEnd * (i + 1);
                    switch (modEntry.ViewType) {
                        case WorkflowViewType.Condition:
                            let hpos = 0;
                            if (pos > 0.5) {
                                hpos = pos - 0.5;
                            } else {
                                hpos = 0.5 - pos;
                            }
                            let vpos = 0;
                            if (per > 0.5) {
                                vpos = per - 0.5;
                            } else {
                                vpos = 0.5 - per;
                            }
                            expoInfo.anchor = [[per, 1 - vpos], [hpos, pos], [1 - hpos, pos]];
                            break;
                        case WorkflowViewType.Start:
                        case WorkflowViewType.Action:
                        default:
                            expoInfo.anchor = [[per, 1, 0, 1], [0, pos], [1, pos]];
                            break;
                    }

                    if (typeof exPo.Type === 'string') {
                        expoInfo['parameters'] = {
                            EndPointType: exPo.Type
                        };
                        const info = WF_TYPE_REGISTRY.get(exPo.Type);
                        if (info) {
                            expoInfo['paintStyle'] = {
                                fill: info.Color
                            };
                        }
                    }
                    epList.push(expoInfo);
                }
                if (epList.length > 0) {
                    dd.EndPoints[dd.ID + '_' + mod.ID] = epList;
                }
            }
            if (descList.length > 0) {
                modEntry.Description = descList.join('\n');
            } else {
                modEntry.Description = null;
            }
        }
    }

    updateJSPlumb() {
        if (this.Initialized) {
            this.jsPlumbInstance.reset(false);
            if (this.DataDescription) {
                this.jsPlumbInstance.setSuspendDrawing(true);
                Object.keys(this.DataDescription.EndPoints).forEach(epKey => {
                    const epList = this.DataDescription.EndPoints[epKey];
                    epList.forEach(exPo => {
                        const ep = this.jsPlumbInstance.addEndpoint(epKey, exPo);
                        if (exPo.Label) {
                            const label = this.translate.instant(exPo.Label);
                            ep.addOverlay(['Label', { label: label, cssClass: 'exitPointLabel', location: [0.5, 2.5] }]);
                            ep.bind('mouseover', (exP, ev) => {
                                exP.showOverlays();
                            });
                            ep.bind('mouseout', (exP, ev) => {
                                exP.hideOverlays();
                            });
                            ep.hideOverlays();
                        }
                    });
                });
                this.DataDescription.Connectors.forEach(x => {
                    const jsCon = this.jsPlumbInstance.connect(x.Connection);
                    if (x.Label) {
                        jsCon.setLabel(x.Label);
                    }
                });
                this.jsPlumbInstance.setSuspendDrawing(false, true);
            }
        }
    }

    applySettings() {
        if (this.DataDescription) {
            let breakpoints = [];
            if (this.SettingsValue && this.SettingsValue.Breakpoints) {
                breakpoints = this.SettingsValue.Breakpoints;
            }
            this.DataDescription.Modules.forEach(mod => {
                const bp = breakpoints.find(x => x.ModuleID === mod.ID);
                if (bp) {
                    mod.BreakOnStart = bp.BreakOnStart;
                    mod.BreakOnEnd = bp.BreakOnEnd;
                    mod.HasAdditional = bp.AdditionalBreakPoints != null;
                } else {
                    mod.BreakOnStart = false;
                    mod.BreakOnEnd = false;
                    mod.HasAdditional = false;
                }
                if (this.SelectedModuleValue && this.SelectedModuleValue.ModuleID === mod.ID) {
                    if (this.SelectedModuleValue.Start) {
                        mod.StartSelected = true;
                        mod.EndSelected = false;
                    } else {
                        mod.StartSelected = false;
                        mod.EndSelected = true;
                    }
                } else {
                    mod.StartSelected = false;
                    mod.EndSelected = false;
                }
            });
        }
    }

    onScroll(ev) {
        if (ev && ev.getModifierState('Control')) {
            const delta = ev.deltaY;
            if (typeof delta === 'number') {
                if (delta < 0) {
                    this.increaseZoom();
                } else {
                    this.decreaseZoom();
                }
            }
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    increaseZoom() {
        this.zoomValue += 0.05;
        this.jsPlumbInstance.setZoom(this.zoomValue);
    }

    decreaseZoom() {
        if (this.zoomValue > 0.05) {
            this.zoomValue -= 0.05;
            this.jsPlumbInstance.setZoom(this.zoomValue);
        }
    }

    resetZoom() {
        this.setZoomValue(1);
    }

    optimalZoom() {
        if (this.DataValue && this.DataValue.Modules && this.DataValue.Modules.length > 0 && this.contentDIV) {
            let maxWidth = 0;
            let maxHeight = 0;
            this.DataValue.Modules.forEach(x => {
                const right = x.XPos + this.wfItemSize;
                if (right > maxWidth) {
                    maxWidth = right;
                }
                const bottom = x.YPos + this.wfItemSize;
                if (bottom > maxHeight) {
                    maxHeight = bottom;
                }
            });
            maxWidth += 10;
            maxHeight += 10;
            const zoom = Math.min(this.contentDIV.nativeElement.offsetWidth / maxWidth,
                this.contentDIV.nativeElement.offsetHeight / maxHeight);
            this.setZoomValue(Math.round(zoom * 100) / 100);
        }
    }

    private setZoomValue(zoomVal: number) {
        this.zoomValue = zoomVal;
        this.zoomStyle = {
            'zoom': this.zoomValue
        };
        this.zoomText = '' + Math.round(this.zoomValue * 100);
        this.jsPlumbInstance.setZoom(this.zoomValue);
    }

    debugClicked(item, start) {
        if (this.CanEditValue) {
            if (start) {
                item.BreakOnStart = !item.BreakOnStart;
            } else {
                item.BreakOnEnd = !item.BreakOnEnd;
            }
            if (this.SettingsValue) {
                if (!this.SettingsValue.Breakpoints) {
                    this.SettingsValue.Breakpoints = [];
                }
                let bp = this.SettingsValue.Breakpoints.find(x => x.ModuleID === item.ID);
                if (!bp) {
                    bp = new BreakPointInfo();
                    bp.ModuleID = item.ID;
                    this.SettingsValue.Breakpoints.push(bp);
                }
                bp.BreakOnStart = item.BreakOnStart;
                bp.BreakOnEnd = item.BreakOnEnd;
                this.BreakpointChanged.emit();
            }
        }
    }

    showAdditional(item) {
        if (this.CanEditValue && item && this.SettingsValue) {
            const abh: IAdditionalBreakPointHandler = item.AdditionalBreakpointHandler;
            if (abh) {
                let bp;
                if (this.SettingsValue.Breakpoints) {
                    bp = this.SettingsValue.Breakpoints.find(x => x.ModuleID === item.ID);
                } else {
                    this.SettingsValue.Breakpoints = [];
                }
                if (bp) {
                    if (!bp.AdditionalBreakPoints) {
                        bp.AdditionalBreakPoints = abh.EmptyData;
                    }
                } else {
                    bp = new BreakPointInfo();
                    bp.AdditionalBreakPoints = abh.EmptyData;
                    this.SettingsValue.Breakpoints.push(bp);
                }
                const injectorTokens = new WeakMap();
                const controlData = {};
                abh.updateControlData(controlData, bp.AdditionalBreakPoints, null)
                injectorTokens.set(abh.Token, controlData);
                this.additionalTemplate = {
                    Portal: new ComponentPortal(abh.ComponentType, null, new PortalInjector(this._injector, injectorTokens)),
                    WFItem: item,
                    BreakPoint: bp
                };
                this.cdRef.detectChanges();
            }
        }
    }

    clearAdditional() {
        if (this.additionalTemplate) {
            if (this.additionalTemplate.WFItem && this.additionalTemplate.BreakPoint) {
                const abh: IAdditionalBreakPointHandler = this.additionalTemplate.WFItem.AdditionalBreakpointHandler;
                if (abh) {
                    const remove = abh.checkBreakPointDataForRemove(this.additionalTemplate.BreakPoint.AdditionalBreakPoints);
                    if (remove) {
                        this.additionalTemplate.BreakPoint.AdditionalBreakPoints = null;
                    }
                }
                this.additionalTemplate.WFItem.HasAdditional = this.additionalTemplate.BreakPoint.AdditionalBreakPoints != null;
            }
            this.additionalTemplate = null;
            this.cdRef.detectChanges();
            this.BreakpointChanged.emit();
        }
    }
}
