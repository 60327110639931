import { ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { MultiCacheService } from '../../../../cache/multi/cache.service';
import { MessageBoxHelper } from '../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../helpers/array.helpers';
import { EnumHelper } from '../../../../helpers/enum.helper';
import { ConditionOperator } from '../../../../models/enums/conditionoperator.enum';
import { MessageBoxButtons } from '../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../../models/enums/messageboxresult.enum';
import { ConditionFormattingStyle } from '../../../../models/tasks/conditional.model';
import { ConditionalLevelTaskExecuter } from '../../../../models/tasks/formattasks/conditionallevel.formatting.task';
import { ConditionalMeasureTaskExecuter } from '../../../../models/tasks/formattasks/conditionalmeasure.formatting.task';
import { MultiReportingService } from '../../../../services/reporting.service';
import { ALevelMeasurePanel } from '../../a.task.panel';

@Directive()
export abstract class AConditionalFormattingTaskPanel extends ALevelMeasurePanel {

    StyleVal: any;
    CompareTypes: any[];
    ActualCondition: any;

    ListItems = [];
    selectedNode;

    // TaskList
    TaskListConditionalValue = [];
    @Input()
    get TaskListConditional() {
        return this.TaskListConditionalValue;
    }
    set TaskListConditional(val) {
        this.TaskListConditionalValue = val;
        this.TaskListConditionalValueChange.emit(val);
    }

    @Output() TaskListConditionalValueChange = new EventEmitter<any>();    

    Levelmembers = [];

    constructor(public service: MultiReportingService, public cdRef: ChangeDetectorRef) {
        super();
        this.CompareTypes = EnumHelper.GetDropdownValues(ConditionOperator);
    }

    async LevelChanged(levelItem) {
        this.Levelmembers = [];
        const levelID = this.levelUniqueToUUID.get(levelItem.value);
        const cubeLevel = await MultiCacheService.GetLevel(levelID, this.DataDescription.DataModelID);
        if (cubeLevel) {
            this.service.GetMembers(cubeLevel.Parent.Parent.DatasourceID, levelID, null, null).subscribe(members => {
                const memberList = [];
                if (members) {
                    members.forEach(m => {
                        memberList.push({
                            Caption: m.Caption,
                            Value: m.ID
                        });
                    });
                }
                this.Levelmembers = memberList;
            });
        }
    }

    OnSettingsSet() {
        if (this.SettingsValue) {
            if (!this.SettingsValue.ConditionFormatingStyles) {
                this.SettingsValue.ConditionFormatingStyles = [];
            }

            this.LevelChanged({ value: this.SettingsValue.LevelUniqueID });
            // this.StyleVal = StyleMerger.GetStyleObject(this.SettingsValue.Style);
        }
        this.TaskListConditionalValue = this.SettingsValue.ConditionFormatingStyles;
        this.updateTaskList(true);
    }

    IsConditionalMeasureTask() {
        return this.SettingsValue.TaskType === ConditionalMeasureTaskExecuter.TaskID;
    }

    IsConditionalLevelTask() {
        return this.SettingsValue.TaskType === ConditionalLevelTaskExecuter.TaskID;
    }

    updateTaskList(selectFirst = false) {
        if (this.TaskListConditionalValue.length === 0) {
            this.addItem();
        } else {

            this.ListItems = [];
            this.TaskListConditionalValue.forEach((task, i) => {
                const item = {
                    Caption: this.CompareTypes.find(x => x.type === task.Condition.ConditionOperator).value,
                    ID: this.ListItems.length
                };
                item.Caption += task.Condition.Value1 ? ' ' + task.Condition.Value1 : '';
                item.Caption += task.Condition.Value2 ? ' ' + task.Condition.Value2 : '';
                this.ListItems.push(item);
            });
            const selectItem = selectFirst ? this.TaskListConditionalValue[0] : null;
            this.setSelectedItem(selectItem);
        }
    }

    async onItemClick(selection) {
        if (this.selectedNode !== selection) {
            this.selectedNode = selection;
            let task = null;
            if (selection && this.TaskListConditionalValue) {
                task = this.TaskListConditionalValue[selection.ID];
                this.LevelChanged({ value: task.Condition.LevelUniqueID });
            }
            this.setSelectedItem(task);
        }
    }

    addItem() {
        if (this.TaskListConditionalValue.length > 0) {
            this.updateTaskList(false);
        }
        this.Levelmembers = [];
        const cond = new ConditionFormattingStyle();

        const item = {
            Caption: this.CompareTypes.find(x => x.type === cond.Condition.ConditionOperator).value,
            ID: this.ListItems.length
        };
        item.Caption += cond.Condition.Value1 ? ' ' + cond.Condition.Value1 : '';
        item.Caption += cond.Condition.Value2 ? ' ' + cond.Condition.Value2 : '';

        this.ListItems.push(item);
        this.TaskListConditionalValue.push(cond);
        this.selectedNode = item;
        this.setSelectedItem(cond);
    }

    setSelectedItem(item) {
        this.ActualCondition = item;
        if (this.ActualCondition) {
            this.StyleVal = this.ActualCondition.Style;
        }
    }

    async deleteItem() {
        const sel = this.selectedNode;
        if (sel) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Task \'{0}\' loeschen moechten?');
            text.FormatParams.push(sel.Caption);
            const retVal = await MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Task loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {

                if (this.TaskListConditionalValue) {
                    this.TaskListConditionalValue.splice(sel.ID, 1);
                }
                this.ListItems.splice(sel.ID, 1);

                this.selectedNode = null;
                const selectItem = this.TaskListConditionalValue.length > 0 ? this.TaskListConditionalValue[0] : null;
                this.setSelectedItem(selectItem);
                this.cdRef.detectChanges();
            }
        }
    }

    moveUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(0, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(0, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveOneUp() {
        if (this.selectedNode && this.selectedNode.ID > 0 && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(this.selectedNode.ID - 1, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(this.selectedNode.ID - 1, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveOneDown() {
        if (this.selectedNode && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0 &&
            this.selectedNode.ID < this.TaskListConditionalValue.length - 1) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.splice(this.selectedNode.ID + 1, 0, task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.splice(this.selectedNode.ID + 1, 0, this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }

    moveDown() {
        if (this.selectedNode && this.TaskListConditionalValue && this.TaskListConditionalValue.length > 0 &&
            this.selectedNode.ID < this.TaskListConditionalValue.length - 1) {
            const task = this.TaskListConditionalValue.splice(this.selectedNode.ID, 1)[0];
            this.TaskListConditionalValue.push(task);
            this.ListItems.splice(this.selectedNode.ID, 1);
            this.ListItems.push(this.selectedNode);
            this.ListItems.forEach((x, i) => { x.ID = i; });
        }
    }
}
