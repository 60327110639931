import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateFormatText} from "../../helpers/array.helpers";
import {MessageBoxButtons} from "../../models/enums/messageboxbuttons.enum";
import {MessageBoxIcon} from "../../models/enums/messageboxicon.enum";
import {MessageBoxResult} from "../../models/enums/messageboxresult.enum";
import {LayoutService} from "../../services/layout.service";
import {NotificationHelper} from "../../helpers/notification.helper";
import {MessageBoxHelper} from "../../components/dialogs/messagebox/messagebox.dialog";
import {RolesService} from "../../services/roles.service";
import {SETTINGS_LIST} from "../../components/pages/settings.page";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";

@Component({
    selector: 'user-permissions-settings',
    templateUrl: './user-permissions.settings.html',
    styleUrls: ['./user-permissions.settings.css']
})
export class UserPermissionsSettings implements OnInit {

    // static GetSettingsEntry() {
    //     return {
    //         Caption: '@@User Permissions',
    //         ID: 'user-permissions',
    //         Icon: 'security',
    //         Index: 2,
    //         Parent: 'userCont',
    //         Security: {
    //             Name: 'evidanza.App.Shared.Security.UsermanagementRight',
    //             Value: 8
    //         },
    //         Content: UserPermissionsSettings
    //     };
    // }

    toggleWindow = true;
    permissions = [];
    permissionList = [];
    SearchValue: string;

    moduleList = [];
    pagesList = [];

    SelectedItem: any = {
        Id: '',
        title: '',
        parentId: '',
        module: '',
        page: '',
        permissionCode: '',
        permissionType: '',
    }

    private _transformer = (node: PermissionNode, level: number) => {
        return {
            expandable: !!node.Children && node.Children.length > 0,
            name: node.Title,
            code: node.PermissionCode,
            level: level,
        };
    };

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.Children,
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    constructor(private service: RolesService, private cdRef: ChangeDetectorRef) {
    }


    ngOnInit(): void {
        this.GetUserPermission();
    }

    GetUserPermission() {
        this.permissions = [];
        this.permissionList = [];
        this.service.GetSystempermissions().subscribe(res => {
            if (res) {
                this.permissions = res['Data'];
                this.moduleList = res['Data'];
                this.onSearch();
            }
        });
    }

    onSearch() {
        let list = [];
        if (this.permissions) {
            if (this.SearchValue) {
                const toLower = this.SearchValue.toLowerCase();
                list = this.permissions.filter(x => x.title.toLowerCase().indexOf(toLower) > -1);
            } else {
                list.push(...this.permissions);
            }
        }
        this.permissionList = list;
        this.dataSource.data = list;
    }

    onCreate() {
        this.SelectedItem = {
            Id: '',
            title: '',
            parentId: '',
            module: '',
            page: '',
            permissionCode: '',
            permissionType: '',
        };
        this.toggleWindow = false;
    }

    onSavePermission() {
        const data = this.SelectedItem;
        this.AddUserPermission(data);
    }

    AddUserPermission(data) {
        this.loading(true);
        this.service.AddSystempermissions(data).subscribe({
            next: (res) => {
                this.loading(false);
                this.refresh();
            },
            error: (err) => {
                if (err.error.Error) {
                    this.loading(false);
                    NotificationHelper.Error(err.error.Message, '@@Error');
                }
            }
        })
    }

    onEdit(row) {
        // this.ToggleWindow();
        this.SelectedItem = row;
    }

    /*
    * Update Grid list
    * Show Grid Window
    * */
    refresh() {
        this.GetUserPermission();
        this.toggleWindow = true;
    }

    loading(value) {
        LayoutService.Loading.next(value);
        this.cdRef.detectChanges();
    }

    // ToggleWindow() {
    //     this.toggleWindow = !this.toggleWindow;
    // }

    onSelectType(value) {
        if (value == 'Module') {
            const key = this.SelectedItem.title.charAt(0).toUpperCase();
            const _code = this.generateCode(this.permissionList);
            this.SelectedItem.permissionCode = `${key}-${_code}`;
        }
    }

    onModuleSelect(list) {
        const titleAt = this.SelectedItem.title.charAt(0).toUpperCase();
        this.pagesList = list.Children;
        this.SelectedItem.parentId = list.PermissionId;
        this.SelectedItem.module = list.Title.charAt(0).toUpperCase();

        const _code = this.generateCode(list.Children);
        this.SelectedItem.permissionCode = `${this.SelectedItem.module + titleAt}-${_code}`;
    }

    onPageSelect(list) {
        const titleAt = this.SelectedItem.title.charAt(0).toUpperCase();

        this.SelectedItem.parentId = list.PermissionId;
        this.SelectedItem.page = list.Title.charAt(0).toUpperCase();
        const code = this.generateCode(list.Children);
        this.SelectedItem.permissionCode = `${this.SelectedItem.module + this.SelectedItem.page + titleAt}-${code}`;
    }

    generateCode(data) {
        let maxVal = 0;
        if (data.length > 0) {
            const codes = data.map(obj => parseInt(obj.PermissionCode.split('-')[1]));
            maxVal = Math.max(...codes);
        }
        const postfix = maxVal + 1;
        return `${postfix.toString().padStart(3, '0')}`;
    }

    // onDeleteRole(id) {
    //     this.loading(true)
    //     this.service.DeleteCompleteUserRole(id).subscribe(res => {
    //         this.loading(false)
    //         this.refresh();
    //     });
    // }
    //
    // deleteDialog(row) {
    //     return new Promise<boolean>(resolve => {
    //         MessageBoxHelper.ShowDialog(new TranslateFormatText('Are you sure you want to delete?'),
    //             new TranslateFormatText('Delete'), MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(retVal => {
    //             if (retVal === MessageBoxResult.Yes) {
    //                 this.onDeleteRole(row.Id);
    //             }
    //         });
    //     });
    // }

    //
    // FindUserPermission(id) {
    //     this.loading(true);
    //     this.service.FindUserRole(id).subscribe({
    //         next: (res) => {
    //             this.SelectedItem = res;
    //             this.loading(false);
    //         },
    //         error: (err) => {
    //             this.loading(false);
    //             NotificationHelper.Error(err.error.Message, '@@Error');
    //         },
    //
    //     })
    // }
    //
    //
    // UpdateUserPermissions(data) {
    //     this.loading(true);
    //     this.service.UpdateUserRole({Role: data}).subscribe({
    //         next: (res) => {
    //             this.loading(false);
    //             this.refresh();
    //         },
    //         error: (err) => {
    //             if (err.error.Error) {
    //                 this.loading(false);
    //                 NotificationHelper.Error(err.error.Message, '@@Error');
    //             }
    //         }
    //     })
    // }

}

interface PermissionNode {
    Title: string;
    PermissionCode: string;
    Children?: PermissionNode[];
}
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    code: string;
    level: number;
}
