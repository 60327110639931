import { MultiCacheService } from '../../../cache/multi/cache.service';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { FormulaNodeInformation, ValueAndType, VariablesNodeInformation } from '../../basic/formulaEditor.model';
import { ValueFormulaNodeCalculator } from '../../calculation/formula.node.calculator';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { Axis, AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { LevelType } from '../../enums';
import { FormulaInputMode } from '../../enums/formulainputmode.enum';
import { FormulaNodeType } from '../../enums/formulanodetype.enum';
import { AxisType, CellType, MemberType } from '../../enums/query.enum';
import { SpecialElementType } from '../../enums/specialElementType';
import { ValueType } from '../../enums/valuetype.enum';
import { TranslatedString } from '../../translatedstring.model';
import { ATask, ITaskExecuter } from '../atask.model';

export class CalculateTask extends ATask {
    ClientImageID: string;
    TaskType: string;
    Caption: string;
    ToolTipCaption: string;
    ToolTipDescription: string;
    IsValid: boolean;

    MeasureUniqueID: number;
    Formula: string;
    IgnoreNotVisibleMeasures = false;

    Execute() {
    }
}

export class CalculateTaskExecuter implements ITaskExecuter {
    static TaskID = 'calculate';

    MeasureUniqueID = -1;
    Formula: FormulaNodeInformation;
    IgnoreNotVisibleMeasures = false;
    Result: MultiResult;
    DataDescription: DataDescription;

    MeasureRootNodes: AxisNode[];
    OppositeAxis: Axis;
    UsedKeys;
    GetValue;
    Variables = [];
    MeasureNode: AxisNode;
    CellAction;

    static async GetMeasuresAndLevelsForCalcTask(dd: DataDescription, uniqueID) {
        const retVal = {
            Measures: [],
            Levels: []
        };
        if (dd) {
            const calcTasks = [];
            if (dd.Tasks) {
                dd.Tasks.some(t => {
                    if (t.TaskType === CalculateTaskExecuter.TaskID) {
                        if (t.MeasureUniqueID === uniqueID) {
                            return true;
                        }
                        calcTasks.push(t.MeasureUniqueID);
                    }
                    return false;
                });
            }
            let taskArea;
            const measures = [];
            const xLevels = [];
            dd.XLevelNodes.Areas.forEach((area, i) => {
                if (area.Tuples) {
                    area.Tuples.forEach(t => {
                        if (t.Levels) {
                            xLevels.push(...t.Levels);
                        }
                    });
                }
                if (area.Measures && area.Measures.length > 0) {
                    let areaCaption;
                    if (dd.XLevelNodes.Areas.length > 1) {
                        areaCaption = TranslatedString.GetTranslation(area.Caption);
                        if (!areaCaption) {
                            areaCaption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + (i + 1);
                        }
                    }
                    area.Measures.forEach(m => {
                        if (m.SpecialElementType === SpecialElementType.None) {
                            measures.push({
                                AreaCaption: areaCaption,
                                Measure: m
                            });
                        } else if (m.SpecialElementType === SpecialElementType.CalculatedElement) {
                            if (m.UniqueID === uniqueID) {
                                taskArea = {
                                    Caption: areaCaption,
                                    Area: area
                                };
                            } else if (calcTasks.some(ct => ct === m.UniqueID)) {
                                measures.push({
                                    AreaCaption: areaCaption,
                                    Measure: m
                                });
                            }
                        }
                    });
                }
            });
            // Measures auf X
            const measureAxisLevels = [];
            const oppositeLevels = [];
            if (measures.length > 0) {
                measureAxisLevels.push(...xLevels);
                dd.YLevelNodes.Areas.forEach(area => {
                    if (area.Tuples) {
                        area.Tuples.forEach(t => {
                            if (t.Levels) {
                                oppositeLevels.push(...t.Levels);
                            }
                        });
                    }
                });
            } else {
                oppositeLevels.push(...xLevels);
                dd.YLevelNodes.Areas.forEach((area, i) => {
                    if (area.Tuples) {
                        area.Tuples.forEach(t => {
                            if (t.Levels) {
                                measureAxisLevels.push(...t.Levels);
                            }
                        });
                    }
                    if (area.Measures && area.Measures.length > 0) {
                        let areaCaption;
                        if (dd.YLevelNodes.Areas.length > 1) {
                            areaCaption = TranslatedString.GetTranslation(area.Caption);
                            if (!areaCaption) {
                                areaCaption = TranslateHelper.TranslatorInstance.instant('@@Bereich') + ' ' + i;
                            }
                        }
                        area.Measures.forEach(m => {
                            if (m.SpecialElementType === SpecialElementType.None) {
                                measures.push({
                                    AreaCaption: areaCaption,
                                    Measure: m
                                });
                            } else if (m.SpecialElementType === 1) {
                                if (m.UniqueID === uniqueID) {
                                    taskArea = {
                                        Caption: areaCaption,
                                        Area: area
                                    };
                                } else if (calcTasks.some(ct => ct === m.UniqueID)) {
                                    measures.push({
                                        AreaCaption: areaCaption,
                                        Measure: m
                                    });
                                }
                            }
                        });
                    }
                });
            }
            for (let i = 0; i < measureAxisLevels.length; i++) {
                const level = await MultiCacheService.GetLevel(measureAxisLevels[i].Level, dd.DataModelID);
                if (!level || level.LevelType !== LevelType.All) {
                    measures.splice(0);
                    if (taskArea && taskArea.Area.Measures) {
                        taskArea.Area.Measures.forEach(m => {
                            if (m.SpecialElementType === SpecialElementType.None) {
                                measures.push({
                                    AreaCaption: taskArea.Caption,
                                    Measure: m
                                });
                            } else if (m.SpecialElementType === 1) {
                                if (calcTasks.some(ct => ct === m.UniqueID)) {
                                    measures.push({
                                        AreaCaption: taskArea.Caption,
                                        Measure: m
                                    });
                                }
                            }
                        });
                    }
                    break;
                }
            }
            retVal.Measures.push(...measures);
            if (taskArea && taskArea.Area.Tuples) {
                taskArea.Area.Tuples.forEach(t => {
                    if (t.Levels) {
                        retVal.Levels.push(...t.Levels);
                    }
                });
            }
            retVal.Levels.push(...oppositeLevels);
        }
        return retVal;
    }

    static GetAllVariableKeys(fni: FormulaNodeInformation) {
        const retVal: any = {};
        if (fni) {
            if (fni.Type === FormulaNodeType.Variable) {
                if (fni.VariableID) {
                    retVal[fni.VariableID] = 0;
                }
            } else if (fni.Type === FormulaNodeType.Operation) {
                fni.Operands.forEach(x => {
                    const opKeys = CalculateTaskExecuter.GetAllVariableKeys(x);
                    Object.assign(retVal, opKeys);
                });
            }
        }
        return retVal;
    }

    async Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.IgnoreNotVisibleMeasures === 'boolean') {
                this.IgnoreNotVisibleMeasures = settings.IgnoreNotVisibleMeasures;
            }
            if (settings.Formula) {
                const variables = [];
                const variableInfo = await CalculateTaskExecuter.GetMeasuresAndLevelsForCalcTask(dataDescription, settings.MeasureUniqueID);
                variableInfo.Measures.forEach(m => {
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = vni.AliasKey = 'M' + m.Measure.UniqueID;
                    variables.push(vni);
                });
                variableInfo.Levels.forEach(l => {
                    const vni = new VariablesNodeInformation();
                    vni.VariableID = vni.AliasKey = 'L' + l.UniqueID;
                    variables.push(vni);
                });
                this.Variables = variables;
                const parser = new AdvancedFormulaParser();
                parser.SetVariables(variables, FormulaInputMode.AliasKey);
                this.Formula = parser.Parse(settings.Formula);
                if (this.Formula && this.Result && this.DataDescription) {
                    this.UsedKeys = {
                        MeasureIDs: [],
                        LevelIDs: []
                    };
                    const allKeysObj = CalculateTaskExecuter.GetAllVariableKeys(this.Formula);
                    Object.keys(allKeysObj).forEach(key => {
                        if (key.length > 1) {
                            if (key[0] === 'M') {
                                const id = parseInt(key.substring(1), 10);
                                if (!isNaN(id)) {
                                    this.UsedKeys.MeasureIDs.push({
                                        ID: id,
                                        ExternalPosition: null,
                                        Visible: true
                                    });
                                }
                            } else if (key[0] === 'L') {
                                const id = parseInt(key.substring(1), 10);
                                if (!isNaN(id)) {
                                    this.UsedKeys.LevelIDs.push(id);
                                }
                            }
                        }
                    });
                    let noLevelIndex = -1;
                    this.DataDescription.XLevelNodes.Areas.forEach(area => {
                        if (area.Tuples && area.Tuples.some(x => x.Levels && x.Levels.length > 0)) {
                        } else if (!this.OppositeAxis) {
                            noLevelIndex++;
                        }
                        if (area.Measures) {
                            const usedFromArea = [];
                            let found = false;
                            area.Measures.forEach(m => {
                                if (m.UniqueID === this.MeasureUniqueID) {
                                    found = true;
                                    this.GetValue = MultiResultHelper.GetValueX;
                                    this.MeasureRootNodes = [];
                                    this.OppositeAxis = this.Result.YAxis;
                                    if (area.Tuples && area.Tuples.some(t => {
                                        if (t.Levels && t.Levels.length > 0) {
                                            const levelID = t.Levels[0].UniqueID;
                                            this.Result.XAxis.Nodes.forEach(n => {
                                                if (n.UniqueID === levelID) {
                                                    this.MeasureRootNodes.push(n);
                                                }
                                            });
                                            return true;
                                        }
                                        return false;
                                    })) {
                                    } else {
                                        let actIndex = 0;
                                        this.Result.XAxis.Nodes.some(n => {
                                            if (n.UniqueID === -1) {
                                                if (actIndex === noLevelIndex) {
                                                    this.MeasureRootNodes.push(n);
                                                    return true;
                                                }
                                                actIndex++;
                                            }
                                            return false;
                                        });
                                    }
                                    this.MeasureNode = this.Result.Measures.Nodes.find(x => x.UniqueID === this.MeasureUniqueID);
                                    if (!this.MeasureNode) {
                                        this.MeasureNode = new AxisNode();
                                        this.MeasureNode.Axis = AxisType.Measure;
                                        this.MeasureNode.Caption = m.toString();
                                        this.MeasureNode.Measure = true;
                                        this.MeasureNode.Position = this.Result.Measures.Nodes.length;
                                        this.MeasureNode.Type = MemberType.Measure;
                                        this.MeasureNode.UniqueID = this.MeasureUniqueID;
                                        this.Result.Measures.Nodes.push(this.MeasureNode);
                                        this.Result.Measures.ElementCount++;
                                    }
                                } else {
                                    const used = this.UsedKeys.MeasureIDs.find(umk => umk.ID === m.UniqueID);
                                    if (used) {
                                        used.Visible = m.IsVisible;
                                        if (!found) {
                                            usedFromArea.push(used);
                                            const matrixM = this.Result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                            if (matrixM) {
                                                const infoObj = {
                                                    Measure: matrixM,
                                                    Level: null
                                                };
                                                if (area.Tuples) {
                                                    area.Tuples.some(t => {
                                                        if (t.Levels && t.Levels.length > 0) {
                                                            const levID = t.Levels[0].UniqueID;
                                                            const node = this.Result.XAxis.Nodes.find(n => n.UniqueID === levID);
                                                            if (node) {
                                                                infoObj.Level = node;
                                                            }
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                }
                                                used.ExternalPosition = infoObj;
                                            }
                                        }
                                    }
                                }
                            });
                            if (found) {
                                usedFromArea.forEach(x => {
                                    x.ExternalPosition = null;
                                });
                            }
                        }
                    });
                    if (!this.OppositeAxis) {
                        noLevelIndex = -1;
                        this.DataDescription.YLevelNodes.Areas.forEach(area => {
                            if (area.Tuples && area.Tuples.some(x => x.Levels && x.Levels.length > 0)) {
                            } else if (!this.OppositeAxis) {
                                noLevelIndex++;
                            }
                            if (area.Measures) {
                                const usedFromArea = [];
                                let found = false;
                                area.Measures.forEach(m => {
                                    if (m.UniqueID === this.MeasureUniqueID) {
                                        found = true;
                                        this.GetValue = MultiResultHelper.GetValueY;
                                        this.MeasureRootNodes = [];
                                        this.OppositeAxis = this.Result.XAxis;
                                        if (area.Tuples && area.Tuples.some(t => {
                                            if (t.Levels && t.Levels.length > 0) {
                                                const levelID = t.Levels[0].UniqueID;
                                                this.Result.YAxis.Nodes.forEach(n => {
                                                    if (n.UniqueID === levelID) {
                                                        this.MeasureRootNodes.push(n);
                                                    }
                                                });
                                                return true;
                                            }
                                            return false;
                                        })) {
                                        } else {
                                            let actIndex = 0;
                                            this.Result.YAxis.Nodes.some(n => {
                                                if (n.UniqueID === -1) {
                                                    if (actIndex === noLevelIndex) {
                                                        this.MeasureRootNodes.push(n);
                                                        return true;
                                                    }
                                                    actIndex++;
                                                }
                                                return false;
                                            });
                                        }
                                        this.MeasureNode = this.Result.Measures.Nodes.find(x => x.UniqueID === this.MeasureUniqueID);
                                        if (!this.MeasureNode) {
                                            this.MeasureNode = new AxisNode();
                                            this.MeasureNode.Axis = AxisType.Measure;
                                            this.MeasureNode.Caption = m.toString();
                                            this.MeasureNode.Measure = true;
                                            this.MeasureNode.Position = this.Result.Measures.Nodes.length;
                                            this.MeasureNode.Type = MemberType.Measure;
                                            this.MeasureNode.UniqueID = this.MeasureUniqueID;
                                            this.Result.Measures.Nodes.push(this.MeasureNode);
                                            this.Result.Measures.ElementCount++;
                                        }
                                    } else {
                                        const used = this.UsedKeys.MeasureIDs.find(umk => umk.ID === m.UniqueID);
                                        if (used) {
                                            used.Visible = m.IsVisible;
                                            if (!found) {
                                                usedFromArea.push(used);
                                                const matrixM = this.Result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                                if (matrixM) {
                                                    const infoObj = {
                                                        Measure: matrixM,
                                                        Level: null
                                                    };
                                                    if (area.Tuples) {
                                                        area.Tuples.some(t => {
                                                            if (t.Levels && t.Levels.length > 0) {
                                                                const levID = t.Levels[0].UniqueID;
                                                                const node = this.Result.YAxis.Nodes.find(n => n.UniqueID === levID);
                                                                if (node) {
                                                                    infoObj.Level = node;
                                                                }
                                                                return true;
                                                            }
                                                            return false;
                                                        });
                                                    }
                                                    used.ExternalPosition = infoObj;
                                                }
                                            }
                                        }
                                    }
                                });
                                if (found) {
                                    usedFromArea.forEach(x => {
                                        x.ExternalPosition = null;
                                    });
                                }
                            }
                        });
                    }
                    if (context) {
                        if (!context.CalcTaskExecuter) {
                            context.CalcTaskExecuter = [this];
                        } else {
                            context.CalcTaskExecuter.push(this);
                        }
                    }
                }
            }
        }
    }

    Execute() {
        if (this.MeasureRootNodes) {
            this.MeasureRootNodes.forEach(n => {
                this.IterateMeasureAxis(n, {}, this.OppositeAxis.Nodes);
            });
        }
    }

    IterateMeasureAxis(node: AxisNode, levelInfo, oppNodes: AxisNode[]) {
        const axisClone = Object.assign({}, levelInfo);
        axisClone['' + node.UniqueID] = node;
        oppNodes.forEach(oppNode => {
            this.IterateOppositeAxis(node, oppNode, axisClone);
        });
        if (node.Children) {
            node.Children.forEach(child => {
                this.IterateMeasureAxis(child, axisClone, oppNodes);
            });
        }
    }

    IterateOppositeAxis(measureNode: AxisNode, oppNode: AxisNode, levelInfo) {
        const axisClone = Object.assign({}, levelInfo);
        axisClone['' + oppNode.UniqueID] = oppNode;
        this.CalcValue(measureNode, oppNode, levelInfo);
        if (oppNode.Children) {
            oppNode.Children.forEach(child => {
                this.IterateOppositeAxis(measureNode, child, levelInfo);
            });
        }
    }

    CalcValue(measureNode, oppNode, levelInfo) {
        const values = new Map<string, ValueAndType>();
        this.UsedKeys.MeasureIDs.forEach(mID => {
            let cellVal;
            if (this.IgnoreNotVisibleMeasures !== true || mID.Visible) { // Sichtbarkeit noch anders pruefen?
                if (mID.ExternalPosition) {
                    cellVal = this.GetValue(this.Result, mID.ExternalPosition.Level, oppNode, mID.ExternalPosition.Measure, false);
                } else {
                    const m = this.Result.Measures.Nodes.find(x => x.UniqueID === mID.ID);
                    if (m) {
                        cellVal = this.GetValue(this.Result, measureNode, oppNode, m, false);
                    }
                }
            }
            if (cellVal) {
                values.set('M' + mID.ID, ValueAndType.GetValueAndTypeFromJSObject(cellVal.InternalValue));
            } else {
                values.set('M' + mID.ID, new ValueAndType());
            }
        });
        this.UsedKeys.LevelIDs.forEach(lID => {
            const levelNode = levelInfo['' + lID];
            if (levelNode) {
                values.set('L' + lID, {
                    Type: ValueType.Object,
                    Value: levelNode
                });
            }
        });

        const fnc = new ValueFormulaNodeCalculator(false, this.Variables, values);
        const value = fnc.Calc(this.Formula);
        const cell = this.GetValue(this.Result, measureNode, oppNode, this.MeasureNode, true);
        if (value) {
            switch (value.Type) {
                case ValueType.Double:
                    cell.CellType = CellType.Double;
                    break;
                case ValueType.Long:
                    cell.CellType = CellType.Long;
                    break;
                case ValueType.Datetime:
                    cell.CellType = CellType.DateTime;
                    break;
                default:
                    cell.CellType = CellType.String;
                    break;
            }
            cell.InternalValue = value.Value;
        }
        if (this.CellAction) {
            this.CellAction(cell);
        }
    }
}
