import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { MediaService } from '../../../../../services/media.service';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'imap-panel',
    templateUrl: './imap.panel.html',
    styleUrls:['./imap.panel.css']
})
export class ImapPanel extends BaseDataSourcePanel {
    static GetInfo(): DBPanelInfo {
        return {
            ControlType: ImapPanel,
            DBInfoType: ImapDBInfo
        };
    }

    constructor(private service: MediaService) {
        super();
        this.DBInfoType = ImapDBInfo;
    }

    //ngOnInit(): void {
    //    this.service.Get().subscribe(result => {
    //        if (result) {
    //            this.MediaSources = result;
    //        }
    //    }); 
    //}
}

export class ImapDBInfo extends ADBInfo {
    Server: string = null;
    Port: number = 0;
    Username: string = null;
    Password: string = null;
    SSL: boolean = true;
    //MediaSource: string;
    getTypeName(): string {
        return 'evidanza.Database.Imap.ImapDBInfo';
    }
}
