import { Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { IDKeeper } from '../../../components/common/basetreecontrol/base.tree.control';
import { ContentDialog } from '../../../components/dialogs/content/content.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { DialogHelper } from '../../../helpers/injector.helper';
import { DialogType } from '../../../models/enums/dialogtype.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { MetaService } from '../../../services/meta.service';
import { SideNavService } from '../../../services/sidenav.service';
import { ObsoleteInfo, WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { OpenURLNode } from '../navigation/openURL.settings';
import { ShowMessageBoxSettings } from './show.messagebox.settings';

@Component({
    selector: 'showDialog-settings',
    templateUrl: './showDialog.settings.html',
    styleUrls: ['./showDialog.settings.css']
})
export class ShowDialogSettings extends WorkflowDialogContent {
    ActualText = '';
    ActualIsQuestion = false;
    NavigationItems;
    Parameters = [];
    URL;
    Type;
    Types;
    Templates;
    Template;

    private static GetNode(navItem, id: IDKeeper) {
        const retVal = new OpenURLNode(id.NextID);
        retVal.Caption = navItem.Label;
        retVal.data = navItem.Url;
        if (navItem.children) {
            retVal.HasChildren = true;
            retVal.Children = [];
            retVal.IsExpanded = true;
            navItem.children.forEach(function (ni) {
                const node = ShowDialogSettings.GetNode(ni, id);
                retVal.Children.push(node);
            });
        }
        return retVal;
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'dialogWFModule';
        reg.Caption = '@@Dialog';
        reg.Index = 40;
        reg.SettingsControl = ShowDialogSettings;
        reg.SettingsTypeHelper = new ShowDialogSettingsDataHelper();
        reg.Executer = ShowDialogModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = ShowMessageBoxSettings.ModuleID;
        return reg;
    }

    constructor(private metaService: MetaService) {
        super();
        this.HasExpressions = true;
        this.Types = EnumHelper.GetDropdownValues(DialogType);
    }

    initialize(data: any) {
        if (data) {
            if (data.Text) {
                this.ActualText = data.Text;
            }
            if (data.IsQuestion) {
                this.ActualIsQuestion = data.IsQuestion;
            }
            if (data.URL) {
                this.URL = data.URL;
            }
            if (data.Parameters) {
                this.Parameters = data.Parameters;
            }
            if (typeof data.Type === 'number') {
                this.Type = data.Type;
            }
            if (data.Template) {
                this.Template = data.Template;
            }
        }
    }

    ngOnInit() {
        if (!this.WFEditOptions || !this.WFEditOptions.IsTemplate) {
            const nav = SideNavService.SelectedNavigation.getValue();
            if (nav && nav.NavigationItems) {
                const list = [];
                const id = new IDKeeper();
                nav.NavigationItems.forEach(function (ni) {
                    const node = ShowDialogSettings.GetNode(ni, id);
                    list.push(node);
                });
                this.NavigationItems = list;
            }
            this.metaService.ReadTemplateInfos().subscribe((templates) => {
                this.Templates = templates;
            });
        }
    }

    getResult(): any {
        const retVal = new ShowDialogSettingsData();
        retVal.Text = this.ActualText;
        retVal.IsQuestion = this.ActualIsQuestion;
        retVal.URL = this.URL;
        retVal.Type = this.Type;
        retVal.Parameters = this.Parameters;
        retVal.Template = this.Template;
        return retVal;
    }

    GetExpressionProperties() {
        return [{
            Value: 'Text'
        }];
    }

    nodeSelected(ev) {
        if (ev && ev.data) {
            this.URL = ev.data;
        }
    }

    addParam() {
        this.Parameters.push(new URLParameter());
    }

    removeParam(i) {
        this.Parameters.splice(i, 1);
    }
}

export class URLParameter {
    Name: string;
    Manual: boolean;
    Value: string;
}

export class ShowDialogSettingsData {
    Text = '';
    IsQuestion = false;
    Type: DialogType;
    Parameters = [];
    URL;
    Template;
}

export class ShowDialogSettingsDataHelper extends WorkflowModuleSettingsHelper {

    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getEmptySettingsInstance() {
        return new ShowDialogSettingsData();
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        const retVal = [];
        if (settings) {
            const truePoint = new WorkflowExitInfo();
            truePoint.ID = 0;
            retVal.push(truePoint);
            if (typeof settings.IsQuestion === 'boolean' && settings.IsQuestion) {
                truePoint.Label = '@@Ja';
                const falsePoint = new WorkflowExitInfo();
                falsePoint.ID = 1;
                falsePoint.Label = '@@Nein';
                retVal.push(falsePoint);
            }
        }
        return retVal;
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Parameters) {
            settings.Parameters.forEach(p => {
                if (!p.Manual && p.Value === oldName) {
                    p.Value = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class ShowDialogModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            switch (status.ActualSettings.Type) {
                case DialogType.MessageBox:
                    if (typeof status.ActualSettings.IsQuestion === 'boolean' && status.ActualSettings.IsQuestion) {
                        const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText(status.ActualSettings.Text),
                            new TranslateFormatText('@@Meldung'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                        if (retVal === MessageBoxResult.Yes) {
                            status.ActualParameter = true;
                            return 0;
                        }
                        status.ActualParameter = false;
                        return 1;
                    } else {
                        await MessageBoxHelper.ShowDialog(new TranslateFormatText(status.ActualSettings.Text),
                            new TranslateFormatText('@@Meldung'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                        return 0;
                    }
                case DialogType.Template:
                    const tempconfig = new MatDialogConfig();
                    tempconfig.data = status;
                    const tempdialogRef = DialogHelper.DialogInstance.open(ContentDialog, tempconfig);
                    const tempretVal = await tempdialogRef.afterClosed().toPromise();
                    return tempretVal;
                case DialogType.Content:
                    const config = new MatDialogConfig();
                    config.data = status.ActualSettings;
                    const dialogRef = DialogHelper.DialogInstance.open(ContentDialog, config);
                    const contRetVal = await dialogRef.afterClosed().toPromise();
                    return contRetVal;
                default:
                    return 0;
            }
        } else {
            status.Logger.logError('Dialog modul: No settings found.');
        }
        return super.execute(status);
    }
}
