import { PrimitiveFormula, FormulaGroups } from './iformula';
import { ValueAndType, FormulaStatics } from '../basic/formulaEditor.model';
import { FormulaNodeCalculator } from './formula.node.calculator';
import { add } from 'mathjs';
import { OperationType } from '../enums/operationtype.enum';
import { ValueType } from '../enums/valuetype.enum';

export class Addition extends PrimitiveFormula {
    static StaticID = 'f0379dab-a270-41a8-b327-79eb53546ddf';

    constructor() {
        super();
        this._ID = Addition.StaticID;
        this._Name = 'Addition';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
        this._MinVariableCount = 1;
    }

    CanCalcNulls(): boolean {
        return true;
    }
    Group(): string {
        return FormulaGroups.BasicOperations;
    }
    OrderID(): number {
        return 1;
    }
    OperationType(): OperationType {
        return OperationType.Addition;
    }
    OperationValue(): string {
        return '+';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        const retVal = new ValueAndType();
        retVal.Type = maxType;
        if (args) {
            args.forEach((item) => {
                if (typeof item.Value !== 'undefined' && item.Value !== null) {
                    if (retVal.Value === null) {
                        if (maxType === ValueType.Long || maxType === ValueType.Double) {
                            retVal.Value = item.Value;
                        } else if (maxType === ValueType.String) {
                            retVal.Value = String(item.Value);
                        }
                    } else {
                        if (maxType === ValueType.Long || maxType === ValueType.Double) {
                            retVal.Value = add(retVal.Value, item.Value);
                        } else if (maxType === ValueType.String) {
                            retVal.Value += String(item.Value);
                        }
                    }
                }
            });
        }
        return retVal;
    }
}
