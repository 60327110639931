import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { ClipboardHelper } from '../../helpers/clipboard.helper';
import { LayoutHelper } from '../../helpers/layout.helper';
import { MetaHelper } from '../../helpers/meta.helper';
import { NotificationHelper } from '../../helpers/notification.helper';
import { DropState } from '../../models/enums/dropstate.enum';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { MultiLayoutPositionChangeValue, MultiPropertyChangeValue, PositionChangeSourceValue } from '../../models/layout/layout.change.model';
import { LayoutElement } from '../../models/layoutelement.model';
import { REGISTRY } from '../../services/dynamic.component.service';
import { LayoutService } from '../../services/layout.service';
import { NavigationService } from '../../services/navigation.service';
import { ABaseTreeNode, IDKeeper } from '../common/basetreecontrol/base.tree.control';
import { EventTreeDialog } from '../dialogs/eventtree/event.tree.dialog';
import { MessageBoxHelper } from '../dialogs/messagebox/messagebox.dialog';
import { WorkflowNameChangeDialog } from '../dialogs/workflowdebug/workflow.name.change.dialog';

@Component({
    selector: 'layout-tree',
    templateUrl: './layout.tree.html',
    styleUrls: ['./layout.tree.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutTree implements OnInit, OnDestroy {
    Registry = REGISTRY;
    //#region SelectedNode
    SelectedNodeValue;
    @Input()
    get SelectedNode() {
        return this.SelectedNodeValue;
    }
    set SelectedNode(val) {
        this.internalSelect = true;
        if (this.SelectedNodeValue) {
            this.SelectedNodeValue.Hover = false;
        }
        this.SelectedNodesValue = null;
        LayoutTree.ExecuteOnAllNodes(this.Elements, (x) => {
            x.IsSelected = false;
        });
        this.SelectedNodeValue = val;
        if (this.SelectedNodeValue) {
            this.SelectedNodeValue.IsSelected = true;
            this.SelectedNodeValue.Hover = true;
        }
        this.SelectedNodeChange.emit(this.SelectedNodeValue);
    }

    @Output() SelectedNodeChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedNodes
    SelectedNodesValue;
    @Input()
    get SelectedNodes() {
        return this.SelectedNodesValue;
    }
    set SelectedNodes(val) {
        this.internalSelect = true;
        if (val != undefined) {
            if (this.SelectedNodeValue) {
                this.SelectedNodeValue.Hover = false;
                this.SelectedNodeValue = null;
            }
            LayoutTree.ExecuteOnAllNodes(this.Elements, (x) => {
                x.IsSelected = false;
            });
            this.SelectedNodesValue = val;
            if (val) {
                val.forEach(x => {
                    x.IsSelected = true;
                });
            }
            this.SelectedNodesChange.emit(this.SelectedNodesValue);
        }
    }

    @Output() SelectedNodesChange = new EventEmitter<any>();
    //#endregion
    //#region ShowIcons
    ShowIconsValue = true;

    @Input()
    get ShowIcons() {
        return this.ShowIconsValue;
    }
    set ShowIcons(val) {
        this.ShowIconsValue = val;
        this.ShowIconsChange.emit(this.ShowIconsValue);
    }

    @Output() ShowIconsChange = new EventEmitter<any>();
    //#endregion
    //#region ShowDeleteButton
    ShowDeleteButtonValue;

    @Input()
    get ShowDeleteButton() {
        return this.ShowDeleteButtonValue;
    }
    set ShowDeleteButton(val) {
        this.ShowDeleteButtonValue = val;
        this.ShowDeleteButtonChange.emit(this.ShowDeleteButtonValue);
    }

    @Output() ShowDeleteButtonChange = new EventEmitter<any>();
    //#endregion
    //#region ShowEditButton
    ShowEditButtonValue;

    @Input()
    get ShowEditButton() {
        return this.ShowEditButtonValue;
    }
    set ShowEditButton(val) {
        this.ShowEditButtonValue = val;
        this.ShowEditButtonChange.emit(this.ShowEditButtonValue);
    }

    @Output() ShowEditButtonChange = new EventEmitter<any>();
    //#endregion
    //#region ShowDuplicateButton
    ShowDuplicateButtonValue;

    @Input()
    get ShowDuplicateButton() {
        return this.ShowDuplicateButtonValue;
    }
    set ShowDuplicateButton(val) {
        this.ShowDuplicateButtonValue = val;
        this.ShowDuplicateButtonChange.emit(this.ShowDuplicateButtonValue);
    }

    @Output() ShowDuplicateButtonChange = new EventEmitter<any>();
    //#endregion
    //#region ShowCopyButton
    ShowCopyButtonValue;

    @Input()
    get ShowCopyButton() {
        return this.ShowCopyButtonValue;
    }
    set ShowCopyButton(val) {
        this.ShowCopyButtonValue = val;
        this.ShowCopyButtonChange.emit(this.ShowCopyButtonValue);
    }

    @Output() ShowCopyButtonChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItem
    SelectedItemValue;
    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        if (val != undefined) {
            this.SelectedItemValue = val;
            if (this.IsInitialized) {
                this.SelectItem(this.Elements, val, []);
            }
            this.SelectedItemChange.emit(this.SelectedItemValue);
            this.cdRef.detectChanges();
        }
    }
    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion
    //#region SelectedItems
    SelectedItemsValue;

    @Input()
    get SelectedItems() {
        return this.SelectedItemsValue;
    }
    set SelectedItems(val) {
        if (val != undefined) {
            this.SelectedItemsValue = val;
            this.SelectedItemsChange.emit(this.SelectedItemsValue);
            this.cdRef.detectChanges();
        }
    }
    @Output() SelectedItemsChange = new EventEmitter<any>();
    //#endregion
    //#region ShowRoot
    ShowRootValue;

    @Input()
    get ShowRoot() {
        return this.ShowRootValue;
    }
    set ShowRoot(val) {
        this.ShowRootValue = val;
        this.ShowRootChange.emit(this.ShowRootValue);
    }

    @Output() ShowRootChange = new EventEmitter<any>();
    //#endregion
    //#region ChangeRootType
    ChangeRootTypeValue = false;

    @Input()
    get ChangeRootType() {
        return this.ChangeRootTypeValue;
    }
    set ChangeRootType(val) {
        this.ChangeRootTypeValue = val;
        this.ChangeRootTypeChange.emit(this.ChangeRootTypeValue);
    }

    @Output() ChangeRootTypeChange = new EventEmitter<any>();
    //#endregion
    //#region ShowInfo
    ShowInfoValue;

    @Input()
    get ShowInfo() {
        return this.ShowInfoValue;
    }
    set ShowInfo(val) {
        this.ShowInfoValue = val;
        this.ShowInfoChange.emit(this.ShowInfoValue);
    }

    @Output() ShowInfoChange = new EventEmitter<any>();
    //#endregion
    //#region Layout
    LayoutValue;

    @Input()
    get Layout() {
        return this.LayoutValue;
    }
    set Layout(val) {
        this.LayoutValue = val;
        this.Component = this.LayoutValue;
        this.RefreshNodes();
        this.LayoutChange.emit(this.LayoutValue);
    }

    @Output() LayoutChange = new EventEmitter<any>();
    //#endregion
    MultipleSelectionValue = false;
    @Input()
    get MultipleSelection(): boolean {
        return this.MultipleSelectionValue;
    }
    set MultipleSelection(val) {
        this.MultipleSelectionValue = val;
    }
    //#region NodeLoader
    NodeLoaderValue: ILayoutTreeNodeLoader;

    @Input()
    get NodeLoader() {
        return this.NodeLoaderValue;
    }
    set NodeLoader(val: ILayoutTreeNodeLoader) {
        this.NodeLoaderValue = val;
        this.NodeLoaderChange.emit(this.NodeLoaderValue);
    }

    @Output() NodeLoaderChange = new EventEmitter<any>();
    //#endregion

    //#region CanDrag
    CanDragValue = false;
    @Input()
    get CanDrag() {
        return this.CanDragValue;
    }
    set CanDrag(val: boolean) {
        this.CanDragValue = val;
        this.CanDragChange.emit(this.CanDragValue);
    }

    @Output() CanDragChange = new EventEmitter<any>();
    //#endregion

    Component;
    Elements = [];
    Subscriptions = [];
    TreeNodes = [];

    IsInitialized = false;
    @Output() Initialized = new EventEmitter<any>();

    @Output() DeleteElement = new EventEmitter<any>();
    @Output() OnRefresh = new EventEmitter<any>();
    @Output() SelectionChanged = new EventEmitter<any>();
    internalSelect = false;
    dragState;

    ShowPasteButton = false;

    constructor(private cdRef: ChangeDetectorRef, private clipboard: Clipboard) { }
    //#region Statics
    private static getNodeRecursive(nodeList, name) {
        let retVal;
        if (nodeList && name) {
            nodeList.some(node => {
                if ((node.longName && node.longName === name) || (node.data && node.data.Name === name)) {
                    retVal = node;
                    return true;
                }
                retVal = LayoutTree.getNodeRecursive(node.Children, name);
                if (retVal) {
                    return true;
                }
                return false;
            });
        }
        return retVal;
    }

    private static getTreeLine(elem: LayoutElement, templateName: string, id: IDKeeper, parent: LayoutTreeNode): any {
        const retVal = TypeNameLoader.GenerateTreeNode(elem, templateName, id, parent);
        if (elem['IsRoot']) {
            retVal.canCopy = false;
            if (elem.ElementType == 'grid' || elem.ElementType == 'flex' || elem.ElementType == 'raster' || elem.ElementType == 'canvas') {
                retVal.canPaste = true;
            }
        } else if (elem.ElementType) {
            const regEntry = REGISTRY[elem.ElementType];
            if (regEntry) {
                retVal.Icon = regEntry.Icon;
            }
            if (elem.ElementType == 'grid' || elem.ElementType == 'flex' || elem.ElementType == 'raster' || elem.ElementType == 'canvas') {
                retVal.Icon = null;
                retVal.canPaste = true;
            }
        }
        const page = NavigationService.SelectedPage.getValue();
        if (elem.Elements && elem.Elements.length > 0) {
            if (!((elem.ElementType === 'template' && elem['LoadByReference']) || (elem.ElementType === 'widget' && page !== 'widget'))) {
                retVal.HasChildren = true;
                retVal.Children = [];
            }
            if (elem.ElementType === 'template') {
                if (!elem['LoadByReference']) {
                    if (templateName) {
                        templateName += '.' + (elem.Name || '');
                    } else {
                        templateName = elem.Name || '';
                    }
                    elem.Elements.forEach(child => {
                        const childLine = LayoutTree.getTreeLine(child, templateName, id, retVal);
                        retVal.Children.push(childLine);
                    });
                }
            } else if (elem.ElementType === 'widget') {
                if (page === 'widget') {
                    if (templateName) {
                        templateName += '.' + (elem.Name || '');
                    } else {
                        templateName = elem.Name || '';
                    }
                    elem.Elements.forEach(child => {
                        const childLine = LayoutTree.getTreeLine(child, templateName, id, retVal);
                        retVal.Children.push(childLine);
                    });
                }
            } else if (elem.ElementType === 'tab' || elem.ElementType === 'accordion' || elem.ElementType === 'expansionpanel') {
                elem.Elements.forEach(child => {
                    const childLine = LayoutTree.getTreeLine(child, templateName, id, retVal);
                    childLine.canDelete = false;
                    childLine.Draggable = false;
                    retVal.Children.push(childLine);
                });
            } else {
                elem.Elements.forEach(child => {
                    const childLine = LayoutTree.getTreeLine(child, templateName, id, retVal);
                    retVal.Children.push(childLine);
                });
            }
        }
        return retVal;
    }

    private static ChangeCopy(copy, viewType, elemNames) {
        let name = copy.Name + '_copy';
        let index = 1;
        while (elemNames[name] === true) {
            name = copy.Name + '_copy_' + index++;
        }
        copy.Name = name;
        elemNames[name] = true;
        copy.ID = UUID.UUID();
        copy.ViewType = viewType;
        if (copy.Elements) {
            copy.Elements.forEach(x => LayoutTree.ChangeCopy(x, viewType, elemNames));
        }
    }

    private static GetElementNames(elem, dict) {
        if (elem.Name) {
            dict[elem.Name] = true;
        }
        if (elem.Elements) {
            elem.Elements.forEach(x => {
                LayoutTree.GetElementNames(x, dict);
            });
        }
    }

    private static CheckForExistingName(elem, name) {
        if (elem.Name === name) {
            return true;
        }
        if (elem.Elements) {
            return elem.Elements.some(x => LayoutTree.CheckForExistingName(x, name));
        }
        return false;
    }

    private static ResetDataBinding(le, elemIDList) {
        le.DataSource = null;
        le.DataSourceID = null;
        le.DataModelID = null;
        elemIDList.push(le.ID);
        if (le.Elements && le.Elements.length > 0) {
            le.Elements.forEach((item) => {
                LayoutTree.ResetDataBinding(item, elemIDList);
            });
        }
    }

    private static FillTreeNodes(elements, treeNodes) {
        if (elements) {
            elements.forEach(x => {
                treeNodes.push(x);
                if (x.HasChildren && x.IsExpanded) {
                    LayoutTree.FillTreeNodes(x.Children, treeNodes);
                }
            });
        }
        return treeNodes;
    }

    private static ExecuteOnAllNodes(elements, action) {
        if (elements) {
            elements.forEach(x => {
                action(x);
                LayoutTree.ExecuteOnAllNodes(x.Children, action);
            });
        }
    }

    static FillState(nodes, state) {
        nodes.forEach(x => {
            if (x.HasChildren) {
                if (x.LayoutElement) {
                    state[x.LayoutElement.ID] = x.IsExpanded;
                }
                LayoutTree.FillState(x.Children, state);
            }
        });
    }

    static ApplyState(nodes, state) {
        nodes.forEach(x => {
            if (x.HasChildren) {
                if (x.LayoutElement) {
                    const stateVal = state[x.LayoutElement.ID];
                    if (typeof stateVal === 'boolean') {
                        x.IsExpanded = stateVal;
                    }
                }
                LayoutTree.ApplyState(x.Children, state);
            }
        });
    }
    //#endregion
    ngOnInit(): void {
        this.Component = this.Layout;

        if (ClipboardHelper.Inititalized) {
            this.ShowCopyButton = true;
            this.ShowDuplicateButton = false;
            if (ClipboardHelper.ClipboardContent && ClipboardHelper.ClipboardContent.type && ClipboardHelper.ClipboardContent.type == "LayoutElementCopy") {
                this.ShowPasteButton = true;
            } else {
                this.ShowPasteButton = false;
            }
            ClipboardHelper.ClipboardChanged.subscribe((data:any) => {
                if (data && data.type && data.type == "LayoutElementCopy") {
                    this.ShowPasteButton = true;
                } else {
                    this.ShowPasteButton = false;
                }
                //this.cdRef.detectChanges();
            });
        } else {
            this.ShowCopyButton = false;
            this.ShowDuplicateButton = true;
        }

        this.Subscriptions.push(LayoutService.RefreshTree.subscribe((val) => {
            if (val) {
                this.RefreshNodes();
            }
        }));
        this.Subscriptions.push(LayoutService.LayoutElementsChangedEvent.subscribe(() => {
            this.RefreshNodes();
        }));
        this.Subscriptions.push(LayoutService.LayoutPropertyChangedEvent.subscribe((obj) => {
            const checkProps = ['Name', 'Variables', 'Events', 'Elements'];
            if (obj && Object.keys(obj).some(x => obj[x].some(y => checkProps.indexOf(y.PropertyName) > -1))) {
                this.RefreshNodes();
            }
        }));
        this.Subscriptions.push(LayoutService.RootSize.subscribe((size) => {
            if (size && this.Layout && this.Layout.Resolutions) {
                let found = false;
                this.Layout.Resolutions.forEach((res) => {
                    if (size.Width > res.FromWidth && size.Width <= res.ToWidth) {
                        this.Component = res;
                        found = true;
                    }
                });
                if (!found) {
                    this.Component = this.Layout;
                }
                this.RefreshNodes();
            }
        }));
        this.IsInitialized = true;
        this.RefreshNodes();
        this.Initialized.emit(this);
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.Subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    SelectItem(elements, val, parents) {
        if (elements && elements.length > 0) {
            elements.forEach((item) => {
                if (item.data === val) {
                    if (!this.internalSelect) {
                        if (parents) {
                            parents.forEach((parent) => {
                                parent.IsExpanded = true;
                            });
                        }
                        this.updateTreeLines();
                        setTimeout(() => {
                            const elementList = document.querySelectorAll('.selectedNode');
                            if (elementList.length > 0) {
                                const element = elementList[0] as HTMLElement;
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        }, 100);
                    } else {
                        this.internalSelect = false;
                    }
                    if (this.SelectedNodeValue) {
                        this.SelectedNodeValue.Hover = false;
                    }
                    LayoutTree.ExecuteOnAllNodes(this.Elements, (x) => {
                        x.IsSelected = false;
                    });
                    this.SelectedNodeValue = item;
                    if (this.SelectedNodeValue) {
                        this.SelectedNodeValue.IsSelected = true;
                        this.SelectedNodeValue.Hover = true;
                    }
                } else {
                    if (item.Children && item.Children.length > 0) {
                        const pList = [];
                        if (parents) {
                            pList.push(...parents);
                        }
                        pList.push(item);
                        this.SelectItem(item.Children, val, pList);
                    }
                }
            });
        }
    }
    SelectItems(elements, vals) {
        if (elements && elements.length > 0) {
            elements.forEach((item) => {
                if (vals.find((value) => value == item.data) != null) {
                    if (!this.SelectedNodesValue) {
                        this.SelectedNodesValue = [];
                    }
                    this.SelectedNodesValue.push(item);
                } else {
                    if (item.Children && item.Children.length > 0) {
                        this.SelectItems(item.Children, vals);
                    }
                }
            });
        }
    }

    RefreshNodes(): void {
        if (this.IsInitialized && this.Component) {
            let expandState;
            if (this.Elements && this.Elements.length > 0) {
                expandState = {};
                LayoutTree.FillState(this.Elements, expandState);
            }
            let nodes = [];
            if (this.NodeLoaderValue) {
                this.NodeLoaderValue.Layout = this.Component;
                nodes = this.NodeLoaderValue.getNodes();
            } else {
                const idKeeper = new IDKeeper();
                if (this.ShowRootValue) {
                    this.Component.IsRoot = true;
                    nodes = [LayoutTree.getTreeLine(this.Component, null, idKeeper, null)];
                } else {
                    const list = [];
                    this.Component.Elements.forEach(elem => {
                        const treeLine = LayoutTree.getTreeLine(elem, null, idKeeper, null);
                        list.push(treeLine);
                    });
                    nodes = list;
                }
            }
            if (expandState) {
                LayoutTree.ApplyState(nodes, expandState);
            }
            this.Elements = nodes;
            if (this.Elements && this.Elements.length > 0) {
                if (this.SelectedItemValue) {
                    this.SelectItem(this.Elements, this.SelectedItemValue, []);
                }
                if (this.SelectedItemsValue) {
                    this.SelectItems(this.Elements, this.SelectedItemsValue);
                }
            }
            this.OnRefresh.emit(nodes);
            this.updateTreeLines();
            this.cdRef.detectChanges();
        }
    }

    getNodeByElemName(name: string) {
        return LayoutTree.getNodeRecursive(this.Elements, name);
    }

    DeleteNode(elem) {
        LayoutHelper.OnDeleteElement(elem.LayoutElement, elem.templatePath, this.LayoutValue, () => {
            this.DeleteElement.emit(elem.LayoutElement);
        });
    }

    changeContent(elemType) {
        this.SelectedItemValue.ElementType = elemType;
        this.SelectedItemValue.ComponentChanged.next();
    }

    EditNode(elem) {
        elem.Edit = !elem.Edit;
        elem.TempName = elem.LayoutElement.Name + '';
    }

    Accept(elem) {
        if (elem.LayoutElement.Name !== elem.TempName && this.LayoutValue) {
            const nameExisting = LayoutTree.CheckForExistingName(this.LayoutValue, elem.TempName);
            if (nameExisting) {
                const tft = new TranslateFormatText('@@Name \'{0}\' already exists in the layout. Name given anyway?');
                tft.FormatParams.push(elem.TempName);
                MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@Umbenennen'),
                    MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question).then(x => {
                        if (x === MessageBoxResult.Yes) {
                            this.checkWorkflowChange(elem);
                        } else if (x === MessageBoxResult.No) {
                            elem.Edit = !elem.Edit;
                        }
                    });
            } else {
                this.checkWorkflowChange(elem);
            }
        } else {
            elem.Edit = !elem.Edit;
        }
    }

    private checkWorkflowChange(elem) {
        const newName = elem.templatePath + elem.TempName;
        WorkflowNameChangeDialog.CheckWorkflowChange(this.LayoutValue, elem.longName, newName).then(x => {
            if (x !== MessageBoxResult.Abort) {
                elem.LayoutElement.Name = elem.TempName;
                elem.Caption = elem.TempName;
                elem.Edit = !elem.Edit;
                LayoutService.OnLayoutPropertyChanged(elem.LayoutElement.ID, 'Name', elem.LayoutElement.Name);
            }
        });
    }

    Cancel(elem) {
        elem.Edit = !elem.Edit;
    }

    KeyDown(ev, elem) {
        if (ev && ev.key === 'Enter' && elem) {
            this.Accept(elem);
        }
    }

    CopyNode(node) {
        if (ClipboardHelper.Inititalized) {
            let entry = {
                type: node.LayoutElement.IsRoot ? node.LayoutElement.ElementType == 'widget' ? "WidgetCopy" : node.LayoutElement.ElementType =='template' ? "TemplateCopy" :"LayoutCopy" :"LayoutElementCopy",
                content: node.LayoutElement
            }
            ClipboardHelper.CopyToClipboard(entry);
            NotificationHelper.Info("@@CopyToClipboard", "@@ElementCopiedToClipboard");
        } else {
            const parent = MetaHelper.FindParent(this.LayoutValue, node.LayoutElement);
            if (parent) {
                const copy = plainToClass(LayoutElement, JSON.parse(JSON.stringify(node.LayoutElement)));
                const elemNames = {};
                LayoutTree.GetElementNames(this.LayoutValue, elemNames);
                LayoutTree.ChangeCopy(copy, node.LayoutElement.ViewType, elemNames);
                parent.Elements.push(copy);
                this.RefreshNodes();
                this.SelectedItem = copy;
                LayoutService.SelectedItem.next(copy);
                LayoutService.OnLayoutElementAdded({
                    ElementContent: JSON.stringify(copy),
                    Index: parent.Elements.length - 1,
                    ParentID: parent.ID
                });
            }
        }
    }
    DuplicateNode(node) {
        if (ClipboardHelper.Inititalized) {
            let entry = {
                type: "LayoutElementCopy",
                content: node.LayoutElement
            }
            ClipboardHelper.CopyToClipboard(entry);
            NotificationHelper.Info("@@CopyToClipboard", "@@ElementCopiedToClipboard");
        } else {
            const parent = MetaHelper.FindParent(this.LayoutValue, node.LayoutElement);
            if (parent) {
                const copy = plainToClass(LayoutElement, JSON.parse(JSON.stringify(node.LayoutElement)));
                const elemNames = {};
                LayoutTree.GetElementNames(this.LayoutValue, elemNames);
                LayoutTree.ChangeCopy(copy, node.LayoutElement.ViewType, elemNames);
                copy['Parent'] = parent;
                parent.Elements.push(copy);
                this.RefreshNodes();
                this.SelectedItem = copy;
                LayoutService.SelectedItem.next(copy);
                LayoutService.OnLayoutElementAdded({
                    ElementContent: JSON.stringify(copy),
                    Index: parent.Elements.length - 1,
                    ParentID: parent.ID
                });
            }
        }
    }
    PasteNode(node) {
        if (node && node.LayoutElement) {
            let item = ClipboardHelper.ClipboardContent.content;
            const copy = plainToClass(LayoutElement, JSON.parse(JSON.stringify(item)));
            const elemNames = {};
            LayoutTree.GetElementNames(this.LayoutValue, elemNames);
            LayoutTree.ChangeCopy(copy, node.LayoutElement.ViewType, elemNames);
            copy['Parent'] = node.LayoutElement;
            node.LayoutElement.Elements.push(copy);
            this.RefreshNodes();
            this.SelectedItem = copy;
            LayoutService.SelectedItem.next(copy);
            LayoutService.OnLayoutElementAdded({
                ElementContent: JSON.stringify(copy),
                Index: node.LayoutElement.Elements.length - 1,
                ParentID: node.LayoutElement.ID
            });
        }
    }

    showEventTree(ev) {
        EventTreeDialog.ShowDialog(this.LayoutValue);
        ev.stopPropagation();
    }

    onToggleClick(node) {
        if (node && node.HasChildren) {
            node.IsExpanded = !node.IsExpanded;
            this.updateTreeLines();
        }
    }

    updateTreeLines() {
        this.TreeNodes = LayoutTree.FillTreeNodes(this.Elements, []);
    }

    changeExpandState(expanded: boolean) {
        LayoutTree.ExecuteOnAllNodes(this.Elements, (x) => {
            x.IsExpanded = expanded;
        });
        this.updateTreeLines();
        this.cdRef.detectChanges();
    }

    onNodeSelected(ev, node) {
        if (this.MultipleSelectionValue && ev && ev.ctrlKey) {
            if (Array.isArray(this.SelectedNodesValue)) {
                const newList = [...this.SelectedNodesValue];
                const index = this.SelectedNodesValue.indexOf(node);
                if (index < 0) {
                    newList.push(node);
                } else {
                    newList.splice(index, 1);
                }
                this.SelectedNodes = newList;
            } else {
                if (this.SelectedNodeValue) {
                    if (node === this.SelectedNodeValue) {
                        this.SelectedNode = null;
                    } else {
                        this.SelectedNodes = [this.SelectedNodeValue, node];
                    }
                } else {
                    this.SelectedNode = node;
                }
            }
        } else {
            this.SelectedNode = node;
        }
        this.cdRef.detectChanges();
    }

    //#region Dragging
    dragStart(ev) {
        const dragContext = {
            Caption: '',
            DraggedTreeNodes: [],
            DropInfos: {},
            DropNode: null,
            CanDrop: false
        };
        const captions = [];
        this.TreeNodes.forEach(x => {
            if (x.IsSelected && x.Draggable) {
                captions.push(x.Caption);
                dragContext.DraggedTreeNodes.push(x);
            }
        });
        dragContext.Caption = captions.join(', ');
        this.dragState = dragContext;
    }

    dragOver(ev, node) {
        node.DropState = DropState.None;
        const ds = this.dragState;
        if (ds) {
            const oldDropNode = ds.DropNode;
            ds.DropNode = node;
            const nodeChanged = oldDropNode !== node;
            if (oldDropNode && nodeChanged) {
                oldDropNode.DropState = DropState.None;
            }
            let canDrop = false;
            if (ev.currentTarget) {
                if (!ev.currentTarget.DropHeight) {
                    ev.currentTarget.DropHeight = ev.currentTarget.offsetHeight;
                }
                const pos = ev.offsetY / ev.currentTarget.DropHeight;
                let target;
                if (ds.DropNode.Parent) {
                    if (pos <= 0.25) {
                        node.DropState = DropState.Before;
                        target = ds.DropNode.Parent;
                    } else if (pos >= 0.75) {
                        node.DropState = DropState.After;
                        target = ds.DropNode.Parent;
                    } else {
                        node.DropState = DropState.Inner;
                        target = ds.DropNode;
                    }
                } else {
                    node.DropState = DropState.Inner;
                    target = ds.DropNode;
                }
                let dropInfo = ds.DropInfos[node.DropKey];
                if (!dropInfo) {
                    dropInfo = {
                        CanDrop: true,
                        DropInfoText: null
                    };
                    ds.DropInfos[node.DropKey] = dropInfo;
                    if (ds.DraggedTreeNodes.some(x => x === target)) {
                        dropInfo.CanDrop = false;
                        dropInfo.DropInfoText = new TranslateFormatText('@@Drop into itself not possible');
                    } else {
                        if (!(target.LayoutElement.ElementType === 'canvas' || target.LayoutElement.ElementType === 'flex'
                            || target.LayoutElement.ElementType === 'grid' || target.LayoutElement.ElementType === 'bootstrapgrid' || target.LayoutElement.ElementType === 'bootstraprepeat'
                            || target.LayoutElement.ElementType === 'raster' || target.LayoutElement.ElementType === 'repeat')) {
                            dropInfo.CanDrop = false;
                            dropInfo.DropInfoText = new TranslateFormatText('@@Dropping is not possible here');
                        } else {
                            const parentIDs = [target.LayoutElement.ID];
                            let parent = target.Parent;
                            while (parent) {
                                parentIDs.push(parent.LayoutElement.ID);
                                parent = parent.Parent;
                            }
                            if (ds.DraggedTreeNodes.some(dtn => parentIDs.some(pi => dtn.LayoutElement.ID === pi))) {
                                dropInfo.CanDrop = false;
                                dropInfo.DropInfoText = new TranslateFormatText('@@Drop into itself not possible');
                            }
                        }
                    }
                }
                canDrop = dropInfo.CanDrop;
            }
            ds.CanDrop = canDrop;
        }
    }

    onInternalDrop() {
        if (this.dragState) {
            const dropNode = this.dragState.DropNode;
            if (dropNode && dropNode.DropState !== DropState.None) {
                const canDropInfo = this.dragState.DropInfos[dropNode.DropKey];
                if (canDropInfo && canDropInfo.CanDrop === false) {
                    if (canDropInfo.DropInfoText !== null) {
                        MessageBoxHelper.ShowDialog(canDropInfo.DropInfoText, new TranslateFormatText('@@Drop'),
                            MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                    }
                } else {
                    let dropTarget;
                    let dropIndex;
                    if (dropNode.DropState === DropState.Inner) {
                        dropNode.IsExpanded = true;
                        dropNode.HasChildren = true;
                        if (!dropNode.Children) {
                            dropNode.Children = [];
                        }
                        dropTarget = dropNode;
                        dropIndex = dropNode.Children.length;
                    } else if (dropNode.Parent) {
                        dropTarget = dropNode.Parent;
                        dropIndex = dropNode.Parent.Children.indexOf(dropNode);
                        if (dropNode.DropState === DropState.After) {
                            dropIndex++;
                        }
                    }
                    if (dropTarget) {
                        let realDropIndex = dropIndex;
                        const droppedNodes = [];
                        const spliceIndices = [];
                        const checkNodes = [];
                        this.dragState.DraggedTreeNodes.forEach((dragNode, di) => {
                            if (dragNode.Parent) {
                                const origIndex = dragNode.Parent.Children.indexOf(dragNode);
                                if (origIndex >= 0) {
                                    if (dragNode.Parent === dropTarget) {
                                        droppedNodes.push({
                                            Node: dragNode,
                                            DropIndex: di
                                        });
                                        spliceIndices.push(origIndex);
                                        if (origIndex < dropIndex) {
                                            realDropIndex--;
                                        }
                                    } else {
                                        checkNodes.push({
                                            Node: dragNode,
                                            DropIndex: di
                                        });
                                    }
                                }
                            }
                        });
                        if (checkNodes.length > 0) {
                            MetaHelper.FindDataBindingProperties(this.Layout, dropTarget.LayoutElement).then(dropSource => {
                                let table;
                                if (dropSource) {
                                    table = dropSource.Table;
                                }
                                const dbObject = {
                                    List: checkNodes,
                                    Index: 0,
                                    Ask: [],
                                    MoveList: [],
                                    DropSourceTable: table,
                                    DataBindings: {}
                                };
                                this.checkDataBindings(dbObject, () => {

                                    const posChange = new MultiLayoutPositionChangeValue();
                                    posChange.Index = realDropIndex;
                                    posChange.NewParentID = dropTarget.LayoutElement.ID;

                                    if (droppedNodes.length > 0) {
                                        // LayoutTreestruktur anpassen
                                        spliceIndices.sort((a, b) => b - a);
                                        spliceIndices.forEach(x => {
                                            dropTarget.Children.splice(x, 1);
                                        });

                                        // LayoutElementStruktur anpassen
                                        const dNodePosChange = new PositionChangeSourceValue();
                                        dNodePosChange.ParentID = dropTarget.LayoutElement.ID;
                                        droppedNodes.forEach(x => {
                                            dNodePosChange.Elements.push({
                                                ElementID: x.Node.LayoutElement.ID,
                                                DropIndex: x.DropIndex
                                            });
                                            const index = dropTarget.LayoutElement.Elements.indexOf(x.Node.LayoutElement);
                                            dropTarget.LayoutElement.Elements.splice(index, 1);
                                        });
                                        posChange.Elements.push(dNodePosChange);
                                    }
                                    const oldParents = {};
                                    dbObject.MoveList.forEach(moveEntry => {
                                        const index = moveEntry.Node.Parent.Children.indexOf(moveEntry.Node);
                                        if (index > -1) {
                                            const oldParent = moveEntry.Node.Parent;
                                            oldParents[oldParent.LayoutElement.ID] = oldParent;
                                            // LayoutTreestruktur anpassen
                                            oldParent.Children.splice(index, 1);
                                            moveEntry.Node.Parent = dropTarget;
                                            droppedNodes.push(moveEntry);

                                            // LayoutElementStruktur anpassen
                                            const leIndex = oldParent.LayoutElement.Elements.indexOf(moveEntry.Node.LayoutElement);
                                            oldParent.LayoutElement.Elements.splice(leIndex, 1);
                                            let mENodePosChange = posChange.Elements.find(x => x.ParentID === oldParent.LayoutElement.ID);
                                            if (!mENodePosChange) {
                                                mENodePosChange = new PositionChangeSourceValue();
                                                mENodePosChange.ParentID = oldParent.LayoutElement.ID;
                                                posChange.Elements.push(mENodePosChange);
                                            }
                                            mENodePosChange.Elements.push({
                                                ElementID: moveEntry.Node.LayoutElement.ID,
                                                DropIndex: moveEntry.DropIndex
                                            });
                                        }
                                    });

                                    droppedNodes.sort((a, b) => b.DropIndex - a.DropIndex);
                                    const realNodes = [];
                                    const realLayoutElements = [];
                                    droppedNodes.forEach(x => {
                                        realNodes.push(x.Node);
                                        realLayoutElements.push(x.Node.LayoutElement);
                                    });
                                    // LayoutTreestruktur anpassen
                                    dropTarget.Children.splice(realDropIndex, 0, ...realNodes);
                                    // LayoutElementStruktur anpassen
                                    dropTarget.LayoutElement.Elements.splice(realDropIndex, 0, ...realLayoutElements);

                                    this.updateTreeLines();

                                    LayoutService.OnMultiLayoutElementMoved(posChange);
                                    if (dropTarget.LayoutElement.ValuesChanged) {
                                        dropTarget.LayoutElement.ValuesChanged.next();
                                    }
                                    Object.keys(oldParents).forEach(x => {
                                        const le = oldParents[x].LayoutElement;
                                        if (le && le.ValuesChanged) {
                                            le.ValuesChanged.next();
                                        }
                                    });
                                    this.cdRef.detectChanges();
                                });
                            });
                        } else {
                            const posChange = new MultiLayoutPositionChangeValue();
                            posChange.Index = realDropIndex;
                            posChange.NewParentID = dropTarget.LayoutElement.ID;

                            if (droppedNodes.length > 0) {
                                // LayoutTreestruktur anpassen
                                spliceIndices.sort((a, b) => b - a);
                                spliceIndices.forEach(x => {
                                    dropTarget.Children.splice(x, 1);
                                });

                                // LayoutElementStruktur anpassen
                                const dNodePosChange = new PositionChangeSourceValue();
                                dNodePosChange.ParentID = dropTarget.LayoutElement.ID;
                                droppedNodes.forEach(x => {
                                    dNodePosChange.Elements.push({
                                        ElementID: x.Node.LayoutElement.ID,
                                        DropIndex: x.DropIndex
                                    });
                                    const index = dropTarget.LayoutElement.Elements.indexOf(x.Node.LayoutElement);
                                    dropTarget.LayoutElement.Elements.splice(index, 1);
                                });
                                posChange.Elements.push(dNodePosChange);
                            }

                            const realNodes = [];
                            const realLayoutElements = [];
                            droppedNodes.forEach(x => {
                                realNodes.push(x.Node);
                                realLayoutElements.push(x.Node.LayoutElement);
                            });
                            // LayoutTreestruktur anpassen
                            dropTarget.Children.splice(realDropIndex, 0, ...realNodes);
                            // LayoutElementStruktur anpassen
                            dropTarget.LayoutElement.Elements.splice(realDropIndex, 0, ...realLayoutElements);

                            this.updateTreeLines();

                            LayoutService.OnMultiLayoutElementMoved(posChange);
                            if (dropTarget.LayoutElement.ValuesChanged) {
                                dropTarget.LayoutElement.ValuesChanged.next();
                            }
                            this.cdRef.detectChanges();
                        }
                    }
                }
                dropNode.DropState = DropState.None;
            }
            this.dragState = null;
        }
    }

    checkDataBindings(dbObject, finishAction) {
        if (dbObject.Index < dbObject.List.length) {
            const actEntry = dbObject.List[dbObject.Index++];
            const mustReset = dbObject.DataBindings[actEntry.Node.Parent.LayoutElement.ID];
            if (mustReset == null) {
                MetaHelper.FindDataBindingProperties(this.Layout, actEntry.Node.Parent.LayoutElement).then(removedSource => {
                    if (removedSource && removedSource.Table && removedSource.Table != dbObject.DropSourceTable) {
                        dbObject.DataBindings[actEntry.Node.Parent.LayoutElement.ID] = true;
                        dbObject.Ask.push(actEntry);
                    } else {
                        dbObject.DataBindings[actEntry.Node.Parent.LayoutElement.ID] = false;
                        dbObject.MoveList.push(actEntry);
                    }
                    this.checkDataBindings(dbObject, finishAction);
                });
            } else {
                if (mustReset) {
                    dbObject.Ask.push(actEntry);
                } else {
                    dbObject.MoveList.push(actEntry);
                }
                this.checkDataBindings(dbObject, finishAction);
            }
        } else {
            if (dbObject.Ask.length > 0) {
                const names = [];
                dbObject.Ask.forEach(x => {
                    names.push(x.Node.Caption);
                });
                const tft = new TranslateFormatText('@@ResetDataBinding on {0}');
                tft.FormatParams.push(names.join(', '));
                MessageBoxHelper.ShowDialog(tft, new TranslateFormatText('@@ResetDataBindingContent'),
                    MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                        if (x === MessageBoxResult.Yes) {
                            dbObject.MoveList.push(...dbObject.Ask);
                            const mpcv = new MultiPropertyChangeValue();
                            mpcv.Properties.push({
                                PropertyName: 'DataSource',
                                Value: null
                            }, {
                                PropertyName: 'DataSourceID',
                                Value: null
                            }, {
                                PropertyName: 'DataModelID',
                                Value: null
                            });
                            dbObject.Ask.forEach(ask => {
                                LayoutTree.ResetDataBinding(ask.Node.LayoutElement, mpcv.ElementIDs);
                            });
                            LayoutService.OnMultiLayoutPropertyChanged([mpcv]);
                            finishAction();
                        } else {
                            finishAction();
                        }
                    });
            } else {
                finishAction();
            }
        }
    }
    //#endregion

}

export class LayoutTreeNode extends ABaseTreeNode {
    data;
    longName;
    templatePath;
    LayoutElement;
    canEdit = true;
    canCopy = true;
    canPaste = false;
    canDelete = true;
    VariableCount = 0;
    VariableTooltip;
    EventCount = 0;
    EventTooltip;
    IsSelected = false;
    DepthStyle;
    DropStyle;

    private InternalDropState: DropState = DropState.None;
    get DropState(): DropState { return this.InternalDropState; }
    set DropState(val: DropState) {
        this.InternalDropState = val;
        const style = {};
        switch (val) {
            case DropState.After:
                style['border-bottom'] = '3px solid var(--primary-color)';
                break;
            case DropState.Before:
                style['border-top'] = '3px solid var(--primary-color)';
                break;
            case DropState.Inner:
                style['background-color'] = 'var(--primary-color)';
                break;
        }
        this.DropStyle = style;
    }

    private DepthValue = 0;
    get Depth(): number { return this.DepthValue; }
    set Depth(val: number) {
        this.DepthValue = val;
        this.DepthStyle = { width: (val * 40) + 'px' };
    }

    private ParentValue: LayoutTreeNode;
    get Parent(): LayoutTreeNode { return this.ParentValue; }
    set Parent(val: LayoutTreeNode) {
        this.ParentValue = val;
        if (val) {
            this.Depth = val.Depth + 1;
        } else {
            this.Depth = 0;
        }
    }

    get DropKey() { return this.LayoutElement.ID + '_' + this.InternalDropState; }

    constructor(id: number, parent: LayoutTreeNode) {
        super(id);
        this.Parent = parent;
    }
}

export interface ILayoutTreeNodeLoader {
    Layout;
    getNodes(): LayoutTreeNode[];
}

export class TypeNameLoader implements ILayoutTreeNodeLoader {

    private static ChildrenTypes = ['dropdown', 'accordion', 'raster', 'grid', 'bootstrapgrid', 'bootstraprepeat', 'flex', 'canvas', 'repeat'];

    Layout;
    ShowRoot = false;
    TypeNames: string[] = [];

    static GenerateTreeNode(elem: LayoutElement, templateName: string, id: IDKeeper, parent: LayoutTreeNode): LayoutTreeNode {
        const retVal = new LayoutTreeNode(id.NextID, parent);
        retVal.Draggable = true;
        retVal.Caption = elem.Name + ' ';
        retVal.data = elem;
        retVal.templatePath = templateName ? templateName + '.' : '';
        retVal.longName = retVal.templatePath + elem.Name;
        retVal.LayoutElement = elem;
        retVal.IsExpanded = true;
        if (elem.Variables) {
            retVal.VariableCount = elem.Variables.length;
            const names = [];
            elem.Variables.forEach((v) => {
                names.push(v.Name);
            });
            retVal.VariableTooltip = names.join('\n');
        }
        if (elem.Events) {
            const names = [];
            elem.Events.forEach(x => {
                if (x && x.Handlers && x.Handlers.length > 0) {
                    retVal.EventCount += x.Handlers.length;
                    names.push(x.EventID);
                }
            });
            retVal.EventTooltip = names.join('\n');
        }
        return retVal;
    }

    private getTreeLine(elem: LayoutElement, templateName: string, id: IDKeeper, parent: LayoutTreeNode): LayoutTreeNode {
        const retVal = TypeNameLoader.GenerateTreeNode(elem, templateName, id, parent);
        const page = NavigationService.SelectedPage.getValue();
        if (elem.Elements && elem.Elements.length > 0) {
            const childList = [];
            if (elem.ElementType === 'template') {
                if (!elem['LoadByReference']) {
                    if (templateName) {
                        templateName += '.' + (elem.Name || '');
                    } else {
                        templateName = elem.Name || '';
                    }
                    elem.Elements.forEach(child => {
                        if (this.TypeNames.some(x => x === child.ElementType)) {
                            const treeLine = this.getTreeLine(child, templateName, id, retVal);
                            childList.push(treeLine);
                        } else if (TypeNameLoader.ChildrenTypes.some(x => x === child.ElementType)) {
                            const treeLine = this.getTreeLine(child, templateName, id, retVal);
                            if (treeLine.Children && treeLine.Children.length > 0) {
                                childList.push(treeLine);
                            }
                        }
                    });
                }
            } else if (elem.ElementType === 'widget') {
                if (page === 'widget') {
                    if (templateName) {
                        templateName += '.' + (elem.Name || '');
                    } else {
                        templateName = elem.Name || '';
                    }
                    elem.Elements.forEach(child => {
                        if (this.TypeNames.some(x => x === child.ElementType)) {
                            const treeLine = this.getTreeLine(child, templateName, id, retVal);
                            childList.push(treeLine);
                        } else if (TypeNameLoader.ChildrenTypes.some(x => x === child.ElementType)) {
                            const treeLine = this.getTreeLine(child, templateName, id, retVal);
                            if (treeLine.Children && treeLine.Children.length > 0) {
                                childList.push(treeLine);
                            }
                        }
                    });
                }
            } else {
                elem.Elements.forEach(child => {
                    if (this.TypeNames.some(x => x === child.ElementType)) {
                        const treeLine = this.getTreeLine(child, templateName, id, retVal);
                        childList.push(treeLine);
                    } else if (TypeNameLoader.ChildrenTypes.some(x => x === child.ElementType)) {
                        const treeLine = this.getTreeLine(child, templateName, id, retVal);
                        if (treeLine.Children && treeLine.Children.length > 0) {
                            childList.push(treeLine);
                        }
                    }
                });
            }
            if (childList.length > 0) {
                retVal.HasChildren = true;
                retVal.Children = childList;
            }
        }
        return retVal;
    }

    getNodes(): LayoutTreeNode[] {
        const retVal = [];
        const idKeeper = new IDKeeper();
        if (this.Layout) {
            const root = this.getTreeLine(this.Layout, null, idKeeper, null);
            if (this.ShowRoot) {
                retVal.push(root);
            } else if (root.Children) {
                retVal.push(...root.Children);
            }
        }
        return retVal;
    }
}
