import { SettingsRegistryHelper } from './settings.registry';
import { DatadescriptionSettings } from '../../settings/datadescription/datadescription.settings';
import { DynamicTimePointReferenceDialog } from '../../settings/dynamictimepoint/dtp.reference.dialog';
import { DynamicTimePointDetail, DynamicTimePointSettings } from '../../settings/dynamictimepoint/dynamic.timepoint.settings';

export const MAC_SETTINGS = [
  DatadescriptionSettings,
  DynamicTimePointSettings,
  DynamicTimePointDetail,
  DynamicTimePointReferenceDialog
];

export class MACSettingsRegistryHelper {
  static Build() {
    MAC_SETTINGS.forEach(function (entry) {
      if (typeof entry['GetSettingsEntry'] === 'function') {
        SettingsRegistryHelper.ExternalRegistry.push(entry);
      }
    });
  }
}
