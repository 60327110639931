import { ValueAndType } from "../basic/formulaEditor.model";
import { CompareType } from "../enums/comparetype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { ComparerFormula } from "./iformula";

export class GreaterEqual extends ComparerFormula {
    static StaticID: string = "a7c9595c-f901-4d24-a779-8a3106e99eea";

    constructor() {
        super();
        this._ID = GreaterEqual.StaticID;
        this._Name = "Groesser Gleich";
    }

    OrderID(): number {
        return 2;
    }
    CompareType(): CompareType {
        return CompareType.GreaterEqual;
    }
    OperationValue(): string {
        return '>=';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal: ValueAndType = {
            Type: ValueType.Bool,
            Value: false
        };
        if (args && args.length >= 2 && args[0] && args[1] && args[0].Type != ValueType.Null && args[1].Type != ValueType.Null) {
            if (args[0].Type == ValueType.String || args[1].Type == ValueType.String) {
                retVal.Value = String(args[0].Value).localeCompare(String(args[1].Value)) >= 0;
            } else if (args[0].Type == ValueType.Datetime || args[1].Type == ValueType.Datetime) {
                var now = new Date();
                var d1, d2;
                if (args[0].Value instanceof Date && !isNaN(args[0].Value.getTime())) {
                    d1 = args[0].Value;
                } else {
                    d1 = now;
                }
                if (args[1].Value instanceof Date && !isNaN(args[1].Value.getTime())) {
                    d2 = args[1].Value;
                } else {
                    d2 = now;
                }
                retVal.Value = d1 >= d2;
            } else if (args[0].Type == ValueType.Double || args[1].Type == ValueType.Double || args[0].Type == ValueType.Long || args[1].Type == ValueType.Long) {
                retVal.Value = Number(args[0].Value) >= Number(args[1].Value);
            }
        }
        return retVal;
    }
}