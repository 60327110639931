import { Component, Directive, EventEmitter, Input, Output } from '@angular/core';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { ReportTableSettings } from '../../../models/reportobjects/reporttablesettings.model';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'mactable-property-menu-tab',
    templateUrl: './mactable.menu.tab.html'
})
export class MacTablePropertyMenuTab extends APropertyTab {
    IsMultidimensional = false;
    Layout;

    static CheckVisible(): boolean {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'tablereportObject') {
            return true;
        }
        return false;
    }

    ngOnInit() {
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = LayoutHelper.GetActiveResolution(layout);
        }));
        super.ngOnInit();
    }

    onItemSet() {
        let multi = false;
        if (this.SelectedItem) {
            if (!this.SelectedItem.FormatTasks) {
                this.SelectedItem.FormatTasks = [];
            }
            if (!this.SelectedItem.DataTasks) {
                this.SelectedItem.DataTasks = [];
            }
            if (this.SelectedItem.Query && this.Layout && this.Layout.Datadescriptions) {
                const dataDesc = this.Layout.Datadescriptions.find(x => x.ID === this.SelectedItem.Query);
                if (dataDesc) {
                    multi = !dataDesc.IsRelational;
                }
            }
        }
        this.IsMultidimensional = multi;
    }
}

@Directive()
export abstract class ATableMenuTab {
    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (this.SelectedItemValue && this.SelectedItemValue.TableSettings == null) {
            this.SelectedItemValue.TableSettings = new ReportTableSettings();
            if (this.SelectedItemValue.ValuesChanged) {
                this.SelectedItem.ValuesChanged.next('TableSettings');
            }
        }
        this.OnItemSet();
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    //#region IsMultidimensional
    IsMultidimensionalValue;

    @Input()
    get IsMultidimensional() {
        return this.IsMultidimensionalValue;
    }
    set IsMultidimensional(val) {
        this.IsMultidimensionalValue = val;
        this.IsMultidimensionalChange.emit(this.IsMultidimensionalValue);
    }
    @Output() IsMultidimensionalChange = new EventEmitter<any>();
    //#endregion

    SettingsChanged(prop: string) {
        const sel = this.SelectedItemValue;
        if (sel && sel.ValuesChanged) {
            sel.ValuesChanged.next();
            LayoutService.OnLayoutPropertyChanged(sel.ID, 'TableSettings.' + prop, sel.TableSettings[prop]);
        }
    }

    OnItemSet() {
    }
}
