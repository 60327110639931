import { Component } from '@angular/core';
import { FormulaEditorDialog } from '../../../components/common/formulaEditor/formulaEditor.control';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { AdvancedFormulaParser } from '../../../helpers/formula.parser';
import { VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { VariableDisplay } from '../../../models/enums/variabledisplay.enum';

@Component({
    selector: 'container-businessmodel-line-dialog',
    templateUrl: './container.businessmodel.line.dialog.html'
})
export class ContainerBusinessModelLineDialog {
    Model;
    OriginalLine;
    Line;
    VariableInfo: VariableInfo;
    FormulaText;

    static ShowDialog(model, line, handler) {
        BaseDialog.ShowDialog({
            InitArgs: {
                Model: model,
                Line: line
            },
            ContentType: ContainerBusinessModelLineDialog,
            Buttons: DialogButton.OkCancel,
            Handler: (r) => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            Title: '@@Zeile bearbeiten'
        });
    }

    static GetVariableNodeInfo(line) {
        const variable = new VariablesNodeInformation();
        variable.AliasKey = '[' + line.Key + ']';
        if (line.Name) {
            variable.Name = line.Name;
        } else {
            variable.Name = line.Key;
        }
        variable.VariableID = line.Key;
        variable['ParentKey'] = line.ParentKey;
        return variable;
    }

    private static parentIsKey(key, searchKey, lineDict): boolean {
        if (key) {
            if (key === searchKey) {
                return true;
            }
            const entry = lineDict[key];
            if (entry) {
                return ContainerBusinessModelLineDialog.parentIsKey(entry.ParentKey, searchKey, lineDict);
            }
        }
        return false;
    }

    Initialize(args) {
        if (args) {
            if (args.Model) {
                this.Model = args.Model;
            }
            if (args.Line) {
                this.OriginalLine = args.Line;
                this.Line = JSON.parse(JSON.stringify(args.Line));
            }
            if (this.Model && this.Line && !this.Line.IsRestRow && (!this.Line.IncludeKeys || this.Line.IncludeKeys.length === 0)
                && (!this.Line.KeyRanges || this.Line.KeyRanges.length === 0)) {
                const keyDict = {};
                const variables = [];
                if (!this.Model.Lines.some(x => {
                    keyDict[x.Key] = x;
                    if (x !== this.OriginalLine) {
                        if (this.Line.Key && x.ParentKey === this.Line.Key) {
                            // Linie hat Kinder
                            return true;
                        }
                        const variable = ContainerBusinessModelLineDialog.GetVariableNodeInfo(x);
                        variables.push(variable);
                    }
                    return false;
                })) {
                    this.VariableInfo = new VariableInfo(variables.filter(x =>
                        !ContainerBusinessModelLineDialog.parentIsKey(x.ParentKey, this.Line.Key, keyDict)));
                    this.FormulaText = this.VariableInfo.getFormulaText(this.Line.Formula);
                }
            }
        }
    }

    GetDialogResult() {
        return this.Line;
    }

    OnDialogButtonClickAction(button: DialogButton) {
        if (button === DialogButton.Ok) {
            if (this.Line) {
                if (!this.Line.Key) {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie einen Key'),
                        new TranslateFormatText('@@Fehlender Key'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    return false;
                }
                if (this.Model && this.Model.Lines && this.Model.Lines.some(l => l !== this.OriginalLine && l.Key === this.Line.Key)) {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Bitte vergeben Sie einen anderen Key'),
                        new TranslateFormatText('@@Key bereits vorhanden'), MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    return false;
                }
            }
        }
        return true;
    }

    showFormulaDialog() {
        const args = {
            Variables: this.VariableInfo.Variables,
            InputMode: FormulaInputMode.AliasKey,
            FormulaGroup: FormulaGroupEnum.BasicOperations,
            Formula: this.Line.Formula
        };
        FormulaEditorDialog.ShowDialog(args, result => {
            if (result) {
                this.Line.Formula = result.Formula;
                if (result.Formula) {
                    this.Line.Unary = 2; // Tilde
                }
                this.FormulaText = this.VariableInfo.getFormulaText(result.Formula);
            }
            return true;
        });
    }
}

export class VariableInfo {
    private _VariableDict = new Map<string, string>();
    private _Parser = new AdvancedFormulaParser();

    constructor(public Variables: VariablesNodeInformation[]) {
        if (this.Variables) {
            this.Variables.forEach(x => this._VariableDict.set(x.VariableID, x.Name));
            this._Parser.SetVariables(this.Variables, FormulaInputMode.AliasKey);
        }
    }

    getFormulaText(formula: string): string {
        if (formula) {
            try {
                const fni = this._Parser.Parse(formula);
                return fni.ToString(this._VariableDict, VariableDisplay.VariableID);
            } catch { }
        }
        return '';
    }
}
