import { ConditionOperator } from '../../enums/conditionoperator.enum';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { MultiResult, MultiNode, AxisNode } from '../../datadescription/multi/multiresult.model';
import { AxisType } from '../../enums/query.enum';
import { ITaskExecuter } from '../atask.model';
import { TaskHelper } from '../task.helper';
import { add } from 'mathjs';

export class FilterTaskExecuter implements ITaskExecuter {

    MeasureUniqueID: number;
    LevelUniqueID: number;
    OppositeLevelUniqueID = -1;
    OneConditionBreaks = false;
    ConditionOperator: ConditionOperator.Equal;
    Value1: number;
    Value2: number;

    Result: MultiResult;
    DataDescription: DataDescription;

    private static ExecuteOnAllNodes(nodes: AxisNode[], checkAction: (n: AxisNode) => boolean): boolean {
        if (nodes) {
            return nodes.some(n => {
                if (checkAction(n)) {
                    return true;
                }
                return FilterTaskExecuter.ExecuteOnAllNodes(n.Children, checkAction);
            });
        }
        return false;
    }

    async Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (typeof settings.OneConditionBreaks === 'boolean') {
                this.OneConditionBreaks = settings.OneConditionBreaks;
            }
            if (typeof settings.ConditionOperator === 'number') {
                this.ConditionOperator = settings.ConditionOperator;
            }
            if (typeof settings.OppositeLevelUniqueID === 'number') {
                this.OppositeLevelUniqueID = settings.OppositeLevelUniqueID;
            }
            if (typeof settings.Value1 === 'number') {
                this.Value1 = settings.Value1;
            }
            if (typeof settings.Value2 === 'number') {
                this.Value2 = settings.Value2;
            }
        }
    }

    Execute() {
        if (this.Result && this.DataDescription && typeof this.MeasureUniqueID === 'number' && typeof this.LevelUniqueID === 'number') {
            const measure = this.Result.Measures.Nodes.find(m => m.UniqueID === this.MeasureUniqueID);
            if (measure) {
                let oppositeAxis, cellAction;
                const checkNodes = [];
                if (this.LevelUniqueID === -1) { // Über alle X
                    cellAction = MultiResultHelper.GetValueX;
                    oppositeAxis = this.Result.YAxis;
                    let rootNodes = [];
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.X_Axis) { // Area auf X suchen
                        const noLevelIndex = {
                            Index: 0
                        };
                        this.DataDescription.XLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                rootNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.XAxis.Nodes, noLevelIndex).Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    } else {
                        rootNodes = this.Result.XAxis.Nodes;
                    }
                    MultiResultHelper.ExecuteOnAllNodesInternal(rootNodes, (n) => { checkNodes.push(n); });
                } else if (this.LevelUniqueID === -2) { // Über alle Y
                    cellAction = MultiResultHelper.GetValueY;
                    oppositeAxis = this.Result.XAxis;
                    let rootNodes = [];
                    if (this.DataDescription.ShowMeasureOnAxis === AxisType.Y_Axis) { // Area auf Y suchen
                        const noLevelIndex = {
                            Index: 0
                        };
                        this.DataDescription.YLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) {
                                rootNodes = MultiResultHelper.GetNodesFromArea(area, this.Result.YAxis.Nodes, noLevelIndex).Nodes;
                                return true;
                            } else {
                                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.length > 0)) {
                                } else {
                                    noLevelIndex.Index++;
                                }
                            }
                            return false;
                        });
                    } else {
                        rootNodes = this.Result.YAxis.Nodes;
                    }
                    MultiResultHelper.ExecuteOnAllNodesInternal(rootNodes, (n) => { checkNodes.push(n); });
                } else if (this.LevelUniqueID > -1) {
                    if (!this.DataDescription.XLevelNodes.Areas.some(a => {
                        if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                            if ((a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) ||
                                this.DataDescription.YLevelNodes.Areas.some(yA => yA.Measures &&
                                    yA.Measures.some(m => m.UniqueID === this.MeasureUniqueID))) {
                                oppositeAxis = this.Result.YAxis;
                                cellAction = MultiResultHelper.GetValueX;
                                const sumNodes = MultiResultHelper.GetNodeSections(this.Result.XAxis, this.LevelUniqueID);
                                sumNodes.forEach(sn => {
                                    checkNodes.push(...sn);
                                });
                            }
                            return true;
                        }
                        return false;
                    })) {
                        this.DataDescription.YLevelNodes.Areas.some(a => {
                            if (a.Tuples && a.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === this.LevelUniqueID))) {
                                if ((a.Measures && a.Measures.some(m => m.UniqueID === this.MeasureUniqueID)) ||
                                    this.DataDescription.XLevelNodes.Areas.some(xA => xA.Measures &&
                                        xA.Measures.some(m => m.UniqueID === this.MeasureUniqueID))) {
                                    oppositeAxis = this.Result.XAxis;
                                    cellAction = MultiResultHelper.GetValueY;
                                    const sumNodes = MultiResultHelper.GetNodeSections(this.Result.YAxis, this.LevelUniqueID);
                                    sumNodes.forEach(sn => {
                                        checkNodes.push(...sn);
                                    });
                                }
                                return true;
                            }
                            return false;
                        });
                    }
                }
                if (oppositeAxis && cellAction && checkNodes.length > 0) {
                    if (this.OppositeLevelUniqueID === -1) { // All -> RootNodes aufsummieren
                        checkNodes.forEach(lNode => {
                            let actVal = null;
                            oppositeAxis.Nodes.forEach(oppNode => {
                                const cV = cellAction(this.Result, lNode, oppNode, measure, false);
                                if (cV && cV.InternalValue != null) {
                                    if (actVal == null) {
                                        actVal = cV.InternalValue;
                                    } else {
                                        actVal = add(actVal, cV.InternalValue);
                                    }
                                }
                            });
                            const cellVal = new MultiNode({
                                InternalValue: actVal
                            });
                            const fulfilled = TaskHelper.CheckCondition(cellVal, this.ConditionOperator, this.Value1, this.Value2);
                            if (!fulfilled) {
                                lNode.Visible = false;
                            }
                        });
                    } else if (this.OppositeLevelUniqueID === -2) { // Alle gegenüberliegenden
                        checkNodes.forEach(lNode => {
                            let hideNode = true;
                            if (this.OneConditionBreaks) {
                                FilterTaskExecuter.ExecuteOnAllNodes(oppositeAxis.Nodes, (oppNode) => {
                                    const cellVal = cellAction(this.Result, lNode, oppNode, measure, false);
                                    const fulFilled = TaskHelper.CheckCondition(cellVal, this.ConditionOperator, this.Value1, this.Value2);
                                    if (fulFilled) {
                                        hideNode = false;
                                        return false;
                                    } else {
                                        hideNode = true;
                                        return true;
                                    }
                                });
                            } else {
                                FilterTaskExecuter.ExecuteOnAllNodes(oppositeAxis.Nodes, (oppNode) => {
                                    const cellVal = cellAction(this.Result, lNode, oppNode, measure, false);
                                    const fulFilled = TaskHelper.CheckCondition(cellVal, this.ConditionOperator, this.Value1, this.Value2);
                                    if (fulFilled) {
                                        hideNode = false;
                                        return true;
                                    }
                                    return false;
                                });
                            }
                            if (hideNode) {
                                lNode.Visible = false;
                            }
                        });
                    } else if (this.OppositeLevelUniqueID > -1) {
                        const oppSections = MultiResultHelper.GetNodeSections(this.Result.YAxis, this.OppositeLevelUniqueID);
                        checkNodes.forEach(lNode => {
                            let hideNode = true;
                            if (this.OneConditionBreaks) {
                                oppSections.some(os => {
                                    return os.some(oppNode => {
                                        const cellVal = cellAction(this.Result, lNode, oppNode, measure, false);
                                        const ff = TaskHelper.CheckCondition(cellVal, this.ConditionOperator, this.Value1, this.Value2);
                                        if (ff) {
                                            hideNode = false;
                                            return false;
                                        } else {
                                            hideNode = true;
                                            return true;
                                        }
                                    });
                                });
                            } else {
                                oppSections.some(os => {
                                    return os.some(oppNode => {
                                        const cellVal = cellAction(this.Result, lNode, oppNode, measure, false);
                                        const ff = TaskHelper.CheckCondition(cellVal, this.ConditionOperator, this.Value1, this.Value2);
                                        if (ff) {
                                            hideNode = false;
                                            return true;
                                        }
                                        return false;
                                    });
                                });
                            }
                            if (hideNode) {
                                lNode.Visible = false;
                            }
                        });
                    }
                }
            }
        }
    }
}
