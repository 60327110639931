import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'macplanning-references-menu-tab',
    templateUrl: './references.menu.tab.html'
})
export class MacPlanningReferencesMenuTab {

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        if (!this.SelectedItemValue.References) {
            this.SelectedItemValue.References = [];
        }
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    SettingsChanged() {
        this.SelectedItem.ValuesChanged.next();
    }
}
