import { PropertyMapper } from '../../../helpers/property.mapper';
import { ContainerClassObject } from '../../../models/ContainerClassObject';

export class ContainerRest extends ContainerClassObject {
    public RestID: string;
    public IsDefaultResponseObject: boolean;
    public IsTemplate = false;
    public ObjectForTemplate: string;
    constructor(value: ContainerRest = undefined) {
        super();
        if (value) {
            PropertyMapper.MapProperty(value, this);
        }
    }
}