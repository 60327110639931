import { Component, OnInit } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { NonClickableVariablesNodeInformation, VariablesNodeInformation } from '../../../models/basic/formulaEditor.model';
import { FormulaGroupEnum } from '../../../models/enums/formulagroupenum.enum';
import { FormulaInputMode } from '../../../models/enums/formulainputmode.enum';
import { CalculateTaskExecuter } from '../../../models/tasks/datatasks/calculate.model';
import { StandardRequestBase } from '../../../services/request-base';
import { AMeasureTaskSettingsHelper, ATaskPanel } from '../a.task.panel';

@Component({
    selector: 'calculate-task-panel',
    templateUrl: './calculate.task.panel.html'
})
export class CalculateTaskPanel extends ATaskPanel implements OnInit {

    Variables;
    Operations = [];
    InputMode: FormulaInputMode = FormulaInputMode.AliasKey;

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = CalculateTaskExecuter.TaskID;
        reg.Caption = '@@Berechneter Wert';
        reg.SettingsControl = CalculateTaskPanel;
        reg.SettingsHelper = new CalculateSettingsHelper();
        reg.Executer = CalculateTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

    static GetNewTask(measureID) {
        return {
            TaskType: CalculateTaskExecuter.TaskID,
            IsActive: true,
            MeasureUniqueID: measureID,
            IgnoreNotVisibleMeasures: false
        };
    }

    constructor(private service: StandardRequestBase) {
        super();
    }

    ngOnInit(): void {
        const query = {
            Group: FormulaGroupEnum.All
        };
        this.service.executePost(query, 'api/formula', 'GetFormulaInfos').subscribe(result => this.Operations = result);
        if (this.SettingsValue) {
            CalculateTaskExecuter.GetMeasuresAndLevelsForCalcTask(this.DataDescriptionValue,
                this.SettingsValue.MeasureUniqueID).then(result => {
                if (result) {
                    const variables = [];

                    const measureNode = new NonClickableVariablesNodeInformation();
                    measureNode.Name = '@@Werte';
                    measureNode.Expand = true;
                    variables.push(measureNode);

                    result.Measures.forEach(m => {
                        const vni = new VariablesNodeInformation();
                        if (m.AreaCaption) {
                            vni.Name = m.AreaCaption + ' / ' + m.Measure.toString();
                        } else {
                            vni.Name = m.Measure.toString();
                        }
                        vni.VariableID = vni.AliasKey = 'M' + m.Measure.UniqueID;
                        measureNode.Children.push(vni);
                    });

                    const levelNode = new NonClickableVariablesNodeInformation();
                    levelNode.Name = '@@Level';
                    variables.push(levelNode);

                    result.Levels.forEach(l => {
                        const vni = new VariablesNodeInformation();
                        vni.Name = l.toString();
                        vni.VariableID = vni.AliasKey = 'L' + l.UniqueID;
                        levelNode.Children.push(vni);
                    });

                    this.Variables = variables;
                }
            });
        }
    }
}

export class CalculateSettingsHelper extends AMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption ;
        }
        return TranslateHelper.TranslatorInstance.instant('@@Berechneter Wert');
    }
}
