import { divide, equal, larger, largerEq, multiply, smaller, smallerEq, unequal } from 'mathjs';
import { TranslateHelper } from '../../helpers/injector.helper';
import { MultiResultHelper } from '../../helpers/multiresult.helper';
import { AxisNode, MultiNode, MultiResult } from '../datadescription/multi/multiresult.model';
import { ConditionOperator } from '../enums/conditionoperator.enum';
import { GradientType } from '../enums/gradienttype.enum';
import { CellStyle } from '../styling/cell.style';
import { DivisorTypes, NumberFormat } from '../styling/number.format.model';
import { ConditionObject } from './conditional.model';

export class TaskHelper {
    static CheckConditionObject(condition: ConditionObject, result: MultiResult,
        xNode: AxisNode, yNode: AxisNode, mNode: AxisNode): boolean {
        if (condition) {
            if (typeof condition.LevelUniqueID === 'number' && condition.LevelUniqueID > -1) {
                if (xNode.UniqueID !== condition.LevelUniqueID && yNode.UniqueID !== condition.LevelUniqueID) {
                    return false;
                }
                if (typeof condition.LevelMember === 'string' && condition.LevelMember) {
                    if (condition.LevelMember !== xNode.MemberId && condition.LevelMember !== yNode.MemberId) {
                        return false;
                    }
                }
            }
            let cell;
            if (typeof condition.MeasureUniqueID === 'number' && condition.MeasureUniqueID > -1 &&
                condition.MeasureUniqueID !== mNode.UniqueID) {
                const newMNode = result.Measures.Nodes.find(x => x.UniqueID === condition.MeasureUniqueID);
                if (newMNode) {
                    cell = MultiResultHelper.GetCellOrNull(result, xNode, yNode, newMNode);
                } else {
                    return false;
                }
            } else {
                cell = MultiResultHelper.GetCellOrNull(result, xNode, yNode, mNode);
            }
            return TaskHelper.CheckCondition(cell, condition.ConditionOperator, condition.Value1, condition.Value2);
        }
        return false;
    }

    static CheckCondition(fc: MultiNode, condition: ConditionOperator, conditonValue1: number, conditionValue2: number): boolean {
        // Check if the flat cell is null and corresponds ConditionOperator.IsNull
        if (fc == null || fc.InternalValue == null) {
            return condition === ConditionOperator.IsNull;
        }

        if (condition === ConditionOperator.NotNull) {
            return true;
        }

        // If the cell is not null, the standard check will be performed

        if (typeof fc.InternalValue !== 'number') {
            return false;
        }

        switch (condition) {
            case ConditionOperator.Equal:
                return equal(fc.InternalValue, conditonValue1) ? true: false;
            case ConditionOperator.NonEqual:
                return unequal(fc.InternalValue, conditonValue1) ? true : false;
            case ConditionOperator.Between:
                return (largerEq(fc.InternalValue, conditonValue1) && smallerEq(fc.InternalValue, conditionValue2)) ? true : false;
            case ConditionOperator.NotBetween:
                return (smaller(fc.InternalValue, conditonValue1) || larger(fc.InternalValue, conditionValue2)) ? true : false;
            case ConditionOperator.Less:
                return smaller(fc.InternalValue, conditonValue1) ? true : false;
            case ConditionOperator.Greater:
                return larger(fc.InternalValue, conditonValue1) ? true : false;
            case ConditionOperator.GreatorOrEqual:
                return largerEq(fc.InternalValue, conditonValue1) ? true : false;
            case ConditionOperator.LessOrEqual:
                return smallerEq(fc.InternalValue, conditonValue1) ? true : false;
        }
        return false;
    }

    static RequiresCell(style: CellStyle): boolean {
        if (style) {
            if (style.BackgroundColor && style.BackgroundColor.Type !== GradientType.None) {
                return true;
            }
            if (style.Border && [style.Border.TopBorder, style.Border.RightBorder, style.Border.BottomBorder,
            style.Border.LeftBorder].some(b => b && b.Thickness && b.Thickness.Value > 0)) {
                return true;
            }
        }
        return false;
    }

    static GetFormatedValue(value, format: NumberFormat): any {
        if (typeof value === 'number' && format) {
            let suffix = '';
            switch (format.Suffix) {
                case DivisorTypes.Percent:
                    value = multiply(value, 100);
                    suffix = '%';
                    break;
                case DivisorTypes.Thousand:
                    value = divide(value, 1000);
                    suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.Thousand');
                    break;
                case DivisorTypes.HundredThousand:
                    value = divide(value, 100000);
                    suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.HundredThousand');
                    break;
                case DivisorTypes.Million:
                    value = divide(value, 1000000);
                    suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.Million');
                    break;
                case DivisorTypes.None:
                    if (typeof format.Divisor === 'number' && format.Divisor !== 0) {
                        value = divide(value, format.Divisor);
                    }
                    break;
            }
            if (format.ChangeSign) {
                value = multiply(value, -1);
            }
            const options = {
                useGrouping: format.ShowNumberGroupSeperator,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };
            if (format.DecimalDigits > 0) {
                options.minimumFractionDigits = format.DecimalDigits;
                options.maximumFractionDigits = format.DecimalDigits;
            }
            let retVal = '';
            if (format.CustomPraefix) {
                retVal += format.CustomPraefix;
                if (!format.WithoutSpaces) {
                    retVal += ' ';
                }
            }
            retVal += value.toLocaleString(undefined, options);
            if (suffix) {
                if (!format.WithoutSpaces) {
                    retVal += ' ';
                }
                retVal += suffix;
            }
            if (format.CustomSuffix) {
                if (!format.WithoutSpaces) {
                    retVal += ' ';
                }
                retVal += format.CustomSuffix;
            }
            return retVal;
        }
        return value;
    }
    
    static GetFormatedCellValue(cell: MultiNode, format: NumberFormat): any {
        if (cell) {
            let cellVal = cell.InternalValue;
            if (typeof cellVal === 'number' && format) {
                let suffix = '';
                switch (format.Suffix) {
                    case DivisorTypes.Percent:
                        cellVal = multiply(cellVal, 100);
                        suffix = '%';
                        break;
                    case DivisorTypes.Thousand:
                        cellVal = divide(cellVal, 1000);
                        suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.Thousand');
                        break;
                    case DivisorTypes.HundredThousand:
                        cellVal = divide(cellVal, 100000);
                        suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.HundredThousand');
                        break;
                    case DivisorTypes.Million:
                        cellVal = divide(cellVal, 1000000);
                        suffix = TranslateHelper.TranslatorInstance.instant('@@DivisorTypes.Million');
                        break;
                    case DivisorTypes.None:
                        if (typeof format.Divisor === 'number' && format.Divisor !== 0) {
                            cellVal = divide(cellVal, format.Divisor);
                        }
                        break;
                }
                if (format.ChangeSign) {
                    cellVal = multiply(cellVal, -1);
                }
                const options = {
                    useGrouping: format.ShowNumberGroupSeperator,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                };
                if (format.DecimalDigits > 0) {
                    options.minimumFractionDigits = format.DecimalDigits;
                    options.maximumFractionDigits = format.DecimalDigits;
                }
                let retVal = '';
                if (format.CustomPraefix) {
                    retVal += format.CustomPraefix;
                    if (!format.WithoutSpaces) {
                        retVal += ' ';
                    }
                }
                retVal += cellVal.toLocaleString(undefined, options);
                if (suffix) {
                    if (!format.WithoutSpaces) {
                        retVal += ' ';
                    }
                    retVal += suffix;
                }
                if (format.CustomSuffix) {
                    if (!format.WithoutSpaces) {
                        retVal += ' ';
                    }
                    retVal += format.CustomSuffix;
                }
                return retVal;
            }
            return cellVal;
        }
        return null;
    }

    static IsTableObject(reportObject) {
        return reportObject && reportObject.LayoutElement && reportObject.LayoutElement.ElementType === 'tablereportObject';
    }
    static IsPlanningObject(reportObject) {
        return reportObject && reportObject.LayoutElement && reportObject.LayoutElement.ElementType === 'planningreportObject';
    }
    static IsChartObject(reportObject) {
        return reportObject && reportObject.LayoutElement && reportObject.LayoutElement.ElementType === 'chartreportObject';
    }
}
