import { Type } from 'class-transformer';
import { ACTIONS_REGISTRY } from '../../registries/actions/actions.registry';
import { ActionSize } from '../enums/actionsize.enum';
import { LayoutElement } from '../layoutelement.model';


// @dynamic
export class Action extends LayoutElement {
    get Name() {
        return this._Name;
    }
    set Name(val) {
        this._Name = val;
        this.Component = ACTIONS_REGISTRY.get(val);
    }
    Component: any;
    Label: string;
    Icon: string;
    Tooltip: string;
    ActionSize: ActionSize = ActionSize.Small;

    KeepOpen: boolean;
    IsContextMenu: boolean;

    @Type(() => Action)
    Elements: LayoutElement[] = [];

    toJSON() {
        const result = super.toJSON();
        delete result.Component;
        return result;
    }
}
