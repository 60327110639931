import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {CustomCSSVariable} from '../models/style/customcssvar.model';
import {RequestBase} from './request-base';
import {RequestOptions} from "../models/rest/requestoptions";

@Injectable()
export class MobileConfigService extends RequestBase {

    static UpdateSettings: Subject<any> = new Subject();
    BasePath = 'api/meta';
    SideNavPath = 'api/sidenav';
    static protocolConfig = {
        http: 'http://',
    };


    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetMobileApp(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/mobileapp', this.options)
    }

    GetThemesTemplate(id): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/themes' + id, this.options);
    }

    SaveAppInfo(formData): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers,
        };
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/mobileapp', formData, httpOptions);
    }

    UpdateAppInfo(formData): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        const httpOptions = {
            headers: headers,
        };
        return this.http.put<any>(this.API_BASE_URL + this.BasePath + '/mobileapp', formData, httpOptions);
    }

    GenerateAppBuild(obj = {}): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/mobileapp/build', obj, this.options);
    }

    UploadImage(obj): Observable<any> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'multipart/form-data');
        const formData = new FormData();
        formData.append('file', obj.file);
        formData.append('PathPrefix', obj.PathPrefix);

        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/themes/images', formData, {headers});
    }

    ApplyhemesTemplate(obj): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.SideNavPath + '/ApplyTheme', obj, this.options);
    }

    DeleteThemesTemplate(id): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/themes/byId/' + id, this.options);
    }

    // AddOrUpdateTheme(data: any): Observable<any> {
    //     return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/AddOrUpdateERD', data, this.options);
    // }
}
