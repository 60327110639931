import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { RolesService } from '../../../services/roles.service';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'enumsec-settings',
    templateUrl: './enumsec.settings.html',
    styleUrls: ['./enumsec.settings.css']
})
export class EnumSecuritySettings implements OnInit {
    InitArgs;
    nestedTreeControl = new NestedTreeControl<TreeNode>(node => node.children);
    nestedDataSource = new MatTreeNestedDataSource<TreeNode>();
    SecurityTreeData: SecurityTreeData;
    HasChanges = false;
    CanSave = false;

    private static CheckTreeNode(node) {
        if (node.children && node.children.length > 0) {
            const firstChild = node.children[0];
            firstChild.Parent = node;
            EnumSecuritySettings.CheckTreeNode(firstChild);
            if (typeof firstChild.Status === 'boolean') {
                node.AnyChildSelected = true;
                if (node.children.length > 1) {
                    const stateCheck = {
                        Status: firstChild.Status,
                        AllSameState: true
                    };
                    for (let i = 1; i < node.children.length; i++) {
                        const child = node.children[i];
                        child.Parent = node;
                        EnumSecuritySettings.CheckTreeNode(child);
                        if (stateCheck.AllSameState && stateCheck.Status !== child.Status) {
                            stateCheck.AllSameState = false;
                        }
                        if (typeof child.Status === 'boolean') {
                            node.AnyChildSelected = true;
                        }
                    }
                    if (stateCheck.AllSameState) {
                        node.Status = stateCheck.Status;
                    }
                } else {
                    node.Status = firstChild.Status;
                }
            } else {
                node.Status = firstChild.Status;
                if (firstChild.AnyChildSelected) {
                    node.AnyChildSelected = true;
                }
                for (let i = 1; i < node.children.length; i++) {
                    const child = node.children[i];
                    child.Parent = node;
                    EnumSecuritySettings.CheckTreeNode(child);
                    if (typeof child.Status === 'boolean' || child.AnyChildSelected) {
                        node.AnyChildSelected = true;
                    }
                }
            }
        }
    }

    private static CheckParentNodes(node) {
        if (node.Parent) {
            if (typeof node.Status === 'boolean') {
                node.Parent.AnyChildSelected = true;
                if (node.Parent.children.some(x => x.Status !== node.Status)) {
                    node.Parent.Status = null;
                } else {
                    node.Parent.Status = node.Status;
                }
            } else {
                node.Parent.Status = null;
                node.Parent.AnyChildSelected = node.Parent.children.some(x => typeof x.Status === 'boolean' || x.AnyChildSelected);
            }
            EnumSecuritySettings.CheckParentNodes(node.Parent);
        }
    }

    constructor(private rolesService: RolesService) {
    }

    ngOnInit(): void {
        if (this.InitArgs) {
            this.CanSave = !RolesService.IsSystemRole(this.InitArgs.Id);
            this.rolesService.GetSecurityEnumTreeNodes(this.InitArgs.Id).subscribe((data) => {
                if (data) {
                    this.SecurityTreeData = data;
                    data.TreeNodes.forEach(node => {
                        EnumSecuritySettings.CheckTreeNode(node);
                    });
                    this.nestedDataSource.data = this.SecurityTreeData.TreeNodes;
                }
            });
        }
    }

    hasChild = (_: number, nodeData: TreeNode) => !!nodeData.children && nodeData.children.length > 0;

    CheckBoxChange(node) {
        const res = this.GetCheckBoxValue(node);
        this.LoopChildren(node, res);
        EnumSecuritySettings.CheckParentNodes(node);
    }

    LoopChildren(node, value) {
        this.SetCheckBoxValue(node, value);
        if (node.children && node.children.length > 0) {
            node.AnyChildSelected = value !== 0;
            node.children.forEach((item) => {
                this.LoopChildren(item, value);
            });
        }
    }

    SetCheckBoxValue(node, value) {
        let eide = node.EnumItemDataElement;
        if (!eide) {
            eide = node;
        }
        const oldValue = eide.ValueStatus;
        eide.ValueStatus = value;
        if (eide.ValueStatus === 0) { // bis jetzt null --> Allowed
            (<EnumTreeNode>node).Status = null;
            (<EnumTreeNode>node).indeterminate = false;
        } else if (eide.ValueStatus === 2) { // bis jetzt Allowed --> Denied
            (<EnumTreeNode>node).Status = true;
            (<EnumTreeNode>node).indeterminate = false;
        } else if (eide.ValueStatus === 1) { // bis jetzt Denied  --> null
            (<EnumTreeNode>node).Status = false;
            (<EnumTreeNode>node).indeterminate = true;
        }
        if (!eide.HasChildren) {
            const eiinDic = this.SecurityTreeData.EnumItemDataElements[eide.EnumTypeAsString].find(item => item.Caption === eide.Caption);
            if (eiinDic !== null) {
                if (eiinDic.OldValueStatus === -1) {
                    eiinDic.OldValueStatus = oldValue;
                }
                eiinDic.ValueStatus = eide.ValueStatus;
                this.HasChanges = true;
            }
        }
    }
    GetCheckBoxValue(node) {
        let eide = node.EnumItemDataElement;
        if (!eide) {
            eide = node;
            if (eide.ValueStatus == null) {
                eide.ValueStatus = 0;
            }
        }
        if (eide) {
            if (eide.ValueStatus === 0) {
                return 2;
            } else if (eide.ValueStatus === 2) {
                return 1;
            }
            else if (eide.ValueStatus === 1) {
                return 0;
            }
        }
    }

    async checkHasChanges() {
        if (this.CanSave && this.HasChanges) {
            const retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'),
                new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal === MessageBoxResult.Yes) {
                this.Save();
            } else if (retVal === MessageBoxResult.Abort) {
                return false;
            } else { // No
                this.HasChanges = false;
            }
        }
        return true;
    }

    Save() {
        if (this.CanSave && this.InitArgs && this.SecurityTreeData) {
            this.rolesService.SaveSecurityEnums(this.InitArgs.Id, {
                EnumItemDataElements: this.SecurityTreeData.EnumItemDataElements
            }).subscribe(() => {
                NotificationHelper.Success('@@Permissions saved successfully', '@@Save');
            });
            this.HasChanges = false;
        }
    }
}

export class TreeNode {
    ID?: string;
    ParentID?: string;
    IsExpanded?: boolean = false;
    HasChildren?: boolean = false;
    Data?: string[];
    name: string;
    children?: TreeNode[];
}

export class EnumTreeNode extends TreeNode {
    EnumItemDataElement: EnumItemDataElement;
    EnumValue: Number;
    IsDenied;
    Status;
    checked = false;
    indeterminate = true;

}

export class EnumItemDataElement {
    /// <summary>
    /// Beschriftung
    /// </summary>
    IsAll: Boolean;

    /// <summary>
    /// Beschriftung
    /// </summary>
    Caption: string;

    Id: string;

    /// <summary>
    /// Value des Enums als int
    /// </summary>
    EnumItemValue: Number;

    /// <summary>
    /// 0 = not Set
    /// 1 = not allowed
    /// 2 = allowed
    /// </summary>
    ValueStatus: Number;

    /// <summary>
    /// 0 = not Set
    /// 1 = not allowed
    /// 2 = allowed
    /// </summary>
    OldValueStatus = -1;

    EnumTypeAsString: string;
}

export class SecurityTreeData {
    TreeNodes: TreeNode[];
    EnumItemDataElements: { [id: string]: EnumItemDataElement[]; };
}
