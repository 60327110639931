import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
    EventEmitter, Input, IterableDiffers, OnDestroy, Output, ViewChild, ViewChildren
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { IntersectHelper } from '../../helpers/intersect.helper';
import { SelectionHelper } from '../../helpers/selection.helper';
import { CustomBaseStyle } from '../../models/basic/custombasestyle.model';
import { Row } from '../../models/basic/row.model';
import { CellSelection, OSSelectionRange, RowSelection } from '../../models/basic/selection.model';
import { Order } from '../../models/enums/order.enum';
import { PagingType } from '../../models/enums/pagingtype.enum';
import { ResponsiveType } from '../../models/enums/responsivetype.enum';
import { SelectMode } from '../../models/enums/selectmode.enum';
import { TableStyle } from '../../models/enums/tablestyle.enum';
import { UnitType } from '../../models/enums/unittype.enum';
import { RequestFilter } from '../../models/rest/requestfilter';
import { RequestOptions } from '../../models/rest/requestoptions';
import { RequestSort } from '../../models/rest/requestsort';

@Component({
    selector: 'evi-lib-datatable',
    templateUrl: 'datatable.component.html',
    styleUrls: ['datatable.component.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class DataTableComponent implements AfterViewInit, OnDestroy {
    //#region Properties
    ID = UUID.UUID();
    Subscriptions = {};
    //#region UI
    ShowFooter;
    Loading;
    initialized = false;
    ShowToolTip;
    //#endregion
    BaseSize;
    //#region Data
    EditRows;
    PagedData;
    //#region DataSource
    DataSourceValue;

    @Input()
    get DataSource() {
        return this.DataSourceValue;
    }
    set DataSource(val) {
        this.DataSourceValue = val;
        const initSub = this.Subscriptions["initDS"];
        if (initSub) {
            initSub.unsubscribe();
            delete this.Subscriptions["initDS"];
        }
        const loadingSub = this.Subscriptions["loadingDS"];
        if (loadingSub) {
            loadingSub.unsubscribe();
            delete this.Subscriptions["loadingDS"];
        }
        if (val) {
            this.Subscriptions["initDS"] = this.DataSource.Initialized.subscribe((res) => {
                this.initialized = res;
                this.SelectedRows = [];
                this.InitPagedDS();
            });
            this.Subscriptions["loadingDS"] = this.DataSource.Loading.subscribe((res) => {
                if (res != this.Loading) {
                    this.Loading = res;
                    this.cdRef.detectChanges();
                }
            });
        }
        this.DataSourceChange.emit(this.DataSourceValue);
    }

    @Output() DataSourceChange = new EventEmitter<any>();
    //#endregion
    //#region Rows
    RowsValue;
    RowsRawValue;
    iterableDiffer;
    @Input()
    get Rows() {
        return this.RowsValue;
    }
    set Rows(val) {
        this.RowsRawValue = val;
        this.SelectedRows = [];
        if (val && val.length > 0) {
            const pd = [];
            let count = 0;
            if (this.FixedRowsValue) {
                count = this.FixedRowsValue.length;
            }
            val.forEach((row) => {
                const toPush = new Row();
                toPush.index = count++;
                toPush.data = row;
                if (this.TableStyle === TableStyle.SpreadSheet) {
                    if (this.Columns) {
                        let cols = {};
                        let TabIndex = 0;
                        this.Columns.forEach((column) => {
                            cols[column.Name] = {
                                selected: false,
                                edit: false,
                                tabindex: TabIndex
                            };
                            TabIndex += 1;
                        });
                        toPush.cols = cols;
                    }
                }
                pd.push(toPush);
            });
            this.RowsValue = pd;
        } else {
            this.RowsValue = val;
        }
        if (val) {
            this.initialized = true;
        }
        this.RowsChange.emit(this.RowsValue);
    }

    @Input()
    get PlanningRows() {
        return this.RowsValue;
    }
    set PlanningRows(val) {
        this.RowsRawValue = val;
        this.RowsValue = val;
        if (val) {
            this.initialized = true;
        }
        this.RowsChange.emit(this.RowsValue);
    }

    @Output() RowsChange = new EventEmitter<any>();
    //#endregion
    //#region
    FixedRowsValue: Row[];
    @Input()
    get FixedRows(): Row[] {
        return this.FixedRowsValue;
    }
    set FixedRows(val) {
        if (Array.isArray(val)) {
            this.FixedRowsValue = val;
        } else {
            this.FixedRowsValue = null;
        }
        this.FixedRowsChange.emit(val);
    }
    @Output() FixedRowsChange = new EventEmitter<any>();
    //#endregion
    //#endregion
    //#region Filter
    Filter: RequestOptions = new RequestOptions();
    ShowAdvancedFilter;

    @ViewChildren(MatMenuTrigger) triggers: MatMenuTrigger[];
    FilterTriggers;
    //#endregion
    //#region Selection
    //#region RowSelection
    SelectedRows: Row[] = [];
    OSSelectionRanges: OSSelectionRange[] = [];
    @Output() RowSelectionChanged = new EventEmitter<RowSelection[]>();
    //#endregion
    //#region CellSelection
    innerSelections: { Row: Row, ColumnNames: string[] }[] = [];
    @Output() CellSelectionChanged = new EventEmitter<CellSelection[]>();
    //#endregion
    //#region CopySelection
    @Output() CopySelectionChanged = new EventEmitter<CellSelection[]>();
    //#endregion
    //#endregion
    //#region Scrolling
    get HeaderHeight() {
        if (this.header) {
            return '-' + (this.header.nativeElement.scrollHeight) + 'px';
        } else {
            return 0;
        }
    }
    @ViewChild('header') set _header(content: ElementRef) {
        this.header = content;
    }
    private header: ElementRef;

    @ViewChild('fixedRows') set _fixedRows(content: ElementRef) {
        this.fixedRows = content;
    }
    private fixedRows: ElementRef;

    @ViewChild('Content') set _Content(content: CdkScrollable) {
        this.Content = content;
    }
    private Content: CdkScrollable;
    //#endregion
    //#region Resize
    ContainerWidth = 0;
    private container: ElementRef;
    @ViewChild('container') set _container(content: ElementRef) {
        this.container = content;
    }
    //#endregion
    //#region Editable
    EditableValue;

    @Input()
    get Editable() {
        return this.EditableValue;
    }
    set Editable(val) {
        this.EditableValue = val;
        this.EditableChange.emit(this.EditableValue);
    }

    @Output() EditableChange = new EventEmitter<any>();
    //#endregion
    //#region CustomBaseStyle
    CustomBaseStyleValue: CustomBaseStyle;
    HeaderRowStyle = {};
    HeaderCellStyle = {};
    ContentRowStyle = {};
    ContentCellStyle = {};
    StickyCellStyle = {};
    @Input()
    get CustomBaseStyle() {
        return this.CustomBaseStyleValue;
    }
    set CustomBaseStyle(val) {
        this.CustomBaseStyleValue = val;
        if (this.CustomBaseStyle) {
            if (this.TableStyle == TableStyle.SpreadSheet) {
                if (this.CustomBaseStyle.HeaderBackground) {
                    this.HeaderCellStyle['background'] = this.CustomBaseStyle.HeaderBackground;
                }
                if (this.CustomBaseStyle.Line) {
                    this.HeaderCellStyle['border'] = "1px solid " + this.CustomBaseStyle.Line;
                    this.HeaderCellStyle['border-bottom'] = "1px solid " + this.CustomBaseStyle.Line;
                    this.ContentCellStyle['border'] = "1px solid " + this.CustomBaseStyle.Line;
                    this.StickyCellStyle['border'] = "1px solid " + this.CustomBaseStyle.Line;
                }
                if (this.CustomBaseStyle.HeaderForeground) {
                    this.HeaderCellStyle['color'] = this.CustomBaseStyle.HeaderForeground;
                }
            }
            if (this.TableStyle == TableStyle.DataTable) {
                if (this.CustomBaseStyle.HeaderBackground) {
                    this.HeaderRowStyle['background'] = this.CustomBaseStyle.HeaderBackground;
                }
                if (this.CustomBaseStyle.Line) {
                    this.HeaderRowStyle['border-bottom'] = "1px solid " + this.CustomBaseStyle.Line;
                    this.StickyCellStyle['border-bottom'] = "1px solid " + this.CustomBaseStyle.Line;
                    this.ContentRowStyle['border-bottom'] = "1px solid " + this.CustomBaseStyle.Line;
                }
                if (this.CustomBaseStyle.HeaderForeground) {
                    this.HeaderRowStyle['color'] = this.CustomBaseStyle.HeaderForeground;
                }
            }
            if (this.CustomBaseStyle.StickyBackground) {
                this.StickyCellStyle['background'] = this.CustomBaseStyle.StickyBackground;
            }
        }
        this.CustomBaseStyleChange.emit(this.CustomBaseStyleValue);
    }

    @Output() CustomBaseStyleChange = new EventEmitter<any>();
    //#endregion
    //#region ShowHeader
    ShowHeaderValue = true;

    @Input()
    get ShowHeader() {
        return this.ShowHeaderValue;
    }
    set ShowHeader(val) {
        this.ShowHeaderValue = val;
        this.ShowHeaderChange.emit(this.ShowHeaderValue);
    }

    @Output() ShowHeaderChange = new EventEmitter<any>();
    //#endregion
    //#region Resizable
    ResizableValue = false;

    @Input()
    get Resizable() {
        return this.ResizableValue;
    }
    set Resizable(val) {
        this.ResizableValue = val;
        this.ResizableChange.emit(this.ResizableValue);
    }

    @Output() ResizableChange = new EventEmitter<any>();
    //#endregion
    //#region SelectAll
    SelectAllValue = false;

    @Input()
    get SelectAll() {
        return this.SelectAllValue;
    }
    set SelectAll(val) {
        this.SelectAllValue = val;
        this.SelectAllChange.emit(this.SelectAllValue);
    }

    @Output() SelectAllChange = new EventEmitter<any>();
    //#endregion
    //#region LineHeight
    LineHeightValue = 30;
    LineHeightSet = false;
    @Input()
    get LineHeight() {
        return this.LineHeightValue;
    }
    set LineHeight(val) {
        if (val) {
            this.LineHeightValue = val;
            this.LineHeightSet = true;
            this.LineHeightChange.emit(this.LineHeightValue);
        }
    }

    @Output() LineHeightChange = new EventEmitter<any>();
    //#endregion
    //#region MobileBreakpoint
    MobileBreakpointValue;
    @Input()
    get MobileBreakpoint() {
        return this.MobileBreakpointValue;
    }
    set MobileBreakpoint(val) {
        this.MobileBreakpointValue = val;
        this.MobileBreakpointChange.emit(this.MobileBreakpointValue);
    }
    @Output() MobileBreakpointChange = new EventEmitter<any>();
    //#endregion
    //#region TableStyle
    public Styles = {
        TableHeader: "TableHeader",
        TableHeaderRow: "TableHeaderRow",
        TableHeaderCell: "TableHeaderCell",
        TableHeaderCellContent: "TableHeaderCellContent",
        TableBody: "TableBody",
        TableContent: "TableContent",
        TableRow: "TableRow",
        TableCell: "TableCell",
        TableCellContent: "TableCellContent"
    }

    TableStyleValue: TableStyle = TableStyle.DataTable;
    @Input()
    get TableStyle() {
        return this.TableStyleValue;
    }
    set TableStyle(val) {
        if (val != null) {
            this.TableStyleValue = val;
            if (val == TableStyle.DataTable) {
                this.Styles.TableHeader = "TableHeader";
                this.Styles.TableHeaderRow = "TableHeaderRow";
                this.Styles.TableHeaderCell = "TableHeaderCell";
                this.Styles.TableHeaderCellContent = "TableHeaderCellContent";

                this.Styles.TableBody = "TableBody";
                this.Styles.TableContent = "TableContent";

                this.Styles.TableRow = "TableRow";

                this.Styles.TableCell = "TableCell";
                this.Styles.TableCellContent = "TableCellContent";
                if (!this.LineHeightSet) {
                    this.LineHeightValue = 50;
                }
            } else {
                this.Styles.TableHeader = "SpreadSheetHeader";
                this.Styles.TableHeaderRow = "SpreadSheetHeaderRow";
                this.Styles.TableHeaderCell = "SpreadSheetHeaderCell";
                this.Styles.TableHeaderCellContent = "SpreadSheetHeaderCellContent";

                this.Styles.TableBody = "TableBody";
                this.Styles.TableContent = "TableContent";

                this.Styles.TableRow = "SpreadSheetRow";

                this.Styles.TableCell = "SpreadSheetCell";
                this.Styles.TableCellContent = "SpreadSheetCellContent";
                if (!this.LineHeightSet) {
                    this.LineHeightValue = 20;
                }
            }
            this.TableStyleChange.emit(this.TableStyleValue);
        }
    }

    @Output() TableStyleChange = new EventEmitter<any>();
    //#endregion
    //#region IsPlanningTable
    IsPlanningTableValue = false;
    @Input()
    get IsPlanningTable() {
        return this.IsPlanningTableValue;
    }
    set IsPlanningTable(val) {
        this.IsPlanningTableValue = val;
        this.IsPlanningTableChange.emit(this.IsPlanningTableValue);
    }

    @Output() IsPlanningTableChange = new EventEmitter<any>();
    //#endregion
    //#region CellStyles
    CellStylesValue = {};

    @Input()
    get CellStyles() {
        return this.CellStylesValue;
    }
    set CellStyles(val) {
        if (val != this.CellStylesValue) {
            this.CellStylesValue = val;
            this.CheckCellStyles();
            this.CellStylesChange.emit(this.CellStylesValue);
        }
    }

    @Output() CellStylesChange = new EventEmitter<any>();
    //#endregion

    //#region PagingType
    PagingTypeValue: PagingType = PagingType.Scroll;

    @Input()
    get PagingType() {
        return this.PagingTypeValue;
    }
    set PagingType(val) {
        if (val != null) {
            this.PagingTypeValue = val;
            this.InitPagedDS();
            this.PagingTypeChange.emit(this.PagingTypeValue);
        }
    }

    @Output() PagingTypeChange = new EventEmitter<any>();
    //#endregion
    //#region ResponsiveType
    ResponsiveTypeValue: ResponsiveType = ResponsiveType.Scroll;

    @Input()
    get ResponsiveType() {
        return this.ResponsiveTypeValue;
    }
    set ResponsiveType(val) {
        if (val != null) {
            this.ResponsiveTypeValue = val;
            this.ResponsiveTypeChange.emit(this.ResponsiveTypeValue);
        }
    }

    @Output() ResponsiveTypeChange = new EventEmitter<any>();
    //#endregion
    //#region SelectMode
    SelectModeValue: SelectMode = SelectMode.os;
    @Input()
    get SelectMode() {
        return this.SelectModeValue;
    }
    set SelectMode(val) {
        this.SelectModeValue = val;
        this.SelectModeChange.emit(this.SelectModeValue);
    }

    @Output() SelectModeChange = new EventEmitter<any>();
    //#endregion
    //#region RowsExpandable
    RowsExpandableValue;
    @Input()
    get RowsExpandable() {
        return this.RowsExpandableValue;
    }
    set RowsExpandable(val) {
        this.RowsExpandableValue = val;
        this.RowsExpandableChange.emit(this.RowsExpandableValue);
    }

    @Output() RowsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ColumnsExpandable
    ColumnsExpandableValue;
    @Input()
    get ColumnsExpandable() {
        return this.ColumnsExpandableValue;
    }
    set ColumnsExpandable(val) {
        this.ColumnsExpandableValue = val;
        this.ColumnsExpandableChange.emit(this.ColumnsExpandableValue);
    }

    @Output() ColumnsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region Columns
    ColumnsValue;
    ResponsiveColumns;
    RequestColumns;
    DefaultSort;
    HeaderLoading = false;
    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        if (val) {
            this.CreateColumns();
            this.CheckCellStyles();
            this.SelectionHelper.Columns = this.ColumnsValue;
            if (this.container) {
                this.RecalculateColumns();
            }
            this.HeaderLoading = true;
            setTimeout(() => {
                this.HeaderLoading = false;
            }, 10);
        }
        this.ColumnsChange.emit(this.ColumnsValue);
    }
    @Output() ColumnsChange = new EventEmitter<any>();

    FixedColumnsLeftValue;
    @Input()
    get FixedColumnsLeft() {
        return this.FixedColumnsLeftValue;
    }
    set FixedColumnsLeft(val) {
        this.FixedColumnsLeftValue = val;
        this.FixedColumnsLeftChange.emit(this.FixedColumnsLeftValue);
        this.CreateColumns();
    }
    @Output() FixedColumnsLeftChange = new EventEmitter<any>();

    FixedColumnsRightValue;
    @Input()
    get FixedColumnsRight() {
        return this.FixedColumnsRightValue;
    }
    set FixedColumnsRight(val) {
        this.FixedColumnsRightValue = val;
        this.FixedColumnsRightChange.emit(this.FixedColumnsRightValue);
        this.CreateColumns();
    }
    @Output() FixedColumnsRightChange = new EventEmitter<any>();
    //#endregion

    RTLValue = false;
    @Input()
    get RTL() {
        return this.RTLValue;
    }
    set RTL(val) {
        this.RTLValue = val;
        this.RTLChange.emit(val);
    }
    @Output() RTLChange = new EventEmitter<any>();

    IgnoreIntersectValue = false;
    @Input()
    get IgnoreIntersect() {
        return this.IgnoreIntersectValue;
    }
    set IgnoreIntersect(val) {
        this.IgnoreIntersectValue = val;
        this.IgnoreIntersectChange.emit(val);
    }
    @Output() IgnoreIntersectChange = new EventEmitter<any>();

    IgnoreHoverValue = false;
    @Input()
    get IgnoreHover() {
        return this.IgnoreHoverValue;
    }
    set IgnoreHover(val) {
        this.IgnoreHoverValue = val;
        this.IgnoreHoverChange.emit(val);
    }
    @Output() IgnoreHoverChange = new EventEmitter<any>();

    //#region Events
    @Output() ValueChanged = new EventEmitter<any>();
    @Output() ColumnResized = new EventEmitter<any>();
    @Output() TableInitialized = new EventEmitter<any>();
    @Output() OnKeyPressed = new EventEmitter<any>();
    @Output() RowDoubleClicked = new EventEmitter<any>();
    DataSourceValuesChanged = new Subject();
    //#endregion
    //#endregion
    //#region LifeCycle

    IntersectHelper: IntersectHelper;
    SelectionHelper: SelectionHelper;
    ParentInfo;

    TableStyleObject = {
        'width': '0px',
        'overflow': 'hidden'
    };

    Instance: DataTableComponent;

    constructor(private cdRef: ChangeDetectorRef, private scrollDispatcher: ScrollDispatcher,
        iterableDiffers: IterableDiffers, private sanitizer: DomSanitizer) {
        this.Instance = this;
        this.iterableDiffer = iterableDiffers.find([]).create(null);
        this.IntersectHelper = new IntersectHelper();
        this.Subscriptions["intersect"] = this.IntersectHelper.OnChange.subscribe(() => {
            this.cdRef.detectChanges();
        });
        this.SelectionHelper = new SelectionHelper();
        this.SelectionHelper.Table = this;
    }

    private CreateColumns() {
        this.IntersectHelper.Columns = {};
        this.ParentInfo = {};
        this.CreateColumnsInternal(this.Columns, null);
    }

    CreateColumnsInternal(cols, parent) {
        cols.forEach((col) => {
            col.DisplayCaption = this.sanitizer.bypassSecurityTrustHtml(col.Caption.replace(/\\n/g, '<br />'));
            col.Caption = col.Caption.replace(/\\n/g, '<br />');
            this.IntersectHelper.Columns[col.Name] = col;
            this.ParentInfo[col.Name] = parent;
            if (col.Children) {
                this.CreateColumnsInternal(col.Children, col);
            }
            if (col.FixedChildren) {
                this.CreateColumnsInternal(col.FixedChildren, col);
            }
        });        
    }

    ngAfterViewInit(): void {
        this.ContainerWidth = this.container.nativeElement.offsetWidth;
        this.TableStyleObject.width = this.ContainerWidth + 'px';
        this.InitializeScroll();
        this.cdRef.detectChanges();
        this.FilterTriggers = {};
        if (this.triggers && this.triggers.length > 0) {
            this.triggers.forEach((trigger) => {
                this.FilterTriggers[trigger['_element']['nativeElement'].id] = trigger;
            })
        }
        this.TableInitialized.emit(this);
    }

    ngOnDestroy() {
        Object.keys(this.Subscriptions).forEach(k => {
            const sub = this.Subscriptions[k];
            if (sub) {
                sub.unsubscribe();
            }
        });
    }
    //#endregion
    //#region SizeChanges
    onResized(event) {
        var retVal = {};
        retVal['min-width'] = event.newWidth + 'px';
        retVal['max-width'] = event.newWidth + 'px';
        retVal['width'] = event.newWidth + 'px';
        retVal['min-height'] = event.newHeight + 'px';
        retVal['max-height'] = event.newHeight + 'px';
        retVal['height'] = event.newHeight + 'px';
        this.BaseSize = retVal;
        this.RecalculateColumns();
    }

    RecalculateColumns() {
        DataTableComponent.resetCalcWidth(this.Columns);
        this.ContainerWidth = this.container.nativeElement.offsetWidth;
        this.TableStyleObject.width = this.ContainerWidth + 'px';
        this.ResultStyle = {};
        this.UpdateColumnWidths();
    }

    UpdateColumnWidths() {
        this.Columns.forEach((column) => {
            this.GetColumnWidth(column);
        });
    }

    //#endregion
    //#region Scrolling
    InitializeScroll() {
        this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
            const nElem = event.getElementRef().nativeElement;
            if (nElem.id === this.ID + "-Header") {
                this.Content.getElementRef().nativeElement.scrollLeft = nElem.scrollLeft;
                if (this.fixedRows) {
                    this.fixedRows.nativeElement.scrollLeft = nElem.scrollLeft;
                }
            } else if (nElem.id === this.ID + "-Content") {
                this.header.nativeElement.scrollLeft = nElem.scrollLeft;
                if (this.fixedRows) {
                    this.fixedRows.nativeElement.scrollLeft = nElem.scrollLeft;
                }
            } else if (nElem.id === this.ID + "-FixedRows") {
                this.Content.getElementRef().nativeElement.scrollLeft = nElem.scrollLeft;
                this.header.nativeElement.scrollLeft = nElem.scrollLeft;
            }
        });
    }
    //#endregion
    //#region Table&Data
    InitPagedDS() {
        const pdSub = this.Subscriptions["pagedData"];
        if (pdSub) {
            pdSub.unsubscribe();
            delete this.Subscriptions["pagedData"];
        }
        if (this.initialized) {
            if (this.PagingType == PagingType.Pager) {
                if (this.DataSource && this.DataSource.PagedData) {
                    this.Subscriptions["pagedData"] = this.DataSource.PagedData.subscribe((data) => {
                        this.PagedData = data;
                    });
                }
            }
        }
    }
    //#endregion
    //#region Style
    ResultStyle = {};
    GetColumnWidth(column) {
        if (column.CalculatedWidth) {
        } else {
            if (column.Width) {
                if (column.Width.Type === UnitType.Pixel) {
                    column.CalculatedWidth = column.Width.Value;
                } else if (column.Width.Type === UnitType.Percent) {
                    if ((column.Children && column.Children.length > 0) || (column.FixedChildren && column.FixedChildren.length > 0)) {
                        // wenn Children vorhanden, Prozent-Verteilung ignorieren
                    } else {
                        let contwidth = this.ContainerWidth;// - (this.TableStyle === 0 ? 50 : 0);
                        if (this.SelectMode === SelectMode.multi) {
                            contwidth -= 50;
                        }
                        const res = contwidth * (column.Width.Value / 100);
                        column.CalculatedWidth = res;
                    }
                }
            }
        }
        const result = Object.assign({}, this.ContentCellStyle);
        this.ResultStyle[column.Name] = result;
        this.CheckExpanded(column);
        if (column.IsSticky) {
            let left = 0;
            if (this.RowsExpandable) {
                left += 25;
            }
            for (let i = 0; i < this.Columns.length; i++) {
                const col = this.Columns[i];
                if (col === column) {
                    break;
                }
                if (col.IsSticky) {
                    if (col.expanded && col.ExpandedWidth) {
                        left += col.ExpandedWidth;
                    } else if (col.CalculatedWidth) {
                        left += col.CalculatedWidth;
                    } else if (typeof col.Width === 'number') {
                        left += col.Width;
                    } else {
                        left += parseInt(column.Width.substring(0, column.Width.length - 2), 10);
                    }
                }
            }
            result['left'] = left + 'px';
        }
    }

    private CheckExpanded(column) {
        let width = 0;
        //if (column.IsFilterable) {
        //    width += 30;
        //}
        //if (column.IsSortable) {
        //    width += 30;
        //}
        //if (column.Expandable && this.ColumnsExpandable) {
        //    width += 30;
        //}
        if (column.expanded) {
            column.Children.forEach((col) => {
                if (!col.CalculatedWidth) {
                    if (col.Width && col.Width.Type === UnitType.Pixel) {
                        col.CalculatedWidth = col.Width.Value;
                    } else {
                        col.CalculatedWidth = column.CalculatedWidth;
                    }
                }
                width += this.CheckExpanded(col);
            });
            column.ExpandedWidth = width;
        } else if (column.FixedChildren && column.FixedChildren.length > 0) {
            column.FixedChildren.forEach((col) => {
                if (!col.CalculatedWidth) {
                    if (col.Width && col.Width.Type === UnitType.Pixel) {
                        col.CalculatedWidth = col.Width.Value;
                    } else {
                        col.CalculatedWidth = column.CalculatedWidth;
                    }
                }
                width += this.CheckExpanded(col);
            });
            column.CalculatedWidth = width;
        } else {
            width += column.CalculatedWidth;
        }
        let style = this.ResultStyle[column.Name];
        if (!style) {
            style = {};
            this.ResultStyle[column.Name] = style;
        }
        style['flex'] = '0 0 ' + width + 'px';
        style['width'] = width + 'px';
        style['overflow'] = 'hidden';
        return width;
    }

    ColumnHeaderStyles = {};
    ColumnHeaderAlignment = {};
    CheckCellStyles() {
        this.ColumnHeaderStyles = {};
        this.ColumnHeaderAlignment = {};
        if (this.CellStylesValue && this.ColumnsValue) {
            this.CheckCellStylesRecursive(this.ColumnsValue);
        }
    }

    private CheckCellStylesRecursive(columns) {
        columns.forEach(col => {
            if (typeof col.StyleID === 'number') {
                const styleObj = this.CellStylesValue['' + col.StyleID];
                if (styleObj) {
                    this.ColumnHeaderStyles[col.Name] = Object.assign({}, styleObj);
                    const textAlign = styleObj['text-align'];
                    if (typeof textAlign === 'string') {
                        let alignment = this.ColumnHeaderAlignment[col.Name];
                        if (!alignment) {
                            alignment = {};
                            this.ColumnHeaderAlignment[col.Name] = alignment;
                        }
                        alignment['text-align'] = textAlign;
                        switch (textAlign) {
                            case 'start':
                                alignment['justify-content'] = 'flex-start';
                                break;
                            case 'center':
                                alignment['justify-content'] = 'center';
                                break;
                            case 'end':
                                alignment['justify-content'] = 'flex-end';
                                break;
                        }
                    }
                }
            }
            if (col.Children) {
                this.CheckCellStylesRecursive(col.Children);
            }
            if (col.FixedChildren) {
                this.CheckCellStylesRecursive(col.FixedChildren);
            }
        });
    }

    static resetCalcWidth(cols) {
        if (cols) {
            cols.forEach(c => {
                if (!c.WasResized) {
                    c.CalculatedWidth = null;
                    DataTableComponent.resetCalcWidth(c.Children);
                    DataTableComponent.resetCalcWidth(c.FixedChildren);
                }
            });
        }
    }
    //#endregion
    //#region ToolTip
    enter(event: MouseEvent) {
        //if (event.target['offsetWidth'] < event.target['scrollWidth']) {
        //    this.ShowToolTip = true;
        //} else {
        //    this.ShowToolTip = false;
        //}
    }
    //#endregion
    //#region Actions
    clicked() {
        //this.triggerEvent('OnFocus', this.DataSource);
    }
    
    RowClick(value) {
        const row = value.Row;
        const col = value.Column;
        const event: MouseEvent = value.Event;
        if (this.TableStyleValue === TableStyle.DataTable) {
            if (this.SelectMode === SelectMode.single) {
                if (this.SelectedRows.length > 0) {
                    const first = this.SelectedRows[0];
                    if (first === row) {
                        return;
                    }
                    first.selected = false;
                }
                row.selected = true;
                this.SelectedRows = [row];
                this.RowSelectionChanged.emit([{
                    RowIndex: row.index,
                    RowData: row.data
                }]);
            } else if (this.SelectMode === SelectMode.os) {
                this.SelectedRows.forEach(x => { x.selected = false; });
                if (this.OSSelectionRanges.length > 0 && (event.ctrlKey || event.shiftKey)) {
                    if (event.ctrlKey) {
                        if (!this.OSSelectionRanges.some((x, i) => {
                            if (x.ContainsOnY(row.index)) {
                                this.OSSelectionRanges.splice(i, 1);
                                if (x.YStart !== x.YEnd) {
                                    if (row.index < x.YEnd) {
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: row.index + 1,
                                            YEnd: x.YEnd
                                        }));
                                    }
                                    if (x.YStart < row.index) {
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: x.YStart,
                                            YEnd: row.index - 1
                                        }));
                                    }
                                }
                                return true;
                            }
                            return false;
                        })) {
                            this.OSSelectionRanges.push(new OSSelectionRange({
                                YStart: row.index,
                                YEnd: row.index
                            }));
                        }
                    } else {
                        const last = this.OSSelectionRanges[this.OSSelectionRanges.length - 1];
                        const startIndex = last.UseYStartForShift ? last.YStart : last.YEnd;
                        if (row.index < startIndex) {
                            last.YStart = row.index;
                            last.YEnd = startIndex;
                            last.UseYStartForShift = false;
                        } else {
                            last.YStart = startIndex;
                            last.YEnd = row.index;
                            last.UseYStartForShift = true;
                        }
                    }
                    const selectedRows = [];
                    const rows = this.GetRowList();
                    if (rows) {
                        const selections: RowSelection[] = [];
                        this.OSSelectionRanges.forEach(x => {
                            for (let i = x.YStart; i <= x.YEnd; i++) {
                                const selRow = rows[i];
                                selRow.selected = true;
                                selections.push({
                                    RowIndex: i,
                                    RowData: selRow.data
                                });
                                selectedRows.push(selRow);
                            }
                        });
                        this.RowSelectionChanged.emit(selections);
                    }
                    this.SelectedRows = selectedRows;
                } else {
                    this.OSSelectionRanges = [new OSSelectionRange({
                        YStart: row.index,
                        YEnd: row.index
                    })];
                    row.selected = true;
                    this.SelectedRows = [row];
                    this.RowSelectionChanged.emit([{
                        RowIndex: row.index,
                        RowData: row.data
                    }]);
                }
            } else if (this.SelectModeValue == SelectMode["fire&forget"]) {
                this.SelectedRows = [];
                this.RowSelectionChanged.emit([{
                    RowIndex: row.index,
                    RowData: row.data
                }]);
            }
        } else {
            if (this.SelectModeValue === SelectMode.single) {
                if (this.innerSelections.length > 0) {
                    const first = this.innerSelections[0];
                    if (first.Row === row && first.ColumnNames.some(x => x === col.Name)) {
                        return;
                    }
                }
                this.innerSelections.forEach(x => {
                    x.ColumnNames.forEach(cn => {
                        x.Row.cols[cn].selected = false;
                    });
                });
                row.cols[col.Name].selected = true;
                this.innerSelections = [{
                    Row: row,
                    ColumnNames: [col.Name]
                }];
                this.CellSelectionChanged.emit([{
                    ColumnName: col.Name,
                    RowIndex: row.index
                }]);
            } else if (this.SelectModeValue === SelectMode.multi) {
                if (!this.innerSelections.some((x, i) => {
                    if (x.Row === row) {
                        if (x.ColumnNames.some((cn, j) => {
                            if (cn === col.Name) {
                                x.Row.cols[cn].selected = false;
                                x.ColumnNames.splice(j, 1);
                                return true;
                            }
                            return false;
                        })) {
                            if (x.ColumnNames.length === 0) {
                                this.innerSelections.splice(i, 1);
                            }
                        } else {
                            x.Row.cols[col.Name].selected = true;
                            x.ColumnNames.push(col.Name);
                        }
                        return true;
                    }
                    return false;
                })) {
                    row.cols[col.Name].selected = true;
                    this.innerSelections.push({
                        Row: row,
                        ColumnNames: [col.Name]
                    });
                }
                const selections: CellSelection[] = [];
                this.innerSelections.forEach(x => {
                    x.ColumnNames.forEach(cn => {
                        selections.push({
                            ColumnName: cn,
                            RowIndex: x.Row.index
                        });
                    });
                });
                this.CellSelectionChanged.emit(selections);
            } else if (this.SelectModeValue === SelectMode.os) {
                const colNames = DataTableComponent.GetColumnLeaveNames(this.ColumnsValue);
                let rows;
                if (this.OSSelectionRanges.length > 0) {
                    rows = this.GetRowList();
                    this.OSSelectionRanges.forEach(range => {
                        for (let y = range.YStart; y <= range.YEnd; y++) {
                            const rangeRow = rows[y];
                            for (let x = range.XStart; x <= range.XEnd; x++) {
                                rangeRow.cols[colNames[x]].selected = false;
                            }
                        }
                    });
                }
                const colIndex = colNames.indexOf(col.Name);
                if (this.OSSelectionRanges.length > 0 && (event.ctrlKey || event.shiftKey)) {
                    if (event.ctrlKey) {
                        if (!this.OSSelectionRanges.some((x, i) => {
                            if (x.Contains(colIndex, row.index)) {
                                this.OSSelectionRanges.splice(i, 1);
                                if (x.YStart < row.index) {
                                    if (row.index < x.YEnd) {
                                        if (x.XStart < colIndex) {
                                            if (colIndex < x.XEnd) {
                                                this.OSSelectionRanges.push(new OSSelectionRange({
                                                    YStart: row.index,
                                                    YEnd: row.index,
                                                    XStart: colIndex + 1,
                                                    XEnd: x.XEnd
                                                }));
                                            }
                                            this.OSSelectionRanges.push(new OSSelectionRange({
                                                YStart: row.index,
                                                YEnd: row.index,
                                                XStart: x.XStart,
                                                XEnd: colIndex - 1
                                            }));
                                        } else {
                                            this.OSSelectionRanges.push(new OSSelectionRange({
                                                YStart: row.index,
                                                YEnd: row.index,
                                                XStart: colIndex + 1,
                                                XEnd: x.XEnd
                                            }));
                                        }
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: row.index + 1,
                                            YEnd: x.YEnd,
                                            XStart: x.XStart,
                                            XEnd: x.XEnd
                                        }));
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: x.YStart,
                                            YEnd: row.index - 1,
                                            XStart: x.XStart,
                                            XEnd: x.XEnd
                                        }));
                                    } else {
                                        if (x.XStart < colIndex) {
                                            if (colIndex < x.XEnd) {
                                                this.OSSelectionRanges.push(new OSSelectionRange({
                                                    YStart: row.index,
                                                    YEnd: row.index,
                                                    XStart: colIndex + 1,
                                                    XEnd: x.XEnd
                                                }));
                                            }
                                            this.OSSelectionRanges.push(new OSSelectionRange({
                                                YStart: row.index,
                                                YEnd: row.index,
                                                XStart: x.XStart,
                                                XEnd: colIndex - 1
                                            }));
                                        } else {
                                            this.OSSelectionRanges.push(new OSSelectionRange({
                                                YStart: row.index,
                                                YEnd: row.index,
                                                XStart: colIndex + 1,
                                                XEnd: x.XEnd
                                            }));
                                        }
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: x.YStart,
                                            YEnd: row.index -1,
                                            XStart: x.XStart,
                                            XEnd: x.XEnd
                                        }));
                                    }
                                } else {
                                    this.OSSelectionRanges.push(new OSSelectionRange({
                                        YStart: row.index + 1,
                                        YEnd: x.YEnd,
                                        XStart: x.XStart,
                                        XEnd: x.XEnd
                                    }));
                                    if (x.XStart < colIndex) {
                                        if (colIndex < x.XEnd) {
                                            this.OSSelectionRanges.push(new OSSelectionRange({
                                                YStart: row.index,
                                                YEnd: row.index,
                                                XStart: colIndex + 1,
                                                XEnd: x.XEnd
                                            }));
                                        }
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: row.index,
                                            YEnd: row.index,
                                            XStart: x.XStart,
                                            XEnd: colIndex - 1
                                        }));
                                    } else {
                                        this.OSSelectionRanges.push(new OSSelectionRange({
                                            YStart: row.index,
                                            YEnd: row.index,
                                            XStart: colIndex + 1,
                                            XEnd: x.XEnd
                                        }));
                                    }
                                }
                                return true;
                            }
                            return false;
                        })) {
                            this.OSSelectionRanges.push(new OSSelectionRange({
                                YStart: row.index,
                                YEnd: row.index,
                                XStart: colIndex,
                                XEnd: colIndex
                            }));
                        }
                    } else {
                        const last = this.OSSelectionRanges[this.OSSelectionRanges.length - 1];
                        const startY = last.UseYStartForShift ? last.YStart : last.YEnd;
                        const startX = last.UseXStartForShift ? last.XStart : last.XEnd;
                        if (row.index < startY) {
                            last.YStart = row.index;
                            last.YEnd = startY;
                            last.UseYStartForShift = false;
                        } else {
                            last.YStart = startY;
                            last.YEnd = row.index;
                            last.UseYStartForShift = true;
                        }
                        if (colIndex < startX) {
                            last.XStart = colIndex;
                            last.XEnd = startX;
                            last.UseXStartForShift = false;
                        } else {
                            last.XStart = startX;
                            last.XEnd = colIndex;
                            last.UseXStartForShift = true;
                        }
                    }
                    const selections = {};
                    this.OSSelectionRanges.forEach(range => {
                        for (let y = range.YStart; y <= range.YEnd; y++) {
                            const rangeRow = rows[y];
                            for (let x = range.XStart; x <= range.XEnd; x++) {
                                const colName = colNames[x];
                                rangeRow.cols[colName].selected = true;
                                selections[y + '_' + x] = {
                                    ColumnName: colName,
                                    RowIndex: y
                                };
                            }
                        }
                    });
                    const selectionList = [];
                    Object.keys(selections).forEach(x => {
                        selectionList.push(selections[x]);
                    });
                    this.CellSelectionChanged.emit(selectionList);
                } else {
                    this.OSSelectionRanges = [new OSSelectionRange({
                        YStart: row.index,
                        YEnd: row.index,
                        XStart: colIndex,
                        XEnd: colIndex
                    })];
                    row.cols[col.Name].selected = true;
                    this.CellSelectionChanged.emit([{
                        ColumnName: col.Name,
                        RowIndex: row.index
                    }]);
                }
            } else if (this.SelectModeValue == SelectMode["fire&forget"]) {
                this.innerSelections = [];
                this.CellSelectionChanged.emit([{
                    ColumnName: col.Name,
                    RowIndex: row.index
                }]);
            }
        }
    }
    RowDoubleClick(row) {
        if (!this.Editable) {
            this.RowDoubleClicked.emit({ RowIndex: row.index, RowData: row.data });
        }
    }
    RowSelected(row) {
        if (this.SelectMode === SelectMode.multi) {
            if (row.selected) {
                this.SelectedRows.push(row);
            } else {
                this.SelectedRows.splice(this.SelectedRows.indexOf(row), 1);
            }
            const selections: RowSelection[] = [];
            this.SelectedRows.forEach(x => {
                selections.push({
                    RowIndex: x.index,
                    RowData: x.data
                });
            });
            this.RowSelectionChanged.emit(selections);
        }
    }
    //ClearSelections() {
    //    this.OSSelectionRanges.splice(0);
    //    if (this.TableStyleValue === TableStyle.DataTable) {
    //        this.SelectedRows.forEach(x => { x.selected = false; });
    //        this.SelectedRows.splice(0);
    //        this.RowSelectionChanged.emit([]);
    //    } else {
    //        if (this.SelectModeValue === SelectMode.os) {
    //            const colNames = DataTableComponent.GetColumnLeaveNames(this.ColumnsValue);
    //            const rows = this.GetRowList();
    //            rows.forEach(row => {
    //                if (row.cols) {
    //                    colNames.forEach(col => {
    //                        const rowCol = row.cols[col];
    //                        if (rowCol) {
    //                            rowCol.selected = false;
    //                        }
    //                    });
    //                }
    //            });
    //        } else {
    //            this.innerSelections.forEach(x => {
    //                x.ColumnNames.forEach(cn => {
    //                    x.Row.cols[cn].selected = false;
    //                });
    //            });
    //            this.innerSelections.splice(0);
    //        }
    //        this.CellSelectionChanged.emit([]);
    //    }
    //}
    RowEdit(row) {
        if (this.Editable) {
            row.edit = true;
            if (!this.EditRows) {
                this.EditRows = [];
            }
            const copy = JSON.parse(JSON.stringify(row));
            this.EditRows.push({
                original: row,
                copy: copy
            });
        }
    }
    RowEditAccept(row) {
        row.edit = false;
        const res = this.EditRows.filter((value, index, array) => {
            return value.original === row;
        });
        if (res && res.length > 0) {
            this.EditRows.splice(this.EditRows.indexOf(res[0]), 1);
        }
        this.ValueChanged.emit(row.data);
    }
    RowEditCancel(row) {
        const res = this.EditRows.filter((value, index, array) => {
            return value.original === row;
        });
        if (res && res.length > 0) {
            row.data = res[0].copy.data;
            this.EditRows.splice(this.EditRows.indexOf(res[0]), 1);
        }
        row.edit = false;
    }
    KeyPressed(event) {
        const ev: KeyboardEvent = event.event;
        let rows;
        if (this.DataSourceValue) {
            rows = this.DataSourceValue.cachedData;
        }
        if (this.RowsValue) {
            rows = this.RowsValue;
        }
        if (rows) {
            let rowindex = rows.indexOf(event.row);
            let columnindex = this.Columns.indexOf(event.column);
            if (!rows[rowindex].cols[this.Columns[columnindex].Name].edit) {
                switch (ev.key) {
                    case 'ArrowRight':
                        if (columnindex + 1 === this.Columns.length) {
                            rowindex += 1;
                            columnindex = 0;
                        }
                        if (rowindex <rows.length) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].selected = false;
                            rows[rowindex].cols[this.Columns[columnindex + 1].Name].selected = true;
                        }
                        break;
                    case 'ArrowLeft':
                        if (columnindex === 0) {
                            rowindex -= 1;
                            columnindex = this.Columns.length;
                        }
                        if (rowindex >= 0) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].selected = false;
                            rows[rowindex].cols[this.Columns[columnindex - 1].Name].selected = true;
                        }
                        break;
                    case 'ArrowUp':
                        if (rowindex > 0) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].selected = false;
                            rows[rowindex - 1].cols[this.Columns[columnindex].Name].selected = true;
                        }
                        break;
                    case 'ArrowDown':
                        if (rowindex + 1 < rows.length) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].selected = false;
                            rows[rowindex + 1].cols[this.Columns[columnindex].Name].selected = true;
                        }
                        break;
                    case 'Enter':
                        if (rows[rowindex].cols[this.Columns[columnindex].Name].editable !== false) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].edit = true;
                        }
                        break;
                }
            } else {
                switch (ev.key) {
                    case 'Tab':
                        if (columnindex + 1 === this.Columns.length) {
                            rowindex += 1;
                            columnindex = 0;
                        }
                        if (rowindex < rows.length) {
                            rows[rowindex].cols[this.Columns[columnindex].Name].selected = false;
                            rows[rowindex].cols[this.Columns[columnindex + 1].Name].selected = true;
                            rows[rowindex].cols[this.Columns[columnindex].Name].edit = false;
                            rows[rowindex].cols[this.Columns[columnindex + 1].Name].edit = true;
                        }
                        break;
                    case 'Escape':
                        rows[rowindex].cols[this.Columns[columnindex].Name].edit = false;
                        break;
                    case 'Enter':
                        rows[rowindex].cols[this.Columns[columnindex].Name].edit = false;
                        break;
                }
            }
        }
        this.OnKeyPressed.emit(ev);
    }
    

    ChangeSort(ev) {
        const column = ev.column;
        const event: MouseEvent = ev.event;
        if (!this.Rows) {
            if (!event.ctrlKey) {
                let clear = true;
                this.Columns.forEach((col) => {
                    if (col === column && col.OrderState !== 'NONE') {
                        clear = false;
                    }
                    if (col !== column) {
                        col.OrderState = 'NONE';
                    }
                });
                if (clear) {
                    this.Filter.Sort = [];
                }
            }
        } else {
            let clear = true;
            this.Columns.forEach((col) => {
                if (col === column && col.OrderState !== 'NONE') {
                    clear = false;
                }
                if (col !== column) {
                    col.OrderState = 'NONE';
                }
            });
            if (clear) {
                this.Filter.Sort = [];
            }
        }
        if (column.IsSortable) {
            if (column.OrderState === 'NONE') {
                column.OrderState = 'ASC';
            } else if (column.OrderState === 'ASC') {
                column.OrderState = 'DESC';
            } else if (column.OrderState === 'DESC') {
                column.OrderState = 'NONE';
            }
            if (this.Filter.Sort && this.Filter.Sort.length > 0) {
                const order = this.Filter.Sort.filter((value, index, array) => {
                    return value.Name === column.Name;
                });
                if (order && order.length > 0) {
                    if (column.OrderState === 'NONE') {
                        this.Filter.Sort.splice(this.Filter.Sort.indexOf(order[0]), 1);
                    } else {
                        order[0].Order = Order.DESC;
                    }
                } else {
                    const sort = new RequestSort();
                    sort.Name = column.Name;
                    sort.Order = Order.ASC;
                    this.Filter.Sort.push(sort);
                }
            } else {
                const sort = new RequestSort();
                sort.Name = column.Name;
                sort.Order = Order.ASC;
                this.Filter.Sort.push(sort);
            }
            this.SortChanged.emit(this.Filter.Sort);
        }
    }

    @Output() SortChanged = new EventEmitter<RequestSort[]>();
    @Output() FilterChanged = new EventEmitter<RequestFilter[]>();

    ChangeFilter(column) {
        let columnName = column.name;
        if (column.Name) {
            columnName = column.Name;
        }

        const fil = this.Filter.Filters.filter((value, index, array) => {
            return value.Name === columnName;
        });
        if (column.SearchValue != null) {
            column.FilterActive = true;
            if (fil && fil.length > 0) {
                fil[0].Value = column.SearchValue;
                fil[0].Operator = column.SearchOperator;
                fil[0].Name = columnName;
            } else {
                const item = new RequestFilter();
                item.Name = columnName;
                item.Operator = column.SearchOperator;
                item.Value = column.SearchValue;
                this.Filter.Filters.push(item);
            }
        } else {
            column.FilterActive = false;
            this.Filter.Filters.splice(this.Filter.Filters.indexOf(fil[0]), 1);
        }
        this.FilterChanged.emit(this.Filter.Filters);
        this.FilterTriggers[column.Name].toggleMenu();
    }
    CancelFilter(column) {
        this.FilterTriggers[column.Name].toggleMenu();
    }
    //#endregion
    //#region Paging
    ChangePage(event: PageEvent) {
        if (event.pageSize !== this.DataSource.pageSize) {
            this.DataSource.pageSize = event.pageSize;
        }
        this.DataSource.fetchPage(event.pageIndex);
    }
    //#endregion

    private GetRowList() {
        let retVal = null;
        if (this.FixedRowsValue) {
            retVal = [...this.FixedRowsValue];
        }
        if (this.RowsValue) {
            if (retVal) {
                retVal.push(...this.RowsValue);
            } else {
                retVal = [...this.RowsValue];
            }
        } else if (this.PagingType === PagingType.Pager) {
            if (this.PagedData) {
                if (retVal) {
                    retVal.push(...this.PagedData);
                } else {
                    retVal = [...this.PagedData];
                }
            }
        } else if (this.DataSourceValue && this.DataSourceValue.cachedData) {
            if (retVal) {
                retVal.push(...this.DataSourceValue.cachedData);
            } else {
                retVal = [...this.DataSourceValue.cachedData];
            }
        }
        return retVal;
    }

    private static GetColumnLeaveNames(columns) {
        const retVal = [];
        if (columns) {
            columns.forEach(col => {
                if (col.expanded) {
                    if (col.Children && col.Children.length > 0) {
                        retVal.push(...DataTableComponent.GetColumnLeaveNames(col.Children));
                    } else {
                        retVal.push(col.Name);
                    }
                } else if (col.FixedChildren && col.FixedChildren.length > 0) {
                    retVal.push(...DataTableComponent.GetColumnLeaveNames(col.FixedChildren));
                } else {
                    retVal.push(col.Name);
                }
            });
        }
        return retVal;
    }

    GenerateTableHeaderRowStyle() {
        const retVal = Object.assign({}, this.HeaderRowStyle);
        retVal['margin-top'] = this.ShowHeader ? '0' : this.HeaderHeight;
        retVal['min-height'] = this.LineHeight + 'px';
        return retVal;
    }
    AllEntriesSelected = false;
    RowSelectionTriggered(event) {
        this.AllEntriesSelected = !this.AllEntriesSelected;
        let rows = [];
        if (this.DataSourceValue) {
            this.DataSourceValue.cachedData.forEach((row) => {
                row.selected = false;
            });

            let page =this.DataSourceValue.ActivePage;
            let size = this.DataSourceValue.pageSize;
            let start = page * size;
            let end = start + size;
            if (end > this.DataSourceValue.cachedData.length) {
                end = this.DataSourceValue.cachedData.length
            }
            if (this.AllEntriesSelected) {
                rows = this.DataSourceValue.cachedData.slice(start, end);
            }
        }
        if (this.RowsValue) {
            rows = this.RowsValue;
        }
        if (rows) {
            rows.forEach((row) => {
                row.selected = this.AllEntriesSelected;
            });
            console.log(rows);
        }

        this.SelectedRows = rows;
        const selections: RowSelection[] = [];
        this.SelectedRows.forEach(x => {
            selections.push({
                RowIndex: x.index,
                RowData: x.data
            });
        });
        this.RowSelectionChanged.emit(selections);
    }
}
