import { ABaseElement } from "./abaseelement.model";
import { ADBInfo } from "./adbinfo.model";

export class DataSource extends ABaseElement {
    Connection: string;
    DBInfoType; //Type???
    DataModelID: string;
    DTExecPath: string;
    DBInfo: ADBInfo;

    toJSON() {
        let result = Object.assign({}, this);
        if (result.DBInfo) {
            result.Connection = JSON.stringify(result.DBInfo);
            result.DBInfoType = result.DBInfo.getTypeName();
            delete result.DBInfo;
        } else {
            result.Connection = null;
            result.DBInfoType = null;
        }
        return result;
    }
}