import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { LayoutHelper } from '../../../helpers/layout.helper';
import { LayoutService } from '../../../services/layout.service';
import { SettingsService } from '../../../services/settings.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-canvas',
    templateUrl: './canvas.layout.html',
    styleUrls: ['./canvas.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CanvasLayout extends IBaseComponent {

    static Type: any = 'canvas';
    static Default = {
        Editable: true,
        Type: 'canvas'
    };

    dragItem;
    _Items;
    Items$;
    @Input()
    public get Items() {
        return this._Items;
    }
    public set Items(val) {
        this._Items = val;
        this.Items$ = of(val);
        if (this.ItemsChange) {
            this.ItemsChange.emit(this._Items);
        }
    }
    @Output()
    public ItemsChange = new EventEmitter();

    dragType;

    constructor(private settingsService: SettingsService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    ControlInitialized() {
        this.Subscriptions['DragStart'] = this.settingsService.DragStart.subscribe((item) => {
            this.dragItem = item.item;
        });
        this.Subscriptions['Delete'] = this.settingsService.Delete.subscribe((item) => {
            this.removeItem(item);
        });
        this.Subscriptions['DragType'] = LayoutService.DragType.subscribe((dragType) => {
            if (dragType === this.LayoutElement.ID) {
                this.dragType = dragType;
            } else {
                this.dragType = null;
            }
        });
        this.Subscriptions['selection'] = LayoutService.SelectionChanged.subscribe((value) => {
            this.cdRef.detectChanges();
        });
        this.Subscriptions['Values'] = this.LayoutElementValue.ValuesChanged.subscribe((Property) => {
            this.cdRef.detectChanges();
        });
    }

    onLayoutElementSet() {
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();
    }
    onLayoutElementChanged() {
        this.Items = this.LayoutElementValue.Elements;
        this.cdRef.detectChanges();
    }
    itemDrop(event) {
        let insert;
        insert = LayoutHelper.GetDroppedLayoutElement(this.dragItem);
        insert.Parent = this.LayoutElement;
        this.LayoutElement.Elements.push(insert);
        event.currentTarget.style.backgroundColor = 'transparent';
        LayoutService.OnLayoutElementAdded({
            ElementContent: JSON.stringify(insert),
            Index: this.LayoutElementValue.Elements.length - 1,
            ParentID: this.LayoutElementValue.ID
        });
        this.cdRef.detectChanges();
    }

    removeItem(item) {
        const index = this.Items.indexOf(item);
        if (index > -1) {
            this.Items.splice(index, 1);
            LayoutService.SelectedItem.next(null);
            LayoutService.OnLayoutElementRemoved({
                ElementID: item.ID,
                ElementName: item.Name ? item.Name : item.ID,
                ParentID: this.LayoutElementValue.ID
            });
        }
    }

    itemSelected(item, event) {
        if (!item.Selected && !this.FromBaseLayout) {
            LayoutService.SelectedItems.next(null);
            LayoutService.SelectedItem.next(item);
        }
    }
}