import { DynamicComponentInjector } from '../services/dynamic.component.service';
import { SettingsService } from '../services/settings.service';
import { LayoutService } from '../services/layout.service';
import { DataService } from '../services/data.service';
import { MetaService, OutputService } from '../services/meta.service';
import { KeyboardShortcuts } from '../helpers/keyboard.helper';
import { WorkflowService } from '../services/workflow.service';
import {
    DataModelService, ContainerEnumService, SelfServiceSecurityService, SelfServiceObjectService
} from '../services/datamodel.service';
import { DynamicDataService } from '../services/dynamicdata.service';
import { MediaService } from '../services/media.service';
import { StandardRequestBase } from '../services/request-base';
import { UsersService } from '../services/users.service';
import { SecurityService } from '../services/security.service';
import { WebsocketService } from '../services/websocket.service';
import { RolesService } from '../services/roles.service';
import { FormulaService } from '../services/formula.service';
import { LogsService } from '../services/logs.service';
import { PermissionsService } from '../services/permissions.service';
import { SchedulerService } from '../services/scheduler.service';
import { GlobalVariableService } from '../services/globalvariable.service';
import { LanguageService } from '../services/language.service';
import { TranslationService } from '../services/translation.service';
import { ScriptLibraryService } from '../services/scriptlibrary.service';
import { UserSettingsService } from '../services/usersettings.service';
import { OfflineService } from '../services/offline.service';
import { GeneralSettingsService } from '../services/generalsettings.service';
import { WebHookService } from '../services/webhook.service';
import { DebugService } from '../services/debug.service';
import { CanDeactivateContentPage } from '../app.routing';
import { MailSettingsService } from '../services/mailsettings.service';
import { CustomRestService } from '../services/customrest.service';
import { NumberCircleService } from '../services/numbercircle.service';
import { ConnectorService } from '../services/connector.service';
import { CollaborationService } from '../services/collaboration.service';
import { GitService } from '../services/git.service';
import { MonitoringService } from '../services/monitoring.service';
import { AppBuilderSettingsService } from '../services/appbuildersettings.service';
import { WorkerService } from '../services/worker.service';
import { DebugScriptBreakpointSettingsControl } from '../debugconsole/workflow/debug.script.breakpoint.control';
import { JiraService } from '../services/jira.service';
import { WebPushService } from '../services/webpush.service';
import { GADateTimeAdapter, GAIntl } from '../services/owl.services';
import { CapsuleService } from '../services/capsule.service';
import { ChangeLogService } from '../services/changelog.service';
import { VersioningService } from '../services/versioning.service';
import { AgentService } from '../services/agent.service';
import {ExternalRestEndPoint} from "../services/external-rest-endpoints.service";
import {MobileConfigService} from "../services/mobile-config.service";
import {GlobalSearchService} from "../services/global-search.service";
import { RefreshTokenService } from '../services/refresh-token.service';

export const APP_SERVICES = [
    AppBuilderSettingsService,
    DynamicComponentInjector,
    SettingsService,
    LayoutService,
    DataService,
    MetaService,
    KeyboardShortcuts,
    WorkflowService,
    DataModelService,
    DynamicDataService,
    MediaService,
    StandardRequestBase,
    UsersService,
    SecurityService,
    ContainerEnumService,
    WebsocketService,
    FormulaService,
    RolesService,
    PermissionsService,
    LogsService,
    SchedulerService,
    GlobalVariableService,
    SelfServiceSecurityService,
    SelfServiceObjectService,
    LanguageService,
    TranslationService,
    ScriptLibraryService,
    JiraService,
    AgentService,
    UserSettingsService,
    OfflineService,
    GeneralSettingsService,
    WebHookService,
    DebugService,
    CanDeactivateContentPage,
    OutputService,
    CustomRestService,
    MailSettingsService,
    NumberCircleService,
    ConnectorService,
    CollaborationService,
    MonitoringService,
    GitService,
    WebPushService,
    WorkerService,
    GADateTimeAdapter,
    GAIntl,
    CapsuleService,
    ChangeLogService,
    VersioningService,
    ExternalRestEndPoint,
    MobileConfigService,
    GlobalSearchService,
    RefreshTokenService,
    
    { provide: LayoutService.CONTAINER_DATA, useValue: undefined },
    { provide: DebugScriptBreakpointSettingsControl.SCRIPT_DEBUG_DATA, useValue: undefined }
];
