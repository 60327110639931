import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { BaseDataSourcePanel } from '../basedatasource.panel';

@Component({
    selector: 'dynamics365-panel',
    templateUrl: './dynamics365.panel.html',
    styleUrls:['./dynamics365.panel.css']
})
export class Dynamics365Panel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = D365DBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: Dynamics365Panel,
            DBInfoType: D365DBInfo
        };
    }
}

export class D365DBInfo extends ADBInfo {
    Username: string = null;
    Password: string = null;
    UriString: string = null;
    ActiveDirectoryResource: string = null;
    ActiveDirectoryTenant: string = null;
    ActiveDirectoryClientAppId: string = null;
    ActiveDirectoryClientAppSecret: string = null;
    TLSVersion: string = null;

    getTypeName(): string {
        return 'evidanza.Database.D365FinOp.D365DBInfo';
    }
}
