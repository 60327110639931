import { LayoutService } from '../../services/layout.service';

export class Color {

    A: number = 255;
    R: number = null;
    G: number = null;
    B: number = null;
    ColorReference: string;

    static IsValid(color) {
        if (color) {
            if (color.ColorReference && LayoutService.CSSVariablesMap && LayoutService.CSSVariablesMap[color.ColorReference]) {
                return true;
            }
            return color.R != null && color.B != null && color.C != null;
        }
        return false;
    }

    static FromReference(reference) {
        if (LayoutService.CSSVariablesMap) {
            const variable = LayoutService.CSSVariablesMap[reference];
            if (variable) {
                let color = new Color();
                if (variable.Value) {
                    color = Color.FromHex(variable.Value);
                }
                color.ColorReference = reference;
                return color;
            }
        }
        return null;
    }

    static FromHex(hexText): Color {
        const retVal = new Color();
        if (typeof hexText === 'string' && hexText.length > 5) {
            if (hexText[0] === '#') {
                hexText = hexText.slice(1);
            }
            if (hexText.length > 7) {
                const rText = hexText.slice(-8, -6);
                const gText = hexText.slice(-6, -4);
                const bText = hexText.slice(-4, -2);
                const aText = hexText.slice(-2);
                retVal.R = parseInt(rText, 16);
                retVal.G = parseInt(gText, 16);
                retVal.B = parseInt(bText, 16);
                retVal.A = parseInt(aText, 16);
            } else {
                const rText = hexText.slice(-6, -4);
                const gText = hexText.slice(-4, -2);
                const bText = hexText.slice(-2);
                retVal.R = parseInt(rText, 16);
                retVal.G = parseInt(gText, 16);
                retVal.B = parseInt(bText, 16);
                retVal.A = 255;
            }
        }
        return retVal;
    }
    static HexFromColor(color, withoutA?: boolean) {
        if (color == null) {
            return null;
        }
        if (color.ColorReference && LayoutService.CSSVariablesMap) {
            const variable = LayoutService.CSSVariablesMap[color.ColorReference];
            if (variable && variable.Value) {
                if (withoutA === true) {
                    if (variable.Value[0] === '#') {
                        if (variable.Value.length > 7) {
                            return variable.Value.substring(0, 7);
                        }
                    } else if (variable.Value.length > 6) {
                        return variable.Value.substring(0, 6);
                    }
                }
                return variable.Value;
            }
        }
        if (color.R == null && color.G == null && color.B == null) {
            return null;
        }
        var retVal = '#';

        if (color.R < 256) {
            if (color.R < 16) {
                retVal += '0';
                if (color.R < 0) {
                    retVal += 0;
                } else {
                    retVal += color.R.toString(16);
                }
            } else {
                retVal += color.R.toString(16);
            }
        } else {
            retVal += 'FF';
        }
        if (color.G < 256) {
            if (color.G < 16) {
                retVal += '0';
                if (color.G < 0) {
                    retVal += 0;
                } else {
                    retVal += color.G.toString(16);
                }
            } else {
                retVal += color.G.toString(16);
            }
        } else {
            retVal += 'FF';
        }
        if (color.B < 256) {
            if (color.B < 16) {
                retVal += '0';
                if (color.B < 0) {
                    retVal += 0;
                } else {
                    retVal += color.B.toString(16);
                }
            } else {
                retVal += color.B.toString(16);
            }
        } else {
            retVal += 'FF';
        }
        if (withoutA !== true && typeof color.A === 'number' && color.A < 256) {
            if (color.A < 16) {
                retVal += '0';
                if (color.A < 0) {
                    retVal += 0;
                } else {
                    retVal += color.A.toString(16);
                }
            } else {
                retVal += color.A.toString(16);
            }
        }
        return retVal;
    }
    static FromObject(color) {
        const retVal = new Color();
        if (color) {
            retVal.A = color.A;
            retVal.R = color.R;
            retVal.G = color.G;
            retVal.B = color.B;
            retVal.ColorReference = color.ColorReference;
        }
        return retVal;
    }
    static ColorToRGBString(color) {
        if (color) {
            if (color.ColorReference && LayoutService.CSSVariablesMap) {
                const variable = LayoutService.CSSVariablesMap[color.ColorReference];
                if (variable && variable.Value) {
                    color = Color.FromHex(variable.Value);
                }
            }
            if (color.R != null && color.G != null && color.B != null) {
                return color.R + ', ' + color.G + ', ' + color.B;
            }
        }
        return '';
    }
    static ColorToAlpha(color) {
        if (color) {
            if (color.ColorReference && LayoutService.CSSVariablesMap) {
                const variable = LayoutService.CSSVariablesMap[color.ColorReference];
                if (variable && variable.Value) {
                    color = Color.FromHex(variable.Value);
                }
            }
            if (color.A != null) {
                return (Math.round((color.A / 255) * 100) / 100) + '';
            }
        }
        return '';
    }

    static GetStyleText(color) {
        if (color) {
            if (color.ColorReference && LayoutService.CSSVariablesMap) {
                const variable = LayoutService.CSSVariablesMap[color.ColorReference];
                if (variable) {
                    return 'var(' + variable.VariableName + ')';
                }
            }
            if (color.R == null && color.G == null && color.B == null) {
                return '';
            }
            if (color.A == null) {
                return 'rgb(' + color.R + ', ' + color.G + ', ' + color.B + ')';
            }
            return 'rgba(' + color.R + ', ' + color.G + ', ' + color.B + ', ' + (Math.round((color.A / 255) * 100) / 100) + ')';
        }
        return '';
    }
}
