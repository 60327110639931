import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'scroll-to-settings',
    templateUrl: './scroll.to.settings.html'
})
export class ScrollToSettings extends WorkflowDialogContent {
    Data = new ScrollToSettingsData();

    constructor() {
        super();
        this.HasExpressions = true;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ScrollToSettingsData, json);
        }
    }

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'scrollToModule';
        reg.Caption = '@@ScrollTo';
        reg.GroupID = 'layout';
        reg.Index = 60;
        reg.SettingsControl = ScrollToSettings;
        reg.SettingsTypeHelper = new ScrollToSettingsDataHelper();
        reg.Executer = ScrollToModulExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }


    getResult(): any {
        return this.Data;
    }

    GetExpressionProperties() {
        return [{
            Value: 'ElemName'
        }];
    }
}

export class ScrollToSettingsData {
    ElemName: string;
    SmoothScroll: boolean = true;
    ScrollToPosition: boolean = false;
    X: number;
    Y: number;
}

export class ScrollToSettingsDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new ScrollToSettingsData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.ElemName) {
            return settings.ElemName;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        if (settings && settings.ElemName === oldName) {
            settings.ElemName = newName;
            return true;
        }
        return false;
    }
}

export class ScrollToModulExecuter extends WorkflowModuleExecuter {

    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (typeof status.ActualSettings.ElemName === 'string') {
                const elem = status.WorkflowLayoutService.getElementByName(status.ActualSettings.ElemName);
                if (elem) {

                    const htmlelem = document.getElementById('LE_' + elem.ID);

                    if (htmlelem) {
                        if (status.ActualSettings.ScrollToPosition) {
                            htmlelem.scrollTo({
                                left: status.ActualSettings.X,
                                top: status.ActualSettings.Y,
                                behavior: status.ActualSettings.SmoothScroll ? "smooth" : "auto"
                            });
                        } else {
                            htmlelem.scrollIntoView(status.ActualSettings.SmoothScroll ? { behavior: "smooth", block: "end", inline: "nearest" } : null);
                        }
                        
                        return 0;
                    } else {
                        status.Logger.logError('ScrollTo modul: HTML Element not found.');
                    }
                } else {
                    status.Logger.logError('ScrollTo modul: Element not found.');
                }
            } else {
                status.Logger.logError('ScrollTo modul: No element name found.');
            }
        } else {
            status.Logger.logError('ScrollTo modul: No settings found.');
        }
        return super.execute(status);
    }
}
