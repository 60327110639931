import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { APropertyTab } from '../../../appbuilder/menutabs/properties/properties.menu.tab';
import { EnumHelper } from '../../../helpers/enum.helper';
import { LayoutHelper } from '../../../helpers/layout.helper';
import {
    AnnotationChartSettings, AxisSettings, BubbleChartSettings, CalendarChartSettings, ChartSettings, ColorAxisValue,
    ColorRangeValue, ContinuousAxisSettings, GeoChartSettings, HeatMapChartSettings, LegendSettings, SeriesSettings
} from '../../../models/controls/chart.model';
import { LevelNode } from '../../../models/datadescription/multi/levelnode.model';
import { MeasureInfo } from '../../../models/datadescription/multi/measureinfo.model';
import { ChartGeoResolution, ChartStackType } from '../../../models/enums/chartcurvetype.enum';
import { ChartLegendPosition } from '../../../models/enums/chartlegendposition.enum';
import { ChartType } from '../../../models/enums/charttype.enum';
import { SeriesType } from '../../../models/enums/seriestype.enum';
import { Color } from '../../../models/style/color.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';

@Component({
    selector: 'chartsreportobject-menu-tab',
    templateUrl: './chart.menu.tab.html',
    styleUrls: ['./chart.menu.tab.css']
})
export class ChartReportObjectMenuTab implements OnInit, OnDestroy {

    Subscriptions = [];
    DataBindingChangedSubscription;

    get LegendPosition(): ChartLegendPosition {
        if (this.ChartSettingsValue && this.ChartSettingsValue.Legend) {
            return this.ChartSettingsValue.Legend.Position;
        }
        return ChartLegendPosition.right;
    }
    set LegendPosition(val: ChartLegendPosition) {
        if (this.ChartSettingsValue) {
            if (!this.ChartSettingsValue.Legend) {
                this.ChartSettingsValue.Legend = new LegendSettings();
            }
            this.ChartSettingsValue.Legend.Position = val;
        }
    }

    get MaxBubbleSize(): number {
        if (this.ChartSettingsValue && this.ChartSettingsValue.BubbleSettings && typeof this.ChartSettingsValue.BubbleSettings.MaxBubbleSize == 'number') {
            return this.ChartSettingsValue.BubbleSettings.MaxBubbleSize;
        }
        return 30;
    }
    set MaxBubbleSize(val: number) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.BubbleSettings) {
            this.ChartSettingsValue.BubbleSettings.MaxBubbleSize = val;
        }
    }
    get MinBubbleSize(): number {
        if (this.ChartSettingsValue && this.ChartSettingsValue.BubbleSettings && typeof this.ChartSettingsValue.BubbleSettings.MinBubbleSize == 'number') {
            return this.ChartSettingsValue.BubbleSettings.MinBubbleSize;
        }
        return 5;
    }
    set MinBubbleSize(val: number) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.BubbleSettings) {
            this.ChartSettingsValue.BubbleSettings.MinBubbleSize = val;
        }
    }

    //#region SelectedItem
    SelectedItemValue;

    @Input()
    get SelectedItem() {
        return this.SelectedItemValue;
    }
    set SelectedItem(val) {
        this.SelectedItemValue = val;
        this.ReadQueries();
        this.SelectedItemChange.emit(this.SelectedItemValue);
    }

    @Output() SelectedItemChange = new EventEmitter<any>();
    //#endregion

    ChartSettingsValue: ChartSettings = ChartSettings.GetDefault();
    Layout;
    DefaultAxisColumns = [];
    NumericColumns = [];
    TextColumns = [];
    DateColumns = [];
    SeriesAdditionalAxisMapping = [];
    charttypes;
    resolutions;
    AxisIsDateColumn = false;
    Data;
    ChartPalettes = [];

    DefaultChartStackType;

    private static CheckPropList(col: SeriesSettings, list: { Prop: string, List: any[] }[]) {
        if (col && list) {
            list.forEach(x => {
                const colName = col[x.Prop];
                if (colName && !x.List.some(y => y.Name === colName)) {
                    col[x.Prop] = null;
                }
            });
        }
    }

    constructor(private service: MetaService) {
        const ct = [];
        [ChartType.AreaChart, ChartType.BarChart, ChartType.BubbleChart, ChartType.ColumnChart, ChartType.ComboChart,
            ChartType.CandlestickChart, ChartType.DonutChart, ChartType.Histogram, ChartType.LineChart, ChartType.PieChart,
            ChartType.ScatterChart, ChartType.SteppedAreaChart, ChartType.Gauge, ChartType.radar, ChartType.polarArea,
            ChartType.radialBar, ChartType.heatmap].forEach(x => {
                ct.push({ type: ChartType[ChartType[x]], value: ChartType[x] });
            });
        this.charttypes = ct;
        this.resolutions = EnumHelper.GetDropdownValues(ChartGeoResolution);
        this.DefaultChartStackType = EnumHelper.GetDropdownValues(ChartStackType);
    }

    ngOnInit(): void {
        this.Subscriptions.push(LayoutService.SelectedItem.subscribe((item) => {
            if (this.DataBindingChangedSubscription != null) {
                this.DataBindingChangedSubscription.unsubscribe();
                this.DataBindingChangedSubscription = null;
            }
            this.SelectedItem = item;
            if (this.SelectedItemValue.DataBindingChanged) {
                this.DataBindingChangedSubscription = this.SelectedItemValue.DataBindingChanged.subscribe((Property) => {
                    this.ReadQueries();
                });
            }
            this.onItemSet();
        }));
        this.Subscriptions.push(LayoutService.SelectedLayout.subscribe((layout) => {
            this.Layout = LayoutHelper.GetActiveResolution(layout);
            this.ReadQueries();
        }));
        this.service.ReadPaletteInfos().subscribe(x => {
            if (x) {
                this.ChartPalettes = x;
            }
        });
    }

    ngOnDestroy() {
        this.Subscriptions.forEach(x => { x.unsubscribe(); });
    }

    UsePaletteForSingleSeriesChanged() {
        if (this.SelectedItemValue && this.SelectedItemValue.ValuesChanged) {
            this.SelectedItemValue.ValuesChanged.next('UsePaletteForSingleSeries');
        }
    }

    ReadQueries() {
        let data;
        if (this.Layout && this.Layout.Datadescriptions && this.Layout.Datadescriptions.length > 0 &&
            this.SelectedItem && this.SelectedItem.Query) {
            data = this.Layout.Datadescriptions.find(x => x.ID === this.SelectedItem.Query);
        }
        this.Data = data;
        this.CreateColumns();
    }

    CreateColumns() {
        const defCols = [];
        const numCols = [];
        const textCols = [];
        const dateCols = [];
        const axisMappings = [];
        if (this.Data && this.Data.Datadescription) {
            if (this.Data.IsRelational) {
                if (this.Data.Datadescription.Fields && this.Data.Datadescription.Fields.length > 0) {
                    this.Data.Datadescription.Fields.forEach((column) => {
                        const col = {
                            Caption: column.Caption,
                            Name: '' + column.ID
                        };
                        if (!column.Type || column.Type === 'System.Double' || column.Type === 'System.Int16' ||
                            column.Type === 'System.Int32' || column.Type === 'System.Int64' || column.Type === 'System.Decimal') {
                            numCols.push(col);
                            defCols.push(col);
                        } else if (column.Type === 'System.String') {
                            textCols.push(col);
                            defCols.push(col);
                        } else if (column.Type === 'System.DateTime') {
                            dateCols.push(col);
                            defCols.push(col);
                        }
                    });
                }
            } else {
                let found = false;
                if (this.Data.Datadescription.YLevelNodes && this.Data.Datadescription.YLevelNodes.Areas) {
                    let seriesMap = {};
                    if (this.SelectedItemValue && this.SelectedItemValue.AdditionalAxisMapping) {
                        const list = this.SelectedItemValue.AdditionalAxisMapping[this.Data.ID];
                        if (list) {
                            seriesMap = list;
                        }
                    }
                    this.Data.Datadescription.YLevelNodes.Areas.forEach(area => {
                        const captions = [];
                        let lnID = '-1';
                        if (area.Tuples && area.Tuples.length > 0) {
                            area.Tuples.some(t => {
                                if (t.Levels && t.Levels.length > 0) {
                                    t.Levels.forEach(l => {
                                        const lev = plainToClass(LevelNode, l);
                                        captions.push(lev.toString());
                                        lnID = '' + lev.UniqueID;
                                    });
                                    return true;
                                }
                                return false;
                            });
                        }
                        if (area.Measures && area.Measures.length > 0) {
                            found = true;
                            area.Measures.forEach(m => {
                                const mi = plainToClass(MeasureInfo, m);
                                // Hier vielleicht noch auf Measure-Typ pr�fen
                                numCols.push({
                                    Caption: mi.toString(),
                                    Name: '' + mi.UniqueID
                                });
                                const amKey = lnID + '_' + mi.UniqueID;
                                axisMappings.push({
                                    Caption: [...captions, mi.Caption].join(' - '),
                                    Key: amKey,
                                    Selected: seriesMap[amKey] === true
                                });
                            });
                        } else if (captions.length > 0) {
                            const amKey = lnID + '_';
                            axisMappings.push({
                                Caption: [...captions].join(' - '),
                                Key: amKey,
                                Selected: seriesMap[amKey] === true
                            });
                        }
                    });
                }
                if (!found && this.Data.Datadescription.XLevelNodes && this.Data.Datadescription.XLevelNodes.Areas) {
                    this.Data.Datadescription.XLevelNodes.Areas.forEach(area => {
                        if (area.Measures && area.Measures.length > 0) {
                            found = true;
                            area.Measures.forEach(m => {
                                const mi = plainToClass(MeasureInfo, m);
                                // Hier vielleicht noch auf Measure-Typ pr�fen
                                numCols.push({
                                    Caption: mi.toString(),
                                    Name: '' + mi.UniqueID
                                });
                            });
                        }
                    });
                }
            }
        }
        this.DefaultAxisColumns = defCols;
        this.NumericColumns = numCols;
        this.TextColumns = textCols;
        this.DateColumns = dateCols;
        this.SeriesAdditionalAxisMapping = axisMappings;
        this.updateChartType();
    }

    onItemSet() {
        if (this.SelectedItem) {
            this.ReadQueries();
            if (!this.SelectedItem.FormatTasks) {
                this.SelectedItem.FormatTasks = [];
            }
            if (!this.SelectedItem.DataTasks) {
                this.SelectedItem.DataTasks = [];
            }
            if (this.SelectedItem.ChartSettings) {
                this.SelectedItem.ChartSettings = plainToClass(ChartSettings, this.SelectedItem.ChartSettings);
            } else {
                this.SelectedItem.ChartSettings = ChartSettings.GetDefault();
            }
            this.ChartSettingsValue = this.SelectedItem.ChartSettings;
        } else {
            this.ChartSettingsValue = ChartSettings.GetDefault();
        }
        if (!this.ChartSettingsValue.LabelAxis) {
            this.ChartSettingsValue.LabelAxis = new AxisSettings();
        }
        if (this.ChartSettingsValue.ValueAxes) {
            while (this.ChartSettingsValue.ValueAxes.length < 2) {
                this.ChartSettingsValue.ValueAxes.push(new ContinuousAxisSettings());
            }
        } else {
            this.ChartSettingsValue.ValueAxes = [new ContinuousAxisSettings(), new ContinuousAxisSettings()];
        }
        this.updateChartType();
    }

    axisMappingChanged(item) {
        if (item && this.SelectedItemValue && this.Data) {
            if (item.Selected) {
                let list;
                if (this.SelectedItemValue.AdditionalAxisMapping) {
                    list = this.SelectedItemValue.AdditionalAxisMapping[this.Data.ID];
                    if (!list) {
                        list = {};
                        this.SelectedItemValue.AdditionalAxisMapping[this.Data.ID] = list;
                    }
                } else {
                    list = {};
                    this.SelectedItemValue.AdditionalAxisMapping = {};
                    this.SelectedItemValue.AdditionalAxisMapping[this.Data.ID] = list
                }
                list[item.Key] = true;
                this.optionChanged('TargetAxis');
            } else {
                if (this.SelectedItemValue.AdditionalAxisMapping) {
                    const list = this.SelectedItemValue.AdditionalAxisMapping[this.Data.ID];
                    if (list) {
                        delete list[item.Key];
                        this.optionChanged('TargetAxis');
                    }
                }
            }
        }
    }

    updateChartType() {
        if (this.Data) {
            const axisColumn = this.ChartSettingsValue['AxisColumn'];
            switch (this.ChartSettingsValue.ChartType) {
                case ChartType.AnnotationChart:
                    if (!this.ChartSettingsValue.AnnotationSettings) {
                        this.ChartSettingsValue.AnnotationSettings = new AnnotationChartSettings();
                    }
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DateColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [
                                { Prop: 'ColumnName', List: this.NumericColumns },
                                { Prop: 'AnnotationTitleColumn', List: this.TextColumns },
                                { Prop: 'AnnotationTextColumn', List: this.TextColumns },
                            ]);
                        });
                    }
                    break;
                case ChartType.AreaChart:
                case ChartType.Bar:
                case ChartType.BarChart:
                case ChartType.ColumnChart:
                case ChartType.Line:
                case ChartType.LineChart:
                case ChartType.ScatterChart:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.Histogram:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.polarArea:
                case ChartType.radar:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.heatmap:
                    if (!this.ChartSettingsValue.HeatMapSettings) {
                        this.ChartSettingsValue.HeatMapSettings = new HeatMapChartSettings();
                    }
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                    break;
                case ChartType.ComboChart:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            if (col.Type === SeriesType.candlesticks) {
                                ChartReportObjectMenuTab.CheckPropList(col, [
                                    { Prop: 'Minimum', List: this.NumericColumns },
                                    { Prop: 'Initial', List: this.NumericColumns },
                                    { Prop: 'Final', List: this.NumericColumns },
                                    { Prop: 'Maximum', List: this.NumericColumns }
                                ]);
                            } else {
                                ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                            }
                        });
                    }
                    break;
                case ChartType.BubbleChart:
                    if (!this.ChartSettingsValue.BubbleSettings) {
                        this.ChartSettingsValue.BubbleSettings = new BubbleChartSettings();
                    }
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, []);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.TextColumns },
                            { Prop: 'XValue', List: this.NumericColumns },
                            { Prop: 'YValue', List: this.NumericColumns },
                            { Prop: 'Radius', List: this.NumericColumns },
                            { Prop: 'Grouping', List: this.NumericColumns.concat(this.TextColumns) }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.PieChart:
                case ChartType.DonutChart:
                case ChartType.Gauge:
                case ChartType.radialBar:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.GeoChart:
                    if (!this.ChartSettingsValue.GeoSettings) {
                        this.ChartSettingsValue.GeoSettings = new GeoChartSettings();
                    }
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Map:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0],
                            [{ Prop: 'ColumnName', List: this.DefaultAxisColumns }]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.SteppedAreaChart:
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.TextColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.WordTree:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'LabelColumn', List: this.TextColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'ColumnName', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.OrgChart:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'Description', List: this.DefaultAxisColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.TreeMap:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'IDColumn', List: this.DefaultAxisColumns },
                            { Prop: 'ParentID', List: this.DefaultAxisColumns },
                            { Prop: 'ColumnName', List: this.NumericColumns },
                            { Prop: 'ColorValue', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Calendar:
                    if (!this.ChartSettingsValue.CalendarSettings) {
                        this.ChartSettingsValue.CalendarSettings = new CalendarChartSettings();
                    }
                    if (axisColumn) {
                        ChartReportObjectMenuTab.CheckPropList(axisColumn, [{ Prop: 'ColumnName', List: this.DateColumns }]);
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'ColumnName', List: this.NumericColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }

                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [{ Prop: 'ColumnName', List: this.NumericColumns }]);
                        });
                    }
                    break;
                case ChartType.Gantt:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'TaskID', List: this.TextColumns },
                            { Prop: 'TaskName', List: this.TextColumns },
                            { Prop: 'Grouping', List: this.TextColumns },
                            { Prop: 'StartValue', List: this.DateColumns },
                            { Prop: 'EndValue', List: this.DateColumns },
                            { Prop: 'Duration', List: this.NumericColumns },
                            { Prop: 'Completed', List: this.NumericColumns },
                            { Prop: 'Dependency', List: this.TextColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.Timeline:
                    if (this.ChartSettingsValue.Series && this.ChartSettingsValue.Series.length > 0) {
                        const concatList = this.DateColumns.concat(this.NumericColumns);
                        ChartReportObjectMenuTab.CheckPropList(this.ChartSettingsValue.Series[0], [
                            { Prop: 'ColumnName', List: this.TextColumns },
                            { Prop: 'StartValue', List: concatList },
                            { Prop: 'EndValue', List: concatList },
                            { Prop: 'LabelColumn', List: this.TextColumns }
                        ]);
                    } else {
                        this.ChartSettingsValue.Series = [new SeriesSettings()];
                    }
                    break;
                case ChartType.CandlestickChart:
                    if (axisColumn) {
                        const colName = axisColumn.ColumnName;
                        if (colName && !this.DefaultAxisColumns.some(x => x.Name === colName)) {
                            axisColumn.ColumnName = null;
                        }
                    } else {
                        this.ChartSettingsValue['AxisColumn'] = {};
                    }
                    this.checkAxisDateColumn();
                    if (this.ChartSettingsValue.Series) {
                        this.ChartSettingsValue.Series.forEach(col => {
                            ChartReportObjectMenuTab.CheckPropList(col, [
                                { Prop: 'Minimum', List: this.NumericColumns },
                                { Prop: 'Initial', List: this.NumericColumns },
                                { Prop: 'Final', List: this.NumericColumns },
                                { Prop: 'Maximum', List: this.NumericColumns }
                            ]);
                        });
                    }
                    break;
            }
        }
    }

    optionChanged(prop: string) {
        if (this.SelectedItemValue && this.SelectedItemValue.Element && typeof this.SelectedItemValue.Element.ChartPropChanged === 'function') {
            this.SelectedItemValue.Element.ChartPropChanged(prop);
        }
    }

    onChartTypeChanged() {
        this.updateChartType();
        this.optionChanged('ChartType');
    }

    addSeriesColumn() {
        if (this.ChartSettingsValue.Series) {
            this.ChartSettingsValue.Series.push(new SeriesSettings());
        } else {
            this.ChartSettingsValue.Series = [new SeriesSettings()];
        }
    }

    deleteSeriesColumn(i) {
        if (this.ChartSettingsValue.Series) {
            this.ChartSettingsValue.Series.splice(i, 1);
            this.optionChanged('Series');
        }
    }

    onDefaultAxisChanged() {
        if (this.ChartSettingsValue) {
            const axisCol = this.ChartSettingsValue['AxisColumn'];
            const colName = axisCol.ColumnName;
            const col = this.DefaultAxisColumns.find(x => x.Name === colName);
            if (col) {
                if (!this.ChartSettingsValue.LabelAxis) {
                    this.ChartSettingsValue.LabelAxis = new AxisSettings();
                }
                this.ChartSettingsValue.LabelAxis.Title = col.TranslatedCaption;
            }
            this.checkAxisDateColumn();
        }
        this.optionChanged('AxisColumn');
    }

    addColor(list) {
        if (list) {
            list.push(new ColorAxisValue());
        }
    }

    getColor(series) {
        if (series && typeof series.Color === 'string') {
            return Color.FromHex(series.Color);
        }
        return new Color();
    }

    changeColor(series: SeriesSettings) {
        if (series) {
            const changedColor = series['ChangedColor'];
            if (changedColor) {
                series.Color = Color.HexFromColor(changedColor);
                this.optionChanged('Series.Color');
                delete series['ChangedColor'];
            }
        }
    }

    clearColor(series: SeriesSettings) {
        if (series) {
            series.Color = null;
            this.optionChanged('Series.Color');
        }
    }

    addColorRange() {
        if (this.ChartSettingsValue && this.ChartSettingsValue.HeatMapSettings) {
            if (this.ChartSettingsValue.HeatMapSettings.ColorValues) {
                this.ChartSettingsValue.HeatMapSettings.ColorValues.push(new ColorRangeValue());
            } else {
                this.ChartSettingsValue.HeatMapSettings.ColorValues = [new ColorRangeValue()];
            }
        }
    }

    deleteColorRange(i) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.HeatMapSettings && this.ChartSettingsValue.HeatMapSettings.ColorValues) {
            this.ChartSettingsValue.HeatMapSettings.ColorValues.splice(i, 1);
            this.optionChanged('HeatMapSettings.ColorValues');
        }
    }

    checkAxisDateColumn() {
        const axisColumn = this.ChartSettingsValue['AxisColumn'];
        this.AxisIsDateColumn = axisColumn && axisColumn.ColumnName &&
            this.DateColumns.concat(this.NumericColumns).some(x => x.Name === axisColumn.ColumnName);
    }

    addPaletteColor() {
        if (this.ChartSettingsValue) {
            if (this.ChartSettingsValue.SeriesColors) {
                this.ChartSettingsValue.SeriesColors.push('#000000');
            } else {
                this.ChartSettingsValue.SeriesColors = ['#000000'];
            }
            this.optionChanged('SeriesColors');
        }
    }
    clearPalette() {
        if (this.ChartSettingsValue) {
            this.ChartSettingsValue.SeriesColors = [];
            this.optionChanged('SeriesColors');
        }
    }
    DropPaletteEntry(event) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.SeriesColors && event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.ChartSettingsValue.SeriesColors, event.previousIndex, event.currentIndex);
            this.optionChanged('SeriesColors');
        }
    }
    paletteColorChanged(event, i) {
        if (this.ChartSettingsValue && this.ChartSettingsValue.SeriesColors && i < this.ChartSettingsValue.SeriesColors.length) {
            if (event == null) {
                this.ChartSettingsValue.SeriesColors.splice(i, 1);
            } else {
                this.ChartSettingsValue.SeriesColors.splice(i, 1, Color.HexFromColor(event, true));
            }
            this.optionChanged('SeriesColors');
        }
    }
    getPaletteColor(col) {
        return Color.FromHex(col);
    }
}

@Component({
    selector: 'chartsreportobject-property-menu-tab',
    template: '<chartsreportobject-menu-tab [(SelectedItem)]="SelectedItem"></chartsreportobject-menu-tab>'
})
export class ChartPropertyMenuTab extends APropertyTab {
    static CheckVisible(): boolean {
        const selected = LayoutService.SelectedItem.getValue();
        if (selected && selected.ElementType === 'chartreportObject') {
            return true;
        }
        return false;
    }
}
