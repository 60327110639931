import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class DynamicDataService extends RequestBase {
    BasePath = 'api/dynamicdata';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    Save(classid, data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/Save?classId=' + classid, data, this.options);
    }

    ForceSave(classid, data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ForceSave?classId=' + classid, data, this.options);
    }

    SaveEntry(classid, data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveEntry?classId=' + classid, data, this.options);
    }

    ForceSaveEntry(classid, data): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ForceSaveEntry?classId=' + classid, data, this.options);
    }

    Delete(classid, id, version): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/Delete?classId=' + classid + '&id=' + id + '&version=' + version;
        return this.http.delete<any>(path, this.options);
    }

    DeleteEntry(classid, item): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/DeleteEntry?classId=' + classid, item, this.options);
    }

    CountObjects(classId, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/CountObjects?classId=' + classId, filter, this.options);
    }
    SearchObjects(classId, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SearchObjects?classId=' + classId, filter, this.options);
    }

    DeleteByFilter(classId, filter): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/DeleteByFilter?classId=' + classId, filter, this.options);
    }

    OpenQuery(classId, filter) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/OpenQuery?containerId=' + classId, filter, this.options);
    }

    ExecuteQuery(queryId, start, end) {
        const path = this.API_BASE_URL + this.BasePath + '/ExecuteQuery?queryId=' + queryId + '&startRow=' + start + '&endRow=' + end;
        return this.http.get<any>(path, this.options);
    }

    ExecuteCountQuery(queryId) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/ExecuteCountQuery?queryId=' + queryId, this.options);
    }

    CloseQuery(queryId) {
        return this.http.delete<any>(this.API_BASE_URL + this.BasePath + '/CloseQuery?queryId=' + queryId, this.options);
    }
    ExecuteQueryWithFilter(containerId, filter) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteQuery?containerId=' + containerId, filter, this.options);
    }

    ExecuteObjectQuery(containerId, filter) {
        const path = this.API_BASE_URL + this.BasePath + '/ExecuteObjectQuery?containerId=' + containerId;
        return this.http.post<any>(path, filter, this.options);
    }

    ExecuteCount(classId, filter) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/ExecuteCount?containerId=' + classId, filter, this.options);
    }
}
