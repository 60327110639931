import { Component } from '@angular/core';
import { BaseDialog } from './basedialog/base.dialog';

@Component({
    selector: 'translatedstring-dialog',
    templateUrl: './translatedstring.dialog.html'
})
export class TranslatedStringDialogComponent {

  InitArgs;

    static showDialog(translatedString, handler) {
    BaseDialog.ShowDialog({
        ContentType: TranslatedStringDialogComponent,
      Title: 'Umbenennen',
      InitArgs: {
        TranslatedString: translatedString
      },
      Handler: r => {
        if (r && handler) {
          handler(r);
        }
        return true;
      }
    });
  }

  Initialize(args) {
      if (args && args.TranslatedString ) {
      this.InitArgs = {
          TranslatedString: args.TranslatedString
      };
    }
  }

  GetDialogResult() {
    return this.InitArgs;
  }
}
