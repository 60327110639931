export enum Orientation {
  Horizontal,
  Vertical,
  HorizontalWrapping,
  VerticalWrapping,
  HorizontalReverse,
  VerticalReverse,
  HorizontalReverseWrapping,
  VerticalReverseWrapping
}
