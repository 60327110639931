import { PropertyMapper } from '../../../helpers/property.mapper';
import { TranslatedString } from '../../../models/translatedstring.model';
import { RequestType } from './auth.type';
import { EndpointParameter, EndpointRequestBody, EndpointResponseBody } from './end.point.description';

export class EndPointRestItem{
    public SID: string;
    public Caption: TranslatedString;
    public Description: string;
    public RequestType = RequestType.GET;
    public EndPoint: string;
    public RestID: string;
    public Parameters: EndpointParameter[];
    public RequestBody: EndpointRequestBody;
    public Responses: EndpointResponseBody[];

    constructor(value: EndPointRestItem = undefined) {
        if (value) {
            PropertyMapper.MapProperty(value, this);
        }
    }
}
