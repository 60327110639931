import { WorkflowDialogContent } from '../../workflow.dialog';
import { Component } from '@angular/core';
import { AServiceWorkflowData, WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowRegistry, WorkflowModuleSettingsHelper, WorkflowExitInfo } from '../../../services/workflow.service';

@Component({
    selector: 'setWFStatus-settings',
    templateUrl: './setWFStatus.settings.html',
    styleUrls: ['./setWFStatus.settings.css']
})
export class SetWorkflowStatusSettings extends WorkflowDialogContent {
    ActualStatusKey = '';
    KeepParam = true;

    static ModuleID = 'setStatusWFModule';

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = SetWorkflowStatusSettings.ModuleID;
        reg.Caption = '@@Statuswert setzen';
        reg.GroupID = 'wfStatus';
        reg.Index = 10;
        reg.SettingsControl = SetWorkflowStatusSettings;
        reg.SettingsTypeHelper = new SetWorkflowStatusDataHelper();
        reg.Executer = SetWorkflowStatusExecuter;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            if (data.StatusKey) {
                this.ActualStatusKey = data.StatusKey;
            }
            if (typeof data.KeepActualParam === 'boolean') {
                this.KeepParam = data.KeepActualParam;
            }
        }
    }

    getResult(): any {
        const retVal = new SetWorkflowStatusData();
        retVal.StatusKey = this.ActualStatusKey;
        retVal.KeepActualParam = this.KeepParam;
        return retVal;
    }
}

export class SetWorkflowStatusData extends AServiceWorkflowData {
    StatusKey = '';
    KeepActualParam = true;
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.WFStatus.SetWorkflowStatusData';
    }
}

export class SetWorkflowStatusDataHelper extends WorkflowModuleSettingsHelper {

    getEmptySettingsInstance() {
        return new SetWorkflowStatusData();
    }

    getAdditionalCaption(settings) {
        if (settings && settings.StatusKey) {
            return settings.StatusKey;
        }
        return super.getAdditionalCaption(settings);
    }

    getExitPoints(settings): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.StatusKey) {
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, [settings.StatusKey]);
            }
        }
    }
}

export class SetWorkflowStatusExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.StatusKey) {
                status.Context.set(status.ActualSettings.StatusKey, status.ActualParameter);
                if (!status.ActualSettings.KeepActualParam) {
                    status.ActualParameter = null;
                }
                return 0;
            } else {
                status.Logger.logError('SetStatus modul: No status key found.');
            }
        } else {
            status.Logger.logError('SetStatus modul: No settings found.');
        }
        return super.execute(status);
    }
}
