import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';


@Injectable()
export class DataSettingsService extends RequestBase {
    BasePath = 'api/selfservice/datasettings';

    GetContainerTables(dataModel): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetContainerTables?dataModel=' + dataModel, this.options);
    }

    GetContainerTablesByDataSource(dataModel, dataSource, isRelational, isExport) {
        const path = this.API_BASE_URL + this.BasePath + '/GetContainerTablesByDataSource?dataModel=' + dataModel +
            '&dataSource=' + dataSource + '&isRelational=' + isRelational + '&isExport=' + isExport;
        return this.http.get<any>(path, this.options);
    }

    GetContainerTableTypes(dataSource, isRelational, isExport): Observable<any> {
        const path = this.API_BASE_URL + this.BasePath + '/GetContainerTableTypes?dataSource=' + dataSource +
            '&isRelational=' + isRelational + '&isExport=' + isExport;
        return this.http.get<any>(path, this.options);
    }

    CheckExistsTable(tableName: string, schema: string, datasource: string) {
        const path = this.API_BASE_URL + this.BasePath + '/CheckExistsTable?tableName=' + tableName +
            '&schema=' + schema + '&datasource=' + datasource;
        return this.http.get<any>(path, this.options);
    }

    SaveContainerTable(request) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveContainerTable', request, this.options);
    }

    DeleteContainerTable(sid: string, dropTable: boolean) {
        const path = this.API_BASE_URL + this.BasePath + '/DeleteContainerTable?sid=' + sid + '&dropTable=' + dropTable;
        return this.http.delete<any>(path, this.options);
    }

    GetRelationInfosForContainer(cont: string) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetRelationInfosForContainer?container=' + cont, this.options);
    }

    SaveRelation(relSaveReq) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveRelation', relSaveReq, this.options);
    }

    GetRelation(sid: string) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetRelation?sid=' + sid, this.options);
    }

    GetAllRelations(dataModel) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllRelations?dataModel=' + dataModel, this.options);
    }

    GetDataPreview(sid: string) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetDataPreview?sid=' + sid, this.options);
    }

    SaveDataPreview(saveReq) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveDataPreview', saveReq, this.options);
    }

    GetAllTableInfos(dataModel) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllTableInfos?dataModel=' + dataModel, this.options);
    }

    GetTableInfosByDataSource(dataSource) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetTableInfosByDataSource?dataSource=' + dataSource, this.options);
    }

    SaveContainerDimension(dim) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveContainerDimension', dim, this.options);
    }

    GetAllContainerDimensions(dataModel) {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllContainerDimensions?dataModel=' + dataModel, this.options);
    }

    SaveContainerCube(cube) {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/SaveContainerCube', cube, this.options);
    }

    GetAllDeltaFilterInfos() {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllDeltaFilterInfos', this.options);
    }

    Process(datasourceID, processId): Observable<any> {
        const path = this.API_BASE_URL + 'api/queryengine/multi/Process?datasourceID=' + datasourceID + '&processId=' + processId;
        return this.http.get<any>(path, this.options);
    }
}
