import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBase } from './request-base';

@Injectable()
export class DebugService extends RequestBase {
    BasePath = 'api/debug';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetWorkflow(id, parent): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetWorkflow?id=' + id + '&parent=' + parent, this.options);
    }

    GetAllWorkflows(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetAllWorkflows', this.options);
    }
}
