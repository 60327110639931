import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WorkflowDebugHelper } from '../helpers/workflow.debug.helper';

@Component({
    selector: 'debug-window',
    templateUrl: './debug.window.html',
    styleUrls: ['./debug.window.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugWindow implements OnInit {
    Active = false;
    Fullscreen = false;
    dragPosition;
    SelectedIndex = 0;

    private static DebugVisible = new BehaviorSubject<boolean>(false);

    static SwitchDebug() {
        const visible = DebugWindow.DebugVisible.getValue();
        DebugWindow.DebugVisible.next(!visible);
    }

    constructor(private cdRef: ChangeDetectorRef) {
        WorkflowDebugHelper.DebugState.subscribe(x => {
            if (x.Type === 'WorkflowState') {
                this.SelectedIndex = 1;
                this.Active = true;
                this.cdRef.detectChanges();
            }
        });
    }

    ngOnInit(): void {
        this.dragPosition = {
            x: Math.max(0, window.innerWidth - 610),
            y: 10
        };
        DebugWindow.DebugVisible.subscribe(x => {
            this.Active = x;
            this.cdRef.detectChanges();
        });
    }

    switchFullScreen() {
        this.Fullscreen = !this.Fullscreen;
        this.cdRef.detectChanges();
    }

    close() {
        DebugWindow.DebugVisible.next(false);
    }
}
