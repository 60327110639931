import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumHelper } from '../../helpers/enum.helper';
import { ResourceBase } from '../../models/enums/resourcebase.enum';
import { RelSourceService } from '../../services/relSource.service';

@Component({
    selector: 'containerSelect-dialog',
    templateUrl: 'containerSelect.dialog.html',
    styleUrls: ['./containerSelect.dialog.css']
})
export class ContainerSelectDialog implements OnInit {
    ResourceBaseTypes = [];
    ContainerTypes = [];
    count = 0;
    Query;
    _SearchValue = '';


    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterContainer();
    }

    _ActualContainerType;
    get ActualContainerType() {
        return this._ActualContainerType;
    }
    set ActualContainerType(val) {
        this._ActualContainerType = val;
        this.FilterContainer();
    }

    _ActualResourceBaseEnumType;
    get ActualResourceBaseEnumType() {
        return this._ActualResourceBaseEnumType;
    }
    set ActualResourceBaseEnumType(val) {
        this._ActualResourceBaseEnumType = val;
        this.FilterContainer();
    }

    @Input()
    InitArgsQuery: any;

    Container = [];
    AllContainers = [];
    @Output() CloseClicked = new EventEmitter<any>();

    constructor(private service: RelSourceService) {
        this.ResourceBaseTypes = EnumHelper.GetDropdownValues(ResourceBase);
    }

    close() { }

    ngOnInit(): void {
        if (this.InitArgsQuery && this.InitArgsQuery.Query) {
            this.service.GetAllContainerTypes(this.InitArgsQuery.Query.DataModelID).subscribe(r => {
                if (r) {
                    this.ContainerTypes = r;
                }
            });
            this.service.GetRelContainer(this.InitArgsQuery.Query).subscribe(r => {
                if (r) {
                    this.AllContainers = r;
                    this.Container = r;
                }
            });
        }
    }

    Initialize(args) {
        if (args && args.Query) {
            this.Query = args.Query;
        }
    }

    GetDialogResult() {
    }

    FilterContainer() {
        let searchVal;
        if (this._SearchValue) {
            searchVal = this._SearchValue.toLowerCase();
        }
        this.Container = this.AllContainers.filter(cont =>
            (this._ActualResourceBaseEnumType == null || cont.ResourceBase == this._ActualResourceBaseEnumType.type) &&
            (this._ActualContainerType == null || cont.ContainerType == this._ActualContainerType.IdGuid) &&
            (!searchVal || cont.Caption.toLowerCase().indexOf(searchVal) !== -1));
    }

    dragStart(event, item) {
        event.dataTransfer.setData('container', JSON.stringify(item));
        event.dataTransfer.setData('allcontainer', JSON.stringify(this.AllContainers));
    }

    Close() {
        this.CloseClicked.emit();
    }
}

