import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'not-found',
  styleUrls:['not-found404.page.css'],
  templateUrl: 'not-found404.page.html'
})

export class NotFound404Page {

  constructor(private router: Router) {
  }
  goBack() {
    this.router.navigate(['default/settings/dashboard']);
  }
}
