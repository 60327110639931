import { FormulaStatics, ValueAndType } from "../basic/formulaEditor.model";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { RangeEnabledFormula } from "./iformula";

export class Max extends RangeEnabledFormula {
    static StaticID: string = '52f35fa1-5fba-47c9-b7a3-628eccd86079';

    constructor() {
        super();
        this._ID = Max.StaticID;
        this._Name = 'Max';
        this._MaxVariableCount = FormulaStatics.IntMaxValue;
    }

    CanCalcNulls(): boolean {
        return true;
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = maxType;
        if (args && (maxType == ValueType.Long || maxType == ValueType.Double)) {
            var that = this;
            args.forEach(function (item) {
                if (typeof item.Value !== 'undefined' && item.Value != null) {
                    if (item.Type == ValueType.Range) {
                        that.HandleNumericRange(item, (val) => {
                            if (val != null && !isNaN(val)) {
                                if (retVal.Value == null || val > retVal.Value) {
                                    retVal.Value = val;
                                }
                            }
                        });
                    } else {
                        var val = Number(item.Value);
                        if (!isNaN(val)) {
                            if (retVal.Value == null || val > retVal.Value) {
                                retVal.Value = val;
                            }
                        }
                    }
                }
            });
        }
        return retVal;
    }
}