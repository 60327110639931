import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { Layout } from '../../../models/layout.model';
import { WIDGETREGISTRY } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { NavigationService } from '../../../services/navigation.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-widget',
    templateUrl: './widget.layout.html',
    styleUrls: ['./widget.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLayout extends IBaseComponent {
    static Type: any = 'widget';
    static Default = {
        Editable: true,
        Type: 'widget',
        Layout: {
            Height: {Type: 0, Value: 350},
            Width: {Type: 0, Value: 500},
        }
    };

    @ViewChild('dynamic', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;
    private componentRef;

    constructor(private factoryResolver: ComponentFactoryResolver, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    WidgetRoot;
    NativeComponent;
    InitFired = false;
    Page = NavigationService.SelectedPage.getValue();
    OverlayStatus = false;

    onLayoutElementSet() {
        console.log('LayoutElementValue', this.LayoutElementValue);
        this.WidgetRoot = null;
        const sub = this.Subscriptions['Editable'];
        if (sub) {
            sub.unsubscribe();
        }
        this.Subscriptions['Editable'] = this.LayoutElementValue.EditableChanged.subscribe((value) => {
            this.Editable = value;
        });
        this.Subscriptions['Overlay'] = LayoutService.OverlayStatus.subscribe((value) => {
            this.OverlayStatus = value;
        });
        if (this.LayoutElementValue.NativeComponent != null) {
            if (WIDGETREGISTRY[this.LayoutElementValue.NativeComponent]) {
                this.viewContainerRef.clear();
                this.Initialized = true;
                const control = WIDGETREGISTRY[this.LayoutElementValue.NativeComponent].Control;
                const factory = this.factoryResolver.resolveComponentFactory(control);
                this.componentRef = this.viewContainerRef.createComponent(factory);
                if (this.LayoutElementValue.WidgetProperties) {
                    if (this.componentRef && this.componentRef.instance) {
                        let props = Object.keys(this.LayoutElementValue.WidgetProperties);
                        props.forEach((prop) => {
                            this.componentRef.instance[prop] = this.LayoutElementValue.WidgetProperties[prop];
                        })
                    }
                }
                if (WIDGETREGISTRY[this.LayoutElementValue.NativeComponent].Changes) {
                    WIDGETREGISTRY[this.LayoutElementValue.NativeComponent].Changes.subscribe(() => {
                        if (this.LayoutElementValue.WidgetProperties) {
                            let props = Object.keys(this.LayoutElementValue.WidgetProperties);
                            props.forEach((prop) => {
                                this.componentRef.instance[prop] = this.LayoutElementValue.WidgetProperties[prop];
                            });
                        }
                    });
                }


                this.componentRef.changeDetectorRef.detectChanges();
            }
        } else {
            if (this.LayoutValue == this.LayoutElementValue) {
                const elements = this.LayoutElementValue['Elements'];
                if (elements && elements.length === 1) {
                    this.WidgetRoot = elements[0];
                    this.WidgetRoot.SetViewType(this.LayoutElementValue.ViewType);
                }
                this.onLayoutSet(this.LayoutElementValue);
            } else {
                CacheService.ReadWidget(this.LayoutElementValue._Id).then((value) => {
                    const data = plainToClass(Layout, value);
                    this.onLayoutSet(data);
                    const elements = data['Elements'];
                    if (elements && elements.length === 1) {
                        this.WidgetRoot = elements[0];
                        this.WidgetRoot.SetViewType(this.LayoutElementValue.ViewType);
                        this.LayoutElementValue['Elements'] = elements;
                    }
                    const workflows = data['Workflows'];
                    if (workflows) {
                        this.LayoutElementValue['Workflows'] = workflows;
                    }
                    const variables = data['Variables'];
                    if (variables) {
                        this.LayoutElementValue['Variables'] = variables;
                    }
                    const events = data['CustomEvents'];
                    if (events) {
                        this.LayoutElementValue['CustomEvents'] = events;
                        if (events.length > 0) {
                            events.forEach((event) => {
                                this.EventList.push(event.Name);
                            });
                        }
                        this.fillEvents();
                    }
                    this.onLayoutSet(data);
                });
            }
        }
    }
    private ChangeCopy(copy) {
        copy.ID = UUID.UUID();
        if (copy.Elements) {
            copy.Elements.forEach(x => this.ChangeCopy(x));
        }
    }
    onLayoutSet(layout) {
        if (this.WidgetRoot) {
            let type = '';
            switch (layout['ContentType']) {
                case ContentType.Grid: type = 'grid'; break;
                case ContentType.Flex: type = 'flex'; break;
                case ContentType.Raster: type = 'raster'; break;
                case ContentType.Canvas: type = 'canvas'; break;
                default: type = 'grid'; break;
            }
            this.WidgetRoot['ElementType'] = type;
        }
        if (this.Initialized) {
            this.ControlInitialized();
        }
    }

    ControlInitialized() {
        if (this.WidgetRoot && !this.InitFired) {
            this.InitFired = true;
            const sub = this.WidgetRoot.Initialized.subscribe(x => {
                if (x === true) {
                    sub.unsubscribe();
                    this.setInitialized();
                }
            });
        }
    }

    itemSelected() {
        if (this.WidgetRoot && !this.WidgetRoot.Selected && this.Page != 'widget') {
            LayoutService.SelectedItem.next(this.WidgetRoot);
        }
    }
}
