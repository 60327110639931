import { ChangeDetectionStrategy, Component } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { LayoutChangeType } from '../../../models/enums/layoutchangetype.enum';
import { Layout } from '../../../models/layout.model';
import { LayoutPropertyChangeValue } from '../../../models/layout/layout.change.model';
import { LayoutService } from '../../../services/layout.service';
import { ALayoutMenuTab } from '../../../appbuilder/common/menutabcontrol/menu.tab.control';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'resolution-menu-tab',
    templateUrl: './resolution.menu.tab.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResolutionMenuTab extends ALayoutMenuTab {
    SelectedResolution;
    OldContentLayout
    addResolution() {
        if (this.ContentLayout) {
            // this.OldContentLayout = this.ContentLayout;
            const item = new Layout();
            item['ElementType'] = 'grid';
            item.ContentType = ContentType.Grid;
            item['SubLayout'] = true;
            item.Name = TranslateHelper.TranslatorInstance.instant('@@New resolution');
            if (this.ContentLayout.Workflows) {
                item['Workflows'] = this.ContentLayout.Workflows
            }
            if (this.ContentLayout.Elements) {
                item["Elements"] = this.ContentLayout.Elements;
            }
            if (this.ContentLayout.Resolutions) {
                this.ContentLayout.Resolutions.push(item);
            } else {
                this.ContentLayout.Resolutions = [item];
            }
            this.SelectedResolution = item;
            LayoutService.OnResolutionAdded({
                Resolution: JSON.stringify(item)
            });
        }
    }
    deleteResolution() {
        if (this.SelectedResolution && this.ContentLayout && this.ContentLayout.Resolutions) {
            const index = this.ContentLayout.Resolutions.indexOf(this.SelectedResolution);
            if (index > -1) {
                const resID = this.SelectedResolution.ID;
                let resName = resID;
                if (this.SelectedResolution.Name) {
                    resName = this.SelectedResolution.Name;
                }
                this.ContentLayout.Resolutions.splice(index, 1);
                const length = this.ContentLayout.Resolutions.length;
                if (length > 0) {
                    if (index < length) {
                        this.SelectedResolution = this.ContentLayout.Resolutions[index];
                    } else {
                        this.SelectedResolution = this.ContentLayout.Resolutions[length - 1];
                    }
                } else {
                    this.SelectedResolution = null;
                }
                LayoutService.OnResolutionRemoved({
                    ResolutionID: resID,
                    ResolutionName: resName
                });
            }
        }
    }
    previewResolution() {
        const { FromWidth, ToWidth } = this.SelectedResolution;
        if (FromWidth > ToWidth) {
            LayoutService.ResolutionSelected.next(this.SelectedResolution.ToWidth);
        }
        else {
            LayoutService.ResolutionSelected.next(this.SelectedResolution.FromWidth);
        }

    }
    cancelPreview() {
        // if(this.OldContentLayout!==null){
        //     this.ContentLayout = this.OldContentLayout;
        // }
        LayoutService.ResolutionSelected.next(null);
    }

    private static SetIDs(layout) {
        layout.ID = UUID.UUID();
        if (layout.Elements) {
            layout.Elements.forEach(x => ResolutionMenuTab.SetIDs(x));
        }
    }

    copyLayout() {
        if (this.ContentLayout) {
            const copy = plainToClass(Layout, JSON.parse(JSON.stringify(this.ContentLayout)));
            delete copy['Resolutions'];
            copy.Name = TranslateHelper.TranslatorInstance.instant('@@New resolution');
            ResolutionMenuTab.SetIDs(copy);
            if (this.ContentLayout.Resolutions) {
                this.ContentLayout.Resolutions.push(copy);
            } else {
                this.ContentLayout.Resolutions = [copy];
            }
            this.SelectedResolution = copy;
            LayoutService.OnResolutionAdded({
                Resolution: JSON.stringify(copy)
            });
        }
    }

    onResChanged(prop) {
        if (this.SelectedResolution) {
            const lpcv = new LayoutPropertyChangeValue();
            lpcv.ElementID = this.SelectedResolution.ID;
            lpcv.PropertyName = prop;
            lpcv.Value = this.SelectedResolution[prop];
            LayoutService.LayoutChanged.next({
                LayoutChangeType: LayoutChangeType.PropertyChange,
                ChangeValue: lpcv,
                ResolutionID: this.SelectedResolution.ID
            });
        }
    }
}
