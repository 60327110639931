export enum Alignment {
  Start,
  Center,
  End,
  Stretch
}
export enum FlexItemAlignment {
    'flex-start',
    'flex-end',
    center,
    baseline,
    stretch,
    inherit,
    initial,
    unset
}
export enum FlexContentAlignment {
    'flex-start',
    'flex-end',
    center,
    'space-between',
    'space-around',
    stretch,
    inherit,
    initial,
    unset
}