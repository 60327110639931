export enum ChartCurveType {
    none,
    function
}

export enum ChartStackType {
    false,
    true,
    percent,
    relative,
    absolute
}

export enum ChartTrendlineType {
    linear,
    exponential,
    polynomial
}

export enum ChartPieSliceText {
    none,
    label,
    value,
    percentage
}

export enum ChartGeoResolution {
    countries,
    provinces,
    metros
}
