import { ComponentPortal } from "@angular/cdk/portal";
import { TransitionProperty } from "../../models/enums/transitionproperty.enum";
import { TransitionTimingFunction } from "../../models/enums/transitiontimingfunction.enum";
import { PROPERTIES, PROPERTYGROUPS } from "../../services/dynamic.component.service";
import { ComboboxThemeControl } from "../controls/combobox/combobox.theme.control";
import { ListBoxThemeControl } from "../controls/listbox/listbox.theme.control";
import { TextboxThemeControl } from "../controls/textbox/textbox.theme.control";
import { GenericMenuTab } from "../menutabs/generic/generic.menu.tab";
import { BasePanel } from "./base.panel";

export class TransitionsPanel extends BasePanel {
    static override SIDS = ['d33d298c-38f3-44bc-be99-55cac0c3b775']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'d33d298c-38f3-44bc-be99-55cac0c3b775',
            ID: 'transitions',
            Caption: '@@Transitions',
            Index: 4,
            Content: GenericMenuTab
        });
        PROPERTIES.push({
            ID: "Transitions",
            Parent: "transitions",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Property",
                    Parent: "transitions",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@Property",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        EnumSource: TransitionProperty
                    }
                },
                {
                    ID: "Duration",
                    Parent: "transitions",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Duration",
                    InitArgs: {
                        InputType: 'number',
                        Minimum:0
                    }
                },
                {
                    ID: "TimingFunction",
                    Parent: "transitions",
                    Content: new ComponentPortal(ComboboxThemeControl),
                    Label: "@@TimingFunction",
                    InitArgs: {
                        Placeholder: "@@Select",
                        Multiple: false,
                        EnumSource: TransitionTimingFunction
                    }
                },
                {
                    ID: "Delay",
                    Parent: "transitions",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Delay",
                    InitArgs: {
                        InputType: 'number',
                        Minimum: 0
                    }
                }
            ],
            InitArgs: {
                GenerateNewItem: () => {
                    return {};
                },
                GetCaption(data, i) {
                    let retVal = (i + 1).toString() + '.';
                    if (typeof data.Property === 'number') {
                        retVal += ' ' + TransitionProperty[data.Property];
                    }
                    if (typeof data.Duration === 'number') {
                        retVal += ' ' + data.Duration + 's';
                    }
                    if (typeof data.TimingFunction === 'number') {
                        retVal += ' ' + TransitionTimingFunction[data.TimingFunction];
                    }
                    if (typeof data.Delay === 'number') {
                        retVal += ' ' + data.Delay + 's';
                    }
                    return retVal;
                }
            }
        });
    }
}