import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../workflow.dialog';

@Component({
    selector: 'get-multi-elem-value-settings',
    templateUrl: './get.multi.elem.value.settings.html'
})
export class GetMultiElemValueSettings extends WorkflowDialogContent {
    static ModuleID = 'getMultiElemValueWFModule';

    Data = new GetMultiElemValueSettingsData();

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = GetMultiElemValueSettings.ModuleID;
        reg.Caption = '@@Werte auslesen';
        reg.GroupID = 'layout';
        reg.Index = 10;
        reg.SettingsControl = GetMultiElemValueSettings;
        reg.SettingsTypeHelper = new GetMultiElemValueSettingsDataHelper();
        reg.Executer = GetMultiElemValueModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetMultiElemValueSettingsData, json);
        }
    }

    getResult() {
        const elements = [];
        this.Data.Elements.forEach(x => {
            if (x.ElemName && (x.StatusKey || x.UseForState)) {
                elements.push(x);
            }
        });
        this.Data.Elements = elements;
        return this.Data;
    }

    addElement() {
        if (this.Data) {
            if (this.Data.Elements) {
                this.Data.Elements.push(new ElemInfo());
            } else {
                this.Data.Elements = [new ElemInfo()];
            }
        }
    }

    removeElement(i) {
        if (this.Data && this.Data.Elements) {
            this.Data.Elements.splice(i, 1);
        }
    }

    useForStateChecked(ev, val) {
        if (ev && ev.checked && this.Data && this.Data.Elements) {
            this.Data.Elements.forEach(x => {
                x.UseForState = false;
            });
            val.UseForState = true;
        }
    }
}

export class ElemInfo {
    ElemName: string;
    StatusKey: string;
    UseForState = false;
}

// @dynamic
export class GetMultiElemValueSettingsData {
    @Type(() => ElemInfo)
    Elements: ElemInfo[] = [];
}

export class GetMultiElemValueSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new GetMultiElemValueSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Elements) {
                const list = [];
                settings.Elements.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
    changeSettingsOnElementNameChange(settings: any, oldName: string, newName: string): boolean {
        let retVal = false;
        if (settings && settings.Elements) {
            settings.Elements.forEach(x => {
                if (x.ElemName === oldName) {
                    x.ElemName = newName;
                    retVal = true;
                }
            });
        }
        return retVal;
    }
}

export class GetMultiElemValueModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            status.ActualSettings.Elements.forEach(elemInfo => {
                const elem = status.WorkflowLayoutService.getElementByName(elemInfo.ElemName);
                if (elem) {
                    if (elem.Element) {
                        if (elemInfo.StatusKey) {
                            status.Context.set(elemInfo.StatusKey, elem.Element.DataSource);
                        }
                        if (elemInfo.UseForState) {
                            status.ActualParameter = elem.Element.DataSource;
                        }
                    } else {
                        status.Logger.logWarning('GetMultiElementValue modul: Control ' + elemInfo.ElemName + ' not found.');
                    }
                } else {
                    status.Logger.logWarning('GetMultiElementValue modul: Element ' + elemInfo.ElemName + ' not found.');
                }
            });
            return 0;
        } else {
            status.Logger.logError('GetMultiElementValue modul: No settings found.');
        }
        return super.execute(status);
    }
}
