import { LayoutChangeType } from '../enums/layoutchangetype.enum';

export class LayoutChange {
    LayoutChangeType: LayoutChangeType;
    ChangeValue: any;
    ResolutionID: string;
}

export class LayoutPropertyChangeValue {
    ElementID: string;
    PropertyName: string;
    Value: any;
}

export class PropertyChangeValue {
    PropertyName: string;
    Value: any;
}

export class MultiPropertyChangeValue {
    ElementIDs: string[] = [];
    Properties: PropertyChangeValue[] = [];
}

export class MultiLayoutPropertyChangeValue {
    Elements: MultiPropertyChangeValue[] = [];
}

export class LayoutPositionChangeValue {
    ElementID: string;
    OldParentID: string;
    NewParentID: string;
    Index: number;
}

export class PositionChangeElementValue {
    ElementID: string;
    DropIndex: number;
}

export class PositionChangeSourceValue {
    Elements: PositionChangeElementValue[] = [];
    ParentID: string;
}

export class MultiLayoutPositionChangeValue {
    Elements: PositionChangeSourceValue[] = [];
    NewParentID: string;
    Index: number;
}

export class LayoutElementAddedValue {
    ParentID: string;
    Index: number;
    ElementContent: string;
}

export class LayoutElementRemovedValue {
    ElementID: string;
    ElementName: string;
    ParentID: string;
}

export class MultiLayoutWorkflowChangeValue {
    LayoutID: string;
    Workflows: string[] = [];
}

export class WorkflowAddedValue {
    LayoutID: string;
    Workflow: string;
}

export class WorkflowRemovedValue {
    LayoutID: string;
    WorkflowID: string;
    WorkflowCaption: string;
}

export class ResolutionAddedValue {
    Resolution: string;
}

export class ResolutionRemovedValue {
    ResolutionID: string;
    ResolutionName: string;
}
