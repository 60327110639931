import { Component } from '@angular/core';
import { AObjectPreviewSettings } from '../common/adatapreview.settings';

@Component({
    selector: 'container-email-settings',
    templateUrl: '../../base.list.settings.html'
})
export class ContainerEmailSettings extends AObjectPreviewSettings {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Container.ContainerEmail';
    }

    getDBTypes(): number[][] {
        return [[16]]; // DocumentStoreRead
    }

    getResourceBase(): number {
        return 0;
    }

    //static GetSettingsEntry() {
    //    return {
    //        Caption: '@@ContainerEmail',
    //        ID: 'containeremail',
    //        Icon: 'table_chart',
    //        Index: 30,
    //        Parent: 'objectcontainer',
    //        Security: {
    //            Name: 'evidanza.MiddleWare.Shared.Security.ObjectContainerRight',
    //            Value: 8
    //        },
    //        Content: ContainerEmailSettings
    //    }
    //}
}