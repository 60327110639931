import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'show-messagebox-wf-settings',
    templateUrl: './show.messagebox.settings.html'
})
export class ShowMessageBoxSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'showMessageBoxWFModule';

    Data = new ShowMessageBoxSettingsData();
    Icons;
    Buttons;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ShowMessageBoxSettings.ModuleID;
        reg.Caption = '@@Nachricht anzeigen';
        reg.GroupID = 'wfActions';
        reg.Index = 40;
        reg.SettingsControl = ShowMessageBoxSettings;
        reg.SettingsTypeHelper = new ShowMessageBoxSettingsDataHelper();
        reg.Executer = ShowMessageBoxModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor() {
        super();
        this.Icons = EnumHelper.GetDropdownValues(MessageBoxIcon);
        this.Buttons = EnumHelper.GetDropdownValues(MessageBoxButtons);
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ShowMessageBoxSettingsData, json);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class ShowMessageBoxSettingsData {
    Title: string;
    Text: string;
    Icon: MessageBoxIcon = MessageBoxIcon.None;
    Buttons: MessageBoxButtons = MessageBoxButtons.OkCancel;
}

export class ShowMessageBoxSettingsDataHelper extends WorkflowModuleSettingsHelper {

    constructor() {
        super();
        this.MustUpdateExitPoints = true;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = [];
        if (settings) {
            switch (settings.Buttons) {
                case MessageBoxButtons.None:
                    const none = new WorkflowExitInfo();
                    none.ID = MessageBoxResult.None;
                    retVal.push(none);
                    break;
                case MessageBoxButtons.Ok:
                    const ok = new WorkflowExitInfo();
                    ok.ID = MessageBoxResult.Ok;
                    retVal.push(ok);
                    break;
                case MessageBoxButtons.OkCancel:
                    const ok2 = new WorkflowExitInfo();
                    ok2.ID = MessageBoxResult.Ok;
                    ok2.Label = '@@OK';
                    retVal.push(ok2);
                    const cancel = new WorkflowExitInfo();
                    cancel.ID = MessageBoxResult.Cancel;
                    cancel.Label = '@@Abbrechen';
                    retVal.push(cancel);
                    break;
                case MessageBoxButtons.YesNo:
                    const yes = new WorkflowExitInfo();
                    yes.ID = MessageBoxResult.Yes;
                    yes.Label = '@@Ja';
                    retVal.push(yes);
                    const no = new WorkflowExitInfo();
                    no.ID = MessageBoxResult.No;
                    no.Label = '@@Nein';
                    retVal.push(no);
                    break;
                case MessageBoxButtons.YesNoAbort:
                    const yes2 = new WorkflowExitInfo();
                    yes2.ID = MessageBoxResult.Yes;
                    yes2.Label = '@@Ja';
                    retVal.push(yes2);
                    const no2 = new WorkflowExitInfo();
                    no2.ID = MessageBoxResult.No;
                    no2.Label = '@@Nein';
                    retVal.push(no2);
                    const abort = new WorkflowExitInfo();
                    abort.ID = MessageBoxResult.Abort;
                    abort.Label = '@@Abbrechen';
                    retVal.push(abort);
                    break;
            }
        }
        return retVal;
    }
    getEmptySettingsInstance() {
        return new ShowMessageBoxSettingsData();
    }
}

export class ShowMessageBoxModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            let title = '';
            let message = '';
            const calculator = new WorkflowFormulaCalculator(status);
            if (status.ActualSettings.Title) {
                try {
                    const titleVal = calculator.CalcFormula(status.ActualSettings.Title);
                    if (titleVal) {
                        title += titleVal;
                    }
                } catch (e) {
                    const t = 'Messagebox modul: Error while calculating title \'' +
                        status.ActualSettings.Title + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            if (status.ActualSettings.Text) {
                try {
                    const messageVal = calculator.CalcFormula(status.ActualSettings.Text);
                    if (messageVal) {
                        message += messageVal;
                    }
                } catch (e) {
                    const t = 'Messagebox modul: Error while calculating text \'' +
                        status.ActualSettings.Text + '\' (' + e + ')';
                    status.Logger.logWarning(t);
                }
            }
            return await MessageBoxHelper.ShowDialog(new TranslateFormatText(message), new TranslateFormatText(title),
                status.ActualSettings.Buttons, status.ActualSettings.Icon);
        } else {
            status.Logger.logError('Messagebox modul: No settings found.');
        }
        return super.execute(status);
    }
}
