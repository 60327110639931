import { BaseTriggerControl } from './base.trigger.control';
import { Component } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
    selector: 'filesystemtrigger-control',
    templateUrl: './filesystem.trigger.control.html',
    styleUrls: ['./filesystem.trigger.control.css']
})
export class FileSystemTriggerControl extends BaseTriggerControl {
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    add(event) {
        if (this.TriggerValue) {
            const input = event.input;
            const value = event.value;

            // Add our fruit
            if ((value || '').trim()) {
                if (!this.TriggerValue.FileSearch) {
                    this.TriggerValue.FileSearch = [];
                }
                this.TriggerValue.FileSearch.push({ SearchValue: value.trim() });
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }
        }
    }
}
