import { Component, ViewChild } from '@angular/core';
import { CacheService } from '../../../cache/cache.service';
import { FilterHelper } from '../../../helpers/filter.helper';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { RequestColumn } from '../../../models/rest/requestcolumn';
import { RequestFilter } from '../../../models/rest/requestfilter';
import { RequestOptions } from '../../../models/rest/requestoptions';
import { RequestSort } from '../../../models/rest/requestsort';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { DataService } from '../../../services/data.service';
import { DataModelService, DataSourceQuery } from '../../../services/datamodel.service';
import { DynamicDataService } from '../../../services/dynamicdata.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { RequestOptionsColumn, RequestOptionsControl } from '../../../components/common/requestoptions/request.options.control';
import { DataCheck, FormulaWorkflowDialogContent } from '../../workflow.dialog';
import { WorkflowFormulaCalculator } from '../definevalue/define.value.settings';

@Component({
    selector: 'wf-readdata-settings',
    templateUrl: './read.data.settings.html',
    styleUrls: ['./read.data.settings.css']
})
export class ReadDataSettings extends FormulaWorkflowDialogContent {
    static ModuleID = 'readDataWFModule';

    DataModelID: string;
    DataSource: string;
    RequestOptions = RequestOptions.CleanRequestOptions();

    @ViewChild('advanced') filterComponent: RequestOptionsControl;
    DataModels = [];
    DataSources = [];
    StaticTables = [];
    Columns = [];
    StaticDataSource = false;
    UseCount = false;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = ReadDataSettings.ModuleID;
        reg.Caption = '@@ReadData';
        reg.GroupID = 'dataoperations';
        reg.Index = 40;
        reg.SettingsControl = ReadDataSettings;
        reg.SettingsTypeHelper = new ReadDataSettingsDataHelper();
        reg.Executer = ReadDataModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    constructor(private dataModelService: DataModelService, private dataService: DataService) {
        super();
    }

    ngOnInit(): void {
        this.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((data) => {
            this.StaticTables = data;
            this.dataModelService.GetModels().subscribe(models => {
                this.DataModels = models;
                if (this.DataSource) {
                    if (data.some(x => x.Value === this.DataSource)) {
                        this.StaticDataSource = true;
                        this.fillColumns();
                    } else if (this.DataModelID) {
                        const query = new DataSourceQuery(this.DataModelID);
                        query.DBUsages = [[4096, 16]]; // RelationalRead oder DocumentStoreRead
                        query.IgnoreResourceBase = true;
                        query['OnlyQueryable'] = true;
                        this.dataModelService.GetContainerByDBUsage(query).subscribe((dataSources) => {
                            this.DataSources = dataSources;
                            if (dataSources.some(x => x.SID === this.DataSource)) {
                                this.fillColumns();
                            } else {
                                this.DataSource = null;
                            }
                        });
                    }
                }
            });
        });
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            data = JSON.parse(JSON.stringify(data));
            if (data.DataModelID) {
                this.DataModelID = data.DataModelID;
            }
            if (data.DataSource) {
                this.DataSource = data.DataSource;
            }
            if (data.Filter) {
                this.RequestOptions.Filters.push(data.Filter);
            }
            if (data.Sort) {
                this.RequestOptions.Sort = data.Sort;
            }
            if (data.Columns) {
                this.RequestOptions.Columns = data.Columns;
            }
            if (data.UseCount != null) {
                this.UseCount = data.UseCount;
            }
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (!this.DataSource) {
            retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
            retVal.IsCorrect = false;
        } else {
            if (this.StaticDataSource) {
                if (!this.StaticTables.some(x => x.Value === this.DataSource)) {
                    retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
                    retVal.IsCorrect = false;
                }
            } else {
                if (!this.DataSources.some(x => x.SID === this.DataSource)) {
                    retVal.Error = '@@Bitte waehlen Sie eine Datenquelle.';
                    retVal.IsCorrect = false;
                }
            }
        }
        return retVal;
    }

    getResult(): any {
        const retVal = new ReadDataSettingsData();
        retVal.DataSource = this.DataSource;
        if (!this.StaticTables.some(x => x.Value === this.DataSource)) {
            retVal.DataModelID = this.DataModelID;
        }
        if (this.filterComponent) {
            this.filterComponent.applyFilter();
        }
        if (this.UseCount != null) {
            retVal.UseCount = this.UseCount;
        }
        if (this.RequestOptions.Filters && this.RequestOptions.Filters.length > 0) {
            retVal.Filter = this.RequestOptions.Filters[0];
        }
        if (this.RequestOptions.Columns) {
            retVal.Columns = this.RequestOptions.Columns;
        }
        if (this.RequestOptions.Sort) {
            retVal.Sort = this.RequestOptions.Sort;
        }
        return retVal;
    }

    modelSelected() {
        this.Columns = [];
        this.RequestOptions = RequestOptions.CleanRequestOptions();
        this.DataSources = [];
        this.DataSource = null;
        const query = new DataSourceQuery(this.DataModelID);
        query.DBUsages = [[4096, 16]]; // RelationalRead oder DocumentStoreRead
        query.IgnoreResourceBase = true;
        query['OnlyQueryable'] = true;
        this.dataModelService.GetContainerByDBUsage(query).subscribe((dataSources) => {
            this.DataSources = dataSources;
        });
    }

    readTableProperties() {
        this.Columns = [];
        this.RequestOptions = RequestOptions.CleanRequestOptions();
        this.fillColumns();
    }

    fillColumns() {
        if (this.DataSource) {
            CacheService.ReadFlatTable(this.DataSource, true).then((data) => {
                if (data) {
                    const cols = data['Columns'];
                    if (cols) {
                        const list = [];
                        cols.forEach((field) => {
                            const col = new RequestOptionsColumn();
                            col.Name = field.Name;
                            if (field.DataTyp) {
                                col.Type = field.DataTyp;
                            } else if (field.Type) {
                                col.Type = field.Type;
                            }
                            list.push(col);
                        });
                        this.Columns = list;
                    }
                }
            });
        }
    }
}

export class ReadDataSettingsData {
    DataModelID: string;
    DataSource: string;
    UseCount: boolean;
    Columns: RequestColumn[] = [];
    Filter: RequestFilter;
    Sort: RequestSort[] = [];
}

export class ReadDataSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const success = new WorkflowExitInfo();
        success.Label = '@@Success';
        const end = new WorkflowExitInfo();
        end.ID = 1;
        end.Label = '@@Error';
        return [success, end];
    }
    getEmptySettingsInstance() {
        return new ReadDataSettingsData();
    }
}

export class ReadDataModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.DataSource) {
                const options = RequestOptions.CleanRequestOptions();
                if (status.ActualSettings.Sort) {
                    options.Sort = status.ActualSettings.Sort;
                }
                if (status.ActualSettings.Filter) {
                    const calculator = new WorkflowFormulaCalculator(status);
                    const filter = FilterHelper.ReplaceVariables(status.ActualSettings.Filter, (value) => {
                        return calculator.CalcFormula(value);
                    });
                    if (filter) {
                        options.Filters.push(filter);
                    }
                }
                if (status.ActualSettings.Columns) {
                    options.Columns = status.ActualSettings.Columns;
                }
                const service = InjectorHelper.InjectorInstance.get<DynamicDataService>(DynamicDataService);
                let result = null;
                if (status.ActualSettings.UseCount) {
                    result = await service.CountObjects(status.ActualSettings.DataSource, options).toPromise();
                } else {
                    result = await service.SearchObjects(status.ActualSettings.DataSource, options).toPromise();
                }
                if (result != null) {
                    status.ActualParameter = result;
                    return 0;
                } else {
                    status.Logger.logError('ReadData modul: Data could not be read.');
                    return 1;
                }
            } else {
                status.Logger.logError('ReadData modul: No DataSource set.');
            }
        } else {
            status.Logger.logError('ReadData modul: No settings found.');
        }
        return super.execute(status);
    }
}
