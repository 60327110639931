import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { DataSettingsService } from '../../../services/data.settings.service';
import { DataModelService } from '../../../services/datamodel.service';

@Component({
    selector: 'containerrelation-edit-dialog',
    templateUrl: './containerrelation.edit.dialog.html',
    styleUrls: ['./containerrelation.edit.dialog.css']
})
export class ContainerRelationEditDialog implements OnInit {
    InitArgs;
    ContainerList = [];
    ButtonClicked = null;

    CustomButtons = [];
    CloseOnCustomButton = true;

    Relation;
    ElementRelationsValue = [];
    get ElementRelations() {
        return this.ElementRelationsValue;
    }
    set ElementRelations(value) {
        this.ElementRelationsValue = value;
    }
    JoinTypes;
    RelationTypes;
    ContainerDict = new Map();
    PrimaryFieldsValue = [];
    get PrimaryFields() {
        return this.PrimaryFieldsValue;
    }
    set PrimaryFields(value) {
        this.PrimaryFieldsValue = value;
        this.cdRef.detectChanges();
    }
    SecondaryFieldsValue = [];
    get SecondaryFields() {
        return this.SecondaryFieldsValue;
    }
    set SecondaryFields(value) {
        this.SecondaryFieldsValue = value;
        this.cdRef.detectChanges();
    }
    constructor(private dataService: DataModelService, private settingsService: DataSettingsService, private cdRef: ChangeDetectorRef) {
        this.JoinTypes = EnumHelper.GetDropdownValues(RelationType);
        this.RelationTypes = EnumHelper.GetDropdownValues(RelationShip);
    }

    ngOnInit(): void {
        if (this.InitArgs && this.InitArgs.DataModelID) {
            if (this.InitArgs.ShowDelete) {
                this.CustomButtons = [{
                    Caption: '@@Save',
                    ID: 0
                }, {
                    Caption: '@@Delete',
                    ID: 2
                }, {
                    Caption: '@@Cancel',
                    ID: 1
                }];
            }
            this.settingsService.GetContainerTables(this.InitArgs.DataModelID).subscribe((result) => {
                if (result) {
                    this.ContainerList = result;
                    if (this.InitArgs.IsNew) {
                        this.SetRelation({
                            DataModelID: this.InitArgs.DataModelID,
                            PrimaryContainer: this.InitArgs.PrimaryContainer,
                            SecondaryContainer: null,
                            ElementRelations: new Map(),
                            JoinType: RelationType.Inner,
                            RelationShip: RelationShip.OneToMany
                        });
                    } else {
                        if (this.InitArgs.SID) {
                            this.settingsService.GetRelation(this.InitArgs.SID).subscribe((r) => {
                                this.SetRelation(r);
                            });
                        } else {
                            this.SetRelation({
                                DataModelID: this.InitArgs.DataModelID,
                                PrimaryContainer: this.InitArgs.PrimaryContainer,
                                SecondaryContainer: this.InitArgs.SecondaryContainer,
                                ElementRelations: this.InitArgs.ElementRelations,
                                JoinType: RelationType.Outer,
                                RelationShip: RelationShip.ManyToOne
                            });
                        }
                    }
                }
                this.cdRef.detectChanges();
            });
        }
    }

    SetRelation(result) {
        if (result) {
            this.Relation = result;
            this.SecondaryFields = [];
            this.PrimaryFields = [];
            if (result.PrimaryContainer) {
                this.getContainer(result.PrimaryContainer, (pc) => {
                    if (pc) {
                        this.PrimaryFields = pc.Fields;
                    }
                    if (result.SecondaryContainer) {
                        this.getContainer(result.SecondaryContainer, (sc) => {
                            if (sc) {
                                this.SecondaryFields = sc.Fields;
                            }
                            this.SetElementRelations();
                        });
                    } else {
                        this.SetElementRelations();
                    }
                });
            } else if (result.SecondaryContainer) {
                this.getContainer(result.SecondaryContainer, (sc) => {
                    if (sc) {
                        this.SecondaryFields = sc.Fields;
                    }
                    this.SetElementRelations();
                });
            }
        }
    }
    SetElementRelations() {
        const erList = [];
        if (this.Relation && this.Relation.ElementRelations) {
            const rels = Object.keys(this.Relation.ElementRelations);
            for (let i = 0; i < rels.length; i++) {
                const k = rels[i];
                erList.push({
                    PrimaryField: k,
                    SecondaryField: this.Relation.ElementRelations[k]
                });
            }
        }
        this.ElementRelations = erList;
        this.cdRef.detectChanges();
    }

    Initialize(args) {
        this.InitArgs = args;
    }

    GetDialogResult() {
        if (this.ButtonClicked === 0) {
            if (this.Relation) {
                const rels = {};
                this.ElementRelations.forEach((er) => {
                    if (er.PrimaryField && er.SecondaryField) {
                        rels[er.PrimaryField] = er.SecondaryField;
                    }
                });
                this.Relation.ElementRelations = rels;
                return this.Relation;
            }
        }
        if (this.ButtonClicked === 2) {
            return 'DELETE';
        }
        return null;
    }
    OnCustomButtonClicked(button) {
        if (button) {
            this.ButtonClicked = button.ID;
            switch (button.ID) {
                case 0:
                    this.OnDialogButtonClickAction(0); break;
                case 1: break;
                case 2: break;
            }
        }
    }

    OnDialogButtonClickAction(but) {
        if (but === DialogButton.Ok) {
            this.ButtonClicked = 0;
            if (this.Relation) {
                const errorList = [];
                if (!this.Relation.PrimaryContainer) {
                    errorList.push('@@PrimaryContainer fehlt');
                }
                if (!this.Relation.SecondaryContainer) {
                    errorList.push('@@SecondaryContainer fehlt');
                }
                let count = 0;
                if (!this.ElementRelations.some((er) => {
                    const pf = this.PrimaryFields.find(x => x.ID === er.PrimaryField);
                    const sf = this.SecondaryFields.find(x => x.ID === er.SecondaryField);
                    if (pf) {
                        if (sf) {
                            if (pf.DataTyp === sf.DataTyp) {
                                count++;
                            } else {
                                errorList.push('@@Unterschiedliche Datentypen');
                                return true;
                            }
                        } else {
                            errorList.push('@@Mindestens eine Zielspalte fehlt');
                            return true;
                        }
                    } else if (sf) {
                        errorList.push('@@Mindestens eine Quellspalte fehlt');
                        return true;
                    }
                    return false;
                }) && count === 0) {
                    errorList.push('@@Keine gueltige Spaltenbeziehung gesetzt');
                }
                if (errorList.length > 0) {
                    const text = new TranslateFormatText('@@Fehlerhafte Relation:\n{0}');
                    text.FormatParams.push(errorList.join('\n'));
                    const header = new TranslateFormatText('@@Fehler');
                    MessageBoxHelper.ShowDialog(text, header, MessageBoxButtons.Ok, MessageBoxIcon.Information);
                    return false;
                }
            }
        }
        return true;
    }

    getAllPrimary() {
        let id = null;
        if (this.Relation && this.Relation.SecondaryContainer) {
            id = this.Relation.SecondaryContainer;
        }
        return this.getTables(id);
    }

    getTables(id) {
        if (id) {
            return this.ContainerList.filter(x => x.SID !== id);
        } else {
            return this.ContainerList;
        }
    }

    updatePrimFields() {
        this.PrimaryFields = [];
        this.ElementRelations.forEach((er) => {
            er.PrimaryField = null;
        });
        this.getContainer(this.Relation.PrimaryContainer, (cont) => {
            if (cont) {
                this.PrimaryFields = cont.Fields;
            }
        });
    }

    getAllSecondary() {
        let id = null;
        if (this.Relation && this.Relation.PrimaryContainer) {
            id = this.Relation.PrimaryContainer;
        }
        return this.getTables(id);
    }

    updateSecFields() {
        this.SecondaryFields = [];
        this.ElementRelations.forEach((er) => {
            er.SecondaryField = null;
        });
        this.getContainer(this.Relation.SecondaryContainer, (cont) => {
            if (cont) {
                this.SecondaryFields = cont.Fields;
            }
        });
    }

    getContainer(ev, finishFunc) {
        if (ev && typeof finishFunc === 'function') {
            const cont = this.ContainerDict.get(ev);
            if (cont) {
                finishFunc(cont);
            } else {
                this.dataService.GetContainer(ev).subscribe((result) => {
                    if (result) {
                        result.Fields.forEach((f) => {
                            f.RelFieldCaption = f.TranslatedCaption + ' (' + f.DataTyp + ')';
                        });
                    } else {
                        result = {
                            Fields: []
                        };
                    }
                    this.ContainerDict.set(ev, result);
                    finishFunc(result);
                });
            }
        }
    }

    addRelation() {
        this.ElementRelations.push({
            PrimaryField: null,
            SecondaryField: null
        });
    }

    removeRelation(i) {
        this.ElementRelations.splice(i, 1);
    }
}

export enum RelationType {
    Inner,
    Left,
    Right,
    Outer,
    Union
}

export enum RelationShip {
    OneToMany,
    ManyToOne,
    ManyToMany
}
