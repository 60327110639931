import { Type, plainToClass } from 'class-transformer';
import { AxisType, MemberType, CellType, CodeDesc } from '../../enums/query.enum';
import { Duration } from 'moment';
import { UUID } from 'angular2-uuid';

export class MultiNode {
    InternalValue: any;
    CellType: CellType;
    Unit: string;
    X = 0;
    Y = 0;
    M = 0;
    StyleID = 0;

    get Value(): any {
        return this.InternalValue;
    }

    toString() {
        return this.CellType + ': ' + this.Value;
    }

    public constructor(init?: Partial<MultiNode>) {
        Object.assign(this, init);
    }

    clone(): MultiNode {
        return plainToClass(MultiNode, JSON.parse(JSON.stringify(this)));
    }
}

export class DummyInfo {
    AreaID = -1;

    clone(): DummyInfo {
        return plainToClass(DummyInfo, JSON.parse(JSON.stringify(this)));
    }
}

// @dynamic
export class AxisNode {
    MergePositions: Map<number, number> = new Map<number, number>();
    @Type(() => AxisNode)
    ChildrenDict: Map<string, AxisNode> = new Map<string, AxisNode>();
    @Type(() => AxisNode)
    Parent: AxisNode;
    @Type(() => AxisNode)
    Children: AxisNode[] = [];
    @Type(() => AxisNode)
    SumNode: AxisNode;
    Position = 0;
    Depth = 0;
    Axis: AxisType;
    IsCalculated = false;
    Key: string;
    Measure = false;
    Caption: string;
    Type: MemberType;
    Attributes: Map<string, string>;
    MemberId: string;
    UniqueID = -1;
    UnaryOp = 1;
    Hierarchy: string;
    SearchChildrenKeyInternal: string;
    IsLastChild = false;
    Visible = true;
    CodeDescription: CodeDesc = CodeDesc.Description;
    StyleID = 0;
    DummyInfo: DummyInfo;

    static GetSearchChildrenKey(an: AxisNode) {
        if (an.SearchChildrenKeyInternal) {
            return an.SearchChildrenKeyInternal;
        }
        return an.UniqueID + '_' + an.MemberId;
    }

    static clone(node: AxisNode, withChildren: boolean): AxisNode {
        const retVal = Object.assign({}, node);
        retVal.MergePositions = new Map<number, number>();
        retVal.ChildrenDict = new Map<string, AxisNode>();
        retVal.Parent = null;
        retVal.Children = [];
        if (node.SumNode) {
            retVal.SumNode = AxisNode.clone(node.SumNode, false);
        }
        if (node.Attributes) {
            retVal.Attributes = new Map<string, string>();
            node.Attributes.forEach((v, k) => {
                retVal.Attributes.set(k, v);
            });
        }
        if (node.DummyInfo) {
            retVal.DummyInfo = node.DummyInfo.clone();
        }
        if (withChildren && node.Children) {
            node.Children.forEach(child => {
                const clone = AxisNode.clone(child, true);
                clone.Parent = retVal;
                retVal.Children.push(clone);
            });
        }
        return retVal;
    }

    toString() {
        return this.Caption;
    }

    addChild(child: AxisNode) {
        this.Children.push(child);
        if (child) {
            this.ChildrenDict.set(AxisNode.GetSearchChildrenKey(child), child);
        }
    }

    public constructor(init?: Partial<AxisNode>) {
        Object.assign(this, init);
    }
}
// @dynamic
export class Axis {
    Type: AxisType;
    ElementCount = 0;
    @Type(() => AxisNode)
    Nodes: AxisNode[] = [];
    @Type(() => AxisNode)
    SumNode: AxisNode;

    public constructor(init?: Partial<Axis>) {
        Object.assign(this, init);
    }

    clone(): Axis {
        let retVal;
        retVal = plainToClass(Axis, JSON.parse(JSON.stringify(this)));
        return retVal;
    }
}
// @dynamic
export class MultiResult {
    ID: UUID;
    @Type(() => Axis)
    XAxis: Axis = new Axis({ Type: AxisType.X_Axis });
    @Type(() => Axis)
    YAxis: Axis = new Axis({ Type: AxisType.Y_Axis });
    @Type(() => Axis)
    Measures: Axis = new Axis({ Type: AxisType.Measure });
    @Type(() => MultiNode)
    Cells: MultiNode[][][] = [];
    Overalltime: Duration;
}
