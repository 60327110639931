import { Component, Input } from '@angular/core';

@Component({
	selector: 'list-view',
	templateUrl: './listview.component.html',
	styleUrls: ['./listview.component.css'],
})
export class ListView {
	constructor() {}

	@Input() Columns: any[];

	@Input() Rows: any[];
}
