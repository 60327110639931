import { UUID } from "angular2-uuid";
import { SelectionType } from "../enums/query.enum";
import { plainToClass } from "class-transformer";

export class Select {
  Hierarchy: UUID;
  Level: UUID;
  Member: UUID;
  AttributLevel: UUID;
  NegativeTag: boolean = false;
  SelectionType: SelectionType;
  AttributeValue: string;
  AttributeName: string;
  FromDate: Date;
  ToDate: Date;

  get IsAttribute(): boolean {
    return this.AttributLevel != null;
  }

  public constructor(init?: Partial<Select>) {
    Object.assign(this, init);
  }

  clone(): Select {
    let retVal;
    retVal = plainToClass(Select, JSON.parse(JSON.stringify(this)));
    return retVal;
  }
}
