import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { deserialize } from 'class-transformer';
import { NotificationHelper } from '../../helpers/notification.helper';
import { Layout } from '../../models/layout.model';
import { TranslatedString } from '../../models/translatedstring.model';
import { SchedulerService, TRIGGER_DICT } from '../../services/scheduler.service';
import { WebsocketService } from '../../services/websocket.service';
import { WorkflowService } from '../../services/workflow.service';

@Component({
    selector: 'workflow-wizard-component',
    templateUrl: './workflow.wizard.component.html'
})
export class WorkflowWizardComponent implements OnInit {
    
    Pages = [];
    LastPageInfo = {
        SaveWorkflow: false,
        ExecuteNow: true,
        Trigger: {
            Name: '',
            IsEnabled: true,
            StartDate: null,
            EndDate: null,
            MaxOccurences: null,
            JobTimeOut: null,
            Type: null
        }
    };
    @ViewChild('dynamicTrigger', { read: ViewContainerRef }) dynamicTrigger: ViewContainerRef;
    TriggerTypes = [];
    
    //#region WizardObject
    WizardObjectValue;

    @Input()
    get WizardObject() {
        return this.WizardObjectValue;
    }
    set WizardObject(val) {
        if (val !== this.WizardObjectValue) {
            this.WizardObjectValue = val;
            this.UpdateWizard();
            this.WizardObjectChange.emit(this.WizardObjectValue);
        }
    }

    @Output() WizardObjectChange = new EventEmitter<any>();
    //#endregion

    //#region Initialized
    InitializedValue = false;

    @Input()
    get Initialized() {
        return this.InitializedValue;
    }
    set Initialized(val) {
        this.InitializedValue = val;
        this.InitializedChange.emit(this.InitializedValue);
    }

    @Output() InitializedChange = new EventEmitter<any>();
    //#endregion

    //#region ShowCancel
    ShowCancelValue = false;

    @Input()
    get ShowCancel() {
        return this.ShowCancelValue;
    }
    set ShowCancel(val) {
        this.ShowCancelValue = val;
        this.ShowCancelChange.emit(this.ShowCancelValue);
    }

    @Output() ShowCancelChange = new EventEmitter<any>();
    //#endregion

    @Output() OnOKClick = new EventEmitter<any>();
    @Output() OnCancelClick = new EventEmitter<any>();

    private static findWorkflowControls(layout) {
        const retVal = [];
        if (layout && layout.Elements) {
            layout.Elements.forEach(elem => {
                if (elem.ElementType === 'workflow-edit-control') {
                    if (elem.Element) {
                        retVal.push(elem.Element);
                    }
                } else {
                    retVal.push(...WorkflowWizardComponent.findWorkflowControls(elem));
                }
            });
        }
        return retVal;
    }

    constructor(private wfService: WorkflowService, private schedService: SchedulerService, private resolver: ComponentFactoryResolver) {
    }

    ngOnInit() {
        this.schedService.GetTriggerTypes().subscribe((result) => {
            if (result) {
                this.TriggerTypes = result;
            }
        });
    }

    UpdateWizard() {
        const pages = [];
        let title = '';
        if (this.WizardObjectValue) {
            if (this.WizardObjectValue.Pages) {
                this.WizardObjectValue.Pages.forEach(page => {
                    pages.push({
                        Caption: TranslatedString.GetTranslation(page.Caption),
                        Layout: deserialize(Layout, page.Layout)
                    });
                });
            }
            if (this.WizardObjectValue.Workflow && this.WizardObjectValue.Caption) {
                title = TranslatedString.GetTranslation(this.WizardObjectValue.Caption);
                this.WizardObjectValue.Workflow.Caption = JSON.parse(JSON.stringify(this.WizardObjectValue.Caption));
            }
        }
        this.Pages = pages;
        this.LastPageInfo.Trigger.Name = title;
    }

    pageInitialized(page) {
        page.WorkflowControls = WorkflowWizardComponent.findWorkflowControls(page.Layout);
        this.updatePage(page);
    }

    updatePage(page) {
        if (page.WorkflowControls && this.WizardObjectValue && this.WizardObjectValue.Workflow) {
            page.WorkflowControls.forEach(wfc => {
                wfc.FillWithData(this.WizardObjectValue.Workflow);
            });
        }
    }

    pageChanged(ev) {
        if (this.WizardObjectValue && this.WizardObjectValue.Workflow) {
            if (ev.previouslySelectedIndex < this.Pages.length) {
                const previousPage = this.Pages[ev.previouslySelectedIndex];
                if (previousPage.WorkflowControls) {
                    previousPage.WorkflowControls.forEach(wfc => {
                        const module = this.findWorkflowModule(wfc);
                        if (module) {
                            const result = wfc.GetResult();
                            if (result.IsCorrect) {
                                module.Settings = result.Settings;
                            } else {
                                // TODO: PageChange unterbinden + Message
                            }
                        }
                    });
                }
            }
            if (ev.selectedIndex < this.Pages.length) {
                const nextPage = this.Pages[ev.selectedIndex];
                this.updatePage(nextPage);
            }
        }
    }

    private findWorkflowModule(wfc) {
        if (wfc && this.WizardObjectValue && this.WizardObjectValue.Workflow && this.WizardObjectValue.Workflow.Modules) {
            const le = wfc.LayoutElement;
            if (le && le.ModuleInfo) {
                return this.WizardObjectValue.Workflow.Modules.find(x => x.ID === le.ModuleInfo.ID);
            }
        }
        return null;
    }

    execute() {
        if (this.WizardObjectValue) {
            const wf = this.WizardObjectValue.Workflow;
            if (wf) {
                if (this.LastPageInfo.SaveWorkflow) {
                    this.wfService.SaveWorkflow(wf).subscribe(result => {
                        if (result) {
                            if (this.LastPageInfo.ExecuteNow) {
                                this.ExecuteWorkflow(wf);
                            } else {
                                const triggerType = this.TriggerTypes.find(tt => tt.ID === this.LastPageInfo.Trigger.Type);
                                if (triggerType) {
                                    const trigger = Object.assign({}, this.LastPageInfo.Trigger);
                                    trigger['ExecutionContext'] = JSON.stringify({
                                        WorkflowData: {
                                            IsServiceWorkflow: true,
                                            WorkflowID: result.Id
                                        }
                                    });
                                    const serStruct = {
                                        Type: triggerType.ConfigTypeName,
                                        Content: JSON.stringify(trigger)
                                    };
                                    this.schedService.SaveTrigger(serStruct).subscribe();
                                } else {
                                    // TODO: Meldung ausgeben dass kein TriggerTyp gewaehlt
                                }
                            }
                        }
                    });
                } else if (this.LastPageInfo.ExecuteNow) {
                    this.ExecuteWorkflow(wf);
                }
            }
        }
        this.OnOKClick.emit();
    }

    private ExecuteWorkflow(wf) {
        const channel = UUID.UUID();
        const subScription = WebsocketService.MessageReceived.subscribe((response) => {
            const parsed = JSON.parse(response);
            if (parsed && parsed.Type === 'evidanza.App.Shared.Workflow.Rest.WorkflowClientMessage') {
                const content = JSON.parse(parsed.Content);
                if (content) {
                    switch (parsed.Level) {
                        case 2: // NotificationLevel.Error
                            NotificationHelper.Error(content.Message, content.Title);
                            break;
                        case 3: // NotificationLevel.Warning
                            NotificationHelper.Warn(content.Message, content.Title);
                            break;
                        case 4: // NotificationLevel.Information
                            NotificationHelper.Info(content.Message, content.Title);
                            break;
                    }
                    if (content.Unsubscribe === true) {
                        subScription.unsubscribe();
                        WebsocketService.Unsubscribe([{ Channel: channel, Level: [2, 3, 4] }]);
                    }
                }
            }
        });
        WebsocketService.Subscribe([{ Channel: channel, Level: [2, 3, 4] }]);
        this.wfService.ExecuteWorkflow(wf, channel, false).subscribe();
    }

    triggerChanged() {
        if (this.dynamicTrigger) {
            this.dynamicTrigger.clear();
            const type = TRIGGER_DICT.get(this.LastPageInfo.Trigger.Type);
            if (type) {
                const factory = this.resolver.resolveComponentFactory(type);
                const comp = factory.create(this.dynamicTrigger.parentInjector);
                this.dynamicTrigger.insert(comp.hostView);
                const component: any = comp.instance;
                if (component) {
                    component.Trigger = this.LastPageInfo.Trigger;
                }
            }
        }
    }

    cancel() {
        this.OnCancelClick.emit();
    }
}
