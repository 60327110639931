import { Component, ViewChild } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { ContainerWorkflowDialogContent, DataCheck } from '../../../workflow.dialog';
import { ABaseContainerSettingsData } from '../container.chooser';

@Component({
    selector: 'wf-execute-delete-settings',
    templateUrl: './execute.delete.settings.html'
})
export class ExecuteDeleteSettings extends ContainerWorkflowDialogContent {

    Data = new ExecuteDeleteSettingsData();
    @ViewChild('chooser') ContainerChooser;
    DBUsages = [[8192, 32]]; // RelationalWrite oder DocumentStoreWrite

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'executeDeleteWFModule';
        reg.Caption = '@@Delete';
        reg.GroupID = 'containeroperations';
        reg.Index = 30;
        reg.SettingsControl = ExecuteDeleteSettings;
        reg.SettingsTypeHelper = new ExecuteDeleteSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(ExecuteDeleteSettingsData, json);
        }
    }

    checkData(): DataCheck {
        const retVal = new DataCheck();
        if (this.Data.ContainerTable) {
            if (this.ContainerChooser) {
                this.ContainerChooser.CheckActualFilter();
            }
            if (!this.Data.Filter || !this.Data.Filter.Filters || this.Data.Filter.Filters.length <= 0) {
                retVal.IsCorrect = false;
                retVal.Error = '@@Bitte definieren Sie einen Filter';
            }
        } else {
            retVal.IsCorrect = false;
            retVal.Error = '@@Bitte waehlen Sie eine Tabelle und definieren Sie einen Filter';
        }
        return retVal;
    }

    getResult(): any {
        if (this.Data.InteractiveProperties) {
            this.Data.InteractiveProperties = this.Data.InteractiveProperties.filter(x => x.Value);
        }
        if (this.Data.ContainerTable && this.ContainerChooser) {
            this.ContainerChooser.CheckActualFilter();
        }
        return this.Data;
    }
}

export class ExecuteDeleteSettingsData extends ABaseContainerSettingsData {
    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.ContainerOperations.ExecuteDelete.ExecuteDeleteSettingsData';
    }
}

export class ExecuteDeleteSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ExecuteDeleteSettingsData();
    }
}
