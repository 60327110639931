import { WorkflowModuleSettingsHelper, WorkflowExitInfo, WorkflowRegistry, ObsoleteInfo } from '../../../services/workflow.service';
import { WorkflowModuleExecuter, WorkflowStatus, AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { WorkflowDialogContent } from '../../workflow.dialog';
import { Type, serialize, deserialize } from 'class-transformer';
import { CacheService } from '../../../cache/cache.service';
import { Component } from '@angular/core';

export class GetCurrentUserSettingsData extends AServiceWorkflowData {
    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GetCurrentUser.GetCurrentUserSettingsData';
    }
}

export class GetCurrentUserModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            status.ActualParameter = user;
            return 0;
        } else {
            status.Logger.logError('GetCurrentUser modul: No user found.');
        }
        return super.execute(status);
    }
}

export class GetCurrentUserSettingsHelper extends WorkflowModuleSettingsHelper {
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'getCurrentUserModule';
        reg.Caption = '@@Aktuellen Benutzer auslesen';
        reg.Index = 50;
        reg.SettingsTypeHelper = new GetCurrentUserSettingsHelper();
        reg.Executer = GetCurrentUserModuleExecuter;
        reg.ObsoleteInfo = new ObsoleteInfo();
        reg.ObsoleteInfo.ReplacedBy = GetUserPropertiesSettings.ModuleID;
        return reg;
    }

    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetCurrentUserSettingsData();
    }
}

@Component({
    selector: 'get-user-properties-wf-settings',
    templateUrl: './get.user.properties.settings.html'
})
export class GetUserPropertiesSettings extends WorkflowDialogContent {
    static ModuleID = 'getUserPropertiesWFModule';

    Data = new GetUserPropertiesSettingsData();
    Properties = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = GetUserPropertiesSettings.ModuleID;
        reg.Caption = '@@Aktuellen Benutzer auslesen';
        reg.GroupID = 'generateData';
        reg.Index = 50;
        reg.SettingsControl = GetUserPropertiesSettings;
        reg.SettingsTypeHelper = new GetUserPropertiesSettingsDataHelper();
        reg.Executer = GetUserPropertiesModuleExecuter;
        return reg;
    }

    ngOnInit(): void {
        CacheService.ReadTable('a236172b-9aaa-4211-9fe9-b57daf3f3a29').then((data) => {
            if (data) {
                const fields = data['Fields'];
                if (fields) {
                    const props = [];
                    fields.forEach((f) => {
                        props.push({
                            Caption: f.TranslatedCaption,
                            Value: f.ID
                        });
                    });
                    // remove field for the time being
                    const _props = props.filter(f =>
                        // middle name ID
                        f.Value != 'ee017661-a266-4baf-bf52-8f9e5918dd1f' &&
                        // image ID
                        f.Value != '2cf82fb0-6360-437e-a489-20bca3c66a1d' &&
                        // version ID
                        f.Value != 'fcd287a7-c74b-4091-b437-6d55794fdf65' &&
                        // SID ID
                        f.Value != 'bc1cf5e4-7d66-45e1-9d19-52965b265097' &&
                        // IsManagedByPortal ID
                        f.Value != 'e883d616-42fd-419f-bb92-87b33e463d27' &&
                        // IsFirstName
                        f.Value != 'b8d94fe2-6992-4746-9fe2-ca3bf9538416' &&
                        // identityID
                        f.Value != '2bfd5fc4-61bf-43ca-82c9-17dbe0c63b11'
                );

                    this.Properties = _props;
                }
            }
        });
    }

    initialize(data: any) {
        if (data) {
            const json = serialize(data);
            this.Data = deserialize(GetUserPropertiesSettingsData, json);
        }
    }

    getResult() {
        const values = [];
        this.Data.Properties.forEach(x => {
            if (x.PropertyID && (x.StatusKey || x.UseForState)) {
                values.push(x);
            }
        });
        this.Data.Properties = values;
        return this.Data;
    }

    useForStateChecked(ev, col) {
        if (ev && ev.checked && this.Data && this.Data.Properties) {
            this.Data.Properties.forEach(x => {
                x.UseForState = false;
            });
            col.UseForState = true;
        }
    }

    addValue() {
        if (this.Data) {
            if (this.Data.Properties) {
                this.Data.Properties.push(new PropertySetting());
            } else {
                this.Data.Properties = [new PropertySetting()];
            }
        }
    }

    removeValue(i) {
        if (this.Data && this.Data.Properties) {
            this.Data.Properties.splice(i, 1);
        }
    }
}

// @dynamic
export class GetUserPropertiesSettingsData extends AServiceWorkflowData {
    @Type(() => PropertySetting)
    Properties: PropertySetting[] = [];

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GetCurrentUser.GetUserPropertiesSettingsData';
    }
}

export class PropertySetting {
    PropertyID: string;
    StatusKey: string;
    UseForState = false;
}

export class GetUserPropertiesSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }

    getEmptySettingsInstance() {
        return new GetUserPropertiesSettingsData();
    }

    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            if (settings && settings.Properties) {
                const list = [];
                settings.Properties.forEach(x => {
                    if (x.StatusKey) {
                        list.push(x.StatusKey);
                    }
                });
                WorkflowModuleSettingsHelper.AddStatusKeysToState(state, list);
            }
        }
    }
}

export class GetUserPropertiesModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            const user = JSON.parse(localStorage.getItem('user'));
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const userRoles = JSON.parse(localStorage.getItem('userRoles'));
            if (user) {
                const table = await CacheService.ReadTable('a236172b-9aaa-4211-9fe9-b57daf3f3a29');
                if (table) {
                    const fields = table['Fields'];
                    if (fields) {
                        status.ActualSettings.Properties.forEach(x => {
                            let val;
                            switch (x.PropertyID) {
                                case 'bc1cf5e4-7d66-45e1-9d19-52965b265097':
                                    val = user.SID;
                                    break;
                                case 'fcd287a7-c74b-4091-b437-6d55794fdf65':
                                    val = user.Version;
                                    break;
                                case 'e4c5dd29-a312-498c-91cf-08f43c6fced7':
                                    val = user.ChangeUser;
                                    break;
                                case 'c2404eca-2d8c-4b6c-bccd-0b3401eed5a3':
                                    val = user.ChangeDate;
                                    break;
                                case 'de720c03-bd37-4c00-ade0-52fb812212bf':
                                    val = user.CreateUser;
                                    break;
                                case 'a4e1c02f-0394-4670-a9a8-059ddaba4c45':
                                    val = user.CreateDate;
                                    break;
                                case '6890cca7-8db6-449b-ade5-242269fa046c':
                                    val = userInfo.UserName;
                                    break;
                                case 'eb4b9f86-4c34-4c31-849a-6468095c461a':
                                    val = userRoles ? userRoles.toString() : '';
                                    break;
                                default:
                                    const field = fields.find(f => f.ID === x.PropertyID);
                                    if (field) {
                                        val = user[field.Name];
                                    } else {
                                        status.Logger.logWarning('GetUserProperties modul: Property with ID ' +
                                            x.PropertyID + ' not found.');
                                    }
                                    break;
                            }
                            if (x.StatusKey) {
                                status.Context.set(x.StatusKey, val);
                            }
                            if (x.UseForState === true) {
                                status.ActualParameter = val;
                            }
                        });
                        return 0;
                    }
                }
                status.Logger.logError('GetUserProperties modul: User table properties not found.');
            } else {
                status.Logger.logError('GetUserProperties modul: No user found.');
            }
        } else {
            status.Logger.logError('GetUserProperties modul: No settings found.');
        }
        return super.execute(status);
    }
}
