import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { SeriesSettings, TrendLineSettings } from '../../../models/controls/chart.model';
import { ChartTrendlineType } from '../../../models/enums/chartcurvetype.enum';
import { SeriesType } from '../../../models/enums/seriestype.enum';
import { Color } from '../../../models/style/color.model';

@Directive({
  selector: '[seriesColumnsContent]'
})
export class SeriesColumnsContentDirective { }

@Component({
    selector: 'seriesreportobject-menu-control',
    templateUrl: './series.menu.control.html',
    styleUrls: ['./series.menu.control.css']
})
export class SeriesReportObjectMenuControl {

    ColorValue: Color;
    ChangedColor: Color;
    SeriesTypes;
    TrendLineTypes;
    TrendLineType = -1;

    //#region Series
    SeriesValue: SeriesSettings;

    @Input()
    get Series() {
        return this.SeriesValue;
    }
    set Series(val) {
        this.SeriesValue = val;
        this.TrendLineType = -1;
        if (val) {
            this.ColorValue = Color.FromHex(val.Color);
            if (val.TrendLine && typeof val.TrendLine.Type === 'number') {
                this.TrendLineType = val.TrendLine.Type;
            }
        } else {
            this.ColorValue = new Color();
        }
        this.SeriesChange.emit(this.SeriesValue);
    }

    @Output() SeriesChange = new EventEmitter<any>();
    //#endregion

    //#region PlaceHolder
    PlaceHolderValue;

    @Input()
    get PlaceHolder() {
        return this.PlaceHolderValue;
    }
    set PlaceHolder(val) {
        this.PlaceHolderValue = val;
        this.PlaceHolderChange.emit(this.PlaceHolderValue);
    }

    @Output() PlaceHolderChange = new EventEmitter<any>();
    //#endregion

    //#region Columns
    ColumnsValue;

    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        this.ColumnsChange.emit(this.ColumnsValue);
    }

    @Output() ColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region Columns
    LabelColumnsValue;

    @Input()
    get LabelColumns() {
        return this.LabelColumnsValue;
    }
    set LabelColumns(val) {
        this.LabelColumnsValue = val;
        this.LabelColumnsChange.emit(this.LabelColumnsValue);
    }

    @Output() LabelColumnsChange = new EventEmitter<any>();
    //#endregion

    //#region ShowColor
    ShowColorValue = false;

    @Input()
    get ShowColor() {
        return this.ShowColorValue;
    }
    set ShowColor(val) {
        this.ShowColorValue = val;
        this.ShowColorChange.emit(this.ShowColorValue);
    }

    @Output() ShowColorChange = new EventEmitter<any>();
    //#endregion

    //#region ShowType
    ShowTypeValue = false;

    @Input()
    get ShowType() {
        return this.ShowTypeValue;
    }
    set ShowType(val) {
        this.ShowTypeValue = val;
        this.ShowTypeChange.emit(this.ShowTypeValue);
    }

    @Output() ShowTypeChange = new EventEmitter<any>();
    //#endregion

    //#region ShowTrendline
    ShowTrendlineValue = false;

    @Input()
    get ShowTrendline() {
        return this.ShowTrendlineValue;
    }
    set ShowTrendline(val) {
        this.ShowTrendlineValue = val;
        this.ShowTrendlineChange.emit(this.ShowTrendlineValue);
    }

    @Output() ShowTrendlineChange = new EventEmitter<any>();
    //#endregion

    //#region ShowDelete
    ShowDeleteValue = true;

    @Input()
    get ShowDelete() {
        return this.ShowDeleteValue;
    }
    set ShowDelete(val) {
        this.ShowDeleteValue = val;
        this.ShowDeleteChange.emit(this.ShowDeleteValue);
    }

    @Output() ShowDeleteChange = new EventEmitter<any>();
    //#endregion

    //#region MultipleAxis
    MultipleAxisValue = false;

    @Input()
    get MultipleAxis() {
        return this.MultipleAxisValue;
    }
    set MultipleAxis(val) {
        this.MultipleAxisValue = val;
        this.MultipleAxisChange.emit(this.MultipleAxisValue);
    }

    @Output() MultipleAxisChange = new EventEmitter<any>();

    get SeriesOnSecondAxis() {
        return this.SeriesValue && this.SeriesValue.TargetAxis == 1;
    }
    set SeriesOnSecondAxis(val) {
        if (this.SeriesValue) {
            this.SeriesValue.TargetAxis = val ? 1 : 0;
        }
    }
    //#endregion

    @Output() SeriesOptionChanged = new EventEmitter<string>();
    @Output() SeriesDeleted = new EventEmitter<any>();

    @ContentChild(SeriesColumnsContentDirective, { read: TemplateRef }) seriesColumnsContentTemplate;

    constructor() {
        this.SeriesTypes = EnumHelper.GetDropdownValues(SeriesType);
        this.TrendLineTypes = EnumHelper.GetDropdownValues(ChartTrendlineType);
    }

    optionChanged(val: string) {
        this.SeriesOptionChanged.emit(val);
    }

    delete() {
        this.SeriesDeleted.emit();
    }

    colorChanged(color) {
        if (color instanceof Color) {
            this.ChangedColor = color;
        }
    }

    changeColor() {
        if (this.ChangedColor instanceof Color) {
            this.ColorValue = this.ChangedColor;
            this.ChangedColor = null;
            if (this.SeriesValue) {
                this.SeriesValue.Color = Color.HexFromColor(this.ColorValue);
                this.optionChanged('Series.Color');
            }
        }
    }

    clearColor() {
        this.ColorValue = null;
        this.ChangedColor = null;
        if (this.SeriesValue) {
            this.SeriesValue.Color = null;
            this.optionChanged('Series.Color');
        }
    }

    setTrendLine(ev) {
        if (this.Series) {
            if (ev && typeof ev.value === 'number' && ev.value >= 0) {
                if (!this.Series.TrendLine) {
                    this.Series.TrendLine = new TrendLineSettings();
                }
                this.Series.TrendLine.Type = ev.value;
            } else {
                this.Series.TrendLine = null;
            }
            this.optionChanged('Series.Trendline');
        }
    }
}
