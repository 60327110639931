import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseDialog } from '../../../components/dialogs/basedialog/base.dialog';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { EnumHelper } from '../../../helpers/enum.helper';
import { RelSourceHelper } from '../../../helpers/relsource.helper';
import { Aggregation } from '../../../models/enums';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { QueryEngineService } from '../../../services/query.engine.service';

@Component({
    selector: 'rel-query-def-dialog',
    templateUrl: './rel.query.def.dialog.html',
    styleUrls: ['./rel.query.def.dialog.css']
})
export class RelQueryDefDialog implements OnInit {

    InitArgs;
    Definition;
    SelectedContainer;
    AggregationList = [];
    AllContainers = [];
    OpenSideNav = false;
    DraggedItem;
    SearchValue;
    FilteredContainers = [];

    static ShowDialog(queryDef, dataModel, usedIDs, handler) {
        BaseDialog.ShowDialog({
            ContentType: RelQueryDefDialog,
            FullScreen: true,
            Handler: r => {
                if (r && handler) {
                    handler(r);
                }
                return true;
            },
            InitArgs: {
                DataModelID: dataModel,
                QueryDef: queryDef,
                UsedIDs: usedIDs
            },
            Title: '@@Query Definition'
        });
    }

    constructor(private service: QueryEngineService, private cdRef: ChangeDetectorRef) {
        this.AggregationList = EnumHelper.GetDropdownValues(Aggregation);
    }

    Initialize(args) {
        this.InitArgs = args;
    }

    GetDialogResult() {
        const retVal = {
            Columns: [],
            Relations: []
        };
        if (this.Definition) {
            this.Definition.Container.forEach(c => {
                c.Fields.forEach(f => {
                    if (f.QueryDef && f.QueryDef.InUse === true) {
                        retVal.Columns.push({
                            UniqueID: f.QueryDef.UniqueID,
                            FieldID: f.SID,
                            ContainerID: c.SID,
                            ContainerUniqueID: c.UniqueID,
                            DatasourceID: c.DataSourceId,
                            IsCommaSeparated: f.QueryDef.IsCommaSeparated,
                            Aggregation: f.QueryDef.Aggregation
                        });
                    }
                });
            });
            retVal.Relations = this.Definition.Relations.slice();
        }
        return retVal;
    }

    OnDialogButtonClickAction(button) {
        if (button === DialogButton.Ok && this.InitArgs && this.InitArgs.UsedIDs) {

        }
        return true;
    }

    ngOnInit() {
        if (this.InitArgs && this.InitArgs.DataModelID) {
            this.service.GetRelContainer(this.InitArgs.DataModelID).subscribe(r => {
                if (r) {
                    this.AllContainers = r;
                    this.resetSearch();
                    if (this.InitArgs && this.InitArgs.QueryDef) {
                        const def = {
                            Container: [],
                            Relations: this.InitArgs.QueryDef.Relations.slice(),
                            MaxID: 0,
                            DataModelID: this.InitArgs.DataModelID
                        };
                        // Filters? Sort?
                        this.OpenSideNav = true;
                        this.InitArgs.QueryDef.Columns.forEach(c => {
                            this.OpenSideNav = false;
                            let cont = def.Container.find(defCon => defCon.UniqueID === c.ContainerUniqueID);
                            if (!cont) {
                                const allCont = this.AllContainers.find(x => x.SID === c.ContainerID);
                                if (allCont) {
                                    cont = JSON.parse(JSON.stringify(allCont));
                                    cont.UniqueID = c.ContainerUniqueID;
                                    cont.CanDelete = true;
                                    cont.Fields.forEach(f => {
                                        f.QueryDef = {
                                            InUse: false,
                                            UniqueID: -1,
                                            IsCommaSeparated: false,
                                            Aggregation: 0,
                                            CanDeselect: true
                                        };
                                    });
                                    def.Container.push(cont);
                                } else {
                                    throw new Error('Container with id ' + c.ContainerID + ' not found!');
                                }
                            }
                            const actField = cont.Fields.find(x => x.SID === c.FieldID);
                            if (actField) {
                                actField.QueryDef.InUse = true;
                                actField.QueryDef.UniqueID = c.UniqueID;
                                actField.QueryDef.IsCommaSeparated = c.IsCommaSeparated;
                                actField.QueryDef.Aggregation = c.Aggregation;
                                if (this.InitArgs.UsedIDs && this.InitArgs.UsedIDs.some(x => x === c.UniqueID)) {
                                    actField.QueryDef.CanDeselect = false;
                                    cont.CanDelete = false;
                                }
                            } else {
                                throw new Error('Field with id ' + c.FieldID + ' not found on container ' + cont.Caption + '!');
                            }
                            if (c.ContainerUniqueID > def.MaxID) {
                                def.MaxID = c.ContainerUniqueID;
                            }
                            if (c.UniqueID > def.MaxID) {
                                def.MaxID = c.UniqueID;
                            }
                        });
                        def.Container.forEach(c => {
                            c.Fields.forEach(f => {
                                if (f.QueryDef.UniqueID < 0) {
                                    f.QueryDef.UniqueID = ++def.MaxID;
                                }
                            });
                        });
                        this.Definition = def;
                    }
                    this.cdRef.detectChanges();
                }
            });
        }
    }

    contChanged(cont) {
        this.SelectedContainer = cont;
    }

    dragStarted(ev, item) {
        this.DraggedItem = item;
    }

    async drop(ev) {
        if (this.DraggedItem && this.Definition) {
            const newCont = JSON.parse(JSON.stringify(this.DraggedItem));
            newCont.UniqueID = this.Definition.MaxID + 1;
            if (this.Definition.Container.length > 0) {
                const list = [];
                this.AllContainers.forEach(ac => {
                    list.push(JSON.parse(JSON.stringify(ac)));
                });
                const newRel = await RelSourceHelper.GetValidRelation(this.InitArgs.DataModelID, newCont, this.Definition.Container,
                    this.Definition.Relations, list);
                if (newRel) {
                    const def = {
                        Container: this.Definition.Container.concat(newRel.Container),
                        Relations: this.Definition.Relations.concat(newRel.RelDefs),
                        MaxID: this.Definition.MaxID,
                        DataModelID: this.InitArgs.DataModelID
                    };
                    newRel.Container.forEach(c => {
                        c.CanDelete = true;
                        if (c.UniqueID > def.MaxID) {
                            def.MaxID = c.UniqueID;
                        }
                    });
                    newRel.Container.forEach(c => {
                        c.Fields.forEach(f => {
                            f.QueryDef = {
                                InUse: false,
                                UniqueID: ++def.MaxID,
                                IsCommaSeparated: false,
                                Aggregation: 0,
                                CanDeselect: true
                            };
                        });
                    });
                    this.Definition = def;
                    this.cdRef.detectChanges();
                } else {
                    MessageBoxHelper.ShowDialog(new TranslateFormatText('No path found'));
                }
            } else {
                const def = {
                    Container: [newCont],
                    Relations: [],
                    MaxID: newCont.UniqueID,
                    DataModelID: this.InitArgs.DataModelID
                };
                newCont.CanDelete = true;
                newCont.Fields.forEach(f => {
                    f.QueryDef = {
                        InUse: false,
                        UniqueID: ++def.MaxID,
                        IsCommaSeparated: false,
                        Aggregation: 0,
                        CanDeselect: true
                    };
                });
                this.Definition = def;
                this.cdRef.detectChanges();
            }
        }
    }

    resetSearch() {
        this.SearchValue = '';
        this.change();
    }

    change() {
        if (this.SearchValue) {
            const sv = this.SearchValue.toLocaleLowerCase();
            this.FilteredContainers = this.AllContainers.filter(v => v.Caption.toLocaleLowerCase().indexOf(sv) !== -1);
        } else {
            this.FilteredContainers = this.AllContainers.slice();
        }
    }

    selectAll(ev) {
        if (ev && this.SelectedContainer) {
            if (ev.checked) {
                this.SelectedContainer.Fields.forEach(f => {
                    f.QueryDef.InUse = true;
                });
            } else {
                this.SelectedContainer.Fields.forEach(f => {
                    if (f.QueryDef.CanDeselect) {
                        f.QueryDef.InUse = false;
                    }
                });
            }
        }
    }
}
