import { Component } from '@angular/core';
import { deserialize, serialize, Type } from 'class-transformer';
import { DataTypeHelper } from '../../../../helpers/datatype.helper';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-changedatatype-settings',
    templateUrl: './changedatatype.settings.html',
    styleUrls: ['./changedatatype.settings.css']
})
export class ChangeDataTypeSettings extends WorkflowDialogContent {
    ChangedColumns = [];
    DSColumns = [];
    DataTypes;

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'changedatatypeWFModule';
        reg.Caption = '@@Spaltentyp aendern';
        reg.GroupID = 'reldataoperations';
        reg.Index = 90;
        reg.SettingsControl = ChangeDataTypeSettings;
        reg.SettingsTypeHelper = new ChangeDataTypeSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor() {
        super();
        this.UseActualState = true;
        this.DataTypes = DataTypeHelper.GetDataTypes();
    }

    initialize(data: any) {
        const dataSource = this.ActualState.get('DataSource');
        if (dataSource) {
            this.DSColumns = dataSource.Columns;
        }
        if (data) {
            const json = serialize(data);
            const obj = deserialize(ChangeDataTypeSettingsData, json);
            this.ChangedColumns = obj.ChangedColumns;
        }
    }

    getResult() {
        const retVal = new ChangeDataTypeSettingsData();
        retVal.ChangedColumns = this.ChangedColumns;
        return retVal;
    }

    addColumn() {
        this.ChangedColumns.push(new ColumnTypeSettings());
    }

    removeColumn(i) {
        this.ChangedColumns.splice(i, 1);
    }
}
// @dynamic
export class ChangeDataTypeSettingsData extends AServiceWorkflowData {
    @Type(() => ColumnTypeSettings)
    ChangedColumns: ColumnTypeSettings[] = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ChangeDataType.ChangeDataTypeSettingsData';
    }
}

export class ColumnTypeSettings {
    ColumnName: string;
    NewType: string;
}

export class ChangeDataTypeSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEntryPoints(): WorkflowExitInfo[] {
        const retVal = new WorkflowExitInfo();
        retVal.Type = 'relData';
        return [retVal];
    }
    getEmptySettingsInstance() {
        return new ChangeDataTypeSettingsData();
    }
    async fillActualState(module, state, wfData) {
        if (state) {
            const settings = WorkflowModuleSettingsHelper.GetSettingsFromModule(module);
            const ds = state.get('DataSource');
            if (ds && ds.Columns && settings && settings.ChangedColumns) {
                settings.ChangedColumns.forEach(x => {
                    const col = ds.Columns.find(y => y.Name === x.ColumnName);
                    if (col) {
                        col.DataTyp = x.NewType;
                    }
                });
            }
        }
    }
}
