import { TranslatedString } from "../translatedstring.model";
import { Type } from "class-transformer";
// @dynamic
export class ABaseElement {
    SID: string;
    Version: string;
    Name: string;
    @Type(() => TranslatedString)
    Caption: TranslatedString;
    Image: string;
    IsReadOnly: boolean = false;
    IsVisible: boolean = true;
    MetaTagID: string;
}