import { Comparer } from "../enums/comparer.enum";
import { Concat } from "../enums/contact.enum";

export class RequestFilter {
    Name: string;
    Value: any;
    Value2: any;
    Operator: Comparer;
    ConcatOperator: Concat;
    Filters: RequestFilter[];
    AdditionalInfos: any;
    IsOptional = false;

    public static GetTextForComparer(comparer) {
        switch (comparer) {
            case Comparer.Equal: return '=';
            case Comparer.NotEqual: return '!=';
            case Comparer.Smaller: return '<';
            case Comparer.SmallerEqual: return '<=';
            case Comparer.Greater: return '>';
            case Comparer.GreaterEqual: return '>=';
            case Comparer.Like: return 'like';
            case Comparer.NotLike: return 'notlike';
            case Comparer.In: return 'in';
            case Comparer.NotIn: return 'notin';
            case Comparer.Between: return 'between';
            case Comparer.NotBetween: return 'notbetween';
            default: return null;
        }
    }

    public static GetComparerFromText(text) {
        switch (text) {
            case '=': return Comparer.Equal;
            case '!=': return Comparer.NotEqual;
            case '<': return Comparer.Smaller;
            case '<=': return Comparer.SmallerEqual;
            case '>': return Comparer.Greater;
            case '>=': return Comparer.GreaterEqual;
            case 'like': return Comparer.Like;
            case 'notlike': return Comparer.NotLike;
            case 'in': return Comparer.In;
            case 'notin': return Comparer.NotIn;
            case 'between': return Comparer.Between;
            case 'notbetween': return Comparer.NotBetween;
            default: return null;
        }
    }

    public static GetComparerForType(type: string): string[] {
        const retVal = [];
        switch (type) {
            case 'string':
                retVal.push(this.GetTextForComparer(Comparer.Equal));
                retVal.push(this.GetTextForComparer(Comparer.NotEqual));
                retVal.push(this.GetTextForComparer(Comparer.Like));
                retVal.push(this.GetTextForComparer(Comparer.NotLike));
                retVal.push(this.GetTextForComparer(Comparer.In));
                retVal.push(this.GetTextForComparer(Comparer.NotIn));
                retVal.push(this.GetTextForComparer(Comparer.Between));
                retVal.push(this.GetTextForComparer(Comparer.NotBetween));
                break;
            case 'date':
            case 'number':
            case 'float':
                retVal.push(this.GetTextForComparer(Comparer.Equal));
                retVal.push(this.GetTextForComparer(Comparer.NotEqual));
                retVal.push(this.GetTextForComparer(Comparer.Smaller));
                retVal.push(this.GetTextForComparer(Comparer.SmallerEqual));
                retVal.push(this.GetTextForComparer(Comparer.Greater));
                retVal.push(this.GetTextForComparer(Comparer.GreaterEqual));
                retVal.push(this.GetTextForComparer(Comparer.In));
                retVal.push(this.GetTextForComparer(Comparer.NotIn));
                retVal.push(this.GetTextForComparer(Comparer.Between));
                retVal.push(this.GetTextForComparer(Comparer.NotBetween));
                break;
            case 'boolean':
                retVal.push(this.GetTextForComparer(Comparer.Equal));
                retVal.push(this.GetTextForComparer(Comparer.NotEqual));
                break;
        }
        return retVal;
    }

    public static getRequestFilterText(reqFilter): string {
        if (reqFilter.Filters && reqFilter.Filters.length > 0) {
            if (reqFilter.Filters.length > 1) {
                const retVal = [];
                reqFilter.Filters.forEach((filter) => {
                    retVal.push(RequestFilter.getRequestFilterText(filter));
                });
                return '(' + retVal.join(reqFilter.ConcatOperator === Concat.And ? ' AND ' : ' OR ') + ')';
            } else {
                return RequestFilter.getRequestFilterText(reqFilter.Filters[0]);
            }
        } else {
            let retVal = reqFilter.Name + ' ' + RequestFilter.GetTextForComparer(reqFilter.Operator) + ' ' + reqFilter.Value;
            if (reqFilter.Operator === Comparer.Between || reqFilter.Operator === Comparer.NotBetween) {
                retVal += ' and ' + reqFilter.Value2;
            }
            return retVal;
        }
    }
}
