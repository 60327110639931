import { MessageBoxHelper } from "../components/dialogs/messagebox/messagebox.dialog";
import { MessageBoxButtons } from "../models/enums/messageboxbuttons.enum";
import { MessageBoxIcon } from "../models/enums/messageboxicon.enum";
import { MessageBoxResult } from "../models/enums/messageboxresult.enum";
import { StandardRequestBase } from "../services/request-base";
import { TranslateFormatText } from "./array.helpers";
import { InjectorHelper, TranslateHelper } from "./injector.helper";


export class RelSourceHelper {

    static async GetValidRelation(dataModelID, container, targetContainers, usedRelations, allContainer, relations?) {
        let retVal = null;
        if (targetContainers && targetContainers.length > 0) {
            if (!relations) {
                const service = InjectorHelper.InjectorInstance.get<StandardRequestBase>(StandardRequestBase);
                relations = await service.executeGet('api/selfservice/datasettings',
                    'GetAllRelations', 'dataModel=' + dataModelID).toPromise();
            }
            const relInfo = RelSourceHelper.GetValidRelationInternal(container, targetContainers, usedRelations, relations);
            if (relInfo.RelInfo) {
                retVal = {
                    Container: [container],
                    RelDefs: [relInfo.RelInfo]
                };
            } else if (relInfo.ContRels.length > 0) {
                let path;
                relInfo.ContRels.some(cr => {
                    const fromRelInfo =
                        RelSourceHelper.GetValidRelationInternal({ SID: cr.Target }, targetContainers, [{ Relation: cr.SID }], relations);
                    if (fromRelInfo.RelInfo) {
                        path = [cr.SID, fromRelInfo.RelInfo.Relation];
                        return true;
                    } else {
                        const paths = RelSourceHelper.GetRelPaths(fromRelInfo, targetContainers, [container.SID, cr.Target], relations);
                        paths.forEach(p => {
                            if (!path || p.length < path.length - 1) {
                                path = [cr.SID].concat(p);
                            }
                        });
                    }
                    return false;
                });
                if (path) {
                    let nextUniqueID = container.UniqueID;
                    const addConts = [container];
                    const addRels = [];
                    let text = TranslateHelper.TranslatorInstance.instant('@@Folgende Container werden fuer eine Verbindung benoetigt') +
                        ':\n\n';
                    let actCont = container;
                    for (let i = 0; i < path.length - 1; i++) {
                        nextUniqueID++;
                        const rel = RelSourceHelper.GetEntryBySID(relations, path[i]);
                        let contObj;
                        let relObj;
                        if (rel.PrimaryContainer === actCont.SID) {
                            contObj = RelSourceHelper.GetEntryBySID(allContainer, rel.SecondaryContainer);
                            relObj = {
                                FromContainerUniqueID: actCont.UniqueID,
                                FromContainer: actCont.SID,
                                FromDataSource: actCont.DataSourceId,
                                ToContainerUniqueID: nextUniqueID,
                                ToContainer: contObj.SID,
                                ToDataSource: contObj.DataSourceId,
                                Relation: rel.SID
                            };
                        } else {
                            contObj = RelSourceHelper.GetEntryBySID(allContainer, rel.PrimaryContainer);
                            relObj = {
                                FromContainerUniqueID: nextUniqueID,
                                FromContainer: contObj.SID,
                                FromDataSource: contObj.DataSourceId,
                                ToContainerUniqueID: actCont.UniqueID,
                                ToContainer: actCont.SID,
                                ToDataSource: actCont.DataSourceId,
                                Relation: rel.SID
                            };
                        }
                        text += contObj.Caption + '\n';
                        contObj.UniqueID = nextUniqueID;
                        actCont = contObj;
                        addConts.push(actCont);
                        addRels.push(relObj);
                    }
                    const lastRel = RelSourceHelper.GetEntryBySID(relations, path[path.length - 1]);
                    if (lastRel.PrimaryContainer === actCont.SID) {
                        const sec = RelSourceHelper.GetEntryBySID(targetContainers, lastRel.SecondaryContainer);
                        addRels.push({
                            FromContainerUniqueID: actCont.UniqueID,
                            FromContainer: actCont.SID,
                            FromDataSource: actCont.DataSourceId,
                            ToContainerUniqueID: sec.UniqueID,
                            ToContainer: sec.SID,
                            ToDataSource: sec.DataSourceId,
                            Relation: lastRel.SID
                        });
                    } else {
                        const prim = RelSourceHelper.GetEntryBySID(targetContainers, lastRel.PrimaryContainer);
                        addRels.push({
                            FromContainerUniqueID: prim.UniqueID,
                            FromContainer: prim.SID,
                            FromDataSource: prim.DataSourceId,
                            ToContainerUniqueID: actCont.UniqueID,
                            ToContainer: actCont.SID,
                            ToDataSource: actCont.DataSourceId,
                            Relation: lastRel.SID
                        });
                    }
                    text += '\n' + TranslateHelper.TranslatorInstance.instant('@@Wollen Sie die zusaetzlichen Container hinzufuegen?');
                    const mbResult = await MessageBoxHelper.ShowDialog(new TranslateFormatText(text),
                        new TranslateFormatText('@@Container hinzufuegen'), MessageBoxButtons.YesNo, MessageBoxIcon.Question);
                    if (mbResult === MessageBoxResult.Yes) {
                        retVal = {
                            Container: addConts,
                            RelDefs: addRels
                        };
                    } else {
                        retVal = {
                            Container: [],
                            RelDefs: []
                        };
                    }
                }
            }
        }
        return retVal;
    }

    private static GetEntryBySID(list, sid) {
        let retVal = null;
        list.some(entry => {
            if (entry.SID === sid) {
                retVal = entry;
                return true;
            }
            return false;
        });
        return retVal;
    }

    private static GetRelPaths(relInfo, targetContainers, usedContainers, relations) {
        const retVal = [];
        relInfo.ContRels.forEach(cr => {
            if (!usedContainers.some(tc => tc === cr.Target)) {
                const fromRelInfo =
                    RelSourceHelper.GetValidRelationInternal({ SID: cr.Target }, targetContainers, [{ Relation: cr.SID }], relations);
                if (fromRelInfo.RelInfo) {
                    retVal.push([cr.SID, fromRelInfo.RelInfo.Relation]);
                } else {
                    const paths = RelSourceHelper.GetRelPaths(fromRelInfo, targetContainers, usedContainers.concat([cr.Target]), relations);
                    paths.forEach(p => {
                        retVal.push([cr.SID].concat(p));
                    });
                }
            }
        });
        return retVal;
    }

    private static GetValidRelationInternal(container, targetContainers, usedRelations, relations) {
        const retVal = {
            RelInfo: null,
            ContRels: []
        };
        if (relations && relations.length > 0) {
            relations.some(r => {
                if (usedRelations && usedRelations.some(ur => r.SID === ur.Relation)) {
                    return false;
                }
                if (r.PrimaryContainer === container.SID) {
                    retVal.ContRels.push({
                        SID: r.SID,
                        Target: r.SecondaryContainer
                    });
                    return targetContainers.some(uc => {
                        if (uc.SID === r.SecondaryContainer) {
                            retVal.RelInfo = {
                                FromContainerUniqueID: container.UniqueID,
                                FromContainer: container.SID,
                                FromDataSource: container.DataSourceId,
                                ToContainerUniqueID: uc.UniqueID,
                                ToContainer: uc.SID,
                                ToDataSource: uc.DataSourceId,
                                Relation: r.SID
                            };
                            return true;
                        }
                        return false;
                    });
                } else if (r.SecondaryContainer === container.SID) {
                    retVal.ContRels.push({
                        SID: r.SID,
                        Target: r.PrimaryContainer
                    });
                    return targetContainers.some(uc => {
                        if (uc.SID === r.PrimaryContainer) {
                            retVal.RelInfo = {
                                FromContainerUniqueID: uc.UniqueID,
                                FromContainer: uc.SID,
                                FromDataSource: uc.DataSourceId,
                                ToContainerUniqueID: container.UniqueID,
                                ToContainer: container.SID,
                                ToDataSource: container.DataSourceId,
                                Relation: r.SID
                            };
                            return true;
                        }
                        return false;
                    });
                }
                return false;
            });
        }
        return retVal;
    }

    static FindConnection(sourceID, targetID, allRelations) {
        return RelSourceHelper.FindConnectionInternal(sourceID, targetID, allRelations, null);
    }

    private static FindConnectionInternal(sourceID, targetID, allRelations, actRel) {
        let retVal;
        allRelations.some(rel => {
            if (rel.Relation !== actRel) {
                let relTarget;
                if (rel.FromContainerUniqueID === sourceID) {
                    relTarget = rel.ToContainerUniqueID;
                } else if (rel.ToContainerUniqueID === sourceID) {
                    relTarget = rel.FromContainerUniqueID;
                }
                if (typeof relTarget === 'number') {
                    if (relTarget === targetID) {
                        retVal = rel;
                        return true;
                    } else {
                        const nextStep = RelSourceHelper.FindConnectionInternal(relTarget, targetID, allRelations, rel.Relation);
                        if (nextStep) {
                            retVal = rel;
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        return retVal;
    }
}
