import { BaseDataSourcePanel } from '../basedatasource.panel';
import { ChangeDetectorRef, Component } from '@angular/core';
import { DBPanelInfo, ConnDBInfo } from '../../../../../models/datamodel/adbinfo.model';
import { ConnectorService } from '../../../../../services/connector.service';

@Component({
    selector: 'connectors-panel',
    templateUrl: './connectors.panel.html',
    styleUrls: ['./connectors.panel.css']
})
export class ConnectorsPanel extends BaseDataSourcePanel {

    ConnectorConfigurations = [];
    Variables = [];

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: ConnectorsPanel,
            DBInfoType: ConnDBInfo
        };
    }

    constructor(private service: ConnectorService, private cdRef: ChangeDetectorRef) {
        super();
        this.DBInfoType = ConnDBInfo;
    }

    ngOnInit(): void {
        this.service.GetConnectorInfos().subscribe(result => {
            if (result) {
                this.ConnectorConfigurations = result;
            }
            this.cdRef.detectChanges();
        });
        if (this.DBInfo.ConnectorId) {
            this.SetVariables();
        }
    }

    ConnectorChanged(ev): void {
        this.SetVariables();
        this.onChange(ev);
    }

    private SetVariables() {
        this.service.GetConnector(this.DBInfo.ConnectorId).subscribe(result => {
            if (result) {
                this.Variables = result.Variables;
                var keys = Object.keys(this.DBInfo.Values);
                keys.forEach((key) => {
                    this.Variables.forEach((val, ind, array) => {
                        if (val.Name == key) {
                            val.Value = this.DBInfo.Values[key];
                        }
                    });
                });
            }
            this.cdRef.detectChanges();
        });
    }

    OnValueChanged(ev, key: string) {
        this.Variables.forEach((val, ind, array) => {
            if (val.Name == key) {
                this.DBInfo.Values[key] = val.Value;
            }
        });
        this.onChange(null);
    }
}
