import { BaseDialog } from "../components/dialogs/basedialog/base.dialog";
import { DataSettingsService } from "../services/data.settings.service";
import { ContainerPathChooserDialog } from "../settings/datamodel/dialogs/containerpath.chooser.dialog";
import { DataCheck } from "../workflow/workflow.dialog";
import { InjectorHelper, TranslateHelper } from "./injector.helper";

export class ContainerPathHelper {

    static async checkPathsForPathObject(containers, pathObject, containerTables) {
        var retVal = new DataCheck();
        // Pr�fen, ob Pfad bereits gesetzt
        var pathsSet = false;
        if (pathObject.Paths && pathObject.Paths.length == containers.length - 1) {
            var contCopy = containers.concat([]);
            var actCont = pathObject.Paths[0].FromContainer;
            var found = false;
            for (var i = 0; i < contCopy.length; i++) {
                if (contCopy[i].SID == actCont) {
                    contCopy.splice(i, 1);
                    found = true;
                    break;
                }
            }
            if (found) {
                for (var j = 0; j < pathObject.Paths.length; j++) {
                    found = false;
                    if (pathObject.Paths[j].FromContainer == actCont) {
                        actCont = pathObject.Paths[j].ToContainer;
                        for (var i = 0; i < contCopy.length; i++) {
                            if (contCopy[i].SID == actCont) {
                                contCopy.splice(i, 1);
                                found = true;
                                break;
                            }
                        }
                    } else {
                        break;
                    }
                }
                if (found) {
                    pathsSet = true;
                }
            }
        }
        if (!pathsSet) {
            const service = InjectorHelper.InjectorInstance.get<DataSettingsService>(DataSettingsService);
            var relations = await service.GetAllRelations(pathObject.DataModelID).toPromise();
            try {
                let containerList = [];
                containers.forEach(function (c) {
                    containerList.push(c.SID);
                });
                let pathCheck = ContainerPathHelper.GetAllPaths(containerList, relations);
                if (pathCheck.NoPathsFound.length > 0) {
                    var noPaths = [];
                    pathCheck.NoPathsFound.forEach(function (npf) {
                        var path = npf;
                        containerTables.some(function (cl) {
                            if (cl.SID == npf) {
                                path = cl.Caption;
                                return true;
                            }
                            return false;
                        });
                        noPaths.push(path);
                    });
                    retVal.IsCorrect = false;
                    retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Kein Pfad gefunden fuer folgende Attributspalten:') + '\n' + noPaths.join(', ');
                } else if (pathCheck.PossiblePathLists.length == 0) {
                    retVal.IsCorrect = false;
                    retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Keine moeglicher Pfad zwischen allen Attributspalten');
                } else if (pathCheck.PossiblePathLists.length == 1) {
                    // Pfad setzen
                    pathObject.Paths = pathCheck.PossiblePathLists[0];
                } else {
                    // Pfad w�hlen
                    retVal.IsCorrect = false;
                    retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Bitte waehlen Sie einen passenden Pfad.');
                    var args = {
                        Paths: [],
                        Fields: containers,
                        Relations: relations,
                        Containers: containerTables
                    };
                    pathCheck.PossiblePathLists.forEach(function (p) {
                        args.Paths.push({
                            PathList: p
                        });
                    });
                    BaseDialog.ShowDialog({
                        ContentType: ContainerPathChooserDialog,
                        InitArgs: args,
                        Title: '@@Pfad waehlen',
                        Handler: function (p) {
                            if (p) {
                                pathObject.Paths = p;
                            }
                        }
                    });
                }
            } catch (e) {
                retVal.IsCorrect = false;
                retVal.Error = TranslateHelper.TranslatorInstance.instant('@@Fehler beim Finden der Pfade zwischen den Attributspalten') + ' (' + e.message + ')';
            }
        }
        return retVal;
    }

    static GetAllPaths(containerIDs: string[], relations: any[]) {
        var retVal = {
            NoPathsFound: [],
            PossiblePathLists: []
        };
        if (containerIDs && containerIDs.length > 1 && relations && relations.length > 0) {
            var containerPaths = new Map<string, any>();
            for (var i = 0; i < containerIDs.length - 1; i++) {
                var firstCont = containerIDs[i];
                var mapEntry = containerPaths.get(firstCont);
                if (!mapEntry) {
                    mapEntry = new Map<string, any>();
                }
                for (var j = i + 1; j < containerIDs.length; j++) {
                    var secCont = containerIDs[j];
                    var paths = ContainerPathHelper.FindRelationPath(firstCont, secCont, relations, [], [firstCont]);
                    if (paths.length > 0) {
                        mapEntry.set(secCont, paths);
                        var reverseMap = containerPaths.get(secCont);
                        if (!reverseMap) {
                            reverseMap = new Map<string, any>();
                            containerPaths.set(secCont, reverseMap);
                        }
                        var outerList = [];
                        paths.forEach(function (path) {
                            var innerList = [];
                            path.forEach(function (p) {
                                innerList.splice(0, 0, p);
                            });
                            outerList.push(innerList);
                        });
                        reverseMap.set(firstCont, outerList);
                    }
                }
                if (mapEntry.size == 0) {
                    retVal.NoPathsFound.push(firstCont);
                } else {
                    containerPaths.set(firstCont, mapEntry);
                }
            }
            if (retVal.NoPathsFound.length == 0) {
                // Wenn f�r alle Container mindestens 1 Pfad gefunden wurden, versuchen alle Ketten zu bilden
                containerPaths.forEach(function (v, k) {
                    ContainerPathHelper.FindAllPaths([k], containerPaths, containerIDs.length, function (path) {
                        retVal.PossiblePathLists = retVal.PossiblePathLists.concat(ContainerPathHelper.FillPaths(0, path, containerPaths));
                    });
                });
            }
        }
        return retVal;
    }

    private static FindRelationPath(firstCont, targetCont, relations: any[], usedRels: string[], usedConts: string[]): any[] {
        var retVal = [];
        relations.forEach(function (rel) {
            if (!usedRels.some(function (r) { return r == rel.SID; })) {
                if (rel.PrimaryContainer == firstCont) {
                    if (rel.SecondaryContainer == targetCont) {
                        retVal.push([rel.SID]);
                    } else if (!usedConts.some(function (c) { return c == rel.SecondaryContainer; })) {
                        var pathLists = ContainerPathHelper.FindRelationPath(rel.SecondaryContainer, targetCont, relations, usedRels.concat([rel.SID]), usedConts.concat([rel.SecondaryContainer]));
                        pathLists.forEach(function (path) {
                            if (path.some(function (p) { return p == rel.SID; })) {
                                throw 'Circle between container relations';
                            }
                            retVal.push([rel.SID].concat(path));
                        });
                    }
                } else if (rel.SecondaryContainer == firstCont) {
                    if (rel.PrimaryContainer == targetCont) {
                        retVal.push([rel.SID]);
                    } else if (!usedConts.some(function (c) { return c == rel.PrimaryContainer; })) {
                        var pathLists = ContainerPathHelper.FindRelationPath(rel.PrimaryContainer, targetCont, relations, usedRels.concat([rel.SID]), usedConts.concat([rel.PrimaryContainer]));
                        pathLists.forEach(function (path) {
                            if (path.some(function (p) { return p == rel.SID; })) {
                                throw 'Circle between container relations';
                            }
                            retVal.push([rel.SID].concat(path));
                        });
                    }
                }
            }
        });
        return retVal;
    }

    private static FindAllPaths(actPath, dict, length, setFull) {
        var actCont = actPath[actPath.length - 1];
        var dictEntry = dict.get(actCont);
        dictEntry.forEach(function (v, k) {
            if (!actPath.some(function (p) { return p == k; })) {
                var newPath = actPath.concat([k]);
                if (newPath.length == length) {
                    setFull(newPath);
                } else {
                    ContainerPathHelper.FindAllPaths(newPath, dict, length, setFull);
                }
            }
        });
    }

    private static FillPaths(index, pathList, dict): any[] {
        var retVal = [];
        var from = pathList[index];
        var to = pathList[index + 1];
        var innerDict = dict.get(from);
        var list = innerDict.get(to);
        if (index == pathList.length - 2) {
            list.forEach(function (entry) {
                retVal.push([{
                    FromContainer: from,
                    ToContainer: to,
                    Path: entry
                }]);
            });
        } else {
            var nextList = ContainerPathHelper.FillPaths(index + 1, pathList, dict);
            list.forEach(function (entry) {
                nextList.forEach(function (next) {
                    retVal.push([{
                        FromContainer: from,
                        ToContainer: to,
                        Path: entry
                    }].concat(next));
                });
            });
        }
        return retVal;
    }

    static GetExcelColumnName(columnNumber: number): string {
        let retVal = '';
        let modulo;
        while (columnNumber > 0) {
            modulo = (columnNumber - 1) % 26;
            retVal = String.fromCharCode(modulo + 65) + retVal;
            columnNumber = Math.floor((columnNumber - modulo) / 26);
        }
        return retVal;
    }
}