import { Component } from '@angular/core';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { PreviousLevelExecuter } from '../../../models/tasks/datatasks/previous.level.model';
import { AMeasurePanel, AMeasureTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'previous-level-task-panel',
    templateUrl: './previous.level.task.panel.html'
})
export class PreviousLevelTaskPanel extends AMeasurePanel {
    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = PreviousLevelExecuter.TaskID;
        reg.Caption = '@@Levelsumme';
        reg.SettingsControl = PreviousLevelTaskPanel;
        reg.SettingsHelper = new PreviousLevelSettingsHelper();
        reg.Executer = PreviousLevelExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }
}

export class PreviousLevelSettingsHelper extends AMeasureTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Levelsumme');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Levelsumme');
    }
}
