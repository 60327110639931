import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { CheckBoxThemeControl } from '../../../appbuilder/controls/checkbox/checkbox.control';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
import { FileSelectionThemeControl } from '../../../appbuilder/controls/fileselection/fileselection.theme.control';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { InjectorHelper } from '../../../helpers/injector.helper';
import { MediaSource } from '../../../models/datamodel/mediasource.model';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { MenuTabLabelPosition } from '../../../models/menutab/menutab.property.model';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { IBaseComponent } from '../base.component';

@Component({
    selector: 'evi-video',
    templateUrl: './video.control.html',
    styleUrls: ['./video.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoControl extends IBaseComponent implements OnDestroy {
    static Type: any = 'video';
    static Default = { IsCheckedValue: false, Type: 'video',Layout: {
        _Editable: true,
        Width: { Type: 0, Value:400},
        Height: {Type:0,Value:275}
    } };

    URL;

    options: {
        controls: boolean,
        fluid: boolean,
        aspectRatio: string,
        autoplay: any,
        muted:any,
        sources: {
            src: string,
            type: string,
        }[],
    };
    MediaSources;
    constructor(private mediaService: MediaService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
        this.URL = false;
    }
    onLayoutElementSet() {
        this.Subscriptions['MediaSources'] = MediaService.MediaSources.subscribe((sources) => {
            if (sources) {
                this.MediaSources = sources;
                this.Refresh();
            }
        });
        if (this.LayoutElementValue.ValuesChanged) {
            this.Subscriptions['ValuesChanged'] = this.LayoutElementValue.ValuesChanged.subscribe(() => {
                this.Refresh();
            })
        }
    }
    Refresh() {
        const source = this.MediaSources.find(x => x.SID === this.LayoutElement.MediaSource);
        if (source) {
            if (source.Private) {
                //this.mediaService.LoadImage(source.SID, this.LayoutElement.FileName).subscribe(() => {
                //});
            } else {
                this.options = null;
                setTimeout(() => {
                    this.options = {
                        controls: this.Editable || !this.Disabled,
                        fluid: true,
                        aspectRatio: '16:9',
                        autoplay: this.LayoutElement.AutoPlay ? 'play' : false,
                        muted:this.LayoutElement.AutoPlay ? true : false,
                        sources: [{
                            src: MediaSource.GetURL(source, this.LayoutElement.FileName),
                            type: 'video/mp4'
                        }]
                    }
                    this.cdRef.detectChanges();
                }, 10);
            }            
        }
    }
    ControlInitialized() {
    }
}
export class VideoPanel extends BasePanel {
    static override SIDS = ['35fe326b-eb00-4b87-bb10-a7f84cddb2dd']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'35fe326b-eb00-4b87-bb10-a7f84cddb2dd',
            ID: 'video',
            Caption: '@@Video',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto', 'auto', 'auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'video';
            }
        })
        let mediaService = InjectorHelper.InjectorInstance.get<MediaService>(MediaService);
        mediaService.GetUsable().subscribe((data) => {
            PROPERTIES.push({
                ID: "MediaSource",
                Parent: "video",
                Content: new ComponentPortal(ComboboxThemeControl),
                Label: "@@SelectMediaSource",
                Row: 1,
                CheckVisibility: (item) => {
                    return !item.UseForBlob;
                },
                InitArgs: {
                    Placeholder: "@@Select",
                    Multiple: false,
                    ItemsSource: data,
                    DisplayMemberPath: 'Caption',
                    ValueMemberPath: 'SID'
                }
            })
        });
        PROPERTIES.push({
            ID: "FileName",
            Parent: "video",
            Content: new ComponentPortal(FileSelectionThemeControl),
            Row: 2,
            CheckVisibility: (item) => {
                return item.MediaSource;
            }
        })

        PROPERTIES.push({
            ID: "AutoPlay",
            Parent: "video",
            Content: new ComponentPortal(CheckBoxThemeControl),
            InitArgs: {
                Caption: "@@AutoPlay"
            },
            Row: 3,
            CheckVisibility: (item) => {
                return item.MediaSource && item.FileName;
            }
        })
    }
}