import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { BaseAction } from '../../base.action';


@Component({
  selector: 'empty-action',
  templateUrl: './empty.action.html',
  styleUrls: ['./empty.action.css'],
})
export class EmptyAction extends BaseAction implements OnInit, OnDestroy {

    public static Name = 'empty';

    ControlInitialized() {

    }
    ExecuteAction() {
        
    }

    Visibility() {
        return true;
    }
}
