import { BehaviorSubject } from 'rxjs';
import { FilterHelper } from '../../../../helpers/filter.helper';
import { Row } from '../../../../models/basic/row.model';
import { PagingType } from '../../../../models/enums/pagingtype.enum';
import { TableStyle } from '../../../../models/enums/tablestyle.enum';
import { RequestColumn } from '../../../../models/rest/requestcolumn';
import { RequestFilter } from '../../../../models/rest/requestfilter';
import { RequestOptions } from '../../../../models/rest/requestoptions';
import { RequestSort } from '../../../../models/rest/requestsort';
import { DynamicDataService } from '../../../../services/dynamicdata.service';

export abstract class ATableDataSource {
    protected PageCache = {};

    //#region PageSize
    private _pageSize = 300;
    get pageSize(): number {
        return this._pageSize;
    }
    set pageSize(val: number) {
        this._pageSize = val;
        if (typeof (this._pageSize) !== 'number') {
            this._pageSize = Number(this._pageSize);
        }
        this.PageCache = {};
    }
    //#endregion

    //#region Columns
    protected DynamicResultSize = false;
    private ColumnsValue = [];
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(value) {
        if (Array.isArray(value)) {
            this.DynamicResultSize = value.some(col => (col.ParentIsList || col.IsList) && col.IsCommaSeparated == false);
            this.ColumnsValue = value;
        } else {
            this.DynamicResultSize = false;
            this.ColumnsValue = [];
        }
    }
    //#endregion

    public UseCaching;
    public PagingType: PagingType = PagingType.Scroll;
    public FilteredCount = 0;
    public cachedData = Array.from<any>({ length: this.FilteredCount });
    public PagedData: any[] = [];

    public Initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public Loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public abstract SetFilter(filter: RequestFilter[]);
    public abstract SetRequestColumns(columns: RequestColumn[]);
    public abstract SetOrder(order: RequestSort[]);
    public abstract RefreshAndFetch(start: number, end: number);
    protected abstract fetchPage(page: number, pageSize: number);

    public Initialize() {
        this.Refresh();
    }

    public Refresh() {
        this.RefreshAndFetch(0, this.pageSize);
    }

    public fetch(start: number, end: number) {
        const ps = this.pageSize;
        const startPage = Math.floor(start / ps);
        let endPage = Math.floor(end / ps);
        if (end > 0 && end % ps == 0) {
            endPage--;
        }
        if (startPage == endPage && this.PagingType == PagingType.Pager) {
            const loaded = this.PageCache[startPage];
            if (loaded) {
                this.PagedData = this.cachedData.slice(startPage * ps, (startPage * ps) + ps);
                this.cachedData = [...this.cachedData];
                return;
            }
        }
        for (let i = startPage; i <= endPage; i++) {
            const loaded = this.PageCache[i];
            if (!loaded) {
                this.Loading.next(true);
                this.PageCache[i] = [];
                this.fetchPage(i, ps);
            }
        }
    }
}

export class DataTableDataSource extends ATableDataSource {
    
    private TableStyle: TableStyle = TableStyle.DataTable;
    private DataSourceID;
    private queryId;
    private TabIndex = 0;
    private Filter: RequestOptions = new RequestOptions();
    private DataService: DynamicDataService;    
    private _RowStyle = null;

    constructor(DataSourceID, Service, tableStyle: TableStyle) {
        super();
        this.DataSourceID = DataSourceID;
        this.DataService = Service;
        if (typeof tableStyle === 'number') {
            this.TableStyle = tableStyle;
        }
    }

    public SetRequestColumns(Columns: RequestColumn[]) {
        if (Columns && Columns.length > 0) {
            this.Filter.Columns = Columns;
            const rs = {};
            Columns.forEach(col => {
                rs[col.Name] = '0';
            });
            this._RowStyle = rs;
        } else {
            this.Filter.Columns = [];
            this._RowStyle = null;
        }
    }
    public SetFilter(Filter: RequestFilter[]) {
        this.Filter.Filters = Filter;
    }
    public SetOrder(Sort: RequestSort[]) {
        this.Filter.Sort = Sort;
    }    

    protected fetchPage(page: number, pageSize: number) {
        if (this.UseCaching) {
            this.DataService.ExecuteQuery(this.queryId, page * pageSize, (page + 1) * pageSize).subscribe((result) => {
                this.FillResult(result, page);
            });
        } else {
            this.Filter.StartRow = page * pageSize;
            this.Filter.EndRow = this.Filter.StartRow + pageSize;
            // if(this.Filter.Sort.length===0){
            //     this.Loading.next(false)
            //     return
            // }
            this.DataService.ExecuteQueryWithFilter(this.DataSourceID, this.Filter).subscribe((result) => {
                this.FillResult(result, page);
            });
        }
    }

    public Refresh() {
        this.RefreshAndFetch(0, this.pageSize);
    }

    public RefreshAndFetch(start: number, end: number) {
        this.TabIndex = 0;
        this.PageCache = {};
        if (this.Filter && this.Filter.Columns && this.Filter.Columns.length > 0) {
            if (this.UseCaching) {
                if (this.queryId) {
                    this.DataService.CloseQuery(this.queryId).subscribe((r) => {
                        this.DataService.OpenQuery(this.DataSourceID, this.Filter).subscribe((result) => {
                            if (result) {
                                this.queryId = result;
                                if (this.PagingType === PagingType.Endless) {
                                    this.FilteredCount = this.pageSize * 2;
                                    this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                                    this.Initialized.next(true);
                                    this.fetch(start, end);
                                } else {
                                    this.DataService.ExecuteCountQuery(this.queryId).subscribe(res => {
                                        if (res) {
                                            this.FilteredCount = res;
                                        } else {
                                            this.FilteredCount = 0;
                                        }
                                        this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                                        this.Initialized.next(true);
                                        this.fetch(start, end);
                                    });
                                }
                            }
                        });
                    });
                } else {
                    this.DataService.OpenQuery(this.DataSourceID, this.Filter).subscribe((result) => {
                        if (result) {
                            this.queryId = result;
                            if (this.PagingType === PagingType.Endless) {
                                this.FilteredCount = this.pageSize * 2;
                                this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                                this.Initialized.next(true);
                                this.fetch(start, end);
                            } else {
                                this.DataService.ExecuteCountQuery(this.queryId).subscribe((data) => {
                                    if (data) {
                                        this.FilteredCount = data;
                                    } else {
                                        this.FilteredCount = 0;
                                    }
                                    this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                                    this.Initialized.next(true);
                                    this.fetch(start, end);
                                });
                            }
                        }
                    });
                }
            } else {
                if (this.PagingType === PagingType.Endless) {
                    this.FilteredCount = this.pageSize * 2;
                    this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                    this.Initialized.next(true);
                    this.fetch(start, end);
                } else {
                    this.DataService.ExecuteCount(this.DataSourceID, this.Filter).subscribe((data) => {
                        if (data) {
                            this.FilteredCount = data;
                        } else {
                            this.FilteredCount = 0;
                        }
                        this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
                        this.Initialized.next(true);
                        this.fetch(start, end);
                    });
                }
            }
        }
    }    

    private FillResult(result, page) {
        const ps = this.pageSize;
        const rows = [];
        if (result) {
            let index = page * ps;
            if (this.TableStyle == TableStyle.DataTable) {
                result.forEach((item) => {
                    const row = new Row();
                    row.data = item;
                    row.styles = this._RowStyle;
                    row.index = index;
                    index++;
                    rows.push(row);
                });
            } else {
                result.forEach((item) => {
                    const cols = {};
                    this.Columns.forEach((column) => {
                        cols[column.Name] = {
                            selected: false,
                            edit: false,
                            tabindex: this.TabIndex
                        };
                        this.TabIndex += 1;
                    });
                    const row = new Row();
                    row.data = item;
                    row.cols = cols;
                    row.styles = this._RowStyle;
                    row.index = index;
                    index++;
                    rows.push(row);
                });
            }
        }
        this.PageCache[page] = rows;

        if (this.DynamicResultSize) {
            if (this.PagingType === PagingType.Endless) {
                if (rows.length >= ps) {
                    const actPageLength = page * ps + rows.length;
                    let max = (page + 2) * ps;
                    while (max <= actPageLength) {
                        max += ps;
                    }
                    while (this.FilteredCount < max) {
                        this.FilteredCount += ps;
                    }
                }
            } else {
                if (rows.length > ps) {
                    this.FilteredCount += rows.length - ps;
                }
            }
            let position = 0;
            this.cachedData = Array.from(Array(this.FilteredCount), (v, index) => new Row());
            let pages = [];
            Object.keys(this.PageCache).forEach(x => {
                pages.push(parseInt(x));
            });
            pages = pages.sort();
            for (let i = 0; i < pages.length; i++) {
                let p = pages[i];
                let items = this.PageCache[p];
                let length = items.length;

                let start = (p * ps) + position;

                if (length > ps) {
                    position += length - ps;
                }
                this.cachedData.splice(start, length, ...items);
            }
        } else {
            if (this.PagingType === PagingType.Endless) {
                if (result && result.length == ps) {
                    const max = (page + 2) * ps;
                    while (this.FilteredCount < max) {
                        this.FilteredCount += ps;
                        for (let i = 0; i < ps; i++) {
                            this.cachedData.push(new Row());
                        }
                    }
                }
            }
            this.cachedData.splice(page * ps, ps, ...rows);
        }
        this.PagedData = this.cachedData.slice(page * ps, (page * ps) + ps);
        this.cachedData = [...this.cachedData];
        this.Loading.next(false);
    }
}

export class DataTableRowDataSource extends ATableDataSource {
    private _DataRows: any[] = [];
    private _Filters: RequestFilter[] = [];
    private _Sort: RequestSort[] = [];

    constructor(table) {
        super();
        if (table) {
            const rows = table.Rows;
            if (Array.isArray(rows)) {
                this._DataRows = rows;
            }
            if (table.RowsChange) {
                table.RowsChange.subscribe(rows => {
                    if (Array.isArray(rows)) {
                        this._DataRows = rows;
                    } else {
                        this._DataRows = [];
                    }
                    this.Refresh();
                });
            }
        }
    }

    public SetFilter(filter: RequestFilter[]) {
        this._Filters = filter;
    }
    public SetRequestColumns(columns: RequestColumn[]) {
    }
    public SetOrder(order: RequestSort[]) {
        this._Sort = order;
    }
    public RefreshAndFetch(start: number, end: number) {
        const filtered = FilterHelper.ApplyTableFilter(this._Filters, this._DataRows, this._Sort);
        this.FilteredCount = filtered.length;
        const pageCache = {};
        const ps = this.pageSize;
        let pageIndex = 0;
        if (filtered.length <= ps) {
            pageCache[pageIndex] = filtered;
        } else {
            let dataIndex = 0;
            while (dataIndex < filtered.length) {
                pageCache[pageIndex] = filtered.slice(dataIndex, dataIndex + ps);
                dataIndex += ps;
                pageIndex++;
            }
        }
        this.PageCache = pageCache;
        this.cachedData = filtered;
        this.Initialized.next(true);
        this.fetch(start, end);
    }

    protected fetchPage(page: number, pageSize: number) {
        this.Loading.next(false);
    }
}