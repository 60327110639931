import { TranslateHelper } from "../../helpers/injector.helper";
import { ValueAndType } from "../basic/formulaEditor.model";
import { OperationType } from "../enums/operationtype.enum";
import { ValueType } from "../enums/valuetype.enum";
import { FormulaNodeCalculator } from "./formula.node.calculator";
import { AFormula, FormulaGroups } from "./iformula";

export class StrLength extends AFormula {
    static StaticID: string = '1859ba2e-493d-4b03-9921-ab9703160df1';

    ID(): string {
        return StrLength.StaticID;
    }

    Name(): string {
        return 'StrLength';
    }

    Description(): string {
        return TranslateHelper.TranslatorInstance.instant('@@Ausgabe der Laenge einer Zeichenkette als Ganzzahl');
    }

    Group(): string {
        return FormulaGroups.Functions;
    }

    OrderID(): number {
        return 16;
    }

    OperationType(): OperationType {
        return OperationType.Function;
    }

    OperationValue(): string {
        return this.Name() + '(' + TranslateHelper.TranslatorInstance.instant('@@Text') + ')';
    }

    Calc(fnc: FormulaNodeCalculator, maxType: ValueType, args?: ValueAndType[]): ValueAndType {
        var retVal = new ValueAndType();
        retVal.Type = ValueType.Long;
        var text = '';
        if (args && args.length > 0) {
            if (typeof args[0].Value !== 'undefined' && args[0].Value != null) {
                text = String(args[0].Value);
            }
        }
        retVal.Value = text.length;
        return retVal;
    }
}