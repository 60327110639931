import { RasterLayout } from "../components/layouts/raster/raster.layout";
import { FlexLayout } from "../components/layouts/flex/flex.layout";
import { GridLayout } from "../components/layouts/grid/grid.layout";
import { SideNavLayout } from "../components/layouts/sidenav/sidenav.layout";
import { BaseLayout } from "../components/layouts/base/base.layout";
import { OverlayLayout } from "../appbuilder/layouts/overlay/overlay.layout";
import { TemplateLayout } from "../components/layouts/template/template.layout";
import { NavigationLayout } from "../components/layouts/navigation/navigation.layout";
import { CanvasLayout } from "../components/layouts/canvas/canvas.layout";
import { RepeatingLayout } from "../components/layouts/repeating/repeating.layout";
import { WidgetLayout } from "../components/layouts/widget/widget.layout";
import { ExpansionPanelLayout } from '../components/layouts/expansionpanel/expansion.panel.layout';
import { BootstrapGridLayout } from "../components/layouts/bootstrapgrid/bootstrapgrid.layout";
import { BootstrapRepeatLayout } from "../components/layouts/bootstraprepeat/bootstraprepeat.layout";

export const APP_LAYOUTS = [
    RasterLayout,
    FlexLayout,
    OverlayLayout,
    GridLayout,
    SideNavLayout,
    BaseLayout,
    TemplateLayout,
    NavigationLayout,
    CanvasLayout,
    RepeatingLayout,
    NavigationLayout,
    WidgetLayout,
    ExpansionPanelLayout,
    BootstrapGridLayout,
    BootstrapRepeatLayout
];
