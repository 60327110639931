import { Component } from '@angular/core';
import { ADBInfo, DBPanelInfo } from '../../../../../models/datamodel/adbinfo.model';
import { ResourceLoginType, UserConfig } from '../../../../../models/userconfig.model';
import { BaseDataSourcePanel } from '../../../../../settings/datamodel/datasource/datasourcepanels/basedatasource.panel';

@Component({
    selector: 'webdb-panel',
    templateUrl: './webdb.panel.html',
    styleUrls: ['./webdb.panel.css']
})
export class WebDBPanel extends BaseDataSourcePanel {
    constructor() {
        super();
        this.DBInfoType = WebDBInfo;
    }

    static GetInfo(): DBPanelInfo {
        return {
            ControlType: WebDBPanel,
            DBInfoType: WebDBInfo
        };
    }
}

export class WebDBInfo extends ADBInfo {
    URL: string = null;
    LoginType: ResourceLoginType = ResourceLoginType.Anonymous;
    Username: string = null;
    Password: string = null;
    User: UserConfig;

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.NonDatabase.WebDBInfo';
    }
}