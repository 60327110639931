import { Component } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { WorkflowModuleExecuter, WorkflowStatus } from '../../../models/workflow/workflow.model';
import { NavigationService } from '../../../services/navigation.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { WorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-read-url-settings',
    templateUrl: './read.url.settings.html'
})
export class ReadURLSettings extends WorkflowDialogContent {
    Data = new ReadURLSettingsData();
    
    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'ReadURLWFModule';
        reg.Caption = '@@URL auslesen';
        reg.GroupID = 'navigation';
        reg.Index = 22;
        reg.SettingsControl = ReadURLSettings;
        reg.SettingsTypeHelper = new ReadURLSettingsDataHelper();
        reg.Executer = ReadURLModuleExecuter;
        reg.WorkflowType = WorkflowType.Client;
        return reg;
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const seri = serialize(data);
            this.Data = deserialize(ReadURLSettingsData, seri);
        }
    }

    getResult(): any {
        return this.Data;
    }
}

export class ReadURLSettingsData {
    ReadFullUrl = false;
    ReadBaseUrl = false;
}

export class ReadURLSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ReadURLSettingsData();
    }
}

export class ReadURLModuleExecuter extends WorkflowModuleExecuter {
    async execute(status: WorkflowStatus): Promise<number> {
        if (status.ActualSettings) {
            if (status.ActualSettings.ReadFullUrl) {
                status.ActualParameter = document.location.href;
            } else if (status.ActualSettings.ReadBaseUrl) {
                status.ActualParameter = document.location.origin;
            } else {
                status.ActualParameter = NavigationService.ActiveURL.getValue();
            }
            return 0;
        } else {
            status.Logger.logError('URL navigation modul: No settings found.');
        }
        return super.execute(status);
    }
}
