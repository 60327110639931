import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AboutInfo } from '../../../helpers/about.info';
import { DialogButton } from '../../../models/enums/dialogbutton.enum';
import { BaseDialog } from '../../dialogs/basedialog/base.dialog';

@Component({
    selector: 'about',
    templateUrl: './about.control.html',
    styleUrls: ['./about.control.css']
})
export class AboutControl implements OnInit {

    AboutInfo;
    BaseDialog;
    CustomButtons;

    static ShowDialog() {
        BaseDialog.ShowDialog({
            AutoFocus: false,
            ContentType: AboutControl,
            Buttons: DialogButton.Ok,
            Title: '@@About'
        });
    }

    constructor(private cdRef: ChangeDetectorRef, private cb: Clipboard) {
    }

    ngOnInit(): void {
        AboutInfo.GetAboutInfo().then(x => {
            if (x) {
                this.AboutInfo = x;
                if (this.BaseDialog) {
                    this.CustomButtons = [{
                        ID: 0,
                        Caption: '@@Copy informations'
                    }];
                }
                this.cdRef.detectChanges();
            }
        });
    }

    OnCustomButtonClicked(but) {
        if (but && but.ID === 0) {
            this.copy();
        }
    }

    copy() {
        if (this.AboutInfo) {
            const text = this.AboutInfo.getInfoText();
            this.cb.copy(text);
        }
    }
}
