import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { GitHelper } from '../../../helpers/git.helper';
import { PermissionHelper } from '../../../helpers/permissions.helper';
import { LayoutService } from '../../../services/layout.service';
import { MediaService } from '../../../services/media.service';
import { UsersService } from '../../../services/users.service';
import { AboutControl } from '../../common/aboutcontrol/about.control';
import { IBaseComponent } from '../base.component';
import { PROPERTIES, PROPERTYGROUPS } from '../../../services/dynamic.component.service';
import { BasePanel } from '../../../appbuilder/panels/base.panel';
import { GenericMenuTab } from '../../../appbuilder/menutabs/generic/generic.menu.tab';
import { PropertyGroupDisplay } from '../../../models/enums/propertygroupdisplay.enum';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComboboxThemeControl } from '../../../appbuilder/controls/combobox/combobox.theme.control';
@Component({
    selector: 'evi-user-menu',
    templateUrl: 'usermenu.control.html',
    styleUrls: ['usermenu.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuControl extends IBaseComponent {

    static Type: any = 'usermenu';
    static Default = {
        Editable: true,
        Type: 'usermenu',
        Variant: 'named',
        Layout: {
            Width: { Type: 0, Value:35},
            Height: {Type:0,Value:35}
        }
    };
    user;
    AllowImpersonate = false;
    URL: string;
    GitActive = false;
    Branches = [];
    SelectedBranch;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    get Icon() {
        if (this.LayoutElement.Icon) {
            return this.LayoutElement.Icon;
        }
        return "person";
    }
    constructor(private usersService: UsersService, cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdRef, data);
    }

    @Input()
    get Variant() {
        return this.LayoutElement.Variant;
    }
    set Variant(val) {
        this.LayoutElement.Variant = val;
        this.VariantChange.emit(this.LayoutElement.Variant);
    }

    @Output() VariantChange = new EventEmitter<any>();

    ControlInitialized() {
        this.GitActive = GitHelper.IsActive;
        if (this.GitActive) {
            this.Branches = GitHelper.LocalBranches;
            this.Subscriptions['BranchesChanged'] = GitHelper.BranchesChanged.subscribe((branches) => {
                this.Branches = GitHelper.LocalBranches;
                this.cdRef.detectChanges();
            });
            this.SelectedBranch = GitHelper.SelectedBranch;
        }
        this.user = localStorage.getItem('user');
        if (this.user) {
            this.user = JSON.parse(this.user);
            this.updateImage();
        }
        this.AllowImpersonate = PermissionHelper.GetImpersonatePermission();
    }
    SelectedBranchChanged() {
        GitHelper.SelectedBranch.next(this.SelectedBranch);
    }
    logout() {
        this.usersService.logout();
        this.trigger.closeMenu();
    }

    updateImage() {
        if (this.user && this.user.Image) {
            const url = MediaService.ProfilePicURL.getValue();
            if (url) {
                this.URL = url + this.user.SID + '/' + this.user.Image;
            }
        }
    }

    languageSelected() {
        // const text = '@@Bitte denken Sie daran auch die Spracheneinstellung Ihres Browsers anzupassen. Einige Controls (z.B.DatetimePicker) und Formatierungen verhalten sich sonst moeglicherweise nicht wie erwartet.';
        // MessageBoxHelper.ShowDialog(new TranslateFormatText(text), null, MessageBoxButtons.Ok, MessageBoxIcon.Information);
    }

    OpenDialog() {
        AboutControl.ShowDialog();
        this.trigger.closeMenu();
    }
}

export class UserMenuPanel extends BasePanel{
    static override SIDS = ['1a0bf6b1-80bb-494a-88fe-270a109f3919']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'1a0bf6b1-80bb-494a-88fe-270a109f3919',
            ID: 'Usermenu',
            Caption: '@@Usermenu',
            Index: 100,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['100%'],
            Rows: ['auto'],
            CheckVisibility: (item) => {
                return item.ElementType == 'usermenu';
            }
        });
        let variants = [
            {
                Caption: '@@Icon only',
                Value: 'iconed'
            },
            {
                Caption: '@@Icon with name',
                Value: 'named'
            },
        ]
        PROPERTIES.push({
            ID: "Variant",
            Parent: "Usermenu",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@Variant",
            Column: 1,
            Row: 1,
            InitArgs: {
                Placeholder: "@@Variant",
                Multiple: false,
                ItemsSource: variants,
                ValueMemberPath: "Value",
                DisplayMemberPath: "Caption"
            }
        });
    }
}
