import { Injectable } from '@angular/core';
import { RequestBase } from './request-base';
import { Observable } from 'rxjs';
import { DataSource } from '../models/datamodel/datasource.model';
import { RestItem } from '../settings/rest/models/rest.item';
import { EndPointRestItem } from '../settings/rest/models/end.point.rest.item';
import { RestObject } from '../settings/rest/models/rest.object';
import { OAuth2Settings } from '../settings/rest/models/oauth2.settings';
import { ImportFile } from '../settings/rest/models/import.init.args';
import { ContainerRest } from '../settings/rest/models/rest.container.classobject';

@Injectable()
export class RestService extends RequestBase {
    private _basePath = 'api/settings/rest'

    public loadAll(): Observable<RestItem[]> {
        return this.http.get<RestItem[]>(this.API_BASE_URL + this._basePath + '/loadAll', this.options);
    }

    public LoadAllRestItemInfos(): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this._basePath + '/LoadAllRestItemInfos', this.options);
    }

    public load(sid: string): Observable<RestItem> {
        return this.http.get<RestItem>(this.API_BASE_URL + this._basePath + '/load?sid=' + sid, this.options);
    }

    public remove(sid: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this._basePath + '/remove?sid=' + sid, this.options);
    }

    public save(rest: RestItem): Observable<RestItem> {
        return this.http.post<any>(this.API_BASE_URL + this._basePath + '/save', rest, this.options);
    }

    public loadEndPoints(sid: string): Observable<EndPointRestItem[]> {
        return this.http.get<EndPointRestItem[]>(this.API_BASE_URL + this._basePath + '/loadEndPoints?rest=' + sid, this.options);
    }

    public LoadAllRestEndPointInfos(sid: string): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this._basePath + '/LoadAllRestEndPointInfos?rest=' + sid, this.options);
    }

    public LoadAllRestEndPointInfosByDataSource(dataSource: string): Observable<any[]> {
        const path = this.API_BASE_URL + this._basePath + '/LoadAllRestEndPointInfosByDataSource?dataSource=' + dataSource;
        return this.http.get<any[]>(path, this.options);
    }

    public loadEndPoint(endPoint: string): Observable<EndPointRestItem> {
        return this.http.get<EndPointRestItem>(this.API_BASE_URL + this._basePath + '/loadEndPoint?endPoint=' + endPoint, this.options);
    }

    //public removeEndPoint(endPoint: string): Observable<any> {
    //    return this.http.delete<any>(this.API_BASE_URL + this._basePath + '/removeEndPoint?endpoint=' + endPoint, this.options);
    //}

    public saveEndPoint(endPoint: EndPointRestItem): Observable<EndPointRestItem> {
        return this.http.post<EndPointRestItem>(this.API_BASE_URL + this._basePath + '/saveEndPoint', endPoint, this.options);
    }

    public loadRestObjects(rest: string): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this._basePath + '/loadRestObjects?rest='+rest, this.options);
    }

    public loadRestObject(container: string): Observable<RestObject> {
        return this.http.get<RestObject>(this.API_BASE_URL + this._basePath + '/loadRestObject?container=' + container, this.options);
    }

    //public removeRestObject(o: string): Observable<any> {
    //    return this.http.delete<any>(this.API_BASE_URL + this._basePath + '/removeRestObject?o=' + o, this.options);
    //}

    public saveRestobject(o: RestObject): Observable<RestObject> {
        return this.http.post<RestObject>(this.API_BASE_URL + this._basePath + '/saveRestobject', o, this.options);
    }

    public getNewAccessToken(nat: OAuth2Settings): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this._basePath + '/getNewAccessToken', nat, this.options);
    }

    public fileImport(file: ImportFile): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this._basePath + '/fileImport', file, this.options);
    }

    public getDynamicTypes(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/getDynamicTypes', this.options);
    }

    public loadTemplates(rest: string): Observable<any[]> {
        return this.http.get<any[]>(this.API_BASE_URL + this._basePath + '/loadTemplates?rest='+rest, this.options);
    }

    public loadTemplate(template: string): Observable<ContainerRest> {
        return this.http.get<ContainerRest>(this.API_BASE_URL + this._basePath + '/loadTemplate?template=' + template, this.options);
    }
    //public removeTemplate(templte: string): Observable<any> {
    //    return this.http.delete<any>(this.API_BASE_URL + this._basePath + '/removeTemplate?template=' + templte, this.options);
    //}

    public saveTemplate(template: ContainerRest): Observable<ContainerRest> {
        return this.http.post<ContainerRest>(this.API_BASE_URL + this._basePath + '/saveTemplate', template, this.options);
    }

    public loadAllRestMappingClass(restID: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/loadAllRestMappingClass?restID=' + restID, this.options);
    }

    public loadRestMappingClassById(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/loadRestMappingClassById?id=' + id, this.options);
    }

    public saveRestMappingClass(o: any): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this._basePath + '/saveRestMappingClass', o, this.options);
    }

    public removeRestMappingClass(sid: string): Observable<any> {
        return this.http.delete<any>(this.API_BASE_URL + this._basePath + '/removeRestMappingClass?sid=' + sid, this.options);
    }

    public loadAllContainerClassObjects(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/loadAllContainerClassObjects', this.options);
    }

    public loadContainerClassObjectByID(sid: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/loadContainerClassObjectByID?sid=' + sid, this.options);
    }

    public getDataFromAttributeClass(sid: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/getDataFromAttributeClass?sid=' + sid, this.options);
    }

    public getDataSources(): Observable<DataSource[]> {
        return this.http.get<DataSource[]>(this.API_BASE_URL + this._basePath + '/getDataSources', this.options);
    }

    public GetRestObjectProperties(id: string): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this._basePath + '/GetRestObjectProperties?id=' + id, this.options);
    }

    public getYamlFromRestObject(restID: string, name: string, description: string): Observable<any> {
      
        return this.http.get<any>(this.API_BASE_URL + this._basePath + "/getYamlFromRestObject?restID=" + restID + "&restPath=" + this.API_BASE_URL
            + "&name=" + name + "&description=" + description , this.options);
    }
}