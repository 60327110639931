import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { MultiCacheService } from '../../../cache/multi/cache.service';
import { DataDescription } from '../../../models/datadescription/multi/datadescription.model';
import { PlanningTableDataSource } from '../../../models/datasource/table.planning.datasource';
import { TableStyle } from '../../../models/enums/tablestyle.enum';
import { LayoutService } from '../../../services/layout.service';
import { MultiReportingService } from '../../../services/reporting.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-macplanning',
    templateUrl: './planning.reportobject.html',
    styleUrls: ['./planning.reportobject.css'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class MacPlanningControl extends IBaseComponent {

    static Type: any = 'planningreportObject';
    static Default = { Type: 'planningreportObject' };
    //#region Properties
    initialized = false;
    ViewInitialized = false;
    PageInitialized = false;
    //#region Data
    Source: PlanningTableDataSource;
    //#region DataDescription
    DataDescriptionsValue;

    @Input()
    get DataDescriptions() {
        return this.DataDescriptionsValue;
    }
    set DataDescriptions(val) {
        this.DataDescriptionsValue = val;
        this.InitializeDataSource();
        this.DataDescriptionsChange.emit(this.DataDescriptionsValue);
    }

    @Output() DataDescriptionsChange = new EventEmitter<any>();
    //#endregion
    Descriptions = {};
    //#region Data
    DataValue;

    @Input()
    get Data() {
        return this.DataValue;
    }
    set Data(val) {
        this.DataValue = val;
        const DataDescriptions = {};
        if (val && val.length > 0) {
            val.forEach((data) => {
                if (!data.IsRelational && data.Datadescription) {
                    DataDescriptions[data.ID] = plainToClass(DataDescription, data.Datadescription);
                    DataDescriptions[data.ID].Caption = data.Caption;
                    if (!this.Descriptions[data.ID]) {
                        this.Descriptions[data.ID] = {
                            X: {},
                            Y: {}
                        };
                    }
                    this.FillAxis(DataDescriptions[data.ID].XLevelNodes, data, this.Descriptions[data.ID].X);
                    this.FillAxis(DataDescriptions[data.ID].YLevelNodes, data, this.Descriptions[data.ID].Y);
                }
            })
        }
        this.DataDescriptions = DataDescriptions;
        this.DataChange.emit(this.DataValue);
    }

    @Output() DataChange = new EventEmitter<any>();
    //#endregion
    //#endregion

    //#region RowsExpandable
    RowsExpandableValue: boolean;

    @Input()
    get RowsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.RowsExpandable;
        } else {
            return this.RowsExpandableValue;
        }
    }
    set RowsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.RowsExpandable = val;
            this.RowsExpandableChange.emit(this.LayoutElement.RowsExpandable);
        } else {
            this.RowsExpandableValue = val;
            this.RowsExpandableChange.emit(this.RowsExpandableValue);
        }
    }

    @Output() RowsExpandableChange = new EventEmitter<any>();
    //#endregion
    //#region ColumnsExpandable
    ColumnsExpandableValue: boolean;

    @Input()
    get ColumnsExpandable() {
        if (this.LayoutElement) {
            return this.LayoutElement.ColumnsExpandable;
        } else {
            return this.ColumnsExpandableValue;
        }
    }
    set ColumnsExpandable(val) {
        if (this.LayoutElement) {
            this.LayoutElement.ColumnsExpandable = val;
            this.ColumnsExpandableChange.emit(this.LayoutElement.ColumnsExpandable);
        } else {
            this.ColumnsExpandableValue = val;
            this.ColumnsExpandableChange.emit(this.ColumnsExpandableValue);
        }
    }

    @Output() ColumnsExpandableChange = new EventEmitter<any>();
    //#endregion

    //#region Rows
    RowsValue;
    @Input()
    get Rows() {
        return this.RowsValue;
    }
    set Rows(val) {
        this.RowsValue = val;
        this.RowsChange.emit(this.RowsValue);
    }
    @Output() RowsChange = new EventEmitter<any>();
    //#endregion

    //#region Columns
    ColumnsValue;
    @Input()
    get Columns() {
        return this.ColumnsValue;
    }
    set Columns(val) {
        this.ColumnsValue = val;
        this.CreateColumns();
        this.ColumnsChange.emit(this.ColumnsValue);
    }
    @Output() ColumnsChange = new EventEmitter<any>();

    FixedColumnsLeftValue;
    @Input()
    get FixedColumnsLeft() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsLeft;
        } else {
            return this.FixedColumnsLeftValue;
        }
    }
    set FixedColumnsLeft(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsLeft = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsLeft);
        } else {
            this.FixedColumnsLeftValue = val;
            this.FixedColumnsLeftChange.emit(this.FixedColumnsLeftValue);
        }
        this.CreateColumns();
    }
    @Output() FixedColumnsLeftChange = new EventEmitter<any>();

    FixedColumnsRightValue;
    @Input()
    get FixedColumnsRight() {
        if (this.LayoutElementValue) {
            return this.LayoutElementValue.FixedColumnsRight;
        } else {
            return this.FixedColumnsRightValue;
        }
    }
    set FixedColumnsRight(val) {
        if (this.LayoutElementValue) {
            this.LayoutElementValue.FixedColumnsRight = val;
            this.FixedColumnsRightChange.emit(this.LayoutElementValue.FixedColumnsRight);
        } else {
            this.FixedColumnsRightValue = val;
            this.FixedColumnsRightChange.emit(this.FixedColumnsRightValue);
        }
        this.CreateColumns();
    }
    @Output() FixedColumnsRightChange = new EventEmitter<any>();
    //#endregion

    ActiveCell = new BehaviorSubject<any>(null);
    get Cell() {
        let retVal = null;
        if (this.LayoutElementValue && this.CellSelection && Array.isArray(this.CellSelection) && this.CellSelection.length == 1) {
            if (this.LayoutElementValue.CellDescriptions && this.LayoutElementValue.CellDescriptions[this.SelectedSheet.Name] &&
                this.LayoutElementValue.CellDescriptions[this.SelectedSheet.Name][this.CellSelection[0].RowIndex] &&
                this.LayoutElementValue.CellDescriptions[this.SelectedSheet.Name][this.CellSelection[0].RowIndex][this.CellSelection[0].ColumnName]) {
                retVal = JSON.parse(JSON.stringify(this.LayoutElementValue.CellDescriptions[this.SelectedSheet.Name][this.CellSelection[0].RowIndex][this.CellSelection[0].ColumnName]));
                if (retVal.UseReference && retVal.Reference.UseFullReference) {
                    let area = this.GetSelectedArea(retVal.Reference.X);
                    if (area) {
                        let level = this.GetSelectedLevel(area);
                        if (level) {
                            retVal.XArea = parseInt(area.AreaID);
                            retVal.XLevel = level.LevelID;
                            retVal.XMember = level.MemberID;
                            if (area.MeasureID != null) {
                                retVal.Measure = area.MeasureID
                            }
                        }
                    }
                    

                    area = this.GetSelectedArea(retVal.Reference.Y);
                    if (area) {
                        let level = this.GetSelectedLevel(area);
                        if (level) {
                            retVal.YArea = parseInt(area.AreaID);
                            retVal.YLevel = level.LevelID;
                            retVal.YMember = level.MemberID;
                        }
                    }
                    
                    
                    if (area.MeasureID != null) {
                        retVal.Measure = area.MeasureID
                    }

                }
            }
        }
        return retVal;
    }
    GetSelectedArea(Axis) {
        let areas = Object.keys(Axis);
        for (let i = 0; i < areas.length; i++) {
            let area = Axis[areas[i]];
            if (area.Selected == true) {
                return area;
            }
        }
        return null;
    }
    GetSelectedLevel(Area) {
        let levels = Object.keys(Area.Levels);
        for (let i = 0; i < levels.length; i++) {
            let level = Area.Levels[levels[i]];
            if (level.MemberID != null) {
                return level;
            }
        }
        return null;
    }

    CellStyles = {};
    RowSelection = [];
    CellSelection = [];
    HasChanges = false;
    SelectedSheet;
    ChangedValues = [];
    DataTable;
    //#endregion

    //#region LifeCycle
    constructor(private cdref: ChangeDetectorRef, private multiService: MultiReportingService, @Inject(LayoutService.CONTAINER_DATA) public data) {
        super(cdref, data);
        this.EventList.push('RowSelectionChanged');
        this.EventList.push('CellSelectionChanged');
        this.EventList.push('ValueChanged');
        this.EventList.push('OnFocus');
        this.EventList.push('Click');
        this.EventList.push('DoubleClick');
    }

    TableInitialized(element) {
        this.DataTable = element;
    }

    CreateColumns() {
       
    }

    onLayoutElementSet() {
        this.initialized = false;
        if (this.LayoutElement && this.LayoutElement.Queries && this.Layout && this.Layout.Datadescriptions) {
            let list = [];
            this.LayoutElement.Queries.forEach((q) => {
                const dd = this.Layout.Datadescriptions.find((value) => {
                    return q === value.ID;
                });
                if (dd) {
                    list.push(dd);
                }
            });
            this.Data = list;
        }
    }

    onLayoutElementChanged() {
        this.initialized = false;
        setTimeout(() => { this.InitializeDataSource(); }, 10);
    }

    ControlInitialized() {
        if (this.LayoutValue && this.LayoutValue.Initialized) {
            this.Subscriptions['pageInitialized'] = this.LayoutValue.Initialized.subscribe((x) => {
                if (x) {
                    this.PageInitialized = true;
                    this.InitializeDataSource();
                }
            });
        }
    }

    InitializeDataSource() {
        if (this.LayoutElementValue.Sheets && this.LayoutElementValue.Sheets.length > 0) {
            this.SelectedSheet = this.LayoutElementValue.Sheets[0];
        }
        const initSub = this.Subscriptions['Initialized'];
        if (initSub) {
            initSub.unsubscribe();
            delete this.Subscriptions['Initialized'];
        }
        const colSub = this.Subscriptions['columns'];
        if (colSub) {
            colSub.unsubscribe();
            delete this.Subscriptions['columns'];
        }
        const rowsSub = this.Subscriptions['Rows'];
        if (rowsSub) {
            rowsSub.unsubscribe();
            delete this.Subscriptions['Rows'];
        }
        const styleSub = this.Subscriptions['cellStyles'];
        if (styleSub) {
            styleSub.unsubscribe();
            delete this.Subscriptions['cellStyles'];
        }

        const source = new PlanningTableDataSource(this);
        this.Source = source;
        this.Source.ActiveSheet = this.SelectedSheet;
        this.Subscriptions['Initialized'] = this.Source.Initialized.subscribe((res) => {
            this.initialized = res;
        });
        this.Subscriptions['columns'] = source.Columns.subscribe((res) => {
            let cols = res;
            if (this.LayoutElementValue.Columns && this.LayoutElementValue.Sheets) {
                this.LayoutElementValue.Sheets.forEach((sheet) => {
                    if (cols[sheet.Name] && this.LayoutElementValue.Columns[sheet.Name]) {
                        cols[sheet.Name].forEach((col) => {
                            if (col.Width) {
                                let layoutcol = this.LayoutElementValue.Columns[sheet.Name].find((value) => { return col.Name == value.Name && col.Width.Value !== value.CalculatedWidth });
                                if (layoutcol) {
                                    if (layoutcol.CalculatedWidth) {
                                        col.Width.Value = layoutcol.CalculatedWidth;
                                    }
                                }
                            }
                        });
                    }
                });
            }
            this.LayoutElementValue.Columns = cols;
            this.Columns = cols;
        });
        this.Subscriptions['Rows'] = source.Data.subscribe((res) => {
            this.Rows = res;
            if (res) {
                if (this.DataTable && this.DataTable.DataSourceValuesChanged) {
                    this.DataTable.DataSourceValuesChanged.next(res);
                }
                this.cdref.detectChanges();
            }
        });
        this.Subscriptions['cellStyles'] = this.Source.CellStyles.subscribe((res) => {
            this.CellStyles = res;
        });
        this.Source.Refresh();
    }

    OnDataBindingChanged() {
        window.setTimeout(() => {
            this.onLayoutElementSet();
        }, 200);
    }

    ngAfterViewInit(): void {
        this.ViewInitialized = true;
        this.cdref.detectChanges();
        super.ngAfterViewInit();
    }
    //#endregion
    //#region Actions
    clicked() {
        this.triggerEvent('OnFocus', this.DataSource);
        this.triggerEvent('Click', this);
    }
    doubleClicked() {
        this.triggerEvent('DoubleClick', this);
    }
    RowSelectionChanged(item) {
        this.RowSelection = item;
        this.triggerEvent('RowSelectionChanged', item);
    }
    CellSelectionChanged(item) {
        if (item && item.length > 0) {
            item.forEach((cell) => {
                cell.Sheet = this.SelectedSheet.Name
            });
        }
        this.CellSelection = item;
        this.ActiveCell.next(item);
        this.triggerEvent('CellSelectionChanged', item);
    }
    CopySelectionChanged(item) {
        this.Source.CellCopy(item);
    }
    
    ValueChanged(item) {
        this.HasChanges = true;
        item.Sheet = this.SelectedSheet.Name;
        this.ChangedValues.push(item);
        this.triggerEvent('ValueChanged', item);
    }
    SortChanged(sort) {
        this.Source.Refresh();
    }
    FilterChanged(filter) {
        this.Source.Refresh();
    }
    ExecuteRefresh() {
        if (this.Source) {
            this.Source.Refresh();
        }
    }
    RefreshCalculations() {
        if (this.Source) {
            this.Source.RefreshCalculations();
        }
    }
    RefreshStyles() {
        if (this.Source) {
            this.Source.RefreshStyles();
        }
    }
    SelectSheet(item) {
        this.SelectedSheet = null;
        setTimeout(() => {
            this.SelectedSheet = item;
            this.Source.ActiveSheet = this.SelectedSheet;
            this.RefreshCalculations();
        }, 100);
    }
    ColumnResized(column) {
        if (column.CalculatedWidth != null) {
            column.Width.Value = column.CalculatedWidth;
        }
    }
    KeyPress(event: KeyboardEvent) {
        event.stopPropagation();
        if (event.key.toLowerCase() == 'delete') {
            if (this.CellSelection && this.CellSelection.length > 0 && this.Rows) {
                let edit = false;
                this.CellSelection.forEach((cell) => {
                    if (this.Rows[cell.Sheet] && this.Rows[cell.Sheet][cell.RowIndex] && this.Rows[cell.Sheet][cell.RowIndex].cols && this.Rows[cell.Sheet][cell.RowIndex].cols[cell.ColumnName] && this.Rows[cell.Sheet][cell.RowIndex].cols[cell.ColumnName].edit) {
                        edit = true;
                    }
                })
                if (!edit) {
                    this.CellSelection.forEach((cell) => {
                        if (this.LayoutElementValue && this.LayoutElementValue.CellDescriptions && this.LayoutElementValue.CellDescriptions[cell.Sheet] && this.LayoutElementValue.CellDescriptions[cell.Sheet][cell.RowIndex]) {
                            delete this.LayoutElementValue.CellDescriptions[cell.Sheet][cell.RowIndex][cell.ColumnName];
                        }
                        if (this.Rows[cell.Sheet] && this.Rows[cell.Sheet][cell.RowIndex] && this.Rows[cell.Sheet][cell.RowIndex].cols && this.Rows[cell.Sheet][cell.RowIndex].cols[cell.ColumnName]) {
                            this.Rows[cell.Sheet][cell.RowIndex].data[cell.ColumnName] = "";
                            this.Rows[cell.Sheet][cell.RowIndex].internal[cell.ColumnName] = null;
                        }
                    })
                }
                if (this.Source) {
                    this.Source.RefreshCalculations();
                }
            }
        }
    }    
    //#endregion

    GetSelectedCoordinates() {
        if (this.Source) {
            if (this.LayoutElement.TableStyle === TableStyle.DataTable) {
                return this.Source.GetSelectedCoordinatesFromRowSelection(this.RowSelection);
            } else {
                return this.Source.GetSelectedCoordinatesFromCellSelection(this.CellSelection);
            }
        }
        return [];
    }

    Save() {
        this.Source.SaveChanges(this.ChangedValues);
        this.HasChanges = false;
        this.ChangedValues = [];
    }

    FillAxis(Node, Data, ReferenceAxis) {
        for (let i = 0; i < Node.Areas.length; i++) {
            let area = {
                Caption: 'Area ' + i,
                ID: i + '',
                Levels: {},
                Measures: {}
            }
            if (!ReferenceAxis[area.ID]) {
                Node.Areas[i].Tuples.forEach((tuple) => {
                    tuple.Levels.forEach(async (level) => {
                        let lev = {
                            Caption: level.Caption.DefaultValue,
                            Value: level.UniqueID,
                            ID: level.Level,
                            Members: {}
                        }
                        if (!area.Levels[lev.Value]) {
                            const cubeLevel = await MultiCacheService.GetLevel(level.Level, Data.DataModelID);
                            if (cubeLevel) {
                                this.multiService.GetMembers(cubeLevel.Parent.Parent.DatasourceID, level.Level, null, null).subscribe(members => {
                                    if (members) {
                                        members.forEach(m => {
                                            lev.Members[m.ID] = m;
                                        });
                                    }
                                });
                            }
                            area.Levels[lev.Value] = lev;
                        }
                    });
                })
                Node.Areas[i].Measures.forEach((measure) => {
                    area.Measures[measure.UniqueID] = measure;
                })
                ReferenceAxis[area.ID] = area;
            }
            
        }
    }
}
