import { Subject } from "rxjs";

export class DispatcherTimer {

  private _TimeOut: any;
  public IsRun: boolean = false;

  public Tick = new Subject<any>();

  get Interval(): number {
    return this._Milsec;
  }

  set Interval(value: number) {
    this._Milsec = value;
  }

  constructor(private _Milsec: number = 200) {

  }

  public Start(arg:any = null): void {
    this.IsRun = true;
    clearTimeout(this._TimeOut);
    this._TimeOut = setTimeout(() => {
      this.IsRun = false;
      this.Tick.next(arg);
    }, this._Milsec);
  }

  public Stop(): void {
    clearTimeout(this._TimeOut);
    this.IsRun = false;
  }
}
