import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { EnumHelper } from '../../helpers/enum.helper';
import { TranslateHelper } from '../../helpers/injector.helper';
import { MailAuthType } from '../../models/enums/mailauthtype.enum';
import { MailProvider } from '../../models/enums/mailprovider.enum';
import { TranslatedString } from '../../models/translatedstring.model';
import { MailSettingsService } from '../../services/mailsettings.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../base.list.settings';
import {NotificationHelper} from "../../helpers/notification.helper";
import {LayoutService} from "../../services/layout.service";

@Component({
    selector: 'mail-settings',
    templateUrl: '../base.list.settings.html'
})
export class MailSettings extends BaseListSettings {

    static GetSettingsEntry() {
        return {
            Caption: '@@Email',
            ID: 'email',
            Icon: 'alternate_email',
            Index: 2,
            Parent: 'settings',
            Security: {
                Name: 'evidanza.App.Shared.Security.PageSettingsRight',
                Value: 8
            },
            Content: MailSettings
        };
    }

    emailListData = [];

    constructor(protected fr: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MailSettingsService) {
        super(fr, cdRef);
    }

    getContentType() {
        return MailDetail;
    }

    loadList(handler) {
        this.service.GetMailSettings().subscribe(result => {
            if (result) {
                const list = [];
                result.forEach((entry) => {
                    list.push({
                        Caption: entry.Caption,
                        ID: entry.SID,
                        IsCapsule: entry.IsCapsule,
                        IsOverridden: entry.IsOverridden,
                        CanNotDelete:entry?._isExternal===true?true:false
                    });
                });
                handler(list);
                this.emailListData = JSON.parse(JSON.stringify(list));
            }
        });
    }

    loadData(data) {
        this.service.LoadMailSetting(data).subscribe(result => {
            if (result) {
                this.setSelectedItem(result);
            }
        });
    }

    getNewItem() {
        return {
            Caption: new TranslatedString(TranslateHelper.TranslatorInstance.instant('@@Neuer MailServer'))
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Sind Sie sicher, dass Sie den MailServer \'{0}\' loeschen moechten?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@MailServer \'{0}\' erfolgreich geloescht.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@MailServer loeschen');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@MailServer \'{0}\' erfolgreich gespeichert.');
        retVal.Text.FormatParams.push(TranslatedString.GetTranslation(sel.Caption));
        retVal.Title = new TranslateFormatText('@@MailServer speichern');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteMailSetting(data).subscribe((res) => {
            handler(res);
        });
    }

    saveInternal(item, handler) {
        if (item?.Caption?.DefaultValue.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }
        const _item = this.addIsExternal(item);
        this.service.SaveMailSetting(_item).subscribe(result => {
            if (result) {
                handler(result, result.SID, result.Caption);
            }
        });
    }

    addIsExternal(item) {
        let _item = item;
        if(this.emailListData.length == 0) {
            _item = JSON.parse(JSON.stringify(item));
            _item._isExternal = true;
        }
        return _item;
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }
}

@Component({
    selector: 'mail-detail',
    templateUrl: './mail.settings.html',
    styleUrls: ['./mail.settings.css']
})
export class MailDetail extends BaseListDetail {
    AuthTypes = []
    Providers=[]
    constructor() {
        super()
        this.AuthTypes = EnumHelper.GetDropdownValues(MailAuthType)
        this.Providers = EnumHelper.GetDropdownValues(MailProvider)
   
    }
}
