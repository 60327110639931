import { Color } from './color.model';
import { Type } from 'class-transformer';

// @dynamic
export class Font {
    @Type(() => Color)
    FontColor: Color = null;
    FontFamilyName: string = null;
    FontSize: any = null;
    Bold: any = null;
    Italic: any = null;
    Underline: any = null;

    applyToOtherFont(other: Font) {
        if (other) {
            if (Color.IsValid(this.FontColor)) {
                other.FontColor = Color.FromObject(this.FontColor);
            }
            if (typeof this.FontFamilyName === 'string') {
                other.FontFamilyName = this.FontFamilyName;
            }
            if (typeof this.FontSize === 'number') {
                other.FontSize = this.FontSize;
            }
            if (typeof this.Bold === 'boolean') {
                other.Bold = this.Bold;
            }
            if (typeof this.Italic === 'boolean') {
                other.Italic = this.Italic;
            }
            if (typeof this.Underline === 'boolean') {
                other.Underline = this.Underline;
            }
        }
    }
}
