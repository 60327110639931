export enum FormulaNodeType {
    Value,
    Operation,
    Variable,
    Placeholder,
    None,
    TextValue,
    DateValue,
    BoolValue,
    SystemVariable
}
