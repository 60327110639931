import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import ImageEditor from 'tui-image-editor';
import { CacheService } from '../../cache/cache.service';
import { TranslateFormatText } from '../../helpers/array.helpers';
import { MediaSource } from '../../models/datamodel/mediasource.model';
import { MessageBoxButtons } from '../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../models/enums/messageboxresult.enum';
import { MediaService } from '../../services/media.service';
import { IOptions } from '../controls/imageeditor/imageeditor.models';
import { MessageBoxHelper } from '../dialogs/messagebox/messagebox.dialog';

@Component({
    selector: 'evi-file-explorer',
    templateUrl: './fileexplorer.control.html',
    styleUrls: ['./fileexplorer.control.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileExplorerControl implements OnInit {

    //#region MediaSource
    MediaSourceIDValue;
    SearchValue: string;
    @Input()
    get MediaSourceID() {
        return this.MediaSourceIDValue;
    }
    set MediaSourceID(val) {
        this.MediaSourceIDValue = val;
        this.MediaSourceIDChange.emit(this.MediaSourceIDValue);
    }

    @Output() MediaSourceIDChange = new EventEmitter<any>();
    //#endregion

    //#region MediaSource
    SelectionValue;

    @Input()
    get Selection() {
        return this.SelectionValue;
    }
    set Selection(val) {
        this.SelectionValue = val;
        if (!val || (val && val.indexOf('.svg') > -1)) {
            this.Editable = false;
        } else {
            this.Editable = true;
        }
        this.SelectionChange.emit(this.SelectionValue);
    }

    @Output() SelectionChange = new EventEmitter<any>();
    //#endregion

    @Output() FileUploaded = new EventEmitter<any>();

    @Input()
    get FixedPath() {
        return this.Path;
    }
    set FixedPath(val) {
        if (Array.isArray(val)) {
            this.Path = val;
            this.HasFixedPath = true;
        } else {
            this.Path = ['./'];
            this.HasFixedPath = false;
        }
    }

    Items = [];
    originalItems=[];
    FolderName;
    Path = ['./'];
    HasFixedPath = false;
    MediaSource;
    FolderCreation = false;
    Uploading = false;
    UploadProgress = 0;
    Uploadindeterminate = false;
    UploadError = false;
    EditMode = false;
    Editable = true;
    EditorOptions: IOptions;
    Editor: ImageEditor;
    Loading = false;

    constructor(private mediaService: MediaService, private sanitizer: DomSanitizer, private iconRegistry: MatIconRegistry, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        CacheService.ReadMediaSource(this.MediaSourceID).then((data) => {
            this.MediaSource = data;
            this.Refresh();
        });
    }

    //#region Folder
    CreateFolder() {
        this.mediaService.CreateFolder(this.MediaSourceID, this.createPath() + this.FolderName).subscribe((result) => { this.Refresh(); });
        this.FolderCreation = false;
        this.FolderName = null;
    }

    OpenFolderCreation() {
        this.FolderCreation = true;
    }
    CancelFolderCreation() {
        this.FolderCreation = false;
    }
    //#endregion
    ItemSelected(item) {
        this.Items.forEach((i) => {
            i.Selected = false;
        });
        if (item.IsDir) {
            this.FolderSelected(item);
        } else {
            item.Selected = true;

            this.EditorOptions = {
                includeUI: {
                    loadImage: {
                        path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                        name: 'Blank'
                    }
                },
                cssMaxWidth: 1000, // Component default value: 1000
                cssMaxHeight: 800  // Component default value: 800
            };

            this.Selection = this.createPath() + item.Name;
        }
    }
    EditorLoaded(instance) {
        this.Editor = instance;
        const url = MediaSource.GetURL(this.MediaSource, this.Selection);
        this.Editor.loadImageFromURL(url, this.Selection).then(result => {
            this.Editor['ui'].resizeEditor({
                imageSize: {
                    oldWidth: result.oldWidth,
                    oldHeight: result.oldHeight,
                    newWidth: result.newWidth,
                    newHeight: result.newHeight
                }
            });
        }).catch(err => {
            console.error('Something went wrong:', err);
        });

        this.Editor.loadImageFromURL(url);
    }
    Edit() {
        this.EditMode = true;
    }
    CancelEdit() {
        this.EditMode = false;
    }

    convertDataUrlToBlob(dataUrl): Blob {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    }

    AcceptEdit() {
        this.EditMode = false;
        const file = new File([this.convertDataUrlToBlob(this.Editor.toDataURL())], this.Selection);
        this.UploadChanged(file);
    }
    Delete() {
        if (this.Selection) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie die Datei {0} loeschen moechten?');
            text.FormatParams.push(this.Selection);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        this.mediaService.DeleteFile(this.MediaSourceID, this.SelectionValue).subscribe((result) => { this.Refresh(); });
                    }
                });
        } else {
            const path = this.createPath();
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Ordner {0} loeschen moechten?');
            text.FormatParams.push(path);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        this.mediaService.DeleteFile(this.MediaSourceID, path).subscribe((result) => {
                            this.Path = this.Path.slice(0, this.Path.length - 1);
                            this.Refresh();
                        });
                    }
                });
        }
    }
    Upload(event) {
        this.UploadError = false;
        const uploadData = new FormData();
        const selectedFile = event.target.files[0];
        const path = this.createPath();
        uploadData.append(path + selectedFile.name, selectedFile, path + selectedFile.name);
        this.mediaService.UploadSpecific(this.MediaSourceID, uploadData).subscribe(e => {
            uploadData.forEach((value) => {
                switch (e.type) {
                    case HttpEventType.Sent:
                        this.Uploading = true;
                        break;
                    case HttpEventType.UploadProgress:
                        this.UploadProgress = Math.round(100 * e.loaded / e.total);
                        if (this.UploadProgress === 100) {
                            this.Uploadindeterminate = true;
                            this.Uploading = false;
                            this.UploadProgress = 0;
                        }
                        break;
                    case HttpEventType.Response:
                        this.FileUploaded.emit(selectedFile.name);
                        this.Refresh();
                        break;
                    default:
                        this.UploadError = true;
                        break;
                }
            });
        });
    }
    UploadChanged(file) {
        this.UploadError = false;
        const uploadData = new FormData();
        const path = this.createPath();
        uploadData.append(path + file.name, file, path + file.name);
        this.mediaService.UploadSpecific(this.MediaSourceID, uploadData).subscribe(event => {
            uploadData.forEach((value) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.Uploading = true;
                        break;
                    case HttpEventType.UploadProgress:
                        this.UploadProgress = Math.round(100 * event.loaded / event.total);
                        if (this.UploadProgress === 100) {
                            this.Uploadindeterminate = true;
                            this.Uploading = false;
                            this.UploadProgress = 0;
                        }
                        break;
                    case HttpEventType.Response:
                        this.Refresh();
                        break;
                    default:
                        this.UploadError = true;
                        break;
                }
            });
        });
    }
    Refresh() {
        this.Loading = true;
        this.Uploadindeterminate = false;
        this.ResetSelection();
        const path = this.createPath();
        this.mediaService.ListObjects(this.MediaSourceID, path).subscribe((result) => {
            if (path && !this.HasFixedPath) {
                this.Items = [{ Name: '..', IsDir: true }];
            } else {
                this.Items = [];
            }
            if (result) {
                const msURL = MediaSource.GetURL(this.MediaSource);
                result.forEach((item) => {
                    if (!item.IsDir && this.MediaSource.Private) {
                        this.mediaService.LoadImage(this.MediaSource.SID, path + item.Name).subscribe((image) => {
                            item.URL = image;
                            this.cdRef.detectChanges();
                        });
                    } else {
                        item.URL = msURL + '/' + path + item.Name + '?a=' + Math.random();
                    }
                    item.Type = item.Name.substring(item.Name.lastIndexOf('.') + 1).toLowerCase();
                    item.IsImage = ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'tiff', 'svg'].indexOf(item.Type) > -1;   
                    this.Items.push(item);
                });
                this.Items = this.Items.sort((x, y) => {
                    return (x.IsDir === y.IsDir) ? 0 : x ? -1 : 1;
                });
                this.originalItems = [...this.Items];
              
            }
            this.Loading = false;
            this.cdRef.detectChanges();
        });
    }

    searchFilter()
    {
        if (this.SearchValue) {
            const toLower = this.SearchValue.toLowerCase();
            this.Items = this.Items.filter(x => x.Name.toLowerCase().indexOf(toLower) > -1);
        } else {
            this.Items = [...this.originalItems];
        }
    }

    FolderSelected(folder) {
        if (folder.Name === '..') {
            this.Path = this.Path.slice(0, this.Path.length - 1);
        } else {
            this.Path.push(folder.Name);
        }
        this.SearchValue="";
        this.Refresh();
    }
    createPath() {
        let path = '';
        this.Path.forEach((folder) => {
            if (folder !== './') {
                path += folder;
            }
        });
        return path;
    }
    PathSelected(index) {
        this.Path = this.Path.slice(0, index + 1);
        this.Refresh();
    }
    ResetSelection() {
        this.Selection = null;
    }
}
