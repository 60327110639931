import { Component } from '@angular/core';
import { ABaseTreeNode } from '../../../components/common/basetreecontrol/base.tree.control';

@Component({
    selector: 'containertabular-column-dialog',
    templateUrl: './tabular.column.dialog.html',
    styleUrls: ['./tabular.column.dialog.css']
})
export class TabularColumnDialog {

    Nodes = [];
    Fields = [];

    Initialize(args) {
        if (args && args.RootNodes) {
            this.Nodes = args.RootNodes;
            args.RootNodes.forEach(node => {
                if (node.Children) {
                    this.Fields.push(...node.Children);
                }
            });
        }
    }

    GetDialogResult() {
        const retVal = [];
        this.Fields.forEach(function (f) {
            if (f.Selected) {
                retVal.push(f);
            }
        });
        return retVal;
    }

    selectAll(ev) {
        if (typeof ev === 'boolean') {
            this.Fields.forEach(function (f) {
                f.Selected = ev;
            });
        }
    }
}

export class TabularColumnNode extends ABaseTreeNode {
    Selected: boolean;
    UniqueID;
    FieldCaption;
    DataTyp;
}
