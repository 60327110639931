import { Component } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TaskRegistryEntry, TaskType } from '../../../helpers/task.registry';
import { CodeDesc } from '../../../models/enums/query.enum';
import { CodeDescriptionTaskExecuter } from '../../../models/tasks/datatasks/codedescription.model';
import { ALevelMeasurePanel, ALevelTaskSettingsHelper } from '../a.task.panel';

@Component({
    selector: 'codedescription-task-panel',
    templateUrl: './codedescription.task.panel.html'
})
export class CodeDescriptionTaskPanel extends ALevelMeasurePanel {
    DisplayTypes: any[];

    static GetRegistry(): TaskRegistryEntry {
        const reg = new TaskRegistryEntry();
        reg.ID = 'codedescription';
        reg.Caption = '@@Anzeige';
        reg.SettingsControl = CodeDescriptionTaskPanel;
        reg.SettingsHelper = new CodeDescriptionSettingsHelper();
        reg.Executer = CodeDescriptionTaskExecuter;
        reg.TaskType = TaskType.DataTask;
        return reg;
    }

    constructor() {
        super();
        this.DisplayTypes = EnumHelper.GetDropdownValues(CodeDesc).filter(x => x.type != CodeDesc.Attribute);;
    }
}

export class CodeDescriptionSettingsHelper extends ALevelTaskSettingsHelper {
    getCaption(task: any, dd: any) {
        const caption = super.getCaption(task, dd);
        if (caption) {
            return caption + " - " + TranslateHelper.TranslatorInstance.instant('@@Anzeige');
        }
        return TranslateHelper.TranslatorInstance.instant('@@Anzeige');
    }
}
