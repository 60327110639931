import { ITaskExecuter } from '../atask.model';
import { MultiResult, AxisNode } from '../../datadescription/multi/multiresult.model';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { StyleMerger } from '../../styling/styleMerger';
import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { TaskHelper } from '../task.helper';
import { CellStyle } from '../../styling/cell.style';

export class LevelFormattingTaskExecuter implements ITaskExecuter {
    static TaskID = 'levelformattingtask';

    LevelUniqueID = -1;
    Style: CellStyle;
    FormatHeaders = false;
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;

    static IterateLevelNodes(levelID: number, dataDescription: DataDescription, result: MultiResult,
        cellFunction: (xNode: AxisNode, yNode: AxisNode, mNode: AxisNode) => void, levelFunction: (lNode: AxisNode) => void) {
        if (levelID > -1 && dataDescription && result) {
            if (!dataDescription.XLevelNodes.Areas.some(area => {
                if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === levelID))) {
                    const mNodes = [];
                    if (area.Measures && area.Measures.length > 0) {
                        area.Measures.forEach(m => {
                            const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                            if (mNode) {
                                mNodes.push(mNode);
                            }
                        });
                    } else {
                        dataDescription.YLevelNodes.Areas.forEach(yArea => {
                            if (yArea.Measures) {
                                yArea.Measures.forEach(m => {
                                    const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                    if (mNode) {
                                        mNodes.push(mNode);
                                    }
                                });
                            }
                        });
                    }
                    if (mNodes.length > 0) {
                        const sections = MultiResultHelper.GetNodeSections(result.XAxis, levelID);
                        sections.forEach(sec => {
                            sec.forEach(xNode => {
                                if (levelFunction) {
                                    levelFunction(xNode);
                                }
                                if (cellFunction) {
                                    MultiResultHelper.ExecuteOnAllNodes(result.YAxis, (yNode) => {
                                        mNodes.forEach(mNode => {
                                            cellFunction(xNode, yNode, mNode);
                                        });
                                    });
                                }
                            });
                        });
                    }
                    return true;
                }
                return false;
            })) {
                dataDescription.YLevelNodes.Areas.some(area => {
                    if (area.Tuples && area.Tuples.some(t => t.Levels && t.Levels.some(l => l.UniqueID === levelID))) {
                        const mNodes = [];
                        if (area.Measures && area.Measures.length > 0) {
                            area.Measures.forEach(m => {
                                const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                if (mNode) {
                                    mNodes.push(mNode);
                                }
                            });
                        } else {
                            dataDescription.XLevelNodes.Areas.forEach(xArea => {
                                if (xArea.Measures) {
                                    xArea.Measures.forEach(m => {
                                        const mNode = result.Measures.Nodes.find(x => x.UniqueID === m.UniqueID);
                                        if (mNode) {
                                            mNodes.push(mNode);
                                        }
                                    });
                                }
                            });
                        }
                        if (mNodes.length > 0) {
                            const sections = MultiResultHelper.GetNodeSections(result.YAxis, levelID);
                            sections.forEach(sec => {
                                sec.forEach(yNode => {
                                    if (levelFunction) {
                                        levelFunction(yNode);
                                    }
                                    if (cellFunction) {
                                        MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (xNode) => {
                                            mNodes.forEach(mNode => {
                                                cellFunction(xNode, yNode, mNode);
                                            });
                                        });
                                    }
                                });
                            });
                        }
                        return true;
                    }
                    return false;
                });
            }
        }
    }

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.LevelUniqueID === 'number') {
                this.LevelUniqueID = settings.LevelUniqueID;
            }
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
            if (typeof settings.IsStyleAppliedToHeaders === 'boolean') {
                this.FormatHeaders = settings.IsStyleAppliedToHeaders;
            }
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
    }

    Execute() {
        if (this.Style && this.Merger) {
            const cellFunction = TaskHelper.RequiresCell(this.Style) ?
                (x, y, m) => {
                    const cell = MultiResultHelper.GetCellOrNew(this.Result, x, y, m);
                    cell.StyleID = this.Merger.MergeStyle(cell.StyleID, this.Style);
                } : (x, y, m) => {
                    const cell = MultiResultHelper.GetCellOrNull(this.Result, x, y, m);
                    if (cell) {
                        cell.StyleID = this.Merger.MergeStyle(cell.StyleID, this.Style);
                    }
                };
            const lFunction = this.FormatHeaders ? (n) => { n.StyleID = this.Merger.MergeStyle(n.StyleID, this.Style); } : null;
            LevelFormattingTaskExecuter.IterateLevelNodes(this.LevelUniqueID, this.DataDescription, this.Result, cellFunction, lFunction);
        }
    }
}
