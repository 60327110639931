import { AfterViewInit, Component, Input } from '@angular/core';
import { EnumHelper } from '../../../helpers/enum.helper';
import { Aggregation } from '../../../models/enums';
import { VisibilityType } from '../../../models/enums/oc.enum';
import { TranslatedString } from '../../../models/translatedstring.model';
import { ObjectcreatorDataManager } from '../helper/objectcreator.datamanager';

@Component({
    selector: 'measuredefinitionsrel',
    templateUrl: './measuredef.rel.control.html'
})
export class MeasureDefinitionRelationalControl implements AfterViewInit {
    AllColumns = [];
    FilteredColumns = [];
    SelectedColumn;
    VisibilityTypes;
    ASFunctionTypes;

    _SearchValue: string;
    get SearchValue() {
        return this._SearchValue;
    }
    set SearchValue(val) {
        this._SearchValue = val;
        this.FilterList();
    }

    @Input()
    DataDescription: any;

    constructor() {
        this.VisibilityTypes = EnumHelper.GetDropdownValues(VisibilityType);
        this.ASFunctionTypes = EnumHelper.GetDropdownValues(Aggregation);
    }

    ngAfterViewInit(): void {
        ObjectcreatorDataManager.FieldsChanged.subscribe(() => {
            this.UpdateFields();
        });
        this.UpdateFields();
    }

    UpdateFields() {
        const allColumns = [];
        if (this.DataDescription && this.DataDescription.Fields) {
            this.DataDescription.Fields.forEach(x => {
                let caption = TranslatedString.GetTranslation(x.TranslatedCaption);
                if (!caption) {
                    caption = x.Caption;
                }
                allColumns.push({
                    ID: x.ID,
                    Caption: caption
                });
            });
        }
        this.AllColumns = allColumns;
        this.SelectedColumn = null;
        this.FilterList();
    }

    FilterList() {
        if (this._SearchValue) {
            const toLower = this._SearchValue.toLowerCase();
            this.AllColumns = this.AllColumns.filter(x => x.LowerCaption.indexOf(toLower) > -1);
        } else {
            this.FilteredColumns = [...this.AllColumns];
        }
    }

    columnSelected(item) {
        if (this.DataDescription && this.DataDescription.Fields) {
            this.SelectedColumn = this.DataDescription.Fields.find(x => x.ID == item);
        } else {
            this.SelectedColumn = null;
        }
    }
}
