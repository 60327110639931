import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { NotificationHelper } from '../../../helpers/notification.helper';
import { MessageBoxButtons } from '../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../models/enums/messageboxicon.enum';
import { MessageBoxResult } from '../../../models/enums/messageboxresult.enum';
import { WorkflowSaveObject } from '../../../models/workflow/workflow.model';
import { ConnectorService } from '../../../services/connector.service';
import { LayoutService } from '../../../services/layout.service';
import { MessageBoxHelper } from '../../../components/dialogs/messagebox/messagebox.dialog';
import { ConnectorWorkflowEditDialog } from '../connector.workflow.edit.dialog';

@Component({
    selector: 'connector-wfmapping-settings',
    templateUrl: './connector.wfmapping.settings.html'
})
export class ConnectorWorkflowMappingSettings implements OnInit {
    InitArgs;
    RootNodes = [];

    constructor(private service: ConnectorService, protected cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.InitArgs) {
            LayoutService.Loading.next(true);
            this.service.GetWorkflowMappingStructure(this.InitArgs.SID).subscribe(x => {
                if (x) {
                    this.RootNodes = x;
                    this.cdRef.detectChanges();
                }
                LayoutService.Loading.next(false);
            });
        }
    }

    edit(node) {
        if (this.InitArgs && node) {
            if (node.MappingID) {
                this.service.GetWorkflowMapping(node.MappingID).subscribe(result => {
                    if (result) {
                        ConnectorWorkflowEditDialog.ShowDialog({
                            Name: this.InitArgs.Caption,
                            SID: this.InitArgs.SID
                        }, result.Workflow, (wf) => {
                            result.Workflow = wf;
                            this.service.SaveWorkflowMapping(result).subscribe(x => {
                                if (x) {
                                    node.MappingID = x.SID;
                                    NotificationHelper.Success('@@Erfolgreich gespeichert', '@@Speichern');
                                }
                            });
                        });
                    } else {
                        this.addMapping(node);
                    }
                });
            } else {
                this.addMapping(node);
            }
        }
    }

    addMapping(node) {
        if (this.InitArgs) {
            ConnectorWorkflowEditDialog.ShowDialog({
                Name: this.InitArgs.Caption,
                SID: this.InitArgs.SID
            }, new WorkflowSaveObject(), (wf) => {
                this.service.SaveWorkflowMapping({
                    ConnectorId: this.InitArgs.SID,
                    ContainerId: node.Container,
                    Operation: node.Operation,
                    Workflow: wf
                }).subscribe(x => {
                    if (x) {
                        node.MappingID = x.SID;
                        NotificationHelper.Success('@@Erfolgreich neu angelegt', '@@Speichern');
                    }
                });
            });
        }
    }

    reset(node) {
        if (node.MappingID) {
            const text = new TranslateFormatText('@@Sind Sie sicher, dass Sie den Workflow fuer Operation {0} loeschen moechten?');
            text.FormatParams.push(node.Caption);
            MessageBoxHelper.ShowDialog(text, new TranslateFormatText('@@Loeschen'),
                MessageBoxButtons.YesNo, MessageBoxIcon.Question).then(x => {
                    if (x === MessageBoxResult.Yes) {
                        this.service.DeleteWorkflowMapping(node.MappingID).subscribe(result => {
                            if (result && result.DeleteCount > 0 && !result.CapsuleActive) {
                                node.MappingID = null;
                                NotificationHelper.Success('@@Erfolgreich geloescht', '@@Loeschen');
                            }
                        });
                    }
                });
        }
    }
}
