import { Component } from '@angular/core';
import { plainToClass, Type } from 'class-transformer';
import { WorkflowType } from '../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../models/workflow/workflow.model';
import { OutputService } from '../../../services/meta.service';
import { StandardRequestBase } from '../../../services/request-base';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../services/workflow.service';
import { FormulaWorkflowDialogContent } from '../../workflow.dialog';

@Component({
    selector: 'wf-generateoutput-settings',
    templateUrl: './generateoutput.settings.html'
})
export class GenerateOutputSettings extends FormulaWorkflowDialogContent {
    Data = new GenerateOutputSettingsData();
    Templates = [];
    PageDataList = [];
    Languages = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'generateoutputWFModule';
        reg.Caption = '@@Output erzeugen';
        reg.GroupID = 'wfActions';
        reg.Index = 50;
        reg.SettingsControl = GenerateOutputSettings;
        reg.SettingsTypeHelper = new GenerateOutputSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: OutputService, private standard: StandardRequestBase) {
        super();
    }

    ngOnInit(): void {
        this.service.ReadOutputInfos().subscribe(result => {
            if (result) {
                this.Templates = result;
                if (this.Data && this.Data.OutputID) {
                    const template = result.find(x => x.SID === this.Data.OutputID);
                    if (template && template.Pages) {
                        if (this.Data.PageData && this.Data.PageData.length > 0) {
                            template.Pages.forEach(tp => {
                                const pd = this.Data.PageData.find(pd => pd.PageID === tp.SID);
                                if (pd) {
                                    tp.Formula = pd.Formula;
                                }
                            });
                        }
                        this.PageDataList = template.Pages;
                    }
                }
            }
        });
        this.standard.executeGet('config/api/config', 'GetSelectedLanguages').subscribe(languages => {
            if (languages) {
                this.Languages = languages;
            }
        });
    }

    fillPageData() {
        this.PageDataList = [];
        if (this.Data && this.Data.OutputID) {
            const template = this.Templates.find(x => x.SID === this.Data.OutputID);
            if (template && template.Pages) {
                this.PageDataList = template.Pages;
            }
        }
    }

    initialize(data: any) {
        super.initialize(data);
        if (data) {
            const json = JSON.stringify(data);
            this.Data = plainToClass(GenerateOutputSettingsData, JSON.parse(json));
        }
    }

    getResult(): any {
        const pageData = [];
        this.PageDataList.forEach(x => {
            if (x.Formula) {
                const opd = new OutputPageData();
                opd.Formula = x.Formula;
                opd.PageID = x.SID;
                pageData.push(opd);
            }
        });
        this.Data.PageData = pageData;
        return this.Data;
    }
}

export class OutputPageData {
    PageID: string;
    Formula: string;
}

// @dynamic
export class GenerateOutputSettingsData extends AServiceWorkflowData {
    OutputID: string;
    @Type(() => OutputPageData)
    PageData: OutputPageData[] = [];
    FixedLanguage = -1;

    getTypeName(): string {
        return 'evidanza.App.Shared.Workflow.Modules.GenerateOutput.GenerateOutputSettingsData';
    }
}

export class GenerateOutputSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        const wei = new WorkflowExitInfo();
        wei.Type = 'media';
        return [wei];
    }
    getEmptySettingsInstance() {
        return new GenerateOutputSettingsData();
    }
}
