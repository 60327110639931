import { ChangeDetectorRef, Component } from '@angular/core';
import { APropertyTab } from '../../appbuilder/menutabs/properties/properties.menu.tab';
import { WorkflowWizardService } from '../../services/workflow.wizard.service';

@Component({
    selector: 'workflow-wizard-menu-tab',
    templateUrl: './workflow.wizard.menu.tab.html'
})
export class WorkflowWizardMenuTab extends APropertyTab {
    Wizards = [];

    constructor(private service: WorkflowWizardService, cdRef: ChangeDetectorRef) {
        super(cdRef);
    }

    ngOnInit() {
        super.ngOnInit();
        this.service.GetAllWizards().subscribe(result => {
            if (result) {
                this.Wizards = result;
            }
        });
    }

    onWizardChanged() {
        if (this.SelectedItem && this.SelectedItem.Element) {
            this.SelectedItem.Element.UpdateWizard();
        }
    }
}
