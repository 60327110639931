import { ComponentPortal } from "@angular/cdk/portal";
import { UUID } from "angular2-uuid";
import { PropertyGroupDisplay } from "../../models/enums/propertygroupdisplay.enum";
import { MenuTabLabelPosition } from "../../models/menutab/menutab.property.model";
import { PROPERTIES, PROPERTYGROUPS } from "../../services/dynamic.component.service";
import { LayoutService } from "../../services/layout.service";
import { ListBoxThemeControl } from "../controls/listbox/listbox.theme.control";
import { TextboxThemeControl } from "../controls/textbox/textbox.theme.control";
import { GenericMenuTab } from "../menutabs/generic/generic.menu.tab";
import { BasePanel } from "./base.panel";

export class CustomEventsPanel extends BasePanel {
    static override SIDS = ['d0367b90-4288-47aa-883c-6f9cd579d48a']
    static InitPanel() {
        PROPERTYGROUPS.push({
            SID:'d0367b90-4288-47aa-883c-6f9cd579d48a',
            ID: 'CustomEvents',
            Caption: '@@CustomEvents',
            Index: 201,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['1fr'],
            Rows: ['auto'],
            CheckVisibilityAsync: (item) => {
                return new Promise((resolve) => {
                    const selected = LayoutService.SelectedItem.getValue();
                    const layout = LayoutService.SelectedLayout.getValue();
                    if (selected && layout && (layout.ElementType === 'template' || layout.ElementType === 'widget')) {
                        if (selected.ElementType === 'template' || selected.ElementType === 'widget') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(false);
                    }
                })
            }
        });
        PROPERTIES.push({
            ID: "CustomEvents",
            Parent: "CustomEvents",
            Content: new ComponentPortal(ListBoxThemeControl),
            Properties: [
                {
                    ID: "Name",
                    Parent: "CustomEvents",
                    Content: new ComponentPortal(TextboxThemeControl),
                    Label: "@@Name",
                    LabelPosition: MenuTabLabelPosition.Top
                }
            ],
            InitArgs: {
                ChangeType: 'Layout',
                GenerateNewItem: () => {
                    const item = {
                        ID: UUID.UUID(),
                        Name: 'Var1',
                        Value: null,
                        Type: null,
                        Formula: null
                    };
                    const layout = LayoutService.SelectedLayout.getValue();
                    if (layout && layout.CustomEvents && layout.CustomEvents.length > 0) {
                        let index = layout.CustomEvents.length + 1;
                        let name = 'Var' + index++;
                        while (layout.CustomEvents.some(x => x.Name === name)) {
                            name = 'Var' + index++;
                        }
                        item.Name = name;
                    }
                    return item;
                }
            },
            LabelPosition: MenuTabLabelPosition.Top
        });
    }
}