import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { plainToClass } from 'class-transformer';
import { ContentType } from '../../../models/enums/contenttype.enum';
import { ViewType } from '../../../models/enums/viewtype.enum';
import { Layout } from '../../../models/layout.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { IBaseComponent } from '../../controls/base.component';

@Component({
    selector: 'evi-template',
    templateUrl: './template.layout.html',
    styleUrls: ['./template.layout.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateLayout extends IBaseComponent {
    static Type: any = 'template';
    static Default = {
        Editable: true,
        Type: 'template'
    };
    TemplateRootValue;
    get TemplateRoot() {
        return this.TemplateRootValue;
    }
    set TemplateRoot(val) {
        this.TemplateRootValue = val;
        if (this.TemplateRootValue) {
            this.TemplateRootValue.Parent = this.LayoutElementValue;
        }
    }
    InitFired = false;

    constructor(public cdRef: ChangeDetectorRef, @Inject(LayoutService.CONTAINER_DATA) public data, private service: MetaService) {
        super(cdRef, data);
    }

    onLayoutElementSet() {
        this.TemplateRoot = null;
        const sub = this.Subscriptions['Editable'];
        if (sub) {
            sub.unsubscribe();
        }
        this.Subscriptions['Editable'] = this.LayoutElementValue.EditableChanged.subscribe((value) => {
            this.Editable = value;
        });
        this.Init()
    }
    onLayoutElementChanged() {
        this.Init();
    }
    private Init() {
        if (this.LayoutElementValue.ViewType == ViewType.Edit) {
            let parent = this.LayoutElementValue.Parent;
            while (parent) {
                if (parent.ElementType == TemplateLayout.Type && parent._Id == this.LayoutElementValue._Id) {
                    return;
                }
                parent = parent.Parent;
            }
        }

        if (this.LayoutElementValue.LoadByReference) {
            this.service.LoadTemplate(this.LayoutElementValue._Id).subscribe((value) => {
                const data = plainToClass(Layout, value);
                data.Element = this;
                this.ChangeCopy(data);
                const elements = data['Elements'];
                if (elements && elements.length === 1) {
                    this.TemplateRoot = elements[0];
                    this.TemplateRoot.SetViewType(this.LayoutElementValue.ViewType);
                    this.LayoutElementValue['Elements'] = elements;
                }
                const workflows = data['Workflows'];
                if (workflows) {
                    this.LayoutElementValue['Workflows'] = workflows;
                }
                const variables = data['Variables'];
                if (variables) {
                    this.LayoutElementValue['Variables'] = variables;
                }
                const events = data['CustomEvents'];
                if (events) {
                    this.LayoutElementValue['CustomEvents'] = events;
                    if (events.length > 0) {
                        events.forEach((event) => {
                            this.EventList.push(event.Name);
                        });
                    }
                    this.fillEvents();
                }
                this.Layout = data;
                this.onLayoutSet(data);                
            });
        } else {
            if (this.LayoutElementValue.Elements && this.LayoutElementValue.Elements.length === 1) {
                this.TemplateRoot = this.LayoutElementValue.Elements[0];
            }
            this.onLayoutSet(this.LayoutElementValue);
        }
    }
    private ChangeCopy(copy) {
        copy.ID = UUID.UUID();
        if (copy.Elements) {
            copy.Elements.forEach(x => this.ChangeCopy(x));
        }
    }
    onLayoutSet(layout) {
        if (this.TemplateRoot) {
            let type = '';
            switch (layout['ContentType']) {
                case ContentType.Grid: type = 'grid'; break;
                case ContentType.Flex: type = 'flex'; break;
                case ContentType.Raster: type = 'raster'; break;
                case ContentType.Canvas: type = 'canvas'; break;
                default: type = 'grid'; break;
            }
            this.TemplateRoot['ElementType'] = type;
        }
        if (this.Initialized) {
            this.ControlInitialized();
        }
        this.cdRef.detectChanges();
    }
    
    ControlInitialized() {
        if (this.TemplateRoot && !this.InitFired) {
            this.InitFired = true;

            const sub = this.TemplateRoot.Initialized.subscribe(x => {
                if (x === true) {
                    this.setInitialized();
                    
                }
            });
        }
    }

    itemSelected() {
        if (!this.TemplateRoot.Selected && !this.LayoutElementValue.LoadByReference) {
            LayoutService.SelectedItem.next(this.TemplateRoot);
        }
    }
}
