import { ComponentPortal } from "@angular/cdk/portal";
import { BehaviorSubject } from "rxjs";
import { RequestOptionsColumn, RequestOptionsControl, RequestOptionsDialogArgs } from "../../components/common/requestoptions/request.options.control";
import { FilterHelper } from "../../helpers/filter.helper";
import { InjectorHelper } from "../../helpers/injector.helper";
import { LayoutHelper } from "../../helpers/layout.helper";
import { MetaHelper } from "../../helpers/meta.helper";
import { PropertyGroupDisplay } from "../../models/enums/propertygroupdisplay.enum";
import { ViewType } from "../../models/enums/viewtype.enum";
import { MultiPropertyChangeValue } from "../../models/layout/layout.change.model";
import { RequestFilter } from "../../models/rest/requestfilter";
import { RequestOptions } from "../../models/rest/requestoptions";
import { DataService } from "../../services/data.service";
import { DataModelService } from "../../services/datamodel.service";
import { PROPERTIES, PROPERTYGROUPS } from "../../services/dynamic.component.service";
import { LayoutService } from "../../services/layout.service";
import { ButtonThemeControl } from "../controls/button/button.theme.control";
import { ComboboxThemeControl } from "../controls/combobox/combobox.theme.control";
import { TextAreaThemeControl } from "../controls/textarea/textarea.theme.control";
import { GenericMenuTab } from "../menutabs/generic/generic.menu.tab";
import { BasePanel } from "./base.panel";

export class DataSourcePanel extends BasePanel {
    static override SIDS = ['977303bb-de02-40c0-936a-df5a5982572f']
    static override SetSelectedItem(item) {
        DataSourcePanel.CheckItem();
        DataSourcePanel.CheckFilterText();
    }
    private static ResetDataBinding(LayoutElement, idList) {
        if (LayoutElement) {
            LayoutElement.DataSource = null;
            LayoutElement.DataSourceID = null;
            LayoutElement.DataModelID = null;
            LayoutElement.DataFilter = null;
            idList.push(LayoutElement.ID);
            if (LayoutElement.Elements && LayoutElement.Elements.length > 0) {
                LayoutElement.Elements.forEach((item) => {
                    DataSourcePanel.ResetDataBinding(item, idList);
                });
            }
        }
    }
    private static DataModels: BehaviorSubject<any> = new BehaviorSubject(null);
    private static Tables: BehaviorSubject<any> = new BehaviorSubject(null);
    private static CheckItem() {
        if (DataSourcePanel.SelectedItem) {
            if ((DataSourcePanel.SelectedItem.DataModelID == null && DataSourcePanel.SelectedItem.DataSource != null) || DataSourcePanel.SelectedItem.SourceType == 0) {
                DataSourcePanel.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((tables) => {
                    DataSourcePanel.Tables.next(tables);
                });
            }
            if (DataSourcePanel.SelectedItem.DataModelID) {
                DataSourcePanel.dataModelService.GetModels().subscribe(models => {
                    DataSourcePanel.DataModels.next(models);
                });
                if (DataSourcePanel.SelectedItem.DataSource) {
                    if (DataSourcePanel.SelectedItem.SourceType != null) {
                        switch (DataSourcePanel.SelectedItem.SourceType) {
                            case DataSourcePanel.SelectedItem: break;
                            case 0:
                                break;
                            case 1:
                                DataSourcePanel.dataService.GetItem('dynamic', 'GetNonCCOContainersByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((data) => {
                                    const container = [];
                                    data.forEach((tab) => {
                                        container.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(container);
                                });
                                break;
                            case 2:
                                DataSourcePanel.dataService.GetItem('dynamic', 'GetTablesByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((tableList) => {
                                    const tables = [];
                                    tableList.forEach((tab) => {
                                        tables.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(tables);
                                });
                                break;
                        }
                    } else {
                        DataSourcePanel.dataService.GetItem('dynamic', 'GetNonCCOContainersByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((data) => {
                            let tables = [];
                            if (data && data.length > 0) {
                                let retVal = data.find((tab) => tab.SID == DataSourcePanel.SelectedItem.DataSource);
                                if (retVal) {
                                    DataSourcePanel.SelectedItem.SourceType = 1;
                                    data.forEach((tab) => {
                                        tables.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(tables);
                                }
                            }
                        });
                        DataSourcePanel.dataService.GetItem('dynamic', 'GetTablesByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((data) => {
                            let tables = [];
                            if (data && data.length > 0) {
                                let retVal = data.find((tab) => tab.SID == DataSourcePanel.SelectedItem.DataSource);
                                if (retVal) {
                                    DataSourcePanel.SelectedItem.SourceType = 2;
                                    data.forEach((tab) => {
                                        tables.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(tables);
                                }
                            }
                        });
                    }
                    
                }
            }
        }
    }
    private static ColumnText: BehaviorSubject<any> = new BehaviorSubject(null);
    private static FilterText: BehaviorSubject<any> = new BehaviorSubject(null);
    private static SortText: BehaviorSubject<any> = new BehaviorSubject(null);
    private static CheckFilterText() {
        let columnText = null;
        let filterText = null;
        let sortText = null;
        if (DataSourcePanel.SelectedItem && DataSourcePanel.SelectedItem.DataFilter) {
            if (DataSourcePanel.SelectedItem.DataFilter.Columns && DataSourcePanel.SelectedItem.DataFilter.Columns.length > 0) {
                const list = [];
                DataSourcePanel.SelectedItem.DataFilter.Columns.forEach((col) => {
                    list.push(col.Name);
                });
                columnText = list.join(', ');
            }
            if (DataSourcePanel.SelectedItem.DataFilter.Filters && DataSourcePanel.SelectedItem.DataFilter.Filters.length > 0) {
                filterText = RequestFilter.getRequestFilterText(DataSourcePanel.SelectedItem.DataFilter.Filters[0]);
            }
            if (DataSourcePanel.SelectedItem.DataFilter.Sort && DataSourcePanel.SelectedItem.DataFilter.Sort.length > 0) {
                const list = [];
                DataSourcePanel.SelectedItem.DataFilter.Sort.forEach(x => {
                    list.push(x.Name + ' (' + (x.Order === 0 ? 'ASC' : 'DESC') + ')');
                });
                sortText = list.join(', ');
            }
        }
        DataSourcePanel.ColumnText.next(columnText);
        DataSourcePanel.FilterText.next(filterText);
        DataSourcePanel.SortText.next(sortText);
    }
    private static dataService;
    private static dataModelService;
    static InitPanel() {
        DataSourcePanel.dataModelService = InjectorHelper.InjectorInstance.get<DataModelService>(DataModelService);
        DataSourcePanel.dataService = InjectorHelper.InjectorInstance.get<DataService>(DataService);
        this.InitSelectedItem();
        PROPERTYGROUPS.push({
            SID:'977303bb-de02-40c0-936a-df5a5982572f',
            ID: 'DataSource',
            Caption: '@@DataSource',
            Index: 203,
            Content: GenericMenuTab,
            Display: PropertyGroupDisplay.Grid,
            Columns: ['1fr'],
            Rows: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            CheckVisibilityAsync: (item) => {
                return new Promise((resolve) => {
                    const selected = LayoutService.SelectedItem.getValue();
                    if (selected && selected.ElementType && ['flex', 'grid', 'raster', 'canvas', 'bootstrapgrid', 'bootstraprepeat'].indexOf(selected.ElementType) >= 0) {
                        const layout = LayoutHelper.GetLayout();
                        const resolution = LayoutHelper.GetActiveResolution(layout);
                        MetaHelper.FindDataBindingProperties(resolution, selected).then(result => {
                            resolve(!result || !result.IsDataBinding)
                        });
                    } else {
                        resolve(false)
                    }
                })
            }
        });
        LayoutService.ViewType.subscribe((vt) => {
            if (vt == ViewType.Edit && DataSourcePanel.SelectedItem) {
                DataSourcePanel.CheckItem();
            }
        })
        const dstype = [
            { Caption: '@@None', Value: null },
            { Caption: '@@Static', Value: 0 },
            { Caption: '@@Container', Value: 1 },
            { Caption: '@@Dynamic', Value: 2 },
        ]
        PROPERTIES.push({
            ID: "SourceType",
            Parent: "DataSource",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DataSourceType",

            InitArgs: {
                Placeholder: "",
                Multiple: false,
                ItemsSource: dstype,
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (value) => {
                    if (value && value.Event) {
                        delete DataSourcePanel.SelectedItem.DataSource;
                        delete DataSourcePanel.SelectedItem.DataModelID;
                        delete DataSourcePanel.SelectedItem.DataSourceID;
                        delete DataSourcePanel.SelectedItem.DataFilter;
                        const mlpcv = new MultiPropertyChangeValue();
                        ['DataSource', 'DataSourceID', 'DataModelID', 'DataFilter'].forEach(x => {
                            mlpcv.Properties.push({
                                PropertyName: x,
                                Value: null
                            });
                        });
                        DataSourcePanel.ResetDataBinding(DataSourcePanel.SelectedItem, mlpcv.ElementIDs);
                        LayoutService.OnMultiLayoutPropertyChanged([mlpcv]);
                        switch (value.Event.value) {
                            case null:
                               
                                break;
                            case 0:
                                DataSourcePanel.dataService.GetList('dynamic', 'GetAllStaticTables').subscribe((tables) => {
                                    DataSourcePanel.Tables.next(tables);
                                });
                                break;
                            case 1:
                            case 2:
                                DataSourcePanel.dataModelService.GetModels().subscribe(models => {
                                    DataSourcePanel.DataModels.next(models);
                                });
                                break;
                        }
                    }
                }
            }
        });

        PROPERTIES.push({
            ID: "DataModelID",
            Parent: "DataSource",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DataModel",
            CheckVisibility: (item) => {
                return item.SourceType == 1 || item.SourceType == 2;
            },
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                ItemsSourceSub: DataSourcePanel.DataModels,
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'SID',
                Action: (value) => {
                    if (value && value.Event && value.Event.value) {
                        if (DataSourcePanel.SelectedItem) {
                            if (DataSourcePanel.SelectedItem.DataSource != null) {
                                DataSourcePanel.SelectedItem.DataSource = null;
                                DataSourcePanel.SelectedItem.DataFilter = null;
                            }
                            if (DataSourcePanel.SelectedItem.SourceType == 1) {
                                DataSourcePanel.dataService.GetItem('dynamic', 'GetNonCCOContainersByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((data) => {
                                    const container = [];
                                    data.forEach((tab) => {
                                        container.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(container);
                                });
                            }
                            if (DataSourcePanel.SelectedItem.SourceType == 2) {
                                DataSourcePanel.dataService.GetItem('dynamic', 'GetTablesByDataModel', DataSourcePanel.SelectedItem.DataModelID).subscribe((tableList) => {
                                    const tables = [];
                                    tableList.forEach((tab) => {
                                        tables.push({
                                            Value: tab.SID,
                                            Caption: tab.Caption
                                        });
                                    });
                                    DataSourcePanel.Tables.next(tables);
                                });
                            }
                        }
                    }
                }
            }
        });
        PROPERTIES.push({
            ID: "DataSource",
            Parent: "DataSource",
            Content: new ComponentPortal(ComboboxThemeControl),
            Label: "@@DataSource",
            CheckVisibility: (item) => {
                return item.SourceType == 0 || item.DataModelID;
            },
            InitArgs: {
                Placeholder: "",
                Multiple: false,
                ItemsSourceSub: DataSourcePanel.Tables,
                DisplayMemberPath: 'Caption',
                ValueMemberPath: 'Value',
                Action: (ev) => {
                    const selected = ev.SelectedItem;
                    if (selected) {
                        const changedProps = [];
                        if (selected.SourceType == 0 && selected.DataModelID != null) {
                            selected.DataModelID = null;
                            changedProps.push('DataModelID');
                        }
                        changedProps.push('DataSource');
                        selected.DataFilter = new RequestOptions();
                        changedProps.push('DataFilter');
                        DataSourcePanel.CheckFilterText();
                        LayoutService.LayoutChanged.next(null);
                    }
                }
            }
        });
        PROPERTIES.push({
            ID: "DataFilter",
            Parent: "DataSource",
            Content: new ComponentPortal(ButtonThemeControl),
            CheckVisibility: (item) => {
                return item.DataSource;
            },
            InitArgs: {
                Icon:'more_horiz',
                Action: (ev) => {
                    const selected = ev.SelectedItem;
                    if (selected && selected.DataSource) {
                        this.dataModelService.GetFilterInfo(selected.DataSource).subscribe(filterInfo => {
                            if (filterInfo) {
                                const initArgs = new RequestOptionsDialogArgs();
                                initArgs.SystemVariables = FilterHelper.GetVariables(selected);
                                if (selected.DataFilter) {
                                    initArgs.RequestOptions = selected.DataFilter;
                                }
                                initArgs.ShowFilter = filterInfo.CanFilter;
                                filterInfo.Columns.forEach(x => {
                                    const col = new RequestOptionsColumn();
                                    col.Name = x.Name;
                                    col.Type = x.DataType;
                                    col.CanFilter = filterInfo.CanFilter;
                                    col.CanSelect = filterInfo.CanSelectColumns;
                                    initArgs.Columns.push(col);
                                });
                                RequestOptionsControl.ShowDialog(initArgs, '@@Spalten, Filter und Sortierung', (r) => {
                                    selected.DataFilter = r;
                                    DataSourcePanel.CheckFilterText();
                                    LayoutService.OnLayoutPropertyChanged(selected.ID, 'DataFilter', r);
                                });
                            }
                        });
                    }
                }
            }
        });
        PROPERTIES.push({
            ID: null,
            Label: "@@Spalten",
            Parent: "DataSource",
            DataSource: DataSourcePanel.ColumnText,
            Content: new ComponentPortal(TextAreaThemeControl),
            CheckVisibility: (item) => {
                return item.DataFilter && item.DataFilter.Columns && item.DataFilter.Columns.length > 0;
            },
            InitArgs: {
                Disabled: true,

            }
        });
        PROPERTIES.push({
            ID: null,
            Label: "@@Filter",
            Parent: "DataSource",
            DataSource: DataSourcePanel.FilterText,
            Content: new ComponentPortal(TextAreaThemeControl),
            CheckVisibility: (item) => {
                return item.DataFilter && item.DataFilter.Filters && item.DataFilter.Filters.length > 0;
            },
            InitArgs: {
                Disabled: true,

            }
        });
        PROPERTIES.push({
            ID: null,
            Parent: "DataSource",
            DataSource: DataSourcePanel.SortText,
            Content: new ComponentPortal(TextAreaThemeControl),
            Label:"@@Sortierung",
            CheckVisibility: (item) => {
                return item.DataFilter && item.DataFilter.Sort && item.DataFilter.Sort.length > 0;
            },
            InitArgs: {
                Disabled: true,

            }
        });
    }
}