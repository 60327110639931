import { Component, OnInit } from "@angular/core";
import { TranslateFormatText } from "../../../helpers/array.helpers";
import { NotificationHelper } from "../../../helpers/notification.helper";
import { MessageBoxButtons } from "../../../models/enums/messageboxbuttons.enum";
import { MessageBoxIcon } from "../../../models/enums/messageboxicon.enum";
import { MessageBoxResult } from "../../../models/enums/messageboxresult.enum";
import { TranslatedString } from "../../../models/translatedstring.model";
import { RolesService } from "../../../services/roles.service";
import { MessageBoxHelper } from "../../../components/dialogs/messagebox/messagebox.dialog";

@Component({
    selector: 'childroles-settings',
    templateUrl: './childroles.settings.html',
    styleUrls: ['./childroles.settings.css']
})
export class ChildRoleSettings implements OnInit {

    FilteredRoles = [];
    Roles = [];
    Role;
    HasChanges = false;
    InitArgs;

    _RolesSelectedOnly;
    get RolesSelectedOnly() {
        return this._RolesSelectedOnly;
    }
    set RolesSelectedOnly(value) {
        this._RolesSelectedOnly = value;
        this.FilterRoles();
    }
    _RoleSearchValue;
    get RoleSearchValue() {
        return this._RoleSearchValue;
    }
    set RoleSearchValue(value) {
        this._RoleSearchValue = value;
        this.FilterRoles();
    }

    constructor(private rolesService: RolesService) {
    }

    ngOnInit(): void {
        if (this.InitArgs) {
            this.rolesService.FindRole(this.InitArgs.Id).subscribe(role => {
                this.Role = role;
                this.rolesService.ReadRoleInfos().subscribe(result => {
                    if (result) {
                        this.Roles = result;
                        this.FilterRoles();
                    }
                });
            });
        }
    }

    FilterRoles() {
        let selectedOnly = this._RolesSelectedOnly;
        let value = this._RoleSearchValue;
        let retVal = [];
        if (value) {
            if (selectedOnly == true && (!this.Role || !this.Role.ChildRoles || this.Role.ChildRoles.length == 0)) {
            } else {
                value = value.toLocaleLowerCase();
                var sid = '';
                if (this.Role) {
                    sid = this.Role.SID;
                }
                retVal = this.Roles.filter((role: any) => {
                    return role.Id != sid && (role.Name.toLocaleLowerCase().indexOf(value) !== -1) &&
                        (!selectedOnly || (selectedOnly && this.Role.ChildRoles.indexOf(role.Id) > -1));
                });
            }
        } else {
            if (selectedOnly == true) {
                if (this.Role && this.Role.ChildRoles && this.Role.ChildRoles.length > 0) {
                    retVal = this.Roles.filter((role: any) => {
                        return role.Id != this.Role.SID && this.Role.ChildRoles.indexOf(role.Id) > -1;
                    });
                }
            } else {
                if (this.Role) {
                    retVal = this.Roles.filter((role: any) => {
                        return role.Id != this.Role.SID;
                    });
                } else {
                    retVal = retVal.concat(this.Roles);
                }
            }
        }
        this.FilteredRoles = retVal;
    }

    onRoleSelectionChange(ev) {
        if (this.Role && ev && ev.options) {
            let doFilter = false;
            ev.options.forEach(option => {
                if (option.value) {
                    if (option.selected) {
                        if (this.Role.ChildRoles) {
                            this.Role.ChildRoles.push(option.value.Id);
                        } else {
                            this.Role.ChildRoles = [option.value.Id];
                        }
                    } else {
                        if (this.Role.ChildRoles) {
                            const index = this.Role.ChildRoles.indexOf(option.value.Id);
                            if (index >= 0 && index < this.Role.ChildRoles.length) {
                                this.Role.ChildRoles.splice(index, 1);
                                if (this._RolesSelectedOnly == true) {
                                    doFilter = true;
                                }
                            }
                        }
                    }
                }
            });
            if (doFilter) {
                this.FilterRoles();
            }
            this.HasChanges = true;
        }
    }

    checkRoleSelection(data) {
        if (data && this.Role && this.Role.ChildRoles) {
            return this.Role.ChildRoles.indexOf(data.Id) > -1;
        }
        return false;
    }

    async checkHasChanges() {
        if (this.HasChanges) {
            let retVal = await MessageBoxHelper.ShowDialog(new TranslateFormatText('@@Wollen Sie die Aenderungen speichern?'), new TranslateFormatText('@@Frage'), MessageBoxButtons.YesNoAbort, MessageBoxIcon.Question);
            if (retVal == MessageBoxResult.Yes) {
                this.Save();
            } else if (retVal == MessageBoxResult.Abort) {
                return false;
            } else { // No
                this.HasChanges = false;
            }
        }
        return true;
    }

    Save() {
        if (this.Role) {
            this.rolesService.AddOrUpdateRole({ Role: this.Role }).subscribe(result => {
                if (result) {
                    const text = new TranslateFormatText('@@Rolle {0} erfolgreich gespeichert.');
                    text.FormatParams.push(TranslatedString.GetTranslation(this.Role.Name));
                    NotificationHelper.Success(text, "@@Rolle speichern");
                } 
                this.HasChanges = false;
            });
        }
    }
}