import { RequestBase } from "./request-base";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class FormulaService extends RequestBase {
    BasePath = 'api/formula';

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    GetFormulaInfos(query): Observable<any> {
        return this.http.post<any>(this.API_BASE_URL + this.BasePath + '/GetFormulaInfos', query, this.options);
    }

    GetFormulaKeys(): Observable<any> {
        return this.http.get<any>(this.API_BASE_URL + this.BasePath + '/GetFormulaKeys', this.options);
    }
}