import { Type } from 'class-transformer';
import { Gradient } from './gradient.model';
import { BorderStyle } from '../enums/borderstyle.enum';
import { LayoutUnit } from '../basic/layoutunit.model';

// @dynamic
export class BorderSide {
    @Type(() => LayoutUnit)
    Thickness: LayoutUnit = new LayoutUnit();
    @Type(() => Gradient)
    Color: Gradient;
    Style: BorderStyle = BorderStyle.Solid;

    toStyle() {
        let retVal = '';
        if (this.Thickness && this.Thickness.Value > 0) {
            retVal += LayoutUnit.ConvertFrom(this.Thickness);
            switch (this.Style) {
                case BorderStyle.Dashed:
                    retVal += ' dashed';
                    break;
                case BorderStyle.Dotted:
                    retVal += ' dotted';
                    break;
                case BorderStyle.Double:
                    retVal += ' double';
                    break;
                default:
                    retVal += ' solid';
                    break;
            }
            let color = 'black';
            if (this.Color) {
                const styles = Gradient.getStyleObject(this.Color);
                if (styles['background']) {
                    color = styles['background'];
                } else if (styles['background-color']) {
                    color = styles['background-color'];
                }
            }
            retVal += ' ' + color;
        }
        return retVal;
    }
    toJSON() {
        if (LayoutUnit.ToStyle(this.Thickness)) {
            return this;
        } else {
            return null;
        }
    }
    isValid() {
        return this.Thickness && this.Thickness.Value > 0;
    }
    static BorderToStyle(border) {
        let retVal = '';
        if (border && border.Thickness && border.Thickness.Value > 0) {
            retVal += LayoutUnit.ConvertFrom(border.Thickness);
            switch (border.Style) {
                case BorderStyle.Dashed:
                    retVal += ' dashed';
                    break;
                case BorderStyle.Dotted:
                    retVal += ' dotted';
                    break;
                case BorderStyle.Double:
                    retVal += ' double';
                    break;
                default:
                    retVal += ' solid';
                    break;
            }
            let color = 'black';
            if (border.Color) {
                const styles = Gradient.getStyleObject(border.Color);
                if (styles['background']) {
                    color = styles['background'];
                } else if (styles['background-color']) {
                    color = styles['background-color'];
                }
            }
            retVal += ' ' + color;
        }
        return retVal;
    }
}
