import { Component } from '@angular/core';
import { ABaseTreeNode } from '../../../../components/common/basetreecontrol/base.tree.control';
import { MessageBoxHelper } from '../../../../components/dialogs/messagebox/messagebox.dialog';
import { TranslateFormatText } from '../../../../helpers/array.helpers';
import { MessageBoxButtons } from '../../../../models/enums/messageboxbuttons.enum';
import { MessageBoxIcon } from '../../../../models/enums/messageboxicon.enum';
import { WorkflowType } from '../../../../models/enums/workflowtype.enum';
import { AServiceWorkflowData } from '../../../../models/workflow/workflow.model';
import { DataSettingsService } from '../../../../services/data.settings.service';
import { WorkflowExitInfo, WorkflowModuleSettingsHelper, WorkflowRegistry } from '../../../../services/workflow.service';
import { WorkflowDialogContent } from '../../../../workflow/workflow.dialog';

@Component({
    selector: 'wf-cleardeltainfo-settings',
    templateUrl: './cleardeltainfo.settings.html',
    styleUrls: ['./cleardeltainfo.settings.css']
})
export class ClearDeltaInfoSettings extends WorkflowDialogContent {

    OriginalList = [];
    FilterInfos = [];
    Nodes = [];

    public static GetRegistry(): WorkflowRegistry {
        const reg = new WorkflowRegistry();
        reg.ID = 'cleardeltainfoWFModule';
        reg.Caption = '@@Delta-Infos zuruecksetzen';
        reg.GroupID = 'reldataoperations';
        reg.Index = 55;
        reg.SettingsControl = ClearDeltaInfoSettings;
        reg.SettingsTypeHelper = new ClearDeltaInfoSettingsDataHelper();
        reg.WorkflowType = WorkflowType.Service;
        return reg;
    }

    constructor(private service: DataSettingsService) {
        super();
    }

    ngOnInit(): void {
        this.service.GetAllDeltaFilterInfos().subscribe(result => {
            if (result) {
                this.FilterInfos = result;
                let index = 0;
                const nodes = [];
                this.FilterInfos.forEach(fi => {
                    const fiNode = new ABaseTreeNode(index++);
                    fiNode.Caption = fi.WorkflowCaption;
                    fiNode.TranslateCaption = '@@Workflow';
                    fiNode.Selectable = false;
                    fiNode.HasChildren = true;
                    fiNode.Children = [];
                    fi.Container.forEach(cont => {
                        const contNode = new ABaseTreeNode(index++);
                        contNode.Caption = cont.ContainerCaption;
                        contNode.TranslateCaption = '@@Container';
                        contNode.Selectable = false;
                        contNode.HasChildren = true;
                        contNode.Children = [];
                        cont.DeltaFilter.forEach(df => {
                            const dfNode = new ClearDeltaNode(index++);
                            dfNode.Selectable = false;
                            dfNode.Data = df;
                            contNode.Children.push(dfNode);
                            let listIndex = -1;
                            if (this.OriginalList.some((x, i) => {
                                if (x === df.SID) {
                                    df.Selected = true;
                                    listIndex = i;
                                    return true;
                                }
                                return false;
                            })) {
                                this.OriginalList.splice(listIndex, 1);
                            }
                        });
                        fiNode.Children.push(contNode);
                    });
                    nodes.push(fiNode);
                });
                if (this.OriginalList.length > 0) {
                    const text = new TranslateFormatText('@@Fuer folgende IDs konnten keine gueltigen DeltaInfos gefunden werden:\n\n{0}');
                    text.FormatParams.push(this.OriginalList.join(', '));
                    MessageBoxHelper.ShowDialog(text, null, MessageBoxButtons.Ok, MessageBoxIcon.Warning);
                }
                this.Nodes = nodes;
            }
        });
    }

    initialize(data: any) {
        if (data && data.DeltaInfos) {
            this.OriginalList.push(...data.DeltaInfos);
        }
    }

    getResult() {
        const retVal = new ClearDeltaInfoSettingsData();
        this.FilterInfos.forEach(fi => {
            fi.Container.forEach(cont => {
                cont.DeltaFilter.forEach(df => {
                    if (df.Selected) {
                        retVal.DeltaInfos.push(df.SID);
                    }
                });
            });
        });
        return retVal;
    }
}

export class ClearDeltaInfoSettingsData extends AServiceWorkflowData {
    DeltaInfos = [];

    getTypeName(): string {
        return 'evidanza.MiddleWare.Shared.Workflow.DataOperations.ClearDeltaInfo.ClearDeltaInfoSettingsData';
    }
}

export class ClearDeltaInfoSettingsDataHelper extends WorkflowModuleSettingsHelper {
    getExitPoints(settings: any): WorkflowExitInfo[] {
        return [new WorkflowExitInfo()];
    }
    getEmptySettingsInstance() {
        return new ClearDeltaInfoSettingsData();
    }
}

export class ClearDeltaNode extends ABaseTreeNode {
    Data;
}
