import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { TranslateFormatText } from '../../../helpers/array.helpers';
import { TranslateHelper } from '../../../helpers/injector.helper';
import { TranslatedString } from '../../../models/translatedstring.model';
import { LayoutService } from '../../../services/layout.service';
import { MetaService } from '../../../services/meta.service';
import { BaseListDetail, BaseListSettings, DeleteTexts, SaveTexts } from '../../base.list.settings';
import {NotificationHelper} from "../../../helpers/notification.helper";

@Component({
    selector: 'customcss-settings',
    templateUrl: '../../base.list.settings.html'
})
export class CustomCSSSettings extends BaseListSettings {

    ShowAdd = true;
    ShowDelete = true;
    ShowRefresh = true;
    ShowSave = true;
    Fonts = [];
    constructor(protected factoryResolver: ComponentFactoryResolver, protected cdRef: ChangeDetectorRef, private service: MetaService) {
        super(factoryResolver, cdRef);
    }

    getContentType() {
        return CustomCSSDetail;
    }

    loadList(handler) {
        this.service.GetCustomCSS(null).subscribe((result) => {
            const list = [];
            result.forEach((entry) => {
                list.push({
                    Caption: entry.Caption,
                    ID: entry.SID,
                    IsCapsule: entry.IsCapsule,
                    IsOverridden: entry.IsOverridden
                });
            });
            handler(list);
        });
    }

    loadData(data) {
        this.service.GetCustomCSSByID(data).subscribe((result) => {
            if (result) {
                this.setSelectedItem(result);
            } else {
                const item = {};
                this.setSelectedItem(item);
            }
        });
    }

    getNewItem() {
        return {
            Name: TranslateHelper.TranslatorInstance.instant('@@New CSS')
        };
    }

    getDeleteText(sel): DeleteTexts {
        const retVal = new DeleteTexts();
        retVal.Question = new TranslateFormatText('@@Are you sure you want to delete the CSS file \'{0}\'?');
        retVal.Question.FormatParams.push(sel.Caption);
        retVal.Success = new TranslateFormatText('@@CSS \'{0}\' successfully deleted.');
        retVal.Success.FormatParams.push(sel.Caption);
        retVal.Title = new TranslateFormatText('@@CSS delete');
        return retVal;
    }

    getSaveSuccessText(sel): SaveTexts {
        let caption = TranslatedString.GetTranslation(sel.Caption);
        if (!caption) {
            caption = sel.Name;
        }
        const retVal = new SaveTexts();
        retVal.Text = new TranslateFormatText('@@CSS \'{0}\' saved successfully.');
        retVal.Text.FormatParams.push(caption);
        retVal.Title = new TranslateFormatText('@@CSS save');
        return retVal;
    }

    delete(data, handler) {
        this.service.DeleteCustomCSS(data).subscribe((result) => {
            handler(result);
        });
    }

    saveInternal(item, handler) {

        if (item?.Name.trim() == '') {
            NotificationHelper.Error('Name field is required', '@@Error');
            LayoutService.Loading.next(false);
            return;
        }

        if (typeof item.VariableName === 'string' && !item.VariableName.startsWith('--')) {
            item.VariableName = '--' + item.VariableName;
        }
        this.service.SaveCustomCSS(item).subscribe((result) => {
            if (result) {
                handler(result, result.SID, result.Caption);
                LayoutService.Loading.next(false);
            }
        });
    }

    handleNew(item, result) {
        item.SID = result.SID;
        item.Version = result.Version;
    }

    updateListItem(item, result) {
        item.IsCapsule = result.IsCapsule;
        item.IsOverridden = result.IsOverridden;
    }

    static GetSettingsEntry() {
        return {
            Caption: '@@CSSClasses',
            ID: 'cssclass',
            Icon: 'cssclass',
            Index: 5,
            Parent: 'ci',
            Security: {
                Name: 'evidanza.App.Shared.Security.CorporateIdentityRight',
                Value: 16
            },
            Content: CustomCSSSettings
        };
    }
}

@Component({
    selector: 'customcss-detail',
    templateUrl: './customcss.settings.html',
    styleUrls: ['./customcss.settings.css']
})
export class CustomCSSDetail extends BaseListDetail { }
