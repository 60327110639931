import {Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {SETTINGS_LIST} from "../components/pages/settings.page";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "./navigation.service";

@Injectable()
export class GlobalSearchService {

    private CallNavMenuSubject = new Subject<void>();
    CallNavMenu$ = this.CallNavMenuSubject.asObservable();

    flattenList = [];
    ActiveURL;
    BaseURL;

    constructor(private router: Router, private route: ActivatedRoute) {
        // Global Search: Pass navigation list to flatten the tree
        this.flattenList = this.flattenTree(SETTINGS_LIST);
        NavigationService.ActiveURL.subscribe((url) => {
            this.ActiveURL = url;
        });
        this.BaseURL = this.ActiveURL.substring(0, this.ActiveURL.indexOf('settings') + 9);
    }

    CallNavMenu(nav) {
        this.CallNavMenuSubject.next(nav);
    }

    search(value) {
        const isEmpty = /^\s*$/.test(value);
        const filterValue = value.toLowerCase();
        if (!isEmpty) {
            return of(this.performSearch(filterValue));
        } else {
            return of([]);
        }
    }

    flattenTree(tree, parentRoute: string = '') {
        const flatList = [];

        function flatten(node: any, currentRoute: string): void {
            const nodeRoute = currentRoute ? `${currentRoute}/${node.ID}` : node.ID;
            flatList.push(
                {navItem: node, route: nodeRoute}
            );

            if (node.Children) {
                for (const child of node.Children) {
                    flatten(child, nodeRoute);
                }
            }
        }

        for (const node of tree) {
            flatten(node, parentRoute);
        }

        return flatList;
    }

    // Function to perform flexible search
    performSearch(query) {
        const queryWords = query.toLowerCase().split(/\s+/);
        const filteredItems = this.flattenList.filter(item => {
            const lowerCaseID = (item.navItem.ID || '').toLowerCase();
            const lowerCaseCaption = (item.navItem.Caption || '').toLowerCase();
            return queryWords.every(word =>
                lowerCaseID.includes(word) || lowerCaseCaption.includes(word)
            );
        });

        return filteredItems;
    }
}
