import { MultiResultHelper } from '../../../helpers/multiresult.helper';
import { DataDescription } from '../../datadescription/multi/datadescription.model';
import { AxisNode, MultiResult } from '../../datadescription/multi/multiresult.model';
import { CellStyle } from '../../styling/cell.style';
import { StyleMerger } from '../../styling/styleMerger';
import { ITaskExecuter } from '../atask.model';
import { TaskHelper } from '../task.helper';

export class MeasureTaskExecuter implements ITaskExecuter {
    static TaskID = 'measureformattingtask';

    MeasureUniqueID = -1;
    Style: CellStyle;
    FormatHeaders = false;
    Result: MultiResult;
    DataDescription: DataDescription;
    Merger: StyleMerger;

    static IterateMeasureNodes(measureID: number, dataDescription: DataDescription, result: MultiResult,
        cellFunction: (xNode: AxisNode, yNode: AxisNode, mNode: AxisNode) => void, measureFunction: (mNode: AxisNode) => void) {
        if (measureID > -1 && dataDescription && result) {
            const mNode = result.Measures.Nodes.find(x => x.UniqueID === measureID);
            if (mNode) {
                if (measureFunction) {
                    measureFunction(mNode);
                }
                if (cellFunction) {
                    const noLevelIndex = {
                        Index: 0
                    };
                    if (!dataDescription.XLevelNodes.Areas.some(area => {
                        if (area.Measures && area.Measures.some(m => m.UniqueID === measureID)) {
                            const measureNodes = MultiResultHelper.GetNodesFromArea(area, result.XAxis.Nodes, noLevelIndex);
                            if (result.XAxis.SumNode) {
                                measureNodes.Nodes.push(result.XAxis.SumNode);
                            }
                            MultiResultHelper.ExecuteOnAllNodesInternal(measureNodes.Nodes, (xNode) => {
                                MultiResultHelper.ExecuteOnAllNodes(result.YAxis, (yNode) => {
                                    cellFunction(xNode, yNode, mNode);
                                }, true);
                            });
                            return true;
                        }
                        return false;
                    })) {
                        noLevelIndex.Index = 0;
                        dataDescription.YLevelNodes.Areas.some(area => {
                            if (area.Measures && area.Measures.some(m => m.UniqueID === measureID)) {
                                const measureNodes = MultiResultHelper.GetNodesFromArea(area, result.YAxis.Nodes, noLevelIndex);
                                if (result.YAxis.SumNode) {
                                    measureNodes.Nodes.push(result.YAxis.SumNode);
                                }
                                MultiResultHelper.ExecuteOnAllNodesInternal(measureNodes.Nodes, (yNode) => {
                                    MultiResultHelper.ExecuteOnAllNodes(result.XAxis, (xNode) => {
                                        cellFunction(xNode, yNode, mNode);
                                    });
                                    if (result.XAxis.SumNode) {
                                        cellFunction(result.XAxis.SumNode, yNode, mNode);
                                    }
                                }, true);
                                return true;
                            }
                            return false;
                        });
                    }
                }
            }
        }
    }

    Init(settings: any, result: MultiResult, dataDescription: DataDescription, context: any) {
        this.Result = result;
        this.DataDescription = dataDescription;
        if (settings) {
            if (typeof settings.MeasureUniqueID === 'number') {
                this.MeasureUniqueID = settings.MeasureUniqueID;
            }
            if (settings.Style) {
                this.Style = settings.Style;
                delete this.Style['MergeID'];
            }
            if (typeof settings.IsStyleAppliedToHeaders === 'boolean') {
                this.FormatHeaders = settings.IsStyleAppliedToHeaders;
            }
        }
        this.Merger = StyleMerger.GetStyleMerger(context);
    }

    Execute() {
        if (this.Style && this.Merger) {
            const cellFunction = TaskHelper.RequiresCell(this.Style) ?
                (x, y, m) => {
                    const cell = MultiResultHelper.GetCellOrNew(this.Result, x, y, m);
                    cell.StyleID = this.Merger.MergeStyle(cell.StyleID, this.Style);
                } : (x, y, m) => {
                    const cell = MultiResultHelper.GetCellOrNull(this.Result, x, y, m);
                    if (cell) {
                        cell.StyleID = this.Merger.MergeStyle(cell.StyleID, this.Style);
                    }
                };
            MeasureTaskExecuter.IterateMeasureNodes(this.MeasureUniqueID, this.DataDescription, this.Result, cellFunction, (m) => {
                if (this.FormatHeaders) {
                    m.StyleID = this.Merger.MergeStyle(m.StyleID, this.Style);
                }
            });
        }
    }
}
