import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import Sunburst from 'sunburst-chart';
import * as d3 from 'd3';
import { ResizedEvent } from 'angular-resize-event';

@Component({
    selector: 'sunburst-chart',
    template: '<div #container class="full" style="posititon:relative;" (resized)="resized($event)"><div id="chart" *ngIf="SizeSet && !Refreshing" [style.width]="ContainerSize.width" [style.height]="ContainerSize.height" style="position:absolute"></div></div>',
    styleUrls: ['sunburst.component.css']
})
export class SunburstControl implements AfterViewInit {
    Chart;

    //#region
    OptionsValue = null;
    @Input()
    get Options() {
        return this.OptionsValue;
    }
    set Options(val) {
        this.Refreshing = true;
        this.OptionsValue = val;
        this.OptionsChange.emit(val);
        this.cdRef.detectChanges();
        if (val) {
            setTimeout(() => {
                this.Refreshing = false;
                this.cdRef.detectChanges();
                this.RenderChart();
            },50)
        }
    }
    @Output() OptionsChange = new EventEmitter<any>();
    Refreshing = false;
    //#endregion
    Color;

    private static clearNode(node, childPath: string) {
        const retVal = Object.assign({}, node);
        delete retVal.__dataNode;
        const children = retVal[childPath];
        if (Array.isArray(children) && children.length > 0) {
            const newChildren = [];
            children.forEach(child => {
                const clearedChild = SunburstControl.clearNode(child, childPath);
                newChildren.push(clearedChild);
            });
            retVal[childPath] = newChildren;
        }
        return retVal;
    }

    constructor(public cdRef: ChangeDetectorRef) {
        this.Color = d3.scaleOrdinal(d3.schemePaired);
    }
    ngAfterViewInit(): void {
        this.RenderChart();
    }

    RenderChart() {
        if (this.OptionsValue && this.OptionsValue.data && this.SizeSet) {
            const element = document.getElementById('chart');
            if (element) {
                this.Chart = Sunburst()
                    .data(this.OptionsValue.data)
                    .label(this.OptionsValue.Label ? this.OptionsValue.Label : 'name')
                    .children(this.OptionsValue.Children ? this.OptionsValue.Children : 'children')
                    .size(this.OptionsValue.Value ? this.OptionsValue.Value : 'value')
                    .color((node) => this.GetColor(node))
                    .showLabels(this.OptionsValue.ShowLabels ? true : false)
                    .showTooltip((node) => this.OptionsValue.ShowTooltip ? true : false)
                    .onClick((node) => {
                        this.Chart.focusOnNode(node);
                        const clearedNode = SunburstControl.clearNode(node, this.OptionsValue.Children ? this.OptionsValue.Children : 'children');
                        this.SelectionChanged.emit(clearedNode);
                    })
                    .tooltipContent((d, node) => this.GetTooltip(node, this.OptionsValue.TooltipContent, this.OptionsValue.TooltipValue ? this.OptionsValue.TooltipValue : 'value'))
                    .tooltipTitle((node) => this.GetTooltip(node, this.OptionsValue.TooltipTitle, this.OptionsValue.Label ? this.OptionsValue.Label : 'name'))
                    .excludeRoot(this.OptionsValue.ExcludeRoot ? true : false)
                    .centerRadius(this.OptionsValue.CenterRadius)
                    .radiusScaleExponent(this.OptionsValue.RadiusScaleExponent)
                    (element);
                this.SetChartSize();
            }
        }
    }
    @Output() SelectionChanged = new EventEmitter<any>();
    GetColor(node) {
        let retVal = null;
        if (this.OptionsValue.Color) {
            retVal = node[this.OptionsValue.Color];
        } else if (node && node.__dataNode) {
            let parent = node.__dataNode.parent;
            retVal = this.Color(parent ? parent.data[this.OptionsValue.Label ? this.OptionsValue.Label : 'name'] : null);
        }
        return retVal;
    }
    GetTooltip(node, text, property) {
        let data = null;
        if (node.data) {
            data = node.data;
        } else {
            data = node;
        }
        let retVal = data[property];
        if (text) {
            if (text.indexOf('$value') > -1) {
                retVal = text.substring(0, text.indexOf('$value'));
                retVal += data[property];
                retVal += text.substring(text.indexOf('$value') + 6);
            } else {
                retVal = text;
            }
        }
        return retVal;
    }

    ContainerSizeValue: any;
    get ContainerSize() {
        return this.ContainerSizeValue;
    }
    set ContainerSize(value) {
        this.ContainerSizeValue = value;
        this.SetChartSize();
    }
    SizeSet = false;
    private container;
    @ViewChild('container') set _container(content: ElementRef) {
        this.container = content;
        if (content && content.nativeElement) {
            this.ContainerSize = {
                height: this.container.nativeElement.clientHeight + 'px',
                Height: this.container.nativeElement.clientHeight,
                width: this.container.nativeElement.clientWidth + 'px',
                Width: this.container.nativeElement.clientWidth
            };
            this.SizeSet = true;
            this.RenderChart();
        }
    }

    resized(event: ResizedEvent) {
        this.ContainerSize = {
            height: event.newRect.height + 'px',
            Height: event.newRect.height,
            width: event.newRect.width + 'px',
            Width: event.newRect.width
        }
        this.SizeSet = true;
    }
    SetChartSize() {
        if (this.Chart && this.ContainerSize) {
            this.Chart.width(this.ContainerSize.Width);
            this.Chart.height(this.ContainerSize.Height);
        }
        this.cdRef.detectChanges();
    }
}
