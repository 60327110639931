export class MappingStructure {
    Create: RestMapping = new RestMapping();
    Read: RestMapping[] = [];
    Update: RestMapping[] = [];
    Replace: RestMapping = new RestMapping();
    Delete: RestMapping[] = [];
    Count: RestMapping[] = [];
}

export class RestMapping {
    EndpointID: string;
    Name: string;
    Parameters: MappingParameter[];
    Body: BodyMapping;
}
export class BodyMapping {
    ObjectID: string;
    Parameters: MappingParameter[];
}

export class WhereStruct {
    ColumnName: string;
    Property: WhereProps;
    Convert: string;
}
export class OrderStruct {
    ColumnName: string;
    Property: OrderProps;
    Convert: string;
}
export class UpdateStruct {
    ColumnName: string;
}
export class SelectStruct {
    Seperator: string;
}
export class FixedStruct {
    Formula: string;
}
export class PagingStruct {
    Formula: string;
}

export class MappingParameter {
    ParameterType: ParameterType;
    ParameterName: string;
    StructureType: StructureType = StructureType.NONE;
    IsRequired: boolean;
    Where: WhereStruct = new WhereStruct();
    Order: OrderStruct = new OrderStruct();
    Update: UpdateStruct = new UpdateStruct();
    Select: SelectStruct = new SelectStruct();
    Fixed: FixedStruct = new FixedStruct();
    Paging: PagingStruct = new PagingStruct();
}

export enum WhereProps {
    Name,
    Comparer,
    Value,
    Value2
}
export enum OrderProps {
    Name,
    Direction
}
export enum StructureType {
    NONE = 100,
    WHERE = 0,
    ORDER = 1,
    UPDATE = 2,
    SELECT = 3,
    FIXED = 4,
    PAGING = 5
}
export enum ParameterType {
    Query = 0,
    Header = 1,
    Path = 2,
    Cookie = 3,
    None = 4
}