import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutUnit } from '../../../models/basic/layoutunit.model';
import { LayoutUnitThickness } from '../../../models/basic/layoutunitthickness.model';
import { UnitType } from '../../../models/enums/unittype.enum';
import { BaseThemeControl } from '../base.theme.control';

export class CarouselMenuTabOptionsChanged {
    Name: string;
    Property: string;
}

@Component({
    selector: 'layoutunitthickness',
    templateUrl: './layoutunitthickness.component.html',
    styleUrls: ['./layoutunitthickness.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutUnitThicknessComponent extends BaseThemeControl {
    SelectModeValue = 'none';
    get SelectMode() {
        return this.SelectModeValue;
    }
    set SelectMode(value) {
        this.Check(value)
        this.SelectModeValue = value;
        this.cdRef.detectChanges();
    }
    
    //#region Title
    TitleValue;
    @Input()
    get Title() {
        return this.TitleValue;
    }
    set Title(val) {
        this.TitleValue = val;
        this.TitleChange.emit(this.TitleValue);
        this.cdRef.detectChanges();
    }
    @Output() TitleChange = new EventEmitter<any>();
    //#endregion
    //#region IsBorderRadius
    IsBorderRadiusValue = false;
    @Input()
    get IsBorderRadius() {
        return this.IsBorderRadiusValue;
    }
    set IsBorderRadius(val) {
        this.IsBorderRadiusValue = val;
        this.IsBorderRadiusChange.emit(this.IsBorderRadiusValue);
        this.cdRef.detectChanges();
    }
    @Output() IsBorderRadiusChange = new EventEmitter<any>();
    //#endregion

    AllValue = new LayoutUnit();
    get All() {
        return this.AllValue;
    }
    set All(value) {
        this.AllValue = value;
        if (this.IsBorderRadius) {
            this.SetValue(this.DataSourceInternal.TopLeft, value)
            this.SetValue(this.DataSourceInternal.TopRight, value)
            this.SetValue(this.DataSourceInternal.BottomRight, value)
            this.SetValue(this.DataSourceInternal.BottomLeft, value)
        } else {
            this.SetValue(this.DataSourceInternal.Top, value)
            this.SetValue(this.DataSourceInternal.Left, value)
            this.SetValue(this.DataSourceInternal.Right, value)
            this.SetValue(this.DataSourceInternal.Bottom, value)
        }
    }
    HorizontalValue = new LayoutUnit();
    get Horizontal() {
        if (this.DataSourceInternal.Left == this.DataSourceInternal.Right) {
            return this.DataSourceInternal.Left;
        } 
        return this.HorizontalValue;
    }
    set Horizontal(value) {
        this.HorizontalValue = value;
        this.SetValue(this.DataSourceInternal.Left, value)
        this.SetValue(this.DataSourceInternal.Right, value)
    }
    VerticalValue = new LayoutUnit();
    get Vertical() {
        if (this.DataSourceInternal.Top == this.DataSourceInternal.Bottom) {
            return this.DataSourceInternal.Top;
        }
        return this.VerticalValue;
    }
    set Vertical(value) {
        this.VerticalValue = value;
        this.SetValue(this.DataSourceInternal.Top, value)
        this.SetValue(this.DataSourceInternal.Bottom, value)
    }
    TopRadiusValue = new LayoutUnit();
    get TopRadius() {
        return this.TopRadiusValue;
    }
    set TopRadius(value) {
        this.TopRadiusValue = value;
        this.SetValue(this.DataSourceInternal.TopLeft, value)
        this.SetValue(this.DataSourceInternal.TopRight, value)
    }
    BottomRadiusValue = new LayoutUnit();
    get BottomRadius() {
        return this.BottomRadiusValue;
    }
    set BottomRadius(value) {
        this.BottomRadiusValue = value;
        this.SetValue(this.DataSourceInternal.BottomRight, value)
        this.SetValue(this.DataSourceInternal.BottomLeft, value)
    }

    SetValue(item, value) {
        if (item) {
            item.Type = value.Type;
            item.Value = value.Value
        }
    }

    constructor(cdRef: ChangeDetectorRef) {
        super(cdRef);
        this.DataSourceChange.subscribe((val) => {
            if (this.IsBorderRadius) {
                if (LayoutUnit.ToStyle(val.TopLeft) != '' || LayoutUnit.ToStyle(val.TopRight) != '' || LayoutUnit.ToStyle(val.BottomRight) != '' || LayoutUnit.ToStyle(val.BottomLeft) != '') {
                    this.SelectMode = 'single'
                    if ((LayoutUnit.ToStyle(val.TopLeft) != '' || LayoutUnit.ToStyle(val.BottomLeft) != '') &&
                        LayoutUnit.ToStyle(val.TopLeft) != LayoutUnit.ToStyle(val.BottomLeft) &&
                        LayoutUnit.ToStyle(val.TopLeft) == LayoutUnit.ToStyle(val.TopRight) &&
                        LayoutUnit.ToStyle(val.BottomLeft) == LayoutUnit.ToStyle(val.BottomRight)) {
                        this.SelectMode = 'width'
                        this.TopRadius = val.TopLeft;
                        this.BottomRadius = val.BottomLeft;
                    }
                    if (LayoutUnit.ToStyle(val.TopLeft) != '' &&
                        LayoutUnit.ToStyle(val.TopLeft) == LayoutUnit.ToStyle(val.TopRight) &&
                        LayoutUnit.ToStyle(val.TopLeft) == LayoutUnit.ToStyle(val.BottomLeft) &&
                        LayoutUnit.ToStyle(val.TopLeft) == LayoutUnit.ToStyle(val.BottomRight)) {
                        this.SelectMode = 'all'
                        this.All = val.TopLeft;
                    }
                } else {
                    this.SelectMode = 'none'
                }
            } else {
                if (LayoutUnit.ToStyle(val.Top) != '' || LayoutUnit.ToStyle(val.Left) != '' || LayoutUnit.ToStyle(val.Right) != '' || LayoutUnit.ToStyle(val.Bottom) != '') {
                    this.SelectMode = 'single'
                    if ((LayoutUnit.ToStyle(val.Top) != '' || LayoutUnit.ToStyle(val.Left) != '') &&
                        LayoutUnit.ToStyle(val.Top) != LayoutUnit.ToStyle(val.Left) &&
                        LayoutUnit.ToStyle(val.Top) == LayoutUnit.ToStyle(val.Bottom) &&
                        LayoutUnit.ToStyle(val.Left) == LayoutUnit.ToStyle(val.Right)) {
                        this.SelectMode = 'width'
                        this.Vertical = val.Top;
                        this.Horizontal = val.Left;
                    }
                    if (LayoutUnit.ToStyle(val.Top) != '' &&
                        LayoutUnit.ToStyle(val.Top) == LayoutUnit.ToStyle(val.Left) &&
                        LayoutUnit.ToStyle(val.Top) == LayoutUnit.ToStyle(val.Right) &&
                        LayoutUnit.ToStyle(val.Top) == LayoutUnit.ToStyle(val.Bottom)) {
                        this.SelectMode = 'all'
                        this.All = val.Top;
                    }
                } else {
                    this.SelectMode = 'none'
                }
            }
        })
    }
    Clear() {
        this.SelectMode = 'none';
        this.Reset();
    }
    Reset() {
        this.All = new LayoutUnit();
        this.Vertical = new LayoutUnit();
        this.Horizontal = new LayoutUnit();
        this.TopRadius = new LayoutUnit();
        this.BottomRadius = new LayoutUnit();
        if (this.DataSourceInternal) {
            if (this.IsBorderRadius) {
                if (this.DataSourceInternal.TopLeft) {
                    this.DataSourceInternal.TopLeft = new LayoutUnit();
                }
                if (this.DataSourceInternal.TopRight) {
                    this.DataSourceInternal.TopRight = new LayoutUnit();
                }
                if (this.DataSourceInternal.BottomRight) {
                    this.DataSourceInternal.BottomRight = new LayoutUnit();
                }
                if (this.DataSourceInternal.BottomLeft) {
                    this.DataSourceInternal.BottomLeft = new LayoutUnit();
                }
            } else {
                if (this.DataSourceInternal.Top) {
                    this.DataSourceInternal.Top = new LayoutUnit();
                }
                if (this.DataSourceInternal.Left) {
                    this.DataSourceInternal.Left = new LayoutUnit();
                }
                if (this.DataSourceInternal.Right) {
                    this.DataSourceInternal.Right = new LayoutUnit();
                }
                if (this.DataSourceInternal.Bottom) {
                    this.DataSourceInternal.Bottom = new LayoutUnit();
                }
            }
        }
        this.onStyleChanged();
    }
    ChangeLock(key) {
        if (this.SelectedItem[key]) {
            let item = this.SelectedItem[key];
            let unit = new LayoutUnit();
            unit.Value = item.Value;
            unit.Type = item.Type;


            this.SelectedItem[key] = unit;
        }
        this.onStyleChanged();
    }

    Check(mode) {
        if (mode == null) {
            switch (this.SelectMode) {
                case 'none': break;
                case 'all':
                    this.All = this.All;
                    break;
                case 'width':
                    if (this.IsBorderRadius) {
                        this.TopRadius = this.TopRadius;
                        this.BottomRadius = this.BottomRadius;
                    } else {
                        this.Horizontal = this.Horizontal
                        this.Vertical = this.Vertical
                    }
                    break;
                case 'single':
                    break;
            }
        } else if (mode != this.SelectMode) {
            if (mode == 'none') {
                this.Reset();
            } else {
                switch (this.SelectMode) {
                    case 'none':
                        if (mode == 'all') {
                            this.All = new LayoutUnit()
                        }
                        break;
                    case 'all':
                        if (mode == 'width') {
                            if (this.IsBorderRadius) {
                                this.TopRadius = this.DataSourceInternal.TopLeft;
                                this.BottomRadius = this.DataSourceInternal.BottomLeft;
                            } else {
                                this.Horizontal = this.DataSourceInternal.Left
                                this.Vertical = this.DataSourceInternal.Top
                            }
                        }
                        break;
                    case 'width':
                        //if (mode == 'all') {
                        //    if (this.IsBorderRadius) {
                        //        this.TopRadius = this.DataSourceInternal.TopLeft;
                        //        this.BottomRadius = this.DataSourceInternal.BottomLeft;
                        //    } else {
                        //        this.Horizontal = this.DataSourceInternal.Left
                        //        this.Vertical = this.DataSourceInternal.Top
                        //    }
                        //}
                        break;
                    case 'single':
                        if (mode == 'width') {
                            if (this.IsBorderRadius) {
                                if ((LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) != '' || LayoutUnit.ToStyle(this.DataSourceInternal.BottomLeft) != '') &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) == LayoutUnit.ToStyle(this.DataSourceInternal.TopRight) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.BottomLeft) == LayoutUnit.ToStyle(this.DataSourceInternal.BottomRight)) {
                                    this.TopRadius = this.DataSourceInternal.TopLeft;
                                    this.BottomRadius = this.DataSourceInternal.BottomLeft;
                                } else {
                                    this.Reset()
                                }
                                
                            } else {
                                if ((LayoutUnit.ToStyle(this.DataSourceInternal.Left) != '' || LayoutUnit.ToStyle(this.DataSourceInternal.Top) != '') &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.Left) == LayoutUnit.ToStyle(this.DataSourceInternal.Right) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.Top) == LayoutUnit.ToStyle(this.DataSourceInternal.Bottom)) {
                                    this.Horizontal = this.DataSourceInternal.Left
                                    this.Vertical = this.DataSourceInternal.Top
                                } else {
                                    this.Reset()
                                }
                            }
                            
                        }
                        if (mode == 'all') {
                            if (this.IsBorderRadius) {
                                if (LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) != '' &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) == LayoutUnit.ToStyle(this.DataSourceInternal.TopRight) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) == LayoutUnit.ToStyle(this.DataSourceInternal.BottomLeft) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.TopLeft) == LayoutUnit.ToStyle(this.DataSourceInternal.BottomRight)) {
                                    this.All = this.DataSourceInternal.TopLeft;
                                } else {
                                    this.Reset()
                                }

                            } else {
                                if (LayoutUnit.ToStyle(this.DataSourceInternal.Left) != '' &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.Left) == LayoutUnit.ToStyle(this.DataSourceInternal.Right) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.Left) == LayoutUnit.ToStyle(this.DataSourceInternal.Top) &&
                                    LayoutUnit.ToStyle(this.DataSourceInternal.Left) == LayoutUnit.ToStyle(this.DataSourceInternal.Bottom)) {
                                    this.All = this.DataSourceInternal.Top;
                                } else {
                                    this.Reset()
                                }
                            }
                        }
                        break;
                }
            }
            
            if (this.SelectMode == 'none') {

            }
            if (this.SelectMode == 'all') {

            }
            if (this.SelectMode == 'width') {

            }
            if (this.SelectMode == 'single') {

            }
        }
        //if (this.IsBorderRadius) {
            
        //    if ((this.DataSourceInternal.TopLeft && this.DataSourceInternal.TopLeft.this.DataSourceInternalue) || (this.DataSourceInternal.TopRight && this.DataSourceInternal.TopRight.this.DataSourceInternalue) || (this.DataSourceInternal.BottomRight && this.DataSourceInternal.BottomRight.this.DataSourceInternalue) || (this.DataSourceInternal.BottomLeft && this.DataSourceInternal.BottomLeft.this.DataSourceInternalue)) {
        //        if (((this.DataSourceInternal.TopLeft && this.DataSourceInternal.TopRight && this.DataSourceInternal.TopLeft.this.DataSourceInternalue == this.DataSourceInternal.TopRight.this.DataSourceInternalue) || (this.DataSourceInternal.TopLeft && this.DataSourceInternal.TopRight)) && ((this.DataSourceInternal.BottomRight && this.DataSourceInternal.BottomLeft && this.DataSourceInternal.BottomRight.this.DataSourceInternalue == this.DataSourceInternal.BottomLeft.this.DataSourceInternalue) || (!this.DataSourceInternal.BottomRight && !this.DataSourceInternal.BottomLeft))) {
        //            this.TopRadius = this.DataSourceInternal.TopLeft;
        //            this.BottomRadius = this.DataSourceInternal.BottomLeft;
        //        }
        //        if (this.DataSourceInternal.TopLeft && this.DataSourceInternal.TopRight && this.DataSourceInternal.BottomRight && this.DataSourceInternal.BottomLeft && this.DataSourceInternal.TopLeft.this.DataSourceInternalue == this.DataSourceInternal.TopRight.this.DataSourceInternalue == this.DataSourceInternal.BottomRight.this.DataSourceInternalue == this.DataSourceInternal.BottomLeft.this.DataSourceInternalue) {
        //            this.All = this.DataSourceInternal.Top;
        //        }
        //    }
        //} else {
        //    if ((this.DataSourceInternal.Top && this.DataSourceInternal.Top.this.DataSourceInternalue) || (this.DataSourceInternal.Bottom && this.DataSourceInternal.Bottom.this.DataSourceInternalue) || (this.DataSourceInternal.Right && this.DataSourceInternal.Right.this.DataSourceInternalue) || (this.DataSourceInternal.Left && this.DataSourceInternal.Left.this.DataSourceInternalue)) {
        //        if (((this.DataSourceInternal.Bottom && this.DataSourceInternal.Top && this.DataSourceInternal.Bottom.this.DataSourceInternalue == this.DataSourceInternal.Top.this.DataSourceInternalue) || (!this.DataSourceInternal.Bottom && !this.DataSourceInternal.Top)) && ((this.DataSourceInternal.Left && this.DataSourceInternal.Right && this.DataSourceInternal.Left.this.DataSourceInternalue == this.DataSourceInternal.Right.this.DataSourceInternalue) || (!this.DataSourceInternal.Left && !this.DataSourceInternal.Right))) {
        //            this.Vertical = this.DataSourceInternal.Top;
        //            this.Horizontal = this.DataSourceInternal.Left;
        //        }
        //        if (this.DataSourceInternal.Top && this.DataSourceInternal.Bottom && this.DataSourceInternal.Right && this.DataSourceInternal.left && this.DataSourceInternal.Top.this.DataSourceInternalue == this.DataSourceInternal.Bottom.this.DataSourceInternalue == this.DataSourceInternal.Right.this.DataSourceInternalue == this.DataSourceInternal.Left.this.DataSourceInternalue) {
        //            this.All = this.DataSourceInternal.Top;
        //        }
        //    }
        //}
    }


    @Output() StyleChanged = new EventEmitter<any>();
    onStyleChanged() {
        this.Check(null)
        this.StyleChanged.emit();
    }
    ToAll() {
        this.SelectMode = 'all';
    }
    ToWidth() {
        this.SelectMode = 'width';
    }
    ToSingle() {
        this.SelectMode = 'single';
    }
}
